import { PageTitle } from "@components/typography/PageTitle"
import { Result, Spin } from "@stories/components/Antd"
import { AnimatePresence, motion } from "framer-motion"
import { Button } from "@stories/components/Button/Button"
import { useClosedTradeForm } from "./ClosedTradeFormContext"

const SavedStatus = () => {
  const { savedClosedTrade, onClose } = useClosedTradeForm()
  return (
    <Result
      className="mt-24"
      status="success"
      extra={
        <div className="flex flex-col items-center justify-center space-y-4">
          <PageTitle
            title={`Thank you for recording your ${savedClosedTrade?.company.name ?? ""} Trade.`}
          />
          <div className="w-36">
            <Button variant="secondary" label="Close" onClick={onClose} isFullWidth />
          </div>
        </div>
      }
    />
  )
}
const ErrorStatus = () => (
  <Result
    className="mt-24"
    status="warning"
    extra={
      <div className=" flex flex-col items-center justify-center space-y-4">
        <PageTitle title="An Error Occurred" />
        <Button label="Close" onClick={useClosedTradeForm().onClose} />
      </div>
    }
  />
)
const SavingStatus = () => (
  <div className="m-auto flex flex-col items-center mt-24 justify-center">
    <div className="flex flex-col h-32 justify-center">
      <Spin size="large" />
    </div>
    <PageTitle title="Your trade is saving." />
  </div>
)

export const ClosedTradeFormConfirmationPage = () => {
  const { saveStatus } = useClosedTradeForm()
  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={saveStatus}
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 10, opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        {saveStatus === "saved" ? <SavedStatus /> : null}
        {saveStatus === "error" ? <ErrorStatus /> : null}
        {saveStatus === "saving" ? <SavingStatus /> : null}
      </motion.div>
    </AnimatePresence>
  )
}
