import { wrapIcon } from "./IconWrapper"

export const GmailIcon = wrapIcon(
  <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.25281 11.8624H3.73859V5.82548L0.1875 3.1622V10.7971C0.1875 11.3866 0.665156 11.8624 1.25281 11.8624Z"
      fill="#4285F4"
    />
    <path
      d="M12.2614 11.8624H14.7472C15.3367 11.8624 15.8125 11.3848 15.8125 10.7971V3.16211L12.2614 5.82547"
      fill="#34A853"
    />
    <path
      d="M12.2614 1.20898V5.82547L15.8125 3.16219V1.74156C15.8125 0.424139 14.3086 -0.326954 13.2557 0.463202"
      fill="#FBBC04"
    />
    <path
      d="M3.73865 5.82545V1.20905L7.99998 4.40491L12.2613 1.20889V5.82538L7.99998 9.02147"
      fill="#EA4335"
    />
    <path
      d="M0.1875 1.74168V3.16215L3.73859 5.82551V1.20911L2.7443 0.463324C1.68961 -0.326832 0.1875 0.424261 0.1875 1.74168Z"
      fill="#C5221F"
    />
  </svg>
)
