import { useDrawer } from "@components/Drawer/Drawer"
import { Popover, Tooltip } from "@stories/components/Antd"
import { Button } from "@stories/components/Button/Button"
import { Color } from "@stories/components/Typography/Typography"
import { ClockAlertIcon } from "@stories/icons/ClockAlertIcon"
import { ClockIcon } from "@stories/icons/ClockIcon"
import { isLoaded } from "common/utils/Loading"
import { useAccountActivityLog } from "src/providers/data/AccountActivityLogProvider"
import FeatureWrapper from "src/providers/featureFlags/FeatureWrapper"
import AccountActivityLogDrawer from "./AccountActivityLogDrawer"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import NewIcon from "@stories/components/Badges/NewIcon/NewIcon"
import { add, isBefore } from "date-fns"

export const AccountActivityLogButton = () => {
  const { activityLog, unreadCount } = useAccountActivityLog()
  const { open, openDrawer, closeDrawer } = useDrawer()
  const user = useCurrentUser()

  const loading = !isLoaded(activityLog) || !isLoaded(unreadCount) || !isLoaded(user)
  const loaded = !loading

  const hasNotViewedActivityLog =
    !open && loaded && !user.user.productInteractionHistory.lastOpenedActivityLog

  if (loading) return null

  if (loaded) {
    const shouldRenderAlertIndicator = unreadCount > 0

    const button = (
      <Button
        id="activity-log"
        variant="hollow"
        renderRawIcons
        leftIcon={
          shouldRenderAlertIndicator ? (
            <ClockAlertIcon color={Color.Link} size="large" />
          ) : (
            <ClockIcon color={Color.Black} size="large" />
          )
        }
        onClick={openDrawer}
      />
    )

    const date = new Date()
    const showNewIcon = isBefore(date, new Date("2024-03-01"))
    const isUserRecentlyOnboarded =
      !user.user.termsOfServiceAcceptance?.latestTermsOfServiceAcceptedAt ||
      isBefore(
        user.user.termsOfServiceAcceptance.latestTermsOfServiceAcceptedAt,
        add(new Date(), { days: 2 })
      )
    const atLeast2EventsExist =
      activityLog.filter((event) => event.user.id !== user.user.id).length > 2

    let wrappedButton = <></>
    if (hasNotViewedActivityLog && !isUserRecentlyOnboarded && atLeast2EventsExist) {
      wrappedButton = (
        <Popover
          overlayInnerStyle={{
            backgroundColor: "#1F2937",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.35)",
          }}
          color="#1F2937"
          content={
            <div className="flex gap-2 text-white">
              {showNewIcon ? <NewIcon /> : null}
              <div>View your team's activity in your Activity Log</div>
            </div>
          }
          placement="bottom"
          open={true}
        >
          {button}
        </Popover>
      )
    } else {
      wrappedButton = (
        <Tooltip title="Activity Log" placement="bottom">
          {button}
        </Tooltip>
      )
    }

    return (
      <FeatureWrapper flagName="account_activity_log">
        {wrappedButton}
        <AccountActivityLogDrawer open={open} closeDrawer={closeDrawer} events={activityLog} />
      </FeatureWrapper>
    )
  }

  return null
}
