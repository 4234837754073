import { SelectOptions } from "@stories/components/Inputs/RadioSelect/RadioSelect"
import SectionLabel from "@stories/components/Inputs/SectionLabel/SectionLabel"
import { FormOrder, FormOrderErrors } from "common/model/Order/OrderForm/State"
import { isIntermediaryContext, useAuthContext } from "src/Routes/auth/UserAuthContext"
import { ClientRelationshipSelect } from "./ClientRelationshipSelect"
import CRMContactSection from "../CRMContactSection/CRMContactSection"

type OrderClientSectionData = Pick<
  FormOrder,
  "direction" | "clientRelationship" | "crmContactId" | "crmInterestId"
>
export const OrderClientSection = ({
  value,
  onChange,
  hiddenFields,
  title = "Client",
  titles,
  options,
  formErrors,
}: {
  value: Partial<OrderClientSectionData>
  onChange: (value: Partial<OrderClientSectionData>) => void
  title?: string
  hiddenFields?: string[]
  titles: {
    clientRelationship: string
  }
  options?: {
    clientRelationship?: SelectOptions<OrderClientSectionData["clientRelationship"]>[]
  }
  formErrors: FormOrderErrors
}) =>
  isIntermediaryContext(useAuthContext()) ? (
    <div className="flex flex-col gap-4">
      {hiddenFields?.includes("clientRelationship") ? null : (
        <>
          <SectionLabel>{title}</SectionLabel>
          <CRMContactSection value={value} onChange={onChange} />
          <ClientRelationshipSelect
            value={value.clientRelationship}
            onChange={(clientRelationship) => onChange({ ...value, clientRelationship })}
            label={titles.clientRelationship}
            options={options?.clientRelationship}
            disabled={hiddenFields?.includes("clientRelationship")}
            hasError={formErrors.has("clientRelationship")}
          />
        </>
      )}
    </div>
  ) : null
