import Typography, { Size } from "@stories/components/Typography/Typography"
import { Switch } from "@headlessui/react"
import { Fragment } from "react"
import { classNames } from "src/utils/classNames"
import InfoTooltip from "@components/InfoTooltip"

export type SwitchFilterProps = {
  name?: string
  active: boolean
  toggle: () => void
  tooltipText?: {
    activeText: string
    inactiveText: string
  }
  disabled?: boolean
}

// TODO: move switch to its own story
const SwitchFilter = ({
  name,
  active,
  toggle,
  tooltipText,
  disabled = false,
}: SwitchFilterProps) => (
  <button
    type="button"
    onClick={toggle}
    className={classNames(
      "flex whitespace-nowrap gap-2 items-center",
      disabled ? "cursor-not-allowed" : "cursor-pointer"
    )}
    disabled={disabled}
  >
    <Switch checked={active} as={Fragment}>
      <div className="group relative inline-flex h-3 w-5 flex-shrink-0 items-center justify-center rounded-full">
        <span
          aria-hidden="true"
          className={classNames(
            disabled
              ? "bg-neutral-300 border-neutral-600"
              : active
              ? "bg-accent-500 border-accent-500"
              : "bg-white border-neutral-800",
            "pointer-events-none absolute mx-auto h-3 w-5 rounded-full border-2 transition-colors duration-200 ease-in-out"
          )}
        />
        <span
          aria-hidden="true"
          className={classNames(
            disabled ? "bg-neutral-600" : active ? "bg-white" : "bg-neutral-800",
            active ? "translate-x-3" : "translate-x-1",
            "pointer-events-none absolute left-0 inline-block h-1 w-1 transform rounded-full transition-all duration-200 ease-in-out"
          )}
        />
      </div>
    </Switch>
    <Typography text={name} size={Size.Small} shouldWrap={false} />
    {tooltipText ? (
      <InfoTooltip
        className="w-80"
        text={active ? tooltipText.activeText : tooltipText.inactiveText}
      />
    ) : null}
  </button>
)

export default SwitchFilter
