import { Link } from "react-router-dom"
import { classNames } from "../../../../utils/classNames"
import TopBrokerIcon from "../../../icons/TopBrokerIcon"
import { Tooltip } from "../../Antd"
import Typography, { Color, Size, Weight } from "../../Typography/Typography"

type TopBrokerPillSize = "default" | "minimal" | "small"

type TopBrokerPillProps = { size?: TopBrokerPillSize; showTooltip?: boolean }

const sizeToTextSize: Record<TopBrokerPillSize, Size> = {
  default: Size.Small,
  small: Size.XSmall,
  minimal: Size.XXSmall,
}

const sizeToPadding: Record<TopBrokerPillSize, string> = {
  default: "py-0.5 px-2",
  small: "py-0.5 px-1",
  minimal: "py-0.5 px-1",
}

export const TopBrokerTooltip = ({ size }: Required<Pick<TopBrokerPillProps, "size">>) => (
  <div className="p-1 flex flex-col space-y-1 max-w-xs">
    <Typography
      color={Color.White}
      text="Top Broker"
      weight={Weight.Bold}
      size={sizeToTextSize[size]}
    />
    <Typography
      color={Color.White}
      text="This order is managed by a Top Broker."
      size={sizeToTextSize[size]}
    />
    <span className="flex justify-center font-semibold">
      <Link
        id="top-broker-learn-more"
        to={`${process.env.PUBLIC_URL}/assets/caplight-top-broker-program.pdf`}
        target="_blank"
        onClick={(e) => e.stopPropagation()}
      >
        Learn More
      </Link>
    </span>
  </div>
)

export const TopBrokerPill = ({ size = "default", showTooltip = false }: TopBrokerPillProps) => (
  <Tooltip title={showTooltip ? <TopBrokerTooltip size={size} /> : null} mouseLeaveDelay={0.5}>
    <div
      className={classNames(
        sizeToPadding[size],
        "border border-primary-500 bg-primary-400 rounded-md text-center inline-block max-w-max"
      )}
    >
      <Typography
        color={Color.White}
        text={
          <span className="flex gap-1 items-center">
            <TopBrokerIcon size={size === "default" ? "medium" : "small"} />
            <span>Top Broker</span>
          </span>
        }
        weight={Weight.Semibold}
        size={sizeToTextSize[size]}
        shouldWrap={false}
      />
    </div>
  </Tooltip>
)
