import { ContactsTableTabsType } from "./DealCRM/DealCRMContact"
import { TableColumnState } from "./Settings/TableSettings"

export type CRMAllDealsViewOption = "table" | "kanban"

export type CRMAllDealsViewSortByOption = "createdAt" | "updatedAt" | "closingDate" | "company"

export const contactsTableTabSettings: {
  [tab in ContactsTableTabsType]: keyof UserCRMSettings
} = {
  all: "allContactsTable",
  firms: "allContactsTableFirms",
  individuals: "allContactsTableIndividuals",
}

export interface UserCRMSettings {
  deals?: {
    allDealsView?: CRMAllDealsViewOption
    allDealsViewSortBy?: CRMAllDealsViewSortByOption
  }
  contacts?: {
    showFundContacts?: boolean
  }
  allInterestTable?: TableColumnState
  allContactsTable?: TableColumnState
  allContactsTableFirms?: TableColumnState
  allContactsTableIndividuals?: TableColumnState
}
