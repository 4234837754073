import { FC, useState } from "react"
import { Collapse } from "@stories/components/Antd"
import { RightOutlined, DownOutlined } from "@ant-design/icons"
import { classNames } from "../../utils/classNames"
import { Highlight } from "../typography/Title"

const { Panel } = Collapse

interface AccordionProps {
  header: JSX.Element | string
  headerClassName?: string
  content: JSX.Element | undefined | false
  openControls?: { open: boolean; setOpen: (val: boolean) => void }
  className?: string
  rightAddon?: JSX.Element
  subheader?: string
  invisibleWhenClosed?: boolean
}
export const SubtleAccordion: FC<React.PropsWithChildren<AccordionProps>> = ({
  header,
  content,
  openControls,
  className,
  rightAddon,
  subheader,
  invisibleWhenClosed,
}) => {
  const [localOpen, setLocalOpen] = useState(false)
  const arrowStyle = "text-xs text-neutral-800 float-right"

  const openCloseCallback = () => {
    if (openControls) {
      openControls.setOpen(!openControls.open)
    } else {
      setLocalOpen(!localOpen)
    }
  }

  const getIsOpen = () => {
    if (openControls) return openControls.open
    return localOpen
  }

  const rightSymbol = () => {
    if (invisibleWhenClosed) return null
    if (rightAddon !== undefined) return getIsOpen() ? <div /> : rightAddon
    return getIsOpen() ? (
      <DownOutlined className={arrowStyle} />
    ) : (
      <RightOutlined className={arrowStyle} />
    )
  }

  return (
    <Collapse
      ghost
      activeKey={getIsOpen() ? ["1"] : undefined}
      className={classNames(
        getIsOpen() ? "bg-neutral-white" : "h-8 flex items-center",
        "w-full",
        className
      )}
      onChange={openCloseCallback}
    >
      <Panel
        showArrow={false}
        header={
          <div className="w-full flex flex-col">
            <div className="flex items-center cursor-pointer space-x-2 w-full">
              {header}
              <div className="flex-none" />
              <div
                className={
                  invisibleWhenClosed
                    ? "flex-1 grow self-end mb-2"
                    : "flex-1 grow border-b border-dashed self-end mb-2"
                }
              />
              {rightSymbol()}
            </div>
            <div className="flex items-center text-xs text-neutral-600">{subheader}</div>
          </div>
        }
        key="1"
      >
        <div className={invisibleWhenClosed ? "-mt-12" : ""}>{content}</div>
      </Panel>
    </Collapse>
  )
}

export const SectionHeaderAccordion: FC<React.PropsWithChildren<AccordionProps>> = ({
  header,
  headerClassName,
  content,
  openControls,
  className,
  rightAddon,
  subheader,
}) => {
  const [localOpen, setLocalOpen] = useState(!!openControls?.open)
  const arrowStyle = "text-lg text-neutral-700 float-right font-bold"

  const openCloseCallback = () => {
    if (openControls) {
      openControls.setOpen(!openControls.open)
    } else {
      setLocalOpen(!localOpen)
    }
  }

  const getIsOpen = () => {
    if (openControls) return openControls.open
    return localOpen
  }

  const rightSymbol = () => {
    if (rightAddon !== undefined) return getIsOpen() ? <div /> : rightAddon
    return getIsOpen() ? (
      <DownOutlined className={arrowStyle} />
    ) : (
      <RightOutlined className={arrowStyle} />
    )
  }

  return (
    <Collapse
      activeKey={getIsOpen() ? ["1"] : undefined}
      className={classNames(className)}
      onChange={openCloseCallback}
      ghost
    >
      <Panel
        showArrow={false}
        header={
          <div className="w-full flex flex-col">
            <div className="flex items-center cursor-pointer space-x-2 w-full ">
              <Highlight className={classNames("text-lg", headerClassName)}>{header}</Highlight>
              <div className="flex-none" />
              <div className="flex-1 grow self-end mb-2" />
              {rightSymbol()}
            </div>
            <div className="flex items-center text-xs text-neutral-600 pt-2 w-1/2">{subheader}</div>
          </div>
        }
        key="1"
      >
        {content}
      </Panel>
    </Collapse>
  )
}
