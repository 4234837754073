import { Company, CompanyWithPostgres, companyInPostgres, companyPrism } from "common/model/Company"
import { DBQuery } from "common/utils/Collection"
import { firestoreConverter } from "common/model/FirestoreConverter"
import { collections } from "common/firestore/Collections"
import { FirebaseReader } from "../../Firebase"
import { FirebaseId, getDocData } from "../utils"
import { RollupPeriod } from "common/model/CompanyMarketHistorySummary"
import _ from "lodash"

const companyConverter = firestoreConverter<Company>()

export const companies = (db: FirebaseReader) =>
  db.db.collection(collections.companies).withConverter(companyConverter)
export const companiesQuery = (db: FirebaseReader, limit?: number) =>
  new DBQuery(
    db.db,
    db.db.collection(collections.companies).withConverter(companyConverter),
    companyPrism,
    limit
  )

export const getAllCompanies = (db: FirebaseReader): Promise<Company[]> =>
  getDocData(companies(db).orderBy("name"))

export const getAllTargetCompanies = (db: FirebaseReader) =>
  companiesQuery(db)
    .where("status", "==", "Private")
    .where("showOnCompaniesTable", "==", true)
    .orderBy("score", "desc")
    .orderBy("fundingRoundsSummary.lastKnownValuation.valuation", "desc")
    .get()
    .then((xs) =>
      xs
        .flatMap((x) => (x.isRight() ? [x.value.value] : []))
        .flatMap((x) => (companyInPostgres(x) ? [x] : []))
    )

export const getCompanyById = (db: FirebaseReader) => (companyId: FirebaseId) =>
  companies(db)
    .doc(companyId)
    .get()
    .then((result) => result.data())

export const getCaplightDataCompanies = (db: FirebaseReader) =>
  companies(db).orderBy("lastTrade", "desc")

/** This function is a bit of a hack bc we are missing total orders, but otherwise it comes close */
export const getMostActiveCompaniesInSector =
  (db: FirebaseReader) =>
  async (
    sector: string,
    maxNumCompanies: number,
    extraFilter: ((company: CompanyWithPostgres) => boolean) | undefined
  ) => {
    const rollupPeriodToUse: RollupPeriod = "past180Days" // you can change it here, but will need to create an index
    const limit = maxNumCompanies * 2 // request 2x to arrive at a better estimate of total orders (=bids+offers)
    // TODO: add totalOrders into company market history agg object
    const mostBids = await companiesQuery(db, limit)
      .where("industry.caplightPrimaryIndustry", "==", sector)
      .orderBy(`marketHistorySummary.${rollupPeriodToUse}.bidHistoryRollup.orderCount`, "desc")
      .get()
      .then((xs) =>
        xs
          .flatMap((x) => (x.isRight() ? [x.value.value] : []))
          .flatMap((x) => (companyInPostgres(x) ? [x] : []))
      )
    const mostAsks = await companiesQuery(db, limit)
      .where("industry.caplightPrimaryIndustry", "==", sector)
      .orderBy(`marketHistorySummary.${rollupPeriodToUse}.offerHistoryRollup.orderCount`, "desc")
      .get()
      .then((xs) =>
        xs
          .flatMap((x) => (x.isRight() ? [x.value.value] : []))
          .flatMap((x) => (companyInPostgres(x) ? [x] : []))
      )
    const bestCompanies: CompanyWithPostgres[] = _.orderBy(
      _.uniqBy([...mostBids, ...mostAsks], (c) => c.id)
        .map((comp) => ({
          company: comp,
          totalOrders:
            (comp.marketHistorySummary?.[rollupPeriodToUse]?.bidHistoryRollup?.orderCount ?? 0) +
            (comp.marketHistorySummary?.[rollupPeriodToUse]?.offerHistoryRollup?.orderCount ?? 0),
        }))
        .filter((c) => c.totalOrders > 0 && (!extraFilter || extraFilter(c.company))),
      (c) => c.totalOrders,
      "desc"
    )
      .slice(0, maxNumCompanies)
      .map((c) => c.company)

    return bestCompanies
  }
