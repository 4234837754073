/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/naming-convention */
import { ExclamationCircleIcon } from "@heroicons/react/solid"
import { omit } from "lodash"
import React, { FC, LegacyRef, ReactElement, useLayoutEffect, useRef, useState } from "react"
import { FieldInputProps, FieldMetaState } from "react-final-form"
import { classNames } from "../../utils/classNames"

interface TextInputFieldProps {
  autoComplete?: string
  autoFocus?: boolean
  label?: string
  fullWidth?: boolean
  forwardRef?: LegacyRef<HTMLInputElement>
  leftAddon?: ReactElement
  rightAddon?: ReactElement
  disabled?: boolean
  placeholder?: string
  meta: {
    error?: string
  } & Omit<FieldMetaState<string>, "error">
  labelClassName?: string
  input: FieldInputProps<string>
  optional?: boolean
}

export const TextInputField: FC<React.PropsWithChildren<TextInputFieldProps>> = (props) => {
  const error = props.meta.error && props.meta.touched ? props.meta.error : undefined

  return (
    <TextInput
      autoComplete={props.autoComplete}
      label={props.label}
      labelClassName={props.labelClassName}
      fullWidth={props.fullWidth}
      autoFocus={props.autoFocus}
      forwardRef={props.forwardRef}
      {...props.input}
      value={props.input.value}
      optional={props.optional}
      error={error}
      leftAddon={props.leftAddon}
      rightAddon={props.rightAddon}
      disabled={props.disabled}
      placeholder={props.placeholder}
    />
  )
}

interface NumberInputFieldProps {
  className?: string
  inputClassName?: string
  labelClassName?: string
  autoComplete?: string
  autoFocus?: boolean
  label?: string
  inlineLabel?: boolean
  fullWidth?: boolean
  forwardRef?: LegacyRef<HTMLInputElement>
  leftAddon?: ReactElement
  rightAddon?: ReactElement
  disabled?: boolean
  meta: {
    error?: string
  } & Omit<FieldMetaState<number>, "error">
  input: FieldInputProps<number>
  optional?: boolean
}
export const NumberInputField: FC<React.PropsWithChildren<NumberInputFieldProps>> = (props) => {
  const error = props.meta.error && props.meta.touched ? props.meta.error : undefined
  const value = Number.isNaN(Number(props.input.value)) ? "" : props.input.value // Number(props.input.value) used to preserve the existing behavior because I don't trust the rest of the codebase not to lie about the props

  return (
    <TextInput
      className={props.className}
      inputClassName={props.inputClassName}
      autoComplete={props.autoComplete}
      label={props.label}
      labelClassName={props.labelClassName}
      fullWidth={props.fullWidth}
      inlineLabel={props.inlineLabel}
      autoFocus={props.autoFocus}
      forwardRef={props.forwardRef}
      type="number"
      {...props.input}
      leftAddon={props.leftAddon}
      rightAddon={props.rightAddon}
      optional={props.optional}
      value={value}
      error={error}
      disabled={props.disabled}
    />
  )
}

export interface TextInputProps {
  name: string
  type?: string
  value: string | number
  required?: boolean
  disabled?: boolean
  autoFocus?: boolean
  autoComplete?: string
  forwardRef?: LegacyRef<HTMLInputElement>
  label?: string
  inlineLabel?: boolean
  labelClassName?: string
  placeholder?: string
  className?: string
  inputClassName?: string
  fullWidth?: boolean
  error?: string
  onBlur?: (event?: React.FocusEvent<HTMLElement>) => void
  onChange?: (event: React.ChangeEvent<{ value: string }>) => void
  onFocus?: (event?: React.FocusEvent<HTMLElement>) => void
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
  onSubmit?: React.FormEventHandler<HTMLInputElement>
  rightAddon?: ReactElement
  leftAddon?: ReactElement
  optional?: boolean
}

export const InputError = ({ error }: { error: string }) => (
  <p className="mt-1 ml-1 -mb-2 text-xs text-danger-600" id="email-error">
    {error}
  </p>
)

export const TextInput: FC<React.PropsWithChildren<TextInputProps>> = (props) => (
  <div className={classNames("flex flex-col", props.fullWidth ? "w-full" : "")}>
    <div
      className={classNames(
        props.className,
        props.inlineLabel ? "flex items-center" : "",
        props.rightAddon && props.error ? "mt-4" : ""
      )}
    >
      {props.label && (
        <label
          htmlFor={props.name}
          className={classNames(
            props.disabled ? "text-neutral-400" : "",
            props.inlineLabel ? "inline text-black mr-4" : "block w-full text-neutral-1000",
            props.labelClassName || "text-base font-normal",
            "whitespace-nowrap"
          )}
        >
          {props.label}
          {props.optional && <span className="text-sm pl-4 right-0 relative">(optional)</span>}
        </label>
      )}
      <div className={classNames("flex items-center", props.fullWidth ? "w-full" : "")}>
        <div className={classNames("mt-1 relative ", props.fullWidth ? "w-full" : "")}>
          {props.leftAddon && (
            <span className="absolute inset-y-0 pl-2 mr-2 left-0 flex items-center">
              {props.leftAddon}
            </span>
          )}
          <input
            {...omit(
              props,
              "forwardRef",
              "optional",
              "rightAddon",
              "leftAddon",
              "fullWidth",
              "inputClassName",
              "labelClassName",
              "inlineLabel"
            )}
            id={props.name}
            ref={props.forwardRef}
            name={props.name}
            type={props.type}
            autoFocus={props.autoFocus}
            onSubmit={props.onSubmit}
            autoComplete={props.autoComplete}
            required={props.required}
            placeholder={props.placeholder}
            onKeyDown={props.onKeyDown}
            className={classNames(
              props.disabled ? "bg-neutral-100" : "",
              props.inputClassName,
              props.leftAddon ? "pl-5" : "",
              props.rightAddon ? "pr-5" : "",
              props.fullWidth ? "w-full py-3" : "",
              "appearance-none block px-3 py-2 border border-neutral-400 rounded-md shadow-sm placeholder-neutral-600 focus:outline-none focus:ring-neutral-700 focus:border-neutral-700 sm:text-sm",
              props.error
                ? "border-danger-600 text-danger-900 placeholder-danger-300 focus:outline-none focus:ring-danger-600 focus:border-danger-600"
                : ""
            )}
          />
          {props.error && !props.rightAddon && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ExclamationCircleIcon className="h-5 w-5 text-danger-600" aria-hidden="true" />
            </div>
          )}
          {props.rightAddon && (
            <span className="absolute inset-y-0 pr-2 right-0 flex items-center">
              {props.rightAddon}
            </span>
          )}
        </div>
      </div>
      {props.error && !props.rightAddon && <InputError error={props.error} />}
    </div>
    {props.error && props.rightAddon && (
      <p className="self-end mt-1 leading-none text-xs text-danger-600" id="email-error">
        {props.error}
      </p>
    )}
  </div>
)

// TODO: In test mode, testing on the portfolio table.
export const _NumberInputField: FC<React.PropsWithChildren<NumberInputFieldProps>> = (props) => {
  const error = props.meta.error && props.meta.touched ? props.meta.error : undefined
  const value = Number.isNaN(Number(props.input.value)) ? "" : props.input.value // Number(props.input.value) used to preserve the existing behavior because I don't trust the rest of the codebase not to lie about the props

  return (
    <TextInput
      className={props.className}
      inputClassName={props.inputClassName}
      autoComplete={props.autoComplete}
      label={props.label}
      labelClassName={props.labelClassName}
      fullWidth={props.fullWidth}
      inlineLabel={props.inlineLabel}
      autoFocus={props.autoFocus}
      forwardRef={props.forwardRef}
      type="number"
      {...props.input}
      leftAddon={props.leftAddon}
      rightAddon={props.rightAddon}
      optional={props.optional}
      value={value}
      error={error}
      disabled={props.disabled}
    />
  )
}

export const _InputError = ({ error }: { error: string }) => (
  <p className="mt-1 ml-1 -mb-2 text-xs text-danger-600">{error}</p>
)

interface _TextInputProps extends TextInputProps {
  leftAddonClassName?: string
  rightAddonClassName?: string
}
export const _TextInput: FC<React.PropsWithChildren<_TextInputProps>> = (props) => {
  const leftRef = useRef<HTMLSpanElement>(null)
  const rightRef = useRef<HTMLSpanElement>(null)

  const [leftAddonWidth, setLeftAddonWidth] = useState(0)
  const [rightAddonWidth, setRightAddonWidth] = useState(0)

  useLayoutEffect(() => {
    if (leftRef.current) {
      setLeftAddonWidth(leftRef.current.offsetWidth)
    }

    if (rightRef.current) {
      setRightAddonWidth(rightRef.current.offsetWidth)
    }
  }, [leftRef, rightRef])

  return (
    <div className="flex flex-col">
      <div
        className={classNames(
          props.className,
          props.inlineLabel ? "flex items-center" : "",
          props.rightAddon && props.error ? "mt-4" : ""
        )}
      >
        {props.label && (
          <label
            htmlFor={props.name}
            className={classNames(
              props.disabled ? "text-neutral-400" : "",
              props.inlineLabel ? "inline text-black mr-4" : "block w-full text-neutral-1000",
              props.labelClassName || "text-base font-normal",
              "whitespace-nowrap"
            )}
          >
            {props.label}
            {props.optional && <span className="text-sm pl-4 right-0 relative">(optional)</span>}
          </label>
        )}
        <div className={classNames("flex items-center")}>
          <div className={classNames("relative flex flex-row")}>
            {props.leftAddon && (
              <span
                ref={leftRef}
                className={classNames(
                  "absolute inset-y-0 pl-2 flex items-center",
                  props.leftAddonClassName || ""
                )}
              >
                {props.leftAddon}
              </span>
            )}
            <input
              {...omit(
                props,
                "forwardRef",
                "optional",
                "rightAddon",
                "leftAddon",
                "inputClassName",
                "labelClassName",
                "inlineLabel",
                "leftAddonClassName",
                "rightAddonClassName"
              )}
              id={props.name}
              ref={props.forwardRef}
              name={props.name}
              type={props.type}
              autoFocus={props.autoFocus}
              autoComplete={props.autoComplete}
              required={props.required}
              placeholder={props.placeholder}
              style={{
                paddingLeft: props.leftAddon ? leftAddonWidth + 8 : 8,
                paddingRight: props.rightAddon ? rightAddonWidth + 8 : 8,
              }}
              className={classNames(
                props.inputClassName || "px-3 py-2 sm:text-sm",
                props.disabled ? "bg-neutral-100" : "",
                "block border border-neutral-400 rounded-md shadow-sm focus:outline-none placeholder-neutral-600 focus:ring-neutral-700 focus:border-neutral-700",
                props.error
                  ? "border-danger-600 text-danger-900 placeholder-danger-300 focus:outline-none focus:ring-danger-600 focus:border-danger-600"
                  : ""
              )}
            />
            {props.error && !props.rightAddon && (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon className="h-5 w-5 text-danger-600" aria-hidden="true" />
              </div>
            )}
            {props.rightAddon && (
              <span
                ref={rightRef}
                className={classNames(
                  "absolute inset-y-0 right-2 flex items-center",
                  props.rightAddonClassName || ""
                )}
              >
                {props.rightAddon}
              </span>
            )}
          </div>
        </div>
        {props.error && !props.rightAddon && <InputError error={props.error} />}
      </div>
      {props.error && props.rightAddon && (
        <p className="self-end mt-1 leading-none text-xs text-danger-600">{props.error}</p>
      )}
    </div>
  )
}
