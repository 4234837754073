import { OrderMetricsResponse } from "common/model/OrderMetrics/OrderMetricsResponse"
import { Loading_, isLoaded } from "common/utils/Loading"
import { nullableToMaybe } from "common/containers/Maybe"
import { APIEndpoint, APIEndpoints, useAPIQuery } from "../../../../firebase/API"
import { useCurrentUser } from "../../../../providers/currentUser/useCurrentUser"
import { OrderPageOrder } from "../types"
import OrderScoreCardMetric from "./OrderScorecardMetric"
import { OrderIdFields } from "common/model/Order/Order"
import { getCollectionForOrder } from "src/firebase/orders"
import { assertUnreachable } from "common/utils/fp/Function"

const getOrderMetricsQuery = (orderIdFields: OrderIdFields) => {
  const collectionName = getCollectionForOrder(orderIdFields)
  switch (collectionName) {
    case "order_observations": {
      return APIEndpoints.orderMetrics.build(orderIdFields.id)
    }
    case "tentative_interest": {
      return APIEndpoints.tentativeInterestMetrics.build(orderIdFields.id)
    }
    case "darkpool_order_observations": {
      return null
    }
    case null: {
      return null
    }
    default: {
      return assertUnreachable(collectionName)
    }
  }
}

const AuthedOrderScorecard = ({
  orderMetricsQuery,
}: {
  orderMetricsQuery: APIEndpoint
}): React.ReactNode => {
  const orderMetricsResult = useAPIQuery<OrderMetricsResponse>(orderMetricsQuery)

  return Loading_.toMaybe(orderMetricsResult)
    .bind((r) => nullableToMaybe(r.result))
    .match(
      ({ totalViewCount, inquiryCount, connectCount }) => (
        <div className="grid grid-cols-3 gap-2">
          <OrderScoreCardMetric label="Views" value={totalViewCount} />
          <OrderScoreCardMetric label="Inquiries" value={inquiryCount} />
          <OrderScoreCardMetric label="Connects" value={connectCount} />
        </div>
      ),
      () => null
    )
}

const LoadedOrderScorecard = ({ order }: { order: OrderPageOrder }) => {
  const currentUser = useCurrentUser()
  const orderMetricsQuery = getOrderMetricsQuery(order.toInternal())

  if (
    !orderMetricsQuery ||
    !isLoaded(currentUser) ||
    !order.fromAccount(currentUser.user.account) ||
    order.sourceAttribution(currentUser.user).match(
      () => true,
      () => false
    ) ||
    order.isDarkpool()
  ) {
    return null
  }

  return <AuthedOrderScorecard orderMetricsQuery={orderMetricsQuery} />
}

export default LoadedOrderScorecard
