import { useModal } from "@components/layout/Modal"
import { DealCRMBrokerContact } from "common/model/DealCRM/DealCRMBrokerContact"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import {
  DealCRMFirmContact,
  viewDealCRMFirmIdFields,
} from "common/model/DealCRM/DealCRMFirmContact"
import { DealCRMIndividualContact } from "common/model/DealCRM/DealCRMIndividualContact"
import { useFirebaseReader } from "src/firebase/Context"
import { updateIndividualContactFirm } from "src/firebase/crm"
import { SelectContactModal } from "../../Components/SelectContactModal"

interface ContactFirmSelectorProps<T extends DealCRMBrokerContact | DealCRMIndividualContact> {
  renderButton: (onClick: () => void) => React.ReactNode
  contact: T
  onFirmSelected: (firm: DealCRMFirmContact | undefined) => unknown
  sourceContact?: DealCRMContact
}

export const ContactFirmSelector = <T extends DealCRMBrokerContact | DealCRMIndividualContact>({
  renderButton,
  contact,
  onFirmSelected,
  sourceContact,
}: ContactFirmSelectorProps<T>) => {
  const db = useFirebaseReader()
  const { open, openModal, closeModal } = useModal()

  const handleContactSelected = async (selectedFirm: DealCRMContact) => {
    if (selectedFirm.tag === "firm") {
      if (contact.id.length) {
        await updateIndividualContactFirm({
          db,
          contact,
          firm: viewDealCRMFirmIdFields(selectedFirm),
        })
      } else {
        onFirmSelected(selectedFirm)
      }
      closeModal()
    }
  }

  return (
    <>
      {renderButton(openModal)}

      {open ? (
        <SelectContactModal
          hideIndividuals
          onContactSelected={handleContactSelected}
          sourceContactTag={contact.tag}
          open={open}
          handleClose={closeModal}
          sourceContact={sourceContact}
          contactFilter={
            contact.tag === "broker"
              ? (firm) => firm.tag === "firm" && !!firm.isBrokerage
              : contact.tag === "individual"
              ? (firm) => firm.tag === "firm" && !firm.isBrokerage
              : undefined
          }
        />
      ) : null}
    </>
  )
}
