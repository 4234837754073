import { annotate } from "common/utils/Coerce"
import { deprecatedIsLoaded, Loading } from "common/utils/Loading"
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useRef } from "react"
import { CollectionReference, getAllDocs, inQuery } from "src/firebase/Firebase/utils"
import { useParameterizedAction } from "src/utils/hooks/state/useAction"
import { useUpsert } from "src/utils/hooks/state/useUpsert"
import {
  DocumentIdContainer,
  FilledContainer,
  lookupWithStringContainer,
  stringContainerValues,
} from "./internal/DocumentIdContainer"
import { handleConsoleError } from "src/utils/Tracking"
import { FieldPath } from "src/firebase/Firebase"

export type DocumentContextData<T> = {
  documents: Partial<Record<string, T | "loading">>
  requestDocLookups: (ids: string[]) => void
}

export const FirebaseDocumentProvider = <T extends { id: string }>({
  Context,
  collection,
  children,
  noSnapshot,
}: {
  Context: React.Context<DocumentContextData<T>>
  collection: CollectionReference<T>
  noSnapshot?: boolean
  children: ReactNode
}) => {
  const [documents, updateDocuments] = useUpsert<Record<string, T | "loading">>({})
  const onUnmount = useRef<(() => void)[]>([])

  // TODO other lookup methods
  const actions = useMemo(
    () => ({
      append: (t: string[]) => (ts: string[]) => [...ts, ...t],
      empty: () => () => [],
    }),
    []
  )
  const [idsPendingLookup, idActions] = useParameterizedAction(annotate<string[]>([]), actions)
  const subscribeToDocument = useCallback(
    (id: string) => {
      // console.log(`subscribing to ${id}`)
      const unsubscribe = collection.doc(id).onSnapshot((data) => {
        // console.log(`fetched ${data.id}`)
        if (data.exists) {
          // console.log(`updating ${data.id}`)
          updateDocuments({ [data.id]: data.data() })
        }
      })
      return () => {
        // console.log(`unsubscribing from ${id}`)
        unsubscribe()
      }
    },
    [collection, updateDocuments]
  )
  const queryDocuments = useCallback(
    (allIds: string[]) => {
      updateDocuments(Object.fromEntries(allIds.map((id) => [id, "loading"])))
      return inQuery(
        (ids) => collection.where(FieldPath.documentId(), "in", ids).get().then(getAllDocs),
        allIds
      )
        .then((results) => updateDocuments(Object.fromEntries(results.map((d) => [d.id, d]))))
        .catch(handleConsoleError)
    },
    [collection, updateDocuments]
  )

  useEffect(() => {
    const idsPendingLookupNoInDocuments = idsPendingLookup.filter((id) => !(id in documents))
    if (idsPendingLookupNoInDocuments.length > 0) {
      if (noSnapshot) {
        queryDocuments(idsPendingLookupNoInDocuments).catch(handleConsoleError)
      } else {
        idsPendingLookupNoInDocuments.forEach((id) => {
          updateDocuments({ [id]: "loading" })
          onUnmount.current.push(subscribeToDocument(id))
        })
      }
      idActions.empty(null)
    }
  }, [
    documents,
    idActions,
    idsPendingLookup,
    noSnapshot,
    queryDocuments,
    subscribeToDocument,
    updateDocuments,
  ])

  useEffect(() => () => onUnmount.current.forEach((f) => f()), [])

  const contextData: DocumentContextData<T> = useMemo(
    () => ({
      documents,
      requestDocLookups: idActions.append,
    }),
    [idActions.append, documents]
  )

  return <Context.Provider value={contextData}>{children}</Context.Provider>
}

export const useFirebaseDocuments = <T, C extends DocumentIdContainer = string[]>(
  ids: Loading<C>,
  context: React.Context<DocumentContextData<T>>
): Loading<FilledContainer<C, T>> => {
  const { documents, requestDocLookups } = useContext(context)
  useEffect(() => {
    if (deprecatedIsLoaded(ids)) {
      requestDocLookups(stringContainerValues(ids))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(ids), requestDocLookups])
  const result = useMemo(
    () =>
      deprecatedIsLoaded(ids)
        ? lookupWithStringContainer((id) => documents?.[id] ?? "loading", ids)
        : ids,
    [documents, ids]
  )
  return result
}
