import {
  AccountTradingRegistration,
  MinimumAccountTradingRegistration,
} from "common/model/Account/AccountTradingRegistration"
import { Loading, matchLoading } from "common/utils/Loading"
import {
  onUpload,
  onUploadSuccess,
} from "src/pages/TradeRegistration/Verification/VerificationPage"
import { Modal, useModal } from "@components/layout/Modal"
import { Button } from "@stories/components/Button/Button"
import { VerificationDocumentsUpload } from "src/pages/TradeRegistration/Verification/VerificationUploader"
import { useState } from "react"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import { useFirebaseWriter } from "src/firebase/Context"
import { UploadFile } from "@stories/components/Antd"
import { handleConsoleError } from "src/utils/Tracking"

export const AdminUploadFiles = ({
  tradingRegistration,
  updateTradingRegistration,
}: {
  tradingRegistration: Loading<
    Partial<AccountTradingRegistration> & MinimumAccountTradingRegistration
  >
  updateTradingRegistration: (
    t: Partial<AccountTradingRegistration> & MinimumAccountTradingRegistration
  ) => Promise<void>
}) => {
  const currentUser = useCurrentUser()
  const db = useFirebaseWriter()
  const { open, openModal, closeModal } = useModal()
  const [uploadedFileList, setUploadedFileList] = useState<UploadFile[]>([])
  const handleUpload = () => {
    onUpload(
      db,
      uploadedFileList,
      tradingRegistration,
      currentUser,
      () =>
        onUploadSuccess(
          uploadedFileList,
          tradingRegistration,
          currentUser,
          updateTradingRegistration
        )
          .then(closeModal)
          .catch(handleConsoleError),
      () => {
        throw new Error("Failed to upload documents")
      }
    )
  }

  const title = `Upload ID and entity formation documents for ${matchLoading(
    tradingRegistration,
    (t) => t.account.name,
    "the account",
    "the account"
  )}`
  return (
    <>
      <Button onClick={openModal} label={title} size="small" />
      <Modal
        open={open}
        closable
        subtitle={title}
        handleClose={closeModal}
        body={
          <>
            <VerificationDocumentsUpload
              files={uploadedFileList}
              onChangeFiles={setUploadedFileList}
            />
            <Button label="Upload" onClick={handleUpload} isFullWidth />
          </>
        }
      />
    </>
  )
}
