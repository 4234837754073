import { CompanyLogoAndName } from "@components/CompanyLogoAndName/CompanyLogoAndName"
import { Tooltip } from "@stories/components/Antd/Tooltip/Tooltip"
import { Button } from "@stories/components/Button/Button"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { RulesIcon } from "@stories/icons/RulesIcon"
import { TrashIcon } from "@stories/icons/TrashIcon"
import { BooleanFormula } from "common/containers/Trees/BooleanFormula"
import {
  ConstructedOpportunityInboxFilterRule,
  OpportunityInboxFilterRule,
  isOpportunityInboxFilterRuleApplicableToDate,
} from "common/model/Order/OrderFilter/OpportunityInboxFilterRule"
import {
  AtomicOrderFilter,
  OrderNumericField,
  numericFieldComparisonDisplay,
} from "common/model/Order/OrderFilter/OrderFilter"
import { commaSeparateWithWord } from "common/utils/StringUtils"
import { relativeDateFormat } from "common/utils/dateUtils"
import { assertUnreachable } from "common/utils/fp/Function"
import { formatAbbreviatedCurrency } from "common/utils/math/format"
import { useState } from "react"
import { structureDisplayName } from "src/pages/Data/IssuerPage/DataTable/DataTableRow"

export const DisplayOrderNumericFilter = ({
  atom,
}: {
  atom: AtomicOrderFilter & { tag: OrderNumericField }
}) => (
  <Typography
    size={Size.Small}
    text={`with ${atom.tag} ${numericFieldComparisonDisplay(
      atom.comparison
    )} ${formatAbbreviatedCurrency(atom.limit)}`}
  />
)

export const DisplayOrderFilterLayer = ({
  layer,
  layerType,
}: {
  layerType: "Not" | "Or" | "And"
  layer: React.ReactNode
}) => (
  <div>
    <div className="w-full flex items-center flex-wrap gap-2">
      <RulesIcon />
      <div className="flex items-center flex-wrap gap-1">
        <Typography size={Size.Small} text="Hide" />
        {layer}
      </div>
    </div>
  </div>
)

export const DisplayAtomicOrderFilter = ({
  atom,
  rule,
}: {
  atom: AtomicOrderFilter
  rule: ConstructedOpportunityInboxFilterRule
}) => {
  if (atom.tag === "company")
    return (
      <Typography size={Size.Small} text={`${rule.tag === "company" ? rule.company.name : ""}`} />
    )
  if (atom.tag === "connectable") return null
  if (atom.tag === "liveMarketIndication") return null
  if (atom.tag === "sharedIndication") return null
  if (atom.tag === "direction")
    return <Typography size={Size.Small} text={`${atom.direction === "buy" ? "Bids" : "Offers"}`} />
  if (atom.tag === "structure")
    return (
      <Typography
        size={Size.Small}
        text={`with structure equal to ${commaSeparateWithWord(
          atom.orderStructure.map((s) => structureDisplayName[s]),
          "or"
        )}`}
      />
    )
  if (atom.tag === "shareClass")
    return <Typography size={Size.Small} text={`with share class equal to ${atom.shareClass}`} />
  if (atom.tag === "price" || atom.tag === "volume")
    return <DisplayOrderNumericFilter atom={atom} />
  if (atom.tag === "origination date") return JSON.stringify(atom)
  if (atom.tag === "field-exists") return null
  return assertUnreachable(atom.tag)
}

export const InnerOrderOpportunityRuleDisplayCard = ({
  rule,
  action,
}: {
  rule: ConstructedOpportunityInboxFilterRule
  action?: React.ReactNode
}) => (
  <div
    className={`border rounded p-4 w-full flex flex-col gap-4 ${
      isOpportunityInboxFilterRuleApplicableToDate(new Date(), rule) ? "" : "border-warning-400"
    }`}
  >
    <div className="flex justify-between">
      {rule.tag === "company" && <CompanyLogoAndName company={rule.company} size="sm" />}
      {rule.tag === "global" && (
        <Typography weight={Weight.Semibold} color={Color.Primary} text="Global Rule" />
      )}
      {action}
    </div>
    <div className="flex flex-col gap-2">
      <div className="flex items-center">
        {BooleanFormula.deserialize(rule.formula).fold(
          (atom) => (
            <DisplayAtomicOrderFilter atom={atom} rule={rule} />
          ),
          (layer) => (
            <DisplayOrderFilterLayer layerType="And" layer={layer} />
          ),
          (layer) => (
            <DisplayOrderFilterLayer layerType="Or" layer={layer} />
          ),
          (layer) => (
            <DisplayOrderFilterLayer layerType="Not" layer={layer} />
          )
        )}
      </div>
      <div className="flex justify-between items-center">
        <div>
          <Typography size={Size.XSmall} text={`Created ${relativeDateFormat(rule.createdAt)}`} />
        </div>
        {rule.expiresAt ? (
          <div>
            <Typography
              size={Size.XSmall}
              text={`${
                isOpportunityInboxFilterRuleApplicableToDate(new Date(), rule)
                  ? `Expires ${relativeDateFormat(rule.expiresAt)}`
                  : `Expired ${relativeDateFormat(
                      rule.expiresAt
                    )} - Not being applied to new opportunities`
              } `}
            />
          </div>
        ) : null}
      </div>
    </div>
  </div>
)

export const OrderOpportunityRuleDisplayCard = ({
  rule,
  onRemove,
}: {
  rule: OpportunityInboxFilterRule
  onRemove?: (r: OpportunityInboxFilterRule) => Promise<void>
}) => {
  const [loading, setLoading] = useState(false)
  return (
    <InnerOrderOpportunityRuleDisplayCard
      rule={rule}
      action={
        onRemove ? (
          <Tooltip title="Delete Rule">
            <Button
              leftIcon={<TrashIcon />}
              variant="hollow"
              onClick={() => {
                setLoading(true)
                onRemove(rule).finally(() => setLoading(false))
              }}
              size="small"
              isLoading={loading}
              id="opportunity-inbox-button-remove-rule"
              heapName="opportunity-inbox-button-remove-rule"
            />
          </Tooltip>
        ) : null
      }
    />
  )
}
