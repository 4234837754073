import { isBoolean } from "lodash"
import { Either, Right, Left } from "../../../containers/Either"
import { isDefined } from "../../../utils/TypeUtils"
import { isNonemptyFromUnknown } from "../../../utils/data/Array/Nonempty"
import { UnionUnder } from "../../../utils/data/Record/Types/Pointwise"
import { BILLION, MILLION, THOUSAND } from "../../../utils/math"
import { IntroductionRequestPriceMatch } from "../../IntroductionRequest"
import { FormOrder, FormOrderErrors, requiredFormOrderKeys } from "./State"
import { Order } from "../Order"
import { StructureOrderTerms } from "../Types/Terms"
import { OrderFormSize, OrderFormState, OrderPrice } from "./State"
import { Interval } from "../../../utils/data/Interval"

export const MINIMUM_ORDER_SIZE = 10 * THOUSAND
export const MINIMUM_ORDER_VALUATION_PRICE = 100 * MILLION
export const MAXIMUM_ORDER_VALUATION_PRICE = 300 * BILLION

export type PriceData = {
  priceMatch: IntroductionRequestPriceMatch
  price: UnionUnder<null, OrderPrice> | null
  size: UnionUnder<null, OrderFormSize>
}
const validateInterval = ({ lowerBound, upperBound }: Interval<number>) =>
  lowerBound > 0 && lowerBound <= upperBound

export const validSize = (size: PriceData["size"] | undefined) =>
  size?.shares
    ? validateInterval(size.shares)
    : size?.amountUSD
    ? validateInterval(size?.amountUSD) && size.amountUSD.lowerBound >= MINIMUM_ORDER_SIZE
    : false
const validFormSize = (partialFormOrder: OrderFormState) => validSize(partialFormOrder.size)
const validateFormValuation = (targetValuation: number | null | undefined) =>
  targetValuation
    ? targetValuation >= MINIMUM_ORDER_VALUATION_PRICE &&
      targetValuation <= MAXIMUM_ORDER_VALUATION_PRICE
    : false
const validFormPriceRequired = (partialFormOrder: OrderFormState) =>
  partialFormOrder.price?.USDPerShare
    ? validateInterval(partialFormOrder.price.USDPerShare)
    : validateFormValuation(partialFormOrder.price?.targetValuation)

const validFormPrice = (partialFormOrder: OrderFormState) =>
  partialFormOrder.price === null || validFormPriceRequired(partialFormOrder)

export const validSizeAndPrice = (
  partialFormOrder: OrderFormState
): partialFormOrder is Partial<FormOrder> & { size: OrderFormSize; price: OrderPrice } =>
  validFormSize(partialFormOrder) && validFormPrice(partialFormOrder)

const validSPVTerms = (partialFormOrder: OrderFormState): boolean =>
  partialFormOrder.structures?.includes("spv") && partialFormOrder.direction === "sell"
    ? isBoolean(partialFormOrder.isMultiLayerSpv)
    : true
const validStructures = (
  partialFormOrder: OrderFormState
): partialFormOrder is Partial<FormOrder> & { structures: (keyof StructureOrderTerms)[] } =>
  isNonemptyFromUnknown(partialFormOrder.structures) && validSPVTerms(partialFormOrder)

const validShareClasses = (
  partialFormOrder: OrderFormState
): partialFormOrder is Partial<FormOrder> & { shareClasses: Order["shareClasses"] } =>
  partialFormOrder.price === null
    ? Array.isArray(partialFormOrder.shareClasses)
    : isNonemptyFromUnknown(partialFormOrder.shareClasses)

const validateBuyerCashOnHand = (partialFormOrder: OrderFormState): boolean =>
  partialFormOrder.direction === "buy" ? !!partialFormOrder.buyerCashOnHand : true

export const validateFormOrder = (
  partialFormOrder: OrderFormState
): Either<FormOrderErrors, FormOrder> =>
  partialFormOrder.account &&
  partialFormOrder.user &&
  partialFormOrder.clientRelationship &&
  partialFormOrder.company &&
  partialFormOrder.direction &&
  partialFormOrder.liveUntil &&
  partialFormOrder.size &&
  validStructures(partialFormOrder) &&
  validShareClasses(partialFormOrder) &&
  validSizeAndPrice(partialFormOrder) &&
  validateBuyerCashOnHand(partialFormOrder)
    ? Right({
        account: partialFormOrder.account,
        user: partialFormOrder.user,
        clientRelationship: partialFormOrder.clientRelationship,
        company: partialFormOrder.company,
        direction: partialFormOrder.direction,
        firmness: partialFormOrder.firmness,
        liveUntil: partialFormOrder.liveUntil,
        price: partialFormOrder.price,
        shareClasses: partialFormOrder.shareClasses,
        shareClassSeries: partialFormOrder.shareClassSeries,
        size: partialFormOrder.size,
        structures: partialFormOrder.structures,
        // allowed to be undefined
        notes: partialFormOrder.notes,
        terms: partialFormOrder.terms,
        orderDocuments: partialFormOrder.orderDocuments,
        commission: partialFormOrder.commission,
        darkpool: !!partialFormOrder.darkpool,
        brokeredBy: partialFormOrder.brokeredBy,
        isMultiLayerSpv: partialFormOrder.isMultiLayerSpv,
        buyerCashOnHand: partialFormOrder.buyerCashOnHand,
      })
    : Left(
        new Set(
          requiredFormOrderKeys
            .filter((key) => !isDefined(partialFormOrder[key]))
            .concat(validFormPrice(partialFormOrder) ? [] : ["price"])
            .concat(validFormSize(partialFormOrder) ? [] : ["size"])
            .concat(validStructures(partialFormOrder) ? [] : ["structures"])
            .concat(validShareClasses(partialFormOrder) ? [] : ["shareClasses"])
            .concat(validateBuyerCashOnHand(partialFormOrder) ? [] : ["buyerCashOnHand"])
        )
      )
