import _ from "lodash"
import * as zod from "zod"
import { annotate } from "../../utils/Coerce"
import { Just, Nothing, Maybe } from "../../containers/Maybe"
import {
  arrayValidator,
  numberValidator,
  stringValidator,
  validateISODate,
  Validator,
  enumValidator,
  isoDateValidator,
} from "../../utils/Validator"
import { DateISOString } from "./PostgresCommon"

export interface CaplightPriceEstimate {
  companyId: string
  date: DateISOString
  caplightPriceEstimate: number
  caplightPriceEstimateStandardError: number
  publicCompsIndexLagged: number
  generatedAt: DateISOString
}

export const caplightPriceEstimateFromPostgresRaw = zod.object({
  company_id: zod.string(),
  date: zod.string().refine((x) => isoDateValidator.validate(x)),
  caplight_composite: zod.number(),
  caplight_composite_standard_error: zod.number(),
  public_comps_lagged: zod.number(),
  generated_at: zod.string().refine((x) => isoDateValidator.validate(x)),
})

export type CaplightPriceEstimateFromPostgresRaw = zod.infer<
  typeof caplightPriceEstimateFromPostgresRaw
>

export const defaultCaplightPriceEstimateFromPostgresRaw = zod.object({
  data: caplightPriceEstimateFromPostgresRaw.array(),
  model_version: zod.string(),
})
export type DefaultCaplightPriceEstimateFromPostgresRaw = zod.infer<
  typeof defaultCaplightPriceEstimateFromPostgresRaw
>

export const caplightPriceEstimateSpecialCase = zod.object({
  date: zod.string().refine((x) => isoDateValidator.validate(x)),
  price: zod.number(),
  comment: zod.string(),
  title: zod.string(),
})
export type CaplightPriceEstimateSpecialCase = zod.infer<typeof caplightPriceEstimateSpecialCase>

export const caplightPriceEstimateAttributionSummary = zod.object({
  RFQ: zod.number(),
  "409a": zod.number(),
  "Price events": zod.number(),
  "Caplight data": zod.number(),
  "Funding rounds": zod.number(),
  /** @deprecated we should not be using this field since we cannot agree on the best way to calculate it */
  "Public comparables": zod.number(),
  "Price log orders long": zod.number(),
  "Price log orders short": zod.number(),
  "Caplight and broker orders long": zod.number(),
  "Caplight and broker orders short": zod.number(),
  "Mutual fund marks": zod.number().optional(),
  leave_out: zod.number(),
  attribution_date: zod.string().refine((x) => isoDateValidator.validate(x)),
})
export type CaplightPriceEstimateAttributionSummary = zod.infer<
  typeof caplightPriceEstimateAttributionSummary
>

const caplightPriceEstimateSimplifiedAttributionSummary = zod.object({
  Orders_long: zod.number().optional(),
  Orders_short: zod.number().optional(),
  "Price events_nan": zod.number().optional(),
  "Caplight data_nan": zod.number().optional(),
  "Price events_long": zod.number().optional(),
  "Funding rounds_nan": zod.number().optional(),
  "Price events_short": zod.number().optional(),
})
export type CaplightPriceEstimateSimplifiedAttributionSummary = zod.infer<
  typeof caplightPriceEstimateAttributionSummary
>

export interface CaplightPriceEstimateMetaIfError {
  error: string
}

export type CaplightPriceEstimateMetaFromPostgres =
  | CaplightPriceEstimateMetaIfError
  | CaplightPriceEstimateMetaIfSuccess

const caplightPriceEstimateMetaIfError = zod.object({ error: zod.string() })
const caplightPriceEstimateMetaIfSuccess = zod.object({
  last_raw_data_date: zod.string().refine((x) => isoDateValidator.validate(x)),
  lag_to_public_comps: zod.number(),
  correlation_to_public_comps: zod.number(),
  number_of_data_points: zod.number(),
  number_of_data_points_with_repeats: zod.number(),
  number_of_datapoints_summary: zod
    .object({ tag: zod.string(), past1mo: zod.number(), past6mo: zod.number() })
    .optional(),
  attribution_summary: caplightPriceEstimateAttributionSummary.optional(),
})

export type CaplightPriceEstimateMetaIfSuccess = zod.infer<
  typeof caplightPriceEstimateMetaIfSuccess
>
export const caplightPriceEstimateMetaFromPostgres = caplightPriceEstimateMetaIfError.or(
  caplightPriceEstimateMetaIfSuccess
)

const successKeys: (keyof CaplightPriceEstimateMetaIfSuccess)[] = [
  "last_raw_data_date",
  "lag_to_public_comps",
  "correlation_to_public_comps",
  "number_of_data_points",
  "number_of_data_points_with_repeats",
  "attribution_summary",
]

const attributionSummarySuccessKeys: (keyof CaplightPriceEstimateAttributionSummary)[] = [
  "RFQ",
  "409a",
  "Price events",
  "Caplight data",
  "Funding rounds",
  "Public comparables",
  "Price log orders long",
  "Price log orders short",
  "Caplight and broker orders long",
  "Caplight and broker orders short",
  "leave_out",
  "attribution_date",
]
export type MarketPriceAttributionSummarized = {
  "Closed trades": number
  Bids: number
  Asks: number
  "Public comps": number
  "Mutual fund marks"?: number
  Other: number
}
export const attributionToHigherLevelSummary = (
  att: CaplightPriceEstimateAttributionSummary
): MarketPriceAttributionSummarized => {
  const shared: MarketPriceAttributionSummarized = {
    "Closed trades": att["Caplight data"],
    Bids: att["Price log orders long"] + att["Caplight and broker orders long"],
    Asks: att["Price log orders short"] + att["Caplight and broker orders short"],
    "Public comps": att["Public comparables"],
    ...(att["Mutual fund marks"] ? { "Mutual fund marks": att["Mutual fund marks"] } : {}),
    Other:
      1 -
      att["Caplight data"] -
      (att["Price log orders long"] +
        att["Caplight and broker orders long"] +
        att["Price log orders short"] +
        att["Caplight and broker orders short"] +
        att["Public comparables"] +
        (att["Mutual fund marks"] || 0)),
  }
  return shared satisfies MarketPriceAttributionSummarized
}

export const isMetaError = (x: unknown): x is CaplightPriceEstimateMetaIfError =>
  _.isObject(x) && Object.keys(x).includes("error")
export const isMetaSuccess = (x: unknown): x is CaplightPriceEstimateMetaIfSuccess =>
  _.isObject(x) && _.intersection(Object.keys(x), successKeys).length === successKeys.length

export const isAttributionSummarySuccess = (x: unknown): x is CaplightPriceEstimateMetaIfSuccess =>
  _.isObject(x) &&
  _.intersection(Object.keys(x), attributionSummarySuccessKeys).length ===
    attributionSummarySuccessKeys.length

export const maybeSuccessMeta = (
  m: CaplightPriceEstimateMetaFromPostgres
): Maybe<CaplightPriceEstimateMetaIfSuccess> =>
  isMetaSuccess(m) ? Just(annotate<CaplightPriceEstimateMetaIfSuccess>(m)) : Nothing

const marketPriceAttributionValidator: Validator<unknown, CaplightPriceEstimateAttributionSummary> =
  Validator.fromRecord({
    RFQ: numberValidator.validate,
    "409a": numberValidator.validate,
    "Price events": numberValidator.validate,
    "Caplight data": numberValidator.validate,
    "Funding rounds": numberValidator.validate,
    "Public comparables": numberValidator.validate,
    "Price log orders long": numberValidator.validate,
    "Price log orders short": numberValidator.validate,
    "Caplight and broker orders long": numberValidator.validate,
    "Caplight and broker orders short": numberValidator.validate,
    "Mutual fund marks": numberValidator.or(enumValidator(undefined)).validate,
    leave_out: numberValidator.validate,
    attribution_date: validateISODate,
  })
export const validateCaplightPriceEstimateFromPostgresMeta: Validator<
  unknown,
  CaplightPriceEstimateMetaFromPostgres
> = Validator.fromRecord({
  error: stringValidator.validate,
}).or(
  Validator.fromRecord({
    last_raw_data_date: validateISODate,
    lag_to_public_comps: numberValidator.validate,
    correlation_to_public_comps: numberValidator.validate,
    number_of_data_points: numberValidator.validate,
    number_of_data_points_with_repeats: numberValidator.validate,
    attribution_summary: marketPriceAttributionValidator.or(enumValidator(undefined)).validate,
  })
)

export const CAPLIGHT_PRICE_ESTIMATE_QUALITY_SCORE_EXCELLENT_CUTOFF = 3.25
export const CAPLIGHT_PRICE_ESTIMATE_QUALITY_SCORE_FAIR_CUTOFF = 2
export interface CaplightPriceEstimateQualityScore {
  date: DateISOString
  score: number
}
export const validateCaplightQualityScore: Validator<unknown, CaplightPriceEstimateQualityScore> =
  Validator.fromRecord({
    date: validateISODate,
    score: numberValidator.validate,
  })

export const validateCaplightPriceEstimateFromPostgresRaw: Validator<
  unknown,
  CaplightPriceEstimateFromPostgresRaw
> = Validator.fromRecord({
  company_id: stringValidator.validate,
  date: validateISODate,
  caplight_composite: numberValidator.validate,
  caplight_composite_standard_error: numberValidator.validate,
  public_comps_lagged: numberValidator.validate,
  generated_at: validateISODate,
})

type CaplightPriceEstimateFromPostgresRawNoComps = Omit<
  CaplightPriceEstimateFromPostgresRaw,
  "public_comps_lagged"
> & {
  generated_at_timestamp: number
}
export type CaplightPriceEstimateNoCompsWithTimestamp = Omit<
  CaplightPriceEstimate,
  "publicCompsIndexLagged"
> & { generatedAtTimestamp: number }
export const validateCaplightPriceEstimateFromPostgresNoComps: Validator<
  unknown,
  CaplightPriceEstimateFromPostgresRawNoComps
> = Validator.fromRecord({
  company_id: stringValidator.validate,
  date: validateISODate,
  caplight_composite: numberValidator.validate,
  caplight_composite_standard_error: numberValidator.validate,
  generated_at: validateISODate,
  generated_at_timestamp: numberValidator.validate,
})

export const parseOneRowCaplightPriceEstimateFromPostgres = (
  row: CaplightPriceEstimateFromPostgresRaw
): CaplightPriceEstimate => ({
  companyId: row.company_id,
  date: row.date,
  generatedAt: row.generated_at,
  caplightPriceEstimate: row.caplight_composite,
  caplightPriceEstimateStandardError: row.caplight_composite_standard_error,
  publicCompsIndexLagged: row.public_comps_lagged,
})
export const parseCaplightPriceEstimateFromPostgres = (
  response: CaplightPriceEstimateFromPostgresRaw[]
): CaplightPriceEstimate[] => response.map(parseOneRowCaplightPriceEstimateFromPostgres)

export const parseOneRowCaplightPriceEstimateFromPostgresNoComps = (
  row: CaplightPriceEstimateFromPostgresRawNoComps
): CaplightPriceEstimateNoCompsWithTimestamp => ({
  companyId: row.company_id,
  date: row.date,
  generatedAt: row.generated_at,
  generatedAtTimestamp: row.generated_at_timestamp,
  caplightPriceEstimate: row.caplight_composite,
  caplightPriceEstimateStandardError: row.caplight_composite_standard_error,
})

export const validateCaplightPriceEstimateModels: Validator<
  unknown,
  {
    fullList: string[]
    default: string
  }
> = Validator.fromRecord({
  fullList: arrayValidator.compose(stringValidator.overArray()).validate,
  default: stringValidator.validate,
})

export interface CaplightPriceEstimateFromPostgresLight {
  date: DateISOString
  caplight_composite: number
}
export const validateCaplightPriceEstimateFromPostgresLight: Validator<
  unknown,
  CaplightPriceEstimateFromPostgresLight
> = Validator.fromRecord({
  date: validateISODate,
  caplight_composite: numberValidator.validate,
})

export const caplightPriceEstimateEODFixed = zod.object({
  company_id: zod.string(),
  date: zod.string().refine((x) => isoDateValidator.validate(x)),
  split_adjusted_for_date: zod.string().refine((x) => isoDateValidator.validate(x)),
  caplight_composite: zod.number(),
  generated_at_timestamp: zod.number(),
  caplight_composite_standard_error: zod.number(),
  last_raw_data_date: zod.string().refine((x) => isoDateValidator.validate(x)),
  number_of_datapoints_summary: zod
    .object({ tag: zod.string(), past1mo: zod.number(), past6mo: zod.number() })
    .optional(),
  trade_number_of_datapoints_summary: zod
    .object({ tag: zod.string(), past1mo: zod.number(), past6mo: zod.number() })
    .optional(),
  attribution_summary: caplightPriceEstimateAttributionSummary.optional(),
  simple_decay_attribution: caplightPriceEstimateSimplifiedAttributionSummary.optional(),
})
export type CaplightPriceEstimateEODFixed = zod.infer<typeof caplightPriceEstimateEODFixed>
