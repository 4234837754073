import { ExternalLinkIcon } from "@stories/icons/ExternalLinkIcon"
import { Account } from "common/model/Account"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { useMemo } from "react"
import { airtableLink } from "src/admin/utils"

const ContactAirtableLink = ({
  contact,
  currentAccount,
}: {
  contact: DealCRMContact
  currentAccount: Account
}) => {
  const linkURL = useMemo(() => {
    if (contact.tag === "firm" && contact.airtableFirmId) {
      return airtableLink("accounts", contact.airtableFirmId)
    } else if (contact.tag !== "firm" && contact.airtableContactId) {
      return airtableLink("contacts", contact.airtableContactId)
    }
    return undefined
  }, [contact])

  if (currentAccount.name !== "Caplight") return null

  if (!linkURL) return null

  return (
    <div className="">
      <a
        className="text-sm font-sans text-accent-500 flex items-center gap-2"
        href={linkURL}
        target="_blank"
        rel="noreferrer"
      >
        View in Airtable
        <ExternalLinkIcon size="medium" />
      </a>
    </div>
  )
}

export default ContactAirtableLink
