import Spinner from "@components/icons/Spinner"
import { Dropdown } from "@components/inputs/Dropdown"
import { CheckboxWithLabel } from "@components/inputs/checkbox/Checkbox"
import Callout from "@components/layout/Callout"
import { CheckCircleIcon } from "@heroicons/react/solid"
import { notification } from "@stories/components/Antd"
import { Button } from "@stories/components/Button/Button"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { collections } from "common/firestore/Collections"
import { Account, viewAccountIdFields } from "common/model/Account"
import {
  AccountCRMSettings,
  ThirdPartyCRM,
  buildAccountCRMSettings,
  thirdPartyCRMOptions,
} from "common/model/AccountCRMSettings"
import { FeatureFlag, FeatureFlagName } from "common/model/FeatureFlag/FeatureFlag"
import { firestoreConverter } from "common/model/FirestoreConverter"
import React, { useEffect, useState } from "react"
import { useFirebaseAdmin } from "src/firebase/Context"
import { oneOrNone } from "src/firebase/Firebase9"

interface AdminAccountCRMSettingsPageProps {
  account: Account
}

const AdminAccountCRMSettingsPage: React.FC<AdminAccountCRMSettingsPageProps> = ({ account }) => {
  const firebase = useFirebaseAdmin()
  const [forceEnabled, setForceEnabled] = useState(false)
  const [crmFeatureFlag, setCRMFeatureFlag] = useState<FeatureFlag | null>(null)

  useEffect(() => {
    firebase.db
      .collection(collections.featureFlags)
      .where("name", "==", "crm" satisfies FeatureFlagName)
      .withConverter<FeatureFlag>(firestoreConverter<FeatureFlag>())
      .get()
      .then(oneOrNone)
      .then((doc) => setCRMFeatureFlag(doc || null))
  }, [firebase, setCRMFeatureFlag])

  if (!crmFeatureFlag) return <Spinner size="md" />

  const isCRMEnabled =
    forceEnabled ||
    crmFeatureFlag.status === "fully_enabled" ||
    (crmFeatureFlag.status === "conditionally_enabled" &&
      (crmFeatureFlag.enabledAccounts || []).some((acc) => acc.id === account.id))

  const accountCRMPreferences = buildAccountCRMSettings(account)

  const updateAccountSettings = async (update: Partial<AccountCRMSettings>) => {
    const accountRef = firebase.db.collection(collections.accounts).doc(account.id)
    return accountRef
      .update({
        crmPreferences: {
          ...accountCRMPreferences,
          ...update,
        },
      } satisfies Pick<Account, "crmPreferences">)
      .then(() => {
        notification.success({
          message: "Account CRM Settings Updated",
          placement: "top",
          duration: 1,
        })
      })
  }

  const enableCRM = async () =>
    firebase.db
      .collection(collections.featureFlags)
      .doc(crmFeatureFlag.id)
      .update({
        enabledAccounts: [...(crmFeatureFlag.enabledAccounts || []), viewAccountIdFields(account)],
      } satisfies Partial<FeatureFlag>)
      .then(() => setForceEnabled(true))

  return (
    <div className="mt-2">
      <Typography size={Size.XXLarge} weight={Weight.Semibold} text="Admin Account CRM Settings" />
      {isCRMEnabled ? (
        <div className="flex flex-col gap-y-2 mt-2">
          <div className="flex gap-4">
            <CheckCircleIcon className="h-6 w-6 text-green-500" />
            <Typography
              text="CRM is enabled for this account."
              weight={Weight.Semibold}
              size={Size.Medium}
              color={Color.Link}
            />
          </div>
          <Dropdown<ThirdPartyCRM | "">
            small
            label="Third Party CRM"
            options={([...thirdPartyCRMOptions, ""] satisfies (ThirdPartyCRM | "")[]).map(
              (opt) => ({ id: opt, name: opt || "none" })
            )}
            selected={
              accountCRMPreferences.thirdPartyCRM
                ? {
                    id: accountCRMPreferences.thirdPartyCRM,
                    name: accountCRMPreferences.thirdPartyCRM,
                  }
                : { id: "", name: "none" }
            }
            setSelected={(val) => updateAccountSettings({ thirdPartyCRM: val.id || null })}
          />
          <Typography text="Deal Settings" weight={Weight.Semibold} size={Size.Large} />
          <div className="grid grid-cols-3 gap-2">
            <CheckboxWithLabel
              title="Show Buyer SPV Fees"
              checked={accountCRMPreferences.deals.showBuyerSPVFees}
              onChange={(checked) =>
                updateAccountSettings({
                  deals: {
                    ...accountCRMPreferences.deals,
                    showBuyerSPVFees: checked.target.checked,
                  },
                })
              }
              id={"showBuyerSPVFees"}
            />
            <CheckboxWithLabel
              title="Show Broker Fees"
              checked={accountCRMPreferences.deals.showBrokerFees}
              onChange={(checked) =>
                updateAccountSettings({
                  deals: {
                    ...accountCRMPreferences.deals,
                    showBrokerFees: checked.target.checked,
                  },
                })
              }
              id={"showBrokerFees"}
            />
            <CheckboxWithLabel
              title="Show Deal Priority Field"
              checked={accountCRMPreferences.deals.dealFields.priority}
              onChange={(checked) =>
                updateAccountSettings({
                  deals: {
                    ...accountCRMPreferences.deals,
                    dealFields: {
                      ...accountCRMPreferences.deals.dealFields,
                      priority: checked.target.checked,
                    },
                  },
                })
              }
              id={"showDealPriorityField"}
            />
            <CheckboxWithLabel
              title="Show Deal Urgency Field"
              checked={accountCRMPreferences.deals.dealFields.urgency}
              onChange={(checked) =>
                updateAccountSettings({
                  deals: {
                    ...accountCRMPreferences.deals,
                    dealFields: {
                      ...accountCRMPreferences.deals.dealFields,
                      urgency: checked.target.checked,
                    },
                  },
                })
              }
              id={"showDealUrgencyField"}
            />
            <CheckboxWithLabel
              title="Show Deal Participant Priority Field"
              checked={accountCRMPreferences.deals.participantFields.priority}
              onChange={(checked) =>
                updateAccountSettings({
                  deals: {
                    ...accountCRMPreferences.deals,
                    participantFields: {
                      ...accountCRMPreferences.deals.participantFields,
                      priority: checked.target.checked,
                    },
                  },
                })
              }
              id={"showDealParticipantPriorityField"}
            />
            <CheckboxWithLabel
              title="Show Deal Participant Urgency Field"
              checked={accountCRMPreferences.deals.participantFields.urgency}
              onChange={(checked) =>
                updateAccountSettings({
                  deals: {
                    ...accountCRMPreferences.deals,
                    participantFields: {
                      ...accountCRMPreferences.deals.participantFields,
                      urgency: checked.target.checked,
                    },
                  },
                })
              }
              id={"showDealParticipantUrgencyField"}
            />
          </div>
        </div>
      ) : (
        <div>
          <Callout body="CRM is not enabled for this account." color="yellow" />
          <Button variant="primary" onClick={enableCRM} label="Enable CRM" />
        </div>
      )}
    </div>
  )
}

export default AdminAccountCRMSettingsPage
