import { matchLoading } from "common/utils/Loading"
import Typography, { TypographyProps } from "@stories/components/Typography/Typography"
import pluralize from "pluralize"
import { useAccountCompliance } from "src/providers/data/AccountComplianceProvider"

export const MAXIMUM_LIVE_DARKPOOL_ORDERS = 2

export const DarkpoolLimitText = (props: Omit<TypographyProps, "text">) => {
  const { remainingDarkpoolOrderCount, canSubmitDarkpoolOrder } = useAccountCompliance()
  return matchLoading(
    remainingDarkpoolOrderCount,
    (n) => (
      <Typography
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        text={
          remainingDarkpoolOrderCount === MAXIMUM_LIVE_DARKPOOL_ORDERS
            ? `You are eligible to submit up to ${n} Darkpool Orders`
            : canSubmitDarkpoolOrder
            ? `You are eligible to submit ${n} additional Darkpool ${pluralize("Order", n)}.`
            : `You have reached your limit of ${MAXIMUM_LIVE_DARKPOOL_ORDERS} live Darkpool Orders`
        }
      />
    ),
    null,
    null
  )
}
