import { orderBy } from "lodash"
import { useMemo } from "react"
import { CompanyWithPostgres } from "common/model/Company"
import { useFullCompanyLiveMarketOrders } from "src/queries/Order/useFullCompanyLiveMarketOrders"
import { Loading } from "common/utils/Loading/Wrapped"
import { useCompanyPrivateOrders } from "./useCompanyPrivateOrders"

export const useCompanyOrders = ({ company }: { company: CompanyWithPostgres }) => {
  const unsortedOrders = useFullCompanyLiveMarketOrders(company)
  const companyOrdersPrivateOrderQuery = useMemo(() => ({ companyId: company.id }), [company.id])
  const allPrivateOrders = useCompanyPrivateOrders(companyOrdersPrivateOrderQuery)
  const companyPrivateOrders = useMemo(
    () =>
      new Loading(allPrivateOrders).map((os) => os.filter((o) => o.company().id === company.id)),
    [allPrivateOrders, company.id]
  )
  const allOrders = useMemo(
    () =>
      unsortedOrders.map((loadedUnsortedOrders) =>
        orderBy(
          loadedUnsortedOrders.concat(companyPrivateOrders.withDefault([])),
          (o) => o.status().lastRefreshed(),
          "desc"
        )
      ),
    [companyPrivateOrders, unsortedOrders]
  )

  return useMemo(
    () => ({
      allOrders: allOrders.unboxed,
      privateOrders: companyPrivateOrders.unboxed,
      platformOrders: unsortedOrders.unboxed,
    }),
    [allOrders.unboxed, companyPrivateOrders.unboxed, unsortedOrders.unboxed]
  )
}
