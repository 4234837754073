import { Order } from "../Order/Order"
import { Company, CompanyIdFields, viewCompanyIdFields } from "../Company"
import { SharedOrder, SharedWithData, SharedWithUser } from "./SharedOrder"
import { StockSplitData } from "../Stock/StockSplit"
import { viewOrderIdFields } from "../Order/Models/Internal"
import { DealCRMInterest } from "../DealCRM/DealCRMInterest"
import { DealSearch } from "../DealSearch/DealSearch"
import { assertUnreachable } from "../../utils/fp/Function"

export type ShareableItem =
  | { tag: "order"; order: Order; id: string }
  | { tag: "crm_interest"; crmInterest: DealCRMInterest; id: string }
  | { tag: "deal_search"; dealSearch: DealSearch; id: string }

export const viewCompanyIdsFromShareableItem = (x: ShareableItem): CompanyIdFields =>
  x.tag === "crm_interest"
    ? x.crmInterest.company
    : x.tag === "order"
    ? x.order.company
    : x.tag === "deal_search"
    ? x.dealSearch.company
    : assertUnreachable(x)

export const exposeMinimumShareableItemData = (x: ShareableItem): ShareableItem =>
  x.tag === "crm_interest"
    ? {
        id: x.id,
        tag: "crm_interest",
        crmInterest: {
          id: x.id,
          tag: x.crmInterest.tag,
          direction: x.crmInterest.direction,
          company: x.crmInterest.company,
          firmness: null,
          commissionPercent: null,
          updatedAt: [x.crmInterest.lastUpdatedAt],
          lastUpdatedAt: x.crmInterest.lastUpdatedAt,
          updates: [],
          structure: x.crmInterest.structure,
          shareClass: x.crmInterest.shareClass,
          amountUSD: x.crmInterest.amountUSD,
          targetPrice: x.crmInterest.targetPrice,
          targetValuation: x.crmInterest.targetValuation,
          managementFeePercent: x.crmInterest.managementFeePercent,
          managementFeeStructure: x.crmInterest.managementFeeStructure,
          carryPercent: x.crmInterest.carryPercent,
          createdAt: x.crmInterest.createdAt,
          orderOriginationDate: x.crmInterest.orderOriginationDate,

          // TODO probably shouldn't be shared
          account: x.crmInterest.account,
          contact: x.crmInterest.contact,
          source: x.crmInterest.source,
        } satisfies DealCRMInterest,
      }
    : x.tag === "order"
    ? {
        id: x.id,
        tag: "order",
        order: {
          // non optional
          ...viewOrderIdFields(x.order),
          _derived: {},
          shareClasses: x.order.shareClasses,
          lastUpdatedAt: x.order.lastUpdatedAt,
          updatedAt: x.order.updatedAt,
          createdAt: x.order.createdAt,
          terms: x.order.terms,
          orderUpdatedAt: x.order.orderUpdatedAt,

          // nullable
          _lastReportedStatus: null,
          shareClassSeries: null,
          firmness: null,
          caplightPromotion: null,
          brokeredBy: null,

          // optional public
          orderOriginationDate: x.order.orderOriginationDate,
          brokerClientMetadata: {
            confirmedAt: x.order.brokerClientMetadata?.confirmedAt ?? null,
            profile: null,
            relationship: null,
            sellerROFR: null,
            buyerCapTableMember: null,
            previousClientTransaction: null,
            investmentCommitteeInvolved: null,
            investmentCommitteeApproved: null,
          },
          commission: x.order.commission,
          buyerCashOnHand: x.order.buyerCashOnHand,
        } satisfies Order,
      }
    : x.tag === "deal_search"
    ? {
        id: x.id,
        tag: "deal_search",
        dealSearch: {
          id: x.id,
          direction: x.dealSearch.direction,
          company: x.dealSearch.company,
          contact: x.dealSearch.contact,
          tag: x.dealSearch.tag,
          firmness: null,
          commissionPercent: null,
          updatedAt: [x.dealSearch.lastUpdatedAt],
          lastUpdatedAt: x.dealSearch.lastUpdatedAt,
          updates: [],
          structure: x.dealSearch.structure,
          shareClass: x.dealSearch.shareClass,
          amountUSD: x.dealSearch.amountUSD,
          targetPrice: x.dealSearch.targetPrice,
          targetValuation: x.dealSearch.targetValuation,
          managementFeePercent: x.dealSearch.managementFeePercent,
          managementFeeStructure: x.dealSearch.managementFeeStructure,
          carryPercent: x.dealSearch.carryPercent,
          orderOriginationDate: x.dealSearch.orderOriginationDate,
          createdAt: x.dealSearch.createdAt,

          // TODO probably shouldn't be shared
          account: x.dealSearch.account,
          source: x.dealSearch.source,
        } satisfies DealSearch,
      }
    : assertUnreachable(x)

export type SharedOrderResponseCompanyData = Company

export const companyToSharedOrderResponseCompanyData = (
  x: Company
): SharedOrderResponseCompanyData => ({
  // non optional
  ...viewCompanyIdFields(x),
  fundingRounds: x.fundingRounds,
  ticker: x.ticker,
  stockSplits: x.stockSplits,
  publicCompTickers: x.publicCompTickers,
  aggregations: x.aggregations,
  valuations409a: x.valuations409a,
  statusDetails: x.statusDetails,
  valuationSummary: x.valuationSummary,

  // optional public
  description: x.description,
  logos: x.logos,
  filings: x.filings,
})

export type SharedOrderResponseSharedOrderData = Pick<
  SharedOrder,
  "id" | "source" | "createdAt"
> & { userForm: SharedOrder["userForm"] | null }
export const viewSharedOrderResponseSharedOrderData = (
  s: SharedOrder
): SharedOrderResponseSharedOrderData => ({
  id: s.id,
  userForm: s.userForm,
  source: s.source,
  createdAt: s.createdAt,
})
type SharedOrderResponseSharedWithUserData = SharedWithUser & SharedWithData

export const sharedOrderResponseCompanyDataToCompany = (
  x: SharedOrderResponseCompanyData
): Company => ({
  ...x,
  fundingRounds: x.fundingRounds.map(
    (f) =>
      ({
        ...f,
        date: new Date(f.date),
        updatedAt: new Date(f.updatedAt),
      } satisfies Company["fundingRounds"][number])
  ),
  stockSplits: x.stockSplits.map(
    (s) =>
      ({
        ...s,
        createdAt: new Date(s.createdAt),
        splitData: {
          ...s.splitData,
          date: new Date(s.splitData.date),
        } satisfies StockSplitData,
      } satisfies Company["stockSplits"][number])
  ),
})
export type SharedOrderResponseItemData = {
  orderData: ShareableItem
}
export type SharedOrderDetailsPageResponse = SharedOrderResponseItemData & {
  companyData: SharedOrderResponseCompanyData
  sharedOrderData: SharedOrderResponseSharedOrderData
  sharedWithUserData: SharedOrderResponseSharedWithUserData
}
export type SharedOrdersListItemResponse = SharedOrderResponseItemData & {
  companyData: SharedOrderResponseCompanyData
  sharedOrderData: SharedOrderResponseSharedOrderData
  sharedWithUserData?: SharedOrderResponseSharedWithUserData
}
export type SharedOrdersListPageResponse = {
  items: SharedOrdersListItemResponse[]
}

export const getSharedItemLastUpdatedAt = (item: ShareableItem): Date =>
  item.tag === "crm_interest"
    ? item.crmInterest.lastUpdatedAt
    : item.tag === "order"
    ? item.order.lastUpdatedAt
    : item.tag === "deal_search"
    ? item.dealSearch.lastUpdatedAt
    : assertUnreachable(item)
