import { Input } from "@stories/components/Inputs/Input/Input"
import { isDefined } from "common/utils/TypeUtils"
import { FC, useEffect, useMemo, useRef, useState } from "react"
import {
  InputProps,
  NumberInputFormatProps,
  formatNumberInput,
  parseNumberInput,
  potentiallyValid,
} from "./NumberInput"
import { defaultNumberInputFormatProps } from "./defaultNumberInputFormatProps"

export const RawNumberInput: FC<
  React.PropsWithChildren<
    Omit<InputProps, "onChange" | "value"> & { onUpdate: (s: string) => void; initialValue: string }
  >
> = ({
  id,
  dataTestId,
  label,
  onUpdate,
  initialValue,
  name,
  min,
  max,
  scale,
  addonBefore,
  addonAfter,
  numberFormat,
  className,
  disabled,
  precision,
  decimalPlaces,
  totalDigits,
  overrideWithRounded,
  subtext,
  size,
}) => {
  const formatProps: NumberInputFormatProps = useMemo(
    () => ({
      numberFormat: numberFormat ?? defaultNumberInputFormatProps.numberFormat,
      scale: scale ?? defaultNumberInputFormatProps.scale,
      precision: precision ?? defaultNumberInputFormatProps.precision,
      decimalPlaces: decimalPlaces ?? defaultNumberInputFormatProps.decimalPlaces,
      totalDigits: totalDigits ?? defaultNumberInputFormatProps.totalDigits,
      overrideWithRounded: overrideWithRounded ?? defaultNumberInputFormatProps.overrideWithRounded,
    }),
    [decimalPlaces, numberFormat, overrideWithRounded, precision, scale, totalDigits]
  )

  const [rawValue, setRawValue] = useState<string>(initialValue)
  const [hasError, setHasError] = useState<boolean>(false)

  const inputRef = useRef<HTMLInputElement>(null)
  const [caret, setCaret] = useState<number | null>(null)

  useEffect(() => {
    parseNumberInput(formatProps, initialValue)
      .alongside(parseNumberInput(formatProps, rawValue))
      .match(
        () => {},
        ([initialValueNumber, rawValueNumber]) => {
          const equalStrings = initialValue === rawValue
          const equalNumbers =
            isDefined(initialValueNumber) &&
            isDefined(rawValueNumber) &&
            Math.abs(initialValueNumber - rawValueNumber) < Number.EPSILON

          if (!equalStrings && !equalNumbers) {
            setRawValue(initialValue)
          }
        }
      )
  }, [formatProps, initialValue, rawValue])

  const onRawUpdate = useMemo(
    () => (e: { target: { value: string; selectionStart: number | null } }) => {
      const { value: newValue, selectionStart } = e.target
      setCaret(selectionStart)
      if (potentiallyValid(formatProps, newValue)) {
        setHasError(false)
        setRawValue(newValue)
        if (newValue !== undefined && parseNumberInput(formatProps, newValue).isRight()) {
          onUpdate(newValue)
        }
      } else {
        setHasError(true)
      }
    },
    [formatProps, onUpdate]
  )

  useEffect(() => {
    if (inputRef.current && caret) inputRef.current.setSelectionRange(caret, caret)
  }, [caret, rawValue])

  const onBlur = useMemo(
    () => () => {
      parseNumberInput(formatProps, rawValue).match(
        () => {},
        (x) => setRawValue(formatNumberInput(formatProps, x))
      )
      onUpdate(rawValue)
    },
    [formatProps, onUpdate, rawValue]
  )

  return (
    <div className={className}>
      <Input
        size={size}
        label={label}
        prefix={addonBefore ?? (numberFormat === "price" ? "$" : undefined)}
        suffix={
          addonAfter || (numberFormat === "percentage" ? <div className="pr-3">%</div> : undefined)
        }
        type="text"
        testId={dataTestId}
        id={id || name}
        name={name}
        isDisabled={disabled}
        value={rawValue}
        onChange={onRawUpdate}
        onBlur={onBlur}
        hintText={subtext}
        step={precision === "decimal" ? 1e-6 : 1e-3}
        min={min}
        max={max}
        inputRef={inputRef}
        isDanger={hasError}
      />
    </div>
  )
}
