import { isEmbed } from "../providers/embed/utils"

// NOTE: some users disallow third-party sessionStorage,
// so we have to try/catch usage of sessionStorage for integrations
export const tryLocalStorage = (): Storage | null => {
  try {
    return window.localStorage
  } catch (e) {
    if (isEmbed()) return null

    throw e
  }
}

export const trySessionStorage = (): Storage | null => {
  try {
    return window.sessionStorage
  } catch (e) {
    if (isEmbed()) return null

    throw e
  }
}

interface AppConfig {
  opportunityDisplay?: "list" | "grid"
}

const localStorageKeys = {
  appConfig: "app_config",
  postLoginRedirect: "post_login_redirect",
  inviteCode: "invite_code",
  userId: "user_id",
  signupSession: "signup_session",
  cartaAuthStateToken: "carta_auth_state_token",
}

export const getAppConfig: () => AppConfig = () => {
  const conf = tryLocalStorage()?.getItem(localStorageKeys.appConfig)
  if (conf) return JSON.parse(conf) as AppConfig
  return {}
}

export function setAppConfigValue<K extends keyof AppConfig>(key: K, value: AppConfig[K]) {
  const conf = getAppConfig()
  conf[key] = value
  tryLocalStorage()?.setItem(localStorageKeys.appConfig, JSON.stringify(conf))
}

export const setPostLoginRedirect = (route: string) => {
  tryLocalStorage()?.setItem(localStorageKeys.postLoginRedirect, route)
}

export const setSessionUserId = (userId: string | undefined) => {
  if (userId) {
    return trySessionStorage()?.setItem(localStorageKeys.userId, userId)
  } else {
    return trySessionStorage()?.removeItem(localStorageKeys.userId)
  }
}

export const getSessionUserId = () => trySessionStorage()?.getItem(localStorageKeys.userId)

export const getPostLoginRedirect = () =>
  tryLocalStorage()?.getItem(localStorageKeys.postLoginRedirect)

export const clearPostLoginRedirect = () => {
  tryLocalStorage()?.removeItem(localStorageKeys.postLoginRedirect)
}

export const setInviteCode = (code: string) => {
  trySessionStorage()?.setItem(localStorageKeys.inviteCode, code)
}

export const getInviteCode = (): string | null =>
  trySessionStorage()?.getItem(localStorageKeys.inviteCode) ?? null

export const clearInviteCode = () => {
  trySessionStorage()?.removeItem(localStorageKeys.inviteCode)
}

export const setIsSignupSession = () => {
  trySessionStorage()?.setItem(localStorageKeys.signupSession, "true")
}

export const isSignupSession = () =>
  trySessionStorage()?.getItem(localStorageKeys.signupSession) === "true"

export const setSessionStorageCartaAuthStateToken = (token: string) => {
  trySessionStorage()?.setItem(localStorageKeys.cartaAuthStateToken, token)
}

export const getSessionStorageCartaAuthStateToken = () =>
  trySessionStorage()?.getItem(localStorageKeys.cartaAuthStateToken) ?? null

