import { FC } from "react"

type UnreadIconVariant = "accent" | "warning" | "danger" | "black"
const variantStyleMap: Record<UnreadIconVariant, string> = {
  accent: "bg-accent-500 text-white",
  warning: "bg-warning-300 text-warning-900",
  danger: "bg-danger-600 text-white",
  black: "bg-neutral-1000 text-neutral-1000",
}

const UnreadIcon: FC<
  React.PropsWithChildren<{ count?: number | string; variant: UnreadIconVariant }>
> = ({ count, variant }) => (
  <div
    className={`${variantStyleMap[variant]} ${
      count ? "w-6 h-6" : "w-2 h-2 min-w-2 min-h-2"
    } rounded-full text-xs font-semibold flex items-center justify-center`}
  >
    <p>{count || null}</p>
  </div>
)

export default UnreadIcon
