// TODO move to common

import moment from "moment"
import { CompanyIdFields } from "../../../model/Company"
import { StockSplitData } from "../../../model/Stock/StockSplit"
import { Ratio, divideQ, multiplyQ, qToNumber } from "../../../utils/data/numeric/Rational"
import { PricingDataRoot } from "../PricingDataSource"
import { NeedsSplitAdjustment, SplitAdjusted, splitAdjustments } from "./Types"
import { SimpleTraversal } from "../../../utils/fp/optics/Traversal"

type Variance = "covariant" | "contravariant"
export type SplitAdjustment<T> = readonly [SimpleTraversal<T, number>, Variance]

const flip2 =
  <X, Y, Z>(f: (x: X, y: Y) => Z) =>
  (y: Y, x: X) =>
    f(x, y)

export const splitAdjustHelper = <K extends keyof NeedsSplitAdjustment>(
  splits: StockSplitData[],
  target: Date,
  k: K,
  x: NeedsSplitAdjustment[K]
): SplitAdjusted[K] => {
  const startDate = splitAdjustments[k].date(x)
  return splitAdjustments[k].adjustments.reduce(
    (adjusted, f) => applySplitAdjustment(f, target)(splits)(startDate, adjusted),
    x
  )
}

export const applySplitAdjustment =
  <T>(adjustment: SplitAdjustment<T>, targetDate: Date) =>
  (splits: StockSplitData[]) =>
  (valuationDate: Date, x: T) => {
    const effectiveSplit = splits
      .filter((y) => y.date > valuationDate && y.date < targetDate)
      .map((y) => y.ratio)
      .reduce(multiplyQ, Ratio(1, 1))
    return adjustment[0].over(x, (n) =>
      qToNumber(
        (adjustment[1] === "contravariant" ? multiplyQ : flip2(divideQ))(
          effectiveSplit,
          Ratio(n, 1)
        )
      )
    )
  }

const getStockSplits = (root: PricingDataRoot, company: CompanyIdFields, after: Date) =>
  root
    .company(company.id)
    .stockSplits()
    .get(null, moment())
    .then((splits) => splits.filter((x) => x.splitData.date >= after))

const getSplitAdjustments = async <K extends keyof NeedsSplitAdjustment>(
  root: PricingDataRoot,
  target: Date,
  company: CompanyIdFields,
  k: K,
  startDate: Date
): Promise<StockSplitData[]> => {
  const realSplits = await getStockSplits(root, company, startDate).then((splits) =>
    splits.filter((s) => s.splitData.date < target)
  )
  return realSplits.map((x) => x.splitData)
}

export const splitAdjust = async <K extends keyof NeedsSplitAdjustment>(
  root: PricingDataRoot,
  target: Date,
  company: CompanyIdFields,
  k: K,
  x: NeedsSplitAdjustment[K]
): Promise<SplitAdjusted[K]> => {
  const startDate = splitAdjustments[k].date(x)
  const splits: StockSplitData[] = await getSplitAdjustments(root, target, company, k, startDate)
  return splitAdjustHelper(splits, target, k, x)
}

type SplitAdjustFn<K extends keyof NeedsSplitAdjustment> = (
  root: PricingDataRoot,
  target: Date,
  company: CompanyIdFields,
  x: NeedsSplitAdjustment[K]
) => Promise<SplitAdjusted[K]>
export const splitAdjustTrade: SplitAdjustFn<"trade"> = (db, target, company, x) =>
  splitAdjust(db, target, company, "trade", x)
export const splitAdjustOrder: SplitAdjustFn<"order"> = (db, target, company, x) =>
  splitAdjust(db, target, company, "order", x)
export const splitAdjustCompany: SplitAdjustFn<"company"> = (db, target, company, x) =>
  splitAdjust(db, target, company, "company", x)
export const splitAdjustFundMark: SplitAdjustFn<"fundMark"> = (db, target, company, x) =>
  splitAdjust(db, target, company, "fundMark", x)
