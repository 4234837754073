import { Subtitle } from "@components/typography/Subtitle"

const Module: React.FC<{ title: string; children: React.ReactNode }> = ({ title, children }) => (
  <div className="bg-neutral-white rounded-md shadow-md p-4 flex flex-col gap-y-4">
    <Subtitle subtitle={title} />
    {children}
  </div>
)

export default Module
