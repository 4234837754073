import { Switch } from "@headlessui/react"
import { Set_ } from "common/utils/data/Set"
import { Fragment } from "react"
import { classNames } from "../../../utils/classNames"
import { TableFilter } from "./TableFilter"
import FilterButton from "./FilterButton"
import { TaggedState, MultiSelectTag } from "./types"

type MultiSelectFilterProps = {
  name: string
  options: { name: string; toggle: () => void; active: boolean }[]
  inputType?: "checkbox" | "switch"
  hideName?: boolean
}
const MultiSelectFilter = ({
  name,
  options,
  inputType = "checkbox",
  hideName = false,
}: MultiSelectFilterProps) => (
  <>
    {!hideName ? <p className="font-bold text-xs capitalize">{name}</p> : null}
    <div className="flex flex-wrap md:flex-nowrap text-xs">
      {options.map(({ name: optionName, toggle, active }) => (
        <FilterButton
          key={`${name}:${optionName}`}
          onClick={toggle}
          className="mr-1 mb-1 md:mb-0"
          active={active}
        >
          {inputType === "checkbox" ? (
            <input
              type="checkbox"
              className="h-4 w-4 border-neutral-800 text-accent-500"
              checked={active}
              readOnly
            />
          ) : (
            <Switch checked={active} as={Fragment}>
              <div className="group relative inline-flex h-4 w-8 flex-shrink-0 cursor-pointer items-center justify-center rounded-full">
                <span
                  aria-hidden="true"
                  className={classNames(
                    active ? "bg-accent-600" : "bg-neutral-500",
                    "pointer-events-none absolute mx-auto h-4 w-8 rounded-full transition-colors duration-200 ease-in-out"
                  )}
                />
                <span
                  aria-hidden="true"
                  className={classNames(
                    active ? "translate-x-4" : "translate-x-0",
                    "pointer-events-none absolute left-0 inline-block h-4 w-4 transform rounded-full bg-neutral-white transition-transform duration-200 ease-in-out"
                  )}
                />
              </div>
            </Switch>
          )}
          <span>{optionName}</span>
        </FilterButton>
      ))}
    </div>
  </>
)

type CreateMultiSelectTableFilterConfig = { inputType?: "checkbox" | "switch"; hideName?: boolean }
export const createMultiSelectTableFilter = <ItemType, OptionName extends string>(
  name: string,
  optionNames: readonly OptionName[],
  getItemAttribute: (item: ItemType) => Set<OptionName> | OptionName | null,
  config: CreateMultiSelectTableFilterConfig = {}
) =>
  new TableFilter<
    ItemType,
    TaggedState<OptionName, MultiSelectTag>,
    TaggedState<OptionName, MultiSelectTag>
  >([
    (taggedState) => (item) => {
      if (taggedState === null || taggedState.state.size === 0) return true

      const itemAttribute = getItemAttribute(item)
      return (
        itemAttribute !== null &&
        (typeof itemAttribute === "string"
          ? taggedState.state.has(itemAttribute)
          : Set_.intersection(itemAttribute, taggedState.state).size > 0)
      )
    },
    ({ value: taggedState, onChange }) => (
      <MultiSelectFilter
        name={name}
        inputType={config.inputType}
        options={optionNames.map((optionName) => ({
          name: optionName,
          toggle: () =>
            onChange(
              taggedState === null
                ? { tag: "set", state: new Set([optionName]) }
                : { tag: "set", state: Set_.toggle(optionName)(taggedState.state) }
            ),
          active: taggedState?.state.has(optionName) ?? false,
        }))}
        hideName={config.hideName}
      />
    ),
  ])
