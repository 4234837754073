import { FC, ReactElement } from "react"
import { Slider, SliderMarks } from "@stories/components/Antd"
import numeral from "numeral"

import { DeprecatedNumberInput } from "./DeprecatedNumberInput"
import { classNames } from "../../utils/classNames"

interface SliderInputProps {
  className?: string
  sliderClassName?: string
  sliderStyle?: React.CSSProperties
  sliderTrackStyle?: React.CSSProperties
  id?: string
  label: string
  name: string
  value: number
  numberFormat?: "price" | "percentage" | "default"
  rightAddon?: string | ReactElement
  onChange: (value: number) => void
  min?: number
  max?: number
  scale?: number
  disabled?: boolean
  marks?: SliderMarks
  step?: undefined | null | number
  hideNumberInput?: boolean
  dotSize?: number
}

export const SliderInput: FC<React.PropsWithChildren<SliderInputProps>> = ({
  className,
  sliderClassName,
  sliderStyle,
  sliderTrackStyle,
  id,
  label,
  name,
  numberFormat,
  value,
  rightAddon,
  onChange,
  min = 0,
  max = numberFormat === "percentage" ? 100 : value * 10,
  scale = numberFormat === "percentage" ? 100 : 1,
  step,
  disabled,
  marks,
  hideNumberInput,
  dotSize,
}) => {
  const isPercentage = numberFormat === "percentage"
  const isPrice = numberFormat === "price"

  const _scale = (isPercentage ? 100 : scale) || 1

  const scaledValue = _scale ? value * _scale : value

  const handleChange = (newValue: number) => {
    onChange(newValue / _scale)
  }

  const formatMarkLabel = (labelValue: number) =>
    isPercentage
      ? `${numeral(labelValue).format("0")}%`
      : isPrice
      ? `$${numeral(labelValue).format("0")}`
      : numeral(labelValue).format("0")

  return (
    <div className={className}>
      <label htmlFor={name} className="block text-sm font-medium text-neutral-700">
        {label}
      </label>
      <div className="flex space-x-5 items-center">
        <Slider
          id={`${id ?? ""}-slider`}
          disabled={disabled}
          min={min}
          max={max}
          step={step}
          marks={
            marks || {
              [min]: {
                label: formatMarkLabel(min),
                style: { fontSize: "12px", color: "rgba(0,0,0,.85)" },
              },
              [max]: {
                label: formatMarkLabel(max),
                style: { fontSize: "12px", color: "rgba(0,0,0,.85)" },
              },
            }
          }
          className={classNames("flex-1", sliderClassName)}
          style={sliderStyle}
          trackStyle={sliderTrackStyle}
          value={scaledValue}
          onChange={handleChange}
          handleStyle={{
            width: dotSize || 14,
            height: dotSize || 14,
            top: (20 - (dotSize || 14)) / 2,
          }}
        />
        {!hideNumberInput && (
          <DeprecatedNumberInput
            id={`${id ?? ""}-number-input`}
            disabled={disabled}
            className="w-36 -mt-3"
            value={value}
            name={name}
            onChange={(x) => {
              if (x !== undefined) {
                onChange(x)
              }
            }}
            scale={scale}
            min={min}
            max={max || (isPercentage ? 100 : undefined)}
            numberFormat={numberFormat}
            precision={step && step < 1 ? "decimal" : "integer"}
            addonAfter={rightAddon}
          />
        )}
      </div>
    </div>
  )
}
