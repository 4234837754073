/* eslint-disable max-classes-per-file */
import { WhereFilterOp } from "@firebase/firestore-types"
import { Boxed } from "../../../utils/fp/Boxed"
import { FirebaseCommon, FirestoreDataContext } from "../../../firestore/Interface"

export interface Wrapper<Self extends Wrapper<Self, Rep>, Rep> {
  update: (f: (r: Rep) => Rep) => Self
}

/** @deprecated */
export abstract class FirebaseQuery<T, Self extends Wrapper<Self, FirebaseCommon.Query<T>>>
  extends Boxed<
    FirestoreDataContext & {
      ref: FirebaseCommon.Query<T>
    }
  >
  implements Wrapper<Self, FirebaseCommon.Query<T>>
{
  abstract update: (f: (r: FirebaseCommon.Query<T>) => FirebaseCommon.Query<T>) => Self

  empty: () => Promise<boolean> = () => this.count().then((x) => x === 0)

  count: () => Promise<number> = () => this._rawGet().then((x) => x.length)

  limit = (n: number) => this.update((q) => q.limit(n))

  where = (path: string, op: WhereFilterOp, value: unknown) =>
    this.update((q) => q.where(path, op, value))

  orderBy = (field: string, order: "desc" | "asc") => this.update((q) => q.orderBy(field, order))

  protected _rawGet = (): Promise<T[]> =>
    this.unboxed.ref
      .get()
      .then((x) => x.docs.map((doc) => doc.data()).flatMap((doc) => (doc ? [doc] : [])))
}
