import { wrapIcon } from "./IconWrapper"

export const ChevronDoubleLeftIcon = wrapIcon(
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.64001 5.52667C7.57803 5.46418 7.5043 5.41459 7.42306 5.38074C7.34182 5.34689 7.25468 5.32947 7.16667 5.32947C7.07866 5.32947 6.99153 5.34689 6.91029 5.38074C6.82905 5.41459 6.75531 5.46418 6.69334 5.52667L4.69334 7.52667C4.63085 7.58864 4.58126 7.66238 4.54741 7.74362C4.51357 7.82486 4.49614 7.91199 4.49614 8C4.49614 8.08801 4.51357 8.17515 4.54741 8.25638C4.58126 8.33762 4.63085 8.41136 4.69334 8.47333L6.69334 10.4733C6.75531 10.5358 6.82905 10.5854 6.91029 10.6193C6.99153 10.6531 7.07866 10.6705 7.16667 10.6705C7.25468 10.6705 7.34182 10.6531 7.42306 10.6193C7.5043 10.5854 7.57803 10.5358 7.64001 10.4733C7.70249 10.4114 7.75209 10.3376 7.78593 10.2564C7.81978 10.1751 7.8372 10.088 7.8372 10C7.8372 9.91199 7.81978 9.82486 7.78593 9.74362C7.75209 9.66238 7.70249 9.58864 7.64001 9.52667L6.10667 8L7.64001 6.47333C7.70249 6.41136 7.75209 6.33762 7.78593 6.25638C7.81978 6.17515 7.8372 6.08801 7.8372 6C7.8372 5.91199 7.81978 5.82485 7.78593 5.74362C7.75209 5.66238 7.70249 5.58864 7.64001 5.52667ZM9.77334 8L11.3333 6.47333C11.4589 6.3478 11.5294 6.17753 11.5294 6C11.5294 5.82247 11.4589 5.6522 11.3333 5.52667C11.2078 5.40113 11.0375 5.33061 10.86 5.33061C10.6825 5.33061 10.5122 5.40113 10.3867 5.52667L8.38667 7.52667C8.32419 7.58864 8.27459 7.66238 8.24074 7.74362C8.2069 7.82486 8.18947 7.91199 8.18947 8C8.18947 8.08801 8.2069 8.17515 8.24074 8.25638C8.27459 8.33762 8.32419 8.41136 8.38667 8.47333L10.3867 10.4733C10.4486 10.5358 10.5224 10.5854 10.6036 10.6193C10.6849 10.6531 10.772 10.6705 10.86 10.6705C10.948 10.6705 11.0351 10.6531 11.1164 10.6193C11.1976 10.5854 11.2714 10.5358 11.3333 10.4733C11.3958 10.4114 11.4454 10.3376 11.4793 10.2564C11.5131 10.1751 11.5305 10.088 11.5305 10C11.5305 9.91199 11.5131 9.82486 11.4793 9.74362C11.4454 9.66238 11.3958 9.58864 11.3333 9.52667L9.77334 8Z"
      fill="currentColor"
    />
  </svg>
)
