import { firestoreConverter } from "@model/FirestoreConverter"
import { collectionReferences, collections } from "common/firestore/Collections"
import { ConnectThread } from "common/model/Messaging/ConnectThread"
import { nullableToMaybe } from "common/containers/Maybe"
import { useDbState, useSimpleQuerySnapshot } from "src/utils/useDb"
import { useDocumentSnapshot } from "src/utils/hooks/queries/useDocumentSnapshot"
import { OrderIdFields } from "common/model/Order/Order"
import { oneOrNone } from "src/firebase/Firebase9"
import { useEffect, useState } from "react"
import { useFirebaseWriter } from "src/firebase/Context"
import { ConnectMessage } from "common/model/Messaging/Message"
import { Loading } from "common/utils/Loading"
import { hydrateConnectThread, setUserLastReadDate } from "common/model/Messaging/Utils/queries"
import { getAllDocs } from "src/firebase/Firebase/utils"
import { handleConsoleError } from "src/utils/Tracking"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { isUserTyping, UserTypingStatus } from "common/model/Messaging/UserTypingStatus"
import { UserPublicFields } from "common/model/User"
import { useUpsert } from "src/utils/hooks/state/useUpsert"
import { subDays } from "date-fns"

export const useOrderThread = (order: Pick<OrderIdFields, "id">) =>
  useDbState(
    (db) =>
      collectionReferences
        .connectThreads(db.db)
        .where("linkedOrder.id", "==", order.id)
        .get()
        .then((result) => oneOrNone(result) ?? null),
    [order.id]
  )

export const useThreadMetadata = (threadId: string | null, deps?: unknown[]) =>
  useDocumentSnapshot(
    (db) =>
      threadId
        ? db.db
            .collection(collections.messageThreads)
            .doc(threadId)
            .withConverter<ConnectThread>(firestoreConverter<ConnectThread>())
        : null,
    [threadId, ...(deps ?? [])]
  )

export const useLoggedInUserThreads = () => {
  const currentUser = useLoggedInUser()
  return useSimpleQuerySnapshot(
    (db) =>
      db.db
        .collection(collections.messageThreads)
        .withConverter<ConnectThread>(firestoreConverter<ConnectThread>())
        .where("participatingAccountIds", "array-contains", currentUser.user.account.id)
        .where("_mostRecentReadDate", ">", subDays(new Date(), 30)),
    [currentUser.user.id]
  )
}

export const useThreadTypingStatuses = (threadId: string) => {
  const firebase = useFirebaseWriter()
  const [userTypingStatusDocs, setUserTypingStatusDocs] =
    useState<Loading<UserTypingStatus[]>>("loading")
  const [userTypingStatuses, setUserTypingStatuses] = useState<
    { user: UserPublicFields; isUserTyping: boolean }[]
  >([])

  useEffect(() => {
    const cleanup = firebase.db
      .collection(collections.messageThreads)
      .doc(threadId)
      .collection(collections.messageThreadSubcollections.userTypingStatuses)
      .withConverter(firestoreConverter<UserTypingStatus>())
      .onSnapshot((result) => {
        setUserTypingStatusDocs(result.docs.map((doc) => doc.data()))
      })

    return cleanup
  }, [firebase.db, threadId])

  useEffect(() => {
    if (userTypingStatusDocs === "loading" || !userTypingStatusDocs) return () => {}
    const interval = setInterval(() => {
      const typingStatuses = userTypingStatusDocs.map((doc) => ({
        user: doc.user,
        isUserTyping: isUserTyping(doc),
      }))
      setUserTypingStatuses(typingStatuses)
    }, 250)

    return () => {
      clearInterval(interval)
    }
  }, [userTypingStatusDocs])

  return userTypingStatuses
}

export const useAllActiveThreads = () =>
  useSimpleQuerySnapshot(
    (db) =>
      db.db
        .collection(collections.messageThreads)
        .withConverter<ConnectThread>(firestoreConverter<ConnectThread>()),
    []
  )

export const useThread = (threadId: string | null) => {
  const firebase = useFirebaseWriter()
  const [state, setState] = useUpsert<Record<string, ConnectMessage[] | "loading" | null>>(
    threadId ? { [threadId]: "loading" } : {}
  )
  const { user } = useLoggedInUser()
  useEffect(() => {
    if (threadId) {
      firebase.db
        .collection(collections.messageThreads)
        .doc(threadId)
        .withConverter<ConnectThread>(firestoreConverter<ConnectThread>())
        .get()
        .then((t) => nullableToMaybe(t.data()))
        .then((t) => t.match(hydrateConnectThread(firebase.writerDb), () => null))
        .then((t) => setState({ [threadId]: t?.messages }))
        .catch(handleConsoleError)
      setUserLastReadDate(firebase.db)({ threadId, user, account: user.account }).catch(
        handleConsoleError
      )
      return firebase.db
        .collection(collections.messageThreads)
        .doc(threadId)
        .collection(collections.messageThreadSubcollections.messages)
        .orderBy("date", "asc")
        .withConverter<ConnectMessage>(firestoreConverter<ConnectMessage>())
        .onSnapshot({
          next: (doc) => {
            setState({
              [threadId]: getAllDocs(doc).sort((l, r) => l.date.valueOf() - r.date.valueOf()),
            })
          },
        })
    }
    return () => null
  }, [firebase.db, firebase.writerDb, setState, threadId, user])

  return state
}
