import { FeatureFlag, FeatureFlagEnabledRecord } from "common/model/FeatureFlag/FeatureFlag"
import { isFlagEnabled } from "common/model/FeatureFlag/FeatureFlagUtils"
import { useContext } from "react"
import { nullableToMaybe } from "common/containers/Maybe"
import { defaultIfLoading, Loading, Loading_, bindLoading } from "common/utils/Loading"
import { useCurrentUser } from "../currentUser/useCurrentUser"
import { FeatureFlagsContext } from "./FeatureFlagsProvider"
import { CurrentUser } from "@model/CurrentUser"

export const useAllFeatureFlags = () => useContext(FeatureFlagsContext).featureFlags

export const useAdminFeatureFlagDrawer = () =>
  useContext(FeatureFlagsContext).setFeatureFlagsDrawerOpen

const isFlagEnabledForCurrentUser = ({
  flag: nullableFlag,
  currentUser,
}: {
  flag: FeatureFlag | undefined
  currentUser: Loading<CurrentUser>
}) =>
  nullableToMaybe(nullableFlag).match(
    (flag) =>
      isFlagEnabled(
        flag,
        Loading_.toMaybe(currentUser).map(({ user }) => ({
          userId: user.id,
          accountId: user.account.id,
        }))
      ),
    () => false
  )

export const useEnabledFeatureFlags = (): Loading<FeatureFlagEnabledRecord> => {
  const currentUser = useCurrentUser()
  const allFeatureFlags = useAllFeatureFlags()

  return bindLoading(
    (loadedFeatureFlags) =>
      Object.fromEntries(
        Object.values(loadedFeatureFlags).map(
          (flag) => [flag.name, isFlagEnabledForCurrentUser({ flag, currentUser })] as const
        )
      ),
    allFeatureFlags
  )
}

export const useFeatureFlag = (flagName: FeatureFlag["name"]): boolean => {
  const currentUser = useCurrentUser()
  const allFeatureFlags = defaultIfLoading(useAllFeatureFlags(), {})

  return isFlagEnabledForCurrentUser({ flag: allFeatureFlags[flagName], currentUser })
}
