import { PageLayout } from "@components/lib/PageLayout"
import { deprecatedIsLoaded, isLoaded } from "common/utils/Loading"
import { assertUnreachable } from "common/utils/fp/Function"
import { useNavigate } from "react-router-dom"
import { AuthFailureReason } from "src/Routes/auth/AuthCondition"
import { useCurrentUser } from "../../providers/currentUser/useCurrentUser"
import { Button } from "../../stories/components/Button/Button"
import { AuthWallPage } from "./AuthWallPage"
import { Routes } from "src/Routes/Routes"

const DefaultAuthFailurePage = () => {
  const navigate = useNavigate()
  const user = useCurrentUser()

  const signedIn = deprecatedIsLoaded(user) && user

  const navigateHome = () => {
    if (!signedIn) {
      navigate("/sign-in")
    } else {
      navigate(Routes.root)
    }
  }

  const shouldDisplayJsonContext =
    isLoaded(user) && (user.isAdmin || window.location.host.includes("localhost"))

  return (
    <PageLayout>
      <div className="mt-20 min-h-screen">
        <div className="flex flex-col items-center justify-center space-y-8">
          <div className="text-lg font-semibold text-center">
            The page you were trying to access does not exist or you do not have access.
          </div>
          {shouldDisplayJsonContext && (
            <div className="w-3/4-screen bg-neutral-300 border overflow-scroll min-h-80 max-h-3/4-screen">
              <pre>{JSON.stringify(user.user.account, null, 2)}</pre>
            </div>
          )}
          <Button
            variant="primary"
            leftIcon={null}
            rightIcon={null}
            label={signedIn ? "Back to platform home" : "Sign In"}
            isDisabled={false}
            onClick={navigateHome}
          />
        </div>
      </div>
    </PageLayout>
  )
}

export const AuthFailurePage = ({ reason = "unspecified" }: { reason?: AuthFailureReason }) => {
  switch (reason) {
    case "contribution required":
      return <AuthWallPage variant="contribution-required" />
    case "trial expired":
      return <AuthWallPage variant="expired-trial" />
    case "quota nonCompliant":
      return <AuthWallPage variant="quota-non-compliant" />
    case "unspecified":
      return <DefaultAuthFailurePage />
    default:
      return assertUnreachable(reason)
  }
}
