import { Func } from "./fp/Function"
import { Just, Maybe, Nothing } from "../containers/Maybe"
import { Cons, Nonempty } from "./data/Array/Nonempty"
import { Union } from "./types/Union"
import { Either, Left, Right } from "../containers/Either"

/**
 * If `k` is not present, inserts `[v]`. Otherwise, appends `v` to the end of the array at `k`.
 * Mutates the provided map.
 */
export const unsafeInsertOrAppend =
  <X, Y>(m: Map<X, Nonempty<Y>>) =>
  (k: X, v: Y): void => {
    if (!m.has(k)) {
      m.set(k, [v])
    } else {
      m.set(k, (m.get(k) as Nonempty<Y>).concat([v]) as Nonempty<Y>)
    }
  }

/**
 * If `k` is not present, inserts `[v]`. Otherwise, appends `v` to the end of the array at `k`.
 */
export const insertOrAppend =
  <X, Y>(m: Map<X, Nonempty<Y>>) =>
  (k: X, v: Y): Map<X, Nonempty<Y>> => {
    const newMap = new Map(m)
    unsafeInsertOrAppend(newMap)(k, v)
    return newMap
  }

/**
 * Takes an array `ts`, and a function `f` for mapping values to keys, and returns a `Map` where the element at `k` is an array containing every `t` in `ts` mapped to `k`.
 */
export const groupBy = <K, T>(f: (t: T) => K, ts: T[]): Map<K, Nonempty<T>> => {
  const result = new Map<K, Nonempty<T>>()
  ts.forEach((t) => {
    unsafeInsertOrAppend(result)(f(t), t)
  })
  return result
}

/**
 * Map over both indices of a Map<,>
 */
export const bimapMap =
  <K, K2, V, V2>(keyfunc: (k: K) => K2, valfunc: (v: V) => V2) =>
  (x: Map<K, V>): Map<K2, V2> =>
    new Map(Array.from(x).map(([k, v]) => [keyfunc(k), valfunc(v)]))

/**
 * Map over the second index of a Map<,>
 */
export const map2Map =
  <K, V>(x: Map<K, V>) =>
  <W>(func: (v: V) => W) =>
    bimapMap((a: K) => a, func)(x)

/**
 * Map over the first index of a Map <,>
 */
export const map1Map =
  <K, L>(func: (v: K) => L) =>
  <V>(x: Map<K, V>): Map<L, V> =>
    bimapMap(func, (a: V) => a)(x)

export const deleteKey = <K, V>(m: Map<K, V>, key: K): Map<K, V> => {
  const result = copyMap(m)
  m.delete(key)
  return result
}
export const insert =
  <K, V>(m: Map<K, V>, key: K) =>
  (val: V): Map<K, V> =>
    copyMap(m).set(key, val)

export const overKey = <K, V>(m: Map<K, V>, key: K, f: Func<Maybe<V>, Maybe<V>>): Map<K, V> =>
  f(safeGet(m, key)).matchStrict(insert(m, key), m.has(key) ? deleteKey(m, key) : m)
export const getOrDefault = <K, V, W>(m: Map<K, V>, key: K, defaultValue: W): Either<W, V> =>
  m.has(key) ? Right(m.get(key) as V) : Left(defaultValue)
export const safeGet = <K, V>(m: Map<K, V>, key: K): Maybe<V> =>
  getOrDefault(m, key, undefined).match(
    () => Nothing,
    (x) => Just(x)
  )

export const copyMap = <K, V>(m: Map<K, V>): Map<K, V> => new Map([...m])

type MapValues<M extends Map<unknown, unknown>[]> = M extends Cons<
  Map<unknown, infer V>,
  infer T extends Map<unknown, unknown>[]
>
  ? [V, ...MapValues<T>]
  : []

export const concatMaps = <K, M extends Map<K, unknown>[]>(...ms: M): Map<K, Union<MapValues<M>>> =>
  ms.reduce(
    (prev, next) => [...next.entries()].reduce((acc, [k, v]) => acc.set(k, v), prev),
    new Map<K, Union<MapValues<M>>>()
  ) as Map<K, Union<MapValues<M>>>

export const transposeMap = <K, V>(x: Map<K, V[]>): Map<V, Nonempty<K>> => {
  const out = new Map<V, Nonempty<K>>()
  Array.from(x.entries()).forEach((entry) => {
    entry[1].forEach((val) => {
      unsafeInsertOrAppend(out)(val, entry[0])
    })
  })
  return out
}
