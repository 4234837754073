import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { Account, isIntermediaryAccount } from "common/model/Account"
import { FC } from "react"
import { useFeatureFlag } from "src/providers/featureFlags/useFeatureFlags"
import { SettingsSection } from "./SettingsModal"
import { useAccountTrustedBrokerConnections } from "src/providers/AccountTrustedBrokerConnections"
import { getPendingInboundInvites } from "common/model/TrustedBrokerConnection"
import { defaultIfLoading } from "common/utils/Loading"
import UnreadIcon from "@stories/components/Badges/UnreadIcon/UnreadIcon"

const SettingsTab = ({
  section,
  isActive,
  icon,
  onClick,
}: {
  section: SettingsSection
  icon?: React.ReactNode
  isActive: boolean
  onClick: (section: SettingsSection) => void
}) => (
  <div
    className={`flex items-center justify-between cursor-pointer py-1 px-2 rounded-md ${
      isActive ? "bg-neutral-200" : ""
    }`}
    onClick={() => onClick(section)}
  >
    <Typography
      text={section}
      size={Size.Small}
      weight={isActive ? Weight.Semibold : Weight.Regular}
    />
    {icon}
  </div>
)

interface SettingsNavigationSidebarProps {
  activeSection: SettingsSection
  setActiveSection: (section: SettingsSection) => void
  account: Account
}

const useAccountSettingsSections = (
  account: Account
): Array<{ name: string; sections: { tag: SettingsSection; icon?: React.ReactNode }[] }> => {
  const crmFeatureEnabled = useFeatureFlag("crm")
  const emailFeatureEnabled = useFeatureFlag("crm_emails_integration")
  const trustedBrokerNetworkConnectionsEnabled = isIntermediaryAccount(account)
  const brokerConnections = useAccountTrustedBrokerConnections()
  const pendingBrokerConnections = getPendingInboundInvites(
    account,
    defaultIfLoading(brokerConnections, [])
  )

  const userSection = {
    name: "User Preferences",
    sections: [{ tag: "Notifications" as const }],
  }

  const crmSection = crmFeatureEnabled
    ? [
        {
          name: "CRM Settings",
          sections: emailFeatureEnabled
            ? [{ tag: "Orders & Deals" as const }, { tag: "Email Sync" as const }]
            : [{ tag: "Orders & Deals" as const }],
        },
      ]
    : []

  const brokerNetworkSection = trustedBrokerNetworkConnectionsEnabled
    ? [
        {
          name: "Team",
          sections: [
            { tag: "Trusted Broker Network" as const },
            {
              tag: "Invitations" as const,
              icon: pendingBrokerConnections.length ? (
                <UnreadIcon count={pendingBrokerConnections.length} variant="accent" />
              ) : null,
            },
          ],
        },
      ]
    : []

  // TODO: add this in
  // const accountSection = {
  //   name: "Account",
  //   sections: ["Account Plan", "Team"] satisfies SettingsSection[],
  // }

  return [userSection, ...crmSection, ...brokerNetworkSection]
}

export const SettingsNavigationSidebar: FC<SettingsNavigationSidebarProps> = ({
  account,
  activeSection,
  setActiveSection,
}) => {
  const settingsSections = useAccountSettingsSections(account)

  return (
    <div className="flex flex-col gap-4 w-[200px]">
      {settingsSections.map((setting) => (
        <div key={setting.name} className="flex flex-col gap-1">
          <Typography
            text={setting.name}
            size={Size.XSmall}
            weight={Weight.Semibold}
            color={Color.Neutral800}
          />
          {setting.sections.map((section) => (
            <SettingsTab
              key={section.tag}
              section={section.tag}
              icon={section.icon}
              isActive={activeSection === section.tag}
              onClick={setActiveSection}
            />
          ))}
        </div>
      ))}
    </div>
  )
}
