import _ from "lodash"

export type KVP<K, V> = { key: K; value: V }
export type AssociationList<K, V> = KVP<K, V>[]

export namespace AssociationList_ {
  export const toMap = <K, V>(a: AssociationList<K, V>): Map<K, V> =>
    a.reduce((acc, x) => acc.set(x.key, x.value), new Map<K, V>())
  export const fromMap = <K, V>(m: Iterable<[K, V]>): AssociationList<K, V> =>
    [...m].map(([k, v]) => ({ key: k, value: v }))
}

export const AssociationListContext = <K>(eq: (l: K, r: K) => boolean) => {
  const x = {
    hasKey:
      <K2 extends K, V>(k: K2) =>
      (a: AssociationList<K2, V>): boolean =>
        a.some(({ key: k2 }) => eq(k, k2)),
    insert:
      <K2 extends K, V>(k: K2, v: V) =>
      (a: AssociationList<K2, V>): AssociationList<K2, V> =>
        x.hasKey(k)(a) ? a : [{ key: k, value: v }].concat(a),
    upsert:
      <K2 extends K, V>(k: K2, v: V, f: (x: V, y: V) => V) =>
      (a: AssociationList<K2, V>): AssociationList<K2, V> =>
        x.hasKey(k)(a)
          ? a.map(({ key, value }) => (eq(k, key) ? { key, value: f(v, value) } : { key, value }))
          : [{ key: k, value: v }].concat(a),
    /** left-biased */
    union: <K2 extends K, V>(
      l: AssociationList<K2, V>,
      r: AssociationList<K2, V>
    ): AssociationList<K2, V> => r.reduce((a, { key: k, value: v }) => x.insert(k, v)(a), l),
    unionBy: <K2 extends K, V>(
      l: AssociationList<K2, V>,
      r: AssociationList<K2, V>,
      f: (x: V, y: V) => V
    ): AssociationList<K2, V> => r.reduce((a, { key: k, value: v }) => x.upsert(k, v, f)(a), l),

    normalize: <K2 extends K, V>(old: AssociationList<K2, V>): AssociationList<K2, V> =>
      old.reduce(
        (acc: AssociationList<K2, V>, next: KVP<K2, V>) =>
          x.insert<K2, V>(next.key, next.value)(acc),
        []
      ),
  }
  return x
}
export const defaultAssocList = AssociationListContext((l, r) => _.isEqual(l, r))
