/* eslint-disable react/jsx-props-no-spreading */
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid"
import { useState } from "react"
import { Link } from "react-router-dom"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { Routes } from "src/Routes/Routes"
import { Button } from "@stories/components/Button/Button"
import Typography, {
  Weight,
  Color,
  Size,
  TypographyProps,
} from "@stories/components/Typography/Typography"
import { DarkpoolIcon } from "@stories/icons/DarkpoolIcon"
import { DarkpoolLimitText, MAXIMUM_LIVE_DARKPOOL_ORDERS } from "../MyOrders/DarkpoolLimitText"
import { OrderPageLoadingOrder } from "../OrderPage/types"
import { useDarkpoolState } from "./contextHooks/useDarkpoolState"
import { useAccountBrokerLinking } from "src/utils/useAccountBrokerLinking"
import { useAccountCompliance } from "../../../providers/data/AccountComplianceProvider"
import { matchLoading } from "common/utils/Loading"

export const TextList: React.FC<
  React.PropsWithChildren<Omit<TypographyProps, "text"> & { options: TypographyProps["text"][] }>
> = (props) => (
  <div className="space-y-1">
    {props.options.map((text, key) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={key} className="flex items-start space-x-2">
        <div>
          <Typography {...props} text="&#x2022;" />
        </div>
        <div>
          <Typography {...props} text={text} />
        </div>
      </div>
    ))}
  </div>
)

export const DarkpoolOrderFormWarning = () => {
  const { isDarkpoolMode } = useDarkpoolState()
  const [open, setOpen] = useState(false)
  const { maxLiveDarkpoolOrderCount } = useAccountCompliance()
  const maxCount = matchLoading(
    maxLiveDarkpoolOrderCount,
    (count) => count,
    MAXIMUM_LIVE_DARKPOOL_ORDERS,
    MAXIMUM_LIVE_DARKPOOL_ORDERS
  )
  return isDarkpoolMode ? (
    <div className=" rounded flex flex-col space-y-2 p-3 from-primary-900 bg-gradient-to-t bg-primary-500">
      <div className="flex items-center space-x-2">
        <DarkpoolIcon color={Color.White} />
        <Typography
          text="You are submitting a Darkpool Order"
          weight={Weight.Semibold}
          color={Color.White}
          size={Size.Small}
        />
      </div>
      {open ? (
        <>
          <TextList
            color={Color.White}
            size={Size.XSmall}
            options={[
              "Darkpool orders will not be shown to the market, but can be used to submit connection requests to others.",
              `You can only have ${maxCount} Darkpool orders live at any time.`,
              <>
                <span>
                  {"You can track your Darkpool orders from "}
                  <Link to={Routes.myOrders}>My Orders.</Link>
                </span>
              </>,
            ]}
          />
          <div className="border rounded-md border-primary-400 p-2">
            <Typography
              text="Placing a Darkpool order greatly reduces the chance your order will get filled. For maximum likelihood of a transaction, use Market Visible orders"
              color={Color.White}
              size={Size.XXSmall}
            />
          </div>
          <DarkpoolLimitText weight={Weight.Semibold} color={Color.White} size={Size.Small} />
        </>
      ) : null}
      <div className="m-auto">
        <Button
          variant="hollow-dark"
          leftIcon={open ? <ChevronUpIcon /> : <ChevronDownIcon />}
          onClick={() => setOpen(!open)}
          label={open ? "Hide Details" : "View Details"}
        />
      </div>
    </div>
  ) : null
}

export const DarkpoolOrderDetailWarning = ({ order }: OrderPageLoadingOrder) => {
  const [open, setOpen] = useState(false)
  const user = useLoggedInUser()
  const { isAccountBrokerLinkingEnabled } = useAccountBrokerLinking()
  const accountOwnedOrder = [
    "Darkpool orders will not be shown to the market, but can be used to submit connection requests to others.",
    <>
      <span>
        {"You can track your Darkpool orders from "}
        <Link to={Routes.myOrders}>My Orders.</Link>
      </span>
    </>,
  ]
  const sharedOrder = [
    "Darkpool orders are considered confidential and should not be shared with anyone.",
  ]
  return order.match(
    (o) =>
      o.isDarkpool() ? (
        <div className=" rounded flex flex-col space-y-2 p-3 from-primary-900 bg-gradient-to-t bg-primary-500">
          <div className="flex items-center space-x-2">
            <DarkpoolIcon color={Color.White} />
            <Typography
              text="You are viewing a Darkpool Order"
              weight={Weight.Semibold}
              color={Color.White}
              size={Size.Small}
            />
          </div>
          {open ? (
            <TextList
              color={Color.White}
              options={
                o.fromAccount(user.user.account) ||
                (o.isBrokeredByUser(user.user) && isAccountBrokerLinkingEnabled)
                  ? accountOwnedOrder
                  : sharedOrder
              }
              size={Size.XSmall}
            />
          ) : null}
          <div className="m-auto">
            <Button
              variant="hollow-dark"
              leftIcon={open ? <ChevronUpIcon /> : <ChevronDownIcon />}
              onClick={() => setOpen(!open)}
              label={open ? "Hide Details" : "View Details"}
            />
          </div>
        </div>
      ) : null,
    null,
    null
  )
}
