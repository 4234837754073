import { AccountIdFields } from "common/model/Account"
import { AccountEventCounts } from "./AccountEventCounts"
import HighchartsReact from "highcharts-react-official"
import Highcharts from "highcharts"
import { userEventTypes } from "common/model/UserEvent"
import moment from "moment-timezone"
import { EXCLUDED_EVENTS } from "../engagement/AccountEngagement"

interface AccountEngagementChartProps {
  account: AccountIdFields
  weeklyAccountEvents: AccountEventCounts[]
  monthlyAccountEvents: AccountEventCounts[]
}

const NUM_WEEKS = 12

export const AccountEngagementChart: React.FC<AccountEngagementChartProps> = ({
  account,
  weeklyAccountEvents,
  monthlyAccountEvents,
}) => {
  // Renders a highcharts plot for the past 12 weeks using weeklyAccountEvents with the Y values being the sum of total events in a given week
  // X axis should be the week, formatted as June 1
  // Y axis should be the total number of events in that week
  // The chart should have a title of "Account Engagement" and a subtitle of "Past 12 Weeks"
  // The chart should have a legend with the event types and their colors
  // The chart should have a tooltip that shows the week or month number and the total number of events in that week or month

  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: "Account Engagement",
    },
    subtitle: {
      text: "Past 12 Weeks",
    },
    xAxis: {
      type: "datetime",
      labels: {
        format: "{value:%b %e}",
      },
      min: moment()
        .tz("America/Los_Angeles")
        .startOf("week")
        .subtract(NUM_WEEKS - 1, "weeks")
        .toDate()
        .getTime(),
      max: moment().tz("America/Los_Angeles").startOf("week").toDate().getTime(),
      tickInterval: 7 * 24 * 3600 * 1000, // 1 week in milliseconds
    },
    yAxis: {
      min: 0,
      title: {
        text: "Total Events",
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
      },
    },
    series: userEventTypes
      .filter((ev) => !EXCLUDED_EVENTS.includes(ev))
      .map((event) => ({
        name: event,
        data: Array(NUM_WEEKS)
          .fill(0)
          .map((_, i) =>
            moment()
              .tz("America/Los_Angeles")
              .startOf("week")
              .subtract(NUM_WEEKS - i - 1, "weeks")
              .toDate()
              .getTime()
          )
          .map((week) => {
            const weekEvents = weeklyAccountEvents.find((w) => w.periodDate.getTime() === week)
            return [week, weekEvents?.eventCounts[event] || 0]
          }),
      })),
  }

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}
