import Alert from "@stories/components/Alert/Alert"
import Typography from "@stories/components/Typography/Typography"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { useCRMContacts } from "../../Providers/CRMContactsProvider"
import { CRMContactName } from "../ContactDetailsDrawer/CRMContactName"

export const SimilarContactWarning = ({
  contact,
  beforeClickAction,
}: {
  contact: DealCRMContact
  beforeClickAction?: () => void
}) => {
  const { contacts } = useCRMContacts()

  if (!contact || !contact.name) return null

  const similarContacts = contacts.filter((c) => {
    const capContactName = c.name?.toUpperCase()
    return [
      contact.name.toUpperCase().length > 1
        ? capContactName.includes(contact.name.toUpperCase())
        : false,
    ].some((v) => !!v)
  })

  return similarContacts.length ? (
    <Alert
      variant="warning"
      message={
        <div className="flex items-center gap-2">
          <Typography text="Similar to" />
          {similarContacts.slice(0, 2).map((c) => (
            <CRMContactName contact={c} key={c.id} beforeClickAction={beforeClickAction} />
          ))}
          {similarContacts.slice(2).length ? (
            <Typography text={`plus ${similarContacts.slice(2).length} more`} />
          ) : null}
        </div>
      }
    />
  ) : null
}
