import { collections, restrictedCollections } from "common/firestore/Collections"
import { Account } from "common/model/Account"
import { firestoreConverter } from "common/model/FirestoreConverter"
import { Order } from "common/model/Order/Models/Internal"
import { Firestore, collection, query, where } from "firebase/firestore"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { useFirebase9QuerySnapshot } from "../firebase/useFirebase9Query"
import { bindLoading, Loading, Loading_ } from "common/utils/Loading"

const adminMarketOrdersByAccountQuery = (account: Account, db: Firestore) =>
  query(
    collection(db, restrictedCollections.orderObservations).withConverter(
      firestoreConverter<Order>()
    ),
    where("source.account.id", "==", account.id)
  )

const adminDarkpoolOrdersByAccountQuery = (account: Account, db: Firestore) =>
  query(
    collection(db, restrictedCollections.darkpoolOrderObservations).withConverter(
      firestoreConverter<Order>()
    ),
    where("source.account.id", "==", account.id)
  )

const adminTentativeOrdersByAccountQuery = (account: Account, db: Firestore) =>
  query(
    collection(db, collections.tentativeInterest).withConverter(firestoreConverter<Order>()),
    where("source.account.id", "==", account.id)
  )

export const useAdminOrdersByAccountQuery = (account: Account): Loading<Order[]> => {
  if (!useLoggedInUser().isAdmin) {
    throw new Error("useAdminOrdersByAccountQuery should not be used by non-admins")
  }

  const marketOrders = useFirebase9QuerySnapshot(
    (db) => adminMarketOrdersByAccountQuery(account, db),
    [account]
  )

  const darkpoolOrders = useFirebase9QuerySnapshot(
    (db) => adminDarkpoolOrdersByAccountQuery(account, db),
    [account]
  )

  const tentativeOrders = useFirebase9QuerySnapshot(
    (db) => adminTentativeOrdersByAccountQuery(account, db),
    [account]
  )

  return bindLoading(
    (loadedOrderArrays) => loadedOrderArrays.flat(),
    Loading_.Array.sequence([marketOrders, darkpoolOrders, tentativeOrders])
  )
}
