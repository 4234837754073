import { InfoCircleOutlined } from "@ant-design/icons"
import Alert from "@stories/components/Alert/Alert"
import { CheckCircleIcon } from "@stories/icons/CheckCircleIcon"
import { readableQuotaComplianceViolation } from "common/model/Order/QuotaCompliance/runQuotaComplianceChecks"
import { deprecatedIsLoaded } from "common/utils/Loading"
import { isNonempty, nonemptyHead } from "common/utils/data/Array/Nonempty"
import FeatureWrapper from "src/providers/featureFlags/FeatureWrapper"
import { useCurrentAccount } from "src/queries/currentUser/useCurrentAccount"
import { useOrderForm } from "./OrderFormContext"
import { useOrderQuotaCompliance } from "./contextHooks/useOrderQuotaCompliance"

export const OrderQuotaComplianceWarning = () => {
  const { formOrder, brokerEditingClientOrder, company } = useOrderForm()
  const { orderQuotaComplianceViolations } = useOrderQuotaCompliance(formOrder, company)

  const account = useCurrentAccount()

  if (deprecatedIsLoaded(account) && !account.isQuotaSystemEnabled) {
    return null
  }
  if (brokerEditingClientOrder) {
    return null
  }

  const humanReadableDirection = (formOrder?.direction ?? "order") === "buy" ? "bid" : "ask"

  return (
    <FeatureWrapper flagName="is_order_quota_system_live">
      {orderQuotaComplianceViolations?.length ? (
        <Alert
          icon={<InfoCircleOutlined />}
          headline={`This ${humanReadableDirection} will not count towards your Order Quota.`}
          message={
            isNonempty(orderQuotaComplianceViolations)
              ? readableQuotaComplianceViolation(nonemptyHead(orderQuotaComplianceViolations))
              : ""
          }
          variant="warning"
        />
      ) : orderQuotaComplianceViolations ? (
        <Alert
          icon={<CheckCircleIcon />}
          message={`This ${humanReadableDirection} will count towards your Order Quota.`}
          variant="accent"
        />
      ) : null}
    </FeatureWrapper>
  )
}
