import { enumValidator, stringValidator, validateString, Validator } from "../../utils/Validator"
import { PythonServerNull } from "./PostgresCommon"

export const MANUALLY_CONFIRMED = "Manual confirm" as const
export interface PostgresCompanyComp {
  displayed_company_id: string
  company_name: string
  ticker: string | PythonServerNull
  status: typeof MANUALLY_CONFIRMED | string | PythonServerNull
  description: string
}
export const validatePostgresCompanyComp: Validator<unknown, PostgresCompanyComp> =
  Validator.fromRecord({
    displayed_company_id: validateString,
    company_name: validateString,
    ticker: stringValidator.or(enumValidator(null)).validate,
    status: stringValidator.or(enumValidator(null)).validate,
    description: validateString,
  })
