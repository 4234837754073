import moment from "moment"

export interface UserOnlineStatus {
  id: string
  userId: string
  lastStatus: "active" | "idle"
  lastStatusChange: Date
  isAdmin?: boolean
}

export type OnlineStatus = UserOnlineStatus["lastStatus"] | "offline"

export interface DerivedUserOnlineStatus {
  userId: string
  lastStatus: OnlineStatus
  lastSeen?: string
}

export const getDerivedOnlineStatus = (status: UserOnlineStatus): DerivedUserOnlineStatus => {
  // User considered offline if more than 2 minutes since their last 'acitve' status update
  // or 15 mintues since their last 'idle' status update
  const OFFLINE_IDLE_THRESHOLD_MINUTES = 15
  const OFFLINE_ACTIVE_THRESHOLD_MINUTES = 2
  const lastStatusChange = moment(status.lastStatusChange)
  const lastSeen = moment(status.lastStatusChange).fromNow()
  if (
    status.lastStatus === "idle" &&
    lastStatusChange.isBefore(moment().subtract(OFFLINE_IDLE_THRESHOLD_MINUTES, "minutes"))
  ) {
    return { userId: status.userId, lastStatus: "offline", lastSeen }
  } else if (
    status.lastStatus === "active" &&
    lastStatusChange.isBefore(moment().subtract(OFFLINE_ACTIVE_THRESHOLD_MINUTES, "minutes"))
  ) {
    return {
      userId: status.userId,
      lastStatus: "offline",
      lastSeen,
    }
  }

  return { userId: status.userId, lastStatus: status.lastStatus, lastSeen }
}

export const offlineUserStatus = (userId: string): DerivedUserOnlineStatus => ({
  userId,
  lastStatus: "offline",
})
