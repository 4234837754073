import { OrderCard } from "@components/ItemCards/OrderCard"
import { DarkpoolOrderUpdatedEvent } from "common/model/AccountActivityLog/AccountActivityLog"
import React from "react"

interface DarkpoolOrderUpdatedDetailsProps {
  event: DarkpoolOrderUpdatedEvent
}

export const DarkpoolOrderUpdatedDetails: React.FC<DarkpoolOrderUpdatedDetailsProps> = ({
  event,
}) => {
  const { order } = event

  return <OrderCard order={order} hideDate />
}
