import React from "react"

export const enum ScreenSize {
  small = "small",
  medium = "medium",
  large = "large",
}

export const smBreakpoints = [0, 768]
export const mdBreakpoints = [769, 1024]
export const lgBreakpoints = [1025, 3460]

const screens = {
  small: {
    min: smBreakpoints[0],
    max: smBreakpoints[1],
    name: ScreenSize.small,
  },
  medium: {
    min: mdBreakpoints[0],
    max: mdBreakpoints[1],
    name: ScreenSize.medium,
  },
  large: {
    min: lgBreakpoints[0],
    max: lgBreakpoints[1],
    name: ScreenSize.large,
  },
}

export type Viewport = {
  size: ScreenSize | null
  width: number
  height: number
}
export const useViewport = (): Viewport => {
  const [breakpoint, setBreakpoint] = React.useState<ScreenSize | null>(null)
  const [width, setWidth] = React.useState(window.innerWidth)
  const [height, setHeight] = React.useState(window.innerHeight)

  React.useEffect(() => {
    const handleResize = () => {
      const viewportWidth = window.visualViewport ? window.visualViewport.width : window.innerWidth
      const screenWidth = Math.min(window.innerWidth, viewportWidth)

      setWidth(screenWidth)
      setHeight(window.innerHeight)

      if (screenWidth <= screens.small.max) {
        setBreakpoint(screens.small.name)
        return
      }

      if (screenWidth >= screens.medium.min && screenWidth <= screens.medium.max) {
        setBreakpoint(screens.medium.name)
        return
      }

      setBreakpoint(screens.large.name)
    }

    window.addEventListener("resize", handleResize)
    handleResize()

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return {
    size: breakpoint,
    width,
    height,
  }
}

export const useLoadingViewport = () => {
  const [breakpoint, setBreakpoint] = React.useState<ScreenSize>()
  const [width, setWidth] = React.useState(window.innerWidth)
  const [height, setHeight] = React.useState(window.innerHeight)

  React.useEffect(() => {
    const handleResize = () => {
      const viewportWidth = window.visualViewport ? window.visualViewport.width : window.innerWidth
      const screenWidth = Math.min(window.innerWidth, viewportWidth)

      setWidth(screenWidth)
      setHeight(window.innerHeight)

      if (screenWidth <= screens.small.max) {
        setBreakpoint(screens.small.name)
        return
      }

      if (screenWidth >= screens.medium.min && screenWidth <= screens.medium.max) {
        setBreakpoint(screens.medium.name)
        return
      }

      setBreakpoint(screens.large.name)
    }

    window.addEventListener("resize", handleResize)
    handleResize()

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return {
    size: breakpoint,
    width,
    height,
  }
}

export const isSmallOrMediumScreen = (size: ScreenSize | null) =>
  size === ScreenSize.small || size === ScreenSize.medium
