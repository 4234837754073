import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { DarkpoolIcon } from "@stories/icons/DarkpoolIcon"
import { IconWrapperProps } from "@stories/icons/IconWrapper"
import { LiveMarketIcon } from "@stories/icons/LiveMarketIcon"
import { PrivateVisibilityIcon } from "@stories/icons/PrivateVisibilityIcon"
import { ShareIcon } from "@stories/icons/ShareIcon"
import { MyOrderVisibility } from "src/pages/Orders/MyOrders/MyOrdersTypes"
import { classNames } from "src/utils/classNames"

type OrderVisibilityPillProps = { variant: MyOrderVisibility; size: "sm" | "md" }

const OrderVisibilityPill = ({ variant, size }: OrderVisibilityPillProps) => {
  const Icon = variantIconMap[variant]

  return (
    <div
      className={classNames(
        "px-2 py-1 rounded flex space-x-1 items-center whitespace-nowrap",
        variantBgColorMap[variant]
      )}
    >
      <Icon color={variantTextColorMap[variant]} size={size === "md" ? "medium" : "small"} />
      <Typography
        size={size === "md" ? Size.XSmall : Size.XXSmall}
        weight={Weight.Bold}
        color={variantTextColorMap[variant]}
        text={variantTextMap[variant]}
        shouldWrap={false}
      />
    </div>
  )
}

const variantTextColorMap: Record<MyOrderVisibility, Color> = {
  Private: Color.Subtitle,
  Shared: Color.Subtitle,
  Darkpool: Color.White,
  Market: Color.Link,
}

const variantBgColorMap: Record<MyOrderVisibility, string> = {
  Private: "bg-neutral-200",
  Shared: "bg-neutral-200",
  Darkpool: "bg-primary-900",
  Market: "bg-neutral-200",
}

const variantTextMap: Record<MyOrderVisibility, string> = {
  Private: "Private",
  Shared: "In Network",
  Darkpool: "Darkpool",
  Market: "Market Visible",
}

const variantIconMap: Record<MyOrderVisibility, (props: IconWrapperProps) => React.ReactNode> = {
  Private: PrivateVisibilityIcon,
  Shared: ShareIcon,
  Darkpool: DarkpoolIcon,
  Market: LiveMarketIcon,
}

export default OrderVisibilityPill
