import { useModal } from "@components/layout/Modal"
import { Button, ButtonProps } from "@stories/components/Button/Button"
import { SettingsModal, SettingsSection } from "./SettingsModal"
import { GearIcon } from "@stories/icons/GearIcon"
import { isIntermediaryAccount } from "common/model/Account"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"

export const SettingsModalButton = ({
  label,
  section,
  variant = "secondary",
  size,
}: {
  label: string
  section?: SettingsSection
  variant?: ButtonProps["variant"]
  size?: ButtonProps["size"]
}) => {
  const { user } = useLoggedInUser()
  const { open, closeModal, openModal } = useModal()
  const isTrustedBrokerNetworkEnabled = isIntermediaryAccount(user.account)
  if (!isTrustedBrokerNetworkEnabled) return null

  return (
    <div>
      <Button
        label={label}
        onClick={openModal}
        leftIcon={<GearIcon />}
        variant={variant}
        size={size}
      />
      <SettingsModal open={open} onClose={closeModal} defaultSection={section} />
    </div>
  )
}
