/* eslint-disable react/no-unstable-nested-components */
import { CloudUploadOutlined, ZoomInOutlined } from "@ant-design/icons"
import Spinner from "@components/icons/Spinner"
import * as Sentry from "@sentry/react"
import { collections } from "common/firestore/Collections"
import { AccountIdFields, AllAccountsCacheFields } from "common/model/Account"
import { UserIdFields, viewUserIdFields } from "common/model/User"
import { DocumentType } from "common/model/files/DocumentSubmission"
import { useCallback, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { createMockDocumentSubmission } from "src/firebase/Firebase/data-product/DocumentSubmission"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { useFirebaseWriter } from "../firebase/Context"
import { handleConsoleError } from "../utils/Tracking"
import AccountTrialStatus from "./components/AccountTrialStatus"
import ClientTypePill from "./components/ClientTypePill"
import ProductAreasPills from "./components/ProductAreasPills"
import { Button } from "@stories/components/Button/Button"
import { TopBrokerPill } from "@stories/components/Badges/TopBrokerPill/TopBrokerPill"
import { PageTitle } from "@components/typography/PageTitle"
import { AdminAllAccountsCache } from "common/model/AdminAllAccountsCache"
import { defaultIfLoading, isLoading } from "common/utils/Loading"
import { useAdminEngagementDrawer } from "src/providers/AdminProviders/AdminEngagementDrawerProvider"
import Table from "@stories/components/Table/Table"
import { createColumnHelper } from "@tanstack/react-table"
import { useFilter } from "src/utils/hooks/components/useFilter"
import { substringFilter } from "src/pages/Data/TableFilters/Substring"
import { createSingleSelectTableFilter } from "src/pages/Data/TableFilters/SingleSelectFilter"
import { createDropDownFilter } from "src/pages/Data/TableFilters/DropDownFilter"
import { createMultiSelectTableFilter } from "src/pages/Data/TableFilters/MultiSelectFilter"
import { getAccountTradingRegistrationStatusString } from "common/model/Account/AccountTradingRegistration"
import {
  AdminTradingRegistrationTableStatus,
  getTradingRegistrationAdminTableString,
} from "./components/TradingRegistration/AdminTradingRegistrationTableStatus"
import { AdminTradingRegistrationDrawer } from "./components/TradingRegistration/AdminTradingRegistrationDrawer"
import { useAdminGetAllAccounts } from "src/queries/Account/useAdminGetAllAccounts"
import { Routes } from "src/Routes/Routes"
import Typography, { Size } from "@stories/components/Typography/Typography"
import AccountUsers from "./components/AccountUsers"

const AccountFilters = {
  name: substringFilter<AdminAllAccountsCache["accounts"][number]>((a) => a.name, {
    placeholder: "Search accounts",
  }),
  fullAccess: createDropDownFilter(
    createSingleSelectTableFilter<
      AdminAllAccountsCache["accounts"][number],
      "Full Access" | "Limited Access"
    >("Full Access", ["Full Access", "Limited Access"], (a) =>
      a.fullAccessGrantedAt ? "Full Access" : "Limited Access"
    ),
    "Account Level"
  ),
  tradeRegistration: createDropDownFilter(
    createMultiSelectTableFilter<
      AdminAllAccountsCache["accounts"][number],
      ReturnType<typeof getAccountTradingRegistrationStatusString> | "N/A"
    >(
      "Trading Registration Status",
      ["Not Started", "Identification Information Submitted", "Approved", "N/A"],
      (a) => a.tradingRegistration?.status ?? "N/A"
    ),
    "Trading Registration"
  ),
}

export const GhostAccountButton = ({ account }: { account: AllAccountsCacheFields }) => {
  const user = useLoggedInUser()
  const firebase = useFirebaseWriter()
  const navigate = useNavigate()
  const ghostAccount = async () => {
    const fullAccount = await firebase
      .account(account.id)
      .get()
      .then((doc) => doc.data())
    const realUserAccount = user.user.ghostingUserAccount || user.user.account
    firebase.db
      .collection(collections.users)
      .doc(user.user.id)
      .update({
        account: fullAccount,
        ghostingUserAccount: realUserAccount,
        ghostSessionStartedAt: new Date(),
      })
      .then(() => navigate(Routes.root))
      .then(() => window.location.reload()) // Temp workaround due to some app state stale with old account info
      .catch(handleConsoleError)
  }
  if (!user.isAdmin) return null

  return (
    <Button
      label="Ghost Account"
      onClick={() => ghostAccount().catch(handleConsoleError)}
      leftIcon={<ZoomInOutlined />}
      variant="secondary"
    />
  )
}
export const Accounts = () => {
  const user = useLoggedInUser()
  const firebase = useFirebaseWriter()
  const accounts = useAdminGetAllAccounts()
  const navigate = useNavigate()
  const location = useLocation()
  const selectedTradingRegistrationAccount = useMemo(() => {
    const id = new URLSearchParams(location.search).get("tradingRegistration")
    return defaultIfLoading(accounts, []).find((a) => a.id === id)
  }, [accounts, location.search])

  const setSelectedTradingRegistrationAccount = useCallback(
    (account: AdminAllAccountsCache["accounts"][number] | null) => {
      navigate({
        search: account ? `?tradingRegistration=${account.id}` : "",
      })
    },
    [navigate]
  )

  const createOrderUpload = useMemo(
    () => (account: AccountIdFields, u: UserIdFields, documentType: DocumentType) =>
      createMockDocumentSubmission(firebase)(u, account, documentType),
    [firebase]
  )

  const [isCreatingUpload, setIsCreatingUpload] = useState(false)
  const { openDrawer: openAdminEngagementDrawer } = useAdminEngagementDrawer()
  const columnHelper = createColumnHelper<AdminAllAccountsCache["accounts"][number]>()

  const [filteredAccounts, FilterControls] = useFilter(
    defaultIfLoading(accounts, []),
    AccountFilters,
    {}
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Name",
        cell: (context) => (
          <div
            role="button"
            tabIndex={0}
            onClick={() => openAdminEngagementDrawer(context.row.original.id)}
            onKeyDown={(e) =>
              e.code === "Enter" ? openAdminEngagementDrawer(context.row.original.id) : null
            }
          >
            <span>{context.row.original.name}</span>
          </div>
        ),
        footer: (props) => props.column.id,
      }),
      columnHelper.accessor("accessControlTier", {
        header: "Access Control Tier",
        cell: (context) => (
          <Typography
            size={Size.Small}
            text={context.cell.getValue() ?? "undefined"}
            shouldWrap={false}
          />
        ),
      }),
      columnHelper.display({
        header: "Account Users",
        enableSorting: false,
        cell: (context) => <AccountUsers accountId={context.row.original.id} />,
        footer: (props) => props.column.id,
      }),
      columnHelper.accessor("isTopBroker", {
        header: "Top Broker",
        cell: (context) =>
          context.row.original.isTopBroker ? <TopBrokerPill size="minimal" /> : null,
        footer: (props) => props.column.id,
        sortUndefined: -1,
      }),
      columnHelper.accessor("clientType", {
        header: "Client Type",
        cell: (context) => <ClientTypePill clientType={context.row.original.clientType} />,
        footer: (props) => props.column.id,
      }),
      columnHelper.accessor("productAreas", {
        header: "Product Areas",
        cell: (context) => <ProductAreasPills productAreas={context.row.original.productAreas} />,
        footer: (props) => props.column.id,
      }),
      columnHelper.accessor("onboardingStatus.data.trialExpiration", {
        header: "Trial Status",
        cell: (context) => <AccountTrialStatus account={context.row.original} />,
        footer: (props) => props.column.id,
      }),
      columnHelper.accessor("tradingRegistration.status", {
        header: "Trade Registration",
        cell: (context) => (
          <AdminTradingRegistrationTableStatus
            account={context.row.original}
            onClick={setSelectedTradingRegistrationAccount}
          />
        ),
        footer: (props) => props.column.id,
        sortingFn: (a, b) =>
          getTradingRegistrationAdminTableString(a.original) >
          getTradingRegistrationAdminTableString(b.original)
            ? 1
            : -1,
        sortUndefined: 1,
      }),
      columnHelper.display({
        header: "Actions",
        enableSorting: false,
        cell: (context) => (
          <div className="flex gap-x-4">
            <GhostAccountButton account={context.row.original} />
            {isCreatingUpload ? (
              <Spinner size="sm" />
            ) : (
              <Button
                label="Create Order Upload"
                onClick={() => {
                  setIsCreatingUpload(true)
                  createOrderUpload(
                    context.row.original,
                    viewUserIdFields(user.user),
                    "order-submission"
                  )
                    .then(() => setIsCreatingUpload(false))
                    .catch(Sentry.captureException)
                }}
                leftIcon={<CloudUploadOutlined />}
                variant="secondary"
                isDisabled={isCreatingUpload}
              />
            )}
          </div>
        ),
      }),
    ],

    [
      columnHelper,
      openAdminEngagementDrawer,
      setSelectedTradingRegistrationAccount,
      isCreatingUpload,
      createOrderUpload,
      user.user,
    ]
  )

  return (
    <div className="flex flex-col gap-4 shadow-md border rounded bg-neutral-white p-4">
      <PageTitle title="Accounts" />
      <div className="flex items-center gap-2">
        {FilterControls.name}
        {FilterControls.fullAccess}
        {FilterControls.tradeRegistration}
      </div>
      <Table
        isLoading={isLoading(accounts)}
        data={filteredAccounts}
        columns={columns}
        defaultPaginationPageSize={50}
        defaultSortBy="name"
        defaultSortDirection="asc"
      />
      {selectedTradingRegistrationAccount ? (
        <AdminTradingRegistrationDrawer
          account={selectedTradingRegistrationAccount}
          onClose={() => setSelectedTradingRegistrationAccount(null)}
          open={!!selectedTradingRegistrationAccount}
        />
      ) : null}
    </div>
  )
}
