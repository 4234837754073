import { Input } from "@stories/components/Inputs/Input/Input"
import React, { useState } from "react"

interface StringFieldProps {
  value: string | null | undefined
  onChange: (newValue: string | null) => void
  placeholder?: string
}

export const StringField: React.FC<StringFieldProps> = ({ value, onChange, placeholder }) => {
  const [rawValue, setRawValue] = useState(value)

  const handleBlur = () => {
    onChange(rawValue ?? null)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRawValue(e.target.value)
  }

  return (
    <Input
      size="xsmall"
      placeholder={placeholder}
      type="text"
      value={rawValue ?? undefined}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  )
}
