import Spinner from "@components/icons/Spinner"
import { DealDistributionParticipant } from "common/model/DealDistribution/DealDistributionParticipant"
import { Loading, isLoaded, isLoading, mapLoading } from "common/utils/Loading"
import { uniqueByRep } from "common/utils/data/Array/ArrayUtils"
import { useMemo } from "react"
import OrderScoreCardMetric from "src/pages/Orders/OrderPage/OrderPageScorecard/OrderScorecardMetric"

type AudienceData = {
  audienceSize: number
  deliveredSize: number
  openedSize: number
  respondedSize: number
  interestedSize: number
}

export const DealDistributionParticipantStats = ({
  dealDistributionParticipants,
}: {
  dealDistributionParticipants: Loading<DealDistributionParticipant[]>
}) => {
  const audience = useMemo(
    () =>
      mapLoading<DealDistributionParticipant[], AudienceData>((loadedParticipants) => {
        const uniqueAccounts = uniqueByRep<DealDistributionParticipant, string>(
          (p) => p.participant.account.id
        )(loadedParticipants)
        return {
          audienceSize: uniqueAccounts.filter((p) => p.status.approvedDate).length,
          deliveredSize: uniqueAccounts.filter((p) => p.status.sentDate !== null).length,
          openedSize: uniqueAccounts.filter((p) => p.status.openDate !== null).length,
          respondedSize: uniqueAccounts.filter((p) => p.response.tag !== "no-response").length,
          interestedSize: uniqueAccounts.filter((p) => p.response.tag === "interested").length,
        }
      })(dealDistributionParticipants),
    [dealDistributionParticipants]
  )

  if (isLoading(audience))
    return (
      <div className="flex flex-wrap gap-4">
        <Spinner size="sm" />
        <Spinner size="sm" />
        <Spinner size="sm" />
        <Spinner size="sm" />
        <Spinner size="sm" />
      </div>
    )
  if (!isLoaded(audience))
    return (
      <div className="flex flex-wrap gap-4">
        <OrderScoreCardMetric label="Audience" value="-" />
        <OrderScoreCardMetric label="Delivered" value="-" />
        <OrderScoreCardMetric label="Click Through" value="-" />
        <OrderScoreCardMetric label="Responded" value="-" />
        <OrderScoreCardMetric label="Interested" value="-" />
      </div>
    )

  return (
    <div className="flex flex-wrap gap-4">
      <OrderScoreCardMetric label="Audience" value={audience.audienceSize} />
      <OrderScoreCardMetric label="Delivered" value={audience.deliveredSize} />
      <OrderScoreCardMetric label="Click Through" value={audience.openedSize} />
      <OrderScoreCardMetric label="Responded" value={audience.respondedSize} />
      <OrderScoreCardMetric label="Interested" value={audience.interestedSize} />
    </div>
  )
}
