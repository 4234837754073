import { Interval } from "../Interval"

export const intervalMidpoint = (interval: Interval<number>) =>
  (interval.lowerBound + interval.upperBound) / 2

/**
 * Broaden or compress a number interval by some scalar applied to each bound.
 * Scalars are applied multiplicatively and should be expressed as a ratio instead of a percentage (scalar of 1.5 = increase value by 50%)
 *
 * Note - this makes no guarantee that the interval bounds will be correctly ordered after scaling!
 */
export const scaleInterval = (
  interval: Interval<number>,
  lowerBoundScalar: number,
  upperBoundScalar: number
): Interval<number> => ({
  lowerBound: interval.lowerBound * lowerBoundScalar,
  upperBound: interval.upperBound * upperBoundScalar,
})

export const intervalsOverlap = (a: Interval<number>, b: Interval<number>) =>
  (a.lowerBound <= b.lowerBound && a.upperBound >= b.lowerBound) ||
  (b.lowerBound <= a.lowerBound && b.upperBound >= a.lowerBound)

export const sumIntervals = (a: Interval<number>, b: Interval<number>) => ({
  lowerBound: a.lowerBound + b.lowerBound,
  upperBound: b.upperBound + b.upperBound,
})
