import { isObject } from "../../utils/json/validate"

/** For use *only* in `firestoreConverter` here and on the frontend
 * @deprecated
 */
export const convertObjectDates = <R extends Record<never, unknown>>(obj: R) => {
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const key in obj) {
    const value = obj[key]
    if (isObject(value)) {
      if ("seconds" in value) {
        // convert firebase timestamps to js dates
        obj[key as keyof typeof obj] = (
          value as unknown as { toDate: () => Date }
        ).toDate() as unknown as R[keyof R] // TODO fix
      } else if (!("firestore" in value)) {
        convertObjectDates(value)
      }
    }
  }
}
