import { FallbackRender } from "@sentry/react/dist/errorboundary"
import { classNames } from "../../utils/classNames"
import { NextButton } from "../buttons/NextButton"

const forceReloadParam = "forceReload"

export const ErrorBanner: FallbackRender = ({ error }) => {
  const pageURL = new URL(document.URL)
  const reload = () => {
    pageURL.searchParams.append(forceReloadParam, "true")
    // eslint-disable-next-line better-mutation/no-mutation
    document.location.search = pageURL.search
  }

  const isChunkError =
    error.message.includes("Loading chunk") ||
    error.name.includes("ChunkLoadError") ||
    error.message.includes("Failed to fetch dynamically imported module")

  const isPageForceReload = pageURL.searchParams.has(forceReloadParam)
  if (isChunkError && !isPageForceReload) {
    reload()
    return <div />
  }
  return (
    <div className={classNames("opacity-80 p-4 mb-4 pt-24 md:pt-4", "bg-danger-800")}>
      <div className="flex justify-center">
        <div className="ml-3">
          <h3 className="text-sm font-medium text-white">
            An error occurred. We've been notified and are looking into the issue.
          </h3>
          <div className="flex justify-center mt-4">
            <NextButton
              noIcon
              text="Reload and try again"
              color="white"
              size="sm"
              onClick={reload}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
