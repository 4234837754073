import * as react from "react"

// do not export
const memoizedWitness: unique symbol = Symbol("memoizedWitness")
const singletonWitness: unique symbol = Symbol("singletonWitness")
type MemoizedWitness = typeof memoizedWitness
type SingletonWitness = typeof singletonWitness

export type Memoized<T> = { value: T; memoizedWitness: MemoizedWitness } // An intersection would be cleaner but unfortunately we need to memoize arrays sometimes
export type SingletonObject<T> = T & { singletonWitness: SingletonWitness }
export const unsafeAssertSingleton = <T extends {}>(t: T): SingletonObject<T> => {
  const newT: T & { singletonWitness?: SingletonWitness } = t
  newT.singletonWitness = singletonWitness
  return newT as SingletonObject<T>
}

export const useMemo = <T>(f: () => T, deps: readonly ValueEqualityType[]): Memoized<T> => {
  const value = react.useMemo(f, deps)
  return react.useMemo(() => ({ value, memoizedWitness }), [value])
}

export const useUnsafeMemo = <T>(f: () => T, deps: readonly unknown[]): Memoized<T> => {
  const value = react.useMemo(f, deps)
  return react.useMemo(() => ({ value, memoizedWitness }), [value])
}

export type ValueEqualityType =
  | null
  | undefined
  | number
  | bigint
  | string
  | symbol
  | Memoized<unknown>
  | SingletonObject<{}>
  | React.Dispatch<never> // this is a blatant lie but wrapping every callback is an ergonomic nightmare. TODO: handle this correctly

export const useEffect = (f: react.EffectCallback, deps: readonly ValueEqualityType[]) =>
  react.useEffect(f, deps)
export const useUnsafeEffect = react.useEffect
