import { StarIcon } from "@heroicons/react/solid"
import { classNames } from "../../utils/classNames"

interface IWatchlistIconProps {
  className?: string
}

const WatchlistIcon: React.FunctionComponent<React.PropsWithChildren<IWatchlistIconProps>> = ({
  className,
}) => <StarIcon className={classNames(className, "w-5 h-5 text-primary")} />

export default WatchlistIcon
