import OrdersRequiringAttentionIcon from "@components/OrdersRequireAttention/OrdersRequiringAttentionIcon"
import RFQLiveIcon from "@components/layout/navbar/RFQLiveIcon"
import UnreadMessagesIcon from "@components/layout/navbar/UnreadMessagesIcon"
import UnreadNotificationsBadge, {
  NavNotificationIcon,
} from "@components/layout/navbar/UnreadNotificationsBadge"
import UnreadWatchlistNews from "@components/layout/navbar/UnreadWatchlistNews"
import { SwitchHorizontalIcon } from "@heroicons/react/solid"
import { ClosedTradeIcon } from "@stories/icons/ClosedTradeIcon"
import { OpportunityInboxIcon } from "@stories/icons/OpportunityInboxIcon"
import { SectorsIcon } from "@stories/icons/SectorsIcon"
import { ShareIcon } from "@stories/icons/ShareIcon"
import { UserIcon } from "@stories/icons/UserIcon"
import FeatureWrapper from "src/providers/featureFlags/FeatureWrapper"
import { CompaniesIcon } from "src/stories/icons/CompaniesIcon"
import { ContributeIcon } from "src/stories/icons/ContributeIcon"
import { FundingRoundsIcon } from "src/stories/icons/FundingRoundsIcon"
import { InboxIcon } from "src/stories/icons/InboxIcon"
import { InsightsIcon } from "src/stories/icons/InsightsIcon"
import { LiveMarketIcon } from "src/stories/icons/LiveMarketIcon"
import { MyOrdersIcon } from "src/stories/icons/MyOrdersIcon"
import { NewsIcon } from "src/stories/icons/NewsIcon"
import { RequestForQuotesIcon } from "src/stories/icons/RequestForQuotesIcon"
import { WatchlistIcon } from "src/stories/icons/WatchlistIcon"
import { Color } from "../stories/components/Typography/Typography"
import { PartialRouted, Routed } from "./types"
import { BookOpenIcon } from "@stories/icons/BookOpenIcon"
import { CurrentUser } from "@model/CurrentUser"
import { isLoaded, Loading, matchLoading } from "common/utils/Loading"
import { getAllInterestPageTitle } from "src/pages/CRM/Interest/getAllInterestPageTitle"
import { User } from "common/model/User"
import { isOpportunityInboxUser } from "@components/auth/conditions/IsOrdersUser"
import { useContext } from "react"
import { AccessControlContext } from "src/providers/AccessControl/AccessControlProvider"
import { AccessControlFeatures } from "common/model/AccessControl/AccessControl"
import { useCurrentAccount } from "src/queries/currentUser/useCurrentAccount"
import { useEnabledFeatureFlags } from "src/providers/featureFlags/useFeatureFlags"
import { hasDealSearchAccess } from "common/model/DealSearch/hasDealSearchAccess"
import { DashboardIcon } from "@stories/icons/DashboardIcon"
import { isEmployeeAccount } from "common/model/Auth/Permissions"

export const navSections = ["Controls", "Markets", "Data", "Insights", "Deal CRM"] as const

export type NavSectionType = (typeof navSections)[number]

type NavName =
  | { name: string; getName?: never }
  | { name?: never; getName: (user: Loading<CurrentUser>) => string }

export type NavItem = NavName & {
  icon: React.ReactNode
  badge?: React.ReactNode
  isNew?: boolean
  accessControlFeatureName?: keyof AccessControlFeatures
  section: NavSectionType
  route<T>(routes: Routed<T>): T
  route<T>(routes: PartialRouted<T>): T | undefined
}

export const navItems: NavItem[] = [
  {
    name: "Notifications",
    icon: <NavNotificationIcon />,
    section: "Controls",
    route: (routes) => routes.notifications,
    badge: <UnreadNotificationsBadge />,
  },
  {
    name: "Dashboard",
    icon: <DashboardIcon color={Color.White} />,
    section: "Controls",
    route: (routes) => routes.dealSearch?.root,
  },
  {
    name: "Portfolio",
    icon: <DashboardIcon color={Color.White} />,
    section: "Data",
    route: (routes) => routes.shareholders?.portfolio,
  },
  {
    name: "Companies",
    icon: <CompaniesIcon color={Color.White} />,
    section: "Data",
    route: (routes) => routes.companies?.root,
  },
  {
    name: "News",
    icon: <NewsIcon color={Color.White} />,
    section: "Insights",
    route: (routes) => routes.news,
    badge: (
      <FeatureWrapper flagName="is_unread_news_pill_active">
        <UnreadWatchlistNews />
      </FeatureWrapper>
    ),
    accessControlFeatureName: "companyNewsData",
  },
  {
    name: "Sectors",
    icon: <SectorsIcon color={Color.White} />,
    section: "Data",
    route: (routes) => routes.sectors?.root,
    accessControlFeatureName: "sectorsData",
  },
  {
    name: "Financing",
    icon: <FundingRoundsIcon color={Color.White} />,
    section: "Insights",
    route: (routes) => routes.fundingRounds,
    accessControlFeatureName: "fundingRoundsPage",
  },
  {
    name: "Market Insights",
    icon: <InsightsIcon color={Color.White} />,
    section: "Insights",
    route: (routes) => routes.insights,
    accessControlFeatureName: "marketInsightsPage",
  },
  {
    name: "Opportunity Inbox",
    icon: <OpportunityInboxIcon color={Color.White} />,
    section: "Markets",
    route: (routes) => routes.opportunityInbox,
    accessControlFeatureName: "liveMarketParticipation",
  },
  {
    name: "Live Market",
    icon: <LiveMarketIcon color={Color.White} />,
    section: "Markets",
    route: (routes) => routes.orders?.root,
    accessControlFeatureName: "liveMarketParticipation",
  },
  {
    name: "My Network",
    icon: <ShareIcon color={Color.White} />,
    section: "Markets",
    route: (routes) => routes.shared?.root,
    accessControlFeatureName: "liveMarketParticipation",
  },
  {
    name: "My Orders",
    icon: <MyOrdersIcon color={Color.White} />,
    section: "Markets",
    route: (routes) => routes.myOrders,
    badge: <OrdersRequiringAttentionIcon />,
    accessControlFeatureName: "liveMarketParticipation",
  },
  {
    name: "Messaging",
    icon: <InboxIcon color={Color.White} />,
    section: "Markets",
    isNew: false,
    route: (routes) => routes.inbox?.root,
    badge: <UnreadMessagesIcon />,
    accessControlFeatureName: "liveMarketParticipation",
  },
  {
    name: "Watchlist",
    icon: <WatchlistIcon color={Color.White} />,
    section: "Markets",
    route: (routes) => routes.watchlistPage,
  },
  {
    name: "RFQs",
    icon: <RequestForQuotesIcon color={Color.White} />,
    section: "Data",
    route: (routes) => routes.rfqs?.root,
    badge: <RFQLiveIcon />,
    accessControlFeatureName: "rfqData",
  },
  {
    name: "Trade History",
    icon: <ClosedTradeIcon color={Color.White} />,
    section: "Data",
    route: (routes) => routes.closedTrades?.root,
    accessControlFeatureName: "tradeHistoryPricing",
  },
  {
    name: "Contribute",
    icon: <ContributeIcon color={Color.White} />,
    section: "Data",
    route: (routes) => routes.data?.upload?.root,
    accessControlFeatureName: "contributeTradeData",
  },
  {
    name: "Deals",
    icon: <SwitchHorizontalIcon color={Color.White} className="text-white h-5 w-5" />,
    section: "Deal CRM",
    route: (routes) => routes.crm?.allDeals,
  },
  {
    getName: getAllInterestPageTitle,
    icon: <BookOpenIcon color={Color.White} />,
    section: "Deal CRM",
    route: (routes) => routes.crm?.allInterest,
  },
  {
    name: "Contacts",
    icon: <UserIcon color={Color.White} />,
    section: "Deal CRM",
    route: (routes) => routes.crm?.allContacts,
  },
]

export const useNavItemsToDisplay = ({ user }: { user: User }): NavItem[] => {
  const isOpportunityInboxEnabled = isOpportunityInboxUser(user)
  const { getFeatureAccessLevel } = useContext(AccessControlContext)
  const account = useCurrentAccount()
  const loadingEnabledFeatureFlags = useEnabledFeatureFlags()

  const { hasSharedOrders } = user
  const hasMessageThreads = isLoaded(account) && account.hasMessageThreads

  const adjustItemSectionsForJournalists = (item: NavItem): NavItem => {
    if (user.account.clientType.includes("Journalist") && item.name === "Watchlist") {
      return {
        ...item,
        section: "Data",
      } satisfies NavItem
    } else {
      return item
    }
  }

  const adjustItemSectionsForEmployees = (item: NavItem): NavItem => {
    if (isEmployeeAccount(user.account) && item.name === "Companies") {
      return {
        ...item,
        name: "All Companies",
      } satisfies NavItem
    } else {
      return item
    }
  }

  const isDealSearchEnabled = matchLoading(
    loadingEnabledFeatureFlags,
    (enabledFeatureFlags) =>
      hasDealSearchAccess({
        account: user.account,
        enabledFeatureFlags,
      }),
    false,
    false
  )

  const navItemsToDisplay = navItems
    .filter(
      (item) =>
        (!hasSharedOrders ? item.name !== "Shared With Me" : true) &&
        (!hasMessageThreads ? item.name !== "Inbox" && item.name !== "Messaging" : true) &&
        (!isOpportunityInboxEnabled ? item.name !== "Opportunity Inbox" : true) &&
        (!isDealSearchEnabled ? item.name !== "Dashboard" : true) &&
        (item.accessControlFeatureName
          ? getFeatureAccessLevel(item.accessControlFeatureName) !== "none"
          : true)
    )
    .map(adjustItemSectionsForJournalists)
    .map(adjustItemSectionsForEmployees)

  return navItemsToDisplay
}
