import React, { FC, useMemo, useState } from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { AccountEventCountsRollup } from "common/model/Analytics/EventCounterRollup"
import { UserEventType, userEventTypes } from "common/model/UserEvent"
import Select from "react-select"
import moment from "moment"
import { OptionType } from "../../components/inputs/Multiselect"
import { useFirebaseReader } from "../../firebase/Context"
import { Button } from "../../stories/components/Button/Button"
import numeral from "numeral"
import TrialAccountsModule from "./TrialAccountsModule"
import { classNames } from "react-notion"
import { AccountIdFields, Account, isTrialCustomer } from "common/model/Account"
import { useDocuments } from "../../firebase/Firestore"
import OverallAccountEngagementChart from "./OverallAccountEngagementChart"
import BrokerAccountsModule from "./BrokerAccountsModule"
import NonBrokerAccountsModule from "./NonBrokerAccountsModule"
import Module from "./Module"
import { isSameWeek, isAfter, format as dateFormat, subWeeks } from "date-fns"
import { Timestamp } from "common/model/postgres/PostgresCommon"
import { useAdminEngagementDrawer } from "src/providers/AdminProviders/AdminEngagementDrawerProvider"
import { AdminAllAccountOpportunityInboxDashboard } from "../opportunity-inbox/AdminAllAccountOpportunityInboxDashboard"

const accountToOption = (account: AccountIdFields): OptionType => ({
  value: account.id,
  label: account.name,
  suggested: false,
})

const eventOptions: OptionType<UserEventType>[] = userEventTypes.map((eventType) => ({
  value: eventType,
  label: eventType,
  suggested: false,
}))

const NUM_WEEKS = 12

const chartWeeks = Array(NUM_WEEKS)
  .fill(0)
  .map((_, i) =>
    moment()
      .tz("America/Los_Angeles")
      .startOf("week")
      .subtract(NUM_WEEKS - i - 1, "weeks")
      .toDate()
      .getTime()
  )

const buildAccountSeriesData = (
  eventCountRollupDocs: AccountEventCountsRollup[],
  accountId: string,
  selectedEvents: UserEventType[]
) => {
  // eslint-disable-next-line rulesdir/no-let
  let sortedRollupDocs = eventCountRollupDocs
    .slice()
    .sort((l, r) => l.periodDate.valueOf() - r.periodDate.valueOf())
  return chartWeeks.map((week) => {
    const weeksEventsIndex = sortedRollupDocs.findIndex((rollupDoc) =>
      isSameWeek(rollupDoc.periodDate, week)
    )
    const weeksEvents = weeksEventsIndex !== -1 ? sortedRollupDocs[weeksEventsIndex] : undefined
    // eslint-disable-next-line better-mutation/no-mutation
    sortedRollupDocs = sortedRollupDocs.slice(weeksEventsIndex + 1)
    const accountEventsInWeek = weeksEvents?.accounts[accountId]
    if (accountEventsInWeek) {
      const includedEvents: UserEventType[] = userEventTypes.filter(
        (ev) =>
          !EXCLUDED_EVENTS.includes(ev) &&
          (selectedEvents.length === 0 || selectedEvents.some((e) => e === ev))
      )
      // Sum events if they are in includedEvents
      const eventCount = Object.entries(accountEventsInWeek.eventCounts)
        .filter(([event]) => includedEvents.includes(event as UserEventType))
        .reduce((total, [, count]) => total + (count || 0), 0)
      return [week, eventCount]
    }
    return [week, 0]
  })
}

export const EXCLUDED_EVENTS: UserEventType[] = ["active-session"]

const AccountEngagementPage: React.FC<{
  allAccounts: Account[]
  weeklyRollupEvents: AccountEventCountsRollup[]
  monthlyRollupEvents: AccountEventCountsRollup[]
}> = ({ allAccounts, weeklyRollupEvents, monthlyRollupEvents }) => {
  const [accountSelection, setSelectedAccounts] = useState<OptionType[]>([])
  const [eventSelection, setSelectedEvents] = useState<OptionType<UserEventType>[]>([])

  const selectTrialAccounts = () => {
    setSelectedAccounts(allAccounts.filter(isTrialCustomer).map(accountToOption))
  }

  const accountOptions = allAccounts.map(accountToOption)

  const clearSelectedAccounts = () => {
    setSelectedAccounts([])
  }

  const selectAllBrokerAccounts = () => {
    setSelectedAccounts(
      allAccounts.filter((acc) => acc.clientType.includes("Intermediary")).map(accountToOption)
    )
  }

  // generate chart series data using the selected accounts and events. Each line represent an account's event counts for a given period. The X axis values for each line are the date and the y values are the sum of event counts for the given account, for the given period, with a default of 0.
  const chartSeriesData: Array<Highcharts.SeriesOptionsType> = accountSelection.map((account) => ({
    type: "line",
    name: account.label,
    data: buildAccountSeriesData(
      weeklyRollupEvents,
      account.value,
      eventSelection.map((e) => e.value)
    ),
  }))

  // // Calculate the average engagement
  const accountAverageEngagementByPeriod = chartWeeks.map((week) => {
    const weeksEvents = weeklyRollupEvents.find((rollupDoc) =>
      isSameWeek(rollupDoc.periodDate, week)
    )
    if (weeksEvents) {
      // if event filters are applied, only include events that are in eventSelection
      const counts = Object.entries(weeksEvents.accounts)
        .filter(
          ([_, account]) =>
            accountSelection.length === 0 ||
            accountSelection.some((a) => a.value === account.account.id)
        )
        .map(([_, account]) => {
          const includedEvents: UserEventType[] = Object.keys(account.eventCounts || {})
            .filter(
              (ev) =>
                !EXCLUDED_EVENTS.includes(ev as UserEventType) &&
                (eventSelection.length === 0 || eventSelection.some((e) => e.value === ev))
            )
            .map((ev) => ev as UserEventType)

          return Object.entries(account.eventCounts || {})
            .filter(([event]) => includedEvents.includes(event as UserEventType))
            .reduce((total, [, count]) => total + (count || 0), 0)
        })
      const average = counts.length
        ? counts.reduce((total, count) => total + count, 0) / counts.length
        : 0
      return [week, average]
    }
    return [week, 0]
  })

  const accountAverageSeries: Highcharts.SeriesOptionsType = {
    name: "Account Average Engagement",
    type: "area",
    data: accountAverageEngagementByPeriod,
    lineWidth: 3,
    fillColor: {
      linearGradient: {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 1,
      },
      stops: [
        [0, "rgba(0, 35, 226, 0.8)"],
        [1, "rgba(0, 35, 226, 0)"],
      ],
    },
  }

  const chartOptions: Highcharts.Options = {
    chart: {
      type: "line",
      height: 700,
      width: 1500,
      margin: 150,
    },
    title: {
      floating: true,
      text: "",
    },
    xAxis: {
      type: "datetime",
      labels: {
        format: "{value:%b %e}",
      },
      min: Math.min(...chartWeeks),
      max: Date.now(),
      tickInterval: 7 * 24 * 3600 * 1000, // 1 week in milliseconds
    },
    yAxis: {},
    plotOptions: {
      line: {
        // ... line plot options
      },
    },
    series: [accountAverageSeries, ...chartSeriesData],
  }

  return (
    <div className="p-4">
      <div className="flex flex-col space-y-4 mb-4">
        <OverallAccountEngagementChart
          allAccounts={allAccounts}
          weeklyAccountEventRollups={weeklyRollupEvents}
        />
        <TrialAccountsModule
          accounts={allAccounts}
          weeklyEventCounts={weeklyRollupEvents}
          monthlyEventCounts={monthlyRollupEvents}
        />
        <BrokerAccountsModule
          accounts={allAccounts}
          weeklyEventCounts={weeklyRollupEvents}
          monthlyEventCounts={monthlyRollupEvents}
        />
        <NonBrokerAccountsModule
          accounts={allAccounts}
          weeklyEventCounts={weeklyRollupEvents}
          monthlyEventCounts={monthlyRollupEvents}
        />
        <Module title="All Accounts Engagement">
          <div className="flex space-x-4">
            <Button
              variant="primary"
              label="All Trial Accounts"
              size="small"
              onClick={selectTrialAccounts}
            />
            <Button
              variant="primary"
              label="All Broker Accounts"
              size="small"
              onClick={selectAllBrokerAccounts}
            />
            <Button
              variant="secondary"
              label="Reset accounts"
              size="small"
              onClick={clearSelectedAccounts}
            />
          </div>
          <div className="mb-4">
            {/* Render the account multiselect */}
            <Select
              isMulti
              options={accountOptions}
              value={accountSelection}
              onChange={(selection) => setSelectedAccounts(selection.map((item) => item))}
              placeholder="Select accounts"
            />
          </div>
          <div className="mb-4">
            {/* Render the event multiselect */}
            <Select
              isMulti
              options={eventOptions}
              value={eventSelection}
              onChange={(selection) => setSelectedEvents(selection.map((item) => item))}
              placeholder={
                eventSelection.length
                  ? "Select events"
                  : "Select events (all events included by default)"
              }
            />
          </div>
          <div className="mb-4">
            {/* Render the Highcharts chart */}
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          </div>
          <div>
            <EventCountFlamegraphTable
              allAccounts={allAccounts}
              selectedAccounts={accountSelection}
              selectedEvents={eventSelection.map((e) => e.value)}
              weeklyRollupData={weeklyRollupEvents}
              monthlyRollupData={monthlyRollupEvents}
              accountAverages={accountAverageEngagementByPeriod}
            />
          </div>
        </Module>
        <Module title="Opp Inbox">
          <AdminAllAccountOpportunityInboxDashboard />
        </Module>
      </div>
    </div>
  )
}

type AccountSort = "name" | "recent-score-low-to-high" | "recent-score-high-to-low"

const EventCountFlamegraphTable: FC<{
  weeklyRollupData: AccountEventCountsRollup[]
  monthlyRollupData: AccountEventCountsRollup[]
  allAccounts: Account[]
  selectedAccounts: OptionType[]
  selectedEvents: UserEventType[]
  accountAverages: number[][]
}> = ({
  allAccounts,
  weeklyRollupData: rollupData,
  monthlyRollupData,
  selectedAccounts,
  selectedEvents,
  accountAverages,
}) => {
  const [sortBy, setSortBy] = useState<AccountSort>("recent-score-high-to-low")
  const [limitToSelectedAccounts, setLimitToSelectedAccounts] = useState(false)
  const [expandedRow, setExpandedRow] = useState<string | undefined>(undefined)

  // 'Recent' defined as past 4 weeks
  const recentScores = allAccounts.map((account) => {
    const recentRollupData = rollupData.filter(
      (rollup) =>
        isSameWeek(rollup.periodDate, subWeeks(new Date(), 4)) ||
        isAfter(rollup.periodDate, subWeeks(new Date(), 4))
    )
    const recentRollupDataForAccount = recentRollupData.find(
      (rollup) => rollup.accounts[account.id]
    )
    const recentRollupDataForAccountEvents = recentRollupDataForAccount?.accounts[account.id]
    const recentRollupDataForAccountEventCounts = recentRollupDataForAccountEvents?.eventCounts
    const recentRollupDataForAccountEventCountValues = Object.values(
      recentRollupDataForAccountEventCounts || {}
    )
    const recentRollupDataForAccountEventCountSum =
      recentRollupDataForAccountEventCountValues.reduce((sum, count) => sum + (count || 0), 0)
    return { accountId: account.id, recentEngagementScore: recentRollupDataForAccountEventCountSum }
  })

  const recentScoreMemoTable = useMemo(() => {
    const out = new Map<
      string,
      | {
          accountId: string
          recentEngagementScore: number
        }
      | undefined
    >()
    allAccounts.forEach((a) => {
      out.set(
        a.id,
        recentScores.find(({ accountId }) => accountId === a.id)
      )
    })
    return out
  }, [allAccounts, recentScores])

  const allSortedAccounts = useMemo(
    () =>
      allAccounts.sort((a, b) => {
        if (sortBy === "name") return (a.name || "").localeCompare(b.name || "")

        const aRecentScore = recentScoreMemoTable.get(a.id)
        const bRecentScore = recentScoreMemoTable.get(b.id)
        if (sortBy === "recent-score-high-to-low") {
          if (!aRecentScore || !bRecentScore) return 0
          return bRecentScore.recentEngagementScore - aRecentScore.recentEngagementScore
        } else {
          if (!aRecentScore || !bRecentScore) return 0
          return aRecentScore.recentEngagementScore - bRecentScore.recentEngagementScore
        }
      }),
    [allAccounts, recentScoreMemoTable, sortBy]
  )
  const sortedAccounts = useMemo(
    () =>
      limitToSelectedAccounts
        ? allSortedAccounts.filter((account) =>
            selectedAccounts.some((selected) => selected.value === account.id)
          )
        : allSortedAccounts,
    [allSortedAccounts, limitToSelectedAccounts, selectedAccounts]
  )

  const accountEventCounts = sortedAccounts.map((account) => ({
    account,
    data: buildAccountSeriesData(rollupData, account.id, selectedEvents),
  }))

  // Background of cell for a flamegraph from 0 with low counts to dark red with high counts
  const getCellBg = (count: number) => {
    // white if low count, red if high count. medium count is light red
    // does not use bg-rgb
    if (count === 0) return "bg-neutral-white"
    if (count < 5) return "bg-danger-100"
    if (count < 10) return "bg-danger-200"
    if (count < 15) return "bg-danger-300"
    if (count < 20) return "bg-danger-400"
    if (count < 25) return "bg-danger-500 text-white"
    if (count < 30) return "bg-danger-600 text-white"
    if (count < 35) return "bg-danger-700 text-white"
    if (count < 40) return "bg-danger-800 text-white"
    return "bg-danger-900 text-white"
  }

  const toggleExpandedRow = (accountId: string) => {
    if (expandedRow === accountId) {
      setExpandedRow(undefined)
    } else {
      setExpandedRow(accountId)
    }
  }
  const { openDrawer: openAdminEngagementDrawer } = useAdminEngagementDrawer()

  // Table with headers representing weeks and rows representing accounts. Each cell is the accounts total event counts for the given week.
  // Background of cells is a gradient from blue to red with blue representing a cell value of 0 and red representing anything above 20

  // if (!selectedAccounts || selectedAccounts.length === 0) return null

  return (
    <div>
      <div className="flex space-x-4 items-center">
        {/* Trial account toggle checkbox */}
        <div>
          <label className="inline-flex items-center">
            Limit to selected accounts
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-accent-600"
              checked={limitToSelectedAccounts}
              onChange={() => setLimitToSelectedAccounts(!limitToSelectedAccounts)}
            />
          </label>
        </div>
        <div>
          {/* Sort by dropdown */}
          <label className="inline-flex items-center text-sm">
            Sort by:
            <select
              className="ml-2 px-4 py-1"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value as AccountSort)}
            >
              <option value="name">Name</option>
              <option value="recent-score-low-to-high">Recent Score (Low to High)</option>
              <option value="recent-score-high-to-low">Recent Score (High to Low)</option>
            </select>
          </label>
        </div>
      </div>
      <table className="w-full">
        <thead>
          <tr>
            <th className="text-left">Account</th>
            {chartWeeks.map((week) => (
              <th
                key={dateFormat(week, "yyyy-MM-dd")}
                className="whitespace-nowrap text-xs font-medium px-1.5 text-left"
              >
                {dateFormat(week, "MMM dd")}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {accountAverages && (
            <tr>
              <td className="font-medium whitespace-nowrap">Average (All Accounts)</td>
              {accountAverages.map(([_, count]) => (
                <td key={_} className={classNames(getCellBg(count))}>
                  {numeral(count).format("0")}
                </td>
              ))}
            </tr>
          )}
          {accountEventCounts.map(({ account, data }) => (
            <>
              <tr
                key={account.id}
                onClick={() => toggleExpandedRow(account.id)}
                className="cursor-pointer"
              >
                <td className="whitespace-nowrap flex space-x-1 items-center">
                  {account.name}
                  <Button
                    variant="hollow"
                    size="small"
                    label="View"
                    onClick={() => openAdminEngagementDrawer(account.id)}
                  />
                </td>
                {data.map(([_, count]) => (
                  <td key={_} className={classNames(getCellBg(count))}>
                    {count}
                  </td>
                ))}
              </tr>
              <ExpandedAccountRow
                open={expandedRow === account.id}
                account={account}
                rollupEvents={rollupData}
                timePeriods={chartWeeks}
              />
            </>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const ExpandedAccountRow = ({
  account,
  rollupEvents,
  timePeriods,
  open,
}: {
  open: boolean
  account: Account
  rollupEvents: AccountEventCountsRollup[]
  timePeriods: Timestamp[]
}) => {
  if (!open) return null

  const getEventCount = (
    period: Timestamp,
    eventType: UserEventType,
    events: AccountEventCountsRollup[]
  ) => {
    const eventCounter = events.find((counter) => isSameWeek(counter.periodDate, period))

    if (eventCounter) {
      return eventCounter.accounts[account.id]?.eventCounts[eventType] || 0
    } else return 0
  }

  // Renders a table with no headers and rows representing the event types
  // Each cell is the event count for the given event type for each period
  return (
    <>
      {userEventTypes.map((eventType) => (
        <tr key={eventType}>
          <td className="text-xs text-accent-500">{eventType}</td>
          {timePeriods.map((period) => (
            <td key={dateFormat(period, "yyyy-MM-dd")} className="text-xs text-accent-500">
              {getEventCount(period, eventType, rollupEvents)}
            </td>
          ))}
        </tr>
      ))}
    </>
  )
}

const AccountEngagementPageWrapper: React.FC = () => {
  const firebase = useFirebaseReader()
  const allAccounts = useDocuments<Account>(firebase.adminGetAllAccountsRaw())
  const weeklyRollupEventCounts = useDocuments<AccountEventCountsRollup>(
    firebase.adminGetAccountEventCountRollupsBetweenDates(
      "week",
      moment().subtract(6, "months").toDate(),
      new Date()
    )
  )

  const monthlyRollupEventCounts = useDocuments<AccountEventCountsRollup>(
    firebase.adminGetAccountEventCountRollupsBetweenDates(
      "month",
      moment().subtract(3, "months").toDate(),
      new Date()
    )
  )

  if (
    allAccounts === "loading" ||
    weeklyRollupEventCounts === "loading" ||
    monthlyRollupEventCounts === "loading"
  ) {
    return <div>Loading...</div>
  }

  if (!allAccounts || !weeklyRollupEventCounts || !monthlyRollupEventCounts) {
    return <div>Error</div>
  }

  const clientAccounts = allAccounts.filter(
    // (account) => account.name !== "Caplight" && !account.name.includes("Test")
    (accoutn) => true
  )

  return (
    <AccountEngagementPage
      allAccounts={clientAccounts}
      weeklyRollupEvents={weeklyRollupEventCounts}
      monthlyRollupEvents={monthlyRollupEventCounts}
    />
  )
}

export default AccountEngagementPageWrapper
