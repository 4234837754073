import Spinner from "@components/icons/Spinner"
import { SwitchVerticalIcon } from "@heroicons/react/solid"
import { Pagination } from "@stories/components/Antd"
import { itemRender } from "@stories/components/Antd/Pagination/Pagination"
import { Button } from "@stories/components/Button/Button"
import Typography, { Size } from "@stories/components/Typography/Typography"
import DownChevronIcon from "@stories/icons/DownChevron"
import { ExternalLinkIcon } from "@stories/icons/ExternalLinkIcon"
import UpChevronIcon from "@stories/icons/UpChevronIcon"
import { PostgresInvestorEmployee } from "common/model/postgres/PostgresInvestors"
import { truncateString } from "common/utils/StringUtils"
import React, { useState } from "react"
import EmployeeEmail from "src/pages/Companies/LandingPage/EmployeeEmail"
import { SortStructure } from "../Deals/AllDealsTable"
import { cleanTitle, compareEmployees } from "./helpers"

export type InvestorEmployeesTableHeader =
  | ""
  | "Name"
  | "Title"
  | "Email"
  | "LinkedIn"
  | "Years at Firm"

interface InvestorEmployeesTableProps {
  employees: PostgresInvestorEmployee[]
  isLoading: boolean
}

export const InvestorEmployeesTable: React.FC<InvestorEmployeesTableProps> = ({
  employees,
  isLoading,
}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const startIdx = (currentPage - 1) * pageSize
  const endIdx = startIdx + pageSize

  const [sort, setSort] = useState<SortStructure<InvestorEmployeesTableHeader>>({
    col: "Name",
    dir: "desc",
  })

  const headers: InvestorEmployeesTableHeader[] = [
    "Name",
    "Title",
    "Email",
    "LinkedIn",
    "Years at Firm",
  ]

  const sortableHeaders: InvestorEmployeesTableHeader[] = [
    "Name",
    "Title",
    "Email",
    "LinkedIn",
    "Years at Firm",
  ]

  const handlePaginationChange = (newPage: number, newPageSize: number) => {
    setCurrentPage(newPage)
    setPageSize(newPageSize)
  }

  const handleHeaderClick = (header: InvestorEmployeesTableHeader) => {
    setCurrentPage(1)

    if (sort.col === header) {
      setSort({ col: header, dir: sort.dir === "asc" ? "desc" : "asc" })
    } else {
      setSort({ col: header, dir: "asc" })
    }
  }

  const sortedEmployees = employees.sort((a, b) => compareEmployees(a, b, sort))
  const employeesToDisplay = sortedEmployees.slice(startIdx, endIdx)

  return (
    <div
      className="w-full overflow-x-auto border rounded bg-neutral-white flex flex-col gap-2"
      data-dd-privacy="mask"
    >
      <table className="table-auto w-full overflow-y-visible bg-neutral-white">
        <thead className="bg-neutral-300">
          <tr>
            {headers.map((header) => (
              <th
                className="whitespace-nowrap px-2 text-left"
                key={header}
                onClick={() => handleHeaderClick(header)}
              >
                <div className="flex gap-2 items-center">
                  {header}
                  {sortableHeaders.includes(header) && (
                    <>
                      {header === sort.col ? (
                        <span>
                          {sort.col === header &&
                            (sort.dir === "asc" ? <UpChevronIcon /> : <DownChevronIcon />)}
                        </span>
                      ) : (
                        <span className="text-neutral-1000">
                          <SwitchVerticalIcon height={16} width={16} />
                        </span>
                      )}
                    </>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {employeesToDisplay.map((employee) => {
            const { firstName, lastName, title, email, yearsAtFirm, linkedInUrl } = employee

            const cleanedName = truncateString(`${firstName} ${lastName}`, 35)
            const cleanedTitle = cleanTitle(title)
            const cleanedEmail = email === null || email === "" || email === " " ? "Unknown" : email
            const cleanedYearsAtFirm = yearsAtFirm === null ? "Unknown" : yearsAtFirm

            return (
              <tr key={`${cleanedName}${cleanedEmail}`} className="border-b hover:bg-neutral-200">
                <td className="whitespace-nowrap px-2 border-r">
                  <Typography size={Size.Small} text={cleanedName} shouldWrap={false} />
                </td>
                <td className="whitespace-nowrap px-2 border-r max-w-32">
                  <Typography size={Size.Small} text={cleanedTitle} shouldWrap={false} />
                </td>
                <td className="whitespace-nowrap px-2 border-r">
                  {cleanedEmail === "Unknown" ? (
                    <EmployeeEmail employee={employee} />
                  ) : (
                    <Typography size={Size.Small} text={cleanedEmail} shouldWrap={false} />
                  )}
                </td>
                <td className="whitespace-nowrap px-2 border-r">
                  {linkedInUrl ? (
                    <a target="_blank" rel="noopener noreferrer" href={linkedInUrl}>
                      <Button variant="hollow-link" leftIcon={<ExternalLinkIcon />} />
                    </a>
                  ) : (
                    <Typography size={Size.Small} text="Unknown" shouldWrap={false} />
                  )}
                </td>
                <td className="whitespace-nowrap px-2">
                  <Typography size={Size.Small} text={cleanedYearsAtFirm} shouldWrap={false} />
                </td>
              </tr>
            )
          })}
          {isLoading && (
            <tr>
              <td colSpan={1000} className="p-2 py-8 bg-neutral-white text-neutral-800">
                <div className="w-full flex flex-col gap-4 items-center">
                  <div className="flex flex-col items-center">
                    <Spinner size="md" />
                  </div>
                </div>
              </td>
            </tr>
          )}
          {!isLoading && employees.length === 0 && (
            <tr>
              <td colSpan={1000} className="p-2 py-8 bg-neutral-white text-neutral-800">
                <div className="w-full flex flex-col gap-4 items-center">
                  <div className="flex flex-col items-center">
                    <div className="text-xs">No employees found</div>
                  </div>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Pagination
        className="p-2 flex gap-2"
        total={employees.length}
        current={currentPage}
        pageSize={pageSize}
        onChange={handlePaginationChange}
        hideOnSinglePage={false}
        itemRender={itemRender}
        pageSizeOptions={[10, 20, 50, 100]}
        showSizeChanger
      />
    </div>
  )
}
