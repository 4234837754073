import { Select } from "@stories/components/Antd"
import { Button } from "@stories/components/Button/Button"
import { Input } from "@stories/components/Inputs/Input/Input"
import { DealCRMContact, DealCRMContactCommonFields } from "common/model/DealCRM/DealCRMContact"
import { IndividualContactClassification } from "common/model/DealCRM/IndividualContactClassification"
import { firestoreConverter } from "common/model/FirestoreConverter"
import { isLoaded } from "common/utils/Loading"
import { getFirstAndLastName } from "common/utils/StringUtils"
import { assertUnreachable } from "common/utils/fp/Function"
import React, { FC, useEffect, useMemo, useState } from "react"
import { APIEndpoints, runAPIQuery } from "src/firebase/API"
import { useFirebaseWriter } from "src/firebase/Context"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import { createDealCRMContact } from "src/firebase/crm"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import { handleConsoleError, trackEventInFirestoreAndHeap } from "src/utils/Tracking"
import { useDebounce } from "src/utils/useDebounce"
import { individualClassificationSelectOptions } from "../../Contacts/CreateContactButton/CreateContactForm"
import {
  defaultBrokerContact,
  defaultBrokerageFirmContact,
  defaultFirmContact,
  defaultIndividualContact,
} from "../../Contacts/CreateContactButton/createContactHelpers"
import { InlineContactSelectComponentSource } from "./InlineContactSelectSearch"
import PopoverWrapper from "./PopoverWrapper"

export interface CreateNewContactPopoverProps {
  onContactCreated: (contact: DealCRMContact) => Promise<void>
  searchString: string
  handleClose: () => void
  innerRef: React.RefObject<HTMLDivElement>
  sourceComponent: InlineContactSelectComponentSource
  fullWidth?: boolean
}

type ContactType = IndividualContactClassification | "broker" | "firm" | "brokerage"

const buildContact = (name: string, type: ContactType, email: string | null): DealCRMContact => {
  const contactParams: {
    creationSource: DealCRMContactCommonFields["creationSource"]
  } = { creationSource: "add-interest-form" }
  switch (type) {
    case "broker":
      return {
        ...defaultBrokerContact(contactParams),
        name,
        firstName: getFirstAndLastName(name).firstName,
        lastName: getFirstAndLastName(name).lastName,
        email,
      }
    case "brokerage":
      return {
        ...defaultBrokerageFirmContact(contactParams),
        name,
      }
    case "firm":
      return {
        ...defaultFirmContact(contactParams),
        name,
        isBrokerage: false,
      }
    case "firm_contact_and_investor":
    case "shareholder":
    case "investor":
      return {
        ...defaultIndividualContact(contactParams),
        name,
        firstName: getFirstAndLastName(name).firstName,
        lastName: getFirstAndLastName(name).lastName,
        isTransactionalIndividual: true,
        classification: type,
        email,
      }
    case "firm_contact_only":
      return {
        ...defaultIndividualContact(contactParams),
        name,
        firstName: getFirstAndLastName(name).firstName,
        lastName: getFirstAndLastName(name).lastName,
        isTransactionalIndividual: false,
        classification: type,
        email,
      }
    default:
      return assertUnreachable(type)
  }
}

const CreateNewContactPopover: FC<CreateNewContactPopoverProps> = ({
  onContactCreated,
  searchString,
  handleClose,
  innerRef,
  fullWidth,
  sourceComponent,
}) => {
  const [contactName, setContactName] = useState(searchString)
  const [contactType, setContactType] = useState<ContactType | undefined>(undefined)
  const [contactEmail, setContactEmail] = useState<string | null>(null)
  const [submitting, setSubmitting] = useState(false)
  const [predictedContactType, setPredictedContactType] = useState<
    "individual" | "fund" | undefined
  >(undefined)
  const user = useCurrentUser()
  const db = useFirebaseWriter()
  const firebase9 = useFirebase9()

  const debouncedContactName = useDebounce(contactName, 300)

  useEffect(() => {
    if (debouncedContactName.length > 3 && isLoaded(user)) {
      // GPT-4 query to predict contact type for ordering of contact type options
      runAPIQuery(
        APIEndpoints.aiQuery,
        {
          query: `Given the following contact name, predict whether it is an individual or fund/org. Return values 'individual' or 'fund'. If you are unsure, return 'fund'. Contact name: ${debouncedContactName}`,
        },
        user
      )
        .then((response) => response.json())
        .then((data) => {
          if (data && typeof data === "object" && "result" in data && data.result) {
            if (data.result === "individual" || data.result === "fund") {
              setPredictedContactType(data.result)
            }
          }
        })
        .catch(handleConsoleError)
    }
  }, [user, debouncedContactName])

  const handleSubmit = async () => {
    if (!contactType || !contactName || !isLoaded(user)) return

    const contact: DealCRMContact = buildContact(contactName, contactType, contactEmail)
    setSubmitting(true)
    const createdContact = await createDealCRMContact({
      db,
      user: user.user,
      contact,
    })
      .then((createdRef) =>
        createdRef.withConverter<DealCRMContact>(firestoreConverter<DealCRMContact>()).get()
      )
      .then((doc) => {
        trackEventInFirestoreAndHeap(firebase9, user.user, "crm-contact-created", {
          source: sourceComponent,
        })
        return doc
      })
      .then((doc) => doc.data())

    if (createdContact) {
      onContactCreated(createdContact).catch(handleConsoleError)
      setSubmitting(false)
    }
  }

  const contactTypeOptions = useMemo(() => {
    const optionsOrgsFirst = individualClassificationSelectOptions
      .filter((opt) => opt.value !== "firm_contact_only" && opt.value !== "broker")
      .concat([
        { value: "broker", label: "Broker (individual)" },
        { value: "brokerage", label: "Brokerage (org)" },
        { value: "firm", label: "Fund/Org" },
      ])
      .reverse()
      .map((option) => {
        if (option.value === "firm_contact_and_investor") {
          return {
            ...option,
            label: "Transactional Firm Contact",
          }
        } else {
          return option
        }
      })
    return predictedContactType === "individual" ? optionsOrgsFirst.reverse() : optionsOrgsFirst
  }, [predictedContactType])

  return (
    <PopoverWrapper
      innerRef={innerRef}
      title="Create Contact"
      handleClose={handleClose}
      fullWidth={fullWidth}
    >
      <div className="flex-1 flex flex-col gap-2">
        <Input
          value={contactName}
          onChange={(e) => setContactName(e.target.value)}
          size="small"
          label="Name"
        />
        <Select<ContactType>
          placeholder="Select contact type"
          style={{ width: "100%" }}
          className="flex items-center justify-center"
          value={contactType}
          options={contactTypeOptions}
          onChange={setContactType}
        />
        {[undefined, "brokerage", "firm"].includes(contactType) ? null : (
          <Input
            value={contactEmail ?? undefined}
            onChange={(e) => setContactEmail(e.target.value)}
            size="small"
            label="Email"
          />
        )}
        <Button
          label="Create"
          isDisabled={!contactName || !contactType || submitting}
          onClick={handleSubmit}
        />
      </div>
    </PopoverWrapper>
  )
}

export default CreateNewContactPopover
