import { FirebaseReader } from "./Firebase"
import { firestoreConverter } from "@model/FirestoreConverter"
import { collections } from "common/firestore/Collections"
import { Company } from "common/model/Company"
import { DealCRMDeal, DealCRMDealIdFields } from "common/model/DealCRM/Deal/DealCRMDeal"
import {
  DealDistribution,
  DealDistributionIdFields,
  buildDealDistribution,
} from "common/model/DealDistribution/DealDistribution"
import { DealDistributionEmail } from "common/model/DealDistribution/DealDistributionEmail"
import { DealDistributionParticipant } from "common/model/DealDistribution/DealDistributionParticipant"
import { User } from "common/model/User"

export const getDealDistributions = ({ db, user }: { db: FirebaseReader; user: User }) =>
  db.db
    .collection(collections.dealDistributions)
    .withConverter<DealDistribution>(firestoreConverter<DealDistribution>())
    .where("account.id", "==", user.account.id)

export const getDealDistribution = ({
  db,
  dealDistributionId,
}: {
  db: FirebaseReader
  dealDistributionId: string
}) =>
  db.db
    .collection(collections.dealDistributions)
    .withConverter<DealDistribution>(firestoreConverter<DealDistribution>())
    .doc(dealDistributionId)

export const getDealDistributionForDeal = ({
  db,
  user,
  deal,
}: {
  db: FirebaseReader
  user: User
  deal: Pick<DealCRMDealIdFields, "id">
}) =>
  db.db
    .collection(collections.dealDistributions)
    .withConverter<DealDistribution>(firestoreConverter<DealDistribution>())
    .where("account.id", "==", user.account.id)
    .where("sourceId.id", "==", deal.id)

export const createDealDistributions = ({
  db,
  user,
  deal,
  company,
}: {
  db: FirebaseReader
  user: User
  deal: DealCRMDeal
  company: Company
}) => {
  const newDealDistribution = buildDealDistribution(user, deal, company)
  return db.db
    .collection(collections.dealDistributions)
    .withConverter<DealDistribution>(firestoreConverter<DealDistribution>())
    .doc(newDealDistribution.id)
    .set(newDealDistribution)
    .then(() => newDealDistribution)
}

export const updateDealDistribution = ({
  db,
  dealDistribution,
  details,
  emails,
  participantView,
  visibility,
  startAt,
  isParticipantViewIncludedInDistribution,
}: {
  db: FirebaseReader
  dealDistribution: DealDistributionIdFields
  details?: DealDistribution["details"]
  emails?: DealDistribution["emails"]
  participantView?: DealDistribution["participantView"]
  visibility?: DealDistribution["visibility"]
  startAt?: DealDistribution["startAt"]
  isParticipantViewIncludedInDistribution?: DealDistribution["isParticipantViewIncludedInDistribution"]
}) =>
  db.db
    .collection(collections.dealDistributions)
    .withConverter<DealDistribution>(firestoreConverter<DealDistribution>())
    .doc(dealDistribution.id)
    .update({
      ...(details ? { details } : {}),
      ...(emails ? { emails } : {}),
      ...(participantView ? { participantView } : {}),
      ...(visibility ? { visibility } : {}),
      ...(startAt ? { startAt } : {}),
      ...(isParticipantViewIncludedInDistribution !== undefined ? { isParticipantViewIncludedInDistribution } : {}),
    })
    .then(() => dealDistribution)

export const updateDealDistributionVisibility = ({
  db,
  dealDistribution,
  visibility,
}: {
  db: FirebaseReader
  dealDistribution: DealDistributionIdFields
  visibility: DealDistribution["visibility"]
}) =>
  db.db
    .collection(collections.dealDistributions)
    .withConverter<DealDistribution>(firestoreConverter<DealDistribution>())
    .doc(dealDistribution.id)
    .update({ visibility })
    .then(() => dealDistribution)

export const addDealDistributionEmail = ({
  db,
  dealDistribution,
  email,
}: {
  db: FirebaseReader
  dealDistribution: DealDistribution
  email: DealDistributionEmail
}) =>
  db.db
    .collection(collections.dealDistributions)
    .withConverter<DealDistribution>(firestoreConverter<DealDistribution>())
    .doc(dealDistribution.id)
    .update({
      emails: [...(dealDistribution.emails?.filter((e) => e.tag !== email.tag) ?? []), email],
    })
    .then(() => dealDistribution)

export const updateDealDistributionParticipant = ({
  db,
  participant,
}: {
  db: FirebaseReader
  participant: DealDistributionParticipant
}) =>
  db.db
    .collection(collections.dealDistributions)
    .doc(participant.dealDistribution.id)
    .collection(collections.dealDistributionSubcollections.participants)
    .doc(participant.id)
    .withConverter<DealDistributionParticipant>(firestoreConverter<DealDistributionParticipant>())
    .update(participant)
    .then(() => participant)

export const toggleParticipantGroupApproval = ({
  db,
  participants,
  date,
}: {
  db: FirebaseReader
  participants: DealDistributionParticipant[]
  date: Date | null
}) => {
  const batch = db.db.batch()
  participants.forEach((p) => {
    batch.update(
      db.db
        .collection(collections.dealDistributions)
        .doc(p.dealDistribution.id)
        .collection(collections.dealDistributionSubcollections.participants)
        .doc(p.id)
        .withConverter<DealDistributionParticipant>(
          firestoreConverter<DealDistributionParticipant>()
        ),
      { "status.approvedDate": date }
    )
  })
  return batch.commit()
}

export const getDealDistributionParticipants = ({
  db,
  dealDistributionId,
  accountId,
}: {
  db: FirebaseReader
  dealDistributionId: string
  accountId: string
}) =>
  db.db
    .collection(collections.dealDistributions)
    .doc(dealDistributionId)
    .collection(collections.dealDistributionSubcollections.participants)
    .where("account.id", "==", accountId)
    .withConverter<DealDistributionParticipant>(firestoreConverter<DealDistributionParticipant>())
