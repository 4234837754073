import SectionLabel from "@stories/components/Inputs/SectionLabel/SectionLabel"
import { SizeInput } from "@stories/components/Inputs/SizeInput/SizeInput"
import { Company } from "common/model/Company"
import { PriceData } from "common/model/Order/OrderForm/State"
import { OrderCommission } from "common/model/Order/Order"
import { CommissionSection } from "./Commission"
import { PricePerShare } from "./PricePerShare"

type OrderPriceData = Omit<PriceData, "priceMatch"> & { commission?: OrderCommission | null }

interface OrderPriceSectionProps {
  value: Partial<OrderPriceData>
  onChange: (priceData: Partial<OrderPriceData>) => void
  title?: string
  titles: {
    size?: string
    canChargeClientFee: string
    orderPrice: string
    orderPriceTooltip: string
    yesCanChargeFeeOption: string
    noCanChargeFeeOption: string
    unknownCanChargeFeeOption: string
    commissionNotesLabel: string
    commissionNotesPlaceholder: string
    commissionNotesHintText: string
  }
  hiddenFields?: string[]
  company?: Company
  showUnpriced?: boolean
}

export const OrderPriceSection = ({
  value,
  onChange,
  title = "Order Terms",
  hiddenFields,
  titles,
  company,
  showUnpriced,
}: OrderPriceSectionProps) => (
  <div className="mb-4 flex flex-col gap-4">
    {hiddenFields?.includes("price") ? null : (
      <div>
        <SectionLabel>{title}</SectionLabel>
        <PricePerShare
          disabled={hiddenFields?.includes("price")}
          value={value.price}
          onChange={(price) => onChange({ ...value, price })}
          company={company}
          titles={titles}
          showUnpriced={showUnpriced}
        />
      </div>
    )}
    {hiddenFields?.includes("commission") || value.price === null ? null : (
      <div>
        <CommissionSection
          disabled={hiddenFields?.includes("commission")}
          titles={titles}
          value={value}
          onChange={(commission) => onChange({ ...value, ...commission })}
        />
      </div>
    )}
    {hiddenFields?.includes("size") ? null : (
      <div className="flex flex-col gap-4">
        <SizeInput
          disabled={hiddenFields?.includes("size")}
          label={titles.size}
          value={value.size}
          onChange={(size) => onChange({ ...value, size })}
        />
      </div>
    )}
  </div>
)
