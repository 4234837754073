import { DocumentType } from "./Document"
import { firestoreConverter } from "./FirestoreConverter"

export interface DocumentTemplate {
  id: string
  type: DocumentType
  htmlFileName: string
  pdfFileName: string
  docxFileName: string
  storageFolder: string
  pdfDownloadUrl: string
  docxDownloadUrl: string
  createdAt: Date
  updatedAt?: Date
}

export const documentTemplateConverter = firestoreConverter<DocumentTemplate>()
