import { Loading, mapLoading } from "common/utils/Loading"
import { useEffect, useMemo, useState } from "react"
import { useErrorHandler } from "src/providers/errorHandler"
import * as Sentry from "@sentry/react"
import { getAllDocs, Query } from "src/firebase/Firebase/utils"
import _ from "lodash"
import { useFirebaseWriter } from "../../../firebase/Context"
import Firebase from "../../../firebase/Firebase"

export type UseQuerySnapshotOptions = { allowCache?: boolean} 

export function useQuerySnapshot<R, T = R[]>(
  f: (db: Omit<Firebase, "adminDb">) => Query<R> | null,
  postprocess: (r: R[]) => T,
  dependencies: unknown[] = [],
  { allowCache = true }: UseQuerySnapshotOptions = {}
): Loading<T> {
  const firebase = useFirebaseWriter()
  const [result, setResult] = useState<Loading<R[]>>("loading")
  const { handleError } = useErrorHandler()
  useEffect(() => {
    // eslint-disable-next-line rulesdir/no-let
    let isSubscribed: boolean = true
    // console.log("subscribing")
    const unsubscribe = f(firebase)?.onSnapshot(
      (x) => {
        if (isSubscribed && (allowCache || !x.metadata.fromCache)) {
          setResult(getAllDocs(x))
        }
      },
      (e) => {
        if (isSubscribed) {
          Sentry.captureException(e)
          if (_.isError(e)) {
            handleError(e)
          }
          setResult(null)
        }
      }
    )
    return () => {
      if (unsubscribe) {
        // console.log("unsubscribing")
        unsubscribe()
      }
      // eslint-disable-next-line better-mutation/no-mutation
      isSubscribed = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)
  const finalResult = useMemo(() => mapLoading(postprocess)(result), [postprocess, result])
  return finalResult
}
