import { OrderInquiryUserFormState } from "common/model/OrderInquiry/UserFormState"
import { Field, Form } from "react-final-form"
import { Button } from "@stories/components/Button/Button"
import { TextAreaField } from "../../../components/inputs/TextAreaField"
import { useErrorHandler } from "../../../providers/errorHandler"

const OrderInquiryForm = ({
  submitButtonId,
  onSubmit,
  submitting,
}: {
  submitButtonId: string
  submitting: boolean
  onSubmit: (formState: OrderInquiryUserFormState) => Promise<void>
}) => {
  const { handleError } = useErrorHandler()

  return (
    <Form<OrderInquiryUserFormState>
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form
          onSubmit={() => {
            handleSubmit()?.catch(handleError)
          }}
          className="flex-grow flex flex-col"
        >
          <Field
            name="additionalQuestions"
            label="Additional Questions (optional)"
            labelClassName="font-bold text-sm text-primary-500"
            placeholder="Add your question..."
            fullWidth
            component={TextAreaField}
          />
          <div className="flex-grow" />
          <Button
            id={submitButtonId}
            isLoading={submitting}
            variant="primary"
            onClick={() => {
              handleSubmit()?.catch(handleError)
            }}
            label="Submit"
          />
        </form>
      )}
    />
  )
}

export default OrderInquiryForm
