import React from "react"

const LiveIcon: React.FC<React.PropsWithChildren<{ className?: string; size?: "sm" | "md" }>> = ({
  className,
  size = "md",
}) => (
  <div
    className={`flex items-center w-max py-0.5 px-1 rounded-md shadow font-medium bg-danger-600 text-white ${
      size === "md" ? "text-sm" : "text-xs"
    } ${className ?? ""}`}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      className={`${size === "md" ? "w-4 h-4" : "w-3 h-3"} mr-0.5`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.348 14.651a3.75 3.75 0 010-5.303m5.304 0a3.75 3.75 0 010 5.303m-7.425 2.122a6.75 6.75 0 010-9.546m9.546 0a6.75 6.75 0 010 9.546M5.106 18.894c-3.808-3.808-3.808-9.98 0-13.789m13.788 0c3.808 3.808 3.808 9.981 0 13.79M12 12h.008v.007H12V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
      />
    </svg>
    Live
  </div>
)

export default LiveIcon
