import { collections } from "common/firestore/Collections"
import { firestoreConverter } from "common/model/FirestoreConverter"
import { IntroductionRequest } from "common/model/IntroductionRequest"
import { createContext, ReactNode, useMemo } from "react"
import { useFirebaseWriter } from "src/firebase/Context"
import {
  DocumentContextData,
  FirebaseDocumentProvider,
  useFirebaseDocuments,
} from "./FirebaseDocumentProvider"
import { DocumentIdContainer } from "./internal/DocumentIdContainer"

export const IntroductionRequestContext = createContext<DocumentContextData<IntroductionRequest>>({
  documents: {},
  requestDocLookups: () => {},
})

export const IntroductionRequestProvider2 = ({ children }: { children: ReactNode }) => {
  const firebase = useFirebaseWriter()
  const orderCollection = useMemo(
    () =>
      firebase.writerDb
        .collection(collections.introductionRequest)
        .withConverter<IntroductionRequest>(firestoreConverter<IntroductionRequest>()),
    [firebase.writerDb]
  )
  return (
    <FirebaseDocumentProvider Context={IntroductionRequestContext} collection={orderCollection}>
      {children}
    </FirebaseDocumentProvider>
  )
}

export const useIntroductionRequests = <C extends DocumentIdContainer = DocumentIdContainer>(
  ids: C
) => useFirebaseDocuments(ids, IntroductionRequestContext)
