import { OrderCard } from "@components/ItemCards/OrderCard"
import { LiveMarketOrderRenewedEvent } from "common/model/AccountActivityLog/AccountActivityLog"
import React from "react"

interface LiveMarketOrderRenewedDetailsProps {
  event: LiveMarketOrderRenewedEvent
}

export const LiveMarketOrderRenewedDetails: React.FC<LiveMarketOrderRenewedDetailsProps> = ({
  event,
}) => {
  const { order } = event

  return <OrderCard order={order} hideDate />
}
