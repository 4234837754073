import { StructureCell } from "@stories/components/Orders/TableCells/StructureCell"
import { Order as WrappedOrder } from "common/model/Order/Models/Wrapped"
import { Order } from "common/model/Order/Order"
import { Nothing } from "common/containers/Maybe"

interface MyOrdersStructureCellProps {
  order: Order
}

export const MyOrdersStructureCell: React.FC<MyOrdersStructureCellProps> = ({ order }) => {
  const wrappedOrder = WrappedOrder.wrapRaw({
    order,
    company: Nothing,
  }).withUnconstrainedDefault(null)

  return wrappedOrder ? <StructureCell order={wrappedOrder} /> : "-"
}
