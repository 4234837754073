import SearchIcon from "@heroicons/react/solid/SearchIcon"
import { Input } from "@stories/components/Inputs/Input/Input"
import { XIcon } from "@stories/icons/XIcon"
import { FC, MutableRefObject } from "react"

export interface ISearchInputProps {
  placeholder?: string
  onChange: (value: string) => void
  onFocus?: () => void
  name?: string
  value?: string
  id?: string
  heapName?: string
  clearable?: boolean
  inputRef?: MutableRefObject<HTMLInputElement | null>
}

export const SearchInput: FC<React.PropsWithChildren<ISearchInputProps>> = ({
  onChange,
  onFocus,
  placeholder,
  name,
  value,
  id,
  heapName,
  clearable = false,
  inputRef,
}) => (
  <Input
    inputRef={inputRef}
    size="small"
    prefix={<SearchIcon className="h-5 w-5" aria-hidden="true" />}
    type="text"
    value={value || ""}
    onChange={(e) => onChange(e.target.value)}
    onFocus={onFocus}
    placeholder={placeholder}
    name={name || "search"}
    id={id || (placeholder ? `search-${placeholder}` : "search")}
    heapName={heapName}
    suffix={
      value && clearable ? (
        <button type="button" className="p-2" onClick={() => onChange("")}>
          <XIcon size="medium" />
        </button>
      ) : null
    }
  />
)
