import { Checkbox } from "@components/inputs/checkbox/Checkbox"
import { StopClickPropagation } from "@stories/components/StopClickPropagation/StopClickPropagation"
import {
  ShareableItem,
  viewCompanyIdsFromShareableItem,
} from "common/model/SharedOrder/SharedOrderResponse"
import { createContext, useContext, useMemo, useState } from "react"
import { trackEvent } from "src/utils/Tracking"

export const ShareOrderCheckbox = ({ shareableItem }: { shareableItem: ShareableItem }) => {
  const { isItemSelected, onSelectItem, isAvailable } = useShareItemsContext()
  if (!isAvailable) return null
  return (
    <StopClickPropagation>
      <Checkbox
        id={shareableItem.id}
        checked={isItemSelected(shareableItem)}
        onChange={() => {
          if (!isItemSelected(shareableItem)) {
            trackEvent("shared-order-selected", {
              company: viewCompanyIdsFromShareableItem(shareableItem).name,
              companyId: viewCompanyIdsFromShareableItem(shareableItem).id,
            })
          }
          onSelectItem(shareableItem)
        }}
      />
    </StopClickPropagation>
  )
}

type ShareItemsContextType = {
  isAvailable: boolean
  onSelectItem: (item: ShareableItem) => void
  isItemSelected: (item: ShareableItem) => boolean
  resetSelectedItems: () => void
  selectedItems: ShareableItem[]
}

export const ShareItemsContext = createContext<ShareItemsContextType>({
  isAvailable: false,
  onSelectItem: () => {},
  selectedItems: [],
  isItemSelected: () => false,
  resetSelectedItems: () => false,
})

const isItemSelected = (item: ShareableItem, items: ShareableItem[]) =>
  !!items.find((searchItem) => searchItem.id === item.id)

export const ShareMyItemsProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedItems, setSelectedItems] = useState<ShareableItem[]>([])
  const value: ShareItemsContextType = useMemo(
    () => ({
      onSelectItem: (item) =>
        setSelectedItems((prevItems) =>
          isItemSelected(item, prevItems)
            ? prevItems.filter((searchItem) => item.id !== searchItem.id)
            : prevItems.concat(item)
        ),
      selectedItems,
      isItemSelected: (item: ShareableItem) => isItemSelected(item, selectedItems),
      resetSelectedItems: () => setSelectedItems([]),
      isAvailable: true,
    }),
    [selectedItems]
  )
  return <ShareItemsContext.Provider value={value}>{children}</ShareItemsContext.Provider>
}

export const useShareItemsContext = () => useContext(ShareItemsContext)
