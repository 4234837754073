import * as zod from "zod"
import { isoDateValidator } from "../../utils/Validator"

export const postgresInvestorEmployee = zod.object({
  id: zod.string(), // Apollo id from Postgres table people_contacts
  firstName: zod.string(),
  lastName: zod.string(),
  title: zod.string(),
  email: zod.string().or(zod.null()), // null means email is unavailable
  yearsAtFirm: zod.number().or(zod.null()),
  linkedInUrl: zod.string().or(zod.null()),
})
export type PostgresInvestorEmployee = zod.infer<typeof postgresInvestorEmployee>

// TODO345 differentiate company investors from overall investors
const postgresInvestor = zod.object({
  investorId: zod.string(),
  name: zod.string(),
  websiteDomain: zod.string().or(zod.null()),
  isLead: zod.boolean(),
  numberOfEmployees: zod.number().or(zod.null()),
  leadPartner: zod.string().or(zod.null()), // This is not a very well structured field, but it is expected to contain full name of the lead partner (person) on deal
  investorType: zod.enum(["individual", "fund"]).or(zod.null()),
})
export const postgresCompanyFundingRoundWithInvestors = zod
  .object({
    date: zod.string().refine((x) => isoDateValidator.validate(x)),
    valuation: zod.number().or(zod.null()),
    price: zod.number().or(zod.null()),
    amount: zod.number().or(zod.null()),
    round: zod.string(),
    investors: zod.array(postgresInvestor),
  })
  .or(
    zod.object({
      roundInfo: zod.enum(["unknown"]), // when we don't know which round investors invested in
      investors: zod.array(postgresInvestor),
    })
  )
export type PostgresCompanyFundingRoundWithInvestors = zod.infer<
  typeof postgresCompanyFundingRoundWithInvestors
>

// Rounds by investors
export const postgresCompanyInvestorWithCompanyFundingRounds = zod
  .object({
    rounds: zod
      .array(
        zod.object({
          date: zod.string().refine((x) => isoDateValidator.validate(x)),
          valuation: zod.number().or(zod.null()),
          price: zod.number().or(zod.null()),
          amount: zod.number().or(zod.null()),
          round: zod.string(),
        })
      )
      .or(zod.null()),
  })
  .and(postgresInvestor)

export type PostgresCompanyInvestorWithCompanyFundingRounds = zod.infer<
  typeof postgresCompanyInvestorWithCompanyFundingRounds
>

export const emailRequestResult = zod
  .object({
    email: zod.string(),
    email_status: zod.string().or(zod.null()),
  })
  .or(zod.null())
export type EmailRequestResult = zod.infer<typeof emailRequestResult>
