import { SET_LOADING } from "../actions/appStates"
import { ReducerAction } from "./postgresData"

interface AppState {
  loading: boolean
}

const initialState: AppState = {
  loading: false,
}

type AppStateAction = ReducerAction<typeof SET_LOADING, { loading: boolean }>

export function appStatesReducer(state: AppState | undefined, action: AppStateAction): AppState {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...(state ?? initialState),
        loading: action.loading,
      }
    default:
      return { ...(state ?? initialState) }
  }
}

export default appStatesReducer
