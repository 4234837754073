import { collections } from "common/firestore/Collections"
import { Account, AccountAccessFields, AccountId, AccountIdFields } from "common/model/Account"
import { ProductStatus, ProductArea } from "common/model/Auth/Permissions"
import {
  DocumentSubmission,
  DocumentType,
  SubmittedDocument,
} from "common/model/files/DocumentSubmission"
import { SubmissionStatus } from "common/model/data-product/SubmissionStatus"
import { UserIdFields, viewUserIdFields } from "common/model/User"
import { firestoreConverter } from "../../../model/FirestoreConverter"
import { FirebaseReader, FirebaseWriter } from "../../Firebase"
import { addDoc, betweenDates, getDocData } from "../utils"

const documentSubmissions = (db: FirebaseReader) =>
  db.db
    .collection(collections.documentSubmissions)
    .withConverter(firestoreConverter<DocumentSubmission>())

const accountDocumentSubmissions =
  (db: FirebaseReader, documentType: DocumentType) => (accountId: AccountId) =>
    documentSubmissions(db).where("account.id", "==", accountId)

export const getDocumentSubmissions = (
  db: FirebaseReader,
  documentType: DocumentType = "data-submission"
) => getDocData(documentSubmissions(db))
export const getAccountDocumentSubmissions =
  (accountId: AccountId, documentType: DocumentType = "data-submission") =>
  (db: FirebaseReader) =>
    getDocData(accountDocumentSubmissions(db, documentType)(accountId))
export const getProcessingDocumentSubmissions = (db: FirebaseReader) => {
  const processingStatus: SubmissionStatus = "processing"
  return getDocData(documentSubmissions(db).where("status", "==", processingStatus))
}

export const getDocumentSubmissionsBetweenTimes =
  (db: FirebaseReader, documentType: DocumentType = "data-submission") =>
  (accountId: AccountId, after: Date, before: Date) =>
    getDocData(
      betweenDates(accountDocumentSubmissions(db, documentType)(accountId))(
        "eventDate",
        after,
        before
      )
    )

const documentSubmissionsBetweenDocumentTimesQuery =
  (db: FirebaseReader, documentType: DocumentType) =>
  (accountId: AccountId, after: Date, before: Date) =>
    betweenDates(accountDocumentSubmissions(db, documentType)(accountId))(
      "document.documentDate",
      after,
      before
    )

export const getDocumentSubmissionsBetweenDocumentTimes =
  (db: FirebaseReader, documentType: DocumentType = "data-submission") =>
  (accountId: AccountId, after: Date, before: Date) =>
    getDocData(
      documentSubmissionsBetweenDocumentTimesQuery(db, documentType)(accountId, after, before)
    )

export const getLastDocumentSubmissionBetweenDocumentTimes =
  (db: FirebaseReader, documentType: DocumentType = "data-submission") =>
  (accountId: AccountId, after: Date, before: Date) =>
    getDocData(
      documentSubmissionsBetweenDocumentTimesQuery(db, documentType)(
        accountId,
        after,
        before
      ).limit(1)
    )

export const generateSubmittedDocument = (
  filePath: string,
  documentDate: Date | undefined,
  documentType: DocumentType
): SubmittedDocument => ({
  filePath,
  documentDate: documentDate || new Date(),
  documentType,
})

export const createDocumentSubmission =
  (db: FirebaseWriter) =>
  (
    user: UserIdFields,
    filePath: string,
    account: Account,
    documentDate: Date | undefined,
    documentType: DocumentType
  ) =>
    addDoc(
      db.db
        .collection(collections.documentSubmissions)
        .withConverter(firestoreConverter<DocumentSubmission>())
    )({
      user: viewUserIdFields(user),
      eventDate: new Date(),
      status: "processing",
      document: generateSubmittedDocument(filePath, documentDate, documentType),
      account,
      eventType: "document-submission",
    })

export const createMockDocumentSubmission =
  (db: FirebaseWriter) =>
  (user: UserIdFields, account: AccountIdFields, documentType: DocumentType) =>
    addDoc(
      db.writerDb
        .collection(collections.documentSubmissions)
        .withConverter(firestoreConverter<DocumentSubmission>())
    )({
      user: viewUserIdFields(user),
      eventDate: new Date(),
      status: "processing",
      document: {
        documentType,
      },
      account,
      eventType: "document-submission",
    })

export const setDocumentSubmissionStatus =
  (db: FirebaseWriter) => (uploadEvent: DocumentSubmission, status: SubmissionStatus) =>
    db.db
      .collection(collections.documentSubmissions)
      .doc(uploadEvent.id)
      .update({ status })
      .then(() => ({ ...uploadEvent, ...{ status } }))

const onboardingUpdateObject = (area: ProductArea, value: ProductStatus) => ({
  [`onboardingStatus.${area}.status`]: value,
})
export const setAccountStartedOnboarding =
  (db: FirebaseWriter) => (account: AccountIdFields & AccountAccessFields, area: ProductArea) =>
    db.db
      .collection(collections.accounts)
      .doc(account.id)
      .update(onboardingUpdateObject(area, "in progress"))

export const setDocumentSubmissionRawSource =
  (db: FirebaseWriter) =>
  (
    uploadEvent: DocumentSubmission,
    source: DocumentSubmission["document"]["submissionSourceInformation"]
  ) => {
    if (!source) return Promise.resolve()
    return db.db
      .collection(collections.documentSubmissions)
      .doc(uploadEvent.id)
      .update({ "document.submissionSourceInformation": source })
  }
