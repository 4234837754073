import Typography, { Color, Size, Weight } from "../../Typography/Typography"

interface PillProps {
  label: string
  size?: Size
}

const Pill: React.FC<PillProps> = ({ label, size = Size.XXSmall }) => (
  <div className="py-0.5 px-1.5 border border-neutral-400 bg-neutral-200 rounded-md text-center whitespace-nowrap inline-block max-w-max">
    <Typography
      color={Color.Primary}
      text={
        <span className="flex items-center justify-center space-x-1">
          <span>{label}</span>
        </span>
      }
      weight={Weight.Semibold}
      size={size}
    />
  </div>
)

export default Pill
