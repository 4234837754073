import { identity } from "../../fp/Function"
import { SubtypeWitness } from "./Subtype"

// Check if two types can be assigned to each other, which is as close as we can get to an equality check
export type Eq<X, Y> = X extends Y ? (Y extends X ? true : false) : false

/** A value witnessing the equality of `A` and `B` */
export class EqWitness<in out A, in out B> extends SubtypeWitness<A, B> {
  /** Use an `EqWitness<A,B>` to "convert" an A into a B */
  private readonly value: <R>(a: A | B, witness: (w: A & B) => R) => R

  /** the implementation here is not fully sound in the presence of subtyping (for example: `((a: number, witness: (w: 5) => R)) => witness(5)` works), so we need to keep the constructor private */
  private constructor(value: <R>(a: A | B, witness: (w: A & B) => R) => R) {
    super(value)
    this.value = value
  }

  /** Types are equal to themselves */
  static refl = <T>() => new EqWitness<T, T>((a, w) => w(a))

  /** If A = B then B = A */
  invert(): EqWitness<B, A> {
    return new EqWitness((w, a) => this.value(w, a))
  }

  /** Use an EqWitness to coerce a value */
  override run(a: A): B {
    return this.value(a, identity)
  }
}
