import { FirebaseDataSource } from "common/queries/sources/firestore/Root"
import { firebase } from "src/providers/AppProviders"

// eslint-disable-next-line rulesdir/no-let, @typescript-eslint/naming-convention
let _dataSource: FirebaseDataSource | undefined = undefined

export const dataSource = () => {
  if (_dataSource === undefined) {
    // eslint-disable-next-line better-mutation/no-mutation
    _dataSource = new FirebaseDataSource({
      db: () => firebase.writerDb,
      caches: {},
    })
  }
  return _dataSource
}
