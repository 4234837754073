/* eslint-disable @typescript-eslint/no-floating-promises */
import { createContext, FC, useContext, useMemo, useCallback } from "react"
import { useCompany } from "src/pages/Data/Hooks"
import { Loading } from "common/utils/Loading"
import { Company } from "common/model/Company"
import { DocumentSnapshot } from "src/firebase/Firebase/utils"
import { Maybe } from "common/containers/Maybe"
import { PriceObservationType } from "common/model/data-product/pricing/PriceObservation"
import { useClosedTradeFormState } from "./contextHooks/useClosedTradeFormState"
import {
  useClosedTradeFormSaving,
  PriceObservationErrors,
} from "./contextHooks/useClosedTradeFormSaving"

interface ClosedTradeFormContextData {
  handleChange: (value: Partial<PriceObservationType>) => void
  priceObservation: Partial<PriceObservationType>
  savedClosedTrade: PriceObservationType | null
  validateAndSave: () => void
  saveStatus: string
  selectCompany: () => Loading<Company>
  formErrors: PriceObservationErrors
  onClose: () => void
  drawerTitle: string | null
}

const ClosedTradeFormContext = createContext<ClosedTradeFormContextData>({
  handleChange: () => () => null,
  priceObservation: {},
  validateAndSave: () => null,
  savedClosedTrade: null,
  saveStatus: "pending",
  selectCompany: () => null,
  formErrors: new Set(),
  onClose: () => null,
  drawerTitle: null,
})

const ClosedTradeFormContextProvider: FC<
  React.PropsWithChildren<{
    initialPriceObservation: Partial<PriceObservationType>
    onSave: (o: PriceObservationType) => Promise<Maybe<DocumentSnapshot<PriceObservationType>>>
    onClose: () => void
    closedTradeFormOpen: boolean
    drawerTitle?: string
  }>
> = ({ children, initialPriceObservation, onSave, onClose, closedTradeFormOpen, drawerTitle }) => {
  const { priceObservation, handleChange } = useClosedTradeFormState(initialPriceObservation, [
    closedTradeFormOpen,
  ])

  const company = useCompany(priceObservation.company?.id ?? "loading")
  const selectCompany = useCallback(() => company, [company])

  const { validateAndSave, formErrors, saveStatus, savedClosedTrade } = useClosedTradeFormSaving(
    priceObservation,
    onSave,
    [closedTradeFormOpen]
  )

  const contextData: ClosedTradeFormContextData = useMemo(
    () => ({
      // formManagement
      handleChange,
      priceObservation,
      // form submit
      validateAndSave,
      formErrors,
      saveStatus,
      savedClosedTrade,
      // output selectors
      selectCompany,
      onClose,
      drawerTitle: drawerTitle ?? null,
    }),
    [
      drawerTitle,
      formErrors,
      handleChange,
      onClose,
      priceObservation,
      saveStatus,
      savedClosedTrade,
      selectCompany,
      validateAndSave,
    ]
  )

  return (
    <ClosedTradeFormContext.Provider value={contextData}>
      {children}
    </ClosedTradeFormContext.Provider>
  )
}

export const useClosedTradeForm = () => useContext(ClosedTradeFormContext)

export default ClosedTradeFormContextProvider
