import { useMessageThreads } from "src/providers/data/MessageThreadProvider"
import UnreadIcon from "@stories/components/Badges/UnreadIcon/UnreadIcon"

const UnreadMessagesIcon = () => {
  const { unreadCount } = useMessageThreads()
  return (
    <>
      {unreadCount ? (
        <UnreadIcon count={unreadCount < 10 ? unreadCount : "9+"} variant="accent" />
      ) : null}
    </>
  )
}

export default UnreadMessagesIcon
