/* eslint-disable max-classes-per-file */
import { v4 as uuid } from "uuid"
import { Record as ImmutableRecord, List } from "immutable"
import { isValid } from "date-fns"
import { Schema, Schema as z } from "../../schema/Schema"
import { Interval } from "../../utils/data/Interval"
import { OrderQuantityTerms, OrderTerms, StructureOrderTerms, orderStructures } from "./Types/Terms"
import {
  makePossiblyIncompleteOrderQuantityTerms,
  orderQuantityTermsSchema,
} from "./Types/Terms/Quantity"
import { Order } from "./Models/Internal"
import { Rec, UnsafeRec } from "../../utils/RecordUtils"

const orderTermDateMapSchema = z.record(
  z
    .date()
    .refine(isValid)
    .map((d) => d.valueOf().toString())
    .contramap((x: string) => new Date(Number.parseInt(x, 10))),
  orderQuantityTermsSchema
)

const embedTermsSchema = <T>(x: Schema<T, T>) =>
  z.array(z.object({ key: x, value: orderTermDateMapSchema })).refine((ts) => {
    const dates = ts.flatMap((t) => [...Object.keys(t.value)])
    return new Set(dates).size === dates.length
  })

export const orderTermsSchema = z
  .object({
    unknown: embedTermsSchema(z.object({})),
    structure_agnostic_regular_way: embedTermsSchema(z.object({})),
    direct: embedTermsSchema(z.object({})),
    spv: embedTermsSchema(
      z.object({
        carry: z.float().or(z.null()),
        managementFee: z.float().or(z.null()),
      })
    ),
    forward: embedTermsSchema(z.object({})),
    call_option: embedTermsSchema(
      z.object({
        strike: z.float(),
        expiration: z.date(),
        style: z.enum(["American", "European"]),
      })
    ),
    put_option: embedTermsSchema(
      z.object({
        strike: z.float(),
        expiration: z.date(),
        style: z.enum(["American", "European"]),
      })
    ),
    swap: embedTermsSchema(z.object({})),
    variable_prepaid_forward: embedTermsSchema(
      z.object({
        USDPerShareForMaximumSharesDelivered: z.float(),
        USDPerShareForMinimumSharesDelivered: z.float(),
      })
    ),
    collective_liquidity_exchange_fund: embedTermsSchema(z.object({})),
  })
  .refine((t) => Object.values(t).some((v) => v.length > 0))

type AddTermData<T extends keyof Order["terms"]> = {
  date: Date
  structure: T
  structureData: StructureOrderTerms[T]
  quantityData: OrderQuantityTerms | null
}

/** Ultimately just a wrapper around a list, but with a more convenient interface */
export class OrderTermsHistoryUpdate {
  private constructor(private readonly _addTermHistory: List<AddTermData<keyof Order["terms"]>>) {}

  run(initial: Order["terms"]): Order["terms"] {
    const addTerm = <T extends keyof Order["terms"]>(
      { structure, date, structureData, quantityData }: AddTermData<T>,
      r: ImmutableRecord<Order["terms"]>
    ): ImmutableRecord<Order["terms"]> =>
      r.set(
        structure,
        r.get(structure).concat([
          {
            key: structureData,
            value: {
              [date.valueOf()]: quantityData,
            },
          },
        ]) as OrderTerms[T]
      )

    return ImmutableRecord<Order["terms"]>(Rec.tabulate(orderStructures, () => []))(initial)
      .withMutations((r) =>
        this._addTermHistory.reduce<ImmutableRecord<Order["terms"]>>(
          (acc, next) => addTerm(next, acc),
          r
        )
      )
      .toJS() as Order["terms"]
  }

  runOnEmptyHistory() {
    return this.run(Rec.tabulate(orderStructures, () => []))
  }

  static empty = () => new OrderTermsHistoryUpdate(List())

  addTerm<T extends keyof Order["terms"]>(
    date: Date,
    structure: T,
    structureData: StructureOrderTerms[T],
    quantityData: OrderQuantityTerms | null
  ) {
    return new OrderTermsHistoryUpdate(
      this._addTermHistory.push({
        date,
        structure,
        structureData,
        quantityData,
      })
    )
  }

  addTerms(
    date: Date,
    args: {
      [key in keyof StructureOrderTerms]?: {
        structureData: StructureOrderTerms[key]
        quantityData: OrderQuantityTerms | null
      }
    }
  ) {
    return UnsafeRec.entries(args).reduce<OrderTermsHistoryUpdate>(
      (acc, [k, terms]) =>
        terms ? acc.addTerm(date, k, terms.structureData, terms.quantityData) : acc,
      this
    )
  }

  concat(after: OrderTermsHistoryUpdate) {
    return new OrderTermsHistoryUpdate(this._addTermHistory.concat(after._addTermHistory))
  }

  modify(f: (x: typeof this) => typeof this) {
    return f(this)
  }
}

export interface RawOrderTerms {
  sharePriceUSD?: number
  valuationUSD?: number
  amountUSD: number
  structures: (keyof StructureOrderTerms)[]
  carry?: number
  managementFee?: number
}

export const buildOrderTermsFromFlatOrderTerms = (
  orderData: RawOrderTerms,
  orderDate: Date
): OrderTerms =>
  buildOrderTermsFromFlatOrderTermsWithNoRequiredFields(
    { ...orderData, amountUSD: Interval.pure(orderData.amountUSD) },
    orderDate
  )

export const buildOrderTermsFromFlatOrderTermsWithNoRequiredFields = (
  orderData: Partial<Omit<RawOrderTerms, "amountUSD">> &
    Pick<RawOrderTerms, "structures"> & { amountUSD?: Interval<number> },
  orderDate: Date
): OrderTerms => {
  // this is bad
  const dropKeysWithUndefined = <T extends object>(x: T): T => {
    const result: T = {} as T
    Object.entries(x).forEach(([k, v]) => {
      if (v !== undefined) {
        result[k as keyof T] = v as T[keyof T]
      }
    })
    return result
  }

  const quantityTerms: () => OrderQuantityTerms = () =>
    makePossiblyIncompleteOrderQuantityTerms(
      dropKeysWithUndefined({
        amountUSD: orderData.amountUSD ? orderData.amountUSD : undefined,
        id: uuid(),
        targetValuation: orderData.valuationUSD,
        USDPerShare: orderData.sharePriceUSD ? Interval.pure(orderData.sharePriceUSD) : undefined,
        shares: undefined,
      })
    )
  const { structures } = orderData
  const spvTerms: StructureOrderTerms["spv"] = structures.includes("spv")
    ? { carry: orderData.carry || null, managementFee: orderData.managementFee || null }
    : { carry: null, managementFee: null }
  return OrderTermsHistoryUpdate.empty()
    .modify((x) =>
      structures.includes("direct") ? x.addTerm(orderDate, "direct", {}, quantityTerms()) : x
    )
    .modify((x) =>
      structures.includes("spv") ? x.addTerm(orderDate, "spv", spvTerms, quantityTerms()) : x
    )
    .modify((x) =>
      structures.includes("forward") ? x.addTerm(orderDate, "forward", {}, quantityTerms()) : x
    )
    .modify((x) =>
      structures.includes("unknown") ? x.addTerm(orderDate, "unknown", {}, quantityTerms()) : x
    )
    .runOnEmptyHistory()
}
