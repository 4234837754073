import { ShareOrderButton } from "@components/ShareOrder/ShareOrderButton"
import SkeletonLoader from "@components/icons/SkeletonLoader"
import { Subtitle } from "@components/typography/Subtitle"
import { CurrentUser } from "@model/CurrentUser"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import {
  SharedOrder,
  getSharedOrderSourceFullName,
  getSharedWithStatus,
  getSharedWithStatusLabel,
  getSharedWithUserEmail,
  isSharedOrderSharedWithUser,
} from "common/model/SharedOrder/SharedOrder"
import { Loading, isLoaded } from "common/utils/Loading"
import { sortByStringValue } from "common/utils/SortUtils"
import { shortDateFormat, sortByDate } from "common/utils/dateUtils"
import AdminVisible from "src/admin/AdminVisible"
import { useFirebaseWriter } from "src/firebase/Context"
import { useDocuments } from "src/firebase/Firestore"
import { CopySharedOrderAccessLink } from "src/pages/Shared/Components/CopySharedOrderAccessLink"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { capitalize } from "lodash"
import { ShareableItem } from "common/model/SharedOrder/SharedOrderResponse"
import { SectionHeaderAccordion } from "@components/data_display/Accordion"
import { WYSIWYGViewer } from "src/pages/CRM/Components/Notes/WYSIWYGViewer"
import { useCRMContacts } from "src/pages/CRM/Providers/CRMContactsProvider"
import { CRMContactName } from "src/pages/CRM/Contacts/ContactDetailsDrawer/CRMContactName"
import { Order } from "common/model/Order/Models/Wrapped"
import { Nothing } from "common/containers/Maybe"
import { User } from "common/model/User"
import { assertUnreachable } from "common/utils/fp/Function"
import { getCRMContactEmail } from "common/model/DealCRM/DealCRMContact"

export const SharedOrderContainerInner = ({
  sharedOrders,
  shareableItem,
  shareableItemTypeString,
}: {
  sharedOrders: Loading<SharedOrder[]>
  shareableItem: ShareableItem
  shareableItemTypeString: "indication" | "buy interest" | "sell interest"
}) => {
  const { findContactByEmail } = useCRMContacts()
  return (
    <div className="p-4 rounded flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <div className="flex flex-col gap-2">
          <Subtitle subtitle={`Share ${capitalize(shareableItemTypeString)}`} />
          {isLoaded(sharedOrders) ? (
            <Typography
              color={Color.Subtitle}
              text={`You've shared this ${shareableItemTypeString} ${sharedOrders.length} times.`}
            />
          ) : null}
        </div>
        <ShareOrderButton
          variant="secondary"
          item={shareableItem}
          title={`Share ${capitalize(shareableItemTypeString)}`}
        />
      </div>
      <div className="flex flex-col gap-2">
        {isLoaded(sharedOrders) &&
          !!sharedOrders.length &&
          sharedOrders
            .sort(sortByDate("createdAt"))
            .reverse()
            .map((sharedOrder) => (
              <div key={sharedOrder.id} className="border rounded">
                <SectionHeaderAccordion
                  className="border rounded"
                  header={
                    <div key={sharedOrder.id} className="flex flex-col gap-2 pt-2">
                      <div className="flex items-start justify-between gap-4">
                        <div className="flex flex-col">
                          <Typography
                            weight={Weight.Light}
                            size={Size.Small}
                            color={Color.Subtitle}
                            text={`${shortDateFormat(
                              sharedOrder.createdAt
                            )} - ${getSharedOrderSourceFullName(sharedOrder)}`}
                          />
                        </div>
                      </div>
                      {Object.values(sharedOrder.sharedWith)
                        .sort(sortByStringValue("id"))
                        .map((sharedWith) => {
                          const email = getSharedWithUserEmail(sharedWith)
                          const contact = findContactByEmail(email)
                          return (
                            <div className="flex items-center justify-between" key={sharedWith.id}>
                              <div className="-space-y-1">
                                <div>
                                  {contact ? (
                                    <CRMContactName contact={contact} />
                                  ) : (
                                    <Typography
                                      size={Size.Small}
                                      weight={
                                        isSharedOrderSharedWithUser(sharedOrder, sharedWith.id)
                                          ? Weight.Semibold
                                          : undefined
                                      }
                                      color={
                                        isSharedOrderSharedWithUser(sharedOrder, sharedWith.id)
                                          ? Color.Primary
                                          : Color.Subtitle
                                      }
                                      text={
                                        sharedWith.tag === "off platform"
                                          ? sharedWith.email
                                          : `${sharedWith.user.firstName} ${sharedWith.user.lastName} (${sharedWith.user.email})`
                                      }
                                    />
                                  )}
                                </div>
                                <div>
                                  <Typography
                                    weight={Weight.Light}
                                    text={`${getSharedWithStatusLabel(
                                      getSharedWithStatus(sharedWith)
                                    )}${
                                      sharedWith?.response?.message
                                        ? ` - "${sharedWith.response.message}"`
                                        : ""
                                    }`}
                                    size={Size.XSmall}
                                    color={Color.Subtitle}
                                  />
                                </div>
                              </div>
                              <div className="flex items-center gap-1">
                                <AdminVisible>
                                  <CopySharedOrderAccessLink
                                    sharedOrder={sharedOrder}
                                    sharedWithId={sharedWith.id}
                                  />
                                </AdminVisible>
                              </div>
                            </div>
                          )
                        })}
                    </div>
                  }
                  content={
                    <>
                      {sharedOrder.userForm.emailSubject ? (
                        <div className="m-4 p-4 rounded border">
                          <Typography text={sharedOrder.userForm.emailSubject} />
                        </div>
                      ) : null}
                      <div className="m-4 p-4 rounded border">
                        <WYSIWYGViewer content={sharedOrder.userForm.emailBody} />
                      </div>
                    </>
                  }
                />
              </div>
            ))}
        {isLoaded(sharedOrders) && !sharedOrders.length ? (
          <div className="border rounded">
            <div className="flex flex-col items-center m-auto my-20 gap-4">
              <Typography
                text={`This ${shareableItemTypeString} has not been shared. `}
                weight={Weight.Bold}
              />
              <div className="max-w-100">
                <Typography
                  color={Color.Placeholder}
                  text={`Invite one or more trusted partners to share your ${shareableItemTypeString} with. Each recipient will be sent an email and a revokable link to view all details.`}
                />
              </div>
              <ShareOrderButton
                item={shareableItem}
                title={`Share ${capitalize(shareableItemTypeString)}`}
              />
            </div>
          </div>
        ) : null}
        {!isLoaded(sharedOrders) ? <SkeletonLoader numRows={4} /> : null}
      </div>
    </div>
  )
}

const LoadedShareOrderContainerWithCurrentUser = ({
  item,
  currentUser,
}: {
  item: ShareableItem
  currentUser: CurrentUser
}) => {
  const { user } = currentUser
  const firebase = useFirebaseWriter()

  const sharedOrders = useDocuments<SharedOrder>(
    firebase.getSharedOrdersFromSharableItem(item, user.account),
    [item, user.account]
  )

  return (
    <SharedOrderContainerInner
      sharedOrders={sharedOrders}
      shareableItem={item}
      shareableItemTypeString="indication"
    />
  )
}
const accountCanShareOrder = (user: User, item: ShareableItem) => {
  if (item.tag === "crm_interest" || item.tag === "deal_search") return true
  if (item.tag === "order") {
    const order = Order.wrapRaw({ order: item.order, company: Nothing }).toNullable()
    if (!order) return false
    return (
      order.fromAccount(user.account) &&
      order.sourceAttribution(user).match(
        () => false,
        () => true
      )
    )
  }
  return assertUnreachable(item)
}

const LoadedShareOrderContainer = ({ item }: { item: ShareableItem }) => {
  const currentUser = useLoggedInUser()
  return accountCanShareOrder(currentUser.user, item) || currentUser.isAdmin ? (
    <LoadedShareOrderContainerWithCurrentUser item={item} currentUser={currentUser} />
  ) : null
}

export default LoadedShareOrderContainer
