import { Button } from "@stories/components/Button/Button"
import { TrashIcon } from "@stories/icons/TrashIcon"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { useState } from "react"
import DeleteContactModal from "../DeleteContactModal"

interface DeleteContactButtonProps {
  contact: DealCRMContact
}

export const DeleteContactButton: React.FC<DeleteContactButtonProps> = ({ contact }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  return (
    <>
      {deleteModalOpen && (
        <DeleteContactModal contact={contact} handleClose={() => setDeleteModalOpen(false)} />
      )}
      <Button
        variant="hollow"
        onClick={() => setDeleteModalOpen(true)}
        label="Delete Contact"
        size="small"
        leftIcon={<TrashIcon />}
      />
    </>
  )
}
