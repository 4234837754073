import { Order } from "common/model/Order/Order"
import { isLoaded } from "common/utils/Loading"
import * as Wrapped from "common/model/Order/Models/Wrapped"
import * as W from "common/utils/Loading/Wrapped"
import { useCompany } from "src/pages/Data/Hooks"
import { Just } from "common/containers/Maybe"
import { OrderLookupFields } from "common/model/Order/Models/Internal"
import { useMemo } from "react"
import { firestoreConverter } from "common/model/FirestoreConverter"
import { restrictedCollections } from "common/firestore/Collections"
import { canShowOnPlatform } from "common/model/data-product/DataPoint/VisibilityFields"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { usePrivateOrder } from "./usePrivateOrders"
import { collections } from "common/firestore/Collections"
import { User } from "common/model/User"
import { useDocumentSnapshot } from "src/utils/hooks/queries/useDocumentSnapshot"

const isOwnOrder = (o: Order, u: User) => o.source.account.id === u.account.id

export const useOrder = ({
  id: orderId,
  darkpool,
  orderCollection,
}: OrderLookupFields): W.Loading<Wrapped.Order> => {
  const collectionPath =
    darkpool || orderCollection === "darkpool"
      ? restrictedCollections.darkpoolOrderObservations
      : orderCollection === "platform"
      ? restrictedCollections.orderObservations
      : orderCollection === "tentativeInterest"
      ? collections.tentativeInterest
      : null
  const loadingFirebaseOrder = useDocumentSnapshot(
    (db) =>
      collectionPath
        ? db.writerDb
            .collection(collectionPath)
            .withConverter<Order>(firestoreConverter<Order>())
            .doc(orderId)
        : null,
    [orderId, collectionPath]
  )
  const loadingPrivateOrder = usePrivateOrder(orderCollection === "private" ? orderId : null)
  const loadingOrder = loadingFirebaseOrder ?? loadingPrivateOrder
  const loadingCompany = useCompany(
    isLoaded(loadingOrder)
      ? loadingOrder instanceof Wrapped.Order
        ? loadingOrder.company().id
        : loadingOrder.company.id
      : "na"
  )
  const user = useLoggedInUser()
  return useMemo(
    () =>
      loadingOrder instanceof Wrapped.Order
        ? new W.Loading(loadingOrder)
        : new W.Loading(loadingOrder)
            .alongside(new W.Loading(loadingCompany))
            .map(([o, company]) =>
              canShowOnPlatform(o, user.user)
                ? isOwnOrder(o, user.user)
                  ? Wrapped.OwnOrder.wrapOwnOrder({
                      order: o,
                      company: Just(company),
                    }).withUnconstrainedDefault(null)
                  : Wrapped.Order.wrapRaw({
                      order: o,
                      company: Just(company),
                    }).withUnconstrainedDefault(null)
                : null
            )
            .bind((x) => new W.Loading(x)),
    [loadingCompany, loadingOrder, user.user]
  )
}
