/* eslint-disable react/jsx-props-no-spreading */
import { FC } from "react"
import { config } from "../../../config"
import AlgoliaCompanyAutocomplete from "./AlgoliaCompanyAutocomplete"
import { CompanyAutocompleteProps } from "./CompanyAutocompleteProps"
import FirebaseCompanyAutocomplete from "./FirebaseCompanyAutocomplete"

const CompanyAutocomplete: FC<React.PropsWithChildren<CompanyAutocompleteProps>> = (props) =>
  !config.offlineMode ? (
    <AlgoliaCompanyAutocomplete {...props} />
  ) : (
    <FirebaseCompanyAutocomplete {...props} />
  )

export default CompanyAutocomplete
