import { AccessControlFeatureName } from "./AccessControl"

/*
 * should be written such that "Upgrade to {action}" makes sense
 *
 */
const accessControlFeatureDisplayActionMap: Record<AccessControlFeatureName, string> = {
  orderHistoryData: "view order history",
  tradeHistoryPage: "view trade history",
  tradeHistoryPricing: "view trade data",
  tradeHistoryDates: "view trade dates",
  tradeHistoryStructure: "view trade structure",
  tradeHistoryShareClass: "view trade share class",
  currentMarketPriceData: "view MarketPrice",
  companyPageFundingRoundsTable: "view funding rounds",
  companyPage409aValuationsTable: "view 409a valuations",
  companyPageFilingsTable: "view company filings",
  companyPageInvestorsTable: "view company investors",
  companyPageMutualFundMarksTable: "view mutual fund marks",
  companyPageBidOfferVolumeRatioChart: "view bid/offer volume ratio",
  companyPageTradingContextSection: "view trading context",
  companyPageResearchReportsSection: "view company research reports",
  companyPageComparablesSection: "view company comparables",
  fundingRoundsPage: "view funding rounds",
  fundingRoundsData: "view funding round data",
  liveMarketParticipation: "view market data",
  sectorsData: "view sector analysis",
  contributeTradeData: "contribute trade data",
  companyNewsData: "view company news",
  marketInsightsPage: "view market insights",
  companyPageShare: "share company page",
  companyPageFlagIssue: "flag issue on company page",
  companyPageExport: "export company data",
  rfqData: "view RFQ data",
}

export const getAccessControlFeatureDisplayAction = (feature: AccessControlFeatureName) =>
  accessControlFeatureDisplayActionMap[feature]
