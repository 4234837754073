import { EitherVersion } from "../SizeInput/SizeInput"
import { NumberField, sizeScaledInputWarning } from "../NumberInput/NumberField"
import { formatAbbreviatedNumber } from "common/utils/math/format"

export interface SizeInputBoxProps {
  version: EitherVersion
  value?: number
  onChange: (s: number) => void
  disabled?: boolean
  label: string
}

export const SizeInputBox = ({ version, value, onChange, disabled, label }: SizeInputBoxProps) => (
  <>
    {version.match(
      () => (
        <NumberField
          key={`volume_${label}`}
          name="volume"
          label={label}
          value={value}
          onChange={(v) => onChange(v ?? 0)}
          isDisabled={disabled}
          prefix="$"
          formatter={formatAbbreviatedNumber}
          placeholder="e.g. 200K or 5M"
          inputWarningFunction={sizeScaledInputWarning}
        />
      ),
      () => (
        <NumberField
          key={`share_${label}`}
          formatter={formatAbbreviatedNumber}
          name="shares"
          label={label}
          suffix={
            <span className="text-sm text-gray-400 font-normal whitespace-nowrap mr-2">Shares</span>
          }
          value={value}
          onChange={(v) => onChange(v ?? 0)}
          isDisabled={disabled}
          placeholder="e.g. 20k or 1M"
        />
      )
    )}
  </>
)
