import { PageTitle } from "@components/typography/PageTitle"
import { FormLabel } from "@stories/components/Inputs/FormLabel/FormLabel"
import { CrossIcon } from "@stories/icons/CrossIcon"

export const OrderFormHeader = ({
  onClose,
  drawerTitle,
  drawerSubtitle,
}: {
  onClose?: () => void
  drawerTitle: string | null
  drawerSubtitle?: string
}) => (
  <div>
    <div className="flex items-center gap-2">
      {onClose ? (
        <button className="text-neutral-600 hover:text-neutral-800" type="button" onClick={onClose}>
          <CrossIcon />
        </button>
      ) : null}
      <PageTitle title={drawerTitle ?? "Submit New Order"} />
    </div>
    {drawerSubtitle ? (
      <div className="ml-6">
        <FormLabel>{drawerSubtitle}</FormLabel>
      </div>
    ) : null}
  </div>
)
