import { Collection, collections, restrictedCollections } from "common/firestore/Collections"
import {
  Account,
  AccountId,
  AccountIdFields,
  NewAccount,
  viewAccountIdFields,
} from "common/model/Account"
import { UserEventCounter } from "common/model/Analytics/EventCounter"
import { AccountEventCountsRollup } from "common/model/Analytics/EventCounterRollup"
import { AuthCode } from "common/model/AuthCode"
import { ClientFeedback } from "common/model/ClientFeedback"
import { Company } from "common/model/Company"
import { RFQEmailType } from "common/model/EmailType"
import { GenericNotification } from "common/model/Notifications/GenericNotification"
import { Order } from "common/model/Order/Order"
import { RFQRequest, RFQResponse, RFQType } from "common/model/RFQ/RFQ"
import { SharedOrder } from "common/model/SharedOrder/SharedOrder"
import { SharedWithContact } from "common/model/SharedOrder/SharedWithContacts"
import { User, UserProductInteractionHistory, viewUserIdFields } from "common/model/User"
import { WatchlistItem } from "common/model/Watchlist"
import {
  WatchlistCompany,
  WatchlistIndustry,
  WatchlistOpportunityInterest,
} from "common/model/WatchlistCompany"
import { CampaignParticipant } from "common/model/campaigns/CampaignParticipant"
import { CampaignBatch, CampaignRef, CampaignType } from "common/model/campaigns/CampaignType"
import { SubmissionStatus } from "common/model/data-product/SubmissionStatus"
import { PriceObservationType } from "common/model/data-product/pricing/PriceObservation"
import {
  DocumentType as DataDocumentType,
  DocumentSubmission,
} from "common/model/files/DocumentSubmission"
import { Holding } from "common/model/holdings/Holding"
import { HoldingDocument, HoldingDocumentType } from "common/model/holdings/HoldingDocument"
import { annotate } from "common/utils/Coerce"
import { randomString } from "common/utils/RandomUtils"
import { titleCase } from "common/utils/StringUtils"
import { deepCopy, head } from "common/utils/data/Array/ArrayUtils"
import { Interval } from "common/utils/data/Interval"
import { Maybe } from "common/containers/Maybe"
import { isString } from "common/utils/json/validate"
import saveAs from "file-saver"
import firebaseApp from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/functions"
import "firebase/compat/storage"
import { chunk, orderBy } from "lodash"
import { SingletonObject, unsafeAssertSingleton } from "src/utils/hooks/effects/useEffectSafe"
import { config } from "../config"
import { CurrentUser } from "../model/CurrentUser"
import { DocumentType, documentConverter } from "../model/Document"
import { documentTemplateConverter } from "../model/DocumentTemplate"
import { firestoreConverter } from "../model/FirestoreConverter"
import { fundConverter } from "../model/Fund"
import { industryConverter } from "../model/Industry"
import { publicOptionConverter } from "../model/PublicOption"
import { publicOptionObservationConverter } from "../model/PublicOptionObservation"
import { auctionConverter } from "../model/auctions/Auction"
import { auctionBidConverter } from "../model/auctions/AuctionBid"
import { auctionParticipantConverter } from "../model/auctions/AuctionParticipant"
import { privateAuctionDataConverter } from "../model/auctions/PrivateAuctionData"
import { FileUtils } from "../utils/FileUtils"
import { handleConsoleError } from "../utils/Tracking"
import {
  accountConverter,
  partialRfqResponseConverter,
  platformPageInviteConverter,
  rfqConverter,
  rfqResponseConverter,
} from "./Converters"
import { getAllDocs, inQuery, oneOrNone } from "./Firebase/utils"
import { AdminAllAccountsCache } from "common/model/AdminAllAccountsCache"
import { HasTransactions, ITransaction } from "common/firestore/Interface"
import { assertExtends } from "common/utils/data/Type/Assertions"
import { Valuation409a } from "common/model/CompanyValuation"
import { AccountActivityLogEvent } from "common/model/AccountActivityLog/AccountActivityLog"
import { OrderSourceType } from "common/model/Order/OrderSource"
import { assertUnreachable } from "common/utils/fp/Function"
import { ShareableItem } from "common/model/SharedOrder/SharedOrderResponse"
import { ShareholderFormSubmission } from "common/model/holdings/ShareholderFormSubmission"
import { publicCompanyForOptionsImportConverter } from "common/model/PublicCompanyForOptionsImport"

assertExtends<
  firebaseApp.firestore.Transaction,
  ITransaction<firebaseApp.firestore.Transaction, firebaseApp.firestore.DocumentReference<object>>
>
assertExtends<
  firebaseApp.firestore.Firestore,
  HasTransactions<firebaseApp.firestore.Transaction, firebaseApp.firestore.DocumentReference>
>

let _fb: Firebase | undefined = undefined
const userConverter = firestoreConverter<User>()
const partialUserConverter = firestoreConverter<Partial<User>>()
const companyConverter = firestoreConverter<Company>()
const holdingConverter = firestoreConverter<Holding>()
const holdingDocumentConverter = firestoreConverter<HoldingDocument>()

export const { FieldValue, FieldPath } = firebaseApp.firestore

export const { Timestamp } = firebaseApp.firestore

type RestrictCollections<T extends { collection: (x: string) => unknown }> = Omit<
  T,
  "collection"
> & {
  collection: (c: Collection) => ReturnType<T["collection"]>
}

// FirebaseReader, FirebaseWriter, and Firebase will likely always be backed by the same concrete objects, but expose different fields to make it easier to track which components make use of which capabilities
// not enforced yet but good to track
export type FirebaseWriter = Omit<Firebase, "adminDb">
export type FirebaseReader = Omit<FirebaseWriter, "writerDb">

class Firebase {
  auth: firebaseApp.auth.Auth

  db: SingletonObject<RestrictCollections<firebaseApp.firestore.Firestore>>

  writerDb: SingletonObject<firebaseApp.firestore.Firestore>

  adminDb: SingletonObject<firebaseApp.firestore.Firestore>

  documentStorage: SingletonObject<firebaseApp.storage.Storage>

  imageStorage: SingletonObject<firebaseApp.storage.Storage>

  functions: SingletonObject<firebaseApp.functions.Functions>

  constructor() {
    if (!firebaseApp.apps.length) {
      firebaseApp.initializeApp(config.firebase)
      firebaseApp.firestore().settings({ experimentalAutoDetectLongPolling: true })
    }

    this.adminDb = unsafeAssertSingleton(_fb?.db || firebaseApp.firestore())
    this.writerDb = this.adminDb
    this.db = this.adminDb

    this.auth = _fb?.auth || firebaseApp.auth()
    this.documentStorage = unsafeAssertSingleton(
      _fb?.documentStorage || firebaseApp.app().storage(config.firebase.documentStorageBucket)
    )
    this.imageStorage = unsafeAssertSingleton(
      _fb?.imageStorage || firebaseApp.app().storage(config.firebase.imageStorageBucket)
    )
    this.functions = unsafeAssertSingleton(_fb?.functions || firebaseApp.functions())

    if (!_fb && window.location.hostname === "localhost") this.useEmulators()
    if (!_fb) _fb = this
  }

  cloudApi = config.firebase.cloudApiUrl

  // cloudApi = this.functions.httpsCallable('api')

  doc = (collectionName: string, uid: string) => this.db.doc(`${collectionName}/${uid}`)

  /** User and Auth */
  createUserWithEmailAndPassword = (email: string, password: string) =>
    this.auth.createUserWithEmailAndPassword(email, password)

  createPasswordlessUser = (email: string) => {
    const password = randomString()
    return this.auth.createUserWithEmailAndPassword(email, password)
  }

  signInWithEmailAndPassword = (email: string, password: string) =>
    this.auth.signInWithEmailAndPassword(email, password)

  signInWithCustomToken = (token: string) => this.auth.signInWithCustomToken(token)

  signOut = () => this.auth.signOut()

  verifyPasswordResetCode = (code: string) => this.auth.verifyPasswordResetCode(code)

  confirmPasswordReset = (code: string, newPassword: string) =>
    this.auth.confirmPasswordReset(code, newPassword)

  passwordUpdate = (password: string) => this.auth.currentUser?.updatePassword(password)

  sendPasswordResetEmail = (email: string) => this.auth.sendPasswordResetEmail(email)

  markAllNotificationsAsRead = async (userId: string) => {
    const notifications = await this.db
      .collection(collections.userNotifications)
      .where("user.id", "==", userId)
      .where("readStatus", "==", "unread")
      .get()

    const batchedUpdates = chunk(notifications.docs, 500)
    // eslint-disable-next-line no-restricted-syntax
    for (const subarray of batchedUpdates) {
      const batch = this.db.batch()
      subarray.forEach((update) =>
        batch.update(update.ref, { readStatus: "read" } satisfies Partial<GenericNotification>)
      )
      await batch.commit()
    }
  }

  markNotificationAsDeleted = async (notificationId: string) => {
    await this.db
      .collection(collections.userNotifications)
      .doc(notificationId)
      .update({ isDeleted: true, readStatus: "read" } satisfies Partial<GenericNotification>)
  }

  markNotificationAsRead = async (notificationId: string) => {
    await this.db
      .collection(collections.userNotifications)
      .doc(notificationId)
      .update({ readStatus: "read" } satisfies Partial<GenericNotification>)
  }

  markNotificationAsUnread = async (notificationId: string) => {
    await this.db
      .collection(collections.userNotifications)
      .doc(notificationId)
      .update({ readStatus: "unread" } satisfies Partial<GenericNotification>)
  }

  newAccountActivityLogEventsQuery = (accountId: string, afterDate: Date) => {
    const query = this.db
      .collection(collections.accountActivityLog)
      .where("accountId", "==", accountId)
      .where("eventTime", ">", afterDate)
      .orderBy("eventTime", "desc")
      .withConverter(firestoreConverter<AccountActivityLogEvent>())
    return query
  }

  getAccountActivityLogEvents = (params: {
    accountId: string
    limit: number
    beforeDate: Date
  }) => {
    const query = this.db
      .collection(collections.accountActivityLog)
      .where("accountId", "==", params.accountId)
      .where("eventTime", "<", params.beforeDate)
      .orderBy("eventTime", "desc")
      .limit(params.limit)
      .withConverter(firestoreConverter<AccountActivityLogEvent>())
    return query
  }

  getUser = (uid: string) =>
    this.db.collection(collections.users).doc(uid).withConverter(userConverter).get()

  adminGetUserAuthCodes = async (email: string): Promise<AuthCode[]> => {
    const authCodes = await this.db
      .collection(collections.userAuthCodes)
      .where("user.email", "==", email)
      .withConverter(firestoreConverter<AuthCode>())
      .get()
      .then((result) => result.docs.map((doc) => doc.data()))

    return authCodes
  }

  user = (uid: string) =>
    this.db.doc(`${collections.users}/${uid}`).withConverter(partialUserConverter)

  fund = (uid: string) =>
    this.db.collection(collections.funds).doc(uid).withConverter(fundConverter)

  account = (uid: string) =>
    this.db.collection(collections.accounts).doc(uid).withConverter(accountConverter)

  latestPublicOptionObservations = (ticker: string) =>
    this.db
      .collection(collections.publicOptionData)
      .doc(ticker)
      .collection(collections.publicOptionDataSubcollections.observations)
      .orderBy("observedDate", "desc")
      .limit(1)
      .withConverter(publicOptionObservationConverter)

  historicalPublicOptionObservations = (ticker: string) =>
    this.db
      .collection(collections.publicOptionData)
      .doc(ticker)
      .collection(collections.publicOptionDataSubcollections.observations)
      .orderBy("observedDate", "asc")
      .limit(1)
      .withConverter(publicOptionObservationConverter)

  optionDataForObservation = (
    observation: firebaseApp.firestore.DocumentReference,
    expirationTs: string,
    optionType: "call" | "put" | "Call" | "Put",
    outOfTheMoneyPercentage: number
  ) =>
    observation
      .collection(collections.publicOptionDataSubcollections.expirations)
      .doc(expirationTs)
      .collection(optionType.toLowerCase() === "call" ? "calls" : "puts")
      .where("strike_percent_of_spot", ">=", outOfTheMoneyPercentage - 0.15)
      .where("strike_percent_of_spot", "<=", outOfTheMoneyPercentage + 0.15)
      .withConverter(publicOptionConverter)

  getTickers = (searchString: string) =>
    this.db
      .collection(collections.publicOptionData)
      .orderBy("ticker")
      .startAt(searchString)
      .endAt(`${searchString}\uf8ff`)
      .withConverter(publicCompanyForOptionsImportConverter)

  accountPriceObservationsQuery = (accountId: AccountId) =>
    this.writerDb
      .collectionGroup(restrictedCollections.companySubcollections.priceObservations)
      .where("observedBy.id", "==", accountId)
      .withConverter(firestoreConverter<PriceObservationType & { id: string }>())

  accountPriceObservations = (accountId: AccountId) =>
    this.accountPriceObservationsQuery(accountId)
      .get()
      .then((result) => result.docs.map((doc) => doc.data()))

  accountOrderObservations = (accountId: AccountId) =>
    this.writerDb
      .collection(restrictedCollections.orderObservations)
      .where("source.account.id", "==", accountId)
      .withConverter(firestoreConverter<Order>())
      .get()
      .then((result) => result.docs.map((doc) => doc.data()))

  adminGetOrderObservations = (params: {
    accountId: string
    createdBefore?: Date
    createdAfter?: Date
    companyId?: string
    onlyCSVSubmissions?: boolean
  }) => {
    let query = this.writerDb
      .collection(restrictedCollections.orderObservations)
      .where("source.account.id", "==", params.accountId)

    query = params.companyId ? query.where("company.id", "==", params.companyId) : query

    query = params.onlyCSVSubmissions
      ? query.where("source.sourceType", "==", annotate<OrderSourceType>("document-upload"))
      : query

    return query
      .where("createdAt", "<", params.createdBefore || new Date("2300-01-01"))
      .where("createdAt", ">", params.createdAfter || new Date("2000-01-01"))
      .withConverter(firestoreConverter<Order>())
      .get()
  }

  recordNoObservations = async (
    mostRecentUploadDate: Date,
    accountId: AccountId,
    documentType: DataDocumentType
  ) => {
    const period: Interval<Date> = {
      lowerBound: mostRecentUploadDate,
      upperBound: new Date(),
    }

    await this.db
      .collection(collections.accounts)
      .doc(accountId)
      .update(`noDataProductSubmissionPeriods.${documentType}`, FieldValue.arrayUnion(period))
  }

  recordNoHistoricalOrders = async (accountId: AccountId) => {
    await this.db
      .collection(collections.accounts)
      .doc(accountId)
      .update({ noHistoricalOrdersToSubmit: true } satisfies Partial<Account>)
  }

  accountDataSubmissions = async (accountId: AccountId, status?: SubmissionStatus) => {
    let submissions = this.db
      .collection(collections.documentSubmissions)
      .where("account.id", "==", accountId)

    if (status) submissions = submissions.where("status", "==", status)

    const accountSubmissions = await submissions
      .withConverter(firestoreConverter<DocumentSubmission>())
      .get()

    return accountSubmissions
  }

  accountDataSubmissionsQuery = (
    accountId: AccountId,
    status?: SubmissionStatus,
    documentType?: DataDocumentType
  ) => {
    let submissions = this.db
      .collection(collections.documentSubmissions)
      .where("account.id", "==", accountId)

    if (status) submissions = submissions.where("status", "==", status)
    if (documentType) submissions = submissions.where("document.documentType", "==", documentType)

    return submissions.withConverter(firestoreConverter<DocumentSubmission>())
  }

  adminDataSubmissionsQuery = (status?: SubmissionStatus, documentType?: DataDocumentType) => {
    let submissions: firebaseApp.firestore.Query<firebaseApp.firestore.DocumentData> =
      this.db.collection(collections.documentSubmissions)

    if (status) submissions = submissions.where("status", "==", status)
    if (documentType) submissions = submissions.where("document.documentType", "==", documentType)

    return submissions.withConverter(firestoreConverter<DocumentSubmission>())
  }

  adminUpdateDocumentSubmission = async (submission: Partial<DocumentSubmission>, id: string) => {
    await this.db
      .collection(collections.documentSubmissions)
      .doc(id)
      .withConverter(firestoreConverter<DocumentSubmission>())
      .update(submission)
  }

  setCompany409aValuation = (firebaseId: string, valuations: Valuation409a[]) =>
    this.db.collection(collections.companies).doc(firebaseId).update({
      valuations409a: valuations,
    })

  auction = (auctionId: string) => this.db.collection(collections.auctions).doc(auctionId)

  allLiveAuctions = () =>
    this.db
      .collection(collections.auctions)
      .where("status", "in", ["announced", "live", "extended"])
      .withConverter(auctionConverter)

  auctionAccountBids = (auctionId: string, accountId: AccountId) =>
    this.db
      .collection(collections.auctions)
      .doc(auctionId)
      .collection(collections.auctionSubcollections.bids)
      .where("accountId", "==", accountId)
      .withConverter(auctionBidConverter)

  auctionParticipantContract = (auctionId: string, accountId: AccountId) =>
    this.db
      .collection(collections.documents)
      .where("auctionId", "==", auctionId)
      .where("accountId", "==", accountId)
      .withConverter(documentConverter)

  auctionAccountParticipants = (auctionId: string, accountId: AccountId) =>
    this.db
      .collection(collections.auctions)
      .doc(auctionId)
      .collection(collections.auctionSubcollections.participants)
      .where("accountId", "==", accountId)
      .withConverter(auctionParticipantConverter)

  userAuctionParticipationByEmailQuery = (
    auctionId: string,
    accountId: AccountId,
    userEmail: string
  ) =>
    this.db
      .collection(collections.auctions)
      .doc(auctionId)
      .collection(collections.auctionSubcollections.participants)
      .where("accountId", "==", accountId)
      .where("user.email", "==", userEmail)
      .withConverter(auctionParticipantConverter)

  latestUserVisibleAuction = () =>
    this.db
      .collection(collections.auctions)
      .where("status", "in", ["scheduled", "announced", "live", "completed", "extended"])
      .orderBy("auctionEndDate", "desc")
      .limit(1)
      .withConverter(auctionParticipantConverter)

  getUserAuctionParticipationByEmail = (
    auctionId: string,
    accountId: AccountId,
    userEmail: string
  ) =>
    this.userAuctionParticipationByEmailQuery(auctionId, accountId, userEmail)
      .get()
      .then((result) => result.docs[0])

  captureUserHistory = async (history: Partial<User> & { updatedAt?: Date }) => {
    if (history.id === undefined) return
    await this.db.collection(collections.userHistory).add(history)
  }

  /** @deprecated  use account watchlists */
  removeCompanyFromWatchList = async (userId: string, watchlistCompany: WatchlistCompany) => {
    const editedWatchlistCompany = deepCopy(watchlistCompany)
    editedWatchlistCompany.previousStatus = watchlistCompany.status
    editedWatchlistCompany.status = "archived"
    const history: Partial<User> & { updatedAt: Date } = {
      id: userId,
      watchlist: {
        companies: [editedWatchlistCompany],
      },
      updatedAt: new Date(),
    }
    this.captureUserHistory(history)
    // delete from the user array:
    await this.user(userId).update({
      "watchlist.companies": FieldValue.arrayRemove(watchlistCompany),
    })
  }

  /** @deprecated  use account watchlists */
  removeIndustryFromWatchList = async (userId: string, watchlistIndustry: WatchlistIndustry) => {
    const history: Partial<User> & { updatedAt: Date } = {
      id: userId,
      watchlist: {
        industries: [watchlistIndustry],
      },
      updatedAt: new Date(),
    }
    this.captureUserHistory(history)
    // delete from the user array:
    await this.user(userId).update({
      "watchlist.industries": FieldValue.arrayRemove(watchlistIndustry),
    })
  }

  /** @deprecated  use account watchlists */
  addCompanyToWatchList = async (userId: string, watchlistCompany: WatchlistCompany) => {
    await this.user(userId).update({
      "watchlist.companies": FieldValue.arrayUnion(watchlistCompany),
      updatedAt: new Date(),
    })
  }

  /** @deprecated  use account watchlists */
  addIndustryToWatchList = async (userId: string, industry: WatchlistIndustry) => {
    await this.user(userId).update({
      "watchlist.industries": FieldValue.arrayUnion(industry),
      updatedAt: new Date(),
    })
  }

  /** @deprecated  use account watchlists */
  updateWatchlistOpportunityInterests = async (
    userId: string,
    originalWatchlistDoc: WatchlistCompany,
    opportunityInterests: WatchlistOpportunityInterest[]
  ) => {
    const newWatchlistDoc = deepCopy(originalWatchlistDoc)
    newWatchlistDoc.opportunityInterests = opportunityInterests
    Promise.all([
      this.removeCompanyFromWatchList(userId, originalWatchlistDoc),
      this.addCompanyToWatchList(userId, newWatchlistDoc),
    ])
  }

  /** @deprecated  use account watchlists */
  updateWatchlistIndustryOpportunityInterests = async (
    userId: string,
    originalWatchlistDoc: WatchlistIndustry,
    opportunityInterests: WatchlistOpportunityInterest[]
  ) => {
    const newWatchlistDoc = deepCopy(originalWatchlistDoc)
    newWatchlistDoc.opportunityInterests = opportunityInterests
    Promise.all([
      this.removeIndustryFromWatchList(userId, originalWatchlistDoc),
      this.addIndustryToWatchList(userId, newWatchlistDoc),
    ])
  }

  getAccountWatchlist = (accountId: AccountId) =>
    this.db
      .collection(collections.watchlists)
      .where("account.id", "==", accountId)
      .withConverter(firestoreConverter<WatchlistItem>())

  getNotifications = (userId: string) =>
    this.db
      .collection(collections.userNotifications)
      .where("user.id", "==", userId)
      .withConverter(firestoreConverter<GenericNotification>())

  getNotificationsAfterDate = (userId: string, startDate: Date) =>
    this.db
      .collection(collections.userNotifications)
      .where("user.id", "==", userId)
      .where("createdAt", ">=", startDate)
      .withConverter(firestoreConverter<GenericNotification>())

  updateWatchlistItemPreferences = (
    itemId: string,
    updates: WatchlistItem["notificationOverrides"]
  ) => {
    const docUpdate: Partial<WatchlistItem> = {
      notificationOverrides: updates,
    }
    return this.db.collection(collections.watchlists).doc(itemId).update(docUpdate)
  }

  updateAllWatchlistItemPreferences = (updates: WatchlistItem["notificationOverrides"]) => {
    const batch = this.db.batch()
    const watchlistsRef = this.db.collection(collections.watchlists)

    return watchlistsRef.get().then((snapshot) => {
      snapshot.forEach((doc) => {
        const docUpdate: Partial<WatchlistItem> = {
          notificationOverrides: updates,
        }
        batch.update(doc.ref, docUpdate)
      })

      return batch.commit()
    })
  }

  removeWatchlistItem = (itemId: string) =>
    this.db.collection(collections.watchlists).doc(itemId).delete()

  getAllSectors = () =>
    this.db.collection(collections.industries).withConverter(industryConverter).get()

  allCompanies = () => this.db.collection(collections.companies).withConverter(companyConverter)

  getCompaniesWithActiveMarket = () =>
    this.db
      .collection(collections.companies)
      .where("isTargetIssuer", "==", true)
      .withConverter(companyConverter)

  getCaplightDataCompanies = () =>
    this.db
      .collection(collections.companies)
      .where("aggregations.totalOrders", ">", 0)
      .withConverter(companyConverter)

  getMarketPriceEnabledCompanies = () =>
    this.db
      .collection(collections.companies)
      .where("settings.showCaplightPriceEstimate", "==", true)
      .where("priceEstimatesSummary.currentPrice.priceEstimatePPS", ">", 0)
      .withConverter(companyConverter)

  getCompanyByPostgresId = (postgresCompanyId: string) =>
    this.db
      .collection(collections.companies)
      .where("postgresCompanyId", "==", postgresCompanyId)
      .withConverter(companyConverter)
      .get()
      .then((result) => result.docs.map((doc) => doc.data()))

  getCompanyById = (id: string) =>
    this.db
      .collection(collections.companies)
      .withConverter(companyConverter)
      .doc(id)
      .get()
      .then((result) => result.data())

  getCompaniesByIds = (ids: string[]) =>
    inQuery(
      (c) =>
        this.db
          .collection(collections.companies)
          .withConverter(companyConverter)
          .where(firebaseApp.firestore.FieldPath.documentId(), "in", c)
          .get()
          .then(getAllDocs),
      ids
    )

  getCompaniesByPostgresIds = (postgresIds: string[]) =>
    inQuery(
      (c) =>
        this.db
          .collection(collections.companies)
          .withConverter(companyConverter)
          .where("postgresCompanyId", "in", c)
          .get()
          .then(getAllDocs),
      postgresIds
    )

  getCompany = (id: string) =>
    this.db.collection(collections.companies).withConverter(companyConverter).doc(id).get()

  getSectorCompaniesThatWentPublicAfter = (date: Date, sectorName: string) =>
    this.db
      .collection(collections.companies)
      .withConverter(companyConverter)
      .where("statusDetails.latestPublicOffering.date", ">=", date)
      .where("industry.caplightPrimaryIndustry", "==", sectorName)
      .get()
      .then((result) => result.docs.map((doc) => doc.data()))

  getCompanyByField = (field: "pbid" | "name" | "airtableId", fieldValue: string) =>
    this.db
      .collection(collections.companies)
      .where(field, "==", fieldValue)
      .withConverter(companyConverter)
      .get()
      .then((result) => result.docs.map((doc) => doc.data()))

  getCompanyByAirtableId = (airtableId: string) =>
    this.db
      .collection(collections.companies)
      .where("airtableId", "==", airtableId)
      .withConverter(companyConverter)
      .get()
      .then((result) => result.docs.map((doc) => doc.data()))

  createCompany = async (name: string) => {
    handleConsoleError(`A company ${name} was created with no additional information!`)
    const res = await this.db.collection(collections.companies).add({ name: titleCase(name) })
    return res
  }

  updateCaplightPriceEstimateVisibility = (id: string, visible: boolean) =>
    this.db
      .collection(collections.companies)
      .doc(id)
      .update({ "settings.showCaplightPriceEstimate": visible })

  updateCaplightPriceEstimateUse = (id: string, use: boolean) =>
    this.db
      .collection(collections.companies)
      .doc(id)
      .update({ "settings.useCaplightPriceEstimateAsCurrentMarket": use })

  getCompaniesWithPriceEstimateMeta = () =>
    this.db
      .collection(collections.companies)
      .orderBy("priceEstimatesSummary.updatedAt")
      .withConverter(companyConverter)

  submitCompanyPageFeedback = (doc: Omit<ClientFeedback, "id">) =>
    this.db.collection(collections.companyPageFeedback).add(doc)

  accountFeedbackSubmissions = (accountId: string) =>
    this.db
      .collection(collections.companyPageFeedback)
      .where("account.id", "==", accountId)
      .withConverter(firestoreConverter<ClientFeedback>())

  getAuctionPrivateData = (auctionId: string) =>
    this.db
      .collection(collections.auctions)
      .doc(auctionId)
      .collection(collections.auctionSubcollections.privateData)
      .withConverter(privateAuctionDataConverter)

  recordUserProductInteraction = async (
    user: User,
    interactionUpdate: Partial<UserProductInteractionHistory>
  ) => {
    const updates: Partial<User> = {
      productInteractionHistory: {
        ...user.productInteractionHistory,
        ...interactionUpdate,
      },
    }

    await this.user(user.id).update(updates)
  }

  setCartaShareholderToken = (userId: string, token: string) =>
    this.db
      .collection(collections.users)
      .doc(userId)
      .update({ cartaShareholderToken: token } satisfies Partial<User>)

  // Holdings
  createHolding = (holding: Partial<Holding>) =>
    this.db.collection(collections.holdings).add(holding)

  updateHolding = (holdingId: string, update: Partial<Holding>) =>
    this.db.collection(collections.holdings).doc(holdingId).update(update)

  deleteHolding = (holdingId: string) =>
    this.db.collection(collections.holdings).doc(holdingId).delete()

  allAccountHoldings = (accountId: string) =>
    this.db
      .collection(collections.holdings)
      .withConverter(holdingConverter)
      .where("account.id", "==", accountId)

  holding = (holdingId: string) =>
    this.db.collection(collections.holdings).doc(holdingId).withConverter(holdingConverter)

  allHoldingDocuments = (holdingId: string) =>
    this.db
      .collection(collections.holdings)
      .doc(holdingId)
      .collection(collections.holdingsSubcollections.documents)
      .withConverter(holdingDocumentConverter)

  holdingDocument = (holdingId: string, documentId: string) =>
    this.db
      .collection(collections.holdings)
      .doc(holdingId)
      .collection(collections.holdingsSubcollections.documents)
      .doc(documentId)
      .withConverter(holdingDocumentConverter)

  holdingDocumentByTypeAndName = (holdingId: string, docType: string, fileName: string) =>
    this.db
      .collection(collections.holdings)
      .doc(holdingId)
      .collection(collections.holdingsSubcollections.documents)
      .where("type", "==", docType)
      .where("fileName", "==", fileName)
      .withConverter(holdingDocumentConverter)
      .get()
      .then(oneOrNone)

  holdingDocumentsByType = (holdingId: string, type: HoldingDocumentType) =>
    this.db
      .collection(collections.holdings)
      .doc(holdingId)
      .collection(collections.holdingsSubcollections.documents)
      .withConverter(holdingDocumentConverter)
      .where("type", "==", type)

  submitShareholderOrderForm = (formValues: Omit<ShareholderFormSubmission, "id">) =>
    this.db.collection(collections.shareholderFormSubmissions).add(formValues)

  getSubmittedShareholderOrderForms = (accountId: string) =>
    this.db
      .collection(collections.shareholderFormSubmissions)
      .where("account.id", "==", accountId)
      .withConverter(firestoreConverter<ShareholderFormSubmission>())

  // Admin queries

  adminGetUserEventCountsForAccount = (params: {
    accountId: string
    period: UserEventCounter["period"]
    start: Date
    end: Date
  }) =>
    this.db
      .collection(collections.userEventCounts)
      .withConverter(firestoreConverter<UserEventCounter>())
      .where("period", "==", params.period)
      .where("account.id", "==", params.accountId)
      .where("periodDate", ">=", params.start)
      .where("periodDate", "<=", params.end)

  adminGetAccountEventCountRollupsBetweenDates = (
    period: AccountEventCountsRollup["period"],
    start: Date,
    end: Date
  ) =>
    this.db
      .collection(collections.accountEventCountRollups)
      .withConverter(firestoreConverter<AccountEventCountsRollup>())
      .where("period", "==", period)
      .where("periodDate", ">=", start)
      .where("periodDate", "<=", end)

  adminCreateAccount = (params: NewAccount) =>
    this.db
      .collection(collections.accounts)
      .add(params)
      .then((doc) => doc.withConverter(accountConverter).get())

  adminGetUserByAirtableId = (airtableId: string) =>
    this.db
      .collection(collections.users)
      .where("airtableId", "==", airtableId)
      .withConverter(userConverter)
      .get()
      .then(oneOrNone)

  adminGetLimitedUsersByAccount = (accountId: string) =>
    this.db
      .collection(collections.users)
      .where("account.id", "==", accountId)
      .where("limitPlatformAccess", "==", true)
      .withConverter(userConverter)

  adminGetUsersByAccount = (accountId: string) =>
    this.db
      .collection(collections.users)
      .where("account.id", "==", accountId)
      .withConverter(userConverter)

  adminRemoveLimitPlatformAccess = (userId: string) =>
    this.db.collection(collections.users).doc(userId).update({ limitPlatformAccess: false })

  /** @deprecated use adminGetAllAccounts unless you have some unusual use-case */
  adminGetAllAccountsRaw = () =>
    this.db.collection(collections.accounts).withConverter(accountConverter)

  adminGetAllAccounts = () =>
    this.db
      .collection(collections.adminAllAccountsCache)
      .withConverter(firestoreConverter<AdminAllAccountsCache>())
      .get()
      .then(getAllDocs)
      .then((docs) => {
        if (docs.length === 0) {
          throw new Error("Admin all accounts cache has not been initialized.")
        } else {
          return docs.flatMap((doc) => doc.accounts)
        }
      })

  adminGetAllDataAccounts = () =>
    this.db
      .collection(collections.accounts)
      .where("productAreas", "array-contains", "data")
      .withConverter(accountConverter)

  adminGetAllAuctions = () =>
    this.db.collection(collections.auctions).withConverter(auctionConverter)

  adminGetAllRFQs = () => this.db.collection(collections.rfqs).withConverter(rfqConverter)

  adminGetAllCampaignBatches = (type: CampaignType["name"]) =>
    this.db
      .collection(collections.campaignBatches)
      .withConverter(firestoreConverter<CampaignBatch>())
      .where("type", "==", type)

  adminCreateCampaignBatch = async (
    startDate: Date,
    endDate: Date,
    name: string,
    type: CampaignType["name"]
  ): Promise<CampaignBatch> => {
    const newBatchDocRef = this.db.collection(collections.campaignBatches).doc()

    const newBatch: CampaignBatch = {
      id: newBatchDocRef.id,
      startDate,
      endDate,
      name,
      type,
    }

    await newBatchDocRef.set(newBatch)

    return newBatch
  }

  // Allows users to create their own participant documents when viewing RFQs from the dashboard
  userCreateRFQCampaignParticipant = async (
    user: CurrentUser,
    campaign: CampaignRef
  ): Promise<CampaignParticipant<RFQEmailType> | undefined> => {
    const participant: Omit<CampaignParticipant<RFQEmailType>, "id"> = {
      account: {
        ...viewAccountIdFields(user.user.account),
        clientType: user.user.account.clientType,
      },
      user: viewUserIdFields(user.user),
      interactions: [],
      addedAt: new Date(),
      suggestionStatus: "added_via_client_rfq_dashboard",
      suggestionReasons: [],
      createdAt: new Date(),
      createdBy: viewUserIdFields(user.user),
    }

    const newParticipant = await this.db
      .collection(collections.rfqs)
      .doc(campaign.id)
      .collection(collections.rfqSubcollections.participants)
      .add(participant)
      .then((doc) =>
        doc.withConverter(firestoreConverter<CampaignParticipant<RFQEmailType>>()).get()
      )
      .then((response) => response.data())

    return newParticipant
  }

  adminGetAllPlatformPageInvites = () =>
    this.db.collection(collections.platformPageInvites).withConverter(platformPageInviteConverter)

  rfqResponsesRef = (rfqId: string) =>
    this.db
      .collection(collections.rfqs)
      .doc(rfqId)
      .collection(collections.rfqSubcollections.responses)
      .withConverter(rfqResponseConverter)

  adminGetRfqPartialResponses = (rfqId: string) =>
    this.db
      .collection(collections.rfqs)
      .doc(rfqId)
      .collection(collections.rfqSubcollections.partialResponses)
      .withConverter(partialRfqResponseConverter)

  rfq = (id: string) => this.db.collection(collections.rfqs).doc(id).withConverter(rfqConverter)

  updateRFQ = async (rfqId: string, update: Partial<RFQType>) => {
    const thisRfq = this.rfq(rfqId)

    if (thisRfq) await thisRfq.update(update)
  }

  updateRFQResponse = async (rfqId: string, userId: string, update: Partial<RFQResponse>) => {
    const query = this.rfqResponsesRef(rfqId)

    const userRFQResponse = await query
      .where("createdBy.id", "==", userId)
      .get()
      .then((result) => oneOrNone(result))

    if (userRFQResponse) {
      await query.doc(userRFQResponse.id).update(update)
    }
  }

  createRFQRequest = (request: Omit<RFQRequest, "id">) =>
    this.db.collection(collections.rfqRequests).add(request)

  recentRFQForCompany = async (companyId: string): Promise<Maybe<RFQType>> =>
    this.db
      .collection(collections.rfqs)
      .where("anchorOrder.companyId", "==", companyId)
      .withConverter(firestoreConverter<RFQType>())
      .get()
      .then(getAllDocs)
      .then((docs) => orderBy(docs, "batch.endDate", "desc"))
      .then((orderedDocs) => head(orderedDocs))

  platformPageInvite = (id: string) =>
    this.db
      .collection(collections.platformPageInvites)
      .doc(id)
      .withConverter(platformPageInviteConverter)

  adminGetAuctionBids = (auctionId: string) =>
    this.db
      .collection(collections.auctions)
      .doc(auctionId)
      .collection(collections.auctionSubcollections.bids)
      .withConverter(auctionBidConverter)

  adminGetAuctionParticipants = (auctionId: string) =>
    this.db
      .collection(collections.auctions)
      .doc(auctionId)
      .collection(collections.auctionSubcollections.participants)
      .withConverter(auctionParticipantConverter)

  adminGetAllPriceObservations = () =>
    this.db
      .collectionGroup(restrictedCollections.companySubcollections.priceObservations)
      .withConverter(firestoreConverter<PriceObservationType>())
      .get()
      .then((result) => result.docs.map((doc) => doc.data()))

  getAllPriceObservations = (pageSize: number) =>
    this.db
      .collectionGroup(restrictedCollections.companySubcollections.priceObservations)
      .withConverter(firestoreConverter<PriceObservationType>())
      .limit(pageSize)
      .orderBy("observationDate.lowerBound", "desc")

  adminGetAuctionContracts = (auctionId: string) =>
    this.db
      .collection(collections.documents)
      .where("auctionId", "==", auctionId)
      .withConverter(documentConverter)

  allDocumentTemplates = () =>
    this.db.collection(collections.documentTemplates).withConverter(documentTemplateConverter)

  adminAddDocumentTemplate = async (templateType: DocumentType, fileNamePrefix: string) => {
    const folder = FileUtils.folders.documentTemplate(templateType)
    return this.db
      .collection(collections.documentTemplates)
      .add({
        type: templateType,
        htmlFileName: `${fileNamePrefix}.html`,
        docxFileName: `${fileNamePrefix}.docx`,
        storageFolder: folder,
        createdAt: new Date(),
      })
      .then((doc) => doc.id)
  }

  getImageFileURL = (loc: string) =>
    this.imageStorage
      .ref()
      .child(loc)
      .getDownloadURL()
      .then((x) =>
        isString(x)
          ? x
          : Promise.reject(new Error(`Expected url to be a string, got ${JSON.stringify(x)})`))
      )

  getSharedOrdersFromSharableItem = (item: ShareableItem, sourceAccount: AccountIdFields) =>
    this.getSharedOrders(
      item.id,
      item.tag === "order"
        ? item.order.orderCollection === "darkpool"
          ? "darkpool_order"
          : item.order.orderCollection === "tentativeInterest"
          ? "tentative_interest"
          : "order"
        : item.tag === "crm_interest"
        ? "crm_interest"
        : item.tag === "deal_search"
        ? "deal_search"
        : assertUnreachable(item),
      sourceAccount
    )

  getSharedOrders = (
    id: string,
    documentType: SharedOrder["sharedDocumentType"],
    sourceAccount: AccountIdFields
  ) =>
    this.writerDb
      .collection(restrictedCollections.sharedOrders)
      .where("document.id", "==", id)
      .where("sharedDocumentType", "==", documentType)
      .where("source.account.id", "==", sourceAccount.id)
      .withConverter<SharedOrder>(firestoreConverter<SharedOrder>())

  getAllSharedOrdersForAccount = (sourceAccount: AccountIdFields) =>
    this.writerDb
      .collection(restrictedCollections.sharedOrders)
      .where("source.account.id", "==", sourceAccount.id)
      .withConverter<SharedOrder>(firestoreConverter<SharedOrder>())

  getMostRecentSharedOrders = (sourceAccount: AccountIdFields) =>
    this.writerDb
      .collection(restrictedCollections.sharedOrders)
      .where("source.account.id", "==", sourceAccount.id)
      .orderBy("createdAt", "desc")
      .withConverter<SharedOrder>(firestoreConverter<SharedOrder>())
      .limit(1)

  getSharedWithContactsByAccountId = (accountId: string) =>
    this.writerDb
      .collection(collections.sharedWithContacts)
      .where("sharedFromAccountIds", "array-contains", accountId)
      .withConverter<SharedWithContact>(firestoreConverter<SharedWithContact>())

  imageExists = (path: string) =>
    this.imageStorage
      .ref(path)
      .list()
      .then((x) => (x.items.length > 0 ? Promise.resolve() : Promise.reject()))

  documentExists = (path: string) =>
    this.imageStorage
      .ref(path)
      .list()
      .then((x) => (x.items.length > 0 ? Promise.resolve() : Promise.reject()))

  uploadFile = (
    file: File,
    folder: string,
    afterUpload: () => void,
    handleError?: (err: Error) => void
  ) => {
    const fullPath = FileUtils.fullFilePath(folder, file.name)
    const uploadTask = this.documentStorage.ref(fullPath).put(file)
    uploadTask.on(
      "state_changed",
      null,
      (error) => {
        handleError ? error : handleConsoleError(error)
      },
      () => {
        afterUpload()
      }
    )
  }

  uploadFilePromise = (file: File, folder: string) => {
    const fullPath = FileUtils.fullFilePath(folder, file.name)
    return this.documentStorage.ref(fullPath).put(file)
  }

  downloadFile = async (fullFilePath: string, downloadAsFilename?: string) => {
    const url = await this.documentStorage.ref(fullFilePath).getDownloadURL()
    const filename = this.documentStorage.ref(fullFilePath).name
    await fetch(url)
      .then((res) => res.blob())
      .then((blob) => saveAs(blob, downloadAsFilename || filename))
  }

  getDocumentStorageBucketName = () => this.documentStorage.ref().bucket

  private useEmulators = () => {
    try {
      const isCypressMode = process.env?.REACT_APP_CYPRESS === "true"
      this.db.useEmulator("localhost", isCypressMode ? 5102 : config.firebase.firestoreEmulatorPort)
      this.auth.useEmulator(`http://localhost:${isCypressMode ? 9198 : 9099}`)
      this.documentStorage.useEmulator("localhost", isCypressMode ? 9399 : 9199)
    } catch (err) {
      handleConsoleError(err)
    }
  }
}

export type QuerySnapshot<T> = firebaseApp.firestore.QuerySnapshot<T>
export type QueryDocumentSnapshot<T> = firebaseApp.firestore.QueryDocumentSnapshot<T>
export type DocumentSnapshot<T> = firebaseApp.firestore.DocumentSnapshot<T>
// export const Persistence = auth.Auth.Persistence

export default Firebase
