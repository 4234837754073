// Auction participant is an invited or participating user in an auction
import { AccountId } from "common/model/Account"
import { AuctionStatusType } from "common/model/Auction"
import { User, UserIdFields } from "common/model/User"
import { firestoreConverter } from "../FirestoreConverter"
import { newDocFirestoreConverter } from "../NewDocFirestoreConverter"

export type AuctionParticipantStatus =
  | "invited_by_user"
  | "invitation_pending"
  | "invited"
  | "viewed"
  | "participation_contract_signed"
  | "bid_placed"
  | "declined"

type AuctionEmailType =
  | "auction_invitation"
  | "auction_live_announcement"
  | "auction_extended_announcement"
  | "auction_ending_soon_announcement"
  | "auction_complete_announcement"
  | "auction_outbid_notification"
  | "auction_participation_agreement_signed"

export interface NewAuctionParticipant {
  accountId: AccountId
  account: { id: string; name: string; airtableId: string }
  userId?: string
  user: Omit<UserIdFields, "id" | "airtableId"> & { id?: string; airtableId?: string } & Pick<
      User,
      "creationSource"
    >
  status: AuctionParticipantStatus
  approved?: boolean
  firstViewedAt?: Date
  lastViewedAt?: Date
  auctionStatusWhenLastViewed?: AuctionStatusType
  addedAt: Date
  addedById: string
  addedBy: {
    id: string
    email: string
    firstName: string
    lastName: string
  }
}

export interface AuctionParticipant extends NewAuctionParticipant {
  id: string
  optOutReasons: { reasons: string[]; other?: string | null } | null
  emailsSent?: Array<{
    emailType: AuctionEmailType
    sentAt: Date
  }>
}

export const auctionParticipantConverter = firestoreConverter<AuctionParticipant>()
export const newAuctionParticipantConverter = newDocFirestoreConverter<NewAuctionParticipant>()
