import { CompanyLogoAndName } from "@components/CompanyLogoAndName/CompanyLogoAndName"
import MarketPill from "@stories/components/Badges/MarketPill/MarketPill"
import Typography, { Color, Size } from "@stories/components/Typography/Typography"
import {
  Matchable,
  crmInterestToMatchable,
  dealSearchToMatchable,
  orderToMatchable,
} from "common/model/Order/Matching"
import {
  getSharedItemLastUpdatedAt,
  ShareableItem,
} from "common/model/SharedOrder/SharedOrderResponse"
import { assertUnreachable } from "common/utils/fp/Function"
import { Order } from "common/model/Order/Models/Wrapped"
import { Nothing } from "common/containers/Maybe"
import { formatPrice, formatValuation } from "common/utils/math"
import { DataDisplayConstants } from "common/UI/DataDisplayConstants"
import { IntervalDisplay } from "@components/displays/Interval"
import { capitalize } from "lodash"
import { relativeDateFormat } from "common/utils/dateUtils"

const Term = ({ title, value }: { title: string; value: string }) => (
  <div className="flex flex-col space-y-1 items-start">
    <Typography text={title} size={Size.Small} color={Color.Neutral800} />
    <Typography text={value} color={Color.Neutral1000} />
  </div>
)

export const ShareableItemCard = ({ shareableItem }: { shareableItem: ShareableItem }) => {
  const matchable: Matchable | null =
    shareableItem.tag === "order"
      ? Order.wrapRaw({ order: shareableItem.order, company: Nothing }).match(
          orderToMatchable,
          () => null
        )
      : shareableItem.tag === "deal_search"
      ? dealSearchToMatchable(shareableItem.dealSearch)
      : shareableItem.tag === "crm_interest"
      ? crmInterestToMatchable(shareableItem.crmInterest)
      : assertUnreachable(shareableItem)

  const lastUpdatedAt = getSharedItemLastUpdatedAt(shareableItem)

  const hasDataToDisplay = matchable && [matchable.price, matchable.structure.length].some(Boolean)

  if (!matchable) return null
  return (
    <div className="flex flex-col bg-white rounded-lg divide-y items-stretch border">
      <div className="flex flex-col p-2 space-y-2">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <CompanyLogoAndName company={matchable.company} size="xs" />
            <MarketPill variant={matchable.direction === "buy" ? "bid" : "offer"} size="sm" />
            <Typography
              size={Size.Small}
              text={matchable.size ? IntervalDisplay(formatValuation, true)(matchable.size) : ""}
              color={Color.Neutral800}
            />
          </div>
          <Typography
            size={Size.XSmall}
            text={`Updated ${relativeDateFormat(lastUpdatedAt)}`}
            color={Color.Neutral800}
          />
        </div>
        {hasDataToDisplay ? (
          <div className="grid grid-cols-4">
            <Term
              title="Price"
              value={matchable.price ? formatPrice(matchable.price) : DataDisplayConstants.noPrice}
            />
            <Term
              title="Structure"
              value={
                matchable.structure
                  ? matchable.structure.map(capitalize).join(", ")
                  : DataDisplayConstants.noStructure
              }
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}
