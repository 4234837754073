import { CurrentUser } from "@model/CurrentUser"
import { Account, viewAccountDataFields } from "common/model/Account"
import { PriceObservationType } from "common/model/data-product/pricing/PriceObservation"
import { useState, useCallback, useEffect } from "react"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { identity } from "common/utils/fp/Function"
import { UserFormDataSource } from "common/model/data-product/DataSource"
import { matchLoading } from "common/utils/Loading"
import { useCurrentAccount } from "src/queries/currentUser/useCurrentAccount"
import { viewUserIdFields } from "common/model/User"

const initialPriceObservationState = (
  user: CurrentUser,
  account: Account | undefined,
  initialPriceObservation: Partial<PriceObservationType>
): Partial<PriceObservationType> => ({
  source: {
    sourceType: "user-form",
    submittingUser: viewUserIdFields(user.user),
    sourceId: null,
    documentUpload: null,
  } satisfies UserFormDataSource,
  observedBy: account ? viewAccountDataFields(account) : undefined,
  createdDate: new Date(),
  ...initialPriceObservation,
})

export const useClosedTradeFormState = (
  initialPriceObservation: Partial<PriceObservationType>,
  deps: unknown[]
) => {
  const user = useLoggedInUser()
  const account = matchLoading(useCurrentAccount(), identity, undefined, undefined)
  const [priceObservation, setPriceObservation] = useState<Partial<PriceObservationType>>(
    initialPriceObservationState(user, account, initialPriceObservation)
  )
  const handleChange = useCallback(
    (value: Partial<PriceObservationType>) => {
      setPriceObservation({ ...priceObservation, ...value })
    },
    [priceObservation]
  )

  // reset order form on dependencies change
  useEffect(() => {
    setPriceObservation(initialPriceObservationState(user, account, initialPriceObservation))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, account])

  return { priceObservation, handleChange }
}
