import { Button } from "@stories/components/Button/Button"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { XIcon } from "@stories/icons/XIcon"
import React from "react"
import { classNames } from "src/utils/classNames"

const PopoverWrapper = ({
  handleClose,
  title,
  children,
  innerRef,
  fullWidth,
}: {
  innerRef: React.RefObject<HTMLDivElement>
  handleClose: () => void
  title: string
  children: React.ReactNode
  fullWidth?: boolean
}) => (
  <div
    className={classNames("flex flex-col gap-y-2", fullWidth ? "w-full" : "w-100")}
    ref={innerRef}
  >
    <div className="flex justify-between items-center">
      <Typography text={title} size={Size.XSmall} color={Color.Black} weight={Weight.Semibold} />
      <Button variant="icon" leftIcon={<XIcon />} size="small" onClick={handleClose} />
    </div>
    {children}
  </div>
)

export default PopoverWrapper
