import Typography, { Color, Font, Size, Weight } from "@stories/components/Typography/Typography"

interface PageTitleProps {
  title: React.ReactNode
}

export const PageTitle = ({ title }: PageTitleProps): JSX.Element => (
  <Typography
    color={Color.Primary}
    font={Font.Cambon}
    size={Size.Large}
    text={title}
    weight={Weight.Semibold}
  />
)
