import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { EmailEditor } from "./EmailEditor"
import { Button } from "@stories/components/Button/Button"
import { PreviewEmailButton } from "./PreviewEmailButton"
import { useSharedOrderEditor } from "./SharedOrderEditorProvider"

export const SharedOrderGroupEmailEditor = () => {
  const { emailContent, emails, setEditorMode } = useSharedOrderEditor()

  return (
    <div className="flex flex-col gap-2">
      <Typography
        weight={Weight.Semibold}
        color={Color.Primary}
        size={Size.Large}
        text="Finalize Email"
      />
      <Typography text="Send the details of your order to clients or partners. All selected contacts will receive this email." />
      <EmailEditor
        introduction="Hi {{firstName}},"
        emailSubject={emailContent.emailSubject}
        onEmailSubjectChange={emailContent.setEmailSubject}
        initialEmailBody={emailContent.emailBody}
        onEmailBodyChange={emailContent.setEmailBody}
      />
      <div className="flex items-center justify-between">
        <Button
          label="Customize Individual Emails"
          variant="hollow-link"
          onClick={() => setEditorMode("individually_customized")}
          isDisabled={!emails.length}
        />
        <PreviewEmailButton />
      </div>
    </div>
  )
}
