import { createContext, useContext, useEffect, useMemo, useState } from "react"
import { useDocumentWithLoadingId } from "../../firebase/Firestore"
import { Account } from "common/model/Account"
import { collections } from "common/firestore/Collections"
import { FirestoreError } from "firebase/firestore"
import { Loading, bindLoading, isLoading } from "common/utils/Loading"
import { useCurrentUser } from "../currentUser/useCurrentUser"

type AccountSnapshotValue = {
  accountSnapshot: Loading<Account>
  err: FirestoreError | null
  /* The first loaded accountSnapshot */
  currentAccount: Loading<Account>
}
export const AccountSnapshotContext = createContext<AccountSnapshotValue>({
  accountSnapshot: null,
  err: null,
  currentAccount: null,
})

export const AccountSnapshotProvider = ({ children }: { children: React.ReactNode }) => {
  const currentUser = useCurrentUser()

  const [accountSnapshot, err] = useDocumentWithLoadingId<Account>(
    collections.accounts,
    bindLoading(({ user }) => user.account, currentUser)
  )

  const [currentAccount, setCurrentAccount] = useState<Loading<Account>>("loading")

  useEffect(() => {
    setCurrentAccount((prev) =>
      isLoading(prev) && !isLoading(accountSnapshot) ? accountSnapshot : prev
    )
  }, [accountSnapshot])

  const value = useMemo(
    () => ({ accountSnapshot, err, currentAccount }),
    [accountSnapshot, err, currentAccount]
  )

  return <AccountSnapshotContext.Provider value={value}>{children}</AccountSnapshotContext.Provider>
}

export const useAccountSnapshot = () => useContext(AccountSnapshotContext)
