import {
  formatSharePriceWithPriceType,
  formatValuation,
} from "../../../../components/displays/numeric/Currency"
import { orderDataAccessLevel } from "../../shared/accessLevels"
import { OrderPageOrder } from "../types"
import OrderPreviewDetail from "./OrderPreviewDetail"

const OrderPreviewPrice = ({ order }: { order: OrderPageOrder }) =>
  order.rawPrice().match(
    (val) => (
      <OrderPreviewDetail
        label="Valuation"
        accessLevel={orderDataAccessLevel.price(order.isConnectable())}
        value={formatValuation(val.valuation)}
        subValue={order
          .impliedPricePerShare()

          .map((pps) => formatSharePriceWithPriceType(pps, order.toInternal()))
          .map((pps) => `${pps} per share (est.)`)}
      />
    ),
    (pps) => (
      <OrderPreviewDetail
        label="Price Per Share"
        accessLevel={orderDataAccessLevel.price(order.isConnectable())}
        value={formatSharePriceWithPriceType(pps.pricePerShare, order.toInternal())}
        subValue={order
          .impliedValuation()

          .map(formatValuation)
          .map((val) => `(${val} est. val.)`)}
      />
    ),
    () => (
      <OrderPreviewDetail
        label="Price Per Share"
        accessLevel={orderDataAccessLevel.price(order.isConnectable())}
        value="Unpriced"
      />
    )
  )

export default OrderPreviewPrice
