import Typography from "@stories/components/Typography/Typography"
import { Loading, deprecatedIsLoaded, mapLoading } from "common/utils/Loading"
import { useMemo } from "react"
import { ImportJobSummaryData } from "./ImportJobSummaryData"

export const ImportJobSummaryText = ({ summary }: { summary: Loading<ImportJobSummaryData> }) => {
  const importCountsWithUpdates = useMemo(
    () =>
      mapLoading<
        ImportJobSummaryData,
        ImportJobSummaryData & { updatedFirms: number; updatedIndividuals: number }
      >((v) => ({
        ...v,
        updatedFirms: v.firmsCount - v.newFirmsImportedCount,
        updatedIndividuals: v.individualsCount - v.newIndividualsImportedCount,
      }))(summary),
    [summary]
  )
  return (
    <>
      {deprecatedIsLoaded(importCountsWithUpdates) ? (
        <Typography
          text={[
            ...(importCountsWithUpdates.updatedFirms
              ? [`Updating ${importCountsWithUpdates.updatedFirms} Firms`]
              : []),
            ...(importCountsWithUpdates.updatedIndividuals
              ? [`Updating ${importCountsWithUpdates.updatedIndividuals} Individuals`]
              : []),
            ...(importCountsWithUpdates.newFirmsImportedCount
              ? [`Creating ${importCountsWithUpdates.newFirmsImportedCount} Firms`]
              : []),
            ...(importCountsWithUpdates.newIndividualsImportedCount
              ? [`Creating ${importCountsWithUpdates.newIndividualsImportedCount} Individuals`]
              : []),
          ].join(" - ")}
        />
      ) : importCountsWithUpdates === "loading" ? (
        "Fetching existing firms..."
      ) : (
        <div />
      )}
    </>
  )
}
