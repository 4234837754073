import { LIVE_UNTIL_DEFAULT_DAYS } from "common/model/Order/OrderConstants"
import { useAuthContext } from "src/Routes/auth/UserAuthContext"
import { Button } from "@stories/components/Button/Button"
import { useOrderForm } from "./OrderFormContext"
import { StepOrderMap, useOrderFormStatus } from "./contextHooks/useFormStatus"
import { DarkpoolLimitText } from "../MyOrders/DarkpoolLimitText"
import Typography, { Color, Size } from "@stories/components/Typography/Typography"
import Alert from "../../../stories/components/Alert/Alert"
import { classNames } from "../../../utils/classNames"
import { useAccountBrokerLinking } from "src/utils/useAccountBrokerLinking"
import { useDarkpoolState } from "./contextHooks/useDarkpoolState"
import { Checkbox } from "@stories/components/Antd"
import { isCompanyPublic } from "common/model/Company"
import { matchLoading } from "common/utils/Loading"

export const OrderFormProgressButtons = () => {
  const ctx = useAuthContext()
  const { isAccountBrokerLinkingEnabled } = useAccountBrokerLinking()
  const {
    validateAndSave,
    formErrors,
    wrappedOrder,
    isEditMode,
    formOrder,
    setFormOrder,
    company,
  } = useOrderForm()
  const { isDarkpoolSubmissionBlocked } = useDarkpoolState()
  const { stepProgress, setStepProgress, selectFormStatusByStep } = useOrderFormStatus()
  const showEditRenewAlert =
    isEditMode && !wrappedOrder?.isTimeSensitive().withDefault(false) && !wrappedOrder?.isDarkpool()
  const showUpdateCRMInterestAlert = !!formOrder.crmInterestId
  const showAlert = showEditRenewAlert || showUpdateCRMInterestAlert

  const isContinueDisabled =
    stepProgress >= 4 ||
    selectFormStatusByStep(stepProgress) === "wait" ||
    selectFormStatusByStep(stepProgress) === "error" ||
    isDarkpoolSubmissionBlocked ||
    matchLoading(company, isCompanyPublic, false, false)

  return (
    <div
      className={classNames("grid grid-cols-2 gap-2", showAlert ? "items-end" : "items-baseline")}
    >
      <Button
        label="Back"
        size="large"
        onClick={() => setStepProgress(Math.max(0, stepProgress - 1))}
        isDisabled={stepProgress <= 0}
        variant="secondary"
      />
      <div>
        {stepProgress === StepOrderMap(ctx, isAccountBrokerLinkingEnabled).reviewStatus ? (
          <div className="flex flex-col space-y-1">
            {showEditRenewAlert ? (
              <Alert
                variant="accent"
                message={
                  <Typography
                    text={`Note: Updating this order will also renew it for ${LIVE_UNTIL_DEFAULT_DAYS} days`}
                    size={Size.Small}
                  />
                }
              />
            ) : null}
            {showUpdateCRMInterestAlert ? (
              <Alert
                variant="default"
                message={
                  <Checkbox
                    checked={formOrder.updateExistingCRMInterestTerms}
                    onChange={(e) =>
                      setFormOrder({
                        ...formOrder,
                        updateExistingCRMInterestTerms: e.target.checked,
                      })
                    }
                  >
                    Update my private CRM {formOrder.direction === "buy" ? "bid" : "offer"} with
                    these terms
                  </Checkbox>
                }
              />
            ) : null}
            <Button
              isFullWidth
              size="large"
              label={`${isEditMode ? "Update" : "Submit"} Order`}
              onClick={validateAndSave}
              isDisabled={!!formErrors.size || !wrappedOrder || isDarkpoolSubmissionBlocked}
            />
          </div>
        ) : (
          <div className="w-full">
            <Button
              isFullWidth
              size="large"
              label="Continue"
              onClick={() =>
                setStepProgress(
                  Math.min(
                    StepOrderMap(ctx, isAccountBrokerLinkingEnabled).reviewStatus + 1,
                    stepProgress + 1
                  )
                )
              }
              isDisabled={isContinueDisabled}
            />
          </div>
        )}
        {isDarkpoolSubmissionBlocked ? (
          <DarkpoolLimitText color={Color.Danger} size={Size.Small} />
        ) : null}
      </div>
    </div>
  )
}
