import _ from "lodash"
import { isObject, isPlainObject } from "./json/validate"
import { UnsafeRec } from "./RecordUtils"

// predominantly for firestore object updating
// null values will be written to firestore, undefined values will not
// replaces all undefined values in an object with null
export const undefinedsToNulls = <T extends object>(obj: T, maxDepth: number = 5) => {
  const copy: T = _.cloneDeep(obj) // TODO remove any
  UnsafeRec.entries(copy).forEach(([key, value]) => {
    if (value === undefined) {
      copy[key] = null as unknown as T[keyof T] // TODO fix
    } else if (isObject(value) && maxDepth > 1) {
      copy[key] = undefinedsToNulls(
        value as unknown as object,
        maxDepth - 1
      ) as unknown as T[keyof T] // TODO fix
    }
  })
  return copy
}

/**
 * Recursively replace objects that have exclusively undefined values (members) with `{}`, returning a new object
 * This is predominantly used for firestore record updating, since firestore will not store objects with only undefined values
 */
export const nestedUndefinedsToEmptyObjects = <T extends object>(
  obj: T,
  maxDepth: number = 10
): T => {
  const copy: T = _.cloneDeep(obj) // TODO remove any

  if (isPlainObject(copy) && UnsafeRec.entries(copy).every(([, value]) => value === undefined)) {
    return {} as unknown as T // TODO: This is certainly not true
  }

  UnsafeRec.entries(copy).forEach(([key, value]) => {
    if (isPlainObject(value) && maxDepth > 1) {
      if (UnsafeRec.entries(value).every(([, nestedObjValue]) => nestedObjValue === undefined)) {
        copy[key] = {} as unknown as T[keyof T]
      } else {
        copy[key] = nestedUndefinedsToEmptyObjects(
          value as unknown as object,
          maxDepth - 1
        ) as unknown as T[keyof T]
      }
    }
  })
  return copy
}

export const mapValuesAsync = async <T extends object, TResult>(
  obj: T,
  asyncFn: (val: T[keyof T], key: keyof T) => Promise<TResult>
): Promise<{ [P in keyof T]: TResult }> => {
  const copy: T = _.cloneDeep(obj)
  const entries = await Promise.all(
    UnsafeRec.entries(copy).map(
      async ([key, value]): Promise<[keyof T, TResult]> => [key, await asyncFn(value, key)]
    )
  )
  return Object.fromEntries(entries) as { [P in keyof T]: TResult }
}

export const objectDifferenceByProps = <T extends object>(
  objA: T,
  objB: T,
  areEqual: (a: T[keyof T], b: T[keyof T]) => boolean = (a, b) =>
    JSON.stringify(a) === JSON.stringify(b)
): Partial<T> =>
  UnsafeRec.fromEntries(
    UnsafeRec.entries(objA).filter(([key, value]) => !areEqual(objB[key], value))
  )

export const objectHasKey = <T extends string>(key: T, obj: unknown): obj is Record<T, unknown> =>
  typeof obj === "object" && obj !== null && key in obj
