import { formatValuation } from "common/utils/math/format"
import { Interval, showInterval } from "common/utils/data/Interval"
import OrderTermSheetSection from "../OrderTermSheetSection"
import { EMPTY_ROW_VALUE } from "./constants"
import { formatShareCount } from "../../../../../components/displays/numeric/Currency"
import { orderDataAccessLevel } from "../../../shared/accessLevels"
import { OrderPageSectionProps } from "./types"

export const SIZE_SECTION_TITLE = "Size"

const SizeSection = ({ order, hideUnspecified }: OrderPageSectionProps) => {
  const volumeValue = order
    .amountUSD()

    .map(Interval.map(formatValuation))
    .map(showInterval((x) => x, true))
    .withDefault(EMPTY_ROW_VALUE)
  const volumeRow = {
    label: "Volume",
    value: volumeValue,
    accessLevel: orderDataAccessLevel.size(order.isConnectable()),
  }

  const shareCountValue = order
    .shareCount()

    .map(Interval.map(formatShareCount))
    .map(showInterval((x) => x, true))
    .withDefault(EMPTY_ROW_VALUE)
  const shareCountRow = {
    label: "Share count",
    value: shareCountValue,
    accessLevel: orderDataAccessLevel.size(order.isConnectable()),
  }

  return (
    <OrderTermSheetSection
      title={SIZE_SECTION_TITLE}
      rows={[volumeRow, shareCountRow]}
      hideUnspecified={hideUnspecified}
    />
  )
}

export default SizeSection
