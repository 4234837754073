import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import {
  TrustedBrokerConnection,
  getAccountNetworkNodeFirmName,
  getPendingOutboundInvites,
} from "common/model/TrustedBrokerConnection"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { TrustedBrokerNetworkCard } from "./TrustedBrokerNetworkCard"
import { useMemo } from "react"

export const TrustedBrokerNetworkSentInvites = ({
  connections,
}: {
  connections: TrustedBrokerConnection[]
}) => {
  const { user } = useLoggedInUser()
  const invites = useMemo(
    () =>
      connections.flatMap((connection) => {
        if (connection.isDeleted || connection.status !== "pending") return []
        return getPendingOutboundInvites(user.account, [connection]).flatMap((node) => ({
          connection,
          node,
        }))
      }),
    [connections, user.account]
  )

  return (
    <div className="flex flex-col gap-2">
      <Typography text="Sent Invitations" weight={Weight.Semibold} />
      {invites.length === 0 && (
        <TrustedBrokerNetworkCard>
          <div className="flex items-center justify-center">
            <Typography text="No Invites" color={Color.Subtitle} size={Size.XSmall} />
          </div>
        </TrustedBrokerNetworkCard>
      )}
      {invites.map(({ connection, node }) => (
        <TrustedBrokerNetworkCard key={`${connection.id}`}>
          <div className="flex items-start justify-between">
            <div className="flex flex-col items-start">
              <Typography weight={Weight.Semibold} text={getAccountNetworkNodeFirmName(node)} />
              <div className="flex items-center gap-2">
                <Typography
                  text={`Sent By: ${connection.createdBy.firstName} ${connection.createdBy.lastName}`}
                  size={Size.XSmall}
                />
              </div>
            </div>
          </div>
        </TrustedBrokerNetworkCard>
      ))}
    </div>
  )
}
