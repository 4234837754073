/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, ReactElement, useState } from "react"
import { useSearchParams } from "react-router-dom"
import Typography, { Color, Size, Weight } from "../Typography/Typography"
import { useLocation } from "react-router-dom"
import React, { useMemo } from "react"
import { DETAIL_TAB } from "common/model/Order/MiscOrderUIStuffThatNeedsToBeCleanedUp"

export interface Tab {
  id: string
  key: number
  label: string
  tabIcon?: React.ReactNode
  children: ReactElement
}

export interface TabsProps {
  tabs: Tab[]
  initialTab: number
  onChange?: (key: number) => void
  stickyHeightClassName?: string
  shouldIncludeSideBorders?: boolean
  shouldIncludeBottomBorder?: boolean
  currentTab?: number
  shouldWrap?: boolean
  isTransparent?: boolean
  mountAllTabs?: boolean
}

export const useURLTab = (allTabs: Tab[]) => {
  const { search } = useLocation()
  const tabId = new URLSearchParams(search).get("section")
  return useMemo(() => allTabs.find((t) => t.id === tabId)?.key ?? 0, [allTabs, tabId])
}

export const Tabs: FC<React.PropsWithChildren<TabsProps>> = ({
  tabs,
  initialTab,
  onChange,
  stickyHeightClassName,
  shouldIncludeSideBorders = true,
  shouldIncludeBottomBorder = true,
  currentTab,
  shouldWrap = false,
  isTransparent = false,
  mountAllTabs = false,
}) => {
  const [activeTab, setActiveTab] = useState(initialTab)
  const [hoveredTab, setHoveredTab] = useState<number | null>(null)
  const [searchParams, setSearchParams] = useSearchParams()

  const handleClick = (tabIndex: number) => {
    if (onChange) {
      onChange(tabIndex)
    }

    searchParams.set(DETAIL_TAB, tabs[tabIndex].id)
    setSearchParams(searchParams)

    setActiveTab(tabIndex)
  }

  const displayedActiveTabKey = currentTab ?? activeTab

  return (
    <div>
      <div
        style={
          shouldIncludeBottomBorder
            ? {
                boxShadow: "0 5px 6px -5px rgb(0 0 0 / 0.1)",
              }
            : undefined
        }
        className={`${
          stickyHeightClassName ? `sticky ${stickyHeightClassName} z-20` : ""
        } flex items-center ${shouldIncludeBottomBorder ? "border-b shadow-sm" : ""} ${
          shouldIncludeSideBorders ? "border-l border-r" : ""
        } border-neutral-400 overflow-x-auto no-scrollbar ${shouldWrap ? "flex-wrap" : ""} ${
          isTransparent ? "!bg-transparent" : "bg-neutral-white"
        }`}
      >
        {tabs.map((tab) => {
          const isActive = tab.key === displayedActiveTabKey
          const color = isActive ? Color.Link : Color.Placeholder

          return (
            <div
              id={tab.id}
              key={tab.key}
              onClick={() => handleClick(tab.key)}
              className={`${
                isActive ? "border-b-2 border-accent-500" : ""
              } py-2 px-4 cursor-pointer flex items-center gap-2`}
              onMouseEnter={() => setHoveredTab(tab.key)}
              onMouseLeave={() => setHoveredTab(null)}
            >
              <Typography
                color={hoveredTab === tab.key ? Color.HoveredLink : color}
                weight={Weight.Semibold}
                text={tab.label}
                size={Size.Small}
                shouldWrap={false}
              />
              {tab.tabIcon}
            </div>
          )
        })}
      </div>
      <div>
        {mountAllTabs
          ? tabs.map((tab) => (
              <div key={tab.id} className={tab.key !== displayedActiveTabKey ? "hidden" : ""}>
                {tab.children}
              </div>
            ))
          : tabs.find((tab) => tab.key === displayedActiveTabKey)?.children}
      </div>
    </div>
  )
}
