import { Divider, notification } from "@stories/components/Antd"
import { AllAccountsCacheFields } from "common/model/Account"
import { handleConsoleError } from "src/utils/Tracking"
import Drawer from "@components/Drawer/Drawer"
import { AccountTradingRegistration } from "common/model/Account/AccountTradingRegistration"
import { isLoaded, isLoading } from "common/utils/Loading"
import Spinner from "@components/icons/Spinner"
import { useAccountTradingRegistrationForAccount } from "src/utils/useAccountTradingRegistration"
import { AdminTradingRegistrationStatusSelection } from "./AdminTradingRegistrationStatusSelection"
import { AdminTradingRegistrationInitialization } from "./AdminTradingRegistrationInitialization"
import { AdminTradingRegistrationForm } from "./AdminTradingRegistrationForm"
import { Card } from "@stories/components/Card/Card"
import { ViewDocumentLink } from "src/pages/TradeRegistration/Verification/ViewVerificationLink"
import { AdminUploadFiles } from "./AdminUploadFiles"
import Typography, { Size, Weight } from "@stories/components/Typography/Typography"
import { AdminAccreditationResponseStatus } from "./AdminAccreditationResponseStatus"
import { AdminAccountUserPreview } from "./AdminAccountUserPreview"
import { AdminTradingRegistrationMarkAsNotRequired } from "./AdminTradingRegistrationMarkAsNotRequired"

export const AdminTradingRegistrationDrawer = ({
  open,
  onClose,
  account,
}: {
  account: AllAccountsCacheFields
  open: boolean
  onClose: () => void
}) => {
  const { tradingRegistration, updateTradingRegistration } =
    useAccountTradingRegistrationForAccount({ accountId: account.id })
  const handleStatusSave = (
    updatedTradingRegistrationStatus: AccountTradingRegistration["status"]
  ) => {
    notification.close("saved-account-registration-status")
    if (isLoaded(tradingRegistration)) {
      updateTradingRegistration({
        ...tradingRegistration,
        status: { ...tradingRegistration.status, ...updatedTradingRegistrationStatus },
      })
        .then(() =>
          notification.success({
            key: "saved-account-registration-status",
            message: "Updated Registration Status",
          })
        )
        .catch(handleConsoleError)
    }
  }
  return (
    <Drawer open={open} title="Trading Registration" onClose={onClose} size="lg">
      <Typography text={account.name} size={Size.XLarge} weight={Weight.Semibold} />
      <Divider />
      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-col gap-4">
          <AdminAccountUserPreview account={account} />
          <AdminAccreditationResponseStatus account={account} />
          {isLoaded(tradingRegistration) ? (
            <AdminTradingRegistrationMarkAsNotRequired
              updateTradingRegistration={updateTradingRegistration}
              tradingRegistration={tradingRegistration}
            />
          ) : null}
        </div>
        <div className="flex flex-col">
          {isLoaded(tradingRegistration) ? (
            <div className="flex flex-col gap-4">
              <AdminTradingRegistrationStatusSelection
                handleSave={handleStatusSave}
                registrationStatus={tradingRegistration?.status}
              />
              {tradingRegistration.verificationDocuments?.length ? (
                <Card>
                  <Typography text="Verification Documents" weight={Weight.Semibold} />
                  {tradingRegistration.verificationDocuments?.map((doc, i) => (
                    <ViewDocumentLink document={doc} key={doc.filePath ?? i} />
                  ))}
                </Card>
              ) : null}
              <AdminUploadFiles
                tradingRegistration={tradingRegistration}
                updateTradingRegistration={updateTradingRegistration}
              />

              <AdminTradingRegistrationForm
                tradingRegistration={tradingRegistration}
                updateTradingRegistration={updateTradingRegistration}
              />
            </div>
          ) : isLoading(tradingRegistration) ? (
            <Spinner size="lg" />
          ) : (
            <AdminTradingRegistrationInitialization
              updateTradingRegistration={updateTradingRegistration}
              account={account}
            />
          )}
        </div>
      </div>
    </Drawer>
  )
}
