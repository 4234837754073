import {
  RadioSelect,
  RadioSelectProps,
  SelectOptions,
} from "@stories/components/Inputs/RadioSelect/RadioSelect"
import { IntroductionRequestData } from "common/model/IntroductionRequest"

const clientRelationshipRadioListOptions: SelectOptions<
  IntroductionRequestData["clientRelationship"]
>[] = [
  { label: "Direct", value: "direct" },
  { label: "Indirect", value: "indirect" },
]
export const ClientRelationshipSelect = ({
  value,
  onChange,
  options = clientRelationshipRadioListOptions,
  label = "Client Relationship",
  disabled,
}: RadioSelectProps<IntroductionRequestData["clientRelationship"]>) => (
  <RadioSelect
    label={label}
    value={value}
    options={options}
    onChange={onChange}
    disabled={disabled}
  />
)
