import { formatRoundedAbbreviatedCurrency } from "common/utils/math/format"
import React from "react"
import { variantStyleMap } from "../Badges/StatusPill/StatusPill"
import { Button } from "../Button/Button"
import LoadingBar from "../LoadingBar/LoadingBar"
import { Color, Size, Typography, Weight } from "../Typography/Typography"
import { UserIcon } from "@stories/icons/UserIcon"
import { Popover } from "@stories/components/Antd/Popover/Popover"

export interface AccountStatusDropdownProps {
  numberOfLiveOrders: number
  numberOfOrdersRequired: number
  orderVolume: number
  orderVolumeRequired: number
  timePeriod: string
  handleButtonClick: () => void
}

const calculateVariant = (ratio: number) => {
  if (ratio >= 1) {
    return "success"
  }

  if (ratio > 0.5) {
    return "default"
  }

  if (ratio > 0.25) {
    return "warning"
  }

  return "danger"
}

export const AccountStatusDropdown: React.FC<AccountStatusDropdownProps> = ({
  numberOfLiveOrders,
  numberOfOrdersRequired,
  orderVolume,
  orderVolumeRequired,
  timePeriod,
  handleButtonClick,
}) => {
  const countRatio = numberOfLiveOrders / numberOfOrdersRequired
  const volumeRatio = orderVolume / orderVolumeRequired

  const complianceIndicator = countRatio >= 1 && volumeRatio < 1 ? "Order Volume" : "Live Orders"
  const indicatorRatio = complianceIndicator === "Live Orders" ? countRatio : volumeRatio

  const volumeTerms = `${formatRoundedAbbreviatedCurrency(
    orderVolume
  )} / ${formatRoundedAbbreviatedCurrency(orderVolumeRequired)}`
  const countTerms = `${numberOfLiveOrders} / ${numberOfOrdersRequired}`

  const complianceDisplayTerms = complianceIndicator === "Live Orders" ? countTerms : volumeTerms

  const indicatorLabel = `${complianceDisplayTerms} ${complianceIndicator}`

  const Preview = (
    <div
      className={`mx-4 px-2 py-1 rounded-md border-2 cursor-pointer text-center whitespace-nowrap inline-block max-w-max
${variantStyleMap[calculateVariant(indicatorRatio)]}`}
    >
      <div className="flex items-center gap-2">
        <UserIcon color={Color.Subtitle} />
        <LoadingBar
          value={(complianceIndicator === "Live Orders" ? countRatio : volumeRatio) * 100}
          label={indicatorLabel}
        />
      </div>
    </div>
  )

  const AccountStatus = (
    <div className="w-64">
      <div>
        <div className="flex flex-col gap-2 mb-4">
          <Typography text="Account Status" size={Size.Small} weight={Weight.Semibold} />
          <Typography
            text="Accounts on Caplight are required to maintain a minimum number of orders and indicative volume."
            size={Size.XSmall}
          />
          <Typography text="Please see your account status below." size={Size.XSmall} />
        </div>
        <div className="flex flex-col gap-2 mb-4">
          <Typography text={timePeriod} size={Size.Small} weight={Weight.Semibold} />
          <LoadingBar
            value={countRatio * 100}
            labelValue={`${numberOfLiveOrders} / ${numberOfOrdersRequired}`}
            label="Live Orders"
          />
          <LoadingBar value={volumeRatio * 100} labelValue={volumeTerms} label="Order volume" />
        </div>
        <div className="flex flex-col items-center justify-center">
          <Typography text="Questions?" size={Size.Small} />
          <Button variant="hollow" label="Contact Us" isFullWidth onClick={handleButtonClick} />
        </div>
      </div>
    </div>
  )

  return <Popover content={AccountStatus}>{Preview}</Popover>
}
