import { Maybe } from "common/containers/Maybe"
import { showInterval } from "common/utils/data/Interval"
import { formatAbbreviatedShares } from "common/utils/math/format"
import { formatValuation } from "../../../../components/displays/numeric/Currency"
import { OrderPageOrder } from "../types"
import OrderPreviewDetail from "./OrderPreviewDetail"
import { orderDataAccessLevel } from "../../shared/accessLevels"

const displayVolume = (o: OrderPageOrder): Maybe<string> =>
  o
    .amountUSD()

    .map((x) => showInterval(formatValuation, true)(x))

const displayShares = (o: OrderPageOrder): Maybe<string> =>
  o
    .shareCount()

    .map((x) => showInterval(formatAbbreviatedShares, true)(x))

const OrderPreviewSize = ({ order }: { order: OrderPageOrder }) =>
  displayVolume(order).match(
    (volume) => (
      <OrderPreviewDetail
        label="Volume"
        accessLevel={orderDataAccessLevel.size(order.isConnectable())}
        value={volume}
        subValue={displayShares(order).map((shareCount) => `${shareCount} shares`)}
      />
    ),
    () =>
      displayShares(order).match(
        (shares) => (
          <OrderPreviewDetail
            accessLevel={orderDataAccessLevel.size(order.isConnectable())}
            label="Shares"
            value={shares}
          />
        ),
        () => null
      )
  )

export default OrderPreviewSize
