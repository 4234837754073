import { chain, uniqBy } from "lodash"
import moment from "moment"
import { addDays, subDays } from "date-fns"
import { Date_, isDateInInterval } from "../../../utils/dateUtils"
import { Interval } from "../../../utils/data/Interval"
import { Just, Maybe, Nothing, nullableToMaybe } from "../../../containers/Maybe"
import { dateOrder, minBy } from "../../../utils/fp/Ord"
import { LIVE_UNTIL_DEFAULT_DAYS, STALE_AFTER_LIVE_UNTIL_DAYS } from "../OrderConstants"
import { Either, Left, Right } from "../../../containers/Either"
import { Schema as z } from "../../../schema/Schema"
import { Expand } from "../../../utils/types/Expand"

export const liveOrderStatuses = ["live"] as const
export type LiveOrderStatus = (typeof liveOrderStatuses)[number]
export const terminalOrderStatuses = ["cancelled", "closed"] as const
export type TerminalOrderStatus = (typeof terminalOrderStatuses)[number]

export const terminalOrderStatusReasonRequiringClosedTrades = [
  "closed trade",
  "closed trade away",
  "rofr",
] as const
type TerminalOrderStatusReasonRequiringClosedTrades =
  (typeof terminalOrderStatusReasonRequiringClosedTrades)[number]
export const isTerminalOrderStatusReasonRequiringClosedTrades = (
  reason: TerminalOrderStatusReason
): reason is TerminalOrderStatusReasonRequiringClosedTrades =>
  terminalOrderStatusReasonRequiringClosedTrades.some((x) => x === reason)
export const userTerminalOrderStatusReasons = [
  ...terminalOrderStatusReasonRequiringClosedTrades,
  "canceled",
  "matched",
] as const
export const adminTerminalOrderStatusReasons = [
  "admin canceled",
  "admin canceled - migrating expired",
] as const
export const terminalOrderStatusReasons = [
  ...userTerminalOrderStatusReasons,
  ...adminTerminalOrderStatusReasons,
] as const
export type TerminalOrderStatusReason = (typeof terminalOrderStatusReasons)[number]
export const userTerminalOrderStatusReasonLabelMap: Record<
  (typeof userTerminalOrderStatusReasons)[number],
  string
> = {
  "closed trade": "Closed Trade On Caplight",
  "closed trade away": "Closed Trade Off Caplight",
  canceled: "Order was Canceled",
  matched: "Matched - In Negotiations",
  rofr: "Trade was ROFR'd",
}

export const orderStatuses = [...liveOrderStatuses, ...terminalOrderStatuses, "unknown"] as const

export const isLiveOrderStatusTag = (
  x: LiveOrderStatus | TerminalOrderStatus | "unknown"
): x is LiveOrderStatus => liveOrderStatuses.some((y) => y === x)
export const isLiveOrderStatus = (x: OrderStatus): x is LiveOrderStatusObj<LiveOrderStatus> =>
  isLiveOrderStatusTag(x.tag)

export const isTerminalOrderStatusTag = (
  x: LiveOrderStatus | TerminalOrderStatus | "unknown"
): x is TerminalOrderStatus => terminalOrderStatuses.some((y) => y === x)
export const isTerminalOrderStatus = (
  x: OrderStatus
): x is DeadOrderStatusObj<TerminalOrderStatus, TerminalOrderStatusReason> =>
  isTerminalOrderStatusTag(x.tag)
export const isTerminalRequiringClosedTradeOrderStatus = (
  x: OrderStatus
): x is DeadOrderStatusObj<TerminalOrderStatus, TerminalOrderStatusReasonRequiringClosedTrades> =>
  isTerminalOrderStatus(x) &&
  !!x.reason &&
  isTerminalOrderStatusReasonRequiringClosedTrades(x.reason)

// needed to get the union to distribute correctly
type DeadOrderStatusObj<
  T extends TerminalOrderStatus,
  R extends TerminalOrderStatusReason
> = T extends TerminalOrderStatus
  ? {
      tag: T
      asOf: Date
      reason?: R
      liveUntil?: never
    }
  : never
type LiveOrderStatusObj<T extends LiveOrderStatus> = T extends LiveOrderStatus
  ? { tag: T; asOf: Date; liveUntil?: Date | null }
  : never

export type OrderStatus =
  | LiveOrderStatusObj<LiveOrderStatus>
  | DeadOrderStatusObj<TerminalOrderStatus, TerminalOrderStatusReason>
  | { tag: "unknown"; asOf: Date; liveUntil?: never }

export type OrderStatusUpdate = Expand<OrderStatus & { statusUpdateType: "reported" | "admin" }>
export const orderStatusUpdateSchema = z
  .object<OrderStatusUpdate>({
    asOf: z.date(),
    statusUpdateType: z.enum(["reported", "admin"]),
    tag: z.enum(["live"]),
  })
  .or(
    z.object<OrderStatusUpdate>({
      asOf: z.date(),
      statusUpdateType: z.enum(["reported", "admin"]),
      reason: z
        .enum([
          "closed trade",
          "closed trade away",
          "rofr",
          "canceled",
          "matched",
          "admin canceled",
          "admin canceled - migrating expired",
        ])
        .optional(),
      tag: z.enum(["cancelled"]),
    })
  )
  .or(
    z.object<OrderStatusUpdate>({
      asOf: z.date(),
      statusUpdateType: z.enum(["reported", "admin"]),
      reason: z
        .enum([
          "closed trade",
          "closed trade away",
          "rofr",
          "canceled",
          "matched",
          "admin canceled",
          "admin canceled - migrating expired",
        ])
        .optional(),
      tag: z.enum(["closed"]),
    })
  )
  .or(
    z.object<OrderStatusUpdate>({
      asOf: z.date(),
      statusUpdateType: z.enum(["reported", "admin"]),
      tag: z.enum(["unknown"]),
    })
  )

export type OrderStatusFields = {
  createdAt: Date
  _lastReportedStatus: OrderStatus | null
  _orderStatusHistory?: OrderStatusUpdate[] | null
  _adminStatusOverride?: OrderStatus | null
}

export const hashOrderStatusUpdate = (x: OrderStatusUpdate) =>
  `${x.asOf.valueOf().toString()}_${x.liveUntil?.valueOf()?.toString() ?? "_noLiveUntil_"}_${
    x.statusUpdateType
  }_${x.tag}`

export const mergeOrderStatusFields = (
  l: OrderStatusFields,
  r: OrderStatusFields
): OrderStatusFields => {
  const fullHistory = uniqBy(
    orderStatusHistory(l).concat(orderStatusHistory(r)),
    hashOrderStatusUpdate
  ).sort((ll, rr) => ll.asOf.valueOf() - rr.asOf.valueOf())
  const status: OrderStatusFields = fullHistory.reduce(
    (acc, u) =>
      u.statusUpdateType === "reported"
        ? updateOrderStatus(acc, u)
        : updateOrderStatusOverride(acc, u),
    {
      createdAt: l.createdAt,
      _lastReportedStatus: null,
      _orderStatusHistory: [],
      _adminStatusOverride: null,
    }
  )
  return status
}

export const viewOrderStatusFields = (o: OrderStatusFields): OrderStatusFields => ({
  _lastReportedStatus: o._lastReportedStatus,
  _orderStatusHistory: o._orderStatusHistory ?? null,
  _adminStatusOverride: o._adminStatusOverride ?? null,
  createdAt: o.createdAt,
})

export const orderStatus = (o: OrderStatusFields): OrderStatus => {
  if (o._adminStatusOverride && o._lastReportedStatus) {
    return o._adminStatusOverride.asOf > o._lastReportedStatus.asOf
      ? o._adminStatusOverride
      : o._lastReportedStatus
  }
  return o._adminStatusOverride ?? o._lastReportedStatus ?? { tag: "unknown", asOf: o.createdAt }
}
export const orderStatusHistory = (o: OrderStatusFields): OrderStatusUpdate[] =>
  (o._orderStatusHistory ?? [])
    .concat([{ ...orderStatus(o), statusUpdateType: "reported" }])
    .concat(
      o._adminStatusOverride ? [{ ...o._adminStatusOverride, statusUpdateType: "admin" }] : []
    )
    .sort((l, r) => l.asOf.valueOf() - r.asOf.valueOf())

export const updateOrderStatus = <R extends OrderStatusFields>(
  r: R,
  newStatus: OrderStatus
): R => ({
  ...r,
  _lastReportedStatus: newStatus,
  _orderStatusHistory: (r._orderStatusHistory || []).concat(
    r._lastReportedStatus
      ? [
          {
            ...r._lastReportedStatus,
            statusUpdateType: "reported",
          },
        ]
      : []
  ),
})
export const updateOrderStatusOverride = <R extends OrderStatusFields>(
  r: R,
  newStatus: OrderStatus
): R => ({
  ...r,
  _adminStatusOverride: newStatus,
  _orderStatusHistory: r._adminStatusOverride
    ? (r._orderStatusHistory || []).concat([
        {
          ...r._adminStatusOverride,
          statusUpdateType: "admin",
        },
      ])
    : r._orderStatusHistory || [],
})

/** do not export */
const orderStatusFieldsAt = (order: OrderStatusFields, date: Date): Maybe<OrderStatusFields> => {
  const history = orderStatusHistory(order)
  // eslint-disable-next-line rulesdir/no-let
  let lastReportedStatus: OrderStatus | null = null
  // eslint-disable-next-line rulesdir/no-let
  let adminStatusOverride: OrderStatus | null = null
  history.forEach((x) => {
    if (x.asOf < date) {
      if (x.statusUpdateType === "reported") {
        // eslint-disable-next-line better-mutation/no-mutation
        lastReportedStatus = x
      } else if (x.statusUpdateType === "admin") {
        // eslint-disable-next-line better-mutation/no-mutation
        adminStatusOverride = x
      }
    }
  })
  return lastReportedStatus
    ? Just({
        _lastReportedStatus: lastReportedStatus,
        _adminStatusOverride: adminStatusOverride,
        _orderStatusHistory: [],
        createdAt: order.createdAt,
      })
    : Nothing
}
export const orderStatusAt = (order: OrderStatusFields, date: Date) =>
  orderStatusFieldsAt(order, date).map(orderStatus)
export const orderLiveAt = (order: OrderStatusFields, date: Date) =>
  orderStatusFieldsAt(order, date).map((s) => isLiveOrder(s, date))

export const lastUserRefreshedDate = (order: OrderStatusFields) => {
  const history = orderStatusHistory(order)
  const mostRecentUserRefreshHistory = chain(history)
    .flatMap((h) => (h.tag === "live" && h.statusUpdateType === "reported" ? [h] : []))
    .orderBy("asOf", "desc")
    .head()
    .value()
  return nullableToMaybe(mostRecentUserRefreshHistory?.asOf)
}

export const getDefaultLiveUntil = (d: Date) => addDays(d, LIVE_UNTIL_DEFAULT_DAYS)

/**
 * Right: Order is "live" or "unknown"
 * Left: Order is terminated
 */
const orderLiveUntilEither = (order: OrderStatusFields): Either<Date, Date> => {
  const status = orderStatus(order)
  return status?.tag === "live"
    ? Right(minBy(dateOrder)(status.liveUntil ?? Date_.maxValue, getDefaultLiveUntil(status.asOf)))
    : status?.tag === "unknown"
    ? Right(getDefaultLiveUntil(status.asOf))
    : Left(subDays(status.asOf, 1))
}

export const orderLiveUntil = (order: OrderStatusFields): Date =>
  orderLiveUntilEither(order).toUnion()

export const isLastReportedStatusLive = (
  lastReportedStatus: OrderStatusFields["_lastReportedStatus"]
) =>
  lastReportedStatus === null ||
  lastReportedStatus === undefined ||
  isLiveOrderStatus(lastReportedStatus)

export const isLiveOrder = (order: OrderStatusFields, now: moment.MomentInput = moment.now()) =>
  moment(orderLiveUntil(order)).isAfter(now) && isLastReportedStatusLive(order._lastReportedStatus)

export const getDefaultStaleUntil = (liveUntil: Date) =>
  moment(liveUntil).add(STALE_AFTER_LIVE_UNTIL_DAYS, "days").toDate()

export const orderStaleUntil = (order: OrderStatusFields): Date =>
  orderLiveUntilEither(order).map(getDefaultStaleUntil).toUnion()

export const isStaleOrder = (order: OrderStatusFields, now: moment.MomentInput = moment.now()) =>
  !isLiveOrder(order, now) &&
  isLastReportedStatusLive(order._lastReportedStatus) &&
  moment(orderStaleUntil(order)).isAfter(now)

export const isLiveOrStaleOrder = (
  order: OrderStatusFields,
  now: moment.MomentInput = moment.now()
) => isLiveOrder(order, now) || isStaleOrder(order, now)

const makeRecentlyRefreshedInterval = (d: Date): Interval<Date> => ({
  lowerBound: moment(d).toDate(),
  upperBound: moment(d).add(7, "days").toDate(),
})
export const isRecentlyRefreshed = (order: OrderStatusFields) =>
  lastUserRefreshedDate(order).match(
    (d) => isDateInInterval(new Date(), makeRecentlyRefreshedInterval(d)),
    () => false
  )
