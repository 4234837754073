import UnreadIcon from "@stories/components/Badges/UnreadIcon/UnreadIcon"
import { HasUnreadNotificationsIcon } from "@stories/icons/HasUnreadNotificationsIcon"
import { NotificationIcon } from "@stories/icons/NotificationIcon"
import { matchLoading } from "common/utils/Loading"
import { useNotifications } from "src/providers/data/NotificationsProvider"
import { Color } from "@stories/components/Typography/Typography"

const UnreadNotificationsBadge = () => {
  const { unreadCount } = useNotifications()

  return matchLoading(
    unreadCount,
    (count) => (
      <>{count ? <UnreadIcon count={count < 10 ? count : "9+"} variant="danger" /> : null}</>
    ),
    null,
    null
  )
}

export const NavNotificationIcon = () => {
  const { unreadCount } = useNotifications()
  return matchLoading(
    unreadCount,
    (count) => (
      <>
        {count ? (
          <HasUnreadNotificationsIcon color={Color.White} />
        ) : (
          <NotificationIcon color={Color.White} />
        )}
      </>
    ),
    <NotificationIcon color={Color.White} />,
    <NotificationIcon color={Color.White} />
  )
}

export default UnreadNotificationsBadge
