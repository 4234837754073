import { formatValuation } from "common/utils/math/format"
import { formatSharePriceWithPriceType } from "../../../../../components/displays/numeric/Currency"
import { orderDataAccessLevel } from "../../../shared/accessLevels"
import OrderTermSheetSection from "../OrderTermSheetSection"
import { EMPTY_ROW_VALUE } from "./constants"
import { OrderPageSectionProps } from "./types"

const PRICE_PER_SHARE = "Price per share"
const VALUATION = "Valuation"
// const COMMISSION = "Commission"
export const PRICE_SECTION_TITLE = "Price"

const getPriceTypeTooltip = (order: OrderPageSectionProps["order"]) =>
  order.commission().match(
    (c) =>
      c.canChargeClientFee
        ? "Pricing includes broker fees."
        : `All in price ${
            order.direction() === "buy"
              ? "buyer is willing to pay."
              : "seller is willing to accept."
          }`,
    () => ""
  )

const PriceSection = ({ order, hideUnspecified }: OrderPageSectionProps) => {
  const accessLevel = orderDataAccessLevel.price(order.isConnectable())
  const ppsRow = order.rawPrice().match(
    ({ valuation: _ }) =>
      // when order has a target valuation
      order.impliedPricePerShare().match(
        // show implied PPS if we can
        (impliedPPS) => ({
          label: `${PRICE_PER_SHARE} (est.)`,
          value: formatSharePriceWithPriceType(impliedPPS, order.toInternal()),
          accessLevel,
          tooltip: getPriceTypeTooltip(order),
        }),
        // otherwise, show empty row
        () => ({
          label: PRICE_PER_SHARE,
          value: EMPTY_ROW_VALUE,
          accessLevel,
        })
      ),
    ({ pricePerShare }) => ({
      label: PRICE_PER_SHARE,
      value: formatSharePriceWithPriceType(pricePerShare, order.toInternal()),
      accessLevel,
      tooltip: getPriceTypeTooltip(order),
    }),
    () => ({ label: PRICE_PER_SHARE, value: "Unpriced", accessLevel })
  )

  const valuationRow = order
    .targetValuation()

    .map(formatValuation)
    .map((valuation) => ({
      label: VALUATION,
      value: valuation,
      accessLevel,
    }))
    .or(
      order
        .impliedValuation()

        .map(formatValuation)
        .map((impliedVal) => ({ label: `${VALUATION} (est.)`, value: impliedVal, accessLevel }))
    )
    .withDefault({ label: VALUATION, value: EMPTY_ROW_VALUE, accessLevel })

  // const commissionRow = order
  //   .commission()
  //   .map((commission) => ({
  //     label: COMMISSION,
  //     value:
  //       commission.commissionType === "USD"
  //         ? formatValuation(commission.amount)
  //         : formatPercentage(commission.amount / 100),
  //     accessLevel,
  //   }))
  //   .withDefault({ label: COMMISSION, value: EMPTY_ROW_VALUE, accessLevel })

  return (
    <OrderTermSheetSection
      title={PRICE_SECTION_TITLE}
      rows={[ppsRow, valuationRow]}
      hideUnspecified={hideUnspecified}
    />
  )
}

export default PriceSection
