import OrderDetailsButton from "@components/OrderDetails"
import { ArrowSmLeftIcon } from "@heroicons/react/solid"
import { restrictedCollections } from "common/firestore/Collections"
import { Link, useParams } from "react-router-dom"
import { Routes } from "../../Routes/Routes"
import PageTabs from "../../components/lib/PageTabs"
import { PageTab } from "../../components/lib/PageTabs/PageTabs"
import { firestoreConsoleLink } from "../utils"
import { ViewOrder } from "./orders/AdminView"
import { EditOrder } from "./orders/Editor"
import { useOrders } from "src/providers/data/OrderProvider"
import { matchLoading } from "common/utils/Loading"
import { RenewButton } from "src/pages/Orders/MyOrders/RefreshOrderButton"

export type AdminOrderPageTabId = "view" | "edit" | "thread"
type AdminOrderPageTab = PageTab & { id: AdminOrderPageTabId }
const adminOrdersPageTabs: (darkpool: boolean) => AdminOrderPageTab[] = (darkpool: boolean) => [
  {
    id: "view",
    title: "view",
    component: <ViewOrder darkpool={darkpool} />,
  },
  {
    id: "edit",
    title: "edit",
    component: <EditOrder />,
  },
]

export const OrderObservationView: React.FC<{ darkpool?: boolean; private?: boolean }> = ({
  darkpool = false,
}) => {
  const { orderId } = useParams<{ orderId: string }>()
  const order = useOrders(orderId ?? "")
  return (
    <div className="p-4">
      <div className="shadow-md bg-neutral-white border-neutral-400 p-8">
        <h2 className="font-bold text-xl mb-4">Order {orderId}</h2>
        <Link
          className="font-bold text-accent-500"
          to={`${Routes.admin.data}?tab=${darkpool ? "darkpool" : "market"}Orders`}
        >
          <div className="flex items-center">
            <ArrowSmLeftIcon height={30} width={30} />{" "}
            <div>Return to {darkpool ? "darkpool" : "market"} orders</div>
          </div>
        </Link>
        <span className="text-neutral-600 text-sm">
          <a
            target="_blank"
            href={firestoreConsoleLink(
              darkpool
                ? restrictedCollections.darkpoolOrderObservations
                : restrictedCollections.orderObservations,
              orderId ?? ""
            )}
            rel="noreferrer"
          >
            View in Firestore console
          </a>
        </span>
        <div className="flex space-x-2">
          <OrderDetailsButton
            orderId={{
              id: orderId ?? "",
              darkpool,
              orderCollection: darkpool ? "darkpool" : "platform", // private orders kept genuinely private
            }}
          />
          {matchLoading(
            order,
            (o) => (
              <RenewButton order={o} sourceComponent="admin-panel" />
            ),
            null,
            null
          )}
        </div>
      </div>
      <PageTabs loadAllTabs tabs={adminOrdersPageTabs(darkpool)} />
    </div>
  )
}
