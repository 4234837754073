import { collections } from "common/firestore/Collections"
import { CampaignParticipant } from "common/model/campaigns/CampaignParticipant"
import { CampaignRef } from "common/model/campaigns/CampaignType"
import { EmailType } from "common/model/EmailType"
import { Loading } from "common/utils/Loading"
import { collection, onSnapshot, query, where } from "firebase/firestore"
import { useState } from "react"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { Memoized, useEffect } from "src/utils/hooks/effects/useEffectSafe"
import { oneOrNone } from "../../firebase/Firebase9"
import { useFirebase9 } from "../../firebase/Firebase9Context"
import { firestoreConverter } from "../../model/FirestoreConverter"
import { useLoadingState } from "../../utils/useLoadingState"

type FirestoreError = firebase.default.firestore.FirestoreError

// TODO: duplicated  code w/ useQuery

export const useCampaignParticipantDoc = <T extends EmailType>(
  campaign: Memoized<CampaignRef | undefined>
): [Loading<CampaignParticipant<T>>, FirestoreError | null] => {
  const firebase = useFirebase9()
  const user = useLoggedInUser()

  const [doc, setDoc] = useLoadingState<CampaignParticipant<T>>()

  const [error, setError] = useState<null | FirestoreError>(null)

  useEffect(() => {
    if (campaign.value) {
      const q = query(
        collection(
          firebase.db,
          campaign.value.campaignType.collectionName,
          campaign.value.id,
          collections.campaignParticipantsSubcollection
        ),
        where("user.email", "==", user.user.email)
      ).withConverter(firestoreConverter<CampaignParticipant<T>>())

      const cleanup = onSnapshot(q, (next) => setDoc(oneOrNone(next) || null), setError)

      return cleanup
    }
    return () => {}
  }, [campaign, campaign.value?.id, firebase.db, setDoc, user.user.email])

  return [doc, error]
}
