import { AccessBoundary } from "@components/auth/DataAccess"
import { FirmIcon } from "@stories/icons/FirmIcon"
import { UserIcon } from "@stories/icons/UserIcon"
import { Account } from "common/model/Account"
import { DealCRMContact, DealCRMContactIdFields } from "common/model/DealCRM/DealCRMContact"
import { isBrokerage, isNonBrokerageFirm } from "common/model/DealCRM/DealCRMFirmContact"
import { DealCRMInterest } from "common/model/DealCRM/DealCRMInterest"
import { CONTACT_ID_SEARCH_KEY } from "common/model/DealCRM/utils/dealCRMRouteUtils"
import { isLoaded, isLoading } from "common/utils/Loading"
import { FC, useCallback, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useFirebaseWriter } from "src/firebase/Context"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import {
  UpdateContactDeprecatedProps,
  createContactInterest,
  updateContactDeprecated,
} from "src/firebase/crm"
import { useCurrentAccount } from "src/queries/currentUser/useCurrentAccount"
import { handleConsoleError, trackEventInFirestoreAndHeap } from "src/utils/Tracking"
import Drawer, { DrawerProps } from "../../../../components/Drawer/Drawer"
import Spinner from "../../../../components/icons/Spinner"
import { useCurrentUser } from "../../../../providers/currentUser/useCurrentUser"
import { useCRMContacts } from "../../Providers/CRMContactsProvider"
import { BrokerContactDetails } from "./BrokerContactDetails"
import { InterestTable } from "./BuyInterest/InterestTable"
import { ContactDealsTable } from "./ContactDeals/ContactDealsTable"
import { ContactNotes } from "./ContactNotes/ContactNotes"
import { ContactTitle } from "./ContactTitle"
import { FirmContactDetails } from "./FirmContactDetails"
import { HoldingsTable } from "./Holdings/HoldingsTable"
import { IndividualContactDetails } from "./IndividualContactDetails"
import { useContactBuySellInterest } from "./helpers"

export const ContactDetailsDrawerWrapper: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { findContactById } = useCRMContacts()
  const urlSearch = useMemo(() => new URLSearchParams(location.search), [location.search])

  const contactIds = useMemo(() => urlSearch.getAll(CONTACT_ID_SEARCH_KEY), [urlSearch])

  const handleClose = useCallback(() => {
    urlSearch.delete(CONTACT_ID_SEARCH_KEY)
    contactIds
      .slice(0, -1)
      .forEach((contactId) => urlSearch.append(CONTACT_ID_SEARCH_KEY, contactId))
    navigate({ search: urlSearch.toString() })
  }, [contactIds, urlSearch, navigate])

  const contactId = contactIds?.[contactIds.length - 1]
  const backContactId = contactIds?.[contactIds.length - 2]
  const backContact = findContactById(backContactId)

  return (
    <>
      {contactId ? (
        <ContactDetailsDrawer
          open
          onClose={handleClose}
          contactId={contactId}
          back={
            backContact ? { onClick: handleClose, label: `Back to ${backContact.name}` } : undefined
          }
        />
      ) : null}
      {children}
    </>
  )
}

const ContactDetailsDrawer = ({
  contactId,
  onClose,
  open,
  back,
}: {
  contactId: string
  onClose: () => void
  open: boolean
  back?: DrawerProps["back"]
}) => {
  const account = useCurrentAccount()
  const { findContactById } = useCRMContacts()

  const contact = findContactById(contactId)

  if (!contact || !isLoaded(account)) return null

  return (
    <Drawer
      back={back}
      className="text-sm h-full"
      open={open}
      title={
        <AccessBoundary accessLevel="hideFromCaplight">
          <div data-dd-privacy="mask" className="flex items-center gap-4">
            <div>{contact.tag === "firm" ? <FirmIcon /> : <UserIcon />}</div>
            {contact.name}
            {isBrokerage(contact) || contact.tag === "broker" ? " (Broker)" : ""}
          </div>
        </AccessBoundary>
      }
      size="xl"
      onClose={onClose}
      destroyOnClose
    >
      <ContactDetailsDrawerContent contact={contact} account={account} />
    </Drawer>
  )
}

const ContactDetailsDrawerContent = ({
  contact: contactIdFields,
  account,
}: {
  account: Account
  contact: DealCRMContactIdFields
}) => {
  const db = useFirebaseWriter()
  const firebase9 = useFirebase9()
  const user = useCurrentUser()
  const { findContactById } = useCRMContacts()
  const currentContact = findContactById(contactIdFields.id)

  const contactBuySellInterest = useContactBuySellInterest({ contact: currentContact })

  if (
    isLoading(currentContact) ||
    !isLoaded(user) ||
    !isLoaded(account) ||
    !isLoaded(contactBuySellInterest)
  )
    return <Spinner size="sm" />

  const handleUpdateContactDeprecated = <C extends DealCRMContact>({
    contact,
    field: contactKey,
    fieldValue: contactValue,
  }: UpdateContactDeprecatedProps<C>) =>
    updateContactDeprecated({
      db,
      contact,
      field: contactKey,
      fieldValue: contactValue,
      user: user.user,
    })
      .then(() => {
        if (
          isLoaded(user) &&
          !["contactBuyInterest", "contactSellInterest", "holdings"].includes(contactKey)
        ) {
          trackEventInFirestoreAndHeap(firebase9, user.user, "crm-contact-updated", {
            field: contactKey,
          })
        }
      })
      .catch(handleConsoleError)

  const handleAddInterest = async (newInterest: DealCRMInterest) => {
    if (!isLoaded(user)) return
    await createContactInterest({
      db,
      interest: newInterest,
      user: user.user,
    })
  }

  if (!currentContact)
    return (
      <div>
        Could not find contact details. This is likely an issue, please contact us at
        contact@caplight.com to let us know about the issue
      </div>
    )

  return (
    <div className="flex flex-col gap-4">
      <ContactTitle contact={currentContact} updateContact={handleUpdateContactDeprecated} />
      <div className="overflow-y-scroll flex flex-col gap-4">
        {currentContact.tag === "firm" && <FirmContactDetails contact={currentContact} />}
        {currentContact.tag === "individual" && (
          <IndividualContactDetails contact={currentContact} />
        )}
        {currentContact.tag === "broker" && (
          <BrokerContactDetails
            contact={currentContact}
            updateContact={handleUpdateContactDeprecated}
          />
        )}
        <InterestTable
          account={account}
          contact={currentContact}
          contactInterest={contactBuySellInterest}
          addInterest={handleAddInterest}
          side="buy"
        />
        <InterestTable
          account={account}
          contact={currentContact}
          contactInterest={contactBuySellInterest}
          addInterest={handleAddInterest}
          side="sell"
        />
        {currentContact.tag === "individual" || isNonBrokerageFirm(currentContact) ? (
          <HoldingsTable contact={currentContact} />
        ) : null}
        <ContactDealsTable contact={currentContact} />
        <ContactNotes contact={currentContact} contactBuySellInterest={contactBuySellInterest} />
      </div>
    </div>
  )
}

export default ContactDetailsDrawer
