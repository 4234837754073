import { classNames } from "react-notion"

type FilterButtonProps = React.PropsWithChildren<
  { active?: boolean; noBackground?: boolean } & React.ComponentProps<"button">
>

const FilterButton: React.FC<FilterButtonProps> = ({
  active,
  children,
  className,
  noBackground,
  ...props
}) => (
  <button
    {...props}
    className={classNames(
      className,
      "px-2 py-1.5 capitalize text-xs flex space-x-1 items-center whitespace-nowrap",
      !noBackground ? "rounded border" : "",
      !noBackground ? (active ? "bg-primary-500 text-white" : "bg-neutral-400") : ""
    )}
    type="button"
  >
    {children}
  </button>
)

export default FilterButton
