/* eslint-disable jsx-a11y/anchor-is-valid */
import { Tooltip } from "@stories/components/Antd"
import DropdownMenu from "@stories/components/DropdownMenu/DropdownMenu"
import Typography, { Color, Size } from "@stories/components/Typography/Typography"
import { FlagIcon } from "@stories/icons/FlagIcon"
import { GearIcon } from "@stories/icons/GearIcon"
import { SignOutIcon } from "@stories/icons/SignOutIcon"
import TopBrokerIcon from "@stories/icons/TopBrokerIcon"
import { Loading } from "common/utils/Loading/Wrapped"
import { Link, useNavigate } from "react-router-dom"
import { Routes } from "src/Routes/Routes"
import { useFirebaseReader } from "../../firebase/Context"
import { CurrentUser } from "../../model/CurrentUser"
import { useErrorHandler } from "../../providers/errorHandler"
import { useCurrentAccount } from "../../queries/currentUser/useCurrentAccount"
import { useAdminFeatureFlagDrawer } from "../../providers/featureFlags/useFeatureFlags"
import { UserSettingsRoutes } from "src/pages/UserSettings/Page"
import { RenewIcon } from "@stories/icons/RenewIcon"
import { useDevResetOnboarding } from "src/pages/Onboarding/components/DevResetOnboarding"
import { useAccessControl } from "src/providers/AccessControl/AccessControlProvider"
import { LockClosedIcon } from "@stories/icons/LockClosedIcon"

const AccountTopBrokerIcon = () => {
  const fullAccount = new Loading(useCurrentAccount())
  return fullAccount.match(
    (a) =>
      a.isTopBroker ? (
        <Tooltip
          placement="bottom"
          title={
            <div className="p-1 flex flex-col space-y-1 max-w-xs">
              <Typography color={Color.White} text="You are a Top Broker" size={Size.Small} />
              <span className="flex justify-center font-semibold">
                <Link
                  id="top-broker-learn-more"
                  to={`${UserSettingsRoutes.topBroker}`}
                  target="_blank"
                  onClick={(e) => e.stopPropagation()}
                >
                  Learn More
                </Link>
              </span>
            </div>
          }
        >
          <div className="w-4 h-4 text-primary-400">
            <TopBrokerIcon color={Color.Primary} />
          </div>
        </Tooltip>
      ) : null,
    null,
    null
  )
}

export const UserMenu = ({ user }: { user: CurrentUser }) => {
  const { handleError: onError } = useErrorHandler()
  const firebase = useFirebaseReader()
  const signOut = () => {
    firebase.signOut().catch(onError)
  }

  const { showResetOnboarding, resetOnboarding } = useDevResetOnboarding()

  const setFeatureFlagsDrawerOpen = useAdminFeatureFlagDrawer()
  const { setDrawerOpen: setAccessControlDrawerOpen } = useAccessControl()
  const navigate = useNavigate()

  return (
    <DropdownMenu
      dropdownButtonSubtitle={user.user.email}
      dropdownButtonTitle={
        <div className="flex space-x-1 items-center">
          <div>{user.user.account.name}</div>
          <AccountTopBrokerIcon />
        </div>
      }
      menuItems={[
        {
          icon: <GearIcon color={Color.Black} />,
          id: "1",
          label: "Settings",
          onClick: () => navigate(Routes.account),
          variant: "accent",
          heapName: "view-user-settings-button",
        },
        {
          icon: <FlagIcon color={Color.Black} />,
          id: "2",
          label: "Feature Flags",
          onClick: () => setFeatureFlagsDrawerOpen?.(true),
          variant: "accent",
          isAdminOnly: true,
          heapName: "view-feature-flags-button",
        },
        {
          icon: <LockClosedIcon color={Color.Primary} />,
          id: "access-control",
          label: "Access Control",
          onClick: () => setAccessControlDrawerOpen?.(true),
          variant: "accent",
          isAdminOnly: true,
          heapName: "view-access-control-button",
        },

        {
          icon: <GearIcon color={Color.Black} />,
          id: "3",
          label: "Admin",
          onClick: () => navigate(Routes.admin.data),
          variant: "accent",
          isAdminOnly: true,
          heapName: "view-admin-button",
        },
        ...(showResetOnboarding
          ? [
              {
                icon: <RenewIcon color={Color.Black} />,
                id: "dev-reset-onboarding",
                label: "Reset Onboarding",
                onClick: () => resetOnboarding().then(() => navigate(Routes.onboarding)),
                variant: "accent",
                heapName: "dev-reset-onboarding-button",
              } as const,
            ]
          : []),
        {
          icon: <SignOutIcon color={Color.Black} />,
          id: "4",
          label: "Sign Out",
          onClick: signOut,
          variant: "danger",
          heapName: "sign-out-button",
        },
      ]}
    />
  )
}
