import { Card } from "@stories/components/Antd"
import { ProductInformationKeys } from "common/model/User"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import NewIcon from "@stories/components/Badges/NewIcon/NewIcon"
import { Button } from "@stories/components/Button/Button"
import { ProductInformationLoomLinks } from "./InformationPopupConfig"
import { useInformationPopupModal } from "./InformationPopupModal"

export const InformationPopupBadgeButton = ({
  informationKey,
  cardClassName,
}: {
  informationKey: (typeof ProductInformationKeys)[number]
  cardClassName?: string
}) => {
  const user = useLoggedInUser()
  const productInfo = ProductInformationLoomLinks[informationKey]

  const hasViewed = user.user?.productInteractionHistory?.[informationKey]
  const { ModalComponent, openModal } = useInformationPopupModal({
    defaultModalOpen: !hasViewed,
    informationKey,
  })
  const hideFromUser = productInfo.hideFromUser?.(user.user) ?? false
  const dateIsExpired = productInfo.announcementLiveUntil
    ? productInfo.announcementLiveUntil.valueOf() < new Date().valueOf()
    : false
  if (hideFromUser || dateIsExpired) {
    return null
  }

  return (
    <>
      <Card size="small" className={cardClassName}>
        <div className="flex items-center">
          <NewIcon />
          <p className="text-xs ml-1 font-semibold">{productInfo.title}</p>
        </div>
        <p className="text-xs mt-1">{productInfo.body}</p>
        <Button variant="hollow" size="small" label="Learn More" onClick={openModal} />
      </Card>
      {ModalComponent}
    </>
  )
}
