import { Order } from "common/model/Order/Order"
import { isLiveOrder } from "common/model/Order/Types/Status"
import { Loading, lift2Loading, mapLoading } from "common/utils/Loading"
import { useMemo } from "react"
import { MAXIMUM_LIVE_DARKPOOL_ORDERS } from "src/pages/Orders/MyOrders/DarkpoolLimitText"
import { Account } from "common/model/Account"

export type RemainingLiveDarkpoolOrderData = {
  canSubmitDarkpoolOrder: Loading<boolean>
  remainingDarkpoolOrderCount: Loading<number>
  liveDarkpoolOrders: Loading<Order[]>
  liveDarkpoolOrderCount: Loading<number>
  maxLiveDarkpoolOrderCount: Loading<number>
}

export const initialRemainingLiveDarkpoolOrderData: RemainingLiveDarkpoolOrderData = {
  canSubmitDarkpoolOrder: "loading",
  remainingDarkpoolOrderCount: "loading",
  liveDarkpoolOrders: "loading",
  liveDarkpoolOrderCount: "loading",
  maxLiveDarkpoolOrderCount: "loading",
}

type DarkpoolOrderCounts = {
  liveOrderCount: number
  maxCount: number
}

export const useRemainingLiveDarkpoolOrders = (
  account: Loading<Account>,
  loadingDarkpoolOrders: Loading<Order[]>
): RemainingLiveDarkpoolOrderData => {
  const liveDarkpoolOrders = mapLoading<Order[], Order[]>((orders) =>
    orders.flatMap((o) => (isLiveOrder(o) ? o : []))
  )(loadingDarkpoolOrders)

  const liveDarkpoolOrderCount = mapLoading<Order[], number>((orders) => orders.length)(
    liveDarkpoolOrders
  )

  const maxLiveDarkpoolOrderCount = mapLoading<Account, number>(
    (a) => a.maxLiveDarkpoolIndicationCount ?? MAXIMUM_LIVE_DARKPOOL_ORDERS
  )(account)

  const loadingData = lift2Loading(
    (x, y) => ({ liveOrderCount: x, maxCount: y }),
    liveDarkpoolOrderCount,
    maxLiveDarkpoolOrderCount
  )

  return useMemo(
    () => ({
      canSubmitDarkpoolOrder: mapLoading<DarkpoolOrderCounts, boolean>(
        ({ liveOrderCount, maxCount }) => liveOrderCount < maxCount
      )(loadingData),

      remainingDarkpoolOrderCount: mapLoading<DarkpoolOrderCounts, number>(
        ({ liveOrderCount, maxCount }) => maxCount - liveOrderCount
      )(loadingData),
      liveDarkpoolOrders,
      liveDarkpoolOrderCount,
      maxLiveDarkpoolOrderCount,
    }),
    [liveDarkpoolOrderCount, liveDarkpoolOrders, maxLiveDarkpoolOrderCount, loadingData]
  )
}
