/** Utils for ensuring type information gets propagated without a concrete value attached */

export type ContravariantProxy<T> = (t: T) => null
export type CovariantProxy<T> = (t: ContravariantProxy<T>) => null
export type InvariantProxy<T> = CovariantProxy<T> & ContravariantProxy<T>
export const covProxy =
  <T>(): CovariantProxy<T> =>
  () =>
    null
export const contraProxy =
  <T>(): ContravariantProxy<T> =>
  () =>
    null
export const invProxy =
  <T>(): InvariantProxy<T> =>
  () =>
    null

export type Variance = "covariant" | "contravariant" | "invariant"

export type Proxy<V extends Variance, T> = V extends "invariant"
  ? InvariantProxy<T>
  : V extends "covariant"
  ? CovariantProxy<T>
  : V extends "contravariant"
  ? ContravariantProxy<T>
  : never

export type UnContraProxy<T extends ContravariantProxy<never>> = T extends ContravariantProxy<infer X> ? X : never
export type Unproxy<T extends CovariantProxy<unknown> | ContravariantProxy<never>> =
  T extends CovariantProxy<infer X> ? X : T extends ContravariantProxy<infer X> ? X : unknown

export type UnproxyArray<T> = T extends readonly [infer Head, ...infer Tail]
  ? Head extends CovariantProxy<unknown> | ContravariantProxy<unknown>
    ? Tail extends (CovariantProxy<unknown> | ContravariantProxy<unknown>)[]
      ? readonly [Unproxy<Head>, ...UnproxyArray<Tail>]
      : readonly [Unproxy<Head>]
    : readonly []
  : readonly []
