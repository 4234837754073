import { DatePicker } from "@stories/components/Antd"
import SectionLabel from "@stories/components/Inputs/SectionLabel/SectionLabel"
import { PriceObservationType } from "common/model/data-product/pricing/PriceObservation"
import { Interval } from "common/utils/data/Interval"
import moment from "moment"
import { PriceObservationErrors } from "src/pages/ClosedTrades/ClosedTradeForm/contextHooks/useClosedTradeFormSaving"

type ClosedTradeDateSectionData = Pick<PriceObservationType, "observationDate">
export const ClosedTradeDateSection = ({
  value,
  onChange,
  titles,
  formErrors,
}: {
  value: Partial<ClosedTradeDateSectionData>
  onChange: (value: Partial<ClosedTradeDateSectionData>) => void
  titles: {
    observationDate: string
  }
  formErrors: PriceObservationErrors
}) => (
  <div>
    <SectionLabel hasError={formErrors.has("observationDate")}>
      {titles.observationDate}
    </SectionLabel>
    <div>
      <DatePicker
        size="large"
        className="w-full pl-0 pr-0"
        clearIcon={false}
        onChange={(v) => onChange({ ...value, observationDate: Interval.pure(moment(v).toDate()) })}
        value={value.observationDate ? moment(value.observationDate.lowerBound) : undefined}
        disabledDate={(d) => moment().isBefore(d)}
      />
    </div>
  </div>
)
