import { collections } from "common/firestore/Collections"
import { Company } from "common/model/Company"
import { firestoreConverter } from "common/model/FirestoreConverter"
import { HashMap } from "common/containers/HashMap"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import { collection, getDocs, limit, query, where } from "firebase/firestore"
import { useMemo, useState } from "react"
import { Loading } from "common/utils/Loading"
import { handleConsoleError } from "src/utils/Tracking"

export const useLazyCompanyLookupTable = <K extends keyof Company>(lookupKey: K) => {
  const firebase = useFirebase9()
  const companies = collection(firebase.db, collections.companies).withConverter(
    firestoreConverter<Company>()
  )
  const backingMap: HashMap<Company[K], Promise<Company>> = new HashMap()
  return {
    get: (key: Company[K]) => {
      const existingResult = backingMap.get(key)
      if (!existingResult) {
        const q = getDocs(query(companies, limit(1), where(lookupKey, "==", key))).then((result) =>
          result.docs[0].data()
        )
        backingMap.mutatingSet(key, q)
        return q
      } else {
        return existingResult
      }
    },
  }
}

export const useLazyStatefulCompanyLookupTable = <K extends keyof Company>(lookupKey: K) => {
  const firebase = useFirebase9()
  const companies = collection(firebase.db, collections.companies).withConverter(
    firestoreConverter<Company>()
  )
  const [backingMap, setBackingMap] = useState(new HashMap<Company[K], Loading<Company>>())
  const surface = useMemo(
    () => ({
      get: (key: Company[K]) => {
        const existingResult = backingMap.get(key)
        if (existingResult === undefined) {
          getDocs(query(companies, limit(1), where(lookupKey, "==", key)))
            .then((result) => {
              setBackingMap(
                backingMap.set(key, result.docs.length > 0 ? result.docs[0].data() : null)
              )
            })
            .catch(handleConsoleError)
          return "loading"
        } else {
          return existingResult
        }
      },
    }),
    [backingMap, companies, lookupKey]
  )
  console.log(backingMap)
  return surface
}
