/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Modal } from "@components/layout/Modal"
import { Card } from "@stories/components/Antd/Card/Card"
import { AsyncDispatchButton } from "@stories/components/Button/AsyncDispatchButton"
import { Button } from "@stories/components/Button/Button"
import Divider from "@stories/components/Divider/Divider"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { Account } from "common/model/Account"
import { AccountSelfReportedClientProfile } from "common/model/Account/AccountSelfReportedClientProfile"
import {
  AccountTradingRegistration,
  AccountTradingRegistrationSummary,
  TRADING_REGISTRATION_SEARCH_KEY,
  defaultAccountTradingRegistration,
  isTradingRegistrationOnLastStep,
} from "common/model/Account/AccountTradingRegistration"
import { defaultIfLoading, isLoaded, matchLoading } from "common/utils/Loading"
import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { APIEndpoints, runAPIQuery, sendPlatformEventMessage } from "src/firebase/API"
import { CompletedAgreementPage } from "src/pages/TradeRegistration/CompletedAgreementPage"
import { InviteColleagueToTradingRegistrationButton } from "src/pages/TradeRegistration/InviteColleague"
import { DocumentsNeededList } from "src/pages/TradeRegistration/Verification/VerificationPage"
import { useAccountSnapshot } from "src/providers/AccountSnapshot/useAccountSnapshot"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { handleConsoleError } from "src/utils/Tracking"
import { useAccountTradingRegistrationForAccount } from "src/utils/useAccountTradingRegistration"
import { v4 as uuidv4 } from "uuid"
import { TradingRegistrationTypeSelection } from "./TradingRegistrationTypeSelection"
import { StopClickPropagation } from "@stories/components/StopClickPropagation/StopClickPropagation"
import { assertUnreachable } from "common/utils/fp/Function"

const tradingRegistrationPageLink = (t: Pick<AccountTradingRegistration, "id">) =>
  `/trading-registration?${TRADING_REGISTRATION_SEARCH_KEY}=${t.id}`

export const LoadedTradingRegistrationRequiredModal = ({
  account,
  open,
  onClose,
}: {
  account: Account & {
    tradingRegistration: AccountTradingRegistrationSummary
    selfReportedClientProfile: AccountSelfReportedClientProfile
  }
  open: boolean
  onClose?: () => void
}) => {
  const navigate = useNavigate()

  const { tradingRegistration, updateTradingRegistration } =
    useAccountTradingRegistrationForAccount({
      accountId: account.id,
    })

  const generateTradingRegistration = async () => {
    if (tradingRegistration === null) {
      const id = uuidv4()
      if (account.selfReportedClientProfile === "employee") {
        throw new Error(
          "Trading registration rendered for employee account. This should not happen"
        )
      }
      const accountProfile: AccountTradingRegistration["type"] = account.selfReportedClientProfile
      return updateTradingRegistration(
        defaultAccountTradingRegistration(id, account, accountProfile)
      )
        .then(() => navigate(tradingRegistrationPageLink({ id })))
        .catch(handleConsoleError)
    }
    return Promise.reject(new Error("Trading registration already exists"))
  }

  const isOnLastStep = isTradingRegistrationOnLastStep(account)
  return (
    <StopClickPropagation>
      <Modal
        open={open}
        handleClose={() => onClose?.()}
        closable={!!onClose}
        subtitle={`Trading Registration ${isOnLastStep ? "Pending" : "Required"}`}
        body={
          <div className="w-150 flex flex-col gap-4">
            {isOnLastStep ? (
              <CompletedAgreementPage tradingRegistration={tradingRegistration} onClose={onClose} />
            ) : account.selfReportedClientProfile === "broker" ? (
              <div className="flex flex-col gap-4">
                <Typography
                  text={
                    "For regulatory & compliance reasons, we require your Broker-Dealer to complete Caplight's trading registration in order for you to access the trading functionality on the platform."
                  }
                />
                <Typography text="Estimated time to complete: 5 minutes." />

                {matchLoading(
                  tradingRegistration,
                  (t) => (
                    <Link to={tradingRegistrationPageLink(t)}>
                      <Button label="Submit Broker-Dealer Information" isFullWidth />
                    </Link>
                  ),
                  <Button label="Loading" isFullWidth isLoading isDisabled />,
                  <AsyncDispatchButton
                    label="Submit Broker-Dealer Information"
                    isFullWidth
                    onClick={generateTradingRegistration}
                  />
                )}
              </div>
            ) : (
              <div className="flex flex-col gap-4">
                <Typography
                  text={`For regulatory & compliance reasons, you must complete Caplight's Trading Registration for ${account.name} to access trading functionality on the platform.`}
                />
                <Typography text="Estimated time to complete: 5-10 minutes." />

                <>
                  <Card size="small">
                    <DocumentsNeededList
                      title="You will need access to the following documents"
                      tradingRegistration={tradingRegistration}
                    />
                  </Card>
                  <div className="flex flex-col gap-4">
                    {matchLoading(
                      tradingRegistration,
                      (t) => (
                        <Link to={tradingRegistrationPageLink(t)}>
                          <Button label="Start Trading Registration" isFullWidth />
                        </Link>
                      ),
                      <Button label="Loading" isFullWidth isLoading isDisabled />,
                      <AsyncDispatchButton
                        label="Start Trading Registration"
                        isFullWidth
                        onClick={generateTradingRegistration}
                      />
                    )}
                    {account.selfReportedClientProfile === "fund" ? (
                      <>
                        <Divider label="OR" />
                        <Typography
                          size={Size.Small}
                          weight={Weight.Semibold}
                          text="Not the best person to complete this?"
                        />
                        <Typography
                          size={Size.Small}
                          text="You may share this registration form with a colleague to complete on your behalf."
                        />
                        <InviteColleagueToTradingRegistrationButton
                          renderButton={(onClick) => (
                            <Button
                              variant="secondary"
                              label="Invite Colleague"
                              onClick={onClick}
                            />
                          )}
                          tradingRegistration={tradingRegistration}
                        />
                      </>
                    ) : null}
                  </div>
                </>
              </div>
            )}
            <div className="flex justify-center gap-1">
              <Typography
                text="Please direct any questions to"
                color={Color.Subtitle}
                size={Size.Small}
              />
              <Typography
                text="platform@caplight.com."
                color={Color.Subtitle}
                size={Size.Small}
                weight={Weight.Semibold}
              />
            </div>
          </div>
        }
      />
    </StopClickPropagation>
  )
}

export const TradingRegistrationRequiredModal = ({
  dataType,
  open,
  onClose,
}: {
  dataType: "order form" | "introduction request" | "status bar"
  open: boolean
  onClose: () => void
}) => {
  const user = useLoggedInUser()
  const { accountSnapshot } = useAccountSnapshot()
  const navigate = useNavigate()
  const [selectedTradingRegistrationType, setSelectedTradingRegistrationType] = useState<Exclude<
    AccountSelfReportedClientProfile,
    "employee"
  > | null>(null)

  useEffect(() => {
    if (open) {
      sendPlatformEventMessage(
        `${user.user.firstName} ${user.user.lastName} of ${
          user.user.account.name
        } opened trading registration required modal ${
          dataType !== "status bar" ? `when trying to see a ${dataType}!` : "."
        }`,
        user
      ).catch(handleConsoleError)
    }
  }, [dataType, user, open])

  if (!isLoaded(accountSnapshot)) return null

  if (accountSnapshot.tradingRegistration && accountSnapshot.selfReportedClientProfile) {
    return (
      <LoadedTradingRegistrationRequiredModal
        account={{
          ...accountSnapshot,
          tradingRegistration: accountSnapshot.tradingRegistration,
          selfReportedClientProfile: accountSnapshot.selfReportedClientProfile,
        }}
        open={open}
        onClose={onClose}
      />
    )
  }

  const generateTradingRegistration = async () => {
    if (
      selectedTradingRegistrationType !== null &&
      accountSnapshot.selfReportedClientProfile !== "employee"
    ) {
      const id = uuidv4()
      const defaultedTradingRegistration = defaultAccountTradingRegistration(
        id,
        accountSnapshot,
        selectedTradingRegistrationType
      )
      return runAPIQuery(APIEndpoints.updateTradingRegistration, {
        tradeRegistration: defaultedTradingRegistration,
      })
        .then(() => navigate(tradingRegistrationPageLink({ id })))
        .catch(handleConsoleError)
    }
    return Promise.reject(new Error("Nothing selected"))
  }

  return (
    <StopClickPropagation>
      <Modal
        open={open}
        handleClose={() => onClose?.()}
        closable={!!onClose}
        subtitle="Trading Registration Required"
        body={
          <div className="w-150 flex flex-col gap-4">
            <div className="flex flex-col gap-4">
              <Typography
                text={`For regulatory & compliance reasons, you must complete Caplight's Trading Registration for ${accountSnapshot.name} to access trading functionality on the platform.`}
              />
              <Typography text="Estimated time to complete: 5-10 minutes." />
              <Divider />
              <div className="flex flex-col gap-2">
                <TradingRegistrationTypeSelection
                  tradingRegistrationType={defaultIfLoading(selectedTradingRegistrationType, null)}
                  updateTradingRegistration={setSelectedTradingRegistrationType}
                />
              </div>
              <AsyncDispatchButton
                label="Continue"
                isDisabled={!isLoaded(selectedTradingRegistrationType)}
                onClick={generateTradingRegistration}
              />
            </div>
          </div>
        }
      />
    </StopClickPropagation>
  )
}
