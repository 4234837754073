import { createContext, useCallback, useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import { Just, justIfIs, Maybe, Nothing } from "common/containers/Maybe"
import { Account } from "common/model/Account"
import { nullableToEither } from "common/containers/Either"
import { Company, companyInPostgres, CompanyWithPostgres } from "common/model/Company"
import {
  isRequested,
  isRequestedPending,
  isRequestedReceived,
  Requesting,
} from "common/utils/Loading"
import { useErrorHandler } from "../errorHandler"
import Spinner from "../../components/icons/Spinner"
import {
  getApiKey,
  getCompanyNameFromParams,
  getCompanyIdFromParams,
  getIntegrationUserFromParams,
} from "./searchParamUtils"
import { isAppLoadingSelector, useAppSelector } from "../../state/reduxSelectors"
import EmbedWrapper from "./EmbedWrapper"
import {
  EmbedAccountResult,
  getCompanyId,
  getEmbedAccount,
  getEmbedCompany,
  getOriginURL,
  isValidationValid,
  ValidationBody,
} from "./utils"
import { useFirebaseReader } from "../../firebase/Context"
import { CurrentUser } from "../../model/CurrentUser"
import { EitherAuthlessValidationOrCurrentUser } from "../../firebase/PostgresDBQuery"
import { trackEvent } from "../../utils/Tracking"
import { StyleOverridesContext } from "../styleOverrides/StyleOverridesProvider"
import { getStyleOverrides } from "../../pages/WidgetEmbeds/components/StyleOverrides"
import { PartnerIntegrationUser } from "../../pages/WidgetEmbeds/model/PartnerIntegrationUser"

type AuthlessWidgetViewMetadata = {
  companyName: string
  companyId: string
  companyMarketPriceExists: boolean
  companyMarketPriceEnabled: boolean
  accountName: string
  accountId: string
}
const trackAuthlessWidgetView = ({ account, company }: { account: Account; company: Company }) =>
  trackEvent("authless-widget-viewed", {
    companyId: company.id,
    companyName: company.name,
    companyMarketPriceExists: Boolean(company.priceEstimatesSummary?.currentPrice),
    companyMarketPriceEnabled: Boolean(company.settings?.showCaplightPriceEstimate),
    accountId: account.id,
    accountName: account.name,
  } satisfies AuthlessWidgetViewMetadata)

const setAccountGlobalStyleOverrides = (account: Account) => {
  if (account.name === "InvestX") {
    // eslint-disable-next-line rulesdir/no-let
    const link = document.createElement("link")
    // eslint-disable-next-line better-mutation/no-mutation
    link.rel = "stylesheet"
    // eslint-disable-next-line better-mutation/no-mutation
    link.href = "https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap"
    document.getElementsByTagName("head")[0].appendChild(link)
    document.body.setAttribute("style", "background: transparent; font-family: Poppins !important;")
  }
}

type EmbedContextValue = {
  account: Maybe<Account>
  company: Maybe<CompanyWithPostgres>
  embedValidationBody: Maybe<ValidationBody>
  partnerUser: Maybe<PartnerIntegrationUser>
  // TODO: rename?
  eitherAuthlessValidationOr: (
    currentUser: CurrentUser | null
  ) => EitherAuthlessValidationOrCurrentUser
}
export const EmbedContext = createContext<EmbedContextValue>({
  account: Nothing,
  company: Nothing,
  embedValidationBody: Nothing,
  partnerUser: Nothing,
  eitherAuthlessValidationOr: (currentUser) => nullableToEither(Nothing, currentUser),
})

export const EmbedProvider = ({ children }: { children: React.ReactNode }) => {
  const { handleError } = useErrorHandler()
  const { search } = useLocation()

  const embedValidationBody = useMemo(
    () =>
      getOriginURL()
        .map((url) => url.hostname)
        .alongside(getApiKey(search))
        .map(([hostname, apiKey]) => ({ hostname, apiKey })),
    [search]
  )
  const isAppLoading = useAppSelector(isAppLoadingSelector)
  const [accountResult, setAccountResult] =
    useState<Requesting<EmbedAccountResult>>("not requested yet")
  useEffect(() => {
    if (isRequested(accountResult)) {
      return
    }

    setAccountResult("loading")

    embedValidationBody
      .match(getEmbedAccount, () => Promise.reject())
      .then(setAccountResult)
      .catch(handleError)
      .finally(() => setAccountResult((x) => (isRequestedPending(x) ? null : x)))
  }, [accountResult, embedValidationBody, handleError])

  const [companyResult, setCompanyResult] = useState<Requesting<Company>>("not requested yet")
  const db = useFirebaseReader()
  useEffect(() => {
    if (isRequested(companyResult)) {
      return
    }

    setCompanyResult("loading")
    embedValidationBody
      .match(
        getEmbedCompany(
          getCompanyIdFromParams(search).match(
            (id) => Promise.resolve(Just(id)),
            () => getCompanyNameFromParams(search).match(getCompanyId, () => Promise.reject())
          ),
          db
        ),
        () => Promise.reject()
      )
      .then(setCompanyResult)
      .catch(handleError)
      .finally(() => setAccountResult((x) => (isRequestedPending(x) ? null : x)))
  }, [db, companyResult, search, embedValidationBody, handleError])

  const eitherAuthlessValidationOr = useCallback(
    (loadedCurrentUser: CurrentUser | null) =>
      nullableToEither(embedValidationBody, loadedCurrentUser),
    [embedValidationBody]
  )

  const partnerUser = getIntegrationUserFromParams(search)
  const value: EmbedContextValue = useMemo(
    () => ({
      account: justIfIs(isRequestedReceived, accountResult).bind(
        // FIXME: create proper zod parser to avoid this
        // eslint-disable-next-line rulesdir/no-assert
        ({ account }) => (account ? Just(account as unknown as Account) : Nothing)
      ),
      company: justIfIs(isRequestedReceived, companyResult).bind((company) =>
        companyInPostgres(company) ? Just(company) : Nothing
      ),
      partnerUser,
      embedValidationBody,
      eitherAuthlessValidationOr,
    }),
    [accountResult, companyResult, partnerUser, embedValidationBody, eitherAuthlessValidationOr]
  )

  useEffect(() => {
    value.account.runEffect(setAccountGlobalStyleOverrides)
    value.account.alongside(value.company).runEffect(([account, company]) => {
      trackAuthlessWidgetView({ company, account })
    })
  }, [value])

  const bgStyle: "light" | "dark" = useMemo(
    () => (value.account.toNullable()?.widgetVersion === "compound" ? "light" : "dark"),
    [value.account]
  )
  const styles = useMemo(() => {
    const account = value.account.toNullable()
    return account ? getStyleOverrides(account, bgStyle === "dark") : {}
  }, [value.account, bgStyle])

  if (isRequestedPending(accountResult) || isRequestedPending(companyResult) || isAppLoading)
    return <Spinner size="sm" fullPage />

  // TODO: better error states?
  const account = value.account.toNullable()
  if (accountResult === null || !isValidationValid(accountResult) || !account) return null

  if (value.company.isNothing()) {
    trackEvent("embed-widget-company-load-error", {
      companyId: getCompanyIdFromParams(search).withDefault(""),
      companyName: getCompanyNameFromParams(search).withDefault(""),
      partnerUser: partnerUser.map((u) => u.email).toNullable(),
      accountId: value.account.map((a) => a.id).withUnconstrainedDefault(null),
      accountName: value.account.map((a) => a.name).withUnconstrainedDefault(null),
    })

    return (
      <div
        style={bgStyle === "dark" ? { background: "rgb(14,14,6)" } : {}}
        className={bgStyle === "dark" ? "text-white p-4" : "p-4"}
      >
        <p>No price history data found for the specified company.</p>
      </div>
    )
  }

  return (
    <StyleOverridesContext.Provider value={styles}>
      <EmbedContext.Provider value={value}>
        <EmbedWrapper>
          <div>{children}</div>
        </EmbedWrapper>
      </EmbedContext.Provider>
    </StyleOverridesContext.Provider>
  )
}
