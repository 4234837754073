import SkeletonLoader from "@components/icons/SkeletonLoader"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { Sector } from "common/model/Sector"
import { Loading, isLoaded } from "common/utils/Loading"
import * as React from "react"
import ReactSelect from "react-select"
import makeAnimated from "react-select/animated"
import { UpdateContactProps } from "src/firebase/crm"
import { useSectors } from "src/pages/Data/Hooks"

const animatedComponents = makeAnimated()

interface ContactSectorFocusListProps {
  contact: DealCRMContact
  updateContact: (params: UpdateContactProps<DealCRMContact>) => unknown
}

const ContactSectorFocusList: React.FunctionComponent<ContactSectorFocusListProps> = ({
  contact,
  updateContact,
}) => {
  const sectors: Loading<Sector[]> = useSectors()

  if (!isLoaded(sectors)) return <SkeletonLoader numRows={1} />

  const sectorOptions = sectors.map((sector) => ({
    value: sector.sectorMetaData.name,
    label: sector.sectorMetaData.name,
  }))

  return (
    <ReactSelect
      styles={{
        control: (provided) => ({
          ...provided,
          width: "100%",
          minWidth: "100%",
        }),
        container: (provided) => ({
          ...provided,
          width: "100%",
          minWidth: "100%",
        }),
        menu: (provided) => ({
          ...provided,
          width: "100%",
          minWidth: "100%",
        }),
        menuList: (provided) => ({
          ...provided,
          width: "100%",
          minWidth: "100%",
        }),
      }}
      closeMenuOnSelect={false}
      components={animatedComponents}
      isMulti
      isClearable={false}
      options={sectorOptions}
      value={contact.sectorsOfInterest?.map((sector) => ({
        value: sector,
        label: sectorOptions.find((option) => option.value === sector)?.label ?? sector,
      }))}
      onChange={(selectedOptions) =>
        updateContact({
          contact,
          update: {
            sectorsOfInterest: selectedOptions.map((option) => option.value),
          },
        })
      }
    />
  )
}

export default ContactSectorFocusList
