import { SharedOrder } from "common/model/SharedOrder/SharedOrder"
import { SharedOrderCreateParams } from "common/model/SharedOrder/SharedOrderCreate"
import { ShareableItem } from "common/model/SharedOrder/SharedOrderResponse"
import { User } from "common/model/User"
import { assertUnreachable } from "common/utils/fp/Function"
import { getAPIResponse, APIEndpoints } from "src/firebase/API"
import Firebase9 from "src/firebase/Firebase9"
import { trackUserEventInFirestore, trackEvent } from "src/utils/Tracking"

export const sendSharedOrder = ({
  firebase9,
  user,
  sharedItems,
  sharedWithUserEmails,
  userForm,
  sendEmails,
}: {
  firebase9: Firebase9
  user: User
  sharedItems: ShareableItem[]
  sharedWithUserEmails: SharedOrderCreateParams["sharedWithUserEmails"]
  userForm: SharedOrderCreateParams["userForm"]
  sendEmails: boolean
  afterSend?: () => void
}) =>
  getAPIResponse<SharedOrder>(APIEndpoints.createSharedOrder, {
    ids: sharedItems.map((item) =>
      item.tag === "order"
        ? {
            id: item.id,
            type:
              item.order.orderCollection === "darkpool"
                ? "darkpool_order"
                : item.order.orderCollection === "tentativeInterest"
                ? "tentative_interest"
                : "order",
          }
        : item.tag === "crm_interest"
        ? { id: item.id, type: "crm_interest" }
        : item.tag === "deal_search"
        ? { id: item.id, type: "deal_search" }
        : assertUnreachable(item)
    ),
    sourceUserId: user.id,
    sharedWithUserEmails,
    userForm,
    sendEmails,
  } satisfies SharedOrderCreateParams).then(() => {
    trackUserEventInFirestore(firebase9, user, "orders-shared")
    trackEvent("orders-shared", {
      orderCount: sharedItems.length,
      recipientCount: sharedWithUserEmails.length,
    })
  })
