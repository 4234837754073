import { isLoaded, Loading } from "common/utils/Loading"
import { GoogleAuthToken } from "common/model/Google/GoogleAuthToken"
import { useQuerySnapshot } from "src/utils/hooks/queries/useQuerySnapshot"
import { collections } from "common/firestore/Collections"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { firestoreConverter } from "common/model/FirestoreConverter"
import { GmailMessage } from "common/model/Google/GmailMessage"
import { GmailInbox } from "common/model/Google/GmailInbox"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import { ExternalAuthAPI } from "common/api/ExternalAuthAPI"
import { makeAPIPostRequest } from "common/api/Request"
import { config } from "src/config"
import { handleConsoleError } from "src/utils/Tracking"
import { usePromisedState } from "src/utils/hooks/state/usePromisedState"
import { useLoggedInUserAuthToken } from "src/firebase/AuthUtils"
import { useMemo } from "react"

export const useLiveGmailAuthTokens = (): Loading<GoogleAuthToken[]> => {
  const currentUser = useLoggedInUser()
  const userToken = useLoggedInUserAuthToken()
  const token = usePromisedState(
    () =>
      isLoaded(userToken)
        ? makeAPIPostRequest(
            `${config.firebase.cloudApiUrl}/external-auth/`,
            ExternalAuthAPI,
            ["get-google-oauth-token"],
            userToken
          )({ userId: currentUser.user.id }).catch((e) => {
            handleConsoleError(e)
            return null
          })
        : Promise.resolve("loading"),
    [],
    [userToken, currentUser.user.id]
  )
  const tokens = useMemo(() => (isLoaded(token) ? [token] : token ?? []), [token]) // TODO remove array
  return tokens
}

export const useOwnGmailInboxes = (): Loading<GmailInbox[]> => {
  const currentUser = useLoggedInUser()
  return useQuerySnapshot(
    (db) =>
      db.db
        .collection(collections.google.gmail.inboxes)
        .withConverter<GmailInbox>(firestoreConverter())
        .where("createdBy.id", "==", currentUser.user.id),
    (x) => x,
    [currentUser.user.id]
  )
}

export const useOwnGmailMessages = (): Loading<GmailMessage[]> => {
  const user = useCurrentUser()
  return useQuerySnapshot(
    (db) =>
      db.db
        .collection(collections.google.gmail.rawMessages)
        // .where("email", "==", )
        .withConverter<GmailMessage>(firestoreConverter<GmailMessage & { id?: string }>()),
    (x) => x,
    []
  )
}
