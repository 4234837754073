import { nullableToMaybe } from "common/containers/Maybe"
import { collections } from "common/firestore/Collections"
import { Company } from "common/model/Company"
import { Opportunity } from "common/model/Opportunity/Opportunity"
import { OrderLookupFields } from "common/model/Order/Models/Internal"
import { Order, OrderIdFields } from "common/model/Order/Order"
import {
  ConstructedOpportunityInboxFilterRule,
  OpportunityInboxFilterRule,
} from "common/model/Order/OrderFilter/OpportunityInboxFilterRule"
import { PartnerIntegrationOrder } from "common/model/PartnerIntegrations/PartnerIntegrationOrder"
import { User, UserProductInteractionHistory } from "common/model/User"
import { CRMAllDealsViewOption, contactsTableTabSettings } from "common/model/UserCRMSettings"
import { UserOnlineStatus } from "common/model/UserOnlineStatus"
import { hiddenOutlierStatuses } from "common/model/data-product/DataPoint/OutlierStatusFields"
import { PriceObservationType } from "common/model/data-product/pricing/PriceObservation"
import { FirebaseApp, getApp, initializeApp } from "firebase/app"
import "firebase/auth"
import {
  DocumentSnapshot,
  Firestore,
  QuerySnapshot,
  SnapshotListenOptions,
  UpdateData,
  addDoc,
  and,
  collection,
  connectFirestoreEmulator,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  initializeFirestore,
  onSnapshot,
  or,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore"
import "firebase/functions"
import "firebase/storage"
import moment from "moment"
import { SingletonObject, unsafeAssertSingleton } from "src/utils/hooks/effects/useEffectSafe"
import { config } from "../config"
import { firestoreConverter } from "../model/FirestoreConverter"
import { industryConverter } from "../model/Industry"
import { handleConsoleError } from "../utils/Tracking"
import { getCollectionForOrder } from "./orders"
import { TableColumnState } from "common/model/Settings/TableSettings"
import { DotNestedKeys } from "common/firestore/DotNestedKeys"
import { ContactsTableTabsType } from "common/model/DealCRM/DealCRMContact"

// eslint-disable-next-line rulesdir/no-let, @typescript-eslint/naming-convention
let _fb: Firebase9 | undefined = undefined

const userConverter = firestoreConverter<User>()

export const oneOrNone = <T>(results: { empty: boolean; docs: { data: () => T }[] }) =>
  results.empty ? undefined : results.docs[0].data()

class Firebase9 {
  db: SingletonObject<Firestore>

  app: FirebaseApp

  constructor() {
    const app = getApp()
    if (!app) {
      this.app = initializeApp(config.firebase)
      initializeFirestore(this.app, { experimentalAutoDetectLongPolling: true })
    } else {
      this.app = app
    }

    this.db = unsafeAssertSingleton(_fb?.db || getFirestore())

    if (!_fb && window.location.hostname === "localhost") this.useEmulators()
    // eslint-disable-next-line better-mutation/no-mutation
    if (!_fb) _fb = this
  }

  userDoc = (userId: string) => doc(this.db, collections.users, userId).withConverter(userConverter)

  getUserSnapshot = (
    userId: string,
    onNext: (next: DocumentSnapshot<User>) => void,
    options: SnapshotListenOptions = {}
  ) => onSnapshot(this.userDoc(userId), options, onNext)

  recordUserProductInteraction = (
    user: User,
    interactionUpdate: Partial<UserProductInteractionHistory>
  ) => {
    const updates: UpdateData<User> = {
      productInteractionHistory: {
        ...user.productInteractionHistory,
        ...interactionUpdate,
      },
    }
    return updateDoc(this.userDoc(user.id), updates)
  }

  updateUserAllDealsView = (user: User, newView: CRMAllDealsViewOption) => {
    const updates: UpdateData<User> = {
      crmPreferences: {
        deals: {
          ...(user.crmPreferences?.deals ?? {}),
          allDealsView: newView,
        },
      },
    }

    return updateDoc(this.userDoc(user.id), updates)
  }

  updateUserAllInterestTableSettingState = (userId: string, tableSettingState: TableColumnState) =>
    updateDoc(this.userDoc(userId), {
      ["crmPreferences.allInterestTable" satisfies DotNestedKeys<User>]:
        tableSettingState satisfies Required<User>["crmPreferences"]["allInterestTable"],
    })

  updateUserAllContactsTableSettingState = (
    userId: string,
    tableSettingState: TableColumnState,
    tab: ContactsTableTabsType
  ) =>
    updateDoc(this.userDoc(userId), {
      [`crmPreferences.${contactsTableTabSettings[tab]}` satisfies DotNestedKeys<User>]:
        tableSettingState satisfies Required<User>["crmPreferences"][(typeof contactsTableTabSettings)[typeof tab]],
    })

  toggleShowFundContacts = (user: User, showFundContacts: boolean) => {
    const updates: UpdateData<User> = {
      crmPreferences: {
        deals: {
          ...(user.crmPreferences?.deals ?? {}),
        },
        contacts: {
          ...(user.crmPreferences?.contacts ?? {}),
          showFundContacts,
        },
      },
    }

    return updateDoc(this.userDoc(user.id), updates)
  }

  getCompanyByAirtableId = (airtableId: string) =>
    getDocs(
      query(
        collection(this.db, collections.companies),
        where("airtableId", "==", airtableId)
      ).withConverter(firestoreConverter<Company>())
    ).then((result) => result.docs.map((document) => document.data()))

  getCompanyByPbid = (pbid: string) =>
    getDocs(
      query(collection(this.db, collections.companies), where("pbid", "==", pbid)).withConverter(
        firestoreConverter<Company>()
      )
    ).then((result) => result.docs.map((document) => document.data()))

  getAllCompanies = () =>
    getDocs(
      query(collection(this.db, collections.companies)).withConverter(firestoreConverter<Company>())
    ).then((result) => result.docs.map((document) => document.data()))

  getAllSectors = () =>
    getDocs(collection(this.db, collections.industries).withConverter(industryConverter))

  setUserOnlineStatus = async (
    userId: string,
    status: UserOnlineStatus["lastStatus"],
    options?: { isAdmin: boolean }
  ) => {
    const previousStatus = await getDoc(
      doc(this.db, collections.userOnlineStatus, userId).withConverter(
        firestoreConverter<UserOnlineStatus>()
      )
    ).then((statusDoc) => statusDoc.data())

    // If a user has multiple sessions open, we don't want to overwrite their 'active' status with 'idle'
    if (
      previousStatus &&
      previousStatus.lastStatus === "active" &&
      moment(previousStatus.lastStatusChange).isAfter(moment().subtract(1, "minute"))
    ) {
      return Promise.resolve()
    } else {
      const statusDocUpdate: UserOnlineStatus = {
        id: userId,
        userId,
        lastStatus: status,
        lastStatusChange: new Date(),
        isAdmin: options?.isAdmin,
      }

      return setDoc(doc(this.db, collections.userOnlineStatus, userId), statusDocUpdate)
    }
  }

  getUserOnlineStatusSnapshot = (
    userId: string,
    onNext: (next: DocumentSnapshot<UserOnlineStatus>) => void
  ) =>
    onSnapshot(
      doc(this.db, collections.userOnlineStatus, userId).withConverter(
        firestoreConverter<UserOnlineStatus>()
      ),
      onNext
    )

  getAdminOnlineStatusSnapshot = (onNext: (next: QuerySnapshot<UserOnlineStatus>) => void) =>
    onSnapshot(
      query(
        collection(this.db, collections.userOnlineStatus),
        where("isAdmin", "==", true)
      ).withConverter(firestoreConverter<UserOnlineStatus>()),
      onNext
    )

  getOrderRef = (order: OrderIdFields) =>
    nullableToMaybe(getCollectionForOrder(order)).map((orderCollection) =>
      doc(this.db, orderCollection, order.id).withConverter(firestoreConverter<Order>())
    )

  createPartnerIntegrationOrder = async (data: Omit<PartnerIntegrationOrder, "id">) =>
    addDoc(collection(this.db, collections.partnerIntegrationOrders), data)

  getAccountPartnerIntegrationOrders = async (
    accountId: string,
    integrationUserId: string | undefined
  ) => {
    const integrationUserIdQuery = integrationUserId
      ? where("integrationUser.uuid", "==", integrationUserId)
      : where("integrationUser", "==", null)

    return getDocs(
      query(
        collection(this.db, collections.partnerIntegrationOrders),
        where("integrationAccount.id", "==", accountId),
        integrationUserIdQuery
      ).withConverter(firestoreConverter<PartnerIntegrationOrder>())
    )
  }

  getHiddenOutlierDataPoints = <T extends Order | PriceObservationType>(col: string) =>
    getDocs(
      query(
        collection(this.db, col),
        or(
          where("outlierStatus.outlierSummary.global.tag", "in", hiddenOutlierStatuses),
          where("outlier.tag", "in", hiddenOutlierStatuses)
        )
      ).withConverter(firestoreConverter<T>())
    )

  getOpportunityInboxFilterRules = (
    accountId: string,
    onNext: (next: QuerySnapshot<OpportunityInboxFilterRule>) => void
  ) =>
    onSnapshot(
      query(
        collection(this.db, collections.opportunityInboxFilterRules),
        where("account.id", "==", accountId)
      ).withConverter(firestoreConverter<OpportunityInboxFilterRule>()),
      onNext
    )

  saveOpportunityInboxFilterRule = (orderRule: ConstructedOpportunityInboxFilterRule) =>
    addDoc(collection(this.db, collections.opportunityInboxFilterRules), orderRule)

  deactivateOpportunityInboxFilterRule = (orderRule: OpportunityInboxFilterRule) =>
    setDoc(doc(this.db, collections.opportunityInboxFilterRules, orderRule.id), {
      ...orderRule,
      active: false,
    })

  getOpportunitiesForAccount = (
    accountId: string,
    onNext: (next: QuerySnapshot<Opportunity>) => void
  ) =>
    onSnapshot(
      query(
        collection(this.db, collections.opportunities),
        where("account.id", "==", accountId),
        where("orderMetadata.hidden", "==", false),
        orderBy("orderMetadata.lastUpdatedAt", "desc")
      ).withConverter(firestoreConverter<Opportunity>()),
      onNext
    )

  getActiveOpportunitiesForAccount = (
    accountId: string,
    onNext: (next: QuerySnapshot<Opportunity>) => void
  ) =>
    onSnapshot(
      query(
        collection(this.db, collections.opportunities),
        and(
          where("account.id", "==", accountId),
          where("orderMetadata.hidden", "==", false),
          and(
            where("archivedBy.active", "==", false),
            where("orderMetadata.expiresAt", ">=", new Date())
          )
        ),
        orderBy("orderMetadata.expiresAt", "desc"),
        orderBy("orderMetadata.lastUpdatedAt", "desc")
      ).withConverter(firestoreConverter<Opportunity>()),
      onNext
    )

  getAllOpportunitiesForAccount = (
    accountId: string,
    onNext: (next: QuerySnapshot<Opportunity>) => void
  ) =>
    onSnapshot(
      query(
        collection(this.db, collections.opportunities),
        and(where("account.id", "==", accountId), where("orderMetadata.hidden", "==", false)),
        orderBy("orderMetadata.expiresAt", "desc"),
        orderBy("orderMetadata.lastUpdatedAt", "desc")
      ).withConverter(firestoreConverter<Opportunity>()),
      onNext
    )

  getOrderOpportunityByOrder = (accountId: string, orderId: OrderLookupFields) =>
    getDocs(
      query(
        collection(this.db, collections.opportunities),
        where("account.id", "==", accountId),
        where("orderMetadata.id", "==", orderId.id),
        where("orderMetadata.orderCollection", "==", orderId.orderCollection ?? "platform")
      ).withConverter(firestoreConverter<Opportunity>())
    ).then(oneOrNone)

  setOrderOpportunity = (opportunity: Opportunity) =>
    setDoc(doc(this.db, collections.opportunities, opportunity.id), opportunity)

  setCompany = (company: Company) =>
    setDoc(doc(this.db, collections.companies, company.id), company)

  private useEmulators = () => {
    try {
      connectFirestoreEmulator(this.db, "localhost", config.firebase.firestoreEmulatorPort)
    } catch (err) {
      handleConsoleError(err)
    }
  }
}

export default Firebase9
