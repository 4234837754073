import { Button } from "@stories/components/Button/Button"
import { TableFilter } from "./TableFilter"
import { Popover } from "@stories/components/Antd/Popover/Popover"
import DownChevronIcon from "@stories/icons/DownChevron"

export const createDropDownFilter = <ItemType, FilterType extends unknown>(
  filter: TableFilter<ItemType, FilterType, FilterType>,
  name: string
): TableFilter<ItemType, FilterType, FilterType> =>
  new TableFilter([
    filter.value[0],
    (props) => (
      <Popover
        content={<div className="flex flex-col gap-2">{filter.value[1](props)}</div>}
        trigger="click"
        placement="bottom"
      >
        <Button
          label={name}
          rightIcon={<DownChevronIcon size="medium" />}
          renderRawIcons
          variant="secondary"
          size="large"
        />
      </Popover>
    ),
  ])
