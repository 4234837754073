import moment from "moment"
import { UnsafeRec } from "../../../utils/RecordUtils"

// firebase can't handle nested arrays
export type UpdateLog<T> = Partial<Record<number, T>>

export const stateAt =
  (x: moment.MomentInput) =>
  <T>(u: UpdateLog<T>): T | null =>
    UnsafeRec.entries(u).find(([date, __value]) => moment(date).isAfter(x))?.[1] ?? null
export const currentState = stateAt(moment.now())
export const dateToTimestamp = (date: Date) => date.valueOf()
export namespace UpdateLog_ {
  export const singleton = <T>(date: Date, t: T): UpdateLog<T> => ({ [dateToTimestamp(date)]: t })
}
