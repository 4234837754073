import { PageTitle } from "@components/typography/PageTitle"
import { FC } from "react"

interface SectionHeaderProps {
  title: string
  subtext: string
}

const SectionHeader: FC<React.PropsWithChildren<SectionHeaderProps>> = ({ title, subtext }) => (
  <div className="text-center">
    <PageTitle title={title} />
    <p className="text-neutral-1000 text-sm text-left mt-4">{subtext}</p>
    <div>
      <hr className="my-4" />
    </div>
  </div>
)

export default SectionHeader
