import { orderBy } from "lodash"
import { useMemo } from "react"
import { Loading } from "common/utils/Loading/Wrapped"
import { Order } from "common/model/Order/Models/Wrapped"
import { Date_ } from "common/utils/dateUtils"
import { useIssuerPageTradesAndOrders } from "src/pages/Data/IssuerPage/DataTable/TradeAndOrderProvider"
import { useLoggedInUser } from "../loggedInUser/useLoggedInUser"

type CompanyLiveOrdersProviderData = {
  liveAndStaleOrders: Loading<Order[]>
}

export const useCompanyLiveOrders = () => {
  const user = useLoggedInUser()
  const { allOrders: loadingLiveAndStaleOrders } = useIssuerPageTradesAndOrders()

  const unsortedLiveAndStaleOrders = useMemo(
    () =>
      new Loading(loadingLiveAndStaleOrders).map((orders) =>
        orders
          .filter(
            (o) =>
              o.isConnectable() ||
              o.sourceAttribution(user.user).match(
                () => true,
                () => false
              )
          )
          .filter((o) => o.isDirectToClient())
      ),
    [loadingLiveAndStaleOrders, user.user]
  )

  const liveAndStaleOrders = useMemo(
    () =>
      unsortedLiveAndStaleOrders.map((orders) =>
        orderBy(
          orders,
          (o) =>
            o
              .status()
              .lastRefreshed()
              .withDefault(o.originationDate() ?? Date_.minValue)
              .valueOf(),
          "desc"
        )
      ),
    [unsortedLiveAndStaleOrders]
  )

  const value = useMemo(
    () => ({ liveAndStaleOrders } satisfies CompanyLiveOrdersProviderData),
    [liveAndStaleOrders]
  )

  return value
}
