import _ from "lodash"
import { subHours } from "date-fns"
import { OrderTermUpdate, serializedStructureTerm } from "./Wrapped/OrderUpdate"
import { Interval } from "../../../utils/data/Interval"
import { OrderStatusUpdate, isTerminalOrderStatusTag } from "../Types/Status"
import { TaggedStructureOrderTerms } from "../Types/Terms"
import { UnsafeRec } from "../../../utils/RecordUtils"
import { nDaysFrom } from "../../../utils/dateUtils"
import { Order } from "./Wrapped"

const getLiveTermsRightBefore = (
  orderedTerms: TermsAtomicHistorySnapshot[],
  date: Date
): TermsAtomicHistorySnapshot | undefined => {
  const filt = _.reverse(_.cloneDeep(orderedTerms)).find(
    (term) => term.date <= date && term.updateType !== "cancelled"
  )
  return filt
}

export const augmentOrderTermsWithUpdateDates = (
  sortedTermSnapshotsByStructure: [string, TermsAtomicHistorySnapshot[]][],
  statusUpdates: Omit<OrderStatusUpdate, "statusUpdateType">[]
) => {
  // string below represents serializedStructureKey (or, structureId)
  // for each structure, we want to get history of updates
  const sortedTermSnapshotsByStructureWithStatusUpdates: [string, TermsAtomicHistorySnapshot[]][] =
    sortedTermSnapshotsByStructure.map((upd) => {
      let terms: TermsAtomicHistorySnapshot[] = _.orderBy(_.cloneDeep(upd[1]), (x) => x.date)
      const structureId = upd[0]
      statusUpdates.forEach((statusUpdate) => {
        const datesAlreadyInTerms = _.uniq(terms.map((t) => t.date.valueOf()))
        const ignoreThisUpdate =
          datesAlreadyInTerms.includes(statusUpdate.asOf.valueOf()) &&
          !isTerminalOrderStatusTag(statusUpdate.tag)
        if (!ignoreThisUpdate) {
          terms = terms.filter((t) => t.date.valueOf() !== statusUpdate.asOf.valueOf())
          // if previousTerm.terms is already null, it means that the terms are no longer active
          // (i.e. user removed a certain structure from their order)
          const previousTerm = getLiveTermsRightBefore(terms, statusUpdate.asOf)
          if (previousTerm) {
            // this condition should be satisfied, unless update date is before the first term date, which is unlikely
            terms.push({
              ...previousTerm,
              updateType: isTerminalOrderStatusTag(statusUpdate.tag) ? "cancelled" : "updated",
              date: statusUpdate.asOf,
            })
            terms = _.orderBy(terms, (x) => x.date)
          }
        }
      })
      terms = _.orderBy(terms, (x) => x.date)
      return [structureId, terms]
    })
  return sortedTermSnapshotsByStructureWithStatusUpdates
}

export const appendLivenessInterval = (
  sortedTermSnapshotsByStructureWithGenericAndStatusUpdates: [
    string,
    TermsAtomicHistorySnapshot[]
  ][],
  livenessAssumption: number
): [string, TermsAtomicHistorySnapshotNonNullTerms[]][] =>
  sortedTermSnapshotsByStructureWithGenericAndStatusUpdates.map(([structureId, updates]) => {
    const updatesWithLiveness: TermsAtomicHistorySnapshotNonNullTerms[] = _.orderBy(updates, (x) =>
      x.date.valueOf()
    ).flatMap((upd, idx): TermsAtomicHistorySnapshotNonNullTerms[] => {
      const { terms } = upd
      if (terms === null) return []

      const liveToUsingLivenessAssumption = nDaysFrom(livenessAssumption, upd.date)

      // if this is a cancellation term update or if the term is an outlier, there is no liveness associated with the term
      const thisStatus = upd.updateType
      if (thisStatus === "cancelled" || terms.isOutlier) {
        return [
          {
            ..._.omit(upd, "terms"),
            terms,
            liveBetween: null satisfies null | Interval<Date>,
          },
        ] satisfies TermsAtomicHistorySnapshotNonNullTerms[]
      }

      // if this is the last known term / update, we assume it will be live for livenessAssumption days
      if (idx === updates.length - 1) {
        // latest term
        const livenessInterval: Interval<Date> = {
          lowerBound: upd.date,
          upperBound: liveToUsingLivenessAssumption,
        }
        return [
          {
            ...upd,
            terms,
            liveBetween: livenessInterval satisfies null | Interval<Date>,
          } satisfies TermsAtomicHistorySnapshotNonNullTerms,
        ]
      }
      const nextDate = updates[idx + 1].date
      // if the whole order got cancelled
      // or if a structure got removed in the future from terms (e.g. user no longer interested in SPVs)
      // or if terms got updates,
      // then liveness is min(livenessAssumption days; next update date)
      return [
        {
          ...upd,
          terms,
          liveBetween: {
            lowerBound: upd.date,
            upperBound:
              liveToUsingLivenessAssumption > nextDate ? nextDate : liveToUsingLivenessAssumption,
          } satisfies null | Interval<Date>,
        } satisfies TermsAtomicHistorySnapshotNonNullTerms,
      ]
    })
    return [structureId, updatesWithLiveness]
  })

export type TermsAtomicHistorySnapshot = {
  date: Date
  structureTerms: TaggedStructureOrderTerms
  structureId: string
  updateType: "updated" | "cancelled"
  terms: null | {
    term: OrderTermUpdate
    isOutlier: boolean
    outlierDate: Date | null
  }
}
export type TermsAtomicHistorySnapshotNonNullTerms = Omit<TermsAtomicHistorySnapshot, "terms"> & {
  terms: {
    term: OrderTermUpdate
    isOutlier: boolean
    outlierDate: Date | null
  }
} & {
  liveBetween: null | Interval<Date>
}

export const comprehensiveHistoryOfOrderTerms = (wrapped: Order) => {
  const updates = wrapped.allTermUpdatesWithOutlierMark()
  const allStructures = updates.map((upd) => upd.update.structureTerms())
  const uniqueStructures = _.uniqWith(allStructures, (x, y) => _.isEqual(x, y))
  const allDates = updates.map((upd) => upd.update.date().valueOf())
  const uniqueDates = _.uniq(allDates)

  // this is a table of all term update dates and all terms
  const allTermsForAllDates = uniqueStructures.flatMap((structure) =>
    uniqueDates.map((dateValue) => {
      const term = updates.find(
        (upd) =>
          upd.update.date().valueOf() === dateValue &&
          _.isEqual(upd.update.structureTerms(), structure)
      )
      return {
        date: new Date(dateValue),
        structureTerms: structure,
        structureId: serializedStructureTerm(structure),
        updateType: term === null ? "cancelled" : "updated",
        terms: term
          ? {
              term: term.update,
              isOutlier: term.isOutlier,
              outlierDate: term.outlierDate,
            }
          : null,
      } satisfies TermsAtomicHistorySnapshot
    })
  )
  const allTermsByStructure = UnsafeRec.entries(
    _.groupBy(allTermsForAllDates, (x) => x.structureId)
  ).map(
    ([structureId, terms]) =>
      [structureId, _.orderBy(terms, (t) => t.date)] satisfies [
        string,
        TermsAtomicHistorySnapshot[]
      ]
  )
  return allTermsByStructure satisfies [string, TermsAtomicHistorySnapshot[]][]
}

export const wasOrderTermUpdated = (order: Order, lookbackHours: number) =>
  order.latestTermUpdateWithChanges().match(
    (t) => t.date().getTime() > subHours(new Date(), lookbackHours).getTime(),
    () => false
  )
