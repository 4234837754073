/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import LoadingCell from "@components/Watchlist/WatchlistPage/LoadingCell"
import { Popover } from "@stories/components/Antd"
import Typography, { Color, Weight } from "@stories/components/Typography/Typography"
import { CommentFilledIcon } from "@stories/icons/CommentFilledIcon"
import { CommentIcon } from "@stories/icons/CommentIcon"
import { CommentPlusIcon } from "@stories/icons/CommentPlusIcon"
import { CRMNoteSource, DealCRMNote } from "common/model/DealCRM/DealCRMNote"
import { Loading, deprecatedIsLoaded, isLoading } from "common/utils/Loading"
import { identity } from "common/utils/fp/Function"
import React, { useEffect, useRef } from "react"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { classNames } from "src/utils/classNames"
import { useQuerySnapshot } from "src/utils/hooks/queries/useQuerySnapshot"
import { AddNoteInput } from "../Components/Notes/AddNoteInput"
import { NoteDisplay } from "../Components/Notes/NoteDisplay"
import { getAllNotesForSourceId } from "src/firebase/crmNotes"

interface NotesColumnProps {
  accountId: string
  source: CRMNoteSource
}

export const NotesColumn: React.FC<NotesColumnProps> = ({ accountId, source }) => {
  const [focused, setFocused] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const notes: Loading<DealCRMNote[]> = useQuerySnapshot(
    (db) => getAllNotesForSourceId({ db, sourceId: source.sourceId, accountId }),
    identity,
    [accountId, source]
  )

  if (isLoading(notes)) return <LoadingCell width={30} />

  return (
    <Popover
      color="white"
      trigger={focused && open ? "click" : "hover"}
      open={open}
      onOpenChange={setOpen}
      destroyTooltipOnHide
      mouseLeaveDelay={0.3}
      mouseEnterDelay={0.3}
      content={
        <div onFocus={() => setFocused(true)} onBlur={() => setFocused(false)}>
          <ParticipantNotesHoverTooltipBody source={source} notes={notes} />
        </div>
      }
    >
      <div className="text-xs flex gap-2 items-center flex-nowrap self-center justify-center cursor-pointer">
        {isLoading(notes) || !notes || notes.length === 0 ? (
          <CommentPlusIcon color={Color.Link} />
        ) : (
          <CommentFilledIcon color={Color.Link} />
        )}
      </div>
    </Popover>
  )
}

const AddNewDealParticipantNote = ({
  source,
  afterSave,
}: {
  source: CRMNoteSource
  afterSave?: () => void
}) => {
  const user = useLoggedInUser()

  return <AddNoteInput source={source} user={user} afterSave={afterSave} />
}

const ParticipantNotesHoverTooltipBody = ({
  source,
  notes,
}: {
  source: CRMNoteSource
  notes: Loading<DealCRMNote[]>
}) => {
  const notesContainer = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (notesContainer && notesContainer.current && notes && notes.length > 1) {
      // scroll to the bottom of notes on load or on new note added. Run after a timeout to allow replies to render
      setTimeout(scrollToBottom, 200)
    }
  }, [notesContainer, notes])

  const scrollToBottom = () => {
    const htmlElem = document.getElementById("notes-container-parent")
    htmlElem?.scrollBy({ top: htmlElem.scrollHeight, behavior: "smooth" })
  }

  const afterNoteCreated = () => {
    setTimeout(scrollToBottom, 200)
  }

  return deprecatedIsLoaded(notes) ? (
    <div className="text-neutral-800 w-100 flex flex-col gap-2">
      <div className="flex gap-2 items-center">
        <CommentIcon />
        <Typography text="Notes" weight={Weight.Semibold} />
      </div>
      {notes.length ? (
        <div
          className={classNames(
            "max-h-72 overflow-y-auto overscroll-contain",
            notes.length > 3 ? "border-t border-b" : ""
          )}
          id="notes-container-parent"
        >
          <div className="flex flex-col gap-2" ref={notesContainer} id="notes-container">
            {(notes || [])
              .sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1))
              .map((note) => (
                <NoteDisplay
                  key={note.id}
                  note={note}
                  displayPage="deal"
                  afterReplyAdded={afterNoteCreated}
                />
              ))}
          </div>
        </div>
      ) : null}
      <AddNewDealParticipantNote source={source} afterSave={afterNoteCreated} />
    </div>
  ) : (
    <div>Loading...</div>
  )
}
