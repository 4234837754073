import * as z from "zod"

export const coiFilesDetails = z.object({
  coiMeta: z.object({ date: z.string().or(z.null()) }),
  numberOfPages: z.number().or(z.null()),
  pdfLink: z.string().or(z.null()),
  htmlLink: z.string().or(z.null()),
})

export type CoiFilesDetails = z.infer<typeof coiFilesDetails>
