import moment from "moment"
import { collectionReferences, collections } from "../../../firestore/Collections"
import { FirebaseCommon } from "../../../firestore/Interface"
import { AccountIdFields } from "../../Account"
import { firestoreConverter } from "../../FirestoreConverter"
import { User, UserIdFields } from "../../User"
import { ConnectThread, HydratedConnectThread } from "../ConnectThread"
import { ConnectMessage, UnsentConnectMessage } from "../Message"
import { ConnectMessageAuthor } from "../Message/Author"
import { _StructuredConnectMessage } from "../MessageTypes/StructuredMessage"
import {
  saveDocumentOverlay,
  SaveDocumentOverlayProps,
  sendMessageToThread,
} from "./messagePrimitives"
import { updateOrCreateParticipantData } from "./users"
import { ThreadType } from "../ThreadTypes/ThreadType"
import { OrderLookupFields } from "../../Order/Models/Internal"

export const setUserLastMessageDateToNow =
  (db: FirebaseCommon.LimitedDB) =>
  async ({
    threadId,
    user,
    account,
  }: {
    threadId: string
    user: UserIdFields
    account: AccountIdFields
  }) => {
    const ref = db
      .collection(collections.messageThreads)
      .withConverter<ConnectThread>(firestoreConverter<ConnectThread>())
      .doc(threadId)
    const thread = await ref.get().then((d) => d.data())
    if (thread) {
      const updatedDate = new Date()
      const update = {
        ...updateOrCreateParticipantData(user, account, thread, {
          lastReadDate: updatedDate,
          lastMessageDate: updatedDate,
        }),
        _mostRecentMessageDate: updatedDate,
        _mostRecentReadDate: updatedDate,
      }
      return ref.update(update)
    } else {
      return Promise.reject(new Error(`Could not find thread with id ${threadId}`))
    }
  }
export const setUserLastReadDate =
  (db: FirebaseCommon.LimitedDB) =>
  async ({
    threadId,
    user,
    account,
  }: {
    threadId: string
    user: User
    account: AccountIdFields
  }) => {
    const ref = db
      .collection(collections.messageThreads)
      .withConverter<ConnectThread>(firestoreConverter<ConnectThread>())
      .doc(threadId)
    const thread = await ref.get().then((d) => d.data())
    if (thread) {
      return ref.update({
        ...updateOrCreateParticipantData(user, account, thread, { lastReadDate: new Date() }),
        _mostRecentReadDate: new Date(),
      })
    } else {
      return Promise.reject(new Error(`Could not find thread with id ${threadId}`))
    }
  }
export const setUserToThread =
  (db: FirebaseCommon.LimitedDB) =>
  async ({
    threadId,
    user,
    account,
  }: {
    threadId: string
    user: User
    account: AccountIdFields
  }) => {
    const ref = db
      .collection(collections.messageThreads)
      .withConverter<ConnectThread>(firestoreConverter<ConnectThread>())
      .doc(threadId)
    const thread = await ref.get().then((d) => d.data())
    if (thread) {
      return ref.update(updateOrCreateParticipantData(user, account, thread, {}))
    } else {
      return Promise.reject(new Error(`Could not find thread with id ${threadId}`))
    }
  }

export const fetchMessagesForAccount =
  (db: FirebaseCommon.LimitedDB) =>
  async (threadId: string, account: AccountIdFields): Promise<ConnectMessage<ThreadType>[]> => {
    const thread =
      (await collectionReferences.connectThreads(db).doc(threadId).get()).data() ?? null
    const threadMessages = await collectionReferences
      .connectThreadSubcollections(threadId)
      .messages(db)
      .get()
      .then((docs) => docs.docs.map((doc) => doc.data()))
      .then((docs) => docs.flatMap((doc) => (doc ? [doc] : [])))
    const parentThreadId = thread?.participatingAccounts?.[account.id]?.parentThreadId
    const result = (
      parentThreadId ? await fetchMessagesForAccount(db)(parentThreadId, account) : []
    ).concat(threadMessages)
    return result
  }

export const allAccountThreads = (db: FirebaseCommon.LimitedDB) => (account: AccountIdFields) =>
  collectionReferences.connectThreads(db).orderBy(`participatingAccounts.${account.id}`, "desc")

// will probably have to be split into frontend and backend versions for update streaming
export const hydrateConnectThread =
  (db: FirebaseCommon.DB) =>
  (thread: ConnectThread, account?: AccountIdFields): Promise<HydratedConnectThread> => {
    const threadRef = db.collection(collections.messageThreads).doc(thread.id)
    const messagesRef = threadRef
      .collection(collections.messageThreadSubcollections.messages)
      .withConverter<ConnectMessage>(firestoreConverter<ConnectMessage>())
    return messagesRef.get().then((messages) => ({
      thread,
      messages: messages.docs
        .map((doc) => doc.data())
        .flatMap((x) => (x ? [x] : []))
        .sort((a, b) => (moment(b.date).isBefore(moment(a.date)) ? 1 : -1)),
      sendMessage: <T extends ThreadType>(message: UnsentConnectMessage<T>) =>
        sendMessageToThread(db)(thread, message),
      saveDocumentOverlay: (params: Omit<SaveDocumentOverlayProps, "thread">) =>
        saveDocumentOverlay(db)({
          thread,
          ...params,
        }),
    }))
  }

export type AnchorThreadCreationFormData = {
  customContextParagraph: string | undefined
  shouldIncludeIsOrderLiveStructuredQuestion: boolean
  followUpQuestionIfOrderIsLive: string | undefined
  recipients: string[]
  orderId: OrderLookupFields
}

export const hydrateAnchorThreadWithFormData =
  (db: FirebaseCommon.DB) =>
  async (
    formData: AnchorThreadCreationFormData,
    thread: ConnectThread,
    author: ConnectMessageAuthor
  ) => {
    const {
      shouldIncludeIsOrderLiveStructuredQuestion,
      orderId,
      followUpQuestionIfOrderIsLive,
      customContextParagraph,
    } = formData

    if (customContextParagraph) {
      await sendMessageToThread(db)(
        thread,
        {
          author,
          tag: "plaintext",
          body: customContextParagraph,
          source: {
            tag: "chat",
          },
        },
        false
      )
    }

    if (shouldIncludeIsOrderLiveStructuredQuestion) {
      await sendMessageToThread(db)(
        thread,
        {
          tag: "stillLive",
          author,
          payload: { orderId },
        },
        false
      )
    }

    const hasFollowUp = !!followUpQuestionIfOrderIsLive

    if (hasFollowUp) {
      await sendMessageToThread(db)(
        thread,
        {
          author,
          tag: "plaintext",
          body: followUpQuestionIfOrderIsLive,
          source: {
            tag: "chat",
          },
        },
        false
      )
    }
  }
