import React, { useState } from "react"
import { Button } from "@stories/components/Button/Button"
import { makeAPIPostRequest } from "common/api/Request"
import { GmailAPI } from "common/api/GmailAPI"
import { useLoggedInUserAuthToken } from "src/firebase/AuthUtils"
import { handleConsoleError } from "src/utils/Tracking"
import { isLoaded } from "common/utils/Loading"
import { config } from "src/config"
import { ParsedEmailMessage } from "common/model/Google/ParsedEmailMessage"
import { TextArea } from "@components/inputs/TextAreaField"
import { notification } from "@stories/components/Antd"
import { useConnectGmail } from "src/pages/Email/useConnectEmail"

interface EmailReplyProps {
  email: ParsedEmailMessage
  onReplySent: (message: string) => void
}

const EmailReply = ({ email, onReplySent }: EmailReplyProps) => {
  const [isReplying, setIsReplying] = useState(false)
  const [message, setMessage] = useState("")
  const userToken = useLoggedInUserAuthToken()
  const { isGmailConnected } = useConnectGmail()
  const [sendingRequest, setSendingRequest] = useState(false)

  const handleSendReply = async () => {
    if (!isLoaded(userToken)) {
      handleConsoleError("User token is not loaded")
      return
    }

    setSendingRequest(true)

    try {
      await makeAPIPostRequest(
        `${config.firebase.cloudApiUrl}/gmail/`,
        GmailAPI,
        ["send-reply"],
        userToken
      )({
        replyBodyPlaintext: message,
        emailDocId: email.id,
      })
      notification.success({
        message: "Email sent",
        description: "Your email reply has been sent.",
        placement: "top",
      })
      setIsReplying(false)
      setMessage("")
      onReplySent(message)
      setSendingRequest(false)
    } catch (error) {
      notification.error({
        message: "Error sending email",
        description: "An error occurred while sending your email reply.",
        placement: "top",
      })
      handleConsoleError(error)
      setSendingRequest(false)
    }
  }

  if (!isGmailConnected) {
    return null
  }

  return (
    <div className="w-full flex p-3">
      {isReplying ? (
        <div>
          <TextArea
            className="w-100"
            fullWidth
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Write your reply here..."
            name=""
          />
          <div className="flex gap-2 mt-2">
            <Button
              variant="secondary"
              label="Send"
              onClick={handleSendReply}
              size="small"
              isLoading={sendingRequest}
            />
            <Button
              label="Cancel"
              variant="secondary"
              onClick={() => setIsReplying(false)}
              size="small"
            />
          </div>
        </div>
      ) : (
        <Button
          size="small"
          variant="secondary"
          label="Reply"
          onClick={() => setIsReplying(true)}
        />
      )}
    </div>
  )
}

export default EmailReply
