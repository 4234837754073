import { Button } from "@stories/components/Button/Button"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { ChevronRightIcon } from "@stories/icons/ChevronRightIcon"

export const CreateContactTagCard = ({
  label,
  subLabel,
  onClick,
  icon,
  disabled,
}: {
  label: string
  subLabel?: React.ReactNode
  onClick: () => void
  icon?: React.ReactNode
  disabled?: boolean
}) => (
  <div
    className={`p-3 rounded-md border flex items-center justify-between ${
      disabled ? "" : "cursor-pointer"
    }`}
    onClick={disabled ? undefined : onClick}
  >
    <div className="flex gap-2 items-center">
      {icon}
      <div className="flex flex-col items-start">
        <Typography
          text={label}
          color={disabled ? Color.Subtitle : Color.Black}
          size={Size.Small}
          weight={Weight.Semibold}
        />
        <Typography
          text={subLabel}
          color={disabled ? Color.Subtitle : Color.Black}
          size={Size.XSmall}
        />
      </div>
    </div>
    <Button leftIcon={<ChevronRightIcon />} variant="secondary" isDisabled={disabled} />
  </div>
)
