import { identity } from "../Function"

/** A function from `X & L` to `X & R` for every X */
export class RecFunc<L, R> {
  
  constructor(readonly run: <Rest>(l: Rest & L) => Rest & R) {}

  static identity = new RecFunc(identity)

  alongside = <X, Y>(x: RecFunc<X, Y>) =>
    new RecFunc<L & X, R & Y>((a) => ({ ...this.run(a), ...x.run(a) }))

  compose = <R2>(x: RecFunc<R, R2>) =>
    new RecFunc<L, R2>((a) => x.run(this.run(a)))
}
