import { UserInvitedBroker, userInvitedBrokerSchema } from "common/model/UserInvitedBroker"

import { Button } from "@stories/components/Button/Button"
import Typography, { Color, Size } from "@stories/components/Typography/Typography"
import { Input } from "@stories/components/Inputs/Input/Input"
import { useState } from "react"
import { Either, Left, Right } from "common/containers/Either"
import { ZodError } from "zod"

export type InvitedBroker = Omit<UserInvitedBroker, "id">
const validateForm = (values: InvitedBroker): Either<ZodError<InvitedBroker>, InvitedBroker> => {
  const result = userInvitedBrokerSchema.safeParse(values)
  return result.success ? Right(result.data) : Left(result.error)
}

const InviteLinkedBrokerForm: React.FC<{ addInvitedBroker: (b: InvitedBroker) => void }> = ({
  addInvitedBroker,
}) => {
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [invalidEmail, setInvalidEmail] = useState(false)

  const validBroker = validateForm({ fullName, email })
  const invalidName =
    fullName.length > 0
      ? validBroker?.match(
          (err) => !!err.formErrors.fieldErrors.fullName,
          () => false
        )
      : false

  const validateEmail = (e: string) =>
    setInvalidEmail(
      e.length > 0
        ? validBroker?.match(
            (err) => !!err.formErrors.fieldErrors.email,
            () => false
          )
        : false
    )

  return (
    <>
      <div className="grid md:grid-cols-2 gap-4">
        <Input
          label="Name"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          hintText={invalidName ? "Name is required" : ""}
          isDanger={invalidName}
        />
        <Input
          label="Email"
          value={email}
          onBlur={(e) => validateEmail(e.target.value)}
          onChange={(e) => setEmail(e.target.value)}
          hintText={invalidEmail ? "Email is required" : ""}
          isDanger={invalidEmail}
        />
      </div>
      <Button
        label="Add"
        variant="secondary"
        onClick={() => {
          validBroker?.match(
            () => null,
            (b) => {
              addInvitedBroker(b)
              setFullName("")
              setEmail("")
            }
          )
        }}
        isDisabled={!validBroker || validBroker.isLeft()}
        isFullWidth
      />
    </>
  )
}

const InviteLinkedBroker: React.FC<{ addInvitedBroker: (b: InvitedBroker) => void }> = ({
  addInvitedBroker,
}) => (
  <div className="flex flex-col space-y-4">
    <div className="flex space-x-2 items-center">
      <div className="flex-1 border h-0" />
      <Typography text="Invite Broker" size={Size.Small} color={Color.Subtitle} />
      <div className="flex-1 border h-0" />
    </div>
    <Typography
      text="If you can’t find your broker, you can invite your broker to Caplight below."
      size={Size.Small}
    />
    <InviteLinkedBrokerForm addInvitedBroker={addInvitedBroker} />
  </div>
)

export default InviteLinkedBroker
