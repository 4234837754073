import HighchartsReact from "highcharts-react-official"
import Highcharts from "highcharts"
import { userEventTypes } from "common/model/UserEvent"
import moment from "moment-timezone"
import { UserEventCounter } from "common/model/Analytics/EventCounter"
import { User, UserFunctions } from "common/model/User"
import { useMemo, useState } from "react"
import Typography, { Size, Weight } from "@stories/components/Typography/Typography"

interface UsersEngagementChartProps {
  userEngagementCounts: UserEventCounter[]
  accountUsers: User[]
}

const NUM_WEEKS = 12

export const UsersEngagementChart: React.FC<UsersEngagementChartProps> = ({
  userEngagementCounts,
  accountUsers,
}) => {
  // If user is selected, show events split by event type for that specific user. Otherwise aggregate events and show a stacked bar chart for each user
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined)

  const chartWeeks = Array(NUM_WEEKS)
    .fill(0)
    .map((_, i) =>
      moment()
        .tz("America/Los_Angeles")
        .startOf("week")
        .subtract(NUM_WEEKS - i - 1, "weeks")
        .toDate()
        .getTime()
    )

  const getChartSeries = useMemo(() => {
    if (selectedUser) {
      const userEvents: UserEventCounter[] = userEngagementCounts.filter(
        (eventCounts) => eventCounts.user.id === selectedUser.id
      )

      return userEventTypes.map((event) => ({
        name: event,
        data: chartWeeks.map((week) => {
          const weekEvents = userEvents.find(
            (eventCounts) => eventCounts.periodDate.getTime() === week
          )
          const eventCount = weekEvents?.eventCounts[event] || 0
          return [week, eventCount]
        }),
      }))
    } else {
      // Aggregate event counts for each user and split by user
      return accountUsers.map((user) => {
        const userEvents = userEngagementCounts.filter(
          (eventCounts) => eventCounts.user.id === user.id
        )

        return {
          name: UserFunctions.name(user),
          data: chartWeeks.map((week) => {
            const weekEvents = userEvents.find(
              (eventCounts) => eventCounts.periodDate.getTime() === week
            )
            const eventCountSum = Object.values(weekEvents?.eventCounts || {}).reduce(
              (sum, count) => sum + count,
              0
            )
            return [week, eventCountSum]
          }),
        }
      })
    }
  }, [selectedUser, userEngagementCounts, accountUsers, chartWeeks])

  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: undefined,
    },
    subtitle: {
      text: "Past 12 Weeks",
    },
    xAxis: {
      type: "datetime",
      labels: {
        format: "{value:%b %e}",
      },
      min: moment()
        .tz("America/Los_Angeles")
        .startOf("week")
        .subtract(NUM_WEEKS - 1, "weeks")
        .toDate()
        .getTime(),
      max: moment().tz("America/Los_Angeles").startOf("week").toDate().getTime(),
      tickInterval: 7 * 24 * 3600 * 1000, // 1 week in milliseconds
    },
    yAxis: {
      min: 0,
      title: {
        text: "Total Events",
      },
    },
    plotOptions: {
      column: {
        stacking: "normal",
      },
    },
    series: getChartSeries,
  }

  return (
    <div>
      <div>
        <Typography size={Size.Large} weight={Weight.Bold} text="User Engagement" />
      </div>
      {/* user picker select input */}
      <div className="flex gap-4 items-center">
        <label htmlFor="user-picker" className="whitespace-nowrap">
          Select User
        </label>
        <select
          id="user-picker"
          className="block w-60 mt-1 border-neutral-300 rounded-md shadow-sm focus:ring-neutral-500 focus:border-neutral-500 sm:text-sm"
          value={selectedUser?.id}
          onChange={(e) => {
            const selectedUserId = e.target.value
            setSelectedUser(accountUsers.find((user) => user.id === selectedUserId) || undefined)
          }}
        >
          <option value="">All Users</option>
          {accountUsers.map((user) => (
            <option key={user.id} value={user.id}>
              {UserFunctions.name(user)}
            </option>
          ))}
        </select>
      </div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  )
}
