import { Loading } from "common/utils/Loading"
import { Query } from "src/firebase/Firebase/utils"
import { identity } from "common/utils/fp/Function"
import { useFirebaseAdmin, useFirebaseReader } from "../firebase/Context"
import Firebase from "../firebase/Firebase"
import { usePromisedState } from "./hooks/state/usePromisedState"
import { useQuerySnapshot } from "./hooks/queries/useQuerySnapshot"

export function useDbState<R>(
  f: (db: Omit<Firebase, "adminDb" | "writerDb">) => Promise<Loading<R>>,
  dependencies: unknown[],
  useLoader?: boolean
): Loading<R> {
  const firebase = useFirebaseReader()
  return usePromisedState(f, firebase, dependencies, useLoader)
}

export function useAdminDb<R>(
  f: (db: Firebase) => Promise<Loading<R>>,
  dependencies: unknown[]
): Loading<R> {
  const firebase = useFirebaseAdmin()
  return usePromisedState(f, firebase, dependencies)
}

export const useSimpleQuerySnapshot = <R>(
  f: (db: Omit<Firebase, "adminDb">) => Query<R> | null,
  dependencies: unknown[]
) => useQuerySnapshot(f, identity, dependencies)
