import { Loading, isLoading, mapLoading } from "common/utils/Loading"
import {
  GenericNotification,
  notificationLinksToHiddenDocument,
} from "common/model/Notifications/GenericNotification"
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react"
import { useFirebaseReader } from "src/firebase/Context"
import { useCurrentUser } from "../currentUser/useCurrentUser"
import moment from "moment"
import { hasFullDataAccess } from "@components/auth/DataAccess"
import { useAccountSnapshot } from "../AccountSnapshot/useAccountSnapshot"

interface NotificationContext {
  notifications: Loading<GenericNotification[]>
  unreadCount: Loading<number>
}

export const NotificationsContext = createContext<NotificationContext>({
  notifications: "loading",
  unreadCount: "loading",
})

export const NotificationsProvider = ({ children }: { children: ReactNode }) => {
  const firebase = useFirebaseReader()
  const user = useCurrentUser()
  const [loadingNotifications, setNotifications] =
    useState<Loading<GenericNotification[]>>("loading")

  const { accountSnapshot } = useAccountSnapshot()

  useEffect(() => {
    if (isLoading(user) || !user) return () => {}

    const canViewTrades = hasFullDataAccess(user.user.account)

    const unsubscribe = firebase
      .getNotificationsAfterDate(user.user.id, moment().subtract(30, "days").toDate())
      .onSnapshot((snapshot) => {
        const docs = snapshot.docs.map((doc) => doc.data())
        setNotifications(
          docs.filter(
            (n) =>
              !n.isDeleted &&
              (canViewTrades || n.notificationType !== "new_trade") &&
              !notificationLinksToHiddenDocument(n, user.user, accountSnapshot)
          )
        )
      })

    return unsubscribe
  }, [user, firebase, accountSnapshot])

  const loadingUnreadCount = mapLoading<GenericNotification[], number>(
    (notifications) => notifications.filter((n) => n.readStatus === "unread").length
  )(loadingNotifications)

  const memomizedContext = useMemo(
    () => ({
      notifications: loadingNotifications,
      unreadCount: loadingUnreadCount,
    }),
    [loadingNotifications, loadingUnreadCount]
  )

  return (
    <NotificationsContext.Provider value={memomizedContext}>
      {children}
    </NotificationsContext.Provider>
  )
}

export const useNotifications = () => useContext(NotificationsContext)
