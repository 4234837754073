// @ts-ignore
import { AuthFailurePage } from "@components/auth/AuthFailurePage"
import { ErrorBanner } from "@components/errors/ErrorBanner"
import SkeletonLoader from "@components/icons/SkeletonLoader"
import Spinner from "@components/icons/Spinner"
import * as Sentry from "@sentry/react"
import {
  sharedOrderListRouteTemplate,
  sharedOrderRouteTemplate,
} from "common/model/SharedOrder/SharedOrder"
import { isLoading } from "common/utils/Loading"
import { Suspense } from "react"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import "./App.css"
import { Routes as AppRoutes } from "./Routes/Routes"
import { authRoutes } from "./Routes/auth/authRoutes"
import { userHomepage } from "./Routes/userHomepage"
import GlobalRedirect from "./components/GlobalRedirect"
import InactivityReloader from "./components/InactivityReloader/InactivityReloader"
import { authenticatedRoute } from "./components/auth/AuthenticatedRoute"
import { AppLayout } from "./components/layout/main-layout/Layout"
import { useAccessControl } from "./providers/AccessControl/AccessControlProvider"
import AppProviders from "./providers/AppProviders"
import { useCurrentUser } from "./providers/currentUser/useCurrentUser"
import { useEnabledFeatureFlags } from "./providers/featureFlags/useFeatureFlags"
import { customLazyLoad } from "./utils/CustomLazyLoad"
import { namedImport } from "./utils/importUtils"

const WidgetEmbed = customLazyLoad(() => import("./pages/WidgetEmbeds/components/WidgetEmbed"))
const SharedOrderDetails = customLazyLoad(() => import("./pages/Shared/SharedOrderPage"))
const SharedOrderList = customLazyLoad(
  () => import("./pages/Shared/SharedOrderTable/AllSharedOrdersTable")
)
const AuthLinkLanding = customLazyLoad(() =>
  import("./pages/AuthLinkLanding").then(namedImport("AuthLinkLanding"))
)
const ForgotPasswordForm = customLazyLoad(() =>
  import("./pages/ForgotPassword").then(namedImport("ForgotPasswordForm"))
)
const ResetPasswordForm = customLazyLoad(() =>
  import("./pages/ResetPassword").then(namedImport("ResetPasswordForm"))
)

const TradingRegistrationPage = customLazyLoad(() =>
  import("./pages/TradeRegistration/TradeRegistrationPage").then(
    namedImport("TradingRegistrationPage")
  )
)
const SignInForm = customLazyLoad(() => import("./pages/SignIn").then(namedImport("SignInForm")))

const OnboardingPage = customLazyLoad(() => import("./pages/Onboarding/OnboardingPage"))

const SignupForm = customLazyLoad(() =>
  import("./pages/Onboarding/steps/signup/SignupForm").then(namedImport("SignupForm"))
)

const CartaShareholderVerificationLandingPage = customLazyLoad(() =>
  import("./pages/Holdings/carta/CartaShareholderVerificationLandingPage").then(
    namedImport("CartaShareholderVerificationLandingPage")
  )
)

const HomepageRedirect = () => {
  const user = useCurrentUser()
  const enabledFeatureFlags = useEnabledFeatureFlags()
  const { currentAccessTier } = useAccessControl()
  if (isLoading(user) || isLoading(enabledFeatureFlags)) return <Spinner size="lg" />
  if (!user) return <Navigate to={AppRoutes.login} />
  return <Navigate to={userHomepage(user.user, currentAccessTier, enabledFeatureFlags)} />
}

const App = () => (
  <BrowserRouter>
    <AppProviders>
      <Suspense
        fallback={
          <div className="mt-8">
            <Spinner size="lg" />
          </div>
        }
      >
        <InactivityReloader />
        <Routes>
          <Route path={AppRoutes.login} element={<SignInForm />} />
          <Route path="/auth/:authCode" element={<AuthLinkLanding />} />
          <Route path={AppRoutes.onboarding} element={<OnboardingPage />} />
          <Route path={AppRoutes.signup} element={<SignupForm />} />
          <Route
            path={AppRoutes.legacyOnboarding}
            element={<Navigate to={AppRoutes.onboarding} replace />}
          />
          <Route path={AppRoutes.forgotPassword} element={<ForgotPasswordForm />} />
          <Route path={AppRoutes.resetPassword} element={<ResetPasswordForm />} />
          <Route path={AppRoutes.widgetEmbed} element={<WidgetEmbed />} />
          <Route
            path="/*"
            element={
              <>
                <GlobalRedirect />
                <AppLayout>
                  <Suspense
                    fallback={
                      <div className="mt-16 md:mt-2 p-4">
                        <SkeletonLoader numRows={3} />
                      </div>
                    }
                  >
                    <Sentry.ErrorBoundary fallback={ErrorBanner}>
                      <Routes>
                        <Route path={sharedOrderRouteTemplate} element={<SharedOrderDetails />} />
                        <Route path={sharedOrderListRouteTemplate} element={<SharedOrderList />} />
                        <Route
                          path={AppRoutes.shareholders.cartaShareholderVerification}
                          element={<CartaShareholderVerificationLandingPage />}
                        />
                        <Route path="/trading-registration" element={<TradingRegistrationPage />} />
                        {authRoutes.map(authenticatedRoute)}
                        <Route
                          path={AppRoutes.insufficientPermissions}
                          element={<AuthFailurePage />}
                        />
                        <Route path="*" element={<HomepageRedirect />} />
                      </Routes>
                    </Sentry.ErrorBoundary>
                  </Suspense>
                </AppLayout>
              </>
            }
          />
        </Routes>
      </Suspense>
    </AppProviders>
  </BrowserRouter>
)

export default App
