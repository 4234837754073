import { CheckboxWithLabel } from "@components/inputs/checkbox/Checkbox"
import Callout from "@components/layout/Callout"
import { firestoreConverter } from "@model/FirestoreConverter"
import { Select } from "@stories/components/Antd/Select/Select"
import { Button } from "@stories/components/Button/Button"
import { Input } from "@stories/components/Inputs/Input/Input"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { BrokerIcon } from "@stories/icons/BrokerIcon"
import { FirmIcon } from "@stories/icons/FirmIcon"
import { PlusIcon } from "@stories/icons/PlusIcon"
import { UserIcon } from "@stories/icons/UserIcon"
import { firmTypeOptions, isIntermediaryAccount } from "common/model/Account"
import { DealCRMBrokerContact } from "common/model/DealCRM/DealCRMBrokerContact"
import { DealCRMContact, isDealCRMFirmContact } from "common/model/DealCRM/DealCRMContact"
import { isBrokerage } from "common/model/DealCRM/DealCRMFirmContact"
import { DealCRMIndividualContact } from "common/model/DealCRM/DealCRMIndividualContact"
import {
  IndividualContactClassification,
  individualContactClassificationOptions,
} from "common/model/DealCRM/IndividualContactClassification"
import { deprecatedIsLoaded, isLoaded } from "common/utils/Loading"
import { concatPartialRecords } from "common/utils/RecordUtils"
import { ReactElement, useEffect, useState } from "react"
import { useFirebaseReader } from "src/firebase/Context"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import { createDealCRMContact } from "src/firebase/crm"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import { handleConsoleError, trackEventInFirestoreAndHeap } from "src/utils/Tracking"
import { CRMContactName } from "../ContactDetailsDrawer/CRMContactName"
import { ContactFirmSelector } from "../ContactDetailsDrawer/ContactFirmSelector"
import { CreateContactTagCard } from "./CreateContactTagCard"
import { SimilarContactWarning } from "./SimilarContactWarning"
import {
  defaultBrokerContact,
  defaultFirmContact,
  defaultIndividualContact,
  validateNewDealCRMContact,
} from "./createContactHelpers"
import PhoneInput from "@stories/components/Inputs/PhoneInput/PhoneInput"
import { APIEndpoints, runAPIQuery } from "src/firebase/API"

const individualContactClassificationLabels: Record<
  IndividualContactClassificationValues,
  string | ReactElement
> = {
  firm_contact_only: (
    <span>
      Fund/Org Contact <i className="text-neutral-800">(does not trade as an individual)</i>
    </span>
  ),
  firm_contact_and_investor: "Fund/Org Contact & Individual Investor/Shareholder",
  investor: "Individual Investor",
  shareholder: "Individual Shareholder",
  broker: "Broker",
}

export const individualContactClassificationValues = [
  ...individualContactClassificationOptions,
  "broker",
]
export type IndividualContactClassificationValues =
  (typeof individualContactClassificationValues)[number]

export const individualClassificationSelectOptions: {
  value: IndividualContactClassificationValues
  label: string | ReactElement
}[] = individualContactClassificationValues.map((val) => ({
  value: val,
  label: individualContactClassificationLabels[val],
}))

interface CreateContactFormProps<C extends DealCRMContact | undefined> {
  afterCreate?: (c: DealCRMContact) => void
  onCancel?: () => void
  newContactValues: DealCRMContact | undefined
  setNewContactValues: (c: C | undefined) => void
  closeModal?: () => void
  sourceContact?: DealCRMContact
}

export const CreateContactForm = ({
  afterCreate,
  onCancel,
  newContactValues,
  setNewContactValues,
  closeModal,
  sourceContact,
}: CreateContactFormProps<DealCRMContact>) => {
  const [loading, setLoading] = useState(false)
  const db = useFirebaseReader()
  const firebase9 = useFirebase9()
  const user = useCurrentUser()

  // Requires user to change the form contact type when trying to create a broker from the 'individual form'
  const [isInvalidClassification, setIsInvalidClassification] = useState(false)

  // Track initial value of isBrokerage to prevent form structure from changing if a firm is classified as a 'broker'
  const [isBrokerageForm, setIsBrokerageForm] = useState<boolean | undefined>(
    newContactValues ? isBrokerage(newContactValues) : undefined
  )
  useEffect(() => {
    if (newContactValues && isBrokerageForm === undefined) {
      setIsBrokerageForm(isBrokerage(newContactValues))
    }
  }, [newContactValues, isBrokerageForm])

  const handleCreateContact = () => {
    if (newContactValues !== undefined) {
      validateNewDealCRMContact(newContactValues).match(
        (validContact) => {
          if (deprecatedIsLoaded(user)) {
            setLoading(true)
            createDealCRMContact({
              db,
              user: user.user,
              contact: { ...validContact, creationSource: "form" },
            })
              .then((d) =>
                d.withConverter<DealCRMContact>(firestoreConverter<DealCRMContact>()).get()
              )
              .then((d) => {
                const newContact = d.data()
                if (
                  (newContact?.tag === "broker" || newContact?.tag === "individual") &&
                  newContact.email &&
                  newContact?.userData?.[user.user.id] &&
                  newContact.userData[user.user.id].syncEnabled
                ) {
                  runAPIQuery(
                    APIEndpoints.syncContactEmails,
                    {
                      contactEmail: newContact.email,
                    },
                    user
                  ).catch(handleConsoleError)
                }
                if (newContact && afterCreate) {
                  afterCreate(newContact)
                }
              })
              .then(() =>
                trackEventInFirestoreAndHeap(firebase9, user.user, "crm-contact-created", {
                  source: "create-contact-form",
                })
              )
              .finally(() => {
                setLoading(false)
                closeModal?.()
              })
          }
        },
        () => {}
      )
    }
  }

  const updateContact = <T extends keyof C, C extends DealCRMContact>(
    updates: {
      contact: C
      contactKey: T
      contactValue: C[T]
    }[]
  ) => {
    const updateFields: Partial<Omit<DealCRMContact, "tag" | "updates">> = concatPartialRecords(
      updates.map((u) => ({ [u.contactKey]: u.contactValue }))
    )
    const newContact: DealCRMContact | undefined =
      newContactValues !== undefined ? { ...newContactValues, ...updateFields } : undefined
    setNewContactValues(newContact)
  }

  const filteredIndividualClassificationSelectOptions =
    individualClassificationSelectOptions.filter((option) => {
      if (sourceContact && isDealCRMFirmContact(sourceContact)) {
        if (["firm_contact_only", "firm_contact_and_investor"].includes(option.value)) {
          return true
        }
        return false
      }

      return true
    })

  if (!isLoaded(user)) {
    return null
  }

  return (
    <div className="flex flex-col gap-4 w-96" data-dd-privacy="mask">
      {!newContactValues && (
        <>
          <CreateContactTagCard
            label="Add New Individual"
            onClick={() =>
              setNewContactValues(defaultIndividualContact({ creationSource: "form" }))
            }
            icon={<UserIcon />}
          />
          <CreateContactTagCard
            label="Add New Firm"
            onClick={() => setNewContactValues(defaultFirmContact({ creationSource: "form" }))}
            icon={<FirmIcon />}
          />
          <CreateContactTagCard
            label="Add New Broker"
            onClick={() => setNewContactValues(defaultBrokerContact({ creationSource: "form" }))}
            icon={<BrokerIcon />}
          />
        </>
      )}

      {newContactValues && newContactValues.tag === "firm" && (
        <>
          <Input
            label={isBrokerageForm ? "Brokerage Name" : "Firm Name"}
            placeholder="Acme Inc."
            onChange={(e) =>
              updateContact([
                {
                  contact: newContactValues,
                  contactKey: "name",
                  contactValue: e.target.value ?? "",
                },
              ])
            }
            value={newContactValues.name}
          />
          {deprecatedIsLoaded(user) &&
          !isIntermediaryAccount(user.user.account) &&
          !isBrokerage(newContactValues) ? (
            <CheckboxWithLabel
              title={`Is this firm an existing LP of ${user.user.account.name}?`}
              id="isFundLPCheckbox"
              checked={newContactValues.isFundLP}
              onChange={(e) =>
                updateContact([
                  {
                    contact: newContactValues,
                    contactKey: "isFundLP",
                    contactValue: e.target.checked,
                  },
                ])
              }
            />
          ) : null}

          {!isBrokerageForm ? (
            <div className="flex flex-col gap-2">
              <Typography text="Classification" size={Size.Small} />
              <Select
                className="w-full min-w-36 flex items-center justify-center"
                value={newContactValues.classification}
                // eslint-disable-next-line rulesdir/mutating-array-methods
                options={firmTypeOptions
                  .slice()
                  .sort() // change to toSorted once polyfill situation is figured out
                  .map((val) => ({
                    value: val,
                    label: val,
                  }))}
                onChange={(contactValue) => {
                  updateContact([
                    {
                      contact: newContactValues,
                      contactKey: "classification",
                      contactValue,
                    },
                    contactValue === "Broker"
                      ? {
                          contact: newContactValues,
                          contactKey: "isBrokerage",
                          contactValue: true,
                        }
                      : {
                          contact: newContactValues,
                          contactKey: "isBrokerage",
                          contactValue: false,
                        },
                  ])
                }}
              />
            </div>
          ) : null}
        </>
      )}

      {newContactValues &&
        (newContactValues.tag === "individual" || newContactValues.tag === "broker") && (
          <>
            <div className="flex gap-4">
              <div className="flex-1">
                <Input
                  label="First Name"
                  placeholder="Jane"
                  onChange={(e) => {
                    updateContact([
                      {
                        contact: newContactValues,
                        contactKey: "firstName",
                        contactValue: e.target.value ?? "",
                      },
                      {
                        contact: newContactValues,
                        contactKey: "name",
                        contactValue: `${e.target.value ?? ""} ${newContactValues.lastName}`,
                      },
                    ])
                  }}
                  value={newContactValues.firstName}
                />
              </div>
              <div className="flex-1">
                <Input
                  label="Last Name"
                  placeholder="Doe"
                  onChange={(e) => {
                    updateContact([
                      {
                        contact: newContactValues,
                        contactKey: "lastName",
                        contactValue: e.target.value ?? "",
                      },
                      {
                        contact: newContactValues,
                        contactKey: "name",
                        contactValue: `${newContactValues.firstName} ${e.target.value ?? ""}`,
                      },
                    ])
                  }}
                  value={newContactValues.lastName}
                />
              </div>
            </div>
            {newContactValues.tag === "individual" && (
              <>
                <div>
                  <label>Classification</label>
                  <Select<IndividualContactClassification | "broker">
                    placeholder="Select an option"
                    style={{ width: "100%" }}
                    className="flex items-center justify-center"
                    value={isInvalidClassification ? "broker" : newContactValues.classification}
                    options={filteredIndividualClassificationSelectOptions}
                    onChange={(classification) => {
                      if (classification === "broker") {
                        setIsInvalidClassification(true)
                      } else {
                        setIsInvalidClassification(false)
                        updateContact([
                          {
                            contact: newContactValues,
                            contactKey: "classification",
                            contactValue: classification,
                          },
                          {
                            contact: newContactValues,
                            contactKey: "isTransactionalIndividual",
                            contactValue:
                              !!classification && classification !== "firm_contact_only",
                          },
                        ])
                      }
                    }}
                  />
                  {isInvalidClassification ? (
                    <Callout
                      color="red"
                      size="small"
                      body={
                        <Typography
                          text={
                            "To add a broker contact, please restart this form and select 'Add New Broker'."
                          }
                          weight={Weight.Semibold}
                          size={Size.XSmall}
                          color={Color.Dark}
                        />
                      }
                    />
                  ) : null}
                </div>
                {newContactValues.isTransactionalIndividual &&
                deprecatedIsLoaded(user) &&
                !isIntermediaryAccount(user.user.account) ? (
                  <CheckboxWithLabel
                    title={`Is this individual an existing LP of ${user.user.account.name}?`}
                    id="isFundLPIndividual"
                    checked={newContactValues.isFundLP}
                    onChange={(e) =>
                      updateContact([
                        {
                          contact: newContactValues,
                          contactKey: "isFundLP",
                          contactValue: e.target.checked,
                        },
                      ])
                    }
                  />
                ) : null}
              </>
            )}
            {!newContactValues.firm ? (
              <ContactFirmSelector<DealCRMBrokerContact | DealCRMIndividualContact>
                renderButton={(onClick) => (
                  <div onClick={onClick}>
                    <Input
                      label={`${
                        newContactValues.tag === "broker" ? "Brokerage" : "Fund / Organization"
                      }`}
                      isOptional
                      placeholder={`Select a ${
                        newContactValues.tag === "broker" ? "brokerage" : "fund/org"
                      }`}
                      value=""
                      readOnly
                    />
                  </div>
                )}
                contact={newContactValues}
                onFirmSelected={(firm) =>
                  updateContact([
                    {
                      contact: newContactValues,
                      contactKey: "firm",
                      contactValue: firm ?? null,
                    },
                  ])
                }
              />
            ) : (
              <div className="flex gap-4 items-center">
                <Typography
                  size={Size.Small}
                  text={newContactValues.tag === "broker" ? "Brokerage" : "Org/Fund"}
                />
                <CRMContactName contact={newContactValues.firm} />
              </div>
            )}
            {newContactValues.tag === "individual" && (
              <Input
                label="Title"
                isOptional
                placeholder="CEO, Partner, Investor..."
                value={newContactValues.title}
                onChange={(e) =>
                  updateContact([
                    {
                      contact: newContactValues,
                      contactKey: "title",
                      contactValue: e.target.value ?? undefined,
                    },
                  ])
                }
              />
            )}
            <div className="flex gap-4">
              <div className="flex-1">
                <Input
                  label="Email"
                  isOptional
                  placeholder="email@company.com"
                  value={newContactValues.email || undefined}
                  onChange={(e) =>
                    updateContact([
                      {
                        contact: newContactValues,
                        contactKey: "email",
                        contactValue: e.target.value ?? undefined,
                      },
                    ])
                  }
                />
              </div>
              <div className="flex-1">
                <PhoneInput
                  label="Phone"
                  isOptional
                  value={newContactValues.phone ?? undefined}
                  onChange={(phoneNumber) =>
                    updateContact([
                      {
                        contact: newContactValues,
                        contactKey: "phone",
                        contactValue: phoneNumber ?? null,
                      },
                    ])
                  }
                  heapName="crm-create-contact-phone-input"
                />
              </div>
            </div>
            {user.user.emailIntegration && (
              <div>
                <CheckboxWithLabel
                  tooltip="Check to enable outbound and inbound emails to sync into the Caplight CRM for this contact."
                  title="Enable Email Sync"
                  disabled={!newContactValues.email || !newContactValues.email.includes("@")}
                  id="enableEmailSync"
                  checked={!!newContactValues.userData?.[user.user.id]?.syncEnabled}
                  onChange={(e) =>
                    updateContact([
                      {
                        contact: newContactValues,
                        contactKey: "userData",
                        contactValue: {
                          [user.user.id]: {
                            ...(newContactValues?.userData?.[user.user.id] ?? {}),
                            syncEnabled: e.target.checked,
                          },
                        },
                      },
                    ])
                  }
                />
              </div>
            )}
          </>
        )}
      <div className="flex items-center gap-4">
        <Button
          label="Cancel"
          variant="secondary"
          isFullWidth
          onClick={() => {
            setNewContactValues(undefined)
            if (onCancel) {
              onCancel()
            }
          }}
        />
        <Button
          isFullWidth
          leftIcon={<PlusIcon />}
          label={
            newContactValues
              ? newContactValues.tag === "firm"
                ? isBrokerageForm
                  ? "Create Brokerage"
                  : "Create Firm"
                : "Create Contact"
              : "Create Contact"
          }
          onClick={handleCreateContact}
          isLoading={loading}
          isDisabled={
            isInvalidClassification ||
            loading ||
            newContactValues === undefined ||
            validateNewDealCRMContact(newContactValues).isNothing() ||
            (newContactValues.tag === "individual" &&
              (newContactValues.firstName === "" || newContactValues.lastName === ""))
          }
        />
      </div>
      {!loading &&
        newContactValues &&
        ((newContactValues.tag === "individual" && newContactValues.lastName?.length > 2) ||
          (newContactValues.tag === "firm" && newContactValues.name?.length > 2)) && (
          <SimilarContactWarning contact={newContactValues} beforeClickAction={onCancel} />
        )}
    </div>
  )
}
