import { filterMaybe, Just, Maybe, Nothing } from "common/containers/Maybe"
import { AlgoliaIndexedCompany } from "common/model/AlgoliaIndexedCompany"
import { SelectedCompany } from "common/model/Company"
import { useCallback, useEffect, useState } from "react"
import { useOperatingSystem } from "src/utils/hooks/useOperatingSystem"
import { searchCompanies } from "../../../services/Algolia"
import { handleConsoleError } from "../../../utils/Tracking"
import { CompanyAutocompleteProps } from "./CompanyAutocompleteProps"
import SearchSelect from "@stories/components/Inputs/SearchSelect/SearchSelect"
import { CompanySmallLogo } from "../CompanySmallLogo"

export const selectedCompanyFromAlgoliaCompany = (
  company: AlgoliaIndexedCompany
): Maybe<SelectedCompany> => {
  if (company.postgresCompanyId) {
    return Just({
      id: company.id || company.objectID,
      name: company.name,
      status: company.status,
      postgresCompanyId: company.postgresCompanyId,
      lastRoundPostMoneyValuation: company.fundingRoundsSummary?.lastKnownValuation?.valuation, // TODO should we use lastKnownValuation instead?
      lastRoundDate: company.fundingRoundsSummary?.lastRound?.date,
      logos: company.logos,
      airtableId: company.airtableId,
      pbid: company.pbid,
    })
  }
  return Nothing
}

const AlgoliaCompanySelection: React.FunctionComponent<
  React.PropsWithChildren<
    CompanyAutocompleteProps & {
      indexedCompanyFilter?: (c: AlgoliaIndexedCompany) => boolean
      placeholder?: string
    }
  >
> = ({
  id,
  onUnfocus,
  autoFocus,
  selected,
  handleSelect,
  placeholder = "Search companies",
  limitResults = 6,
  shouldIncludeNonPrivateCompanies: includeNonPrivateCompanies,
  companyFilter = () => true,
  indexedCompanyFilter = () => true,
  createCompanyText,
  searchInputClassName,
  isClearable,
  includeKeyboardShortcut,
}) => {
  const { isMac, isWindows } = useOperatingSystem()
  const [forceFocus, setForceFocus] = useState<boolean | undefined>(undefined)

  const onSearchInputChanged = async (value: string): Promise<SelectedCompany[]> => {
    handleSelect(undefined)

    if (value !== "") {
      try {
        const results = await searchCompanies(value, {
          maxResults: limitResults,
          includeNonPrivateCompanies,
        })

        const maybeCompanies = results
          .filter(({ id: companyId }) => companyFilter(companyId))
          .filter(indexedCompanyFilter)
          .map(selectedCompanyFromAlgoliaCompany)

        return filterMaybe(maybeCompanies)
      } catch (e) {
        handleConsoleError(e)
        return []
      }
    }

    return []
  }

  const onBlur = useCallback(() => {
    setForceFocus(undefined)
    onUnfocus?.()
  }, [onUnfocus, setForceFocus])

  useEffect(() => {
    if (!includeKeyboardShortcut) return () => {}

    // If control+K or command+K is pressed, open the quick action bar
    const handleKeyDown = (e: KeyboardEvent) => {
      if (((isWindows && e.ctrlKey) || (isMac && e.metaKey)) && e.key === "k") {
        e.preventDefault()
        const input = document.getElementById("navbar-company-search")
        if (input) {
          input.focus()
          setForceFocus(true)
        }
      }
      if (e.key === "Escape" && forceFocus) {
        e.preventDefault()
        onBlur()
      }
    }

    window.addEventListener("keydown", handleKeyDown)

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [forceFocus, setForceFocus, isMac, isWindows, includeKeyboardShortcut, onBlur])

  const placeholderWithKeyboardShortcut = includeKeyboardShortcut
    ? `${placeholder ?? ""}${isWindows ? " (Ctrl + K)" : " (⌘ K)"}`
    : placeholder

  return (
    <div id="company-search" className="w-full">
      <SearchSelect<SelectedCompany>
        id={id ?? "company-search-input"}
        menuIsOpen={forceFocus}
        value={selected ?? null}
        loadOptions={onSearchInputChanged}
        onChange={(v) => handleSelect(v ?? undefined)}
        getOptionLabel={(v) => (createCompanyText ? createCompanyText(v.name) : v.name)}
        getOptionValue={(v) => v.id}
        className={searchInputClassName}
        renderOption={(company) => (
          <div className="flex items-center space-x-2">
            <CompanySmallLogo logo={company.logos?.xs} />
            <div>{company.name}</div>
          </div>
        )}
        placeholder={placeholderWithKeyboardShortcut}
        isClearable={isClearable}
        autoFocus={autoFocus || forceFocus}
        onBlur={onBlur}
      />
    </div>
  )
}

export default AlgoliaCompanySelection
