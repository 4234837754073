import React, { ReactNode, useMemo, useState } from "react"
import { useErrorHandler } from "react-error-boundary"
import { v4 as uuidv4 } from "uuid"
import * as Sentry from "@sentry/react"
import { Severity } from "@sentry/react"
import Context, { ErrorNotification } from "./context"

export const ERROR_SNACKBAR_DURATION = 8000
export const DEFAULT_ERROR_MSG =
  "An error occurred. We've been notified and are looking into the issue."

interface ErrorHandlerProviderProps {
  children: ReactNode
}

const ErrorHandlerProvider: React.FunctionComponent<
  React.PropsWithChildren<ErrorHandlerProviderProps>
> = ({ children }) => {
  const [notifications, setNotifications] = useState<ErrorNotification[]>([])
  const handleError = useErrorHandler()

  const remove = (notificationKey: string) => {
    setNotifications((currentNotifications) =>
      currentNotifications.filter((item) => item.key !== notificationKey)
    )
  }

  const removeNotification = useMemo(
    () => (notificationKey: string) =>
      setTimeout(() => remove(notificationKey), ERROR_SNACKBAR_DURATION),
    []
  )

  const create = useMemo(
    () =>
      (err: Error, severity: "low" | "high" = "low", message: string = DEFAULT_ERROR_MSG) => {
        Sentry.captureException(err, {
          level: severity === "low" ? Severity.Error : Severity.Fatal,
          tags: {
            errorMessage: message,
          },
        })
        if (severity === "low") {
          // Render as a snackbar notification
          const newNotification = {
            key: uuidv4(),
            message,
          }
          setNotifications([
            newNotification,
            ...notifications.slice(0, 2), // limit to 3 errors displayed
          ])
          removeNotification(newNotification.key)
          throw err // throw to get captured by error logging
        } else {
          // Render as a full app stop
          handleError(err)
        }
      },
    [handleError, notifications, removeNotification]
  )

  const state = useMemo(
    () => ({
      handleError: create,
      notifications,
    }),
    [create, notifications]
  )

  return <Context.Provider value={state}>{children}</Context.Provider>
}

export default ErrorHandlerProvider
