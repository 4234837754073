import { FloatingPortal } from "@floating-ui/react"
import { NoteModalProvider } from "./NoteModalProvider"
import { NoteModalBody } from "./components/NoteModalBody"
import { NoteModalFooter } from "./components/NoteModalFooter"
import { NoteModalHeader } from "./components/NoteModalHeader"
import { NoteModalSearchSelect } from "./components/NoteModalSearchSelect"
import { motion } from "framer-motion"
import { useFirebaseWriter } from "src/firebase/Context"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { handleConsoleError } from "src/utils/Tracking"
import { useRef } from "react"

type NoteModalProps = {
  open: boolean
  onClose: () => void
}

const NoteModal = ({ open, onClose }: NoteModalProps) => {
  const firebase = useFirebaseWriter()
  const { user } = useLoggedInUser()
  const containerRef = useRef<HTMLDivElement | null>(null)
  const recordDragInteraction = () => {
    if (!user.productInteractionHistory.firstDraggedNoteModal) {
      firebase
        .recordUserProductInteraction(user, { firstDraggedNoteModal: new Date() })
        .catch(handleConsoleError)
    }
  }
  return open ? (
    <FloatingPortal>
      <NoteModalProvider onClose={onClose}>
        <div
          ref={containerRef}
          className="h-svh w-screen fixed inset-0 flex items-center justify-center z-1000 pointer-events-none"
        >
          <motion.div
            drag
            onDrag={recordDragInteraction}
            dragConstraints={containerRef}
            dragElastic={0}
            dragMomentum={false}
            className="w-[30rem] border border-neutral-400 bg-white rounded-md divide-y divide-neutral-400 shadow-md overflow-hidden pointer-events-auto"
          >
            <NoteModalHeader />
            <NoteModalSearchSelect />
            <NoteModalBody />
            <NoteModalFooter />
          </motion.div>
        </div>
      </NoteModalProvider>
    </FloatingPortal>
  ) : null
}

export default NoteModal
