import { TermsOfServiceVersion } from "../compliance/TermsOfServiceVersion"
import { Excluding } from "../utils/TypeUtils"
import { Account, AccountAccessFields, AccountIdFields } from "./Account"
import { firestoreConverter } from "./FirestoreConverter"
import { UserInsightsPageSettings } from "./Insights"
import { OnboardingStep } from "./Onboarding/OnboardingSteps"
import { SharedCompanyPage } from "./Permissions"
import { UserCRMSettings } from "./UserCRMSettings"
import { UserNotificationPreferences } from "./UserNotificationPreferences"
import { UserWatchlistSettings } from "./Watchlist"
import { WatchlistCompany, WatchlistIndustry } from "./WatchlistCompany"
import { Schema as z } from "../schema/Schema"
import { isAfter } from "date-fns"

export const fullNameString = (u: UserIdFields) => `${u.firstName} ${u.lastName}`
export const fullNameAndEmailString = (u: UserIdFields) => `${fullNameString(u)} (${u.email})`

export interface UserIdFields {
  id: string
  airtableId: string
  firstName: string
  lastName: string
  email: string
}
export const userIdFieldsSchema = z.object<UserIdFields>({
  id: z.string(),
  airtableId: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
})

export type UserAccessFields = UserIdFields & {
  account: AccountIdFields & AccountAccessFields & Pick<Account, "crmPreferences">
  isDealFlowUser?: boolean
}

export const viewUserIdFields = (u: UserIdFields): UserIdFields => ({
  id: u.id,
  airtableId: u.airtableId,
  firstName: u.firstName,
  lastName: u.lastName,
  email: u.email,
})
type PhoneNumber = string
export interface UserPublicFields {
  id: string
  firstName: string
  lastName: string
  email: string
  phoneNumber?: PhoneNumber | null
}
export const viewUserPublicFields = (u: UserPublicFields): UserPublicFields => ({
  id: u.id,
  firstName: u.firstName,
  lastName: u.lastName,
  email: u.email,
  phoneNumber: u.phoneNumber ?? null,
})

export type AccountUserSharedFields = UserPublicFields & Pick<User, "passwordless" | "createdAt">
export const viewAccountUserSharedFields = (user: User) => ({
  ...viewUserPublicFields(user),
  passwordless: user.passwordless,
  createdAt: user.createdAt,
})

export type NewUserFields = Omit<UserIdFields, "id"> &
  Pick<UserPublicFields, "phoneNumber"> &
  Pick<User, "creationSource">

export interface User extends UserIdFields, UserPublicFields, UserAccessFields {
  airtableId: string
  passwordless?: boolean
  inviteCode?: string
  creationSource: "caplight_invite" | "account_admin_invite" | "signup" | "iframe_embed"
  createdAt: Date
  updatedAt?: Date
  /** @deprecated use user.termsOfServiceAcceptance */
  latestTermsAndConditionsSignedAt?: Date
  termsOfServiceAcceptance?: {
    latestTermsOfServiceAcceptedAt: Date
    latestTermsOfServiceVersionAccepted: TermsOfServiceVersion
  }
  emailIntegration?: "gmail" | "office365" | null
  crmPreferences?: UserCRMSettings

  // Controlled via "Add to Platform" in Airtable CRM
  addedToFullPlatform?: boolean

  customPlatformInvite?: boolean

  // Admin fields
  ghostingUserAccount?: Account // ghosting allows an admin to emulate a user. we store their original account so they can revert back
  ghostSessionStartedAt?: Date

  // Resource access
  resourceAccess?: {
    auctionIds?: string[]
    rfqIds?: string[]
    platformPageInviteIds?: string[]
  }

  sharedCompanyPages?: SharedCompanyPage[]
  limitPlatformAccess?: boolean

  // Watchlists
  /**  @Deprecated use account watchlists instead */
  watchlist?: {
    companies?: Array<WatchlistCompany>
    industries?: Array<WatchlistIndustry>
  }
  watchlistSettings: UserWatchlistSettings

  productInteractionHistory: UserProductInteractionHistory
  mostRecentSessionStart?: Date

  // Insights Page
  insightsPageSettings: UserInsightsPageSettings

  // User settings
  notificationPreferences: UserNotificationPreferences

  onboardingStatus: {
    dataOnboardingComplete: boolean
    lastCompletedOnboardingStep?: OnboardingStep
  }

  emailLog?: {
    lastNotificationsCountEmailSentAt?: Date
    completeSignupEmailRemindersSentAt?: Date[]
  }

  hasSharedOrders?: boolean
  howDidYouHearAboutCaplight?: string | null

  // Unique link per user that can be sent to shareholders for them to import & verify their shares via Carta
  cartaShareholderToken?: string
}

export type ExclusiveUserIdFields = Excluding<UserIdFields, User>

export const ProductInformationKeys = [
  "firstViewedWatchlistAnnouncement",
  "firstViewedOrderLinkCaplightConnect",
  "firstViewedJune2023ProductUpdateAnnouncement",
  "firstViewedDarkpoolAnnouncement",
  "firstViewedAugust2023ProductUpdateAnnouncement",
  "firstViewedMarch2024ProductUpdateAnnouncement",
  "firstViewedAugust2024InsightsAnnouncement",
] as const

export type UserProductInformationHistory = Partial<
  Record<(typeof ProductInformationKeys)[number], Date>
>

export interface UserProductInteractionHistory extends UserProductInformationHistory {
  firstRFQInviteAt?: Date
  firstPlatformPageInvite?: Date
  firstViewedMarketsDashboardPage?: Date
  firstViewedRFQDashboardPage?: Date
  firstViewedIssuerShareButton?: Date
  firstPressedIssuerShareButton?: Date
  firstViewedIssuerFeedbackButton?: Date
  firstPressedIssuerFeedbackButton?: Date
  firstDismissedMarketPriceExplainer?: Date
  firstViewedPortfolioPage?: Date
  firstViewedOrdersPage?: Date
  firstViewedOrderInquiryButton?: Date
  firstViewedOrderInquiryDrawer?: Date
  lastViewedConnectDrawer?: Date
  intercomChatFirstOpenedAt?: Date
  firstViewedInboxFAQ?: Date
  firstDismissedConnectPopover?: Date
  lastOrdersRequiringAttentionDismissal?: Date
  lastViewOfNewsPage?: Date
  lastViewOfTradeHistoryPage?: Date
  firstDismissedTentativeInterestFormInfo?: Date
  firstDismissedDealDistributionsInfo?: Date
  firstSectorTimeRangeSelector?: Date
  lastOpenedActivityLog?: Date
  firstDraggedNoteModal?: Date
  lastDismissedEmailSyncBanner?: Date
  firstDismissedShortOrderBannerAnnouncement?: Date
}

export type UserResourceAccessKey = keyof Required<User>["resourceAccess"]

export const UserFunctions = {
  extractNameParts: (name: string) => {
    const nameParts = name.split(" ")
    return {
      firstName: nameParts[0],
      lastName: nameParts.slice(1).join(" "),
    }
  },
  name: (user: { firstName: string; lastName: string }) => `${user.firstName} ${user.lastName}`,
  initials: (user: UserIdFields) =>
    `${user.firstName[0]}${user.lastName.length > 0 ? user.lastName[0] : ""}`.toUpperCase(),
  identifierFields: (user: User): UserIdFields => ({
    id: user.id,
    airtableId: user.airtableId,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
  }),
  isIndividualAccountUser: (user: PartialUser) =>
    user.account?.name === [user.firstName, user.lastName].join(" ") ||
    (user.account?.name || "").toLowerCase().includes("(individual)"),
}

export type PartialUser = Partial<User>

export const userConverter = firestoreConverter<User>()

export const isSelfServiceUser = ({ creationSource }: Pick<User, "creationSource">) =>
  creationSource === "signup"

const ONBOARDING_V2_REQUIRED_DATE = new Date("2024-06-24")

export const hasUserCompletedRequiredOnboarding = (
  user: Pick<User, "onboardingStatus" | "account" | "createdAt">
) => {
  // NOTE: prior to this date, we weren't being as strict about requiring onboarding to be completed,
  // so we're using a date check to avoid sending legacy users back to onboarding.
  const isUserCreatedAfterOnboardingRequiredDate = isAfter(
    user.createdAt,
    ONBOARDING_V2_REQUIRED_DATE
  )
  if (isUserCreatedAfterOnboardingRequiredDate) {
    return user.onboardingStatus?.lastCompletedOnboardingStep === "complete"
  }

  return true
}
