import { isLoaded, Loading } from "../../utils/Loading"
import { Account } from "../Account"
import { Order } from "../Order/Models/Wrapped"
import { viewAccountGlobalSizeFilter } from "./viewAccountGlobalSizeFilter"

export const canShowWrappedOrderToAccount = (
  o: Order,
  loadingAccount: Loading<Account>
): boolean => {
  if (isLoaded(loadingAccount)) {
    // NOTE: a user's own orders should update their global filter,
    // but we should always show them anyway
    if (o.fromAccount(loadingAccount)) {
      return true
    }

    // TODO: not sure if this is what we want long term, but it should not
    // really matter currently because this check is only used on public orders
    if (o.sharedWithAccount(loadingAccount)) {
      return true
    }
  }

  return o
    .amountUSD()
    .map(({ upperBound }) => upperBound >= viewAccountGlobalSizeFilter(loadingAccount))
    .withDefault(true)
}
