import { OrderPageLoadingOrder } from "../types"
import LoadedOrderPreview from "./LoadedOrderPreview"
import LoadingOrderPreview from "./LoadingOrderPreview"

type Props = OrderPageLoadingOrder & { showCTAs?: boolean }

const OrderDrawerOrderPreview = ({ order, showCTAs }: Props) =>
  order.match(
    (o) => <LoadedOrderPreview order={o} showCTAs={showCTAs} />,
    <LoadingOrderPreview />,
    null
  )

export default OrderDrawerOrderPreview
