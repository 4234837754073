import { useEffect } from "react"
import { WYSIWYGEditor } from "../../Components/Notes/WYSIWYGEditor"
import { useNoteModal } from "../NoteModalProvider"

export const NoteModalBody = () => {
  const { editor, allowSave, onSaveNote, selectedSource } = useNoteModal()

  useEffect(() => {
    if (selectedSource && !editor?.isFocused) {
      editor?.commands.focus()
    }
  }, [editor, selectedSource])

  return (
    <div className="flex flex-col gap-1" onPointerDownCapture={(e) => e.stopPropagation()}>
      {editor ? (
        <WYSIWYGEditor
          showSubmitButton={false}
          editor={editor}
          isSubmitDisabled={!allowSave}
          handleSubmit={onSaveNote}
          hideBorder
        />
      ) : null}
    </div>
  )
}
