import _ from "lodash"
import { Func } from "../../fp/Function"
import { Just, Maybe, Nothing } from "../../../containers/Maybe"
import { scanL } from "./ArrayUtils"

/** A nonempty array of `T`s.
 * This is just a type alias, so TS can and will let it degrade to `T[]` when inconvenient.
 * If you need to propagate the results of an emptiness check over long distances, use a wrapper class instead.
 */
export type Nonempty<T> = Cons<T, T[]>

/** This is a safer drop-in replacement for _.isArray or Array.isArray
 *
 * If you must use one of these functions, use this one. In the vast majority of cases, however, all validation and parsing should be performed at program boundaries (e.g. API response deserialization): use of this function elsewhere likely indicates an organizational problem with your code.
 */
export const isArray = (x: unknown): x is unknown[] => _.isArray(x)

export type Cons<H, T extends unknown[]> = [H] | [H, ...T]

export const justIfNonempty = <T>(ts: T[]): Maybe<Nonempty<T>> =>
  isNonempty(ts) ? Just(ts) : Nothing

export const isNonempty = <T>(ts: readonly T[]): ts is Nonempty<T> => ts.length > 0

export const isNonemptyFromUnknown = (t: unknown): t is Nonempty<unknown> =>
  isArray(t) && isNonempty(t)

export const reduceNonempty =
  <T>(f: (prev: T, current: T) => T) =>
  (ts: Nonempty<T>) =>
    ts.slice(1).reduce(f, ts[0])

/** As scanL, using the first element of `ts` as the initial value. */
export const scanLNonempty = <T>(f: (s: T, t: T) => T, ts: Nonempty<T>): T[] =>
  scanL(f, ts.slice(1), ts[0])

export const nonemptyHead = <X>(xs: Nonempty<X>): X => xs[0]
export const nonemptyLast = <X>(xs: Nonempty<X>): X => xs[xs.length - 1]

/** Some builtin array operations (e.g. map) are length-preserving, but typescript isn't aware of that. */
export const unsafeAssertNonempty = <T>(x: T[]): Nonempty<T> => {
  if (isNonempty(x)) {
    return x
  }
  throw new Error("unsafeAssertNonempty called on an empty array")
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const Nonempty_ = {
  map: <X, Y>(xs: Nonempty<X>, f: Func<X, Y>): Nonempty<Y> =>
    justIfNonempty(xs.map((x) => f(x))).withLazyDefault(() => {
      throw new Error(
        "Nonempty_.map called on an empty array; this indicates unsoundness somewhere upstream"
      )
    }),

  concat: <X>(xs: Nonempty<X>, ys: Nonempty<X>): Nonempty<X> =>
    justIfNonempty(xs.concat(ys)).withLazyDefault(() => {
      throw new Error(
        "Nonempty_.concat called on an empty array; this indicates unsoundness somewhere upstream"
      )
    }),
}
