import CopyText from "@stories/components/CopyText/CopyText"
import { Size } from "@stories/components/Typography/Typography"

const CRMContactCopyEmail = ({
  email,
  textSize = Size.XSmall,
  iconOnly,
}: {
  email: string
  textSize?: Size
  iconOnly?: boolean
}) => {
  if (!email) return <span />
  return (
    <div className="flex">
      <CopyText text={email} size={textSize} iconOnly={iconOnly} shouldMaskDataInDataDog />
    </div>
  )
}

export default CRMContactCopyEmail
