import { ShareOrderStatusIcon } from "@components/ShareOrder/ShareOrderStatusIcon"
import { Drawer, DrawerProps } from "@stories/components/Antd"
import { Tab, Tabs } from "@stories/components/Tabs/Tabs"
import { OrderLookupFields } from "common/model/Order/Models/Internal"
import { Order } from "common/model/Order/Models/Wrapped"
import {
  DETAIL_TAB,
  OrderDetailsTabId,
} from "common/model/Order/MiscOrderUIStuffThatNeedsToBeCleanedUp"
import { Loading } from "common/utils/Loading/Wrapped"
import { useCallback, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import TradeAndOrderProvider from "src/pages/Data/IssuerPage/DataTable/TradeAndOrderProvider"
import { useOrder } from "src/queries/Order/useOrder"
import { useLoggedInUser } from "../../../providers/loggedInUser/useLoggedInUser"
import { classNames } from "../../../utils/classNames"
import { useCompany } from "../../Data/Hooks"
import { DarkpoolOrderDetailWarning } from "../OrderForm/DarkpoolOrderFormWarning"
import { OrderFormHeader } from "../OrderForm/OrderFormHeader"
import OrderBrokerLinkingWarning from "./OrderBrokerLinkingWarning/OrderBrokerLinkingWarning"
import OrderPageLiveMarket from "./OrderLiveMarket/OrderPageLiveMarket"
import OrderPageMarketPriceComparison from "./OrderMarketPriceComparison/OrderPageMarketPriceComparison"
import { OrderPageExpireContainer } from "./OrderPageExpireContainer"
import OrderPageLayout from "./OrderPageLayout"
import { OrderPageRefreshContainer } from "./OrderPageRefreshContainer"
import OrderPageScorecard from "./OrderPageScorecard/OrderPageScorecard"
import OrderPagePendingConnect from "./OrderPendingConnect/OrderPagePendingConnect"
import OrderDrawerOrderPreview from "./OrderPreview/OrderDrawerOrderPreview"
import OrderPageTermSheet from "./OrderTermSheet/OrderPageTermSheet"
import ShareOrderContainer from "./ShareOrderContainer/ShareOrderContainer"
import TrackOrderPage from "./TrackOrderPage"
import OrderPageOrderLatestUpdates from "./OrderHistory/OrderPageOrderLatestUpdates"
import OrderPageOrderLog from "./OrderHistory/OrderPageOrderLog"
import FeatureWrapper from "../../../providers/featureFlags/FeatureWrapper"
import { SourceAttributionCard } from "./OrderTermSheet/Sections/SourceAttributionSection"
import OrderCRMContactSection from "./OrderTermSheet/Sections/OrderCRMContactSection"
import { CompanyAccessProvider } from "src/providers/AccessControl/CompanyAccessProvider"
import { TrustedBrokerSourceAttributionCard } from "./OrderTermSheet/Sections/TrustedBrokerSourceAttributionCard"

type DetailTab = Tab & { id: OrderDetailsTabId }
const useTabs = (tabs: DetailTab[]) => {
  const navigate = useNavigate()
  const location = useLocation()

  const onChange = useCallback(
    (n: number) => {
      const search = new URLSearchParams(location.search)
      const newTab = tabs.find((t) => t.key === n)
      if (newTab) {
        search.set(DETAIL_TAB, newTab.id)
      }
      navigate({ search: search.toString() })
    },
    [location.search, navigate, tabs]
  )

  const initialTab = useMemo(() => {
    const search = new URLSearchParams(location.search)
    const tabFromURL = search.get(DETAIL_TAB)
    return tabs.find((t) => t.id === tabFromURL)?.key ?? 0
  }, [location.search, tabs])

  return { onChange, initialTab }
}

type Props = {
  onClose?: () => void
  orderId: OrderLookupFields
  refresh?: boolean
  expire?: boolean
}

const OrderPageDrawerMain = ({
  order,
  refresh,
  expire,
}: { order: Loading<Order> } & Pick<Props, "refresh" | "expire">) => {
  const currentUser = useLoggedInUser()

  const orderDetailsMain = (
    <div className="flex flex-col gap-4 p-4">
      <OrderPageRefreshContainer order={order} refresh={refresh} />
      <OrderPageExpireContainer order={order} expire={expire} />
      <OrderPagePendingConnect order={order} />
      <OrderCRMContactSection order={order} />
      <OrderPageTermSheet order={order} />
      <FeatureWrapper flagName="order_history">
        <OrderPageOrderLog order={order} />
      </FeatureWrapper>
      <OrderPageLiveMarket order={order} />
      <TrackOrderPage order={order} />
    </div>
  )

  const tabs: DetailTab[] = [
    { id: "details", key: 0, label: "Details", children: orderDetailsMain },
    {
      id: "sharing",
      key: 1,
      label: "Share",
      tabIcon: order.match(
        (o) => <ShareOrderStatusIcon item={{ tag: "order", id: o.id(), order: o.toInternal() }} />,
        null,
        null
      ),
      children: (
        <ShareOrderContainer
          item={order.map((o) => ({ tag: "order", id: o.id(), order: o.toInternal() }))}
        />
      ),
    },
  ]

  const { onChange, initialTab } = useTabs(tabs)

  return order.match(
    (o) => {
      const accountCanShareOrder =
        o.fromAccount(currentUser.user.account) &&
        o.sourceAttribution(currentUser.user).match(
          () => false,
          () => true
        )
      return accountCanShareOrder || currentUser.isAdmin ? (
        <Tabs
          shouldIncludeSideBorders={false}
          tabs={tabs}
          initialTab={initialTab}
          onChange={onChange}
        />
      ) : (
        orderDetailsMain
      )
    },
    orderDetailsMain,
    null
  )
}

export const OrderPageDrawerInner = ({
  onClose,
  orderId,
  isInDrawer,
  refresh,
  expire,
}: Props & {
  // NOTE: kinda janky, should be changed if we want to make more distinction between drawer/page other than just some classNames
  isInDrawer: boolean
}) => {
  const order = useOrder(orderId)
  const company = new Loading(useCompany(order.match((o) => o.company().id, "loading", "null")))

  return (
    <CompanyAccessProvider company={company.match((c) => c, "loading", null)}>
      <TradeAndOrderProvider key={orderId.id} company={company}>
        <div
          className={classNames(
            "w-full md:sticky z-40 border-b",
            isInDrawer ? "top-0 bg-neutral-white p-8" : "top-16 py-4 bg-neutral-200"
          )}
        >
          <OrderFormHeader onClose={onClose} drawerTitle="Order Details" />
          <OrderDrawerOrderPreview order={order} showCTAs />
        </div>
        <div className={classNames("w-full flex min-h-full", !isInDrawer && "pt-4")}>
          <OrderPageLayout.Wrapper
            main={<OrderPageDrawerMain order={order} refresh={refresh} expire={expire} />}
            sidebar={
              <>
                <TrustedBrokerSourceAttributionCard order={order} />
                <SourceAttributionCard order={order} />
                <OrderBrokerLinkingWarning order={order} />
                <DarkpoolOrderDetailWarning order={order} />
                <OrderPageScorecard order={order} />
                <OrderPageMarketPriceComparison order={order} company={company} />
                <FeatureWrapper flagName="order_history">
                  <OrderPageOrderLatestUpdates order={order} />
                </FeatureWrapper>
                {/* <OrderPageNotificationToggle order={order} /> */}
              </>
            }
          />
        </div>
      </TradeAndOrderProvider>
    </CompanyAccessProvider>
  )
}

const OrderPageDrawerRendered = ({
  onClose,
  open,
  orderId,
  refresh,
  expire,
}: Props & {
  open: Required<DrawerProps["open"]>
}) => (
  <Drawer
    open={open}
    onClose={onClose}
    placement="right"
    width={1200}
    contentWrapperStyle={{ maxWidth: "100vw" }}
    bodyStyle={{ padding: 0 }}
    closable={false}
    destroyOnClose
  >
    <OrderPageDrawerInner
      onClose={onClose}
      orderId={orderId}
      key={orderId.id}
      isInDrawer
      refresh={refresh}
      expire={expire}
    />
  </Drawer>
)

const OrderPageDrawer = (
  props: Props & {
    open: Required<DrawerProps["open"]>
  }
) => (props.open ? <OrderPageDrawerRendered {...props} /> : null)

export default OrderPageDrawer
