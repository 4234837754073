import { AccountIdFields } from "common/model/Account"
import { v4 as uuidv4 } from "uuid"
import {
  AccountTradingRegistration,
  MinimumAccountTradingRegistration,
  defaultAccountTradingRegistration,
} from "common/model/Account/AccountTradingRegistration"
import { AdminTradingRegistrationTypeSelection } from "./AdminTradingRegistrationTypeSelection"

export const AdminTradingRegistrationInitialization = ({
  account,
  updateTradingRegistration,
}: {
  updateTradingRegistration: (
    t: Partial<AccountTradingRegistration> & MinimumAccountTradingRegistration
  ) => Promise<void>
  account: AccountIdFields
}) => (
  <>
    <AdminTradingRegistrationTypeSelection
      updateTradingRegistration={updateTradingRegistration}
      tradingRegistration={defaultAccountTradingRegistration(uuidv4(), account, "broker")}
    />
  </>
)
