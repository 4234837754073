import { CurrentUser } from "@model/CurrentUser"
import { Loading, isLoaded } from "common/utils/Loading"

export const getAllInterestPageTitle = (user: Loading<CurrentUser>) => {
  const defaultTitle = "Buy/Sell Interest"
  if (!isLoaded(user)) return defaultTitle
  if (user.user.account.clientType.includes("Intermediary")) return "Client Interest"
  if (user.user.account.clientType.includes("Investor/Shareholder")) return "LP Interest"

  return defaultTitle
}
