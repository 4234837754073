export type ClientUseCase = (typeof clientUseCases)[number]
export const clientUseCases = [
  "Yield Generation",
  "Hedging",
  "Tax Advantages",
  "Volatility Buyer/Seller",
  "Pre-IPO Trade",
  "Access (long)",
  "Spot Pricing Info",
] as const

export interface LabeledUseCase {
  value: ClientUseCase
  label?: string
}

export const ClientUseCases: LabeledUseCase[] = [
  {
    value: "Yield Generation",
  },
  {
    value: "Hedging",
  },
  {
    value: "Tax Advantages",
    label: "Tax-advantaged trading",
  },
  { value: "Volatility Buyer/Seller", label: "Trading volatility" },
  { value: "Pre-IPO Trade", label: "Pre-IPO Trading" },
  { value: "Access (long)", label: "Access (additional long exposure)" },
  { value: "Spot Pricing Info", label: "Company Data & Pricing Information" },
]
