import HighlightedOrderPill from "@stories/components/Badges/HighlightedOrderPill/HighlightedOrderPill"
import MarketPill from "@stories/components/Badges/MarketPill/MarketPill"
import { TopBrokerPill } from "@stories/components/Badges/TopBrokerPill/TopBrokerPill"
import { localeDateFormat } from "common/utils/dateUtils"
import { nullableToMaybe } from "common/containers/Maybe"
import { AdminRecordLink } from "src/pages/Data/IssuerPage/DataTable/DataTableRow"
import CompanyLogoAndName from "../../../../components/CompanyLogoAndName"
import { useTopBrokerAccountIds } from "../../../../providers/topBroker/TopBrokerProvider"
import { orderDataAccessLevel } from "../../shared/accessLevels"
import { OrderPageOrder } from "../types"
import OrderPreviewDetail from "./OrderPreviewDetail"
import OrderPreviewPrice from "./OrderPreviewPrice"
import OrderPreviewShareClass from "./OrderPreviewShareClass"
import OrderPreviewSize from "./OrderPreviewSize"
import OrderPreviewStructure from "./OrderPreviewStructure"
import { LoadedOrderCTAs } from "../OrderCTAs/OrderCTAs"
import OrderPreviewContact from "./OrderPreviewContact"
import { AccessBoundary } from "@components/auth/DataAccess"

const LoadedOrderPreview = ({ order, showCTAs }: { order: OrderPageOrder; showCTAs?: boolean }) => {
  const topBrokerAccountIds = useTopBrokerAccountIds()

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between mt-4">
        <div className="flex flex-col md:flex-row items-center gap-2">
          <AccessBoundary accessLevel={order.isDarkpool() ? "hideFromCaplight" : "public"}>
            <CompanyLogoAndName
              company={order.company()}
              size="xs"
              shouldMaskDataInDataDog={order.isDarkpool()}
            />
          </AccessBoundary>
          <AdminRecordLink
            companyId={order.company().id}
            recordId={order.id()}
            itemType={order.direction()}
          >
            <MarketPill variant={order.direction() === "buy" ? "bid" : "offer"} />
          </AdminRecordLink>
          {order.isFromTopBrokerAccount(topBrokerAccountIds.map((id) => ({ id }))) ? (
            <TopBrokerPill showTooltip />
          ) : null}
          {order.isHighlighted() ? <HighlightedOrderPill showText placement="bottom" /> : null}
        </div>
        {showCTAs ? <LoadedOrderCTAs order={order} /> : null}
      </div>
      <div className="flex flex-col gap-4 whitespace-nowrap md:flex-row md:flex-wrap md:justify-between">
        <div className="flex flex-col gap-4 whitespace-nowrap md:flex-row">
          <OrderPreviewSize order={order} />
          <OrderPreviewPrice order={order} />
          <OrderPreviewStructure order={order} />
          <OrderPreviewShareClass order={order} />
          <OrderPreviewContact order={order} />
        </div>
        <div className="flex flex-col gap-4 whitespace-nowrap md:flex-row">
          <OrderPreviewDetail
            label="Last updated"
            detailType={order.isOutdated() ? "warning" : "default"}
            accessLevel={orderDataAccessLevel.updatedDate}
            value={localeDateFormat(order.latestUpdateDate())}
          />
        </div>
      </div>
    </div>
  )
}

export default LoadedOrderPreview
