import { Maybe, nullableToMaybe } from "../../../containers/Maybe"
import { IReifiedTraversal, ReifiedTraversal, Traversal, composeTraversal } from "./Traversal"

/** A lens picks out a particular factor within a product type.
 * A `Lens<S, T, A, B>` allows us to view an `A` within an `S`, or replace with with a `B` to yield a `T`.
 */
export interface Lens<S, T, A, B> {
  view: (s: S) => A
  over: (s: S, f: (a: A) => B) => T
}
export type SimpleLens<S, A> = Lens<S, S, A, A>


export interface RecordLens<S, T, A, B> {
  view: <R>(r: R & S) => A
  over: <R>(s: R & S, f: (a: A) => B) => R & T
}
export interface GenericSimpleLens<K extends string | number | symbol> {
  view: <R extends Partial<Record<K, unknown>>>(r: R) => R[K]
  over: <S, R extends Record<K, unknown>>(s: S & R, f: (a: R[K]) => R[K]) => S & R
}
export const field =
  <K extends string | number | symbol>(key: K) =>
  <R extends Record<K, unknown>>(): ReifiedSimpleLens<R, R[K]> =>
    new ReifiedLens({ view: (r) => r[key], over: (r, f) => ({ ...r, [key]: f(r[key]) }) })

export const subrecord = <Focus, Rest>(): ReifiedSimpleLens<Focus & Rest, Focus> =>
  new ReifiedLens<Focus & Rest, Focus & Rest, Focus, Focus>({
    view: (x) => x,
    over: (s, f) => ({ ...s, ...f(s) }),
  })

export const optionalFieldAsUndefined =
  <K extends string | number | symbol>(key: K) =>
  <R extends { [key in K]?: unknown }>(): ReifiedSimpleLens<R, R[K] | undefined> =>
    new ReifiedLens({ view: (r) => r[key], over: (r, f) => ({ ...r, [key]: f(r[key]) }) })
export const optionalFieldAsMaybe =
  <K extends string | number | symbol>(key: K) =>
  <R extends { [key in K]?: unknown }>() =>
    new ReifiedLens<R, R, Maybe<R[K]>, R[K]>({
      view: (s) => nullableToMaybe(s[key]),
      over: (s, f) => ({
        ...s,
        [key]: f(nullableToMaybe(s[key])),
      }),
    })

export const composeLens = <S, T, A, B, X, Y>(
  l: Lens<S, T, A, B>,
  r: Lens<A, B, X, Y>
): Lens<S, T, X, Y> => ({
  view: (s) => r.view(l.view(s)),
  over: (s, f) => l.over(s, (a) => r.over(a, f)),
})


export interface IReifiedLens<S, T, A, B> extends Lens<S, T, A, B>, IReifiedTraversal<S, T, A, B> {
  composeLens: <X, Y>(l: Lens<A, B, X, Y>) => ReifiedLens<S, T, X, Y>
}

export class ReifiedLens<S, T, A, B> implements IReifiedLens<S, T, A, B> {
  readonly view: (s: S) => A

  readonly over: (s: S, f: (a: A) => B) => T

  constructor(lens: { view: (s: S) => A; over: (s: S, f: (a: A) => B) => T }) {
    this.view = lens.view
    this.over = lens.over
  }

  views = (s: S) => [this.view(s)]

  composeLens = <X, Y>(l: Lens<A, B, X, Y>): ReifiedLens<S, T, X, Y> =>
    new ReifiedLens(composeLens(this, l))

  composeTraversal = <X, Y>(t: Traversal<A, B, X, Y>): ReifiedTraversal<S, T, X, Y> =>
    new ReifiedTraversal(composeTraversal(this, t))
}
export type ReifiedSimpleLens<S, A> = ReifiedLens<S, S, A, A>
