import { captureException } from "@sentry/react"
import DropdownMenu, { DropdownMenuItem } from "@stories/components/DropdownMenu/DropdownMenu"
import { Color } from "@stories/components/Typography/Typography"
import { CrossIcon } from "@stories/icons/CrossIcon"
import { DotsIcon } from "@stories/icons/DotsIcon"
import { DealCRMInterest, isInterestCancelled } from "common/model/DealCRM/DealCRMInterest"
import { UserIdFields } from "common/model/User"
import { useFirebaseWriter } from "src/firebase/Context"
import OrderFormDrawer from "src/pages/Orders/OrderForm/OrderFormDrawer"
import { orderFormDrawerTitle } from "src/pages/Orders/OrderForm/constants"
import { handleInterestEdit } from "../handleInterestEdit"
import { usePublishCRMInterestItems } from "./PublishedCRMInterestButtonMenu"
import { PublishedOrderControls } from "./PublishedOrderControls"

type InterestActionDropdownMenuProps = {
  interest: DealCRMInterest
  user: UserIdFields
  afterUpdateInterest?: (interest: DealCRMInterest) => void
}

const InterestActionDropdownMenu = ({
  interest,
  user,
  afterUpdateInterest,
}: InterestActionDropdownMenuProps) => {
  const firebaseWriter = useFirebaseWriter()

  const cancelItem: DropdownMenuItem = {
    icon: <CrossIcon />,
    id: "0",
    label: "Cancel",
    onClick: () =>
      handleInterestEdit({
        interest,
        interestUpdate: { firmness: "cancelled", stage: "cancelled" },
        user,
        db: firebaseWriter,
        afterUpdate: () => afterUpdateInterest?.(interest),
      }),
    variant: "danger",
    heapName: "remove-contact-buy-interest-button",
  }
  const removeItem: DropdownMenuItem = {
    icon: <CrossIcon />,
    id: "0",
    label: "Remove",
    onClick: () => {
      if (!isInterestCancelled(interest)) {
        return captureException(
          new Error(
            `Attempted to remove CRM interest that wasn't cancelled first. Interest: ${interest.id}`
          )
        )
      }

      return handleInterestEdit({
        interest,
        interestUpdate: { isDeleted: true },
        user,
        db: firebaseWriter,
        afterUpdate: () => afterUpdateInterest?.(interest),
      })
    },
    variant: "danger",
    heapName: "remove-contact-buy-interest-button",
  }

  const removeOrCancel = isInterestCancelled(interest) ? [removeItem] : [cancelItem]

  const eitherDisallowedReasonOrPublishItems = usePublishCRMInterestItems(interest)

  return eitherDisallowedReasonOrPublishItems.match(
    () => (
      <DropdownMenu menuButtonIcon={<DotsIcon color={Color.Black} />} menuItems={removeOrCancel} />
    ),
    ({
      isDarkpoolFormOpen,
      isLiveMarketFormOpen,
      closeLiveMarketForm,
      closeDarkpoolForm,
      formOrderFromCRMInterest,
      onSaveOrderForm,
      items: publishItems,
    }) => (
      <>
        <div className="flex space-x-2 items-center w-full">
          {interest.publishedOrder ? (
            <PublishedOrderControls
              interest={{ ...interest, publishedOrder: interest.publishedOrder }}
            />
          ) : null}
          <DropdownMenu
            menuButtonIcon={<DotsIcon color={Color.Black} />}
            menuItems={publishItems.concat(removeOrCancel)}
          />
        </div>
        {isLiveMarketFormOpen ? (
          <OrderFormDrawer
            open={isLiveMarketFormOpen}
            initialFormOrder={{
              ...formOrderFromCRMInterest,
              darkpool: false,
            }}
            onClose={closeLiveMarketForm}
            onSave={onSaveOrderForm}
            drawerTitle={orderFormDrawerTitle("market")}
          />
        ) : null}
        {isDarkpoolFormOpen ? (
          <OrderFormDrawer
            open={isDarkpoolFormOpen}
            initialFormOrder={{ ...formOrderFromCRMInterest, darkpool: true }}
            onClose={closeDarkpoolForm}
            onSave={onSaveOrderForm}
            drawerTitle={orderFormDrawerTitle("darkpool")}
          />
        ) : null}
      </>
    )
  )
}

export default InterestActionDropdownMenu
