import { firestoreConverter } from "./FirestoreConverter"

export interface Industry {
  id: string
  name: string
  isSuggested?: boolean
  priority?: number
}

export const industryConverter = firestoreConverter<Industry>()
