import { AccessControlTier } from "./AccessControlTier"

export const accessLevels = ["full", "locked", "limited-company", "none"] as const
export type AccessLevel = (typeof accessLevels)[number]

type AccessFeature<T extends string> = {
  [k in T]: AccessLevel
}

const platformFeatureNames = [
  "liveMarketParticipation",
  "currentMarketPriceData",
  "orderHistoryData",
  "sectorsData",
  "contributeTradeData",
  "companyNewsData",
  "fundingRoundsPage",
  "fundingRoundsData",
  "marketInsightsPage",
  "rfqData",
] as const
type PlatformFeatureName = (typeof platformFeatureNames)[number]
export type PlatformFeatures = AccessFeature<PlatformFeatureName>

const companyDetailPageFeatureNames = [
  "companyPageFundingRoundsTable",
  "companyPage409aValuationsTable",
  "companyPageInvestorsTable",
  "companyPageMutualFundMarksTable",
  "companyPageBidOfferVolumeRatioChart",
  "companyPageTradingContextSection",
  "companyPageResearchReportsSection",
  "companyPageComparablesSection",
  "companyPageFilingsTable",
  "companyPageShare",
  "companyPageFlagIssue",
  "companyPageExport",
] as const
type CompanyDetailPageFeatureName = (typeof companyDetailPageFeatureNames)[number]
export type CompanyDetailPageFeatures = AccessFeature<CompanyDetailPageFeatureName>

const tradeHistoryDataFeatureNames = [
  "tradeHistoryPage",
  "tradeHistoryPricing",
  "tradeHistoryDates",
  "tradeHistoryStructure",
  "tradeHistoryShareClass",
] as const
type TradeHistoryDataFeatureName = (typeof tradeHistoryDataFeatureNames)[number]
export type TradeHistoryDataFeatures = AccessFeature<TradeHistoryDataFeatureName>

export const accessControlFeatureNames = [
  ...platformFeatureNames,
  ...companyDetailPageFeatureNames,
  ...tradeHistoryDataFeatureNames,
] as const
export type AccessControlFeatureName = (typeof accessControlFeatureNames)[number]
export type AccessControlFeatures = AccessFeature<AccessControlFeatureName>

export type AccessControl<T extends AccessControlTier> = {
  tier: T
  features: AccessControlFeatures
}

export const getFeatureAccessLevel = <T extends AccessControlTier>(
  feature: AccessControlFeatureName,
  accessControlDocument: AccessControl<T>
): AccessLevel => accessControlDocument.features[feature]
