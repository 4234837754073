import { wrapIcon } from "./IconWrapper"

export const CompaniesIcon = wrapIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.00016 4.66667V3.33333C8.00016 2.6 7.40016 2 6.66683 2H2.66683C1.9335 2 1.3335 2.6 1.3335 3.33333V12.6667C1.3335 13.4 1.9335 14 2.66683 14H13.3335C14.0668 14 14.6668 13.4 14.6668 12.6667V6C14.6668 5.26667 14.0668 4.66667 13.3335 4.66667H8.00016ZM4.00016 12.6667H2.66683V11.3333H4.00016V12.6667ZM4.00016 10H2.66683V8.66667H4.00016V10ZM4.00016 7.33333H2.66683V6H4.00016V7.33333ZM4.00016 4.66667H2.66683V3.33333H4.00016V4.66667ZM6.66683 12.6667H5.3335V11.3333H6.66683V12.6667ZM6.66683 10H5.3335V8.66667H6.66683V10ZM6.66683 7.33333H5.3335V6H6.66683V7.33333ZM6.66683 4.66667H5.3335V3.33333H6.66683V4.66667ZM12.6668 12.6667H8.00016V11.3333H9.3335V10H8.00016V8.66667H9.3335V7.33333H8.00016V6H12.6668C13.0335 6 13.3335 6.3 13.3335 6.66667V12C13.3335 12.3667 13.0335 12.6667 12.6668 12.6667ZM12.0002 7.33333H10.6668V8.66667H12.0002V7.33333ZM12.0002 10H10.6668V11.3333H12.0002V10Z"
      fill="currentColor"
    />
  </svg>
)
