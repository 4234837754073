import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from "react"

type TopNavbarRefCtx = {
  topNavbarRef: (el: HTMLDivElement) => void
  topNavbarHeight: number
}

export const TopNavbarRefContext = createContext<TopNavbarRefCtx | null>(null)

/**
 * Provides `topNavbarHeight`, to be used with elements that are sticky just below the navbar and the various ghosting/trial/trading registration bars that could appear.
 *
 * ```tsx
 * export const StickySidebarWrapper = () => {
 *   const topNavbarHeight = useTopNavbarHeight()
 *   return (
 *     <div className="sticky" style={{ top: topNavbarHeight }}>
 *       <Sidebar />
 *     </div>
 *   )
 * }
 * ```
 *
 */
const TopNavbarRefProvider = ({ children }: PropsWithChildren) => {
  const [topNavbarHeight, setTopNavbarHeight] = useState<number>(16)
  const [topNavbarNode, setTopNavbarNode] = useState<HTMLDivElement | null>(null)
  const topNavbarRef = useMemo(() => (el: HTMLDivElement) => setTopNavbarNode(el), [])

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const { y, height } = entries[0]?.target.getBoundingClientRect() ?? { y: 0, height: 0 }
      setTopNavbarHeight(y + height)
    })

    if (topNavbarNode) {
      observer.observe(topNavbarNode)
    }

    return () => {
      if (topNavbarNode) observer.unobserve(topNavbarNode)
    }
  }, [topNavbarNode])

  const value = useMemo(() => ({ topNavbarHeight, topNavbarRef }), [topNavbarHeight, topNavbarRef])

  return <TopNavbarRefContext.Provider value={value}>{children}</TopNavbarRefContext.Provider>
}

export default TopNavbarRefProvider

export const useTopNavbarHeight = () => {
  const value = useContext(TopNavbarRefContext)?.topNavbarHeight
  if (!value) {
    throw new Error("useTopNavbarHeight must be used within TopNavbarRefProvider")
  }
  return value
}
