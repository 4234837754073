import { collections, restrictedCollections } from "../../../firestore/Collections"
import { Company } from "../../../model/Company"
import { firestoreConverter } from "../../../model/FirestoreConverter"
import { Order } from "../../../model/Order/Order"
import { Boxed } from "../../../utils/fp/Boxed"
import {
  CompanyCollection,
  CompanyRef,
  OrderCollection,
  OrderRef,
  PricingDataRoot,
} from "../../pricing/PricingDataSource"
import { FirestoreDataContext } from "../../../firestore/Interface"
import { FirebaseCompanies, FirebaseCompany } from "./model/Companies"
import { FirebaseAllOrders, FirebaseLiveOrders, FirebaseOrder } from "./model/Orders"

export class FirebaseDataSource
  extends Boxed<Omit<FirestoreDataContext, "root">>
  implements PricingDataRoot
{
  order = (id: string): OrderRef =>
    new FirebaseOrder({
      ...this.unboxed,
      id,
      db: this.unboxed.db,
      ref: this.unboxed
        .db()
        .collection(restrictedCollections.orderObservations)
        .withConverter<Order>(firestoreConverter<Order>())
        .doc(id),
      root: this,
    })

  orders = (): OrderCollection =>
    new FirebaseAllOrders({
      ...this.unboxed,
      db: this.unboxed.db,
      ref: this.unboxed
        .db()
        .collection(restrictedCollections.orderObservations)
        .withConverter<Order>(firestoreConverter<Order>()),
      root: this,
    })

  liveOrders = (): OrderCollection =>
    new FirebaseLiveOrders({
      ...this.unboxed,
      db: this.unboxed.db,
      ref: this.unboxed
        .db()
        .collection(restrictedCollections.orderObservations)
        .withConverter<Order>(firestoreConverter<Order>())
        .where("_derived.liveUntil", ">", new Date()),
      root: this,
    })

  company = (id: string): CompanyRef =>
    new FirebaseCompany({
      ...this.unboxed,
      id,
      db: this.unboxed.db,
      ref: this.unboxed
        .db()
        .collection(collections.companies)
        .withConverter<Company>(firestoreConverter<Company>())
        .doc(id),
      root: this,
    })

  companies = (): CompanyCollection =>
    new FirebaseCompanies({
      ...this.unboxed,
      db: this.unboxed.db,
      ref: this.unboxed
        .db()
        .collection(collections.companies)
        .withConverter<Company>(firestoreConverter<Company>()),
      root: this,
    })
}
