import { UserFunctions } from "common/model/User"
import { deprecatedIsLoaded } from "common/utils/Loading"
import { ReactNode, useEffect, useState } from "react"
import { useFirebaseWriter } from "../../firebase/Context"
import { useCurrentUser } from "../currentUser/useCurrentUser"
import { isEmbed } from "../embed/utils"
import { useFeatureFlag } from "../featureFlags/useFeatureFlags"
import { useLocation } from "react-router-dom"
import { Routes } from "src/Routes/Routes"

export const IntercomPositioning = {
  leftPaddingDefault: 260,
  leftPaddingCollapsed: 80,
  verticalPadding: 30,
}

export const IntercomChatProvider = ({ children }: { children: ReactNode }) => {
  const intercomEnabled = useFeatureFlag("intercom_chat")
  const currentUser = useCurrentUser()
  const firebase = useFirebaseWriter()
  const location = useLocation()

  const [intercomLoaded, setIntercomLoaded] = useState(false)

  useEffect(() => {
    if (
      !isEmbed() &&
      intercomEnabled &&
      deprecatedIsLoaded(currentUser) &&
      currentUser?.user &&
      window.Intercom
    ) {
      if (!intercomLoaded) {
        window.intercomSettings = {
          alignment: "left",
          horizontal_padding: IntercomPositioning.leftPaddingDefault,
          vertical_padding: IntercomPositioning.verticalPadding,
        }
        window.Intercom("boot", {
          api_base: "https://api-iam.intercom.io",
          app_id: "ymfgkcix",
          name: UserFunctions.name(currentUser.user),
          email: currentUser.user.email,
          user_id: currentUser.user.id,
          companies: [
            {
              id: currentUser.user.account.id,
              name: currentUser.user.account.name,
              product_areas: currentUser.user.account.productAreas.join(", "),
              client_type: currentUser.user.account.clientType.join(", "),
            },
          ],
          created_at: currentUser.user.createdAt,
        })
        window.Intercom("update", {
          hide_default_launcher: true,
        })
        setIntercomLoaded(true)
      }
    }

    if (!currentUser && intercomLoaded && window.Intercom) {
      window.Intercom("shutdown")
      setIntercomLoaded(false)
    }

    if (window.Intercom && currentUser === null && location.pathname.includes(Routes.signup)) {
      window.intercomSettings = {
        alignment: "left",
        horizontal_padding: IntercomPositioning.leftPaddingDefault,
        vertical_padding: IntercomPositioning.verticalPadding,
      }
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "ymfgkcix",
      })
      window.Intercom("update", {
        hide_default_launcher: true,
      })
      setIntercomLoaded(true)
    }
  }, [intercomEnabled, currentUser, firebase, intercomLoaded, location])

  return <>{children}</>
}
