import InfoTooltip from "@components/InfoTooltip"
import { Input } from "@stories/components/Antd"
import { FormLabel } from "@stories/components/Inputs/FormLabel/FormLabel"
import { SelectOptions } from "@stories/components/Inputs/RadioSelect/RadioSelect"
import SectionLabel from "@stories/components/Inputs/SectionLabel/SectionLabel"
import { OrderShareClass } from "common/model/Order/Order"
import { isIntermediaryContext, useAuthContext } from "src/Routes/auth/UserAuthContext"
import { MultiCheckboxSelect } from "../CheckboxSelect"
import { useEffect } from "react"
import { OrderFormState } from "common/model/Order/OrderForm/State"
import { FormOrder } from "common/model/Order/OrderForm/State"

const { TextArea } = Input

export const shareClassSelectOptions: SelectOptions<OrderShareClass>[] = [
  { label: "Common", value: "common" },
  { label: "Preferred", value: "preferred" },
  { label: "Unconfirmed", value: "unknown" },
]
export const clientShareClassSelectOptions: SelectOptions<OrderShareClass>[] = [
  { label: "Common", value: "common" },
  { label: "Preferred", value: "preferred" },
]

type ShareClassSectionData = {
  shareClasses?: OrderShareClass[]
  shareClassSeries?: string | null
}
export const ShareClassSection = ({
  value,
  onChange,
  hiddenFields,
  title = "Share Class",
  titles,
}: {
  value: ShareClassSectionData & Pick<OrderFormState, "price">
  onChange: (value: ShareClassSectionData) => void
  title?: string
  hiddenFields?: (keyof FormOrder)[]
  titles: {
    shareClass: string
    shareClassSeries: string
    shareClassSeriesTooltip: string
  }
}) => {
  const ctx = useAuthContext()
  useEffect(() => {
    if (value.price === null && value.shareClasses === undefined) {
      onChange({ ...value, shareClasses: [] })
    }
  }, [onChange, value])
  return (
    <>
      {hiddenFields?.includes("shareClasses") ? null : (
        <div className="flex flex-col mt-4">
          <SectionLabel>{title}</SectionLabel>
          <FormLabel>{titles?.shareClass}</FormLabel>
          <div>
            <MultiCheckboxSelect<OrderShareClass>
              options={
                isIntermediaryContext(ctx) ? shareClassSelectOptions : clientShareClassSelectOptions
              }
              onChange={(shareClasses) => onChange({ ...value, shareClasses })}
              value={value.shareClasses}
              disabled={hiddenFields?.includes("shareClasses")}
            />
          </div>
          {value.shareClasses?.includes("preferred") ? (
            <div className="mt-4">
              <FormLabel>
                {titles.shareClassSeries}
                <InfoTooltip text={titles.shareClassSeriesTooltip} />
              </FormLabel>
              <div>
                <TextArea
                  rows={1}
                  placeholder={titles.shareClassSeries}
                  className="w-full"
                  onChange={(e) => onChange({ ...value, shareClassSeries: e.target.value })}
                  value={value.shareClassSeries ?? undefined}
                  disabled={hiddenFields?.includes("shareClassSeries")}
                />
              </div>
            </div>
          ) : null}
        </div>
      )}
    </>
  )
}
