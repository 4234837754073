import { Account, isIntermediaryAccount } from "../Account"
import { FeatureFlagEnabledRecord } from "../FeatureFlag/FeatureFlag"

export const hasDealSearchAccess = ({
  account,
  enabledFeatureFlags,
}: {
  enabledFeatureFlags: Pick<FeatureFlagEnabledRecord, "crm" | "deal_search">
} & {
  account: Pick<Account, "clientType">
}): boolean =>
  !!enabledFeatureFlags.crm && !!enabledFeatureFlags.deal_search && isIntermediaryAccount(account)
