import { LabelValueList } from "@stories/components/LabelValueList/LabelValueList"
import { AccountIdFields } from "common/model/Account"
import { isLoaded } from "common/utils/Loading"
import { useAdminGetUsersByAccount } from "src/queries/User/useAdminGetUsersByAccount"

export const AdminAccountUserPreview = ({ account }: { account: AccountIdFields }) => {
  const allAccountUsers = useAdminGetUsersByAccount(account.id)

  return isLoaded(allAccountUsers) ? (
    <>
      <LabelValueList
        title="Users"
        items={allAccountUsers.map((user) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: `${user.email}`,
          id: user.id,
        }))}
      />
    </>
  ) : null
}
