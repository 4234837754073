import OrderDetailsButton from "@components/OrderDetails"
import { ShareOrderStatusIcon } from "@components/ShareOrder/ShareOrderStatusIcon"
import { Checkbox } from "@components/inputs/checkbox/Checkbox"
import { Tooltip } from "@stories/components/Antd"
import MarketPill from "@stories/components/Badges/MarketPill/MarketPill"
import { StatusCircle } from "@stories/components/Badges/StatusCircle/StatusCircle"
import { Button, ButtonSize } from "@stories/components/Button/Button"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { EditIcon } from "@stories/icons/EditIcon"
import {
  createOrderFormFieldsFromOrder,
  updateOrderFromForm,
} from "common/model/Order/OrderForm/Constructors"
import { Order, orderLatestQuantityTerms } from "common/model/Order/Order"
import { isOrderQuotaCompliant } from "common/model/Order/QuotaCompliance/checkAccountQuotaCompliance"
import { readableQuotaComplianceViolation } from "common/model/Order/QuotaCompliance/runQuotaComplianceChecks"
import { isLiveOrder, orderLiveUntil } from "common/model/Order/Types/Status"
import { head } from "common/utils/data/Array/ArrayUtils"
import { isNonempty, nonemptyHead } from "common/utils/data/Array/Nonempty"
import { shortDateFormat } from "common/utils/dateUtils"
import { nullableToMaybe } from "common/containers/Maybe"
import { useAccountCompliance } from "src/providers/data/AccountComplianceProvider"
import FeatureWrapper from "src/providers/featureFlags/FeatureWrapper"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import CompanyLogoAndName from "../../../components/CompanyLogoAndName"
import OrderFormButton from "../../../components/OrderForm"
import { useFirebaseWriter } from "../../../firebase/Context"
import { updateOrder } from "../../../firebase/orders"
import {
  DirectionCell,
  LocaleDateCell,
  MyOrderPriceDisplay,
  RelativeDateCell,
  SizeCell,
} from "../shared/Cells"
import { MyOrdersStructureCell } from "./MyOrdersStructureCell"
import { MyOrderStatus, MyOrdersHeader, OrderItem } from "./MyOrdersTypes"
import { OrderFlag } from "./OrderFlag"
import { RefreshButton } from "./RefreshOrderButton"
import { ShareOrderCheckbox } from "./ShareItemsProvider/ShareMyItemsProvider"
import { getMyOrderStatus } from "./utils"
import ButtonGroup from "../../../stories/components/ButtonGroup/ButtonGroup"
import { FormOrder } from "common/model/Order/OrderForm/State"
import { useCRMContacts } from "src/pages/CRM/Providers/CRMContactsProvider"
import { CRMContactName } from "src/pages/CRM/Contacts/ContactDetailsDrawer/CRMContactName"
import { useCompanies } from "src/providers/data/CompanyProvider"
import { isCompanyPublic } from "common/model/Company"
import { matchLoading } from "common/utils/Loading"
import { ShareableItem } from "common/model/SharedOrder/SharedOrderResponse"

export const StatusCell: React.FC<
  React.PropsWithChildren<{ orderStatus: MyOrderStatus; liveUntil: Date; hideMargin?: boolean }>
> = ({ orderStatus, liveUntil, hideMargin = false }) =>
  ((
    {
      Expiring: (
        <Tooltip title={`Expiring on ${shortDateFormat(liveUntil)}`}>
          <MarketPill variant="order_expiring" hideMargin={hideMargin} />
        </Tooltip>
      ),
      Expired: (
        <Tooltip title={`Expired on ${shortDateFormat(liveUntil)}`}>
          <MarketPill variant="order_expired" hideMargin={hideMargin} />
        </Tooltip>
      ),
      Active: (
        <Tooltip title={`Live until ${shortDateFormat(liveUntil)}`}>
          <MarketPill variant="order_active" hideMargin={hideMargin} />
        </Tooltip>
      ),
      "Closed Canceled": (
        <Tooltip title="Closed Canceled">
          <MarketPill variant="order_closed_canceled" hideMargin={hideMargin} />
        </Tooltip>
      ),
      Closed: (
        <Tooltip title="Closed">
          <MarketPill variant="order_closed" hideMargin={hideMargin} />
        </Tooltip>
      ),
      "Close Pending": (
        <Tooltip title="Requires recording closed trade">
          <MarketPill variant="order_closed_pending" hideMargin={hideMargin} />
        </Tooltip>
      ),
      Matched: (
        <Tooltip title="Order is Matched - In Negotiations">
          <MarketPill variant="order_matched" hideMargin={hideMargin} />
        </Tooltip>
      ),
      Unknown: null,
    } satisfies Record<MyOrderStatus, React.ReactNode>
  )[orderStatus])

export const EditButton: React.FC<
  React.PropsWithChildren<
    OrderItem & {
      iconOnly?: boolean
      size?: ButtonSize
      sourceComponent:
        | "order-details-page"
        | "my-orders-table"
        | "connect-panel"
        | "rfq-page"
        | "crm-interest-row"
    }
  >
> = ({ order, iconOnly, size, sourceComponent }) => {
  const firebase = useFirebaseWriter()
  const myOrderStatus = getMyOrderStatus(order)
  const company = useCompanies(order.company.id)

  if (!["Active", "Expired", "Expiring", "Matched", "Unknown"].includes(myOrderStatus)) return null

  // order edit
  const onEditOrderForm = (formOrder: FormOrder) =>
    updateOrder({
      mergedOrder: updateOrderFromForm(formOrder, order, { autoRenew: true }),
      db: firebase,
      updateOrderOptions: {
        updateExistingCRMInterestTerms: formOrder.updateExistingCRMInterestTerms,
      },
    })
  return (
    <OrderFormButton
      initialOrderId={order.id}
      initialFormOrder={createOrderFormFieldsFromOrder(order)}
      hiddenFields={["account", "company", "direction"]}
      onSave={onEditOrderForm}
      drawerTitle="Edit Your Order"
      renderButton={(onClick) => (
        <Tooltip title={iconOnly ? "Edit" : ""} placement="top">
          <Button
            size={size}
            leftIcon={<EditIcon />}
            isDisabled={matchLoading(company, isCompanyPublic, true, true)}
            label={!iconOnly ? "Edit" : ""}
            onClick={onClick}
            variant="secondary"
            heapName="edit-order-button"
            dataAttributes={{ "source-component": sourceComponent }}
          />
        </Tooltip>
      )}
    />
  )
}
const InteractCell: React.FC<React.PropsWithChildren<OrderItem>> = ({ order }) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  <div className="flex flex-row items-center gap-1" onClick={(e) => e.stopPropagation()}>
    <ButtonGroup>
      <OrderDetailsButton orderId={order} iconOnly />
      <EditButton order={order} iconOnly sourceComponent="my-orders-table" />
      <RefreshButton order={order} iconOnly sourceComponent="my-orders-table" />
    </ButtonGroup>
    <OrderFlag order={order} />
    <ShareOrderStatusIcon item={{ id: order.id, order, tag: "order" }} />
  </div>
)

const OrderQuotaComplianceStatus = ({ order }: { order: Order }) => {
  const { isQuotaSystemEnabled } = useAccountCompliance()
  const { user } = useLoggedInUser()
  const isBrokerLinkedOrderAndVisibleForLinkedBroker = order.brokeredBy?.user?.id === user.id
  if (!isQuotaSystemEnabled || isBrokerLinkedOrderAndVisibleForLinkedBroker) return null
  const status = !isLiveOrder(order)
    ? "default"
    : isOrderQuotaCompliant(order)
    ? "success"
    : "warning"
  const statusTooltipMap: Record<typeof status, { title: string; subtitle: string }> = {
    default: {
      title: "Disabled",
      subtitle: "Only live orders count towards order quota compliance",
    },
    success: {
      title: "Quota Requirements Met",
      subtitle: "This order meets quota requirements.",
    },
    warning: {
      title: "Quota Requirements Not Satisfied",
      subtitle: `${
        order.orderQuotaComplianceViolations && isNonempty(order.orderQuotaComplianceViolations)
          ? readableQuotaComplianceViolation(nonemptyHead(order.orderQuotaComplianceViolations))
          : ""
      }`,
    },
  }
  return (
    <FeatureWrapper flagName="is_order_quota_system_live">
      <StatusCircle
        variant={status}
        tooltip={
          <div>
            <div>
              <Typography
                text={statusTooltipMap[status].title}
                color={Color.White}
                weight={Weight.Bold}
                size={Size.XSmall}
              />
            </div>
            <div>
              <Typography
                text={statusTooltipMap[status].subtitle}
                color={Color.White}
                size={Size.XSmall}
              />
            </div>
          </div>
        }
      />
    </FeatureWrapper>
  )
}

const CompanyCell = ({ order }: { order: Order }) => {
  const item: ShareableItem = { order, id: order.id, tag: "order" }
  return (
    <div className="flex items-center gap-2">
      <ShareOrderCheckbox shareableItem={item} />
      <CompanyLogoAndName company={order.company} size="xs" />
    </div>
  )
}

const ContactCell = ({ order }: { order: Order }) => {
  const { findContactById } = useCRMContacts()
  const contact = order.crmContactId && findContactById(order.crmContactId)

  if (!contact) return null

  return <CRMContactName contact={contact} />
}

export type MyOrderRowData = { order: Order }
const headerToCell: Record<MyOrdersHeader, ({ order }: MyOrderRowData) => React.ReactNode> = {
  company: ({ order }) => <CompanyCell order={order} />,
  contact: ({ order }) => <ContactCell order={order} />,
  direction: ({ order }) => <DirectionCell direction={order.direction} />,
  origination: ({ order }) =>
    order.orderOriginationDate ? <LocaleDateCell date={order.orderOriginationDate} /> : null,
  lastUpdate: ({ order }) => <RelativeDateCell date={order.lastUpdatedAt} />,
  status: ({ order }) => (
    <StatusCell orderStatus={getMyOrderStatus(order)} liveUntil={orderLiveUntil(order)} />
  ),
  quota: ({ order }) => <OrderQuotaComplianceStatus order={order} />,
  size: ({ order }) =>
    head(orderLatestQuantityTerms(order))
      .map(({ amountUSD, shares, USDPerShare }) => ({
        USDPerShare: nullableToMaybe(USDPerShare),
        amountUSD: nullableToMaybe(amountUSD),
        shares: nullableToMaybe(shares),
      }))
      .match(
        ({ amountUSD, shares, USDPerShare }) => (
          <SizeCell amountUSD={amountUSD} shares={shares} USDPerShare={USDPerShare} />
        ),
        () => null
      ),
  price: ({ order }) => <MyOrderPriceDisplay order={order} />,
  structure: ({ order }) => <MyOrdersStructureCell order={order} />,
  interact: ({ order }) => <InteractCell order={order} />,
}

export const renderMyOrdersRowCell = (myRowOrderData: MyOrderRowData) => (header: MyOrdersHeader) =>
  (
    <td key={header} className="px-4 py-2 text-sm">
      {headerToCell[header](myRowOrderData)}
    </td>
  )
