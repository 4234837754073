import _ from "lodash"
import { Interval } from "../../../utils/data/Interval"
import { TotalMapRecord } from "../../../utils/data/TotalMap/Unwrapped"
import { shortDateFormat } from "../../../utils/dateUtils"
import { formatCurrency, formatPrice } from "../../../utils/math/format"
import { AccountIdFields, viewAccountIdFields } from "../../Account"
import { CompanyIdFields } from "../../Company" // Might make sense to factor out a more generic "DatabaseObjectIdFields"
import { OrderShareClass } from "../../Order/Order"
import { DataVisibilityFields } from "../DataPoint/VisibilityFields"
import { DataSource } from "../DataSource"
import { Structure } from "./TransactionStructure"
import { formatStructureDisplayName } from "./formatStructureDisplayName"
import { DataDisplayConstants } from "../../../UI/DataDisplayConstants"
import { Enriched } from "../../../queries/pricing/PricingEnrichment"
import { LayeredStructure } from "../../Order/Models/Internal"
import { TradeReference } from "../Trade"

export const TRADE_HISTORY_COMPANY_SEARCH_PROP = "company"

export type PriceObservationType = DataVisibilityFields & {
  company: CompanyIdFields
  createdDate: Date
  observationDate: Interval<Date>
  shareClass?: OrderShareClass | "mixed" | null
  shareClassSeries?: string | null
  id?: string
  notes?: string
  observedBy: AccountIdFields
  price: number
  volume: number
  source: DataSource
  carriedInterest: boolean | number
  managementFee: number | null
  structure: TotalMapRecord<Structure, number>
  layeredStructure?: LayeredStructure
  /** @deprecated use `trade` */
  tradeId?: string
  trade?: TradeReference
  rofr: { subjectToROFR: boolean | null; wasROFRd: boolean | null } | null
}
export const priceObservationTradeId = (obs: PriceObservationType) => obs.trade?.id ?? obs.tradeId

export type PriceObservationLog = {
  id?: string | undefined
  before: PriceObservationType | null
  after: PriceObservationType | null
  docId: string
  timestamp: number
}

export const makePriceObservation = (
  e: Pick<PriceObservationType, "price" | "volume" | "observationDate">,
  structure: Pick<PriceObservationType, "carriedInterest" | "managementFee" | "structure">,
  company: CompanyIdFields,
  account: AccountIdFields,
  source: DataSource,
  shareClass?: OrderShareClass
): PriceObservationType => ({
  createdDate: new Date(),
  company,
  shareClass: shareClass ?? null,
  shareClassSeries: null,
  source,
  observedBy: viewAccountIdFields(account),
  rofr: null,
  ...structure,
  ...e,
})

export const getMostCommonStructure = (x: TotalMapRecord<Structure, number>) =>
  _.maxBy(x.entries, (kvp) => kvp.value)?.key ?? "unknown"

export const tradeStructureString = (
  trade: Pick<
    PriceObservationType,
    "structure" | "managementFee" | "carriedInterest" | "layeredStructure"
  >
): string => {
  const structure = getMostCommonStructure(trade.structure)

  return `${formatStructureDisplayName(structure)}${
    structure === "spv"
      ? ` (${
          typeof trade.managementFee === "number"
            ? trade.managementFee
            : DataDisplayConstants.noValue
        }/${
          typeof trade.carriedInterest === "number"
            ? trade.carriedInterest
            : DataDisplayConstants.noValue
        })${trade.layeredStructure ? " (multi-layered)" : ""}`
      : ""
  }`
}

export const tradeDisplayString = (
  trade: Pick<
    PriceObservationType,
    "volume" | "structure" | "managementFee" | "carriedInterest" | "price" | "observationDate"
  >
) => ({
  sizeString: `$${formatCurrency(2, trade.volume)}`,
  priceString: formatPrice(trade.price),
  structureString: tradeStructureString(trade),
  lastUpdatedString: shortDateFormat(trade.observationDate.upperBound),
})

export const getROFROutcome = (o: Pick<PriceObservationType, "rofr">): string =>
  o.rofr
    ? o.rofr.subjectToROFR
      ? o.rofr.wasROFRd
        ? "ROFR'd"
        : "Cleared ROFR"
      : "Not Subject to ROFR"
    : "Unknown"

export const getTradeROFROutcome = (trade: Enriched<"trade">): string =>
  trade.raw.rofr
    ? trade.raw.rofr.subjectToROFR
      ? trade.raw.rofr.wasROFRd
        ? "ROFR'd"
        : "Cleared ROFR"
      : "Not Subject to ROFR"
    : "Unknown"

export type AnonymousPriceObservation = Omit<PriceObservationType, "observedBy"> & {
  observedBy: null | undefined
}

export const anonymizePriceObservation = (
  trade: PriceObservationType
): AnonymousPriceObservation => ({
  ...trade,
  observedBy: undefined,
})

export const isDuplicatePriceObservation = (x: PriceObservationType) =>
  x.trade && x.trade?.data.primaryObservationId !== x.id
