import Pill from "@components/Pill"
import Typography, { Size, Weight } from "@stories/components/Typography/Typography"
import {
  AccountNetworkNode,
  getAccountNetworkNodeFirmName,
} from "common/model/TrustedBrokerConnection"
import { CRMContactName } from "src/pages/CRM/Contacts/ContactDetailsDrawer/CRMContactName"
import { useCRMContacts } from "src/pages/CRM/Providers/CRMContactsProvider"
import { TrustedBrokerNetworkCard } from "./TrustedBrokerNetworkCard"
import { assertUnreachable } from "common/utils/fp/Function"

export const TrustedBrokerAccountNodeView = ({ node }: { node: AccountNetworkNode }) => {
  const { contacts } = useCRMContacts()
  const nodeAccountId =
    node.tag === "on platform"
      ? node.account.id
      : node.tag === "linked broker" || node.tag === "new invite"
      ? null
      : assertUnreachable(node)
  const firmContact = nodeAccountId
    ? contacts
        .flatMap((c) => (c.tag === "firm" ? [c] : []))
        .find((c) => c.platformAccountId === nodeAccountId)
    : null
  const foundContacts = firmContact
    ? contacts.flatMap((contact) =>
        contact.tag === "broker" && contact.firm?.id === firmContact.id ? [contact] : []
      )
    : []
  const primaryContact = firmContact?.contacts.find((c) => c.isPrimaryContact)
  return (
    <TrustedBrokerNetworkCard>
      <div className="flex flex-col gap-2">
        {firmContact ? (
          <div className="-ml-2 -mt-2">
            <CRMContactName
              contact={firmContact}
              size="xlarge"
              isSubtitleDisplayed={false}
              isIconDisplayed={false}
            />
          </div>
        ) : (
          <Typography
            size={Size.Large}
            weight={Weight.Semibold}
            text={getAccountNetworkNodeFirmName(node)}
          />
        )}

        {foundContacts
          .sort((a) => (a.id === primaryContact?.id ? -1 : 1))
          .map((contact) => (
            <div className="flex items-center gap-2" key={contact.id}>
              <CRMContactName
                contact={contact}
                size="small"
                isIconDisplayed={false}
                isSubtitleDisplayed={false}
              />
              {primaryContact?.id === contact.id ? (
                <Pill content="Primary Contact" textClass=" text-neutral-800" />
              ) : null}
            </div>
          ))}
      </div>
    </TrustedBrokerNetworkCard>
  )
}
