import { DealCRMFirmContact } from "common/model/DealCRM/DealCRMFirmContact"
import Typography, { Color, Weight } from "@stories/components/Typography/Typography"
import { useInvestorEmployees } from "../../../Companies/Hooks"
import { InvestorEmployeesTable } from "../../Components/InvestorEmployeesTable"
import { defaultIfLoading, isLoading } from "common/utils/Loading"

const FirmEmployeesTable = ({
  postgresWebsiteDomain,
  name,
}: Required<Pick<DealCRMFirmContact, "name" | "postgresWebsiteDomain">>) => {
  const employees = useInvestorEmployees(postgresWebsiteDomain)

  if (employees === null) return null
  return (
    <div className="flex flex-col gap-2">
      <Typography color={Color.Primary} weight={Weight.Semibold} text="Employees" />
      <InvestorEmployeesTable
        employees={defaultIfLoading(employees, [])}
        isLoading={isLoading(employees)}
      />
    </div>
  )
}

export default FirmEmployeesTable
