import _ from "lodash"
import { isDefined } from "../../utils/TypeUtils"
import {
  FlatOrderSlice,
  mergeOrderTerms,
  orderQuantityTermsPrice,
  orderQuantityTermsTargetValuation,
  OrderSlice,
  OrderTerms,
  StructureOrderTerms,
} from "./Types/Terms"
// @ts-ignore // importing symbols for jsdoc
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { OrderQuantityTerms, OrderStructure } from "./Types/Terms"
// @ts-ignore
import { dateToTimestamp, UpdateLog } from "./Types/UpdateLog"
import { Just, Maybe, Nothing, nullableToMaybe } from "../../containers/Maybe"
import { dateOrder, maxBy, minBy } from "../../utils/fp/Ord"
import { head, uniqueByRep } from "../../utils/data/Array/ArrayUtils"
import { Date_, now } from "../../utils/dateUtils"
import { identity } from "../../utils/fp/Function"
import { DualizeUnion, overField, Rec, UnsafeRec } from "../../utils/RecordUtils"
import { Interval } from "../../utils/data/Interval"
import { ArrayUnder } from "../../utils/data/Record/Types/Pointwise"
import { annotate } from "../../utils/Coerce"
import * as Internal from "./Models/Internal"
import { mergeOrderStatusFields, orderLiveUntil } from "./Types/Status"
import { deriveOrderFields } from "./Types/DerivedFields"
import { DataSource } from "../data-product/DataSource"
import { isNonempty } from "../../utils/data/Array/Nonempty"
import { ConstrainNominal } from "../../utils/types/Nominal"
import {
  makeOutlierStatus,
  mergeDeprecatedOutlierCheckResult,
} from "../data-product/DataPoint/OutlierStatusFields"
import { UnconfirmableYesNo } from "../UnconfirmableYesNo"
import { viewOrderSource, OrderSource } from "./OrderSource"

export const MAX_STRINGIFIED_ORDER_LENGTH = 100000

export type Order = Internal.Order
export type DarkpoolOrder = Internal.Order & { darkpool: true }
export type OrderIdFields = Internal.OrderIdFields
export type OrderWithMetaData = Internal.OrderWithMetaData

// eslint-disable-next-line prefer-destructuring
export const viewOrderIdFields = Internal.viewOrderIdFields

export const orderDirections = ["buy", "sell"] as const
export type OrderDirection = (typeof orderDirections)[number]

export const orderFirmnessValues = ["firm", "tentative"] as const
export type OrderFirmness = (typeof orderFirmnessValues)[number]

export type OrderDocuments = {
  diligenceAvailable?: UnconfirmableYesNo | null
  requiresDiligence?: UnconfirmableYesNo | null
  /** @deprecated * */
  brokerRecentlyClosedTradeInCompany?: boolean | null
  /** @deprecated * */
  areTransactionDocumentOnHand?: UnconfirmableYesNo | null
}

export type OrderCommission = {
  priceType: "gross" | "net-of-fees" | "unknown" | null
  canChargeClientFee?: UnconfirmableYesNo | null
  commissionNotes?: string | null // expected to be filled in by brokers; eg. "normal fee is 2-3% but will be dependent on pricing"
  /* deprecated */
  commissionType?: "percent" | "USD" | null
  /* deprecated */
  amount?: number | null
  /* deprecated */
  priceInclusiveOfFees?: UnconfirmableYesNo | null
}

export const orderShareClasses = ["preferred", "common", "unknown"] as const
export type OrderShareClass = (typeof orderShareClasses)[number]

// todo move
export type ExcludeFromField<R, K extends keyof R, V> = Omit<R, K> & {
  [key in K]: Exclude<R[K], V>
}

export type OrderWithKnownOriginationDate = ConstrainNominal<
  ExcludeFromField<Order, "orderOriginationDate", null>,
  Order
>
export const orderHasKnownOriginationDate = (
  order: Order
): order is OrderWithKnownOriginationDate => order.orderOriginationDate !== null

/** Returns {@link Just} the {@link OrderIdFields} of `l` if  `r` and `l` can be merged. Assumes both l and r are for the same company */
export const mergeCompanyOrderIdFields = (
  l: OrderIdFields,
  r: ConstructedOrder
): Maybe<OrderIdFields> =>
  l.source.sourceId === r.source.sourceId &&
  l.source.sourceId !== null &&
  l.direction === r.direction
    ? Just(viewOrderIdFields(l))
    : Nothing

// returns order with most recent `orderUpdatedAt` or `orderOriginationDate`
// prefers order with definable recent date if one order has undefined recent date
// prefers `l` if neither order has definable recent date
const getMostRecentOrder = (
  l: ConstructedOrder,
  r: ConstructedOrder,
  idFields: OrderIdFields
): ConstructedOrder => {
  const rightMostRecentDate = lastOrderUpdateOrOrigination({ ...r, ...idFields })
  const leftMostRecentDate = lastOrderUpdateOrOrigination({ ...l, ...idFields })
  return leftMostRecentDate.valueOf() > rightMostRecentDate.valueOf() ? l : r
}

const mergeOrderFields =
  (l: OrderWithMetaData, r: ConstructedOrder, preferMostRecent?: boolean) =>
  (idFields: OrderIdFields): OrderWithMetaData => {
    const mostRecentOrder = getMostRecentOrder(l, r, idFields)
    const dataSourceHistory: DataSource[] = _.uniqBy(
      [
        ...(l.source.dataSourceHistory || []),
        ...(r.source.dataSourceHistory || []),
        viewOrderSource(idFields.source),
        viewOrderSource(r.source as DataSource), // TODO fix messed up union cases
      ],
      (a) => `${a?.sourceType || ""}${a?.sourceId || ""}${a?.documentUpload || ""}`
    ).filter(isDefined)
    const source: OrderSource & { dataSourceHistory: DataSource[] } = {
      ...idFields.source,
      dataSourceHistory,
    }
    return deriveOrderFields({
      ...idFields,
      source,
      firmness: mostRecentOrder.firmness,
      brokerClientMetadata: mostRecentOrder.brokerClientMetadata,
      terms: mergeOrderTerms(l.terms, r.terms),
      orderOriginationDate: _.min([l.orderOriginationDate, r.orderOriginationDate]) || null,
      updatedAt: uniqueByRep((d: Date) => d.valueOf())(
        l.updatedAt.concat(r.updatedAt).concat([now()])
      ),
      ...mergeOrderStatusFields(l, r),
      createdAt: minBy(dateOrder)(l.createdAt, r.createdAt),
      lastUpdatedAt: maxBy(dateOrder)(l.lastUpdatedAt, r.lastUpdatedAt),
      orderUpdatedAt: uniqueByRep((d: Date) => d.valueOf())(
        l.orderUpdatedAt.concat(r.orderUpdatedAt)
      ),
      metaData: {
        ..._.merge(l.metaData || {}, r.metaData || {}),
        priceDateAttribution: {
          status: (r.metaData || {}).priceDateAttribution?.status || "applied",
          triggerEvent: "upload",
          priceDateAttributionHistory: [
            ...((l.metaData || {}).priceDateAttribution?.priceDateAttributionHistory || []),
            ...((r.metaData || {}).priceDateAttribution?.priceDateAttributionHistory || []),
          ],
        },
      },
      shareClasses: mostRecentOrder.shareClasses,
      shareClassSeries: mostRecentOrder.shareClassSeries || null,
      lastDismissedRefreshAt: mostRecentOrder.lastDismissedRefreshAt || null,
      adminNotes: mostRecentOrder.adminNotes || null,
      privateNotes: mostRecentOrder.privateNotes || null,
      publicAdminNotes: mostRecentOrder.publicAdminNotes || null,
      orderDocuments: mostRecentOrder.orderDocuments || null,
      caplightPromotion: mostRecentOrder.caplightPromotion ?? null,
      caplightAuctionInterest: mostRecentOrder.caplightAuctionInterest ?? null,
      timeSensitive: mostRecentOrder.timeSensitive ?? null,
      commission: mostRecentOrder.commission || null,
      darkpool: mostRecentOrder.darkpool,
      linkedPriceObservationId: mostRecentOrder.linkedPriceObservationId,
      brokeredBy: mostRecentOrder.brokeredBy,
      layeredStructure: mostRecentOrder.layeredStructure,
      outlier:
        l.outlier && r.outlier
          ? mergeDeprecatedOutlierCheckResult(l.outlier, r.outlier)
          : l.outlier ?? r.outlier ?? null,
      outlierStatus: makeOutlierStatus(
        (l.outlierStatus?.outlierHistory ?? []).concat(r.outlierStatus?.outlierHistory ?? [])
      ),
      buyerCashOnHand: mostRecentOrder.buyerCashOnHand ?? null,
      crmContactId: mostRecentOrder.crmContactId ?? null,
      crmInterestId: mostRecentOrder.crmInterestId ?? null,
    })
  }

/** Merges `l` and `r`, if permitted
 *
 * By field:
 * - `liveUntil`: picks the later date
 * - `firmness`: takes most recent firmness
 * - `brokerClientMetadata`: takes most recent order
 * - `terms`: see {@link mergeOrderTerms}
 * - `createdAt`: picks the earlier date
 * - `orderOriginationDate`: picks the earlier date, coalescing `null`
 * - `updatedAt`: concatenates, then removes duplicates
 */
export const mergeCompanyOrders = (
  l: OrderWithMetaData,
  r: ConstructedOrder
): Maybe<OrderWithMetaData> => mergeCompanyOrderIdFields(l, r).map(mergeOrderFields(l, r))

// used for "merging" orders when updating an order
export const mergeEditedOrder = (l: OrderWithMetaData, r: ConstructedOrder): OrderWithMetaData =>
  mergeOrderFields(l, r, true)(viewOrderIdFields(l))

type OrderConstructorGeneratedKeys =
  | "id"
  | "createdAt"
  | "updatedAt"
  | "lastUpdatedAt"
  | "_derived"
  | "airtableId"

type OrderConstructorOptionalKeys =
  | "orderOriginationDate"
  | "orderUpdatedAt"
  | "firmness"
  | "brokerClientMetadata"
  | "shareClasses"
  | "shareClassSeries"
  | "_lastReportedStatus"
  | "_adminStatusOverride"
  | "commission"
  | "timeSensitive"
  | "sharedAccountIds"
  | "linkedPriceObservationId"
  | "isHighlighted"
  | "layeredStructure"
  | "buyerCashOnHand"

type OrderConstructorRequired = Omit<
  OrderWithMetaData,
  OrderConstructorGeneratedKeys | OrderConstructorOptionalKeys | "raw"
>

type OrderConstructorOptional = Pick<Order, OrderConstructorOptionalKeys>

/** The minimal parameters required to construct an order */
export type OrderConstructorParams = OrderConstructorRequired & Partial<OrderConstructorOptional>

type OrderDefaultValues = { [k in OrderConstructorOptionalKeys]: Order[k] }

const orderDefaultValues: OrderDefaultValues = {
  orderOriginationDate: null,
  firmness: null,
  brokerClientMetadata: null,
  orderUpdatedAt: [],
  shareClasses: [],
  shareClassSeries: null,
  _lastReportedStatus: {
    tag: "unknown",
    asOf: new Date(),
  },
  _adminStatusOverride: null,
  timeSensitive: null,
  commission: null,
  sharedAccountIds: null,
  linkedPriceObservationId: null,
  isHighlighted: false,
  layeredStructure: null,
  buyerCashOnHand: null,
}

export type ConstructedOrder = Omit<OrderWithMetaData, "id" | "_derived" | "airtableId">

/** Fills in the missing values in an `OrderConstructorParams` with defaults to yield an `Order` */
export const createOrder = (params: OrderConstructorParams): ConstructedOrder => ({
  ...orderDefaultValues,
  ...params,
  createdAt: now(),
  updatedAt: [now()],
  orderUpdatedAt: params.orderUpdatedAt
    ? params.orderUpdatedAt
    : params.orderOriginationDate
    ? [params.orderOriginationDate]
    : [],
  lastUpdatedAt: now(),
})

/** Fills in the missing values in an `OrderConstructorParams` with defaults to yield an `Order` */
export const finalizeConstructedOrder = (order: ConstructedOrder, id: Order["id"]): Order =>
  deriveOrderFields({
    ...order,
    sharedAccountIds: order.sharedAccountIds ?? null,
    id,
    airtableId: null,
  })

// helpers for orderLatestQuantityTerms, do not export
const flatOrderTerms = (x: OrderTerms): OrderTerms[keyof OrderTerms] =>
  [
    x.direct,
    x.call_option,
    x.forward,
    x.put_option,
    x.spv,
    x.structure_agnostic_regular_way,
    x.swap,
    x.variable_prepaid_forward,
    x.collective_liquidity_exchange_fund,
    x.unknown,
  ].flatMap(identity)

/** do not export */
const maxByKey = <T>(r: Record<number, T>) =>
  head(
    _.orderBy(UnsafeRec.entries(r), ([k, v]) => Number.parseInt(k as unknown as string, 10), "desc")
  )

const orderLatestTermsAsArray = ({ terms }: Pick<Order, "terms">): ArrayUnder<OrderSlice> =>
  Rec.map(terms, (x) =>
    x
      .map((y) => overField("value", (z) => maxByKey(z), y))
      .flatMap((y) =>
        y.value.match(
          (v) => [[y.key, v] as const],
          () => []
        )
      )
      .map((y) => ({
        date: new Date(Number.parseInt(y[1][0] as unknown as string, 10)), // not sure why this is a string
        terms: y[0] as StructureOrderTerms["spv"] &
          StructureOrderTerms["call_option"] &
          StructureOrderTerms["variable_prepaid_forward"], // obviously not true, but typescript refuses to infer different types for each field,
        priceAndQuantity: y[1][1] ?? null,
      }))
  )

const orderTimestampToDate = (timestamp: number | string) =>
  typeof timestamp === "string"
    ? new Date(Number.parseInt(timestamp as unknown as string, 10))
    : new Date(timestamp)

export const orderDateToTimestamp = (date: Date) => dateToTimestamp(date)

export const orderFullTermsArray = ({ terms }: Pick<Order, "terms">): ArrayUnder<OrderSlice> =>
  Rec.map(terms, (x) =>
    x.flatMap((y) => {
      const orderUpdatesForAStructure: UpdateLog<OrderQuantityTerms | null> = y.value
      const bb = UnsafeRec.entries(orderUpdatesForAStructure).map(([timestamp, v]) => ({
        // this erroneously expects timestamp to be number
        date: orderTimestampToDate(timestamp),
        terms: y.key as StructureOrderTerms["spv"] &
          StructureOrderTerms["call_option"] &
          StructureOrderTerms["variable_prepaid_forward"],
        priceAndQuantity: v ?? null,
      }))
      return bb
    })
  )

export const orderLatestTerms = (o: Pick<Order, "terms">): OrderSlice =>
  Rec.map(
    orderLatestTermsAsArray(o),
    (a) => head(_.orderBy(a, (x) => x?.date?.valueOf() ?? -1, "desc")).toNullable() ?? null
  ) as OrderSlice

export const orderLatestQuantityTerms = (x: Pick<Order, "terms">): OrderQuantityTerms[] =>
  _.orderBy(
    flatOrderTerms(x.terms).flatMap((t) => UnsafeRec.entries(t.value)),
    ([k, __]) => Number.parseInt(k as unknown as string, 10),
    "desc"
  )
    .map(([__, v]) => v)
    .flatMap((v) => (v ? [v] : []))

export const extractOrderPPS = (o: Order): Maybe<number> =>
  head(orderLatestQuantityTerms(o)).bind((t) => nullableToMaybe(orderQuantityTermsPrice(t)))
export const extractOrderTargetValuation = (o: Order) =>
  head(orderLatestQuantityTerms(o)).bind(orderQuantityTermsTargetValuation)
export const extractOrderVolume = (o: Order) =>
  head(orderLatestQuantityTerms(o)).bind((t) => nullableToMaybe(t.amountUSD))

const flattenOrderSliceHelper = (o: OrderSlice): FlatOrderSlice[] =>
  UnsafeRec.entries(o)
    .map(
      ([k, v]) =>
        [
          k,
          v,
          annotate<null | Partial<DualizeUnion<StructureOrderTerms[keyof StructureOrderTerms]>>>(
            v?.terms ?? {}
          ),
        ] as const
    )
    .map(([k, v, terms]) => ({
      date: v?.date ?? null,
      quantity: v?.priceAndQuantity ?? null,
      strike: terms?.strike ?? null,
      style: terms?.style ?? null,
      managementFee: terms?.managementFee ?? null,
      carry: terms?.carry ?? null,
      expiration: terms?.expiration ?? null,
      structure: k,
      USDPerShareForMaximumSharesDelivered: terms?.USDPerShareForMaximumSharesDelivered ?? null,
      USDPerShareForMinimumSharesDelivered: terms?.USDPerShareForMinimumSharesDelivered ?? null,
    }))

export const flattenOrderSlice = (o: OrderSlice): Maybe<FlatOrderSlice> => {
  const sorted = _.orderBy(flattenOrderSliceHelper(o), (x) => x?.date, "desc").filter(
    (x) => x.date !== null
  )
  return head(sorted)
}

export type FlatOrderSliceWithFullQuantityTerms = FlatOrderSlice &
  Required<Pick<OrderQuantityTerms, "USDPerShare" | "amountUSD" | "shares">>

export const isFlatOrderSliceWithFullQuantityTerms = (
  x: FlatOrderSlice
): x is FlatOrderSliceWithFullQuantityTerms =>
  x.quantity?.USDPerShare !== null && x.quantity?.amountUSD !== null && x.quantity?.shares !== null

export const flattenOrderSliceWithFullQuantityTerms = (
  o: OrderSlice
): Maybe<FlatOrderSliceWithFullQuantityTerms> =>
  flattenOrderSlice(o).bind((x) => (isFlatOrderSliceWithFullQuantityTerms(x) ? Just(x) : Nothing))

export const lastOrderUpdateOrOrigination = (
  order: Pick<Order, "orderUpdatedAt" | "orderOriginationDate" | "id">
): Maybe<Date> =>
  nullableToMaybe(_.maxBy(order.orderUpdatedAt, (x) => x?.valueOf()) ?? order.orderOriginationDate)

export const orderUpdateDates = (x: Order) =>
  x.orderUpdatedAt.concat(x.orderOriginationDate ? [x.orderOriginationDate] : [])
export const liveAtSomePointDuring = (x: Order, window: Interval<Date>) =>
  Interval.overlap(dateOrder)(window, {
    lowerBound: _.min(orderUpdateDates(x)) ?? Date_.minValue,
    upperBound: orderLiveUntil(x),
  })

export const updatedDateSort = (asc: "asc" | "desc") => (a: Order, b: Order) => {
  const res: 1 | -1 = asc === "asc" ? 1 : -1
  return (_.max(a.orderUpdatedAt) || 0) > (_.max(b.orderUpdatedAt) || 0) ? res : -res
}

export const orderStructures = (order: Pick<Order, "terms">) =>
  UnsafeRec.entries(order.terms)
    .filter(([__, structureTerms]) => isNonempty(structureTerms))
    .map(([structure, __]) => structure)
