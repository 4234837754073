import { cartaShareholderVerificationRoutes } from "common/model/carta/CartaShareholderVerificationRoute"
import { dealCRMRoutes } from "common/model/DealCRM/utils/dealCRMRouteUtils"
import { dealSearchRoutes } from "common/model/DealSearch/DealSearchRoutes"
import { OrderLookupFields } from "common/model/Order/Models/Internal"
import { adminOrderRoute } from "common/model/Order/OrderConstants"
import {
  sharedOrderListRouteTemplate,
  sharedOrderRoute,
} from "common/model/SharedOrder/SharedOrder"
import { TRADE_HISTORY_COMPANY_SEARCH_PROP } from "common/model/data-product/pricing/PriceObservation"

export const Routes = {
  root: "/",
  account: "/account",
  login: "/signin",
  signup: "/signup",
  /**
   * @deprecated only use for redirecting to /onboarding
   */
  legacyOnboarding: "/invite/onboarding",
  onboarding: "/onboarding",
  resetPassword: "/resetPassword",
  forgotPassword: "/forgotPassword",
  closedTrades: {
    root: "/trades/history",
    rootWithCompanyFilter: (companyId: string) =>
      `${Routes.closedTrades.root}?${TRADE_HISTORY_COMPANY_SEARCH_PROP}=${companyId}`,
  },
  tradingRegistration: "/trading-registration",
  trading: {
    onboarding: "/trading/onboarding",
    deals: "/trading/deals",
    deal: (dealId: string) => `/trading/deal/${dealId}`,
  },
  companies: {
    root: "/companies",
    company: (companyId: string) => `/companies/${companyId}`,
    companyEdit: (companyId: string) => `/companies/${companyId}/edit`,
    companyPageLocations: {
      marketPriceChartId: "market-price-chart",
      marketPriceChart: (companyId: string) =>
        `/companies/${companyId}#${Routes.companies.companyPageLocations.marketPriceChartId}`,
      fundingRoundsTab: (companyId: string) => `/companies/${companyId}?section=funding+rounds`,
    },
  },
  sectors: {
    root: "/sectors",
    sector: (sectorId: string) => `/sectors/${sectorId}`,
  },
  myOrders: "/my-indications",
  opportunityInbox: "/opportunity-inbox",
  orders: {
    root: "/market",
    order: (orderId: string) => `/indications/${orderId}`,
  },
  insights: "/insights",
  news: "/news",
  fundingRounds: "/funding-rounds",
  structurePricer: "/structurePricer",
  marketPriceEmbed: "/market-price-embed",
  widgetEmbed: "/widget-embed",
  eventFeed: "/event-feed",
  companyNewsEmbed: "/company-news-embed",
  shared: {
    order: sharedOrderRoute,
    root: sharedOrderListRouteTemplate,
  },
  admin: {
    root: "/admin/*",
    accounts: "/admin/accounts",
    accessControl: "/admin/access-control",
    analytics: "/admin/analytics",
    engagement: "/admin/engagement",
    opportunityInboxDashboard: "/admin/opportunityInboxDashboard",
    pricing: "/admin/pricing",
    auctions: "/admin/auctions",
    campaigns: "/admin/campaigns",
    holdings: "/admin/holdings", // TODO - support /:holdingId and /new
    rfqs: "/admin/rfqs",
    contracts: "/admin/contracts",
    data: "/admin/data",
    crm: "/admin/crm",
    dataUpload: "/admin/dataUpload",
    companies: "/admin/companies",
    demoReset: "/admin/demoReset",
    platformPageInvites: "/admin/platformPageInvites",
    emailTemplates: "/admin/emailTemplates",
    emailOutbox: "/admin/email-outbox",
    linkedBrokers: "/admin/linked-brokers",
    cartaOauth: "/admin/carta-oauth",
    cartaCompaniesFound: "/admin/carta-companies-found",
    cartaDashboard: "/admin/carta-dashboard",
    apiLog: "/admin/api-log",
    eventFeed: "/admin/event-feed",
    priceAlerts: "/admin/price-alerts",
    migrations: "/admin/migrations",
    orderInquiries: {
      root: "/admin/order-inquiries",
      orderInquiry: function orderInquiry(orderInquiryId: string) {
        return `${this.root}/${orderInquiryId}`
      },
    },
    tentativeInterest: {
      root: "/admin/tentative-interest",
      tentativeInterestView: function tentativeInterestView(tentativeInterestId: string) {
        return `${this.root}/${tentativeInterestId}`
      },
    },
    inbox: "/admin/inbox",
    records: {
      root: "/admin/records", // TODO - generate this structure automatically
      company: (companyId: string) => ({
        root: `/admin/records/companies/${companyId}`,
        priceObservations: (priceObservationId: string) =>
          `/admin/records/companies/${companyId}/price_observations/${priceObservationId}`,
      }),
      order: (order: OrderLookupFields) => adminOrderRoute(order),
      trade: (tradeId: string) => `/admin/records/trades/${tradeId}`,
    },
    featureFlags: "/admin/feature-flags",
    debug: "/admin/debug",
    contributorDashboard: "/admin/contributor-dashboard",
    dealDistributions: {
      root: "/admin/deal-distributions",
      distribution: function distribution(distributionId: string) {
        return `${this.root}/${distributionId}`
      },
    },
  },
  data: {
    upload: {
      root: "/data/upload",
      noTransactions: "/data/upload/no-transactions",
    },
    onboarding: "/data/onboarding",
  },
  auctions: "/auctions",
  auction: (auctionId: string) => `/auctions/${auctionId}`,
  rfqs: {
    root: "/rfqs",
    rfq: (rfqId: string) => `/rfqs/${rfqId}`,
    results: (rfqId: string) => `/rfqs/results/${rfqId}`,
  },
  holding: (holdingId: string) => `/holdings/${holdingId}`,
  holdings: {
    done: (holdingId: string) => `/holdings/${holdingId}/done`,
    overview: (holdingId: string) => `/holdings/${holdingId}/overview`,
    ownership: (holdingId: string) => `/holdings/${holdingId}/ownership`,
    nda: (holdingId: string) => `/holdings/${holdingId}/nda`,
    upload: (holdingId: string) => `/holdings/${holdingId}/upload`,
  },
  watchlistPage: "/watchlist",
  notifications: "/notifications",
  insufficientPermissions: "/insufficient-permissions",
  inbox: {
    root: "/inbox",
  },
  crm: dealCRMRoutes,
  dealSearch: dealSearchRoutes,
  external: {
    marketingSite: {
      root: "https://www.caplight.com",
      signUp: "https://www.caplight.com/#signup-form",
      termsOfUse: "https://www.caplight.com/legal/terms-of-use",
    },
  },
  email: {
    gmailSync: "mail/sync",
    inbox: "mail/inbox",
  },
  shareholders: {
    portfolio: "/portfolio",
    cartaShareholderVerification: cartaShareholderVerificationRoutes.root,
  },
}
