import { justIfNonempty } from "./Array/Nonempty"
import { Record } from "../fp/Eq"
import { Func } from "../fp/Function"
import { Maybe } from "../../containers/Maybe"
import { maxBy, minBy, Order, Ordering, PartialOrder } from "../fp/Ord"
import { ReifiedTraversal } from "../fp/optics/Traversal"

export interface Interval<X> {
  lowerBound: X
  upperBound: X
}

export module Interval {
  /**
   * Lifts a function f:X -> Y to a function Interval<X> -> Interval<Y> by applying f to both bounds.
   * Doesn't (and without Extremely Suspect use of typeof, can't) check that the result has the correct orientation.
   */
  export const map =
    <X, Y>(f: (x: X) => Y) =>
    (x: Interval<X>) => ({
      lowerBound: f(x.lowerBound),
      upperBound: f(x.upperBound),
    })
  export const lift2 =
    <X, Y, Z>(f: (x: X, y: Y) => Z) =>
    (i: Interval<X>, j: Interval<Y>): Interval<Z> => ({
      lowerBound: f(i.lowerBound, j.lowerBound),
      upperBound: f(i.upperBound, j.upperBound),
    })

  /** Join two intervals by taking the min of their lower bounds and the max of their upper bounds,
   *  according to the supplied comparison function.  */
  export const concat =
    <X>(compare: (a: X, b: X) => Ordering) =>
    (i: Interval<X>, j: Interval<X>) => ({
      lowerBound: minBy(compare)(i.lowerBound, j.lowerBound),
      upperBound: maxBy(compare)(i.upperBound, j.upperBound),
    })
  export const eq = <X>(f: (l: X, r: X) => boolean) =>
    Record.eq<Interval<X>>({
      lowerBound: f,
      upperBound: f,
    })
  export const pure = <X>(x: X): Interval<X> => ({
    lowerBound: x,
    upperBound: x,
  })

  export const bounds = <S, T = S>(): ReifiedTraversal<Interval<S>, Interval<T>, S, T> =>
    new ReifiedTraversal<Interval<S>, Interval<T>, S, T>({
      views: (x) => [x.lowerBound, x.upperBound],
      over: (s, f) => Interval.map(f)(s),
    })

  export const overlap =
    <X>(f: Order<X>) =>
    (i: Interval<X>, j: Interval<X>): boolean =>
      f(i.lowerBound, j.lowerBound) !== f(i.upperBound, j.lowerBound) ||
      f(i.lowerBound, j.upperBound) !== f(i.upperBound, j.upperBound) ||
      isSubinterval(f)(i, j) ||
      isSubinterval(f)(j, i)

  export const traverse = <S, T>() =>
    new ReifiedTraversal<Interval<S>, Interval<T>, S, T>({
      views: (s) => [s.lowerBound, s.upperBound],
      over: (s, f) => ({ lowerBound: f(s.lowerBound), upperBound: f(s.upperBound) }),
    })
}

export const closedIntervalContains =
  <T>(order: Order<T> | PartialOrder<T>) =>
  (interval: Interval<T>, point: T): boolean =>
    order(interval.lowerBound, point) === "<=" && order(point, interval.upperBound) === "<="
export const openIntervalContains =
  <T>(order: Order<T>) =>
  (interval: Interval<T>, point: T): boolean =>
    order(interval.upperBound, point) === ">" && order(point, interval.lowerBound) === ">"
export const isSubinterval =
  <T>(order: Order<T>) =>
  (sub: Interval<T>, sup: Interval<T>) =>
    closedIntervalContains(order)(sup, sub.lowerBound) &&
    closedIntervalContains(order)(sup, sub.upperBound)
/**
 * Returns the array `[x.lowerBound, step(x.lowerBound), step(step(x.lowerBound)), ... , final]`, where `final` is the first iterate such that `step(final) > x.upperBound`
 * Diverges if no such `final` exists.
 * */
export const sampleIntervalAsc = <X>(step: (x: X) => X, order: Order<X>, x: Interval<X>): X[] => {
  const result: X[] = [x.lowerBound]
  while (order(x.upperBound, result[result.length - 1]) === ">") {
    const next = step(result[result.length - 1])
    result.push(next)
  }
  return result
}

/**
 * Returns the array `[x.upperBound, step(x.upperBound), step(step(x.upperBound)), ... , final]`, where `final` is the first iterate such that `step(final) < x.lowerBound`
 * Diverges if no such `final` exists.
 * */
export const sampleIntervalDesc = <X>(step: (x: X) => X, order: Order<X>, x: Interval<X>): X[] => {
  const result: X[] = [x.upperBound]
  while (order(result[result.length - 1], x.lowerBound) === ">") {
    const next = step(result[result.length - 1])
    result.push(next)
  }
  return result
}

export type LineOrientation = "asc" | "desc" // abbreviated for consistency with firebase

export type Equivalence<X> = (x: X, y: X) => boolean

/** Returns "asc" if `upperBound > lowerBound`, and "desc" otherwise. */
export const intervalOrientation = <X>(order: Order<X>, x: Interval<X>) =>
  order(x.upperBound, x.lowerBound) === ">" ? "asc" : "desc"

/** Returns an interval with lower and upper endpoints given by the max and min of `a` and `b` respectively, as determined by `order`. */
export const orientedInterval =
  <X>(order: Order<X>) =>
  (a: X, b: X): Interval<X> => ({
    lowerBound: minBy(order)(a, b),
    upperBound: maxBy(order)(a, b),
  })

/** Returns "asc" if `upperBound > lowerBound`, "desc" if `upperBound < lowerBound`, and `undefined` if `upperBound = lowerBound`. */
export const intervalEqOrientation = <X>(
  order: Order<X>,
  eq: Equivalence<X>,
  x: Interval<X>
): LineOrientation | undefined =>
  eq(x.upperBound, x.lowerBound) ? undefined : intervalOrientation(order, x)

export const minMaxNumber: Func<number[], Maybe<Interval<number>>> = (xs) =>
  justIfNonempty(xs).map((x) => ({
    lowerBound: Math.min(...x),
    upperBound: Math.max(...x),
  }))

export const intervalToTuple = <T>(i: Interval<T>): [T, T] => [i.lowerBound, i.upperBound]

export const showInterval =
  <T>(f: (t: T) => string, collapseDegenerate: boolean = false) =>
  (t: Interval<T>) =>
    // `${f(t.lowerBound)} - ${f(t.upperBound)}`
    !collapseDegenerate || t.upperBound !== t.lowerBound
      ? `${f(t.lowerBound)} - ${f(t.upperBound)}`
      : `${f(t.lowerBound)}`
