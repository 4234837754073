import { hasUserCompletedRequiredOnboarding } from "common/model/User"
import { isLoaded } from "common/utils/Loading"
import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Routes } from "src/Routes/Routes"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import { isEmbed } from "src/providers/embed/utils"

const loggedInBypassOnboardingRoutes: string[] = [
  Routes.rfqs.root,
  Routes.data.upload.root,
  Routes.data.onboarding,
  Routes.insufficientPermissions,
  Routes.shared.root,
]

export const useOnboardingRedirect = () => {
  const currentUser = useCurrentUser()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const isBypassedRoute = loggedInBypassOnboardingRoutes.some((route) =>
      location.pathname.startsWith(route)
    )

    if (isBypassedRoute || isEmbed()) {
      return
    }

    if (isLoaded(currentUser) && !hasUserCompletedRequiredOnboarding(currentUser.user)) {
      navigate(Routes.onboarding, { replace: true })
    }
  }, [location.pathname, navigate, currentUser])
}
