import InfoTooltip from "@components/InfoTooltip"
import { Radio } from "@stories/components/Antd"
import { FormLabel } from "../FormLabel/FormLabel"
import Typography, { Color, Size } from "@stories/components/Typography/Typography"
import { classNames } from "src/utils/classNames"

export type SelectOptions<T> = { label: string; value: T; subLabel?: string; tooltip?: string }

export type RadioSelectProps<T> = {
  value?: T
  label?: string
  tooltip?: string
  onChange: (updatedDirection: T) => void
  options?: SelectOptions<T>[]
  disabled?: boolean
  className?: string
  hasError?: boolean
  variant?: "default" | "border"
}
export function RadioSelect<T>({
  value,
  label,
  tooltip,
  onChange,
  disabled,
  hasError = false,
  options = [],
  className = "",
  variant = "default",
}: RadioSelectProps<T>) {
  return (
    <div className="flex flex-col">
      <FormLabel hasError={hasError}>
        {label}
        {tooltip ? <InfoTooltip text={tooltip} /> : null}
      </FormLabel>
      <div
        className={classNames(
          className,
          "flex items-center flex-wrap",
          variant === "default" ? "space-y-1" : "space-y-2"
        )}
      >
        {options.map((option) => (
          <Radio
            className={classNames(
              variant === "border" && "p-4 border rounded w-full",
              variant === "border" &&
                (value === option.value
                  ? "border-primary-500 outline outline-primary-500"
                  : "border-neutral-400")
            )}
            checked={value === option.value}
            onClick={() => onChange(option.value)}
            disabled={disabled}
            key={option.label}
          >
            <div className="flex flex-col gap-1">
              <div className="flex items-center">
                {option.label}
                {option.tooltip ? <InfoTooltip text={option.tooltip} /> : null}
              </div>
              {option.subLabel ? (
                <Typography text={option.subLabel} color={Color.Subtitle} size={Size.XSmall} />
              ) : null}
            </div>
          </Radio>
        ))}
      </div>
    </div>
  )
}
