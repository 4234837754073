import { Modal, useModal } from "@components/layout/Modal"
import { FC, useCallback, useEffect, useMemo } from "react"
import { useNavigate, useLocation, NavigateFunction, Location, To } from "react-router-dom"
import {
  COLLECTION_SEARCH_PROP,
  DARKPOOL_ORDER_DETAIL_SEARCH_PROP,
  DARKPOOL_ORDER_EXPIRE_SEARCH_PROP,
  DARKPOOL_ORDER_REFRESH_SEARCH_PROP,
  DETAIL_TAB,
  ORDER_DETAIL_SEARCH_PROP,
  ORDER_EXPIRE_SEARCH_PROP,
  ORDER_REFRESH_SEARCH_PROP,
  OrderDetailsTabId,
} from "common/model/Order/MiscOrderUIStuffThatNeedsToBeCleanedUp"
import OrderPageDrawer from "../../pages/Orders/OrderPage/OrderPageDrawer"
import { InnerOrderPageRefreshContainer } from "src/pages/Orders/OrderPage/OrderPageRefreshContainer"
import { useOrder } from "src/queries/Order/useOrder"
import { deprecatedIsLoaded } from "common/utils/Loading"
import { OrderLookupFields } from "common/model/Order/Models/Internal"
import { InnerOrderPageExpireContainer } from "src/pages/Orders/OrderPage/OrderPageExpireContainer"
import { Order } from "common/model/Order/Order"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import { handleConsoleError } from "src/utils/Tracking"
import { markOpportunityIsReadByOrder } from "src/firebase/opportunities"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"

const resetOrderDetailsSearch = (location: Location): URLSearchParams => {
  const search = new URLSearchParams(location.search)
  search.delete(ORDER_REFRESH_SEARCH_PROP)
  search.delete(DARKPOOL_ORDER_REFRESH_SEARCH_PROP)
  search.delete(ORDER_DETAIL_SEARCH_PROP)
  search.delete(DARKPOOL_ORDER_DETAIL_SEARCH_PROP)
  search.delete(ORDER_EXPIRE_SEARCH_PROP)
  search.delete(DARKPOOL_ORDER_EXPIRE_SEARCH_PROP)
  search.delete(DETAIL_TAB)
  search.delete(COLLECTION_SEARCH_PROP)
  return search
}
export const getOrderDetailsRoute = (
  location: Location,
  orderId: OrderLookupFields,
  tab?: OrderDetailsTabId
): To => {
  const search = resetOrderDetailsSearch(location)
  search.set(ORDER_DETAIL_SEARCH_PROP, orderId.id)
  search.set(
    COLLECTION_SEARCH_PROP,
    orderId.orderCollection ?? (orderId.darkpool ? "darkpool" : "platform")
  )
  if (tab) {
    search.set(DETAIL_TAB, tab)
  }
  return { search: search.toString() }
}
const openOrderDetails = (
  navigate: NavigateFunction,
  location: Location,
  orderId: OrderLookupFields,
  tab?: OrderDetailsTabId
) => {
  navigate(getOrderDetailsRoute(location, orderId, tab))
}

const closeOrderRefresh = (navigate: NavigateFunction, location: Location) => {
  const search = new URLSearchParams(location.search)
  search.delete(ORDER_REFRESH_SEARCH_PROP)
  search.delete(DARKPOOL_ORDER_REFRESH_SEARCH_PROP)
  search.delete(COLLECTION_SEARCH_PROP)
  navigate({ search: search.toString() })
}
const openOrderRefresh = (
  navigate: NavigateFunction,
  location: Location,
  orderId: OrderLookupFields
) => {
  const search = new URLSearchParams(location.search)
  search.set(ORDER_REFRESH_SEARCH_PROP, orderId.id)
  search.set(
    COLLECTION_SEARCH_PROP,
    orderId.orderCollection ?? (orderId.darkpool ? "darkpool" : "platform")
  )
  navigate({ search: search.toString() })
}
const closeOrderExpire = (navigate: NavigateFunction, location: Location) => {
  const search = new URLSearchParams(location.search)
  search.delete(ORDER_EXPIRE_SEARCH_PROP)
  search.delete(DARKPOOL_ORDER_EXPIRE_SEARCH_PROP)
  navigate({ search: search.toString() })
}
const openOrderExpire = (
  navigate: NavigateFunction,
  location: Location,
  orderId: OrderLookupFields
) => {
  const search = new URLSearchParams(location.search)
  search.set(ORDER_EXPIRE_SEARCH_PROP, orderId.id)
  search.set(
    COLLECTION_SEARCH_PROP,
    orderId.orderCollection ?? (orderId.darkpool ? "darkpool" : "platform")
  )
  navigate({ search: search.toString() })
}

export const useOrderDetailsDrawer = () => {
  const currentUser = useCurrentUser()
  const db = useFirebase9()
  const navigate = useNavigate()
  const location = useLocation()
  return useCallback(
    (orderId: OrderLookupFields, tab?: OrderDetailsTabId) => {
      if (deprecatedIsLoaded(currentUser)) {
        openOrderDetails(navigate, location, orderId, tab)
        markOpportunityIsReadByOrder(db, orderId, currentUser.user).catch(handleConsoleError)
      }
    },
    [db, location, navigate, currentUser]
  )
}
export const useOrderRefreshModal = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const open = useCallback(
    (orderId: OrderLookupFields) => openOrderRefresh(navigate, location, orderId),
    [location, navigate]
  )
  const close = useCallback(() => closeOrderRefresh(navigate, location), [location, navigate])
  return { open, close }
}

const OrderRefreshModal = ({ orderId }: { orderId: OrderLookupFields }) => {
  const { close } = useOrderRefreshModal()
  const order = useOrder(orderId)
  return (
    <>
      {deprecatedIsLoaded(order.unboxed) ? (
        <Modal
          open
          handleClose={close}
          maskClosable
          body={
            <div className="w-175 -mt-2 -mb-4">
              <InnerOrderPageRefreshContainer order={order} />
            </div>
          }
        />
      ) : null}
    </>
  )
}

export const useOrderExpireModal = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const open = useCallback(
    (orderId: OrderLookupFields) => openOrderExpire(navigate, location, orderId),
    [location, navigate]
  )
  const close = useCallback(() => closeOrderExpire(navigate, location), [location, navigate])
  return { open, close }
}

const OrderExpireModal = ({ orderId }: { orderId: OrderLookupFields }) => {
  const { close } = useOrderExpireModal()
  const order = useOrder(orderId)
  return (
    <>
      {deprecatedIsLoaded(order.unboxed) ? (
        <Modal
          open
          handleClose={close}
          maskClosable
          body={
            <div className="w-175 -mt-2 -mb-4">
              <InnerOrderPageExpireContainer order={order} />
            </div>
          }
        />
      ) : null}
    </>
  )
}

const OrderDetailsWrapper: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { openModal, closeModal, open } = useModal()

  const location = useLocation()
  const navigate = useNavigate()
  const search = useMemo(() => new URLSearchParams(location.search), [location.search])

  const litOrderId = search.get(ORDER_DETAIL_SEARCH_PROP)
  const darkpoolOrderId = search.get(DARKPOOL_ORDER_DETAIL_SEARCH_PROP)
  const orderId = litOrderId ?? darkpoolOrderId

  const litRefreshId = search.get(ORDER_REFRESH_SEARCH_PROP)
  const darkpoolRefreshId = search.get(DARKPOOL_ORDER_REFRESH_SEARCH_PROP)
  const refreshId = litRefreshId ?? darkpoolRefreshId

  const litExpireId = search.get(ORDER_EXPIRE_SEARCH_PROP)
  const darkpoolExpireId = search.get(DARKPOOL_ORDER_EXPIRE_SEARCH_PROP)
  const expireId = litExpireId ?? darkpoolExpireId

  const isDarkpool = !!(darkpoolOrderId ?? darkpoolRefreshId ?? darkpoolExpireId)
  const orderCollection = search.get(COLLECTION_SEARCH_PROP) as Order["orderCollection"]

  useEffect(() => {
    if (orderId) {
      openModal()
    }
  }, [openModal, orderId])

  const handleClose = () => {
    closeModal()
    const resetSearch = resetOrderDetailsSearch(location)
    navigate({ search: resetSearch.toString() })
  }

  return (
    <>
      {orderId ? (
        <OrderPageDrawer
          open={open}
          onClose={handleClose}
          orderId={{
            id: orderId,
            darkpool: isDarkpool,
            orderCollection: orderCollection ?? (isDarkpool ? "darkpool" : "platform"),
          }}
          refresh={!!refreshId}
          expire={!!expireId}
        />
      ) : refreshId ? (
        <OrderRefreshModal
          orderId={{
            id: refreshId,
            darkpool: isDarkpool,
            orderCollection: orderCollection ?? (isDarkpool ? "darkpool" : "platform"),
          }}
        />
      ) : expireId ? (
        <OrderExpireModal
          orderId={{
            id: expireId,
            darkpool: isDarkpool,
            orderCollection: orderCollection ?? (isDarkpool ? "darkpool" : "platform"),
          }}
        />
      ) : null}

      {children}
    </>
  )
}

export default OrderDetailsWrapper
