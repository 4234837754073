import { Button } from "@stories/components/Button/Button"
import { FormLabel } from "@stories/components/Inputs/FormLabel/FormLabel"
import SearchSelect from "@stories/components/Inputs/SearchSelect/SearchSelect"
import Typography, { Size } from "@stories/components/Typography/Typography"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { inferClientRelationshipFromContact } from "common/model/Order/OrderForm/Constructors"
import { FormOrder } from "common/model/Order/OrderForm/State"
import { isLoaded } from "common/utils/Loading"
import { CRMContactName } from "src/pages/CRM/Contacts/ContactDetailsDrawer/CRMContactName"
import { useCRMContacts } from "src/pages/CRM/Providers/CRMContactsProvider"
import { useOrderForm } from "src/pages/Orders/OrderForm/OrderFormContext"
import { userCanCreateOrderFromCRM } from "src/pages/Orders/shared/permissions"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import { useFeatureFlag } from "src/providers/featureFlags/useFeatureFlags"

type CRMContactSectionData = Pick<FormOrder, "crmContactId" | "crmInterestId">
type CRMContactSectionProps = {
  value: CRMContactSectionData
  onChange: (c: CRMContactSectionData & Partial<Pick<FormOrder, "clientRelationship">>) => void
}

const CRMContactSectionSearch = ({ value, onChange }: CRMContactSectionProps) => {
  const { findContactById, contacts } = useCRMContacts()
  const selectedContact = value.crmContactId ? findContactById(value.crmContactId) : undefined
  const { initialFormOrder } = useOrderForm()
  const isDisabled = !!initialFormOrder.crmContactId

  return (
    <div className="flex flex-col">
      {isDisabled ? null : <FormLabel>Select Contact (optional)</FormLabel>}
      <Typography text="This information is private to your account" size={Size.Small} />
      {selectedContact ? (
        <div className="grid grid-cols-2 gap-4 w-full">
          <div className="flex w-full justify-between items-center">
            <CRMContactName contact={selectedContact} />
            {isDisabled ? null : (
              <Button
                label="Clear"
                onClick={() =>
                  onChange({
                    crmContactId: null,
                    crmInterestId: null,
                    clientRelationship: undefined,
                  })
                }
                variant="hollow"
                heapName="clear-crm-contact-on-order-form"
              />
            )}
          </div>
        </div>
      ) : (
        <SearchSelect<DealCRMContact>
          id="crm-contact-section-search"
          onChange={(contact) =>
            contact
              ? onChange({
                  crmContactId: contact.id,
                  clientRelationship: inferClientRelationshipFromContact(contact),
                })
              : onChange({ crmContactId: null, clientRelationship: undefined })
          }
          defaultOptions
          loadOptions={(search) =>
            Promise.resolve(
              contacts.filter((c) => c.name.toLowerCase().includes(search.toLowerCase()))
            )
          }
          renderOption={(c) => c.name}
          getOptionLabel={(c) => c.name}
          getOptionValue={(c) => c.id}
          shouldMaskDataInDataDog
          isDisabled={isDisabled}
        />
      )}
    </div>
  )
}

const CRMContactSection = ({ ...props }: CRMContactSectionProps) => {
  const isCRMEnabled = useFeatureFlag("crm")
  const currentUser = useCurrentUser()
  const userIsAllowedToCreateFromCRM =
    isLoaded(currentUser) && userCanCreateOrderFromCRM(currentUser)
  if (!isCRMEnabled || !userIsAllowedToCreateFromCRM) return null
  return (
    <div className="flex flex-col mb-4">
      <CRMContactSectionSearch {...props} />
    </div>
  )
}

export default CRMContactSection
