import { Button } from "@stories/components/Button/Button"
import { useNoteModal } from "../NoteModalProvider"
import { useKeyboardShortcutText } from "src/utils/hooks/useKeyboardShortCut"

const AiVoiceNoteButton = () => <div />

export const NoteModalFooter = () => {
  const { editor, onSaveNote, allowSave, saveStatus } = useNoteModal()
  const shortcutText = useKeyboardShortcutText("Enter")

  return (
    <div className="flex justify-between p-2">
      <AiVoiceNoteButton />
      <Button
        label={`Save Note (${shortcutText})`}
        onClick={() => (editor ? onSaveNote(editor) : null)}
        isDisabled={!allowSave}
        isLoading={saveStatus === "loading"}
      />
    </div>
  )
}
