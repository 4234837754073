import OrderFormButton from "@components/OrderForm"
import { Button } from "@stories/components/Button/Button"
import { PlusIcon } from "@stories/icons/PlusIcon"
import { FormOrder } from "common/model/Order/OrderForm/State"
import { Order } from "common/model/Order/Order"
import { useOnSaveNewFormOrder } from "./useOnSaveNewFormOrder"
import { useAddOrderPermission } from "./permissions"
import { AccessControlWrapper } from "src/providers/AccessControl/AccessControlWrapper"

export const OrderPageOrderButton = ({
  defaultInitialFormOrder = {},
  afterSave,
  buttonTextOverride,
  buttonIsDisabled,
}: {
  defaultInitialFormOrder?: Partial<FormOrder>
  afterSave?: (o: Order | undefined) => void
  buttonTextOverride?: string
  buttonIsDisabled?: boolean
}) => {
  const onSaveNewFormOrder = useOnSaveNewFormOrder({
    afterSave,
    initialFormOrder: defaultInitialFormOrder,
  })

  const showOrderButton = useAddOrderPermission()
  return (
    <AccessControlWrapper feature="liveMarketParticipation">
      {showOrderButton ? (
        <OrderFormButton
          onSave={onSaveNewFormOrder}
          hiddenFields={[]}
          initialFormOrder={defaultInitialFormOrder}
          renderButton={(onClick: React.MouseEventHandler<Element>) => (
            <Button
              onClick={onClick}
              size="large"
              variant="primary"
              leftIcon={<PlusIcon />}
              label={buttonTextOverride ?? "Add Order"}
              isTextWrapped
              heapName="new-indication-btn"
              isDisabled={buttonIsDisabled}
            />
          )}
          drawerTitle="Submit New Order"
        />
      ) : null}
    </AccessControlWrapper>
  )
}
