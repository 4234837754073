import { wrapIcon } from "./IconWrapper"

export const FlagIcon = wrapIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.00876 2.43C1.92712 2.50077 1.86173 2.58834 1.81707 2.68672C1.77241 2.7851 1.74954 2.89196 1.75001 3V13.5C1.75001 13.6989 1.82902 13.8897 1.96968 14.0303C2.11033 14.171 2.30109 14.25 2.50001 14.25C2.69892 14.25 2.88969 14.171 3.03034 14.0303C3.17099 13.8897 3.25001 13.6989 3.25001 13.5V10.8575C4.67751 9.7875 5.88126 10.2875 7.66751 11.17C8.68188 11.67 9.83876 12.245 11.0925 12.245C12.0125 12.245 12.985 11.9363 13.9913 11.065C14.0723 10.9948 14.1373 10.908 14.1819 10.8106C14.2265 10.7131 14.2498 10.6072 14.25 10.5V3C14.25 2.8562 14.2087 2.71542 14.131 2.59444C14.0533 2.47345 13.9424 2.37737 13.8116 2.31763C13.6808 2.25789 13.5355 2.23701 13.3932 2.25749C13.2509 2.27797 13.1174 2.33894 13.0088 2.43313C11.4644 3.77125 10.2256 3.265 8.33251 2.32813C6.57438 1.45438 4.38563 0.371253 2.00876 2.43ZM12.75 10.1413C11.3225 11.2119 10.1188 10.7106 8.33251 9.82875C6.88501 9.11 5.14563 8.25 3.25001 9.11688V3.35563C4.67751 2.28563 5.88126 2.78563 7.66751 3.66813C8.68188 4.16813 9.83876 4.74313 11.0925 4.74313C11.6648 4.74398 12.2305 4.62007 12.75 4.38V10.1413Z"
      fill="currentColor"
    />
  </svg>
)
