import { Button } from "@stories/components/Button/Button"
import { XIcon } from "@stories/icons/XIcon"

export const FilterPill = ({ label, onClick }: { label: string; onClick: () => void }) => (
  <Button
    size="small"
    onClick={onClick}
    variant="neutral"
    label={label}
    rightIcon={<XIcon size="small" />}
  />
)
