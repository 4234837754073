export type PillVariant =
  | "offer"
  | "bid"
  | "offers"
  | "bids"
  | "rofrd_trade"
  | "closed_trade"
  | "order_expiring"
  | "order_expired"
  | "order_active"
  | "order_closed"
  | "order_closed_canceled"
  | "order_closed_pending"
  | "order_matched"
  | "announced"
  | "results_ready"
  | "deal"
  | "order_placed"

const variantStyleMap: Record<PillVariant, string> = {
  offer: "bg-danger-400 border-danger-500 text-white border",
  bid: "bg-success-400 border-success-500 text-white border",
  offers: "bg-danger-400 border-danger-500 text-white border",
  bids: "bg-success-400 border-success-500 text-white border",
  closed_trade: "bg-accent-400 border-accent-600 text-white border",
  rofrd_trade: "bg-white border-accent-400 text-accent-500 border-2",
  order_expiring: "bg-warning-300 border-warning-700 text-warning-900 border",
  order_expired: "bg-neutral-300 border-neutral-700 text-neutral-900 border",
  order_active: "bg-success-100 border-success-500 text-success-800 border",
  order_closed_canceled: "bg-neutral-300 border-neutral-700 text-neutral-900 border",
  order_closed: "bg-accent-100 border-accent-600 text-accent-900 border",
  order_closed_pending: "bg-warning-300 border-warning-700 text-warning-900 border",
  order_matched: "bg-neutral-100 border-accent-600 text-accent-900 border",
  order_placed: "bg-neutral-100 border-accent-600 text-accent-900 border",
  announced: "bg-neutral-100 border-accent-600 text-accent-900 border",
  results_ready: "bg-neutral-100 border-accent-600 text-accent-900 border",
  deal: "bg-accent-400 border-accent-600 text-white border",
}

const variantSizeMap = (variant: PillVariant, size: "sm" | "md") => {
  const standardSize = size === "md" ? "w-28 p-1 m-1 " : "w-12 p-1"
  const standardFit = size === "md" ? "w-fit p-1 m-1 " : "w-12 p-1"

  const map: Record<PillVariant, string> = {
    offer: standardSize,
    bid: standardSize,
    offers: "",
    bids: "",
    closed_trade: standardSize,
    rofrd_trade: standardSize,
    order_expiring: standardFit,
    order_expired: standardFit,
    order_active: standardFit,
    order_closed_canceled: standardFit,
    order_closed: standardFit,
    order_closed_pending: standardFit,
    order_matched: standardFit,
    order_placed: standardFit,
    announced: standardFit,
    results_ready: standardFit,
    deal: standardSize,
  }

  return map[variant]
}

const variantTextMap: Record<PillVariant, string> = {
  offer: "OFFER",
  bid: "BID",
  offers: "OFFERS",
  bids: "BIDS",
  rofrd_trade: "ROFR`d TRADE",
  closed_trade: "CLOSED TRADE",
  order_expiring: "Expiring",
  order_expired: "Expired",
  order_active: "Active",
  order_closed: "Closed",
  order_closed_canceled: "Cancelled",
  order_closed_pending: "Close Pending",
  order_matched: "Matched",
  announced: "ANNOUNCED",
  results_ready: "RESULTS READY",
  deal: "DEAL",
  order_placed: "ORDER PLACED",
}

interface MarketPillProps {
  variant: PillVariant
  size?: "sm" | "md"
  hideMargin?: boolean
}

const MarketPill: React.FC<React.PropsWithChildren<MarketPillProps>> = ({
  variant,
  hideMargin = false,
  size = "md",
}) => (
  <div
    className={`text-xs font-bold uppercase rounded cursor-default text-center whitespace-nowrap
        ${variantStyleMap[variant]}
        ${!hideMargin ? "m-1" : ""}
        ${variantSizeMap(variant, size)}
      `}
  >
    {variantTextMap[variant]}
  </div>
)

export default MarketPill
