import { Just, Maybe, Nothing } from "common/containers/Maybe"
import * as csv from "papaparse"
// TODO move to common
export const parseCSV = (rawCSV: string): Maybe<string[][]> => {
  const parsed = csv.parse(rawCSV)
  if (parsed.data) {
    return Just((parsed.data as string[][]).map((row) => row.map((cell) => cell.trim())))
  }
  return Nothing
}
export const exportCSV = (data: string[][]): string => csv.unparse(data)
export const filterEmpty = (data: string[][]): string[][] =>
  data.filter((row) => row.some((cell) => !!cell))
