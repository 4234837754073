import Typography from "@stories/components/Typography/Typography"
import { Order } from "common/model/Order/Models/Wrapped"
import { isLoaded } from "common/utils/Loading"
import * as W from "common/utils/Loading/Wrapped"
import { useCRMContacts } from "src/pages/CRM/Providers/CRMContactsProvider"
import ContactPopoverContent from "src/pages/CRM/Contacts/ContactDisplay/ContactPopoverContent"
import { useContactDetailsDrawer } from "src/pages/CRM/Contacts/ContactDetailsDrawer/helpers"
import OrderPageSidebarCard from "../../components/OrderPageSidebarCard"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { useAccountTrustedBrokerConnections } from "src/providers/AccountTrustedBrokerConnections"
import { getCurrentConnectedNodes } from "common/model/TrustedBrokerConnection"

export const TrustedBrokerSourceAttributionCard = ({ order }: { order: W.Loading<Order> }) => {
  const openDetailsDrawer = useContactDetailsDrawer()
  const currentTrustedBrokerConnections = useAccountTrustedBrokerConnections()
  const { user } = useLoggedInUser()
  const { findContactByEmail } = useCRMContacts()
  return order.match(
    (loadedOrder) => {
      const sourceFromPrivateNetwork = isLoaded(currentTrustedBrokerConnections)
        ? loadedOrder.privateBrokerNetworkSource(
            getCurrentConnectedNodes(user.account, currentTrustedBrokerConnections).map(
              (n) => n.account
            )
          )?.submittingUser
        : null
      if (sourceFromPrivateNetwork) {
        const contact = sourceFromPrivateNetwork
          ? findContactByEmail(sourceFromPrivateNetwork.email)
          : null
        return (
          <OrderPageSidebarCard>
            {contact ? (
              <ContactPopoverContent
                contact={contact}
                onClickView={() => openDetailsDrawer(contact.id)}
              />
            ) : (
              <Typography text={sourceFromPrivateNetwork?.email ?? "Trusted Broker Partner"} />
            )}
          </OrderPageSidebarCard>
        )
      }
      return null
    },
    null,
    null
  )
}
