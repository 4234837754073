import { collections } from "common/firestore/Collections"
import { AccountIdFields } from "common/model/Account"
import { AccountActivationKey } from "common/model/Account/AccountActivation"
import { doc, updateDoc } from "firebase/firestore"
import { FirebaseWriter } from "src/firebase/Firebase"
import Firebase9 from "src/firebase/Firebase9"

export const trackAccountActivationFirebase9 = async (
  firebase: Firebase9,
  account: AccountIdFields,
  event: AccountActivationKey
) => {
  const accountRef = doc(firebase.db, collections.accounts, account.id)
  return updateDoc(accountRef, { [`accountActivation.${event}`]: new Date() }).catch((err) => {
    throw err
  })
}
export const adminResetAccountActivationFirebase9 = async (
  firebase: Firebase9,
  account: AccountIdFields,
  event: AccountActivationKey
) => {
  const accountRef = doc(firebase.db, collections.accounts, account.id)
  return updateDoc(accountRef, { [`accountActivation.${event}`]: null }).catch((err) => {
    throw err
  })
}
export const trackAccountActivationFirebaseWriter = async (
  firebase: FirebaseWriter,
  account: AccountIdFields,
  event: AccountActivationKey
) => {
  const accountRef = doc(firebase.db, collections.accounts, account.id)
  return updateDoc(accountRef, { [`accountActivation.${event}`]: new Date() }).catch((err) => {
    throw err
  })
}
