import { AccountEventCountsRollup } from "common/model/Analytics/EventCounterRollup"
import { firestoreConverter } from "common/model/FirestoreConverter"
import { Loading } from "common/utils/Loading"
import moment from "moment"
import { createContext, useContext, useEffect, useMemo, useState } from "react"
import AdminAccountDetailsDrawer from "../../admin/account-details-drawer/AccountDetailsDrawer"
import { useFirebaseReader } from "../../firebase/Context"
import {
  SearchParamDrawerValue,
  defaultSearchParamDrawerValue,
  useSearchParamDrawer,
} from "../../utils/hooks/useSearchParamDrawer"
import { useLoggedInUser } from "../loggedInUser/useLoggedInUser"

export const AdminEngagementDrawerContext = createContext<SearchParamDrawerValue>(
  defaultSearchParamDrawerValue
)

const SELECTED_ENGAGEMENT_ACCOUNT_ID_KEY = "selected-engagement-account-id"
export const AdminEngagementDrawerProvider = ({ children }: { children: React.ReactNode }) => {
  if (!useLoggedInUser().isAdmin) {
    // NOTE: this is just a safety measure and opportunity for Sentry to alert us
    // the parent should be checking isAdmin before rendering this component
    throw new Error("Attempted to render an admin-only component for a non-admin user")
  }

  const { selectedId, openDrawer, closeDrawer } = useSearchParamDrawer(
    SELECTED_ENGAGEMENT_ACCOUNT_ID_KEY
  )
  const [weeklyEventRollups, setWeeklyEventRollups] =
    useState<Loading<AccountEventCountsRollup[]>>("loading")
  const [monthlyEventRollups, setMonthlyEventRollups] =
    useState<Loading<AccountEventCountsRollup[]>>("loading")

  const firebase = useFirebaseReader()

  useEffect(
    () =>
      firebase
        .adminGetAccountEventCountRollupsBetweenDates(
          "week",
          moment().subtract(6, "months").toDate(),
          new Date()
        )
        .withConverter(firestoreConverter<AccountEventCountsRollup>())
        .onSnapshot((snapshot) => setWeeklyEventRollups(snapshot.docs.map((d) => d.data()))),
    [firebase]
  )

  useEffect(
    () =>
      firebase
        .adminGetAccountEventCountRollupsBetweenDates(
          "month",
          moment().subtract(3, "months").toDate(),
          new Date()
        )
        .withConverter(firestoreConverter<AccountEventCountsRollup>())
        .onSnapshot((snapshot) => setMonthlyEventRollups(snapshot.docs.map((d) => d.data()))),
    [firebase]
  )

  const value = useMemo(
    () => ({
      selectedId,
      closeDrawer,
      openDrawer,
    }),
    [selectedId, closeDrawer, openDrawer]
  )

  return (
    <AdminEngagementDrawerContext.Provider value={value}>
      {children}
      {selectedId ? (
        <AdminAccountDetailsDrawer
          accountId={selectedId}
          weeklyEventRollups={weeklyEventRollups}
          monthlyEventRollups={monthlyEventRollups}
          closeDrawer={closeDrawer}
          open={!!selectedId}
        />
      ) : null}
    </AdminEngagementDrawerContext.Provider>
  )
}

export const useAdminEngagementDrawer = () => useContext(AdminEngagementDrawerContext)
