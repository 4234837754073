import { RFQType } from "common/model/RFQ/RFQ"
import { deprecatedIsLoaded, mapLoading } from "common/utils/Loading"
import { useNonDraftRFQs } from "src/pages/RFQs/Hooks"
import { isActiveRFQ } from "src/pages/RFQs/RFQDisplayCard"
import LiveIcon from "@stories/components/Badges/LiveIcon/LiveIcon"

const RFQLiveIcon = () => {
  const activeLoadedRFQs = mapLoading((rfqs: RFQType[]) => rfqs.filter((r) => isActiveRFQ(r)))(
    useNonDraftRFQs()
  )

  const shouldDisplayLivePill = deprecatedIsLoaded(activeLoadedRFQs) && activeLoadedRFQs?.length > 0

  return shouldDisplayLivePill ? <LiveIcon size="sm" /> : null
}

export default RFQLiveIcon
