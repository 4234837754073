import { wrapIcon } from "./IconWrapper"

export const ChevronLeftIcon = wrapIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7071 2.62622C11.0976 3.01675 11.0976 3.64991 10.7071 4.04044L6.74755 7.99999L10.7071 11.9596C11.0976 12.3501 11.0976 12.9832 10.7071 13.3738C10.3166 13.7643 9.68342 13.7643 9.29289 13.3738L4.62623 8.7071C4.2357 8.31658 4.2357 7.68341 4.62623 7.29289L9.29289 2.62622C9.68342 2.2357 10.3166 2.2357 10.7071 2.62622Z"
      fill="currentColor"
    />
  </svg>
)
