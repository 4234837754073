export const useOperatingSystem = () => {
  const operatingSystem = navigator.platform.toLowerCase().includes("mac") ? "mac" : "windows"
  const isMac = operatingSystem === "mac"
  const isWindows = operatingSystem === "windows"

  return {
    operatingSystem,
    isMac,
    isWindows,
  }
}
