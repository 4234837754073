import { ExclusiveUserIdFields, userIdFieldsSchema } from "../User"
import { Schema, Schema as z } from "../../schema/Schema"

export type DocumentUploadDataSource = {
  sourceType: "document-upload"
  /** id of the order provided by the contributor */
  sourceId: string | null
  documentUpload: string
}
export const documentUploadDataSource: Schema<DocumentUploadDataSource> = z.object({
  sourceType: z.enum(["document-upload"]),
  sourceId: z.string().or(z.null()),
  documentUpload: z.string(),
})

export type UserFormDataSource = {
  sourceType: "user-form"
  sourceId: null
  submittingUser: ExclusiveUserIdFields | null // Historical records may not have this field populated
  documentUpload: null
}
export type CRMHoldingDataSource = {
  sourceType: "crm-holding"
  sourceId: null
  submittingUser: ExclusiveUserIdFields | null
  documentUpload: null
}

export const userFormDataSource: Schema<UserFormDataSource> = z.object({
  sourceType: z.enum(["user-form"]),
  sourceId: z.null(),
  documentUpload: z.null(),
  submittingUser: userIdFieldsSchema.or(z.null()),
})

export type APIDataSource = {
  sourceType: "api"
  sourceId: string | null
  documentUpload: null
  unparsedDocumentId: string
}
export const apiDataSource: Schema<APIDataSource> = z.object({
  sourceType: z.enum(["api"]),
  sourceId: z.string().or(z.null()),
  documentUpload: z.null(),
  unparsedDocumentId: z.string(),
})

export type AirtableFileDataSource = {
  sourceType: "airtable-file"
  sourceId: null
  documentUpload: null
}
export const airtableFileDataSource: Schema<AirtableFileDataSource> = z.object({
  sourceType: z.enum(["airtable-file"]),
  sourceId: z.null(),
  documentUpload: z.null(),
})

export const dataSourceTypes = [
  "document-upload",
  "user-form",
  "api",
  "airtable-file",
  "crm-holding",
] as const

export type DataSource =
  | DocumentUploadDataSource
  | UserFormDataSource
  | APIDataSource
  | AirtableFileDataSource
  | CRMHoldingDataSource
export const dataSource = documentUploadDataSource
  .or(userFormDataSource)
  .or(apiDataSource)
  .or(airtableFileDataSource)

export const isDocumentUploadDataSource = (x: DataSource): x is DocumentUploadDataSource =>
  x.sourceType === "document-upload"
export const isUserFormDataSource = (x: DataSource): x is UserFormDataSource =>
  x.sourceType === "user-form"
export const isAPIDataSource = (x: DataSource): x is APIDataSource => x.sourceType === "api"
