import { CheckboxWithLabel } from "@components/inputs/checkbox/Checkbox"
import { Modal } from "@components/layout/Modal"
import { firestoreConverter } from "@model/FirestoreConverter"
import { Button } from "@stories/components/Button/Button"
import { Card } from "@stories/components/Card/Card"
import Divider from "@stories/components/Divider/Divider"
import { FormLabel } from "@stories/components/Inputs/FormLabel/FormLabel"
import SectionLabel from "@stories/components/Inputs/SectionLabel/SectionLabel"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { collections } from "common/firestore/Collections"
import { LinkedBroker } from "common/model/LinkedBroker"
import { FormOrder } from "common/model/Order/OrderForm/State"
import { UserInvitedBroker } from "common/model/UserInvitedBroker"
import { Loading, deprecatedIsLoaded } from "common/utils/Loading"
import { collection, onSnapshot } from "firebase/firestore"
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import { AddLinkedBroker } from "src/pages/OnboardingDeprecated/AddLinkedBroker"
import { InvitedBroker } from "src/pages/OnboardingDeprecated/InviteLinkedBroker"
import { saveLinkedBrokerPreferences } from "src/pages/OnboardingDeprecated/SelectLinkedBroker"
import { useOrderForm } from "src/pages/Orders/OrderForm/OrderFormContext"
import { UserSettingsRoutes } from "src/pages/UserSettings/Page"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { classNames } from "../../../utils/classNames"
import MyBrokerSearch from "./MyBrokerSearch"
import { useAccountSnapshot } from "../../../providers/AccountSnapshot/useAccountSnapshot"
import { useLinkedBrokersQuery } from "src/queries/LinkedBroker/useLinkedBrokersQuery"

const InitialBrokerLinkingModal = () => {
  const user = useLoggedInUser()
  const { db } = useFirebase9()
  const allBrokers = useLinkedBrokersQuery()

  const [selectedBrokers, setSelectedBrokers] = useState<LinkedBroker[]>([])
  const [selectedInvitedBrokers, setSelectedInvitedBrokers] = useState<InvitedBroker[]>([])
  const [useCaplightAsBroker, setUseCaplightAsBroker] = useState(false)

  const handleSubmit = () =>
    saveLinkedBrokerPreferences(db, {
      selectedBrokers,
      invitedBrokers: selectedInvitedBrokers,
      caplightAsBroker: useCaplightAsBroker,
      user,
    })
      .then(() => setSelectedBrokers([]))
      .then(() => setSelectedInvitedBrokers([]))
      .then(() => setUseCaplightAsBroker(false))

  return (
    <Modal
      className=" w-100"
      open
      handleClose={() => true}
      subtitle="Select a Preferred Broker"
      body={
        <>
          <div className="flex flex-col space-y-2">
            <div>
              <Typography
                text="To submit your order to the market, first select the Preferred Broker you would like to work with."
                color={Color.Primary}
                weight={Weight.Semibold}
                size={Size.Small}
              />
            </div>
            <div>
              <Typography
                text="Preferred brokers can be linked to the buy/sell orders you place on Caplight. The brokers you select are able to edit and maintain these linked orders on your behalf, as well as respond to other brokers' Connect Requests and Inquiries on your order."
                color={Color.Subtitle}
                size={Size.Small}
              />
            </div>
            <div>
              <Typography
                text="Please select a broker to continue."
                color={Color.Primary}
                weight={Weight.Semibold}
                size={Size.Small}
              />
            </div>
          </div>
          <AddLinkedBroker
            allBrokers={allBrokers}
            selectedBrokers={selectedBrokers}
            setSelectedBrokers={setSelectedBrokers}
            invitedBrokers={selectedInvitedBrokers}
            addInvitedBroker={(b: InvitedBroker) =>
              setSelectedInvitedBrokers([...selectedInvitedBrokers, b])
            }
            removeInvitedBroker={(b: InvitedBroker) =>
              setSelectedInvitedBrokers(selectedInvitedBrokers.filter((iB) => iB.email !== b.email))
            }
          />
          <Divider
            label={
              <Typography
                text="or"
                weight={Weight.Light}
                color={Color.Subtitle}
                size={Size.Small}
              />
            }
          />
          <CheckboxWithLabel
            checked={useCaplightAsBroker}
            title="I want to use Caplight as my broker."
            comment="You can change this later."
            id="use-caplight-as-broker-checkbox"
            onChange={(e) => {
              setUseCaplightAsBroker(e.target.checked)
            }}
          />
          <div className="flex items-center space-x-2 mt-8">
            <Button
              label="Save Selection"
              isDisabled={
                !selectedBrokers.length && !selectedInvitedBrokers.length && !useCaplightAsBroker
              }
              onClick={handleSubmit}
              isFullWidth
            />
          </div>
        </>
      }
    />
  )
}

export const BrokerSection = () => {
  const { setFormOrder: handleChange, formOrder, isEditMode, initialFormOrder } = useOrderForm()
  const user = useLoggedInUser()
  const [linkedBrokers, setLinkedBrokers] = useState<Loading<LinkedBroker[]>>("loading")
  const [invitedBrokers, setInvitedBrokers] = useState<Loading<UserInvitedBroker[]>>("loading")
  const [selectedBroker, setSelectedBroker] = useState<FormOrder["brokeredBy"] | null>(
    formOrder.brokeredBy
  )
  const { db } = useFirebase9()
  useEffect(() => {
    const selectedBrokerCollectionRef = collection(
      db,
      collections.accounts,
      user.user.account.id,
      collections.accountSubcollections.linkedBrokers
    ).withConverter<LinkedBroker>(firestoreConverter<LinkedBroker>())
    return onSnapshot(selectedBrokerCollectionRef, ({ docs }) =>
      setLinkedBrokers(docs.map((d) => d.data()))
    )
  }, [db, user.user.account.id])
  useEffect(() => {
    const selectedBrokerCollectionRef = collection(
      db,
      collections.accounts,
      user.user.account.id,
      collections.accountSubcollections.invitedBrokers
    ).withConverter<UserInvitedBroker>(firestoreConverter<UserInvitedBroker>())
    return onSnapshot(selectedBrokerCollectionRef, ({ docs }) =>
      setInvitedBrokers(docs.map((d) => d.data()))
    )
  }, [db, user.user.account.id])

  const disableChange = initialFormOrder.brokeredBy !== undefined && isEditMode
  const { accountSnapshot } = useAccountSnapshot()

  if (
    !deprecatedIsLoaded(linkedBrokers) ||
    !deprecatedIsLoaded(invitedBrokers) ||
    !deprecatedIsLoaded(accountSnapshot)
  ) {
    return null
  }

  if (!linkedBrokers.length && !invitedBrokers.length && !accountSnapshot.caplightAsBroker) {
    return <InitialBrokerLinkingModal />
  }

  return (
    <div className={classNames(disableChange && "cursor-not-allowed", "flex flex-col space-y-4")}>
      <SectionLabel>Select a Broker</SectionLabel>
      <div className={classNames(disableChange && "opacity-70", "flex flex-col space-y-2")}>
        <FormLabel>
          Choose a broker that will work on this order for you (
          <Link
            to={UserSettingsRoutes.broker}
            target="_blank"
            rel="noopener noreferrer"
            className="font-bold text-sm text-primary-500"
          >
            Manage My Brokers
          </Link>
          )
        </FormLabel>
        <MyBrokerSearch
          disabled={disableChange}
          selectedBroker={selectedBroker ?? null}
          addSelectedBroker={(broker) => {
            setSelectedBroker(broker)
            handleChange({ brokeredBy: undefined })
          }}
          clearSelectedBroker={() => setSelectedBroker(undefined)}
          allBrokers={[...linkedBrokers, ...invitedBrokers]}
          isSingleSelect
        />
        {selectedBroker ? (
          <>
            <AnimatePresence>
              <motion.div
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                className="space-y-2"
              >
                <Card>
                  <CheckboxWithLabel
                    wrapText
                    checked={!!formOrder.brokeredBy}
                    title={`I acknowledge that my selected broker, ${selectedBroker.fullName}, will be able to edit, renew, or cancel this order on my behalf.`}
                    id="broker-client-accept-checkbox"
                    disabled={disableChange}
                    onChange={(e) =>
                      disableChange
                        ? undefined
                        : handleChange({
                            brokeredBy: e.target.checked ? selectedBroker : undefined,
                          })
                    }
                  />
                </Card>
              </motion.div>
            </AnimatePresence>
          </>
        ) : null}
      </div>

      <Divider
        label={
          <Typography text="or" weight={Weight.Light} color={Color.Subtitle} size={Size.Small} />
        }
      />
      <div className={classNames(disableChange && "opacity-70")}>
        <CheckboxWithLabel
          checked={formOrder.brokeredBy === null}
          disabled={disableChange}
          title="I want to use Caplight as my broker."
          comment="I accept that Caplight will act as my broker for this order."
          id="use-caplight-as-broker-checkbox"
          onChange={(e) => {
            handleChange({ brokeredBy: e.target.checked ? null : undefined })
            if (e.target.checked) {
              setSelectedBroker(undefined)
            }
          }}
        />
      </div>
    </div>
  )
}
