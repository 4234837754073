import { isEqual } from "lodash"
import { annotate } from "../../../utils/Coerce"
import { uniqueBy } from "../../../utils/data/Array/ArrayUtils"
import { Date_ } from "../../../utils/dateUtils"
import { Nominal, makeNominal } from "../../../utils/types/Nominal"
import { FlatSum } from "../../../utils/types/Sum"
import { UserIdFields, userIdFieldsSchema } from "../../User"
import { updateOutlierCheckResult } from "../DataPoint"
import { DataVisibilityFields } from "./VisibilityFields"
import { msPerSecond } from "../../../utils/constants/time"
import { Schema } from "../../../schema/Schema"

/** Do not export */
const outlierStatus: unique symbol = Symbol("outlier status symbol")
export type OutlierStatusSymbol = typeof outlierStatus
type RawOutlierStatusFields = {
  /** Rollup of `outlierHistory` */
  outlierSummary?: OutlierSummary | null
  outlierHistory?: OutlierCheckResult[]
}
export type OutlierStatusFields = Nominal<RawOutlierStatusFields, typeof outlierStatus>

const eqOutlierCheckResult = (l: OutlierCheckResult, r: OutlierCheckResult) =>
  isEqual({ ...l, date: null }, { ...r, date: null }) &&
  Math.abs((l.date?.valueOf() ?? 0) - (r.date?.valueOf() ?? 0)) < msPerSecond

export const makeOutlierStatus = (history: OutlierCheckResult[]): OutlierStatusFields =>
  makeNominal(
    {
      outlierHistory: uniqueBy(history, eqOutlierCheckResult),
      outlierSummary: outlierSummary(history),
    },
    outlierStatus
  )

export const viewOutlierFields = (x: DataVisibilityFields) => ({
  outlier: x.outlier ?? null,
  outlierStatus: x.outlierStatus ?? null,
})
type TermId = string
export type OutlierSummary = {
  global: OutlierCheckResult
  terms: Record<TermId, OutlierCheckResult>
}
export const outlierSummary = (history: OutlierCheckResult[]) =>
  history
    .slice()
    .sort(
      (l, r) =>
        (outlierCheckResultDate(l)?.valueOf() ?? 0) - (outlierCheckResultDate(r)?.valueOf() ?? 0)
    )
    .map((x) => outlierCheckResultToSummary(x))
    .reduce(
      (l, r) => ({
        global: updateOutlierCheckResult(l.global, r.global),
        terms: concatTermsOutlierSummary(l.terms, r.terms),
      }),
      annotate<OutlierSummary>({ global: { tag: "unflagged" as const }, terms: {} })
    )
const concatTermsOutlierSummary = (
  l: OutlierSummary["terms"],
  r: OutlierSummary["terms"]
): OutlierSummary["terms"] => {
  const out: OutlierSummary["terms"] = {}
  const keys = Object.keys(l).concat(Object.keys(r))
  keys.forEach((key) => {
    if (!(key in out))
      if (key in l) {
        if (key in r) {
          out[key] = concatOutlierCheckResult(l[key], r[key])
        } else {
          out[key] = l[key]
        }
      } else {
        out[key] = r[key]
      }
  })
  return out
}

const outlierCheckResultToSummary = (x: OutlierCheckResult): OutlierSummary =>
  x.scope?.tag === "term"
    ? { global: { tag: "unflagged", date: x.date ?? null }, terms: { [x.scope.termId]: x } }
    : { terms: {}, global: x }
const concatOutlierCheckResult = (l: OutlierCheckResult, r: OutlierCheckResult) =>
  (outlierCheckResultDate(l) ?? Date_.minValue) > (outlierCheckResultDate(r) ?? Date_.minValue)
    ? updateOutlierCheckResult(r, l)
    : updateOutlierCheckResult(l, r)

export const outlierCheckResultDate = (x: OutlierCheckResult) => x.date ?? null

export type OutlierCheckScope = { tag: "global" } | { tag: "term"; termId: string }
export type OutlierCheckResult = FlatSum<
  [
    ["auto flagged", { date: Date; _flaggedFor?: string[] }],
    [
      "unflagged",
      { date?: Date | null }
    ] /** The date is only optional for backwards-compatibility reasons and should be set on all new records */,
    ["manually flagged", { user: UserIdFields; date: Date }],
    ["outlier acknowledged", { user: UserIdFields; date: Date }],
    ["approved", { user: UserIdFields; date: Date }],
    ["cannot check", { date: Date }]
  ]
> & { scope?: OutlierCheckScope | null }

export const outlierCheckResultSchema: Schema<OutlierCheckResult> = Schema.object({
  tag: Schema.enum(["auto flagged"]),
  _flaggedFor: Schema.string().array().optional(),
  date: Schema.date(),
  scope: Schema.object({ tag: Schema.enum(["global"]) }).or(
    Schema.object({ tag: Schema.enum(["term"]), termId: Schema.string() })
  ),
})
  .or(
    Schema.object({
      tag: Schema.enum(["manually flagged", "outlier acknowledged", "approved"]),
      user: userIdFieldsSchema,
      date: Schema.date(),
      scope: Schema.object({ tag: Schema.enum(["global"]) }).or(
        Schema.object({ tag: Schema.enum(["term"]), termId: Schema.string() })
      ),
    })
  )
  .or(
    Schema.object({
      tag: Schema.enum(["cannot check"]),
      date: Schema.date(),
      scope: Schema.object({ tag: Schema.enum(["global"]) }).or(
        Schema.object({ tag: Schema.enum(["term"]), termId: Schema.string() })
      ),
    })
  )
  .or(
    Schema.object({
      tag: Schema.enum(["unflagged"]),
      date: Schema.date().or(Schema.null()).optional(),
      scope: Schema.object({ tag: Schema.enum(["global"]) }).or(
        Schema.object({ tag: Schema.enum(["term"]), termId: Schema.string() })
      ),
    })
  )

export type OutlierStatus = OutlierCheckResult["tag"]

export const hiddenOutlierStatuses: OutlierStatus[] = [
  "auto flagged",
  "manually flagged",
  "outlier acknowledged",
]
export type DeprecatedOutlierCheckResult = FlatSum<
  [
    ["auto flagged", { date: Date; _flaggedFor?: string[] }],
    "unflagged",
    ["manually flagged", { user: UserIdFields; date: Date }],
    ["outlier acknowledged", { user: UserIdFields; date: Date }],
    ["approved", { user: UserIdFields; date: Date }],
    ["cannot check", { date: Date }]
  ]
>

export const convertDeprecatedOutlierCheckResult = (
  x: DeprecatedOutlierCheckResult
): OutlierCheckResult => x

export const mergeDeprecatedOutlierCheckResult = (
  l: DeprecatedOutlierCheckResult,
  r: DeprecatedOutlierCheckResult
) =>
  (outlierCheckResultDate(l) ?? Date_.minValue).valueOf() >
    (outlierCheckResultDate(r) ?? Date_.minValue).valueOf() && l.tag !== "unflagged"
    ? l
    : r
