import { wrapIcon } from "./IconWrapper"

export const UploadIcon = wrapIcon(
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.80666 5.14L7.33333 3.60666V10C7.33333 10.1768 7.40357 10.3464 7.52859 10.4714C7.65362 10.5964 7.82319 10.6667 8 10.6667C8.17681 10.6667 8.34638 10.5964 8.4714 10.4714C8.59643 10.3464 8.66667 10.1768 8.66667 10V3.60666L10.1933 5.14C10.2553 5.20248 10.329 5.25208 10.4103 5.28592C10.4915 5.31977 10.5787 5.3372 10.6667 5.3372C10.7547 5.3372 10.8418 5.31977 10.923 5.28592C11.0043 5.25208 11.078 5.20248 11.14 5.14C11.2025 5.07802 11.2521 5.00429 11.2859 4.92305C11.3198 4.84181 11.3372 4.75467 11.3372 4.66666C11.3372 4.57866 11.3198 4.49152 11.2859 4.41028C11.2521 4.32904 11.2025 4.25531 11.14 4.19333L8.47333 1.52666C8.40993 1.46597 8.33517 1.41839 8.25333 1.38666C8.09102 1.31998 7.90897 1.31998 7.74667 1.38666C7.66483 1.41839 7.59007 1.46597 7.52667 1.52666L4.86 4.19333C4.79784 4.25549 4.74853 4.32928 4.71489 4.4105C4.68125 4.49171 4.66394 4.57876 4.66394 4.66666C4.66394 4.75457 4.68125 4.84162 4.71489 4.92283C4.74853 5.00404 4.79784 5.07784 4.86 5.14C4.92216 5.20216 4.99595 5.25146 5.07717 5.2851C5.15838 5.31874 5.24543 5.33606 5.33333 5.33606C5.42124 5.33606 5.50828 5.31874 5.5895 5.2851C5.67071 5.25146 5.74451 5.20216 5.80666 5.14ZM14 8C13.8232 8 13.6536 8.07024 13.5286 8.19526C13.4036 8.32028 13.3333 8.48985 13.3333 8.66666V12.6667C13.3333 12.8435 13.2631 13.013 13.1381 13.1381C13.013 13.2631 12.8435 13.3333 12.6667 13.3333H3.33333C3.15652 13.3333 2.98695 13.2631 2.86193 13.1381C2.7369 13.013 2.66667 12.8435 2.66667 12.6667V8.66666C2.66667 8.48985 2.59643 8.32028 2.4714 8.19526C2.34638 8.07024 2.17681 8 2 8C1.82319 8 1.65362 8.07024 1.52859 8.19526C1.40357 8.32028 1.33333 8.48985 1.33333 8.66666V12.6667C1.33333 13.1971 1.54405 13.7058 1.91912 14.0809C2.29419 14.456 2.8029 14.6667 3.33333 14.6667H12.6667C13.1971 14.6667 13.7058 14.456 14.0809 14.0809C14.456 13.7058 14.6667 13.1971 14.6667 12.6667V8.66666C14.6667 8.48985 14.5964 8.32028 14.4714 8.19526C14.3464 8.07024 14.1768 8 14 8Z"
      fill="currentColor"
    />
  </svg>
)
