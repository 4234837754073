import { CurrentUser } from "@model/CurrentUser"
import { CRMNoteSource, DealCRMNote } from "common/model/DealCRM/DealCRMNote"
import { useState } from "react"
import { useFirebaseWriter } from "src/firebase/Context"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import { handleConsoleError, trackEventInFirestoreAndHeap } from "src/utils/Tracking"
import { ITipTapEditor } from "./ITipTapEditor"
import { WYSIWYGEditor } from "./WYSIWYGEditor"
import { saveNewCRMNote, saveNewCRMNoteReply } from "./createCRMNote"
import { getAllNoteMentions } from "./getAllNoteMentions"
import { useTipTapEditor } from "./useTipTapEditor"
import { JSONContent } from "@tiptap/react"
import { NoteTag } from "./SuggestionConfig"

interface AddNoteInputProps {
  source: CRMNoteSource
  user: CurrentUser
  afterSave?: () => void
}

export const AddNoteInput: React.FC<AddNoteInputProps> = ({ source, user, afterSave }) => {
  const db = useFirebaseWriter()
  const firebase9 = useFirebase9()

  const handleAddNote = (content: JSONContent, mentions: NoteTag[]) =>
    saveNewCRMNote({
      db,
      user: user.user,
      content,
      source,
      mentions,
    })
      .then(() =>
        trackEventInFirestoreAndHeap(firebase9, user.user, "crm-note-added", {
          companyTags: mentions.filter((tag) => tag.tag === "company").length,
        })
      )
      .then(() => afterSave?.())
      .catch(handleConsoleError)

  return <AddNoteInputElement handleSave={handleAddNote} showSubmitButton />
}
export const AddReplyNoteInput = ({
  note,
  user,
  autoFocus,
  afterReplyAdded,
}: {
  note: DealCRMNote
  user: CurrentUser
  autoFocus?: boolean
  afterReplyAdded?: () => void
}) => {
  const db = useFirebaseWriter()
  const firebase9 = useFirebase9()

  const handleAddReplyNote = (content: JSONContent, mentions: NoteTag[]) =>
    saveNewCRMNoteReply({
      db,
      user: user.user,
      content,
      source: note.source,
      mentions,
      parentNote: note,
    })
      .then(() =>
        trackEventInFirestoreAndHeap(firebase9, user.user, "crm-note-reply-added", {
          companyTags: mentions.filter((tag) => tag.tag === "company").length,
        })
      )
      .then(() => afterReplyAdded?.())
      .catch(handleConsoleError)

  return (
    <AddNoteInputElement
      showSubmitButton={false}
      handleSave={handleAddReplyNote}
      placeholder="Reply..."
      autoFocus={autoFocus}
    />
  )
}

export const AddNoteInputElement = ({
  handleSave,
  placeholder,
  autoFocus,
  showSubmitButton,
}: {
  handleSave: (content: JSONContent, mentions: NoteTag[]) => Promise<void>
  placeholder?: string
  autoFocus?: boolean
  showSubmitButton: boolean
}) => {
  const [submitting, setSubmitting] = useState(false)
  const [editorTextContent, setEditorTextContent] = useState<string>("")

  const handleSubmit = (editor: ITipTapEditor) => {
    setSubmitting(true)
    const content = editor.getJSON()
    editor.commands.clearContent()
    const mentions = getAllNoteMentions(content)

    return handleSave(content, mentions)
      .catch(handleConsoleError)
      .finally(() => setSubmitting(false))
  }

  const editor = useTipTapEditor({
    placeholder,
    handleSaveNote: handleSubmit,
    onTextContentChanged: setEditorTextContent,
    initialContent: {
      type: "doc",
      content: [
        {
          type: "paragraph",
          attrs: {
            textAlign: "left",
          },
        },
      ],
    },
  })

  return (
    <div className="flex flex-col gap-1">
      {editor && (
        <>
          <WYSIWYGEditor
            showSubmitButton={showSubmitButton}
            autoFocus={autoFocus}
            editor={editor}
            isSubmitDisabled={submitting || editorTextContent.replace(/\s/g, "") === ""}
            handleSubmit={handleSubmit}
          />
        </>
      )}
    </div>
  )
}
