import { Button } from "@stories/components/Button/Button"
import { PlusIcon } from "@stories/icons/PlusIcon"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { titleCaseWords } from "common/utils/StringUtils"
import React, { FC, useEffect, useState } from "react"
import { ContactSelect } from "../ContactSelectSearch/ContactSelect"
import CreateNewContactPopover from "./CreateNewContactPopover"
import { InlineContactSelectComponentSource } from "./InlineContactSelectSearch"
import PopoverWrapper from "./PopoverWrapper"

export interface InlineContactSelectPopoverContentProps {
  onContactSelected: (contact: DealCRMContact | null) => Promise<void>
  searchString: string
  sourceComponent: InlineContactSelectComponentSource
  matchingContacts: DealCRMContact[]
  handleClose: () => void
  innerRef: React.RefObject<HTMLDivElement>
  isSkipVisible?: boolean
}

const InlineContactSelectPopoverContent: FC<InlineContactSelectPopoverContentProps> = ({
  onContactSelected,
  searchString,
  sourceComponent,
  matchingContacts,
  handleClose,
  innerRef,
  isSkipVisible,
}) => {
  const [isCreating, setIsCreating] = useState(false)
  const [contactName, setContactName] = useState("")

  useEffect(() => {
    if (searchString.length === 0) setIsCreating(false)
  }, [setIsCreating, searchString])

  useEffect(() => {
    if (!isCreating && searchString.length > 0) {
      const titleCaseName = titleCaseWords(searchString)
      setContactName(titleCaseName)
    }
  }, [searchString, isCreating, setContactName])

  if (isCreating) {
    return (
      <CreateNewContactPopover
        innerRef={innerRef}
        sourceComponent={sourceComponent}
        onContactCreated={onContactSelected}
        searchString={contactName}
        handleClose={() => setIsCreating(false)}
      />
    )
  }
  return (
    <PopoverWrapper
      innerRef={innerRef}
      title="Select a contact"
      handleClose={() => {
        setIsCreating(false)
        handleClose()
      }}
    >
      <div className="flex flex-col gap-2">
        {matchingContacts.slice(0, 3).map((contact) => (
          <ContactSelect
            hideContactDetails
            showIndividualFirmName
            contact={contact}
            onContactSelected={onContactSelected}
            key={contact.id}
          />
        ))}
      </div>
      <div className="flex justify-between items-center">
        <Button
          variant="hollow-link"
          size="small"
          label={`Create "${searchString}"`}
          onClick={() => setIsCreating(true)}
          leftIcon={<PlusIcon />}
        />
        {isSkipVisible ? (
          <Button
            variant="hollow-link"
            size="small"
            label="Skip"
            onClick={() => onContactSelected(null)}
          />
        ) : (
          <div />
        )}
      </div>
    </PopoverWrapper>
  )
}

export default InlineContactSelectPopoverContent
