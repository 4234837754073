import React, { useContext } from "react"
import Firebase9 from "./Firebase9"

const Firebase9Context: React.Context<Firebase9> = React.createContext<Firebase9>(
  null as unknown as Firebase9
)

export default Firebase9Context

export const useFirebase9: () => Firebase9 = () => useContext(Firebase9Context)
