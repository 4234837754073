import { ContactAddedToDealEvent } from "common/model/AccountActivityLog/AccountActivityLog"
import React from "react"
import { CRMContactName } from "src/pages/CRM/Contacts/ContactDetailsDrawer/CRMContactName"

interface CRMContactAddedToDealDetailsProps {
  event: ContactAddedToDealEvent
}

export const CRMContactAddedToDealDetails: React.FC<CRMContactAddedToDealDetailsProps> = ({
  event,
}) => {
  const { dealParticipant } = event

  return (
    <div className="flex gap-4 items-center">
      <CRMContactName contact={dealParticipant.contact} clickable />
    </div>
  )
}
