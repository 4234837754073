import { Left, Right } from "common/containers/Either"
import { DBQuery } from "common/utils/Collection"
import { collections } from "common/firestore/Collections"
import { firestoreConverter } from "common/model/FirestoreConverter"
import { RFQRequest, RFQType } from "common/model/RFQ/RFQ"
import { SimplePrism } from "common/utils/fp/optics/Prism"
import { FirebaseReader } from "../../Firebase"

export const rfqPrism: SimplePrism<RFQType, RFQType> = {
  preview: (s) => (s ? Right(s) : Left(s)),
  review: (s) => s,
}

export const rfqRequestPrism: SimplePrism<RFQRequest, RFQRequest> = {
  preview: (s) => (s ? Right(s) : Left(s)),
  review: (s) => s,
}

export const rfqsQuery = (db: FirebaseReader) =>
  new DBQuery(
    db.db,
    db.db.collection(collections.rfqs).withConverter(firestoreConverter<RFQType>()),
    rfqPrism
  )

export const rfqRequestQuery = (db: FirebaseReader) =>
  new DBQuery(
    db.db,
    db.db.collection(collections.rfqRequests).withConverter(firestoreConverter<RFQRequest>()),
    rfqRequestPrism
  )

export const getAllNonDraftRFQs = (db: FirebaseReader) =>
  rfqsQuery(db)
    .where("isDraft", "==", false)
    .get()
    .then((xs) => xs.flatMap((x) => (x.isRight() ? [x.value.value] : [])))

export const getAllRFQRequests = (db: FirebaseReader) =>
  rfqRequestQuery(db)
    .get()
    .then((xs) => xs.flatMap((x) => (x.isRight() ? [x.value.value] : [])))
