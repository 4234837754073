import Spinner from "@components/icons/Spinner"
import { useDropdownSelector } from "@components/inputs/selectors/DropdownSelector"
import { firestoreConverter } from "@model/FirestoreConverter"
import { AsyncDispatchButton } from "@stories/components/Button/AsyncDispatchButton"
import { collections, restrictedCollections } from "common/firestore/Collections"
import { Trade, createTrade } from "common/model/data-product/Trade"
import { PriceObservationType } from "common/model/data-product/pricing/PriceObservation"
import { defaultIfLoading, isLoaded } from "common/utils/Loading"
import { unsafeAssertNonempty } from "common/utils/data/Array/Nonempty"
import { shortDateFormat } from "common/utils/dateUtils"
import { useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { useFirebaseAdmin } from "src/firebase/Context"
import { handleConsoleError } from "src/utils/Tracking"
import { useDocumentSnapshot } from "src/utils/hooks/queries/useDocumentSnapshot"
import { useQuerySnapshot } from "src/utils/hooks/queries/useQuerySnapshot"
import { PriceObservationLink } from "../RecordLink"

const priceObservationOptionLabel = (x: PriceObservationType) =>
  `$${x.volume} @ $${x.price.toString()}pps ${x.observedBy.name} trade at ${shortDateFormat(
    x.observationDate.lowerBound
  )}`

export const TradeView = () => {
  const { tradeId } = useParams<{ tradeId: string }>()
  const trade = useDocumentSnapshot(
    (db) =>
      db.db.collection(collections.trades).withConverter<Trade>(firestoreConverter<Trade>()).doc(tradeId),
    [tradeId]
  )
  const firebase = useFirebaseAdmin()
  const tradeRef = useMemo(
    () =>
      firebase.adminDb
        .collection(collections.trades)
        .withConverter<Trade>(firestoreConverter<Trade>())
        .doc(tradeId),
    [firebase.adminDb, tradeId]
  )
  const otherPriceObservations = useQuerySnapshot(
    (db) =>
      isLoaded(trade)
        ? db.db
            .collection(collections.companies)
            .doc(trade.data.observations[0].company.id)
            .collection(restrictedCollections.companySubcollections.priceObservations)
            .withConverter<PriceObservationType>(firestoreConverter<PriceObservationType>())
        : null,
    (x) =>
      x.filter((obs) =>
        isLoaded(trade)
          ? !trade.data.ids.includes(
              obs.id ??
                "this is impossible and we should really do something about the fake optional ids in our model types"
            )
          : false
      ),
    [trade]
  )
  const [selectedObs, ObsSelector] = useDropdownSelector({
    label: "Other price observations",
    options: defaultIfLoading(otherPriceObservations, []),
    renderOption: (t) => ({ id: t.id ?? "MISSING", name: priceObservationOptionLabel(t) }),
  })
  const addSelectedObservationToTrade = useMemo(
    () => () => {
      if (isLoaded(trade) && selectedObs.isJust()) {
        return tradeRef
          .update(
            createTrade([selectedObs.value, ...trade.data.observations]).match(
              (e) => {
                throw new Error(e)
              },
              (x) => x
            )
          )
          .catch(handleConsoleError)
      }
      return Promise.reject(new Error("Button should not have been clickable"))
    },
    [selectedObs, trade, tradeRef]
  )
  const setPrimaryObservationOnTrade = useMemo(
    () => (obs: PriceObservationType) => tradeRef.update({ "data.primaryObservation": obs }),
    [tradeRef]
  )
  const removeObservationFromTrade = useMemo(
    () => (obs: PriceObservationType) => {
      if (isLoaded(trade) && trade.data.observations.length > 1) {
        return tradeRef
          .update(
            createTrade(
              unsafeAssertNonempty(trade.data.observations.filter((o) => o.id !== obs.id))
            ).match(
              (e) => {
                throw new Error(e)
              },
              (x) => trade.data.primaryObservation.id === obs.id ? x : ({...x, data: {...x.data, primaryObservation: trade.data.primaryObservation}})
            )
          )
          .catch(handleConsoleError)
      }
      return Promise.reject(new Error("Button should not have been clickable"))
    },
    [trade, tradeRef]
  )
  const [inputBlocked, setInputBlocked] = useState(false)
  return isLoaded(trade) ? (
    <div className="flex flex-row justify-around">
      <div className="flex flex-col">
        <div>Company:{trade.data.observations[0].company.name}</div>
        <div>Date:{shortDateFormat(trade.data.observations[0].observationDate.lowerBound)}</div>
        <div>Price observations:</div>
        <div>
          {trade.data.observations.map((obs) => (
            <div key={obs.id}>
              <PriceObservationLink obs={obs} />
              <AsyncDispatchButton
                label="Mark as primary observation for this trade"
                onClick={() => setPrimaryObservationOnTrade(obs)}
                onFailure={(err) => {
                  handleConsoleError(err)
                }}
                onFailureProps={{isDisabled: true, label: "Something went wrong"}}
                onSuccessProps={{isDisabled: trade.data.primaryObservation.id === obs.id}}
              />
              <AsyncDispatchButton
                label={
                  trade.data.observations.length > 1
                    ? "Remove from trade"
                    : "Cannot remove last observation from trade"
                }
                onClick={() => {
                  setInputBlocked(true)
                  return removeObservationFromTrade(obs)
                }}
                onSuccess={() => {
                  setInputBlocked(false)
                }}
                onFailure={(err) => {
                  setInputBlocked(false)
                  handleConsoleError(err)
                }}
                isDisabled={inputBlocked || trade.data.observations.length < 2}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col">
        <div>Add other price observations to this trade:</div>
        {isLoaded(otherPriceObservations) ? (
          <div>
            {ObsSelector}
            <AsyncDispatchButton
              label="Add selected observation to trade"
              onClick={() => addSelectedObservationToTrade()}
              onSuccessProps={{}}
              onFailureProps={{
                label: "Error adding price observation to trade",
                isDisabled: true,
              }}
              onClickedProps={{
                label: "Adding...",
                isDisabled: true,
              }}
            />
          </div>
        ) : (
          <Spinner size="sm" />
        )}
      </div>
    </div>
  ) : trade === null ? (
    <div>
      Could not find trade with id {tradeId ?? "undefined"}. You can create a new trade from the
      price observation inspector at
      /admin/records/companies/:companyId/price_observations/:observationId
    </div>
  ) : (
    <Spinner size="md" />
  )
}
