import _ from "lodash"
import moment from "moment"
import { Just, Maybe, Nothing } from "../../containers/Maybe"
import {
  enumValidator,
  numberValidator,
  parseId,
  stringValidator,
  validateBoolean,
  validateString,
  Validator,
} from "../../utils/Validator"
import { Company, FundingRoundsSummary } from "../Company"
import { CompanyFundingRound, FundingRoundStatus } from "../Company/FundingRound"
import { CaplightSource } from "../../types-for-typeorm/sharedTypes"

const pitchbookFundingRoundStatuses = [
  "Rumor/Speculation",
  "Postponed",
  "Failed/Cancelled",
  "Completed",
  "Announced/In Progress",
  "Upcoming",
] as const
type PitchbookFundingRoundStatus = (typeof pitchbookFundingRoundStatuses)[number]
const pitchbookFundingRoundStatusesToStatus: Record<
  PitchbookFundingRoundStatus,
  FundingRoundStatus
> = {
  Completed: "completed",
  "Announced/In Progress": "announced",
  "Rumor/Speculation": "rumored",
  "Failed/Cancelled": "failed",
  Postponed: "postponed",
  Upcoming: "upcoming",
}

export interface FundingRoundTerms {
  funding_amount_usd?: number
  pre_money_valuation_usd?: number
  post_money_valuation_usd?: number
  average_price_per_share_usd?: number
  status?: PitchbookFundingRoundStatus
  caplight_source?: CaplightSource
  deal_pbid?: string
}
export const validateFundingRoundTerms: Validator<unknown, FundingRoundTerms> =
  Validator.fromRecord({
    funding_amount_usd: numberValidator.or(enumValidator(undefined)).validate,
    pre_money_valuation_usd: numberValidator.or(enumValidator(undefined)).validate,
    post_money_valuation_usd: numberValidator.or(enumValidator(undefined)).validate,
    average_price_per_share_usd: numberValidator.or(enumValidator(undefined)).validate,
    caplight_source: enumValidator<CaplightSource>(CaplightSource.RUMOR, CaplightSource.PARTIES).or(
      enumValidator(undefined)
    ).validate,
    status: enumValidator<PitchbookFundingRoundStatus>(...pitchbookFundingRoundStatuses).or(
      enumValidator(undefined)
    ).validate,
    deal_pbid: stringValidator.or(enumValidator(undefined)).validate,
  })

export interface PostgresFundingRound {
  funding_round_id: number
  date: string
  company_name: string
  /** postgres id, not airtable or pbid */
  company_id: string
  pbid: string // company pbid
  funding_round_info: FundingRoundTerms // "{'funding_amount_usd': 20000.0}",
  /** e.g. Series B */
  round: string
  is_exit: boolean
}
export const validatePostgresFundingRound: Validator<unknown, PostgresFundingRound> =
  Validator.fromRecord({
    funding_round_id: parseId,
    date: validateString,
    company_name: validateString,
    pbid: validateString,
    company_id: validateString,
    funding_round_info: validateFundingRoundTerms.validate,
    round: validateString,
    is_exit: validateBoolean,
  })

/** @deprecated do not use; rely on CompanyFundingRound instead */
interface FundingRound {
  date: string
  id: number
  postMoneyValuation?: number
  pricePerShare?: number
  amount?: number
  roundName?: string
  companyId: string
  isExit: boolean
  status?: FundingRoundStatus
}

export const formatIntoCompFunding = (el: PostgresFundingRound): FundingRound => ({
  date: el.date,
  id: el.funding_round_id,
  postMoneyValuation: el.funding_round_info.post_money_valuation_usd,
  pricePerShare: el.funding_round_info.average_price_per_share_usd,
  amount: el.funding_round_info.funding_amount_usd,
  roundName: el.round,
  companyId: el.company_id,
  isExit: el.is_exit,
  status: !el.funding_round_info.status
    ? undefined
    : pitchbookFundingRoundStatusesToStatus[el.funding_round_info.status],
})

export const isPrimaryRound = (roundName?: string) =>
  roundName !== undefined &&
  !roundName.toLowerCase().includes("secondary") &&
  !roundName.toLowerCase().includes("tender") &&
  !roundName.toLowerCase().includes("debt") &&
  !roundName.toLowerCase().includes("grant") &&
  !roundName.toLowerCase().includes("mezz") &&
  !roundName.toLowerCase().includes("loan") &&
  !roundName.toLowerCase().includes("credit") &&
  !roundName.toLowerCase().includes("joint") &&
  !roundName.toLowerCase().includes("tender")

export const isAnnouncedIPO = (round: CompanyFundingRound) =>
  round.roundName.toLowerCase().includes("ipo") && round.status === "announced"

export const isTenderOffer = (roundName?: string) =>
  roundName !== undefined && roundName.toLowerCase().includes("tender")

const genericTransformFundingRoundToSummary = <T>(
  fundingRounds: FundingRound[],
  transform: (fr: (Omit<FundingRound, "date"> & { date: Date }) | FundingRound) => Maybe<T>
): Maybe<T> =>
  _.orderBy(
    fundingRounds.map((fr) => ({ ...fr, date: new Date(fr.date) })),
    "date",
    "desc"
  )
    .filter(
      (fr) =>
        isPrimaryRound(fr.roundName) && (!fr.status || !["rumored", "failed"].includes(fr.status))
    )
    .map(transform)
    .find((fr: Maybe<T>) => fr.isJust()) ?? Nothing

const fundingRoundToLastRound = (
  fr: (Omit<FundingRound, "date"> & { date: Date }) | FundingRound
): ReturnType<typeof Just<FundingRoundsSummary["lastRound"]>> => {
  const { postMoneyValuation, date, pricePerShare, roundName } = fr
  return Just({
    date: new Date(date),
    valuation: postMoneyValuation,
    pps: pricePerShare,
    roundName,
  })
}

const lastFundingRound = (
  fundingRounds: FundingRound[]
): Maybe<FundingRoundsSummary["lastRound"]> =>
  genericTransformFundingRoundToSummary(fundingRounds, fundingRoundToLastRound)

const fundingRoundToLastKnownValuation = (
  fr: (Omit<FundingRound, "date"> & { date: Date }) | FundingRound
): Maybe<FundingRoundsSummary["lastKnownValuation"]> => {
  const { postMoneyValuation, date, roundName } = fr
  if (postMoneyValuation) {
    return Just({
      date: new Date(date),
      valuation: postMoneyValuation,
      roundName,
    })
  }
  return Nothing
}
const lastKnownPostMoneyValuationRound = (
  fundingRounds: FundingRound[]
): Maybe<FundingRoundsSummary["lastKnownValuation"]> =>
  genericTransformFundingRoundToSummary(fundingRounds, fundingRoundToLastKnownValuation)

const fundingRoundToLastKnownPPS = (
  fr: (Omit<FundingRound, "date"> & { date: Date }) | FundingRound
): Maybe<FundingRoundsSummary["lastKnownPPS"]> => {
  const { pricePerShare, date, roundName } = fr
  if (pricePerShare) {
    return Just({
      date: new Date(date),
      pps: pricePerShare,
      roundName,
    })
  }
  return Nothing
}
const lastKnownPPSRound = (
  fundingRounds: FundingRound[]
): Maybe<FundingRoundsSummary["lastKnownPPS"]> =>
  genericTransformFundingRoundToSummary(fundingRounds, fundingRoundToLastKnownPPS)

const fundingRoundToLastKnownShareCount = (
  fr: (Omit<FundingRound, "date"> & { date: Date }) | FundingRound
): Maybe<FundingRoundsSummary["lastKnownShareCount"]> => {
  const { pricePerShare, postMoneyValuation, date, roundName } = fr
  if (
    pricePerShare !== undefined &&
    pricePerShare > 0 &&
    postMoneyValuation !== undefined &&
    postMoneyValuation > 0
  ) {
    return Just({
      date: new Date(date),
      shareCount: postMoneyValuation / pricePerShare,
      roundName,
    })
  }
  return Nothing
}
const lastKnownShareCountRound = (
  fundingRounds: FundingRound[]
): Maybe<FundingRoundsSummary["lastKnownShareCount"]> =>
  genericTransformFundingRoundToSummary(fundingRounds, fundingRoundToLastKnownShareCount)

export const getLastFundingRoundSummary = (
  frs: FundingRound[]
): Pick<Company, "fundingRoundsSummary"> => {
  const lastKnown = lastFundingRound(frs)
  const lastKnownVal = lastKnownPostMoneyValuationRound(frs)
  const lastKnownPPS = lastKnownPPSRound(frs)
  const lastKnownCount = lastKnownShareCountRound(frs)

  const fundingRoundsSummary: Company["fundingRoundsSummary"] = {
    ...lastKnown.match(
      (fr) => ({ lastRound: fr }),
      () => ({})
    ),
    ...lastKnownVal.match(
      (fr) => ({ lastKnownValuation: fr }),
      () => ({})
    ),
    ...lastKnownPPS.match(
      (fr) => ({ lastKnownPPS: fr }),
      () => ({})
    ),
    ...lastKnownCount.match(
      (fr) => ({ lastKnownShareCount: fr }),
      () => ({})
    ),
  }

  return { ...{ fundingRoundsSummary } }
}

export const formatPostgresIntoCompanyFundingRound = (
  el: PostgresFundingRound
): CompanyFundingRound => ({
  date: new Date(moment.utc(el.date).valueOf()),
  updatedAt: new Date(),
  pps: el.funding_round_info.average_price_per_share_usd || null,
  valuation: el.funding_round_info.post_money_valuation_usd || null,
  amount: el.funding_round_info.funding_amount_usd || null,
  roundName: el.round,
  modelVersion: 1,
  caplightSource: el.funding_round_info.caplight_source || null,
  status:
    el.funding_round_info.status === undefined
      ? null
      : pitchbookFundingRoundStatusesToStatus[el.funding_round_info.status],
  dealPbid: el.funding_round_info.deal_pbid || null,
})
