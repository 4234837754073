import { nullableToMaybe } from "../../containers/Maybe"
import { randomString } from "../../utils/RandomUtils"
import { Rec } from "../../utils/RecordUtils"
import { Excluding } from "../../utils/TypeUtils"
import { Interval } from "../../utils/data/Interval"
import { Account, AccountIdFields, viewAccountIdFields } from "../Account"
import { buildAccountCRMSettings, defaultAccountCRMSettings } from "../AccountCRMSettings"
import { Company, CompanyIdFields, viewCompanyIdFields } from "../Company"
import { viewOrderIdFields } from "../Order/Models/Internal"
import {
  Order,
  OrderIdFields,
  OrderDirection,
  OrderFirmness,
  orderFirmnessValues,
} from "../Order/Order"
import { BaseOrderSource } from "../Order/OrderSource"
import {} from "../Order/Order"
import { createOrderFormFieldsFromOrder } from "../Order/OrderForm/Constructors"
import { UserIdFields } from "../User"
import { UserOrSystemCreatable } from "../UserCreatable"
import { UserFormDataSource } from "../data-product/DataSource"
import { DealCRMDeal, DealCRMStage } from "./Deal/DealCRMDeal"
import { DealCRMPriority } from "./Deal/DealCRMDealParticipant"
import {
  DealCRMContact,
  DealCRMContactIdFields,
  viewDealCRMContactIdFields,
} from "./DealCRMContact"
import { HoldingIdFields } from "../holdings/Holding"

export const dealCRMInterestStructures = ["direct", "spv", "forward"] as const
export type DealCRMInterestStructure = (typeof dealCRMInterestStructures)[number]
export const dealCRMInterestShareClasses = ["common", "preferred"] as const
export type DealCRMInterestShareClass = (typeof dealCRMInterestShareClasses)[number]
export const managementFeeStructures = ["1-year", "2-year", "annual"] as const
export type ManagementFeeStructure = (typeof managementFeeStructures)[number]

export type DealCRMInterestSource =
  | (UserFormDataSource & Pick<BaseOrderSource, "account">)
  | { sourceType: "migration" }
  | { sourceType: "crm-holding-import"; sourceId: string }
export type DealCRMInterestFirmness = OrderFirmness | "cancelled"
export const dealCRMInterestFirmnessValues: DealCRMInterestFirmness[] = [
  ...orderFirmnessValues,
  "cancelled",
]

export type DealCRMInterestTerms = {
  structure: DealCRMInterestStructure | null
  shareClass: DealCRMInterestShareClass | null
  amountUSD: Interval<number> | null
  targetPrice: number | null
  targetValuation: number | null
} & SPVFees

export type DealCRMInterestWithOrigin = DealCRMInterest & { originId: string }

export type DealCRMInterest = DealCRMInterestTerms & {
  id: string
  publishedOrder?: Excluding<OrderIdFields, Order> | null
  sourceHolding?: HoldingIdFields
  account: Excluding<AccountIdFields, Account>
  contact: Excluding<DealCRMContactIdFields, DealCRMContact> | null
  source: DealCRMInterestSource
  direction: OrderDirection
  stage?: DealCRMDeal["stage"] | null
  priority?: DealCRMPriority
  company: Excluding<CompanyIdFields, Company>
  /** @deprecated: use stage instead * */
  firmness: DealCRMInterestFirmness | null
  commissionPercent: number | null
  updatedAt: Date[]
  lastUpdatedAt: Date
  updates: DealCRMInterestUpdate[]
  isDeleted?: boolean
  tag: "crm-interest" | "draft-search"
  orderOriginationDate: Date // defaulted to createdAt but can be overridden by users
} & UserOrSystemCreatable

export type TaggedDealCrmInterest<Tag extends Exclude<DealCRMInterest["tag"], undefined>> =
  DealCRMInterest & {
    tag: Tag
  }

export type DeprecatedDealCRMInterest = {
  id: string
  source: DealCRMInterestSource
  direction: OrderDirection
  company: Excluding<CompanyIdFields, Company>
  stage?: DealCRMDeal["stage"]
  firmness: DealCRMInterestFirmness | null
  structure: DealCRMInterestStructure | null
  shareClass: DealCRMInterestShareClass | null
  amountUSD: Interval<number> | null
  targetPrice: number | null
  targetValuation: number | null
  commissionPercent: number | null
  updatedAt: Date[]
  lastUpdatedAt: Date
  createdAt: Date
} & SPVFees

export type DealCRMInterestUpdate = {
  updatedBy: UserIdFields
  updatedAt: Date
  data: Partial<DealCRMInterest>
}

export type SPVFees = {
  managementFeeStructure: ManagementFeeStructure | null
  managementFeePercent: number | null
  carryPercent: number | null
}

export const buildSPVFees = ({
  contact,
  accountCRMSettings,
  deal,
  terms,
}: {
  contact: DealCRMContact | null
  deal: DealCRMDeal | null
  accountCRMSettings: Account["crmPreferences"]
  terms?: Partial<DealCRMInterestTerms>
}): SPVFees => {
  const managementFeeStructure = nullableToMaybe(contact?.defaultManagementFeeStructure)
    .or(nullableToMaybe(deal?.managementFeeStructure))
    .or(nullableToMaybe(accountCRMSettings?.defaultManagementFeeStructure))
    .withUnconstrainedDefault(null)

  const managementFeePercent = nullableToMaybe(contact?.defaultManagementFeePercent)
    .or(nullableToMaybe(deal?.managementFeePercent))
    .or(nullableToMaybe(accountCRMSettings?.defaultManagementFeePercent))
    .withUnconstrainedDefault(terms?.managementFeePercent ?? null)
  const carryPercent = nullableToMaybe(contact?.defaultCarryPercent)
    .or(nullableToMaybe(deal?.carryPercent))
    .or(nullableToMaybe(accountCRMSettings?.defaultCarryPercent))
    .withUnconstrainedDefault(terms?.carryPercent ?? null)

  return {
    managementFeeStructure,
    managementFeePercent,
    carryPercent,
  }
}

export const defaultAccountBuySellInterestStage = (account: Account): DealCRMStage => {
  const accountCRMPreferences = buildAccountCRMSettings(account)
  const dealAndOrderStages =
    accountCRMPreferences.dealAndOrderStages ??
    defaultAccountCRMSettings(account.clientType).dealAndOrderStages
  const stages = dealAndOrderStages.orders
  return stages.length > 0 ? stages[0] : "Tentative"
}

export type BuildDealCRMInterestArgs = {
  company: CompanyIdFields
  direction: OrderDirection
  source: DealCRMInterestSource
  contact: DealCRMContact | null
  account: Account
  publishedOrder?: Order
  terms?: Partial<DealCRMInterestTerms>
  stage?: DealCRMStage | null
}

export const buildDealCRMInterest = ({
  company,
  direction,
  source,
  contact,
  account,
  publishedOrder,
  terms,
  stage,
}: BuildDealCRMInterestArgs): TaggedDealCrmInterest<"crm-interest"> => {
  const defaultBrokerCommissionPercent = nullableToMaybe(contact?.typicalBrokerCommissionPercent)
    .or(nullableToMaybe(account.crmPreferences?.defaultBrokerCommissionPercent))
    .withUnconstrainedDefault(null)

  const orderStage = stage ?? defaultAccountBuySellInterestStage(account)

  const now = new Date()

  return {
    id: randomString(),
    tag: "crm-interest",
    account: viewAccountIdFields(account),
    contact: contact ? viewDealCRMContactIdFields(contact) : null,
    company: viewCompanyIdFields(company),
    stage: orderStage ?? null,
    direction,
    source,
    firmness: null,
    amountUSD: terms?.amountUSD ?? null,
    targetPrice: terms?.targetPrice ?? null,
    targetValuation: terms?.targetValuation ?? null,
    structure: terms?.structure ?? null,
    shareClass: terms?.shareClass ?? null,
    commissionPercent: defaultBrokerCommissionPercent,
    ...buildSPVFees({ contact, accountCRMSettings: account.crmPreferences, deal: null, terms }),
    lastUpdatedAt: now,
    updates: [],
    updatedAt: [now],
    orderOriginationDate: now,
    createdAt: now,
    ...(publishedOrder ? { publishedOrder: viewOrderIdFields(publishedOrder) } : {}),
  }
}

export const orderToCRMInterestTerms = (order: Order): Partial<DealCRMInterestTerms> => {
  const { price, size, structures, shareClasses, terms } = createOrderFormFieldsFromOrder(order)
  const { spv } = terms ?? {}

  return Rec.filter(
    {
      targetPrice: price?.USDPerShare?.lowerBound,
      targetValuation: price?.targetValuation,
      amountUSD: size?.amountUSD,
      structure: dealCRMInterestStructures.find((s) => structures?.includes(s)),
      shareClass: dealCRMInterestShareClasses.find((s) => shareClasses?.includes(s)),
      carryPercent: spv?.carry,
      managementFeePercent: spv?.managementFee,
    },
    ([_, value]) => value !== undefined
  )
}

export const isVisibleCRMInterest = (interest: DealCRMInterest): boolean =>
  !interest.isDeleted && interest.tag === "crm-interest"

export const isInterestCancelled = (interest: Pick<DealCRMInterest, "firmness" | "stage">) =>
  interest.firmness === "cancelled" || interest.stage === "cancelled"
