import React from "react"

export enum Weight {
  Light = "font-light",
  Regular = "font-normal",
  Semibold = "font-semibold",
  Bold = "font-bold",
}

export enum Font {
  Inter = "font-sans",
  Cambon = "font-serif",
}

export enum Size {
  XXSmall = "text-xxs leading-2xs",
  XSmall = "text-xs leading-4",
  Small = "text-sm leading-5",
  Medium = "text-base leading-6",
  Large = "text-lg leading-7",
  XLarge = "text-xl leading-7",
  XXLarge = "text-2xl leading-8",
  XXXLarge = "text-3xl leading-9",
  XXXXLarge = "text-4xl leading-10",
  XXXXXLarge = "text-5xl leading-5xl",
  XXXXXXLarge = "text-6xl leading-6xl",
  XXXXXXXLarge = "text-7xl leading-7xl",
  XXXXXXXXLarge = "text-8xl leading-8xl",
  XXXXXXXXXLarge = "text-9xl leading-9xl",
}

export enum Color {
  Black = "text-neutral-1000",
  Dark = "text-neutral-900",
  Primary = "text-primary-500",
  PrimarySecondary = "text-primary-400",
  White = "text-white",
  Subtitle = "text-neutral-800",
  Disabled = "text-neutral-600",
  Gray = "text-neutral-500",
  Placeholder = "text-neutral-700",
  OffWhite = "text-neutral-200",
  Danger = "text-danger-600",
  Success = "text-success-600",
  SuccessSecondary = "text-success-800",
  Warning = "text-warning-600",
  WarningSecondary = "text-warning-700",
  Link = "text-accent-500",
  SecondaryLink = "text-accent-800",
  HoveredLink = "text-accent-400",
  Neutral700 = "text-neutral-700",
  Neutral800 = "text-neutral-800",
  Neutral1000 = "text-neutral-1000",
  Accent100 = "text-accent-100",
}

export enum BackgroundColor {
  Black = "bg-neutral-1000",
  Dark = "bg-neutral-900",
  Primary = "bg-primary-500",
  PrimarySecondary = "bg-primary-400",
  White = "bg-white",
  Subtitle = "bg-neutral-800",
  Gray = "bg-neutral-500",
  Placeholder = "bg-neutral-700",
  OffWhite = "bg-neutral-200",
  Danger = "bg-danger-600",
  DangerSecondary = "bg-danger-800",
  DangerLight = "bg-danger-200",
  Success = "bg-success-600",
  SuccessSecondary = "bg-success-800",
  SuccessLight = "bg-success-200",
  Warning = "bg-warning-500",
  WarningSecondary = "bg-warning-800",
  WarningLight = "bg-warning-200",
  Link = "bg-accent-500",
  SecondaryLink = "bg-accent-800",
  HoveredLink = "bg-accent-400",
  Neutral800 = "bg-neutral-800",
  Neutral1000 = "bg-neutral-1000",
}

export interface TypographyProps {
  /**
   * What color should the text be?
   */
  color?: Color
  /**
   * What weight should the text be?
   */
  weight?: Weight
  /**
   * What font should the text be?
   */
  font?: Font
  /**
   * What size should the text be?
   */
  size?: Size
  /**
   * What should the text read?
   */
  text: string | React.ReactNode
  /**
   * Should the text wrap?
   */
  shouldWrap?: boolean
  /**
   * Should the render italic?
   */
  isItalic?: boolean
  /**
   * Should data dog mask the data in this typography?
   */
  shouldMaskDataInDataDog?: boolean
  /**
   * What html tag should this typography render to?
   */
  htmlTag?: keyof JSX.IntrinsicElements
  /**
   * What background color should the text be?
   */
  backgroundColor?: BackgroundColor
}

export const Typography: React.FC<React.PropsWithChildren<TypographyProps>> = ({
  color = Color.Black,
  weight = Weight.Regular,
  font = Font.Inter,
  size = Size.Medium,
  shouldWrap = true,
  shouldMaskDataInDataDog = false,
  isItalic = false,
  text,
  htmlTag: Element = "span",
  backgroundColor,
}) => {
  const wrappingStyle = shouldWrap ? "whitespace-normal" : "whitespace-nowrap"
  const classes = `${color} ${weight} ${font} ${size} ${wrappingStyle} ${
    isItalic ? "italic" : ""
  } ${backgroundColor ?? ""}`

  return React.createElement(
    Element,
    {
      className: classes,
      "data-dd-privacy": shouldMaskDataInDataDog ? "mask" : "allow",
    },
    text
  )
}

export default Typography
