import * as React from "react"
import { AccountIdFields } from "common/model/Account"
import { useDocuments } from "../../firebase/Firestore"
import { useFirebaseReader } from "../../firebase/Context"
import Typography, { Size, Weight } from "../../stories/components/Typography/Typography"

interface IAccountWatchlistModuleProps {
  account: AccountIdFields
}

const AccountWatchlistModule: React.FunctionComponent<IAccountWatchlistModuleProps> = ({
  account,
}) => {
  const firebase = useFirebaseReader()
  const watchlistItems = useDocuments(firebase.getAccountWatchlist(account.id), [account.id])

  // eslint-disable-next-line rulesdir/no-let
  let content: React.ReactNode = null
  if (!watchlistItems || watchlistItems === "loading") {
    content = <div>Loading...</div>
  } else if (watchlistItems.length === 0) {
    content = <div className="text-center">No watchlist items</div>
  } else {
    content = (
      <div className="mx-auto max-w-175">
        {watchlistItems.map((item) => (
          <div className="text-center" key={item.id}>
            {item.company.name}
          </div>
        ))}
      </div>
    )
  }

  return (
    <div>
      <div className="text-center">
        <Typography size={Size.XLarge} text="Watchlist" weight={Weight.Bold} />
      </div>
      {content}
    </div>
  )
}

export default AccountWatchlistModule
