import { Just, Maybe, Nothing } from "../containers/Maybe"

export const defined = <X>(x: X | undefined): x is X => x !== undefined

/**
 * Lifts a function (X -> Y) to a function (X | undefined -> Y | undefined)
 */
export const mapOrUndefined = <X, Y>(x: X | undefined, func: (x: X) => Y) =>
  defined(x) ? func(x) : undefined

export const curriedMapOrUndefined =
  <X, Y>(func: (x: X) => Y) =>
  (x: X | undefined) =>
    defined(x) ? func(x) : undefined

/**
 * Lifts a function (X,Y) -> Z to a function (X | undefined, Y | undefined) -> Z | undefined
 */
export const lift2OrUndefined =
  <X, Y, Z>(func: (x: X, y: Y) => Z) =>
  (x: X | undefined, y: Y | undefined): Z | undefined =>
    x === undefined || y === undefined ? undefined : func(x, y)

/**
 * Lifts a function (X -> Y | undefined) to a function (X | undefined -> Y | undefined)
 * Because typescript doesn't distinguish between X | undefined and X | undefined | undefined, this happens to just be a specialized version of mapMaybe, but code that conflates the two will be more difficult to refactor.
 */
export const bindOrUndefined =
  <X, Y>(func: (x: X) => Y | undefined) =>
  (x: X | undefined): Y | undefined =>
    mapOrUndefined(x, func)

export const curriedMapOrNull =
  <X, Y>(func: (x: X) => Y) =>
  (x: X | null) =>
    x !== null ? func(x) : null

/**
 * Lifts a function (X,Y) -> Z to a function (X | null, Y | null) -> Z | null
 */
export const lift2OrNull =
  <X, Y, Z>(func: (x: X, y: Y) => Z) =>
  (x: X | null, y: Y | null): Z | null =>
    x === null || y === null ? null : func(x, y)

export const coalesceNull =
  <T>(f: (l: T, r: T) => T) =>
  (l: T | null, r: T | null): T | null =>
    l === null ? r : r === null ? l : f(l, r)

export const isInEnum =
  <S>(xs: readonly S[]) =>
  <T>(x: T): x is T & S =>
    // eslint-disable-next-line rulesdir/no-assert
    xs.includes(x as T & S)
export const inEnum = <S, T>(x: T, xs: readonly S[]): Maybe<T & S> =>
  // eslint-disable-next-line rulesdir/no-assert
  xs.includes(x as T & S) ? Just(x as T & S) : Nothing
