import { PageTitle } from "@components/typography/PageTitle"
import { Drawer, Select } from "@stories/components/Antd"
import { Loading, isLoaded, mapLoading } from "common/utils/Loading"
import { ReactNode, createContext, useCallback, useContext, useMemo, useState } from "react"
import { useCurrentUser } from "../currentUser/useCurrentUser"
import {
  AccessControl,
  AccessControlFeatureName,
  AccessLevel,
  accessControlFeatureNames,
  accessLevels,
  getFeatureAccessLevel as getFeatureAccessLevelRaw,
} from "common/model/AccessControl/AccessControl"
import { getAccessControlTierForAccount } from "common/model/AccessControl/getAccessControlTierForAccount"
import {
  AccessControlTier,
  accessControlTierMap,
  getAccessControlForTier,
  getUpgradeAccessTier,
} from "common/model/AccessControl/AccessControlTier"
import Typography from "@stories/components/Typography/Typography"
import { createColumnHelper } from "@tanstack/react-table"
import { SelectField } from "src/pages/CRM/Components/DealParticipantFieldDisplay/DealParticipantFieldDisplay"
import Table from "@stories/components/Table/Table"
import { Left } from "common/containers/Either"
import { UpgradePlanModal } from "./components/UpgradePlanModal"

type AccessControlCtx = {
  currentAccessTier: AccessControlTier | null
  getFeatureAccessLevel: (feature: AccessControlFeatureName) => AccessLevel
  getUpgradeAccessTierForCurrentTier: (args: {
    featureName: AccessControlFeatureName
    targetAccessLevel: AccessLevel
  }) => ReturnType<typeof getUpgradeAccessTier>
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
  currentAccessControlDoc: Loading<AccessControl<AccessControlTier>>
  setIsUpgradePlanModalOpen: (open: boolean) => void
  setUpgradePlanModalFeatureName: (featureName: AccessControlFeatureName | null) => void
}
export const AccessControlContext = createContext<AccessControlCtx>({
  currentAccessTier: null,
  getFeatureAccessLevel: () => "none",
  getUpgradeAccessTierForCurrentTier: () => Left("elite"),
  setDrawerOpen: () => {},
  currentAccessControlDoc: "loading",
  setUpgradePlanModalFeatureName: () => {},
  setIsUpgradePlanModalOpen: () => {},
})

export const AccessControlProvider = ({ children }: { children: ReactNode }) => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [isUpgradePlanModalOpen, setIsUpgradePlanModalOpen] = useState(false)
  const [upgradePlanModalFeatureName, setUpgradePlanModalFeatureName] =
    useState<AccessControlFeatureName | null>(null)
  const currentUser = useCurrentUser()
  const [overrideAccessControlDoc, setAccessControlDoc] =
    useState<AccessControl<AccessControlTier> | null>(null)

  const currentAccessControlDoc = useMemo(
    () =>
      isLoaded(currentUser)
        ? overrideAccessControlDoc ??
          getAccessControlForTier(getAccessControlTierForAccount(currentUser.user.account))
        : null,
    [currentUser, overrideAccessControlDoc]
  )

  const getFeatureAccessLevel = useCallback(
    (featureName: AccessControlFeatureName) => {
      if (!currentAccessControlDoc) return "none"
      return getFeatureAccessLevelRaw(
        featureName,
        overrideAccessControlDoc ?? currentAccessControlDoc
      )
    },
    [currentAccessControlDoc, overrideAccessControlDoc]
  )

  const value: AccessControlCtx = useMemo(
    () => ({
      currentAccessTier: currentAccessControlDoc?.tier ?? null,
      getFeatureAccessLevel,
      getUpgradeAccessTierForCurrentTier: ({ featureName, targetAccessLevel }) =>
        currentAccessControlDoc
          ? getUpgradeAccessTier({
              currentAccessTier: currentAccessControlDoc.tier,
              featureName,
              targetAccessLevel,
            })
          : Left("elite"),
      setDrawerOpen: isLoaded(currentUser) && currentUser.isAdmin ? setDrawerOpen : () => {},
      currentAccessControlDoc,
      setIsUpgradePlanModalOpen,
      setUpgradePlanModalFeatureName,
    }),
    [currentUser, currentAccessControlDoc, getFeatureAccessLevel]
  )

  return (
    <AccessControlContext.Provider value={value}>
      {children}
      {isLoaded(currentUser) && currentUser.isAdmin ? (
        <AccessControlDrawer
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          currentAccessControlDoc={currentAccessControlDoc}
          setAccessControlDoc={setAccessControlDoc}
        />
      ) : null}
      <UpgradePlanModal
        open={isUpgradePlanModalOpen}
        onClose={() => setIsUpgradePlanModalOpen(false)}
        featureName={upgradePlanModalFeatureName}
      />
    </AccessControlContext.Provider>
  )
}

type AdminAccessControlTable = {
  feature: AccessControlFeatureName
  currentAccessLevel: AccessLevel
}

type AccessControlDrawerProps = {
  open: boolean
  onClose: () => void
  currentAccessControlDoc: AccessControl<AccessControlTier> | null
  setAccessControlDoc: React.Dispatch<React.SetStateAction<AccessControl<AccessControlTier> | null>>
}
const AccessControlDrawer = ({
  open,
  onClose,
  currentAccessControlDoc,
  setAccessControlDoc,
}: AccessControlDrawerProps) => {
  const accessControlItems: AdminAccessControlTable[] = useMemo(
    () =>
      currentAccessControlDoc
        ? accessControlFeatureNames.map((feature) => ({
            feature,
            currentAccessLevel: getFeatureAccessLevelRaw(feature, currentAccessControlDoc),
          }))
        : [],
    [currentAccessControlDoc]
  )

  const columnHelper = createColumnHelper<AdminAccessControlTable>()
  const columns = useMemo(
    () =>
      currentAccessControlDoc
        ? [
            columnHelper.accessor("feature", {
              header: "Feature",
              cell: (context) => context.row.original.feature,
              footer: (p) => p.column.id,
              enableGlobalFilter: true,
            }),
            columnHelper.accessor("currentAccessLevel", {
              header: "Status",
              // eslint-disable-next-line react/no-unstable-nested-components
              cell: (context) => (
                <SelectField<AccessLevel>
                  options={[...accessLevels]}
                  value={context.row.original.currentAccessLevel}
                  handleChange={(v) =>
                    setAccessControlDoc({
                      ...currentAccessControlDoc,
                      features: {
                        ...currentAccessControlDoc.features,
                        [context.row.original.feature]: v,
                      },
                    })
                  }
                  renderValue={(v) => v}
                  editable
                />
              ),
              footer: (p) => p.column.id,
            }),
          ]
        : [],
    [columnHelper, currentAccessControlDoc, setAccessControlDoc]
  )

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={<PageTitle title="Access Control" />}
      closable
      mask
      maskClosable
      destroyOnClose
      size="large"
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col">
          <Typography text="Change Current Tier" />
          <Select<AccessControlTier>
            options={Object.keys(accessControlTierMap).map((v) => ({ label: v, value: v }))}
            value={currentAccessControlDoc?.tier}
            onSelect={(v) => setAccessControlDoc(accessControlTierMap[v])}
          />
        </div>
        <Table<AdminAccessControlTable>
          columns={columns}
          data={accessControlItems}
          isPaginationEnabled={false}
          globalFilter={{ placeholder: "Filter for specific control..." }}
        />
      </div>
    </Drawer>
  )
}

export const useAccessControl = () => useContext(AccessControlContext)
