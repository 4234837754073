import { FC, useState } from "react"
import { useIdleTimer } from "react-idle-timer"
import { useFirebase9 } from "../../firebase/Firebase9Context"
import { CurrentUser } from "../../model/CurrentUser"
import { handleConsoleError, trackEvent } from "../../utils/Tracking"

interface UserStatusTrackerProps {
  user: CurrentUser
}

const MIN_ACTIVE_SESSION_DURATION_IN_MS = 10 * 1000 // Min 10 seconds for a session to count
const SESSION_DURATION_IN_MS = 30 * 60 * 1000 //30 minutes between 'session counts'

export const UserStatusTracker: FC<React.PropsWithChildren<UserStatusTrackerProps>> = ({
  user,
}) => {
  const firebase = useFirebase9()
  const { isAdmin } = user
  const [lastSessionTimestamp, setLastSessionTimestamp] = useState<number | undefined>(undefined)
  useIdleTimer({
    throttle: 5000,
    timeout: 1000 * 60 * 20, // 20 minutes
    events: ["click", "scroll", "keydown"],
    onAction: (event) => {
      firebase.setUserOnlineStatus(user.user.id, "active", { isAdmin }).catch(handleConsoleError)
      if (
        event?.timeStamp &&
        event.timeStamp > MIN_ACTIVE_SESSION_DURATION_IN_MS &&
        (!lastSessionTimestamp || event.timeStamp - SESSION_DURATION_IN_MS > lastSessionTimestamp)
      ) {
        trackEvent("active-session")
        setLastSessionTimestamp(event.timeStamp)
      }
    },
    onIdle: () => {
      firebase.setUserOnlineStatus(user.user.id, "idle", { isAdmin }).catch(handleConsoleError)
    },
  })

  return null
}
