import { Company } from "common/model/Company"
import { clamp } from "lodash"
import { formatPercentage } from "common/utils/math/format"
import { Routes } from "src/Routes/Routes"
import { HashLink } from "react-router-hash-link"
import { LineChartOutlined } from "@ant-design/icons"
import { Tooltip } from "@stories/components/Antd"
import { OrderDirection } from "common/model/Order/Order"
import { FC } from "react"
import { formatSharePrice } from "../../../../components/displays/numeric/Currency"
import OrderPageSidebarCard, { OrderPageSidebarCardTitle } from "../components/OrderPageSidebarCard"
import { AccessControlWrapper } from "src/providers/AccessControl/AccessControlWrapper"
import { UnlockCompanyPopover } from "src/providers/AccessControl/components/UnlockCompanyPopover"
import { LockedTableCell } from "src/providers/AccessControl/TableCellAccessControlWrapper"

const INNER_BOUNDARY = 0.1
const OUTER_BOUNDARY = 0.2

const OrderMarketPriceComparisonLabels = {
  pricePoint: (direction?: OrderDirection) => `Selected ${direction === "buy" ? "bid" : "offer"}`,
  marketPricePoint: () => "",
}

const PricePoint = ({
  orderDirection,
  orderPPS,
  estimatePPS,
  min,
  spread,
  labels,
}: {
  orderDirection?: OrderDirection
  orderPPS?: number
  estimatePPS?: boolean
  min: number
  spread: number
  labels: typeof OrderMarketPriceComparisonLabels
}) => {
  if (!orderPPS || orderPPS < 0.001) return null

  const MAX_SPREAD = 100
  const MIN_SPREAD = 0
  const orderPPSPercentSpread = clamp(((orderPPS - min) / spread) * 100, MIN_SPREAD, MAX_SPREAD)
  const highlight = orderPPSPercentSpread === MIN_SPREAD || orderPPSPercentSpread === MAX_SPREAD
  return (
    <div
      className={`absolute w-0 flex flex-col items-center top-2 ${
        highlight ? " text-warning-600" : "text-accent-500"
      }`}
      style={{ left: `${orderPPSPercentSpread}%` }}
    >
      <p className="text-xs">▲</p>
      <div className="flex flex-col items-center whitespace-nowrap">
        <p className="text-sm font-bold">
          {formatSharePrice(orderPPS)}
          {estimatePPS ? " (est.)" : ""}
        </p>
        <p className="text-xs text-neutral-700">{labels.pricePoint(orderDirection)}</p>
      </div>
    </div>
  )
}

const Subtitle = ({
  orderDirection,
  orderPPS,
  marketPricePPS,
}: {
  orderDirection?: OrderDirection
  orderPPS?: number
  marketPricePPS: number
}) => {
  if (!orderPPS) return null

  const bidOrOffer = orderDirection === "buy" ? "bid" : "offer"
  const orderPPSPercentMarketPrice = (orderPPS - marketPricePPS) / marketPricePPS
  return (
    <p className="w-full text-sm mt-2">
      This {bidOrOffer} is{" "}
      {orderPPSPercentMarketPrice > 0.01 || orderPPSPercentMarketPrice < -0.01
        ? formatPercentage(Math.abs(orderPPSPercentMarketPrice))
        : "<1%"}{" "}
      {orderPPSPercentMarketPrice > 0 ? "higher" : "lower"} than current MarketPrice
    </p>
  )
}

export const InnerOrderMarketPriceComparison: FC<
  React.PropsWithChildren<{
    orderDirection: OrderDirection | undefined
    orderPPS: number | undefined
    estimatePPS: boolean | undefined
    company: Company
    hiddenLinkToPriceChart?: boolean
    labels?: typeof OrderMarketPriceComparisonLabels
  }>
> = ({
  company,
  orderDirection,
  orderPPS,
  estimatePPS,
  hiddenLinkToPriceChart,
  labels = OrderMarketPriceComparisonLabels,
}) => {
  const { currentPrice } = company.priceEstimatesSummary || {}
  if (!currentPrice) return null
  const { priceEstimatePPS: marketPricePPS } = currentPrice

  const tenPercentMarketPrice = marketPricePPS * INNER_BOUNDARY
  const twentyPercentMarketPrice = marketPricePPS * OUTER_BOUNDARY

  const min = marketPricePPS - twentyPercentMarketPrice
  const max = marketPricePPS + twentyPercentMarketPrice
  const spread = max - min

  const innerMin = marketPricePPS - tenPercentMarketPrice
  const innerMax = marketPricePPS + tenPercentMarketPrice

  if (!company.settings?.showCaplightPriceEstimate || !marketPricePPS) return null
  return (
    <OrderPageSidebarCard className="flex flex-col items-center">
      <div className="flex flex-row items-center justify-between w-full mb-2">
        <OrderPageSidebarCardTitle title="MarketPrice Comparison" />
        {!hiddenLinkToPriceChart && company.postgresCompanyId ? (
          <HashLink
            to={Routes.companies.companyPageLocations.marketPriceChart(company.postgresCompanyId)}
            smooth
          >
            <Tooltip title={`View ${company.name} MarketPrice Chart`} placement="left">
              <div className="text-md text-base">
                <LineChartOutlined />
              </div>
            </Tooltip>
          </HashLink>
        ) : null}
      </div>
      <AccessControlWrapper
        feature="currentMarketPriceData"
        limitedCompanyFallback={
          <UnlockCompanyPopover featureName="currentMarketPriceData">
            <LockedTableCell size="medium" />
          </UnlockCompanyPopover>
        }
      >
        <div className="flex flex-col items-center">
          <p className="text-xs text-neutral-700">{labels.marketPricePoint()}</p>
          <p className="text-sm font-bold text-primary-500">{formatSharePrice(marketPricePPS)}</p>
        </div>
        <div className="w-3/4 h-2 mt-4 mb-12 relative rounded bg-neutral-400">
          <div
            className="absolute w-0 flex flex-col items-center bottom-2 text-primary-500 text-xs"
            style={{ left: "50%" }}
          >
            ▼
          </div>
          <div
            className="absolute h-2 bg-primary-500"
            style={{
              left: `${((innerMin - min) / spread) * 100}%`,
              right: `${((max - innerMax) / spread) * 100}%`,
            }}
          />
          <PricePoint
            orderDirection={orderDirection}
            orderPPS={orderPPS}
            estimatePPS={estimatePPS}
            min={min}
            spread={spread}
            labels={labels}
          />
        </div>
        <Subtitle
          orderDirection={orderDirection}
          orderPPS={orderPPS}
          marketPricePPS={marketPricePPS}
        />
      </AccessControlWrapper>
    </OrderPageSidebarCard>
  )
}

export default InnerOrderMarketPriceComparison
