import { Drawer as AntdDrawer, DrawerProps as AntdDrawerProps } from "@stories/components/Antd"
import { CrossIcon } from "@stories/icons/CrossIcon"
import { useCallback, useEffect, useState } from "react"
import { logMessage } from "src/utils/Logging"
import { classNames } from "../../utils/classNames"
import { PageTitle } from "../typography/PageTitle"
import { Button } from "@stories/components/Button/Button"
import { ChevronLeftIcon } from "@stories/icons/ChevronLeftIcon"

export const useDrawer = (initial: boolean = false) => {
  const [open, setOpen] = useState(initial)
  const closeDrawer = useCallback(() => setOpen(false), [])
  const openDrawer = useCallback(() => setOpen(true), [])
  return { closeDrawer, openDrawer, open, setOpen }
}

type DrawerSize = "sm" | "md" | "lg" | "xl"

const sizeToWidth: Record<DrawerSize, number> = {
  sm: 450,
  md: 800,
  lg: 1200,
  xl: 1500,
}

type RequiredAntdProps = Required<Pick<AntdDrawerProps, "onClose" | "open">>
type OptionalAntdProps = Pick<
  AntdDrawerProps,
  "placement" | "destroyOnClose" | "keyboard" | "maskClosable"
>
export type DrawerProps = {
  title: React.ReactNode
  children: React.ReactNode
  size: DrawerSize
  className?: string
  okToRenderWhileClosed?: boolean
  back?: { label: string; onClick: () => void }
} & RequiredAntdProps &
  OptionalAntdProps

type DrawerHeaderProps = Pick<DrawerProps, "title" | "onClose">

const DrawerHeader = ({ title, onClose }: DrawerHeaderProps) => (
  <div className="absolute top-0 left-0 right-0 z-10">
    <div className="flex justify-between w-full gap-2 bg-neutral-white p-4 border-b">
      <PageTitle title={title} />
      <Button variant="hollow" leftIcon={<CrossIcon />} onClick={onClose} />
    </div>
  </div>
)

const Drawer = ({ size, children, title, onClose, className, back, ...props }: DrawerProps) => {
  useEffect(() => {
    if (!props.okToRenderWhileClosed && !props.open) {
      logMessage("Drawer rendered while closed")
    }
  }, [props.okToRenderWhileClosed, props.open])
  return (
    <AntdDrawer
      placement="right"
      width={sizeToWidth[size]}
      contentWrapperStyle={{ maxWidth: "100vw" }}
      onClose={onClose}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      title={undefined} // required for custom header
      closable={false} // required for custom header
    >
      {back ? (
        <div className="absolute top-16 left-0 right-0 border-b-2 border-neutral-400 bg-neutral-200 z-10">
          <Button
            label={back.label}
            onClick={back.onClick}
            variant="hollow"
            leftIcon={<ChevronLeftIcon />}
          />
        </div>
      ) : null}
      <div className={classNames("flex flex-col gap-4", className, back ? "mt-8" : false)}>
        <DrawerHeader title={title} onClose={onClose} />
        <div className="mt-14">{children}</div>
      </div>
    </AntdDrawer>
  )
}

export default Drawer
