import { SubtleAccordion } from "@components/data_display/Accordion"
import { CheckboxWithLabel } from "@components/inputs/checkbox/Checkbox"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { SharedOrderCreateParams } from "common/model/SharedOrder/SharedOrderCreate"
import { Rec } from "common/utils/RecordUtils"
import { includesByRep } from "common/utils/data/Array/ArrayUtils"
import { mapValues } from "lodash"
import { useCallback, useMemo } from "react"
import { CRMContactName } from "src/pages/CRM/Contacts/ContactDetailsDrawer/CRMContactName"
import { useCRMContacts } from "src/pages/CRM/Providers/CRMContactsProvider"
import { SelectedContacts, createEmailFromCRMContact } from "./ShareOrderInviteContacts"
import { StopClickPropagation } from "@stories/components/StopClickPropagation/StopClickPropagation"

type ContactGroupMember = {
  crmContact: DealCRMContact
  email: SharedOrderCreateParams["sharedWithUserEmails"][number]
  isChecked: boolean
}

export const SharedOrderContactGroups = ({
  emails,
  handleAddEmails,
  handleRemoveEmails,
}: {
  emails: SharedOrderCreateParams["sharedWithUserEmails"]
  handleAddEmails: (e: SharedOrderCreateParams["sharedWithUserEmails"]) => void
  handleRemoveEmails: (e: SharedOrderCreateParams["sharedWithUserEmails"]) => void
}) => {
  const { contacts, findContactById } = useCRMContacts()
  const groups = useMemo(
    () =>
      contacts.reduce<Record<string, ContactGroupMember[]>>(
        (allAccumulatedGroups, contact) =>
          Rec.mergeBy(
            allAccumulatedGroups,
            mapValues(contact.groups ?? {}, (isMember) =>
              isMember
                ? [
                    {
                      email: createEmailFromCRMContact(contact),
                      crmContact: contact,
                      isChecked: includesByRep(
                        emails,
                        createEmailFromCRMContact(contact),
                        (e) => e.email
                      ),
                    },
                  ]
                : []
            ),
            (a, b) => [...a, ...b]
          ),
        {}
      ),
    [contacts, emails]
  )

  const handleToggleGroup = (group: string, included: boolean) => {
    if (included) {
      handleRemoveEmails(groups[group].map(({ email }) => email))
    } else {
      handleAddEmails(groups[group].map(({ email }) => email))
    }
  }

  const handleToggleEmails = useCallback(
    (checked: boolean, newEmails: SharedOrderCreateParams["sharedWithUserEmails"]) => {
      if (!checked) {
        handleAddEmails(newEmails)
      } else {
        handleRemoveEmails(newEmails)
      }
    },
    [handleAddEmails, handleRemoveEmails]
  )

  const selectedEmailsNotInGroups = useMemo(
    () =>
      emails.filter((e) => {
        if (!e.crmContactId) return true
        const contact = findContactById(e.crmContactId.id)
        if (!contact || !contact.groups) return true
        if (Object.values(contact.groups).some(Boolean)) return false
        return true
      }),
    [emails, findContactById]
  )

  return (
    <>
      {Object.entries(groups).map(([group, members]) =>
        members.length ? (
          <div key={group}>
            <SubtleAccordion
              header={
                <StopClickPropagation>
                  <CheckboxWithLabel
                    checked={members.every(({ isChecked }) => isChecked)}
                    title={`${group} (${members.length})`}
                    onChange={(e) => {
                      handleToggleGroup(
                        group,
                        members.every((m) => m.isChecked)
                      )
                    }}
                    id={group}
                  />
                </StopClickPropagation>
              }
              content={
                <div className="flex flex-col ml-8 -mt-8">
                  {members.map((member) => (
                    <CheckboxWithLabel
                      id={member.crmContact.id}
                      key={member.crmContact.id}
                      title={
                        <CRMContactName
                          contact={member.crmContact}
                          isSubtitleDisplayed
                          isIconDisplayed={false}
                        />
                      }
                      checked={member.isChecked}
                      onChange={(e) => handleToggleEmails(member.isChecked, [member.email])}
                    />
                  ))}
                </div>
              }
            />
          </div>
        ) : null
      )}
      <SelectedContacts
        emails={selectedEmailsNotInGroups}
        handleRemoveEmail={(e) => handleRemoveEmails([e])}
      />
    </>
  )
}
