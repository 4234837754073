import { POSTGRES_QUERIES } from "common/utils/postgres"
import * as zod from "zod"
import { parseWith } from "common/utils/zodUtils"
import { CurrentUser } from "../../../model/CurrentUser"
import { APIEndpoints, runAPIQuery } from "../../../firebase/API"
import {
  PostgresCompanyFundingRoundWithInvestors,
  PostgresCompanyInvestorWithCompanyFundingRounds,
  PostgresInvestorEmployee,
  postgresCompanyFundingRoundWithInvestors,
  postgresCompanyInvestorWithCompanyFundingRounds,
  postgresInvestorEmployee,
  emailRequestResult,
  EmailRequestResult,
} from "common/model/postgres/PostgresInvestors"
import { Loading } from "common/utils/Loading"

export const getInvestorsIntoCompanyByRounds = async (
  companyId: string,
  currentUser: Pick<CurrentUser, "authUser">
): Promise<Loading<PostgresCompanyFundingRoundWithInvestors[]>> => {
  const response = await runAPIQuery(
    APIEndpoints.postgresDBQuery,
    {
      query_type: POSTGRES_QUERIES.GET_INVESTORS_FOR_COMPANY_BY_ROUNDS,
      payload: {
        company_id: companyId,
      },
    },
    currentUser
  )
  const resJson: unknown = await response.json()
  const responseBody = parseWith(zod.array(postgresCompanyFundingRoundWithInvestors))(resJson)
  return responseBody.match(
    () => null,
    (r) => r
  )
}

export const getInvestorsIntoCompanyWithRounds = async (
  companyId: string,
  currentUser: Pick<CurrentUser, "authUser">
): Promise<Loading<PostgresCompanyInvestorWithCompanyFundingRounds[]>> => {
  const response = await runAPIQuery(
    APIEndpoints.postgresDBQuery,
    {
      query_type: POSTGRES_QUERIES.GET_INVESTORS_FOR_COMPANY_WITH_FUNDING_ROUNDS,
      payload: {
        company_id: companyId,
      },
    },
    currentUser
  )
  const resJson: unknown = await response.json()
  const responseBody = parseWith(zod.array(postgresCompanyInvestorWithCompanyFundingRounds))(
    resJson
  )
  return responseBody.match(
    () => null,
    (r) => r
  )
}

export const getInvestorEmployees = async (
  domain: string,
  currentUser: Pick<CurrentUser, "authUser">
): Promise<Loading<PostgresInvestorEmployee[]>> => {
  const response = await runAPIQuery(
    APIEndpoints.postgresDBQuery,
    {
      query_type: POSTGRES_QUERIES.GET_INVESTOR_EMPLOYEES,
      payload: { domain },
    },
    currentUser
  )
  const resJson: unknown = await response.json()
  const responseBody = parseWith(zod.array(postgresInvestorEmployee))(resJson)
  return responseBody.match(
    () => null,
    (r) => r
  )
}

export const requestEmployeeEmail = async (
  employeeId: string,
  linkedInUrl: string | null,
  currentUser: Pick<CurrentUser, "authUser">
): Promise<EmailRequestResult> => {
  const response = await runAPIQuery(
    APIEndpoints.postgresDBQuery,
    {
      query_type: POSTGRES_QUERIES.EMAIL_FOR_INVESTOR_EMPLOYEE,
      payload: { linkedin_link: linkedInUrl, person_id: employeeId },
    },
    currentUser
  )
  const resJson: unknown = await response.json()
  const responseBody = parseWith(emailRequestResult)(resJson)
  return responseBody.match(
    () => null,
    (r) => r
  )
}
