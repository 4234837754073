import { AccountIdFields } from "../../Account"
import { OpportunityInboxFilterRule } from "../../Order/OrderFilter/OpportunityInboxFilterRule"
import { Opportunity } from "../Opportunity"

export type OppDigestKeys =
  | "allOpportunities"
  | "archivedOpportunities"
  | "expiredOpportunities"
  | "primaryInboxOpportunities"
  | "secondaryInboxOpportunities"
  | "pinnedOpportunities"
export type RuleDigestKeys = "allRules" | OpportunityInboxFilterRule["tag"]
export type OpportunityInboxAccountUsageData = {
  account: AccountIdFields
  opportunityData: {
    all: Partial<Record<OppDigestKeys, number>>
    unread: Partial<Record<OppDigestKeys, number>>
  }
  ruleData: Partial<Record<RuleDigestKeys, number>>
  uploadData: {
    all: Partial<Record<Opportunity["source"]["tag"], number>>
    thisWeek: Partial<Record<Opportunity["source"]["tag"], number>>
    passingFilterThisWeek: Partial<Record<Opportunity["source"]["tag"], number>>
  }
}

export const defaultOpportunityInboxAccountUsageData: OpportunityInboxAccountUsageData = {
  account: { id: "", name: "", airtableId: "" },
  ruleData: {},
  opportunityData: { all: {}, unread: {} },
  uploadData: { all: {}, thisWeek: {}, passingFilterThisWeek: {} },
}
