import { DrawerControlProvider, useDrawerControls } from "@components/Drawer/DrawerControlContext"
import { Drawer, DrawerProps } from "@stories/components/Antd"
import { classNames } from "../../../utils/classNames"
import { DarkpoolOrderFormWarning } from "./DarkpoolOrderFormWarning"
import { OrderBrokerEditingClientOrderWarning } from "./OrderBrokerEditingClientOrderWarning"
import OrderForm from "./OrderForm"
import { OrderFormConfirmationPage } from "./OrderFormConfirmationPage"
import FullOrderFormContextProvider, { useOrderForm } from "./OrderFormContext"
import { OrderFormHeader } from "./OrderFormHeader"
import { OrderFormInitialInformation } from "./OrderFormInitialInformation"
import OrderFormLayout from "./OrderFormLayout"
import { OrderFormMarketPriceComparison } from "./OrderFormMarketPriceComparison"
import { OrderFormProgressButtons } from "./OrderFormProgressButtons"
import { OrderFormSteps } from "./OrderFormSteps"
import { OrderQuotaComplianceWarning } from "./OrderQuotaComplianceWarning"
import { OrderFormSaveFunction } from "./contextHooks/useFormOrderSaving"
import { OrderFormBrokeredBy } from "./OrderFormBrokeredBy"
import { useOrderFormCloseConfirm } from "./useOrderFormCloseConfirm"
import { FormOrder } from "common/model/Order/OrderForm/State"
import { useAccountTradingRegistrationStatus } from "src/utils/useAccountTradingRegistration"
import { TradingRegistrationRequiredModal } from "@components/TradingRegistration/TradingRegistrationRequiredModal"
import { CompanyAccessProvider } from "src/providers/AccessControl/CompanyAccessProvider"
import NewIcon from "@stories/components/Badges/NewIcon/NewIcon"
import Typography, { Size, Weight } from "@stories/components/Typography/Typography"
import { Button } from "@stories/components/Button/Button"
import { useState } from "react"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { useFirebaseWriter } from "src/firebase/Context"
import { handleConsoleError } from "src/utils/Tracking"

export const OrderFormDrawerInner = ({
  confirmationElement = OrderFormConfirmationPage,
  drawerTitle,
  drawerSubtitle,
}: {
  confirmationElement?: () => JSX.Element
  drawerTitle: string
  drawerSubtitle?: string
}) => {
  const { saveStatus, formOrder } = useOrderForm()
  const { onClose } = useDrawerControls()
  const Confirm = confirmationElement
  const [dismissed, setDismissed] = useState(false)
  const { user } = useLoggedInUser()
  const firestore = useFirebaseWriter()

  const onDismiss = () => {
    setDismissed(true)
    firestore
      .recordUserProductInteraction(user, {
        firstDismissedShortOrderBannerAnnouncement: new Date(),
      })
      .catch(handleConsoleError)
  }

  if (saveStatus === "pending") {
    return (
      <CompanyAccessProvider company={formOrder.company ?? null}>
        <OrderFormLayout.Header
          header={
            <>
              <OrderFormHeader
                drawerTitle={drawerTitle}
                drawerSubtitle={drawerSubtitle}
                onClose={onClose}
              />
              <OrderFormInitialInformation />
              <OrderFormSteps />
            </>
          }
          beforeDivider={
            dismissed ||
            !!user.productInteractionHistory.firstDismissedShortOrderBannerAnnouncement ||
            new Date("2024-09-01").getTime() < new Date().getTime() ? null : (
              <div className="border-y bg-neutral-100 border-accent-500 p-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-4">
                    <NewIcon />
                    <Typography
                      size={Size.Small}
                      weight={Weight.Semibold}
                      text="Shorter Order Form"
                    />
                    <Typography
                      size={Size.Small}
                      text="Submitting an order now takes half the time"
                    />
                  </div>
                  <Button variant="hollow-link" label="Dismiss" onClick={onDismiss} />
                </div>
              </div>
            )
          }
        />
        <OrderFormLayout.Wrapper
          main={<OrderForm />}
          sidebar={
            <>
              <DarkpoolOrderFormWarning />
              <OrderFormMarketPriceComparison />
              <OrderFormBrokeredBy />
              <OrderQuotaComplianceWarning />
              <OrderBrokerEditingClientOrderWarning />
            </>
          }
        />
        <OrderFormLayout.Footer footer={<OrderFormProgressButtons />} />
      </CompanyAccessProvider>
    )
  }
  return (
    <div className="p-4">
      <div className={classNames("w-full md:sticky z-50", "top-0 bg-neutral-white p-8 pb-4")}>
        <OrderFormHeader
          drawerTitle={drawerTitle}
          drawerSubtitle={drawerSubtitle}
          onClose={onClose}
        />
      </div>
      <Confirm />
    </div>
  )
}

const OrderFormDrawer = ({
  onClose,
  open,
  initialFormOrder,
  initialOrderId,
  onSave,
  drawerTitle,
}: {
  onClose: () => void
  open: Required<DrawerProps["open"]>
  onSave: OrderFormSaveFunction
  initialFormOrder: Partial<FormOrder>
  initialOrderId?: string
  drawerTitle?: string
}) => {
  const { isAccountApprovedForTrading } = useAccountTradingRegistrationStatus()
  const { handleSaveWithConfirmation, handleDrawerCloseWithConfirmation, afterChange } =
    useOrderFormCloseConfirm({
      onClose,
      onSave,
    })

  if (!open) {
    return null
  }
  if (!isAccountApprovedForTrading) {
    return <TradingRegistrationRequiredModal open={open} onClose={onClose} dataType="order form" />
  }

  return (
    <div onClick={(e) => e.stopPropagation()} className="absolute" role="none">
      <Drawer
        open={open}
        onClose={handleDrawerCloseWithConfirmation}
        placement="right"
        width={1200}
        contentWrapperStyle={{ maxWidth: "100vw" }}
        bodyStyle={{ padding: 0 }}
        closable={false}
        maskClosable={false}
        destroyOnClose
      >
        <DrawerControlProvider onClose={handleDrawerCloseWithConfirmation}>
          <FullOrderFormContextProvider
            initialFormOrder={initialFormOrder}
            onSave={handleSaveWithConfirmation}
            afterChange={afterChange}
            orderFormOpen={!!open}
            initialOrderId={initialOrderId}
          >
            <OrderFormDrawerInner drawerTitle={drawerTitle ?? ""} />
          </FullOrderFormContextProvider>
        </DrawerControlProvider>
      </Drawer>
    </div>
  )
}

export default OrderFormDrawer
