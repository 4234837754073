import { annotate } from "common/utils/Coerce"
import { Company, CompanyWithPostgres, FundingRoundsSummary } from "common/model/Company"
import { mapLoading, Loading, deprecatedIsLoaded, Requesting } from "common/utils/Loading"
import { viewFieldWith, overField, UnsafeRec } from "common/utils/RecordUtils"
import _ from "lodash"
import { Interval } from "common/utils/data/Interval"
import { nullableToMaybe } from "common/containers/Maybe"
import {
  CaplightPriceEstimate,
  CaplightPriceEstimateMetaFromPostgres,
  CaplightPriceEstimateQualityScore,
} from "common/model/postgres/PostgresCaplightPriceEstimate"
import { MutualFundMarkParsed } from "common/model/postgres/PostgresFundMarks"
import { ThematicIndex } from "common/model/postgres/PostgresThematicIndex"
import { ArrayDataRequest } from "common/utils/ArrayDataRequest"
import { CompanyMarketHistorySummary } from "common/model/CompanyMarketHistorySummary"
import moment from "moment"
import {
  PostgresComparableCompany,
  FundamentalDataFromPostgres,
  IVolatilityDataForCompanyAndComps,
  PublicCompsIndexData,
  StatusString,
  CompanyIds,
  CurrentCompsSummary,
} from "../model/postgresData"
import {
  CompanyPriceEstimateSummary,
  HistoricalCompanyPriceEstimate,
  isHistoricalCompanyPriceEstimate,
} from "common/model/CompanyPriceEstimateSummary"

export const GET_TARGET_ISSUERS = "GET_TARGET_ISSUERS"
export const GET_ISSUER_INFO = "GET_ISSUER_INFO"
export const GET_ISSUER_COMPS = "GET_ISSUER_COMPS"
export const GET_PBID_INFO = "GET_PBID_INFO"
export const GET_STATUS_INFO = "GET_STATUS_INFO"
export const DELETE_COMP_INFO = "DELETE_COMP_INFO"
export const ADD_COMP_INFO = "ADD_COMP_INFO"
export const CONFIRM_COMP_INFO = "CONFIRM_COMP_INFO"
export const GET_FUNDAMENTAL_INFO = "GET_FUNDAMENTAL_INFO"
export const ADD_ANOTHER_ISSUER_COMP = "ADD_ANOTHER_ISSUER_COMP"
export const CONCATENATE_ISSUER_INFO = "CONCATENATE_ISSUER_INFO"
export const MARKETPRICE_COMPANIES_REQUEST_STATUS = "MARKETPRICE_COMPANIES_REQUEST_STATUS"
export const GET_VOLATILITY_COMPANY_AND_COMPS = "GET_VOLATILITY_COMPANY_AND_COMPS"
export const ADD_TO_TARGET_ISSUERS = "ADD_TO_TARGET_ISSUERS"
export const RECEIVE_PUBLIC_COMPS_INDEX = "RECEIVE_PUBLIC_COMPS_INDEX"
export const ADD_TO_LOADING_TARGET_ISSUERS = "ADD_TO_LOADING_TARGET_ISSUERS"
export const REMOVE_FROM_LOADING_TARGET_ISSUERS = "REMOVE_FROM_LOADING_TARGET_ISSUERS"
export const RECEIVE_MARKET_INSIGHT = "RECEIVE_MARKET_INSIGHT"
export const SET_CURRENT_COMPS_SUMMARY = "SET_CURRENT_COMPS_SUMMARY"
export const RECEIVE_CAPLIGHT_PRICE_ESTIMATE_FOR_CAPLIGHT_DATA_COMPANIES =
  "RECEIVE_CAPLIGHT_PRICE_ESTIMATE_FOR_CAPLIGHT_DATA_COMPANIES"
export const RECEIVE_CAPLIGHT_PRICE_ESTIMATE_FOR_A_COMPANY =
  "RECEIVE_CAPLIGHT_PRICE_ESTIMATE_FOR_A_COMPANY"
export const RECEIVE_CAPLIGHT_PRICE_ESTIMATE_META_FOR_A_COMPANY =
  "RECEIVE_CAPLIGHT_PRICE_ESTIMATE_META_FOR_A_COMPANY"
export const RECEIVE_CAPLIGHT_PRICE_ESTIMATE_MODEL_LIST =
  "RECEIVE_CAPLIGHT_PRICE_ESTIMATE_MODEL_LIST"
export const RECEIVE_CAPLIGHT_PRICE_ESTIMATE_FOR_CAPLIGHT_DATA_COMPANIES_ALTERNATIVE_MODEL =
  "RECEIVE_CAPLIGHT_PRICE_ESTIMATE_FOR_CAPLIGHT_DATA_COMPANIES_ALTERNATIVE_MODEL"
export const RECEIVE_MUTUAL_FUND_MARKS_FOR_A_COMPANY = "RECEIVE_MUTUAL_FUND_MARKS_FOR_A_COMPANY"
export const RECEIVE_THEMATIC_INDICES = "RECEIVE_THEMATIC_INDICES"
export const RECEIVE_PRICE_ESTIMATE_FIXING = "RECEIVE_PRICE_ESTIMATE_FIXING"
export const RECEIVE_PRICE_ESTIMATE_SCORES = "RECEIVE_PRICE_ESTIMATE_SCORES"
export const CAPLIGHT_DATA_COMPANIES_REQUEST_STATUS = "CAPLIGHT_DATA_COMPANIES_REQUEST_STATUS"
export const RECEIVE_PRICE_INSIGHT = "RECEIVE_PRICE_INSIGHT"
export const RECEIVE_MOST_VIEWED_COMPANIES = "RECEIVE_MOST_VIEWED_COMPANIES"
export const RECEIVE_ALL_SECTOR_LIST = "RECEIVE_ALL_SECTOR_LIST"
export const RECEIVE_DEFAULT_CAPLIGHT_PRICE_ESTIMATE_FOR_A_COMPANY =
  "RECEIVE_DEFAULT_CAPLIGHT_PRICE_ESTIMATE_FOR_A_COMPANY"

const makeLastTradeSerializable = (lastTrade: Required<Company>["lastTrade"]) =>
  overField(
    "observationDate",
    Interval.map((x) => x.valueOf()),
    overField(
      "observedBy",
      () => null,
      overField("createdDate", (date) => date.valueOf(), lastTrade)
    )
  )

const makeFundingRoundNodeSerializable = (
  n:
    | {
        date: Date
      }
    | undefined
) => (!n ? n : overField("date", (x) => x.valueOf(), n))

const makeUpdatedAtSerializable = (
  n:
    | {
        updatedAt: Date
      }
    | undefined
) => (!n ? n : overField("updatedAt", (x) => x.valueOf(), n))

const makeFundingRoundsSummarySerializable = (o: FundingRoundsSummary) =>
  overField(
    "lastRound",
    makeFundingRoundNodeSerializable,
    overField(
      "lastKnownValuation",
      makeFundingRoundNodeSerializable,
      overField(
        "lastKnownPPS",
        makeFundingRoundNodeSerializable,
        overField("lastKnownShareCount", makeFundingRoundNodeSerializable, o)
      )
    )
  )

const makeCompanyPriceEstimateSerializable = (o: CompanyPriceEstimateSummary) => {
  const initial: Record<
    string,
    | HistoricalCompanyPriceEstimate
    | (Omit<HistoricalCompanyPriceEstimate, "date"> & { date: number })
    | number
    | string
    | undefined
  > = overField(
    "lastModelRunAt",
    (x: Date | undefined) => x?.valueOf(),
    overField("updatedAt", (x: Date) => x.valueOf(), o)
  )
  return UnsafeRec.keys(o).reduce(
    (accum, k) =>
      overField(
        k,
        (v1) =>
          isHistoricalCompanyPriceEstimate(v1)
            ? overField("date", (x: Date) => x.valueOf(), v1)
            : k === "updatedAt" && v1
            ? v1.valueOf()
            : v1,
        accum
      ),
    initial
  )
}

const makeCompanySerializable = (c: Company) =>
  annotate<Company>({
    ...c,
    ...viewFieldWith(
      "lastTrade",
      (x) => nullableToMaybe(x).map(makeLastTradeSerializable).withUnconstrainedDefault(undefined),
      c
    ),
    ...viewFieldWith(
      "valuations409a",
      (data) => data.map((obs) => ({ ...obs, date: obs.date.valueOf() })),
      c
    ),
    ...viewFieldWith(
      "fundingRounds",
      (data) =>
        data.map((obs) => ({
          ...obs,
          date: obs.date.valueOf(),
          updatedAt: obs.updatedAt.valueOf(),
        })),
      c
    ),
    ...viewFieldWith(
      "fundingRoundsSummary",
      (x) =>
        nullableToMaybe(x)
          .map(makeFundingRoundsSummarySerializable)
          .withUnconstrainedDefault(undefined),
      c
    ),
    ...viewFieldWith(
      "priceEstimatesSummary",
      (x) =>
        nullableToMaybe(x)
          .map(makeCompanyPriceEstimateSerializable)
          .withUnconstrainedDefault(undefined),
      c
    ),
    ...viewFieldWith(
      "marketHistorySummary",
      (x) => nullableToMaybe(x).map(makeUpdatedAtSerializable).withUnconstrainedDefault(undefined),
      c
    ),
    ...viewFieldWith(
      "statusDetails",
      (statusDetails) => ({
        ...statusDetails,
        latestPublicOffering: {
          ...statusDetails.latestPublicOffering,
          date: statusDetails.latestPublicOffering?.date
            ? statusDetails.latestPublicOffering.date.valueOf()
            : null,
        },
      }),
      c
    ),
    ...viewFieldWith(
      "stockSplits",
      (data) =>
        data.map((obs) => ({
          ...obs,
          splitData: { ...obs.splitData, date: obs.splitData.date.valueOf() },
        })),
      c
    ),
    ...viewFieldWith(
      "filings",
      (data) =>
        data.map((obs) => ({
          ...obs,
          filingDate: obs.filingDate.valueOf(),
          createdAt: obs.createdAt.valueOf(),
        })),
      c
    ),
  })

// TODO: complete & test better
export const unserializeCompany = (c: CompanyWithPostgres) =>
  annotate<CompanyWithPostgres>({
    ...c,
    ...viewFieldWith(
      "stockSplits",
      (data) =>
        data.map((obs) => ({
          ...obs,
          splitData: { ...obs.splitData, date: moment(obs.splitData.date).toDate() },
        })),
      c
    ),
    ...viewFieldWith(
      "filings",
      (data) =>
        data.map((obs) => ({
          ...obs,
          filingDate: moment(obs.filingDate).toDate(),
          createdAt: moment(obs.createdAt).toDate(),
        })),
      c
    ),
    ...viewFieldWith(
      "fundingRounds",
      (data) =>
        data.map((obs) => ({
          ...obs,
          date: moment(obs.date).toDate(),
        })),
      c
    ),
    ...viewFieldWith(
      "statusDetails",
      (statusDetails) => ({
        ...statusDetails,
        latestPublicOffering: {
          ...statusDetails.latestPublicOffering,
          date: statusDetails.latestPublicOffering?.date
            ? moment(statusDetails.latestPublicOffering.date).toDate()
            : null,
        },
      }),
      c
    ),
  })

export const receivePublicCompsIndex = (publicCompsIndices: {
  [companyId: string]: PublicCompsIndexData[] | "loading" | undefined
}) => ({
  type: RECEIVE_PUBLIC_COMPS_INDEX,
  publicCompsIndices,
})

export const receiveTargetIssuers = (targetIssuers: Company[] | "loading") => ({
  type: GET_TARGET_ISSUERS,
  targetIssuers: mapLoading((cs: Company[]) => cs.map((c) => makeCompanySerializable(c)))(
    targetIssuers
  ),
})

export const addToLocalTargetIssuers = (extraIssuer: Company | "loading") => ({
  type: ADD_TO_TARGET_ISSUERS,
  extraIssuer: mapLoading(makeCompanySerializable)(extraIssuer),
})

export const makeIssuerLoading = (ids: CompanyIds) => ({
  type: ADD_TO_LOADING_TARGET_ISSUERS,
  ids,
})

export const removeIssuerFromLoading = (ids: CompanyIds) => ({
  type: REMOVE_FROM_LOADING_TARGET_ISSUERS,
  ids,
})

export const receiveCompanyInfo = (
  companyId: string,
  companyInfo: { [key: string]: unknown }[] // TODO fix
) => ({
  type: GET_ISSUER_INFO,
  companyId,
  companyInfo,
})

export interface Comp {
  companyId: string // comp's id
  ticker?: string
  description: string
  companyName: string // comp's name
  hasMarketPrice: boolean
}

export const addAnotherIssuerComp = (
  companyId: string,
  extraCompInfo: Comp,
  directOrReverse: "direct" | "reverse" = "direct"
) => ({
  // this just temporarily adds a comp to the list, after user selects some company
  type: ADD_ANOTHER_ISSUER_COMP,
  companyId,
  extraCompInfo,
  directOrReverse,
})

export const addExtraCompanyInfo = (
  companyId: string,
  extraCompanyInfo: { [key: string]: unknown }
) => ({
  type: CONCATENATE_ISSUER_INFO,
  companyId,
  extraCompanyInfo,
})

export const receiveVolatility = (
  companyId: string,
  volatilityData: IVolatilityDataForCompanyAndComps[]
) => ({
  type: GET_VOLATILITY_COMPANY_AND_COMPS,
  companyId,
  volatilityData,
})

export const receiveCompsInfo = (
  companyId: string,
  comps: PostgresComparableCompany[] | "loading",
  directOrReverse: "direct" | "reverse" = "direct"
) => ({
  type: GET_ISSUER_COMPS,
  companyId,
  comps,
  directOrReverse,
})

export const receivePbids = (companyId: string, pbid: string) => ({
  type: GET_PBID_INFO,
  companyId,
  pbid,
})

export const deleteCompsInfo = (
  issuer: string,
  comp: string,
  directOrReverse: "direct" | "reverse"
) => ({
  type: DELETE_COMP_INFO,
  issuer,
  comp,
  directOrReverse,
})

export const confirmCompsInfo = (
  issuer: string,
  comp: string,
  directOrReverse: "direct" | "reverse"
) => ({
  type: CONFIRM_COMP_INFO,
  issuer,
  comp,
  directOrReverse,
})

export const addedCompsInfo = (
  comp: Comp,
  companyId: string,
  directOrReverse: "direct" | "reverse"
) => ({
  type: ADD_COMP_INFO,
  companyId,
  extraCompInfo: comp,
  directOrReverse,
})
// {
//   issuerId: string
//   compId: string
//   compName: string
//   description: string
//   directOrReverse: "direct" | "reverse"
//   ticker?: string
//   hasMarketPrice: boolean
// }
export const receiveStatus = (companyId: string, status: StatusString) => ({
  type: GET_STATUS_INFO,
  companyId,
  status,
})

export const receiveFundamentalInfo = (
  companyId: string,
  fundamentalInfoArray: FundamentalDataFromPostgres[]
) => ({
  type: GET_FUNDAMENTAL_INFO,
  companyId,
  fundamentalInfoArray,
})

export const setCurrentCompsSummary = (currentCompsSummaryData: CurrentCompsSummary[]) => ({
  type: SET_CURRENT_COMPS_SUMMARY,
  currentCompsSummaryData,
})

export const receiveCaplightPriceEstimateForCaplightDataCompanies = (
  caplightPriceEstimateFromPostgres: Loading<CaplightPriceEstimate[]>
) => ({
  type: RECEIVE_CAPLIGHT_PRICE_ESTIMATE_FOR_CAPLIGHT_DATA_COMPANIES,
  caplightPriceEstimateFromPostgres: deprecatedIsLoaded(caplightPriceEstimateFromPostgres)
    ? annotate<{ [companyId: string]: CaplightPriceEstimate[] }>(
        Object.fromEntries(
          _.uniq(
            caplightPriceEstimateFromPostgres.map((row: CaplightPriceEstimate) => row.companyId)
          ).map((companyId) => [
            companyId,
            caplightPriceEstimateFromPostgres.filter((row) => row.companyId === companyId),
          ])
        )
      )
    : "loading",
})

export const receiveCaplightPriceEstimateForCaplightDataCompaniesAlternativeModel = (
  caplightPriceEstimateFromPostgres: Loading<CaplightPriceEstimate[]>
) => ({
  type: RECEIVE_CAPLIGHT_PRICE_ESTIMATE_FOR_CAPLIGHT_DATA_COMPANIES_ALTERNATIVE_MODEL,
  caplightPriceEstimateFromPostgres: deprecatedIsLoaded(caplightPriceEstimateFromPostgres)
    ? Object.fromEntries(
        _.uniq(caplightPriceEstimateFromPostgres.map((row) => row.companyId)).map((companyId) => [
          companyId,
          caplightPriceEstimateFromPostgres.filter((row) => row.companyId === companyId),
        ])
      )
    : "loading",
})

export const receiveCaplightPriceEstimateForACompany = (
  companyId: string,
  caplightPriceEstimateForACompany: Loading<CaplightPriceEstimate[]>
) => ({
  type: RECEIVE_CAPLIGHT_PRICE_ESTIMATE_FOR_A_COMPANY,
  companyId,
  caplightPriceEstimateForACompany,
})

export const receiveDefaultCaplightPriceEstimateForACompany = (
  companyId: string,
  caplightPriceEstimateForACompany: Loading<CaplightPriceEstimate[]>,
  modelVersion: string
) => ({
  type: RECEIVE_DEFAULT_CAPLIGHT_PRICE_ESTIMATE_FOR_A_COMPANY,
  companyId,
  caplightPriceEstimateForACompany,
  modelVersion,
})

export const receiveCaplightPriceEstimateMetaForACompany = (
  companyId: string,
  caplightPriceEstimateMetaForACompany: Loading<CaplightPriceEstimateMetaFromPostgres>
) => ({
  type: RECEIVE_CAPLIGHT_PRICE_ESTIMATE_META_FOR_A_COMPANY,
  companyId,
  caplightPriceEstimateMetaForACompany,
})

export const receiveCaplightPriceEstimateModelList = (
  listOfModels: Loading<{
    fullList: string[]
    default: string
  }>
) => ({
  type: RECEIVE_CAPLIGHT_PRICE_ESTIMATE_MODEL_LIST,
  listOfModels,
})

export const receiveMutualFundMarksForACompany = (
  companyId: string,
  mutualFundMarks: Loading<MutualFundMarkParsed[]>
) => ({
  type: RECEIVE_MUTUAL_FUND_MARKS_FOR_A_COMPANY,
  companyId,
  mutualFundMarks,
})

export const receiveAllThematicIndices = (result: Requesting<ThematicIndex[]>) => ({
  type: RECEIVE_THEMATIC_INDICES,
  result,
})

export const receivePriceEstimateMonthlyFixing = (
  companyId: string,
  result: ArrayDataRequest<CaplightPriceEstimate>
) => ({
  type: RECEIVE_PRICE_ESTIMATE_FIXING,
  companyId,
  result,
})

export type CompanyMarketInsightsType =
  | "most_bids"
  | "most_asks"
  | "most_trades"
  | "most_relative_asks"
  | "most_relative_bids"
export const receiveCompaniesMarketInsight = (
  insight: CompanyMarketInsightsType,
  timeFrame: keyof Omit<CompanyMarketHistorySummary, "updatedAt">,
  indicator: "byUSD" | "byCount",
  companyPostgresIds: Loading<string[]>
) => ({
  type: RECEIVE_MARKET_INSIGHT,
  companyPostgresIds,
  timeFrame,
  indicator,
  insight,
})
export const receiveCompaniesPriceInsight = (
  insight: "positive_price_moves" | "negative_price_moves",
  timeFrame: keyof Omit<CompanyMarketHistorySummary, "updatedAt">,
  companyPostgresIds: Loading<string[]>
) => ({
  type: RECEIVE_PRICE_INSIGHT,
  companyPostgresIds,
  timeFrame,
  insight,
})
export const caplightDataCompaniesRequested = (status: Loading<boolean>) => ({
  type: CAPLIGHT_DATA_COMPANIES_REQUEST_STATUS,
  status,
})
export const marketPriceCompaniesRequested = (status: Loading<boolean>) => ({
  type: MARKETPRICE_COMPANIES_REQUEST_STATUS,
  status,
})
export const receiveMostViewedCompanies = (
  timeFrame: keyof Omit<CompanyMarketHistorySummary, "updatedAt">,
  companyPostgresIds: Loading<{ companyPostgresId: string; viewsPercent: number }[]>
) => ({
  type: RECEIVE_MOST_VIEWED_COMPANIES,
  companyPostgresIds,
  timeFrame,
})

export const receivePriceEstimateScores = (
  companyId: string,
  scores: Loading<CaplightPriceEstimateQualityScore[]>
) => ({
  type: RECEIVE_PRICE_ESTIMATE_SCORES,
  scores,
  companyId,
})

export const receiveAllSectorList = (sectors: Loading<string[]>) => ({
  type: RECEIVE_ALL_SECTOR_LIST,
  sectors,
})
