import { PageTitle } from "@components/typography/PageTitle"
import { Button } from "@stories/components/Button/Button"
import Divider from "@stories/components/Divider/Divider"
import { Account } from "common/model/Account"
import { deprecatedIsLoaded } from "common/utils/Loading"
import { HashLink } from "react-router-hash-link"
import { useCurrentAccount } from "src/queries/currentUser/useCurrentAccount"
import { TopBrokerBenefits } from "./TopBrokerBenefits"
import { LiveTopBrokerCriteria } from "./TopBrokerCriteria/LiveTopBrokerCriteria"
import { PreviousTopBrokerCriteria } from "./TopBrokerCriteria/PreviousTopBrokerCriteria"

export const LoadedTopBrokerTracking = ({ account }: { account: Account }) => (
  <div className="flex flex-col gap-4 p-4">
    <div className="flex items-end gap-4">
      <PageTitle title="Top Broker" />
      <HashLink to="#top-broker-benefits" smooth>
        <Button variant="hollow-link" label="Learn about the Top Broker Program" />
      </HashLink>
    </div>
    <PreviousTopBrokerCriteria
      account={account}
      quarterStartDate={new Date("7/1/2023")}
      quarterEndDate={new Date("9/31/2023")}
    />
    <div className="py-4">
      <Divider />
    </div>
    <LiveTopBrokerCriteria
      account={account}
      quarterStartDate={new Date("10/1/2023")}
      quarterEndDate={new Date("12/31/2023")}
    />
    <Divider label="About Top Broker" shouldWrap={false} />
    <TopBrokerBenefits />
  </div>
)

export const TopBrokerTracking = () => {
  const account = useCurrentAccount()
  if (!deprecatedIsLoaded(account)) {
    return null
  }
  return <LoadedTopBrokerTracking account={account} />
}
