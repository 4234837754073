import { OfficeBuildingIcon } from "@heroicons/react/solid"
import { Skeleton } from "@stories/components/Antd"
import { CompanyWithPostgres, LogoSize } from "common/model/Company"
import { classNames } from "../../utils/classNames"
import useCompanyLogo, { CompanyAirtableId } from "../../utils/companies/useCompanyLogo"
import { logoClass } from "./CompanyLogo"

type CompanyLogoImgInnerProps = Omit<ReturnType<typeof useCompanyLogo>, "postgresId"> & {
  size: LogoSize
}
export const CompanyLogoImgInner = ({
  logoUrl,
  setError,
  error,
  size,
}: CompanyLogoImgInnerProps) => {
  if (error) {
    return <OfficeBuildingIcon className={classNames("text-neutral-600", logoClass[size])} />
  }

  if (!logoUrl) {
    return (
      <div className={`${logoClass[size]} bg-neutral-100`}>
        <Skeleton active paragraph={false} />
      </div>
    )
  }

  return (
    <img
      className="w-full max-h-full"
      alt=""
      src={logoUrl}
      onError={(err) => setError(err.toString())}
    />
  )
}

type CompanyLogoImgProps = {
  company: CompanyWithPostgres | CompanyAirtableId
  size: LogoSize
}

const CompanyLogoImg = ({ company, size }: CompanyLogoImgProps) => {
  const { logoUrl, error, setError } = useCompanyLogo(company, size)

  return <CompanyLogoImgInner logoUrl={logoUrl} error={error} setError={setError} size={size} />
}

export default CompanyLogoImg
