import { useFirebase9 } from "../../../../firebase/Firebase9Context"
import {
  ConstructedOpportunityInboxFilterRule,
  OpportunityInboxFilterRule,
} from "common/model/Order/OrderFilter/OpportunityInboxFilterRule"
import { Loading, deprecatedIsLoaded } from "common/utils/Loading"
import { useCallback, useEffect, useState } from "react"
import { handleConsoleError, trackOpportunityInboxFilterRuleCreated } from "src/utils/Tracking"
import { useCurrentAccount } from "src/queries/currentUser/useCurrentAccount"
import { APIEndpoints, getAPIResponse } from "src/firebase/API"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"

export const useOrderOpportunityRules = () => {
  const user = useLoggedInUser()
  const account = useCurrentAccount()
  const firebase9 = useFirebase9()
  const [orderRules, setOrderRules] = useState<Loading<OpportunityInboxFilterRule[]>>("loading")

  useEffect(() => {
    if (deprecatedIsLoaded(account)) {
      return firebase9.getOpportunityInboxFilterRules(account.id, (data) =>
        setOrderRules(data.docs.map((d) => d.data()).filter(({ active }) => active))
      )
    }
    return () => null
  }, [account, firebase9])

  const createOrderRule = useCallback(
    (
      filter: ConstructedOpportunityInboxFilterRule,
      runRule?: boolean,
      afterSave?: (ruleId: string) => void
    ) =>
      firebase9
        .saveOpportunityInboxFilterRule(filter)
        .then(async (rule) => {
          if (runRule) {
            await getAPIResponse(
              APIEndpoints.updateOpportunitiesWithRule,
              { ruleId: rule?.id, tag: "apply" },
              user
            ).catch(handleConsoleError)
          }
          return rule
        })
        .then((rule) => afterSave?.(rule.id))
        .then(() => trackOpportunityInboxFilterRuleCreated(filter))
        .catch(handleConsoleError),
    [firebase9, user]
  )

  const removeOrderRule = useCallback(
    (rule: OpportunityInboxFilterRule, removeRule?: boolean) =>
      firebase9
        .deactivateOpportunityInboxFilterRule(rule)
        .then(async () => {
          if (removeRule) {
            await getAPIResponse(
              APIEndpoints.updateOpportunitiesWithRule,
              { ruleId: rule?.id, tag: "remove" },
              user
            ).catch(handleConsoleError)
          }
        })
        .catch(handleConsoleError),
    [firebase9, user]
  )

  return {
    orderRules,
    createOrderRule,
    removeOrderRule,
  }
}
