import Spinner from "@components/icons/Spinner"
import { PencilAltIcon } from "@heroicons/react/solid"
import { Tooltip } from "@stories/components/Antd"
import { TrashIcon } from "@stories/icons/TrashIcon"
import { DealCRMNote } from "common/model/DealCRM/DealCRMNote"
import { UserFunctions, viewUserIdFields } from "common/model/User"
import { isLoaded } from "common/utils/Loading"
import { titleCase } from "common/utils/StringUtils"
import { articulatedDateTimeFormat } from "common/utils/dateUtils"
import { assertUnreachable } from "common/utils/fp/Function"
import * as React from "react"
import { useEffect } from "react"
import { useFirebaseWriter } from "src/firebase/Context"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import { deleteCRMNote, updateCRMNote } from "src/firebase/crmNotes"
import { UserAvatar } from "src/pages/Orders/shared/Cells"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import { handleConsoleError, trackEventInFirestoreAndHeap } from "src/utils/Tracking"
import { Button } from "../../../../stories/components/Button/Button"
import Typography, {
  Color,
  Size,
  Weight,
} from "../../../../stories/components/Typography/Typography"
import { ContactPill } from "../ContactPill/ContactPill"
import { DealPill } from "../DealPill/DealPill"
import { ITipTapEditor } from "./ITipTapEditor"
import { ReplyDisplay } from "./ReplyDisplay"
import { WYSIWYGEditor } from "./WYSIWYGEditor"
import { buildCRMNote } from "./createCRMNote"
import { getAllNoteMentions } from "./getAllNoteMentions"
import { useTipTapEditor } from "./useTipTapEditor"

export interface NoteDisplayProps {
  note: DealCRMNote
  showSourcePill?: boolean
  displayPage: "deal" | "contact" | "activity-log"
  viewOnly?: boolean
  hideCreatedAt?: boolean
  hideCreator?: boolean
  afterReplyAdded?: () => void
}

export const NoteDisplay: React.FC<NoteDisplayProps> = ({
  note,
  showSourcePill,
  displayPage,
  viewOnly,
  hideCreatedAt,
  hideCreator,
  afterReplyAdded,
}) => {
  const [isHovered, setIsHovered] = React.useState<boolean>(false)
  const [isEditing, setIsEditing] = React.useState<boolean>(false)
  const db = useFirebaseWriter()
  const firebase9 = useFirebase9()
  const user = useCurrentUser()

  const handleDeleteNoteClick = () => {
    deleteCRMNote({ db, note }).catch((e) => {
      handleConsoleError("Error deleting crm note", e)
    })
  }

  const handleUpdateNote = async (editor: ITipTapEditor) => {
    if (isLoaded(user)) {
      const updatedContent = editor.getJSON()
      const mentions = getAllNoteMentions(updatedContent)
      const updatedNote: DealCRMNote = {
        ...buildCRMNote({
          content: updatedContent,
          source: note.source,
          mentions,
          user: user.user,
        }),
        parentNote: note.parentNote,
        id: note.id,
        source: note.source,
        createdAt: note.createdAt,
        updatedAt: new Date(),
        updatedBy: viewUserIdFields(user.user),
      }
      return updateCRMNote({ db, note, updatedNote })
        .catch((e) => {
          handleConsoleError("Error updating note", e)
        })
        .then(() => trackEventInFirestoreAndHeap(firebase9, user.user, "crm-note-updated"))
        .finally(() => {
          editor.commands?.focus(false)
          setIsEditing(false)
        })
    }
    return Promise.resolve()
  }

  const editor = useTipTapEditor({
    handleSaveNote: handleUpdateNote,
    initialContent: note.note,
    textClassName: "text-sm",
  })

  useEffect(() => {
    if (isEditing) {
      editor?.setOptions({ editable: true })
      editor?.commands.focus("start")
    } else {
      editor?.setOptions({ editable: false })
    }
  }, [isEditing, editor])

  if (!isLoaded(user)) {
    return <Spinner size="md" />
  }

  return (
    <div
      key={note.id}
      className="bg-neutral-white py-2 relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="w-full flex flex-col gap-1 text-neutral-700">
        <div className="flex items-center justify-between">
          <div className="flex gap-2 items-center flex-wrap">
            {hideCreator ? null : (
              <>
                <UserAvatar character={UserFunctions.name(note.createdBy)} size="sm" />
                <Typography
                  text={UserFunctions.name(note.createdBy)}
                  size={Size.XSmall}
                  weight={Weight.Semibold}
                />
              </>
            )}
            {showSourcePill && <NoteSourcePill note={note} displayPage={displayPage} />}
          </div>
          {hideCreatedAt ? null : (
            <div className="flex gap-2 items-center flex-wrap">
              <Typography
                size={Size.XSmall}
                color={Color.Subtitle}
                text={`${articulatedDateTimeFormat(note.createdAt).date} ${
                  articulatedDateTimeFormat(note.createdAt).time
                }`}
              />
              {note.updatedAt && note.updatedBy && (
                <>
                  <Typography size={Size.XSmall} color={Color.Subtitle} text="•" />
                  <Typography
                    size={Size.XSmall}
                    color={Color.Subtitle}
                    text={
                      note.updatedBy.id === note.createdBy.id
                        ? `Updated ${articulatedDateTimeFormat(note.updatedAt).date} ${
                            articulatedDateTimeFormat(note.updatedAt).time
                          }`
                        : `Updated by ${UserFunctions.name(note.updatedBy)} ${
                            articulatedDateTimeFormat(note.updatedAt).date
                          } ${articulatedDateTimeFormat(note.updatedAt).time}`
                    }
                  />
                </>
              )}
            </div>
          )}
        </div>
        {isHovered && !viewOnly && user.user.id === note.createdBy.id && (
          <div className="absolute right-4 top-10 z-10">
            <div className="flex items-center gap-2">
              <Tooltip title="Edit">
                <div>
                  <Button
                    data-testid="edit-crm-note-button"
                    heapName="edit-crm-note-button"
                    size="small"
                    isTextWrapped={false}
                    variant="secondary"
                    leftIcon={<PencilAltIcon className="w-3 h-3 text-primary-500" />}
                    onClick={() => {
                      setIsEditing(true)
                    }}
                  />
                </div>
              </Tooltip>
              <Tooltip title="Delete">
                <div>
                  <Button
                    data-testid="delete-crm-note-button"
                    heapName="delete-crm-note-button"
                    size="small"
                    isTextWrapped={false}
                    variant="secondary"
                    leftIcon={<TrashIcon />}
                    onClick={() => {
                      handleDeleteNoteClick()
                    }}
                  />
                </div>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
      <div className="text-neutral-1000">
        {editor && (
          <WYSIWYGEditor
            editor={editor}
            showSubmitButton={isEditing}
            submitButtonText="Save"
            handleSubmit={handleUpdateNote}
            handleCancel={isEditing ? () => setIsEditing(false) : undefined}
          />
        )}
      </div>
      <ReplyDisplay note={note} displayPage={displayPage} afterReplyAdded={afterReplyAdded} />
    </div>
  )
}

export const NoteSourcePill = ({
  note,
  displayPage,
}: {
  note: DealCRMNote
  displayPage: "deal" | "contact" | "activity-log"
}) => {
  switch (note.source.sourceType) {
    case "company":
      return <Typography text={note.source.name} size={Size.XSmall} />
    case "interest":
      return (
        <Typography
          text={`${note.source.company.name} ${titleCase(note.source.interestDirection)} Interest`}
          size={Size.XSmall}
        />
      )
    case "contact":
      return <Typography text={note.source.name} size={Size.XSmall} />
    case "dealParticipant":
      if (displayPage === "deal") {
        return <ContactPill contact={note.source.contact} />
      }
      return <DealPill deal={note.source.deal} company={note.source.company} />
    case "deal":
      return (
        <DealPill
          deal={{ id: note.source.sourceId, name: note.source.name }}
          company={note.source.company}
        />
      )
    case "holding":
      return <Typography text={`${note.source.company.name} Holding`} size={Size.XSmall} />
    default:
      return assertUnreachable(note.source)
  }
}
