import { wrapIcon } from "./IconWrapper"

export const VerticalDotsIcon = wrapIcon(
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.00002 11.3333C8.35364 11.3333 8.69278 11.4738 8.94283 11.7239C9.19288 11.9739 9.33335 12.313 9.33335 12.6667C9.33335 13.0203 9.19288 13.3594 8.94283 13.6095C8.69278 13.8595 8.35364 14 8.00002 14C7.6464 14 7.30726 13.8595 7.05721 13.6095C6.80716 13.3594 6.66669 13.0203 6.66669 12.6667C6.66669 12.313 6.80716 11.9739 7.05721 11.7239C7.30726 11.4738 7.6464 11.3333 8.00002 11.3333ZM8.00002 6.66667C8.35364 6.66667 8.69278 6.80714 8.94283 7.05719C9.19288 7.30724 9.33335 7.64638 9.33335 8C9.33335 8.35362 9.19288 8.69276 8.94283 8.94281C8.69278 9.19286 8.35364 9.33333 8.00002 9.33333C7.6464 9.33333 7.30726 9.19286 7.05721 8.94281C6.80716 8.69276 6.66669 8.35362 6.66669 8C6.66669 7.64638 6.80716 7.30724 7.05721 7.05719C7.30726 6.80714 7.6464 6.66667 8.00002 6.66667ZM8.00002 2C8.35364 2 8.69278 2.14048 8.94283 2.39052C9.19288 2.64057 9.33335 2.97971 9.33335 3.33333C9.33335 3.68696 9.19288 4.02609 8.94283 4.27614C8.69278 4.52619 8.35364 4.66667 8.00002 4.66667C7.6464 4.66667 7.30726 4.52619 7.05721 4.27614C6.80716 4.02609 6.66669 3.68696 6.66669 3.33333C6.66669 2.97971 6.80716 2.64057 7.05721 2.39052C7.30726 2.14048 7.6464 2 8.00002 2Z"
      fill="currentColor"
    />
  </svg>
)
