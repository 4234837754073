import _ from "lodash"
import { UnsafeRec } from "../../utils/RecordUtils"
import { Case } from "../../utils/types/Sum"
import { DataVisibilityFields } from "./DataPoint/VisibilityFields"
import {
  OutlierCheckResult,
  convertDeprecatedOutlierCheckResult,
  makeOutlierStatus,
} from "./DataPoint/OutlierStatusFields"
import { outlierDetectionModelEnforced, outlierDetectionModels } from "./OutlierDetection/Models"
import { isNotNull } from "../../utils/TypeUtils"

export const addOutlierResult = (
  existing: DataVisibilityFields,
  result: OutlierCheckResult
): DataVisibilityFields => {
  const existingOutlier = existing?.outlier && !existing.outlierStatus ? existing?.outlier : null
  const outlierHistory = (
    existingOutlier ? [convertDeprecatedOutlierCheckResult(existingOutlier)] : []
  )
    .concat(existing?.outlierStatus?.outlierHistory ?? [])
    .concat([result])
  return { ...existing, outlierStatus: makeOutlierStatus(outlierHistory) }
}

export const isHiddenOutlierResult = (outlier: OutlierCheckResult) =>
  (outlier?.tag === "auto flagged" &&
    outlier._flaggedFor?.some(
      (flag) => outlierDetectionModelEnforced[flag as keyof typeof outlierDetectionModels]
    )) ||
  outlier?.tag === "manually flagged" ||
  outlier?.tag === "outlier acknowledged"

export const isOutlier = (dataPoint: DataVisibilityFields) =>
  ((dataPoint.outlier && isHiddenOutlierResult(dataPoint.outlier)) ||
    (dataPoint.outlierStatus?.outlierSummary?.global &&
      isHiddenOutlierResult(dataPoint.outlierStatus?.outlierSummary.global)) ||
    (UnsafeRec.values(dataPoint?.outlierStatus?.outlierSummary?.terms ?? {}).every(
      isHiddenOutlierResult
    ) &&
      UnsafeRec.values(dataPoint?.outlierStatus?.outlierSummary?.terms ?? {}).length > 0)) ??
  false

export const latestOutlierStatusAsOfDate = (dataPoint: DataVisibilityFields): Date | null => {
  const date1 =
    dataPoint.outlier &&
    isHiddenOutlierResult(dataPoint.outlier) &&
    dataPoint.outlier?.tag !== "unflagged"
      ? dataPoint.outlier?.date
      : null
  const date2 =
    dataPoint.outlierStatus?.outlierSummary?.global &&
    isHiddenOutlierResult(dataPoint.outlierStatus?.outlierSummary?.global) &&
    dataPoint.outlierStatus?.outlierSummary?.global.tag !== "unflagged"
      ? dataPoint.outlierStatus?.outlierSummary?.global?.date
      : null
  const termDates = UnsafeRec.values(dataPoint?.outlierStatus?.outlierSummary?.terms ?? {}).map(
    (dp) => (isHiddenOutlierResult(dp) && dp.tag !== "unflagged" ? dp.date : null)
  )
  const onlyDateArray = [date1, date2, ...termDates].filter(isNotNull)
  if (onlyDateArray.length === 0) return null
  return _.min(onlyDateArray) ?? null
}
export const isHidden = (dataPoint: DataVisibilityFields) =>
  dataPoint.hidden || isOutlier(dataPoint)
export const flaggedFor = (o: Case<OutlierCheckResult, "auto flagged">): string[] =>
  o._flaggedFor ?? []

export const updateOutlierCheckResult = (
  existing: OutlierCheckResult | undefined | null,
  newResult: OutlierCheckResult
) => (existing?.tag === "manually flagged" ? existing : newResult)
