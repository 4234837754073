import { datadogRum } from "@datadog/browser-rum"
import { User, UserFunctions } from "common/model/User"
import { config } from "../config"

const init = () => {
  if (config.env === "prod") {
    datadogRum.init({
      applicationId: config.datadogApplicationId,
      clientToken: config.datadogClientToken,
      site: "datadoghq.com",
      service: "web-platform",
      env: "prod",
      version: config.releaseVersion,
      sampleRate: 100,
      premiumSampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: "allow",
      enableExperimentalFeatures: ["clickmap"],
    })

    datadogRum.startSessionReplayRecording()
  } else if (config.env === "staging") {
    datadogRum.init({
      applicationId: config.datadogApplicationId,
      clientToken: config.datadogClientToken,
      site: "datadoghq.com",
      service: "web-platform",
      env: "staging",
      version: config.releaseVersion,
      sampleRate: 100,
      premiumSampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: "allow",
      enableExperimentalFeatures: ["clickmap"],
    })

    datadogRum.startSessionReplayRecording()
  }
}

export const logErrorMessageToDatadogSessionRecording = (message: string) => {
  try {
    datadogRum.addError({ message })
  } catch {}
}

const identifyUser = (user: User) => {
  datadogRum.setUser({
    id: user.id,
    airtableId: user.airtableId,
    name: UserFunctions.name(user),
    email: user.email,
    accountName: user.account.name,
    accountId: user.account.id,
    accountAirtableId: user.account.airtableId,
    productAreas: user.account.productAreas || [],
  })
}

export const Datadog = {
  init,
  identifyUser,
}
