/* eslint-disable react/no-unstable-nested-components */
import { Tooltip } from "@stories/components/Antd"
import Typography, { Size } from "@stories/components/Typography/Typography"
import { LockClosedIcon } from "@stories/icons/LockClosedIcon"
import { assertUnreachable } from "common/utils/fp/Function"
import { ReactNode, useState } from "react"
import { classNames } from "src/utils/classNames"
import { useCompanyAccess } from "./CompanyAccessProvider"
import { GenericAccessControlWrapper } from "./GenericAccessControlWrapper"
import { UpgradeFeatureAccessProps } from "./UpgradeFeatureAccessProps"
import { UnlockCompanyPopover } from "./components/UnlockCompanyPopover"
import { StopClickPropagation } from "@stories/components/StopClickPropagation/StopClickPropagation"

type LockedTableCellProps = {
  size: "small" | "medium"
  iconOnly?: true
}

export const LockedTableCell = ({ size, iconOnly }: LockedTableCellProps) => (
  <div
    className={classNames("flex items-center cursor-pointer", size === "small" ? "gap-1" : "gap-2")}
  >
    <LockClosedIcon size={size} />
    {!iconOnly ? (
      <Typography text="Locked" size={size === "small" ? Size.Small : Size.Medium} />
    ) : null}
  </div>
)

type TableCellAccessControlWrapperProps = {
  children: ReactNode
  upgradeTooltipContent: (
    props: UpgradeFeatureAccessProps & { onCloseWrapper?: () => void }
  ) => ReactNode
}

export const TableCellAccessControlWrapper = ({
  featureName,
  targetAccessLevel,
  children,
  upgradeTooltipContent,
  size,
  iconOnly,
}: TableCellAccessControlWrapperProps & UpgradeFeatureAccessProps & LockedTableCellProps) => {
  const { isCompanyUnlocked } = useCompanyAccess()
  const [upgradeTooltipOpen, setUpgradeTooltipOpen] = useState<false | undefined>()

  return (
    <GenericAccessControlWrapper
      feature={featureName}
      component={(accessLevel) => {
        switch (accessLevel) {
          case "limited-company": {
            return isCompanyUnlocked ? (
              children
            ) : (
              <UnlockCompanyPopover featureName={featureName}>
                <LockedTableCell size={size} iconOnly={iconOnly} />
              </UnlockCompanyPopover>
            )
          }
          case "full": {
            return children
          }
          case "locked": {
            return (
              <StopClickPropagation>
                <Tooltip
                  title={upgradeTooltipContent({
                    featureName,
                    targetAccessLevel,
                    onCloseWrapper: () => setUpgradeTooltipOpen(false),
                  })}
                  placement="left"
                  open={upgradeTooltipOpen}
                >
                  <div>
                    <LockedTableCell size={size} iconOnly={iconOnly} />
                  </div>
                </Tooltip>
              </StopClickPropagation>
            )
          }
          case "none": {
            return null
          }
          default: {
            return assertUnreachable(accessLevel)
          }
        }
      }}
    />
  )
}
