import { CheckboxWithLabel } from "@components/inputs/checkbox/Checkbox"
import { DatePicker } from "@stories/components/Antd/DatePicker/DatePicker"
import { notification } from "@stories/components/Antd/notification"
import { Button } from "@stories/components/Button/Button"
import Typography, { Color, Weight } from "@stories/components/Typography/Typography"
import { AccountTradingRegistration } from "common/model/Account/AccountTradingRegistration"
import { viewUserIdFields } from "common/model/User"
import { formatDate } from "common/utils/dateUtils"
import moment from "moment"
import { ChangeEventHandler } from "react"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"

export const AdminTradingRegistrationStatusSelection = ({
  handleSave,
  registrationStatus,
}: {
  handleSave: (t: AccountTradingRegistration["status"]) => void
  registrationStatus: AccountTradingRegistration["status"]
}) => {
  const { user } = useLoggedInUser()
  const handleApproveSave = (approved: boolean) => {
    handleSave({
      ...registrationStatus,
      approved,
      approvedDate: approved ? new Date() : null,
      approvedBy: viewUserIdFields(user),
    })
  }
  const handleApproveClick: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (user.email === "javier@caplight.com" || !e.target.checked) {
      handleApproveSave(e.target.checked)
    } else {
      notification.warn({
        key: "approve-trading-registration",
        placement: "top",
        duration: 0,
        message: (
          <>
            <Typography
              text="Only Caplight Employees with their series 24 can approve users for the ATS. Do you have written approval?"
              color={Color.Danger}
              weight={Weight.Bold}
            />
            <div className="flex gap-4">
              <Button
                label="Cancel"
                onClick={() => notification.close("approve-trading-registration")}
                variant="secondary"
              />
              <Button
                label="Yes Change"
                onClick={() => {
                  handleApproveSave(true)
                  notification.close("approve-trading-registration")
                }}
              />
            </div>
          </>
        ),
      })
    }
  }

  return (
    <>
      <div className="flex gap-4">
        <CheckboxWithLabel
          id="trading-registration-submitted"
          title="Identification Submitted"
          onChange={(e) =>
            handleSave({
              ...registrationStatus,
              submittedIdentification: e.target.checked,
            })
          }
          checked={registrationStatus.submittedIdentification}
        />
        <DatePicker
          placeholder="Identification Submitted Date"
          value={
            registrationStatus.submittedIdentificationDate
              ? moment(registrationStatus.submittedIdentificationDate)
              : undefined
          }
          onChange={(date) =>
            handleSave({
              ...registrationStatus,
              submittedIdentificationDate: date?.toDate(),
            })
          }
        />
      </div>
      <div>
        <div className="flex gap-4">
          <CheckboxWithLabel
            id="trading-registration-submitted"
            title="Agreement Signed"
            onChange={(e) =>
              handleSave({
                ...registrationStatus,
                signedAgreement: e.target.checked,
              })
            }
            checked={registrationStatus.signedAgreement}
          />
          <DatePicker
            placeholder="Agreement Date"
            value={
              registrationStatus.signedAgreementDate
                ? moment(registrationStatus.signedAgreementDate)
                : undefined
            }
            onChange={(date) =>
              handleSave({
                ...registrationStatus,
                signedAgreementDate: date?.toDate(),
              })
            }
          />
        </div>

        {registrationStatus.offlineAgreementRequested ? (
          <Typography
            text={`Offline Agreement Requested ${
              registrationStatus.offlineAgreementRequestedDate
                ? formatDate(registrationStatus.offlineAgreementRequestedDate)
                : ""
            }`}
            weight={Weight.Bold}
          />
        ) : null}
      </div>

      <div className="flex gap-4">
        <CheckboxWithLabel
          id="trading-registration-completed"
          title="Approved"
          onChange={handleApproveClick}
          checked={registrationStatus.approved}
        />
        <DatePicker
          value={
            registrationStatus.approvedDate ? moment(registrationStatus.approvedDate) : undefined
          }
          onChange={(date) =>
            handleSave({
              ...registrationStatus,
              approvedDate: date?.toDate(),
            })
          }
        />
      </div>
    </>
  )
}
