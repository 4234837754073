import { User } from "common/model/User"
import { isOpportunityInboxUser, isOrdersUser } from "../components/auth/conditions/IsOrdersUser"
import { Routes } from "./Routes"
import { getFeatureAccessLevel } from "common/model/AccessControl/AccessControl"
import {
  AccessControlTier,
  getAccessControlForTier,
} from "common/model/AccessControl/AccessControlTier"
import { hasDealSearchAccess } from "common/model/DealSearch/hasDealSearchAccess"
import { FeatureFlagEnabledRecord } from "common/model/FeatureFlag/FeatureFlag"
import { isEmployeeAccount } from "common/model/Auth/Permissions"

export const userHomepage = (
  user: User,
  currentAccessTier: AccessControlTier | null,
  enabledFeatureFlags: FeatureFlagEnabledRecord | null
) => {
  if (isEmployeeAccount(user.account)) {
    return Routes.shareholders.portfolio
  }
  if (enabledFeatureFlags && hasDealSearchAccess({ account: user.account, enabledFeatureFlags })) {
    return Routes.dealSearch?.root
  }
  if (
    currentAccessTier &&
    getFeatureAccessLevel("liveMarketParticipation", getAccessControlForTier(currentAccessTier)) !==
      "none"
  )
    return isOpportunityInboxUser(user) ? Routes.opportunityInbox : Routes.orders.root
  if (
    currentAccessTier &&
    getFeatureAccessLevel("liveMarketParticipation", getAccessControlForTier(currentAccessTier)) ===
      "none"
  )
    return Routes.companies.root

  if (isOpportunityInboxUser(user)) return Routes.opportunityInbox
  if (isOrdersUser(user)) return Routes.orders.root
  if (user.addedToFullPlatform || user.account.productAreas.length) return Routes.companies.root
  return "/"
}
