export const RECEIVE_ALL_NON_HIDDEN_ORDERS = "RECEIVE_ALL_NON_HIDDEN_ORDERS"
export const RECEIVE_COMPS_TO_CHART = "RECEIVE_COMPS_TO_CHART"
export const SET_COMPARABLES_FILTER = "SET_COMPARABLES_FILTER"
export const ADD_COMPS_TO_CHART = "ADD_COMPS_TO_CHART"

export interface ChartSeriesMetadata {
  companyId: string
  companyName?: string
  ticker?: string | null
  hasMarketPrice: boolean
}

export const receiveAllNonHiddenOrders = (orders: unknown[]) => ({
  type: RECEIVE_ALL_NON_HIDDEN_ORDERS,
  orders,
})

export const receiveCompsToChart = (
  companyId: string,
  compsToChart: ChartSeriesMetadata[],
  compsAnalysisType: "fundamentals" | "volatility"
) => ({
  type: RECEIVE_COMPS_TO_CHART,
  companyId,
  compsToChart,
  compsAnalysisType,
})

export const appendCompsToChart = (
  companyId: string,
  compsToChart: ChartSeriesMetadata[],
  compsAnalysisType: "fundamentals" | "volatility"
) => ({
  type: ADD_COMPS_TO_CHART,
  companyId,
  compsToChart,
  compsAnalysisType,
})
