import { Maybe, nullableToMaybe } from "../containers/Maybe"
import { Account } from "./Account"
import { AccountClientType } from "./Account/AccountClientType"
import { User } from "./User"

export const entityTypes = [
  "Individual",
  "Corporation",
  "LLC",
  "LP or LLP",
  "Trust",
  "Pension Plan",
  "Registered Investment Advisor",
  "Exempt Investment Advisor",
  "Other",
] as const
export type EntityType = (typeof entityTypes)[number]

type IndividualAccreditationAttestation =
  | "netWorkMoreThan1M"
  | "incomeMoreThan200k"
  | "licensedByFINRA"

type TrustAccreditationAttestation =
  | "totalAssetsMoreThan5M"
  | "allLimitedPartnersAreAccredited"
  | "aumMoreThan5M"
  | "revocableTrust"
  | "registeredWithSEC"
  | "registeredWithState"
  | "exemptFromRegistration"

export type AccreditationAttestation =
  | IndividualAccreditationAttestation
  | TrustAccreditationAttestation

export const AccreditationStandardizedEntityTypes = [
  "individual",
  "trust",
  "ria",
  "eia",
  "other",
] as const

export type AccreditationInfo = {
  accreditationEntityType: EntityType
  accreditationStatements: AccreditationAttestation[]
  createdAt: Date
  filledBy: User["airtableId"]
}

export const buildAccreditationInfo = ({
  user,
  accreditationEntityType,
  accreditationStatements,
}: { user: Pick<User, "airtableId"> } & Pick<
  AccreditationInfo,
  "accreditationEntityType" | "accreditationStatements"
>): AccreditationInfo => ({
  accreditationEntityType,
  accreditationStatements,
  createdAt: new Date(),
  filledBy: user.airtableId,
})

export type AccountAccreditationFields = {
  accreditationInfos?: AccreditationInfo[]
}

export const isAccreditedAccount = (account: Account): Maybe<boolean> => {
  const waivedAccreditationClientTypes: AccountClientType[] = [
    "Intermediary",
    "Journalist",
    "Lender",
  ]
  return nullableToMaybe(
    waivedAccreditationClientTypes.some((clientType) => account.clientType.includes(clientType)) ||
      account.accreditationInfos?.some((info) => info.accreditationStatements.length > 0)
  )
}
