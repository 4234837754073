import { AccessControl } from "../AccessControl"

export const basicAccessControl: AccessControl<"basic"> = {
  tier: "basic",
  features: {
    // live market
    liveMarketParticipation: "full",
    orderHistoryData: "limited-company",
    currentMarketPriceData: "limited-company",
    contributeTradeData: "full",
    companyNewsData: "full",
    fundingRoundsPage: "full",
    fundingRoundsData: "limited-company",
    marketInsightsPage: "none",
    rfqData: "full",

    // company page
    companyPageFundingRoundsTable: "limited-company",
    companyPage409aValuationsTable: "none",
    companyPageFilingsTable: "none",
    companyPageInvestorsTable: "limited-company",
    companyPageMutualFundMarksTable: "limited-company",
    companyPageBidOfferVolumeRatioChart: "limited-company",
    companyPageTradingContextSection: "limited-company",
    companyPageResearchReportsSection: "limited-company",
    companyPageComparablesSection: "limited-company",
    companyPageShare: "full",
    companyPageFlagIssue: "full",
    companyPageExport: "none",

    // trade history
    tradeHistoryPage: "full",
    tradeHistoryPricing: "limited-company",
    tradeHistoryDates: "full",
    tradeHistoryStructure: "full",
    tradeHistoryShareClass: "full",

    // sectors page
    sectorsData: "none",
  },
}
