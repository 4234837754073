import * as React from "react"
import CapLoader from "../lib/CapLoader"

interface SpinnerProps {
  size: "xxs" | "xs" | "sm" | "md" | "lg"
  fullPage?: boolean
}

const Spinner: React.FunctionComponent<React.PropsWithChildren<SpinnerProps>> = ({
  size,
  fullPage,
}) => {
  const pxSize = { xxs: 12, xs: 20, sm: 50, md: 80, lg: 100 }[size] || 50
  const loader = <CapLoader height={pxSize} width={pxSize} />
  if (fullPage) {
    return (
      <div data-test-id="spinner" className="flex h-screen w-screen justify-center items-center">
        {loader}
      </div>
    )
  }
  return (
    <div data-test-id="spinner" className="flex justify-center items-center">
      {loader}
    </div>
  )
}

export default Spinner
