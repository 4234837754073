export type SourcePosition = {
  char: number
  line: number
}
export const compareSourcePositions = (l: SourcePosition, r: SourcePosition) => {
  if (r.line > l.line || (r.line === l.line && r.char > l.char)) {
    return ">"
  }
  if (r.line === l.line && r.char === l.char) {
    return "="
  }
  return "<"
}
export const stepSourcePositionByChar = (char: string, pos: SourcePosition) =>
  char === "\n" ? { line: pos.line + 1, char: 1 } : { line: pos.line, char: pos.char + 1 }
