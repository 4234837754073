import { v4 as uuid } from "uuid"
import { UserIdFields } from "../User"
import { UserCreatable } from "../UserCreatable"
import { SubmittedDocument } from "../files/DocumentSubmission"
import { FirebaseCommon } from "../../firestore/Interface"
import { collections } from "../../firestore/Collections"
import { firestoreConverter } from "../FirestoreConverter"

export type DealCRMImportJob = {
  id: string
  accountId: string
  status: "draft" | "processing" | "completed" | "failed"
  errorReason?: string
  rawFilePath: string
  tag: "contact-import-job" // possibly extend
  contactType: "individual" | "firm" | "joint"
} & UserCreatable &
  DealCRMContactImportJob

type DealCRMContactImportJob = {
  tag: "contact-import-job"
  firmsCount: number
  newFirmsImportedCount: number
  individualsCount: number
  newIndividualsImportedCount: number
}

const makeCRMImportJob = (
  accountId: string,
  user: UserIdFields,
  rawFilePath: string,
  contactType: DealCRMImportJob["contactType"] | "joint",
  importCounts: {
    firmsCount: number
    newFirmsImportedCount: number
    individualsCount: number
    newIndividualsImportedCount: number
  }
): DealCRMImportJob => ({
  id: uuid(),
  accountId,
  createdAt: new Date(),
  createdBy: user,
  ...importCounts,
  rawFilePath,
  contactType,
  tag: "contact-import-job",
  status: "draft",
})

/** frontend */
export const startCRMImportJob = async (
  db: FirebaseCommon.DB,
  accountId: string,
  user: UserIdFields,
  document: SubmittedDocument,
  contactType: "individual" | "firm" | "joint",
  importCounts: {
    firmsCount: number
    newFirmsImportedCount: number
    individualsCount: number
    newIndividualsImportedCount: number
  }
) => {
  if (document.filePath === undefined) {
    throw new Error("Document has no file path")
  }
  const importJob: DealCRMImportJob = {
    ...makeCRMImportJob(accountId, user, document.filePath, contactType, importCounts),
    status: "processing",
  }
  return db
    .collection(collections.dealCRM.importJobs)
    .withConverter<DealCRMImportJob>(firestoreConverter<DealCRMImportJob>())
    .doc(importJob.id)
    .set(importJob)
    .then(() => importJob)
}
