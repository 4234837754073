import { InformationCircleIcon as OutlinedIcon } from "@heroicons/react/outline"
import { InformationCircleIcon as SolidIcon } from "@heroicons/react/solid"
import { Tooltip, TooltipProps } from "@stories/components/Antd"
import { ReactElement } from "react"
import { classNames } from "../utils/classNames"

type InfoTooltipProps = {
  text: string | ReactElement
  className?: string
  backgroundColor?: string
  placement?: TooltipProps["placement"]
} & (
  | {
      icon?: JSX.Element
      light?: never
      outlined?: never
    }
  | {
      light?: boolean
      outlined?: boolean
      icon?: never
    }
)

const InfoTooltip: React.FunctionComponent<React.PropsWithChildren<InfoTooltipProps>> = ({
  light,
  backgroundColor,
  icon,
  outlined,
  text,
  placement,
}) => {
  const iconClasses = classNames(
    "inline w-4 h-4 ml-2",
    light ? "text-neutral-100" : "text-neutral-1000"
  )
  return (
    <Tooltip
      overlayStyle={{
        backgroundColor: backgroundColor || "rgba(255,255,255,1)",
        maxWidth: "350px",
      }}
      overlayClassName="bg-opacity-100"
      title={typeof text === "string" ? <div className="p-2">{text}</div> : text}
      placement={placement}
    >
      {icon ||
        (outlined ? (
          <OutlinedIcon className={iconClasses} />
        ) : (
          <SolidIcon className={iconClasses} />
        ))}
    </Tooltip>
  )
}

export default InfoTooltip
