import _ from "lodash"
import { Interval } from "../../../../utils/data/Interval"
import { qToNumber } from "../../../../utils/data/numeric/Rational"
import { Boxed } from "../../../../utils/fp/Boxed"
import { Identity } from "../../../../containers/Identity"
import { assertUnreachable } from "../../../../utils/fp/Function"
import { Just, Maybe, Nothing, nullableToMaybe } from "../../../../containers/Maybe"
import { memoize } from "../../../../utils/memo"
import { Company } from "../../../Company/Wrapped"
import {
  TaggedStructureOrderTerms,
  orderQuantityTermsPrice,
  orderQuantityTermsTargetValuation,
  orderQuantityTermsShares,
  orderQuantityTermsVolume,
  StructureOrderTerms,
  orderQuantityTermsPriceInterval,
} from "../../Types/Terms"
import { AtomicOrderUpdate } from "../UpdateLog"
import { Either, Left, Right } from "../../../../containers/Either"
import { SplitDisplayPriceData } from "../../../../queries/pricing/PricingEnrichment"
import { annotate } from "../../../../utils/Coerce"
import { OrderQuantityTerms } from "../../Types/Terms/Quantity"

export class OrderTermUpdate extends Boxed<{
  readonly update: AtomicOrderUpdate
  readonly company: Maybe<Company>
}> {
  structureType() {
    return this.unboxed.update.structure
  }

  eq(u: OrderTermUpdate) {
    const thisUpdate = this.unboxed.update
    const otherUpdate = u.unboxed.update

    const equivalentPrices = this.impliedPricePerShare()
      .alongside(u.impliedPricePerShare())
      .map(([l, r]) => Math.abs(l - r) < 0.001)
      .withDefault(false)

    return (
      _.isEqual(thisUpdate.structure, otherUpdate.structure) &&
      _.isEqual(thisUpdate.updateDate, otherUpdate.updateDate) &&
      _.isEqual(thisUpdate.structureTerms, otherUpdate.structureTerms) &&
      equivalentPrices &&
      _.isEqual(
        {
          ...thisUpdate.quantityTerms,
          USDPerShare: undefined,
          targetValuation: undefined,
          id: "",
        } satisfies OrderQuantityTerms,
        {
          ...otherUpdate.quantityTerms,
          USDPerShare: undefined,
          targetValuation: undefined,
          id: "",
        } satisfies OrderQuantityTerms
      )
    )
  }

  structureTerms(): TaggedStructureOrderTerms {
    switch (this.unboxed.update.structure) {
      case "call_option":
        return { tag: this.unboxed.update.structure, ...this.unboxed.update.structureTerms }
      case "direct":
        return { tag: this.unboxed.update.structure, ...this.unboxed.update.structureTerms }
      case "forward":
        return { tag: this.unboxed.update.structure, ...this.unboxed.update.structureTerms }
      case "put_option":
        return { tag: this.unboxed.update.structure, ...this.unboxed.update.structureTerms }
      case "spv":
        return { tag: this.unboxed.update.structure, ...this.unboxed.update.structureTerms }
      case "structure_agnostic_regular_way":
        return { tag: this.unboxed.update.structure, ...this.unboxed.update.structureTerms }
      case "swap":
        return { tag: this.unboxed.update.structure, ...this.unboxed.update.structureTerms }
      case "unknown":
        return { tag: this.unboxed.update.structure, ...this.unboxed.update.structureTerms } // rip
      case "variable_prepaid_forward":
        return { tag: this.unboxed.update.structure, ...this.unboxed.update.structureTerms }
      case "collective_liquidity_exchange_fund":
        return { tag: this.unboxed.update.structure, ...this.unboxed.update.structureTerms }
      default:
        return assertUnreachable(this.unboxed.update)
    }
  }

  carry() {
    return Identity.pure(this.structureTerms()).map((x) =>
      x.tag === "spv" ? Just(x.carry) : Nothing
    ).value
  }

  managementFee() {
    return Identity.pure(this.structureTerms()).map((x) =>
      x.tag === "spv" ? Just(x.managementFee) : Nothing
    ).value
  }

  date() {
    return this.unboxed.update.updateDate
  }

  cumulativeStockSplits(): SplitDisplayPriceData[] {
    return this.unboxed.company
      .map((c) => c.cumulativeStockSplits())
      .withDefault([])
      .filter((s) => s.splitDate > this.date())
      .map((s) => ({
        ...s,
        pricePerShare:
          this.impliedPricePerShare().withDefault(0) / qToNumber(s.cumulativeSplitRatio),
      }))
  }

  cumulativeSplitRatio() {
    return this.unboxed.company
      .map((c) => c.cumulativeSplitRatio(this.date(), new Date()))
      .withDefault(1)
  }

  /** Returns average price */
  pricePerShare() {
    return this.hasSimplePrice()
      ? nullableToMaybe(orderQuantityTermsPrice(this.unboxed.update.quantityTerms)).map(
          (p) => p / this.cumulativeSplitRatio()
        )
      : Just(
          (this.unboxed.update.structureTerms as StructureOrderTerms["variable_prepaid_forward"])
            .USDPerShareForMinimumSharesDelivered
        )
  }

  pricePerShareInterval() {
    return this.checkPriceStructure().match(
      (t) =>
        annotate<Maybe<Interval<number>>>(
          nullableToMaybe(orderQuantityTermsPriceInterval(t.unboxed.update.quantityTerms)).map(
            (p) => Interval.map((x: number) => x / this.cumulativeSplitRatio())(p)
          )
        ),
      (x) => Just(Interval.pure(x.USDPerShareForMinimumSharesDelivered))
    )
  }

  impliedPricePerShare() {
    return this.pricePerShare().or(
      this.targetValuation().bind((targetValuation) =>
        this.unboxed.company.bind((c) =>
          c
            .splitPricePerShareFromValuation(
              { valuation: targetValuation, valuationDate: this.date() },
              new Date()
            )
            .map(({ pricePerShare }) => pricePerShare)
        )
      )
    )
  }

  targetValuation() {
    return orderQuantityTermsTargetValuation(this.unboxed.update.quantityTerms)
  }

  impliedValuation = memoize(() =>
    this.targetValuation().or(
      this.pricePerShare()
        .bind((pps) =>
          this.unboxed.company.bind((c) =>
            c.valuationFromPricePerShareInformation({
              isSplitAdjusted: true,
              price: pps,
              priceDate: this.date(),
              splitAdjustedForDate: new Date(),
            })
          )
        )
        .map((v) => v.valuation)
    )
  )

  shareCount() {
    return nullableToMaybe(orderQuantityTermsShares(this.unboxed.update.quantityTerms)).map(
      Interval.map((x) => x * this.cumulativeSplitRatio())
    )
  }

  rawPrice() {
    return this.hasSimplePrice()
      ? this.targetValuation().map(Left).or(this.impliedPricePerShare().map(Right))
      : Just(
          Right(
            (this.unboxed.update.structureTerms as StructureOrderTerms["variable_prepaid_forward"])
              .USDPerShareForMinimumSharesDelivered
          )
        )
  }

  amountUSD() {
    return nullableToMaybe(orderQuantityTermsVolume(this.unboxed.update.quantityTerms))
  }

  hasSimplePrice() {
    return !(this.structureType() === "variable_prepaid_forward")
  }

  checkPriceStructure(): Either<typeof this, StructureOrderTerms["variable_prepaid_forward"]> {
    if (this.structureType() === "variable_prepaid_forward")
      return Right(
        this.unboxed.update.structureTerms as StructureOrderTerms["variable_prepaid_forward"]
      )
    return Left(this)
  }

  isEqualPriceTo(otherUpdate: OrderTermUpdate): boolean {
    return this.rawPrice()
      .bind<boolean>((thisPrice) =>
        thisPrice.match(
          (thisTargetValuation) =>
            otherUpdate
              .targetValuation()
              .map(
                (otherUpdateTargetValuation) => otherUpdateTargetValuation === thisTargetValuation
              ),
          (thisPricePerShare) =>
            otherUpdate
              .pricePerShare()
              .map((otherUpdatePricePerShare) => otherUpdatePricePerShare === thisPricePerShare)
        )
      )
      .withDefault(false)
  }
}

/** Primarily used for exporting functionality to differentiate between price updates by structures */
export const serializedStructureTerm = (structure: TaggedStructureOrderTerms): string => {
  switch (structure.tag) {
    case "call_option":
      return `${structure.tag}_${structure.style}_${structure.expiration.valueOf()}_${
        structure.strike
      }`
    case "direct":
      return `${structure.tag}`
    case "forward":
      return `${structure.tag}`
    case "put_option":
      return `${structure.tag}_${structure.style}_${structure.expiration.valueOf()}_${
        structure.strike
      }`
    case "spv":
      return `${structure.tag}_${structure.carry ?? "NA"}_${structure.managementFee ?? "NA"}`
    case "structure_agnostic_regular_way":
      return `${structure.tag}`
    case "swap":
      return `${structure.tag}`
    case "unknown":
      return `${structure.tag}` // rip
    case "variable_prepaid_forward":
      return `${structure.tag}_${structure.USDPerShareForMaximumSharesDelivered}_${structure.USDPerShareForMinimumSharesDelivered}`
    case "collective_liquidity_exchange_fund":
      return "exchange_fund"
    default:
      return assertUnreachable(structure)
  }
}
