import { assertUnreachable } from "../../utils/fp/Function"
import { UpgradePathTier } from "./AccessControlTier"
import { accessControlTierToCompanyAccessCount } from "./CompanyAccess"

export const displayAccessControlTierSeatCount = (upgradeTier: UpgradePathTier) =>
  ((
    {
      basic: 1,
      essential: 1,
      pro: 4,
      elite: "unlimited",
    } as const satisfies Record<UpgradePathTier, number | "unlimited">
  )[upgradeTier])

export const displayAccessControlUpgradeFeatures = (
  upgradeTier: UpgradePathTier
): { name: string; description: string }[] => {
  switch (upgradeTier) {
    case "basic": {
      return [
        {
          name: "View Company Data",
          description: `Unlock ${accessControlTierToCompanyAccessCount.basic} companies per month`,
        },
        { name: "Live Market Access", description: "Access live market" },
      ]
    }
    case "essential": {
      return [
        {
          name: "Increased Company Unlocks",
          description: `Unlock ${accessControlTierToCompanyAccessCount.essential} companies per month`,
        },
        { name: "Sector Analysis", description: "View sector data" },
        { name: "Market Insights", description: "Insights on Caplight's market" },
      ]
    }
    case "pro": {
      return [
        {
          name: "Increased Company Unlocks",
          description: `Unlock ${accessControlTierToCompanyAccessCount.pro} companies per month`,
        },
        { name: "COI & Reg D Filings", description: "View company COI and Reg D filings" },
        { name: "409a Data", description: "View company 409a data" },
        {
          name: "Custom Reports",
          description: "Export reports on your watchlist companies",
        },
      ]
    }
    case "elite": {
      return [
        { name: "Unlimited Company Access", description: "View data for every company" },
        {
          name: "Export Company Data",
          description: "Export company data to CSV",
        },
        {
          name: "Dedicated Account Rep",
          description: "Personalized reporting and support",
        },
        {
          name: "Custom Feature Development",
          description: "Customized features built upon request by our engineering team",
        },
      ]
    }
    default: {
      return assertUnreachable(upgradeTier)
    }
  }
}

export const displayAccessControlTierPrice = (upgradeTier: UpgradePathTier) =>
  ((
    {
      basic: 100,
      essential: 250,
      pro: 600,
      elite: "custom",
    } as const satisfies Record<UpgradePathTier, number | "custom">
  )[upgradeTier])
