import { ConnectionRequestReceivedEvent } from "common/model/AccountActivityLog/AccountActivityLog"
import { titleCase } from "common/utils/StringUtils"
import { formatAbbreviatedCurrency, formatPrice } from "common/utils/math/format"
import React from "react"
import { DealTerm } from "./DealTerm"

interface ConnectRequestReceivedDetailsProps {
  event: ConnectionRequestReceivedEvent
}

export const ConnectRequestReceivedDetails: React.FC<ConnectRequestReceivedDetailsProps> = ({
  event,
}) => {
  const { introRequest } = event
  const { anchorTradeOrOrder } = introRequest
  const { price, structure, volumeUSD } = anchorTradeOrOrder

  return (
    <div className="flex gap-4 items-center">
      <div className="flex gap-4 justify-between">
        <DealTerm
          label="Target Price"
          value={price && price.USDPerShare ? formatPrice(price.USDPerShare.lowerBound) : "-"}
        />
        <DealTerm
          label="Target Size"
          value={volumeUSD ? formatAbbreviatedCurrency(volumeUSD) : ""}
        />
        <DealTerm label="Structure" value={structure ? titleCase(structure) : "-"} />
      </div>
    </div>
  )
}
