import { createContext } from "react"
import { handleConsoleError } from "../../utils/Tracking"

export interface ErrorNotification {
  key: string
  message: string
}

export type ErrorHandler = (error: Error, severity?: "low" | "high", message?: string) => void

interface ContextType {
  handleError: ErrorHandler
  notifications: ErrorNotification[]
}

export default createContext<ContextType>({
  handleError: (error: Error, severity: "low" | "high" = "low", message?: string) => {
    handleConsoleError(
      "You are attempting to log an error outside the scope of an ErrorHandlerProvider"
    )
  },
  notifications: [],
})
