import { DealCRMInterest, isVisibleCRMInterest } from "common/model/DealCRM/DealCRMInterest"
import { Loading, isLoaded, mapLoading } from "common/utils/Loading"
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react"
import { useFirebaseReader } from "src/firebase/Context"
import { getAllDocs } from "src/firebase/Firebase/utils"
import {
  getAccountBuySellInterest,
  getMostRecentUpdatedAccountBuySellInterest,
} from "../../../firebase/crm"
import { useCurrentUser } from "../../../providers/currentUser/useCurrentUser"
import { handleConsoleError } from "src/utils/Tracking"

type BuySellInterestProviderState = Loading<DealCRMInterest[]>

export const BuySellInterestContext = createContext<BuySellInterestProviderState | undefined>(
  undefined
)

export const BuySellInterestProvider = ({ children }: { children: ReactNode }) => {
  const firebase = useFirebaseReader()
  const user = useCurrentUser()

  const [buySellInterest, setBuySellInterest] = useState<BuySellInterestProviderState>("loading")

  useEffect(() => {
    if (isLoaded(user)) {
      getAccountBuySellInterest({
        db: firebase,
        accountId: user.user.account.id,
      })
        .get()
        .then(getAllDocs)
        .then((docs) => docs.sort((a, b) => b.lastUpdatedAt.getTime() - a.lastUpdatedAt.getTime()))
        .then(setBuySellInterest)
        .catch(handleConsoleError)
    }
  }, [firebase, user])

  useEffect(() => {
    if (isLoaded(user)) {
      const unsubscribe = getMostRecentUpdatedAccountBuySellInterest({
        db: firebase,
        accountId: user.user.account.id,
      }).onSnapshot((snapshot) => {
        const newOrUpdatedDoc = snapshot.docs.map((doc) => doc.data())[0]
        if (newOrUpdatedDoc) {
          handleNewOrUpdatedInterest(newOrUpdatedDoc)
        }
      })
      return unsubscribe
    } else {
      return () => {}
    }
  }, [firebase, user])

  const handleNewOrUpdatedInterest = (interest: DealCRMInterest) => {
    setBuySellInterest((prevState) => {
      if (!isLoaded(prevState)) {
        return prevState
      } else {
        let newInterestList = [interest]
        const matchingIndex = prevState.findIndex((i) => i.id === interest.id)
        if (matchingIndex !== -1) {
          newInterestList = newInterestList
            .concat(prevState.slice(0, matchingIndex))
            .concat(prevState.slice(matchingIndex + 1))
        } else {
          newInterestList = newInterestList.concat(prevState)
        }
        return newInterestList
      }
    })
  }

  const visibleBuySellInterest = useMemo(
    () =>
      mapLoading<DealCRMInterest[], DealCRMInterest[]>((interests) =>
        interests.filter(isVisibleCRMInterest)
      )(buySellInterest),
    [buySellInterest]
  )

  return (
    <BuySellInterestContext.Provider value={visibleBuySellInterest}>
      {children}
    </BuySellInterestContext.Provider>
  )
}

export const useCRMBuySellInterest = (): BuySellInterestProviderState => {
  const context = useContext(BuySellInterestContext)

  if (!context) {
    throw new Error("useCRMBuySellInterest must be wrapped in a <BuySellInterestProvider />")
  }

  return context
}
