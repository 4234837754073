import Metric, { MetricProps } from "../../../../stories/components/Metric/Metric"
import OrderPageSidebarCard from "../components/OrderPageSidebarCard"

const OrderScoreCardMetric = ({ label, value }: Omit<MetricProps, "alignment">) => (
  <OrderPageSidebarCard>
    <Metric label={label} value={value} alignment="left" />
  </OrderPageSidebarCard>
)

export default OrderScoreCardMetric
