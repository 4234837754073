export type ResponseStatus = number // todo

export interface IResponse {
  text: () => Promise<string>
  json: () => Promise<unknown>
  blob: () => Promise<Blob>
  ok: boolean
  status: ResponseStatus
}

export class ReusableResponse implements IResponse {
  response: Response

  get ok() {
    return this.response.ok
  }

  get status() {
    return this.response.status
  }

  constructor(response: Response) {
    this.response = response
  }

  private usingClone = <T>(f: (r: Response) => T): T => {
    const newResponse = this.response.clone()
    const result = f(this.response)
    this.response = newResponse
    return result
  }

  text = () => this.usingClone((r) => r.text())

  json = () => this.usingClone((r) => r.json())

  blob = () => this.usingClone((r) => r.blob())
}
