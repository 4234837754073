import _ from "lodash"
import { Either } from "common/containers/Either"
import { Nonempty, Nonempty_ } from "common/utils/data/Array/Nonempty"
import {
  nonEmptyArrayValidator,
  numberValidator,
  stringValidator,
  Validator,
} from "common/utils/Validator"
import { deprecatedIsLoaded, isLoading } from "common/utils/Loading"
import { POSTGRES_QUERIES } from "common/utils/postgres"
import { Company, CompanyWithPostgres } from "common/model/Company"
import {
  CompanyMarketHistorySummary,
  CompanyMarketHistorySummaryKeysToDays,
} from "common/model/CompanyMarketHistorySummary"
import { isDefined } from "common/utils/TypeUtils"
import {
  CaplightPriceEstimateFromPostgresRaw,
  validateCaplightPriceEstimateFromPostgresRaw,
  parseOneRowCaplightPriceEstimateFromPostgres,
  validateCaplightQualityScore,
} from "common/model/postgres/PostgresCaplightPriceEstimate"
import { annotate } from "common/utils/Coerce"
import {
  isEmptyArrayDataRequest,
  IsEmptyArrayDataRequest,
  validateIsEmptyArrayDataRequest,
} from "common/utils/ArrayDataRequest"
import { companySummaryToPriceEstimateSummary } from "common/model/CompanyPriceEstimateSummary"
import { useAppDispatch } from "../reduxHooks"
import { GetState } from "../store"
import { CurrentUser } from "../../model/CurrentUser"
import { APIEndpoints, runAPIQuery } from "../../firebase/API"
import { setLoading } from "../actions/appStates"
import { handleConsoleError } from "../../utils/Tracking"
import * as actions from "../actions/postgresData"
import {
  PostgresComparableCompany,
  IVolatilityDataForCompanyAndComps,
  CompanyIds,
  CurrentCompsSummary,
} from "../model/postgresData"
import { FirebaseReader } from "../../firebase/Firebase"
import { config } from "../../config"
import {
  EitherAuthlessValidationOrCurrentUser,
  runPostgresDbQuery,
} from "../../firebase/PostgresDBQuery"

/*
  HELPER FUNCTIONS
*/

export interface PostgresRequestOptions {
  forceRefresh: boolean
}

export const filterCompanyIds = (
  loadedCompanyIds: string[],
  requestedCompanyIds: string | string[],
  forceRefresh: boolean
) => {
  const isArr = Array.isArray(requestedCompanyIds)
  let companyIdsToRequest: string[] = []
  if (isArr && !forceRefresh) {
    companyIdsToRequest = requestedCompanyIds.filter((id) => !loadedCompanyIds.includes(id))
  } else if (!isArr && !forceRefresh) {
    companyIdsToRequest = [requestedCompanyIds].filter((id) => !loadedCompanyIds.includes(id))
  } else if (isArr && forceRefresh) {
    companyIdsToRequest = requestedCompanyIds
  } else if (!isArr && forceRefresh) {
    companyIdsToRequest = [requestedCompanyIds]
  }
  return companyIdsToRequest
}

// TODO fix
export const parseVolatilityForCompanyAndComps = (row: { [key in string]?: string }) => {
  const keys = Object.keys(row)
  const out: Partial<IVolatilityDataForCompanyAndComps> = {}
  keys.forEach((key) => {
    if (key === "date") {
      out[key] = row[key]
    } else if (key === "date_timestamp") {
      out[key] = parseInt(row[key] || "", 10)
    } else if (!Number.isNaN(parseFloat(row[key] || ""))) {
      out[key] = parseFloat(row[key] || "") as unknown as string // TODO fix, I have no idea what the intended behavior is here
    }
  })
  return out as IVolatilityDataForCompanyAndComps // TODO fix this
}

export const getMarketPriceCompanies =
  (firebase: FirebaseReader, options?: PostgresRequestOptions) =>
  async (dispatch: ReturnType<typeof useAppDispatch>, getState: GetState) => {
    const { marketPriceCompaniesRequestStatus } = getState().postgresData
    if (!marketPriceCompaniesRequestStatus || !!options?.forceRefresh) {
      dispatch(actions.marketPriceCompaniesRequested("loading"))
      firebase
        .getMarketPriceEnabledCompanies()
        .get()
        .then((querySnapshot) => {
          dispatch(actions.receiveTargetIssuers(querySnapshot.docs.map((doc) => doc.data())))
          dispatch(actions.marketPriceCompaniesRequested(true))
        })
        .catch((e) => {
          handleConsoleError(e)
          dispatch(actions.marketPriceCompaniesRequested(true))
        })
    }
  }

export const getCaplightDataCompanies =
  (firebase: FirebaseReader, options?: PostgresRequestOptions) =>
  async (dispatch: ReturnType<typeof useAppDispatch>, getState: GetState) => {
    const { caplightDataCompaniesRequestStatus } = getState().postgresData
    if (!caplightDataCompaniesRequestStatus || !!options?.forceRefresh) {
      dispatch(actions.caplightDataCompaniesRequested("loading"))
      firebase
        .getCaplightDataCompanies()
        .get()
        .then((querySnapshot) => {
          dispatch(actions.receiveTargetIssuers(querySnapshot.docs.map((doc) => doc.data())))
          dispatch(actions.caplightDataCompaniesRequested(true))
        })
        .catch((e) => {
          handleConsoleError(e)
          dispatch(actions.caplightDataCompaniesRequested(true))
        })
    }
  }

/** @deprecated */
export const getTargetIssuers =
  (firebase: FirebaseReader, options?: PostgresRequestOptions) =>
  async (dispatch: ReturnType<typeof useAppDispatch>, getState: GetState) => {
    const { targetIssuers, targetIssuersWereRequested } = getState().postgresData
    if ((!targetIssuersWereRequested && !isLoading(targetIssuers)) || !!options?.forceRefresh) {
      dispatch(actions.receiveTargetIssuers("loading"))
      firebase
        .getCompaniesWithActiveMarket()
        .get()
        .then((querySnapshot) => {
          dispatch(actions.receiveTargetIssuers(querySnapshot.docs.map((doc) => doc.data())))
        })
        .catch((e) => handleConsoleError(e))
    }
  }

export const getOneTargetIssuer =
  (firebase: FirebaseReader, ids: CompanyIds) =>
  async (dispatch: ReturnType<typeof useAppDispatch>) => {
    if (ids.postgresId !== undefined)
      dispatch(addToLocalTargetIssuersByPostgresId(firebase, ids.postgresId)).catch(
        handleConsoleError
      )
    else if (ids.airtableId !== undefined)
      dispatch(addToLocalTargetIssuersByAirtableId(firebase, ids.airtableId)).catch(
        handleConsoleError
      )
    else if (ids.firebaseId !== undefined)
      dispatch(addToLocalTargetIssuersByFirebaseId(firebase, ids.firebaseId)).catch(
        handleConsoleError
      )
  }

export const addToLocalTargetIssuersByPostgresId =
  (firebase: FirebaseReader, postgresCompanyId: string, options?: PostgresRequestOptions) =>
  async (dispatch: ReturnType<typeof useAppDispatch>, getState: GetState) => {
    const { targetIssuers, loadingTargetIssuers, caplightDataCompaniesRequestStatus } =
      getState().postgresData
    const isLoadingIssuer = !!loadingTargetIssuers.find(
      (company) => company.postgresId === postgresCompanyId
    )
    const notReady = targetIssuers === "loading"
    if (
      (!isLoading(caplightDataCompaniesRequestStatus) &&
        !isLoadingIssuer &&
        !notReady &&
        !(
          deprecatedIsLoaded(targetIssuers) &&
          !!targetIssuers.find(companyIdsCheck({ postgresId: postgresCompanyId }))
        )) ||
      !!options?.forceRefresh
    ) {
      dispatch(actions.makeIssuerLoading({ postgresId: postgresCompanyId }))
      firebase
        .getCompanyByPostgresId(postgresCompanyId)
        .then((querySnapshot) => {
          if (querySnapshot.length) {
            dispatch(handleQuerySnapshotNewTargetIssuer(querySnapshot))
          } else {
            handleConsoleError(
              `Could not find company with postgresId ${postgresCompanyId} in firebase`
            )
          }
        })
        .catch((e) => handleConsoleError(e))
    }
  }

export const addToLocalTargetIssuersByAirtableId =
  (firebase: FirebaseReader, airtableId: string, options?: PostgresRequestOptions) =>
  async (dispatch: ReturnType<typeof useAppDispatch>, getState: GetState) => {
    const { targetIssuers, loadingTargetIssuers } = getState().postgresData
    const isLoadingIssuers = loadingTargetIssuers.find(
      (company) => company.airtableId === airtableId
    )
    const notReady = targetIssuers === "loading"
    if (
      (!notReady &&
        !isLoadingIssuers &&
        !(
          deprecatedIsLoaded(targetIssuers) && targetIssuers.find(companyIdsCheck({ airtableId }))
        )) ||
      !!options?.forceRefresh
    ) {
      dispatch(actions.makeIssuerLoading({ airtableId }))
      firebase
        .getCompanyByAirtableId(airtableId)
        .then((querySnapshot) => {
          if (querySnapshot.length) {
            dispatch(handleQuerySnapshotNewTargetIssuer(querySnapshot))
          } else {
            handleConsoleError(`Could not find company with airtableId ${airtableId} in firebase`)
          }
        })
        .catch((e) => handleConsoleError(e))
    }
  }

export const addToLocalTargetIssuersByFirebaseId =
  (firebase: FirebaseReader, firebaseId: string, options?: PostgresRequestOptions) =>
  async (dispatch: ReturnType<typeof useAppDispatch>, getState: GetState) => {
    const { targetIssuers, loadingTargetIssuers } = getState().postgresData
    const isLoadingIssuers = loadingTargetIssuers.find(
      (company) => company.airtableId === firebaseId
    )
    const notReady = targetIssuers === "loading"
    if (
      (!notReady &&
        !isLoadingIssuers &&
        !(
          deprecatedIsLoaded(targetIssuers) && targetIssuers.find(companyIdsCheck({ firebaseId }))
        )) ||
      !!options?.forceRefresh
    ) {
      dispatch(actions.makeIssuerLoading({ firebaseId }))
      firebase
        .getCompany(firebaseId)
        .then((result) => {
          const company = result.data()
          if (company) dispatch(handleQuerySnapshotNewTargetIssuer([company]))
        })
        .catch((e) => handleConsoleError(e))
    }
  }

const handleQuerySnapshotNewTargetIssuer =
  (companies: Company[]) => (dispatch: ReturnType<typeof useAppDispatch>) => {
    const issuers: Company[] = _.orderBy(companies, ["name"])
    if (issuers.length > 0) {
      dispatch(actions.addToLocalTargetIssuers(issuers[0]))
      dispatch(
        actions.removeIssuerFromLoading({
          airtableId: issuers[0].airtableId,
          firebaseId: issuers[0].id,
          postgresId: issuers[0].postgresCompanyId,
        })
      )
    }
  }

export const getCurrentCompsTabularSummaryData =
  (eitherAuthlessOrCurrentUser: EitherAuthlessValidationOrCurrentUser, postgresIds: string[]) =>
  async (dispatch: ReturnType<typeof useAppDispatch>, getState: GetState) => {
    const { currentCompsSummaryData } = getState().postgresData
    const existingIds = currentCompsSummaryData.map(
      (row: CurrentCompsSummary) => row.postgresCompanyId
    )
    const companyIdsToRequest: string[] = filterCompanyIds(existingIds, postgresIds, false)
    if (companyIdsToRequest.length) {
      dispatch(
        actions.setCurrentCompsSummary(
          companyIdsToRequest.map((id) => ({
            postgresCompanyId: id,
            info: "loading",
          }))
        )
      )
      const res = await runPostgresDbQuery(eitherAuthlessOrCurrentUser, {
        query_type: POSTGRES_QUERIES.COMPS_SUMMARY_DATA,
        payload: { company_ids: companyIdsToRequest },
      })
      const responseBody = (await res.json()) as CurrentCompsSummary[] // TODO fix
      const data = responseBody.map((row: CurrentCompsSummary) => ({
        postgresCompanyId: row.postgresCompanyId,
        info: row.info,
      }))
      dispatch(actions.setCurrentCompsSummary(data))
    }
  }

export const getCompsWithDescription =
  (
    eitherAuthlessOrCurrentUser: EitherAuthlessValidationOrCurrentUser,
    companyId: string,
    companyIdAsSomeonesComp: boolean = false,
    options?: PostgresRequestOptions
  ) =>
  async (dispatch: ReturnType<typeof useAppDispatch>, getState: GetState) => {
    const { compsLists } = getState().postgresData
    const subKey: "direct" | "reverse" = companyIdAsSomeonesComp ? "reverse" : "direct"
    if (!Object.keys(compsLists[subKey]).includes(companyId) || !!options?.forceRefresh) {
      dispatch(setLoading(true))
      dispatch(actions.receiveCompsInfo(companyId, "loading", subKey))
      const res = await runPostgresDbQuery(eitherAuthlessOrCurrentUser, {
        query_type: POSTGRES_QUERIES.COMPS,
        payload: { company_id: companyId, company_id_is_someones_comp: companyIdAsSomeonesComp },
      })
      type ResponseEntry = {
        displayed_company_id: PostgresComparableCompany["companyId"]
        status: PostgresComparableCompany["status"]
        company_name: PostgresComparableCompany["companyName"]
        ticker: PostgresComparableCompany["ticker"]
        description: PostgresComparableCompany["description"]
        has_market_price?: boolean
      }
      const responseBody = (await res.json()) as ResponseEntry[] // TODO fix

      const compsInfo: PostgresComparableCompany[] = responseBody.map((el) => ({
        companyId: el.displayed_company_id,
        status: el.status,
        companyName: el.company_name,
        ticker: el.ticker,
        description: el.description,
        hasMarketPrice: !!el.has_market_price,
      }))
      dispatch(actions.receiveCompsInfo(companyId, compsInfo, subKey))
      dispatch(setLoading(false))
    }
  }

export const deleteAComp =
  (issuer: string, comp: string, directOrReverse: "direct" | "reverse", currentUser: CurrentUser) =>
  async (dispatch: ReturnType<typeof useAppDispatch>) => {
    try {
      await runAPIQuery(
        APIEndpoints.postgresDBQuery,
        {
          query_type: POSTGRES_QUERIES.DELETE_COMP,
          payload: { issuer_id: issuer, comparable_id: comp },
        },
        currentUser
      )
      if (directOrReverse === "direct") {
        dispatch(actions.deleteCompsInfo(issuer, comp, directOrReverse))
      } else {
        dispatch(actions.deleteCompsInfo(comp, issuer, directOrReverse))
      }
    } catch (err) {
      handleConsoleError(err)
    }
  }

export const confirmAComp =
  (issuer: string, comp: string, directOrReverse: "direct" | "reverse", currentUser: CurrentUser) =>
  async (dispatch: ReturnType<typeof useAppDispatch>) => {
    try {
      await runAPIQuery(
        APIEndpoints.postgresDBQuery,
        {
          query_type: POSTGRES_QUERIES.CONFIRM_COMP,
          payload: { issuer_id: issuer, comparable_id: comp },
        },
        currentUser
      )
      if (directOrReverse === "direct") {
        dispatch(actions.confirmCompsInfo(issuer, comp, directOrReverse))
      } else {
        dispatch(actions.confirmCompsInfo(comp, issuer, directOrReverse))
      }
    } catch (err) {
      handleConsoleError(err)
    }
  }

export const addAComp =
  (comp: {
    issuerId: string
    compId: string
    compName: string
    currentUser: CurrentUser
    directOrReverse: "direct" | "reverse"
    description: string
    oldComp?: boolean
    ticker?: string
    hasMarketPrice: boolean
  }) =>
  async (dispatch: ReturnType<typeof useAppDispatch>) => {
    try {
      const {
        issuerId,
        compId,
        compName,
        currentUser,
        directOrReverse,
        description,
        ticker,
        hasMarketPrice,
      } = comp
      await runAPIQuery(
        APIEndpoints.postgresDBQuery,
        {
          query_type: POSTGRES_QUERIES.ADD_COMP,
          payload: { issuer_id: issuerId, comparable_id: compId },
        },
        currentUser
      )
      dispatch(
        actions.addedCompsInfo(
          {
            companyId: directOrReverse === "direct" ? compId : issuerId,
            companyName: compName,
            description,
            ticker,
            hasMarketPrice,
          },
          directOrReverse === "direct" ? issuerId : compId,
          directOrReverse
        )
      )
    } catch (err) {
      handleConsoleError(err)
    }
  }

export const companyIdsCheck = (ids: CompanyIds) => (company: Company) =>
  (ids.postgresId !== undefined && company.postgresCompanyId === ids.postgresId) ||
  (ids.airtableId !== undefined && company.airtableId === ids.airtableId) ||
  (ids.firebaseId !== undefined && company.id === ids.firebaseId)

export const readMarketPriceScores =
  (postgresCompanyId: string, currentUser: CurrentUser, options?: PostgresRequestOptions) =>
  async (dispatch: ReturnType<typeof useAppDispatch>, getState: GetState) => {
    const { caplightPriceEstimateScores } = getState().postgresData

    if (
      (!isLoading(caplightPriceEstimateScores) &&
        !caplightPriceEstimateScores[postgresCompanyId]) ||
      !!options?.forceRefresh
    ) {
      // re-request if an error or request for the first time
      try {
        dispatch(actions.receivePriceEstimateScores(postgresCompanyId, "loading"))
        const response = await runAPIQuery(
          APIEndpoints.postgresDBQuery,
          {
            query_type: POSTGRES_QUERIES.CAPLIGHT_PRICE_ESTIMATE_SCORE_HISTORY,
            payload: {
              environment: config.env === "prod" ? "prod" : "staging",
              company_id: postgresCompanyId,
            },
          },
          currentUser
        )
        const rawRes: unknown = await response.json()
        if (rawRes && _.isArray(rawRes)) {
          const isValid = validateCaplightQualityScore.overArray().validate(rawRes)
          const toSend = isValid.match(
            () => null,
            (x) => x
          )
          dispatch(actions.receivePriceEstimateScores(postgresCompanyId, toSend))
        } else {
          dispatch(actions.receivePriceEstimateScores(postgresCompanyId, []))
        }
      } catch (err) {
        dispatch(actions.receivePriceEstimateScores(postgresCompanyId, []))
        handleConsoleError(err)
      }
    }
  }

export const readPriceEstimateMonthlyFixing =
  (currentUser: CurrentUser, companyId: string, options?: PostgresRequestOptions) =>
  async (dispatch: ReturnType<typeof useAppDispatch>, getState: GetState) => {
    const { priceEstimateMonthlyFixing } = getState().postgresData
    const maybeEstimateForCompany = priceEstimateMonthlyFixing[companyId]

    if (maybeEstimateForCompany === undefined || !!options?.forceRefresh) {
      try {
        dispatch(actions.receivePriceEstimateMonthlyFixing(companyId, "loading"))
        const response = await runAPIQuery(
          APIEndpoints.postgresDBQuery,
          {
            query_type: POSTGRES_QUERIES.PRICE_ESTIMATE_MONTHLY_FIXING,
            payload: {
              company_id: companyId,
              environment: config.env === "prod" ? "prod" : "staging",
            },
          },
          currentUser
        )
        const rawRes: unknown = await response.json()
        // expecting either IsEmptyArrayDataRequest or nonempty CaplightPriceEstimateFromPostgresRaw
        if (rawRes) {
          const isValid: Either<
            string,
            IsEmptyArrayDataRequest | Nonempty<CaplightPriceEstimateFromPostgresRaw>
          > = validateIsEmptyArrayDataRequest
            .or(
              nonEmptyArrayValidator.compose(
                validateCaplightPriceEstimateFromPostgresRaw.overNonEmptyArray()
              )
            )
            .validate(rawRes)
          const result = isValid.match(
            (err: string) =>
              annotate<IsEmptyArrayDataRequest>({
                reasonEmpty: `Data received in a wrong format: ${err}`,
              }),
            (correctRes) =>
              isEmptyArrayDataRequest(correctRes)
                ? correctRes
                : Nonempty_.map(correctRes, parseOneRowCaplightPriceEstimateFromPostgres)
          )
          dispatch(actions.receivePriceEstimateMonthlyFixing(companyId, result))
        } else {
          dispatch(
            actions.receivePriceEstimateMonthlyFixing(companyId, {
              reasonEmpty: "No response received from the server",
            })
          )
        }
      } catch (err) {
        handleConsoleError(err)
      }
    }
  }

const getTotalOrdersLastYear = (issuer: CompanyWithPostgres) =>
  (issuer?.marketHistorySummary?.pastYear?.bidHistoryRollup?.orderCount || 0) +
  (issuer?.marketHistorySummary?.pastYear?.offerHistoryRollup?.orderCount || 0)

// TODO DRY
export const calculateMarketActivityRollup =
  (
    insight: actions.CompanyMarketInsightsType,
    indicator: "byUSD" | "byCount",
    timeFrame: keyof Omit<CompanyMarketHistorySummary, "updatedAt">
  ) =>
  (dispatch: ReturnType<typeof useAppDispatch>, getState: GetState) => {
    const { targetIssuers, caplightDataCompaniesRequestStatus, targetIssuersWereRequested } =
      getState().postgresData
    if (
      !targetIssuersWereRequested ||
      caplightDataCompaniesRequestStatus !== true ||
      !deprecatedIsLoaded(targetIssuers)
    )
      return
    const top = 30

    if (insight === "most_asks") {
      const field = indicator === "byUSD" ? "orderVolume" : "orderCount"
      const sortedIssuers = _.orderBy(
        targetIssuers.map((company) => ({
          activity: company?.marketHistorySummary?.[timeFrame]?.offerHistoryRollup?.[field] || 0,
          companyId: company.postgresCompanyId,
        })),
        "activity",
        "desc"
      )
        .splice(0, top)
        .map((issuer) => issuer.companyId)
      dispatch(actions.receiveCompaniesMarketInsight(insight, timeFrame, indicator, sortedIssuers))
    } else if (insight === "most_bids") {
      const field = indicator === "byUSD" ? "orderVolume" : "orderCount"
      const sortedIssuers = _.orderBy(
        targetIssuers.map((company) => ({
          activity: company?.marketHistorySummary?.[timeFrame]?.bidHistoryRollup?.[field] || 0,
          companyId: company.postgresCompanyId,
        })),
        "activity",
        "desc"
      )
        .splice(0, top)
        .map((issuer) => issuer.companyId)
      dispatch(actions.receiveCompaniesMarketInsight(insight, timeFrame, indicator, sortedIssuers))
    } else if (insight === "most_trades") {
      const field = indicator === "byUSD" ? "tradeVolume" : "tradeCount"
      const sortedIssuers = _.orderBy(
        targetIssuers.map((company) => ({
          activity: company?.marketHistorySummary?.[timeFrame]?.tradeHistoryRollup?.[field] || 0,
          companyId: company.postgresCompanyId,
        })),
        "activity",
        "desc"
      )
        .splice(0, top)
        .map((issuer) => issuer.companyId)
      dispatch(actions.receiveCompaniesMarketInsight(insight, timeFrame, indicator, sortedIssuers))
    } else if (insight === "most_relative_asks") {
      /**
       *
       * MARKET TILT
       *
       * */
      const field = indicator === "byUSD" ? "orderVolume" : "orderCount"
      const activitySorted = targetIssuers
        .filter((issuer) => issuer.status === "Private")
        .map(getTotalOrdersLastYear)
        .sort((a, b) => b - a)
      const activityCutoff = activitySorted[activitySorted.length < 50 ? 0 : 50]
      const sortedIssuers = _.orderBy(
        targetIssuers
          .filter(
            (issuer) =>
              issuer.status === "Private" && getTotalOrdersLastYear(issuer) >= activityCutoff
          )
          .map((company) => ({
            bids: company?.marketHistorySummary?.[timeFrame]?.bidHistoryRollup?.[field] || 0,
            asks: company?.marketHistorySummary?.[timeFrame]?.offerHistoryRollup?.[field] || 0,
            companyId: company.postgresCompanyId,
          }))
          .filter((summary) => summary.bids > 0 || summary.asks > 0)
          .map((summary) => ({
            activity: summary.asks / (summary.asks + summary.bids),
            companyId: summary.companyId,
          }))
          .filter((summary) => summary.activity > 0.5),
        "activity",
        "desc"
      )
        .splice(0, top)
        .map((issuer) => issuer.companyId)
      dispatch(actions.receiveCompaniesMarketInsight(insight, timeFrame, indicator, sortedIssuers))
    } else if (insight === "most_relative_bids") {
      /**
       *
       * MARKET TILT
       *
       * */
      const field = indicator === "byUSD" ? "orderVolume" : "orderCount"
      const activitySorted = targetIssuers
        .filter((issuer) => issuer.status === "Private")
        .map(getTotalOrdersLastYear)
        .sort((a, b) => b - a)
      const activityCutoff = activitySorted[activitySorted.length < 50 ? 0 : 50]
      const sortedIssuers = _.orderBy(
        targetIssuers
          .filter(
            (issuer) =>
              issuer.status === "Private" && getTotalOrdersLastYear(issuer) >= activityCutoff
          )
          .map((company) => ({
            bids: company?.marketHistorySummary?.[timeFrame]?.bidHistoryRollup?.[field] || 0,
            asks: company?.marketHistorySummary?.[timeFrame]?.offerHistoryRollup?.[field] || 0,
            companyId: company.postgresCompanyId,
          }))
          .filter((summary) => summary.bids > 0 || summary.asks > 0)
          .map((summary) => ({
            activity: summary.bids / (summary.asks + summary.bids),
            companyId: summary.companyId,
          }))
          .filter((summary) => summary.activity > 0.5),
        "activity",
        "desc"
      )
        .splice(0, top)
        .map((issuer) => issuer.companyId)
      dispatch(actions.receiveCompaniesMarketInsight(insight, timeFrame, indicator, sortedIssuers))
    }
  }

export const calculatePriceMovesRollup =
  (
    insight: "positive_price_moves" | "negative_price_moves",
    timeFrame: keyof Omit<CompanyMarketHistorySummary, "updatedAt">
  ) =>
  (dispatch: ReturnType<typeof useAppDispatch>, getState: GetState) => {
    const { targetIssuers, caplightDataCompaniesRequestStatus, targetIssuersWereRequested } =
      getState().postgresData
    if (
      !targetIssuersWereRequested ||
      caplightDataCompaniesRequestStatus !== true ||
      !deprecatedIsLoaded(targetIssuers)
    )
      return
    const top = 30
    const activitySorted = targetIssuers
      .filter((issuer) => issuer.status === "Private")
      .map(getTotalOrdersLastYear)
      .sort((a, b) => b - a)
    const activityCutoff = activitySorted[activitySorted.length < 50 ? 0 : 50]
    const timeKey = companySummaryToPriceEstimateSummary[timeFrame]
    const sortedIssuers = _.orderBy(
      targetIssuers
        .filter(
          (issuer) =>
            issuer.status === "Private" &&
            getTotalOrdersLastYear(issuer) >= activityCutoff &&
            !!issuer.settings?.showCaplightPriceEstimate
        )
        .map((company) => ({
          pastPrice: company?.priceEstimatesSummary?.[timeKey]?.priceEstimatePPS || 0,
          currentPrice: company?.priceEstimatesSummary?.currentPrice?.priceEstimatePPS || 0,
          totalOrdersLastYear: getTotalOrdersLastYear(company),
          companyId: company.postgresCompanyId,
        }))
        .filter((summary) => summary.pastPrice > 0.005 && summary.currentPrice > 0.005)
        .map((summary) => ({
          priceChange: summary.currentPrice / summary.pastPrice - 1,
          companyId: summary.companyId,
          totalOrdersLastYear: summary.totalOrdersLastYear,
        }))
        .filter((summary) =>
          insight === "positive_price_moves" ? summary.priceChange > 0 : summary.priceChange < 0
        ),
      ["priceChange", "totalOrdersLastYear"],
      insight === "positive_price_moves" ? "desc" : "asc"
    )
      .splice(0, top)
      .map((issuer) => issuer.companyId)
    dispatch(actions.receiveCompaniesPriceInsight(insight, timeFrame, sortedIssuers))
  }

const validateMostViewedCompanies: Validator<
  unknown,
  {
    company_id: string
    view_time_spent: number
    sessions: number
  }
> = Validator.fromRecord({
  company_id: stringValidator.validate,
  view_time_spent: numberValidator.validate,
  sessions: numberValidator.validate,
})

export const getMostViewedCompanies =
  (
    currentUser: CurrentUser,
    firebase: FirebaseReader,
    timeFrame: keyof Omit<CompanyMarketHistorySummary, "updatedAt">,
    options?: PostgresRequestOptions
  ) =>
  async (dispatch: ReturnType<typeof useAppDispatch>, getState: GetState) => {
    const { insightsData } = getState().postgresData

    if (
      !deprecatedIsLoaded(insightsData.companyMetrics.mostViewed[timeFrame]) ||
      !!options?.forceRefresh
    ) {
      const days = CompanyMarketHistorySummaryKeysToDays[timeFrame]
      try {
        dispatch(actions.receiveMostViewedCompanies(timeFrame, "loading"))
        const response = await runAPIQuery(
          APIEndpoints.postgresDBQuery,
          {
            query_type: POSTGRES_QUERIES.MOST_VIEWED_COMPANIES,
            payload: { days },
          },
          currentUser
        )
        const rawRes: unknown = await response.json()
        if (rawRes && _.isArray(rawRes)) {
          const isValid = validateMostViewedCompanies.overArray().validate(rawRes)
          dispatch(
            actions.receiveMostViewedCompanies(
              timeFrame,
              isValid.match(
                () => null,
                (correctRes) => {
                  // eslint-disable-next-line no-restricted-syntax
                  for (const id of correctRes) {
                    // checks if the company is in redux; otherwise requests it and adds to the store
                    dispatch(addToLocalTargetIssuersByPostgresId(firebase, id.company_id)).catch(
                      handleConsoleError
                    )
                  }
                  // validation code changes the order of the data
                  return _.orderBy(
                    correctRes.map((c) => ({
                      companyPostgresId: c.company_id,
                      viewsPercent: c.view_time_spent,
                      sessions: c.sessions,
                    })),
                    "sessions",
                    "desc"
                  )
                }
              )
            )
          )
        } else {
          dispatch(actions.receiveCaplightPriceEstimateModelList(null))
        }
      } catch (err) {
        handleConsoleError(err)
      }
    }
  }

export const getSectorList =
  (currentUser: CurrentUser, options?: PostgresRequestOptions) =>
  async (dispatch: ReturnType<typeof useAppDispatch>, getState: GetState) => {
    const { sectors } = getState().postgresData
    if (sectors === null || !!options?.forceRefresh) {
      actions.receiveAllSectorList("loading")
      try {
        const response = await runAPIQuery(
          APIEndpoints.postgresDBQuery,
          {
            query_type: POSTGRES_QUERIES.ALL_SECTOR_LIST,
          },
          currentUser
        )
        const rawRes: unknown = await response.json()
        if (rawRes && _.isArray(rawRes)) {
          dispatch(
            actions.receiveAllSectorList(
              rawRes.map((s) => (typeof s === "string" ? s : undefined)).filter(isDefined)
            )
          )
        } else {
          dispatch(actions.receiveAllSectorList(null))
        }
      } catch (err) {
        dispatch(actions.receiveAllSectorList([]))
      }
    }
  }
