import { useState } from "react"
import { ShareableItem } from "common/model/SharedOrder/SharedOrderResponse"
import ShareOrderInviteContacts from "./ShareOrderInviteContacts"
import { SharedOrderContactGroups } from "./SharedOrderContactGroups"
import { ShareableItemCard } from "./ShareableItemCard"
import { Button } from "@stories/components/Button/Button"
import { useSharedOrderEditor } from "./SharedOrderEmailEditor/SharedOrderEditorProvider"
import { SharedOrderGroupEmailEditor } from "./SharedOrderEmailEditor/SharedOrderGroupEditor"
import { SharedOrderMultiEmailEditor } from "./SharedOrderEmailEditor/SharedOrderMultiEditor"
import { assertUnreachable } from "common/utils/fp/Function"
import Typography, { Size, Weight } from "@stories/components/Typography/Typography"
import { ChevronRightIcon } from "@stories/icons/ChevronRightIcon"
import { ChevronLeftIcon } from "@stories/icons/ChevronLeftIcon"

export const SharedOrderSelectContacts = () => {
  const { handleAddEmails, handleRemoveEmails, emails } = useSharedOrderEditor()
  return (
    <div className="flex flex-col gap-4">
      <Typography text="Select Contacts or Colleagues" weight={Weight.Semibold} />
      <Typography
        text="Select the contacts or colleagues you want to share your orders with. They will receive an email with your orders and a notification in Caplight."
        size={Size.XSmall}
      />
      <ShareOrderInviteContacts handleAddEmails={handleAddEmails} emails={emails} />
      <SharedOrderContactGroups
        handleAddEmails={handleAddEmails}
        handleRemoveEmails={handleRemoveEmails}
        emails={emails}
      />
    </div>
  )
}

export const ShareOrderWorkflow = ({
  items,
  onCancel,
}: {
  items: ShareableItem[]
  onCancel: () => void
}) => {
  const [step, setStep] = useState<"select_contacts" | "customize_emails">("select_contacts")
  const { emails, editorMode, handleShareOrders } = useSharedOrderEditor()

  return (
    <div className="grid grid-cols-[1fr_1fr_5px_1fr_1fr_1fr] gap-4 mb-16">
      <div className="flex flex-col gap-4 col-span-2">
        {items.map((item) => (
          <ShareableItemCard key={item.id} shareableItem={item} />
        ))}
      </div>
      <div className="col-span-1 w-[1px] bg-neutral-400 h-[81vh]" />
      <div className="col-span-2">
        {step === "select_contacts" && (
          <>
            <SharedOrderSelectContacts />
            <div className="absolute bottom-0 left-0 right-0 bg-neutral-100 p-4 border-t z-20 flex justify-end gap-2">
              <Button size="large" label="Cancel" onClick={onCancel} variant="secondary" />
              <Button
                size="large"
                variant="secondary"
                label="Share without Email"
                onClick={() => handleShareOrders({ sendEmails: false })}
                isDisabled={emails.length === 0}
              />
              <Button
                size="large"
                label="Finalize Email"
                rightIcon={<ChevronRightIcon />}
                onClick={() => setStep("customize_emails")}
                isDisabled={emails.length === 0}
              />
            </div>
          </>
        )}
        {step === "customize_emails" && (
          <>
            {editorMode === "group" ? (
              <>
                <SharedOrderGroupEmailEditor />
                <div className="absolute bottom-0 left-0 right-0 bg-neutral-100 p-4 border-t z-20 flex justify-end gap-2">
                  <Button
                    size="large"
                    label="Back"
                    onClick={() => setStep("select_contacts")}
                    leftIcon={<ChevronLeftIcon />}
                    variant="secondary"
                  />
                  <Button
                    size="large"
                    label={`Share Orders (${emails.length})`}
                    rightIcon={<ChevronRightIcon />}
                    onClick={() => handleShareOrders({ sendEmails: true })}
                  />
                </div>
              </>
            ) : editorMode === "individually_customized" ? (
              <>
                <SharedOrderMultiEmailEditor />
                <div className="absolute bottom-0 left-0 right-0 bg-neutral-100 p-4 border-t z-20 flex justify-end gap-2">
                  <Button
                    size="large"
                    label="Back"
                    onClick={() => setStep("select_contacts")}
                    leftIcon={<ChevronLeftIcon />}
                    variant="secondary"
                  />
                  <Button
                    size="large"
                    label={`Share Orders (${emails.length})`}
                    rightIcon={<ChevronRightIcon />}
                    onClick={() => handleShareOrders({ sendEmails: true })}
                  />
                </div>
              </>
            ) : (
              assertUnreachable(editorMode)
            )}
          </>
        )}
      </div>
    </div>
  )
}
