import { Skeleton } from "@stories/components/Antd"
import React from "react"

interface LoadingCellProps {
  width?: number
}

const LoadingCell: React.FC<LoadingCellProps> = ({ width }) => (
  <div className="text-sm whitespace-nowrap">
    <Skeleton.Input
      size="small"
      className="w-full"
      active
      style={width ? { width, minWidth: width } : {}}
    />
  </div>
)

export default LoadingCell
