import { HighPriorityIcon } from "@stories/icons/HighPriorityIcon"
import { LowPriorityIcon } from "@stories/icons/LowPriorityIcon"
import { MediumPriorityIcon } from "@stories/icons/MediumPriorityIcon"
import { NoPriorityIcon } from "@stories/icons/NoPriorityIcon"
import { UrgentPriorityIcon } from "@stories/icons/UrgentPriorityIcon"
import { DealCRMPriority } from "common/model/DealCRM/Deal/DealCRMDeal"

export const getPriorityIcon = (priority: DealCRMPriority) => {
  switch (priority) {
    case "urgent":
      return <UrgentPriorityIcon />
    case "high":
      return <HighPriorityIcon />
    case "medium":
      return <MediumPriorityIcon />
    case "low":
      return <LowPriorityIcon />
    case "none":
      return <NoPriorityIcon />
    default:
      return <NoPriorityIcon />
  }
}
