import { OrderPageOrder } from "../types"
import { ExclamationIcon } from "@heroicons/react/outline"
import { matchLoading } from "common/utils/Loading"
import { Firestore, collection, getCountFromServer, query, where } from "firebase/firestore"
import { DotNestedKeys } from "common/firestore/DotNestedKeys"
import { collections } from "common/firestore/Collections"
import { IntroductionRequest } from "common/model/IntroductionRequest"
import Typography from "../../../../stories/components/Typography/Typography"
import { Size } from "../../../../stories/components/Typography/Typography"
import { APIEndpoints, useAPIQuery } from "../../../../firebase/API"
import { useLoggedInUser } from "../../../../providers/loggedInUser/useLoggedInUser"
import { useEffect, useState } from "react"
import { handleConsoleError } from "../../../../utils/Tracking"
import { useFirebase9 } from "../../../../firebase/Firebase9Context"
import { useIntroductionRequestButton } from "../../../../components/IntroductionRequest"
import { useAccountBrokerLinking } from "src/utils/useAccountBrokerLinking"

// TODO: make generic & move to design lib?
const ActiveConnectAlert = () => (
  <div className="border border-warning-500 rounded flex space-x-3 px-3 py-2">
    <ExclamationIcon className="w-4 h-4 flex-shrink-0" />
    <Typography
      size={Size.XSmall}
      text="This order has an open connection request in progress from another user. You can still request a connection, however the previous request will be given priority."
    />
  </div>
)

const getMyIntroRequestCount = (db: Firestore, orderId: string, userId: string) =>
  getCountFromServer(
    query(
      collection(db, collections.introductionRequest),
      where("createdBy.id" satisfies DotNestedKeys<IntroductionRequest>, "==", userId),
      where("priceObservationId.id" satisfies DotNestedKeys<IntroductionRequest>, "==", orderId)
    )
  ).then((snapshot) => snapshot.data().count)

export const LoadedOrderPendingConnect = ({ order }: { order: OrderPageOrder }) =>
  order.isDarkpool() ? null : <MarketVisibleOrderPendingConnect order={order} />

const MarketVisibleOrderPendingConnect = ({ order }: { order: OrderPageOrder }) => {
  const { isAccountBrokerLinkingEnabled } = useAccountBrokerLinking()
  const orderActiveIntroCount = useAPIQuery<{ activeIntroRequestCount: number }>(
    APIEndpoints.activeIntroRequestCount.build(order.id())
  )

  const { db } = useFirebase9()
  const { user } = useLoggedInUser()
  const [hasMyPendingIntro, setHasMyPendingIntro] = useState(false)
  useEffect(() => {
    getMyIntroRequestCount(db, order.id(), user.id)
      .then((count) => setHasMyPendingIntro(count > 0))
      .catch(handleConsoleError)
  }, [db, order, user.id])

  const orderHasActiveIntros = matchLoading(
    orderActiveIntroCount,
    (resp) => (resp.result?.activeIntroRequestCount ?? 0) > 0,
    false,
    false
  )

  const userHasAccessToConnect = useIntroductionRequestButton(
    user,
    "order"
  ).showIntroductionRequestButtonToUser

  const showConditions = [
    !order.isDarkpool(),
    userHasAccessToConnect,
    orderHasActiveIntros,
    order.isConnectable(),
    !order.fromAccount(user.account),
    !(order.isBrokeredByUser(user) && isAccountBrokerLinkingEnabled),
    !hasMyPendingIntro,
  ]

  return showConditions.every((x) => x) ? <ActiveConnectAlert /> : null
}

export default LoadedOrderPendingConnect
