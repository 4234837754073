import { useMemo, useCallback, useState, useContext, PropsWithChildren, useEffect } from "react"
import { FormOrderErrors, RequiredFormOrderKeys } from "common/model/Order/OrderForm/State"
import { chain, isNil } from "lodash"
import { UnsafeRec } from "common/utils/RecordUtils"
import { identity } from "common/utils/fp/Function"
import {
  isIntermediaryContext,
  useAuthContext,
  UserAuthContext,
} from "src/Routes/auth/UserAuthContext"
import { useAccountBrokerLinking } from "src/utils/useAccountBrokerLinking"
import { StepProps, StepsProps } from "@stories/components/Antd"
import { useOrderForm } from "../OrderFormContext"
import React from "react"
import { isDefined } from "common/utils/TypeUtils"
import { validateFormOrder } from "common/model/Order/OrderForm/Validation"

export const stepKeys = ["brokerStatus", "termStatus", "clientStatus", "reviewStatus"] as const
export type StepKey = (typeof stepKeys)[number]
export type StepCount = number
export const StepOrderMap = (
  ctx: UserAuthContext,
  brokerLinkingFlag: boolean = false
): Record<StepKey, StepCount> =>
  isIntermediaryContext(ctx)
    ? {
        termStatus: 0,
        clientStatus: 1,
        reviewStatus: 2,
        brokerStatus: -1,
      }
    : brokerLinkingFlag
    ? {
        brokerStatus: 0,
        termStatus: 1,
        clientStatus: 2,
        reviewStatus: 3,
      }
    : {
        termStatus: 0,
        clientStatus: 1,
        reviewStatus: 2,
        brokerStatus: -1,
      }
const InverseStepOrderMap = (
  ctx: UserAuthContext,
  brokerLinkingFlag: boolean = false
): Record<StepCount, StepKey> =>
  UnsafeRec.fromEntries(
    UnsafeRec.entries(StepOrderMap(ctx, brokerLinkingFlag)).map(([k, v]) => [v, k])
  )
export const StepTitleMap: Record<StepKey, string> = {
  termStatus: "Terms",
  clientStatus: "Client",
  reviewStatus: "Review",
  brokerStatus: "Broker",
}

type FormStatusState = {
  selectFormStatuses: () => Partial<Record<StepKey, StepsProps["status"]>>
  selectFormStatusByStep: (n: StepCount) => StepProps["status"]
  setStepProgress: (n: StepCount) => void
  stepProgress: StepCount
  maxStepReached: StepCount
}

const OrderFormStatusContext = React.createContext<FormStatusState>({
  selectFormStatuses: () => ({}),
  selectFormStatusByStep: () => "error",
  setStepProgress: () => {},
  stepProgress: 0,
  maxStepReached: 0,
})

export const useOrderFormStatus = () => useContext(OrderFormStatusContext)

export const OrderFormStatusContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { formOrder } = useOrderForm()
  const formErrors: FormOrderErrors = useMemo(
    () =>
      validateFormOrder(formOrder).match(
        (errs) => errs,
        () => new Set()
      ),
    [formOrder]
  )

  const { isAccountBrokerLinkingEnabled } = useAccountBrokerLinking()
  const ctx = useAuthContext()
  const [stepProgress, setStepProgress] = useState<StepCount>(0)

  const realTimeErrors = useMemo(
    () => validateFormOrder(formOrder).match(identity, () => null),
    [formOrder]
  )

  const checkFields = useCallback(
    (errorFields: RequiredFormOrderKeys[]) => {
      const allRequired = chain(errorFields)
        .every((k) => isDefined(formOrder[k]))
        .value()
      const hasErrors = chain(errorFields)
        .some((k) => formErrors.has(k))
        .value()
      const canContinue = realTimeErrors
        ? !chain(errorFields)
            .some((k) => realTimeErrors.has(k))
            .value()
        : true

      if (hasErrors) return "error"
      if (!canContinue) return "wait"
      if (allRequired) return "finish"
      return undefined
    },
    [formOrder, formErrors, realTimeErrors]
  )

  const clientStatus = useMemo(
    () =>
      isIntermediaryContext(ctx)
        ? checkFields([
            "clientRelationship",
            ...(formOrder.direction === "buy" ? ["buyerCashOnHand" as const] : []),
          ])
        : checkFields([]),
    [checkFields, ctx, formOrder.direction]
  )

  const termStatus = useMemo(() => {
    const priceAndSize = checkFields(["price", "size", "structures", "shareClasses"])
    if (priceAndSize && ["wait", "error"].includes(priceAndSize)) return priceAndSize
    else if (
      formOrder.price !== null &&
      isIntermediaryContext(ctx) &&
      isNil(formOrder.commission?.canChargeClientFee)
    ) {
      return "wait"
    }
    return priceAndSize
  }, [checkFields, ctx, formOrder])

  const brokerStatus: StepsProps["status"] = useMemo(
    () =>
      isIntermediaryContext(ctx) || !isAccountBrokerLinkingEnabled
        ? undefined
        : formOrder.brokeredBy !== undefined
        ? "finish"
        : "wait",
    [ctx, formOrder.brokeredBy, isAccountBrokerLinkingEnabled]
  )

  const reviewStatus = useMemo(() => {
    if (formErrors.size !== 0) {
      return "error"
    }
    if (stepProgress === StepOrderMap(ctx, isAccountBrokerLinkingEnabled).reviewStatus) {
      return "finish"
    }
    return "wait"
  }, [ctx, formErrors.size, stepProgress, isAccountBrokerLinkingEnabled])

  const selectFormStatuses = useCallback(
    (): Partial<Record<StepKey, StepsProps["status"]>> => ({
      clientStatus,
      termStatus,
      reviewStatus,
      brokerStatus,
    }),
    [clientStatus, reviewStatus, termStatus, brokerStatus]
  )

  const selectFormStatusByStep = useCallback(
    (n: StepCount) =>
      selectFormStatuses()[InverseStepOrderMap(ctx, isAccountBrokerLinkingEnabled)[n]],
    [selectFormStatuses, ctx, isAccountBrokerLinkingEnabled]
  )

  const maxStepReached = useMax(stepProgress)

  const ctxValue = useMemo(
    () => ({
      stepProgress,
      setStepProgress,
      selectFormStatuses,
      selectFormStatusByStep,
      maxStepReached,
    }),
    [maxStepReached, selectFormStatusByStep, selectFormStatuses, stepProgress]
  )
  return (
    <OrderFormStatusContext.Provider value={ctxValue}>{children}</OrderFormStatusContext.Provider>
  )
}

const useMax = (x: number) => {
  const [val, setVal] = useState(x)
  useEffect(() => {
    if (x > val) {
      setVal(x)
    }
  }, [x, val])
  return val
}
