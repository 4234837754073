import { Skeleton } from "@stories/components/Antd"
import { CompanyLogoAndNameSkeleton } from "../../../../components/CompanyLogoAndName"
import { TablePillSkeleton } from "../../../../components/TablePill"

const LoadingOrderPreviewDetail = ({ itemWidth = 48 }: { itemWidth?: number }) => (
  <div className="flex flex-col space-y-2 mb-2">
    <Skeleton title={{ width: 72 }} paragraph={false} active />
    <Skeleton title={{ width: itemWidth }} paragraph={false} active />
  </div>
)

const LoadingOrderPreview = () => (
  <div className="flex flex-col gap-4">
    <div className="flex items-center justify-between">
      <div className="flex flex-row items-center space-x-2">
        <CompanyLogoAndNameSkeleton />
        <Skeleton title={{ width: 32 }} paragraph={false} active />
      </div>
      <div className="flex flex-row items-center space-x-2">
        <Skeleton title={{ width: 48 }} paragraph={false} active />
        <Skeleton.Button active />
      </div>
    </div>
    <div className="flex items-center justify-between">
      <div className="flex space-x-2">
        <LoadingOrderPreviewDetail />
        <LoadingOrderPreviewDetail />
        <LoadingOrderPreviewDetail />
      </div>
      <div className="flex space-x-2">
        <LoadingOrderPreviewDetail itemWidth={96} />
        <LoadingOrderPreviewDetail itemWidth={96} />
      </div>
    </div>
  </div>
)

export default LoadingOrderPreview
