import { DealCRMPriorities } from "common/model/DealCRM/Deal/DealCRMDeal"
import { priorityOrder } from "../../DealKanbanBoard/helpers"
import { PriorityOptionLabel } from "./PriorityOptionLabel"

export const PriorityOptions = (options: {
  includeUrgent: boolean
  size: "md" | "sm" | "xs"
  iconOnlyMenu?: boolean
}) =>
  DealCRMPriorities.filter((item) => options.includeUrgent || item !== "urgent")
    .map((val) => ({
      value: val,
      label: (
        <PriorityOptionLabel
          size={options.size}
          priority={val}
          iconOnlyMenu={options.iconOnlyMenu ?? false}
        />
      ),
    }))
    .sort((a, b) => {
      const priorityA = priorityOrder[a.value]
      const priorityB = priorityOrder[b.value]

      return priorityA - priorityB
    })
