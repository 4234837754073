import { createContext, useContext } from "react"
import { Loading } from "common/utils/Loading/Wrapped"
import { APIEndpoints, useAPIQuery } from "../../firebase/API"

export const TopBrokerContext = createContext<string[]>([])

export const TopBrokerProvider = ({ children }: { children: React.ReactNode }) => {
  const topBrokerAccountIds = new Loading(
    useAPIQuery<{ topBrokerAccountIds: string[] }>(APIEndpoints.getTopBrokerAccountIds)
  )
    .map((response) => response.result?.topBrokerAccountIds ?? [])
    .withDefault([])

  return (
    <TopBrokerContext.Provider value={topBrokerAccountIds}>{children}</TopBrokerContext.Provider>
  )
}

export const useTopBrokerAccountIds = () => useContext(TopBrokerContext)
