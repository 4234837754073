import { gcd } from "./Arithmetic"

export type Q<T> = {
  numerator: T
  denominator: T
}

export const Ratio = <T>(numerator: T, denominator: T): Q<T> => ({
  numerator,
  denominator,
})

export const simplifyQ = (q: Q<number>) => {
  const x = gcd(q.numerator, q.denominator)
  return {
    numerator: q.numerator / x,
    denominator: q.denominator / x,
  }
}

export const addQ = (l: Q<number>, r: Q<number>) =>
  simplifyQ({
    numerator: l.numerator * r.denominator + r.numerator * l.denominator,
    denominator: l.denominator * r.denominator,
  })
export const multiplyQ = (l: Q<number>, r: Q<number>) =>
  simplifyQ({
    numerator: l.numerator * r.numerator,
    denominator: l.denominator * r.denominator,
  })
export const negateQ = (q: Q<number>) => ({
  numerator: -q.numerator,
  denominator: q.denominator,
})
export const invertQ = <T>(q: Q<T>) => ({
  numerator: q.denominator,
  denominator: q.numerator,
})
export const subtractQ = (l: Q<number>, r: Q<number>) => addQ(l, negateQ(r))
export const divideQ = (l: Q<number>, r: Q<number>) => multiplyQ(l, invertQ(r))

export const numberToQ = (x: number) => Ratio(x, 1)
export const qToNumber = (x: Q<number>): number => x.numerator / x.denominator
