import { collectionReferences } from "../../firestore/Collections"
import { FirebaseCommon } from "../../firestore/Interface"
import { DualizeUnion } from "../../utils/RecordUtils"
import { unique } from "../../utils/data/Array/ArrayUtils"
import { assertDoesNotExtend, assertExtends } from "../../utils/data/Type/Assertions"
import { Maybe } from "../../containers/Maybe"
import { field, optionalFieldAsMaybe, ReifiedLens, subrecord } from "../../utils/fp/optics/Lens"
import {
  ReifiedSimpleTraversal,
  recordFields,
  indexedRecordFields,
} from "../../utils/fp/optics/Traversal"
import { RecordToSum } from "../../utils/types/Sum"
import { viewAccountIdFields } from "../Account"
import { User, UserIdFields, viewUserIdFields } from "../User"
import { ThreadOverlayData } from "./DocumentOverlay"
import { ConnectMessage, PlaintextConnectMessage, UnsentConnectMessage } from "./Message"
import { ConnectUserRole, ThreadAccountData, ThreadUserData } from "./ThreadParticipant"
import { ThreadTypeData, ThreadType, ThreadTypeSpec } from "./ThreadTypes/ThreadType"
import { SaveDocumentOverlayProps } from "./Utils/messagePrimitives"
import { addUserToThread } from "./Utils/users"

export type ConnectThreadStatus = RecordToSum<{
  archived: {}
  live: {}
}>

export type ThreadParticipantFields = {
  participatingAccounts: Record<AccountId, ThreadAccountData>
  participatingAccountIds: AccountId[]
  participatingUsers: Record<User["id"], ThreadUserData>
}

type ThreadConditional = "orderConfirmedLive" | "orderConfirmedDead"
type ThreadConditionalMessage = Record<ThreadConditional, Omit<PlaintextConnectMessage, "date">>

/** A connect thread which typescript does not yet believe has a definite value of `T` for annoying technical reasons */
export type NewConnectThread<T extends ThreadType> = ThreadParticipantFields &
  Pick<ThreadTypeData[T], keyof ThreadTypeSpec> & {
    // The Pick<> here ensures we have a consistent set of keys for all T
    id: string
    documentOverlays: Record<DocumentId, ThreadOverlayData>
    _subjectLine?: string
    _status?: ConnectThreadStatus | null
    _threadRep?: UserIdFields | null
    _mostRecentMessageDate?: Date
    _mostRecentReadDate?: Date
    conditionalMessages?: ThreadConditionalMessage[]
  }
// Distributes over ThreadType
type ThreadUnionHelper<T extends ThreadType> = T extends unknown ? NewConnectThread<T> : never
export type ConnectThread<T extends ThreadType = ThreadType> = ThreadUnionHelper<T>

assertExtends<ConnectThread<ThreadType>, NewConnectThread<ThreadType>>
assertDoesNotExtend<NewConnectThread<ThreadType>, ConnectThread<ThreadType>>

type ConnectThreadWitness<T extends ThreadType> = DualizeUnion<
  T extends unknown ? (t: NewConnectThread<T>) => ConnectThread<T> : never
>
/** Verifies that the generic parameter to a `NewConnectThread` is a single literal and not a union. Should generally not have to be called but included just in case  */
export const distributeConnectThread: ConnectThreadWitness<ThreadType> = <T extends ThreadType>(
  t: NewConnectThread<T>
) => t as ConnectThread<T>

/** Asserts without evidence that the generic parameter to a `NewConnectThread` is a single literal and not a union. Use the safe version if at all possible. */
export const unsafeDistributeConnectThread = <T extends ThreadType>(t: NewConnectThread<T>) =>
  t as ConnectThread<T>

export const threadAccounts = field(
  "participatingAccounts"
)<ThreadParticipantFields>().composeTraversal(indexedRecordFields())

const threadUserDataRoles: ReifiedSimpleTraversal<ThreadUserData, ConnectUserRole[]> =
  new ReifiedLens({
    view: (u) => u.roles,
    over: (s, f) => ({ ...s, roles: f(s.roles) }),
  })
export const threadUsers = field("participatingUsers")<ThreadParticipantFields>().composeTraversal(
  recordFields()
)
export const threadAccount = <T extends ThreadParticipantFields = ThreadParticipantFields>(
  accountId: string
): ReifiedLens<T, T, Maybe<ThreadAccountData>, ThreadAccountData> =>
  subrecord<ThreadParticipantFields, T>()
    .composeLens(field("participatingAccounts")<ThreadParticipantFields>())
    .composeLens(optionalFieldAsMaybe(accountId)())

export const threadUser = (
  userId: string
): ReifiedLens<
  ThreadParticipantFields,
  ThreadParticipantFields,
  Maybe<ThreadUserData>,
  ThreadUserData
> =>
  field("participatingUsers")<ThreadParticipantFields>().composeLens(optionalFieldAsMaybe(userId)())

export const threadUserRoles: ReifiedSimpleTraversal<ThreadParticipantFields, ConnectUserRole[]> =
  threadUsers.composeTraversal(threadUserDataRoles)

export const threadRepEmail = (thread: ConnectThread) =>
  thread?._threadRep?.email ?? "contact@caplight.com"

export const setThreadRep =
  (db: FirebaseCommon.DB) => async (thread: ConnectThread, user: User) => {
    const threadRef = collectionReferences.connectThreads(db).doc(thread.id)
    await threadRef.update({ _threadRep: viewUserIdFields(user) })
    await threadRef.update(
      addUserToThread(
        viewUserIdFields(user),
        viewAccountIdFields(user.account),
        threadUserRoles.over(
          thread,
          (roles) => unique(roles.map((role) => (role === "point" ? "other admin" : role))) // only one point person at a time
        ),
        { roles: ["point"], primaryParticipant: true }
      )
    )
  }

export const connectThreadStatus = (thread: ConnectThread): ConnectThreadStatus =>
  thread._status ?? { tag: "live" }

export const threadSubjectLine = (
  thread: Pick<ConnectThread, "_subjectLine" | "parentDocument" | "id">
) => thread._subjectLine ?? thread.id // TODO better fallback

type DocumentId = string
type AccountId = string

export type HydratedConnectThread = {
  thread: ConnectThread
  messages: ConnectMessage[]
  sendMessage: (message: UnsentConnectMessage) => Promise<unknown>
  saveDocumentOverlay: (props: Omit<SaveDocumentOverlayProps, "thread">) => Promise<Maybe<never>>
}

export const isThreadDenied = (thread: ConnectThread): boolean => thread._status?.tag === "archived"
export const getThreadRecencyDate = (t: ConnectThread): Date =>
  t._mostRecentMessageDate ?? t._mostRecentReadDate ?? new Date()

/** insurance in case we screw up the migration */
export const temp_isConnectionRequestThread = (
  t: ConnectThread
): t is ConnectThread<"connectRequest"> =>
  t.threadType === undefined || t.threadType === "connectRequest"
