import { CountdownTimer } from "@components/data_display/CountdownTimer"
import { Tooltip, notification } from "@stories/components/Antd"
import { collections } from "common/firestore/Collections"
import { CampaignTypes } from "common/model/campaigns/CampaignType"
import { Company, companyInPostgres } from "common/model/Company"
import { RFQType, rfqCompanyId, rfqCompanyName } from "common/model/RFQ/RFQ"
import { deprecatedIsLoaded, isLoading, matchLoading } from "common/utils/Loading"
import moment from "moment-timezone"
import React, { useState } from "react"
import LiveIcon from "@stories/components/Badges/LiveIcon/LiveIcon"
import { useMemo } from "src/utils/hooks/effects/useEffectSafe"
import CompanyLogo from "../../components/CompanyLogo"
import { logoClass } from "../../components/CompanyLogo/CompanyLogo"
import { Modal } from "../../components/layout/Modal"
import { QuestionComponents } from "../../components/Questions/QuestionComponents"
import { useDocument } from "../../firebase/Firestore"
import { useLoggedInUser } from "../../providers/loggedInUser/useLoggedInUser"
import { Routes } from "../../Routes/Routes"
import { classNames } from "../../utils/classNames"
import { useAccountRFQResponse } from "./useAccountRFQResponse"
import { useCampaignParticipantDoc } from "./useCampaignParticipant"
import { Button, ButtonVariant } from "@stories/components/Button/Button"
import { useCurrentAccount } from "../../queries/currentUser/useCurrentAccount"

export const getStatusMessage = (rfq: RFQType): React.ReactNode => {
  if (isCompletedRFQ(rfq)) return "Completed"

  if (isActiveRFQ(rfq)) {
    return (
      <div className="flex items-center whitespace-nowrap space-x-1">
        <div>Expires in</div>
        <CountdownTimer targetDate={rfq.batch.endDate} />
      </div>
    )
  }

  if (isScheduledRFQ(rfq)) {
    return (
      <div className="flex items-center space-x-1">
        <div>Starting in</div>
        <CountdownTimer targetDate={rfq.batch.startDate} />
      </div>
    )
  }

  return ""
}

const getStatusStyle = (rfq: RFQType): string => {
  if (isCompletedRFQ(rfq)) return "text-success-700"

  return "text-neutral-800"
}

export const RFQDisplayCardInner = ({ rfq }: { rfq: RFQType }) => {
  const user = useLoggedInUser()
  const displayHostedRFQVisualizations = rfq.isHostedResultsLive
  const [accountRFQResponse] = useAccountRFQResponse(rfq.id, user.user.account.id)
  const account = useCurrentAccount()
  const rfqHasResults = displayHostedRFQVisualizations || rfq.resultsDownloadUrl
  const accountHasAnsweredRFQ = deprecatedIsLoaded(accountRFQResponse)
  const allRFQResponsesEnabledAccount = matchLoading(
    account,
    (acc) => acc.allRFQResultsEnabled,
    false,
    false
  )

  const accountCanViewResponse =
    accountHasAnsweredRFQ || user.isAdmin || allRFQResponsesEnabledAccount

  const handleViewResults = React.useCallback(() => {
    if (displayHostedRFQVisualizations) window.open(Routes.rfqs.results(rfq.id), "_blank")
    else if (rfq.resultsDownloadUrl) window.open(rfq.resultsDownloadUrl, "_blank")
    else {
      notification.error({
        message: "There was an issue retrieving results. We are looking into this.",
      })
    }
  }, [rfq.resultsDownloadUrl, rfq.id, displayHostedRFQVisualizations])

  const [showOrderTerms, setShowOrderTerms] = useState<boolean>(false)

  const handleViewRFQ = React.useCallback(() => {
    window.open(Routes.rfqs.rfq(rfq.id), "_blank")
  }, [rfq.id])

  const handleViewOrderTerms = () => {
    setShowOrderTerms(true)
  }

  const rfqActions = React.useMemo((): RFQCardAction[] => {
    if (isCompletedRFQ(rfq)) {
      return [
        {
          onClick: () => handleViewOrderTerms(),
          title: "View Terms",
          buttonType: "hollow",
        },
        {
          onClick: accountCanViewResponse && rfqHasResults ? () => handleViewResults() : () => {},
          isDisabled: !accountCanViewResponse || !rfqHasResults,
          title: "View Results",
          heapName: "view-results-from-rfq-page",
          buttonType: "primary",
          tooltip: accountCanViewResponse
            ? !rfqHasResults
              ? "You will receive a notification when results for this RFQ are made available."
              : undefined
            : "Results are only available to users who participated in the RFQ while it was live.",
        },
      ]
    }

    if (isActiveRFQ(rfq)) {
      if (accountHasAnsweredRFQ) {
        return [
          {
            onClick: () => handleViewOrderTerms(),
            title: "View Terms",
            buttonType: "hollow",
          },
          {
            onClick: () => {},
            title: "Results pending",
            buttonType: "hollow",
            textClass: "italic",
          },
        ]
      }
      return [
        {
          onClick: () => handleViewRFQ(),
          title: "View RFQ",
          heapName: "respond-to-rfq-from-rfq-page",
          buttonType: "primary",
        },
      ]
    }

    if (isScheduledRFQ(rfq)) {
      return []
    }

    return []
  }, [
    handleViewRFQ,
    rfq,
    handleViewResults,
    rfqHasResults,
    accountCanViewResponse,
    accountHasAnsweredRFQ,
  ])

  return (
    <>
      <Modal
        handleClose={() => setShowOrderTerms(false)}
        open={showOrderTerms}
        closable
        maskClosable
        body={
          <div className="p-4">
            <QuestionComponents.OrderTerms rfq={rfq} forceExpand />
          </div>
        }
      />
      <div className="flex flex-col justify-start sm:justify-end items-start sm:items-end space-y-2 w-full">
        <div className="flex items-center justify-end space-x-2 w-full">
          {rfqActions.map((action) => (
            <Tooltip title={action.tooltip} key={action.title}>
              <Button
                label={action.title}
                variant={action.buttonType}
                onClick={action.onClick}
                heapName={action.heapName}
                isDisabled={action.isDisabled}
              />
            </Tooltip>
          ))}
        </div>
        <div className={classNames("text-sm", getStatusStyle(rfq))}>{getStatusMessage(rfq)}</div>
      </div>
    </>
  )
}

interface IRFQDisplayCard {
  rfq: RFQType
}

type RFQCardAction = {
  onClick: () => void
  title: string
  buttonType: ButtonVariant
  isDisabled?: boolean
  textClass?: string
  tooltip?: string
  heapName?: string
}

export const isCompletedRFQ = (rfq: RFQType): boolean =>
  moment(rfq.batch.endDate).isBefore(moment())
export const isActiveRFQ = (rfq: RFQType): boolean =>
  moment(rfq.batch.endDate).isAfter(moment()) && moment(rfq.batch.startDate).isBefore(moment())
export const isScheduledRFQ = (rfq: RFQType): boolean =>
  moment(rfq.batch.endDate).isAfter(moment()) && moment(rfq.batch.startDate).isAfter(moment())

const RFQDisplayCard: React.FC<React.PropsWithChildren<IRFQDisplayCard>> = ({ rfq }) => {
  const campaignInfo = useMemo(() => ({ campaignType: CampaignTypes.rfq, id: rfq.id }), [rfq.id])

  const [company] = useDocument<Company>(collections.companies, rfqCompanyId(rfq))
  const [participant] = useCampaignParticipantDoc(campaignInfo)

  if (isLoading(participant)) return null

  if (!participant && (rfq.isSensitive || rfq.isTest)) return null

  return (
    <>
      <div className="bg-neutral-white rounded-md shadow max-w-3xl w-full p-4 flex flex-col space-y-1 sm:space-y-0 sm:flex-row items-center justify-between border border-neutral-300">
        <div className="flex items-center space-x-4 w-full">
          {deprecatedIsLoaded(company) && companyInPostgres(company) ? (
            <CompanyLogo size="md" company={company} />
          ) : (
            <div className={logoClass.md} />
          )}
          <div className="flex flex-col justify-center space-y-2">
            <div className="flex flex-row items-center space-x-2">
              <p className="text-lg font-bold">{rfqCompanyName(rfq)}</p>
              {isActiveRFQ(rfq) ? <LiveIcon /> : null}
            </div>
            <p className="text-sm text-neutral-800">
              {isCompletedRFQ(rfq)
                ? `Ended ${moment(rfq.batch.endDate).format("MM/DD/YYYY")}`
                : `${isScheduledRFQ(rfq) ? "Starting" : "Started"} ${moment(
                    rfq.batch.startDate
                  ).format("MM/DD/YYYY")}`}
            </p>
          </div>
        </div>
        <RFQDisplayCardInner rfq={rfq} />
      </div>
    </>
  )
}

export default RFQDisplayCard
