import { Button } from "@stories/components/Button/Button"
import Disclaimer from "@stories/components/Disclaimer/Disclaimer"
import Typography, { Color, Font, Size, Weight } from "@stories/components/Typography/Typography"
import { WarningIcon } from "@stories/icons/WarningIcon"
import { useNavigate } from "react-router-dom"
import { Routes } from "src/Routes/Routes"
import { BookACallButton } from "./BookACallButton"

interface AuthWallPageProps {
  variant: "expired-trial" | "contribution-required" | "quota-non-compliant"
}

export const AuthWallPage: React.FC<AuthWallPageProps> = ({ variant }) => {
  const navigate = useNavigate()
  const handleClick = () =>
    variant === "quota-non-compliant"
      ? navigate(Routes.myOrders)
      : navigate(Routes.data.upload.root)

  return (
    <>
      <div className="flex flex-col justify-between items-center w-full h-screen my-8">
        <div className="px-6 md:px-0 md:w-1/2 flex flex-col gap-6">
          <div className="flex flex-row justify-center items-center gap-4">
            <div className="w-12">
              <WarningIcon color={Color.Primary} />
            </div>
            <div className="flex">
              <Typography
                weight={Weight.Semibold}
                font={Font.Cambon}
                size={Size.XXXLarge}
                text={
                  variant === "contribution-required"
                    ? "Data Contribution Required"
                    : variant === "quota-non-compliant"
                    ? "Account Restricted - Action Required due to Order Quota Violation"
                    : "Trial Expired"
                }
              />
            </div>
          </div>
          {variant === "contribution-required" && (
            <div className="flex flex-col gap-4">
              <Typography text="Your account access has been restricted due to insufficient data contributions." />
              <div>
                <Typography text="To regain platform access, please upload your recent orders and/or closed transactions on the " />
                <span
                  role="link"
                  className="font-bold text-primary-400 hover:text-primary-500 cursor-pointer"
                  tabIndex={0}
                  onKeyDown={handleClick}
                  onClick={handleClick}
                >
                  data contribution page.
                </span>
              </div>
              <Typography text="If you don't have any data points to upload but would like to continue accessing the platform please book a call with us using the link below." />
            </div>
          )}
          {variant === "expired-trial" && (
            <div className="flex flex-col gap-4">
              <Typography text="Your trial access to Caplight has expired. To regain access to the platform, please book a call with us using the link below or email contact@caplight.com" />
            </div>
          )}
          {variant === "quota-non-compliant" && (
            <div className="flex flex-col gap-4">
              <Typography text="You must resolve your order quota violation immediately to regain access to the main functions of the app. Update or add new orders now to maintain compliance." />
            </div>
          )}

          <div className="flex gap-4 flex-row justify-center">
            <BookACallButton variant="secondary" />
            {variant === "contribution-required" && (
              <Button
                size="large"
                onClick={handleClick}
                variant="primary"
                label="Contribute data"
              />
            )}
            {variant === "quota-non-compliant" && (
              <Button
                size="large"
                onClick={handleClick}
                variant="primary"
                label="Manage My Orders"
              />
            )}
          </div>
        </div>
        <div className="p-4 mt-8">
          <Disclaimer />
        </div>
      </div>
    </>
  )
}
