import * as React from "react"
import { classNames } from "../utils/classNames"

export interface PillProps {
  textClass?: string
  bgClass?: string
  content: string | JSX.Element
  uppercase?: boolean
}

// UI Component for rendering labels, tags, etc
const Pill: React.FunctionComponent<React.PropsWithChildren<PillProps>> = ({
  textClass,
  bgClass,
  content,
  uppercase,
}) => (
  <div
    className={classNames(
      "text-xxs px-2 h-5 rounded-sm font-medium inline-flex items-center justify-center",
      textClass || "text-white",
      bgClass || "bg-neutral-300"
    )}
  >
    <span>{uppercase && typeof content === "string" ? content.toUpperCase() : content}</span>
  </div>
)

export default Pill
