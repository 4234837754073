/* eslint-disable @typescript-eslint/no-empty-function */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const assertExtends = <S extends T, T>() => {}
export const assertExtendedBy = <S, T extends S>() => {}
export const assertMutuallyAssignable = <
  S extends T,
  T extends CircularConstraintCircuitBreaker,
  CircularConstraintCircuitBreaker = S
>() => {}

/** Does not work for <S, `never`> */
export const assertDoesNotExtend = <
  S,
  T extends Helper,
  Helper = [S] extends [T] ? never : T
>() => {}

assertExtends<string, unknown>
assertExtends<number, unknown>
assertExtends<object, unknown>
assertExtends<never, string>
assertExtends<never, number>
assertExtends<never, object>
assertExtends<string, string | number>

/** @ts-expect-error */
assertExtendedBy<string, unknown>
/** @ts-expect-error */
assertExtendedBy<number, unknown>
/** @ts-expect-error */
assertExtendedBy<object, unknown>
/** @ts-expect-error */
assertExtendedBy<never, string>
/** @ts-expect-error */
assertExtendedBy<never, number>
/** @ts-expect-error */
assertExtendedBy<never, object>
/** @ts-expect-error */
assertExtendedBy<string, string | number>

assertMutuallyAssignable<"a" | string, string>
assertMutuallyAssignable<0 | 1, 1 | 0>
assertMutuallyAssignable<Partial<object>, object>
assertMutuallyAssignable<{ a?: string; c: number }, { b?: string; c: number }>

/** @ts-expect-error */
assertMutuallyAssignable<0 | string, string>
/** @ts-expect-error */
assertMutuallyAssignable<0 | 1, boolean>
/** @ts-expect-error */
assertMutuallyAssignable<{ a: string }, { a?: string }>
/** @ts-expect-error */
assertMutuallyAssignable<{ a?: string }, { b?: string }>

assertDoesNotExtend<number, never>
assertDoesNotExtend<unknown, never>
assertDoesNotExtend<object, never>
assertDoesNotExtend<string, never>
assertDoesNotExtend<(x: never) => void, never>
assertDoesNotExtend<(x: never) => void, (x: unknown) => void>
assertDoesNotExtend<{ a: string }, { a: string; b: number }>

/** @ts-expect-error */
assertDoesNotExtend<unknown, unknown>
/** @ts-expect-error */
assertDoesNotExtend<(x: unknown) => void, (x: never) => void>
/** @ts-expect-error */
assertDoesNotExtend<{ a: string; b: number }, { a: string }>
/** @ts-expect-error */
assertDoesNotExtend<Record<string, unknown>, object>
/** @ts-expect-error */
assertDoesNotExtend<number, number | string>
