import OrderDetailsButton from "@components/OrderDetails"
import ButtonGroup from "@stories/components/ButtonGroup/ButtonGroup"
import { DealCRMInterest } from "common/model/DealCRM/DealCRMInterest"
import { OrderIdFields } from "common/model/Order/Order"
import { useOrder } from "src/queries/Order/useOrder"
import { EditButton } from "src/pages/Orders/MyOrders/MyOrdersRowCells"
import { RefreshButton } from "src/pages/Orders/MyOrders/RefreshOrderButton"

export const PublishedOrderControls = ({
  interest,
}: {
  interest: DealCRMInterest & { publishedOrder: OrderIdFields }
}) => {
  const loadingOrder = useOrder(interest.publishedOrder)

  return loadingOrder.match(
    (order) => (
      <div className="flex flex-row items-center gap-1" onClick={(e) => e.stopPropagation()}>
        <ButtonGroup>
          <OrderDetailsButton orderId={order.toInternal()} iconOnly />
          <EditButton order={order.toInternal()} iconOnly sourceComponent="crm-interest-row" />
          <RefreshButton order={order.toInternal()} iconOnly sourceComponent="crm-interest-row" />
        </ButtonGroup>
      </div>
    ),
    null,
    null
  )
}
