import { Schema as z } from "../../../schema/Schema"
import { Excluding } from "../../../utils/TypeUtils"
import { BrokerClientMetadata } from "../../BrokerClient"
import { CompanyIdFields, Company } from "../../Company"
import { Derived } from "../../data-product/Derived"
import { DataVisibilityFields } from "../../data-product/DataPoint/VisibilityFields"
import {
  makeOutlierStatus,
  outlierCheckResultSchema,
} from "../../data-product/DataPoint/OutlierStatusFields"
import {
  OrderDirection,
  OrderFirmness,
  OrderShareClass,
  OrderDocuments,
  OrderCommission,
} from "../Order"
import { OrderSource, orderSourceSchema } from "../OrderSource"
import { OrderMetadata } from "../Types/OrderMetadata"
import {
  isLiveOrder,
  isLiveOrStaleOrder,
  OrderStatusFields,
  orderStatusUpdateSchema,
  updateOrderStatusOverride,
} from "../Types/Status"
import { OrderStructure, OrderTerms } from "../Types/Terms"
import {
  connectExcludedAccountIds,
  nonFormOrdersConnectExcludedAccountIds,
} from "../../../utils/constants/flaggedAccounts"
import { ViewTracked } from "../../ViewTracked"
import { User } from "../../User"
import { isHidden } from "../../data-product/DataPoint"
import { Note } from "../../Note"
import { OrderQuotaComplianceFields } from "../QuotaCompliance/runQuotaComplianceChecks"
import { LinkedBroker } from "../../LinkedBroker"
import { UserInvitedBroker } from "../../UserInvitedBroker"
import { RecordInfo } from "../../RecordInfo"
import { SourceAttribution } from "../../data-product/DataPoint/SourceAttribution"
import { orderTermsSchema } from "../TermConstruction"
import { UnconfirmableYesNo } from "../../UnconfirmableYesNo"
import { normalizeOrder } from "./normalizeOrder"

export type LayeredStructure =
  | { structure: "spv"; underlying: LayeredStructure }
  | { structure: Omit<OrderStructure, "spv">; underlying: null }

export const countStructureLayers = (l: LayeredStructure): number => {
  if (!l.underlying) {
    return 0
  }
  return 1 + countStructureLayers(l.underlying)
}

export const doubleLayerSPV: LayeredStructure = {
  structure: "spv",
  underlying: {
    structure: "spv",
    underlying: { structure: "direct" as const, underlying: null },
  },
}

export type OrderLookupFields = {
  id: string
  /** @deprecated */
  darkpool: boolean
  /** public if missing: TODO migration */
  orderCollection?: "platform" | "private" | "darkpool" | "tentativeInterest"
}

/** The fields used to decide whether two {@link Order}s refer to the same entity */
export type OrderIdFields = OrderLookupFields & {
  source: OrderSource
  direction: OrderDirection
  company: Excluding<CompanyIdFields, Company>
  airtableId: string | null
}

export const viewOrderIdFields = (x: OrderIdFields): Required<OrderIdFields> => ({
  id: x.id,
  source: x.source,
  direction: x.direction,
  company: x.company,
  airtableId: x.airtableId ?? null,
  darkpool: x.darkpool ?? false,
  orderCollection: x.orderCollection ?? (x.darkpool ? "darkpool" : "platform"),
})

/** TODO MOVE */
const companyIdFieldsSchema = z.object<CompanyIdFields>({
  id: z.string(),
  airtableId: z.string(),
  name: z.string(),
  pbid: z.string().optional(),
  postgresCompanyId: z.string().optional(),
})

/** Todo: fill in the rest of the fields */
export const orderSchema = z
  .object<Order>({
    terms: orderTermsSchema,
    id: z.string(),
    source: orderSourceSchema,
    airtableId: z.string().or(z.null()),
    hidden: z.boolean().or(z.null()).optional(),
    createdAt: z.date(),
    updatedAt: z.date().array(),
    lastUpdatedAt: z.date(),
    direction: z.enum(["buy", "sell"]),
    darkpool: z.boolean(),
    defaultVisibility: z.enum(["platform", "private"]).optional(),
    firmness: z.enum(["firm", "tentative"]).or(z.null()),
    lastDismissedRefreshAt: z.date().or(z.null()).optional(),
    lastViewReportNotificationSentAt: z.date().or(z.null()).optional(),
    forceIncludeInNewOrdersEmail: z.boolean().or(z.null()).optional(),
    isHighlighted: z.boolean().optional(),
    timeSensitive: z.boolean().or(z.null()).optional(),
    linkedPriceObservationId: z.string().or(z.null()).optional(),
    company: companyIdFieldsSchema,
    orderOriginationDate: z.date().or(z.null()),
    orderUpdatedAt: z.date().nonempty(),
    sharedAccountIds: z.string().array().or(z.null()).optional(),
    shareClasses: z.enum(["unknown", "preferred", "common"]).array(),
    shareClassSeries: z.string().or(z.null()),
    _derived: z.object({}),
    _lastReportedStatus: z
      .object({
        asOf: z.date(),
        tag: z.enum(["unknown"]),
      })
      .or(z.null()),
    brokeredBy: z.null(),
    brokerClientMetadata: z.null(),
    outlierStatus: z
      .array(outlierCheckResultSchema)
      .map(makeOutlierStatus)
      .contramap((x) => x?.outlierHistory ?? []),
    _orderStatusHistory: z.array(orderStatusUpdateSchema),
  })
  .map(normalizeOrder)

type OrderPrivateMetadata = { privateNotes?: string | null }
export type OrderBaseData = OrderIdFields &
  DataVisibilityFields & {
    brokerClientMetadata: BrokerClientMetadata | null
    orderOriginationDate: Date | null
    shareClasses: OrderShareClass[]
    shareClassSeries: string | null
    firmness: OrderFirmness | null
    lastUpdatedAt: Date
    lastDismissedRefreshAt?: Date | null
    orderDocuments?: OrderDocuments | null
    commission?: OrderCommission | null
    adminNotes?: Note[] | null
    publicAdminNotes?: Note[] | null
    forceIncludeInNewOrdersEmail?: boolean | null
    lastViewReportNotificationSentAt?: Date | null
    /** @deprecated */
    caplightPromotion?: boolean | null
    /** @deprecated */
    caplightAuctionInterest?: boolean | null
    timeSensitive?: boolean | null
    linkedPriceObservationId?: string | null
    brokeredBy: LinkedBroker | Excluding<UserInvitedBroker, LinkedBroker> | null
    isHighlighted?: boolean
    attribution?: SourceAttribution | null
    layeredStructure?: LayeredStructure | null
    crmContactId?: string | null
    crmInterestId?: string | null
    buyerCashOnHand?: UnconfirmableYesNo | null
  } & OrderStatusFields &
  ViewTracked &
  OrderPrivateMetadata

export const viewOrderBaseData = (order: OrderBaseData): Required<OrderBaseData> => ({
  ...viewOrderIdFields(order),
  hidden: order.hidden ?? false,
  createdAt: order.createdAt,
  outlier: order.outlier ?? null,
  outlierHistory: order.outlierHistory ?? [],
  brokerClientMetadata: order.brokerClientMetadata,
  orderOriginationDate: order.orderOriginationDate,
  shareClasses: order.shareClasses,
  shareClassSeries: order.shareClassSeries ?? null,
  firmness: order.firmness,
  lastUpdatedAt: order.lastUpdatedAt,
  lastDismissedRefreshAt: order.lastDismissedRefreshAt ?? null,
  _lastReportedStatus: order._lastReportedStatus,
  _adminStatusOverride: order._adminStatusOverride ?? null,
  _orderStatusHistory: order._orderStatusHistory ?? null,
  adminNotes: order.adminNotes ?? null,
  publicAdminNotes: order.publicAdminNotes ?? null,
  orderDocuments: order.orderDocuments ?? null,
  commission: order.commission ?? null,
  viewTracking: order.viewTracking ?? null,
  forceIncludeInNewOrdersEmail: order.forceIncludeInNewOrdersEmail ?? null,
  lastViewReportNotificationSentAt: order.lastViewReportNotificationSentAt ?? null,
  caplightPromotion: order.caplightPromotion ?? null,
  caplightAuctionInterest: order.caplightAuctionInterest ?? null,
  timeSensitive: order.timeSensitive ?? null,
  darkpool: !!order.darkpool,
  sharedAccountIds: order.sharedAccountIds ?? null,
  outlierStatus: makeOutlierStatus(order.outlierStatus?.outlierHistory ?? []),
  linkedPriceObservationId: order.linkedPriceObservationId ?? null,
  brokeredBy: order.brokeredBy ?? null,
  isHighlighted: order.isHighlighted ?? false,
  defaultVisibility: order.defaultVisibility ?? "platform",
  attribution: order.attribution ?? null,
  layeredStructure: order.layeredStructure ?? null,
  privateNotes: order.privateNotes ?? null,
  crmContactId: order.crmContactId ?? null,
  crmInterestId: order.crmInterestId ?? null,
  buyerCashOnHand: order.buyerCashOnHand ?? null,
})

/** A bid or offer for some security.
 *
 * Each order consists of some number of terms, each of which represents a particular way that the order can be satisfied.
 * These are grouped first (for the sake of type inference) into various structures, indexed by {@link OrderStructure}, and then into maps (implemented as association lists) between the corresponding {@link StructureOrderTerms[key]}s and an {@link UpdateLog} of {@link OrderQuantityTerms}
 */
export type Order = OrderBaseData &
  RecordInfo & {
    terms: OrderTerms
    orderUpdatedAt: Date[]
  } & Derived<{ liveUntil: Date }> &
  OrderQuotaComplianceFields

export type OrderWithMetaData = Order & {
  metaData?: OrderMetadata
}

export const adminCancelOrder = (o: Order): Order => ({
  ...o,
  ...updateOrderStatusOverride(o, {
    tag: "cancelled",
    asOf: new Date(),
  }),
})

export const adminHideOrder = (o: Order): Order => ({
  ...o,
  hidden: true,
})

export const adminShowOrder = (o: Order): Order => ({
  ...o,
  hidden: false,
})

export const adminResurrectOrder = (o: Order): Order => ({
  ...o,
  ...updateOrderStatusOverride(o, {
    tag: "live",
    asOf: new Date(),
    liveUntil: null,
  }),
})

export const isOrderAccountConnectable = (order: OrderBaseData) =>
  !connectExcludedAccountIds.includes(order.source.account.id) &&
  (order.source.sourceType === "user-form" ||
    !nonFormOrdersConnectExcludedAccountIds.includes(order.source.account.id))

export const isOrderOwnedByAccountId = (order: Order, accountId: string) =>
  accountId === order.source.account.id
export const isOrderUserOwned = (order: Order, user: User | null) =>
  user ? isOrderOwnedByAccountId(order, user.account.id) : true

const isTimeSensitiveOrder = (order: OrderBaseData): boolean => !!order.timeSensitive

export const isOrderConnectable = (order: OrderBaseData) =>
  !isHidden(order) &&
  isOrderAccountConnectable(order) &&
  (isTimeSensitiveOrder(order) ? isLiveOrder(order) : isLiveOrStaleOrder(order)) &&
  !(order.orderCollection === "private")

export const isOrderDirectToClient = (order: OrderBaseData) =>
  order.brokerClientMetadata?.relationship === "direct" ||
  order.source.account.clientType.includes("Investor/Shareholder")

export const isOrderNormalStructure = (o: Order) =>
  o.terms.variable_prepaid_forward.length === 0 &&
  o.terms.collective_liquidity_exchange_fund.length === 0
