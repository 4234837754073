import { useErrorHandler } from "src/providers/errorHandler"
import { useState, useEffect, useCallback, useMemo } from "react"
import { DocumentSnapshot } from "src/firebase/Firebase/utils"
import { Maybe } from "common/containers/Maybe"
import { PriceObservationType } from "common/model/data-product/pricing/PriceObservation"
import { Either, Right, Left } from "common/containers/Either"
import { isDefined } from "common/utils/TypeUtils"
import { makeOutlierStatus } from "common/model/data-product/DataPoint/OutlierStatusFields"
import { MILLION } from "common/utils/math"
import z from "zod"

const requiredPriceObservationFields = [
  "company",
  "createdDate",
  "observationDate",
  "observedBy",
  "price",
  "source",
  "structure",
  "volume",
  "shareClass",
  "rofr",
] as const
export type PriceObservationErrors = Set<(typeof requiredPriceObservationFields)[number]>

const validateClosedTradeVolume = (volume: number): boolean => volume < 500 * MILLION

const isNonDirectStructure = (structure: PriceObservationType["structure"]): boolean =>
  !structure.entries.some(({ key, value }) => key === "direct" && value > 0)

const validRofrParser = z.union([
  z.object({
    // When not subject to ROFR, no answer for wasROFRd
    subjectToROFR: z.literal(false),
    wasROFRd: z.null(),
  } satisfies Record<keyof PriceObservationType["rofr"], unknown>),
  z.object({
    // When subject to ROFR, require answer for wasROFRd
    subjectToROFR: z.literal(true),
    wasROFRd: z.boolean(),
  } satisfies Record<keyof PriceObservationType["rofr"], unknown>),
])

const validateRofrForDirectStructure = (
  structure: PriceObservationType["structure"] | undefined,
  rofr: PriceObservationType["rofr"] | undefined
): boolean =>
  isDefined(structure) &&
  (isNonDirectStructure(structure) || validRofrParser.safeParse(rofr).success)

const validateClosedTrade = (
  partialPriceObservation: Partial<PriceObservationType>
): Either<PriceObservationErrors, PriceObservationType> =>
  partialPriceObservation.company &&
  partialPriceObservation.createdDate &&
  // partialPriceObservation.carriedInterest &&
  // partialPriceObservation.managementFee &&
  partialPriceObservation.observationDate &&
  partialPriceObservation.observedBy &&
  partialPriceObservation.price &&
  partialPriceObservation.source &&
  partialPriceObservation.structure &&
  validateRofrForDirectStructure(partialPriceObservation.structure, partialPriceObservation.rofr) &&
  partialPriceObservation.volume &&
  validateClosedTradeVolume(partialPriceObservation.volume) &&
  partialPriceObservation.shareClass
    ? Right({
        company: partialPriceObservation.company,
        createdDate: partialPriceObservation.createdDate,
        observationDate: partialPriceObservation.observationDate,
        observedBy: partialPriceObservation.observedBy,
        price: partialPriceObservation.price,
        source: partialPriceObservation.source,
        structure: partialPriceObservation.structure,
        volume: partialPriceObservation.volume,
        shareClass: partialPriceObservation.shareClass,
        // nullable
        hidden: partialPriceObservation.hidden,
        outlier: partialPriceObservation.outlier ?? null,
        outlierStatus: makeOutlierStatus(
          partialPriceObservation.outlierStatus?.outlierHistory ?? []
        ),
        shareClassSeries: partialPriceObservation.shareClassSeries,
        managementFee: partialPriceObservation.managementFee ?? null,
        carriedInterest: partialPriceObservation.carriedInterest ?? false,
        notes: partialPriceObservation.notes,
        layeredStructure: partialPriceObservation.layeredStructure,
        rofr: partialPriceObservation.rofr ?? null,
      })
    : Left(
        new Set(
          requiredPriceObservationFields.filter(
            (key) =>
              !isDefined(partialPriceObservation[key]) ||
              (key === "volume" &&
                partialPriceObservation.volume &&
                !validateClosedTradeVolume(partialPriceObservation.volume)) ||
              (key === "rofr" &&
                !validateRofrForDirectStructure(
                  partialPriceObservation.structure,
                  partialPriceObservation.rofr
                ))
          )
        )
      )

export const useClosedTradeFormSaving = (
  priceObservation: Partial<PriceObservationType>,
  onSave: (o: PriceObservationType) => Promise<Maybe<DocumentSnapshot<PriceObservationType>>>,
  deps: unknown[]
) => {
  const [closedTradeSaving, setClosedTradeSaving] = useState(false)
  const [returnedClosedTrade, setReturnedClosedTrade] = useState<PriceObservationType | null>(null)
  const { handleError } = useErrorHandler()

  const [formErrors, setFormErrors] = useState<PriceObservationErrors>(new Set())
  const [realTimeFormErrors, setRealTimeFormErrors] = useState<PriceObservationErrors>(new Set())
  const [saveError, setSaveError] = useState(false)

  useEffect(() => {
    // removes errors as user completes form, but doesn't add any
    validateClosedTrade(priceObservation).match(
      (newErrs) => {
        setFormErrors((prevErrs) => new Set([...newErrs].filter((x) => prevErrs.has(x))))
        setRealTimeFormErrors(newErrs)
      },
      () => {
        setFormErrors(new Set())
        setRealTimeFormErrors(new Set())
      }
    )
  }, [priceObservation])

  const validateAndSave = useCallback(
    () =>
      validateClosedTrade(priceObservation).match(setFormErrors, (o) => {
        setClosedTradeSaving(true)
        onSave(o)
          .then((closedTradeDoc) =>
            closedTradeDoc.match(
              (doc) => setReturnedClosedTrade(doc.data() ?? null),
              () => setSaveError(true)
            )
          )
          .catch((e: Error) => {
            setSaveError(true)
            handleError(e)
          })
          .finally(() => setClosedTradeSaving(false))
      }),
    [priceObservation, handleError, onSave]
  )

  const saveStatus = useMemo(() => {
    if (realTimeFormErrors.size) return "disabled"
    if (closedTradeSaving) return "saving"
    if (saveError) return "error"
    if (returnedClosedTrade) return "saved"
    return "pending"
  }, [closedTradeSaving, returnedClosedTrade, saveError, realTimeFormErrors])

  // reset closedTrade steps on dependencies change
  useEffect(() => {
    setClosedTradeSaving(false)
    setReturnedClosedTrade(null)
    setFormErrors(new Set())
    setSaveError(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  return { saveStatus, validateAndSave, formErrors, savedClosedTrade: returnedClosedTrade }
}
