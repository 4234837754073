import { matchLoading } from "common/utils/Loading"
import { useFeatureFlag } from "src/providers/featureFlags/useFeatureFlags"
import { useCurrentAccount } from "../queries/currentUser/useCurrentAccount"
import { useMemo } from "react"
import { isIntermediaryAccount, isInvestorShareholderAccount } from "common/model/Account"

export const useAccountBrokerLinking = () => {
  const account = useCurrentAccount()
  const isBrokerLinkingFeatureFlag = useFeatureFlag("linked_broker")
  const isAccountBrokerLinkingEnabled = useMemo(
    () =>
      isBrokerLinkingFeatureFlag &&
      matchLoading(
        account,
        (a) => isIntermediaryAccount(a) || !!a.isBrokerLinkingEnabled,
        false,
        false
      ),
    [account, isBrokerLinkingFeatureFlag]
  )

  const isLinkedBroker = useMemo(
    () => isBrokerLinkingFeatureFlag && matchLoading(account, isIntermediaryAccount, false, false),
    [account, isBrokerLinkingFeatureFlag]
  )

  const isBrokerLinkingClient = useMemo(
    () =>
      isBrokerLinkingFeatureFlag &&
      matchLoading(account, isInvestorShareholderAccount, false, false) &&
      matchLoading(account, (a) => !!a.isBrokerLinkingEnabled, false, false),
    [account, isBrokerLinkingFeatureFlag]
  )

  return { isAccountBrokerLinkingEnabled, isLinkedBroker, isBrokerLinkingClient }
}
