import { DocumentData } from "@firebase/firestore-types"
import { getMonth, getYear } from "date-fns"
import { subMonths } from "date-fns"
import * as _ from "lodash"
import * as datefns from "date-fns"
import { collections, restrictedCollections } from "../../firestore/Collections"
import { firestoreConverter } from "../../model/FirestoreConverter"
import { PriceObservationType } from "../../model/data-product/pricing/PriceObservation"
import { plus } from "../../utils/data/numeric/Arithmetic"
import { VirtualQuery } from "../VirtualQuery"
import { FirebaseCommon } from "../../firestore/Interface"
import { DocumentSubmission } from "../../model/files/DocumentSubmission"
import { Unproxy } from "../../utils/types/Proxy"
import { Account, AccountIdFields } from "../../model/Account"
import { UnsafeRec } from "../../utils/RecordUtils"
import { Date_ } from "../../utils/dateUtils"
import { Order } from "../../model/Order/Models/Internal"

const dataAccounts: VirtualQuery<FirebaseCommon.DB, Account> = VirtualQuery.source((db) =>
  db
    .collection(collections.accounts)
    .withConverter<Account>(firestoreConverter<Account>())
    .orderBy("dataContributionFrequency", "desc")
    .get()
    .then((docs) => docs.docs.map((doc) => doc.data()).flatMap((doc) => (doc ? [doc] : [])))
)
const platformTrades: VirtualQuery<FirebaseCommon.DB, PriceObservationType> = VirtualQuery.source(
  (db) =>
    db
      .collectionGroup(restrictedCollections.companySubcollections.priceObservations)
      .withConverter<PriceObservationType>(firestoreConverter<PriceObservationType>())
      .get()
      .then((docs) => docs.docs.map((doc) => doc.data()).flatMap((doc) => (doc ? [doc] : [])))
)
const platformOrders: VirtualQuery<FirebaseCommon.DB, Order> = VirtualQuery.source((db) =>
  db
    .collection(restrictedCollections.orderObservations)
    .withConverter<Order>(firestoreConverter<Order>())
    .get()
    .then((docs) => docs.docs.map((doc) => doc.data()).flatMap((doc) => (doc ? [doc] : [])))
)
const darkpoolOrders: VirtualQuery<FirebaseCommon.DB, Order> = VirtualQuery.source((db) =>
  db
    .collection(restrictedCollections.darkpoolOrderObservations)
    .withConverter<Order>(firestoreConverter<Order>())
    .get()
    .then((docs) => docs.docs.map((doc) => doc.data()).flatMap((doc) => (doc ? [doc] : [])))
)
const documentSubmissions: VirtualQuery<
  FirebaseCommon.DB,
  DocumentSubmission & { account: Account }
> = VirtualQuery.source((db) => {
  const allAccounts = db
    .collection(collections.accounts)
    .withConverter<Account>(firestoreConverter<Account>())
    .get()
    .then((docs) => docs.docs.map((doc) => doc.data()).flatMap((doc) => (doc ? [doc] : [])))

  const submissions = db
    .collection(collections.documentSubmissions)
    .withConverter<DocumentSubmission>(firestoreConverter<DocumentSubmission>())
    .get()
    .then((docs) => docs.docs.map((doc) => doc.data()).flatMap((doc) => (doc ? [doc] : [])))

  const submissionsWithFullAccountData = Promise.all([allAccounts, submissions]).then(
    ([accounts, docSubmissions]) =>
      docSubmissions.map((submission) => {
        const account = accounts.find((acc) => acc.id === submission.account.id)
        if (!account)
          throw new Error(
            `Account ${submission.account.id} not found for doc submission ${submission.id}`
          )
        return {
          ...submission,
          account,
        }
      })
  )
  return submissionsWithFullAccountData
})
// bad one-off patch, do not export
const sanitizeNumberInput = (x: number | string) =>
  typeof x === "string" ? Number.parseFloat(x) : x

const dataSubmissionsByAccount = documentSubmissions
  .filter((doc) => doc.document.documentType === "data-submission")
  .filter((doc) => doc.status !== "failure" && doc.status !== "cancelled")
  .groupBy(
    (doc) => doc.account.id,
    (docs) => ({
      account: docs[0].account.name,
      lastSubmissionDate: new Date(Math.max(0, ...docs.map((doc) => doc.eventDate.valueOf()))),
      lastNoSubmissionDate: new Date(
        Math.max(
          0,
          ...(docs[0].account.noDataProductSubmissionPeriods?.["data-submission"]?.map((x) =>
            x.upperBound.valueOf()
          ) ?? [])
        )
      ),
      lastMonthSubmissions: docs.filter((doc) => doc.eventDate > subMonths(new Date(), 1)).length,
      last3monthsSubmissions: docs.filter((doc) => doc.eventDate > subMonths(new Date(), 3)).length,
      last6monthsSubmissions: docs.filter((doc) => doc.eventDate > subMonths(new Date(), 6)).length,
      totalSubmissions: docs.length,
      totalNoSubmissions:
        docs[0].account?.noDataProductSubmissionPeriods?.["data-submission"]?.length ?? 0,
    })
  )

const tradeCountIntervals = [1, 7, 30, 90, 365] as const

const getSubmissionCounts = <S, T>(
  x: VirtualQuery<S, T>,
  rowName: string,
  getDate: (t: T) => Date
) =>
  x.groupBy(
    () => null,
    (ts) => ({
      submissionType: rowName,
      ...tradeCountIntervals
        .map((i) => ({
          [`last ${i} days`]: ts.filter((t) => datefns.differenceInDays(new Date(), getDate(t)) < i)
            .length,
        }))
        .reduce((l, r) => ({ ...l, ...r }), {}),
      total: ts.length,
    })
  )

const caplightAccountNames = ["Marc's Test Firm", "Caplight"] // replace with email
const isCaplightAccount = (a: AccountIdFields): boolean => caplightAccountNames.includes(a.name)
const totalPlatformTrades = getSubmissionCounts(platformTrades, "trades", (t) => t.createdDate)
const totalCaplightInvolvedTrades = getSubmissionCounts(
  platformTrades.filter((t) => isCaplightAccount(t.observedBy)),
  "caplight involved trades",
  (t) => t.createdDate
)
const totalPlatformOrders = getSubmissionCounts(platformOrders, "orders", (t) => t.createdAt)
const totalDarkpoolOrders = getSubmissionCounts(darkpoolOrders, "darkpool", (t) => t.createdAt)

/** We want to infer view return types from this object, but if it's exported directly without a signature ts ends up inferring an overly narrow type in `latestMaterializedView`.
 * To get around this we derive the return types in this module, and then export an annotated `tradeDashboardViews` instead.
 */
const internalTradeDashboardViews = {
  submissionTotals: totalPlatformTrades.union([
    totalCaplightInvolvedTrades,
    totalPlatformOrders,
    totalDarkpoolOrders,
  ]),
  accountDelinquencyStatus: dataAccounts
    .leftOuterJoin(
      dataSubmissionsByAccount.map((r) => ({
        accountName: r.account,
        lastSubmissionDate: r.lastSubmissionDate,
      })),
      (a, r) => a.name === r.accountName,
      (a) => a.id
    )
    .map((a) => ({
      account: a.name,
      contributorEmails: (a.dataContributors ?? []).flatMap((u) => u.email).join(" ; "),
      contributionFrequency: a.dataContributionFrequency,
      daysSinceLastTradeSubmission: datefns.differenceInDays(
        new Date(),
        _.maxBy(UnsafeRec.values(a.lastDataContributions ?? {}), (d) => d?.valueOf() ?? 0) ??
          Date_.minValue
      ),
      daysSinceLastOrderCSVSubmission: datefns.differenceInDays(
        new Date(),
        a.lastSubmissionDate ?? Date_.minValue
      ),
    })),
  tradesByAccount: platformTrades.groupBy(
    (t) => t.observedBy.id,
    (ts) => ({
      account: ts[0].observedBy.name,
      lastTradeDate: new Date(
        Math.max(...ts.map((doc) => doc.observationDate.lowerBound.valueOf()))
      ),
      lastMonthCount: ts.filter((t) => t.observationDate.lowerBound > subMonths(new Date(), 1))
        .length,
      lastMonthVolume: ts
        .filter((t) => t.observationDate.lowerBound > subMonths(new Date(), 1))
        .map((t) => sanitizeNumberInput(t.volume))
        .reduce(plus, 0),
      last3MonthsCount: ts.filter((t) => t.observationDate.lowerBound > subMonths(new Date(), 3))
        .length,
      last3MonthsVolume: ts
        .filter((t) => t.observationDate.lowerBound > subMonths(new Date(), 3))
        .map((t) => sanitizeNumberInput(t.volume))
        .reduce(plus, 0),
      last6MonthsCount: ts.filter((t) => t.observationDate.lowerBound > subMonths(new Date(), 6))
        .length,
      last6MonthsVolume: ts
        .filter((t) => t.observationDate.lowerBound > subMonths(new Date(), 6))
        .map((t) => sanitizeNumberInput(t.volume))
        .reduce(plus, 0),
      totalCount: ts.length,
      totalVolume: ts.map((t) => sanitizeNumberInput(t.volume)).reduce(plus, 0),
    })
  ),
  dataSubmissionsByAccount,
  tradesByAccountAndMonth: platformTrades.groupBy(
    (t) => [
      t.observedBy.id,
      getMonth(t.observationDate.lowerBound),
      getYear(t.observationDate.lowerBound),
    ],
    (ts) => ({
      account: ts[0].observedBy.name,
      month: getMonth(ts[0].observationDate.lowerBound),
      year: getYear(ts[0].observationDate.lowerBound),
      count: ts.length,
      volume: ts.map((t) => sanitizeNumberInput(t.volume)).reduce(plus, 0),
    })
  ),
} satisfies Record<
  string,
  {
    run: (source: FirebaseCommon.DB) => Promise<DocumentData[]>
  }
>

/** Queries added to this object will automatically be added as tabs in the admin data dashboard */
export const tradeDashboardViews: Record<
  TradeDashboardView,
  {
    run: (source: FirebaseCommon.DB) => Promise<DocumentData[]>
  }
> = internalTradeDashboardViews

export type TradeDashboardView = keyof typeof internalTradeDashboardViews
export type TradeDashboardViewType<T extends TradeDashboardView> = Unproxy<
  (typeof internalTradeDashboardViews)[T]["returnType"]
>
