import { collections, restrictedCollections } from "common/firestore/Collections"
import { User, UserIdFields } from "common/model/User"
import { Just, Nothing } from "common/containers/Maybe"
import { nestedUndefinedsToEmptyObjects, undefinedsToNulls } from "common/utils/ObjectUtils"
import { Excluding } from "common/utils/TypeUtils"
import { notification } from "@stories/components/Antd"
import { PriceObservationType } from "common/model/data-product/pricing/PriceObservation"
import { FirebaseWriter } from "./Firebase"
import { trackEvent } from "../utils/Tracking"
import { firestoreConverter } from "../model/FirestoreConverter"

export const addPriceObservation = async (
  priceObservation: PriceObservationType,
  creator: Excluding<UserIdFields, User>,
  db: FirebaseWriter
) => {
  const priceObservationRef = db.writerDb
    .collection(collections.companies)
    .doc(priceObservation.company.id)
    .collection(restrictedCollections.companySubcollections.priceObservations)
    .withConverter<PriceObservationType>(firestoreConverter<PriceObservationType>())
    .doc()

  const batch = db.writerDb.batch()
  batch.set(
    priceObservationRef,
    nestedUndefinedsToEmptyObjects(undefinedsToNulls(priceObservation))
  )

  await batch.commit()
  notification.success({
    message: `${priceObservation.company.name} closed trade submitted.`,
    placement: "top",
  })
  trackEvent("trade-submitted", {
    companyId: priceObservation.company?.id,
    companyName: priceObservation.company?.name,
  })

  return priceObservationRef.get().then((doc) => (doc.data() ? Just(doc) : Nothing))
}
