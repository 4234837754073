import { OrderPageOrder } from "../types"
import { useLoggedInUser } from "../../../../providers/loggedInUser/useLoggedInUser"
import { useAccountBrokerLinking } from "src/utils/useAccountBrokerLinking"
import Alert from "@stories/components/Alert/Alert"
import Typography, { Color, Size } from "@stories/components/Typography/Typography"
import { ViewIcon } from "@stories/icons/ViewIcon"

const LoadedOrderBrokerLinkingWarning = ({ order }: { order: OrderPageOrder }) => {
  const { user } = useLoggedInUser()
  const { isAccountBrokerLinkingEnabled, isLinkedBroker } = useAccountBrokerLinking()
  const sourceAccountName = order.unboxed.order.base.source.account.name
  const brokerName = order.brokeredBy()?.fullName

  return isLinkedBroker && order.isBrokeredByUser(user) ? (
    <Alert
      variant="default"
      headline={`Submitted by: ${sourceAccountName}`}
      message={
        <div>
          <Typography
            text={`This order was placed by ${sourceAccountName} and linked to you as their preferred broker for the order. You may manage the order on their behalf.`}
          />
          <div className="flex items-center space-x-1 mt-2">
            <Typography size={Size.XXSmall} color={Color.Subtitle} text={<ViewIcon />} />
            <Typography text="Only Visible to You" color={Color.Subtitle} size={Size.XXSmall} />
          </div>
        </div>
      }
    />
  ) : isAccountBrokerLinkingEnabled && !!brokerName && order.fromAccount(user.account) ? (
    <Alert
      variant="default"
      headline={`Brokered By: ${brokerName}`}
      message={
        <div>
          <Typography
            text={`${brokerName} is able to view and manage this order on your behalf.`}
          />
          <div className="flex items-center space-x-1 mt-2">
            <Typography size={Size.XXSmall} color={Color.Subtitle} text={<ViewIcon />} />
            <Typography text="Only Visible to You" color={Color.Subtitle} size={Size.XXSmall} />
          </div>
        </div>
      }
    />
  ) : null
}

export default LoadedOrderBrokerLinkingWarning
