import { classNames } from "src/utils/classNames"

const SectionLabel = ({
  children,
  hasError,
}: {
  children: React.ReactNode
  hasError?: boolean
}): JSX.Element => (
  <div
    className={classNames(
      "w-full font-semibold text-lg mb-4",
      hasError ? "text-danger-600" : "text-primary-800"
    )}
  >
    {children}
  </div>
)

export default SectionLabel
