import { wrapIcon } from "./IconWrapper"

export const ClosedTradeIcon = wrapIcon(
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.52658 6.19334L6.33325 8.39334L5.47325 7.52667C5.34772 7.40114 5.17745 7.33061 4.99992 7.33061C4.82238 7.33061 4.65212 7.40114 4.52659 7.52667C4.40105 7.65221 4.33052 7.82247 4.33052 8C4.33052 8.17754 4.40105 8.3478 4.52659 8.47334L5.85992 9.80667C5.92189 9.86916 5.99563 9.91875 6.07687 9.9526C6.15811 9.98645 6.24524 10.0039 6.33325 10.0039C6.42126 10.0039 6.5084 9.98645 6.58964 9.9526C6.67088 9.91875 6.74461 9.86916 6.80658 9.80667L9.47325 7.14C9.59879 7.01447 9.66931 6.84421 9.66931 6.66667C9.66931 6.48914 9.59879 6.31887 9.47325 6.19334C9.34772 6.0678 9.17745 5.99728 8.99992 5.99728C8.82238 5.99728 8.65212 6.0678 8.52658 6.19334ZM11.6666 2.66667H7.47992L7.26658 2C7.12828 1.60882 6.87175 1.27034 6.53252 1.03145C6.19329 0.79255 5.78816 0.665072 5.37325 0.666671H2.33325C1.80282 0.666671 1.29411 0.877385 0.919038 1.25246C0.543966 1.62753 0.333252 2.13624 0.333252 2.66667V11.3333C0.333252 11.8638 0.543966 12.3725 0.919038 12.7476C1.29411 13.1226 1.80282 13.3333 2.33325 13.3333H11.6666C12.197 13.3333 12.7057 13.1226 13.0808 12.7476C13.4559 12.3725 13.6666 11.8638 13.6666 11.3333V4.66667C13.6666 4.13624 13.4559 3.62753 13.0808 3.25246C12.7057 2.87739 12.197 2.66667 11.6666 2.66667ZM12.3333 11.3333C12.3333 11.5101 12.263 11.6797 12.138 11.8047C12.013 11.9298 11.8434 12 11.6666 12H2.33325C2.15644 12 1.98687 11.9298 1.86185 11.8047C1.73682 11.6797 1.66659 11.5101 1.66659 11.3333V2.66667C1.66659 2.48986 1.73682 2.32029 1.86185 2.19527C1.98687 2.07024 2.15644 2 2.33325 2H5.37325C5.51301 1.99964 5.64935 2.04322 5.763 2.12457C5.87665 2.20591 5.96186 2.32092 6.00659 2.45334L6.36659 3.54667C6.41131 3.67909 6.49652 3.79409 6.61017 3.87544C6.72381 3.95679 6.86016 4.00037 6.99992 4H11.6666C11.8434 4 12.013 4.07024 12.138 4.19527C12.263 4.32029 12.3333 4.48986 12.3333 4.66667V11.3333Z"
      fill="white"
    />
  </svg>
)
