import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"

export const createContactSubtitle = (contact: DealCRMContact | undefined): string | null => {
  if (!contact) {
    return null
  }

  switch (contact.tag) {
    case "individual":
      if (contact.firm) {
        return contact.firm.name
      }
      if (contact.classification === "investor") {
        return "Individual Investor"
      }
      return null
    case "firm": {
      const primaryContact = contact.contacts.find((c) => c.isPrimaryContact)
      return primaryContact ? `Primary Contact: ${primaryContact.name}` : null
    }
    case "broker":
      if (contact.firm?.isBrokerage && contact.firm.name) {
        return `Brokerage: ${contact.firm.name}`
      }
      return null
    default:
      return null
  }
}
