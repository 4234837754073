import moment from "moment"
import { CompanyIdFields } from "../../../model/Company"
import { splitPricePerShareFromValuationInformation } from "../../../model/data-product/ppsCalculation/PricePerShare"
import { Just, Nothing } from "../../../containers/Maybe"
import { PricingDataRoot } from "../../pricing/PricingDataSource"
import { Enriching } from "../../pricing/PricingEnrichment"

export const enrichWithImpliedPPS =
  (dataSource: PricingDataRoot) =>
  (companyIdFields: CompanyIdFields, date: Date): Enriching<"priceDisplay", "impliedPPSDisplay"> =>
  async (t) => {
    const company = await dataSource.company(companyIdFields.id).get(null, moment(date))
    return {
      ...t,
      impliedPPSDisplay: t.priceDisplay
        .match(Just, () => Nothing)
        .bind((valuationDisplayData) =>
          splitPricePerShareFromValuationInformation(
            {
              valuation: valuationDisplayData.valuation,
              valuationDate: valuationDisplayData.displayDate,
            },
            company,
            new Date()
          ).map(({ pricePerShare }) => ({
            pricePerShare,
            displayDate: valuationDisplayData.displayDate,
          }))
        ),
    }
  }
