import { Skeleton } from "@stories/components/Antd"
import { classNames } from "../../utils/classNames"

interface SkeletonProps {
  numRows: number
  className?: string
}

const SkeletonLoader: React.FunctionComponent<React.PropsWithChildren<SkeletonProps>> = ({
  numRows,
  className,
}) => {
  const loader = <Skeleton active paragraph={{ rows: numRows || 4 }} />
  return (
    <div className={classNames("flex justify-center items-center w-full", className)}>{loader}</div>
  )
}

export default SkeletonLoader
