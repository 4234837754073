import { ConstructedOrderInquiry } from "./Constructed"
import { OrderInquiry, OrderInquirySource, OrderInquiryUserForm } from "./Db"

export const constructOrderInquiry = ({
  source,
  userForm,
}: OrderInquirySource & OrderInquiryUserForm): ConstructedOrderInquiry => ({
  userForm,
  source,
})

export const createDbOrderInquiry = ({
  id,
  constructedInquiry,
}: {
  id: string
  constructedInquiry: ConstructedOrderInquiry
}): OrderInquiry => ({
  ...constructedInquiry,
  id,
  createdAt: new Date(),
  updatedAt: new Date(),
  adminMetadata: {
    adminNotes: null,
    adminStatusHistory: [
      {
        tag: "pending",
        asOf: new Date(),
      },
    ],
  },
})

export const updateDbOrderInquiry = <UpdatedKeys extends keyof OrderInquiry>({
  original,
  updates,
}: {
  original: OrderInquiry
  updates: Pick<OrderInquiry, UpdatedKeys>
}): OrderInquiry => ({ ...original, updatedAt: new Date(), ...updates })
