import { UnconfirmableYesNoSelect } from "@components/lib/Select/BooleanSelect"
import { Input } from "@stories/components/Inputs/Input/Input"
import { OrderCommission } from "common/model/Order/Order"
import { isIntermediaryContext, useAuthContext } from "src/Routes/auth/UserAuthContext"

const defaultBrokerCommission: OrderCommission = {
  priceType: "gross",
}

export type OrderCommissionProps = {
  value: { commission?: OrderCommission | null }
  onChange: (value: { commission: OrderCommission | null }) => void
  disabled?: boolean
  titles: {
    canChargeClientFee: string
    yesCanChargeFeeOption: string
    noCanChargeFeeOption: string
    unknownCanChargeFeeOption: string
    commissionNotesLabel: string
    commissionNotesPlaceholder: string
    commissionNotesHintText: string
  }
}
export function CommissionSection({ value, onChange, titles }: OrderCommissionProps) {
  const nonNullValue: OrderCommission = value.commission ?? defaultBrokerCommission
  return isIntermediaryContext(useAuthContext()) ? (
    <div className="flex flex-col gap-4 ">
      <div className="max-w-150">
        <UnconfirmableYesNoSelect
          label={titles.canChargeClientFee}
          value={value.commission?.canChargeClientFee}
          onChange={(canChargeClientFee) =>
            onChange({ commission: { ...nonNullValue, canChargeClientFee } })
          }
          options={[
            {
              value: "Yes",
              label: titles.yesCanChargeFeeOption,
            },
            {
              value: "No",
              label: titles.noCanChargeFeeOption,
            },
            {
              value: "Unknown",
              label: titles.unknownCanChargeFeeOption,
            },
          ]}
        />
      </div>
      <Input
        onChange={(e) =>
          onChange({ commission: { ...nonNullValue, commissionNotes: e.target.value } })
        }
        value={value.commission?.commissionNotes ?? ""}
        label={titles.commissionNotesLabel}
        placeholder={titles.commissionNotesPlaceholder}
        hintText={titles.commissionNotesHintText}
      />
    </div>
  ) : null
}
