import { Trade } from "common/model/data-product/Trade"
import { PriceObservationType } from "common/model/data-product/pricing/PriceObservation"
import { shortDateFormat } from "common/utils/dateUtils"
import { Link } from "react-router-dom"

const priceObservationRoute = (o: PriceObservationType) =>
  `/admin/records/companies/${o.company.id}/price_observations/${o.id ?? "MISSING ID"}`
const tradeRoute = (o: Trade) => `/admin/records/trades/${o.id ?? "MISSING ID"}`
const orderRoute = (o: { id: string }) =>
  `/admin/records/order_observations/${o.id ?? "MISSING ID"}`

const recordLinkClassName = "hover:underline text-accent-300"
export const PriceObservationLink = ({ obs: x }: { obs: PriceObservationType }) => {
  const linkText = `$${x.volume} @ $${x.price.toString()}pps ${
    x.observedBy.name
  } trade reported at ${shortDateFormat(x.createdDate)}`
  return (
    <Link className={recordLinkClassName} to={priceObservationRoute(x)}>
      {linkText}
    </Link>
  )
}

export const TradeLink = ({ trade }: { trade: Trade }) => (
  <Link className={recordLinkClassName} to={tradeRoute(trade)}>
    {trade.id}
  </Link>
)
export const OrderLink = ({ order }: { order: { id: string } }) => (
  <Link className={recordLinkClassName} to={orderRoute(order)}>
    {order.id}
  </Link>
)
