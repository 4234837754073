import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { useNoteModal } from "../NoteModalProvider"
import { XIcon } from "@stories/icons/XIcon"
import { Button } from "@stories/components/Button/Button"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { Tooltip } from "@stories/components/Antd"

export const NoteModalHeader = () => {
  const { onClose } = useNoteModal()
  const {
    user: {
      productInteractionHistory: { firstDraggedNoteModal },
    },
  } = useLoggedInUser()

  return (
    <Tooltip
      title={!firstDraggedNoteModal ? "Try dragging" : undefined}
      defaultOpen
      placement="top"
    >
      <div className="cursor-move p-2 flex items-center justify-between bg-neutral-300">
        <Typography
          text="New Note"
          size={Size.XSmall}
          color={Color.Neutral1000}
          weight={Weight.Semibold}
        />
        <Button rightIcon={<XIcon />} variant="icon" onClick={onClose} />
      </div>
    </Tooltip>
  )
}
