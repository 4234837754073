import { LogoSize } from "common/model/Company"

// TailWind Specific Type Definitions
export type Direction = "row" | "col"
export type Size = "sm" | "md" | "lg" | "xl"

export type Border = boolean
export type Alignment = "center" | "end" | "start" | "baseline" | "stretch"
export type Justify = "center" | "between" | "around" | "evenly" | "end" | "start"
export type Position = "static" | "fixed" | "absolute" | "relative" | "sticky"

// Constant definitions for to make updating logic related to sizing easy in the future
export const SIZE_XL = "w-full"
export const SIZE_LG = "xl:w-10/12 w-full"
export const SIZE_MD = "xl:w-8/12 w-10/12"
export const SIZE_SM = "xl:w-1/2 w-10/12"

export const sizeToTextSize: Record<"base" | Size | LogoSize, string> = {
  base: "base",
  xxs: "text-xxs",
  xs: "text-xs",
  sm: "text-sm",
  md: "text-md",
  lg: "text-lg",
  xl: "text-xl",
}
