import { restrictedCollections } from "common/firestore/Collections"
import {
  adminCancelOrder,
  adminHideOrder,
  adminResurrectOrder,
  adminShowOrder,
  Order,
} from "common/model/Order/Models/Internal"
import { isLiveOrder, isLiveOrStaleOrder } from "common/model/Order/Types/Status"
import { isLoaded } from "common/utils/Loading"
import { useFirebaseAdmin } from "src/firebase/Context"
import Firebase from "src/firebase/Firebase"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import { CurrentUser } from "../../../model/CurrentUser"
import { firestoreConverter } from "../../../model/FirestoreConverter"
import { updateDocumentLogged } from "../RecordInspectorCRUD"
import { AsyncDispatchButton } from "@stories/components/Button/AsyncDispatchButton"
import { assertUnreachable } from "common/utils/fp/Function"

const adminStatusControlOrderCollection = (o: Order) => {
  if (o.orderCollection === "darkpool" || o.darkpool) {
    return restrictedCollections.darkpoolOrderObservations
  }
  if (o.orderCollection === "platform" || o.orderCollection === undefined) {
    return restrictedCollections.orderObservations
  }
  if (o.orderCollection === "private" || o.orderCollection === "tentativeInterest") {
    throw new Error(
      "adminStatusControlOrderCollection was used with unimplemented order collection"
    )
  }
  return assertUnreachable(o.orderCollection)
}

const updateOrderLogged = (
  db: Firebase,
  user: CurrentUser,
  reason: string,
  updatedOrder: Order
) => {
  const ref = db.adminDb
    .collection(adminStatusControlOrderCollection(updatedOrder))
    .withConverter<Order>(firestoreConverter<Order>())
    .doc(updatedOrder.id)
  return updateDocumentLogged(db, user, reason, ref, updatedOrder)
}

const markOrderAsCancelled = (db: Firebase, user: CurrentUser, o: Order) =>
  updateOrderLogged(db, user, "Cancelled", adminCancelOrder(o))

const markOrderAsLive = async (db: Firebase, user: CurrentUser, o: Order) =>
  updateOrderLogged(db, user, "Uncancelled", adminResurrectOrder(o))

const markOrderAsHidden = (db: Firebase, user: CurrentUser, o: Order) =>
  updateOrderLogged(db, user, "Hidden", adminHideOrder(o))

const markOrderAsNotHidden = (db: Firebase, user: CurrentUser, o: Order) =>
  updateOrderLogged(db, user, "Not Hidden", adminShowOrder(o))

export const CancelOrderButton = ({ order }: { order: Order }) => {
  const db = useFirebaseAdmin()
  const user = useCurrentUser()
  return isLoaded(user) ? (
    <AsyncDispatchButton
      label="Cancel Order"
      isDisabled={!isLiveOrStaleOrder(order)}
      onClick={() => markOrderAsCancelled(db, user, order)}
    />
  ) : null
}
export const ResurrectOrderButton = ({ order }: { order: Order }) => {
  const db = useFirebaseAdmin()
  const user = useCurrentUser()
  return isLoaded(user) ? (
    <AsyncDispatchButton
      label="Resurrect Order"
      isDisabled={isLiveOrder(order)}
      onClick={() => markOrderAsLive(db, user, order)}
    />
  ) : null
}

export const HideOrderButton = ({ order }: { order: Order }) => {
  const db = useFirebaseAdmin()
  const user = useCurrentUser()
  if (!isLoaded(user)) {
    return null
  }

  return order.hidden ? (
    <AsyncDispatchButton
      label="Un-Hide Order"
      onClick={() => markOrderAsNotHidden(db, user, order)}
    />
  ) : (
    <AsyncDispatchButton label="Hide Order" onClick={() => markOrderAsHidden(db, user, order)} />
  )
}
