import { AccountDataFields, viewAccountDataFields } from "common/model/Account"
import { viewCompanyIdFields } from "common/model/Company"
import { flattenOrderSlice, Order, orderLatestTerms } from "common/model/Order/Order"
import { head } from "common/utils/data/Array/ArrayUtils"
import ClosedTradeButton, { ClosedTradeButtonProps } from "./ClosedTradeButton"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { viewUserIdFields } from "common/model/User"

export const CreateClosedTradeFromOrderButton = ({
  order,
  account,
  ...props
}: { order: Order; account: AccountDataFields } & Omit<
  ClosedTradeButtonProps,
  "initialClosedTrade"
>) => {
  const terms = flattenOrderSlice(orderLatestTerms(order)).toNullable()
  const user = useLoggedInUser()
  return (
    <ClosedTradeButton
      initialClosedTrade={{
        createdDate: new Date(),
        company: viewCompanyIdFields(order.company),
        observedBy: viewAccountDataFields(account),
        source: {
          sourceType: "user-form",
          submittingUser: viewUserIdFields(user.user),
          sourceId: null,
          documentUpload: null,
        },
        shareClass: head(order.shareClasses).toNullable(),
        shareClassSeries: order.shareClassSeries,
        structure: terms
          ? {
              defaultValue: 0,
              entries: [
                {
                  value: 0,
                  key: terms.structure,
                },
              ],
            }
          : undefined,
        carriedInterest: terms?.carry ?? undefined,
        managementFee: terms?.managementFee ?? undefined,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
}
