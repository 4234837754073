/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { SwitchVerticalIcon } from "@heroicons/react/solid"
import DownChevronIcon from "@stories/icons/DownChevron"
import { HelpIcon } from "@stories/icons/HelpIcon"
import UpChevronIcon from "@stories/icons/UpChevronIcon"
import { Table } from "@tanstack/react-table"
import { ColumnMeta } from "@tanstack/table-core/build/lib/types"
import Typography, { Color, Size, Weight } from "../Typography/Typography"
import { renderWithTypography } from "./helpers"
import { HoveredColumnProps } from "./types"
import { classNames } from "src/utils/classNames"

type TableHeaderRowProps<T extends object> = {
  table: Table<T>
  isFirstColumnSticky: boolean
  isTableColumnsFixedWidth?: boolean
} & HoveredColumnProps

export const TableHeaderRow = <T extends object>({
  table,
  isFirstColumnSticky,
  isTableColumnsFixedWidth,
  hoveredColumn,
  setHoveredColumn,
}: TableHeaderRowProps<T>) => {
  const indexesOfStartOfGroupColumn = table.getHeaderGroups()[0].headers.reduce(
    (
      acc: {
        indexes: number[]
        lastHeader: string | null | undefined
        pointer: number
      },
      header,
      index
    ) => {
      const isInGroup = header.subHeaders.length > 1
      const isStartOfGroup = isInGroup && header.id !== acc.lastHeader

      if (isStartOfGroup) {
        const newIndex = index + acc.pointer
        return {
          indexes: [...acc.indexes, newIndex],
          lastHeader: header.id,
          pointer: acc.pointer + header.colSpan - 1,
        }
      }

      return acc
    },
    { indexes: [], lastHeader: null, pointer: 0 }
  )

  return table.getHeaderGroups().map((headerGroup) => (
    <tr key={headerGroup.id}>
      {headerGroup.headers.map((header, i) => {
        const sortDirection = header.column.getIsSorted()
        const size = header.getSize()
        const isGroupHeader = header.colSpan > 1
        const isStartOfGroup = indexesOfStartOfGroupColumn.indexes.includes(i)
        const meta: ColumnMeta<T, unknown> = header.column.columnDef.meta || {}
        const shouldHaveBorder = isGroupHeader || isStartOfGroup

        return (
          <th
            key={header.id}
            colSpan={header.colSpan}
            className={classNames(
              "whitespace-nowrap h-6 px-2 text-left",
              hoveredColumn === header.column.id && "bg-neutral-400",
              shouldHaveBorder && "border-neutral-400 border-l",
              header.column.getCanSort() && "cursor-pointer select-none",
              i === 0 && isFirstColumnSticky && "sticky top-0 left-0 z-10 bg-neutral-300"
            )}
            style={isTableColumnsFixedWidth ? undefined : { width: size }}
            onClick={header.column.getToggleSortingHandler()}
            onMouseEnter={() => setHoveredColumn?.(header.column.id)}
          >
            {header.isPlaceholder || header.column.columnDef.meta?.hideColumnHeader ? null : (
              <div className="flex gap-2 items-center h-6">
                <div className="flex gap-1 items-center">
                  {renderWithTypography(
                    (text) => (
                      <Typography
                        size={isGroupHeader ? Size.XXSmall : Size.XSmall}
                        weight={Weight.Semibold}
                        shouldWrap={false}
                        color={Color.Black}
                        text={text}
                      />
                    ),
                    header.getContext(),
                    header.column.columnDef.header
                  )}

                  {meta.tooltip && (
                    <div className="flex items-center justify-center">
                      <HelpIcon tooltipTitle={meta.tooltip} />
                    </div>
                  )}
                </div>
                {header.column.getCanSort() ? (
                  <div onClick={header.column.getToggleSortingHandler()}>
                    {sortDirection === "asc" ? (
                      <UpChevronIcon />
                    ) : sortDirection === "desc" ? (
                      <DownChevronIcon />
                    ) : sortDirection === false ? (
                      <span className="text-neutral-1000">
                        <SwitchVerticalIcon height={16} width={16} />
                      </span>
                    ) : null}
                  </div>
                ) : null}
              </div>
            )}
          </th>
        )
      })}
    </tr>
  ))
}
