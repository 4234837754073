import { OrderQuotaComplianceData } from "./useAccountQuotaCompliantOrders"
import { AccountOrderQuotaFields } from "common/model/AccountOrderQuotaFields"
// import { QuarterQuotaHistory } from "./useAccountQuotaHistory"
import { defaultIfLoading } from "common/utils/Loading"
import { useMemo } from "react"
import { useFeatureFlag } from "src/providers/featureFlags/useFeatureFlags"

const accountIsQuotaDelinquent = ({
  isQuotaEnabledFeatureFlag,
  orderQuotaComplianceData,
  accountOrderQuotaFields,
}: {
  isQuotaEnabledFeatureFlag: boolean
  // accountQuotaHistory: Pick<QuarterQuotaHistory, "daysInQuarterOutOfCompliance">
  orderQuotaComplianceData: Pick<
    OrderQuotaComplianceData,
    "isAccountCompliantWithOrderQuotaThresholds"
  >
  accountOrderQuotaFields: Pick<
    AccountOrderQuotaFields,
    "enforceOrderQuota" | "isQuotaSystemEnabled"
  >
}): boolean => {
  if (!isQuotaEnabledFeatureFlag) return false
  if (!accountOrderQuotaFields.isQuotaSystemEnabled) return false
  return (
    !!accountOrderQuotaFields.enforceOrderQuota &&
    !defaultIfLoading(orderQuotaComplianceData.isAccountCompliantWithOrderQuotaThresholds, true)
  )
}

export const useAccountIsQuotaDelinquent = ({
  orderQuotaComplianceData,
  accountOrderQuotaFields,
}: // accountQuotaHistory,
{
  orderQuotaComplianceData: OrderQuotaComplianceData
  accountOrderQuotaFields: AccountOrderQuotaFields
}): boolean => {
  const isQuotaEnabledFeatureFlag = useFeatureFlag("is_order_quota_system_live")
  return useMemo(
    () =>
      accountIsQuotaDelinquent({
        isQuotaEnabledFeatureFlag,
        orderQuotaComplianceData,
        accountOrderQuotaFields,
        // accountQuotaHistory,
      }),
    [accountOrderQuotaFields, isQuotaEnabledFeatureFlag, orderQuotaComplianceData]
  )
}
