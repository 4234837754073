import { isHidden } from "../DataPoint"
import { assertUnreachable } from "../../../utils/fp/Function"
import { DeprecatedOutlierCheckResult, OutlierStatusFields } from "./OutlierStatusFields"
import { UserAccessFields, UserIdFields } from "../../User"

export type DataVisibilityFields = {
  hidden?: boolean | null
  /** @deprecated */
  outlier?: DeprecatedOutlierCheckResult | null
  /** @deprecated */
  outlierHistory?: DeprecatedOutlierCheckResult[]
  outlierStatus?: OutlierStatusFields | null
  defaultVisibility?: "platform" | "private"
  sharedAccountIds?: string[] | null
}

type EmailTarget = { tag: "platform users" } | { tag: "user"; user: UserIdFields }
export const canIncludeInEmailNotifications = (v: DataVisibilityFields, to: EmailTarget) => {
  if (isHidden(v) || v?.defaultVisibility === "private") {
    return false
  }
  switch (to.tag) {
    case "platform users":
      return true
    case "user":
      return true
    default:
      return assertUnreachable(to)
  }
}

/** This should always be backed up by actual firestore rules  */
export const canSendToUser = (v: DataVisibilityFields, to: UserAccessFields) => {
  if (v.defaultVisibility === "private" && !v.sharedAccountIds?.includes(to.account.id)) { // todo add owner account id
    return false
  }
  if (v.hidden) {
    return true // should be false, but that would currently be a lie
  }
  return true
}

export const canShowOnPlatform = (v: DataVisibilityFields, to: UserAccessFields) => {
  if (!canSendToUser(v, to)) {
    return false
  }
  if (v.hidden) {
    return false
  }
  return true
}
