import { wrapIcon } from "./IconWrapper"

export const HighlightedIcon = wrapIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" fill="none">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.5 1a.5.5 0 0 1 .5.5V2h.5a.5.5 0 0 1 0 1H3v.5a.5.5 0 0 1-1 0V3h-.5a.5.5 0 0 1 0-1H2v-.5a.5.5 0 0 1 .5-.5ZM6 1a.5.5 0 0 1 .475.344l.956 2.906a.5.5 0 0 0 .319.319l2.906.956a.5.5 0 0 1 0 .95l-2.906.956a.5.5 0 0 0-.319.319l-.956 2.906a.5.5 0 0 1-.95 0L4.569 7.75a.5.5 0 0 0-.319-.319l-2.906-.956a.5.5 0 0 1 0-.95l2.906-.956a.5.5 0 0 0 .319-.319l.956-2.906A.5.5 0 0 1 6 1Zm-.481 3.563a1.5 1.5 0 0 1-.956.956L3.1 6l1.463.481a1.5 1.5 0 0 1 .956.956L6 8.9l.481-1.463a1.5 1.5 0 0 1 .956-.956L8.9 6l-1.463-.481a1.5 1.5 0 0 1-.956-.956L6 3.1l-.481 1.463ZM9.5 8a.5.5 0 0 1 .5.5V9h.5a.5.5 0 0 1 0 1H10v.5a.5.5 0 0 1-1 0V10h-.5a.5.5 0 0 1 0-1H9v-.5a.5.5 0 0 1 .5-.5Z"
      clipRule="evenodd"
    />
  </svg>
)
