import { Steps, StepsProps } from "@stories/components/Antd"
import { UserAuthContext, useAuthContext } from "src/Routes/auth/UserAuthContext"
import { useAccountBrokerLinking } from "src/utils/useAccountBrokerLinking"
import { useOrderForm } from "./OrderFormContext"
import { StepIcon } from "./StepIcon"
import {
  StepKey,
  StepOrderMap,
  StepTitleMap,
  stepKeys,
  useOrderFormStatus,
} from "./contextHooks/useFormStatus"

const clickEnabled = (status: StepsProps["status"]) => status !== "wait"

const isCurrentStep = (
  stepKey: StepKey,
  stepProgress: number,
  authContext: UserAuthContext,
  brokerLinkingFeatureFlag: boolean
) => stepProgress === StepOrderMap(authContext, brokerLinkingFeatureFlag)[stepKey]

export const OrderFormSteps = () => {
  const { formOrder } = useOrderForm()
  const { selectFormStatuses, stepProgress, setStepProgress } = useOrderFormStatus()

  const statuses = selectFormStatuses()

  const ctx = useAuthContext()
  const { isAccountBrokerLinkingEnabled } = useAccountBrokerLinking()

  return formOrder.direction ? (
    <div className="flex gap-4">
      <Steps
        current={stepProgress}
        items={stepKeys
          .flatMap((k, idx) =>
            statuses[k]
              ? {
                  id: k,
                  title: StepTitleMap[k],
                  onClick: () =>
                    clickEnabled(statuses[k]) &&
                    setStepProgress(StepOrderMap(ctx, isAccountBrokerLinkingEnabled)[k]),
                  icon: (
                    <StepIcon
                      stepKey={k}
                      isCurrentStep={isCurrentStep}
                      isStepComplete={idx <= stepProgress}
                      clickEnabled={clickEnabled}
                    />
                  ),
                  className: isCurrentStep(k, stepProgress, ctx, isAccountBrokerLinkingEnabled)
                    ? "font-bold"
                    : "",
                }
              : []
          )
          .sort(
            (a, b) =>
              StepOrderMap(ctx, isAccountBrokerLinkingEnabled)[a.id] -
              StepOrderMap(ctx, isAccountBrokerLinkingEnabled)[b.id]
          )}
      />
    </div>
  ) : null
}
