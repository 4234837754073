import { v4 as uuidv4 } from "uuid"
import { startOfMonth } from "date-fns"
import { AccountIdFields, viewAccountIdFields } from "../Account"
import { CompanyIdFields, viewCompanyIdFields } from "../Company"
import { UserAccessFields, UserIdFields, viewUserIdFields } from "../User"
import { AccessControlTier } from "./AccessControlTier"

export type CompanyAccess = {
  id: string
  company: CompanyIdFields
  account: AccountIdFields
  unlockedAt: Date
  unlockedBy: UserIdFields
}

export const buildNewCompanyAccess = ({
  user,
  company,
}: {
  user: UserAccessFields
  company: CompanyIdFields
}): CompanyAccess => ({
  id: uuidv4(),
  company: viewCompanyIdFields(company),
  account: viewAccountIdFields(user.account),
  unlockedAt: new Date(),
  unlockedBy: viewUserIdFields(user),
})

export const accessControlTierToCompanyAccessCount: Record<AccessControlTier, number | "unlimited"> = {
  "individual-trial": 5,
  employee: 2,
  "group-trial": 5,
  basic: 3,
  essential: 7,
  pro: 15,
  elite: "unlimited",
  free: 0,
  journalist: "unlimited",
  limited: 0,

  "legacy-data": "unlimited",
  "legacy-markets": 7,
}

export const hasUnlimitedCompanyAccess = (currentAccessTier: AccessControlTier) =>
  accessControlTierToCompanyAccessCount[currentAccessTier] === "unlimited"

export const canAccessCompany = ({
  companyToCheck,
  accountCompanyAccess,
  currentAccessTier,
}: {
  companyToCheck: Pick<CompanyIdFields, "id">
  accountCompanyAccess: CompanyAccess[]
  currentAccessTier: AccessControlTier
}): boolean =>
  hasUnlimitedCompanyAccess(currentAccessTier) ||
  accountCompanyAccess.some((companyAccess) => companyAccess.company.id === companyToCheck.id)

/**
 * Access is considered expired at the start of a new month
 */
export const isCompanyAccessExpired = (companyAccess: CompanyAccess): boolean => {
  const today = new Date()
  const startOfThisMonth = startOfMonth(today)
  return companyAccess.unlockedAt.getTime() < startOfThisMonth.getTime()
}

export const isCompanyAccessActive = (companyAccess: CompanyAccess): boolean =>
  !isCompanyAccessExpired(companyAccess)

export const countRemainingUnlocks = ({
  accountCompanyAccess,
  currentAccessTier,
}: {
  accountCompanyAccess: CompanyAccess[]
  currentAccessTier: AccessControlTier
}): number | "unlimited" => {
  const allowedUnlockCount = accessControlTierToCompanyAccessCount[currentAccessTier]

  if (allowedUnlockCount === "unlimited") {
    return "unlimited"
  }

  const activeAccountCompanyAccess = new Set(
    accountCompanyAccess
      .filter((companyAccess) => isCompanyAccessActive(companyAccess))
      .map((companyAccess) => companyAccess.company.id)
  )

  return Math.max(allowedUnlockCount - activeAccountCompanyAccess.size, 0)
}

// TODO: make this more usable
export const companyAccessResetsMonthly = (tier: AccessControlTier): boolean =>
  !["individual-trial", "group-trial"].includes(tier)
