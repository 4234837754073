import React from "react"
import { Size, Typography } from "../Typography/Typography"

const calculateColor = (val: number) => {
  if (val >= 100) {
    return "bg-success-400"
  }

  if (val > 50) {
    return "bg-accent-500"
  }

  if (val > 25) {
    return "bg-warning-400"
  }

  return "bg-danger-400"
}

export interface LoadingBarProps {
  /**
   * What percentage complete is the loading bar?
   */
  value: number
  /**
   * What text should be displayed as a label?
   */
  label?: string
  /**
   * What text should be displayed as a label value?
   */
  labelValue?: string
}

const LoadingBar: React.FC<LoadingBarProps> = ({ value, label, labelValue }) => (
  <div className="flex flex-col gap-1">
    <div className="flex justify-between items-center">
      {label && <Typography text={label} size={Size.XSmall} shouldWrap={false} />}
      {labelValue && <Typography text={labelValue} size={Size.XSmall} shouldWrap={false} />}
    </div>
    <div className="h-2 w-full bg-neutral-300 rounded-full">
      <div
        className={`h-2 rounded-full ${calculateColor(value)}`}
        style={{ width: `${value}%`, maxWidth: "100%" }}
      />
    </div>
  </div>
)

export default LoadingBar
