import { Company } from "common/model/Company"
import { DealCRMDeal } from "common/model/DealCRM/Deal/DealCRMDeal"
import { DealCRMDealParticipant } from "common/model/DealCRM/Deal/DealCRMDealParticipant"
import { DealCRMInterest } from "common/model/DealCRM/DealCRMInterest"
import { DealDistribution } from "common/model/DealDistribution/DealDistribution"

export const CRMInterestTag = "crm_interest" as const

export type CrmInterestData = {
  tag: typeof CRMInterestTag
  id: string
  interest: DealCRMInterest
  contact: DealCRMInterest["contact"]
}

export const CRMDealTag = "crm_deal" as const

export type CrmDealData = {
  tag: typeof CRMDealTag
  id: string
  deal: DealCRMDeal
  distribution: DealDistribution | null
  company: Company
  dealParticipants: DealCRMDealParticipant[]
  bids: DealCRMInterest[]
  offers: DealCRMInterest[]
}

export type CrmItemData = CrmInterestData | CrmDealData

export const isCrmInterestCard = (card: CrmItemData): card is CrmInterestData =>
  card.tag === CRMInterestTag

export const isCrmDealCard = (card: CrmItemData): card is CrmDealData => card.tag === "crm_deal"
