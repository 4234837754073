import moment from "moment"
import _ from "lodash"
import { v4 as uuid } from "uuid"
import { head } from "../../../utils/data/Array/ArrayUtils"
import { Either, Left, Right } from "../../../containers/Either"
import { Rec, UnsafeRec } from "../../../utils/RecordUtils"
import { isDefined, isNotNull } from "../../../utils/TypeUtils"
import { BrokerClientMetadata } from "../../BrokerClient"
import { viewCompanyIdFields } from "../../Company"
import { APIDataSource, UserFormDataSource } from "../../data-product/DataSource"
import {
  ConstructedOrder,
  createOrder,
  mergeEditedOrder,
  Order,
  OrderDocuments,
  orderFullTermsArray,
  orderLatestQuantityTerms,
  orderLatestTerms,
} from "../Order"
import { OrderSourceAccountFields, viewOrderSourceAccountFields } from "../OrderSource"
import { isLiveOrStaleOrder, orderLiveUntil } from "../Types/Status"
import {
  OrderQuantityTerms,
  OrderTerms,
  StructureOrderTerms,
  orderStructures,
} from "../Types/Terms"
import {
  makeOrderQuantityTerms,
  orderQuantityTermsPriceInterval,
  orderQuantityTermsShares,
  orderQuantityTermsVolume,
} from "../Types/Terms/Quantity"
import { now } from "../../../utils/dateUtils"
import { LIVE_UNTIL_DEFAULT_DAYS } from "../OrderConstants"
import { makeOutlierStatus } from "../../data-product/DataPoint/OutlierStatusFields"
import { countStructureLayers, doubleLayerSPV } from "../Models/Internal"
import { FormOrder, OrderFormSize, OrderFormState, OrderPrice } from "./State"
import { OrderTermsHistoryUpdate } from "../TermConstruction"
import { viewUserIdFields } from "../../User"
import { DealCRMInterest } from "../../DealCRM/DealCRMInterest"
import { Interval } from "../../../utils/data/Interval"
import { isBrokerage } from "../../DealCRM/DealCRMFirmContact"
import { DealCRMContact } from "../../DealCRM/DealCRMContact"

type EitherValuationOrSharePrice = Either<
  Required<Pick<OrderQuantityTerms, "targetValuation">>,
  Required<Pick<OrderQuantityTerms, "USDPerShare">>
>

export const orderPriceToEither = ({
  targetValuation,
  USDPerShare,
}: OrderPrice): EitherValuationOrSharePrice => {
  if (isDefined(targetValuation)) return Left({ targetValuation })
  if (isDefined(USDPerShare)) return Right({ USDPerShare })
  throw new Error(
    "orderFormPriceToEither was given a price that had neither targetValuation nor USDPerShare. this should not be possible"
  )
}

type EitherOrderFormSize = Either<
  Required<Pick<OrderQuantityTerms, "amountUSD">>,
  Required<Pick<OrderQuantityTerms, "shares">>
>

export const orderFormSizeToEither = ({
  shares,
  amountUSD,
}: OrderFormSize): EitherOrderFormSize => {
  if (isDefined(amountUSD)) return Left({ amountUSD })
  if (isDefined(shares)) return Right({ shares })
  throw new Error(
    "orderFormSizeToEither was given a size that had neither shares nor amountUSD. this should not be possible"
  )
}

const createOrderSourceFromForm = (
  x: FormOrder
): ConstructedOrder["source"] & UserFormDataSource => ({
  account: viewOrderSourceAccountFields(x.account),
  documentUpload: null,
  sourceId: null,
  sourceType: "user-form",
  submittingUser: viewUserIdFields(x.user),
})

export const createOrderSourceFromAPI = (params: {
  account: OrderSourceAccountFields
  unparsedDocumentId: string
  sourceOrderId?: string
}): ConstructedOrder["source"] & APIDataSource => ({
  account: viewOrderSourceAccountFields(params.account),
  unparsedDocumentId: params.unparsedDocumentId,
  documentUpload: null,
  sourceId: params.sourceOrderId || null,
  sourceType: "api",
})

const createBrokerClientMetadataFromForm = (form: FormOrder): BrokerClientMetadata => ({
  relationship: form.clientRelationship,
  confirmedAt: null,
  profile: null,
  buyerCapTableMember: null,
  sellerROFR: null,
  previousClientTransaction: null,
  investmentCommitteeInvolved: null,
  investmentCommitteeApproved: null,
})

export const emptyOrderTerms: OrderTerms = Rec.tabulate(orderStructures, () => [])

/** @deprecated */
export const deprecated_createOrderQuantityTermsFromForm = (
  x: Pick<FormOrder, "price" | "size">,
  date: number
): Record<number, OrderQuantityTerms> => ({
  [date]: makeOrderQuantityTerms(
    x.price === null
      ? orderFormSizeToEither(x.size).match(
          ({ amountUSD }) => ({ amountUSD, id: uuid() }),
          ({ shares }) => ({ shares, id: uuid() })
        )
      : orderPriceToEither(x.price).match(
          ({ targetValuation }) =>
            orderFormSizeToEither(x.size).match(
              ({ amountUSD }) => ({ targetValuation, amountUSD, id: uuid() }),
              ({ shares }) => ({ targetValuation, shares, id: uuid() })
            ),
          ({ USDPerShare }) =>
            orderFormSizeToEither(x.size).match(
              ({ amountUSD }) => ({ USDPerShare, amountUSD, id: uuid() }),
              ({ shares }) => ({ USDPerShare, shares, id: uuid() })
            )
        )
  ),
})

const createOrderQuantityTermsFromForm = (
  x: Pick<FormOrder, "price" | "size">
): OrderQuantityTerms =>
  makeOrderQuantityTerms(
    x.price === null
      ? orderFormSizeToEither(x.size).match(
          ({ amountUSD }) => ({ amountUSD, id: uuid() }),
          ({ shares }) => ({ shares, id: uuid() })
        )
      : orderPriceToEither(x.price).match(
          ({ targetValuation }) =>
            orderFormSizeToEither(x.size).match(
              ({ amountUSD }) => ({ targetValuation, amountUSD, id: uuid() }),
              ({ shares }) => ({ targetValuation, shares, id: uuid() })
            ),
          ({ USDPerShare }) =>
            orderFormSizeToEither(x.size).match(
              ({ amountUSD }) => ({ USDPerShare, amountUSD, id: uuid() }),
              ({ shares }) => ({ USDPerShare, shares, id: uuid() })
            )
        )
  )

export const createOrderTermsFromForm = (
  x: FormOrder,
  previousOrderForm: OrderFormState
): OrderTerms => {
  const quantityTerms = () => createOrderQuantityTermsFromForm(x)
  const spvTerms: StructureOrderTerms["spv"] = x.terms?.spv ?? { carry: null, managementFee: null }
  return OrderTermsHistoryUpdate.empty()
    .addTerms(now(), {
      direct: x.structures.includes("direct")
        ? { quantityData: quantityTerms(), structureData: {} }
        : previousOrderForm.structures?.includes("direct")
        ? { structureData: {}, quantityData: null }
        : undefined,
      spv: x.structures.includes("spv")
        ? { quantityData: quantityTerms(), structureData: spvTerms }
        : previousOrderForm.structures?.includes("spv")
        ? { structureData: spvTerms, quantityData: null }
        : undefined,
      forward: x.structures.includes("forward")
        ? { quantityData: quantityTerms(), structureData: {} }
        : previousOrderForm.structures?.includes("forward")
        ? { structureData: {}, quantityData: null }
        : undefined,
      unknown: x.structures.includes("unknown")
        ? { quantityData: quantityTerms(), structureData: {} }
        : previousOrderForm.structures?.includes("unknown")
        ? { structureData: {}, quantityData: null }
        : undefined,
    })
    .runOnEmptyHistory()
}

const createOrderDocumentsFromForm = (x: FormOrder): OrderDocuments => ({
  areTransactionDocumentOnHand: x.orderDocuments?.areTransactionDocumentOnHand ?? null,
  diligenceAvailable: x.orderDocuments?.diligenceAvailable ?? null,
  requiresDiligence: x.orderDocuments?.requiresDiligence ?? null,
  brokerRecentlyClosedTradeInCompany: x.orderDocuments?.brokerRecentlyClosedTradeInCompany ?? false,
})

export const createOrderFromForm = (
  x: FormOrder,
  previousFormOrder: OrderFormState
): ConstructedOrder =>
  createOrder({
    source: createOrderSourceFromForm(x),
    direction: x.direction,
    company: viewCompanyIdFields(x.company),
    adminNotes: null,
    terms: createOrderTermsFromForm(x, previousFormOrder),
    orderOriginationDate: new Date(),
    orderUpdatedAt: [],
    firmness: x.firmness ?? null,
    brokerClientMetadata: createBrokerClientMetadataFromForm(x),
    shareClasses: x.shareClasses,
    shareClassSeries: x.shareClassSeries ?? null,
    orderDocuments: createOrderDocumentsFromForm(x),
    commission: x.commission ?? null,
    darkpool: !!x.darkpool,
    _lastReportedStatus: {
      tag: "live",
      asOf: new Date(),
      liveUntil: x.liveUntil,
    },
    brokeredBy: x.brokeredBy ?? null,
    outlierStatus: makeOutlierStatus([]),
    orderCollection: x.darkpool ? "darkpool" : x.price === null ? "tentativeInterest" : "platform",
    layeredStructure: x.isMultiLayerSpv ? doubleLayerSPV : null,
    privateNotes: x.notes ?? "",
    crmContactId: x.crmContactId ?? null,
    crmInterestId: x.crmInterestId ?? null,
    buyerCashOnHand: x.buyerCashOnHand ?? null,
  })

type UpdateOrderFromFormOptions = {
  autoRenew?: boolean
}
export const updateOrderFromForm = (
  x: FormOrder,
  prevOrder: Order,
  { autoRenew = false }: UpdateOrderFromFormOptions
): Order =>
  mergeEditedOrder(prevOrder, {
    ...createOrderFromForm(
      {
        ...x,
        liveUntil:
          autoRenew && isLiveOrStaleOrder(prevOrder)
            ? moment().add(LIVE_UNTIL_DEFAULT_DAYS, "days").toDate()
            : x.liveUntil,
      },
      createOrderFormFieldsFromOrder(prevOrder)
    ),
    ...(autoRenew ? {} : { _lastReportedStatus: prevOrder._lastReportedStatus }),
    updatedAt: [new Date()],
    orderUpdatedAt: [new Date()],
  })

export const createOrderFormFieldsFromOrder = (x: Order): Partial<FormOrder> => {
  const maybeLatestTerms = head(orderLatestQuantityTerms(x))
  const price =
    x.orderCollection === "tentativeInterest"
      ? null
      : maybeLatestTerms.match<OrderPrice | undefined>(
          (orderQuantityTerms) => {
            const { targetValuation } = orderQuantityTerms
            const USDPerShare = orderQuantityTermsPriceInterval(orderQuantityTerms)
            return USDPerShare ? { USDPerShare } : targetValuation ? { targetValuation } : undefined
          },
          () => undefined
        )

  const size = maybeLatestTerms.match<OrderFormSize | undefined>(
    (orderQuantityTerms) => {
      const shares = orderQuantityTermsShares(orderQuantityTerms)
      const amountUSD = orderQuantityTermsVolume(orderQuantityTerms)
      return amountUSD ? { amountUSD } : shares ? { shares } : undefined
    },
    () => undefined
  )

  const fullTermsArray = orderFullTermsArray(x)

  const terms: FormOrder["terms"] = {
    unknown: {},
    structure_agnostic_regular_way: {},
    direct: {},
    spv: head(_.orderBy(fullTermsArray.spv.filter(isNotNull), ({ date }) => date.valueOf(), "desc"))
      .map(({ terms: spvTerms }) => spvTerms)
      .withDefault({ carry: null, managementFee: null }),
    forward: {},
    swap: {},
  }

  return {
    direction: x.direction,
    clientRelationship: x.brokerClientMetadata?.relationship ?? undefined,
    crmContactId: x.crmContactId,
    crmInterestId: x.crmInterestId,
    structures: UnsafeRec.entries(orderLatestTerms(x)).flatMap(([k, v]) =>
      v?.priceAndQuantity ? [k] : []
    ),
    price,
    size,
    terms,
    shareClasses: x.shareClasses,
    shareClassSeries: x.shareClassSeries,
    firmness: x.firmness ?? undefined,
    liveUntil: orderLiveUntil(x),
    company: x.company,
    account: x.source.account,
    orderDocuments: x.orderDocuments ?? undefined,
    darkpool: !!x.darkpool,
    brokeredBy: x.brokeredBy,
    isMultiLayerSpv: x.layeredStructure ? countStructureLayers(x.layeredStructure) === 2 : false,
    notes: x.privateNotes ?? undefined,
    commission: x.commission
      ? {
          priceInclusiveOfFees: x.commission.priceInclusiveOfFees ?? null,
          priceType: x.commission.priceType ?? null,
          canChargeClientFee: x.commission.canChargeClientFee ?? null,
          commissionNotes: x.commission.commissionNotes ?? null,
          commissionType: x.commission.commissionType ?? null,
          amount: x.commission.amount ?? null,
        }
      : undefined,
    buyerCashOnHand: x.buyerCashOnHand,
    updateExistingCRMInterestTerms: !!x.crmInterestId,
  }
}

export const inferClientRelationshipFromContact = (contact: DealCRMContact) =>
  contact.tag === "broker" || isBrokerage(contact) ? "indirect" : "direct"

export const buildFormOrderFromCRMInterest = (
  interest: Omit<DealCRMInterest, "contact">,
  contact: DealCRMContact | null
): Required<Pick<FormOrder, "direction" | "company" | "crmContactId" | "crmInterestId">> &
  Partial<FormOrder> => ({
  direction: interest.direction,
  company: interest.company,
  price: interest.targetPrice
    ? { USDPerShare: Interval.pure(interest.targetPrice) }
    : interest.targetValuation
    ? { targetValuation: interest.targetValuation }
    : undefined,
  size: interest.amountUSD ? { amountUSD: interest.amountUSD } : undefined,
  structures: interest.structure ? [interest.structure] : undefined,
  shareClasses: interest.shareClass ? [interest.shareClass] : undefined,
  crmContactId: contact?.id ?? null,
  crmInterestId: interest.id,
  clientRelationship: contact ? inferClientRelationshipFromContact(contact) : undefined,
  updateExistingCRMInterestTerms: true,
})
