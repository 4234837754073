import { downPath } from "./DownArrowIcon"
import { wrapIcon } from "./IconWrapper"

export const UpArrowIcon = wrapIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d={downPath}
      transform="rotate(180 8 8)"
      fill="currentColor"
    />
  </svg>
)
