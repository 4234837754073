import { Color, Size, Typography } from "@stories/components/Typography/Typography"

export const TopBrokerCriteriaDisclaimer = () => (
  <div className="flex flex-col gap-2">
    <Typography
      text="* Caplight reserves the right to approve or deny Top Broker status."
      size={Size.XSmall}
      color={Color.Placeholder}
    />
    <Typography
      text="* Caplight also monitors instances of Order Revisions, Ghosting and Average Response Time."
      size={Size.XSmall}
      color={Color.Placeholder}
    />
    <Typography
      text="* Ghosting occurs when a broker does not respond to a deal thread for more than 3 business days. Revisions are when there are material changes to order terms (price, size, structure, etc.) that occur after the initial Connect request has been placed."
      size={Size.XSmall}
      color={Color.Placeholder}
    />
  </div>
)
