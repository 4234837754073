import { Button } from "@stories/components/Button/Button"
import ButtonGroup from "@stories/components/ButtonGroup/ButtonGroup"
import { BoldIcon } from "@stories/icons/BoldIcon"
import { BulletListIcon } from "@stories/icons/BulletListIcon"
import { ItalicIcon } from "@stories/icons/ItalicIcon"
import { LinkIcon } from "@stories/icons/LinkIcon"
import { NumberedListIcon } from "@stories/icons/NumberedListIcon"
import { UnlinkIcon } from "@stories/icons/UnlinkIcon"
import { BubbleMenu } from "@tiptap/react"
import React, { useCallback } from "react"
import { Editor } from "@tiptap/react"

interface BubbleMenuBarProps {
  editor: Editor
}

export const MenuBar = ({ editor }: BubbleMenuBarProps) => {
  const setLink = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const previousUrl: string = editor.getAttributes("link").href
    // eslint-disable-next-line no-alert
    const url = window.prompt("URL", previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run()
      return
    }

    // update link
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run()
  }, [editor])
  return (
    <div className="flex items-center gap-2 p-2">
      <ButtonGroup>
        <Button
          leftIcon={<BoldIcon />}
          variant={editor.isActive("bold") ? "primary" : "secondary"}
          onClick={() => editor.chain().focus().toggleBold().run()}
          isDisabled={!editor.can().chain().focus().toggleBold().run()}
        />
        <Button
          leftIcon={<ItalicIcon />}
          variant={editor.isActive("italic") ? "primary" : "secondary"}
          onClick={() => editor.chain().focus().toggleItalic().run()}
          isDisabled={!editor.can().chain().focus().toggleItalic().run()}
        />
      </ButtonGroup>

      <div className="border-r h-5 border mx-1" />

      <ButtonGroup>
        <Button
          leftIcon={<BulletListIcon />}
          variant={editor.isActive("bulletList") ? "primary" : "secondary"}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
        />
        <Button
          leftIcon={<NumberedListIcon />}
          variant={editor.isActive("orderedList") ? "primary" : "secondary"}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
        />
      </ButtonGroup>

      <div className="border-r h-5 border mx-1" />

      <ButtonGroup>
        <Button
          leftIcon={<LinkIcon />}
          variant={editor.isActive("link") ? "primary" : "secondary"}
          onClick={setLink}
        />

        {editor.isActive("link") && (
          <Button
            leftIcon={<UnlinkIcon />}
            variant={editor.isActive("link") ? "secondary" : "primary"}
            onClick={setLink}
          />
        )}
      </ButtonGroup>
    </div>
  )
}

export const BubbleMenuBar: React.FC<BubbleMenuBarProps> = ({ editor }) => {
  if (!editor) {
    return null
  }

  return (
    <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
      <MenuBar editor={editor} />
    </BubbleMenu>
  )
}
