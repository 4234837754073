import { AccessControl } from "../AccessControl"

export const employeeAccessControl: AccessControl<"employee"> = {
  tier: "employee",
  features: {
    // live market
    liveMarketParticipation: "none",
    orderHistoryData: "none",
    currentMarketPriceData: "none",
    contributeTradeData: "none",
    companyNewsData: "full",
    fundingRoundsPage: "full",
    fundingRoundsData: "limited-company",
    marketInsightsPage: "full",
    rfqData: "none",

    // company page
    companyPageFundingRoundsTable: "limited-company",
    companyPage409aValuationsTable: "none",
    companyPageFilingsTable: "none",
    companyPageInvestorsTable: "limited-company",
    companyPageMutualFundMarksTable: "limited-company",
    companyPageBidOfferVolumeRatioChart: "limited-company",
    companyPageTradingContextSection: "limited-company",
    companyPageResearchReportsSection: "limited-company",
    companyPageComparablesSection: "limited-company",
    companyPageShare: "full",
    companyPageFlagIssue: "full",
    companyPageExport: "none",

    // trade history
    tradeHistoryPage: "full",
    tradeHistoryPricing: "limited-company",
    tradeHistoryDates: "full",
    tradeHistoryStructure: "full",
    tradeHistoryShareClass: "full",

    // sectors page
    sectorsData: "full",
  },
}
