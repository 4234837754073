import moment from "moment"
import { Excluding } from "../../utils/TypeUtils"
import { Account, AccountIdFields } from "../Account"
import { ExclusiveUserIdFields } from "../User"
import { OpportunityAppliedRules } from "./OpportunityAppliedRules"
import { OpportunityOrderMetadata } from "./OpportunityOrderMetadata"
import { isOpportunityArchived } from "./fns/archiveOpportunity"
import { PinnedOpportunity, isOpportunityPinned } from "./fns/pinOpportunity"
import {
  ExclusiveSharedIdFields,
  SharedOrder,
  SharedOrderDocumentType,
} from "../SharedOrder/SharedOrder"
import { sortByDate } from "../../utils/dateUtils"

export type OpportunityIdFields = {
  id: string
  account: Excluding<AccountIdFields, Account>
}

export const viewOpportunityIdFields = (
  opp: OpportunityIdFields
): Excluding<OpportunityIdFields, Opportunity> => ({
  id: opp.id,
  account: opp.account,
})

export type OpportunityInteractionUser = ExclusiveUserIdFields | "system"
type OpportunityInteractionTag = "pin" | "archive" | "read"
type OpportunityUserOnlyInteractionTag = "read"
type OpportunityInteraction<Tag extends OpportunityInteractionTag> =
  (Tag extends OpportunityUserOnlyInteractionTag
    ? { user: ExclusiveUserIdFields }
    : {
        user: OpportunityInteractionUser
      }) &
    (Tag extends "archive" ? { archiveReason: string[] } : {}) & {
      active: boolean
      asOf: Date
      tag: Tag
    }

export type OpportunityInteractionFields = {
  pinnedBy: OpportunityInteraction<"pin">
  archivedBy: OpportunityInteraction<"archive">
  readBy: OpportunityInteraction<"read">[]
  // TODO: move to subcollection? should be less urgent than rules
  interactionHistory: OpportunityInteraction<OpportunityInteractionTag>[]
}

export type OpportunityRuleFields = {
  appliedRules: OpportunityAppliedRules | null
}

export const viewOpportunityRuleFields = ({
  appliedRules,
}: OpportunityRuleFields): Excluding<OpportunityRuleFields, Opportunity> => ({
  appliedRules,
})

export type OpportunityMetadataFields = {
  orderMetadata: OpportunityOrderMetadata
  createdAt: Date
}

type OpportunitySource =
  | { tag: "platform_upload" | "dropbox_upload" | "live_market" | "private" }
  | {
      tag: "forwarded_email"
      emailContentType: "text" | "csv_run" | "pdf_run" | "excel_run" | "image_run" | "other"
    }
  | {
      tag: "shared_order"
      sharedOrders: ExclusiveSharedIdFields<SharedOrderDocumentType>[]
    }

type OpportunitySourceFields = {
  source: OpportunitySource
}

export type Opportunity = OpportunityIdFields &
  OpportunityInteractionFields &
  OpportunityRuleFields &
  OpportunityMetadataFields &
  OpportunitySourceFields

export const isOpportunityExpired = (opp: Opportunity): boolean =>
  moment(opp.orderMetadata.expiresAt).isBefore(moment.now())
export const isOpportunityArchivedInbox = (opp: Opportunity): boolean =>
  isOpportunityExpired(opp) || isOpportunityArchived(opp)

export type PrimaryInboxOpportunity = (Opportunity & { appliedRules: null }) | PinnedOpportunity
export const isOpportunityPrimaryInbox = (opp: Opportunity): opp is PrimaryInboxOpportunity =>
  !isOpportunityArchivedInbox(opp) && (opp.appliedRules === null || isOpportunityPinned(opp))

export type SecondaryInboxOpportunity = Opportunity & { appliedRules: OpportunityAppliedRules }
export const isOpportunitySecondaryInbox = (opp: Opportunity): opp is SecondaryInboxOpportunity =>
  !isOpportunityArchivedInbox(opp) && !isOpportunityPrimaryInbox(opp)

export const mostRecentOpportunitySource = (
  sharedOrders: ExclusiveSharedIdFields<SharedOrderDocumentType>[]
): SharedOrder["source"] => sharedOrders.sort(sortByDate("createdAt")).reverse()[0].source
