import { JSONContent } from "@tiptap/react"
import { useEffect } from "react"
import { ITipTapEditor } from "./ITipTapEditor"
import { WYSIWYGEditor } from "./WYSIWYGEditor"
import { useTipTapEditor } from "./useTipTapEditor"
import { Size } from "@stories/components/Typography/Typography"

export const WYSIWYGViewer = ({ content }: { content: JSONContent }) => {
  const handleEditorChange = (_editor: ITipTapEditor) =>
    Promise.reject(new Error("not implemented"))

  const editor = useTipTapEditor({
    handleSaveNote: handleEditorChange,
    initialContent: content,
    isMentionsDisabled: true,
  })

  useEffect(() => {
    if (editor && editor.isEditable) {
      editor.setOptions({ editable: false })
    }
  }, [editor])

  return editor ? (
    <WYSIWYGEditor
      showSubmitButton={false}
      autoFocus={false}
      editor={editor}
      handleSubmit={handleEditorChange}
      textSize={Size.Small}
      minHeight
    />
  ) : null
}
