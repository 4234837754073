import { CurrentUser } from "../../model/CurrentUser"
import {
  PostgresCompanyFundingRoundWithInvestors,
  PostgresInvestorEmployee,
  PostgresCompanyInvestorWithCompanyFundingRounds,
} from "common/model/postgres/PostgresInvestors"
import { Loading, isLoaded } from "common/utils/Loading"
import { useEffect, useMemo, useState } from "react"
import {
  getInvestorEmployees,
  getInvestorsIntoCompanyByRounds,
  getInvestorsIntoCompanyWithRounds,
} from "../../state/business_logic/postgresData/investors"
import { handleConsoleError } from "../../utils/Tracking"
import { useCurrentUser } from "../../providers/currentUser/useCurrentUser"

export const useInvestorsIntoCompanyByRound = (
  companyId: string,
  currentUser: Pick<CurrentUser, "authUser">
): Loading<PostgresCompanyFundingRoundWithInvestors[]> => {
  const [rounds, setRounds] =
    useState<Loading<PostgresCompanyFundingRoundWithInvestors[]>>("loading")
  const memoizedUser = useMemo(() => currentUser, [currentUser])
  useEffect(() => {
    getInvestorsIntoCompanyByRounds(companyId, memoizedUser)
      .then((res) => setRounds(res))
      .catch(handleConsoleError)
  }, [memoizedUser, companyId])
  return rounds
}

export const useInvestorsIntoCompany = (
  companyId: string,
  currentUser: Pick<CurrentUser, "authUser">
): Loading<PostgresCompanyInvestorWithCompanyFundingRounds[]> => {
  const [investorsWithRounds, setInvestorsWithRounds] =
    useState<Loading<PostgresCompanyInvestorWithCompanyFundingRounds[]>>("loading")
  const memoizedUser = useMemo(() => currentUser, [currentUser])
  useEffect(() => {
    getInvestorsIntoCompanyWithRounds(companyId, memoizedUser)
      .then((res) => setInvestorsWithRounds(res))
      .catch(handleConsoleError)
  }, [memoizedUser, companyId])
  return investorsWithRounds
}

export const useInvestorEmployees = (domain: string): Loading<PostgresInvestorEmployee[]> => {
  const user = useCurrentUser()
  const [employees, setEmployees] = useState<Loading<PostgresInvestorEmployee[]>>("loading")
  useEffect(() => {
    if (!isLoaded(user)) return
    getInvestorEmployees(domain, user)
      .then((res) => setEmployees(res))
      .catch(handleConsoleError)
  }, [user, domain])
  return employees
}
