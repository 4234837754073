import { v4 as uuid } from "uuid"
import { Account, AccountIdFields, viewAccountIdFields } from "./Account"
import { User, UserIdFields, viewUserIdFields } from "./User"
import { UserCreatable } from "./UserCreatable"
import { LinkedBroker } from "./LinkedBroker"
import { assertUnreachable } from "../utils/fp/Function"

export const DEFAULT_MAX_BROKER_NETWORK_SIZE = 5

type AccountNetworkNodeStatus =
  | "sentInvite"
  | "cancelledInvite"
  | "inviteReceived"
  | "inviteRejected"
  | "approved"
  | "cancelled"
  | "adminConnected"
  | "adminCancelled"

export const connectedAccountNetworkNodeStatuses: AccountNetworkNodeStatus[] = [
  "approved",
  "adminConnected",
]
export type AccountNetworkNode =
  | {
      tag: "on platform"
      account: AccountIdFields
      status: AccountNetworkNodeStatus
      primaryConnection?: UserIdFields
    }
  | {
      tag: "linked broker"
      broker: LinkedBroker
      status: AccountNetworkNodeStatus
    }
  | {
      tag: "new invite"
      name: string
      email: string
      status: AccountNetworkNodeStatus
    }

export const getAccountNetworkNodeFirmName = (node: AccountNetworkNode) => {
  if (node.tag === "on platform") return node.account.name
  if (node.tag === "linked broker") return node.broker?.firmName
  if (node.tag === "new invite") return `${node.name} (${node.email})`
  return assertUnreachable(node)
}

export type TrustedBrokerConnection = UserCreatable & {
  id: string
  isDeleted: boolean
  status: "active" | "pending" | "rejected" | "adminConnected"
  leftAccount: AccountNetworkNode
  rightAccount: AccountNetworkNode
}

export const generateTrustedBrokerConnection = ({
  createdBy,
  leftAccount,
  rightAccount,
  status,
  adminConnection,
}: {
  createdBy: User
  leftAccount: AccountIdFields
  rightAccount: AccountNetworkNode
  status: AccountNetworkNode["status"]
  adminConnection?: boolean
}) =>
  ({
    id: uuid(),
    createdAt: new Date(),
    createdBy: viewUserIdFields(createdBy),
    status: adminConnection ? "adminConnected" : "pending",
    leftAccount: {
      account: viewAccountIdFields(leftAccount),
      tag: "on platform",
      status: adminConnection ? "adminConnected" : status,
    },
    rightAccount: {
      ...rightAccount,
      status: adminConnection ? "adminConnected" : rightAccount.status,
    },
    isDeleted: false,
  } satisfies TrustedBrokerConnection)

export const rejectTrustedBrokerConnectionInvite = ({
  connection,
  sourceAccountId,
}: {
  connection: TrustedBrokerConnection
  sourceAccountId: string
}): TrustedBrokerConnection | null => {
  const matchLeftAccount =
    connection.leftAccount.tag === "on platform" &&
    connection.leftAccount.account.id === sourceAccountId &&
    connection.leftAccount.status === "inviteReceived"
  const matchRightAccount =
    connection.rightAccount.tag === "on platform" &&
    connection.rightAccount.account.id === sourceAccountId &&
    connection.rightAccount.status === "inviteReceived"
  if (!matchLeftAccount && !matchRightAccount) return null

  return {
    ...connection,
    status: "rejected",
    leftAccount: matchLeftAccount
      ? { ...connection.leftAccount, status: "inviteRejected" }
      : connection.leftAccount,
    rightAccount: matchRightAccount
      ? { ...connection.rightAccount, status: "inviteRejected" }
      : connection.rightAccount,
  }
}

export const acceptTrustedBrokerConnectionInvite = ({
  connection,
  sourceAccountId,
}: {
  connection: TrustedBrokerConnection
  sourceAccountId: string
}): TrustedBrokerConnection | null => {
  const matchLeftAccount =
    connection.leftAccount.tag === "on platform" &&
    connection.leftAccount.account.id === sourceAccountId &&
    connection.leftAccount.status === "inviteReceived"
  const matchRightAccount =
    connection.rightAccount.tag === "on platform" &&
    connection.rightAccount.account.id === sourceAccountId &&
    connection.rightAccount.status === "inviteReceived"
  if (!matchLeftAccount && !matchRightAccount) return null

  return {
    ...connection,
    status: "active",
    leftAccount: { ...connection.leftAccount, status: "approved" },
    rightAccount: { ...connection.rightAccount, status: "approved" },
  }
}

export const getConnectionNodes = (
  currentAccount: Pick<Account, "id">,
  connections: TrustedBrokerConnection[]
): AccountNetworkNode[] =>
  connections
    .filter((c) => !c.isDeleted && c.status !== "rejected")
    .flatMap((c) => [c.leftAccount, c.rightAccount])
    .filter((a) =>
      a.tag === "on platform"
        ? a.account.id !== currentAccount.id
        : a.tag === "linked broker" || a.tag === "new invite"
        ? true
        : assertUnreachable(a)
    )

export const getPendingInboundInvites = (
  currentAccount: Pick<Account, "id">,
  connections: TrustedBrokerConnection[]
) =>
  getConnectionNodes(currentAccount, connections).flatMap((n) =>
    n.status === "sentInvite" ? [n] : []
  )
export const getPendingOutboundInvites = (
  currentAccount: Pick<Account, "id">,
  connections: TrustedBrokerConnection[]
) =>
  getConnectionNodes(currentAccount, connections).flatMap((n) =>
    n.status === "inviteReceived" ? [n] : []
  )

export const getCurrentConnectedNodes = (
  currentAccount: Pick<Account, "id">,
  connections: TrustedBrokerConnection[]
): (AccountNetworkNode & { tag: "on platform" })[] =>
  getConnectionNodes(currentAccount, connections)
    .filter((a) => connectedAccountNetworkNodeStatuses.includes(a.status))
    .flatMap((n) =>
      n.tag === "on platform"
        ? [n]
        : n.tag === "linked broker" || n.tag === "new invite"
        ? []
        : assertUnreachable(n)
    )
