import { Left, Right } from "common/containers/Either"
import { isOpportunityInboxAccount } from "common/model/Account"
import { isMarketsCustomer } from "common/model/Auth/Permissions"
import { User } from "common/model/User"
import { deprecatedIsLoaded } from "common/utils/Loading"
import { Navigate } from "react-router-dom"
import { AuthCondition } from "../../../Routes/auth/AuthCondition"
import {
  isAdminContext,
  isIntermediaryContext,
  isPureEmployeeContext,
  UserAuthContext,
} from "../../../Routes/auth/UserAuthContext"
import { Routes } from "../../../Routes/Routes"
import { hasFullDataAccess } from "../DataAccess"

const isFullDataCustomerContext = (ctx: UserAuthContext) =>
  deprecatedIsLoaded(ctx.user) && !!ctx.user && hasFullDataAccess(ctx.user.user.account)

const isMarketsCustomerContext = (ctx: UserAuthContext) =>
  deprecatedIsLoaded(ctx.user) && !!ctx.user && isMarketsCustomer(ctx.user.user.account)

export const isOrdersUserCtx: AuthCondition<UserAuthContext, UserAuthContext> = (ctx: UserAuthContext) =>
  !isPureEmployeeContext(ctx) &&
  (isIntermediaryContext(ctx) ||
    isFullDataCustomerContext(ctx) ||
    isMarketsCustomerContext(ctx) ||
    isAdminContext(ctx))
    ? Right(ctx)
    : Left(() => <Navigate to="/" />)

export const isOrdersUser = (user: User) =>
  user.account.clientType.includes("Intermediary") ||
  hasFullDataAccess(user.account) ||
  isMarketsCustomer(user.account)

export const isOpportunityInboxUser = (user: User): boolean =>
  isOpportunityInboxAccount(user.account)

export const isOpportunityInboxUserCtx: AuthCondition<UserAuthContext, UserAuthContext> = (
  ctx: UserAuthContext
) =>
  deprecatedIsLoaded(ctx.user) && !!ctx.user && isOpportunityInboxUser(ctx.user.user)
    ? Right(ctx)
    : Left(() => <Navigate to={Routes.root} />)
