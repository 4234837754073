import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { config } from "src/config"
import issuerReducer_ from "./reducers/issuers"
import postgresDataReducer_ from "./reducers/postgresData"
import appStatesReducer_ from "./reducers/appStates"

const reducer = combineReducers({
  issuers: issuerReducer_,
  postgresData: postgresDataReducer_,
  appStates: appStatesReducer_,
})
export const store = configureStore({
  reducer,
})
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type GetState = typeof store.getState

try {
  if (config.env === "dev" || config.env === "staging") {
    ;(window as unknown as Record<string, unknown>).__CAPLIGHT_DEBUG_REDUX_STORE__ = store
  }
} catch {
  // debug code so it's ok if it fails
}
