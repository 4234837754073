import CompanyLogo from "@components/CompanyLogo"
import { ExternalLinkIcon } from "@heroicons/react/solid"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { CompanyIdFieldsWithPostgres } from "common/model/Company"
import { DealCRMDealIdFields } from "common/model/DealCRM/Deal/DealCRMDeal"
import { deprecatedIsLoaded } from "common/utils/Loading"
import React from "react"
import { useNavigate } from "react-router-dom"
import { Routes } from "src/Routes/Routes"
import { useCompanies } from "src/providers/data/CompanyProvider"

interface DealPillProps {
  deal: DealCRMDealIdFields
  company: CompanyIdFieldsWithPostgres
}

export const DealPill: React.FC<DealPillProps> = ({ deal, company }) => {
  const fullCompany = useCompanies(company.id)
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(Routes.crm.deal(deal.id))
  }

  return (
    <div
      className="hover:bg-gray-500 cursor-pointer px-1 pt-0.5 bg-neutral-200 border border-neutral-400 flex items-center rounded-full gap-1"
      onClick={handleClick}
    >
      {deprecatedIsLoaded(fullCompany) ? (
        <CompanyLogo size="xxs" company={fullCompany} />
      ) : (
        <div className="h-3 w-3 bg-neutral-500" />
      )}
      <Typography
        text={deal.name}
        size={Size.XXSmall}
        color={Color.Primary}
        weight={Weight.Semibold}
      />
      <ExternalLinkIcon className="h-3 w-3" />
    </div>
  )
}
