import { useMemo, useState } from "react"
import { ButtonProps, Button } from "./Button"
import { assertUnreachable } from "common/utils/fp/Function"

type AsyncButtonProps<T = unknown> = Omit<ButtonProps, "onClick"> & {
  onClick: (e: React.MouseEvent<Element, MouseEvent>) => Promise<T>
  onClickedProps?: Partial<Omit<ButtonProps, "onClick">>
  onFailureProps?: Partial<Omit<ButtonProps, "onClick">>
  onSuccessProps?: Partial<Omit<ButtonProps, "onClick">>
  onSuccess?: (t: T) => void
  onFailure?: (err: Error) => void
}

export const AsyncDispatchButton = <T,>({
  onClick,
  onSuccess,
  onFailure,
  onClickedProps = { isDisabled: true },
  onFailureProps = { isDisabled: true },
  onSuccessProps = { isDisabled: true },
  ...props
}: AsyncButtonProps<T>) => {
  const [dispatchState, setDispatchState] = useState<
    "unclicked" | "clicked" | "success" | "failure"
  >("unclicked")
  const wrappedOnClick = useMemo(
    () => (e: React.MouseEvent<Element, MouseEvent>) => {
      setDispatchState("clicked")
      onClick(e).then(
        (t) => {
          setDispatchState("success")
          if (onSuccess) {
            onSuccess(t)
          }
        },
        (err: Error) => {
          setDispatchState("failure")
          if (onFailure) {
            onFailure(err)
          } else {
            throw err
          }
        }
      )
    },
    [onClick, onFailure, onSuccess]
  )
  const clickDependentProps = useMemo(() => {
    switch (dispatchState) {
      case "unclicked":
        return {}
      case "clicked":
        return onClickedProps
      case "success":
        return onSuccessProps
      case "failure":
        return onFailureProps
      default:
        return assertUnreachable(dispatchState)
    }
  }, [dispatchState, onClickedProps, onFailureProps, onSuccessProps])
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Button onClick={wrappedOnClick} {...props} {...clickDependentProps} />
}
