/* eslint-disable react/no-unstable-nested-components */
import { AccessControlFeatures } from "common/model/AccessControl/AccessControl"
import { assertUnreachable } from "common/utils/fp/Function"
import { ReactNode } from "react"
import { useCompanyAccess } from "./CompanyAccessProvider"
import { GenericAccessControlWrapper } from "./GenericAccessControlWrapper"

type AccessControlWrapperProps = {
  feature: keyof AccessControlFeatures
  children: ReactNode
  lockedFallback?: ReactNode
  limitedCompanyFallback?: ReactNode
}

export const AccessControlWrapper = ({
  feature,
  children,
  lockedFallback,
  limitedCompanyFallback,
}: AccessControlWrapperProps) => {
  const { isCompanyUnlocked } = useCompanyAccess()

  return (
    <GenericAccessControlWrapper
      feature={feature}
      component={(accessLevel) => {
        switch (accessLevel) {
          case "limited-company": {
            return isCompanyUnlocked ? children : limitedCompanyFallback
          }
          case "full": {
            return children
          }
          case "locked": {
            return lockedFallback ?? null
          }
          case "none": {
            return null
          }
          default: {
            return assertUnreachable(accessLevel)
          }
        }
      }}
    />
  )
}
