import * as React from "react"
import { FC, ReactElement, useEffect, useMemo } from "react"
import { classNames } from "../../utils/classNames"
import { NextButton } from "../buttons/NextButton"
import { uniqueId } from "lodash"

/**
 * Generic table component, with a table header and striped rows
 */

type TableHeader = { id: string; label: string; bold?: boolean; darkText?: boolean }
interface TableProps {
  headers: TableHeader[]
  rows: Array<{ id: string; [header: string]: string | ReactElement }>
  pagination?: number
  filter?: string
}

const Table: React.FunctionComponent<React.PropsWithChildren<TableProps>> = ({
  headers,
  rows,
  pagination,
  filter,
}) => {
  const initialPage = useMemo(() => (pagination ? 0 : undefined), [pagination])
  const [page, setPage] = React.useState(initialPage)
  const paginatedRows =
    page !== undefined && pagination ? rows.slice(page * pagination, (page + 1) * pagination) : rows

  // Reset pagination upon filter search
  useEffect(() => {
    setPage(initialPage)
  }, [filter, initialPage])
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            className="shadow overflow-hidden border-b border-neutral-400 sm:rounded-lg flex flex-col"
            style={pagination ? { minHeight: `${pagination * 50}px` } : {}}
          >
            <table className="min-w-full divide-y divide-neutral-400 flex-grow">
              <thead className="bg-neutral-100">
                <tr>
                  {headers.map((header) => (
                    <th
                      key={uniqueId()}
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium text-neutral-600 uppercase tracking-wider"
                    >
                      {header.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedRows.map((row, index) => (
                  <TableRow key={uniqueId()} row={row} index={index} headers={headers} />
                ))}
                {rows.length === 0 && (
                  <tr className="text-sm mx-auto my-4 w-full">
                    <td className="col-auto w-full text-center">No data to display.</td>
                  </tr>
                )}
              </tbody>
            </table>
            {page !== undefined && pagination !== undefined && (
              <TablePagination
                currentPage={page}
                pageSize={pagination}
                handlePageChange={setPage}
                totalRecords={rows.length}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

interface TableRowProps {
  row: { id: string; [header: string]: string | ReactElement }
  headers: TableHeader[]
  index: number
}

const TableRow: FC<React.PropsWithChildren<TableRowProps>> = ({ row, index, headers }) => {
  const orderedColumns = Object.keys(row)
    .filter((key) => key !== "id")
    .sort(
      (left, right) =>
        headers.findIndex(({ id }) => id === left) - headers.findIndex(({ id }) => id === right)
    )
  return (
    <tr key={uniqueId()} className={index % 2 === 0 ? "bg-neutral-white" : "bg-neutral-100"}>
      {orderedColumns.map((col) => {
        const header = headers.find((h) => h.id === col)
        return (
          <td
            key={uniqueId()}
            className={classNames(
              "px-3 py-3 whitespace-nowrap text-sm",
              header?.bold ? "font-medium" : "",
              header?.darkText ? "text-neutral-1000" : "text-neutral-700"
            )}
          >
            {row[col]}
          </td>
        )
      })}
    </tr>
  )
}

interface TablePaginationProps {
  pageSize: number
  currentPage: number
  totalRecords: number
  handlePageChange: (newPageNum: number) => void
}

const TablePagination: FC<React.PropsWithChildren<TablePaginationProps>> = ({
  pageSize,
  currentPage,
  totalRecords,
  handlePageChange,
}) => {
  const totalPages = Math.ceil(totalRecords / pageSize)

  return (
    <div className="flex space-x-2 items-center mt-2">
      <NextButton
        noIcon
        size="xs"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 0}
        color="white"
        text="Prev"
      />
      <div className="font-medium">
        Page {currentPage + 1} of {totalPages} ({totalRecords} total rows)
      </div>
      <NextButton
        noIcon
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages - 1}
        text="Next"
        size="xs"
        color="white"
      />
    </div>
  )
}

export default Table
