import { collections } from "common/firestore/Collections"
import { firestoreConverter } from "common/model/FirestoreConverter"
import { createContext, ReactNode, useMemo } from "react"
import { useFirebaseWriter } from "src/firebase/Context"
import {
  DocumentContextData,
  FirebaseDocumentProvider,
  useFirebaseDocuments,
} from "./FirebaseDocumentProvider"
import { DocumentIdContainer } from "./internal/DocumentIdContainer"
import { Company } from "common/model/Company"

export const CompanyContext = createContext<DocumentContextData<Company>>({
  documents: {},
  requestDocLookups: () => {},
})

export const CompanyProvider = ({ children }: { children: ReactNode }) => {
  const firebase = useFirebaseWriter()
  const companyCollection = useMemo(
    () =>
      firebase.writerDb
        .collection(collections.companies)
        .withConverter<Company>(firestoreConverter<Company>()),
    [firebase.writerDb]
  )
  return (
    <FirebaseDocumentProvider Context={CompanyContext} collection={companyCollection} noSnapshot>
      {children}
    </FirebaseDocumentProvider>
  )
}

export const useCompanies = <C extends DocumentIdContainer = DocumentIdContainer>(ids: C) =>
  useFirebaseDocuments(ids, CompanyContext)
