// A Holding represents the data we have about
// a shareholder's ownership of a company

import _ from "lodash"
import { v4 as uuid } from "uuid"
import { firestoreConverter } from "../FirestoreConverter"
import { HoldingOwnershipStructure } from "./HoldingOwnership"
import { Document } from "../Document"
import { User, UserIdFields, viewUserIdFields } from "../User"
import { AccountIdFields, viewAccountIdFields } from "../Account"
import { Company, CompanyIdFields, viewCompanyIdFields } from "../Company"
import { HoldingInviteEmailType } from "../EmailType"
import { filterMaybe, Just, Maybe, Nothing, nullableToMaybe } from "../../containers/Maybe"
import { annotate } from "../../utils/Coerce"
import { Structure } from "../data-product/pricing/TransactionStructure"
import { justIfNonempty } from "../../utils/data/Array/Nonempty"
import { UnsafeRec } from "../../utils/RecordUtils"
import { DealCRMContactIdFields } from "../DealCRM/DealCRMContact"
import { Indexable } from "../../container-interfaces/Indexable"
import { Validation } from "../../containers/Validation"
import { CSVSchema } from "../../csv/CSVSchema"
import { Loading, isLoaded } from "../../utils/Loading"
import { monthsBetweenDates } from "../../utils/dateUtils"
import { DealCRMInterest } from "../DealCRM/DealCRMInterest"

export type PositionIntent = "hold" | "sell" | "hedge" | "buy-more"

export type HoldingRole = "viewer" | "editor"

// export type HoldingStructure = "direct" | "spv" | "multi-layer-spv"

export type HoldingUser = {
  user: UserIdFields
  role: HoldingRole
}

export type HoldingNDA = Document

export type SubholdingId = string

export type HoldingIdFields = {
  id: string
  account: AccountIdFields
  company: CompanyIdFields
  shareholder:
    | {
        tag: "currentAccount"
      }
    | {
        tag: "crm_contact"
        contact: DealCRMContactIdFields
      }
}
export type Holding = HoldingIdFields & {
  // Token to allow unauthed users to query for holdings after Carta import
  cartaAuthStateToken?: {
    token: string
    expiresAt: Date
  } | null
  createdBy?: UserIdFields
  createdAt: Date
  updatedAt: Date
  dataSource: "crm" | "holding_form" | "carta"
  /** @deprecated use holding.onboardingForm */
  completedAt?: Date
  /** @deprecated use holding.onboardingForm */
  cancelledAt?: Date
  /** @deprecated use holding.onboardingForm */
  usersWithAccess?: Record<UserIdFields["id"], HoldingUser>
  /** @deprecated use holding.onboardingForm */
  ndaId?: HoldingNDA["id"]
  /** @deprecated use holding.onboardingForm */
  acceptedOverview?: boolean
  /** @deprecated use holding.onboardingForm */
  ownershipStructures?: HoldingOwnershipStructure[]
  /** @deprecated use holding.onboardingForm */
  emailInviteType?: HoldingInviteEmailType
  onboardingForm?: {
    completedAt?: Date
    cancelledAt?: Date
    usersWithAccess: Record<UserIdFields["id"], HoldingUser>
    ndaId: HoldingNDA["id"] | null
    acceptedOverview: boolean
    emailInviteType: HoldingInviteEmailType
    ownershipStructures: HoldingOwnershipStructure[]
  }
  investments: Record<SubholdingId, InvestmentHolding>
  grants: Record<SubholdingId, GrantHolding>
  summary?: {
    dollarAmount?: number
    numShares?: number
    shareClass?: ShareClass
    ownershipStructure?: Structure
    // structure?: Structure
    spvManagementFee?: number
    spvCarry?: number
    // spvLayers?: number
  }

  // options: Array<OptionPosition>
  intent?: PositionIntent
  isDeleted?: boolean
}

export type ContactHolding = Holding & {
  shareholder: {
    tag: "crm_contact"
    contact: DealCRMContactIdFields
  }
}

export const holdingUsers = (holding: Holding): HoldingUser[] =>
  _.uniqBy(
    UnsafeRec.values(holding?.usersWithAccess ?? {}).concat(
      UnsafeRec.values(holding.onboardingForm?.usersWithAccess ?? {})
    ),
    (u) => u.user.id
  )

export const shareClasses = ["common", "preferred"] as const
export const holdingTypes = ["investment", "grant"] as const

export type ShareClass = (typeof shareClasses)[number]
export type HoldingType = (typeof holdingTypes)[number]
export interface ShareHolding {
  id: string
  holdingType: HoldingType
  ownershipStructure?: Structure // -> Display as direct ownership, indirect (SPV) ownership
  estimatedNumShares?: number // NOTE: Discussed to set as number instead of Interval
  estimatedValue?: number
  numShares?: number
  shareClass?: ShareClass // TODO: maybe we can constrain it based on company
  acquisitionDate?: Date
  cartaHoldingId?: string
  updatedAt: Date
  createdAt: Date
}

export interface InvestmentHolding extends ShareHolding {
  holdingType: "investment"
  assetType: "stock" | "convertible_note" | "warrant"
  costBasis?: number
  carry?: number
  managementFee?: number
}
export interface GrantHolding extends ShareHolding {
  holdingType: "grant"
  grantType?: "shares" | "options" | "rsus" | "rsas"
  strikePrice?: number
  vestingPercentage?: number
  vestingStartDate?: Date
  vestingEndDate?: Date
  vestingDurationInMonths?: number
  vestingCliffInMonths?: number
  exercises: StockExercise[]
}

export const isGrantSubholding = (subholding: ShareHolding): subholding is GrantHolding =>
  subholding.holdingType === "grant"

export const isInvestmentSubholding = (subholding: ShareHolding): subholding is InvestmentHolding =>
  subholding.holdingType === "investment"
export interface StockExercise {
  id: string // Cap Table Provider ID, or a random UUID
  exerciseDate: Date
  numShares: number
  exercisePrice: number
  status: "pending" | "exercised" | "cancelled"
}

// TODO: Add back this on Portfolio(V2)
// interface OptionPosition {
//   optionType: "put" | "call"
//   contractDirection: "long" | "short" // long = option buyer, short = option writer. TODO: consider buyer/seller to reduce confusion
//   strikePPS: number
//   style: OptionStyle
//   notionalNumShares: number
//   contractExpirationDate: Date
//   transactionSpotPPS?: number
//   transactionDate: Date
// }

export type NewHolding = Omit<Holding, "id">

export const holdingConverter = firestoreConverter<Holding>()

export type CreateHoldingOnboardingFormInput = {
  accountAirtableId: string
  companyId: string
  contactAirtableId: string
  ndaTemplateId: string
  emailInviteType: HoldingInviteEmailType
}

export * from "./HoldingOwnership"

export const viewHoldingIdFields = (holding: HoldingIdFields): HoldingIdFields => ({
  id: holding.id,
  account: holding.account,
  company: holding.company,
  shareholder: holding.shareholder,
})

export const newMinimalHolding = (
  company: CompanyIdFields,
  account: AccountIdFields,
  user: UserIdFields
): Omit<Holding, "id"> => ({
  account: viewAccountIdFields(account),
  company: viewCompanyIdFields(company),
  createdBy: viewUserIdFields(user),
  createdAt: new Date(),
  updatedAt: new Date(),
  investments: {},
  grants: {},
  shareholder: {
    tag: "currentAccount",
  },
  dataSource: "holding_form",
  // options: [], TODO: add options on V2
})

export const totalHoldingValue = (holding: Holding, company: Company) => {
  const shareHoldings: ShareHolding[] = annotate<ShareHolding[]>(
    Object.values(holding.investments)
  ).concat(Object.values(holding.grants))

  const shareHoldingsValue = shareHoldings.map(shareHoldingValue(company))
  const optionHoldingsValue = Nothing
  const totalSubholdingValue = shareHoldingsValue.concat(optionHoldingsValue)

  return totalSubholdingValue.reduce(
    (sum, current) =>
      sum.matchStrict(
        // (sumInterval) => current.map((interval) => sumIntervals(sumInterval, interval)),
        (_sum) => current.map((holdingNum) => _sum + holdingNum),
        current
      ),
    Nothing
  )
}

export const totalHoldingShares = (
  holding: Holding,
  options?: {
    includeUnvested?: boolean
  }
): Maybe<number> => {
  const shareHoldings: ShareHolding[] = annotate<ShareHolding[]>(
    Object.values(holding.investments)
  ).concat(Object.values(holding.grants))

  const shareHoldingsNumShares = shareHoldings.map((subholding) =>
    shareHoldingNumShares(subholding, options)
  )

  const optionHoldingsNumShares = Nothing
  // const optionHoldingsNumShares = holding.options.map(
  //   () =>
  //     //TODO: implement me
  //     Nothing
  // )

  const totalSubholdingShares = shareHoldingsNumShares.concat(optionHoldingsNumShares)

  return justIfNonempty(filterMaybe(totalSubholdingShares)).map(_.sum)
}

export const isMinimalHolding = (holding: Holding) =>
  // _.isEmpty(holding.investments) && _.isEmpty(holding.grants) && holding.options.length === 0
  _.isEmpty(holding.investments) && _.isEmpty(holding.grants)

const shareHoldingValue =
  (company: Company) =>
  (holding: ShareHolding): Maybe<number> => {
    const marketPrice: Maybe<number> = nullableToMaybe(
      company.priceEstimatesSummary?.currentPrice?.priceEstimatePPS
    )
    const pricePerShare = marketPrice.or(
      nullableToMaybe(company.fundingRoundsSummary?.lastKnownPPS?.pps)
    )

    const estimatedValueRange = nullableToMaybe(holding.estimatedValue)

    return pricePerShare.bind((price) =>
      shareHoldingNumShares(holding)
        .map((numShares) => numShares * price)
        .or(estimatedValueRange)
        .or(Nothing)
    )
  }

const shareHoldingNumShares = (
  holding: ShareHolding,
  options?: {
    includeUnvested?: boolean
  }
): Maybe<number> => {
  const numSharesActual = nullableToMaybe(holding.numShares)
  const numSharesEstimated = nullableToMaybe(holding.estimatedNumShares)

  if (isGrantSubholding(holding) && !options?.includeUnvested) {
    return Just(grantHoldingVestedShareCount(holding))
  }

  return numSharesActual.or(numSharesEstimated)
}
export type MinimalHolding = Omit<Holding, "shareholder"> | null
export const minimalHoldingSchema = (
  user: User,
  companyNameLookup: Indexable<string, Loading<CompanyIdFields>>
): CSVSchema<
  {
    company: Loading<CompanyIdFields> | null
    shareCount: number | null
    estimatedDollarAmount: number | null
    ownershipStructure: "direct" | "indirect" | "other" | null
    spvManagementFee: number | null
    spvCarry: number | null
    intent: "buy" | "sell" | "hold" | null
  },
  MinimalHolding
> => ({
  row: {
    company: (name) => {
      if (name.trim() === "") {
        return Validation.Ok(null)
      }
      const company = companyNameLookup.get(name)
      return company || company === "loading"
        ? Validation.Ok(company)
        : Validation.Problem(`Company with name ${name} not found`)
    },
    ownershipStructure: (x) =>
      ((s) =>
        x.trim() === ""
          ? Validation.Ok(null)
          : s === "direct" || s === "indirect" || s === "other"
          ? Validation.Ok(s)
          : Validation.Problem(s))(x.trim().toLowerCase()),
    shareCount: (s) =>
      !Number.isNaN(Number.parseInt(s, 10))
        ? Validation.Ok(Number.parseInt(s, 10))
        : s.trim() === ""
        ? Validation.Ok(null)
        : Validation.Problem(s),
    estimatedDollarAmount: (s) =>
      !Number.isNaN(Number.parseFloat(s))
        ? Validation.Ok(Number.parseFloat(s))
        : s.trim() === ""
        ? Validation.Ok(null)
        : Validation.Problem(s),
    spvManagementFee: (s) => {
      const num = Number.parseFloat(s)
      return !Number.isNaN(num)
        ? num > 1
          ? Validation.Ok(num / 100)
          : Validation.Ok(num)
        : s.trim() === ""
        ? Validation.Ok(null)
        : Validation.Problem(s)
    },
    spvCarry: (s) => {
      const num = Number.parseFloat(s)
      return !Number.isNaN(num)
        ? num > 1
          ? Validation.Ok(num / 100)
          : Validation.Ok(num)
        : s.trim() === ""
        ? Validation.Ok(null)
        : Validation.Problem(s)
    },
    intent: (s) => {
      const lower = s.toLowerCase()
      return lower.trim() === ""
        ? Validation.Ok(null)
        : lower === "buy" || lower === "sell" || lower === "hold"
        ? Validation.Ok(lower)
        : Validation.Problem("Intent should be `buy`, `sell`, or `hold`")
    },
  },
  reassemble: (r) => {
    if (Object.values(r).every((x) => x === null)) {
      return Validation.Ok(null)
    }
    if (r.intent === null) {
      return Validation.Problem(["Intent should be `buy`, `sell`, or `hold`"])
    }

    return isLoaded(r.company)
      ? Validation.Ok({
          dataSource: "crm",
          account: viewAccountIdFields(user.account),
          company: viewCompanyIdFields(r.company),
          id: uuid(),
          accountId: user.account.id,
          intent: r.intent === "buy" ? "buy-more" : r.intent,
          investments: {},
          grants: {},
          createdAt: new Date(),
          updatedAt: new Date(),
          summary: {
            dollarAmount: r.estimatedDollarAmount ?? undefined,
            numShares: r.shareCount ?? undefined,
            ownershipStructure:
              r.ownershipStructure === "other" || r.ownershipStructure === null
                ? "unknown"
                : r.ownershipStructure ?? undefined,
            spvCarry: r.spvCarry ?? undefined,
            spvManagementFee: r.spvManagementFee ?? undefined,
          },
          createdBy: viewUserIdFields(user),
        })
      : Validation.Problem(["Searching for company..."])
  },
})

export const minimalHoldingCRMInterest = (
  holding: Holding & { intent: "buy-more" | "sell" }
): DealCRMInterest => {
  const result: DealCRMInterest = {
    sourceHolding: viewHoldingIdFields(holding),
    company: viewCompanyIdFields(holding.company),
    direction: holding.intent === "buy-more" ? "buy" : holding.intent,
    createdAt: new Date(),
    updatedAt: [new Date()],
    source: {
      sourceType: "crm-holding-import",
      sourceId: holding.id,
    },
    orderOriginationDate: new Date(),
    shareClass: holding.summary?.shareClass ? holding.summary.shareClass : null,
    firmness: null,
    lastUpdatedAt: new Date(),
    structure: (() => {
      const subholdings = UnsafeRec.values<string, ShareHolding>(holding.investments).concat(
        UnsafeRec.values(holding.grants)
      )
      return subholdings.some((i) => i.ownershipStructure === "spv")
        ? "spv"
        : subholdings.some((i) => i.ownershipStructure === "forward")
        ? "forward"
        : "direct"
    })(),
    contact: holding.shareholder.tag === "crm_contact" ? holding.shareholder.contact : null,
    amountUSD: null,
    targetPrice: null,
    targetValuation: null,
    managementFeePercent: holding.summary?.spvManagementFee ?? null,
    managementFeeStructure: null,
    carryPercent: holding.summary?.spvCarry ?? null,
    id: uuid(),
    updates: [],
    commissionPercent: null,
    tag: "crm-interest",
    account: holding.account,
  }
  return result
}
const grantHoldingVestedShareCount = (grant: GrantHolding): number => {
  const numShares = grant.numShares ?? 0
  if (!numShares) return 0
  else if (grant.grantType === "shares" || grant.grantType === "rsus") {
    return numShares
  } else {
    return Math.round(
      calculateGrantVestingPercentage(grant)
        .map((percentage) => numShares * percentage)
        .withDefault(0)
    )
  }
}

export const calculateGrantVestingPercentage = (grant: GrantHolding): Maybe<number> => {
  if (!grant.vestingStartDate || !grant.vestingDurationInMonths)
    return nullableToMaybe(grant.vestingPercentage)
  const monthsVested = monthsBetweenDates(grant.vestingStartDate, new Date())

  if (grant.vestingCliffInMonths !== undefined) {
    const metCliff = monthsVested >= grant.vestingCliffInMonths
    if (!metCliff) return Just(0)
  }
  return Just(monthsVested / grant.vestingDurationInMonths)
}

export const numExercisedShares = (holding: Holding): number => {
  const grants = Object.values(holding.grants)

  const exercisedShares = grants.flatMap((grant) =>
    grant.exercises.filter((exercise) => exercise.status === "exercised")
  )
  return _.sumBy(exercisedShares, "numShares")
}

export const calculateVestingSchedule = (params: {
  vestingStartDate?: Date
  vestingEndDate?: Date
}):
  | Pick<
      GrantHolding,
      "vestingStartDate" | "vestingEndDate" | "vestingDurationInMonths" | "vestingCliffInMonths"
    >
  | undefined => {
  if (!params.vestingStartDate || !params.vestingEndDate) return undefined
  const months = monthsBetweenDates(params.vestingStartDate, params.vestingEndDate)
  return {
    vestingStartDate: params.vestingStartDate,
    vestingEndDate: params.vestingEndDate,
    vestingDurationInMonths: months,
    vestingCliffInMonths: months >= 36 ? 12 : undefined,
  }
}

export const isGrantFullyVested = (grant: GrantHolding): boolean =>
  calculateGrantVestingPercentage(grant).withDefault(1) === 1

export const numExercisedOptions = (grant: GrantHolding): number =>
  grant.grantType === "options"
    ? grant.exercises
        .filter((exercise) => exercise.status === "exercised")
        .map((exercise) => exercise.numShares)
        .reduce((sum, numShares) => sum + numShares, 0)
    : 0

export const isOptionGrantFullyExercised = (grant: GrantHolding): boolean =>
  !!grant.numShares && numExercisedOptions(grant) === grant.numShares
