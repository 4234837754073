import _ from "lodash"
import { AssociationList, defaultAssocList } from "../../../utils/data/AssociationList"
import { Interval } from "../../../utils/data/Interval"
import { Func } from "../../../utils/fp/Function"
import { Just, Maybe, Nothing, nullableToMaybe } from "../../../containers/Maybe"
import { DualizeUnion, mergeRecords, overField, Projection, Rec } from "../../../utils/RecordUtils"
import { UnionUnder } from "../../../utils/data/Record/Types/Pointwise"
import { definiteTransactionStructures } from "../../data-product/pricing/TransactionStructure"
import { UpdateLog, UpdateLog_ } from "./UpdateLog"
import * as Internal from "./Terms/Quantity"
import { FlatSum } from "../../../utils/types/Sum"
import { assertExhaustive } from "../../../utils/data/Array/Exhaustive"
import { DataDisplayConstants } from "../../../UI/DataDisplayConstants"

export type FullOrderQuantityTerms = {
  amountUSD: Interval<number>
  shares: Interval<number>
  USDPerShare: Interval<number>
  targetValuation: number
}
export type NonNormalizedOrderQuantityTerms = {
  id: string
  amountUSD?: Interval<number> | null
  shares?: Interval<number> | null
  USDPerShare?: Interval<number> | null
  targetValuation?: number | null
}

export const existingTermsToNonNormalizedOrderQuantityTerms = (
  terms: OrderQuantityTerms
): UnionUnder<null, FullOrderQuantityTerms> & { id: string } => ({
  amountUSD: terms.amountUSD || null,
  shares: terms.shares || null,
  USDPerShare: terms.USDPerShare || null,
  targetValuation: terms.targetValuation || null,
  id: terms.id,
})

export const normalizeOrderQuantityTerms = (
  u: NonNormalizedOrderQuantityTerms
): Maybe<OrderQuantityTerms> =>
  (u.amountUSD && u.shares
    ? Just({ amountUSD: u.amountUSD, shares: u.shares, id: u.id })
    : u.amountUSD && u.USDPerShare
    ? Just({ amountUSD: u.amountUSD, USDPerShare: u.USDPerShare, id: u.id })
    : u.USDPerShare && u.shares
    ? Just({ USDPerShare: u.USDPerShare, shares: u.shares, id: u.id })
    : u.targetValuation && u.amountUSD
    ? Just({ targetValuation: u.targetValuation, amountUSD: u.amountUSD, id: u.id })
    : u.targetValuation && u.shares
    ? Just({ targetValuation: u.targetValuation, shares: u.shares, id: u.id })
    : u.amountUSD
    ? Just({ amountUSD: u.amountUSD, id: u.id })
    : Nothing
  ).map(Internal.makeOrderQuantityTerms)

export type OrderQuantityTerms = Internal.OrderQuantityTerms

export const { orderQuantityTermsPriceInterval } = Internal
export const { orderQuantityTermsPrice } = Internal
export const { orderQuantityTermsShares } = Internal
export const { orderQuantityTermsVolume } = Internal
export const { orderQuantityTermsTargetValuation } = Internal

export const optionStyles = ["American", "European"] as const
export type OptionStyle = (typeof optionStyles)[number]

export type OptionTerms = {
  strike: number
  expiration: Date
  style: OptionStyle
}
export const orderStructures = assertExhaustive([
  ...definiteTransactionStructures,
  "unknown",
  "structure_agnostic_regular_way",
])

// eslint-disable-next-line @typescript-eslint/naming-convention
export const _checkAllOrderStructuresListed: keyof StructureOrderTerms extends (typeof orderStructures)[number]
  ? true
  : false = true

/** @deprecated */
export type StructureOrderTerms = {
  unknown: {}
  structure_agnostic_regular_way: {}
  direct: {} // Figure out what goes in these later
  spv: { carry: number | null; managementFee: number | null }
  forward: {}
  call_option: OptionTerms
  put_option: OptionTerms
  swap: {}
  variable_prepaid_forward: {
    USDPerShareForMaximumSharesDelivered: number
    USDPerShareForMinimumSharesDelivered: number
  }
  collective_liquidity_exchange_fund: {}
}

export type TaggedStructureOrderTerms = FlatSum<
  [
    ["unknown", {}],
    ["structure_agnostic_regular_way", {}],
    ["direct", {}],
    ["spv", { carry: number | null; managementFee: number | null }],
    ["forward", {}],
    ["call_option", OptionTerms],
    ["put_option", OptionTerms],
    ["swap", {}],
    [
      "variable_prepaid_forward",
      { USDPerShareForMaximumSharesDelivered: number; USDPerShareForMinimumSharesDelivered: number }
    ],
    ["collective_liquidity_exchange_fund", {}]
  ]
>

export const projectStructureOrderTerms: Projection<StructureOrderTerms> = {
  unknown: () => ({}),
  structure_agnostic_regular_way: () => ({}),
  direct: () => ({}),
  spv: ({ carry, managementFee }) => ({ carry, managementFee }),
  forward: () => ({}),
  call_option: ({ strike, expiration, style }) => ({ strike, expiration, style }),
  put_option: ({ strike, expiration, style }) => ({ strike, expiration, style }),
  swap: () => ({}),
  variable_prepaid_forward: ({
    USDPerShareForMaximumSharesDelivered,
    USDPerShareForMinimumSharesDelivered,
  }) => ({ USDPerShareForMaximumSharesDelivered, USDPerShareForMinimumSharesDelivered }),
  collective_liquidity_exchange_fund: () => ({}),
}

export type OrderTerms = {
  [key in keyof StructureOrderTerms]: AssociationList<
    StructureOrderTerms[key], // {} {carry: ...}
    UpdateLog<OrderQuantityTerms | null>
  >
}

export type OrderSlice = {
  [key in keyof StructureOrderTerms]: {
    date: Date
    terms: StructureOrderTerms[key]
    priceAndQuantity: OrderQuantityTerms | null
  } | null
}

export type FlatOrderQuantityTerms = UnionUnder<
  null,
  Pick<OrderQuantityTerms, "USDPerShare" | "amountUSD" | "shares" | "targetValuation">
>
export type FlatOrderSlice = { quantity: OrderQuantityTerms | null } & UnionUnder<
  null,
  Required<DualizeUnion<StructureOrderTerms[keyof StructureOrderTerms]>>
> & {
    structure: keyof StructureOrderTerms
    date: Date | null
  }

export const uniformOrderTerms = (
  x: UnionUnder<undefined, StructureOrderTerms>,
  date: Date,
  c: OrderQuantityTerms
): OrderTerms => {
  const result = Rec.map(x, (v) =>
    v === undefined
      ? []
      : [
          {
            key: v,
            value: UpdateLog_.singleton(date, c),
          },
        ]
  )
  return result as OrderTerms
} // todo fix

export const singletonTerm = <K extends keyof StructureOrderTerms>(
  date: Date,
  k: StructureOrderTerms[K],
  v: OrderQuantityTerms
): OrderTerms[K] => [{ key: k, value: UpdateLog_.singleton(date, v) }] as OrderTerms[K] // TODO fix

export const mergeTerms: {
  [key in keyof OrderTerms]: (older: OrderTerms[key], newer: OrderTerms[key]) => OrderTerms[key]
} = {
  unknown: (older, newer) => defaultAssocList.unionBy(newer, older, mergeRecords),
  structure_agnostic_regular_way: (older, newer) =>
    defaultAssocList.unionBy(newer, older, mergeRecords),
  direct: (older, newer) => defaultAssocList.unionBy(newer, older, mergeRecords),
  call_option: (older, newer) => defaultAssocList.unionBy(newer, older, mergeRecords),
  put_option: (older, newer) => defaultAssocList.unionBy(newer, older, mergeRecords),
  spv: (older, newer) => defaultAssocList.unionBy(newer, older, mergeRecords),
  swap: (older, newer) => defaultAssocList.unionBy(newer, older, mergeRecords),
  forward: (older, newer) => defaultAssocList.unionBy(newer, older, mergeRecords),
  variable_prepaid_forward: (older, newer) => defaultAssocList.unionBy(newer, older, mergeRecords),
  collective_liquidity_exchange_fund: (older, newer) =>
    defaultAssocList.unionBy(newer, older, mergeRecords),
}
export const mergeOrderTerms = (l: OrderTerms, r: OrderTerms) => Rec.zipWith(mergeTerms)(l, r)

export type OrderStructure = keyof OrderTerms
export const orderStructureDisplayName: Record<OrderStructure, string> = {
  unknown: "Unspecified",
  structure_agnostic_regular_way: "N/A",
  put_option: "Put Option",
  call_option: "Call Option",
  direct: "Direct",
  spv: "SPV",
  swap: "Swap",
  forward: "Forward",
  variable_prepaid_forward: "Variable Prepaid Forward",
  collective_liquidity_exchange_fund: "Exchange Fund",
}
export const orderStructureShortDisplayName = {
  ...orderStructureDisplayName,
  variable_prepaid_forward: "VPPF",
}
export const mapOrderQuantityTerms =
  (f: Func<OrderQuantityTerms, OrderQuantityTerms>) =>
  (t: OrderTerms): OrderTerms =>
    Rec.map(t, (x: OrderTerms[keyof OrderTerms]) =>
      x.map((kvp) => overField("value", (v) => Rec.map(v, (q) => (q ? f(q) : q)), kvp))
    ) as typeof t

const mapOrderUpdateLog =
  (f: Func<[number, OrderQuantityTerms], OrderQuantityTerms>) =>
  (t: UpdateLog<OrderQuantityTerms | null>): UpdateLog<OrderQuantityTerms | null> =>
    Rec.indexedMapSimple(t, (k, v) => (v ? f([k, v]) : v))

export const indexedMapOrderQuantityTerms =
  (f: Func<[number, OrderQuantityTerms], OrderQuantityTerms>) =>
  (t: OrderTerms): OrderTerms =>
    Rec.map(t, (x: OrderTerms[keyof OrderTerms]) =>
      x.map((kvp) => overField("value", mapOrderUpdateLog(f), kvp))
    ) as typeof t

export interface FlatOrderTerm {
  structure: keyof StructureOrderTerms
  priceAndQuantity: OrderQuantityTerms
  date: Date
  key: StructureOrderTerms[keyof StructureOrderTerms]
}

export const equalFlatTerms = (a: FlatOrderTerm, b: FlatOrderTerm) =>
  a.date.valueOf() === b.date.valueOf() &&
  _.isEqual(a.priceAndQuantity, b.priceAndQuantity) &&
  a.structure === b.structure &&
  _.isEqual(a.key, b.key)

export const formatSPVTerms = ({
  carry,
  mgmt,
  structureLayersCount,
}: {
  carry: Maybe<number | null>
  mgmt: Maybe<number | null>
  structureLayersCount: Maybe<number | null>
}) => {
  const carryStr = carry.bind(nullableToMaybe).match(
    (c) => (c < 0.5 && c > 0 ? (c * 100).toFixed(0) : c.toFixed(0)),
    () => DataDisplayConstants.noValue
  )
  const mgmtStr = mgmt.bind(nullableToMaybe).match(
    (c) => (c < 0.5 && c > 0 ? (c * 100).toFixed(0) : c.toFixed(0)),
    () => DataDisplayConstants.noValue
  )
  const layers = structureLayersCount.match(
    (n) => (n && n > 1 ? "(Multi-layered)" : ""),
    () => ""
  )
  return `SPV (${mgmtStr}/${carryStr}) ${layers}`
}
