export type Func<A, B> = (a: A) => B
export type Endo<A> = (a: A) => A

/** Compose two functions */
export const compose =
  <Y, Z>(f: Func<Y, Z>) =>
  <X>(g: Func<X, Y>): Func<X, Z> =>
  (x) =>
    f(g(x))

/** `compose`, but backwards. Typescript's typechecker is much worse at inferring input types from output types than at inferring outputs from inputs, so rearranging arguments can sometimes help cut down on unnecessary type annotations.
 */
export const pipe =
  <X, Y>(f: Func<X, Y>) =>
  <Z>(g: Func<Y, Z>): Func<X, Z> =>
  (x) =>
    g(f(x))

/** Function composition, but with a pedagogically useful name */
export const mapFunc = compose

/** The constant function */
// eslint-disable-next-line prettier/prettier
export const constant =
  <Y>(y: Y): Func<unknown, Y> =>
  () =>
    y
export const pureFunc = constant

/** Zip together two functions by precomposing them with a function of two arguments */
export const lift2Func =
  <A, X, Y, Z>(f: (x: X, y: Y) => Z) =>
  (l: Func<A, X>, r: Func<A, Y>): Func<A, Z> =>
  (a: A) =>
    f(l(a), r(a))

/** Flatten a function (x) => (x) => (y) by providing the same value to both arguments */
export const joinFunc =
  <A, X>(f: Func<A, Func<A, X>>): Func<A, X> =>
  (a: A) =>
    f(a)(a)

/** Takes a function (x => y) and a function (y => (x => z)), and returns a function (x => z) */
export const bindFunc =
  <A, X, Y>(f: Func<X, Func<A, Y>>) =>
  (x: Func<A, X>) =>
    joinFunc(mapFunc(f)(x))

/** The identity function: takes an input, and returns it. */
export const identity = <X>(x: X): X => x

/** Takes two arguments, puts them in a tuple, returns it. This is useful because typescript is inexplicably horrible at inferring tuple types */
export const tuple =
  <X>(x: X) =>
  <Y>(y: Y): [X, Y] =>
    [x, y]

/** Func<F, G> is a subtype of Func<F', G'> if G is a subtype of G' and F' is a subtype of F.
 * `never` is a subtype of every other type, and every type is a subtype of `unknown`, so `Func<never, unknown>` is the maximal function type
 */
export type Absurd = Func<never, unknown>

/** Variadic `Absurd` */
export type VAbsurd = (...args: never[]) => unknown
/** Use this to satisfy overly strict exhaustiveness checks */
export const assertUnreachable = (_: never): never => {
  throw new Error(
    "`assertUnreachable` was called. This should be impossible, and indicates the existence of a type error further upstream"
  )
}

export type IsFunction<F> = F extends Absurd ? true : false

export const spreadArgs =
  <Args extends unknown[], T>(f: (args: Args) => T) =>
  (...args: Args) =>
    f(args)
export const unspreadArgs =
  <Args extends unknown[], T>(f: (...args: Args) => T) =>
  (args: Args) =>
    f(...args)
