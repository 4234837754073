import { Loading } from "common/utils/Loading/Wrapped"
import { useCurrentUser } from "../../../../providers/currentUser/useCurrentUser"
import { OrderPageLoadingOrder } from "../types"
import LoadedOrderLog from "./components/LoadedOrderLog"

const OrderPageOrderLog = ({ order }: OrderPageLoadingOrder) =>
  order
    .alongside(new Loading(useCurrentUser()))
    .match(
      ([loadedOrder, { user }]) =>
        loadedOrder.fromAccount(user.account) ? <LoadedOrderLog order={loadedOrder} /> : null,
      null,
      null
    )

export default OrderPageOrderLog
