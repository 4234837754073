import { identity } from "./fp/Function"
import { Just, Maybe, Nothing } from "../containers/Maybe"

export const memoize = <T>(f: () => T): (() => T) => {
  // eslint-disable-next-line rulesdir/no-let
  let val: Maybe<T> = Nothing
  // this is extremely hacky and should not be imitated
  return () =>
    val.match(identity, () => {
      const result = f()
      // eslint-disable-next-line better-mutation/no-mutation
      val = Just(result)
      return result
    })
}
