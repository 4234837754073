import { Color, Size, Typography } from "@stories/components/Typography/Typography"
import { AllAccountsCacheFields } from "common/model/Account"
import { isLoaded } from "common/utils/Loading"
import { useEffect } from "react"
import { useAdminGetAllAccounts } from "src/queries/Account/useAdminGetAllAccounts"
import { useLoggedInUser } from "../../../../providers/loggedInUser/useLoggedInUser"
import { classNames } from "../../../../utils/classNames"
import SectionHeader from "./SectionHeader"

const AdminAccountSearchRow = ({
  account,
  onClick,
}: {
  account: AllAccountsCacheFields
  onClick: () => void
}) => (
  <div className="flex flex-col gap-1 p-2 border-b border-neutral-400" onClick={onClick}>
    <div className="flex justify-between">
      <Typography text={account.name} size={Size.Small} />
      <Typography text={account.clientType.join(", ")} size={Size.Small} color={Color.Subtitle} />
    </div>
    <div className="flex justify-between">
      <Typography
        text={account.productAreas
          .map((area) => `${area}: ${account.onboardingStatus[area]?.status ?? "invalid"}`)
          .join(", ")}
        size={Size.XSmall}
        color={Color.Subtitle}
      />
    </div>
  </div>
)

type AdminAccountSearchSectionProps = {
  searchTerm: string
  isHighlighted: (id: string) => boolean
  matchingAccounts: AllAccountsCacheFields[]
  setMatchingAccounts: (accounts: AllAccountsCacheFields[]) => void
  onClick: (account: AllAccountsCacheFields) => void
}
const AdminAccountSearchSection = ({
  searchTerm,
  isHighlighted,
  matchingAccounts,
  setMatchingAccounts,
  onClick,
}: AdminAccountSearchSectionProps) => {
  if (!useLoggedInUser().isAdmin) {
    // NOTE: this is just a safety measure and opportunity for Sentry to alert us
    // the parent should be checking isAdmin before rendering this component
    throw new Error("Attempted to render an admin-only component for a non-admin user")
  }
  const accounts = useAdminGetAllAccounts()

  useEffect(() => {
    const matching =
      searchTerm && isLoaded(accounts)
        ? accounts
            .filter(({ name }) => name.toLowerCase().includes(searchTerm.toLowerCase()))
            // puts results that match start of name first in list
            // safe to .sort in place because previous step .filter returns a new array
            .sort((l, r) =>
              l.name.toLowerCase().startsWith(searchTerm.toLowerCase())
                ? -1
                : r.name.toLowerCase().startsWith(searchTerm.toLowerCase())
                ? 1
                : 0
            )
            .slice(0, 5)
        : []
    setMatchingAccounts(matching)
  }, [setMatchingAccounts, accounts, searchTerm])

  return (
    <div className="flex flex-col">
      <SectionHeader title="Admin: Accounts" />
      {matchingAccounts.map((account) => (
        <div
          key={account.id}
          className={classNames(
            "cursor-pointer hover:bg-neutral-200",
            isHighlighted(account.id) && "bg-primary-100"
          )}
        >
          <AdminAccountSearchRow onClick={() => onClick(account)} account={account} />
        </div>
      ))}
      {matchingAccounts.length === 0 ? (
        <div className="text-gray-500 p-2">
          {searchTerm ? "No matching accounts" : "Start typing to search accounts"}
        </div>
      ) : null}
    </div>
  )
}

export default AdminAccountSearchSection
