import { Select } from "@stories/components/Antd"
import { DefaultOptionType } from "@stories/components/Antd"
import { useEffect, useMemo, useState } from "react"
import { randomString } from "common/utils/RandomUtils"
import { handleConsoleError } from "../../utils/Tracking"
import { classNames } from "../../utils/classNames"

export interface OptionType<T extends string = string> extends DefaultOptionType {
  value: T
  label: string
  suggested: boolean
}

export const stringArrayToOptions = <T extends string>(values: readonly T[]) =>
  values.map((item) => ({ value: item, label: item, suggested: false }))

export const namedRecordToOptionType = (rec: {
  id: string
  name: string
  suggested?: boolean
}): OptionType<string> => ({
  label: rec.name,
  value: rec.id,
  suggested: rec.suggested || false,
})

interface MultiSelectProps<T, S extends string = string> {
  options?: Array<T & OptionType<S>>
  selectedOptions: Array<T & OptionType<S>>
  setSelectedOptions: (values: Array<T & OptionType<S>>) => void
  loadOptions?: () => Promise<Array<T & OptionType<S>>>
  createNewValue?: (label: string) => Promise<T & OptionType<S>>
  placeholder?: string
  mode?: "tags" | "multiple" | "single"
  optionsPreSorted?: boolean
  selectionLimit?: number
  onFocus?: () => void
  customSuggestionsIds?: string[]
  testId?: string
  className?: string
  wrapperClassName?: string
  displaySelectionBelow?: boolean
}

export function MultiSelect<T, S extends string = string>(props: MultiSelectProps<T, S>) {
  type EnrichedOptionType = T & OptionType<S>
  const [open, setOpen] = useState<boolean>(false) // Controlled open state of the dropdown menu
  const [optionData, setOptionData] = useState<EnrichedOptionType[] | undefined>()
  const domElementId = useMemo(randomString, [])

  const closeMenu = () => {
    setOpen(false)
    document.getElementById(domElementId)?.blur()
  }

  const sortByLabel = (optionA: DefaultOptionType, optionB: DefaultOptionType) => {
    if ((optionA.label || "") > (optionB.label || "")) return 1
    return -1
  }

  const excludeSelectedOptions = (
    selectedOptions: EnrichedOptionType[],
    allOptions: EnrichedOptionType[]
  ) =>
    allOptions.filter((option) => !selectedOptions.map((item) => item.value).includes(option.value))

  const visibleOptions = optionData
    ? excludeSelectedOptions(props.selectedOptions, optionData)
    : undefined

  const sortOptions = useMemo(
    () => (options: EnrichedOptionType[]) =>
      props.optionsPreSorted ? options : options.sort(sortByLabel),
    [props.optionsPreSorted]
  )

  useEffect(() => {
    if (props.options) {
      const allOptionData = sortOptions(props.options)
      setOptionData(allOptionData)
    } else if (props.loadOptions) {
      props
        .loadOptions()
        .then((options) => {
          const withCustomSuggestions = options.map((item) =>
            props.customSuggestionsIds
              ? { ...item, suggested: props.customSuggestionsIds.includes(item.value) }
              : item
          )
          const allOptionData = sortOptions(withCustomSuggestions)
          setOptionData(allOptionData)
        })
        .catch((err) => handleConsoleError(err))
    }
  }, [props, sortOptions])

  const handleChange = (values: string[] | string) => {
    if (typeof values === "string") {
      props.setSelectedOptions((optionData || []).filter((item) => item.value === values))
      closeMenu()
    } else {
      const items = visibleOptions?.filter((item) => values.includes(item.value)) || []
      const newValue = values.find((value) => !optionData?.find((item) => item.value === value))
      if (newValue && props.createNewValue) {
        props
          .createNewValue(newValue)
          .then((createdOption) =>
            props.setSelectedOptions(
              [...items, createdOption, ...props.selectedOptions].slice(0, props.selectionLimit)
            )
          )
          .then(() => (props.selectionLimit ? closeMenu() : void 0))
          .catch((err) => handleConsoleError(err))
      } else {
        const newSelectedSet = items.concat(props.selectedOptions)
        props.setSelectedOptions(newSelectedSet.slice(0, props.selectionLimit))
        if (props.selectionLimit) closeMenu()
      }
    }
  }

  const removeItem = (value: string) => {
    const newSelectedSet = props.selectedOptions.filter((item) => item.value !== value)
    props.setSelectedOptions(newSelectedSet)
  }

  const renderedSelection = (wrapperClass: string) => (
    <div className={classNames("flex gap-2  flex-wrap items-center align-middle", wrapperClass)}>
      {sortOptions(props.selectedOptions).map((opt) => (
        <div
          className=" max-w-lg px-2 bg-primary-500 text-white font-medium text-sm border border-primary-500 rounded-xs items-center flex whitespace-nowrap max-h-7 h-7"
          key={opt.value}
        >
          <span>{opt.label}</span>
          <button type="button" onClick={() => removeItem(opt.value)} className="ml-2">
            x
          </button>
        </div>
      ))}
    </div>
  )

  return (
    <div className={classNames("max-w-lg", props.wrapperClassName)}>
      {props.selectedOptions.length > 0 &&
        !props.displaySelectionBelow &&
        renderedSelection("mb-4")}
      <Select<OptionType>
        id={domElementId}
        className={props.className}
        data-test-id={props.testId}
        options={(visibleOptions || []).map(({ value, label }) => ({
          value,
          label,
        }))}
        /* @ts-ignore */
        value={[]} // props.selectedOptions.map((opt) => opt.value)}
        mode={props.mode === "single" ? undefined : props.mode || "tags"}
        allowClear
        style={props.className ? {} : { width: "100%" }}
        onFocus={() => {
          props.onFocus?.()
          setOpen(true)
        }}
        onBlur={() => setOpen(false)}
        open={open}
        placeholder={props.placeholder || "Select an option"}
        placement="bottomLeft"
        optionFilterProp="label"
        /* @ts-ignore */
        onChange={handleChange}
        filterSort={props.optionsPreSorted ? undefined : sortByLabel}
      />
      {props.selectedOptions.length > 0 && !!props.displaySelectionBelow && renderedSelection("")}
      {visibleOptions && visibleOptions.find((option) => option.suggested) && (
        <div className="mt-4">
          <div className="text-sm text-neutral-600">Recommended for you:</div>
          <div className="my-2 grid grid-cols-4 gap-2 pr-8">
            {visibleOptions
              .filter(
                (option) =>
                  option.suggested &&
                  !props.selectedOptions.map((item) => item.value).includes(option.value)
              )
              .map((option) => (
                <div
                  className="cursor-pointer px-2 py-1 bg-neutral-white text-neutral-600 text-xs flex space-x-2"
                  key={option.value}
                  onClick={() => handleChange([option.value])}
                >
                  <span>{option.label}</span>
                  <button type="button" className="font-bold">
                    +
                  </button>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  )
}
