import { useLoggedInUser } from "../../../providers/loggedInUser/useLoggedInUser"
import AdminLinkedBrokersTable from "./components/AdminLinkedBrokersTable"

const AdminLinkedBrokersPage = () => {
  const { isAdmin, user } = useLoggedInUser()

  if (!isAdmin) {
    throw new Error(`Non-admin user (id: ${user.id}) attempted to render AdminLinkedBrokersPage`)
  }

  return <AdminLinkedBrokersTable />
}

export default AdminLinkedBrokersPage
