/* eslint-disable @typescript-eslint/naming-convention */
import { UnsafeRec } from "../utils/RecordUtils"
import { BaseADT } from "./ADT"
import { Func, identity, pipe, tuple } from "../utils/fp/Function"
import { Just, Maybe, Nothing } from "./Maybe"
import { isNonnullable } from "../utils/json/validate"

type EitherSignature<X, Y> = [["left", X], ["right", Y]]

/**
 * Structurally the same as Either, but accumulates on the left instead of short-circuiting
 */
export class Validation<X, Y> extends BaseADT<EitherSignature<X, Y>> {
  /** Deconstruct an Either
   *
   * Using `R1 | R2` as a return type improves type inference, but in most usecases `ifLeft` and `ifRight` should return the same type.
   */
  match<R1, R2 = R1>(ifLeft: Func<X, R1>, ifRight: Func<Y, R2>): R1 | R2 {
    return this.value.tag === "left" ? ifLeft(this.value.value) : ifRight(this.value.value)
  }

  static Match =
    <L1, L2 extends unknown[], R1, R2 = R1>(ifLeft: Func<L1, R1>, ifRight: Func<L2, R2>) =>
    (x: Validation<L1, L2>): R1 | R2 =>
      x.match(ifLeft, ifRight)

  /** Map over the second type argument of an `Either`: `Left`s are preserved, while `Right`s are transformed with the provided function */
  map<Y2>(f: Func<Y, Y2>): Validation<X, Y2> {
    return this.match(Validation.Problem, (y) => Validation.Ok(f(y)))
  }

  /** Map over the first type argument of an `Either`: `Right`s are preserved, while `Left`s are transformed with the provided function */
  mapFst<X2>(f: Func<X, X2>): Validation<X2, Y> {
    return this.match(pipe(f)(Validation.Problem), Validation.Ok)
  }

  /**
   * Map over both type arguments of an `Either`: applies the left function on `Left`s, and the right function on `Right`s.
   */
  bimap<X2, Y2>(l: Func<X, X2>, r: Func<Y, Y2>): Validation<X2, Y2> {
    return this.match(pipe(l)(Validation.Problem), pipe(r)(Validation.Ok))
  }

  flat<A, B>(this: Validation<X, Validation<A, B>>): Validation<X | A, B> {
    return this.match(Validation.Problem, (a) => a.match(Validation.Problem, Validation.Ok))
  }

  /**
   * Zips a Validation<X,Y> with a Validation<X2,Y2> into a Validation<,[Y,Y2]>
   */
  alongsidePaired<X2, Y2>(
    this: Validation<X, Y>,
    x: Validation<X2, Y2>
  ): Validation<(X | X2)[], [Y, Y2]> {
    return this.match(
      (l) =>
        x.match(
          (l2) => Validation.Problem([l, l2]),
          () => Validation.Problem([l])
        ),
      (r) =>
        x.match(
          (l2) => Validation.Problem([l2]),
          (r2) => Validation.Ok([r, r2])
        )
    )
  }

  /**
   * Zips a Validation<X[],Y> with a Validation<X[],Y2> into a Validation<X[],[Y,Y2]>
   */
  alongside<X2, Y2>(this: Validation<X2[], Y>, x: Validation<X2[], Y2>): Validation<X2[], [Y, Y2]> {
    return this.match(
      (l) =>
        x.match(
          (l2) => Validation.Problem([...l, ...l2]),
          () => Validation.Problem(l)
        ),
      (r) =>
        x.match(
          (l2) => Validation.Problem(l2),
          (r2) => Validation.Ok([r, r2])
        )
    )
  }

  isRight(): this is BaseADT<[["right", Y]]> {
    return this.value.tag === "right"
  }

  isLeft(): this is BaseADT<[["left", X]]> {
    return this.value.tag === "left"
  }

  toUnion(): X | Y {
    return this.match(identity, identity)
  }

  toMaybe(): Maybe<Y> {
    return this.match(
      () => Nothing,
      (y) => Just(y)
    )
  }

  withDefault<Z = Y>(z: Z): Y | Z {
    return this.match(
      () => z,
      (r) => r
    )
  }

  or<X2 = X, Y2 = Y>(alternative: Validation<X2, Y2>): Validation<X | X2, Y | Y2> {
    return this.isLeft() ? alternative : this
  }
}

export namespace Validation {
  export type LeftArg<T> = T extends Validation<infer L, infer R> ? L : never
  export type RightArg<T> = T extends Validation<infer L, infer R> ? R : never

  export namespace Record {
    export type Sequence<R extends Record<never, Validation<unknown, unknown>>> = Validation<
      Validation.LeftArg<R[keyof R]>,
      {
        [key in keyof R]: Validation.RightArg<R[key]>
      }
    >
    /** As `Either.Array.sequence`, but traverses a record instead of an array. */
    export const sequence = <X, R extends Record<never, unknown>>(r: {
      [k in keyof R]: Validation<X, R[k]>
    }): Validation<Partial<Record<keyof R, X>>, R> =>
      Validation.Array.sequence(
        UnsafeRec.entries(r).map(([k, v]) =>
          v.mapFst((x) => [[k, x] as const]).map((x) => tuple(k)(x))
        )
      )
        .map((x) => UnsafeRec.fromEntries(x))
        .mapFst((x) => UnsafeRec.fromEntries<Partial<Record<keyof R, X>>>(x))
  }
  export namespace Array {
    export type Sequence<R extends Validation<unknown, unknown>[]> = Validation<
      Validation.LeftArg<R>,
      Validation.RightArg<R>[]
    >
    /** Traverses an array of `Either<X,Y>`s, accumulating the `Right` values. If a `Left` is encountered, returns it. Otherwise, returns the accumulated array. */
    export const sequence = <X, Y>(a: Validation<X[], Y>[]): Validation<X[], Y[]> =>
      a.reduce(
        (prev: Validation<X[], Y[]>, cur: Validation<X[], Y>) =>
          prev.alongside(cur).map(([t, h]) => [h].concat(t)),
        Ok<Y[]>([])
      )
  }
  /**
   * Construct a `Validation<X,_>` from an `X`
   */
  export const Problem = <X>(x: X): Validation<X, never> =>
    new Validation<X, never>({
      tag: "left",
      value: x,
    })

  /**
   * Construct a `Validation<_,Y>` from a `Y`
   */
  export const Ok = <Y>(x: Y): Validation<never, Y> =>
    new Validation<never, Y>({
      tag: "right",
      value: x,
    })
}

export const nullableToValidation = <X, Y>(
  fallback: Y,
  possiblyNull: X
): Validation<Y, Exclude<X, undefined | null>> =>
  isNonnullable(possiblyNull) ? Validation.Ok(possiblyNull) : Validation.Problem(fallback)

export const filterValidation = <L, R>(xs: Validation<L, R>[]): [L[], R[]] => {
  const lefts: L[] = []
  const rights: R[] = []
  xs.forEach((x) => {
    x.match(
      (l) => lefts.push(l),
      (r) => rights.push(r)
    )
  })
  return [lefts, rights]
}
