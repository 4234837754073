import { Structure } from "./data-product/pricing/TransactionStructure"
import { BidOrOffer } from "./DeprecatedOrder"

export interface TradeHistoryRollupStats {
  tradeCount: number
  tradeVolume: number
  tradeVWAP: number
  structuresTraded: Structure[]
}
export type TradeHistoryRollup = TradeHistoryRollupStats & {
  // TODO - do we need direct/indirect count/volume/vwap?
  previousComparablePeriod: TradeHistoryRollupStats
}

export interface OrderHistoryRollupStats {
  orderCount: number
  orderVolume: number | null
  bestPrice: number | null // Highest bid, lowest offer
  medianPrice: number | null
}

export type OrderHistoryRollup = OrderHistoryRollupStats & {
  orderType: BidOrOffer
  previousComparablePeriod: OrderHistoryRollupStats
}

export interface HistoricalMarketSummaryRollup {
  tradeHistoryRollup?: TradeHistoryRollup
  bidHistoryRollup?: OrderHistoryRollup
  offerHistoryRollup?: OrderHistoryRollup
}

export const rollupPeriods = [
  "past7Days",
  "past30Days",
  "past90Days",
  "past180Days",
  "pastYear",
  "past2Years",
] as const
export type RollupPeriod = (typeof rollupPeriods)[number]

// TODO: I like the semantics here but we could restructure this to not need the below object -> days number mapping
// consider a map of days (number) -> HistoricalMarketSummaryRollup
export type CompanyMarketHistorySummary = Record<RollupPeriod, HistoricalMarketSummaryRollup> & {
  updatedAt: Date
}

export type MarketHistorySummaryTimePeriod = Exclude<keyof CompanyMarketHistorySummary, "updatedAt">

export const CompanyMarketHistorySummaryKeysToDays: Record<MarketHistorySummaryTimePeriod, number> =
  {
    past7Days: 7,
    past30Days: 30,
    past90Days: 90,
    past180Days: 180,
    pastYear: 365,
    past2Years: 730,
  }

export const companyHasMarketActivityInPastYear = (company: CompanyMarketHistorySummary): boolean =>
  // prettier-ignore
  (company.pastYear.tradeHistoryRollup?.tradeCount || 0) +
    (company.pastYear.bidHistoryRollup?.orderCount || 0) +
    (company.pastYear.offerHistoryRollup?.orderCount || 0) > 0

export const companyHasMarketActivityInPast6Months = (
  company: CompanyMarketHistorySummary
): boolean =>
  // prettier-ignore
  (company.past180Days.tradeHistoryRollup?.tradeCount || 0) +
      (company.past180Days.bidHistoryRollup?.orderCount || 0) +
      (company.past180Days.offerHistoryRollup?.orderCount || 0) > 0
