import { collections } from "common/firestore/Collections"
import { FirebaseCommon } from "common/firestore/Interface"
import { firestoreConverter } from "common/model/FirestoreConverter"
import {
  TrustedBrokerConnection,
  rejectTrustedBrokerConnectionInvite,
} from "common/model/TrustedBrokerConnection"

export const rejectConnectionInvite = async ({
  db,
  sourceAccountId,
  connection,
}: {
  db: FirebaseCommon.DB
  sourceAccountId: string
  connection: TrustedBrokerConnection
}) => {
  const rejectedInvite = rejectTrustedBrokerConnectionInvite({ sourceAccountId, connection })
  if (!rejectedInvite) return Promise.reject()
  return db
    .collection(collections.trustedBrokerConnections)
    .withConverter<TrustedBrokerConnection>(firestoreConverter<TrustedBrokerConnection>())
    .doc(connection.id)
    .set(rejectedInvite)
}
