import { DocumentsQueryResult, useDocuments } from "src/firebase/Firestore"
import { useFirebaseReader } from "../../firebase/Context"
import { WatchlistItem } from "common/model/Watchlist"
import { useLoggedInUser } from "../../providers/loggedInUser/useLoggedInUser"

export const useAccountWatchlist = (): DocumentsQueryResult<WatchlistItem> => {
  const user = useLoggedInUser()
  const firebase = useFirebaseReader()
  const watchlistQuery = firebase.getAccountWatchlist(user.user.account.id)
  return useDocuments(watchlistQuery)
}
