import OrderDetailsButton from "@components/OrderDetails"
import { WarningMessage } from "@components/errors/WarningMessage"
import { Checkbox } from "@components/inputs/checkbox/Checkbox"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { CompanyIdFields } from "common/model/Company"
import { DeepPartialIntroduction } from "common/model/IntroductionRequest"
import { Order, OrderDirection } from "common/model/Order/Order"
import { deprecatedIsLoaded, matchLoading } from "common/utils/Loading"
import { withIndefiniteArticle } from "common/utils/StringUtils"
import { useFirebaseWriter } from "src/firebase/Context"
import { useSuggestedOrders } from "src/firebase/orders"
import { EditButton } from "src/pages/Orders/MyOrders/MyOrdersRowCells"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { useAccountBrokerLinking } from "src/utils/useAccountBrokerLinking"
import { OrderPreview, OrderPreviewWrapper } from "../OrderPreview"
import { OrderCardHeader } from "./OrderCardHeader"
import { OrderSelectorNewOrderButton } from "./OrderSelectorNewOrderButton"

const SharedOrderCard = ({ order }: { order: Order }) => (
  <>
    <OrderCardHeader
      company={order.company}
      direction={order.direction}
      createdAt={order.createdAt}
      darkpool={order.darkpool}
    />
    <OrderPreviewWrapper>
      <OrderPreview
        order={order}
        hiddenFields={[
          "submitted",
          "direction",
          "shareClasses",
          "clientROFR",
          "clientRelationship",
          "areTransactionDocumentOnHand",
        ]}
      />
    </OrderPreviewWrapper>
  </>
)

const BrokerName = ({ order }: { order: Order }) => {
  const { isAccountBrokerLinkingEnabled } = useAccountBrokerLinking()
  if (isAccountBrokerLinkingEnabled) {
    return order.brokeredBy ? (
      <Typography
        text={`Brokered By: ${order.brokeredBy?.fullName ?? ""}`}
        weight={Weight.Light}
        size={Size.XSmall}
        color={Color.Subtitle}
      />
    ) : null
  }
  return null
}

const EmptyOrderSelector = ({
  company,
  oppositeDirection,
}: {
  company: CompanyIdFields | undefined
  oppositeDirection: OrderDirection | undefined
}) => (
  <WarningMessage
    msg={`Add ${company?.name ? withIndefiniteArticle(company.name) : ""} ${
      oppositeDirection === "buy" ? "Bid" : "Offer"
    } to get started`}
  />
)

export const IntroductionOrderSelector = ({
  introduction,
  onChange,
  selectedOrders,
  onContinue,
}: {
  introduction: DeepPartialIntroduction
  onChange: (data: { orders: Order[] }) => void
  onContinue: () => void
  selectedOrders: Order[]
}) => {
  const { company } = introduction
  const companyId = company?.id ?? "n/a"
  const db = useFirebaseWriter()
  const { user } = useLoggedInUser()
  const { isAccountBrokerLinkingEnabled } = useAccountBrokerLinking()
  const anchorDirection = introduction.anchorTradeOrOrder?.direction
  const suggestedOrders = useSuggestedOrders({
    db,
    companyId,
    user,
    anchorDirection,
  })

  const orderIsSelected = (o: Order): boolean => selectedOrders.map(({ id }) => id).includes(o.id)
  const oppositeDirection = anchorDirection
    ? anchorDirection === "buy"
      ? "sell"
      : "buy"
    : undefined
  const selectedSuggestedOrderItems = matchLoading(
    suggestedOrders,
    (o) => o.filter((order) => orderIsSelected(order)),
    [],
    []
  )
  const sameBrokerIsSelected = (o: Order): boolean =>
    selectedSuggestedOrderItems.length && deprecatedIsLoaded(o) && isAccountBrokerLinkingEnabled
      ? !!selectedSuggestedOrderItems.find((order) => order.brokeredBy?.id === o.brokeredBy?.id)
      : true

  const handleChange = (order: Order) => {
    onChange({
      orders: orderIsSelected(order)
        ? selectedOrders.filter((v) => v.id !== order.id)
        : [...selectedOrders, order],
    })
  }

  const title = `Add New ${company?.name ? `${company?.name} ` : ""}${
    oppositeDirection ? (oppositeDirection === "buy" ? "Bid" : "Offer") : "order"
  }`

  return (
    <div>
      {matchLoading(
        suggestedOrders,
        (suggestedOrderItems) => (
          <div className="flex flex-col gap-4">
            <div>
              <Typography text="My Orders" weight={Weight.Semibold} />
            </div>
            <div>
              <Typography
                text={`Select one or more existing orders to connect with the counterparty's ${
                  anchorDirection ? (anchorDirection === "buy" ? "bid" : "offer") : "order"
                } above`}
                size={Size.Small}
                color={Color.Primary}
              />
            </div>
            {suggestedOrderItems.length ? (
              <>
                {suggestedOrderItems.map((order) => (
                  <div key={order.id} className="my-2 flex">
                    {sameBrokerIsSelected(order) ? (
                      <Checkbox
                        onChange={() => handleChange(order)}
                        checked={orderIsSelected(order)}
                        id={order.id}
                        className="mt-2 border-neutral-400"
                      />
                    ) : (
                      <div className="w-7" />
                    )}

                    <div className="w-full ml-2 p-2 space-y-2 bg-neutral-200 rounded">
                      <SharedOrderCard order={order} />
                      <div className="flex items-center justify-between text-sm">
                        <EditButton order={order} size="small" sourceComponent="connect-panel" />
                        <OrderDetailsButton orderId={order} size="small" />
                      </div>
                      <BrokerName order={order} />
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <EmptyOrderSelector company={company} oppositeDirection={oppositeDirection} />
            )}
          </div>
        ),
        <EmptyOrderSelector company={company} oppositeDirection={oppositeDirection} />,
        <>
          <Typography text="Loading..." weight={Weight.Light} />
        </>
      )}
      <div className="w-ful flex items-center justify-center mt-4">
        <OrderSelectorNewOrderButton
          defaultInitialFormOrder={{ company, direction: oppositeDirection }}
          afterSave={(o) => {
            if (o && o.direction === oppositeDirection) {
              handleChange(o)
              onContinue()
            }
          }}
          title={title}
        />
      </div>
    </div>
  )
}
