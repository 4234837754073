import { FC, ReactElement } from "react"
import { classNames } from "../../utils/classNames"

/** Background-layered information with a header to draw users' attention.  */

type CalloutColors = "blue" | "red" | "yellow"
interface CalloutProps {
  color: CalloutColors // can be extended
  header?: string | ReactElement
  body: string | ReactElement
  dismissable?: boolean // not yet implemented
  size?: "small" | "default"
}

const COLOR_MAPPING: { [key in CalloutColors]: string } = {
  blue: "bg-accent-100",
  red: "bg-danger-100",
  yellow: "bg-warning-100",
}

const Callout: FC<React.PropsWithChildren<CalloutProps>> = ({ color, header, body, size }) => {
  if (size === "small") {
    return (
      <div className={classNames("w-full p-4 border-solid text-sm my-4", COLOR_MAPPING[color])}>
        <div>
          {header && <div className="font-medium uppercase text-neutral-600">{header}</div>}
          {body}
        </div>
      </div>
    )
  }

  return (
    <div className={COLOR_MAPPING[color]}>
      <div className="px-4 py-5 sm:p-6">
        {header && <h3 className="text-lg leading-6 font-medium text-neutral-1000">{header}</h3>}
        <div className={`text-sm text-neutral-800 ${header ? "mt-2" : ""}`}>{body}</div>
      </div>
    </div>
  )
}

export default Callout
