import { LoadingTermsSheetSection } from "./LoadingTermsSheetSection"
import {
  COUNTERPARTY_SECTION_TITLE,
  DEAL_EXECUTION_SECTION_TITLE,
  OTHER_TERMS_SECTION_TITLE,
  PRICE_SECTION_TITLE,
  SIZE_SECTION_TITLE,
} from "./Sections"

const LoadingOrderPageTermSheet = () => (
  <>
    <LoadingTermsSheetSection title={PRICE_SECTION_TITLE} numRows={2} />
    <LoadingTermsSheetSection title={SIZE_SECTION_TITLE} numRows={2} />
    <LoadingTermsSheetSection title={OTHER_TERMS_SECTION_TITLE} numRows={2} />
    <LoadingTermsSheetSection title={COUNTERPARTY_SECTION_TITLE} numRows={1} />
    <LoadingTermsSheetSection title={DEAL_EXECUTION_SECTION_TITLE} numRows={2} />
  </>
)

export default LoadingOrderPageTermSheet
