import { createContext, FC, useContext, useMemo } from "react"
import { FormOrderErrors } from "common/model/Order/OrderForm/State"
import { Order as WrappedOrder } from "common/model/Order/Models/Wrapped"
import { useCompany } from "src/pages/Data/Hooks"
import { Order } from "common/model/Order/Order"
import { formOrderToWrappedOrder } from "./contextHooks/formOrderToWrappedOrder"
import { OrderFormSaveFunction, useFormOrderSaving } from "./contextHooks/useFormOrderSaving"
import { useFormOrderState } from "./contextHooks/useFormOrderState"
import { OrderFormStatusContextProvider } from "./contextHooks/useFormStatus"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { useAccountBrokerLinking } from "src/utils/useAccountBrokerLinking"
import { OrderFormState } from "common/model/Order/OrderForm/State"
import { validateFormOrder } from "common/model/Order/OrderForm/Validation"
import { Loading } from "common/utils/Loading"
import { Company } from "common/model/Company"

export interface OrderFormContextData {
  setFormOrder: (value: OrderFormState) => void
  initialFormOrder: OrderFormState
  initialOrderId: string | undefined
  formOrder: OrderFormState
  validateAndSave: () => Promise<void>
  savedOrder: Order | null
  saveStatus: "saving" | "pending" | "error" | "saved"
  isEditMode: boolean
  wrappedOrder: WrappedOrder | null
  formErrors: FormOrderErrors
  brokerEditingClientOrder: boolean
  showUnpriced: boolean
  company: Loading<Company>
}

const OrderFormContext = createContext<OrderFormContextData>({
  setFormOrder: () => () => null,
  initialFormOrder: {},
  formOrder: {},
  initialOrderId: undefined,
  validateAndSave: () => Promise.reject(),
  savedOrder: null,
  isEditMode: false,
  saveStatus: "pending",
  wrappedOrder: null,
  formErrors: new Set(),
  brokerEditingClientOrder: false,
  showUnpriced: false,
  company: null,
})

const FullOrderFormContextProvider: FC<
  React.PropsWithChildren<{
    initialOrderId?: string
    initialFormOrder: OrderFormState
    onSave: OrderFormSaveFunction
    afterChange?: () => void
    orderFormOpen: boolean
  }>
> = ({ children, initialFormOrder, initialOrderId, onSave, orderFormOpen, afterChange }) => (
  <OrderFormContextProvider
    initialFormOrder={initialFormOrder}
    initialOrderId={initialOrderId}
    onSave={onSave}
    afterChange={afterChange}
    orderFormOpen={orderFormOpen}
  >
    <OrderFormStatusContextProvider>{children}</OrderFormStatusContextProvider>
  </OrderFormContextProvider>
)

export const OrderFormContextProvider: FC<
  React.PropsWithChildren<{
    initialOrderId?: string
    initialFormOrder: OrderFormState
    onSave: OrderFormSaveFunction
    afterChange?: () => void
    orderFormOpen: boolean
  }>
> = ({ children, initialFormOrder, initialOrderId, onSave, afterChange, orderFormOpen }) => {
  const isEditMode = !!initialOrderId
  const { formOrder, handleChange } = useFormOrderState(initialFormOrder, initialOrderId, [
    orderFormOpen,
  ])
  const user = useLoggedInUser()
  const { isLinkedBroker } = useAccountBrokerLinking()
  const brokerEditingClientOrder = useMemo(
    () => isLinkedBroker && formOrder.brokeredBy?.user?.id === user.user.id,
    [formOrder.brokeredBy?.user?.id, isLinkedBroker, user.user.id]
  )
  const company = useCompany(formOrder.company?.id ?? "loading")

  const formErrors: FormOrderErrors = useMemo(
    () =>
      validateFormOrder(formOrder).match(
        (errs) => errs,
        () => new Set()
      ),
    [formOrder]
  )
  const { validateAndSave, saveStatus, savedOrder } = useFormOrderSaving(
    formOrder,
    initialFormOrder,
    onSave,
    [orderFormOpen]
  )

  const wrappedOrder = useMemo(
    () => formOrderToWrappedOrder(formOrder, company, initialFormOrder),
    [company, formOrder, initialFormOrder]
  )

  const showUnpriced = useMemo(
    () =>
      !formOrder.darkpool &&
      (initialFormOrder.price === null || initialFormOrder.price === undefined),
    [initialFormOrder.price, formOrder.darkpool]
  )
  const contextData: OrderFormContextData = useMemo(
    () => ({
      // formManagement
      setFormOrder: (o: OrderFormState) => handleChange(o, afterChange),
      initialFormOrder,
      formOrder,
      initialOrderId,
      showUnpriced,
      // form submit
      validateAndSave,
      formErrors,
      saveStatus,
      savedOrder,
      isEditMode,
      // output selectors
      wrappedOrder,
      brokerEditingClientOrder,
      company,
    }),
    [
      handleChange,
      afterChange,
      initialFormOrder,
      formOrder,
      showUnpriced,
      initialOrderId,
      validateAndSave,
      formErrors,
      saveStatus,
      savedOrder,
      isEditMode,
      wrappedOrder,
      brokerEditingClientOrder,
      company,
    ]
  )

  return <OrderFormContext.Provider value={contextData}>{children}</OrderFormContext.Provider>
}

export const useOrderForm = () => useContext(OrderFormContext)

export default FullOrderFormContextProvider
