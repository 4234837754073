import React from "react"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"

interface LabelValueListProps {
  title: string
  actions?: React.ReactNode
  items: {
    label: React.ReactNode
    value: React.ReactNode | undefined
    actionButton?: React.ReactNode
    id: string
    hide?: boolean
  }[]
}

export const LabelValueList: React.FC<LabelValueListProps> = ({ title, items, actions }) => {
  if (items.length === 0) {
    return null
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between">
        <Typography color={Color.Primary} weight={Weight.Semibold} text={title} />
        {actions}
      </div>
      <div className="rounded-md border">
        {items
          .filter((item) => !item.hide)
          .map(({ label, value, actionButton, id }) => (
            <div className="flex border-b last:border-b-0 w-full" key={id}>
              <div className="bg-neutral-200 px-4 py-1 flex-1 min-w-64 flex items-center">
                <Typography
                  color={Color.Primary}
                  weight={Weight.Regular}
                  text={label}
                  size={Size.Small}
                />
              </div>
              <div className="pl-2 pr-2 py-1 border-l flex-2 flex items-center justify-between w-full">
                {typeof value === "string" ? (
                  <Typography color={Color.Primary} text={value || "-"} size={Size.Small} />
                ) : (
                  value
                )}
                {actionButton && <div className="ml-8">{actionButton}</div>}
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
