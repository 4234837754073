import { Indexable } from "../container-interfaces/Indexable"

/** A wrapper around Map<K, V> with additional utility functions */
export class HashMap<K, V> implements Indexable<K, V | undefined>, Iterable<[K, V]> {
  readonly #raw: Map<K, V>

  constructor(data?: Iterable<readonly [K, V]> | null | undefined) {
    this.#raw = new Map(data)
  }

  /** Construct a map from a list of keys and a lookup functoin */
  static tabulate<K, V>(f: (k: K) => V, keys: K[]) {
    return new HashMap(keys.map((key) => [key, f(key)] as const))
  }

  /** Construct a map by specifying a key for each value. Later elements of `values` win collisions. */
  static backtabulate<K, V>(f: (v: V) => K, values: V[]) {
    return new HashMap(values.map((value) => [f(value), value] as const))
  }

  [Symbol.iterator](): Iterator<[K, V], unknown, undefined> {
    return this.#raw[Symbol.iterator]()
  }

  private modify<K2 = K, V2 = V>(f: (m: Map<K, V>) => Map<K2, V2>) {
    return new HashMap(f(new Map(this.#raw.entries())))
  }

  unwrap() {
    return this.#raw
  }

  has(key: K) {
    return this.#raw.has(key)
  }

  get(key: K) {
    return this.#raw.get(key)
  }

  set(key: K, value: V) {
    return this.modify((m) => m.set(key, value))
  }

  mutatingSet(key: K, value: V) {
    this.#raw.set(key, value)
  }

  delete(key: K) {
    // eslint-disable-next-line prettier/prettier
    return this.modify((m) => { m.delete(key) ; return m } )
  }

  map<V2>(f: (v: V) => V2) {
    return this.modify((m) => new Map([...m].map(([k, v]) => [k, f(v)])))
  }

  mapWithKey<V2>(f: (v: V, k: K) => V2) {
    return this.modify((m) => new Map([...m].map(([k, v]) => [k, f(v, k)])))
  }

  values() {
    return this.#raw.values()
  }

  keys() {
    return this.#raw.keys()
  }

  entries() {
    return this.#raw.entries()
  }

  find(f: (v: V) => boolean) {
    return [...this.values()].find(f)
  }
}
