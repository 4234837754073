import { Json, rawJsonDisplay } from "@components/displays/JSON"
import { LeafEditor, RecordEditor } from "@components/displays/StateDisplay"
import * as S from "common/model/Order/Models/UpdateLog"
import { Order } from "common/model/Order/Order"
import { OrderQuantityTerms } from "common/model/Order/Types/Terms"
import { Left, Right } from "common/containers/Either"
import { Stream } from "common/containers/Stream"
import { floatPrism, nullPrism, unionPrism } from "common/utils/fp/optics/Prism"
import Firebase from "../../../firebase/Firebase"
import { DocumentReference } from "../../../firebase/Firebase/utils"
import { CurrentUser } from "../../../model/CurrentUser"
import { saveUpdatedOrderInFirestore } from "common/model/Order/UploaderLogic"
import {
  AdaptedRecordInspector,
  companyEditor,
  editorFromPrism,
  numberEditorFromPrism,
  optionalCheckboxEditor,
} from "../RecordInspector"

const updateOrderDocumentLogged = (
  db: Firebase,
  user: CurrentUser,
  reason: string,
  docRef: DocumentReference<Order>,
  editedDoc: Order
) =>
  saveUpdatedOrderInFirestore<firebase.default.firestore.Transaction, DocumentReference>(
    db.writerDb,
    user.user
  )({ reason, order: editedDoc })

const rawJSON: LeafEditor<never, unknown> = {
  display: (k, s) => rawJsonDisplay.run({ [k]: s } as Json),
}

const orderQuantityTermsEditor = {
  amountUSD: {
    lowerBound: numberEditorFromPrism("decimal")(floatPrism),
    upperBound: numberEditorFromPrism("decimal")(floatPrism),
  },
  shares: {
    lowerBound: editorFromPrism(floatPrism),
    upperBound: editorFromPrism(floatPrism),
  },
  USDPerShare: {
    lowerBound: numberEditorFromPrism("decimal")(floatPrism),
    upperBound: numberEditorFromPrism("decimal")(floatPrism),
  },
  targetValuation: editorFromPrism(floatPrism),
} as unknown as RecordEditor<OrderQuantityTerms> // TODO figure out better situation for unions

const orderStructureTermsEditor: RecordEditor<S.AtomicOrderUpdate["structureTerms"]> = {
  carry: editorFromPrism(
    unionPrism((x) => (x === null ? Left(x) : Right(x)), nullPrism, floatPrism)
  ),
  managementFee: editorFromPrism(
    unionPrism((x) => (x === null ? Left(x) : Right(x)), nullPrism, floatPrism)
  ),
}

const orderUpdateEditor: RecordEditor<S.Order["orderUpdates"][number]> = {
  quantityTerms: orderQuantityTermsEditor,
  structureTerms: orderStructureTermsEditor,
  updateDate: rawJSON,
}

const arrayEditor = <T,>(e: RecordEditor<T>): RecordEditor<T[]> => Stream.repeat(e)

export const EditOrder = () => (
  <AdaptedRecordInspector<Order, S.Order>
    editor={{
      base: {
        hidden: optionalCheckboxEditor,
        company: companyEditor,
        orderOriginationDate: rawJSON,
      },
      orderUpdates: arrayEditor<S.Order["orderUpdates"][number]>(orderUpdateEditor),
    }}
    canEdit
    canDelete
    adapter={{
      view: S.orderFromInternal,
      over: (s, f) => S.orderToInternal(f(S.orderFromInternal(s))),
    }}
    updateDocumentWithEditLogs={updateOrderDocumentLogged}
  />
)
