/* eslint-disable max-classes-per-file */
import { annotate } from "../../utils/Coerce"
import { UnsafeRec } from "../../utils/RecordUtils"
import * as I from "../../utils/data/Interval"
import { Func, identity, tuple } from "../../utils/fp/Function"
import { Left, Right } from "../Either"
import { filterMaybe, nullableToMaybe } from "../Maybe"

/** Do not export */
abstract class MaybeBase {
  readonly value?: unknown

  // I want to maintain 'join' as the standard name for this operation, as 'flatten' is an odd fit in many cases (e.g. S -> S -> T => S -> T)
  join<T>(this: Maybe<Maybe<T>>) {
    return this.bind(identity)
  }

  flatten<T>(this: Maybe<Maybe<T>>) {
    return this.join()
  }

  /** Map over a Maybe, transforming the contained value, if any, with the provided function */
  map<T2, T = typeof this extends Maybe<infer X> ? X : unknown>(
    this: Maybe<T>,
    f: Func<T, T2>
  ): Maybe<T2> {
    /** This method is heavily optimized and would be terrible code in almost all other circumstances. */
    /** This is safe because Nothing overrides map and no other subclasses exist. */
    // eslint-disable-next-line rulesdir/no-assert
    const x = (this as _Just<T>).value
    const y = f(x)
    // eslint-disable-next-line rulesdir/no-assert
    if (x === (y as unknown)) {
      /** This is safe since x === y => typeof x is a subtype of T2 => Maybe<typeof x> is a subtype of Maybe<T2> */
      // eslint-disable-next-line rulesdir/no-assert
      return this as unknown as Maybe<T2>
    }
    return new _Just(y)
  }
}

/** This was made abstract with subclassed cases because it resulted in slightly better performance in the MarketPrice chart as of spring 2023.
 * This is not an ideal state of affairs and should generally not be copied.
 *
 * Do not extend: the only subclasses this should have are Just and Nothing.
 * */
export abstract class Maybe<out T> extends MaybeBase {
  /** Deconstruct a Maybe */
  abstract matchStrict<X, Y = X>(ifJust: Func<T, X>, ifNothing: Y): X | Y

  abstract match<X, Y = X>(ifJust: Func<T, X>, ifNothing: () => Y): X | Y

  /** Widen the wrapped type parameter; this is a no-op but sometimes necessary to get ts to infer correctly */
  weaken<S>(): Maybe<T | S> {
    return this
  }

  /** Unwrap a Maybe, supplying any contained value to the provided function and preserving `Nothing` */
  bind<T2>(f: Func<T, Maybe<T2>>): Maybe<T2> {
    return this.matchStrict(f, Nothing)
  }

  /** Run effect if Maybe contains a value */
  runEffect(effect: Func<T, void>) {
    return this.match(
      (x) => effect(x),
      () => undefined
    )
  }

  /** Zip together a `Maybe<X>` and a `Maybe<Y>` into a `Maybe<[X,Y]>`. If either is `Nothing`, so is the result. */
  alongside<T2>(x: Maybe<T2>): Maybe<[T, T2]> {
    return this.bind((l) => x.map(tuple(l)))
  }

  /* note that there are two natural monoid instances for Maybe<T>. One is induced by a monoid structure on T, as
`concat (Just x, Just y) => Just (concat x y)`. The other is given by `concat (Just x, Just y) = Just x`. This `concat` is the latter. */
  /** Preserve a 'Just', replacing a Nothing value with a new Maybe */
  concat<S>(x: Maybe<S>): Maybe<S | T> {
    return this.matchStrict(Just, x)
  }

  or<S>(x: Maybe<S>): Maybe<S | T> {
    return this.concat(x)
  }

  withDefault<S extends T>(x: S) {
    return this.matchStrict(identity, x)
  }

  /** withDefault, but with an unconstrained type of the default value. In most situations, prefer `withDefault` */
  withUnconstrainedDefault<S>(x: S): S | T {
    return this.matchStrict(identity, x)
  }

  withLazyDefault<S>(x: () => S): S | T {
    return this.match(identity, x)
  }

  toNullable() {
    return this.matchStrict(identity, undefined)
  }

  asNullable<S>(f: (t: T | undefined) => S | undefined) {
    return nullableToMaybe(f(this.toNullable()))
  }

  isJust(): this is _Just<T> {
    return this.matchStrict(() => true, false)
  }

  isNothing(): this is _Nothing {
    return !this.isJust()
  }

  toArray(): T[] {
    return this.matchStrict((val) => [val], [])
  }

  /** Determines whether the Maybe contains a Just meeting some criteria defined by 'predicate' */
  some(predicate: Func<T, boolean>) {
    return this.matchStrict(predicate, false)
  }

  toEither() {
    return this.matchStrict((y) => Right(y), Left(undefined))
  }
}

/** Do not extend */
// eslint-disable-next-line @typescript-eslint/naming-convention
export class _Just<T> extends Maybe<T> {
  override readonly value: T

  constructor(value: T) {
    super()
    this.value = value
  }

  matchStrict<X, Y = X>(ifJust: Func<T, X>, __: Y) {
    return ifJust(this.value)
  }

  match<X, Y = X>(ifJust: Func<T, X>, __: Y) {
    return ifJust(this.value)
  }
}

/** Do not extend */
// eslint-disable-next-line @typescript-eslint/naming-convention
class _Nothing extends Maybe<never> {
  // eslint-disable-next-line class-methods-use-this
  matchStrict<X, Y = X>(__: Func<never, X>, ifNothing: Y) {
    return ifNothing
  }

  // eslint-disable-next-line class-methods-use-this
  match<X, Y = X>(__: Func<never, X>, ifNothing: () => Y) {
    return ifNothing()
  }

  override map<T2, T>(this: Maybe<T & never>, _: Func<T, T2>): Maybe<T2> {
    return this
  }
}

export const Just = <T>(t: T): Maybe<T> => new _Just(t)
export const Nothing: Maybe<never> = new _Nothing()

export namespace Maybe {
  export namespace Array {
    export const sequence = <T>(ts: Maybe<T>[]): Maybe<T[]> =>
      ts.reduce(
        (x, y) => x.alongside(y).map(([list, next]) => list.concat([next])),
        Just(annotate<T[]>([]))
      )
  }

  export namespace Interval {
    export const sequence = <T>(ts: I.Interval<Maybe<T>>): Maybe<I.Interval<T>> =>
      ts.lowerBound
        .alongside(ts.upperBound)
        .map(([lowerBound, upperBound]) => ({ lowerBound, upperBound }))
  }

  export namespace Record {
    /** If any field of the input is `Nothing`, returns `Nothing`. Otherwise, turns a record of `Just`s into `Just` a record. */
    export const sequence = <R extends object>(r: {
      [key in keyof R]: Maybe<R[key]>
    }): Maybe<R> =>
      Maybe.Array.sequence(UnsafeRec.entries(r).map(([k, v]) => v.map((x) => tuple(k)(x)))).map(
        UnsafeRec.fromEntries
      )
    export const filter = <R extends object>(r: {
      [key in keyof R]: Maybe<R[key]>
    }): Partial<R> =>
      UnsafeRec.fromEntries(
        filterMaybe(UnsafeRec.entries(r).map(([k, v]) => v.map((x) => tuple(k)(x))))
      )
  }
}
