import { ReactRenderer } from "@tiptap/react"
import { SuggestionKeyDownProps, SuggestionOptions, SuggestionProps } from "@tiptap/suggestion"
import tippy from "tippy.js"
import { GetReferenceClientRect } from "tippy.js"
import { ReactElement } from "react"
import { MentionList } from "./MentionList"
import { CompanyIdFields } from "common/model/Company"
import { User, UserPublicFields } from "common/model/User"
import { DealCRMContactIdFields } from "common/model/DealCRM/DealCRMContact"
import { DealCRMDealIdFields } from "common/model/DealCRM/Deal/DealCRMDeal"
import { Instance } from "tippy.js"
import { Excluding } from "common/utils/TypeUtils"

export type NoteTag = {
  id: string
  name: string
  icon: string | ReactElement
  tag: "user" | "company" | "contact" | "deal"
} & (
  | {
      tag: "company"
      data: CompanyIdFields
    }
  | {
      tag: "user"
      data: UserPublicFields
    }
  | {
      tag: "contact"
      data: DealCRMContactIdFields
    }
  | {
      tag: "deal"
      data: DealCRMDealIdFields
    }
)
export const viewSavedNoteTag = (tag: NoteTag): SavedNoteTag => ({
  id: tag.id,
  name: tag.name,
  tag: tag.tag,
  data: tag.data,
})
export type SavedNoteTag = Excluding<Omit<NoteTag, "icon">, NoteTag>

const SuggestionConfig: (taggables: NoteTag[]) => Omit<SuggestionOptions, "editor"> = (
  taggables
) => ({
  items: ({ query }: { query: string }) =>
    taggables
      .filter((taggable) => taggable.name.toLowerCase().startsWith(query.toLowerCase()))
      .slice(0, 5),
  render: () => {
    let reactRenderer: ReactRenderer | undefined = undefined
    let popup: Instance[] = []

    return {
      onStart: (props: SuggestionProps) => {
        if (!props.clientRect) {
          return
        }

        reactRenderer = new ReactRenderer(MentionList, {
          props,
          editor: props.editor,
        })

        popup = tippy("body", {
          getReferenceClientRect: props.clientRect as unknown as GetReferenceClientRect,
          appendTo: () => document.body,
          content: reactRenderer.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "top-start",
        })
      },

      onUpdate(props: SuggestionProps) {
        if (!reactRenderer) {
          return
        }
        reactRenderer.updateProps(props)

        if (!props.clientRect) {
          return
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect as unknown as GetReferenceClientRect,
        })
      },

      onKeyDown(props: SuggestionKeyDownProps) {
        if (props.event.key === "Escape") {
          popup[0].hide()

          props.event.cancelBubble = true
          if (props.event.stopPropagation) {
            props.event.stopPropagation()
          }

          return true
        }
        if (!reactRenderer) return false

        return (
          reactRenderer.ref as { onKeyDown: (props: SuggestionKeyDownProps) => boolean }
        )?.onKeyDown(props)
      },

      onExit() {
        if (!popup[0]?.state.isDestroyed) {
          popup[0].destroy()
        }
        if (reactRenderer) {
          reactRenderer.destroy()
        }
      },
    }
  },
})

export default SuggestionConfig
