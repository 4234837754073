import { Loading } from "common/utils/Loading"
import { Firestore, onSnapshot, Query } from "firebase/firestore"
import { useEffect, useState } from "react"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import { handleConsoleError } from "src/utils/Tracking"

export const useFirebase9QuerySnapshot = <T>(
  queryFn: (db: Firestore) => Query<T>,
  deps: unknown[]
): Loading<T[]> => {
  const { db } = useFirebase9()
  const [items, setItems] = useState<Loading<T[]>>("loading")

  useEffect(() => {
    onSnapshot(queryFn(db), {
      next: ({ docs }) => setItems(docs.map((d) => d.data())),
      error: (e) => {
        handleConsoleError(e)
        setItems(null)
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db, queryFn, setItems, ...deps])

  return items
}
