import Typography, { Color, Size } from "@stories/components/Typography/Typography"
import { ContactCreatedEvent } from "common/model/AccountActivityLog/AccountActivityLog"
import { CRMContactName } from "src/pages/CRM/Contacts/ContactDetailsDrawer/CRMContactName"
import { useCRMContacts } from "src/pages/CRM/Providers/CRMContactsProvider"

export const CRMContactCreatedEventCardDetails = ({ event }: { event: ContactCreatedEvent }) => {
  const { findContactById } = useCRMContacts()

  const fullContact = findContactById(event.contact.id)

  if (!fullContact) {
    return <CRMContactName contact={event.contact} clickable={false} />
  }

  if (fullContact.tag === "firm" || !fullContact.firm) {
    return <CRMContactName contact={fullContact} clickable={false} />
  }

  return (
    <div className="flex gap-4 items-center">
      <CRMContactName contact={fullContact} clickable />
      <Typography size={Size.XSmall} color={Color.Subtitle} text={fullContact.firm.name} />
    </div>
  )
}
