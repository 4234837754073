export const notificationFrequencies = ["daily", "weekly", "never"] as const

export type NotificationFrequency = (typeof notificationFrequencies)[number]

export type NotificationSetting = NotificationFrequency | "enabled"

export const notificationPreferences = [
  "product_announcement_emails",
  "blog_and_insights_emails",
  "general_marketing_communications",
  "rfq_emails",
  "digest_emails",
  "new_indications_emails",
  "platform_notification_emails",
  "crm_notes",
] as const

export type UserNotificationPreferences = {
  [key in NotificationPreference]: NotificationSetting
}

export const defaultNotificationPreferences: UserNotificationPreferences = {
  product_announcement_emails: "enabled",
  blog_and_insights_emails: "enabled",
  general_marketing_communications: "enabled",
  rfq_emails: "enabled",
  new_indications_emails: "enabled",
  digest_emails: "weekly",
  platform_notification_emails: "weekly",
  crm_notes: "enabled",
}

export type NotificationPreference = (typeof notificationPreferences)[number]

export type UserNotificationPreferencesDisplaySettings = {
  notificationType: (typeof notificationPreferences)[number]
  label: string
  shouldUseFrequencyOptions: boolean
  description: string
}

export const userNotificationPreferencesDisplaySettings: UserNotificationPreferencesDisplaySettings[] =
  [
    {
      notificationType: "product_announcement_emails",
      label: "Product announcement emails",
      shouldUseFrequencyOptions: false,
      description: "Emails showcasing new features and updates to the Caplight Platform",
    },
    {
      notificationType: "blog_and_insights_emails",
      label: "Blog and insights emails",
      shouldUseFrequencyOptions: false,
      description:
        "Thought leadership pieces from Caplight that surface observed trends in the private markets and analyze the pre-IPO asset class in novel ways",
    },
    {
      notificationType: "general_marketing_communications",
      label: "General marketing communications",
      shouldUseFrequencyOptions: false,
      description: "Emails from Caplight about our product offerings and services",
    },
    {
      notificationType: "rfq_emails",
      label: "RFQ emails",
      shouldUseFrequencyOptions: false,
      description:
        "Invitations to Caplight RFQs (request-for-quote) which enable reliable price-discovery on private companies",
    },
    {
      notificationType: "new_indications_emails",
      label: "New orders emails",
      shouldUseFrequencyOptions: false,
      description: "A daily digest of all new buy and sell orders placed on the platform",
    },
    {
      notificationType: "digest_emails",
      label: "Digest emails",
      shouldUseFrequencyOptions: true,
      description:
        "Summaries of market activity observed on Caplight Data, including new orders and reported trades, along with company price movements and news",
    },
    {
      notificationType: "platform_notification_emails",
      label: "Platform notification emails",
      shouldUseFrequencyOptions: true,
      description: "A daily or weekly summary of your in-platform notifications",
    },
    {
      notificationType: "crm_notes",
      label: "CRM Note Mentions",
      shouldUseFrequencyOptions: false,
      description: "Receive email notifications when you are replied to or are mentioned in a note."
    },
  ]
