import { chunk } from "lodash"
import { unique } from "./data/Array/ArrayUtils"
import { FIRESTORE_IN_LIMIT } from "./constants/firestore"
import { isNonempty } from "./data/Array/Nonempty"

export const inQueryCommon = async <T>(
  search: (chunkedSearch: string[]) => Promise<T[]>,
  allSearchValues: string[]
): Promise<T[]> => {
  if (!isNonempty(allSearchValues)) return Promise.resolve([])
  const chunks = chunk(unique(allSearchValues), FIRESTORE_IN_LIMIT)
  const items: T[] = []
  // eslint-disable-next-line no-restricted-syntax
  for (const c of chunks) {
    // eslint-disable-next-line no-await-in-loop
    await search(c).then((results) => items.push(...results))
  }
  return items
}
