import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"

export const nameSimilarityScore = (
  contact: DealCRMContact,
  searchString: string
): 100 | 90 | 80 | 0 => {
  const name = contact.name.toLowerCase()
  const search = searchString.toLowerCase()
  if (name === search) return 100
  if (name.startsWith(search)) return 90
  if (name.includes(search)) return 80
  return 0
}
