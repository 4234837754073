import { ArrowDownIcon, ArrowUpIcon, SwitchVerticalIcon } from "@heroicons/react/solid"
import { Just, Maybe, nullableToMaybe } from "common/containers/Maybe"
import { PartialOrdering, partialOrderingToNumber } from "common/utils/fp/Ord"
import { Rec } from "common/utils/RecordUtils"
import { classNames } from "../../classNames"
import { useAction } from "../state/useAction"

export type SortDirection = "asc" | "desc"

export type SortFn<T> = (l: T, r: T, options?: { direction: SortDirection }) => PartialOrdering

const flipDirection = (x: SortDirection) => (x === "asc" ? "desc" : "asc")

const SortButton = ({
  sortState,
  toggleSort,
}: {
  sortState: SortDirection | "off"
  toggleSort: () => void
}) => (
  <div
    onClick={(e) => {
      e.stopPropagation()
      toggleSort()
    }}
    className={classNames("w-4 h-4", sortState === "off" ? "text-neutral-400" : "")}
    role="button"
    tabIndex={0}
    onKeyUp={toggleSort}
  >
    {sortState === "off" ? (
      <div className="flex flex-row text-neutral-1000">
        <SwitchVerticalIcon />
      </div>
    ) : sortState === "desc" ? (
      <ArrowDownIcon />
    ) : (
      <ArrowUpIcon />
    )}
  </div>
)

/** Takes a list of `T`s to be sorted and a record of orders on `T`. Returns a sorted list and a record of toggles for each sort. */
export const useSort = <T, K extends string | number | symbol>(
  ts: T[],
  sorts: Record<K, SortFn<T>>,
  options?: {
    defaultSort?: SortFn<T>
    initialSort?: readonly [SortDirection, K]
  }
): [T[], Record<K, () => JSX.Element>, Record<K, () => void>] => {
  const [activeSort, setActiveSort] = useAction<Maybe<readonly [SortDirection, K]>, K>(
    nullableToMaybe(options?.initialSort),
    Rec.indexedMap(
      sorts,
      ([i, _]) =>
        (x: Maybe<readonly ["desc" | "asc", K]>) =>
          x.isJust() && x.value[1] === i
            ? Just([flipDirection(x.value[0]), i] as const)
            : Just(["desc", i] as const)
    )
  )
  const sortControlFunctions = Rec.indexedMap(setActiveSort, ([i, f]) => f)
  const sortControls = Rec.indexedMap(setActiveSort, ([i, f]) => () => (
    <SortButton
      sortState={activeSort.matchStrict(([dir, idx]) => (idx === i ? dir : "off"), "off")}
      toggleSort={f}
    />
  ))

  const defaultSort = options?.defaultSort

  const sorted = activeSort.matchStrict(
    (i) =>
      ts
        .slice()
        .sort(
          (a, b) =>
            (i[0] === "asc" ? 1 : -1) *
            partialOrderingToNumber(sorts[i[1]](a, b, { direction: i[0] }))
        ),
    defaultSort ? ts.sort((a, b) => partialOrderingToNumber(defaultSort(a, b))) : ts
  )
  return [sorted, sortControls, sortControlFunctions]
}
