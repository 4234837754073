/* eslint-disable react/no-unstable-nested-components */
import { DatePicker } from "@stories/components/Antd/DatePicker/DatePicker"
import { Input } from "@stories/components/Inputs/Input/Input"
import PhoneInput from "@stories/components/Inputs/PhoneInput/PhoneInput"
import Typography from "@stories/components/Typography/Typography"
import { IndividualAccountTradingRegistration } from "common/model/Account/AccountTradingRegistration"
import moment from "moment"
import { Field } from "react-final-form"

export const IndividualAccountTradingRegistrationForm = (
  <>
    <Field<IndividualAccountTradingRegistration["entityName"]>
      name="entityName"
      component={({ input: { value, onChange } }) => (
        <Input
          value={value}
          onChange={onChange}
          label="Full Legal Name"
          placeholder="e.g. John Doe"
          hintText="As displayed on official identification."
        />
      )}
    />
    <Field<IndividualAccountTradingRegistration["address"]>
      name="address"
      component={({ input: { value, onChange } }) => (
        <Input
          value={value}
          onChange={onChange}
          label="Address"
          placeholder="e.g. 1919 Broadway, New York, NY 10023, USA"
          hintText="As displayed on official identification."
        />
      )}
    />
    <Field<IndividualAccountTradingRegistration["phoneNumber"]>
      name="phoneNumber"
      render={({ input: { value, onChange } }) => (
        <PhoneInput
          value={value}
          onChange={onChange}
          label="Contact Number"
          heapName="trading-registration-phone-input"
        />
      )}
    />
    <Field<IndividualAccountTradingRegistration["dateOfBirth"]>
      name="dateOfBirth"
      component={({ input: { value, onChange } }) => (
        <>
          <Typography text="Date of Birth" />
          <DatePicker
            onChange={(m) => onChange(m?.toDate())}
            placeholder="e.g. 01/01/1970"
            value={value ? moment(value) : undefined}
          />
        </>
      )}
    />
    <Field<IndividualAccountTradingRegistration["ssn"]>
      name="ssn"
      component={({ input: { value, onChange } }) => (
        <Input
          shouldMaskDataInDataDog
          value={value}
          onChange={onChange}
          label="Full SSN"
          placeholder="### ## ####"
        />
      )}
    />
    <Field<IndividualAccountTradingRegistration["country"]>
      name="country"
      component={({ input: { value, onChange } }) => (
        <Input
          value={value}
          onChange={onChange}
          label="Country of Tax Domicile (if different from address)"
          placeholder="Country"
        />
      )}
    />
  </>
)
