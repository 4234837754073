import { Skeleton, Tooltip } from "@stories/components/Antd"
import { AsyncDispatchButton } from "@stories/components/Button/AsyncDispatchButton"
import { Color } from "@stories/components/Typography/Typography"
import { defaultIfLoading, isLoaded, isLoading } from "common/utils/Loading"
import { PropsWithChildren, useState } from "react"
import { useCompanyAccess } from "../CompanyAccessProvider"
import { RemainingUnlockText } from "./RemainingUnlockText"
import { SeePlansButton } from "./SeePlansButton"
import { AccessControlFeatureName } from "common/model/AccessControl/AccessControl"
import { StopClickPropagation } from "@stories/components/StopClickPropagation/StopClickPropagation"
import { handleConsoleError } from "src/utils/Tracking"
import { companyAccessResetsMonthly } from "common/model/AccessControl/CompanyAccess"
import { useAccessControl } from "../AccessControlProvider"

const LoadingDisplay = () => <Skeleton active title paragraph={false} />

export const UnlockCompanyPopover = ({
  children,
  featureName,
}: PropsWithChildren<{ featureName: AccessControlFeatureName }>) => {
  const { currentAccessTier } = useAccessControl()
  const { company, remainingUnlockCount, unlockCompany, isCompanyUnlocked } = useCompanyAccess()
  const [isTooltipOpen, setIsTooltipOpen] = useState<false | undefined>()

  if (remainingUnlockCount === "unlimited") {
    handleConsoleError(
      new Error("UnlockCompanyPopover was rendered for user with unlimited unlocks")
    )
    return children
  }

  if (isCompanyUnlocked) {
    return children
  }

  if (isLoading(company) || isLoading(remainingUnlockCount)) {
    return <LoadingDisplay />
  }

  if (!isLoaded(company) || !isLoaded(remainingUnlockCount)) {
    handleConsoleError(
      new Error(
        "UnlockCompanyPopover was rendered with null company or remainingUnlockCount. There may be a missing CompanyAccessProvider or CompanyAccessRowWrapper."
      )
    )
    return <LoadingDisplay />
  }
  return (
    <StopClickPropagation>
      <Tooltip
        title={
          <div className="flex flex-col space-y-2 p-2 max-w-xs">
            <RemainingUnlockText
              remainingUnlockCount={remainingUnlockCount}
              accessResetsMonthly={companyAccessResetsMonthly(
                defaultIfLoading(currentAccessTier, "individual-trial")
              )}
              color={Color.OffWhite}
            />
            {remainingUnlockCount === 0 ? (
              <SeePlansButton
                variant="secondary"
                featureName={featureName}
                onCloseWrapper={() => setIsTooltipOpen(false)}
              />
            ) : (
              <AsyncDispatchButton
                variant="full-link"
                onClick={unlockCompany}
                label={`Unlock ${company.name}`}
                isDisabled={!remainingUnlockCount}
                isFullWidth
              />
            )}
          </div>
        }
        placement="left"
        open={isTooltipOpen}
      >
        <div className="w-fit">{children}</div>
      </Tooltip>
    </StopClickPropagation>
  )
}
