import { ExchangeFundExplanation } from "../StructureExplanations/ExchangeFundExplanation"
import { VPPFExplanation } from "../StructureExplanations/VPPFExplanation"
import { OrderPageOrder } from "../types"
import {
  AdditionalDetailsSection,
  CounterpartySection,
  DealExecutionSection,
  OtherTermsSection,
  PriceSection,
  SizeSection,
} from "./Sections"

const LoadedOrderPageTermSheet = ({
  order,
  hideUnspecified = false,
}: {
  order: OrderPageOrder
  hideUnspecified?: boolean
}) => (
  <>
    {order.currentStructures().some((s) => s.is("variable_prepaid_forward")) ? (
      <VPPFExplanation order={order} />
    ) : null}
    {order.currentStructures().some((s) => s.is("collective_liquidity_exchange_fund")) ? (
      <ExchangeFundExplanation order={order} />
    ) : null}
    <PriceSection order={order} hideUnspecified={hideUnspecified} />
    <SizeSection order={order} hideUnspecified={hideUnspecified} />
    <OtherTermsSection order={order} hideUnspecified={hideUnspecified} />
    <CounterpartySection order={order} hideUnspecified={hideUnspecified} />
    <DealExecutionSection order={order} hideUnspecified={hideUnspecified} />
    <AdditionalDetailsSection order={order} hideUnspecified={hideUnspecified} />
  </>
)

export default LoadedOrderPageTermSheet
