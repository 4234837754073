import { Tooltip } from "@stories/components/Antd"
import { Button, ButtonSize } from "@stories/components/Button/Button"
import { ViewIcon } from "@stories/icons/ViewIcon"
import { OrderLookupFields } from "common/model/Order/Models/Internal"
import { useOrderDetailsDrawer } from "./OrderDetailsWrapper"
import { OrderDetailsTabId } from "common/model/Order/MiscOrderUIStuffThatNeedsToBeCleanedUp"

type OrderDetailsButtonProps = {
  iconOnly?: boolean
  size?: ButtonSize
  orderId: OrderLookupFields
  tab?: OrderDetailsTabId
  isTransparent?: boolean
  isFullWidth?: boolean
}

const OrderDetailsButton = ({
  orderId,
  tab,
  iconOnly,
  size,
  isTransparent,
  isFullWidth,
}: OrderDetailsButtonProps) => {
  const handleOrderDetailOpen = useOrderDetailsDrawer()
  const handleClick = () => handleOrderDetailOpen(orderId, tab)
  return (
    <Tooltip placement="top" title={iconOnly ? "View Order Details" : ""}>
      <Button
        label={!iconOnly ? "View" : ""}
        leftIcon={<ViewIcon />}
        size={size}
        variant="secondary"
        onClick={handleClick}
        heapName="order-details-btn"
        isTransparent={isTransparent}
        isFullWidth={isFullWidth}
      />
    </Tooltip>
  )
}

export default OrderDetailsButton
