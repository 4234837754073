const gmailParser = (htmlContent: string) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlContent, "text/html")

  // Select the div with the class "gmail_quote"
  const gmailQuoteDiv = doc.querySelector(".gmail_quote")

  // Check if the element exists to avoid errors
  if (gmailQuoteDiv) {
    // Set the inner HTML of the selected div to an empty string
    // eslint-disable-next-line better-mutation/no-mutation
    gmailQuoteDiv.innerHTML = ""
  }

  // Serialize the DOM back to a string
  const updatedHtmlString = doc.body.innerHTML

  return updatedHtmlString
}

export default gmailParser
