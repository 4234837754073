import { ChangeEventHandler, FC, ReactNode } from "react"
import { classNames } from "../../../utils/classNames"
import { Tooltip } from "@stories/components/Antd"
import { InfoIcon } from "@stories/icons/InfoIcon"
import Typography, { Color, Size } from "@stories/components/Typography/Typography"

interface CheckboxProps {
  id: string
  checked?: boolean
  disabled?: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
  className?: string
  size?: "medium" | "small"
  stopPropagation?: boolean
}

export const Checkbox: FC<React.PropsWithChildren<CheckboxProps>> = ({
  checked,
  onChange,
  id,
  className,
  disabled,
  size,
  stopPropagation
}) => (
  <input
    id={id}
    type="checkbox"
    checked={checked}
    onChange={onChange}
    disabled={disabled}
    onClick={(x) => { if(stopPropagation) { x.stopPropagation() } }}
    className={classNames(
      className,
      size === "small" ? "w-4" : "w-6",
      size === "small" ? "h-4" : "h-6",
      "border border-neutral-500 rounded-md text-primary-500 ring-0 focus:ring-0 cursor-pointer focus:ring-transparent",
      className
    )}
  />
)

export const NegativeCheckbox: FC<React.PropsWithChildren<CheckboxProps>> = ({
  checked,
  onChange,
  id,
  className,
}) => (
  <input
    id={id}
    type="checkbox"
    checked={checked}
    onChange={onChange}
    className={classNames(
      className,
      "w-6 h-6 border border-neutral-500 rounded-md text-danger-700 ring-0 focus:ring-0 cursor-pointer focus:ring-transparent",
      className
    )}
  />
)

interface CheckboxWithLabelProps extends CheckboxProps {
  title?: ReactNode
  comment?: string
  wrapText?: boolean
  tooltip?: string
}

export const CheckboxWithLabel: FC<React.PropsWithChildren<CheckboxWithLabelProps>> = ({
  checked,
  onChange,
  id,
  title,
  comment,
  disabled,
  wrapText,
  tooltip,
  size,
}) => {
  const label = (
    <label
      htmlFor={id}
      className={`${
        disabled ? "cursor-not-allowed text-neutral-600" : "cursor-pointer text-neutral-1000"
      }  flex items-center gap-2`}
    >
      {title}
      {tooltip && (
        <Tooltip title={tooltip} placement="top">
          <div>
            <InfoIcon color={Color.Subtitle} />
          </div>
        </Tooltip>
      )}
    </label>
  )

  return (
    <div className="relative flex flex-col justify-center my-2">
      <div className={classNames("flex items-center gap-2", wrapText ? "" : "w-max")}>
        <Checkbox
          id={id}
          onChange={onChange}
          disabled={disabled === true}
          checked={checked}
          size={size}
        />
        <Typography text={label} size={size === "small" ? Size.XSmall : Size.Small} />
      </div>
      <div className="ml-7">
        <Typography
          text={comment}
          size={Size.XSmall}
          color={disabled ? Color.Disabled : Color.Subtitle}
        />
      </div>
    </div>
  )
}
