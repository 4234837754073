import moment, { Moment } from "moment"
import { viewCompanyIdFields } from "../../../../model/Company"
import { User } from "../../../../model/User"
import { CompanyRef, StockSplitCollection } from "../../../pricing/PricingDataSource"

export class FirebaseStockSplits implements StockSplitCollection {
  company: CompanyRef

  constructor({ company }: { company: CompanyRef }) {
    this.company = company
  }

  empty = () => this.count().then((x) => x === 0)

  count = () => this.get(null, moment()).then((docs) => docs.length)

  get = (forUser: User | null, asOf: Moment) =>
    this.company
      .get(forUser, asOf)
      .then((c) =>
        c.stockSplits.map((split) => ({ ...split, scope: { company: viewCompanyIdFields(c) } }))
      )
}
