import { CheckboxWithLabel } from "@components/inputs/checkbox/Checkbox"
import {
  AccountTradingRegistration,
  MinimumAccountTradingRegistration,
} from "common/model/Account/AccountTradingRegistration"
import { Loading, isLoaded } from "common/utils/Loading"
import { handleConsoleError } from "src/utils/Tracking"

export const AdminTradingRegistrationMarkAsNotRequired = ({
  tradingRegistration,
  updateTradingRegistration,
}: {
  updateTradingRegistration: (
    t: Partial<AccountTradingRegistration> & MinimumAccountTradingRegistration
  ) => Promise<void>
  tradingRegistration: Loading<
    Partial<AccountTradingRegistration> & MinimumAccountTradingRegistration
  >
}) => {
  const handleMarkAsNotRequired = (checked: boolean) => {
    if (isLoaded(tradingRegistration)) {
      updateTradingRegistration({
        ...tradingRegistration,
        status: {
          ...tradingRegistration.status,
          isNonTransactional: checked,
          isNonTransactionalDate: checked ? new Date() : null,
        },
      }).catch(handleConsoleError)
    }
  }
  if (!isLoaded(tradingRegistration)) return null
  return (
    <CheckboxWithLabel
      title="Mark Account as Non-Transactional"
      comment="This will remove the banner asking for trading registration, but does NOT approve this account to trade."
      tooltip="Trading Registration still blocks connecting and adding orders."
      id="mark-trading-registration-as-not-required"
      onChange={(e) => handleMarkAsNotRequired(e.target.checked)}
      checked={tradingRegistration.status.isNonTransactional}
    />
  )
}
