import { Func, identity, tuple } from "./fp/Function"
import { Just, Maybe, Nothing } from "../containers/Maybe"
import { EntryOf, UnsafeRec } from "./RecordUtils"
import { isNil } from "lodash"

export type Loading<T> = T | "loading" | null
export type Requesting<T> = T | "loading" | "not requested yet" | null

export const isRequested = <T>(t: Requesting<T>): t is Loading<T> => t !== "not requested yet"

export const isRequestedPending = <T>(t: Requesting<T>): t is "loading" | "not requested yet" =>
  t === "not requested yet" || t === "loading"

export const isRequestedReceived = <T>(t: Requesting<T>): t is T =>
  !!t && t !== "loading" && t !== "not requested yet"

export const isLoading = <T>(t: Loading<T>): t is "loading" => t === "loading"

/** @deprecated use isLoaded instead which safely handles 0 and empty-string "" values */
export const deprecatedIsLoaded = <T>(t: Loading<T>): t is T => !!t && t !== "loading"

export const isLoaded = <T>(t: Loading<T>): t is T => !isNil(t) && t !== "loading"

export const mapLoading =
  <S, T>(f: Func<S, T>) =>
  (s: Loading<S>): Loading<T> =>
    s === "loading" ? "loading" : s === null ? null : f(s)

export const bindLoading = <S, T>(f: Func<S, Loading<T>>, x: Loading<S>): Loading<T> =>
  isLoading(x) ? "loading" : x ? f(x) : null

export const lift2Loading = <X, Y, Z>(
  f: (x: X, y: Y) => Z,
  x: Loading<X>,
  y: Loading<Y>
): Loading<Z> => {
  if (isLoading(x) || isLoading(y)) {
    return "loading"
  }
  if (x === null || y === null) {
    return null
  }
  return f(x, y)
}

export const defaultIfLoading = <T>(loadingValue: Loading<T>, defaultValue: T): T =>
  isLoading(loadingValue) ? defaultValue : loadingValue ?? defaultValue

export const matchLoading = <S, T1, T2 = T1, T3 = T2>(
  x: Loading<S>,
  f: Func<S, T1>,
  ifLoading: T2,
  ifNull: T3
): T1 | T2 | T3 => (isLoading(x) ? ifLoading : x === null ? ifNull : f(x))

export const sequenceLoadingPromise = <T>(x: Loading<Promise<T>>): Promise<Loading<T>> =>
  matchLoading(x, identity, Promise.resolve("loading"), Promise.resolve(null))

export namespace Loading_ {
  export const toMaybe = <T>(t: Loading<T>): Maybe<T> => matchLoading(t, Just, Nothing, Nothing)

  export namespace Array {
    export const sequence = <T>(ts: Loading<T>[]): Loading<T[]> =>
      ts.some((t) => t === "loading")
        ? "loading"
        : ts.flatMap((t) => (deprecatedIsLoaded(t) ? [t] : []))
  }

  export namespace Rec {
    export const sequence = <K extends string, T>(
      ts: Record<K, Loading<T>>
    ): Loading<Record<K, T>> =>
      mapLoading(UnsafeRec.fromEntries<Record<K, T>>)(
        Loading_.Array.sequence<EntryOf<Record<K, T>>>(
          UnsafeRec.entries(ts).map(([k, v]) => mapLoading((x: T) => tuple(k)(x))(v))
        )
      )
  }
}
