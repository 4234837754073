import Drawer from "@components/Drawer/Drawer"
import { Switch } from "@headlessui/react"
import { Button } from "@stories/components/Button/Button"
import {
  AccountActivityLogEvent,
  ActivityLogEventGroupType,
  activityLogEventGroups,
  activityLogEventGroupMap,
} from "common/model/AccountActivityLog/AccountActivityLog"
import { Fragment, useState } from "react"
import { useFirebaseWriter } from "src/firebase/Context"
import FilterButton from "src/pages/Data/TableFilters/FilterButton"
import { useAccountActivityLog } from "src/providers/data/AccountActivityLogProvider"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { handleConsoleError } from "src/utils/Tracking"
import { classNames } from "src/utils/classNames"
import { ActivityLogEventCard } from "./ActivityLogEventCard/ActivityLogEventCard"
import { Dropdown } from "@components/inputs/Dropdown"

const AccountActivityLogDrawerInner = ({
  closeDrawer,
  open,
  events,
}: {
  closeDrawer: () => void
  open: boolean
  events: AccountActivityLogEvent[]
}) => {
  const user = useLoggedInUser()
  const firebase = useFirebaseWriter()
  const { loadMore, loadedAllEvents } = useAccountActivityLog()
  const [eventTypeGroup, setEventTypeGroup] = useState<ActivityLogEventGroupType>("All Events")

  const [shouldDisplayUserEvents, setShouldDisplayUserEvents] = useState(false)

  const handleClose = () => {
    firebase
      .recordUserProductInteraction(user.user, {
        lastOpenedActivityLog: new Date(),
      })
      .catch(handleConsoleError)
    closeDrawer()
  }

  const filteredEvents = events.filter((event) => {
    if (!shouldDisplayUserEvents && event.user.id === user.user.id) {
      return false
    }
    if (eventTypeGroup !== "All Events") {
      const includedInGroup = activityLogEventGroupMap[eventTypeGroup].includes(event.eventType)
      if (!includedInGroup) return false
    }
    return true
  })

  return (
    <Drawer
      className="text-sm h-full"
      open={open}
      title="Activity Log"
      size="sm"
      onClose={handleClose}
      destroyOnClose
    >
      <div className="mb-4 flex justify-between items-center mr-2">
        <FilterButton
          onClick={() => setShouldDisplayUserEvents(!shouldDisplayUserEvents)}
          className="mr-1 mb-1 md:mb-0"
          noBackground
        >
          <Switch checked={shouldDisplayUserEvents} as={Fragment}>
            <div className="group relative inline-flex h-4 w-8 flex-shrink-0 cursor-pointer items-center justify-center rounded-full">
              <span
                aria-hidden="true"
                className={classNames(
                  shouldDisplayUserEvents ? "bg-accent-600" : "bg-neutral-500",
                  "pointer-events-none absolute mx-auto h-4 w-8 rounded-full transition-colors duration-200 ease-in-out border-neutral-400 border"
                )}
              />
              <span
                aria-hidden="true"
                className={classNames(
                  shouldDisplayUserEvents ? "translate-x-4" : "translate-x-0",
                  "pointer-events-none absolute left-0 inline-block h-4 w-4 transform rounded-full bg-neutral-white transition-transform duration-200 ease-in-out border-neutral-400 border"
                )}
              />
            </div>
          </Switch>
          <span>Show my activity</span>
        </FilterButton>
        <Dropdown<ActivityLogEventGroupType>
          listboxOptionsClassName="right-5"
          small
          selected={{ id: eventTypeGroup, name: eventTypeGroup }}
          setSelected={(group) => setEventTypeGroup(group.id)}
          label=""
          options={activityLogEventGroups.map((group) => ({ id: group, name: group }))}
        />
      </div>
      <div className="flex flex-col gap-4">
        {filteredEvents.map((event) => (
          <ActivityLogEventCard event={event} key={event.id} />
        ))}
      </div>

      <div>{filteredEvents.length === 0 && <div>No activity detected</div>}</div>
      <div>
        {loadedAllEvents ? (
          <div>You've reached the end of your activity log history</div>
        ) : (
          <div className="flex items-center justify-center mt-4 mb-8">
            <Button label="Load More" variant="primary" onClick={loadMore} isFullWidth />
          </div>
        )}
      </div>
    </Drawer>
  )
}


const AccountActivityLogDrawer = ({
  closeDrawer,
  open,
  events,
}: {
  closeDrawer: () => void
  open: boolean
  events: AccountActivityLogEvent[]
}) =>
  open ? (
    <AccountActivityLogDrawerInner closeDrawer={closeDrawer} open={open} events={events} />
  ) : null

export default AccountActivityLogDrawer
