import { createContext, useContext } from "react"
import { TrustedBrokerConnection } from "common/model/TrustedBrokerConnection"
import { Loading } from "common/utils/Loading"
import { useLoggedInUser } from "./loggedInUser/useLoggedInUser"
import { useTrustedBrokerConnectionQuery } from "src/queries/TrustedBrokerNetwork/useTrustedBrokerConnectionsQuery"


export const AccountTrustedBrokerConnectionsContext =
  createContext<Loading<TrustedBrokerConnection[]>>("loading")

export const AccountTrustedBrokerConnectionsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { user } = useLoggedInUser()
  const currentTrustedBrokerConnections = useTrustedBrokerConnectionQuery({
    account: user.account,
  })
  return (
    <AccountTrustedBrokerConnectionsContext.Provider value={currentTrustedBrokerConnections}>
      {children}
    </AccountTrustedBrokerConnectionsContext.Provider>
  )
}

export const useAccountTrustedBrokerConnections = () =>
  useContext(AccountTrustedBrokerConnectionsContext)
