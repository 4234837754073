import SkeletonLoader from "@components/icons/SkeletonLoader"
import Typography, { Size } from "@stories/components/Typography/Typography"
import { buildAccountCRMSettings, defaultAccountCRMSettings } from "common/model/AccountCRMSettings"
import {
  DealAndOrderStageGroup,
  dealAndOrderStageGroups,
  DealCRMDeal,
} from "common/model/DealCRM/Deal/DealCRMDeal"
import { isLoaded } from "common/utils/Loading"
import { FC } from "react"
import { useCurrentAccount } from "src/queries/currentUser/useCurrentAccount"
import { SelectField } from "../DealParticipantFieldDisplay/DealParticipantFieldDisplay"

interface StageDisplayProps {
  onChange: (newValue: string) => Promise<void>
  value: DealCRMDeal["stage"] | null
  editable: boolean
  noBorder?: boolean
  size?: "sm" | "xs"
  className?: string
  omitStages?: DealAndOrderStageGroup[]
}

const StageDisplay: FC<StageDisplayProps> = ({
  onChange,
  value,
  editable,
  noBorder = false,
  size = "sm",
  className,
  omitStages = [],
}) => {
  const account = useCurrentAccount()
  if (!isLoaded(account)) return <SkeletonLoader numRows={1} />

  const accountCRMPreferences = buildAccountCRMSettings(account)

  const groupedStages =
    accountCRMPreferences.dealAndOrderStages ??
    defaultAccountCRMSettings(account.clientType).dealAndOrderStages

  const stages = dealAndOrderStageGroups
    .filter((group) => !omitStages.includes(group))
    .flatMap((group) => groupedStages[group])

  return (
    <SelectField<string | null>
      className={className}
      size={size === "xs" ? "small" : "middle"}
      bordered={!noBorder}
      value={value}
      options={stages}
      renderValue={(val) =>
        val ? (
          <Typography
            text={val}
            size={size === "sm" ? Size.Small : Size.XSmall}
            shouldWrap={false}
          />
        ) : null
      }
      handleChange={(v) => (v ? onChange(v) : Promise.resolve())}
      editable={editable}
    />
  )
}

export default StageDisplay
