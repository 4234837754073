import moment from "moment"
import _ from "lodash"
import { ConstructedOrder, OrderWithMetaData, mergeCompanyOrders, updatedDateSort } from "./Order"
import { Order } from "./Models/Internal"
import { QueryFilter } from "../../utils/fp/Filter"
import { Path, viewPath } from "../../utils/data/Record/Path"
import { firestoreConverter } from "../FirestoreConverter"
import { restrictedCollections } from "../../firestore/Collections"
import { FirebaseCommon } from "../../firestore/Interface"
import { annotate } from "../../utils/Coerce"
import { Nothing } from "../../containers/Maybe"
import { minimalBy } from "../../utils/data/Array/ArrayUtils"

// TODO: remove from frontend

const companyIdPath: Path<Order, string> = "company.id"

type MatchedOrder = {
  orderOriginationDate: Order["orderOriginationDate"]
  source: Pick<Order["source"], "account">
  company: Order["company"]
}
const accountPath: Path<MatchedOrder, string> = "source.account.id" as Path<MatchedOrder, string>

export const potentialOrderMatchFilter = (order: MatchedOrder) => {
  const baseFilter = QueryFilter.identity<MatchedOrder>()
    .where(accountPath, "==", order.source.account.id)
    .where(companyIdPath, "==", viewPath<MatchedOrder, string>(companyIdPath, order))
  if (order.orderOriginationDate) {
    return baseFilter
      .where<Date | null>(
        "orderOriginationDate",
        ">=",
        moment(order.orderOriginationDate).subtract("3", "months").toDate()
      )
      .where<Date | null>(
        "orderOriginationDate",
        "<=",
        moment(order.orderOriginationDate).add("3", "months").toDate()
      )
  } else {
    return baseFilter
  }
}

export const potentialOrderMatches = (db: FirebaseCommon.DB, order: MatchedOrder) =>
  potentialOrderMatchFilter(order).interpret(
    annotate<FirebaseCommon.Query<OrderWithMetaData>>(
      db
        .collection(restrictedCollections.orderObservations)
        .withConverter<OrderWithMetaData>(firestoreConverter<OrderWithMetaData>())
    )
  )

export const mergeOrderWithFirstValidCandidate = (
  existingOrders: Order[],
  order: ConstructedOrder
) =>
  minimalBy(existingOrders, (a) =>
    Math.abs(moment(order.orderOriginationDate).diff(a.orderOriginationDate, "days"))
  )
    .sort(updatedDateSort("desc"))
    .reduce(
      (accum, next) =>
        accum.or(
          mergeCompanyOrders(next, order) // merge with the first matching
        ),
      Nothing.weaken<Order>()
    ) // order by the latest updatedAtDate (older date first)
