import { orderFromInternal } from "../../../model/Order/Models/UpdateLog"
import { lastOrderUpdateOrOrigination, Order } from "../../../model/Order/Order"
import { orderQuantityTermsPrice, orderQuantityTermsVolume } from "../../../model/Order/Types/Terms"
import { orderQuantityTermsTargetValuation } from "../../../model/Order/Types/Terms/Quantity"
import { intervalMidpoint } from "../../../utils/data/numeric/NumberInterval"
import { Either, Left, Right } from "../../../containers/Either"
import { identity } from "../../../utils/fp/Function"
import { Just, Maybe, Nothing, nullableToMaybe } from "../../../containers/Maybe"
import { Promise_ } from "../../../utils/fp/Promise"
import { FlatOrder, flattenOrderForDisplay } from "../../pricing/displays/Order"
import { PricingDataRoot } from "../../pricing/PricingDataSource"
import { EnrichedWith, MaybeEnrichedWith, Raw } from "../../pricing/PricingEnrichment"
import { splitAdjust } from "../../pricing/splitAdjustment/SplitAdjustment"
import { enrichWithImpliedPPS } from "../payload/ImpliedPPS"
import { enrichWithImpliedValuation } from "../payload/ImpliedValuation"

const getOrderPrice = (o: FlatOrder): Maybe<number> =>
  o.quantity.map(orderQuantityTermsPrice).bind(nullableToMaybe)

const getOrderPriceOrValuation = (o: FlatOrder): Maybe<Either<number, number>> => {
  const price = getOrderPrice(o)
  const val = o.quantity.bind(orderQuantityTermsTargetValuation)
  return price.match(
    (x) => Just(Right(x)),
    () =>
      val.match(
        (x) => Just(Left(x)),
        () => Nothing
      )
  )
}

const getOrderVolume = (o: FlatOrder): Maybe<number> =>
  o.quantity.map(orderQuantityTermsVolume).bind(nullableToMaybe).map(intervalMidpoint)

/** @deprecated */
export const prepOrderForDisplay = async (
  root: PricingDataRoot,
  o: Order
): Promise<
  Maybe<
    Raw<"order"> &
      EnrichedWith<"valuationDisplay" | "priceDisplay" | "impliedPPSDisplay"> &
      MaybeEnrichedWith<"volumeDisplay">
  >
> => {
  const flattened = await Promise_.Maybe.sequence(
    flattenOrderForDisplay(o).map((order) =>
      splitAdjust(root, new Date(), order.company, "order", order)
    )
  )

  const maybeDisplayDate = lastOrderUpdateOrOrigination(o)
  const result = maybeDisplayDate.map(async (displayDate) => {
    const enrichedWithImpliedValuation = await Promise_.Maybe.sequence(
      flattened
        .bind((v) =>
          getOrderPriceOrValuation(v).map((price) => ({
            raw: v,
            stagingRaw: orderFromInternal(o),
            priceDisplay: price.bimap(
              (val) => ({ valuation: val, displayDate }),
              (pps) => ({ pricePerShare: pps, displayDate })
            ),
          }))
        )
        .map((x) => enrichWithImpliedValuation(root)(o.company, displayDate)(x))
    )

    const enrichedWithImpliedPPS = await Promise_.Maybe.sequence(
      enrichedWithImpliedValuation.map((x) => enrichWithImpliedPPS(root)(o.company, displayDate)(x))
    )

    return enrichedWithImpliedPPS.map((x) => ({
      ...x,
      volumeDisplay: getOrderVolume(x.raw).map((volumeUSD) => ({
        displayDate,
        volumeUSD,
      })),
    }))
  })
  return (await Promise_.Maybe.sequence(result)).bind(identity)
}
