import CompanyLogo from "@components/CompanyLogo"
import { CompanyIdFields, CompanyWithPostgres } from "common/model/Company"
import * as React from "react"
import { Size, Typography, Weight } from "../Typography/Typography"
import { truncate } from "lodash"
import { Routes } from "src/Routes/Routes"
import { useNavigate } from "react-router-dom"

export interface CompanyPillProps {
  company: CompanyWithPostgres | CompanyIdFields
  shouldMaskDataInDataDog?: boolean
}

export const CompanyPill: React.FunctionComponent<React.PropsWithChildren<CompanyPillProps>> = ({
  company,
  shouldMaskDataInDataDog,
}) => {
  const navigate = useNavigate()

  const navigateToCompany = () => {
    navigate(Routes.companies.company(company.postgresCompanyId ?? ""))
  }

  return (
    <div
      className="cursor-pointer text-xxs p-1 rounded border font-medium flex items-center gap-2 justify-center bg-neutral-200"
      onClick={navigateToCompany}
    >
      <CompanyLogo company={company.airtableId} size="xxs" />
      <Typography
        text={truncate(company.name, {
          length: 10,
        })}
        size={Size.XSmall}
        weight={Weight.Semibold}
        shouldWrap={false}
        shouldMaskDataInDataDog={shouldMaskDataInDataDog}
      />
    </div>
  )
}
