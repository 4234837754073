import { CompanyMarketHistorySummary } from "./CompanyMarketHistorySummary"

export interface HistoricalCompanyPriceEstimate {
  date: Date
  priceEstimatePPS: number
  // priceEstimateValuation: number TODO: figure this out!
  priceEstimateStandardError: number
}

export const isHistoricalCompanyPriceEstimate = (
  x: unknown
): x is HistoricalCompanyPriceEstimate => {
  if (
    hasKey(x, "date") &&
    x.date instanceof Date &&
    hasKey(x, "priceEstimatePPS") &&
    typeof x.priceEstimatePPS === "number" &&
    hasKey(x, "priceEstimateStandardError") &&
    typeof x.priceEstimateStandardError === "number"
  ) {
    return true
  }
  return false
}

export const priceEstimateSnapshotPeriods = [
  "_1WeekAgoPrice",
  "_2WeeksAgoPrice",
  "_3WeeksAgoPrice",
  "_4WeeksAgoPrice",
  "_5WeeksAgoPrice",
  "_6WeeksAgoPrice",
  "_7WeeksAgoPrice",
  "_8WeeksAgoPrice",
  "_9WeeksAgoPrice",
  "_10WeeksAgoPrice",
  "_11WeeksAgoPrice",
  "_12WeeksAgoPrice",
  "_1MonthAgoPrice",
  "_2MonthsAgoPrice",
  "_3MonthsAgoPrice",
  "_4MonthsAgoPrice",
  "_5MonthsAgoPrice",
  "_6MonthsAgoPrice",
  "_7MonthsAgoPrice",
  "_8MonthsAgoPrice",
  "_9MonthsAgoPrice",
  "_10MonthsAgoPrice",
  "_11MonthsAgoPrice",
  "_1YearAgoPrice",
  "_2YearsAgoPrice",
] as const
export type PriceEstimateSnapshotPeriod = (typeof priceEstimateSnapshotPeriods)[number]

export const timeAgoDates: Record<
  PriceEstimateSnapshotPeriod | "currentPrice",
  [number, moment.unitOfTime.DurationConstructor]
> = {
  currentPrice: [0, "days"],
  _1WeekAgoPrice: [1, "week"],
  _2WeeksAgoPrice: [2, "weeks"],
  _3WeeksAgoPrice: [3, "weeks"],
  _4WeeksAgoPrice: [4, "weeks"],
  _5WeeksAgoPrice: [5, "weeks"],
  _6WeeksAgoPrice: [6, "weeks"],
  _7WeeksAgoPrice: [7, "weeks"],
  _8WeeksAgoPrice: [8, "weeks"],
  _9WeeksAgoPrice: [9, "weeks"],
  _10WeeksAgoPrice: [10, "weeks"],
  _11WeeksAgoPrice: [11, "weeks"],
  _12WeeksAgoPrice: [12, "weeks"],
  _1MonthAgoPrice: [1, "month"],
  _2MonthsAgoPrice: [2, "months"],
  _3MonthsAgoPrice: [3, "months"],
  _4MonthsAgoPrice: [4, "months"],
  _5MonthsAgoPrice: [5, "months"],
  _6MonthsAgoPrice: [6, "months"],
  _7MonthsAgoPrice: [7, "months"],
  _8MonthsAgoPrice: [8, "months"],
  _9MonthsAgoPrice: [9, "months"],
  _10MonthsAgoPrice: [10, "months"],
  _11MonthsAgoPrice: [11, "months"],
  _1YearAgoPrice: [1, "year"],
  _2YearsAgoPrice: [2, "years"],
}

export type CompanyPriceEstimateSummary = Partial<
  Record<PriceEstimateSnapshotPeriod, HistoricalCompanyPriceEstimate>
> & {
  currentPrice?: HistoricalCompanyPriceEstimate & {
    daysHigh?: number
    daysLow?: number
  }
  reasonNotAvailable?: string // this field captures everything that could be wrong with MarketPrice, even if some historic data is available
  metaInformation?: string
  lastModelRunAt?: Date
  updatedAt: Date
}

export type HistoricalCompanyPriceEstimateKey = keyof Omit<
  CompanyPriceEstimateSummary,
  "updatedAt" | "reasonNotAvailable" | "metaInformation" | "lastModelRunAt"
>

const hasKey = (x: unknown, key: string): x is { [key: string]: unknown } =>
  typeof x === "object" && x !== null && key in x

export const companySummaryToPriceEstimateSummary: Record<
  keyof Omit<CompanyMarketHistorySummary, "updatedAt">,
  HistoricalCompanyPriceEstimateKey
> = {
  past7Days: "_1WeekAgoPrice",
  past30Days: "_1MonthAgoPrice",
  past90Days: "_3MonthsAgoPrice",
  past180Days: "_6MonthsAgoPrice",
  pastYear: "_1YearAgoPrice",
  past2Years: "_2YearsAgoPrice",
}
