import { Moment } from "moment"
import { User } from "../../../../model/User"
import { isHidden } from "../../../../model/data-product/DataPoint"
import { PriceObservationType } from "../../../../model/data-product/pricing/PriceObservation"
import { Endo } from "../../../../utils/fp/Function"
import { enrichTrades } from "../../../enrichment/model/ClosedTrades"
import { ClosedTradeCollection } from "../../../pricing/PricingDataSource"
import { Enriched } from "../../../pricing/PricingEnrichment"
import { FirebaseQuery } from "../Collection"

export class FirebaseClosedTrades
  extends FirebaseQuery<PriceObservationType, FirebaseClosedTrades>
  implements ClosedTradeCollection
{
  get: (forUser: User | null, asOf: Moment) => Promise<Enriched<"trade">[]> = () =>
    this._rawGet()
      .then((trades) => trades.filter((trade) => !isHidden(trade)))
      .then(enrichTrades(this.unboxed.root))

  update = (f: Endo<typeof this.unboxed.ref>) =>
    new FirebaseClosedTrades({ ...this.unboxed, ref: f(this.unboxed.ref) })
}
