import { wrapIcon } from "./IconWrapper"

export const RightArrowIcon = wrapIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.13634 4.13634C8.42923 3.84345 8.9041 3.84345 9.197 4.13634L12.5303 7.46967C12.671 7.61033 12.75 7.80109 12.75 8C12.75 8.19892 12.671 8.38968 12.5303 8.53033L9.197 11.8637C8.9041 12.1566 8.42923 12.1566 8.13634 11.8637C7.84344 11.5708 7.84344 11.0959 8.13634 10.803L10.1893 8.75H4C3.58579 8.75 3.25 8.41422 3.25 8C3.25 7.58579 3.58579 7.25 4 7.25H10.1893L8.13634 5.197C7.84344 4.90411 7.84344 4.42923 8.13634 4.13634Z"
      fill="currentColor"
    />
  </svg>
)
