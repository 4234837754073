import { createOrderFromForm } from "common/model/Order/OrderForm/Constructors"
import * as Wrapped from "common/model/Order/Models/Wrapped"
import { finalizeConstructedOrder } from "common/model/Order/Order"
import { identity } from "common/utils/fp/Function"
import { Loading, Loading_ } from "common/utils/Loading"
import { Company } from "common/model/Company"
import { OrderFormState } from "common/model/Order/OrderForm/State"
import { validateFormOrder } from "common/model/Order/OrderForm/Validation"

export const formOrderToWrappedOrder = (
  formOrder: OrderFormState,
  company: Loading<Company>,
  initialFormOrder: OrderFormState
) => {
  const o = validateFormOrder(formOrder).match(() => null, identity)
  if (o) {
    const order = finalizeConstructedOrder(createOrderFromForm(o, initialFormOrder), "pending")
    return Wrapped.Order.wrapRaw({
      order,
      company: Loading_.toMaybe(company),
    }).withUnconstrainedDefault(null)
  }
  return null
}
