import { nullableToMaybe } from "common/containers/Maybe"
import { orderDataAccessLevel } from "../../../shared/accessLevels"
import OrderTermSheetSection from "../OrderTermSheetSection"
import { UNSPECIFIED_VALUE } from "./constants"
import { OrderPageSectionProps } from "./types"

export const DEAL_EXECUTION_SECTION_TITLE = "Deal Execution Details"

const DealExecutionSection = ({ order, hideUnspecified }: OrderPageSectionProps) => {
  const accessLevel = orderDataAccessLevel.dealExecution(order.isConnectable())

  const diligenceAvailableValue = nullableToMaybe(
    order.unboxed.order.base.orderDocuments?.diligenceAvailable
  )
    .map((x) => (x === "Unknown" ? UNSPECIFIED_VALUE : x))
    .withUnconstrainedDefault(UNSPECIFIED_VALUE)
  const diligenceAvailableRow = {
    label: "Is diligence available to serious buyers",
    value: diligenceAvailableValue,
    accessLevel,
  }
  const requiresDiligenceValue = nullableToMaybe(
    order.unboxed.order.base.orderDocuments?.requiresDiligence
  )
    .map((x) => (x === "Unknown" ? UNSPECIFIED_VALUE : x))
    .withUnconstrainedDefault(UNSPECIFIED_VALUE)
  const requiresDiligenceRow = {
    label: "Is diligence required before proceeding",
    value: requiresDiligenceValue,
    accessLevel,
  }

  const directionDependentRows =
    order.direction() === "sell" ? [diligenceAvailableRow] : [requiresDiligenceRow]

  return (
    <OrderTermSheetSection
      title={DEAL_EXECUTION_SECTION_TITLE}
      rows={directionDependentRows}
      hideUnspecified={hideUnspecified}
    />
  )
}

export default DealExecutionSection
