import { Boxed } from "../../../utils/fp/Boxed"
import { nullableToMaybe } from "../../../containers/Maybe"
import { FirebaseCommon, FirestoreDataContext } from "../../../firestore/Interface"

export abstract class FirebaseDocument<T> extends Boxed<
  FirestoreDataContext & {
    id: string
    ref: FirebaseCommon.Doc<T>
  }
> {
  protected _rawGet = () => this.unboxed.ref.get().then((x) => nullableToMaybe(x.data()))
}
