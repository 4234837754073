
export const ORDER_DETAIL_SEARCH_PROP = "selected-indication-id"
export const DARKPOOL_ORDER_DETAIL_SEARCH_PROP = "selected-darkpool-indication-id"
export const ORDER_REFRESH_SEARCH_PROP = "refresh-indication"
export const DARKPOOL_ORDER_REFRESH_SEARCH_PROP = "refresh-darkpool-indication"
export const ORDER_EXPIRE_SEARCH_PROP = "expire-indication"
export const DARKPOOL_ORDER_EXPIRE_SEARCH_PROP = "expire-darkpool-indication"
export const DETAIL_TAB = "section"
export type OrderDetailsTabId = "details" | "sharing"
export const COLLECTION_SEARCH_PROP = "c"
