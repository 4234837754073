import { Company, CompanyWithPostgres, LogoSize } from "common/model/Company"
import { useNavigate, useLocation } from "react-router-dom"
import { Routes } from "../../Routes/Routes"
import { classNames } from "../../utils/classNames"
import useCompanyLogo, { CompanyAirtableId } from "../../utils/companies/useCompanyLogo"
import { CompanyLogoImgInner } from "./CompanyLogoImg"

type CompanyLogoProps = {
  company: CompanyWithPostgres | CompanyAirtableId | Company
  size: LogoSize
  className?: string
  disableClick?: boolean
}
const CompanyLogo = ({ company, size, className, disableClick }: CompanyLogoProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const { logoUrl, error, setError, postgresId } = useCompanyLogo(company, size)

  // TODO - update after company page routing changes from /postgresId => /companyName
  const navigateToCompany = (companyId: string) => {
    navigate(Routes.companies.company(companyId))
  }

  const disabledLogoClickRoutes = [Routes.companies.company(postgresId)]
  const disableLogoClick = disableClick || disabledLogoClickRoutes.includes(location.pathname)

  const handleLogoClick = () => {
    if (!disableLogoClick) navigateToCompany(postgresId)
  }

  return disableClick ? (
    <div className={classNames(className, logoClass[size])}>
      <CompanyLogoImgInner logoUrl={logoUrl} error={error} setError={setError} size={size} />
    </div>
  ) : (
    <button
      type="button"
      onClick={() => handleLogoClick()}
      className={classNames(
        className,
        logoClass[size],
        postgresId && !disableLogoClick ? "cursor-pointer" : "cursor-default"
      )}
    >
      <CompanyLogoImgInner logoUrl={logoUrl} error={error} setError={setError} size={size} />
    </button>
  )
}

export default CompanyLogo

export const logoClass: { [key in LogoSize]: string } = {
  xxs: "h-3 w-3",
  xs: "h-6 w-6",
  sm: "h-12 w-12",
  md: "h-16 w-16",
  lg: "h-24 w-24",
}
