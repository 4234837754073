import { Menu } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import React, { ReactElement } from "react"
import AdminVisible from "src/admin/AdminVisible"
import { Tooltip } from "../Antd"

export type DropdownMenuItem = {
  id: string
  label: string
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
  isAdminOnly?: boolean
  variant: "danger" | "warning" | "success" | "accent"
  icon?: ReactElement
  isDisabled?: boolean
  heapName: string
  tooltip?: string
}

export interface DropdownMenuProps {
  dropdownButtonTitle?: string | React.ReactNode
  dropdownButtonSubtitle?: string
  menuButtonIcon?: ReactElement
  menuItems: DropdownMenuItem[]
  accountInfo?: ReactElement
  className?: string
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  dropdownButtonTitle,
  dropdownButtonSubtitle,
  menuButtonIcon,
  menuItems,
  accountInfo,
  className,
}) => (
  <Menu as="div" className={className}>
    <Menu.Button className="max-w-xs flex items-center text-sm focus:outline-none">
      {menuButtonIcon ? (
        <div className="h-8 w-8 rounded-full hover:bg-neutral-300 flex items-center justify-center">
          {menuButtonIcon}
        </div>
      ) : (
        <div className="flex space-x-4 items-center text-white md:text-neutral-1000 font-sm">
          <div className="text-left text-sm">
            <div className="font-semibold">{dropdownButtonTitle}</div>
            <div>{dropdownButtonSubtitle}</div>
          </div>
          <ChevronDownIcon className="h-4 w-4" />
        </div>
      )}
    </Menu.Button>
    <Menu.Items className="md:origin-top-right absolute z-50 md:top-auto right-0 mt-2 w-64 rounded-md shadow-lg py-1 bg-neutral-white border focus:outline-none">
      {accountInfo}
      {menuItems.map((item: DropdownMenuItem) => {
        const { tooltip, label, isAdminOnly, variant, onClick, icon, id, isDisabled, heapName } =
          item
        const menuItem = (
          <Menu.Item key={label}>
            <div
              className={`${
                isDisabled
                  ? "cursor-not-allowed text-neutral-300"
                  : `text-neutral-1000 hover:text-${variant}-500`
              }`}
            >
              <Tooltip
                title={tooltip ? <div className="max-w-sm">{tooltip}</div> : null}
                placement="left"
              >
                <button
                  type="button"
                  data-heap-name={heapName}
                  onClick={onClick}
                  className="flex items-center gap-2 w-full text-left px-4 py-2 text-sm"
                  disabled={item.isDisabled}
                >
                  {icon !== undefined ? icon : null}
                  {label}
                </button>
              </Tooltip>
            </div>
          </Menu.Item>
        )

        if (isAdminOnly) {
          return <AdminVisible key={label}>{menuItem}</AdminVisible>
        }

        return menuItem
      })}
    </Menu.Items>
  </Menu>
)

export default DropdownMenu
