import Typography, { Color, Size } from "@stories/components/Typography/Typography"
import { RightArrowIcon } from "@stories/icons/RightArrowIcon"
import { ReadableFieldUpdate } from "common/model/AccountActivityLog/AccountActivityLog"
import { FC } from "react"

export const UpdatedFieldDisplay: FC<ReadableFieldUpdate> = ({
  displayField,
  oldValue,
  newValue,
}) => {
  const oldValueDisplay = oldValue === "" ? "None" : oldValue
  const newValueDisplay = newValue === "" ? "None" : newValue
  return (
    <div>
      <div className="">
        <Typography color={Color.Subtitle} size={Size.XXSmall} text={displayField.toUpperCase()} />
      </div>
      <div className="border flex gap-2 p-2 rounded text-xs items-center">
        <div className="whitespace-nowrap">{oldValueDisplay}</div>
        <RightArrowIcon />
        <div className="whitespace-nowrap">{newValueDisplay}</div>
      </div>
    </div>
  )
}