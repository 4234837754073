import seedrandom from "seedrandom"
import { Func, tuple } from "./fp/Function"
import { UnsafeRec } from "./RecordUtils"
import { isNonempty } from "./data/Array/Nonempty"

export const randomString = () =>
  Math.random().toString(36).substring(2, 8) + Math.random().toString(36).substring(2, 8)

export class Random<T, Generator = string> {
  step: (g: Generator) => [T, Generator]

  constructor(step: (g: Generator) => [T, Generator]) {
    this.step = step
  }

  stepGenerator = (g: Generator): Generator => this.step(g)[1]

  sample = (g: Generator): T => this.step(g)[0]

  map = <T2>(f: Func<T, T2>): Random<T2, Generator> =>
    new Random((g) => {
      const [val, g2] = this.step(g)
      return [f(val), g2]
    })

  bind = <T2>(f: Func<T, Random<T2, Generator>>): Random<T2, Generator> =>
    new Random((g) => {
      const [next, g2] = this.step(g)
      return f(next).step(g2)
    })

  /** Combine a generator for two types into a generator for the corresponding tuple type */
  alongside = <T2>(y: Random<T2, Generator>): Random<[T, T2], Generator> =>
    this.bind((x) => y.map((v) => tuple(x)(v)))

  /** Applies rejection sampling using the given predicate. */
  filter = (f: (t: T) => boolean): Random<T, Generator> => {
    const x: Random<T, Generator> = this.bind((t) => (f(t) ? Random.pure(t) : x))
    return x
  }

  /** A generator that always returns the given value */
  static pure = <X, Y>(val: X): Random<X, Y> => new Random((g) => [val, g])

  /** Construct a generator for a record type from a record of generators for its fields */
  static record = <R, G = string>(rec: { [key in keyof R]: Random<R[key], G> }): Random<R, G> => {
    const entries = UnsafeRec.entries(rec)

    return !isNonempty(entries)
      ? Random.pure({} as R)
      : (entries
          .map(([k, v]) => v.map((x) => ({ [k]: x })))
          .reduce((l, r) => l.alongside(r).map(([x, y]) => ({ ...x, ...y }))) as unknown as Random<
          R,
          G
        >)
  }

  static pair = <L, R>(l: Random<L>, r: Random<R>): Random<[L, R]> =>
    l.bind((x) => r.map((y) => tuple(x)(y)))

  static uniformFloatBetween = (lower: number, upper: number) =>
    uniform01.map((x) => x * (upper - lower) + lower)

  static uniformIntBetween = (lower: number, upper: number) =>
    Random.uniformFloatBetween(lower, upper).map(Math.floor)

  static uniformFrom = <X>(xs: X[]): Random<X, string> =>
    Random.uniformIntBetween(0, xs.length).map((i) => xs[i])
}

export const uniform01: Random<number> = new Random((g) => [
  seedrandom(g)(),
  seedrandom(g)().toString(),
]) // Probably not a good choice of seed

export const biasedRandInt = (atLeast: number, lessThan: number): Random<number> =>
  new Random((g) => [
    (Math.abs(seedrandom(g).int32()) % (lessThan - atLeast)) + atLeast,
    seedrandom(g)().toString(),
  ]) // Using % biases the results towards smaller numbers unless 2^32 % n == n - 1. TODO: implement unbiased version
export const randomArrayEntry = <T>(arr: T[]): Random<T> =>
  biasedRandInt(0, arr.length).map((i) => arr[i])

export const randomIntIO = (atLeast: number, lessThan: number): number => {
  const l = Math.ceil(atLeast)
  const u = Math.floor(lessThan)
  return Math.floor(Math.random() * (u - l) + l)
}

const unsafeSwap = <T>(ts: T[], i: number, j: number): T[] => {
  const temp = ts[i]
  // eslint-disable-next-line
  ts[i] = ts[j]
  // eslint-disable-next-line
  ts[j] = temp
  return ts
}
export const shuffleIO = <T>(ts: T[]): T[] => {
  const shuffling = ts.map((t) => t)
  for (let idx = 0; idx < ts.length - 2; idx += 1) {
    const targetIdx = randomIntIO(0, idx + 1)
    unsafeSwap(shuffling, idx, targetIdx)
  }
  return shuffling
}
