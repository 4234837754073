import { SubmittedDocument } from "common/model/files/DocumentSubmission"
import { useEffect, useMemo, useState } from "react"
import { useFirebaseAdmin } from "src/firebase/Context"
import { readFileData } from "src/utils/FileUtils"
import Typography from "@stories/components/Typography/Typography"
import { handleConsoleError } from "src/utils/Tracking"

export const ViewDocumentLink = ({ document }: { document: SubmittedDocument }) => {
  const firebase = useFirebaseAdmin()
  const [fileLink, setFileLink] = useState<string | null>(null)
  const documentSubmissionName = useMemo(() => {
    if (!document?.filePath) return ""
    const [fileName] = document.filePath.split("/").slice(-1)
    return fileName
  }, [document.filePath])
  useEffect(() => {
    if (document.filePath) {
      readFileData(firebase, document.filePath)
        .then((v) => setFileLink(v.url))
        .catch(handleConsoleError)
    }
  }, [document.filePath, firebase])

  return fileLink ? (
    <a href={fileLink} target="_blank" rel="noreferrer">
      <Typography text={documentSubmissionName} />
    </a>
  ) : (
    <Typography text={documentSubmissionName} />
  )
}
