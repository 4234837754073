import * as z from "zod"
import { productAreas, productStatuses } from "../Auth/Permissions"
import { clientUseCases } from "../ClientUseCases"
import { accountClientTypes } from "../Account/AccountClientType"
import { AccountSubscriptionType } from "../Account/AccountSubscriptionType"
import { accountRfqProfiles } from "../Account/AccountRFQProfiles"
import { targetCheckSizes } from "../Account/AccountTargetCheckSize"
import { weeksToCloseOptions } from "../Account/AccountWeeksToClose"
import { cashOnHandOptions } from "../Account/AccountCashOnHand"

const contributionFrequencies = ["Monthly", "Weekly", "None"] as const
export type AirtableAccount = z.infer<typeof airtableAccount>

export const airtableAccount = z.object({
  id: z.string(),
  "Fund Name": z.string(),
  PBID: z.string().optional(),
  "Is Self Signup Account": z.boolean().optional(),
  "Use Cases": z.enum(clientUseCases).array().optional(),
  "Client Type": z.enum(accountClientTypes).array().optional(),
  "Client Status": z.string().optional(),
  "Target Deal Size Min ($M)": z.number().optional(),
  "Target Deal Size Max ($M)": z.number().optional(),
  "Suggested Watchlist - DO NOT EDIT": z.string().array().optional(),
  "Product Areas": z.enum(productAreas).array().optional(),
  "Is Quota System Enabled": z.boolean().optional(),
  "Enforce Order Quota Compliance": z.boolean().optional(),
  "Order Quota Minimum Number Of Compliant Orders": z.number().optional(),
  "Order Quota Minimum Total Compliant Order Volume": z.number().optional(),
  "Order Quota Minimum Order Size": z.number().optional(),
  platformAccountId: z.string().optional(),
  platformAccountCreatedAt: z.number().or(z.string()).optional(),
  "Data onboarding status": z.enum(productStatuses).optional(),
  /** @deprecated */
  "Data trial length (Days)": z.number().optional(),
  "Data trial expiration date": z.number().or(z.string()).optional(),
  "Data contributors": z.string().array().optional(),
  "Contribution Frequency": z.enum(contributionFrequencies).optional(),
  "RFQ Profile": z.enum(accountRfqProfiles).or(z.string()).optional(),
  "Default Connect Points of Contact": z.string().array().optional(),
  "Connect Do-Not-Intro List": z.string().array().optional(),
  "Is Broker Linking Enabled": z.boolean().optional(),
  "Is Opportunity Inbox Enabled": z.boolean().optional(),
  "Is Deal Distributions Enabled": z.boolean().optional(),
  "Opportunity Inbox Forwarding Email": z.string().optional(),
  "Is Top Broker": z.boolean().optional(),
  "Paying Customer": z.boolean().optional(),
  "MSA with Caplight": z.boolean().optional(),
  "Already Have MSA": z.boolean().optional(),
  "Quota MSA": z.boolean().optional(),
  "Data onboarding status last modified": z.number().or(z.string()).optional(),
  // Deal Distribution Investment Preferences
  Profile: z.string().array().optional(),
  "Location(s)": z.string().array().optional(),
  "Firm Total AUM": z.string().array().optional(),
  "Requires Diligence": z.boolean().optional(),
  "Can Pay Fee / Carry": z.boolean().optional(),
  "Check Size": z.enum(targetCheckSizes).array().optional(),
  "Sectors of Interest": z.string().array().optional(),
  "Estimated Time to Close (wks)": z.enum(weeksToCloseOptions).optional(),
  "Cash on Hand": z.enum(cashOnHandOptions).optional(),
  "Company Data Downloads Enabled": z.boolean().optional(),
})

export const airtableAccountSubscriptionType = (
  account: AirtableAccount
): AccountSubscriptionType => {
  if (!account["Product Areas"]?.includes("data")) return "limited-account"
  if (account["Paying Customer"]) return "paid-subscriber"
  if (account["Quota MSA"] && account["Is Quota System Enabled"]) return "quota-subscriber"
  if (account["MSA with Caplight"] || account["Already Have MSA"]) return "data-contributor"
  if (account["Data onboarding status"] === "trial") return "trial-account"
  if (account["Data onboarding status"] === "complete") return "free-subscriber"
  return "limited-account"
}
