import {
  useOrderExpireModal,
  useOrderRefreshModal,
} from "@components/OrderDetails/OrderDetailsWrapper"
import { Badge, Tooltip } from "@stories/components/Antd"
import { Button, ButtonSize } from "@stories/components/Button/Button"
import { RenewIcon } from "@stories/icons/RenewIcon"
import { TrashIcon } from "@stories/icons/TrashIcon"
import { UploadIcon } from "@stories/icons/UploadIcon"
import { LIVE_UNTIL_DEFAULT_DAYS } from "common/model/Order/OrderConstants"
import { isRecentlyRefreshed, lastUserRefreshedDate } from "common/model/Order/Types/Status"
import { deprecatedIsLoaded, matchLoading } from "common/utils/Loading"
import { shortDateFormat } from "common/utils/dateUtils"
import { CreateClosedTradeFromOrderButton } from "src/pages/ClosedTrades/ClosedTradeForm/CreateClosedTradeFromOrderButton"
import { useCurrentAccount } from "src/queries/currentUser/useCurrentAccount"
import { useOwnOrderControls } from "./Controls/Context"
import { OrderItem } from "./MyOrdersTypes"
import { getMyOrderStatus } from "./utils"
import { ButtonHTMLAttributes } from "react"
import { useCompanies } from "src/providers/data/CompanyProvider"
import { isCompanyPublic } from "common/model/Company"
import { IconWrapperProps } from "@stories/icons/IconWrapper"

const BadgedRenewIcon = ({ dot, ...props }: IconWrapperProps & { dot?: boolean }) => (
  <Badge offset={[-1, 1]} dot={dot}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <RenewIcon {...props} />
  </Badge>
)

export const RenewButton: React.FC<
  React.PropsWithChildren<
    OrderItem & {
      iconOnly?: boolean
      size?: ButtonSize
      sourceComponent: RefreshSourceComponent | "admin-panel"
      disabled?: boolean
      isTransparent?: boolean
      dot?: boolean
    } & Pick<
        ButtonHTMLAttributes<HTMLButtonElement>,
        "onMouseEnter" | "onMouseLeave" | "onPointerEnter" | "onPointerLeave"
      >
  >
> = ({ order, iconOnly, dot, size, sourceComponent, disabled, isTransparent, ...props }) => {
  const { open: refreshOpen } = useOrderRefreshModal()
  return (
    <Button
      {...props}
      data-testid="refresh-order-button"
      heapName="refresh-order-button"
      dataAttributes={{ "source-component": sourceComponent }}
      size={size}
      isDisabled={disabled}
      isTransparent={isTransparent}
      variant="secondary"
      label={!iconOnly ? "Renew" : ""}
      leftIcon={<BadgedRenewIcon dot={dot} />}
      onClick={() => {
        refreshOpen(order)
      }}
    />
  )
}

type RefreshSourceComponent =
  | "order-details-page"
  | "requires-attention-widget"
  | "my-orders-table"
  | "rfq-page"
  | "notifications"
  | "crm-interest-row"

export const RefreshButton: React.FC<
  React.PropsWithChildren<
    OrderItem & {
      iconOnly?: boolean
      size?: ButtonSize
      sourceComponent: RefreshSourceComponent
      isTransparent?: boolean
    }
  >
> = ({ order, iconOnly, size, sourceComponent, isTransparent }) => {
  const account = useCurrentAccount()
  const { open: expireOpen } = useOrderExpireModal()
  const { recordClosedTradeOnOrder } = useOwnOrderControls()
  const company = useCompanies(order.company.id)

  const myOrderStatus = getMyOrderStatus(order)
  const recentlyRefreshed = isRecentlyRefreshed(order) && myOrderStatus === "Active"
  const lastRefreshedDate = lastUserRefreshedDate(order).toNullable()

  const expirationLabel = myOrderStatus === "Expired" ? "Confirm Expiration" : "Expire Now"

  return (
    <>
      {["Active", "Expired", "Expiring", "Matched", "Closed Canceled", "Unknown"].includes(
        myOrderStatus
      ) ? (
        <Tooltip
          placement="top"
          title={
            recentlyRefreshed && lastRefreshedDate
              ? `Updated ${shortDateFormat(lastRefreshedDate)}`
              : `Renew this order for  ${LIVE_UNTIL_DEFAULT_DAYS} days`
          }
        >
          <RenewButton
            disabled={recentlyRefreshed || matchLoading(company, isCompanyPublic, true, true)}
            order={order}
            iconOnly={iconOnly}
            size={size}
            sourceComponent={sourceComponent}
            isTransparent={isTransparent}
            dot={["Expiring"].includes(myOrderStatus)}
          />
        </Tooltip>
      ) : null}
      {["Close Pending"].includes(myOrderStatus) && deprecatedIsLoaded(account) ? (
        <CreateClosedTradeFromOrderButton
          order={order}
          account={account}
          afterSave={(p) => recordClosedTradeOnOrder(order, p?.id)}
          renderButton={(onClick) => (
            <Tooltip placement="top" title="Submit Closed Trade">
              <Button
                data-testid="submit-closed-trade-order-button"
                heapName="submit-closed-trade-order-button"
                dataAttributes={{ "source-component": sourceComponent }}
                size={size}
                variant="secondary"
                label={!iconOnly ? "Submit Closed Trade" : ""}
                leftIcon={
                  <Badge dot={["Close Pending"].includes(myOrderStatus)}>
                    <UploadIcon />
                  </Badge>
                }
                onClick={onClick}
                isTransparent={isTransparent}
              />
            </Tooltip>
          )}
        />
      ) : null}
      {!["Close Pending", "Closed Canceled", "Closed"].includes(myOrderStatus) ? (
        <Tooltip placement="top" title={expirationLabel}>
          <Button
            data-testid="expire-order-button"
            heapName="expire-order-button"
            dataAttributes={{ "source-component": sourceComponent }}
            size={size}
            isTextWrapped={false}
            variant="secondary"
            label={!iconOnly ? expirationLabel : ""}
            leftIcon={
              <Badge dot={["Expired", "Matched"].includes(myOrderStatus)}>
                <TrashIcon />
              </Badge>
            }
            isTransparent={isTransparent}
            onClick={() => {
              expireOpen(order)
            }}
          />
        </Tooltip>
      ) : null}
    </>
  )
}
