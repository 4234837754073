import React from "react"
import Typography, { Color, Size, Weight } from "../Typography/Typography"

export interface MetricProps {
  label: string | JSX.Element
  value: string | number
  alignment: "left" | "center"
  size?: "regular" | "small"
  metricChange?: JSX.Element
}

const Metric: React.FC<MetricProps> = ({
  label,
  value,
  alignment,
  metricChange,
  size = "regular",
}) => (
  <div
    className={`flex flex-col justify-center ${size === "regular" ? "gap-2" : "gap-0"} ${
      alignment === "center" ? "items-center" : ""
    }`}
  >
    <div className="flex items-center">
      <Typography
        color={Color.Primary}
        text={value}
        weight={size === "small" ? Weight.Regular : Weight.Bold}
        size={size === "small" ? Size.Small : Size.Medium}
      />
      {metricChange ? <div className="w-1" /> : null}
      {metricChange || null}
    </div>
    <Typography color={Color.Subtitle} text={label} size={Size.XSmall} shouldWrap={false} />
  </div>
)

export default Metric
