import { firestoreConverter } from "./FirestoreConverter"

/**
 * This interface is for collections.publicOptionData collection.
 * Each document there contains public company info, and then there is a subcollection with
 * option chains (for various days, expiries and moneyness).
 *
 * Importantly, whenever a ticker is added to this collection, it triggers daily option chain pull.
 * It's not something we want for the full universe of public companies, which is 10,000s.
 *
 * This is distinct from PublicCompany interface. PublicCompany is for the full universe of public companies.
 */

export type PublicCompanyStatus = "active_with_options" | "active_without_options" | "inactive"
export interface PublicCompanyForOptionsImport {
  id?: string
  ticker: string
  skipOptionsImport?: boolean
  companyNameOverride?: string
  companyDisplayName?: string
  companyShortName?: string
  companyLongName?: string
  marketCap?: number
  exchange?: string
  marketPrice?: number
  updatedAt?: Date
  companyStatus?: PublicCompanyStatus
}

export const publicCompanyForOptionsImportConverter =
  firestoreConverter<PublicCompanyForOptionsImport>()
