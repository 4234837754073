import { Account } from "common/model/Account"
import { useTopBrokerCriteria } from "./useTopBrokerCriteria"
import { useState } from "react"
import { Card } from "@stories/components/Card/Card"
import { topBrokerCriteria } from "./TopBrokerCriteriaConfig"
import { TopBrokerCriteriaCard } from "./TopBrokerCriteriaCard"
import { AnimatePresence, motion } from "framer-motion"
import { Button } from "@stories/components/Button/Button"
import DownChevronIcon from "@stories/icons/DownChevron"
import UpChevronIcon from "@stories/icons/UpChevronIcon"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { TopBrokerCriteriaDisclaimer } from "./TopBrokerCriteriaDisclaimer"
import { TopBrokerPill } from "@stories/components/Badges/TopBrokerPill/TopBrokerPill"
import { HashLink } from "react-router-hash-link"
import { displayYearAndQuarter } from "common/utils/dateUtils"
import moment from "moment"

const PreviousTopBrokerCriteriaCards = ({
  account,
  quarterStartDate,
  quarterEndDate,
}: {
  account: Account
  quarterStartDate: Date
  quarterEndDate: Date
}) => {
  const criteria = useTopBrokerCriteria({
    config: topBrokerCriteria,
    account,
    startDate: quarterStartDate,
    endDate: quarterEndDate,
  })
  return (
    <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
      {criteria.map((c) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <TopBrokerCriteriaCard key={c.type} {...c} />
      ))}
    </div>
  )
}

export const PreviousTopBrokerCriteria = ({
  account,
  quarterStartDate,
  quarterEndDate,
}: {
  account: Account
  quarterStartDate: Date
  quarterEndDate: Date
}) => {
  const [open, setOpen] = useState(false)
  const handleToggleOpen = () => {
    setOpen(!open)
  }
  return (
    <Card>
      <div className="flex flex-col items-start w-full">
        <div
          className="flex items-center justify-between w-full"
          onClick={handleToggleOpen}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => e.code === "Enter" && handleToggleOpen()}
        >
          <div>
            <Typography
              text={`${displayYearAndQuarter(moment(quarterEndDate).add(1, "week").toDate())} : ${
                account.isTopBroker
                  ? "You are currently a Top Broker"
                  : "You are currently not a Top Broker"
              }`}
              weight={Weight.Semibold}
              size={Size.XLarge}
              color={Color.Primary}
            />
          </div>
          <div className="flex items-center">{account.isTopBroker ? <TopBrokerPill /> : null}</div>
        </div>
      </div>
      <AnimatePresence mode="wait">
        <motion.div
          key={open ? "open" : "closed"}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 10, opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          {open ? (
            <div className="flex flex-col gap-4">
              <Typography
                text={`This criteria was determined from your activity in the previous period (${displayYearAndQuarter(
                  quarterStartDate
                )}).`}
                color={Color.Subtitle}
                size={Size.Small}
              />
              <PreviousTopBrokerCriteriaCards
                account={account}
                quarterStartDate={quarterStartDate}
                quarterEndDate={quarterEndDate}
              />
              <TopBrokerCriteriaDisclaimer />
            </div>
          ) : null}
        </motion.div>
      </AnimatePresence>
      <Button
        variant="hollow"
        size="small"
        label="View Details"
        rightIcon={!open ? <DownChevronIcon /> : <UpChevronIcon />}
        onClick={handleToggleOpen}
      />
    </Card>
  )
}
