import { firestoreConverter } from "@model/FirestoreConverter"
import { Button } from "@stories/components/Button/Button"
import { Input } from "@stories/components/Inputs/Input/Input"
import Typography, { Size, Weight } from "@stories/components/Typography/Typography"
import { SearchIcon } from "@stories/icons/SearchIcon"
import { collections } from "common/firestore/Collections"
import { LinkedBroker } from "common/model/LinkedBroker"
import {
  AccountNetworkNode,
  connectedAccountNetworkNodeStatuses,
  getConnectionNodes,
  TrustedBrokerConnection,
} from "common/model/TrustedBrokerConnection"
import { getDocs, collection } from "firebase/firestore"
import { useState, useEffect, useMemo, useCallback } from "react"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { handleConsoleError } from "src/utils/Tracking"
import { TrustedBrokerNetworkCard } from "./TrustedBrokerNetworkCard"

import { AnimatePresence, motion } from "framer-motion"
import { emailPatternString } from "common/utils/StringUtils"

const getSearchResult = (searchValue: string, allBrokers: LinkedBroker[]): LinkedBroker[] => {
  const upperSearch = searchValue.toUpperCase()
  if (searchValue.length === 0) return allBrokers
  return allBrokers.filter((b) =>
    [
      b.firmName.toUpperCase().includes(upperSearch),
      b.fullName.toUpperCase().includes(upperSearch),
    ].some(Boolean)
  )
}

const MissingBrokerAddition = ({
  searchValue,
  handleAddToNetwork,
}: {
  searchValue: string
  handleAddToNetwork: (connection: {
    connectionAccount: AccountNetworkNode
    status: AccountNetworkNode["status"]
  }) => void
}) => {
  const [missingBrokerEmail, setMissingBrokerEmail] = useState("")
  const [missingBrokerName, setMissingBrokerName] = useState(searchValue)

  const validEmail = useMemo(
    () => emailPatternString.test(missingBrokerEmail) && missingBrokerName.length,
    [missingBrokerEmail, missingBrokerName.length]
  )

  const handleAddMissingBroker = useCallback(() => {
    if (!validEmail) return
    handleAddToNetwork({
      connectionAccount: {
        tag: "new invite",
        name: missingBrokerName,
        email: missingBrokerEmail,
        status: "inviteReceived",
      },
      status: "sentInvite",
    })
  }, [validEmail, handleAddToNetwork, missingBrokerName, missingBrokerEmail])

  return (
    <TrustedBrokerNetworkCard>
      <div className="flex flex-col items-start gap-2">
        <Typography text="Invite New Broker" weight={Weight.Semibold} />
        <Typography text="We'll send an invite to this new broker." size={Size.XSmall} />
        <Input
          placeholder="Broker name..."
          label="Name"
          onChange={(e) => setMissingBrokerName(e.target.value)}
          value={missingBrokerName}
        />
        <Input
          placeholder="Send invite to email..."
          label="Email"
          onChange={(e) => setMissingBrokerEmail(e.target.value)}
          value={missingBrokerEmail}
        />
        <Button isDisabled={!validEmail} label="Invite" onClick={handleAddMissingBroker} />
      </div>
    </TrustedBrokerNetworkCard>
  )
}

const EmptySearch = ({
  searchValue,
  handleAddToNetwork,
}: {
  searchValue: string
  handleAddToNetwork: (connection: {
    connectionAccount: AccountNetworkNode
    status: AccountNetworkNode["status"]
  }) => void
}) => {
  const [addingMissingBroker, setAddingMissingBroker] = useState(false)

  return (
    <>
      <TrustedBrokerNetworkCard>
        <div className="flex flex-col items-start gap-2">
          <SearchIcon size="xlarge" />
          <Typography text="No results found" weight={Weight.Semibold} />
          <Typography text="Try searching for another broker or brokerage" size={Size.XSmall} />
          {addingMissingBroker ? null : (
            <Button
              variant="hollow"
              label="Add missing broker"
              onClick={() => setAddingMissingBroker(true)}
            />
          )}
        </div>
      </TrustedBrokerNetworkCard>
      {addingMissingBroker ? (
        <AnimatePresence>
          <motion.div
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.4 }}
          >
            <MissingBrokerAddition
              searchValue={searchValue}
              handleAddToNetwork={(connection) => {
                handleAddToNetwork(connection)
                setAddingMissingBroker(false)
              }}
            />
          </motion.div>
        </AnimatePresence>
      ) : null}
    </>
  )
}

const NotSearchingYet = () => (
  <>
    <TrustedBrokerNetworkCard>
      <div className="flex  items-start gap-2">
        <SearchIcon size="large" />
        <Typography text="Try searching for another broker or brokerage" size={Size.Small} />
      </div>
    </TrustedBrokerNetworkCard>
  </>
)

export const TrustedBrokerSearch = ({
  connections,
  handleAddToNetwork,
}: {
  connections: TrustedBrokerConnection[]
  handleAddToNetwork: (connection: {
    connectionAccount: AccountNetworkNode
    status: AccountNetworkNode["status"]
  }) => void
}) => {
  const { user } = useLoggedInUser()
  const { db } = useFirebase9()
  const [searchValue, setSearchValue] = useState<string>("")
  const [allBrokers, setAllBrokers] = useState<LinkedBroker[]>([])
  useEffect(() => {
    getDocs(
      collection(db, collections.linkedBrokers).withConverter<LinkedBroker>(
        firestoreConverter<LinkedBroker>()
      )
    )
      .then(({ docs }) => docs.map((d) => d.data()))
      .then((brokers) => {
        const shouldShowBrokerReasons: ((b: LinkedBroker) => boolean)[] = [
          (b) => b.account?.id !== user.account.id,
        ]
        return brokers.filter((b) => shouldShowBrokerReasons.every((f) => f(b)))
      })
      .then(setAllBrokers)
      .catch(handleConsoleError)
  }, [connections, db, user.account, user.account.id])

  const handleSelectContact = (selected: LinkedBroker) =>
    handleAddToNetwork({
      connectionAccount: selected.account
        ? {
            tag: "on platform",
            account: selected.account,
            status: "inviteReceived",
            primaryConnection: selected.user,
          }
        : {
            tag: "linked broker",
            broker: selected,
            status: "inviteReceived",
          },
      status: "sentInvite",
    })

  const searchResult = useMemo(
    () => getSearchResult(searchValue, allBrokers),
    [allBrokers, searchValue]
  )

  const connectionNodes = useMemo(
    () => getConnectionNodes(user.account, connections),
    [connections, user.account]
  )

  const isBrokerSelected = useCallback(
    (b: LinkedBroker) => {
      if (b.account) {
        return connectionNodes.find((n) => n.tag === "on platform" && n.account.id === b.account.id)
      } else {
        return connectionNodes.find((n) => n.tag === "linked broker" && b.id === n.broker.id)
      }
    },
    [connectionNodes]
  )

  return (
    <>
      <Input
        placeholder="Search for Broker or Broker Dealer"
        onChange={(e) => setSearchValue(e.target.value)}
      />
      {searchValue.length > 2 ? (
        searchResult.length ? (
          searchResult.map((broker) => {
            const previousConnection = isBrokerSelected(broker)
            const buttonTitle = previousConnection
              ? connectedAccountNetworkNodeStatuses.includes(previousConnection.status)
                ? "Connection is Active"
                : "Connection is Pending"
              : "Send Invitations"
            return (
              <TrustedBrokerNetworkCard key={broker.id}>
                <div className="flex justify-between items-start">
                  <div className="flex flex-col items-start gap-2">
                    <Typography text={broker.firmName} size={Size.Large} weight={Weight.Semibold} />
                    <Typography text={broker.fullName} />
                  </div>
                  <Button
                    onClick={() => handleSelectContact(broker)}
                    isDisabled={!!previousConnection}
                    label={buttonTitle}
                    variant="secondary"
                  />
                </div>
              </TrustedBrokerNetworkCard>
            )
          })
        ) : (
          <EmptySearch handleAddToNetwork={handleAddToNetwork} searchValue={searchValue} />
        )
      ) : (
        <NotSearchingYet />
      )}
    </>
  )
}
