import { JSONContent } from "@tiptap/react"
import { NoteTag } from "./SuggestionConfig"

export const getAllNoteMentions = (content: JSONContent): NoteTag[] => {
  if (!content) return []

  if (content.type === "mention" && content.attrs) {
    // eslint-disable-next-line rulesdir/no-assert
    return [content.attrs.label as unknown as NoteTag]
  }

  if (Array.isArray(content.content)) {
    return content.content.reduce((mentions: NoteTag[], item: JSONContent) => {
      const itemMentions = getAllNoteMentions(item)
      return [...mentions, ...itemMentions]
    }, [])
  }

  return []
}
