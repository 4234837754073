import { Tooltip } from "@stories/components/Antd"
import {
  SourceAttribution,
  sourceAttributionRootSource,
  sourceFirm,
} from "common/model/data-product/DataPoint/SourceAttribution"

export const SourceAttributionTooltip = ({
  sourceAttribution,
  children,
}: {
  sourceAttribution: SourceAttribution
  children?: React.ReactNode
}) => (
  <Tooltip
    title={`This is a private order${sourceAttributionRootSource(sourceAttribution)
      .bind(sourceFirm)
      .map((s) => ` from ${s}`)
      .withDefault("")}.`}
  >
    {children}
  </Tooltip>
)
