import {
  FieldPath,
  FirebaseFirestore,
  OrderByDirection,
  Query,
  UpdateData,
  WhereFilterOp,
} from "@firebase/firestore-types"
import { Filterable } from "../firestore/Filterable"
import { Prism, overPrism } from "./fp/optics/Prism"

export class DBQuery<S extends UpdateData, A, B> implements Filterable<S, DBQuery<S, A, B>> {
  query: Query<S>

  db: FirebaseFirestore

  prism: Prism<S, S, A, B>

  limit?: number

  constructor(
    db: FirebaseFirestore,
    collection: Query<S>,
    prism: Prism<S, S, A, B>,
    limit?: number
  ) {
    this.db = db
    this.query = collection
    this.prism = prism
    this.limit = limit
  }

  // copy of the firebase where API: needs improvement
  where = (fieldPath: string | FieldPath, opStr: WhereFilterOp, value: unknown) =>
    new DBQuery<S, A, B>(this.db, this.query.where(fieldPath, opStr, value), this.prism)

  orderBy(fieldPath: string | FieldPath, directionStr?: OrderByDirection | undefined) {
    return new DBQuery<S, A, B>(this.db, this.query.orderBy(fieldPath, directionStr), this.prism)
  }

  get() {
    if (this.limit) {
      return this.query
        .limit(this.limit)
        .get()
        .then((docs) =>
          docs.docs.flatMap((doc) => (doc.data() ? [this.prism.preview(doc.data())] : []))
        )
    }
    return this.query
      .get()
      .then((docs) =>
        docs.docs.flatMap((doc) => (doc.data() ? [this.prism.preview(doc.data())] : []))
      )
  }

  update = (f: (a: A) => B) =>
    this.query.get().then((docs) =>
      this.db
        .runTransaction((t) =>
          Promise.resolve(
            docs.docs.reduce(
              (transaction, doc) =>
                transaction.update(doc.ref, overPrism<S, S, A, B>(this.prism, doc.data(), f)),
              t
            )
          )
        )
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .then(() => {})
    )
}
