/* eslint-disable react/jsx-props-no-spreading */

import { Menu } from "@headlessui/react"
import React from "react"
import { Button, ButtonProps } from "../Button/Button"
import { DropdownMenuItem } from "./DropdownMenu"
import { Tooltip } from "../Antd"
import { flip, Alignment, Side, useFloating, FloatingPortal } from "@floating-ui/react"

export type DropdownMenuButtonProps = {
  menuItems: DropdownMenuItem[]
  wrapperClassName?: string
  position?: Side
  align?: Alignment
} & ButtonProps

const DropdownMenuButton: React.FC<DropdownMenuButtonProps> = ({
  menuItems,
  wrapperClassName,
  position = "bottom" as const,
  align = "end",
  ...buttonProps
}) => {
  const { refs, floatingStyles } = useFloating({
    placement: `${position}-${align}`,
    middleware: [flip()],
  })

  return (
    <Menu as="div" className={wrapperClassName}>
      <Menu.Button as={Button} {...buttonProps} ref={refs.setReference} />
      <FloatingPortal>
        <Menu.Items
          className="z-1000 w-64 rounded-md shadow-lg py-1 bg-neutral-white border focus:outline-none"
          ref={refs.setFloating}
          style={floatingStyles}
        >
          {menuItems.map((item: DropdownMenuItem) => {
            const { label, tooltip, variant, onClick, icon, isDisabled, heapName } = item
            return (
              <Menu.Item key={label}>
                <div
                  className={`${
                    isDisabled
                      ? "cursor-not-allowed text-neutral-300"
                      : `text-neutral-1000 hover:text-${variant}-500`
                  }`}
                >
                  <Tooltip
                    title={tooltip ? <div className="max-w-sm">{tooltip}</div> : null}
                    placement={align === "start" ? "right" : "left"}
                  >
                    <button
                      type="button"
                      data-heap-name={heapName}
                      onClick={onClick}
                      className="flex items-center gap-2 w-full text-left px-4 py-2 text-sm"
                      disabled={item.isDisabled}
                    >
                      {icon !== undefined ? icon : null}
                      {label}
                    </button>
                  </Tooltip>
                </div>
              </Menu.Item>
            )
          })}
        </Menu.Items>
      </FloatingPortal>
    </Menu>
  )
}

export default DropdownMenuButton
