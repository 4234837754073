import { debounce } from "lodash"
import { useEffect, useState } from "react"
import { MultiSelect } from "@components/inputs/Multiselect"
import Spinner from "@components/icons/Spinner"
import { deprecatedIsLoaded, isLoading } from "common/utils/Loading"
import { useAppSelector } from "../../../../state/reduxSelectors"
import { FilterFnRecord, Variant } from "common/model/newsfeed/NewsFeed"
import { useAppDispatch } from "../../../../state/reduxHooks"
import { useLoggedInUser } from "../../../../providers/loggedInUser/useLoggedInUser"
import { getSectorList } from "../../../../state/business_logic/postgresData"
import { handleConsoleError } from "../../../../utils/Tracking"

const delimiter = ";"
export const sectorIsIn = (sectors: string[]): FilterFnRecord => ({
  news: {
    function: (n) => sectors.includes(n.company?.primarySector || ""),
    asStrings: { field: "sectors", value: sectors.join(delimiter) },
  },
  funding_rounds: {
    function: (f) => sectors.includes(f.sector || ""),
    asStrings: { field: "sectors", value: sectors.join(delimiter) },
  },
})
export const parseSections = (sectors: string | null) => (sectors ? sectors.split(delimiter) : [])
const sectorStringToOption = (s: string) => ({ label: s, value: s, suggested: false })

const fn =
  <V extends Variant>(variant: V) =>
  (
    debouncedValue: string[] | undefined,
    onEnable: (fn: FilterFnRecord[typeof variant] | undefined) => void
  ) => {
    if (debouncedValue && debouncedValue.length > 0) {
      onEnable(sectorIsIn(debouncedValue)[variant])
    } else onEnable(undefined)
  }

const debounceOnSectorSelected = <V extends Variant>(variant: V) =>
  debounce(fn(variant), 200, { leading: false, trailing: true })

export function SectorSelector<V extends Variant>({
  onEnable,
  variant,
  valueString,
}: {
  onEnable: (fn: FilterFnRecord[V] | undefined) => void
  variant: V
  valueString: string | null
}) {
  const dispatch = useAppDispatch()
  const user = useLoggedInUser()
  useEffect(() => {
    dispatch(getSectorList(user)).catch(handleConsoleError)
  }, [user, dispatch])
  const value = parseSections(valueString).map(sectorStringToOption)
  const sectors = useAppSelector((state) => state.postgresData.sectors)

  if (isLoading(sectors)) return <Spinner size="xs" />
  if (!deprecatedIsLoaded(sectors)) return <></>
  return (
    <div className="flex flex-row text-xs space-x-1" id={`${variant}-filter-sector`}>
      <MultiSelect
        options={sectors.map(sectorStringToOption)}
        selectedOptions={value}
        placeholder="Select sectors"
        setSelectedOptions={(opts) => {
          debounceOnSectorSelected(variant)(
            opts.map((o) => o.value),
            onEnable
          )
        }}
        mode="multiple"
        testId={`sector-filter-for-${variant}`}
        className="w-40 pr-1"
        wrapperClassName="flex flex-row"
        optionsPreSorted
        displaySelectionBelow
      />
    </div>
  )
}
