import { Button } from "@stories/components/Button/Button"
import { DealCRMNote } from "common/model/DealCRM/DealCRMNote"
import { isLoaded } from "common/utils/Loading"
import { useEffect, useState } from "react"
import { useFirebaseWriter } from "src/firebase/Context"
import { useDocuments } from "src/firebase/Firestore"
import { getNoteReplies } from "src/firebase/crmNotes"
import { NoteDisplay, NoteDisplayProps } from "./NoteDisplay"
import { AddReplyNoteInput } from "./AddNoteInput"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { sortByDate } from "common/utils/dateUtils"
import { ChatIcon } from "@stories/icons/ChatIcon"
import Typography, { Size, Weight } from "@stories/components/Typography/Typography"

const REPLY_LIST_LIMIT = 5

const canShowReplies = (note: DealCRMNote) => note.parentNote === null

export const ReplyDisplay = ({
  note,
  displayPage,
  afterReplyAdded,
}: {
  note: DealCRMNote
  displayPage: NoteDisplayProps["displayPage"]
  afterReplyAdded?: () => void
}) => {
  const [replyCountLimit, setReplyCountLimit] = useState<number>(REPLY_LIST_LIMIT)
  const db = useFirebaseWriter()
  const replies = useDocuments(getNoteReplies({ db, note, limit: replyCountLimit }), [
    note,
    replyCountLimit,
  ])
  const user = useLoggedInUser()
  const [repliesOpen, setRepliesOpen] = useState<boolean>(false)

  const onLoadMoreReplies = () => {
    setReplyCountLimit(Infinity)
  }

  useEffect(() => {
    afterReplyAdded?.()
  }, [replies, afterReplyAdded])

  if (!canShowReplies(note)) return null

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div>
      {isLoaded(replies) && replies.length ? (
        <>
          <div className="ml-4">
            <div className="ml-1">
              <Typography text="Replies" weight={Weight.Semibold} size={Size.XXSmall} />
            </div>
            <div className="flex flex-col">
              {replies.length === REPLY_LIST_LIMIT && replyCountLimit === REPLY_LIST_LIMIT ? (
                <Button
                  variant="hollow"
                  label="Load Older Notes"
                  onClick={onLoadMoreReplies}
                  isFullWidth
                  size="xs"
                />
              ) : null}
              {replies.sort(sortByDate("createdAt", "asc")).map((reply) => (
                <NoteDisplay key={reply.id} note={reply} displayPage={displayPage} />
              ))}
            </div>
          </div>
          <div className="ml-4 px-1">
            <AddReplyNoteInput note={note} user={user} afterReplyAdded={afterReplyAdded} />
          </div>
        </>
      ) : repliesOpen ? (
        <div className="ml-4 px-1">
          <AddReplyNoteInput note={note} user={user} autoFocus afterReplyAdded={afterReplyAdded} />
        </div>
      ) : (
        <Button
          leftIcon={<ChatIcon />}
          variant="secondary"
          size="small"
          label="Reply"
          onClick={() => setRepliesOpen(true)}
        />
      )}
    </div>
  )
}
