import { AccountId, AccountIdFields } from "common/model/Account"
import { firestoreConverter } from "./FirestoreConverter"

export type DocumentType = "auction_participation_agreement" | "holding_non_disclosure_agreement"
type SignatureRoleType = "client" | "caplight-ceo"
type DocumentStatusType =
  | "unsigned"
  | "client-signed-on-platform"
  | "redline_review"
  | "fully-signed-off-platform"
  | "fully-signed-on-platform"
  | "complete" // for non-contract documents

/** @deprecated */
export interface Document {
  id: string
  accountId: AccountId
  account: AccountIdFields
  auctionId?: string
  type: DocumentType
  isContract: boolean
  signatures: ContractSignature[]
  fileHistory: unknown[] // pointers to file store objects,  // TODO remove any
  template: {
    id: string
    pdfFileName: string
    docxDownloadUrl: string
    pdfDownloadUrl: string
  }
  filledFields?: unknown // TODO remove any
  storageFolder: string // google storage path of the signed file, not including file name
  clientSignedDocumentPath?: string
  fullySignedDocumentPath?: string
  status: DocumentStatusType
  viewedAt?: Date
  downloadedAt?: Date
}

export interface ContractSignature {
  userId?: string // who signed it
  role: SignatureRoleType
  title?: string
  name?: string
  firmOrEntityName?: string
  signedAt?: Date
  signerIPAddress?: string
}

export const documentConverter = firestoreConverter<Document>()
