import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { DealCRMInterest, DealCRMInterestWithOrigin } from "common/model/DealCRM/DealCRMInterest"
import { CONTACT_ID_SEARCH_KEY } from "common/model/DealCRM/utils/dealCRMRouteUtils"
import { Loading, isLoaded, mapLoading, matchLoading } from "common/utils/Loading"
import { useCallback } from "react"
import { Location, To, useLocation, useNavigate } from "react-router-dom"
import { useCRMBuySellInterest } from "../../Providers/BuySellInterestProvider"

export const getContactDetailsRoute = (location: Location, contactId: string): To => {
  const search = new URLSearchParams(location.search)
  search.append(CONTACT_ID_SEARCH_KEY, contactId)
  return { search: search.toString() }
}

export const useContactDetailsDrawer = () => {
  const navigate = useNavigate()
  const location = useLocation()
  return useCallback(
    (contactId: string) => navigate(getContactDetailsRoute(location, contactId)),
    [location, navigate]
  )
}

export const getContactBuySellInterest = (
  contactId: string,
  allInterests: Loading<DealCRMInterest[]>
) =>
  mapLoading((interests: DealCRMInterest[]) =>
    interests.filter((interest) => interest.contact?.id === contactId)
  )(allInterests)

export const getAggregatedContactBuySellInterest: (params: {
  contact?: DealCRMContact | null
  allInterests: Loading<DealCRMInterest[]>
}) => Loading<DealCRMInterestWithOrigin[]> = ({ contact, allInterests }) => {
  if (!contact) return "loading"

  const contactInterests = getContactBuySellInterest(contact.id, allInterests)

  const mapInterestWithSource =
    (originId: string) =>
    (interest: DealCRMInterest): DealCRMInterestWithOrigin => ({ ...interest, originId })

  const individualFirmInterests =
    contact.tag === "individual" && contact.firm
      ? matchLoading(
          getContactBuySellInterest(contact.firm.id, allInterests),
          // This is fine because of contact.firm check above
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          (result) => result.map(mapInterestWithSource(contact.firm!.id)),
          [],
          []
        )
      : []

  const firmInterestsFromIndividuals =
    contact.tag === "firm"
      ? contact.contacts.flatMap(
          (firmContact) =>
            matchLoading(
              getContactBuySellInterest(firmContact.id, allInterests),
              (result) => result.map(mapInterestWithSource(firmContact.id)),
              [],
              []
            ),
          []
        )
      : []

  if (
    !isLoaded(contactInterests) ||
    !isLoaded(individualFirmInterests) ||
    !isLoaded(firmInterestsFromIndividuals)
  )
    return "loading"

  return [
    ...contactInterests.map(mapInterestWithSource(contact.id)),
    ...individualFirmInterests,
    ...firmInterestsFromIndividuals,
  ]
}

export const useContactBuySellInterest = (params: {
  contact: DealCRMContact | null
}): Loading<DealCRMInterestWithOrigin[]> => {
  const allInterests = useCRMBuySellInterest()

  return getAggregatedContactBuySellInterest({ ...params, allInterests })
}
