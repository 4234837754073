import { DealCRMInterestStructure } from "../DealCRMInterest"

export const displayDealCRMInterestStructure = (structure: DealCRMInterestStructure): string =>
  ((
    {
      direct: "Direct",
      spv: "SPV",
      forward: "Forward",
    } satisfies Record<DealCRMInterestStructure, string>
  )[structure])

export const displayDealCRMInterestStructures = (structures: DealCRMInterestStructure[]): string =>
  structures.map(displayDealCRMInterestStructure).join(", ")
