import * as Sentry from "@sentry/react"
import { collections } from "common/firestore/Collections"
import { isOpportunityInboxAccount, viewAccountIdFields } from "common/model/Account"
import { isDataCustomer, isMarketsCustomer } from "common/model/Auth/Permissions"
import { DealCRMContactIdFields } from "common/model/DealCRM/DealCRMContact"
import { ConnectThread } from "common/model/Messaging/ConnectThread"
import { ArchiveReason } from "common/model/Opportunity/fns/archiveOpportunity"
import { Order } from "common/model/Order/Order"
import { Order as Wrapped } from "common/model/Order/Models/Wrapped"
import { ConstructedOpportunityInboxFilterRule } from "common/model/Order/OrderFilter/OpportunityInboxFilterRule"
import { User, UserIdFields, viewUserIdFields } from "common/model/User"
import { UserEvent, UserEventType } from "common/model/UserEvent"
import { addDoc, collection, getDocs, limit, orderBy, query, where } from "firebase/firestore"
import moment from "moment"
import { OrderOpportunityInboxItem } from "src/pages/Orders/OrderOpportunityInbox/types"
import { config } from "../config"
import {
  sendPlatformEventMessage,
  sendAccountUsageEventMessage,
  sendTradingPlatformEventMessage,
} from "../firebase/API"
import Firebase9, { oneOrNone } from "../firebase/Firebase9"
import { CurrentUser } from "../model/CurrentUser"
import { firestoreConverter } from "../model/FirestoreConverter"
import { Auction } from "../model/auctions/Auction"
import { IComparableSelection } from "../state/model/postgresData"
import { Datadog } from "./Datadog"
import { EventCounterService } from "./EventTracking/EventCounterService"
import { adminOrderRoute } from "common/model/Order/OrderConstants"
import { getPriceUpdatesDescending } from "src/pages/Orders/OrderPage/OrderHistory/fns/useOrderUpdateLog"
import { Just, Nothing } from "common/containers/Maybe"
import { head } from "common/utils/data/Array/ArrayUtils"
import { displayPriceUpdate } from "src/pages/Orders/OrderPage/OrderHistory/components/OrderHistoryPriceChange"
import { IntervalDisplay } from "@components/displays/Interval"
import { formatValuation } from "common/utils/math"
import { assertUnreachable } from "common/utils/fp/Function"

export const identifyUser = (currentUser: Omit<CurrentUser, "isAdmin">) => {
  if (config.sentryKey) {
    Sentry.configureScope((scope) =>
      scope.setUser({ email: currentUser.user.email, id: currentUser.user.id })
    )
    Sentry.setTag("userEmail", currentUser.user.email)
  }
  heapIdentify(currentUser.user)
  updateUserProperties(currentUser)
  Datadog.identifyUser(currentUser.user)
}

const heapIdentify = (user: User) => window.heap && window.heap.identify(user.email?.toLowerCase())

const heapAddUserProperties = (metadata: () => object) => {
  if (window.heap) {
    try {
      window.heap.addUserProperties(metadata())
    } catch (e) {
      handleConsoleError(e)
    }
  }
}
const trackHeapEvent = (event: string, metadata: () => object) => {
  if (window.heap) {
    try {
      window.heap.track(event, metadata())
    } catch (e) {
      handleConsoleError(e)
    }
  }
}

export const heapTrackWithEmail = (params: { event: string; email: string; metadata?: object }) => {
  if (window.heap) {
    try {
      window.heap.identify(params.email)
      setTimeout(() => {
        if (window.heap) {
          window.heap.track(params.event, params.metadata || {})
        }
      }, 250)
    } catch (e) {
      handleConsoleError(e)
    }
  }
}

const updateUserProperties = (currentUser: Omit<CurrentUser, "isAdmin">) => {
  const { user } = currentUser
  heapAddUserProperties(() => ({
    accountName: user.account.name,
    accountId: user.account.id,
    isDataCustomer: isDataCustomer(user.account),
    isMarketsCustomer: isMarketsCustomer(user.account),
    isOpportunityInboxCustomer: isOpportunityInboxAccount(user.account),
    dataOnboardingStatus: user.account.onboardingStatus.data?.status,
    tradingOnboardingStatus: user.account.onboardingStatus.markets?.status,
    creationSource: user.creationSource,
    createdAt: user.createdAt,
  }))
}

export const trackCompToggle = (
  action: "add_to_chart" | "remove_from_chart" | "view_details",
  selection:
    | IComparableSelection
    | {
        companyId: string
        status: string
        companyName: string
        ticker: string
        description: string
      }
) => {
  const trackingTitle = `Comps Analysis - ${action}`

  trackHeapEvent(trackingTitle, () => selection)
}

export const trackAuctionTabView = (auction: Auction, tab: string) => {
  trackHeapEvent(`Auction ${tab} Tab View`, () => ({
    auctionId: auction.id,
    auctionName: auction.name,
    auctionDate: auction.liveDateString,
  }))
}

export const trackAuthTokenRetrieved = (endpoint?: string) => {
  trackHeapEvent("auth-token-retrieved", () => ({
    apiEndpoint: endpoint,
  }))
}

export const trackAuthTokenRefresh = (endpoint?: string) => {
  trackHeapEvent("auth-token-refresh", () => ({
    apiEndpoint: endpoint,
  }))
}

export const trackEvent = (
  event: string,
  metadata?: Record<string, number | string | boolean | undefined | null>
) => {
  trackHeapEvent(event, () => metadata || {})
}

export const trackChatMessageSent = ({
  firebase9,
  user,
  thread,
}: {
  firebase9: Firebase9
  thread: ConnectThread
  user: User
}) => {
  trackHeapEvent("chat-message-sent", () => ({
    threadType: thread.threadType,
    threadStatus: thread._status,
  }))
  trackUserEventInFirestore(firebase9, user, "chat-message-sent")
}

export const trackReportDownloaded = (metadata: {
  companyId: string
  companyName: string
  reportSource: string
  pagePath: string
}) => {
  trackHeapEvent("pdf-report-downloaded", () => metadata)
}

export const trackFilingDownloaded = async (
  filingType: "coi" | "regD",
  metadata: {
    companyId: string
    companyName: string
    reportType: "pdf" | "html"
  },
  currentUser: CurrentUser
) => {
  trackHeapEvent(`${filingType}-downloaded`, () => metadata)
  await sendPlatformEventMessage(
    `${filingType} downloaded for ${metadata.companyName} format ${metadata.reportType} by ${currentUser.user.email} (${currentUser.user.account.name})`,
    currentUser
  )
}

export const trackEpenDownloaded = async (
  metadata: {
    companyId: string
    companyName: string
  },
  currentUser: CurrentUser
) => {
  trackHeapEvent("epen-downloaded", () => metadata)
  await sendPlatformEventMessage(
    `EPEN downloaded for ${metadata.companyName} by ${currentUser.user.email} (${currentUser.user.account.name})`,
    currentUser
  )
}

export const trackCompanyReportDownloaded = async (
  metadata: {
    companyId: string
    companyName: string
    timeInSeconds: number
  },
  currentUser: CurrentUser
) => {
  trackHeapEvent("company-excel-report-downloaded", () => metadata)
  await sendAccountUsageEventMessage(
    `Company report downloaded for ${metadata.companyName} (generation time: ${metadata.timeInSeconds}s) by ${currentUser.user.email} (${currentUser.user.account.name})`,
    currentUser
  )
}

export const trackMarkOpportunityAsArchived = (metadata: ArchiveReason[]) => {
  const payload = {
    archiveReasons: metadata,
  }

  trackHeapEvent("mark-opportunity-as-archived", () => payload)
}

export const trackOpportunityInboxFilterRuleCreated = (
  filter: ConstructedOpportunityInboxFilterRule
) => {
  const payload = {
    filter,
  }

  trackHeapEvent("opportunity-inbox-filter-rule-created", () => payload)
}

export const trackOpportunityPinnedOrUnpinned = (
  item: OrderOpportunityInboxItem,
  pinned: boolean
) => {
  const payload = {
    item,
  }

  trackHeapEvent(`opportunity-inbox-opportunity-${pinned ? "pinned" : "unpinned"}`, () => payload)
}

export const trackOrderRenewed = (order: Order, currentUser: CurrentUser) => {
  trackHeapEvent("order-renewed", () => ({
    orderDaysOld: order.orderOriginationDate
      ? moment().diff(order.orderOriginationDate, "days")
      : null,
    orderIsExpired: order._derived.liveUntil ? moment().isAfter(order._derived.liveUntil) : null,
    orderDaysSinceExpired: order._derived.liveUntil
      ? moment().diff(order._derived.liveUntil, "days")
      : null,
  }))

  const maybeWrappedOrder = Wrapped.wrapRaw({ order, company: Nothing })
  const lastPriceUpdate = maybeWrappedOrder
    .bind((wrappedOrder) => head(getPriceUpdatesDescending(wrappedOrder)))
    .bind(([price, prev]) => {
      const newPrice = price.rawPrice().toNullable()
      const prevPrice = prev?.rawPrice().toNullable()
      return newPrice?.toUnion() !== prevPrice?.toUnion() && newPrice && prevPrice
        ? Just(`Price Changed: ${displayPriceUpdate(prevPrice)} to ${displayPriceUpdate(newPrice)}`)
        : newPrice
        ? Just(`Price Unchanged: ${displayPriceUpdate(newPrice)}`)
        : Nothing
    })
    .toArray()

  const sizeMessage = maybeWrappedOrder
    .bind((wrappedOrder) => wrappedOrder.amountUSD())
    .map(IntervalDisplay(formatValuation, true))
    .map((sizeText) => `Size: ${sizeText}`)
    .toArray()

  const orderKind =
    order.orderCollection === "platform" || order.orderCollection === undefined
      ? "Market"
      : order.orderCollection === "tentativeInterest"
      ? "Tentative"
      : order.orderCollection === "darkpool"
      ? "Darkpool"
      : order.orderCollection === "private"
      ? "Opportunity"
      : assertUnreachable(order.orderCollection)

  const message = [
    `${order.company.name} ${orderKind} ${order.direction === "buy" ? "Bid" : "Offer"} renewed by ${
      currentUser.user.email
    }`,
    `View: ${window.location.hostname}${adminOrderRoute(order)}`,
    ...sizeMessage,
    ...lastPriceUpdate,
  ].join("\n")

  sendTradingPlatformEventMessage(message, currentUser).catch(handleConsoleError)
}

/**
 * Track a user event (product interaction) event via the user_event_log collection
 */
export const trackUserEventInFirestore = (
  firebase9: Firebase9,
  user: User,
  event: UserEventType
) => {
  if (config.env === "dev") {
    console.log(`Captured user event ${event} (not stored in Firestore in dev mode)`)
    return
  }
  const eventLogDoc: Omit<UserEvent, "id"> = {
    user: viewUserIdFields(user),
    account: viewAccountIdFields(user.account),
    eventDate: new Date(),
    eventType: event,
  }
  incrementEventCounts(firebase9, user, event)
  const eventLogCollection = collection(firebase9.db, collections.userEventLog)
  addDoc(eventLogCollection, eventLogDoc).catch((err) => {
    if (!String(err).includes("Document already exists")) {
      handleConsoleError(err)
    }
  })
}

export const incrementEventCounts = (firebase9: Firebase9, user: User, event: UserEventType) => {
  const userEventCounterService = EventCounterService.getInstance(firebase9, user)
  userEventCounterService.incrementEventCount(event).catch(handleConsoleError)
}

/**
 * Track a user session event via the user_event_log collection
 * Session events are logged at most every 30 minutes
 * Note: this logs events even during idle sessions, we can look into https://idletimer.dev/
 * in the future if we want more accurate session tracking.
 */
export const trackUserSession = async (firebase9: Firebase9, user: User) => {
  const SESSION_GAP_INTERVAL_IN_MINUTES = 30

  const q = query(
    collection(firebase9.db, collections.userEventLog),
    where("user.id", "==", user.id),
    where("eventType", "==", "active-session"),
    orderBy("eventDate", "desc"),
    limit(1)
  ).withConverter(firestoreConverter<UserEvent>())

  const latestSessionEvent = await getDocs(q).then(oneOrNone)

  if (
    !latestSessionEvent ||
    moment().diff(latestSessionEvent.eventDate, "minutes") > SESSION_GAP_INTERVAL_IN_MINUTES
  ) {
    return trackUserEventInFirestore(firebase9, user, "active-session")
  } else {
    return Promise.resolve()
  }
}

export const handleConsoleError = (...err: unknown[]) => {
  const devHrefPatterns = ["localhost", "caplight-staging", "caplight.dev"]
  // eslint-disable-next-line no-console
  if (devHrefPatterns.some((pattern) => window.location.href.includes(pattern))) console.error(err)
  Sentry.captureException(err)
}

export const trackEventInFirestoreAndHeap = (
  firebase9: Firebase9,
  user: User,
  event: UserEventType,
  metadata?: object
): void => {
  trackUserEventInFirestore(firebase9, user, event)
  trackHeapEvent(event, () => metadata ?? {})
}

export const trackEmailSyncUpdated = (
  updatedBy: UserIdFields,
  updatedFor: DealCRMContactIdFields,
  newValue: boolean
) => {
  trackHeapEvent(`email-sync-${newValue ? "enabled" : "disabled"}`, () => ({
    updatedFor: updatedFor.id,
    newValue,
  }))
}
