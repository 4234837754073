import { DealCRMContactIdFields } from "common/model/DealCRM/DealCRMContact"
import React from "react"
import { CRMContactName } from "../../Contacts/ContactDetailsDrawer/CRMContactName"

interface ContactPillProps {
  contact: DealCRMContactIdFields
}

export const ContactPill: React.FC<ContactPillProps> = ({ contact }) => (
  <div className="hover:bg-gray-500 cursor-pointer px-1 py-0.5 bg-neutral-200 border border-neutral-400 flex items-center rounded-md gap-1">
    <CRMContactName size="xs" contact={contact} />
  </div>
)
