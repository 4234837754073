import { FloatingPortal } from "@floating-ui/react"
import { Button } from "@stories/components/Button/Button"
import { FeatureList } from "@stories/components/List/FeatureList"
import Typography, { Color, Font, Size, Weight } from "@stories/components/Typography/Typography"
import { AccessControlFeatureName } from "common/model/AccessControl/AccessControl"
import {
  AccessControlTier,
  UpgradePathTier,
  displayAccessControlTierName,
  displayUpgradeTierListForCurrentTier,
} from "common/model/AccessControl/AccessControlTier"
import {
  displayAccessControlTierPrice,
  displayAccessControlTierSeatCount,
  displayAccessControlUpgradeFeatures,
} from "common/model/AccessControl/displayAccessControlUpgradeFeatures"
import { getAccessControlFeatureDisplayAction } from "common/model/AccessControl/getAccessControlFeatureDisplayName"
import { isLoaded } from "common/utils/Loading"
import { ComponentProps, useEffect } from "react"
import { sendPlatformEventMessage } from "src/firebase/API"
import { useAccessControl } from "../AccessControlProvider"
import { EverythingInTierPlus } from "./EverythingInTierPlus"
import { UpgradeTierOverlay } from "./UpgradeTierOverlay"
import { handleConsoleError } from "src/utils/Tracking"
import { XIcon } from "@stories/icons/XIcon"
import { StopClickPropagation } from "@stories/components/StopClickPropagation/StopClickPropagation"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import { AnimatePresence, motion } from "framer-motion"
import { assertUnreachable } from "common/utils/fp/Function"
import { classNames } from "src/utils/classNames"

const initialStyle: ComponentProps<typeof motion.div>["initial"] = { opacity: 0 }
const displayedStyle: ComponentProps<typeof motion.div>["animate"] = { opacity: 1 }

const PlanSeatInformation = ({
  seatCount,
}: {
  seatCount: ReturnType<typeof displayAccessControlTierSeatCount>
}) => {
  switch (seatCount) {
    case 1: {
      return <Typography text="For individuals" size={Size.Small} />
    }
    case 4: {
      return <Typography text={`For teams, up to ${seatCount} users`} size={Size.Small} />
    }
    case "unlimited": {
      return <Typography text="For teams of any size" size={Size.Small} />
    }
    default: {
      return assertUnreachable(seatCount)
    }
  }
}

const PriceInformation = ({
  price,
}: {
  price: ReturnType<typeof displayAccessControlTierPrice>
}) => {
  const text =
    typeof price === "number"
      ? `$${price} monthly`
      : price === "custom"
      ? "Pricing Varies"
      : assertUnreachable(price)

  return (
    <div className="flex justify-center items-center mt-2">
      <Typography text={text} weight={Weight.Bold} />
    </div>
  )
}

export const UpgradePlanModal = ({
  open,
  onClose,
  featureName,
}: {
  onClose: () => void
  open: boolean
  featureName: AccessControlFeatureName | null
}) => {
  const currentUser = useCurrentUser()
  const { currentAccessControlDoc } = useAccessControl()

  useEffect(() => {
    if (open && !featureName) {
      handleConsoleError(new Error("UpgradePlanModal was opened without a feature name."))
    }
  }, [open, featureName])

  if (!isLoaded(currentAccessControlDoc)) {
    return null
  }

  const displayLists = displayUpgradeTierListForCurrentTier(currentAccessControlDoc.tier)

  const openIntercomChat = (tier: UpgradePathTier) => {
    if (!featureName) {
      handleConsoleError(
        new Error("openIntercomChat in UpgradePlanModal called with no featureName")
      )
      return
    }

    if (!isLoaded(currentUser)) {
      handleConsoleError(
        new Error("openIntercomChat in UpgradePlanModal called without loaded currentUser")
      )
      return
    }

    sendPlatformEventMessage(
      `${currentUser.user.email} clicked "chat with us" button on ${displayAccessControlTierName(
        tier
      )} to ${getAccessControlFeatureDisplayAction(featureName)}`,
      currentUser
    ).catch(handleConsoleError)

    if (window?.Intercom) {
      window.Intercom(
        "showNewMessage",
        `I'm interested in upgrading to the ${displayAccessControlTierName(
          tier
        )} Plan to ${getAccessControlFeatureDisplayAction(featureName)}`
      )
    }
    onClose()
  }

  const showIncludesText = (displayedTier: AccessControlTier) => {
    if (currentAccessControlDoc.tier === "individual-trial" && displayedTier === "basic") {
      return true
    }
    if (currentAccessControlDoc.tier === "group-trial" && displayedTier === "pro") {
      return true
    }
    return false
  }

  return (
    <FloatingPortal>
      <StopClickPropagation>
        <AnimatePresence>
          {open ? (
            <motion.div
              className="fixed inset-0 z-1005 bg-primary-500 bg-opacity-60"
              initial={initialStyle}
              animate={displayedStyle}
              exit={initialStyle}
              role="none"
              onClick={() => onClose()}
            >
              <UpgradeTierOverlay size={displayLists.length > 2 ? "large" : "small"}>
                <StopClickPropagation>
                  <div className="flex flex-col space-y-2 p-4 rounded bg-white">
                    <div className="flex justify-between">
                      <Typography
                        text="Upgrade Plan"
                        size={Size.XXLarge}
                        weight={Weight.Semibold}
                        font={Font.Cambon}
                      />
                      <Button variant="secondary" leftIcon={<XIcon />} onClick={() => onClose()} />
                    </div>
                    <div
                      className={classNames(
                        "grid gap-2",
                        displayLists.length > 2 ? "grid-cols-3" : "grid-cols-2"
                      )}
                    >
                      {displayLists.map((tier) => (
                        <div
                          key={tier}
                          className="flex flex-col bg-white rounded-sm border shadow p-4 space-y-2"
                        >
                          <Typography
                            text={displayAccessControlTierName(tier)}
                            size={Size.XLarge}
                            weight={Weight.Semibold}
                            font={Font.Cambon}
                          />
                          <PlanSeatInformation
                            seatCount={displayAccessControlTierSeatCount(tier)}
                          />
                          {currentAccessControlDoc.tier === tier ? (
                            <Typography
                              text="Your current plan:"
                              color={Color.Primary}
                              weight={Weight.Semibold}
                              size={Size.Small}
                            />
                          ) : showIncludesText(tier) ? (
                            <Typography
                              text="Includes:"
                              color={Color.Primary}
                              weight={Weight.Semibold}
                              size={Size.Small}
                            />
                          ) : (
                            <EverythingInTierPlus />
                          )}
                          <FeatureList features={displayAccessControlUpgradeFeatures(tier)} />
                          <div className="flex-1 " />
                          <PriceInformation price={displayAccessControlTierPrice(tier)} />
                          <Button
                            label={
                              currentAccessControlDoc.tier === tier
                                ? "Current Plan"
                                : "Chat with Us"
                            }
                            onClick={() => openIntercomChat(tier)}
                            isDisabled={currentAccessControlDoc.tier === tier}
                            isFullWidth
                            size="large"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </StopClickPropagation>
              </UpgradeTierOverlay>
            </motion.div>
          ) : null}
        </AnimatePresence>
      </StopClickPropagation>
    </FloatingPortal>
  )
}
