import { Excluding } from "../../../utils/TypeUtils"
import { Maybe, nullableToMaybe } from "../../../containers/Maybe"
import { User, UserIdFields } from "../../User"
import { Opportunity } from "../Opportunity"

export const opportunityReadByUserInteraction = ({
  opportunity,
  userToCheck,
}: {
  opportunity: Opportunity
  userToCheck: { id: string }
}): Maybe<Opportunity["readBy"][number]> =>
  nullableToMaybe(opportunity.readBy.find(({ user }) => userToCheck.id === user.id))

export const isOpportunityReadByUser = ({
  opportunity,
  userToCheck,
}: {
  opportunity: Opportunity
  userToCheck: { id: string }
}) =>
  opportunityReadByUserInteraction({ opportunity, userToCheck })
    .map(({ active }) => active)
    .withDefault(false)

export const readOpportunity = ({
  opportunity,
  readByUser,
  readAtDate,
}: {
  opportunity: Opportunity
  readByUser: Excluding<UserIdFields, User>
  readAtDate: Date
}): Opportunity =>
  isOpportunityReadByUser({ opportunity, userToCheck: readByUser })
    ? opportunity
    : {
        ...opportunity,
        readBy: opportunity.readBy.concat({
          user: readByUser,
          asOf: readAtDate,
          tag: "read",
          active: true,
        }),
        interactionHistory: opportunityReadByUserInteraction({
          opportunity,
          userToCheck: readByUser,
        }).match(
          (prevInteraction) => opportunity.interactionHistory.concat(prevInteraction),
          () => opportunity.interactionHistory
        ),
      }

export const unReadOpportunityForUser = ({
  opportunity,
  unReadByUser,
  unReadAtDate,
}: {
  opportunity: Opportunity
  unReadByUser: Excluding<UserIdFields, User>
  unReadAtDate: Date
}): Opportunity =>
  opportunityReadByUserInteraction({ opportunity, userToCheck: unReadByUser }).match(
    (prevInteraction) => ({
      ...opportunity,
      readBy: opportunity.readBy.flatMap((interaction) =>
        interaction.user.id === unReadByUser.id
          ? [
              {
                ...interaction,
                asOf: unReadAtDate,
                active: false,
              },
            ]
          : [interaction]
      ),
      interactionHistory: opportunity.interactionHistory.concat(prevInteraction),
    }),
    () => opportunity
  )

export const unReadOpportunityForAllUsers = ({
  opportunity,
}: {
  opportunity: Opportunity
}): Opportunity => ({
  ...opportunity,
  readBy: [],
  interactionHistory: opportunity.interactionHistory.concat(opportunity.readBy),
})
