import { useMemo, useState } from "react"
import { Button } from "@stories/components/Button/Button"
import { DisplayFields, allDisplayFields } from "."

export const useExpandOrderPreviewButton = (props: {
  visibleFields?: DisplayFields[]
  hiddenFields?: DisplayFields[]
  alwaysHiddenFields?: DisplayFields[]
}): { button: React.ReactElement; hiddenFields: DisplayFields[] } => {
  const [expanded, setExpanded] = useState(false)

  const handleClick = () => {
    setExpanded(!expanded)
  }

  const button = (
    <Button
      label={expanded ? "Collapse" : "Expand"}
      onClick={handleClick}
      variant="hollow"
      size="small"
    />
  )

  const hiddenFieldsFromVisible = useMemo(
    () => allDisplayFields.filter((field) => !props.visibleFields?.includes(field)),
    [props.visibleFields]
  )

  const closedHiddenFields = props.hiddenFields ?? hiddenFieldsFromVisible
  const expandedHiddenFields = props.alwaysHiddenFields ?? []

  return {
    button,
    hiddenFields: expanded ? expandedHiddenFields : closedHiddenFields,
  }
}
