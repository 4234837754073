import React, { useState } from "react"
import { Reorder } from "framer-motion"
import { Modal, Select } from "@stories/components/Antd"
import ReOrderableItem from "./ReOrderableItem"
import { Input } from "@stories/components/Inputs/Input/Input"
import { Button } from "@stories/components/Button/Button"
import { trackEvent } from "src/utils/Tracking"

interface ReOrderableItemListProps {
  items: string[]
  onChangeItems: (newItems: string[]) => void
  addingStage?: boolean
  onAddStageCancel: () => void
  onStageReplaced: ({ deletedStage, newStage }: { deletedStage: string; newStage: string }) => void
  itemsIcon?: React.ReactNode
  disableReorder?: boolean
}

const ReOrderableItemList: React.FC<ReOrderableItemListProps> = ({
  items,
  onChangeItems,
  addingStage,
  onAddStageCancel,
  onStageReplaced,
  itemsIcon,
  disableReorder,
}) => {
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null)
  const ref = React.useRef<HTMLDivElement>(null)

  const handleRename = (index: number, newName: string) => {
    trackEvent("deal_stage_renamed")
    const updatedItems = [...items]
    updatedItems[index] = newName
    onChangeItems(updatedItems)
    onStageReplaced({ deletedStage: items[index], newStage: newName })
  }

  const handleDelete = (index: number) => {
    const updatedItems = items.filter((_, i) => i !== index)
    onChangeItems(updatedItems)
  }

  const handleConfirmDelete = (index: number) => {
    setDeleteIndex(index)
  }

  const handleDeleteCancel = () => {
    setDeleteIndex(null)
  }

  const handleDeleteConfirm = (replacement: string) => {
    if (deleteIndex !== null) {
      onStageReplaced({
        deletedStage: items[deleteIndex],
        newStage: replacement,
      })
      handleDelete(deleteIndex)
      setDeleteIndex(null)
    }
  }

  const handleReorder = (newOrder: string[]) => {
    trackEvent("deal_stages_reordered", { newOrder: newOrder.join(",") })
    onChangeItems(newOrder)
  }

  if (disableReorder) {
    return (
      <div>
        {items.map((item, index) => (
          <ReOrderableItem
            key={item}
            item={item}
            index={index}
            onRename={handleRename}
            onConfirmDelete={handleConfirmDelete}
            className="bg-gray-100"
            containerRef={ref}
            icon={itemsIcon}
            disableReorder
          />
        ))}
        {addingStage && (
          <NewStageInput
            onAdd={(name) => onChangeItems([...items, name])}
            onCancel={onAddStageCancel}
          />
        )}
      </div>
    )
  }

  return (
    <div ref={ref}>
      <Reorder.Group axis="y" values={items} onReorder={handleReorder} className="p-0 m-0">
        {items.map((item, index) => (
          <ReOrderableItem
            key={item}
            item={item}
            index={index}
            onRename={handleRename}
            onConfirmDelete={handleConfirmDelete}
            className="bg-gray-100"
            containerRef={ref}
            icon={itemsIcon}
          />
        ))}
      </Reorder.Group>
      {addingStage && (
        <NewStageInput
          onAdd={(name) => onChangeItems([...items, name])}
          onCancel={onAddStageCancel}
        />
      )}

      {deleteIndex !== null && (
        <Modal
          title="Confirm Delete"
          visible
          onCancel={handleDeleteCancel}
          onOk={() => handleDeleteConfirm(items[deleteIndex])}
        >
          <p>Select a replacement tag for existing data:</p>
          <Select style={{ width: "100%" }} onChange={handleDeleteConfirm}>
            {items
              .filter((_, i) => i !== deleteIndex)
              .map((item) => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
          </Select>
        </Modal>
      )}
    </div>
  )
}

const NewStageInput = ({
  onAdd,
  onCancel,
}: {
  onAdd: (name: string) => void
  onCancel: () => void
}) => {
  const [value, setValue] = useState("")

  const handleAdd = () => {
    onAdd(value)
    setValue("")
  }

  return (
    <div className="flex gap-1 items-center w-full mb-2">
      <Input
        type="text"
        size="small"
        placeholder="Name"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => e.key === "Enter" && handleAdd()}
      />
      <Button label="Cancel" variant="neutral" size="small" onClick={onCancel} />
      <Button
        label="Add"
        variant="primary"
        size="small"
        onClick={() => handleAdd()}
        isDisabled={!value}
      />
    </div>
  )
}

export default ReOrderableItemList
