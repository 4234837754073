import { Loading } from "common/utils/Loading"
import { useEffect, useState } from "react"
import { useErrorHandler } from "src/providers/errorHandler"
import * as Sentry from "@sentry/react"
import { DocumentReference } from "src/firebase/Firebase/utils"
import _ from "lodash"
import { useFirebaseWriter } from "../../../firebase/Context"
import Firebase from "../../../firebase/Firebase"


export function useDocumentSnapshot<R>(
  f: (db: Omit<Firebase, "adminDb">) => DocumentReference<R> | null,
  dependencies: unknown[]
): Loading<R> {
  const firebase = useFirebaseWriter()
  const [result, setResult] = useState<Loading<R>>("loading")
  const { handleError } = useErrorHandler()
  useEffect(() => {
    const query = f(firebase)
    if (!query) {
      setResult(null)
    }
    // eslint-disable-next-line rulesdir/no-let
    let isSubscribed: boolean = true
    const unsubscribe = query?.onSnapshot(
      (x) => (isSubscribed ? setResult(x.data() ?? null) : null),
      (e) => {
        if (isSubscribed) {
          Sentry.captureException(e)
          if (_.isError(e)) {
            handleError(e)
          }
          setResult(null)
        }
      }
    )
    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
      // eslint-disable-next-line better-mutation/no-mutation
      isSubscribed = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)
  return result
}
