import Typography, { Size, Weight } from "@stories/components/Typography/Typography"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { DealCRMFirmContact } from "common/model/DealCRM/DealCRMFirmContact"
import { isBefore } from "date-fns"
import { useMemo } from "react"
import { ContactSelect } from "./ContactSelect"
import { CompanyFilterSearchProp } from "./shared"

export const RecentFirms = ({
  allContacts,
  onContactSelected,
  initialCompanyFilter,
}: {
  allContacts: DealCRMContact[]
  onContactSelected: (contact: DealCRMContact) => Promise<void>
  initialCompanyFilter?: CompanyFilterSearchProp
}) => {
  const mostRecentFirms = useMemo(
    () =>
      allContacts
        .filter((contact): contact is DealCRMFirmContact => contact.tag === "firm")
        .sort((a, b) => (isBefore(b.createdAt, a.createdAt) ? -1 : 1))
        .slice(0, 3),
    [allContacts]
  )
  return (
    <div>
      <Typography text="Most Recent Firms" size={Size.Small} weight={Weight.Semibold} />
      <div className="flex flex-col gap-2 overflow-y-scroll">
        {mostRecentFirms.map((contact) => (
          <ContactSelect
            key={contact.id}
            contact={contact}
            onContactSelected={onContactSelected}
            initialCompanyFilter={initialCompanyFilter}
          />
        ))}
      </div>
    </div>
  )
}
