import { justIfNonempty } from "common/utils/data/Array/Nonempty"
import OrderTermSheetRow, { OrderTermSheetRowProps } from "./OrderTermSheetRow"
import { UNSPECIFIED_VALUE } from "./Sections/constants"
import { TitleWrapper } from "./TitleWrapper"
import { Wrapper } from "./Wrapper"

export interface OrderTermSheetSectionProps {
  title: React.ReactNode
  rows: OrderTermSheetRowProps[]
  hideUnspecified: boolean
}

const filterUnspecified =
  (hideUnspecified: boolean) =>
  ({ value }: { value: React.ReactNode }): boolean =>
    !hideUnspecified || value !== UNSPECIFIED_VALUE

const OrderTermSheetSection = ({ title, rows, hideUnspecified }: OrderTermSheetSectionProps) =>
  justIfNonempty(rows.filter(filterUnspecified(hideUnspecified))).match(
    (nonemptyRows) => (
      <Wrapper>
        <TitleWrapper>{title}</TitleWrapper>
        {nonemptyRows.map(({ label, value, tooltip, accessLevel }) => (
          <OrderTermSheetRow
            key={label}
            label={label}
            value={value}
            tooltip={tooltip}
            accessLevel={accessLevel}
          />
        ))}
      </Wrapper>
    ),
    () => null
  )

export default OrderTermSheetSection
