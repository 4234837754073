import { wrapIcon } from "./IconWrapper"

export const NotificationIcon = wrapIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 8.78666V6.66666C11.999 5.72208 11.6638 4.80831 11.0537 4.08718C10.4436 3.36605 9.59803 2.88409 8.66666 2.72666V1.99999C8.66666 1.82318 8.59642 1.65361 8.47139 1.52859C8.34637 1.40357 8.1768 1.33333 7.99999 1.33333C7.82318 1.33333 7.65361 1.40357 7.52859 1.52859C7.40356 1.65361 7.33332 1.82318 7.33332 1.99999V2.72666C6.40195 2.88409 5.55634 3.36605 4.94625 4.08718C4.33615 4.80831 4.00095 5.72208 3.99999 6.66666V8.78666C3.61096 8.9242 3.27399 9.17871 3.03528 9.51529C2.79658 9.85186 2.66782 10.254 2.66666 10.6667V12C2.66666 12.1768 2.73689 12.3464 2.86192 12.4714C2.98694 12.5964 3.15651 12.6667 3.33332 12.6667H5.42666C5.58018 13.2316 5.91532 13.7303 6.38039 14.0858C6.84545 14.4414 7.41459 14.634 7.99999 14.634C8.58539 14.634 9.15453 14.4414 9.61959 14.0858C10.0847 13.7303 10.4198 13.2316 10.5733 12.6667H12.6667C12.8435 12.6667 13.013 12.5964 13.1381 12.4714C13.2631 12.3464 13.3333 12.1768 13.3333 12V10.6667C13.3322 10.254 13.2034 9.85186 12.9647 9.51529C12.726 9.17871 12.389 8.9242 12 8.78666ZM5.33332 6.66666C5.33332 5.95942 5.61427 5.28114 6.11437 4.78104C6.61447 4.28095 7.29275 3.99999 7.99999 3.99999C8.70723 3.99999 9.38551 4.28095 9.88561 4.78104C10.3857 5.28114 10.6667 5.95942 10.6667 6.66666V8.66666H5.33332V6.66666ZM7.99999 13.3333C7.7673 13.3319 7.53904 13.2697 7.33788 13.1527C7.13672 13.0357 6.96966 12.8682 6.85332 12.6667H9.14666C9.03032 12.8682 8.86326 13.0357 8.6621 13.1527C8.46094 13.2697 8.23268 13.3319 7.99999 13.3333ZM12 11.3333H3.99999V10.6667C3.99999 10.4899 4.07023 10.3203 4.19525 10.1953C4.32028 10.0702 4.48985 10 4.66666 10H11.3333C11.5101 10 11.6797 10.0702 11.8047 10.1953C11.9298 10.3203 12 10.4899 12 10.6667V11.3333Z"
      fill="currentColor"
    />
  </svg>
)
