import { Opportunity, OpportunityInteractionUser } from "../Opportunity"

export type PinnedOpportunity = Opportunity & {
  pinnedBy: Opportunity["pinnedBy"] & { active: true }
}
export const isOpportunityPinned = (opportunity: Opportunity): opportunity is PinnedOpportunity =>
  opportunity.pinnedBy?.active ?? false

export const pinOpportunity = ({
  opportunity,
  pinnedByUser,
  pinnedAtDate,
}: {
  opportunity: Opportunity
  pinnedByUser: OpportunityInteractionUser
  pinnedAtDate: Date
}): PinnedOpportunity =>
  !isOpportunityPinned(opportunity)
    ? {
        ...opportunity,
        pinnedBy: { user: pinnedByUser, asOf: pinnedAtDate, active: true, tag: "pin" },
        interactionHistory: opportunity.pinnedBy
          ? opportunity.interactionHistory.concat(opportunity.pinnedBy)
          : opportunity.interactionHistory,
      }
    : opportunity

export const unPinOpportunity = ({
  opportunity,
  unPinnedByUser,
  unPinnedAtDate,
}: {
  opportunity: Opportunity
  unPinnedByUser: OpportunityInteractionUser
  unPinnedAtDate: Date
}): Opportunity =>
  isOpportunityPinned(opportunity)
    ? {
        ...opportunity,
        pinnedBy: { user: unPinnedByUser, asOf: unPinnedAtDate, active: false, tag: "pin" },
        interactionHistory: opportunity.interactionHistory.concat(opportunity.pinnedBy),
      }
    : opportunity
