import { Button } from "@stories/components/Button/Button"
import { FormOrder } from "common/model/Order/OrderForm/State"
import { Order } from "common/model/Order/Models/Internal"
import { useState } from "react"
import ConnectOrderFormDrawer from "src/pages/Orders/OrderForm/SpecializedOrderForms/Connect/ConnectOrderFormDrawer"
import { useAddOrderPermission } from "src/pages/Orders/shared/permissions"
import { useOnSaveNewFormOrder } from "../../../pages/Orders/shared/useOnSaveNewFormOrder"

export const OrderSelectorNewOrderButton = ({
  defaultInitialFormOrder = {},
  title,
  afterSave,
}: {
  defaultInitialFormOrder?: Partial<FormOrder>
  title: string
  afterSave?: (o: Order | undefined) => void
}) => {
  const [open, setOpen] = useState(false)
  const onSaveNewFormOrder = useOnSaveNewFormOrder({
    afterSave,
    initialFormOrder: defaultInitialFormOrder,
  })

  const showOrderButton = useAddOrderPermission()

  return (
    <>
      {showOrderButton ? (
        <>
          <Button
            onClick={() => setOpen(true)}
            label={title}
            heapName="new-indication-btn"
            size="large"
          />
          <ConnectOrderFormDrawer
            open={open}
            initialFormOrder={defaultInitialFormOrder}
            onClose={() => setOpen(false)}
            onSave={onSaveNewFormOrder}
            drawerTitle={title}
          />
        </>
      ) : null}
    </>
  )
}
