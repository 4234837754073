import { CompanyLogoImgInner } from "@components/CompanyLogo/CompanyLogoImg"
import { sizeToTextSize } from "@model/components/lib/shared"
import { Tooltip } from "@stories/components/Antd"
import { Identity } from "common/containers/Identity"
import { nullableToMaybe } from "common/containers/Maybe"
import { CompanyIdFields, LogoSize } from "common/model/Company"
import { isLoaded } from "common/utils/Loading"
import { truncate } from "lodash"
import { Link } from "react-router-dom"
import { useCompanies } from "src/providers/data/CompanyProvider"
import { classNames } from "src/utils/classNames"
import { Routes } from "../../Routes/Routes"
import CompanyLogo from "../CompanyLogo/CompanyLogo"

export const CompanyLogoAndName: React.FC<
  React.PropsWithChildren<{
    company: CompanyIdFields
    size?: LogoSize
    showParenthesis?: boolean
    truncateLength?: 20
    disableClick?: boolean
    shouldMaskDataInDataDog?: boolean
    cursorPointer?: boolean // Force-override cursor to pointer
  }>
> = ({
  company,
  size = "sm",
  showParenthesis = false,
  disableClick: disableClickProp,
  cursorPointer,
  truncateLength,
  shouldMaskDataInDataDog = false,
}) => {
  const fullCompany = useCompanies(company.id)

  const toRoute = nullableToMaybe(company.postgresCompanyId).match(
    (postgresId) => Routes.companies.company(postgresId),
    () => ""
  )

  const companyName = Identity.pure(company.name)
    .map((name) => (showParenthesis || name.length < 10 ? name : name.split(" (")[0]))
    .map((name) => (truncateLength ? truncate(name, { length: truncateLength }) : name)).value

  const disableClick = disableClickProp || !company.postgresCompanyId

  return (
    <Link
      tabIndex={disableClick ? undefined : 0}
      className={classNames(
        "flex flex-row items-center justify-start py-1 select-none",
        size === "xs" || size === "xxs" ? "gap-1" : "gap-2",
        disableClick && !cursorPointer ? "cursor-default" : "cursor-pointer"
      )}
      to={toRoute}
      onClick={(e) => {
        if (disableClick) {
          e.preventDefault()
          return
        }
        e.stopPropagation()
      }}
    >
      <div className="flex items-center flex-shrink-0">
        {isLoaded(fullCompany) ? (
          <CompanyLogo company={fullCompany} size={size} disableClick={disableClick} />
        ) : (
          <CompanyLogoImgInner logoUrl={undefined} error={null} setError={() => {}} size={size} />
        )}
      </div>

      <div className="text-left text-primary-800 font-semibold">
        <Tooltip title={companyName !== company.name ? company.name : undefined}>
          <div
            className={classNames(
              "text-primary-500 whitespace-nowrap",
              sizeToTextSize[size],
              disableClick ? "" : "hover:text-accent-500"
            )}
            data-dd-privacy={shouldMaskDataInDataDog ? "mask" : "allow"}
          >
            {companyName}
          </div>
        </Tooltip>
      </div>
    </Link>
  )
}
