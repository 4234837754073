import { Company, SelectedCompany } from "common/model/Company"
import { longestSharedPrefix } from "common/utils/StringUtils"
import { Just, Maybe, Nothing, filterMaybe } from "common/containers/Maybe"
import { useEffect, useState } from "react"
import { useFirebaseReader } from "../../../firebase/Context"
import { CompanyAutocompleteProps } from "./CompanyAutocompleteProps"
import SearchSelect from "@stories/components/Inputs/SearchSelect/SearchSelect"
import { CompanySmallLogo } from "../CompanySmallLogo"

const tickerRegex = /.*\([A-Z]+:\s*.+\).*/

const defaultCompanyFilter = () => true

export const FirebaseCompanyAutocomplete: React.FunctionComponent<
  React.PropsWithChildren<CompanyAutocompleteProps>
> = ({
  autoFocus,
  selected,
  searchInputClassName,
  handleSelect,
  onFocus,
  onUnfocus,
  limitResults = 6,
  shouldIncludeNonPrivateCompanies: includeNonPrivateCompanies,
  companyFilter = defaultCompanyFilter, // NOTE: () => true inline assignment is not working. It causes the infinite loop
  createCompanyText,
  placeholder = "Search companies",
}) => {
  const firebase = useFirebaseReader()
  const [loading, setLoading] = useState(false)
  const [companies, setCompanies] = useState<Array<SelectedCompany>>([])

  useEffect(() => {
    setLoading(true)
    const getCompanies = firebase.allCompanies().onSnapshot((snapshot) => {
      if (snapshot) {
        setCompanies(
          filterMaybe(
            snapshot.docs
              .map((doc) => doc.data())
              .filter(
                (company) =>
                  company.name &&
                  typeof company.name === "string" &&
                  company.postgresCompanyId &&
                  company.pbid &&
                  (includeNonPrivateCompanies || !company.status || company.status === "Private") &&
                  companyFilter(company.id) &&
                  tickerRegex.exec(company.name) === null
              )
              .map((company) => selectedCompanyFromCompany(company))
          )
        )
      }
      setLoading(false)
    })
    return getCompanies
  }, [firebase, companyFilter, includeNonPrivateCompanies])

  const selectedCompanyFromCompany = (company: Company): Maybe<SelectedCompany> => {
    if (company.postgresCompanyId)
      return Just({
        id: company.id,
        name: company.name,
        status: company.status,
        postgresCompanyId: company.postgresCompanyId,
        lastRoundPostMoneyValuation: company.fundingRoundsSummary?.lastKnownValuation?.valuation,
        lastRoundDate: company.fundingRoundsSummary?.lastRound?.date,
        logos: company.logos,
        airtableId: company.airtableId,
      })
    else return Nothing
  }

  const onSearchInputChanged = (input: string): Promise<SelectedCompany[]> => {
    const lowerInput = input.toLowerCase()
    const searchedCompanies =
      !companies || companies.length === 0 || loading
        ? []
        : !!selected?.name && input === ""
        ? [
            {
              name: selected.name,
              status: selected.status,
              logos: selected.logos,
              id: companies.find((doc) => doc.name === selected.name)?.id || "",
              airtableId: companies.find((doc) => doc.name === selected.name)?.airtableId || "",
              lastRoundPostMoneyValuation: selected.lastRoundPostMoneyValuation,
              lastRoundDate: selected.lastRoundDate,
              postgresCompanyId: selected.postgresCompanyId,
            },
          ]
        : lowerInput.trim() === ""
        ? []
        : companies
            .filter((company) => company.name.toLowerCase().includes(lowerInput))
            .sort((a, b) => {
              const lowerA = a.name.toLowerCase()
              const lowerB = b.name.toLowerCase()
              if (lowerA === lowerInput) return -1
              if (lowerB === lowerInput) return 1
              if (lowerA.startsWith(lowerInput) && !lowerB.startsWith(lowerInput)) return -1
              if (lowerB.startsWith(lowerInput) && !lowerA.startsWith(lowerInput)) return 1

              // Closest match
              const aMatchScore = longestSharedPrefix(lowerA, lowerInput)
              const bMatchScore = longestSharedPrefix(lowerB, lowerInput)
              if (aMatchScore === bMatchScore) {
                if (a < b) return -1
                return 1
              }
              return -1 * (aMatchScore - bMatchScore)
            })
            .slice(0, limitResults)
    return Promise.resolve(searchedCompanies)
  }

  return (
    <div id="company-search" className="w-full">
      <SearchSelect<SelectedCompany>
        id="company-search-input"
        value={selected ?? null}
        onFocus={onFocus}
        onBlur={onUnfocus}
        loadOptions={onSearchInputChanged}
        onChange={(v) => handleSelect(v ?? undefined)}
        getOptionLabel={(v) => (createCompanyText ? createCompanyText(v.name) : v.name)}
        getOptionValue={(v) => v.id}
        className={searchInputClassName}
        placeholder={placeholder}
        autoFocus={autoFocus}
        renderOption={(company) => (
          <div className="flex items-center space-x-2">
            <CompanySmallLogo logo={company.logos?.xs} />
            <div>{company.name}</div>
          </div>
        )}
      />
    </div>
  )
}

export default FirebaseCompanyAutocomplete
