import { AlgoliaIndexedCompany } from "common/model/AlgoliaIndexedCompany"
import { SelectedCompany } from "common/model/Company"
import { filterMaybe, Just, Nothing } from "common/containers/Maybe"
import { useEffect, useMemo, useRef, useState } from "react"
import { searchCompanies } from "../../../services/Algolia"
import { handleConsoleError } from "../../../utils/Tracking"
import { selectedCompanyFromAlgoliaCompany } from "./AlgoliaCompanyAutocomplete"
import { CompanyAutocompleteProps } from "./CompanyAutocompleteProps"
import { charWidthMap } from "@components/typography/CharWidthMap"
import CompanyLogoAndName from "@components/CompanyLogoAndName"
import { Popover } from "@stories/components/Antd/Popover/Popover"

const CompanyAutocompletePill: React.FunctionComponent<
  React.PropsWithChildren<
    CompanyAutocompleteProps & {
      indexedCompanyFilter?: (c: AlgoliaIndexedCompany) => boolean
      placeholder?: string
      allowTabDefault?: boolean
      showDropDown?: boolean
    }
  >
> = ({
  autoFocus,
  handleSelect,
  placeholder,
  companyFilter = () => true,
  indexedCompanyFilter = () => true,
  allowTabDefault = false,
  showDropDown = false,
}) => {
  const [typedText, setTypedText] = useState<string>("")
  const inputRef = useRef<HTMLInputElement>(null)

  // TODO: we should have an app-wide algolia cache
  const [suggestionsCache, setSuggestionsCache] = useState<Record<string, SelectedCompany[]>>({})

  const onSearchInputChanged = async (value: string): Promise<SelectedCompany[]> => {
    handleSelect(undefined)
    const cleanedValue = value.replace(/[^a-zA-Z0-9\s.]/g, "")
    setTypedText(cleanedValue)

    if (cleanedValue !== "") {
      if (suggestionsCache[cleanedValue]) {
        return Promise.resolve(suggestionsCache[cleanedValue])
      } else {
        try {
          const results = await searchCompanies(cleanedValue, {
            maxResults: 6,
          })

          const maybeCompanies = results
            .filter((doc) => doc.name.toLowerCase().startsWith(cleanedValue.toLowerCase()))
            .filter(({ id }) => companyFilter(id))
            .filter(indexedCompanyFilter)
            .map(selectedCompanyFromAlgoliaCompany)

          const filteredResults = filterMaybe(maybeCompanies)

          setSuggestionsCache({ ...suggestionsCache, [cleanedValue]: filteredResults })

          return filteredResults
        } catch (e) {
          handleConsoleError(e)
          return []
        }
      }
    }
    return []
  }

  const suggestedCompany = useMemo(() => {
    if (!typedText) return Nothing
    const inputLadder = []
    for (let i = 1; i <= typedText.length; i++) {
      inputLadder.push(typedText.slice(0, i))
    }
    for (let i = inputLadder.length - 1; i >= 0; i--) {
      const suggestion = suggestionsCache[inputLadder[i]]
      if (suggestion && suggestion.length) return Just(suggestion[0])
    }
    return Nothing
  }, [typedText, suggestionsCache])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Tab" || event.key === "Enter") {
      if (!allowTabDefault || event.key !== "Tab") event.preventDefault()
      suggestedCompany.runEffect((company) => {
        handleSelect(company)
        setTypedText("")
      })
    }
  }

  const focusInput = () => {
    inputRef.current?.focus()
  }

  useEffect(() => {
    if (autoFocus) focusInput()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Popover
      open={!!typedText && showDropDown && !!suggestionsCache[typedText]?.length}
      placement="bottomLeft"
      content={
        <div>
          {suggestionsCache[typedText]?.map((c) => (
            <div
              key={c.id}
              onClick={() => handleSelect(c)}
              tabIndex={0}
              role="button"
              onKeyDown={(e) => e.key === "Enter" && handleSelect(c)}
            >
              <CompanyLogoAndName company={c} size="xxs" disableClick />
            </div>
          ))}
        </div>
      }
    >
      <div
        id="company-pill-autocomplete"
        className="text-sm items-center flex gap-0 flex-nowrap"
        onClick={focusInput}
        role="none"
      >
        <input
          ref={inputRef}
          type="text"
          value={typedText}
          onChange={(e) => onSearchInputChanged(e.target.value)}
          onKeyDown={handleKeyDown}
          style={{ width: typedText.length ? getWordWidth(typedText, "sm") : 2 }}
          className="px-0 py-0.5 text-sm block text-neutral-1000 font-medium placeholder-neutral-600 border-0 focus:outline-none ring-0 outline-none focus:border-0 focus:ring-0"
        />
        {suggestedCompany.match(
          (company) => (
            <>
              <span className="text-neutral-700 font-medium whitespace-nowrap">
                {company.name.substr(typedText.length)}
              </span>
              <span className="text-xxs text-neutral-700 ml-2 whitespace-nowrap flex-nowrap">
                <span className="px-1 py-0.5 border border-neutral-700 rounded-md">TAB</span> to
                select
              </span>
            </>
          ),
          () => null
        )}
        {!typedText && (
          <span className="text-neutral-700 whitespace-nowrap">
            {placeholder || "Search companies"}
          </span>
        )}
      </div>
    </Popover>
  )
}

export default CompanyAutocompletePill

export const getWordWidth = (word: string, size: "sm" | "md" | "lg") => {
  const wordWidth = word.split("").reduce((acc, char) => acc + charWidthMap[char], 0)
  if (size === "sm") {
    return wordWidth
  } else {
    return wordWidth * 1.5 // Obviously wrong, but this is a placeholder
  }
}
