import { Just, Nothing, nullableToMaybe } from "common/containers/Maybe"
import { THOUSAND, formatValuation } from "common/utils/math"
import { isNil } from "lodash"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Input, InputProps } from "../Input/Input"
import {
  MAXIMUM_ORDER_VALUATION_PRICE,
  MINIMUM_ORDER_VALUATION_PRICE,
} from "common/model/Order/OrderForm/Validation"

export const sizeScaledInputWarning = (value?: number): string | null => {
  if (value && value < 10 * THOUSAND) {
    if (value < 100) return `Did you mean ${value}M?`
    if (value >= 100) return `Did you mean ${value}K?`
  }
  return null
}
export const valuationScaledInputWarning = (value?: number): string | null => {
  if (value && value < 10 * THOUSAND) {
    if (value < 250) return `Did you mean ${value}B?`
    if (value >= 250) return `Did you mean ${value}M?`
  }
  if (value) {
    if (value < MINIMUM_ORDER_VALUATION_PRICE)
      return `The valuation entered must be at least ${formatValuation(
        MINIMUM_ORDER_VALUATION_PRICE
      )}.`
    if (value > MAXIMUM_ORDER_VALUATION_PRICE)
      return `The valuation entered must be at most ${formatValuation(
        MAXIMUM_ORDER_VALUATION_PRICE
      )}.`
  }
  return null
}

const parseAbbreviatedCurrencyString = (input: string): number | null => {
  const suffixes: { [key: string]: number } = {
    K: 1e3,
    M: 1e6,
    B: 1e9,
  }

  // Match the numeric part and suffix
  const match = input.match(/([\d.]+)([KMB]?)$/i)

  if (match) {
    const numericPart = parseFloat(match[1])
    const suffix = match[2].toUpperCase()

    if (!Number.isNaN(numericPart) && suffixes[suffix] !== undefined) {
      return numericPart * suffixes[suffix]
    }
  }

  return null
}

export type NumberFieldProps = Omit<InputProps, "value" | "onChange" | "onKeyDown"> & {
  value: number | null | undefined
  onChange: (newValue: number | undefined | null) => void
  formatter: (value: number) => string
  parser?: (rawInput: number) => number | null
  inputWarningFunction?: (value?: number) => string | null
  updateOnEveryChange?: boolean
  max?: number
  min?: number
}

export const NumberField: React.FC<NumberFieldProps> = ({
  value,
  onChange,
  onBlur,
  onFocus,
  formatter,
  parser,
  size = "small",
  inputWarningFunction,
  updateOnEveryChange = false,
  ...props
}) => {
  const [previousPropValue, setPreviousPropValue] = useState<number | null | undefined>(value)
  const [rawValue, setRawValue] = useState<string>(
    value !== null && value !== undefined ? formatter(value).replaceAll("$", "") : ""
  )
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [inputWarning, setInputWarning] = useState<string | null>(null)

  const parseNumber = useCallback(
    (val: string) => {
      if (val === "") {
        return Just(undefined)
      }

      const parsedFloat = parseFloat(val.replace(/,/g, ""))
      const containsLetters = /[a-zA-Z]/.test(val)

      if (!Number.isNaN(parsedFloat) && !containsLetters) {
        if (parser) {
          const customParsed = parser(parsedFloat)
          return nullableToMaybe(customParsed)
        } else {
          return Just(parsedFloat)
        }
      }

      const parsedCurrencyString = parseAbbreviatedCurrencyString(val)

      if (parsedCurrencyString) {
        return Just(parsedCurrencyString)
      }

      if (Number.isNaN(parsedFloat)) {
        return Nothing
      }

      return Just(parsedFloat)
    },
    [parser]
  )

  useEffect(() => {
    if (previousPropValue !== value && value?.toString() !== rawValue) {
      setPreviousPropValue(value)
      const formattedValue = value ? formatter(value ?? 0).replaceAll("$", "") : ""
      if (parseNumber(formattedValue).toNullable() === value) {
        setRawValue(formattedValue)
      } else if (parseNumber(rawValue).toNullable() !== value) {
        setRawValue(value?.toString() ?? "")
      }
    }
  }, [value, rawValue, previousPropValue, formatter, parseNumber])

  const handleBlur = () => {
    setIsEditing(false)
    if (rawValue === "" && isNil(value)) return

    parseNumber(rawValue).match(
      (val) => {
        if (!updateOnEveryChange) {
          onChange(val)
        }
        const formattedValue = val ? formatter(val).replaceAll("$", "") : ""
        if (val === parseNumber(formattedValue).toNullable()) {
          setRawValue(formattedValue)
        }
        if (inputWarningFunction) {
          setInputWarning(inputWarningFunction?.(val) ?? null)
        } else {
          setInputWarning(outOfBoundsWarning(val))
        }
      },
      () => {
        setRawValue(value?.toString() ?? "")
        setInputWarning(null)
      }
    )
  }

  const outOfBoundsWarning = (val: number | undefined | null): string | null => {
    if (isNil(val) || Number.isNaN(val)) return null
    else if (val && props.max && val > props.max)
      return `Must be less than or equal to ${formatter(props.max)}`
    else if (val && props.min && val < props.min)
      return `Must be greater than or equal to ${formatter(props.min)}`
    else return null
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRawValue(e.target.value)
    setInputWarning(null)

    const parsedNumber = parseNumber(e.target.value).match(
      (num) => num,
      () => undefined
    )

    if (updateOnEveryChange && !Number.isNaN(parsedNumber)) {
      onChange(parsedNumber)
    }
  }

  const displayValue = useMemo(
    () =>
      isEditing
        ? rawValue
        : parseNumber(rawValue).match(
            (num) => (!isNil(num) ? formatter(num) : ""),
            () => ""
          ),
    [rawValue, formatter, isEditing, parseNumber]
  )

  return (
    <Input
      type="text"
      size={size}
      value={displayValue}
      onBlur={(event) => {
        handleBlur()
        onBlur?.(event)
      }}
      onChange={handleChange}
      onFocus={(event) => {
        setIsEditing(true)
        onFocus?.(event)
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleBlur()
        }
      }}
      // START - overridden if passed as props
      isDanger={!!inputWarning}
      hintText={inputWarning ?? undefined}
      // END - overridden if passed as prop
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
}
