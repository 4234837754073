import { annotate } from "./Coerce"
import { Either, Left, Right } from "../containers/Either"
import {
  isBoolean,
  isInteger,
  isISODate,
  isISODateWithTime,
  isNumber,
  isObject,
  isString,
} from "./json/validate"
import { Rec } from "./RecordUtils"
import {
  Nonempty,
  Nonempty_,
  isArray,
  isNonemptyFromUnknown,
  reduceNonempty,
} from "./data/Array/Nonempty"
import { isInEnum } from "./UnionUtils"

export type ErrorMessage = string
const defaultErrorMessage: ErrorMessage = ""
/** A wrapper around T => Either<string, S> @deprecated */
export class Validator<T, S> {
  readonly validate: (t: T) => Either<ErrorMessage, S>

  constructor(validate: (t: T) => Either<ErrorMessage, S>) {
    this.validate = validate
  }

  /** @deprecated */
  public static fromPredicate = <X, Y extends X>(
    f: (x: X) => x is Y,
    message?: (x: X) => ErrorMessage
  ): Validator<X, Y> =>
    new Validator((x) => (f(x) ? Right(x) : Left((message || (() => defaultErrorMessage))(x))))

  /** @deprecated */
  public static overRecord = <
    K extends string,
    L extends { [key in K]: unknown },
    R extends { [key in K]: unknown }
  >(v: { [key in K]: (t: L[key]) => Either<ErrorMessage, R[key]> }): Validator<L, R> => {
    const applied = (t: L) =>
      Rec.apply<L, { [key in keyof R]: Either<ErrorMessage, R[key]> }, K>(v, t)
    return new Validator<L, R>((t) => Either.Record.sequence(applied(t)))
  }

  /** @deprecated */
  public static fromRecord = <R extends Record<never, unknown>>(v: {
    [key in keyof R]: (t: unknown) => Either<ErrorMessage, R[key]>
  }): Validator<unknown, R> =>
    new Validator<unknown, R>((t) =>
      isObject(t)
        ? Either.Record.sequence(
            Rec.apply<
              { [key in keyof R]: unknown },
              { [key in keyof R]: Either<ErrorMessage, R[key]> },
              keyof R
            >(
              v,
              t as { [key in keyof R]: unknown } // TODO fix
            )
          )
        : Left("")
    )

  map = <S2>(f: (s: S) => S2) => new Validator((x: T) => this.validate(x).map(f))

  compose = <S2>(f: Validator<S, S2>): Validator<T, S2> =>
    new Validator((x: T) => this.validate(x).bind((y) => f.validate(y)))

  or = <S2>(f: Validator<T, S2>): Validator<T, S | S2> =>
    new Validator((x) =>
      this.validate(x).match<Either<ErrorMessage, S | S2>, Either<ErrorMessage, S | S2>>(
        () => annotate<Either<ErrorMessage, S | S2>>(f.validate(x)),
        (r) => annotate<Either<ErrorMessage, S | S2>>(Right(r))
      )
    )

  overArray = (): Validator<T[], S[]> =>
    new Validator((ts) =>
      ts
        .map((t) => this.validate(t))
        .reduce(
          (l: Either<ErrorMessage, S[]>, r) => l.alongside(r).map(([t, h]) => [h].concat(t)),
          Right([])
        )
    )

  overArray1 = (): Validator<T[], S[]> =>
    new Validator((ts) => {
      const one: Either<string, S>[] = ts.map((t) => this.validate(t))
      return one.reduce(
        (l: Either<ErrorMessage, S[]>, r) => l.alongside(r).map(([t, h]) => [h].concat(t)),
        Right([])
      )
    })

  overNonEmptyArray = (): Validator<Nonempty<T>, Nonempty<S>> =>
    new Validator((ts) => {
      const validated: Nonempty<Either<ErrorMessage, Nonempty<S>>> = Nonempty_.map(ts, (t) =>
        this.validate(t).match(
          (err) => Left(err),
          (s: S) => Right(annotate<Nonempty<S>>([s]))
        )
      )
      const fn = (l: Either<ErrorMessage, Nonempty<S>>, r: Either<ErrorMessage, Nonempty<S>>) =>
        l.alongside(r).map(([t, h]) => Nonempty_.concat(t, h))
      return reduceNonempty(fn)(validated)
    })
}

/** @deprecated */
export const stringValidator: Validator<unknown, string> = Validator.fromPredicate(
  isString,
  (x) => `Expected a string, got ${JSON.stringify(x)}`
)
/** @deprecated */
export const validateString = stringValidator.validate
/** @deprecated */
export const booleanValidator: Validator<unknown, boolean> = Validator.fromPredicate(
  isBoolean,
  (x) => `Expected a boolean, got ${JSON.stringify(x)}`
)
/** @deprecated */
export const validateBoolean = booleanValidator.validate

/** @deprecated */
export const arrayValidator: Validator<unknown, unknown[]> = Validator.fromPredicate(
  isArray,
  (x) => `Expected an array, got ${JSON.stringify(x)}`
)

/** @deprecated */
export const nonEmptyArrayValidator: Validator<
  unknown,
  Nonempty<unknown>
> = Validator.fromPredicate(
  isNonemptyFromUnknown,
  (x) => `Expected a non-empty array, got ${isArray(x) ? "empty array" : typeof x}`
)

/** @deprecated */
export const numberValidator: Validator<unknown, number> = Validator.fromPredicate(
  isNumber,
  (x) => `Expected a number, got ${JSON.stringify(x)}`
)
/** @deprecated */
export const validateNumber = numberValidator.validate

/** @deprecated */
export const integerValidator: Validator<unknown, number> = Validator.fromPredicate(
  isInteger,
  (x) => `Expected an integer, got ${JSON.stringify(x)}`
)

/** @deprecated */
export const parseId = integerValidator.or(
  stringValidator.map((x) => Number.parseInt(x, 10)).compose(integerValidator)
).validate

/** @deprecated */
export const enumValidator = <T>(...ts: T[]): Validator<unknown, T> =>
  Validator.fromPredicate(
    isInEnum(ts),
    (x) => `Expected one of ${JSON.stringify(ts)}, got ${JSON.stringify(x)}`
  )

/** @deprecated */
export const isoDateValidator: Validator<unknown, string> = Validator.fromPredicate(
  isISODate,
  (x) => `Expected an ISO date, got ${JSON.stringify(x)}`
)

/** @deprecated */
export const isoDateWithTimeValidator: Validator<unknown, string> = Validator.fromPredicate(
  isISODateWithTime,
  (x) => `Expected an ISO date with time, got ${JSON.stringify(x)}`
)

/** @deprecated */
export const validateISODate = isoDateValidator.validate
