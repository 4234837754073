import { restrictedCollections } from "common/firestore/Collections"
import { EditLog } from "common/model/EditLog"
import { viewUserIdFields } from "common/model/User"
import { undefinedsToNulls } from "common/utils/ObjectUtils"
import { mapOrUndefined } from "common/utils/UnionUtils"
import { now } from "common/utils/dateUtils"
import { Just, Maybe } from "common/containers/Maybe"
import Firebase from "src/firebase/Firebase"
import { DocumentReference } from "../../firebase/Firebase/utils"
import { CurrentUser } from "../../model/CurrentUser"
import { firestoreConverter } from "../../model/FirestoreConverter"

export type EditableRecord = { lastUpdatedAt?: Date; id: string }

/** Do not use for order updates that may require merges. See saveOrderInFirestore isntead */
export const updateDocumentLogged = <R extends EditableRecord>(
  db: Firebase,
  user: CurrentUser,
  reason: string,
  docRef: DocumentReference<R>,
  doc: R
): Promise<Maybe<{ id: string }>> =>
  {
    console.log(doc)
    return db.adminDb.runTransaction(async (t) => {
    const currentState = await t.get(docRef.withConverter<R>(firestoreConverter<R>()))
    const log: EditLog<R> = {
      before: mapOrUndefined(currentState.data(), undefinedsToNulls) ?? null,
      after: undefinedsToNulls(doc),
      path: docRef.path,
      editor: user.user,
      reason,
      editDate: now(),
    }
    const logRef = db.adminDb.collection(restrictedCollections.editLogs).doc()
    t.set(logRef, log).update(docRef, { ...doc, lastUpdatedAt: now() })
    return Just({ id: doc.id })
  })
}
export type UpdateDocumentLoggedFnType<R extends EditableRecord> = (
  db: Firebase,
  user: CurrentUser,
  reason: string,
  docRef: DocumentReference<R>,
  doc: R
) => Promise<Maybe<{ id: string }>> // TODO clean up

export const deleteDocument = <T extends object>(
  db: Firebase,
  user: CurrentUser,
  reason: string,
  docRef: DocumentReference<T>
) =>
  db.adminDb.runTransaction(async (t) => {
    const currentState = await t.get(docRef)
    const log: EditLog<T> = {
      before: mapOrUndefined(currentState.data(), undefinedsToNulls) ?? null,
      after: null,
      path: docRef.path,
      editor: viewUserIdFields(user.user),
      reason,
      editDate: now(),
    }
    const logRef = db.adminDb.collection(restrictedCollections.editLogs).doc()
    return t.set(logRef, log).delete(docRef)
  })
