import { Button } from "@stories/components/Button/Button"
import { ChevronRightIcon } from "@stories/icons/ChevronRightIcon"
import { Account } from "common/model/Account"
import { AccountMetrics } from "common/model/AccountMetrics"
import { AccountEventCountsRollup } from "common/model/Analytics/EventCounterRollup"
import { UserEventType } from "common/model/UserEvent"
import moment from "moment-timezone"
import numeral from "numeral"
import * as React from "react"
import ClientTypePill from "../components/ClientTypePill"
import { EXCLUDED_EVENTS } from "./AccountEngagement"
import Module from "./Module"
import { ChevronDownIcon } from "@stories/icons/ChevronDownIcon"
import { addWeeks, subWeeks } from "date-fns"
import { useAdminEngagementDrawer } from "src/providers/AdminProviders/AdminEngagementDrawerProvider"

interface TrialAccountsModuleProps {
  title: string
  accounts: Account[]
  weeklyEventCounts: AccountEventCountsRollup[]
  monthlyEventCounts: AccountEventCountsRollup[]
  accountGroups?: Array<{
    name: string
    filter: (account: Account) => boolean
  }>
  renderClientType?: boolean
}

type AccountEventCounts = AccountEventCountsRollup["accounts"][string]["eventCounts"]

interface AccountEngagementStats {
  account: Account
  currentWeekEngagement: AccountEventCounts
  previousWeekEngagement: AccountEventCounts
  pastFourWeeksEngagement: AccountEventCounts
  eightWeeksAgoFourWeekEngagement: AccountEventCounts
  twoWeeksAgoEngagement: AccountEventCountsRollup["accounts"][string]["eventCounts"]
}

const sortByOptions = [
  "Previous Week Change % (low-to-high)",
  "Previous Week Change % (high-to-low)",
  "Previous Week Engagement (low-to-high)",
  "Previous Week Engagement (high-to-low)",
  "Current Week Change % (low-to-high)",
  "Current Week Change % (high-to-low)",
  "Current Week Engagement (low-to-high)",
  "Current Week Engagement (high-to-low)",
  "Past 4 Weeks Engagement (low-to-high)",
  "Past 4 Weeks Engagement (high-to-low)",
  "Past 4 Weeks Change % (low-to-high)",
  "Past 4 Weeks Change % (high-to-low)",
] as const
type SortByOption = (typeof sortByOptions)[number]

const AccountsModule: React.FunctionComponent<TrialAccountsModuleProps> = ({
  title,
  accounts,
  accountGroups,
  weeklyEventCounts,
  monthlyEventCounts,
  renderClientType = false,
}) => {
  const eventCounts = weeklyEventCounts
  const isEarlyInWeek = React.useMemo(() => {
    const currentDay = moment().tz("America/Los_Angeles").day()
    return currentDay < 4
  }, [])

  const sortByOptionsForCurrentDay = isEarlyInWeek
    ? sortByOptions.filter((option) => !option.includes("Current Week"))
    : sortByOptions

  const defaultSortByOption = isEarlyInWeek
    ? "Current Week Change % (low-to-high)"
    : "Previous Week Change % (low-to-high)"

  const [sortBy, setSortBy] = React.useState<SortByOption>(defaultSortByOption)
  const [expandedGroup, setExpandedGroup] = React.useState<string | undefined>()

  const currentWeekPeriodStart = moment().tz("America/Los_Angeles").startOf("week").toDate()
  const previousWeekPeriodStart = subWeeks(currentWeekPeriodStart, 1)
  const twoWeeksAgoPeriodStart = subWeeks(currentWeekPeriodStart, 2)
  const fourWeekPeriodStart = subWeeks(currentWeekPeriodStart, 4)
  const eightWeeksAgoPeriodStart = subWeeks(currentWeekPeriodStart, 8)

  const getAccountEngagmentCountsForPeriod = (
    account: Account,
    period: AccountEventCountsRollup["period"],
    periodStarts: Date[]
  ) => {
    const periodCounts = eventCounts
      .filter(
        (rollup) =>
          rollup.period === period &&
          periodStarts.some(
            (date) => Math.abs(date.valueOf() - rollup.periodDate.valueOf()) < 1000 * 60 * 60 * 24
          )
      )
      .map((rollup) => rollup.accounts[account.id]?.eventCounts || {})

    return periodCounts.reduce((acc, curr) => {
      Object.keys(curr)
        .filter((ev) => !EXCLUDED_EVENTS.includes(ev as UserEventType))
        .forEach((eventType) => {
          acc[eventType as UserEventType] =
            (acc[eventType as UserEventType] || 0) + (curr[eventType as UserEventType] || 0)
        })
      return acc
    }, {} satisfies AccountEventCountsRollup["accounts"][string]["eventCounts"])
  }

  const getAccountEngagementStats = (account: Account) => {
    const currentWeekEngagement = getAccountEngagmentCountsForPeriod(account, "week", [
      currentWeekPeriodStart,
    ])
    const previousWeekEngagement = getAccountEngagmentCountsForPeriod(account, "week", [
      previousWeekPeriodStart,
    ])
    const twoWeeksAgoEngagement = getAccountEngagmentCountsForPeriod(account, "week", [
      twoWeeksAgoPeriodStart,
    ])
    const pastFourWeeksEngagement = getAccountEngagmentCountsForPeriod(account, "week", [
      fourWeekPeriodStart,
      addWeeks(fourWeekPeriodStart, 1),
      addWeeks(fourWeekPeriodStart, 2),
      addWeeks(fourWeekPeriodStart, 3),
    ])
    const eightWeeksAgoFourWeekEngagement = getAccountEngagmentCountsForPeriod(account, "week", [
      eightWeeksAgoPeriodStart,
      addWeeks(eightWeeksAgoPeriodStart, 1),
      addWeeks(eightWeeksAgoPeriodStart, 2),
      addWeeks(eightWeeksAgoPeriodStart, 3),
    ])
    return {
      account,
      currentWeekEngagement,
      previousWeekEngagement,
      twoWeeksAgoEngagement,
      pastFourWeeksEngagement,
      eightWeeksAgoFourWeekEngagement,
    }
  }

  const accountEngagementStats = (accountGroup: Account[]) =>
    accountGroup.map(getAccountEngagementStats)

  const accountGroupsEngagementStats = accountGroups
    ? accountGroups.map(({ name, filter }) => ({
        group: name,
        engagementStats: accountEngagementStats(accounts.filter(filter)),
      }))
    : [
        {
          group: title,
          engagementStats: accountEngagementStats(accounts),
        },
      ]

  const getEngagementCount = (engagement: AccountEngagementStats["currentWeekEngagement"]) =>
    Object.values(engagement).reduce((acc, curr) => acc + curr, 0)

  const renderAccountMetric = (
    account: Account,
    metric: keyof Required<AccountMetrics>["accountMetrics"]
  ) => {
    if (!account.accountMetrics) return "0 (0)"
    const metricValue = account.accountMetrics[metric]

    return `${metricValue.last30Days} (${metricValue.total})`
  }

  const renderEngagementPercentageChange = (
    currentPeriod: AccountEngagementStats["currentWeekEngagement"],
    previousPeriod: AccountEngagementStats["previousWeekEngagement"]
  ) => {
    const currentPeriodEngagement = getEngagementCount(currentPeriod)
    const previousPeriodEngagement = getEngagementCount(previousPeriod)
    let text = ""
    let style = ""
    if (!previousPeriodEngagement) text = "(N/A)"
    else {
      const percentChange =
        (currentPeriodEngagement - previousPeriodEngagement) / previousPeriodEngagement
      if (percentChange >= 0.1) style = "bg-success-700 text-white p-0.5"
      else if (percentChange <= -0.1) style = "bg-danger-700 text-white p-0.5"
      text = `(${percentChange > 0 ? "+" : ""}${numeral(percentChange).format("0%")})`
    }
    return <span className={`text-xs ${style} ml-1`}>{text}</span>
  }

  const sortedSection = (accountEngagmentStats: AccountEngagementStats[]) => {
    const getEngagementCountForSort = (accountStats: AccountEngagementStats) => {
      switch (sortBy) {
        case "Current Week Engagement (low-to-high)":
          return getEngagementCount(accountStats.currentWeekEngagement)
        case "Current Week Engagement (high-to-low)":
          return getEngagementCount(accountStats.currentWeekEngagement) * -1
        case "Current Week Change % (low-to-high)":
          return (
            getEngagementCount(accountStats.currentWeekEngagement) /
            getEngagementCount(accountStats.previousWeekEngagement)
          )
        case "Current Week Change % (high-to-low)":
          return (
            (getEngagementCount(accountStats.currentWeekEngagement) /
              getEngagementCount(accountStats.previousWeekEngagement)) *
            -1
          )
        case "Previous Week Engagement (low-to-high)":
          return getEngagementCount(accountStats.previousWeekEngagement)
        case "Previous Week Engagement (high-to-low)":
          return getEngagementCount(accountStats.previousWeekEngagement) * -1

        case "Previous Week Change % (low-to-high)":
          return (
            getEngagementCount(accountStats.previousWeekEngagement) /
            getEngagementCount(accountStats.twoWeeksAgoEngagement)
          )
        case "Previous Week Change % (high-to-low)":
          return (
            (getEngagementCount(accountStats.previousWeekEngagement) /
              getEngagementCount(accountStats.twoWeeksAgoEngagement)) *
            -1
          )

        case "Past 4 Weeks Change % (high-to-low)":
          return (
            (getEngagementCount(accountStats.pastFourWeeksEngagement) /
              getEngagementCount(accountStats.eightWeeksAgoFourWeekEngagement)) *
            -1
          )

        case "Past 4 Weeks Change % (low-to-high)":
          return (
            getEngagementCount(accountStats.pastFourWeeksEngagement) /
            getEngagementCount(accountStats.eightWeeksAgoFourWeekEngagement)
          )

        case "Past 4 Weeks Engagement (low-to-high)":
          return getEngagementCount(accountStats.pastFourWeeksEngagement)

        case "Past 4 Weeks Engagement (high-to-low)":
          return getEngagementCount(accountStats.pastFourWeeksEngagement) * -1
        default:
          return 0
      }
    }

    return accountEngagmentStats.sort((a, b) => {
      const aEngagementCount = getEngagementCountForSort(a)
      const bEngagementCount = getEngagementCountForSort(b)
      return aEngagementCount - bEngagementCount
    })
  }

  const DEFAULT_NUM_ACCOUNTS_TO_SHOW = 8

  const { openDrawer: openAdminEngagementDrawer } = useAdminEngagementDrawer()
  const renderAccountsSection = (
    sectionTitle: string,
    accountEngagmentStats: AccountEngagementStats[],
    expanded: boolean
  ) => {
    const sortedAccounts = sortedSection(accountEngagmentStats)
    const limitedAccounts = expanded
      ? sortedAccounts
      : sortedAccounts.slice(0, DEFAULT_NUM_ACCOUNTS_TO_SHOW)
    return (
      <div>
        <div className="flex flex-row justify-between">
          <h4 className="text-sm font-semibold">{sectionTitle}</h4>
          <span className="text-sm font-semibold">{accountEngagmentStats.length} accounts</span>
        </div>
        {/* account name, current week engagement and change %, previous week engagement and change %, current month engagement and change %, previous month engagement and change % */}
        <div className="flex flex-col space-y-4">
          <div className="mt-2 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-neutral-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-neutral-500 sm:pl-0"
                      >
                        Account
                      </th>
                      {renderClientType && (
                        <th
                          scope="col"
                          className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-neutral-500 sm:pl-0"
                        >
                          Type
                        </th>
                      )}
                      {!isEarlyInWeek && (
                        <th
                          scope="col"
                          className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-neutral-500"
                        >
                          Current Week Engagement (change %)
                        </th>
                      )}
                      <th
                        scope="col"
                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-neutral-500"
                      >
                        Prev Week Engagement (change %)
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-neutral-500"
                      >
                        Past 4 Weeks Engagement (change %)
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-neutral-500"
                      >
                        Prev Month Engagement
                      </th>
                      <th
                        scope="col"
                        className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-neutral-500 sm:pl-0"
                      >
                        30-day order count (total)
                      </th>
                      <th
                        scope="col"
                        className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-neutral-500 sm:pl-0"
                      >
                        30-day Connect count (total)
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-neutral-200 bg-neutral-white">
                    {limitedAccounts.map((accountStats) => (
                      <tr key={accountStats.account.id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-neutral-900 sm:pl-0">
                          <Button
                            variant="hollow-link"
                            onClick={() => openAdminEngagementDrawer(accountStats.account.id)}
                            label={accountStats.account.name}
                          />
                        </td>
                        {renderClientType && (
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-neutral-900 sm:pl-0">
                            <ClientTypePill clientType={accountStats.account.clientType} />
                          </td>
                        )}
                        {!isEarlyInWeek && (
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-neutral-500">
                            {getEngagementCount(accountStats.currentWeekEngagement)}
                            {renderEngagementPercentageChange(
                              accountStats.currentWeekEngagement,
                              accountStats.previousWeekEngagement
                            )}
                          </td>
                        )}
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-neutral-500">
                          {getEngagementCount(accountStats.previousWeekEngagement)}
                          {renderEngagementPercentageChange(
                            accountStats.previousWeekEngagement,
                            accountStats.twoWeeksAgoEngagement
                          )}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-neutral-500">
                          {getEngagementCount(accountStats.pastFourWeeksEngagement)}
                          {renderEngagementPercentageChange(
                            accountStats.pastFourWeeksEngagement,
                            accountStats.eightWeeksAgoFourWeekEngagement
                          )}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-neutral-500">
                          {getEngagementCount(accountStats.eightWeeksAgoFourWeekEngagement)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-neutral-500">
                          {renderAccountMetric(accountStats.account, "orders")}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-neutral-500">
                          {renderAccountMetric(accountStats.account, "connectRequestsSubmitted")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="flex justify-center">
                  <Button
                    variant="secondary"
                    leftIcon={
                      expandedGroup === sectionTitle ? <ChevronDownIcon /> : <ChevronRightIcon />
                    }
                    label={expandedGroup === sectionTitle ? "Collapse" : "Show All"}
                    onClick={() =>
                      setExpandedGroup(expandedGroup === sectionTitle ? undefined : sectionTitle)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Module title={title}>
      <div className="flex flex-col space-y-4">
        {/* dropdown selector for sort by */}
        <div className="flex flex-row justify-between">
          <div className="flex flex-row space-x-4 items-center">
            <span className="text-sm font-semibold">Sort by</span>
            <select
              className="text-sm font-semibold px-3 pr-7 py-0.5"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value as SortByOption)}
            >
              {sortByOptionsForCurrentDay.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
        {accountGroupsEngagementStats.map(({ group, engagementStats }) =>
          renderAccountsSection(group, engagementStats, expandedGroup === group)
        )}
      </div>
    </Module>
  )
}

export default AccountsModule
