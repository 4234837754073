import { Rec, UnsafeRec } from "../RecordUtils"
import { UndefinedToOptional } from "../types/Record"
import { Boxed } from "./Boxed"
import { Either, Left, Right } from "../../containers/Either"
import { Func } from "./Function"
import { nullableToMaybe } from "../../containers/Maybe"

export class Lambda<X, Y> extends Boxed<Func<X, Y>> {
  run = this.unboxed

  // core functionality

  alongside = <X2, Y2>(s: Lambda<X2, Y2>): Lambda<[X, X2], [Y, Y2]> =>
    λ(([l, r]) => [this.run(l), s.run(r)])

  and = <X2, Y2>(s: Lambda<X2, Y2>): Lambda<X & X2, [Y, Y2]> => λ((x) => [this.run(x), s.run(x)])

  // This is really dual to `alongside`, rather than `and`
  // The actual dual signature would be `(Display<T>, Display<S>) => Display<T | S>`, but `|` is too badly behaved for such a function to exist.
  or = <X2, Y2>(s: Lambda<X2, Y2>): Lambda<Either<X, X2>, Y | Y2> =>
    λ((x) => x.match(this.run, s.run))

  map = <Y2>(f: Func<Y, Y2>): Lambda<X, Y2> => λ((x) => f(this.run(x)))

  contramap = <X2>(f: Func<X2, X>): Lambda<X2, Y> => λ((x) => this.run(f(x)))

  bind = <Y2>(f: (r: Y) => Lambda<X, Y2>): Lambda<X, Y2> => λ((x) => f(this.run(x)).run(x))

  // TODO better name
  // TODO rewrite other methods in terms of this where possible
  asFunction = <A, B>(f: Func<Func<X, Y>, Func<A, B>>): Lambda<A, B> => new Lambda(f(this.unboxed))

  // helpers, shorthand forms, etc.

  withDefault = <Y2>(r: Y2): Lambda<X | undefined, Y | Y2> =>
    this.or(Lambda.constant(r)).contramap((x?: X) => (x ? Left(x) : Right(null)))

  // mapping over records, field selectors, assorted javascript nonsense

  hoist = <K extends string | symbol>(
    f: <T>(t: T) => Record<K, T>
  ): Lambda<Record<K, X>, Record<K, Y>> =>
    overRecord<K, Record<K, X>, Y>(f(this)) as Lambda<Record<K, X>, Record<K, Y>>

  // constant values
  static identity = <T>() => λ((x: T) => x)

  static constant = <R>(r: R) => λ((_: unknown) => r)
}

export const λ = <X, Y>(f: Func<X, Y>) => new Lambda<X, Y>(f)
export const lambda = λ

export const overRecordValues = <T, R, K extends keyof R = keyof R>(rec: {
  [key in K]?: Lambda<R[key], T>
}): Lambda<UndefinedToOptional<{ [key in K]: R[key] }>, T[]> =>
  λ((x) =>
    UnsafeRec.keys(rec).flatMap((key) =>
      nullableToMaybe(rec[key])
        .alongside(nullableToMaybe(Rec.index(x, key)))
        .map(([f, v]) => f.run(v))
        .matchStrict((t) => [t], [])
    )
  )

export const overRecord = <K extends keyof R, R, T>(rec: {
  [key in K]?: Lambda<R[key], T>
}): Lambda<UndefinedToOptional<{ [key in K]: R[key] }>, { [key in K]?: T }> =>
  λ((x) =>
    Rec.fromEntries<Record<K, T>>(
      UnsafeRec.keys(rec).flatMap((key) =>
        nullableToMaybe(rec[key])
          .alongside(nullableToMaybe(Rec.index(x, key)))
          .map(([f, v]) => f.run(v))
          .matchStrict((t) => [[key, t]], [])
      )
    )
  )
