import { NumberInputFormatProps } from "./NumberInput"

export const defaultNumberInputFormatProps: NumberInputFormatProps = {
  numberFormat: "default",
  scale: 1,
  precision: "integer",
  decimalPlaces: 0,
  totalDigits: 10,
  overrideWithRounded: false,
}
