import Spinner from "@components/icons/Spinner"
import Typography, { Color, Size } from "@stories/components/Typography/Typography"
import { CheckIcon } from "@stories/icons/CheckIcon"
import { useState } from "react"

export type SaveStatus = "loading" | "success" | "error" | "idle"
export type SaveStatusProps = {
  saveStatus: SaveStatus
  setSaveStatus: React.Dispatch<React.SetStateAction<SaveStatus>>
}

export const useSaveStatus = () => useState<SaveStatus>("idle")

const statusToColor: Record<SaveStatus, Color> = {
  idle: Color.Gray,

  success: Color.Success,
  error: Color.Danger,
  loading: Color.Warning,
}

const StatusIcon = ({ saveStatus }: Pick<SaveStatusProps, "saveStatus">) => (
  <div className="w-4 h-4">
    {
      {
        idle: null,
        success: <CheckIcon />,
        error: null,
        loading: <Spinner size="xxs" />,
      }[saveStatus]
    }
  </div>
)

const StatusText = ({ saveStatus }: Pick<SaveStatusProps, "saveStatus">) => (
  <div>
    {
      {
        idle: null,
        success: "Saved successfully",
        error: "There was an error while saving, please try again",
        loading: "Saving...",
      }[saveStatus]
    }
  </div>
)

const StatusTextAndIcon = ({ saveStatus }: Pick<SaveStatusProps, "saveStatus">) => (
  <div className="flex space-x-1">
    <StatusText saveStatus={saveStatus} />
    <StatusIcon saveStatus={saveStatus} />
  </div>
)

export const DisplaySaveStatus = ({ saveStatus }: Pick<SaveStatusProps, "saveStatus">) => (
  <Typography
    text={<StatusTextAndIcon saveStatus={saveStatus} />}
    color={statusToColor[saveStatus]}
    size={Size.XSmall}
  />
)
