import { ReactNode } from "react"
import { classNames } from "src/utils/classNames"
import { TableProps } from "./Table"
import { assertUnreachable } from "common/utils/fp/Function"
import { TableVariant } from "./types"

export const DEFAULT_VARIANT: TableVariant = "stripe"

export const femaleNames = [
  "Emily",
  "Hannah",
  "Madison",
  "Ashley",
  "Sarah",
  "Alexis",
  "Samantha",
  "Jessica",
  "Elizabeth",
  "Taylor",
  "Lauren",
  "Alyssa",
  "Kayla",
  "Abigail",
  "Brianna",
  "Olivia",
  "Emma",
  "Megan",
  "Grace",
  "Victoria",
  "Rachel",
  "Anna",
  "Sydney",
  "Destiny",
  "Morgan",
  "Jennifer",
  "Jasmine",
  "Haley",
  "Julia",
  "Kaitlyn",
  "Nicole",
  "Amanda",
  "Katherine",
  "Natalie",
  "Hailey",
  "Alexandra",
  "Savannah",
  "Chloe",
  "Rebecca",
  "Stephanie",
  "Maria",
  "Sophia",
  "Mackenzie",
  "Allison",
  "Isabella",
  "Amber",
  "Mary",
  "Danielle",
  "Gabrielle",
  "Jordan",
  "Brooke",
  "Michelle",
  "Sierra",
  "Katelyn",
  "Andrea",
  "Madeline",
  "Sara",
  "Kimberly",
  "Courtney",
  "Erin",
  "Brittany",
  "Vanessa",
  "Jenna",
  "Jacqueline",
  "Caroline",
  "Faith",
  "Makayla",
  "Bailey",
  "Paige",
  "Shelby",
  "Melissa",
  "Kaylee",
  "Christina",
  "Trinity",
  "Mariah",
  "Caitlin",
  "Autumn",
  "Marissa",
  "Breanna",
  "Angela",
  "Catherine",
  "Zoe",
  "Briana",
  "Jada",
  "Laura",
  "Claire",
  "Alexa",
  "Kelsey",
  "Kathryn",
  "Leslie",
  "Alexandria",
  "Sabrina",
  "Mia",
  "Isabel",
  "Molly",
  "Leah",
  "Katie",
  "Gabriella",
  "Cheyenne",
  "Cassandra",
  "Tiffany",
  "Erica",
  "Lindsey",
  "Kylie",
  "Amy",
  "Diana",
  "Cassidy",
  "Mikayla",
  "Ariana",
  "Margaret",
  "Kelly",
  "Miranda",
  "Maya",
  "Melanie",
  "Audrey",
  "Jade",
  "Gabriela",
  "Caitlyn",
  "Angel",
  "Jillian",
  "Alicia",
  "Jocelyn",
  "Erika",
  "Lily",
  "Heather",
  "Madelyn",
  "Adriana",
  "Arianna",
  "Lillian",
  "Kiara",
  "Riley",
  "Crystal",
  "Mckenzie",
  "Meghan",
  "Skylar",
  "Ana",
  "Britney",
  "Angelica",
  "Kennedy",
  "Chelsea",
  "Daisy",
  "Kristen",
  "Veronica",
  "Isabelle",
  "Summer",
  "Hope",
  "Brittney",
  "Lydia",
  "Hayley",
  "Evelyn",
  "Bethany",
  "Shannon",
  "Michaela",
  "Karen",
  "Jamie",
  "Daniela",
  "Angelina",
  "Kaitlin",
  "Karina",
  "Sophie",
  "Sofia",
  "Diamond",
  "Payton",
  "Cynthia",
  "Alexia",
  "Valerie",
  "Monica",
  "Peyton",
  "Carly",
  "Bianca",
  "Hanna",
  "Brenda",
  "Rebekah",
  "Alejandra",
  "Mya",
  "Avery",
  "Brooklyn",
  "Ashlyn",
  "Lindsay",
  "Ava",
  "Desiree",
  "Alondra",
  "Camryn",
  "Ariel",
  "Naomi",
  "Jordyn",
  "Kendra",
]

export const maleNames = [
  "Jacob",
  "Michael",
  "Matthew",
  "Joshua",
  "Christopher",
  "Nicholas",
  "Andrew",
  "Joseph",
  "Daniel",
  "Tyler",
  "William",
  "Brandon",
  "Ryan",
  "John",
  "Zachary",
  "David",
  "Anthony",
  "James",
  "Justin",
  "Alexander",
  "Jonathan",
  "Christian",
  "Austin",
  "Dylan",
  "Ethan",
  "Benjamin",
  "Noah",
  "Samuel",
  "Robert",
  "Nathan",
  "Cameron",
  "Kevin",
  "Thomas",
  "Jose",
  "Hunter",
  "Jordan",
  "Kyle",
  "Caleb",
  "Jason",
  "Logan",
  "Aaron",
  "Eric",
  "Brian",
  "Gabriel",
  "Adam",
  "Jack",
  "Isaiah",
  "Juan",
  "Luis",
  "Connor",
  "Charles",
  "Elijah",
  "Isaac",
  "Steven",
  "Evan",
  "Jared",
  "Sean",
  "Timothy",
  "Luke",
  "Cody",
  "Nathaniel",
  "Alex",
  "Seth",
  "Mason",
  "Richard",
  "Carlos",
  "Angel",
  "Patrick",
  "Devin",
  "Bryan",
  "Cole",
  "Jackson",
  "Ian",
  "Garrett",
  "Trevor",
  "Jesus",
  "Chase",
  "Adrian",
  "Mark",
  "Blake",
  "Sebastian",
  "Antonio",
  "Lucas",
  "Jeremy",
  "Gavin",
  "Miguel",
  "Julian",
  "Dakota",
  "Alejandro",
  "Jesse",
  "Dalton",
  "Bryce",
  "Tanner",
  "Kenneth",
  "Stephen",
  "Jake",
  "Victor",
  "Spencer",
  "Marcus",
  "Paul",
  "Brendan",
  "Jeremiah",
  "Xavier",
  "Jeffrey",
  "Tristan",
  "Jalen",
  "Jorge",
  "Edward",
  "Riley",
  "Wyatt",
  "Colton",
  "Joel",
  "Maxwell",
  "Aidan",
  "Travis",
  "Shane",
  "Colin",
  "Dominic",
  "Carson",
  "Vincent",
  "Derek",
  "Oscar",
  "Grant",
  "Eduardo",
  "Peter",
  "Henry",
  "Parker",
  "Hayden",
  "Collin",
  "George",
  "Bradley",
  "Mitchell",
  "Devon",
  "Ricardo",
  "Shawn",
  "Taylor",
  "Nicolas",
  "Francisco",
  "Gregory",
  "Liam",
  "Kaleb",
  "Preston",
  "Erik",
  "Alexis",
  "Owen",
  "Omar",
  "Diego",
  "Dustin",
  "Corey",
  "Fernando",
  "Clayton",
  "Carter",
  "Ivan",
  "Jaden",
  "Javier",
  "Alec",
  "Johnathan",
  "Scott",
  "Manuel",
  "Cristian",
  "Alan",
  "Raymond",
  "Brett",
  "Max",
  "Andres",
  "Gage",
  "Mario",
  "Dawson",
  "Dillon",
  "Cesar",
  "Wesley",
  "Levi",
  "Jakob",
  "Chandler",
  "Martin",
  "Malik",
  "Edgar",
  "Trenton",
  "Sergio",
  "Josiah",
  "Nolan",
  "Marco",
  "Peyton",
  "Harrison",
  "Hector",
  "Micah",
  "Roberto",
  "Drew",
  "Brady",
  "Erick",
  "Conner",
  "Jonah",
  "Casey",
  "Jayden",
  "Emmanuel",
  "Edwin",
  "Andre",
  "Phillip",
  "Brayden",
]

export const lastNames = [
  "Smith",
  "Johnson",
  "Williams",
  "Brown",
  "Jones",
  "Miller",
  "Davis",
  "Garcia",
  "Rodriguez",
  "Wilson",
  "Martinez",
  "Anderson",
  "Taylor",
  "Thomas",
  "Hernandez",
  "Moore",
  "Martin",
  "Jackson",
  "Thompson",
  "White",
  "Lopez",
  "Lee",
  "Gonzalez",
  "Harris",
  "Clark",
  "Lewis",
  "Robinson",
  "Walker",
  "Perez",
  "Hall",
  "Young",
  "Allen",
  "Sanchez",
  "Wright",
  "King",
  "Scott",
  "Green",
  "Baker",
  "Adams",
  "Nelson",
  "Hill",
  "Ramirez",
  "Campbell",
  "Mitchell",
  "Roberts",
  "Carter",
  "Phillips",
  "Evans",
  "Turner",
  "Torres",
  "Parker",
  "Collins",
  "Edwards",
  "Stewart",
  "Flores",
  "Morris",
  "Nguyen",
  "Murphy",
  "Rivera",
  "Cook",
  "Rogers",
  "Morgan",
  "Peterson",
  "Cooper",
  "Reed",
  "Bailey",
  "Bell",
  "Gomez",
  "Kelly",
  "Howard",
  "Ward",
  "Cox",
  "Diaz",
  "Richardson",
  "Wood",
  "Watson",
  "Brooks",
  "Bennett",
  "Gray",
  "James",
  "Reyes",
  "Cruz",
  "Hughes",
  "Price",
  "Myers",
  "Long",
  "Foster",
  "Sanders",
  "Ross",
  "Morales",
  "Powell",
  "Sullivan",
  "Russell",
  "Ortiz",
  "Jenkins",
  "Gutierrez",
  "Perry",
  "Butler",
  "Barnes",
  "Fisher",
  "Henderson",
  "Coleman",
  "Simmons",
  "Patterson",
  "Jordan",
  "Reynolds",
  "Hamilton",
  "Graham",
  "Kim",
  "Gonzales",
  "Alexander",
  "Ramos",
  "Wallace",
  "Griffin",
  "West",
  "Cole",
  "Hayes",
  "Chavez",
  "Gibson",
  "Bryant",
  "Ellis",
  "Stevens",
  "Murray",
  "Ford",
  "Marshall",
  "Owens",
  "McDonald",
  "Harrison",
  "Ruiz",
  "Kennedy",
  "Wells",
  "Alvarez",
  "Woods",
  "Mendoza",
  "Castillo",
  "Olson",
  "Webb",
  "Washington",
  "Tucker",
  "Freeman",
  "Burns",
  "Henry",
  "Vasquez",
  "Snyder",
  "Simpson",
  "Crawford",
  "Jimenez",
  "Porter",
  "Mason",
  "Shaw",
  "Gordon",
  "Wagner",
  "Hunter",
  "Romero",
  "Hicks",
  "Dixon",
  "Hunt",
  "Palmer",
  "Robertson",
  "Black",
  "Holmes",
  "Stone",
  "Meyer",
  "Boyd",
  "Mills",
  "Warren",
  "Fox",
  "Rose",
  "Rice",
  "Moreno",
  "Schmidt",
  "Patel",
  "Ferguson",
  "Nichols",
  "Herrera",
  "Medina",
  "Ryan",
  "Fernandez",
  "Weaver",
  "Daniels",
  "Stephens",
  "Gardner",
  "Payne",
  "Kelley",
  "Dunn",
  "Pierce",
  "Arnold",
  "Tran",
  "Spencer",
  "Peters",
  "Hawkins",
  "Grant",
  "Hansen",
  "Castro",
]

export const firstNames = [...maleNames, ...femaleNames]

export const randomName = () => {
  const firstName = firstNames[Math.floor(Math.random() * firstNames.length)]
  const lastName = lastNames[Math.floor(Math.random() * lastNames.length)]
  const fullName = `${firstName} ${lastName}`
  return { firstName, lastName, fullName }
}

export const renderWithTypography = <T extends unknown>(
  wrapText: (t: string) => ReactNode,
  context: T,
  renderContext: undefined | string | ((props: T) => ReactNode)
) => {
  const rendered = typeof renderContext === "string" ? renderContext : renderContext?.(context)
  return typeof rendered === "string" ? wrapText(rendered) : rendered
}

export const rowClassName = <T extends object>(
  isHovered: boolean,
  isPaginationEnabled: boolean,
  { variant = DEFAULT_VARIANT, onRowClick }: Pick<TableProps<T>, "onRowClick" | "variant">
) =>
  classNames(
    `border-b ${isPaginationEnabled ? "" : "last:border-b-0"} `,
    variant === "stripe"
      ? isHovered
        ? "bg-neutral-200"
        : "even:bg-neutral-200 odd:bg-neutral-white"
      : variant === "grid"
      ? "bg-neutral-white"
      : assertUnreachable(variant),
    !!onRowClick && "cursor-pointer"
  )
