import { Cell } from "@tanstack/react-table"
import { assertUnreachable } from "common/utils/fp/Function"
import { useMemo, useState } from "react"
import { classNames } from "src/utils/classNames"
import Typography, { Size } from "../Typography/Typography"
import { TableProps } from "./Table"
import { renderWithTypography } from "./helpers"
import { HoveredColumnProps, TableVariant } from "./types"

type TableCellProps<T extends object> = Pick<TableProps<T>, "isFirstColumnSticky" | "textSize"> & {
  variant: TableVariant
  cell: Cell<T, unknown>
  idx: number
  isEven: boolean
  indexesOfStartOfGroupColumn: {
    indexes: number[]
    lastHeader: string | null | undefined
    pointer: number
  }
  isRowHovered: boolean
  colSpan?: number
} & HoveredColumnProps

const TableCell = <T extends object>({
  cell,
  idx,
  isEven,
  isFirstColumnSticky,
  indexesOfStartOfGroupColumn,
  variant,
  isRowHovered,
  colSpan,
  textSize,
  hoveredColumn,
  setHoveredColumn,
}: TableCellProps<T>) => {
  const isStartOfGroup = indexesOfStartOfGroupColumn.indexes.includes(idx)

  const bgClassName = useMemo(() => {
    const isColumnHovered = hoveredColumn === cell.column.id

    switch (variant) {
      case "stripe": {
        if (isColumnHovered) {
          return isEven ? "bg-neutral-200" : "bg-neutral-400"
        }
        if (isRowHovered) {
          return isEven ? "bg-neutral-200" : "bg-neutral-400"
        }

        return isEven ? "bg-neutral-white" : "bg-neutral-300"
      }
      case "grid": {
        return isColumnHovered ? "bg-neutral-200" : "bg-neutral-white"
      }
      default: {
        return assertUnreachable(variant)
      }
    }
  }, [cell.column.id, hoveredColumn, isEven, isRowHovered, variant])

  const firstColStickyStyle =
    idx === 0 && isFirstColumnSticky && classNames("sticky top-0 left-0 z-10 border-r", bgClassName)

  const [isHovered, setIsHovered] = useState(false)
  const { maxSize } = cell.getContext().column.columnDef

  const showOverflow = maxSize && isHovered
  return (
    <td
      key={cell.id}
      colSpan={colSpan ?? 1}
      className={classNames(
        "relative whitespace-nowrap px-2 py-0 transition-colors",
        isStartOfGroup && "border-l",
        variant === "grid" && "border-r last:border-r-0",
        firstColStickyStyle,
        bgClassName
      )}
      onMouseEnter={() => {
        if (maxSize) {
          setIsHovered(true)
        }
        setHoveredColumn?.(cell.column.id)
      }}
      onMouseLeave={() => maxSize && setIsHovered(false)}
      style={{ maxWidth: maxSize && `${maxSize}px` }}
    >
      {showOverflow ? (
        <div
          className={classNames(
            "whitespace-nowrap z-50 absolute top-0 bottom-0 left-0 px-2 py-px",
            variant === "grid" && "border-r",
            bgClassName
          )}
          style={{ minWidth: "calc(100% + 1px)" }}
        >
          <CellWithTypography cell={cell} textSize={textSize} />
        </div>
      ) : null}
      <div className="whitespace-nowrap overflow-x-hidden">
        <CellWithTypography cell={cell} textSize={textSize} />
      </div>
    </td>
  )
}

const CellWithTypography = <T extends object>({
  cell,
  textSize = Size.Small,
}: Pick<TableCellProps<T>, "cell" | "textSize">) =>
  renderWithTypography(
    (text) => <Typography size={textSize} text={text} shouldWrap={false} />,
    cell.getContext(),
    cell.column.columnDef.cell
  )

export default TableCell
