import { keys, pick } from "lodash"
import {
  updateOrderFromForm,
  createOrderFormFieldsFromOrder,
} from "../Order/OrderForm/Constructors"
import { Order } from "../Order/Order"
import { objectDifferenceByProps } from "../../utils/ObjectUtils"
import { validateFormOrder } from "../Order/OrderForm/Validation"
import { FormOrder } from "../Order/OrderForm/State"

export type ThreadOverlayData = { tag: "order"; orderOverlay: ThreadOrderOverlay }

export type ThreadOrderOverlay = Partial<FormOrder>

export const overlayOrderData = (overlay: ThreadOrderOverlay | undefined, order: Order): Order =>
  validateFormOrder({ ...createOrderFormFieldsFromOrder(order), ...overlay }).match(
    () => order,
    (updatedOrder) => updateOrderFromForm(updatedOrder, order, {})
  )

export const createOverlayOrderFromOrder = ({
  formOrder,
  order,
  previousOrderOverlay,
}: {
  formOrder: Partial<FormOrder>
  order: Order
  previousOrderOverlay: ThreadOrderOverlay | undefined
}) => {
  const initialFormOrder = createOrderFormFieldsFromOrder(order)

  const previousChangesThatMayHaveBeenResetToInitialOrder = pick(
    formOrder,
    keys(previousOrderOverlay)
  )
  return {
    ...previousChangesThatMayHaveBeenResetToInitialOrder,
    ...objectDifferenceByProps(formOrder, initialFormOrder),
  }
}

export const createPreviousOverlayOrderFromOrder = ({
  formOrder,
  order,
  previousOrderOverlay,
}: {
  formOrder: Partial<FormOrder>
  order: Order
  previousOrderOverlay: ThreadOrderOverlay | undefined
}) => {
  const previousOrderWithPreviousOverlayApplied: Partial<FormOrder> =
    createOrderFormFieldsFromOrder(overlayOrderData(previousOrderOverlay, order))
  return {
    ...previousOrderOverlay,
    ...objectDifferenceByProps(previousOrderWithPreviousOverlayApplied, formOrder),
  }
}
