import { uniqueByRep } from "../../../utils/data/Array/ArrayUtils"
import { AssociationList_ } from "../../../utils/data/AssociationList"
import { arrayField, ReifiedSimpleTraversal } from "../../../utils/fp/optics/Traversal"
import { groupBy } from "../../../utils/MapUtils"
import { Rec, UnsafeRec } from "../../../utils/RecordUtils"
import { emptyOrderTerms } from "../OrderForm/Constructors"
import { deriveOrderFields } from "../Types/DerivedFields"
import { ConstructedOrder } from "../Order"
import { OrderQuantityTerms, StructureOrderTerms } from "../Types/Terms"
import * as Internal from "./Internal"
import { isNonempty, Nonempty } from "../../../utils/data/Array/Nonempty"
import { isHiddenOutlierResult } from "../../data-product/DataPoint"
import { nullableToMaybe } from "../../../containers/Maybe"
import { RecordInfo } from "../../RecordInfo"
import { reformatOrderTermsV1ToV2 } from "./V2/Conversion"
import { HashMap } from "../../../containers/HashMap"

export type OrderBaseData = Internal.OrderBaseData
export type OrderUpdate<T extends keyof StructureOrderTerms> = T extends unknown
  ? {
      updateDate: Date
      structure: T
      quantityTerms: OrderQuantityTerms
      structureTerms: StructureOrderTerms[T]
    }
  : never
const orderUpdateHasStructure = <K extends keyof StructureOrderTerms>(
  k: K,
  u: OrderUpdate<keyof StructureOrderTerms>
): u is OrderUpdate<K> => u.structure === k
export type AtomicOrderUpdate = OrderUpdate<keyof StructureOrderTerms> // todo

/** exported for tests, do not use elsewhere */
export const unpackOrderUpdates = (o: ConstructedOrder): AtomicOrderUpdate[] =>
  reformatOrderTermsV1ToV2(o)
    .flatMap((t) =>
      t.quantityTerms
        ? [
            {
              quantityTerms: t.quantityTerms,
              structure: t.tag,
              structureTerms: t.structureTerms,
              updateDate: new Date(t.timestamp),
            } as AtomicOrderUpdate,
          ]
        : []
    )
    .sort((l, r) => l.updateDate.valueOf() - r.updateDate.valueOf())

const repackOrderUpdatesWithStructure = <K extends keyof StructureOrderTerms>(
  k: K,
  o: Order
): Internal.Order["terms"][K] =>
  AssociationList_.fromMap(
    new HashMap(
      groupBy(
        (v) => v.structureTerms,
        o.orderUpdates.flatMap((u) => (orderUpdateHasStructure(k, u) ? [u] : []))
      )
    ).map((us) =>
      UnsafeRec.fromEntries(
        new HashMap(groupBy((u) => u.updateDate.valueOf(), us)).map((vs) => vs[0].quantityTerms)
      )
    )
  ) as Internal.Order["terms"][K]

const repackOrderUpdates = (o: Order): Internal.Order["terms"] =>
  Rec.indexedMap(emptyOrderTerms, ([k, v]) =>
    repackOrderUpdatesWithStructure(k, o)
  ) as Internal.Order["terms"]

export type Order = RecordInfo & {
  base: OrderBaseData
  orderUpdates: AtomicOrderUpdate[]
}

/** Do not construct this directly */
export type OrderWithData = RecordInfo & {
  base: OrderBaseData
  /** Must also not all be outliers */
  orderUpdates: Nonempty<AtomicOrderUpdate>
}
export const orderHasData = (x: Order): x is OrderWithData => {
  const isOutlierTerm = (id: string) =>
    nullableToMaybe(x.base.outlierStatus?.outlierSummary?.terms[id])
      .map(isHiddenOutlierResult)
      .withDefault(false)
  return (
    isNonempty(x.orderUpdates) &&
    x.orderUpdates.some((u) => !isOutlierTerm(u.quantityTerms.id ?? "xyz123"))
  )
}
export const orderHasDataIncludingOutliers = (x: Order): x is OrderWithData =>
  isNonempty(x.orderUpdates)

export const orderFromInternal = (o: Internal.Order): Order => ({
  base: Internal.viewOrderBaseData(o),
  orderUpdates: unpackOrderUpdates(o),
  createdAt: o.createdAt,
  updatedAt: o.updatedAt,
})
export const orderToInternal = (o: Order): Internal.Order =>
  deriveOrderFields({
    ...o.base,
    terms: repackOrderUpdates(o),
    updatedAt: o.updatedAt,
    orderUpdatedAt: uniqueByRep((d: Date) => d.valueOf())(
      o.orderUpdates.map((u) => u.updateDate)
    ).sort((l, r) => l.valueOf() - r.valueOf()),
    createdAt: o.createdAt,
  })

export const orderUpdates: ReifiedSimpleTraversal<
  Order,
  OrderUpdate<keyof StructureOrderTerms>
> = arrayField("orderUpdates")()

export const orderLastUpdate = (o: Order): AtomicOrderUpdate =>
  o.orderUpdates.reduce((a, b) => (a.updateDate > b.updateDate ? a : b))
