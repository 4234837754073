import { Select } from "@stories/components/Antd/Select/Select"
import Typography from "@stories/components/Typography/Typography"
import {
  AccountTradingRegistration,
  MinimumAccountTradingRegistration,
} from "common/model/Account/AccountTradingRegistration"

export const TradingRegistrationTypeSelection = ({
  tradingRegistrationType,
  updateTradingRegistration,
}: {
  updateTradingRegistration: (t: MinimumAccountTradingRegistration["type"]) => void
  tradingRegistrationType: MinimumAccountTradingRegistration["type"] | null
}) => (
  <div className="flex flex-col gap-4">
    <Typography text="Which of the following best describes your account?" />
    <Select<AccountTradingRegistration["type"]>
      value={tradingRegistrationType}
      options={[
        { label: "Individual", value: "individual-investor" },
        { label: "Fund or Institutional Investor", value: "fund" },
        { label: "Broker/Advisor", value: "broker" },
      ]}
      onChange={updateTradingRegistration}
    />
  </div>
)
