import { Card, UploadFile } from "@stories/components/Antd"
import { Button } from "@stories/components/Button/Button"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import {
  AccountTradingRegistration,
  MinimumAccountTradingRegistration,
} from "common/model/Account/AccountTradingRegistration"
import { useState } from "react"
import { useFirebaseWriter } from "src/firebase/Context"
import { FileUtils } from "src/utils/FileUtils"
import { VerificationDocumentsUpload } from "./VerificationUploader"
import { Loading, isLoaded, matchLoading } from "common/utils/Loading"
import { generateSubmittedDocument } from "src/firebase/Firebase/data-product/DocumentSubmission"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import { handleConsoleError } from "src/utils/Tracking"
import { assertUnreachable } from "common/utils/fp/Function"
import { CheckCircleIcon } from "@stories/icons/CheckCircleIcon"
import { InviteColleagueToTradingRegistrationButton } from "../InviteColleague"
import { CurrentUser } from "@model/CurrentUser"
import { FirebaseWriter } from "src/firebase/Firebase"

const documentsNeededText = (
  tradingRegistration: Loading<
    Partial<AccountTradingRegistration> & MinimumAccountTradingRegistration
  >
): { docName: string; docSubtitle?: string[] }[] =>
  ({
    broker: [{ docName: "Government Identification (State Drivers License or Passport)" }],
    "individual-investor": [{ docName: "Photo ID (State Drivers License or Passport)" }],
    fund: [
      {
        docName: `Photo ID of an authorized signatory${matchLoading(
          tradingRegistration,
          (t) => ` for ${t.account.name}`,
          "",
          ""
        )}`,
        docSubtitle: ["e.g. State Drivers License or Passport"],
      },
      {
        docName: `Organizational Documents${matchLoading(
          tradingRegistration,
          (t) => (t.type === "fund" && t.fundName ? ` for ${t.fundName ?? ""}` : ""),
          "",
          ""
        )}`,
        docSubtitle: ["e.g. Articles of Incorporation, Partnership Agreements, etc."],
      },
    ],
  }[matchLoading(tradingRegistration, (t) => t.type, "broker", "broker")])

export const DocumentsNeededList = ({
  tradingRegistration,
  title,
}: {
  tradingRegistration: Loading<
    Partial<AccountTradingRegistration> & MinimumAccountTradingRegistration
  >
  title: string
}) => (
  <div className="flex flex-col gap-2">
    <Typography text={title} weight={Weight.Semibold} />
    {documentsNeededText(tradingRegistration).map((doc) => (
      <div className="flex flex-col gap-2" key={doc.docName}>
        <div className="flex items-center gap-2">
          <div className="w-2 h-2 bg-neutral-900 rounded-full" />
          <Typography text={doc.docName} size={Size.Small} />
        </div>
        <div className="ml-4">
          {doc.docSubtitle?.map((d) => (
            <div className="flex items-center gap-2" key={d}>
              <div className="w-2 h-2 bg-neutral-900 rounded-full" />
              <Typography text={d} size={Size.Small} />
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
)

const DocumentUpload = ({
  docName,
  uploaded,
  setUploaded,
}: {
  docName: string
  uploaded: UploadFile[]
  setUploaded: (files: UploadFile[]) => void
}) => {
  const [uploadedFileList, setUploadedFileList] = useState<UploadFile[]>([])
  const handleOnChangeFiles = (files: UploadFile[]) => {
    setUploadedFileList(files)
    const fileNames = files.map((f) => f.name)
    setUploaded([...uploaded.filter((f) => !fileNames.includes(f.name)), ...files])
  }
  return (
    <>
      <Typography text={docName} weight={Weight.Semibold} />
      <VerificationDocumentsUpload files={uploadedFileList} onChangeFiles={handleOnChangeFiles} />
    </>
  )
}

export const onUpload = (
  db: FirebaseWriter,
  uploadedFileList: UploadFile[],
  tradingRegistration: Loading<MinimumAccountTradingRegistration>,
  currentUser: Loading<CurrentUser>,
  onSuccess: () => void,
  onFail: () => void
) => {
  uploadedFileList.forEach((upload) => {
    if (
      upload.originFileObj === undefined ||
      !(isLoaded(tradingRegistration) && tradingRegistration.id)
    )
      return
    const path = isLoaded(currentUser)
      ? FileUtils.folders.verificationDocs(currentUser.user, tradingRegistration.id)
      : FileUtils.folders.unauthedVerificationDocs(tradingRegistration.id)
    db.uploadFile(upload.originFileObj, path, onSuccess, onFail)
  })
}
export const onUploadSuccess = async (
  uploadedFileList: UploadFile[],
  tradingRegistration: Loading<MinimumAccountTradingRegistration>,
  currentUser: Loading<CurrentUser>,
  updateTradingRegistration: (
    t: Partial<AccountTradingRegistration> & MinimumAccountTradingRegistration
  ) => Promise<void>
) => {
  if (isLoaded(tradingRegistration) && tradingRegistration.id) {
    const newVerificationDocs = uploadedFileList.flatMap((upload) => {
      if (upload.originFileObj === undefined) return []
      const fileName = upload.originFileObj.name
      const path = isLoaded(currentUser)
        ? FileUtils.folders.verificationDocs(currentUser.user, tradingRegistration.id)
        : FileUtils.folders.unauthedVerificationDocs(tradingRegistration.id)
      return [generateSubmittedDocument(`${path}/${fileName}`, new Date(), "verification-docs")]
    })
    return updateTradingRegistration({
      ...tradingRegistration,
      verificationDocuments: [
        ...(tradingRegistration.verificationDocuments ?? []),
        ...newVerificationDocs,
      ],
    })
  }
  return Promise.resolve()
}

export const VerificationDocumentsPage = ({
  tradingRegistration,
  updateTradingRegistration,
}: {
  tradingRegistration: Loading<
    Partial<AccountTradingRegistration> & MinimumAccountTradingRegistration
  >
  updateTradingRegistration: (
    t: Partial<AccountTradingRegistration> & MinimumAccountTradingRegistration
  ) => Promise<void>
}) => {
  const [status, setStatus] = useState<"fileUpload" | "uploadedSuccess" | "uploadError">(
    "fileUpload"
  )
  const [uploadedFileList, setUploadedFileList] = useState<UploadFile[]>([])
  const currentUser = useCurrentUser()
  const db = useFirebaseWriter()

  const handleIdentificationSubmission = () => {
    onUpload(
      db,
      uploadedFileList,
      tradingRegistration,
      currentUser,
      () => setStatus("uploadedSuccess"),
      () => setStatus("uploadError")
    )
  }

  const handleUploadSuccess = () => {
    onUploadSuccess(
      uploadedFileList,
      tradingRegistration,
      currentUser,
      updateTradingRegistration
    ).catch(handleConsoleError)
  }

  if (!isLoaded(tradingRegistration)) return null

  if (status === "fileUpload") {
    return (
      <div className="flex flex-col gap-4">
        <DocumentsNeededList
          title="Caplight requires the following documents as part of our ATS Trading Registration."
          tradingRegistration={tradingRegistration}
        />
        {tradingRegistration.type === "fund" ? (
          <Card size="small">
            <div className="flex flex-col gap-2">
              <Typography
                size={Size.XSmall}
                text="If you do not have the required documents, you may invite a colleague to upload these documents on your behalf."
              />
              <InviteColleagueToTradingRegistrationButton
                tradingRegistration={tradingRegistration}
                renderButton={(onClick) => (
                  <div>
                    <Button
                      onClick={onClick}
                      variant="secondary"
                      label="Invite Colleague to Submit Documents"
                      size="small"
                    />
                  </div>
                )}
              />
            </div>
          </Card>
        ) : null}
        {documentsNeededText(tradingRegistration).map((doc) => (
          <DocumentUpload
            key={doc.docName}
            docName={doc.docName}
            uploaded={uploadedFileList}
            setUploaded={setUploadedFileList}
          />
        ))}
        <Button
          isFullWidth
          label="Continue"
          onClick={handleIdentificationSubmission}
          isDisabled={uploadedFileList.length < documentsNeededText(tradingRegistration).length}
        />
        <div className="flex justify-center">
          <Typography
            size={Size.Small}
            color={Color.Subtitle}
            text="Please contact us at platform@caplight.com with any questions."
          />
        </div>
      </div>
    )
  }

  if (status === "uploadedSuccess") {
    return (
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-2">
          <CheckCircleIcon color={Color.PrimarySecondary} />
          <Typography
            weight={Weight.Semibold}
            color={Color.PrimarySecondary}
            text="Documents uploaded successfully."
          />
        </div>
        <Typography text="Caplight will review and be in contact if further documentation is required." />
        <Button
          isFullWidth
          label="Continue"
          onClick={handleUploadSuccess}
        />
      </div>
    )
  }

  if (status === "uploadError") {
    return (
      <div className="flex flex-col gap-4">
        <Typography text="Something went wrong uploading your documents." />
        <Typography text="If you continue to experience issues, contact platform@caplight.com." />
      </div>
    )
  }
  return assertUnreachable(status)
}
