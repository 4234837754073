import { RadioSelect, SelectOptions } from "@stories/components/Inputs/RadioSelect/RadioSelect"
import { Company } from "common/model/Company"
import { PriceData } from "common/model/Order/OrderForm/State"
import { Interval } from "common/utils/data/Interval"
import { Either, Left, Right } from "common/containers/Either"
import { nullableToMaybe } from "common/containers/Maybe"
import { formatAbbreviatedNumber, formatPrice } from "common/utils/math/format"
import { useCompanyValuation } from "../../../data/Hooks/useCompanyValuation"
import { formatValuation } from "../../displays/numeric/Currency"
import {
  NumberField,
  valuationScaledInputWarning,
} from "@stories/components/Inputs/NumberInput/NumberField"
import { useMemo } from "react"
import { assertUnreachable } from "common/utils/fp/Function"
import { captureException } from "@sentry/react"
import Alert from "@stories/components/Alert/Alert"
import { InfoIcon } from "../../../stories/icons/InfoIcon"
import { useOrderForm } from "src/pages/Orders/OrderForm/OrderFormContext"

type Version = "valuation" | "pps" | "unpriced"
type EitherVersion = Either<"valuation", "pps">

export type PricePerShareProps = {
  value?: PriceData["price"]
  onChange: (price: PriceData["price"]) => void
  disabled?: boolean
  company?: Company
  titles: { orderPrice: string; orderPriceTooltip: string }
  showUnpriced?: boolean
}

export function PricePerShare({
  value,
  onChange,
  disabled,
  company,
  titles,
  showUnpriced = false,
}: PricePerShareProps) {
  const { isEditMode } = useOrderForm()
  const eitherVersion: EitherVersion =
    value?.targetValuation !== undefined ? Left("valuation") : Right("pps")

  const disableAddingPrice = isEditMode && value === null

  const handleRadioChange = (newVersion: Version) => {
    switch (newVersion) {
      case "unpriced": {
        if (!showUnpriced) {
          captureException(
            new Error(
              "User selected unpriced in order form when it should have been impossible to do so."
            ),
            { contexts: { props: { showUnpriced } } }
          )
          // This should never happen, but if it does, just kick them back to USDPerShare
          return onChange({ USDPerShare: null })
        } else {
          return onChange(null)
        }
      }
      case "pps": {
        return onChange({ USDPerShare: null })
      }
      case "valuation": {
        return onChange({ targetValuation: null })
      }
      default: {
        return assertUnreachable(newVersion)
      }
    }
  }

  const handleValuationChange = (targetValuation: number | null | undefined) => {
    if (targetValuation && targetValuation >= 0) {
      onChange({ targetValuation })
    } else {
      onChange({ targetValuation: null })
    }
  }

  const handlePPSChange = (nullablePPS: number | null | undefined) =>
    nullableToMaybe(nullablePPS).match(
      (pps) => pps >= 0 && onChange({ USDPerShare: Interval.pure(pps) }),
      () => onChange({ USDPerShare: null })
    )

  const options = useMemo(() => {
    const basicOpts: SelectOptions<"pps" | "valuation" | "unpriced">[] = [
      { value: "pps" as const, label: "Share Price" },
      { value: "valuation" as const, label: "Valuation" },
    ]
    // TODO: better UI hints for selecting unpriced
    return basicOpts.concat(
      showUnpriced
        ? [
            {
              value: "unpriced" as const,
              label: "Unpriced",
              tooltip:
                "Publish an unpriced indication of interest if you do not have a firm price.",
            },
          ]
        : []
    )
  }, [showUnpriced])

  return (
    <>
      <RadioSelect<Version>
        label={titles.orderPrice}
        options={options}
        value={value === null ? "unpriced" : eitherVersion.toUnion()}
        onChange={handleRadioChange}
        disabled={disabled || (isEditMode && disableAddingPrice)}
        tooltip={titles.orderPriceTooltip}
      />
      {value !== null ? (
        <>
          <div className="flex items-center">
            <div className="w-full sm:w-1/2 sm:pr-2">
              {eitherVersion.match(
                () => (
                  <NumberField
                    formatter={formatAbbreviatedNumber}
                    value={value?.targetValuation}
                    onChange={handleValuationChange}
                    isDisabled={disabled}
                    prefix="$"
                    placeholder="e.g. 2.2B"
                    inputWarningFunction={valuationScaledInputWarning}
                    tooltipHintText="Hint: try '5.2b' for $5.2 billion"
                  />
                ),
                () => (
                  <NumberField
                    formatter={formatAbbreviatedNumber}
                    prefix="$"
                    value={value?.USDPerShare?.lowerBound}
                    onChange={handlePPSChange}
                    isDisabled={disabled}
                    placeholder="e.g. 15.10"
                  />
                )
              )}
            </div>
          </div>

          {company ? (
            <EstimatedImpliedPriceOrValuation
              company={company}
              value={value ?? null}
              version={eitherVersion}
            />
          ) : null}
        </>
      ) : (
        <div className="w-full mt-2">
          <Alert
            variant="accent"
            icon={<InfoIcon />}
            headline="This will be considered a tentative indication of interest"
            message="Your indication will appear in the Live Market and can receive Connect requests. However, you will NOT be able to submit Connect requests using this unpriced indication."
          />
        </div>
      )}
    </>
  )
}

const EstimatedImpliedPriceOrValuation = ({
  company,
  version,
  value,
}: { company: Company; version: EitherVersion } & Required<Pick<PricePerShareProps, "value">>) => {
  const { valuationFromPPS } = useCompanyValuation(company)

  const numShares = valuationFromPPS({
    price: 1,
    priceDate: new Date(),
    isSplitAdjusted: false,
  }).match(
    (x) => x.adjustedShareCount,
    () => undefined
  )

  if (!numShares || !value)
    return (
      <div className="text-sm text-neutral-800 p-0 mt-2">
        {version.match(
          () => "Est. Price per Share: -",
          () => "Est. Valuation: -"
        )}
      </div>
    )
  return (
    <div className="text-sm text-neutral-800 p-0 mt-2">
      {version.match(
        () =>
          nullableToMaybe(value.targetValuation).match(
            (targetValuation) => {
              const pps = targetValuation / numShares
              return pps && pps > 0.001 ? `Est. Price per Share: ${formatPrice(pps)}` : null
            },
            () => "Est. Price per Share: -"
          ),
        () =>
          nullableToMaybe(value.USDPerShare).match(
            (pps) => `Est. Valuation: ${formatValuation(numShares * pps.lowerBound)}`,
            () => "Est. Valuation: -"
          )
      )}
    </div>
  )
}
