import { assertMutuallyAssignable } from "../../../utils/data/Type/Assertions"
import { Order } from "../Order"
import { orderLiveUntil, OrderStatusFields } from "./Status"

export const deriveOrderFields = <R extends OrderStatusFields>(
  o: R
): R & { _derived: Order["_derived"] } => ({
  ...o,
  _derived: { liveUntil: orderLiveUntil(o) },
})

assertMutuallyAssignable<keyof Order["_derived"], "liveUntil">() // Update this and eqDerivedOrderFields whenever a new derived field is added
export const eqDerivedOrderFields = (l: Order["_derived"], r: Order["_derived"]) =>
  l.liveUntil === r.liveUntil || l.liveUntil?.valueOf() === r.liveUntil?.valueOf()
