import { Popover, PopoverPanelProps } from "@headlessui/react"
import { Button } from "@stories/components/Button/Button"
import { ChevronDownIcon } from "@stories/icons/ChevronDownIcon"
import { FilterIcon } from "@stories/icons/FilterIcon"
import { GearIcon } from "@stories/icons/GearIcon"
import { WrappedIcon } from "@stories/icons/IconWrapper"
import { SortIcon } from "@stories/icons/SortIcon"
import { ElementType } from "react"

type FilterBarDropdownButtonVariant = "filter" | "sort" | "setting"
type FilterBarDropdownButtonProps<T extends ElementType> = {
  label: string
  variant: FilterBarDropdownButtonVariant
  children: PopoverPanelProps<T>["children"]
}

const variantToIcon: Record<FilterBarDropdownButtonVariant, WrappedIcon> = {
  filter: FilterIcon,
  sort: SortIcon,
  setting: GearIcon,
}

const FilterBarDropdownButton = <T extends ElementType>({
  label,
  variant,
  children,
}: FilterBarDropdownButtonProps<T>) => {
  const Icon = variantToIcon[variant]
  return (
    <Popover className="relative">
      <Popover.Button>
        <Button
          size="medium"
          variant="secondary"
          label={label}
          leftIcon={<Icon size="small" />}
          rightIcon={<ChevronDownIcon size="small" />}
          renderRawIcons
        />
      </Popover.Button>
      <Popover.Panel className="rounded inset-auto overflow-scroll absolute right-0 z-50 bg-neutral-white shadow-md border border-neutral-200 p-1">
        {children}
      </Popover.Panel>
    </Popover>
  )
}

export default FilterBarDropdownButton
