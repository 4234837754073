import _ from "lodash"
import { useEffect, useMemo, useState } from "react"

// Debounce a value 'obj' (typically a state or prop value)
// See https://lodash.com/docs/4.17.15#debounce

export const useDebounce = <T>(obj: T, wait: number = 1000) => {
  const [state, setState] = useState(obj)

  const debounce = useMemo(
    () =>
      _.debounce((value: T) => {
        setState(value)
      }, wait),
    [wait]
  )

  const setDebouncedState = useMemo(
    () => (value: T) => {
      debounce(value)
    },
    [debounce]
  )

  useEffect(() => {
    setDebouncedState(obj)
  }, [obj, setDebouncedState])

  return state
}
