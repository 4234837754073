import { ProductArea } from "common/model/Auth/Permissions"
import {
  areaAccessConditions,
  areaNavConditions,
} from "../../components/auth/conditions/ProductAreaConditions"
import { AuthCondition } from "./AuthCondition"
import { UserAuthContext } from "./UserAuthContext"

export interface AuthRegion {
  /** adding `navConditions` is a quick patch to make it easier to specify when navbar links for inaccessible pages should be visible, but the long term solution here is to split our current auth logic into AuthContext -> AuthResult and domain-specific AuthResult -> T handlers */
  navConditions?: AuthCondition<UserAuthContext, UserAuthContext>[]
  authConditions?: AuthCondition<UserAuthContext, UserAuthContext>[]
  area?: ProductArea
  requireToS?: boolean
}

export const flattenAuthProps = ({
  authConditions,
  area,
}: AuthRegion): AuthCondition<UserAuthContext, UserAuthContext>[] =>
  (authConditions || []).concat(areaAccessConditions(area))

export const flattenAuthPropsNav = ({
  navConditions,
  authConditions,
  area,
}: AuthRegion): AuthCondition<UserAuthContext, UserAuthContext>[] =>
  (navConditions ?? authConditions ?? []).concat(areaNavConditions(area))
