import OrderVisibilityPill from "@stories/components/Badges/OrderVisibilityPill/OrderVisibilityPill"
import { DealCRMInterest } from "common/model/DealCRM/DealCRMInterest"
import { OrderIdFields } from "common/model/Order/Order"
import { orderLiveUntil } from "common/model/Order/Types/Status"
import { StatusCell } from "src/pages/Orders/MyOrders/MyOrdersRowCells"
import { getMyOrderStatus, getMyOrderVisibility } from "src/pages/Orders/MyOrders/utils"
import {
  PublishCRMInterestButtonMenu,
  PublishCRMInterestButtonMenuProps,
} from "./InterestActionDropdownMenu/PublishedCRMInterestButtonMenu"
import { useGenericOrders } from "src/providers/data/OrderProvider"
import { defaultIfLoading, matchLoading } from "common/utils/Loading"
import { useDocuments } from "src/firebase/Firestore"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { useFirebaseWriter } from "src/firebase/Context"
import { SharedOrder } from "common/model/SharedOrder/SharedOrder"

const VisibilityStatusWithPublishedOrder = ({
  interest,
  sharedOrders,
  size,
}: {
  interest: DealCRMInterest & { publishedOrder: OrderIdFields }
  sharedOrders: SharedOrder[]
  size: "sm" | "md"
}) => {
  const loadingOrder = useGenericOrders([interest.publishedOrder])
  return (
    <>
      <OrderVisibilityPill
        variant={getMyOrderVisibility(interest.publishedOrder, sharedOrders)}
        size={size}
      />
      {matchLoading(
        loadingOrder,
        ([order]) =>
          order ? (
            <StatusCell orderStatus={getMyOrderStatus(order)} liveUntil={orderLiveUntil(order)} />
          ) : null,
        null,
        null
      )}
    </>
  )
}

const InterestVisibilityStatus = ({
  interest,
  isPublishButtonDisplayed = true,
  size = "md",
}: PublishCRMInterestButtonMenuProps) => {
  const { user } = useLoggedInUser()
  const firebase = useFirebaseWriter()
  const sharedOrders = useDocuments<SharedOrder>(
    firebase.getSharedOrdersFromSharableItem(
      { tag: "crm_interest", crmInterest: interest, id: interest.id },
      user.account
    ),
    [interest, user.account]
  )
  return (
    <div className="flex space-x-2 items-center whitespace-nowrap">
      {interest.publishedOrder ? (
        <VisibilityStatusWithPublishedOrder
          interest={{ ...interest, publishedOrder: interest.publishedOrder }}
          sharedOrders={defaultIfLoading(sharedOrders, [])}
          size={size}
        />
      ) : (
        <>
          <OrderVisibilityPill
            variant={defaultIfLoading(sharedOrders, []).length ? "Shared" : "Private"}
            size={size}
          />
          {isPublishButtonDisplayed && (
            <PublishCRMInterestButtonMenu interest={interest} variant="hollow-link" align="start" />
          )}
        </>
      )}
    </div>
  )
}

export default InterestVisibilityStatus
