import { Dropdown } from "@components/inputs/Dropdown"
import { SelectorOption } from "@components/inputs/selectors"
import { PageTitle } from "@components/typography/PageTitle"
import { Subtitle } from "@components/typography/Subtitle"
import { CurrentUser } from "@model/CurrentUser"
import { Checkbox, notification } from "@stories/components/Antd"
import { Button } from "@stories/components/Button/Button"
import { Tabs, useURLTab } from "@stories/components/Tabs/Tabs"
import Typography, { Color, Size } from "@stories/components/Typography/Typography"
import { collections } from "common/firestore/Collections"
import { isIntermediaryAccount } from "common/model/Account"
import { OrderDirection } from "common/model/Order/Order"
import { User } from "common/model/User"
import {
  NotificationPreference,
  NotificationSetting,
  UserNotificationPreferences,
  notificationFrequencies,
  userNotificationPreferencesDisplaySettings,
} from "common/model/UserNotificationPreferences"
import { isLoading } from "common/utils/Loading"
import { capitalize, debounce } from "lodash"
import { Routes } from "src/Routes/Routes"
import { sendPlatformEventMessage } from "src/firebase/API"
import { useFirebaseWriter } from "src/firebase/Context"
import { FirebaseWriter } from "src/firebase/Firebase"
import { useErrorHandler } from "src/providers/errorHandler"
import { ErrorHandler } from "src/providers/errorHandler/context"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { trackEvent } from "src/utils/Tracking"
import { useAccountBrokerLinking } from "src/utils/useAccountBrokerLinking"
import { useViewport } from "src/utils/useViewport"
import { useCurrentAccount } from "../../queries/currentUser/useCurrentAccount"
import { TopBrokerTracking } from "../TopBroker/TopBrokerTracking/TopBrokerTracking"
import { BrokerPage } from "./BrokerLinkingPage"
import { useFeatureFlag } from "src/providers/featureFlags/useFeatureFlags"
import { useMemo } from "react"
import { EmailIntegrationSettingsPage } from "./EmailIntegrationSettingsPage"
import { TrustedBrokerFullSettingsPage } from "./TrustedBrokerManagement/TrustedBrokerFullSettingsPage"

const settingsTabLabels = ["notification", "broker", "topBroker"] as const
type SettingsTab = (typeof settingsTabLabels)[number]

export const UserSettingsRoutes: Record<SettingsTab, string> = {
  notification: `${Routes.account}?tab=notification`,
  broker: `${Routes.account}?tab=broker`,
  topBroker: `${Routes.account}?tab=topBroker`,
}
export const updateWatchlistSettings = (
  currentUser: CurrentUser,
  existingSettings: User["watchlistSettings"]["realtimeOrderNotifications"],
  firebase: FirebaseWriter,
  direction: OrderDirection,
  isEnabled: boolean,
  handleError: ErrorHandler
) => {
  const { user } = currentUser || {}
  const currentBuyValue = existingSettings?.includes("buy") ? "buy" : undefined
  const currentSellValue = existingSettings?.includes("sell") ? "sell" : undefined

  const newSettings: User["watchlistSettings"] = {
    ...user.watchlistSettings,
    eventTypes: user.watchlistSettings.eventTypes,
    realtimeOrderNotifications: [
      direction === "buy" ? (isEnabled ? direction : undefined) : currentBuyValue,
      direction === "sell" ? (isEnabled ? direction : undefined) : currentSellValue,
    ].filter((d): d is OrderDirection => d !== undefined),
  }

  const updates: Partial<User> = {
    watchlistSettings: newSettings,
  }

  firebase.db
    .collection(collections.users)
    .doc(user.id)
    .update(updates)
    .then(() =>
      notification.success({
        message: <div className="whitespace-nowrap">Email notification preferences updated</div>,
        closeIcon: <></>,
        placement: "top",
        duration: 2,
      })
    )
    .then(() =>
      trackEvent("watchlist-notification-settings-updated", {
        eventTypes: newSettings.eventTypes.join(","),
        realtimeOrderNotifications: newSettings.realtimeOrderNotifications.join(","),
        bidNotificationsEnabled: newSettings.realtimeOrderNotifications.includes("buy"),
        offerNotificationsEnabled: newSettings.realtimeOrderNotifications.includes("sell"),
      })
    )
    .catch(handleError)
}

export const NotificationPage = ({ modalEmbed }: { modalEmbed?: boolean }) => {
  const isCRMEnabled = useFeatureFlag("crm")
  const firebase = useFirebaseWriter()
  const currentUser = useLoggedInUser()
  const { user } = currentUser || {}
  const { handleError } = useErrorHandler()
  const { notificationPreferences } = user

  const writeNewNotificationPreferences = (updates: Partial<User>) => {
    firebase.db
      .collection(collections.users)
      .doc(user.id)
      .update(updates)
      .then(() =>
        notification.success({
          message: "Email notification preferences updated",
          placement: "top",
        })
      )
      .then(() => trackEvent("user-notification-settings-updated"))
      .then(() =>
        sendPlatformEventMessage(
          [
            `${user.email} (${user.firstName} ${user.lastName}) changed their email notification preferences:`,
            `* RFQ emails: ${
              updates.notificationPreferences?.rfq_emails || user.notificationPreferences.rfq_emails
            }`,
            `* General marketing communications: ${
              updates.notificationPreferences?.general_marketing_communications ||
              user.notificationPreferences.general_marketing_communications
            }`,
            `* Product announcements: ${
              updates.notificationPreferences?.product_announcement_emails ||
              user.notificationPreferences.product_announcement_emails
            }`,
            `* Blog and Insights: ${
              updates.notificationPreferences?.blog_and_insights_emails ||
              user.notificationPreferences.blog_and_insights_emails
            }`,
          ].join("\n"),
          currentUser
        )
      )
      .catch(handleError)
  }

  const handleUserNotificationSettingsChange = (
    preference: NotificationPreference,
    newValue: NotificationSetting
  ) => {
    const updates: Partial<User> = {
      notificationPreferences: {
        ...notificationPreferences,
        [preference]: newValue,
      },
    }

    writeNewNotificationPreferences(updates)
  }

  const debouncedWatchlistSettingUpdate = debounce(updateWatchlistSettings, 500)

  const dropdownOptions: SelectorOption[] = notificationFrequencies.map((setting) => ({
    id: setting,
    name: capitalize(setting),
  }))

  const unsubscribeFromAll = () => {
    const newPreferences: UserNotificationPreferences = {
      product_announcement_emails: "never",
      blog_and_insights_emails: "never",
      general_marketing_communications: "never",
      rfq_emails: "never",
      digest_emails: "never",
      new_indications_emails: "never",
      platform_notification_emails: "never",
      crm_notes: "never",
    }

    const updates: Partial<User> = {
      notificationPreferences: {
        ...newPreferences,
      },
      watchlistSettings: {
        eventTypes: [],
        realtimeOrderNotifications: [],
      },
    }

    writeNewNotificationPreferences(updates)
  }

  const unsubscribeFromAllButton = (
    <Button variant="secondary" label="Unsubscribe from all" onClick={unsubscribeFromAll} />
  )

  const directions: OrderDirection[] = ["buy", "sell"]

  const watchlistBidAskNotificationDisplaySettings: {
    [key in OrderDirection]: { label: string; description: string }
  } = {
    buy: {
      label: "New bids (buy orders)",
      description: "Receive notifications when a bid is placed on a watchlist company",
    },
    sell: {
      label: "New offers (sell orders)",
      description: "Receive notifications when an ask is placed on a watchlist company",
    },
  }

  const watchlistSettings = (
    <div className="mt-4">
      {" "}
      <Subtitle subtitle="Watchlist Notifications" />
      <div>
        {directions.map((direction: OrderDirection) => {
          const currentValue =
            user.watchlistSettings?.realtimeOrderNotifications?.includes(direction)
          const { label, description } = watchlistBidAskNotificationDisplaySettings[direction]

          return (
            <div className="my-4 flex items-start" key={direction}>
              <Checkbox
                id={direction}
                checked={currentValue}
                onChange={() => {
                  debouncedWatchlistSettingUpdate(
                    currentUser,
                    currentUser.user.watchlistSettings?.realtimeOrderNotifications,
                    firebase,
                    direction,
                    !currentValue,
                    handleError
                  )
                }}
              />
              <div className="flex-col ml-4 mb-4 w-3/4">
                <label htmlFor={label} className="font-semibold">
                  {label}
                </label>
                <p className="text-sm mt-2">{description}</p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )

  const notificationSettingsForm = userNotificationPreferencesDisplaySettings.map((setting) => {
    if (!isCRMEnabled && setting.notificationType === "crm_notes") {
      return null
    }
    const { notificationType } = setting
    const currentSetting = notificationPreferences[notificationType]

    const { label, shouldUseFrequencyOptions, description } = setting

    if (shouldUseFrequencyOptions) {
      return (
        <div className="my-2 flex items-start max-w-full" key={notificationType}>
          <Checkbox
            id={notificationType}
            checked={currentSetting !== "never"}
            onChange={(newSetting) =>
              handleUserNotificationSettingsChange(
                notificationType,
                newSetting.target.checked ? "weekly" : "never"
              )
            }
          />
          <div className="flex-col ml-4 mb-4 flex-1">
            <label htmlFor={label} className="font-semibold">
              {label}
            </label>
            <p className="text-sm mt-2">{description}</p>
          </div>
          {currentSetting !== "never" && (
            <Dropdown
              label=""
              minWidthPx={155}
              className="mr-2"
              selected={{ id: currentSetting, name: capitalize(currentSetting) }}
              setSelected={(newSetting) =>
                handleUserNotificationSettingsChange(
                  notificationType,
                  newSetting.id as NotificationSetting
                )
              }
              options={dropdownOptions}
            />
          )}
        </div>
      )
    }

    return (
      <div key={notificationType} className="flex items-start">
        <Checkbox
          id={notificationType}
          checked={currentSetting === "enabled"}
          onChange={(newSetting) =>
            handleUserNotificationSettingsChange(
              notificationType,
              newSetting.target.checked ? "enabled" : "never"
            )
          }
        />
        <div className="flex-col ml-4 mb-4 w-3/4">
          <label htmlFor={label} className="font-semibold mb-4">
            {label}
          </label>
          <p className="text-sm mt-2">{description}</p>
        </div>
      </div>
    )
  })

  if (modalEmbed) {
    return (
      <div>
        <p className="mt-4 border-b pb-4">
          <Typography
            color={Color.Subtitle}
            size={Size.Small}
            text="Select the emails you'd like to receive"
          />
        </p>
        <div className="mt-4">{notificationSettingsForm}</div>
        {watchlistSettings}
        <div className="mt-4">{unsubscribeFromAllButton}</div>
      </div>
    )
  }

  return (
    <div className="shadow-md bg-neutral-white border-neutral-400 my-4 p-8 rounded-md w-full">
      <PageTitle title="Email Notifications" />
      <p className="mt-4 border-b pb-4">
        <Typography
          color={Color.Subtitle}
          size={Size.Small}
          text="Select the emails you'd like to receive"
        />
      </p>
      <div className="mt-4">{notificationSettingsForm}</div>
      {watchlistSettings}
      <div className="mt-4">{unsubscribeFromAllButton}</div>
    </div>
  )
}

export const UserSettingsPage = () => {
  const viewport = useViewport()
  const account = useCurrentAccount()
  // eslint-disable-next-line rulesdir/no-assert
  const user = useLoggedInUser()
  const { isBrokerLinkingClient } = useAccountBrokerLinking()
  const isEmailIntegrationFeatureEnabled = useFeatureFlag("crm_emails_integration")
  const trustedBrokerNetworkConnectionsEnabled = isIntermediaryAccount(user.user.account)

  const isDisplayedOnLargeScreen = viewport.width > 1500
  const tabs = useMemo(
    () =>
      [
        {
          id: "email_notifications",
          label: "Email Notifications",
          children: <NotificationPage />,
        },
        ...(isEmailIntegrationFeatureEnabled
          ? [
              {
                id: "email_integration",
                label: "Email Integration",
                children: <EmailIntegrationSettingsPage />,
              },
            ]
          : []),
        ...(isIntermediaryAccount(user.user.account)
          ? [
              {
                id: "top_broker",
                label: "Top Broker",
                children: <TopBrokerTracking />,
              },
            ]
          : []),
        ...(isBrokerLinkingClient
          ? [
              {
                id: "broker_linking",
                label: "Preferred Brokers",
                children: <BrokerPage />,
              },
            ]
          : []),
        ...(trustedBrokerNetworkConnectionsEnabled
          ? [
              {
                id: "broker_networks",
                label: "Team",
                children: <TrustedBrokerFullSettingsPage />,
              },
            ]
          : []),
      ].map((x, i) => ({ ...x, key: i })),
    [
      isBrokerLinkingClient,
      isEmailIntegrationFeatureEnabled,
      trustedBrokerNetworkConnectionsEnabled,
      user.user.account,
    ]
  )
  const initialTab = useURLTab(tabs)
  return !isLoading(account) && account ? (
    <div className={`flex ${isDisplayedOnLargeScreen ? "justify-center" : "justify-start"} w-full`}>
      <div
        className={`px-4 pt-20 md:pt-4 pb-4 ${
          isDisplayedOnLargeScreen ? "w-2/3" : "w-full"
        } animate-fade flex flex-col gap-4`}
      >
        <PageTitle title="Settings" />
        <Tabs tabs={tabs} initialTab={initialTab} isTransparent shouldIncludeSideBorders={false} />
      </div>
    </div>
  ) : null
}
