import { Button } from "@stories/components/Button/Button"
import { Card } from "@stories/components/Card/Card"
import Typography, { Size, Weight } from "@stories/components/Typography/Typography"
import { JSONContent } from "@tiptap/react"
import { DealDistribution } from "common/model/DealDistribution/DealDistribution"
import { Loading, isLoaded, matchLoading } from "common/utils/Loading"
import { useEffect, useMemo, useState } from "react"
import { ITipTapEditor } from "src/pages/CRM/Components/Notes/ITipTapEditor"
import { WYSIWYGEditor } from "src/pages/CRM/Components/Notes/WYSIWYGEditor"
import { useTipTapEditor } from "src/pages/CRM/Components/Notes/useTipTapEditor"
import { DealDistributionUploader } from "src/pages/DealDistributions/DealDistributionCreate/DealDistributionUploader"

import { PaperClipOutlined } from "@ant-design/icons"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { FileUtils } from "src/utils/FileUtils"
import {
  DealDistributionEmail,
  defaultInitialEmail,
} from "common/model/DealDistribution/DealDistributionEmail"
import { updateDealDistribution } from "src/firebase/dealDistributions"
import { useFirebaseWriter } from "src/firebase/Context"
import { handleConsoleError } from "src/utils/Tracking"
import { uniqueByRep } from "common/utils/data/Array/ArrayUtils"
import { AsyncDispatchButton } from "@stories/components/Button/AsyncDispatchButton"
import { APIEndpoints, getAPIResponse } from "src/firebase/API"
import Spinner from "@components/icons/Spinner"
import { deepEqual } from "src/pages/issuer/issuer-components/helpers"
import { Input } from "@stories/components/Inputs/Input/Input"

const ParagraphEditor = ({
  onChange,
  content,
  editable,
}: {
  onChange: (v: JSONContent) => void
  content: JSONContent
  editable: boolean
}) => {
  const handleEditorChange = (editor: ITipTapEditor) => Promise.resolve(onChange(editor.getJSON()))

  const editor = useTipTapEditor({
    placeholder: "Add a paragraph",
    handleSaveNote: handleEditorChange,
    onContentChange: handleEditorChange,
    initialContent: content,
    isMentionsDisabled: true,
  })

  useEffect(() => {
    if (editor && !editable && editor.isEditable) {
      editor.setOptions({ editable })
    }
  }, [editor, editable])

  return editor ? (
    <WYSIWYGEditor
      showSubmitButton={false}
      autoFocus={false}
      editor={editor}
      handleSubmit={handleEditorChange}
      textSize={Size.Small}
      minHeight
      className="border-2 border-neutral-400"
    />
  ) : null
}

export const LoadedAdminDealDistributionInitialEmailView = ({
  dealDistribution,
}: {
  dealDistribution: DealDistribution
}) => {
  const user = useLoggedInUser()
  const db = useFirebaseWriter()
  const initialEmail = useMemo(
    () =>
      dealDistribution.emails?.find((e) => e.tag === "initial_email") ??
      defaultInitialEmail(dealDistribution),
    [dealDistribution]
  )
  const [draftEmail, setDraftEmail] = useState<DealDistributionEmail>(initialEmail)
  useEffect(() => {
    if (initialEmail) {
      setDraftEmail(initialEmail)
    }
  }, [initialEmail])

  const handleSaveDeal = () =>
    updateDealDistribution({
      db,
      dealDistribution,
      emails: uniqueByRep<DealDistributionEmail, string>((e) => e.tag)([
        ...(dealDistribution?.emails ?? []),
        {
          ...draftEmail,
        },
      ]),
    }).catch(handleConsoleError)

  const handleSendTestEmail = () => {
    if (isLoaded(dealDistribution)) {
      return getAPIResponse(
        APIEndpoints.testDealDistributionEmail,
        {
          dealDistributionId: dealDistribution.id,
        },
        user
      )
    }
    return Promise.reject(new Error("no deal distribution"))
  }

  return (
    <div className="flex flex-col gap-4">
      <AsyncDispatchButton
        label="Save"
        onClick={handleSaveDeal}
        size="large"
        onSuccessProps={{ isDisabled: false }}
        variant={deepEqual(initialEmail, draftEmail) ? "secondary" : "tertiary"}
      />
      <Input
        placeholder="Email Subject"
        onChange={(e) => setDraftEmail((prev) => ({ ...prev, emailSubject: e.target.value }))}
        value={draftEmail.emailSubject}
      />
      <Card>
        <Typography text="Hi <name>," />
        <ParagraphEditor
          onChange={(emailBody) => setDraftEmail((prev) => ({ ...prev, emailBody }))}
          content={draftEmail.emailBody}
          editable
        />
        {dealDistribution.isParticipantViewIncludedInDistribution ? (
          <Typography text="View all opportunity details here: https://caplight.com" />
        ) : null}
        <Typography text="Bests," />
        <Typography text="Javier Avalos" />
        <div className="flex flex-col gap-2">
          <Typography text="Attachments" weight={Weight.Bold} />
          {isLoaded(dealDistribution) ? (
            <DealDistributionUploader
              dealDistribution={dealDistribution}
              getFileFromDealDistribution={() => draftEmail.documents ?? []}
              onDocumentsChange={(documents) =>
                setDraftEmail((prev) => ({
                  ...prev,
                  documents,
                }))
              }
              path={FileUtils.folders.dealDistributionEmailDocs(user.user, dealDistribution)}
              documentType="deal-distribution-email-docs"
            >
              <Button
                variant="secondary"
                label="Add Email Attachments"
                leftIcon={<PaperClipOutlined />}
              />
            </DealDistributionUploader>
          ) : null}
        </div>
      </Card>
      <AsyncDispatchButton
        label={`Send Test Email to ${user.user.email}`}
        variant="secondary"
        onClick={handleSendTestEmail}
        size="large"
        onSuccessProps={{ isDisabled: false }}
      />
    </div>
  )
}
export const AdminDealDistributionInitialEmailView = ({
  dealDistribution,
}: {
  dealDistribution: Loading<DealDistribution>
}) =>
  matchLoading(
    dealDistribution,
    (d) => <LoadedAdminDealDistributionInitialEmailView dealDistribution={d} />,
    <Spinner size="lg" />,
    "something went wrong"
  )
