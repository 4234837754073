import CompanyLogoAndName from "@components/CompanyLogoAndName"
import { OrderInquiryReceivedEvent } from "common/model/AccountActivityLog/AccountActivityLog"
import React from "react"

interface OrderInquiryReceivedDetailsProps {
  event: OrderInquiryReceivedEvent
}

export const OrderInquiryReceivedDetails: React.FC<OrderInquiryReceivedDetailsProps> = ({
  event,
}) => {
  const { inquiry } = event
  const { company } = inquiry.source.anchorOrder

  return (
    <div className="flex gap-4 items-center">
      <CompanyLogoAndName company={company} size="xs" />
    </div>
  )
}
