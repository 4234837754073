import _ from "lodash"
import { Func } from "./fp/Function"
import { isArray, Nonempty, isNonempty } from "./data/Array/Nonempty"
import { stringValidator, Validator } from "./Validator"

export type IsEmptyArrayDataRequest = { reasonEmpty: string }

export type ArrayDataRequest<T> =
  | "not requested yet"
  | "loading"
  | Nonempty<T>
  | IsEmptyArrayDataRequest

const isLoadingArrayDataRequest = <T>(t: ArrayDataRequest<T>): t is "loading" => t === "loading"

const isNotRequestedArrayDataRequest = <T>(t: ArrayDataRequest<T>): t is "not requested yet" =>
  t === "not requested yet"

export const isEmptyArrayDataRequest = <T>(t: ArrayDataRequest<T>): t is IsEmptyArrayDataRequest =>
  _.isObject(t) &&
  Object.entries(t).filter((entry) => entry[0] === "reasonEmpty" && typeof entry[1] === "string")
    .length === 1

export const isNonEmptyArrayDataRequest = <T>(t: ArrayDataRequest<T>): t is Nonempty<T> =>
  !isLoadingArrayDataRequest(t) &&
  !isEmptyArrayDataRequest(t) &&
  !isNotRequestedArrayDataRequest(t) &&
  isNonempty(t)

export const matchDataRequest = <T, Output>(
  x: ArrayDataRequest<T>,
  ifNotRequestedYet: Output,
  ifLoading: Output,
  ifReady: Func<Nonempty<T>, Output>,
  ifEmpty: Func<string, Output>
): Output => {
  if (isNotRequestedArrayDataRequest(x)) return ifNotRequestedYet
  if (isLoadingArrayDataRequest(x)) return ifLoading
  if (isArray(x) && isNonempty(x)) return ifReady(x)
  if (isEmptyArrayDataRequest(x)) return ifEmpty(x.reasonEmpty)
  return ifNotRequestedYet
}

export const matchSuccessfulDataRequest = <T, Output>(
  x: ArrayDataRequest<T>,
  ifReady: Func<Nonempty<T>, Output>,
  ifNotReady: Output
): Output => matchDataRequest(x, ifNotReady, ifNotReady, ifReady, () => ifNotReady)

export const validateIsEmptyArrayDataRequest: Validator<unknown, IsEmptyArrayDataRequest> =
  Validator.fromRecord({
    reasonEmpty: stringValidator.validate,
  })
