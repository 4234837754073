import { Divider, Drawer, DrawerProps } from "@stories/components/Antd"
import { DocumentSnapshot } from "src/firebase/Firebase/utils"
import { Maybe } from "common/containers/Maybe"
import { PriceObservationType } from "common/model/data-product/pricing/PriceObservation"
import ClosedTradeFormLayout from "./ClosedTradeFormLayout"
import ClosedTradeForm from "./ClosedTradeForm"
import ClosedTradeFormContextProvider, { useClosedTradeForm } from "./ClosedTradeFormContext"
import { ClosedTradeFormButtons } from "./ClosedTradeFormButtons"
import { ClosedTradeFormHeader } from "./ClosedTradeFormHeader"
import { ClosedTradeFormConfirmationPage } from "./ClosedTradeFormConfirmationPage"

export const ClosedTradeFormDrawerInner = () => (
  <>
    <ClosedTradeFormLayout.Header
      header={
        <>
          <ClosedTradeFormHeader />
          <Divider />
        </>
      }
    />
    {["pending", "disabled"].includes(useClosedTradeForm().saveStatus) ? (
      <>
        <ClosedTradeFormLayout.Wrapper main={<ClosedTradeForm />} />
        <ClosedTradeFormLayout.Footer footer={<ClosedTradeFormButtons />} />
      </>
    ) : (
      <ClosedTradeFormConfirmationPage />
    )}
  </>
)

const ClosedTradeFormDrawer = ({
  onClose,
  open,
  initialPriceObservation,
  onSave,
  drawerTitle,
}: {
  onClose: () => void
  open: Required<DrawerProps["open"]>
  onSave: (t: PriceObservationType) => Promise<Maybe<DocumentSnapshot<PriceObservationType>>>
  initialPriceObservation: Partial<PriceObservationType>
  drawerTitle?: string
}) => {
  if (!open) return null

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onClick={(e) => e.stopPropagation()} className="absolute">
      <Drawer
        open={open}
        onClose={onClose}
        placement="right"
        width={800}
        contentWrapperStyle={{ maxWidth: "100vw" }}
        bodyStyle={{ padding: 0 }}
        closable={false}
        destroyOnClose
      >
        <ClosedTradeFormContextProvider
          initialPriceObservation={initialPriceObservation}
          onSave={onSave}
          onClose={onClose}
          closedTradeFormOpen={!!open}
          drawerTitle={drawerTitle}
        >
          <ClosedTradeFormDrawerInner />
        </ClosedTradeFormContextProvider>
      </Drawer>
    </div>
  )
}

export default ClosedTradeFormDrawer
