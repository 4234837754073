import { head } from "common/utils/data/Array/ArrayUtils"
import moment from "moment"
import { useLoggedInUser } from "../../providers/loggedInUser/useLoggedInUser"
import { ProductInformationLoomLinks } from "../layout/information-popup/InformationPopupConfig"
import { NewFeatureButton } from "./NewFeatureButton"

const NavbarNewFeatureAnnouncement = () => {
  const announcements = ProductInformationLoomLinks
  const user = useLoggedInUser()

  const latestAnnouncement = head(
    Object.entries(announcements)
      .sort((left, right) => {
        const leftDate = left[1].announcementLiveUntil || new Date(2020, 1, 1)
        const rightDate = right[1].announcementLiveUntil || new Date(2020, 1, 1)
        return leftDate.getTime() - rightDate.getTime()
      })
      .filter(
        (entry) =>
          entry[1].announcementLiveUntil &&
          moment(entry[1].announcementLiveUntil).isAfter(moment()) &&
          !(entry[1].hideFromUser && entry[1].hideFromUser(user.user))
      )
      // eslint-disable-next-line rulesdir/no-assert
      .map((item) => item[0] as keyof typeof announcements)
  )

  return latestAnnouncement.match(
    (key) => <NewFeatureButton announcementKey={key} />,
    () => null
  )
}

export default NavbarNewFeatureAnnouncement
