import { Opportunity } from "common/model/Opportunity/Opportunity"
import { ArchiveReason, archiveOpportunity } from "common/model/Opportunity/fns/archiveOpportunity"
import { pinOpportunity, unPinOpportunity } from "common/model/Opportunity/fns/pinOpportunity"
import { readOpportunity } from "common/model/Opportunity/fns/readOpportunity"
import { OrderLookupFields } from "common/model/Order/Models/Internal"
import { UserAccessFields, viewUserIdFields } from "common/model/User"
import Firebase9 from "./Firebase9"

export const markOpportunityIsReadByOrder = async (
  db: Firebase9,
  orderId: OrderLookupFields,
  user: UserAccessFields
) => {
  const opp = await db.getOrderOpportunityByOrder(user.account.id, orderId)
  if (!opp) return // opportunity doesn't exist (account probably not turned on)
  await markOpportunityAsRead(db, opp, user)
}

const markOpportunityAsRead = async (
  db: Firebase9,
  opportunity: Opportunity,
  user: UserAccessFields
) => {
  const oppReadByUser: Opportunity = readOpportunity({
    opportunity,
    readByUser: viewUserIdFields(user),
    readAtDate: new Date(),
  })
  await db.setOrderOpportunity(oppReadByUser)
}
export const markOpportunityAsPinned = async (
  db: Firebase9,
  opportunity: Opportunity,
  user: UserAccessFields
) => {
  const oppWithPinnedBy = pinOpportunity({
    opportunity,
    pinnedByUser: viewUserIdFields(user),
    pinnedAtDate: new Date(),
  })
  await db.setOrderOpportunity(oppWithPinnedBy)
}
export const markOpportunityAsNotPinned = async (
  db: Firebase9,
  opportunity: Opportunity,
  user: UserAccessFields
) => {
  const oppWithoutPinnedBy = unPinOpportunity({
    opportunity,
    unPinnedByUser: viewUserIdFields(user),
    unPinnedAtDate: new Date(),
  })
  await db.setOrderOpportunity(oppWithoutPinnedBy)
}
export const markOpportunityAsArchived = async (
  db: Firebase9,
  opportunity: Opportunity,
  user: UserAccessFields,
  archiveReason: ArchiveReason[]
) => {
  const archivedOpp = archiveOpportunity({
    opportunity,
    archivedByUser: viewUserIdFields(user),
    archiveReason,
    archivedAtDate: new Date(),
  })
  await db.setOrderOpportunity(archivedOpp)
}
