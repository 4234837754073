import { wrapIcon } from "./IconWrapper"

export const CommentPlusIcon = wrapIcon(
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 6H8.66668V4.66666C8.66668 4.48985 8.59644 4.32028 8.47141 4.19526C8.34639 4.07023 8.17682 3.99999 8.00001 3.99999C7.8232 3.99999 7.65363 4.07023 7.52861 4.19526C7.40358 4.32028 7.33334 4.48985 7.33334 4.66666V6H6.00001C5.8232 6 5.65363 6.07023 5.52861 6.19526C5.40358 6.32028 5.33334 6.48985 5.33334 6.66666C5.33334 6.84347 5.40358 7.01304 5.52861 7.13807C5.65363 7.26309 5.8232 7.33333 6.00001 7.33333H7.33334V8.66666C7.33334 8.84347 7.40358 9.01304 7.52861 9.13807C7.65363 9.26309 7.8232 9.33333 8.00001 9.33333C8.17682 9.33333 8.34639 9.26309 8.47141 9.13807C8.59644 9.01304 8.66668 8.84347 8.66668 8.66666V7.33333H10C10.1768 7.33333 10.3464 7.26309 10.4714 7.13807C10.5964 7.01304 10.6667 6.84347 10.6667 6.66666C10.6667 6.48985 10.5964 6.32028 10.4714 6.19526C10.3464 6.07023 10.1768 6 10 6ZM12.6667 1.33333H3.33334C2.80291 1.33333 2.2942 1.54404 1.91913 1.91911C1.54406 2.29419 1.33334 2.8029 1.33334 3.33333V10C1.33334 10.5304 1.54406 11.0391 1.91913 11.4142C2.2942 11.7893 2.80291 12 3.33334 12H11.06L13.5267 14.4733C13.589 14.5351 13.6628 14.584 13.7441 14.6172C13.8253 14.6504 13.9123 14.6672 14 14.6667C14.0875 14.6689 14.1742 14.6507 14.2533 14.6133C14.3751 14.5633 14.4793 14.4784 14.5529 14.3692C14.6264 14.2601 14.666 14.1316 14.6667 14V3.33333C14.6667 2.8029 14.456 2.29419 14.0809 1.91911C13.7058 1.54404 13.1971 1.33333 12.6667 1.33333ZM13.3333 12.3933L11.8067 10.86C11.7444 10.7982 11.6705 10.7493 11.5893 10.7161C11.5081 10.683 11.4211 10.6662 11.3333 10.6667H3.33334C3.15653 10.6667 2.98696 10.5964 2.86194 10.4714C2.73691 10.3464 2.66668 10.1768 2.66668 10V3.33333C2.66668 3.15652 2.73691 2.98695 2.86194 2.86192C2.98696 2.7369 3.15653 2.66666 3.33334 2.66666H12.6667C12.8435 2.66666 13.0131 2.7369 13.1381 2.86192C13.2631 2.98695 13.3333 3.15652 13.3333 3.33333V12.3933Z"
      fill="currentColor"
    />
  </svg>
)
