import Alert from "@stories/components/Alert/Alert"
import { InfoIcon } from "@stories/icons/InfoIcon"
import * as React from "react"

interface IWarningMessageProps {
  msg?: string | null
}

export const WarningMessage: React.FunctionComponent<
  React.PropsWithChildren<IWarningMessageProps>
> = ({ msg }) => {
  if (!msg) return null
  return <Alert variant="warning" message={msg} icon={<InfoIcon />} />
}
