import { OrderStatus } from "common/model/Order/Types/Status"
import { assertUnreachable } from "common/utils/fp/Function"
import { nullableToMaybe } from "common/containers/Maybe"
import { RenewIcon } from "../../../../../stories/icons/RenewIcon"
import { XCircleIcon } from "../../../../../stories/icons/XCircleIcon"
import OrderHistoryItem from "./OrderHistoryItem"

const orderStatusTagToDisplay = {
  live: "Renewed",
  closed: "Expired",
  cancelled: "Expired",
  unknown: null,
} as const satisfies Record<OrderStatus["tag"], string | null>
const OrderHistoryOrderStatus = ({
  orderStatus,
  isHorizontal,
}: {
  orderStatus: OrderStatus
  isHorizontal?: boolean
}) =>
  nullableToMaybe(orderStatusTagToDisplay[orderStatus.tag]).match(
    (displayStatus) => (
      <OrderHistoryItem
        labelString={displayStatus}
        iconComponent={
          displayStatus === "Renewed"
            ? RenewIcon
            : displayStatus === "Expired"
            ? XCircleIcon
            : assertUnreachable(displayStatus)
        }
        date={orderStatus.asOf}
        isHorizontal={isHorizontal}
      />
    ),
    () => null
  )

export default OrderHistoryOrderStatus
