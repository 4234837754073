import { AccountIdFields } from "../Account"
import { UserIdFields } from "../User"
import { UserEventCounts } from "../UserEvent"

export const eventCounterPeriods = ["day", "week", "month"] as const
export type EventCounterPeriod = (typeof eventCounterPeriods)[number]

export type EventCounter = {
  id: string
  account: AccountIdFields
  period: EventCounterPeriod
  periodDate: Date // Start of period, PST
  eventCounts: UserEventCounts
}

export type AccountEventCounter = EventCounter
export type UserEventCounter = EventCounter & { user: UserIdFields }
