import { OrderTermUpdate } from "common/model/Order/Models/Wrapped/OrderUpdate"
import { Order } from "common/model/Order/Models/Wrapped"
import OrderHistoryCreated from "./OrderHistoryCreated"
import OrderHistoryPriceChange from "./OrderHistoryPriceChange"
import OrderHistoryRenewed from "./OrderHistoryRenewed"

const OrderHistoryPriceUpdateItem = ({
  pricePair: [price, prev],
  order,
  isHorizontal,
}: {
  pricePair: [OrderTermUpdate, OrderTermUpdate | null]
  order?: Order
  isHorizontal?: boolean
}) => {
  const prevPrice = prev?.rawPrice().toNullable()
  const newPrice = price.rawPrice().toNullable()
  const date = price.date()

  if (!newPrice) return null
  if (!prevPrice) {
    return <OrderHistoryCreated date={date} isHorizontal={isHorizontal} />
  }

  if (prevPrice.toUnion() === newPrice.toUnion()) {
    return <OrderHistoryRenewed date={date} isHorizontal={isHorizontal} />
  }

  return (
    <OrderHistoryPriceChange
      order={order}
      date={date}
      prevPrice={prevPrice}
      newPrice={newPrice}
      isHorizontal={isHorizontal}
    />
  )
}

export default OrderHistoryPriceUpdateItem
