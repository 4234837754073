import { getAccessControlTierForAccount } from "common/model/AccessControl/getAccessControlTierForAccount"
import {
  AccountTradingRegistration,
  MinimumAccountTradingRegistration,
  isTradingRegistrationOnLastStep,
} from "common/model/Account/AccountTradingRegistration"
import { isEmployeeAccount } from "common/model/Auth/Permissions"
import { Loading, isLoaded } from "common/utils/Loading"
import { ReusableResponse } from "common/utils/network/ReusableResponse"
import { useState, useEffect, useCallback } from "react"
import { APIEndpoints, runAPIQuery } from "src/firebase/API"
import { useAccountSnapshot } from "src/providers/AccountSnapshot/useAccountSnapshot"
import { useAuthUser } from "src/providers/authUser/useAuthUser"
import { handleConsoleError } from "src/utils/Tracking"

const buildTradingRegistrationFromAPI =
  (setData: (d: AccountTradingRegistration | null) => void) =>
  async (results: ReusableResponse) => {
    if ([201, 200].includes(results.status)) {
      const { result } = await (results.json() as Promise<{ result: AccountTradingRegistration }>) // TODO fix
      setData(
        result
          ? {
              ...result,
              status: {
                ...result.status,
                submittedIdentificationDate: result.status.submittedIdentificationDate
                  ? new Date(result.status.submittedIdentificationDate)
                  : undefined,
                signedAgreementDate: result.status.signedAgreementDate
                  ? new Date(result.status.signedAgreementDate)
                  : undefined,
                offlineAgreementRequestedDate: result.status.offlineAgreementRequestedDate
                  ? new Date(result.status.offlineAgreementRequestedDate)
                  : undefined,
                approvedDate: result.status.approvedDate
                  ? new Date(result.status.approvedDate)
                  : undefined,
              },
            }
          : null
      )
    } else if ([404].includes(results.status)) {
      setData(null)
    } else {
      throw new Error("Error on request")
    }
  }

const useAccountTradingRegistration = ({
  fetchTradingRegistrationFromAPI,
}: {
  fetchTradingRegistrationFromAPI: () => Promise<ReusableResponse>
}) => {
  const [tradingRegistration, setTradingRegistration] =
    useState<Loading<Partial<AccountTradingRegistration> & MinimumAccountTradingRegistration>>(
      "loading"
    )

  useEffect(() => {
    fetchTradingRegistrationFromAPI()
      .then(buildTradingRegistrationFromAPI(setTradingRegistration))
      .catch(handleConsoleError)
  }, [fetchTradingRegistrationFromAPI])

  const updateTradingRegistration = useCallback(
    (
      updatedTradingRegistration: Partial<AccountTradingRegistration> &
        MinimumAccountTradingRegistration
    ) =>
      runAPIQuery(APIEndpoints.updateTradingRegistration, {
        tradeRegistration: updatedTradingRegistration,
      })
        .then(buildTradingRegistrationFromAPI(setTradingRegistration))
        .catch(handleConsoleError),
    []
  )

  return { tradingRegistration, updateTradingRegistration }
}

export const useAccountTradingRegistrationForAccount = ({ accountId }: { accountId: string }) => {
  const authUser = useAuthUser()
  const fetchTradingRegistrationFromAPI = useCallback(
    () =>
      runAPIQuery(
        APIEndpoints.getTradingRegistrationByAccount,
        {
          accountId,
        },
        authUser ? { authUser } : undefined
      ),
    [accountId, authUser]
  )
  return useAccountTradingRegistration({
    fetchTradingRegistrationFromAPI,
  })
}

export const useAccountTradingRegistrationForTradeRegistrationId = ({
  tradingRegistrationId,
}: {
  tradingRegistrationId: string | null
}) => {
  const fetchTradingRegistrationFromAPI = useCallback(
    () =>
      runAPIQuery(APIEndpoints.getTradingRegistration, {
        tradingRegistrationId,
      }),
    [tradingRegistrationId]
  )
  return useAccountTradingRegistration({
    fetchTradingRegistrationFromAPI,
  })
}

export const useAccountTradingRegistrationStatus = () => {
  const { accountSnapshot } = useAccountSnapshot()
  return {
    isAccountNonTransactional: isLoaded(accountSnapshot)
      ? getAccessControlTierForAccount(accountSnapshot) === "journalist" ||
        isEmployeeAccount(accountSnapshot) ||
        accountSnapshot.tradingRegistration?.isNonTransactional
      : false,
    isAccountApprovedForTrading: isLoaded(accountSnapshot)
      ? !!accountSnapshot.tradingRegistration?.approvedDate
      : true,
    isAccountPendingApprovalForTrading: isLoaded(accountSnapshot)
      ? isTradingRegistrationOnLastStep(accountSnapshot)
      : false,
  }
}
