import { Just, nullableToMaybe } from "common/containers/Maybe"
import { Routes } from "./Routes"
import { PartialRouted, RouteEndpoint } from "./types"

/** Get a path with `:` escaped params, suitable for use in a `Router` component. */
export const getRoutePathTemplate = (route: RouteEndpoint): string | undefined =>
  nullableToMaybe(route(Routes))
    .map((x) => (typeof x === "string" ? x : x[0](`:${x[1]}`)))
    .toNullable()

export const withRouteParam =
  <X extends Y, Y>(f: <T>(routes: PartialRouted<T>) => ((param: X) => T) | undefined, param: Y) =>
  <T>(routes: PartialRouted<T>) =>
    nullableToMaybe(f(routes)).alongside(Just(param)).toNullable()
