import { isIntermediaryAccount } from "common/model/Account"
import { ProductInformationKeys, User } from "common/model/User"
import { Routes } from "src/Routes/Routes"

export type ProductInformationData = {
  link: string
  title?: string
  shortTitle?: string
  body?: string
  announcementLiveUntil?: Date
  hideFromUser?: (u: User) => boolean
  whitelistedRoutes?: string[]
  blacklistedRoutes?: string[]
}

export const ProductInformationLoomLinks: Record<
  (typeof ProductInformationKeys)[number],
  ProductInformationData
> = {
  firstViewedWatchlistAnnouncement: {
    link: "https://www.loom.com/embed/56d09a5f372145b1a2fd2c80dddbef66",
    title: "New Feature: Watchlists",
    body: "Watchlists allow you to track companies you're interested in and receive notifications for new company orders, trades, news, and price movements.",
    announcementLiveUntil: new Date("3/30/23"),
    hideFromUser: (u: User) =>
      [
        u.createdAt.valueOf() > new Date("2/27/23").valueOf(),
        u.account.onboardingStatus?.data?.status !== "complete",
      ].some((b) => !!b),
    whitelistedRoutes: [Routes.companies.root, Routes.orders.root],
  },
  firstViewedOrderLinkCaplightConnect: {
    link: "https://www.loom.com/embed/f816bc83bc77458fa9452f54af497247",
    title: "Updated Caplight Connect Workflow",
    body: "Order Linking allows you to quickly select from your live orders to connect with the counter party.",
    announcementLiveUntil: new Date("4/1/23"),
    hideFromUser: (u: User) =>
      [u.createdAt.valueOf() > new Date("3/2/23").valueOf()].some((b) => !!b),
    whitelistedRoutes: [],
  },
  firstViewedJune2023ProductUpdateAnnouncement: {
    link: "https://www.loom.com/embed/12744d0dc0c34387a55a93ab0500a322",
    title: "June Product Updates",
    body: "We've made a number of updates to the Caplight Platform. Watch this video to learn more.",
    announcementLiveUntil: new Date("7/1/23"),
    hideFromUser: (u: User) => u.createdAt.valueOf() > new Date("6/9/23").valueOf(),
    whitelistedRoutes: [
      Routes.companies.root,
      Routes.orders.root,
      Routes.news,
      Routes.insights,
      Routes.fundingRounds,
    ],
  },
  firstViewedDarkpoolAnnouncement: {
    link: "https://www.loom.com/embed/d94e165367ee442e8702a3cc8b4f4687",
    title: "Caplight Darkpool",
    body: "Introducing the Caplight Darkpool for your most sensitive orders",
    announcementLiveUntil: new Date("7/20/23"),
    hideFromUser: (u: User) => u.createdAt.valueOf() > new Date("7/20/23").valueOf(),
    whitelistedRoutes: [
      Routes.companies.root,
      Routes.orders.root,
      Routes.myOrders,
      Routes.news,
      Routes.insights,
      Routes.fundingRounds,
    ],
  },
  firstViewedAugust2023ProductUpdateAnnouncement: {
    link: "https://www.loom.com/embed/343109f469df492f9a733d49109413c8",
    title: "Top Broker Program and more updates",
    body: "We've made a number of updates to the Caplight Platform, including the new Top Broker Program, 'Highlighted' orders, Platform Notifications, and a Sector Analysis tool. Watch this video to learn more.",
    announcementLiveUntil: new Date("9/30/23"),
    hideFromUser: (u: User) => u.createdAt.valueOf() > new Date("9/1/23").valueOf(),
    whitelistedRoutes: [
      Routes.companies.root,
      Routes.orders.root,
      Routes.myOrders,
      Routes.news,
      Routes.insights,
      Routes.fundingRounds,
    ],
  },
  firstViewedMarch2024ProductUpdateAnnouncement: {
    link: "https://www.loom.com/embed/2cada980b0c24a9ea05e9a7621f29030",
    title: "Broker Platform March Updates",
    shortTitle: "New Update",
    body: "Brokers can now create 'unpriced indications' as well as push private CRM indications to the Live Market or Darkpool. Watch this video to learn more.",
    announcementLiveUntil: new Date("3/30/24"),
    hideFromUser: (u: User) =>
      u.createdAt.valueOf() > new Date("3/20/24").valueOf() || !isIntermediaryAccount(u.account),
    whitelistedRoutes: [],
  },
  firstViewedAugust2024InsightsAnnouncement: {
    title: "New: August Market Analysis",
    shortTitle: "August Market Update",
    body: "",
    announcementLiveUntil: new Date("8/15/24"),
    link: "https://cdn.prod.website-files.com/638a2e63e7d4101440882de8/66b2b605e518d311cf8bb124_Caplight%201H%202024%20Secondary%20Market%20Update.pdf",
    whitelistedRoutes: [],
  },
}
