import { isGhosting } from "../../Routes/auth/UserAuthContext"
import { useLoggedInUser } from "../loggedInUser/useLoggedInUser"
import { AdminAirtableContactsProvider } from "./AdminAirtableContacts"
import { AdminEngagementDrawerProvider } from "./AdminEngagementDrawerProvider"

export const AdminProviders = ({ children }: React.PropsWithChildren) => {
  const user = useLoggedInUser()

  if (user.isAdmin && !isGhosting(user)) {
    return (
      <AdminEngagementDrawerProvider>
        <AdminAirtableContactsProvider>{children}</AdminAirtableContactsProvider>
      </AdminEngagementDrawerProvider>
    )
  }

  return children
}

export default AdminProviders
