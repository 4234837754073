import { SelectOptions } from "@stories/components/Inputs/RadioSelect/RadioSelect"
import SectionLabel from "@stories/components/Inputs/SectionLabel/SectionLabel"
import { IntroductionRequestData } from "common/model/IntroductionRequest"
import { FormOrder } from "common/model/Order/OrderForm/State"
import { OrderStructure, StructureOrderTerms } from "common/model/Order/Types/Terms"
import { isIntermediaryContext, useAuthContext } from "src/Routes/auth/UserAuthContext"
import { MultiCheckboxSelect } from "../CheckboxSelect"
import { CarryManagement } from "./CarryManagement"
import { OrderDirection } from "common/model/Order/Order"
import { BooleanSelect } from "@components/lib/Select/BooleanSelect"
import { AnimatePresence, motion } from "framer-motion"

export const standardStructureSelectOptions: SelectOptions<OrderStructure>[] = [
  { label: "Direct", value: "direct" },
  { label: "SPV", value: "spv" },
  { label: "Forward", value: "forward" },
]

export const brokerBidStructureSelectOptions: SelectOptions<OrderStructure>[] = [
  ...standardStructureSelectOptions,
  { label: "Unconfirmed", value: "unknown" },
]
const clientBidStructureSelectOptions: SelectOptions<OrderStructure>[] =
  standardStructureSelectOptions
export const offerStructureSelectOptions: SelectOptions<OrderStructure>[] =
  standardStructureSelectOptions

type StructureSectionData = {
  structures?: IntroductionRequestData["structures"]
  terms?: Partial<StructureOrderTerms>
  direction?: OrderDirection
  isMultiLayerSpv?: FormOrder["isMultiLayerSpv"]
}
export const StructureSection = ({
  value,
  onChange,
  hiddenFields,
  title = "Structure",
  titles,
}: {
  value: StructureSectionData
  onChange: (value: StructureSectionData) => void
  title?: string
  hiddenFields?: (keyof (FormOrder & IntroductionRequestData))[]
  titles: {
    structure: string
    carryManagement: string
    isMultiLayerSpv: string
    isMultiLayerSpvTooltip: string
  }
}) => {
  const ctx = useAuthContext()
  return (
    <>
      {hiddenFields?.includes("structures") ? null : (
        <div className="flex flex-col">
          <SectionLabel>{title}</SectionLabel>
          <div className="flex flex-col">
            <MultiCheckboxSelect<OrderStructure>
              label={titles.structure}
              options={
                value.direction === "sell"
                  ? offerStructureSelectOptions
                  : isIntermediaryContext(ctx)
                  ? brokerBidStructureSelectOptions
                  : clientBidStructureSelectOptions
              }
              onChange={(structures) => onChange({ ...value, structures })}
              value={value.structures}
              disabled={hiddenFields?.includes("structures")}
            />
            {value.structures?.includes("spv") ? (
              <AnimatePresence>
                <motion.div
                  initial={{ y: -10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  <div className="flex flex-col gap-4 mt-4">
                    {value.direction === "sell" ? (
                      <div>
                        <BooleanSelect
                          options={[
                            {
                              value: false,
                              label: "Single-layer SPV",
                              subLabel: "(SPV holds shares)",
                            },
                            {
                              value: true,
                              label: "Multi-layer SPV",
                              subLabel: "(SPV holds units in another fund)",
                            },
                          ]}
                          value={value.isMultiLayerSpv}
                          onChange={(isMultiLayerSpv) => onChange({ ...value, isMultiLayerSpv })}
                          label={titles.isMultiLayerSpv}
                          tooltip={titles.isMultiLayerSpvTooltip}
                        />
                      </div>
                    ) : null}

                    <div>
                      <CarryManagement
                        disabled={hiddenFields?.includes("structures")}
                        title={titles.carryManagement}
                        onChange={(spv) => onChange({ ...value, terms: { spv } })}
                        value={value.terms?.spv}
                      />
                    </div>
                  </div>
                </motion.div>
              </AnimatePresence>
            ) : null}
          </div>
        </div>
      )}
    </>
  )
}
