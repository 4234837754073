import { CompanyFundingRound } from "common/model/Company/FundingRound"
import { FundingRoundWithMeta, InvestorForDeals, SourceForDeals } from "common/model/FundingRounds"
import { CoiFilesDetails, coiFilesDetails } from "common/model/airtable/AirtableCompanyCOI"
import { AirtableId } from "common/model/airtable/airtable"
import { isSecondaryRound } from "common/model/funding-rounds/FundingRound"
import { Loading, deprecatedIsLoaded, isLoading } from "common/utils/Loading"
import {
  Validator,
  arrayValidator,
  enumValidator,
  isoDateValidator,
  stringValidator,
  validateNumber,
  validateString,
} from "common/utils/Validator"
import { identity } from "common/utils/fp/Function"
import { POSTGRES_QUERIES } from "common/utils/postgres"
import { parseWith } from "common/utils/zodUtils"
import _, { isEqual } from "lodash"
import { logMessage } from "src/utils/Logging"
import { APIEndpoints, getAPIResponse, runAPIQuery } from "../../../firebase/API"
import { CurrentUser } from "../../../model/CurrentUser"
import { CompsListRow, IComparableSelection } from "src/state/model/postgresData"
import { ChartSeriesMetadata } from "src/state/actions/issuers"
import { ChartTimeLimit } from "./IssuerCompsChartFunctions"

export const validateInvestorForDeals: Validator<unknown, InvestorForDeals> = Validator.fromRecord({
  deal_pbid: validateString,
  investor_name: validateString,
  total_deals: validateNumber,
})

export const validateSourceForDeals: Validator<unknown, SourceForDeals> = Validator.fromRecord({
  deal_pbid: validateString,
  title: stringValidator.or(enumValidator(undefined)).or(enumValidator(null)).validate,
  url: stringValidator.or(enumValidator(undefined)).or(enumValidator(null)).validate,
  date: isoDateValidator.validate,
  source: stringValidator.or(enumValidator(undefined)).or(enumValidator(null)).validate,
  image: stringValidator.or(enumValidator(undefined)).or(enumValidator(null)).validate,
})

export const getInvestors = async (
  dealPbids: string[],
  currentUser: Pick<CurrentUser, "authUser">
) =>
  runAPIQuery(
    APIEndpoints.postgresDBQuery,
    {
      query_type: POSTGRES_QUERIES.LIST_OF_INVESTORS_FOR_DEALS,
      payload: {
        dealPbids,
      },
    },
    currentUser
  )
    .then((res) => res.json())
    .then((investors: unknown) => {
      const parsed: InvestorForDeals[] = arrayValidator
        .compose(validateInvestorForDeals.overArray())
        .validate(investors)
        .match(
          (l) => {
            logMessage(l.toString())
            logMessage(JSON.stringify(investors).slice(0, 1000))
            return [] satisfies InvestorForDeals[]
          },
          (r: InvestorForDeals[]) => r
        )
      return parsed.map((i) => ({
        dealPbid: i.deal_pbid,
        investorName: i.investor_name,
        investorTotalDeals: i.total_deals,
      }))
    })

export const getSources = async (
  dealPbids: string[],
  currentUser: Pick<CurrentUser, "authUser">
): Promise<SourceForDeals[]> =>
  runAPIQuery(
    APIEndpoints.postgresDBQuery,
    {
      query_type: POSTGRES_QUERIES.LIST_OF_SOURCES_FOR_DEALS,
      payload: {
        dealPbids,
      },
    },
    currentUser
  )
    .then((res) => res.json())
    .then((sources) => {
      const parsed: SourceForDeals[] = arrayValidator
        .compose(validateSourceForDeals.overArray())
        .validate(sources)
        .match(
          (l) => {
            logMessage(l.toString())
            logMessage(JSON.stringify(sources).slice(0, 1000))
            return [] satisfies SourceForDeals[]
          },
          (r: SourceForDeals[]) => r
        )
      return parsed
    })

export const combineData = (
  fundingRounds: CompanyFundingRound[],
  sources: Loading<SourceForDeals[]>,
  investors: Loading<{ dealPbid: string; investorName: string; investorTotalDeals: number }[]>
): FundingRoundWithMeta[] =>
  fundingRounds.map((fr) => ({
    raw: fr,
    date: fr.date,
    postMoneyValuation: fr.valuation,
    amount: fr.amount,
    pricePerShare: fr.pps,
    round: fr.roundName,
    sources:
      fr.dealPbid && deprecatedIsLoaded(sources)
        ? sources
            .filter((s) => s.deal_pbid === fr.dealPbid)
            .map((s) => ({ ...s, date: new Date(s.date) }))
        : isLoading(sources) && !isSecondaryRound(fr)
        ? [{ date: new Date() }]
        : [],
    investors:
      fr.dealPbid && deprecatedIsLoaded(investors)
        ? _.orderBy(
            investors.filter((i) => i.dealPbid === fr.dealPbid),
            "investorTotalDeals",
            "desc"
          ).map((inv) => inv.investorName)
        : isLoading(investors) && !isSecondaryRound(fr)
        ? ["loading..."]
        : [],
  }))

export const getCOILinks = async (
  airtableId: AirtableId,
  user: CurrentUser
): Promise<CoiFilesDetails | null> => {
  const record = await getAPIResponse(APIEndpoints.coiFileUrls, { airtableId }, user)
  if (!record) return null
  const safe = parseWith(coiFilesDetails)(record)
  return safe.match(() => null, identity)
}

export const compRowToSelection = (el: CompsListRow): IComparableSelection => ({
  comparableId: el.companyId,
  name: el.companyName,
  status: el.status || undefined,
  description: el.description,
  ticker: el.ticker,
  hasMarketPrice: el.hasMarketPrice,
})

export const convertToChartSeriesMetadata = (
  comparableId: string,
  compsWithDescription: IComparableSelection[]
): ChartSeriesMetadata => {
  const filtered = compsWithDescription.filter((comp) => comp.comparableId === comparableId)
  return {
    companyId: comparableId,
    companyName: filtered.length ? filtered[0].name : "",
    ticker: filtered.length ? filtered[0].ticker : "",
    hasMarketPrice: filtered.length ? filtered[0].hasMarketPrice : false,
  }
}

export const historySelection: ChartTimeLimit[] = [
  { label: "1Y", months: 13 },
  { label: "2Y", months: 25 },
  { label: "3Y", months: 37 },
  { label: "Max", months: Infinity },
]

export const deepEqual = <T,>(l: T, r: T) => isEqual(l, r)
