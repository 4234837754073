import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import {
  ShareableItem,
  viewCompanyIdsFromShareableItem,
} from "common/model/SharedOrder/SharedOrderResponse"
import { SharedOrderCreateParams } from "common/model/SharedOrder/SharedOrderCreate"
import { useCRMContacts } from "src/pages/CRM/Providers/CRMContactsProvider"
import { createEmailFromCRMContact } from "../ShareOrderInviteContacts"
import { removeByRep, uniqueByRep } from "common/utils/data/Array/ArrayUtils"
import { sendSharedOrder } from "../utils/sendSharedOrder"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { Company } from "common/model/Company"
import { JSONContent } from "@tiptap/react"
import { commaSeparateWithAnd } from "common/utils/StringUtils"
import pluralize from "pluralize"
import { defaultIfLoading } from "common/utils/Loading"
import { useCompanies } from "src/providers/data/CompanyProvider"
import { Nonempty, nonemptyHead } from "common/utils/data/Array/Nonempty"
import { useArrayStepper } from "./useArrayStepper"
import { assertUnreachable } from "common/utils/fp/Function"
import { notification } from "@stories/components/Antd/notification"

type WorkingItems = {
  emailBody: JSONContent
  emailSubject: string
  contact: SharedOrderCreateParams["sharedWithUserEmails"][number]
}
interface SharedOrderEditorContextType {
  emails: SharedOrderCreateParams["sharedWithUserEmails"]
  handleAddEmails: (c: SharedOrderCreateParams["sharedWithUserEmails"]) => void
  handleRemoveEmails: (c: SharedOrderCreateParams["sharedWithUserEmails"]) => void
  handleShareOrders: (p: { sendEmails: boolean }) => void
  preview: {
    handleSendPreview: () => Promise<void>
  }
  emailContent: {
    initialBody: JSONContent
    initialSubject: string
    emailSubject: string
    emailBody: JSONContent
    setEmailSubject: (s: string) => void
    setEmailBody: (b: JSONContent) => void
  }
  multiEditorContent: null | ReturnType<typeof useArrayStepper<WorkingItems>>
  editorMode: "group" | "individually_customized"
  setEditorMode: (m: "group" | "individually_customized") => void
}

export const SharedOrderEditorContext = createContext<SharedOrderEditorContextType>({
  emails: [],
  handleAddEmails: () => {},
  handleRemoveEmails: () => {},
  handleShareOrders: () => {},
  preview: {
    handleSendPreview: () => Promise.reject(),
  },
  emailContent: {
    initialBody: [],
    initialSubject: "",
    emailSubject: "",
    emailBody: [],
    setEmailSubject: () => {},
    setEmailBody: () => {},
  },
  multiEditorContent: null,
  editorMode: "group",
  setEditorMode: () => {},
})

const ORDER_SHARING_NOTIFICATION_KEY = "order sharing"
const PREVIEW_SHARING_NOTIFICATION_KEY = "order sharing preview"

export const SharedOrderEditorProvider = ({
  children,
  items,
  initialContacts,
  templates,
  onSend,
}: {
  children: ReactNode
  items: ShareableItem[]
  initialContacts: SharedOrderCreateParams["sharedWithUserEmails"]
  templates: Nonempty<(items: ShareableItem[], companies: Company[]) => JSONContent>
  onSend?: () => void
}) => {
  const firebase9 = useFirebase9()
  const { user } = useLoggedInUser()

  const { findContactById } = useCRMContacts()
  const [editorMode, setEditorMode] = useState<"group" | "individually_customized">("group")
  const [emails, setEmails] =
    useState<SharedOrderCreateParams["sharedWithUserEmails"]>(initialContacts)
  const companies = useCompanies(items.map((i) => viewCompanyIdsFromShareableItem(i).id))
  useEffect(() => {
    setEmails((curr) =>
      curr.map((e) => {
        const { crmContactId } = e
        if (crmContactId) {
          const contact = findContactById(crmContactId.id)
          if (contact) {
            return {
              ...e,
              ...createEmailFromCRMContact(contact),
            }
          }
        }
        return e
      })
    )
  }, [findContactById])

  const handleAddEmails = useCallback(
    (c: SharedOrderCreateParams["sharedWithUserEmails"]) => {
      setEmails(
        uniqueByRep<SharedOrderCreateParams["sharedWithUserEmails"][number], string>(
          (e) => e.email
        )([...emails, ...c])
      )
    },
    [emails]
  )

  const handleRemoveEmails = useCallback(
    (c: SharedOrderCreateParams["sharedWithUserEmails"]) => {
      setEmails(removeByRep(emails, c, (contact) => contact.email))
    },
    [emails]
  )

  const { initialBody, initialSubject } = useMemo(
    () => ({
      initialBody: nonemptyHead(templates)(items, defaultIfLoading(companies, [])),
      initialSubject: `New ${commaSeparateWithAnd([
        ...new Set(items.map((i) => viewCompanyIdsFromShareableItem(i).name)),
      ])} ${pluralize("opportunity", items.length)}`,
    }),
    [companies, items, templates]
  )

  const [emailSubject, setEmailSubject] = useState<string>(initialSubject)
  const [emailBody, setEmailBody] = useState<JSONContent>(initialBody)

  const handleSendPreview = useCallback(async () => {
    notification.info({
      message: `Sending Preview to ${user.email}`,
      key: PREVIEW_SHARING_NOTIFICATION_KEY,
    })
    return sendSharedOrder({
      firebase9,
      sharedItems: items,
      user,
      sharedWithUserEmails: [
        { email: user.email, firstName: user.firstName, lastName: user.lastName },
      ],
      userForm: { emailBody, emailSubject },
      sendEmails: true,
    })
      .then(() =>
        notification.success({
          message: `Sent Preview to ${user.email}`,
          key: PREVIEW_SHARING_NOTIFICATION_KEY,
        })
      )
      .catch(() =>
        notification.warn({
          message: "Something went wrong sending preview.",
          key: PREVIEW_SHARING_NOTIFICATION_KEY,
        })
      )
  }, [firebase9, items, user, emailBody, emailSubject])

  const multiEditorContent = useArrayStepper<WorkingItems>([])

  const handleSetEditorMode = useCallback(
    (newMode: "group" | "individually_customized") => {
      setEditorMode(newMode)
      if (newMode === "individually_customized" && multiEditorContent) {
        multiEditorContent.setItems(
          emails.map((contact) => ({
            contact,
            emailBody: initialBody,
            emailSubject: initialSubject,
          }))
        )
      }
    },
    [emails, initialBody, initialSubject, multiEditorContent]
  )

  const handleShareOrders = useCallback(
    async ({ sendEmails }: { sendEmails: boolean }) => {
      onSend?.()
      notification.info({
        message: `Sharing to ${commaSeparateWithAnd(emails.map((e) => e.email))}`,
        key: ORDER_SHARING_NOTIFICATION_KEY,
      })
      const toSendEmails =
        editorMode === "individually_customized"
          ? multiEditorContent?.allItems.map((i) => ({
              sharedWithUserEmails: [i.contact],
              userForm: { emailBody: i.emailBody, emailSubject: i.emailSubject },
            }))
          : editorMode === "group"
          ? [{ sharedWithUserEmails: emails, userForm: { emailBody, emailSubject } }]
          : assertUnreachable(editorMode)

      return Promise.allSettled(
        toSendEmails.map((i) =>
          sendSharedOrder({
            firebase9,
            sharedItems: items,
            user,
            sharedWithUserEmails: i.sharedWithUserEmails,
            userForm: i.userForm,
            sendEmails,
          })
        )
      )
        .then(() =>
          notification.success({
            message: `Shared to ${commaSeparateWithAnd(emails.map((e) => e.email))}`,
            key: ORDER_SHARING_NOTIFICATION_KEY,
          })
        )
        .catch(() =>
          notification.warn({
            message: "Something went wrong sending.",
            key: ORDER_SHARING_NOTIFICATION_KEY,
          })
        )
    },
    [
      onSend,
      editorMode,
      multiEditorContent?.allItems,
      emails,
      emailBody,
      emailSubject,
      firebase9,
      items,
      user,
    ]
  )

  const data = useMemo(
    () => ({
      emails,
      handleAddEmails,
      handleRemoveEmails,
      handleShareOrders,
      preview: {
        handleSendPreview,
      },
      emailContent: {
        initialBody,
        initialSubject,
        emailSubject,
        emailBody,
        setEmailSubject,
        setEmailBody,
      },
      multiEditorContent,
      editorMode,
      setEditorMode: handleSetEditorMode,
    }),
    [
      emails,
      handleAddEmails,
      handleRemoveEmails,
      handleShareOrders,
      initialBody,
      initialSubject,
      handleSendPreview,
      emailSubject,
      emailBody,
      setEmailSubject,
      setEmailBody,
      multiEditorContent,
      editorMode,
      handleSetEditorMode,
    ]
  )

  return (
    <SharedOrderEditorContext.Provider value={data}>{children}</SharedOrderEditorContext.Provider>
  )
}

export const useSharedOrderEditor = () => useContext(SharedOrderEditorContext)
