import { PageTitle } from "@components/typography/PageTitle"
import Typography, { Size, Weight } from "@stories/components/Typography/Typography"
import { Link } from "react-router-dom"

const TopBrokerBenefitExplanation = ({
  title,
  subtitle,
}: {
  title: string
  subtitle: string[]
}) => (
  <div className="flex flex-col gap-4 items-start">
    <div>
      <Typography weight={Weight.Semibold} text={title} />
    </div>
    {subtitle.map((s) => (
      <div key={s}>
        <Typography size={Size.Small} text={s} />
      </div>
    ))}
  </div>
)

export const TopBrokerBenefits = () => (
  <div className="flex flex-col items-center">
    <div id="top-broker-benefits" />
    <PageTitle title="Top Broker Benefits" />
    <div className="grid grid-cols-2 items-center justify-center gap-4">
      <img
        alt="Top Broker Benefits"
        src="/assets/images/topBroker/recommended.png"
        className=" w-full"
      />
      <TopBrokerBenefitExplanation
        title="Get Recommended to More Clients"
        subtitle={[
          "Top Brokers will get suggested to unrepresented buy or sell orders placed directly by investors and shareholders on the platform based upon their market needs.",
          "In addition, Top Brokers get priority to represent new investors and shareholders who request platform access. These referrals are awarded based on alignment between the goals of the new investor / shareholder and the historical activity of the broker.",
        ]}
      />
    </div>
    <div className="grid grid-cols-2 items-center justify-center gap-4">
      <TopBrokerBenefitExplanation
        title="Enhanced Visibility on Live Market"
        subtitle={[
          "Orders represented by Top Brokers are promoted with a special designation and get ranked higher in the Live Market displays across the platform.",
        ]}
      />
      <img
        alt="Top Broker Benefits"
        src="/assets/images/topBroker/visibility.png"
        className=" w-full"
      />
    </div>
    <div className="grid grid-cols-2 items-center justify-center gap-4">
      <TopBrokerBenefitExplanation
        title="Increased Deal Flow Exposure"
        subtitle={[
          "Top Brokers can become the assigned Market Specialists for the Companies they are the most active in and will get recommended to users looking to transact in those markets.",
        ]}
      />
      <TopBrokerBenefitExplanation
        title="Early Access to New Broker Tooling"
        subtitle={[
          "Top Brokers will receive early access to valuable new features on Caplight for managing their order books and marketing to clients.",
        ]}
      />
    </div>
    <span className="flex justify-center font-semibold my-8 text-accent-500">
      <Link
        id="top-broker-learn-more"
        to={`${process.env.PUBLIC_URL}/assets/caplight-top-broker-program.pdf`}
        target="_blank"
        onClick={(e) => e.stopPropagation()}
      >
        View Top Broker PDF
      </Link>
    </span>
  </div>
)
