import { PriceObservationType } from "common/model/data-product/pricing/PriceObservation"
import { viewUserIdFields } from "common/model/User"
import { useState } from "react"
import { useFirebaseWriter } from "src/firebase/Context"
import { addPriceObservation } from "src/firebase/priceObservations"
import ClosedTradeFormDrawer from "src/pages/ClosedTrades/ClosedTradeForm/ClosedTradeFormDrawer"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"

export type ClosedTradeButtonProps = {
  drawerTitle?: string
  initialClosedTrade?: Partial<PriceObservationType>
  renderButton: (onClick: React.MouseEventHandler) => React.ReactNode
  afterSave?: (p: PriceObservationType | undefined) => void
}

const ClosedTradeButton = ({
  initialClosedTrade,
  renderButton,
  drawerTitle,
  afterSave,
}: ClosedTradeButtonProps) => {
  const [open, setOpen] = useState(false)
  const handleClick: React.MouseEventHandler = (e) => {
    e.stopPropagation()
    setOpen(!open)
  }
  const db = useFirebaseWriter()
  const user = useLoggedInUser()
  return (
    <>
      {renderButton(handleClick)}
      <ClosedTradeFormDrawer
        open={open}
        initialPriceObservation={initialClosedTrade ?? {}}
        onClose={() => setOpen(false)}
        onSave={(t) =>
          addPriceObservation(t, viewUserIdFields(user.user), db).then((priceDoc) => {
            const priceObservation = priceDoc.match(
              (doc) => doc.data(),
              () => undefined
            )
            afterSave?.(priceObservation)
            return priceDoc
          })
        }
        drawerTitle={drawerTitle}
      />
    </>
  )
}

export default ClosedTradeButton
