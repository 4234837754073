import { Order } from "common/model/Order/Models/Wrapped"
import { isTerminalOrderStatus, OrderStatusUpdate } from "common/model/Order/Types/Status"
import { head, last } from "common/utils/data/Array/ArrayUtils"
import { Just, Maybe, Nothing } from "common/containers/Maybe"
import { differenceInMinutes } from "date-fns"
import { useMemo } from "react"

// TODO: figure out how to get .reduce types to work and .reduce instead
const toPairs = <T extends unknown>(array: T[]): [T, T | null][] =>
  array.map((x, i) => [x, array[i + 1] ?? null])

export const getPriceUpdatesDescending = (order: Order, hiddenUpdateDates?: Set<number>) =>
  toPairs(
    [
      ...order
        .orderedUpdates()
        .toArray()
        .filter((update) => !hiddenUpdateDates?.has(update.date().valueOf())),
    ].reverse()
  )

export const useOrderUpdateLog = (order: Order) => {
  const priceUpdates = useMemo(() => getPriceUpdatesDescending(order), [order])
  return useMemo(() => ({ priceUpdates }), [priceUpdates])
}

const MIN_MINUTES_BETWEEN_UPDATES = 30
const LATEST_PRICE_UPDATES_COUNT = 5
export const useOrderLatestUpdates = (order: Order) => {
  const { priceUpdates } = useOrderUpdateLog(order)
  const hiddenUpdateDates = useMemo(
    () =>
      new Set(
        priceUpdates.flatMap(([update, previousUpdate]) =>
          previousUpdate === null
            ? []
            : differenceInMinutes(update.date(), previousUpdate.date()) <
              MIN_MINUTES_BETWEEN_UPDATES
            ? [previousUpdate.date().valueOf()]
            : []
        )
      ),
    [priceUpdates]
  )
  const latestPriceUpdates = useMemo(
    () => getPriceUpdatesDescending(order, hiddenUpdateDates).slice(0, LATEST_PRICE_UPDATES_COUNT),
    [order, hiddenUpdateDates]
  )

  const maybeTerminalStatus: Maybe<OrderStatusUpdate> = useMemo(() => {
    const orderExpiredByDate = !order.status().isMarketVisible()
      ? Just({
          tag: "cancelled",
          asOf: order.status().staleUntilDate(),
          // NOTE: not exactly true but we don't differentiate this in the UI
          statusUpdateType: "reported",
        } satisfies OrderStatusUpdate)
      : Nothing

    const orderExpiredByStatus = last(order.orderedStatusUpdates().toArray())
      .bind((statusUpdate) => (isTerminalOrderStatus(statusUpdate) ? Just(statusUpdate) : Nothing))
      .alongside(head(latestPriceUpdates))
      .bind(([terminalStatus, [latestPriceUpdate]]) =>
        terminalStatus.asOf.valueOf() > latestPriceUpdate.date().valueOf()
          ? Just(terminalStatus)
          : Nothing
      )
    return orderExpiredByStatus.or(orderExpiredByDate)
  }, [order, latestPriceUpdates])

  return useMemo(
    () => ({ latestPriceUpdates, maybeTerminalStatus }),
    [latestPriceUpdates, maybeTerminalStatus]
  )
}
