import { OrderFormState } from "common/model/Order/OrderForm/State"
import { LIVE_UNTIL_DEFAULT_DAYS } from "common/model/Order/OrderConstants"
import { useMemo } from "react"
import { isIntermediaryContext, useAuthContext } from "src/Routes/auth/UserAuthContext"
import { Order } from "common/model/Order/Order"

const notesSectionLabels = {
  title: "Notes",
  subtitles: {
    notes: "Add any notes you would like Caplight to know about this order (optional)",
  },
}

const liveUntilSectionLabels = {
  title: "How long should the order stay open?",
  subtitles: { liveUntil: `(Orders open for ${LIVE_UNTIL_DEFAULT_DAYS} days by default)` },
}

export const buildDarkpoolVisibilityTooltip = (direction?: Order["direction"]) =>
  `A Darkpool order is only visible to you. Caplight will contact you if there is a potential match but will not inform anyone about your ${
    direction ? (direction === "buy" ? "bid" : "offer") : "order"
  }.`

export const buildMarketVisibilityTooltip = (direction?: Order["direction"]) =>
  `A Live Market order is published on Caplight. Other parties can Connect to your ${
    direction ? (direction === "buy" ? "bid" : "offer") : "order"
  } with their ${direction ? (direction === "buy" ? "offers" : "bids") : "orders"}.`

export const useFormOrderLabels = (formOrder: OrderFormState) => {
  const ctx = useAuthContext()
  const clientSectionLabels = useMemo(
    () => ({
      title: "Client Details",
      subtitles: {
        clientRelationship: "What is your relationship with your client?",
      },
    }),
    []
  )

  const structureSectionLabels = useMemo(
    () => ({
      title: "Structure",
      subtitles: {
        structure:
          formOrder.direction === "buy"
            ? isIntermediaryContext(ctx)
              ? "Which trade structures is your client open to?"
              : "Which trade structures are you open to?"
            : isIntermediaryContext(ctx)
            ? "Which trade structures can your client sell through?"
            : "Which trade structures can you sell through?",
        carryManagement:
          formOrder.direction === "buy"
            ? `What are the maximum fees ${
                isIntermediaryContext(ctx) ? "your client is" : "you are"
              } willing to pay?`
            : "Economics",
        isMultiLayerSpv: "Is this a Single-layer or Multi-layer SPV?",
        isMultiLayerSpvTooltip:
          "A single-layer SPV is a fund that holds shares directly. A multi-layer SPV is a fund that owns shares in another fund.",
      },
    }),
    [ctx, formOrder.direction]
  )

  const shareClassSectionLabels = useMemo(
    () => ({
      title: "Share Class",
      subtitles: {
        shareClass:
          formOrder.direction === "buy"
            ? isIntermediaryContext(ctx)
              ? "What share classes is your client interested in?"
              : "What share classes are you interested in?"
            : "What share classes are offered?",
        shareClassSeries: "Which series of Preferred Stock? (optional)",
        shareClassSeriesTooltip:
          "Not publicized on the live market, only for Caplight informational purposes.",
      },
    }),
    [formOrder.direction, ctx]
  )

  const termSectionLabels = useMemo(
    () => ({
      title: "Terms",
      subtitles: {
        size: "Size",
        price: "What is the price of the order?",
        canChargeClientFee: "Broker Fees",
        orderPriceTooltip: isIntermediaryContext(ctx)
          ? `${formOrder.direction === "buy" ? "Bid" : "Offer"} price, omitting any broker fees`
          : formOrder.direction === "buy"
          ? "Price you are willing to pay. Include broker fees in your price."
          : "Price at which you are willing to sell, net of any broker fees.",
        yesCanChargeFeeOption: `I am covered on fees for this ${
          formOrder.direction === "buy" ? "bid" : "offer"
        }.`,
        noCanChargeFeeOption: `I cannot charge fees and need to split fees with the ${
          formOrder.direction === "buy" ? "sellers's" : "buyers's"
        } broker.`,
        unknownCanChargeFeeOption: "I have not discussed fees yet with my client.",
        orderPrice: isIntermediaryContext(ctx) ? "Gross Price" : "Price",
        commissionNotesLabel: "Optional: Please provide details/context",
        commissionNotesPlaceholder: "e.g. 'My normal fee is 2-3% but will be dependent on pricing'",
        commissionNotesHintText:
          "This information is only visible to Caplight to improve the matching process.",
      },
    }),
    [ctx, formOrder.direction]
  )

  const documentSectionLabels = useMemo(
    () => ({
      title: "Execution Details",
      subtitles: {
        diligenceAvailable: "Diligence available to serious buyers?",
        requiresDiligence: isIntermediaryContext(ctx)
          ? "Does your client require diligence on the company?"
          : "Do you require diligence on the company?",
        buyerCashOnHand: isIntermediaryContext(ctx)
          ? "Does your client have cash on hand for this transaction?"
          : "Do you have cash on hand for this transaction?",

        buyerCashOnHandYesAnswer: isIntermediaryContext(ctx)
          ? "Has funds on hand"
          : "Already have funds",
        buyerCashOnHandNoAnswer: isIntermediaryContext(ctx)
          ? "Requires capital call"
          : "Need to capital call",
        buyerCashOnHandUnknownAnswer: isIntermediaryContext(ctx) ? "Unconfirmed" : "",
      },
    }),
    [ctx]
  )

  const directionSectionLabels = useMemo(
    () => ({
      title: formOrder.direction
        ? "Direction"
        : isIntermediaryContext(ctx)
        ? "Is your client a buyer or seller?"
        : "Are you a buyer or seller?",
    }),
    [formOrder.direction, ctx]
  )

  const marketVisibilitySectionLabels = useMemo(
    () => ({
      title: "Market Visibility",
      subtitles: {
        darkpoolVisibilityTooltip: buildDarkpoolVisibilityTooltip(formOrder.direction),
        marketVisibleVisibilityTooltip: buildMarketVisibilityTooltip(formOrder.direction),
      },
    }),
    [formOrder.direction]
  )

  return {
    directionSectionLabels,
    marketVisibilitySectionLabels,
    clientSectionLabels,
    structureSectionLabels,
    shareClassSectionLabels,
    termSectionLabels,
    documentSectionLabels,
    notesSectionLabels,
    liveUntilSectionLabels,
  }
}
