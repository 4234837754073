import { wrapIcon } from "./IconWrapper"

export const ContributeIcon = wrapIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.33317 9.88338V12C7.33317 12.1889 7.39717 12.3474 7.52517 12.4754C7.65317 12.6034 7.81139 12.6672 7.99984 12.6667C8.18873 12.6667 8.34717 12.6027 8.47517 12.4747C8.60317 12.3467 8.66695 12.1885 8.66651 12V9.88338L9.26651 10.4834C9.33317 10.55 9.40828 10.6 9.49184 10.6334C9.57539 10.6667 9.65873 10.6807 9.74184 10.6754C9.82495 10.6696 9.90539 10.65 9.98317 10.6167C10.061 10.5834 10.1332 10.5334 10.1998 10.4667C10.3221 10.3334 10.3861 10.1778 10.3918 10C10.3976 9.82226 10.3336 9.66671 10.1998 9.53338L8.46651 7.80004C8.39984 7.73338 8.32762 7.68626 8.24984 7.65871C8.17206 7.63115 8.08873 7.61715 7.99984 7.61671C7.91095 7.61671 7.82762 7.63071 7.74984 7.65871C7.67206 7.68671 7.59984 7.73382 7.53317 7.80004L5.79984 9.53338C5.66651 9.66671 5.60273 9.82226 5.60851 10C5.61428 10.1778 5.68362 10.3334 5.81651 10.4667C5.94984 10.5889 6.10539 10.6529 6.28317 10.6587C6.46095 10.6645 6.61651 10.6005 6.74984 10.4667L7.33317 9.88338ZM3.99984 14.6667C3.63317 14.6667 3.31917 14.536 3.05784 14.2747C2.7965 14.0134 2.66606 13.6996 2.66651 13.3334V2.66671C2.66651 2.30004 2.79717 1.98604 3.0585 1.72471C3.31984 1.46338 3.63362 1.33293 3.99984 1.33338H8.78317C8.96095 1.33338 9.13051 1.36671 9.29184 1.43338C9.45317 1.50004 9.59473 1.59449 9.71651 1.71671L12.9498 4.95004C13.0721 5.07226 13.1665 5.21404 13.2332 5.37538C13.2998 5.53671 13.3332 5.70604 13.3332 5.88338V13.3334C13.3332 13.7 13.2025 14.014 12.9412 14.2754C12.6798 14.5367 12.3661 14.6672 11.9998 14.6667H3.99984ZM8.66651 5.33338V2.66671H3.99984V13.3334H11.9998V6.00004H9.33317C9.14428 6.00004 8.98584 5.93604 8.85784 5.80804C8.72984 5.68004 8.66606 5.52182 8.66651 5.33338Z"
      fill="currentColor"
    />
  </svg>
)
