import moment from "moment-timezone"
import { FC, ReactElement, useEffect, useState } from "react"
import { classNames } from "../../utils/classNames"

interface CountdownTimerProps {
  targetDate: Date
  render?: (
    hours: number | undefined,
    minutes: number | undefined,
    seconds: number | undefined
  ) => ReactElement
}

const calculateTimerSeconds = (targetDate: Date) => {
  const diff = moment(targetDate).diff(moment(), "seconds")
  return Math.max(0, diff)
}

export const CountdownTimer: FC<React.PropsWithChildren<CountdownTimerProps>> = ({
  targetDate,
  render,
}) => {
  const [timerSeconds, setTimerSeconds] = useState<number | undefined>(
    calculateTimerSeconds(targetDate)
  )

  useEffect(() => {
    const timer = setInterval(() => {
      setTimerSeconds(calculateTimerSeconds(targetDate))
    }, 1000)
    return () => clearTimeout(timer)
  })

  const SECONDS_IN_DAY = 60 * 60 * 24
  const SECONDS_IN_HOUR = 60 * 60
  const SECONDS_IN_MIN = 60

  const daysRemaining =
    timerSeconds !== undefined ? Math.floor(timerSeconds / SECONDS_IN_DAY) : undefined

  const hoursRemaining =
    timerSeconds !== undefined
      ? Math.floor((timerSeconds - (daysRemaining || 0) * SECONDS_IN_DAY) / SECONDS_IN_HOUR)
      : undefined

  const minsRemaining =
    hoursRemaining !== undefined
      ? Math.floor(((timerSeconds || 0) - SECONDS_IN_HOUR * hoursRemaining) / SECONDS_IN_MIN)
      : undefined

  const secsRemaining = timerSeconds !== undefined ? timerSeconds % 60 : undefined

  const daysLabel = daysRemaining && daysRemaining === 1 ? "day" : "days"
  const hoursLabel = hoursRemaining && hoursRemaining === 1 ? "hour" : "hours"
  const minsLabel = minsRemaining && minsRemaining === 1 ? "min" : "mins"
  const secsLabel = secsRemaining && secsRemaining === 1 ? "second" : "seconds"

  if (render) return render(hoursRemaining, minsRemaining, secsRemaining)
  if (daysRemaining) {
    // days and hours remaining
    return (
      <div className="flex justify-center">
        <div>
          {daysRemaining} {daysLabel}
        </div>
        ,{" "}
        <div className="ml-1">
          {hoursRemaining || 0} {hoursLabel}
        </div>
      </div>
    )
  }
  if (hoursRemaining) {
    // hours and minutes remaining
    return (
      <div className="flex justify-center">
        <div>
          {hoursRemaining} {hoursLabel}
          {minsRemaining && ","}
        </div>
        {minsRemaining !== undefined && minsRemaining > 0 && (
          <div className="ml-1">
            {minsRemaining} {minsLabel}
          </div>
        )}
      </div>
    )
  }
  // minutes & seconds remaining
  return (
    <div
      className={classNames("flex justify-center", secsRemaining === undefined ? "invisible" : "")}
    >
      {minsRemaining !== undefined && minsRemaining > 0 && (
        <div>
          {minsRemaining} {minsLabel},
        </div>
      )}
      <div className="ml-1">
        {secsRemaining} {secsLabel}
      </div>
    </div>
  )
}
