import pluralize from "pluralize"
import { Order } from "common/model/Order/Models/Wrapped"
import {
  createOrderInquiryUserForm,
  OrderInquiryUserFormState,
} from "common/model/OrderInquiry/UserFormState"
import { collections } from "common/firestore/Collections"
import { constructOrderInquiry, createDbOrderInquiry } from "common/model/OrderInquiry/Constructors"
import { viewAccountIdFields } from "common/model/Account"
import { viewUserIdFields } from "common/model/User"
import { OrderInquirySource } from "common/model/OrderInquiry/Db"
import { useFirebaseWriter } from "../../../firebase/Context"
import { useLoggedInUser } from "../../../providers/loggedInUser/useLoggedInUser"
import { SubmissionStatus } from "../types"
import OrderInquiryForm from "../components/OrderInquiryForm"
import { FirebaseWriter } from "../../../firebase/Firebase"
import { useErrorHandler } from "../../../providers/errorHandler"
import { useFirebase9 } from "../../../firebase/Firebase9Context"
import {
  handleConsoleError,
  trackEventInFirestoreAndHeap,
  trackUserEventInFirestore,
} from "../../../utils/Tracking"
import { trackAccountActivationFirebase9 } from "src/utils/AccountActivation"
import Alert from "@stories/components/Alert/Alert"
import { InfoCircleOutlined } from "@ant-design/icons"

const saveOrderInquiry = ({
  writerDb,
  source,
  formState,
}: Pick<FirebaseWriter, "writerDb"> &
  OrderInquirySource & { formState: OrderInquiryUserFormState }): Promise<void> => {
  const { userForm } = createOrderInquiryUserForm(formState)

  const constructedInquiry = constructOrderInquiry({ userForm, source })

  const orderInquiryRef = writerDb.collection(collections.orderInquiries).doc()

  const orderInquiry = createDbOrderInquiry({ id: orderInquiryRef.id, constructedInquiry })

  return orderInquiryRef.set(orderInquiry)
}

const OrderInquiryRemainingCount = ({ count }: { count: number }) => (
  <Alert
    headline="Inquiries Remaining"
    icon={<InfoCircleOutlined />}
    message={
      count > 0
        ? `You have ${count} ${pluralize("inquiry", count)} remaining this month.`
        : "You have used all your inquiries this month."
    }
    variant="accent"
  />
)

const OrderInquiryDrawerUnsubumittedView = ({
  count,
  order,
  submissionStatus,
  setSubmissionStatus,
}: {
  count: number
  order: Order
  submissionStatus: SubmissionStatus
  setSubmissionStatus: (s: SubmissionStatus) => void
}) => {
  const { user } = useLoggedInUser()
  const { writerDb } = useFirebaseWriter()
  const firebase9 = useFirebase9()
  const inquirySource: OrderInquirySource["source"] = {
    account: viewAccountIdFields(user.account),
    user: viewUserIdFields(user),
    anchorOrder: order.idFields(),
  }

  const { handleError } = useErrorHandler()

  return (
    <div className="flex flex-col gap-4">
      <p>
        Check with the counterparty behind this order to ensure that it is still live and the terms
        provided are up-to-date. You can also request further details on the order from this form.
      </p>
      <OrderInquiryRemainingCount count={count} />
      {count > 0 ? (
        <>
          <div className="flex flex-col space-y-4">
            <h6 className="font-bold">How this works:</h6>
            <p>
              Inquiries are <b>fully anonymous</b>
            </p>
            <p>When inquiring on an order, Caplight will confirm the following:</p>
            <ul className="pl-4 list-disc font-bold">
              <li>Liveness and realness of the order </li>
              <li>Specified terms are accurate</li>
            </ul>
            <p>
              If you have any additional questions you’d like Caplight to ask anonymously on your
              behalf, please use the form below.
            </p>
          </div>
          <OrderInquiryForm
            submitButtonId={`caplight-inquire-submit-${order.id()}`}
            onSubmit={async (formState) => {
              setSubmissionStatus("submitting")
              try {
                await saveOrderInquiry({
                  writerDb,
                  source: inquirySource,
                  formState,
                }).then(() => {
                  trackEventInFirestoreAndHeap(firebase9, user, "order-inquiry-submitted", {
                    orderDirection: order.direction,
                    companyName: order.company().name,
                    companyId: order.company().id,
                    orderSize: order
                      .amountUSD()
                      .map((interval) => interval.upperBound)
                      .toNullable(),
                  })
                  trackAccountActivationFirebase9(firebase9, user.account, "placedAnInquiry").catch(
                    handleConsoleError
                  )
                })
                setSubmissionStatus("success")
              } catch (e) {
                setSubmissionStatus("failure")
                if (e instanceof Error) handleError(e)
              }
            }}
            submitting={submissionStatus === "submitting"}
          />
        </>
      ) : null}
    </div>
  )
}

export default OrderInquiryDrawerUnsubumittedView
