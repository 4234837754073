import { useAccountWatchlist } from "@components/Watchlist/useAccountWatchlist"
import { mapLoading } from "common/utils/Loading"
import { useMemo } from "react"
import { AllFilters, FilterFnRecord, Variant } from "common/model/newsfeed/NewsFeed"
import { afterSeries } from "./AfterSeries"
import { amountAbove } from "./AmountAbove"
import { excludeCompaniesWithoutIoIs } from "./CompaniesWithOrders"
import { companyFilter } from "./Company"
import { excludePublic } from "./IncludePublic"
import { investorFilter } from "./Investors"
import { onlyHighlighted } from "./OnlyHighlighted"
import { onlyWatchlist } from "./OnlyWatchlist"
import { parseSections, sectorIsIn } from "./Sectors"
import { valuationAbove } from "./ValuationAbove"
import { WatchlistItem } from "common/model/Watchlist"

export const useWatchlistPostgresIds = () => {
  const watchlistLoading = useAccountWatchlist()

  const watchlistPostgresIds = useMemo(
    () =>
      mapLoading<WatchlistItem[], string[]>((accountWatchlist) =>
        accountWatchlist.map((item) => item.company.postgresCompanyId)
      )(watchlistLoading),
    [watchlistLoading]
  )

  return watchlistPostgresIds
}

export const getFilterFunctions = <V extends Variant>(
  variant: V,
  rawFilterValues: FilterFnRecord[V]["asStrings"][]
) => {
  const filterFunctions: Record<AllFilters, (v: string) => FilterFnRecord[V]["function"]> = {
    excludePublic: () => excludePublic[variant].function,
    onlyWatchlist: (watchlistIds) => onlyWatchlist(watchlistIds)[variant].function,
    excludeCompaniesWithoutIoIs: () => excludeCompaniesWithoutIoIs[variant].function,
    fundingRoundAbove: (series) => afterSeries(series)[variant].function,
    amountAbove: (numString) => amountAbove(parseFloat(numString))[variant].function,
    company: (company) => companyFilter(company)[variant].function,
    investor: (investor) => investorFilter(investor)[variant].function,
    companyPbid: () => () => true,
    valuationAbove: (numString) => valuationAbove(parseFloat(numString))[variant].function,
    sectors: (sectors) => sectorIsIn(parseSections(sectors))[variant].function,
    onlyHighlighted: () => onlyHighlighted[variant].function,
    afterDate: () => () => true,
    beforeDate: () => () => true,
  }

  const filterValues: Record<
    AllFilters,
    (v: string) => FilterFnRecord[V]["asStrings"] | undefined
  > = {
    excludePublic: () => excludePublic[variant].asStrings,
    onlyWatchlist: (watchlistIds) => onlyWatchlist(watchlistIds)[variant].asStrings,
    excludeCompaniesWithoutIoIs: () => excludeCompaniesWithoutIoIs[variant].asStrings,
    fundingRoundAbove: (series) => afterSeries(series)[variant].asStrings,
    amountAbove: (numString) => amountAbove(parseFloat(numString))[variant].asStrings,
    company: (company) => companyFilter(company)[variant].asStrings,
    investor: (investor) => investorFilter(investor)[variant].asStrings,
    companyPbid: () => ({ field: "companyPbid", value: "" }),
    valuationAbove: (numString) => valuationAbove(parseFloat(numString))[variant].asStrings,
    sectors: (sectors) => sectorIsIn(parseSections(sectors))[variant].asStrings,
    onlyHighlighted: () => onlyHighlighted[variant].asStrings,
    afterDate: () => ({ field: "afterDate", value: "" }),
    beforeDate: () => ({ field: "beforeDate", value: "" }),
  }

  return [
    rawFilterValues.flatMap((f) => filterFunctions[f.field](f.value) ?? []),
    rawFilterValues.flatMap((f) => filterValues[f.field](f.value) ?? []),
  ] as const
}
