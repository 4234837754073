import * as z from "zod"
import { RequireFields } from "../utils/data/Record/Types/Fields"
import { Left, Right } from "../containers/Either"
import { Record } from "../utils/fp/Eq"
import { SimplePrism } from "../utils/fp/optics/Prism"
import { CompanyFundingRound } from "./Company/FundingRound"
import { CompanyMarketHistorySummary } from "./CompanyMarketHistorySummary"
import { CompanyPriceEstimateSummary } from "./CompanyPriceEstimateSummary"
import { StockSplit, stockSplitSchema } from "./Stock/StockSplit"
import { AdditionalTradingContext } from "./AdditionalTradingContext"
import { CompanyFiling } from "./CompanyFiling"
import { CompanyValue, Valuation409a } from "./CompanyValuation"
import { AnonymousPriceObservation } from "./data-product/pricing/PriceObservation"
import { assertExtends } from "../utils/data/Type/Assertions"

export type CompanyPostgresId = string

export type SelectedCompany = Pick<Company, "status" | "logos"> &
  CompanyIdFieldsWithPostgres & {
    lastRoundDate?: Date | undefined | null
    lastRoundPostMoneyValuation?: number | undefined | null
  }
assertExtends<CompanyWithPostgres, SelectedCompany>()

export const selectedCompanyFromCompany = (
  company: Company & CompanyIdFieldsWithPostgres
): SelectedCompany => ({
  ...company,
  lastRoundDate: company.fundingRoundsSummary?.lastRound?.date || null,
  lastRoundPostMoneyValuation: company.fundingRoundsSummary?.lastKnownValuation?.valuation || null,
})

export interface CompanyIdFields {
  id: string
  airtableId: string
  name: string
  pbid?: string // pitchbook id
  postgresCompanyId?: string
}

export type CompanyIdFieldsWithPostgres = RequireFields<CompanyIdFields, "postgresCompanyId">

export namespace CompanyIdFields {
  export const eq = Record.eq<CompanyIdFields>({
    id: () => true,
    airtableId: () => true,
    name: () => true,
    pbid: (l, r) => l === r,
    postgresCompanyId: (l, r) => l === r,
  })
}

export const eqCompanyIdFields = Record.eq<CompanyIdFields>({
  id: () => true,
  airtableId: () => true,
  name: () => true,
  pbid: (l, r) => l === r,
  postgresCompanyId: (l, r) => l === r,
})

// Isolates exactly the fields on a CompanyIdFields to, for instance, avoid accidentally inserting an entire `Company` into the db.
export const viewCompanyIdFields = (c: unknown & CompanyIdFields) => ({
  id: c.id,
  airtableId: c.airtableId,
  name: c.name,
  ...(c.pbid ? { pbid: c.pbid } : {}),
  ...(c.postgresCompanyId ? { postgresCompanyId: c.postgresCompanyId } : {}),
})

export const briefDescription = (company: Company) => {
  if (!company.description) return "N/A"
  return company.description.split(".")[0]
}

export const extremelyBriefDescription = (company: Company) => {
  if (!company.description) return "N/A"
  const firstSentence = company.description.split(".")[0]
  const firstWords = firstSentence.split(" ").splice(0, 5)
  return `${firstWords.join(" ")}...`
}
export interface FundingRoundsSummary {
  lastRound?: {
    date: Date
    valuation?: number | null
    pps?: number | null
    roundName?: string
  }
  lastKnownValuation?: {
    date: Date
    valuation: number
    roundName?: string
  }
  lastKnownPPS?: {
    date: Date
    pps: number
    roundName?: string
  }
  lastKnownShareCount?: {
    date: Date
    shareCount: number
    roundName?: string
  }
}

export interface ValuationSummary {
  valuation: number | null
  asOfDate: Date | null
  tag: "public_market_cap" | "last_known_funding_round" // we can later add marketprice etc
}

export const companyStatuses = ["Private", "Public", "Acquired", "Out of business"] as const
export type CompanyStatus = (typeof companyStatuses)[number]

export const ipoTypes = [
  "unknown",
  "SPAC",
  "IPO (regular)",
  "IPO (direct listing)",
  "IPO (unknown non-SPAC)",
  "Other",
] as const

export type IpoType = (typeof ipoTypes)[number]

export const isCompanyPublic = (company: Pick<Company, "statusDetails">): boolean =>
  company.statusDetails.status === "Public"

export interface Company extends CompanyIdFields {
  cartaId?: string | null
  ticker: string | null
  /** @deprecated use statusDetails.status */
  status?: CompanyStatus | null
  statusDetails: {
    status: CompanyStatus | null
    // statusHistory: TODO add
    yearFounded: number | null
    latestPublicOffering: {
      date: Date | null
      offeringType: IpoType
      daysSincePublic: number | null
    } | null
    ipoCandidate: boolean | null
  }
  valuationSummary: ValuationSummary | null
  score?: number | null // number between 1 - 10
  /** @warning Use functions to calculate valuations */
  fundingRoundsSummary?: FundingRoundsSummary
  fundingRounds: CompanyFundingRound[]
  stockSplits: Omit<StockSplit, "scope">[]
  description?: string
  publicCompTickers: string[]
  descriptionSource?: string
  pbid?: string
  /** @deprecated Use CompanyLogo instead */
  logoUrl?: string
  /** @deprecated Use CompanyLogo instead */
  squareLogoUrl?: string
  /** @deprecated Use isActiveTwoWayMarketIssuer instead */
  isTargetIssuer?: boolean
  isActiveTwoWayMarketIssuer?: boolean
  hasMarketActivityInPastYear?: boolean // Used in Algolia index to indicate if a company has order/trade data in the past year or a MarketPrice
  isSupplyConstrained?: boolean
  isDemandConstrained?: boolean
  isHighlighted?: boolean
  showOnCompaniesTable?: boolean
  isDataExportEnabled?: boolean // Data CSV export from the company details page

  lastTrade?: AnonymousPriceObservation
  domain?: string
  logos?: CompanyLogos
  spot?: CompanyValue
  aggregations: {
    totalOrders: number
    totalTrades: number
    totalFundMarks?: number
  }
  settings?: {
    showCaplightPriceEstimate?: boolean
    useCaplightPriceEstimateAsCurrentMarket?: boolean
  }

  priceEstimatesSummary?: CompanyPriceEstimateSummary
  marketHistorySummary?: CompanyMarketHistorySummary

  valuations409a: Valuation409a[]

  industry?: {
    caplightPrimaryIndustry?: string
  }

  additionalTradingContext?: AdditionalTradingContext

  filings: CompanyFiling[]
}

/** extend this to cover all of `Company` over time */
export const checkedCompanyFieldsSchema: z.Schema<Pick<Company, "stockSplits">> = z.object({
  stockSplits: z.array(stockSplitSchema),
})

export type CompanyWithPostgres = RequireFields<Company, "postgresCompanyId">

type LogoURL = string

export type CompanyLogos = { [key in LogoSize]: LogoURL }

export type LogoSize = "xxs" | "xs" | "sm" | "md" | "lg"

/** Static logo sizes, change this template to alter seed logo sizing */
export const logoSizes: { [key in LogoSize]: string } = {
  xxs: "18",
  xs: "25",
  sm: "64",
  md: "128",
  lg: "256",
}

/** util functions */
export const companyInPostgres = (c: Company): c is CompanyWithPostgres =>
  c.postgresCompanyId !== undefined
export const companyIdsInPostgres = (c: CompanyIdFields): c is CompanyIdFieldsWithPostgres =>
  c.postgresCompanyId !== undefined

export const unsafeAssertCompanyInPostgres = (c: Company): CompanyWithPostgres =>
  companyInPostgres(c)
    ? c
    : (() => {
        throw new Error(`Company ${c.id} has no postgres id`)
      })()

export const hasMarketPrice = (c: Company): boolean =>
  !!c.settings?.showCaplightPriceEstimate &&
  !!c.priceEstimatesSummary?._1WeekAgoPrice?.priceEstimatePPS

export const defaultCompanyFields: Pick<
  Company,
  | "aggregations"
  | "valuations409a"
  | "publicCompTickers"
  | "fundingRounds"
  | "statusDetails"
  | "filings"
  | "ticker"
  | "valuationSummary"
> = {
  valuations409a: [],
  publicCompTickers: [],
  aggregations: {
    totalOrders: 0,
    totalTrades: 0,
  },
  fundingRounds: [],
  statusDetails: {
    status: null,
    yearFounded: null,
    latestPublicOffering: null,
    ipoCandidate: null,
  },
  filings: [],
  ticker: null,
  valuationSummary: null,
}

export const companyPrism: SimplePrism<Company, Company> = {
  preview: (s) => (s.pbid ? Right(s) : Left(s)),
  review: (s) => s,
}

/**
 * Strips pitchbook-included sector parenthetical from company names
 * e.g. "Cohere (Business/Productivity Software)" -> "Cohere"
 */
export const companyNameWithoutSector = (companyName: string): string =>
  companyName.replace(/ \(.+\)/, "")

export const companyHasMarketData = (company: Company): boolean =>
  company.aggregations.totalOrders > 0 ||
  company.aggregations.totalTrades > 0 ||
  (company.aggregations.totalFundMarks ?? 0) > 0
