import { CurrentUser } from "@model/CurrentUser"
import { DatePicker } from "@stories/components/Antd"
import {
  NumberField,
  sizeScaledInputWarning,
  valuationScaledInputWarning,
} from "@stories/components/Inputs/NumberInput/NumberField"
import Typography, { Size } from "@stories/components/Typography/Typography"
import {
  DealCRMInterest,
  DealCRMInterestShareClass,
  DealCRMInterestStructure,
  isInterestCancelled,
} from "common/model/DealCRM/DealCRMInterest"
import { displayDealCRMInterestStructure } from "common/model/DealCRM/utils/displayDealCRMInterestStructure"
import { Interval } from "common/utils/data/Interval"
import {
  formatAbbreviatedCurrency,
  formatRoundedAbbreviatedCurrency,
} from "common/utils/math/format"
import { capitalize } from "lodash"
import { FirebaseWriter } from "src/firebase/Firebase"
import { SelectField } from "../Components/DealParticipantFieldDisplay/DealParticipantFieldDisplay"
import { handleInterestEdit } from "./handleInterestEdit"
import moment from "moment"
import { isToday, isYesterday } from "date-fns"

export const allowEdit = (
  crmInterest: Pick<DealCRMInterest, "firmness" | "publishedOrder" | "stage">
) => !isInterestCancelled(crmInterest)

export const CRMInterestPriceTargetField = ({
  db,
  crmInterest,
  currentUser,
  afterUpdate,
}: {
  db: FirebaseWriter
  crmInterest: DealCRMInterest
  currentUser: CurrentUser
  afterUpdate: (interest: Pick<DealCRMInterest, "direction">) => void
}) => (
  <NumberField
    value={crmInterest.targetPrice}
    onChange={async (targetPrice) =>
      targetPrice !== crmInterest.targetPrice
        ? handleInterestEdit({
            interest: crmInterest,
            user: currentUser.user,
            db,
            afterUpdate: () => afterUpdate(crmInterest),
            interestUpdate: {
              targetPrice,
            },
          })
        : null
    }
    formatter={(v) => `${formatAbbreviatedCurrency(v)}`}
    isDisabled={!allowEdit(crmInterest)}
    noBackground
    noBorder
  />
)

export const CRMInterestTargetValuationField = ({
  db,
  crmInterest,
  currentUser,
  afterUpdate,
}: {
  db: FirebaseWriter
  crmInterest: DealCRMInterest
  currentUser: CurrentUser
  afterUpdate: (interest: Pick<DealCRMInterest, "direction">) => void
}) => (
  <NumberField
    value={crmInterest.targetValuation}
    onChange={async (targetValuation) =>
      targetValuation !== crmInterest.targetValuation
        ? handleInterestEdit({
            interest: crmInterest,
            user: currentUser.user,
            db,
            afterUpdate: () => afterUpdate(crmInterest),
            interestUpdate: {
              targetValuation,
            },
          })
        : null
    }
    formatter={(v) => `${formatRoundedAbbreviatedCurrency(v)}`}
    inputWarningFunction={valuationScaledInputWarning}
    isDisabled={!allowEdit(crmInterest)}
    noBackground
    noBorder
    tooltipHintText="Hint: try '2.5b' for 2.5 billion"
  />
)

export const CRMInterestAmountUSDField = ({
  db,
  crmInterest,
  currentUser,
  afterUpdate,
}: {
  db: FirebaseWriter
  crmInterest: DealCRMInterest
  currentUser: CurrentUser
  afterUpdate: (interest: Pick<DealCRMInterest, "direction">) => void
}) => (
  <NumberField
    value={crmInterest.amountUSD?.lowerBound ?? null}
    onChange={async (amount) =>
      amount !== crmInterest.amountUSD
        ? handleInterestEdit({
            interest: crmInterest,
            user: currentUser.user,
            db,
            afterUpdate: () => afterUpdate(crmInterest),
            interestUpdate: { amountUSD: amount ? Interval.pure(amount) : null },
          })
        : null
    }
    formatter={(v) => `${formatAbbreviatedCurrency(v)}`}
    inputWarningFunction={sizeScaledInputWarning}
    isDisabled={!allowEdit(crmInterest)}
    noBackground
    noBorder
    tooltipHintText="Hint: try '1.5m' for 1.5 million"
  />
)

export const CRMInterestStructureField = ({
  db,
  crmInterest,
  currentUser,
  afterUpdate,
}: {
  db: FirebaseWriter
  crmInterest: DealCRMInterest
  currentUser: CurrentUser
  afterUpdate: (interest: Pick<DealCRMInterest, "direction">) => void
}) => (
  <SelectField<DealCRMInterestStructure | null>
    size="small"
    value={crmInterest.structure}
    options={["direct", "spv", "forward"]}
    renderValue={(s) => (
      <Typography size={Size.Small} text={s ? displayDealCRMInterestStructure(s) : null} />
    )}
    handleChange={(structure) =>
      handleInterestEdit({
        interest: crmInterest,
        user: currentUser.user,
        db,
        afterUpdate: () => afterUpdate(crmInterest),
        interestUpdate: {
          structure,
        },
      })
    }
    editable={allowEdit(crmInterest)}
  />
)

export const CRMInterestShareClassField = ({
  db,
  crmInterest,
  currentUser,
  afterUpdate,
}: {
  db: FirebaseWriter
  crmInterest: DealCRMInterest
  currentUser: CurrentUser
  afterUpdate: (interest: Pick<DealCRMInterest, "direction">) => void
}) => (
  <SelectField<DealCRMInterestShareClass | null>
    size="small"
    value={crmInterest.shareClass}
    options={["common", "preferred"]}
    renderValue={(v) => <Typography size={Size.Small} text={capitalize(v ?? undefined)} />}
    handleChange={(shareClass) =>
      handleInterestEdit({
        interest: crmInterest,
        user: currentUser.user,
        db,
        afterUpdate: () => afterUpdate(crmInterest),
        interestUpdate: {
          shareClass,
        },
      })
    }
    editable={allowEdit(crmInterest)}
  />
)

export const CRMInterestOrderOriginationDateField = ({
  db,
  crmInterest,
  currentUser,
  afterUpdate,
}: {
  db: FirebaseWriter
  crmInterest: DealCRMInterest
  currentUser: CurrentUser
  afterUpdate?: (interest: Pick<DealCRMInterest, "direction">) => void
}) => (
  <DatePicker
    size="small"
    className="w-full pl-0 pr-0 text-xs"
    clearIcon={false}
    onChange={(v) =>
      handleInterestEdit({
        interest: crmInterest,
        user: currentUser.user,
        db,
        interestUpdate: v ? { orderOriginationDate: v.toDate() } : {},
        afterUpdate: () => (afterUpdate ? afterUpdate(crmInterest) : Promise.resolve()),
      })
    }
    value={crmInterest.orderOriginationDate ? moment(crmInterest.orderOriginationDate) : undefined}
    format={(d) =>
      isToday(d.toDate()) ? "Today" : isYesterday(d.toDate()) ? "Yesterday" : d.format("YYYY-MM-DD")
    }
  />
)
