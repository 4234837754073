import { AirtableContact } from "common/model/airtable/AirtableContact"
import { Loading, matchLoading } from "common/utils/Loading"
import { createContext, useContext, useMemo } from "react"
import { APIEndpoints, useAPIQuery } from "../../firebase/API"
import { AirtableRecord } from "../../model/airtable/AirtableRecord"
import { useLoggedInUser } from "../loggedInUser/useLoggedInUser"

export const AdminAirtableContactsContext = createContext<Loading<AirtableContact[]>>("loading")

export const AdminAirtableContactsProvider = ({ children }: { children: React.ReactNode }) => {
  const { isAdmin } = useLoggedInUser()

  if (!isAdmin) {
    // NOTE: this is just a safety measure and opportunity for Sentry to alert us
    // the parent should be checking isAdmin before rendering this component
    throw new Error("Attempted to render an admin-only component for a non-admin user")
  }

  const airtableContactsResponse = useAPIQuery<Array<AirtableRecord<AirtableContact>>>(
    APIEndpoints.adminAllAirtableContactIdFields
  )

  const allAirtableContacts = useMemo(
    () =>
      matchLoading(
        airtableContactsResponse,
        ({ result }) => result?.map((r) => r.fields) ?? [],
        "loading",
        null
      ),
    [airtableContactsResponse]
  )

  return (
    <AdminAirtableContactsContext.Provider value={allAirtableContacts}>
      {children}
    </AdminAirtableContactsContext.Provider>
  )
}

export const useAdminAirtableContacts = () => useContext(AdminAirtableContactsContext)
