import { useState } from "react"
import OrderFormDrawer from "../../pages/Orders/OrderForm/OrderFormDrawer"
import { FormOrderProps } from "./types"

type OrderFormButtonProps = {
  renderButton: (onClick: React.MouseEventHandler) => React.ReactNode
} & FormOrderProps

const OrderFormButton = ({
  initialOrderId,
  initialFormOrder,
  onSave,
  renderButton,
  drawerTitle,
}: OrderFormButtonProps) => {
  const [open, setOpen] = useState(false)
  const handleClick: React.MouseEventHandler = (e) => {
    e.stopPropagation()
    setOpen(true)
  }
  return (
    <>
      {renderButton(handleClick)}
      <OrderFormDrawer
        open={open}
        initialFormOrder={initialFormOrder}
        initialOrderId={initialOrderId}
        onClose={() => setOpen(false)}
        onSave={onSave}
        drawerTitle={drawerTitle}
      />
    </>
  )
}

export default OrderFormButton
