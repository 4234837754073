import numeral from "numeral"
import { repeatToString } from "common/utils/data/Array/ArrayUtils"
import { λ } from "common/utils/fp/WrappedFunction"
import { formatCurrency } from "common/utils/math/format"
import { Order } from "common/model/Order/Order"

export type SharePrice = number

// Register custom locale to adjust numeral abbreviations + set ordinal function
export const registerNumeralLocale = () => {
  numeral.register("locale", "us", {
    delimiters: {
      thousands: ",",
      decimal: ".",
    },
    abbreviations: {
      thousand: "K",
      million: "M",
      billion: "B",
      trillion: "T",
    },
    ordinal(n) {
      const end = ["th", "st", "nd", "rd"]
      const num = n % 100
      return n.toString() + (end[(num - 20) % 10] || end[num] || end[0])
    },
    currency: {
      symbol: "$",
    },
  })

  numeral.locale("us")
}

export const formatDate = (x: Date) => x.toLocaleDateString()
export const formatSharePrice = (x: SharePrice) => numeral(x).format("$0.00")
export const formatSharePriceWithPriceType = (x: SharePrice, o: Pick<Order, "commission">) =>
  `${formatSharePrice(x)}${
    o.commission?.priceType === "gross"
      ? " (Gross)"
      : o.commission?.priceType === "net-of-fees"
      ? " (Net)"
      : ""
  }`
export const sharePriceDisplay = λ(formatSharePrice)
export const volumeDisplay = λ((x: number) => `$${formatCurrency(0, x)}`)
export const estimatedValuationDisplay = λ((x: number) => `$${formatCurrency(0, x)}`)

export const formatShareCount = (x: number) => numeral(x).format("0a")
export const formatSharePriceAccounting = (x: SharePrice) => numeral(x).format("($0.00)")
export const formatSharePriceInt = (x: SharePrice) => numeral(x).format("$0,0")
export const formatInt = (x: number) => numeral(x).format("0,0")
export const formatValuation = (x: number, rounding: number = 1) =>
  numeral(x)
    .format(`$0.${repeatToString("0", rounding)}a`)
    .toUpperCase()
export const formatValuationAccounting = (x: number, rounding: number = 1) =>
  numeral(x)
    .format(`($0.${repeatToString("0", rounding)}a)`)
    .toUpperCase()
export const formatRounded = (x: number, rounding: number = 1) =>
  numeral(x)
    .format(`0.${repeatToString("0", rounding)}a`)
    .toUpperCase()
export const formatValuationB = (x: number) => `${numeral(x).format("($0)")}B` // temp: preserving exact behavior of existing code, but seems likely that we should standardize
// eslint-disable-next-line react/destructuring-assignment
export const SharePriceDisplay = (x: SharePrice) => <>{formatSharePrice(x)}</>

export const CurrencyDisplay = (x: number, precision: number = 3) => (
  <>${formatCurrency(precision, x)}</>
)
