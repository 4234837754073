import { Left, Right } from "common/containers/Either"
import { deprecatedIsLoaded } from "common/utils/Loading"
import { User } from "common/model/User"
import { secondsBetweenDates } from "common/utils/dateUtils"
import { isFullAccessCustomer } from "common/model/Auth/Permissions"
import { AuthCondition } from "../../../Routes/auth/AuthCondition"
import { UserAuthContext } from "../../../Routes/auth/UserAuthContext"
import { AuthFailurePage } from "../AuthFailurePage"
import { Account, isTrialExpired } from "common/model/Account"
import { useAccountCompliance } from "src/providers/data/AccountComplianceProvider"

type UserAccessCtx = { companyRouteId?: string }
type IsFullAccessUser = (
  userAccessCtx?: UserAccessCtx
) => AuthCondition<UserAuthContext, UserAuthContext>

const isDelinquentContributor = (a: Account) => a.delinquentContributor ?? false

export const isFullAccessUser: IsFullAccessUser =
  (userAccessCtx?: UserAccessCtx) => (ctx: UserAuthContext) => {
    const account = ctx.account ?? null
    const { isQuotaDelinquent } = useAccountCompliance()
    const isDelinquent = deprecatedIsLoaded(account) && isDelinquentContributor(account)
    const trialExpired = deprecatedIsLoaded(account) ? isTrialExpired(account) : false
    const autoLockout = deprecatedIsLoaded(account)
      ? isDelinquent || trialExpired || isQuotaDelinquent // && (account.autoLockoutAllowed ?? false)
      : false

    return isFullAccessUserContext(ctx, userAccessCtx) && !autoLockout
      ? Right(ctx)
      : Left(() => (
          <AuthFailurePage
            reason={
              isDelinquent
                ? "contribution required"
                : trialExpired
                ? "trial expired"
                : isQuotaDelinquent
                ? "quota nonCompliant"
                : "unspecified"
            }
          />
        )) // TODO: apply component later and only return the reason here
  }

export const isFullAccessUserContext = (ctx: UserAuthContext, userAccessCtx?: UserAccessCtx): boolean =>
  deprecatedIsLoaded(ctx.user) &&
  !!ctx.user &&
  (isFullAccessCustomer(ctx.user.user) ||
    limitedAccessUserCanAccessRoute(ctx.user.user, userAccessCtx))

const limitedAccessUserCanAccessRoute = (user: User, userAccessCtx?: UserAccessCtx): boolean =>
  (!!userAccessCtx &&
    user?.sharedCompanyPages?.some(
      ({ postgresCompanyId, expiresAt }) =>
        postgresCompanyId === userAccessCtx.companyRouteId &&
        secondsBetweenDates(new Date(), expiresAt) > 0
    )) ??
  false
