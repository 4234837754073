import { updateContactInterest } from "src/firebase/crm"
import { DealCRMInterest } from "common/model/DealCRM/DealCRMInterest"
import { UserIdFields } from "common/model/User"
import { FirebaseWriter } from "src/firebase/Firebase"

type HandleInterestEdit = {
  interestUpdate: Partial<DealCRMInterest>
  interest: DealCRMInterest
  user: UserIdFields
  db: FirebaseWriter
  afterUpdate?: () => void | Promise<void>
}

export const handleInterestEdit = ({
  interest,
  interestUpdate,
  user,
  db,
  afterUpdate,
}: HandleInterestEdit) =>
  updateContactInterest({
    db,
    user,
    interestUpdate: {
      ...interest,
      ...interestUpdate,
    },
  }).then(() => afterUpdate?.())
