import { HTMLProps } from "react"
import { InputLabel } from "../Input/Input"
import { classNames } from "src/utils/classNames"
import { Color } from "@stories/components/Typography/Typography"

export type NativeSelectProps<T extends string> = {
  value: T | undefined
  onChange: (value: T | undefined) => void
  options: { value: T; label: string }[]
  label?: string
  optional?: boolean
} & Omit<HTMLProps<HTMLSelectElement>, "value" | "onChange">

const NativeSelect = <T extends string>({
  value,
  onChange,
  onBlur,
  onFocus,
  options,
  optional = false,
  label,
  placeholder,
  name,
  id = name,
}: NativeSelectProps<T>) => (
  <div>
    <InputLabel htmlFor={id} label={label} isOptional={optional} size="medium" />
    <select
      id={id}
      className={classNames(
        "w-full border border-neutral-400 rounded text-sm bg-transparent",
        !value && Color.Placeholder
      )}
      value={value ?? ""}
      // eslint-disable-next-line rulesdir/no-assert
      onChange={(e) => onChange(e.target.value as T | undefined)}
      onBlur={onBlur}
      onFocus={onFocus}
    >
      {placeholder ? (
        <option disabled value="">
          {placeholder}
        </option>
      ) : null}
      {options.map(({ value: optionValue, label: optionLabel }) => (
        <option key={optionValue} value={optionValue}>
          {optionLabel}
        </option>
      ))}
    </select>
  </div>
)

export default NativeSelect
