import { AdminAllAccountsCache } from "common/model/AdminAllAccountsCache"
import { Loading } from "common/utils/Loading"
import { useState, useEffect } from "react"
import { useFirebaseWriter } from "src/firebase/Context"
import { handleConsoleError } from "src/utils/Tracking"

export const useAdminGetAllAccounts = () => {
  const firebase = useFirebaseWriter()
  const [accounts, setAccounts] = useState<Loading<AdminAllAccountsCache["accounts"]>>("loading")
  useEffect(() => {
    if (accounts === "loading") {
      firebase.adminGetAllAccounts().then(setAccounts).catch(handleConsoleError)
    }
  }, [accounts, setAccounts, firebase])
  return accounts
}
