import { Schema as ZodSchema, ZodError, ZodTypeDef } from "zod"
import { Either, Left, Right } from "../containers/Either"

type Candidate<Output, Def, Input> = unknown // todo improve this

export type Schema<Input, Def extends ZodTypeDef, Output> = Pick<
  ZodSchema<Output, Def, Input>,
  "safeParse" | "parse"
>

export const parseWith =
  <Output, Def extends ZodTypeDef = ZodTypeDef, Input = Output>(
    schema: Schema<Input, Def, Output>
  ) =>
  (data: Candidate<Output, Def, Input>): Either<ZodError<Input>, Output> => {
    const parsed = schema.safeParse(data)
    return parsed.success ? Right(parsed.data) : Left(parsed.error)
  }
export const unsafeParseWith =
  <Output, Def extends ZodTypeDef = ZodTypeDef, Input = Output>(
    schema: Schema<Input, Def, Output>
  ) =>
  (data: Candidate<Output, Def, Input>): Output =>
    schema.parse(data)
