import { wrapIcon } from "./IconWrapper"

export const RulesIcon = wrapIcon(
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.21668 9.03333L4.73334 8.55C4.60001 8.41667 4.44446 8.35289 4.26668 8.35867C4.0889 8.36444 3.93334 8.43378 3.80001 8.56667C3.67779 8.7 3.61379 8.85556 3.60801 9.03333C3.60223 9.21111 3.66623 9.36667 3.80001 9.5L4.75001 10.45C4.88334 10.5833 5.0389 10.65 5.21668 10.65C5.39446 10.65 5.55001 10.5833 5.68334 10.45L8.05001 8.08333C8.18334 7.95 8.25001 7.79444 8.25001 7.61667C8.25001 7.43889 8.18334 7.28333 8.05001 7.15C7.91668 7.01667 7.75846 6.95 7.57535 6.95C7.39223 6.95 7.23379 7.01667 7.10001 7.15L5.21668 9.03333ZM10.6667 7.98333L10.0667 7.38333C9.94446 7.26111 9.7889 7.2 9.60001 7.2C9.41112 7.2 9.25557 7.26111 9.13334 7.38333C9.01112 7.50556 8.95001 7.66111 8.95001 7.85C8.95001 8.03889 9.01112 8.19444 9.13334 8.31667L9.73335 8.91667L9.13334 9.51667C9.01112 9.63889 8.95001 9.79444 8.95001 9.98333C8.95001 10.1722 9.01112 10.3278 9.13334 10.45C9.25557 10.5722 9.41112 10.6333 9.60001 10.6333C9.7889 10.6333 9.94446 10.5722 10.0667 10.45L10.6667 9.85L11.2667 10.45C11.3889 10.5722 11.5445 10.6333 11.7333 10.6333C11.9222 10.6333 12.0778 10.5722 12.2 10.45C12.3222 10.3278 12.3833 10.1722 12.3833 9.98333C12.3833 9.79444 12.3222 9.63889 12.2 9.51667L11.6 8.91667L12.2 8.31667C12.3222 8.19444 12.3833 8.03889 12.3833 7.85C12.3833 7.66111 12.3222 7.50556 12.2 7.38333C12.0778 7.26111 11.9222 7.2 11.7333 7.2C11.5445 7.2 11.3889 7.26111 11.2667 7.38333L10.6667 7.98333ZM2.66668 13.3333C2.30001 13.3333 1.98601 13.2027 1.72468 12.9413C1.46334 12.68 1.3329 12.3662 1.33334 12V4C1.33334 3.63333 1.46401 3.31933 1.72534 3.058C1.98668 2.79667 2.30046 2.66622 2.66668 2.66667H6.11668C6.29446 2.66667 6.46401 2.7 6.62534 2.76667C6.78668 2.83333 6.92823 2.92778 7.05001 3.05L8.00001 4H13.3333C13.7 4 14.014 4.13067 14.2753 4.392C14.5367 4.65333 14.6671 4.96711 14.6667 5.33333V12C14.6667 12.3667 14.536 12.6807 14.2747 12.942C14.0133 13.2033 13.6996 13.3338 13.3333 13.3333H2.66668ZM2.66668 12H13.3333V5.33333H7.45001L6.11668 4H2.66668V12Z"
      fill="currentColor"
    />
  </svg>
)
