import { pick } from "lodash"
import { Validation } from "../containers/Validation"

// I'm not happy with this design, it's not very composable. Use at your own risk.
export type CSVSchema<R, T = R> = {
  row: { [key in keyof R]: (s: string) => Validation<string, R[key]> }
  /** Builds a potentially more complex type out of the parsed rows */
  reassemble: (r: R) => Validation<string[], T>
}

export const mergeCSVSchemas = <LIn, LOut, RIn, ROut, Out>(
  l: CSVSchema<LIn, LOut>,
  r: CSVSchema<RIn, ROut>,
  merge: (l: LOut, r: ROut) => Validation<string[], Out>
): CSVSchema<LIn & RIn, Out> => ({
  row: { ...l.row, ...r.row } as {
    [key in keyof (LIn & RIn)]: (s: string) => Validation<string, (LIn & RIn)[key]>
  }, // this is not actually valid if there are overlapping keys
  reassemble: (x) =>
    l
      .reassemble(pick(x, Object.keys(l.row)) as LIn)
      .alongsidePaired(r.reassemble(pick(x, Object.keys(r.row)) as RIn))
      .map(([a, b]) => merge(a, b))
      .match(
        (err) => Validation.Problem(err.flat()),
        (a) => a
      ),
})
