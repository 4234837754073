import { DocumentData } from "@firebase/firestore-types"
import { Account } from "../model/Account"
import { Company } from "../model/Company"
import { FeatureFlag } from "../model/FeatureFlag/FeatureFlag"
import { firestoreConverter } from "../model/FirestoreConverter"
import { IntroductionRequest } from "../model/IntroductionRequest"
import { ConnectThread } from "../model/Messaging/ConnectThread"
import { ConnectMessage } from "../model/Messaging/Message"
import { UserTypingStatus } from "../model/Messaging/UserTypingStatus"
import { Order } from "../model/Order/Models/Internal"
import { MessageThreadJob } from "../model/QueuedJobs"
import { ThreadType } from "../model/Messaging/ThreadTypes/ThreadType"
import { FirebaseCommon } from "./Interface"
import { OrderInquiry } from "../model/OrderInquiry/Db"
import { PartnerIntegrationOrder } from "../model/PartnerIntegrations/PartnerIntegrationOrder"
import { Func } from "../utils/fp/Function"
import { deriveOrderFields } from "../model/Order/Types/DerivedFields"
import { DealCRMInterest } from "../model/DealCRM/DealCRMInterest"

export const restrictedCollections = {
  darkpoolOrderObservations: "darkpool_order_observations",
  orderObservations: "order_observations",
  orderObservationsLog: "order_observations_log",
  priceObservationsLog: "price_observations_log",
  companySubcollections: {
    priceObservations: "price_observations",
  },
  editLogs: "edit_logs",
  sharedOrders: "shared_orders",
} as const

export const collections = {
  adminAllAccountsCache: "admin_all_accounts_cache",
  accounts: "accounts",
  accountAnalytics: "account_analytics",
  accountSubcollections: {
    linkedBrokers: "linked_brokers",
    invitedBrokers: "invited_brokers",
    companyAccess: "company_access",
  },
  auctions: "auctions",
  auctionSubcollections: {
    privateData: "private_data",
    bids: "bids",
    participants: "participants",
    lettersOfIntent: "letters_of_intent",
  },
  campaignParticipantsSubcollection: "participants",
  cartaOAuthUrls: "carta_oauth_urls",
  companies: "companies",
  publiclyViewableCompanies: "publicly_viewable_companies",
  companySubcollections: {
    priceLogs: "price_logs",
  },
  companyPageFeedback: "company_page_feedback",
  orderMatchSuggestions: "order_match_suggestions",
  messageThreads: "message_threads",
  messageThreadJobs: "message_thread_jobs",
  messageThreadSubcollections: {
    messages: "messages",
    userTypingStatuses: "user_typing_statuses",
  },
  documents: "documents",
  documentSubmissions: "document_submissions",
  documentTemplates: "document_templates",
  linkedBrokers: "linked_brokers",
  users: "users",
  userAuthCodes: "auth_codes",
  emailLog: "email_log",
  emailEventLog: "email_event_log",
  inboundEmails: "inbound_emails",
  funds: "funds",
  featureFlags: "feature_flags",
  holdings: "holdings",
  holdingsSubcollections: { documents: "documents" },
  industries: "industries",
  opportunities: "opportunities",
  // TODO: Update Airtable Table Names
  orderInquiries: "order_inquiries",
  orderProcessingJobs: "order_processing_jobs",
  orderMatchReports: "order_match_reports",
  partialOrderObservations: "partial_order_observations",
  pitchbookCompanyPageViews: "pitchbook_company_page_views",
  platformPageInvites: "platform_page_invites",
  platformPageInvitesSubcollections: {
    sessions: "sessions",
    participants: "participants",
  },
  postgresHelpers: "postgres_helpers",
  publicCompanies: "public_companies",
  publicOptionData: "public_option_data",
  publicOptionDataSubcollections: {
    observations: "observations",
    expirations: "expirations",
  },
  campaignBatches: "campaign_batches",
  rfqs: "rfqs",
  rfqRequests: "rfq_requests",
  rfqSubcollections: {
    responses: "responses",
    partialResponses: "partial_responses",
    participants: "participants",
  },
  sectors: "sectors",
  stockSplitsUpdates: "stock_splits_updates",
  sharedWithContacts: "shared_with_contacts",
  userHistory: "user_history",
  userTrackingMetadata: "user_tracking_metadata",
  userEventLog: "user_event_log",
  userEventCounts: "user_event_counts",
  userNotifications: "user_notifications",
  accountEventCounts: "account_event_counts",
  accountEventCountRollups: "account_event_count_rollups",
  accountQuotaHistory: "account_quota_history",
  accountTradingRegistrations: "account_trading_registrations",
  userOnlineStatus: "user_online_status",
  valuations: "valuations",
  changelogs: {
    priceObservations: "price_observations_changelog",
  },
  watchlistSuggestionsJobQueue: "client_watchlist_suggestions_job_queue",
  watchlists: "watchlists",
  apiLog: "api_log",
  fundingRounds: "funding_rounds",
  fundingRoundsUpdateLog: "funding_rounds_update_log",
  introductionRequest: "introduction_request",
  eventFeedEvents: "event_feed_events",
  marketingLists: "marketing_lists",
  rawPartnerAPIData: "raw_partner_api_data",
  materializedViews: "materialized_views",
  partnerIntegrationOrders: "partner_integration_orders",
  opportunityInboxFilterRules: "opportunity_inbox_filter_rules",
  highlightedOpportunities: "highlighted_opportunities",
  trades: "trades",
  tentativeInterest: "tentative_interest",
  dealCRM: {
    contacts: "crm_contacts",
    deals: "crm_deals",
    dealParticipants: "crm_deal_participants",
    notes: "crm_notes",
    funds: "crm_funds",
    importJobs: "crm_import_jobs",
    buySellInterest: "buy_sell_interests",
    emails: "crm_emails",
  },
  migrationRuns: "migration_runs",
  accountActivityLog: "account_activity_log",
  google: {
    authTokens: "google_auth_tokens",
    gmail: {
      pubsubMessages: "gmail_pubsub_messages",
      inboxes: "gmail_inboxes",
      rawMessages: "gmail_messages",
      messageSyncAttempts: "message_sync_attempts",
    },
  },
  carta: {
    authTokens: "carta_auth_tokens",
    authTokenRequests: "carta_auth_token_requests",
  },
  dealDistributions: "deal_distributions",
  dealDistributionSubcollections: {
    participants: "deal_distribution_participants",
  },
  trustedBrokerConnections: "trusted_broker_connections",
  shareholderFormSubmissions: "shareholder_form_submissions",
} as const

export const restrictedCollectionReferences = {
  orderObservations: (db: FirebaseCommon.DB) =>
    db
      .collection(restrictedCollections.orderObservations)
      .withConverter<Order>(firestoreConverter<Order>()),
  darkpoolOrderObservations: (db: FirebaseCommon.DB) =>
    db
      .collection(restrictedCollections.darkpoolOrderObservations)
      .withConverter<Order>(firestoreConverter<Order>()),
}
export const collectionReferences = {
  accounts: (db: FirebaseCommon.LimitedDB) =>
    db.collection(collections.accounts).withConverter<Account>(firestoreConverter<Account>()),
  companies: (db: FirebaseCommon.LimitedDB) =>
    db.collection(collections.companies).withConverter<Company>(firestoreConverter<Company>()),
  introductionRequests: (db: FirebaseCommon.LimitedDB) =>
    db
      .collection(collections.introductionRequest)
      .withConverter<IntroductionRequest>(firestoreConverter<IntroductionRequest>()),
  connectThreads: (db: FirebaseCommon.LimitedDB) =>
    db
      .collection(collections.messageThreads)
      .withConverter<ConnectThread>(firestoreConverter<ConnectThread>()),
  messageThreadJobs: (db: FirebaseCommon.LimitedDB) =>
    db
      .collection(collections.messageThreadJobs)
      .withConverter<MessageThreadJob>(firestoreConverter<MessageThreadJob>()),
  connectThreadSubcollections: (threadId: string) => ({
    messages: (db: FirebaseCommon.LimitedDB) =>
      db
        .collection(collections.messageThreads)
        .doc(threadId)
        .collection(collections.messageThreadSubcollections.messages)
        .withConverter<ConnectMessage<ThreadType>>(firestoreConverter()),
    userTypingStatuses: (db: FirebaseCommon.LimitedDB) =>
      db
        .collection(collections.messageThreads)
        .doc(threadId)
        .collection(collections.messageThreadSubcollections.userTypingStatuses)
        .withConverter<UserTypingStatus>(firestoreConverter<UserTypingStatus>()),
  }),
  featureFlags: (db: FirebaseCommon.LimitedDB) =>
    db
      .collection(collections.featureFlags)
      .withConverter<FeatureFlag>(firestoreConverter<FeatureFlag>()),
  orderInquiries: (db: FirebaseCommon.LimitedDB) =>
    db
      .collection(collections.orderInquiries)
      .withConverter<OrderInquiry>(firestoreConverter<OrderInquiry>()),
  partnerIntegrationOrders: (db: FirebaseCommon.LimitedDB) =>
    db
      .collection(collections.partnerIntegrationOrders)
      .withConverter<PartnerIntegrationOrder>(firestoreConverter<PartnerIntegrationOrder>()),
  dealCRMInterest: (db: FirebaseCommon.LimitedDB) =>
    db
      .collection(collections.dealCRM.buySellInterest)
      .withConverter<DealCRMInterest>(firestoreConverter<DealCRMInterest>()),
} as const

type FlatValues<R> = R extends string
  ? R
  : {
      [key in keyof R]: R[key] extends Record<string | number | symbol, unknown>
        ? FlatValues<R[key][keyof R[key]]>
        : R[key]
    }[keyof R]

export type Collection = FlatValues<typeof collections>
export type AdminCollection = Collection | FlatValues<typeof restrictedCollections>

type Converter<Read> = {
  fromFirestore?: Func<DocumentData, Read> // no toFirestore because we don't get the path on writes
}

export const collectionConverters = {
  order_observations: {
    fromFirestore: (doc) => deriveOrderFields<Order>(doc as Order),
  },
} satisfies { [key in Collection | AdminCollection /** Todo handle nested */]?: Converter<unknown> }
