import CompanyLogoAndName from "@components/CompanyLogoAndName"
import { DealCreatedEvent } from "common/model/AccountActivityLog/AccountActivityLog"
import { titleCase } from "common/utils/StringUtils"
import { formatAbbreviatedCurrency } from "common/utils/math/format"
import { DealTerm } from "./DealTerm"

export const DealCreatedEventCardDetails = ({ event }: { event: DealCreatedEvent }) => {
  const { deal } = event
  const { company } = deal

  return (
    <div className="flex flex-col gap-4">
      <CompanyLogoAndName company={company} size="xs" />
      <div className="flex gap-4 justify-between">
        <DealTerm
          label="Target Size"
          value={deal.targetDollarAmount ? formatAbbreviatedCurrency(deal.targetDollarAmount) : ""}
        />
        <DealTerm
          label="Target Price"
          value={deal.targetSharePrice ? formatAbbreviatedCurrency(deal.targetSharePrice) : ""}
        />
        <DealTerm label="Stage" value={deal.stage ? titleCase(deal.stage) : ""} />
        <DealTerm
          label="Priority"
          value={deal.priority && deal.priority !== "none" ? titleCase(deal.priority) : ""}
        />
      </div>
    </div>
  )
}
