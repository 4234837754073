import { caplightDisclaimers } from "common/compliance/disclaimer"
import React from "react"
import Typography, { Color, Size, Weight } from "../Typography/Typography"

interface DisclaimerProps {}

const Disclaimer: React.FC<React.PropsWithChildren<DisclaimerProps>> = () => (
  <div className="animate-fade leading-3">
    <div className="mb-2">
      <Typography
        weight={Weight.Semibold}
        size={Size.XSmall}
        color={Color.Subtitle}
        text="Disclaimer"
      />
    </div>
    {caplightDisclaimers.map((disclaimer) => (
      <div key={disclaimer.slice(0, 20)} className="mb-2">
        <Typography
          weight={Weight.Light}
          size={Size.XSmall}
          color={Color.Subtitle}
          text={disclaimer}
        />
      </div>
    ))}
  </div>
)

export default Disclaimer
