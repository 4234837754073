import React, { useMemo } from "react"
import { Order } from "common/model/Order/Order"
import { useFirebaseWriter } from "src/firebase/Context"
import { updateOrder } from "src/firebase/orders"
import { OrderUpdateControlsContext } from "./Context"

export const FirebaseOwnOrderControlsProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const fbWriter = useFirebaseWriter()
  // TODO: make logged
  const payload = useMemo(
    () => ({
      updateOrder: (args: { mergedOrder: Order }) =>
        updateOrder({
          ...args,
          db: fbWriter,
          updateOrderOptions: {},
        }),
    }),
    [fbWriter]
  )
  return (
    <OrderUpdateControlsContext.Provider value={payload}>
      {children}
    </OrderUpdateControlsContext.Provider>
  )
}
