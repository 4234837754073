import { OrderCard } from "@components/ItemCards/OrderCard"
import { LiveMarketOrderPlacedEvent } from "common/model/AccountActivityLog/AccountActivityLog"
import React from "react"

interface LiveMarketOrderPlacedDetailsProps {
  event: LiveMarketOrderPlacedEvent
}

export const LiveMarketOrderPlacedDetails: React.FC<LiveMarketOrderPlacedDetailsProps> = ({
  event,
}) => {
  const { order } = event

  return <OrderCard order={order} hideDate />
}
