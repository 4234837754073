import { Func } from "../fp/Function"

/** The type of a value which can be applied to itself to yield a T */
type UArg<T> = (u: UArg<T>) => T

/** The Y-combinator */
export const fix = <Args extends unknown[], T>(
  f: Func<(...args: Args) => T, (...args: Args) => T>
): ((...args: Args) => T) => {
  const g =
    (self: UArg<(...args: Args) => T>) =>
    (...args: Args) => {
      const ss = self(self)
      const f2 = f(ss)
      return f2(...args)
    }
  return g(g)
}
