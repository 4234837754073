import { CompanyIdFields } from "common/model/Company"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"

export type CompanyFilterSearchProp = {
  company: CompanyIdFields
  type: ("buy-interest" | "sell-interest" | "holding")[]
}
export interface ContactSelectSearchProps {
  allContacts: DealCRMContact[]
  onContactSelected: (contact: DealCRMContact) => Promise<void>
  hideIndividuals?: boolean
  hideFirms?: boolean
  initialCompanyFilter?: CompanyFilterSearchProp
  additionalFilter?: (contact: DealCRMContact) => boolean
  contactSearchType?: DealCRMContact["tag"]
}

export const contactSort = (input: string) => (left: DealCRMContact, right: DealCRMContact) => {
  // If name starts with input, sort first
  // Otherwise, sort by created date
  const leftName = left.name.toLowerCase()
  const rightName = right.name.toLowerCase()
  const inputLower = input.toLowerCase()
  const leftStartsWithInput = leftName.startsWith(inputLower)
  const rightStartsWithInput = rightName.startsWith(inputLower)
  if (leftStartsWithInput && !rightStartsWithInput) return -1
  if (!leftStartsWithInput && rightStartsWithInput) return 1
  return leftName.localeCompare(rightName)
}

const filterContactByInput = (contact: DealCRMContact, input: string) => {
  const nameMatches = contact.name.toLowerCase().includes(input.toLowerCase())
  if (nameMatches) return true
  if (contact.tag === "firm") {
    const firmContactsMatch = contact.contacts.some((c) =>
      c.name.toLowerCase().includes(input.toLowerCase())
    )
    return firmContactsMatch
  } else {
    const firmMatches = contact.firm?.name.toLowerCase().includes(input.toLowerCase())
    return !!firmMatches
  }
}
const filterContactByCompanyInterest = (
  contact: DealCRMContact,
  companyInterest?: CompanyFilterSearchProp
): boolean =>
  companyInterest
    ? [
        companyInterest.type.includes("buy-interest")
          ? contact.contactBuyInterest.some((v) => v.company.id === companyInterest.company.id)
          : false,
        companyInterest.type.includes("sell-interest")
          ? contact.contactSellInterest?.some((v) => v.company.id === companyInterest.company.id)
          : false,
        companyInterest.type.includes("holding")
          ? contact.holdings.some((h) => h.company.id === companyInterest.company.id)
          : false,
      ].some((v) => !!v)
    : true

interface ExtraFilterOptions {
  hideFirms?: boolean
  hideIndividuals?: boolean
  additionalFilter?: (contact: DealCRMContact) => boolean
}

export const filterContacts = (
  allContacts: DealCRMContact[],
  filter: { searchString: string; companyFilter?: CompanyFilterSearchProp },
  options: ExtraFilterOptions
): DealCRMContact[] =>
  // eslint-disable-next-line rulesdir/mutating-array-methods
  allContacts
    .filter((contact) =>
      [
        options.hideFirms ? contact.tag !== "firm" : true,
        options.hideIndividuals ? contact.tag !== "individual" : true,
        filterContactByCompanyInterest(contact, filter.companyFilter),
        filterContactByInput(contact, filter.searchString),
        options.additionalFilter ? options.additionalFilter(contact) : true,
      ].every((v) => !!v)
    )
    .slice()
    .sort(contactSort(filter.searchString)) // change to toSorted once polyfill situation is figured out

export const defaultFilteredContacts = (
  allContacts: DealCRMContact[],
  filter: {
    searchString: string
    companyFilter?: CompanyFilterSearchProp
  },
  options: ExtraFilterOptions
): [boolean, DealCRMContact[]] => {
  const filteredContacts = filterContacts(allContacts, filter, options)
  const isFilteredContactsDefined = filteredContacts.length > 0
  return [
    isFilteredContactsDefined,
    isFilteredContactsDefined
      ? filteredContacts
      : filterContacts(allContacts, { ...filter, companyFilter: undefined }, options),
  ]
}
