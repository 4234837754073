import { FC } from "react"
import caplightLogoIconOnly from "../../public/caplight-icon-dark.svg"
import caplightLogo from "../../public/caplight-logo.svg"

interface CaplightLogoProps {
  className?: string
  iconOnly?: boolean
  grayscale?: boolean
}

export const CaplightLogo: FC<React.PropsWithChildren<CaplightLogoProps>> = ({
  className = "",
  iconOnly = false,
  grayscale,
}) => (
  <img
    alt="Caplight logo"
    className={`${className} ${grayscale ? "filter grayscale opacity-40" : ""}`}
    width="137"
    height="29"
    src={iconOnly ? caplightLogoIconOnly : caplightLogo}
  />
)
