import { ExclamationCircleIcon } from "@heroicons/react/outline"
import { Maybe } from "common/containers/Maybe"
import { AccessBoundary, DataAccessLevel } from "../../../../components/auth/DataAccess"
import { classNames } from "../../../../utils/classNames"
import Typography, { Size } from "@stories/components/Typography/Typography"
import { PrivateVisibilityIcon } from "@stories/icons/PrivateVisibilityIcon"
import { Tooltip } from "@stories/components/Antd"

type DetailType = "default" | "warning" | "private"

const detailTypeToIcon: Record<DetailType, JSX.Element | null> = {
  default: null,
  warning: <ExclamationCircleIcon className="w-4 h-4 text-warning-500" />,
  private: <PrivateVisibilityIcon />,
}

const detailTypeToClassName: Record<DetailType, string> = {
  default: "",
  warning: "bg-warning-100 rounded p-2",
  private: "",
}

const detailTypeToTooltip: Record<DetailType, string> = {
  default: "",
  warning: "",
  private: "This information is private to your account",
}

type OrderPreviewDetailProps = {
  label: string
  value: React.ReactNode
  subValue?: Maybe<string>
  accessLevel: DataAccessLevel
}

export const OrderPreviewDetail = ({
  label,
  value,
  subValue,
  accessLevel,
  detailType = "default",
}: OrderPreviewDetailProps & {
  detailType?: DetailType
}) => (
  <div className={classNames(detailTypeToClassName[detailType])}>
    <GenericDetail
      label={label}
      value={value}
      subValue={subValue}
      accessLevel={accessLevel}
      icon={detailTypeToIcon[detailType]}
      tooltip={detailTypeToTooltip[detailType]}
    />
  </div>
)

export const GenericDetail = ({
  label,
  value,
  subValue,
  tooltip,
  icon,
  accessLevel,
}: OrderPreviewDetailProps & { tooltip?: string; icon?: React.ReactNode }) => (
  <div className="flex flex-col space-y-1">
    <Tooltip title={tooltip ?? ""}>
      <h4 className="text-sm text-neutral-800 flex space-x-1 items-center">
        <span>{label}</span>
        {icon}
      </h4>
    </Tooltip>
    <AccessBoundary accessLevel={accessLevel}>
      <Typography text={value} />
      {subValue?.match(
        (str) => (
          <Typography text={str} size={Size.XSmall} />
        ),
        () => null
      )}
    </AccessBoundary>
  </div>
)

export default OrderPreviewDetail
