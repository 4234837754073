import { wrapIcon } from "./IconWrapper"

export const RequestForQuotesIcon = wrapIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.3333 1.33337V2.66671H13.338C13.7033 2.66671 14 2.96337 14 3.32871V14.0047C13.9998 14.1802 13.93 14.3485 13.8059 14.4726C13.6818 14.5967 13.5135 14.6665 13.338 14.6667H2.662C2.48643 14.6667 2.31804 14.597 2.1939 14.4728C2.06975 14.3487 2 14.1803 2 14.0047V3.32871C2 2.96337 2.29667 2.66671 2.662 2.66671H4.66667V1.33337H11.3333ZM4.66667 4.00004H3.33333V13.3334H12.6667V4.00004H11.3333V5.33337H4.66667V4.00004ZM6 10.6667V12H4.66667V10.6667H6ZM6 8.66671V10H4.66667V8.66671H6ZM6 6.66671V8.00004H4.66667V6.66671H6ZM10 2.66671H6V4.00004H10V2.66671Z"
      fill="currentColor"
    />
  </svg>
)
