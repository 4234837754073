import CompanyLogoAndName from "@components/CompanyLogoAndName"
import { AccessBoundary } from "@components/auth/DataAccess"
import { CurrentUser } from "@model/CurrentUser"
import { Tooltip } from "@stories/components/Antd/Tooltip/Tooltip"
import { PercentageField } from "@stories/components/Inputs/NumberInput/PercentageField"
import AbsoluteCell from "@stories/components/Table/AbsoluteCell"
import {
  makeSingleSelectFilter,
  makeTableSwitchFilter,
} from "@stories/components/Table/filters/makeTableFilter"
import { makeSortingFn } from "@stories/components/Table/sort/makeSortingFn"
import Typography, { Color, Size } from "@stories/components/Typography/Typography"
import { EditIcon } from "@stories/icons/EditIcon"
import { LockClosedIcon } from "@stories/icons/LockClosedIcon"
import { createColumnHelper } from "@tanstack/react-table"
import { nullableToMaybe } from "common/containers/Maybe"
import { Account } from "common/model/Account"
import { buildAccountCRMSettings } from "common/model/AccountCRMSettings"
import { dealCRMStageOrdering } from "common/model/DealCRM/Deal/DealCRMDeal"
import { DealCRMContact, viewDealCRMContactIdFields } from "common/model/DealCRM/DealCRMContact"
import {
  DealCRMInterest,
  ManagementFeeStructure,
  managementFeeStructures,
} from "common/model/DealCRM/DealCRMInterest"
import { Order } from "common/model/Order/Order"
import { isLoaded, matchLoading } from "common/utils/Loading"
import { articulatedDateTimeFormat } from "common/utils/dateUtils"
import { formatAbbreviatedCurrency } from "common/utils/math/format"
import { isBefore, subDays } from "date-fns"
import { isNil, keyBy } from "lodash"
import capitalize from "lodash/capitalize"
import { useCallback, useMemo } from "react"
import { useFirebaseWriter } from "src/firebase/Context"
import { FirebaseWriter } from "src/firebase/Firebase"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import { updateContactInterest } from "src/firebase/crm"
import { ShareOrderCheckbox } from "src/pages/Orders/MyOrders/ShareItemsProvider/ShareMyItemsProvider"
import { getMyOrderStatus } from "src/pages/Orders/MyOrders/utils"
import { DirectionDot } from "src/pages/Orders/shared/Cells"
import { userCanCreateOrderFromCRM } from "src/pages/Orders/shared/permissions"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import { useGenericOrders } from "src/providers/data/OrderProvider"
import { useCurrentAccount } from "src/queries/currentUser/useCurrentAccount"
import { trackEventInFirestoreAndHeap } from "src/utils/Tracking"
import { classNames } from "src/utils/classNames"
import { SelectField } from "../Components/DealParticipantFieldDisplay/DealParticipantFieldDisplay"
import StageDisplay from "../Components/StageDisplay/StageDisplay"
import { CRMContactName } from "../Contacts/ContactDetailsDrawer/CRMContactName"
import { NotesColumn } from "../Deals/NotesColumn"
import { useCRMBuySellInterest } from "../Providers/BuySellInterestProvider"
import InterestActionDropdownMenu from "./InterestActionDropdownMenu/InterestActionDropdownMenu"
import {
  CRMInterestAmountUSDField,
  CRMInterestOrderOriginationDateField,
  CRMInterestPriceTargetField,
  CRMInterestShareClassField,
  CRMInterestStructureField,
  CRMInterestTargetValuationField,
  allowEdit,
} from "./InterestCells"
import InterestVisibilityStatus from "./InterestVisibilityStatus"
import { handleInterestEdit } from "./handleInterestEdit"

type RowData = DealCRMInterest

const makeCol = createColumnHelper<RowData>()

type ColProps = {
  afterUpdate: (interest: Pick<DealCRMInterest, "direction">) => void
  db: FirebaseWriter
  currentUser: CurrentUser
  orders: Record<Order["id"], Order>
  onAddMissingContact: (interest: DealCRMInterest, c: DealCRMContact) => Promise<void>
  account: Account
}

type EditableContext = {
  row: { original: DealCRMInterest }
}
const allowEditContext = (context: EditableContext) => allowEdit(context.row.original)

const getEditIconTooltip = (context: EditableContext) => {
  if (!allowEditContext(context)) {
    return "To enable editing, change Cancelled status"
  }
  return undefined
}

const CellEditIcon = ({ context }: { context: EditableContext }) => (
  <div
    className={classNames(
      "absolute top-0 bottom-0 right-2 flex items-center",
      allowEditContext(context) && "pointer-events-none"
    )}
  >
    {allowEditContext(context) ? (
      <EditIcon size="small" color={Color.Placeholder} />
    ) : (
      <Tooltip title={getEditIconTooltip(context)}>
        <div>
          <LockClosedIcon size="small" color={Color.Subtitle} />
        </div>
      </Tooltip>
    )}
  </div>
)

const directionFilter = makeSingleSelectFilter<RowData, RowData["direction"]>({
  columnId: "direction",
  filterBy: ({ cellValue, filterState }) => filterState === cellValue,
  filterLabel: "Direction",
  filterOptions: ["buy", "sell"],
  undefinedLabel: "All",
})

const hideOldFilter = makeTableSwitchFilter<RowData, RowData["lastUpdatedAt"]>({
  columnId: "updated",
  filterRow: ({ cellValue }) => isBefore(subDays(new Date(), 90), cellValue),
  filterLabels: { on: "Hide Old", off: "Show Old" },
})

const createClientInterestTableColumnsAndFilters = ({
  afterUpdate,
  db,
  currentUser,
  orders,
  onAddMissingContact,
  account,
}: ColProps) => {
  const hideCancelledFilter = makeTableSwitchFilter<RowData, RowData["stage"]>({
    columnId: "stage",
    filterRow: ({ cellValue }) => {
      const { dealAndOrderStages: dealStages } = buildAccountCRMSettings(account)
      return cellValue !== "cancelled" && !dealStages.cancelled.includes(cellValue ?? "")
    },
    filterLabels: { on: "Hide Cancelled", off: "Show Cancelled" },
    initialActive: true,
  })
  const columns = [
    makeCol.accessor("company.name", {
      id: "company",
      header: "Company",
      cell: (context) => (
        <div className="flex items-center gap-2">
          <ShareOrderCheckbox
            shareableItem={{
              id: context.row.original.id,
              tag: "crm_interest",
              crmInterest: context.row.original,
            }}
          />
          <AccessBoundary accessLevel="hideFromCaplight">
            <CompanyLogoAndName
              company={context.row.original.company}
              size="xs"
              shouldMaskDataInDataDog
            />
          </AccessBoundary>
        </div>
      ),
      enableGlobalFilter: true,
      maxSize: 192,
    }),
    makeCol.accessor("contact.name", {
      id: "name",
      header: "Contact",
      cell: (context) => (
        <CRMContactName
          size="medium"
          contact={context.row.original.contact}
          onAddMissingContact={(c) => onAddMissingContact(context.row.original, c)}
        />
      ),
      enableGlobalFilter: true,
    }),
    makeCol.display({
      id: "notes",
      header: "Notes",
      cell: (context) => (
        <NotesColumn
          source={{
            sourceType: "interest",
            sourceId: context.row.original.id,
            interestDirection: context.row.original.direction,
            company: context.row.original.company,
            contact: context.row.original.contact,
          }}
          accountId={currentUser.user.account.id}
        />
      ),
      enableGlobalFilter: true,
    }),
    makeCol.accessor("direction", {
      id: "direction",
      header: "Direction",
      cell: (context) => (
        <div className="text-sm">
          <DirectionDot direction={context.getValue()} />
        </div>
      ),
      filterFn: directionFilter.filterFn,
      enableGlobalFilter: false,
      meta: {
        displayFilterState: (state) =>
          state === "buy" ? "Bids" : state === "sell" ? "Offers" : undefined,
      },
    }),
    ...(userCanCreateOrderFromCRM(currentUser)
      ? [
          makeCol.display({
            id: "visibility",
            header: "Visibility Status",
            cell: (context) => <InterestVisibilityStatus interest={context.row.original} />,
            enableGlobalFilter: false,
            enableSorting: false,
            meta: {
              tooltip:
                "Set the visibility of your bid/offer. By default, your CRM bids and offers are private and not discoverable by the market",
            },
            filterFn: (row, _colId, { filterValue }: { filterValue: string }) =>
              nullableToMaybe(row.original.publishedOrder)
                .bind((publishedOrder) => nullableToMaybe(orders[publishedOrder.id]))
                .map(
                  (order) =>
                    filterValue === "hide cancelled" &&
                    getMyOrderStatus(order) !== "Closed Canceled"
                )
                .withDefault(true),
          }),
        ]
      : []),
    makeCol.accessor("stage", {
      id: "stage",
      header: "Stage",
      cell: (context) => (
        <StageDisplay
          noBorder
          onChange={(stage) =>
            handleInterestEdit({
              interest: context.row.original,
              interestUpdate: { stage },
              user: currentUser.user,
              db,
              afterUpdate: () => afterUpdate(context.row.original),
            })
          }
          value={context.getValue() ?? null}
          editable
          className="min-w-32"
        />
      ),
      filterFn: hideCancelledFilter.filterFn,
      sortDescFirst: false,
      enableGlobalFilter: false,
      sortingFn: (rowA, rowB) => {
        if (rowA.original.stage && rowB.original.stage) {
          const rowAIndex = dealCRMStageOrdering(rowA.original.stage, account)
          const rowBIndex = dealCRMStageOrdering(rowB.original.stage, account)
          return rowAIndex - rowBIndex
        }
        return 0
      },
    }),
    makeCol.accessor("targetPrice", {
      id: "pps",
      header: "Price Target",
      cell: (context) => (
        <AbsoluteCell>
          <CRMInterestPriceTargetField
            db={db}
            crmInterest={context.row.original}
            currentUser={currentUser}
            afterUpdate={afterUpdate}
          />
          <CellEditIcon context={context} />
        </AbsoluteCell>
      ),
      sortDescFirst: true,
      enableGlobalFilter: false,
      sortingFn: makeSortingFn({ nullishLastWhenDesc: true }),
    }),
    makeCol.accessor("targetValuation", {
      id: "valuation",
      header: "Target Val.",
      cell: (context) => (
        <AbsoluteCell>
          <CRMInterestTargetValuationField
            db={db}
            crmInterest={context.row.original}
            currentUser={currentUser}
            afterUpdate={afterUpdate}
          />
          <CellEditIcon context={context} />
        </AbsoluteCell>
      ),
      sortDescFirst: true,
      enableGlobalFilter: false,
      sortingFn: makeSortingFn({ nullishLastWhenDesc: true }),
    }),
    makeCol.accessor((row) => row.amountUSD?.lowerBound ?? null, {
      id: "amount",
      header: "Amount ($)",
      cell: (context) => (
        <AbsoluteCell>
          <CRMInterestAmountUSDField
            db={db}
            crmInterest={context.row.original}
            currentUser={currentUser}
            afterUpdate={afterUpdate}
          />
          <CellEditIcon context={context} />
        </AbsoluteCell>
      ),
      sortDescFirst: true,
      enableGlobalFilter: false,
      sortingFn: makeSortingFn({ nullishLastWhenDesc: true }),
    }),
    makeCol.accessor(
      (row) => (isNil(row.managementFeeStructure) ? null : row.managementFeeStructure),
      {
        id: "managementFeeStructure",
        header: "Mgmt Fee Structure",
        cell: (context) => {
          const isUserABroker = currentUser.user.account.clientType.includes("Intermediary")
          const isDisabled = isUserABroker && context.row.original.structure !== "spv"

          return (
            <AbsoluteCell>
              <SelectField<ManagementFeeStructure | null>
                size="small"
                value={context.getValue()}
                options={[...managementFeeStructures]}
                isDisabled={isDisabled}
                renderValue={(v) => (
                  <Typography size={Size.XSmall} text={capitalize(v ?? undefined)} />
                )}
                handleChange={async (feeStructure) =>
                  feeStructure !== context.getValue()
                    ? handleInterestEdit({
                        interest: context.row.original,
                        user: currentUser.user,
                        db,
                        afterUpdate: () => afterUpdate(context.row.original),
                        interestUpdate: {
                          managementFeeStructure: isNil(feeStructure) ? null : feeStructure,
                        },
                      })
                    : Promise.resolve()
                }
                editable={allowEditContext(context)}
              />
              {!allowEditContext(context) ? <CellEditIcon context={context} /> : null}
            </AbsoluteCell>
          )
        },
        sortDescFirst: true,
        enableGlobalFilter: false,
        sortingFn: makeSortingFn({ nullishLastWhenDesc: true }),
      }
    ),
    makeCol.accessor((row) => (isNil(row.managementFeePercent) ? null : row.managementFeePercent), {
      id: "managementFeePercent",
      header: "Mgmt Fee %",
      cell: (context) => {
        const isUserABroker = currentUser.user.account.clientType.includes("Intermediary")
        const isDisabled = isUserABroker && context.row.original.structure !== "spv"

        return (
          <AbsoluteCell>
            <PercentageField
              noBorder
              noBackground
              value={context.getValue()}
              onChange={async (mgmtFee) =>
                mgmtFee !== context.getValue()
                  ? handleInterestEdit({
                      interest: context.row.original,
                      user: currentUser.user,
                      db,
                      afterUpdate: () => afterUpdate(context.row.original),
                      interestUpdate: {
                        managementFeePercent: isNil(mgmtFee) ? null : mgmtFee,
                      },
                    })
                  : Promise.resolve()
              }
              isDisabled={!allowEditContext(context) || isDisabled}
            />
            <CellEditIcon context={context} />
          </AbsoluteCell>
        )
      },
      sortDescFirst: true,
      enableGlobalFilter: false,
      sortingFn: makeSortingFn({ nullishLastWhenDesc: true }),
    }),
    makeCol.accessor((row) => (isNil(row.carryPercent) ? null : row.carryPercent), {
      id: "carry",
      header: "Carry %",
      cell: (context) => {
        const isUserABroker = currentUser.user.account.clientType.includes("Intermediary")
        const isDisabled = isUserABroker && context.row.original.structure !== "spv"

        return (
          <AbsoluteCell>
            <PercentageField
              noBorder
              noBackground
              value={context.getValue()}
              onChange={async (carry) =>
                carry !== context.getValue()
                  ? handleInterestEdit({
                      interest: context.row.original,
                      user: currentUser.user,
                      db,
                      afterUpdate: () => afterUpdate(context.row.original),
                      interestUpdate: {
                        carryPercent: isNil(carry) ? null : carry,
                      },
                    })
                  : Promise.resolve()
              }
              isDisabled={!allowEditContext(context) || isDisabled}
            />
            <CellEditIcon context={context} />
          </AbsoluteCell>
        )
      },
      sortDescFirst: true,
      enableGlobalFilter: false,
      sortingFn: makeSortingFn({ nullishLastWhenDesc: true }),
    }),
    makeCol.accessor((row) => (isNil(row.commissionPercent) ? null : row.commissionPercent), {
      id: "commission",
      header: "Commission (%)",
      cell: (context) => (
        <AbsoluteCell>
          <PercentageField
            max={0.1}
            value={context.getValue()}
            onChange={async (commission) =>
              commission !== context.getValue()
                ? handleInterestEdit({
                    interest: context.row.original,
                    user: currentUser.user,
                    db,
                    afterUpdate: () => afterUpdate(context.row.original),
                    interestUpdate: { commissionPercent: isNil(commission) ? null : commission },
                  })
                : null
            }
            isDisabled={!allowEditContext(context)}
            noBackground
            noBorder
          />
          <CellEditIcon context={context} />
        </AbsoluteCell>
      ),
      sortDescFirst: true,
      enableGlobalFilter: false,
      sortingFn: makeSortingFn({ nullishLastWhenDesc: true }),
    }),
    makeCol.accessor(
      (row) =>
        !row.commissionPercent || !row.amountUSD?.lowerBound
          ? null
          : row.commissionPercent * row.amountUSD.lowerBound,
      {
        id: "totalFee",
        header: "Total Fee",
        cell: (context) => (
          <AbsoluteCell>
            <div className="h-full w-full flex items-center justify-center">
              <Typography
                size={Size.Small}
                text={
                  context.row.original.amountUSD?.lowerBound &&
                  context.row.original.commissionPercent
                    ? formatAbbreviatedCurrency(
                        context.row.original.amountUSD.lowerBound *
                          context.row.original.commissionPercent
                      )
                    : ""
                }
              />
            </div>
          </AbsoluteCell>
        ),
        sortDescFirst: true,
        enableGlobalFilter: false,
        sortingFn: makeSortingFn({ nullishLastWhenDesc: true }),
      }
    ),
    makeCol.accessor("structure", {
      id: "structure",
      header: "Structure",
      cell: (context) => (
        <AbsoluteCell passThrough={!allowEditContext(context)}>
          <CRMInterestStructureField
            db={db}
            crmInterest={context.row.original}
            currentUser={currentUser}
            afterUpdate={afterUpdate}
          />
          {!allowEditContext(context) ? <CellEditIcon context={context} /> : null}
        </AbsoluteCell>
      ),
      sortDescFirst: false,
      enableGlobalFilter: false,
      sortingFn: makeSortingFn(),
    }),
    makeCol.accessor("shareClass", {
      id: "shareClass",
      header: "Share Class",
      cell: (context) => (
        <AbsoluteCell passThrough={!allowEditContext(context)}>
          <CRMInterestShareClassField
            db={db}
            crmInterest={context.row.original}
            currentUser={currentUser}
            afterUpdate={afterUpdate}
          />
          {!allowEditContext(context) ? <CellEditIcon context={context} /> : null}
        </AbsoluteCell>
      ),
      sortDescFirst: false,
      enableGlobalFilter: false,
      sortingFn: makeSortingFn(),
    }),
    makeCol.accessor("lastUpdatedAt", {
      id: "updated",
      header: "Updated",
      cell: (context) => articulatedDateTimeFormat(context.getValue()).date,
      filterFn: hideOldFilter.filterFn,
      enableGlobalFilter: false,
    }),
    makeCol.accessor("orderOriginationDate", {
      id: "orderOriginationDate",
      header: "Origination Date",
      cell: (context) => (
        <CRMInterestOrderOriginationDateField
          db={db}
          crmInterest={context.row.original}
          currentUser={currentUser}
          afterUpdate={afterUpdate}
        />
      ),
      enableGlobalFilter: false,
    }),
    makeCol.display({
      id: "actionMenu",
      header: "Actions",
      cell: (context) => (
        <InterestActionDropdownMenu
          interest={context.row.original}
          user={currentUser.user}
          afterUpdateInterest={afterUpdate}
        />
      ),
      enableGlobalFilter: false,
      enableHiding: false,
      meta: {
        disableCustomization: "end",
        hideColumnHeader: true,
      },
    }),
  ]

  const filterBarComponents = [directionFilter.filterBarComponent]
  const advancedFilterComponents = [
    hideOldFilter.filterBarComponent,
    hideCancelledFilter.filterBarComponent,
  ]
  const initialFilterState = [
    directionFilter.initialFilterState,
    hideOldFilter.initialFilterState,
    hideCancelledFilter.initialFilterState,
  ].filter((filterState) => filterState !== undefined)

  return {
    columns,
    filterBarComponents,
    advancedFilterComponents,
    initialFilterState,
  }
}

export const useInterestTable = ({ side }: { side: "buy" | "sell" | "both" }) => {
  const db = useFirebaseWriter()
  const currentUser = useCurrentUser()
  const loadingBuySellInterest = useCRMBuySellInterest()
  const account = useCurrentAccount()

  const onAddMissingContact = useCallback(
    async (interest: DealCRMInterest, c: DealCRMContact) => {
      if (isLoaded(currentUser)) {
        await updateContactInterest({
          db,
          user: currentUser.user,
          interestUpdate: { ...interest, contact: viewDealCRMContactIdFields(c) },
        })
      }
    },
    [currentUser, db]
  )

  const firebase9 = useFirebase9()

  const afterUpdate = useMemo(
    () =>
      isLoaded(currentUser)
        ? ({ direction }: Pick<DealCRMInterest, "direction">) =>
            trackEventInFirestoreAndHeap(
              firebase9,
              currentUser.user,
              "crm-contact-indication-of-interest-updated",
              { direction }
            )
        : () => {},
    [firebase9, currentUser]
  )
  const rows: DealCRMInterest[] = useMemo(
    () =>
      matchLoading(
        loadingBuySellInterest,
        (allInterest) =>
          allInterest.filter(
            (interest) =>
              side === "both" ||
              (side === "buy" && interest.direction === "buy") ||
              (side === "sell" && interest.direction === "sell")
          ),
        [],
        []
      ),
    [loadingBuySellInterest, side]
  )
  const loadingOrders = useGenericOrders(rows.flatMap((interest) => interest.publishedOrder || []))

  const orders: ColProps["orders"] = useMemo(
    () =>
      matchLoading(
        loadingOrders,
        (loadedOrders) => keyBy(loadedOrders, (order) => order.id),
        {},
        {}
      ),
    [loadingOrders]
  )

  const { columns, filterBarComponents, advancedFilterComponents, initialFilterState } = useMemo(
    () =>
      isLoaded(currentUser) && isLoaded(account)
        ? createClientInterestTableColumnsAndFilters({
            currentUser,
            afterUpdate,
            db,
            orders,
            onAddMissingContact,
            account,
          })
        : {
            columns: [],
            filterBarComponents: [],
            advancedFilterComponents: [],
            initialFilterState: [],
          },
    [afterUpdate, db, currentUser, orders, onAddMissingContact, account]
  )

  return { data: rows, columns, filterBarComponents, advancedFilterComponents, initialFilterState }
}
