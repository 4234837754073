import { DataAccessLevel } from "../../../components/auth/DataAccess"

const restrictedTableDataTypes = [
  "price",
  "size",
  "structure",
  "shareClass",
  "clientRelationship",
  "source",
  "score",
  "adminNotes",
  "dealExecution",
  "originationDate",
  "updatedDate",
  "view",
] as const

type RestrictedTableDataTypeOption = typeof restrictedTableDataTypes
type RestrictedTableDataType = RestrictedTableDataTypeOption[number]

export const orderDataAccessLevel = {
  price: (isConnectable): DataAccessLevel => (isConnectable ? "connect" : "dataLite"),
  size: (isConnectable): DataAccessLevel => (isConnectable ? "connect" : "dataLite"),
  structure: "trading",
  shareClass: (isConnectable): DataAccessLevel => (isConnectable ? "connect" : "dataLite"),
  clientRelationship: (isConnectable): DataAccessLevel => (isConnectable ? "connect" : "dataLite"),
  source: (isConnectable): DataAccessLevel => (isConnectable ? "connect" : "dataLite"),
  score: "admin",
  adminNotes: (isConnectable): DataAccessLevel => (isConnectable ? "connect" : "dataLite"),
  dealExecution: (isConnectable): DataAccessLevel => (isConnectable ? "connect" : "dataLite"),
  originationDate: "trading",
  updatedDate: "trading",
  view: "trading",
} satisfies Record<
  RestrictedTableDataType,
  DataAccessLevel | ((isConnectable: boolean) => DataAccessLevel)
>
