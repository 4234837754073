import { ChevronDownIcon } from "@stories/icons/ChevronDownIcon"
import { DotsIcon } from "@stories/icons/DotsIcon"
import { ParsedEmailMessage } from "common/model/Google/ParsedEmailMessage"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { Button } from "../Button/Button"
import Typography, { Color, Size } from "../Typography/Typography"
import { EmailCardHeader } from "./EmailCardHeader"
import { EmailCardMessage } from "./EmailCardMessage"

export interface EmailCardProps {
  data: ParsedEmailMessage[]
  emailParser: (email: string) => string
}

export type EmailCardType = {
  id: string
  body: string
  subject: string
  from: string
  to: string[]
  cc: string[]
  sentAt: Date
  threadLength: number
  emailParser: (email: string) => string
}

const LINES_UNTIL_COLLAPSE = 4
const THREAD_RELEVANT_THRESHOLD = 100

export const EmailCard: React.FC<React.PropsWithChildren<EmailCardProps>> = ({
  data,
  emailParser,
  children,
}) => {
  const threadLength = data.length
  const [isExpanded, setIsExpanded] = useState(false)
  const [showThread, setShowThread] = useState(false)
  const [showNestedEmails, setShowNestedEmails] = useState(false)
  const [isOverflowing, setIsOverflowing] = useState(false)
  const lineHeight = 24
  const maxHeight = lineHeight * LINES_UNTIL_COLLAPSE
  const bodyRef = useRef<HTMLDivElement | null>(null)
  const isThreaded = useMemo(() => threadLength > 1, [threadLength])

  const emailCardData: EmailCardType[] = data.map((email) => ({
    id: email.id,
    body: email.data.body.html ?? "",
    subject: email.data.subject,
    from: email.data.from,
    to: email.data.to,
    cc: email.data.cc,
    sentAt: email.data.sentAt ?? new Date(),
    emailParser,
    threadLength,
  }))
  const latestMessage = emailCardData[0]
  const replies = emailCardData.slice(1)

  const mainBodyContent = emailParser(latestMessage.body)

  // This ensures the three-dots button is hidden when there's no more relevant info
  const isThreadRelevant =
    latestMessage.body.length - mainBodyContent.length > THREAD_RELEVANT_THRESHOLD

  useEffect(() => {
    const bodyHeight = bodyRef.current?.scrollHeight
    if (bodyHeight && bodyHeight > maxHeight) {
      setIsOverflowing(true)
    }
  }, [maxHeight])

  const setExpandThread = (value: boolean) => {
    if (value) {
      setIsExpanded(true)
    }
    setShowThread(value)
  }

  return (
    <div className="bg-white rounded border">
      <EmailCardHeader
        subject={latestMessage.subject}
        from={latestMessage.from}
        to={latestMessage.to}
        cc={latestMessage.cc}
        sentAt={latestMessage.sentAt}
        threadLength={threadLength}
      />
      <div className="border-t-1.5">
        {showNestedEmails && (
          <div className="flex gap-4 pt-3 px-3">
            <Typography
              color={Color.Neutral800}
              size={Size.XSmall}
              text={latestMessage.sentAt.toDateString()}
            />
            <Typography
              color={Color.Neutral800}
              size={Size.XSmall}
              text={`from: ${latestMessage.from}`}
            />
          </div>
        )}
        <div className="p-3">
          <div
            ref={bodyRef}
            style={{
              display: "-webkit-box",
              WebkitLineClamp: isExpanded ? "unset" : LINES_UNTIL_COLLAPSE,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              transition: "max-height 0.3s ease-out",
            }}
          >
            <div
              style={{ fontSize: "14px" }}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: showThread ? latestMessage.body : mainBodyContent,
              }}
            />
          </div>
        </div>
        {isThreaded && isThreadRelevant && (
          <div className="flex flex-col justify-start items-start gap-2 px-3 pb-3">
            <button
              type="button"
              className="cursor-pointer rounded-xl hover:bg-neutral-400 bg-neutral-300 h-3 w-5 relative"
              onClick={() => setExpandThread(!showThread)}
            >
              <div className="absolute" style={{ top: -2, right: 2 }}>
                <DotsIcon color={Color.Neutral800} />
              </div>
            </button>
          </div>
        )}
        {children}
        {isThreaded && !showNestedEmails ? (
          <div className="pb-2 px-3">
            <Button
              label={`View thread (${threadLength})`}
              variant="hollow-link"
              size="small"
              leftIcon={<ChevronDownIcon color={Color.Neutral800} />}
              onClick={() => setShowNestedEmails(true)}
              style={{ padding: 0 }}
            />
          </div>
        ) : (
          replies.map((reply) => (
            <EmailCardMessage
              key={reply.id}
              body={reply.body}
              sentAt={reply.sentAt}
              emailParser={reply.emailParser}
              from={reply.from}
            />
          ))
        )}
        {isOverflowing && !isExpanded && !isThreaded && (
          <div className="pb-2 px-3">
            <Button
              label="View entire message"
              variant="hollow-link"
              size="small"
              leftIcon={<ChevronDownIcon color={Color.Neutral800} />}
              onClick={() => setIsExpanded(!isExpanded)}
              style={{ padding: 0 }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
