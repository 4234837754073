import { FormOrder } from "common/model/Order/OrderForm/State"
import { OrderFormContextProvider, useOrderForm } from "../../OrderFormContext"
import { OrderFormHeader } from "../../OrderFormHeader"
import RefreshOrderForm from "./RefreshOrderForm"
import { RefreshOrderFormProgressButtons } from "./RefreshOrderFormProgressButtons"
import { RefreshOrderConfirmationPage } from "./RefreshOrderConfirmationPage"
import { OrderFormInitialInformationStatic } from "../../OrderFormInitialInformation"
import { Divider } from "@stories/components/Antd"
import { OrderFormSaveFunction } from "../../contextHooks/useFormOrderSaving"
import { DrawerControlProvider } from "@components/Drawer/DrawerControlContext"

const RefreshOrderFormContainerInner = ({
  drawerTitle,
  drawerSubtitle,
  onClose,
}: {
  drawerTitle: string
  drawerSubtitle: string
  onClose: () => void
}) => {
  const { saveStatus } = useOrderForm()
  if (saveStatus !== "pending") {
    return <RefreshOrderConfirmationPage />
  }
  return (
    <>
      <div className="mb-8">
        <OrderFormHeader
          drawerTitle={drawerTitle}
          drawerSubtitle={drawerSubtitle}
          onClose={onClose}
        />
      </div>
      <OrderFormInitialInformationStatic />
      <Divider />
      <RefreshOrderForm />
      <RefreshOrderFormProgressButtons />
    </>
  )
}

const RefreshOrderFormContainer = ({
  initialFormOrder,
  initialOrderId,
  onSave,
  drawerTitle,
  drawerSubtitle,
  onClose,
}: {
  onClose: () => void
  onSave: OrderFormSaveFunction
  initialFormOrder: Partial<FormOrder>
  initialOrderId?: string
  drawerTitle?: string
  drawerSubtitle?: string
}) => (
  <DrawerControlProvider onClose={onClose}>
    <OrderFormContextProvider
      initialOrderId={initialOrderId}
      initialFormOrder={initialFormOrder}
      onSave={onSave}
      orderFormOpen
    >
      <RefreshOrderFormContainerInner
        drawerTitle={drawerTitle ?? ""}
        drawerSubtitle={drawerSubtitle ?? ""}
        onClose={onClose}
      />
    </OrderFormContextProvider>
  </DrawerControlProvider>
)

export default RefreshOrderFormContainer
