import {
  DealCRMContact,
  DealCRMContactCommonFields,
  isDealCRMFirmContact,
} from "common/model/DealCRM/DealCRMContact"
import { DealCRMFirmContact } from "common/model/DealCRM/DealCRMFirmContact"
import { DealCRMIndividualContact } from "common/model/DealCRM/DealCRMIndividualContact"
import { Just, Maybe, Nothing } from "common/containers/Maybe"
import { DealCRMBrokerContact } from "common/model/DealCRM/DealCRMBrokerContact"
import { defaultContactInvestmentPreferences } from "common/model/DealCRM/ContactInvestmentPreferences"

export const validateNewDealCRMContact = (contact: DealCRMContact): Maybe<DealCRMContact> => {
  if (contact.tag === "individual") {
    if (contact.firstName.length > 0 && contact.lastName.length > 0 && contact.classification) {
      return Just(contact)
    } else {
      return Nothing
    }
  }
  return contact.name.length > 0 ? Just(contact) : Nothing
}

export const defaultIndividualContact = (params: {
  classification?: DealCRMIndividualContact["classification"]
  creationSource: DealCRMContactCommonFields["creationSource"]
  creationSourceContact?: DealCRMContact
}): DealCRMIndividualContact => ({
  id: "",
  sourceId: "",
  tag: "individual",
  name: "",
  firstName: "",
  lastName: "",
  accountId: "",
  createdAt: new Date(),
  holdings: [],
  notes: [],
  contactBuyInterest: [],
  contactSellInterest: [],
  isFundLP: false,
  previousFirmIds: [],
  previousFirms: [],
  isTransactionalIndividual: false,
  title: "",
  email: null,
  phone: "",
  individualType: "firm-employee",
  updates: [],
  investmentPreferences: defaultContactInvestmentPreferences,
  creationSource: params.creationSource,
  classification: params.classification ?? null,
  firm:
    params.creationSourceContact && isDealCRMFirmContact(params.creationSourceContact)
      ? params.creationSourceContact
      : null,
})

export const defaultFirmContact = (params: {
  creationSource: DealCRMContactCommonFields["creationSource"]
}): DealCRMFirmContact => ({
  id: "",
  sourceId: "",
  tag: "firm",
  name: "",
  accountId: "",
  createdAt: new Date(),
  holdings: [],
  notes: [],
  contactBuyInterest: [],
  contactSellInterest: [],
  isFundLP: false,
  contacts: [],
  contactIds: [],
  classification: "",
  updates: [],
  creationSource: params.creationSource,
  isBrokerage: false,
  investmentPreferences: defaultContactInvestmentPreferences,
})

export const defaultBrokerContact = (params: {
  creationSource: DealCRMContactCommonFields["creationSource"]
}): DealCRMBrokerContact => ({
  id: "",
  sourceId: "",
  tag: "broker",
  name: "",
  accountId: "",
  createdAt: new Date(),
  holdings: [],
  notes: [],
  contactBuyInterest: [],
  contactSellInterest: [],
  isFundLP: false,
  firstName: "",
  lastName: "",
  email: null,
  phone: null,
  firm: null,
  brokerDealer: null,
  previousFirmIds: [],
  previousFirms: [],
  updates: [],
  creationSource: params.creationSource,
  investmentPreferences: defaultContactInvestmentPreferences,
})

export const defaultBrokerageFirmContact = (params: {
  creationSource: DealCRMContactCommonFields["creationSource"]
}): DealCRMFirmContact & { isBrokerage: true } => ({
  ...defaultFirmContact(params),
  isBrokerage: true,
})
