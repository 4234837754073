/* eslint-disable @typescript-eslint/quotes */

const opportunitiesDisclaimer = `Opportunities offered on the platform may consist of investments into private companies, which are illiquid and are not expected to behave like publicly traded companies or derivatives thereof. Investors acknowledge that they understand the investment risks associated with these financial products, including the potential loss of all principal. Any valuations and/or pricing displayed herein are approximate, are for illustrative purposes only and may not be relied upon for any purpose. Valuation and/or pricing displayed herein may be subject to change, as the public information incorporated into the analysis may be incomplete and has not been independently corroborated by Caplight Technologies, Inc or any issuers. Valuation may further be impacted by material non-public information that is outside of Caplight's possession. Investors should conduct their own research and analysis on companies of interest and should not rely on the presentation herein.`

const marketUpdatesDisclaimer = `Market updates and news are curated by Caplight Technologies Inc., ("Caplight Technologies") and may not include all information that would be relevant to investors. Investments in private companies are not expected to behave like publicly traded companies or derivatives thereof.`

const investingDisclaimer = `Caplight does not provide investment, tax, or legal advice. Caplight will not be held responsible for the decisions you make to buy, sell, or hold based on the information provided by Caplight or any of its affiliates. An offer or a solicitation can be made only through the delivery of final offering document(s) and purchase agreement(s), and will be subject to the terms and conditions and risks delivered in such documents. Any securities offered are offered through Caplight Markets LLC, member FINRA/SIPC, an affiliate of Caplight.`

const clearbitDisclaimer = `Logos provided by Clearbit.`

const securitiesDisclaimer = `All investments involve risk, including the risk of loss of principal. Investments into private companies may be more volatile and have little or no liquidity. You should carefully consider your investment objectives, risks, transaction costs and other expenses before deciding to invest in options, swaps or other investments.`

const solicitationDisclaimer = `This does not constitute an offer by Caplight Technologies, Inc. to sell, or a solicitation of an offer to buy, any securities and may not be used or relied upon in connection with any offer or sale of securities. An offer or solicitation can be made only through the delivery of final offering document(s) and purchase agreement(s), and will be subject to the terms and conditions and risks delivered in such documents. Any securities offered are offered through Caplight Markets LLC, member FINRA/SIPC.`

export const caplightDisclaimers = [
  solicitationDisclaimer,
  opportunitiesDisclaimer,
  marketUpdatesDisclaimer,
  securitiesDisclaimer,
  investingDisclaimer,
]

export const disclaimerHTMLString = caplightDisclaimers
  .concat([clearbitDisclaimer])
  .map((disclaimer) => `<p>${disclaimer}</p>`)
  .join("")
