import { wrapIcon } from "./IconWrapper"

export const ChevronRightIcon = wrapIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.29289 2.62622C5.68342 2.2357 6.31658 2.2357 6.70711 2.62622L11.3738 7.29289C11.7643 7.68341 11.7643 8.31658 11.3738 8.7071L6.70711 13.3738C6.31658 13.7643 5.68342 13.7643 5.29289 13.3738C4.90237 12.9832 4.90237 12.3501 5.29289 11.9596L9.25245 7.99999L5.29289 4.04044C4.90237 3.64991 4.90237 3.01675 5.29289 2.62622Z"
      fill="currentColor"
    />
  </svg>
)
