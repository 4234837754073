import { DealCRMFund } from "common/model/DealCRM/Deal/DealCRMFund"
import { deprecatedIsLoaded } from "common/utils/Loading"
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react"
import { useFirebaseReader } from "src/firebase/Context"
import { getAccountCrmFunds } from "../../../firebase/crm"
import { useCurrentUser } from "../../../providers/currentUser/useCurrentUser"

interface CRMFundsContextType {
  funds: DealCRMFund[]
}

export const CRMFundsContext = createContext<CRMFundsContextType>({
  funds: [],
})

export const CRMFundsProvider = ({ children }: { children: ReactNode }) => {
  const firebase = useFirebaseReader()
  const user = useCurrentUser()
  const [funds, setFunds] = useState<DealCRMFund[]>([])
  useEffect(() => {
    if (deprecatedIsLoaded(user)) {
      const unsubscribe = getAccountCrmFunds({
        db: firebase,
        accountId: user.user.account.id,
      }).onSnapshot((snapshot) => {
        const fundDocs = snapshot.docs.map((doc) => doc.data())
        setFunds(fundDocs)
      })
      return unsubscribe
    }
    return () => {}
  }, [firebase, user])

  const memoizedFunds = useMemo(() => ({ funds }), [funds])

  return <CRMFundsContext.Provider value={memoizedFunds}>{children}</CRMFundsContext.Provider>
}

export const useCRMFunds = () => useContext(CRMFundsContext)
