import algoliaSearch from "algoliasearch/lite"
import { AlgoliaIndexedCompany } from "common/model/AlgoliaIndexedCompany"
import { config } from "../config"
import { PublicCompany } from "common/model/PublicCompany"

const client = algoliaSearch(config.algolia.appId, config.algolia.apiKey)
const companiesIndex = client.initIndex(config.algolia.companiesIndexName)
const publicCompaniesIndex = client.initIndex(config.algolia.publicCompaniesIndexName)

const DEFAULT_MAX_COMPANY_SEARCH_RESULTS = 6

interface CompanySearchOptions {
  maxResults?: number
  includeNonPrivateCompanies?: boolean
}

const isValidCompany = (company: AlgoliaIndexedCompany): boolean =>
  company.name.split(" ").length < 6

export const searchCompanies = (
  input: string,
  options?: CompanySearchOptions
): Promise<AlgoliaIndexedCompany[]> =>
  companiesIndex
    .search(input, {
      hitsPerPage: options?.maxResults || DEFAULT_MAX_COMPANY_SEARCH_RESULTS,
      filters: options?.includeNonPrivateCompanies ? undefined : "status:Private",
    })
    .then(({ hits }) => {
      const companies = hits as AlgoliaIndexedCompany[]
      return companies.filter(isValidCompany)
    })

interface PublicCompanySearchOptions {
  maxResults?: number
}

export const searchPublicCompanies = (
  input: string,
  options?: PublicCompanySearchOptions
): Promise<PublicCompany[]> =>
  publicCompaniesIndex
    .search<PublicCompany>(input, {
      hitsPerPage: options?.maxResults || DEFAULT_MAX_COMPANY_SEARCH_RESULTS,
    })
    .then(({ hits }) => hits)