import { FormLabel } from "@stories/components/Inputs/FormLabel/FormLabel"
import {
  NumberField,
  sizeScaledInputWarning,
} from "@stories/components/Inputs/NumberInput/NumberField"
import SectionLabel from "@stories/components/Inputs/SectionLabel/SectionLabel"
import { Company } from "common/model/Company"
import { PriceObservationType } from "common/model/data-product/pricing/PriceObservation"
import { MILLION, THOUSAND, formatAbbreviatedNumber, formatValuation } from "common/utils/math"
import { useCompanyValuation } from "src/data/Hooks/useCompanyValuation"
import { PriceObservationErrors } from "src/pages/ClosedTrades/ClosedTradeForm/contextHooks/useClosedTradeFormSaving"

type ClosedTradePriceData = Pick<PriceObservationType, "price" | "volume">

export const ClosedTradePriceSection = ({
  value,
  onChange,
  title,
  hiddenFields,
  titles,
  formErrors,
  company,
}: {
  value: Partial<ClosedTradePriceData>
  onChange: (priceData: Partial<ClosedTradePriceData>) => void
  title: string
  titles: {
    volume: string
    price: string
  }
  hiddenFields?: string[]
  formErrors: PriceObservationErrors
  company?: Company | null
}) => (
  <div>
    <SectionLabel>{title}</SectionLabel>
    <div className="mb-4 grid md:grid-cols-2 grid-cols-1 gap-4">
      {hiddenFields?.includes("price") ? null : (
        <div>
          <FormLabel hasError={formErrors.has("price")}>{titles.price}</FormLabel>
          <NumberField
            prefix="$"
            value={value.price}
            onChange={(p) => onChange({ ...value, price: p ?? undefined })}
            name="Price"
            formatter={formatAbbreviatedNumber}
            placeholder="e.g. 15.10"
          />
          {company && value ? <EstimatedValuation company={company} value={value} /> : null}
        </div>
      )}
      {hiddenFields?.includes("size") ? null : (
        <div>
          <FormLabel hasError={formErrors.has("volume")}>{titles.volume}</FormLabel>
          <NumberField
            value={value.volume}
            onChange={(v) => {
              onChange({ ...value, volume: v ?? undefined })
            }}
            name="volume"
            prefix="$"
            inputWarningFunction={(v) => {
              if (!v) return null
              const sizeScaleWarning = sizeScaledInputWarning(v)
              const volumeBoundsWarning =
                v > 100 * MILLION || v < 10 * THOUSAND ? "Volume outside normal bounds" : null
              return sizeScaleWarning || volumeBoundsWarning
            }}
            formatter={formatAbbreviatedNumber}
            placeholder="e.g. 200K or 5M"
          />
        </div>
      )}
    </div>
  </div>
)

const EstimatedValuation = ({
  company,
  value,
}: {
  company: Company
  value: Partial<ClosedTradePriceData>
}) => {
  const { valuationFromPPS } = useCompanyValuation(company)

  const numShares = valuationFromPPS({
    price: 1,
    priceDate: new Date(),
    isSplitAdjusted: false,
  }).match(
    (x) => x.adjustedShareCount,
    () => undefined
  )

  if (!numShares) return null
  return (
    <div className="text-sm text-neutral-600 p-0">
      {`Est. Valuation: ${formatValuation(numShares * (value?.price ?? 0))}`}
    </div>
  )
}
