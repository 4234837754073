import Table from "@components/data_display/Table"
import Divider from "@stories/components/Divider/Divider"
import Typography, { Weight } from "@stories/components/Typography/Typography"
import {
  OpportunityInboxAccountUsageData,
  defaultOpportunityInboxAccountUsageData,
} from "common/model/Opportunity/admin/AdminOpportunityDigest"
import { matchLoading } from "common/utils/Loading"
import { APIEndpoints, useAPIQuery } from "src/firebase/API"

export const AdminAccountOpportunityInboxDashboard = ({ accountId }: { accountId: string }) => {
  const responseData = useAPIQuery<OpportunityInboxAccountUsageData>(
    APIEndpoints.adminOpportunityInboxDigestForAccount.build(accountId)
  )
  const data = matchLoading(
    responseData,
    (d) => d.result ?? defaultOpportunityInboxAccountUsageData,
    defaultOpportunityInboxAccountUsageData,
    defaultOpportunityInboxAccountUsageData
  )
  return (
    <div>
      <div className="flex flex-col gap-2">
        <Typography weight={Weight.Bold} text="Inbox Stats" />
        <Table
          headers={[
            { id: "type", label: "Opportunity Upload Type" },
            { id: "count", label: "Count" },
            { id: "unread", label: "Unread" },
          ]}
          rows={[
            {
              id: "allOpportunities",
              type: "Total Opportunities",
              count: `${data.opportunityData.all.allOpportunities ?? 0}`,
              unread: `${data.opportunityData.unread.allOpportunities ?? 0}`,
            },
            {
              id: "archivedOpportunities",
              type: "Archived Opportunities",
              count: `${data.opportunityData.all.archivedOpportunities ?? 0}`,
              unread: `${data.opportunityData.unread.archivedOpportunities ?? 0}`,
            },
            {
              id: "expired",
              type: "Expired Opportunities",
              count: `${data.opportunityData.all.expiredOpportunities ?? 0}`,
              unread: `${data.opportunityData.unread.expiredOpportunities ?? 0}`,
            },
            {
              id: "primary",
              type: "Primary Inbox Opportunities",
              count: `${data.opportunityData.all.primaryInboxOpportunities ?? 0}`,
              unread: `${data.opportunityData.unread.primaryInboxOpportunities ?? 0}`,
            },
            {
              id: "secondary ",
              type: "Secondary Inbox Opportunities",
              count: `${data.opportunityData.all.secondaryInboxOpportunities ?? 0}`,
              unread: `${data.opportunityData.unread.secondaryInboxOpportunities ?? 0}`,
            },
            {
              id: "pinnedOpp",
              type: "Pinned Opportunities",
              count: `${data.opportunityData.all.pinnedOpportunities ?? 0}`,
              unread: `${data.opportunityData.unread.pinnedOpportunities ?? 0}`,
            },
          ]}
        />
      </div>
      <Divider />
      <div className="flex flex-col gap-2">
        <Typography weight={Weight.Bold} text="By Upload Type" />
        <Table
          headers={[
            { id: "type", label: "Opportunity Upload Type" },
            { id: "count", label: "Count" },
            { id: "weekly", label: "From Previous Week" },
            { id: "pass", label: "Pass Rules from Prev Week" },
          ]}
          rows={[
            {
              id: "live_market",
              type: "Live Market",
              count: `${data.uploadData.all.live_market ?? 0}`,
              weekly: `${data.uploadData.thisWeek.live_market ?? 0}`,
              pass: `${data.uploadData.passingFilterThisWeek.live_market ?? 0}`,
            },
            {
              id: "private",
              type: "Private",
              count: `${data.uploadData.all.private ?? 0}`,
              weekly: `${data.uploadData.thisWeek.private ?? 0}`,
              pass: `${data.uploadData.passingFilterThisWeek.private ?? 0}`,
            },
            {
              id: "forwarded_email",
              type: "Forwarded Email",
              count: `${data.uploadData.all.forwarded_email ?? 0}`,
              weekly: `${data.uploadData.thisWeek.forwarded_email ?? 0}`,
              pass: `${data.uploadData.passingFilterThisWeek.forwarded_email ?? 0}`,
            },
            {
              id: "shared_order",
              type: "Shared Order",
              count: `${data.uploadData.all.shared_order ?? 0}`,
              weekly: `${data.uploadData.thisWeek.shared_order ?? 0}`,
              pass: `${data.uploadData.passingFilterThisWeek.shared_order ?? 0}`,
            },
            {
              id: "platform_upload",
              type: "Platform Upload",
              count: `${data.uploadData.all.platform_upload ?? 0}`,
              weekly: `${data.uploadData.thisWeek.platform_upload ?? 0}`,
              pass: `${data.uploadData.passingFilterThisWeek.platform_upload ?? 0}`,
            },
            {
              id: "dropbox_upload",
              type: "Dropbox",
              count: `${data.uploadData.all.dropbox_upload ?? 0}`,
              weekly: `${data.uploadData.thisWeek.dropbox_upload ?? 0}`,
              pass: `${data.uploadData.passingFilterThisWeek.dropbox_upload ?? 0}`,
            },
          ]}
        />
      </div>
      <Divider />
      <div className="flex flex-col gap-2">
        <Typography weight={Weight.Bold} text="Rules" />
        <Table
          headers={[
            { id: "type", label: "Rule Type" },
            { id: "count", label: "Count" },
          ]}
          rows={[
            { id: "allRules", type: "All Rule Count", count: `${data.ruleData.allRules ?? 0}` },
            {
              id: "companyRules",
              type: "Company Rules",
              count: `${data.ruleData.company ?? 0}`,
            },
            { id: "globalRules", type: "Global Rules", count: `${data.ruleData.global ?? 0}` },
            {
              id: "watchlistRule",
              type: "Watchlist Rule",
              count: `${data.ruleData["global show watchlist"] ? "On" : "Off"}`,
            },
            {
              id: "bidRule",
              type: "Bid Rule",
              count: `${data.ruleData["global show bids"] ? "On" : "Off"}`,
            },
            {
              id: "offerRule",
              type: "Offer Rule",
              count: `${data.ruleData["global show offers"] ? "On" : "Off"}`,
            },
            {
              id: "liveMarketRule",
              type: "Live Market Rule",
              count: `${data.ruleData["global hide live market"] ? "On" : "Off"}`,
            },
          ]}
        />
      </div>
    </div>
  )
}
