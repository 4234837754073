import { removeCompanyFromWatchlist } from "@components/Watchlist/WatchlistCard/helpers"
import { Button } from "@stories/components/Button/Button"
import { Account } from "common/model/Account"
import { User } from "common/model/User"
import { isLoaded } from "common/utils/Loading"
import { useMemo, useState } from "react"
import { config } from "src/config"
import { useFirebaseWriter } from "src/firebase/Context"
import { FieldValue } from "src/firebase/Firebase"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"

export const useDevResetOnboarding = () => {
  const currentUser = useCurrentUser()
  const firebase = useFirebaseWriter()
  const [loading, setLoading] = useState(false)

  //  excessive safe guards against accidentally rendering this
  const showResetOnboarding = useMemo(() => {
    if (!isLoaded(currentUser)) {
      return false
    }

    const userIsAdmin = currentUser.isAdmin

    const userHasCaplightEmail = ["caplight.io", "caplight.com"].some((domain) =>
      currentUser.user.email.includes(domain)
    )

    return userIsAdmin || userHasCaplightEmail || ["staging", "dev"].includes(config.env)
  }, [currentUser])

  const resetOnboarding = useMemo(
    () => async () => {
      if (!showResetOnboarding || !isLoaded(currentUser)) {
        return Promise.resolve()
      }

      setLoading(true)
      const updateAccount = firebase.account(currentUser.user.account.id).update({
        onboardingStatus: {},
        clientType: [],
        selfReportedClientProfile: FieldValue.delete(),
        selfReportedBrokerProfile: FieldValue.delete(),
        targetNotionalValueLow: FieldValue.delete(),
        targetNotionalValueHigh: FieldValue.delete(),
        typicalOrderDirection: FieldValue.delete(),
        yearsOfSecondaryExperience: FieldValue.delete(),
        primaryUseCase: FieldValue.delete(),
        primaryContact: null,
      } satisfies Partial<{ [k in keyof Account]: Account[k] | ReturnType<typeof FieldValue.delete> }>)
      const updateUser = firebase
        .user(currentUser.user.id)
        .update({ onboardingStatus: { dataOnboardingComplete: false } } satisfies Partial<{
          [k in keyof User]: User[k] | ReturnType<typeof FieldValue.delete>
        }>)

      const clearWatchlist = firebase
        .getAccountWatchlist(currentUser.user.account.id)
        .get()
        .then(({ docs }) =>
          docs
            .map((doc) => doc.data())
            .map((item) => removeCompanyFromWatchlist(firebase, item, { hideNotification: true }))
        )

      return Promise.all([updateAccount, updateUser, clearWatchlist]).finally(() =>
        setLoading(false)
      )
    },
    [showResetOnboarding, currentUser, firebase]
  )

  return useMemo(
    () => ({ showResetOnboarding, resetOnboarding, loading }),
    [showResetOnboarding, resetOnboarding, loading]
  )
}

export const DevResetOnboardingButton = () => {
  const { showResetOnboarding, resetOnboarding, loading } = useDevResetOnboarding()

  if (!showResetOnboarding) {
    return null
  }

  return (
    <div className="mt-4 flex w-full items-center justify-center">
      <Button
        onClick={resetOnboarding}
        label="dev reset onboarding"
        variant="neutral"
        isLoading={loading}
      />
    </div>
  )
}
