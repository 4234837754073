import Typography, { Size } from "@stories/components/Typography/Typography"
import DownChevronIcon from "@stories/icons/DownChevron"
import {
  DealCrmDealContactCommitmentStatus,
  DealCrmDealContactCommitmentStatuses,
} from "common/model/DealCRM/Deal/DealCRMDealParticipant"
import { FC } from "react"
import { Select } from "../../../../stories/components/Antd"
import { getDealCommitmentDisplayText } from "./CommitmentPill"

interface CommitmentDisplayProps {
  onChange?: (newValue: DealCrmDealContactCommitmentStatus) => Promise<void>
  value: DealCrmDealContactCommitmentStatus
  editable: boolean
}

const CommitmentDisplay: FC<CommitmentDisplayProps> = ({ onChange, value, editable }) => {
  // eslint-disable-next-line rulesdir/mutating-array-methods
  const options = DealCrmDealContactCommitmentStatuses.map((val) => ({
    value: val,
    label: (
      <Typography text={getDealCommitmentDisplayText(val)} size={Size.Small} shouldWrap={false} />
    ),
  })).sort((a, b) => {
    const statusOrder = {
      "not-interested": 0,
      "pending-outreach": 1,
      "pending-reply": 2,
      "tentative-interest": 3,
      "firm-interest": 4,
      "signed-commitment": 5,
      wired: 6,
    }

    return statusOrder[b.value] - statusOrder[a.value]
  })

  if (editable && onChange) {
    return (
      <div className="text-sm">
        <Select
          bordered={false}
          value={value}
          style={{ width: "100%" }}
          options={options}
          onChange={onChange}
          suffixIcon={<DownChevronIcon />}
        />
      </div>
    )
  }
  return (
    <div className="text-sm">
      <Typography text={getDealCommitmentDisplayText(value)} size={Size.Small} shouldWrap={false} />
    </div>
  )
}

export default CommitmentDisplay
