import { Func } from "../../fp/Function"

type KVP<K, V> = {
  key: K
  value: V
}

/** A TotalMap<K,V> should be thought of as a Map<K,V> where every `K` has a corresponding value. */
export interface TotalMapRecord<K, V> {
  entries: KVP<K, V>[] // TODO: Custom converters to transform this into a map
  defaultValue: V
}

/** Extract the underlying `Map` from a `TotalMap` */
export const forgetDefaultValue = <K, V>(m: TotalMapRecord<K, V>): Map<K, V> =>
  new Map(m.entries.map(({ key, value }) => [key, value]))
/** Get the non-default indices of a `TotalMap` */
export const totalMapIndices = <K, V>(m: TotalMapRecord<K, V>) => m.entries.map(({ key }) => key)
/** Get the non-default values of a `TotalMap` */
export const totalMapValues = <K, V>(m: TotalMapRecord<K, V>) => m.entries.map(({ value }) => value)

/** Insert a new key-value pair into a `TotalMap` */
export const insertTotalMap =
  <K, V>(key: K, value: V) =>
  (m: TotalMapRecord<K, V>): TotalMapRecord<K, V> => {
    const result = {
      entries: m.entries.map((x) => x),
      defaultValue: m.defaultValue,
    }
    const ix = result.entries.findIndex(({ key: k }) => key === k)
    if (ix === -1) {
      result.entries.push({ key, value })
    } else {
      result.entries[ix].value = value
    }
    return result
  }

/** Create a `TotalMap` where every key is associated with the same value */
export const pureTotalMap = <K, V>(defaultValue: V): TotalMapRecord<K, V> => ({
  entries: [],
  defaultValue,
})

/** Create a `TotalMap` with a single non-default value */
export const singletonTotalMap = <K, X>(key: K, value: X, defaultValue: X): TotalMapRecord<K, X> =>
  insertTotalMap(key, value)(pureTotalMap(defaultValue))

/** Given a `TotalMap<K,V>`, returns a lookup function `K => V` */
export const indexTotalMap =
  <K, V>(m: TotalMapRecord<K, V>) =>
  (key: K): V =>
    m.entries.find(({ key: k }) => key === k)?.value || m.defaultValue

/** Given a lookup function `K => V`, a defaultValue value `V`, and a list of non-default indices `K[]`, returns the corresponding `TotalMap` */
export const tabulateTotalMap =
  <K, V>(indices: K[], defaultValue: V) =>
  (f: Func<K, V>): TotalMapRecord<K, V> => ({
    defaultValue,
    entries: indices.map((k) => ({ key: k, value: f(k) })),
  })

/** Map over the values in a `TotalMap` */
export const mapTotalMap =
  <X, Y>(f: (x: X) => Y) =>
  <K>(x: TotalMapRecord<K, X>): TotalMapRecord<K, Y> => ({
    entries: x.entries.map(({ key, value }) => ({ key, value: f(value) })),
    defaultValue: f(x.defaultValue),
  })

/** Zip two `TotalMap`s together. */
export const lift2TotalMap =
  <X, Y, Z>(f: (x: X, y: Y) => Z) =>
  <K>(l: TotalMapRecord<K, X>, r: TotalMapRecord<K, Y>): TotalMapRecord<K, Z> => {
    const indices = [...new Set([...totalMapIndices(l), ...totalMapIndices(r)])]
    const lookup = (k: K) => f(indexTotalMap(l)(k), indexTotalMap(r)(k))
    return tabulateTotalMap(indices, f(l.defaultValue, r.defaultValue))(lookup)
  }

// hideously inefficient, TODO fix
export const eqTotalMap = <K, V>(
  eqKey: (l: K, r: K) => boolean,
  eqValue: (l: V, r: V) => boolean,
  l: TotalMapRecord<K, V>,
  r: TotalMapRecord<K, V>
) =>
  eqValue(l.defaultValue, r.defaultValue) &&
  l.entries.every(({ key, value }) =>
    r.entries.find(({ key: key2, value: value2 }) => eqValue(value, value2) && eqKey(key, key2))
  ) &&
  r.entries.every(({ key, value }) =>
    l.entries.find(({ key: key2, value: value2 }) => eqValue(value, value2) && eqKey(key, key2))
  )
