import { AccountRFQProfile } from "../../../model/Account/AccountRFQProfiles"
import { BrokerClientRelationship } from "../../../model/BrokerClient"
import { CompanyIdFields } from "../../../model/Company"
import {
  flattenOrderSlice,
  Order,
  OrderDirection,
  orderLatestTerms,
} from "../../../model/Order/Order"
import { orderLiveUntil } from "../../../model/Order/Types/Status"
import { OrderQuantityTerms, OrderStructure } from "../../../model/Order/Types/Terms"
import { Interval } from "../../../utils/data/Interval"
import { Date_ } from "../../../utils/dateUtils"
import { Maybe, nullableToMaybe } from "../../../containers/Maybe"
import { UnconfirmableYesNo } from "../../../model/UnconfirmableYesNo"

export type FlatOrder = {
  // I want to make these classes, but I haven't been able to get typescript to generate the necessary fields automatically. As always, I am obligated to note that this wouldn't be an issue with Purescript.
  id: string
  direction: OrderDirection
  brokerClientRelationship: Maybe<BrokerClientRelationship>
  clientConfirmedAt: Maybe<Date>
  clientROFR: Maybe<boolean>
  clientProfile: Maybe<AccountRFQProfile>
  areTransactionDocumentOnHand: Maybe<UnconfirmableYesNo>
  diligenceAvailable: Maybe<UnconfirmableYesNo>
  requiresDiligence: Maybe<UnconfirmableYesNo>
  brokerRecentlyClosedTradeInCompany: Maybe<boolean>
  company: CompanyIdFields
  quantity: Maybe<OrderQuantityTerms>
  carry: Maybe<number>
  managementFee: Maybe<number>
  createdAt: Date
  orderOriginationDate: Date
  liveDuring: Interval<Date>
  lastUpdatedAt: Date
  structure: OrderStructure
  firmness: Order["firmness"]
  account: Order["source"]["account"]
  shareClasses: Order["shareClasses"]
  publicAdminNotes: Order["publicAdminNotes"]
  timeSensitive: Order["timeSensitive"]
  /** @deprecated */
  caplightPromotion: Order["caplightPromotion"]
  /** @deprecated */
  caplightAuctionInterest: Order["caplightAuctionInterest"]
}

/** @deprecated */
export const flattenOrderForDisplay = (order: Order): Maybe<FlatOrder> =>
  flattenOrderSlice(orderLatestTerms(order)).map((x) => ({
    ...x,
    carry: nullableToMaybe(x.carry),
    managementFee: nullableToMaybe(x.managementFee),
    lastUpdatedAt: order.lastUpdatedAt,
    brokerClientRelationship: nullableToMaybe(order.brokerClientMetadata?.relationship),
    clientConfirmedAt: nullableToMaybe(order.brokerClientMetadata?.confirmedAt),
    clientROFR: nullableToMaybe(order.brokerClientMetadata?.sellerROFR),
    clientProfile: nullableToMaybe(order.brokerClientMetadata?.profile),
    company: order.company,
    quantity: nullableToMaybe(x.quantity),
    createdAt: order.createdAt,
    orderOriginationDate: order.orderOriginationDate ?? Date_.minValue,
    liveDuring: {
      lowerBound: order.orderOriginationDate ?? Date_.minValue,
      upperBound: orderLiveUntil(order),
    },
    structure: x.structure,
    direction: order.direction,
    id: order.id,
    firmness: order.firmness,
    account: order.source.account,
    shareClasses: order.shareClasses,
    areTransactionDocumentOnHand: nullableToMaybe(
      order.orderDocuments?.areTransactionDocumentOnHand
    ),
    diligenceAvailable: nullableToMaybe(order.orderDocuments?.diligenceAvailable),
    requiresDiligence: nullableToMaybe(order.orderDocuments?.requiresDiligence),
    brokerRecentlyClosedTradeInCompany: nullableToMaybe(
      order.orderDocuments?.brokerRecentlyClosedTradeInCompany
    ),
    publicAdminNotes: order.publicAdminNotes,
    caplightPromotion: order.caplightPromotion,
    caplightAuctionInterest: order.caplightAuctionInterest,
    timeSensitive: order.timeSensitive,
  }))
