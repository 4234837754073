import { selectedCompanyFromAlgoliaCompany } from "@components/companies/select/AlgoliaCompanyAutocomplete"
import { OfficeBuildingIcon } from "@heroicons/react/solid"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import {
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react"
import { searchCompanies } from "src/services/Algolia"
import { classNames } from "src/utils/classNames"
import { NoteTag, SavedNoteTag, viewSavedNoteTag } from "./SuggestionConfig"
import { viewCompanyIdFields } from "common/model/Company"
import { filterMaybe } from "common/containers/Maybe"
import {
  Loading,
  defaultIfLoading,
  isLoaded,
  isLoading,
  lift2Loading,
  matchLoading,
} from "common/utils/Loading"
import { AccountUserSharedFields, User, viewUserPublicFields } from "common/model/User"
import { handleConsoleError } from "src/utils/Tracking"
import { UserInboxIcon } from "@stories/icons/UserInboxIcon"
import { APIEndpoints, useAPIQuery } from "src/firebase/API"
import SkeletonLoader from "@components/icons/SkeletonLoader"
import { getSessionUserId } from "src/utils/LocalStorage"

interface MentionListProps {
  items: NoteTag[]
  text: string
  command: (item: { id: string; label: SavedNoteTag }) => void
}
const MAX_RESULTS = 3
const useSuggestedCompanies = (searchValue: string) => {
  const [suggestedCompanies, setSuggestedCompanies] = useState<Loading<NoteTag[]>>("loading")

  const handleFindSuggestedCompanies = useCallback(async () => {
    const results = await searchCompanies(searchValue, {
      maxResults: MAX_RESULTS,
    })

    const maybeCompanies = results
      .filter((doc) => doc.name.toLowerCase().startsWith(searchValue.toLowerCase()))
      .map(selectedCompanyFromAlgoliaCompany)

    const filteredResults = filterMaybe(maybeCompanies)

    setSuggestedCompanies(
      filteredResults.map(
        (company) =>
          ({
            name: company.name,
            icon: company.logos?.xs ?? (
              <OfficeBuildingIcon className={classNames("text-neutral-600 h-4 w-4")} />
            ),
            id: company.id,
            tag: "company",
            data: viewCompanyIdFields(company),
          } satisfies NoteTag)
      )
    )
  }, [searchValue])

  useEffect(() => {
    handleFindSuggestedCompanies().catch(handleConsoleError)
  }, [handleFindSuggestedCompanies])

  return { suggestedCompanies }
}

const useSuggestedUsers = (searchValue: string) => {
  const currentUserId = getSessionUserId()
  const users = useAPIQuery<AccountUserSharedFields[]>(APIEndpoints.accountUsers)
  const suggestedColleagues: Loading<NoteTag[]> = useMemo(
    () =>
      matchLoading(
        users,
        (loadedUsers) =>
          loadedUsers.success && loadedUsers.result
            ? loadedUsers.result
                .filter(
                  (u) =>
                    u.id !== currentUserId &&
                    `${u.firstName} ${u.lastName}`.toLowerCase().includes(searchValue.toLowerCase())
                )
                .slice(0, MAX_RESULTS)
                .map(
                  (u) =>
                    ({
                      name: `${u.firstName} ${u.lastName}`,
                      id: u.id,
                      tag: "user",
                      data: viewUserPublicFields(u),
                      icon: <UserInboxIcon />,
                    } satisfies NoteTag)
                )
            : null,
        null,
        "loading"
      ),
    [searchValue, users, currentUserId]
  )

  return { suggestedColleagues }
}

export const MentionList = forwardRef((props: MentionListProps, ref: Ref<unknown>) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const cleanedValue = props.text.substring(1).replace(/[^a-zA-Z\s.]/g, "")
  const { suggestedColleagues } = useSuggestedUsers(cleanedValue)
  const { suggestedCompanies } = useSuggestedCompanies(cleanedValue)

  const allSuggestions = useMemo(
    () => lift2Loading((a, b) => a.concat(b), suggestedCompanies, suggestedColleagues),
    [suggestedCompanies, suggestedColleagues]
  )
  const defaultedAllSuggestions = defaultIfLoading(allSuggestions, [])

  const selectItem = (item: NoteTag) => {
    if (item) {
      props.command({ id: `${item.tag}:${item.id}`, label: viewSavedNoteTag(item) })
    }
  }

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + defaultedAllSuggestions.length - 1) % defaultedAllSuggestions.length
    )
  }

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % defaultedAllSuggestions.length)
  }

  const enterHandler = () => {
    const item = defaultedAllSuggestions[selectedIndex]
    selectItem(item)
  }

  useEffect(() => setSelectedIndex(0), [allSuggestions])

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: { key: string } }) => {
      if (event.key === "ArrowUp") {
        upHandler()
        return true
      }

      if (event.key === "ArrowDown") {
        downHandler()
        return true
      }

      if (event.key === "Enter") {
        enterHandler()
        return true
      }

      return false
    },
  }))

  return (
    <div className="bg-neutral-100 relative text-neutral-900 text-sm shadow-sm border border-neutral-600 rounded-lg w-80">
      {isLoading(suggestedCompanies) ? (
        <SkeletonLoader numRows={2} />
      ) : suggestedCompanies?.length ? (
        <div>
          <SectionHeader text="Companies" />
          {suggestedCompanies.map((item, index) => (
            <MentionListItem
              key={item.id}
              index={index}
              selectedIndex={selectedIndex}
              selectItem={selectItem}
              item={item}
            />
          ))}
        </div>
      ) : null}
      {isLoading(suggestedColleagues) ? (
        <SkeletonLoader numRows={2} />
      ) : suggestedColleagues?.length ? (
        <div>
          <SectionHeader text="Colleagues" />
          {suggestedColleagues.map((item, index) => (
            <MentionListItem
              key={item.id}
              index={index + defaultIfLoading(suggestedCompanies, []).length}
              selectedIndex={selectedIndex}
              selectItem={selectItem}
              item={item}
            />
          ))}
        </div>
      ) : null}
      {isLoaded(allSuggestions) && allSuggestions?.length === 0 && (
        <div className="block m-0 w-full text-left bg-opacity-0 rounded-sm px-2 py-1">
          No results
        </div>
      )}
    </div>
  )
})

const SectionHeader = ({ text }: { text: string }) => (
  <div className="pl-3 my-1.5">
    <Typography size={Size.XSmall} weight={Weight.Semibold} color={Color.Subtitle} text={text} />
  </div>
)

const MentionListItem = ({
  item,
  index,
  selectedIndex,
  selectItem,
}: {
  item: NoteTag
  index: number
  selectedIndex: number
  selectItem: (item: NoteTag) => void
}) => (
  <button
    type="button"
    className={classNames(
      "block m-0 w-full text-left rounded-lg px-2 py-1 hover:bg-neutral-300 hover:bg-opacity-100 text-neutral-1000",
      index === selectedIndex && "border-white bg-neutral-300 bg-opacity-100"
    )}
    key={index}
    onClick={() => selectItem(item)}
  >
    <div className="flex items-center gap-2">
      {item.icon ? (
        typeof item.icon === "string" ? (
          <img src={item.icon} alt={item.name} className="w-4 h-4 rounded-full inline-block" />
        ) : (
          item.icon
        )
      ) : null}
      {item.name}
    </div>
  </button>
)
