/* eslint-disable no-param-reassign */
/** Simple hooks used by several parts of the data product */
import { Company, CompanyWithPostgres } from "common/model/Company"
import { User } from "common/model/User"
import {
  AnonymousPriceObservation,
  PriceObservationType,
} from "common/model/data-product/pricing/PriceObservation"
import { Enriched } from "common/queries/pricing/PricingEnrichment"
import { Loading } from "common/utils/Loading"
import { NaiveCache } from "common/utils/cache/NaiveCache"
import { Maybe, nullableToMaybe } from "common/containers/Maybe"
import { createCache } from "common/utils/useCache"
import moment from "moment"
import { usePricingData } from "src/data/Hooks"
import { FirebaseReader } from "../../firebase/Firebase"
import {
  getAllTargetCompanies,
  getCompanyById,
  getMostActiveCompaniesInSector,
} from "../../firebase/Firebase/data-product/Companies"
import { getAllSectors } from "../../firebase/Firebase/data-product/Sectors"
import { useDbState } from "../../utils/useDb"

const cachedGetTargetCompanies = createCache((db: FirebaseReader) => getAllTargetCompanies(db))
export const useTargetCompanies = () => useDbState(cachedGetTargetCompanies, [])

export const cachedGetCompanyById = createCache((companyId: string) =>
  createCache((db: FirebaseReader) => getCompanyById(db)(companyId))
)

export const useCompany = (companyId: string): Loading<Company> =>
  useDbState(cachedGetCompanyById(companyId), [companyId]) || null

const latestPriceObservationCache = new NaiveCache<string, AnonymousPriceObservation>()

/** @deprecated */
export const getLatestPriceObservationIfLoaded = (
  company: Company
): AnonymousPriceObservation | null => latestPriceObservationCache.get(company.id) ?? null

export const useLatestPriceObservation = (
  companyId: string,
  user: User,
  forceRefreshCache: boolean = false
): Loading<Maybe<AnonymousPriceObservation>> => {
  const newerThan = moment().subtract(5, "minutes").toDate()
  const runQuery = forceRefreshCache || !latestPriceObservationCache.has(companyId, newerThan)
  const result = usePricingData(
    (db) =>
      runQuery
        ? db.company(companyId).latestPriceObservation()
        : Promise.resolve(nullableToMaybe(latestPriceObservationCache.get(companyId, newerThan))),
    [companyId]
  )
  if (result !== "loading" && runQuery) {
    result?.runEffect((t) => latestPriceObservationCache.set(companyId, t))
  }
  return result
}

const cachedGetSectors = createCache((db: FirebaseReader) => getAllSectors(db))
export const useSectors = () => useDbState(cachedGetSectors, [])

export const useTopSectorCompanies = (
  sector: string,
  limit: number,
  useLoader: boolean,
  extraFilter?: (company: CompanyWithPostgres) => boolean
) =>
  useDbState(
    (db: FirebaseReader) => getMostActiveCompaniesInSector(db)(sector, limit, extraFilter),
    [sector],
    useLoader
  )
