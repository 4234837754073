import { Either, Left, Right } from "../../containers/Either"
import { annotate } from "../../utils/Coerce"
import { assertUnreachable } from "../../utils/fp/Function"
import {
  AccessControl,
  AccessControlFeatureName,
  AccessLevel,
  getFeatureAccessLevel,
} from "./AccessControl"
import * as TierDefinitions from "./TierDefinitions"

export const accessControlTiers = [
  "individual-trial",
  "employee",
  "group-trial",
  "basic",
  "essential",
  "pro",
  "elite",
  "free",
  "journalist",
  "limited",

  "legacy-data",
  "legacy-markets",
] as const
export type AccessControlTier = (typeof accessControlTiers)[number]

export const accessControlTierMap: { [K in AccessControlTier]: AccessControl<K> } = {
  "individual-trial": TierDefinitions.individualTrialAccessControl,
  "group-trial": TierDefinitions.groupTrialAccessControl,
  employee: TierDefinitions.employeeAccessControl,
  basic: TierDefinitions.basicAccessControl,
  essential: TierDefinitions.essentialAccessControl,
  pro: TierDefinitions.proAccessControl,
  elite: TierDefinitions.eliteAccessControl,
  free: TierDefinitions.freeAccessControl,
  journalist: TierDefinitions.journalistAccessControl,
  limited: TierDefinitions.limitedAccessControl,

  "legacy-data": TierDefinitions.legacyDataAccessControl,
  "legacy-markets": TierDefinitions.legacyMarketsAccessControl,
}

export const getAccessControlForTier = <T extends AccessControlTier>(tier: T): AccessControl<T> =>
  accessControlTierMap[tier]

export const accessControlPaidTierUpgradePath = [
  "basic",
  "essential",
  "pro",
  "elite",
] as const satisfies AccessControlTier[]
const accessControlPaidTierGroupUpgradePath = [
  "pro",
  "elite",
] as const satisfies AccessControlTier[]

export type UpgradePathTier =
  | (typeof accessControlPaidTierUpgradePath)[number]
  | (typeof accessControlPaidTierGroupUpgradePath)[number]

export const getUpgradeAccessTier = ({
  currentAccessTier,
  featureName,
  targetAccessLevel,
}: {
  currentAccessTier: AccessControlTier
  featureName: AccessControlFeatureName
  targetAccessLevel: AccessLevel
}): Either<"no-upgrade-required" | Extract<UpgradePathTier, "elite">, UpgradePathTier> => {
  if (
    getFeatureAccessLevel(featureName, getAccessControlForTier(currentAccessTier)) ===
    targetAccessLevel
  ) {
    return Left("no-upgrade-required")
  }

  const upgradePath =
    currentAccessTier === "group-trial"
      ? accessControlPaidTierGroupUpgradePath
      : accessControlPaidTierUpgradePath

  const currentAccessTierIndex =
    annotate<AccessControlTier[]>(upgradePath).indexOf(currentAccessTier)

  const nextAccessibleTier = upgradePath.find(
    (tier, currentIndex) =>
      currentIndex > currentAccessTierIndex &&
      tier !== currentAccessTier &&
      getFeatureAccessLevel(featureName, getAccessControlForTier(tier)) === targetAccessLevel
  )

  return nextAccessibleTier ? Right(nextAccessibleTier) : Left("elite")
}

export const getNextUpgradeTier = ({
  currentAccessTier,
}: {
  currentAccessTier: AccessControlTier
}): UpgradePathTier => {
  const upgradePath =
    currentAccessTier === "group-trial"
      ? accessControlPaidTierGroupUpgradePath
      : accessControlPaidTierUpgradePath

  const currentAccessTierIndex =
    annotate<AccessControlTier[]>(upgradePath).indexOf(currentAccessTier)

  const nextAccessibleTier = upgradePath.find(
    (tier, currentIndex) => currentIndex > currentAccessTierIndex && tier !== currentAccessTier
  )

  return nextAccessibleTier ?? "elite"
}

/**
 * Gives 3 or fewer upgrade tiers for displaying in "see plans" UI
 */
export const displayUpgradeTierListForCurrentTier = (
  currentAccessTier: AccessControlTier
): UpgradePathTier[] => {
  const nextUpgradeTier = getNextUpgradeTier({ currentAccessTier })

  switch (nextUpgradeTier) {
    case "basic": {
      return accessControlPaidTierUpgradePath.slice(0, 3)
    }
    case "essential": {
      return accessControlPaidTierUpgradePath.slice(0, 3)
    }
    case "pro": {
      // NOTE: we only want to show Essential if the user is currently on Essential
      return currentAccessTier === "essential"
        ? accessControlPaidTierUpgradePath.slice(1)
        : accessControlPaidTierGroupUpgradePath
    }
    case "elite": {
      return accessControlPaidTierGroupUpgradePath
    }
    default: {
      return assertUnreachable(nextUpgradeTier)
    }
  }
}

const accessControlTierToDisplayName: Record<AccessControlTier, string> = {
  "individual-trial": "Trial",
  "group-trial": "Trial",
  basic: "Basic",
  employee: "Employee",
  essential: "Essential",
  pro: "Pro",
  elite: "Elite",
  free: "Trading",
  journalist: "Journalist",
  limited: "Limited",

  "legacy-data": "Data",
  "legacy-markets": "Markets",
}
export const displayAccessControlTierName = (tier: AccessControlTier): string =>
  accessControlTierToDisplayName[tier]
