import { Link } from "react-router-dom"
import { CompanyIdFields } from "common/model/Company"
import { JsonKVP } from "../../components/displays/JSON"
import { Routes } from "../../Routes/Routes"

export const companyLink = (k: string, c: CompanyIdFields) => (
  <div>
    {JsonKVP(
      k,
      <Link className="underline" to={Routes.admin.records.company(c.id).root}>
        {c.name}
      </Link>
    )}
  </div>
)
