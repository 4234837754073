import UnreadIcon from "@stories/components/Badges/UnreadIcon/UnreadIcon"
import { deprecatedIsLoaded, Loading, matchLoading } from "common/utils/Loading"
import { EnrichedFeed, News } from "common/model/newsfeed/NewsFeed"
import moment from "moment"
import { useCallback, useEffect, useState } from "react"
import { useFirebaseWriter } from "src/firebase/Context"
import { useWatchlistPostgresIds } from "src/pages/News/Controller/Filters/FilterFunctions"
import { onlyWatchlist } from "src/pages/News/Controller/Filters/OnlyWatchlist"
import { useNewsfeedLastPostgresId } from "src/pages/News/DataConsumer/FirebaseNewsSnapshots"
import { requestNewsFeed } from "src/pages/News/DataConsumer/NewsfeedRequestFunctions"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { handleConsoleError } from "src/utils/Tracking"

export const useRecordNewsFeedInteraction = () => {
  const { user } = useLoggedInUser()
  const db = useFirebaseWriter()

  useEffect(() => {
    db.recordUserProductInteraction(user, {
      lastViewOfNewsPage: new Date(),
    }).catch(handleConsoleError)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

const LoadedUnreadWatchlistNews = ({ watchlistIds }: { watchlistIds: string[] }) => {
  const firebaseNews = useNewsfeedLastPostgresId()
  const user = useLoggedInUser()

  const [unreadUnreadItems, setUnreadItems] = useState<Loading<EnrichedFeed<News>[]>>("loading")
  const [lastViewOfNewsPage, setLastViewOfNewsPage] = useState<Date>()
  useEffect(() => {
    const newVal = matchLoading(
      user,
      (u) => u.user.productInteractionHistory.lastViewOfNewsPage,
      undefined,
      undefined
    )
    if (newVal !== lastViewOfNewsPage) {
      setLastViewOfNewsPage(newVal)
    }
  }, [user, lastViewOfNewsPage])

  const handleQuery = useCallback(
    (ids: string[], lastView: Date) =>
      requestNewsFeed(
        { authUser: user.authUser },
        {
          tag: "standalone-request",
          filters: [
            onlyWatchlist(ids.join(",")).news.asStrings,
            {
              field: "afterDate",
              value: lastView ? lastView.toISOString() : new Date().toISOString(),
            },
          ],
        },
        ({ data }) => {
          setUnreadItems(
            data.filter((i) =>
              moment(lastView).isBefore(
                i.publishedAt ? moment(Date.parse(`${i.publishedAt} UTC`)) : undefined
              )
            )
          )
        }
      ),
    [user.authUser]
  )

  useEffect(() => {
    if (lastViewOfNewsPage && watchlistIds.length) {
      handleQuery(watchlistIds, lastViewOfNewsPage ?? new Date()).catch(handleConsoleError)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleQuery, lastViewOfNewsPage])
  useEffect(() => {
    const shouldLoadMoreNews = deprecatedIsLoaded(unreadUnreadItems)
      ? unreadUnreadItems.length < 10
      : true
    if (shouldLoadMoreNews && watchlistIds.length) {
      handleQuery(watchlistIds, lastViewOfNewsPage ?? new Date()).catch(handleConsoleError)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleQuery, firebaseNews, watchlistIds])

  return (
    <>
      {watchlistIds.length && deprecatedIsLoaded(unreadUnreadItems) && unreadUnreadItems.length ? (
        <UnreadIcon
          count={unreadUnreadItems.length < 10 ? unreadUnreadItems.length : "9+"}
          variant="accent"
        />
      ) : null}
    </>
  )
}

const UnreadWatchlistNews = () => {
  const loadingWatchlistIds = useWatchlistPostgresIds()
  return matchLoading(
    loadingWatchlistIds,
    (ids) => (ids.length ? <LoadedUnreadWatchlistNews watchlistIds={ids} /> : null),
    null,
    null
  )
}

export default UnreadWatchlistNews
