import { accountTransactionSizeThresholdQuotaComplianceCheck } from "./accountTransactionSizeThresholdQuotaCompliance"
import {
  priceThresholdQuotaComplianceFunctions,
  isPriceThresholdQuotaComplianceViolation,
} from "./priceThresholdQuotaCompliance"
import { AccountIdFields } from "../../Account"
import { Company } from "../../Company"
import { OrderDirection } from "../Order"
import { OrderQuantityTerms } from "../Types/Terms"
import { AccountTransactionSizeThresholdOrderQuotaComplianceViolations } from "./accountTransactionSizeThresholdQuotaCompliance"
import { PriceThresholdOrderQuotaComplianceViolations } from "./priceThresholdQuotaCompliance"
import { assertUnreachable } from "../../../utils/fp/Function"
import { AccountOrderQuotaFields } from "../../AccountOrderQuotaFields"

export type ComplianceContextData = {
  account: AccountIdFields & AccountOrderQuotaFields
  company: Pick<
    Company,
    | "name"
    | "priceEstimatesSummary"
    | "isDemandConstrained"
    | "isSupplyConstrained"
    | "marketHistorySummary"
    | "fundingRoundsSummary"
    | "fundingRounds"
    | "stockSplits"
  >
  direction: OrderDirection
  orderQuantity: OrderQuantityTerms
}

export type OrderQuotaComplianceViolations =
  | PriceThresholdOrderQuotaComplianceViolations
  | AccountTransactionSizeThresholdOrderQuotaComplianceViolations

export type QuotaComplianceCheckFunction = (
  context: ComplianceContextData
) => OrderQuotaComplianceViolations | undefined

export type OrderQuotaComplianceFields = {
  adminIgnoreQuotaComplianceViolations?: boolean
  orderQuotaComplianceViolations?: OrderQuotaComplianceViolations[]
}

const quotaComplianceCheckFunctions: QuotaComplianceCheckFunction[] = [
  ...priceThresholdQuotaComplianceFunctions,
  accountTransactionSizeThresholdQuotaComplianceCheck,
]

export const runQuotaComplianceChecks = (
  context: ComplianceContextData
): OrderQuotaComplianceViolations[] =>
  quotaComplianceCheckFunctions.flatMap((f) => f(context) ?? [])

export const readableQuotaComplianceViolation = (
  violation: OrderQuotaComplianceViolations
): string => {
  if (violation.tag === "ACCOUNT_TRANSACTION_SIZE_THRESHOLD") {
    return "Order size does not meet size requirements."
  }
  if (isPriceThresholdQuotaComplianceViolation(violation)) {
    return "Order price does not meet price requirements."
  }
  return assertUnreachable(violation)
}
