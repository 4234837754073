import { Popover } from "@headlessui/react"
import { ChevronDownIcon } from "@stories/icons/ChevronDownIcon"
import DragIcon from "@stories/icons/DragIcon"
import { GearIcon } from "@stories/icons/GearIcon"
import { VisibilityState } from "@tanstack/react-table"
import { Reorder, useDragControls } from "framer-motion"
import { Button } from "../Button/Button"
import Typography, { Color, Size, Weight } from "../Typography/Typography"
import SwitchFilter from "./filters/SwitchFilter"
import { classNames } from "src/utils/classNames"
import FilterBarDropdownButton from "./FilterBarDropdownButton"

const ColumnItem = ({
  onReorder,
  headerName,
  isVisible,
  toggleVisibility,
}: {
  onReorder?: React.PointerEventHandler
  headerName: string
  isVisible: boolean
  toggleVisibility?: () => void
}) => (
  <div
    className={classNames(
      "flex space-x-2 justify-between p-2 transition transition-background rounded px-2 py-1 items-center whitespace-nowrap select-none",
      onReorder ? "hover:bg-neutral-300 cursor-pointer" : "cursor-not-allowed"
    )}
  >
    <div onPointerDown={onReorder} className="flex space-x-2">
      <DragIcon color={onReorder ? Color.Neutral800 : Color.Disabled} />
      <Typography
        size={Size.XSmall}
        weight={Weight.Semibold}
        shouldWrap={false}
        color={onReorder ? Color.Black : Color.Disabled}
        text={headerName}
      />
    </div>
    <SwitchFilter
      active={isVisible}
      toggle={toggleVisibility ?? (() => {})}
      disabled={!toggleVisibility}
    />
  </div>
)
const ReorderEnabledColumnItem = ({
  columnId,
  headerName,
  isVisible,
  toggleVisibility,
}: {
  columnId: string
  headerName: string
  isVisible: boolean
  toggleVisibility?: () => void
}) => {
  const controls = useDragControls()
  return (
    <Reorder.Item key={columnId} value={columnId} dragListener={false} dragControls={controls}>
      <ColumnItem
        onReorder={(e) => controls.start(e)}
        headerName={headerName}
        isVisible={isVisible}
        toggleVisibility={toggleVisibility}
      />
    </Reorder.Item>
  )
}

type TableColumnConfigButtonProps = {
  getColumnHeaderNameById: (id: string) => string | undefined
  getColumnCanHideById: (id: string) => boolean | undefined
  getColumnDisableCustomizationById: (id: string) => "start" | "end" | undefined
  columnOrder: string[]
  setColumnOrder: React.Dispatch<React.SetStateAction<string[]>>
  columnVisibility: VisibilityState
  setColumnVisibility: React.Dispatch<React.SetStateAction<VisibilityState>>
}
export const TableColumnConfigButton = ({
  getColumnHeaderNameById,
  getColumnCanHideById,
  getColumnDisableCustomizationById,
  columnOrder,
  setColumnOrder,
  columnVisibility,
  setColumnVisibility,
}: TableColumnConfigButtonProps) => {
  const getItemData = (id: string) => {
    const headerName = getColumnHeaderNameById(id)
    const isVisible = columnVisibility[id] ?? true
    const canHide = getColumnCanHideById(id)

    return { id, headerName, canHide, isVisible }
  }
  const {
    start: startIds,
    end: endIds,
    reorderEnabledIds,
  } = columnOrder.reduce(
    (acc, id) => {
      const key = getColumnDisableCustomizationById(id) ?? "reorderEnabledIds"
      return { ...acc, [key]: [...acc[key], id] }
    },
    { start: [], end: [], reorderEnabledIds: [] }
  )

  return (
    <FilterBarDropdownButton label="Customize Columns" variant="setting">
      <div className="flex flex-col">
        {startIds.map((id) => {
          const { headerName, isVisible } = getItemData(id)
          return headerName ? (
            <ColumnItem headerName={headerName} key={id} isVisible={isVisible} />
          ) : null
        })}
      </div>
      <Reorder.Group
        className="flex flex-col"
        axis="y"
        values={reorderEnabledIds}
        onReorder={(ids) => setColumnOrder([...startIds, ...ids, ...endIds])}
      >
        {reorderEnabledIds.map((id) => {
          const { headerName, canHide, isVisible } = getItemData(id)
          if (!isVisible && !canHide) {
            return null
          }
          return headerName ? (
            <ReorderEnabledColumnItem
              columnId={id}
              headerName={headerName}
              key={id}
              isVisible={isVisible}
              toggleVisibility={
                canHide
                  ? () => setColumnVisibility((prev) => ({ ...prev, [id]: !(prev[id] ?? true) }))
                  : undefined
              }
            />
          ) : null
        })}
      </Reorder.Group>
      <div className="flex flex-col">
        {endIds.map((id) => {
          const { headerName, isVisible } = getItemData(id)
          return headerName ? (
            <ColumnItem headerName={headerName} key={id} isVisible={isVisible} />
          ) : null
        })}
      </div>
    </FilterBarDropdownButton>
  )
}
