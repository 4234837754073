import { Seq } from "immutable"
import { Maybe, nullableToMaybe } from "../Maybe"
import { Order, partialOrderingToNumber } from "../../utils/fp/Ord"

export class OrderedList<T, M = T> {
  private constructor(
    readonly measure: (t: T) => M,
    readonly comparison: Order<M>,
    readonly raw: Seq.Indexed<T>
  ) {}

  static fromArray<T, M>(measure: (t: T) => M, comparison: Order<M>, array: T[] = []) {
    const raw = Seq(array).sort((l, r) =>
      partialOrderingToNumber(comparison(measure(l), measure(r)))
    )
    return new OrderedList<T, M>(measure, comparison, raw)
  }

  private modifyRaw(f: (t: typeof this.raw) => Seq.Indexed<T>) {
    return new OrderedList<T, M>(this.measure, this.comparison, f(this.raw))
  }

  filter(f: (t: T) => boolean) {
    return new OrderedList<T, M>(this.measure, this.comparison, this.raw.filter(f))
  }

  dropUntil(f: (m: M) => boolean) {
    return this.modifyRaw((t) => t.skipUntil((x) => f(this.measure(x))))
  }

  takeWhile(f: (m: M) => boolean) {
    return this.modifyRaw((t) => t.takeWhile((x) => f(this.measure(x))))
  }

  split(at: M) {
    const lt = this.raw.takeWhile((x) => this.comparison(at, this.measure(x)) === ">")
    const geq = this.raw.skipWhile((x) => this.comparison(at, this.measure(x)) === ">")
    return {
      lt: new OrderedList(this.measure, this.comparison, lt),
      geq: new OrderedList(this.measure, this.comparison, geq),
    }
  }

  head(): Maybe<T> {
    return nullableToMaybe(this.raw.first())
  }

  last(): Maybe<T> {
    return nullableToMaybe(this.raw.last())
  }

  tail() {
    return new OrderedList(this.measure, this.comparison, this.raw.skip(1))
  }

  empty() {
    return this.head().isNothing()
  }

  reduce<S>(f: (l: T, r: S) => S, init: S): S {
    return this.head().match(
      (h) => f(h, this.tail().reduce(f, init)),
      () => init
    )
  }

  get length() {
    return this.raw.cacheResult().size ?? 0
  }

  toArray() {
    return this.raw.toArray()
  }
}
