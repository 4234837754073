import { IntroductionOrderSelector } from "@components/OrderFormComponents/OrderSelector"
import { InformationPopupBadgeButton } from "@components/layout/information-popup/InformationBadgeButton"
import { ArrowLeftIcon } from "@heroicons/react/solid"
import { Drawer, DrawerProps, notification } from "@stories/components/Antd"
import {
  DeepPartialIntroduction,
  IntroductionRequestDataKeys,
  IntroductionRequestFormState,
} from "common/model/IntroductionRequest"
import * as Wrapped from "common/model/Order/Models/Wrapped"
import { Order } from "common/model/Order/Order"
import { defaultIfLoading } from "common/utils/Loading"
import { motion } from "framer-motion"
import { useMemo, useState } from "react"
import { useEnrichOrders } from "src/data/Hooks"
import { Button } from "@stories/components/Button/Button"
import { handleConsoleError } from "src/utils/Tracking"
import { useUpsert } from "src/utils/hooks/state/useUpsert"
import {
  IntroductionSaveState,
  PendingIntroductionMessage,
} from "../IntroductionRequestDrawer/PendingIntroductionMessage"
import { IntroductionRequestHeader } from "../IntroductionRequestHeader"
import { IntroductionPreview } from "./IntroductionPreview"
import SupplementaryIntroductionRequestForm from "./SupplementaryIntroductionRequestForm"
import CaplightConnectIcon from "@stories/icons/ConnectIcon"
import Typography, { Weight } from "@stories/components/Typography/Typography"

export type RequestStates = "select_orders" | "add_notes" | "review" | "submitted"

const IntroductionRequestDrawerInner = ({
  introductionRequest,
  onClose,
  open,
  drawerProps,
  readOnlyFields,
  userIsClient,
  handleSave,
  initialState,
  wrappedOrder,
}: {
  introductionRequest: DeepPartialIntroduction
  onClose: () => void
  open: Required<DrawerProps["open"]>
  drawerProps?: Omit<DrawerProps, "onClose" | "open">
  readOnlyFields?: IntroductionRequestDataKeys[]
  userIsClient?: boolean
  handleSave: (intro: DeepPartialIntroduction) => Promise<boolean>
  initialState?: RequestStates
  wrappedOrder?: Wrapped.Order
}) => {
  const [saveState, setSaveState] = useState<IntroductionSaveState>(
    introductionRequest.id ? "success" : "pending"
  )
  const [selectedOrders, setSelectedOrders] = useState<Order[]>(
    introductionRequest.requesterOrders ?? []
  )
  const enrichedOrders = defaultIfLoading(useEnrichOrders(selectedOrders), [])
  const [introData, setIntroData] = useUpsert<IntroductionRequestFormState>(
    introductionRequest.data ?? {}
  )
  const [requestState, setRequestState] = useState<RequestStates>(
    initialState ?? (introductionRequest.id ? "submitted" : "select_orders")
  )

  const handleOrderSelection = ({ orders }: { orders: Order[] }) => {
    setSelectedOrders(orders)
  }

  const workingIntroductionRequest = useMemo(
    () => ({
      ...introductionRequest,
      requesterOrders: selectedOrders,
      updatedAt: new Date(),
      data: introData,
    }),
    [introData, selectedOrders, introductionRequest]
  )

  const saveInterest = async () => {
    setRequestState("submitted")
    return handleSave(workingIntroductionRequest)
      .then((saved) => setSaveState(saved ? "success" : "error"))
      .catch(handleConsoleError)
  }

  const handleClose = () => {
    if (selectedOrders.length && saveState === "pending") {
      notification.warn({
        style: {
          width: 600,
        },
        duration: 0,
        placement: "top",
        key: "connect-close-confirm",
        message: (
          <div>
            <div>
              <Typography text="Your Connect Request has not yet been submitted." />
            </div>
            <div>
              <Typography weight={Weight.Bold} text="Are you sure you want to cancel?" />
            </div>
          </div>
        ),
        onClose: () => notification.close("connect-close-confirm"),
        btn: (
          <div className="flex space-x-2">
            <Button
              variant="secondary"
              label="Yes I'm Sure, Cancel"
              onClick={() => {
                onClose()
                notification.close("connect-close-confirm")
              }}
              heapName="caplight-connect-warning-close"
            />
            <Button
              variant="secondary"
              label="Complete Form"
              onClick={() => {
                onClose()
                notification.close("connect-close-confirm")
              }}
              heapName="caplight-connect-warning-continue"
            />
            <Button
              leftIcon={<CaplightConnectIcon />}
              label="Submit Connect"
              onClick={() => saveInterest().then(() => notification.close("connect-close-confirm"))}
              heapName="caplight-connect-warning-submit"
            />
          </div>
        ),
      })
    } else {
      onClose()
    }
  }

  const buttonSecondaryText =
    requestState === "submitted"
      ? `Submitted at ${workingIntroductionRequest?.updatedAt?.toLocaleString() || "n/a"}`
      : `${selectedOrders.length} orders selected.`

  const footer = (
    <div className="grid grid-col-1 gap-1">
      {requestState === "select_orders" ? (
        <>
          <p className="mb-2 text-lg w-full font-semibold text-primary">{buttonSecondaryText}</p>
          <Button
            size="large"
            id={`caplight-connect-submit-${
              workingIntroductionRequest.priceObservationId?.id || "n/a"
            }`}
            label="Next"
            variant="primary"
            isDisabled={!selectedOrders.length}
            onClick={() => setRequestState("add_notes")}
          />
        </>
      ) : null}
      {requestState === "add_notes" ? (
        <>
          <Button
            size="large"
            variant="hollow"
            label="Back"
            leftIcon={<ArrowLeftIcon />}
            onClick={() => setRequestState("select_orders")}
          />
          <Button
            size="large"
            id={`caplight-connect-submit-${
              workingIntroductionRequest.priceObservationId?.id || "n/a"
            }`}
            isDisabled={!selectedOrders.length}
            onClick={() => setRequestState("review")}
            variant="primary"
            label="Review"
          />
        </>
      ) : null}
      {requestState === "review" ? (
        <>
          <Button
            size="large"
            label="Back"
            variant="hollow"
            leftIcon={<ArrowLeftIcon />}
            onClick={() => setRequestState("add_notes")}
          />
          <Button
            size="large"
            id={`caplight-connect-submit-${
              workingIntroductionRequest.priceObservationId?.id || "n/a"
            }`}
            label="Submit"
            variant="primary"
            isDisabled={!selectedOrders.length}
            onClick={saveInterest}
          />
        </>
      ) : null}
      {requestState === "submitted" ? (
        <>
          <p className="mb-2 text-sm w-full font-italic text-neutral-600">{`Submitted at ${
            workingIntroductionRequest?.updatedAt?.toLocaleString() || "n/a"
          }`}</p>
        </>
      ) : null}
    </div>
  )

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      onClick={(e) => {
        e.stopPropagation()
      }} // TODO this is dumb, but needed for how we implemented
    >
      <Drawer
        width={420}
        // eslint-disable-next-line prettier/prettier, react/jsx-props-no-spreading
        {...{ ...drawerProps, open, onClose: handleClose }}
        footer={footer}
      >
        <InformationPopupBadgeButton
          informationKey="firstViewedOrderLinkCaplightConnect"
          cardClassName="mb-1"
        />
        <IntroductionRequestHeader
          introductionRequest={workingIntroductionRequest}
          wrappedOrder={wrappedOrder}
          selectedOrders={selectedOrders}
          requestState={requestState}
        />

        <motion.div
          key={requestState}
          initial={{ x: 10, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.4 }}
        >
          {requestState === "select_orders" ? (
            <>
              <IntroductionOrderSelector
                introduction={workingIntroductionRequest}
                onChange={handleOrderSelection}
                onContinue={() => setRequestState("add_notes")}
                selectedOrders={selectedOrders}
              />
            </>
          ) : null}
          {requestState === "add_notes" ? (
            <div className="space-y-2">
              <SupplementaryIntroductionRequestForm
                introductionData={introData}
                onChange={setIntroData}
                userIsClient={userIsClient}
                readOnlyFields={readOnlyFields}
              />
            </div>
          ) : null}
          {requestState === "review" ? (
            <div className="space-y-2">
              <IntroductionPreview
                intro={workingIntroductionRequest}
                enrichedOrders={enrichedOrders}
              />
            </div>
          ) : null}
          {requestState === "submitted" ? (
            <>
              <PendingIntroductionMessage
                request={workingIntroductionRequest}
                saveState={saveState}
              />
            </>
          ) : null}
        </motion.div>
      </Drawer>
    </div>
  )
}

const IntroductionRequestDrawer = (props: {
  introductionRequest: DeepPartialIntroduction
  onClose: () => void
  open: Required<DrawerProps["open"]>
  drawerProps?: Omit<DrawerProps, "onClose" | "open">
  readOnlyFields?: IntroductionRequestDataKeys[]
  userIsClient?: boolean
  handleSave: (intro: DeepPartialIntroduction) => Promise<boolean>
  initialState?: RequestStates
  wrappedOrder?: Wrapped.Order
}) => (props.open ? <IntroductionRequestDrawerInner {...props} /> : null)

export default IntroductionRequestDrawer
