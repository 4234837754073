// Renders all error snackbars, stacked layout

import { useErrorHandler } from "src/providers/errorHandler"
import { ErrorSnackbar } from "./ErrorSnackbar"

const ErrorSnackbarRenderer = () => {
  const { notifications } = useErrorHandler()
  return (
    <>
      {notifications.map((notification, i) => (
        <ErrorSnackbar key={notification.key} message={notification.message} index={i} />
      ))}
    </>
  )
}

export default ErrorSnackbarRenderer
