import { Boxed } from "../fp/Boxed"
import { identity, tuple } from "../fp/Function"
import { Just, Nothing } from "../../containers/Maybe"
import * as Raw from "../Loading"

export class Loading<T> extends Boxed<Raw.Loading<T>> {
  map<S>(f: (t: T) => S) {
    return new Loading(Raw.mapLoading(f)(this.unboxed))
  }

  alongside<T2>(x: Loading<T2>): Loading<[T, T2]> {
    return this.bind((l) => x.map(tuple(l)))
  }

  match<S1, S2 = S1, S3 = S2>(f: (t: T) => S1, ifLoading: S2, ifNull: S3) {
    return Raw.matchLoading(this.unboxed, f, ifLoading, ifNull)
  }

  bind<S>(f: (t: T) => Loading<S>): Loading<S> {
    return this.match(f, new Loading<S>("loading"), new Loading<S>(null))
  }

  toMaybe() {
    return this.match(Just, Nothing, Nothing)
  }

  withDefault(t: T): T {
    return this.match(identity, t, t)
  }

  withUnconstrainedDefault<S>(s: S): T | S {
    return this.match(identity, s, s)
  }
}
