import { wrapIcon } from "./IconWrapper"

export const FundingRoundsIcon = wrapIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
    <path
      d="M12.8381 9.44183C12.2387 9.80621 11.5503 9.99784 10.8488 9.99558C10.2611 9.99092 9.68007 9.87072 9.13877 9.64183C8.72159 10.2308 8.49832 10.9351 8.50002 11.6568V13.5C8.50017 13.5685 8.48623 13.6363 8.45906 13.6993C8.43188 13.7622 8.39206 13.8189 8.34207 13.8658C8.29207 13.9126 8.23297 13.9488 8.16843 13.9718C8.10389 13.9949 8.03529 14.0045 7.9669 14C7.83838 13.9888 7.71884 13.9294 7.63226 13.8338C7.54567 13.7382 7.49843 13.6133 7.50002 13.4843V12.7068L5.08627 10.2931C4.72746 10.4269 4.34798 10.497 3.96502 10.5C3.43782 10.5013 2.92048 10.357 2.47002 10.0831C1.10815 9.25558 0.375023 7.35121 0.516898 4.98683C0.524042 4.86451 0.575853 4.74907 0.662494 4.66243C0.749136 4.57579 0.864577 4.52398 0.986898 4.51683C3.35127 4.37746 5.25565 5.10808 6.08065 6.46996C6.40478 7.00376 6.54629 7.62859 6.48377 8.24996C6.47989 8.2981 6.46214 8.34409 6.43267 8.38236C6.40321 8.42063 6.36329 8.44954 6.31774 8.46561C6.27219 8.48167 6.22296 8.4842 6.176 8.47288C6.12905 8.46156 6.08638 8.43688 6.05315 8.40183L4.85315 7.14558C4.75862 7.05577 4.63274 7.00644 4.50236 7.00811C4.37198 7.00978 4.24741 7.06232 4.15521 7.15452C4.06301 7.24672 4.01047 7.37129 4.0088 7.50167C4.00713 7.63205 4.05646 7.75793 4.14627 7.85246L7.51377 11.3056C7.51752 11.2568 7.5219 11.2081 7.5269 11.16C7.63624 10.2329 8.0453 9.36695 8.6919 8.69371L11.8538 5.35246C11.9476 5.25872 12.0003 5.13155 12.0004 4.99893C12.0005 4.8663 11.9478 4.73909 11.8541 4.64527C11.7603 4.55145 11.6332 4.49871 11.5006 4.49865C11.3679 4.49859 11.2407 4.55122 11.1469 4.64496L8.0844 7.88371C8.05374 7.91618 8.01495 7.93984 7.97205 7.95225C7.92916 7.96465 7.88372 7.96534 7.84047 7.95424C7.79721 7.94314 7.75773 7.92065 7.7261 7.88913C7.69448 7.8576 7.67188 7.81818 7.66065 7.77496C7.3644 6.68246 7.49502 5.59496 8.06065 4.66121C9.1769 2.81871 11.7744 1.83246 15.0094 2.02246C15.1317 2.0296 15.2472 2.08141 15.3338 2.16805C15.4204 2.2547 15.4723 2.37014 15.4794 2.49246C15.6669 5.72808 14.6806 8.32558 12.8381 9.44183Z"
      fill="currentColor"
    />
  </svg>
)
