import { Account } from "common/model/Account"
import { OrderStatusUpdate, orderLiveAt, orderStatusHistory } from "common/model/Order/Types/Status"
import { matchLoading } from "common/utils/Loading"
import { useMemo } from "react"
import { useFirebaseWriter } from "src/firebase/Context"
import { useMyOrdersQuery } from "src/firebase/orders"
import { TopBrokerCardProps, TopBrokerConfigProps } from "./TopBrokerCriteriaConfig"
import moment from "moment"
import { eachDayOfInterval } from "date-fns"
import { Order } from "common/model/Order/Order"

type OrderWithUpdates = {
  updates: OrderStatusUpdate[]
  order: Order
}
const findOrdersWithRenewsWithinPeriod = (
  orders: Order[],
  startDate: Date,
  endDate: Date
): OrderWithUpdates[] =>
  orders
    .map((order) => ({
      order,
      updates: orderStatusHistory(order).filter(
        (s) => s.tag === "live" && moment(s.asOf).isBetween(startDate, endDate)
      ),
    }))
    .filter(({ updates }) => updates.length)

const findUpdatesInitiatedWhenOrderIsNotLive = ({ updates, order }: OrderWithUpdates) =>
  updates.filter((s) =>
    orderLiveAt(order, moment(s.asOf).subtract(1, "millisecond").toDate()).match(
      (isLive) => !isLive,
      () => false
    )
  )

export const useTopBrokerCriteria = ({
  config,
  account,
  startDate,
  endDate,
}: {
  config: TopBrokerConfigProps[]
  account: Account
  startDate: Date
  endDate: Date
}): TopBrokerCardProps[] => {
  const firebase = useFirebaseWriter()
  const loadingOrders = useMyOrdersQuery({
    db: firebase,
    collection: "platform",
    queryLimit: 100,
    userAccountId: account.id,
  })
  const quarterKey: "currentQuarter" | "previousQuarter" = useMemo(
    () =>
      moment(startDate).quarter() === moment().quarter() ? "currentQuarter" : "previousQuarter",
    [startDate]
  )

  const participatedConnects = useMemo(
    () =>
      (account.accountMetrics?.connectRequestsReceived?.[quarterKey] ?? 0) +
      (account.accountMetrics?.connectRequestsSubmitted?.[quarterKey] ?? 0),
    [account.accountMetrics, quarterKey]
  )

  const closedTradesSubmitted = useMemo(
    () => account.accountMetrics?.closedTradeReported?.[quarterKey] ?? 0,
    [account.accountMetrics?.closedTradeReported, quarterKey]
  )

  const refreshesAfterExpiration = useMemo(
    () =>
      matchLoading(
        loadingOrders,
        (loadedOrders) => {
          const ordersWithRenewsFromPeriod = findOrdersWithRenewsWithinPeriod(
            loadedOrders,
            startDate,
            endDate
          )

          const renewsFromPeriod = ordersWithRenewsFromPeriod.flatMap(({ updates }) => updates)

          const renewsAfterExpiration = ordersWithRenewsFromPeriod.flatMap(
            findUpdatesInitiatedWhenOrderIsNotLive
          )
          return renewsFromPeriod.length
            ? renewsAfterExpiration.length / renewsFromPeriod.length
            : 0
        },
        0,
        0
      ),
    [endDate, loadingOrders, startDate]
  )

  const liveOrders = useMemo(
    () =>
      matchLoading(
        loadingOrders,
        (loadedOrders) => {
          const dates = eachDayOfInterval({
            start: startDate,
            end: moment.min(moment(endDate), moment()).toDate(),
          } satisfies globalThis.Interval).filter((d) => moment(d).isBefore(moment.now()))
          const numberOfLiveOrdersPerDay = dates.map(
            (day) => loadedOrders.filter((o) => orderLiveAt(o, day).toNullable()).length
          )
          return numberOfLiveOrdersPerDay.reduce((prev, curr) => prev + curr, 0) / dates.length
        },
        0,
        0
      ),
    [endDate, loadingOrders, startDate]
  )

  const topBrokerCriteria = useMemo(
    () => ({ participatedConnects, closedTradesSubmitted, liveOrders, refreshesAfterExpiration }),
    [closedTradesSubmitted, liveOrders, participatedConnects, refreshesAfterExpiration]
  )

  return config.map((v) => ({ ...v, value: topBrokerCriteria[v.type] }))
}
