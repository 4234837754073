import Spinner from "@components/icons/Spinner"
import { Rec } from "common/utils/RecordUtils"
import React, {
  ButtonHTMLAttributes,
  CSSProperties,
  ReactElement,
  useContext,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
} from "react"
import { classNames } from "../../../utils/classNames"
import { ButtonGroupContext } from "../ButtonGroup/ButtonGroup"
import { Color, Font, Size, Typography, Weight } from "../Typography/Typography"
import { Tooltip } from "../Antd/Tooltip/Tooltip"

export type ButtonSize = "none" | "xs" | "small" | "medium" | "large" | "xlarge"

export type ButtonVariant =
  | "primary"
  | "secondary"
  | "tertiary"
  | "hollow"
  | "connect"
  | "hollow-dark"
  | "hollow-link"
  | "hollow-warning"
  | "full-link"
  | "neutral"
  | "icon"

// ! - remember to update the documentation below when adding/updating ButtonProps
export type ButtonProps = {
  dataAttributes?: { [key: string]: string }
  heapName?: string
  id?: string
  isDisabled?: boolean
  isFullWidth?: boolean
  isFullHeight?: boolean
  isLoading?: boolean
  isTextWrapped?: boolean
  isTransparent?: boolean
  label?: string | ReactElement
  leftIcon?: ReactElement | null
  onClick?: (e: React.MouseEvent<Element, MouseEvent>) => void
  onMouseEnter?: ButtonHTMLAttributes<HTMLButtonElement>["onMouseEnter"]
  onMouseLeave?: ButtonHTMLAttributes<HTMLButtonElement>["onMouseLeave"]
  onPointerEnter?: ButtonHTMLAttributes<HTMLButtonElement>["onPointerEnter"]
  onPointerLeave?: ButtonHTMLAttributes<HTMLButtonElement>["onPointerLeave"]
  renderRawIcons?: boolean
  rightIcon?: ReactElement | null
  shouldMaskDataInDataDog?: boolean
  size?: ButtonSize
  style?: CSSProperties
  tabIndex?: ButtonHTMLAttributes<HTMLButtonElement>["tabIndex"]
  tooltipPlacement?: "top" | "bottom" | "left" | "right"
  tooltipTitle?: ReactElement | string
  type?: "button" | "submit" | "reset"
  variant?: ButtonVariant
  disableButtonGroup?: boolean
}

/**
 * @typedef {Object} ButtonProps
 * @property {ButtonVariant} [variant] - What variant is the button?
 * @property {ButtonSize} [size] - How large should the button be?
 * @property {React.ReactElement | null} [leftIcon] - Should the button have a left icon?
 * @property {React.ReactElement | null} [rightIcon] - Should the button have a right icon?
 * @property {boolean} [renderRawIcons] - By default, we override the icon color and size to match the button. Set this to true if you want to manually set icon color and size.
 * @property {string} [label] - What should the button's label read?
 * @property {boolean} [isDisabled] - Is the button disabled?
 * @property {boolean} [isLoading] - Is the button loading?
 * @property {string} [id] - What is the component id?
 * @property {(e: React.MouseEvent<Element, MouseEvent>) => void} [onClick] - What should happen when the button is clicked?
 * @property {boolean} [isTextWrapped] - Should the button text wrap? Default no-wrap
 * @property {string} [heapName] - Name for Heap
 * @property {boolean} [isFullWidth] - Is the button full width?
 * @property {{ [key: string]: string }} [dataAttributes] - Data attributes for Heap, e.g. "source-component": "live-market-table"
 * @property {boolean} [isTransparent] - Is the button transparent?
 * @property {"button" | "submit" | "reset"} [type] - Button type
 * @property {boolean} [shouldMaskDataInDataDog] - Should data dog mask the data in this button?
 * @property {import("react").ButtonHTMLAttributes<HTMLButtonElement>["onMouseEnter"]} [onMouseEnter] - Button onMouseEnter
 * @property {import("react").ButtonHTMLAttributes<HTMLButtonElement>["onMouseLeave"]} [onMouseLeave] - Button onMouseLeave
 * @property {import("react").ButtonHTMLAttributes<HTMLButtonElement>["onPointerEnter"]} [onPointerEnter] - Button onPointerEnter
 * @property {import("react").ButtonHTMLAttributes<HTMLButtonElement>["onPointerLeave"]} [onPointerLeave] - Button onPointerLeave
 * @property {import("react").ButtonHTMLAttributes<HTMLButtonElement>["tabIndex"]} [tabIndex] - Button tabIndex
 * @property {import("react").CSSProperties} [style] - Custom style
 * @property {string} [tooltipTitle] - What should the tooltip title say?
 * @property {"top" | "bottom" | "left" | "right"} [tooltipPlacement] - Where does the tooltip appear?
 */
export const Button = React.forwardRef(
  (
    {
      variant = "primary",
      leftIcon = null,
      rightIcon = null,
      label,
      onClick,
      type = "button",
      isDisabled = false,
      isLoading = false,
      isTextWrapped = false,
      isFullWidth = false,
      id,
      heapName,
      style,
      dataAttributes,
      isTransparent = false,
      renderRawIcons = false,
      shouldMaskDataInDataDog = false,
      onMouseEnter,
      onMouseLeave,
      onPointerEnter,
      onPointerLeave,
      tabIndex,
      tooltipTitle,
      tooltipPlacement = "top",
      isFullHeight = false,
      size: propSize,
      disableButtonGroup,
    }: ButtonProps,
    ref
  ) => {
    const rawButtonGroupContext = useContext(ButtonGroupContext)
    const {
      isGrouped = false,
      size: groupSize = undefined,
      renderRawIcons: groupRenderRawIcons = undefined,
      isFullWidth: groupFullWidth = undefined,
    } = !disableButtonGroup ? rawButtonGroupContext : {}

    const size = groupSize ?? propSize ?? "medium"

    const [isHovered, setIsHovered] = React.useState(false)

    const color = textColor(variant, isDisabled, isHovered)

    const renderIcon = (icon: ReactElement | null) => {
      if (!icon) return null
      return renderRawIcons || groupRenderRawIcons
        ? icon
        : React.cloneElement(icon, { color, size })
    }

    const dataAttributeProps: Partial<Record<string, string>> = dataAttributes
      ? Rec.fromEntries(Object.keys(dataAttributes).map((k) => [`data-${k}`, dataAttributes[k]]))
      : {}

    const handleMouseEnter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setIsHovered(true)
      if (onMouseEnter) onMouseEnter(e)
    }

    const handleMouseLeave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setIsHovered(false)
      if (onMouseLeave) onMouseLeave(e)
    }

    const isDisabledOrLoading = isDisabled || isLoading
    const buttonStyles = `
  ${isGrouped ? "first:rounded-l last:rounded-r border-l-0 first:border-l" : "rounded"}
  flex items-center justify-center
  ${isTextWrapped ? "whitespace-pre-wrap" : "whitespace-nowrap"}
  ${isFullWidth || groupFullWidth ? "w-full" : ""}
  ${buttonStyle(variant, isDisabledOrLoading)}
  ${isTransparent ? "!bg-transparent" : ""}
  ${buttonSize(size)}
  ${isFullHeight ? "h-full" : ""}
`

    const innerRef = useRef<HTMLButtonElement | null>(null)
    useImperativeHandle(ref, () => innerRef.current, [])
    useLayoutEffect(() => {
      if (innerRef.current && isTransparent) {
        // override button background color to transparent with ref because tailwind doesn't support !important or CSS class overrides
        innerRef.current.style.setProperty("background-color", "transparent", "important")
      }
    }, [innerRef, isTransparent])

    const button = (
      <button
        id={id}
        // eslint-disable-next-line react/button-has-type
        type={type}
        className={buttonStyles}
        style={style}
        onClick={isDisabledOrLoading ? undefined : onClick}
        disabled={isDisabledOrLoading}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onPointerEnter={onPointerEnter}
        onPointerLeave={onPointerLeave}
        ref={innerRef}
        tabIndex={tabIndex}
        data-heap-name={heapName}
        data-dd-privacy={shouldMaskDataInDataDog ? "mask" : "allow"}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...dataAttributeProps}
      >
        <div
          className={classNames(
            "flex items-center justify-center",
            label && leftIcon ? (size === "xs" ? "mr-1.5" : "mr-2") : ""
          )}
        >
          {renderIcon(leftIcon)}
        </div>
        {isLoading ? (
          <Spinner size="xs" />
        ) : (
          <Typography
            color={color}
            font={Font.Inter}
            text={label ?? ""}
            size={fontSize(size)}
            weight={Weight.Semibold}
            shouldWrap={isTextWrapped}
            shouldMaskDataInDataDog={shouldMaskDataInDataDog}
          />
        )}

        <div
          className={classNames("flex items-center justify-center", label && !!rightIcon && "ml-2")}
        >
          {renderIcon(rightIcon)}
        </div>
      </button>
    )

    return tooltipTitle ? (
      <Tooltip title={tooltipTitle} placement={tooltipPlacement}>
        {button}
      </Tooltip>
    ) : (
      button
    )
  }
)

const buttonStyle = (type: ButtonVariant, isDisabledOrLoading: boolean): string => {
  switch (type) {
    case "primary":
      return `shadow-md ${
        isDisabledOrLoading
          ? "bg-neutral-600 cursor-not-allowed"
          : "bg-primary-500 hover:bg-primary-600"
      }`
    case "connect":
      return `shadow-md border ${
        isDisabledOrLoading
          ? "bg-neutral-600 cursor-not-allowed border-neutral-400"
          : "bg-neutral-white hover:bg-neutral-100 border-accent-500 hover:border-accent-600"
      }`
    case "secondary":
      return `bg-neutral-white border hover:bg-neutral-100 shadow-sm ${
        isDisabledOrLoading ? "border-neutral-400 cursor-not-allowed" : "border-neutral-600"
      }`
    case "tertiary":
      return `shadow-md ${
        isDisabledOrLoading
          ? "bg-neutral-600 cursor-not-allowed"
          : "bg-accent-500 hover:bg-accent-600"
      }`
    case "hollow":
      return `${isDisabledOrLoading ? "opacity-50 cursor-not-allowed" : ""}`
    case "hollow-link":
      return `${isDisabledOrLoading ? "opacity-50 cursor-not-allowed" : ""}`
    case "hollow-warning":
      return `${isDisabledOrLoading ? "opacity-50 cursor-not-allowed" : ""}`
    case "full-link":
      return `shadow-md ${
        isDisabledOrLoading
          ? "bg-neutral-600 cursor-not-allowed"
          : "bg-accent-500 hover:bg-accent-600"
      }`
    case "hollow-dark":
      return `${isDisabledOrLoading ? "opacity-50 cursor-not-allowed" : ""}`
    case "neutral":
      return `border ${
        isDisabledOrLoading
          ? "bg-neutral-600 cursor-not-allowed"
          : "bg-neutral-200 hover:bg-neutral-300"
      }`
    case "icon":
      return `rounded-xl ${
        isDisabledOrLoading
          ? "bg-neutral-600 cursor-not-allowed"
          : "bg-transparent hover:bg-neutral-300"
      }`
    default:
      return "bg-primary-500"
  }
}

const textColor = (
  type: ButtonVariant,
  isDisabled: boolean,
  isHovered: boolean
): Color | undefined => {
  switch (type) {
    case "primary":
      return Color.White
    case "connect":
      return Color.Link
    case "secondary":
      return isDisabled ? Color.Disabled : Color.Primary
    case "tertiary":
      return Color.White
    case "hollow":
      return Color.Primary
    case "hollow-dark":
      return Color.White
    case "hollow-link":
      return isHovered ? Color.SecondaryLink : Color.Link
    case "hollow-warning":
      return isHovered ? Color.WarningSecondary : Color.Warning
    case "full-link":
      return Color.White
    case "neutral":
      return Color.Subtitle
    case "icon":
      return Color.Primary
    default:
      return Color.Primary
  }
}

export const buttonSize = (size: ButtonSize): string => {
  switch (size) {
    case "xlarge":
      return "h-12 px-4 py-3"
    case "large":
      return "h-9 px-3 py-2"
    case "medium":
      return "h-7 px-2 py-1.5"
    case "small":
      return "h-5 px-2 py-1.5"
    case "xs":
      return "h-3 px-1 py-0.5"
    case "none":
      return ""
    default:
      return "bg-primary-500"
  }
}

const fontSize = (size: ButtonSize): Size => {
  switch (size) {
    case "xlarge":
      return Size.Medium
    case "large":
      return Size.Small
    case "medium":
      return Size.Small
    case "small":
      return Size.XSmall
    case "xs":
      return Size.XXSmall
    default:
      return Size.Small
  }
}
