import { AccessControl } from "../AccessControl"

export const limitedAccessControl: AccessControl<"limited"> = {
  tier: "limited",
  features: {
    // live market
    liveMarketParticipation: "locked",
    orderHistoryData: "full",
    currentMarketPriceData: "locked",
    contributeTradeData: "full",
    companyNewsData: "locked",
    fundingRoundsPage: "locked",
    fundingRoundsData: "locked",
    marketInsightsPage: "locked",
    rfqData: "locked",

    // company page
    companyPageFundingRoundsTable: "locked",
    companyPage409aValuationsTable: "locked",
    companyPageFilingsTable: "locked",
    companyPageInvestorsTable: "locked",
    companyPageMutualFundMarksTable: "locked",
    companyPageBidOfferVolumeRatioChart: "locked",
    companyPageTradingContextSection: "locked",
    companyPageResearchReportsSection: "locked",
    companyPageComparablesSection: "locked",
    companyPageShare: "locked",
    companyPageFlagIssue: "locked",
    companyPageExport: "locked",

    // trade history
    tradeHistoryPage: "full",
    tradeHistoryPricing: "locked",
    tradeHistoryDates: "locked",
    tradeHistoryStructure: "locked",
    tradeHistoryShareClass: "locked",
  
    // sectors page
    sectorsData: "locked",
  },
}
