/* eslint-disable @typescript-eslint/naming-convention */
import { isNonnullable, isRealNumber } from "../utils/json/validate"
import { Func } from "../utils/fp/Function"
import { Just, Maybe, Nothing } from "./Maybe/Class"

export { Just, Maybe, Nothing } from "./Maybe/Class"

export const nullableToMaybe = <X>(x: X): Maybe<Exclude<X, undefined | null>> =>
  isNonnullable(x) ? Just(x) : Nothing
export const possiblyUndefinedToMaybe = <X>(x: X): Maybe<Exclude<X, undefined>> =>
  x !== undefined ? Just(x as Exclude<X, undefined>) : Nothing

// TODO replace at call sites with appropriate checks
/** @deprecated */
export const nullableNumToMaybe = (x: number | string | undefined | null): Maybe<number> =>
  justIfIs(isRealNumber, x)

export const justIf = <X>(condition: Func<X, boolean>, x: X) => (condition(x) ? Just(x) : Nothing)
export const justIfIs = <X, Y extends X>(condition: (a: X) => a is Y, x: X): Maybe<Y> =>
  condition(x) ? Just(x) : Nothing

/** Removes `Nothing`s from an array of `Maybe<T>`s, and unwraps the `Just`s. */
export const filterMaybe = <T>(x: Maybe<T>[]): T[] =>
  x.flatMap((v) =>
    v.match(
      (a) => [a],
      () => []
    )
  )
