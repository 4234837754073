import _ from "lodash"
import { v4 as uuid } from "uuid"
import { Just, Maybe, nullableToMaybe } from "../../containers/Maybe"
import { ConstructedOrder, Order } from "./Order"
import { appendCreatedAtTerms } from "./PriceAttribution/OrderAttributionLogic"
import { restrictedCollections } from "../../firestore/Collections"
import { firestoreConverter } from "../FirestoreConverter"
import { nestedUndefinedsToEmptyObjects } from "../../utils/ObjectUtils"
import { now } from "../../utils/dateUtils"
import { Excluding } from "../../utils/TypeUtils"
import { User, UserIdFields } from "../User"
import { potentialOrderMatches, mergeOrderWithFirstValidCandidate } from "./OrderMerging"
import {
  FirebaseCommon,
  HasTransactions,
  ITransaction,
} from "../../firestore/Interface"
import { orderChangelogWriteJob } from "./orderChangelogWriteJob"

const updateOrder = async (o: Order, db: FirebaseCommon.DB) => {
  const ref = db
    .collection(restrictedCollections.orderObservations)
    .withConverter<Order>(firestoreConverter<Order>())
    .doc(o.id)
  return ref.set(nestedUndefinedsToEmptyObjects(o)).then(() => ref.get())
}

// todo unstub
const addOrder = async (o: ConstructedOrder, creator: UserIdFields, db: FirebaseCommon.DB) => {
  const ref = db
    .collection(restrictedCollections.orderObservations)
    .withConverter<Order>(firestoreConverter<Order>())
    .doc(uuid())
  return ref
    .set(nestedUndefinedsToEmptyObjects(o))
    .then(() => ref.get())
    .then((doc) => nullableToMaybe(doc))
    .then((x) => [x, null] as const /** todo metadata */)
}

export const mergeAndStructureOrder =
  (db: FirebaseCommon.DB) =>
  async (order: ConstructedOrder | Order): Promise<Maybe<Order>> => {
    const potentialMatches = (await potentialOrderMatches(db, order).get()).docs.map((doc) =>
      doc.data()
    ) // by contributor, company, origination date +/-30d
    const maybeMergedWithExistingOrder = mergeOrderWithFirstValidCandidate(potentialMatches, order)
    /*
    const maybeMergedOrderWithAssignedDatePrices = await Promise_.Maybe.sequence(
      maybeMergedWithExistingOrder.map(checkAssignDatesMergedOrder(order, user, dataSource()))
    )
    */
    return maybeMergedWithExistingOrder
  }

interface UpdatePayload {
  order: Order
  reason: string
}
interface CreatePayload {
  order: ConstructedOrder
  creator: Excluding<UserIdFields, User>
}

const handleOrderUpdate =
  <T extends ITransaction<T, Doc>, Doc extends FirebaseCommon.Doc<object>>(
    db: FirebaseCommon.DB & HasTransactions<T, Doc>,
    user: UserIdFields
  ) =>
  async (
    { order: updatedOrder, reason }: UpdatePayload,
    maybeMergedOrder: Maybe<Order>
  ): Promise<FirebaseCommon.Doc<Order>> => {
    const collection = db
      .collection(restrictedCollections.orderObservations)
      .withConverter<Order>(firestoreConverter<Order>())
    const currentDocRef = collection.doc(updatedOrder.id)
    const currentDbOrder = await currentDocRef.get().then((x) => x.data())
    const writeChangelog = orderChangelogWriteJob(db, user, currentDbOrder, reason)
    const orderDataToWrite = maybeMergedOrder.match(
      (orderToMergeWith) =>
        orderToMergeWith.id === updatedOrder.id ||
        currentDbOrder?.company.id === updatedOrder.company.id
          ? updatedOrder // company was not updated, no need to merge
          : orderToMergeWith, // company was updated so we need to merge

      // If the order that we want to merge with has the same id,
      // then we don't need to merge and can just overwrite. Otherwise we need to use the merged order
      () => updatedOrder // if no mergeable orders found, use this order as-is
    )
    const targetRef = collection.doc(orderDataToWrite.id)
    const shouldDeleteOldRef = targetRef.id !== currentDocRef.id

    return db
      .runTransaction((t) => {
        const updated = writeChangelog(orderDataToWrite)<
          typeof t & ITransaction<typeof t, object>,
          typeof t
        >(t as T & ITransaction<typeof t, object>).update(targetRef as Doc, {
          ...orderDataToWrite,
          lastUpdatedAt: now(),
        })
        // if we need to merge with some other order, we then have to delete the original order

        return Promise.resolve(shouldDeleteOldRef ? updated.delete(currentDocRef as Doc) : updated)
      })
      .then(() => targetRef)
  }

export const saveNewOrderInFirestore =
  (db: FirebaseCommon.DB) =>
  async ({ order, creator }: CreatePayload): Promise<Maybe<FirebaseCommon.DocSnapshot<Order>>> => {
    const maybeMergedOrderWithAssignedDatePrices = await mergeAndStructureOrder(db)(order)

    // ORDER CREATION
    // if there is no order to merge with, we append terms at createdAt and run date-price assignment
    const newO: ConstructedOrder = maybeMergedOrderWithAssignedDatePrices.match(
      () => order,
      () => appendCreatedAtTerms(order)
    )

    return maybeMergedOrderWithAssignedDatePrices.match(
      (merged) => updateOrder(merged, db).then(Just),
      () => addOrder(newO, creator, db).then(([o, __]) => o)
    )
  }

export const saveUpdatedOrderInFirestore =
  <T extends ITransaction<T, Doc>, Doc extends FirebaseCommon.Doc<object>>(
    db: FirebaseCommon.DB & HasTransactions<T, Doc>,
    user: UserIdFields
  ) =>
  async (payload: UpdatePayload): Promise<Maybe<FirebaseCommon.DocSnapshot<Order>>> => {
    const maybeMergedOrderWithAssignedDatePrices = await mergeAndStructureOrder(db)(payload.order)

    // ORDER UPDATE
    return handleOrderUpdate<T, Doc>(db, user)(payload, maybeMergedOrderWithAssignedDatePrices)
      .then((x) => x.get())
      .then(Just)
  }
