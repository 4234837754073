/* eslint-disable @typescript-eslint/naming-convention */
import { isNonnullable } from "../utils/json/validate"
import { Rec, UnsafeRec } from "../utils/RecordUtils"
import { BaseADT } from "./ADT"
import { UnionUnder } from "../utils/data/Record/Types/Pointwise"
import { Func, identity, pipe, tuple } from "../utils/fp/Function"
import { Just, Maybe, Nothing } from "./Maybe"

type EitherSignature<X, Y> = [["left", X], ["right", Y]]

/**
 * `Either<L, R>` is the sum of L and R.
 * Unlike the union `X | X`, which typescript collapses to `X`, the type `Either<X,X>` is isomorphic to (boolean, X): we can match on the tag to determine whether a given value is a `Left` or a `Right` without having to inspect the wrapped value itself.
 *
 * Functions of type `X => Either<Y,Z>` are often used to represent computations `X => Z` which can short circuit to produce a Y.
 */
export class Either<X, Y> extends BaseADT<EitherSignature<X, Y>> {
  /** Deconstruct an Either
   *
   * Using `R1 | R2` as a return type improves type inference, but in most usecases `ifLeft` and `ifRight` should return the same type.
   */
  match<R1, R2 = R1>(ifLeft: Func<X, R1>, ifRight: Func<Y, R2>): R1 | R2 {
    return this.value.tag === "left" ? ifLeft(this.value.value) : ifRight(this.value.value)
  }

  static Match =
    <L1, L2, R1, R2 = R1>(ifLeft: Func<L1, R1>, ifRight: Func<L2, R2>) =>
    (x: Either<L1, L2>): R1 | R2 =>
      x.match(ifLeft, ifRight)

  /** Map over the second type argument of an `Either`: `Left`s are preserved, while `Right`s are transformed with the provided function */
  map<Y2>(f: Func<Y, Y2>): Either<X, Y2> {
    return this.match(Left, pipe(f)(Right))
  }

  /** Map over the first type argument of an `Either`: `Right`s are preserved, while `Left`s are transformed with the provided function */
  mapFst<X2>(f: Func<X, X2>): Either<X2, Y> {
    return this.match(pipe(f)(Left), Right)
  }

  /**
   * Map over both type arguments of an `Either`: applies the left function on `Left`s, and the right function on `Right`s.
   */
  bimap<X2, Y2>(l: Func<X, X2>, r: Func<Y, Y2>): Either<X2, Y2> {
    return this.match(pipe(l)(Left), pipe(r)(Right))
  }

  /**
   * Unwraps an Either<X,Y>, then either rewraps a `Left`, or supplies the value of a `Right` to the provided function.
   */
  bind<X2, Y2>(f: Func<Y, Either<X2, Y2>>): Either<X | X2, Y2> {
    return this.match(Left, f)
  }

  /**
   * Zips an Either<X,Y> with an Either<X,Y2> into an Either<X,[Y,Y2]>, favoring the first `Left`.
   */
  alongside<X2, Y2>(x: Either<X2, Y2>): Either<X | X2, [Y, Y2]> {
    return this.bind((l) => x.map(tuple(l)))
  }

  isRight(): this is BaseADT<[["right", Y]]> {
    return this.value.tag === "right"
  }

  isLeft(): this is BaseADT<[["left", X]]> {
    return this.value.tag === "left"
  }

  toUnion(): X | Y {
    return this.match(identity, identity)
  }

  toMaybe(): Maybe<Y> {
    return this.match(
      () => Nothing,
      (y) => Just(y)
    )
  }

  withDefault<Z = Y>(z: Z): Y | Z {
    return this.match(
      () => z,
      (r) => r
    )
  }

  or<X2 = X, Y2 = Y>(alternative: Either<X2, Y2>): Either<X | X2, Y | Y2> {
    return this.isLeft() ? alternative : this
  }
}

export namespace Either {
  export type LeftArg<T> = T extends Either<infer L, infer R> ? L : never
  export type RightArg<T> = T extends Either<infer L, infer R> ? R : never

  /**
   * Collapse an Either<X,Either<X,Y>> to an Either<X,Y>, favoring the first Left.
   */
  export const join = <X, X2, Y>(e: Either<X, Either<X2, Y>>): Either<X | X2, Y> => e.bind(identity)

  export namespace Record {
    export type Sequence<R extends Record<never, Either<unknown, unknown>>> = Either<
      Either.LeftArg<R[keyof R]>,
      {
        [key in keyof R]: Either.RightArg<R[key]>
      }
    >
    /** As `Either.Array.sequence`, but traverses a record instead of an array. */
    export const sequence = <X, R extends Record<never, unknown>>(r: {
      [k in keyof R]: Either<X, R[k]>
    }): Either<X, R> =>
      Either.Array.sequence(UnsafeRec.entries(r).map(([k, v]) => v.map((x) => tuple(k)(x)))).map(
        (x) => UnsafeRec.fromEntries(x)
      )
  }
  export namespace Array {
    export type Sequence<R extends Either<unknown, unknown>[]> = Either<
      Either.LeftArg<R>,
      Either.RightArg<R>[]
    >
    /** Traverses an array of `Either<X,Y>`s, accumulating the `Right` values. If a `Left` is encountered, returns it. Otherwise, returns the accumulated array. */
    export const sequence = <X, Y>(a: Either<X, Y>[]): Either<X, Y[]> =>
      a.reduce(
        (prev: Either<X, Y[]>, cur) => prev.alongside(cur).map(([t, h]) => [h].concat(t)),
        Right([])
      )
  }
}

/**
 * Construct an `Either<X,_>` from an `X`
 */
export const Left = <X>(x: X): Either<X, never> =>
  new Either<X, never>({
    tag: "left",
    value: x,
  })

/**
 * Construct an `Either<_,Y>` from a `Y`
 */
export const Right = <Y>(x: Y): Either<never, Y> =>
  new Either<never, Y>({
    tag: "right",
    value: x,
  })
export const nullableToEither = <X, Y>(
  fallback: Y,
  possiblyNull: X
): Either<Y, Exclude<X, undefined | null>> =>
  isNonnullable(possiblyNull) ? Right(possiblyNull) : Left(fallback)

export const filterEitherRecord = <X, R>(r: {
  [k in keyof R]: Either<X, R[k]>
}): UnionUnder<null, R> =>
  Rec.map(r, (v) =>
    v.match(
      () => null,
      (x) => x
    )
  ) as UnionUnder<null, R>

export const filterEither = <L, R>(xs: Either<L, R>[]): [L[], R[]] => {
  const lefts: L[] = []
  const rights: R[] = []
  xs.forEach((x) => {
    x.match(
      (l) => lefts.push(l),
      (r) => rights.push(r)
    )
  })
  return [lefts, rights]
}
