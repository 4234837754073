import { wrapIcon } from "./IconWrapper"

export const PrivateVisibilityIcon = wrapIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" fill="none">
    <path
      fill="currentColor"
      d="M6.294 3.054A4.62 4.62 0 0 1 7 3c2.12 0 4.114 1.527 5.274 4-.178.377-.378.742-.6 1.094a.667.667 0 1 0 1.133.7c.31-.488.58-1.001.807-1.534a.668.668 0 0 0 0-.526C12.267 3.607 9.734 1.667 7 1.667a5.18 5.18 0 0 0-.933.08.676.676 0 1 0 .227 1.333v-.026ZM1.474.527a.67.67 0 1 0-.947.947l2.067 2.06a9.747 9.747 0 0 0-2.207 3.2.667.667 0 0 0 0 .533c1.347 3.127 3.88 5.067 6.613 5.067a6.173 6.173 0 0 0 3.367-1.027l2.16 2.167a.665.665 0 0 0 .947 0 .665.665 0 0 0 0-.947l-12-12Zm4.24 6.127 1.633 1.633a1.333 1.333 0 0 1-1.633-1.633ZM7 11c-2.12 0-4.113-1.526-5.266-4a8.06 8.06 0 0 1 1.8-2.526l1.18 1.193a2.667 2.667 0 0 0 3.62 3.62l1.06 1.047A4.826 4.826 0 0 1 7 11Z"
    />
  </svg>
)
