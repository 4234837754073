import { PageTitle } from "@components/typography/PageTitle"
import { useCRMContacts } from "../Providers/CRMContactsProvider"
import { Button } from "@stories/components/Button/Button"
import { ChevronLeftIcon } from "@stories/icons/ChevronLeftIcon"
import { Routes } from "src/Routes/Routes"
import { useNavigate } from "react-router-dom"
import { useCurrentAccount } from "src/queries/currentUser/useCurrentAccount"
import { isLoaded } from "common/utils/Loading"
import { FirmContactCSVUploader } from "../Components/Uploader/Uploaders/FirmContactCSVUploader"
import { useState } from "react"
import { CreateContactTagCard } from "../Contacts/CreateContactButton/CreateContactTagCard"
import { shortDateFormat } from "common/utils/dateUtils"
import { JointContactCSVUploader } from "../Components/Uploader/Uploaders/JointContactCSVUploader"
import { buildAccountCRMSettings } from "common/model/AccountCRMSettings"
import { FirmContactHoldingsUploader } from "../Components/Uploader/Uploaders/FirmContactHoldingsUploader"
import { useFeatureFlag } from "src/providers/featureFlags/useFeatureFlags"

export const ImportContactsPage = () => {
  const account = useCurrentAccount()
  const navigate = useNavigate()
  const [uploadType, setUploadType] = useState<
    "firm" | "individual" | "joint" | "contactsWithHoldings"
  >()
  const { latestFirmImport, latestIndividualImport } = useCRMContacts()
  const isNewCRMUploaderEnabled = useFeatureFlag("crm_new_uploader")

  if (!isLoaded(account)) return null
  return (
    <div className="px-4 pt-20 md:pt-4 pb-4 m-auto animate-fade">
      <div className="flex flex-col gap-4 items-start">
        <Button
          leftIcon={<ChevronLeftIcon />}
          label="Back to CRM Contacts"
          variant="hollow"
          onClick={() => navigate(Routes.crm.allContacts)}
        />
        <PageTitle
          title={`Import ${
            uploadType === "firm" ? "Fund/Org" : uploadType === "individual" ? "Individual" : ""
          } Contacts`}
        />
      </div>
      <div className="mt-4 flex flex-col gap-4  ">
        {!uploadType ? (
          <div className="flex flex-col gap-4 w-full max-w-4xl m-auto">
            {buildAccountCRMSettings(account)?.thirdPartyCRM ? (
              <>
                <CreateContactTagCard
                  label="Upload Fund/Org Contacts"
                  subLabel={
                    latestFirmImport
                      ? `Latest import at ${shortDateFormat(latestFirmImport.createdAt)}${
                          latestFirmImport.status === "processing"
                            ? " : Running"
                            : latestFirmImport.status === "failed"
                            ? " : Failed"
                            : ""
                        }`
                      : undefined
                  }
                  onClick={() => setUploadType("firm")}
                />
                <CreateContactTagCard
                  label="Upload Individual Contacts"
                  subLabel={
                    latestIndividualImport
                      ? `Latest import at ${shortDateFormat(latestIndividualImport.createdAt)}${
                          latestIndividualImport.status === "processing"
                            ? " : Running"
                            : latestIndividualImport.status === "failed"
                            ? " : Failed"
                            : ""
                        }`
                      : undefined
                  }
                  onClick={() => setUploadType("individual")}
                />
              </>
            ) : (
              <CreateContactTagCard
                label="Upload Contacts"
                onClick={() => setUploadType("joint")}
              />
            )}
            {isNewCRMUploaderEnabled ? (
              <CreateContactTagCard
                label="Upload Contacts With Holdings"
                onClick={() => setUploadType("contactsWithHoldings")}
              />
            ) : null}
          </div>
        ) : null}
        {uploadType === "firm" ? <FirmContactCSVUploader /> : null}
        {/* {uploadType === "individual" ? <IndividualContactCSVUploader /> : null} */}
        {uploadType === "joint" ? <JointContactCSVUploader /> : null}
        {uploadType === "contactsWithHoldings" ? <FirmContactHoldingsUploader /> : null}
      </div>
    </div>
  )
}
