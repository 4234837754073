import { config } from "../config"
import { QueryDocumentSnapshot } from "../firebase/Firebase"

type AirtableTable = "accounts" | "orders" | "contacts"

// Generate an environment-agnostic URL for an airtable record
export const airtableLink = (table: AirtableTable, recordId: string) => {
  const isProd = config.env === "prod"
  const baseId = isProd ? "apprnEXt7EAGDXvnu" : "appDAwGlt3DvZx4BW"

  let tableId: string | null = null
  switch (table) {
    case "accounts":
      tableId = isProd
        ? "tblDNEGaz37Eq8FjM/viwiAvouhog9Mk3tI"
        : "tblP0wp2VsatMIexe/viwuNn7mDNjY8UCHa"
      break
    case "orders":
      tableId = isProd
        ? "tbll0m20r8btY3IDn/viwk6c31s8lzb9FCl"
        : "tblxdeLSNxeikDhRP/viwwj4MTOxooxJeQN"
      break
    case "contacts":
      tableId = isProd
        ? "tblAtMkkWTlIzj4fg/viwI6fW1uFWhUsvrS"
        : "tblMGE3ciioxVTDtI/viwUj7FTQ4Z6g24Fk"
      break
  }

  return `https://airtable.com/${baseId}/${tableId}/${recordId}?blocks=hide`
}

export const retrievePaginatedDocs = async <T, F, DB>(
  lastDoc: QueryDocumentSnapshot<T> | undefined,
  filter: F,
  setDocs: React.Dispatch<React.SetStateAction<T[] | "loading">>,
  setLastDoc: React.Dispatch<React.SetStateAction<QueryDocumentSnapshot<T> | undefined>>,
  setDocsLoading: React.Dispatch<React.SetStateAction<boolean | "complete">>,
  fb: DB,
  getDocs: (
    fb: DB,
    pageSize: number,
    startAfter?: QueryDocumentSnapshot<T>,
    filter?: F
  ) => Promise<QueryDocumentSnapshot<T>[]>,
  pageSize: number = 50
): Promise<void> => {
  if (lastDoc) setDocsLoading(true)

  const docs = await getDocs(fb, pageSize, lastDoc, filter)

  const docData = docs.map((doc) => doc.data())

  if (docs.length) {
    setLastDoc(docs[docs.length - 1])
    setDocs((prevDocs) => {
      if (!prevDocs || prevDocs === "loading") return docData

      return prevDocs.concat(docData)
    })
    setDocsLoading(false)
  } else {
    setDocsLoading("complete")
  }
}

export const firestoreConsoleLink = (...docPath: string[]) => {
  const rootURL = config.firebase.firestoreConsoleRootURL
  const pathSeparator = config.env === "dev" ? "/" : "~2F"
  return `${rootURL}/${docPath.join(pathSeparator)}`
}
