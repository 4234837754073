import { CurrentUser } from "@model/CurrentUser"
import { notification } from "@stories/components/Antd"
import { collections } from "common/firestore/Collections"
import { AccountIdFields, viewAccountIdFields } from "common/model/Account"
import {
  CompanyIdFieldsWithPostgres,
  SelectedCompany,
  viewCompanyIdFields,
} from "common/model/Company"
import { viewUserIdFields } from "common/model/User"
import { WatchlistItem } from "common/model/Watchlist"
import { Timestamp } from "firebase/firestore"
import { sendPlatformEventMessage } from "src/firebase/API"
import { FirebaseWriter } from "src/firebase/Firebase"
import { trackAccountActivationFirebaseWriter } from "src/utils/AccountActivation"
import { handleConsoleError, trackEvent } from "src/utils/Tracking"

export const removeCompanyFromWatchlist = async (
  firebase: FirebaseWriter,
  watchlistItem: WatchlistItem,
  options?: {
    hideNotification?: boolean
  }
): Promise<void> => {
  const { company, id } = watchlistItem
  const { name, id: companyId } = company
  await firebase
    .removeWatchlistItem(id)
    .then(() => {
      if (!options?.hideNotification) {
        notification.info({
          className: "max-w-100",
          message: `${name} removed from your Watchlist.`,
          placement: "top",
        })
      }
    })
    .then(() =>
      trackEvent("watchlist-item-removed", {
        name,
        companyId,
      })
    )
    .catch(handleConsoleError)
}

const addWatchlistItem = (
  firebase: FirebaseWriter,
  account: AccountIdFields,
  user: CurrentUser,
  company: CompanyIdFieldsWithPostgres,
  notificationOverrides?: WatchlistItem["notificationOverrides"]
) => {
  const newWatchlistItem: Omit<WatchlistItem, "id" | "addedAt"> & { addedAt: Timestamp } = {
    company: { ...viewCompanyIdFields(company), postgresCompanyId: company.postgresCompanyId },
    account: viewAccountIdFields(account),
    itemType: "company",
    addedBy: viewUserIdFields(user.user),
    addedAt: Timestamp.fromDate(new Date()),
    notificationOverrides: notificationOverrides ?? [],
  }

  return firebase.db
    .collection(collections.watchlists)
    .add(newWatchlistItem)
    .then(() =>
      trackEvent("watchlist-item-added", { companyName: company.name, companyId: company.id })
    )
    .then(() =>
      sendPlatformEventMessage(
        `${user.user.email} (${account.name}) added ${company.name} to their Watchlist`,
        user
      )
    )
    .then(() =>
      trackAccountActivationFirebaseWriter(firebase, user.user.account, "addedWatchlistCompanies")
    )
    .catch(handleConsoleError)
}

export const addCompanyToWatchlist = (
  firebase: FirebaseWriter,
  company: SelectedCompany | CompanyIdFieldsWithPostgres,
  user: CurrentUser,
  account: AccountIdFields,
  watchlist: WatchlistItem[],
  options?: { hideItemAddedNotification?: boolean }
) => {
  if (!company) return

  // check if company is already in watchlist and if so, don't add it again
  const watchlistItem = watchlist.find(
    (item) => item.company.postgresCompanyId === company.postgresCompanyId
  )
  if (watchlistItem) {
    notification.info({
      message: `${company.name} is already in your watchlist`,
      placement: "top",
    })
  }

  addWatchlistItem(firebase, account, user, company)
    .then(() => {
      if (!options?.hideItemAddedNotification)
        notification.success({
          message: `${company.name} added to your watchlist`,
          placement: "top",
        })
    })
    .then(() =>
      trackEvent("watchlist-company-added", { companyName: company.name, companyId: company.id })
    )
    .catch(handleConsoleError)
}
