import { useState } from "react"
import { SearchInput } from "@components/inputs/SearchInput"
import { FilterFnRecord, Variant } from "common/model/newsfeed/NewsFeed"
import { useUnsafeEffect } from "../../../../utils/hooks/effects/useEffectSafe"

const formattedSearch = (where: string, what: string) =>
  where
    .toLowerCase()
    .replace(/[^a-z0-9]/gi, "")
    .indexOf(what.toLowerCase().replace(/[^a-z0-9]/gi, "")) !== -1

export const investorFilter = (substring: string): FilterFnRecord => ({
  news: {
    function: (t) => true,
    asStrings: { field: "investor", value: substring },
  },
  funding_rounds: {
    function: (f) => formattedSearch(f.investors ?? "", substring),
    asStrings: { field: "investor", value: substring },
  },
})

export function InvestorFilter<V extends Variant>({
  onEnable,
  variant,
}: {
  onEnable: (fn: FilterFnRecord[V] | undefined) => void
  variant: V
}) {
  const [value, setValue] = useState("")
  useUnsafeEffect(() => {
    if (value) {
      onEnable(investorFilter(value)[variant])
    } else {
      onEnable(undefined)
    }
  }, [value])

  return (
    <div className="flex flex-row items-center max-w-60 h-8">
      <SearchInput
        key="substringFilterNews"
        id={`${variant}-filter-investor`}
        placeholder="Search by investor"
        value={value}
        onChange={setValue}
      />
    </div>
  )
}
