import SkeletonLoader from "@components/icons/SkeletonLoader"
import { CurrentUser } from "@model/CurrentUser"
import Typography, { Color, Weight } from "@stories/components/Typography/Typography"
import { collections } from "common/firestore/Collections"
import { DealCRMDealParticipant } from "common/model/DealCRM/Deal/DealCRMDealParticipant"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { DealCRMInterest } from "common/model/DealCRM/DealCRMInterest"
import { DealCRMNote, DealCRMNoteContactSource } from "common/model/DealCRM/DealCRMNote"
import { firestoreConverter } from "common/model/FirestoreConverter"
import { Loading, isLoaded, isLoading } from "common/utils/Loading"
import { uniqueByRep } from "common/utils/data/Array/ArrayUtils"
import { identity } from "common/utils/fp/Function"
import { useEffect, useState } from "react"
import { useFirebaseReader } from "src/firebase/Context"
import { getContactDealParticipantDocs } from "src/firebase/crm"
import {
  getContactSourceNotes,
  getContactTaggedNotes,
  getContactBuySellInterestNotes,
} from "src/firebase/crmNotes"
import { AddNoteInput } from "src/pages/CRM/Components/Notes/AddNoteInput"
import { NoteDisplay } from "src/pages/CRM/Components/Notes/NoteDisplay"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import { useMultiQuerySnapshot } from "src/utils/hooks/queries/useMultiQuerySnapshot"
import { useQuerySnapshot } from "src/utils/hooks/queries/useQuerySnapshot"
import { chunk } from "lodash"
import { FIRESTORE_IN_LIMIT } from "common/utils/constants/firestore"
import { contactToDealCRMNoteContactSource } from "common/model/DealCRM/DealCRMNoteSourceConverters"

export const ContactNotes = ({
  contact,
  contactBuySellInterest,
}: {
  contact: DealCRMContact
  contactBuySellInterest: DealCRMInterest[]
}) => {
  const user = useCurrentUser()

  if (!isLoaded(user)) return <SkeletonLoader numRows={3} />

  return (
    <ContactNotesInner
      contact={contact}
      user={user}
      contactBuySellInterest={contactBuySellInterest}
    />
  )
}

const useContactDealParticipantNotes = (contact: DealCRMContact, user: CurrentUser) => {
  const [dealParticipantNotes, setDealParticipantNotes] = useState<
    Loading<DealCRMNote[]> | undefined
  >(undefined)
  const db = useFirebaseReader()

  const dealParticipantIdsSnapshot = useQuerySnapshot<DealCRMDealParticipant, string[]>(
    (firebase) =>
      getContactDealParticipantDocs({
        db: firebase,
        accountId: user.user.account.id,
        contact,
      }),
    (docs) => docs.map((doc) => doc.id),
    [contact, user]
  )

  useEffect(() => {
    if (isLoaded(dealParticipantIdsSnapshot) && dealParticipantNotes === undefined) {
      const nonEmptyParticipantIds =
        dealParticipantIdsSnapshot.length > 0 ? dealParticipantIdsSnapshot : [""]
      // eslint-disable-next-line rulesdir/no-firestore-in-clause
      db.db
        .collection(collections.dealCRM.notes)
        .where("accountId", "==", user.user.account.id)
        .where("source.sourceId", "in", nonEmptyParticipantIds)
        .withConverter<DealCRMNote>(firestoreConverter<DealCRMNote>())
        .onSnapshot((docs) => {
          const notes = docs.docs.map((doc) => doc.data())
          setDealParticipantNotes(notes)
        })
    }
  }, [dealParticipantIdsSnapshot, dealParticipantNotes, setDealParticipantNotes, user, db.db])

  return dealParticipantNotes
}

export const useContactNotes = (params: {
  contact: DealCRMContact
  user: CurrentUser
  contactBuySellInterest: DealCRMInterest[]
}) => {
  const { contact, user, contactBuySellInterest } = params

  const contactSourceNotes = useQuerySnapshot(
    (db) =>
      getContactSourceNotes({
        db,
        accountId: user.user.account.id,
        contact,
        contactBuySellInterest,
      }),
    identity,
    [contact, user]
  )

  const buySellInterestNotes = useMultiQuerySnapshot<DealCRMNote, DealCRMInterest>(
    chunk(contactBuySellInterest, FIRESTORE_IN_LIMIT).map(
      (interestChunk) => (db) =>
        getContactBuySellInterestNotes({
          db,
          accountId: user.user.account.id,
          contactBuySellInterestIds: interestChunk,
        })
    ),
    (interests) => uniqueByRep<DealCRMNote, string>((interest) => interest.id)(interests),
    identity,
    [contact, user, contactBuySellInterest]
  )

  const contactTaggedNotes = useQuerySnapshot(
    (db) => getContactTaggedNotes({ db, accountId: user.user.account.id, contact }),
    identity,
    [contact, user]
  )

  const dealParticipantNotes = useContactDealParticipantNotes(contact, user)

  if (
    isLoading(contactTaggedNotes) ||
    isLoading(contactSourceNotes) ||
    isLoading(buySellInterestNotes) ||
    isLoading(dealParticipantNotes)
  )
    return "loading"

  const allContactNotes = uniqueByRep<DealCRMNote, string>((item) => item.id)([
    ...(contactTaggedNotes || []),
    ...(contactSourceNotes || []),
    ...(buySellInterestNotes || []),
    ...(dealParticipantNotes || []),
  ]).sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())

  return {
    contactTaggedNotes,
    contactSourceNotes,
    dealParticipantNotes,
    allContactNotes,
  }
}

const ContactNotesInner = ({
  contact,
  user,
  contactBuySellInterest,
}: {
  contact: DealCRMContact
  contactBuySellInterest: DealCRMInterest[]
  user: CurrentUser
}) => {
  const loadingNotes = useContactNotes({ contact, user, contactBuySellInterest })

  if (isLoading(loadingNotes)) return <SkeletonLoader numRows={3} />

  const { allContactNotes } = loadingNotes

  const source: DealCRMNoteContactSource = contactToDealCRMNoteContactSource(contact)

  const notesContent = isLoaded(allContactNotes) ? (
    <>
      <div className="flex flex-col">
        {(allContactNotes || []).map((note) => (
          <NoteDisplay
            key={note.id}
            note={note}
            showSourcePill={note.source.sourceId !== contact.id}
            displayPage="contact"
          />
        ))}
      </div>
      <AddNoteInput source={source} user={user} />
    </>
  ) : (
    <SkeletonLoader numRows={3} />
  )

  return (
    <div className="mb-4 flex flex-col gap-2">
      <Typography color={Color.Primary} weight={Weight.Semibold} text="Notes" />
      {notesContent}
    </div>
  )
}
