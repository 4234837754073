/* eslint-disable rulesdir/no-assert */
import numeral from "numeral"
import { Schema as z } from "../../../../schema/Schema"
import { Interval, showInterval } from "../../../../utils/data/Interval"
import { quotient, times } from "../../../../utils/data/numeric/Arithmetic"
import { intervalMidpoint } from "../../../../utils/data/numeric/NumberInterval"
import { Maybe, nullableToMaybe } from "../../../../containers/Maybe"
import { lift2OrUndefined, mapOrUndefined } from "../../../../utils/UnionUtils"
import { assertExtends } from "../../../../utils/data/Type/Assertions"

type AdequateOrderQuantityTerms =
  | {
      amountUSD: Interval<number>
      shares: Interval<number>
    }
  | {
      amountUSD: Interval<number>
      USDPerShare: Interval<number>
    }
  | {
      amountUSD: Interval<number>
    }
  | {
      shares: Interval<number>
    }
  | {
      shares: Interval<number>
      USDPerShare: Interval<number>
    }
  | {
      shares: Interval<number>
      targetValuation: number
    }
  | {
      amountUSD: Interval<number>
      targetValuation: number
    }

export type OrderQuantityTermsConstructorData = AdequateOrderQuantityTerms & { id: string }

export const orderQuantityTermsSchema = z.object({
  shares: z
    .object({
      lowerBound: z.float(),
      upperBound: z.float(),
    })
    .optional(),
  amountUSD: z
    .object({
      lowerBound: z.float(),
      upperBound: z.float(),
    })
    .optional(),
  USDPerShare: z
    .object({
      lowerBound: z.float(),
      upperBound: z.float(),
    })
    .optional(),
  targetValuation: z.float().optional(),
  id: z.string(),
})

export type OrderQuantityTerms = {
  shares?: Interval<number>
  amountUSD?: Interval<number>
  USDPerShare?: Interval<number>
  targetValuation?: number
  id: string
}
assertExtends<z.infer<typeof orderQuantityTermsSchema>, OrderQuantityTerms>

export const makeOrderQuantityTerms = (
  data: OrderQuantityTermsConstructorData
): OrderQuantityTerms => data

export const makePossiblyIncompleteOrderQuantityTerms = (
  data: OrderQuantityTerms
): OrderQuantityTerms => data

// TODO: have these return Maybe
export const orderQuantityTermsPriceInterval = (
  x: OrderQuantityTerms
): Interval<number> | undefined =>
  x.USDPerShare ?? lift2OrUndefined(Interval.lift2(quotient))(x.amountUSD, x.shares)
export const orderQuantityTermsPrice = (x: OrderQuantityTerms): number | undefined =>
  mapOrUndefined(orderQuantityTermsPriceInterval(x), intervalMidpoint)

export const orderQuantityTermsTargetValuation = (x: OrderQuantityTerms): Maybe<number> =>
  nullableToMaybe(x.targetValuation)

export const orderQuantityTermsShares = (x: OrderQuantityTerms): Interval<number> | undefined =>
  x.shares ?? lift2OrUndefined(Interval.lift2(quotient))(x.amountUSD, x.USDPerShare)

export const orderQuantityTermsVolume = (x: OrderQuantityTerms): Interval<number> | undefined =>
  x.amountUSD ?? lift2OrUndefined(Interval.lift2(times))(x.USDPerShare, x.shares)

export const orderQuantityTermsSizeString = (x: OrderQuantityTerms): string | undefined =>
  x.amountUSD
    ? showInterval((n) => numeral(n).format("$0.[0]a"), true)(x.amountUSD)
    : x.shares
    ? showInterval((n: number) => `${n} shares`, true)(x.shares)
    : undefined
