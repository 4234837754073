import { createCache } from "common/utils/useCache"
import { FirebaseReader } from "../../firebase/Firebase"
import { getAllNonDraftRFQs, getAllRFQRequests } from "../../firebase/Firebase/RFQs/RFQs"
import { useDbState } from "../../utils/useDb"

const cachedGetNonDraftRFQs = createCache((db: FirebaseReader) => getAllNonDraftRFQs(db))
export const useNonDraftRFQs = () => useDbState(cachedGetNonDraftRFQs, [])

const cachedGetRFQRequests = createCache((db: FirebaseReader) => getAllRFQRequests(db))
export const useRFQRequests = () => useDbState(cachedGetRFQRequests, [])
