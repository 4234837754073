import { ArrowRightIcon } from "@heroicons/react/solid"
import { Button } from "@stories/components/Button/Button"
import { Size, Typography } from "@stories/components/Typography/Typography"
import {
  AccountAccessFields,
  AccountIdFields,
  isTrialExpired,
  trialExpirationDate,
} from "common/model/Account"
import { shortDateFormat } from "common/utils/dateUtils"
import moment, { Moment } from "moment"
import * as React from "react"
import { useFirebaseWriter } from "../../firebase/Context"
import { handleConsoleError } from "../../utils/Tracking"

interface AccountTrialStatusProps {
  account: AccountIdFields & AccountAccessFields
}

const AccountTrialStatus: React.FunctionComponent<
  React.PropsWithChildren<AccountTrialStatusProps>
> = ({ account }) => {
  const firebase = useFirebaseWriter()

  const trialExpiration: Moment | null = trialExpirationDate(account).match(
    (d) => moment(d),
    () => null
  )

  const extendTrial = () => {
    if (!trialExpiration) return
    const newTrialEndDate = trialExpiration.add(2, "weeks")
    firebase
      .account(account.id)
      .update({
        "onboardingStatus.data.trialExpiration": newTrialEndDate.toDate(),
        "onboardingStatus.data.status": newTrialEndDate.isAfter(moment())
          ? "trial"
          : "trial-expired",
      })
      .catch(handleConsoleError)
  }

  if (!trialExpiration) return null

  return (
    <div className="text-xs flex items-center space-x-2">
      <Typography
        text={`Trial expires: ${shortDateFormat(trialExpiration.toDate())}`}
        size={Size.XXSmall}
        shouldWrap={false}
      />
      {isTrialExpired(account) ? (
        <span className="bg-danger-600 text-white px-2 py-0.5">(expired)</span>
      ) : (
        <span className="bg-success-700 text-white px-2 py-0.5">(active)</span>
      )}
      <Button
        size="small"
        label="Extend trial by two weeks"
        onClick={extendTrial}
        leftIcon={<ArrowRightIcon />}
        variant="primary"
      />
    </div>
  )
}

export default AccountTrialStatus
