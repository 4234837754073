import { Card } from "@stories/components/Card/Card"
import { Color, Size, Typography, Weight } from "@stories/components/Typography/Typography"
import { TopBrokerCardProps, ValidationStatus } from "./TopBrokerCriteriaConfig"
import { assertUnreachable } from "common/utils/fp/Function"
import { Tooltip } from "@stories/components/Antd/Tooltip/Tooltip"
import InfoTooltip from "@components/InfoTooltip"

const TopBrokerCriteria = ({ validation }: { validation: ValidationStatus }) => {
  const color =
    validation === "none"
      ? "bg-neutral-500"
      : validation === "good"
      ? "bg-success-400"
      : validation === "not good"
      ? "bg-neutral-700"
      : validation === "ok"
      ? "bg-warning-400"
      : assertUnreachable(validation)
  const tooltip =
    validation === "none"
      ? "Error"
      : validation === "good"
      ? "Meeting expectations"
      : validation === "not good" || validation === "ok"
      ? "Not meeting expectations"
      : assertUnreachable(validation)
  return (
    <div>
      <Tooltip title={tooltip}>
        <div className={`w-3 h-3 rounded-full ${color}`} />
      </Tooltip>
    </div>
  )
}

export const TopBrokerCriteriaCard = ({
  validator,
  title,
  subtitle,
  tooltip,
  value,
  disclaimer,
}: TopBrokerCardProps) => (
  <Card>
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <TopBrokerCriteria validation={validator(value)} />
        <InfoTooltip text={tooltip} outlined />
      </div>
      <div className="flex flex-col gap-2">
        <Typography
          color={Color.Primary}
          weight={Weight.Semibold}
          size={Size.Large}
          text={`${title}`}
        />
        <Typography color={Color.Primary} size={Size.Small} text={`${subtitle(value)}`} />
      </div>
      <Typography color={Color.Subtitle} size={Size.XXSmall} text={disclaimer} />
    </div>
  </Card>
)
