import moment from "moment"
import { getAuth, onIdTokenChanged } from "firebase/auth"
import { CurrentUser } from "../model/CurrentUser"
import { trackAuthTokenRefresh, trackAuthTokenRetrieved } from "../utils/Tracking"
import { APIEndpoint } from "./API"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { Loading, deprecatedIsLoaded, isLoading } from "common/utils/Loading"
import { useState, useEffect, useMemo } from "react"

/**
 * Wrapper around firebase.User.getIdToken()
 *
 * Retrieve the provided user's JSON Web Token from Firebase auth, typically for use in a backend API request
 * This will opportunistically refresh the token from Firebase if the token is relatively old.
 * NOTE - this method should be used instead of the native Firebase `getIdToken` method which seems to have faulty refresh logic
 *
 * @param params.forceRefresh Force refresh regardless of token expiration.
 * @param params.requestEndpoint Optionally provide a request endpoint this token will be used to request (useful for logging/monitoring)
 */
export const getUserAuthToken = async (
  user: Pick<CurrentUser, "authUser">,
  params?: { forceRefresh?: boolean; requestEndpoint?: APIEndpoint }
): Promise<string> => {
  const endpointPath = params?.requestEndpoint?.path
  if (params?.forceRefresh) {
    return forceRefreshToken(user, endpointPath)
  } else {
    const tokenData = await user.authUser.getIdTokenResult()
    trackAuthTokenRetrieved(endpointPath)
    const expirationTime = moment(tokenData.expirationTime)
    if (expirationTime.isBefore(moment().add(20, "minutes"))) {
      return forceRefreshToken(user, endpointPath)
    } else {
      return tokenData.token
    }
  }
}

const useExpiringFirebaseToken = () => {
  const [token, setToken] = useState<Loading<string>>("loading")

  useEffect(() => onIdTokenChanged(getAuth(), () => setToken("loading")), [])

  return useMemo(() => [token, setToken] as const, [token, setToken])
}

export const useLoggedInUserAuthToken = () => {
  const currentUser = useLoggedInUser()
  const [token, setToken] = useExpiringFirebaseToken()
  useEffect(() => {
    if (isLoading(token)) {
      getUserAuthToken(currentUser)
        .then((tok) => setToken(tok))
        .catch(() => setToken(null))
    }
  }, [currentUser, setToken, token])
  return token
}

export const useCurrentUserAuthToken = () => {
  const currentUser = useCurrentUser()
  const [token, setToken] = useExpiringFirebaseToken()
  useEffect(() => {
    if (deprecatedIsLoaded(currentUser) && isLoading(token)) {
      getUserAuthToken(currentUser)
        .then((tok) => setToken(tok))
        .catch(() => setToken(null))
    }
  }, [currentUser, setToken, token])
  return token
}

const forceRefreshToken = async (user: Pick<CurrentUser, "authUser">, endpointPath?: string) => {
  const userToken = await user.authUser.getIdToken(/* force refresh */ true)
  trackAuthTokenRetrieved(endpointPath)
  trackAuthTokenRefresh(endpointPath)
  return userToken
}
