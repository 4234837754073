import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useCurrentUser } from "../currentUser/useCurrentUser"
import { Loading, isLoaded, lift2Loading, matchLoading } from "common/utils/Loading"
import { CompanyIdFields } from "common/model/Company"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import { DocumentData, collection, doc, onSnapshot, query, where, setDoc } from "firebase/firestore"
import { collections } from "common/firestore/Collections"
import { startOfMonth } from "date-fns"
import {
  CompanyAccess,
  buildNewCompanyAccess,
  canAccessCompany,
  companyAccessResetsMonthly,
  countRemainingUnlocks,
  hasUnlimitedCompanyAccess,
  isCompanyAccessActive,
} from "common/model/AccessControl/CompanyAccess"
import { firestoreConverter } from "common/model/FirestoreConverter"
import { useCompanies } from "../data/CompanyProvider"
import { companyHasMarketActivityInPastYear } from "common/model/CompanyMarketHistorySummary"
import { useAccessControl } from "./AccessControlProvider"
import { AccessControlFeatureName } from "common/model/AccessControl/AccessControl"
import { assertUnreachable } from "common/utils/fp/Function"
import { AccessControlTier } from "common/model/AccessControl/AccessControlTier"

type AccountCompanyAccessCtx = {
  getCompanyUnlockStatus: (company: Pick<CompanyIdFields, "id">) => "locked" | "unlocked"
  getRemainingUnlockCount: () => Loading<number | "unlimited">
  unlockLoadedCompany: (c: CompanyIdFields) => Promise<void>
}
const defaultAccountCompanyAccessContext = {
  getCompanyUnlockStatus: () => "locked",
  getRemainingUnlockCount: () => null,
  unlockLoadedCompany: () => Promise.reject(new Error("Not implemented")),
} satisfies AccountCompanyAccessCtx

const AccountCompanyAccessContext = createContext<AccountCompanyAccessCtx>(
  defaultAccountCompanyAccessContext
)

const useActiveCompanyAccess = (currentAccessTier: Loading<AccessControlTier>) => {
  const currentUser = useCurrentUser()
  const [activeCompanyAccess, setActiveCompanyAccess] =
    useState<Loading<CompanyAccess[]>>("loading")

  const firebase9 = useFirebase9()
  useEffect(() => {
    if (!isLoaded(currentUser) || !isLoaded(currentAccessTier)) {
      return () => {}
    }
    const companyAccessCollection = collection(
      firebase9.db,
      collections.accounts,
      currentUser.user.account.id,
      collections.accountSubcollections.companyAccess
    )
    const activeCompanyAccessQuery = companyAccessResetsMonthly(currentAccessTier)
      ? query(companyAccessCollection)
      : query(companyAccessCollection, where("unlockedAt", ">=", startOfMonth(new Date())))
    return onSnapshot<CompanyAccess, DocumentData>(
      activeCompanyAccessQuery.withConverter<CompanyAccess>(firestoreConverter<CompanyAccess>()),
      (snapshot) => {
        setActiveCompanyAccess(
          snapshot.docs.flatMap((d) => {
            const companyAccess = d.data()
            return companyAccess && isCompanyAccessActive(companyAccess) ? [companyAccess] : []
          })
        )
      }
    )
  }, [firebase9.db, currentUser, currentAccessTier])

  return activeCompanyAccess
}

export const AccountCompanyAccessProvider = ({ children }: { children: ReactNode }) => {
  const firebase9 = useFirebase9()
  const { currentAccessTier } = useAccessControl()
  const activeCompanyAccess = useActiveCompanyAccess(currentAccessTier)
  const currentUser = useCurrentUser()

  const getCompanyUnlockStatus: AccountCompanyAccessCtx["getCompanyUnlockStatus"] = useMemo(
    () => (company) => {
      if (isLoaded(currentAccessTier) && hasUnlimitedCompanyAccess(currentAccessTier)) {
        return "unlocked"
      }

      if (!isLoaded(activeCompanyAccess) || !isLoaded(currentAccessTier)) {
        return "locked"
      }

      return canAccessCompany({
        companyToCheck: company,
        accountCompanyAccess: activeCompanyAccess,
        currentAccessTier,
      })
        ? "unlocked"
        : "locked"
    },
    [currentAccessTier, activeCompanyAccess]
  )

  const getRemainingUnlockCount = useCallback(
    () =>
      lift2Loading(
        (loadedCurrentAccessTier, loadedActiveCompanyAccess) =>
          countRemainingUnlocks({
            accountCompanyAccess: loadedActiveCompanyAccess,
            currentAccessTier: loadedCurrentAccessTier,
          }),
        currentAccessTier,
        activeCompanyAccess
      ),
    [activeCompanyAccess, currentAccessTier]
  )

  const unlockLoadedCompany = useCallback(
    (c: CompanyIdFields) => {
      if (!isLoaded(currentUser)) {
        return Promise.reject(new Error("User not loaded"))
      }
      const remainingUnlockCount = getRemainingUnlockCount()
      if (!isLoaded(remainingUnlockCount)) {
        return Promise.reject(new Error("Remaining unlock count not loaded"))
      }
      if (remainingUnlockCount === 0) {
        return Promise.reject(new Error("No unlocks remaining"))
      }
      const newAccess = buildNewCompanyAccess({ company: c, user: currentUser.user })
      return setDoc(
        doc(
          firebase9.db,
          collections.accounts,
          currentUser.user.account.id,
          collections.accountSubcollections.companyAccess,
          newAccess.id
        ),
        newAccess
      )
    },
    [currentUser, firebase9.db, getRemainingUnlockCount]
  )

  const value = useMemo(
    () => ({
      getCompanyUnlockStatus,
      getRemainingUnlockCount,
      unlockLoadedCompany,
    }),
    [getCompanyUnlockStatus, getRemainingUnlockCount, unlockLoadedCompany]
  )

  return (
    <AccountCompanyAccessContext.Provider value={value}>
      {children}
    </AccountCompanyAccessContext.Provider>
  )
}

const useAccountCompanyAccess = () => useContext(AccountCompanyAccessContext)

export type CompanyAccessCtx = {
  company: Loading<CompanyIdFields>
  isCompanyUnlocked: boolean
  isCompanyUnlockedForLackOfData: boolean
  remainingUnlockCount: Loading<number | "unlimited">
  unlockCompany: () => Promise<void>
}
const defaultCompanyAccessContext = {
  company: null,
  isCompanyUnlocked: false,
  isCompanyUnlockedForLackOfData: false,
  remainingUnlockCount: null,
  unlockCompany: () => Promise.reject(new Error("Not implemented")),
} satisfies CompanyAccessCtx

const CompanyAccessContext = createContext<CompanyAccessCtx>(defaultCompanyAccessContext)

export const CompanyAccessProvider = ({
  children,
  company,
}: {
  children: ReactNode
  company: CompanyAccessCtx["company"]
}) => {
  const { getCompanyUnlockStatus, getRemainingUnlockCount, unlockLoadedCompany } =
    useAccountCompanyAccess()

  const fullCompany = useCompanies(matchLoading(company, (c) => c.id, null, null))

  const remainingUnlockCount = useMemo(() => getRemainingUnlockCount(), [getRemainingUnlockCount])

  const isCompanyUnlockedForLackOfData = useMemo(
    () =>
      remainingUnlockCount !== "unlimited" &&
      isLoaded(fullCompany) &&
      !!fullCompany?.marketHistorySummary &&
      !companyHasMarketActivityInPastYear(fullCompany.marketHistorySummary),
    [fullCompany, remainingUnlockCount]
  )

  const isCompanyUnlocked: CompanyAccessCtx["isCompanyUnlocked"] = useMemo(() => {
    const hasCompanyBeenUnlocked = isLoaded(company)
      ? getCompanyUnlockStatus(company) === "unlocked"
      : true

    return isCompanyUnlockedForLackOfData || hasCompanyBeenUnlocked
  }, [company, getCompanyUnlockStatus, isCompanyUnlockedForLackOfData])

  const unlockCompany = useCallback(
    () =>
      isLoaded(company)
        ? unlockLoadedCompany(company)
        : Promise.reject(new Error("Company not loaded")),
    [unlockLoadedCompany, company]
  )

  const value: CompanyAccessCtx = useMemo(
    () => ({
      company,
      isCompanyUnlocked,
      isCompanyUnlockedForLackOfData,
      remainingUnlockCount,
      unlockCompany,
    }),
    [
      company,
      isCompanyUnlocked,
      isCompanyUnlockedForLackOfData,
      remainingUnlockCount,
      unlockCompany,
    ]
  )

  return <CompanyAccessContext.Provider value={value}>{children}</CompanyAccessContext.Provider>
}

export const useCompanyAccess = () => useContext(CompanyAccessContext)

export const useCompanyUnlockedForFeature = (featureName: AccessControlFeatureName): boolean => {
  const { getFeatureAccessLevel } = useAccessControl()
  const { isCompanyUnlocked } = useCompanyAccess()

  return useMemo(() => {
    const accessLevelForFeature = getFeatureAccessLevel(featureName)
    switch (accessLevelForFeature) {
      case "full": {
        return true
      }
      case "limited-company": {
        return isCompanyUnlocked
      }
      case "none": {
        return false
      }
      case "locked": {
        return false
      }
      default: {
        return assertUnreachable(accessLevelForFeature)
      }
    }
  }, [isCompanyUnlocked, getFeatureAccessLevel, featureName])
}

export const useShowBlurredCompanyDataForFeature = (
  featureName: AccessControlFeatureName
): boolean => {
  const { getFeatureAccessLevel } = useAccessControl()

  return useMemo(() => {
    const accessLevelForFeature = getFeatureAccessLevel(featureName)
    switch (accessLevelForFeature) {
      case "full": {
        return true
      }
      case "limited-company": {
        return true
      }
      case "none": {
        return false
      }
      case "locked": {
        return false
      }
      default: {
        return assertUnreachable(accessLevelForFeature)
      }
    }
  }, [getFeatureAccessLevel, featureName])
}

export const CompanyAccessRowWrapper = <T extends { company: CompanyAccessCtx["company"] }>({
  rowData,
  children,
}: {
  rowData: T
  children: React.ReactNode
}) => <CompanyAccessProvider company={rowData.company}>{children}</CompanyAccessProvider>
