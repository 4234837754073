import { wrapIcon } from "./IconWrapper"

export const InboxIcon = wrapIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.33333 14C2.96667 14 2.65267 13.8693 2.39133 13.608C2.13 13.3467 1.99956 13.0329 2 12.6667V3.33333C2 2.96667 2.13067 2.65267 2.392 2.39133C2.65333 2.13 2.96711 1.99956 3.33333 2H12.6667C13.0333 2 13.3473 2.13067 13.6087 2.392C13.87 2.65333 14.0004 2.96711 14 3.33333V12.6667C14 13.0333 13.8693 13.3473 13.608 13.6087C13.3467 13.87 13.0329 14.0004 12.6667 14H3.33333ZM8 10.6667C8.42222 10.6667 8.80556 10.5444 9.15 10.3C9.49445 10.0556 9.73334 9.73334 9.86667 9.33333H12.6667V3.33333H3.33333V9.33333H6.13333C6.26667 9.73334 6.50556 10.0556 6.85 10.3C7.19445 10.5444 7.57778 10.6667 8 10.6667Z"
      fill="currentColor"
    />
  </svg>
)
