import { OrderInquiry } from "common/model/OrderInquiry/Db"
import { Maybe } from "common/containers/Maybe"
import { Button } from "@stories/components/Button/Button"
import Typography, { Weight } from "@stories/components/Typography/Typography"
import { useNavigate } from "react-router-dom"
import { messageThreadRoute } from "common/model/Messaging/MessagingRoutes"
import FeatureWrapper from "src/providers/featureFlags/FeatureWrapper"

const OrderInquiryDrawerSubmittedView = ({
  onClose,
  inquiry,
}: {
  onClose: () => void
  inquiry: Maybe<OrderInquiry>
}) => {
  const navigate = useNavigate()
  return (
    <div className="flex flex-col h-full space-y-2">
      <div>
        <Typography
          text="Your inquiry has been received. We will be in touch when we hear back from the user
      who placed this order."
        />
      </div>
      <div>
        <Typography text="Most inquiries receive a response within 24 hours." />
      </div>
      {inquiry.match(
        ({ userForm, threadId }) => (
          <div className="w-full">
            {userForm.additionalQuestions ? (
              <div>
                <div>
                  <Typography text="Your Inquiry" />
                </div>
                <div>
                  <Typography weight={Weight.Bold} text={userForm.additionalQuestions} />
                </div>
              </div>
            ) : null}
            {threadId ? (
              <FeatureWrapper flagName="order_inquiry_threads">
                <div className="flex flex-col items-center">
                  <Button
                    onClick={() => navigate(messageThreadRoute(threadId))}
                    label="View In Inbox"
                  />
                </div>
              </FeatureWrapper>
            ) : null}
          </div>
        ),
        () => null
      )}
      <Button variant="hollow" onClick={onClose} label="Back to Order" />
    </div>
  )
}
export default OrderInquiryDrawerSubmittedView
