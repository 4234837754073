import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { EmailEditor } from "./EmailEditor"
import { Button } from "@stories/components/Button/Button"
import ButtonGroup from "@stories/components/ButtonGroup/ButtonGroup"
import { ChevronLeftIcon } from "@stories/icons/ChevronLeftIcon"
import { ChevronRightIcon } from "@stories/icons/ChevronRightIcon"
import { useSharedOrderEditor } from "./SharedOrderEditorProvider"

export const SharedOrderMultiEmailEditor = () => {
  const { multiEditorContent } = useSharedOrderEditor()
  if (!multiEditorContent) {
    return null
  }
  const {
    allItems,
    currentItem,
    currentIndex,
    updateCurrentItem,
    canDecrementIndex,
    canIncrementIndex,
    incrementIndex,
    decrementIndex,
  } = multiEditorContent

  return (
    <div className="flex flex-col gap-2">
      <Typography
        weight={Weight.Semibold}
        color={Color.Primary}
        size={Size.Large}
        text="Finalize Email"
      />
      <Typography text="Send the details of your order to clients or partners. All selected contacts will receive this email." />
      <div className=" bg-neutral-200 rounded flex flex-col gap-2 p-2">
        {currentItem ? (
          <>
            <div className="flex flex-col gap-0">
              <Typography
                text={`You are now editing the email for ${
                  currentItem.contact.firstName ?? currentItem.contact.email
                }.`}
                size={Size.Small}
              />
              <Typography
                text="Press next to continue editing other contacts."
                weight={Weight.Light}
                size={Size.Small}
              />
            </div>
            <EmailEditor
              key={currentItem.contact.email}
              introduction={`Hi${
                currentItem.contact.firstName ? ` ${currentItem.contact.firstName}` : ""
              },`}
              emailSubject={currentItem.emailSubject}
              onEmailSubjectChange={(emailSubject) => updateCurrentItem({ emailSubject })}
              initialEmailBody={currentItem.emailBody}
              onEmailBodyChange={(emailBody) => updateCurrentItem({ emailBody })}
            />
          </>
        ) : null}
      </div>
      <ButtonGroup>
        <Button
          leftIcon={<ChevronLeftIcon />}
          label="Back"
          onClick={decrementIndex}
          isDisabled={!canDecrementIndex}
          variant="secondary"
        />
        <div className="w-full border flex items-center justify-center">
          <Typography
            size={Size.XSmall}
            weight={Weight.Semibold}
            text={`Now Editing: ${
              currentItem?.contact.firstName ?? currentItem?.contact.email ?? ""
            } (${currentIndex + 1}/${allItems.length})`}
          />
        </div>
        <Button
          label="Next"
          onClick={incrementIndex}
          isDisabled={!canIncrementIndex}
          variant="secondary"
          rightIcon={<ChevronRightIcon />}
        />
      </ButtonGroup>
    </div>
  )
}
