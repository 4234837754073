import { assertUnreachable } from "common/utils/fp/Function"

export type PillOption =
  | "open"
  | "pending_confirmation"
  | "locked"
  | "closed"
  | "numerical_indicator"
  | "caplight"
  | "user"
  | "counterparty"

interface MessagingPillProps {
  text: string
  type: PillOption
}

const pillStyle = (pillType: PillOption): string => {
  if (pillType === "numerical_indicator") {
    return "bg-accent-100 w-5 h-5 text-neutral-1000"
  }

  if (pillType === "open") {
    return "bg-success-500 text-white"
  }

  if (pillType === "pending_confirmation") {
    return "bg-warning-500 text-white"
  }

  if (pillType === "locked") {
    return "bg-danger-500 text-white"
  }

  if (pillType === "closed") {
    return "bg-danger-700 text-white"
  }

  if (pillType === "caplight") {
    return "bg-primary-500 text-white"
  }

  if (pillType === "user") {
    return "bg-success-500 text-white"
  }

  if (pillType === "counterparty") {
    return "bg-danger-500 text-white"
  }

  return assertUnreachable(pillType)
}

export const MessagingPill: React.FC<React.PropsWithChildren<MessagingPillProps>> = ({
  text,
  type,
}) => (
  <div
    className={`${pillStyle(
      type
    )} text-xs rounded-full flex justify-center items-center py-1 px-2 max-w-min whitespace-nowrap`}
  >
    <span>{text}</span>
  </div>
)
