import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { DealCrmDealContactCommitmentStatus } from "common/model/DealCRM/Deal/DealCRMDealParticipant"
import React from "react"

interface CommitmentPillProps {
  status: DealCrmDealContactCommitmentStatus
}

export const CommitmentPill: React.FC<CommitmentPillProps> = ({ status }) => (
  <div
    className={`flex gap-2 px-2 py-1 items-center ${getStyle(
      status
    )} rounded-md inline-block max-w-max min-w-36`}
  >
    <Typography
      text={getDealCommitmentDisplayText(status)}
      size={Size.XSmall}
      weight={Weight.Semibold}
      color={getColor(status)}
    />
  </div>
)

const getStyle = (status: DealCrmDealContactCommitmentStatus): string => {
  switch (status) {
    case "wired":
      return "bg-accent-100 border border-accent-400"
    case "signed-commitment":
      return "bg-success-200 border border-success-400"
    case "firm-interest":
      return "bg-success-100 border border-success-400"
    case "tentative-interest":
      return "bg-warning-200 border border-warning-400"
    case "pending-outreach":
      return "bg-warning-100 border border-warning-400"
    case "not-interested":
      return "bg-gray-300 border border-gray-400"
    default:
      return "bg-neutral-200 border border-neutral-300"
  }
}

const getColor = (status: DealCrmDealContactCommitmentStatus): Color => {
  switch (status) {
    case "wired":
      return Color.SecondaryLink
    case "signed-commitment":
      return Color.SuccessSecondary
    case "firm-interest":
      return Color.SuccessSecondary
    case "tentative-interest":
      return Color.WarningSecondary
    case "pending-outreach":
      return Color.WarningSecondary
    case "not-interested":
      return Color.Placeholder
    default:
      return Color.White
  }
}

export const getDealCommitmentDisplayText = (
  status: DealCrmDealContactCommitmentStatus
): string => {
  switch (status) {
    case "wired":
      return "Wired"
    case "signed-commitment":
      return "Signed"
    case "firm-interest":
      return "Firm"
    case "tentative-interest":
      return "Tentative"
    case "pending-outreach":
      return "Prospecting"
    case "not-interested":
      return "Not Interested"
    default:
      return "Unknown"
  }
}
