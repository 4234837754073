/* eslint-disable no-case-declarations */
import { DataDisplayConstants } from "common/UI/DataDisplayConstants"
import { nullableToMaybe } from "common/containers/Maybe"
import { Account, isIntermediaryAccount, viewAccountIdFields } from "common/model/Account"
import { viewCompanyIdFields } from "common/model/Company"
import {
  DealCRMDeal,
  DealCRMStage,
  defaultAccountDealStage,
} from "common/model/DealCRM/Deal/DealCRMDeal"
import { DealCRMDealParticipant } from "common/model/DealCRM/Deal/DealCRMDealParticipant"
import {
  DealCRMInterest,
  defaultAccountBuySellInterestStage,
} from "common/model/DealCRM/DealCRMInterest"
import { User, viewUserIdFields } from "common/model/User"
import { undefinedsToNulls } from "common/utils/ObjectUtils"
import { articulatedDateTimeFormat, formatDate, nDaysAgo } from "common/utils/dateUtils"
import { assertUnreachable } from "common/utils/fp/Function"
import { capitalize } from "lodash"
import { ReactElement } from "react"
import { FirebaseWriter } from "src/firebase/Firebase"
import { createDeal } from "src/firebase/crm"
import { firebase9 } from "src/providers/AppProviders"
import { trackEventInFirestoreAndHeap } from "src/utils/Tracking"
import { buildAccountCRMSettings, defaultAccountCRMSettings } from "common/model/AccountCRMSettings"
import { CRMInterestTag, CrmItemData } from "./KanbanCardModels"

export type Metric = {
  title: string
  value: string | null | ReactElement
}

export const getCardStage = (card: CrmItemData, account: Account): DealCRMStage => {
  switch (card.tag) {
    case "crm_interest":
      return card.interest.stage ?? defaultAccountBuySellInterestStage(account)
    case "crm_deal":
      return card.deal.stage ?? defaultAccountDealStage(account)
    default:
      return assertUnreachable(card)
  }
}

export const accountDealAndOrderColumns = (account: Account): Record<string, CrmItemData[]> => {
  const { dealAndOrderStages: dealStages } = buildAccountCRMSettings(account)

  return Object.fromEntries(
    Object.values(dealStages ?? defaultAccountCRMSettings(account.clientType).dealAndOrderStages)
      .flat()
      .map((stage) => [stage, []])
  )
}

export const kanbanSorts = ["priority", "lastUpdatedAt", "commission", "companyName"] as const
export type KanbanSort = (typeof kanbanSorts)[number]

export type KanbanSortingOption = {
  value: KanbanSort
  label: string
}

export const kanbanSortingOptions: KanbanSortingOption[] = [
  { value: "priority", label: "By Priority" },
  { value: "lastUpdatedAt", label: "By Updated Date" },
  { value: "commission", label: "By Commission" },
  { value: "companyName", label: "By Company" },
]

export const defaultKanbanSortingOption = kanbanSortingOptions[1]

export const priorityOrder = {
  urgent: 0,
  high: 1,
  medium: 2,
  low: 3,
  none: 4,
}

const getDate = (card: CrmItemData): Date =>
  card.tag === "crm_deal" ? card.deal.lastUpdatedAt : card.interest.lastUpdatedAt

const getPriority = (card: CrmItemData): number =>
  card.tag === "crm_deal"
    ? priorityOrder[card.deal.priority]
    : priorityOrder[card.interest.contact?.priority ?? "none"]

const getCommission = (card: CrmItemData, account: Account): number =>
  card.tag === "crm_deal"
    ? calculateDealFees({
        bids: card.bids,
        offers: card.offers,
        dealParticipants: card.dealParticipants,
        isBroker: isIntermediaryAccount(account),
      })
    : (card.interest.commissionPercent ?? 0) * (card.interest.amountUSD?.upperBound ?? 0)

const getCompanyName = (card: CrmItemData): string =>
  card.tag === "crm_deal" ? card.deal.company.name : card.interest.company.name

export const sortKanbanCards = (
  cards: CrmItemData[],
  sortingOption: KanbanSort,
  sortDirection: "asc" | "desc",
  account: Account
): CrmItemData[] => {
  const sortingStrategies: {
    [key in KanbanSort]: (a: CrmItemData, b: CrmItemData) => number
  } = {
    lastUpdatedAt: (a, b) => getDate(a).getTime() - getDate(b).getTime(),
    priority: (a, b) => getPriority(b) - getPriority(a),
    commission: (a, b) => getCommission(a, account) - getCommission(b, account),
    companyName: (a, b) => getCompanyName(b).localeCompare(getCompanyName(a)),
  }

  const applyDirection = (result: number): number => (sortDirection === "asc" ? result : -result)

  return [...cards].sort((a, b) => applyDirection(sortingStrategies[sortingOption](a, b)))
}

export const calculateDealFees = ({
  bids,
  offers,
  dealParticipants,
  isBroker,
}: {
  bids: DealCRMInterest[]
  offers: DealCRMInterest[]
  dealParticipants: DealCRMDealParticipant[]
  isBroker: boolean
}): number => {
  if (dealParticipants.length) {
    const DEFAULT_BROKER_COMMISSION = 0.03
    const dealParticipantFee = (participant: DealCRMDealParticipant): number =>
      nullableToMaybe(isBroker ? DEFAULT_BROKER_COMMISSION : participant.managementFeePercent)
        .alongside(nullableToMaybe(participant.commitmentSharePrice))
        .alongside(nullableToMaybe(participant.commitmentNumShares))
        .match(
          ([[fee, price], shares]) => fee * price * shares,
          () => 0
        )
    return dealParticipants.map(dealParticipantFee).reduce((a, b) => a + b, 0)
  } else {
    const buySellInterestFee = (interest: DealCRMInterest): number =>
      nullableToMaybe(interest.amountUSD)
        .alongside(nullableToMaybe(interest.commissionPercent))
        .match(
          ([amount, percent]) => amount.upperBound * percent,
          () => 0
        )
    const bidFees = bids.map(buySellInterestFee).reduce((a, b) => a + b, 0)
    const offerFees = offers.map(buySellInterestFee).reduce((a, b) => a + b, 0)
    return bidFees + offerFees
  }
}

export const handleAddInterest = async ({
  db,
  user,
  interest,
  newInterest,
}: {
  db: FirebaseWriter
  user: User
  interest: DealCRMInterest
  newInterest: DealCRMInterest
}) => {
  const companyFields = {
    ...viewCompanyIdFields(interest.company),
    postgresCompanyId: interest.company.postgresCompanyId ?? "",
  }

  const interests = [
    { tag: CRMInterestTag, direction: interest.direction, id: interest.id },
    { tag: CRMInterestTag, direction: newInterest.direction, id: newInterest.id },
  ]

  // Bids in position 0, Offers in position 1
  const orderedInterests = interests[0].direction === "buy" ? interests : interests.reverse()

  const deal: Omit<DealCRMDeal, "id"> = {
    company: companyFields,
    createdAt: new Date(),
    account: viewAccountIdFields(user.account),
    createdBy: viewUserIdFields(user),
    stage: interest.stage ?? "active",
    urgency: "none",
    priority: interest.priority ?? "none",
    name: `${interest.company.name} ${formatDate(new Date())}`,
    managementFeePercent: null,
    managementFeeStructure: null,
    carryPercent: null,
    lastUpdatedAt: new Date(),
    lastUpdateBy: viewUserIdFields(user),
    bids: [orderedInterests[0]],
    bidIds: [orderedInterests[0].id],
    bidMatchSuggestions: [],
    bidMatchSuggestionIds: [],
    offers: [orderedInterests[1]],
    offerIds: [orderedInterests[1].id],
    offerMatchSuggestions: [],
    offerMatchSuggestionIds: [],
    updates: [],
  }

  await createDeal({ db, newDeal: undefinedsToNulls(deal) }).then(() => {
    // eslint-disable-next-line no-void
    void trackEventInFirestoreAndHeap(firebase9, user, "crm-deal-created", {
      targetDollarAmount: deal.targetDollarAmount,
      urgency: deal.urgency,
      priority: deal.priority,
      // Do NOT track company due to sensitivity
    })
  })
}

export const getInterestCustomStructure = (interest: DealCRMInterest) => {
  if (interest.structure) {
    if (interest.structure === "spv") {
      const managementFee = interest.managementFeePercent ?? 0
      const carry = interest.carryPercent ?? 0
      return `SPV (${managementFee * 100} / ${carry * 100})`
    }
    return capitalize(interest.structure)
  }

  return DataDisplayConstants.noValue
}

export const getLastUpdatedText = (lastUpdatedAt: Date): string => {
  if (lastUpdatedAt.valueOf() > nDaysAgo(2).valueOf()) {
    return `Updated ${articulatedDateTimeFormat(lastUpdatedAt).date} at ${
      articulatedDateTimeFormat(lastUpdatedAt).time
    }`
  } else {
    return `Updated ${articulatedDateTimeFormat(lastUpdatedAt).date}`
  }
}
