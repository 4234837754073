import { JSONContent } from "@tiptap/react"
import { Account, AccountIdFields } from "../Account"
import { User, UserIdFields } from "../User"
import { Excluding } from "../../utils/TypeUtils"
import { assertUnreachable } from "../../utils/fp/Function"

/*
  example SharedOrder = {
    id: 'abc123',
    createdAt: 1/2/2023,
    sharedDocumentType: 'order',
    document: { id: '321cba' },
    source: { tag: 'user', user: {...}, account: {...} },
    sharedWithIds: ['xyz981'],
    sharedWith: {
      xyz981: {
        tag: 'off platform',
        email: 'test@caplight.com',
        status: 'sent',
        startedShare: 1/2/2023,
        loadCount: 0,
      }
    },
    userForm: {
      introduction: 'Hey test! I was thinking you'd be able to help me with this.',
    }
  }
*/

type SharedDocumentIdentifier = { id: string }

export type SharedWithId = string
export type SharedWithData = {
  startedShare: Date
  response?: {
    at: Date
    responseType: "rejected" | "interested"
    message?: string
  }
  emailDates: { initialNotification?: Date }
  loadCount: number
}
export type SharedWithUser =
  | {
      id: SharedWithId
      tag: "off platform"
      email: string
    }
  | {
      id: SharedWithId
      tag: "on platform"
      user: Excluding<UserIdFields, User>
    }
export const getSharedOrderSourceEmail = <T extends string | null>(
  { source }: Pick<SharedOrder, "source">,
  systemEmailFallback: T
) =>
  source.tag === "user" || source.tag === "admin"
    ? source.user.email
    : source.tag === "system"
    ? systemEmailFallback
    : assertUnreachable(source)
export const getSharedOrderSourceFullName = (sharedOrderData: Pick<SharedOrder, "source">) => {
  const sourceName =
    sharedOrderData.source.tag === "user" || sharedOrderData.source.tag === "admin"
      ? `${sharedOrderData.source.user.firstName} ${sharedOrderData.source.user.lastName}`
      : "Caplight"

  return sourceName
}
export const getSharedWithUserFullName = (u: SharedWithUser) =>
  u.tag === "on platform" ? `${u.user.firstName} ${u.user.lastName}` : ""
export const getSharedWithUserEmail = (u: SharedWithUser) =>
  u.tag === "on platform" ? u.user.email : u.email
export const getSharedWithStatus = (d: SharedWithData): SharedWithStatus => {
  if (d.response) {
    return d.response.responseType
  }
  return d.loadCount > 0 ? "viewed" : d.emailDates?.initialNotification ? "sent" : "created"
}

export type SharedWithStatus = "sent" | "viewed" | "created" | "rejected" | "interested"
export const getSharedWithStatusLabel = (s: SharedWithStatus): string => {
  const statusMap: Record<SharedWithStatus, string> = {
    sent: "Sent",
    viewed: "Opened",
    created: "Pending",
    rejected: "Not Interested",
    interested: "Expressed Interest",
  }
  return statusMap[s]
}

export type SharedWithUserForm = {
  emailBody: JSONContent
  emailSubject?: string
}

export type SharedSourceFields = {
  source:
    | {
        tag: "user"
        user: Excluding<UserIdFields, User>
        account: Excluding<AccountIdFields, Account>
      }
    | {
        tag: "admin"
        user: Excluding<UserIdFields, User>
        account: Excluding<AccountIdFields, Account>
      }
    | { tag: "system" }
}

export type SharedIdFields<T extends string> = {
  id: string
  sharedDocumentType: T
  document: SharedDocumentIdentifier
  createdAt: Date
} & SharedSourceFields

type Shared<T extends string> = SharedIdFields<T> & {
  sharedWithIds: SharedWithId[]
  sharedWith: Record<SharedWithId, SharedWithUser & SharedWithData>
  userForm: SharedWithUserForm
}

export type ExclusiveSharedIdFields<T extends string> = Excluding<SharedIdFields<T>, Shared<T>>

export type SharedOrderDocumentType =
  | "order"
  | "darkpool_order"
  | "tentative_interest"
  | "crm_interest"
  | "deal_search"
export type SharedOrder = Shared<SharedOrderDocumentType>

export const sharedWithIdKey = "u"
export const sharedOrderRouteTemplate = "/shared/indications/:id"
export const sharedOrderListRouteTemplate = "/shared/indications"
export const sharedOrderRoute = (id: string, sharedWithId: SharedWithId) =>
  `${sharedOrderRouteTemplate.replace(":id", id)}?${sharedWithIdKey}=${sharedWithId}`
export const sharedOrderListRoute = (sharedWithId: SharedWithId) =>
  `${sharedOrderListRouteTemplate}?${sharedWithIdKey}=${sharedWithId}`

export const viewSharedIdFields = <T extends string>(
  shared: Shared<T>
): ExclusiveSharedIdFields<T> => ({
  id: shared.id,
  sharedDocumentType: shared.sharedDocumentType,
  document: shared.document,
  source: shared.source,
  createdAt: shared.createdAt,
})

export const isSharedOrderSharedWithUser = (
  sharedOrder: SharedOrder,
  sharedWithId: SharedWithId
): boolean => !!sharedOrder.sharedWith[sharedWithId]
