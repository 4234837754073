import { FloatingOverlay } from "@floating-ui/react"
import { ComponentProps, PropsWithChildren } from "react"
import background from "../../../../public/assets/images/accessControl/UpgradePlan.png"
import { classNames } from "src/utils/classNames"
import { AnimatePresence, motion } from "framer-motion"
import { StopClickPropagation } from "@stories/components/StopClickPropagation/StopClickPropagation"

const initialStyle: ComponentProps<typeof motion.div>["initial"] = {
  opacity: 0,
  transform: "scale(0.5)",
}
const displayedStyle: ComponentProps<typeof motion.div>["animate"] = {
  opacity: 1,
  transform: "scale(1)",
}
export const UpgradeTierOverlay = ({
  children,
  size = "small",
}: PropsWithChildren<{ size?: "small" | "large" }>) => (
  <FloatingOverlay>
    <StopClickPropagation>
      <AnimatePresence>
        <div className="z-50 fixed inset-0 flex justify-center pointer-events-none">
          <motion.div
            className={classNames(
              "sticky top-32 w-full h-fit rounded-lg p-4 shadow-xl pointer-events-auto",
              size === "small" ? "max-w-2xl" : "max-w-4xl"
            )}
            style={{
              backgroundImage: `url(${background})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            initial={initialStyle}
            animate={displayedStyle}
            exit={initialStyle}
          >
            {children}
          </motion.div>
        </div>
      </AnimatePresence>
    </StopClickPropagation>
  </FloatingOverlay>
)
