import { v4 as uuid } from "uuid"
import { Order } from "./Order"
import { restrictedCollections } from "../../firestore/Collections"
import { firestoreConverter } from "../FirestoreConverter"
import { nestedUndefinedsToEmptyObjects, undefinedsToNulls } from "../../utils/ObjectUtils"
import { now } from "../../utils/dateUtils"
import { EditLog } from "../EditLog"
import { mapOrUndefined } from "../../utils/UnionUtils"
import { FirebaseCommon, ITransaction } from "../../firestore/Interface"
import { UserIdFields } from "../User"

export const orderChangelogWriteJob =
  (db: FirebaseCommon.DB, user: UserIdFields, currentDbOrder: Order | undefined, reason: string) =>
  (newOrder: Order) => {
    const newDocRef = db
      .collection(restrictedCollections.orderObservations)
      .withConverter<Order>(firestoreConverter<Order>())
      .doc(newOrder.id)
    const log: EditLog<Order> = nestedUndefinedsToEmptyObjects({
      before: mapOrUndefined(currentDbOrder, undefinedsToNulls) ?? null,
      after: undefinedsToNulls(newOrder),
      path: newDocRef.path,
      editor: user,
      reason,
      editDate: now(),
    })
    const logDoc = db
      .collection(restrictedCollections.editLogs)
      .withConverter<EditLog<Order>>(firestoreConverter())
      .doc(uuid())
    return <T extends ITransaction<Self, object>, Self>(t: T): Self => t.set(logDoc, log)
  }
