import { Maybe } from "../../containers/Maybe"
import { Account } from "../Account"
import { User } from "../User"
import { FeatureFlagLogicFields } from "./FeatureFlag"

type IdFields = { userId: User["id"]; accountId: Account["id"] }

export const isFlagEnabled = (
  { status, enabledUsers, enabledAccounts }: FeatureFlagLogicFields,
  idFields: Maybe<IdFields>
): boolean => {
  switch (status) {
    case "fully_enabled": {
      return true
    }
    case "disabled": {
      return false
    }
    case "conditionally_enabled":
    default: {
      return idFields.match(
        ({ accountId, userId }) =>
          enabledAccounts?.some(({ id }) => id === accountId) ||
          enabledUsers?.some(({ id }) => id === userId) ||
          false,
        () => false
      )
    }
  }
}
