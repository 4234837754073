import { UserIdFields } from "common/model/User"
import { firestoreConverter } from "../FirestoreConverter"

/** Private auction data only viewable by the client running the auction */
export interface PrivateAuctionData {
  id: string // corresponds with auctionId
  account: { id: string; name: string; airtableId: string }
  createdBy: UserIdFields
  createdAt: Date
  updatedBy: UserIdFields
  updatedAt: Date
}

export const privateAuctionDataConverter = firestoreConverter<PrivateAuctionData>()
