import { ProductInformationKeys, UserProductInformationHistory } from "common/model/User"
import { useFirebaseWriter } from "src/firebase/Context"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { Button } from "@stories/components/Button/Button"
import { handleConsoleError } from "src/utils/Tracking"
import { Modal, useModal } from "../Modal"
import { ProductInformationLoomLinks } from "./InformationPopupConfig"

export const useInformationPopupModal = ({
  informationKey,
  defaultModalOpen,
}: {
  informationKey: keyof UserProductInformationHistory
  defaultModalOpen?: boolean
}) => {
  const user = useLoggedInUser()
  const firebase = useFirebaseWriter()

  const { open, openModal, closeModal } = useModal(defaultModalOpen)

  const handleClose = () => {
    if (user.user.productInteractionHistory[informationKey]) {
      closeModal()
    } else {
      firebase
        .recordUserProductInteraction(user.user, {
          [informationKey]: new Date(),
        })
        .catch((err) => {
          handleConsoleError(err)
        })
        .finally(closeModal)
    }
  }

  const ModalComponent = (
    <Modal
      closable
      open={open}
      handleClose={handleClose}
      body={<InformationPopupModalBody informationKey={informationKey} />}
      footer={<Button label="Close" onClick={handleClose} />}
    />
  )

  return { ModalComponent, openModal }
}

const InformationPopupModalBody = ({
  informationKey,
}: {
  informationKey: (typeof ProductInformationKeys)[number]
}) => {
  const width = informationKey === "firstViewedAugust2024InsightsAnnouncement" ? 1200 : 800
  return (
    <>
      <div style={{ width }}>
        {ProductInformationLoomLinks[informationKey].title ? (
          <h2 className="font-serif text-2xl text-primary-800 font-medium mb-4">
            {ProductInformationLoomLinks[informationKey].title}
          </h2>
        ) : null}
        {ProductInformationLoomLinks[informationKey].body ? (
          <p className="mb-4">{ProductInformationLoomLinks[informationKey].body}</p>
        ) : null}
        <div>
          <iframe
            title={informationKey}
            width={width}
            height="520"
            src={ProductInformationLoomLinks[informationKey].link}
            allowFullScreen
          />
        </div>
      </div>
    </>
  )
}
