/* eslint-disable max-classes-per-file */

/** Fields that an account can have hidden in the data product tables */
export const priceObservationFields = ["price", "volume"] as const
export type PriceObservationField = (typeof priceObservationFields)[number]

export type AccountDataPreferences = {
  /** Which fields, if any, are being excluded from data product tables for this user? */
  hiddenFields: PriceObservationField[]
  /** When were these preferences last modified? */
  date: Date
}

const accountDataPreferencesDefaults: AccountDataPreferences = {
  hiddenFields: [],
  date: new Date(),
}
export const createAccountDataPreferences = (a: Partial<AccountDataPreferences>) => ({
  ...accountDataPreferencesDefaults,
  ...a,
})
