import { CurrentUser } from "@model/CurrentUser"
import { viewOrderSourceAccountFields } from "common/model/Order/OrderSource"
import { OrderFormState } from "common/model/Order/OrderForm/State"
import { LIVE_UNTIL_DEFAULT_DAYS } from "common/model/Order/OrderConstants"
import moment from "moment"
import { useState, useCallback, useEffect, useMemo, useRef } from "react"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { useFirebaseWriter } from "src/firebase/Context"
import { DocumentReference } from "src/firebase/Firebase/utils"
import { handleConsoleError } from "src/utils/Tracking"
import { collections } from "common/firestore/Collections"
import { v4 as uuid } from "uuid"
import { Func } from "common/utils/fp/Function"
import { undefinedsToNulls } from "common/utils/ObjectUtils"
import { useFeatureFlag } from "src/providers/featureFlags/useFeatureFlags"
import { DocumentData } from "common/model/FirestoreConverter"
import { PartialOrderForm } from "common/model/Order/OrderForm/PartialOrderForm"
import { viewUserIdFields } from "common/model/User"

export const initialFormOrderState = (user: CurrentUser, initialFormOrder: OrderFormState) => ({
  liveUntil: moment().add(LIVE_UNTIL_DEFAULT_DAYS, "days").toDate(),
  ...(user.user.account.clientType.includes("Intermediary")
    ? {}
    : ({ clientRelationship: "direct" } satisfies OrderFormState)),
  account: viewOrderSourceAccountFields(user.user.account),
  user: viewUserIdFields(user.user),
  ...initialFormOrder,
})

const useLoggedState = <T, S = T>(init: T, doc: DocumentReference<S>, log: Func<T, S>) => {
  const [value, setValue] = useState(init)
  const promise = useRef(Promise.resolve())
  const onChange = useMemo(
    () => (t: T) => {
      setValue(t)
      promise.current = promise.current.then(() => doc.set(log(t)).catch(handleConsoleError))
    },
    [doc, log]
  )
  const flag = useFeatureFlag("order_form_logging")
  return useMemo(
    () => (flag ? ([value, onChange] as const) : ([value, setValue] as const)),
    [flag, value, onChange]
  )
}

const normalizeFormOrderState = (formOrderState: OrderFormState): OrderFormState => formOrderState

export const useFormOrderState = (
  initialFormOrder: OrderFormState,
  initialOrderId: string | undefined,
  deps: unknown[]
) => {
  const user = useLoggedInUser()
  const fb = useFirebaseWriter()
  const id = useMemo(() => uuid(), [])
  const partialDoc = useMemo(
    () => fb.db.collection(collections.partialOrderObservations).doc(id),
    [fb.db, id]
  )
  const serialize = useMemo(
    () => (x: OrderFormState) =>
      ({
        data: undefinedsToNulls(x),
        updatedAt: new Date(),
        user: user.user,
        formType: initialOrderId ? "edit" : "create",
        slackNotificationSent: false,
      } satisfies PartialOrderForm),
    [user, initialOrderId]
  )
  const [formOrderState, setFormOrder] = useLoggedState<OrderFormState, DocumentData>(
    initialFormOrderState(user, initialFormOrder),
    partialDoc,
    serialize
  )
  const formOrder = useMemo(() => normalizeFormOrderState(formOrderState), [formOrderState])
  const handleChange = useCallback(
    (value: OrderFormState, afterChange?: () => void) => {
      setFormOrder({ ...formOrder, ...value })
      afterChange?.()
    },
    [formOrder, setFormOrder]
  )

  // reset order form on dependencies change
  useEffect(() => {
    setFormOrder(initialFormOrderState(user, initialFormOrder))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  return { formOrder, handleChange }
}
