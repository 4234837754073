import { debounce } from "lodash"
import { NumberInput } from "@components/inputs"
import { FilterFnRecord, Variant } from "common/model/newsfeed/NewsFeed"

export const valuationAbove = (amt: number): FilterFnRecord => ({
  news: {
    function: (n) => (n.company?.valuation || 0) >= amt,
    asStrings: { field: "valuationAbove", value: amt.toString() },
  },
  funding_rounds: {
    function: () => true,
    asStrings: { field: "valuationAbove", value: amt.toString() },
  },
})

const fn =
  <V extends Variant>(variant: V) =>
  (
    debouncedValue: number | undefined,
    onEnable: (fn: FilterFnRecord[typeof variant] | undefined) => void
  ) => {
    if (debouncedValue && debouncedValue > 0) {
      onEnable(valuationAbove(debouncedValue * 1e9)[variant])
    } else onEnable(undefined)
  }

const debouncedOnAboveValuation = <V extends Variant>(variant: V) =>
  debounce(fn(variant), 200, { leading: false, trailing: true })

export function ValuationAbove<V extends Variant>({
  onEnable,
  variant,
  valueString,
}: {
  onEnable: (fn: FilterFnRecord[V] | undefined) => void
  variant: V
  valueString: string | null
}) {
  return (
    <div className="flex flex-row text-xs space-x-1 items-center">
      <div className="text-xs whitespace-nowrap ml-2">Valuation &ge;</div>
      <div className="h-8">
        <NumberInput
          size="small"
          className="text-xs w-16  focus:ring-primary-500 focus:border-neutral-400 block sm:text-sm border-neutral-400 rounded-md h-8 shadow-sm"
          name="filter-amount"
          value={valueString ? parseFloat(valueString) / 1e9 : undefined}
          id={`${variant}-filter-valuation`}
          onChange={(val) => {
            debouncedOnAboveValuation(variant)(val, onEnable)
          }}
          decimalPlaces={1}
          precision="decimal"
        />
      </div>
      <div className="text-xs whitespace-nowrap ml-2">$B</div>
    </div>
  )
}
