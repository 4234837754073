import { Just, Maybe, Nothing } from "../../../containers/Maybe"
import { Opportunity, OpportunityInteractionUser } from "../Opportunity"

const archiveReasons = [
  "direction",
  "price too high",
  "price too low",
  "size too high",
  "size too low",
  "structure",
] as const
export type ArchiveReason = (typeof archiveReasons)[number]

export type ArchivedOpportunity = Opportunity & {
  archivedBy: Opportunity["archivedBy"] & { active: true }
}
export const isOpportunityArchived = (
  opportunity: Opportunity
): opportunity is ArchivedOpportunity => opportunity.archivedBy?.active ?? false

export const opportunityArchivedAt = (opportunity: Opportunity): Maybe<Date> =>
  isOpportunityArchived(opportunity) ? Just(opportunity.archivedBy.asOf) : Nothing

export const archiveOpportunity = ({
  opportunity,
  archivedByUser,
  archivedAtDate,
  archiveReason,
}: {
  opportunity: Opportunity
  archivedByUser: OpportunityInteractionUser
  archivedAtDate: Date
  archiveReason: ArchiveReason[]
}): Opportunity =>
  !isOpportunityArchived(opportunity)
    ? {
        ...opportunity,
        archivedBy: {
          user: archivedByUser,
          asOf: archivedAtDate,
          tag: "archive",
          active: true,
          archiveReason,
        },
        interactionHistory: opportunity.archivedBy
          ? opportunity.interactionHistory.concat(opportunity.archivedBy)
          : opportunity.interactionHistory,
      }
    : opportunity

export const unArchiveOpportunity = ({
  opportunity,
  unArchivedByUser,
  unArchivedAtDate,
}: {
  opportunity: Opportunity
  unArchivedByUser: OpportunityInteractionUser
  unArchivedAtDate: Date
}): Opportunity =>
  isOpportunityArchived(opportunity)
    ? {
        ...opportunity,
        archivedBy: {
          user: unArchivedByUser,
          asOf: unArchivedAtDate,
          active: false,
          tag: "archive",
          archiveReason: [],
        },
        interactionHistory: opportunity.interactionHistory.concat(opportunity.archivedBy),
      }
    : opportunity
