import { formatPercentage } from "common/utils/math/format"

export type TopBrokerCriteriaKeys =
  | "participatedConnects"
  | "closedTradesSubmitted"
  | "liveOrders"
  | "refreshesAfterExpiration"
export type ValidationStatus = "none" | "not good" | "ok" | "good"
export type TopBrokerConfigProps = {
  type: TopBrokerCriteriaKeys
  title: string
  subtitle: (v: number) => string
  disclaimer?: string
  tooltip: string
  validator: (v: number) => ValidationStatus
}
export type TopBrokerCardProps = TopBrokerConfigProps & { value: number }

export const topBrokerCriteria: TopBrokerConfigProps[] = [
  {
    type: "participatedConnects",
    title: "Participate in Connects",
    subtitle: (v) => `You have participated in ${v} Connects.`,
    tooltip:
      "Initiate Connections on the Live Market or receive inbound Connect Requests on your Live Market Orders.",
    disclaimer: "Value may not be representative of all Connects counted towards Top Broker.",
    validator: (v) => (v < 4 ? "not good" : v < 10 ? "ok" : v >= 10 ? "good" : "none"),
  },
  {
    type: "closedTradesSubmitted",
    title: "Closed Trades",
    subtitle: (v) => `You have submitted ${v} closed trades.`,
    tooltip:
      "Submit your Closed Trades by filling out the form found on the Trade History Page or Contribute Page.",
    validator: (v) => (v < 1 ? "not good" : v < 2 ? "ok" : v >= 2 ? "good" : "none"),
  },
  {
    type: "liveOrders",
    title: "Maintain Live Orders",
    subtitle: (v) => `You have an average of ${Math.round(v)} Live Orders per day.`,
    tooltip: "Add Market Visible Orders to the Live Market.",
    validator: (v) => (v < 2 ? "not good" : v < 5 ? "ok" : v >= 5 ? "good" : "none"),
  },
  {
    type: "refreshesAfterExpiration",
    title: "Maintain Orders before Expiry",
    subtitle: (v) =>
      `You have renewed or expired orders prior to reaching expiration ${formatPercentage(
        1 - v
      )} of the time.`,
    tooltip: "Keep your orders up to date by renewing or expiring the order before it expires.",
    validator: (v) => (v > 0.9 ? "not good" : v > 0.5 ? "ok" : "good"),
  },
]
