import { NotificationArgProps, notification } from "@stories/components/Antd"
import { OrderFormSaveFunction } from "./contextHooks/useFormOrderSaving"
import { useCallback, useState } from "react"
import Typography, { Size, Weight } from "@stories/components/Typography/Typography"
import { Button } from "@stories/components/Button/Button"

const orderFormCloseDialogConfig = ({
  onCancelOrder,
  onReturnToOrder,
}: {
  onCancelOrder: () => void
  onReturnToOrder: () => void
}): NotificationArgProps => ({
  style: {
    width: 600,
  },
  duration: 0,
  placement: "top",
  key: "order-button-close-confirm",
  message: (
    <div className="flex flex-col gap-2">
      <div>
        <Typography weight={Weight.Bold} text="Your order has not been saved" size={Size.Large} />
      </div>
      <div>
        <Typography text="Are you sure you want to cancel?" />
      </div>
    </div>
  ),
  onClose: () => notification.close("order-button-close-confirm"),
  btn: (
    <div className="flex gap-2">
      <Button
        variant="secondary"
        label="Yes I'm Sure, Cancel"
        onClick={onCancelOrder}
        heapName="order-button-warning-close"
      />
      <Button
        variant="primary"
        label="Go Back"
        onClick={onReturnToOrder}
        heapName="order-button-warning-continue"
      />
    </div>
  ),
})

type UseOrderFormCloseConfirmProps = {
  onSave: OrderFormSaveFunction
  onClose: () => void
}
export const useOrderFormCloseConfirm = ({ onSave, onClose }: UseOrderFormCloseConfirmProps) => {
  const [saveState, setSaveState] = useState<"saved" | "saving" | "not-saved" | "unchanged">(
    "unchanged"
  )

  const handleSaveWithConfirmation: OrderFormSaveFunction = (p) => {
    setSaveState("saving")
    return onSave(p).finally(() => setSaveState("saved"))
  }

  const handleDrawerCloseWithConfirmation = () => {
    if (saveState === "not-saved") {
      const onCancelOrder = () => {
        setSaveState("unchanged")
        notification.close("order-button-close-confirm")
        onClose()
      }

      const onReturnToOrder = () => {
        setSaveState("not-saved")
        notification.close("order-button-close-confirm")
      }

      notification.warn(
        orderFormCloseDialogConfig({
          onCancelOrder,
          onReturnToOrder,
        })
      )
    } else {
      setSaveState("unchanged")
      onClose()
    }
  }

  const afterChange = useCallback(
    () => setSaveState((prev) => (prev === "unchanged" ? "not-saved" : prev)),
    []
  )

  return {
    handleSaveWithConfirmation,
    handleDrawerCloseWithConfirmation,
    afterChange,
  }
}
