/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
import { SearchInput } from "@components/inputs/SearchInput"
import { SwitchVerticalIcon } from "@heroicons/react/solid"
import DownChevronIcon from "@stories/icons/DownChevron"
import UpChevronIcon from "@stories/icons/UpChevronIcon"
import {
  ColumnFiltersState,
  ColumnOrderState,
  InitialTableState,
  OnChangeFn,
  Row,
  VisibilityState,
  getCoreRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { TableColumnFilter, TableColumnState, TableSort } from "common/model/Settings/TableSettings"
import { groupBy, throttle } from "lodash"
import { RefObject, useEffect, useMemo, useRef, useState } from "react"
import { classNames } from "src/utils/classNames"
import { Skeleton } from "../Antd"
import { Button } from "../Button/Button"
import HorizontalScrollContainer from "../HorizontalScrollContainer/HorizontalScrollContainer"
import Typography, { Color, Size, Weight } from "../Typography/Typography"
import { EmptyTableRow } from "./EmptyTableRow"
import { TableColumnConfigButton } from "./TableColumnConfigButton"
import { TableHeaderRow } from "./TableHeaderRow"
import { TablePagination } from "./TablePagination"
import { TableRow } from "./TableRow"
import { FilterPill } from "./filters/FilterPill"
import { FilterBarComponentRenderer } from "./filters/makeTableFilter"
import { DEFAULT_VARIANT, renderWithTypography, rowClassName } from "./helpers"
import { DataProps, FilterBarComponent, SortingProps, TableVariant } from "./types"
import FilterBarDropdownButton from "./FilterBarDropdownButton"
import { useViewport } from "src/utils/useViewport"

export type TableProps<TableData extends object> = DataProps<TableData> &
  SortingProps & {
    /*
     * Indicates whether to mask data from DataDog
     */
    shouldMaskDataFromDataDog?: boolean

    /*
     * Default number of rows per page for pagination
     */
    defaultPaginationPageSize?: number

    /*
     * Indicates if the table is in loading state
     */
    isLoading?: boolean

    /*
     * Callback function triggered when a row is clicked
     */
    onRowClick?: (row: Row<TableData>) => void

    /*
     * Indicates whether table columns have fixed width
     */
    isTableColumnsFixedWidth?: boolean

    /*
     * Text to display when table is empty
     */
    emptyStateText?: string

    /*
     * Name of the table records (plural)
     */
    nameOfTableRecords?: string

    /*
     * Name of a single table record
     */
    nameOfTableRecord?: string

    /*
     * Indicates whether pagination is enabled
     */
    isPaginationEnabled?: boolean

    /*
     * Indicates whether the first column should be sticky
     */
    isFirstColumnSticky?: boolean

    /*
     * Indicates whether filter header is enabled
     */
    isFilterHeaderEnabled?: boolean

    /*
     * Font size of table elements
     */
    fontSize?: Size

    /*
     * Variant of the table (e.g., bordered, striped)
     */
    variant?: TableVariant

    /*
     * Displays a global string search input in the filter bar.
     * Use `enableGlobalFilter: false` on any columns that should be ignored.
     */
    defaultGlobalFilter?: string

    /*
     * Configuration for global filter input
     */
    globalFilter?: {
      placeholder: string
      id?: string
      heapName?: string
    }

    /*
     * This isn't really ready to use broadly yet.
     * It requires a lot of repeated code and has clunky types.
     * A helper will be created soon to make this usable.
     */
    filterBarComponents?: FilterBarComponent[]

    /*
     * Components for advanced filtering
     */
    advancedFilterComponents?: FilterBarComponent[]

    /*
     * Additional components to render after filters
     */
    additionalPanelComponents?: React.ReactNode

    /*
     * A custom node that appears between headers and rows.
     * Must manually handle `<tr>` and `<td>`
     */
    beforeRows?: React.ReactNode

    /*
     * A custom node that appears between rows and pagination.
     * Must manually handle `<tr>` and `<td>`
     */
    afterRows?: React.ReactNode

    /*
     * Visibility of columns by id (false to hide, visible by default)
     */
    initialColumnVisibility?: VisibilityState

    /*
     * Is the header sticky?
     */
    isHeaderSticky?: boolean

    /*
     * Row Wrapper
     */
    rowWrapper?: (props: { rowData: TableData; children: React.ReactNode }) => React.ReactNode

    /*
     * Whether row can expand (accordion style)
     * If the row can expand, subcomponent
     */
    getRowCanExpand?: (row: Row<TableData>) => boolean
    renderSubComponent?: (props: { row: Row<TableData> }) => React.ReactElement
    onVisibleRowsChange?: (rows: Row<TableData>[]) => void
    showColumnConfigButton?: boolean
    onColumnStateChange?: (columnState: TableColumnState) => void
    initialColumnOrder?: ColumnOrderState
    initialColumnFilters?: TableColumnFilter[]
    textSize?: Size
    getHorizontalScrollDivRef?: (x: RefObject<HTMLDivElement>) => void
    onHorizontalScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void
    showColumnHover?: boolean
  }

const Table = <TableData extends object>({
  data,
  columns,
  shouldMaskDataFromDataDog,
  defaultPaginationPageSize = 10,
  isLoading = false,
  onRowClick,
  // TODO - implement fixed width cols that still allow for the entire table to perform horizontal scrolling
  isTableColumnsFixedWidth = false, // this doesn't work entirely correct yet, the table isn't able to scroll horizontally when this is true
  emptyStateText = "No data to display",
  nameOfTableRecord,
  nameOfTableRecords,
  isPaginationEnabled = true,
  isFirstColumnSticky = false,
  isFilterHeaderEnabled = false,
  defaultSortBy,
  defaultSortDirection = "desc",
  fontSize = Size.Small,
  enableSortingRemoval = false,
  enableMultiSort = true,
  variant = DEFAULT_VARIANT,
  getRowId,
  globalFilter,
  defaultGlobalFilter = "",
  filterBarComponents = [],
  advancedFilterComponents = [],
  beforeRows,
  afterRows,
  initialColumnVisibility = {},
  isHeaderSticky = false,
  rowWrapper,
  getRowCanExpand,
  renderSubComponent,
  onVisibleRowsChange,
  initialColumnOrder = [],
  showColumnConfigButton = false,
  onColumnStateChange,
  initialColumnFilters = [],
  additionalPanelComponents,
  textSize,
  getHorizontalScrollDivRef,
  onHorizontalScroll,
  showColumnHover = false,
}: TableProps<TableData>) => {
  const [rawHoveredColumn, rawSetHoveredColumn] = useState<string | null>(null)
  const setHoveredColumn = useMemo(
    () =>
      showColumnHover ? throttle((col: string | null) => rawSetHoveredColumn(col), 100) : null,
    [showColumnHover]
  )
  const hoveredColumn = showColumnHover ? rawHoveredColumn : null

  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(initialColumnOrder)
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(initialColumnVisibility)
  const [columnFilters, setColumnFilters] = useState<TableColumnFilter[]>(initialColumnFilters)
  const [columnSort, setColumnSort] = useState<TableSort[]>(
    defaultSortBy ? [{ id: defaultSortBy, desc: defaultSortDirection === "desc" }] : []
  )

  const initialState = useRef<InitialTableState>({
    pagination: { pageSize: defaultPaginationPageSize },
  })

  useEffect(() => {
    // debounce column state changes
    const timeout = setTimeout(
      () => onColumnStateChange?.({ columnOrder, columnVisibility, columnFilters, columnSort }),
      200
    )
    return () => clearTimeout(timeout)
  }, [columnFilters, columnOrder, columnVisibility, onColumnStateChange, columnSort])

  const table = useReactTable<TableData>({
    data,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    onSortingChange: setColumnSort,
    // NOTE: we only support strings in filters, so it's safe to assert here
    // eslint-disable-next-line rulesdir/no-assert
    onColumnFiltersChange: setColumnFilters as OnChangeFn<ColumnFiltersState>,
    getRowCanExpand,
    columns: columns.filter((col) => {
      if (col.meta?.disableColumnFn) {
        return !col.meta.disableColumnFn()
      }

      return true
    }),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: isPaginationEnabled ? getPaginationRowModel() : undefined,
    getSortedRowModel: getSortedRowModel(),
    getRowId,
    getGroupedRowModel: getGroupedRowModel(),
    enableSortingRemoval,
    enableMultiSort,
    initialState: initialState.current,
    state: {
      columnVisibility,
      columnOrder: columnOrder.length === 0 ? undefined : columnOrder,
      columnFilters,
      sorting: columnSort,
    },
    defaultColumn: {
      maxSize: undefined,
    },
  })

  const leafColumns = useMemo(() => table.getAllLeafColumns(), [table])

  // NOTE: fixes state of columnOrder if column ids ever change
  useEffect(() => {
    table.setColumnOrder((prev) => {
      const { startDefaultColumnIds, endDefaultColumnIds, otherDefaultColumnIds } =
        leafColumns.reduce<
          Record<
            "startDefaultColumnIds" | "endDefaultColumnIds" | "otherDefaultColumnIds",
            string[]
          >
        >(
          (acc, col) => {
            if (col.columnDef.meta?.disableCustomization === "start") {
              return { ...acc, startDefaultColumnIds: [...acc.startDefaultColumnIds, col.id] }
            }

            if (col.columnDef.meta?.disableCustomization === "end") {
              return { ...acc, endDefaultColumnIds: [...acc.endDefaultColumnIds, col.id] }
            }

            return { ...acc, otherDefaultColumnIds: [...acc.otherDefaultColumnIds, col.id] }
          },
          { startDefaultColumnIds: [], endDefaultColumnIds: [], otherDefaultColumnIds: [] }
        )

      const columnIdsThatExist = prev.filter(
        (id) =>
          !startDefaultColumnIds.includes(id) &&
          !endDefaultColumnIds.includes(id) &&
          table.getColumn(id)
      )

      const customizableMissingDefaultColumnsIds = otherDefaultColumnIds.filter(
        (id) => !columnIdsThatExist.includes(id)
      )

      return [
        ...startDefaultColumnIds,
        ...columnIdsThatExist,
        ...customizableMissingDefaultColumnsIds,
        ...endDefaultColumnIds,
      ]
    })
  }, [table, leafColumns])

  // NOTE: fixes state of columnFilters if column ids ever change
  useEffect(() => {
    table.setColumnFilters((prev) => {
      const defaultColumnFilters = leafColumns.flatMap((column) => {
        const colInPrev = prev.some((prevFilter) => prevFilter.id === column.id)
        if (colInPrev) {
          return []
        }
        return { id: column.id, value: undefined }
      })

      const columnFiltersThatExist = prev.filter(({ id }) => table.getColumn(id))
      return [...columnFiltersThatExist, ...defaultColumnFilters]
    })
  }, [table, leafColumns])

  // NOTE: fixes state of columnVisibility if column ids ever change
  useEffect(() => {
    table.setColumnVisibility((prev) =>
      Object.fromEntries(Object.entries(prev).filter(([id]) => table.getColumn(id)))
    )
  }, [table])

  const [globalFilterState, setGlobalFilterState] = useState(defaultGlobalFilter)

  // debounce table search
  useEffect(() => {
    const timeout = setTimeout(() => {
      table.setGlobalFilter(globalFilterState)
    }, 100)

    return () => clearTimeout(timeout)
  }, [globalFilterState, table])

  useEffect(() => {
    table.setGlobalFilter(defaultGlobalFilter)
  }, [defaultGlobalFilter, table])

  const rowsToDisplay = table.getRowModel().rows
  const numberOfColumns = table.getAllFlatColumns().length

  const isTableEmpty = rowsToDisplay.length === 0 && !isLoading

  useEffect(() => {
    onVisibleRowsChange?.(rowsToDisplay)
  }, [onVisibleRowsChange, rowsToDisplay])

  const indexesOfStartOfGroupColumn = table.getHeaderGroups()[0].headers.reduce(
    (
      acc: {
        indexes: number[]
        lastHeader: string | null | undefined
        pointer: number
      },
      header,
      index
    ) => {
      const isInGroup = header.subHeaders.length > 1
      const isStartOfGroup = isInGroup && header.id !== acc.lastHeader

      if (isStartOfGroup) {
        const newIndex = index + acc.pointer
        return {
          indexes: [...acc.indexes, newIndex],
          lastHeader: header.id,
          pointer: acc.pointer + header.colSpan - 1,
        }
      }

      return acc
    },
    { indexes: [], lastHeader: null, pointer: 0 }
  )

  const filteredColumns = table.getAllFlatColumns().filter((c) => c.getIsFiltered())
  const hasActiveFilters = filteredColumns.length > 0
  const showFilterBar = hasActiveFilters || globalFilter !== undefined

  const filterPills = useMemo(() => {
    const colMap = groupBy(filteredColumns, (column) => {
      const displayFilterState = column.columnDef.meta?.displayFilterState
      const filterState = column.getFilterValue()
      if (typeof filterState !== "string" && filterState !== undefined) {
        // should not be possible
        return null
      }
      return displayFilterState?.(filterState) ?? filterState
    })
    return Object.entries(colMap).map(([label, cols]) => (
      <FilterPill
        key={label}
        label={label}
        onClick={() => cols.forEach((col) => col.setFilterValue(undefined))}
      />
    ))
  }, [filteredColumns])

  const { width } = useViewport()
  const showFilterBarFiltersOnBar = width >= 1200

  return (
    <div className="flex flex-col">
      {showFilterBar ? (
        <div className="flex flex-col bg-neutral-white divide-y rounded-t">
          <div className="p-2 flex flex-col space-y-2 items-center lg:space-y-0 lg:space-x-2 lg:flex-row lg:justify-between">
            {globalFilter ? (
              <div className="w-72">
                <SearchInput
                  id={globalFilter?.id || "table-search-input"}
                  placeholder={globalFilter?.placeholder}
                  value={globalFilterState}
                  onChange={setGlobalFilterState}
                  heapName={globalFilter?.heapName || "table-search-filter"}
                  clearable
                />
              </div>
            ) : null}
            {showFilterBarFiltersOnBar
              ? filterBarComponents.map(({ component, columnId }) => (
                  <FilterBarComponentRenderer
                    key={columnId}
                    column={table.getColumn(columnId)}
                    component={component}
                    isOnFilterBar
                  />
                ))
              : null}
            {additionalPanelComponents ?? null}
            <div className="hidden lg:block lg:flex-grow" />
            {(showFilterBarFiltersOnBar ? [] : filterBarComponents).concat(advancedFilterComponents)
              .length ? (
              <FilterBarDropdownButton label="Filters" variant="filter">
                <div className="flex flex-col gap-4 p-4 max-w-175">
                  {advancedFilterComponents.map(({ component, columnId }) => (
                    <FilterBarComponentRenderer
                      key={columnId}
                      column={table.getColumn(columnId)}
                      component={component}
                    />
                  ))}
                </div>
              </FilterBarDropdownButton>
            ) : null}

            <FilterBarDropdownButton label="Sort" variant="sort">
              <div className="flex flex-col p-1">
                {table.getFlatHeaders().map((header) =>
                  header.column.getCanSort() ? (
                    <div
                      key={header.id}
                      className="hover:bg-neutral-300 transition transition-background rounded px-2 py-1 flex space-x-2 items-center justify-between whitespace-nowrap"
                      role="button"
                      tabIndex={0}
                      onClick={header.column.getToggleSortingHandler()}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          header.column.toggleSorting()
                        }
                      }}
                    >
                      <div>
                        {renderWithTypography(
                          (text) => (
                            <Typography
                              size={Size.XSmall}
                              weight={Weight.Semibold}
                              shouldWrap={false}
                              color={Color.Black}
                              text={text}
                            />
                          ),
                          header.getContext(),
                          header.column.columnDef.header
                        )}
                      </div>

                      <div
                        onClick={header.column.getToggleSortingHandler()}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            header.column.toggleSorting()
                          }
                        }}
                      >
                        {header.column.getIsSorted() === "asc" ? (
                          <UpChevronIcon />
                        ) : header.column.getIsSorted() === "desc" ? (
                          <DownChevronIcon />
                        ) : (
                          <span className="text-neutral-1000">
                            <SwitchVerticalIcon height={16} width={16} />
                          </span>
                        )}
                      </div>
                    </div>
                  ) : null
                )}
              </div>
            </FilterBarDropdownButton>

            {showColumnConfigButton ? (
              <TableColumnConfigButton
                getColumnHeaderNameById={(id) => table.getColumn(id)?.columnDef.header?.toString()}
                getColumnCanHideById={(id) => {
                  const col = table.getColumn(id)
                  if (col?.columnDef.meta?.disableCustomization) {
                    return false
                  }
                  return col?.getCanHide()
                }}
                getColumnDisableCustomizationById={(id) =>
                  table.getColumn(id)?.columnDef.meta?.disableCustomization
                }
                columnOrder={columnOrder}
                setColumnOrder={setColumnOrder}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
              />
            ) : null}
          </div>
          <div
            className={classNames("flex items-center p-2", hasActiveFilters && "justify-between")}
          >
            {hasActiveFilters ? (
              <>
                <Typography
                  text="Filters"
                  color={Color.Subtitle}
                  size={Size.XSmall}
                  weight={Weight.Semibold}
                />
                <div className="flex flex-grow ml-2 space-x-2 overflow-x-auto h-6 items-center">
                  {filterPills}
                </div>
                <Button
                  variant="hollow-link"
                  onClick={() => table.resetColumnFilters()}
                  label="Clear"
                  size="xs"
                />
              </>
            ) : (
              <div className="leading-6">
                <Typography
                  text="No filters active"
                  color={Color.Subtitle}
                  size={Size.XSmall}
                  weight={Weight.Regular}
                />
              </div>
            )}
          </div>
        </div>
      ) : null}
      <HorizontalScrollContainer
        className={classNames(
          isHeaderSticky && "overflow-y-auto max-h-100vh",
          isFilterHeaderEnabled ? "rounded-b" : "rounded",
          "w-full border relative z-0"
        )}
        shouldMaskDataFromDataDog={shouldMaskDataFromDataDog}
        getRef={getHorizontalScrollDivRef}
        onScroll={onHorizontalScroll}
      >
        <table
          className={`bg-neutral-white w-full border-spacing-0 border-collapse animate-fade ${
            isTableColumnsFixedWidth ? "table-fixed" : "table-auto"
          }`}
          onMouseLeave={() => setHoveredColumn?.(null)}
        >
          <thead className={`bg-neutral-300 ${isHeaderSticky ? "sticky z-10 top-0" : ""}`}>
            <TableHeaderRow
              table={table}
              isFirstColumnSticky={isFirstColumnSticky}
              hoveredColumn={hoveredColumn}
              setHoveredColumn={setHoveredColumn}
            />
          </thead>
          <tbody>
            {beforeRows}
            {isTableEmpty && (
              <EmptyTableRow numberOfColumns={numberOfColumns} emptyStateText={emptyStateText} />
            )}

            {isLoading &&
              Array.from({ length: defaultPaginationPageSize }).map((_, i) => (
                <tr key={i} className={rowClassName(false, isPaginationEnabled, { variant })}>
                  {Array.from({ length: numberOfColumns }).map((__, idx) => (
                    <td key={idx} className="whitespace-nowrap px-2">
                      <div className="p-1 m-1">
                        <Skeleton active paragraph={false} style={{ height: "8px" }} />
                      </div>
                    </td>
                  ))}
                </tr>
              ))}

            {!isLoading &&
              rowsToDisplay.map((row, i) => (
                <TableRow
                  key={row.id}
                  row={row}
                  index={i}
                  onRowClick={onRowClick}
                  variant={variant}
                  isFirstColumnSticky={isFirstColumnSticky}
                  indexesOfStartOfGroupColumn={indexesOfStartOfGroupColumn}
                  isPaginationEnabled={isPaginationEnabled}
                  rowWrapper={rowWrapper}
                  renderSubComponent={renderSubComponent}
                  textSize={textSize}
                  hoveredColumn={hoveredColumn}
                  setHoveredColumn={setHoveredColumn}
                />
              ))}
            {afterRows}
          </tbody>
        </table>
        {!isTableEmpty && isPaginationEnabled && (
          <div className="sticky bottom-0 left-0 bg-neutral-200 border-t">
            <TablePagination
              table={table}
              nameOfTableRecord={nameOfTableRecord}
              nameOfTableRecords={nameOfTableRecords}
              data={data}
              numberOfRecordsInTable={data.length}
              fontSize={fontSize}
            />
          </div>
        )}
      </HorizontalScrollContainer>
    </div>
  )
}

export default Table
