import CompanyLogoAndName from "@components/CompanyLogoAndName"
import { AccessBoundary } from "@components/auth/DataAccess"
import {
  NumberField,
  sizeScaledInputWarning,
  valuationScaledInputWarning,
} from "@stories/components/Inputs/NumberInput/NumberField"
import { PercentageField } from "@stories/components/Inputs/NumberInput/PercentageField"
import { Size, Typography } from "@stories/components/Typography/Typography"
import { isIntermediaryAccount, isInvestorShareholderAccount } from "common/model/Account"
import { viewCompanyIdFields } from "common/model/Company"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import {
  DealCRMInterest,
  DealCRMInterestShareClass,
  DealCRMInterestStructure,
  DealCRMInterestWithOrigin,
  ManagementFeeStructure,
  isInterestCancelled,
  managementFeeStructures,
} from "common/model/DealCRM/DealCRMInterest"
import { DealCRMNoteInterestSource } from "common/model/DealCRM/DealCRMNote"
import { displayDealCRMInterestStructure } from "common/model/DealCRM/utils/displayDealCRMInterestStructure"
import { splitPricePerShareFromValuationInformation } from "common/model/data-product/ppsCalculation/PricePerShare"
import { getValuationFromTheDaysSplitAdjustedData } from "common/model/data-product/valuationCalculation/Valuation"
import { isLoaded, isLoading } from "common/utils/Loading"
import { Interval } from "common/utils/data/Interval"
import { articulatedDateTimeFormat } from "common/utils/dateUtils"
import {
  formatAbbreviatedCurrency,
  formatPercentage,
  formatRoundedAbbreviatedCurrency,
} from "common/utils/math/format"
import { capitalize, isNil } from "lodash"
import { ReactNode } from "react"
import { useFirebaseWriter } from "src/firebase/Context"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import { updateContactInterest } from "src/firebase/crm"
import { SelectField } from "src/pages/CRM/Components/DealParticipantFieldDisplay/DealParticipantFieldDisplay"
import StageDisplay from "src/pages/CRM/Components/StageDisplay/StageDisplay"
import { NotesColumn } from "src/pages/CRM/Deals/NotesColumn"
import InterestActionDropdownMenu from "src/pages/CRM/Interest/InterestActionDropdownMenu/InterestActionDropdownMenu"
import InterestVisibilityStatus from "src/pages/CRM/Interest/InterestVisibilityStatus"
import { useCRMContacts } from "src/pages/CRM/Providers/CRMContactsProvider"
import { useCompany } from "src/pages/Data/Hooks"
import { userCanCreateOrderFromCRM } from "src/pages/Orders/shared/permissions"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import { trackEventInFirestoreAndHeap } from "src/utils/Tracking"
import { classNames } from "src/utils/classNames"
import { CRMContactName } from "../CRMContactName"
import { CRMInterestOrderOriginationDateField } from "src/pages/CRM/Interest/InterestCells"

interface InterestRowProps {
  side: "buy" | "sell"
  contact: DealCRMContact
  interest: DealCRMInterestWithOrigin
}

export const InterestRow = ({ interest, contact, side }: InterestRowProps) => {
  const db = useFirebaseWriter()
  const firebase9 = useFirebase9()
  const user = useCurrentUser()

  const { findContactById } = useCRMContacts()

  const originContact =
    interest.originId === contact.id ? contact : findContactById(interest.originId)

  const company = useCompany(interest.company.id)

  if (isLoading(company) || isLoading(user) || !user || company === null) {
    return null
  }

  const { account } = user.user

  const handleInterestEdit = (interestUpdate: Partial<DealCRMInterest>) => {
    if (isLoaded(user)) {
      return updateContactInterest({
        user: user.user,
        db,
        interestUpdate: {
          ...interest,
          ...interestUpdate,
        },
      }).then(() =>
        trackEventInFirestoreAndHeap(
          firebase9,
          user.user,
          "crm-contact-indication-of-interest-updated",
          { direction: side }
        )
      )
    }
    return Promise.resolve()
  }

  const valFromSharePrice = (sharePrice: number | null) => {
    if (sharePrice && isLoaded(company)) {
      const valuation = getValuationFromTheDaysSplitAdjustedData(sharePrice, new Date(), company)
      return valuation.map((v) => v.valuation).withUnconstrainedDefault(null)
    }
    return null
  }

  const priceFromValuation = (valuation: number | null) => {
    if (valuation && isLoaded(company)) {
      const price = splitPricePerShareFromValuationInformation(
        {
          valuation,
          valuationDate: new Date(),
        },
        company,
        new Date()
      )
      return price.map((p) => p.pricePerShare).withUnconstrainedDefault(null)
    }
    return null
  }

  const matchCancelledStatus = (ifCancelled: ReactNode | string, ifNotCancelled: ReactNode) =>
    isInterestCancelled(interest) ? (
      <span className="text-xs px-2">{ifCancelled}</span>
    ) : (
      ifNotCancelled
    )

  const source: DealCRMNoteInterestSource = {
    sourceId: interest.id,
    interestDirection: side,
    sourceType: "interest",
    company: viewCompanyIdFields(company),
    contact,
  }

  const totalFeeString =
    interest.amountUSD?.lowerBound && interest.commissionPercent
      ? formatAbbreviatedCurrency(interest.amountUSD.lowerBound * interest.commissionPercent)
      : ""

  return (
    <tr
      className={classNames(
        "border-b last:border-b-0 h-8",
        isInterestCancelled(interest) ? "bg-neutral-300" : "bg-neutral-white"
      )}
    >
      <td className="whitespace-nowrap px-2 border-r">
        <AccessBoundary accessLevel="hideFromCaplight">
          <CompanyLogoAndName
            company={interest.company}
            size="xs"
            truncateLength={20}
            shouldMaskDataInDataDog
          />
        </AccessBoundary>
      </td>

      {(contact.tag === "individual" || contact.tag === "firm") && (
        <td className="whitespace-nowrap px-2 border-r">
          <CRMContactName contact={originContact} isIconDisplayed={false} />
        </td>
      )}

      <td className="whitespace-nowrap px-2 border-r">
        <NotesColumn source={source} accountId={user?.user.account.id ?? ""} />
      </td>

      {userCanCreateOrderFromCRM(user) ? (
        <td className="whitespace-nowrap px-2 border-r text-xs">
          <InterestVisibilityStatus interest={interest} />
        </td>
      ) : null}

      <td className="whitespace-nowrap border-r cursor-pointer">
        <StageDisplay
          noBorder
          onChange={(stage) => handleInterestEdit({ stage })}
          value={interest.stage ?? null}
          editable
        />
      </td>

      <td className="whitespace-nowrap border-r">
        {matchCancelledStatus(
          interest.targetPrice ? formatAbbreviatedCurrency(interest.targetPrice) : "",
          <NumberField
            noBorder
            value={interest.targetPrice}
            onChange={(targetPrice) =>
              handleInterestEdit({
                targetPrice: targetPrice ?? null,
                targetValuation: valFromSharePrice(targetPrice ?? null),
              })
            }
            formatter={(v) => `${formatAbbreviatedCurrency(v)}`}
          />
        )}
      </td>

      <td className="whitespace-nowrap border-r">
        {matchCancelledStatus(
          interest.targetValuation
            ? formatRoundedAbbreviatedCurrency(interest.targetValuation)
            : "",
          <NumberField
            noBorder
            value={interest.targetValuation}
            onChange={(targetValuation) =>
              handleInterestEdit({
                targetValuation: targetValuation ?? null,
                targetPrice: priceFromValuation(targetValuation ?? null),
              })
            }
            formatter={(v) => `${formatRoundedAbbreviatedCurrency(v)}`}
            inputWarningFunction={valuationScaledInputWarning}
            tooltipHintText="Hint: use '2.5b' for 2.5 billion"
          />
        )}
      </td>

      <td className="whitespace-nowrap border-r">
        {matchCancelledStatus(
          interest.amountUSD?.lowerBound
            ? formatAbbreviatedCurrency(interest.amountUSD.lowerBound)
            : "",
          <NumberField
            noBorder
            value={interest.amountUSD?.lowerBound}
            onChange={(amount) =>
              handleInterestEdit({
                amountUSD: amount ? Interval.pure(amount) : null,
              })
            }
            formatter={(v) => `${formatAbbreviatedCurrency(v)}`}
            inputWarningFunction={sizeScaledInputWarning}
            tooltipHintText="Hint: use '1m' for 1 million"
          />
        )}
      </td>

      {isIntermediaryAccount(account) && (
        <>
          <td className="whitespace-nowrap border-r">
            {matchCancelledStatus(
              isNil(interest.commissionPercent) ? "" : formatPercentage(interest.commissionPercent),
              <PercentageField
                noBorder
                value={interest.commissionPercent}
                onChange={(commission) =>
                  handleInterestEdit({
                    commissionPercent: commission,
                  })
                }
              />
            )}
          </td>

          {/* <td className="whitespace-nowrap border-r">
            {matchCancelledStatus(
              isNil(interest.managementFeePercent)
                ? ""
                : formatPercentage(interest.managementFeePercent),
              <PercentageField
                noBorder
                value={interest.managementFeePercent}
                onChange={(managementFeePercent) =>
                  handleInterestEdit({
                    managementFeePercent,
                  })
                }
              />
            )}
          </td> */}

          <td className="whitespace-nowrap border-r">
            {matchCancelledStatus(totalFeeString, totalFeeString)}
          </td>
        </>
      )}
      {side === "buy" && isInvestorShareholderAccount(account) && (
        <>
          <td className="whitespace-nowrap px-2 border-r cursor-pointer">
            <SelectField<ManagementFeeStructure | null>
              size="small"
              value={interest.managementFeeStructure}
              options={[...managementFeeStructures]}
              renderValue={(v) => (
                <Typography size={Size.XSmall} text={capitalize(v ?? undefined)} />
              )}
              handleChange={(feeStructure) =>
                handleInterestEdit({
                  managementFeeStructure: feeStructure,
                })
              }
              editable={!isInterestCancelled(interest)}
            />
          </td>
          <td className="whitespace-nowrap border-r">
            {matchCancelledStatus(
              isNil(interest.managementFeePercent)
                ? ""
                : formatPercentage(interest.managementFeePercent),
              <PercentageField
                noBorder
                value={interest.managementFeePercent}
                onChange={(managementFeePercent) =>
                  handleInterestEdit({
                    managementFeePercent,
                  })
                }
              />
            )}
          </td>
          <td className="whitespace-nowrap border-r min-w-20">
            {matchCancelledStatus(
              isNil(interest.carryPercent) ? "" : formatPercentage(interest.carryPercent),
              <PercentageField
                noBorder
                value={interest.carryPercent}
                onChange={(carry) =>
                  handleInterestEdit({
                    carryPercent: carry,
                  })
                }
              />
            )}
          </td>
        </>
      )}
      <td className="whitespace-nowrap px-2 border-r cursor-pointer">
        <SelectField<DealCRMInterestStructure | null>
          size="small"
          value={interest.structure}
          options={["direct", "spv", "forward"]}
          renderValue={(s) => (
            <Typography size={Size.XSmall} text={s ? displayDealCRMInterestStructure(s) : null} />
          )}
          handleChange={(structure) =>
            handleInterestEdit({
              structure,
            })
          }
          editable={!isInterestCancelled(interest)}
        />
      </td>
      <td className="whitespace-nowrap px-2 border-r cursor-pointer">
        <SelectField<DealCRMInterestShareClass | null>
          size="small"
          value={interest.shareClass}
          options={["common", "preferred"]}
          renderValue={(v) => <Typography size={Size.XSmall} text={capitalize(v ?? undefined)} />}
          handleChange={(shareClass) =>
            handleInterestEdit({
              shareClass,
            })
          }
          editable={!isInterestCancelled(interest)}
        />
      </td>
      <td className="whitespace-nowrap px-2 border-r text-xs">
        {articulatedDateTimeFormat(interest.lastUpdatedAt).date}
      </td>
      <td className="whitespace-nowrap px-2 border-r text-xs">
        <CRMInterestOrderOriginationDateField db={db} crmInterest={interest} currentUser={user} />
      </td>
      <td className="whitespace-nowrap px-2 cursor-pointer border-r-0">
        <InterestActionDropdownMenu interest={interest} user={user.user} />
      </td>
    </tr>
  )
}
