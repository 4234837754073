import { LinkedBroker } from "common/model/LinkedBroker"
import BrokerSearch from "./BrokerSearchLinkedBroker"
import InviteLinkedBroker, { InvitedBroker } from "./InviteLinkedBroker"
import { Button } from "@stories/components/Button/Button"
import { XIcon } from "@stories/icons/XIcon"

export const BrokerItem = ({ onClick, name }: { onClick?: () => void; name: string }) => (
  <Button label={name} variant="neutral" onClick={onClick} rightIcon={onClick ? <XIcon /> : null} />
)

export const AddLinkedBroker: React.FC<{
  selectedBrokers: LinkedBroker[]
  allBrokers: LinkedBroker[]
  setSelectedBrokers: React.Dispatch<React.SetStateAction<LinkedBroker[]>>
  invitedBrokers: InvitedBroker[]
  addInvitedBroker: (b: InvitedBroker) => void
  removeInvitedBroker: (b: InvitedBroker) => void
}> = ({
  allBrokers,
  selectedBrokers,
  setSelectedBrokers,
  invitedBrokers,
  addInvitedBroker,
  removeInvitedBroker,
}) => {
  const addSelectedBroker = (b: LinkedBroker) => setSelectedBrokers((prev) => prev.concat(b))
  const removeSelectedBroker = (b: LinkedBroker) =>
    setSelectedBrokers((prev) => prev.filter((cur) => cur.id !== b.id))
  return (
    <div className="flex flex-col space-y-4">
      <BrokerSearch
        addSelectedBroker={addSelectedBroker}
        selectedBrokers={selectedBrokers}
        allBrokers={allBrokers}
      />
      <div className="flex flex-wrap space-x-2">
        {selectedBrokers.map((b) => (
          <BrokerItem key={b.id} onClick={() => removeSelectedBroker(b)} name={b.fullName} />
        ))}
      </div>
      <InviteLinkedBroker addInvitedBroker={addInvitedBroker} />
      <div className="flex flex-wrap space-x-2">
        {invitedBrokers.map((b) => (
          <BrokerItem key={b.email} onClick={() => removeInvitedBroker(b)} name={b.fullName} />
        ))}
      </div>
    </div>
  )
}
