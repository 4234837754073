import { DropdownMenuItem } from "@stories/components/DropdownMenu/DropdownMenu"
import DropdownMenuButton, {
  DropdownMenuButtonProps,
} from "@stories/components/DropdownMenu/DropdownMenuButton"
import { DarkpoolIcon } from "@stories/icons/DarkpoolIcon"
import { UploadIcon } from "@stories/icons/UploadIcon"
import {
  buildFormOrderFromCRMInterest,
  createOrderFromForm,
  inferClientRelationshipFromContact,
} from "common/model/Order/OrderForm/Constructors"
import { isLoaded } from "common/utils/Loading"
import { useMemo, useState } from "react"
import { useFirebaseWriter } from "src/firebase/Context"
import { addOrder } from "src/firebase/orders"
import OrderFormDrawer from "src/pages/Orders/OrderForm/OrderFormDrawer"
import { orderFormDrawerTitle } from "src/pages/Orders/OrderForm/constants"
import { OrderFormSaveFunction } from "src/pages/Orders/OrderForm/contextHooks/useFormOrderSaving"
import { userCanCreateOrderFromCRM } from "src/pages/Orders/shared/permissions"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import { ButtonVariant } from "@stories/components/Button/Button"
import {
  buildDarkpoolVisibilityTooltip,
  buildMarketVisibilityTooltip,
} from "@components/OrderFormComponents/useFormOrderLabels"
import { DealCRMInterest, isInterestCancelled } from "common/model/DealCRM/DealCRMInterest"
import { useCRMContacts } from "../../Providers/CRMContactsProvider"
import { Either, Left, Right } from "common/containers/Either"
import { ShareIcon } from "@stories/icons/ShareIcon"
import { useMultipleSharedOrderWorkflow } from "@components/ShareOrder/ShareOrderButton"

export type PublishCRMInterestButtonMenuProps = {
  interest: DealCRMInterest
  variant?: ButtonVariant
  align?: DropdownMenuButtonProps["align"]
  size?: "sm" | "md"
  isPublishButtonDisplayed?: boolean
}

const disallowedReasons = [
  "interest-cancelled",
  "user-cannot-create-orders",
  "contact-not-found",
  "indirect-client",
] as const
type DisallowedReason = (typeof disallowedReasons)[number]
export const usePublishCRMInterestItems = (
  interest: PublishCRMInterestButtonMenuProps["interest"]
): Either<
  DisallowedReason,
  {
    isDarkpoolFormOpen: boolean
    isLiveMarketFormOpen: boolean
    closeLiveMarketForm: () => void
    closeDarkpoolForm: () => void
    formOrderFromCRMInterest: ReturnType<typeof buildFormOrderFromCRMInterest>
    onSaveOrderForm: OrderFormSaveFunction
    items: DropdownMenuItem[]
    sharedOrderDrawer: JSX.Element
  }
> => {
  const firebaseWriter = useFirebaseWriter()

  const [isLiveMarketFormOpen, setIsLiveMarketFormOpen] = useState(false)
  const [isDarkpoolFormOpen, setIsDarkpoolFormOpen] = useState(false)
  const { drawer: sharedOrderDrawer, handleOpenShareWorkflow } = useMultipleSharedOrderWorkflow({
    items: [{ tag: "crm_interest", crmInterest: interest, id: interest.id }],
  })

  const { findContactById } = useCRMContacts()
  const currentUser = useCurrentUser()

  return useMemo(() => {
    const contact = interest.contact ? findContactById(interest.contact.id) : null

    const userAllowedToCreateOrder = isLoaded(currentUser) && userCanCreateOrderFromCRM(currentUser)

    if (isInterestCancelled(interest)) {
      return Left("interest-cancelled")
    }
    if (!userAllowedToCreateOrder) {
      return Left("user-cannot-create-orders")
    }

    if (contact && inferClientRelationshipFromContact(contact) === "indirect") {
      return Left("indirect-client")
    }

    const formOrderFromCRMInterest = buildFormOrderFromCRMInterest(interest, contact)

    const onSaveOrderForm: OrderFormSaveFunction = (formOrder) =>
      addOrder(createOrderFromForm(formOrder, {}), firebaseWriter, {
        updateExistingCRMInterestTerms: formOrder.updateExistingCRMInterestTerms,
      })

    const addToLiveMarketItem: DropdownMenuItem = {
      icon: <UploadIcon />,
      id: "Add to Live Market",
      label: "Add to Live Market",
      tooltip: buildMarketVisibilityTooltip(interest.direction),
      onClick: () => {
        setIsLiveMarketFormOpen(true)
      },
      variant: "accent",
      heapName: "add-crm-interest-to-live-market",
    }

    const addToDarkpoolItem: DropdownMenuItem = {
      icon: <DarkpoolIcon />,
      id: "Add to Darkpool",
      label: "Add to Darkpool",
      tooltip: buildDarkpoolVisibilityTooltip(interest.direction),
      onClick: () => {
        setIsDarkpoolFormOpen(true)
      },
      variant: "accent",
      heapName: "add-crm-interest-to-live-market",
    }
    const addToMyNetwork: DropdownMenuItem = {
      icon: <ShareIcon />,
      id: "Share to My Network",
      label: "Share to My Network",
      tooltip: "",
      onClick: handleOpenShareWorkflow,
      variant: "accent",
      heapName: "add-crm-interest-to-my-network",
    }

    const items = [addToMyNetwork, addToLiveMarketItem, addToDarkpoolItem]
    const closeDarkpoolForm = () => setIsDarkpoolFormOpen(false)
    const closeLiveMarketForm = () => setIsLiveMarketFormOpen(false)
    return Right({
      items,
      isLiveMarketFormOpen,
      isDarkpoolFormOpen,
      formOrderFromCRMInterest,
      onSaveOrderForm,
      closeDarkpoolForm,
      closeLiveMarketForm,
      sharedOrderDrawer,
    })
  }, [
    interest,
    findContactById,
    currentUser,
    handleOpenShareWorkflow,
    isLiveMarketFormOpen,
    isDarkpoolFormOpen,
    firebaseWriter,
    sharedOrderDrawer,
  ])
}

export const PublishCRMInterestButtonMenu = ({
  interest,
  variant = "secondary",
  align,
}: PublishCRMInterestButtonMenuProps) =>
  usePublishCRMInterestItems(interest).match(
    (disallowedReason) =>
      disallowedReason === "indirect-client" ? (
        <DropdownMenuButton
          variant={variant}
          label="Publish"
          isFullWidth
          align={align}
          menuItems={[]}
          isDisabled
          tooltipTitle="You cannot publish other broker's orders to the Live Market"
        />
      ) : null,
    ({
      isDarkpoolFormOpen,
      isLiveMarketFormOpen,
      closeLiveMarketForm,
      closeDarkpoolForm,
      formOrderFromCRMInterest,
      onSaveOrderForm,
      items,
      sharedOrderDrawer,
    }) => (
      <>
        <DropdownMenuButton
          variant={variant}
          label="Publish"
          isFullWidth
          align={align}
          menuItems={items}
        />

        {isLiveMarketFormOpen ? (
          <OrderFormDrawer
            open={isLiveMarketFormOpen}
            initialFormOrder={{
              ...formOrderFromCRMInterest,
              darkpool: false,
            }}
            onClose={closeLiveMarketForm}
            onSave={onSaveOrderForm}
            drawerTitle={orderFormDrawerTitle("market")}
          />
        ) : null}
        {isDarkpoolFormOpen ? (
          <OrderFormDrawer
            open={isDarkpoolFormOpen}
            initialFormOrder={{ ...formOrderFromCRMInterest, darkpool: true }}
            onClose={closeDarkpoolForm}
            onSave={onSaveOrderForm}
            drawerTitle={orderFormDrawerTitle("darkpool")}
          />
        ) : null}
        {sharedOrderDrawer}
      </>
    )
  )
