import { collections } from "common/firestore/Collections"
import { DeepPartialIntroduction } from "common/model/IntroductionRequest"
import { FirebaseWriter } from "src/firebase/Firebase"
import { handleConsoleError, trackEvent } from "src/utils/Tracking"
import { IntroductionRequestButtonProps } from "."

export const saveOrUpdateIntroductionRequest = async (
  introductionRequest: DeepPartialIntroduction,
  firebase: FirebaseWriter,
  metadata?: {
    sourceComponent?: IntroductionRequestButtonProps["sourceComponent"]
  }
) => {
  if (introductionRequest.id) {
    return firebase.db
      .collection(collections.introductionRequest)
      .doc(introductionRequest.id)
      .set(introductionRequest)
      .catch(handleConsoleError)
  } else {
    trackEvent("intro-request-submitted", {
      companyId: introductionRequest.company?.id,
      companyName: introductionRequest.company?.name,
      orderDirection: introductionRequest.data?.direction,
      sourceObjectType: introductionRequest.priceObservationId?.type,
      sourceObjectId: introductionRequest.priceObservationId?.id,
      sourceComponent: metadata?.sourceComponent,
    })
    return firebase.db
      .collection(collections.introductionRequest)
      .add(introductionRequest)
      .catch(handleConsoleError)
  }
}
