import { v4 as uuidv4 } from "uuid"
import { isStructuredConnectMessage, isStructuredConnectMessageResponse } from "../Effects"
import { UnsentConnectMessage, ConnectMessage } from "../Message"
import { ConnectMessageAuthor } from "../Message/Author"
import { StructuredMessageType, _StructuredConnectMessageResponse } from "../MessageTypes/StructuredMessage"
import { ThreadType } from "../ThreadTypes/ThreadType"

export abstract class ThreadMessageCoordinator<T extends ThreadType> {
  protected abstract writeNewMessageDocument: (
    message: UnsentConnectMessage<T>
  ) => Promise<ConnectMessage<T>>

  protected abstract recordUserSentMessage: (author: ConnectMessageAuthor) => Promise<void>

  protected abstract recordMessageResponse: <K extends StructuredMessageType>(
    message: _StructuredConnectMessageResponse<K>
  ) => Promise<void>

  protected abstract dispatchMessageEffects: (message: ConnectMessage<T>) => Promise<void>

  sendMessageToThread = async (
    message: UnsentConnectMessage<T>,
    emailNotifications: boolean,
    thread: { id: string }
  ) => {
    const id = uuidv4()
    const toSend = {
      ...message,
      id,
      date: new Date(),
      threadId: thread.id,
      emailNotifications,
      ...(isStructuredConnectMessage(message) ? { responses: [] } : {}),
    }

    const writtenMessage = await this.writeNewMessageDocument(toSend)
    await this.dispatchMessageEffects(writtenMessage)
    await this.recordUserSentMessage(writtenMessage.author)
    if (isStructuredConnectMessageResponse(writtenMessage)) {
      await this.recordMessageResponse(writtenMessage)
    }
  }
}
