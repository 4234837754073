import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { AccountActivityLogEvent } from "common/model/AccountActivityLog/AccountActivityLog"
import { UserFunctions } from "common/model/User"
import { articulatedRelativeDateAndTimeFormat } from "common/utils/dateUtils"
import { assertUnreachable } from "common/utils/fp/Function"
import { CRMContactAddedToDealDetails } from "./CRMContactAddedToDealDetails"
import { CRMContactCreatedEventCardDetails } from "./CRMContactCreatedEventCardDetails"
import { CRMContactUpdatedEventCardDetails } from "./CRMContactUpdatedEventCardDetails"
import { ConnectRequestReceivedDetails } from "./ConnectRequestReceivedDetails"
import { ConnectRequestSubmittedDetails } from "./ConnectRequestSubmittedDetails"
import { ContactBuySellInterestAddedOrUpdatedEventCardDetails } from "./ContactBuySellInterestAddedOrUpdatedEventCardDetails"
import { DarkpoolOrderPlacedDetails } from "./DarkpoolOrderPlacedDetails"
import { DarkpoolOrderRenewedDetails } from "./DarkpoolOrderRenewedDetails"
import { DarkpoolOrderUpdatedDetails } from "./DarkpoolOrderUpdatedDetails"
import { DealCreatedEventCardDetails } from "./DealCreatedEventCardDetails"
import { LiveMarketOrderPlacedDetails } from "./LiveMarketOrderPlacedDetails"
import { LiveMarketOrderRenewedDetails } from "./LiveMarketOrderRenewedDetails"
import { LiveMarketOrderUpdatedDetails } from "./LiveMarketOrderUpdatedDetails"
import { NoteAddedEventCardDetails } from "./NoteAddedEventCardDetails"
import { OrderInquiryReceivedDetails } from "./OrderInquiryReceivedDetails"
import { OrderInquirySubmittedDetails } from "./OrderInquirySubmittedDetails"

export const ActivityLogEventCard = ({ event }: { event: AccountActivityLogEvent }) => (
  <div key={event.id} className="p-4 border rounded flex flex-col gap-4">
    <div className="flex justify-between gap-4">
      <Typography size={Size.XSmall} color={Color.Subtitle} text={UserFunctions.name(event.user)} />
      <Typography
        size={Size.XSmall}
        color={Color.Subtitle}
        text={articulatedRelativeDateAndTimeFormat(event.eventTime)}
      />
    </div>
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between gap-2 w-full">
        <Typography weight={Weight.Semibold} color={Color.Dark} text={getEventName(event)} />
      </div>
      <ActivityLogEventCardDetails event={event} />
    </div>
  </div>
)

const ActivityLogEventCardDetails = ({ event }: { event: AccountActivityLogEvent }) => {
  switch (event.eventType) {
    case "contact_created":
      return <CRMContactCreatedEventCardDetails event={event} />
    case "contact_updated":
      return <CRMContactUpdatedEventCardDetails event={event} />
    case "note_added":
      return <NoteAddedEventCardDetails event={event} />
    case "deal_created":
      return <DealCreatedEventCardDetails event={event} />
    case "contact_buy_sell_interest_added":
      return <ContactBuySellInterestAddedOrUpdatedEventCardDetails event={event} />
    case "contact_buy_sell_interest_updated":
      return <ContactBuySellInterestAddedOrUpdatedEventCardDetails event={event} />
    case "contact_added_to_deal":
      return <CRMContactAddedToDealDetails event={event} />
    case "live_market_order_placed":
      return <LiveMarketOrderPlacedDetails event={event} />
    case "live_market_order_updated":
      return <LiveMarketOrderUpdatedDetails event={event} />
    case "live_market_order_renewed":
      return <LiveMarketOrderRenewedDetails event={event} />
    case "darkpool_order_placed":
      return <DarkpoolOrderPlacedDetails event={event} />
    case "darkpool_order_updated":
      return <DarkpoolOrderUpdatedDetails event={event} />
    case "darkpool_order_renewed":
      return <DarkpoolOrderRenewedDetails event={event} />
    case "connection_request_submitted":
      return <ConnectRequestSubmittedDetails event={event} />
    case "connection_request_received":
      return <ConnectRequestReceivedDetails event={event} />
    case "order_inquiry_submitted":
      return <OrderInquirySubmittedDetails event={event} />
    case "order_inquiry_received":
      return <OrderInquiryReceivedDetails event={event} />
    default:
      return assertUnreachable(event)
  }
}

const getEventName = (event: AccountActivityLogEvent) => {
  switch (event.eventType) {
    case "contact_created":
      return "Contact Added"
    case "contact_updated":
      return "Contact Updated"
    case "contact_buy_sell_interest_added":
      if (event.direction === "buy") {
        return "Buy Interest Added"
      } else {
        return "Sell Interest Added"
      }
    case "contact_buy_sell_interest_updated":
      if (event.direction === "buy") {
        return "Buy Interest Updated"
      } else {
        return "Sell Interest Updated"
      }
    case "note_added":
      // eslint-disable-next-line no-case-declarations
      const source = event.note.source.sourceType

      switch (source) {
        case "company":
          return "New Company Note"
        case "contact":
          return "New Contact Note"
        case "deal":
          return "New Deal Note"
        case "dealParticipant":
          return "New Deal Participant Note"
        case "interest":
          return "New Interest Note"
        case "holding":
          return "New Holding Note"
        default:
          return assertUnreachable(source)
      }
    case "deal_created":
      return "New Deal Created"
    case "contact_added_to_deal":
      return "Contact Added to Deal"
    case "live_market_order_placed":
      return "Live Market Order Submitted"
    case "live_market_order_updated":
      return "Live Market Order Updated"
    case "live_market_order_renewed":
      return "Live Market Order Renewed"
    case "darkpool_order_placed":
      return "Darkpool Order Submitted"
    case "darkpool_order_updated":
      return "Darkpool Order Updated"
    case "darkpool_order_renewed":
      return "Darkpool Order Renewed"
    case "connection_request_submitted":
      return "Connection Request Submitted"
    case "connection_request_received":
      return "Connection Request Received"
    case "order_inquiry_submitted":
      return "Order Inquiry Submitted"
    case "order_inquiry_received":
      return "Order Inquiry Received"
    default:
      return assertUnreachable(event)
  }
}
