import { Just, Maybe, Nothing, nullableToMaybe } from "common/containers/Maybe"
import { PartnerIntegrationUser } from "../../pages/WidgetEmbeds/model/PartnerIntegrationUser"

const COMPANY_NAME_PARAM_KEY = "companyName"

export const getCompanyNameFromParams = (search: string): Maybe<string> =>
  Just(new URLSearchParams(search))
    .map((searchParams) => searchParams.get(COMPANY_NAME_PARAM_KEY))
    .bind(nullableToMaybe)

export const getCompanyIdFromParams = (search: string): Maybe<string> => {
  const params = new URLSearchParams(search)
  const id = params.has("caplightId") ? params.get("caplightId") : params.get("companyId")
  if (!id) return Nothing
  return Just(id)
}

export const getIntegrationUserFromParams = (search: string): Maybe<PartnerIntegrationUser> => {
  const params = new URLSearchParams(search)
  const uuid = params.get("user_id") || undefined
  const email = params.get("user_email") || undefined
  if (!uuid && !email) return Nothing
  return Just({
    uuid,
    email,
  })
}

export const API_KEY_PARAM_KEY = "apiKey"

export const getApiKey = (search: string): Maybe<string> =>
  Just(new URLSearchParams(search))
    .map((searchParams) => searchParams.get(API_KEY_PARAM_KEY))
    .bind(nullableToMaybe)
