import { wrapIcon } from "./IconWrapper"

export const OpportunityInboxIcon = wrapIcon(
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 2C8.30432 2 8.55102 2.24742 8.55102 2.55263V8.6132L10.0594 7.10046C10.2745 6.88464 10.6234 6.88464 10.8386 7.10046C11.0538 7.31627 11.0538 7.66618 10.8386 7.882L8.38963 10.3381C8.28629 10.4418 8.14614 10.5 8 10.5C7.85386 10.5 7.71371 10.4418 7.61037 10.3381L5.16139 7.882C4.9462 7.66618 4.9462 7.31627 5.16139 7.10046C5.37658 6.88464 5.72547 6.88464 5.94065 7.10046L7.44898 8.6132V2.55263C7.44898 2.24742 7.69568 2 8 2Z"
      fill="currentColor"
    />
    <path
      d="M6.5 2.66667C6.5 2.29848 6.20152 2 5.83333 2H3.33334C2.96711 1.99956 2.65334 2.13 2.392 2.39134C2.13067 2.65267 2 2.96667 2 3.33334V12.6667C1.99956 13.0329 2.13 13.3467 2.39134 13.608C2.65267 13.8693 2.96667 14 3.33334 14H12.6667C13.0329 14.0004 13.3467 13.87 13.608 13.6087C13.8693 13.3473 14 13.0333 14 12.6667V3.33334C14.0004 2.96711 13.87 2.65334 13.6087 2.392C13.3473 2.13067 13.0333 2 12.6667 2H10.1667C9.79848 2 9.5 2.29848 9.5 2.66667C9.5 3.03486 9.79848 3.33334 10.1667 3.33334H12.6667V10.6667H9.86667C9.73334 11.0667 9.49445 11.3889 9.15 11.6333C8.80556 11.8778 8.42222 12 8 12C7.57778 12 7.19445 11.8778 6.85 11.6333C6.50556 11.3889 6.26667 11.0667 6.13334 10.6667H3.33334V3.33334H5.83333C6.20152 3.33334 6.5 3.03486 6.5 2.66667Z"
      fill="currentColor"
    />
  </svg>
)
