import { wrapIcon } from "./IconWrapper"

export const InfoIcon = wrapIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.00004 9.33331C7.83523 9.33331 7.67411 9.38219 7.53707 9.47375C7.40003 9.56532 7.29322 9.69547 7.23014 9.84774C7.16707 10 7.15057 10.1676 7.18272 10.3292C7.21488 10.4909 7.29424 10.6394 7.41079 10.7559C7.52733 10.8724 7.67582 10.9518 7.83747 10.984C7.99912 11.0161 8.16668 10.9996 8.31895 10.9365C8.47122 10.8735 8.60137 10.7667 8.69294 10.6296C8.7845 10.4926 8.83338 10.3315 8.83338 10.1666C8.83338 9.94563 8.74558 9.73367 8.5893 9.57739C8.43302 9.42111 8.22106 9.33331 8.00004 9.33331ZM8.00004 8.33331C8.17686 8.33331 8.34642 8.26307 8.47145 8.13805C8.59647 8.01303 8.66671 7.84346 8.66671 7.66665V5.66665C8.66671 5.48984 8.59647 5.32027 8.47145 5.19524C8.34642 5.07022 8.17686 4.99998 8.00004 4.99998C7.82323 4.99998 7.65366 5.07022 7.52864 5.19524C7.40362 5.32027 7.33338 5.48984 7.33338 5.66665V7.66665C7.33338 7.84346 7.40362 8.01303 7.52864 8.13805C7.65366 8.26307 7.82323 8.33331 8.00004 8.33331ZM8.00004 1.33331C6.6815 1.33331 5.39257 1.72431 4.29624 2.45685C3.19991 3.18939 2.34543 4.23058 1.84085 5.44876C1.33626 6.66693 1.20424 8.00737 1.46148 9.30058C1.71871 10.5938 2.35365 11.7817 3.286 12.714C4.21835 13.6464 5.40624 14.2813 6.69944 14.5385C7.99265 14.7958 9.33309 14.6638 10.5513 14.1592C11.7694 13.6546 12.8106 12.8001 13.5432 11.7038C14.2757 10.6075 14.6667 9.31852 14.6667 7.99998C14.6648 6.23247 13.9618 4.5379 12.7119 3.28808C11.4621 2.03826 9.76756 1.33525 8.00004 1.33331ZM8.00004 13.3333C6.94521 13.3333 5.91406 13.0205 5.037 12.4345C4.15994 11.8484 3.47635 11.0155 3.07269 10.041C2.66902 9.06642 2.5634 7.99406 2.76919 6.9595C2.97498 5.92493 3.48293 4.97462 4.22881 4.22874C4.97469 3.48286 5.925 2.97491 6.95956 2.76912C7.99413 2.56334 9.06648 2.66895 10.041 3.07262C11.0156 3.47629 11.8485 4.15988 12.4345 5.03694C13.0206 5.914 13.3334 6.94515 13.3334 7.99998C13.3316 9.41393 12.7691 10.7695 11.7693 11.7693C10.7695 12.7691 9.41399 13.3315 8.00004 13.3333Z"
      fill="currentColor"
    />
  </svg>
)
