import { wrapIcon } from "./IconWrapper"

export const ExclamationPointCircleIcon = wrapIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" fill="none">
    <path
      fill="currentColor"
      d="M7 8.333A.833.833 0 1 0 7 10a.833.833 0 0 0 0-1.667Zm0-1a.667.667 0 0 0 .667-.667v-2a.667.667 0 0 0-1.333 0v2A.667.667 0 0 0 7 7.333Zm0-7A6.667 6.667 0 1 0 13.667 7 6.674 6.674 0 0 0 7 .333Zm0 12A5.334 5.334 0 1 1 12.334 7 5.34 5.34 0 0 1 7 12.333Z"
    />
  </svg>
)
