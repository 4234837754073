import { AccountIdFields } from "common/model/Account"
import moment from "moment-timezone"
import { UserEventCounter } from "common/model/Analytics/EventCounter"
import { useFirebaseReader } from "../../firebase/Context"
import { useDocuments } from "../../firebase/Firestore"
import Spinner from "../../components/icons/Spinner"
import { UsersEngagementChart } from "./UsersEngagementChart"
import { useAdminGetUsersByAccount } from "src/queries/User/useAdminGetUsersByAccount"

interface AccountUserEngagementProps {
  account: AccountIdFields
}

const NUM_WEEKS = 12

export const AccountUsersEngagement: React.FC<AccountUserEngagementProps> = ({ account }) => {
  const firebase = useFirebaseReader()

  const userEngagementCounts = useDocuments<UserEventCounter>(
    firebase.adminGetUserEventCountsForAccount({
      accountId: account.id,
      period: "week",
      start: moment()
        .startOf("week")
        .subtract(NUM_WEEKS - 1, "weeks")
        .toDate(),
      end: new Date(),
    }),
    [account.id]
  )

  const allAccountUsers = useAdminGetUsersByAccount(account.id)

  if (
    !userEngagementCounts ||
    !allAccountUsers ||
    userEngagementCounts === "loading" ||
    allAccountUsers === "loading"
  ) {
    return <Spinner size="md" />
  }

  return (
    <div>
      <UsersEngagementChart
        userEngagementCounts={userEngagementCounts}
        accountUsers={allAccountUsers}
      />
    </div>
  )
}
