import { Order } from "common/model/Order/Models/Wrapped"
import { ALLOWED_ORDER_INQUIRIES_PER_MONTH } from "common/model/OrderInquiry/Constants"
import { OrderInquiry } from "common/model/OrderInquiry/Db"
import {
  accountInquiriesQuery,
  orderInquiryCountsAgainstRemaining,
} from "common/model/OrderInquiry/Utils"
import { Loading } from "common/utils/Loading/Wrapped"
import { shortDateFormat } from "common/utils/dateUtils"
import { nullableToMaybe } from "common/containers/Maybe"
import { useEffect, useMemo } from "react"
import { Button, ButtonSize } from "@stories/components/Button/Button"
import { useDrawer } from "../../components/Drawer/Drawer"
import { useFirebaseReader } from "../../firebase/Context"
import { useDocuments } from "../../firebase/Firestore"
import { useErrorHandler } from "../../providers/errorHandler"
import { useLoggedInUser } from "../../providers/loggedInUser/useLoggedInUser"
import OrderInquiryDrawer from "./OrderInquiryDrawer"
import { Tooltip } from "@stories/components/Antd"
import moment from "moment"
import { useCurrentAccount } from "src/queries/currentUser/useCurrentAccount"
import { isLoaded } from "common/utils/Loading"

const OrderInquiryButton = ({ order, size = "large" }: { order: Order; size?: ButtonSize }) => {
  const { open, openDrawer, closeDrawer } = useDrawer()
  const { user } = useLoggedInUser()
  const account = useCurrentAccount()
  const firebase = useFirebaseReader()
  const { handleError } = useErrorHandler()

  const monthlyInquiries = useDocuments(
    accountInquiriesQuery(firebase.db, user.account).where(
      "createdAt",
      ">=",
      moment().startOf("month").toDate()
    )
  )

  const previousInquiries = useDocuments(
    accountInquiriesQuery(firebase.db, user.account).where(
      "source.anchorOrder.id",
      "==",
      order.id()
    )
  )
  const loadingInquiry = useMemo(
    () => new Loading(previousInquiries).map((inquiries) => inquiries[0]).map(nullableToMaybe),
    [previousInquiries]
  )

  const maxAccountInquiriesPerMonth = useMemo(() => {
    if (isLoaded(account) && account.maxMonthlyInquiriesCount) {
      return account.maxMonthlyInquiriesCount
    }
    return ALLOWED_ORDER_INQUIRIES_PER_MONTH
  }, [account])

  const remainingInquiryCount = useMemo(
    () =>
      new Loading(monthlyInquiries)
        .map(
          (inquiries: OrderInquiry[]): number =>
            inquiries.filter(orderInquiryCountsAgainstRemaining).length
        )
        .map((orderInquiryCount: number): number =>
          Math.max(0, maxAccountInquiriesPerMonth - orderInquiryCount)
        ),
    [monthlyInquiries, maxAccountInquiriesPerMonth]
  )

  const { firstViewedOrderInquiryButton } = user.productInteractionHistory

  useEffect(() => {
    if (!firstViewedOrderInquiryButton) {
      firebase
        .recordUserProductInteraction(user, { firstViewedOrderInquiryButton: new Date() })
        .catch(handleError)
    }
  }, [firstViewedOrderInquiryButton, firebase, handleError, user])

  // AB test to determine if which order inquiry button to show to users
  const isAccountSegmentA = /^[a-m]/i.test(user.account.name)
  const buttonId = `caplight-inquire-start-${order.id()}`
  const buttonLabel = loadingInquiry
    .toMaybe()
    .flatten()
    .map((inquiry) => `Inquiry sent on ${shortDateFormat(inquiry.createdAt)}`)
    .withDefault(isAccountSegmentA ? "Inquire" : "Ask a question")

  const buttonToDisplay = isAccountSegmentA ? (
    <Tooltip title="Ask a question about this order" placement="bottom">
      <Button
        id={buttonId}
        variant="secondary"
        size={size}
        onClick={openDrawer}
        label={buttonLabel}
        heapName="inquire-button-a"
        isFullWidth
      />
    </Tooltip>
  ) : (
    <Button
      id={buttonId}
      variant="secondary"
      size={size}
      onClick={openDrawer}
      label={buttonLabel}
      heapName="inquire-button-b"
      isFullWidth
    />
  )

  return (
    <>
      {buttonToDisplay}
      <OrderInquiryDrawer
        open={open}
        order={order}
        closeDrawer={closeDrawer}
        remainingInquiryCount={remainingInquiryCount}
        existingInquiry={loadingInquiry}
      />
    </>
  )
}

export default OrderInquiryButton
