import * as React from "react"
import { AccountEventCountsRollup } from "common/model/Analytics/EventCounterRollup"
import { Account } from "common/model/Account"
import HighchartsReact from "highcharts-react-official"
import Highcharts from "highcharts"
import moment from "moment-timezone"
import {
  UserEventType,
  dataUserEvents,
  marketUserEvents,
  otherUserEvents,
  userEventTypes,
} from "common/model/UserEvent"
import { identity } from "common/utils/fp/Function"
import Module from "./Module"
import { EXCLUDED_EVENTS } from "./AccountEngagement"

interface IOverallAccountEngagemnetChartProps {
  weeklyAccountEventRollups: AccountEventCountsRollup[]
  allAccounts: Account[]
}

const NUM_WEEKS = 12

const chartWeeks = Array(NUM_WEEKS)
  .fill(0)
  .map((_, i) =>
    moment()
      .tz("America/Los_Angeles")
      .startOf("week")
      .subtract(NUM_WEEKS - i - 1, "weeks")
      .toDate()
      .getTime()
  )

const OverallAccountEngagementChart: React.FunctionComponent<
  IOverallAccountEngagemnetChartProps
> = ({ weeklyAccountEventRollups, allAccounts }) => {
  const [selectedEvents, setSelectedEvents] = React.useState<UserEventType[]>(
    userEventTypes.map(identity)
  )

  const brokerAccounts = allAccounts.filter((account) =>
    account.clientType.includes("Intermediary")
  )
  const investorShareholders = allAccounts.filter(
    (account) =>
      account.clientType.includes("Investor/Shareholder") &&
      !account.clientType.includes("Intermediary")
  )
  const journalists = allAccounts.filter((account) => account.clientType.includes("Journalist"))
  const uncategorizedAccounts = allAccounts.filter(
    (account) =>
      !account.clientType.includes("Intermediary") &&
      !account.clientType.includes("Investor/Shareholder") &&
      !account.clientType.includes("Journalist")
  )

  const getChartSeries = React.useMemo(() => {
    const series = [
      { name: "Broker", accounts: brokerAccounts },
      { name: "Investor/Shareholder", accounts: investorShareholders },
      { name: "Journalist", accounts: journalists },
      { name: "Uncategorized", accounts: uncategorizedAccounts },
    ].map((accountType) => {
      const data = chartWeeks.map((week) => {
        const eventCountsByAccounts = Object.entries(
          weeklyAccountEventRollups.find((doc) => doc.periodDate.getTime() === week)?.accounts || {}
        )
        const eventCountsInAccountType = eventCountsByAccounts.filter(([accountId, eventCounts]) =>
          accountType.accounts.find((account) => account.id === accountId)
        )
        const countsPerAccount = eventCountsInAccountType.map(([accountId, eventCounts]) => {
          // Sum up events incldued in selectedEvents
          const filteredEventCounts = Object.entries(eventCounts.eventCounts).filter(
            ([eventType]) =>
              selectedEvents.includes(eventType as UserEventType) &&
              !EXCLUDED_EVENTS.includes(eventType as UserEventType)
          )
          return filteredEventCounts.reduce((sum, [eventType, count]) => sum + count, 0)
        })
        const totalEvents = countsPerAccount.reduce((sum, count) => sum + count, 0)
        return [week, totalEvents]
      })
      return {
        name: accountType.name,
        data,
      }
    })
    return series
  }, [
    selectedEvents,
    weeklyAccountEventRollups,
    brokerAccounts,
    investorShareholders,
    journalists,
    uncategorizedAccounts,
  ])

  const chartOptions = {
    chart: {
      type: "column",
    },
    title: {
      text: "Account Engagement By Type",
    },
    subtitle: {
      text: "Past 12 Weeks",
    },
    xAxis: {
      type: "datetime",
      labels: {
        format: "{value:%b %e}",
      },
      min: moment()
        .tz("America/Los_Angeles")
        .startOf("week")
        .subtract(NUM_WEEKS - 1, "weeks")
        .toDate()
        .getTime(),
      max: moment().tz("America/Los_Angeles").startOf("week").toDate().getTime(),
      tickInterval: 7 * 24 * 3600 * 1000, // 1 week in milliseconds
    },
    yAxis: {
      min: 0,
      title: {
        text: "Total Events",
      },
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: getChartSeries,
  }

  const eventCheckbox = (eventType: UserEventType) => (
    <label key={eventType} className="flex space-x-1 whitespace-nowrap">
      <input
        type="checkbox"
        checked={selectedEvents.includes(eventType)}
        onChange={() =>
          setSelectedEvents((events) =>
            events.includes(eventType)
              ? events.filter((e) => e !== eventType)
              : [...events, eventType]
          )
        }
      />
      <span className="whitespace-nowrap">{eventType}</span>
    </label>
  )

  const eventFilterSection = (name: string, events: UserEventType[]) => (
    <div className="flex space-x-2 text-sm">
      <div className="whitespace-nowrap font-semibold text-base">{name}</div>
      <div className="flex space-x-2 flex-wrap items-center">{events.map(eventCheckbox)}</div>
    </div>
  )

  return (
    <Module title="Account Engagement">
      {eventFilterSection(
        "Market Events",
        marketUserEvents.filter((ev) => !EXCLUDED_EVENTS.includes(ev))
      )}
      {eventFilterSection(
        "Data Events",
        dataUserEvents.filter((ev) => !EXCLUDED_EVENTS.includes(ev))
      )}
      {eventFilterSection(
        "Other Events",
        otherUserEvents.filter((ev) => !EXCLUDED_EVENTS.includes(ev))
      )}
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </Module>
  )
}

export default OverallAccountEngagementChart
