import { v4 as uuid } from "uuid"
import { Either, Left, Right } from "../../containers/Either"
import { collections } from "../../firestore/Collections"
import { replaceMessages } from "../../parser/ParseErrors"
import { Parse, Parser } from "../../parser/ParserClasses/Simple"
import { FirebaseCommon } from "../../firestore/Interface"
import { firestoreConverter } from "../FirestoreConverter"
import { User, UserIdFields, viewUserIdFields } from "../User"
import { defaultContactInvestmentPreferences } from "./ContactInvestmentPreferences"
import {
  DealCRMCommonContactImportData,
  DealCRMContactCommonFields,
  DealCRMContactIdFieldsCommon,
  DealCRMContactUpdate,
  constructDealCRMContactParser,
  salesforceIdParser,
} from "./DealCRMContact"
import { DealCRMFirmContact, DealCRMFirmContactIdFields } from "./DealCRMFirmContact"
import { IndividualContactClassification } from "./IndividualContactClassification"
import { CSVSchema } from "../../csv/CSVSchema"
import { Validation } from "../../containers/Validation"
import { emailPatternString } from "../../utils/StringUtils"

export type DealCRMIndividualContactIdFields = Pick<
  DealCRMIndividualContact,
  keyof DealCRMContactIdFieldsCommon
> & {
  tag: "individual"
}

export type DealCRMIndividualContact = DealCRMContactCommonFields & {
  tag: "individual"
  isTransactionalIndividual: boolean // Transactional individuals may be added to deals
  classification: IndividualContactClassification | null
  individualType:
    | "firm-employee"
    | "angel-investor"
    | "HNWI"
    | "current-company-executive"
    | "former-company-executive"
    | "board-member"
    | "advisor"
    | "broker"
    | null
  firstName: string
  lastName: string
  title: string
  email: string | null
  phone: string
  firm: DealCRMFirmContactIdFields | null
  previousFirmIds: string[]
  previousFirms: DealCRMFirmContactIdFields[]
  updates: DealCRMContactUpdate<DealCRMIndividualContact>[]
  platformAccountId?: string
  platformContactId?: string
  airtableContactId?: string
}
export type DealCRMIndividualContactImportData = Pick<
  DealCRMIndividualContact,
  "firstName" | "lastName" | "title" | "email" | "phone" | "individualType"
> &
  DealCRMCommonContactImportData & {
    sourceId: string
    firmSourceId: string
  }

export const dealCRMIndividualTypes = [
  "firm-employee",
  "angel-investor",
  "HNWI",
  "current-company-executive",
  "former-company-executive",
  "board-member",
  "advisor",
  "broker",
] as const

export const dealCRMIndividualContactFieldParsers: {
  [key in keyof DealCRMIndividualContactImportData]: Parser<
    string,
    {},
    DealCRMIndividualContactImportData[key]
  >
} = {
  sourceId: salesforceIdParser,
  firstName: Parse.String.freeText,
  lastName: Parse.String.freeText,
  email: Parse.String.email,
  phone: Parse.String.freeText.mapError(replaceMessages("Expected a phone number")),
  individualType: Parse.String.oneOf(dealCRMIndividualTypes),
  firmSourceId: Parse.String.freeText,
  title: Parse.String.freeText,
  lastContactedAt: Parse.Dates.isoDate,
}
export const dealCRMIndividualContactImportDataParser = constructDealCRMContactParser(
  dealCRMIndividualContactFieldParsers
)

export type DealCRMJointContactImportData = {
  firmName: string
  firmType: string
  firstName: string
  lastName: string
  email: string
  phone: string
}
export const dealCRMJointContactFieldParsers: {
  [key in keyof DealCRMJointContactImportData]: Parser<
    string,
    {},
    DealCRMJointContactImportData[key]
  >
} = {
  firmName: Parse.String.freeText.optional().map((x) => x.withDefault("")),
  firmType: Parse.String.freeText.optional().map((x) => x.withDefault("")),
  firstName: Parse.String.freeText,
  lastName: Parse.String.freeText,
  email: Parse.String.email.optional().map((x) => x.withDefault("")),
  phone: Parse.String.freeText
    .mapError(replaceMessages("Expected a phone number"))
    .optional()
    .map((x) => x.withDefault("")),
}
export const dealCRMJointContactImportDataParser = constructDealCRMContactParser(
  dealCRMJointContactFieldParsers
)

export const finalizeIndividualContactImport = (
  knownFirms: DealCRMFirmContact[],
  user: UserIdFields,
  accountId: string,
  imported: DealCRMIndividualContactImportData
): Either<
  "could not find firm",
  (existing: DealCRMIndividualContact | null) => DealCRMIndividualContact
> => {
  const firm = knownFirms.find((f) => imported.firmSourceId === f.sourceId)
  if (firm === undefined && imported.firmSourceId) {
    return Left("could not find firm")
  }
  const ifNew: DealCRMIndividualContact = {
    accountId,
    contactBuyInterest: [],
    contactSellInterest: [],
    email: imported.email,
    firstName: imported.firstName,
    lastName: imported.lastName, // not convinced we need these
    name: `${imported.firstName} ${imported.lastName}`,
    firm: firm ?? null,
    createdAt: new Date(),
    holdings: [],
    id: uuid(),
    individualType: imported.individualType,
    isFundLP: firm?.isFundLP ?? false, // seems odd
    notes: [],
    phone: imported.phone,
    previousFirmIds: [],
    previousFirms: [],
    sourceId: imported.sourceId,
    isTransactionalIndividual: false, // ??
    tag: "individual",
    title: imported.title,
    updates: [],
    createdBy: user,
    lastContactedAt: imported.lastContactedAt,
    creationSource: "import",
    // TODO: this is brittle, maybe we iterate on this or default to null
    classification:
      imported.individualType === "firm-employee"
        ? "firm_contact_only"
        : imported.individualType === "current-company-executive" ||
          imported.individualType === "former-company-executive"
        ? "shareholder"
        : "investor",
    investmentPreferences: defaultContactInvestmentPreferences,
  }
  const ifUpdate = (existing: DealCRMIndividualContact) => {
    const updateData: Partial<DealCRMIndividualContact> = {
      email: imported.email,
      firstName: imported.firstName,
      lastName: imported.lastName, // not convinced we need these
      name: `${imported.firstName} ${imported.lastName}`,
      firm,
      isFundLP: firm?.isFundLP ?? false, // seems odd
      phone: imported.phone,
      sourceId: imported.sourceId,
      isTransactionalIndividual: existing.isTransactionalIndividual,
      tag: "individual",
      title: imported.title,
      lastContactedAt: imported.lastContactedAt,
    }
    const update: DealCRMContactUpdate<DealCRMIndividualContact> = {
      updatedBy: viewUserIdFields(user),
      updatedAt: new Date(),
      data: updateData,
    }

    const result: DealCRMIndividualContact = {
      ...existing,
      previousFirmIds:
        existing.firm?.id === firm?.id || existing.firm?.id === undefined
          ? existing.previousFirmIds
          : [existing.firm.id, ...existing.previousFirmIds],
      previousFirms:
        existing.firm?.id === firm?.id || !existing.firm
          ? existing.previousFirms
          : [existing.firm, ...existing.previousFirms],
      updates: [update, ...existing.updates],
      ...updateData,
    }
    return result
  }

  return Right((doc) => (doc === null ? ifNew : ifUpdate(doc)))
}

export const getKnownIndividuals = async (
  db: FirebaseCommon.LimitedDB,
  accountId: string
): Promise<DealCRMIndividualContact[]> =>
  db
    .collection(collections.dealCRM.contacts)
    .withConverter<DealCRMIndividualContact>(firestoreConverter<DealCRMIndividualContact>())
    .where("tag", "==", "individual" satisfies DealCRMIndividualContact["tag"])
    .where("accountId", "==", accountId satisfies DealCRMIndividualContact["accountId"])
    .get()
    .then((docs) => docs.docs.map((doc) => doc.data()).flatMap((doc) => (doc ? [doc] : [])))

export const minimalIndividualContactSchema = (
  user: User
): CSVSchema<
  Pick<DealCRMIndividualContact, "firstName" | "lastName" | "email" | "phone">,
  DealCRMIndividualContact
> => ({
  row: {
    firstName: Validation.Ok,
    lastName: Validation.Ok,
    email: (x) =>
      x
        ? emailPatternString.test(x)
          ? Validation.Ok(x)
          : Validation.Problem("Invalid email")
        : Validation.Ok(null),
    phone: Validation.Ok,
  },
  reassemble: (r) =>
    Validation.Ok({
      ...r,
      accountId: user.account.id,
      createdAt: new Date(),
      classification: "shareholder",
      firm: null,
      id: uuid(),
      sourceId: "",
      creationSource: "import",
      name: `${r.firstName} ${r.lastName}`,
      contactBuyInterest: [],
      contactSellInterest: [],
      holdings: [],
      notes: [],
      isFundLP: false,
      investmentPreferences: {
        openToSPVs: null,
        maxManagementFee: null,
        openToDoubleLayerSPVs: null,
        maxCarry: null,
        paysBrokerFees: null,
        requiresDiligence: null,
        typicalInvestmentTimeline: null,
        openToForwards: null,
        cashOnHand: null,
      },
      tag: "individual",
      isTransactionalIndividual: true,
      individualType: null,
      title: "",
      previousFirmIds: [],
      previousFirms: [],
      updates: [],
    }),
})

export const minimalFirmContactSchema = (
  user: User
): CSVSchema<
  {
    firmName: string | null
  },
  DealCRMFirmContact | null
> => ({
  row: {
    firmName: (x) => Validation.Ok(x),
  },
  reassemble: ({ firmName }) =>
    firmName
      ? Validation.Ok({
          tag: "firm",
          name: firmName,
          accountId: user.account.id,
          createdAt: new Date(),
          id: uuid(),
          sourceId: "",
          contactBuyInterest: [],
          contactSellInterest: [],
          holdings: [],
          notes: [],
          creationSource: "import",
          isFundLP: false,
          investmentPreferences: defaultContactInvestmentPreferences,
          isBrokerage: false,
          classification: "",
          contactIds: [],
          contacts: [],
          updates: [],
        })
      : Validation.Ok(null),
})

export const individualContactDefaultFieldValues = {
  sourceId: "",
  tag: "individual",
  createdAt: new Date(),
  holdings: [],
  notes: [],
  contactBuyInterest: [],
  contactSellInterest: [],
  isFundLP: false,
  previousFirmIds: [],
  previousFirms: [],
  isTransactionalIndividual: false,
  title: "",
  email: "",
  phone: "",
  individualType: "firm-employee",
  updates: [],
  investmentPreferences: defaultContactInvestmentPreferences,
} satisfies Partial<DealCRMIndividualContact>
