import { Excluding } from "../utils/TypeUtils"
import { User, UserIdFields, viewUserIdFields } from "./User"

export type Note = {
  text: string
  timestamp: Date
  author: Excluding<UserIdFields, User>
}

export const createNote = (text: string, author: User): Note => ({
  text,
  timestamp: new Date(),
  author: viewUserIdFields(author),
})
