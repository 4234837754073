import { isEmployeeAccount, isPureDataCustomer } from "common/model/Auth/Permissions"
import { isLoading } from "common/utils/Loading"
import { FC, useEffect } from "react"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import { Routes } from "../Routes/Routes"
import { isAdminContext, useAuthContext } from "../Routes/auth/UserAuthContext"
import { useFirebaseReader } from "../firebase/Context"
import { useCurrentUser } from "../providers/currentUser/useCurrentUser"
import { useLoadingState } from "../utils/useLoadingState"

const GlobalRedirect: FC<React.PropsWithChildren<unknown>> = () => {
  const authCtx = useAuthContext()
  const user = useCurrentUser()
  const firebase = useFirebaseReader()
  const location = useLocation()
  const navigate = useNavigate()

  const [redirect, setRedirect] = useLoadingState<string>()

  /** TODO: get rid of this and integrate the parts that are supposed to be there with the AuthWall */
  useEffect(() => {
    const redirectToDataUpload = () => {
      if (
        !isLoading(user) &&
        user &&
        user.user &&
        !isAdminContext(authCtx) &&
        !isEmployeeAccount(user.user.account) &&
        isPureDataCustomer(user.user.account) &&
        user.user.account.onboardingStatus?.data?.status !== "complete" &&
        user.user.account.onboardingStatus?.data?.status !== "trial" &&
        user.user.account.onboardingStatus?.data?.status !== "trial-expired" &&
        location.pathname !== Routes.data.onboarding &&
        !location.pathname.includes(Routes.rfqs.rfq("")) &&
        !location.pathname.includes(Routes.structurePricer)
      ) {
        // force redirect to this page (user cannot navigate away)
        navigate(Routes.data.onboarding)
      }
    }
    redirectToDataUpload()
  }, [user, authCtx, location, navigate, firebase])

  useEffect(() => {
    // eslint-disable-next-line prefer-const
    let active = true
    const redirectToLiveAuction = async () => {
      if (active && !isLoading(user) && user && user.user) {
        if (!user.isAdmin && !location.pathname.includes("auctions/")) {
          const auction = await firebase.latestUserVisibleAuction().get()
          if (
            !auction.empty &&
            (user.user?.resourceAccess?.auctionIds || []).includes(auction.docs[0].id)
          ) {
            setRedirect(Routes.auction(auction.docs[0].data().id))
            return
          }
        }
        if (redirect !== null) setRedirect(null)
      }
    }
    redirectToLiveAuction()
    return () => {
      // eslint-disable-next-line better-mutation/no-mutation
      active = false
    }
  }, [firebase, location.pathname, redirect, setRedirect, user])

  // the way react-router works ensures that this redirect happens only once
  if (!isLoading(redirect) && redirect) return <Navigate to={redirect} />
  return null
}
export default GlobalRedirect
