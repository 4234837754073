import { User, viewUserIdFields } from "common/model/User"
import { DataVisibilityFields } from "common/model/data-product/DataPoint/VisibilityFields"
import { useMemo, useState } from "react"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { DocumentReference } from "../../../firebase/Firebase/utils"
import { addOutlierResult } from "common/model/data-product/DataPoint"
import { FlagIcon } from "@stories/icons/FlagIcon"
import { CheckCircleIcon } from "@stories/icons/CheckCircleIcon"
import { OutlierCheckResult } from "common/model/data-product/DataPoint/OutlierStatusFields"
import { AsyncDispatchButton } from "@stories/components/Button/AsyncDispatchButton"
import { MAX_STRINGIFIED_ORDER_LENGTH } from "common/model/Order/Order"

/** TODO dedupe */
const storeOutlierResult = (
  path: DocumentReference<DataVisibilityFields>,
  result: OutlierCheckResult
) =>
  path.firestore.runTransaction(async (t) => {
    const existing = (await t.get(path)).data()
    if (!existing) {
      throw new Error(`No document found at ${path.path} during outlier detection`)
    }
    const previousTime = existing.outlierStatus?.outlierSummary?.global.date?.getTime() ?? 0
    const newTime = result.date?.getTime()
    if (newTime && newTime - previousTime < 100) {
      throw new Error(`Outlier was updated too quickly at ${path.path}`)
    }
    if (JSON.stringify(existing).length > MAX_STRINGIFIED_ORDER_LENGTH) {
      throw new Error(`Document at ${path.path} is too large to store outlier result`)
    }
    return t.update(path, addOutlierResult(existing, result))
  })

// TODO - make logged
const flagAsOutlier = (
  user: User,
  path: DocumentReference<DataVisibilityFields>,
  outlierState: OutlierCheckResult | null,
  setOutlierState: (v: OutlierCheckResult | null) => void
) => {
  const flaggedState = {
    tag: "manually flagged",
    date: new Date(),
    user: viewUserIdFields(user),
    scope: outlierState?.scope ?? null,
  } as const
  return storeOutlierResult(path, flaggedState).then(() => setOutlierState(flaggedState))
}

const approvePoint = (
  user: User,
  path: DocumentReference<DataVisibilityFields>,
  outlierState: OutlierCheckResult | null,
  setOutlierState: (v: OutlierCheckResult | null) => void
) => {
  const approvedState = {
    tag: "approved",
    date: new Date(),
    user: viewUserIdFields(user),
    scope: outlierState?.scope ?? null,
  } as const
  return storeOutlierResult(path, approvedState).then(() => setOutlierState(approvedState))
}

const acknowledgeOutlier = (
  user: User,
  path: DocumentReference<DataVisibilityFields>,
  outlierState: OutlierCheckResult | null,
  setOutlierState: (v: OutlierCheckResult | null) => void
) => {
  const acknowledgedState = {
    tag: "outlier acknowledged",
    date: new Date(),
    user: viewUserIdFields(user),
    scope: outlierState?.scope ?? null,
  } as const
  return storeOutlierResult(path, acknowledgedState).then(() => setOutlierState(acknowledgedState))
}

export const FlagOutlierControls = ({
  outlier,
  path,
}: {
  outlier: OutlierCheckResult | null
  path: DocumentReference<DataVisibilityFields>
}) => {
  const { user } = useLoggedInUser()
  const [outlierState, setOutlierState] = useState<OutlierCheckResult | null>(outlier)
  const dataType = path.path.includes("order") ? "order" : "trade"

  const isOutlier = useMemo(
    () =>
      outlierState?.tag === "auto flagged" ||
      outlierState?.tag === "manually flagged" ||
      outlierState?.tag === "outlier acknowledged",
    [outlierState]
  )

  return (
    <div className="flex flex-row px-4">
      <div className="mr-4">
        <AsyncDispatchButton
          leftIcon={<CheckCircleIcon />}
          label={`Approve ${dataType}`}
          isDisabled={!isOutlier}
          onClick={(_e) => approvePoint(user, path, outlierState, setOutlierState)}
        />
      </div>
      {outlierState?.tag === "auto flagged" && (
        <AsyncDispatchButton
          label="Confirm is Outlier"
          isDisabled={!isOutlier}
          onClick={(_e) => acknowledgeOutlier(user, path, outlierState, setOutlierState)}
        />
      )}
      {(outlierState?.tag === "unflagged" ||
        outlierState?.tag === "approved" ||
        outlierState?.tag === "cannot check" ||
        !outlierState) && (
        <AsyncDispatchButton
          leftIcon={<FlagIcon />}
          label="Flag Outlier"
          isDisabled={isOutlier}
          onClick={(_e) => flagAsOutlier(user, path, outlierState, setOutlierState)}
        />
      )}
    </div>
  )
}
