import { JsonKVP } from "@components/displays/JSON"
import {
  PriceObservationType,
  priceObservationTradeId,
} from "common/model/data-product/pricing/PriceObservation"
import { Left, Right } from "common/containers/Either"
import {
  boolPrism,
  floatPrism,
  nullPrism,
  stringOrUndefinedPrism,
  unionPrism,
} from "common/utils/fp/optics/Prism"
import { companyLink } from "./components/CompanyLink"
import {
  AdaptedRecordInspector,
  editorFromPrism,
  numberEditorFromPrism,
  optionalCheckboxEditor,
} from "./components/RecordInspector"
import { updateDocumentLogged } from "./components/RecordInspectorCRUD"
import { useLocation } from "react-router-dom"
import * as _ from "lodash"
import { useDocumentSnapshot } from "src/utils/hooks/queries/useDocumentSnapshot"
import { deprecatedIsLoaded } from "common/utils/Loading"
import { firestoreConverter } from "@model/FirestoreConverter"
import Spinner from "@components/icons/Spinner"
import { collections } from "common/firestore/Collections"
import { useFirebaseAdmin } from "src/firebase/Context"
import { Trade, createTrade } from "common/model/data-product/Trade"
import { useMemo, useState } from "react"
import { handleConsoleError } from "src/utils/Tracking"
import { AsyncDispatchButton } from "@stories/components/Button/AsyncDispatchButton"
import { PriceObservationLink, TradeLink } from "./components/RecordLink"
import { ClosedTradeROFRSectionInner } from "@components/ClosedTradeFormComponents/ClosedTradeROFRSection"

const PriceObservationTradeDisplay = (props: { obs: PriceObservationType; trade: Trade }) => (
  <div>
    <div>
      Part of trade <TradeLink trade={props.trade} />
    </div>
    <div>Other price observations in this trade:</div>
    <div>
      {props.trade.data.observations
        .filter((o) => o.id !== props.obs.id)
        .map((x) => (
          <PriceObservationLink key={x.id} obs={x} />
        ))}
    </div>
  </div>
)

const PriceObservationTradePanel = (props: { obs: PriceObservationType }) => {
  const [newTradeCreated, setNewTradeCreated] = useState(false)
  const tradeId = priceObservationTradeId(props.obs)
  const trade = useDocumentSnapshot(
    (db) =>
      priceObservationTradeId(props.obs) === undefined
        ? null
        : db.db
            .collection(collections.trades)
            .withConverter<Trade>(firestoreConverter())
            .doc(tradeId),
    [tradeId, props.obs, newTradeCreated]
  )
  const firebase = useFirebaseAdmin()
  const createNewTrade = useMemo(
    () => () => {
      const toCreate = createTrade([props.obs]).match(
        (err) => {
          throw new Error(err)
        },
        (x) => x
      )
      return firebase.adminDb.collection(collections.trades).add(toCreate).catch(handleConsoleError)
    },
    [firebase.adminDb, props.obs]
  )

  return deprecatedIsLoaded(trade) ? (
    <PriceObservationTradeDisplay obs={props.obs} trade={trade} />
  ) : trade === null ? (
    <div>
      <AsyncDispatchButton
        label="Create new trade"
        onClick={() => createNewTrade()}
        onSuccess={() => {
          setNewTradeCreated(true)
        }}
        onClickedProps={{ isDisabled: true, label: "Creating trade..." }}
        onSuccessProps={{ isDisabled: true, label: "Trade created successfully" }}
        onFailureProps={{ isDisabled: true, label: "Could not create trade" }}
      />
    </div>
  ) : (
    <Spinner size="sm" />
  )
}

export const PriceObservationView = () => {
  const location = useLocation()
  const path = _.tail(location.pathname.split("records")[1].split("/"))
  const obs = useDocumentSnapshot(
    (db) =>
      db.db
        .doc(path.join("/"))
        .withConverter<PriceObservationType>(firestoreConverter<PriceObservationType>()),
    [path.join("/")]
  )
  return deprecatedIsLoaded(obs) ? (
    <div>
      <div className="flex flex-col justify-between align-middle p-4 shadow-lg bg-neutral-white mt-2 border-neutral-400 border-2">
        <div>Trade Data</div>
        <PriceObservationTradePanel obs={obs} />
      </div>
      <PriceObservationInspector />
    </div>
  ) : obs === null ? (
    <div>Could not find price observation</div>
  ) : (
    <Spinner size="md" />
  )
}

const rofrEditor = (
  lastKey: string,
  state: PriceObservationType["rofr"],
  setState: (state: PriceObservationType["rofr"]) => void
) => (
  <ClosedTradeROFRSectionInner
    value={{
      rofr: state,
    }}
    onChange={({ rofr }) => setState(rofr ?? null)}
    title="ROFR override"
    titles={{
      subjectToROFR: "Was trade subject to rofr",
      wasROFRd: "Was trade ROFRd",
    }}
  />
)

// this should have been parameterized by the observation instead of fetching it from the params, but I don't want to mess with the record inspector right now
const PriceObservationInspector = () => (
  <AdaptedRecordInspector<PriceObservationType & { id: string }>
    editor={{
      id: { display: null },
      structure: {
        defaultValue: { display: null }, // TODO - custom structure display
      },
      price: numberEditorFromPrism("decimal")(floatPrism),
      volume: numberEditorFromPrism("decimal")(floatPrism),
      carriedInterest: editorFromPrism(
        unionPrism((x) => (x === true || x === false ? Left(x) : Right(x)), boolPrism, floatPrism)
      ),
      managementFee: editorFromPrism(
        unionPrism((x) => (x === null ? Left(x) : Right(x)), nullPrism, floatPrism)
      ),
      company: {
        display: companyLink,
      },
      hidden: optionalCheckboxEditor,
      observedBy: {
        name: { display: (key, value, __) => JsonKVP(key, <>{value}</>) },
      },
      notes: editorFromPrism<string | undefined>(stringOrUndefinedPrism),
      rofr: {
        display: rofrEditor,
      },
    }}
    updateDocumentWithEditLogs={updateDocumentLogged<PriceObservationType & { id: string }>}
    canEdit
    canDelete
    adapter={{
      view: (x) => ({ ...x, hidden: !!x.hidden }),
      over: (x, f) => f({ ...x, hidden: !!x.hidden }),
    }}
  />
)
