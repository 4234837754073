import { capitalize } from "lodash"
import { justIfNonempty } from "common/utils/data/Array/Nonempty"
import { OrderPageOrder } from "../types"
import OrderPreviewDetail from "./OrderPreviewDetail"
import { UNSPECIFIED_VALUE } from "../OrderTermSheet/Sections/constants"
import { orderDataAccessLevel } from "../../shared/accessLevels"

const OrderPreviewShareClass = ({ order }: { order: OrderPageOrder }) => (
  <OrderPreviewDetail
    label="Share Class"
    accessLevel={orderDataAccessLevel.shareClass(order.isConnectable())}
    value={justIfNonempty(order.shareClasses().map(capitalize))
      .withDefault([UNSPECIFIED_VALUE])
      .join(", ")}
  />
)

export default OrderPreviewShareClass
