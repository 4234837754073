/* eslint-disable max-classes-per-file */
import { deprecatedIsLoaded, Loading } from "common//utils/Loading"
import { useEffect, useState } from "react"
import * as RandomUtils from "common/utils/RandomUtils"
import { HeaderKeys } from "common/utils/ApiUtils"
import * as Sentry from "@sentry/react"
import { IResponse, ReusableResponse } from "common/utils/network/ReusableResponse"
import { config } from "../config"
import { CurrentUser } from "../model/CurrentUser"
import { useErrorHandler } from "../providers/errorHandler"
import { handleConsoleError } from "../utils/Tracking"
import { useCurrentUser } from "../providers/currentUser/useCurrentUser"
import { getUserAuthToken } from "./AuthUtils"

/**
 * Accessory functions for serverless API access
 */

const apiRoot = config.firebase.cloudApiUrl

type APIMethod = "GET" | "POST" | "PATCH"

export class APIEndpoint {
  path: string

  method: APIMethod

  get fullURL(): string {
    return apiRoot + this.path
  }

  constructor(path: string, method?: APIMethod) {
    this.path = path
    this.method = method || "GET"
  }
}

export class ParameterizedAPIEndpoint {
  private createPath: (param: string) => string

  private method: APIMethod

  constructor(createPath: (param: string) => string, method?: APIMethod) {
    this.createPath = createPath
    this.method = method || "GET"
  }

  build(param: string): APIEndpoint {
    return new APIEndpoint(this.createPath(param), this.method)
  }
}

export const APIEndpoints = {
  signup: new APIEndpoint("/auth/signup", "POST"),
  newEmailVerification: new APIEndpoint("/auth/request-email-verification", "POST"),
  aiQuery: new APIEndpoint("/ai-query", "POST"),
  liveOrders: new APIEndpoint("/orders/live"),
  curatedDeals: new APIEndpoint("/opportunities/curated"),
  createFund: new APIEndpoint("/funds/create", "POST"),
  addCounterOffer: new APIEndpoint("/add-counter-offer", "POST"),
  createOrder: new APIEndpoint("/orders/create", "POST"),
  updateOrder: new APIEndpoint("/orders/update", "POST"),
  cancelOrder: new APIEndpoint("/orders/cancel", "POST"),
  renewOrder: new APIEndpoint("/orders/renew", "POST"),
  adminAllAirtableAccounts: new APIEndpoint("/admin/all-airtable-accounts", "GET"),
  adminGenerateOffPlatformCampaignAuthLinks: new APIEndpoint(
    "/admin/off-platform-campaign-auth-links",
    "POST"
  ),
  adminActiveAirtableAccountIdFields: new APIEndpoint(
    "/admin/active-airtable-accounts-id-fields",
    "GET"
  ),
  adminAllAirtableContactsForFirm: new ParameterizedAPIEndpoint(
    (airtableFirmId) => `/admin/airtable-contacts-for-firm/${airtableFirmId}`
  ),
  adminAllAirtableContactIdFields: new APIEndpoint("/admin/all-airtable-contacts-id-fields", "GET"),

  adminAllAirtableContacts: new APIEndpoint("/admin/all-airtable-contacts"),
  adminDataMigration: new ParameterizedAPIEndpoint(
    (migrationName) => `/admin/data-migration/${migrationName}`,
    "POST"
  ),
  adminDataMigrationList: new APIEndpoint("/admin/data-migration"),
  adminOpportunityInboxDigest: new APIEndpoint("/admin/opportunity-inbox-digest"),
  adminOpportunityInboxDigestForAccount: new ParameterizedAPIEndpoint(
    (accountId) => `/admin/opportunity-inbox-digest/${accountId}`
  ),
  adminSendAuctionEmails: new APIEndpoint("/admin/send-auction-emails", "POST"),
  adminSendRfqEmails: new APIEndpoint("/admin/send-rfq-emails", "POST"),
  adminSendPlatformPageInviteEmails: new APIEndpoint("/admin/send-platform-page-emails", "POST"),
  adminUpdateAuctionStatuses: new APIEndpoint("/admin/update-auction-statuses", "POST"),
  adminGetTemplatePDF: new ParameterizedAPIEndpoint(
    (templateId) => `/admin/template/${templateId}/pdf`,
    "GET"
  ),
  adminGetAuctionAllocations: new ParameterizedAPIEndpoint(
    (auctionId) => `/admin/auctions/${auctionId}/allocations`
  ),
  adminMessagingCreateAnchorEmailNotification: new ParameterizedAPIEndpoint(
    (threadId) => `/admin/messaging/${threadId}/create-anchor-email`,
    "POST"
  ),
  auth: new APIEndpoint("/auth", "POST"),
  setPassword: new APIEndpoint("/auth/set-password", "POST"),
  accountUsers: new APIEndpoint("/account/list-users"),
  inviteAccountColleague: new APIEndpoint("/account/invite-colleague", "POST"),
  getTopBrokerAccountIds: new APIEndpoint("/account/top-brokers", "GET"),
  authNewLink: new APIEndpoint("/auth/new-link", "POST"),
  authEmbed: new APIEndpoint("/auth/embed", "POST"),
  postgresDBQuery: new APIEndpoint("/postgres-db/querydb", "POST"),
  pythonCalculations: new APIEndpoint("/postgres-db/calculate", "POST"),
  commentOnData: new APIEndpoint("/postgres-db/comment-on-data", "POST"),
  mlPythonTask: new APIEndpoint("/ml-python/ml-task", "POST"),
  news: new APIEndpoint("/companies/news", "POST"),
  newIssuer: new APIEndpoint("/admin/new-issuer", "POST"),
  pdfReports: new APIEndpoint("/companies/pdf-reports", "POST"),
  coiFileUrls: new APIEndpoint("/companies/coi-file-urls", "POST"),
  epenLoenFileUrl: new APIEndpoint("/companies/epen-loen-file-url", "POST"),
  sacraReports: new APIEndpoint("/companies/sacra-reports", "POST"),
  shareCompanyPage: new APIEndpoint("/companies/share", "POST"),
  postgresBacktester: new APIEndpoint("/postgres-db/postgres-backtester", "POST"),
  environment: new APIEndpoint("/admin/environment", "GET"),
  getTemplatePDF: new ParameterizedAPIEndpoint((templateId) => `/admin/template/${templateId}/pdf`),
  getTemplateDocx: new ParameterizedAPIEndpoint(
    (templateId) => `/admin/template/${templateId}/docx`
  ),
  getTemplateHTML: new ParameterizedAPIEndpoint(
    (templateId) => `/admin/template/${templateId}/html`
  ),
  signDocument: new ParameterizedAPIEndpoint(
    (documentId) => `/documents/${documentId}/sign`,
    "POST"
  ),
  redlineDocument: new ParameterizedAPIEndpoint(
    (documentId) => `/documents/${documentId}/redline`,
    "POST"
  ),
  countersignDocumentAsAdmin: new ParameterizedAPIEndpoint(
    (documentId) => `/admin/documents/${documentId}/countersign`,
    "POST"
  ),
  fullPlatformAccess: new APIEndpoint("/users/request-platform-access", "POST"),
  interestedInRFQ: new APIEndpoint("/users/interested-in-rfq", "POST"),
  seedCompanyLogos: new APIEndpoint("/companies/seed-logos", "POST"),
  unfollowDeal: new APIEndpoint("/opportunities/unfollow-deal", "POST"),
  followDeal: new APIEndpoint("/opportunities/follow-deal", "POST"),
  markOpportunitySeenByClient: new APIEndpoint("/opportunities/seen-by-client", "POST"),
  adminResetDemoDeals: new APIEndpoint("/admin/reset-demo-opportunities", "POST"),
  adminReplaceCompanyLogo: new APIEndpoint("/admin/replace-company-logo", "POST"),
  adminSyncCompanyData: new APIEndpoint("/admin/sync-company", "POST"),
  adminCreateHolding: new APIEndpoint("/admin/create-holding", "POST"),
  sendPlatformEvent: new APIEndpoint("/platform-events", "POST"),
  fetchRFQResponses: new ParameterizedAPIEndpoint((rfqId) => `/rfq/responses/${rfqId}`, "GET"),
  activeIntroRequestCount: new ParameterizedAPIEndpoint(
    (orderId) => `/order-observations/${orderId}/active-intro-request-count`,
    "GET"
  ),
  orderMetrics: new ParameterizedAPIEndpoint(
    (orderId) => `/order-observations/${orderId}/metrics`,
    "GET"
  ),
  postMatchSuggestionInterest: new APIEndpoint(
    "/order-observations/post-match-suggestion-interest",
    "POST"
  ),
  tentativeInterestMetrics: new ParameterizedAPIEndpoint(
    (tentativeInterestId) => `/tentative-interest/${tentativeInterestId}/metrics`,
    "GET"
  ),

  // embed
  embedAccount: new APIEndpoint("/embed/account", "POST"),
  embedCompanyLiveOrders: new ParameterizedAPIEndpoint(
    (companyId) => `/embed/companies/${companyId}/live-orders`,
    "POST"
  ),
  embedPostgresDBQuery: new APIEndpoint("/embed/querydb", "POST"),

  // shared
  fetchSharedOrder: new APIEndpoint("/shared/shared-order", "POST"),
  createSharedOrder: new APIEndpoint("/shared/create-shared-order", "POST"),
  fetchSharedWithMeOrders: new APIEndpoint("/shared/shared-orders", "POST"),
  getOpportunityInboxImportStatuses: new APIEndpoint("/opportunity-inbox/import-statuses"),
  updateOpportunitiesWithRule: new APIEndpoint(
    "/opportunity-inbox/update-opportunities-with-rule",
    "POST"
  ),
  updateTradingRegistration: new APIEndpoint(
    "/trading-registration/write-trading-registration",
    "POST"
  ),
  getTradingRegistrationByAccount: new APIEndpoint(
    "/trading-registration/get-trading-registration-by-account",
    "POST"
  ),
  getTradingRegistration: new APIEndpoint("/trading-registration/get-trading-registration", "POST"),
  inviteUserToTradingRegistration: new APIEndpoint("/trading-registration/invite-user", "POST"),
  getDealDistributionList: new APIEndpoint("/deal-distribution/get-distribution-list", "POST"),
  getDealForParticipant: new APIEndpoint("/deal-distribution/get-deal-for-participant", "POST"),
  testDealDistributionEmail: new APIEndpoint("/deal-distribution/email/test", "POST"),
  publishDealDistributionEmails: new APIEndpoint("/deal-distribution/email/publish", "POST"),
  addDealDistributionParticipant: new APIEndpoint("/deal-distribution/add-participant", "POST"),
  getLinkForDealDistributionParticipant: new APIEndpoint(
    "/deal-distribution/get-link-for-participant",
    "POST"
  ),
  cartaRequestOauthURL: new APIEndpoint("/carta/request-carta-oauth-url", "POST"),
  checkCartaImportStatus: new APIEndpoint("/carta/check-import-status", "POST"),
  getCartaHoldings: new APIEndpoint("/carta/holdings", "POST"),
  //CRM
  replaceCRMInterestAndDealStage: new APIEndpoint("/crm/replace-interest-and-deal-stage", "POST"),
  syncContactEmails: new APIEndpoint("/gmail/sync-contact-emails", "POST"),
  getSuggestedBrokersForHolding: new APIEndpoint("/suggested-brokers-for-holding", "POST"),
}

interface APIResponse<T> {
  result?: T
  loading: boolean
  success: boolean
}
export interface APISuccessResponse<T> extends APIResponse<T> {
  result: T
}
export interface APIErrorResponse<T> extends APIResponse<T> {
  error: Error
}

export const requestHeaders = (params: { userToken: string }) => ({
  "Content-Type": "application/json",
  [HeaderKeys.userToken]: params.userToken,
  [HeaderKeys.releaseVersion]: config.releaseVersion || "not-found",
  [HeaderKeys.requestId]: RandomUtils.randomString(),
})

/**
 * Throw an error on a non-2xx fetch API response.
 *
 * TODO: runAPIQuery/useAPIQuery should likely use this, but we need to think more about what errors
 * we want to expose to our clients.
 *
 * @param resp the fetch API response to handle
 */
export const handleAPIErrorResponse = async (resp: IResponse) => {
  if (!resp.ok) {
    const errorBody = await resp.text()
    const errorMessage = errorBody.slice(0, errorBody.indexOf("\n"))
    throw new Error(`API request error ${errorMessage}.`)
  }
}

const logFailedRequest = (
  reqEndpoint: APIEndpoint,
  res: IResponse,
  headers: ReturnType<typeof requestHeaders>
) => {
  res
    .text()
    .then((respBody) =>
      handleConsoleError(
        `Error loading ${reqEndpoint.fullURL} with req id ${
          headers[HeaderKeys.requestId]
        }. Status: ${res.status} Resp Body: ${JSON.stringify(respBody)}.`
      )
    )
    .catch(handleConsoleError)
}

// Run a query immediately
export async function runAPIQuery<T extends Object>(
  endpoint: APIEndpoint,
  body?: T,
  currentUser?: Pick<CurrentUser, "authUser">
): Promise<ReusableResponse> {
  const userToken = currentUser
    ? await getUserAuthToken(currentUser, { requestEndpoint: endpoint })
    : ""

  const headers = requestHeaders({ userToken })
  const res = new ReusableResponse(
    await fetch(endpoint.fullURL, {
      method: endpoint.method || "GET",
      headers,
      body: body ? JSON.stringify(body) : null,
    })
  )
  if (!res.ok) logFailedRequest(endpoint, res, headers)
  return res
}

export async function getAPIResponse<T>(
  endpoint: APIEndpoint,
  body?: object,
  currentUser?: Pick<CurrentUser, "authUser">
) {
  const resp = await runAPIQuery(endpoint, body, currentUser)
  if (resp === undefined || !resp.ok) {
    return undefined
  }
  const responseBody = (await resp.json()) as { result: T } // TODO fix
  return responseBody.result
}

// react hook to run a query on component mount
export function useAPIQuery<T>(endpoint: APIEndpoint, body?: unknown) {
  const { handleError: onError } = useErrorHandler()
  const [results, setResults] = useState<Loading<APISuccessResponse<T> | APIErrorResponse<T>>>(null)

  const user = useCurrentUser()

  useEffect(() => {
    async function runQuery(currentUser: CurrentUser) {
      if (!currentUser || !currentUser.authUser) {
        return
      }

      const userToken = await getUserAuthToken(currentUser, { requestEndpoint: endpoint })
      try {
        setResults("loading")
        const headers = requestHeaders({ userToken })
        const resp = await fetch(endpoint.fullURL, {
          method: endpoint.method || "GET",
          headers,
          body: body ? JSON.stringify(body) : null,
        })
        if (!resp.ok) {
          logFailedRequest(endpoint, resp, headers)
          setResults({
            loading: false,
            success: false,
            error: Error("An error occurred"),
          })
        } else {
          const responseBody = (await resp.json()) as T
          setResults({
            loading: false,
            success: true,
            result: responseBody,
            error: undefined,
          })
        }
      } catch (err) {
        Sentry.captureException("Error on useApiQuery")
        Sentry.captureException(err)
        if (err instanceof Error) setResults({ loading: false, success: false, error: err })
        throw err
      }
    }

    if (deprecatedIsLoaded(user) && results === null) {
      runQuery(user).catch(onError)
    }
  }, [user, results, endpoint, body, onError])

  return results
}

export const sendPlatformEventMessage = (message: string, currentUser: CurrentUser) =>
  runAPIQuery(APIEndpoints.sendPlatformEvent, { message, channel: "events" }, currentUser)

export const sendTradingPlatformEventMessage = (message: string, currentUser: CurrentUser) =>
  runAPIQuery(APIEndpoints.sendPlatformEvent, { message, channel: "tradingEvents" }, currentUser)

export const sendAccountUsageEventMessage = (message: string, currentUser: CurrentUser) =>
  runAPIQuery(
    APIEndpoints.sendPlatformEvent,
    { message, channel: "accountsUsageAlerts" },
    currentUser
  )

export const sendFilingRequestEventMessage = (message: string, currentUser: CurrentUser) =>
  runAPIQuery(
    APIEndpoints.sendPlatformEvent,
    { message, channel: "filingsRequestsAlerts" },
    currentUser
  )
