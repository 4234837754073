/** This file contains code that attempts to correct inaccurate dates provided by order uploaders. It is long and complicated; please do not attempt to extend it further. */
import _ from "lodash"
import { v4 as uuid } from "uuid"
import { isDefined, isNotNull } from "../../../utils/TypeUtils"
import {
  existingTermsToNonNormalizedOrderQuantityTerms,
  FlatOrderTerm,
  mergeOrderTerms,
  normalizeOrderQuantityTerms,
  orderQuantityTermsPrice,
  orderQuantityTermsVolume,
  orderStructures,
  OrderTerms,
  StructureOrderTerms,
} from "../Types/Terms"
import type { OrderQuantityTerms } from "../Types/Terms"
import { ISODateFormat } from "../../../utils/dateUtils"
import { identity } from "../../../utils/fp/Function"
import { Rec, UnsafeRec } from "../../../utils/RecordUtils"
import { OrderMetadata } from "../Types/OrderMetadata"
import { CaplightPriceEstimateFromPostgresLight } from "../../postgres/PostgresCaplightPriceEstimate"
import { ConstructedOrder, Order, orderFullTermsArray, OrderWithMetaData } from "../Order"
import { splitAdjust } from "../../../queries/pricing/splitAdjustment/SplitAdjustment"
import { PricingDataRoot } from "../../../queries/pricing/PricingDataSource"
import { dateToTimestamp } from "../Types/UpdateLog"
import { OrderMetaDataPriceAttributionHistory } from "./PriceAttributionHistory"

export const getFlatOrderTerms = (order: Pick<Order, "terms">): FlatOrderTerm[] =>
  UnsafeRec.entries(orderFullTermsArray(order)).flatMap(([structure, terms]) =>
    terms
      .filter(isNotNull)
      .map((oldTerm) =>
        !oldTerm?.priceAndQuantity
          ? undefined
          : {
              structure,
              priceAndQuantity: oldTerm.priceAndQuantity,
              date: oldTerm.date,
              key: oldTerm.terms,
            }
      )
      .filter(isDefined)
  )

export const getKey = (
  order: Pick<Order, "terms">,
  structure: keyof StructureOrderTerms,
  timestamp: number
): StructureOrderTerms[keyof StructureOrderTerms] | undefined =>
  order.terms[structure].find(
    (term) =>
      UnsafeRec.keys(term.value)
        .map((k) => k.toString())
        .includes(timestamp.toString()) // UnsafeRec does not handle numeric keys correctly
  )?.key

export const orderTermsFrom = (
  flatTerm: Omit<FlatOrderTerm, "key">,
  key: StructureOrderTerms[keyof StructureOrderTerms] | undefined
): OrderTerms => {
  const termsToMergeWith: OrderTerms = {
    ...Rec.tabulate(orderStructures, () => []),
    [flatTerm.structure]: [
      {
        key: key || {},
        value: {
          [dateToTimestamp(flatTerm.date)]: flatTerm.priceAndQuantity,
        },
      },
    ],
  }
  return termsToMergeWith
}

const filteredTermValues = (
  termValue: Partial<Record<number, OrderQuantityTerms | null>>,
  timestampToRm: string
): Partial<Record<number, OrderQuantityTerms | null>> => {
  const records = UnsafeRec.entries(termValue).filter(
    ([tmst, terms]) => tmst.toString() !== timestampToRm && isDefined(terms)
  )
  return UnsafeRec.fromEntries(records)
}

const setUndefinedPrice = <T extends ConstructedOrder>(order: T, addedTerm: FlatOrderTerm): T => {
  const { structure } = addedTerm

  const vol = orderQuantityTermsVolume(addedTerm.priceAndQuantity)
  if (!vol) return order
  const newPriceQuantity: OrderQuantityTerms = normalizeOrderQuantityTerms({
    amountUSD: vol,
    shares: null,
    USDPerShare: null,
    targetValuation: null,
    id: uuid(),
  }).match(identity, () => {
    throw new Error("Could not create an order term with null shares and USDPerShare")
  })
  const key = getKey(order, structure, dateToTimestamp(addedTerm.date)) // take the key from the addedTerm
  const addedTermTimestampStr = dateToTimestamp(addedTerm.date).toString()
  const termsToMergeWith: OrderTerms = orderTermsFrom(
    {
      date: addedTerm.date,
      priceAndQuantity: newPriceQuantity,
      structure,
    },
    key || {}
  )
  const termsExStructure: OrderTerms = {
    ...order.terms,
    [structure]: order.terms[structure].map((trm) =>
      !_.isEqual(trm.key || {}, key)
        ? trm
        : {
            key: trm.key,
            value: filteredTermValues(trm.value, addedTermTimestampStr),
          }
    ),
  }
  const modifiedTerms: OrderTerms = mergeOrderTerms(termsExStructure, termsToMergeWith)
  return { ...order, terms: modifiedTerms }
}

const addMeta = <T extends ConstructedOrder>(
  order: T,
  meta: OrderMetaDataPriceAttributionHistory,
  triggerEvent: "upload" | "scheduled_job"
): T => {
  const existingMeta: OrderMetadata = order.metaData || {}
  const metaData: OrderMetadata = {
    ...existingMeta,
    priceDateAttribution: {
      ...(existingMeta.priceDateAttribution || {}),
      status: existingMeta.priceDateAttribution?.status || "applied",
      triggerEvent,
      priceDateAttributionHistory: [
        ...(existingMeta.priceDateAttribution?.priceDateAttributionHistory || []),
        meta,
      ],
    },
  }
  return {
    ...order,
    metaData,
  }
}

export const runDatePriceAssignmentMergedOrder = async (
  order: OrderWithMetaData,
  marketPrices: CaplightPriceEstimateFromPostgresLight[],
  addedTerm: FlatOrderTerm,
  root: PricingDataRoot
): Promise<OrderWithMetaData> => {
  const failMeta = {
    originalTimestamp: dateToTimestamp(addedTerm.date),
    structure: addedTerm.structure,
    price: -1,
    attributionResult: { error: "MarketPrice was not generated for dates requested" },
  }
  const filteredMP = marketPrices.filter(
    (mp) => ISODateFormat(mp.date) === ISODateFormat(addedTerm.date)
  )
  if (filteredMP.length !== 1)
    return {
      ...addMeta(
        order,
        {
          date: new Date(),
          meta: [failMeta],
        },
        "upload"
      ),
      id: order.id,
    }
  const TOL = 0.3
  const termPrice = orderQuantityTermsPrice(addedTerm.priceAndQuantity)
  if (!termPrice) return order
  const splitAdjustedMidpoint = await splitAdjust(root, new Date(), order.company, "simplePrice", {
    date: addedTerm.date,
    price: termPrice,
  })
  const priceIsStale =
    Math.abs(splitAdjustedMidpoint.price / marketPrices[0].caplight_composite - 1) > TOL
  const meta = {
    originalTimestamp: dateToTimestamp(addedTerm.date),
    structure: addedTerm.structure,
    price: termPrice,
    attributionResult: {
      success: {
        newDates: priceIsStale ? [] : marketPrices.map((mp) => new Date(mp.date)),
        allPriceEstimates: marketPrices.map((mp) => mp.caplight_composite),
        allDates: marketPrices.map((mp) => new Date(mp.date)),
      },
    },
  }
  if (priceIsStale) {
    return {
      ...addMeta(
        setUndefinedPrice(order, addedTerm),
        {
          date: new Date(),
          meta: [meta],
        },
        "upload"
      ),
      id: order.id,
    }
  }
  return {
    ...addMeta(
      order,
      {
        date: new Date(),
        meta: [meta],
      },
      "upload"
    ),
    id: order.id,
  }
}

/** @throws */
export const appendCreatedAtTerms = (newOrder: ConstructedOrder): ConstructedOrder => {
  const createdDate = newOrder.orderOriginationDate
  const flatNewTerms = getFlatOrderTerms(newOrder)
  const addedTermDate = _.max(flatNewTerms.map((term) => term.date))
  const addedTerm = flatNewTerms.find((term) => term.date === addedTermDate)
  if (!createdDate || !addedTerm || !addedTermDate) return newOrder

  const newPriceQuantity: OrderQuantityTerms = normalizeOrderQuantityTerms(
    existingTermsToNonNormalizedOrderQuantityTerms(addedTerm.priceAndQuantity)
  ).match(identity, () => {
    throw new Error("Could not create an order term with addedTerm.priceAndQuantity")
  })
  const key = getKey(newOrder, addedTerm.structure, dateToTimestamp(addedTermDate)) // take the key from the addedTermDate
  const termsToMergeWith: OrderTerms = orderTermsFrom(
    {
      date: createdDate,
      priceAndQuantity: newPriceQuantity,
      structure: addedTerm.structure,
    },
    key || {}
  )
  const modifiedTerms: OrderTerms = mergeOrderTerms(newOrder.terms, termsToMergeWith)
  return { ...newOrder, terms: modifiedTerms }
}
