import { Button } from "@stories/components/Button/Button"
import { EmailCard } from "@stories/components/EmailCard/EmailCard"
import gmailParser from "@stories/components/EmailCard/gmailParser"
import Typography, { Size } from "@stories/components/Typography/Typography"
import { Account } from "common/model/Account"
import { DealCRMContactIdFields } from "common/model/DealCRM/DealCRMContact"
import { ParsedEmailMessage } from "common/model/Google/ParsedEmailMessage"
import { isNonempty, nonemptyLast } from "common/utils/data/Array/Nonempty"
import { isLoaded, isLoading } from "common/utils/Loading"
import { DocumentSnapshot } from "firebase/firestore"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useFirebaseReader } from "src/firebase/Context"
import { getAllEmailsByContactIdPaginated, getEmailsByThreadId } from "src/firebase/crm"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import Spinner from "../../../../components/icons/Spinner"
import { useCurrentUser } from "../../../../providers/currentUser/useCurrentUser"
import { EmailConnectBanner } from "../../Components/EmailConnectBanner"
import { useCRMContacts } from "../../Providers/CRMContactsProvider"
import { ContactNotes } from "./ContactNotes/ContactNotes"
import EmailReply from "./EmailReply"
import { useContactBuySellInterest } from "./helpers"
import { useOwnGmailInboxes } from "src/pages/Email/Admin/useLiveGmailAuthTokens"

const EMAILS_PAGE_SIZE = 30

const useContactEmails = (contactId: string) => {
  const firebase = useFirebaseReader()
  const user = useLoggedInUser()

  const fetchEmailsPaginated = useCallback(
    async (lastVisible?: DocumentSnapshot<ParsedEmailMessage> | null) => {
      const emails = await getAllEmailsByContactIdPaginated({
        db: firebase,
        user: user.user,
        contactId,
        pageSize: EMAILS_PAGE_SIZE,
        lastVisible,
      }).get()
      return emails
    },
    [contactId, firebase, user.user]
  )

  const fetchEmailsByThreadId = useCallback(
    async (threadId: string) => {
      const emails = await getEmailsByThreadId({
        db: firebase,
        user: user.user,
        threadId,
      }).get()
      return emails
    },
    [firebase, user.user]
  )

  return {
    fetchEmailsPaginated,
    fetchEmailsByThreadId,
  }
}

type EmailsType = {
  threadId: string | null
  data: ParsedEmailMessage[]
}[]

const ContactDetailsDrawerNotesAndEmails = ({
  contact: contactIdFields,
  account,
}: {
  account: Account
  contact: DealCRMContactIdFields
}) => {
  const user = useCurrentUser()
  const { fetchEmailsPaginated, fetchEmailsByThreadId } = useContactEmails(contactIdFields.id)

  const { findContactById } = useCRMContacts()
  const currentContact = findContactById(contactIdFields.id)

  const [emails, setEmails] = useState<EmailsType>([])
  const [localEmailCache, setLocalEmailCache] = useState<EmailsType>([]) // for opportunistic ui updates

  const [lastVisible, setLastVisible] = useState<DocumentSnapshot<ParsedEmailMessage> | null>(null)
  const [endOfList, setEndOfList] = useState(false)
  const [initialLoading, setInitialLoading] = useState(true)

  const gmailInbox = useOwnGmailInboxes()

  const contactBuySellInterest = useContactBuySellInterest({
    contact: currentContact,
  })

  const existingThreadIds = useMemo(() => emails.map((email) => email.threadId), [emails])

  const isInitialRender = useRef(true)

  const fetchEmails = useCallback(async () => {
    const fetchedEmails = await fetchEmailsPaginated(lastVisible).then((res) => res.docs)

    if (isNonempty(fetchedEmails)) {
      // eslint-disable-next-line rulesdir/no-assert
      setLastVisible(nonemptyLast(fetchedEmails) as unknown as DocumentSnapshot<ParsedEmailMessage>)

      const emailsData = fetchedEmails.map((doc) => doc.data())

      const existingThreadIdsSet = new Set(existingThreadIds)

      const filteredEmails: typeof emailsData = []

      emailsData.forEach((email) => {
        if (!existingThreadIdsSet.has(email.data.threadId)) {
          filteredEmails.push(email)
          existingThreadIdsSet.add(email.data.threadId)
        }
      })

      const results = await Promise.all(
        filteredEmails.map(async (email) => {
          if (!email.data.threadId) {
            return Promise.resolve({
              threadId: null,
              data: [email],
            })
          }
          const threadEmails = await fetchEmailsByThreadId(email.data.threadId)
          return {
            threadId: email.data.threadId,
            data: threadEmails.docs.map((doc) => doc.data()),
          }
        })
      )

      if (fetchedEmails.length < EMAILS_PAGE_SIZE) {
        setEndOfList(true)
      }

      setEmails([...emails, ...results])
    } else {
      setEndOfList(true)
    }
    setInitialLoading(false)
  }, [fetchEmailsPaginated, lastVisible, emails, existingThreadIds, fetchEmailsByThreadId])

  useEffect(() => {
    if (isInitialRender.current) {
      // eslint-disable-next-line better-mutation/no-mutation
      isInitialRender.current = false
      // eslint-disable-next-line no-void
      void fetchEmails()
    }
  }, [contactIdFields.id, emails.length, fetchEmails, fetchEmailsPaginated])

  useEffect(() => {
    setLocalEmailCache((prev) => {
      const newEmails = emails.slice(prev.length)
      return [...prev, ...newEmails]
    })
  }, [emails])

  const updateLocalEmails = (email: EmailsType[0], message: string) => {
    const updatedEmails = localEmailCache.map((localEmail) => {
      if (
        localEmail.threadId === email.threadId &&
        isLoaded(user) &&
        isLoaded(gmailInbox) &&
        gmailInbox[0]
      ) {
        const recipient =
          email.data[0].data.from === gmailInbox[0].gmailAddress
            ? email.data[0].data.to[0]
            : email.data[0].data.from

        return {
          ...localEmail,
          data: [
            {
              ...email.data[0],
              data: {
                ...email.data[0].data,
                from: gmailInbox[0].gmailAddress,
                to: [recipient],
                sentAt: new Date(),
                body: {
                  html: message,
                  plaintext: message,
                },
              },
            },
            ...localEmail.data,
          ],
        }
      }
      return localEmail
    })

    setLocalEmailCache([...updatedEmails])
  }

  if (
    isLoading(currentContact) ||
    !isLoaded(user) ||
    !isLoaded(account) ||
    !isLoaded(contactBuySellInterest)
  )
    return <Spinner size="sm" />

  if (!currentContact)
    return (
      <div>
        Could not find contact details. This is likely an issue, please contact us at
        contact@caplight.com to let us know about the issue
      </div>
    )

  return (
    <div
      className="flex flex-col gap-6 p-6 bg-neutral-200"
      style={{
        minHeight: "calc(100vh - 110px)",
      }}
    >
      <EmailConnectBanner client="gmail" />
      <div className="p-3 bg-white rounded border">
        <ContactNotes contact={currentContact} contactBuySellInterest={contactBuySellInterest} />
      </div>
      {!initialLoading && (
        <>
          {localEmailCache.map((email) => (
            <EmailCard
              key={email.threadId}
              data={email.data}
              emailParser={gmailParser} // TODO: We should adapt depending on the email provider
            >
              {email.data[0].data.messageId && (
                <EmailReply
                  email={email.data[0]}
                  onReplySent={(message) => updateLocalEmails(email, message)}
                />
              )}
            </EmailCard>
          ))}
          <div className="flex justify-center">
            {!endOfList ? (
              <Button variant="hollow" label="Load More" onClick={fetchEmails} />
            ) : (
              localEmailCache.length > 0 && <Typography size={Size.Small} text="End of emails" />
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default ContactDetailsDrawerNotesAndEmails
