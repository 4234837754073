import { StructureDisplay } from "@stories/components/Orders/TableCells/StructureCell"
import { orderDataAccessLevel } from "../../shared/accessLevels"
import { OrderPageOrder } from "../types"
import OrderPreviewDetail from "./OrderPreviewDetail"

const OrderPreviewStructure = ({ order }: { order: OrderPageOrder }) => (
  <OrderPreviewDetail
    label={order.currentStructures().length > 1 ? "Structures" : "Structure"}
    accessLevel={orderDataAccessLevel.structure}
    value={
      <StructureDisplay
        structures={order.currentStructures()}
        managementFee={order.managementFee()}
        carry={order.carry()}
        structureLayersCount={order.structureLayersCount()}
        size="base"
      />
    }
  />
)

export default OrderPreviewStructure
