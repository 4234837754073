import { Boxed } from "../../../../utils/fp/Boxed"
import * as Unwrapped from "../../Types/Status"

export class OrderStatus extends Boxed<Unwrapped.OrderStatusFields> {
  isLive() {
    return Unwrapped.isLiveOrder(this.unboxed)
  }

  isStale() {
    return Unwrapped.isStaleOrder(this.unboxed)
  }

  staleUntilDate() {
    return Unwrapped.orderStaleUntil(this.unboxed)
  }

  isMarketVisible() {
    return Unwrapped.isLiveOrStaleOrder(this.unboxed)
  }

  lastRefreshed() {
    return Unwrapped.lastUserRefreshedDate(this.unboxed)
  }
}
