import { Either } from "../../../../containers/Either"
import { Maybe } from "../../../../containers/Maybe"

export class RawOrderPrice {
  constructor(
    readonly value: Maybe<
      | Either<
          {
            valuation: number
            displayDate: Date
          },
          {
            pricePerShare: number
            displayDate: Date
          }
        >
      | Either<
          never,
          {
            pricePerShare: number
            displayDate: Date
          }
        >
    >
  ) {}

  match<I, J = I, K = J>(
    ifLeft: (x: { valuation: number; displayDate: Date }) => I,
    ifRight: (x: { pricePerShare: number; displayDate: Date }) => J,
    ifNothing: () => K
  ): I | J | K {
    return this.value.match(
      (x) => x.match(ifLeft, ifRight),
      () => ifNothing()
    )
  }

  bimap<S, T>(
    l: (x: { valuation: number; displayDate: Date }) => S,
    r: (x: { pricePerShare: number; displayDate: Date }) => T
  ) {
    return this.value.map((x) => x.bimap(l, r))
  }
}
