import { DealCRMDeal } from "../DealCRM/Deal/DealCRMDeal"
import { DealCRMDealParticipant } from "../DealCRM/Deal/DealCRMDealParticipant"
import { DealCRMContactIdFields } from "../DealCRM/DealCRMContact"
import { DealCRMInterest, DeprecatedDealCRMInterest } from "../DealCRM/DealCRMInterest"
import { DealCRMNote } from "../DealCRM/DealCRMNote"
import { IntroductionRequest } from "../IntroductionRequest"
import { DarkpoolOrder, Order } from "../Order/Order"
import { OrderInquiry } from "../OrderInquiry/Db"
import { UserIdFields } from "../User"

const AccountActivityLogEventTypes = [
  "contact_created",
  "contact_updated",
  "contact_buy_sell_interest_added",
  "contact_buy_sell_interest_updated",
  "note_added",
  "deal_created",
  "contact_added_to_deal",
  "live_market_order_placed",
  "live_market_order_updated",
  "live_market_order_renewed",
  "darkpool_order_placed",
  "darkpool_order_updated",
  "darkpool_order_renewed",
  "connection_request_submitted",
  "connection_request_received",
  "order_inquiry_submitted",
  "order_inquiry_received",
] as const
export type AccountActivityLogEventType = (typeof AccountActivityLogEventTypes)[number]

export const activityLogEventGroups = [
  "All Events",
  "Market Events",
  "CRM Contact Events",
  "CRM Deal Events",
] as const

export type ActivityLogEventGroupType = (typeof activityLogEventGroups)[number]

export const activityLogEventGroupMap: Record<
  ActivityLogEventGroupType,
  AccountActivityLogEventType[]
> = {
  "All Events": [...AccountActivityLogEventTypes],
  "Market Events": [
    "live_market_order_placed",
    "live_market_order_updated",
    "live_market_order_renewed",
    "darkpool_order_placed",
    "darkpool_order_updated",
    "darkpool_order_renewed",
    "connection_request_submitted",
    "connection_request_received",
    "order_inquiry_submitted",
    "order_inquiry_received",
  ],
  "CRM Contact Events": [
    "contact_created",
    "contact_updated",
    "contact_buy_sell_interest_added",
    "contact_buy_sell_interest_updated",
    "note_added",
  ],
  "CRM Deal Events": ["deal_created", "contact_added_to_deal"],
}

export interface AccountActivityLogEventBaseFields<T extends AccountActivityLogEventType> {
  id: string
  user: UserIdFields //user who took the event action. All users on the account have the same view of the event log
  accountId: string
  eventTime: Date
  eventType: T
  sourceId: string
}

export type ContactCreatedEvent = AccountActivityLogEventBaseFields<"contact_created"> & {
  contact: DealCRMContactIdFields
}

export type ReadableFieldUpdate = {
  field: string
  displayField: string
  oldValue: string
  newValue: string
}

export type ContactUpdatedEvent = AccountActivityLogEventBaseFields<"contact_updated"> & {
  contact: DealCRMContactIdFields
  updates: ReadableFieldUpdate[]
}
export type ContactBuySellInterestAddedEvent =
  AccountActivityLogEventBaseFields<"contact_buy_sell_interest_added"> & {
    contact: DealCRMContactIdFields | null
  } & (
      | {
          direction: "buy"
          interest: DealCRMInterest | DeprecatedDealCRMInterest
        }
      | {
          direction: "sell"
          interest: DealCRMInterest | DeprecatedDealCRMInterest
        }
    )

export type ContactBuySellInterestUpdatedEvent =
  AccountActivityLogEventBaseFields<"contact_buy_sell_interest_updated"> & {
    contact: DealCRMContactIdFields | null
    updates: ReadableFieldUpdate[]
  } & (
      | {
          direction: "buy"
          interest: DealCRMInterest | DeprecatedDealCRMInterest
        }
      | {
          direction: "sell"
          interest: DealCRMInterest | DeprecatedDealCRMInterest
        }
    )

export type NoteAddedEvent = AccountActivityLogEventBaseFields<"note_added"> & {
  note: DealCRMNote
}

export type DealCreatedEvent = AccountActivityLogEventBaseFields<"deal_created"> & {
  deal: DealCRMDeal
}

export type ContactAddedToDealEvent = AccountActivityLogEventBaseFields<"contact_added_to_deal"> & {
  dealParticipant: DealCRMDealParticipant
}

export type LiveMarketOrderPlacedEvent =
  AccountActivityLogEventBaseFields<"live_market_order_placed"> & {
    contact?: DealCRMContactIdFields
    order: Order
  }

export type LiveMarketOrderUpdatedEvent =
  AccountActivityLogEventBaseFields<"live_market_order_updated"> & {
    contact?: DealCRMContactIdFields
    order: Order
    updates: ReadableFieldUpdate[]
  }

export type LiveMarketOrderRenewedEvent =
  AccountActivityLogEventBaseFields<"live_market_order_renewed"> & {
    contact?: DealCRMContactIdFields
    order: Order
  }

export type DarkpoolOrderPlacedEvent =
  AccountActivityLogEventBaseFields<"darkpool_order_placed"> & {
    contact?: DealCRMContactIdFields
    order: DarkpoolOrder
  }

export type DarkpoolOrderUpdatedEvent =
  AccountActivityLogEventBaseFields<"darkpool_order_updated"> & {
    contact?: DealCRMContactIdFields
    order: DarkpoolOrder
    updates: {
      field: string
      oldValue: string
      newValue: string
    }[]
  }

export type DarkpoolOrderRenewedEvent =
  AccountActivityLogEventBaseFields<"darkpool_order_renewed"> & {
    contact?: DealCRMContactIdFields
    order: DarkpoolOrder
  }

export type ConnectionRequestSubmittedEvent =
  AccountActivityLogEventBaseFields<"connection_request_submitted"> & {
    introRequest: IntroductionRequest
  }

export type ConnectionRequestReceivedEvent =
  AccountActivityLogEventBaseFields<"connection_request_received"> & {
    introRequest: IntroductionRequest
  }

export type OrderInquirySubmittedEvent =
  AccountActivityLogEventBaseFields<"order_inquiry_submitted"> & {
    inquiry: OrderInquiry
  }

export type OrderInquiryReceivedEvent =
  AccountActivityLogEventBaseFields<"order_inquiry_received"> & {
    inquiry: OrderInquiry
  }

export type AccountActivityLogEvent =
  | ContactCreatedEvent
  | ContactUpdatedEvent
  | ContactBuySellInterestAddedEvent
  | ContactBuySellInterestUpdatedEvent
  | NoteAddedEvent
  | DealCreatedEvent
  | ContactAddedToDealEvent
  | LiveMarketOrderPlacedEvent
  | LiveMarketOrderUpdatedEvent
  | LiveMarketOrderRenewedEvent
  | DarkpoolOrderPlacedEvent
  | DarkpoolOrderUpdatedEvent
  | DarkpoolOrderRenewedEvent
  | ConnectionRequestSubmittedEvent
  | ConnectionRequestReceivedEvent
  | OrderInquirySubmittedEvent
  | OrderInquiryReceivedEvent
