import { TableFilter } from "./TableFilter"
import FilterButton from "./FilterButton"
import { SingleSelectTag, TaggedState } from "./types"

type SingleSelectFilterProps<T> = {
  name: string
  options: { name: T; toggle: () => void; active: boolean }[]
}
const SingleSelectFilter = <T extends string>({ name, options }: SingleSelectFilterProps<T>) => (
  <>
    <p className="font-bold text-xs capitalize">{name}</p>
    <div className="flex space-x-1 text-xs">
      {options.map(({ name: optionName, toggle, active }) => (
        <FilterButton key={`${name}:${optionName}`} onClick={toggle} active={active}>
          <input
            type="radio"
            className="h-4 w-4 border-neutral-800 text-accent-500"
            checked={active}
            readOnly
          />
          <span>{optionName}</span>
        </FilterButton>
      ))}
    </div>
  </>
)

export const createSingleSelectTableFilter = <ItemType, OptionName extends string>(
  name: string,
  optionNames: readonly OptionName[],
  getItemAttribute: (item: ItemType) => Set<OptionName> | OptionName | null
) =>
  new TableFilter<
    ItemType,
    TaggedState<OptionName, SingleSelectTag>,
    TaggedState<OptionName, SingleSelectTag>
  >([
    (taggedState) => (item) => {
      if (taggedState === null || taggedState.state.length === 0) return true

      const itemAttribute = getItemAttribute(item)
      return (
        itemAttribute !== null &&
        (typeof itemAttribute === "string"
          ? itemAttribute.includes(taggedState.state)
          : itemAttribute.has(taggedState.state))
      )
    },
    ({ value: taggedState, onChange }) => {
      const options = [
        {
          name: "all",
          toggle: () => onChange(null),
          active: taggedState === null,
        },
        ...optionNames.map((optionName) => ({
          name: optionName,
          toggle: () => onChange({ tag: "string", state: optionName }),
          active: taggedState !== null && taggedState.state === optionName,
        })),
      ]
      return <SingleSelectFilter name={name} options={options} />
    },
  ])
