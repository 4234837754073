// Auction bid by a participant, only viewable by the bidder themselves
// Subcollection within auction documents

import { Account, AccountId } from "common/model/Account"
import { AuctionStatusType } from "common/model/Auction"
import { UserCreatable } from "common/model/UserCreatable"
import { firestoreConverter } from "../FirestoreConverter"
import { newDocFirestoreConverter } from "../NewDocFirestoreConverter"

export interface NewAuctionBid extends UserCreatable {
  auctionId: string
  accountId: AccountId
  account: Account
  auctionStatusWhenSubmitted: AuctionStatusType
  bid: {
    premiumPerShare: number
    premiumPercent: number
    numShares: number // number of units
    minNumShares: number
  }
  status: "live" | "cancelled" | "updated"
  source: "platform" | "off_platform"
  // opportunityId: string
  // privateOpportunityData: PrivateOpportunityMetadata
  // status (e.g. cancelled) from 'privateOpportunityData'
}

export interface AuctionBid extends NewAuctionBid {
  id: string
}

export const auctionBidConverter = firestoreConverter<AuctionBid>()
export const newAuctionBidConverter = newDocFirestoreConverter<NewAuctionBid>()
