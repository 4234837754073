import { classNames } from "src/utils/classNames"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { relativeDateFormat } from "common/utils/dateUtils"
import { IconWrapperProps } from "@stories/icons/IconWrapper"

type LabelStringOrNode =
  | { labelString: string; labelNode?: never }
  | { labelNode: React.ReactNode; labelString?: never }

type OrderHistoryItemProps = {
  date: Date
  isHorizontal?: boolean
  iconColor?: Color
  iconComponent: React.FunctionComponent<IconWrapperProps>
} & LabelStringOrNode

const OrderHistoryItem = ({
  date,
  isHorizontal,
  iconColor,
  iconComponent: Icon,
  labelNode,
  labelString,
}: OrderHistoryItemProps) => (
  <>
    {isHorizontal ? (
      <Typography
        text={relativeDateFormat(date)}
        size={Size.XXSmall}
        color={Color.Subtitle}
        shouldWrap={false}
      />
    ) : null}
    <div className={classNames("flex gap-1", Color.Dark)}>
      <div className="pt-0.5">
        <Icon size="small" color={iconColor} />
      </div>
      <div className="flex flex-col whitespace-nowrap">
        {labelString ? (
          <Typography text={labelString} weight={Weight.Semibold} size={Size.XSmall} />
        ) : (
          labelNode
        )}
        {!isHorizontal ? (
          <Typography text={relativeDateFormat(date)} size={Size.XXSmall} color={Color.Subtitle} />
        ) : null}
      </div>
    </div>
  </>
)

export default OrderHistoryItem
