import { Account } from "common/model/Account"
import { AccountEventCountsRollup } from "common/model/Analytics/EventCounterRollup"
import * as React from "react"
import AccountsModule from "./AccountsModule"

interface ITrialAccountsModuleProps {
  accounts: Account[]
  weeklyEventCounts: AccountEventCountsRollup[]
  monthlyEventCounts: AccountEventCountsRollup[]
}

const NonBrokerAccountsModule: React.FunctionComponent<ITrialAccountsModuleProps> = ({
  accounts,
  weeklyEventCounts,
  monthlyEventCounts,
}) => {
  const nonBrokerAccounts = accounts.filter(
    (account) => !account.clientType.includes("Intermediary")
  )

  return (
    <AccountsModule
      accounts={nonBrokerAccounts}
      weeklyEventCounts={weeklyEventCounts}
      monthlyEventCounts={monthlyEventCounts}
      title="Non-Broker Accounts"
    />
  )
}

export default NonBrokerAccountsModule
