import { LabelValueList } from "@stories/components/LabelValueList/LabelValueList"
import Typography from "@stories/components/Typography/Typography"
import { collections } from "common/firestore/Collections"
import { Account, AccountIdFields } from "common/model/Account"
import { isLoading } from "common/utils/Loading"
import { useDocument } from "src/firebase/Firestore"
import {
  AccreditationQuestions,
  convertRawEntityTypeToStandForm,
} from "src/pages/Onboarding/controller/Accreditation"

export const AdminAccreditationResponseStatus = ({ account }: { account: AccountIdFields }) => {
  const [dbAccount] = useDocument<Account>(collections.accounts, account.id)

  if (dbAccount === null) return <Typography text="Could not fetch the accreditation status" />

  return !isLoading(dbAccount) && dbAccount.accreditationInfos
    ? dbAccount.accreditationInfos.map((accreditationInfo) => (
        <>
          <LabelValueList
            title="Accreditation Response"
            items={[
              {
                label: "Entity Type",
                value: accreditationInfo.accreditationEntityType,
                id: "entityType",
              },
              ...accreditationInfo.accreditationStatements.flatMap((statement) => {
                const entity = convertRawEntityTypeToStandForm(
                  accreditationInfo.accreditationEntityType
                )
                if (!entity) return []
                const humanReadableStatement = AccreditationQuestions[entity]?.[statement]
                if (!humanReadableStatement) return []
                return {
                  label: "Accreditation Responses",
                  value: humanReadableStatement,
                  id: statement,
                }
              }),
            ]}
          />
        </>
      ))
    : null
}
