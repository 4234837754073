import { isLoaded } from "common/utils/Loading"
import { CRMContactName } from "src/pages/CRM/Contacts/ContactDetailsDrawer/CRMContactName"
import { useCRMContacts } from "src/pages/CRM/Providers/CRMContactsProvider"
import { useCurrentAccount } from "src/queries/currentUser/useCurrentAccount"
import { OrderPageOrder } from "../types"
import OrderPreviewDetail from "./OrderPreviewDetail"
import { nullableToMaybe } from "common/containers/Maybe"

const OrderPreviewContact = ({ order }: { order: OrderPageOrder }) => {
  const { findContactById } = useCRMContacts()

  const account = useCurrentAccount()
  if (!isLoaded(account)) {
    return null
  }

  const contact = nullableToMaybe(order.crmContactId(account)).match(
    (id) => findContactById(id),
    () => null
  )

  if (!contact) {
    return null
  }

  return (
    <OrderPreviewDetail
      label="Contact"
      detailType="private"
      accessLevel={order.fromAccount(account) ? "public" : "never"}
      value={
        <div className="-mx-2">
          <CRMContactName contact={contact} />
        </div>
      }
    />
  )
}

export default OrderPreviewContact
