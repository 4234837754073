import _ from "lodash"
import { tuple } from "./fp/Function"
import { GenericSimpleLens, RecordLens } from "./fp/optics/Lens"
import { PointwiseFunction } from "./types/Record"
import { Intersection } from "./types/Intersection"
import { unique } from "./data/Array/ArrayUtils"
import { Exhaustive } from "./data/Array/Exhaustive"
import { annotate } from "./Coerce"

export type EntryOf<R> = { [key in keyof R]: [key, R[key]] }[keyof R]
export type ReadonlyEntryOf<R> = { [key in keyof R]: readonly [key, R[key]] }[keyof R]

export const mergeRecords = <X extends Record<never, unknown>[]>(...x: X): Intersection<X> =>
  _.merge({}, ...x) as Intersection<X> // TODO fix

// eslint-disable-next-line rulesdir/no-assert
const extremelyUnsafeDoNotExportThisIMeanItAssertComplete = <R>(x: Partial<R>): R => x as R

// TODO: figure out how to implement a decent transpose

// Typescript distributes parameters used in conditionals over unions, so `(A | B) => X` becomes `(A => X) | (B => X)` = `A & B => X`
/** DualizeUnion<A | B> = A & B */
export type DualizeUnion<T> = (T extends unknown ? (t: T) => void : never) extends (
  t: infer T2
) => void
  ? T2
  : never

export type Projection<R extends Record<keyof R, unknown>> = {
  [key in keyof R]: (t: R[key]) => R[key]
}
export const partitionOverlapped = <R extends Record<keyof R, unknown>>(
  p: Projection<R>,
  o: R[keyof R]
): R => Rec.indexedMap(p, ([k, f]) => f(o)) as R

/*
type Pointwise<
  K extends string | number | symbol,
  R1 extends Record<K, unknown>,
  R2 extends Record<K, unknown>
> = DualizeUnion<
  Required<{
    [key in K]: (x: R1[key]) => R2[key]
  }>[K]
> */

/*
type PointwiseWithKey<R1, R2 extends Record<keyof R1, unknown>> = DualizeUnion<
  Required<{
    [key in keyof R1]: (k: key, x: R1[key]) => R2[key]
  }>[keyof R1]
>
*/
type Pointwise<R, V> = (x: R[keyof R]) => V
type PointwiseWithKey<R, V> = (x: EntryOf<R>) => V

export type GenericFields<R> = {
  [key in keyof R]: GenericSimpleLens<key>
}

export type Fields<R> = {
  [key in keyof R]: <B>() => RecordLens<
    Pick<R, key>,
    { [key2 in keyof Pick<R, key>]: B },
    R[key],
    B
  >
}

export type SimpleFields<R> = {
  [key in keyof R]: RecordLens<Pick<R, key>, Pick<R, key>, R[key], R[key]>
}

export type Zipped<L, R extends Record<keyof L, unknown>> = { [key in keyof L]: [L[key], R[key]] }
export type DeepZipped<L, R extends Record<keyof L, unknown>> = {
  [key in keyof L]: R[key] extends Record<keyof L[key], unknown>
    ? Zipped<L[key], R[key]>
    : [L[key], R[key]]
}
export type PureRecord<R, X> = {
  [key in keyof R]: R[key] extends Record<string | number | symbol, unknown>
    ? PureRecord<R[key], X>
    : X
}

const RecInternal = {
  Functor: {
    /** Apply a function to every key-value pair of a record, using the result as the new value of the corresponding field */
    indexedMap: <R extends Record<keyof R, unknown>, V>(
      x: R,
      f: PointwiseWithKey<R, V>
    ): Record<keyof R, V> =>
      UnsafeRec.fromEntries<Record<keyof R, V>>(
        UnsafeRec.entries(x).map(([k, v]) => tuple(k)(f([k, v])))
      ),
    /** Apply a function to every field of a record */
    map: <R extends Record<keyof R, unknown>, V>(x: R, f: Pointwise<R, V>): Record<keyof R, V> =>
      RecInternal.Functor.indexedMap(x, ([k, v]) => f(v)),

    mapIntersect: <R extends Record<keyof R, object>, Y>(
      x: R,
      y: Y
    ): { [key in keyof R]: R[key] & Y } =>
      Rec.indexedMap(x, ([k, v]) => ({ ...v, ...y })) as { [key in keyof R]: R[key] & Y },
    // Better polymorphism in exchange for not changing the result type
    // TODO: see if we can have both
    /** Apply a function to every field of a record */
    mapSimple: <R extends Record<never, unknown>>(
      x: R,
      f: <K extends keyof R>(x: R[K]) => R[K]
    ): R => Rec.indexedMapSimple(x, (__, v) => f(v)),

    /** Apply a function to every key-value pair of a record, using the result as the new value of the corresponding field */
    indexedMapSimple: <R extends Record<never, unknown>>(
      x: R,
      f: <K extends keyof R>(...entries: [K, R[K]]) => R[K]
    ): R => UnsafeRec.fromEntries<R>(UnsafeRec.entries(x).map(([k, v]) => [k, f(k, v)])),
    indexedMapCurried:
      <R extends Record<keyof R, unknown>>(x: R) =>
      <V2>(f: (entries: EntryOf<R>) => V2): { [key in keyof R]: V2 } =>
        UnsafeRec.fromEntries<Record<keyof R, V2>>(UnsafeRec.entries(x).map((e) => [e[0], f(e)])), // TODO cleanup
  },
  Indexable: {
    /** A wrapper for object indexing that allows trying to access keys that are not known to exist. Should be used only when necessary. */
    index: <K, R>(r: R, key: K): R[K extends keyof R ? K : never] | undefined =>
      // eslint-disable-next-line rulesdir/no-assert
      r[key as K extends keyof R ? K : never],

    tabulatePartial: <K extends string | number | symbol, V>(ks: K[], f: (k: K) => V) =>
      Rec.fromEntries<Record<K, V>>(ks.map((k) => tuple(k)(f(k)))),

    tabulate: <K extends string | number | symbol, V>(
      ks: Exhaustive<K>,
      f: (k: K) => V
    ): Record<K, V> => Rec.tabulatePartial(ks, f) as Record<K, V>,

    unsafeTabulate: <K extends string | number | symbol, V>(
      ks: K[],
      f: (k: K) => V
    ): Record<K, V> => Rec.tabulatePartial(ks, f) as Record<K, V>,

    field:
      <K extends string | number | symbol>(k: K) =>
      <V1, V2>(): RecordLens<{ [k in K]: V1 }, { [k in K]: V2 }, V1, V2> => ({
        view: (r) => r[k],
        over: (r, f) => ({ ...r, [k]: f(r[k]) }),
      }),

    fields: <R extends Record<never, unknown>>(r: R): Fields<R> =>
      UnsafeRec.fromEntries<Fields<R>>(
        UnsafeRec.entries(r).map(([k, __]) => tuple(k)(RecInternal.Indexable.field(k)))
      ),
    genericFields: <R extends Record<never, unknown>>(r: R): GenericFields<R> =>
      UnsafeRec.fromEntries<GenericFields<R>>(
        UnsafeRec.entries(r).map(([k, __]) =>
          tuple(k)(RecInternal.Indexable.field(k)())
        ) as EntryOf<GenericFields<R>>[]
      ),
  },
  Applicative: {
    /** Apply a record of functions to a corresponding record of parameters, and collect the results into a new record. */
    apply: <
      X extends { [key in K]: unknown },
      Y extends { [key in K]: unknown },
      K extends string | number | symbol = keyof X
    >(
      fs: { [key in K]: (x: X[key]) => Y[key] },
      x: X
    ): Y => UnsafeRec.fromEntries<Y>(UnsafeRec.keys(fs).map((k) => [k, fs[k](x[k])])),

    /** As `apply`, but with functions of two arguments, and two parameter arrays. */
    zipWith:
      <X, Y extends { [key in keyof X]: unknown } = X, Z extends { [key in keyof X]: unknown } = Y>(
        fs: PointwiseFunction<X, Y, Z>
      ) =>
      (l: X, r: Y): Z =>
        UnsafeRec.fromEntries<Z>(UnsafeRec.keys(fs).map((k) => [k, fs[k](l[k], r[k])])),

    zip: <L, R extends Record<keyof L, unknown>>(l: L, r: R): Zipped<L, R> =>
      Rec.zipWith<L, R, Zipped<L, R>>(
        Rec.fill(l, <S, T>(x: S, y: T) => tuple(x)(y)) as Required<{
          [key in keyof L]: (l: L[key], r: R[key]) => Zipped<L, R>[key]
        }>
      )(l, r),

    /** Replace every field of a record with a given value. */
    fill: <R extends Record<keyof R, unknown>, T>(r: R, t: T): { [key in keyof R]: T } =>
      Rec.map(r, () => t),
  },
}

export const Rec = {
  /** Reassemble a record from an array of key-value pairs. Since the keys aren't statically know to be exhaustive, this produces a `Partial<R>` rather than an `R`. */
  fromEntries: <R extends Record<never, unknown>>(
    entries: Iterable<ReadonlyEntryOf<R> | EntryOf<R>>
  ): Partial<R> =>
    // eslint-disable-next-line rulesdir/no-assert
    Object.fromEntries(entries) as Partial<R>,
  ...RecInternal.Functor,
  ...RecInternal.Indexable,
  ...RecInternal.Applicative,
  /** Apply a filter function to every key-value pair of a record */
  filter: <R extends Record<never, unknown>>(x: R, f: (entry: EntryOf<R>) => boolean): Partial<R> =>
    UnsafeRec.fromEntries<R>(UnsafeRec.entries(x).filter(([k, v]) => f([k, v]))),

  /** Check a condition function on every key-value pair of a record */
  every: <R extends Record<never, unknown>>(x: R, f: (entry: EntryOf<R>) => boolean): boolean =>
    UnsafeRec.entries(x).every(([k, v]) => f([k, v])),

  /** Merge two records, favoring the right in case of duplicate keys */
  merge: <L, R>(l: L, r: R): L & R => ({ ...l, ...r }),

  /** Merge two records, using the provided function to handle duplicate keys */
  mergeBy: <K extends string, V>(
    l: Record<K, V>,
    r: Record<K, V>,
    f: (x: V, y: V) => V
  ): Record<K, V> =>
    UnsafeRec.fromEntries(
      unique(
        UnsafeRec.keys(l)
          .concat(UnsafeRec.keys(r))
          .map((k) => (k in l && k in r ? [k, f(l[k], r[k])] : k in l ? [k, l[k]] : [k, r[k]]))
      )
    ),
}

// the distinction between safe and unsafe functions here is somewhat arbitrary - almost all object manipulation in typescript is unsafe, in the final analysis - but these are particularly likely to cause problems if used on a record with more entries than expected.
export const UnsafeRec = {
  /** As `Rec.fromEntries`, but asserting that all keys are present. Do not use unless you can demonstrate that this assertion is correct. */
  fromEntries: <R extends Record<never, unknown>>(
    entries: Iterable<ReadonlyEntryOf<R> | EntryOf<R>>
  ): R => extremelyUnsafeDoNotExportThisIMeanItAssertComplete(Rec.fromEntries(entries)),

  /** List all keys of an object. Safe only when used on object literals - in general, extra keys may be present if `r` has fields not mentioned in `R`. */
  keys: <R extends object>(r: R): (keyof R)[] =>
    // eslint-disable-next-line rulesdir/no-assert
    Object.keys(r).map((k) => k as keyof R),

  /** List all values of an object. Safe only when used on object literals. */
  values: <K extends string | number | symbol, V>(x: { [key in K]?: V }): V[] =>
    // eslint-disable-next-line rulesdir/no-assert
    Object.values(x).map((v) => v as V),

  /** List all key-value pairs of an object. Safe only when used on object literals. */
  entries: <R extends Record<never, unknown>>(x: R): Exclude<EntryOf<R>, undefined>[] =>
    // eslint-disable-next-line rulesdir/no-assert
    Object.entries(x).map((kvp) => kvp as Exclude<EntryOf<R>, undefined>),
}

// TODO put field manipulation in own file

// Sometimes the type inference algorithm needs an unreasonable amount of handholding
export const field =
  <K extends string | number | symbol, T>(k: K) =>
  (x: { [key in K]: T }): T =>
    x[k]

// better inference properties than `overField`, but less flexible
export const overFieldSimple = <R, K extends keyof R>(k: K, f: (v: R[K]) => R[K], r: R): R => ({
  ...r,
  ...viewFieldWith(k, f, r),
})

/**
 * @returns the original object, except for the field k substituted with f(r[k])
 */
export const overField = <R, V, K extends keyof R>(
  /** Should only ever be a string literal */
  k: K,
  f: (v: R[K]) => V,
  r: R
): Omit<R, K> & { [key in K]: V } => ({ ...r, ...viewFieldWith(k, f, r) })
export const overFieldCurried =
  <R, V, K extends keyof R>(
    /** Should only ever be a string literal */
    k: K,
    f: (v: R[K]) => V
  ) =>
  (r: R): Omit<R, K> & { [key in K]: V } => ({ ...r, ...viewFieldWith(k, f, r) })

export const setField = <
  K extends string | number | symbol,
  V,
  R extends { [key in K]?: unknown } = { [key in K]?: unknown }
>(
  k: K,
  v: V,
  r: R
) => overField(k, () => v, r)
export const setFieldSimple = <
  K extends string | number | symbol,
  R extends { [key in K]?: unknown } = { [key in K]?: unknown }
>(
  k: K,
  v: R[K],
  r: R
) => overFieldSimple(k, () => v, r)

export const viewFieldWith = <R, V, K extends keyof R>(k: K, f: (v: R[K]) => V, r: R) => ({
  [k]: f(r[k]),
})

/** Field declarations further to the right take priority. */
export const concatPartialRecords = <R>(rs: Partial<R>[]) =>
  rs.reduce((x, y) => ({ ...x, ...y }), annotate<Partial<R>>({}))
