import { Account } from "./Account"
import { AccountClientType } from "./Account/AccountClientType"
import { DealAndOrderStageGroup } from "./DealCRM/Deal/DealCRMDeal"
import { ManagementFeeStructure } from "./DealCRM/DealCRMInterest"

export const thirdPartyCRMOptions = ["salesforce", "hubspot", "other"] as const
export type ThirdPartyCRM = (typeof thirdPartyCRMOptions)[number]

export interface AccountCRMSettings {
  thirdPartyCRM?: ThirdPartyCRM | null
  defaultBrokerCommissionPercent?: number | null // percent, 0.05 represents 5% commission
  defaultManagementFeePercent?: number | null // percent, 0.01 represents 1% management fee
  defaultCarryPercent?: number | null // percent, 0.10 represents 10% carry
  defaultManagementFeeStructure?: ManagementFeeStructure | null
  deals: {
    showBuyerSPVFees: boolean
    showBrokerFees: boolean
    dealFields: {
      priority: boolean
      urgency: boolean
    }
    participantFields: {
      priority: boolean
      urgency: boolean
      targetAmount: boolean
    }
  }
  emailSync?: {
    syncAllContactsByDefault?: boolean
  }
  dealAndOrderStages: Record<DealAndOrderStageGroup, string[]>
}

const defaultOrderStages: DefaultBrokerOrderStages[] = ["Tentative", "Firm"]

export const defaultBrokerDealStages = {
  matched: "Matched",
  inDiligence: "In Diligence",
  docsSigned: "Docs Signed",
  inROFR: "In ROFR",
  wiring: "Wiring",
  closed: "Closed",
} satisfies Record<string, DefaultBrokerDealStages>

export const defaultFundOrderStages = {
  tentativeOrders: "Tentative",
  firmOrders: "Firm",
} satisfies Record<string, DefaultFundOrderStages>

export const defaultFundDealStages = {
  prospecting: "Prospecting",
  active: "Active",
  confirmingCommitments: "Confirming Commitments",
  callingCapital: "Calling Capital",
  closed: "Closed",
} satisfies Record<string, DefaultFundDealStages>

const defaultCancelledStage: CancelledStage = "Cancelled"
type CancelledStage = "Cancelled"

type DefaultBrokerOrderStages = "Firm" | "Tentative"
type DefaultFundOrderStages = "Firm" | "Tentative"
type DefaultBrokerDealStages =
  | "Matched"
  | "Docs Signed"
  | "In ROFR"
  | "In Diligence"
  | "Wiring"
  | "Closed"
type DefaultFundDealStages =
  | "Prospecting"
  | "Active"
  | "Confirming Commitments"
  | "Calling Capital"
  | "Closed"

export type DefaultDealStageType = DefaultBrokerDealStages | DefaultFundDealStages | CancelledStage
export type DefaultOrderStageType =
  | DefaultBrokerOrderStages
  | DefaultFundOrderStages
  | CancelledStage

export const defaultAccountCRMSettings = (
  clientTypes: AccountClientType[]
): AccountCRMSettings => ({
  deals: {
    showBuyerSPVFees: clientTypes.includes("Investor/Shareholder"),
    showBrokerFees: clientTypes.includes("Intermediary"),
    dealFields: {
      priority: true,
      urgency: false,
    },
    participantFields: {
      priority: true,
      urgency: false,
      targetAmount: true,
    },
  },
  emailSync: {
    syncAllContactsByDefault: true, // Change this before release
  },
  dealAndOrderStages: clientTypes.includes("Intermediary")
    ? {
        orders: defaultOrderStages,
        potentialDeals: [defaultBrokerDealStages.matched],
        inClosing: [
          defaultBrokerDealStages.inDiligence,
          defaultBrokerDealStages.docsSigned,
          defaultBrokerDealStages.inROFR,
          defaultBrokerDealStages.wiring,
        ],
        closed: [defaultBrokerDealStages.closed],
        cancelled: [defaultCancelledStage],
      }
    : {
        orders: defaultOrderStages,
        potentialDeals: [defaultFundDealStages.prospecting],
        inClosing: [
          defaultFundDealStages.active,
          defaultFundDealStages.confirmingCommitments,
          defaultFundDealStages.callingCapital,
        ],
        closed: [defaultFundDealStages.closed],
        cancelled: [defaultCancelledStage],
      },
})

export const buildAccountCRMSettings = (
  account: Pick<Account, "crmPreferences" | "clientType">
): NonNullable<AccountCRMSettings> =>
  account.crmPreferences ?? defaultAccountCRMSettings(account.clientType)
