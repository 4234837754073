import React from "react"
import { Dropdown, DropdownDisplayProps } from "./Dropdown"

type YesNoNullDropdownProps = {
  value: boolean | null
  onChange: (value: boolean | null) => void
  label?: string | JSX.Element
}

const YesNoNullDropdown: React.FC<
  YesNoNullDropdownProps & Omit<DropdownDisplayProps<string>, "label" | "options">
> = ({ value, onChange, label, ...rest }) => {
  const options = [
    { name: "", id: "" },
    { name: "Yes", id: "Yes" },
    { name: "No", id: "No" },
  ]

  const handleChange = (option: { name: string; id: string }) => {
    const selectedValue = option.id === "" ? null : option.id === "Yes"
    onChange(selectedValue)
  }

  const inputValue = value === null ? "" : value ? "Yes" : "No"

  return (
    <Dropdown
      {...rest}
      minWidthPx={140}
      label={label ?? <></>}
      options={options}
      selected={{ name: inputValue, id: inputValue }}
      setSelected={handleChange}
    />
  )
}

export default YesNoNullDropdown
