import { PlatformPageInvite } from "common/model/PlatformPageInvite"
import { Account } from "common/model/Account"
import { PartialRFQResponse, RFQResponse, RFQType } from "common/model/RFQ/RFQ"
import { firestoreConverter } from "../model/FirestoreConverter"

export const accountConverter = firestoreConverter<Account>()

export const rfqConverter = firestoreConverter<RFQType>()

export const rfqResponseConverter = firestoreConverter<RFQResponse>()
export const partialRfqResponseConverter = firestoreConverter<PartialRFQResponse>()

export const platformPageInviteConverter = firestoreConverter<PlatformPageInvite>()
