import { BlurredText } from "@components/BlurredText"
import { CurrentUser } from "@model/CurrentUser"
import { Tooltip } from "@stories/components/Antd"
import { isActiveTrialCustomer } from "common/model/Account"
import { isDataCustomer, isMarketsCustomer } from "common/model/Auth/Permissions"
import { Func } from "common/utils/fp/Function"
import { User } from "common/model/User"
import { useAccountAccessLevel } from "./useAccountAccessLevel"
import { isGhosting } from "src/Routes/auth/UserAuthContext"
import { isLoaded } from "common/utils/Loading"

export const dataAccessLevels = [
  "public",
  "trading",
  "data",
  "dataLite",
  "connect",
  "full",
  "admin",
  "never",
  "hideFromCaplight",
] as const
export type DataAccessLevel = (typeof dataAccessLevels)[number]

export const hasFullDataAccess = (account: User["account"]) =>
  hasPartialDataAccess(account) && !account.clientType.includes("Journalist")

export const hasPartialDataAccess = (account: User["account"]) =>
  isDataCustomer(account) || isActiveTrialCustomer(account)

export const hasConnectDataAccess = (account: User["account"]) =>
  hasPartialDataAccess(account) || isMarketsCustomer(account)

export const checkAccessLevelByAccount: Record<
  DataAccessLevel,
  Func<User["account"] | null, boolean>
> = {
  public: () => true,
  trading: (a) => a !== null,
  data: (a) => (a ? hasFullDataAccess(a) : false),
  dataLite: (a) => (a ? hasPartialDataAccess(a) : false),
  connect: (a) => (a ? hasConnectDataAccess(a) : false),
  full: (a) => (a ? hasFullDataAccess(a) : false),
  admin: () => false,
  never: () => false,
  hideFromCaplight: () => true,
}

export const checkAccessLevel: Record<DataAccessLevel, Func<CurrentUser | null, boolean>> = {
  public: () => true,
  trading: (u) => u !== null,
  data: (u) => (u ? hasFullDataAccess(u.user.account) : false),
  dataLite: (u) => (u ? hasPartialDataAccess(u.user.account) : false),
  connect: (u) => (u ? hasConnectDataAccess(u.user.account) : false),
  full: (u) => (u ? hasFullDataAccess(u.user.account) : false),
  admin: (u) => (u ? u.isAdmin : false),
  never: () => false,
  hideFromCaplight: (u) => !(isLoaded(u) && isGhosting(u)),
}

const accessDeniedTooltip: Record<DataAccessLevel, string> = {
  public: "",
  trading: "",
  data: "Access to this information requires a full Caplight Data subscription. Please contact us at contact@caplight.com to learn more.",
  dataLite:
    "Access to this information requires a full Caplight Data subscription. Please contact us at contact@caplight.com to learn more.",
  connect:
    "Access to this information requires a full Caplight Data subscription. Please contact us at contact@caplight.com to learn more.",
  full: "",
  admin: "",
  never: "",
  hideFromCaplight: "",
}

export const AccessBoundary: React.FC<
  React.PropsWithChildren<{
    accessLevel: DataAccessLevel
    fallbackClassName?: string
  }>
> = ({ accessLevel, children, fallbackClassName = "" }) => {
  const fallback = (
    <Tooltip className={fallbackClassName} title={accessDeniedTooltip[accessLevel]}>
      <div>
        <BlurredText textLength="word" />
      </div>
    </Tooltip>
  )
  return useAccountAccessLevel(accessLevel) ? <>{children}</> : fallback
}
