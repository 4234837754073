import { SearchInput } from "@components/inputs/SearchInput"
import { TableFilter } from "./TableFilter"
import { SingleSelectTag, TaggedState } from "./types"

export const substringFilter = <T,>(
  f: (t: T) => string,
  options?: { placeholder?: string; id?: string; heapName?: string }
) =>
  new TableFilter([
    (s: string) => (t: T) => f(t).toLowerCase().indexOf(s.toLowerCase()) !== -1,
    ({ value, onChange }: { value: string | null; onChange: (s: string) => void }) => (
      <div className="w-56">
        <SearchInput
          id={options?.id}
          key="substringFilter"
          placeholder={options?.placeholder}
          value={value || undefined}
          onChange={onChange}
          heapName={options?.heapName || "substring-filter"}
        />
      </div>
    ),
  ] as const)

export const taggedStateSubstringFilter = <T,>(
  f: (t: T) => string,
  options?: { placeholder?: string; id?: string; heapName?: string }
) =>
  new TableFilter<T, TaggedState<string, SingleSelectTag>, TaggedState<string, SingleSelectTag>>([
    (taggedState) => (item) =>
      taggedState === null || f(item).toLowerCase().includes(taggedState.state.toLowerCase()),
    ({
      value,
      onChange,
    }: {
      value: TaggedState<string, "string"> | null
      onChange: (s: TaggedState<string, "string"> | null) => void
    }) => (
      <SearchInput
        id={options?.id}
        key="taggedStateSubstringFilter"
        placeholder={options?.placeholder}
        value={value?.state ?? ""}
        onChange={(next) => onChange({ tag: "string", state: next })}
        heapName={options?.heapName || "substring-filter"}
      />
    ),
  ])
