import { OrderPreview, OrderPreviewWrapper } from "@components/OrderFormComponents/OrderPreview"
import { OrderCardHeader } from "@components/OrderFormComponents/OrderSelector/OrderCardHeader"
import { Card } from "@stories/components/Antd"
import { restrictedCollections } from "common/firestore/Collections"
import { Order } from "common/model/Order/Order"
import {
  isLiveOrder,
  isTerminalOrderStatus,
  orderLiveUntil,
  orderStaleUntil,
  orderStatus,
} from "common/model/Order/Types/Status"
import { flattenOrderForDisplay } from "common/queries/pricing/displays/Order"
import { Loading, isLoading, mapLoading } from "common/utils/Loading"
import { shortDateFormat } from "common/utils/dateUtils"
import { FirestoreError } from "firebase/firestore"
import { useMemo } from "react"
import { useParams } from "react-router-dom"
import Spinner from "../../../components/icons/Spinner"
import { useDocument } from "../../../firebase/Firestore"
import { DisplayField } from "../DisplayField"
import { AdminOrderNoteEditor } from "../editing/AdminOrderNotesEditor"
import { PublicAdminOrderNoteEditor } from "../editing/PublicAdminNotesEditor"
import { CancelOrderButton, HideOrderButton, ResurrectOrderButton } from "./OrderStatusControls"
import { AdminIgnoreQuotaComplianceViolationCheckbox } from "./OrderQuotaComplianceControls"
import { AdminHighlightOrderCheckbox } from "./OrderHighlightControls"
import { isOrderQuotaCompliant } from "common/model/Order/QuotaCompliance/checkAccountQuotaCompliance"
import { OrderOutlierStatusDisplay } from "./OutlierStatusDisplay"
import { isUserFormDataSource } from "common/model/data-product/DataSource"

export const ViewOrder: React.FC<{ darkpool: boolean }> = ({ darkpool }) => {
  const { orderId } = useParams<{ orderId: string }>()
  const [order]: [Loading<Order>, FirestoreError | null] = useDocument<Order>(
    darkpool
      ? restrictedCollections.darkpoolOrderObservations
      : restrictedCollections.orderObservations,
    orderId ?? ""
  )
  const flattenedOrder = useMemo(() => mapLoading(flattenOrderForDisplay)(order), [order])

  if (isLoading(order) || isLoading(flattenedOrder)) {
    return <Spinner size="md" />
  }

  if (order === null || flattenedOrder === null) {
    return <div>Error loading this order</div>
  }

  const submitterEmail = isUserFormDataSource(order.source)
    ? order.source.submittingUser?.email ?? null
    : null

  const { privateNotes } = order

  const metaItems: { name: string; value: string | null }[] = [
    { name: "Created On", value: shortDateFormat(order.createdAt) },
    { name: "Live Until", value: shortDateFormat(orderLiveUntil(order)) },
    { name: "Stale Until", value: shortDateFormat(orderStaleUntil(order)) },
    { name: "Account", value: order.source.account.name },
    { name: "Type", value: order.source.sourceType },
    {
      name: "Raw API Doc ID",
      value: order.source.sourceType === "api" ? order.source.unparsedDocumentId : null,
    },
    {
      name: "Creator",
      value: submitterEmail ?? "n/a",
    },
    {
      name: "Notes",
      value: privateNotes ?? "n/a",
    },
    { name: "Views", value: (order.viewTracking?.totalViewCount || 0).toString() },
  ]
  const status = orderStatus(order)
  const statusItems: { name: string; value: string }[] = [
    { name: "Most Recent Reported Status", value: status.tag },
    ...(isTerminalOrderStatus(status)
      ? [{ name: "Reason", value: status.reason ?? "unspecified" }]
      : []),
    {
      name: "As of",
      value: shortDateFormat(status.asOf),
    },
    {
      name: "Hidden",
      value: order.hidden ? "true" : "false",
    },
  ]
  const quotaComplianceItems: { name: string; value: string }[] = [
    {
      name: "Order Counts Towards Quota",
      value: isOrderQuotaCompliant(order) ? "counts" : "does not count",
    },
    {
      name: "Flagged For",
      value: order.orderQuotaComplianceViolations
        ? order.orderQuotaComplianceViolations.map((v) => v.tag).join("; ") || "not flagged"
        : "compliance not ran",
    },
    {
      name: "Live For Quota",
      value: isLiveOrder(order) ? "live for quota" : "not live for quota",
    },
  ]

  return (
    <div className="grid grid-cols-4 gap-4">
      <div className="grid grid-cols-1 gap-4">
        <div>
          <h2 className="font-bold py-2 text-lg">Info</h2>
          <div className="overflow-hidden rounded-md bg-neutral-white shadow">
            <ul className="divide-y divide-neutral-400">
              {metaItems.map(({ name, value }) =>
                value ? (
                  <li key={name} className="px-6 py-4">
                    <DisplayField name={name} value={value} />
                  </li>
                ) : null
              )}
            </ul>
          </div>
        </div>
        <AdminOrderNoteEditor order={order} side="Anchor" />
        <PublicAdminOrderNoteEditor order={order} />
      </div>
      <div className="col-span-1">
        <h2 className="font-bold py-2 text-lg">Status</h2>
        <div className="overflow-hidden rounded-md bg-neutral-white shadow">
          <ul className="divide-y divide-neutral-400">
            <div className="p-4">
              <AdminHighlightOrderCheckbox order={order} />
            </div>
            {statusItems.map(({ name, value }) => (
              <li key={name} className="px-6 py-4">
                <DisplayField name={name} value={value} />
              </li>
            ))}
          </ul>
          <div className="flex flex-row p-4 space-x-4">
            <CancelOrderButton order={order} />
            <ResurrectOrderButton order={order} />
            <HideOrderButton order={order} />
          </div>
        </div>
      </div>
      <div className="col-span-2">
        <h2 className="font-bold py-2 text-lg">Order</h2>
        <Card size="small">
          <OrderCardHeader
            company={order.company}
            direction={order.direction}
            createdAt={order.createdAt}
            darkpool={order.darkpool}
          />
          <OrderPreviewWrapper>
            <OrderPreview order={order} />
          </OrderPreviewWrapper>
        </Card>
        <h2 className="font-bold py-2 text-lg">Outlier Status</h2>
        <div className="overflow-hidden rounded-md bg-neutral-white shadow">
          <OrderOutlierStatusDisplay order={order} />
        </div>
        <h2 className="font-bold py-2 text-lg">Quota Compliance Status</h2>
        <div className="overflow-hidden rounded-md bg-neutral-white shadow">
          <ul className="divide-y divide-neutral-400">
            {quotaComplianceItems.map(({ name, value }) => (
              <li key={name} className="px-6 py-4">
                <DisplayField name={name} value={value} />
              </li>
            ))}
            <div className="p-4">
              <AdminIgnoreQuotaComplianceViolationCheckbox order={order} />
            </div>
          </ul>
        </div>
      </div>
    </div>
  )
}
