/** Put all and only constants for the order business logic in this file.  */
import { OrderLookupFields } from "./Models/Internal"

export const LIVE_UNTIL_DEFAULT_DAYS = 10

export const STALE_AFTER_LIVE_UNTIL_DAYS = 20

export const MAX_DAYS_BETWEEN_DUPLICATE_ORDERS = 7 // Orders with the same exact terms are considered duplicates if they are within this many days of each other

// TODO move this somewhere more appropriate
export const adminOrderRoute = (o: OrderLookupFields) =>
  `/admin/records/${o.darkpool ? "darkpool_" : ""}order_observations/${o.id}`
