import { filterMaybe, Just, Nothing, nullableToMaybe } from "common/containers/Maybe"
import { justIfNonempty } from "common/utils/data/Array/Nonempty"
import OrderTermSheetSection from "../OrderTermSheetSection"
import { UNSPECIFIED_VALUE } from "./constants"
import { orderDataAccessLevel } from "../../../shared/accessLevels"
import { OrderPageSectionProps } from "./types"
import {
  sourceAttributionImmediateSource,
  sourceAttributionTail,
  sourceEmail,
  sourceFirm,
  sourceName,
} from "common/model/data-product/DataPoint/SourceAttribution"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import { isLoaded } from "common/utils/Loading"

export const COUNTERPARTY_SECTION_TITLE = "Counterparty Details"

const CounterpartySection = ({ order, hideUnspecified }: OrderPageSectionProps) => {
  const user = useCurrentUser()
  const accessLevel = orderDataAccessLevel.clientRelationship(order.isConnectable())
  if (!isLoaded(user)) {
    return null
  }
  if (order.isPrivate) {
    return (
      <OrderTermSheetSection
        title={COUNTERPARTY_SECTION_TITLE}
        rows={filterMaybe([
          order
            .sourceAttribution(user.user)
            .bind(sourceAttributionTail)
            .bind(sourceAttributionImmediateSource)
            .map((source) => ({
              label: "Counterparty",
              value: `${sourceName(source).match(
                (name) =>
                  `${name} ${sourceFirm(source).match(
                    (firm) => `(${firm})`,
                    () => ""
                  )}`,
                () => sourceFirm(source).withDefault("")
              )}`,
              accessLevel,
            })),
          order
            .sourceAttribution(user.user)
            .bind(sourceAttributionTail)
            .bind(sourceAttributionImmediateSource)
            .bind(sourceEmail)
            .map((email) => ({
              label: "Counterparty Email",
              value: email,
              accessLevel,
            })),
        ])}
        hideUnspecified={hideUnspecified}
      />
    )
  }

  const {
    relationship: brokerClientRelationship,
    // previousClientTransaction,
    // investmentCommitteeInvolved,
    // investmentCommitteeApproved,
  } = order.unboxed.order.base.brokerClientMetadata ?? {
    relationship: null,
    confirmedAt: null,
    profile: null,
    previousClientTransaction: null,
    investmentCommitteeInvolved: null,
    investmentCommitteeApproved: null,
  }

  const counterpartyTypeRow = justIfNonempty(order.sourceClientType())
    .bind((clientType) => {
      if (clientType.includes("Intermediary")) return Just("Broker")
      if (clientType.includes("Investor/Shareholder")) {
        return Just(order.direction() === "buy" ? "Investor" : "Shareholder")
      }
      return Nothing
    })
    .map((counterpartyType) => ({
      label: "Counterparty type",
      value: counterpartyType,
      accessLevel,
    }))

  const fundsOnHandRow = nullableToMaybe(order.unboxed.order.base.buyerCashOnHand).map(
    (hasCashOnHand) => ({
      label: "Funds on hand",
      value: hasCashOnHand ? "Yes" : "No",
      accessLevel,
    })
  )

  const directToClientRow = nullableToMaybe(brokerClientRelationship)
    .map<"Yes" | "No" | typeof UNSPECIFIED_VALUE>((relationship) =>
      relationship === "direct" ? "Yes" : "No"
    )
    .or(Just(UNSPECIFIED_VALUE))
    .map((value) => ({ label: "Direct to client", value, accessLevel }))

  // const clientProfileRow = nullableToMaybe(clientProfile)
  //   .or(Just(UNSPECIFIED_VALUE))
  //   .map((value) => ({
  //     label: "Client profile",
  //     value,
  //     accessLevel,
  //   }))

  // !- sensitive?
  // const previousClientTransactionRow = nullableToMaybe(previousClientTransaction)
  //   .map((v) => (v ? "Yes" : "No"))
  //   .or(Just(UNSPECIFIED_VALUE))
  //   .map((value) => ({
  //     label: "Previous Client Transaction",
  //     value,
  //     accessLevel,
  //   }))
  // const investmentCommitteeInvolvedRow = nullableToMaybe(investmentCommitteeInvolved)
  //   .map((v) => (v ? "Yes" : "No"))
  //   .or(Just(UNSPECIFIED_VALUE))
  //   .map((value) => ({
  //     label: "Investment Committee Involved",
  //     value,
  //     accessLevel,
  //   }))
  // const investmentCommitteeApprovedRow = nullableToMaybe(investmentCommitteeApproved)
  //   .map((v) => (v ? "Yes" : "No"))
  //   .or(Just(UNSPECIFIED_VALUE))
  //   .map((value) => ({
  //     label: "Investment Committee Approved",
  //     value,
  //     accessLevel,
  //   }))

  const clientInfoRows = counterpartyTypeRow
    .map(({ value }) => (value === "Broker" ? filterMaybe([directToClientRow]) : []))
    .withDefault([])

  return (
    <OrderTermSheetSection
      title={COUNTERPARTY_SECTION_TITLE}
      rows={filterMaybe([counterpartyTypeRow, fundsOnHandRow]).concat(clientInfoRows)}
      hideUnspecified={hideUnspecified}
    />
  )
}

export default CounterpartySection
