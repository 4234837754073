import { Order } from "common/model/Order/Models/Wrapped"
import { deprecatedIsLoaded } from "common/utils/Loading"
import { Subtitle } from "../../../../../components/typography/Subtitle"
import { useCurrentUser } from "../../../../../providers/currentUser/useCurrentUser"
import { useOrderLatestUpdates, useOrderUpdateLog } from "../fns/useOrderUpdateLog"
import OrderHistoryOrderStatus from "./OrderHistoryOrderStatus"
import OrderHistoryPriceUpdateItem from "./OrderHistoryPriceUpdateItem"

const LoadedOrderLog = ({ order }: { order: Order }) => {
  const { priceUpdates } = useOrderUpdateLog(order)
  const { maybeTerminalStatus } = useOrderLatestUpdates(order)
  const currentUser = useCurrentUser()
  if (!deprecatedIsLoaded(currentUser) || !order.fromAccount(currentUser.user.account)) return null

  return (
    <div className="flex flex-col gap-2">
      <Subtitle subtitle="All Your Updates" />
      <div className="grid gap-x-2 gap-y-1" style={{ gridTemplateColumns: "min-content auto" }}>
        {maybeTerminalStatus.match(
          (orderStatus) => (
            <OrderHistoryOrderStatus orderStatus={orderStatus} isHorizontal />
          ),
          () => null
        )}
        {priceUpdates.map((pricePair) => (
          <OrderHistoryPriceUpdateItem
            key={pricePair[0].date().toString()}
            pricePair={pricePair}
            isHorizontal
          />
        ))}
      </div>
    </div>
  )
}

export default LoadedOrderLog
