import { Account } from "common/model/Account"
import { ProductArea } from "common/model/Auth/Permissions"
import { justIfNonempty } from "common/utils/data/Array/Nonempty"
import * as React from "react"
import Pill from "../../components/Pill"

interface ProductAreaPillProps {
  productArea: ProductArea
}

const ProductAreaPill: React.FunctionComponent<React.PropsWithChildren<ProductAreaPillProps>> = ({
  productArea,
}) => (
  <Pill
    content={productArea}
    bgClass={
      productArea === "data" ? "bg-warning-300 bg-opacity-80" : " bg-purple-300 bg-opacity-20"
    }
    textClass="text-neutral-800"
  />
)

interface ProductAreasPillsProps {
  productAreas: Account["productAreas"]
}

const ProductAreasPills: React.FunctionComponent<
  React.PropsWithChildren<ProductAreasPillsProps>
> = ({ productAreas }) => (
  <div className="flex space-x-2">
    {justIfNonempty(productAreas || []).matchStrict(
      (nonemptyProductAreas) =>
        nonemptyProductAreas.map((productArea) => (
          <ProductAreaPill key={productArea} productArea={productArea} />
        )),
      <Pill content="No product area" bgClass="bg-neutral-white" textClass="text-neutral-800" />
    )}
  </div>
)

export default ProductAreasPills
