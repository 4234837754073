import { Tooltip } from "@stories/components/Antd/Tooltip/Tooltip"
import { wrapIcon, IconWrapperProps } from "./IconWrapper"

export const MultiLayerStructureIcon = (props: IconWrapperProps & { tooltip?: string }) => (
  <Tooltip
    // we want to always show this icon with a tooltip
    title={
      props.tooltip ??
      "This offer is for a multi-layered SPV. The SPV does not own company shares directly, but instead owns shares in another SPV."
    }
    placement="top"
  >
    <div>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <RawMultiLayerStructureIcon {...props} />
    </div>
  </Tooltip>
)

const RawMultiLayerStructureIcon = wrapIcon(
  <svg viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.37736 1.92533C6.72669 1.38533 7.29336 1.16666 8.00003 1.16666C8.70669 1.16666 9.27336 1.38533 10.6227 1.92466L12.4954 2.67333C13.1687 2.94266 13.6354 3.13066 13.938 3.28933C13.9649 3.30333 13.9916 3.31778 14.018 3.33266L13.938 3.376C13.6354 3.53466 13.1687 3.72266 12.4954 3.992L10.6227 4.74066C10.2067 4.90714 9.8651 5.04308 9.57086 5.15148V6.21303C9.96499 6.0817 10.3976 5.9086 10.9174 5.70066L10.994 5.66933L12.8967 4.90933L12.9253 4.89787C13.5488 4.64782 14.054 4.44522 14.402 4.26266C14.582 4.16866 14.7554 4.06266 14.8887 3.934C15.0267 3.802 15.1667 3.602 15.1667 3.33333C15.1667 3.06466 15.026 2.86466 14.8887 2.73266C14.7547 2.604 14.5814 2.49733 14.4027 2.404C14.0549 2.22124 13.5513 2.01983 12.9299 1.77129L10.9155 0.965234C9.66527 0.465027 8.91956 0.166664 8.00003 0.166664C7.08069 0.166664 6.33403 0.465331 5.08269 0.965997L5.00603 0.997331L3.10336 1.75733L3.07478 1.76879C2.45126 2.01884 1.94606 2.22145 1.59803 2.404C1.41803 2.498 1.24469 2.604 1.11136 2.73266C0.97336 2.86466 0.83336 3.06466 0.83336 3.33333C0.83336 3.602 0.974027 3.802 1.11136 3.934C1.2575 4.06535 1.42104 4.17594 1.59736 4.26266C1.94518 4.44542 2.44876 4.64684 3.07014 4.89538L5.0846 5.70143C5.7219 5.95642 6.22811 6.15895 6.69308 6.29625V5.24541C6.34213 5.1253 5.92402 4.95933 5.37736 4.74066L3.50469 3.992C2.83136 3.72266 2.36469 3.53466 2.06203 3.376L1.98203 3.33266C2.00845 3.31778 2.03512 3.30333 2.06203 3.28933C2.36469 3.13066 2.83136 2.94266 3.50469 2.67333L5.37736 1.92533Z"
      fill="#FCB100"
    />
    <path
      d="M7.49308 5.45856V6.46872C7.65891 6.48936 7.82637 6.5 8.00003 6.5C8.26595 6.5 8.51743 6.47501 8.77086 6.42737V5.405C8.50211 5.4696 8.26059 5.49933 8.00003 5.49933C7.82714 5.49933 7.66267 5.48624 7.49308 5.45856Z"
      fill="#FCB100"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.81986 7.95484C6.44335 7.83253 5.99168 7.65346 5.37803 7.408L3.50536 6.65933C2.87669 6.40733 2.40936 6.14266 2.10269 5.94333C1.95856 5.85027 1.81927 5.74992 1.68536 5.64266L1.66936 5.62866L1.66669 5.62666C1.56777 5.53835 1.43714 5.49295 1.30474 5.50045C1.17234 5.50795 1.04834 5.56774 0.960027 5.66666C0.87171 5.76559 0.826308 5.89555 0.833809 6.02795C0.841311 6.16035 0.901101 6.28435 1.00003 6.37266L1.00062 6.372L1.00136 6.37333L1.00469 6.376L1.01403 6.384C1.02394 6.39276 1.03394 6.40143 1.04403 6.41C1.20874 6.54289 1.38031 6.66706 1.55803 6.782C2.05591 7.10049 2.58348 7.36997 3.13336 7.58666L5.0846 8.36743C5.65 8.59365 6.11222 8.77858 6.534 8.91331C6.53357 8.89825 6.53336 8.88316 6.53336 8.86804C6.53336 8.55187 6.62711 8.24279 6.80277 7.9799C6.80839 7.97149 6.81409 7.96314 6.81986 7.95484Z"
      fill="#FCB100"
    />
    <path
      d="M8.90566 9.06596C8.60598 9.13138 8.31301 9.166 8.00003 9.166C7.78455 9.166 7.57862 9.14962 7.37351 9.11809C7.34715 9.03814 7.33336 8.9538 7.33336 8.86804C7.33336 8.71009 7.38019 8.55569 7.46795 8.42436C7.54335 8.31151 7.64605 8.22006 7.76604 8.1582C7.84335 8.16389 7.92113 8.16666 8.00069 8.16666C8.15349 8.16666 8.29974 8.15644 8.44877 8.13496C8.54083 8.17474 8.62504 8.23172 8.69667 8.30334C8.80836 8.41503 8.88442 8.55733 8.91523 8.71224C8.93863 8.82985 8.93514 8.95072 8.90566 9.06596Z"
      fill="#FCB100"
    />
    <path
      d="M9.72998 8.82453C10.0814 8.70113 10.4666 8.547 10.9174 8.36666L12.8667 7.58666C13.4168 7.37022 13.9446 7.10096 14.4427 6.78266C14.6202 6.66769 14.7915 6.54352 14.956 6.41066C14.9585 6.40859 14.9609 6.40659 14.9632 6.40466C14.9727 6.3967 14.9807 6.39003 14.986 6.38466L14.9954 6.37666L14.9987 6.374L15 6.37333V6.372L14.6667 6L15.0014 6.372C15.0993 6.28336 15.1582 6.15955 15.1652 6.02763C15.1721 5.89571 15.1266 5.76639 15.0385 5.66793C14.9505 5.56947 14.827 5.50986 14.6951 5.50213C14.5632 5.49439 14.4337 5.53917 14.3347 5.62666L14.334 5.62733L14.332 5.62866C14.1933 5.74116 14.0486 5.8462 13.8987 5.94333C13.4553 6.2267 12.9856 6.46648 12.496 6.65933L10.6234 7.408C10.1197 7.60955 9.72512 7.76634 9.39158 7.8837C9.54495 8.07996 9.65092 8.31012 9.69986 8.55617C9.71753 8.64501 9.72754 8.73479 9.72998 8.82453Z"
      fill="#FCB100"
    />
    <path
      d="M14.3347 8.29333L14.3334 8.294L14.3314 8.29533C14.1926 8.40783 14.0479 8.51287 13.898 8.61C13.4547 8.89337 12.9849 9.13315 12.4954 9.326L10.6227 10.0747C9.27336 10.6147 8.70669 10.8333 8.00003 10.8333C7.29336 10.8333 6.72736 10.6147 5.37736 10.0747L3.50469 9.326C2.87603 9.074 2.40869 8.80933 2.10203 8.61C1.95789 8.51694 1.8186 8.41658 1.68469 8.30933L1.66869 8.29533L1.66669 8.294C1.56772 8.2065 1.43814 8.16173 1.30626 8.16946C1.17438 8.17719 1.05092 8.2368 0.962853 8.33526C0.874782 8.43373 0.829256 8.56304 0.836221 8.69496C0.843187 8.82689 0.902076 8.95002 1.00003 9.03866H1.00069L1.00136 9.04L1.00469 9.04266L1.01403 9.05066C1.02394 9.05943 1.03394 9.0681 1.04403 9.07666C1.20874 9.20956 1.38031 9.33373 1.55803 9.44866C2.05591 9.76715 2.58348 10.0366 3.13336 10.2533L5.08462 11.0341C6.33479 11.5343 7.0805 11.8327 8.00003 11.8327C8.91936 11.8327 9.66603 11.534 10.9174 11.0333L12.8667 10.2533C13.4165 10.0368 13.9441 9.76759 14.442 9.44933C14.62 9.334 14.7547 9.23533 14.848 9.16333C14.895 9.12715 14.941 9.08981 14.986 9.05133L14.9954 9.04333L14.9987 9.04066L15 9.04V9.03866L14.6667 8.66666L15.0014 9.03866C15.0993 8.95002 15.1582 8.82622 15.1652 8.6943C15.1721 8.56238 15.1266 8.43306 15.0385 8.3346C14.9505 8.23613 14.827 8.17652 14.6951 8.16879C14.5632 8.16106 14.4337 8.20583 14.3347 8.29333Z"
      fill="#FCB100"
    />
    <path
      d="M8.13197 7.11111C8.30141 7.11111 8.46392 7.0438 8.58373 6.92398C8.70355 6.80417 8.77086 6.64166 8.77086 6.47222V2.63889C8.77086 2.46944 8.70355 2.30694 8.58373 2.18712C8.46392 2.06731 8.30141 2 8.13197 2C7.96253 2 7.80002 2.06731 7.68021 2.18712C7.56039 2.30694 7.49308 2.46944 7.49308 2.63889V6.47222C7.49308 6.64166 7.56039 6.80417 7.68021 6.92398C7.80002 7.0438 7.96253 7.11111 8.13197 7.11111ZM8.13197 9.66666C8.28992 9.66666 8.44432 9.61983 8.57565 9.53207C8.70699 9.44432 8.80935 9.3196 8.86979 9.17367C8.93023 9.02774 8.94605 8.86716 8.91523 8.71224C8.88442 8.55733 8.80836 8.41503 8.69667 8.30334C8.58498 8.19165 8.44269 8.1156 8.28777 8.08479C8.13286 8.05397 7.97228 8.06979 7.82635 8.13023C7.68043 8.19068 7.5557 8.29303 7.46795 8.42436C7.38019 8.55569 7.33336 8.71009 7.33336 8.86804C7.33336 9.07985 7.4175 9.28299 7.56727 9.43276C7.71704 9.58253 7.92017 9.66666 8.13197 9.66666Z"
      fill="#FCB100"
    />
  </svg>
)
