import { Account } from "common/model/Account"

export interface StyleOverrides {
  darkBg?: boolean
  headingClassName?: string
  paragraphClassName?: string
  chartBackgroundColor?: string
  chartBackgroundClassName?: string
  chartLegendTextColor?: string
  largeLabeledValueTextClassName?: string
  marketPriceDescriptionLocation?: "above-chart" | "below-chart"
}

export const getStyleOverrides = (account: Account, darkBg?: boolean): StyleOverrides => {
  switch (account.name) {
    case "InvestX":
      return {
        headingClassName: "text-white",
        paragraphClassName: "text-neutral-300",
        chartBackgroundColor: "transparent",
        chartBackgroundClassName: "bg-transparent",
        chartLegendTextColor: "#E9ECF1",
        largeLabeledValueTextClassName: "text-neutral-100",
        marketPriceDescriptionLocation: "below-chart",
        darkBg,
      }
    default:
      return { darkBg }
  }
}
