import { Either, Left, Right } from "common/containers/Either"
import { AccountClientType } from "common/model/Account/AccountClientType"
import { Permissions, ProductArea } from "common/model/Auth/Permissions"
import { isLoaded, isLoading } from "common/utils/Loading"
import { AuthCondition, ElementWrapper } from "../../../Routes/auth/AuthCondition"
import { UserAuthContext, isAdminContext } from "../../../Routes/auth/UserAuthContext"
import { tosCheck } from "./TermsOfServiceRedirect"

const DefaultAuthFailurePage: ElementWrapper = () => <div>Insufficient Permissions</div>

const accountPermissions = (ctx: UserAuthContext): Permissions | undefined =>
  !isLoading(ctx.user) ? ctx?.user?.user?.account : undefined

export const hasAreaAccess = (ctx: UserAuthContext, area: ProductArea) =>
  (accountPermissions(ctx)?.productAreas || []).includes(area)

export const hasClientType = (ctx: UserAuthContext, clientType: AccountClientType) =>
  ctx.account && isLoaded(ctx.account) && ctx.account.clientType.includes(clientType)

// Useful for quick testing
// const alwaysFail = () => Left(DefaultAuthFailurePage)

const defaultAreaCheck =
  (area: ProductArea) =>
  (ctx: UserAuthContext): Either<ElementWrapper, UserAuthContext> => {
    if (isAdminContext(ctx) || hasAreaAccess(ctx, area)) {
      return Right(ctx)
    }
    return Left(DefaultAuthFailurePage)
  }

// To be appended to the condition list in an AuthBoundary
// Conditions are checked from left to right
export const areaAccessConditions = (
  area?: ProductArea
): AuthCondition<UserAuthContext, UserAuthContext>[] => {
  switch (area) {
    case "markets":
      return [defaultAreaCheck("markets"), tosCheck]
    case "data":
      return [defaultAreaCheck("data"), tosCheck]
    default:
      return []
  }
}
export const areaNavConditions = (
  area?: ProductArea
): AuthCondition<UserAuthContext, UserAuthContext>[] => {
  switch (area) {
    case "markets":
      return [defaultAreaCheck("markets")]
    case "data":
      return [defaultAreaCheck("data")]
    default:
      return []
  }
}
