/* eslint-disable react/no-unstable-nested-components */
import Divider from "@stories/components/Divider/Divider"
import { Input } from "@stories/components/Inputs/Input/Input"
import PhoneInput from "@stories/components/Inputs/PhoneInput/PhoneInput"
import { BrokerAccountTradingRegistration } from "common/model/Account/AccountTradingRegistration"
import { Field } from "react-final-form"

export const BrokerAccountTradingRegistrationForm = (
  <>
    <Divider label="Confirm Your Personal Information" shouldWrap={false} />
    <Field<BrokerAccountTradingRegistration["entityName"]>
      name="entityName"
      render={({ input: { value, onChange } }) => (
        <Input
          value={value}
          onChange={onChange}
          label="Full Name"
          placeholder="e.g. Jane Appleseed"
        />
      )}
    />
    <Field<BrokerAccountTradingRegistration["phoneNumber"]>
      name="phoneNumber"
      render={({ input: { value, onChange } }) => (
        <PhoneInput
          value={value}
          onChange={onChange}
          label="Contact Number"
          heapName="trading-registration-phone-input"
        />
      )}
    />
    <Divider label="Broker Dealer Information" shouldWrap={false} />
    <Field<BrokerAccountTradingRegistration["brokerDealerName"]>
      name="brokerDealerName"
      render={({ input: { value, onChange } }) => (
        <Input value={value} onChange={onChange} label="Broker Dealer Name" />
      )}
    />
    <Field<BrokerAccountTradingRegistration["brokerDealerContactName"]>
      name="brokerDealerContactName"
      render={({ input: { value, onChange } }) => (
        <Input
          value={value}
          onChange={onChange}
          label="Broker Dealer Contact Name"
          tooltipHintText="Indicate the representative at your broker dealer who is best suited to complete Caplight's trading registration"
        />
      )}
    />
    <Field<BrokerAccountTradingRegistration["brokerDealerContactEmail"]>
      name="brokerDealerContactEmail"
      render={({ input: { value, onChange } }) => (
        <Input value={value} onChange={onChange} label="Broker Dealer Contact Email" />
      )}
    />
  </>
)
