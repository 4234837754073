import { JSONContent } from "@tiptap/react"
import { SubmittedDocument } from "../files/DocumentSubmission"
import { DealDistribution } from "./DealDistribution"
import { americanDate } from "../../utils/dateUtils"
import { dealDistributionTypeLabels } from "./DealDistributionDetails"
import { formatValuation } from "../../utils/math/format"

const defaultInitialEmailBody = (dealDistribution: DealDistribution): JSONContent => ({
  type: "doc",
  content: [
    {
      type: "paragraph",
      attrs: {
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          text: `We have a co-invest fund with an allocation in ${dealDistribution.company.name} and I thought you might be interested in participating in the deal.`,
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          text: `The opportunity is through ${
            dealDistributionTypeLabels[dealDistribution.details.tag]
          }. ${
            dealDistribution.details.closeDate
              ? `Closing is expected to be by ${americanDate(dealDistribution.details.closeDate)}.`
              : ""
          }`,
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        textAlign: "left",
      },
    },
    {
      type: "paragraph",
      attrs: {
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          marks: [
            {
              type: "bold",
            },
          ],
          text: "Opportunity Details:",
        },
      ],
    },
    {
      type: "bulletList",
      content: [
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              attrs: {
                textAlign: "left",
              },
              content: [
                {
                  type: "text",
                  text: `Round Valuation: ${formatValuation(
                    dealDistribution.details.valuationUSD ?? 0
                  )}`,
                },
              ],
            },
          ],
        },
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              attrs: {
                textAlign: "left",
              },
              content: [
                {
                  type: "text",
                  text: `Amount Being Raised: ${formatValuation(
                    dealDistribution.details.amountBeingRaisedUSD ?? 0
                  )}`,
                },
              ],
            },
          ],
        },
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
              attrs: {
                textAlign: "left",
              },
              content: [
                {
                  type: "text",
                  text: `Minimum Investment: ${formatValuation(
                    dealDistribution.details.minimumInvestmentUSD ?? 0
                  )}`,
                },
              ],
            },
          ],
        },
        ...(dealDistribution.details.managementFee !== null &&
        dealDistribution.details.carry !== null
          ? [
              {
                type: "listItem",
                content: [
                  {
                    type: "paragraph",
                    attrs: {
                      textAlign: "left",
                    },
                    content: [
                      {
                        type: "text",
                        text: `SPV Fees: ${dealDistribution.details.managementFee} / ${
                          dealDistribution.details.carry
                        } ${
                          dealDistribution.details.feeNotes
                            ? ` - ${dealDistribution.details.feeNotes}`
                            : ""
                        } `,
                      },
                    ],
                  },
                ],
              },
            ]
          : []),
        ...(dealDistribution.details.capitalCallDate !== null
          ? [
              {
                type: "listItem",
                content: [
                  {
                    type: "paragraph",
                    attrs: {
                      textAlign: "left",
                    },
                    content: [
                      {
                        type: "text",
                        text: `Capital Call: ${americanDate(
                          dealDistribution.details.capitalCallDate
                        )}`,
                      },
                    ],
                  },
                ],
              },
            ]
          : []),
      ],
    },
  ],
})

export const defaultInitialEmail = (
  dealDistribution: DealDistribution | null
): DealDistributionEmail => ({
  tag: "initial_email",
  status: "requested",
  documents: [],
  emailBody: dealDistribution ? defaultInitialEmailBody(dealDistribution) : [],
})

export interface DealDistributionEmail {
  tag: "initial_email"
  status: "requested" | "approved" | "sent"
  documents?: SubmittedDocument[]
  emailBody: JSONContent
  emailSubject?: string
}
