import React from "react"

interface NewIconProps {}

const NewIcon: React.FC<React.PropsWithChildren<NewIconProps>> = () => (
  <div className="flex items-center justify-center px-1 text-xs text-white bg-accent-500 rounded-sm font-semibold max-w-fit">
    <p>NEW</p>
  </div>
)

export default NewIcon
