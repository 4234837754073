import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"

type SingleSelectFilterProps<T> = {
  name: string
  options: { name: T; toggle: () => void; active: boolean }[]
}
const SingleSelectFilter = <T extends string>({ name, options }: SingleSelectFilterProps<T>) => (
  <div className="flex space-x-2 items-center">
    <Typography text={name} color={Color.Subtitle} size={Size.XSmall} weight={Weight.Semibold} />
    <div className="flex space-x-2">
      {options.map(({ name: optionName, toggle, active }) => (
        <button
          key={`${name}:${optionName}`}
          onClick={toggle}
          className="capitalize flex space-x-1 items-center whitespace-nowrap"
          type="button"
        >
          <input
            type="radio"
            className="h-3 w-3 border-neutral-800 text-accent-500"
            checked={active}
            readOnly
          />
          <Typography text={optionName} size={Size.Small} />
        </button>
      ))}
    </div>
  </div>
)

export default SingleSelectFilter
