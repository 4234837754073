import * as L from "../Loading/Wrapped"
import { Just, Maybe as Maybe_, Nothing } from "../../containers/Maybe"

export namespace Promise_ {
  export namespace Maybe {
    /** `{effect}.{container}.sequence` lifts a `container` of `effect`ful values to a `container` of pure values wrapped in a single `effect` */
    export const sequence = <T>(x: Maybe_<Promise<T>>): Promise<Maybe_<T>> =>
      x.match(
        (a) => a.then((v) => Just(v)),
        () => Promise.resolve(Nothing)
      )
  }
  export namespace Array {
    export const sequence = <T>(ts: Promise<T>[]): Promise<T[]> =>
      ts.reduce((x, y) => x.then((xs) => y.then((z) => [...xs, z])), Promise.resolve<T[]>([]))
  }

  export namespace Loading {
    export const sequence = <T>(t: L.Loading<Promise<T>>): Promise<L.Loading<T>> =>
      t.match(
        (p) => p.then((v) => new L.Loading<T>(v)),
        Promise.resolve(new L.Loading<T>("loading")),
        Promise.resolve(new L.Loading<T>(null))
      )
  }
}
