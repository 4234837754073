import { ContactUpdatedEvent } from "common/model/AccountActivityLog/AccountActivityLog"
import { CRMContactName } from "src/pages/CRM/Contacts/ContactDetailsDrawer/CRMContactName"
import { UpdatedFieldDisplay } from "./UpdatedFieldDisplay"

export const CRMContactUpdatedEventCardDetails = ({ event }: { event: ContactUpdatedEvent }) => (
  <div className="flex flex-col gap-2">
    <CRMContactName contact={event.contact} clickable={false} />
    <div className="flex flex-col gap-2">
      {event.updates.map((update) => (
        <UpdatedFieldDisplay key={update.field} {...update} />
      ))}
    </div>
  </div>
)
