import { isUndefined } from "common/utils/json/validate"
import { defaultIfLoading } from "common/utils/Loading"
import { useAccountCompliance } from "src/providers/data/AccountComplianceProvider"
import { useOrderForm } from "../OrderFormContext"

export const useDarkpoolState = () => {
  const { formOrder, initialFormOrder, setFormOrder, initialOrderId, brokerEditingClientOrder } =
    useOrderForm()
  const isDarkpoolMode = !!formOrder.darkpool
  const canChangeDarkpoolMode = isUndefined(initialFormOrder.darkpool)
  const { canSubmitDarkpoolOrder, liveDarkpoolOrders } = useAccountCompliance()
  const isCurrentlyEditingLiveDarkpoolOrder = defaultIfLoading(liveDarkpoolOrders, []).find(
    (o) => o.id === initialOrderId
  )
  const isDarkpoolSubmissionBlocked =
    isDarkpoolMode &&
    !canSubmitDarkpoolOrder &&
    !isCurrentlyEditingLiveDarkpoolOrder &&
    !brokerEditingClientOrder
  const setIsDarkpoolMode = (value: boolean) => {
    if (canChangeDarkpoolMode) {
      setFormOrder({
        darkpool: value,
      })
    }
  }
  return { isDarkpoolMode, setIsDarkpoolMode, canChangeDarkpoolMode, isDarkpoolSubmissionBlocked }
}
