import Typography from "@stories/components/Typography/Typography"
import { DealCRMImportJob } from "common/model/DealCRM/DealCRMImportJob"
import { Loading, deprecatedIsLoaded, isLoading } from "common/utils/Loading"
import { Button } from "@stories/components/Button/Button"
import { Result } from "@stories/components/Antd/Result/Result"
import { assertUnreachable } from "common/utils/fp/Function"
import { Routes } from "src/Routes/Routes"
import { useNavigate } from "react-router-dom"
import Spinner from "@components/icons/Spinner"
import { dateTimeFormat } from "common/utils/dateUtils"
import Alert from "@stories/components/Alert/Alert"

export const ImportJobStatus = ({
  uploadJob, onResetUpload,
}: {
  uploadJob: Loading<DealCRMImportJob>
  onResetUpload?: () => void
}) => {
  const navigate = useNavigate()
  return (
    <>
      {deprecatedIsLoaded(uploadJob) ? (
        <div>
          {uploadJob.status === "completed" ? (
            <Alert
              variant="success"
              headline={`Previous upload was completed ${dateTimeFormat(uploadJob.createdAt)}`}
            />
          ) : uploadJob.status === "failed" ? (
            <Alert
              variant="danger"
              headline="Something went wrong."
              message={
                <>
                  <Typography text="If you continue to experience issues, please notify Caplight." />
                  {onResetUpload ? (
                    <Button variant="primary" label="Reset" onClick={onResetUpload} />
                  ) : null}
                </>
              }
            />
          ) : uploadJob.status === "draft" ? null : uploadJob.status === "processing" ? (
            <Result
              status="info"
              title="Upload Processing"
              subTitle={
                <div className="flex flex-col gap-4">
                  <Spinner size="lg" />
                  <Button
                    onClick={() => navigate(Routes.crm.allContacts)}
                    label="Return to all contacts - upload will continue in background"
                    variant="primary"
                  />
                </div>
              }
            />
          ) : (
            assertUnreachable(uploadJob.status)
          )}
        </div>
      ) : isLoading(uploadJob) ? (
        "Creating Import Job"
      ) : null}
    </>
  )
}
