import { OrderCard } from "@components/ItemCards/OrderCard"
import { DarkpoolOrderRenewedEvent } from "common/model/AccountActivityLog/AccountActivityLog"
import React from "react"

interface DarkpoolOrderRenewedDetailsProps {
  event: DarkpoolOrderRenewedEvent
}

export const DarkpoolOrderRenewedDetails: React.FC<DarkpoolOrderRenewedDetailsProps> = ({
  event,
}) => {
  const { order } = event

  return <OrderCard order={order} hideDate />
}
