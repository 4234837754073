/** Zod-compatible schema with support for fast-check Arbitrary<> generation */

import { ZodTypeDef } from "zod"
import * as z from "zod"
import * as fc from "fast-check"
import { Arbitrary } from "fast-check"
import { Schema as ISchema } from "../utils/zodUtils"
import { Rec } from "../utils/RecordUtils"

/** Not robust enough for general-purpose use: ts can and will make unsound variance assumptions. */
interface HKT {
  type: unknown
  params: unknown[]
}
type Apply<F extends HKT, T, O = T> = (F & { params: [T, O] })["type"]

interface SchemaHKT extends HKT {
  type: z.Schema<this["params"][1], ZodTypeDef, this["params"][0]>
}
interface ArbitraryHKT extends HKT {
  type: Arbitrary<this["params"][1]>
}

export class Schema<I, O = I> implements ISchema<I, ZodTypeDef, O> {
  private constructor(
    readonly fold: <F extends HKT>(cases: {
      float: Apply<F, number>
      null: Apply<F, null>
      date: Apply<F, Date>
      string: Apply<F, string>
      nat: Apply<F, number>
      int: Apply<F, number>
      boolean: Apply<F, boolean>
      array: <T>(t: Apply<F, T>) => Apply<F, T[]>
      nonempty: <T>(t: Apply<F, T>) => Apply<F, T[]>
      object: <R>(t: { [key in keyof R]: Apply<F, R[key]> }) => Apply<F, R>
      record: <K extends string, V>(key: Apply<F, K>, value: Apply<F, V>) => Apply<F, Record<K, V>>
      or: <S, T>(l: Apply<F, S>, r: Apply<F, T>) => Apply<F, S | T>
      intersect: <S extends object, T extends object>(
        l: Apply<F, S>,
        r: Apply<F, T>
      ) => Apply<F, S & T>
      optional: <T>(t: Apply<F, T>) => Apply<F, T | undefined>
      refine: <X, T, S extends T>(f: (t: T) => t is S, self: Apply<F, X, T>) => Apply<F, X, S>
      map: <X, T, S>(f: (t: T) => S, self: Apply<F, X, T>) => Apply<F, X, S>
      contramap: <X, T, S>(f: (t: T) => S, self: Apply<F, S, X>) => Apply<F, T, X>
      enum: <T extends string>(enums: readonly [T, ...T[]]) => Apply<F, T>
    }) => Apply<F, I, O>
  ) {}

  // constructors: these are completely determined by/completely determine `fold`

  static float(): Schema<number> {
    return new Schema(({ float }) => float)
  }

  static date(): Schema<Date> {
    return new Schema(({ date }) => date)
  }

  static enum<const T extends string>(enums: readonly [T, ...T[]]): Schema<T> {
    return new Schema((f) => f.enum(enums))
  }

  static null(): Schema<null> {
    return new Schema((f) => f.null)
  }

  static string(): Schema<string> {
    return new Schema(({ string }) => string)
  }

  static nat(): Schema<number> {
    return new Schema(({ nat }) => nat)
  }

  static int(): Schema<number> {
    return new Schema(({ int }) => int)
  }

  static boolean(): Schema<boolean> {
    return new Schema(({ boolean }) => boolean)
  }

  static object<const R extends {}>(obj: { [key in keyof R]: Schema<R[key]> }): Schema<R> {
    return new Schema((f) => f.object(Rec.map(obj, (x) => x.fold(f))))
  }

  static record<const K extends string, const V>(
    key: Schema<K>,
    value: Schema<V>
  ): Schema<Record<K, V>> {
    return new Schema((f) => f.record(key.fold(f), value.fold(f)))
  }

  static array<const X>(x: Schema<X>): Schema<X[]> {
    return new Schema((f) => f.array(x.fold(f)))
  }

  static nonempty<const X>(x: Schema<X>): Schema<X[]> {
    return new Schema((f) => f.nonempty(x.fold(f)))
  }

  array(this: Schema<I, I>) {
    return Schema.array(this)
  }

  nonempty() {
    return Schema.nonempty(this)
  }

  or<Y>(other: Schema<Y>): Schema<I | Y> {
    return new Schema((f) => f.or(this.fold(f), other.fold(f)))
  }

  optional(): Schema<I | undefined> {
    return new Schema((f) => f.optional(this.fold(f)))
  }

  refine<S extends I>(test: (x: I) => x is S): Schema<S>
  refine(test: (x: I) => boolean): Schema<I>
  refine<S extends I>(test: (x: I) => x is S): Schema<S> {
    return new Schema((f) => f.refine(test, this.fold(f)))
  }

  map<O2>(f: (x: O) => O2): Schema<I, O2> {
    return new Schema((s) => s.map(f, this.fold(s)))
  }

  contramap<I2>(f: (x: I2) => I): Schema<I2, O> {
    return new Schema((s) => s.contramap(f, this.fold(s)))
  }

  intersect<Y>(other: Schema<Y>): Schema<I & Y> {
    return new Schema((f) => f.intersect(this.fold(f), other.fold(f)))
  }

  /** interpretations */

  toZod() {
    return this.fold<SchemaHKT>({
      float: z.number(),
      null: z.null(),
      string: z.string(),
      nat: z.number().int().nonnegative(),
      int: z.number().int(),
      boolean: z.boolean(),
      date: z.date(),
      array: (t) => z.array(t),
      nonempty: (t) => z.array(t).refine((x) => x.length > 0, "Expected nonempty array"),
      object: <R>(t: { [key in keyof R]: z.Schema<R[key]> }) => {
        const res = z.object(t)
        return res as unknown as z.Schema<R> /** Todo figure this out */
      },
      record: <K extends string, V>(key: z.Schema<K>, value: z.Schema<V>) =>
        z.record(key, value) as unknown as z.Schema<Record<K, V>>,
      or: (l, r) => l.or(r),
      optional: (t) => t.optional(),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, rulesdir/no-assert, @typescript-eslint/no-explicit-any, rulesdir/no-any
      refine: (f, s) => s.refine(f) as unknown as any,
      enum: (enums) => z.enum(enums) as unknown as z.Schema<(typeof enums)[number]>,
      map: (f, x) =>
        x.transform((t) => f(t)) as unknown as z.Schema<
          ReturnType<typeof f>,
          ZodTypeDef,
          (typeof x)["_input"]
        >,
      contramap: (f, x) =>
        z.preprocess(f as (a: unknown) => unknown, x) as unknown as z.Schema<
          (typeof x)["_output"],
          ZodTypeDef,
          Parameters<typeof f>[0]
        >,
      intersect: (l, r) => l.and(r),
    })
  }

  toArbitrary() {
    return this.fold<ArbitraryHKT>({
      float: fc.float({ noNaN: true }),
      string: fc.string(),
      date: fc.date({ noInvalidDate: true, min: new Date(0), max: new Date(3000, 0) }),
      null: fc.constant(null),
      nat: fc.nat(),
      int: fc.integer(),
      boolean: fc.boolean(),
      array: (t) => fc.array(t),
      nonempty: (t) => fc.array(t, { minLength: 1 }),
      object: (t) => fc.record(t),
      record: (key, value) => fc.dictionary(key, value, { minKeys: 1 }),
      or: (l, r) => fc.oneof(l, r),
      optional: (t) => fc.oneof(t, fc.constant(undefined)), // todo also cover the real missing key case
      refine: (f, s) => s.filter(f),
      enum: (enums) => fc.constantFrom(...enums),
      map: (f, self) => self.map(f),
      contramap: (f, self) => self,
      intersect: (l, r) => l.chain((x) => r.map((y) => ({ ...x, ...y }))),
    })
  }

  sample(count: number) {
    return fc.sample(this.toArbitrary(), count)
  }

  /** helper methods that allow this to be used as a drop-in replacement for zod */

  parse(data: unknown, params?: Partial<z.ParseParams> | undefined) {
    return this.toZod().parse(data, params)
  }

  safeParse(data: unknown, params?: Partial<z.ParseParams> | undefined) {
    return this.toZod().safeParse(data, params)
  }
}

export namespace Schema {
  export type infer<T extends Schema<unknown>> = T extends Schema<infer X> ? X : unknown
}
