import { BrokerSection } from "@components/OrderFormComponents/BrokerSection"
import { OrderClientSection } from "@components/OrderFormComponents/ClientSection"
import { DocumentsSection } from "@components/OrderFormComponents/DocumentsSection"
import { NotesSection } from "@components/OrderFormComponents/NotesSection"
import { OrderPriceSection } from "@components/OrderFormComponents/PriceSection"
import { ShareClassSection } from "@components/OrderFormComponents/ShareClassSection"
import { StructureSection } from "@components/OrderFormComponents/StructureSection"
import { useFormOrderLabels } from "@components/OrderFormComponents/useFormOrderLabels"
import { defaultIfLoading } from "common/utils/Loading"
import { motion } from "framer-motion"
import React, { useMemo } from "react"
import { useAuthContext } from "src/Routes/auth/UserAuthContext"
import { useAccountBrokerLinking } from "src/utils/useAccountBrokerLinking"
import { useOrderForm } from "./OrderFormContext"
import { OrderFormPreview } from "./OrderFormPreview"
import { StepKey, StepOrderMap, useOrderFormStatus } from "./contextHooks/useFormStatus"

const SectionWrapper = ({
  children,
  stepKey,
  stepProgress,
  className = "",
  steps,
}: {
  children: React.ReactNode
  stepKey: StepKey
  stepProgress: number
  className?: string
  steps: Record<StepKey, number>
}) => (
  <>{steps[stepKey] === stepProgress ? <div className={`${className}`}>{children}</div> : null}</>
)

const OrderForm = () => {
  const {
    setFormOrder: handleChange,
    formOrder,
    formErrors,
    showUnpriced,
    company,
  } = useOrderForm()
  const { stepProgress } = useOrderFormStatus()
  const { isAccountBrokerLinkingEnabled } = useAccountBrokerLinking()
  const ctx = useAuthContext()
  const steps = useMemo(
    () => StepOrderMap(ctx, isAccountBrokerLinkingEnabled),
    [ctx, isAccountBrokerLinkingEnabled]
  )
  const {
    clientSectionLabels,
    structureSectionLabels,
    shareClassSectionLabels,
    termSectionLabels,
    documentSectionLabels,
    notesSectionLabels,
  } = useFormOrderLabels(formOrder)

  return (
    <div>
      {formOrder.direction && company ? (
        <>
          <motion.div
            key={stepProgress}
            initial={{ x: 10, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.25 }}
          >
            <SectionWrapper stepProgress={stepProgress} stepKey="brokerStatus" steps={steps}>
              <BrokerSection />
            </SectionWrapper>
            <SectionWrapper stepProgress={stepProgress} stepKey="clientStatus" steps={steps}>
              <OrderClientSection
                title={clientSectionLabels.title}
                titles={clientSectionLabels.subtitles}
                value={formOrder}
                onChange={handleChange}
                formErrors={formErrors}
              />
              <DocumentsSection
                title={documentSectionLabels.title}
                titles={documentSectionLabels.subtitles}
                value={formOrder}
                onChange={handleChange}
              />
              <div className="space-y-4">
                <NotesSection
                  title={notesSectionLabels.title}
                  titles={notesSectionLabels.subtitles}
                  value={formOrder}
                  onChange={handleChange}
                />
              </div>
            </SectionWrapper>
            <SectionWrapper stepProgress={stepProgress} stepKey="termStatus" steps={steps}>
              <OrderPriceSection
                title={termSectionLabels.title}
                titles={termSectionLabels.subtitles}
                value={formOrder}
                onChange={handleChange}
                company={defaultIfLoading(company, undefined)}
                showUnpriced={showUnpriced}
              />
              <StructureSection
                title={structureSectionLabels.title}
                titles={structureSectionLabels.subtitles}
                value={formOrder}
                onChange={handleChange}
              />
              <ShareClassSection
                title={shareClassSectionLabels.title}
                titles={shareClassSectionLabels.subtitles}
                value={formOrder}
                onChange={handleChange}
              />
            </SectionWrapper>
            <SectionWrapper
              stepProgress={stepProgress}
              stepKey="reviewStatus"
              className="flex flex-col gap-4"
              steps={steps}
            >
              <OrderFormPreview />
            </SectionWrapper>
          </motion.div>
        </>
      ) : (
        <div className="text-neutral-500 text-center">Select Company and Direction to continue</div>
      )}
    </div>
  )
}

export default OrderForm
