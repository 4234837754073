import * as Sentry from "@sentry/react"
import { config } from "src/config"
import { logErrorMessageToDatadogSessionRecording } from "./Datadog"

export const logMessage = (message: string) => {
  if (config.env === "dev" || config.env === "staging") {
    // eslint-disable-next-line no-console
    console.log(message)
  } else {
    logErrorMessageToDatadogSessionRecording(message)
  }
}

const isIndexError = (e: Error) => e.message.includes("requires an index")

const logIndexError = (e: Error) => {
  if (isIndexError(e)) {
    logMessage(e.message)
  }
}

export const captureException = (
  ...args: Parameters<typeof Sentry.captureException>
): ReturnType<typeof Sentry.captureException> => {
  if (args[0] instanceof Error) {
    logIndexError(args[0])
  }
  return Sentry.captureException(...args)
}
