import { LatestTermsOfServiceVersion } from "common/compliance/TermsOfServiceVersion"
import { User } from "common/model/User"
import { FC, useState } from "react"
import { FirebaseWriter } from "../../firebase/Firebase"
import { CurrentUser } from "../../model/CurrentUser"
import { useErrorHandler } from "../../providers/errorHandler"
import { classNames } from "../../utils/classNames"
import { NextButton } from "../buttons/NextButton"
import { CheckboxWithLabel } from "../inputs/checkbox/Checkbox"

interface AcceptTermsOfServiceProps {
  onAcceptanceSubmission: () => Promise<void>
  disabled: boolean
  className?: string
}

export const acceptTermsOfService = async (user: CurrentUser, firebase: FirebaseWriter) => {
  const updates: Pick<User, "termsOfServiceAcceptance"> = {
    termsOfServiceAcceptance: {
      latestTermsOfServiceAcceptedAt: new Date(),
      latestTermsOfServiceVersionAccepted: LatestTermsOfServiceVersion,
    },
  }
  await firebase.user(user.user.id).update(updates)
}

export const AcceptTermsOfService: FC<React.PropsWithChildren<AcceptTermsOfServiceProps>> = ({
  onAcceptanceSubmission,
  disabled,
  className,
}) => {
  const [checked, setChecked] = useState(false)
  const { handleError } = useErrorHandler()

  const handleSubmit = () => {
    if (onAcceptanceSubmission) onAcceptanceSubmission().catch(handleError)
  }

  return (
    <div className={classNames(className, "max-w-md")}>
      <div className="max-w-sm">
        <CheckboxWithLabel
          id="terms-of-service-checkbox"
          title="I have read and agree to the Terms of Service and Confidentiality Agreement"
          checked={checked}
          disabled={disabled}
          wrapText
          onChange={() => setChecked((prev) => !prev)}
        />
      </div>
      <NextButton
        text="Continue"
        testId="termsOfServiceContinue"
        disabled={disabled || !checked}
        noIcon
        color="primary"
        size="sm"
        onClick={handleSubmit}
      />
    </div>
  )
}
