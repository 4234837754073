import { PageTitle } from "@components/typography/PageTitle"
import { FormLabel } from "@stories/components/Inputs/FormLabel/FormLabel"
import { CrossIcon } from "@stories/icons/CrossIcon"
import { Link } from "react-router-dom"
import { Routes } from "src/Routes/Routes"
import { useClosedTradeForm } from "./ClosedTradeFormContext"

export const ClosedTradeFormHeader = () => {
  const { onClose, drawerTitle } = useClosedTradeForm()
  return (
    <div>
      <div className="flex items-center gap-2">
        <button className="text-neutral-600 hover:text-neutral-800" type="button" onClick={onClose}>
          <CrossIcon />
        </button>
        <PageTitle title={drawerTitle ?? "Submit A Closed Trade"} />
      </div>
      <div className="my-4">
        <FormLabel>
          Submit an individual closed trade to Caplight. If you are submitting more than one closed
          trade, please{" "}
          <Link to={Routes.data.upload.root}>click here to upload via our contribute page.</Link>
        </FormLabel>
      </div>
    </div>
  )
}
