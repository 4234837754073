import { classNames } from "src/utils/classNames"
import Typography, { Weight, Color, Size } from "../../Typography/Typography"

export const FormLabel = ({
  children,
  hasError = false,
  isOptional,
}: {
  children: React.ReactNode
  hasError?: boolean
  isOptional?: boolean
}): JSX.Element => (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label
    className={classNames(
      "w-full mb-2",
      hasError ? Color.Danger : Color.Black,
      Size.Medium,
      Weight.Semibold,
      isOptional && "flex flex-col gap-1"
    )}
  >
    {children}
    {isOptional ? <Typography text="Optional" size={Size.XSmall} color={Color.Subtitle} /> : null}
  </label>
)
