import { Left, Right } from "common/containers/Either"
import { deprecatedIsLoaded, isLoading } from "common/utils/Loading"
import { isAccountUserDataContributor } from "common/model/Account"
import { AuthCondition } from "../../../Routes/auth/AuthCondition"
import { UserAuthContext } from "../../../Routes/auth/UserAuthContext"
import { PageLayout } from "../../lib/PageLayout"
import Spinner from "../../icons/Spinner"

const AuthFailurePage = (ctx: UserAuthContext) => () =>
  (
    <PageLayout>
      {isLoading(ctx.account) || isLoading(ctx.user) ? (
        <div className="w-full h-full flex justify-center items-center p-8">
          <Spinner size="md" />
        </div>
      ) : (
        "Insufficient Permissions"
      )}
    </PageLayout>
  )

export const isDataContributorUser: AuthCondition<UserAuthContext, UserAuthContext> = (
  ctx: UserAuthContext
) => (isDataContributorUserContext(ctx) ? Right(ctx) : Left(AuthFailurePage(ctx)))

export const isDataContributorUserContext = (ctx: UserAuthContext): boolean =>
  deprecatedIsLoaded(ctx.account) &&
  deprecatedIsLoaded(ctx.user) &&
  !!ctx.user &&
  !!ctx.account &&
  (isAccountUserDataContributor(ctx.account, ctx.user.user) || ctx.user?.isAdmin)
