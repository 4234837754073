import { identity } from "lodash"
import { format } from "date-fns"
import moment, { Moment, MomentInput } from "moment-timezone"
import * as datefns from "date-fns"
import { justIfNonempty } from "./data/Array/Nonempty"
import { Interval } from "./data/Interval"
import { dateOrder } from "./fp/Ord"
import { DateISOString } from "../model/postgres/PostgresCommon"
import { assertUnreachable } from "./fp/Function"

export type Quarter = 1 | 2 | 3 | 4

export const calculateRemainingDays = (targetDate: Date) =>
  datefns.differenceInDays(targetDate, new Date())

const pacificTimeFormatter = new Intl.DateTimeFormat("en-US", {
  timeZone: "America/Los_Angeles",
})
export const convertToPacificTime = (date: Date) =>
  new Date(pacificTimeFormatter.format(typeof date === "string" ? new Date(date) : date))

export const secondsBetweenDates = (d1: Date, d2: Date) =>
  Math.abs(datefns.differenceInSeconds(d1, d2))

export const hoursBetweenDates = (d1: Date, d2: Date) => datefns.differenceInHours(d2, d1)

const absFloor = (x: number) => (x < 0 ? Math.ceil(x) || 0 : Math.floor(x)) // preserving moment behavior; I don't think this is actually a good idea
export const daysBetweenDates = (d1: Date, d2: Date, precise: boolean = true) =>
  precise ? datefns.differenceInDays(d1, d2) : absFloor(datefns.differenceInDays(d1, d2))

export const pstTimeString = (date: Date) => {
  const d = moment(date).tz("America/Los_Angeles")
  return d.format("hh:mmA")
}

export const nDaysFrom = (n: number, referenceDate: Date = new Date()): Date =>
  datefns.addDays(referenceDate, n)

export const nMinutesAgo = (minutes: number) => datefns.subMinutes(new Date(), minutes)

export const nHoursAgo = (hours: number) => datefns.subHours(new Date(), hours)

export const nDaysAgo = (days: number, referenceDate: Date = new Date()) =>
  datefns.subDays(referenceDate, days)

export const nWeeksAgo = (weeks: number) => datefns.subWeeks(new Date(), weeks)
export const nMonthsAgo = (months: number) => datefns.subMonths(new Date(), months)

export const dateToSecondsTimestamp = (d: Date) => d.getTime() / 1000
export const secondsTimestampToDate = (t: number) => new Date(t * 1000)

export const weeksBetweenDates = (before: Date, after: Date) =>
  datefns.differenceInWeeks(after, before)
export const monthsBetweenDates = (before: Date, after: Date) =>
  datefns.differenceInMonths(after, before)

export const timeFormat = (date: Date | Moment) =>
  moment(date).minutes() !== 0 ? moment(date).format("h:mma") : moment(date).format("ha")

export const dateTimeFormat = (date: moment.MomentInput) => moment(date).format("M/DD/YY hh:mmA")

/**
 * Used in articulatedDateTimeFormat and articulatedRelativeDateAndTimeFormat
 * Dates older than this number of days ago (relative to today) will not:
 *  - show the time in articulatedRelativeDateAndTimeFormat
 *  - show the day of month instead of day of week in articulatedDateTimeFormat
 */
const DAYS_AGO_TO_HIDE_TIME = 2

/**
 * Formats a given date and time into a date dependent/articulated human-readable format.
 *
 * This function takes a date input and returns an object containing the formatted date and time
 * formatted in relation to today's date such as "Today," "Yesterday," the day of the week (within the last 7 days),
 * or a default date format.
 *
 * @param {moment.MomentInput} date - The date and time to be formatted, can be a Moment object,
 *                                    Date object, string, or number.
 *
 * @returns {{
 *   date: string, // The formatted date with articulation
 *   time: string  // The formatted time
 * }} - An object containing the formatted date and time.
 */
export const articulatedDateTimeFormat = (
  date: moment.MomentInput
): {
  date: string
  time: string
} => {
  if (moment(date).isSame(moment(), "day")) {
    return {
      date: "Today",
      time: moment(date).format("hh:mmA"),
    }
  }

  if (moment(date).isSame(moment().subtract(1, "day"), "day")) {
    return {
      date: "Yesterday",
      time: moment(date).format("hh:mmA"),
    }
  }

  if (moment(new Date()).diff(date, "days") <= DAYS_AGO_TO_HIDE_TIME) {
    return {
      date: moment(date).format("dddd"),
      time: moment(date).format("hh:mmA"),
    }
  }

  return {
    date: moment(date).format("MMM D, YYYY"),
    time: moment(date).format("hh:mmA"),
  }
}

export const articulatedRelativeDateAndTimeFormat = (date: moment.MomentInput): string => {
  const now = moment()

  if (moment(date).isSame(now, "minute")) {
    return "Just now"
  }

  if (moment(date).isSame(now, "hour")) {
    return moment(date).fromNow()
  }

  if (moment(date).isSame(now, "day")) {
    return moment(date).fromNow()
  }

  if (moment(date).isSame(now.subtract(1, "day"), "day")) {
    return "Yesterday"
  }

  return `${moment(date).format("MMM D, YYYY")}, ${moment(date).format("hh:mmA")}`
}

export const formatHumanReadableDateTime = (date: moment.MomentInput) => ({
  date: moment(date).format("MMM D, YYYY"),
  time: moment(date).format("hh:mmA"),
})

export const localDateTimeFormat = (date: moment.MomentInput) =>
  moment(date).local().format("M/DD/YY hh:mm A")

export const ISODateFormat = (date: moment.MomentInput) => moment(date).format("YYYY-MM-DD")
export const ISODateFormatForUTC = (date: Date) => moment(date).utc().format("YYYY-MM-DD")
export const ISODateTimeFormatSpec = "YYYY-MM-DDTHH:mm:ss Z"
export const ISODateTimeFormat = (date: moment.MomentInput) =>
  moment(date).format(ISODateTimeFormatSpec)
export const momentFromISODateTimeFormat = (dateString: string) =>
  moment(dateString, ISODateTimeFormatSpec)

/** @deprecated */ // moment is too slow to use in chart rendering
export const abbreviatedMonthAndYearFormat = (date: moment.MomentInput) =>
  moment(date).format("MMM, YYYY")

const abbreviatedMonthAndYearDateFormatter = new Intl.DateTimeFormat(undefined, {
  month: "short",
  year: "numeric",
})
export const abbreviatedMonthAndYearDateFormat = (date: Date) =>
  abbreviatedMonthAndYearDateFormatter.format(date)

export const fullMonthAndYearFormat = (date: moment.MomentInput) => moment(date).format("MMMM YYYY")

export const longEasternDateTimeFormat = (
  date: Moment | Date,
  options?: { includeYear?: boolean; includeDay?: boolean }
) => `${longDateFormat(moment(date), options)} at ${easternTimeString(moment(date))}`

export const longDateFormat = (
  date: Moment | Date,
  options?: { includeYear?: boolean; includeDay?: boolean }
) => {
  if (!options || (!options.includeDay && !options.includeYear))
    return moment(date).format("MMMM D")
  if (options.includeDay && options.includeYear) return moment(date).format("dddd, MMMM D, YYYY")
  if (options.includeDay) return moment(date).format("dddd, MMMM D")
  return moment(date).format("MMMM D, YYYY")
}

export const emailDateFormat = (date: Moment) =>
  date.tz("America/New_York").format("dddd, MMMM Do [at] hA [(]zz[)]")

export const sortByDate =
  <K extends string>(dateField: K, direction: "asc" | "desc" = "asc") =>
  <T extends { [key in K]: moment.MomentInput }>(a: T, b: T) => {
    const lDate = moment(a[dateField])
    const rDate = moment(b[dateField])
    if (lDate.isBefore(rDate)) return direction === "asc" ? -1 : 1
    return direction === "asc" ? 1 : -1
  }

export const isBeforeNow = (date: Moment) => date.isBefore(new Date())

export const isWithinPastYear = (date: Moment) => moment().subtract(1, "year").isAfter(date)

const timeZonedRangeString = (date1: Moment, date2: Moment, tz: string) =>
  `${date1.tz(tz).format("h:mm a")} - ${date2.tz(tz).format("h:mm a zz")}`

const zonedTimeString = (date: Moment, tz: string) => date.tz(tz).format("h:mm A zz")
const zonedDateString = (date: Moment, tz: string) => date.tz(tz).format("YYYY-MM-DD")
const zonedDateTimeString = (date: Moment, tz: string) => date.tz(tz).format("YYYY-MM-DD h:mm a zz")
export const localTimeString = (date: Moment) => zonedTimeString(date, moment.tz.guess())
export const easternTimeString = (date: Moment) => zonedTimeString(date, "America/New_York")
export const easternDateTimeString = (date: Moment | Date) =>
  zonedDateTimeString(moment(date), "America/New_York")
export const pacificTimeString = (date: Moment) => zonedTimeString(date, "America/Los_Angeles")
export const pacificDateTimeString = (date: Moment) =>
  zonedDateTimeString(date, "America/Los_Angeles")
export const pacificDateString = (date: Moment) => zonedDateString(date, "America/Los_Angeles")

export const localTimeRangeString = (date1: Moment, date2: Moment) =>
  timeZonedRangeString(date1, date2, moment.tz.guess())
export const easternTimeRangeString = (date1: Moment, date2: Moment) =>
  timeZonedRangeString(date1, date2, "America/New_York")

export const easternAndPacificTimeString = (date: Moment) =>
  `${easternTimeString(date)} (${pacificTimeString(date)})`

export const easternAndPacificTimeRangeString = (date1: Moment, date2: Moment) => {
  const easternPart = timeZonedRangeString(date1, date2, "America/New_York")
  const pacificPart = timeZonedRangeString(date1, date2, "America/Los_Angeles")
  return `${easternPart} (${pacificPart})`
}
/*
  Functions used in state/actions/postgresData
*/
export const formatDateFromTS = (timestamp: number) => {
  const dt = new Date(timestamp * 1000)
  return `${dt.getFullYear()}-${dt.getMonth()}-${dt.getDay()}`
}

export type PostgresDateData = {
  indicator_as_of_quarter?: number | "None"
  indicator_as_of_month?: string
  indicator_as_of_day?: string
  indicator_as_of_year?: string
}
/*
  Functions to parse Postgres data dates
*/
export const getDateFromPostgresCompanyInfo = (
  el: PostgresDateData,
  asDateWithDay: boolean = false
) => {
  const qDate = ["03-31", "06-30", "09-30", "12-31"]
  const q = el?.indicator_as_of_quarter
  const m = el?.indicator_as_of_month
  const d = el?.indicator_as_of_day
  const dt = el?.indicator_as_of_year
  if (!dt || dt === "None") return ""
  if (q && q !== "None") return `${dt}-${qDate[q - 1]}`
  if (m && d && m !== "None" && d !== "None") return `${dt}-${m}-${d}`
  if (m && m !== "None" && d === "None") return `${dt}-${m}`
  if (asDateWithDay) {
    return `${dt}-12-31`
  }
  return dt
}

/*
  other
*/
export const dateDiff = (a: number, b: number, diffUnit: "days" | "months" = "days") => {
  const a1 = moment(a)
  const b1 = moment(b)
  return a1.diff(b1, diffUnit)
}
export const numericDaysDiff = (tsStart: number, tsEnd: number): number =>
  (tsEnd - tsStart) / (1000 * 60 * 60 * 24)

export const dateDiffToNow = (date: Date, diffUnit: "days" | "months" = "days") =>
  dateDiff(moment().valueOf(), moment(date).valueOf(), diffUnit)
export const addTimeDelta = (
  date: Date,
  diffAmount: number,
  diffUnit: "minutes" | "days" | "months" = "days"
): Date => {
  switch (diffUnit) {
    case "minutes":
      return datefns.addMinutes(date, diffAmount)
    case "days":
      return datefns.addDays(date, diffAmount)
    case "months":
      return datefns.addMonths(date, diffAmount)
    default:
      return assertUnreachable(diffUnit)
  }
}

export const addMonthsApprox = (timestamp: number, months: number) =>
  timestamp + months * 30 * 24 * 60 * 60 * 1000

export const randomTimeBetweenTwoDates = (date1: Date, date2: Date) => {
  const date1Time = date1.getTime()
  const date2Time = date2.getTime()
  return new Date(date1Time + Math.random() * (date2Time - date1Time))
}

export const startOfWeek = (d: Date) => moment(d).startOf("week").toDate()
export const endOfWeek = (d: Date) => moment(d).endOf("week").toDate()
export const weekInterval = (d: Date): Interval<Date> => ({
  lowerBound: startOfWeek(d),
  upperBound: endOfWeek(d),
})

export const isDateInInterval = (date: Date, interval: Interval<Date>) =>
  Interval.overlap(dateOrder)(interval, Interval.pure(date))

export const isFirstWeekOfMonth = (d: Date) =>
  moment(d).subtract(1, "week").month() !== moment(d).month()

export const americanDate = (d: moment.MomentInput) => moment(d).format("MMMM Do, YYYY")

export const monthAndYearDate = (d: moment.MomentInput) => moment(d).format("MMMM, YYYY")

export const dateRangeString = (interval: Interval<Date>): string =>
  `${americanDate(interval.lowerBound)} - ${americanDate(interval.upperBound)}`

export const localeDateFormat = (d: Date) => moment(d).format("l")
export const relativeDateFormat = (d: Date) => moment(d).fromNow()

const shortDateNoYearFormatter = new Intl.DateTimeFormat("en-US", {
  month: "short",
  day: "numeric",
})
export const shortDateNoYear = (d: Date) => shortDateNoYearFormatter.format(d)
const shortDateFormater = new Intl.DateTimeFormat("en-US", {
  month: "short",
  day: "numeric",
  year: "numeric",
})
export const shortDateFormat = (d: Date) => shortDateFormater.format(d)

export const findClosestDate = (targetDate: Date, dates: Date[]) =>
  dates
    .map((date) => ({ date, delta: Math.abs(moment(date).diff(targetDate)) }))
    .sort((a, b) => a.delta - b.delta)
    .find(identity)?.date

export const monthsToDays = (months: number) => Math.round(months * (365 / 12))

export const maxDate = (dates: Date[]) =>
  justIfNonempty(dates).map(
    // eslint-disable-next-line rulesdir/no-max
    (nonemptyArr) => new Date(Math.max(...nonemptyArr.map((date) => date.valueOf())))
  )

export namespace Date_ {
  /** The earliest valid Date, as of ECMA 5, is April 19th 271,821 BCE */
  export const minValue: Date = new Date(-8640000000000000)
  /** The latest valid Date, September 12th 275,760 */
  export const maxValue: Date = new Date(8640000000000000)
}

export const dateToMoment = (x: Date) => moment(x.valueOf())
export const momentToDate = (x: Moment) => x.toDate()
export const now = () => new Date()

const isValidQuarter = (quarter: number): quarter is Quarter => quarter >= 1 && quarter <= 4

type DateMonth = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11
const isDateMonth = (x: number): x is DateMonth => x >= 0 && x <= 11
const quarterFromMonth = (month: DateMonth): Quarter => {
  const quarter = Math.floor(month / 3) + 1
  if (!isValidQuarter(quarter)) {
    throw new Error(`Invalid quarter: (${quarter})`)
  }
  return quarter
}
export const getQuarterFromDate = (date: Date): Quarter => {
  // NOTE: date.getMonth() starts from 0
  const month = date.getMonth()
  if (!isDateMonth(month)) throw new Error(`Date library returned an unexpected month: (${month})`)
  const quarter = quarterFromMonth(month)
  return quarter
}

export type YearAndQuarter = `${number} Q${Quarter}`
export type QuarterAndYear = `${Quarter}Q${number}`
export const isQuarterAndYear = (x: string): x is QuarterAndYear =>
  (x.match(/[1234]Q\d{4}/) || []).length > 0
// to beginning of period (BoP) or to end of period (EoP)
export const quarterAndYearToBoPDateISOString = (x: QuarterAndYear): DateISOString =>
  x.substring(2, 6) +
  ({ "1Q": "-01-01", "2Q": "-04-01", "3Q": "-07-01", "4Q": "-10-01" }[x.substring(0, 2)] || "")
export const quarterAndYearToEoPDateISOString = (x: QuarterAndYear): DateISOString =>
  x.substring(2, 6) +
  ({ "1Q": "-03-31", "2Q": "-06-30", "3Q": "-09-30", "4Q": "-12-31" }[x.substring(0, 2)] || "")

// TODO: fundmarks should be UTC
export const displayYearAndQuarter = (date: Date): YearAndQuarter =>
  `${date.getFullYear()} Q${getQuarterFromDate(date)}`

export const quarterAndYearFromUTCString = (dateString: DateISOString): QuarterAndYear =>
  quarterAndYearFromDate(new Date(dateString))

export const quarterAndYearFromDate = (date: Date): QuarterAndYear => {
  const utcYear = date.getUTCFullYear()
  const month = date.getUTCMonth()
  if (!isDateMonth(month)) throw new Error(`Date library returned an unexpected month: (${month})`)
  const quarter = quarterFromMonth(month)
  return `${quarter}Q${utcYear}`
}

export const buildQuarterStringFromDates = (dates: Date[]): YearAndQuarter[] => [
  ...new Set(dates.map((date) => displayYearAndQuarter(date))),
]

export const isDateWithinQuarter = (date: Date, year: number, quarter: number): boolean =>
  date.getFullYear() === year && getQuarterFromDate(date) === quarter

const yearAndQuarterToQuarters = (yearAndQuarter: YearAndQuarter): number => {
  const [year, quarter] = yearAndQuarter.split(" Q")
  // NOTE: quarter starts from 1, actual calculation starts from 0
  return parseInt(year, 10) * 4 + parseInt(quarter, 10) - 1
}

const quartersToYearAndQuarter = (quarters: number): YearAndQuarter => {
  const year = Math.floor(quarters / 4)
  const quarter = (quarters % 4) + 1

  if (!isValidQuarter(quarter)) {
    throw new Error(`Invalid quarter: (${quarter})`)
  }
  return `${year} Q${quarter}`
}

export const shiftYearAndQuarter = (
  initialYearAndQuarter: YearAndQuarter,
  quarterDelta: number
): YearAndQuarter =>
  quartersToYearAndQuarter(yearAndQuarterToQuarters(initialYearAndQuarter) + quarterDelta)

export const emailOutboxFormat = (date: Date): string => {
  const newMoment = moment(date)
  if (newMoment.isSame(new Date(), "day")) return newMoment.format("h:mm a")
  if (newMoment.isSame(new Date(), "year")) return newMoment.format("MMM D")
  return newMoment.format("MM/DD/YYYY")
}

const dateFormatter = new Intl.DateTimeFormat()
export const formatDate = (x: Date) => dateFormatter.format(x)

export const dayOfWeek = (x?: MomentInput) => moment(x).format("dddd")
export const hourOfDay = (x?: MomentInput): number => moment(x).hour()

export const reformatDateString = (x: string, formatIn: string, formatOut: string) =>
  format(moment(x, formatIn).toDate(), formatOut)

export const formatDateToYYYYMMDD = (inputDate: Date): string =>
  datefns.format(inputDate, "yyyy-MM-dd")

export const relativeToTodayDateTimeText = (
  d: Date,
  options?: {
    hideTimeOnOldDates?: boolean
  }
): string => {
  const { date, time } = articulatedDateTimeFormat(d)

  if (!options?.hideTimeOnOldDates) {
    return `${date} ${time}`
  }

  const daysAgo = daysBetweenDates(new Date(), d)

  if (daysAgo >= DAYS_AGO_TO_HIDE_TIME) {
    return `${date}`
  }

  return `${date} ${time}`
}

export const parseDateStringOrFail = (dateString: string): Date => {
  const timestamp = Date.parse(dateString)

  if (Number.isNaN(timestamp)) {
    throw new Error(`Invalid date: ${dateString}`)
  }

  return new Date(timestamp)
}
