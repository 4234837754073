export const annotate = <S, T extends S = S>(t: T): S => t
// eslint-disable-next-line prettier/prettier
export const annotateEveryField =
  <T>() =>
  <R extends Record<keyof R, T>>(r: R): Record<keyof R, T> =>
    r
export const annotateHasKeys =
  <K extends string | number | symbol>() =>
  <R extends Record<K, unknown>>(r: R): R =>
    r
