import * as Wrapped from "common/model/Order/Models/Wrapped"
import { Order } from "common/model/Order/Order"
import { Loading, defaultIfLoading, isLoaded, lift2Loading, mapLoading } from "common/utils/Loading"
import { useMemo } from "react"

import { Just, nullableToMaybe } from "common/containers/Maybe"
import { Company } from "common/model/Company"
import { head } from "common/utils/data/Array/ArrayUtils"
import { useCompanies } from "src/providers/data/CompanyProvider"

export const useWrapOrder = (order: Loading<Order>): Loading<Wrapped.PlatformOrder> => {
  const companyIdToQuery = useMemo(() => (isLoaded(order) ? [order.company.id] : []), [order])
  const companies = useCompanies(companyIdToQuery)
  return useMemo(
    () =>
      lift2Loading(
        (o, c) =>
          Wrapped.Order.wrapRaw({
            order: o,
            company: head(c),
          }).withUnconstrainedDefault(null),
        order,
        companies
      ),
    [companies, order]
  )
}

export const useWrapOrders = (orders: Loading<Order[]>) => {
  const companyIdsToQuery = useMemo(
    () => (isLoaded(orders) ? orders.map((o) => o.company.id) : []),
    [orders]
  )
  const companies = useCompanies(companyIdsToQuery)
  const loadingCompanyMap = useMemo(
    () =>
      mapLoading((c: Company[]) => new Map(c.map((company) => [company.id, company])))(companies),
    [companies]
  )
  const companyMap = useMemo(
    () => defaultIfLoading(loadingCompanyMap, new Map<string, Company>()),
    [loadingCompanyMap]
  )
  return useMemo(
    () =>
      isLoaded(orders)
        ? orders.flatMap((o) =>
            Wrapped.Order.wrapRaw({
              order: o,
              company: Just(companyMap.get(o.company.id)).bind(nullableToMaybe),
            }).toArray()
          )
        : orders,
    [companyMap, orders]
  )
}
