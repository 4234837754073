import { wrapIcon } from "./IconWrapper"

const UpChevronIcon = wrapIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.29289 4.62623C7.68342 4.2357 8.31658 4.2357 8.70711 4.62623L13.3738 9.2929C13.7643 9.68342 13.7643 10.3166 13.3738 10.7071C12.9832 11.0976 12.3501 11.0976 11.9596 10.7071L8 6.74755L4.04044 10.7071C3.64991 11.0976 3.01675 11.0976 2.62623 10.7071C2.2357 10.3166 2.2357 9.68342 2.62623 9.2929L7.29289 4.62623Z"
      fill="currentColor"
    />
  </svg>
)

export default UpChevronIcon
