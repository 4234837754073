import { Excluding } from "../../utils/TypeUtils"
import { Account, AccountIdFields, accountIdFieldsSchema, viewAccountIdFields } from "../Account"
import { assertUnreachable } from "../../utils/fp/Function"
import { dataSource, DataSource } from "../data-product/DataSource"
import { Schema } from "../../schema/Schema"
import { accountClientTypes } from "../Account/AccountClientType"

type OrderSourceAccountAdditionalFields = Pick<Account, "clientType">
export type OrderSourceAccountFields = AccountIdFields & OrderSourceAccountAdditionalFields
export type BaseOrderSource = {
  account: Excluding<OrderSourceAccountFields, Account>
}

export type OrderSource = DataSource & BaseOrderSource & { dataSourceHistory?: DataSource[] }
export const orderSourceSchema: Schema<OrderSource> = dataSource
  .intersect(
    Schema.object({
      account: accountIdFieldsSchema.intersect(
        Schema.object({ clientType: Schema.enum(accountClientTypes).array() })
      ),
    })
  )
  .intersect(Schema.object({ dataSourceHistory: dataSource.array().optional() }))

export const viewOrderSourceAccountFields = (
  a: OrderSourceAccountFields
): BaseOrderSource["account"] => ({
  ...viewAccountIdFields(a),
  clientType: a.clientType,
})
export const viewOrderSource = <T extends DataSource>(x: T): DataSource => {
  switch (x.sourceType) {
    case "airtable-file":
      return { sourceType: "airtable-file", sourceId: x.sourceId, documentUpload: x.documentUpload }
    case "api":
      return {
        sourceType: "api",
        documentUpload: null,
        sourceId: x.sourceId,
        unparsedDocumentId: x.unparsedDocumentId,
      }
    case "document-upload":
      return {
        sourceType: "document-upload",
        documentUpload: x.documentUpload,
        sourceId: x.sourceId,
      }
    case "user-form":
      return {
        sourceType: "user-form",
        documentUpload: x.documentUpload,
        sourceId: x.sourceId,
        submittingUser: x.submittingUser,
      }
    case "crm-holding":
      return {
        sourceType: "crm-holding",
        documentUpload: null,
        sourceId: null,
        submittingUser: null,
      }
    default:
      return assertUnreachable(x)
  }
}

export type OrderSourceType = OrderSource["sourceType"]
