import CompanyLogoAndName from "@components/CompanyLogoAndName"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import {
  ContactBuySellInterestAddedEvent,
  ContactBuySellInterestUpdatedEvent,
} from "common/model/AccountActivityLog/AccountActivityLog"
import { CRMContactName } from "src/pages/CRM/Contacts/ContactDetailsDrawer/CRMContactName"
import { UpdatedFieldDisplay } from "./UpdatedFieldDisplay"

export const ContactBuySellInterestAddedOrUpdatedEventCardDetails = ({
  event,
}: {
  event: ContactBuySellInterestAddedEvent | ContactBuySellInterestUpdatedEvent
}) => {
  const { interest, contact } = event
  const { company } = interest

  const updates: ContactBuySellInterestUpdatedEvent["updates"] =
    event.eventType === "contact_buy_sell_interest_updated" ? event.updates : []

  return (
    <div>
      <div className="flex gap-x-2 items-center">
        <div className="flex flex-col">
          <Typography
            text="Contact"
            size={Size.XSmall}
            color={Color.Subtitle}
            weight={Weight.Semibold}
          />
          <CRMContactName contact={contact} clickable={false} />
        </div>
        <div className="flex flex-col">
          <Typography
            text="Company"
            size={Size.XSmall}
            color={Color.Subtitle}
            weight={Weight.Semibold}
          />
          <CompanyLogoAndName company={company} size="xs" />
        </div>
      </div>

      <div>
        {updates.map((update) => (
          <UpdatedFieldDisplay key={update.field} {...update} />
        ))}
      </div>
    </div>
  )
}
