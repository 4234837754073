import { useCompanyLiveOrders } from "../../../../providers/data/CompanyLiveOrdersProvider"
import { OrderPageOrder } from "../types"
import LoadingOrderLiveMarket from "./LoadingOrderLiveMarket"
import OrderLiveMarketTable from "./OrderLiveMarketTable"

const LoadedOrderLiveMarket = ({ order }: { order: OrderPageOrder }): JSX.Element | null =>
  useCompanyLiveOrders().liveAndStaleOrders.match(
    (liveOrders) => <OrderLiveMarketTable liveOrders={liveOrders} order={order} />,
    <LoadingOrderLiveMarket />,
    null
  )

export default LoadedOrderLiveMarket
