import Typography, { Size } from "@stories/components/Typography/Typography"
import FilterBarDropdownButton from "../FilterBarDropdownButton"

type DropdownRadioFilterProps<T> = {
  label: string
  options: { name: T; toggle: () => void; active: boolean }[]
}
const DropdownRadioFilter = <T extends string>({ label, options }: DropdownRadioFilterProps<T>) => (
  <FilterBarDropdownButton label={label} variant="filter">
    <div className="flex flex-col space-y-2 p-2">
      {options.map(({ name: optionName, toggle, active }) => (
        <button
          key={`${label}:${optionName}`}
          onClick={toggle}
          className="capitalize flex space-x-1 items-center whitespace-nowrap"
          type="button"
        >
          <input
            type="radio"
            className="h-3 w-3 border-neutral-800 text-accent-500"
            checked={active}
            readOnly
          />
          <Typography text={optionName} size={Size.Small} shouldWrap={false} />
        </button>
      ))}
    </div>
  </FilterBarDropdownButton>
)

export default DropdownRadioFilter
