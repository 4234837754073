import { createContext, FC, useContext, useMemo } from "react"
import { Loading as RawLoading } from "common/utils/Loading"
import { Company, companyInPostgres, CompanyWithPostgres } from "common/model/Company"
import { Enriched } from "common/queries/pricing/PricingEnrichment"
import { useCompanyTrades } from "src/queries/Trade/useCompanyTrades"
import { Order } from "common/model/Order/Models/Wrapped"
import { Loading } from "common/utils/Loading/Wrapped"
import { useCompanyOrders } from "../../../../queries/Order/useCompanyOrders"

interface TradeAndOrderProviderData {
  fullCompany: RawLoading<CompanyWithPostgres>
  trades: RawLoading<Enriched<"trade">[]>
  allOrders: RawLoading<Order[]>
  privateOrders: RawLoading<Order[]>
  platformOrders: RawLoading<Order[]>
}

const TradeAndOrderContext = createContext<TradeAndOrderProviderData>({
  fullCompany: null,
  trades: null,
  allOrders: null,
  privateOrders: null,
  platformOrders: null,
})

const LoadedTradeAndOrderProvider: FC<
  React.PropsWithChildren<{ company: CompanyWithPostgres }>
> = ({ children, company }) => {
  const { allOrders, privateOrders, platformOrders } = useCompanyOrders({ company })
  const trades = useCompanyTrades(company)

  const tradeAndOrderValue: TradeAndOrderProviderData = useMemo(
    () => ({
      fullCompany: company,
      allOrders,
      privateOrders,
      platformOrders,
      trades,
    }),
    [company, allOrders, privateOrders, platformOrders, trades]
  )

  return (
    <TradeAndOrderContext.Provider value={tradeAndOrderValue}>
      {children}
    </TradeAndOrderContext.Provider>
  )
}

const TradeAndOrderProvider: FC<React.PropsWithChildren<{ company: Loading<Company> }>> = ({
  company,
  children,
}) =>
  company.match(
    (c) =>
      companyInPostgres(c) ? (
        <LoadedTradeAndOrderProvider company={c}>{children}</LoadedTradeAndOrderProvider>
      ) : (
        <>{children}</>
      ),
    <>{children}</>,
    <>{children}</>
  )

export const useIssuerPageTradesAndOrders = () => useContext(TradeAndOrderContext)

export default TradeAndOrderProvider
