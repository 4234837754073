import { CSSProperties, Key, ReactNode } from "react"
import { FixedSizeList } from "react-window"

export type TableRowRenderer<T> = ({
  item,
  style,
}: {
  item: T
  style: CSSProperties
}) => JSX.Element
export interface WindowedTableProps<T> {
  items: T[]
  headers: ReactNode[]
  headerRowClassName?: string
  headerCellClassName?: string | string[]
  bodyClassName?: string
  wrapperClassName?: string
  /** The total height of the table component, in pixels */
  tableHeight: number
  /** The width of the table, in pixels or as a CSS width property */
  tableWidth: number | string
  /** The height of a single row, in pixels */
  itemSize: number
  itemKey?: (i: number) => Key
  /** The number of rows to render outside the viewport */
  overscanCount?: number
  /** A function taking an X and returning a JSX element. The `style` argument is used by react-window to position elements, and should be supplied to the `style` attribute of the returned element. */
  renderRow: TableRowRenderer<T>
}

/** A table that handles mounting and unmounting rows as they enter and leave the viewport. */
export const WindowedTable = <T,>({
  items,
  headers,
  headerRowClassName,
  headerCellClassName,
  bodyClassName,
  renderRow,
  wrapperClassName,
  tableHeight,
  itemKey,
  overscanCount,
}: WindowedTableProps<T>) => {
  const IxRow = TableRow(renderRow)(items)

  return (
    <div className={wrapperClassName}>
      <table className="w-full table-auto">
        <TableHeader
          headers={headers}
          rowClassName={headerRowClassName}
          cellClassName={headerCellClassName}
        />
        <FixedSizeList
          className={bodyClassName}
          height={tableHeight}
          width="100%"
          itemSize={90}
          itemCount={items.length}
          itemKey={itemKey}
          overscanCount={overscanCount}
          style={{ overflowX: "hidden" }}
        >
          {IxRow}
        </FixedSizeList>
      </table>
    </div>
  )
}

export const TableHeader = ({
  headers,
  cellClassName,
  rowClassName,
}: {
  headers: ReactNode[]
  cellClassName?: string | string[]
  rowClassName?: string
}) => (
  <thead>
    <tr className={`${rowClassName || ""}`}>
      {headers.map((h, i) => (
        <th
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          className={`${
            (Array.isArray(cellClassName) ? cellClassName[i] : cellClassName) || ""
          } inline-block`}
        >
          {h}
        </th>
      ))}
    </tr>
  </thead>
)

export const TableRow =
  <T,>(Row: ({ item, style }: { item: T; style: CSSProperties }) => JSX.Element) =>
  (items: T[]) =>
  ({ index, style }: { index: number; style: CSSProperties }) =>
    Row({ item: items[index], style })
