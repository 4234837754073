import { chain, difference } from "lodash"
import { useMemo } from "react"
import Select, { GroupBase } from "react-select"
import Typography, { Color, Size } from "@stories/components/Typography/Typography"
import { LinkedBroker } from "common/model/LinkedBroker"
import { SearchIcon } from "@stories/icons/SearchIcon"

const groupBrokersByFirmName = (brokers: LinkedBroker[]): GroupBase<LinkedBroker>[] =>
  chain(brokers)
    .groupBy("firmName")
    .toPairs()
    .map(([firmName, options]) => ({
      label: firmName,
      options,
    }))
    .value()

const searchableLinkedBrokerKeys: string[] = [
  "firmName",
  "fullName",
] satisfies (keyof LinkedBroker)[]
const stringifyBrokerItem = (b: LinkedBroker): string =>
  Object.entries(b)
    .flatMap(([k, v]) => (searchableLinkedBrokerKeys.includes(k) ? [v] : []))
    .join(" ")
    .toLowerCase()

const Placeholder = ({ isFocused, hasValue }: { isFocused: boolean; hasValue: boolean }) => (
  <div className="flex items-center space-x-2 text-neutral-800 text-sm">
    <SearchIcon />
    {isFocused || hasValue ? null : (
      <Typography text="Search for your broker" size={Size.Small} color={Color.Placeholder} />
    )}
  </div>
)

const BrokerSearch: React.FC<
  {
    addSelectedBroker: (b: LinkedBroker) => void
    allBrokers: LinkedBroker[]
    disabled?: boolean
  } & (
    | {
        selectedBrokers?: never
        selectedBroker: LinkedBroker | null
        clearSelectedBroker: () => void
        isSingleSelect: true
      }
    | {
        selectedBrokers: LinkedBroker[]
        selectedBroker?: never
        clearSelectedBroker?: never
        isSingleSelect?: false
      }
  )
> = ({
  addSelectedBroker,
  selectedBrokers,
  allBrokers,
  disabled,
  selectedBroker,
  clearSelectedBroker,
  isSingleSelect,
}) => {
  const groupedBrokers = useMemo(
    () =>
      groupBrokersByFirmName(
        difference(allBrokers, selectedBrokers ?? (selectedBroker ? [selectedBroker] : []))
      ),
    [selectedBroker, selectedBrokers, allBrokers]
  )

  return (
    <Select<LinkedBroker>
      className=""
      isDisabled={disabled}
      styles={{
        control: (base) => ({
          ...base,
          borderColor: "#d3d7dd",
          "&:hover": {
            borderColor: "#d3d7dd",
          },
          borderRadius: 4,
          boxShadow: "none",
          display: "flex",
          alignItems: "center",
          padding: "0px 0.5rem",
          zIndex: 40,
        }),
        valueContainer: () => ({
          display: "flex",
          alignItems: "center",
          zIndex: 40,
        }),
        input: (base) => ({
          ...base,
          borderColor: "#d3d7dd",
          outline: "0px !important",
          boxShadow: "none",
          marginLeft: "10px",
          "&:focus-visible": {
            boxShadow: "none",
            outline: "0px !important",
          },
          ":focus": {
            outline: "none",
            boxShadow: "none",
          },
          zIndex: 40,
        }),
      }}
      components={{ Placeholder, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
      value={isSingleSelect ? selectedBroker : null}
      onFocus={isSingleSelect ? clearSelectedBroker : () => null}
      onChange={(b) => (b ? addSelectedBroker(b) : null)}
      options={groupedBrokers}
      filterOption={(option, search) =>
        stringifyBrokerItem(option.data).includes(search.toLowerCase())
      }
      getOptionValue={(v) => v.id}
      getOptionLabel={(v) => v.fullName}
    />
  )
}

export default BrokerSearch
