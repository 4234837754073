import { Modal, useModal } from "@components/layout/Modal"
import { Button } from "@stories/components/Button/Button"
import { Input } from "@stories/components/Inputs/Input/Input"
import Typography, { Size } from "@stories/components/Typography/Typography"
import { CheckCircleIcon } from "@stories/icons/CheckCircleIcon"
import { AccountTradingRegistration } from "common/model/Account/AccountTradingRegistration"
import { Loading, isLoaded } from "common/utils/Loading"
import { emailPatternString } from "common/utils/StringUtils"
import { assertUnreachable } from "common/utils/fp/Function"
import { motion } from "framer-motion"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { APIEndpoints, getAPIResponse } from "src/firebase/API"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { handleConsoleError } from "src/utils/Tracking"

export const TradingRegistrationInviteModal = ({
  tradingRegistration,
  onClose,
}: {
  tradingRegistration: Loading<Pick<AccountTradingRegistration, "id">>
  onClose: () => void
}) => {
  const user = useLoggedInUser()
  const [status, setStatus] = useState<"sent" | "not sent">("not sent")
  const [email, setEmail] = useState<string>()
  const [name, setName] = useState<string>()
  const [invalidEmail, setInvalidEmail] = useState<boolean>()
  const [loading, setLoading] = useState<boolean>()
  const handleSendInvite = () => {
    if (email && emailPatternString.test(email) && isLoaded(tradingRegistration)) {
      setInvalidEmail(false)
      setLoading(true)
      getAPIResponse(
        APIEndpoints.inviteUserToTradingRegistration,
        { email, name, tradingRegistrationId: tradingRegistration.id },
        user
      )
        .catch(handleConsoleError)
        .then(() => setStatus("sent"))
        .finally(() => setLoading(false))
    } else {
      setInvalidEmail(true)
    }
  }
  return (
    <div className="flex flex-col gap-4">
      <Typography
        size={Size.Small}
        text="Send the Trading Registration for a colleague to complete on your behalf. They will receive a link to fill in the form with limited access to Caplight."
      />
      <motion.div
        initial={{ x: 10, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        key={status}
        transition={{ duration: 0.2 }}
      >
        <div className="flex flex-col gap-4">
          {status === "not sent" ? (
            <>
              <Input
                label="Colleague Name"
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter Name..."
              />
              <Input
                label="Colleague Email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Email..."
                isDanger={invalidEmail}
                hintText={invalidEmail ? "Invalid Email" : undefined}
              />
              <Button
                label="Send Invite"
                onClick={handleSendInvite}
                isLoading={loading}
                isFullWidth
              />
            </>
          ) : status === "sent" ? (
            <>
              <div className="flex gap-2 items-center">
                <CheckCircleIcon />
                <Typography text={`Invite sent to ${email ?? ""}.`} />
              </div>
              <Button
                label="Return to Platform"
                onClick={onClose}
                isLoading={loading}
                isFullWidth
              />
            </>
          ) : (
            assertUnreachable(status)
          )}
        </div>
      </motion.div>
    </div>
  )
}

export const InviteColleagueToTradingRegistrationButton = ({
  renderButton,
  tradingRegistration,
}: {
  renderButton: (onClick: () => void) => React.ReactElement
  tradingRegistration: Loading<Pick<AccountTradingRegistration, "id">>
}) => {
  const { open, openModal, closeModal } = useModal()
  const navigate = useNavigate()
  const handleClose = () => {
    navigate("/")
    closeModal()
  }
  return (
    <>
      {renderButton(openModal)}
      {open ? (
        <Modal
          subtitle="Invite Colleague"
          open
          handleClose={closeModal}
          closable
          width={500}
          body={
            <TradingRegistrationInviteModal
              tradingRegistration={tradingRegistration}
              onClose={handleClose}
            />
          }
        />
      ) : null}
    </>
  )
}
