import CompanyAutocompletePill from "@components/companies/select/CompanyAutocompletePill"
import { Button } from "@stories/components/Button/Button"
import { PlusIcon } from "@stories/icons/PlusIcon"
import { SelectedCompany } from "common/model/Company"
import { useState } from "react"

export const AddCompanyRow = ({
  buttonLabel,
  onDisplayCompany,
  onAddCompany,
}: {
  buttonLabel: string
  onDisplayCompany?: (v: boolean) => void
  onAddCompany: (c: SelectedCompany) => void
}) => {
  const [displayAddCompany, setDisplayAddCompany] = useState<boolean>(false)

  const handleDisplayCompany = (v: boolean) => {
    onDisplayCompany?.(v)
    setDisplayAddCompany(v)
  }

  return (
    <>
      {displayAddCompany ? (
        <div className="h-8 border-t">
          <div className="px-2 flex gap-4 items-center justify-start">
            <CompanyAutocompletePill
              autoFocus
              limitResults={2}
              selected={undefined}
              handleSelect={(selected) => {
                if (selected) {
                  onAddCompany(selected)
                }
              }}
            />
            <Button onClick={() => handleDisplayCompany(false)} variant="hollow" label="Cancel" />
          </div>
        </div>
      ) : (
        <div className="h-8 border-t flex items-center">
          <Button
            variant="hollow"
            leftIcon={<PlusIcon />}
            label={buttonLabel}
            onClick={() => handleDisplayCompany(true)}
          />
        </div>
      )}
    </>
  )
}
