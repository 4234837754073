import {
  IntroductionRequestDataKeys,
  IntroductionRequestFormState,
} from "common/model/IntroductionRequest"
import React from "react"
import { DoNoIntroSection, NotesSection } from "@components/OrderFormComponents/NotesSection"
import { requestFormLabels } from "@components/OrderFormComponents/labels"

const SectionWrapper = ({
  children,
  hidden,
  className = "",
}: {
  children: React.ReactNode
  hidden?: boolean
  className?: string
}) => <>{!hidden ? <div className={`${className} mb-1 mt-1`}>{children}</div> : null}</>

const SupplementaryIntroductionRequestForm = ({
  introductionData,
  onChange,
  readOnlyFields,
  userIsClient,
}: {
  introductionData: IntroductionRequestFormState
  onChange: (payload: IntroductionRequestFormState) => void
  readOnlyFields?: IntroductionRequestDataKeys[]
  userIsClient?: boolean
}) => {
  const labels = userIsClient ? requestFormLabels.Client : requestFormLabels.Intermediary

  return (
    <>
      <SectionWrapper>
        <NotesSection
          hiddenFields={readOnlyFields}
          title={labels.notes.title}
          titles={labels.notes.titles}
          value={introductionData}
          onChange={(updated) => onChange({ ...introductionData, ...updated })}
        />
      </SectionWrapper>
      <SectionWrapper>
        <DoNoIntroSection
          hiddenFields={readOnlyFields}
          title={labels.doNotIntroNotes.title}
          titles={labels.doNotIntroNotes.titles}
          value={introductionData}
          onChange={(updated) => onChange({ ...introductionData, ...updated })}
        />
      </SectionWrapper>
    </>
  )
}

export default SupplementaryIntroductionRequestForm
