import moment from "moment"
import { CompanyIdFields } from "../../../model/Company"
import { valuationFromPricePerShareInformation } from "../../../model/data-product/valuationCalculation/Valuation"
import { Just, Nothing, nullableToMaybe } from "../../../containers/Maybe"
import { PricingDataRoot } from "../../pricing/PricingDataSource"
import { Enriching, ValuationDisplayData } from "../../pricing/PricingEnrichment"

export const enrichWithImpliedValuation =
  (dataSource: PricingDataRoot) =>
  (companyIdFields: CompanyIdFields, date: Date): Enriching<"priceDisplay", "valuationDisplay"> =>
  async (t) => {
    const company = await dataSource.company(companyIdFields.id).get(null, moment(date))
    const maybePriceDisplayData = t.priceDisplay.match(() => Nothing, Just)
    const valuation = maybePriceDisplayData.match(
      (priceDisplayData) =>
        valuationFromPricePerShareInformation(
          {
            price: priceDisplayData.pricePerShare, // this data is already split adjusted for today
            priceDate: priceDisplayData.displayDate,
            splitAdjustedForDate: new Date(),
            isSplitAdjusted: true,
          },
          company
        ).match(
          (x) => x.valuation,
          () => undefined
        ),
      () => undefined
    )
    return {
      ...t,
      valuationDisplay: maybePriceDisplayData.alongside(nullableToMaybe(valuation)).map(
        ([priceDisplayData, v]) =>
          ({
            valuation: v,
            displayDate: priceDisplayData.displayDate,
          } satisfies ValuationDisplayData)
      ),
    }
  }

export const enrichWithPPSImpliedValuation =
  (dataSource: PricingDataRoot) =>
  (
    companyIdFields: CompanyIdFields,
    date: Date
  ): Enriching<"pricePerShareDisplay", "valuationDisplay"> =>
  async (t) => {
    const company = await dataSource.company(companyIdFields.id).get(null, moment(date))
    const valuation = valuationFromPricePerShareInformation(
      {
        price: t.pricePerShareDisplay.pricePerShare,
        priceDate: t.pricePerShareDisplay.displayDate,
        splitAdjustedForDate: new Date(),
        isSplitAdjusted: true,
      },
      company
    ).match(
      (x) => x.valuation,
      () => undefined
    )

    return {
      ...t,
      valuationDisplay: nullableToMaybe(valuation).map(
        (v) =>
          ({
            valuation: v,
            displayDate: t.pricePerShareDisplay.displayDate,
          } satisfies ValuationDisplayData)
      ),
    }
  }
