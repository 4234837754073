import { collections } from "common/firestore/Collections"
import { AccessControlTier } from "common/model/AccessControl/AccessControlTier"
import {
  Account,
  AccountAccessFields,
  AccountIdFields,
  AccountTierFields,
} from "common/model/Account"
import { Firestore, doc, updateDoc } from "firebase/firestore"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"

const updateAccountTier = ({
  db,
  accessControlTier,
  account,
}: {
  db: Firestore
  accessControlTier: AccessControlTier
  account: Pick<AccountIdFields & AccountAccessFields, "id" | "productAreas">
}) =>
  updateDoc(doc(db, collections.accounts, account.id), {
    accessControlTier,
    ...(!account.productAreas.includes("data")
      ? { productAreas: [...account.productAreas, "data"] }
      : {}),
  } satisfies AccountTierFields & (Pick<AccountAccessFields, "productAreas"> | { productAreas?: never }))

export const useAdminUpdateAccountTier = (account: Account) => {
  const { db } = useFirebase9()
  const loggedInUser = useLoggedInUser()
  if (!loggedInUser.isAdmin) {
    throw new Error("useAdminUpdateAccountTier should not be used by non-admins")
  }
  return (accessControlTier: AccessControlTier) =>
    window.confirm(
      `Are you sure you want to update this accounts's tier?\nAccount: ${account.name}\nOld Tier: ${
        account.accessControlTier ?? "Undefined"
      }\nNew Tier: ${accessControlTier}`
    )
      ? updateAccountTier({ db, account, accessControlTier })
      : Promise.reject()
}
