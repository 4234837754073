import { RichTextNode } from "../RichText/richText"
import { SubmittedDocument } from "../files/DocumentSubmission"

export type DealDistributionParticipantViewOption =
  | {
      tag: "company"
      hidden?: boolean
      notes: RichTextNode | null
    }
  | {
      tag: "data-room"
      hidden?: boolean
      documents: SubmittedDocument[]
    }
  // | {
  //     tag: "investors"
  //     hidden?: boolean
  //   }
  | {
      tag: "funding-rounds"
      hidden?: boolean
    }
  // | {
  //     tag: "news"
  //     hidden?: boolean
  //   }
  | {
      tag: "history"
      hidden?: boolean
    }
  | {
      tag: "comparables"
      hidden?: boolean
    }
  | {
      tag: "price-history"
      hidden?: boolean
    }

export type Section = {
  id: DealDistributionParticipantViewOption["tag"]
  title: string
  content: React.ReactNode
  isAlwaysDisplayed?: boolean
}

export type DealPreviewSettings = {
  displayedSections: Record<DealDistributionParticipantViewOption["tag"], boolean>
}

export const defaultDealDistributionParticipantViewOptions: DealDistributionParticipantViewOption[] =
  [
    { tag: "company", notes: null },
    { tag: "data-room", documents: [] },
    // { tag: "investors" },
    { tag: "funding-rounds" },
    // { tag: "news" },
    { tag: "history" },
    { tag: "comparables" },
    { tag: "price-history" },
  ]
