/* eslint-disable no-restricted-syntax */
/** This will eventually fully transform our order models into something sane. */

import { hash } from "immutable"
import { clone, partition } from "lodash"
import { UnsafeRec } from "../../../../utils/RecordUtils"
import { KVP } from "../../../../utils/data/AssociationList"
import { Expand, expand } from "../../../../utils/types/Expand"
import { Order } from "../Internal"
import { OrderTermV2 } from "./OrderTermV2"
import { OrderStatusFields } from "../../Types/Status"
import { Case } from "../../../../utils/types/Sum"
import { isHiddenOutlierResult } from "../../../data-product/DataPoint"
import { unique } from "../../../../utils/data/Array/ArrayUtils"
import { AtomicOrderUpdate } from "../UpdateLog"

export const reformatOrderTermsV1ToV2 = ({
  terms,
  outlierStatus,
}: Pick<Order, "terms" | "outlierStatus">): OrderTermV2[] => {
  const flattenDates = <K, V extends { id: string } | null>(
    arr: KVP<K, Partial<Record<number, V>>>[]
  ) =>
    arr.flatMap(({ key, value }) =>
      UnsafeRec.entries(value).flatMap(([timestamp, contents]) =>
        contents || contents === null
          ? [
              {
                structureTerms: key,
                timestamp:
                  typeof timestamp === "string" ? Number.parseInt(timestamp, 10) : timestamp,
                quantityTerms: contents,
                id: contents?.id ?? hash(key).toString() + hash(timestamp).toString(),
              },
            ]
          : []
      )
    )
  const addTag =
    <const T>(tag: T) =>
    <R>(obj: R): Expand<R & { tag: T }> =>
      expand({ ...obj, tag })
  const flatTermsRecord = {
    call_option: flattenDates(terms.call_option).map(addTag("call_option")),
    put_option: flattenDates(terms.put_option).map(addTag("put_option")),
    collective_liquidity_exchange_fund: flattenDates(terms.collective_liquidity_exchange_fund).map(
      addTag("collective_liquidity_exchange_fund")
    ),
    direct: flattenDates(terms.direct).map(addTag("direct")),
    forward: flattenDates(terms.forward).map(addTag("forward")),
    spv: flattenDates(terms.spv).map(addTag("spv")),
    structure_agnostic_regular_way: flattenDates(terms.structure_agnostic_regular_way).map(
      addTag("structure_agnostic_regular_way")
    ),
    swap: flattenDates(terms.swap).map(addTag("swap")),
    unknown: flattenDates(terms.unknown).map(addTag("unknown")),
    variable_prepaid_forward: flattenDates(terms.variable_prepaid_forward).map(
      addTag("variable_prepaid_forward")
    ),
  } satisfies Record<keyof typeof terms, unknown>
  return Object.values(flatTermsRecord)
    .flat()
    .map((x) =>
      x.id in (outlierStatus?.outlierSummary?.terms ?? {})
        ? { ...x, outlier: outlierStatus?.outlierSummary?.terms?.[x.id] }
        : x
    )
}

/** Unsafe and mutates its argument. Do not export */
const unsafeAssign = <R extends Record<K, unknown>, K extends string | number | symbol, V>(
  rec: R,
  key: K,
  value: V
) => {
  // deliberately unsafe
  // eslint-disable-next-line better-mutation/no-mutation, rulesdir/no-assert, no-param-reassign
  rec[key] = value as R[K]
}
const listTermSnapshots = (terms: OrderTermV2[], status: OrderStatusFields) => {
  const snapshotTimestamps = unique(
    terms
      .map((t) => t.timestamp)
      .concat((status._orderStatusHistory ?? []).map((x) => x.asOf.valueOf()))
      .sort((l, r) => l - r)
  )
  // eslint-disable-next-line rulesdir/no-let
  let currentTerms: { [key in OrderTermV2["tag"]]: null | Case<OrderTermV2, key> } = {
    call_option: null,
    collective_liquidity_exchange_fund: null,
    direct: null,
    forward: null,
    put_option: null,
    spv: null,
    structure_agnostic_regular_way: null,
    swap: null,
    unknown: null,
    variable_prepaid_forward: null,
  }
  const acc: { timestamp: number; terms: typeof currentTerms }[] = []

  // eslint-disable-next-line rulesdir/no-let
  let sortedTerms = terms.sort((l, r) => l.timestamp - r.timestamp)
  for (const timestamp of snapshotTimestamps) {
    currentTerms = clone(currentTerms)
    const [recentTerms, newerTerms] = partition(sortedTerms, (t) => t.timestamp <= timestamp)
    for (const v of recentTerms) {
      unsafeAssign(currentTerms, v.tag, v)
    }
    acc.push({ timestamp, terms: currentTerms })
    sortedTerms = newerTerms
  }
  return acc
}

export const termSnapshots = (
  order: Pick<Order, "terms" | "outlierStatus" | keyof OrderStatusFields>,
  options?: {
    includeOutliers?: boolean
  }
) =>
  listTermSnapshots(
    reformatOrderTermsV1ToV2(order).filter(
      (t) => !t.outlier || (options?.includeOutliers ? true : !isHiddenOutlierResult(t.outlier))
    ),
    order
  )

export const partitionTermSnapshot = (
  snapshot: ReturnType<typeof termSnapshots>[number]
): AtomicOrderUpdate[] =>
  Object.values(snapshot.terms).flatMap((v) =>
    v && v.quantityTerms && (!v.outlier || !isHiddenOutlierResult(v.outlier))
      ? [
          {
            quantityTerms: v.quantityTerms,
            updateDate: new Date(v.timestamp),
            structure: v.tag,
            structureTerms: v.structureTerms,
          } as AtomicOrderUpdate,
        ]
      : []
  )
