import * as Sentry from "@sentry/react"
import { ErrorBoundary } from "@sentry/react/dist/errorboundary"
import React from "react"
import { Provider as ReduxProvider } from "react-redux"
import { FirebaseOwnOrderControlsProvider } from "src/pages/Orders/MyOrders/Controls/Firebase"
import { ErrorBanner } from "../components/errors/ErrorBanner"
import ErrorSnackbarRenderer from "../components/errors/ErrorSnackbarRenderer"
import FirebaseContext from "../firebase/Context"
import Firebase from "../firebase/Firebase"
import Firebase9 from "../firebase/Firebase9"
import Firebase9Context from "../firebase/Firebase9Context"
import { store } from "../state/store"
import AuthUserProvider from "./authUser/AuthUserProvider"
import { CurrentUserProvider } from "./currentUser/useCurrentUser"
import { DarkpoolOrderProvider } from "./data/DarkpoolOrderProvider"
import { IntroductionRequestProvider2 } from "./data/IntroductionRequestProvider"
import { OrderInquiryProvider } from "./data/OrderInquiryProvider"
import { OrderProvider } from "./data/OrderProvider"
import { ErrorHandlerProvider } from "./errorHandler"
import { FeatureFlagsProvider } from "./featureFlags/FeatureFlagsProvider"
import { IntercomChatProvider } from "./intercom/IntercomChatProvider"
import { CompanyProvider } from "./data/CompanyProvider"
import { AccountActivityLogProvider } from "./data/AccountActivityLogProvider"
import { TentativeInterestProvider } from "./data/TentativeInterestProvider"
import { AccountCompanyAccessProvider } from "./AccessControl/CompanyAccessProvider"
import { AccessControlProvider } from "./AccessControl/AccessControlProvider"
import TopNavbarRefProvider from "./TopNavbar/TopNavbarRefProvider"
import { AccountSnapshotProvider } from "./AccountSnapshot/useAccountSnapshot"
import { MetaTagProvider } from "./meta/MetaTagProvider"

export const firebase = new Firebase()
export const firebase9 = new Firebase9()

export const DataProviders: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <FirebaseContext.Provider value={firebase}>
    <Firebase9Context.Provider value={firebase9}>
      <ReduxProvider store={store}>
        <FirebaseOwnOrderControlsProvider>{children}</FirebaseOwnOrderControlsProvider>
      </ReduxProvider>
    </Firebase9Context.Provider>
  </FirebaseContext.Provider>
)

const AuthedDataProviders: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <CompanyProvider>
    <OrderProvider>
      <DarkpoolOrderProvider>
        <TentativeInterestProvider>
          <OrderInquiryProvider>
            <IntroductionRequestProvider2>
              <AccountActivityLogProvider>{children}</AccountActivityLogProvider>
            </IntroductionRequestProvider2>
          </OrderInquiryProvider>
        </TentativeInterestProvider>
      </DarkpoolOrderProvider>
    </OrderProvider>
  </CompanyProvider>
)

const AppProviders: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <MetaTagProvider>
    <Sentry.ErrorBoundary fallback={ErrorBanner}>
      <ErrorBoundary fallback={ErrorBanner}>
        <ErrorHandlerProvider>
          <ErrorSnackbarRenderer />
          <DataProviders>
            <AuthUserProvider>
              <CurrentUserProvider>
                <AccountSnapshotProvider>
                  <FeatureFlagsProvider>
                    <AccessControlProvider>
                      <AccountCompanyAccessProvider>
                        <IntercomChatProvider>
                          <TopNavbarRefProvider>
                            <AuthedDataProviders>{children}</AuthedDataProviders>
                          </TopNavbarRefProvider>
                        </IntercomChatProvider>
                      </AccountCompanyAccessProvider>
                    </AccessControlProvider>
                  </FeatureFlagsProvider>
                </AccountSnapshotProvider>
              </CurrentUserProvider>
            </AuthUserProvider>
          </DataProviders>
        </ErrorHandlerProvider>
      </ErrorBoundary>
    </Sentry.ErrorBoundary>
  </MetaTagProvider>
)

export default AppProviders
