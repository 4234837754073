import { wrapIcon } from "./IconWrapper"

export const CrossIcon = wrapIcon(
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.94001 8L11.8067 5.14C11.9322 5.01446 12.0027 4.8442 12.0027 4.66666C12.0027 4.48913 11.9322 4.31887 11.8067 4.19333C11.6811 4.06779 11.5109 3.99727 11.3333 3.99727C11.1558 3.99727 10.9855 4.06779 10.86 4.19333L8.00001 7.06L5.14001 4.19333C5.01447 4.06779 4.84421 3.99727 4.66667 3.99727C4.48914 3.99727 4.31887 4.06779 4.19334 4.19333C4.0678 4.31887 3.99728 4.48913 3.99728 4.66666C3.99728 4.8442 4.0678 5.01446 4.19334 5.14L7.06001 8L4.19334 10.86C4.13085 10.922 4.08126 10.9957 4.04741 11.0769C4.01357 11.1582 3.99614 11.2453 3.99614 11.3333C3.99614 11.4213 4.01357 11.5085 4.04741 11.5897C4.08126 11.671 4.13085 11.7447 4.19334 11.8067C4.25531 11.8691 4.32905 11.9187 4.41029 11.9526C4.49153 11.9864 4.57866 12.0039 4.66667 12.0039C4.75468 12.0039 4.84182 11.9864 4.92306 11.9526C5.0043 11.9187 5.07803 11.8691 5.14001 11.8067L8.00001 8.94L10.86 11.8067C10.922 11.8691 10.9957 11.9187 11.077 11.9526C11.1582 11.9864 11.2453 12.0039 11.3333 12.0039C11.4213 12.0039 11.5085 11.9864 11.5897 11.9526C11.671 11.9187 11.7447 11.8691 11.8067 11.8067C11.8692 11.7447 11.9188 11.671 11.9526 11.5897C11.9864 11.5085 12.0039 11.4213 12.0039 11.3333C12.0039 11.2453 11.9864 11.1582 11.9526 11.0769C11.9188 10.9957 11.8692 10.922 11.8067 10.86L8.94001 8Z"
      fill="currentColor"
    />
  </svg>
)
