import { ButtonProps } from "@stories/components/Button/Button"
import { DealCRMContact, DealCRMContactIdFields } from "common/model/DealCRM/DealCRMContact"
import { SyntheticEvent } from "react"
import ContactNameButton, { UnknownContactButton } from "../ContactDisplay/ContactNameButton"
import { useContactDetailsDrawer } from "./helpers"

type CRMContactNameProps = {
  contact: DealCRMContactIdFields | null
  beforeClickAction?: () => void
  hidePopover?: boolean
  size?: ButtonProps["size"]
  variant?: ButtonProps["variant"]
  tabIndex?: number
  isSubtitleDisplayed?: boolean
  isIconDisplayed?: boolean
  clickable?: boolean
  onAddMissingContact?: (c: DealCRMContact) => Promise<void>
}

export const CRMContactName = ({
  contact,
  clickable,
  beforeClickAction,
  hidePopover = false,
  size,
  variant = "hollow",
  tabIndex,
  isSubtitleDisplayed,
  isIconDisplayed = true,
  onAddMissingContact,
}: CRMContactNameProps) => {
  const openDetailsDrawer = useContactDetailsDrawer()

  if (!contact) {
    return (
      <UnknownContactButton
        size={size}
        tabIndex={tabIndex}
        onAddMissingContact={clickable !== false ? onAddMissingContact : undefined}
      />
    )
  }

  const handleClick = (e: SyntheticEvent) => {
    beforeClickAction?.()
    e.stopPropagation()
    openDetailsDrawer(contact.id)
  }

  return (
    <ContactNameButton
      contact={contact}
      variant={variant}
      onNameClick={(e) => {
        if (clickable !== false) {
          handleClick(e)
        }
      }}
      onPopoverClick={handleClick}
      hidePopover={hidePopover}
      size={size}
      tabIndex={tabIndex}
      isSubtitleDisplayed={isSubtitleDisplayed}
      isIconDisplayed={isIconDisplayed}
    />
  )
}
