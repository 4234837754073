import { Button } from "@stories/components/Button/Button"
import NativeSelect from "@stories/components/Inputs/NativeSelect/NativeSelect"
import Typography from "@stories/components/Typography/Typography"
import { Maybe, Nothing } from "common/containers/Maybe"
import { Account } from "common/model/Account"
import {
  DEFAULT_ACCOUNT_GLOBAL_SIZE_FILTER_MINIMUM,
  GlobalMinimumSizeFilterSetting,
  globalMinimumSizeFilterSettings,
} from "common/model/Account/AccountGlobalSizeFilter"
import { Order } from "common/model/Order/Models/Wrapped"
import { Order as DBOrder } from "common/model/Order/Models/Internal"
import { head } from "common/utils/data/Array/ArrayUtils"
import { defaultIfLoading, isLoading } from "common/utils/Loading"
import { formatValuation } from "common/utils/math"
import { useMemo, useState } from "react"
import { useAdminOrdersByAccountQuery } from "src/queries/admin/useAdminOrdersByAccountQuery"
import { useAdminUpdateGlobalSizeFilterMinimum } from "src/queries/admin/useAdminUpdateAccountGlobalSizeFilter"
import { handleConsoleError } from "src/utils/Tracking"

export const AdminAccountDrawerSizeFilterSettings = ({ account }: { account: Account }) => {
  const onChangeGlobalSizeFilterMinimum = useAdminUpdateGlobalSizeFilterMinimum(account)
  const currentAccountMinimum =
    account.globalMinimumSizeFilterSetting ?? DEFAULT_ACCOUNT_GLOBAL_SIZE_FILTER_MINIMUM
  const [newAccountMinimum, setNewAccountMinimum] = useState<Exclude<
    GlobalMinimumSizeFilterSetting,
    undefined
  > | null>(account.globalMinimumSizeFilterSetting ?? null)
  const [isSaving, setIsSaving] = useState(false)

  // TODO: probably want to make this more efficient
  const accountOrders = useAdminOrdersByAccountQuery(account)

  const accountSmallestPublishedOrderSize: number | null = useMemo(() => {
    const getOrderSizeLowerBound = (order: DBOrder): Maybe<number> =>
      Order.wrapRaw({ order, company: Nothing })
        .bind((o) => o.amountUSD())
        .map((size) => size.lowerBound)

    const sortedOrderSizes = defaultIfLoading(accountOrders, [])
      .flatMap((order) => getOrderSizeLowerBound(order).toArray())
      .sort((a, b) => a - b)

    return head(sortedOrderSizes).withUnconstrainedDefault(null)
  }, [accountOrders])

  return (
    <div className="p-4 flex flex-col space-y-2">
      <Typography
        text={`Account Minimum Target Check Size: ${
          account.targetNotionalValueLow !== undefined
            ? formatValuation(account.targetNotionalValueLow)
            : "Not Set"
        }`}
      />
      <Typography
        text={`Account Minimum Published Order Size (includes market, unpriced, and darkpool): ${
          isLoading(accountOrders)
            ? "Loading Orders"
            : accountSmallestPublishedOrderSize !== null
            ? formatValuation(accountSmallestPublishedOrderSize)
            : "No Orders With USD Size"
        }`}
      />
      <Typography
        text={`Current Account Global Size Filter: ${formatValuation(currentAccountMinimum)}`}
      />
      <NativeSelect
        label="New Global Size Filter"
        value={newAccountMinimum?.toString()}
        onChange={(v) =>
          setNewAccountMinimum(
            globalMinimumSizeFilterSettings.find((n) => n.toString() === v) ?? null
          )
        }
        options={globalMinimumSizeFilterSettings.map((n) => ({
          value: n.toString(),
          label: formatValuation(n),
        }))}
        placeholder="No setting"
      />
      <Button
        label="Save Global Size Filter"
        onClick={async () => {
          setIsSaving(true)
          try {
            if (newAccountMinimum === null) {
              throw new Error(
                "newAccountMinimum was null when saving global size filter. This should not be possible."
              )
            }
            await onChangeGlobalSizeFilterMinimum(newAccountMinimum)
          } catch (e) {
            handleConsoleError(e)
          } finally {
            setIsSaving(false)
          }
        }}
        isDisabled={
          newAccountMinimum === null || newAccountMinimum === account.globalMinimumSizeFilterSetting
        }
        isLoading={isSaving}
      />
    </div>
  )
}
