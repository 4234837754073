import { wrapIcon } from "./IconWrapper"

const TopBrokerIcon = wrapIcon(
  <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.49995 8.74995C5.499 8.12969 5.67637 7.52224 6.01095 6.99995H2.62595C2.32789 7.00009 2.04207 7.11855 1.83131 7.32931C1.62055 7.54007 1.50209 7.82589 1.50195 8.12395V8.58395C1.50195 8.86995 1.59095 9.14895 1.75695 9.38195C2.52795 10.464 3.78995 11 5.49995 11C5.79995 11 6.08595 10.9835 6.35845 10.9505C5.80546 10.3512 5.49888 9.56538 5.49995 8.74995ZM5.49995 1.00195C6.16299 1.00195 6.79888 1.26535 7.26772 1.73419C7.73656 2.20303 7.99995 2.83891 7.99995 3.50195C7.99995 4.16499 7.73656 4.80088 7.26772 5.26972C6.79888 5.73856 6.16299 6.00195 5.49995 6.00195C4.83691 6.00195 4.20103 5.73856 3.73219 5.26972C3.26335 4.80088 2.99995 4.16499 2.99995 3.50195C2.99995 2.83891 3.26335 2.20303 3.73219 1.73419C4.20103 1.26535 4.83691 1.00195 5.49995 1.00195ZM11.5 8.74995C11.5 9.4793 11.2102 10.1788 10.6945 10.6945C10.1788 11.2102 9.4793 11.5 8.74995 11.5C8.02061 11.5 7.32113 11.2102 6.80541 10.6945C6.28968 10.1788 5.99995 9.4793 5.99995 8.74995C5.99995 8.02061 6.28968 7.32113 6.80541 6.80541C7.32113 6.28968 8.02061 5.99995 8.74995 5.99995C9.4793 5.99995 10.1788 6.28968 10.6945 6.80541C11.2102 7.32113 11.5 8.02061 11.5 8.74995ZM9.02745 7.20995C9.01029 7.14959 8.97391 7.09647 8.92383 7.05864C8.87376 7.02082 8.81271 7.00036 8.74995 7.00036C8.6872 7.00036 8.62615 7.02082 8.57607 7.05864C8.526 7.09647 8.48962 7.14959 8.47245 7.20995L8.19395 8.10395H7.29245C7.00945 8.10395 6.89245 8.48095 7.12095 8.65395L7.84995 9.20645L7.57145 10.1C7.48395 10.3805 7.79195 10.614 8.02095 10.4405L8.74995 9.88845L9.47895 10.4405C9.70795 10.614 10.016 10.3805 9.92845 10.1005L9.64995 9.20645L10.379 8.65445C10.608 8.48095 10.4905 8.10395 10.2075 8.10395H9.30595L9.02745 7.20995Z"
      fill="currentColor"
    />
  </svg>
)

export default TopBrokerIcon
