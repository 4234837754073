import {
  DealCRMContact,
  getCRMContactEmail,
  getCRMContactTagName,
} from "common/model/DealCRM/DealCRMContact"
import { isLoaded } from "common/utils/Loading"
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useFirebaseReader } from "src/firebase/Context"
import { getAccountCrmContacts, getLatestImportJob } from "../../../firebase/crm"
import { useCurrentUser } from "../../../providers/currentUser/useCurrentUser"
import { DealCRMImportJob } from "common/model/DealCRM/DealCRMImportJob"
import { sortByDate } from "common/utils/dateUtils"
import { keyBy } from "lodash"

interface CRMContactsContextType {
  contacts: DealCRMContact[]
  importJobs: DealCRMImportJob[]
  latestFirmImport: DealCRMImportJob | undefined
  latestIndividualImport: DealCRMImportJob | undefined
  findContactByEmail: (email: string | null) => DealCRMContact | null
  findContactById: (contactId: string) => DealCRMContact | null
  findContactByTagName: (tagName: string) => DealCRMContact | null
}

export const CRMContactsContext = createContext<CRMContactsContextType>({
  contacts: [],
  importJobs: [],
  latestFirmImport: undefined,
  latestIndividualImport: undefined,
  findContactByEmail: () => null,
  findContactById: () => null,
  findContactByTagName: () => null,
})

export const CRMContactsProvider = ({ children }: { children: ReactNode }) => {
  const firebase = useFirebaseReader()
  const user = useCurrentUser()
  const [contacts, setContacts] = useState<DealCRMContact[]>([])
  const [importJobs, setLatestFirmImport] = useState<DealCRMImportJob[]>([])
  useEffect(() => {
    if (isLoaded(user)) {
      const unsubscribe = getAccountCrmContacts({
        db: firebase,
        accountId: user.user.account.id,
      }).onSnapshot((snapshot) => {
        const contactDocs = snapshot.docs.map((doc) => doc.data())
        setContacts(contactDocs)
      })

      return unsubscribe
    }
    return () => {}
  }, [firebase, user])
  useEffect(() => {
    if (isLoaded(user)) {
      const unsubscribe = getLatestImportJob({
        db: firebase,
        accountId: user.user.account.id,
      }).onSnapshot((snapshot) => {
        const jobs = snapshot.docs.map((doc) => doc.data())
        setLatestFirmImport(jobs)
      })

      return unsubscribe
    }
    return () => {}
  }, [firebase, user])

  const latestFirmImport = useMemo(
    () =>
      // eslint-disable-next-line rulesdir/mutating-array-methods
      importJobs
        .filter((i) => i.contactType === "firm")
        .slice()
        .sort(sortByDate("createdAt")) // change to toSorted once polyfill situation is figured out
        .slice()
        .reverse()?.[0],
    [importJobs]
  )
  const latestIndividualImport = useMemo(
    () =>
      // eslint-disable-next-line rulesdir/mutating-array-methods
      importJobs
        .filter((i) => i.contactType === "individual")
        .slice()
        .sort(sortByDate("createdAt")) // change to toSorted once polyfill situation is figured out
        .slice()
        .reverse()?.[0],
    [importJobs]
  )

  const contactByUniqueTagName = useMemo(
    () => keyBy(contacts, (c) => getCRMContactTagName(c)),
    [contacts]
  )
  const findContactByTagName = useCallback(
    (tagName: string) => contactByUniqueTagName[tagName],
    [contactByUniqueTagName]
  )
  const contactsByEmail = useMemo(
    () => keyBy(contacts, (c) => getCRMContactEmail(c) ?? "EMAIL_NOT_FOUND"),
    [contacts]
  )
  const contactsById = useMemo(() => keyBy(contacts, (c) => c.id), [contacts])
  const findContactByEmail = useCallback(
    (email: string | null) => (email ? contactsByEmail[email] ?? null : null),
    [contactsByEmail]
  )
  const findContactById = useCallback(
    (contactId: string) => contactsById[contactId] ?? null,
    [contactsById]
  )

  const memoizedData = useMemo(
    () => ({
      contacts,
      importJobs,
      latestFirmImport,
      latestIndividualImport,
      findContactByEmail,
      findContactById,
      findContactByTagName,
    }),
    [
      contacts,
      importJobs,
      latestFirmImport,
      latestIndividualImport,
      findContactByEmail,
      findContactById,
      findContactByTagName,
    ]
  )

  return <CRMContactsContext.Provider value={memoizedData}>{children}</CRMContactsContext.Provider>
}

export const useCRMContacts = () => useContext(CRMContactsContext)
