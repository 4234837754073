import { Select } from "@stories/components/Antd/Select/Select"
import SkeletonLoader from "@components/icons/SkeletonLoader"
import { useOrderExpireModal } from "@components/OrderDetails/OrderDetailsWrapper"
import { orderToInternal } from "common/model/Order/Models/UpdateLog"
import { AnimatePresence, motion } from "framer-motion"
import { Button } from "@stories/components/Button/Button"
import { useOwnOrderControls } from "../MyOrders/Controls/Context"
import { OrderPageLoadingOrder } from "./types"
import { useState } from "react"
import {
  TerminalOrderStatusReason,
  isTerminalOrderStatusReasonRequiringClosedTrades,
  userTerminalOrderStatusReasonLabelMap,
  userTerminalOrderStatusReasons,
} from "common/model/Order/Types/Status"
import Typography, { Size, Weight } from "@stories/components/Typography/Typography"
import { OrderFormHeader } from "../OrderForm/OrderFormHeader"
import { CreateClosedTradeFromOrderButton } from "src/pages/ClosedTrades/ClosedTradeForm/CreateClosedTradeFromOrderButton"
import { deprecatedIsLoaded } from "common/utils/Loading"
import { useCurrentAccount } from "src/queries/currentUser/useCurrentAccount"
import { UploadIcon } from "@stories/icons/UploadIcon"
import { PriceObservationType } from "common/model/data-product/pricing/PriceObservation"
import { Card } from "@stories/components/Card/Card"

export const InnerOrderPageExpireContainer = ({
  order,
}: OrderPageLoadingOrder & { showInitialInformation?: boolean }) => {
  const account = useCurrentAccount()
  const { expireOrder } = useOwnOrderControls()
  const { close } = useOrderExpireModal()
  const [reason, setReason] = useState<TerminalOrderStatusReason>()
  const [loading, setLoading] = useState(false)
  // order expire
  const handleExpire = async (p?: PriceObservationType) =>
    order.match(
      async (wrappedO) => {
        if (reason) {
          setLoading(true)
          await expireOrder(orderToInternal(wrappedO.unboxed.order), reason, p?.id)
          close()
          setLoading(false)
        }
      },
      null,
      null
    )
  if (!deprecatedIsLoaded(account)) return null
  return order.match(
    (o) => (
      <div>
        <OrderFormHeader
          drawerTitle={`Expire My ${o.company().name} ${o.direction() === "buy" ? "Bid" : "Offer"}`}
          drawerSubtitle="Please provide details on why this order expired."
          onClose={close}
        />
        <Select
          placeholder="Select Expiration"
          className="w-full"
          options={userTerminalOrderStatusReasons
            .filter((r) =>
              r === "rofr" ? !!o.currentStructures().find((s) => s.is("direct")) : true
            )
            .map((r) => ({
              label: userTerminalOrderStatusReasonLabelMap[r],
              value: r,
            }))}
          onChange={setReason}
        />
        {reason && isTerminalOrderStatusReasonRequiringClosedTrades(reason) ? (
          <CreateClosedTradeFromOrderButton
            afterSave={handleExpire}
            order={orderToInternal(o.unboxed.order)}
            account={account}
            renderButton={(onClick) => (
              <div className="py-4">
                <Button
                  onClick={onClick}
                  label={reason === "rofr" ? "Submit ROFR'd Trade" : "Submit Closed Trade"}
                  variant="secondary"
                  leftIcon={<UploadIcon />}
                />
              </div>
            )}
          />
        ) : null}
        <div className="flex space-x-2 pt-4 w-full">
          <Button onClick={close} label="Cancel" variant="secondary" isFullWidth size="large" />
          <Button
            onClick={() => handleExpire()}
            label="Confirm"
            isLoading={loading}
            variant="primary"
            isFullWidth
            size="large"
          />
        </div>
      </div>
    ),
    <div className="w-100">
      <SkeletonLoader numRows={4} />
    </div>,
    <Button variant="primary" label="Something went wrong, Close" onClick={close} />
  )
}

export const OrderPageExpireContainer = ({
  order,
  expire,
}: OrderPageLoadingOrder & { expire?: boolean }) => (
  <AnimatePresence>
    {expire ? (
      <motion.div
        initial={{ opacity: 0, y: 40 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 40 }}
      >
        <Card>
          <InnerOrderPageExpireContainer order={order} />
        </Card>
      </motion.div>
    ) : null}
  </AnimatePresence>
)
