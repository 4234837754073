import { CompanyIdFields } from "common/model/Company"
import { isLoaded } from "common/utils/Loading"
import { useEffect, useMemo } from "react"
import { useFirebaseReader } from "src/firebase/Context"
import {
  addToLocalTargetIssuersByPostgresId,
  getOneTargetIssuer,
} from "src/state/business_logic/postgresData"
import { useAppDispatch } from "src/state/reduxHooks"
import {
  useAppSelector,
  targetIssuersSelector,
  serializedCompanySelector,
} from "src/state/reduxSelectors"
import { handleConsoleError } from "src/utils/Tracking"
import { unserializeCompany } from "../../state/actions/postgresData"

export const useLocalTargetIssuer = (company: CompanyIdFields) => {
  const firebase = useFirebaseReader()
  const dispatch = useAppDispatch()
  const targetIssuers = useAppSelector(targetIssuersSelector())
  const firestoreIssuer = useAppSelector(serializedCompanySelector(company))

  useEffect(() => {
    dispatch(getOneTargetIssuer(firebase, { firebaseId: company.id })).catch(handleConsoleError)
  }, [firebase, dispatch, company.id])

  useEffect(() => {
    // this functions checks and requests data for a company if it's not a target issuer
    if (isLoaded(targetIssuers) && !isLoaded(firestoreIssuer) && company.postgresCompanyId) {
      dispatch(addToLocalTargetIssuersByPostgresId(firebase, company.postgresCompanyId)).catch(
        (e: unknown) => {
          handleConsoleError(e)
        }
      )
    }
  }, [company, dispatch, firebase, targetIssuers, firestoreIssuer])
  return useMemo(
    () => (isLoaded(firestoreIssuer) ? unserializeCompany(firestoreIssuer) : firestoreIssuer),
    [firestoreIssuer]
  )
}
