import z from "zod"
import { assertExtends } from "../utils/data/Type/Assertions"

export type UserInvitedBroker = { id: string; fullName: string; email: string }

export const userInvitedBrokerSchema = z.object({
  fullName: z.string().min(1),
  email: z.string().email(),
})

assertExtends<UserInvitedBroker, z.infer<typeof userInvitedBrokerSchema>>()
