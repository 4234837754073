import { Transition } from "@headlessui/react"
import { Fragment, useEffect, useState } from "react"
import { ExclamationCircleIcon } from "@heroicons/react/outline"
import { ERROR_SNACKBAR_DURATION } from "../../providers/errorHandler/provider"
import { classNames } from "../../utils/classNames"

export const ErrorSnackbar = ({ message, index }: { message: string; index: number }) => {
  const [isShown, setShown] = useState(false)

  useEffect(() => {
    setTimeout(() => setShown(true), 100)
    setTimeout(() => setShown(false), ERROR_SNACKBAR_DURATION - 300)
  }, [])

  const NAVBAR_WITH_PADDING_HEIGHT = 72

  return (
    <Transition
      show={isShown}
      as={Fragment}
      enter="transition-opacity ease-linear duration-200"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity ease-linear duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="relative full-width">
        <div
          className={classNames(
            "fixed left-0 right-0 ml-auto mr-auto bg-danger-800 p-3 z-50 shadow-md rounded-sm"
          )}
          style={{ maxWidth: "38em", top: `${NAVBAR_WITH_PADDING_HEIGHT + index * 80}px` }}
        >
          <div className="flex justify-center">
            <div className="ml-3 text-white flex space-x-2 items-center">
              <ExclamationCircleIcon className="h-7 w-7" />
              <h3 className="text-sm font-medium text-white">{message}</h3>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  )
}
