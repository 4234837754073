import numeral from "numeral"
import { repeatToString } from "../data/Array/ArrayUtils"
import { BILLION, MILLION, THOUSAND } from "./constants"

export const formatPrice = (price: number): string => numeral(price).format("$0,0.00")

export const formatPriceRoundedToInteger = (price: number): string => numeral(price).format("$0")

export const formatShares = (numShares: number): string => numeral(numShares).format("0,0")
export const formatAbbreviatedShares = (numShares: number): string =>
  numeral(numShares).format("0a")

export const formatValuation = (x: number, rounding: number = 1) =>
  numeral(x)
    .format(`$0.${repeatToString("0", rounding)}a`)
    .toUpperCase()

export const formatPercentage = (number: number) => `${Math.round(number * 100)}%`
export const formatPercentageWithPlus = (number: number) =>
  `${number > 0 ? "+" : ""}${formatPercentage(number)}`

export const formatCurrency = (precision: number, x: number) =>
  numeral(x)
    .format(`(0.${repeatToString("0", precision)}a)`)
    .toUpperCase()

export const formatAbbreviatedNumber = (num: number, precision: number = 2) => {
  if (num >= BILLION) {
    return `${numeral(num / BILLION).format(`0.${repeatToString("0", precision)}`)}B`
  } else if (num >= MILLION) {
    return `${numeral(num / MILLION).format(`0.${repeatToString("0", precision)}`)}M`
  } else if (num >= THOUSAND) {
    return `${numeral(num / THOUSAND).format(`0.${repeatToString("0", precision)}`)}K`
  } else {
    return `${numeral(num).format(`0.${repeatToString("0", precision)}`)}`
  }
}
export const formatAbbreviatedCurrency = (num: number) => `$${formatAbbreviatedNumber(num, 2)}`

export const formatRoundedAbbreviatedCurrency = (num: number, precision = 1) =>
  `$${formatAbbreviatedNumber(num, precision)}`

export const formatPhoneNumber = (input: string): string => {
  const match = input.match(
    /[^a-zA-Z0-9]*([0-9]{3})[^a-zA-Z0-9]*([0-9]{3})[^a-zA-Z0-9]*([0-9]{4})$/
  )

  if (match) {
    const formattedNumber = `(${match[1]}) ${match[2]}-${match[3]}`
    return formattedNumber
  } else {
    return input
  }
}

export const humanReadableNumber = (num: number) => {
  const smallNumberMap: { [key: number]: string } = {
    0: "zero",
    1: "one",
    2: "two",
    3: "three",
    4: "four",
    5: "five",
    6: "six",
    7: "seven",
    8: "eight",
    9: "nine",
    10: "ten",
  }

  if (num < 10) {
    return smallNumberMap[num]
  }

  return num.toString()
}
