/** TODO delete this */
/* eslint-disable better-mutation/no-mutation */
/* eslint-disable rulesdir/no-assert */
import { Chart, LineController } from "chart.js"

/**
 * Custom chart tooltip for hovering, used by LineHoveredChartController
 * Tooltip placed at the top of the chart regardless of the y-value of the hovered point
 */
const tooltipPlugin = {
  id: "tooltip",
  positioners: {
    myCustomPositioner: (elements: unknown, eventPosition: { x: number }) => ({
      x: eventPosition.x,
      y: -20,
    }),
  },
}

/**
 * ChartJs LineChart extension that draws a vertical line on the chart
 * where the user hovers.
 */
/** @deprecated */
export class LineHoveredChartController extends LineController {
  axesLabeled = false

  override draw() {
    super.draw()

    const chart = this.chart as {
      tooltip?: { _eventPosition?: { x: number } }
      chartArea: { top: number; bottom: number }
    }

    if (chart.tooltip && chart.tooltip._eventPosition) {
      const { ctx } = this.chart
      const { x } = chart.tooltip._eventPosition
      const topY = chart.chartArea.top
      const bottomY = chart.chartArea.bottom

      //  draw vertical line on cursor
      ctx.save()
      ctx.beginPath()
      ctx.moveTo(x, topY)
      ctx.lineTo(x, bottomY)
      ctx.lineWidth = 2
      ctx.strokeStyle = "#B7B7B7"
      ctx.stroke()
      ctx.restore()
    }
  }
}
/** @deprecated */
export const registerChartJsCharts = () => {
  LineHoveredChartController.id = "lineHoveredLineChart"
  LineHoveredChartController.defaults = LineController.defaults
  Chart.register(LineHoveredChartController)
  Chart.registry.addPlugins(tooltipPlugin)
}
