import { Company } from "common/model/Company"
import { DealCRMDeal, DealCRMDealIdFields } from "common/model/DealCRM/Deal/DealCRMDeal"
import { DealCRMDealParticipant } from "common/model/DealCRM/Deal/DealCRMDealParticipant"
import { DealDistribution } from "common/model/DealDistribution/DealDistribution"
import { annotate } from "common/utils/Coerce"
import { Loading, defaultIfLoading, isLoaded, isLoading, matchLoading } from "common/utils/Loading"
import { groupBy, map2Map } from "common/utils/MapUtils"
import { nonemptyHead } from "common/utils/data/Array/Nonempty"
import { identity } from "common/utils/fp/Function"
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useFirebaseReader } from "src/firebase/Context"
import { getAllDocs } from "src/firebase/Firebase/utils"
import { getDealDistributions } from "src/firebase/dealDistributions"
import { handleConsoleError } from "src/utils/Tracking"
import { useQuerySnapshot } from "src/utils/hooks/queries/useQuerySnapshot"
import { getAccountCrmDeals, getAllDealsDealParticipants } from "../../../firebase/crm"
import { useCurrentUser } from "../../../providers/currentUser/useCurrentUser"
import { CrmDealData, CrmItemData } from "../DealKanbanBoard/KanbanCardModels"
import { useCRMBuySellInterest } from "./BuySellInterestProvider"
import { useCompanies } from "src/providers/data/CompanyProvider"
import { uniq, uniqBy } from "lodash"

interface CRMDealsContextType {
  deals: DealCRMDeal[]
  getDistributionForDeal: (deal: DealCRMDealIdFields) => DealDistribution | null
  crmItem: CrmItemData | null
  setCrmItem: React.Dispatch<React.SetStateAction<CrmItemData | null>>
  buildCrmData: (deal: DealCRMDeal) => CrmDealData | null
}

export const CRMDealsContext = createContext<CRMDealsContextType>({
  deals: [],
  getDistributionForDeal: () => null,
  crmItem: null,
  setCrmItem: () => {},
  buildCrmData: () => {
    throw new Error("buildCrmData not set up correctly")
  },
})

export const CRMDealsProvider = ({ children }: { children: ReactNode }) => {
  const user = useCurrentUser()
  const [selectedCRMItem, setCrmItem] = useState<CrmItemData | null>(null)
  const buySellInterest = useCRMBuySellInterest()
  const crmItem = useMemo(() => {
    if (selectedCRMItem?.tag === "crm_interest") {
      const interest =
        defaultIfLoading(buySellInterest, [])?.find((i) => i.id === selectedCRMItem.interest.id) ??
        selectedCRMItem.interest
      return {
        ...selectedCRMItem,
        interest,
      }
    }
    if (selectedCRMItem?.tag === "crm_deal") {
      const bids = selectedCRMItem.bids.map(
        (bid) => defaultIfLoading(buySellInterest, []).find((i) => i.id === bid.id) ?? bid
      )
      const offers = selectedCRMItem.offers.map(
        (offer) => defaultIfLoading(buySellInterest, []).find((i) => i.id === offer.id) ?? offer
      )
      return {
        ...selectedCRMItem,
        bids,
        offers,
      }
    }
    return selectedCRMItem
  }, [selectedCRMItem, buySellInterest])
  const [loadedCompanies, setLoadedCompanies] = useState<Company[]>([])

  const [dealParticipantsByDealId, setDealParticipantsByDealId] = useState<
    Record<string, Loading<DealCRMDealParticipant[]>>
  >({})
  const firebase = useFirebaseReader()

  const loadingDeals = useQuerySnapshot(
    (db) => {
      if (!isLoaded(user)) return null
      return getAccountCrmDeals({ db, accountId: user.user.account.id })
    },
    identity,
    [user]
  )

  const companyIds = useMemo(() => {
    if (!isLoaded(loadingDeals)) return []
    const dealCompanyIds = loadingDeals.map((deal) => deal.company.id)
    const buySellInterestCompanyIds = isLoaded(buySellInterest)
      ? buySellInterest.map((interest) => interest.company.id)
      : []
    return uniq([...dealCompanyIds, ...buySellInterestCompanyIds])
  }, [loadingDeals, buySellInterest])

  const companies = useCompanies(companyIds)

  useEffect(() => {
    if (isLoaded(companies)) {
      const newCompanies = companies.filter(
        (company) => !loadedCompanies.find((co) => co.id === company.id)
      )
      if (newCompanies.length)
        setLoadedCompanies(uniqBy(companies.concat(loadedCompanies), (co) => co.id))
    }
  }, [companies, setLoadedCompanies, loadedCompanies])

  const loadingDistributions = useQuerySnapshot(
    (db) => {
      if (!isLoaded(user)) return null
      return getDealDistributions({ db, user: user.user })
    },
    identity,
    [user]
  )

  const distributionByDealId = useMemo(
    () =>
      map2Map(
        groupBy<string, DealDistribution>(
          (d) => (d.tag === "deal-crm-deal" ? d.sourceId.id : "not-deal"),
          defaultIfLoading(loadingDistributions, [])
        )
      )((d) => nonemptyHead(d)),
    [loadingDistributions]
  )

  useEffect(() => {
    if (!isLoaded(loadingDeals) || !isLoaded(user)) return
    const newDeals = loadingDeals.filter((deal) => !dealParticipantsByDealId[deal.id])
    if (!newDeals.length) return

    getAllDealsDealParticipants({
      db: firebase,
      accountId: user.user.account.id,
    })
      .get()
      .then(getAllDocs)
      .then((result) => {
        const newOrChangedParticipants = result.find((dealParticipant) => {
          const existingDealParticipants = dealParticipantsByDealId[dealParticipant.deal.id]
          if (!isLoaded(existingDealParticipants)) return true
          return !existingDealParticipants.find((existing) => existing.id === dealParticipant.id)
        })
        if (newOrChangedParticipants) {
          const dealParticipants = result.reduce((acc, dealParticipant) => {
            acc[dealParticipant.deal.id] = [
              ...(acc[dealParticipant.deal.id] ?? []),
              dealParticipant,
            ]
            return acc
          }, annotate<Record<string, DealCRMDealParticipant[]>>({}))
          setDealParticipantsByDealId(dealParticipants)
        }
      })
      .catch((e) => {
        handleConsoleError(e)
      })
  }, [loadingDeals, dealParticipantsByDealId, setDealParticipantsByDealId, firebase, user])

  const getDistributionForDeal = useCallback(
    (deal: DealCRMDealIdFields) => distributionByDealId.get(deal.id) ?? null,
    [distributionByDealId]
  )

  const buildCrmData = useMemo(
    () =>
      (deal: DealCRMDeal): CrmDealData | null => {
        const company = loadedCompanies.find((co) => co.id === deal.company.id)

        if (!company) return null

        return {
          tag: "crm_deal",
          id: deal.id,
          deal,
          distribution: getDistributionForDeal(deal),
          company,
          dealParticipants: defaultIfLoading(dealParticipantsByDealId[deal.id], []),
          bids: isLoading(buySellInterest)
            ? []
            : deal.bidIds.flatMap((bidId) => {
                const bid = (buySellInterest ?? []).find((interest) => interest.id === bidId)
                return bid ? [bid] : []
              }),
          offers: isLoading(buySellInterest)
            ? []
            : deal.offerIds.flatMap((offerId) => {
                const offer = (buySellInterest ?? []).find((interest) => interest.id === offerId)
                return offer ? [offer] : []
              }),
        }
      },
    [buySellInterest, dealParticipantsByDealId, getDistributionForDeal, loadedCompanies]
  )

  const memoizedDeals = useMemo(
    () => ({
      deals: matchLoading(loadingDeals, identity, [], []),
      getDistributionForDeal,
      crmItem,
      setCrmItem,
      buildCrmData,
    }),
    [loadingDeals, getDistributionForDeal, crmItem, setCrmItem, buildCrmData]
  )

  return <CRMDealsContext.Provider value={memoizedDeals}>{children}</CRMDealsContext.Provider>
}

export const useCRMDeals = () => useContext(CRMDealsContext)
