import { Order } from "common/model/Order/Models/Wrapped"
import { shortDateFormat } from "common/utils/dateUtils"
import { AccessBoundary, DataAccessLevel } from "../../../../components/auth/DataAccess"
import { orderDataAccessLevel } from "../../shared/accessLevels"
import { ChevronRightIcon } from "@stories/icons/ChevronRightIcon"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { PrivateOrderIcon } from "@components/icons/PrivateOrderIcon"
import { WrappedOrderPriceDisplay } from "../../shared/Cells"
import { SourceAttributionTooltip } from "src/pages/Data/IssuerPage/DataTable/SourceAttributionTooltip"

export const LiveMarketTableHeaderCell: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => <th className="px-1 first:pl-3 last:pr-3 py-2">{children}</th>

export const LiveMarketTableCell: React.FC<
  React.PropsWithChildren<{ accessLevel: DataAccessLevel }>
> = ({ children, accessLevel }) => (
  <td className="px-1 first:pl-3 last:pr-3 py-2 whitespace-nowrap">
    <div className="flex flex-col items-start justify-start h-full">
      <AccessBoundary accessLevel={accessLevel}>{children}</AccessBoundary>
    </div>
  </td>
)

export type OrderCellProps = { order: Order }

export const UpdateCell = ({ order }: OrderCellProps) => {
  const user = useLoggedInUser()
  return (
    <LiveMarketTableCell accessLevel={orderDataAccessLevel.originationDate}>
      <div className="flex items-center gap-1">
        {order.sourceAttribution(user.user).match(
          (source) => (
            <SourceAttributionTooltip sourceAttribution={source}>
              <div className="w-3">
                <PrivateOrderIcon />
              </div>
            </SourceAttributionTooltip>
          ),
          () => (
            <div className="w-3" />
          )
        )}
        <div className="whitespace-nowrap">{shortDateFormat(order.latestUpdateDate())}</div>
      </div>
    </LiveMarketTableCell>
  )
}

export const PriceCell = ({ order }: OrderCellProps) => (
  <LiveMarketTableCell accessLevel={orderDataAccessLevel.price(order.isConnectable())}>
    <WrappedOrderPriceDisplay order={order} />
  </LiveMarketTableCell>
)

export const CTACell = () => (
  <LiveMarketTableCell accessLevel={orderDataAccessLevel.view}>
    <div className="flex flex-col h-full justify-center">
      <ChevronRightIcon />
    </div>
  </LiveMarketTableCell>
)
