export const InvestmentTimelines = ["<1 week", "1-2 weeks", "2-4 weeks", "4+ weeks"] as const
export type InvestmentTimeline = (typeof InvestmentTimelines)[number]

interface ContactInvestmentPreferences {
  openToSPVs: boolean | null
  maxManagementFee: number | null
  openToDoubleLayerSPVs: boolean | null
  openToForwards: boolean | null
  maxCarry: number | null
  requiresDiligence: boolean | null
  paysBrokerFees: boolean | null
  typicalInvestmentTimeline: InvestmentTimeline | null
  cashOnHand: boolean | null
}

export const defaultContactInvestmentPreferences: ContactInvestmentPreferences = {
  openToSPVs: null,
  openToDoubleLayerSPVs: null,
  openToForwards: null,
  maxManagementFee: null,
  maxCarry: null,
  requiresDiligence: null,
  paysBrokerFees: null,
  typicalInvestmentTimeline: null,
  cashOnHand: null,
}

export default ContactInvestmentPreferences
