import { nullableNumToMaybe } from "../../../containers/Maybe"

export const plus = (i: number, j: number) => i + j
export const minus = (i: number, j: number) => i - j
export const times = (i: number, j: number) => i * j
export const quotient = (i: number, j: number) => i / j
export const negate = (i: number) => -i

export const nanToUndefined = (val: number) => (Number.isNaN(val) ? undefined : val)

export const safeMathOp =
  (mathOp: (a: number, b: number) => number) =>
  (n1: number | undefined | null, n2: number | undefined | null) =>
    nullableNumToMaybe(n1)
      .alongside(nullableNumToMaybe(n2))
      .bind((args) => nullableNumToMaybe(mathOp(...args)))
      .toNullable()

// TODO: tail-call optimization
export const gcd = (x: number, y: number): number => (y === 0 ? Math.abs(x) : gcd(y, x % y))
export const lcm = (x: number, y: number): number => (x * y) / gcd(x, y)

export const between = (a: number, lowerBound: number, upperBound: number) =>
  a <= upperBound && a >= lowerBound

export const absDeviationFromBase = (a: number, base: number): number =>
  (Math.abs(a - base) / base) * 100

export const linearInterpolaion = (
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  point: number
): number => {
  const minXY: [number, number] = x1 <= x2 ? [x1, y1] : [x2, y2]
  const maxXY: [number, number] = x1 !== minXY[0] ? [x1, y1] : [x2, y2]
  const boundedWeight = Math.min(1, Math.max(0, (point - minXY[0]) / (maxXY[0] - minXY[0])))
  return minXY[1] * (1 - boundedWeight) + maxXY[1] * boundedWeight
}
