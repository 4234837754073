import Spinner from "@components/icons/Spinner"
import { PlusIcon } from "@stories/icons/PlusIcon"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { isLoading } from "common/utils/Loading"
import { assertUnreachable } from "common/utils/fp/Function"
import * as React from "react"
import { FC } from "react"
import { useUpsertWithReset } from "src/utils/hooks/state/useUpsertWithReset"
import { CustomModalProps, Modal } from "../../../components/layout/Modal"
import { Button } from "../../../stories/components/Button/Button"
import { CreateContactForm } from "../Contacts/CreateContactButton/CreateContactForm"
import {
  defaultBrokerContact,
  defaultBrokerageFirmContact,
  defaultFirmContact,
  defaultIndividualContact,
} from "../Contacts/CreateContactButton/createContactHelpers"
import { useCRMContacts } from "../Providers/CRMContactsProvider"
import ContactSelectSearch from "./ContactSelectSearch/ContactSelectSearch"
import { CompanyFilterSearchProp } from "./ContactSelectSearch/shared"
import { SelectContactButtonProps } from "./SelectContactButton"
import { isBrokerage } from "common/model/DealCRM/DealCRMFirmContact"

type AddContactModalProps = SelectContactButtonProps &
  CustomModalProps & {
    hideIndividuals?: boolean
    hideFirms?: boolean
    initialCompanyFilter?: CompanyFilterSearchProp
    contactFilter?: (contact: DealCRMContact) => boolean
    sourceContactTag: DealCRMContact["tag"] | "brokerage" | null
    sourceContact?: DealCRMContact
  }

export const SelectContactModal: FC<AddContactModalProps> = ({
  onContactCreated,
  onContactSelected,
  hideIndividuals,
  hideFirms,
  contactFilter,
  handleClose,
  initialCompanyFilter,
  sourceContactTag,
  sourceContact,
}) => {
  const allContacts = useCRMContacts().contacts
  const [mode, setMode] = React.useState<"search" | "create">("search")

  const buildEmptyContact = () => {
    if (!sourceContactTag) return undefined

    switch (sourceContactTag) {
      case "firm":
        return defaultIndividualContact({
          classification: "firm_contact_only",
          creationSource: "form",
          creationSourceContact: sourceContact,
        })
      case "individual":
        return defaultFirmContact({ creationSource: "form" })
      case "broker":
        return defaultBrokerageFirmContact({ creationSource: "form" })
      case "brokerage":
        return defaultBrokerContact({ creationSource: "form" })
      default:
        assertUnreachable(sourceContactTag)
        return undefined
    }
  }

  const [selectedContact, setSelectedContact] = useUpsertWithReset<DealCRMContact | undefined>(
    buildEmptyContact()
  )

  const getPageTitle = () => {
    const action = mode === "create" ? "Add" : "Select"
    if (!sourceContactTag) return `${action} a Contact`
    switch (sourceContactTag) {
      case "firm":
        return `${action} a Contact`
      case "individual":
        return `${action} a Firm`
      case "broker":
        return `${action} a Brokerage`
      case "brokerage":
        return `${action} a Broker`
      default:
        assertUnreachable(sourceContactTag)
        return undefined
    }
  }

  const addNewContactButtonText = () => {
    if (!selectedContact?.tag) return "Add New Contact"
    switch (selectedContact.tag) {
      case "firm":
        return "Add New Fund/Org"
      case "individual":
        return "Add New Contact"
      case "broker":
        return "Add New Broker"
      default:
        assertUnreachable(selectedContact)
        return undefined
    }
  }

  const pageTitle = getPageTitle()

  const getContactSearchType = () => {
    if (!sourceContactTag) return undefined
    switch (sourceContactTag) {
      case "firm":
        return "individual"
      case "individual":
        return "firm"
      case "broker":
        return "firm"
      case "brokerage":
        return "broker"
      default:
        assertUnreachable(sourceContactTag)
        return undefined
    }
  }

  const contactSearchType = getContactSearchType()

  const filteredContacts = allContacts.filter((contact) => {
    // TODO - filter out contacts that already have an assigned firm or affiliated brokerage
    if (sourceContact && isBrokerage(sourceContact)) {
      return contact.tag === "broker"
    }
    return true
  })

  const body = (
    <div>
      {isLoading(allContacts) && <Spinner size="md" />}

      {!isLoading(allContacts) && mode === "search" && (
        <div className="flex flex-col gap-4">
          <ContactSelectSearch
            allContacts={filteredContacts || []}
            onContactSelected={onContactSelected}
            hideIndividuals={hideIndividuals}
            hideFirms={hideFirms}
            initialCompanyFilter={initialCompanyFilter}
            additionalFilter={contactFilter}
            contactSearchType={contactSearchType}
          />
          <Button
            leftIcon={<PlusIcon />}
            label={addNewContactButtonText()}
            isFullWidth
            variant="hollow-link"
            onClick={() => {
              setMode("create")
            }}
          />
        </div>
      )}

      {mode === "create" ? (
        <CreateContactForm
          afterCreate={(c) => (onContactCreated ? onContactCreated(c) : onContactSelected(c))}
          onCancel={handleClose}
          newContactValues={selectedContact}
          setNewContactValues={setSelectedContact}
          sourceContact={sourceContact}
        />
      ) : null}
    </div>
  )

  return <Modal open maskClosable handleClose={handleClose} subtitle={pageTitle} body={body} />
}
