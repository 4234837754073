import { TrustedBrokerInviteManagementPage } from "./TrustedBrokerInviteManagementPage"
import { TrustedBrokerNetworkPage } from "./TrustedBrokerPage"
import { Typography, Weight } from "@stories/components/Typography/Typography"

export const TrustedBrokerFullSettingsPage = () => (
  <div className="flex flex-col gap-4 mt-4">
    <div className="flex flex-col gap-4 shadow-md bg-neutral-white border-neutral-400 p-8 rounded-md w-full">
      <TrustedBrokerInviteManagementPage />
    </div>
    <div className="flex flex-col gap-4 shadow-md bg-neutral-white border-neutral-400 p-8 rounded-md w-full">
      <Typography text="Trusted Broker Network" weight={Weight.Semibold} />
      <TrustedBrokerNetworkPage />
    </div>
  </div>
)
