/** Some *extremely* bare-bones validation functions for quickly patching existing unsafe code. Not recommended for use in new development. */
import _ from "lodash"
import { parse, isValid } from "date-fns"
import {
  AmericanDateString,
  DateISOString,
  DateISOStringWithTime,
} from "../../model/postgres/PostgresCommon"

// as usual this is only correct when K is a literal, but we can't enforce that constraint
export const hasKey = <K extends string>(k: K, x: object): x is { [key in K]: unknown } => k in x
export const hasStringEntry = <K extends string>(k: K, x: object): x is { [key in K]: string } =>
  hasKey(k, x) && _.isString(x[k])
export const hasNumberEntry = <K extends string>(k: K, x: object): x is { [key in K]: number } =>
  hasKey(k, x) && _.isNumber(x[k])

// to protect against lodash getting creative with variadic functions again
export const isBoolean = (x: unknown): x is boolean => _.isBoolean(x)
export const isString = (x: unknown): x is string => _.isString(x)
export const isISODate = (x: unknown): x is DateISOString =>
  _.isString(x) &&
  (x.match(/\d{4}-[01]\d-[0-3]\d/) || []).length > 0 &&
  isValid(parse((x.match(/\d{4}-[01]\d-[0-3]\d/) ?? [""])[0], "yyyy-MM-dd", new Date()))
export const isAmericanFormatDate = (x: unknown): x is AmericanDateString =>
  _.isString(x) &&
  (x.match(/[01]\d\/[0-3]\d\/\d{4}/) || []).length > 0 &&
  isValid(parse((x.match(/[01]\d\/[0-3]\d\/\d{4}/) ?? [""])[0], "MM/dd/yyyy", new Date()))
// TODO
export const isISODateWithTime = (x: unknown): x is DateISOStringWithTime =>
  _.isString(x) &&
  (x.match(/\d{4}-[01]\d-[0-3]\d/) || []).length > 0 &&
  isValid(parse((x.match(/\d{4}-[01]\d-[0-3]\d/) ?? [""])[0], "yyyy-MM-dd", new Date()))

export const isNumber = (x: unknown): x is number => _.isNumber(x)
export const isRealNumber = (x: unknown): x is number => Number.isFinite(x)
export const isInteger = (x: unknown): x is number => Number.isInteger(x)
export const isNatural = (x: unknown): x is number => isInteger(x) && x >= 0

export const isUndefined = (x: unknown): x is undefined => x === undefined
export const isNull = (x: unknown): x is null => x === null

// tried to write `negatePredicate`, but ts started letting me get away with things that were obviously unsound
export const isNotNull = <T>(x: T): x is Exclude<T, null> => x !== null
export const isNotUndefined = <T>(x: T): x is Exclude<T, undefined> => x !== undefined
export const isNonnullable = <T>(x: T): x is Exclude<T, undefined | null> =>
  x !== null && x !== undefined
export const isObject = (x: unknown): x is { [key in string]?: unknown } => _.isObject(x)
export const isPlainObject = (x: unknown): x is { [key in string]?: unknown } => _.isPlainObject(x)

// typescript converts back to boolean by default
export const predicateOr =
  <A extends X & Y, B extends X & Y, X = unknown, Y = unknown>(
    f: (x: X) => x is A,
    g: (y: Y) => y is B
  ) =>
  (z: X & Y): z is A | B =>
    f(z) || g(z)

/** This should *only* be used at program boundaries, and even then only as a temporary patch.  */
export const crashUnless = <T, S = unknown>(
  check: (x: S) => x is S & T,
  x: S,
  propertyName?: string
): T => {
  if (check(x)) {
    return x
  }
  const errorMessage = `${propertyName || "Value"} '${String(
    x
  )}' did not satisfy required check '${String(check)}'`

  if (process.env.NODE_ENV === "test") {
    console.warn(errorMessage)
    return undefined as T
  } else {
    throw new Error(
      `${propertyName || "Value"} '${String(x)}' did not satisfy required check '${String(check)}'`
    )
  }
}
