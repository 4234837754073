import { useFeatureFlag } from "src/providers/featureFlags/useFeatureFlags"
import { useMemo } from "react"
import { deprecated_createOrderQuantityTermsFromForm } from "common/model/Order/OrderForm/Constructors"
import { deprecatedIsLoaded, Loading } from "common/utils/Loading"
import { Company } from "common/model/Company"
import { runQuotaComplianceChecks } from "common/model/Order/QuotaCompliance/runQuotaComplianceChecks"
import { useCurrentAccount } from "src/queries/currentUser/useCurrentAccount"
import { OrderFormState } from "common/model/Order/OrderForm/State"
import { validSizeAndPrice } from "common/model/Order/OrderForm/Validation"

export const useOrderQuotaCompliance = (formOrder: OrderFormState, company: Loading<Company>) => {
  const isOrderSystemLive = useFeatureFlag("is_order_quota_system_live")
  const account = useCurrentAccount()
  const orderQuotaComplianceViolations = useMemo(() => {
    if (
      formOrder.direction &&
      validSizeAndPrice(formOrder) &&
      deprecatedIsLoaded(company) &&
      deprecatedIsLoaded(account) &&
      account.isQuotaSystemEnabled &&
      isOrderSystemLive
    ) {
      const orderQuantityTerms = deprecated_createOrderQuantityTermsFromForm(formOrder, 1)
      return runQuotaComplianceChecks({
        direction: formOrder.direction,
        orderQuantity: orderQuantityTerms[1],
        company,
        account,
      })
    }
    return undefined
  }, [account, company, formOrder, isOrderSystemLive])
  return { orderQuotaComplianceViolations }
}
