import { Account, isTrialCustomer } from "common/model/Account"
import { AccountEventCountsRollup } from "common/model/Analytics/EventCounterRollup"
import * as React from "react"
import AccountsModule from "./AccountsModule"

interface ITrialAccountsModuleProps {
  accounts: Account[]
  weeklyEventCounts: AccountEventCountsRollup[]
  monthlyEventCounts: AccountEventCountsRollup[]
}

const TrialAccountsModule: React.FunctionComponent<ITrialAccountsModuleProps> = ({
  accounts,
  weeklyEventCounts,
  monthlyEventCounts,
}) => {
  const trialAccounts = accounts.filter(isTrialCustomer)
  const isActiveTrialAccount = (account: Account) =>
    account.onboardingStatus.data?.status === "trial"

  const isExpiredTrialAccount = (account: Account) =>
    account.onboardingStatus.data?.status === "trial-expired"

  return (
    <AccountsModule
      accounts={trialAccounts}
      weeklyEventCounts={weeklyEventCounts}
      monthlyEventCounts={monthlyEventCounts}
      title="Trial Accounts"
      renderClientType
      accountGroups={[
        {
          name: "Active Trial Accounts",
          filter: isActiveTrialAccount,
        },
        {
          name: "Expired Trial Accounts",
          filter: isExpiredTrialAccount,
        },
      ]}
    />
  )
}

export default TrialAccountsModule
