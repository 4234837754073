import { User } from "common/model/User"
import { UserEventType } from "common/model/UserEvent"
import Firebase9 from "../../firebase/Firebase9"
import { FirestoreEventCounter } from "./FirestoreEventCounter"

const MIN_TIME_BETWEEN_EVENTS_IN_MS = 500
export class EventCounterService {
  private static instance: EventCounterService | null = null

  private userEventCounter: FirestoreEventCounter | null = null

  private accountEventCounter: FirestoreEventCounter | null = null

  private constructor(firebase: Firebase9, user: User) {
    this.userEventCounter = new FirestoreEventCounter(firebase, user, "user")
    this.accountEventCounter = new FirestoreEventCounter(firebase, user, "account")
  }

  private lastEventTimesInMs: Partial<Record<UserEventType, number>> = {}

  static getInstance(firebase: Firebase9, user: User) {
    if (!this.instance) {
      this.instance = new EventCounterService(firebase, user)
    }
    return this.instance
  }

  incrementEventCount(event: UserEventType) {
    const shouldIncrementEventCount = this.shouldIncrementEventCount(event)
    this.lastEventTimesInMs[event] = new Date().valueOf()
    if (!shouldIncrementEventCount) {
      return Promise.resolve()
    }
    return Promise.all([
      this.userEventCounter?.incrementEventCount(event),
      this.accountEventCounter?.incrementEventCount(event),
    ])
  }

  /**
   * Prevents duplicated events from being logged in cases of re-renders, etc
   */
  private shouldIncrementEventCount(event: UserEventType) {
    const now = new Date()
    const lastEventTime = this.lastEventTimesInMs[event]?.valueOf()
    if (lastEventTime && now.valueOf() - lastEventTime < MIN_TIME_BETWEEN_EVENTS_IN_MS) {
      return false
    }
    return true
  }
}
