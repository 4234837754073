import { Func } from "common/utils/fp/Function"

export type RawTableFilter<Filtered, State, Update> = readonly [
  (s: State) => (t: Filtered) => boolean,
  ({
    value,
    onChange,
  }: {
    value: State | null
    onChange: (s: Update | null) => void
  }) => JSX.Element
]

export class TableFilter<Filtered, State, Update> {
  value: RawTableFilter<Filtered, State, Update>

  constructor(value: RawTableFilter<Filtered, State, Update>) {
    this.value = value
  }

  contramapFiltered = <T2>(f: Func<T2, Filtered>): TableFilter<T2, State, Update> =>
    new TableFilter<T2, State, Update>([(s) => (t2) => this.value[0](s)(f(t2)), this.value[1]])

  contramapState = <S2>(f: Func<S2, State>): TableFilter<Filtered, S2, Update> =>
    new TableFilter<Filtered, S2, Update>([
      (s2) => (t) => this.value[0](f(s2))(t),
      ({ value, onChange }) => this.value[1]({ value: value !== null ? f(value) : null, onChange }),
    ])

  map = <U2>(f: Func<Update, U2>): TableFilter<Filtered, State, U2> =>
    new TableFilter<Filtered, State, U2>([
      this.value[0],
      ({ value, onChange }) =>
        this.value[1]({ value, onChange: (u) => onChange(u !== null ? f(u) : null) }),
    ])
}
