import { Left, Right } from "common/containers/Either"
import { AccountClientType } from "common/model/Account/AccountClientType"
import { Navigate } from "react-router-dom"
import { AuthCondition } from "../../../Routes/auth/AuthCondition"
import { hasClientType } from "./ProductAreaConditions"

export const clientTypeCheck =
  (clientType: AccountClientType): AuthCondition =>
  (ctx) => {
    if (hasClientType(ctx, clientType)) {
      return Right(ctx)
    }
    return Left(() => <Navigate to="/" />)
  }
