/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"
import { NavItem } from "../../../../Routes/NavItems"
import NavMenuItem from "../NavMenuItem/NavMenuItem"
import UpChevronIcon from "@stories/icons/UpChevronIcon"
import DownChevronIcon from "@stories/icons/DownChevron"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import NewIcon from "@stories/components/Badges/NewIcon/NewIcon"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import { useNavigation } from "../Navigation/NavigationContext"
import { classNames } from "src/utils/classNames"
import { isLoaded } from "common/utils/Loading"
import { isEmployeeAccount } from "common/model/Auth/Permissions"

interface NavSectionProps {
  /**
   * What nav items are included in the nav section?
   */
  items: NavItem[]
  /**
   * What is the title of the nav section?
   */
  title: string
  /**
   * Is the section collapsed to render only the menu item icons?
   */
  iconsOnly: boolean
  /**
   * What happens when you click an item?
   */
  onItemClick: () => void

  /**
   * Is the section new (will display a 'New' badge)
   */
  isNew?: boolean
}

const NavSection: React.FC<React.PropsWithChildren<NavSectionProps>> = ({
  title,
  items,
  iconsOnly,
  onItemClick,
  isNew,
}) => {
  const user = useCurrentUser()
  const { sectionState } = useNavigation()
  const sectionType = items[0]?.section

  const {
    open,
    active: isActive,
    toggle,
  } = sectionState[sectionType] ?? { open: true, active: false, toggle: () => null }

  const isControls = title === "Controls"
  const isExpanded = open || isControls || (isLoaded(user) && isEmployeeAccount(user.user.account))

  if (items.length === 0) {
    return null
  }

  return (
    <div
      className={classNames(
        !isControls && !iconsOnly && isActive ? "bg-neutral-900" : "bg-neutral-1000",
        !isControls && "p-2",
        "rounded-md"
      )}
    >
      {title !== "Controls" && (
        <div
          className={classNames(
            "flex justify-between items-center rounded cursor-pointer",
            isExpanded && "mb-2",
            iconsOnly && "hidden"
          )}
          onClick={toggle}
        >
          <div className="flex items-center gap-2">
            <Typography
              size={Size.XSmall}
              weight={Weight.Bold}
              color={Color.Gray}
              text={title && title.toLocaleUpperCase()}
            />
            {isNew && <NewIcon />}
          </div>
          {isExpanded ? (
            <UpChevronIcon color={Color.OffWhite} />
          ) : (
            <DownChevronIcon color={Color.OffWhite} />
          )}
        </div>
      )}

      <div className="gap-y-1 flex flex-col items-center">
        {isExpanded
          ? items.map((item, idx) => {
              const stringName = item.name ?? item.getName(user)
              return (
                <NavMenuItem
                  key={idx}
                  isCollapsed={iconsOnly}
                  isNew={!!item.isNew}
                  icon={item.icon}
                  text={stringName}
                  badge={item.badge}
                  // not actually unbound
                  // eslint-disable-next-line @typescript-eslint/unbound-method
                  route={item.route}
                  onItemClick={onItemClick}
                  section={item.section}
                />
              )
            })
          : null}
      </div>
    </div>
  )
}

export default NavSection
