import { Tooltip } from "@stories/components/Antd"
import Typography, { Color, Size } from "@stories/components/Typography/Typography"
import { BrokerIcon } from "@stories/icons/BrokerIcon"
import { FirmIcon } from "@stories/icons/FirmIcon"
import { IconSize } from "@stories/icons/IconWrapper"
import { UserIcon } from "@stories/icons/UserIcon"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"

const CRMContactIcon = ({
  contact,
  size = "medium",
}: {
  contact?: DealCRMContact
  size?: IconSize
}) => {
  if (!contact) {
    return null
  }

  switch (contact.tag) {
    case "individual":
      return (
        <Tooltip
          title={
            <Typography
              text="Individual"
              size={Size.Small}
              shouldWrap={false}
              color={Color.White}
            />
          }
        >
          <>
            <UserIcon size={size} />
          </>
        </Tooltip>
      )
    case "firm":
      if (contact.isBrokerage) {
        return (
          <Tooltip
            title={
              <Typography text="Broker" size={Size.Small} shouldWrap={false} color={Color.White} />
            }
          >
            <>
              <BrokerIcon size={size} />
            </>
          </Tooltip>
        )
      }

      return (
        <Tooltip
          title={
            <Typography text="Firm" size={Size.Small} shouldWrap={false} color={Color.White} />
          }
        >
          <>
            <FirmIcon size={size} />
          </>
        </Tooltip>
      )
    case "broker":
      return (
        <Tooltip
          title={
            <Typography text="Broker" size={Size.Small} shouldWrap={false} color={Color.White} />
          }
        >
          <>
            <BrokerIcon size={size} />
          </>
        </Tooltip>
      )
    default:
      return null
  }
}

export default CRMContactIcon
