import moment from "moment-timezone"
import numeral from "numeral"
import { identity } from "lodash"
import { arraysEqual } from "common/utils/data/Array/ArrayUtils"
import { isArray } from "common/utils/data/Array/Nonempty"
import { addTimeDelta, calculateRemainingDays, dateDiff } from "common/utils/dateUtils"
import { nullableToMaybe } from "common/containers/Maybe"
import {
  DEFAULT_OPTION_TERM_LENGTH_IN_MONTHS,
  DeprecatedOrder,
  DeprecatedOrderStructure,
} from "common/model/DeprecatedOrder"
import {
  volumeDisplay,
  formatSharePrice,
  formatSharePrice as formatSharePriceUtil,
} from "../components/displays/numeric/Currency"
import { isOptionFromStructures, isSwapFromStructures, structuresString } from "./Order"

const numberInMillions = (n: number) => Math.ceil(n / 1e6)

/** @deprecated  this should no longer be used. Use common/model/order/Order instead **/
export class WrappedDeprecatedOrder {
  public data: DeprecatedOrder

  private DEFAULT_TERM_LENGTH_IN_MONTHS = 24

  constructor(order: DeprecatedOrder) {
    this.data = order
  }

  opportunityName() {
    if (this.data.structure.length === 1) {
      return `${this.issuerName} ${structuresString(this.data.structure, this.data.direction)}`
    }
    return `${this.issuerName} ${this.direction()} Opportunity`
  }

  inverseDirection() {
    if (this.data.direction === "Long") return "Short"
    return "Long"
  }

  bidOrOffer() {
    if (arraysEqual<DeprecatedOrderStructure>(this.data.structure, ["Put"])) {
      return this.data.direction === "Long" ? "Offer" : "Bid"
    }
    return this.data.direction === "Long" ? "Bid" : "Offer"
  }

  bidOrOfferName() {
    if (this.data.structure.length === 1) {
      return `${this.issuerName} ${structuresString(this.data.structure, this.data.direction)}`
    }
    return `${this.issuerName} ${this.direction()} ${this.bidOrOffer()} (multiple structures)`
  }

  get issuerName(): string {
    if (isArray(this.data.issuerName) && this.data.issuerName.length > 0) {
      return this.data.issuerName[0]
    }
    return this.data.issuerName
  }

  direction() {
    return this.data.direction
  }

  get notionalSizeString() {
    const lowSize = this.data.notionalValueLow
    const highSize = this.data.notionalValueHigh

    if (!highSize && !lowSize) return ""
    if (!lowSize && highSize) {
      return `$${numberInMillions(highSize)}m`
    }
    if (!highSize && lowSize) {
      return `$${numberInMillions(lowSize)}m`
    }
    if (lowSize && lowSize === highSize) {
      return `$${numberInMillions(lowSize)}m`
    }
    return `$${numberInMillions(lowSize || 0)}m - $${numberInMillions(highSize || 0)}m`
  }

  get spotPriceString() {
    return this.formatSharePrice(this.data.refPPS)
  }

  spotValuationString() {
    return this.formatValuation(this.data.refValuation)
  }

  spotPriceAndValuationString() {
    if (this.data.refPPS && this.data.refValuation) {
      return `${this.spotPriceString} (${this.spotValuationString()} valuation)`
    }
    if (this.data.refPPS) {
      return this.spotPriceString
    }
    return this.spotValuationString()
  }

  orderPriceAndValuationString() {
    if (this.data.PPS && this.data.valuation) {
      return `${this.formatSharePrice(this.data.PPS)} (${this.formatValuation(
        this.data.valuation
      )} valuation)`
    }
    if (this.data.PPS) {
      return `${this.formatSharePrice(this.data.PPS)}`
    }
    return `${this.formatValuation(this.data.valuation)}`
  }

  orderPriceString() {
    return `${this.formatSharePrice(this.data.PPS)}`
  }

  orderValuationString() {
    return `${this.formatValuation(this.data.valuation)}`
  }

  strikePriceString() {
    return this.formatSharePrice(this.data.strikePPS)
  }

  strikeValuationString() {
    return this.formatValuation(this.data.strikeValuation)
  }

  strikeValuationPlusPremiumString(): string {
    let value: number | undefined = undefined

    if (this.data && this.data.strikePPS && this.premiumPerShare) {
      const strikePriceWithPremium = this.data.strikePPS + this.premiumPerShare

      const numShares =
        this.data.refValuation && this.data.refPPS ? this.data.refValuation / this.data.refPPS : 0

      value = numShares > 0 ? strikePriceWithPremium * numShares : this.data.strikeValuation
    }

    return this.formatValuation(value)
  }

  strikePriceAndValuationString() {
    if (this.data.strikePPS && this.data.strikeValuation) {
      return `${this.strikePriceString()} (${this.strikeValuationString()} valuation)`
    }
    if (this.data.strikePPS) {
      return this.strikePriceString()
    }
    return this.strikeValuationString()
  }

  get isOption(): boolean {
    return isOptionFromStructures(this.data.structure)
  }

  get isSwap(): boolean {
    return isSwapFromStructures(this.data.structure)
  }

  get premiumPerShare(): number | undefined {
    if (this.data.refPPS && this.data.premPercent) {
      return this.data.refPPS * this.data.premPercent
    }
    return undefined
  }

  get premiumPerShareString(): string {
    return nullableToMaybe(this.premiumPerShare)
      .map((price) => `${formatSharePrice(price)} / share`)
      .withDefault("")
  }

  get totalPremiumString(): string | undefined {
    const { premPercent } = this.data
    if (this.notionalSizeString && premPercent) {
      return [this.data.notionalValueLow, this.data.notionalValueHigh]
        .map((val) =>
          nullableToMaybe(val)
            .map((size) => size * premPercent)
            .map(volumeDisplay.run)
            .toNullable()
        )
        .filter(identity)
        .join(" - ")
    }
    return undefined
  }

  structure() {
    if (this.data.structure.length > 1) return "Multiple"
    if (this.data.structure[0] === "Call") return "Call Option"
    if (this.data.structure[0] === "Put") return "Put Option"
    return this.data.structure[0]
  }

  get contractExpirationInMonths(): number {
    return this.data.contractExpirationInMonths || DEFAULT_OPTION_TERM_LENGTH_IN_MONTHS
  }

  // When an order expires (is killed if not refreshed)
  // See `contractExpirationInMonths` to get an option duration
  expirationDateString() {
    if (!this.data.expirationDate) return undefined
    return moment(this.data.expirationDate).format("MMM DD")
  }

  /** Days and notional */
  daysLeftOnMarket() {
    if (!this.data.expirationDate) return undefined
    const days = calculateRemainingDays(this.data.expirationDate)
    if (days !== undefined && days !== null) return days + 1 // add an additional day for today
    return undefined
  }

  get getContractExpiryDate() {
    if (this.data.expirationDate) {
      return moment(this.data.expirationDate)
    }

    if (this.data.contractExpirationInMonths) {
      return moment(addTimeDelta(new Date(), this.data.contractExpirationInMonths, "months"))
    }
    return moment(addTimeDelta(new Date(), this.DEFAULT_TERM_LENGTH_IN_MONTHS, "months"))
  }

  get getMonthsDuration() {
    const expiryDate = this.getContractExpiryDate
    return dateDiff(expiryDate.valueOf(), moment().valueOf(), "months")
  }

  get getDaysDuration() {
    const expiryDate = this.getContractExpiryDate
    return dateDiff(expiryDate.valueOf(), moment().valueOf(), "days")
  }

  premPercentOfSpotString() {
    if (!this.data.premPercent) return ""
    return numeral(this.data.premPercent).format("0%")
  }

  isFullDataOption(): boolean {
    return (
      !!this.issuerName &&
      !!this.data.refPPS &&
      !!this.data.strikePPS &&
      this.data.liveOrDead === "Live"
    )
  }

  valuationMultiplied(multiplier: number): string | boolean {
    return (
      !!this.data.refValuation && this.formatValuation(this.data.refValuation * multiplier || 1)
    )
  }

  // eslint-disable-next-line class-methods-use-this
  private formatSharePrice(price: number | undefined) {
    if (!price) return ""

    return `${formatSharePriceUtil(price)} / share`
  }

  // eslint-disable-next-line class-methods-use-this
  private formatValuation(valuation: number | undefined): string {
    if (!valuation) return ""
    const isSmallCompany = valuation && valuation < 25
    if (!isSmallCompany) return `${numeral(valuation).format("$0")}B`

    const formattedWith2Decimals = numeral(valuation).format("$0.00")
    const lastThreeChars = formattedWith2Decimals.slice(
      Math.max(formattedWith2Decimals.length - 3, 1)
    )
    let smallCompanyFormat = "$0.0"
    // these are quite special cases, and it makes sense to show them as-is:
    if ([".25", ".75"].includes(lastThreeChars)) smallCompanyFormat = "$0.00"
    return `${numeral(valuation).format(smallCompanyFormat)}B`
  }
}
