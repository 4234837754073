import Typography, { Color } from "@stories/components/Typography/Typography"
import pluralize from "pluralize"

export const RemainingUnlockText = ({
  remainingUnlockCount,
  accessResetsMonthly,
  color,
}: {
  remainingUnlockCount: number
  accessResetsMonthly: boolean
  color?: Color
}) => (
  <Typography
    color={color}
    text={
      <span>
        You have <span className="font-semibold">{remainingUnlockCount}</span> remaining company{" "}
        {pluralize("unlock", remainingUnlockCount)}
        {accessResetsMonthly ? " this month." : "."}
      </span>
    }
  />
)
