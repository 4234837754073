import CompanyLogoAndName from "@components/CompanyLogoAndName"
import Spinner from "@components/icons/Spinner"
import Callout from "@components/layout/Callout"
import { Modal } from "@components/layout/Modal"
import { Subtitle } from "@components/typography/Subtitle"
import { CurrentUser } from "@model/CurrentUser"
import { notification } from "@stories/components/Antd"
import { Button } from "@stories/components/Button/Button"
import Typography, { Size, Weight } from "@stories/components/Typography/Typography"
import { TrashIcon } from "@stories/icons/TrashIcon"
import { collections } from "common/firestore/Collections"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { DealCRMFirmContact } from "common/model/DealCRM/DealCRMFirmContact"
import { DealCRMNote } from "common/model/DealCRM/DealCRMNote"
import { UserFunctions } from "common/model/User"
import { isLoaded } from "common/utils/Loading"
import { titleCase } from "common/utils/StringUtils"
import { articulatedRelativeDateAndTimeFormat } from "common/utils/dateUtils"
import React, { useState } from "react"
import { useFirebaseWriter } from "src/firebase/Context"
import { FieldValue, FirebaseWriter } from "src/firebase/Firebase"
import {
  getContactDealParticipantDocs,
  getFirmsForIndividualContact,
  getIndividualsForFirmContact,
} from "src/firebase/crm"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import { handleConsoleError } from "src/utils/Tracking"
import { NoteDisplay } from "../Components/Notes/NoteDisplay"
import { ContactDealsTable } from "./ContactDetailsDrawer/ContactDeals/ContactDealsTable"
import { useContactNotes } from "./ContactDetailsDrawer/ContactNotes/ContactNotes"
import { DealCRMInterest } from "common/model/DealCRM/DealCRMInterest"
import { useContactBuySellInterest } from "./ContactDetailsDrawer/helpers"
import { deleteCRMNote } from "src/firebase/crmNotes"

interface DeleteContactModalProps {
  contact: DealCRMContact
  handleClose: () => void
}

const DeleteContactModal: React.FC<DeleteContactModalProps> = ({ contact, handleClose }) => {
  const user = useCurrentUser()
  const contactBuySellInterest = useContactBuySellInterest({ contact })

  if (!isLoaded(user) || !isLoaded(contactBuySellInterest)) return null

  return (
    <Modal
    subtitle="Delete Contact"
      className="min-w-120"
      handleClose={handleClose}
      closable
      body={
        <DeleteContactModalBody
          contact={contact}
          user={user}
          handleClose={handleClose}
          contactBuySellInterest={contactBuySellInterest}
        />
      }
      open
      destroyOnClose
    />
  )
}

const DeleteContactModalBody = ({
  contact,
  user,
  handleClose,
  contactBuySellInterest,
}: {
  contact: DealCRMContact
  user: CurrentUser
  handleClose: () => void
  contactBuySellInterest: DealCRMInterest[]
}) => {
  const [runningDelete, setRunningDelete] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const firebase = useFirebaseWriter()
  const notes = useContactNotes({ contact, user, contactBuySellInterest })

  const handleDelete = async () => {
    if (!isLoaded(user) || !isLoaded(notes)) return

    setRunningDelete(true)

    try {
      await deleteContact({ contact, user, firebase, contactNotes: notes })
      notification.info({ message: "Contact deleted", placement: "top", closeIcon: <></> })
      handleClose()
    } catch (e) {
      handleConsoleError(e)
      setError("An error occurred. Please contact Caplight for assistance")
    }
  }

  const labeledValue = (label: string, value: string) => (
    <div className="flex items-center gap-2">
      <Typography size={Size.Small} text={label} weight={Weight.Semibold} />
      <Typography size={Size.Small} text={value} />
    </div>
  )

  return (
    <div>
      <div className="flex flex-col gap-2 p-4 bg-neutral-200 border rounded mt-4">
        {labeledValue("Name", contact.name)}
        {labeledValue("Type", titleCase(contact.tag))}
        {labeledValue("Created", articulatedRelativeDateAndTimeFormat(contact.createdAt))}
        {contact.createdBy && labeledValue("Created by", UserFunctions.name(contact.createdBy))}
        <div className="flex items-center gap-2">
          <Typography size={Size.Small} text="Buy Interest " weight={Weight.Semibold} />
          <div className="flex gap-2 max-w-100 flex-wrap">
            {contact.contactBuyInterest.map((buyInterest, i) => (
              <CompanyLogoAndName key={i} size="xxs" company={buyInterest.company} />
            ))}
          </div>
          {contact.contactBuyInterest.length === 0 && <Typography size={Size.Small} text="None" />}
        </div>

        <div className="flex gap-2 items-center">
          <Typography size={Size.Small} text="Sell Interest " weight={Weight.Semibold} />

          <div className="flex gap-2 max-w-400 flex-wrap">
            {contact.contactSellInterest.map((sellInterest, i) => (
              <CompanyLogoAndName key={i} size="xxs" company={sellInterest.company} />
            ))}
          </div>
          {contact.contactSellInterest.length === 0 && <Typography size={Size.Small} text="None" />}
        </div>
        <div>
          <Typography size={Size.Small} text="Deals" weight={Weight.Semibold} />
          <div>
            <ContactDealsTable hideTitle contact={contact} noDealsMessage="None" />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <Typography size={Size.Small} text="Notes" weight={Weight.Semibold} />
          {isLoaded(notes) ? (
            notes.allContactNotes.length > 0 ? (
              <div className="p-2 border rounded">
                {notes.allContactNotes.map((note) => (
                  <NoteDisplay
                    key={note.id}
                    viewOnly
                    note={note}
                    showSourcePill={note.source.sourceId !== contact.id}
                    displayPage="contact"
                  />
                ))}
              </div>
            ) : (
              <span className="ml-2">
                <Typography size={Size.Small} text="None" />
              </span>
            )
          ) : (
            <Spinner size="xs" />
          )}
        </div>
      </div>
      <div className="mt-4 flex flex-col gap-4">
        <Subtitle subtitle="Are you sure you want to delete this contact?" />
        {contact.tag === "individual" && contact.firm ? (
          <Typography
            size={Size.Small}
            text={`${contact.firm.name} (the associated firm) will not be deleted.`}
          />
        ) : null}
        {contact.tag === "firm" && contact.contacts.length ? (
          <Typography
            size={Size.Small}
            text={
              <span>
                Associated contacts {contact.contacts.map((ind) => ind.name).join(", ")} will{" "}
                <b>not</b> be deleted.
              </span>
            }
          />
        ) : null}
        <Typography
          size={Size.Small}
          weight={Weight.Semibold}
          text="This action cannot be undone."
        />
        {error ? <Callout color="red" body={error} /> : null}
        <div className="flex gap-2">
          <Button
            isDisabled={runningDelete || !!error}
            label={`Delete ${contact.name}`}
            onClick={handleDelete}
            variant="primary"
            leftIcon={<TrashIcon />}
          />
          <Button
            isDisabled={runningDelete}
            label="Cancel"
            onClick={handleClose}
            variant="hollow"
          />
        </div>
      </div>
    </div>
  )
}

const deleteContact = async (params: {
  contact: DealCRMContact
  user: CurrentUser
  firebase: FirebaseWriter
  contactNotes: { allContactNotes: DealCRMNote[] }
}) => {
  const { contact, user, firebase, contactNotes } = params
  const notes = contactNotes
  // Deal participant docs
  await getContactDealParticipantDocs({
    db: firebase,
    accountId: user.user.account.id,
    contact,
  })
    .get()
    .then((result) => Promise.all(result.docs.map((doc) => doc.ref.delete())))

  // Direct Contact Notes
  await Promise.all(notes.allContactNotes.map((note) => deleteCRMNote({ db: firebase, note })))

  // Remove references to this contact on firm contacts (if this contact is an individual contact, no-op otherwise)
  const firmContactsReferencingContact = await getFirmsForIndividualContact({
    db: firebase,
    accountId: user.user.account.id,
    contactId: contact.id,
  })

  await Promise.all(
    firmContactsReferencingContact.docs.map((firm) =>
      firm.ref.update({
        contactIds: firm.data().contactIds.filter((id: string) => id !== contact.id),
        contacts: firm.data().contacts.filter((c) => c.id !== contact.id),
      } satisfies Pick<DealCRMFirmContact, "contacts" | "contactIds">)
    )
  )

  // Remove references to this contact on individual contacts (if this contact is a firm contact, no-op otherwise)
  const contactFirmDocs = await getIndividualsForFirmContact({
    db: firebase,
    accountId: user.user.account.id,
    contactId: contact.id,
  })
  await Promise.all(
    contactFirmDocs.docs.map((doc) =>
      doc.ref.update({
        firm: FieldValue.delete(),
      })
    )
  )

  // Delete contact
  await firebase.db.collection(collections.dealCRM.contacts).doc(contact.id).delete()
}

export default DeleteContactModal
