import { CompanyIdFields, LogoSize, SelectedCompany } from "common/model/Company"
import AlgoliaCompanySelection from "../../../../components/companies/select/AlgoliaCompanyAutocomplete"
import { Loading, isLoaded } from "common/utils/Loading"
import CompanyLogoAndName from "@components/CompanyLogoAndName"

type CompanyInputProps = {
  value: Loading<CompanyIdFields>
  onChange: (c: SelectedCompany | undefined) => void
  logoSize: LogoSize
  shouldMaskDataInDataDog?: boolean
}
const CompanyInput = ({ value, onChange, logoSize, shouldMaskDataInDataDog }: CompanyInputProps) =>
  isLoaded(value) ? (
    <CompanyLogoAndName
      company={value}
      size={logoSize}
      disableClick
      shouldMaskDataInDataDog={shouldMaskDataInDataDog}
    />
  ) : (
    <AlgoliaCompanySelection className="w-full" selected={undefined} handleSelect={onChange} />
  )

export default CompanyInput
