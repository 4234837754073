import { OrderOpportunityInboxItem } from "../../types"
import { useState } from "react"
import { useOrderOpportunityRules } from "../useOrderOpportunityRules"
import { deprecatedIsLoaded } from "common/utils/Loading"
import { Button } from "@stories/components/Button/Button"
import { ArchiveReason } from "common/model/Opportunity/fns/archiveOpportunity"
import { Checkbox, CheckboxWithLabel } from "@components/inputs/checkbox/Checkbox"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { notInterestedRules } from "./ArchiveReasonInboxFilterRuleMap"
import { Order as WrappedOrder } from "common/model/Order/Models/Wrapped"
import { formatAbbreviatedCurrency, formatPrice } from "common/utils/math/format"
import { InnerOrderOpportunityRuleDisplayCard } from "../OrderOpportunityRuleDisplayCard"
import { AnimatePresence, motion } from "framer-motion"
import { Account } from "common/model/Account"
import { ConstructedOpportunityInboxFilterRule } from "common/model/Order/OrderFilter/OpportunityInboxFilterRule"
import { Switch } from "@stories/components/Antd/Switch/Switch"
import { useAccountSnapshot } from "../../../../../providers/AccountSnapshot/useAccountSnapshot"

const checkBoxDisabled = (value: ArchiveReason, selectedValues: ArchiveReason[]): boolean =>
  value !== "direction" ? selectedValues.includes("direction") : false

const handleCheckboxChange = (
  value: ArchiveReason,
  previouslySelectedValues: ArchiveReason[]
): ArchiveReason[] => {
  if (value === "direction") {
    return previouslySelectedValues.includes("direction") ? [] : ["direction"]
  }
  return previouslySelectedValues.includes(value)
    ? previouslySelectedValues.filter((v) => v !== value)
    : previouslySelectedValues.concat(value)
}

const WhyNotInterested = ({
  order,
  selectedReasons,
  setSelectedReasons,
  setModalState,
}: {
  order: WrappedOrder
  selectedReasons: ArchiveReason[]
  setSelectedReasons: (reasons: ArchiveReason[]) => void
  setModalState: (v: "RulePreview") => void
}) => (
  <div className="flex flex-col gap-2">
    <Typography
      text={`Do you want to hide similar ${
        order.direction() === "buy" ? "bids" : "offers"
      } in the future?`}
      weight={Weight.Semibold}
      size={Size.Small}
      color={Color.Primary}
    />
    <div className="flex flex-col">
      {(
        [
          {
            value: "direction",
            label: `Hide all ${order.company().name} ${
              order.direction() === "buy" ? "Bids" : "Offers"
            }`,
          },
          ...order.impliedPricePerShare().match(
            (price) =>
              order.direction() === "buy"
                ? [
                    {
                      value: "price too low" as const,
                      label: `Hide ${order.company().name} ${
                        order.direction() === "buy" ? "Bids" : "Offers"
                      } below ${formatPrice(price)} pps`,
                    },
                  ]
                : [
                    {
                      value: "price too high" as const,
                      label: `Hide ${order.company().name} ${
                        order.direction() === "buy" ? "Bids" : "Offers"
                      } above ${formatPrice(price)} pps`,
                    },
                  ],
            () => []
          ),
          ...order.amountUSD().match(
            (amountInterval) => [
              {
                value: "size too high" as const,
                label: `Hide ${order.company().name} ${
                  order.direction() === "buy" ? "Bids" : "Offers"
                } larger than ${formatAbbreviatedCurrency(amountInterval.lowerBound)}`,
              },
              {
                value: "size too low" as const,
                label: `Hide ${order.company().name} ${
                  order.direction() === "buy" ? "Bids" : "Offers"
                } smaller than ${formatAbbreviatedCurrency(amountInterval.upperBound)}`,
              },
            ],
            () => []
          ),
          {
            value: "structure",
            label: `Hide ${order.company().name} ${
              order.direction() === "buy" ? "Bids" : "Offers"
            } with matching structure: ${order.structureDisplay()}`,
          },
        ] satisfies { value: ArchiveReason; label: string }[]
      ).map((c) => (
        <CheckboxWithLabel
          size="small"
          disabled={checkBoxDisabled(c.value, selectedReasons)}
          key={c.value}
          checked={selectedReasons.includes(c.value)}
          id={c.value}
          onChange={() => setSelectedReasons(handleCheckboxChange(c.value, selectedReasons))}
          title={c.label}
        />
      ))}
    </div>

    <div>
      <Button
        isFullWidth
        variant="primary"
        onClick={() => setModalState("RulePreview")}
        label="Continue"
        isDisabled={!selectedReasons.length}
      />
    </div>
  </div>
)

const RulePreview = ({
  order,
  selectedReasons,
  onMarkNotInterested,
  account,
}: {
  order: WrappedOrder
  selectedReasons: ArchiveReason[]
  onMarkNotInterested: (archiveReasons: ArchiveReason[]) => void
  account: Account
}) => {
  const [runRule, setRunRule] = useState(false)
  const [loading, setLoading] = useState(false)
  const [rulesFromSelectedReasons, setRulesFromSelectedReasons] = useState<
    {
      active: boolean
      rule: ConstructedOpportunityInboxFilterRule
      reason: ArchiveReason
    }[]
  >(
    selectedReasons.flatMap((reason) => {
      const rule = notInterestedRules[reason]({ account, order })
      return rule ? [{ rule, reason, active: true }] : []
    })
  )
  const { createOrderRule } = useOrderOpportunityRules()

  const handlePrimaryClick = async () => {
    setLoading(true)
    await Promise.all(
      rulesFromSelectedReasons.map((rule) =>
        rule.active ? createOrderRule(rule.rule, runRule) : Promise.resolve(null)
      )
    )
      .then(() => onMarkNotInterested(selectedReasons))
      .finally(() => setLoading(false))
  }
  return (
    <div className="flex flex-col gap-2">
      <Typography
        text="Confirm these rule filters are correct."
        weight={Weight.Semibold}
        size={Size.Small}
        color={Color.Primary}
      />
      {rulesFromSelectedReasons.map(({ rule, active, reason }) => (
        <div className="flex gap-1 items-start" key={reason}>
          <Checkbox
            id={reason}
            checked={active}
            onChange={(e) =>
              setRulesFromSelectedReasons(
                rulesFromSelectedReasons.map((r) =>
                  r.reason === reason ? { ...r, active: e.target.checked } : r
                )
              )
            }
            size="small"
          />
          <InnerOrderOpportunityRuleDisplayCard rule={rule} />
        </div>
      ))}
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-4">
          <Switch size="small" className="w-8" checked={!!runRule} onChange={setRunRule} />
          <Typography size={Size.Small} text="Run rule against matching opportunities" />
        </div>
        <Button
          isFullWidth
          variant="primary"
          onClick={handlePrimaryClick}
          label="Save Rules"
          isDisabled={!rulesFromSelectedReasons.filter(({ active }) => active).length}
          isLoading={loading}
        />
      </div>
    </div>
  )
}

export const NotInterestedModalBody = ({
  item,
  onMarkNotInterested,
}: {
  item: OrderOpportunityInboxItem
  onMarkNotInterested: (archiveReasons: ArchiveReason[]) => void
}) => {
  const { accountSnapshot: account } = useAccountSnapshot()
  const [selectedReasons, setSelectedReasons] = useState<ArchiveReason[]>([])
  const [modalState, setModalState] = useState<"WhyNotInterested" | "RulePreview">(
    "WhyNotInterested"
  )

  if (!deprecatedIsLoaded(account)) {
    return null
  }

  return (
    <div className="w-96">
      <div className="flex flex-col">
        <div className="flex flex-col items-center mb-2">
          <Typography text="Marked Not Interested" weight={Weight.Semibold} color={Color.Primary} />
        </div>
        {modalState === "WhyNotInterested" ? (
          <WhyNotInterested
            selectedReasons={selectedReasons}
            setSelectedReasons={setSelectedReasons}
            order={item.order}
            setModalState={setModalState}
          />
        ) : null}
        <AnimatePresence>
          <motion.div
            key={modalState}
            initial={{ x: 10, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
          >
            {modalState === "RulePreview" ? (
              <RulePreview
                selectedReasons={selectedReasons}
                order={item.order}
                onMarkNotInterested={onMarkNotInterested}
                account={account}
              />
            ) : null}
          </motion.div>
        </AnimatePresence>
        <Button
          variant="hollow"
          onClick={() => onMarkNotInterested(selectedReasons)}
          label="Skip"
        />
      </div>
    </div>
  )
}
