import Typography, { Size } from "@stories/components/Typography/Typography"
import { NumberField, NumberFieldProps } from "./NumberField"
import { isNil } from "lodash"

type PercentageFieldProps = Omit<NumberFieldProps, "formatter">

export const PercentageField: React.FC<PercentageFieldProps> = (props) => (
  <NumberField
    formatter={(v) => (isNil(v) ? "" : +(v * 100).toFixed(8)).toString()}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    min={props.min || 0}
    max={props.max || 1}
    onChange={(newValue) => {
      const parsed = isNil(newValue) ? null : newValue <= 1 ? newValue : newValue / 100
      if (props.value === parsed) {
        // No op if unchanged
      } else {
        props.onChange(parsed)
      }
    }}
    suffix={
      props.value ? (
        <div className="pr-6">
          <Typography text="%" size={Size.Small} />
        </div>
      ) : null
    }
    parser={(input) => (isNil(input) ? null : input / 100)}
  />
)
