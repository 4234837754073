import { Tooltip } from "@stories/components/Antd/Tooltip/Tooltip"
import UnreadIcon from "@stories/components/Badges/UnreadIcon/UnreadIcon"
import { defaultIfLoading } from "common/utils/Loading"
import { useAccountCompliance } from "src/providers/data/AccountComplianceProvider"
import FeatureWrapper from "src/providers/featureFlags/FeatureWrapper"

const OrdersRequiringAttentionIcon = ({ type }: { type?: "market-visible" | "darkpool" }) => {
  const { countRequiresAttention, ordersRequiringAttention, darkpoolOrdersRequiringAttention } =
    useAccountCompliance()
  const count =
    type === "darkpool"
      ? defaultIfLoading(darkpoolOrdersRequiringAttention, []).length
      : type === "market-visible"
      ? defaultIfLoading(ordersRequiringAttention, []).length
      : defaultIfLoading(countRequiresAttention, 0)

  if (count) {
    return (
      <FeatureWrapper flagName="is_orders_requiring_attention_live">
        <Tooltip title="Orders Require Attention">
          <div>
            <UnreadIcon count={count < 10 ? count : "9+"} variant="warning" />
          </div>
        </Tooltip>
      </FeatureWrapper>
    )
  }
  return null
}

export default OrdersRequiringAttentionIcon
