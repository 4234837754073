import { Random, randomArrayEntry } from "common/utils/RandomUtils"

type TextLength = "word" | "sentence"

const taDeina = "Polla ta deina kouden anthropou deinoteron pelei"

const sampleText = (textLength: TextLength): Random<string> =>
  textLength === "word" ? randomArrayEntry(taDeina.split(" ")) : Random.pure(taDeina)

export const BlurredText = ({
  textLength,
  seed = "",
  className = "",
}: {
  textLength: TextLength
  seed?: string
  className?: string
}) => <span className={`filter blur-sm ${className}`}>{sampleText(textLength).sample(seed)}</span>
