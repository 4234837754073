import { JSONContent } from "@tiptap/react"
import { nullableToMaybe } from "common/containers/Maybe"
import { Company, CompanyIdFields } from "common/model/Company"
import { OwnOrder } from "common/model/Order/Models/Wrapped"
import { Order } from "common/model/Order/Order"
import { ShareableItem } from "common/model/SharedOrder/SharedOrderResponse"
import { showInterval, Interval } from "common/utils/data/Interval"
import { assertUnreachable } from "common/utils/fp/Function"
import { formatPrice, formatValuation } from "common/utils/math"

const constructList = (title: string, items: string[]): JSONContent[] => [
  {
    type: "paragraph",
    attrs: {
      textAlign: "left",
    },
    content: [
      {
        type: "text",
        marks: [
          {
            type: "bold",
          },
        ],
        text: title,
      },
    ],
  },
  {
    type: "bulletList",
    content: items.map((i) => ({
      type: "listItem",
      content: [{ type: "paragraph", content: [{ type: "text", text: i }] }],
    })),
  },
  {
    type: "paragraph",
    attrs: {
      textAlign: "left",
    },
  },
]

const orderTagLine = (
  direction: Order["direction"],
  amountUSD: Interval<number> | null | undefined,
  company: CompanyIdFields
) =>
  `${company.name} ${
    direction === "buy"
      ? amountUSD
        ? `Buyer looking for ${showInterval(formatValuation, true)(amountUSD)}`
        : "Buyer looking for offers"
      : amountUSD
      ? `Seller offering ${showInterval(formatValuation, true)(amountUSD)}`
      : "Seller looking for bids"
  } `

export const multipleOrdersTemplate = (
  items: ShareableItem[],
  companies: Company[]
): JSONContent => ({
  type: "doc",
  content: [
    {
      type: "paragraph",
      attrs: {
        textAlign: "left",
      },
      content: [
        {
          type: "text",
          text: "I thought you might be interested in this.",
        },
      ],
    },
    {
      type: "paragraph",
      attrs: {
        textAlign: "left",
      },
    },
    ...items.flatMap((item) => {
      if (item.tag === "crm_interest") {
        return constructList(
          orderTagLine(
            item.crmInterest.direction,
            item.crmInterest.amountUSD,
            item.crmInterest.company
          ),
          [
            ...(item.crmInterest.targetPrice
              ? [`${formatPrice(item.crmInterest.targetPrice)} Price Per Share`]
              : []),
            ...(item.crmInterest.targetValuation
              ? [`${formatValuation(item.crmInterest.targetValuation)} Valuation`]
              : []),
            ...(item.crmInterest.structure ? [`Structure: ${item.crmInterest.structure}`] : []),
            ...(item.crmInterest.shareClass ? [`Share Class: ${item.crmInterest.shareClass}`] : []),
          ]
        )
      } else if (item.tag === "order") {
        const company = nullableToMaybe(companies.find((c) => c.id === item.order.company.id))
        const wrapped = OwnOrder.wrapOwnOrder({ order: item.order, company }).toNullable()
        if (!wrapped) return []
        return constructList(
          orderTagLine(wrapped.direction(), wrapped.amountUSD().toNullable(), wrapped.company()),
          [
            ...wrapped.impliedPricePerShare().match(
              (n) => [`${formatPrice(n)} Price Per Share`],
              () => []
            ),
            ...wrapped.impliedValuation().match(
              (n) => [`${formatValuation(n)} Estimated Valuation`],
              () =>
                wrapped.targetValuation().match(
                  (n) => [`${formatValuation(n)} Valuation`],
                  () => []
                )
            ),
            `Structure: ${wrapped.structuresWithSPVTerms().join(", ")}`,
            `Share Class: ${wrapped.shareClasses().join(", ")}`,
          ]
        )
      } else if (item.tag === "deal_search") {
        return constructList(
          orderTagLine(
            item.dealSearch.direction,
            item.dealSearch.amountUSD,
            item.dealSearch.company
          ),
          [
            ...(item.dealSearch.targetPrice
              ? [`${formatPrice(item.dealSearch.targetPrice)} Price Per Share`]
              : []),
            ...(item.dealSearch.targetValuation
              ? [`${formatValuation(item.dealSearch.targetValuation)} Valuation`]
              : []),
            ...(item.dealSearch.structure ? [`Structure: ${item.dealSearch.structure}`] : []),
            ...(item.dealSearch.shareClass ? [`Share Class: ${item.dealSearch.shareClass}`] : []),
          ]
        )
      } else {
        return assertUnreachable(item)
      }
    }),
  ],
})
