import { createContext, FC, useContext, useMemo } from "react"
import {
  initialOrderQuotaComplianceData,
  OrderQuotaComplianceData,
  useAccountQuotaCompliantOrders,
} from "src/utils/useAccountQuotaCompliantOrders"
import { useCurrentAccount } from "src/queries/currentUser/useCurrentAccount"
import { AccountOrderQuotaFields } from "common/model/AccountOrderQuotaFields"
import { Account } from "common/model/Account"
import {
  AccountOrdersRequiringAttentionData,
  initialAccountOrdersRequiringAttentionData,
  useAccountOrdersRequiringAttention,
} from "src/utils/useAccountRequiresAttentionOrders"
import {
  initialRemainingLiveDarkpoolOrderData,
  RemainingLiveDarkpoolOrderData,
  useRemainingLiveDarkpoolOrders,
} from "src/utils/useRemainingLiveDarkpoolOrders"
import { useFirebaseWriter } from "src/firebase/Context"
import { useMyOrdersQuery } from "src/firebase/orders"
import { useLoggedInUser } from "../loggedInUser/useLoggedInUser"
import { useAccountIsQuotaDelinquent } from "src/utils/accountIsQuotaDelinquent"
import {
  initialQuarterQuotaHistory,
  QuarterQuotaHistory,
  useAccountQuotaHistory,
} from "src/utils/useAccountQuotaHistory"
import { matchLoading } from "common/utils/Loading"

const initialAccountOrderQuotaFields: AccountOrderQuotaFields = {
  isQuotaSystemEnabled: undefined,
  accountOrderQuotaRequirements: undefined,
  enforceOrderQuota: undefined,
}

const useAccountOrderQuotaFields = (): AccountOrderQuotaFields => {
  const account = useCurrentAccount()
  return useMemo(
    () =>
      matchLoading<
        Account,
        AccountOrderQuotaFields,
        AccountOrderQuotaFields,
        AccountOrderQuotaFields
      >(
        account,
        (a) => ({
          isQuotaSystemEnabled: a.isQuotaSystemEnabled,
          accountOrderQuotaRequirements: a.accountOrderQuotaRequirements,
          enforceOrderQuota: a.enforceOrderQuota,
        }),
        initialAccountOrderQuotaFields,
        initialAccountOrderQuotaFields
      ),
    [account]
  )
}

type AccountComplianceProviderData = AccountOrderQuotaFields &
  OrderQuotaComplianceData &
  AccountOrdersRequiringAttentionData &
  RemainingLiveDarkpoolOrderData &
  QuarterQuotaHistory & { isQuotaDelinquent: boolean }

const AccountComplianceContext = createContext<AccountComplianceProviderData>({
  ...initialAccountOrderQuotaFields,
  ...initialOrderQuotaComplianceData,
  ...initialAccountOrdersRequiringAttentionData,
  ...initialRemainingLiveDarkpoolOrderData,
  ...initialQuarterQuotaHistory,
  isQuotaDelinquent: false,
})

const AccountComplianceProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const db = useFirebaseWriter()
  const { user } = useLoggedInUser()
  const loadingOrders = useMyOrdersQuery({
    db,
    collection: "platform",
    queryLimit: 100,
    userAccountId: user.account.id,
  })

  const loadingDarkpoolOrders = useMyOrdersQuery({
    db,
    collection: "darkpool",
    queryLimit: 100,
    userAccountId: user.account.id,
  })
  const account = useCurrentAccount()
  const accountOrderQuotaFields = useAccountOrderQuotaFields()
  const orderQuotaComplianceData = useAccountQuotaCompliantOrders(
    accountOrderQuotaFields,
    loadingOrders,
    loadingDarkpoolOrders
  )
  const ordersRequiringAttentionData = useAccountOrdersRequiringAttention(
    loadingOrders,
    loadingDarkpoolOrders
  )
  const remainingLiveDarkpoolOrders = useRemainingLiveDarkpoolOrders(account, loadingDarkpoolOrders)

  const accountQuotaHistory = useAccountQuotaHistory(user.account.id)
  const isQuotaDelinquent = useAccountIsQuotaDelinquent({
    orderQuotaComplianceData,
    accountOrderQuotaFields,
  })

  const value = useMemo(
    () => ({
      ...accountOrderQuotaFields,
      ...orderQuotaComplianceData,
      ...ordersRequiringAttentionData,
      ...remainingLiveDarkpoolOrders,
      ...accountQuotaHistory,
      isQuotaDelinquent,
    }),
    [
      accountOrderQuotaFields,
      orderQuotaComplianceData,
      ordersRequiringAttentionData,
      remainingLiveDarkpoolOrders,
      accountQuotaHistory,
      isQuotaDelinquent,
    ]
  )

  return (
    <AccountComplianceContext.Provider value={value}>{children}</AccountComplianceContext.Provider>
  )
}

export const useAccountCompliance = () => useContext(AccountComplianceContext)

export default AccountComplianceProvider
