import { MILLION } from "../../utils/math"

export const targetCheckSizes = [
  "<$1m",
  "$1-5m",
  "$5-10m",
  "$10-25m",
  "$25-100m",
  "$100m+",
] as const
export type TargetCheckSize = (typeof targetCheckSizes)[number]

export const getAirtableCheckSizesForDealSize = (dealSize: number): TargetCheckSize[] => {
  const fuzzyCheckSizes = [
    dealSize > 1.5 * MILLION ? null : ("<$1m" as const),
    dealSize > 6 * MILLION ? null : ("$1-5m" as const),
    dealSize > 12 * MILLION ? null : ("$5-10m" as const),
    dealSize > 30 * MILLION ? null : ("$10-25m" as const),
    dealSize > 120 * MILLION ? null : ("$25-100m" as const),
    "$100m+",
  ] satisfies (TargetCheckSize | null)[]
  return fuzzyCheckSizes.flatMap((cs) => cs ?? [])
}
