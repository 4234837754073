import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { defaultIfLoading } from "common/utils/Loading"
import { TrustedBrokerConnectionList } from "./components/TrustedBrokerConnectionList"
import { TrustedBrokerSearch } from "./components/TrustedBrokerSearch"
import { useState } from "react"
import { useTrustedBrokerNetwork } from "./hooks/useTrustedBrokerNetwork"
import { handleConsoleError } from "src/utils/Tracking"
import { SettingsSection } from "@components/Settings/SettingsModal/SettingsModal"
import { ChevronLeftIcon } from "@stories/icons/ChevronLeftIcon"
import { Button } from "@stories/components/Button/Button"

export const TrustedBrokerNetworkPage = ({
  onChangeActiveSection,
}: {
  onChangeActiveSection?: (section: SettingsSection) => void
}) => {
  const user = useLoggedInUser()
  const [inviteOpen, setInviteOpen] = useState(false)
  const { handleAddToNetwork, currentTrustedBrokerConnections } = useTrustedBrokerNetwork({
    account: user.user.account,
  })
  const handleStartInvite = () => setInviteOpen(true)
  return (
    <>
      {inviteOpen ? (
        <>
          <div className="flex flex-col items-start">
            <Button
              variant="hollow"
              label="Back"
              leftIcon={<ChevronLeftIcon />}
              onClick={() => setInviteOpen(false)}
            />
          </div>
          <TrustedBrokerSearch
            connections={defaultIfLoading(currentTrustedBrokerConnections, [])}
            handleAddToNetwork={(a) => {
              setInviteOpen(false)
              handleAddToNetwork(a).catch(handleConsoleError)
              onChangeActiveSection?.("Invitations")
            }}
          />
        </>
      ) : (
        <TrustedBrokerConnectionList onInvite={handleStartInvite} />
      )}
    </>
  )
}
