import { v4 as uuid } from "uuid"
import { Excluding } from "../../utils/TypeUtils"
import { Account, AccountIdFields, viewAccountIdFields } from "../Account"
import { Company, CompanyIdFields, viewCompanyIdFields } from "../Company"
import { DealCRMDeal, DealCRMDealIdFields, viewDealIdFields } from "../DealCRM/Deal/DealCRMDeal"
import { User, viewUserIdFields } from "../User"
import { UserCreatable } from "../UserCreatable"
import {
  DealDistributionParticipantViewOption,
  defaultDealDistributionParticipantViewOptions,
} from "./DealDistributionParticipantViewOptions"
import { DealDistributionDetails } from "./DealDistributionDetails"
import { DealDistributionEmail } from "./DealDistributionEmail"
import { companyHasMarketActivityInPast6Months } from "../CompanyMarketHistorySummary"

export interface DealDistributionIdFields {
  id: string
  account: Excluding<AccountIdFields, Account>
  sourceId: Excluding<DealCRMDealIdFields, DealCRMDeal>
  isParticipantViewIncludedInDistribution: boolean
}

export const viewDealDistributionIdFields = (
  io: DealDistributionIdFields
): DealDistributionIdFields => ({
  id: io.id,
  account: io.account,
  sourceId: io.sourceId,
  isParticipantViewIncludedInDistribution: io.isParticipantViewIncludedInDistribution,
})
export interface DealDistributionBaseFelids {
  startAt: Date | null
  endAt: Date | null
  visibility: "draft" | "pending approval" | "public" | "closed"
}
export type DealDistributionParticipantViewDetails = {
  views: DealDistributionParticipantViewOption[]
}

export interface DealDistribution
  extends UserCreatable,
    DealDistributionIdFields,
    DealDistributionBaseFelids {
  tag: "deal-crm-deal"
  sourceId: Excluding<DealCRMDealIdFields, DealCRMDeal>
  company: Excluding<CompanyIdFields, Company>
  details: DealDistributionDetails
  emails?: DealDistributionEmail[]
  participantView: DealDistributionParticipantViewDetails
}

export const buildDealDistribution = (
  user: User,
  deal: Pick<
    DealCRMDeal,
    | "id"
    | "name"
    | "company"
    | "expectedCloseDate"
    | "targetDollarAmount"
    | "dealType"
    | "targetSharePrice"
  >,
  company: Pick<Company, "marketHistorySummary">
): DealDistribution => ({
  id: uuid(),
  createdBy: viewUserIdFields(user),
  account: viewAccountIdFields(user.account),
  isParticipantViewIncludedInDistribution: company.marketHistorySummary
    ? companyHasMarketActivityInPast6Months(company.marketHistorySummary)
    : false,
  createdAt: new Date(),
  startAt: null,
  endAt: null,
  visibility: "draft",
  tag: "deal-crm-deal",
  sourceId: viewDealIdFields(deal),
  company: viewCompanyIdFields(deal.company),
  participantView: { views: defaultDealDistributionParticipantViewOptions },
  details:
    deal.dealType === "secondary-direct" || deal.dealType === "secondary-indirect"
      ? {
          tag: deal.dealType,
          closeDate: deal.expectedCloseDate ?? null,
          pricePerShareUSD: deal.targetSharePrice ?? null,
          capitalCallDate: null,
          desiredDealDistribution: "selected-investors",
          valuationUSD: null,
          amountBeingRaisedUSD: deal.targetDollarAmount ?? null,
          minimumInvestmentUSD: null,
          managementFee: null,
          carry: null,
          feeNotes: null,
        }
      : {
          tag: deal.dealType ?? "primary-direct",
          closeDate: deal.expectedCloseDate ?? null,
          capitalCallDate: null,
          desiredDealDistribution: "selected-investors",
          roundName: "",
          valuationUSD: null,
          roundAmountUSD: deal.targetDollarAmount ?? null,
          amountBeingRaisedUSD: deal.targetDollarAmount ?? null,
          minimumInvestmentUSD: null,
          managementFee: null,
          carry: null,
          feeNotes: null,
        },
})
