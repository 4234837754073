import { Company } from "../../../model/Company"
import { CompanyPriceEstimateSummary } from "../../../model/CompanyPriceEstimateSummary"
import { PriceObservationType } from "../../../model/data-product/pricing/PriceObservation"
import { MutualFundMarkParsed } from "../../../model/postgres/PostgresFundMarks"
import { annotate } from "../../../utils/Coerce"
import { Interval } from "../../../utils/data/Interval"
import { Func } from "../../../utils/fp/Function"
import {
  field,
} from "../../../utils/fp/optics/Lens"
import { ifJust } from "../../../utils/fp/optics/Prism"
import { composeTraversal, optionalField, SimpleTraversal } from "../../../utils/fp/optics/Traversal"
import { FlatOrder } from "../displays/Order"
import { SplitAdjustment } from "./SplitAdjustment"

interface SimplePrice {
  date: Date
  price: number
}
export type NeedsSplitAdjustment = {
  company: Company
  order: FlatOrder
  trade: PriceObservationType
  fundMark: MutualFundMarkParsed
  simplePrice: SimplePrice
}
export type SplitAdjusted = {
  company: Company
  order: FlatOrder
  trade: PriceObservationType
  fundMark: MutualFundMarkParsed
  simplePrice: SimplePrice
}
// todo separate date from adjustments
export const splitAdjustments: {
  [key in keyof NeedsSplitAdjustment]: {
    date: Func<NeedsSplitAdjustment[key], Date>
    adjustments: SplitAdjustment<NeedsSplitAdjustment[key]>[]
  }
} = {
  fundMark: {
    date: (x) => new Date(x.reportingDate),
    adjustments: [[field("pps")(), "covariant"]],
  },
  company: {
    date: () => new Date(), // TODO: dates need to be per-field for companies
    adjustments: [
      [
        optionalField("priceEstimatesSummary")<Company>()
          .composeTraversal(
            optionalField("currentPrice")<CompanyPriceEstimateSummary>().composeTraversal(optionalField("priceEstimatePPS")())
          )        ,
        "covariant",
      ],
      [
        annotate<SimpleTraversal<Company, number>>(
          composeTraversal(optionalField("spot")<Company>(), optionalField("pps")())
        ),
        "covariant",
      ],
    ],
  },
  order: {
    date: (x: FlatOrder) => x.orderOriginationDate ?? new Date(),
    adjustments: [
      [
        field("quantity")<FlatOrder>()
          .composeTraversal(ifJust())
          .composeTraversal(optionalField("USDPerShare")())
          .composeTraversal(Interval.bounds()),
        "covariant",
      ],
      [
        field("quantity")<FlatOrder>()
          .composeTraversal(ifJust())
          .composeTraversal(optionalField("shares")())
          .composeTraversal(Interval.bounds()),
        "contravariant",
      ],
    ],
  },
  trade: {
    date: (x: PriceObservationType) => x.observationDate.lowerBound,
    adjustments: [[field("price")(), "covariant"]],
  },
  simplePrice: {
    date: (x) => x.date,
    adjustments: [[field("price")(), "covariant"]],
  },
}
