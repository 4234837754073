import { annotate } from "common/utils/Coerce"
import { assertUnreachable } from "common/utils/fp/Function"

const prodDomains = ["platform.caplight.com", "platform.caplight.io"]

type Domain = "dev" | "staging" | "prod"

const getEnv = (): Domain => {
  const { href } = window.location
  if (prodDomains.some((domain) => href.includes(domain))) return "prod"
  if (href.includes("localhost") || href.includes("0.0.0.0")) return "dev"
  return "staging"
}

const devBackendUrl = (useTestPort?: string) =>
  `http://localhost:${useTestPort === "true" ? "5101" : "5001"}/caplight-staging/us-central1/api`

const firestoreConsoleRootURL = (): string => {
  const env = getEnv()
  switch (env) {
    case "dev":
      return "http://localhost:4000/firestore/data"
    case "staging":
      return "https://console.firebase.google.com/u/0/project/caplight-staging/firestore/data"
    case "prod":
      return "https://console.firebase.google.com/u/0/project/caplight-prod/firestore/data"
    default:
      return assertUnreachable(env)
  }
}
export const config = {
  firebase: {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    imageStorageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    documentStorageBucket:
      process.env.REACT_APP_DOCUMENT_STORAGE_BUCKET || process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    cloudApiUrl:
      (process.env.NODE_ENV && process.env.NODE_ENV !== "development"
        ? process.env.REACT_APP_FIREBASE_CLOUD_API_URL
        : null) || devBackendUrl(process.env.REACT_APP_CYPRESS),
    firestoreEmulatorPort: 5002,
    firestoreConsoleRootURL: firestoreConsoleRootURL(),
  },
  sentryKey: process.env.REACT_APP_SENTRY_KEY,
  releaseVersion: process.env.REACT_APP_SENTRY_RELEASE,
  env: getEnv(),
  datadogClientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || "",
  datadogApplicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID || "",
  reactRenderLogging: annotate<"console" | "none">(
    process.env.REACT_APP_RENDER_LOGGING === "console" ? "console" : "none"
  ),
  algolia: {
    appId: process.env.REACT_APP_ALGOLIA_APP_ID || "",
    apiKey: process.env.REACT_APP_ALGOLIA_API_KEY || "",
    companiesIndexName: process.env.REACT_APP_ALGOLIA_COMPANIES_INDEX_NAME || "company_idx",
    publicCompaniesIndexName:
      process.env.REACT_APP_ALGOLIA_PUBLIC_COMPANIES_INDEX_NAME || "public_companies",
  },
  offlineMode: process.env.REACT_APP_OFFLINE_MODE === "true",
}

export const adminAccountId =
  config.env === "prod" ? "AVhJzdxc0AdbTKsMObsF" : "gdYCjy9z8XlrMr6C6Fga"
export const adminUserIds =
  config.env === "prod"
    ? [
        "UMaURmR5QISyD09nt0y8RLoh0LH3", // rusty@caplight.com
        "bRTTfIAFgefROtDIPcAcDRmio3j1", // chris@caplight.com
      ]
    : [
        "04pnw45eh5PkGX1sxH1n5RgBRqQ2", // chris@caplight.com
        "P7fNLl4dw6dtrcQKEtQGqV91rxt2", // rusty@caplight.com
      ]
