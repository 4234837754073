import { isLoaded, Loading } from "common/utils/Loading"
import { DependencyList, useEffect, useState } from "react"
import * as firebase from "firebase/compat/app"
import * as firestore from "firebase/firestore"
import { onSnapshot, collection, doc as firestoreDoc } from "firebase/firestore"
import { useLoadingState } from "../utils/useLoadingState"
import { firestoreConverter } from "../model/FirestoreConverter"
import { useFirebase9 } from "./Firebase9Context"

type FirestoreError = firestore.FirestoreError

export function useDocument<T extends { id: string }>(
  collectionName: string,
  docId: string | null,
  loadedData?: T
): [T | null | "loading", FirestoreError | null] {
  const firebase9 = useFirebase9()

  const [doc, setDoc] = useLoadingState<T>()

  const [error, setError] = useState<null | FirestoreError>(null)

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (docId && !loadedData) {
      const cleanup = onSnapshot(
        firestoreDoc(collection(firebase9.db, collectionName), docId).withConverter(
          firestoreConverter<T>()
        ),
        (next) => {
          setDoc(next.data() || null)
        },
        (err) => {
          setError(err) // TODO: log sentry error
        }
      )
      return cleanup
    }
    if (docId === null) setDoc(null)
    else if (loadedData) setDoc(loadedData)
  }, [collectionName, firebase9.db, docId, loadedData, setDoc])

  return [doc, error]
}

export function useDocumentWithLoadingId<T extends { id: string }>(
  collectionName: string,
  docId: Loading<{ id: string }>
): [T | null | "loading", FirestoreError | null] {
  const firebase9 = useFirebase9()

  const [doc, setDoc] = useLoadingState<T>()

  const [error, setError] = useState<null | FirestoreError>(null)

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isLoaded(docId)) {
      const cleanup = onSnapshot(
        firestoreDoc(collection(firebase9.db, collectionName), docId.id).withConverter(
          firestoreConverter<T>()
        ),
        (next) => {
          setDoc(next.data() || null)
        },
        (err) => {
          setError(err) // TODO: log sentry error
        }
      )
      return cleanup
    }
    if (docId === null) setDoc(null)
  }, [collectionName, firebase9.db, docId, setDoc])

  return [doc, error]
}

export function useDocumentQuery<T extends { id: string }>(
  query: firebase.default.firestore.Query<T>
): [Loading<firebase.default.firestore.QueryDocumentSnapshot<T>>, Error | null] {
  const [doc, setDoc] = useLoadingState<firebase.default.firestore.QueryDocumentSnapshot<T>>()

  const [error, setError] = useState<null | Error>(null)

  useEffect(() => {
    const cleanup = query.onSnapshot(
      (next) => {
        setDoc(next.docs[0] || null)
      },
      (err) => {
        setError(err) // TODO: log sentry error
      }
    )
    return cleanup
  }, [query, setDoc])

  return [doc, error]
}

export type DocumentsQueryResult<T extends { id: string }> = Loading<T[]>

export function useDocuments<T extends { id: string }>(
  query: firebase.default.firestore.Query<T>,
  deps?: DependencyList
): DocumentsQueryResult<T> {
  const [results, setData] = useLoadingState<T[]>()

  useEffect(() => {
    const cleanup = query.onSnapshot((snapshot) => {
      setData(snapshot.docs.map((doc) => doc.data()))
    })
    return cleanup
    // eslint-disable-next-line
  }, deps || [])

  return results
}
