import { useAccessControl } from "../AccessControlProvider"
import { Color, Size, Typography, Weight } from "@stories/components/Typography/Typography"
import { getAccessControlFeatureDisplayAction } from "common/model/AccessControl/getAccessControlFeatureDisplayName"
import { UpgradeFeatureAccessProps } from "../UpgradeFeatureAccessProps"
import { displayAccessControlTierName } from "common/model/AccessControl/AccessControlTier"
import { SeePlansButton } from "./SeePlansButton"

export const SimpleUpgradeTierTooltipContent = ({
  featureName,
  targetAccessLevel,
  onCloseWrapper,
}: UpgradeFeatureAccessProps & { onCloseWrapper?: () => void }) => {
  const { getUpgradeAccessTierForCurrentTier } = useAccessControl()
  const featureDisplayAction = getAccessControlFeatureDisplayAction(featureName)
  const eitherUpgradeTier = getUpgradeAccessTierForCurrentTier({
    featureName,
    targetAccessLevel,
  })

  return eitherUpgradeTier.match(
    // TODO: probably shouldn't be possible -- might be worth capturing an error
    () => null,
    (upgradeTier) => (
      <div className="flex flex-col space-y-1 p-2">
        <Typography
          color={Color.White}
          weight={Weight.Bold}
          size={Size.Small}
          text={`Upgrade to ${featureDisplayAction}`}
        />
        <Typography
          color={Color.White}
          size={Size.Small}
          text={`Get access with the ${displayAccessControlTierName(upgradeTier)} plan`}
        />
        <div className="pt-4">
          <SeePlansButton
            variant="full-link"
            featureName={featureName}
            onCloseWrapper={onCloseWrapper}
          />
        </div>
      </div>
    )
  )
}
