import { ShareableItem } from "common/model/SharedOrder/SharedOrderResponse"
import LoadedShareOrderContainer from "./LoadedShareOrderContainer"
import LoadingShareOrderContainer from "./LoadingShareOrderContainer"
import { Loading } from "common/utils/Loading/Wrapped"

type Props = {
  item: Loading<ShareableItem>
}

const ShareOrderContainer = ({ item }: Props) =>
  item.match((i) => <LoadedShareOrderContainer item={i} />, <LoadingShareOrderContainer />, null)

export default ShareOrderContainer
