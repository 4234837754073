import { ExclamationCircleIcon } from "@heroicons/react/solid"
import { omit } from "lodash"
import React, { FC, LegacyRef } from "react"
import { FieldInputProps, FieldMetaState } from "react-final-form"
import { classNames } from "../../utils/classNames"

interface TextAreaFieldProps {
  autoFocus?: boolean
  label?: string
  fullWidth?: boolean
  forwardRef?: LegacyRef<HTMLTextAreaElement>
  disabled?: boolean
  placeholder?: string
  meta: {
    error?: string
  } & Omit<FieldMetaState<string>, "error">
  labelClassName?: string
  input: FieldInputProps<string>
  optional?: boolean
}

export const TextAreaField: FC<React.PropsWithChildren<TextAreaFieldProps>> = (props) => {
  const error = props.meta.error && props.meta.touched ? props.meta.error : undefined

  return (
    <TextArea
      label={props.label}
      labelClassName={props.labelClassName}
      fullWidth={props.fullWidth}
      autoFocus={props.autoFocus}
      forwardRef={props.forwardRef}
      {...props.input}
      value={props.input.value}
      optional={props.optional}
      error={error}
      disabled={props.disabled}
      placeholder={props.placeholder}
    />
  )
}

interface TextAreaInputProps {
  name: string
  value: string | number
  required?: boolean
  disabled?: boolean
  autoFocus?: boolean
  forwardRef?: LegacyRef<HTMLTextAreaElement>
  label?: string
  inlineLabel?: boolean
  labelClassName?: string
  placeholder?: string
  className?: string
  inputClassName?: string
  fullWidth?: boolean
  error?: string
  onBlur?: (event?: React.FocusEvent<HTMLElement>) => void
  onChange?: (event: React.ChangeEvent<{ value: string }>) => void
  onFocus?: (event?: React.FocusEvent<HTMLElement>) => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void
  optional?: boolean
}

export const InputError = ({ error }: { error: string }) => (
  <p className="mt-1 ml-1 -mb-2 text-xs text-danger-600" id="email-error">
    {error}
  </p>
)

export const TextArea: FC<React.PropsWithChildren<TextAreaInputProps>> = (props) => (
  <div className="flex flex-col">
    <div className={classNames(props.className, props.inlineLabel ? "flex items-center" : "")}>
      {props.label && (
        <label
          htmlFor={props.name}
          className={classNames(
            props.disabled ? "text-neutral-400" : "",
            props.inlineLabel ? "inline text-black mr-4" : "block w-full text-neutral-1000",
            props.labelClassName || "text-sm font-normal",
            "whitespace-nowrap"
          )}
        >
          {props.label}
          {props.optional && <span className="text-sm pl-4 right-0 relative">(optional)</span>}
        </label>
      )}
      <div className={classNames("flex items-center", props.fullWidth ? "w-full" : "")}>
        <div className={classNames("mt-1 relative ", props.fullWidth ? "w-full" : "")}>
          <textarea
            {...omit(
              props,
              "forwardRef",
              "optional",
              "fullWidth",
              "inputClassName",
              "labelClassName",
              "inlineLabel"
            )}
            id={props.name}
            ref={props.forwardRef}
            name={props.name}
            autoFocus={props.autoFocus}
            required={props.required}
            placeholder={props.placeholder}
            className={classNames(
              props.disabled ? "bg-neutral-100" : "",
              props.inputClassName,
              props.fullWidth ? "w-full py-3" : "",
              "appearance-none block px-3 py-2 border border-neutral-400 rounded-md shadow-sm placeholder-neutral-600 focus:outline-none focus:ring-neutral-700 focus:border-neutral-700 sm:text-sm",
              props.error
                ? "border-danger-600 text-danger-900 placeholder-danger-300 focus:outline-none focus:ring-danger-600 focus:border-danger-600"
                : ""
            )}
          />
          {props.error && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ExclamationCircleIcon className="h-5 w-5 text-danger-600" aria-hidden="true" />
            </div>
          )}
        </div>
      </div>
      {props.error && <InputError error={props.error} />}
    </div>
  </div>
)

export const _InputError = ({ error }: { error: string }) => (
  <p className="mt-1 ml-1 -mb-2 text-xs text-danger-600">{error}</p>
)
