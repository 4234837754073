import NotesDisplay from "@components/inputs/NotesDisplay"
import { TextArea } from "@components/inputs/TextAreaField"
import { notification } from "@stories/components/Antd"
import { restrictedCollectionReferences } from "common/firestore/Collections"
import { createNote } from "common/model/Note"
import { Order } from "common/model/Order/Order"
import { useCallback, useState } from "react"
import { useFirebaseWriter } from "src/firebase/Context"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { Button } from "@stories/components/Button/Button"
import { handleConsoleError } from "src/utils/Tracking"

export const AdminOrderNoteEditor = ({
  order,
  side,
}: {
  order: Order
  side: "Anchor" | "Requester"
}) => {
  const [newNote, setNewNote] = useState("")
  const firebase = useFirebaseWriter()
  const docRef = order.darkpool
    ? restrictedCollectionReferences.darkpoolOrderObservations(firebase.writerDb).doc(order.id)
    : restrictedCollectionReferences.orderObservations(firebase.writerDb).doc(order.id)
  const currentUser = useLoggedInUser()
  // TODO: make logged (potentially)
  const updateOrderAdminNotes = useCallback(() => {
    const newNoteObj = createNote(newNote, currentUser.user)
    const updatedOrder: Partial<Order> = { adminNotes: [...(order.adminNotes ?? []), newNoteObj] }
    docRef
      .update(updatedOrder)
      .then(() => {
        notification.success({
          message: "Admin order notes submitted",
          placement: "top",
        })
      })
      .then(() => setNewNote(""))
      .catch(handleConsoleError)
  }, [docRef, newNote, currentUser.user, order.adminNotes])

  return (
    <div className="flex flex-col space-y-4 flex-1">
      <div className="flex space-x-2 items-end">
        <TextArea
          name="adminOrderNoteEditor"
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
          label={`${side} Order`}
        />
        <Button label="Submit" variant="primary" onClick={updateOrderAdminNotes} />
      </div>
      <NotesDisplay notes={order.adminNotes ?? undefined} />
    </div>
  )
}
