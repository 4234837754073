import { enumPrism } from "../../../utils/fp/optics/Prism"

export const definiteTransactionStructures = [
  "direct",
  "spv",
  "forward",
  "call_option",
  "put_option",
  "swap",
  "variable_prepaid_forward",
  "collective_liquidity_exchange_fund",
] as const
export const tradeStructures = [
  "structure_agnostic_regular_way",
  "unknown",
  "indirect",
  ...definiteTransactionStructures,
] as const
export const structurePrism = enumPrism(tradeStructures)

type TradeStructure = typeof tradeStructures

export type Structure = TradeStructure[number]
