import { Button, ButtonProps } from "@stories/components/Button/Button"

export const BookACallButton = ({
  variant,
  isFullWidth,
}: Pick<ButtonProps, "variant" | "isFullWidth">) => (
  <Button
    size="large"
    onClick={() => window.location.assign("https://calendly.com/d/ytq-g9s-fqm/caplight-meeting")}
    variant={variant}
    isFullWidth={isFullWidth}
    label="Book a call"
  />
)
