import Table from "@components/data_display/Table"
import { Button } from "@stories/components/Button/Button"
import Typography, { Weight } from "@stories/components/Typography/Typography"
import type { OpportunityInboxAccountUsageData } from "common/model/Opportunity/admin/AdminOpportunityDigest"
import { query, collection, where, getDocs } from "firebase/firestore"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import { useAdminEngagementDrawer } from "src/providers/AdminProviders/AdminEngagementDrawerProvider"
import { collections } from "common/firestore/Collections"
import { useEffect, useState } from "react"
import { firestoreConverter } from "common/model/FirestoreConverter"
import { AccountAnalytics } from "common/model/AccountAnalytics/AccountAnalytics"
import { handleConsoleError } from "src/utils/Tracking"

export const AdminAllAccountOpportunityInboxDashboard = () => {
  const firebase9 = useFirebase9()
  const [allAccountData, setUsageData] = useState<OpportunityInboxAccountUsageData[]>([])

  useEffect(() => {
    getDocs(
      query(
        collection(firebase9.db, collections.accountAnalytics),
        where("tag", "==", "opportunity inbox usage")
      ).withConverter<AccountAnalytics & { tag: "opportunity inbox usage" }>(
        firestoreConverter<AccountAnalytics & { tag: "opportunity inbox usage" }>()
      )
    )
      .then((s) => s.docs.map((d) => d.data().opportunityInboxUsageData))
      .then(setUsageData)
      .catch(handleConsoleError)
  }, [firebase9.db])
  const { openDrawer: openAdminEngagementDrawer } = useAdminEngagementDrawer()

  return (
    <div>
      <div className="flex flex-col gap-2">
        <Typography weight={Weight.Bold} text="Opp Inbox Stats" />
        <Table
          headers={[
            { id: "account", label: "Account" },
            { id: "allOpportunities", label: "Total Opportunities (unread)" },
            { id: "archivedOpportunities", label: "Archived Opportunities (unread)" },
            { id: "primary", label: "Primary Opportunities (unread)" },
            { id: "secondary", label: "Secondary Opportunities (unread)" },
            { id: "pinned", label: "Pinned Opportunities (unread)" },
            { id: "weeklyLiveMarket", label: "Weekly Live Market (primary)" },
            { id: "weeklyPrivate", label: "Weekly Private (primary)" },
            { id: "allRules", label: "All Rules" },
            { id: "companyRules", label: "Company Rules" },
            { id: "globalRules", label: "Global Rules" },
            { id: "watchlistRules", label: "Watchlist" },
            { id: "bidRules", label: "Show Bids" },
            { id: "offerRule", label: "Show Offers" },
            { id: "liveMarketRules", label: "Live Market" },
          ]}
          rows={allAccountData.map((data) => ({
            account: (
              <Button
                variant="hollow-link"
                label={`${data.account.name}`}
                onClick={() => openAdminEngagementDrawer(data.account.id)}
              />
            ),
            allOpportunities: `${data.opportunityData.all.allOpportunities ?? "na"} (${
              data.opportunityData.unread.allOpportunities ?? "na"
            })`,
            archivedOpportunities: `${data.opportunityData.all.archivedOpportunities ?? "na"} (${
              data.opportunityData.unread.archivedOpportunities ?? "na"
            })`,
            primary: `${data.opportunityData.all.primaryInboxOpportunities ?? "na"} (${
              data.opportunityData.unread.primaryInboxOpportunities ?? "na"
            })`,
            secondary: `${data.opportunityData.all.secondaryInboxOpportunities ?? "na"} (${
              data.opportunityData.unread.secondaryInboxOpportunities ?? "na"
            })`,
            pinned: `${data.opportunityData.all.pinnedOpportunities ?? "na"} (${
              data.opportunityData.unread.pinnedOpportunities ?? "na"
            })`,
            weeklyLiveMarket: `${data.uploadData.thisWeek.live_market ?? "na"} (${
              data.uploadData.passingFilterThisWeek.live_market ?? "na"
            })`,
            weeklyPrivate: `${data.uploadData.thisWeek.private ?? "na"} (${
              data.uploadData.passingFilterThisWeek.private ?? "na"
            })`,
            allRules: `${data.ruleData.allRules ?? "na"}`,
            companyRules: `${data.ruleData.company ?? "na"}`,
            globalRules: `${data.ruleData.global ?? "na"}`,
            watchlistRules: `${data.ruleData["global show watchlist"] ? "on" : "off"}`,
            liveMarketRules: `${data.ruleData["global hide live market"] ? "on" : "off"}`,
            bidRules: `${data.ruleData["global show bids"] ? "on" : "off"}`,
            offerRule: `${data.ruleData["global show offers"] ? "on" : "off"}`,
            id: data.account.id,
          }))}
        />
      </div>
    </div>
  )
}
