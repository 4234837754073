/* eslint-disable max-classes-per-file */

import { ReifiedLens } from "./optics/Lens"

/** Adds another level of indirection between the underlying `unboxed` value and calling code.
 * This allows us to have slightly more uniform syntax and semantics between primitive values and objects, which is useful for writing code which needs to be generic over both
 * Subclasses of `Boxed` should not implement their own constructors: doing so will cause `rebox` to break
 * */
export abstract class Boxed<T> {
  readonly unboxed: T

  // we need different names for `map` and its non type-changing variant because we can't seal subclasses
  rebox(f: (t: T) => T): this {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const result = Object.create(Object.getPrototypeOf(this) as object) as this
    ;(result as { unboxed: T }).unboxed = f(this.unboxed) // This is extremely unsafe and should never be imitated
    return result
  }

  constructor(unboxed: T) {
    this.unboxed = unboxed
  }
}

export const unboxed = <B extends Boxed<unknown>>(): ReifiedLens<
  B,
  B,
  B["unboxed"],
  B["unboxed"]
> => new ReifiedLens({ view: (u) => u.unboxed, over: (s, f) => s.rebox(f) })
