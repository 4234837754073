/* eslint-disable react/jsx-no-useless-fragment */
import { lambda } from "common/utils/fp/WrappedFunction"
import _ from "lodash"
import { Display, DisplayNode } from "./Display"

type JsonPrim = string | number | boolean | null | Date // Date is not actually a JSON primitive but we use it like one everywhere else

/**
 * JSON is a recursive data type, generated by a fixed collection of primitives (strings, numbers, etc.) together with combinators for combining JSON objects together (in this case: records and arrays).
 *
 * `JsonF` is the type of a single layer of a JSON structure, parameterized by the type of the next layer.
 * (Compare: `List<X> = X[]` and `ListF<X,T> = [X, ...T]`)
 *
 * For many purposes, it's better to think about the layers of a recursive type as the primary objects, and the recursive type itself as a fixed point: `JSON = JsonF<JSON>`, `List<X> = ListF<X,List<X>>`, and so on. (In particular, these are the *least* fixed points. The greatest fixed point of `JsonF` includes infinitely deep structures, but is somewhat awkward to encode in TS). When `reduce`ing a list, for instance, we only provide a function `ListF<X,Y> => Y`, and let the structure of the type handle the rest.
 */
export type JsonF<T> = { [key: string]: T } | T[] | JsonPrim

export type Json = { [key: string]: Json } | Json[] | JsonPrim

export const matchJson = <T, S>(
  x: JsonF<T>,
  ifObject: (o: { [key: string]: T }) => S,
  ifArray: (a: T[]) => S,
  ifString: (s: string) => S,
  ifNumber: (n: number) => S,
  ifBoolean: (b: boolean) => S,
  ifDate: (d: Date) => S,
  ifNull: S
): S =>
  _.isArray(x)
    ? ifArray(x)
    : _.isDate(x)
    ? ifDate(x)
    : _.isObject(x)
    ? ifObject(x)
    : _.isString(x)
    ? ifString(x)
    : _.isNumber(x)
    ? ifNumber(x)
    : _.isBoolean(x)
    ? ifBoolean(x)
    : ifNull

export const JsonKVP = (k: string, Value: DisplayNode) => (
  <div className="my-2 border-neutral-400border-l-2 flex flex-row align-middle ml-8" key={k}>
    <span className="shadow-inner bg-neutral-200 ml-2 p-1 inline-flex items-center font-mono">
      {k}
    </span>
    <span className="pl-1 inline-block">{Value}</span>
  </div>
)

export const foldJsonDisplay = <T extends Json>(
  ifString: (s: string) => DisplayNode,
  ifNumber: (n: number) => DisplayNode,
  ifBoolean: (b: boolean) => DisplayNode,
  ifDate: (d: Date) => DisplayNode,
  ifNull: DisplayNode
): Display<T, DisplayNode> =>
  lambda((y) =>
    matchJson(
      y,
      (o) =>
        Object.entries(o).length > 0 ? (
          <div className="flex flex-col">
            {Object.entries(o)
              .sort(([k1, v1], [k2, v2]) => k1.localeCompare(k2))
              .map(([k, v]) =>
                JsonKVP(k, foldJsonDisplay(ifString, ifNumber, ifBoolean, ifDate, ifNull).run(v))
              )}
          </div>
        ) : (
          <span>{"{}"}</span>
        ),
      (a) => (a.length > 0 ? <div>{a.map((x) => rawJsonDisplay.run(x))}</div> : <span>[]</span>),
      ifString,
      ifNumber,
      ifBoolean,
      ifDate,
      ifNull
    )
  )

const jsonLeafClassName =
  "p-1 block shadow-inner bg-neutral-white h-full text-blue border-2 border-solid border-neutral-400"
// default displays for json primitives
export const rawJsonDisplay: Display<Json> = foldJsonDisplay(
  (s) => <span className={jsonLeafClassName}>{s}</span>,
  (n) => <span className={jsonLeafClassName}>{n}</span>,
  (b) => <span className={jsonLeafClassName}>{b ? "true" : "false"}</span>,
  (d) => <span className={jsonLeafClassName}>{d.toString()}</span>,
  <span className={jsonLeafClassName}>null</span>
)
