import { isLoading } from "common/utils/Loading"
import { ReactElement } from "react"
import { NotificationsProvider } from "src/providers/data/NotificationsProvider"
import { isLoggedIn } from "../../../model/CurrentUser"
import { useCurrentUser } from "../../../providers/currentUser/useCurrentUser"
import { LoggedInLayout } from "./LoggedInLayout"
import { LoggedOutLayout } from "./LoggedOutLayout"

export const AppLayout = ({ children }: { children: ReactElement }) => {
  const user = useCurrentUser()
  if (isLoading(user)) return null

  return isLoggedIn(user) ? (
    <NotificationsProvider>
      <LoggedInLayout currentUser={user}>{children}</LoggedInLayout>
    </NotificationsProvider>
  ) : (
    <LoggedOutLayout>{children}</LoggedOutLayout>
  )
}
