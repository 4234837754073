import { collectionReferences, restrictedCollectionReferences } from "../../firestore/Collections"
import { FirebaseCommon } from "../../firestore/Interface"
import { DualizeUnion } from "../../utils/RecordUtils"
import { assertUnreachable } from "../../utils/fp/Function"
import { Maybe, Nothing, nullableToMaybe } from "../../containers/Maybe"
import { Order, OrderLookupFields } from "../Order/Models/Internal"
import { updateOrderStatus } from "../Order/Types/Status"
import { ConnectThread } from "./ConnectThread"
import { ConnectMessage, Unsent, UnsentConnectMessage } from "./Message"
import {
  StructuredConnectMessage,
  StructuredConnectMessageResponse,
  StructuredMessagePayloads,
  StructuredMessageResponses,
  StructuredMessageType,
  _StructuredConnectMessage,
  _StructuredConnectMessageResponse,
} from "./MessageTypes/StructuredMessage"
import { ConnectSystemMessage } from "./MessageTypes/SystemMessage"
import { ThreadType } from "./ThreadTypes/ThreadType"

// todo generalize this
type IndexedUpdate<T> = DualizeUnion<T extends unknown ? (t: T) => Partial<T> : never> &
  ((t: T) => Partial<T>)

export type MessageEffect = {
  threadUpdate?: IndexedUpdate<ConnectThread>
  globalOrderUpdates?: { orderId: OrderLookupFields; update: (o: Order) => Partial<Order> }[]
}

export const emptyMessageEffect: MessageEffect = {}

export const runMessageEffect =
  (db: FirebaseCommon.DB) => async (eff: MessageEffect, thread: ConnectThread) => {
    if (eff.threadUpdate) {
      await collectionReferences.connectThreads(db).doc(thread.id).update(eff.threadUpdate(thread))
    }
    if (eff.globalOrderUpdates) {
      await Promise.all(
        eff.globalOrderUpdates.map(async (update) => {
          const ref = update.orderId.darkpool
            ? restrictedCollectionReferences.darkpoolOrderObservations(db).doc(update.orderId.id)
            : restrictedCollectionReferences.orderObservations(db).doc(update.orderId.id)
          const order = (await ref.get()).data()
          if (order) {
            // TODO: make logged
            await ref.update(update.update(order))
          }
        })
      )
    }
  }

export const systemMessageEffects: {
  [key in ConnectSystemMessage["tag"]]: () => MessageEffect
} = {
  "global order update": () => emptyMessageEffect,
  "thread closed": () => ({ threadUpdate: () => ({ _status: { tag: "archived" } }) }),
  "thread created": () => ({ threadUpdate: () => ({ _status: { tag: "live" } }) }),
  "connection established": () => emptyMessageEffect,
  "requester thread created": () => emptyMessageEffect,
}

export const structuredMessageEffects: {
  [key in keyof StructuredMessagePayloads]: (r: StructuredMessagePayloads[key]) => MessageEffect
} = {
  orderOverlayUpdate: (update) => ({
    threadUpdate: (thread: ConnectThread) => ({
      documentOverlays: {
        ...thread.documentOverlays,
        [update.orderId.id]: { tag: "order", orderOverlay: update.after },
      },
    }),
  }),
  stillLive: () => emptyMessageEffect, // TODO,
}

export const structuredMessageResponseEffects: {
  [key in keyof StructuredMessageResponses]: (r: StructuredMessageResponses[key]) => MessageEffect
} = {
  stillLive: ({ orderId, stillLive }) => ({
    globalOrderUpdates: [
      {
        orderId,
        update: (o) =>
          updateOrderStatus(o, { tag: stillLive ? "live" : "cancelled", asOf: new Date() }),
      },
    ],
  }), // TODO
  orderOverlayUpdate: assertUnreachable,
}

export const structuredMessageEffect = <S extends StructuredMessageType>(
  s: Pick<_StructuredConnectMessage<S>, "tag" | "payload">
): MessageEffect =>
  s.tag in structuredMessageEffects
    ? structuredMessageEffects[s.tag as S](s.payload as StructuredMessagePayloads[S])
    : emptyMessageEffect
export const structuredMessageResponseEffect = <S extends StructuredMessageType>(
  s: _StructuredConnectMessageResponse<S>
): MessageEffect => {
  const effTag = s.tag.replace("_response", "")
  return effTag in structuredMessageResponseEffects
    ? structuredMessageResponseEffects[effTag as S](s.response as StructuredMessageResponses[S]) // TODO fix
    : emptyMessageEffect
}

export const isStructuredConnectMessage = (
  message: Unsent<ConnectMessage<ThreadType>>
): message is Unsent<StructuredConnectMessage> => message.tag in structuredMessageEffects

export const isStructuredConnectMessageResponse = (
  message: UnsentConnectMessage<ThreadType>
): message is StructuredConnectMessageResponse =>
  message.tag.includes("_response") &&
  message.tag.replace("_response", "") in structuredMessageResponseEffects // TODO fix these

const isSystemConnectMessage = (
  message: UnsentConnectMessage<ThreadType>
): message is ConnectSystemMessage => message.tag in systemMessageEffects

export const getMessageEffect = (
  message: UnsentConnectMessage<ThreadType>
): Maybe<MessageEffect> => {
  if (isStructuredConnectMessage(message)) {
    return nullableToMaybe(structuredMessageEffect(message))
  } else if (isStructuredConnectMessageResponse(message)) {
    return nullableToMaybe(structuredMessageResponseEffect(message))
  } else if (isSystemConnectMessage(message)) {
    return nullableToMaybe(systemMessageEffects[message.tag]())
  } else {
    return Nothing
  }
}
