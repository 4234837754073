import { HoldingDocumentType } from "./HoldingDocument"

export const holdingOwnershipVariants = [
  "personal",
  "retirementOrTrust",
  "stockOptions",
  "investmentFund",
  "personalInvestment",
  "indirect",
] as const
export type HoldingOwnershipKey = (typeof holdingOwnershipVariants)[number]

type HoldingOwnershipRequirementsItem = {
  documents: HoldingDocumentType[]
  moreInfo?: boolean
}
type HoldingOwnershipRequirements = Record<HoldingOwnershipKey, HoldingOwnershipRequirementsItem>
export const ownershipRequirements: HoldingOwnershipRequirements = {
  personal: {
    documents: [
      "companyBylaws",
      "stockPlan",
      "stockGrant",
      "optionGrant",
      "optionExerciseNotice",
      "stockCertificates",
    ],
  },
  retirementOrTrust: {
    documents: [
      "companyBylaws",
      "stockPlan",
      "stockGrant",
      "optionGrant",
      "optionExerciseNotice",
      "stockCertificates",
      "stockTransferAgreement",
    ],
  },
  stockOptions: {
    documents: ["companyBylaws", "stockPlan", "optionGrant", "optionExerciseNotice"],
  },
  investmentFund: {
    documents: [
      "subscriptionAgreement",
      "operatingAgreement",
      "privatePlacementMemorandum",
      "companyBylaws",
      "stockCertificates",
      "stockPurchaseAgreement",
      "investorRightsAgreement",
      "rofr",
    ],
  },
  personalInvestment: {
    documents: [
      "companyBylaws",
      "stockCertificates",
      "stockPurchaseAgreement",
      "investorRightsAgreement",
      "rofr",
    ],
  },
  indirect: {
    documents: [
      "subscriptionAgreement",
      "operatingAgreement",
      "privatePlacementMemorandum",
      "companyBylaws",
      "stockCertificates",
      "stockPurchaseAgreement",
      "investorRightsAgreement",
      "rofr",
    ],
    moreInfo: true,
  },
}

type HoldingOwnershipDescriptionItem = { short: string; full: string }
type HoldingOwnershipDescriptions = Record<HoldingOwnershipKey, HoldingOwnershipDescriptionItem>
export const ownershipDescriptions: HoldingOwnershipDescriptions = {
  personal: {
    short: "I own shares in my personal name and received the shares as an employee/advisor",
    full: "You own shares in your personal name if you (as an individual) are the listed shareholder of record. This is common if you served as an employee or advisor to the company, or if you were a personal investor in the company. You do not own shares in your personal name if you own the shares in a trust or an investment fund.",
  },
  retirementOrTrust: {
    short:
      "I own shares in a retirement account or trust and received the shares as an employee/advisor",
    full: "You own shares in a retirement account or trust if you purchased your shares originally through a retirement account or trust. You would also own shares in a retirement account or trust if you previously held your shares in your personal name and transferred them into your retirement account or trust. Common examples of retirement accounts include a traditional or Roth IRA, trusts are typically used as family investment entities.",
  },
  stockOptions: {
    short: "I own stock options",
    full: "You hold stock options if you were given an option grant by a private company and have not yet exercised your stock options. Option grants are typically awarded to employees and advisors of private companies as a form of compensation. An option grant gives you the ability to exercise your options and convert those options into shares of the private company.",
  },
  investmentFund: {
    short: "I own shares through an investment fund",
    full: "You own shares directly on the cap table in a co-mingled fund if you purchased shares directly from the company or through a Board-approved transfer and those shares are held with other investments in a fund. This is typical for a venture capital fund, hedge fund, or other pooled investment vehicle that professionally invests in the private markets.",
  },
  personalInvestment: {
    short: "I own shares through a personal investment I made, not through an investment fund",
    full: "You own shares directly on the cap table in your name if you purchased shares directly from the company or through a Board-approved transfer. This is most common for an Angel investor who bought shares through a primary round of fundraising.",
  },
  indirect: {
    short: "I own shares indirectly through some other ownership structure",
    full: "",
  },
}

export type HoldingOwnershipStructure = {
  type: HoldingOwnershipKey
  moreInfo?: string
}
