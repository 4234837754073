import { AccountIdFields } from "../../Account"
import { UserIdFields } from "../../User"
import { UserCreatable } from "../../UserCreatable"
import { UserUpdatable } from "../../UserUpdatable"
import { DealCRMContactIdFields } from "../DealCRMContact"
import { DealCRMHolding } from "../DealCRMHolding"
import { SPVFees } from "../DealCRMInterest"
import { DealCRMDeal, DealCRMDealIdFields, DealCRMPriorities } from "./DealCRMDeal"
import { DealCRMFund } from "./DealCRMFund"

export const DealCRMUrgencies = ["low", "medium", "high", "none"] as const
export const DealCRMStages = ["prospecting", "active", "closed", "cancelled"] as const
export const DealCRMFundTypes = ["direct", "fund"] as const
export const DealCRMShareClasses = ["common", "preferred"] as const

export type DealCRMUrgency = (typeof DealCRMUrgencies)[number]
export type DealCRMPriority = (typeof DealCRMPriorities)[number]
export type DealCRMStage = (typeof DealCRMStages)[number]
export type DealCRMFundType = (typeof DealCRMFundTypes)[number]
export type DealCRMShareClass = (typeof DealCRMShareClasses)[number]

export type DealCRMDealParticipant = UserCreatable &
  UserUpdatable & {
    id: string
    contact: DealCRMContactIdFields
    deal: DealCRMDealIdFields
    dealSide: "buyer" | "seller"
    account: AccountIdFields

    dealStructure?: DealCrmDealStructureType
    fund?: DealCRMFund

    urgency: DealCRMUrgency
    priority: DealCRMPriority

    closeByDate?: Date

    outreachStatus?: DealCrmDealContactOutreachStatus | null
    commitmentStatus?: DealCrmDealContactCommitmentStatus | null
    targetDollarAmount?: number | null
    commitmentNumShares?: number | null
    commitmentSharePrice?: number | null
  } & SPVFees & {
    lastUpdatedAt?: Date
    lastUpdateBy?: UserIdFields
  }

export type DealCRMDealShareholderParticipant = DealCRMDealParticipant & {
  dealSide: "seller"
  holding?: DealCRMHolding
}

export const DealCrmDealContactOutreachStatuses = [
  "pending-outreach",
  "awaiting-reply",
  "in-conversations",
  "committed",
  "not-interested",
  "no-response",
] as const

export type DealCrmDealContactOutreachStatus = (typeof DealCrmDealContactOutreachStatuses)[number]

export const DealCrmDealContactCommitmentStatuses = [
  "pending-outreach",
  "pending-reply",
  "tentative-interest",
  "not-interested",
  "firm-interest",
  "signed-commitment",
  "wired",
] as const

export type DealCrmDealContactCommitmentStatus =
  (typeof DealCrmDealContactCommitmentStatuses)[number]

export const dealCrmDealContractOutreachStatusesHumanReadable: Record<
  DealCrmDealContactCommitmentStatus,
  string
> = {
  "pending-outreach": "Pending Outreach",
  "pending-reply": "Pending Reply",
  "tentative-interest": "Tentative Interest",
  "not-interested": "Not Interested",
  "firm-interest": "Firm Interest",
  "signed-commitment": "Signed Commitment",
  wired: "Wired",
}

export const DealCrmDealStructureTypes = ["direct", "fund"] as const
export type DealCrmDealStructureType = (typeof DealCrmDealStructureTypes)[number]

export type DealWithParticipant = {
  deal: DealCRMDeal
  participant: DealCRMDealParticipant
}
