import { WarningFilled } from "@ant-design/icons"
import { PageTitle } from "@components/typography/PageTitle"
import { Drawer, Radio, Tooltip } from "@stories/components/Antd"
import { collections } from "common/firestore/Collections"
import {
  FeatureFlag,
  FeatureFlagRecord,
  FeatureFlagStatus,
  featureFlagStatuses,
} from "common/model/FeatureFlag/FeatureFlag"
import { firestoreConverter } from "common/model/FirestoreConverter"
import { defaultIfLoading, deprecatedIsLoaded, Loading, mapLoading } from "common/utils/Loading"
import { ReactNode, createContext, useEffect, useMemo, useState } from "react"
import { FirebaseReader } from "../../firebase/Firebase"
import { useDbState } from "../../utils/useDb"
import { useCurrentUser } from "../currentUser/useCurrentUser"
import { snakeCaseToTitleCase } from "common/utils/StringUtils"
import { SearchInput } from "@components/inputs/SearchInput"

const getAllFeatureFlags = ({ db }: FirebaseReader): Promise<FeatureFlag[]> =>
  db
    .collection(collections.featureFlags)
    .withConverter<FeatureFlag>(firestoreConverter<FeatureFlag>())
    .get()
    .then(({ docs }) => docs.map((d) => d.data()))

const featureFlagArrayToObject = (arr: FeatureFlag[]) =>
  Object.fromEntries(arr.map((flag) => [flag.name, flag]))

export const FeatureFlagsContext = createContext<{
  featureFlags: Loading<FeatureFlagRecord>
  setFeatureFlagsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>> | null
}>({ featureFlags: null, setFeatureFlagsDrawerOpen: null })

export const FeatureFlagsProvider = ({ children }: { children: ReactNode }) => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const loadingFeatureFlags = useDbState(getAllFeatureFlags, [])
  const featureFlagsFromDb = useMemo(
    () => mapLoading(featureFlagArrayToObject)(loadingFeatureFlags),
    [loadingFeatureFlags]
  )
  const currentUser = useCurrentUser()
  const [featureFlags, setFeatureFlags] = useState<Loading<FeatureFlagRecord>>("loading")

  useEffect(() => {
    setFeatureFlags(featureFlagsFromDb)
  }, [featureFlagsFromDb])

  const setFeatureFlagsDrawerOpen =
    deprecatedIsLoaded(currentUser) && currentUser.isAdmin ? setDrawerOpen : null

  const value = useMemo(
    () => ({
      featureFlags,
      setFeatureFlagsDrawerOpen,
    }),
    [featureFlags, setFeatureFlagsDrawerOpen]
  )

  return (
    <FeatureFlagsContext.Provider value={value}>
      {children}
      {deprecatedIsLoaded(currentUser) && currentUser.isAdmin ? (
        <FeatureFlagsDrawer
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          featureFlags={featureFlags}
          setFeatureFlags={setFeatureFlags}
          featureFlagsFromDb={featureFlagsFromDb}
        />
      ) : null}
    </FeatureFlagsContext.Provider>
  )
}

type FeatureFlagsDrawerProps = {
  open: boolean
  onClose: () => void
  featureFlags: Loading<FeatureFlagRecord>
  setFeatureFlags: React.Dispatch<React.SetStateAction<Loading<FeatureFlagRecord>>>
  featureFlagsFromDb: Loading<FeatureFlagRecord>
}
const FeatureFlagsDrawer = ({
  open,
  onClose,
  featureFlags,
  setFeatureFlags,
  featureFlagsFromDb,
}: FeatureFlagsDrawerProps) => {
  const currentUser = useCurrentUser()
  const [searchTerm, setSearchTerm] = useState("")

  const filteredFeatureFlags = useMemo(
    () =>
      Object.values(defaultIfLoading(featureFlags, {})).filter((f) =>
        f.name.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [featureFlags, searchTerm]
  )

  return (
    <Drawer
      open={deprecatedIsLoaded(currentUser) && currentUser.isAdmin && open}
      onClose={onClose}
      title={<PageTitle title="Feature Flags" />}
      closable
      mask
      maskClosable
      destroyOnClose
    >
      <div className="flex flex-col gap-4">
        <SearchInput
          key="marketFilterCompanySearch"
          placeholder="Search feature flags..."
          value={searchTerm}
          onChange={(newValue) => setSearchTerm(newValue)}
        />
        {filteredFeatureFlags.map((flag) => (
          <div key={flag.name} className="text-sm flex flex-col gap-2 border rounded p-2">
            <div className="flex flex-col gap-2">
              <div className="font-bold text-base">{snakeCaseToTitleCase(flag.name)}</div>
              <div className="text-sm px-2 py-1 bg-neutral-200 rounded border inline-block max-w-max">
                {flag.name}
              </div>
              {defaultIfLoading(featureFlagsFromDb, {})[flag.name]?.status !== flag.status ? (
                <Tooltip
                  title={`You are locally overriding this flag. Db status: ${
                    defaultIfLoading(featureFlagsFromDb, {})[flag.name]?.status ?? "undefined"
                  }`}
                >
                  <WarningFilled className="text-warning-500" />
                </Tooltip>
              ) : null}
            </div>
            <Radio.Group
              className="flex"
              size="small"
              value={flag.status}
              onChange={(e) =>
                setFeatureFlags((prev) => ({
                  ...defaultIfLoading(prev, {}),
                  // eslint-disable-next-line rulesdir/no-assert
                  [flag.name]: { ...flag, status: e.target.value as FeatureFlagStatus },
                }))
              }
            >
              {featureFlagStatuses.map((s) => (
                <Radio.Button className="text-xs" value={s} key={s}>
                  {snakeCaseToTitleCase(s)}
                </Radio.Button>
              ))}
            </Radio.Group>
          </div>
        ))}
      </div>
    </Drawer>
  )
}
