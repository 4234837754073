export type LogoSize = "XLarge" | "large" | "regular" | "small"

export type LogoColor = "black" | "white" | "default"

export type LogoVariant = "default" | "iconOnly"

type LogoVariants = {
  [a in LogoColor]: {
    [b in LogoVariant]: {
      [c in LogoSize]: React.ReactNode
    }
  }
}

export const variants: LogoVariants = {
  default: {
    iconOnly: {
      XLarge: (
        <svg
          width="72"
          height="72"
          viewBox="0 0 72 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M23.8501 36.562L34.0328 56L10 56L23.8501 36.562Z" fill="#667279" />
          <path
            d="M30.9676 25.3023L48.145 55.9514L37.8572 56L25.061 33.1807L30.9676 25.3023Z"
            fill="#B9BEC0"
          />
          <path
            d="M39.2717 16L41.5705 20.278L61.7453 55.9515L51.5514 56L29.061 16H39.2717Z"
            fill="#103F5A"
          />
        </svg>
      ),
      large: (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M16.6591 24.3372L22.7687 36L8.34906 36L16.6591 24.3372Z" fill="#667279" />
          <path
            d="M20.9296 17.5814L31.2361 35.9709L25.0634 36L17.3857 22.3084L20.9296 17.5814Z"
            fill="#B9BEC0"
          />
          <path
            d="M25.9121 12L27.2914 14.5668L39.3963 35.9709L33.2799 36L19.7857 12H25.9121Z"
            fill="#103F5A"
          />
        </svg>
      ),
      regular: (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M14.3378 20.281L19.4292 30L7.41275 30L14.3378 20.281Z" fill="#667279" />
          <path
            d="M17.8966 14.6512L26.4853 29.9757L21.3414 30L14.9433 18.5904L17.8966 14.6512Z"
            fill="#B9BEC0"
          />
          <path
            d="M22.0486 10L23.198 12.139L33.2854 29.9757L28.1884 30L16.9432 10H22.0486Z"
            fill="#103F5A"
          />
        </svg>
      ),
      small: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M6.15503 10.1686L9.20984 16L2 16L6.15503 10.1686Z" fill="#667279" />
          <path
            d="M8.29029 6.7907L13.4435 15.9854L10.3572 16L6.51831 9.15422L8.29029 6.7907Z"
            fill="#B9BEC0"
          />
          <path
            d="M10.7815 4L11.4711 5.28341L17.5236 15.9854L14.4654 16L7.71829 4H10.7815Z"
            fill="#103F5A"
          />
        </svg>
      ),
    },
    default: {
      XLarge: (
        <svg
          width="298"
          height="72"
          viewBox="0 0 298 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M29.8501 36.562L40.0328 56L16 56L29.8501 36.562Z" fill="#667279" />
          <path
            d="M36.9676 25.3023L54.145 55.9514L43.8572 56L31.061 33.1807L36.9676 25.3023Z"
            fill="#B9BEC0"
          />
          <path
            d="M45.2717 16L47.5705 20.278L67.7453 55.9515L57.5514 56L35.061 16H45.2717Z"
            fill="#103F5A"
          />
          <path
            d="M92.4329 52C87.9843 52 84.9592 50.7869 83.3577 48.3607C81.7859 45.9345 81 41.9444 81 36.3904C81 30.8364 81.8007 26.8901 83.4022 24.5516C85.0037 22.1839 88.0139 21 92.4329 21C95.0724 21 97.9788 21.3654 101.152 22.0962L100.974 26.0424C98.305 25.5747 95.6358 25.3409 92.9667 25.3409C90.2975 25.3409 88.4885 26.1301 87.5394 27.7086C86.5904 29.2579 86.1159 32.1957 86.1159 36.5219C86.1159 40.819 86.5756 43.7567 87.4949 45.3352C88.4143 46.8845 90.2086 47.6591 92.8777 47.6591C95.5469 47.6591 98.2457 47.4399 100.974 47.0014L101.108 51.0354C98.0529 51.6785 95.1613 52 92.4329 52Z"
            fill="#103F5A"
          />
          <path
            d="M105.628 51.5177L113.146 21.4823H122.977L130.54 51.5177H125.558L123.912 45.0721H112.212L110.566 51.5177H105.628ZM116.972 25.5601L113.19 40.7751H122.933L119.196 25.5601H116.972Z"
            fill="#103F5A"
          />
          <path
            d="M147.903 42.1782H141.675V51.5177H136.737V21.4823H147.903C154.813 21.4823 158.268 24.8439 158.268 31.5672C158.268 35.0165 157.393 37.6473 155.643 39.4597C153.923 41.272 151.343 42.1782 147.903 42.1782ZM141.675 37.9689H147.858C151.417 37.9689 153.197 35.835 153.197 31.5672C153.197 29.521 152.767 28.0302 151.906 27.0948C151.046 26.1594 149.697 25.6917 147.858 25.6917H141.675V37.9689Z"
            fill="#103F5A"
          />
          <path
            d="M181.956 51.5177H164.874V21.4823H169.811V47.1768H181.956V51.5177Z"
            fill="#103F5A"
          />
          <path d="M187.841 51.5177V21.4823H192.778V51.5177H187.841Z" fill="#103F5A" />
          <path
            d="M214.053 40.0297V35.7327H222.727V50.9915C221.927 51.1961 220.295 51.4154 217.834 51.6492C215.402 51.8831 213.637 52 212.54 52C207.913 52 204.725 50.743 202.976 48.2291C201.226 45.7152 200.351 41.7836 200.351 36.4342C200.351 31.0556 201.241 27.1386 203.02 24.6832C204.829 22.2277 207.943 21 212.362 21C214.972 21 217.908 21.2923 221.17 21.8769L222.727 22.1839L222.549 26.0424C218.961 25.5747 215.743 25.3409 212.896 25.3409C210.049 25.3409 208.091 26.1301 207.024 27.7086C205.986 29.2871 205.467 32.2103 205.467 36.4781C205.467 40.7459 205.956 43.6983 206.935 45.3352C207.943 46.943 209.96 47.7468 212.985 47.7468C215.15 47.7468 216.766 47.6153 217.834 47.3522V40.0297H214.053Z"
            fill="#103F5A"
          />
          <path
            d="M248.883 51.5177V38.5389H235.715V51.5177H230.777V21.4823H235.715V34.2857H248.883V21.4823H253.865V51.5177H248.883Z"
            fill="#103F5A"
          />
          <path
            d="M259.757 25.8232V21.4823H282V25.8232H273.414V51.5177H268.432V25.8232H259.757Z"
            fill="#103F5A"
          />
        </svg>
      ),
      large: (
        <svg
          width="188"
          height="48"
          viewBox="0 0 188 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M20.3101 24.3372L26.4197 36L12 36L20.3101 24.3372Z" fill="#667279" />
          <path
            d="M24.5806 17.5814L34.887 35.9709L28.7143 36L21.0366 22.3085L24.5806 17.5814Z"
            fill="#B9BEC0"
          />
          <path
            d="M29.563 12L30.9423 14.5668L43.0472 35.9709L36.9308 36L23.4366 12H29.563Z"
            fill="#103F5A"
          />
          <path
            d="M57.8597 33.6C55.1906 33.6 53.3755 32.8722 52.4146 31.4164C51.4715 29.9607 51 27.5666 51 24.2342C51 20.9019 51.4804 18.5341 52.4413 17.131C53.4022 15.7103 55.2084 15 57.8597 15C59.4434 15 61.1873 15.2193 63.0913 15.6577L62.9845 18.0255C61.383 17.7449 59.7815 17.6045 58.18 17.6045C56.5785 17.6045 55.4931 18.0781 54.9237 19.0252C54.3542 19.9548 54.0695 21.7174 54.0695 24.3132C54.0695 26.8914 54.3453 28.654 54.897 29.6011C55.4486 30.5307 56.5251 30.9955 58.1266 30.9955C59.7281 30.9955 61.3474 30.8639 62.9845 30.6009L63.0646 33.0212C61.2317 33.4071 59.4968 33.6 57.8597 33.6Z"
            fill="#103F5A"
          />
          <path
            d="M65.7767 33.3106L70.2876 15.2894H76.1864L80.724 33.3106H77.7345L76.7469 29.4433H69.7271L68.7395 33.3106H65.7767ZM72.5831 17.7361L70.3143 26.8651H76.1597L73.9176 17.7361H72.5831Z"
            fill="#103F5A"
          />
          <path
            d="M91.1417 27.7069H87.4048V33.3106H84.4421V15.2894H91.1417C95.2877 15.2894 97.3608 17.3064 97.3608 21.3403C97.3608 23.4099 96.8358 24.9884 95.786 26.0758C94.7539 27.1632 93.2058 27.7069 91.1417 27.7069ZM87.4048 25.1813H91.115C93.2503 25.1813 94.3179 23.901 94.3179 21.3403C94.3179 20.1126 94.0599 19.2181 93.5439 18.6569C93.0279 18.0956 92.2182 17.815 91.115 17.815H87.4048V25.1813Z"
            fill="#103F5A"
          />
          <path
            d="M111.574 33.3106H101.324V15.2894H104.287V30.7061H111.574V33.3106Z"
            fill="#103F5A"
          />
          <path d="M115.104 33.3106V15.2894H118.067V33.3106H115.104Z" fill="#103F5A" />
          <path
            d="M130.832 26.4178V23.8396H136.036V32.9949C135.556 33.1177 134.577 33.2492 133.1 33.3895C131.641 33.5299 130.582 33.6 129.924 33.6C127.148 33.6 125.235 32.8458 124.185 31.3375C123.135 29.8292 122.611 27.4702 122.611 24.2606C122.611 21.0334 123.144 18.6832 124.212 17.2099C125.297 15.7366 127.166 15 129.817 15C131.383 15 133.145 15.1754 135.102 15.5262L136.036 15.7103L135.93 18.0255C133.776 17.7449 131.846 17.6045 130.138 17.6045C128.429 17.6045 127.255 18.0781 126.614 19.0252C125.991 19.9723 125.68 21.7262 125.68 24.2869C125.68 26.8475 125.974 28.619 126.561 29.6011C127.166 30.5658 128.376 31.0481 130.191 31.0481C131.49 31.0481 132.46 30.9692 133.1 30.8113V26.4178H130.832Z"
            fill="#103F5A"
          />
          <path
            d="M151.73 33.3106V25.5234H143.829V33.3106H140.866V15.2894H143.829V22.9714H151.73V15.2894H154.719V33.3106H151.73Z"
            fill="#103F5A"
          />
          <path
            d="M158.254 17.8939V15.2894H171.6V17.8939H166.449V33.3106H163.459V17.8939H158.254Z"
            fill="#103F5A"
          />
        </svg>
      ),
      regular: (
        <svg
          width="149"
          height="40"
          viewBox="0 0 149 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M14.925 20.281L20.0164 30L8 30L14.925 20.281Z" fill="#667279" />
          <path
            d="M18.4838 14.6512L27.0725 29.9757L21.9286 30L15.5305 18.5904L18.4838 14.6512Z"
            fill="#B9BEC0"
          />
          <path
            d="M22.6358 10L23.7852 12.139L33.8727 29.9757L28.7757 30L17.5305 10H22.6358Z"
            fill="#103F5A"
          />
          <path
            d="M46.2164 28C43.9921 28 42.4796 27.3934 41.6789 26.1803C40.893 24.9672 40.5 22.9722 40.5 20.1952C40.5 17.4182 40.9004 15.4451 41.7011 14.2758C42.5019 13.0919 44.007 12.5 46.2164 12.5C47.5362 12.5 48.9894 12.6827 50.576 13.0481L50.4871 15.0212C49.1525 14.7874 47.8179 14.6704 46.4833 14.6704C45.1488 14.6704 44.2442 15.0651 43.7697 15.8543C43.2952 16.6289 43.0579 18.0978 43.0579 20.261C43.0579 22.4095 43.2878 23.8784 43.7475 24.6676C44.2072 25.4422 45.1043 25.8296 46.4389 25.8296C47.7734 25.8296 49.1228 25.7199 50.4871 25.5007L50.5538 27.5177C49.0265 27.8392 47.5807 28 46.2164 28Z"
            fill="#103F5A"
          />
          <path
            d="M52.8139 27.7588L56.573 12.7412H61.4887L65.27 27.7588H62.7788L61.9558 24.5361H56.1059L55.2829 27.7588H52.8139ZM58.4859 14.7801L56.5952 22.3876H61.4664L59.598 14.7801H58.4859Z"
            fill="#103F5A"
          />
          <path
            d="M73.9514 23.0891H70.8374V27.7588H68.3684V12.7412H73.9514C77.4065 12.7412 79.134 14.422 79.134 17.7836C79.134 19.5083 78.6965 20.8237 77.8217 21.7298C76.9616 22.636 75.6715 23.0891 73.9514 23.0891ZM70.8374 20.9844H73.9291C75.7086 20.9844 76.5983 19.9175 76.5983 17.7836C76.5983 16.7605 76.3833 16.0151 75.9532 15.5474C75.5232 15.0797 74.8485 14.8458 73.9291 14.8458H70.8374V20.9844Z"
            fill="#103F5A"
          />
          <path
            d="M90.9781 27.7588H82.4368V12.7412H84.9057V25.5884H90.9781V27.7588Z"
            fill="#103F5A"
          />
          <path d="M93.9203 27.7588V12.7412H96.3892V27.7588H93.9203Z" fill="#103F5A" />
          <path
            d="M107.026 22.0149V19.8663H111.364V27.4958C110.963 27.5981 110.148 27.7077 108.917 27.8246C107.701 27.9415 106.819 28 106.27 28C103.957 28 102.363 27.3715 101.488 26.1146C100.613 24.8576 100.175 22.8918 100.175 20.2171C100.175 17.5278 100.62 15.5693 101.51 14.3416C102.415 13.1139 103.972 12.5 106.181 12.5C107.486 12.5 108.954 12.6462 110.585 12.9385L111.364 13.0919L111.275 15.0212C109.48 14.7874 107.871 14.6704 106.448 14.6704C105.024 14.6704 104.046 15.0651 103.512 15.8543C102.993 16.6436 102.733 18.1051 102.733 20.239C102.733 22.3729 102.978 23.8491 103.467 24.6676C103.972 25.4715 104.98 25.8734 106.492 25.8734C107.575 25.8734 108.383 25.8076 108.917 25.6761V22.0149H107.026Z"
            fill="#103F5A"
          />
          <path
            d="M124.441 27.7588V21.2694H117.857V27.7588H115.388V12.7412H117.857V19.1429H124.441V12.7412H126.933V27.7588H124.441Z"
            fill="#103F5A"
          />
          <path
            d="M129.879 14.9116V12.7412H141V14.9116H136.707V27.7588H134.216V14.9116H129.879Z"
            fill="#103F5A"
          />
        </svg>
      ),
      small: (
        <svg
          width="75"
          height="20"
          viewBox="0 0 75 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M7.46252 10.1405L10.0082 15L4 15L7.46252 10.1405Z" fill="#667279" />
          <path
            d="M9.24191 7.32558L13.5363 14.9879L10.9643 15L7.76526 9.29518L9.24191 7.32558Z"
            fill="#B9BEC0"
          />
          <path
            d="M11.3179 5L11.8926 6.0695L16.9363 14.9879L14.3879 15L8.76526 5H11.3179Z"
            fill="#103F5A"
          />
          <path
            d="M23.1082 14C21.9961 14 21.2398 13.6967 20.8394 13.0902C20.4465 12.4836 20.25 11.4861 20.25 10.0976C20.25 8.7091 20.4502 7.72254 20.8506 7.13791C21.2509 6.54597 22.0035 6.25 23.1082 6.25C23.7681 6.25 24.4947 6.34135 25.288 6.52405L25.2435 7.51061C24.5762 7.39368 23.909 7.33522 23.2417 7.33522C22.5744 7.33522 22.1221 7.53253 21.8849 7.92716C21.6476 8.31447 21.529 9.04892 21.529 10.1305C21.529 11.2047 21.6439 11.9392 21.8737 12.3338C22.1036 12.7211 22.5521 12.9148 23.2194 12.9148C23.8867 12.9148 24.5614 12.86 25.2435 12.7504L25.2769 13.7588C24.5132 13.9196 23.7903 14 23.1082 14Z"
            fill="#103F5A"
          />
          <path
            d="M26.407 13.8794L28.2865 6.37058H30.7443L32.635 13.8794H31.3894L30.9779 12.268H28.053L27.6415 13.8794H26.407ZM29.2429 7.39003L28.2976 11.1938H30.7332L29.799 7.39003H29.2429Z"
            fill="#103F5A"
          />
          <path
            d="M36.9757 11.5446H35.4187V13.8794H34.1842V6.37058H36.9757C38.7032 6.37058 39.567 7.21099 39.567 8.8918C39.567 9.75413 39.3483 10.4118 38.9108 10.8649C38.4808 11.318 37.8358 11.5446 36.9757 11.5446ZM35.4187 10.4922H36.9646C37.8543 10.4922 38.2991 9.95875 38.2991 8.8918C38.2991 8.38024 38.1916 8.00754 37.9766 7.77369C37.7616 7.53984 37.4243 7.42291 36.9646 7.42291H35.4187V10.4922Z"
            fill="#103F5A"
          />
          <path
            d="M45.489 13.8794H41.2184V6.37058H42.4529V12.7942H45.489V13.8794Z"
            fill="#103F5A"
          />
          <path d="M46.9601 13.8794V6.37058H48.1946V13.8794H46.9601Z" fill="#103F5A" />
          <path
            d="M53.5131 11.0074V9.93317H55.6818V13.7479C55.4816 13.799 55.0738 13.8538 54.4585 13.9123C53.8505 13.9708 53.4093 14 53.135 14C51.9784 14 51.1813 13.6858 50.7439 13.0573C50.3064 12.4288 50.0877 11.4459 50.0877 10.1086C50.0877 8.76391 50.3101 7.78465 50.755 7.17079C51.2073 6.55693 51.9858 6.25 53.0905 6.25C53.743 6.25 54.477 6.32308 55.2926 6.46924L55.6818 6.54597L55.6373 7.51061C54.7402 7.39368 53.9357 7.33522 53.224 7.33522C52.5122 7.33522 52.0229 7.53253 51.7559 7.92716C51.4964 8.32178 51.3667 9.05257 51.3667 10.1195C51.3667 11.1865 51.489 11.9246 51.7337 12.3338C51.9858 12.7357 52.49 12.9367 53.2462 12.9367C53.7875 12.9367 54.1915 12.9038 54.4585 12.838V11.0074H53.5131Z"
            fill="#103F5A"
          />
          <path
            d="M62.2207 13.8794V10.6347H58.9287V13.8794H57.6942V6.37058H58.9287V9.57143H62.2207V6.37058H63.4663V13.8794H62.2207Z"
            fill="#103F5A"
          />
          <path
            d="M64.9393 7.4558V6.37058H70.5V7.4558H68.3536V13.8794H67.108V7.4558H64.9393Z"
            fill="#103F5A"
          />
        </svg>
      ),
    },
  },
  white: {
    iconOnly: {
      XLarge: (
        <svg
          width="72"
          height="72"
          viewBox="0 0 72 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M23.8501 36.562L34.0328 56L10 56L23.8501 36.562Z" fill="white" />
          <path
            d="M30.9676 25.3023L48.145 55.9514L37.8572 56L25.061 33.1807L30.9676 25.3023Z"
            fill="white"
          />
          <path
            d="M39.2717 16L41.5705 20.278L61.7453 55.9515L51.5514 56L29.061 16H39.2717Z"
            fill="white"
          />
        </svg>
      ),
      large: (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M16.6591 24.3372L22.7687 36L8.34906 36L16.6591 24.3372Z" fill="white" />
          <path
            d="M20.9296 17.5814L31.2361 35.9709L25.0634 36L17.3857 22.3084L20.9296 17.5814Z"
            fill="white"
          />
          <path
            d="M25.9121 12L27.2914 14.5668L39.3963 35.9709L33.2799 36L19.7857 12H25.9121Z"
            fill="white"
          />
        </svg>
      ),
      regular: (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M14.3378 20.281L19.4292 30L7.41275 30L14.3378 20.281Z" fill="white" />
          <path
            d="M17.8966 14.6512L26.4853 29.9757L21.3414 30L14.9433 18.5904L17.8966 14.6512Z"
            fill="white"
          />
          <path
            d="M22.0486 10L23.198 12.139L33.2854 29.9757L28.1884 30L16.9432 10H22.0486Z"
            fill="white"
          />
        </svg>
      ),
      small: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M6.15503 10.1686L9.20984 16L2 16L6.15503 10.1686Z" fill="white" />
          <path
            d="M8.29029 6.7907L13.4435 15.9854L10.3572 16L6.51831 9.15422L8.29029 6.7907Z"
            fill="white"
          />
          <path
            d="M10.7815 4L11.4711 5.28341L17.5236 15.9854L14.4654 16L7.71829 4H10.7815Z"
            fill="white"
          />
        </svg>
      ),
    },
    default: {
      XLarge: (
        <svg
          width="298"
          height="72"
          viewBox="0 0 298 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M29.8501 36.562L40.0328 56L16 56L29.8501 36.562Z" fill="white" />
          <path
            d="M36.9676 25.3023L54.145 55.9514L43.8572 56L31.061 33.1807L36.9676 25.3023Z"
            fill="white"
          />
          <path
            d="M45.2717 16L47.5705 20.278L67.7453 55.9515L57.5514 56L35.061 16H45.2717Z"
            fill="white"
          />
          <path
            d="M92.4329 52C87.9843 52 84.9592 50.7869 83.3577 48.3607C81.7859 45.9345 81 41.9444 81 36.3904C81 30.8364 81.8007 26.8901 83.4022 24.5516C85.0037 22.1839 88.0139 21 92.4329 21C95.0724 21 97.9788 21.3654 101.152 22.0962L100.974 26.0424C98.305 25.5747 95.6358 25.3409 92.9667 25.3409C90.2975 25.3409 88.4885 26.1301 87.5394 27.7086C86.5904 29.2579 86.1159 32.1957 86.1159 36.5219C86.1159 40.819 86.5756 43.7567 87.4949 45.3352C88.4143 46.8845 90.2086 47.6591 92.8777 47.6591C95.5469 47.6591 98.2457 47.4399 100.974 47.0014L101.108 51.0354C98.0529 51.6785 95.1613 52 92.4329 52Z"
            fill="white"
          />
          <path
            d="M105.628 51.5177L113.146 21.4823H122.977L130.54 51.5177H125.558L123.912 45.0721H112.212L110.566 51.5177H105.628ZM116.972 25.5601L113.19 40.7751H122.933L119.196 25.5601H116.972Z"
            fill="white"
          />
          <path
            d="M147.903 42.1782H141.675V51.5177H136.737V21.4823H147.903C154.813 21.4823 158.268 24.8439 158.268 31.5672C158.268 35.0165 157.393 37.6473 155.643 39.4597C153.923 41.272 151.343 42.1782 147.903 42.1782ZM141.675 37.9689H147.858C151.417 37.9689 153.197 35.835 153.197 31.5672C153.197 29.521 152.767 28.0302 151.906 27.0948C151.046 26.1594 149.697 25.6917 147.858 25.6917H141.675V37.9689Z"
            fill="white"
          />
          <path
            d="M181.956 51.5177H164.874V21.4823H169.811V47.1768H181.956V51.5177Z"
            fill="white"
          />
          <path d="M187.841 51.5177V21.4823H192.778V51.5177H187.841Z" fill="white" />
          <path
            d="M214.053 40.0297V35.7327H222.727V50.9915C221.927 51.1961 220.295 51.4154 217.834 51.6492C215.402 51.8831 213.637 52 212.54 52C207.913 52 204.725 50.743 202.976 48.2291C201.226 45.7152 200.351 41.7836 200.351 36.4342C200.351 31.0556 201.241 27.1386 203.02 24.6832C204.829 22.2277 207.943 21 212.362 21C214.972 21 217.908 21.2923 221.17 21.8769L222.727 22.1839L222.549 26.0424C218.961 25.5747 215.743 25.3409 212.896 25.3409C210.049 25.3409 208.091 26.1301 207.024 27.7086C205.986 29.2871 205.467 32.2103 205.467 36.4781C205.467 40.7459 205.956 43.6983 206.935 45.3352C207.943 46.943 209.96 47.7468 212.985 47.7468C215.15 47.7468 216.766 47.6153 217.834 47.3522V40.0297H214.053Z"
            fill="white"
          />
          <path
            d="M248.883 51.5177V38.5389H235.715V51.5177H230.777V21.4823H235.715V34.2857H248.883V21.4823H253.865V51.5177H248.883Z"
            fill="white"
          />
          <path
            d="M259.757 25.8232V21.4823H282V25.8232H273.414V51.5177H268.432V25.8232H259.757Z"
            fill="white"
          />
        </svg>
      ),
      large: (
        <svg
          width="188"
          height="48"
          viewBox="0 0 188 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M20.3101 24.3372L26.4197 36L12 36L20.3101 24.3372Z" fill="white" />
          <path
            d="M24.5806 17.5814L34.887 35.9709L28.7143 36L21.0366 22.3085L24.5806 17.5814Z"
            fill="white"
          />
          <path
            d="M29.563 12L30.9423 14.5668L43.0472 35.9709L36.9308 36L23.4366 12H29.563Z"
            fill="white"
          />
          <path
            d="M57.8597 33.6C55.1906 33.6 53.3755 32.8722 52.4146 31.4164C51.4715 29.9607 51 27.5666 51 24.2342C51 20.9019 51.4804 18.5341 52.4413 17.131C53.4022 15.7103 55.2084 15 57.8597 15C59.4434 15 61.1873 15.2193 63.0913 15.6577L62.9845 18.0255C61.383 17.7449 59.7815 17.6045 58.18 17.6045C56.5785 17.6045 55.4931 18.0781 54.9237 19.0252C54.3542 19.9548 54.0695 21.7174 54.0695 24.3132C54.0695 26.8914 54.3453 28.654 54.897 29.6011C55.4486 30.5307 56.5251 30.9955 58.1266 30.9955C59.7281 30.9955 61.3474 30.8639 62.9845 30.6009L63.0646 33.0212C61.2317 33.4071 59.4968 33.6 57.8597 33.6Z"
            fill="white"
          />
          <path
            d="M65.7767 33.3106L70.2876 15.2894H76.1864L80.724 33.3106H77.7345L76.7469 29.4433H69.7271L68.7395 33.3106H65.7767ZM72.5831 17.7361L70.3143 26.8651H76.1597L73.9176 17.7361H72.5831Z"
            fill="white"
          />
          <path
            d="M91.1417 27.7069H87.4048V33.3106H84.4421V15.2894H91.1417C95.2877 15.2894 97.3608 17.3064 97.3608 21.3403C97.3608 23.4099 96.8358 24.9884 95.786 26.0758C94.7539 27.1632 93.2058 27.7069 91.1417 27.7069ZM87.4048 25.1813H91.115C93.2503 25.1813 94.3179 23.901 94.3179 21.3403C94.3179 20.1126 94.0599 19.2181 93.5439 18.6569C93.0279 18.0956 92.2182 17.815 91.115 17.815H87.4048V25.1813Z"
            fill="white"
          />
          <path
            d="M111.574 33.3106H101.324V15.2894H104.287V30.7061H111.574V33.3106Z"
            fill="white"
          />
          <path d="M115.104 33.3106V15.2894H118.067V33.3106H115.104Z" fill="white" />
          <path
            d="M130.832 26.4178V23.8396H136.036V32.9949C135.556 33.1177 134.577 33.2492 133.1 33.3895C131.641 33.5299 130.582 33.6 129.924 33.6C127.148 33.6 125.235 32.8458 124.185 31.3375C123.135 29.8292 122.611 27.4702 122.611 24.2606C122.611 21.0334 123.144 18.6832 124.212 17.2099C125.297 15.7366 127.166 15 129.817 15C131.383 15 133.145 15.1754 135.102 15.5262L136.036 15.7103L135.93 18.0255C133.776 17.7449 131.846 17.6045 130.138 17.6045C128.429 17.6045 127.255 18.0781 126.614 19.0252C125.991 19.9723 125.68 21.7262 125.68 24.2869C125.68 26.8475 125.974 28.619 126.561 29.6011C127.166 30.5658 128.376 31.0481 130.191 31.0481C131.49 31.0481 132.46 30.9692 133.1 30.8113V26.4178H130.832Z"
            fill="white"
          />
          <path
            d="M151.73 33.3106V25.5234H143.829V33.3106H140.866V15.2894H143.829V22.9714H151.73V15.2894H154.719V33.3106H151.73Z"
            fill="white"
          />
          <path
            d="M158.254 17.8939V15.2894H171.6V17.8939H166.449V33.3106H163.459V17.8939H158.254Z"
            fill="white"
          />
        </svg>
      ),
      regular: (
        <svg
          width="149"
          height="40"
          viewBox="0 0 149 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M14.925 20.281L20.0164 30L8 30L14.925 20.281Z" fill="white" />
          <path
            d="M18.4838 14.6512L27.0725 29.9757L21.9286 30L15.5305 18.5904L18.4838 14.6512Z"
            fill="white"
          />
          <path
            d="M22.6358 10L23.7852 12.139L33.8727 29.9757L28.7757 30L17.5305 10H22.6358Z"
            fill="white"
          />
          <path
            d="M46.2164 28C43.9921 28 42.4796 27.3934 41.6789 26.1803C40.893 24.9672 40.5 22.9722 40.5 20.1952C40.5 17.4182 40.9004 15.4451 41.7011 14.2758C42.5019 13.0919 44.007 12.5 46.2164 12.5C47.5362 12.5 48.9894 12.6827 50.576 13.0481L50.4871 15.0212C49.1525 14.7874 47.8179 14.6704 46.4833 14.6704C45.1488 14.6704 44.2442 15.0651 43.7697 15.8543C43.2952 16.6289 43.0579 18.0978 43.0579 20.261C43.0579 22.4095 43.2878 23.8784 43.7475 24.6676C44.2072 25.4422 45.1043 25.8296 46.4389 25.8296C47.7734 25.8296 49.1228 25.7199 50.4871 25.5007L50.5538 27.5177C49.0265 27.8392 47.5807 28 46.2164 28Z"
            fill="white"
          />
          <path
            d="M52.8139 27.7588L56.573 12.7412H61.4887L65.27 27.7588H62.7788L61.9558 24.5361H56.1059L55.2829 27.7588H52.8139ZM58.4859 14.7801L56.5952 22.3876H61.4664L59.598 14.7801H58.4859Z"
            fill="white"
          />
          <path
            d="M73.9514 23.0891H70.8374V27.7588H68.3684V12.7412H73.9514C77.4065 12.7412 79.134 14.422 79.134 17.7836C79.134 19.5083 78.6965 20.8237 77.8217 21.7298C76.9616 22.636 75.6715 23.0891 73.9514 23.0891ZM70.8374 20.9844H73.9291C75.7086 20.9844 76.5983 19.9175 76.5983 17.7836C76.5983 16.7605 76.3833 16.0151 75.9532 15.5474C75.5232 15.0797 74.8485 14.8458 73.9291 14.8458H70.8374V20.9844Z"
            fill="white"
          />
          <path
            d="M90.9781 27.7588H82.4368V12.7412H84.9057V25.5884H90.9781V27.7588Z"
            fill="white"
          />
          <path d="M93.9203 27.7588V12.7412H96.3892V27.7588H93.9203Z" fill="white" />
          <path
            d="M107.026 22.0149V19.8663H111.364V27.4958C110.963 27.5981 110.148 27.7077 108.917 27.8246C107.701 27.9415 106.819 28 106.27 28C103.957 28 102.363 27.3715 101.488 26.1146C100.613 24.8576 100.175 22.8918 100.175 20.2171C100.175 17.5278 100.62 15.5693 101.51 14.3416C102.415 13.1139 103.972 12.5 106.181 12.5C107.486 12.5 108.954 12.6462 110.585 12.9385L111.364 13.0919L111.275 15.0212C109.48 14.7874 107.871 14.6704 106.448 14.6704C105.024 14.6704 104.046 15.0651 103.512 15.8543C102.993 16.6436 102.733 18.1051 102.733 20.239C102.733 22.3729 102.978 23.8491 103.467 24.6676C103.972 25.4715 104.98 25.8734 106.492 25.8734C107.575 25.8734 108.383 25.8076 108.917 25.6761V22.0149H107.026Z"
            fill="white"
          />
          <path
            d="M124.441 27.7588V21.2694H117.857V27.7588H115.388V12.7412H117.857V19.1429H124.441V12.7412H126.933V27.7588H124.441Z"
            fill="white"
          />
          <path
            d="M129.879 14.9116V12.7412H141V14.9116H136.707V27.7588H134.216V14.9116H129.879Z"
            fill="white"
          />
        </svg>
      ),
      small: (
        <svg
          width="75"
          height="20"
          viewBox="0 0 75 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M7.46252 10.1405L10.0082 15L4 15L7.46252 10.1405Z" fill="white" />
          <path
            d="M9.24191 7.32558L13.5363 14.9879L10.9643 15L7.76526 9.29518L9.24191 7.32558Z"
            fill="white"
          />
          <path
            d="M11.3179 5L11.8926 6.0695L16.9363 14.9879L14.3879 15L8.76526 5H11.3179Z"
            fill="white"
          />
          <path
            d="M23.1082 14C21.9961 14 21.2398 13.6967 20.8394 13.0902C20.4465 12.4836 20.25 11.4861 20.25 10.0976C20.25 8.7091 20.4502 7.72254 20.8506 7.13791C21.2509 6.54597 22.0035 6.25 23.1082 6.25C23.7681 6.25 24.4947 6.34135 25.288 6.52405L25.2435 7.51061C24.5762 7.39368 23.909 7.33522 23.2417 7.33522C22.5744 7.33522 22.1221 7.53253 21.8849 7.92716C21.6476 8.31447 21.529 9.04892 21.529 10.1305C21.529 11.2047 21.6439 11.9392 21.8737 12.3338C22.1036 12.7211 22.5521 12.9148 23.2194 12.9148C23.8867 12.9148 24.5614 12.86 25.2435 12.7504L25.2769 13.7588C24.5132 13.9196 23.7903 14 23.1082 14Z"
            fill="white"
          />
          <path
            d="M26.407 13.8794L28.2865 6.37058H30.7443L32.635 13.8794H31.3894L30.9779 12.268H28.053L27.6415 13.8794H26.407ZM29.2429 7.39003L28.2976 11.1938H30.7332L29.799 7.39003H29.2429Z"
            fill="white"
          />
          <path
            d="M36.9757 11.5446H35.4187V13.8794H34.1842V6.37058H36.9757C38.7032 6.37058 39.567 7.21099 39.567 8.8918C39.567 9.75413 39.3483 10.4118 38.9108 10.8649C38.4808 11.318 37.8358 11.5446 36.9757 11.5446ZM35.4187 10.4922H36.9646C37.8543 10.4922 38.2991 9.95875 38.2991 8.8918C38.2991 8.38024 38.1916 8.00754 37.9766 7.77369C37.7616 7.53984 37.4243 7.42291 36.9646 7.42291H35.4187V10.4922Z"
            fill="white"
          />
          <path d="M45.489 13.8794H41.2184V6.37058H42.4529V12.7942H45.489V13.8794Z" fill="white" />
          <path d="M46.9601 13.8794V6.37058H48.1946V13.8794H46.9601Z" fill="white" />
          <path
            d="M53.5131 11.0074V9.93317H55.6818V13.7479C55.4816 13.799 55.0738 13.8538 54.4585 13.9123C53.8505 13.9708 53.4093 14 53.135 14C51.9784 14 51.1813 13.6858 50.7439 13.0573C50.3064 12.4288 50.0877 11.4459 50.0877 10.1086C50.0877 8.76391 50.3101 7.78465 50.755 7.17079C51.2073 6.55693 51.9858 6.25 53.0905 6.25C53.743 6.25 54.477 6.32308 55.2926 6.46924L55.6818 6.54597L55.6373 7.51061C54.7402 7.39368 53.9357 7.33522 53.224 7.33522C52.5122 7.33522 52.0229 7.53253 51.7559 7.92716C51.4964 8.32178 51.3667 9.05257 51.3667 10.1195C51.3667 11.1865 51.489 11.9246 51.7337 12.3338C51.9858 12.7357 52.49 12.9367 53.2462 12.9367C53.7875 12.9367 54.1915 12.9038 54.4585 12.838V11.0074H53.5131Z"
            fill="white"
          />
          <path
            d="M62.2207 13.8794V10.6347H58.9287V13.8794H57.6942V6.37058H58.9287V9.57143H62.2207V6.37058H63.4663V13.8794H62.2207Z"
            fill="white"
          />
          <path
            d="M64.9393 7.4558V6.37058H70.5V7.4558H68.3536V13.8794H67.108V7.4558H64.9393Z"
            fill="white"
          />
        </svg>
      ),
    },
  },
  black: {
    iconOnly: {
      XLarge: (
        <svg
          width="72"
          height="72"
          viewBox="0 0 72 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M23.8501 36.562L34.0328 56L10 56L23.8501 36.562Z" fill="#0A0B0D" />
          <path
            d="M30.9676 25.3023L48.145 55.9514L37.8572 56L25.061 33.1807L30.9676 25.3023Z"
            fill="#0A0B0D"
          />
          <path
            d="M39.2717 16L41.5705 20.278L61.7453 55.9515L51.5514 56L29.061 16H39.2717Z"
            fill="#0A0B0D"
          />
        </svg>
      ),
      large: (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M16.6591 24.3372L22.7687 36L8.34906 36L16.6591 24.3372Z" fill="#0A0B0D" />
          <path
            d="M20.9296 17.5814L31.2361 35.9709L25.0634 36L17.3857 22.3084L20.9296 17.5814Z"
            fill="#0A0B0D"
          />
          <path
            d="M25.9121 12L27.2914 14.5668L39.3963 35.9709L33.2799 36L19.7857 12H25.9121Z"
            fill="#0A0B0D"
          />
        </svg>
      ),
      regular: (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M14.3378 20.281L19.4292 30L7.41275 30L14.3378 20.281Z" fill="#0A0B0D" />
          <path
            d="M17.8966 14.6512L26.4853 29.9757L21.3414 30L14.9433 18.5904L17.8966 14.6512Z"
            fill="#0A0B0D"
          />
          <path
            d="M22.0486 10L23.198 12.139L33.2854 29.9757L28.1884 30L16.9432 10H22.0486Z"
            fill="#0A0B0D"
          />
        </svg>
      ),
      small: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M6.15503 10.1686L9.20984 16L2 16L6.15503 10.1686Z" fill="#0A0B0D" />
          <path
            d="M8.29029 6.7907L13.4435 15.9854L10.3572 16L6.51831 9.15422L8.29029 6.7907Z"
            fill="#0A0B0D"
          />
          <path
            d="M10.7815 4L11.4711 5.28341L17.5236 15.9854L14.4654 16L7.71829 4H10.7815Z"
            fill="#0A0B0D"
          />
        </svg>
      ),
    },
    default: {
      XLarge: (
        <svg
          width="298"
          height="72"
          viewBox="0 0 298 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M29.8501 36.562L40.0328 56L16 56L29.8501 36.562Z" fill="#0A0B0D" />
          <path
            d="M36.9676 25.3023L54.145 55.9514L43.8572 56L31.061 33.1807L36.9676 25.3023Z"
            fill="#0A0B0D"
          />
          <path
            d="M45.2717 16L47.5704 20.278L67.7453 55.9515L57.5514 56L35.061 16H45.2717Z"
            fill="#0A0B0D"
          />
          <path
            d="M92.4329 52C87.9843 52 84.9592 50.7869 83.3577 48.3607C81.7859 45.9345 81 41.9444 81 36.3904C81 30.8364 81.8007 26.8901 83.4022 24.5516C85.0037 22.1839 88.0139 21 92.4329 21C95.0724 21 97.9788 21.3654 101.152 22.0962L100.974 26.0424C98.305 25.5747 95.6358 25.3409 92.9667 25.3409C90.2975 25.3409 88.4885 26.1301 87.5394 27.7086C86.5904 29.2579 86.1159 32.1957 86.1159 36.5219C86.1159 40.819 86.5756 43.7567 87.4949 45.3352C88.4143 46.8845 90.2086 47.6591 92.8777 47.6591C95.5469 47.6591 98.2457 47.4399 100.974 47.0014L101.108 51.0354C98.0529 51.6785 95.1613 52 92.4329 52Z"
            fill="#0A0B0D"
          />
          <path
            d="M105.628 51.5177L113.146 21.4823H122.977L130.54 51.5177H125.558L123.912 45.0721H112.212L110.566 51.5177H105.628ZM116.972 25.5601L113.19 40.7751H122.933L119.196 25.5601H116.972Z"
            fill="#0A0B0D"
          />
          <path
            d="M147.903 42.1782H141.675V51.5177H136.737V21.4823H147.903C154.813 21.4823 158.268 24.8439 158.268 31.5672C158.268 35.0165 157.393 37.6473 155.643 39.4597C153.923 41.272 151.343 42.1782 147.903 42.1782ZM141.675 37.9689H147.858C151.417 37.9689 153.197 35.835 153.197 31.5672C153.197 29.521 152.767 28.0302 151.906 27.0948C151.046 26.1594 149.697 25.6917 147.858 25.6917H141.675V37.9689Z"
            fill="#0A0B0D"
          />
          <path
            d="M181.956 51.5177H164.874V21.4823H169.811V47.1768H181.956V51.5177Z"
            fill="#0A0B0D"
          />
          <path d="M187.841 51.5177V21.4823H192.778V51.5177H187.841Z" fill="#0A0B0D" />
          <path
            d="M214.053 40.0297V35.7327H222.727V50.9915C221.927 51.1961 220.295 51.4154 217.834 51.6492C215.402 51.8831 213.637 52 212.54 52C207.913 52 204.725 50.743 202.976 48.2291C201.226 45.7152 200.351 41.7836 200.351 36.4342C200.351 31.0556 201.241 27.1386 203.02 24.6832C204.829 22.2277 207.943 21 212.362 21C214.972 21 217.908 21.2923 221.17 21.8769L222.727 22.1839L222.549 26.0424C218.961 25.5747 215.743 25.3409 212.896 25.3409C210.049 25.3409 208.091 26.1301 207.024 27.7086C205.986 29.2871 205.467 32.2103 205.467 36.4781C205.467 40.7459 205.956 43.6983 206.935 45.3352C207.943 46.943 209.96 47.7468 212.985 47.7468C215.15 47.7468 216.766 47.6153 217.834 47.3522V40.0297H214.053Z"
            fill="#0A0B0D"
          />
          <path
            d="M248.883 51.5177V38.5389H235.715V51.5177H230.777V21.4823H235.715V34.2857H248.883V21.4823H253.865V51.5177H248.883Z"
            fill="#0A0B0D"
          />
          <path
            d="M259.757 25.8232V21.4823H282V25.8232H273.414V51.5177H268.432V25.8232H259.757Z"
            fill="#0A0B0D"
          />
        </svg>
      ),
      large: (
        <svg
          width="188"
          height="48"
          viewBox="0 0 188 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M20.3101 24.3372L26.4197 36L12 36L20.3101 24.3372Z" fill="#0A0B0D" />
          <path
            d="M24.5806 17.5814L34.887 35.9709L28.7144 36L21.0366 22.3085L24.5806 17.5814Z"
            fill="#0A0B0D"
          />
          <path
            d="M29.563 12L30.9423 14.5668L43.0472 35.9709L36.9308 36L23.4366 12H29.563Z"
            fill="#0A0B0D"
          />
          <path
            d="M57.8597 33.6C55.1906 33.6 53.3755 32.8722 52.4146 31.4164C51.4715 29.9607 51 27.5666 51 24.2342C51 20.9019 51.4804 18.5341 52.4413 17.131C53.4022 15.7103 55.2084 15 57.8597 15C59.4434 15 61.1873 15.2193 63.0913 15.6577L62.9845 18.0255C61.383 17.7449 59.7815 17.6045 58.18 17.6045C56.5785 17.6045 55.4931 18.0781 54.9237 19.0252C54.3542 19.9548 54.0695 21.7174 54.0695 24.3132C54.0695 26.8914 54.3453 28.654 54.897 29.6011C55.4486 30.5307 56.5251 30.9955 58.1266 30.9955C59.7281 30.9955 61.3474 30.8639 62.9845 30.6009L63.0646 33.0212C61.2317 33.4071 59.4968 33.6 57.8597 33.6Z"
            fill="#0A0B0D"
          />
          <path
            d="M65.7767 33.3106L70.2876 15.2894H76.1864L80.724 33.3106H77.7345L76.7469 29.4433H69.7271L68.7395 33.3106H65.7767ZM72.5831 17.7361L70.3143 26.8651H76.1597L73.9176 17.7361H72.5831Z"
            fill="#0A0B0D"
          />
          <path
            d="M91.1417 27.7069H87.4048V33.3106H84.4421V15.2894H91.1417C95.2877 15.2894 97.3608 17.3064 97.3608 21.3403C97.3608 23.4099 96.8358 24.9884 95.786 26.0758C94.7539 27.1632 93.2058 27.7069 91.1417 27.7069ZM87.4048 25.1813H91.115C93.2503 25.1813 94.3179 23.901 94.3179 21.3403C94.3179 20.1126 94.0599 19.2181 93.5439 18.6569C93.0279 18.0956 92.2182 17.815 91.115 17.815H87.4048V25.1813Z"
            fill="#0A0B0D"
          />
          <path
            d="M111.574 33.3106H101.324V15.2894H104.287V30.7061H111.574V33.3106Z"
            fill="#0A0B0D"
          />
          <path d="M115.104 33.3106V15.2894H118.067V33.3106H115.104Z" fill="#0A0B0D" />
          <path
            d="M130.832 26.4178V23.8396H136.036V32.9949C135.556 33.1177 134.577 33.2492 133.1 33.3895C131.641 33.5299 130.582 33.6 129.924 33.6C127.148 33.6 125.235 32.8458 124.185 31.3375C123.135 29.8292 122.611 27.4702 122.611 24.2606C122.611 21.0334 123.144 18.6832 124.212 17.2099C125.297 15.7366 127.166 15 129.817 15C131.383 15 133.145 15.1754 135.102 15.5262L136.036 15.7103L135.93 18.0255C133.776 17.7449 131.846 17.6045 130.138 17.6045C128.429 17.6045 127.255 18.0781 126.614 19.0252C125.991 19.9723 125.68 21.7262 125.68 24.2869C125.68 26.8475 125.974 28.619 126.561 29.6011C127.166 30.5658 128.376 31.0481 130.191 31.0481C131.49 31.0481 132.46 30.9692 133.1 30.8113V26.4178H130.832Z"
            fill="#0A0B0D"
          />
          <path
            d="M151.73 33.3106V25.5234H143.829V33.3106H140.866V15.2894H143.829V22.9714H151.73V15.2894H154.719V33.3106H151.73Z"
            fill="#0A0B0D"
          />
          <path
            d="M158.254 17.8939V15.2894H171.6V17.8939H166.449V33.3106H163.459V17.8939H158.254Z"
            fill="#0A0B0D"
          />
        </svg>
      ),
      regular: (
        <svg
          width="149"
          height="40"
          viewBox="0 0 149 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M14.925 20.281L20.0164 30L8 30L14.925 20.281Z" fill="#0A0B0D" />
          <path
            d="M18.4838 14.6512L27.0725 29.9757L21.9286 30L15.5305 18.5904L18.4838 14.6512Z"
            fill="#0A0B0D"
          />
          <path
            d="M22.6358 10L23.7852 12.139L33.8726 29.9757L28.7757 30L17.5305 10H22.6358Z"
            fill="#0A0B0D"
          />
          <path
            d="M46.2164 28C43.9921 28 42.4796 27.3934 41.6789 26.1803C40.893 24.9672 40.5 22.9722 40.5 20.1952C40.5 17.4182 40.9004 15.4451 41.7011 14.2758C42.5019 13.0919 44.007 12.5 46.2164 12.5C47.5362 12.5 48.9894 12.6827 50.576 13.0481L50.4871 15.0212C49.1525 14.7874 47.8179 14.6704 46.4833 14.6704C45.1488 14.6704 44.2442 15.0651 43.7697 15.8543C43.2952 16.6289 43.0579 18.0978 43.0579 20.261C43.0579 22.4095 43.2878 23.8784 43.7475 24.6676C44.2072 25.4422 45.1043 25.8296 46.4389 25.8296C47.7734 25.8296 49.1228 25.7199 50.4871 25.5007L50.5538 27.5177C49.0265 27.8392 47.5807 28 46.2164 28Z"
            fill="#0A0B0D"
          />
          <path
            d="M52.8139 27.7588L56.573 12.7412H61.4887L65.27 27.7588H62.7788L61.9558 24.5361H56.1059L55.2829 27.7588H52.8139ZM58.4859 14.7801L56.5952 22.3876H61.4664L59.598 14.7801H58.4859Z"
            fill="#0A0B0D"
          />
          <path
            d="M73.9514 23.0891H70.8374V27.7588H68.3684V12.7412H73.9514C77.4065 12.7412 79.134 14.422 79.134 17.7836C79.134 19.5083 78.6965 20.8237 77.8217 21.7298C76.9616 22.636 75.6715 23.0891 73.9514 23.0891ZM70.8374 20.9844H73.9291C75.7086 20.9844 76.5983 19.9175 76.5983 17.7836C76.5983 16.7605 76.3833 16.0151 75.9532 15.5474C75.5232 15.0797 74.8485 14.8458 73.9291 14.8458H70.8374V20.9844Z"
            fill="#0A0B0D"
          />
          <path
            d="M90.9781 27.7588H82.4368V12.7412H84.9057V25.5884H90.9781V27.7588Z"
            fill="#0A0B0D"
          />
          <path d="M93.9203 27.7588V12.7412H96.3892V27.7588H93.9203Z" fill="#0A0B0D" />
          <path
            d="M107.026 22.0149V19.8663H111.364V27.4958C110.963 27.5981 110.148 27.7077 108.917 27.8246C107.701 27.9415 106.819 28 106.27 28C103.957 28 102.363 27.3715 101.488 26.1146C100.613 24.8576 100.175 22.8918 100.175 20.2171C100.175 17.5278 100.62 15.5693 101.51 14.3416C102.415 13.1139 103.972 12.5 106.181 12.5C107.486 12.5 108.954 12.6462 110.585 12.9385L111.364 13.0919L111.275 15.0212C109.48 14.7874 107.871 14.6704 106.448 14.6704C105.024 14.6704 104.046 15.0651 103.512 15.8543C102.993 16.6436 102.733 18.1051 102.733 20.239C102.733 22.3729 102.978 23.8491 103.467 24.6676C103.972 25.4715 104.98 25.8734 106.492 25.8734C107.575 25.8734 108.383 25.8076 108.917 25.6761V22.0149H107.026Z"
            fill="#0A0B0D"
          />
          <path
            d="M124.441 27.7588V21.2694H117.857V27.7588H115.388V12.7412H117.857V19.1429H124.441V12.7412H126.933V27.7588H124.441Z"
            fill="#0A0B0D"
          />
          <path
            d="M129.879 14.9116V12.7412H141V14.9116H136.707V27.7588H134.216V14.9116H129.879Z"
            fill="#0A0B0D"
          />
        </svg>
      ),
      small: (
        <svg
          width="75"
          height="20"
          viewBox="0 0 75 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M7.46252 10.1405L10.0082 15L4 15L7.46252 10.1405Z" fill="#0A0B0D" />
          <path
            d="M9.24191 7.32558L13.5363 14.9879L10.9643 15L7.76526 9.29518L9.24191 7.32558Z"
            fill="#0A0B0D"
          />
          <path
            d="M11.3179 5L11.8926 6.0695L16.9363 14.9879L14.3879 15L8.76526 5H11.3179Z"
            fill="#0A0B0D"
          />
          <path
            d="M23.1082 14C21.9961 14 21.2398 13.6967 20.8394 13.0902C20.4465 12.4836 20.25 11.4861 20.25 10.0976C20.25 8.7091 20.4502 7.72254 20.8506 7.13791C21.2509 6.54597 22.0035 6.25 23.1082 6.25C23.7681 6.25 24.4947 6.34135 25.288 6.52405L25.2435 7.51061C24.5762 7.39368 23.909 7.33522 23.2417 7.33522C22.5744 7.33522 22.1221 7.53253 21.8849 7.92716C21.6476 8.31447 21.529 9.04892 21.529 10.1305C21.529 11.2047 21.6439 11.9392 21.8737 12.3338C22.1036 12.7211 22.5521 12.9148 23.2194 12.9148C23.8867 12.9148 24.5614 12.86 25.2435 12.7504L25.2769 13.7588C24.5132 13.9196 23.7903 14 23.1082 14Z"
            fill="#0A0B0D"
          />
          <path
            d="M26.407 13.8794L28.2865 6.37058H30.7443L32.635 13.8794H31.3894L30.9779 12.268H28.053L27.6415 13.8794H26.407ZM29.2429 7.39003L28.2976 11.1938H30.7332L29.799 7.39003H29.2429Z"
            fill="#0A0B0D"
          />
          <path
            d="M36.9757 11.5446H35.4187V13.8794H34.1842V6.37058H36.9757C38.7032 6.37058 39.567 7.21099 39.567 8.8918C39.567 9.75413 39.3483 10.4118 38.9108 10.8649C38.4808 11.318 37.8358 11.5446 36.9757 11.5446ZM35.4187 10.4922H36.9646C37.8543 10.4922 38.2991 9.95875 38.2991 8.8918C38.2991 8.38024 38.1916 8.00754 37.9766 7.77369C37.7616 7.53984 37.4243 7.42291 36.9646 7.42291H35.4187V10.4922Z"
            fill="#0A0B0D"
          />
          <path
            d="M45.489 13.8794H41.2184V6.37058H42.4529V12.7942H45.489V13.8794Z"
            fill="#0A0B0D"
          />
          <path d="M46.9601 13.8794V6.37058H48.1946V13.8794H46.9601Z" fill="#0A0B0D" />
          <path
            d="M53.5131 11.0074V9.93317H55.6818V13.7479C55.4816 13.799 55.0738 13.8538 54.4585 13.9123C53.8505 13.9708 53.4093 14 53.135 14C51.9784 14 51.1813 13.6858 50.7439 13.0573C50.3064 12.4288 50.0877 11.4459 50.0877 10.1086C50.0877 8.76391 50.3101 7.78465 50.755 7.17079C51.2073 6.55693 51.9858 6.25 53.0905 6.25C53.743 6.25 54.477 6.32308 55.2926 6.46924L55.6818 6.54597L55.6373 7.51061C54.7402 7.39368 53.9357 7.33522 53.224 7.33522C52.5122 7.33522 52.0229 7.53253 51.7559 7.92716C51.4964 8.32178 51.3667 9.05257 51.3667 10.1195C51.3667 11.1865 51.489 11.9246 51.7337 12.3338C51.9858 12.7357 52.49 12.9367 53.2462 12.9367C53.7875 12.9367 54.1915 12.9038 54.4585 12.838V11.0074H53.5131Z"
            fill="#0A0B0D"
          />
          <path
            d="M62.2207 13.8794V10.6347H58.9287V13.8794H57.6942V6.37058H58.9287V9.57143H62.2207V6.37058H63.4663V13.8794H62.2207Z"
            fill="#0A0B0D"
          />
          <path
            d="M64.9393 7.4558V6.37058H70.5V7.4558H68.3536V13.8794H67.108V7.4558H64.9393Z"
            fill="#0A0B0D"
          />
        </svg>
      ),
    },
  },
}
