import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { isLoaded } from "common/utils/Loading"
import { localeDateFormat } from "common/utils/dateUtils"
import { useCRMContacts } from "src/pages/CRM/Providers/CRMContactsProvider"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import { OrderPageLoadingOrder, OrderPageOrder } from "../../types"
import { OrderTermSheetRowProps } from "../OrderTermSheetRow"
import OrderTermSheetSection from "../OrderTermSheetSection"
import { CRMContactName } from "src/pages/CRM/Contacts/ContactDetailsDrawer/CRMContactName"
import { nullableToMaybe } from "common/containers/Maybe"

const OrderCRMContactCardLoaded = (order: OrderPageOrder) => {
  const currentUser = useCurrentUser()
  const { findContactById } = useCRMContacts()

  if (!isLoaded(currentUser)) return null

  const contact = nullableToMaybe(order.crmContactId(currentUser.user.account)).match(
    (id) => findContactById(id),
    () => null
  )

  if (!contact) return null

  const nameRow: OrderTermSheetRowProps = {
    label: "Name",
    // TODO: shouldn't have to put negative margin to make this display correctly...
    value: (
      <div className="-m-2">
        <CRMContactName contact={contact} />
      </div>
    ),
    accessLevel: order.fromAccount(currentUser.user.account) ? "public" : "never",
  }
  const emailRow: OrderTermSheetRowProps[] =
    contact.tag !== "firm"
      ? [
          {
            label: "Email",
            value: contact.email ?? "",
            accessLevel: order.fromAccount(currentUser.user.account) ? "public" : "never",
          },
        ]
      : []
  const phoneRow: OrderTermSheetRowProps[] =
    contact.tag !== "firm"
      ? [
          {
            label: "Phone",
            value: contact.phone ?? "",
            accessLevel: order.fromAccount(currentUser.user.account) ? "public" : "never",
          },
        ]
      : []

  const lastContactedRow: OrderTermSheetRowProps = {
    label: "Last Contacted",
    value: contact.lastContactedAt ? localeDateFormat(contact.lastContactedAt) : "",
    accessLevel: order.fromAccount(currentUser.user.account) ? "public" : "never",
  }

  return (
    <OrderTermSheetSection
      title={
        <div className="flex flex-col gap-1">
          <Typography
            size={Size.Small}
            weight={Weight.Bold}
            color={Color.Primary}
            text="Linked Contact"
          />
          <Typography size={Size.Small} text="This information is private to your account" />
        </div>
      }
      rows={[nameRow, emailRow, phoneRow, lastContactedRow].flat()}
      hideUnspecified={false}
    />
  )
}

const OrderCRMContactSection = ({ order }: OrderPageLoadingOrder) =>
  order.match(OrderCRMContactCardLoaded, null, null)

export default OrderCRMContactSection
