/* eslint-disable better-mutation/no-mutation */
import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { Routes } from "../../Routes/Routes"

interface RouteMetaTagData {
  title: string
  description: string
  keywords: string
}

const routeMetaMap: Record<string, RouteMetaTagData> = {
  [Routes.signup]: {
    title: "Signup for Caplight",
    description: "Sign up for an account on Caplight.",
    keywords: "signup, register, create account",
  },
  [Routes.login]: {
    title: "Caplight Log In",
    description: "Sign in to your Caplight account.",
    keywords: "signin, login, access account",
  },
}

const defaultMetaTagData: RouteMetaTagData = {
  title: "Caplight",
  description: "Private market data and trading for institutional investors",
  keywords: "caplight, investing, private stocks",
}

const MetaTagProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation()

  useEffect(() => {
    const path = location.pathname
    const meta = routeMetaMap[path] || defaultMetaTagData

    document.title = meta.title

    const metaDescription = document.querySelector('meta[name="description"]')
    const metaKeywords = document.querySelector('meta[name="keywords"]')

    if (metaDescription) {
      metaDescription.setAttribute("content", meta.description)
    } else {
      const metaElement = document.createElement("meta")
      metaElement.name = "description"
      metaElement.content = meta.description
      document.head.appendChild(metaElement)
    }

    if (metaKeywords) {
      metaKeywords.setAttribute("content", meta.keywords)
    } else {
      const metaElement = document.createElement("meta")
      metaElement.name = "keywords"
      metaElement.content = meta.keywords
      document.head.appendChild(metaElement)
    }
  }, [location.pathname])

  return <>{children}</>
}

export { MetaTagProvider }
