import { Left, Right } from "common/containers/Either"
import { CurrentUser } from "../../../model/CurrentUser"
import { AuthCondition, El } from "../../../Routes/auth/AuthCondition"
import { UserAuthContext } from "../../../Routes/auth/UserAuthContext"
import { acceptTermsOfService } from "../../legal/AcceptTermsOfService"
import { hasUserSignedLatestTermsOfService } from "../../legal/termsOfServiceCheck"
import { TermsOfServiceModal } from "../../legal/TermsOfServiceModal"
import { deprecatedIsLoaded } from "common/utils/Loading"

export const tosCheck: AuthCondition<UserAuthContext, UserAuthContext> = (ctx: UserAuthContext) => {
  if (
    ctx?.user &&
    deprecatedIsLoaded(ctx.user) &&
    deprecatedIsLoaded(ctx.account) &&
    !hasUserSignedLatestTermsOfService(ctx.user, ctx.account)
  ) {
    return Left((children: El) => {
      const onSignComplete = () => acceptTermsOfService(ctx.user as CurrentUser, ctx.firebase)
      return (
        <>
          <TermsOfServiceModal open onSignComplete={onSignComplete} handleClose={() => {}} />
          {children}
        </>
      )
    })
  }
  return Right(ctx)
}
