import { Modal, useModal } from "@components/layout/Modal"
import { NonWindowedTable } from "@components/table/NonWindowedTable"
import { PageTitle } from "@components/typography/PageTitle"
import { firestoreConverter } from "@model/FirestoreConverter"
import { TopBrokerPill } from "@stories/components/Badges/TopBrokerPill/TopBrokerPill"
import { Button } from "@stories/components/Button/Button"
import Typography, { Color } from "@stories/components/Typography/Typography"
import { collections } from "common/firestore/Collections"
import { LinkedBroker } from "common/model/LinkedBroker"
import { UserInvitedBroker } from "common/model/UserInvitedBroker"
import { collection, onSnapshot } from "firebase/firestore"
import { useEffect, useState } from "react"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { useAccountBrokerLinking } from "src/utils/useAccountBrokerLinking"
import { AddLinkedBroker } from "../OnboardingDeprecated/AddLinkedBroker"
import { InvitedBroker } from "../OnboardingDeprecated/InviteLinkedBroker"
import { saveLinkedBrokerPreferences } from "../OnboardingDeprecated/SelectLinkedBroker"
import { useLinkedBrokersQuery } from "src/queries/LinkedBroker/useLinkedBrokersQuery"

const FindNewBrokerModal = () => {
  const user = useLoggedInUser()
  const { db } = useFirebase9()
  const allBrokers = useLinkedBrokersQuery()

  const [selectedBrokers, setSelectedBrokers] = useState<LinkedBroker[]>([])
  const [selectedInvitedBrokers, setSelectedInvitedBrokers] = useState<InvitedBroker[]>([])
  const handleSubmit = () =>
    saveLinkedBrokerPreferences(db, {
      selectedBrokers,
      invitedBrokers: selectedInvitedBrokers,
      caplightAsBroker: false,
      user,
    })
      .then(closeModal)
      .then(() => setSelectedBrokers([]))
      .then(() => setSelectedInvitedBrokers([]))

  const { open, openModal, closeModal } = useModal()
  return (
    <div className="space-y-2">
      <Button label="Add New Preferred Broker" onClick={openModal} />
      <Modal
        open={open}
        handleClose={closeModal}
        closable
        subtitle="Add New Preferred Broker"
        body={
          <>
            <Typography text="Select Broker(s)" />
            <AddLinkedBroker
              allBrokers={allBrokers}
              selectedBrokers={selectedBrokers}
              setSelectedBrokers={setSelectedBrokers}
              invitedBrokers={selectedInvitedBrokers}
              addInvitedBroker={(b: InvitedBroker) =>
                setSelectedInvitedBrokers([...selectedInvitedBrokers, b])
              }
              removeInvitedBroker={(b: InvitedBroker) =>
                setSelectedInvitedBrokers(
                  selectedInvitedBrokers.filter((iB) => iB.email !== b.email)
                )
              }
            />
            <div className="flex items-center space-x-2 mt-8">
              <Button
                label={`Add ${
                  selectedBrokers.length + selectedInvitedBrokers.length
                } selected brokers`}
                isDisabled={!selectedBrokers.length && !selectedInvitedBrokers.length}
                onClick={handleSubmit}
                isFullWidth
              />
            </div>
          </>
        }
      />
    </div>
  )
}

const AddLinkedBrokerPopup = () => {
  const user = useLoggedInUser()
  const { db } = useFirebase9()
  const [invitedBrokers, setInvitedBrokers] = useState<InvitedBroker[]>([])
  useEffect(() => {
    const selectedBrokerCollectionRef = collection(
      db,
      collections.accounts,
      user.user.account.id,
      collections.accountSubcollections.invitedBrokers
    ).withConverter<UserInvitedBroker>(firestoreConverter<UserInvitedBroker>())
    return onSnapshot(selectedBrokerCollectionRef, ({ docs }) =>
      setInvitedBrokers(docs.map((d) => d.data()))
    )
  }, [db, user.user.account.id])
  const [linkedBrokers, setLinkedBrokers] = useState<LinkedBroker[]>([])
  useEffect(() => {
    const selectedBrokerCollectionRef = collection(
      db,
      collections.accounts,
      user.user.account.id,
      collections.accountSubcollections.linkedBrokers
    ).withConverter<LinkedBroker>(firestoreConverter<LinkedBroker>())
    return onSnapshot(selectedBrokerCollectionRef, ({ docs }) =>
      setLinkedBrokers(docs.map((d) => d.data()))
    )
  }, [db, user.user.account.id])

  return (
    <div>
      <FindNewBrokerModal />
      {!linkedBrokers.length && !invitedBrokers.length ? (
        <Typography text="No Brokers Found, find a new broker." />
      ) : null}
      {linkedBrokers.length ? (
        <>
          <div className="mt-4">
            <Typography text="Your Linked Brokers" />
          </div>
          <NonWindowedTable<LinkedBroker>
            items={linkedBrokers}
            headers={["Broker", "Firm", "Contact"]}
            tableHeight={100}
            tableWidth={100}
            itemSize={0}
            renderRow={({ item }) => (
              <tr>
                <td className="p-2">{item.fullName}</td>
                <td className="p-2">
                  <div className="flex space-x-2 items-center">
                    <span>{item.firmName}</span> {item.meta?.isTopBroker ? <TopBrokerPill /> : null}
                  </div>
                </td>
                <td className="p-2">{item.user?.email ?? "N/A"}</td>
              </tr>
            )}
          />
        </>
      ) : null}
      {invitedBrokers.length ? (
        <>
          <div className="mt-4">
            <Typography text="Your Invited Brokers" />
          </div>
          <NonWindowedTable<InvitedBroker>
            items={invitedBrokers}
            headers={["Broker", "Contact"]}
            tableHeight={100}
            tableWidth={100}
            itemSize={0}
            renderRow={({ item }) => (
              <tr>
                <td className="p-2">{item.fullName}</td>
                <td className="p-2">{item.email}</td>
              </tr>
            )}
          />
        </>
      ) : null}
    </div>
  )
}
export const BrokerPage = () => {
  const { isBrokerLinkingClient } = useAccountBrokerLinking()
  if (!isBrokerLinkingClient) return null
  return (
    <div className="shadow-md bg-neutral-white border-neutral-400 my-12 p-8 rounded-md w-full">
      <PageTitle title="Preferred Broker" />
      <div>
        <Typography
          color={Color.Subtitle}
          text="Preferred brokers can be linked to the buy/sell orders you place on Caplight. The brokers you select are able to edit and maintain these linked orders on your behalf, as well as respond to other brokers' Connect Requests and Inquiries on your order."
        />
      </div>
      <AddLinkedBrokerPopup />
    </div>
  )
}
