import { ContactHolding } from "common/model/holdings/Holding"
import { Loading, isLoaded } from "common/utils/Loading"
import { ReactNode, createContext, useContext, useEffect, useState } from "react"
import { useFirebaseReader } from "src/firebase/Context"
import { getAccountContactsHoldings } from "../../../firebase/crm"
import { useCurrentUser } from "../../../providers/currentUser/useCurrentUser"

type ContactHoldingsProviderState = Loading<ContactHolding[]>

export const ContactHoldingsContext = createContext<ContactHoldingsProviderState | undefined>(
  undefined
)

export const ContactHoldingsProvider = ({ children }: { children: ReactNode }) => {
  const firebase = useFirebaseReader()
  const user = useCurrentUser()

  const [contactHoldings, setContactHoldings] = useState<ContactHoldingsProviderState>("loading")

  useEffect(() => {
    if (isLoaded(user)) {
      const unsubscribe = getAccountContactsHoldings({
        db: firebase,
        accountId: user.user.account.id,
      }).onSnapshot((snapshot) => {
        const docs = snapshot.docs.map((doc) => doc.data())
        setContactHoldings(docs)
      })
      return unsubscribe
    } else {
      return () => {}
    }
  }, [firebase, user])

  return (
    <ContactHoldingsContext.Provider value={contactHoldings}>
      {children}
    </ContactHoldingsContext.Provider>
  )
}

export const useContactHoldings = (): ContactHoldingsProviderState => {
  const context = useContext(ContactHoldingsContext)

  if (!context) {
    throw new Error("useContactHoldings must be wrapped in a <ContactHoldingsProvider />")
  }

  return context
}
