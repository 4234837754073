import { TradingRegistrationTypeSelection } from "@components/TradingRegistration/TradingRegistrationTypeSelection"
import { notification } from "@stories/components/Antd/notification"
import { Button } from "@stories/components/Button/Button"
import Typography, { Color, Weight } from "@stories/components/Typography/Typography"
import {
  AccountTradingRegistration,
  MinimumAccountTradingRegistration,
  defaultAccountTradingRegistration,
} from "common/model/Account/AccountTradingRegistration"
import { Loading, isLoaded, matchLoading } from "common/utils/Loading"
import { handleConsoleError } from "src/utils/Tracking"

export const AdminTradingRegistrationTypeSelection = ({
  tradingRegistration,
  updateTradingRegistration,
}: {
  updateTradingRegistration: (
    t: Partial<AccountTradingRegistration> & MinimumAccountTradingRegistration
  ) => Promise<void>
  tradingRegistration: Loading<
    Partial<AccountTradingRegistration> & MinimumAccountTradingRegistration
  >
}) => {
  const handleStatusSave = (type: AccountTradingRegistration["type"]) => {
    if (isLoaded(tradingRegistration)) {
      updateTradingRegistration({
        ...tradingRegistration,
        ...defaultAccountTradingRegistration(
          tradingRegistration.id,
          tradingRegistration.account,
          type
        ),
      })
        .then(() => notification.close("change-registration-type"))
        .catch(handleConsoleError)
    }
  }
  const handleStatusChange = (type: AccountTradingRegistration["type"]) => {
    if (isLoaded(tradingRegistration)) {
      notification.warn({
        key: "change-registration-type",
        placement: "top",
        duration: 0,
        message: (
          <>
            <Typography
              text="Are you sure you want to change the registration type? This will reset anything the user has submitted."
              color={Color.Danger}
              weight={Weight.Bold}
            />
            <Button label="Cancel" onClick={() => notification.close("change-registration-type")} />
            <Button label="Yes Change" onClick={() => handleStatusSave(type)} />
          </>
        ),
      })
    }
  }
  return (
    <TradingRegistrationTypeSelection
      updateTradingRegistration={handleStatusChange}
      tradingRegistrationType={matchLoading(tradingRegistration, (t) => t.type, null, null)}
    />
  )
}
