import { Loading, Loading_ } from "common/utils/Loading"
import { Rec } from "common/utils/RecordUtils"
import _ from "lodash"

// unsafe internals for FirebaseDocumentProvider.
// this code should not be used or imitated elsewhere

type RawContainer<T> = null | T | T[] | { [key: string]: RawContainer<T> }

export const lookupWithStringContainer = <T, C extends RawContainer<string>>(
  f: (t: string) => Loading<T>,
  c: C
): Loading<FilledContainer<C, T>> =>
  (c === null
    ? null
    : _.isString(c)
    ? f(c)
    : _.isArray(c)
    ? Loading_.Array.sequence(c.map((x) => f(x)))
    : Loading_.Rec.sequence(
        Rec.map(c, (x) => lookupWithStringContainer(f, x as RawContainer<string>))
      )) as Loading<FilledContainer<C, T>>

export const stringContainerValues = (c: RawContainer<string>): string[] =>
  c === null ? [] : _.isString(c) ? [c] : Object.values(c).flatMap((v) => stringContainerValues(v))

export type DocumentIdContainer = RawContainer<string>
export type FilledContainer<C extends DocumentIdContainer, T> =
  | (C extends null ? null : never)
  | (C extends string ? T : never)
  | (C extends string[] ? T[] : never)
  | (C extends { [key: string]: RawContainer<string> }
      ? { [key in keyof C]: FilledContainer<C[key], T> }
      : never)
