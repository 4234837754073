import { debounce } from "lodash"
import { useEffect } from "react"

const hoursToMs = (hours: number) => hours * 60 * 60 * 1000
const TIMEOUT_MS = hoursToMs(6)

const debouncedReload = debounce(() => window.location.reload(), TIMEOUT_MS, {
  trailing: true,
  leading: false,
})

type EventType = keyof WindowEventMap
const activityEventTypes: EventType[] = [
  "mousedown",
  "mousemove",
  "keypress",
  "touchmove",
  "focus",
  "scroll",
]

const addListener = (eventType: EventType) =>
  window.addEventListener(eventType, debouncedReload, { passive: true })

const removeListener = (eventType: EventType) =>
  window.removeEventListener(eventType, debouncedReload)

const InactivityReloader = () => {
  useEffect(() => {
    debouncedReload()
    activityEventTypes.forEach(addListener)
    return () => activityEventTypes.forEach(removeListener)
  })

  return null
}

export default InactivityReloader
