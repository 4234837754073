import Typography, { Size } from "@stories/components/Typography/Typography"
import { DealCRMPriority } from "common/model/DealCRM/Deal/DealCRMDeal"
import capitalize from "lodash/capitalize"
import { FC } from "react"
import { PriorityPill } from "./PriorityPill"

export const PriorityOptionLabel: FC<{
  size: "md" | "sm" | "xs"
  priority: DealCRMPriority
  iconOnlyMenu: boolean
}> = ({ size, priority, iconOnlyMenu }) => (
  <div
    className={`flex items-center justify-between gap-2 
      ${iconOnlyMenu ? "icon-only-priority-menu" : ""}`}
  >
    <Typography
      size={size === "md" ? Size.Medium : size === "sm" ? Size.Small : Size.XSmall}
      text={capitalize(priority)}
    />
    <PriorityPill priority={priority} />
  </div>
)
