import { PageTitle } from "../../../../components/typography/PageTitle"
import { OrderPageLoadingOrder } from "../types"
import LoadedOrderPreview from "./LoadedOrderPreview"
import LoadingOrderPreview from "./LoadingOrderPreview"

type Props = OrderPageLoadingOrder
const OrderPageOrderPreview = ({ order }: Props) => (
  <div className="w-full bg-neutral-white p-4 border border-neutral-400 rounded">
    <div className="flex justify-between mb-4">
      <div className="flex items-center space-x-2">
        <PageTitle title="Review" />
      </div>
    </div>
    {order.match(
      (o) => (
        <LoadedOrderPreview order={o} />
      ),
      <LoadingOrderPreview />,
      null
    )}
  </div>
)

export default OrderPageOrderPreview
