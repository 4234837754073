import { CheckOutlined, ExclamationOutlined } from "@ant-design/icons"
import { StepsProps } from "@stories/components/Antd/StepsProps"
import { UserAuthContext, useAuthContext } from "src/Routes/auth/UserAuthContext"
import { useAccountBrokerLinking } from "src/utils/useAccountBrokerLinking"
import { StepKey, StepOrderMap, useOrderFormStatus } from "./contextHooks/useFormStatus"

interface StepIconProps {
  stepKey: StepKey
  isStepComplete: boolean
  isCurrentStep: (
    stepKey: StepKey,
    stepProgress: number,
    authContext: UserAuthContext,
    brokerLinkingFeatureFlag: boolean
  ) => boolean
  clickEnabled: (status: StepsProps["status"]) => boolean
}

export const StepIcon = ({
  stepKey,
  isCurrentStep,
  clickEnabled,
  isStepComplete,
}: StepIconProps) => {
  const { selectFormStatuses, stepProgress, maxStepReached } = useOrderFormStatus()
  const status = selectFormStatuses()[stepKey]
  const ctx = useAuthContext()
  const { isAccountBrokerLinkingEnabled } = useAccountBrokerLinking()

  const stepOutlineClasses = `mt-1 h-6 w-6 rounded-full text-xs font-bold flex items-center justify-center font-serif text-white ${
    isCurrentStep(stepKey, stepProgress, ctx, isAccountBrokerLinkingEnabled)
      ? "bg-accent-500"
      : isStepComplete
      ? "bg-accent-500"
      : "bg-neutral-500"
  } ${clickEnabled(status) ? "cursor-pointer hover:bg-opacity-80" : ""}`

  if (status === "finish") {
    return (
      <div className={stepOutlineClasses}>
        <CheckOutlined />
      </div>
    )
  }

  if (status === "error" && maxStepReached >= stepProgress) {
    return (
      <div className={stepOutlineClasses}>
        <ExclamationOutlined />
      </div>
    )
  }

  return (
    <div className={stepOutlineClasses}>
      <div>{StepOrderMap(ctx, isAccountBrokerLinkingEnabled)[stepKey] + 1}</div>
    </div>
  )
}
