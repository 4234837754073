type Opts = {
  nullishLastWhenDesc?: boolean
}
type SortableValue = string | number | null

export const rawSortingFn = (opts?: Opts) => (l: SortableValue, r: SortableValue) => {
  const flipNullish = opts?.nullishLastWhenDesc ? -1 : 1

  if (l === null && r === null) {
    return 0
  }
  if (l === null) {
    return 1 * flipNullish
  }
  if (r === null) {
    return -1 * flipNullish
  }

  return l > r ? 1 : l < r ? -1 : 0
}

export const makeSortingFn =
  (opts?: Opts) =>
  (
    l: { getValue: (colId: string) => SortableValue },
    r: { getValue: (colId: string) => SortableValue },
    colId: string
  ): number => {
    const lValue = l.getValue(colId)
    const rValue = r.getValue(colId)

    return rawSortingFn(opts)(lValue, rValue)
  }
