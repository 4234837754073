import { Maybe, Nothing, nullableToMaybe } from "common/containers/Maybe"
import { safeGet } from "common/utils/MapUtils"
import { useState } from "react"
import { Selector, SelectorProps } from "."
import { Dropdown } from "../Dropdown"

export const useDropdownSelector: Selector<{ label: string }> = <T,>({
  options,
  renderOption,
  label,
  initialState,
  small,
  minWidth,
  emptyLabel,
  disabled,
  loading,
}: SelectorProps<T> & { label: string }): [Maybe<T>, JSX.Element, () => void] => {
  const emptyLabelOption = emptyLabel ? { id: "", name: emptyLabel } : null
  const renderedOptions = (emptyLabelOption ? [emptyLabelOption] : []).concat(
    options.map((t) => renderOption(t))
  )
  const optionLookup = new Map(options.map((t) => [renderOption(t).id, t]))
  const [selected, setSelected] = useState<Maybe<T>>(nullableToMaybe(initialState))
  const clearSelected = () => setSelected(Nothing)
  const component = (
    <Dropdown
      label={label}
      options={renderedOptions}
      small={small}
      minWidthPx={minWidth}
      selected={selected.matchStrict(renderOption, emptyLabelOption ?? undefined)}
      setSelected={(option) => setSelected(safeGet(optionLookup, option.id))}
      disabled={disabled}
      loading={loading}
    />
  )
  return [selected.matchStrict(nullableToMaybe, Nothing), component, clearSelected]
}
