import { Tabs } from "@stories/components/Tabs/Tabs"
import { Loading, defaultIfLoading, isLoaded } from "common/utils/Loading"
import { useParams } from "react-router-dom"
import {
  getDealDistribution,
  updateDealDistribution,
  updateDealDistributionParticipant,
  updateDealDistributionVisibility,
} from "src/firebase/dealDistributions"
import { useDocumentSnapshot } from "src/utils/hooks/queries/useDocumentSnapshot"
import { useQuerySnapshot } from "src/utils/hooks/queries/useQuerySnapshot"
import { AdminDealDistributionParticipantView } from "./AdminDealDistributionParticipantView"
import Spinner from "@components/icons/Spinner"
import { AdminDealDistributionInitialEmailView } from "./AdminDealDistributionInitialEmailView"
import { Button } from "@stories/components/Button/Button"
import { Routes } from "src/Routes/Routes"
import { AsyncDispatchButton } from "@stories/components/Button/AsyncDispatchButton"
import { DealDistribution } from "common/model/DealDistribution/DealDistribution"
import { DealDistributionParticipant } from "common/model/DealDistribution/DealDistributionParticipant"
import { useMemo } from "react"
import { getAPIResponse, APIEndpoints } from "src/firebase/API"
import { useFirebaseReader } from "src/firebase/Context"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { firestoreConverter } from "@model/FirestoreConverter"
import { collections } from "common/firestore/Collections"
import { identity } from "common/utils/fp/Function"
import { localDateTimeFormat } from "common/utils/dateUtils"
import { CheckboxWithLabel } from "@components/inputs/checkbox/Checkbox"

const PublishDealButton = ({
  dealDistribution,
  participants,
}: {
  dealDistribution: Loading<DealDistribution>
  participants: DealDistributionParticipant[]
}) => {
  const db = useFirebaseReader()
  const user = useLoggedInUser()
  const uninvitedParticipants = useMemo(
    () => participants.filter((p) => !p.status.sentDate && p.status.approvedDate),
    [participants]
  )

  if (!isLoaded(dealDistribution)) {
    return null
  }
  if (dealDistribution.visibility === "closed") {
    return null
  }
  const handleCloseDeal = () =>
    updateDealDistributionVisibility({ db, dealDistribution, visibility: "closed" })
  const handlePublishDeal = () =>
    updateDealDistributionVisibility({ db, dealDistribution, visibility: "public" })
      .then(async (idFields) => {
        await Promise.all(
          uninvitedParticipants.map((p) =>
            updateDealDistributionParticipant({
              db,
              participant: { ...p, status: { ...p.status, sentDate: new Date() } },
            })
          )
        )
        return idFields
      })
      .then(async (idFields) =>
        getAPIResponse(
          APIEndpoints.publishDealDistributionEmails,
          { dealDistributionId: idFields.id },
          user
        )
      )

  const label =
    dealDistribution.visibility === "public"
      ? "Close Deal"
      : `Publish Deal${
          dealDistribution.startAt
            ? `- requested schedule for ${localDateTimeFormat(dealDistribution.startAt)}`
            : "- not scheduled"
        }`
  const action = dealDistribution.visibility === "public" ? handleCloseDeal : handlePublishDeal

  return (
    <AsyncDispatchButton label={label} onClick={action} onSuccessProps={{ isDisabled: false }} />
  )
}

const IncludeDealPageButton = ({
  dealDistribution,
}: {
  dealDistribution: Loading<DealDistribution>
}) => {
  const db = useFirebaseReader()
  if (!isLoaded(dealDistribution)) {
    return null
  }
  const handleToggleDealPageInclusion = () =>
    updateDealDistribution({
      db,
      dealDistribution,
      isParticipantViewIncludedInDistribution: !dealDistribution.isParticipantViewIncludedInDistribution,
    })

  return (
    <>
      <CheckboxWithLabel
        id="deal-distribution-deal-page-inclusion"
        title="Is Deal Page Included"
        checked={dealDistribution.isParticipantViewIncludedInDistribution}
        onChange={handleToggleDealPageInclusion}
      />
      <Button
        label="View Deal Page Preview"
        variant="secondary"
        isDisabled={!dealDistribution.isParticipantViewIncludedInDistribution}
        onClick={() =>
          window.open(Routes.crm.dealDistribution.creatorPreview(dealDistribution.id), "_blank")
        }
      />
    </>
  )
}

export const AdminDealDistributionView = () => {
  const { distributionId } = useParams()

  const dealDistribution = useDocumentSnapshot(
    (db) =>
      distributionId ? getDealDistribution({ db, dealDistributionId: distributionId }) : null,
    [distributionId]
  )
  const participants = useQuerySnapshot(
    (db) =>
      distributionId
        ? db.db
            .collection(collections.dealDistributions)
            .doc(distributionId)
            .collection(collections.dealDistributionSubcollections.participants)
            .withConverter<DealDistributionParticipant>(
              firestoreConverter<DealDistributionParticipant>()
            )
        : null,
    identity,
    [distributionId]
  )

  return (
    <>
      <div className="flex justify-end items-center gap-4 p-4">
        <IncludeDealPageButton dealDistribution={dealDistribution} />
        <PublishDealButton
          dealDistribution={dealDistribution}
          participants={defaultIfLoading(participants, [])}
        />
      </div>
      <Tabs
        tabs={[
          {
            id: "deal-distribution-participant-view",
            key: 0,
            label: "Participants",
            children: isLoaded(dealDistribution) ? (
              <AdminDealDistributionParticipantView
                dealDistribution={dealDistribution}
                participants={participants}
              />
            ) : (
              <Spinner size="md" />
            ),
          },
          {
            id: "deal-distribution-participant-view",
            key: 1,
            label: "Initial Email",
            children: <AdminDealDistributionInitialEmailView dealDistribution={dealDistribution} />,
          },
        ]}
        initialTab={0}
      />
    </>
  )
}
