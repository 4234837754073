import { v4 as uuidv4 } from "uuid"
import { collectionReferences } from "../../../firestore/Collections"
import { FirebaseCommon } from "../../../firestore/Interface"
import { getMessageEffect, runMessageEffect } from "../Effects"
import { UnsentConnectMessage, ConnectMessage } from "../Message"
import { ConnectMessageAuthor } from "../Message/Author"
import {
  StructuredConnectMessageResponse,
  StructuredMessageType,
  _StructuredConnectMessage,
  _StructuredConnectMessageResponse,
} from "../MessageTypes/StructuredMessage"
import { setUserLastMessageDateToNow } from "./queries"
import { ThreadMessageCoordinator } from "./ThreadMessageCoordinator"
import { ThreadType } from "../ThreadTypes/ThreadType"

export class FirebaseThreadMessageCoordinator<
  T extends ThreadType = never
> extends ThreadMessageCoordinator<T> {
  readonly db: FirebaseCommon.DB

  readonly threadId: string

  constructor(db: FirebaseCommon.DB, threadId: string) {
    super()
    this.db = db
    this.threadId = threadId
  }

  writeNewMessageDocument = async (message: UnsentConnectMessage<T>) => {
    const id = uuidv4()

    const messageRef = collectionReferences
      .connectThreadSubcollections(this.threadId)
      .messages(this.db)
      .doc(id)

    await messageRef.set(message)
    const doc = (await messageRef.get()).data()
    if (doc === undefined) {
      throw new Error(`failed to write message to thread ${this.threadId}`)
    } else {
      return doc as ConnectMessage<T>
    }
  }

  recordUserSentMessage = async (author: ConnectMessageAuthor) => {
    if (author.tag === "user") {
      await setUserLastMessageDateToNow(this.db)({
        threadId: this.threadId,
        user: author.user,
        account: author.account,
      })
    }
  }

  recordMessageResponse = async <K extends StructuredMessageType>(
    response: _StructuredConnectMessageResponse<K>
  ) => {
    const messageRef = collectionReferences
      .connectThreadSubcollections(response.threadId)
      .messages(this.db)
      .doc(response.responseToMessageId)

    const respondingTo = (await messageRef.get()).data()

    if (respondingTo) {
      if (response.threadId !== respondingTo.threadId) {
        throw new Error(
          `Attempting to send response to message ${respondingTo.id} of thread ${respondingTo.threadId} to thread ${response.threadId}. This should never happen.`
        )
      } else {
        await messageRef.update({
          responses: [
            response,
            ...(respondingTo as _StructuredConnectMessage<K>).responses,
          ] as StructuredConnectMessageResponse[], // TODO FieldValue.arrayUnion
        })
      }
    } else {
      throw new Error(
        `message ${response.responseToMessageId} not found on thread ${response.threadId}`
      )
    }
  }

  dispatchMessageEffects = async (message: ConnectMessage<T>) => {
    const thread = (
      await collectionReferences.connectThreads(this.db).doc(this.threadId).get()
    ).data()
    if (thread) {
      getMessageEffect(message)?.runEffect((eff) => runMessageEffect(this.db)(eff, thread)) // TODO only-once
    } else {
      throw new Error(`thread ${this.threadId} not found`)
    }
  }
}
