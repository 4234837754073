import { collections } from "common/firestore/Collections"
import { FirebaseCommon } from "common/firestore/Interface"
import { AccountIdFields } from "common/model/Account"
import { firestoreConverter } from "common/model/FirestoreConverter"
import {
  AccountNetworkNode,
  generateTrustedBrokerConnection,
  TrustedBrokerConnection,
} from "common/model/TrustedBrokerConnection"
import { User } from "common/model/User"

export const addConnectionToNetwork = async ({
  db,
  connectionAccount,
  createdForAccount,
  status,
  createdBy,
  adminConnection,
}: {
  db: FirebaseCommon.DB
  connectionAccount: AccountNetworkNode
  createdForAccount: AccountIdFields
  status: AccountNetworkNode["status"]
  createdBy: User
  adminConnection?: boolean
}) => {
  if (!connectionAccount) return null

  const newConnection = generateTrustedBrokerConnection({
    createdBy,
    leftAccount: createdForAccount,
    rightAccount: connectionAccount,
    status,
    adminConnection,
  })
  return db
    .collection(collections.trustedBrokerConnections)
    .withConverter<TrustedBrokerConnection>(firestoreConverter<TrustedBrokerConnection>())
    .doc(newConnection.id)
    .set(newConnection)
}
