import Typography, { Color, Size } from "@stories/components/Typography/Typography"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { useMemo } from "react"
import { CompanyFilterSearchProp } from "./shared"
import { isBrokerage } from "common/model/DealCRM/DealCRMFirmContact"
import { truncateString } from "common/utils/StringUtils"

const ContactInfoPreviewCell = ({ label, value }: { label: string; value: string }) => (
  <div className="flex flex-col gap-2">
    <Typography text={label} size={Size.XXSmall} color={Color.Subtitle} shouldWrap={false} />
    <Typography text={truncateString(value, 15)} size={Size.XSmall} shouldWrap={false} />
  </div>
)

const ContactTransactionInterestInfoPreview = ({
  contact,
  initialCompanyFilter,
  interestDirection,
}: {
  contact: DealCRMContact
  initialCompanyFilter?: CompanyFilterSearchProp
  interestDirection: "buy" | "sell"
}) => {
  const [label, value] = useMemo(() => {
    const contactBuyInterest =
      contact.contactBuyInterest?.find((b) => b.company.id === initialCompanyFilter?.company?.id) ??
      contact.contactBuyInterest?.[0]
    const contactSellInterest =
      contact.contactSellInterest?.find(
        (b) => b.company.id === initialCompanyFilter?.company?.id
      ) ?? contact.contactSellInterest?.[0]

    return interestDirection === "buy"
      ? ["Buy Interest", contactBuyInterest ? contactBuyInterest.company.name : "-"]
      : ["Sell Interest", contactSellInterest ? contactSellInterest.company.name : "-"]
  }, [
    initialCompanyFilter?.company?.id,
    contact.contactBuyInterest,
    contact.contactSellInterest,
    interestDirection,
  ])

  return <ContactInfoPreviewCell label={label} value={value} />
}

const ContactHoldingInfoPreview = ({
  contact,
  initialCompanyFilter,
}: {
  contact: DealCRMContact
  initialCompanyFilter?: CompanyFilterSearchProp
}) => {
  const value = useMemo(() => {
    const holding =
      contact.holdings?.find((h) => h.company.id === initialCompanyFilter?.company?.id) ??
      contact.holdings?.[0]
    const holdingString = holding
      ? `${holding.company.name}${
          contact.holdings.length > 1 ? ` and ${contact.holdings.length - 1} more` : ""
        }`
      : "-"
    return holdingString
  }, [contact.holdings, initialCompanyFilter?.company?.id])

  return <ContactInfoPreviewCell label="Holdings" value={value} />
}

export const ContactInfoPreview = ({
  contact,
  initialCompanyFilter,
}: {
  contact: DealCRMContact
  initialCompanyFilter?: CompanyFilterSearchProp
}) => {
  const primaryContact =
    contact.tag === "firm" ? contact.contacts.filter((c) => c.isPrimaryContact)[0] : undefined

  return (
    <div className="grid grid-cols-3 gap-2">
      {contact.tag === "individual" ? (
        <ContactInfoPreviewCell label="Firm" value={contact.firm ? contact.firm.name : "-"} />
      ) : null}
      {contact.tag === "firm" ? (
        <ContactInfoPreviewCell
          label="Primary Contact"
          value={primaryContact ? primaryContact.name : "-"}
        />
      ) : null}
      {!initialCompanyFilter || initialCompanyFilter.type.includes("buy-interest") ? (
        <ContactTransactionInterestInfoPreview
          contact={contact}
          initialCompanyFilter={initialCompanyFilter}
          interestDirection="buy"
        />
      ) : null}
      {!initialCompanyFilter || initialCompanyFilter.type.includes("sell-interest") ? (
        <ContactTransactionInterestInfoPreview
          contact={contact}
          initialCompanyFilter={initialCompanyFilter}
          interestDirection="sell"
        />
      ) : null}
      {contact.tag !== "broker" && !isBrokerage(contact) && initialCompanyFilter ? (
        <ContactHoldingInfoPreview contact={contact} initialCompanyFilter={initialCompanyFilter} />
      ) : null}
    </div>
  )
}
