import { replaceMessages } from "../../parser/ParseErrors"
import { Parse, Parser } from "../../parser/ParserClasses/Simple"
import { Interval } from "../../utils/data/Interval"
import { Date_ } from "../../utils/dateUtils"
import { UserIdFields } from "../User"
import { UserOrSystemCreatable } from "../UserCreatable"
import ContactInvestmentPreferences from "./ContactInvestmentPreferences"
import { DealCRMPriority } from "./Deal/DealCRMDeal"
import { DealCRMBrokerContact, DealCRMBrokerContactIdFields } from "./DealCRMBrokerContact"
import { DealCRMFirmContact, DealCRMFirmContactIdFields } from "./DealCRMFirmContact"
import { DealCRMHolding } from "./DealCRMHolding"
import {
  DealCRMIndividualContact,
  DealCRMIndividualContactIdFields,
} from "./DealCRMIndividualContact"
import {
  DealCRMInterest,
  DeprecatedDealCRMInterest,
  ManagementFeeStructure,
} from "./DealCRMInterest"
import { DealCRMNote } from "./DealCRMNote"

export type DealCRMContactUpdate<T extends DealCRMContact> = {
  updatedBy: UserIdFields
  updatedAt: Date
  data:
    | Partial<T>
    | {
        tag: "contactInterestUpdated"
      }
}

export type DealCRMContactCommonFields = {
  id: string
  accountId: string
  sourceId: string
  stage?: string // client lifecycle stage - customizable and defaulted to
  name: string // derived and stored from firstName and lastName on Contacts
  /** @deprecated use buySellInterest collection */
  contactBuyInterest: DeprecatedDealCRMInterest[]
  /** @deprecated use buySellInterest collection */
  contactSellInterest: DeprecatedDealCRMInterest[]
  holdings: DealCRMHolding[]
  notes: DealCRMNote[]
  importJobId?: string // see DealCRMImportJob.ts
  lastContactedAt?: Date
  targetCheckSize?: Interval<number | null>
  location?: {
    city: string
    state: string
    country: string
  }
  priority?: DealCRMPriority
  postgresContactId?: string
  pitchbookContactId?: string
  creationSource:
    | "form"
    | "import"
    | "investor-database"
    | "add-interest-form"
    | "airtable-sync"
    | "trusted-broker-network-sync"
    | "carta-holdings-import"
  isFundLP: boolean
  typicalBrokerCommissionPercent?: number | null // percent, 0.05 represents 5% commission
  defaultManagementFeePercent?: number | null
  defaultManagementFeeStructure?: ManagementFeeStructure | null
  defaultCarryPercent?: number | null
  userData?: {
    [userId: string]: {
      syncEnabled?: boolean | null
      lastContactedAt?: Date | null
    }
  }
  sectorsOfInterest?: string[]
  investmentPreferences: ContactInvestmentPreferences
  groups?: Record<string, boolean>
} & UserOrSystemCreatable

export type DealCRMContact = DealCRMFirmContact | DealCRMIndividualContact | DealCRMBrokerContact

export type DealCRMContactIdFieldsCommon = Pick<DealCRMContact, "id" | "name">
export type DealCRMContactIdFields =
  | DealCRMFirmContactIdFields
  | DealCRMIndividualContactIdFields
  | DealCRMBrokerContactIdFields

export type DealCRMCommonContactImportData = Required<
  Pick<DealCRMContactCommonFields, "lastContactedAt">
>

export const isDealCRMFirmContact = (contact: DealCRMContact): contact is DealCRMFirmContact =>
  contact.tag === "firm"

export const isDealCRMIndividualContact = (
  contact: DealCRMContact
): contact is DealCRMIndividualContact => contact.tag === "individual"

export const isDealCRMBrokerContact = (contact: DealCRMContact): contact is DealCRMBrokerContact =>
  contact.tag === "broker"

// eslint-disable-next-line prettier/prettier
export function viewDealCRMContactIdFields(
  contact: DealCRMBrokerContact
): DealCRMBrokerContactIdFields
// eslint-disable-next-line prettier/prettier
export function viewDealCRMContactIdFields(
  contact: DealCRMIndividualContact
): DealCRMIndividualContactIdFields
export function viewDealCRMContactIdFields(contact: DealCRMContactIdFields): DealCRMContactIdFields
export function viewDealCRMContactIdFields(
  contact: DealCRMContactIdFields
): DealCRMContactIdFields {
  switch (contact.tag) {
    case "firm":
      return {
        id: contact.id,
        name: contact.name,
        tag: contact.tag,
        isBrokerage: contact.isBrokerage,
      }
    default:
      return { id: contact.id, name: contact.name, tag: contact.tag }
  }
}
export const locationStringForCRMContact = ({
  location,
}: Pick<DealCRMContactCommonFields, "location">) =>
  location ? `${location.city}, ${location.state}, ${location.country}` : "-"

export const salesforceIdParser = Parse.Char.alphanumeric
  .repeat()
  .map((s) => s.join(""))
  .mapError(replaceMessages("Expected an alphanumeric string"))

const headerParser = Parse.Char.alphanumeric
  .or(Parse.char(" "))
  .or(Parse.char("?"))
  .repeatAtLeastOnce()
  .map((s) => s.join("").trim())
  .mapError(replaceMessages("Failed to parse headers"))

export const constructDealCRMContactParser = <R>(parser: {
  [key in keyof R]: Parser<string, {}, R[key]>
}) => Parse.csvWithOptionalHeader(parser, headerParser.overNonemptyArray(Parse.char(",")))

export const isBrokerOrBrokerageCRMInterest = ({ contact }: DealCRMInterest) =>
  contact ? contact.tag === "broker" || (contact.tag === "firm" && !!contact.isBrokerage) : false

export const recordUserContactDate = (contact: DealCRMContact, user: UserIdFields, date: Date) =>
  ({
    ...contact,
    lastContactedAt:
      (contact.lastContactedAt ?? Date_.minValue) > date ? contact.lastContactedAt : date,
    userData: {
      ...(contact.userData ?? {}),
      [user.id]: {
        ...((contact.userData ?? {})?.[user.id] ?? {}),
        lastContactedAt:
          (contact.userData?.[user.id]?.lastContactedAt ?? Date_.minValue) > date
            ? contact.userData?.[user.id]?.lastContactedAt
            : date,
      },
    },
  } satisfies DealCRMContact)

const contactsTabs = ["all", "firms", "individuals"] as const
export type ContactsTableTabsType = (typeof contactsTabs)[number]

export const getCRMContactEmail = (contact: {
  tag?: DealCRMContact["tag"]
  email?: string | null | never
}): string | null => (contact.tag !== "firm" ? contact.email ?? null : null)

export const getCRMContactTagName = (contact: { tag: DealCRMContact["tag"]; name: string }) =>
  `${contact.tag}_${contact.name}`
