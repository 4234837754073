import numeral from "numeral"
import { showInterval } from "../utils/data/Interval"
import { Either, Left, Right } from "../containers/Either"
import { Just, Maybe, Nothing } from "../containers/Maybe"
import { isDefined } from "../utils/TypeUtils"
import { AccountIdFields } from "./Account"
import { BrokerClientRelationship } from "./BrokerClient"
import { CompanyIdFields } from "./Company"
import { firestoreConverter } from "./FirestoreConverter"
import { Note } from "./Note"
import { OrderPrice, PriceData } from "./Order/OrderForm/State"
import { Order, OrderDirection, OrderFirmness } from "./Order/Order"
import { OrderStructure, StructureOrderTerms } from "./Order/Types/Terms"
import { UserIdFields } from "./User"
import { OrderFormSize } from "./Order/OrderForm/State"

export const introductionRequestConverter = firestoreConverter<IntroductionRequest>()

export const activeIntroductionRequestStatuses = [
  "requested",
  "opt_in_pending",
  "connected",
] as const
export const deadIntroductionRequestStatuses = ["client_refused", "caplight_rejected"] as const
export const introductionRequestStatuses = [
  ...activeIntroductionRequestStatuses,
  ...deadIntroductionRequestStatuses,
] as const

type IntroductionRequestStatusesTypes = typeof introductionRequestStatuses

export type IntroductionRequestStatus = IntroductionRequestStatusesTypes[number]

export type IntroductionAnchorTradeOrOrderId = {
  type: "order" | "closedTrade" | "darkpool_order" | "tentative_interest"
  airtableId?: string // For derivative orders
  id: string
}
export type AnchorTradeOrOrder = IntroductionAnchorTradeOrOrderId & {
  price?: OrderPrice | null
  direction?: OrderDirection | null
  accountId: string
  createdAt?: Date
  volumeUSD?: number
  structure?: string
}
export type IntroductionRequestPriceMatch = "match" | "differ" | "unknown"

const requiredIntroductionRequestDataKeys = [
  "firmness",
  "price",
  "priceMatch",
  "confirmedAt",
  "clientRelationship",
  "direction",
  "size",
] as const

type RequiredIntroductionRequestDataKeysType = typeof requiredIntroductionRequestDataKeys

export type IntroductionRequestDataError = Set<RequiredIntroductionRequestDataKeysType[number]>

export type V1RequiredIntroductionRequestData = {
  direction: OrderDirection | null
  firmness: OrderFirmness
  price: OrderPrice
  priceMatch: IntroductionRequestPriceMatch
  confirmedAt: Date
  clientRelationship: BrokerClientRelationship
  size: OrderFormSize
}

export type IntroductionRequestData = Partial<V1RequiredIntroductionRequestData> & {
  structures?: OrderStructure[]
  terms?: Partial<StructureOrderTerms>
  notes?: string
  doNotIntroNotes?: string
}

export type IntroductionRequestDataKeys = keyof IntroductionRequestData

export type IntroductionRequestThreadData = {
  anchorThreadId?: string | null
  requesterThreadId?: string | null
  connectionThreadId?: string | null
  adminNotes?: Note[] | null
}

export type IntroductionRequest = IntroductionRequestThreadData & {
  id: string
  status: IntroductionRequestStatus
  company: CompanyIdFields
  priceObservationId: IntroductionAnchorTradeOrOrderId
  anchorTradeOrOrder: AnchorTradeOrOrder
  requesterOrders: Omit<Order, "attribution" | "layeredStructure">[]
  createdBy: UserIdFields
  account: AccountIdFields
  createdAt: Date
  updatedAt: Date
  data: IntroductionRequestData
  anchorEmailNotificationMetadata?: {
    // TODO: probably move into a field to capture all email-related metadata
    unsentReason?: string
    autoSent?: boolean
  }
  isAdminFlagged?: boolean
}

export const accountIntroductionRequestSide = (
  accountId: string,
  request: IntroductionRequest
): Maybe<"anchor" | "requester"> =>
  accountId === request.anchorTradeOrOrder.accountId
    ? Just("anchor")
    : accountId === request.account.id
    ? Just("requester")
    : Nothing

export const allIntroductionRequestDataFields: (keyof IntroductionRequestData)[] = [
  "firmness",
  "price",
  "priceMatch",
  "size",
  "clientRelationship",
  "direction",
  "terms",
  "confirmedAt",
  "structures",
  "notes",
  "doNotIntroNotes",
]

export type IntroductionRequestFormState = Omit<
  Partial<IntroductionRequestData>,
  "price" | "size"
> &
  Partial<PriceData>
export type DeepPartialIntroduction = Partial<
  Omit<IntroductionRequest, "data"> & { data: IntroductionRequestFormState }
>
export const validateIntroductionRequest = (
  partialIntroduction: IntroductionRequestFormState
): Either<IntroductionRequestDataError, IntroductionRequestFormState> =>
  requiredIntroductionRequestDataKeys.every((key) => isDefined(partialIntroduction[key]))
    ? Right(partialIntroduction)
    : Left(
        new Set(
          requiredIntroductionRequestDataKeys.filter((key) => !isDefined(partialIntroduction[key]))
        )
      )

export const introRequestSizeRangeString = (intro: IntroductionRequest): string =>
  intro.data.size?.amountUSD
    ? showInterval((value) => numeral(value).format("$0a"), true)(intro.data.size.amountUSD)
    : intro.data.size?.shares
    ? showInterval((shares: number) => `${shares} shares`, true)(intro.data.size.shares)
    : ""

export type IntroductionRequestAndAnchor<T> = { requester: T; anchor: T }
export const extractIntroductionRequestAndAnchorOrderIds = (
  intros: IntroductionRequest[]
): IntroductionRequestAndAnchor<string[]> => ({
  requester: intros.flatMap((i) => (i ? i.requesterOrders : [])).map((o) => o.id),
  anchor: intros
    .map(
      (i) =>
        (i?.anchorTradeOrOrder.type === "order" ||
          i?.anchorTradeOrOrder.type === "darkpool_order" ||
          i?.anchorTradeOrOrder.type === "tentative_interest") &&
        i.anchorTradeOrOrder.id
    )
    .flatMap((id) => (id ? [id] : [])),
})

const ordersBelongToUser = (accountId: string, orders?: Order[]): boolean =>
  !!orders?.every((o) => o.source.account.id === accountId)
export const introductionOrdersForAccountId = (
  introductionOrders: IntroductionRequestAndAnchor<Order[]>,
  accountId: string
) => {
  if (ordersBelongToUser(accountId, introductionOrders.anchor)) {
    return introductionOrders.anchor
  } else {
    return introductionOrders.requester
  }
}
export const counterPartyIntroductionOrdersForAccountId = (
  introductionOrders: IntroductionRequestAndAnchor<Order[]>,
  accountId: string
) => {
  if (!ordersBelongToUser(accountId, introductionOrders.anchor)) {
    return introductionOrders.anchor
  } else {
    return introductionOrders.requester
  }
}
