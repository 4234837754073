import { CurrentUser } from "@model/CurrentUser"
import { createContext, useContext } from "react"

export const LoggedInUserContext = createContext<CurrentUser | null>(null)
export const useLoggedInUser = () => {
  const user = useContext(LoggedInUserContext)
  if (user === null) {
    throw new Error(
      "useLoggedInUser returned null. This should never happen, and indicates that this component is not a descendant of LoggedInLayout" // in a better world, this would be a type error. Instead, we have react.
    )
  }
  return user
}
