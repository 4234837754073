import { isNonempty } from "../../utils/data/Array/Nonempty"
import { AccountAccessFields } from "../Account"

export const productAreas = ["markets", "data"] as const
type ProductAreaOption = typeof productAreas
export type ProductArea = ProductAreaOption[number]

const onboardingStatuses = ["not started", "in progress", "complete"] as const
type OnboardingStatusOption = typeof onboardingStatuses
type OnboardingStatus = OnboardingStatusOption[number]

const trialStatuses = ["trial", "trial-expired"] as const
type TrialStatusOption = typeof trialStatuses
type TrialStatus = TrialStatusOption[number]

export const productStatuses = [...onboardingStatuses, ...trialStatuses] as const
type ProductStatusOption = typeof productStatuses
export type ProductStatus = ProductStatusOption[number]

type OnboardingState = { status: OnboardingStatus }

type TrialState = { status: TrialStatus; trialExpiration: Date }
export type ProductAreaState = OnboardingState | TrialState

export type ProductOnboardingState = {
  [Area in ProductArea]?: OnboardingState | TrialState
}

export interface Permissions {
  productAreas: ProductArea[]
}

export interface UserPermissions {
  limitPlatformAccess?: boolean
}

export const isFullAccessCustomer = (userPermissions: UserPermissions): boolean =>
  !userPermissions.limitPlatformAccess

export const isPureDataCustomer = (permissions: Permissions) =>
  isDataCustomer(permissions) && !isMarketsCustomer(permissions)

export const isPureMarketsCustomer = (permissions: Permissions) =>
  !isDataCustomer(permissions) && isMarketsCustomer(permissions)

export const isDataCustomer = (permissions: Permissions) => {
  const accountProductAreas = permissions.productAreas || []
  return accountProductAreas.includes("data")
}

export const isMarketsCustomer = (permissions: Permissions) => {
  const accountProductAreas = permissions.productAreas || []
  return accountProductAreas.includes("markets")
}

export const isEmployeeAccount = (account: AccountAccessFields): boolean =>
  (account.clientType || []).includes("Employee")

export const hasSomePlatformAccess = (permissions: Permissions) => {
  const accountProductAreas = permissions.productAreas || []

  return isNonempty(accountProductAreas)
}
