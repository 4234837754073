import {
  EmailRequestResult,
  PostgresInvestorEmployee,
} from "common/model/postgres/PostgresInvestors"
import { FC, PropsWithChildren, useMemo, useState } from "react"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import Spinner from "../../../components/icons/Spinner"
import { requestEmployeeEmail } from "../../../state/business_logic/postgresData/investors"
import { Button } from "../../../stories/components/Button/Button"
import { handleConsoleError, trackEvent } from "../../../utils/Tracking"

type EmployeeEmailProps = {
  employee: PostgresInvestorEmployee
}

const EmployeeEmail: FC<PropsWithChildren<EmployeeEmailProps>> = ({ employee }) => {
  const user = useLoggedInUser()
  const memoizedAuthUser = useMemo(() => ({ authUser: user.authUser }), [user.authUser])
  const [requesting, setRequesting] = useState(false)

  const [requestResult, setRequestResult] = useState<EmailRequestResult | "not_requested">(
    "not_requested"
  )

  const runRequest = async () => {
    setRequesting(true)
    const res = await requestEmployeeEmail(employee.id, employee.linkedInUrl, memoizedAuthUser)
    trackEvent("investor-email-requested", {
      success: !!res?.email,
    })
    setRequestResult(res)
    setRequesting(false)
  }

  if (requesting) return <Spinner size="xs" />

  if (!employee.email && requestResult === "not_requested")
    return (
      <Button
        variant="secondary"
        size="small"
        label="Request"
        onClick={() => runRequest().catch(handleConsoleError)}
      />
    )

  return (
    <span>
      {employee.email ??
        (requestResult !== "not_requested" ? requestResult?.email ?? "Unknown" : "Unknown")}
    </span>
  )
}
export default EmployeeEmail
