import { Button } from "@stories/components/Button/Button"
import { useClosedTradeForm } from "./ClosedTradeFormContext"

export const ClosedTradeFormButtons = () => {
  const { validateAndSave, onClose, saveStatus } = useClosedTradeForm()
  return (
    <div className="flex items-center space-x-4 w-full">
      <Button onClick={onClose} label="Back" variant="secondary" size="large" isFullWidth />
      <Button
        onClick={validateAndSave}
        label="Save"
        isDisabled={saveStatus === "disabled"}
        size="large"
        isFullWidth
      />
    </div>
  )
}
