import { groupBy, intersection } from "lodash"
import { Just, Maybe, Nothing, nullableToMaybe } from "../../containers/Maybe"
import { unsafeInsertOrAppend } from "../../utils/MapUtils"
import { Rec, UnsafeRec } from "../../utils/RecordUtils"
import { justIfNonempty, Nonempty } from "../../utils/data/Array/Nonempty"
import { Interval } from "../../utils/data/Interval"
import { intervalMidpoint } from "../../utils/data/numeric/NumberInterval"
import { assertUnreachable, identity } from "../../utils/fp/Function"
import { CompanyIdFields } from "../Company"
import { DealCRMInterest } from "../DealCRM/DealCRMInterest"
import { UserIdFields } from "../User"
import { Order as RawOrder } from "./Models/Internal"
import { Order } from "./Models/Wrapped"
import { StructureOrderTerms } from "./Types/Terms"
import { orderLiveUntil } from "./Types/Status"
import { DealSearch } from "../DealSearch/DealSearch"
import { ShareableItem } from "../SharedOrder/SharedOrderResponse"

const dedupeMatches = (results: OrderMatchResult[]): OrderMatchResult[] => {
  const seen = new Map<string, Nonempty<string>>()
  return results.map((res) => ({
    ...res,
    potentialMatches: res.potentialMatches.filter((o) => {
      if (seen.get(o.id())?.includes(res.order.id())) {
        return false
      } else {
        unsafeInsertOrAppend(seen)(res.order.id(), o.id())
        return true
      }
    }),
  }))
}

export const matchOrders = (orders: Order[], logger: (s: string) => void = () => ({})) => {
  const byCompany = groupBy(orders, (o) => o.company().id)
  return dedupeMatches(
    UnsafeRec.values(byCompany).flatMap((x) => matchSameCompanyOrders(x, logger))
  )
}

type OrderMatchResult = {
  order: Order
  potentialMatches: Order[]
}

export type OrderMatchResultStatus = "accepted" | "rejected" | "pending"

type PotentialMatch =
  | {
      tag?: "order"
      order: RawOrder
      status: {
        tag: OrderMatchResultStatus
        notes?: string
        user?: UserIdFields
      }
    }
  | {
      tag: "crm interest"
      crmInterest: DealCRMInterest
      status: {
        tag: OrderMatchResultStatus
        notes?: string
        user?: UserIdFields
      }
    }

export type OrderMatchReport = {
  id?: string
  result: {
    order: RawOrder
    potentialMatches: PotentialMatch[]
  }
  /** Not yet used in any meaningful way but we'll want it later */
  score: number
  company: CompanyIdFields
  date: Date
}

/** This is split from orderMatchClauses so that we can filter out orders before the O(n^2) step */
export const matchableOrder = (o: Order) => {
  const clauses = [o.structureLayersCount().withDefault(0) <= 1]
  return clauses.every(identity)
}

const maxMatchSpread = (a: { company: CompanyIdFields }) =>
  a.company.name === "SpaceX" ? 1.04 : 1.1

export interface Matchable {
  id: string
  accountId: string
  company: CompanyIdFields
  direction: "buy" | "sell"
  structure: (keyof StructureOrderTerms)[]
  size: Interval<number> | null
  price: number | null
  targetValuation: number | null
  managementFee: number | null
  tag: RawOrder["orderCollection"] | "crm interest" | "deal search"
  liveUntil: Date | null
}

export type MatchSuggestion = {
  id: string
  matchable1: Matchable
  matchable2: Matchable
  viewed: boolean
  dismissed: boolean
  liveUntil: Date
}
export type MatchableWithMatchSuggestion = Matchable & { matchSuggestionId: string }

export const isMatchSuggestionEquivalent = (a: MatchSuggestion, b: MatchSuggestion) =>
  a.matchable1.id === b.matchable1.id && a.matchable2.id === b.matchable2.id

// Deprecated - TODO: Remove this function after refactoring matchSuggestions
const getOrderSuggestionsFromMatchable = (matchable: Matchable, orders: Order[]): Order[] => {
  const potentialMatches = orders.filter(matchableOrder).filter((b) => {
    const clauses = getMatchClauses(matchable, orderToMatchable(b))
    const match = parseMatchClauses(clauses, orderMatchConditions)
    return match
  })
  return potentialMatches
}

// Deprecated - TODO: Remove this function after refactoring matchSuggestions
export const getOrderSuggestionsFromCRMInterest = (
  interest: DealCRMInterest,
  orders: Order[]
): Order[] => {
  const matchableInterest = crmInterestToMatchable(interest)
  return getOrderSuggestionsFromMatchable(matchableInterest, orders)
}

export const buildMatchSuggestion = (
  matchable1: Matchable,
  matchable2: Matchable
): MatchSuggestion => ({
  id: `${matchable1.id}-${matchable2.id}`,
  matchable1,
  matchable2,
  viewed: false,
  dismissed: false,
  liveUntil:
    matchable1.liveUntil && matchable2.liveUntil
      ? matchable1.liveUntil < matchable2.liveUntil
        ? matchable1.liveUntil
        : matchable2.liveUntil
      : new Date(),
})

export const orderToMatchable = (o: Order): Matchable => {
  const internalOrder = o.toInternal()

  return {
    id: o.id(),
    accountId: internalOrder.source.account.id,
    company: o.company(),
    direction: o.direction(),
    structure: o.currentStructures().map((x) => x.rawStructure()),
    size: o.amountUSD().toNullable() ?? null,
    price: o.impliedPricePerShare().toNullable() ?? null,
    targetValuation: o.targetValuation().toNullable() ?? null,
    managementFee: o.managementFee().toNullable() ?? null,
    tag: internalOrder.orderCollection,
    liveUntil: orderLiveUntil(internalOrder),
  } satisfies Matchable
}

export const shareableItemToMatchable = (shareableItem: ShareableItem): Matchable | null =>
  shareableItem.tag === "order"
    ? Order.wrapRaw({
        order: shareableItem.order,
        company: Nothing,
      })
        .map(orderToMatchable)
        .toNullable() ?? null
    : shareableItem.tag === "crm_interest"
    ? crmInterestToMatchable(shareableItem.crmInterest)
    : shareableItem.tag === "deal_search"
    ? dealSearchToMatchable(shareableItem.dealSearch)
    : assertUnreachable(shareableItem)

export const crmInterestToMatchable = (i: DealCRMInterest): Matchable => ({
  id: i.id,
  accountId: i.account.id,
  company: i.company,
  direction: i.direction,
  structure: i.structure ? [i.structure] : [],
  size: i.amountUSD,
  price: i.targetPrice,
  targetValuation: i.targetValuation,
  managementFee: i.managementFeePercent,
  tag: "crm interest",
  liveUntil: null,
})

export const dealSearchToMatchable = (i: DealSearch): Matchable & { tag: "deal search" } => ({
  id: i.id,
  accountId: i.account.id,
  company: i.company,
  direction: i.direction,
  structure: i.structure ? [i.structure] : [],
  size: i.amountUSD,
  price: i.targetPrice,
  targetValuation: i.targetValuation,
  managementFee: i.managementFeePercent,
  tag: "deal search",
  liveUntil: null,
})

const matchClauseKeys = [
  "direction",
  "structure",
  "size",
  "price",
  "managementFee",
  "company",
] as const
type MatchClauseKey = (typeof matchClauseKeys)[number]

export type MatchClauses = Record<MatchClauseKey, Maybe<number>>
export type MatchClausesWithDefaults = Record<MatchClauseKey, number>

const matchPrices = (a: Matchable, b: Matchable): Maybe<number> => {
  const justIfValidPrice = (price: number | null) =>
    nullableToMaybe(price).bind((p) => (p > 0 ? Just(p) : Nothing))

  const pricePerShareMatch = justIfValidPrice(a.price)
    .alongside(justIfValidPrice(b.price))
    .map(([l, r]) =>
      l === r || (l / r <= maxMatchSpread(a) && r / l <= maxMatchSpread(a)) ? 1 : 0
    )
  const valuationMatch = justIfValidPrice(a.targetValuation)
    .alongside(justIfValidPrice(b.targetValuation))
    .map(([l, r]) =>
      l === r || (l / r <= maxMatchSpread(a) && r / l <= maxMatchSpread(a)) ? 1 : 0
    )

  return pricePerShareMatch.or(valuationMatch)
}
export const getMatchClauses = (a: Matchable, b: Matchable): MatchClauses => ({
  company: Just(a.company.id === b.company.id ? 1 : 0),
  direction: Just(b.direction === a.direction ? 0 : 1),
  structure: nullableToMaybe(a.structure)
    .bind(justIfNonempty)
    .alongside(nullableToMaybe(b.structure).bind(justIfNonempty))
    .map(([l, r]) => (intersection(l, r).length > 0 ? 1 : 0)),
  size: nullableToMaybe(a.size)
    .alongside(nullableToMaybe(b.size))
    .map(([l, r]) => {
      const midpointL = intervalMidpoint(l)
      const midpointR = intervalMidpoint(r)
      return midpointL === midpointR ||
        (midpointL / midpointR < 2 && midpointR / midpointL < 2) ||
        Math.max(l.lowerBound, r.lowerBound) <= Math.min(l.upperBound, r.upperBound)
        ? 1
        : 0
    }),
  price: matchPrices(a, b),
  managementFee: nullableToMaybe(a.managementFee)
    .alongside(nullableToMaybe(b.managementFee))
    .map(([l, r]) => {
      const buyerFee = a.direction === "buy" ? l : r
      const sellerFee = a.direction === "buy" ? r : l
      return sellerFee <= buyerFee ? 1 : 0
    }),
})

type MatchCondition = {
  defaultScore: number
  minimumScore: number
}

type MatchConditions = Record<MatchClauseKey, MatchCondition>

export const orderMatchConditions: MatchConditions = {
  company: {
    defaultScore: 0,
    minimumScore: 1,
  },
  direction: {
    defaultScore: 0,
    minimumScore: 1,
  },
  structure: {
    defaultScore: 0,
    minimumScore: 1,
  },
  size: {
    defaultScore: 0,
    minimumScore: 1,
  },
  price: {
    defaultScore: 0,
    minimumScore: 1,
  },
  managementFee: {
    defaultScore: 1,
    minimumScore: 1,
  },
}

export const applyDefaultsToMatchClauses = (
  clauses: MatchClauses,
  conditions: MatchConditions
): MatchClausesWithDefaults =>
  Rec.indexedMap(clauses, ([key, clause]) => clause.withDefault(conditions[key].defaultScore))

export const getMatchClausesWithDefaults = (
  a: Matchable,
  b: Matchable,
  conditions: MatchConditions
): Record<MatchClauseKey, number> => applyDefaultsToMatchClauses(getMatchClauses(a, b), conditions)

const parseMatchClauses = (clauses: MatchClauses, conditions: MatchConditions): boolean =>
  Rec.every(
    clauses,
    ([key, clause]) =>
      clause.withDefault(conditions[key].defaultScore) >= conditions[key].minimumScore
  )

export const getSuggestionsFromMatchable = <T extends Matchable>(
  matchable: Matchable,
  matchables: T[],
  conditions: MatchConditions
): T[] =>
  matchables.filter((b) => {
    const clauses = getMatchClauses(matchable, b)
    const match = parseMatchClauses(clauses, conditions)
    return match
  })

// inefficient but fine for n ~ a few hundred
export const matchSameCompanyOrders = (
  orders: Order[],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logger: (s: string) => void = () => {}
): OrderMatchResult[] =>
  orders
    .filter(matchableOrder)
    .map((a) => ({
      order: a,
      potentialMatches: orders.filter(matchableOrder).filter((b) => {
        const clauses = getMatchClauses(orderToMatchable(a), orderToMatchable(b))
        const match = parseMatchClauses(clauses, orderMatchConditions)
        logger(
          `Match score for orders ${a.id()} and ${b.id()}: ${JSON.stringify(
            applyDefaultsToMatchClauses(clauses, orderMatchConditions)
          )}; result: ${JSON.stringify(match)}`
        )
        return match
      }),
    }))
    .filter(({ potentialMatches }) => potentialMatches.length > 0)

export const dealSearchMatchConditions: MatchConditions = {
  company: {
    defaultScore: 0,
    minimumScore: 1,
  },
  direction: {
    defaultScore: 0,
    minimumScore: 1,
  },
  structure: {
    defaultScore: 1,
    minimumScore: 1,
  },
  size: {
    defaultScore: 1,
    minimumScore: 1,
  },
  price: {
    defaultScore: 1,
    minimumScore: 1,
  },
  managementFee: {
    defaultScore: 1,
    minimumScore: 1,
  },
}

export const getSuggestionsFromDealSearch = <T extends Matchable>(
  dealSearch: DealSearch,
  matchables: T[]
): T[] =>
  getSuggestionsFromMatchable(
    dealSearchToMatchable(dealSearch),
    matchables,
    dealSearchMatchConditions
  ).filter((match) => match.id !== dealSearch.id)
