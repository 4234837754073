import { useOrderDetailsDrawer } from "@components/OrderDetails/OrderDetailsWrapper"
import { PageTitle } from "@components/typography/PageTitle"
import { Result, Spin } from "@stories/components/Antd"
import { isLoaded } from "common/utils/Loading"
import { AnimatePresence, motion } from "framer-motion"
import moment from "moment"
import { Link } from "react-router-dom"
import { Routes } from "src/Routes/Routes"
import { Button } from "@stories/components/Button/Button"
import { OrderPageOrderButton } from "../shared/OrderPageOrderButton"
import { useOrderForm } from "./OrderFormContext"
import { useDrawerControls } from "@components/Drawer/DrawerControlContext"
import { ShareOrderButton } from "@components/ShareOrder/ShareOrderButton"

const SavedStatus = () => {
  const { savedOrder, formOrder, company } = useOrderForm()
  const { onClose } = useDrawerControls()
  const handleViewOrderDetails = useOrderDetailsDrawer()

  const onViewMyOrders = () => {
    if (savedOrder) {
      handleViewOrderDetails(savedOrder)
      onClose()
    }
  }

  const successTitle = formOrder.darkpool
    ? `Your ${formOrder.direction === "buy" ? "Bid" : "Offer"} is saved.`
    : `Your ${formOrder.direction === "buy" ? "Bid" : "Offer"} is now live.`

  const isUnpricedOrder = formOrder.price === null || formOrder.price === undefined

  return (
    <Result
      className="mt-24"
      status="success"
      extra={
        <div className="flex flex-col items-center justify-center gap-4">
          <PageTitle title={successTitle} />
          {savedOrder && formOrder && isLoaded(company) ? (
            <>
              <div className="max-w-150 flex flex-col gap-4 items-center justify-center">
                <div>
                  {formOrder.darkpool ? null : (
                    <Link to={Routes.orders.root}>{`You can find your ${company.name} ${
                      formOrder.direction === "buy" ? "Bid" : "Offer"
                    } in the Live Market.`}</Link>
                  )}
                </div>
                {!isUnpricedOrder && (
                  <div>
                    {`You can now connect to ${company.name} ${
                      formOrder.direction === "buy" ? "offers" : "bids"
                    }
              with this ${formOrder.direction === "buy" ? "bid" : "offer"}${
                      formOrder.darkpool
                        ? "."
                        : ` and ${company.name} ${
                            formOrder.direction === "buy" ? "sellers" : "buyers"
                          } can now connect against your ${
                            formOrder.direction === "buy" ? "bid" : "offer"
                          }`
                    }`}
                  </div>
                )}
              </div>

              {formOrder.darkpool ? null : (
                <div>{`This order will expire on ${moment(savedOrder._derived.liveUntil).format(
                  "MMM D, YYYY"
                )} (${moment(savedOrder._derived.liveUntil).fromNow()})`}</div>
              )}

              <>
                <div className="flex space-x-4">
                  <ShareOrderButton
                    item={{ id: savedOrder.id, order: savedOrder, tag: "order" }}
                    variant="secondary"
                    size="large"
                  />
                  <OrderPageOrderButton />
                </div>
                <Button
                  size="large"
                  label="View This Order"
                  variant="hollow"
                  onClick={onViewMyOrders}
                />
              </>

              {/* {companyInPostgres(company) ? (
                <div className="mt-8">
                  <AddCompanyToWatchlist company={company} />
                </div>
              ) : null} */}
            </>
          ) : null}
        </div>
      }
    />
  )
}
export const ErrorStatus = () => (
  <Result
    className="mt-24"
    status="warning"
    extra={
      <div className="flex flex-col items-center justify-center">
        <PageTitle title="An Error Occurred" />
        <Button label="Close" onClick={useDrawerControls().onClose} />
      </div>
    }
  />
)
export const SavingStatus = () => (
  <div className="m-auto flex flex-col items-center mt-24 justify-center">
    <div className="flex flex-col h-32 justify-center">
      <Spin size="large" />
    </div>
    <PageTitle title="Your order is saving." />
  </div>
)

export const OrderFormConfirmationPage = () => {
  const { saveStatus } = useOrderForm()
  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={saveStatus}
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 10, opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        {saveStatus === "saved" ? <SavedStatus /> : null}
        {saveStatus === "error" ? <ErrorStatus /> : null}
        {saveStatus === "saving" ? <SavingStatus /> : null}
      </motion.div>
    </AnimatePresence>
  )
}
