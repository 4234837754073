import React from "react"
import Loader, { LoaderProps } from "react-loader-spinner"

/* eslint-disable react/jsx-props-no-spreading */

const CapLoader: React.FC<React.PropsWithChildren<Partial<LoaderProps>>> = ({
  height = 80,
  width = 80,
  ...restProps
}) => (
  <div className="w-full flex flex-row items-center justify-center">
    <Loader type="TailSpin" color="#335D6C" height={height} width={width} {...restProps} />
  </div>
)

export default CapLoader
