import { RecFunc } from "../Record/Mapping"
import { IReifiedRecTraversal, RecTraversal, ReifiedRecTraversal, composeRecTraversal } from "./RecTraversal"

/** A lens picks out a particular factor within a product type.
 * A `Lens<S, T, A, B>` allows us to view an `A` within an `S`, or replace with with a `B` to yield a `T`.
 */
export interface RecLens<S, T, A, B> {
  view: (s: S) => A
  over: (f: (a: A) => B) => RecFunc<S, T>
}
export type SimpleRecLens<S, A> = RecLens<S, S, A, A>

export const field =
  <K extends string | number | symbol>(key: K) =>
  <R extends Record<K, unknown>>(): ReifiedSimpleRecLens<R, R[K]> =>
    new ReifiedRecLens({ view: (r) => r[key], over: (f) => new RecFunc((r) => ({ ...r, [key]: f(r[key]) })) })

export const composeRecLens = <S, T, A, B, X, Y>(
  l: RecLens<S, T, A, B>,
  r: RecLens<A, B, X, Y>
): RecLens<S, T, X, Y> => ({
  view: (s) => r.view(l.view(s)),
  over: (f) => l.over(r.over(f).run),
})


export interface IReifiedRecLens<S, T, A, B> extends RecLens<S, T, A, B>, IReifiedRecTraversal<S, T, A, B> {
  composeRecLens: <X, Y>(l: RecLens<A, B, X, Y>) => ReifiedRecLens<S, T, X, Y>
}

export class ReifiedRecLens<S, T, A, B> implements IReifiedRecLens<S, T, A, B> {
  readonly view: (s: S) => A

  readonly over: (f: (a: A) => B) => RecFunc<S, T>

  constructor(lens: RecLens<S, T, A, B>) {
    this.view = lens.view
    this.over = lens.over
  }

  views = (s: S) => [this.view(s)]

  composeRecLens = <X, Y>(l: RecLens<A, B, X, Y>): ReifiedRecLens<S, T, X, Y> =>
    new ReifiedRecLens(composeRecLens(this, l))

  composeRecTraversal = <X, Y>(t: RecTraversal<A, B, X, Y>): ReifiedRecTraversal<S, T, X, Y> =>
    new ReifiedRecTraversal(composeRecTraversal(this, t))
}
export type ReifiedSimpleRecLens<S, A> = ReifiedRecLens<S, S, A, A>
