import { Account } from "common/model/Account"
import { ArchiveReason } from "common/model/Opportunity/fns/archiveOpportunity"
import { ConstructedOpportunityInboxFilterRule } from "common/model/Order/OrderFilter/OpportunityInboxFilterRule"
import { Order as WrappedOrder } from "common/model/Order/Models/Wrapped"
import { buildCompanyOrderRule } from "../OrderOpportunityRuleForms/CompanyOrderOpportunityRuleForm"
import moment from "moment"

export const notInterestedRules: Record<
  ArchiveReason,
  ({
    account,
    order,
  }: {
    account: Account
    order: WrappedOrder
  }) => ConstructedOpportunityInboxFilterRule
> = {
  direction: ({ account, order }: { account: Account; order: WrappedOrder }) =>
    buildCompanyOrderRule({
      account,
      rule: { company: order.company(), direction: order.direction() },
    }),
  "price too high": ({ account, order }: { account: Account; order: WrappedOrder }) =>
    buildCompanyOrderRule({
      account,
      rule: {
        company: order.company(),
        direction: order.direction(),
        expiresAt: moment().add(2, "weeks").toDate(),
        atom: {
          tag: "price",
          comparison: "geq",
          limit: order.impliedPricePerShare().match(
            (n) => n,
            () => 0
          ),
        },
      },
    }),
  "price too low": ({ account, order }: { account: Account; order: WrappedOrder }) =>
    buildCompanyOrderRule({
      account,
      rule: {
        company: order.company(),
        direction: order.direction(),
        expiresAt: moment().add(2, "weeks").toDate(),
        atom: {
          tag: "price",
          comparison: "lt",
          limit: order.impliedPricePerShare().match(
            (n) => n,
            () => 0
          ),
        },
      },
    }),
  "size too high": ({ account, order }: { account: Account; order: WrappedOrder }) =>
    buildCompanyOrderRule({
      account,
      rule: {
        company: order.company(),
        direction: order.direction(),
        atom: {
          tag: "volume",
          comparison: "geq",
          limit: order.amountUSD().match(
            (amountUSD) => amountUSD.lowerBound,
            () => 0
          ),
        },
      },
    }),
  "size too low": ({ account, order }: { account: Account; order: WrappedOrder }) =>
    buildCompanyOrderRule({
      account,
      rule: {
        company: order.company(),
        direction: order.direction(),
        atom: {
          tag: "volume",
          comparison: "lt",
          limit: order.amountUSD().match(
            (amountUSD) => amountUSD.upperBound,
            () => 0
          ),
        },
      },
    }),
  structure: ({ account, order }: { account: Account; order: WrappedOrder }) =>
    buildCompanyOrderRule({
      account,
      rule: {
        company: order.company(),
        direction: order.direction(),
        atom: {
          tag: "structure",
          orderStructure: order.currentStructures().map((s) => s.rawStructure()),
        },
      },
    }),
}
