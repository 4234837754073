import FeatureWrapper from "src/providers/featureFlags/FeatureWrapper"
import { ContactDetailsDrawerWrapper } from "./Contacts/ContactDetailsDrawer/ContactDetailsDrawer"
import { ContactDetailsDrawerWrapper as ContactDetailsDrawerWrapperDeprecated } from "./Contacts/ContactDetailsDrawer/ContactDetailsDrawerDeprecated"
import { BuySellInterestProvider } from "./Providers/BuySellInterestProvider"
import { CRMContactsProvider } from "./Providers/CRMContactsProvider"
import { CRMDealsProvider } from "./Providers/CRMDealsProvider"
import { CRMFundsProvider } from "./Providers/CRMFundsProvider"
import { ContactHoldingsProvider } from "./Providers/ContactHoldingsProvider"

export const CRMProviders: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <CRMContactsProvider>
    <BuySellInterestProvider>
      <ContactHoldingsProvider>
        <CRMDealsProvider>
          <FeatureWrapper
            flagName="crm_emails_integration"
            defaultRender={
              <ContactDetailsDrawerWrapperDeprecated>
                <CRMFundsProvider>{children}</CRMFundsProvider>
              </ContactDetailsDrawerWrapperDeprecated>
            }
          >
            <ContactDetailsDrawerWrapper>
              <CRMFundsProvider>{children}</CRMFundsProvider>
            </ContactDetailsDrawerWrapper>
          </FeatureWrapper>
        </CRMDealsProvider>
      </ContactHoldingsProvider>
    </BuySellInterestProvider>
  </CRMContactsProvider>
)
