import { intervalMidpoint } from "../../../../utils/data/numeric/NumberInterval"
import { assertUnreachable } from "../../../../utils/fp/Function"
import { Just, nullableToMaybe } from "../../../../containers/Maybe"
import { orderQuantityTermsVolume } from "../../../Order/Types/Terms"
import { OutlierCheckContext, OutlierCheckPoint } from "../OutlierCheckContext"

export const volumeBounds = (ctx: OutlierCheckContext, point: OutlierCheckPoint) => {
  const volume =
    point.tag === "trade"
      ? Just(point.trade.volume)
      : point.tag === "order"
      ? nullableToMaybe(orderQuantityTermsVolume(point.order.quantityTerms)).map(intervalMidpoint)
      : assertUnreachable(point)
  return volume.match(
    (v) => v < 1e4 || v > 1e8,
    () => false
  )
}
