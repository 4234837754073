import YesNoNullDropdown from "@components/inputs/YesNoNullDropdown"
import { PercentageField } from "@stories/components/Inputs/NumberInput/PercentageField"
import { LabelValueList } from "@stories/components/LabelValueList/LabelValueList"
import { isIntermediaryAccount } from "common/model/Account"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { isLoaded } from "common/utils/Loading"
import { isNil } from "lodash"
import { UpdateContactProps } from "src/firebase/crm"
import { useCurrentAccount } from "src/queries/currentUser/useCurrentAccount"
import ContactSectorFocusList from "./ContactSectorFocusList"

export const InvestmentPreferences = ({
  contact,
  updateContact,
  title,
}: {
  contact: DealCRMContact
  updateContact: (params: UpdateContactProps<DealCRMContact>) => unknown
  title?: string
}) => {
  const account = useCurrentAccount()
  const isBrokerUser = isLoaded(account) && isIntermediaryAccount(account)

  return (
    <div className="flex-1 flex flex-col gap-2">
      <LabelValueList
        title={title ?? "Investment Preferences"}
        items={[
          {
            id: "openToSPV",
            label: "Open to SPVs",
            value: (
              <YesNoNullDropdown
                value={contact.investmentPreferences?.openToSPVs ?? null}
                onChange={(newValue) =>
                  updateContact({
                    contact,
                    update: {
                      investmentPreferences: {
                        ...contact.investmentPreferences,
                        openToSPVs: newValue,
                      },
                    },
                  })
                }
              />
            ),
          },
          {
            id: "openToDualLayerSPV",
            label: "Open to Dual Layer SPV",
            hide: !isBrokerUser,
            value: (
              <YesNoNullDropdown
                value={contact.investmentPreferences?.openToDoubleLayerSPVs ?? null}
                onChange={(newValue) =>
                  updateContact({
                    contact,
                    update: {
                      investmentPreferences: {
                        ...contact.investmentPreferences,
                        openToDoubleLayerSPVs: newValue,
                      },
                    },
                  })
                }
              />
            ),
          },
          {
            id: "maxManagementFeeCarry",
            label: "Max Management Fee & Carry",
            hide: !isBrokerUser,
            value: (
              <div className="flex items-center gap-2">
                <span className="text-xs flex flex-row gap-1 items-center">
                  <span className="w-20">
                    <PercentageField
                      name="investmentPreferences.maxManagementFee"
                      value={contact.investmentPreferences?.maxManagementFee}
                      max={0.05}
                      onChange={(fee) =>
                        updateContact({
                          contact,
                          update: {
                            investmentPreferences: {
                              ...contact.investmentPreferences,
                              maxManagementFee: isNil(fee) ? null : fee,
                            },
                          },
                        })
                      }
                    />
                  </span>
                  mgmt fee
                </span>
                <span>/</span>
                <span className="text-xs flex flex-row gap-1 items-center">
                  <span className="w-20">
                    <PercentageField
                      name="investmentPreferences.maxCarry"
                      value={contact.investmentPreferences?.maxCarry}
                      max={0.3}
                      onChange={(maxCarry) =>
                        updateContact({
                          contact,
                          update: {
                            investmentPreferences: {
                              ...contact.investmentPreferences,
                              maxCarry: isNil(maxCarry) ? null : maxCarry,
                            },
                          },
                        })
                      }
                    />
                  </span>
                  carry
                </span>
              </div>
            ),
          },
          {
            id: "openToForwards",
            label: "Open to Forwards",
            hide: !isBrokerUser,
            value: (
              <YesNoNullDropdown
                value={contact.investmentPreferences?.openToForwards ?? null}
                onChange={(newValue) =>
                  updateContact({
                    contact,
                    update: {
                      investmentPreferences: {
                        ...contact.investmentPreferences,
                        openToForwards: newValue,
                      },
                    },
                  })
                }
              />
            ),
          },
          {
            id: "requireDiligenceBuySide",
            label: "Require Diligence",
            value: (
              <YesNoNullDropdown
                value={contact.investmentPreferences?.requiresDiligence ?? null}
                onChange={(newValue) =>
                  updateContact({
                    contact,
                    update: {
                      investmentPreferences: {
                        ...contact.investmentPreferences,
                        requiresDiligence: newValue,
                      },
                    },
                  })
                }
              />
            ),
          },
          {
            id: "paysBrokersFees",
            label: "Pays Brokers Fees?",
            hide: !isBrokerUser,
            value: (
              <YesNoNullDropdown
                value={contact.investmentPreferences?.paysBrokerFees ?? null}
                onChange={(newValue) =>
                  updateContact({
                    contact,
                    update: {
                      investmentPreferences: {
                        ...contact.investmentPreferences,
                        paysBrokerFees: newValue,
                      },
                    },
                  })
                }
              />
            ),
          },
          {
            id: "typicalCommission",
            label: "Typical Commission Charged",
            hide: !isBrokerUser,
            value: (
              <PercentageField
                name="typicalCommission"
                value={contact.typicalBrokerCommissionPercent}
                max={0.1}
                onChange={(newCommission) =>
                  updateContact({
                    contact,
                    update: {
                      typicalBrokerCommissionPercent: newCommission,
                    },
                  })
                }
              />
            ),
          },
          {
            id: "cashOnHand",
            label: "Has Cash on Hand",
            value: (
              <YesNoNullDropdown
                value={contact.investmentPreferences?.cashOnHand ?? null}
                onChange={(newValue) =>
                  updateContact({
                    contact,
                    update: {
                      investmentPreferences: {
                        ...contact.investmentPreferences,
                        cashOnHand: newValue,
                      },
                    },
                  })
                }
              />
            ),
          },
          {
            id: "sectorFocus",
            label: "Sector Focus",
            value: <ContactSectorFocusList contact={contact} updateContact={updateContact} />,
          },
        ]}
      />
    </div>
  )
}
