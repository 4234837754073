import { AccessControlFeatureName, AccessLevel } from "common/model/AccessControl/AccessControl"
import { ReactNode } from "react"
import { useAccessControl } from "./AccessControlProvider"

export const GenericAccessControlWrapper = ({
  feature,
  component,
}: {
  feature: AccessControlFeatureName
  component: (accessLevel: AccessLevel) => ReactNode
}) => {
  const { getFeatureAccessLevel } = useAccessControl()
  const accessLevel = getFeatureAccessLevel(feature)
  return component(accessLevel)
}
