import moment from "moment"
import { capitalize } from "lodash"
import { formatAbbreviatedCurrency, formatPrice } from "common/utils/math/format"
import { FlatOrder, flattenOrderForDisplay } from "common/queries/pricing/displays/Order"
import {
  orderQuantityTermsPrice,
  orderQuantityTermsVolume,
  orderStructureDisplayName,
  orderQuantityTermsTargetValuation,
} from "common/model/Order/Types/Terms"
import { Order } from "common/model/Order/Order"
import { formatValuation } from "@components/displays/numeric/Currency"
import { nullableToMaybe } from "common/containers/Maybe"
import { DirectionCell } from "src/pages/Orders/shared/Cells"
import { FC } from "react"
import { showInterval } from "common/utils/data/Interval"
import { DarkpoolIcon } from "@stories/icons/DarkpoolIcon"
import { Tooltip } from "@stories/components/Antd"
import { Color } from "@stories/components/Typography/Typography"
import { shortDateFormat } from "common/utils/dateUtils"
import { DataDisplayConstants } from "common/UI/DataDisplayConstants"

export const allDisplayFields = [
  "size",
  "price",
  "fee",
  "structure",
  "submitted",
  "direction",
  "shareClasses",
  "clientRelationship",
  "clientROFR",
  "areTransactionDocumentOnHand",
] as const
export type DisplayFields = (typeof allDisplayFields)[number]

const PreviewFieldComponent =
  ({ hiddenFields, hideHeaders }: { hiddenFields: string[]; hideHeaders?: boolean }) =>
  ({
    title,
    value,
    hiddenField,
    tooltip,
  }: {
    title: string
    value: string
    hiddenField: DisplayFields
    tooltip?: string
  }) =>
    (
      <>
        {!hiddenFields.includes(hiddenField) ? (
          <Tooltip title={tooltip}>
            <div className="p-1 flex-1">
              <div className="flex flex-col">
                {hideHeaders ? null : <p className="text-xs mb-1 whitespace-nowrap">{title}</p>}
                <p className="text-xs font-semibold whitespace-nowrap">{value}</p>
              </div>
            </div>
          </Tooltip>
        ) : null}
      </>
    )

interface PreviewProps {
  hideHeaders?: boolean
  hiddenFields: DisplayFields[]
  direction: Order["direction"] | null
  darkpool: boolean
  sizeString: string
  priceString: string
  feeString: string
  structureString: string
  createdAtDateString: string
  shareClassesString: string
  clientRelationshipString: string
  areTransactionDocumentOnHandString: string
  clientROFRString: string
}

export const Preview = (props: PreviewProps) => {
  const {
    hideHeaders,
    hiddenFields,
    direction,
    darkpool,
    sizeString,
    priceString,
    feeString,
    structureString,
    createdAtDateString,
    shareClassesString,
    clientRelationshipString,
    areTransactionDocumentOnHandString,
    clientROFRString,
  } = props

  const PreviewField = PreviewFieldComponent({ hiddenFields, hideHeaders })
  const buyer = direction === "buy"

  return (
    <div className="flex items-center flex-wrap overflow-x-auto px-2">
      {!hiddenFields.includes("direction") && direction ? (
        <div className="p-2 flex-grow-1 flex items-center">
          <DirectionCell direction={direction} />
          {darkpool ? <DarkpoolIcon color={Color.Black} /> : null}
        </div>
      ) : null}
      <PreviewField title="Size" value={sizeString} hiddenField="size" />
      <PreviewField title="Price" value={priceString} hiddenField="price" />
      <PreviewField title="Structure" value={structureString} hiddenField="structure" />
      {feeString ? (
        <PreviewField
          title="SPV Fees"
          value={feeString}
          hiddenField="fee"
          tooltip="SPV Management Fee / Carry Percentage"
        />
      ) : null}
      <PreviewField title="Submitted" value={createdAtDateString} hiddenField="submitted" />
      <PreviewField
        title={buyer ? "Share class requirements" : "Share Classes"}
        value={shareClassesString.length > 0 ? shareClassesString : "Unspecified"}
        hiddenField="shareClasses"
      />
      <PreviewField
        title="Deal Execution Documents On Hand"
        value={areTransactionDocumentOnHandString}
        hiddenField="areTransactionDocumentOnHand"
        tooltip="Indicates whether the broker or client behind this order has the necessary documents relevant for a transaction in this company (stock transfer agreement, stock purchase agreement, etc)."
      />
      {!buyer && (
        <PreviewField
          title="Seller Subject to ROFR?"
          value={clientROFRString}
          hiddenField="clientROFR"
        />
      )}
      <PreviewField
        title="Client Relationship"
        value={clientRelationshipString}
        hiddenField="clientRelationship"
        tooltip="Relationship between the contributor of the order and the end buyer or seller. Direct means the contributor is working directly with or is the buyer or seller."
      />
    </div>
  )
}

const EMPTY_STRING = "Unspecified"
const flatOrderToStrings = (flat: FlatOrder) => ({
  sizeString: flat.quantity
    .map(orderQuantityTermsVolume)
    .bind(nullableToMaybe)
    .map(showInterval(formatValuation, true))
    .withDefault(EMPTY_STRING),
  priceString: flat.quantity
    .map(orderQuantityTermsPrice)
    .bind(nullableToMaybe)
    .map(formatPrice)
    .or(
      flat.quantity
        .bind(orderQuantityTermsTargetValuation)
        .map(formatValuation)
        .map((val) => `${val} val.`)
    )
    .withDefault(EMPTY_STRING),
  feeString:
    flat.structure === "spv"
      ? `${flat.managementFee.match(
          (n) => `${n}`,
          () => EMPTY_STRING
        )} / ${flat.carry.match(
          (n) => `${n}`,
          () => EMPTY_STRING
        )}`
      : "",
  createdAtDateString: moment(flat.orderOriginationDate).fromNow(),
  structureString: orderStructureDisplayName[flat.structure] ?? EMPTY_STRING,
  direction: flat.direction,
  shareClassesString: flat.shareClasses
    ? flat.shareClasses.includes("common") && flat.shareClasses.includes("preferred")
      ? "No Preference"
      : flat.shareClasses.map(capitalize).join(", ")
    : EMPTY_STRING,
  clientRelationshipString: capitalize(flat.brokerClientRelationship.toNullable() ?? EMPTY_STRING),
  areTransactionDocumentOnHandString: flat.areTransactionDocumentOnHand.match(
    (v) => (v ? "Yes" : "No"),
    () => EMPTY_STRING
  ),
  clientROFRString: flat.clientROFR.match(
    (v) => (v ? "Yes" : "No"),
    () => EMPTY_STRING
  ),
})

export const OrderPreviewWrapper: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <div className="p-1 bg-neutral-100 border border-neutral-400 rounded-md w-full overflow-x-hidden">
    {children}
  </div>
)

export const OrderPreview = ({
  order,
  hiddenFields = ["direction"],
  hideHeaders,
}: {
  order: Order
  hiddenFields?: DisplayFields[]
  hideHeaders?: boolean
}) =>
  flattenOrderForDisplay(order)
    .map(flatOrderToStrings)
    .match(
      ({
        sizeString,
        priceString,
        feeString,
        createdAtDateString,
        structureString,
        direction,
        shareClassesString,
        clientRelationshipString,
        areTransactionDocumentOnHandString,
        clientROFRString,
      }) => (
        <Preview
          darkpool={order.darkpool}
          hideHeaders={hideHeaders}
          hiddenFields={hiddenFields}
          sizeString={sizeString}
          feeString={feeString}
          priceString={priceString}
          createdAtDateString={createdAtDateString}
          structureString={structureString}
          direction={direction}
          shareClassesString={shareClassesString}
          clientRelationshipString={clientRelationshipString}
          clientROFRString={clientROFRString}
          areTransactionDocumentOnHandString={areTransactionDocumentOnHandString}
        />
      ),
      () => null
    )
