import { head } from "../../../utils/data/Array/ArrayUtils"
import { AccountOrderQuotaFields } from "../../AccountOrderQuotaFields"
import { Order, orderLatestQuantityTerms } from "../Order"
import { OrderStatusFields, isLiveOrder } from "../Types/Status"
import { OrderQuantityTerms, orderQuantityTermsVolume } from "../Types/Terms"
import { OrderQuotaComplianceFields } from "./runQuotaComplianceChecks"

export const isOrderQuotaCompliant = (o: OrderStatusFields & OrderQuotaComplianceFields) => {
  const defaultComplianceIfChecksNotRan = true
  const orderPassesCompliance = o.orderQuotaComplianceViolations
    ? !o.orderQuotaComplianceViolations.length
    : defaultComplianceIfChecksNotRan
  return isLiveOrder(o) && (!!o.adminIgnoreQuotaComplianceViolations || orderPassesCompliance)
}

export const orderVolumeForQuotaCompliance = (quantity: OrderQuantityTerms) =>
  orderQuantityTermsVolume(quantity)?.upperBound

const reduceOrderVolume = (orders: Order[]) =>
  orders
    .flatMap((o) =>
      head(orderLatestQuantityTerms(o)).match(
        (latestQuantityTerms) => orderVolumeForQuotaCompliance(latestQuantityTerms) ?? [],
        () => []
      )
    )
    .reduce((value, currValue) => value + currValue, 0)

export const checkAccountQuotaCompliance = (
  { isQuotaSystemEnabled, accountOrderQuotaRequirements }: AccountOrderQuotaFields,
  orders: Order[],
  darkpoolOrders: Order[]
) => {
  const quotaCompliantOrders = orders.flatMap((o) => (isOrderQuotaCompliant(o) ? o : []))
  const quotaCompliantDarkpoolOrders = darkpoolOrders.flatMap((o) =>
    isOrderQuotaCompliant(o) ? o : []
  )
  const quotaCompliantOrderCount = quotaCompliantOrders.length + quotaCompliantDarkpoolOrders.length
  const quotaCompliantOrderVolume =
    reduceOrderVolume(quotaCompliantOrders) + reduceOrderVolume(quotaCompliantDarkpoolOrders)
  const isAccountCompliantWithOrderQuotaThresholds =
    !!isQuotaSystemEnabled &&
    (accountOrderQuotaRequirements?.minimumNumberOfCompliantOrders ?? 0) <=
      quotaCompliantOrderCount &&
    (accountOrderQuotaRequirements?.minimumTotalCompliantOrderVolume ?? 0) <=
      quotaCompliantOrderVolume

  return {
    quotaCompliantOrderCount,
    quotaCompliantOrderVolume,
    quotaCompliantOrderIds: quotaCompliantOrders.map((o) => o.id),
    quotaCompliantDarkpoolOrderIds: quotaCompliantDarkpoolOrders.map((o) => o.id),
    isAccountCompliantWithOrderQuotaThresholds,
  }
}
