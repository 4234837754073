import { ChevronDownIcon } from "@stories/icons/ChevronDownIcon"
import React, { useEffect, useRef, useState } from "react"
import { Button } from "../Button/Button"
import Typography, { Color, Size } from "../Typography/Typography"

export interface EmailCardMessageProps {
  body: string
  sentAt: Date
  emailParser: (email: string) => string
  from: string
}

const LINES_UNTIL_COLLAPSE = 4

export const EmailCardMessage: React.FC<React.PropsWithChildren<EmailCardMessageProps>> = ({
  body,
  sentAt,
  emailParser,
  from,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isOverflowing, setIsOverflowing] = useState(false)
  const lineHeight = 24
  const maxHeight = lineHeight * LINES_UNTIL_COLLAPSE
  const bodyRef = useRef<HTMLDivElement | null>(null)

  const mainBodyContent = emailParser(body)

  useEffect(() => {
    const bodyHeight = bodyRef.current?.scrollHeight
    if (bodyHeight && bodyHeight > maxHeight) {
      setIsOverflowing(true)
    }
  }, [maxHeight])

  return (
    <div className="p-3 border-t-1.5 w-full">
      <div className="flex gap-4">
        <Typography color={Color.Neutral800} size={Size.XSmall} text={sentAt.toDateString()} />
        <Typography color={Color.Neutral800} size={Size.XSmall} text={`from: ${from}`} />
      </div>
      <div
        className="my-2"
        ref={bodyRef}
        style={{
          display: "-webkit-box",
          WebkitLineClamp: isExpanded ? "unset" : LINES_UNTIL_COLLAPSE,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
          transition: "max-height 0.3s ease-out",
        }}
      >
        <div
          style={{ fontSize: "14px" }}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: mainBodyContent,
          }}
        />
      </div>
      {isOverflowing && !isExpanded && (
        <div className="py-4">
          <Button
            label="View entire message"
            variant="hollow-link"
            size="small"
            leftIcon={<ChevronDownIcon color={Color.Neutral800} />}
            onClick={() => setIsExpanded(!isExpanded)}
            style={{ padding: 0 }}
          />
        </div>
      )}
    </div>
  )
}
