import { Button } from "@stories/components/Button/Button"

export const LinkButton = ({
  id,
  text,
  href,
  onClick,
}: {
  id?: string
  text: string
  href?: string
  onClick?: () => void
}) =>
  href ? (
    <a target="_blank" rel="noreferrer" href={href}>
      <Button variant="hollow" onClick={onClick} id={id} label={text} />
    </a>
  ) : (
    <Button variant="hollow" onClick={onClick} id={id} label={text} />
  )
