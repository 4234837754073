import React from "react"

interface ProductAnnouncementPillProps {
  label: string
}

const ProductAnnouncementPill: React.FC<React.PropsWithChildren<ProductAnnouncementPillProps>> = ({
  label,
}) => (
  <div className="flex items-center justify-center px-1 text-xs text-white bg-accent-500 rounded-sm font-semibold max-w-fit">
    <p>{label}</p>
  </div>
)

export default ProductAnnouncementPill
