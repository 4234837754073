import { collections } from "common/firestore/Collections"
import { Account } from "common/model/Account"
import { DealCRMDeal, DealCRMPriority } from "common/model/DealCRM/Deal/DealCRMDeal"
import {
  DealCRMDealParticipant,
  DealWithParticipant,
} from "common/model/DealCRM/Deal/DealCRMDealParticipant"
import { DealCRMFund } from "common/model/DealCRM/Deal/DealCRMFund"
import { DealCRMBrokerContact } from "common/model/DealCRM/DealCRMBrokerContact"
import {
  DealCRMContact,
  DealCRMContactIdFields,
  DealCRMContactUpdate,
  viewDealCRMContactIdFields,
} from "common/model/DealCRM/DealCRMContact"
import {
  DealCRMFirmContact,
  DealCRMFirmContactIdFields,
} from "common/model/DealCRM/DealCRMFirmContact"
import { DealCRMImportJob } from "common/model/DealCRM/DealCRMImportJob"
import { DealCRMIndividualContact } from "common/model/DealCRM/DealCRMIndividualContact"
import {
  DealCRMInterest,
  buildDealCRMInterest,
  orderToCRMInterestTerms,
} from "common/model/DealCRM/DealCRMInterest"
import { ParsedEmailMessage } from "common/model/Google/ParsedEmailMessage"
import { ContactHolding } from "common/model/holdings/Holding"
import { MatchSuggestion } from "common/model/Order/Matching"
import { Order, viewOrderIdFields } from "common/model/Order/Order"
import { User, UserIdFields, viewUserIdFields } from "common/model/User"
import { undefinedsToNulls } from "common/utils/ObjectUtils"
import compatFirebaseApp from "firebase/compat/app"
import { DocumentSnapshot, doc, documentId, updateDoc } from "firebase/firestore"
import { handleConsoleError } from "src/utils/Tracking"
import { firestoreConverter } from "../model/FirestoreConverter"
import { FieldPath, FieldValue, FirebaseReader, FirebaseWriter } from "./Firebase"
import { getAllDocs, inQuery } from "./Firebase/utils"
import Firebase9 from "./Firebase9"

// TODO - implement pagination
export const getAccountCrmContacts = ({
  db,
  accountId,
}: {
  db: FirebaseReader
  accountId: string
}) =>
  db.db
    .collection(collections.dealCRM.contacts)
    .withConverter<DealCRMContact>(firestoreConverter<DealCRMContact>())
    .where("accountId", "==", accountId)
    .orderBy("createdAt", "desc")

export const getFirmById = ({ db, contactId }: { db: FirebaseReader; contactId: string }) =>
  db.db
    .collection(collections.dealCRM.contacts)
    .doc(contactId)
    .withConverter<DealCRMFirmContact>(firestoreConverter<DealCRMFirmContact>())
    .get()

export const getAccountCrmDeals = ({ db, accountId }: { db: FirebaseReader; accountId: string }) =>
  db.db
    .collection(collections.dealCRM.deals)
    .withConverter<DealCRMDeal>(firestoreConverter<DealCRMDeal>())
    .where("account.id", "==", accountId)
    .orderBy("createdAt", "desc")

export const getAccountContactsHoldings = ({
  db,
  accountId,
}: {
  db: FirebaseReader
  accountId: string
}) =>
  db.db
    .collection(collections.holdings)
    .withConverter<ContactHolding>(firestoreConverter<ContactHolding>())
    .where("account.id", "==", accountId)
    .where("shareholder.contact.id", "!=", null)

export const getMostRecentUpdatedAccountBuySellInterest = ({
  db,
  accountId,
}: {
  db: FirebaseReader
  accountId: string
}) =>
  db.db
    .collection(collections.dealCRM.buySellInterest)
    .withConverter<DealCRMInterest>(firestoreConverter<DealCRMInterest>())
    .where("account.id", "==", accountId)
    .where("tag", "==", "crm-interest")
    .orderBy("lastUpdatedAt", "desc")
    .limit(1)

export const getAccountBuySellInterest = ({
  db,
  accountId,
}: {
  db: FirebaseReader
  accountId: string
}) =>
  db.db
    .collection(collections.dealCRM.buySellInterest)
    .withConverter<DealCRMInterest>(firestoreConverter<DealCRMInterest>())
    .where("account.id", "==", accountId)
    .where("tag", "==", "crm-interest")

export const getAccountBuySellInterestsByIds = async ({
  db,
  accountId,
  ids,
}: {
  db: FirebaseReader
  accountId: string
  ids: string[]
}) =>
  inQuery(
    (chunk) =>
      db.db
        .collection(collections.dealCRM.buySellInterest)
        .withConverter<DealCRMInterest>(firestoreConverter<DealCRMInterest>())
        .where(FieldPath.documentId(), "in", chunk)
        .where("account.id", "==", accountId)
        .where("tag", "==", "crm-interest")
        .get()
        .then(getAllDocs),
    ids
  )

export const getAccountCrmFunds = ({ db, accountId }: { db: FirebaseReader; accountId: string }) =>
  db.db
    .collection(collections.dealCRM.funds)
    .withConverter<DealCRMFund>(firestoreConverter<DealCRMFund>())
    .where("account.id", "==", accountId)
    .orderBy("createdAt", "desc")

export const getDeal = ({ db, dealId }: { db: FirebaseReader; dealId: string }) =>
  db.db
    .collection(collections.dealCRM.deals)
    .withConverter<DealCRMDeal>(firestoreConverter<DealCRMDeal>())
    .doc(dealId)

export const updateDeal = ({
  db,
  dealId,
  update,
}: {
  db: FirebaseWriter
  dealId: string
  update: Partial<DealCRMDeal>
}) => db.db.collection(collections.dealCRM.deals).doc(dealId).update(undefinedsToNulls(update))

export const getAllDealsDealParticipants = ({
  db,
  accountId,
}: {
  db: FirebaseReader
  accountId: string
}) =>
  db.db
    .collection(collections.dealCRM.dealParticipants)
    .withConverter<DealCRMDealParticipant>(firestoreConverter<DealCRMDealParticipant>())
    .where("account.id", "==", accountId)

export const getDealParticipants = ({
  db,
  dealId,
  accountId,
}: {
  db: FirebaseReader
  dealId: string
  accountId: string
}) =>
  db.db
    .collection(collections.dealCRM.dealParticipants)
    .withConverter<DealCRMDealParticipant>(firestoreConverter<DealCRMDealParticipant>())
    .where("deal.id", "==", dealId)
    .where("account.id", "==", accountId)

export const getDealParticipantsForMultipleDeals = ({
  db,
  dealIds,
  accountId,
}: {
  db: FirebaseReader
  dealIds: string[]
  accountId: string
}) =>
  inQuery(
    (ids) =>
      db.db
        .collection(collections.dealCRM.dealParticipants)
        .withConverter<DealCRMDealParticipant>(firestoreConverter<DealCRMDealParticipant>())
        .where("deal.id", "in", ids)
        .where("account.id", "==", accountId)
        .get()
        .then(getAllDocs),
    dealIds
  )

export const addDealParticipant = ({
  db,
  dealParticipant,
}: {
  db: FirebaseReader
  dealParticipant: Omit<DealCRMDealParticipant, "id">
}) => db.db.collection(collections.dealCRM.dealParticipants).add(dealParticipant) // TODO - we can consider using a subcollection on the deal

export const removeDealParticipant = ({
  db,
  participant,
}: {
  db: FirebaseReader
  participant: DealCRMDealParticipant
}) => db.db.collection(collections.dealCRM.dealParticipants).doc(participant.id).delete()

export const updateDealParticipant = ({
  db,
  dealParticipantId,
  update,
}: {
  db: FirebaseReader
  dealParticipantId: string
  update: Partial<DealCRMDealParticipant>
}) => db.db.collection(collections.dealCRM.dealParticipants).doc(dealParticipantId).update(update)

// contacts

export const createContactInterest = ({
  db,
  interest,
  user,
}: {
  db: FirebaseWriter
  interest: DealCRMInterest
  user: UserIdFields
}) =>
  db.db
    .collection(collections.dealCRM.buySellInterest)
    .doc(interest.id)
    .set(interest)
    .then(() =>
      logContactInterestChanged({
        db,
        interest,
        user,
      })
    )

export const updateContactInterest = ({
  db,
  interestUpdate,
  user,
}: {
  db: FirebaseWriter
  interestUpdate: Partial<DealCRMInterest> & Pick<DealCRMInterest, "id" | "contact" | "updatedAt">
  user: UserIdFields
}) => {
  const date = new Date()
  const updateData: Partial<DealCRMInterest> = {
    ...interestUpdate,
    lastUpdatedAt: date,
    updatedAt: [...interestUpdate.updatedAt, date],
  }

  return db.db
    .collection(collections.dealCRM.buySellInterest)
    .doc(interestUpdate.id)
    .update(updateData)
    .then(() =>
      logContactInterestChanged({
        db,
        interest: interestUpdate,
        user,
      })
    )
}

export type UpdateContactDeprecatedProps<C extends DealCRMContact> = {
  contact: C
  field: keyof C & string
  fieldValue: C[keyof C & string]
}

export const updateContactDeprecated = <C extends DealCRMContact>({
  db,
  contact,
  field,
  fieldValue,
  user,
}: UpdateContactDeprecatedProps<C> & { db: FirebaseReader; user: UserIdFields }) => {
  const data: Partial<C> = {}
  data[field] = fieldValue

  return db.db
    .collection(collections.dealCRM.contacts)
    .doc(contact.id)
    .update({
      ...data,
      updates: FieldValue.arrayUnion({
        updatedBy: viewUserIdFields(user),
        updatedAt: new Date(),
        data,
      } satisfies DealCRMContactUpdate<C>),
    })
}

export const enableContactsEmailSync = async ({
  db,
  contactIds,
  user,
}: {
  db: FirebaseWriter
  contactIds: string[]
  user: UserIdFields
}) => {
  const batch = db.db.batch()

  await Promise.all(
    contactIds.map(async (contactId) => {
      const contactRef = db.db
        .collection(collections.dealCRM.contacts)
        .doc(contactId)
        .withConverter<DealCRMContact>(firestoreConverter<DealCRMContact>())
      const contactData = await contactRef.get().then((d) => d.data())
      if (contactData) {
        batch.update(contactRef, {
          userData: {
            ...(contactData?.userData ?? {}),
            [user.id]: {
              ...(contactData?.userData?.[user.id] ?? {}),
              syncEnabled: true,
            },
          },
          updates: FieldValue.arrayUnion({
            updatedBy: viewUserIdFields(user),
            updatedAt: new Date(),
            data: {
              userData: {
                ...(contactData?.userData ?? {}),
                [user.id]: {
                  ...(contactData?.userData?.[user.id] ?? {}),
                  syncEnabled: true,
                },
              },
            },
          } satisfies DealCRMContactUpdate<DealCRMContact>),
        })
      }
    })
  )

  try {
    await batch.commit()
  } catch (error) {
    handleConsoleError(error)
  }
}

export type UpdateContactProps<C extends DealCRMContact> = {
  contact: C
  update: Partial<C>
}

export const updateContact = <C extends DealCRMContact>({
  db,
  contact,
  update,
  user,
}: UpdateContactProps<C> & { db: FirebaseReader; user: UserIdFields }) =>
  db.db
    .collection(collections.dealCRM.contacts)
    .doc(contact.id)
    .update({
      ...update,
      updates: FieldValue.arrayUnion({
        updatedBy: viewUserIdFields(user),
        updatedAt: new Date(),
        data: update,
      } satisfies DealCRMContactUpdate<C>),
    })

export const updateContactPriority = ({
  priority,
  contact,
  db,
  user,
}: {
  priority: DealCRMPriority
  contact: DealCRMContact
  db: FirebaseReader
  user: UserIdFields
}) =>
  updateContactDeprecated({
    db,
    user,
    contact,
    field: "priority",
    fieldValue: priority,
  }).catch(handleConsoleError)

const logContactInterestChanged = async <C extends DealCRMContact>({
  db,
  interest,
  user,
}: {
  db: FirebaseReader
  user: UserIdFields
  interest: Pick<DealCRMInterest, "contact">
}) => {
  if (interest.contact) {
    await db.db
      .collection(collections.dealCRM.contacts)
      .doc(interest.contact.id)
      .update({
        updates: FieldValue.arrayUnion({
          updatedBy: viewUserIdFields(user),
          updatedAt: new Date(),
          data: {
            tag: "contactInterestUpdated",
          },
        } satisfies DealCRMContactUpdate<C>),
      })
  }
}

export const updateFirmContactWithIndividualContact = async ({
  db,
  contact,
  firm,
}: {
  db: FirebaseReader
  contact: DealCRMIndividualContact | DealCRMBrokerContact
  firm: DealCRMContactIdFields
}) => {
  if (firm.tag !== "firm") {
    throw Error(`Incorrect update of a firm contact with sub contacts: ${firm.id}`)
  }
  // update new firm
  await db.db
    .collection(collections.dealCRM.contacts)
    .doc(firm.id)
    .update({
      contacts: FieldValue.arrayUnion({
        ...viewDealCRMContactIdFields(contact),
        isPrimaryContact: false,
      } satisfies DealCRMFirmContact["contacts"][number]),
      contactIds: FieldValue.arrayUnion(
        contact.id satisfies DealCRMFirmContact["contactIds"][number]
      ),
    })
}

export const updateIndividualContactFirm = async ({
  db,
  contact,
  firm,
}: {
  db: FirebaseReader
  contact: DealCRMIndividualContact | DealCRMBrokerContact
  firm: DealCRMFirmContactIdFields
}) => {
  // update individual
  await db.db
    .collection(collections.dealCRM.contacts)
    .doc(contact.id)
    .update({
      firm,
      previousFirmIds: contact.firm ? [contact.firm.id, ...contact.previousFirmIds] : [],
      previousFirms: contact.firm ? [contact.firm, ...contact.previousFirms] : [],
    } satisfies Pick<DealCRMIndividualContact, "firm" | "previousFirmIds" | "previousFirms">)
  await updateFirmContactWithIndividualContact({ db, contact, firm })

  // update old firm
  if (contact.firm) {
    const oldContactRef = db.db
      .collection(collections.dealCRM.contacts)
      .withConverter<DealCRMContact>(firestoreConverter<DealCRMContact>())
      .doc(contact.firm.id)
    const oldContact = (await oldContactRef.get()).data()

    if (oldContact && oldContact.tag === "firm") {
      await oldContactRef.update({
        contacts: oldContact.contacts.filter((c) => contact.id !== c.id),
        contactIds: oldContact.contactIds.filter((id) => contact.id !== id),
      } satisfies Pick<DealCRMFirmContact, "contactIds" | "contacts">)
    }
  }
}

export const createDealCRMContact = async ({
  db,
  contact,
  user,
}: {
  db: FirebaseReader
  user: User
  contact: DealCRMContact
}) => {
  const createdRef = await db.db.collection(collections.dealCRM.contacts).add({
    ...contact,
    id: null,
    accountId: user.account.id,
    createdAt: new Date(),
    createdBy: viewUserIdFields(user),
  } satisfies Omit<DealCRMContact, "id"> & { id: null })
  if (contact.tag === "individual") {
    const createdContact = await createdRef
      .withConverter<DealCRMContact>(firestoreConverter<DealCRMContact>())
      .get()
      .then((d) => d.data())
    if (
      createdContact?.tag === "individual" &&
      createdContact.firm &&
      createdContact.firm.tag === "firm"
    ) {
      const firm = (await getFirmById({ db, contactId: createdContact.firm.id })).data()

      await updateFirmContactWithIndividualContact({
        db,
        contact: createdContact,
        firm: createdContact.firm,
      })

      if (firm && firm.contacts.length === 0) {
        const newContacts = [
          ...firm.contacts.filter((c) => c.id !== createdContact.id),
          {
            ...viewDealCRMContactIdFields(createdContact),
            isPrimaryContact: true,
          } satisfies DealCRMFirmContact["contacts"][number],
        ]

        await updateContactDeprecated({
          db,
          user,
          contact: firm,
          field: "contacts",
          fieldValue: newContacts,
        })
      }
    }
  }
  return createdRef
}

export const createDeal = async ({
  db,
  newDeal,
}: {
  db: FirebaseReader
  newDeal: Omit<DealCRMDeal, "id">
}) => {
  const docRef = await db.db.collection(collections.dealCRM.deals).add({
    ...newDeal,
  })

  return docRef.id
}

export const deleteDeal = ({ db, dealId }: { db: FirebaseWriter; dealId: string }) =>
  db.db.collection(collections.dealCRM.deals).doc(dealId).delete()

export const getContactDeals = async ({
  db,
  contactId,
  accountId,
}: {
  db: FirebaseReader
  contactId: string
  accountId: string
}): Promise<DealWithParticipant[]> => {
  const participantDocs = await db.db
    .collection(collections.dealCRM.dealParticipants)
    .where("contact.id", "==", contactId)
    .where("account.id", "==", accountId)
    .withConverter<DealCRMDealParticipant>(firestoreConverter<DealCRMDealParticipant>())
    .get()
    .then(getAllDocs)

  if (participantDocs.length === 0) return []

  const deals = await db.db
    .collection(collections.dealCRM.deals)
    .withConverter<DealCRMDeal>(firestoreConverter<DealCRMDeal>())
    .where(
      documentId(),
      "in",
      participantDocs.map((d) => d.deal.id)
    )
    .where("account.id", "==", accountId)
    .get()
    .then(getAllDocs)

  return deals
    .map((deal) => ({
      deal,
      participant: participantDocs.find((p) => p.deal.id === deal.id),
    }))
    .filter(
      (obj): obj is { deal: DealCRMDeal; participant: DealCRMDealParticipant } =>
        !!obj.deal && !!obj.participant
    )
}

export const createCRMFund = ({
  db,
  newFund,
}: {
  db: FirebaseWriter
  newFund: Omit<DealCRMFund, "id">
}) => db.db.collection(collections.dealCRM.funds).add(newFund)

export const getContactDealParticipantDocs = ({
  db,
  contact,
  accountId,
}: {
  db: FirebaseReader
  accountId: string
  contact: DealCRMContact
}) =>
  db.db
    .collection(collections.dealCRM.dealParticipants)
    .where("contact.id", "==", contact.id)
    .where("account.id", "==", accountId)
    .withConverter<DealCRMDealParticipant>(firestoreConverter<DealCRMDealParticipant>())

// job
export const getLatestImportJob = ({ db, accountId }: { db: FirebaseReader; accountId: string }) =>
  db.db
    .collection(collections.dealCRM.importJobs)
    .withConverter<DealCRMImportJob>(firestoreConverter<DealCRMImportJob>())
    .where("accountId", "==", accountId)
    .orderBy("createdAt", "desc")

export const getFirmsForIndividualContact = ({
  db,
  contactId,
  accountId,
}: {
  db: FirebaseReader
  contactId: string
  accountId: string
}) =>
  db.db
    .collection(collections.dealCRM.contacts)
    .withConverter<DealCRMFirmContact>(firestoreConverter<DealCRMFirmContact>())
    .where("accountId", "==", accountId)
    .where("contactIds", "array-contains", contactId)
    .get()

export const getIndividualsForFirmContact = async ({
  db,
  contactId,
  accountId,
}: {
  db: FirebaseReader
  contactId: string
  accountId: string
}) =>
  db.db
    .collection(collections.dealCRM.contacts)
    .withConverter<DealCRMIndividualContact>(firestoreConverter<DealCRMIndividualContact>())
    .where("accountId", "==", accountId)
    .where("firm.id", "==", contactId)
    .get()

export const updateCRMDeal = ({
  db,
  dealId,
  update,
  user,
}: {
  db: FirebaseWriter
  dealId: string
  update: Partial<DealCRMDeal>
  user: UserIdFields
}) => {
  const updateMetadata: Pick<DealCRMDeal, "lastUpdatedAt" | "lastUpdateBy"> = {
    // TODO - update 'updates' array
    lastUpdateBy: viewUserIdFields(user),
    lastUpdatedAt: new Date(),
  }
  const updateData: Partial<DealCRMDeal> = {
    ...undefinedsToNulls(update),
    ...updateMetadata,
  }

  return db.db.collection(collections.dealCRM.deals).doc(dealId).update(updateData)
}

export type CreateOrUpdateCRMInterestForOrderOptions = { updateExistingCRMInterestTerms?: boolean }

export const createOrUpdateCRMInterestForOrder = async (
  order: Order,
  db: FirebaseWriter,
  writeBatch: compatFirebaseApp.firestore.WriteBatch,
  { updateExistingCRMInterestTerms = false }: CreateOrUpdateCRMInterestForOrderOptions
): Promise<{ crmInterestId: string | null }> => {
  if (!order.crmContactId || order.source.sourceType !== "user-form") {
    return { crmInterestId: null }
  }

  if (order.crmInterestId) {
    const interestRef = db.db
      .collection(collections.dealCRM.buySellInterest)
      .withConverter<DealCRMInterest>(firestoreConverter())
      .doc(order.crmInterestId)

    const interestData = await interestRef.get().then((interestDoc) => interestDoc.data())

    if (!interestData) {
      throw new Error(`Found undefined .data() on buy sell interest with id ${order.crmInterestId}`)
    }

    const date = new Date()
    const dateUpdates: Pick<DealCRMInterest, "publishedOrder" | "lastUpdatedAt" | "updatedAt"> = {
      publishedOrder: viewOrderIdFields(order),
      updatedAt: [...interestData.updatedAt, date],
      lastUpdatedAt: date,
    }

    if (updateExistingCRMInterestTerms) {
      writeBatch.update(interestRef, {
        ...dateUpdates,
        ...orderToCRMInterestTerms(order),
      } satisfies Partial<DealCRMInterest>)
    } else if (!interestData.publishedOrder) {
      writeBatch.update(interestRef, dateUpdates)
    }

    return { crmInterestId: order.crmInterestId }
  }

  const contact = await db.db
    .collection(collections.dealCRM.contacts)
    .withConverter<DealCRMContact>(firestoreConverter())
    .doc(order.crmContactId)
    .get()
    .then((d) => d.data())

  const account = await db.db
    .collection(collections.accounts)
    .withConverter<Account>(firestoreConverter())
    .doc(order.source.account.id)
    .get()
    .then((d) => d.data())

  if (!contact) {
    throw new Error(
      `Could not find contact with id ${order.crmContactId} when creating CRM interest for order`
    )
  }
  if (!account) {
    throw new Error(
      `Could not find account with id ${order.source.account.id} when creating CRM interest for order`
    )
  }

  const newInterest = buildDealCRMInterest({
    publishedOrder: order,
    direction: order.direction,
    company: order.company,
    source: order.source,
    account,
    contact,
    terms: orderToCRMInterestTerms(order),
  })

  const interestRef = db.db
    .collection(collections.dealCRM.buySellInterest)
    .withConverter<DealCRMInterest>(firestoreConverter())
    .doc(newInterest.id)

  writeBatch.set(interestRef, newInterest)

  return { crmInterestId: newInterest.id }
}

export type UpdateAccountCRMPreferencesArgs = {
  db: Firebase9["db"]
  account: Required<Pick<Account, "id" | "crmPreferences">>
  updatedPreferences: Partial<Account["crmPreferences"]>
}
export const updateAccountCRMPreferences = ({
  updatedPreferences,
  db,
  account,
}: UpdateAccountCRMPreferencesArgs) =>
  updateDoc(doc(db, collections.accounts, account.id), {
    crmPreferences: { ...account.crmPreferences, ...updatedPreferences },
  })
export const getMatchSuggestionsFromAccount = ({
  db,
  accountId,
  side,
}: {
  db: FirebaseReader
  accountId: string
  side: keyof Pick<MatchSuggestion, "matchable1" | "matchable2">
}) =>
  db.db
    .collection(collections.orderMatchSuggestions)
    .withConverter<MatchSuggestion>(firestoreConverter<MatchSuggestion>())
    .where(`${side}.accountId`, "==", accountId)

export const getDealCRMInterestById = ({ db, id }: { db: FirebaseReader; id: string }) =>
  db.db
    .collection(collections.dealCRM.buySellInterest)
    .withConverter<DealCRMInterest>(firestoreConverter<DealCRMInterest>())
    .doc(id)
    .get()
    .then((d) => d.data())

export const getAllEmailsByContactId = ({
  db,
  user,
  contactId,
}: {
  db: FirebaseReader
  user: UserIdFields & { account: { id: string } }
  contactId: string
}) =>
  db.db
    .collection(collections.dealCRM.emails)
    .withConverter<ParsedEmailMessage>(firestoreConverter<ParsedEmailMessage>())
    .where("accountId" satisfies keyof ParsedEmailMessage, "==", user.account.id)
    .where("userId" satisfies keyof ParsedEmailMessage, "==", user.id)
    .where("contactIds", "array-contains", contactId)
    .orderBy("data.sentAt", "desc")

export const getAllEmailsByContactIdPaginated = ({
  db,
  user,
  contactId,
  pageSize,
  lastVisible,
}: {
  db: FirebaseReader
  user: UserIdFields & { account: { id: string } }
  contactId: string
  pageSize: number
  lastVisible?: DocumentSnapshot<ParsedEmailMessage> | null
}) => {
  if (!lastVisible) {
    return db.db
      .collection(collections.dealCRM.emails)
      .withConverter<ParsedEmailMessage>(firestoreConverter<ParsedEmailMessage>())
      .where("accountId" satisfies keyof ParsedEmailMessage, "==", user.account.id)
      .where("userId" satisfies keyof ParsedEmailMessage, "==", user.id)
      .where("contactIds", "array-contains", contactId)
      .orderBy("data.sentAt", "desc")
      .limit(pageSize)
  } else {
    return db.db
      .collection(collections.dealCRM.emails)
      .withConverter<ParsedEmailMessage>(firestoreConverter<ParsedEmailMessage>())
      .where("accountId" satisfies keyof ParsedEmailMessage, "==", user.account.id)
      .where("userId" satisfies keyof ParsedEmailMessage, "==", user.id)
      .where("contactIds", "array-contains", contactId)
      .orderBy("data.sentAt", "desc")
      .startAfter(lastVisible)
      .limit(pageSize)
  }
}

export const getEmailsByThreadId = ({
  db,
  user,
  threadId,
}: {
  db: FirebaseReader
  user: UserIdFields & { account: { id: string } }
  threadId: string
}) =>
  db.db
    .collection(collections.dealCRM.emails)
    .withConverter<ParsedEmailMessage>(firestoreConverter<ParsedEmailMessage>())
    .where("accountId" satisfies keyof ParsedEmailMessage, "==", user.account.id)
    .where("userId" satisfies keyof ParsedEmailMessage, "==", user.id)
    .where("data.threadId", "==", threadId)
    .orderBy("data.sentAt", "desc")
