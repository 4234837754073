import { Button } from "@stories/components/Button/Button"
import Typography, { Color, Size } from "@stories/components/Typography/Typography"
import { AirtableContact } from "common/model/airtable/AirtableContact"
import { defaultIfLoading, deprecatedIsLoaded, isLoading, matchLoading } from "common/utils/Loading"
import { emailPatternString } from "common/utils/StringUtils"
import { useCallback, useMemo, useState } from "react"
import { useLoggedInUser } from "../../providers/loggedInUser/useLoggedInUser"
import { useAdminAirtableContacts } from "../../providers/AdminProviders/AdminAirtableContacts"
import { Input } from "@stories/components/Inputs/Input/Input"
import AdminVisible from "../../admin/AdminVisible"
import { SharedOrderCreateParams } from "common/model/SharedOrder/SharedOrderCreate"
import SearchSelect from "@stories/components/Inputs/SearchSelect/SearchSelect"
import InlineContactSelectSearch from "src/pages/CRM/Components/InlineContactSelectSearch/InlineContactSelectSearch"
import { assertUnreachable } from "common/utils/fp/Function"
import { ExclamationPointCircleIcon } from "@stories/icons/ExclamationPointCircleIcon"
import { CRMContactName } from "src/pages/CRM/Contacts/ContactDetailsDrawer/CRMContactName"
import { DealCRMContact, viewDealCRMContactIdFields } from "common/model/DealCRM/DealCRMContact"
import { TooltipWrapper } from "@stories/components/TooltipWrapper/TooltipWrapper"
import { Checkbox } from "@components/inputs/checkbox/Checkbox"

type ContactSelectionProps = {
  emails: SharedOrderCreateParams["sharedWithUserEmails"]
  handleAddEmails: (v: SharedOrderCreateParams["sharedWithUserEmails"]) => void
}

const getLabelFromAirtableContact = (u: AirtableContact) =>
  `${u.Name} (${[
    u.Email,
    u["Firm-text"] ?? "No Firm",
    `${u["Add to platform"] ? "on" : "off"}-platform`,
  ].join(", ")})`

const AdminAirtableContactSelection = ({ handleAddEmails }: ContactSelectionProps) => {
  const [searchResult, setSearchResult] = useState<{
    filteredContacts: typeof airtableContactsWithLabels
    searchString: string
  } | null>(null)

  const airtableContacts = useAdminAirtableContacts()

  const airtableContactsWithLabels = useMemo(
    () =>
      defaultIfLoading(airtableContacts, []).flatMap((u) =>
        u.Email && emailPatternString.test(u.Email.trim())
          ? [{ label: getLabelFromAirtableContact(u), value: u.Email.trim() }]
          : []
      ),
    [airtableContacts]
  )

  const searchForContact = useCallback(
    (s: string) => {
      if (s.length < 3) return Promise.resolve([])

      const contactsToSearch =
        searchResult && s.startsWith(searchResult.searchString)
          ? // If user is continuing to type the same string, continue to filter down previous search results
            searchResult.filteredContacts
          : // Otherwise, search the entire array
            airtableContactsWithLabels

      const nextFilteredContacts = contactsToSearch.filter((u) =>
        u.label.toUpperCase().includes(s.toUpperCase())
      )

      setSearchResult({ filteredContacts: nextFilteredContacts, searchString: s })

      return Promise.resolve(nextFilteredContacts)
    },
    [airtableContactsWithLabels, searchResult]
  )

  const handleOnChange = (emailString: string) => {
    const contact = matchLoading(
      airtableContacts,
      (loadedAirtableContacts) => loadedAirtableContacts.find((v) => v.Email === emailString),
      null,
      null
    )
    handleAddEmails([
      {
        email: emailString,
        firstName: contact?.["First Name"],
        lastName: contact?.["Last Name"],
      },
    ])
  }

  if (isLoading(airtableContacts)) return <Typography text="Loading contacts..." />
  if (!deprecatedIsLoaded(airtableContacts)) return <Typography text="Loading contacts failed" />
  return (
    <AdminVisible>
      <SearchSelect<{ label: string; value: string }>
        id="share-email-search"
        label="Search Contacts (type 3 characters to start searching)"
        placeholder="Start searching"
        loadOptions={searchForContact}
        onChange={(v) => (v ? handleOnChange(v.value) : null)}
        renderOption={(v) => v.label}
      />
    </AdminVisible>
  )
}

const AdminContactSelection = ({ emails, handleAddEmails }: ContactSelectionProps) => {
  const [manualContactEntry, setManualContactEntry] = useState("")

  const isManualContactEntryValid = emailPatternString.test(manualContactEntry)
  const submitManualContactEntry = (email: string) => {
    handleAddEmails([{ email }])
    setManualContactEntry("")
  }

  return (
    <AdminVisible>
      <div className="flex flex-col gap-4">
        <AdminAirtableContactSelection emails={emails} handleAddEmails={handleAddEmails} />
        <Input
          label="Enter email address manually"
          value={manualContactEntry}
          onChange={(e) => setManualContactEntry(e.target.value)}
          onKeyDown={(e) =>
            e.code === "Enter" &&
            isManualContactEntryValid &&
            submitManualContactEntry(manualContactEntry)
          }
          suffix={
            <div className="p-1">
              <Button
                label="+ Add Email"
                variant="secondary"
                isDisabled={!isManualContactEntryValid}
                onClick={() => submitManualContactEntry(manualContactEntry)}
              />
            </div>
          }
        />
      </div>
    </AdminVisible>
  )
}

export const createEmailFromCRMContact = (
  c: DealCRMContact
): SharedOrderCreateParams["sharedWithUserEmails"][number] =>
  c.tag === "broker"
    ? {
        email: c.email ?? "",
        firstName: c.firstName,
        lastName: c.lastName,
        crmContactId: viewDealCRMContactIdFields(c),
      }
    : c.tag === "firm"
    ? {
        email: "",
        firstName: c.name,
        lastName: "",
        crmContactId: viewDealCRMContactIdFields(c),
      }
    : c.tag === "individual"
    ? {
        email: c.email ?? "",
        firstName: c.firstName,
        lastName: c.lastName,
        crmContactId: viewDealCRMContactIdFields(c),
      }
    : assertUnreachable(c)

const UserContactSelection = ({ handleAddEmails }: ContactSelectionProps) => (
  <>
    <InlineContactSelectSearch
      label="Search for Contacts or Colleagues"
      hiddenContactTypes={["firm"]}
      onContactSelected={(c) =>
        c ? Promise.resolve(handleAddEmails([createEmailFromCRMContact(c)])) : Promise.resolve()
      }
      sourceComponent="share-order"
      resetSearchOnContactSelect
      isSkipVisible={false}
    />
  </>
)

export const SelectedContacts = ({
  emails,
  handleRemoveEmail,
}: {
  handleRemoveEmail?: (e: SharedOrderCreateParams["sharedWithUserEmails"][number]) => void
  emails: SharedOrderCreateParams["sharedWithUserEmails"]
}) => (
  <div className="flex flex-col gap-2">
    {emails.map((e) => (
      <div key={`${e.email}${e.firstName ?? ""}`} className="flex items-center pl-3">
        {handleRemoveEmail ? (
          <Checkbox checked onChange={() => handleRemoveEmail(e)} id={e.email} />
        ) : null}
        {e.crmContactId ? (
          <CRMContactName contact={e.crmContactId} isSubtitleDisplayed isIconDisplayed={false} />
        ) : (
          <Typography text={e.email} />
        )}
        {!e.email ? (
          <div className="pl-2">
            <TooltipWrapper
              title={
                <div className="flex flex-col gap-1">
                  <Typography text="Email Not Found" color={Color.White} size={Size.XSmall} />
                  <Typography
                    text="Must update contact before you can send an email."
                    color={Color.White}
                    size={Size.XSmall}
                  />
                </div>
              }
            >
              <ExclamationPointCircleIcon color={Color.Danger} />
            </TooltipWrapper>
          </div>
        ) : null}
      </div>
    ))}
  </div>
)

type ShareOrderInviteContactsProps = {
  handleAddEmails?: (e: SharedOrderCreateParams["sharedWithUserEmails"]) => void
  emails: SharedOrderCreateParams["sharedWithUserEmails"]
}
const ShareOrderInviteContacts = ({ handleAddEmails, emails }: ShareOrderInviteContactsProps) => {
  const { isAdmin } = useLoggedInUser()
  if (!handleAddEmails) return null
  return isAdmin ? (
    <AdminContactSelection emails={emails} handleAddEmails={handleAddEmails} />
  ) : (
    <UserContactSelection emails={emails} handleAddEmails={handleAddEmails} />
  )
}

export default ShareOrderInviteContacts
