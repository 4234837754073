import { UnsafeRec } from "../../../utils/RecordUtils"
import { subrecord } from "../../../utils/fp/optics/Lens"
import { AccountIdFields, viewAccountPublicFields } from "../../Account"
import { UserIdFields } from "../../User"
import {
  NewConnectThread,
  threadAccount,
  ThreadParticipantFields,
  threadUser,
} from "../ConnectThread"
import {
  ConnectAccountRole,
  defaultThreadAccountData,
  defaultThreadUserData,
  ThreadUserData,
} from "../ThreadParticipant"
import { ThreadType } from "../ThreadTypes/ThreadType"

export const accountMessagingId = (account: AccountIdFields) => account.id

export const addAccountToThreadParticipants = <T extends ThreadType>(
  account: AccountIdFields,
  thread: NewConnectThread<T>,
  roles: ConnectAccountRole[] = []
): Pick<NewConnectThread<T>, "participatingAccounts" | "participatingAccountIds"> => ({
  participatingAccounts: threadAccount(accountMessagingId(account)).over(thread, (a) =>
    a.match(
      (existing) => existing,
      () => ({
        ...defaultThreadAccountData(account),
        roles,
      })
    )
  ).participatingAccounts,
  participatingAccountIds: [...new Set([...thread.participatingAccountIds, account.id])],
})

// we need a better solution for referential integrity
export const normalizeThreadParticipantData = <T extends ThreadParticipantFields>(thread: T): T => {
  const normalized: Pick<ThreadParticipantFields, "participatingAccountIds"> = {
    participatingAccountIds: UnsafeRec.keys(thread.participatingAccounts),
  }
  return {
    ...thread,
    ...normalized,
  }
}

export const addAccountToThread = <T extends ThreadType>(
  account: AccountIdFields,
  thread: NewConnectThread<T>,
  roles: ConnectAccountRole[] = []
): NewConnectThread<T> =>
  normalizeThreadParticipantData({
    ...thread,
    ...addAccountToThreadParticipants(account, thread, roles),
  })

export const accountIsOnThread = (account: AccountIdFields, thread: ThreadParticipantFields) =>
  threadAccount(accountMessagingId(account)).view(thread).isJust()

export const userIsOnThread = (user: UserIdFields, thread: ThreadParticipantFields) =>
  threadUser(user.id).view(thread).isJust()

export const addUserToThread = <T extends ThreadParticipantFields = ThreadParticipantFields>(
  user: UserIdFields,
  account: AccountIdFields,
  thread: T,
  data?: Partial<ThreadUserData>
): T =>
  normalizeThreadParticipantData(updateOrCreateParticipantData(user, account, thread, data ?? {}))

export const updateOrCreateParticipantData = <
  T extends ThreadParticipantFields = ThreadParticipantFields
>(
  user: UserIdFields,
  account: AccountIdFields,
  thread: T,
  data: Partial<ThreadUserData>
): T =>
  subrecord<ThreadParticipantFields, T>()
    .composeLens(threadUser(user.id))
    .over(
      subrecord<ThreadParticipantFields, T>()
        .composeLens(threadAccount(account.id))
        .over(thread, (a) =>
          a.withDefault(defaultThreadAccountData(viewAccountPublicFields(account)))
        ),
      (u) =>
        u.match(
          (existing) => ({
            ...existing,
            ...data,
          }),
          () => ({
            ...defaultThreadUserData(user, account),
            ...data,
          })
        )
    )
