import { showInterval } from "common/utils/data/Interval"
import { formatValuation } from "common/utils/math/format"
import { orderDataAccessLevel } from "../../../../pages/Orders/shared/accessLevels"
import {
  OrderCellProps,
  LiveMarketTableCell,
} from "../../../../pages/Orders/OrderPage/OrderLiveMarket/Cells"

export const VolumeCell = ({
  order,
}: {
  order: Pick<OrderCellProps["order"], "amountUSD" | "isConnectable">
}) => (
  <LiveMarketTableCell accessLevel={orderDataAccessLevel.size(order.isConnectable())}>
    <p>{order.amountUSD().map(showInterval(formatValuation, true)).withDefault("--")}</p>
  </LiveMarketTableCell>
)
