import { User } from "common/model/User"
import firebase from "firebase/compat/app"

/**
 *  CurrentUser is a data wrapper around a firebase auth user object and the firestore user record
 */
export interface CurrentUser {
  user: User
  authUser: firebase.User
  isAdmin: boolean
}

export const isLoggedIn = (user?: CurrentUser | null): user is CurrentUser =>
  !!user && !!user.authUser && !!user.user
