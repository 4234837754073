import { firestoreConverter } from "./FirestoreConverter"

export interface PublicOption {
  id: string
  observedDate: string
  contractSymbol: string
  expiration: {
    fmt: string
    raw: number
  }
  impliedVolatility: {
    fmt: string
    raw: number
  }
  lastPrice: {
    fmt: string
    raw: number
  }
  strike: {
    fmt: string
    raw: number
  }
  strike_percent_of_spot: number
  volume: {
    raw: number
    fmt: string
  }
  openInterest: {
    raw: string
    fmt: number
  }
}

export const publicOptionConverter = firestoreConverter<PublicOption>()
