import { Nothing, nullableToMaybe } from "common/containers/Maybe"
import { Company } from "common/model/Company"
import {
  getLatestRoundForCalculations,
  getPriorFullInformationRound,
} from "common/model/Company/FundingRound"
import {
  PricePerShareToConvertIntoValuation,
  getValuationFromTheDaysSplitAdjustedData,
  getValuationFromTodaysSplitAdjustedData,
  valuationFromPricePerShareInformation,
} from "common/model/data-product/valuationCalculation/Valuation"
import { isLoaded } from "common/utils/Loading"
import { useMemo } from "react"
import { useCompanies } from "src/providers/data/CompanyProvider"

export const getCompanyValuation = (firestoreIssuer: Company) => {
  /**
   * Calculate the valuation of a company based on the price per share.
   * @param pricePerShare -
   * Specify if it's an already split price per share or a pre-split price per share.
   * Whether it's a pre- or post-split price per share, we need to know the date of the price.
   * If it's a post-split price per share, we need to know the date for which the price was evaluated. Typically, this date is today.
   */
  const valuationFromPPS = (pricePerShare: PricePerShareToConvertIntoValuation) =>
    valuationFromPricePerShareInformation(pricePerShare, firestoreIssuer)

  /** see explanation for valuationFromPPS */
  const valuationFromTodaysSplitAdjustedData = (pricePerShare: number, priceDate: Date) =>
    getValuationFromTodaysSplitAdjustedData(pricePerShare, priceDate, firestoreIssuer)

  const valuationChangeVsPriorRoundFromTodaysSplitAdjPrice = (
    todaysSplitAdjustedPrice: number,
    date: Date
  ) => {
    const { latestRoundWithInfo } = getLatestRoundForCalculations(
      firestoreIssuer.fundingRounds,
      date
    )
    const nowVal = valuationFromTodaysSplitAdjustedData(todaysSplitAdjustedPrice, date)
    return nowVal
      .alongside(nullableToMaybe(latestRoundWithInfo?.valuation))
      .map(([now_, then_]) => now_.valuation / then_)
  }

  /**
   * Price per share as of priceDate, split adjusted for priceDate (and not today).
   * Use for e.g. MarketPrice, as it would have been generated yesterday.
   * */
  const valuationChangeVsPriorRoundForTheDay = (todaysSplitAdjustedPrice: number, date: Date) => {
    const { latestRoundWithInfo } = getPriorFullInformationRound(
      firestoreIssuer.fundingRounds,
      date
    )
    const nowVal = getValuationFromTheDaysSplitAdjustedData(
      todaysSplitAdjustedPrice,
      date,
      firestoreIssuer
    )
    return nowVal
      .alongside(nullableToMaybe(latestRoundWithInfo?.valuation))
      .map(([now_, then_]) => now_.valuation / then_)
  }

  const premiumOrDiscountToLastFundingRound = (todaysSplitAdjustedPrice: number, date: Date) => {
    const { latestRoundWithInfo } = getPriorFullInformationRound(
      firestoreIssuer.fundingRounds,
      date
    )

    const currentValuation = getValuationFromTheDaysSplitAdjustedData(
      todaysSplitAdjustedPrice,
      date,
      firestoreIssuer
    )

    return currentValuation
      .alongside(nullableToMaybe(latestRoundWithInfo?.valuation))
      .map(([current, initial]) => (current.valuation - initial) / initial)
  }

  return {
    valuationFromPPS,
    valuationFromTodaysSplitAdjustedData,
    valuationChangeVsPriorRoundFromTodaysSplitAdjPrice,
    valuationChangeVsPriorRoundForTheDay,
    premiumOrDiscountToLastFundingRound,
  }
}

export const useCompanyValuation = (company: { id: string }) => {
  const firestoreIssuer = useCompanies(company.id)
  return useMemo(
    () =>
      isLoaded(firestoreIssuer)
        ? getCompanyValuation(firestoreIssuer)
        : {
            valuationFromPPS: () => Nothing,
            valuationFromTodaysSplitAdjustedData: () => Nothing,
            valuationChangeVsPriorRoundFromTodaysSplitAdjPrice: () => Nothing,
            valuationChangeVsPriorRoundForTheDay: () => Nothing,
            premiumOrDiscountToLastFundingRound: () => Nothing,
          },
    [firestoreIssuer]
  )
}
