import { SyncOutlined } from "@ant-design/icons"
import { useDrawerControls } from "@components/Drawer/DrawerControlContext"
import { CheckboxWithLabel } from "@components/inputs/checkbox/Checkbox"
import { Button } from "@stories/components/Button/Button"
import { FormLabel } from "@stories/components/Inputs/FormLabel/FormLabel"
import { Color, Size } from "@stories/components/Typography/Typography"
import { LIVE_UNTIL_DEFAULT_DAYS } from "common/model/Order/OrderConstants"
import { useState } from "react"
import { DarkpoolLimitText } from "src/pages/Orders/MyOrders/DarkpoolLimitText"
import { useOrderForm } from "../../OrderFormContext"
import { OrderQuotaComplianceWarning } from "../../OrderQuotaComplianceWarning"
import { useDarkpoolState } from "../../contextHooks/useDarkpoolState"
import { isCompanyPublic } from "common/model/Company"
import { matchLoading } from "common/utils/Loading"

export const RefreshOrderFormProgressButtons = () => {
  const { validateAndSave, formErrors, company } = useOrderForm()
  const { isDarkpoolSubmissionBlocked } = useDarkpoolState()
  const { onClose } = useDrawerControls()
  const [acknowledged, setAcknowledged] = useState(false)
  const priceErrors = [...formErrors].filter((err) => err === "price" || err === "size")
  const canRenew =
    priceErrors.length === 0 &&
    acknowledged &&
    !isDarkpoolSubmissionBlocked &&
    matchLoading(company, (c) => !isCompanyPublic(c), true, true)
  return (
    <div>
      <div className="flex flex-col items-center mb-4">
        <CheckboxWithLabel
          checked={acknowledged}
          id="refresh-order-acknowledge-checkbox"
          onChange={(e) => setAcknowledged(e.target.checked)}
          title="I confirm that this order is still active"
        />
      </div>
      {priceErrors.length > 0 ? (
        <FormLabel hasError>
          Something went wrong, click the edit button to review your order details.
        </FormLabel>
      ) : null}
      <div className=" grid grid-cols-2 gap-2">
        <Button
          size="large"
          id="refresh-order-cancel-button"
          label="Cancel"
          onClick={onClose}
          variant="secondary"
        />
        <div className=" grid grid-cols-1">
          <Button
            size="large"
            id="refresh-order-submit-button"
            leftIcon={<SyncOutlined className="text-white" />}
            label={`Renew order for ${LIVE_UNTIL_DEFAULT_DAYS} Days`}
            onClick={validateAndSave}
            isDisabled={!canRenew}
          />
          {isDarkpoolSubmissionBlocked ? (
            <DarkpoolLimitText color={Color.Danger} size={Size.Small} />
          ) : null}
        </div>
      </div>
      <div className="mt-2">
        <OrderQuotaComplianceWarning />
      </div>
    </div>
  )
}
