import { OrderOpportunityInboxItem } from "../../types"
import { Button } from "@stories/components/Button/Button"
import { useModal } from "@components/layout/Modal"
import { XIcon } from "@stories/icons/XIcon"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { markOpportunityAsArchived } from "src/firebase/opportunities"
import { handleConsoleError, trackMarkOpportunityAsArchived } from "src/utils/Tracking"
import { Tooltip } from "@stories/components/Antd/Tooltip/Tooltip"
import { relativeDateFormat } from "common/utils/dateUtils"
import {
  isOpportunityArchivedInbox,
  isOpportunityExpired,
} from "common/model/Opportunity/Opportunity"
import {
  ArchiveReason,
  opportunityArchivedAt,
} from "common/model/Opportunity/fns/archiveOpportunity"
import { NotInterestedModalBody } from "./NotInterestedModal"
import { Popover } from "@stories/components/Antd/Popover/Popover"

// Caller must give both or neither
type DismissControlProps =
  | {
      isDismissPopoverOpen: boolean
      setIsDismissPopoverOpen: (b: boolean) => void
    }
  | { isDismissPopoverOpen?: never; setIsDismissPopoverOpen?: never }

type NotInterestedButtonProps = {
  item: OrderOpportunityInboxItem
  iconOnly: boolean
} & DismissControlProps

export const NotInterestedButton = ({
  item,
  iconOnly,
  isDismissPopoverOpen,
  setIsDismissPopoverOpen,
}: NotInterestedButtonProps) => {
  const firebase9 = useFirebase9()
  const { user } = useLoggedInUser()
  const { open, setOpen } = useModal()

  const handleMarkAsNotInterest = (archiveReasons: ArchiveReason[]) => {
    markOpportunityAsArchived(firebase9, item.opportunity, user, archiveReasons)
      .catch(handleConsoleError)
      .then(() => trackMarkOpportunityAsArchived(archiveReasons))
      .catch(handleConsoleError)
  }

  if (isOpportunityArchivedInbox(item.opportunity)) {
    return opportunityArchivedAt(item.opportunity).match(
      (archivedAtDate) => (
        <Tooltip title={`Archived ${relativeDateFormat(archivedAtDate)}`}>
          <Button
            leftIcon={<XIcon />}
            variant="secondary"
            label={iconOnly ? undefined : "Not Interested"}
            isDisabled
          />
        </Tooltip>
      ),
      () => (
        <Tooltip
          title={
            isOpportunityExpired(item.opportunity)
              ? `Opportunity has expired ${relativeDateFormat(
                  item.opportunity.orderMetadata.expiresAt
                )}`
              : ""
          }
        >
          <Button
            leftIcon={<XIcon />}
            variant="secondary"
            label={iconOnly ? undefined : "Not Interested"}
            isDisabled
          />
        </Tooltip>
      )
    )
  }
  return (
    <Popover
      trigger="click"
      open={isDismissPopoverOpen ?? open}
      onOpenChange={setIsDismissPopoverOpen ?? setOpen}
      placement="bottomLeft"
      content={<NotInterestedModalBody item={item} onMarkNotInterested={handleMarkAsNotInterest} />}
    >
      <Tooltip title="Not Interested">
        <Button
          leftIcon={<XIcon />}
          variant="secondary"
          label={iconOnly ? undefined : "Not Interested"}
        />
      </Tooltip>
    </Popover>
  )
}
