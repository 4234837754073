import { DocumentData } from "@firebase/firestore-types"

/** Makes it possible to detect serializability at runtime.
 * This symbol should only be added to objects that implement the ISerializable interface. */
export const isSerializable: unique symbol = Symbol("isSerializable")

export interface ISerializable<Serialized extends DocumentData> {
  [isSerializable]: true
  serialize: (this: this) => Serialized
}
