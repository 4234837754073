import CompanyLogo from "@components/CompanyLogo"
import { Order } from "common/model/Order/Models/Wrapped"
import { CompanyIdFields } from "common/model/Company"
import { OrderDirection } from "common/model/Order/Order"
import moment from "moment"
import { DirectionCell } from "src/pages/Orders/shared/Cells"
import { classNames } from "../../../utils/classNames"
import MarketPill from "@stories/components/Badges/MarketPill/MarketPill"
import { DarkpoolIcon } from "@stories/icons/DarkpoolIcon"
import { TopBrokerPill } from "@stories/components/Badges/TopBrokerPill/TopBrokerPill"
import { useTopBrokerAccountIds } from "../../../providers/topBroker/TopBrokerProvider"
import { Color } from "@stories/components/Typography/Typography"

export const OrderCardHeader = ({
  company,
  direction,
  createdAt,
  updatedAt,
  order,
  darkpool,
}: {
  company?: CompanyIdFields
  direction?: OrderDirection | null
  createdAt?: Date
  updatedAt?: Date
  order?: Order
  darkpool?: boolean
}) => {
  const topBrokerAccountIds = useTopBrokerAccountIds()
  return (
    <>
      <div className="flex items-center">
        <div className="flex items-center w-full">
          <CompanyLogo className="pr-2" company={company?.airtableId || ""} size="sm" />
          <div className="flex items-center justify-between text-sm mb-1 w-full">
            <div className="flex items-center space-x-1">
              {direction ? (
                <DirectionCell direction={direction} />
              ) : (
                <MarketPill variant="closed_trade" />
              )}
              {darkpool || order?.isDarkpool() ? <DarkpoolIcon color={Color.Black} /> : null}
              {order?.isFromTopBrokerAccount(topBrokerAccountIds.map((id) => ({ id }))) ? (
                <TopBrokerPill showTooltip />
              ) : null}
            </div>
            <div>
              {createdAt ? (
                <div className="text-xs">Created {moment(createdAt).fromNow()}</div>
              ) : null}
              {updatedAt ? (
                <div
                  className={classNames(
                    "text-xs",
                    order?.isOutdated() &&
                      "py-1.5 px-2 bg-warning-100 rounded border border-warning-500"
                  )}
                >
                  Updated {moment(updatedAt).fromNow()}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
