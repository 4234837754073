import {
  AnchorTradeOrOrder,
  DeepPartialIntroduction,
  IntroductionAnchorTradeOrOrderId,
} from "common/model/IntroductionRequest"
import * as Wrapped from "common/model/Order/Models/Wrapped"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { isMarketsCustomer, isDataCustomer } from "common/model/Auth/Permissions"
import { CompanyIdFields, viewCompanyIdFields } from "common/model/Company"
import { User, viewUserIdFields } from "common/model/User"
import { viewAccountIdFields } from "common/model/Account"
import { useIntroductionRequests } from "src/providers/IntroductionRequests"
import { DrawerProps, Tooltip } from "@stories/components/Antd"
import { useFirebaseWriter } from "src/firebase/Context"
import { useModal } from "@components/layout/Modal"
import { createOrderFormFieldsFromOrder } from "common/model/Order/OrderForm/Constructors"
import { Order, orderStructures } from "common/model/Order/Order"
import React, { FC } from "react"
import { Interval } from "common/utils/data/Interval"
import {
  getMostCommonStructure,
  PriceObservationType,
} from "common/model/data-product/pricing/PriceObservation"
import { formatStructureDisplayName } from "common/model/data-product/pricing/formatStructureDisplayName"
import IntroductionRequestDrawer from "./IntroductionRequestByOrder"
import { saveOrUpdateIntroductionRequest } from "./util"
import { useFirebase9 } from "../../firebase/Firebase9Context"
import { handleConsoleError, trackUserEventInFirestore } from "../../utils/Tracking"
import { Button, ButtonSize, ButtonVariant } from "@stories/components/Button/Button"
import CaplightConnectIcon from "@stories/icons/ConnectIcon"
import { isOrderConnectable } from "common/model/Order/Models/Internal"
import { trackAccountActivationFirebase9 } from "src/utils/AccountActivation"
import { AccountClientType } from "common/model/Account/AccountClientType"
import { useAccountTradingRegistrationStatus } from "src/utils/useAccountTradingRegistration"
import { TradingRegistrationRequiredModal } from "@components/TradingRegistration/TradingRegistrationRequiredModal"

const allowedClientTypes: AccountClientType[] = ["Intermediary", "Investor/Shareholder", "Lender"]

export const useIntroductionRequestButton = (
  user: User,
  anchorType: "trade" | "order" | "mixed"
) => {
  // Show to connect customers only on orders
  const showToConnectCustomer = isMarketsCustomer(user.account) && anchorType !== "trade"
  const showToCustomer = isDataCustomer(user.account) || showToConnectCustomer

  const showToClientType = user.account.clientType.some((t) => allowedClientTypes.includes(t))

  const showIntroductionRequestButtonToUser = showToCustomer && showToClientType
  return {
    showIntroductionRequestButtonToUser,
  }
}

export type IntroductionRequestButtonProps = {
  company: CompanyIdFields
  anchorTradeOrOrder: AnchorTradeOrOrder
  size?: ButtonSize
  buttonClassName?: string
  wrappedOrder?: Wrapped.Order
  variant?: ButtonVariant
  sourceComponent:
    | "live-market"
    | "order-details-page"
    | "company-details-recent-market-activity"
    | "company-details-trade-history"
    | "event-feed-card"
    | "order-inquiry-inbox"
    | "shared-orders"
    | "opportunity-inbox"
  onClick?: () => void
  iconOnly?: boolean
  tooltipText?: string
}
const IntroductionRequestButton = ({
  company,
  size,
  anchorTradeOrOrder,
  wrappedOrder,
  variant = "connect",
  sourceComponent,
  onClick,
  iconOnly,
  tooltipText,
}: IntroductionRequestButtonProps) => {
  const { open, openModal: openDrawer, closeModal: closeDrawer } = useModal()
  const { isAccountApprovedForTrading } = useAccountTradingRegistrationStatus()
  const user = useLoggedInUser()
  const userIsClient = !user.user.account.clientType.includes("Intermediary")
  const firebase = useFirebaseWriter()
  const firebase9 = useFirebase9()
  const { showIntroductionRequestButtonToUser } = useIntroductionRequestButton(
    user.user,
    anchorTradeOrOrder.direction ? "order" : "trade"
  )
  const { selectOrderIntroductionOfUser } = useIntroductionRequests()
  const fetchedIntro = selectOrderIntroductionOfUser(anchorTradeOrOrder.id)

  const priceObservationId: IntroductionAnchorTradeOrOrderId = {
    type: anchorTradeOrOrder.type,
    id: anchorTradeOrOrder.id,
  }

  const lockedDirection =
    anchorTradeOrOrder.direction === "buy"
      ? "sell"
      : anchorTradeOrOrder.direction === "sell"
      ? "buy"
      : null

  const workingIntroductionRequest: DeepPartialIntroduction = {
    createdAt: new Date(),
    requesterOrders: [],
    ...fetchedIntro,
    updatedAt: new Date(),
    status: "requested",
    priceObservationId,
    anchorTradeOrOrder,
    company: viewCompanyIdFields(company),
    createdBy: viewUserIdFields(user.user),
    account: viewAccountIdFields(user.user.account),
    data: {
      price: anchorTradeOrOrder.price,
      direction: lockedDirection,
    },
  }

  const itemTypeDisplay =
    anchorTradeOrOrder.type === "closedTrade"
      ? "closed trade"
      : `indicative ${anchorTradeOrOrder.direction === "sell" ? "offer" : "bid"}`

  if (!showIntroductionRequestButtonToUser || user.user.account.id === anchorTradeOrOrder.accountId)
    return null

  const sharedDrawerProps = {
    title: (
      <div className="flex items-center space-x-1">
        <CaplightConnectIcon />
        <div className="font-serif font-bold">Connect</div>
      </div>
    ),
    placement: "right",
  } satisfies DrawerProps

  const saveHandler = async (i: DeepPartialIntroduction) => {
    try {
      await saveOrUpdateIntroductionRequest(i, firebase, { sourceComponent })
      trackUserEventInFirestore(firebase9, user.user, "connect-request-submitted")
      await trackAccountActivationFirebase9(
        firebase9,
        user.user.account,
        "submittedAnIntroductionRequest"
      )
    } catch (e) {
      handleConsoleError(e)
      return false
    }
    return true
  }

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    openDrawer()
    // firebase
    //   .recordUserProductInteraction(user.user, { lastViewedConnectDrawer: new Date() })
    //   .catch(handleConsoleError)
    onClick?.()
  }

  return (
    <>
      {fetchedIntro?.id ? (
        <button
          id={`caplight-connect-pending-${anchorTradeOrOrder.id}`}
          type="button"
          className="text-xs text-neutral-1000 background-transparent underline text-left"
          onClick={handleClick}
        >
          Pending Connect
        </button>
      ) : (
        <>
          {iconOnly ? (
            <Tooltip
              title={
                tooltipText ??
                `Connect with the broker or investor/shareholder behind this ${itemTypeDisplay}`
              }
            >
              <Button
                id={`caplight-connect-start-icon-${anchorTradeOrOrder.id}`}
                onClick={handleClick as unknown as () => void}
                variant={variant}
                label=""
                rightIcon={<CaplightConnectIcon />}
                size={size}
                heapName="connect-btn"
                dataAttributes={{ "source-component": sourceComponent }}
              />
            </Tooltip>
          ) : (
            <Button
              id={`caplight-connect-start-${anchorTradeOrOrder.id}`}
              onClick={handleClick as unknown as () => void}
              variant={variant}
              label="Connect"
              leftIcon={<CaplightConnectIcon />}
              size={size}
              heapName="connect-btn"
              dataAttributes={{ "source-component": sourceComponent }}
            />
          )}
        </>
      )}
      {isAccountApprovedForTrading ? (
        <IntroductionRequestDrawer
          open={open}
          onClose={closeDrawer}
          introductionRequest={workingIntroductionRequest}
          userIsClient={userIsClient}
          handleSave={saveHandler}
          drawerProps={sharedDrawerProps}
          wrappedOrder={wrappedOrder}
        />
      ) : open ? (
        <TradingRegistrationRequiredModal
          open={open}
          dataType="introduction request"
          onClose={closeDrawer}
        />
      ) : null}
    </>
  )
}

export const IntroductionRequestButtonForOrder: FC<
  React.PropsWithChildren<
    { order: Order } & Omit<IntroductionRequestButtonProps, "anchorTradeOrOrder" | "company">
  >
> = ({
  order,
  wrappedOrder,
  buttonClassName,
  size,
  sourceComponent: pageType,
  onClick,
  iconOnly,
  tooltipText,
  variant,
}) => {
  const { price, size: orderSize } = createOrderFormFieldsFromOrder(order)

  return isOrderConnectable(order) ? (
    <IntroductionRequestButton
      onClick={onClick}
      sourceComponent={pageType}
      buttonClassName={buttonClassName}
      company={order.company}
      anchorTradeOrOrder={{
        createdAt: order.orderOriginationDate ?? undefined,
        type:
          order.orderCollection === "darkpool"
            ? "darkpool_order"
            : order.orderCollection === "tentativeInterest"
            ? "tentative_interest"
            : "order",
        id: order.id,
        accountId: order.source.account.id,
        direction: order.direction,
        price,
        volumeUSD: orderSize?.amountUSD?.lowerBound,
        structure: orderStructures(order)[0],
      }}
      size={size}
      wrappedOrder={wrappedOrder}
      iconOnly={iconOnly}
      tooltipText={tooltipText}
      variant={variant}
    />
  ) : null
}

export const IntroductionRequestButtonForPriceObservation: FC<
  React.PropsWithChildren<
    {
      trade: PriceObservationType
    } & Omit<IntroductionRequestButtonProps, "anchorTradeOrOrder" | "company">
  >
> = ({ trade, size, buttonClassName, sourceComponent: pageType }) => (
  <IntroductionRequestButton
    sourceComponent={pageType}
    size={size}
    buttonClassName={buttonClassName}
    company={trade.company}
    anchorTradeOrOrder={{
      createdAt: trade.createdDate,
      type: "closedTrade",
      id: trade.id ?? "",
      accountId: trade.observedBy.id,
      volumeUSD: trade.volume,
      structure: formatStructureDisplayName(getMostCommonStructure(trade.structure)),
      price: { USDPerShare: Interval.pure(trade.price) },
    }}
  />
)

export default IntroductionRequestButton
