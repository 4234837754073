import { FC } from "react"
import { LogoColor, LogoSize, variants } from "./LogoVariants"

interface LogoProps {
  /**
   * Is the logo rendered with only the icon?
   */
  isIconOnly?: boolean
  /**
   * What color should the logo be?
   */
  color: LogoColor
  /**
   * What size should the logo be?
   */
  size?: LogoSize
}

export const Logo: FC<React.PropsWithChildren<LogoProps>> = ({
  isIconOnly = false,
  color = "black",
  size = "regular",
}) => variants[color][isIconOnly ? "iconOnly" : "default"][size]
