import moment from "moment"
import { Maybe, Nothing, nullableToMaybe } from "../../containers/Maybe"
import { ISODateFormat } from "../../utils/dateUtils"
import { BILLION } from "../../utils/math"
import { Account, AccountIdFields } from "../Account"
import { AccountClientType } from "../Account/AccountClientType"
import { AccountRFQProfile } from "../Account/AccountRFQProfiles"
import { CompanyIdFields, CompanyIdFieldsWithPostgres } from "../Company"
import { DeprecatedOrder } from "../DeprecatedOrder"
import { UserCreatable } from "../UserCreatable"
import { CampaignBatch } from "../campaigns/CampaignType"
import { RFQIndicationInterest } from "./RFQIndicationInterest"
import { V2PartialRFQResponse } from "./V2RFQ"
import { V3PartialRFQResponse } from "./V3RFQ"

export const RFQ_REMINDER_EMAIL_AFTER_HOURS = 24

export const CURRENT_RFQ_VERSION: RFQResponse["version"] = 3

// derived based on date & isDraft
export type RFQStatusType = "draft" | "scheduled" | "live" | "complete"

export type RFQResponseTypes =
  | RFQResponse
  | AnonymousRFQResponse
  | V2PartialRFQResponse
  | V3PartialRFQResponse

export type PriceAndValuation = { pps?: number | null; valuation?: number | null }

export type RFQAccount = AccountIdFields & Pick<Account, "rfqProfile">

export type DeprecatedRFQOrder = DeprecatedOrder

export type RFQOrderTerms = {
  pps: number | null
  valuation: number | null
  size: number | null
}

export interface RFQRequest extends UserCreatable {
  id: string
  company: CompanyIdFields
  isBackedByOrder: boolean
}

export type RFQOrderData =
  | {
      tag: "deprecated"
      anchorOrder: DeprecatedRFQOrder
      orderTerms: null
    }
  | {
      tag: "current"
      anchorOrder: null
      orderTerms: RFQOrderTerms
      company: CompanyIdFieldsWithPostgres
    }

export type RFQType = {
  id: string
  name?: string | null
  batch: CampaignBatch
  // @deprecated use anchorOrderTerms instead
  isDraft: boolean
  reminderEnabled: boolean
  version: number
  resultsDownloadUrl?: string
  isSensitive?: boolean // Sensitive RFQs are hidden from non-invited participants
  isTest?: boolean // Test RFQs are hidden from non-invited participants
  insights?: string
  isHostedResultsLive?: boolean
  announcementNotificationsGenerated?: boolean
  stopAllEmails?: boolean
} & UserCreatable &
  RFQOrderData

export type PartialRFQResponse = V2PartialRFQResponse | V3PartialRFQResponse

export interface RFQResponse extends UserCreatable, RFQIndicationInterest {
  id: string
  rfqId: string
  version: number
  formType: AccountClientType
  account: RFQAccount
  currentSpot: PriceAndValuation | null
  currentQuestion: number
  feedback?: string
  orderInterest?: boolean
  interestedInRunningOwnRFQ?: boolean
}
export type AnonymousRFQResponse = Omit<RFQResponse, "account" | "createdBy"> & {
  createdBy: undefined
  account: { rfqProfile?: AccountRFQProfile }
}

export const getRFQName = (rfq: RFQType): string =>
  rfq.name ??
  (rfq.tag === "current"
    ? `${rfq.company.name} RFQ (${ISODateFormat(rfq.batch.startDate)})`
    : `${rfq.anchorOrder?.issuerName} RFQ (${ISODateFormat(rfq.batch.startDate)})`)

export const getRFQStatus = (rfq: RFQType): RFQStatusType => {
  if (rfq.isDraft) return "draft"
  if (moment(rfq.batch.endDate).isBefore(moment())) return "complete"
  if (moment(rfq.batch.startDate).isAfter(moment())) return "scheduled"
  return "live"
}

export const getRFQValuationFromPPS = (rfq: RFQType, pps: number): Maybe<number> =>
  rfq.anchorOrder
    ? nullableToMaybe(rfq.anchorOrder.refValuation)
        .alongside(nullableToMaybe(rfq.anchorOrder.refPPS))
        .map(([refVal, refPPS]) => refVal * BILLION * (pps / refPPS))
    : rfq.orderTerms
    ? nullableToMaybe(rfq.orderTerms.valuation)
        .alongside(nullableToMaybe(rfq.orderTerms.pps))
        .map(([refVal, refPPS]) => refVal * BILLION * (pps / refPPS))
    : Nothing

export const rfqCompanyName = (rfq: RFQType): string =>
  rfq.tag === "current" ? rfq.company.name : rfq.anchorOrder.issuerName

export const rfqCompanyId = (rfq: RFQType): string =>
  rfq.tag === "current" ? rfq.company.id : rfq.anchorOrder.companyId