import { TableRow, WindowedTableProps } from "./WindowedTable"

export const NonWindowedTable = <T,>({
  items,
  headers,
  renderRow,
  itemSize,
}: WindowedTableProps<T>) => {
  const rows = TableRow(renderRow)(items)
  return (
    <table className="table-auto border border-neutral-400 bg-neutral-white mb-2 overflow-x-auto w-full">
      <thead className="bg-neutral-400 border-b">
        <tr>
          {headers.map((h, i) => (
            <th className="px-2 text-sm text-left" key={i}>
              {h}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{items.map((_i, index) => rows({ index, style: { height: itemSize } }))}</tbody>
    </table>
  )
}
