import Drawer, { useDrawer } from "@components/Drawer/Drawer"
import { ShareOrderWorkflow } from "./ShareOrderWorkflow"
import { Button, ButtonProps } from "@stories/components/Button/Button"
import { ShareIcon } from "@stories/icons/ShareIcon"
import { trackEvent } from "src/utils/Tracking"
import { uniq } from "lodash"
import { assertUnreachable } from "common/utils/fp/Function"
import { ShareOrderStatusIcon } from "./ShareOrderStatusIcon"
import { ShareableItem } from "common/model/SharedOrder/SharedOrderResponse"
import { SharedOrderEditorProvider } from "./SharedOrderEmailEditor/SharedOrderEditorProvider"
import { multipleOrdersTemplate } from "./sharedOrderTemplates/multipleOrdersTemplate"

export const ShareOrderButton = ({
  item,
  title,
  variant,
  size,
}: {
  item: ShareableItem
  title?: string
} & Pick<ButtonProps, "variant" | "size">) => (
  <ShareMultipleOrderButton
    items={[item]}
    title={title}
    variant={variant}
    size={size}
    icon={<ShareOrderStatusIcon item={item} showIconOnEmpty />}
  />
)

export const useMultipleSharedOrderWorkflow = ({
  items,
  title,
  onSend,
}: {
  items: ShareableItem[]
  title?: string
  onSend?: () => void
}) => {
  const { open, openDrawer, closeDrawer } = useDrawer()
  const orderDirections = items.map((item) =>
    item.tag === "order"
      ? item.order.direction
      : item.tag === "crm_interest"
      ? item.crmInterest.direction
      : item.tag === "deal_search"
      ? item.dealSearch.direction
      : assertUnreachable(item)
  )
  const handleOpenShareWorkflow = () => {
    trackEvent("share-order-button-click", {
      orderCount: items.filter(
        (item) => item.tag === "order" && item.order.orderCollection !== "tentativeInterest"
      ).length,
      tentativeInterestCount: items.filter(
        (item) => item.tag === "order" && item.order.orderCollection === "tentativeInterest"
      ).length,
      direction: uniq(orderDirections).length === 1 ? orderDirections[0] : "mixed",
    })
    openDrawer()
  }
  return {
    handleOpenShareWorkflow,
    drawer: (
      <>
        {open ? (
          <Drawer title={title ?? "Share to My Network"} size="lg" open={open} onClose={closeDrawer}>
            <SharedOrderEditorProvider
              items={items}
              initialContacts={[]}
              templates={[multipleOrdersTemplate]}
              onSend={() => {
                onSend?.()
                closeDrawer()
              }}
            >
              <ShareOrderWorkflow items={items} onCancel={closeDrawer} />
            </SharedOrderEditorProvider>
          </Drawer>
        ) : null}
      </>
    ),
  }
}

export const ShareMultipleOrderButton = ({
  items,
  title,
  variant,
  size,
  icon,
  onSend,
}: {
  items: ShareableItem[]
  title?: string
  icon?: React.ReactElement
  onSend?: () => void
} & Pick<ButtonProps, "variant" | "size">) => {
  const { handleOpenShareWorkflow, drawer } = useMultipleSharedOrderWorkflow({
    items,
    title,
    onSend,
  })

  return (
    <>
      <Button
        variant={variant}
        size={size}
        onClick={handleOpenShareWorkflow}
        label={title ?? "Share to My Network"}
        leftIcon={icon ?? <ShareIcon />}
        heapName="share-order-button"
      />
      {drawer}
    </>
  )
}
