import { notification } from "@stories/components/Antd"
import { ConnectThread } from "common/model/Messaging/ConnectThread"
import { THREAD_ID_PARAM } from "common/model/Messaging/MessagingRoutes"
import { arrayPartition, head } from "common/utils/data/Array/ArrayUtils"
import { identity } from "common/utils/fp/Function"
import { isLoaded, Loading, mapLoading, matchLoading } from "common/utils/Loading"
import { Rec, UnsafeRec } from "common/utils/RecordUtils"
import { chain, isEqual } from "lodash"
import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useLoggedInUserThreads } from "src/pages/Messaging/hooks/useThread"
import { isUnread, unreadMessageDate } from "src/pages/Messaging/hooks/useUnreadThreads"
import { useIntroductionRequests } from "src/providers/data/IntroductionRequestProvider"
import { playNewMessageAudio } from "../../pages/Messaging/utils/audio/NewMessageAudio"
import { useFeatureFlag } from "../featureFlags/useFeatureFlags"
import { useLoggedInUser } from "../loggedInUser/useLoggedInUser"
import { Button } from "@stories/components/Button/Button"
import { ThreadType } from "common/model/Messaging/ThreadTypes/ThreadType"
import { useFirebaseReader } from "src/firebase/Context"
import { FirebaseReader } from "src/firebase/Firebase"
import { collections } from "common/firestore/Collections"
import { IntroductionRequest } from "common/model/IntroductionRequest"
import { firestoreConverter } from "@model/FirestoreConverter"
import { handleConsoleError } from "src/utils/Tracking"
import { OrderInquiry } from "common/model/OrderInquiry/Db"

type MessageThreadProviderData = {
  connectThreads: Loading<ConnectThread[]>
  unreadCount: number
  unreadCountByThreadType: Record<ConnectThread["threadType"], number>
  selectedThread: ConnectThread | null
}

const MessageThreadContext = createContext<MessageThreadProviderData>({
  connectThreads: "loading",
  unreadCount: 0,
  unreadCountByThreadType: { orderInquiry: 0, connectRequest: 0 },
  selectedThread: null,
})

const getCompanyFromThread = async (db: FirebaseReader, thread: ConnectThread) => {
  const company =
    thread?.threadType === "connectRequest" && thread?.parentDocument.tag === "connection request"
      ? await db.db
          .collection(collections.introductionRequest)
          .withConverter<IntroductionRequest>(firestoreConverter<IntroductionRequest>())
          .doc(thread.parentDocument.requestIds[0])
          .get()
          .then((doc) => doc.data()?.company)
      : thread?.threadType === "orderInquiry"
      ? await db.db
          .collection(collections.orderInquiries)
          .withConverter<OrderInquiry>(firestoreConverter<OrderInquiry>())
          .doc(thread.parentDocument.id)
          .get()
          .then((doc) => doc.data()?.source.anchorOrder.company)
      : null

  return company ?? null
}

const handleSendPlatformNotification = async (
  db: FirebaseReader,
  thread: ConnectThread,
  onViewThread: (threadId: string) => void
) => {
  const company = await getCompanyFromThread(db, thread)
  const messageType =
    thread.threadType === "connectRequest" && thread?.parentDocument.tag === "connection request"
      ? "Connection"
      : thread.threadType === "orderInquiry"
      ? "Inquiry"
      : "Thread"
  notification.success({
    key: thread.id,
    message: company ? `New Message on your ${company.name} ${messageType}` : "New Message",
    placement: "top",
    btn: (
      <Button
        label="View Message"
        onClick={() => onViewThread(thread.id)}
        variant="primary"
        size="small"
      />
    ),
  })
}

const useUnreadMessages = (connectThreads: Loading<ConnectThread[]>) => {
  const { user } = useLoggedInUser()
  const navigate = useNavigate()
  const location = useLocation()
  const db = useFirebaseReader()

  const [threadUnreadStatusMap, setThreadUnreadStatusMap] =
    useState<Record<string, number | null>>()
  const onViewThread = useCallback(
    (threadId: string) => {
      notification.close(threadId)
      navigate(`/inbox?${THREAD_ID_PARAM}=${threadId}`)
    },
    [navigate]
  )

  useEffect(() => {
    if (isLoaded(connectThreads)) {
      const threadsById = chain(connectThreads)
        .keyBy((thread) => thread.id)
        .value()
      const updatedThreadUnreadStatusMap = chain(threadsById)
        .mapValues((thread) =>
          isUnread({ thread, user }) ? unreadMessageDate({ thread, user }) ?? 1 : null
        )
        .value()

      const shouldUpdateStatusMap = !isEqual(threadUnreadStatusMap, updatedThreadUnreadStatusMap)

      if (shouldUpdateStatusMap) {
        const shouldCheckForNewUnreadMessages = threadUnreadStatusMap !== undefined

        if (shouldCheckForNewUnreadMessages) {
          const threadIdWithNewUnreadTimestamp = chain(updatedThreadUnreadStatusMap)
            .pickBy((newStatus, threadId) => {
              const oldStatus = threadUnreadStatusMap?.[threadId]
              return !!newStatus && oldStatus !== newStatus
            })
            .keys()
            .head()
            .value()

          const selectedThreadId = new URLSearchParams(location.search).get(THREAD_ID_PARAM)

          if (threadIdWithNewUnreadTimestamp) {
            playNewMessageAudio()

            if (selectedThreadId !== threadIdWithNewUnreadTimestamp) {
              handleSendPlatformNotification(
                db,
                threadsById[threadIdWithNewUnreadTimestamp],
                onViewThread
              ).catch(handleConsoleError)
            }
          }
        }

        setThreadUnreadStatusMap(updatedThreadUnreadStatusMap)
      }
    }
  }, [connectThreads, onViewThread, threadUnreadStatusMap, user, location.search, db])

  const unreadCount = chain(threadUnreadStatusMap)
    .pickBy((v) => !!v)
    .values()
    .value().length

  const unreadCountByThreadType: Record<ThreadType, number> = useMemo(
    () =>
      Rec.map(
        matchLoading(
          connectThreads,
          (threads) =>
            arrayPartition(
              {
                connectRequest: (thread) =>
                  thread.threadType === "connectRequest" && isUnread({ thread, user }),
                orderInquiry: (thread) =>
                  thread.threadType === "orderInquiry" && isUnread({ thread, user }),
              },
              threads
            ),
          { connectRequest: [], orderInquiry: [] },
          { connectRequest: [], orderInquiry: [] }
        ),
        (t) => t.length
      ),
    [connectThreads, user]
  )

  return { unreadCount, unreadCountByThreadType }
}

const useRedirectToConnectedThread = (selectedThread: ConnectThread | null) => {
  const location = useLocation()
  const navigate = useNavigate()
  const selectedIntroductionRequest = matchLoading(
    useIntroductionRequests(
      selectedThread?.parentDocument.tag === "connection request"
        ? selectedThread.parentDocument.requestIds
        : []
    ),
    (intros) => head(intros).match(identity, () => null),
    null,
    null
  )

  useEffect(() => {
    if (
      selectedIntroductionRequest?.connectionThreadId &&
      selectedThread?.id !== selectedIntroductionRequest?.connectionThreadId
    ) {
      const search = new URLSearchParams(location.search)
      search.delete(THREAD_ID_PARAM)
      search.append(THREAD_ID_PARAM, selectedIntroductionRequest?.connectionThreadId)
      navigate({ search: search.toString() })
    }
  }, [
    navigate,
    location.search,
    selectedIntroductionRequest?.connectionThreadId,
    selectedThread?.id,
  ])
}

const useSelectedThread = (
  connectThreads: Loading<ConnectThread[]>,
  connectThreadsWithParents: Loading<ConnectThread[]>
) => {
  const location = useLocation()
  const search = useMemo(() => new URLSearchParams(location.search), [location.search])
  const threadId = useMemo(() => search.get(THREAD_ID_PARAM), [search])

  const selectedThread = useMemo(
    () =>
      matchLoading(
        connectThreadsWithParents,
        (ts) => ts.find((t) => t.id === threadId) ?? null,
        null,
        null
      ),
    [threadId, connectThreadsWithParents]
  )
  return { selectedThread }
}

const MessageThreadProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const loggedInUserThreads = useLoggedInUserThreads()
  const inquiryThreadsActive = useFeatureFlag("order_inquiry_threads")
  const connectThreads = mapLoading((threads: ConnectThread[]) => {
    const parentIdsOfGroup = threads.flatMap((t) =>
      UnsafeRec.values(t.participatingAccounts).flatMap((a) =>
        a.parentThreadId ? [a.parentThreadId] : []
      )
    )

    return threads.filter(
      (t) =>
        !parentIdsOfGroup.includes(t.id) &&
        (inquiryThreadsActive || t.threadType === "connectRequest")
    )
  })(loggedInUserThreads)

  const { unreadCount, unreadCountByThreadType } = useUnreadMessages(connectThreads)
  const { selectedThread } = useSelectedThread(connectThreads, loggedInUserThreads)
  useRedirectToConnectedThread(selectedThread)

  const value = useMemo(
    () => ({ connectThreads, unreadCount, unreadCountByThreadType, selectedThread }),
    [connectThreads, unreadCount, selectedThread, unreadCountByThreadType]
  )

  return <MessageThreadContext.Provider value={value}>{children}</MessageThreadContext.Provider>
}

export const useMessageThreads = () => useContext(MessageThreadContext)

export default MessageThreadProvider
