import {
  DealCRMJointContactImportData,
  dealCRMJointContactFieldParsers,
  getKnownIndividuals,
} from "common/model/DealCRM/DealCRMIndividualContact"
import { Right } from "common/containers/Either"
import { ContactCSVUploader } from "./ContactCSVUploader"
import { AccountIdFields } from "common/model/Account"
import { FirebaseCommon } from "common/firestore/Interface"
import { ImportJobSummaryData } from "../ImportJobSummaryData"
import { getKnownFirms } from "common/model/DealCRM/DealCRMFirmContact"
import { unique } from "common/utils/data/Array/ArrayUtils"
import { DealCRMImportJob, startCRMImportJob } from "common/model/DealCRM/DealCRMImportJob"
import { viewUserIdFields } from "common/model/User"
import { SubmittedDocument } from "common/model/files/DocumentSubmission"
import { useMemo } from "react"
import { useFirebaseWriter } from "src/firebase/Context"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"

export const jointImportJobSummary = async (
  a: AccountIdFields,
  db: FirebaseCommon.LimitedDB,
  recs: DealCRMJointContactImportData[]
): Promise<ImportJobSummaryData> => {
  const knownIndividuals = await getKnownIndividuals(db, a.id)
  const knownFirms = await getKnownFirms(db, a.id)
  return {
    individualsCount: unique(recs.map((r) => r.firstName + r.lastName)).length,
    newIndividualsImportedCount: recs.filter(
      (r) => !knownIndividuals.some((f) => f.firstName === r.firstName && f.lastName === r.lastName)
    ).length,
    firmsCount: unique(recs.map((r) => r.firmName)).length,
    newFirmsImportedCount: recs.filter((r) => !knownFirms.some((f) => f.name === r.firmName))
      .length,
  }
}

/** Create firms and individuals simultaneously */
export const JointContactCSVUploader = () => {
  const db = useFirebaseWriter()
  const user = useLoggedInUser()
  const startImportJob: (
    data: SubmittedDocument,
    importCounts: ImportJobSummaryData
  ) => Promise<DealCRMImportJob> = useMemo(
    () => (data, importCounts) =>
      startCRMImportJob(
        db.writerDb,
        user.user.account.id,
        viewUserIdFields(user.user),
        data,
        "joint",
        importCounts
      ),
    [db.writerDb, user.user]
  )
  return (
    <>
      <ContactCSVUploader
        fieldParsers={dealCRMJointContactFieldParsers}
        uploadButtonText="Upload Contact CSV"
        headerNames={
          {
            firmName: "Firm",
            firmType: "Firm Type",
            firstName: "First Name",
            lastName: "Last Name",
            email: "Email",
            phone: "Phone Number",
          } as const
        }
        startImportJob={startImportJob}
        postParseValidation={(__, _, records) => Promise.resolve(Right(records))}
        importJobSummary={jointImportJobSummary}
        uploadJobId={undefined}
        exampleLocation="/assets/JointContactExample.csv"
      />
    </>
  )
}
