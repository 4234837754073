/* This example requires Tailwind CSS v2.0+ */
import { Subtitle } from "@components/typography/Subtitle"
import { Modal as Dialog, ModalProps } from "@stories/components/Antd"
import { FC, ReactElement, useCallback, useState } from "react"
import { classNames } from "../../utils/classNames"
import { PageTitle } from "@components/typography/PageTitle"

export const useModal = (initial: boolean = false) => {
  const [open, setOpen] = useState(initial)
  const closeModal = useCallback(() => setOpen(false), [])
  const openModal = useCallback(() => setOpen(true), [])
  return { closeModal, openModal, open, setOpen }
}

export interface CustomModalProps {
  open: boolean
  handleClose: () => void
  className?: string
  wrapperClassName?: string
  modalClassName?: string
  title?: string
  subtitle?: string
  body?: ReactElement
  actions?: ReactElement
  closable?: boolean
  maskClosable?: boolean // should it close if you click outside
  noPadding?: boolean
  footer?: ReactElement
}

export type FullModalProps = CustomModalProps & Partial<ModalProps>

export const Modal: FC<React.PropsWithChildren<FullModalProps>> = ({
  open,
  handleClose,
  wrapperClassName,
  modalClassName,
  title,
  subtitle,
  body,
  closable,
  maskClosable,
  noPadding,
  className,
  ...rest
}) => {
  if (!open) return null

  return (
    <Dialog
      transitionName=""
      closable={!!closable}
      maskClosable={!!maskClosable}
      open={open}
      bodyStyle={{ padding: 0 }}
      style={{ padding: 0, ...rest.style }}
      onCancel={handleClose}
      footer={null}
      width="fit-content"
      className={wrapperClassName}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <div
        className={classNames(
          "flex items-end justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0",
          modalClassName
        )}
      >
        <div
          className={classNames(
            "h-4/5 inline-block align-bottom bg-neutral-white rounded-lg text-left overflow-auto shadow-xl transform transition-all sm:align-middle ",
            noPadding ? "" : "px-4 pt-5 pb-4 sm:p-6",
            className
          )}
        >
          <div className="max-h-full">
            {title && <PageTitle title={title} />}
            {subtitle && <Subtitle subtitle={subtitle} />}
            <div className={noPadding ? "" : "mt-2"}>{body}</div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
