import { matchLoading, lift2Loading, Loading } from "common/utils/Loading"
import { Order } from "common/model/Order/Order"
import { identity } from "common/utils/fp/Function"
import { MyOrderStatus } from "src/pages/Orders/MyOrders/MyOrdersTypes"
import { getMyOrderStatus } from "src/pages/Orders/MyOrders/utils"
import { useMemo } from "react"
import { isBefore, subDays } from "date-fns"
import { User } from "common/model/User"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"

export type AccountOrdersRequiringAttentionData = {
  ordersRequiringAttention: Loading<Order[]>
  darkpoolOrdersRequiringAttention: Loading<Order[]>
  countRequiresAttention: Loading<number>
}

export const initialAccountOrdersRequiringAttentionData: AccountOrdersRequiringAttentionData = {
  ordersRequiringAttention: "loading",
  darkpoolOrdersRequiringAttention: "loading",
  countRequiresAttention: 0,
}

const isOrderRequiringAttention = (o: Order, currentUser: User) => {
  const statusesRequiringAttention: MyOrderStatus[] = [
    "Close Pending",
    "Expired",
    "Expiring",
    "Matched",
  ]

  const overFiveDaysSinceLastStatusChange = o._lastReportedStatus?.asOf
    ? isBefore(new Date(o._lastReportedStatus?.asOf), subDays(new Date(), 5))
    : false
  const overThirtyDaysSinceLastStatusChange = o._lastReportedStatus?.asOf
    ? isBefore(new Date(o._lastReportedStatus?.asOf), subDays(new Date(), 30))
    : false

  const orderFromUser =
    o.source.sourceType === "user-form" && o.source.submittingUser?.id === currentUser.id

  return (
    statusesRequiringAttention.includes(getMyOrderStatus(o)) &&
    overFiveDaysSinceLastStatusChange &&
    !overThirtyDaysSinceLastStatusChange &&
    orderFromUser
  )
}

const checkOrderRequiresAttention = (orders: Order[], darkpoolOrders: Order[], user: User) => {
  const ordersRequiringAttention = orders.filter((o) => isOrderRequiringAttention(o, user))
  const darkpoolOrdersRequiringAttention = darkpoolOrders.filter((o) =>
    isOrderRequiringAttention(o, user)
  )
  const countRequiresAttention =
    ordersRequiringAttention.length + darkpoolOrdersRequiringAttention.length

  return {
    ordersRequiringAttention,
    darkpoolOrdersRequiringAttention,
    countRequiresAttention,
  }
}

export const useAccountOrdersRequiringAttention = (
  loadingOrders: Loading<Order[]>,
  loadingDarkpoolOrders: Loading<Order[]>
): AccountOrdersRequiringAttentionData => {
  const { user } = useLoggedInUser()
  const loadingAccountOrdersRequiringAttentionData = useMemo(
    () =>
      lift2Loading<Order[], Order[], AccountOrdersRequiringAttentionData>(
        (orders, darkpoolOrders) => checkOrderRequiresAttention(orders, darkpoolOrders, user),
        loadingOrders,
        loadingDarkpoolOrders
      ),
    [loadingOrders, loadingDarkpoolOrders, user]
  )

  return useMemo(
    () =>
      matchLoading(
        loadingAccountOrdersRequiringAttentionData,
        identity,
        initialAccountOrdersRequiringAttentionData,
        {
          ordersRequiringAttention: [],
          darkpoolOrdersRequiringAttention: [],
          countRequiresAttention: 0,
        }
      ),
    [loadingAccountOrdersRequiringAttentionData]
  )
}
