import { wrapIcon } from "./IconWrapper"

export const ItalicIcon = wrapIcon(
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.3333 4H7.33333C7.15652 4 6.98695 4.07024 6.86193 4.19526C6.7369 4.32029 6.66667 4.48986 6.66667 4.66667C6.66667 4.84348 6.7369 5.01305 6.86193 5.13807C6.98695 5.2631 7.15652 5.33333 7.33333 5.33333H8.34667L6.21333 10.6667H4.66667C4.48986 10.6667 4.32029 10.7369 4.19526 10.8619C4.07024 10.987 4 11.1565 4 11.3333C4 11.5101 4.07024 11.6797 4.19526 11.8047C4.32029 11.9298 4.48986 12 4.66667 12H8.66667C8.84348 12 9.01305 11.9298 9.13807 11.8047C9.2631 11.6797 9.33333 11.5101 9.33333 11.3333C9.33333 11.1565 9.2631 10.987 9.13807 10.8619C9.01305 10.7369 8.84348 10.6667 8.66667 10.6667H7.65333L9.78667 5.33333H11.3333C11.5101 5.33333 11.6797 5.2631 11.8047 5.13807C11.9298 5.01305 12 4.84348 12 4.66667C12 4.48986 11.9298 4.32029 11.8047 4.19526C11.6797 4.07024 11.5101 4 11.3333 4Z"
      fill="currentColor"
    />
  </svg>
)
