import { Route } from "react-router-dom"
import { doesRouteRequireTermsOfService, getRouteAccessConditions } from "src/Routes/auth"
import { getRoutePathTemplate } from "src/Routes/utils"
import { ReactNode } from "react"
import { RouteEndpoint, RouteParams } from "../../Routes/types"
import AuthBoundary from "./AuthBoundary"

export type AuthenticatedRouteProps = {
  exact?: boolean
  route: RouteEndpoint
  component: (props: object) => ReactNode | null
}

export const authenticatedRoute = ({ component, route }: AuthenticatedRouteProps) => {
  const accessConditions = (params: RouteParams) => getRouteAccessConditions(route, params)
  const requireToS = (params: RouteParams) => doesRouteRequireTermsOfService(route, params)
  const Component = component
  return (
    <Route
      path={getRoutePathTemplate(route)}
      key={route.name}
      index
      element={
        <AuthBoundary accessConditions={accessConditions} requireToS={requireToS}>
          <Component />
        </AuthBoundary>
      }
    />
  )
}
