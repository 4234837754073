import { matchLoading } from "common/utils/Loading"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import { useEmbed } from "../../providers/embed/useEmbed"
import { DataAccessLevel, checkAccessLevelByAccount, checkAccessLevel } from "./DataAccess"
import React, { useContext } from "react"
import { useLocation } from "react-router-dom"
import { sharedOrderRouteTemplate } from "common/model/SharedOrder/SharedOrder"

const DataAccessContext = React.createContext<{ overrideWith: boolean | null }>({
  overrideWith: null,
})

/**
 * Temporary workaround to get things rendering in storybook without having to clean up our auth code first
 * DO NOT USE IN THE APP */
export const DataAccessProvider = DataAccessContext.Provider

export const useAccountAccessLevel = (accessLevel: DataAccessLevel): boolean => {
  const { overrideWith } = useContext(DataAccessContext)
  const currentUser = useCurrentUser()
  const location = useLocation()
  const { account: embedAccount } = useEmbed()
  if (currentUser === null && embedAccount.isNothing() && overrideWith !== null) {
    return overrideWith
  }
  if (location.pathname.includes(sharedOrderRouteTemplate.replace(":id", ""))) {
    return true // TODO
  }
  return embedAccount.match(
    (account) => checkAccessLevelByAccount[accessLevel](account),
    () => matchLoading(currentUser, (u) => checkAccessLevel[accessLevel](u), false, false)
  )
}
