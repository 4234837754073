import { Company } from "common/model/Company"
import { Loading } from "common/utils/Loading/Wrapped"
import LoadingOrderMarketPriceComparison from "./LoadingOrderMarketPriceComparison"
import LoadedOrderMarketPriceComparison from "./LoadedOrderMarketPriceComparison"
import { OrderPageLoadingOrder } from "../types"

const OrderPageMarketPriceComparison = ({
  order,
  company,
}: OrderPageLoadingOrder & { company: Loading<Company> }): JSX.Element | null =>
  order
    .alongside(company)
    .match(
      ([loadedOrder, loadedCompany]) => (
        <LoadedOrderMarketPriceComparison order={loadedOrder} company={loadedCompany} />
      ),
      <LoadingOrderMarketPriceComparison />,
      null
    )

export default OrderPageMarketPriceComparison
