import { createOrderFromForm } from "common/model/Order/OrderForm/Constructors"
import { Order } from "common/model/Order/Order"
import { useFirebaseWriter } from "../../../firebase/Context"
import { useFirebase9 } from "../../../firebase/Firebase9Context"
import { addOrder } from "../../../firebase/orders"
import { useLoggedInUser } from "../../../providers/loggedInUser/useLoggedInUser"
import { trackAccountActivationFirebase9 } from "../../../utils/AccountActivation"
import { handleConsoleError, trackUserEventInFirestore } from "../../../utils/Tracking"
import { FormOrder } from "common/model/Order/OrderForm/State"

export const useOnSaveNewFormOrder = ({
  initialFormOrder,
  afterSave,
}: {
  initialFormOrder: Partial<FormOrder>
  afterSave?: (o: Order | undefined) => void
}) => {
  const firebase = useFirebaseWriter()
  const firebase9 = useFirebase9()
  const currentUser = useLoggedInUser()

  return (formOrder: FormOrder) => {
    const investorCommission = formOrder.account.clientType.includes("Investor/Shareholder")
      ? { priceType: "net-of-fees" as const }
      : formOrder.commission
    return addOrder(
      createOrderFromForm(
        {
          ...formOrder,
          commission: formOrder.commission ?? investorCommission,
        },
        initialFormOrder
      ),
      firebase,
      {
        updateExistingCRMInterestTerms: formOrder.updateExistingCRMInterestTerms,
      }
    ).then((orderDoc) => {
      trackUserEventInFirestore(firebase9, currentUser.user, "order-submitted")
      trackAccountActivationFirebase9(
        firebase9,
        currentUser.user.account,
        "submittedAnIndication"
      ).catch(handleConsoleError)
      const order = orderDoc.match(
        (doc) => doc.data(),
        () => undefined
      )
      afterSave?.(order)
      return orderDoc
    })
  }
}
