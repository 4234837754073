import { Skeleton } from "@stories/components/Antd"
import { LogoSize } from "common/model/Company"

const logoSizeToSkeletonSize: Record<
  LogoSize,
  React.ComponentProps<typeof Skeleton.Avatar>["size"]
> = {
  xxs: "small",
  xs: "small",
  sm: "small",
  md: "default",
  lg: "large",
}

export const CompanyLogoAndNameSkeleton: React.FC<React.PropsWithChildren<{ size?: LogoSize }>> = ({
  size = "sm",
}) => (
  <div className="flex flex-row items-center space-x-2 py-1">
    <Skeleton.Avatar shape="square" size={logoSizeToSkeletonSize[size]} active />
    <Skeleton title={{ width: 72, className: "rounded" }} paragraph={false} active />
  </div>
)
