import { TextArea } from "@stories/components/Antd"
import { FormLabel } from "@stories/components/Inputs/FormLabel/FormLabel"
import { RadioSelect } from "@stories/components/Inputs/RadioSelect/RadioSelect"
import SectionLabel from "@stories/components/Inputs/SectionLabel/SectionLabel"
import { OrderShareClass } from "common/model/Order/Order"
import { PriceObservationType } from "common/model/data-product/pricing/PriceObservation"
import { PriceObservationErrors } from "src/pages/ClosedTrades/ClosedTradeForm/contextHooks/useClosedTradeFormSaving"

type ClosedTradeShareClassSectionData = Pick<
  PriceObservationType,
  "shareClass" | "shareClassSeries"
>
export const ClosedTradeShareClassSection = ({
  value,
  onChange,
  title,
  titles,
  formErrors,
}: {
  value: ClosedTradeShareClassSectionData
  onChange: (value: ClosedTradeShareClassSectionData) => void
  title: string
  titles: {
    shareClassSeries: string
  }
  formErrors: PriceObservationErrors
}) => (
  <div className="flex flex-col gap-4">
    <SectionLabel>{title}</SectionLabel>
    <RadioSelect<ClosedTradeShareClassSectionData["shareClass"]>
      hasError={formErrors.has("shareClass")}
      label="Please select the correct share class"
      onChange={(shareClass) => onChange({ ...value, shareClass })}
      value={value.shareClass ?? undefined}
      options={[
        { value: "common", label: "Common" },
        { value: "preferred", label: "Preferred" },
        { value: "mixed", label: "Mixed" },
      ]}
    />
    {value.shareClass === "preferred" ? (
      <div className="mb-2">
        <FormLabel>{titles.shareClassSeries}</FormLabel>
        <TextArea
          rows={1}
          placeholder={titles.shareClassSeries}
          className="w-full"
          onChange={(e) => onChange({ ...value, shareClassSeries: e.target.value })}
          value={value.shareClassSeries ?? undefined}
        />
      </div>
    ) : null}
  </div>
)
