import Mention from "@tiptap/extension-mention"
import Placeholder from "@tiptap/extension-placeholder"
import { Editor, JSONContent, useEditor } from "@tiptap/react"
import { useState } from "react"
import { ITipTapEditor } from "./ITipTapEditor"
import SuggestionConfig, { SavedNoteTag } from "./SuggestionConfig"
import BulletList from "@tiptap/extension-bullet-list"
import Document from "@tiptap/extension-document"
import Link from "@tiptap/extension-link"
import TextAlign from "@tiptap/extension-text-align"
import StarterKit from "@tiptap/starter-kit"
import { useKeyboardShortcut } from "src/utils/hooks/useKeyboardShortCut"

export const useTipTapEditor = ({
  handleSaveNote,
  onTextContentChanged,
  onContentChange,
  initialContent,
  textClassName,
  placeholder,
  isMentionsDisabled,
}: {
  handleSaveNote: (editor: ITipTapEditor) => void
  onTextContentChanged?: (content: string) => void
  onContentChange?: (editor: ITipTapEditor) => void
  initialContent: JSONContent
  textClassName?: string
  placeholder?: string
  isMentionsDisabled?: boolean
}): Editor | null => {
  const [content, setContent] = useState<JSONContent>(initialContent)

  const editorConfig: Editor | null = useEditor({
    editorProps: {
      attributes: {
        class: `prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none ${
          textClassName || ""
        }`,
      },
    },
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false,
        },
      }),
      BulletList.configure({
        HTMLAttributes: {
          class: "list-disc",
        },
      }),
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      Document,
      Link.configure({
        openOnClick: true,
        validate: (href) => /^https?:\/\//.test(href),
        HTMLAttributes: {
          class: "link",
        },
      }),
      Placeholder.configure({
        placeholder: placeholder ?? "Add a note...",
      }),
      ...(isMentionsDisabled
        ? []
        : [
            Mention.configure({
              suggestion: SuggestionConfig([]),
              HTMLAttributes: {
                class: "tiptap-mention",
              },
              renderLabel: ({ options, node }) => {
                // eslint-disable-next-line rulesdir/no-assert
                const tagData = node.attrs.label as SavedNoteTag
                return `${options.suggestion.char || ""}${(tagData.name ?? tagData.id) || ""}`
              },
            }),
          ]),
    ],
    content,
    onUpdate({ editor }) {
      onTextContentChanged?.(editor.getText())
      onContentChange?.(editor)
      setContent(editor.getJSON())
    },
  })

  useKeyboardShortcut("Enter", () => {
    if (!editorConfig) {
      return
    }
    const text = editorConfig.getText()
    const shouldHaltSubmit = (text || "").replaceAll(/\s/g, "") === ""
    if (!shouldHaltSubmit) {
      handleSaveNote(editorConfig)
    }
  })

  return editorConfig
}
