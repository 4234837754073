import { Button } from "@stories/components/Button/Button"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { ClockAlertIcon } from "@stories/icons/ClockAlertIcon"
import { DownloadIcon } from "@stories/icons/DownloadIcon"
import {
  AccountTradingRegistration,
  MinimumAccountTradingRegistration,
} from "common/model/Account/AccountTradingRegistration"
import { Loading, isLoaded } from "common/utils/Loading"
import { americanDate, maxDate } from "common/utils/dateUtils"
import { assertUnreachable, identity } from "common/utils/fp/Function"
import { Link, useNavigate } from "react-router-dom"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"

export const getTradingRegistrationResponseTimeFrame = (
  tradingRegistrationType: MinimumAccountTradingRegistration["type"]
) => {
  switch (tradingRegistrationType) {
    case "broker":
      return "1 week" as const
    case "fund":
      return "1 week" as const
    case "individual-investor":
      return "less than 1 business day" as const
    default:
      return assertUnreachable(tradingRegistrationType)
  }
}

const getMostRecentActivityDate = (
  tradingRegistration: Partial<AccountTradingRegistration>
): false | Date =>
  maxDate(
    [
      tradingRegistration.status?.signedAgreementDate,
      tradingRegistration.status?.offlineAgreementRequestedDate,
      tradingRegistration.status?.submittedIdentificationDate,
    ].flatMap((d) => d || [])
  ).match(identity, () => false as const)

export const CompletedAgreementPage = ({
  tradingRegistration,
  onClose,
}: {
  tradingRegistration: Loading<Partial<AccountTradingRegistration>>
  onClose?: () => void
}) => {
  const navigate = useNavigate()
  const user = useCurrentUser()
  if (!isLoaded(tradingRegistration)) return null
  const mostRecentActivity = getMostRecentActivityDate(tradingRegistration)
  const handleExplorePlatform = () => {
    onClose?.()
    navigate("/")
  }
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2 mb-4">
          {tradingRegistration.status?.offlineAgreementRequested ? (
            <>
              <div className="flex gap-2 items-center">
                <ClockAlertIcon color={Color.PrimarySecondary} size="large" />
                <Typography
                  text="Trading Registration pending offline signature"
                  weight={Weight.Semibold}
                  color={Color.PrimarySecondary}
                />
              </div>
              <Typography
                size={Size.Small}
                text="Download a copy of the agreement to send to your team."
              />
              <Link
                id="trading-registration-offline-agreement-link"
                to={`${process.env.PUBLIC_URL}/assets/tradingRegistration/SubscriberAgreement.docx`}
                target="_blank"
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                <Button
                  isFullWidth
                  variant="hollow-link"
                  leftIcon={<DownloadIcon />}
                  label="Download the Subscriber Agreement (docx)"
                />
              </Link>
              <Link
                id="trading-registration-offline-agreement-link"
                to={`${process.env.PUBLIC_URL}/assets/tradingRegistration/SubscriberAgreement.pdf`}
                target="_blank"
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                <Button
                  isFullWidth
                  variant="hollow-link"
                  leftIcon={<DownloadIcon />}
                  label="Download the Subscriber Agreement (pdf)"
                />
              </Link>
              <Typography
                size={Size.Small}
                weight={Weight.Semibold}
                text="After reviewing with your team, send any questions, comments or redlines to platform@caplight.com."
              />
            </>
          ) : (
            <>
              <div className="flex gap-2 items-center">
                <ClockAlertIcon color={Color.PrimarySecondary} size="large" />
                <Typography
                  text={`Trading Registration submitted${
                    mostRecentActivity ? ` on ${americanDate(mostRecentActivity)}.` : "."
                  }`}
                  weight={Weight.Semibold}
                  color={Color.PrimarySecondary}
                />
              </div>
            </>
          )}
          {isLoaded(tradingRegistration) && tradingRegistration.type === "broker" ? (
            <>
              <Typography
                size={Size.Small}
                text="Caplight will be in touch with your Broker Dealer."
              />
              <Typography
                size={Size.Small}
                text={`You will be notified over email when your trading registration is approved. This normally takes ${getTradingRegistrationResponseTimeFrame(
                  tradingRegistration.type
                )}.`}
              />
            </>
          ) : isLoaded(tradingRegistration) && tradingRegistration.type === "fund" ? (
            <Typography
              size={Size.Small}
              text="You will be notified over email when your trading registration is approved."
            />
          ) : isLoaded(tradingRegistration) &&
            tradingRegistration.type === "individual-investor" ? (
            <Typography
              size={Size.Small}
              text={`You will be notified over email when your trading registration is approved. This normally takes ${getTradingRegistrationResponseTimeFrame(
                tradingRegistration.type
              )}.`}
            />
          ) : null}
          <Typography
            size={Size.Small}
            text="Please reach out to platform@caplight.com with any questions."
          />
        </div>
      </div>
      {isLoaded(user) ? (
        <Button label="Explore the Caplight Platform" isFullWidth onClick={handleExplorePlatform} />
      ) : null}
    </div>
  )
}
