import NativeSelect from "@stories/components/Inputs/NativeSelect/NativeSelect"
import Typography from "@stories/components/Typography/Typography"
import { accessControlTiers } from "common/model/AccessControl/AccessControlTier"
import { Account } from "common/model/Account"
import { useAdminUpdateAccountTier } from "src/queries/admin/useAdminUpdateAccountTier"

export const AdminAccountDrawerAccessControlSettings = ({ account }: { account: Account }) => {
  const onChangeAccountTier = useAdminUpdateAccountTier(account)

  return (
    <div className="p-4 flex space-x-2 items-center">
      <Typography text="Account Tier:" />
      <NativeSelect
        options={accessControlTiers.map((value) => ({ label: value, value }))}
        value={account.accessControlTier}
        onChange={(accessControlTier) =>
          accessControlTier ? onChangeAccountTier(accessControlTier) : null
        }
        placeholder="undefined"
      />
    </div>
  )
}
