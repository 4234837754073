/* eslint-disable max-classes-per-file */
import _ from "lodash"
import * as dateFns from "date-fns"
import { Seq } from "immutable"
import { head, unique } from "../../../utils/data/Array/ArrayUtils"
import { Boxed, unboxed } from "../../../utils/fp/Boxed"
import { Just, Maybe, Nothing, nullableToMaybe } from "../../../containers/Maybe"
import { memoize } from "../../../utils/memo"
import { Company } from "../../Company"
import { mergeCompanyOrders, viewOrderIdFields } from "../Order"
import { countStructureLayers, isOrderConnectable, isOrderDirectToClient } from "./Internal"
import { OrderTermUpdate } from "./Wrapped/OrderUpdate"
import { OrderStatus } from "./Wrapped/Status"
import * as S from "./UpdateLog"
import * as I from "./Internal"
import { Nonempty, nonemptyHead, unsafeAssertNonempty } from "../../../utils/data/Array/Nonempty"
import { Interval } from "../../../utils/data/Interval"
import {
  OrderStatusUpdate,
  isLiveOrderStatus,
  isTerminalOrderStatus,
  orderStatusHistory,
} from "../Types/Status"
import { LIVE_UNTIL_DEFAULT_DAYS } from "../OrderConstants"
import { dateOrder, maxBy } from "../../../utils/fp/Ord"
import { Account } from "../../Account"
import { annotate } from "../../../utils/Coerce"
import { Product } from "../../../containers/Tuple/Product"
import * as Wrapped from "../../Company/Wrapped"
import { OrderedList } from "../../../containers/List/OrderedList"
import { arrayField, ReifiedTraversal } from "../../../utils/fp/optics/Traversal"
import { field } from "../../../utils/fp/optics/Lens"
import { isDefined, isNotNull } from "../../../utils/TypeUtils"
import { formatStructureDisplayName } from "../../data-product/pricing/formatStructureDisplayName"
import { RawOrderPrice } from "./Wrapped/RawPrice"
import { isHiddenOutlierResult, isOutlier } from "../../data-product/DataPoint"
import { User, UserAccessFields } from "../../User"
import { OrderStructure } from "../Types/Structure"
import { canShowOnPlatform } from "../../data-product/DataPoint/VisibilityFields"
import { SourceAttribution } from "../../data-product/DataPoint/SourceAttribution"
import { formatSPVTerms } from "../Types/Terms"
import { AccountClientType } from "../../Account/AccountClientType"
import {
  TermsAtomicHistorySnapshot,
  TermsAtomicHistorySnapshotNonNullTerms,
  appendLivenessInterval,
  augmentOrderTermsWithUpdateDates,
  comprehensiveHistoryOfOrderTerms,
} from "./Terms"
import { partitionTermSnapshot, termSnapshots } from "./V2/Conversion"
import { IndicationChartPointDisplay } from "./Display/IndicationChartPointDisplay"
import { OrderSource } from "../OrderSource"
import { Loading } from "../../../utils/Loading"
import { canShowWrappedOrderToAccount } from "../../Account/canShowWrappedOrderToAccount"

export abstract class Order extends Boxed<{
  readonly order: S.OrderWithData
  readonly company: Maybe<Wrapped.Company>
}> {
  abstract withCompanyData(company: Company): this

  toInternal() {
    return S.orderToInternal(this.unboxed.order)
  }

  fromAccount(account: Pick<Account, "id">) {
    return this.unboxed.order.base.source.account.id === account.id
  }

  privateBrokerNetworkSource(
    accounts: Pick<Account, "id">[]
  ): (OrderSource & { sourceType: "user-form" }) | null {
    return this.unboxed.order.base.source.sourceType === "user-form" &&
      accounts.some((a) => this.fromAccount(a))
      ? this.unboxed.order.base.source
      : null
  }

  sharedWithAccount(account: Pick<Account, "id">) {
    return !!this.unboxed.order.base.sharedAccountIds?.includes(account.id)
  }

  brokeredBy() {
    return this.unboxed.order.base.brokeredBy
  }

  crmContactId(account: Pick<Account, "id">) {
    if (this.fromAccount(account)) {
      return this.unboxed.order.base.crmContactId
    }

    return null
  }

  isBrokeredByUser(user: Pick<User, "id">) {
    return this.brokeredBy()?.user?.id === user.id
  }

  isBrokeredByAccount(account: Pick<Account, "id">) {
    return this.brokeredBy()?.account?.id === account.id
  }

  isFromTopBrokerAccount(topBrokerAccounts: Pick<Account, "id">[]) {
    return (
      !this.isPrivate &&
      topBrokerAccounts.some((a) => this.fromAccount(a) || this.isBrokeredByAccount(a))
    )
  }

  id() {
    return this.unboxed.order.base.id
  }

  idFields() {
    return viewOrderIdFields(this.unboxed.order.base)
  }

  status() {
    return new OrderStatus(this.unboxed.order.base)
  }

  direction() {
    return this.unboxed.order.base.direction
  }

  isDarkpool() {
    return (
      !!this.unboxed.order.base.darkpool || this.unboxed.order.base.orderCollection === "darkpool"
    )
  }

  get isPrivate() {
    return this.unboxed.order.base.orderCollection === "private"
  }

  get isTentativeInterest() {
    return this.unboxed.order.base.orderCollection === "tentativeInterest"
  }

  company() {
    return this.unboxed.order.base.company
  }

  sourceClientType(): AccountClientType[] {
    const clientType = this.unboxed.order.base.source.account.clientType ?? ["Investor/Shareholder"]
    if (clientType.length === 0) {
      return ["Investor/Shareholder"]
    }
    return clientType
  }

  sourceAttribution_ADMIN_ONLY(): Maybe<SourceAttribution> {
    const { attribution } = this.unboxed.order.base
    return attribution?.tag === "indirect" ? Just(attribution.reportedToSourceBy) : Nothing
  }

  sourceAttribution(user: UserAccessFields): Maybe<SourceAttribution> {
    // an extra line of defense that should never actually come up
    // We might just want to crash here?
    if (
      !this.fromAccount(user.account) ||
      !this.canShowOnPlatform(user) ||
      this.unboxed.order.base.defaultVisibility !== "private"
    ) {
      return Nothing
    }
    const { attribution } = this.unboxed.order.base
    if (attribution?.tag === "indirect") {
      return Just(attribution.reportedToSourceBy)
    }
    return Nothing
  }

  originationDate() {
    return this.unboxed.order.base.orderOriginationDate
  }

  /** Ordered in descending order by date */
  abstract allTermUpdates: () => Nonempty<OrderTermUpdate>

  allTermUpdatesWithStatusUpdatesIntercalated() {
    return termSnapshots(this.toInternal())
      .flatMap(partitionTermSnapshot)
      .map((s) => new OrderTermUpdate({ update: s, company: this.unboxed.company }))
  }

  marketPriceChartDataPoints = memoize(() => {
    const updates = OrderedList.fromArray(
      (x) => x.date(),
      dateOrder,
      this.allTermUpdatesWithStatusUpdatesIntercalated()
    )
    return updates.raw
      .map((update) =>
        update.impliedPricePerShare().matchStrict(
          (pps) =>
            ({
              x: update.date().setHours(0, 0, 0, 0),
              y: pps,
              indicationId: this.id(),
              indicationType:
                this.direction() === "buy"
                  ? this.isPrivate
                    ? ("Private Bid" as const)
                    : ("Bid" as const)
                  : this.isPrivate
                  ? ("Private Offer" as const)
                  : ("Offer" as const),
              impliedValuation: update.impliedValuation().toNullable(),
              text: this.structuresWithSPVTerms().join(", "),
            } satisfies IndicationChartPointDisplay),
          undefined
        )
      )
      .filter(isDefined)
  })

  allTermUpdatesWithOutlierMark() {
    const fullOrderResult = this.unboxed.order.base.outlierStatus?.outlierSummary?.global
    const oldModelFullOrderResult = this.unboxed.order.base.outlier

    const fullOrderResultOutlier = !!fullOrderResult && isHiddenOutlierResult(fullOrderResult)
    const oldModelFullOrderResultOutlier =
      !!oldModelFullOrderResult && isHiddenOutlierResult(oldModelFullOrderResult)

    const fullOrderOutlierDate =
      !!fullOrderResult && fullOrderResultOutlier && fullOrderResult.tag !== "unflagged"
        ? fullOrderResult.date
        : null
    const oldModelFullOrderOutlierDate =
      !!oldModelFullOrderResult &&
      oldModelFullOrderResultOutlier &&
      oldModelFullOrderResult.tag !== "unflagged"
        ? oldModelFullOrderResult.date
        : null

    // this seems to be an overkill, but adding it here just in case
    // a note: this function (isOutlier) considers an order an outlier iff ALL terms are outliers
    // here we need to go more granular
    const anotherOutlierCheck = isOutlier(this.unboxed.order.base)
    const isOutlierTermOrAll = (id: string) => {
      const termResult = this.unboxed.order.base.outlierStatus?.outlierSummary?.terms?.[id]
      const termResultOutlier = !!termResult && isHiddenOutlierResult(termResult)
      const termOutlierDate =
        !!termResult && termResultOutlier && termResult.tag !== "unflagged" ? termResult.date : null
      const dates = [fullOrderOutlierDate, oldModelFullOrderOutlierDate, termOutlierDate].filter(
        isNotNull
      )
      return {
        isOutlier:
          anotherOutlierCheck ||
          termResultOutlier ||
          fullOrderResultOutlier ||
          oldModelFullOrderResultOutlier,
        outlierDate: dates.length === 0 ? null : _.min(dates) ?? null,
      }
    }

    return unsafeAssertNonempty(
      _.orderBy(
        this.unboxed.order.orderUpdates.map((u) => ({
          update: new OrderTermUpdate({ update: u, company: this.unboxed.company }),
          ...isOutlierTermOrAll(u.quantityTerms.id),
        })),
        (u) => u.update.date(),
        "desc"
      )
    )
  }

  /**
   * This function returns a detailed set of terms by structure and how long the terms were live for.
   * One order can have several terms at a time, typically a term for an SPV and a term for a direct.
   * Every time user updates terms or confirms an order as live or cancels an order,
   * we need to track it at each structure level.
   * It is possible for users to even remove structures or add them.
   */
  termsAtThePointOfAllOrderUpdates(livenessAssumption: number) {
    const sortedTermSnapshotsByStructure: [string, TermsAtomicHistorySnapshot[]][] =
      comprehensiveHistoryOfOrderTerms(this) // each subarray is the history of terms for a given structure

    const statusUpdates: Omit<OrderStatusUpdate, "statusUpdateType">[] = _.orderBy(
      _.uniqWith(orderStatusHistory(this.unboxed.order.base), (x, y) => _.isEqual(x, y)),
      (x) => x.asOf
    ).filter((statusUpd) => statusUpd.tag !== "unknown") // unknown statuses are actually counterproductive here
    const sortedTermSnapshotsByStructureWithStatusUpdates = augmentOrderTermsWithUpdateDates(
      sortedTermSnapshotsByStructure,
      statusUpdates
    )
    const termsByStructureWithLiveInterval: [string, TermsAtomicHistorySnapshotNonNullTerms[]][] =
      appendLivenessInterval(sortedTermSnapshotsByStructureWithStatusUpdates, livenessAssumption)

    return termsByStructureWithLiveInterval
  }

  orderedUpdates = memoize(() =>
    OrderedList.fromArray((o) => o.date(), dateOrder, this.allTermUpdates())
  )

  orderedChangedTermUpdates = memoize(() => {
    const reduceToChangedTerms = (acc: OrderTermUpdate[], update: OrderTermUpdate) =>
      head(acc).match(
        (prev) => (update.isEqualPriceTo(prev) ? acc : [update, ...acc]),
        () => [update]
      )

    const reducedUpdates = this.orderedUpdates()
      .toArray()
      .reduce<OrderTermUpdate[]>(reduceToChangedTerms, [])

    return OrderedList.fromArray((o) => o.date(), dateOrder, reducedUpdates)
  })

  statusUpdates = memoize(() =>
    _.orderBy(orderStatusHistory(this.unboxed.order.base), (u) => u.asOf, "desc")
  )

  orderedStatusUpdates = memoize(() =>
    OrderedList.fromArray((o) => o.asOf, dateOrder, this.statusUpdates())
  )

  isConnectable() {
    return isOrderConnectable(this.unboxed.order.base)
  }

  latestUpdateDate() {
    return this.latestDefiniteStatusUpdate().match(
      (u) => maxBy(dateOrder)(u.asOf, this.latestTermUpdate().date()),
      () => this.latestTermUpdate().date()
    )
  }

  isOutdated() {
    return (
      this.status().isStale() &&
      dateFns.isBefore(
        dateFns.add(this.latestUpdateDate(), { days: LIVE_UNTIL_DEFAULT_DAYS }),
        new Date()
      )
    )
  }

  latestTermUpdate() {
    return nonemptyHead(this.allTermUpdates())
  }

  latestTermUpdateWithChanges() {
    return this.orderedChangedTermUpdates().last()
  }

  /** The most recent status update with a known status */
  latestDefiniteStatusUpdate() {
    return nullableToMaybe(this.statusUpdates().find((u) => u.tag !== "unknown"))
  }

  /** The most recent status update, including 'unknown' */
  latestPossibleStatusUpdate() {
    return nullableToMaybe(this.statusUpdates().find((u) => u.tag !== "unknown"))
  }

  latestUpdateWithJust<T>(f: (u: OrderTermUpdate) => Maybe<T>): Product<OrderTermUpdate, Maybe<T>> {
    return this.allTermUpdates()
      .reduce(
        (x, y) => x.or(f(y).map((v) => new Product(y, Just(v)))),
        annotate<Maybe<Product<OrderTermUpdate, Maybe<T>>>>(Nothing)
      )
      .withDefault(new Product(this.latestTermUpdate(), Nothing))
  }
  // quantity terms

  pricePerShare() {
    return !this.hasSimplePrice() ? Nothing : this.latestTermUpdate().pricePerShare()
  }

  impliedPricePerShare() {
    return !this.hasSimplePrice()
      ? Nothing
      : this.latestUpdateWithJust((u) => u.impliedPricePerShare()).right
  }

  targetValuation() {
    return !this.hasSimplePrice() ? Nothing : this.latestTermUpdate().targetValuation()
  }

  impliedValuation() {
    return !this.hasSimplePrice()
      ? Nothing
      : this.latestUpdateWithJust((u) => u.impliedValuation()).right
  }

  commission() {
    return nullableToMaybe(this.unboxed.order.base.commission)
  }

  shareCount() {
    return this.latestUpdateWithJust((u) => u.shareCount()).extract()
  }

  amountUSD() {
    return this.latestUpdateWithJust((u) => u.amountUSD()).extract()
  }

  /**
   * Price in whichever format was supplied by the uploader
   * valuation on the left, price per share on the right */
  rawPrice = memoize(
    () =>
      new RawOrderPrice(
        this.latestUpdateWithJust((u) => u.rawPrice())
          .extend(([u, p]) => [u.date(), p] as const)
          .map(([displayDate, p]) =>
            p.map((rawPrice) =>
              rawPrice.bimap(
                (v) => ({ valuation: v, displayDate }),
                (pps) => ({ pricePerShare: pps, displayDate })
              )
            )
          )
          .extract()
      )
  )

  /** list of structure terms as of the latest term update (i.e. may ignore historic structures that are no longer active) */
  currentStructures = memoize(() => {
    const knownStructures = unique(
      this.allTermUpdates()
        .filter((v) => v.date().getTime() === this.latestTermUpdate().date().getTime())
        .map((u) => u.structureTerms().tag)
    )
      .filter((t) => t !== "unknown")
      .map((s) => new OrderStructure(s))
    return knownStructures.length > 0 ? knownStructures : [new OrderStructure("unknown" as const)]
  })

  structureDisplay() {
    return this.currentStructures()
      .map((s) => s.displayName())
      .join(", ")
  }

  structuresWithSPVTerms = memoize(() => {
    const knownStructures = unique(
      this.allTermUpdates()
        .filter((v) => v.date().getTime() === this.latestTermUpdate().date().getTime())
        .map((u) =>
          u.structureTerms().tag === "spv"
            ? formatSPVTerms({
                carry: u.carry(),
                mgmt: u.managementFee(),
                structureLayersCount: this.structureLayersCount(),
              })
            : formatStructureDisplayName(u.structureTerms().tag)
        )
    ).filter((t) => t !== "unknown")
    return knownStructures.length > 0 ? knownStructures : ["Unknown" as const]
  })

  carry = memoize(() =>
    nullableToMaybe(
      this.allTermUpdates()
        .find((u) => u.carry().isJust())
        ?.carry()
        .toNullable()
    )
  )

  managementFee = memoize(() =>
    nullableToMaybe(
      this.allTermUpdates()
        .find((u) => u.managementFee().isJust())
        ?.managementFee()
        .toNullable()
    )
  )

  structureLayersCount = memoize(() =>
    nullableToMaybe(this.unboxed.order.base.layeredStructure).map(countStructureLayers)
  )

  private livenessState = memoize(() =>
    Seq.Indexed(this.allTermUpdates())
      .map((u) => ({
        date: u.date(),
        to: annotate<Date | null>(dateFns.addDays(u.date(), LIVE_UNTIL_DEFAULT_DAYS)),
        live: annotate<boolean | null>(true),
      }))
      .concat(
        this.statusUpdates().map((u) => ({
          date: u.asOf,
          to: null,
          live: isLiveOrderStatus(u) ? true : isTerminalOrderStatus(u) ? false : null,
        }))
      )
      .sort((l, r) => l.date.valueOf() - r.date.valueOf())
      .cacheResult()
  )

  private earliestLiveDate = memoize(() => this.livenessState().find((x) => !!x.live)?.date)

  private latestLiveDate = memoize(() => this.livenessState().findLast((x) => !!x.live)?.date)

  liveDuring(dateRange: Interval<Date>) {
    const paddedLowerBound = dateFns.subDays(dateRange.lowerBound, LIVE_UNTIL_DEFAULT_DAYS) // updates slightly before the liveDuring range can cause liveness during it
    if (
      (this.earliestLiveDate()?.valueOf() ?? 0) > dateRange.upperBound.valueOf() ||
      (this.latestLiveDate()?.valueOf() ?? Number.POSITIVE_INFINITY) < paddedLowerBound.valueOf()
    ) {
      return false
    }
    return !!this.livenessState().find((state) =>
      state
        ? state.date >= paddedLowerBound &&
          state.date <= dateRange.upperBound &&
          (state?.live ?? false)
        : false
    )
  }

  isTimeSensitive() {
    return nullableToMaybe(this.unboxed.order.base.timeSensitive)
  }

  shareClasses() {
    return this.unboxed.order.base.shareClasses
  }

  shareClassSeries() {
    return this.unboxed.order.base.shareClassSeries
  }

  hasSimplePrice() {
    return !this.currentStructures().some((s) => s.is("variable_prepaid_forward"))
  }

  isHighlighted() {
    return this.unboxed.order.base.isHighlighted ?? false
  }

  isDirectToClient() {
    return isOrderDirectToClient(this.unboxed.order.base)
  }

  canShowOnPlatform(user: UserAccessFields) {
    return canShowOnPlatform(S.orderToInternal(this.unboxed.order), user)
  }

  canShowToAccount(account: Loading<Account>): boolean {
    return canShowWrappedOrderToAccount(this, account)
  }
}

export const orderUpdates: ReifiedTraversal<
  Order,
  Order,
  S.AtomicOrderUpdate,
  S.AtomicOrderUpdate
> = unboxed<Order>()
  .composeTraversal(field("order")<Order["unboxed"]>())
  .composeTraversal(arrayField("orderUpdates")())

export class OwnOrder extends Order {
  private static wrap({ order, company }: { order: S.OrderWithData; company: Maybe<Company> }) {
    return new OwnOrder({
      order,
      company: company.map((c) => new Wrapped.Company(c)),
    })
  }

  override allTermUpdates: () => Nonempty<OrderTermUpdate> = memoize(() =>
    // orderBy and map are length-preserving, and the precondition on orderHasData checks that at least one non-outlier exists
    unsafeAssertNonempty(
      _.orderBy(
        this.unboxed.order.orderUpdates.map(
          (u) => new OrderTermUpdate({ update: u, company: this.unboxed.company })
        ),
        (u) => u.date(),
        "desc"
      )
    )
  )

  withCompanyData(company: Company) {
    // This is safe as long as OwnOrder has no subclasses
    // eslint-disable-next-line rulesdir/no-assert
    return OwnOrder.wrap({ order: this.unboxed.order, company: Just(company) }) as this
  }

  static wrapOwnOrder({ order, company }: { order: I.Order; company: Maybe<Company> }) {
    const transformed = S.orderFromInternal(order)
    return S.orderHasDataIncludingOutliers(transformed)
      ? Just(
          new OwnOrder({
            order: transformed,
            company: company.map((c) => new Wrapped.Company(c)),
          })
        )
      : Nothing
  }
}
export class PlatformOrder extends Order {
  private static wrap({ order, company }: { order: S.OrderWithData; company: Maybe<Company> }) {
    return new PlatformOrder({
      order,
      company: company.map((c) => new Wrapped.Company(c)),
    })
  }

  override allTermUpdates: () => Nonempty<OrderTermUpdate> = memoize(() => {
    const isOutlierTerm = (id: string) => {
      const termResult = this.unboxed.order.base.outlierStatus?.outlierSummary?.terms?.[id]
      if (termResult) {
        return isHiddenOutlierResult(termResult)
      } else {
        return false
      }
    }
    // orderBy and map are length-preserving, and the precondition on orderHasData checks that at least one non-outlier exists
    return unsafeAssertNonempty(
      _.orderBy(
        this.unboxed.order.orderUpdates
          .filter((u) => !isOutlierTerm(u.quantityTerms.id))
          .map((u) => new OrderTermUpdate({ update: u, company: this.unboxed.company })),
        (u) => u.date(),
        "desc"
      )
    )
  })

  withCompanyData(company: Company) {
    // This is safe as long as PlatformOrder has no subclasses
    // eslint-disable-next-line rulesdir/no-assert
    return PlatformOrder.wrap({ order: this.unboxed.order, company: Just(company) }) as this
  }

  static override wrapRaw({ order, company }: { order: I.Order; company: Maybe<Company> }) {
    const transformed = S.orderFromInternal(order)
    return S.orderHasData(transformed)
      ? Just(
          new PlatformOrder({
            order: transformed,
            company: company.map((c) => new Wrapped.Company(c)),
          })
        )
      : Nothing
  }

  concat(other: Order): Maybe<PlatformOrder> {
    return mergeCompanyOrders(
      S.orderToInternal(this.unboxed.order),
      S.orderToInternal(other.unboxed.order)
    )
      .map(S.orderFromInternal)
      .bind((o) => (S.orderHasData(o) ? Just(o) : Nothing))
      .map((o) =>
        PlatformOrder.wrap({ order: o, company: this.unboxed.company.map((c) => c.unboxed) })
      )
  }

  merge(other: Order) {
    return this.concat(other)
  }
}

export namespace Order {
  // eslint-disable-next-line prefer-destructuring
  export const wrapRaw: typeof PlatformOrder.wrapRaw = (args) => PlatformOrder.wrapRaw(args)
}
