import OrderHistoryItem from "./OrderHistoryItem"
import { Color } from "@stories/components/Typography/Typography"
import { Either } from "common/containers/Either"
import { Order } from "common/model/Order/Models/Wrapped"
import { formatPrice, formatValuation } from "common/utils/math/format"
import { UpArrowIcon } from "@stories/icons/UpArrowIcon"
import { DownArrowIcon } from "@stories/icons/DownArrowIcon"
import { nullableToMaybe } from "common/containers/Maybe"

type OrderHistoryPriceChangeProps = {
  order?: Order
  date: Date
  prevPrice: Either<number, number>
  newPrice: Either<number, number>
  isHorizontal?: boolean
}

export const displayPriceUpdate = (price: Either<number, number>) =>
  price.match(formatValuation, formatPrice)

const OrderHistoryPriceChange = ({
  order,
  date,
  prevPrice,
  newPrice,
  isHorizontal,
}: OrderHistoryPriceChangeProps) => {
  if (newPrice === prevPrice) return null
  const isNewPriceHigher = newPrice.toUnion() > prevPrice.toUnion()
  const iconColor = nullableToMaybe(order)
    .map((o) => (isNewPriceHigher ? o.direction() === "buy" : o.direction() === "sell"))
    .map((isGoodForViewer) => (isGoodForViewer ? Color.Success : Color.Danger))
    .toNullable()
  return (
    <OrderHistoryItem
      labelString={`Price Change: ${displayPriceUpdate(prevPrice)} to ${displayPriceUpdate(
        newPrice
      )}`}
      iconComponent={isNewPriceHigher ? UpArrowIcon : DownArrowIcon}
      iconColor={iconColor}
      date={date}
      isHorizontal={isHorizontal}
    />
  )
}

export default OrderHistoryPriceChange
