import CompanyLogo from "@components/CompanyLogo"
import { DirectionSelect } from "@components/OrderFormComponents/DirectionSelect"
import { useFormOrderLabels } from "@components/OrderFormComponents/useFormOrderLabels"
import { SelectedCompany, viewCompanyIdFields } from "common/model/Company"
import { isLoaded } from "common/utils/Loading"
import { AnimatePresence, motion } from "framer-motion"
import { VisibilitySelect } from "@components/OrderFormComponents/VisibilitySelect"
import MarketPill from "@stories/components/Badges/MarketPill/MarketPill"
import { useOrderForm } from "./OrderFormContext"
import { useOrderFormStatus } from "./contextHooks/useFormStatus"
import { FormLabel } from "@stories/components/Inputs/FormLabel/FormLabel"
import CompanyInput from "@stories/components/Inputs/CompanyInput/CompanyInput"
import { AccessBoundary } from "@components/auth/DataAccess"

export const OrderFormInitialInformation = () => {
  const handleCompanySelect = (company: SelectedCompany | undefined) => {
    handleChange({ company: company ? viewCompanyIdFields(company) : undefined })
  }

  const { setFormOrder: handleChange, formOrder } = useOrderForm()
  const { selectFormStatuses } = useOrderFormStatus()
  const { reviewStatus } = selectFormStatuses()
  const { directionSectionLabels } = useFormOrderLabels(formOrder)
  return (
    <>
      <AnimatePresence mode="wait">
        <motion.div
          key={reviewStatus}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 10, opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-8 text-lg my-4 w-full">
            <div>
              <div className="mb-2">
                <FormLabel>Company</FormLabel>
              </div>
              <CompanyInput
                value={formOrder.company ?? null}
                onChange={handleCompanySelect}
                logoSize="sm"
              />
            </div>
            {formOrder.direction ? (
              <div className="flex flex-col justify-center">
                <FormLabel>{directionSectionLabels.title}</FormLabel>
                <MarketPill variant={formOrder.direction === "buy" ? "bid" : "offer"} />
              </div>
            ) : (
              <div>
                <DirectionSelect
                  label={directionSectionLabels.title}
                  options={[
                    { value: "buy", label: "Buyer" },
                    { value: "sell", label: "Seller" },
                  ]}
                  value={formOrder?.direction}
                  onChange={(direction) => handleChange({ direction })}
                />
              </div>
            )}
            <div>
              <VisibilitySelect />
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    </>
  )
}

export const OrderFormInitialInformationStatic = () => {
  const { formOrder, company } = useOrderForm()
  const { directionSectionLabels } = useFormOrderLabels(formOrder)
  if (!isLoaded(company)) {
    return null
  }
  return (
    <>
      <div className="grid grid-cols-3 gap-8 text-lg m-3 w-full">
        <div className="flex items-center font-semibold text-primary-800">
          <CompanyLogo company={company?.airtableId} size="sm" className="pr-2" />
          {company?.name || "n/a"}
        </div>
        <div className="w-24 flex items-center">
          <FormLabel>{directionSectionLabels.title}</FormLabel>
          <MarketPill variant={formOrder.direction === "buy" ? "bid" : "offer"} />
        </div>
        <div>
          <VisibilitySelect />
        </div>
      </div>
    </>
  )
}
