import { wrapIcon } from "./IconWrapper"

export const UrgentPriorityIcon = wrapIcon(
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.66669" y="1.66666" width="16.6667" height="16.6667" rx="2" fill="#DA1F28" />
    <g clipPath="url(#clip0_1815_7734)">
      <path
        d="M6.64442 5.55554C6.57074 5.55554 6.50009 5.58481 6.448 5.6369C6.3959 5.68899 6.36664 5.75965 6.36664 5.83332V14.1667C6.36664 14.2403 6.3959 14.311 6.448 14.3631C6.50009 14.4152 6.57074 14.4444 6.64442 14.4444C6.71809 14.4444 6.78874 14.4152 6.84083 14.3631C6.89293 14.311 6.92219 14.2403 6.92219 14.1667V5.83332C6.92219 5.75965 6.89293 5.68899 6.84083 5.6369C6.78874 5.58481 6.71809 5.55554 6.64442 5.55554Z"
        fill="white"
      />
      <path
        d="M13.4722 6.0611C13.43 6.03672 13.3821 6.02388 13.3333 6.02388C13.2846 6.02388 13.2367 6.03672 13.1944 6.0611C12.6559 6.28746 12.0756 6.39728 11.4917 6.38332C10.9885 6.34651 10.4986 6.20557 10.0528 5.96943C9.62405 5.7378 9.15221 5.59691 8.66667 5.55554C8.26596 5.55598 7.86935 5.63624 7.5 5.79165V10.5555C7.8619 10.3575 8.26803 10.2543 8.68056 10.2555C9.08907 10.2961 9.48554 10.4169 9.84722 10.6111C10.3639 10.8809 10.932 11.0381 11.5139 11.0722C12.1818 11.0861 12.8449 10.9563 13.4583 10.6917C13.5041 10.6686 13.5427 10.6333 13.5696 10.5896C13.5966 10.546 13.611 10.4957 13.6111 10.4444V6.29721C13.6103 6.24921 13.5971 6.20222 13.5728 6.16084C13.5485 6.11946 13.5138 6.0851 13.4722 6.0611Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1815_7734">
        <rect width="10" height="10" fill="white" transform="translate(5 5)" />
      </clipPath>
    </defs>
  </svg>
)
