import { debounce } from "lodash"
import { SearchInput } from "@components/inputs/SearchInput"
import { FilterFnRecord, Variant } from "common/model/newsfeed/NewsFeed"
import { useState } from "react"

const formattedSearch = (where: string, what: string) =>
  where
    .toLowerCase()
    .replace(/[^a-z0-9]/gi, "")
    .indexOf(what.toLowerCase().replace(/[^a-z0-9]/gi, "")) !== -1

export const companyFilter = (substring: string): FilterFnRecord => ({
  news: {
    function: (t) => formattedSearch(t.company?.name ?? "", substring),
    asStrings: { field: "company", value: substring },
  },
  funding_rounds: {
    function: (f) => formattedSearch(f.name ?? "", substring),
    asStrings: { field: "company", value: substring },
  },
})

const debouncedOnEnable = <V extends Variant>() =>
  debounce(
    (debouncedValue: string, onEnable: (fn: FilterFnRecord[V] | undefined) => void, variant: V) => {
      if (debouncedValue) {
        onEnable(companyFilter(debouncedValue)[variant])
      } else {
        onEnable(undefined)
      }
    },
    200,
    { leading: false, trailing: true }
  )

export const CompanyFilter = <V extends Variant>({
  onEnable,
  variant,
  valueString,
}: {
  onEnable: (fn: FilterFnRecord[V] | undefined) => void
  variant: V
  valueString: string | null
}) => {
  const [currentValue, setCurrentValue] = useState(valueString ?? "")
  return (
    <div className="flex flex-row items-center max-w-60" id={`${variant}-filter-company`}>
      <SearchInput
        key="substringFilterNews"
        id={`${variant}-filter-company`}
        placeholder="Search by company"
        value={currentValue}
        onChange={(val) => {
          setCurrentValue(val)
          debouncedOnEnable<V>()(val, onEnable, variant)
        }}
      />
    </div>
  )
}
