import { Tooltip } from "@stories/components/Antd"
import { Color, Size, Typography, Weight } from "@stories/components/Typography/Typography"
import { CheckCircleIcon } from "@stories/icons/CheckCircleIcon"
import { CopyIcon } from "@stories/icons/CopyIcon"
import { AnimatePresence, motion } from "framer-motion"
import { useState } from "react"

const CopyText = ({
  text,
  size = Size.XSmall,
  weight,
  iconOnly,
  shouldMaskDataInDataDog,
}: {
  text: string
  size?: Size
  weight?: Weight
  iconOnly?: boolean
  shouldMaskDataInDataDog?: boolean
}) => {
  const [copiedText, setCopiedText] = useState(false)
  if (!text) return <span />
  return (
    <Tooltip
      title={copiedText ? "Copied to Clipboard" : "Click to Copy"}
      mouseEnterDelay={0}
      mouseLeaveDelay={0}
      destroyTooltipOnHide
    >
      <button
        type="button"
        onClick={() =>
          navigator.clipboard
            .writeText(text)
            .then(() => setCopiedText(true))
            .then(() => setTimeout(() => setCopiedText(false), 1000))
        }
        className="inline-flex items-center gap-1 hover:text-accent-500"
      >
        {!iconOnly ? (
          <Typography
            text={text}
            shouldWrap={false}
            size={size}
            weight={weight}
            shouldMaskDataInDataDog={shouldMaskDataInDataDog}
          />
        ) : null}
        <AnimatePresence mode="popLayout">
          {copiedText ? (
            <motion.div
              key="check"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <CheckCircleIcon color={Color.Success} />
            </motion.div>
          ) : (
            <motion.div
              key="copy"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <CopyIcon />
            </motion.div>
          )}
        </AnimatePresence>
      </button>
    </Tooltip>
  )
}

export default CopyText
