import { UserResourceAccessKey } from "../User"
import { AuctionStatusType } from "../Auction"
import { RFQStatusType } from "../RFQ/RFQ"
import { AuctionEmailType, PlatformPageEmailType, RFQEmailType } from "../EmailType"

export type CampaignStatusType = AuctionStatusType | RFQStatusType

export interface CampaignBatch {
  id: string
  startDate: Date
  endDate: Date
  name: string
  type: CampaignType["name"]
  // Date when campaign is distributed to participants
  sentAt?: Date
}

export type CampaignEmailTypes = {
  rfq: RFQEmailType
  auction: AuctionEmailType
  platform_page_invite: PlatformPageEmailType
}

export interface CampaignType {
  name: "rfq" | "auction" | "platform_page_invite"
  collectionName: "rfqs" | "auctions" | "platform_page_invites"
}

export const rfqCampaignType: CampaignType = { name: "rfq", collectionName: "rfqs" }
const auctionCampaignType: CampaignType = { name: "auction", collectionName: "auctions" }
const platformPageCampaignType: CampaignType = {
  name: "platform_page_invite",
  collectionName: "platform_page_invites",
}

export const CampaignTypes = {
  rfq: rfqCampaignType,
  auction: auctionCampaignType,
  platformPageInviteCampaign: platformPageCampaignType,
}

export interface CampaignRef {
  campaignType: CampaignType
  id: string
}

export const CampaignRefs: Record<CampaignType["name"], (id: string) => CampaignRef> = {
  rfq: (id: string): CampaignRef => ({
    campaignType: rfqCampaignType,
    id,
  }),
  auction: (id: string): CampaignRef => ({
    campaignType: auctionCampaignType,
    id,
  }),
  platform_page_invite: (id: string): CampaignRef => ({
    campaignType: platformPageCampaignType,
    id,
  }),
}

export const getUserResourceAccessFieldName = (
  campaignType: CampaignType
): UserResourceAccessKey => {
  const fieldNames: Record<CampaignType["name"], UserResourceAccessKey> = {
    auction: "auctionIds",
    rfq: "rfqIds",
    platform_page_invite: "platformPageInviteIds",
  }
  return fieldNames[campaignType.name]
}

export const getUserResourceAccessFieldNameByCampaignTypeName = (
  name: CampaignType["name"]
): UserResourceAccessKey => {
  const fieldNames: Record<CampaignType["name"], UserResourceAccessKey> = {
    auction: "auctionIds",
    rfq: "rfqIds",
    platform_page_invite: "platformPageInviteIds",
  }
  return fieldNames[name]
}
