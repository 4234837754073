import { firestoreConverter } from "@model/FirestoreConverter"
import { collections } from "common/firestore/Collections"
import { Account } from "common/model/Account"
import { TrustedBrokerConnection } from "common/model/TrustedBrokerConnection"
import { uniqueByRep } from "common/utils/data/Array/ArrayUtils"
import { Loading, isLoaded } from "common/utils/Loading"
import { useMultiQuerySnapshot } from "src/utils/hooks/queries/useMultiQuerySnapshot"

export const useTrustedBrokerConnectionQuery = ({
  account,
}: {
  account: Loading<Pick<Account, "id">>
}) =>
  useMultiQuerySnapshot<TrustedBrokerConnection, TrustedBrokerConnection>(
    isLoaded(account)
      ? [
          (db) =>
            db.db
              .collection(collections.trustedBrokerConnections)
              .where("leftAccount.account.id", "==", account.id)
              .withConverter(firestoreConverter<TrustedBrokerConnection>()),
          (db) =>
            db.db
              .collection(collections.trustedBrokerConnections)
              .where("rightAccount.account.id", "==", account.id)
              .withConverter(firestoreConverter<TrustedBrokerConnection>()),
        ]
      : [],
    (items) => uniqueByRep<TrustedBrokerConnection, string>((t) => t.id)(items),
    (items) => items.filter((t) => !t.isDeleted),
    [account]
  )
