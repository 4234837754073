import { Tooltip } from "../../Antd"
import Typography, { Color, Size, Weight } from "../../Typography/Typography"
import { HighlightedIcon } from "../../../icons/HighlightedIcon"
import { classNames } from "../../../../utils/classNames"
import { TooltipProps } from "antd/es/tooltip"

const HighlightedOrderPill = ({
  showText = false,
  placement,
}: {
  showText?: boolean
  placement?: TooltipProps["placement"]
}) => (
  <Tooltip
    title={
      <div className="p-1 flex flex-col space-y-1 max-w-xs">
        <Typography
          text="Highlighted Order"
          size={Size.Small}
          color={Color.White}
          weight={Weight.Bold}
        />
        <Typography
          text="Highlighted orders are reviewed by Caplight and determined likely to be actionable. This is based on Caplight's review of the order terms, contributing party, and known secondary market context (ie. transfer restrictions, pricing, etc.)"
          size={Size.Small}
          color={Color.White}
        />
      </div>
    }
    placement={placement}
  >
    <div
      className={classNames(
        showText ? "py-1 px-2" : "p-1",
        "border border-neutral-400 bg-neutral-200 rounded-md text-center whitespace-nowrap inline-block max-w-max"
      )}
    >
      <Typography
        color={Color.Primary}
        text={
          <span className="flex items-center justify-center space-x-1">
            <HighlightedIcon color={Color.Link} />
            {showText ? <span>Highlighted</span> : null}
          </span>
        }
        weight={Weight.Semibold}
        size={Size.Small}
      />
    </div>
  </Tooltip>
)

export default HighlightedOrderPill
