import _ from "lodash"
import { AccountIdFields } from "./Account"
import { UserIdFields } from "./User"

export const userEventTypes = [
  "document-submission",
  "active-session",
  "single-company-page-view",
  "all-companies-page-view",
  "trading-page-view",
  "deal-details-view",
  "market-insights-page-view",
  "portfolio-page-view",
  "orderbook-page-view",
  "my-orders-page-view",
  "closed-trade-history-page-view",
  "news-page-view",
  "funding-rounds-page-view",
  "order-details-page-view",
  "order-submitted",
  "order-renewed",
  "connect-request-submitted",
  "connect-request-received",
  "order-inquiry-submitted",
  "rfq-submitted",
  "chat-opened",
  "chat-message-sent",
  "orders-shared",
  "order-opportunity-inbox-page-view",
  "crm-deal-created",
  "crm-contact-created",
  "crm-contact-updated",
  "crm-contact-indication-of-interest-added",
  "crm-contact-indication-of-interest-updated",
  "crm-contact-holding-added",
  "crm-contact-holding-updated",
  "crm-deal-participant-added",
  "crm-deal-participant-status-updated",
  "crm-note-added",
  "crm-note-reply-added",
  "crm-note-updated",
  "crm-contacts-tab-change",
  "comparables-add",
  "comparables-tickbox",
  "comparables-chart-toggle",
  "employee-order-form-submitted",
  "employee-broker-intro-request-submitted",
] as const

export type UserEventType = (typeof userEventTypes)[number]

export type UserEvent = {
  id: string
  user: UserIdFields
  account: AccountIdFields
  eventDate: Date
  eventType: UserEventType
}

export const marketUserEvents: UserEventType[] = [
  "order-submitted",
  "order-renewed",
  "rfq-submitted",
  "connect-request-submitted",
  "connect-request-received",
  "order-inquiry-submitted",
  "orderbook-page-view",
  "my-orders-page-view",
  "order-details-page-view",
  "order-opportunity-inbox-page-view",
]

export const dataUserEvents: UserEventType[] = [
  "single-company-page-view",
  "all-companies-page-view",
  "market-insights-page-view",
  "news-page-view",
  "portfolio-page-view",
  "document-submission",
]

export const crmUserEvents: UserEventType[] = [
  "crm-deal-created",
  "crm-contact-created",
  "crm-contact-updated",
  "crm-contact-indication-of-interest-added",
  "crm-contact-indication-of-interest-updated",
  "crm-contact-holding-added",
  "crm-contact-holding-updated",
  "crm-deal-participant-added",
  "crm-deal-participant-status-updated",
  "crm-note-added",
  "crm-note-updated",
  "crm-contacts-tab-change",
]

export type UserEventCounts = {
  [event in UserEventType]?: number
}

export const otherUserEvents: UserEventType[] = userEventTypes.filter(
  (e) => !marketUserEvents.includes(e) && !dataUserEvents.includes(e)
)
