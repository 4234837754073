import { Color } from "@stories/components/Typography/Typography"
import { PlusIcon } from "@stories/icons/PlusIcon"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import * as React from "react"
import { FC } from "react"
import { Button } from "../../../stories/components/Button/Button"
import { CompanyFilterSearchProp } from "./ContactSelectSearch/shared"
import { SelectContactModal } from "./SelectContactModal"

// TODO - CRM Contacts Provider nested at CRM Root component

export interface SelectContactButtonProps {
  onContactCreated?: (contact: DealCRMContact) => Promise<void>
  onContactSelected: (contact: DealCRMContact) => Promise<void>
  hideFirms?: boolean
  initialCompanyFilter?: CompanyFilterSearchProp
  sourceContactTag: DealCRMContact["tag"] | "brokerage" | null
  sourceContact?: DealCRMContact
  contactFilter?: (contact: DealCRMContact) => boolean
}

export const SelectContactButton: FC<SelectContactButtonProps> = ({
  onContactCreated,
  onContactSelected,
  hideFirms,
  initialCompanyFilter,
  sourceContactTag,
  sourceContact,
  contactFilter,
}) => {
  const [modalOpen, setModalOpen] = React.useState(false)

  const handleContactSelected = (contact: DealCRMContact) =>
    onContactSelected(contact).then(() => setModalOpen(false))

  const handleContactCreated = async (contact: DealCRMContact) => {
    if (onContactCreated) {
      await onContactCreated?.(contact)
    } else {
      await onContactSelected(contact)
    }
    setModalOpen(false)
  }

  const handleOpenModalClick = (clickEvent: React.MouseEvent<Element, MouseEvent>) => {
    clickEvent.stopPropagation()
    setModalOpen(true)
  }

  if (!modalOpen)
    return (
      <Button
        onClick={handleOpenModalClick}
        label="Add Contact"
        leftIcon={<PlusIcon color={Color.Primary} />}
        variant="hollow-link"
      />
    )

  return (
    <>
      <SelectContactModal
        sourceContactTag={sourceContactTag}
        onContactCreated={handleContactCreated}
        onContactSelected={handleContactSelected}
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        hideFirms={hideFirms}
        initialCompanyFilter={initialCompanyFilter}
        sourceContact={sourceContact}
        contactFilter={contactFilter}
      />
      <Button isDisabled label="Add Contact" leftIcon={<PlusIcon />} variant="hollow" />
    </>
  )
}
