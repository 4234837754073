import { Checkbox } from "@components/inputs/checkbox/Checkbox"
import WatchlistIcon from "@components/Watchlist/WatchlistIcon"
import { FilterFnRecord, Variant } from "common/model/newsfeed/NewsFeed"
import { useWatchlistPostgresIds } from "./FilterFunctions"
import Typography, { Color, Size } from "@stories/components/Typography/Typography"
import { Tooltip } from "@stories/components/Antd"
import { NavLink } from "react-router-dom"
import { Routes } from "src/Routes/Routes"
import { defaultIfLoading } from "common/utils/Loading"

export const onlyWatchlist = (watchlistPostgresIds: string): FilterFnRecord => ({
  news: {
    function: (t) => watchlistPostgresIds.includes(t.company?.postgresCompanyId ?? ""),
    asStrings: { field: "onlyWatchlist", value: watchlistPostgresIds },
  },
  funding_rounds: {
    function: (f) => watchlistPostgresIds.includes(f.postgresCompanyId ?? ""),
    asStrings: { field: "onlyWatchlist", value: watchlistPostgresIds },
  },
})

export const OnlyWatchlist = <V extends Variant>({
  onEnable,
  variant,
  valueString,
}: {
  onEnable: (fn: FilterFnRecord[V] | undefined) => void
  variant: V
  valueString: string | null
}) => {
  const loadingWatchlistIds = useWatchlistPostgresIds()
  const watchlistIds = defaultIfLoading(loadingWatchlistIds, [])
  const onlyWatchlistDisabled = watchlistIds.length === 0
  return (
    <Tooltip
      title={
        onlyWatchlistDisabled ? (
          <>
            No companies in your watchlist. <NavLink to={Routes.watchlistPage}>Set up here</NavLink>
          </>
        ) : undefined
      }
    >
      <div className="flex flex-row items-center text-xs">
        <Checkbox
          id={`${variant}-filter-only-watchlist`}
          onChange={(newVal) => {
            onEnable(
              newVal.target.checked ? onlyWatchlist(watchlistIds.join(","))[variant] : undefined
            )
          }}
          checked={!!valueString}
          disabled={onlyWatchlistDisabled}
        />
        <Typography
          color={onlyWatchlistDisabled ? Color.Disabled : Color.Primary}
          text={<WatchlistIcon className="mx-1" />}
        />
        <Typography
          color={onlyWatchlistDisabled ? Color.Disabled : Color.Primary}
          text="Only Watchlist companies"
          size={Size.XSmall}
        />
      </div>
    </Tooltip>
  )
}
