import { DealCRMDeal, DealCRMStage } from "common/model/DealCRM/Deal/DealCRMDeal"
import { FC } from "react"
import { useFirebaseWriter } from "../../../../firebase/Context"
import { updateDeal } from "../../../../firebase/crm"
import StageDisplay from "./StageDisplay"
import { sizeToTextSize } from "@model/components/lib/shared"

interface DealStageDisplayProps {
  editable: boolean
  deal: DealCRMDeal
  label?: boolean
  size?: "sm" | "xs"
  noBorder?: boolean
  className?: string
}

const DealStageDisplay: FC<DealStageDisplayProps> = ({
  editable,
  deal,
  label,
  size = "sm",
  noBorder = false,
  className,
}) => {
  const db = useFirebaseWriter()

  const handleChange = (stage: DealCRMStage) =>
    updateDeal({
      db,
      dealId: deal.id,
      update: { stage } satisfies Pick<DealCRMDeal, "stage">,
    })

  return (
    <>
      {label && <div className={`${sizeToTextSize[size]} font-medium`}>Stage</div>}
      <StageDisplay
        value={deal.stage}
        editable={editable}
        onChange={handleChange}
        size={size}
        noBorder={noBorder}
        className={className}
      />
    </>
  )
}

export default DealStageDisplay
