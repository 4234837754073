import { isEqual, maxBy, sortBy, tail as lodashTail } from "lodash"
import { annotate } from "../../Coerce"
import { tuple } from "../../fp/Function"
import { nullableToMaybe } from "../../../containers/Maybe"
import { Just, Maybe, Nothing } from "../../../containers/Maybe/Class"
import { isNonnullable, isNotNull, isNotUndefined, isString } from "../../json/validate"
import { UnsafeRec } from "../../RecordUtils"
import { justIfNonempty } from "./Nonempty"
import { groupBy } from "../../MapUtils"

export const deepCopy = <T extends object | number | string | Date | boolean | null>(obj: T) =>
  JSON.parse(JSON.stringify(obj)) as T
export const isObject = (value: unknown) =>
  typeof value === "object" && !Array.isArray(value) && value !== null

/** @deprecated */
export const isStringifiedObject = (value: unknown) => {
  if (isString(value)) {
    try {
      const parsed: object | unknown = JSON.parse(value)
      return typeof parsed === "object" && !Array.isArray(parsed) && parsed !== null
    } catch (e) {
      if (
        e instanceof Error &&
        (e.message.includes("not valid JSON") ||
          e.message.includes("in JSON") ||
          e.message.includes("after JSON") ||
          e.message.includes("JSON Parse") ||
          e.message.includes("after JSON") ||
          e.message.includes("JSON data"))
      ) {
        return false
      }
      throw e
    }
  }
  return false
}

/** @deprecated */
export const containsSameValues = <T>(a1: T[], a2: T[]) => isEqual(sortBy(a1), sortBy(a2))

export const repeat = <T>(item: T, count: number): T[] => Array<T>(count).fill(item)
export const repeatToString = (item: unknown, count: number, join: string = ""): string =>
  Array(count).fill(String(item)).join(join)
export const tabulateArray = <T>(f: (i: number) => T, length: number): T[] =>
  Array(length)
    .fill(null)
    .map((__, i) => f(i))

/** cumulative sum function. E.g. arr = [1, 2, 10] => accumulate(arr) = [1, 3, 13] */
export const accumulate = (arr: number[]) =>
  arr.reduce((a, b, i) => (i === 0 ? [b] : [...a, b + a[i - 1]]), [0])

export const splitAt = <T>(ts: T[], ix: number): [T[], T[]] => [ts.slice(0, ix), ts.slice(ix)]

interface WithAccumulatedValue {
  accumulatedValue: number
}

// Accumulate a numeric field of an array of objects of type T
// The accumulated result will be added to array elements as a field called 'accumulatedValue'
export const accumulateBy = <T>(
  arr: T[],
  iteratee: (t: T) => number
): (T & WithAccumulatedValue)[] => {
  const accumulatedValues = accumulate(arr.map((x) => iteratee(x)))

  return arr.map((item, i) => ({ ...item, accumulatedValue: accumulatedValues[i] }))
}
export interface WithIntIndex<T> {
  index: number
  value: T
}

export const head = <X>(xs: X[]) => justIfNonempty(xs).map((x) => x[0])
export const last = <X>(xs: X[]) => justIfNonempty(xs).map((x) => x[x.length - 1])

export const tail = <X>(xs: X[]): X[] => lodashTail(xs)
export const maybeSecond = <X>(xs: X[]) => head(tail(xs))

export const indexed = <T>(arr: T[]): WithIntIndex<T>[] =>
  arr.map((a, i) => ({ index: i, value: a }))

export const unique = <X>(xs: X[]): X[] => [...new Set(xs)]
/** O(n^2). Use `uniqueByRep` if possible */
export const uniqueBy = <X>(xs: X[], eq: (l: X, r: X) => boolean) => {
  const seen: X[] = []
  const result: X[] = []
  xs.forEach((x) => {
    if (seen.every((s) => !eq(x, s))) {
      result.push(x)
      seen.push(x)
    }
  })
  return result
}
export const uniqueByRep =
  <X, R>(f: (x: X) => R) =>
  (xs: X[]): X[] =>
    xs.reduceRight(
      ([seen, result], x) =>
        seen.has(f(x)) ? tuple(seen)(result) : tuple(seen.add(f(x)))([x].concat(result)),
      tuple(new Set<R>())(annotate<X[]>([]))
    )[1]

type ValueType = string | number | boolean

/** Deduplicates elements with string keys, while preserving those with `undefined`.  */
export const uniqueByIfPresent = <T>(ts: T[], by: (t: T) => ValueType | undefined): T[] => {
  const notPresent = ts
    .map((x, i) => [x, i] as const) // to preserve the original ordering
    .filter(([x, __]) => by(x) === undefined)
  const grouped = groupBy(
    ([x, __]) => by(x) ?? "no key",
    ts.map((x, i) => [x, i] as const).filter(([x, __]) => by(x) !== undefined)
  )
  return notPresent
    .concat(Array.from(grouped.values()).map((vals) => vals[0]))
    .slice()
    .sort(([__, l], [___, r]) => l - r)
    .map(([x, __]) => x)
}

export const removeByRep = <TRemove, TOriginal extends TRemove = TRemove, R = unknown>(
  originalArray: TOriginal[],
  toRemove: TRemove[],
  byRep: (t: TRemove) => R
) => {
  const toRemoveSet = new Set(toRemove.map(byRep))
  return originalArray.filter((t) => !toRemoveSet.has(byRep(t)))
}
export const includesByRep = <T, R>(originalArray: T[], isIncluded: T, byRep: (t: T) => R) =>
  originalArray.map(byRep).includes(byRep(isIncluded))

export const collapseRepeatedValues = <T>(
  ts: T[],
  eq: (l: T, r: T) => boolean = (l, r) => l === r
): T[] =>
  ts.reduce((l, r) => {
    if (l.length === 0 || !eq(l[l.length - 1], r)) {
      l.push(r) // mutating, but arrays don't concatenate well
    }
    return l
  }, annotate<T[]>([]))

// TODO delete this
/** @deprecated */
export const mapIntoObject = <S extends string | number | symbol, T>(
  array: S[],
  applyFunction?: (s: S) => T
): { [key in S]?: T } =>
  array.reduce(
    (acc, val) => ({
      ...acc,
      [val]: applyFunction ? applyFunction(val) : val,
    }),
    {}
  )

export const filterUndefined = <T>(arr: T[]): Exclude<T, undefined>[] =>
  arr.flatMap((x) => (isNotUndefined(x) ? [x] : []))
export const filterNull = <T>(arr: T[]): Exclude<T, null>[] =>
  arr.flatMap((x) => (isNotNull(x) ? [x] : []))
export const filterNullable = <T>(arr: T[]): Exclude<T, null | undefined>[] =>
  arr.flatMap((x) => (isNonnullable(x) ? [x] : []))
// Preferred wrapper of _.isEqual to enforce type-safety on params
export const arraysEqual = <T>(left: T[] | undefined, right: T[] | undefined): boolean =>
  isEqual(left || [], right || [])

export const arrayUnion = <T>(x: T[], y: T[]): T[] => unique(x.concat(y))

export const arrayPartition = <X, K extends string>(
  targets: Record<K, (x: X) => boolean>,
  xs: X[]
): Record<K, X[]> => {
  const acc: Record<K, X[]> = UnsafeRec.fromEntries(
    UnsafeRec.entries(targets).map(([k, v]) => [k, annotate<X[]>([])])
  )
  xs.forEach((x) => {
    const key = UnsafeRec.entries(targets).find(([k, v]) => v(x))?.[0]
    if (key !== undefined) {
      acc[key].push(x)
    }
  })
  return acc
}

export const arrayPartitionBoolean = <X>(xs: X[], f: (x: X) => boolean): [X[], X[]] => {
  const partitioned = arrayPartition({ true: (x) => f(x), false: (x) => !f(x) }, xs)
  return [partitioned.true, partitioned.false]
}

/** Lift a type predicate to arrays. */
export const isArrayOf = <X, Y extends X>(xs: X[], f: (x: X) => x is Y): xs is Y[] =>
  xs.every((x) => f(x))

export namespace Array_ {
  export const map = <T, T2>(a: T[], f: (t: T) => T2): T2[] => a.map((x) => f(x))
  export const mapCurried =
    <T, T2>(f: (t: T) => T2) =>
    (a: T[]): T2[] =>
      a.map((x) => f(x))

  export const pure = <X>(x: X): X[] => [x]

  /** n.b. this is a product, not zipping */
  export const lift2 = <X, Y, Z>(f: (x: X, y: Y) => Z, l: X[], r: Y[]): Z[] =>
    l.flatMap((x) => r.map((y) => f(x, y)))

  export const flatMapCurried =
    <T, T2>(f: (t: T) => T2[]) =>
    (a: T[]): T2[] =>
      a.flatMap((x) => f(x))
  export const concat = <X, Y>(l: X[], r: Y[]): (X | Y)[] => annotate<(X | Y)[]>(l).concat(r) // the default array.concat has poor type inference
  /** Adds extra elements `padWith` to given array `arr` up to given `length` */
  export const pad = <T>(length: number, padWith: T, arr: T[]): T[] =>
    tabulateArray((i) => arr?.[i] ?? padWith, Math.max(length, arr.length))
  /** Adds extra elements to given array `arr` up to given `length` using function `padWithIndex` to create elements  */
  export const padIndexed = <T>(length: number, padWithIndex: (i: number) => T, arr: T[]): T[] =>
    tabulateArray((i) => arr?.[i] ?? padWithIndex(i), Math.max(length, arr.length))
  export const find = <X>(x: X[], f: (x: X) => boolean): Maybe<X> => nullableToMaybe(x.find(f))

  export const upsertBy = <T>(ts: T[], t: T, predicate: (l: T, r: T) => boolean) =>
    ts.some((x) => predicate(x, t)) ? ts.map((x) => (predicate(x, t) ? t : x)) : ts.concat([t])
}

interface Foldable<T> {
  reduce: <S>(f: (prev: S, next: T) => S, initial: S) => S
}

/** As reduce, but accumulating intermediate results */
export const scanL = <S, T>(f: (s: S, t: T) => S, ts: Foldable<T>, initial: S): S[] =>
  ts.reduce((prev, cur) => [...prev, f(prev[prev.length - 1], cur)], [initial])

/** As reduceRight, but accumulating intermediate results */
export const scanR = <S, T>(f: (s: S, t: T) => S, ts: T[], initial: S): S[] =>
  ts.reduceRight((prev, cur) => [f(prev[0], cur), ...prev], [initial])

export const all = (arr: boolean[]) => arr.reduce((a, b) => a && b, true)
export const none = (arr: boolean[]) => arr.reduce((a, b) => !a && !b, true)

export const maxByKey = <T extends object>(arr: T[], key: keyof T): Maybe<T> =>
  nullableToMaybe(maxBy(arr, key))

export const unappend = <T>(arr: T[]) =>
  arr.length > 0 ? Just([arr[0], tail(arr)] as const) : Nothing

export const minimalBy = <T>(arr: T[], score: (t: T) => number): T[] =>
  arr.reduce<{ bestScore: number; acc: T[] }>(
    ({ bestScore, acc }, next) =>
      score(next) < bestScore
        ? { bestScore: score(next), acc: [next] }
        : score(next) === bestScore
        ? { bestScore, acc: acc.concat([next]) }
        : { bestScore, acc },
    { bestScore: Number.POSITIVE_INFINITY, acc: [] }
  ).acc

/** Sorts an array of Ts (non-mutating) then greedily splits it into chunks separated by at least `minGapBetweenChunks`.  */
export const partitionByValue = <T>(
  key: (o: T) => number,
  toPartition: T[],
  { minGapBetweenChunks }: { minGapBetweenChunks: number }
) => {
  if (toPartition.length === 0) {
    return []
  }
  const out: T[][] = [[]]
  const sortedValues = toPartition.slice().sort((l, r) => key(l) - key(r))
  let t = key(sortedValues[0])
  for (const v of sortedValues) {
    if (key(v) - t > minGapBetweenChunks) {
      out.push([v])
    } else {
      out[out.length - 1].push(v)
    }
    t = key(v)
  }
  return out
}

export const createAllPairsFromItems = <T>(items: T[]): [T, T][] =>
  items.flatMap((i, index) => items.slice(index + 1).map((j) => [i, j] satisfies [T, T]))
