import { Checkbox } from "@components/inputs/checkbox/Checkbox"
import { HighlightedIcon } from "@stories/icons/HighlightedIcon"
import { FilterFnRecord, Variant } from "common/model/newsfeed/NewsFeed"
import { Color } from "../../../../stories/components/Typography/Typography"

export const onlyHighlighted: FilterFnRecord = {
  news: {
    function: (t) => t.relevance.highlighted === true,
    asStrings: { field: "onlyHighlighted", value: "true" },
  },
  funding_rounds: {
    function: () => true,
    asStrings: { field: "onlyHighlighted", value: "true" },
  },
}

export const OnlyHighlighted = <V extends Variant>({
  onEnable,
  variant,
  valueString,
}: {
  onEnable: (fn: FilterFnRecord[V] | undefined) => void
  variant: V
  valueString: string | null
}) => (
  <div className="flex flex-row space-x-2 text-xs items-center">
    <Checkbox
      className="w-3 h-3"
      id={`${variant}-filter-only-highlighted`}
      onChange={(newVal) => {
        onEnable(!newVal.target.checked ? undefined : onlyHighlighted[variant])
      }}
      checked={!!valueString}
    />
    <div className="w-4 flex items-center h-full text-primary-500 mr-1">
      <HighlightedIcon color={Color.Primary} />
    </div>
    <span>Only highlighted news</span>
  </div>
)
