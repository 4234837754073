import { RefObject, useRef, useEffect } from "react"
import { classNames } from "src/utils/classNames"

type HorizontalScrollContainerProps = {
  children: React.ReactNode
  className?: string
  id?: string
  shouldMaskDataFromDataDog?: boolean
  onScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void
  getRef?: (x: RefObject<HTMLDivElement>) => void
}

const HorizontalScrollContainer = ({
  id,
  children,
  className,
  onScroll,
  getRef,
  shouldMaskDataFromDataDog = false,
}: HorizontalScrollContainerProps) => {
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (getRef) getRef(ref)
  }, [ref, getRef])
  return (
    <div
      id={id}
      ref={ref}
      className={classNames(
        className,
        "overflow-x-auto scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thin scrollbar-thumb-neutral-500 scrollbar-track-neutral-200"
      )}
      data-dd-privacy={shouldMaskDataFromDataDog ? "mask" : ""}
      onScroll={(e) => {
        if (onScroll) onScroll(e)
      }}
    >
      {children}
    </div>
  )
}

export default HorizontalScrollContainer
