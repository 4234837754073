import CompanyLogoAndName from "@components/CompanyLogoAndName"
import { OrderInquirySubmittedEvent } from "common/model/AccountActivityLog/AccountActivityLog"
import React from "react"

interface OrderInquirySubmittedDetailsProps {
  event: OrderInquirySubmittedEvent
}

export const OrderInquirySubmittedDetails: React.FC<OrderInquirySubmittedDetailsProps> = ({
  event,
}) => {
  const { inquiry } = event
  const { company } = inquiry.source.anchorOrder

  return (
    <div className="flex gap-4 items-center">
      <CompanyLogoAndName company={company} size="xs" />
    </div>
  )
}
