import { Loading } from "common/utils/Loading"
import { useEffect, useState } from "react"
import * as Sentry from "@sentry/react"
import _ from "lodash"
import { useErrorHandler } from "../../../providers/errorHandler"

export const usePromisedState = <S, T>(
  query: (s: S) => Promise<Loading<T>>,
  arg: S,
  deps: unknown[] = [arg],
  useLoader: boolean | undefined = false
) => {
  const [result, setResult] = useState<Loading<T>>("loading")
  const { handleError } = useErrorHandler()
  useEffect(() => {
    if (useLoader) setResult("loading")
    // eslint-disable-next-line rulesdir/no-let
    let isSubscribed: boolean = true
    query(arg).then(
      (x) => (isSubscribed ? setResult(x) : null),
      (e) => {
        if (isSubscribed) {
          Sentry.captureException(e)
          if (_.isError(e)) {
            handleError(e)
          }
          setResult(null)
        }
      }
    )
    return () => {
      // eslint-disable-next-line better-mutation/no-mutation
      isSubscribed = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
  return result
}
