import { collections } from "common/firestore/Collections"
import { FirebaseCommon } from "common/firestore/Interface"
import {
  acceptTrustedBrokerConnectionInvite,
  TrustedBrokerConnection,
} from "common/model/TrustedBrokerConnection"

export const acceptConnectionInvite = ({
  db,
  sourceAccountId,
  connection,
}: {
  db: FirebaseCommon.DB
  sourceAccountId: string
  connection: TrustedBrokerConnection
}) => {
  const acceptedConnection = acceptTrustedBrokerConnectionInvite({ sourceAccountId, connection })
  if (!acceptedConnection) return Promise.reject()

  return db
    .collection(collections.trustedBrokerConnections)
    .doc(connection.id)
    .set(acceptedConnection)
}
