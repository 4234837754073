import { useEffect, useState, useSyncExternalStore } from "react"

import * as Sentry from "@sentry/react"
import {
  Company,
  companyInPostgres,
  CompanyWithPostgres,
  LogoSize,
  logoSizes,
} from "common/model/Company"
import { head } from "common/utils/data/Array/ArrayUtils"
import { Just, Nothing } from "common/containers/Maybe"
import Firebase9 from "../../firebase/Firebase9"
import { useFirebase9 } from "../../firebase/Firebase9Context"

type CompanyLogoDataCacheValue = { logoUrl: string; postgresId: string | undefined }
const companyLogoDataCache = new Map<string, CompanyLogoDataCacheValue | undefined>()
const subscribers = new Set<() => void>()
const subscribeToCompanyLogoDataCache = (callback: () => void) => {
  subscribers.add(callback)
  return () => subscribers.delete(callback)
}
const updateCompanyLogoDataCache = (
  key: string,
  value: { logoUrl: string; postgresId: string | undefined } | undefined
) => {
  companyLogoDataCache.set(key, value)
  subscribers.forEach((cb) => cb())
}

const getId = (company: Company | CompanyAirtableId | undefined) => {
  if (!company) return ""
  return typeof company === "string" ? company : company.airtableId
}

const getCacheId = (company: Company | CompanyAirtableId | undefined, size: LogoSize) =>
  `${getId(company)}_${size}`

export type CompanyAirtableId = string

const getFullCompany = async (
  company: string | CompanyWithPostgres | Company,
  firebase: Firebase9
): Promise<Company> => {
  if (company && typeof company !== "string") {
    return company
  }
  const lookup = head(await firebase.getCompanyByAirtableId(company)).bind((c) =>
    companyInPostgres(c) ? Just(c) : Nothing
  )
  return lookup.match(
    (x: CompanyWithPostgres | Company) => Promise.resolve(x),
    () => Promise.reject(new Error(`Could not find company with airtable id ${company}`))
  )
}

const buildLogoData =
  (size: LogoSize) =>
  (
    currentCompany: CompanyWithPostgres | Company
  ): Promise<{ logoUrl: string; postgresId: string | undefined }> => {
    const { logos, domain } = currentCompany

    if (logos?.[size]) {
      return Promise.resolve({
        logoUrl: logos[size],
        postgresId: currentCompany.postgresCompanyId,
      })
    }

    if (domain) {
      return Promise.resolve({
        logoUrl: `https://logo.clearbit.com/${domain}?size=${logoSizes[size]}`,
        postgresId: currentCompany.postgresCompanyId,
      })
    }

    return Promise.reject(
      new Error(
        `Unable to generate Logo URL for company because it does not have logos or domain: ${
          currentCompany.name
        }\nPitchbook page: ${
          currentCompany.pbid
            ? `https://pitchbook.com/profiles/company/${currentCompany.pbid}`
            : "not found"
        }\nId: ${currentCompany.id}`
      )
    )
  }
const updateCompanyLogoDataCacheIfNew =
  (company: CompanyWithPostgres | CompanyAirtableId | Company, size: LogoSize) =>
  (newCacheValue: CompanyLogoDataCacheValue) => {
    const cacheId = getCacheId(company, size)
    const currentCacheValue = companyLogoDataCache.get(cacheId)
    if (
      currentCacheValue?.logoUrl !== newCacheValue.logoUrl ||
      currentCacheValue?.postgresId !== newCacheValue.postgresId
    ) {
      updateCompanyLogoDataCache(cacheId, newCacheValue)
    }
  }

// Returns url for company logo to be used in img src
const useCompanyLogo = (
  company: CompanyWithPostgres | CompanyAirtableId | Company,
  size: LogoSize
) => {
  const firebase = useFirebase9()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const cachedLogoData = useSyncExternalStore(subscribeToCompanyLogoDataCache, () =>
    companyLogoDataCache.get(getCacheId(company, size))
  )

  // Checks for company domain and logos object, if domain exists we default to clearbit api for logo src, if logos object exists on company and specified size exists, than we default to that source instead. If just companyAirtableId is provided, than we lookup company before continuing
  useEffect(() => {
    if (cachedLogoData || error !== null || isLoading) return

    getFullCompany(company, firebase)
      .then(buildLogoData(size))
      .then(updateCompanyLogoDataCacheIfNew(company, size))
      .catch((e) => {
        setError(e instanceof Error ? e.message : "Logo fetch error")
        Sentry.captureException(e, { level: Sentry.Severity.Warning })
      })
      .finally(() => setIsLoading(false))
  }, [company, firebase, size, cachedLogoData, isLoading, error])

  // We return postgresId to enable forced navigation to issuer page
  return {
    error,
    setError,
    logoUrl: cachedLogoData?.logoUrl,
    postgresId: cachedLogoData?.postgresId ?? "",
  }
}

export default useCompanyLogo
