import ProductAnnouncementPill from "@stories/components/Badges/ProductAnnouncementPill/ProductAnnouncementPill"
import { UserProductInformationHistory } from "common/model/User"
import { useViewport } from "src/utils/useViewport"
import { Button } from "../../stories/components/Button/Button"
import { ProductInformationLoomLinks } from "../layout/information-popup/InformationPopupConfig"
import { useInformationPopupModal } from "../layout/information-popup/InformationPopupModal"
import { HighlightedIcon } from "@stories/icons/HighlightedIcon"
import { Size } from "@stories/components/Typography/Typography"

export const NewFeatureButton = ({
  announcementKey,
}: {
  announcementKey: keyof UserProductInformationHistory
}) => {
  const viewport = useViewport()
  const { ModalComponent, openModal } = useInformationPopupModal({
    informationKey: announcementKey,
    defaultModalOpen: false,
  })

  const announcement = ProductInformationLoomLinks[announcementKey]

  const shouldDisplayTruncatedTitle = viewport.width < 1350

  const label = shouldDisplayTruncatedTitle
    ? announcement.shortTitle
    : announcement.title || "New Product Update"

  const productAnnouncementPillLabel = "LEARN MORE"

  return (
    <div className="mr-4">
      <Button
        variant="secondary"
        leftIcon={
          shouldDisplayTruncatedTitle ? (
            <HighlightedIcon size="xs" />
          ) : (
            <ProductAnnouncementPill label={productAnnouncementPillLabel} />
          )
        }
        size="large"
        label={label}
        onClick={openModal}
      />
      {ModalComponent}
    </div>
  )
}
