import { Rec, UnsafeRec, setFieldSimple } from "common/utils/RecordUtils"
import { useMemo } from "react"
import { TableFilter } from "src/pages/Data/TableFilters/TableFilter"
import { useParameterizedAction } from "../state/useAction"

export type FilterStates<S> = { [key in keyof S]: S[key] | null }

export type FilterToggles<S> = { [key in keyof S]: (s: S[key] | null) => void }

/** Takes a list to be filtered, a record of `TableFilter`s (which are class wrappers around `[(s) => (t) => boolean, (s, (s) => void) => Element]` pairs), returns a filtered list and a record of control `Element`s for each filter. */
export const useFilter = <T, S extends Record<keyof S, unknown>>(
  ts: T[],
  filters: {
    [key in keyof S]: TableFilter<T, S[key], S[key]>
  },
  defaultStates: { [key in keyof S]?: S[key] }
): [T[], Record<keyof S, JSX.Element>, FilterStates<S>, FilterToggles<S>] =>
  useFiltered((f) => ts.filter(f), filters, defaultStates)

export const useFiltered = <T, S extends Record<keyof S, unknown>, R>(
  ts: (f: (t: T) => boolean) => R,
  filters: {
    [key in keyof S]: TableFilter<T, S[key], S[key]>
  },
  defaultStates: { [key in keyof S]?: S[key] }
): [R, Record<keyof S, JSX.Element>, FilterStates<S>, FilterToggles<S>] => {
  const [filterStates, filterToggles]: [FilterStates<S>, FilterToggles<S>] = useParameterizedAction<
    FilterStates<S>,
    FilterStates<S>
  >(
    { ...Rec.fill(filters, null), ...defaultStates },
    Rec.indexedMap(
      filters,
      ([i, _]) =>
        (s) =>
        (x) =>
          setFieldSimple(i, s, x)
    )
  )

  const filterControls = useMemo(
    () =>
      Rec.indexedMap(filterToggles, ([i, f]) => {
        const Component = filters[i].value[1]
        return (
          <Component key={i.toString()} value={filterStates[i] || null} onChange={(s) => f(s)} />
        )
      }),
    [filterStates, filterToggles, filters]
  )

  const activeFilter = selectActiveFilter(filterStates, filters)

  return [ts(activeFilter), filterControls, filterStates, filterToggles]
}

export const applyActiveFilter = <T, S extends Record<keyof S, unknown>>(
  ts: T[],
  filters: {
    [key in keyof S]: TableFilter<T, S[key], S[key]>
  },
  filterStates: FilterStates<S>
): T[] => {
  const activeFilter = selectActiveFilter(filterStates, filters)

  return ts.filter(activeFilter)
}

const selectActiveFilter = <T, S extends Record<keyof S, unknown>, R>(
  filterStates: FilterStates<S>,
  filters: {
    [key in keyof S]: TableFilter<T, S[key], S[key]>
  }
): ((t: T) => boolean) =>
  UnsafeRec.entries(filterStates)
    .filter((x) => x[1])
    .reduce(
      (prev, next) => (t) => {
        // something actually unsound is going on here
        const thingToMakeTscStopBeingWrong: S[keyof S] | null = next[1]
        return (
          (thingToMakeTscStopBeingWrong
            ? filters[next[0]].value[0](thingToMakeTscStopBeingWrong)(t)
            : true) && prev(t)
        )
      },
      (_) => true
    )
