import { SerializedBooleanFormula, BooleanFormula } from "../../../containers/Trees/BooleanFormula"
import { assertUnreachable } from "../../../utils/fp/Function"
import { Order } from "../Models/Wrapped"
import { OrderDirection, OrderShareClass } from "../Order"
import { OrderStructure } from "../Types/Terms"


export type OrderNumericField = "price" | "volume" | "origination date"
export const orderNumericFieldComparisons = ["geq", "lt"] as const
export type OrderNumericFieldComparisons = (typeof orderNumericFieldComparisons)[number]

export const numericFieldComparisonDisplay = (comparison: OrderNumericFieldComparisons) =>
  comparison === "geq"
    ? "greater than"
    : comparison === "lt"
    ? "less than"
    : assertUnreachable(comparison)

type OrderBooleanField = "connectable"

export type AtomicOrderFilter =
  | {
      tag: "company"
      companyId: string
    }
  | {
      tag: OrderNumericField
      limit: number
      comparison: OrderNumericFieldComparisons
    }
  | {
      tag: OrderBooleanField
      value: boolean
    }
  | { tag: "field-exists"; field: "volume" /** extend here if needed */ }
  | { tag: "direction"; direction: OrderDirection }
  | { tag: "structure"; orderStructure: OrderStructure[] }
  | { tag: "shareClass"; shareClass: OrderShareClass }
  | { tag: "liveMarketIndication" }
  | { tag: "sharedIndication"; accountId: string }

export const runAtomicOrderFilter =
  (order: Order) =>
  (filter: AtomicOrderFilter): boolean => {
    switch (filter.tag) {
      case "company":
        return order.company().id === filter.companyId
      case "price":
        return order.impliedPricePerShare().match(
          (pps) => (filter.comparison === "geq" ? pps >= filter.limit : pps < filter.limit),
          () => false
        )
      case "volume":
        return order.amountUSD().match(
          (amount) =>
            filter.comparison === "geq"
              ? amount.upperBound >= filter.limit
              : amount.lowerBound < filter.limit,
          () => false
        )
      case "connectable":
        return order.isConnectable()
      case "origination date":
        return order.originationDate() !== null && filter.comparison === "geq"
          ? (order.originationDate()?.valueOf() ?? 0) >= filter.limit
          : (order.originationDate()?.valueOf() ?? 0) < filter.limit
      case "direction":
        return order.direction() === filter.direction
      case "structure":
        return !!filter.orderStructure.some((filterOrderStructure) =>
          order
            .currentStructures()
            .some((orderStructure) => orderStructure.is(filterOrderStructure))
        )
      case "shareClass":
        return order.shareClasses().includes(filter.shareClass)
      case "liveMarketIndication":
        return !order.isDarkpool() && !order.isPrivate
      case "sharedIndication":
        return order.sharedWithAccount({ id: filter.accountId })
      case "field-exists":
        switch (filter.field) {
          case "volume":
            return order.amountUSD().isJust()
          default:
            return assertUnreachable(filter.field)
        }
      default:
        return assertUnreachable(filter)
    }
  }

export type SerializedOrderFilterFormula = SerializedBooleanFormula<AtomicOrderFilter>
export type OrderFilterFormula = BooleanFormula<AtomicOrderFilter>
