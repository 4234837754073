import { User, UserAccessFields } from "common/model/User"
import { last } from "lodash"
import { AccountId } from "common/model/Account"
import { HoldingDocumentType } from "common/model/holdings/HoldingDocument"
import { isString } from "common/utils/json/validate"
import Firebase from "../firebase/Firebase"
import { DocumentType } from "../model/Document"
import { DealDistributionIdFields } from "common/model/DealDistribution/DealDistribution"

const folders = {
  auctionParticipationAgreement: (accountId: AccountId, auctionId: string) =>
    `accounts/${accountId}/auction-contracts/${auctionId}`,
  documentTemplate: (templateType: DocumentType) => `document_templates/${templateType}`,
  documentUploads: (user: User, uploadEventFolder: string) =>
    `data-product-test/accounts/${user.account.id}/trade-data-uploads/${uploadEventFolder}`,
  holdingDocuments: (holdingId: string, type: HoldingDocumentType) =>
    `holdings/${holdingId}/documents/${type}`,
  portfolioUploads: (accountId: AccountId, uploadFolder: string) =>
    `accounts/${accountId}/portfolio-uploads/${uploadFolder}`,
  verificationDocs: (user: UserAccessFields, tradingRegistrationId: string) =>
    `trading_registration/${tradingRegistrationId}/${user.id}`,
  unauthedVerificationDocs: (tradingRegistrationId: string) =>
    `trading_registration/${tradingRegistrationId}/unauthed`,
  dealDistributionEmailDocs: (user: UserAccessFields, dealDistribution: DealDistributionIdFields) =>
    `deal_distribution/${dealDistribution.id}/${user.id}/email-docs`,
  dealDistributionPrivateDataRoomDocs: (
    user: UserAccessFields,
    dealDistribution: DealDistributionIdFields
  ) => `deal_distribution/${dealDistribution.id}/${user.id}/private-data-room`,
  dealDistributionPublicDataRoomDocs: (
    user: UserAccessFields,
    dealDistribution: DealDistributionIdFields
  ) => `deal_distribution/${dealDistribution.id}/${user.id}/public-data-room`,
}

const fullFilePath = (folder: string, filename: string) => [folder, filename].join("/")

const filenameFromFullPath = (filePath: string) =>
  last(filePath.split("/").filter((s) => s.length)) || ""

export const readFileData = (db: Firebase, filePath: string) =>
  db.documentStorage
    .ref(filePath)
    .getDownloadURL()
    .then((url) =>
      isString(url)
        ? fetch(url)
        : Promise.reject(new Error(`expected url to be a string, got ${JSON.stringify(url)}`))
    )

export const FileUtils = {
  folders,
  fullFilePath,
  filenameFromFullPath,
  readFileData,
}
