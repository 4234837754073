import Typography, { Size, Weight } from "@stories/components/Typography/Typography"
import { classNames } from "../../../../utils/classNames"

export const OrderPageSidebarCardTitle = ({ title }: { title: string }) => (
  <Typography text={title} weight={Weight.Semibold} size={Size.Medium} />
)

const OrderPageSidebarCard = ({
  className,
  children,
}: React.PropsWithChildren<{ title?: string; className?: string }>) => (
  <div className={classNames(className, "rounded border bg-white p-4")}>{children}</div>
)

export default OrderPageSidebarCard
