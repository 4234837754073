import { isLoading } from "common/utils/Loading"
import { Left, Right } from "common/containers/Either"
import { Navigate } from "react-router-dom"
import { Routes } from "../../../Routes/Routes"
import { UserAuthContext } from "../../../Routes/auth/UserAuthContext"
import { setPostLoginRedirect } from "../../../utils/LocalStorage"
import Spinner from "../../icons/Spinner"
import { useLocation } from "react-router-dom"

export const loginCheck = (location: ReturnType<typeof useLocation>) => (ctx: UserAuthContext) => {
  if (isLoading(ctx.user) || isLoading(ctx.savedInviteCode)) {
    return Left(() => <Spinner fullPage size="sm" />)
  }
  if (!ctx?.authUser && !ctx.savedInviteCode) {
    setPostLoginRedirect(`${location.pathname}${location.search}`)
    return Left(() => <Navigate to={Routes.login} />)
  }
  return Right(ctx)
}
