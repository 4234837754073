import { DeepPartialIntroduction } from "common/model/IntroductionRequest"
import { THREAD_ID_PARAM } from "common/model/Messaging/MessagingRoutes"
import { useNavigate } from "react-router-dom"
import { Routes } from "src/Routes/Routes"

import { PageTitle } from "@components/typography/PageTitle"
import { Spin, Result } from "@stories/components/Antd"
import { AnimatePresence, motion } from "framer-motion"

export type IntroductionSaveState = "success" | "error" | "pending"

const SavedStatus = ({ request }: { request: DeepPartialIntroduction }) => {
  const navigate = useNavigate()
  const threadId = request.connectionThreadId ?? request.requesterThreadId
  const viewThread = () => {
    const search = new URLSearchParams()
    if (threadId) {
      search.append(THREAD_ID_PARAM, threadId)
    }
    navigate({ pathname: Routes.inbox.root, search: search.toString() })
  }

  return (
    <Result
      className="p-4"
      status="success"
      extra={
        <div className="flex flex-col items-center justify-center space-y-4">
          <div className="mt-2 max-w-xs text-center font-semibold text-neutral-1000">
            Your Connect Request has been submitted. We will contact you once we have reviewed the
            request.
          </div>
          <div className="mt-4 max-w-xs text-neutral-800">
            You can view your introduction request and provide additional information to Caplight
            within your{" "}
            <span
              role="button"
              className="font-semibold text-accent-500"
              onClick={viewThread}
              onKeyUp={(e) => e.code === "Enter" && viewThread}
              tabIndex={0}
            >
              Messaging Inbox
            </span>
          </div>
        </div>
      }
    />
  )
}
export const ErrorStatus = () => (
  <Result
    className="p-4"
    status="warning"
    extra={
      <div className="flex flex-col items-center justify-center">
        <PageTitle title="An Error Occurred" />
      </div>
    }
  />
)
export const SavingStatus = () => (
  <div className="m-auto flex flex-col items-center mt-24 justify-center">
    <div className="flex flex-col h-32 justify-center">
      <Spin size="large" />
    </div>
    <PageTitle title="Your connection is being submitted." />
  </div>
)

export const PendingIntroductionMessage = ({
  request,
  saveState,
}: {
  request: DeepPartialIntroduction
  saveState: IntroductionSaveState
}) => (
  <div className="flex flex-col justify-center items-center">
    <AnimatePresence mode="wait">
      <motion.div
        key={saveState}
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 10, opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        {saveState === "success" ? <SavedStatus request={request} /> : null}
        {saveState === "error" ? <ErrorStatus /> : null}
        {saveState === "pending" ? <SavingStatus /> : null}
      </motion.div>
    </AnimatePresence>
  </div>
)
