import { Input } from "@stories/components/Antd"
import { FormLabel } from "@stories/components/Inputs/FormLabel/FormLabel"
import { StructureOrderTerms } from "common/model/Order/Types/Terms"

type CarryManagementData = StructureOrderTerms["spv"]
export type CarryManagementProps = {
  value?: CarryManagementData
  title?: string
  onChange: (terms: CarryManagementData) => void
  disabled?: boolean
  titles?: Record<keyof CarryManagementData, string>
}

export const updateCarryManagementInput = (
  value: CarryManagementData,
  key: "carry" | "managementFee",
  v: string
) => {
  if (v) {
    return {
      ...value,
      [key]: Number.parseFloat(v),
    }
  } else {
    return {
      ...value,
      [key]: null,
    }
  }
}

export function CarryManagement({
  value = { carry: null, managementFee: null },
  title = "",
  onChange,
  disabled,
  titles = {
    carry: "Carry",
    managementFee: "Management Fees",
  },
}: CarryManagementProps) {
  const handleChange =
    (key: keyof CarryManagementData): React.ChangeEventHandler<HTMLInputElement> =>
    (e) => {
      const v = e.target.value
      onChange(updateCarryManagementInput(value, key, v))
    }
  return (
    <div className="flex flex-col">
      <FormLabel>{title}</FormLabel>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
        <div className="w-full">
          <FormLabel>{titles.managementFee}</FormLabel>
          <Input
            type="number"
            className="w-full"
            suffix="%"
            value={value.managementFee || undefined}
            onChange={handleChange("managementFee")}
            disabled={disabled}
          />
        </div>
        <div className="w-full">
          <FormLabel>{titles.carry}</FormLabel>
          <Input
            type="number"
            className="w-full"
            suffix="%"
            value={value.carry || undefined}
            onChange={handleChange("carry")}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  )
}
