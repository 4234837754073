import { collections } from "common/firestore/Collections"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { DealCRMInterest } from "common/model/DealCRM/DealCRMInterest"
import { DealCRMNote } from "common/model/DealCRM/DealCRMNote"
import { firestoreConverter } from "../model/FirestoreConverter"
import { FirebaseReader } from "./Firebase"

export const getContactTaggedNotes = ({
  db,
  accountId,
  contact,
}: {
  db: FirebaseReader
  accountId: string
  contact: DealCRMContact
}) => {
  const individualAndFirmIds = [
    contact.id,
    ...(contact.tag === "individual" && contact.firm
      ? [contact.firm.id]
      : contact.tag === "firm"
      ? contact.contacts.map((c) => c.id)
      : []),
  ]
  return db.db
    .collection(collections.dealCRM.notes)
    .where("taggedContactIds", "array-contains-any", individualAndFirmIds)
    .where("accountId", "==", accountId)
    .where("parentNote", "==", null)
    .withConverter<DealCRMNote>(firestoreConverter<DealCRMNote>())
    .orderBy("createdAt", "desc")
}

export const getContactSourceNotes = ({
  db,
  accountId,
  contact,
  contactBuySellInterest,
}: {
  db: FirebaseReader
  accountId: string
  contact: DealCRMContact
  contactBuySellInterest: DealCRMInterest[]
}) => {
  const contactBuySellInterestIds = contactBuySellInterest.map((interest) => interest.id)

  const individualAndFirmIds = [
    contact.id,
    ...(contact.tag === "individual" && contact.firm
      ? [contact.firm.id]
      : contact.tag === "firm"
      ? contact.contacts.map((c) => c.id)
      : []),
  ]
  const associatedRecordIds = [...contactBuySellInterestIds, ...individualAndFirmIds]

  // eslint-disable-next-line rulesdir/no-firestore-in-clause
  return db.db
    .collection(collections.dealCRM.notes)
    .where("source.sourceId", "in", associatedRecordIds)
    .where("accountId", "==", accountId)
    .where("parentNote", "==", null)
    .withConverter<DealCRMNote>(firestoreConverter<DealCRMNote>())
    .orderBy("createdAt", "desc")
}

export const getContactBuySellInterestNotes = ({
  db,
  accountId,
  contactBuySellInterestIds,
}: {
  db: FirebaseReader
  accountId: string
  contactBuySellInterestIds: Pick<DealCRMInterest, "id">[]
}) =>
  db.db
    .collection(collections.dealCRM.notes)
    .where(
      "source.sourceId",
      "in",
      contactBuySellInterestIds.map((interest) => interest.id)
    )
    .where("accountId", "==", accountId)
    .where("parentNote", "==", null)
    .withConverter<DealCRMNote>(firestoreConverter<DealCRMNote>())
    .orderBy("createdAt", "desc")

export const getAllNotesForAccount = ({
  db,
  accountId,
}: {
  db: FirebaseReader
  accountId: string
}) =>
  db.db
    .collection(collections.dealCRM.notes)
    .where("accountId", "==", accountId)
    .where("parentNote", "==", null)
    .withConverter<DealCRMNote>(firestoreConverter<DealCRMNote>())
    .orderBy("createdAt", "desc")

export const getAllNotesForSourceId = ({
  db,
  accountId,
  sourceId,
}: {
  db: FirebaseReader
  accountId: string
  sourceId: string
}) =>
  db.db
    .collection(collections.dealCRM.notes)
    .withConverter(firestoreConverter<DealCRMNote>())
    .where("source.sourceId", "==", sourceId)
    .where("accountId", "==", accountId)
    .where("parentNote", "==", null)

export const getAllNotesForDealParticipants = ({
  db,
  accountId,
  dealId,
}: {
  db: FirebaseReader
  accountId: string
  dealId: string
}) =>
  db.db
    .collection(collections.dealCRM.notes)
    .withConverter(firestoreConverter<DealCRMNote>())
    .where("accountId", "==", accountId)
    .where("source.deal.id", "==", dealId)
    .where("parentNote", "==", null)

export const getNoteReplies = ({
  db,
  note,
  limit,
}: {
  db: FirebaseReader
  note: DealCRMNote
  limit?: number
}) => {
  const query = db.db
    .collection(collections.dealCRM.notes)
    .withConverter<DealCRMNote>(firestoreConverter<DealCRMNote>())
    .where("accountId", "==", note.accountId)
    .where("parentNote.id", "==", note.id)
    .orderBy("createdAt", "desc")

  return limit ? query.limit(limit) : query
}

export const deleteCRMNote = ({ db, note }: { db: FirebaseReader; note: DealCRMNote }) =>
  db.db.collection(collections.dealCRM.notes).doc(note.id).delete()

export const updateCRMNote = ({
  db,
  note,
  updatedNote,
}: {
  db: FirebaseReader
  note: DealCRMNote
  updatedNote: DealCRMNote
}) => db.db.collection(collections.dealCRM.notes).doc(note.id).update(updatedNote)
