import { head } from "common/utils/data/Array/ArrayUtils"
import { orderBy } from "lodash"
import { Just, Nothing } from "common/containers/Maybe"
import OrderTermSheetSection from "../OrderTermSheetSection"
import { orderDataAccessLevel } from "../../../shared/accessLevels"
import { OrderPageSectionProps } from "./types"

const AdditionalDetailsSection = ({ order, hideUnspecified }: OrderPageSectionProps) => {
  const { publicAdminNotes } = order.unboxed.order.base
  const currentPublicAdminNotes = head(
    orderBy(publicAdminNotes, (n) => n.timestamp.getTime(), "desc")
  )
    .bind((n) => (n.text.trim().length > 0 ? Just(n.text.trim()) : Nothing))
    .match(
      (value) => ({
        label: "Notes from Caplight",
        value,
        accessLevel: orderDataAccessLevel.adminNotes(order.isConnectable()),
      }),
      () => null
    )

  return (
    <OrderTermSheetSection
      title="Additional Details"
      rows={[...(currentPublicAdminNotes ? [currentPublicAdminNotes] : [])]}
      hideUnspecified={hideUnspecified}
    />
  )
}

export default AdditionalDetailsSection
