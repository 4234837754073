import { firestoreConverter } from "./FirestoreConverter"

export interface PublicOptionObservation {
  id: string
  observedDate: Date
  companyName: string
  expirations: number[]
}

export const publicOptionObservationConverter = firestoreConverter<PublicOptionObservation>()
