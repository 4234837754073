import * as app from "firebase/compat/app"
import "firebase/firestore"

/**
 *  A firestore data converter for *new documents*
 *  Useful for adding documents in a type-safe way
 */
export const newDocFirestoreConverter = <T>() => {
  const converter: app.default.firestore.FirestoreDataConverter<T> = {
    toFirestore: <S>(data: S) => data, // TODO fix
    fromFirestore: (snapshot, options) => snapshot.data(options) as T,
  }
  return converter
}
