import { ExclamationCircleOutlined } from "@ant-design/icons"
import Alert from "@stories/components/Alert/Alert"
import { deprecatedIsLoaded } from "common/utils/Loading"
import { daysBetweenDates } from "common/utils/dateUtils"
import moment from "moment"
import { useLocation } from "react-router-dom"
import { useCurrentAccount } from "src/queries/currentUser/useCurrentAccount"
import { Routes } from "../../Routes/Routes"

const whitelistedRoutes: string[] = [
  Routes.data.upload.root,
  Routes.data.onboarding,
  Routes.account,
  Routes.companies.root,
  Routes.structurePricer,
  Routes.orders.root,
  Routes.myOrders,
]

export const isWhitelistedDataRoute = (currentRoute: string): boolean =>
  !!whitelistedRoutes.find((whitelistedRoute) => currentRoute.includes(whitelistedRoute))

const DataTrialStatusBar = () => {
  const location = useLocation()
  const account = useCurrentAccount()

  if (!deprecatedIsLoaded(account)) {
    return null
  }

  if (
    account.onboardingStatus?.data?.status !== "trial" ||
    !isWhitelistedDataRoute(location.pathname) ||
    account.autoLockoutAllowed
  )
    return null

  const isExpiringMoreThanOneWeekFromNow =
    daysBetweenDates(account.onboardingStatus.data.trialExpiration, new Date()) > 7

  if (isExpiringMoreThanOneWeekFromNow) {
    return null
  }

  const isDataTrialExpired = account.onboardingStatus.data.trialExpiration < new Date()

  const expiringMessage = (
    <p className="text-neutral-1000 text-sm lg:whitespace-nowrap">
      Your Caplight Data trial period will end on{" "}
      {moment(account.onboardingStatus.data.trialExpiration).format("MMMM Do, YYYY")}
    </p>
  )

  const expiredMessage = (
    <p className="text-neutral-1000 text-sm lg:whitespace-nowrap">
      Your Caplight Data trial period ended on{" "}
      {moment(account.onboardingStatus.data.trialExpiration).format("MMMM Do, YYYY")}
    </p>
  )

  return (
    <div className="flex flex-col z-30 pb-4">
      <Alert
        headline={
          isDataTrialExpired
            ? "Caplight Data Trial Expired"
            : "Attention! Your Data Trial is Expiring Soon"
        }
        variant={isDataTrialExpired ? "danger" : "warning"}
        icon={<ExclamationCircleOutlined />}
        isFullWidthBanner
        message={isDataTrialExpired ? expiredMessage : expiringMessage}
      />
    </div>
  )
}

export default DataTrialStatusBar
