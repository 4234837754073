import React, { ReactNode, useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { classNames } from "../../../utils/classNames"

export type PageTab = {
  title: string
  mobileTitle?: string
  component: ReactNode
  id: string
}

type PageTabSize = "sm" | "md"

type PageTabProps = {
  tabs: PageTab[]
  size?: PageTabSize
  loadAllTabs?: boolean
}

const PageTabs: React.FC<React.PropsWithChildren<PageTabProps>> = ({
  tabs,
  size = "md",
  loadAllTabs = false,
}) => {
  const location = useLocation()
  const navigate = useNavigate()

  const queryTab = tabs.find((tab) => tab.id === new URLSearchParams(location.search).get("tab"))

  const [currentTab, setCurrentTab] = useState<PageTab>(tabs[0])

  useEffect(() => {
    if (queryTab) {
      setCurrentTab(queryTab)
    }
  }, [queryTab, location])

  return (
    <div className="w-full h-full">
      <div className="overflow-x-auto w-full">
        {tabs.map(
          (tab) =>
            tab && (
              <button
                key={tab.title}
                type="button"
                onClick={() => {
                  navigate({ search: `?tab=${tab.id}` })
                }}
                className={`${
                  currentTab.title === tab.title
                    ? "border-b-2 border-accent-500 "
                    : "text-neutral-800 hover:text-accent-500"
                } ${tabClasses[size]}  inline-block`}
              >
                <span className={`${tab.mobileTitle ? "hidden md:flex" : ""}`}>
                  {tab.title.toUpperCase()}
                </span>
                {tab.mobileTitle && (
                  <span className="flex md:hidden">{tab.mobileTitle.toUpperCase()}</span>
                )}
              </button>
            )
        )}
      </div>
      {loadAllTabs ? (
        tabs.map((tab) => (
          <div
            key={tab.id}
            className={classNames(currentTab.id === tab.id ? "mt-4 w-full" : "display-none hidden")}
          >
            {tab.component}
          </div>
        ))
      ) : (
        <div className="w-full">{currentTab.component}</div>
      )}
    </div>
  )
}

export default PageTabs

export const TabWrapper: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <div className="w-full overflow-x-hidden my-4 bg-neutral-white p-4 shadow rounded-md">
    {children}
  </div>
)

const tabClasses: { [key in PageTabSize]: string } = {
  sm: "text-xs mx-4 font-semibold py-1",
  md: "text-sm mx-4 tracking-wide font-semibold py-2",
}
