import { Button } from "@stories/components/Button/Button"
import Typography, { Size } from "@stories/components/Typography/Typography"
import { PlusIcon } from "@stories/icons/PlusIcon"
import { Account } from "common/model/Account"
import { buildAccountCRMSettings, defaultAccountCRMSettings } from "common/model/AccountCRMSettings"
import {
  DealAndOrderStageGroup,
  dealAndOrderStageDisplayNames,
  dealAndOrderStageGroups,
} from "common/model/DealCRM/Deal/DealCRMDeal"
import { isLoaded } from "common/utils/Loading"
import { isEqual } from "lodash"
import { useEffect, useState } from "react"
import { APIEndpoints, runAPIQuery } from "src/firebase/API"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import { updateAccountCRMPreferences } from "src/firebase/crm"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import { handleConsoleError, trackEvent } from "src/utils/Tracking"
import ReOrderableItemList from "./ReOrderableItemList/ReOrderableItemList"
import { SaveStatusProps } from "./useSaveStatus"

export const OrdersAndDealsSettings = ({
  account,
  saveStatus,
  setSaveStatus,
}: { account: Account } & SaveStatusProps) => {
  const { db } = useFirebase9()
  const user = useCurrentUser()
  const accountCRMPreferences = buildAccountCRMSettings(account)

  const [addingStageToGroup, setAddingStageToGroup] = useState<string | null>(null)

  const { dealAndOrderStages: dealStages } = accountCRMPreferences

  const [dealStagesUI, setDealStageUI] = useState(
    dealStages ?? defaultAccountCRMSettings(account.clientType).dealAndOrderStages
  )

  const onDealStagesChanged = (group: DealAndOrderStageGroup, newStages: string[]) => {
    trackEvent("deal_stages_changed", { group, newStages: newStages.join(",") })
    setDealStageUI((prev) => ({ ...prev, [group]: newStages }))
  }

  const onDealStageReplaced = async ({
    deletedStage,
    newStage,
  }: {
    deletedStage: string
    newStage: string
  }) => {
    if (!isLoaded(user)) return
    await runAPIQuery(
      APIEndpoints.replaceCRMInterestAndDealStage,
      {
        deletedStage,
        newStage,
      },
      user
    ).catch(handleConsoleError)
  }

  useEffect(() => {
    const oldCrmPreferences = account.crmPreferences ?? accountCRMPreferences

    if (saveStatus === "loading") {
      return
    }

    if (!isEqual(oldCrmPreferences?.dealAndOrderStages, dealStagesUI)) {
      setSaveStatus("loading")
      updateAccountCRMPreferences({
        db,
        account: { id: account.id, crmPreferences: oldCrmPreferences },
        updatedPreferences: { dealAndOrderStages: dealStagesUI },
      })
        .then(() => setSaveStatus("success"))
        .catch((err) => {
          // TODO: should fail more silently?
          handleConsoleError(err)
          setSaveStatus("error")
        })
    }
  }, [account, saveStatus, setSaveStatus, accountCRMPreferences, db, dealStagesUI])

  const dealIcons: Record<DealAndOrderStageGroup, React.ReactNode> = {
    orders: <div className="w-3 h-3 bg-warning-200 text-white rounded-full" />,
    potentialDeals: <div className="w-3 h-3 bg-primary-200 text-white rounded-full" />,
    inClosing: <div className="w-3 h-3 bg-primary-300 text-white rounded-full" />,
    closed: <div className="w-3 h-3 bg-success-200 text-white rounded-full" />,
    cancelled: <div className="w-3 h-3 bg-neutral-400 text-white rounded-full" />,
  }

  const groupDisplayName = (group: DealAndOrderStageGroup) =>
    dealAndOrderStageDisplayNames(account)[group]

  return (
    <div className="w-full">
      <Typography size={Size.XSmall} text="Manage the stages of your order & deal pipeline" />
      <div className="border rounded-md p-4 mt-2 w-full lg:w-80">
        {dealAndOrderStageGroups.map((group) => (
          <div key={group}>
            <div className="flex justify-between items-center rounded-md bg-neutral-200 text-neutral-500 p-2">
              <Typography text={groupDisplayName(group)} size={Size.XSmall} />
              <Button
                variant="hollow"
                tooltipTitle={`Add new stage within '${groupDisplayName(group)}'`}
                size="small"
                leftIcon={<PlusIcon />}
                onClick={() => setAddingStageToGroup(group)}
              />
            </div>
            <ReOrderableItemList
              items={dealStagesUI[group]}
              onChangeItems={(newItems) => onDealStagesChanged(group, newItems)}
              addingStage={addingStageToGroup === group}
              onAddStageCancel={() => setAddingStageToGroup(null)}
              itemsIcon={dealIcons[group]}
              disableReorder={!!addingStageToGroup}
              onStageReplaced={onDealStageReplaced}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
