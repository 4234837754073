/* eslint-disable react/jsx-props-no-spreading */
import { Tooltip } from "antd"
import { HelpIcon } from "src/stories/icons/HelpIcon"
import { classNames } from "src/utils/classNames"
import Typography, { Color, Size } from "../../Typography/Typography"
import { Rec } from "common/utils/RecordUtils"
import { isNil } from "lodash"
import React from "react"

export const styles = {
  xsmall: {
    paddingY: "py-1",
    paddingX: "px-2",
    paddingLeft: "pl-2",
    marginTop: "mt-1",
    marginBottom: "mb-1",
  },
  small: {
    paddingY: "py-1.5",
    paddingX: "px-2",
    paddingLeft: "pl-2",
    marginTop: "mt-1.5",
    marginBottom: "mb-1.5",
  },
  medium: {
    paddingY: "py-2",
    paddingX: "px-3",
    paddingLeft: "pl-3",
    marginTop: "mt-2",
    marginBottom: "mb-2",
  },
}

type HTMLInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

export type InputProps = {
  /**
   * What is shown at the beginning of the input?
   */
  prefix?: React.ReactNode
  /**
   * What is shown at the end of the input?
   */
  suffix?: React.ReactNode
  /**
   * Is the field optional?
   */
  isOptional?: boolean
  /**
   * Is the field highlighted danger?
   */
  isDanger?: boolean
  /**
   * Is the field disabled?
   */
  isDisabled?: boolean
  /**
   * How large should the input be?
   */
  size?: "xsmall" | "small" | "medium"
  /**
   * Remove Border
   */
  noBorder?: boolean

  /**
   * Make background transparent
   */
  noBackground?: boolean

  /**
   * What should the input's label read?
   */
  label?: string
  /**
   * What should the input's hint text read?
   */
  hintText?: string

  /**
   * Display hint text as tooltip
   */
  tooltipHintText?: string
  /**
   * What should the input's help tooltip read?
   */
  helpText?: string

  /**
   * Name for testId
   */
  testId?: string
  /**
   * Name for heap
   */
  heapName?: string
  /**
   * Data attributes for Heap, e.g. "source-component": "order-form"
   *
   */
  dataAttributes?: { [key: string]: string }
  /**
   * Ref for input element
   *
   */
  inputRef?: React.Ref<HTMLInputElement>
  /**
   * Should the input be hidden in datadog recordings?
   */
  shouldMaskDataInDataDog?: boolean

  /**
   * Class name for placeholder text
   */
  placeholderClassName?: string
} & Pick<
  HTMLInputProps,
  | "value"
  | "onChange"
  | "onBlur"
  | "onFocus"
  | "onKeyDown"
  | "type"
  | "placeholder"
  | "name"
  | "id"
  | "step"
  | "min"
  | "max"
  | "readOnly"
  | "autoComplete"
  | "minLength"
>

export const InputLabel = ({
  label,
  isOptional,
  size,
  disableYPadding = false,
  htmlFor,
}: Pick<InputProps, "label" | "isOptional"> &
  Required<Pick<InputProps, "size">> & { disableYPadding?: boolean; htmlFor?: string }) =>
  label || isOptional ? (
    <label
      htmlFor={htmlFor}
      className={classNames(
        "flex items-center justify-between",
        htmlFor && "select-none",
        !disableYPadding && styles[size].marginBottom
      )}
    >
      <div>{label ? <Typography text={label} size={Size.Small} /> : null}</div>
      <div>
        {isOptional ? (
          <Typography text="Optional" size={Size.XXSmall} color={Color.Subtitle} />
        ) : null}
      </div>
    </label>
  ) : null

export const Input = ({
  value,
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
  placeholder,
  type,
  id,
  name,
  step,
  min,
  max,
  inputRef,
  prefix,
  suffix,
  size = "medium",
  noBorder,
  isDisabled,
  isDanger,
  isOptional,
  noBackground = false,
  label,
  hintText,
  tooltipHintText,
  helpText,
  testId,
  heapName,
  dataAttributes,
  shouldMaskDataInDataDog,
  readOnly,
  autoComplete,
  placeholderClassName,
}: InputProps) => {
  const dataAttributeProps: Partial<Record<string, string>> = dataAttributes
    ? Rec.fromEntries(Object.keys(dataAttributes).map((k) => [`data-${k}`, dataAttributes[k]]))
    : {}

  return (
    <div>
      <InputLabel htmlFor={id} label={label} isOptional={isOptional} size={size} />
      <Tooltip
        title={
          tooltipHintText ? (
            <Typography text={tooltipHintText} color={Color.White} size={Size.XSmall} />
          ) : null
        }
        placement="bottom"
      >
        <div className="relative rounded-md">
          <div
            className={classNames(
              "pointer-events-none absolute inset-y-0 left-0 flex items-center text-neutral-800",
              styles[size].paddingLeft
            )}
          >
            {prefix}
          </div>
          <input
            className={classNames(
              prefix !== undefined && "pl-10",
              (!!helpText || !!suffix) && "pr-12",
              styles[size].paddingY,
              "block w-full sm:text-sm text-neutral-1000",
              placeholderClassName ?? "placeholder-neutral-600",
              noBorder ? "border-0" : "border rounded-md focus:ring-2 focus:outline-none",
              isDisabled && "bg-neutral-300 text-neutral-800 border-neutral-400",
              isDanger
                ? "focus:ring-danger-100 border-danger-500"
                : "focus:ring-accent-100 border-neutral-400",
              noBackground && "bg-transparent"
            )}
            disabled={isDisabled}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            id={id}
            name={name}
            type={type}
            data-testid={testId}
            data-dd-privacy={shouldMaskDataInDataDog ? "mask" : "allow"}
            data-heap-name={heapName}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...dataAttributeProps}
            step={step}
            min={min}
            max={max}
            ref={inputRef}
            readOnly={readOnly}
            autoComplete={autoComplete}
          />
          <InputRightAdornment value={value} helpText={helpText} suffix={suffix} size={size} />
        </div>
      </Tooltip>
      {hintText ? (
        <div className={styles[size].marginTop}>
          <div>
            <Typography
              text={hintText}
              color={isDanger ? Color.Danger : undefined}
              size={Size.XSmall}
            />
          </div>
        </div>
      ) : null}
    </div>
  )
}

interface InputRightAdornmentProps {
  value: InputProps["value"]
  helpText?: string
  suffix?: React.ReactNode
  size?: InputProps["size"]
}

const InputRightAdornment = ({
  value,
  helpText,
  suffix,
  size = "medium",
}: InputRightAdornmentProps) => {
  const suffixClassName = () => {
    if (suffix === "%") {
      if (isNil(value) || value === "") {
        return "invisible"
      }
      const leftPadding = Math.min(
        value.toString().length * 2 + 2 - (value.toString().includes(".") ? 1 : 0),
        20
      )
      return `left-${leftPadding} text-neutral-700`
    }
    return "right-0 text-neutral-800"
  }

  if (!helpText && !suffix) {
    return null
  }

  return (
    <div className={classNames("absolute inset-y-0 flex items-center left-", suffixClassName())}>
      {helpText ? (
        <Tooltip title={helpText}>
          <div className={styles[size].paddingX}>
            <HelpIcon />
          </div>
        </Tooltip>
      ) : null}
      {suffix}
    </div>
  )
}
