import { Left, Right } from "common/containers/Either"
import { Navigate } from "react-router-dom"
import { Routes } from "src/Routes/Routes"
import { AuthCondition } from "../../../Routes/auth/AuthCondition"
import { UserAuthContext } from "../../../Routes/auth/UserAuthContext"
import { hasClientType } from "./ProductAreaConditions"

export const isNonEmployee: AuthCondition<UserAuthContext, UserAuthContext> = (
  ctx: UserAuthContext
) =>
  hasClientType(ctx, "Employee")
    ? Left(() => <Navigate to={Routes.insufficientPermissions} />)
    : Right(ctx)
