import { LoadingOrderTermSheetRow } from "./OrderTermSheetRow"
import { OrderTermSheetSectionProps } from "./OrderTermSheetSection"
import { TitleWrapper } from "./TitleWrapper"
import { Wrapper } from "./Wrapper"

export const LoadingTermsSheetSection = ({
  title,
  numRows,
}: Pick<OrderTermSheetSectionProps, "title"> & { numRows: number }) => (
  <Wrapper>
    <TitleWrapper>{title}</TitleWrapper>
    {Array.from({ length: numRows }, (_, i) => (
      <LoadingOrderTermSheetRow key={i} />
    ))}
  </Wrapper>
)
