import { Button, ButtonVariant } from "@stories/components/Button/Button"
import { User } from "common/model/User"
import { DocumentSubmission, DocumentType } from "common/model/files/DocumentSubmission"
import { Maybe } from "common/containers/Maybe"
import { ReactElement, useState } from "react"
import { useFirebaseWriter } from "../../firebase/Context"
import { createDocumentSubmission } from "../../firebase/Firebase/data-product/DocumentSubmission"
import { DocumentSnapshot } from "../../firebase/Firebase/utils"

export interface UploadButtonProps {
  files?: File[]
  text: string
  filePath?: string
  user?: User
  documentDate?: Date
  documentType: DocumentType
  afterUpload?: (doc: Maybe<DocumentSnapshot<DocumentSubmission>>) => Promise<void>
  variant?: ButtonVariant
  rightIcon?: ReactElement
  disabled?: boolean
}

export const UploadButton: React.FC<React.PropsWithChildren<UploadButtonProps>> = ({
  files,
  text,
  filePath,
  user,
  documentDate,
  documentType,
  variant = "primary",
  afterUpload = () => Promise.resolve(),
  rightIcon,
  disabled = false,
}: UploadButtonProps) => {
  const db = useFirebaseWriter()
  const [loading, setLoading] = useState(false)

  const finalizeUpload = (path: string) => async () => {
    if (user !== undefined) {
      setLoading(true)
      await db
        .account(user.account.id)
        .get()
        .then((account) => account.data())
        .then((account) => account || Promise.reject())
        .then(
          (account) =>
            createDocumentSubmission(db)(user, path, account, documentDate, documentType),
          () => Promise.reject()
        )
        .then(afterUpload)
        .finally(() => setLoading(false))
    }
  }
  return (
    <Button
      isLoading={loading}
      variant={variant}
      label={text}
      size="large"
      isDisabled={
        disabled ||
        filePath === undefined ||
        files === undefined ||
        !files.length ||
        user === undefined
      }
      rightIcon={rightIcon}
      onClick={() => {
        if (files !== undefined && files.length && filePath !== undefined) {
          files.forEach((upload) => {
            db.uploadFile(upload, filePath, finalizeUpload(`${filePath}/${upload.name}`))
          })
        } // Should eventually add a path type instead of working with raw strings
      }}
    />
  )
}
