import _ from "lodash"
import * as zod from "zod"
import { Right } from "../containers/Either"
import {
  Validator,
  enumValidator,
  isoDateValidator,
  isoDateWithTimeValidator,
  numberValidator,
} from "../utils/Validator"
import { SimplePrism } from "../utils/fp/optics/Prism"
import { CompanyIdFields } from "./Company"
import { CompanyFundingRound } from "./Company/FundingRound"
import { RollupPeriod } from "./CompanyMarketHistorySummary"
import {
  PriceEstimateSnapshotPeriod,
  priceEstimateSnapshotPeriods,
} from "./CompanyPriceEstimateSummary"
import { Structure } from "./data-product/pricing/TransactionStructure"
import { DateISOString } from "./postgres/PostgresCommon"
import { BidOrOffer } from "./DeprecatedOrder"

export const ALL_SECTORS = "All sectors"

export const viewSectorSummaryRelevantRoundFields = (
  fr: CompanyFundingRound
): Pick<CompanyFundingRound, "date" | "pps" | "amount" | "valuation" | "roundName"> =>
  _.pick(fr, ["date", "pps", "amount", "valuation", "roundName"])

export type FundingRoundSummaryForPeriod = {
  endOfPeriodDate: Date
  roundCount: number
  averageValuation: {
    value: number | null // null if not available
    roundsWithAvailableDataCount: number
  }
  averageStepUpFromPreviousRound: {
    value: number | null // null if not available
    roundsWithAvailableDataCount: number
  }
  averageAmount: {
    value: number | null // null if not available
    roundsWithAvailableDataCount: number
  }
  averageDaysFromLastRound: {
    value: number | null // null if not available
    roundsWithAvailableDataCount: number
  }
  companies: CompanyIdFields[]
}

export type MarketSummaryForPeriod = {
  endOfPeriodDate: Date
  bids: {
    countLive: number
    amountLive: number
  }
  offers: {
    countLive: number
    amountLive: number
  }
  trades: {
    count: number
    amount: number
  }
}

export type GoingPublicSummaryForPeriod = {
  endOfPeriodDate: Date
  roundCount: number
  averageValuation: {
    value: number | null // null if not available
    roundsWithAvailableDataCount: number
  }
  averageStepUpFromPreviousRound: {
    value: number | null // null if not available
    roundsWithAvailableDataCount: number
  }
  averagePerformanceToDate: {
    value: number | null // null if not available
    roundsWithAvailableDataCount: number
  }
  companies: CompanyIdFields[]
}

export type PriceIndexEstimateRecords = Record<
  PriceEstimateSnapshotPeriod,
  HistoricalSectorPriceIndexEstimate | null
> & { currentPrice: HistoricalSectorPriceIndexEstimate | null }

export type FinancialReportingRecords = Record<
  PriceEstimateSnapshotPeriod,
  HistoricalFinancialSnapshots | null
> & { currentPrice: HistoricalFinancialSnapshots | null }

export type Sector = {
  id?: string | undefined
  sectorMetaData: {
    name: string
    path: string
    // potentially TODO: sector level: lowest level or an agg
  }
  sizeStatistics: {
    companiesCount: {
      total: number
      public: number
      private: number
    }
    showOnCompaniesTableCompaniesCount: {
      total: number
      public: number
      private: number
    }
    companiesWithOrdersCount: {
      total: number
      public: number
      private: number
    }
    companiesWithTradesCount: {
      total: number
      public: number
      private: number
    }
  }
  fundingRoundsByMonths: FundingRoundSummaryForPeriod[]
  goingPublicByMonths: GoingPublicSummaryForPeriod[]
  marketHistorySummary: SectorMarketHistorySummary

  priceEstimateSummary: PriceIndexEstimateRecords
  publicCompsSummary: PriceIndexEstimateRecords
  publicCompsFinancialsSummary: FinancialReportingRecords
  marketHistoryByMonths: MarketSummaryForPeriod[]

  lastUpdated: Date
}

export const rollupToPriceSummary: Record<RollupPeriod, PriceEstimateSnapshotPeriod> = {
  past7Days: "_1WeekAgoPrice",
  past30Days: "_1MonthAgoPrice",
  past90Days: "_3MonthsAgoPrice",
  past180Days: "_6MonthsAgoPrice",
  pastYear: "_1YearAgoPrice",
  past2Years: "_2YearsAgoPrice",
}

export interface HistoricalSectorPriceIndexEstimate {
  date: Date
  indexValue: number
  /** This field is not available in the final object */
  constituents: { company: CompanyIdFields; companyPriceEstimate: number }[]
}

export const publicCompsFinancialIndicators = [
  "Current price to trailing 12m revenue",
  "Net profit margin",
] as const
export type PublicCompsFinancialIndicator = (typeof publicCompsFinancialIndicators)[number]
export interface HistoricalFinancialSnapshotData {
  indicator: PublicCompsFinancialIndicator
  averageValue: number | null
  tickers: string[]
  numberOfDataPoints: number
}

export interface HistoricalFinancialSnapshots {
  date: Date
  data: HistoricalFinancialSnapshotData[]
}

export interface HistoricalFinancialSnapshotsForSkeletonData {
  indicator: "Current price to trailing 12m revenue" | "Net profit margin"
  values: { ticker: string; value: number }[]
}

export interface HistoricalFinancialSnapshotsForSkeleton {
  date: Date
  data: HistoricalFinancialSnapshotsForSkeletonData[]
}

export type SectorMarketHistorySummary = Record<
  RollupPeriod,
  HistoricalSectorMarketSummaryRollup
> & { updatedAt: Date }

export interface SectorTradeHistoryRollup {
  tradeCount: number
  tradeVolume: number
  structuresTraded: Structure[]
  previousComparablePeriod: {
    tradeCount: number
    tradeVolume: number
    structuresTraded: Structure[]
  }
}

export interface SectorOrderHistoryRollup {
  orderType: BidOrOffer
  orderCount: number
  orderVolume: number
  previousComparablePeriod: {
    orderCount: number
    orderVolume: number
  }
}

export interface SectorGoingPublicHistoryRollup {
  publicOfferingsCount: number
  averageTargetValuation: {
    value: number | null // null if not available
    roundsWithAvailableDataCount: number
  } // this is the IPO price, not end of day price which can be significantly different
  averageDaysFromLastRound: {
    value: number | null // null if not available
    roundsWithAvailableDataCount: number
  }
  averageStepUpFromPreviousRound: {
    value: number | null // null if not available
    roundsWithAvailableDataCount: number
  }
}

export interface SectorFundingRoundHistoryRollup {
  fundingRoundsCount: number
  averageValuation: {
    value: number | null // null if not available
    roundsWithAvailableDataCount: number
  }
  averageAmountRaised: {
    value: number | null // null if not available
    roundsWithAvailableDataCount: number
  }
  averageDaysFromLastRound: {
    value: number | null // null if not available
    roundsWithAvailableDataCount: number
  }
  averageStepUpFromPreviousRound: {
    value: number | null // null if not available
    roundsWithAvailableDataCount: number
  }
}

export interface HistoricalSectorMarketSummaryRollup {
  tradeHistoryRollup: SectorTradeHistoryRollup
  bidHistoryRollup: SectorOrderHistoryRollup
  offerHistoryRollup: SectorOrderHistoryRollup
  goingPublicHistoryRollup: SectorGoingPublicHistoryRollup
  fundingRoundHistoryRollup: SectorFundingRoundHistoryRollup
}

export const sectorPrism: SimplePrism<Sector, Sector> = {
  preview: (s) => Right(s),
  review: (s) => s,
}

export type PublicCompsPerformance = {
  [ticker: string]: {
    snapshotPeriod: PriceEstimateSnapshotPeriod | "currentPrice"
    date: Date
    company: CompanyIdFields
    companyPriceEstimate: number
  }[]
}

export type PublicCompsFinancials = {
  [ticker: string]: {
    snapshotPeriod: PriceEstimateSnapshotPeriod | "currentPrice"
    date: DateISOString
    data: { indicator: PublicCompsFinancialIndicator; value: number }[]
  }[]
} | null

export const validatePublicCompsRollups: Validator<
  unknown,
  {
    snapshotPeriod: PriceEstimateSnapshotPeriod | "currentPrice"
    date: DateISOString
    marketCap: number
  }
> = Validator.fromRecord({
  marketCap: numberValidator.validate,
  date: isoDateWithTimeValidator.validate,
  snapshotPeriod: enumValidator<PriceEstimateSnapshotPeriod | "currentPrice">(
    ...priceEstimateSnapshotPeriods,
    "currentPrice"
  ).validate,
})

export const publicCompsFinancialsModel = zod.object({
  snapshotPeriod: zod.enum([...priceEstimateSnapshotPeriods, "currentPrice"]),
  date: zod.string().refine((x) => isoDateValidator.validate(x)),
  data: zod
    .object({ indicator: zod.enum(publicCompsFinancialIndicators), value: zod.number() })
    .array(),
})
