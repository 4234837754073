import { Button } from "@stories/components/Button/Button"
import { Typography } from "@stories/components/Typography/Typography"
import { AllAccountsCacheFields } from "common/model/Account"
import { formatDateToYYYYMMDD } from "common/utils/dateUtils"

export const getTradingRegistrationAdminTableString = (account: AllAccountsCacheFields) =>
  `${account.tradingRegistration?.status ?? "N/A"}${
    account.tradingRegistration?.approvedDate
      ? ` - ${formatDateToYYYYMMDD(account.tradingRegistration.approvedDate)}`
      : ""
  }`

export const AdminTradingRegistrationTableStatus = ({
  account,
  onClick,
}: {
  account: AllAccountsCacheFields
  onClick: (a: AllAccountsCacheFields) => void
}) => (
  <div className="flex gap-1 items-center">
    <Typography text={getTradingRegistrationAdminTableString(account)} />
    <Button label="View" size="xs" onClick={() => onClick(account)} />
  </div>
)
