import moment from "moment"
import { AccountIdFields } from "../../Account"
import { Company, CompanyIdFields } from "../../Company"
import { SerializedOrderFilterFormula } from "./OrderFilter"

type OpportunityInboxFilterRuleBase = {
  account: AccountIdFields
  createdAt: Date
  expiresAt?: Date | null
  active: boolean
  formula: SerializedOrderFilterFormula
}
export const globalTogglesRuleTags = [
  "global show bids",
  "global show offers",
  "global show watchlist",
  "global hide live market",
] as const
export type GlobalToggleRuleTag = (typeof globalTogglesRuleTags)[number]
export type DirectionalGlobalRuleTag = Extract<
  GlobalToggleRuleTag,
  "global show bids" | "global show offers"
>
export type ConstructedOpportunityInboxFilterRule = OpportunityInboxFilterRuleBase &
  (
    | {
        tag: "company"
        company: Exclude<CompanyIdFields, Company>
      }
    | {
        tag: "global" | Exclude<GlobalToggleRuleTag, DirectionalGlobalRuleTag>
      }
    | {
        tag: DirectionalGlobalRuleTag
      }
  )
export type OpportunityInboxFilterRule = { id: string } & ConstructedOpportunityInboxFilterRule

export const isOpportunityInboxFilterRuleApplicableToDate = (
  date: Date,
  orderRule: OpportunityInboxFilterRuleBase
) => !orderRule.expiresAt || moment(orderRule.expiresAt).isAfter(date)
