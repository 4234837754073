import { Date_ } from "../dateUtils"

export class NaiveCache<K, V> {
  private cache: Map<K, { value: V; date: Date }>

  constructor() {
    this.cache = new Map<K, { value: V; date: Date }>()
  }

  get = (key: K, newerThan: Date = Date_.minValue): V | undefined => {
    const val = this.cache.get(key)
    return val && val.date > newerThan ? val.value : undefined
  }

  has = (key: K, newerThan: Date = Date_.minValue) => this.get(key, newerThan) !== undefined

  set = (key: K, value: V) => {
    this.cache.set(key, { value, date: new Date() })
  }
}
