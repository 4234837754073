import { useMemo } from "react"
import { CompanyFilterSearchProp, filterContacts } from "./shared"
import { Color, Size, Typography } from "@stories/components/Typography/Typography"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { Button } from "@stories/components/Button/Button"

export const CompanyFilterWarning = ({
  onSetCompanyFilterTypes,
  allContacts,
  searchString,
  companyFilter,
  viewOptions,
}: {
  onSetCompanyFilterTypes: (types: CompanyFilterSearchProp["type"]) => void
  allContacts: DealCRMContact[]
  searchString: string
  companyFilter: CompanyFilterSearchProp | undefined
  viewOptions: { hideFirms?: boolean; hideIndividuals?: boolean }
}) => {
  const hiddenContactsWarning = useMemo(() => {
    const contactsFromSearchStringNotInCurrentFilter =
      filterContacts(allContacts, { searchString, companyFilter: undefined }, viewOptions).length -
      filterContacts(allContacts, { searchString, companyFilter }, viewOptions).length

    return companyFilter
      ? ` ${
          contactsFromSearchStringNotInCurrentFilter > 0
            ? `Show ${contactsFromSearchStringNotInCurrentFilter} hidden contacts`
            : ""
        }`
      : null
  }, [allContacts, companyFilter, searchString, viewOptions])

  return (
    <>
      {companyFilter ? (
        <div className=" flex flex-col gap-1 items-center">
          <Typography
            size={Size.XSmall}
            color={Color.Subtitle}
            text={`Filtered to contacts with interest in ${companyFilter.company.name}`}
          />
          {hiddenContactsWarning ? (
            <Button
              label={hiddenContactsWarning}
              variant="hollow-link"
              onClick={() => onSetCompanyFilterTypes([])}
              size="small"
            />
          ) : null}
        </div>
      ) : null}
    </>
  )
}
