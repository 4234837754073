import { formatShareAsPercent } from "@components/displays/numeric/PercentageList"
import { Company, CompanyWithPostgres } from "common/model/Company"
import { CompanyMarketHistorySummary } from "common/model/CompanyMarketHistorySummary"
import { companySummaryToPriceEstimateSummary } from "common/model/CompanyPriceEstimateSummary"
import { valuationFromPricePerShareInformation } from "common/model/data-product/valuationCalculation/Valuation"
import {
  CaplightPriceEstimate,
  CaplightPriceEstimateMetaFromPostgres,
  CaplightPriceEstimateQualityScore,
  attributionToHigherLevelSummary,
  isMetaSuccess,
} from "common/model/postgres/PostgresCaplightPriceEstimate"
import { MANUALLY_CONFIRMED } from "common/model/postgres/PostgresCompanyComp"
import { ArrayDataRequest } from "common/utils/ArrayDataRequest"
import { Loading, deprecatedIsLoaded, isLoading } from "common/utils/Loading"
import { UnsafeRec } from "common/utils/RecordUtils"
import { isDefined } from "common/utils/TypeUtils"
import _ from "lodash"
import { TypedUseSelectorHook, useSelector } from "react-redux"
import { createSelector } from "reselect"
import { unserializeCompany } from "./actions/postgresData"
import { companyIdsCheck } from "./business_logic/postgresData"
import { CompanyIds, PublicCompsIndexData } from "./model/postgresData"
import type { RootState } from "./store"

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

// Various selectors
export const targetIssuersSelector = () =>
  createSelector(
    (state: RootState) => state.postgresData.targetIssuers,
    (targetIssuers): CompanyWithPostgres[] => {
      if (targetIssuers === "loading" || !targetIssuers) return <CompanyWithPostgres[]>[]
      return targetIssuers
    }
  )

export const isAppLoadingSelector = createSelector(
  (state: RootState) => state.appStates.loading,
  (loading) => loading
)

export const publicCompsIndexSelector = (
  postgresId: string,
  dataTransformer?: (input: PublicCompsIndexData[]) => PublicCompsIndexData[]
) =>
  createSelector(
    (state: RootState) => state.postgresData.publicCompsIndices[postgresId],
    (data) => {
      const transformFunction = (input: PublicCompsIndexData[] | "loading" | undefined) => {
        if (input === "loading" || !input) return input
        if (dataTransformer) {
          return dataTransformer(input)
        }
        return input
      }

      return transformFunction(data)
    }
  )

/** @deprecated */
export const companiesStatusSelector = () =>
  createSelector(
    (state: RootState) => state.postgresData.companyStatus,
    (data) => data
  )
export const compsListsAllSelector = (postgresId: string, type: "direct" | "reverse") =>
  createSelector(
    (state: RootState) => state.postgresData.compsLists[type][postgresId],
    (data) => (data === undefined ? "loading" : data)
  )

/**
 *
 * @returns a filtered list of comps that only contains listed companies
 * and reviewed/confirmed comps (rather than the longlist)
 */
export const publicConfirmedCompsSelector = (postgresId: string, type: "direct" | "reverse") =>
  createSelector(
    (state: RootState) => state.postgresData.compsLists[type][postgresId],
    (data) =>
      data === undefined || data === "loading"
        ? "loading"
        : data.filter(
            (row) =>
              row.ticker !== undefined && row.ticker !== "None" && row.status === MANUALLY_CONFIRMED
          )
  )

/**
 *
 * @returns a filtered list of comps that only contains listed companies
 * and reviewed/confirmed comps (rather than the longlist)
 */
export const publicOrHasMarketPriceConfirmedCompsSelector = (
  postgresId: string,
  type: "direct" | "reverse"
) =>
  createSelector(
    (state: RootState) => state.postgresData.compsLists[type][postgresId],
    (data) =>
      data === undefined || data === "loading"
        ? "loading"
        : _.orderBy(
            data.filter(
              (row) => (row.ticker || row.hasMarketPrice) && row.status === MANUALLY_CONFIRMED
            ),
            ({ companyName, ticker, timestampAdded }) =>
              ticker
                ? `${1e14 - timestampAdded}_1_${companyName}`
                : `${1e14 - timestampAdded}_0_${companyName}`
          )
  )

/**
 *
 * @returns a filtered list of comps that only contains American listed
 * companies (non-American companies don't have options) and
 * reviewed/confirmed comps (rather than the longlist)
 */
export const publicAmericanConfirmedCompsSelector = (
  postgresId: string,
  type: "direct" | "reverse"
) =>
  createSelector(
    (state: RootState) => state.postgresData.compsLists[type][postgresId],
    (data) =>
      data === undefined || data === "loading"
        ? "loading"
        : data.filter(
            (row) =>
              !!row.ticker &&
              row.ticker !== "None" &&
              row.status === MANUALLY_CONFIRMED &&
              String(row.ticker).indexOf(".") === -1
          )
  )

export const compsSummaryData = (postgresId: string, type: "direct" | "reverse") =>
  createSelector(
    (state: RootState) => ({
      comps: state.postgresData.compsLists[type][postgresId],
      tableSummaryData: state.postgresData.currentCompsSummaryData,
    }),
    (data) => {
      if (data.comps === undefined) {
        return undefined
      }
      if (data.comps === "loading") {
        return "loading"
      }
      const companyIds = data.comps.map((comp) => comp.companyId)
      return new Map(
        data.tableSummaryData
          .filter((summaryRow) => companyIds.includes(summaryRow.postgresCompanyId))
          .map((summaryRow) => [summaryRow.postgresCompanyId, summaryRow.info])
      )
    }
  )

/*
 * Selecting company directly from Redux is complicated.
 * You probably want useReduxTargetIssuer.
 *
 * If you use this directly, don't forget to unserializeCompany
 */
export const serializedCompanySelector = (ids: CompanyIds) =>
  createSelector(
    (state: RootState) => ({
      data: state.postgresData.targetIssuers,
    }),
    ({ data }): Loading<CompanyWithPostgres> => {
      if (deprecatedIsLoaded(data)) {
        const thisIssuer = data.find(companyIdsCheck(ids))
        if (thisIssuer) return thisIssuer
      }
      if (data === "loading") return "loading"
      return null
    }
  )

export const companySelectorWithUndefined = (ids: CompanyIds) =>
  createSelector(
    (state: RootState) => ({
      data: state.postgresData.targetIssuers,
    }),
    ({ data }) => {
      if (data !== "loading") {
        const candidate = (data || []).find(companyIdsCheck(ids))
        return candidate ? unserializeCompany(candidate) : undefined
      }
      return "loading"
    }
  )

export const fundamentalCompsToChartSelector = (postgresId: string) =>
  createSelector(
    (state: RootState) => state.issuers.toChart,
    (data) => data.fundamentals[postgresId] || []
  )

export const compsToChartSelector = (
  postgresId: string,
  analysisType: "fundamentals" | "volatility"
) =>
  createSelector(
    (state: RootState) => state.issuers.toChart,
    (data) => data[analysisType][postgresId] || []
  )

export const volatilityCompsToChartSelector = (postgresId: string) =>
  createSelector(
    (state: RootState) => state.issuers.toChart,
    (data) => data.volatility[postgresId] || []
  )

export const volatilityCompsToChartSelectorWithUndefined = (postgresId?: string) =>
  createSelector(
    (state: RootState) => state.issuers.toChart,
    (data) => (postgresId === undefined ? undefined : data.volatility[postgresId])
  )

export const caplightPriceEstimateFromPostgresSelector = (postgresId: string, isAdmin: boolean) =>
  createSelector(
    (state: RootState) => ({
      data: state.postgresData.caplightPriceEstimateFromPostgres,
      models: state.postgresData.caplightPriceEstimateModelVersion,
    }),
    (data): Loading<readonly CaplightPriceEstimate[]> =>
      data.models[postgresId] === "20220901" || isAdmin ? data.data[postgresId] : []
    // the default model version we use is 20220901, otherwise it's a fund-marks-based marketprice. for now we keep it admin-only
  )

export const multiCaplightPriceEstimateFromPostgresSelector = (
  postgresIds: string[],
  isAdmin: boolean
) =>
  createSelector(
    (state: RootState) => ({
      data: state.postgresData.caplightPriceEstimateFromPostgres,
      models: state.postgresData.caplightPriceEstimateModelVersion,
    }),
    (data): Map<string, Loading<readonly CaplightPriceEstimate[]>> => {
      const out: Map<string, Loading<readonly CaplightPriceEstimate[]>> = new Map()

      postgresIds.forEach((postgresId) => {
        const toUse = data.models[postgresId] === "20220901" || isAdmin
        if (toUse) out.set(postgresId, data.data[postgresId])
      })
      return out
    }
  )

export const caplightPriceEstimateModelVersionOk = (postgresId: string) =>
  createSelector(
    (state: RootState) => state.postgresData.caplightPriceEstimateModelVersion,
    (data): "main_version" | "alternative_version" | "missing" =>
      data[postgresId] === undefined
        ? "missing"
        : data[postgresId] === "20220901"
        ? "main_version"
        : "alternative_version"
  )

export const caplightPriceEstimateFromPostgresSelectorAlternativeModel = (postgresId: string) =>
  createSelector(
    (state: RootState) => state.postgresData.caplightPriceEstimateFromPostgresAlternativeModel,
    (data): Loading<readonly CaplightPriceEstimate[]> =>
      !data ? null : isLoading(data) ? "loading" : data[postgresId]
  )

export const caplightPriceEstimateMetaFromPostgresSelector = (postgresId: string) =>
  createSelector(
    (state: RootState) => state.postgresData.caplightPriceEstimateMetaFromPostgres,
    (data): Loading<CaplightPriceEstimateMetaFromPostgres> =>
      !data ? null : isLoading(data) ? "loading" : data[postgresId]
  )

export const allCaplightPriceEstimateFromPostgresSelector = () =>
  createSelector(
    (state: RootState) => state.postgresData.caplightPriceEstimateFromPostgres,
    (data) => data
  )

export const allCaplightPriceEstimateMetaFromPostgresSelector = () =>
  createSelector(
    (state: RootState) => state.postgresData.caplightPriceEstimateMetaFromPostgres,
    (data) => data
  )

export const availableCaplightPriceEstimateModels = () =>
  createSelector(
    (state: RootState) => state.postgresData.availablePriceEstimateModels,
    (data) => data
  )

export const mutualFundMarksSelector = (postgresId: string) =>
  createSelector(
    (state: RootState) => state.postgresData.mutualFundMarks,
    (data) => data[postgresId] || null
  )

export const thematicIndicesSelector = () =>
  createSelector(
    (state: RootState) => state.postgresData.thematicIndices,
    (data) => data
  )

export const priceEstimateSelector = () =>
  createSelector(
    (state: RootState) => state.postgresData.thematicIndices,
    (data) => data
  )

export const caplightPriceEstimateFixingSelector = (postgresId: string) =>
  createSelector(
    (state: RootState) => state.postgresData.priceEstimateMonthlyFixing,
    (data): ArrayDataRequest<CaplightPriceEstimate> => {
      const selData = data[postgresId]
      if (!selData) return "not requested yet"
      return selData
    }
  )

export const caplightPriceEstimateQualityScore = (postgresId: string) =>
  createSelector(
    (state: RootState) => state.postgresData.caplightPriceEstimateScores,
    (data): Loading<CaplightPriceEstimateQualityScore[]> => {
      const selData = data[postgresId]
      return selData || null
    }
  )

export const topDriversOfMarketPrice = (postgresId: string) =>
  createSelector(
    (state: RootState) => state.postgresData.caplightPriceEstimateMetaFromPostgres,
    (data) => {
      if (!deprecatedIsLoaded(data)) return data
      const selData = data[postgresId]
      if (!deprecatedIsLoaded(selData)) return selData
      if (!isMetaSuccess(selData)) return null
      const { attribution_summary } = selData
      if (!attribution_summary) return null
      return _.orderBy(
        UnsafeRec.entries(attributionToHigherLevelSummary(attribution_summary)).map(([k, v]) => ({
          key: k,
          value: formatShareAsPercent(v, 1),
        })),
        "value",
        "desc"
      )
    }
  )

export const driversOfMarketPrice = (postgresId: string) =>
  createSelector(
    (state: RootState) => state.postgresData.caplightPriceEstimateMetaFromPostgres,
    (data) => {
      if (!deprecatedIsLoaded(data)) return data
      const selData = data[postgresId]
      if (!deprecatedIsLoaded(selData)) return selData
      if (!isMetaSuccess(selData)) return null
      const { attribution_summary } = selData
      if (!attribution_summary) return null
      return attributionToHigherLevelSummary(attribution_summary)
    }
  )

// TODO DRY
export const mostActiveBidCompanies = (
  metric: "byUSD" | "byCount",
  timeFrame: keyof Omit<CompanyMarketHistorySummary, "updatedAt">,
  numberOfIssuers: number = 10
) =>
  createSelector(
    (state: RootState) => ({
      allIssuers: state.postgresData.targetIssuers,
      isRequesting: state.postgresData.caplightDataCompaniesRequestStatus,
      summarizedData: state.postgresData.insightsData.companies.most_bids[timeFrame][metric],
    }),
    (data) => {
      const { allIssuers, summarizedData, isRequesting } = data
      if (isRequesting !== true) return "loading"
      if (!deprecatedIsLoaded(summarizedData)) return "loading"
      if (!deprecatedIsLoaded(allIssuers)) return "loading"
      const grouped = _.groupBy(allIssuers, (d) => d.postgresCompanyId)
      return summarizedData
        .map((d) => grouped[d]?.[0])
        .filter(isDefined)
        .splice(0, numberOfIssuers)
    }
  )

export const mostActiveAskCompanies = (
  metric: "byUSD" | "byCount",
  timeFrame: keyof Omit<CompanyMarketHistorySummary, "updatedAt">,
  numberOfIssuers: number = 10
) =>
  createSelector(
    (state: RootState) => ({
      allIssuers: state.postgresData.targetIssuers,
      isRequesting: state.postgresData.caplightDataCompaniesRequestStatus,
      summarizedData: state.postgresData.insightsData.companies.most_asks[timeFrame][metric],
    }),
    (data) => {
      const { allIssuers, summarizedData } = data
      if (data.isRequesting !== true) return "loading"
      if (!deprecatedIsLoaded(summarizedData)) return "loading"
      if (!deprecatedIsLoaded(allIssuers)) return "loading"
      const grouped = _.groupBy(allIssuers, (d) => d.postgresCompanyId)
      return summarizedData
        .map((d) => grouped[d]?.[0])
        .filter(isDefined)
        .splice(0, numberOfIssuers)
    }
  )

export const mostActiveTradedCompanies = (
  metric: "byUSD" | "byCount",
  timeFrame: keyof Omit<CompanyMarketHistorySummary, "updatedAt">,
  numberOfIssuers: number = 10
) =>
  createSelector(
    (state: RootState) => ({
      allIssuers: state.postgresData.targetIssuers,
      isRequesting: state.postgresData.caplightDataCompaniesRequestStatus,
      summarizedData: state.postgresData.insightsData.companies.most_trades[timeFrame][metric],
    }),
    (data) => {
      const { allIssuers, summarizedData } = data

      if (data.isRequesting !== true) return "loading"
      if (!deprecatedIsLoaded(summarizedData)) return "loading"
      if (!deprecatedIsLoaded(allIssuers)) return "loading"

      const grouped = _.groupBy(allIssuers, (d) => d.postgresCompanyId)

      // eslint-disable-next-line rulesdir/mutating-array-methods
      return summarizedData
        .map((d) => grouped[d]?.[0])
        .filter(isDefined)
        .splice(0, numberOfIssuers)
    }
  )

const higherLevelIndustry = (x: string) => x.split(":")[0]

export const sectorPerformanceTreeMap = (
  timeFrame: keyof Omit<CompanyMarketHistorySummary, "updatedAt">
) =>
  createSelector(
    (state: RootState) => ({
      targetIssuers: state.postgresData.targetIssuers,
      requested1: state.postgresData.caplightDataCompaniesRequestStatus,
    }),
    (data) => {
      const { targetIssuers } = data
      if (data.requested1 !== true) return "loading"
      if (!deprecatedIsLoaded(targetIssuers)) return "loading"
      const timeKey = companySummaryToPriceEstimateSummary[timeFrame]
      const companyInfo = _.orderBy(
        targetIssuers
          .filter(
            (issuer) =>
              issuer.status === "Private" &&
              !!issuer.settings?.showCaplightPriceEstimate &&
              issuer.industry?.caplightPrimaryIndustry &&
              issuer?.priceEstimatesSummary?.currentPrice?.priceEstimatePPS &&
              issuer?.priceEstimatesSummary?.[timeKey]?.priceEstimatePPS !== undefined
          )
          .map((company) => ({
            company,
            pastPrice: company?.priceEstimatesSummary?.[timeKey]?.priceEstimatePPS || 0,
            currentPrice: company?.priceEstimatesSummary?.currentPrice?.priceEstimatePPS || 0,
            name: company.name,
            industry: higherLevelIndustry(company.industry?.caplightPrimaryIndustry || ""),
            valuation: valuationFromPricePerShareInformation(
              {
                price: company?.priceEstimatesSummary?.currentPrice?.priceEstimatePPS || 0,
                priceDate: company?.priceEstimatesSummary?.currentPrice?.date || new Date(),
                isSplitAdjusted: true,
                splitAdjustedForDate:
                  company?.priceEstimatesSummary?.currentPrice?.date || new Date(),
              },
              company
            ),
          }))
          .map((summary) => ({
            company: summary.company,
            priceChange: summary.currentPrice / summary.pastPrice - 1,
            name: summary.name,
            industry: summary.industry,
            valuation: summary.valuation.match(
              (x) => x.valuation,
              () => 0
            ),
          }))
          .filter((s) => s.valuation > 0),
        ["valuation"],
        ["desc"]
      )
      return companyInfo
    }
  )

export const mostPriceMoverCompanies = (
  timeFrame: keyof Omit<CompanyMarketHistorySummary, "updatedAt">,
  insight: "positive_price_moves" | "negative_price_moves",
  numberOfIssuers: number = 10
) =>
  createSelector(
    (state: RootState) => ({
      allIssuers: state.postgresData.targetIssuers,
      isRequesting: state.postgresData.caplightDataCompaniesRequestStatus,
      summarizedPositiveData:
        state.postgresData.insightsData.companyPrices.positivePriceChanges[timeFrame],
      summarizedNegativeData:
        state.postgresData.insightsData.companyPrices.negativePriceChanges[timeFrame],
    }),
    (data) => {
      const { allIssuers, summarizedPositiveData, summarizedNegativeData } = data

      if (data.isRequesting !== true) return "loading"
      if (
        !deprecatedIsLoaded(summarizedPositiveData) ||
        !deprecatedIsLoaded(summarizedNegativeData)
      )
        return "loading"
      if (!deprecatedIsLoaded(allIssuers)) return "loading"

      const grouped = _.groupBy(allIssuers, (d) => d.postgresCompanyId)

      return (insight === "positive_price_moves" ? summarizedPositiveData : summarizedNegativeData)
        .map((d) => grouped[d]?.[0])
        .filter(isDefined)
        .splice(0, numberOfIssuers)
    }
  )

export interface MostViewsRow {
  company: Company | CompanyWithPostgres
  id: string
  viewsPercent: number
  sessions: number
}

const isMostViewsRow = (d: {
  company?: Company | CompanyWithPostgres
  id?: string
  viewsPercent: number
}): d is MostViewsRow => !!d.company && !!d.id
export const mostViewedCompanies = (
  timeFrame: keyof Omit<CompanyMarketHistorySummary, "updatedAt">,
  numberOfIssuers: number = 20
) =>
  createSelector(
    (state: RootState) => ({
      allIssuers: state.postgresData.targetIssuers,
      isRequesting: state.postgresData.caplightDataCompaniesRequestStatus,
      summarizedData: state.postgresData.insightsData.companyMetrics.mostViewed[timeFrame],
    }),
    (data): Loading<MostViewsRow[]> => {
      const { allIssuers, summarizedData, isRequesting } = data
      if (isRequesting !== true) return "loading"
      if (!deprecatedIsLoaded(summarizedData)) return "loading"
      if (!deprecatedIsLoaded(allIssuers)) return "loading"
      const grouped = _.groupBy(allIssuers, (d) => d.postgresCompanyId)
      return summarizedData
        .map((sum) => ({
          company: grouped[sum.companyPostgresId]?.[0],
          viewsPercent: sum.viewsPercent,
          sessions: sum.sessions,
          id: grouped[sum.companyPostgresId]?.[0].id,
        }))
        .filter(isMostViewsRow)
        .splice(0, numberOfIssuers)
    }
  )

export const mostRelativeBidAskCompanies = (
  insight: "most_relative_bids" | "most_relative_asks",
  metric: "byUSD" | "byCount",
  timeFrame: keyof Omit<CompanyMarketHistorySummary, "updatedAt">,
  numberOfIssuers: number = 10
) =>
  createSelector(
    (state: RootState) => ({
      allIssuers: state.postgresData.targetIssuers,
      isRequesting: state.postgresData.caplightDataCompaniesRequestStatus,
      summarizedData: state.postgresData.insightsData.companies[insight][timeFrame][metric],
    }),
    (data) => {
      const { allIssuers, summarizedData, isRequesting } = data
      if (isRequesting !== true) return "loading"
      if (!deprecatedIsLoaded(summarizedData)) return "loading"
      if (!deprecatedIsLoaded(allIssuers)) return "loading"
      const grouped = _.groupBy(allIssuers, (d) => d.postgresCompanyId)
      return summarizedData
        .map((d) => grouped[d]?.[0])
        .filter(isDefined)
        .splice(0, numberOfIssuers)
    }
  )
