import {
  AccountAccessFields,
  isActiveTrialCustomer,
  isGroupAccount,
  isTrialCustomer,
  isTrialExpired,
} from "../Account"
import { isDataCustomer, isMarketsCustomer } from "../Auth/Permissions"
import { AccessControlTier } from "./AccessControlTier"

export const getAccessControlTierForAccount = (account: AccountAccessFields): AccessControlTier => {
  if (account.accessControlTier) {
    return account.accessControlTier
  }

  if (account.clientType.includes("Journalist")) {
    return "journalist"
  }

  if (account && isActiveTrialCustomer(account) && !isTrialExpired(account)) {
    return isGroupAccount(account) ? "group-trial" : "individual-trial"
  }

  if (account && isTrialCustomer(account) && isTrialExpired(account)) {
    return "free"
  }

  if (isDataCustomer(account)) {
    return "legacy-data"
  }

  if (isMarketsCustomer(account)) {
    return "legacy-markets"
  }

  return "free"
}
