import _ from "lodash"
import {
  ChartSeriesMetadata,
  RECEIVE_COMPS_TO_CHART,
  SET_COMPARABLES_FILTER,
  ADD_COMPS_TO_CHART,
} from "../actions/issuers"
import { StatusString } from "../model/postgresData"
import { ReducerAction } from "./postgresData"

export const LIMIT_NUMBER_OF_LINES: { fundamentals: number; volatility: number } = {
  fundamentals: 40,
  volatility: 40,
}

interface IssuersState {
  toChart: {
    fundamentals: {
      [companyId: string]: ChartSeriesMetadata[]
    }
    volatility: {
      [companyId: string]: ChartSeriesMetadata[]
    }
  }
}

const initialState: IssuersState = {
  toChart: {
    fundamentals: {},
    volatility: {},
  },
}

export type IssuerAction =
  | ReducerAction<
      typeof RECEIVE_COMPS_TO_CHART,
      {
        companyId: string
        compsToChart: ChartSeriesMetadata[]
        compsAnalysisType: "fundamentals" | "volatility"
      }
    >
  | ReducerAction<
      typeof ADD_COMPS_TO_CHART,
      {
        companyId: string
        compsToChart: ChartSeriesMetadata[]
        compsAnalysisType: "fundamentals" | "volatility"
      }
    >
  | ReducerAction<typeof SET_COMPARABLES_FILTER, { companyStagesFilter: StatusString[] }>

export function issuerReducer(
  state_: IssuersState | undefined,
  action: IssuerAction
): IssuersState {
  const state = state_ ?? initialState
  switch (action.type) {
    case RECEIVE_COMPS_TO_CHART:
      return {
        ...state,
        toChart: {
          ...state.toChart,
          [action.compsAnalysisType]: {
            ...state.toChart[action.compsAnalysisType],
            [action.companyId]: _.takeRight(
              [...action.compsToChart],
              LIMIT_NUMBER_OF_LINES[action.compsAnalysisType]
            ),
          },
        },
      }
    case ADD_COMPS_TO_CHART:
      return {
        ...state,
        toChart: {
          ...state.toChart,
          [action.compsAnalysisType]: {
            ...state.toChart[action.compsAnalysisType],
            [action.companyId]: _.takeRight(
              _.uniqBy(
                [
                  ...(state.toChart[action.compsAnalysisType][action.companyId] || []),
                  ...action.compsToChart,
                ],
                "companyId"
              ),
              LIMIT_NUMBER_OF_LINES[action.compsAnalysisType]
            ),
          },
        },
      }
    default:
      return { ...state }
  }
}

export default issuerReducer
