import { wrapIcon } from "./IconWrapper"

export const ExternalLinkIcon = wrapIcon(
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 7.21333C11.8232 7.21333 11.6536 7.28357 11.5286 7.4086C11.4036 7.53362 11.3333 7.70319 11.3333 7.88V12.6667C11.3333 12.8435 11.2631 13.013 11.1381 13.1381C11.013 13.2631 10.8435 13.3333 10.6667 13.3333H3.33333C3.15652 13.3333 2.98695 13.2631 2.86193 13.1381C2.73691 13.013 2.66667 12.8435 2.66667 12.6667V5.33333C2.66667 5.15652 2.73691 4.98695 2.86193 4.86193C2.98695 4.7369 3.15652 4.66667 3.33333 4.66667H8.12C8.29681 4.66667 8.46638 4.59643 8.5914 4.4714C8.71643 4.34638 8.78667 4.17681 8.78667 4C8.78667 3.82319 8.71643 3.65362 8.5914 3.5286C8.46638 3.40357 8.29681 3.33333 8.12 3.33333H3.33333C2.8029 3.33333 2.29419 3.54405 1.91912 3.91912C1.54405 4.29419 1.33333 4.8029 1.33333 5.33333V12.6667C1.33333 13.1971 1.54405 13.7058 1.91912 14.0809C2.29419 14.456 2.8029 14.6667 3.33333 14.6667H10.6667C11.1971 14.6667 11.7058 14.456 12.0809 14.0809C12.456 13.7058 12.6667 13.1971 12.6667 12.6667V7.88C12.6667 7.70319 12.5964 7.53362 12.4714 7.4086C12.3464 7.28357 12.1768 7.21333 12 7.21333ZM14.6133 1.74667C14.5457 1.58377 14.4162 1.45432 14.2533 1.38667C14.1732 1.35251 14.0871 1.33439 14 1.33333H10C9.82319 1.33333 9.65362 1.40357 9.5286 1.5286C9.40357 1.65362 9.33333 1.82319 9.33333 2C9.33333 2.17681 9.40357 2.34638 9.5286 2.4714C9.65362 2.59643 9.82319 2.66667 10 2.66667H12.3933L5.52667 9.52667C5.46418 9.58864 5.41459 9.66238 5.38074 9.74361C5.34689 9.82485 5.32947 9.91199 5.32947 10C5.32947 10.088 5.34689 10.1751 5.38074 10.2564C5.41459 10.3376 5.46418 10.4114 5.52667 10.4733C5.58864 10.5358 5.66238 10.5854 5.74362 10.6193C5.82486 10.6531 5.91199 10.6705 6 10.6705C6.08801 10.6705 6.17515 10.6531 6.25638 10.6193C6.33762 10.5854 6.41136 10.5358 6.47333 10.4733L13.3333 3.60667V6C13.3333 6.17681 13.4036 6.34638 13.5286 6.4714C13.6536 6.59643 13.8232 6.66667 14 6.66667C14.1768 6.66667 14.3464 6.59643 14.4714 6.4714C14.5964 6.34638 14.6667 6.17681 14.6667 6V2C14.6656 1.91288 14.6475 1.82681 14.6133 1.74667Z"
      fill="currentColor"
    />
  </svg>
)
