import React from "react"
import { NavLink } from "react-router-dom"
import { NavItem } from "src/Routes/NavItems"
import { Routes } from "src/Routes/Routes"
import { checkCanSeeLinks } from "src/Routes/auth"
import { useAuthContext } from "src/Routes/auth/UserAuthContext"
import NewIcon from "../../Badges/NewIcon/NewIcon"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"

interface NavMenuItemProps {
  /**
   * Is the component collapsed to render only the icon?
   */
  isCollapsed: boolean
  /**
   * Is the component new?
   */
  isNew: boolean
  /**
   * Additional Badge to the right of the nav item
   */
  badge?: React.ReactNode
  /**
   * What icon should the component render?
   */
  icon: React.ReactNode
  /**
   * What text should the component display?
   */
  text: string
  /**
   * Which route is this nav item for?
   */
  route: NavItem["route"]
  /**
   * What happens when you click an item?
   */
  onItemClick: () => void
  section: string
}

const NavMenuItem: React.FC<React.PropsWithChildren<NavMenuItemProps>> = ({
  isCollapsed,
  isNew,
  icon,
  text,
  route,
  badge,
  onItemClick,
  section,
}) => {
  const ctx = useAuthContext()
  const isVisible = checkCanSeeLinks(route, {}, ctx).isRight()

  return isVisible ? (
    <NavLink
      to={route(Routes)}
      className={({ isActive }) =>
        `flex items-center rounded-md p-1 gap-2 ${
          isActive ? "bg-neutral-800 hover:bg-neutral-700" : "hover:bg-neutral-800"
        } ${isCollapsed ? "justify-center w-10" : "w-full"}`
      }
      onClick={onItemClick}
      key={text}
    >
      <div
        className={`flex h-5 w-5 justify-center items-center flex-shrink-0 ${
          section === "Controls" && !isCollapsed ? "ml-2" : ""
        }`}
      >
        {icon}
      </div>

      {!isCollapsed && (
        <div className="flex justify-between items-center w-full ml-2">
          <Typography
            size={Size.Small}
            weight={Weight.Regular}
            color={Color.White}
            text={text}
            shouldWrap={false}
          />
          {badge}
          {isNew && <NewIcon />}
        </div>
      )}
    </NavLink>
  ) : null
}

export default NavMenuItem
