import { Just, Maybe, Nothing } from "common/containers/Maybe"
import firebaseApp from "firebase/compat/app"
import * as Sentry from "@sentry/react"
import { isDate, isPlainObject } from "lodash"
import { inQueryCommon } from "common/utils/FirestoreUtils"

export type Query<T> = firebaseApp.firestore.Query<T>
export type QuerySnapshot<T> = firebaseApp.firestore.QuerySnapshot<T>
export type QueryDocumentSnapshot<T> = firebaseApp.firestore.QueryDocumentSnapshot<T>
export type DocumentSnapshot<T> = firebaseApp.firestore.DocumentSnapshot<T>
export type DocumentReference<T = firebaseApp.firestore.DocumentData> =
  firebaseApp.firestore.DocumentReference<T>
export type CollectionReference<T> = firebaseApp.firestore.CollectionReference<T>
export type FirebaseId = string

export const oneOrNone = <T>(results: firebase.default.firestore.QuerySnapshot<T>) =>
  results.empty ? undefined : results.docs[0].data()

export const getIfExists = <T>(result: firebase.default.firestore.DocumentSnapshot<T>) =>
  result.exists ? result.data() : undefined

export const getAllDocs = <T>(results: { docs: { data: () => T | undefined }[] }) =>
  results.docs.map((doc) => doc.data()).flatMap((x) => (x ? [x] : []))

export const betweenDates =
  <T>(query: Query<T>) =>
  (dateField: string, after: Date, before: Date): Query<T> =>
    query.where(dateField, ">", after).where(dateField, "<=", before)

interface Gettable<T> {
  get: () => Promise<QuerySnapshot<T>>
}

export const getDocData = <T>(query: Gettable<T>): Promise<T[]> =>
  query.get().then((result) => result.docs.map((doc) => doc.data()))

type UndefinedHandling = { remove: boolean; log: (s: string[]) => void }

const isProbablyRecord = (x: unknown) => isPlainObject(x) && !isDate(x)

// not actually safe, but slightly safer
export const safeWhere = <T, V>(
  q: Query<T>,
  path: string,
  op: firebaseApp.firestore.WhereFilterOp,
  value: Exclude<V, undefined>
) => q.where(path, op, value)

/** @deprecated */
export const extremelyUnsafeRemoveUndefined = <R extends object>(
  record: R,
  log: (s: string[]) => void = () => {}
): R => {
  if (!isProbablyRecord(record)) {
    return record
  }
  const newObject: Partial<R> = {}
  Object.entries(record).forEach(([k, v]) => {
    if (v !== undefined) {
      // eslint-disable-next-line better-mutation/no-mutation, @typescript-eslint/no-unsafe-assignment
      newObject[k as keyof R] = extremelyUnsafeRemoveUndefined(v, (t) => log([k].concat(t)))
    } else {
      log([k])
    }
  })
  return newObject as R
}

export const addDoc =
  <T>(
    collection: CollectionReference<T>,
    onUndefined: UndefinedHandling = { remove: false, log: () => {} }
  ) =>
  (toAdd: Omit<T, "id">): Promise<Maybe<DocumentSnapshot<T>>> =>
    collection
      .add(
        (onUndefined.remove ? extremelyUnsafeRemoveUndefined(toAdd, onUndefined.log) : toAdd) as T
      ) // TODO: fix this cast
      .then(
        (ref) => ref.get().then((doc) => (doc.data() ? Just(doc) : Nothing)),
        (e) => {
          Sentry.captureException(e)
          return Nothing
        }
      )

export const inQuery = inQueryCommon
