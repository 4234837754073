/* eslint-disable react/no-unstable-nested-components */
import InfoTooltip from "@components/InfoTooltip"
import { Select } from "@stories/components/Antd/Select/Select"
import Divider from "@stories/components/Divider/Divider"
import { Input } from "@stories/components/Inputs/Input/Input"
import PhoneInput from "@stories/components/Inputs/PhoneInput/PhoneInput"
import { Size, Typography } from "@stories/components/Typography/Typography"
import {
  FundAccountTradingRegistration,
  MinimumAccountTradingRegistration,
  numberOfSeatOptions,
} from "common/model/Account/AccountTradingRegistration"
import { Field } from "react-final-form"

export const FundAccountTradingRegistrationForm = ({
  tradingRegistration,
}: {
  tradingRegistration: MinimumAccountTradingRegistration
}) => (
  <>
    <Divider label="Fund Information" shouldWrap={false} />
    <Field<FundAccountTradingRegistration["fundName"]>
      name="fundName"
      render={({ input: { value, onChange } }) => (
        <Input
          value={value}
          onChange={onChange}
          label={`Legal Entity Name of ${tradingRegistration.account.name}`}
          placeholder="e.g. ACME Ventures Inc."
        />
      )}
    />
    <Field<FundAccountTradingRegistration["fundAddress"]>
      name="fundAddress"
      render={({ input: { value, onChange } }) => (
        <Input
          value={value}
          onChange={onChange}
          label="Business Address"
          placeholder="e.g. 1919 Broadway, New York, NY 10023, USA"
        />
      )}
    />
    <Field<FundAccountTradingRegistration["fundCountry"]>
      name="fundCountry"
      render={({ input: { value, onChange } }) => (
        <Input
          value={value}
          onChange={onChange}
          label="Country of Formation"
          placeholder="e.g. USA"
        />
      )}
    />
    <Field<FundAccountTradingRegistration["fundState"]>
      name="fundState"
      render={({ input: { value, onChange } }) => (
        <Input
          value={value}
          onChange={onChange}
          label="State/Province of Formation"
          placeholder="e.g. New York"
        />
      )}
    />
    <Field<FundAccountTradingRegistration["numberOfSeats"]>
      name="numberOfSeats"
      render={({ input: { value, onChange } }) => (
        <div className="flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <Typography
              text="Estimate of number authorized users expected to use the platform"
              size={Size.Small}
            />
            <InfoTooltip text="If you don't know the exact number of individuals, an approximate estimate is fine." />
          </div>
          <Select<FundAccountTradingRegistration["numberOfSeats"]>
            options={numberOfSeatOptions.map((v) => ({ label: v, value: v }))}
            value={value}
            onChange={onChange}
          />
        </div>
      )}
    />

    <Divider label="Confirm Your Personal Information" shouldWrap={false} />
    <Field<FundAccountTradingRegistration["entityName"]>
      name="entityName"
      render={({ input: { value, onChange } }) => (
        <Input value={value} onChange={onChange} label="Full Name" />
      )}
    />
    <Field<FundAccountTradingRegistration["phoneNumber"]>
      name="phoneNumber"
      render={({ input: { value, onChange } }) => (
        <PhoneInput
          value={value}
          onChange={onChange}
          label="Contact Number"
          heapName="trading-registration-phone-input"
        />
      )}
    />
  </>
)
