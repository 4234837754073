import { Loading, isLoaded, mapLoading } from "common/utils/Loading"
import { useEffect, useMemo, useState } from "react"
import { useErrorHandler } from "src/providers/errorHandler"
import * as Sentry from "@sentry/react"
import { getAllDocs, Query } from "src/firebase/Firebase/utils"
import _ from "lodash"
import { useFirebaseWriter } from "../../../firebase/Context"
import Firebase from "../../../firebase/Firebase"
import { UseQuerySnapshotOptions } from "./useQuerySnapshot"

export function useMultiQuerySnapshot<R, K, T = R[]>(
  multiQueries: ((db: Omit<Firebase, "adminDb">) => Query<R> | null)[],
  dedupe: (r: R[]) => R[],
  postprocess: (r: R[]) => T,
  dependencies: unknown[],
  { allowCache = true }: UseQuerySnapshotOptions = {}
): Loading<T> {
  const firebase = useFirebaseWriter()
  const [result, setResult] = useState<Loading<R[]>>("loading")
  const { handleError } = useErrorHandler()
  useEffect(() => {
    if (multiQueries.length === 0) {
      setResult(null)
      return () => { }
    }
    const unsubs = multiQueries.map((f) => {
      const query = f(firebase)
      if (!query) {
        setResult(null)
        return () => { }
      }
      return query.onSnapshot(
        (x) => {
          if (allowCache || !x.metadata.fromCache) {
            setResult((prev) => {
              const newDocs = getAllDocs(x)
              if (!isLoaded(prev)) return newDocs
              return dedupe([...prev, ...newDocs])
            })
          }
        },
        (e) => {
          Sentry.captureException(e)
          if (_.isError(e)) {
            handleError(e)
          }
          setResult(null)
        }
      )
    })
    return () => {
      unsubs.forEach((unsub) => {
        unsub()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)
  const finalResult = useMemo(() => mapLoading(postprocess)(result), [postprocess, result])
  return finalResult
}
