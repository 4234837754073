import { Path } from "../data/Record/Path"

export type QueryFilterOperator = "==" | "<=" | ">="
export interface QueryFilterable<Self, R> {
  where: <T>(fieldPath: Path<R, T>, op: QueryFilterOperator, val: T) => Self
}

export class QueryFilter<R extends Record<string, unknown>>
  implements QueryFilterable<QueryFilter<R>, R>
{
  private tail: QueryFilter<R> | null

  private head: {
    fieldPath: string
    op: QueryFilterOperator
    val: unknown
  } | null

  private constructor(
    tail: QueryFilter<R> | null,
    head: {
      fieldPath: string
      op: QueryFilterOperator
      val: unknown
    } | null
  ) {
    this.tail = tail
    this.head = head
  }

  where = <T>(fieldPath: Path<R, T>, op: QueryFilterOperator, val: T) =>
    new QueryFilter(this, { fieldPath, op, val })

  static identity = <T extends Record<string, unknown>>() => new QueryFilter<T>(null, null)

  interpret = <Query extends QueryFilterable<Query, R>>(q: Query): Query => {
    const x: Query = this.tail ? this.tail?.interpret(q) : q
    const y: Query = this.head
      ? x.where(this.head.fieldPath as Path<R, unknown>, this.head.op, this.head.val)
      : x
    return y
  }
}
