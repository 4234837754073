import _ from "lodash"
import { FC, useState, useEffect, useRef, ReactNode } from "react"
import { FieldRenderProps } from "react-final-form"
import { classNames } from "../../utils/classNames"

export type NumberFormat = "price" | "percentage" | "default"

// far too many options
// TODO prune unused props
interface InputProps {
  id?: string
  label?: ReactNode
  labelClassName?: string // absorb into label
  dataTestId?: string
  name: string
  value?: number
  numberFormat?: NumberFormat // TODO allow custom validation
  onChange?: (value: number | undefined) => void
  min?: number
  max?: number
  scale?: number
  addonBefore?: string | React.ReactElement // belongs on the parent
  addonAfter?: string | React.ReactElement // as with addonBefore
  className?: string
  disabled?: boolean
  precision?: "integer" | "decimal"
  decimalPlaces?: number
  totalDigits?: number // TODO consolidate with `decimalPlaces`, `precision`, and `numberFormat`
  overrideWithRounded?: boolean // should not exist. modify the rendering logic, not the state
  subtext?: string // not what that word means
}

interface InputPropsExtended extends FieldRenderProps<number>, InputProps {} // if we're going to do this then className, etc. should go on one of these

export const DeprecatedNumberInput: FC<React.PropsWithChildren<InputProps>> = ({
  id,
  dataTestId = "",
  label,
  labelClassName,
  value,
  name,
  onChange = () => {},
  min,
  max,
  scale,
  addonBefore,
  addonAfter,
  numberFormat,
  className,
  disabled,
  precision,
  decimalPlaces,
  totalDigits,
  overrideWithRounded,
  subtext,
}) => {
  /*
    DeprecatedNumberInput for financial calculations.
    It triggers onChange() event (i.e. it overrides its own value) when:
       - overrideWithRounded is set to true
      &- the value ends up being rounded to satisfy precision / decimalPlaces / totalDigits
    Related fields:
      - How precision is defined:
        - totalDigits
        - if not defined, then decimalPlaces
        - if not defined, then check if precision is int
        - if not defined, then 2 for percentage number format
                               3 for default number format
  */
  const ref = useRef<HTMLInputElement>(null)
  const hasAddonBefore = addonBefore || numberFormat === "price"
  const hasAddonAfter = addonAfter || numberFormat === "percentage"
  const scaleFactor = numberFormat === "percentage" ? 100.0 : scale || 1.0
  const scaledValue = value ? value * scaleFactor : value
  // this value will be sent to parent, and its scaled version will be rendered for users
  const [valueToUse, setValueToUse] = useState(value)
  const [rawValue, setRawValue] = useState(scaledValue?.toString())
  const [prevRawValue, setPrevRawValue] = useState(scaledValue?.toString())
  const [sendUpdate, setSendUpdate] = useState(value)
  const [caret, setCaret] = useState<number | null>(0)
  const [error, setError] = useState("")

  useEffect(() => {
    onChange(sendUpdate)
    // eslint-disable-next-line
  }, [sendUpdate])

  useEffect(() => {
    setValueToUse(value)
    if (ref.current) ref.current.setSelectionRange(caret, caret)
    // eslint-disable-next-line
  }, [value])

  useEffect(() => {
    if (ref.current && caret) ref.current.setSelectionRange(caret, caret)
  }, [caret, valueToUse])

  const safeParse = (val?: string) => {
    if (val === undefined) return undefined
    const out = parseFloat(val)
    return Number.isNaN(out) ? undefined : out
  } // extract and refactor into util functions

  const safeAbsDiff = (val1?: number, val2?: number) => {
    if (val1 === undefined || val2 === undefined) return 2 * 1e-10
    return Math.abs(val1 - val2)
  } // util

  const capFloor = (val: number) => {
    let cappedFloored = val
    if (min !== undefined) cappedFloored = Math.max(min, cappedFloored)
    if (max !== undefined) cappedFloored = Math.min(max, cappedFloored)
    return cappedFloored
  } // util

  const findStartZeros = (st: string) => {
    let numZeros = 0
    let i = "0" // variable names
    let y = 0
    while (i === "0" || i === ".") {
      i = st[y]
      numZeros += i === "0" ? 1 : 0
      y += 1
    }
    return numZeros
  } // `takeWhile`

  const getJSPrecision = (val: number) => {
    if (totalDigits !== undefined) return totalDigits

    const decimalPlacesFinal =
      decimalPlaces === 0
        ? decimalPlaces
        : decimalPlaces ||
          (precision === "integer" ? 0 : undefined) ||
          (numberFormat === "percentage" ? 2 : 3)
    return Math.floor(val).toString().length + decimalPlacesFinal
  } // remove

  const updateParent = (val?: number) => {
    setSendUpdate(val)
  } // just call onChange

  const formatForInput = () => {
    const localValueToUse =
      _.isNil(valueToUse) || valueToUse.toString() === "" || valueToUse === null
        ? undefined
        : valueToUse
    const scaled = localValueToUse === undefined ? undefined : localValueToUse * scaleFactor
    let raw = rawValue || ""
    const totDigits = scaled === undefined ? 0 : getJSPrecision(scaled)
    let parsed: number | undefined = scaled

    if (rawValue !== prevRawValue) {
      // if the user is typing something new
      setPrevRawValue(rawValue)
    } else if (safeAbsDiff(safeParse(rawValue), scaled) > 1e-10) {
      // programmatic override externally
      raw =
        (rawValue === "" || rawValue === "-"
          ? rawValue
          : scaled !== undefined
          ? scaled.toString()
          : "") || "" //  && scaled === 0
    }
    let addon = ""
    if (raw.substring(0, 1) === "-") {
      addon = "-"
      raw = raw.substring(1, raw.length)
    }
    if (raw === "" && addon === "-") return "-"
    const pointLength = raw.includes(".") ? 1 : 0
    const maxLen = totDigits + pointLength
    const numSignifZeros = findStartZeros(raw)
    if (raw[raw.length - 1] === "." || raw.length < maxLen) return `${addon}${raw}`
    parsed = safeParse(raw)
    if (parsed === undefined) return ""
    let out = ""
    if (parsed === 0 || totDigits <= numSignifZeros) {
      out = raw.substring(0, Math.min(maxLen, raw.length))
    } else {
      out = parsed.toPrecision(totDigits - numSignifZeros)
    }
    if (
      overrideWithRounded &&
      (safeAbsDiff(safeParse(out), safeParse(raw)) > 1e-10 ||
        safeAbsDiff(safeParse(out), scaled) > 1e-10)
    ) {
      setValueToUse(parseFloat(out) / scaleFactor)
      updateParent(parseFloat(out) / scaleFactor)
    }
    return `${addon}${out}`
  } // refactor

  const getRoundedCappedFlooredValue = (val: string) => {
    const num = Math.abs(parseFloat(val))
    if (Number.isNaN(num)) return val

    let raw = val
    let signAddon = ""
    if (raw.substring(0, 1) === "-") {
      signAddon = "-"
      raw = raw.substring(1, raw.length)
    }

    const pointLength = raw.includes(".") ? 1 : 0
    const totDigits = getJSPrecision(num)
    const maxLen = totDigits + pointLength
    let out = raw
    if (maxLen < raw.length) {
      out = raw.substring(0, maxLen)
      setRawValue(out)
    }
    if (parseFloat(out) !== capFloor(parseFloat(out))) {
      // eslint-disable-next-line
      setError(`Value between ${min} and ${max}`)
      return `${signAddon}${parseFloat(out)}`
    }
    setError("")
    return `${signAddon}${out}`
  } // refactor

  const onUpdate = (e: {
    target: { value: string | undefined; selectionStart: number | null }
  }) => {
    // TODO remove any
    const { value: newValue, selectionStart } = e.target
    setCaret(selectionStart)
    setRawValue(newValue)

    if (newValue === undefined || newValue.toString() === "") {
      onChange(undefined)
    } else {
      const strVal = getRoundedCappedFlooredValue(newValue)

      if (Number.isNaN(parseFloat(strVal))) {
        onChange(undefined)
      } else {
        onChange(parseFloat(strVal) / scaleFactor)
      }
    }
  } // refactor

  const renderAddOn =
    typeof addonBefore === "string" || addonBefore === undefined
      ? hasAddonBefore && (
          <div className="px-3 inline-flex items-center pointer-events-none bg-neutral-100 rounded-l-sm border border-r-0 border-neutral-400">
            <span className="text-neutral-600 sm:text-sm">{addonBefore || "$"}</span>
          </div>
        )
      : addonBefore // remove

  return (
    <div className={className}>
      {label && (
        <label
          htmlFor={name}
          className={labelClassName || "block text-sm font-medium text-neutral-700"}
        >
          {label}
        </label>
      )}

      <div className="flex rounded-sm shadow-sm items-stretch">
        {renderAddOn}
        <input
          ref={ref}
          id={id || name}
          data-test-id={dataTestId}
          type="text"
          className={classNames(
            "flex-1 px-3 py-2 min-w-0 block w-full sm:text-sm border border-neutral-400 rounded-none",
            hasAddonBefore ? "" : "rounded-l-sm",
            hasAddonAfter ? "" : "rounded-r-sm",
            disabled ? "bg-neutral-100" : "",
            error !== ""
              ? "border-danger-600 focus:border-danger-600 focus:ring-danger-600"
              : "focus:ring-accent-700 focus:border-accent-700"
          )}
          min={min}
          max={max}
          name={name}
          disabled={disabled}
          value={formatForInput()}
          step={precision === "decimal" ? 1e-6 : 1e-3}
          onChange={(e) => onUpdate(e)}
        />
        {(addonAfter || numberFormat === "percentage") && (
          <div className="px-3 inline-flex items-center pointer-events-none bg-neutral-100 rounded-r-sm border border-l-0 border-neutral-400">
            <span
              className="text-neutral-600 sm:text-sm"
              id="price-currency"
              data-test-id={`${dataTestId}-addonAfter`}
            >
              {addonAfter || "%"}
            </span>
          </div>
        )}
      </div>
      <div className="flex flex-row items-center justify-start w-full">
        {subtext && <p className="text-xs my-1 text-neutral-600 font-medium">{subtext}</p>}
      </div>
    </div>
  )
}

export const DeprecatedNumberInputForm: FC<React.PropsWithChildren<InputPropsExtended>> = (
  props
) => {
  const onChange = props.onChange !== undefined ? props.onChange : props.input.onChange
  const name = props.name !== undefined ? props.name : props.input.name
  return (
    <DeprecatedNumberInput {...props} name={name} value={props.input.value} onChange={onChange} />
  )
}
