import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"

export const DealTerm = ({ label, value }: { label: string; value: string }) => (
  <div className="flex flex-col gap-2">
    <Typography size={Size.XSmall} weight={Weight.Semibold} text={label} />
    <Typography
      size={Size.Small}
      weight={Weight.Regular}
      color={Color.Primary}
      text={value ?? "-"}
    />
  </div>
)
