import { AccountStatusDropdown } from "@stories/components/AccountStatusDropdown/AccountStatusDropdown"
import { isLoading } from "common/utils/Loading"
import { getQuarter, getYear } from "date-fns"
import { useAccountCompliance } from "src/providers/data/AccountComplianceProvider"
import FeatureWrapper from "src/providers/featureFlags/FeatureWrapper"
import { toggleChat } from "./Intercom/IntercomChat"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { useFirebase9 } from "src/firebase/Firebase9Context"

export const AccountQuotaStatusDropdown = () => {
  const user = useLoggedInUser()
  const firebase9 = useFirebase9()

  const {
    isQuotaSystemEnabled,
    accountOrderQuotaRequirements,
    quotaCompliantOrderCount,
    quotaCompliantOrderVolume,
  } = useAccountCompliance()

  if (
    isQuotaSystemEnabled &&
    accountOrderQuotaRequirements &&
    !isLoading(quotaCompliantOrderCount) &&
    !isLoading(quotaCompliantOrderVolume)
  ) {
    return (
      <FeatureWrapper flagName="is_order_quota_system_live">
        <AccountStatusDropdown
          numberOfLiveOrders={quotaCompliantOrderCount ?? 0}
          numberOfOrdersRequired={accountOrderQuotaRequirements.minimumNumberOfCompliantOrders}
          orderVolume={quotaCompliantOrderVolume ?? 0}
          orderVolumeRequired={accountOrderQuotaRequirements.minimumTotalCompliantOrderVolume}
          timePeriod={`Q${getQuarter(new Date())} ${getYear(new Date())}`}
          handleButtonClick={() => toggleChat(firebase9, user.user, false)}
        />
      </FeatureWrapper>
    )
  }
  return null
}
