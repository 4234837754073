import { wrapIcon } from "./IconWrapper"

export const SortIcon = wrapIcon(
  <svg viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.166687 1.20833C0.166687 0.976268 0.258874 0.753708 0.422969 0.589614C0.587063 0.425519 0.809623 0.333332 1.04169 0.333332H10.9584C11.1904 0.333332 11.413 0.425519 11.5771 0.589614C11.7412 0.753708 11.8334 0.976268 11.8334 1.20833C11.8334 1.4404 11.7412 1.66296 11.5771 1.82705C11.413 1.99114 11.1904 2.08333 10.9584 2.08333H1.04169C0.809623 2.08333 0.587063 1.99114 0.422969 1.82705C0.258874 1.66296 0.166687 1.4404 0.166687 1.20833ZM0.404698 4.87243C0.404698 4.64036 0.496886 4.4178 0.66098 4.25371C0.825074 4.08961 1.04763 3.99743 1.2797 3.99743H6.5297C6.76176 3.99743 6.98432 4.08961 7.14842 4.25371C7.31251 4.4178 7.4047 4.64036 7.4047 4.87243C7.4047 5.10449 7.31251 5.32705 7.14842 5.49114C6.98432 5.65524 6.76176 5.74743 6.5297 5.74743H1.2797C1.04763 5.74743 0.825074 5.65524 0.66098 5.49114C0.496886 5.32705 0.404698 5.10449 0.404698 4.87243ZM1.62501 7.33333C1.39295 7.33333 1.17039 7.42552 1.00629 7.58961C0.842199 7.75371 0.750011 7.97627 0.750011 8.20833C0.750011 8.4404 0.842199 8.66296 1.00629 8.82705C1.17039 8.99114 1.39295 9.08333 1.62501 9.08333H3.37501C3.60708 9.08333 3.82964 8.99114 3.99373 8.82705C4.15782 8.66296 4.25001 8.4404 4.25001 8.20833C4.25001 7.97627 4.15782 7.75371 3.99373 7.58961C3.82964 7.42552 3.60708 7.33333 3.37501 7.33333H1.62501Z"
      fill="currentColor"
    />
  </svg>
)
