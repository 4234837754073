import { ReactElement } from "react"
import { Tooltip } from "../Antd"

export interface TooltipWrapperProps {
  isWrapped?: boolean
  title: string | React.ReactNode
  children: ReactElement
}

export const TooltipWrapper: React.FC<React.PropsWithChildren<TooltipWrapperProps>> = ({
  isWrapped = true,
  children,
  title,
}) => {
  if (isWrapped) {
    return (
      <Tooltip title={title}>
        <>{children}</>
      </Tooltip>
    )
  }

  return children
}
