import { Modal } from "@components/layout/Modal"
import { StopClickPropagation } from "@stories/components/StopClickPropagation/StopClickPropagation"
import Typography, { Size, Weight } from "@stories/components/Typography/Typography"
import { isLoaded } from "common/utils/Loading"
import { assertUnreachable } from "common/utils/fp/Function"
import { useEffect, useState } from "react"
import { NotificationPage } from "src/pages/UserSettings/Page"
import { useAccountSnapshot } from "src/providers/AccountSnapshot/useAccountSnapshot"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { OrdersAndDealsSettings } from "../OrdersAndDealsSettings"
import { DisplaySaveStatus, useSaveStatus } from "../useSaveStatus"
import { SettingsNavigationSidebar } from "./SettingsModalSidebar"
import Divider from "@stories/components/Divider/Divider"
import { EmailConnectBanner } from "src/pages/CRM/Components/EmailConnectBanner"
import { trackEvent } from "src/utils/Tracking"
import { TrustedBrokerNetworkPage } from "src/pages/UserSettings/TrustedBrokerManagement/TrustedBrokerPage"
import { TrustedBrokerInviteManagementPage } from "src/pages/UserSettings/TrustedBrokerManagement/TrustedBrokerInviteManagementPage"

export type SettingsSection =
  | "Account Plan"
  | "Team"
  | "Email Sync"
  | "Orders & Deals"
  | "Contacts"
  | "Notifications"
  | "Trusted Broker Network"
  | "Invitations"

export const SettingsModal = ({
  open,
  onClose,
  defaultSection,
}: {
  open: boolean
  onClose: () => void
  defaultSection?: SettingsSection
}) => {
  const user = useLoggedInUser()
  const { accountSnapshot } = useAccountSnapshot()
  const [activeSection, setActiveSection] = useState<SettingsSection>(
    defaultSection ?? "Notifications"
  )
  const [saveStatus, setSaveStatus] = useSaveStatus()

  useEffect(() => {
    if (open) {
      trackEvent("settings_modal_opened", { section: defaultSection })
    }
  }, [open, defaultSection])

  if (!isLoaded(accountSnapshot)) return null

  const SettingsBody = () => {
    if (!isLoaded(accountSnapshot)) return null
    switch (activeSection) {
      case "Account Plan":
        return <p />
      case "Team":
        return <p />
      case "Email Sync":
        return user.user.emailIntegration ? (
          <p>Your email has been connected.</p>
        ) : (
          <div>
            <EmailConnectBanner client="gmail" />
            <p>
              Connect your email for automatic syncing of contact emails and AI parsing of deals you
              are working on.
            </p>
          </div>
        )
      case "Orders & Deals":
        return (
          <OrdersAndDealsSettings
            account={accountSnapshot}
            saveStatus={saveStatus}
            setSaveStatus={setSaveStatus}
          />
        )
      case "Invitations":
        return <TrustedBrokerInviteManagementPage />
      case "Trusted Broker Network":
        return <TrustedBrokerNetworkPage onChangeActiveSection={setActiveSection} />
      case "Contacts":
        return <p />
      case "Notifications":
        return <NotificationPage modalEmbed />
      default:
        return assertUnreachable(activeSection)
    }
  }

  return (
    <StopClickPropagation>
      <Modal
        open={open}
        handleClose={() => onClose?.()}
        closable={!!onClose}
        className="h-screen md:max-h-3/4-screen w-screen max-w-[950px] 2xl:max-w-[1200px]"
        noPadding
        body={
          <div className="relative">
            <div className="absolute h-screen md:max-h-3/4-screen py-2 w-full">
              <div className="py-2 px-4">
                <Typography text="Settings" size={Size.Medium} weight={Weight.Semibold} />
              </div>
              <div className="pt-2">
                <Divider />
              </div>
              <div
                className="flex flex-row gap-4 px-4 pb-2 w-full"
                style={{ height: "calc(100% - 72px)" }}
              >
                <div className="border-r border-r-neutral-400 pt-5 pr-4">
                  <SettingsNavigationSidebar
                    account={accountSnapshot}
                    activeSection={activeSection}
                    setActiveSection={setActiveSection}
                  />
                </div>
                <div className="flex flex-col gap-4 pt-3 overflow-y-auto mx-auto w-full md:w-150 xl:w-175">
                  <div className="flex justify-between">
                    <Typography text={activeSection} size={Size.Large} weight={Weight.Semibold} />
                    <DisplaySaveStatus saveStatus={saveStatus} />
                  </div>
                  <SettingsBody />
                </div>
              </div>
            </div>
          </div>
        }
      />
    </StopClickPropagation>
  )
}
