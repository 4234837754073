export type Field = { name: string; value: string | null }
export const DisplayField: React.FC<React.PropsWithChildren<Field>> = ({ name, value }) => (
  <div className="flex space-x-2">
    <h3 className="font-bold">{name}:</h3>
    <p>{value}</p>
  </div>
)

export const DisplayFields: React.FC<
  React.PropsWithChildren<{ fields: Field[]; title?: string }>
> = ({ fields, title, children }) => (
  <div>
    {title ? <h2 className="font-bold py-2 text-lg">{title}</h2> : null}
    <div className="overflow-hidden rounded-md bg-neutral-white shadow">
      <ul className="divide-y divide-neutral-400">
        {fields.map(({ name, value }) =>
          value ? (
            <li key={name} className="px-6 py-4">
              <DisplayField name={name} value={value} />
            </li>
          ) : null
        )}
        {children ? <li className="px-6 py-4">{children}</li> : null}
      </ul>
    </div>
  </div>
)
