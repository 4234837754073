import { getValuationFromTheDaysSplitAdjustedData } from "../../data-product/valuationCalculation/Valuation"
import { OrderDirection } from "../Order"
import { orderQuantityTermsPrice, orderQuantityTermsTargetValuation } from "../Types/Terms/Quantity"
import { ComplianceContextData, OrderQuotaComplianceViolations } from "./runQuotaComplianceChecks"

export const priceThresholdQuotaComplianceTags = [
  "PRICE_FLOOR__BUY__HIGH_QUALITY_MP__NOT_CONSTRAINED",
  "PRICE_FLOOR__BUY__LOW_QUALITY_MP__NOT_CONSTRAINED",
  "PRICE_FLOOR__BUY__NO_MP__NOT_CONSTRAINED",
  "PRICE_FLOOR__BUY__HIGH_QUALITY_MP__CONSTRAINED",
  "PRICE_FLOOR__BUY__LOW_QUALITY_MP__CONSTRAINED",
  "PRICE_FLOOR__BUY__NO_MP__CONSTRAINED",
  "PRICE_CEIL__SELL__HIGH_QUALITY_MP__NOT_CONSTRAINED",
  "PRICE_CEIL__SELL__LOW_QUALITY_MP__NOT_CONSTRAINED",
  "PRICE_CEIL__SELL__NO_MP__NOT_CONSTRAINED",
  "PRICE_CEIL__SELL__HIGH_QUALITY_MP__CONSTRAINED",
  "PRICE_CEIL__SELL__LOW_QUALITY_MP__CONSTRAINED",
  "PRICE_CEIL__SELL__NO_MP__CONSTRAINED",
] as const

type PriceThresholdTestConditions = {
  direction: OrderDirection
  marketPriceQualityThreshold: "high" | "low" | "none"
  constrained: boolean
}

export type PriceThresholdOrderQuotaComplianceViolations = {
  tag: (typeof priceThresholdQuotaComplianceTags)[number]
  data: {
    companyValue: number
    orderValue: number
    testConditions: PriceThresholdTestConditions
  }
}

export const isPriceThresholdQuotaComplianceViolation = (
  t: OrderQuotaComplianceViolations
): t is PriceThresholdOrderQuotaComplianceViolations =>
  t.tag === "PRICE_FLOOR__BUY__HIGH_QUALITY_MP__NOT_CONSTRAINED" ||
  t.tag === "PRICE_FLOOR__BUY__LOW_QUALITY_MP__NOT_CONSTRAINED" ||
  t.tag === "PRICE_FLOOR__BUY__NO_MP__NOT_CONSTRAINED" ||
  t.tag === "PRICE_FLOOR__BUY__HIGH_QUALITY_MP__CONSTRAINED" ||
  t.tag === "PRICE_FLOOR__BUY__LOW_QUALITY_MP__CONSTRAINED" ||
  t.tag === "PRICE_FLOOR__BUY__NO_MP__CONSTRAINED" ||
  t.tag === "PRICE_CEIL__SELL__HIGH_QUALITY_MP__NOT_CONSTRAINED" ||
  t.tag === "PRICE_CEIL__SELL__LOW_QUALITY_MP__NOT_CONSTRAINED" ||
  t.tag === "PRICE_CEIL__SELL__NO_MP__NOT_CONSTRAINED" ||
  t.tag === "PRICE_CEIL__SELL__HIGH_QUALITY_MP__CONSTRAINED" ||
  t.tag === "PRICE_CEIL__SELL__LOW_QUALITY_MP__CONSTRAINED" ||
  t.tag === "PRICE_CEIL__SELL__NO_MP__CONSTRAINED"

export const priceThresholdQuotaComplianceCheckDefinitions: {
  testConditions: PriceThresholdTestConditions
  isCompliantComparison: (orderValue: number, companyValue: number) => boolean
  tag: (typeof priceThresholdQuotaComplianceTags)[number]
}[] = [
  {
    testConditions: { direction: "buy", marketPriceQualityThreshold: "high", constrained: false },
    isCompliantComparison: (orderValue, companyValue) => orderValue > companyValue * 0.9,
    tag: "PRICE_FLOOR__BUY__HIGH_QUALITY_MP__NOT_CONSTRAINED",
  },
  {
    testConditions: { direction: "buy", marketPriceQualityThreshold: "low", constrained: false },
    isCompliantComparison: (orderValue, companyValue) => orderValue > companyValue * 0.8,
    tag: "PRICE_FLOOR__BUY__LOW_QUALITY_MP__NOT_CONSTRAINED",
  },
  {
    testConditions: { direction: "buy", marketPriceQualityThreshold: "none", constrained: false },
    isCompliantComparison: (_orderValue, _companyValue) => true,
    tag: "PRICE_FLOOR__BUY__NO_MP__NOT_CONSTRAINED",
  },
  {
    testConditions: { direction: "buy", marketPriceQualityThreshold: "high", constrained: true },
    isCompliantComparison: (orderValue, companyValue) => orderValue > companyValue * 0.8,
    tag: "PRICE_FLOOR__BUY__HIGH_QUALITY_MP__CONSTRAINED",
  },
  {
    testConditions: { direction: "buy", marketPriceQualityThreshold: "low", constrained: true },
    isCompliantComparison: (orderValue, companyValue) => orderValue > companyValue * 0.6,
    tag: "PRICE_FLOOR__BUY__LOW_QUALITY_MP__CONSTRAINED",
  },
  {
    testConditions: { direction: "buy", marketPriceQualityThreshold: "none", constrained: true },
    isCompliantComparison: (_orderValue, _companyValue) => true,
    tag: "PRICE_FLOOR__BUY__NO_MP__CONSTRAINED",
  },
  {
    testConditions: { direction: "sell", marketPriceQualityThreshold: "high", constrained: false },
    isCompliantComparison: (orderValue, companyValue) => orderValue < companyValue * 1.1,
    tag: "PRICE_CEIL__SELL__HIGH_QUALITY_MP__NOT_CONSTRAINED",
  },
  {
    testConditions: { direction: "sell", marketPriceQualityThreshold: "low", constrained: false },
    isCompliantComparison: (orderValue, companyValue) => orderValue < companyValue * 1.2,
    tag: "PRICE_CEIL__SELL__LOW_QUALITY_MP__NOT_CONSTRAINED",
  },
  {
    testConditions: { direction: "sell", marketPriceQualityThreshold: "none", constrained: false },
    isCompliantComparison: (_orderValue, _companyValue) => true,
    tag: "PRICE_CEIL__SELL__NO_MP__NOT_CONSTRAINED",
  },
  {
    testConditions: { direction: "sell", marketPriceQualityThreshold: "high", constrained: true },
    isCompliantComparison: (orderValue, companyValue) => orderValue < companyValue * 1.2,
    tag: "PRICE_CEIL__SELL__HIGH_QUALITY_MP__CONSTRAINED",
  },
  {
    testConditions: { direction: "sell", marketPriceQualityThreshold: "low", constrained: true },
    isCompliantComparison: (orderValue, companyValue) => orderValue < companyValue * 1.4,
    tag: "PRICE_CEIL__SELL__LOW_QUALITY_MP__CONSTRAINED",
  },
  {
    testConditions: { direction: "sell", marketPriceQualityThreshold: "none", constrained: true },
    isCompliantComparison: (_orderValue, _companyValue) => true,
    tag: "PRICE_CEIL__SELL__NO_MP__CONSTRAINED",
  },
]

const shouldRunComplianceCheckBasedOnMarketPriceQualityThreshold = (
  context: ComplianceContextData,
  testConditions: PriceThresholdTestConditions
) => {
  const totalOrdersInPast90Days =
    (context.company.marketHistorySummary?.past90Days.bidHistoryRollup?.orderCount ?? 0) +
    (context.company.marketHistorySummary?.past90Days.offerHistoryRollup?.orderCount ?? 0)

  if (testConditions.marketPriceQualityThreshold === "high" && totalOrdersInPast90Days > 10) {
    return true
  }
  if (
    testConditions.marketPriceQualityThreshold === "low" &&
    totalOrdersInPast90Days > 5 &&
    totalOrdersInPast90Days <= 10
  ) {
    return true
  }
  if (testConditions.marketPriceQualityThreshold === "none" && totalOrdersInPast90Days <= 5) {
    return true
  }
  return false
}

const shouldRunComplianceCheck = (
  context: ComplianceContextData,
  testConditions: PriceThresholdTestConditions
) => {
  if (testConditions.direction !== context.direction) {
    return false
  }
  if (!shouldRunComplianceCheckBasedOnMarketPriceQualityThreshold(context, testConditions)) {
    return false
  }
  if (
    testConditions.constrained !== !!context.company.isSupplyConstrained &&
    context.direction === "sell"
  ) {
    return false
  }
  if (
    testConditions.constrained !== !!context.company.isDemandConstrained &&
    context.direction === "buy"
  ) {
    return false
  }

  return true
}

const runPriceThresholdQuotaComplianceCheck = (
  context: ComplianceContextData,
  definition: (typeof priceThresholdQuotaComplianceCheckDefinitions)[number]
): PriceThresholdOrderQuotaComplianceViolations | undefined => {
  if (!shouldRunComplianceCheck(context, definition.testConditions)) {
    return undefined
  }
  const orderPrice = orderQuantityTermsPrice(context.orderQuantity)
  const orderValuation = orderQuantityTermsTargetValuation(context.orderQuantity).toNullable()
  const orderValue = orderPrice ?? orderValuation
  const companyPrice = context.company.priceEstimatesSummary?.currentPrice?.priceEstimatePPS
  const fundingRoundValuation = context.company.fundingRoundsSummary?.lastKnownValuation?.valuation
  const marketPriceValuation = companyPrice
    ? getValuationFromTheDaysSplitAdjustedData(
        companyPrice,
        new Date(),
        context.company
      ).toNullable()?.valuation
    : undefined
  const companyValuation = marketPriceValuation ?? fundingRoundValuation
  const companyValue = orderPrice ? companyPrice : companyValuation
  if (!orderValue || !companyValue || definition.isCompliantComparison(orderValue, companyValue)) {
    return undefined
  }

  return {
    data: {
      orderValue,
      companyValue,
      testConditions: definition.testConditions,
    },
    tag: definition.tag,
  }
}

type PriceThresholdQuotaComplianceFunction = (
  c: ComplianceContextData
) => PriceThresholdOrderQuotaComplianceViolations | undefined

export const priceThresholdQuotaComplianceFunctions: PriceThresholdQuotaComplianceFunction[] =
  priceThresholdQuotaComplianceCheckDefinitions.map(
    (definition) => (context: ComplianceContextData) =>
      runPriceThresholdQuotaComplianceCheck(context, definition)
  )
