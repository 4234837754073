import { chain, difference } from "lodash"
import { useMemo, useState } from "react"
import { GroupBase } from "react-select"
import { LinkedBroker } from "common/model/LinkedBroker"
import { UserInvitedBroker } from "common/model/UserInvitedBroker"
import SearchSelect from "@stories/components/Inputs/SearchSelect/SearchSelect"

const groupBrokersByFirmName = (
  brokers: (LinkedBroker | UserInvitedBroker)[]
): GroupBase<LinkedBroker | UserInvitedBroker>[] =>
  chain(brokers)
    .groupBy((b) => ("firmName" in b ? b.firmName : "Invited"))
    .toPairs()
    .map(([firmName, options]) => ({
      label: firmName,
      options,
    }))
    .value()

const searchableLinkedBrokerKeys: string[] = [
  "firmName",
  "fullName",
] satisfies (keyof LinkedBroker)[]

const stringifyBrokerItem = (b: LinkedBroker | UserInvitedBroker): string =>
  Object.entries(b)
    .flatMap(([k, v]) => (searchableLinkedBrokerKeys.includes(k) ? [v] : []))
    .join(" ")
    .toLowerCase()

const MyBrokerSearch: React.FC<
  {
    addSelectedBroker: (b: LinkedBroker | UserInvitedBroker) => void
    allBrokers: (LinkedBroker | UserInvitedBroker)[]
    disabled?: boolean
  } & (
    | {
        selectedBrokers?: never
        selectedBroker: LinkedBroker | UserInvitedBroker | null
        clearSelectedBroker: () => void
        isSingleSelect: true
      }
    | {
        selectedBrokers: (LinkedBroker | UserInvitedBroker)[]
        selectedBroker?: never
        clearSelectedBroker?: never
        isSingleSelect?: false
      }
  )
> = ({
  addSelectedBroker,
  selectedBrokers,
  allBrokers,
  disabled,
  selectedBroker,
  clearSelectedBroker,
  isSingleSelect,
}) => {
  const getGroupedBrokers = useMemo(
    () => (search?: string) =>
      groupBrokersByFirmName(
        difference(
          search
            ? allBrokers.filter((b) => stringifyBrokerItem(b).includes(search.toLowerCase()))
            : allBrokers,
          selectedBrokers ?? (selectedBroker ? [selectedBroker] : [])
        )
      ),
    [selectedBroker, selectedBrokers, allBrokers]
  )

  return (
    <SearchSelect<LinkedBroker | UserInvitedBroker>
      id="search-linked-broker"
      isDisabled={disabled}
      value={isSingleSelect ? selectedBroker : null}
      onFocus={isSingleSelect ? clearSelectedBroker : () => null}
      defaultOptions={getGroupedBrokers()}
      onChange={(b) => (b ? addSelectedBroker(b) : null)}
      loadOptions={(search) => Promise.resolve(getGroupedBrokers(search))}
      renderOption={(o) => o.fullName}
      getOptionValue={(v) => v.id}
      getOptionLabel={(v) => v.fullName}
      placeholder="Search for your broker"
    />
  )
}

export default MyBrokerSearch
