import { Tooltip } from "@stories/components/Antd"
import { isOrderUserOwned } from "common/model/Order/Models/Internal"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { OrderItem } from "./MyOrdersTypes"
import { FlagIcon } from "@stories/icons/FlagIcon"
import { hiddenOutlierStatuses } from "common/model/data-product/DataPoint/OutlierStatusFields"

export const OrderFlag: React.FC<OrderItem> = ({ order }) => {
  const flagged = order.outlier?.tag && hiddenOutlierStatuses.includes(order.outlier?.tag)
  const user = useLoggedInUser()

  if (!flagged || !isOrderUserOwned(order, user.user)) {
    return null
  }

  return (
    <Tooltip
      placement="left"
      title="This order is under review and may not be visible to all parties"
    >
      <FlagIcon />
    </Tooltip>
  )
}
