import { ReactNode } from "react"
import { classNames } from "../../utils/classNames"

export const GrayH: (val: {
  className?: string
  children: JSX.Element | JSX.Element[] | string | ReactNode
}) => JSX.Element = ({ children, className }) => {
  const grayCapsStyle = "font-medium text-neutral-600 uppercase tracking-wider"
  return <div className={classNames(className || "text-xs", grayCapsStyle)}>{children}</div>
}

export const GraySubtext: (val: {
  className?: string
  children: JSX.Element | JSX.Element[] | string | ReactNode
}) => JSX.Element = ({ children, className }) => {
  const grayCapsStyle = "font-medium text-neutral-600 uppercase tracking-wider"
  return <div className={classNames(className || "text-xs", grayCapsStyle)}>{children}</div>
}
export const AccentText: (val: {
  className?: string
  children: JSX.Element | JSX.Element[] | string | ReactNode
}) => JSX.Element = ({ children, className }) => {
  const grayCapsStyle = "font-semibold text-accent-500"
  return <div className={classNames(className || "text-xs", grayCapsStyle)}>{children}</div>
}

export const Highlight: (val: {
  className?: string
  children: JSX.Element | JSX.Element[] | string | string[] | ReactNode
}) => JSX.Element = ({ children, className }) => {
  const customStyle = "font-semibold text-primary-800 leading-4"
  return <div className={classNames(customStyle, className)}>{children}</div>
}

export const ANote: (val: {
  className?: string
  children: JSX.Element | JSX.Element[] | string | string[] | ReactNode
}) => JSX.Element = ({ children, className }) => {
  const customStyle = "font-normal text-neutral-900 text-xxs"
  return <div className={classNames(customStyle, className, "leading-3")}>{children}</div>
}
