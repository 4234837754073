import moment from "moment"
import { CompanyIdFields } from "../../../model/Company"
import { StockSplitData, StockSplitDataWithCumulativeSplits } from "../../../model/Stock/StockSplit"
import { isNonempty, scanLNonempty } from "../../../utils/data/Array/Nonempty"
import {
  Q,
  Ratio,
  divideQ,
  invertQ,
  multiplyQ,
  qToNumber,
} from "../../../utils/data/numeric/Rational"
import { Just, Nothing } from "../../../containers/Maybe"
import { PricingDataRoot } from "../../pricing/PricingDataSource"
import { Enriching, MaybeEnriching } from "../../pricing/PricingEnrichment"

const getSplits = (root: PricingDataRoot) => async (company: CompanyIdFields, fromDate: Date) => {
  const splits = (await root.company(company.id).stockSplits().get(null, moment()))
    .map((x) => x.splitData)
    .filter((x) => x.date > fromDate) // TODO: is it > or >=
  return getCumulativeSplitAdjustments(splits)
}

export const splitAdjustPrice = (price: number, cumulativeSplitRatio: Q<number>) =>
  qToNumber(divideQ(Ratio(price, 1), cumulativeSplitRatio))

export const unSplitAdjustPrice = (price: number, cumulativeSplitRatio: Q<number>) =>
  qToNumber(divideQ(Ratio(price, 1), invertQ(cumulativeSplitRatio)))

export const splitAdjustShareCount = (shareCount: number, cumulativeSplitRatio: Q<number>) =>
  qToNumber(multiplyQ(Ratio(shareCount, 1), cumulativeSplitRatio))

export const getCumulativeSplitAdjustments = (
  splits: StockSplitData[],
  dateTo: Date = new Date()
) => {
  const i: StockSplitDataWithCumulativeSplits[] = splits
    .map((split) => ({
      splitDate: split.date,
      splitRatio: split.ratio,
      cumulativeSplitRatio: split.ratio,
    }))
    .sort((x) => -1 * x.splitDate.valueOf()) // sort by dates descending
    .filter((x) => x.splitDate <= dateTo)
  // from dateTo to the past
  const out = isNonempty(i)
    ? scanLNonempty(
        (l, r) => ({
          ...r,
          cumulativeSplitRatio: multiplyQ(l.cumulativeSplitRatio, r.splitRatio),
        }),
        i
      )
    : []
  return out
}

export const enrichWithSplitAdjustments =
  (root: PricingDataRoot) =>
  (company: CompanyIdFields): Enriching<"priceDisplay", "splitDisplay"> =>
  async (t) => {
    const j = await getSplits(root)(company, t.priceDisplay.toUnion().displayDate)
    return {
      ...t,
      splitDisplay: t.priceDisplay.match(
        () => [],
        (r) =>
          j.map((x) => ({
            ...x,
            pricePerShare: unSplitAdjustPrice(r.pricePerShare, x.cumulativeSplitRatio),
          }))
      ),
    }
  }

// TODO figure out how to deduplicate this
export const enrichWithPPSSplitAdjustments =
  (root: PricingDataRoot) =>
  (company: CompanyIdFields): Enriching<"pricePerShareDisplay", "splitDisplay"> =>
  async (t) => {
    const j = await getSplits(root)(company, t.pricePerShareDisplay.displayDate)
    return {
      ...t,
      splitDisplay: j.map((x) => ({
        ...x,
        pricePerShare: unSplitAdjustPrice(
          t.pricePerShareDisplay.pricePerShare,
          x.cumulativeSplitRatio
        ),
      })),
    }
  }
export const enrichWithOptionalPPSSplitAdjustments =
  (root: PricingDataRoot) =>
  (company: CompanyIdFields): MaybeEnriching<"pricePerShareDisplay", "splitDisplay"> =>
  async (t) =>
    t.pricePerShareDisplay.match(
      async (pps) => {
        const j = await getSplits(root)(company, pps.displayDate)
        return {
          ...t,
          splitDisplay: Just(
            j.map((x) => ({
              ...x,
              pricePerShare: unSplitAdjustPrice(pps.pricePerShare, x.cumulativeSplitRatio),
            }))
          ),
        }
      },
      () => ({ ...t, splitDisplay: Nothing })
    )
