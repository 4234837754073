export type DealPrimaryType = "primary-direct" | "primary-indirect"

export type DealSecondaryType = "secondary-direct" | "secondary-indirect"

export type DealType = DealPrimaryType | DealSecondaryType

export const DealTypes: DealType[] = [
  "primary-direct",
  "primary-indirect",
  "secondary-direct",
  "secondary-indirect",
] as const satisfies DealDistributionDetails["tag"][]

export const DealDistributionDetailsDesired = [
  "selected-investors",
  "caplight-live-market",
] as const

type DealDistributionDetailsCommon = {
  desiredDealDistribution: (typeof DealDistributionDetailsDesired)[number] | null
  closeDate: Date | null
  capitalCallDate: Date | null
  valuationUSD: number | null
  amountBeingRaisedUSD: number | null
  minimumInvestmentUSD: number | null
  carry: number | null
  managementFee: number | null
  feeNotes: string | null // e.g. "Negotiable" or "2 year structure"
}

type DealDistributionPrimaryRoundInnerDetails = {
  roundName: string
  roundAmountUSD: number | null
}
type DealDistributionSecondaryRoundInnerDetails = {
  pricePerShareUSD: number | null
}

type DealDistributionPrimaryRoundDetails = {
  tag: DealPrimaryType
} & DealDistributionPrimaryRoundInnerDetails &
  DealDistributionDetailsCommon

type DealDistributionSecondaryRoundDetails = {
  tag: DealSecondaryType
} & DealDistributionSecondaryRoundInnerDetails &
  DealDistributionDetailsCommon

export type DealDistributionDetails =
  | DealDistributionPrimaryRoundDetails
  | DealDistributionSecondaryRoundDetails

export const dealDistributionTypeLabels: Record<DealType, string> = {
  "primary-direct": "Primary Round Investment (direct to cap-table)",
  "primary-indirect": "Primary Round Investment (indirect via another fund's SPV)",
  "secondary-direct": "Secondary Investment (direct to cap-table)",
  "secondary-indirect": "Secondary Investment (indirect via another fund's SPV)",
}

export const dealDistributionTypeShortLabels: Record<DealType, string> = {
  "primary-direct": "Primary (direct)",
  "primary-indirect": "Primary (fund)",
  "secondary-direct": "Secondary (direct)",
  "secondary-indirect": "Secondary (fund)",
}
