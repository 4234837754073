import { Popover } from "@stories/components/Antd"
import { Button, ButtonProps } from "@stories/components/Button/Button"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { DealCRMContact, DealCRMContactIdFields } from "common/model/DealCRM/DealCRMContact"
import { SyntheticEvent } from "react"
import { useCRMContacts } from "../../Providers/CRMContactsProvider"
import ContactIcon from "./ContactIcon"
import ContactPopoverContent from "./ContactPopoverContent"
import { createContactSubtitle } from "./helpers"
import { AccessBoundary } from "@components/auth/DataAccess"
import { useModal } from "@components/layout/Modal"
import { SelectContactModal } from "../../Components/SelectContactModal"
import { StopClickPropagation } from "@stories/components/StopClickPropagation/StopClickPropagation"

interface CRMContactNameButtonProps {
  contact: DealCRMContactIdFields | null
  onPopoverClick?: (e: SyntheticEvent) => void
  onNameClick?: (e: SyntheticEvent) => void
  onAddMissingContact?: (c: DealCRMContact) => Promise<void>
  hidePopover?: boolean
  size?: ButtonProps["size"]
  variant: ButtonProps["variant"]
  tabIndex?: number
  isSubtitleDisplayed?: boolean
  isIconDisplayed?: boolean
}

export const UnknownContactButton = (
  props: Pick<CRMContactNameButtonProps, "size" | "tabIndex" | "onAddMissingContact">
) => {
  const { openModal, open, closeModal } = useModal()

  return (
    <StopClickPropagation>
      <>
        {props.onAddMissingContact ? (
          <Button
            label="No Contact Selected"
            size={props.size}
            variant="hollow-warning"
            tabIndex={props.tabIndex}
            onClick={openModal}
          />
        ) : (
          <div className="px-2">
            <Typography
              text="No Contact Selected"
              color={Color.WarningSecondary}
              weight={Weight.Semibold}
              size={
                props.size === "xs"
                  ? Size.XXSmall
                  : props.size === "small"
                  ? Size.XSmall
                  : Size.Small
              }
            />
          </div>
        )}

        {open && props.onAddMissingContact ? (
          <SelectContactModal
            onContactSelected={props.onAddMissingContact}
            sourceContactTag={null}
            open={open}
            handleClose={closeModal}
          />
        ) : null}
      </>
    </StopClickPropagation>
  )
}

const CRMContactNameButton = ({
  contact,
  onPopoverClick,
  onNameClick,
  onAddMissingContact,
  hidePopover = false,
  size,
  variant,
  tabIndex,
  isSubtitleDisplayed,
  isIconDisplayed = true,
}: CRMContactNameButtonProps) => {
  const { findContactById } = useCRMContacts()

  if (!contact)
    return (
      <UnknownContactButton
        size={size}
        tabIndex={tabIndex}
        onAddMissingContact={onAddMissingContact}
      />
    )

  const fullContact = findContactById(contact.id) ?? undefined
  const allowPopover = fullContact && !hidePopover

  const subtitle = createContactSubtitle(fullContact)
  const showSubtitle = isSubtitleDisplayed && !!subtitle

  return (
    <div data-dd-privacy="mask">
      <AccessBoundary accessLevel="hideFromCaplight">
        <Popover
          content={
            allowPopover ? (
              <ContactPopoverContent contact={fullContact} onClickView={onPopoverClick} />
            ) : null
          }
          mouseEnterDelay={0.5}
          trigger={allowPopover ? "hover" : []}
        >
          <div className="flex items-start">
            {isSubtitleDisplayed && isIconDisplayed ? (
              <div className="mt-2">
                <ContactIcon contact={fullContact} size={size === "none" ? undefined : size} />
              </div>
            ) : null}
            <div className="flex flex-col items-start gap-0">
              <Button
                style={
                  showSubtitle ? { paddingBottom: "0px", paddingTop: "4px", height: "1.5rem" } : {}
                }
                size={size}
                label={contact.name}
                variant={variant}
                onClick={onNameClick}
                leftIcon={
                  !isSubtitleDisplayed && isIconDisplayed ? (
                    <ContactIcon contact={fullContact} size={size === "none" ? undefined : size} />
                  ) : undefined
                }
                isTextWrapped={false}
                shouldMaskDataInDataDog
                tabIndex={tabIndex}
              />
              {showSubtitle && (
                <div className="ml-2">
                  <Typography
                    size={Size.XSmall}
                    color={Color.Subtitle}
                    text={subtitle}
                    shouldWrap={false}
                    htmlTag="p"
                  />
                </div>
              )}
            </div>
          </div>
        </Popover>
      </AccessBoundary>
    </div>
  )
}

export default CRMContactNameButton
