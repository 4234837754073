import { ConnectThread, threadUser } from "common/model/Messaging/ConnectThread"
import { User } from "common/model/User"

export const getMostRecentMessageTime = (thread: ConnectThread) =>
  thread._mostRecentMessageDate?.getTime() ?? 0

export const unreadMessageDate = ({ thread, user }: { thread: ConnectThread; user: User }) => {
  const userLastReadTime: number =
    threadUser(user.id).view(thread).toNullable()?.lastReadDate?.getTime() ?? 0

  const mostRecentMessageTime = getMostRecentMessageTime(thread)

  return mostRecentMessageTime > userLastReadTime ? mostRecentMessageTime : null
}

export const isNeverOpenedByUser = ({ thread, user }: { thread: ConnectThread; user: User }) =>
  !threadUser(user.id).view(thread).toNullable()?.lastReadDate?.getTime()

export const isUnread = (props: { thread: ConnectThread; user: User }) =>
  !!unreadMessageDate(props) ||
  (props.thread.threadType === "connectRequest" && isNeverOpenedByUser(props))
