import { AccessControl } from "../AccessControl"

export const journalistAccessControl: AccessControl<"journalist"> = {
  tier: "journalist",
  features: {
    // live market
    liveMarketParticipation: "none",
    orderHistoryData: "none",
    currentMarketPriceData: "full",
    contributeTradeData: "none",
    companyNewsData: "full",
    fundingRoundsPage: "full",
    fundingRoundsData: "full",
    marketInsightsPage: "full",
    rfqData: "none",

    // company page
    companyPageFundingRoundsTable: "full",
    companyPage409aValuationsTable: "none",
    companyPageFilingsTable: "full",
    companyPageInvestorsTable: "full",
    companyPageMutualFundMarksTable: "full",
    companyPageBidOfferVolumeRatioChart: "full",
    companyPageTradingContextSection: "full",
    companyPageResearchReportsSection: "full",
    companyPageComparablesSection: "full",
    companyPageShare: "full",
    companyPageFlagIssue: "none",
    companyPageExport: "none",

    // trade history
    tradeHistoryPage: "none",
    tradeHistoryPricing: "none",
    tradeHistoryDates: "none",
    tradeHistoryStructure: "none",
    tradeHistoryShareClass: "none",

    // sectors page
    sectorsData: "full",
  },
}
