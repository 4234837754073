import { restrictedCollections } from "common/firestore/Collections"
import { firestoreConverter } from "common/model/FirestoreConverter"
import { OrderLookupFields } from "common/model/Order/Models/Internal"
import { Order } from "common/model/Order/Order"
import { Loading, Loading_, mapLoading } from "common/utils/Loading"
import { createContext, ReactNode, useMemo } from "react"
import { useFirebaseWriter } from "src/firebase/Context"
import { useDarkpoolOrders } from "./DarkpoolOrderProvider"
import {
  DocumentContextData,
  FirebaseDocumentProvider,
  useFirebaseDocuments,
} from "./FirebaseDocumentProvider"
import { DocumentIdContainer, FilledContainer } from "./internal/DocumentIdContainer"
import { useTentativeInterests } from "./TentativeInterestProvider"

export const OrderContext = createContext<DocumentContextData<Order>>({
  documents: {},
  requestDocLookups: () => {},
})

export const OrderProvider = ({ children }: { children: ReactNode }) => {
  const firebase = useFirebaseWriter()
  const orderCollection = useMemo(
    () =>
      firebase.writerDb
        .collection(restrictedCollections.orderObservations)
        .withConverter<Order>(firestoreConverter<Order>()),
    [firebase.writerDb]
  )
  return (
    <FirebaseDocumentProvider Context={OrderContext} collection={orderCollection}>
      {children}
    </FirebaseDocumentProvider>
  )
}

export const useOrders = <C extends DocumentIdContainer = DocumentIdContainer>(
  ids: C
): Loading<FilledContainer<C, Order>> => useFirebaseDocuments(ids, OrderContext)

export const useGenericOrders = (orderIds: OrderLookupFields[]): Loading<Order[]> => {
  const orders = useOrders(orderIds.flatMap((o) => (o.orderCollection === "platform" ? o.id : [])))
  const darkOrders = useDarkpoolOrders(
    orderIds.flatMap((o) => (o.orderCollection === "darkpool" ? o.id : []))
  )
  const tentativeInterests = useTentativeInterests(
    orderIds.flatMap((o) => (o.orderCollection === "tentativeInterest" ? o.id : []))
  )
  return mapLoading<Order[][], Order[]>((o) => o.flat())(
    Loading_.Array.sequence([orders, darkOrders, tentativeInterests])
  )
}
