import CompanyLogoAndName from "@components/CompanyLogoAndName"
import CompanyAutocompletePill from "@components/companies/select/CompanyAutocompletePill"
import Spinner from "@components/icons/Spinner"
import { CheckIcon } from "@heroicons/react/outline"
import { Button } from "@stories/components/Button/Button"
import Typography, { Color, Size } from "@stories/components/Typography/Typography"
import { ExclamationPointCircleIcon } from "@stories/icons/ExclamationPointCircleIcon"
import { PlusIcon } from "@stories/icons/PlusIcon"
import { SelectedCompany, viewCompanyIdFields } from "common/model/Company"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { DealCRMInterest, buildDealCRMInterest } from "common/model/DealCRM/DealCRMInterest"
import { viewOrderSourceAccountFields } from "common/model/Order/OrderSource"
import { viewUserIdFields } from "common/model/User"
import { isLoaded } from "common/utils/Loading"
import { useCallback, useEffect, useState } from "react"
import { useFirebaseWriter } from "src/firebase/Context"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import { createContactInterest } from "src/firebase/crm"
import { DirectionDot } from "src/pages/Orders/shared/Cells"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import { handleConsoleError, trackEventInFirestoreAndHeap } from "src/utils/Tracking"
import { useCurrentAccount } from "src/queries/currentUser/useCurrentAccount"
import DirectionAutocomplete from "../Components/DirectionAutocomplete/DirectionAutocomplete"
import { useCRMContacts } from "../Providers/CRMContactsProvider"
import InlineContactSelectSearch from "../Components/InlineContactSelectSearch/InlineContactSelectSearch"
import { CRMContactName } from "../Contacts/ContactDetailsDrawer/CRMContactName"

export const AddInterestRow = ({
  colSpan,
  afterCreateInterest,
}: {
  colSpan: number
  afterCreateInterest?: (i: DealCRMInterest) => void
}) => {
  const { contacts } = useCRMContacts()
  const [company, setCompany] = useState<SelectedCompany>()
  const [contact, setContact] = useState<DealCRMContact | null>()
  const [direction, setDirection] = useState<DealCRMInterest["direction"] | null>(null)
  const [formStatus, setFormStatus] = useState<
    "idle" | "input" | "submitting" | "success" | "error"
  >("idle")
  const currentUser = useCurrentUser()
  const db = useFirebaseWriter()
  const firebase9 = useFirebase9()
  const account = useCurrentAccount()

  const resetForm = () => {
    setCompany(undefined)
    setContact(undefined)
    setDirection(null)
  }

  const createContact = useCallback(() => {
    if (
      company === undefined ||
      direction === null ||
      !isLoaded(currentUser) ||
      !isLoaded(account)
    ) {
      return
    }

    const { user } = currentUser

    const newInterest: DealCRMInterest = buildDealCRMInterest({
      company: viewCompanyIdFields(company),
      direction,
      account,
      contact: contact ?? null,
      source: {
        sourceType: "user-form",
        submittingUser: viewUserIdFields(user),
        sourceId: null,
        documentUpload: null,
        account: viewOrderSourceAccountFields(user.account),
      },
    })

    createContactInterest({
      db,
      user,
      interest: newInterest,
    })
      .then(() => setFormStatus("success"))
      .then(() =>
        trackEventInFirestoreAndHeap(firebase9, user, "crm-contact-indication-of-interest-added", {
          direction,
          hasContact: newInterest.contact !== null,
        })
      )
      .then(() => afterCreateInterest?.(newInterest))
      .catch((err) => {
        setFormStatus("error")
        handleConsoleError(err)
      })
  }, [account, afterCreateInterest, company, contact, currentUser, db, direction, firebase9])

  useEffect(() => {
    if (contact === undefined) return
    if (direction === null) return
    if (company === undefined) return

    setFormStatus("submitting")
    createContact()
    resetForm()
  }, [company, contact, createContact, direction])

  if (!contacts.length) return null

  const displayForm = formStatus === "input"

  const focusDirectionInput = () => {
    document.getElementById("order-direction-autocomplete")?.click()
  }
  const focusContactInput = () => document.getElementById("inline-contact-search-select")?.click()

  return (
    <tr className="h-8 border-b">
      {displayForm ? (
        <>
          <td className="px-2 border-r">
            {company ? (
              <CompanyLogoAndName company={company} size="xs" disableClick />
            ) : (
              <CompanyAutocompletePill
                autoFocus
                limitResults={2}
                selected={company}
                handleSelect={(c) => {
                  setCompany(c)
                  if (c) {
                    focusContactInput()
                  }
                }}
                allowTabDefault={false}
              />
            )}
          </td>
          <td className="border-r px-2">
            {contact || contact === null ? (
              <CRMContactName size="medium" contact={contact} tabIndex={-1} />
            ) : (
              <InlineContactSelectSearch
                sourceComponent={company ? "company-interest-table" : "all-interests-table"}
                onContactSelected={(selectedContact) => {
                  setContact(selectedContact)
                  focusDirectionInput()
                  return Promise.resolve()
                }}
                noBorder
              />
            )}
          </td>
          <td className="border-r px-2">
            {direction ? (
              <div className="text-sm">
                <DirectionDot direction={direction} />
              </div>
            ) : (
              <DirectionAutocomplete onChange={(d) => setDirection(d === "bid" ? "buy" : "sell")} />
            )}
          </td>
          <td className="px-2 border-r">
            <div className="flex items-center justify-start">
              <Button
                onClick={() => {
                  setFormStatus("idle")
                  resetForm()
                }}
                variant="hollow"
                label="Cancel"
              />
            </div>
          </td>
        </>
      ) : (
        <td colSpan={colSpan}>
          <div className="flex gap-2 items-center justify-start px-2">
            {formStatus === "submitting" ? (
              <div className="flex gap-1 items-center">
                <Spinner size="xs" />
                <Typography text="Submitting new interest..." size={Size.Small} />
              </div>
            ) : (
              <Button
                variant="hollow"
                leftIcon={<PlusIcon />}
                label="Add New Interest"
                onClick={() => setFormStatus("input")}
              />
            )}
            {formStatus === "error" ? (
              <div className="flex gap-1 items-center">
                <ExclamationPointCircleIcon color={Color.Danger} />
                <Typography
                  text="Something went wrong, please try again"
                  size={Size.Small}
                  color={Color.Danger}
                />
              </div>
            ) : null}
            {formStatus === "success" ? (
              <div className="flex gap-1 items-center">
                <CheckIcon className="w-4 text-success-500" />
                <Typography
                  text="Successfully added interest"
                  size={Size.Small}
                  color={Color.Success}
                />
              </div>
            ) : null}
          </div>
        </td>
      )}
    </tr>
  )
}
