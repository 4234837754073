import { DealCRMDealParticipant } from "common/model/DealCRM/Deal/DealCRMDealParticipant"
import { isNil } from "lodash"
import { ReactElement, ReactNode, useState } from "react"
import { useFirebaseWriter } from "../../../../firebase/Context"
import { updateDealParticipant } from "../../../../firebase/crm"
import { Select, SelectProps } from "../../../../stories/components/Antd"
import DownChevronIcon from "@stories/icons/DownChevron"
import { classNames } from "src/utils/classNames"

interface FieldProps<T> {
  value: T
  handleChange: (newValue: T) => Promise<void> | void
  editable: boolean
}

interface DealParticipantFieldDisplayProps<T> {
  size: "small" | "large"
  editable: boolean
  participant: DealCRMDealParticipant
  fieldName: keyof DealCRMDealParticipant
  component: (props: FieldProps<T>) => ReactElement
  label?: string
}

function DealParticipantFieldDisplay<T>(props: DealParticipantFieldDisplayProps<T>) {
  const { size, editable, participant, fieldName, component, label } = props
  const db = useFirebaseWriter()

  const handleChange = (value: T) =>
    updateDealParticipant({
      db,
      dealParticipantId: participant.id,
      update: { [fieldName]: value },
    })

  const value: T = participant[fieldName] as unknown as T

  return (
    <>
      {label && <div className="text-sm font-medium">{label}</div>}
      {component({ value, handleChange, editable })}
    </>
  )
}

export interface SelectFieldProps<T extends string | null> extends FieldProps<T> {
  options: T[]
  renderValue: (value: T) => ReactNode
  size?: SelectProps["size"]
  bordered?: boolean
  className?: string
  isDisabled?: boolean
}

export function SelectField<T extends string | null>(props: SelectFieldProps<T>) {
  const [focused, setFocused] = useState(false)
  const {
    value,
    handleChange,
    options,
    renderValue,
    editable,
    size,
    bordered,
    className,
    isDisabled,
  } = props

  const selectOptions = options.map((val) => ({
    value: val,
    label: renderValue(val),
  }))

  if (editable) {
    return (
      <div className={classNames(className, "min-w-20")}>
        <Select
          disabled={isDisabled}
          className={focused ? "ring-1 ring-accent-100" : ""}
          showAction={["focus", "click"]}
          tabIndex={0}
          size={size}
          bordered={bordered ?? false}
          value={value}
          style={{ width: "100%" }}
          options={selectOptions.sort((a, b) => (isNil(a) ? -1 : isNil(b) ? 1 : 0))}
          onChange={handleChange}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          suffixIcon={
            <span className="ml-2">
              <DownChevronIcon />
            </span>
          }
        />
      </div>
    )
  } else {
    return <div className="min-w-20 text-sm">{renderValue(value)}</div>
  }
}

export default DealParticipantFieldDisplay
