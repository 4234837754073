import { isLoaded } from "common/utils/Loading"
import { isNonempty } from "common/utils/data/Array/Nonempty"
import { FC, useState } from "react"
import { useFirebaseReader } from "../../firebase/Context"
import { useErrorHandler } from "../../providers/errorHandler"
import { Button } from "@stories/components/Button/Button"
import Typography, { Size } from "@stories/components/Typography/Typography"
import { useAdminGetUsersByAccount } from "src/queries/User/useAdminGetUsersByAccount"

const AccountUsers: FC<React.PropsWithChildren<{ accountId: string }>> = ({ accountId }) => {
  const firebase = useFirebaseReader()
  const allUsersByAccount = useAdminGetUsersByAccount(accountId)
  const { handleError } = useErrorHandler()
  const [showUserList, setShowUserList] = useState(false)
  const [userListLimit, setUserListLimit] = useState(5)

  if (!isLoaded(allUsersByAccount)) return null

  const limitedUsers = allUsersByAccount.flatMap((u) => (u.limitPlatformAccess ? [u] : []))

  return (
    <>
      <Typography
        size={Size.XXSmall}
        text={`(${allUsersByAccount.length}): ${allUsersByAccount
          .map((u) => u.email)
          .slice(0, userListLimit)
          .join(", ")}`}
      />
      {allUsersByAccount.length > userListLimit && (
        <Button
          label={`Show All Users (${allUsersByAccount.length})`}
          onClick={() => setUserListLimit(Infinity)}
          size="xs"
          variant="hollow"
        />
      )}
      {isNonempty(limitedUsers) ? (
        <>
          <Button
            label={`${showUserList ? "Hide" : "Show"} List of Limited Users`}
            onClick={() => setShowUserList((x) => !x)}
            size="xs"
            variant="hollow"
          />
          {showUserList && (
            <div className="flex flex-col w-full my-2 border rounded-lg bg-neutral-white">
              {limitedUsers.map((u) => (
                <div key={u.id} className="flex space-x-2 p-2 border-b hover:bg-neutral-100">
                  <div>
                    {u.firstName} {u.lastName} ({u.email})
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      firebase.adminRemoveLimitPlatformAccess(u.id).catch(handleError)
                    }}
                    className="text-primary-500 font-bold cursor-pointer hover:text-accent-700"
                  >
                    Grant User Full Access
                  </button>
                </div>
              ))}
            </div>
          )}
        </>
      ) : null}
    </>
  )
}

export default AccountUsers
