/* eslint-disable react/jsx-props-no-spreading */
import PhoneNumberInput, { Country } from "react-phone-number-input"
import { InputLabel, InputProps, styles } from "../Input/Input"
import { useCallback, useEffect } from "react"
import getUnicodeFlagIcon from "country-flag-icons/unicode"
import React from "react"
import { classNames } from "src/utils/classNames"

export const DEFAULT_PHONE_NUMBER_PLACEHOLDER = "###-###-####"
export type PhoneInputProps = {
  value: string | undefined
  onChange: (phoneNumber: string | undefined) => void
  defaultCountry?: Country
  disabled?: boolean
} & Pick<InputProps, "id" | "name" | "size" | "label" | "heapName" | "placeholder" | "isOptional">

const PhoneInput = ({
  value,
  onChange,
  placeholder = DEFAULT_PHONE_NUMBER_PLACEHOLDER,
  defaultCountry,
  heapName,
  label,
  isOptional,
  size = "small",
  name,
  id = name,
  disabled = false,
}: PhoneInputProps) => (
  <div>
    <InputLabel htmlFor={id} label={label} isOptional={isOptional} size={size} />
    <div className="relative">
      <PhoneNumberInput
        inputComponent={ForwardRefInput}
        countrySelectComponent={CountrySelect}
        focusInputOnCountrySelection={false} // FIXME: would be ideal for this to be true, but it doesn't work
        defaultCountry={defaultCountry}
        value={value}
        onChange={onChange}
        // NOTE: following props pass through to input
        id={id}
        name={name}
        placeholder={placeholder}
        heapName={heapName}
        size={size}
        disabled={disabled}
      />
    </div>
  </div>
)

export default PhoneInput

const ForwardRefInput = React.forwardRef<
  HTMLInputElement,
  Pick<PhoneInputProps, "heapName" | "size" | "disabled">
>(({ size, heapName, disabled, ...props }, ref) => (
  // TODO: might be possible to use our Input here, but PhoneNumberInput requires forwardRef
  <input
    {...props}
    ref={ref}
    disabled={disabled}
    data-heap-name={heapName}
    className={classNames(
      "pl-16",
      styles[size ?? "small"].paddingY,
      "block w-full sm:text-sm text-neutral-1000 placeholder-neutral-600",
      disabled && "bg-neutral-300 text-neutral-800 border-neutral-400",
      "border rounded-md focus:ring-2 focus:outline-none",
      "focus:ring-accent-100 border-neutral-400"
    )}
  />
))

const CountrySelect = ({
  value,
  onChange,
  options,
  defaultCountry = "US",
  ...inputProps
}: {
  value: Country
  onChange: (c: string | undefined) => void
  defaultCountry?: Country
  options: { label: string; value: string | undefined }[]
} & Omit<React.HTMLProps<HTMLSelectElement>, "value" | "onChange">) => {
  useEffect(() => {
    if (!value) {
      onChange(defaultCountry)
    }
  }, [onChange, defaultCountry, value])

  const rawOnChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const { value: newValue } = event.target
      onChange(newValue)
    },
    [onChange]
  )

  return (
    <div className="absolute flex items-center left-0 top-0 bottom-0">
      <select
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...inputProps}
        className="pl-2 pr-4 border-0 text-xs bg-right bg-transparent"
        style={{ backgroundPosition: "right .25rem center" }}
        value={value || defaultCountry}
        onChange={rawOnChange}
      >
        {options.map(({ value: optionValue }) => (
          <option key={optionValue} value={optionValue}>
            {optionValue ? getUnicodeFlagIcon(optionValue) : null} {optionValue}
          </option>
        ))}
      </select>
    </div>
  )
}
