import { wrapIcon } from "./IconWrapper"

export const CommentFilledIcon = wrapIcon(
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.6667 1.33333H3.33334C2.80291 1.33333 2.2942 1.54404 1.91913 1.91911C1.54406 2.29419 1.33334 2.8029 1.33334 3.33333V9.99999C1.33334 10.5304 1.54406 11.0391 1.91913 11.4142C2.2942 11.7893 2.80291 12 3.33334 12H11.06L13.5267 14.4733C13.589 14.5351 13.6628 14.584 13.7441 14.6172C13.8253 14.6504 13.9123 14.6672 14 14.6667C14.0875 14.6689 14.1742 14.6506 14.2533 14.6133C14.3751 14.5633 14.4793 14.4784 14.5529 14.3692C14.6264 14.2601 14.666 14.1316 14.6667 14V3.33333C14.6667 2.8029 14.456 2.29419 14.0809 1.91911C13.7058 1.54404 13.1971 1.33333 12.6667 1.33333Z"
      fill="currentColor"
    />
  </svg>
)
