import { wrapIcon } from "./IconWrapper"

export const ChevronDownIcon = wrapIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.62623 5.29289C3.01675 4.90237 3.64991 4.90237 4.04044 5.29289L8 9.25245L11.9596 5.29289C12.3501 4.90237 12.9832 4.90237 13.3738 5.29289C13.7643 5.68342 13.7643 6.31658 13.3738 6.70711L8.70711 11.3738C8.31658 11.7643 7.68342 11.7643 7.29289 11.3738L2.62623 6.70711C2.2357 6.31658 2.2357 5.68342 2.62623 5.29289Z"
      fill="currentColor"
    />
  </svg>
)
