import { uniqueByRep } from "../../utils/data/Array/ArrayUtils"
import { Nonempty, unsafeAssertNonempty } from "../../utils/data/Array/Nonempty"
import { Either, Left, Right } from "../../containers/Either"
import { Nominal, makeNominal } from "../../utils/types/Nominal"
import { AccountIdFields } from "../Account"
import { viewCompanyIdFields, CompanyIdFields } from "../Company"
import { OffPlatformContact } from "../OffPlatformContact"
import { PriceObservationType } from "./pricing/PriceObservation"

/** Do not export */
const normalizedWitness: unique symbol = Symbol("normalized trade")

/** A single trade, which may correspond to multiple price observations: ideally we'll receive one from each party.
 */
export type Trade = {
  id?: string
  data: Nominal<
    {
      company: CompanyIdFields
      ids: string[]
      accountIds: string[]
      observations: PriceObservationType[]
      primaryObservation: PriceObservationType
      date: Date
    },
    typeof normalizedWitness
  >
}

export type TradeReference = {
  id?: string
  data: {
    company: CompanyIdFields
    observationIds: string[]
    primaryObservationId: string
    date: Date
  }
}

/** May throw if linked price observations are missing ids: this should never happen (and we should figure out how to handle saved vs. unsaved models in a better way) */
export const makeTradeReference = (t: Trade): TradeReference => {
  const primaryObservationId = t.data.primaryObservation.id
  if (primaryObservationId === undefined) {
    throw new Error(
      `trade ${t.id ?? "missing id"} for company ${
        t.data.company.name
      } has no id on its primary price observation`
    )
  }
  return {
    id: t.id,
    data: {
      company: t.data.company,
      observationIds: t.data.ids,
      primaryObservationId,
      date: t.data.date,
    },
  }
}

export type TradeParticipant =
  | {
      tag: "platform account"
      idFields: AccountIdFields
    }
  | {
      tag: "off platform"
      idFields: OffPlatformContact
    }

export type TradeCreationError = "multiple companies"

export const concatTrades = (l: Trade, r: Trade): Either<TradeCreationError, Trade> =>
  createTrade(
    unsafeAssertNonempty(
      uniqueByRep<PriceObservationType, string | undefined>((t) => t.id)(
        l.data.observations.concat(r.data.observations)
      )
    )
  )

export const makeTradeData = (
  obs: Nonempty<PriceObservationType>
): Either<TradeCreationError, Trade["data"]> => {
  const uniqueObs = uniqueByRep<PriceObservationType, string | undefined>((t) => t.id)(obs)
  return obs.every((o) => o.company.id === obs[0].company.id)
    ? Right(
        makeNominal(
          {
            ids: uniqueObs.map((o) => o.id).flatMap((i) => (i ? [i] : [])),
            accountIds: uniqueObs.map((o) => o.observedBy.id).flatMap((i) => (i ? [i] : [])),
            observations: uniqueObs,
            primaryObservation: uniqueObs[0],
            date: uniqueObs[0].observationDate.lowerBound,
            company: viewCompanyIdFields(uniqueObs[0].company),
          },
          normalizedWitness
        )
      )
    : Left("multiple companies")
}

export const createTrade = (
  obs: Nonempty<PriceObservationType>
): Either<TradeCreationError, Trade> => makeTradeData(obs).map((x) => ({ data: x }))
