import { Button } from "@stories/components/Button/Button"
import SearchSelect, { CreateNewOption } from "@stories/components/Inputs/SearchSelect/SearchSelect"
import Typography, { Size } from "@stories/components/Typography/Typography"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import {
  contactToDealCRMNoteContactSource,
  dealToDealCRMNoteContactSource,
} from "common/model/DealCRM/DealCRMNoteSourceConverters"
import { titleCaseWords } from "common/utils/StringUtils"
import { has } from "lodash"
import { useRef, useState } from "react"
import { GroupBase } from "react-select"
import CreateNewContactPopover from "../../Components/InlineContactSelectSearch/CreateNewContactPopover"
import { useCRMContacts } from "../../Providers/CRMContactsProvider"
import { useCRMDeals } from "../../Providers/CRMDealsProvider"
import { SearchableSource, useNoteModal } from "../NoteModalProvider"

const DisplaySelectedSource = () => {
  const { selectedSource, setSelectedSource } = useNoteModal()
  if (!selectedSource) {
    return null
  }

  return (
    <div className="flex space-x-1 px-2 py-1 items-center">
      <Typography text={selectedSource.name} size={Size.Small} />
      <Button
        label="change"
        variant="hollow"
        size="small"
        onClick={() => setSelectedSource(null)}
      />
    </div>
  )
}

const hasNameField = (
  item: SearchableSource | CreateNewOption | GroupBase<SearchableSource | CreateNewOption>
): item is SearchableSource => has(item, "name")

type SearchableSourceWithLabel = SearchableSource & { label: string }

export const NoteModalSearchSelect = () => {
  const { selectedSource, setSelectedSource } = useNoteModal()
  const { contacts } = useCRMContacts()
  const [creatingNewContact, setCreatingNewContact] = useState(false)
  const [createContactInitialName, setCreateContactInitialName] = useState<string>("")
  const popoverRef = useRef<HTMLDivElement>(null)

  const { deals } = useCRMDeals()
  const sources: SearchableSourceWithLabel[] = [
    ...contacts.map(contactToDealCRMNoteContactSource),
    ...deals.map(dealToDealCRMNoteContactSource),
  ].map((source) => ({ ...source, label: source.name }))

  const loadOptions = (inputValue: string) =>
    Promise.resolve(
      sources.filter((source) => source.name.toLowerCase().includes(inputValue.toLowerCase()))
    )

  const handleContactCreate = (input: string) => {
    setCreateContactInitialName(titleCaseWords(input.replaceAll("Create ", "")))
    setCreatingNewContact(true)
  }

  const afterContactCreated = (contact: DealCRMContact) => {
    setSelectedSource(contactToDealCRMNoteContactSource(contact))
    setCreatingNewContact(false)
    setCreateContactInitialName("")
    return Promise.resolve()
  }

  return !selectedSource ? (
    <>
      <div className={creatingNewContact ? "hidden" : ""}>
        <SearchSelect<SearchableSourceWithLabel>
          id="note-modal-search-select"
          autoFocus
          placeholder="Search for a contact or deal..."
          handleCreate={handleContactCreate}
          loadOptions={loadOptions}
          isValidNewOption={(input, _val, options) =>
            input.length > 2 &&
            !options.some((option) => hasNameField(option) && option.name === input)
          }
          renderOption={(option) => <span>{option.label}</span>}
          onChange={setSelectedSource}
          overrideStyles={(defaultStyles) => ({
            control: (base, props) => ({
              ...defaultStyles.control?.(base, props),
              border: "0",
            }),
            input: (base, props) => ({
              ...defaultStyles.input?.(base, props),
              border: "0",
            }),
          })}
          cacheOptions
        />
      </div>
      {creatingNewContact && (
        <div className="px-2 py-1">
          <CreateNewContactPopover
            innerRef={popoverRef}
            onContactCreated={afterContactCreated}
            sourceComponent="new-note"
            searchString={createContactInitialName}
            handleClose={() => setCreatingNewContact(false)}
            fullWidth
          />
        </div>
      )}
    </>
  ) : (
    <DisplaySelectedSource />
  )
}
