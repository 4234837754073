import { Dropdown } from "@components/inputs/Dropdown"
import { ChevronDownIcon } from "@heroicons/react/solid"
import { notification } from "@stories/components/Antd"
import { Button } from "@stories/components/Button/Button"
import { DeprecatedOrder } from "common/model/DeprecatedOrder"
import { RFQResponse, RFQType, rfqCompanyId, rfqCompanyName } from "common/model/RFQ/RFQ"
import { RFQCounterOffer } from "common/model/RFQ/V1RFQ"
import { isNil } from "lodash"
import numeral from "numeral"
import React from "react"
import { useWatchlistCompany } from "src/pages/issuer/useWatchlistCompany"
import { useFirebaseReader } from "../../firebase/Context"
import { WrappedDeprecatedOrder } from "../../model/WrappedDeprecatedOrder"
import { useLoggedInUser } from "../../providers/loggedInUser/useLoggedInUser"
import InfoTooltip from "../InfoTooltip"
import { formatValuation, formatValuationB } from "../displays/numeric/Currency"
import { DeprecatedNumberInput } from "../inputs"
import { CheckboxWithLabel } from "../inputs/checkbox/Checkbox"
import { formatAbbreviatedCurrency } from "common/utils/math/format"

export type CounterOfferProps = {
  order: DeprecatedOrder
  counterOffer: RFQCounterOffer | undefined
}

export const QuestionText: React.FC<React.PropsWithChildren<{ subtext?: boolean }>> = ({
  children,
  subtext,
}) => (
  <p className={`${!subtext ? "font-bold tracking-wide" : "font-light text-sm mt-2"}`}>
    {children}
  </p>
)

const OrderTerms: React.FC<
  React.PropsWithChildren<{ rfq: RFQType; forceExpand: boolean; showCta?: boolean }>
> = ({ rfq, forceExpand = false, showCta = true }) => {
  const [expanded, setExpanded] = React.useState<boolean>(forceExpand)
  const [discussDeal, setDiscussDeal] = React.useState<boolean>(false)

  const firebase = useFirebaseReader()
  const user = useLoggedInUser()

  const orderSizeString =
    rfq.tag === "current"
      ? rfq.orderTerms.valuation
        ? formatAbbreviatedCurrency(rfq.orderTerms.valuation)
        : "N/A"
      : new WrappedDeprecatedOrder(rfq.anchorOrder).notionalSizeString

  const orderTerms: {
    title: string
    titleTooltipText?: string
    value: string | undefined
    subtext?: string
  }[] = [
    {
      title: "Notional Size",
      titleTooltipText:
        "The notional size of the contract, representing the total value of the company shares at the current spot price.",
      value: orderSizeString,
    },
  ]

  const handleDiscussDeal = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setDiscussDeal(e.target.checked)

    if (e.target.checked) {
      notification.success({
        message: "Great, we'll be in touch shortly.",
        placement: "topRight",
        closeIcon: <div />,
      })
    }

    const update: Partial<RFQResponse> = {
      orderInterest: e.target.checked,
    }

    await firebase.updateRFQResponse(rfq.id, user.user.id, update)
  }
  const { toggleWatchlist, isWatched } = useWatchlistCompany(rfqCompanyId(rfq))

  return (
    <div className="w-full my-4 border rounded-md border-neutral-400">
      <div className={`w-full bg-neutral-100 rounded-sm ${expanded ? "p-6" : "p-2"}`}>
        {!expanded ? (
          <button
            id="view-rfq-order-terms-btn"
            type="button"
            className="cursor-pointer w-full flex flex-row items-center text-sm"
            onClick={() => setExpanded((s) => !s)}
          >
            View sellers tentative order terms <ChevronDownIcon height={24} width={24} />
          </button>
        ) : (
          <>
            <div className="mb-2 space-y-2">
              <h3 className="font-bold text-lg">Tentative Order Terms</h3>
              <h3 className=" text-md">
                A seller is considering a <span className="font-bold">{rfqCompanyName(rfq)}</span>{" "}
                sale at the following terms:
              </h3>
            </div>
            <div className="flex flex-col w-full border border-neutral-400 rounded-md bg-neutral-white space-y-6 p-4">
              {orderTerms.map((term) => (
                <div key={term.title} className="flex justify-between items-center">
                  <div className="font-medium text-sm text-neutral-900">
                    {term.titleTooltipText ? (
                      <div className="flex items-center">
                        {term.title}
                        <InfoTooltip text={term.titleTooltipText} />
                      </div>
                    ) : (
                      term.title
                    )}
                  </div>
                  <div className="flex flex-col items-end">
                    <p className="text-sm">{term.value || "-"}</p>
                    {term.subtext && <p className="text-xs font-light">{term.subtext || "-"}</p>}
                  </div>
                </div>
              ))}
            </div>
            {showCta && (
              <div className="my-2 w-full">
                <CheckboxWithLabel
                  title="I'd like to discuss this deal with Caplight"
                  id="rfq-interested-in-discussion-deal"
                  checked={discussDeal}
                  onChange={(e) => handleDiscussDeal(e)}
                />
                <CheckboxWithLabel
                  title={`Add ${rfqCompanyName(rfq)} to my watchlist`}
                  id="rfq-watchlist-company"
                  checked={isWatched}
                  onChange={toggleWatchlist}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

const CounterOffer: React.FC<React.PropsWithChildren<CounterOfferProps>> = ({
  order,
  counterOffer,
}) => {
  const durationOptions = [
    {
      id: "12",
      name: "12 Months",
    },
    {
      id: "24",
      name: "24 Months",
    },
    {
      id: "36",
      name: "36 Months",
    },
  ]

  if (!counterOffer) return null

  const updateFormState = <K extends keyof typeof counterOffer>(
    key: K,
    value: (typeof counterOffer)[K]
  ) => {
    const stateCopy = { ...counterOffer }

    stateCopy[key] = value
  }

  return (
    <div className="w-full mt-6">
      <div className="flex flex-col">
        <div className="mx-6 h-full mb-6">
          <QuestionText>Please provide your counter-offer terms</QuestionText>

          <QuestionText subtext>
            Feel free to submit tentative terms. This submission is non-binding.
          </QuestionText>
        </div>

        <form id="suggest_new_terms_form">
          <div className="w-full">
            <div className="w-full bg-neutral-100 flex flex-col items-center justify-center">
              <div className="w-11/12 space-y-6 mx-auto border-t border-b py-6 my-4 border-neutral-400">
                <div className="flex flex-row space-x-4 w-full justify-between">
                  <DeprecatedNumberInput
                    precision="integer"
                    name="size"
                    label="Notional Size"
                    onChange={(e) => updateFormState("size", e)}
                    addonBefore="$"
                    addonAfter={<div className="unclickable">M</div>}
                    value={counterOffer.size}
                    className="w-1/2"
                  />
                  <DeprecatedNumberInput
                    precision="decimal"
                    name="strikePricePerShare"
                    label="Strike"
                    addonBefore="$"
                    addonAfter={<div className="unclickable">/share</div>}
                    onChange={(e) => updateFormState("strikePricePerShare", e)}
                    value={counterOffer.strikePricePerShare}
                    className="w-1/2"
                    subtext={
                      counterOffer.strikePricePerShare && order.strikeValuation && order.strikePPS
                        ? `${formatValuation(
                            (order.strikeValuation / order.strikePPS) *
                              counterOffer.strikePricePerShare
                          )}B est. valuation`
                        : undefined
                    }
                  />
                </div>
                <div className="flex flex-row space-x-4 w-full justify-between">
                  <DeprecatedNumberInput
                    precision="decimal"
                    name="premiumPerShare"
                    label="Premium"
                    onChange={(e) => updateFormState("premiumPerShare", e)}
                    addonBefore="$"
                    addonAfter={<div className="unclickable">/share</div>}
                    value={counterOffer.premiumPerShare}
                    className="w-1/2"
                    subtext={
                      counterOffer.premiumPerShare && counterOffer.spotPricePerShare
                        ? `${(
                            (counterOffer.premiumPerShare / counterOffer.spotPricePerShare) *
                            100
                          ).toFixed(2)}% Premium`
                        : undefined
                    }
                  />
                  <div className="flex flex-col items-start w-1/2">
                    <Dropdown
                      selected={durationOptions.find(
                        (option) =>
                          parseInt(option.id, 10) === counterOffer.contractExpirationInMonths
                      )}
                      setSelected={(e) =>
                        updateFormState("contractExpirationInMonths", parseInt(e.id, 10))
                      }
                      label="Duration"
                      options={durationOptions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

const QuestionWrapper: React.FC<
  React.PropsWithChildren<{
    title: string
    subTitle?: string
    currentQuestion: number
    totalQuestions?: number
    navigateQuestions: (direction: "next" | "back") => void
    questionNum?: number
    canProgress: boolean | null
    forceRenderState?: "show" | "hide"
    additionalButtons?: JSX.Element[]
    progress?: number
  }>
> = ({
  children,
  title,
  subTitle,
  currentQuestion,
  totalQuestions,
  navigateQuestions,
  questionNum,
  canProgress,
  forceRenderState,
  additionalButtons,
  progress,
}) => {
  const nextQuestion = () => {
    if (currentQuestion === questionNum) {
      navigateQuestions("next")
      scrollToNextQuestion()
    }
  }

  const scrollToNextQuestion = () => {
    const questions = document.getElementsByClassName("rfq-question")
    const questionYs = Array.from(questions).map((question) => question.getBoundingClientRect().top)
    if (questionYs.length > 1) {
      const scrollDistance = questionYs[questionYs.length - 1] - questionYs[questionYs.length - 2]
      window.scrollBy(0, scrollDistance)
    }
  }

  if (questionNum === undefined) return null

  if ((currentQuestion < questionNum || forceRenderState === "hide") && forceRenderState !== "show")
    return null

  return (
    <div className="w-full bg-neutral-white rounded-md shadow border-1 border-neutral-400 p-4 my-4 border max-w-2xl rfq-question">
      <div className="flex justify-between">
        <div className="w-full mb-4 max-w-3/4">
          <QuestionText>{title}</QuestionText>
          {subTitle && <QuestionText subtext>{subTitle}</QuestionText>}
        </div>
        {forceRenderState !== "show" && totalQuestions && (
          <div className="pb-1 flex">
            <p className="text-sm text-neutral-600 whitespace-nowrap">
              {questionNum + 1} / {totalQuestions}
            </p>
          </div>
        )}
        {forceRenderState !== "show" && !totalQuestions && !isNil(progress) && (
          <div className="pb-1 flex">
            <p className="text-sm text-neutral-600 whitespace-nowrap">
              {numeral(progress).format("0%")} complete
            </p>
          </div>
        )}
      </div>
      <div className="flex flex-col px-6">{children}</div>
      <div className="w-full flex md:flex-row flex-col-reverse justify-end items-center py-2 px-8 md:space-x-2  md:space-y-0 space-y-4">
        {additionalButtons}
        {currentQuestion === questionNum && canProgress !== null && (
          <div className="w-full md:w-min">
            <Button
              label="Next"
              variant="primary"
              onClick={() => nextQuestion()}
              isDisabled={!canProgress}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export const QuestionComponents = {
  QuestionWrapper,
  OrderTerms,
  CounterOffer,
  QuestionText,
}
