export class Product<S, T> {
  readonly left: S

  readonly right: T

  constructor(left: S, right: T) {
    this.left = left
    this.right = right
  }

  extract() {
    return this.right
  }

  map<T2>(f: (t: T) => T2) {
    return new Product(this.left, f(this.right))
  }

  extend<T2>(f: (t: readonly [S, T]) => T2) {
    return new Product(this.left, f([this.left, this.right]))
  }
}
