import { classNames } from "src/utils/classNames"

type WrapperProps = {
  main: React.ReactNode
  // sidebar: React.ReactNode
  wrapperClasses?: string
  mainClasses?: string
}

const Wrapper = ({ main, wrapperClasses, mainClasses }: WrapperProps) => (
  <div className={classNames("w-full", "p-8 pt-0 mb-16")}>
    <div className={`w-full ${wrapperClasses ?? ""}`}>
      <div className={`w-full flex flex-col space-y-6 col-span-2 ${mainClasses ?? ""}`}>{main}</div>
    </div>
  </div>
)

const Footer = ({ footer }: { footer: React.ReactNode }) => (
  <div className="absolute bottom-0 p-8 w-full bg-neutral-white">{footer}</div>
)
const Header = ({ header }: { header: React.ReactNode }) => (
  <div className={classNames("w-full md:sticky z-50", "top-0 bg-neutral-white p-8 pb-4")}>
    {header}
  </div>
)

export default { Wrapper, Footer, Header }
