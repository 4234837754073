import { PageTitle } from "@components/typography/PageTitle"
import { Spin, Result } from "@stories/components/Antd"
import { AnimatePresence, motion } from "framer-motion"
import moment from "moment"
import { useOrderForm } from "../../OrderFormContext"
import { Button } from "@stories/components/Button/Button"
import { useDrawerControls } from "@components/Drawer/DrawerControlContext"

const SavedStatus = () => {
  const { savedOrder, formOrder } = useOrderForm()
  const { onClose } = useDrawerControls()

  return (
    <Result
      className="flex flex-row items-center justify-center space-y-0"
      status="success"
      extra={
        <div className="flex flex-col items-start mb-4 ml-4 space-y-4">
          <PageTitle
            title={`Your ${formOrder.direction === "buy" ? "Bid" : "Offer"} is renewed.`}
          />
          {savedOrder && formOrder ? (
            <>
              <div>{`This order will expire on ${moment(savedOrder._derived.liveUntil).format(
                "MMM D, YYYY"
              )} (${moment(savedOrder._derived.liveUntil).fromNow()})`}</div>
            </>
          ) : null}
          <Button label="Close" onClick={onClose} />
        </div>
      }
    />
  )
}
export const ErrorStatus = () => (
  <Result
    className="flex flex-row items-center justify-center space-y-0"
    status="warning"
    extra={
      <div className="flex flex-col items-start mb-4 ml-4">
        <PageTitle title="An Error Occurred" />
        <Button label="Close" onClick={useDrawerControls().onClose} />
      </div>
    }
  />
)
export const SavingStatus = () => (
  <div className="m-auto flex items-center justify-center space-x-4">
    <div className="flex flex-col h-32 justify-center">
      <Spin size="large" />
    </div>
    <div>
      <PageTitle title="Your order is renewing." />
    </div>
  </div>
)

export const RefreshOrderConfirmationPage = () => {
  const { saveStatus } = useOrderForm()
  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={saveStatus}
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 10, opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        {saveStatus === "saved" ? <SavedStatus /> : null}
        {saveStatus === "error" ? <ErrorStatus /> : null}
        {saveStatus === "saving" ? <SavingStatus /> : null}
      </motion.div>
    </AnimatePresence>
  )
}
