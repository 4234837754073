import { ReactNode, createContext, useMemo } from "react"
import { ButtonSize } from "../Button/Button"
import { classNames } from "src/utils/classNames"

type Context = {
  isGrouped: boolean
  size?: ButtonSize
  renderRawIcons?: boolean
  isFullWidth?: true
}

export const ButtonGroupContext = createContext<Context>({ isGrouped: false })

export type ButtonGroupProps = {
  children: ReactNode
} & Omit<Context, "isGrouped">

const ButtonGroup = ({ children, size, renderRawIcons, isFullWidth }: ButtonGroupProps) => {
  const value = useMemo(
    () => ({ isGrouped: true, size, renderRawIcons, isFullWidth }),
    [size, renderRawIcons, isFullWidth]
  )
  return (
    <ButtonGroupContext.Provider value={value}>
      <div className={classNames("flex", isFullWidth && "w-full")}>{children}</div>
    </ButtonGroupContext.Provider>
  )
}

export default ButtonGroup
