import Spinner from "@components/icons/Spinner"
import { Loading, isLoaded, isLoading } from "common/utils/Loading"
import { assertUnreachable } from "common/utils/fp/Function"
import { BrokerAccountTradingRegistrationForm } from "./BrokerTradingRegistrationForm"
import { FundAccountTradingRegistrationForm } from "./FundTradingRegistrationForm"
import { IndividualAccountTradingRegistrationForm } from "./IndividualTradingRegistrationForm"
import {
  AccountTradingRegistration,
  MinimumAccountTradingRegistration,
} from "common/model/Account/AccountTradingRegistration"

export const TradingRegistrationForm = ({
  tradingRegistration,
}: {
  tradingRegistration: Loading<
    Partial<AccountTradingRegistration> & MinimumAccountTradingRegistration
  >
}) =>
  isLoaded(tradingRegistration) && tradingRegistration.type === "individual-investor" ? (
    IndividualAccountTradingRegistrationForm
  ) : isLoaded(tradingRegistration) && tradingRegistration.type === "broker" ? (
    BrokerAccountTradingRegistrationForm
  ) : isLoaded(tradingRegistration) && tradingRegistration.type === "fund" ? (
    <FundAccountTradingRegistrationForm tradingRegistration={tradingRegistration} />
  ) : isLoading(tradingRegistration) ? (
    <Spinner size="xs" />
  ) : !tradingRegistration ? (
    "emtpy"
  ) : (
    assertUnreachable(tradingRegistration)
  )
