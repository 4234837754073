import "core-js/actual/array/to-sorted"
import "core-js/actual/array/to-reversed"
import "core-js/actual/array/to-spliced"
import { createBrowserHistory } from "history"
import React from "react"
import { createRoot } from "react-dom/client"
import App from "./App"
import { registerChartJsCharts } from "./components/charts/LineHoveredChartController"
import { registerNumeralLocale } from "./components/displays/numeric/Currency"
import "./index-compiled.css"
import reportWebVitals from "./reportWebVitals"
import { Datadog } from "./utils/Datadog"
import { initSentry } from "./utils/Sentry"

const history = createBrowserHistory()

initSentry(history)
Datadog.init()

registerNumeralLocale()
registerChartJsCharts()

const listenToDevEvents = () => {
  if (window.location.hostname.includes("localhost")) {
    new EventSource("/esbuild").addEventListener("change", () => window.location.reload())
  }
}

listenToDevEvents()

const tryGetRoot = () => {
  const root = document.getElementById("root")
  if (root === null) {
    throw new Error("Root element not found")
  }
  return root
}
const setDevFavicon = () => {
  if (window.location.hostname.includes("localhost")) {
    const link = document.querySelector("link[rel*='icon']") as HTMLLinkElement
    link.href = "/dev-favicon.ico"
  }
}

setDevFavicon()
const rootElement = tryGetRoot()
const root = createRoot(rootElement)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.info))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
