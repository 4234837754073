import SkeletonLoader from "@components/icons/SkeletonLoader"
import { useOrderRefreshModal } from "@components/OrderDetails/OrderDetailsWrapper"
import {
  createOrderFormFieldsFromOrder,
  updateOrderFromForm,
} from "common/model/Order/OrderForm/Constructors"
import { orderToInternal } from "common/model/Order/Models/UpdateLog"
import { Order as WrappedOrder } from "common/model/Order/Models/Wrapped"
import { AnimatePresence, motion } from "framer-motion"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { Button } from "@stories/components/Button/Button"
import { trackUserEventInFirestore } from "src/utils/Tracking"
import { useOwnOrderControls } from "../MyOrders/Controls/Context"
import RefreshOrderFormContainer from "../OrderForm/SpecializedOrderForms/Refresh/RefreshOrderFormContainer"
import { OrderPageLoadingOrder } from "./types"
import { Card } from "@stories/components/Card/Card"
import { FormOrder } from "common/model/Order/OrderForm/State"

export const InnerOrderPageRefreshContainer = ({ order }: OrderPageLoadingOrder) => {
  const { refreshOrder } = useOwnOrderControls()
  const { close } = useOrderRefreshModal()
  const firebase9 = useFirebase9()
  const user = useLoggedInUser()
  // order refresh
  const handleRefresh = async (formOrder: FormOrder, originalOrder: WrappedOrder) => {
    const refreshed = await refreshOrder(
      updateOrderFromForm(formOrder, orderToInternal(originalOrder.unboxed.order), {
        autoRenew: false,
      })
    )
    trackUserEventInFirestore(firebase9, user.user, "order-renewed")
    return refreshed
  }
  return order.match(
    (o) => (
      <RefreshOrderFormContainer
        onClose={() => close()}
        onSave={(formOrder) => handleRefresh(formOrder, o)}
        initialFormOrder={createOrderFormFieldsFromOrder(orderToInternal(o.unboxed.order))}
        initialOrderId={o.id()}
        drawerTitle="Renew Order"
        drawerSubtitle="Please review the terms of your order"
      />
    ),
    <div className="w-100">
      <SkeletonLoader numRows={4} />
    </div>,
    <Button variant="primary" label="Something went wrong, Close" onClick={close} />
  )
}

export const OrderPageRefreshContainer = ({
  order,
  refresh,
}: OrderPageLoadingOrder & { refresh?: boolean }) => (
  <AnimatePresence>
    {refresh ? (
      <motion.div
        initial={{ opacity: 0, y: 40 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 40 }}
      >
        <Card>
          <InnerOrderPageRefreshContainer order={order} />
        </Card>
      </motion.div>
    ) : null}
  </AnimatePresence>
)
