import MarketPill from "@stories/components/Badges/MarketPill/MarketPill"
import { TopBrokerPill } from "@stories/components/Badges/TopBrokerPill/TopBrokerPill"
import { StructureDisplay } from "@stories/components/Orders/TableCells/StructureCell"
import { UserIcon } from "@stories/icons/UserIcon"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { OwnOrder, Order as WrappedOrder } from "common/model/Order/Models/Wrapped"
import { Order } from "common/model/Order/Order"
import {
  sourceAttributionImmediateSource,
  sourceAttributionTail,
  sourceFirm,
  sourceName,
  sourceAttributionRootSource,
} from "common/model/data-product/DataPoint/SourceAttribution"
import { Loading_, isLoaded, deprecatedIsLoaded } from "common/utils/Loading"
import { Interval } from "common/utils/data/Interval"
import { times } from "common/utils/data/numeric/Arithmetic"
import { americanDate, localeDateFormat, relativeDateFormat } from "common/utils/dateUtils"
import { assertUnreachable, unspreadArgs } from "common/utils/fp/Function"
import { Maybe, Nothing } from "common/containers/Maybe"
import { useCompany } from "src/pages/Data/Hooks"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import { useTopBrokerAccountIds } from "src/providers/topBroker/TopBrokerProvider"
import { classNames } from "src/utils/classNames"
import { IntervalDisplay } from "../../../components/displays/Interval"
import { formatSharePrice, formatValuation } from "../../../components/displays/numeric/Currency"
import { OrderOpportunityInboxItem } from "../OrderOpportunityInbox/types"
import { getSharedOrderSourceFullName } from "common/model/SharedOrder/SharedOrder"
import { mostRecentOpportunitySource } from "common/model/Opportunity/Opportunity"
import { useMemo } from "react"
import { Tooltip } from "@stories/components/Antd/Tooltip/Tooltip"
import { ExclamationPointCircleIcon } from "@stories/icons/ExclamationPointCircleIcon"
import { useCRMContacts } from "src/pages/CRM/Providers/CRMContactsProvider"
import { useAccessControl } from "src/providers/AccessControl/AccessControlProvider"
import { getCRMContactEmail } from "common/model/DealCRM/DealCRMContact"
import { CRMContactName } from "src/pages/CRM/Contacts/ContactDetailsDrawer/CRMContactName"
import { useAccountTrustedBrokerConnections } from "src/providers/AccountTrustedBrokerConnections"
import { getCurrentConnectedNodes } from "common/model/TrustedBrokerConnection"

export { PriceVsCell } from "./PriceVsCell"

interface DirectionCellProps {
  direction: Order["direction"]
}

export const DirectionCell: React.FC<React.PropsWithChildren<DirectionCellProps>> = ({
  direction,
}) => (
  <div className="flex items-center w-16">
    <MarketPill variant={direction === "buy" ? "bid" : "offer"} />
  </div>
)

export const DirectionDot: React.FC<
  React.PropsWithChildren<{ direction: Order["direction"]; iconOnly?: boolean }>
> = ({ direction, iconOnly }) =>
  direction === "buy" ? (
    <div className="flex items-center gap-1">
      <div className="w-2 h-2 bg-success-500 rounded-full" />
      {iconOnly ? null : "Bid"}
    </div>
  ) : (
    <div className="flex items-center gap-1">
      <div className="w-2 h-2 bg-danger-600 rounded-full" />
      {iconOnly ? null : "Offer"}
    </div>
  )

export const LocaleDateCell: React.FC<React.PropsWithChildren<{ date: Date }>> = ({ date }) => (
  <>{localeDateFormat(date)}</>
)

export const RelativeDateCell: React.FC<React.PropsWithChildren<{ date: Date }>> = ({ date }) => (
  <Tooltip title={americanDate(date)}>
    <div className="whitespace-nowrap">{relativeDateFormat(date)}</div>
  </Tooltip>
)

export const SizeCell: React.FC<
  React.PropsWithChildren<{
    USDPerShare: Maybe<Interval<number>>
    amountUSD: Maybe<Interval<number>>
    shares: Maybe<Interval<number>>
  }>
> = ({ amountUSD, shares, USDPerShare }) => (
  <div className="flex flex-col text-sm">
    {amountUSD
      .or(USDPerShare.alongside(shares).map(unspreadArgs(Interval.lift2(times))))
      .map(IntervalDisplay(formatValuation, true))
      .map((x) => <div key={x}>{x}</div>)
      .withUnconstrainedDefault(null)}
  </div>
)

type PricePerShareAndValuationProps = {
  ppsText: string
  valText: string
  direction: "buy" | "sell"
  plaintext?: boolean
}

export const PricePerShareAndValuation = ({
  ppsText,
  valText,
  direction,
  plaintext,
}: PricePerShareAndValuationProps) => (
  <div className="flex gap-2 whitespace-nowrap">
    {ppsText ? (
      <p
        className={classNames(
          "font-semibold text-sm",
          plaintext ? "text-neutral-1000" : "py-1 px-2 text-white rounded",
          plaintext ? "" : direction === "buy" ? "bg-success-400" : "bg-danger-400"
        )}
      >
        {ppsText}
      </p>
    ) : null}
    {valText ? <p className="text-sm text-neutral-800">{valText}</p> : null}
  </div>
)

export const WrappedOrderPriceDisplay = ({
  order,
  plaintext,
}: {
  order: WrappedOrder
  plaintext?: boolean
}) => (
  <>
    {order.rawPrice().match(
      (val) => (
        <PricePerShareAndValuation
          plaintext={plaintext}
          direction={order.direction()}
          ppsText={order
            .impliedPricePerShare()
            .map(formatSharePrice)
            .match(
              (estPPS) => `${estPPS} (est.)`,
              () => ""
            )}
          valText={formatValuation(val.valuation)}
        />
      ),
      (pps) => (
        <PricePerShareAndValuation
          plaintext={plaintext}
          direction={order.direction()}
          ppsText={formatSharePrice(pps.pricePerShare)}
          valText={order
            .impliedValuation()
            .map(formatValuation)
            .match(
              (estVal) => `${estVal} (est.)`,
              () => ""
            )}
        />
      ),
      () => (
        <div className="flex w-full items-center gap-1">
          <ExclamationPointCircleIcon color={Color.Subtitle} />
          <Typography text="Unpriced" size={Size.Small} />
        </div>
      )
    )}
  </>
)

export const MyOrderPriceDisplay = ({ order }: { order: Order }) => {
  const company = useCompany(order.company.id)
  const wrappedOrder = useMemo(
    () =>
      OwnOrder.wrapOwnOrder({
        order,
        company: Loading_.toMaybe(company),
      }),
    [company, order]
  )

  return wrappedOrder.match(
    (o) => <WrappedOrderPriceDisplay plaintext order={o} />,
    () => null
  )
}

export const CommaSeparatedComponent =
  <T,>(f: (x: T) => React.ReactNode) =>
  (x: T, i: number, arr: T[]) =>
    (
      <div className="flex flex-item whitespace-nowrap" key={i.toString()}>
        {f(x)}
        {arr.length > 1 && i !== arr.length - 1 ? "," : ""}
      </div>
    )

interface StructureCellProps {
  order: WrappedOrder
  size: "sm" | "xs"
}

export const StructureCell: React.FC<StructureCellProps> = ({ order, size }) => (
  <>
    <StructureDisplay
      structures={order.currentStructures()}
      managementFee={order.managementFee()}
      carry={order.carry()}
      structureLayersCount={order.structureLayersCount()}
      size={size}
    />
  </>
)
const displayOrderShareClasses = ({
  shareClasses,
  direction,
}: Pick<Order, "shareClasses" | "direction">): string =>
  direction === "buy" && shareClasses.includes("common") && shareClasses.includes("preferred")
    ? "any"
    : shareClasses.join(", ")

export const ShareClassCell: React.FC<
  React.PropsWithChildren<Pick<Order, "shareClasses" | "direction">>
> = (order) => (
  <span className="capitalize whitespace-normal">{displayOrderShareClasses(order)}</span>
)

export const SourceCell: React.FC<React.PropsWithChildren<{ order: WrappedOrder }>> = ({
  order,
}) => {
  const topBrokerAccountIds = useTopBrokerAccountIds()
  const user = useCurrentUser()
  const currentTrustedBrokerConnections = useAccountTrustedBrokerConnections()
  const { findContactByEmail } = useCRMContacts()

  const sourceFromPrivateNetwork =
    isLoaded(currentTrustedBrokerConnections) && isLoaded(user)
      ? order.privateBrokerNetworkSource(
          getCurrentConnectedNodes(user.user.account, currentTrustedBrokerConnections).map(
            (n) => n.account
          )
        )?.submittingUser
      : null

  const contact = sourceFromPrivateNetwork
    ? findContactByEmail(sourceFromPrivateNetwork?.email)
    : null

  if (sourceFromPrivateNetwork) {
    return contact ? (
      <div className="-ml-2">
        <CRMContactName
          contact={contact}
          isSubtitleDisplayed
          isIconDisplayed={false}
          size="small"
        />
      </div>
    ) : (
      <Typography text={sourceFromPrivateNetwork?.email ?? "Trusted Broker Partner"} />
    )
  }

  const shouldDisplayTopBrokerPill = order.isFromTopBrokerAccount(
    topBrokerAccountIds.map((id) => ({ id }))
  )

  const isBroker = order.sourceClientType().includes("Intermediary")
  const isInvestorShareholder = order.sourceClientType().includes("Investor/Shareholder")
  const isBid = order.direction() === "buy"
  const investorOrShareholder = isBid ? "Investor" : "Shareholder"

  const clientType = (deprecatedIsLoaded(user) ? order.sourceAttribution(user.user) : Nothing)
    .bind(sourceAttributionRootSource)
    .bind(sourceFirm)
    .withDefault(isBroker ? "Broker" : isInvestorShareholder ? investorOrShareholder : "")

  return (
    <div className="whitespace-nowrap flex space-x-1 capitalize items-center">
      {shouldDisplayTopBrokerPill ? (
        <TopBrokerPill showTooltip size="small" />
      ) : (
        <p>{clientType}</p>
      )}
    </div>
  )
}
export const OpportunityInboxSourceCell: React.FC<
  React.PropsWithChildren<{ item: OrderOpportunityInboxItem }>
> = ({ item }) => {
  const { order, opportunity } = item
  const topBrokerAccountIds = useTopBrokerAccountIds()
  const user = useCurrentUser()

  const shouldDisplayTopBrokerPill =
    order.isFromTopBrokerAccount(topBrokerAccountIds.map((id) => ({ id }))) && !order.isPrivate

  const isBroker = order.sourceClientType().includes("Intermediary")
  const isInvestorShareholder = order.sourceClientType().includes("Investor/Shareholder")
  const isBid = order.direction() === "buy"
  const investorOrShareholder = isBid ? "Investor (Live Market)" : "Shareholder (Live Market)"

  const clientType = isBroker
    ? "Broker (Live Market)"
    : isInvestorShareholder
    ? investorOrShareholder
    : ""

  return (
    <div className="whitespace-nowrap flex gap-1 capitalize items-center">
      {opportunity.source.tag === "shared_order" ? (
        getSharedOrderSourceFullName({
          source: mostRecentOpportunitySource(opportunity.source.sharedOrders),
        })
      ) : opportunity.source.tag === "private" ? (
        <div className="flex flex-col gap-0">
          {(isLoaded(user) ? order.sourceAttribution(user.user) : Nothing)
            .bind(sourceAttributionTail)
            .bind(sourceAttributionImmediateSource)
            .match(
              (s) =>
                sourceName(s).match(
                  (name) => (
                    <>
                      <p>{name}</p>
                      <p className="text-xxs leading-snug">{sourceFirm(s).withDefault("")}</p>
                    </>
                  ),
                  () => <p>{sourceFirm(s).withDefault("")}</p>
                ),
              () => ""
            )}
        </div>
      ) : shouldDisplayTopBrokerPill ? (
        <TopBrokerPill showTooltip size="small" />
      ) : order.unboxed.order.base.orderCollection === "platform" ? (
        <Tooltip title="This opportunity is from the Caplight Live Market">
          <p>{clientType}</p>
        </Tooltip>
      ) : (
        <p>{clientType}</p>
      )}
    </div>
  )
}

export const UserAvatar: React.FC<
  React.PropsWithChildren<{ character?: string; size?: "sm" | "md" }>
> = ({ character, size = "md" }) => (
  <div className="flex items-center justify-center">
    <div
      className={`rounded-full  p-1  bg-neutral-300 flex items-center justify-center ${
        size === "md" ? "w-6 h-6" : size === "sm" ? "w-4 h-4" : assertUnreachable(size)
      }`}
    >
      {character ? (
        <Typography
          text={character.toUpperCase()[0]}
          weight={Weight.Semibold}
          color={Color.Dark}
          size={size === "md" ? Size.Small : size === "sm" ? Size.XXSmall : assertUnreachable(size)}
        />
      ) : (
        <UserIcon
          size={size === "md" ? "small" : size === "sm" ? "xs" : assertUnreachable(size)}
          color={Color.Dark}
        />
      )}
    </div>
  </div>
)
