import { ProductArea } from "common/model/Auth/Permissions"
import { Left, Right } from "common/containers/Either"
import { Navigate } from "react-router-dom"
import { Routes } from "../../../Routes/Routes"
import { AuthCondition } from "../../../Routes/auth/AuthCondition"
import { UnparameterizedSafeRouteEndpoint } from "../../../Routes/types"
import { hasAreaAccess } from "./ProductAreaConditions"
import { isAdminContext } from "../../../Routes/auth/UserAuthContext"

export const areaCheckWithRedirect =
  (area: ProductArea, redirectRoute: UnparameterizedSafeRouteEndpoint): AuthCondition =>
  (ctx) => {
    if (isAdminContext(ctx) || hasAreaAccess(ctx, area)) {
      return Right(ctx)
    }
    return Left(() => <Navigate to={redirectRoute(Routes)} />)
  }
