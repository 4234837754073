import { Modal, useModal } from "@components/layout/Modal"
import { defaultIfLoading, isLoading, matchLoading } from "common/utils/Loading"
import AllInterestTable from "src/pages/CRM/Interest/AllInterestTable"
import { useInterestTable } from "src/pages/CRM/Interest/useInterestTable"
import {
  ShareMyItemsProvider,
  useShareItemsContext,
} from "src/pages/Orders/MyOrders/ShareItemsProvider/ShareMyItemsProvider"
import {
  SharedOrderEditorProvider,
  useSharedOrderEditor,
} from "../SharedOrderEmailEditor/SharedOrderEditorProvider"
import { useState } from "react"
import { SharedOrderSelectContacts } from "../ShareOrderWorkflow"
import { Button } from "@stories/components/Button/Button"
import { ChevronRightIcon } from "@stories/icons/ChevronRightIcon"
import { SharedOrderGroupEmailEditor } from "../SharedOrderEmailEditor/SharedOrderGroupEditor"
import { SharedOrderMultiEmailEditor } from "../SharedOrderEmailEditor/SharedOrderMultiEditor"
import { ChevronLeftIcon } from "@stories/icons/ChevronLeftIcon"
import { assertUnreachable } from "common/utils/fp/Function"
import { multipleOrdersTemplate } from "../sharedOrderTemplates/multipleOrdersTemplate"
import Typography, { Weight } from "@stories/components/Typography/Typography"
import { useNavigate } from "react-router-dom"
import { Routes } from "src/Routes/Routes"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { TrustedBrokerNetworkReceivedInvites } from "src/pages/UserSettings/TrustedBrokerManagement/components/TrustedBrokerNetworkReceivedInvites"
import { useTrustedBrokerNetwork } from "src/pages/UserSettings/TrustedBrokerManagement/hooks/useTrustedBrokerNetwork"
import { SettingsModalButton } from "@components/Settings/SettingsModal/SettingsModalButton"
import {
  getCurrentConnectedNodes,
  getPendingInboundInvites,
} from "common/model/TrustedBrokerConnection"
import { useFirebaseWriter } from "src/firebase/Context"
import { handleConsoleError } from "src/utils/Tracking"
import { trackAccountActivationFirebaseWriter } from "src/utils/AccountActivation"
import { useCurrentAccount } from "src/queries/currentUser/useCurrentAccount"
import { daysBetweenDates } from "common/utils/dateUtils"

export const ShareOrderPopupInner = () => {
  const { user } = useLoggedInUser()
  const [step, setStep] = useState<
    "intro" | "select_items" | "select_contacts" | "customize_emails" | "pendingSharedWithMe"
  >("intro")
  const { currentTrustedBrokerConnections, handleRejectInvite, acceptInvite, maxInvitesReached } =
    useTrustedBrokerNetwork({ account: user.account })
  const { emails, editorMode, handleShareOrders, emailContent } = useSharedOrderEditor()
  const { open, closeModal } = useModal(true)
  const { data, columns } = useInterestTable({ side: "both" })
  const { selectedItems, onSelectItem } = useShareItemsContext()
  const firebase = useFirebaseWriter()
  const navigate = useNavigate()

  const columnSubset = columns.filter(
    (column) =>
      column.id === "company" ||
      column.id === "name" ||
      column.id === "direction" ||
      column.id === "pps" ||
      column.id === "valuation" ||
      column.id === "amount"
  )

  if (isLoading(currentTrustedBrokerConnections) || currentTrustedBrokerConnections?.length === 0)
    return null

  const handleSend = ({ sendEmails }: { sendEmails: boolean }) => {
    handleShareOrders({ sendEmails })
    navigate(Routes.shared.root)
    handleCloseModal().catch(handleConsoleError)
  }

  const recordInteraction = async () =>
    trackAccountActivationFirebaseWriter(firebase, user.account, "lastViewedTrustedBrokerReminder")

  const handleCloseModal = () => recordInteraction().then(() => closeModal())

  return (
    <Modal
      open={open}
      handleClose={handleCloseModal}
      closable={step === "intro"}
      title="What are you working on?"
      body={
        <>
          <div className="max-w-6xl mb-16">
            {step === "intro" && (
              <>
                <div className="grid grid-cols-2 gap-8">
                  <div className="flex flex-col gap-4">
                    <Typography
                      weight={Weight.Semibold}
                      text="Take 60 seconds to make sure your orderbook is up to date."
                    />
                    <Typography text="1. Add / update your client indications." />
                    <Typography text="2. Share deals with relevant colleagues or partners." />
                    <Typography text="3. See what your network is working on. " />
                  </div>
                  <div className="flex flex-col gap-4">
                    <TrustedBrokerNetworkReceivedInvites
                      connections={defaultIfLoading(currentTrustedBrokerConnections, [])}
                      maxInvitesReached={maxInvitesReached}
                      onAcceptInvite={acceptInvite}
                      onRejectInvite={handleRejectInvite}
                    />
                    <div className="flex justify-center">
                      <SettingsModalButton
                        label="Manage Your Network"
                        section="Trusted Broker Network"
                        size="large"
                        variant="secondary"
                      />
                    </div>
                  </div>
                </div>
                <div className="absolute bottom-0 left-0 right-0 bg-neutral-100 p-4 border-t z-20 flex">
                  <Button
                    size="xlarge"
                    label="Get Started"
                    onClick={() => {
                      setStep("select_items")
                    }}
                    isFullWidth
                  />
                </div>
              </>
            )}
            {step === "select_items" && (
              <div className="flex flex-col">
                <Typography text="Add what you're working on by adding company and direction." />
                <Typography text="Select orders you'd like to share by clicking the checkbox on the right." />
                <div className="border p-1 m-1 rounded-sm">
                <AllInterestTable
                  data={data}
                  columns={columnSubset}
                  filterBarComponents={[]}
                  showColumnConfigButton={false}
                  defaultPaginationPageSize={10}
                    advancedFilterComponents={[]}
                    afterCreateInterest={(crmInterest) =>
                      onSelectItem({ id: crmInterest.id, tag: "crm_interest", crmInterest })
                    }
                />
                </div>
                <div className="absolute bottom-0 left-0 right-0 bg-neutral-100 p-4 border-t z-20 flex justify-end gap-2">
                  <Button
                    size="large"
                    label="Cancel"
                    onClick={handleCloseModal}
                    variant="secondary"
                  />
                  <Button
                    size="large"
                    label="Select Colleagues"
                    rightIcon={<ChevronRightIcon />}
                    onClick={() => {
                      setStep("select_contacts")
                      emailContent.setEmailBody(emailContent.initialBody)
                      emailContent.setEmailSubject(emailContent.initialSubject)
                    }}
                    isDisabled={selectedItems.length === 0}
                  />
                </div>
              </div>
            )}
            {step === "select_contacts" && (
              <>
                <SharedOrderSelectContacts />
                <div className="absolute bottom-0 left-0 right-0 bg-neutral-100 p-4 border-t z-20 flex justify-end gap-2">
                  <Button
                    size="large"
                    label="Cancel"
                    onClick={handleCloseModal}
                    variant="secondary"
                  />
                  <Button
                    size="large"
                    variant="secondary"
                    label="Share without Email"
                    onClick={() => handleSend({ sendEmails: false })}
                    isDisabled={emails.length === 0}
                  />
                  <Button
                    size="large"
                    label="Finalize Email"
                    rightIcon={<ChevronRightIcon />}
                    onClick={() => {
                      setStep("customize_emails")
                      emailContent.setEmailBody(emailContent.initialBody)
                      emailContent.setEmailSubject(emailContent.initialSubject)
                    }}
                    isDisabled={emails.length === 0}
                  />
                </div>
              </>
            )}
            {step === "customize_emails" && (
              <>
                {editorMode === "group" ? (
                  <>
                    <SharedOrderGroupEmailEditor />
                    <div className="absolute bottom-0 left-0 right-0 bg-neutral-100 p-4 border-t z-20 flex justify-end gap-2">
                      <Button
                        size="large"
                        label="Back"
                        onClick={() => setStep("select_contacts")}
                        leftIcon={<ChevronLeftIcon />}
                        variant="secondary"
                      />
                      <Button
                        size="large"
                        label={`Share Orders (${emails.length})`}
                        rightIcon={<ChevronRightIcon />}
                        onClick={() => handleSend({ sendEmails: true })}
                      />
                    </div>
                  </>
                ) : editorMode === "individually_customized" ? (
                  <>
                    <SharedOrderMultiEmailEditor />
                    <div className="absolute bottom-0 left-0 right-0 bg-neutral-100 p-4 border-t z-20 flex justify-end gap-2">
                      <Button
                        size="large"
                        label="Back"
                        onClick={() => setStep("select_contacts")}
                        leftIcon={<ChevronLeftIcon />}
                        variant="secondary"
                      />
                      <Button
                        size="large"
                        label={`Share Orders (${emails.length})`}
                        rightIcon={<ChevronRightIcon />}
                        onClick={() => handleSend({ sendEmails: true })}
                      />
                    </div>
                  </>
                ) : (
                  assertUnreachable(editorMode)
                )}
              </>
            )}
          </div>
        </>
      }
    />
  )
}

const ShareOrderIntermediate = () => {
  const { selectedItems } = useShareItemsContext()

  return (
    <SharedOrderEditorProvider
      items={selectedItems}
      initialContacts={[]}
      templates={[multipleOrdersTemplate]}
    >
      <ShareOrderPopupInner />
    </SharedOrderEditorProvider>
  )
}

export const ShareOrderPopup = () => {
  const { user } = useLoggedInUser()
  const account = useCurrentAccount()
  const { currentTrustedBrokerConnections } = useTrustedBrokerNetwork({ account: user.account })

  const daysToRemind = [2, 3, 4, 5] // Tuesday, Wednesday, Thursday, Friday
  const isNotCorrectDayOfWeek = !daysToRemind.includes(new Date().getDay())
  const hasRecentlyBeenReminded = matchLoading(
    account,
    (a) =>
      a.accountActivation?.lastViewedTrustedBrokerReminder
        ? daysBetweenDates(new Date(), a.accountActivation?.lastViewedTrustedBrokerReminder) < 5
        : false,
    true,
    true
  )
  const doesNotHaveNetwork = matchLoading(
    currentTrustedBrokerConnections,
    (c) =>
      [...getCurrentConnectedNodes(user.account, c), ...getPendingInboundInvites(user.account, c)]
        .length === 0,
    true,
    true
  )

  if (doesNotHaveNetwork || hasRecentlyBeenReminded || isNotCorrectDayOfWeek) return null

  return (
    <ShareMyItemsProvider>
      <ShareOrderIntermediate />
    </ShareMyItemsProvider>
  )
}
