import { ExclamationCircleOutlined } from "@ant-design/icons"
import { useModal } from "@components/layout/Modal"
import Alert from "@stories/components/Alert/Alert"
import { Button } from "@stories/components/Button/Button"
import Typography, { Weight } from "@stories/components/Typography/Typography"
import { matchLoading } from "common/utils/Loading"
import { useLocation } from "react-router-dom"
import { getTradingRegistrationResponseTimeFrame } from "src/pages/TradeRegistration/CompletedAgreementPage"
import { useAccountSnapshot } from "src/providers/AccountSnapshot/useAccountSnapshot"
import { useAccountTradingRegistrationStatus } from "src/utils/useAccountTradingRegistration"
import { Routes } from "../../Routes/Routes"
import { TradingRegistrationRequiredModal } from "./TradingRegistrationRequiredModal"

const routesRequiringTradingRegistration: string[] = [Routes.orders.root, Routes.myOrders]

export const doesRouteRequireTradingRegistration = (currentRoute: string): boolean =>
  !!routesRequiringTradingRegistration.find((route) => currentRoute.includes(route))

const TradingRegistrationStatusBar = () => {
  const { accountSnapshot } = useAccountSnapshot()
  const location = useLocation()
  const { open, closeModal, openModal } = useModal()
  const {
    isAccountNonTransactional,
    isAccountApprovedForTrading,
    isAccountPendingApprovalForTrading,
  } = useAccountTradingRegistrationStatus()

  if (isAccountNonTransactional) {
    return null
  }

  if (isAccountApprovedForTrading) {
    return null
  }

  const accountTradingRegistrationType = matchLoading(
    accountSnapshot,
    (a) => a.selfReportedClientProfile ?? null,
    null,
    null
  )

  if (!doesRouteRequireTradingRegistration(location.pathname)) return null

  if (accountTradingRegistrationType === "employee") return null

  const pendingApprovalMessage = (
    <>
      <div className="flex items-center flex-wrap justify-between gap-y-2">
        <div className="flex items-center flex-wrap gap-2">
          <ExclamationCircleOutlined />
          <Typography text="Trading Registration Pending" weight={Weight.Semibold} />
          <Typography
            text={`We are reviewing your Trading Registration. ${
              accountTradingRegistrationType
                ? `This usually takes ${getTradingRegistrationResponseTimeFrame(
                    accountTradingRegistrationType
                  )}.`
                : ""
            }`}
          />
        </div>
      </div>
    </>
  )

  const pendingCompletionMessage = (
    <>
      <div className="flex items-center flex-wrap justify-between gap-y-2">
        <div className="flex items-center flex-wrap gap-2">
          <ExclamationCircleOutlined />
          <Typography text="Complete Trading Registration" weight={Weight.Semibold} />
          <Typography text="To gain full access to the Live Market and Caplight Connect, please complete your Trading Registration" />
        </div>
        <Button onClick={openModal} variant="secondary" label="Complete Trading Registration" />
      </div>
    </>
  )

  return (
    <div className="flex flex-col z-30 pb-4">
      <Alert
        variant="warning"
        isFullWidthBanner
        message={
          isAccountPendingApprovalForTrading ? pendingApprovalMessage : pendingCompletionMessage
        }
      />
      {open && !isAccountPendingApprovalForTrading ? (
        <TradingRegistrationRequiredModal open={open} dataType="status bar" onClose={closeModal} />
      ) : null}
    </div>
  )
}

export default TradingRegistrationStatusBar
