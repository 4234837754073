import { FC } from "react"

interface QuestionLabelProps {
  field: string
  text: string
  subtext?: string
}

export const QuestionLabel: FC<React.PropsWithChildren<QuestionLabelProps>> = ({
  field,
  text,
  subtext,
}) => (
  <>
    <label htmlFor={field} className="block text-base font-normal text-neutral-1000">
      {text}
    </label>
    {subtext && (
      <label htmlFor={field} className="block text-xs font-normal text-neutral-700 mt-2">
        {subtext}
      </label>
    )}
  </>
)
