import { head, unique } from "../../utils/data/Array/ArrayUtils"
import { UnsafeRec } from "../../utils/RecordUtils"
import { AccountPublicFields, viewAccountPublicFields, AccountIdFields } from "../Account"
import { User, UserPublicFields, viewUserPublicFields } from "../User"
import { ConnectThread, threadAccounts } from "./ConnectThread"

export type ThreadUserData = {
  user: UserPublicFields
  accountId: string
  account: AccountPublicFields
  roles: ConnectUserRole[]
  actionNeeded: boolean
  primaryParticipant: boolean | null
  lastReadDate: Date | null
  lastMessageDate: Date | null
}

export const defaultThreadUserData = (
  user: UserPublicFields,
  account: AccountIdFields
): ThreadUserData => ({
  actionNeeded: false,
  roles: [],
  lastReadDate: null,
  lastMessageDate: null,
  user: viewUserPublicFields(user),
  accountId: account.id,
  account: viewAccountPublicFields(account),
  primaryParticipant: null,
})

export const maxLastMessageDates = (
  dates: ThreadUserData["lastMessageDate"][]
): ThreadUserData["lastMessageDate"] => compareLastMessageDates(dates, Math.max)
export const compareLastMessageDates = (
  dates: ThreadUserData["lastMessageDate"][],
  fn: (...n: number[]) => number
): ThreadUserData["lastMessageDate"] => {
  const datesNoNulls = dates.flatMap((d) => (d ? [d.getTime()] : []))
  return datesNoNulls.length ? new Date(fn(...datesNoNulls)) : null
}

export const mergeThreadUserData = (l: ThreadUserData, r: ThreadUserData): ThreadUserData => ({
  roles: unique(l.roles.concat(r.roles)),
  actionNeeded: l.actionNeeded || r.actionNeeded,
  user: l.user ?? r.user,
  lastReadDate:
    l.lastReadDate && r.lastReadDate
      ? new Date(Math.max(l.lastReadDate.getTime(), r.lastReadDate.getTime()))
      : l.lastReadDate ?? r.lastReadDate,
  lastMessageDate: maxLastMessageDates([l.lastMessageDate, r.lastMessageDate]),
  accountId: l.accountId ?? r.accountId,
  account: l.account ?? r.account,
  primaryParticipant: l.primaryParticipant ?? r.primaryParticipant,
})

export const removeReadDates = (u: ThreadUserData): ThreadUserData => ({
  ...u,
  lastReadDate: null,
  lastMessageDate: null,
})

export const connectAccountRoles = ["requester", "anchor", "admin"] as const
export type ConnectAccountRole = (typeof connectAccountRoles)[number]
export const connectUserRoles = ["point", "other admin", "broker"] as const
export type ConnectUserRole = (typeof connectUserRoles)[number]

export type ThreadAccountData = {
  account: AccountPublicFields
  roles: ConnectAccountRole[]
  parentThreadId: string | null
}
export const mergeThreadAccountData = (
  l: ThreadAccountData,
  r: ThreadAccountData
): ThreadAccountData => ({
  roles: unique<ConnectAccountRole>(l.roles.concat(r.roles)),
  parentThreadId: l.parentThreadId ?? r.parentThreadId,
  account: l.account ?? r.account,
})

export const defaultThreadAccountData = (account: AccountPublicFields): ThreadAccountData => ({
  roles: [],
  parentThreadId: null,
  account: viewAccountPublicFields(account),
})

export const nonAdminThreadParticipants = (thread: ConnectThread): ThreadAccountData[] =>
  threadAccounts
    .views(thread)
    .filter(
      ([accountId, accountInfo]) =>
        !accountInfo.roles.includes("admin") &&
        (accountInfo.roles.includes("requester") || accountInfo.roles.includes("anchor"))
    )
    .map(([_id, data]) => data)

export const threadIsConnected = (thread: ConnectThread): boolean => {
  const roles = UnsafeRec.values(thread.participatingAccounts).flatMap((v) => v.roles)
  return roles.includes("anchor") && roles.includes("requester")
}
const otherNonAdminThreadParticipants = (user: User, thread: ConnectThread): ThreadAccountData[] =>
  threadAccounts
    .views(thread)
    .filter(
      ([accountId, accountInfo]) =>
        accountId !== user.account.id &&
        !accountInfo.roles.includes("admin") &&
        (accountInfo.roles.includes("requester") || accountInfo.roles.includes("anchor"))
    )
    .map(([_id, data]) => data)

export const otherThreadParticipantName = (user: User, thread: ConnectThread): string =>
  head(otherNonAdminThreadParticipants(user, thread)).match(
    (v) => (threadIsConnected(thread) && v.account.name ? v.account.name : "Caplight"),
    () => "Caplight"
  )
