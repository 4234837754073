import { wrapIcon } from "./IconWrapper"

export const SearchIcon = wrapIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="currentColor">
    <path
      d="M12.6 13.5333L8.86667 9.8C8.53333 10.0667 8.15 10.2778 7.71667 10.4333C7.28333 10.5889 6.82222 10.6667 6.33333 10.6667C5.12222 10.6667 4.09733 10.2471 3.25867 9.408C2.42 8.56889 2.00044 7.544 2 6.33333C2 5.12222 2.41956 4.09733 3.25867 3.25867C4.09778 2.42 5.12267 2.00044 6.33333 2C7.54444 2 8.56933 2.41956 9.408 3.25867C10.2467 4.09778 10.6662 5.12267 10.6667 6.33333C10.6667 6.82222 10.5889 7.28333 10.4333 7.71667C10.2778 8.15 10.0667 8.53333 9.8 8.86667L13.55 12.6167C13.6722 12.7389 13.7333 12.8889 13.7333 13.0667C13.7333 13.2444 13.6667 13.4 13.5333 13.5333C13.4111 13.6556 13.2556 13.7167 13.0667 13.7167C12.8778 13.7167 12.7222 13.6556 12.6 13.5333ZM6.33333 9.33333C7.16667 9.33333 7.87511 9.04156 8.45867 8.458C9.04222 7.87444 9.33378 7.16622 9.33333 6.33333C9.33333 5.5 9.04156 4.79156 8.458 4.208C7.87444 3.62444 7.16622 3.33289 6.33333 3.33333C5.5 3.33333 4.79156 3.62511 4.208 4.20867C3.62444 4.79222 3.33289 5.50044 3.33333 6.33333C3.33333 7.16667 3.62511 7.87511 4.20867 8.45867C4.79222 9.04222 5.50044 9.33378 6.33333 9.33333Z"
      fill="currentColor"
    />
  </svg>
)
