import { wrapIcon } from "./IconWrapper"

export const ChevronDoubleRightIcon = wrapIcon(
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.64 5.52666C5.51446 5.39759 5.3428 5.32367 5.16276 5.32117C4.98273 5.31867 4.80907 5.38779 4.68 5.51333C4.55093 5.63886 4.47701 5.81053 4.47451 5.99057C4.47201 6.1706 4.54113 6.34426 4.66667 6.47333L6.22667 7.99999L4.66667 9.52666C4.60418 9.58864 4.55458 9.66237 4.52074 9.74361C4.48689 9.82485 4.46947 9.91199 4.46947 9.99999C4.46947 10.088 4.48689 10.1751 4.52074 10.2564C4.55458 10.3376 4.60418 10.4114 4.66667 10.4733C4.72864 10.5358 4.80238 10.5854 4.88361 10.6193C4.96485 10.6531 5.05199 10.6705 5.14 10.6705C5.22801 10.6705 5.31514 10.6531 5.39638 10.6193C5.47762 10.5854 5.55136 10.5358 5.61333 10.4733L7.61333 8.47333C7.67582 8.41135 7.72541 8.33762 7.75926 8.25638C7.79311 8.17514 7.81053 8.088 7.81053 7.99999C7.81053 7.91199 7.79311 7.82485 7.75926 7.74361C7.72541 7.66237 7.67582 7.58864 7.61333 7.52666L5.64 5.52666ZM11.3067 7.52666L9.30667 5.52666C9.18113 5.40112 9.01087 5.3306 8.83333 5.3306C8.6558 5.3306 8.48554 5.40112 8.36 5.52666C8.23446 5.6522 8.16394 5.82246 8.16394 5.99999C8.16394 6.17753 8.23446 6.34779 8.36 6.47333L9.89333 7.99999L8.36 9.52666C8.29751 9.58864 8.24792 9.66237 8.21407 9.74361C8.18023 9.82485 8.1628 9.91199 8.1628 9.99999C8.1628 10.088 8.18023 10.1751 8.21407 10.2564C8.24792 10.3376 8.29751 10.4114 8.36 10.4733C8.42197 10.5358 8.49571 10.5854 8.57695 10.6193C8.65819 10.6531 8.74533 10.6705 8.83333 10.6705C8.92134 10.6705 9.00848 10.6531 9.08972 10.6193C9.17096 10.5854 9.24469 10.5358 9.30667 10.4733L11.3067 8.47333C11.3709 8.41313 11.4226 8.3408 11.4588 8.26051C11.4949 8.18022 11.5148 8.09357 11.5173 8.00555C11.5198 7.91753 11.5048 7.8299 11.4732 7.7477C11.4417 7.66551 11.3941 7.59038 11.3333 7.52666H11.3067Z"
      fill="currentColor"
    />
  </svg>
)
