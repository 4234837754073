import { assertUnreachable } from "../../utils/fp/Function"
import { Holding } from "../holdings/Holding"
import { DealCRMDeal } from "./Deal/DealCRMDeal"
import { DealCRMContact, viewDealCRMContactIdFields } from "./DealCRMContact"
import {
  DealCRMNoteContactSource,
  DealCRMNoteDealSource,
  DealCRMNoteHoldingSource,
} from "./DealCRMNote"

export const contactToDealCRMNoteContactSource = (
  contact: DealCRMContact
): DealCRMNoteContactSource => ({
  sourceType: "contact",
  sourceId: contact.id,
  name: contact.name,
  contactType: viewDealCRMContactIdFields(contact).tag,
  contact: viewDealCRMContactIdFields(contact),
})

export const dealToDealCRMNoteContactSource = (deal: DealCRMDeal): DealCRMNoteDealSource => ({
  sourceType: "deal",
  sourceId: deal.id,
  name: deal.name,
  contact: null,
  company: deal.company,
  createdAt: deal.createdAt,
})
export const holdingToDealCRMNoteHoldingSource = (holding: Holding): DealCRMNoteHoldingSource => ({
  sourceType: "holding",
  sourceId: holding.id,
  contact:
    holding.shareholder.tag === "crm_contact"
      ? holding.shareholder.contact
      : holding.shareholder.tag === "currentAccount"
      ? null
      : assertUnreachable(holding.shareholder),
  company: holding.company,
})
