import { assertUnreachable } from "../utils/fp/Function"
import { SourcePosition, compareSourcePositions } from "./SourcePosition"

export type ParseErrors = {
  position: SourcePosition
  messages: string[]
}
export const printErrors = (x: ParseErrors) =>
  `error at char ${x.position.char.toString()} of line ${x.position.line.toString()}:${x.messages.join(
    ";"
  )}`

export const concatErrors = (l: ParseErrors, r: ParseErrors): ParseErrors => {
  const comparison = compareSourcePositions(l.position, r.position)
  switch (comparison) {
    case "<":
      return r
    case ">":
      return l
    case "=":
      return {
        position: r.position,
        messages: l.messages.concat(r.messages),
      }
    default:
      return assertUnreachable(comparison)
  }
}
export const addMessage = (message: string) => (err: ParseErrors) => ({
  position: err.position,
  messages: [message].concat(err.messages),
})
export const replaceMessages = (message: string) => (err: ParseErrors) => ({
  position: err.position,
  messages: [message],
})
export const unexpectedTokenMessage = (t: string, expected?: string) =>
  `Unexpected token ${JSON.stringify(t)}${expected ? `Expected ${JSON.stringify(expected)}` : ""}`
export const EOF = (pos: SourcePosition) => ({
  position: pos,
  messages: ["Unexpected end of input"],
})
