import { DataAccessLevel } from "@components/auth/DataAccess"
import { Right } from "common/containers/Either"
import { Just, Nothing, nullableToMaybe } from "common/containers/Maybe"
import { AccountIdFields } from "common/model/Account"
import { Permissions, isDataCustomer } from "common/model/Auth/Permissions"
import { Order } from "common/model/Order/Models/Wrapped"
import { TradeStructure } from "common/model/Order/Types/Structure"
import { User } from "common/model/User"
import { Enriched } from "common/queries/pricing/PricingEnrichment"
import { connectExcludedAccountIds } from "common/utils/constants/flaggedAccounts"
import { unique } from "common/utils/data/Array/ArrayUtils"
import { intervalMidpoint } from "common/utils/data/numeric/NumberInterval"
import { identity } from "common/utils/fp/Function"
import moment from "moment"
import { DataRowItem } from "./DataTableRow"
import { unconfirmableYesNoToBoolean } from "common/model/UnconfirmableYesNo"

export const ALL_HISTORIC_DATA_EL_ID = "allHistoricDataTable"
export const DATA_TABLE_ROW_HEIGHT = 64

const restrictedTableDataTypes = [
  "transaction price",
  "transaction volume",
  "transaction valuation",
  "transaction date",
  "order price",
  "order valuation",
  "order volume",
  "structure",
] as const
type RestrictedTableDataType = (typeof restrictedTableDataTypes)[number]
export const tableDataAccessLevel = {
  "transaction price": "data",
  "transaction volume": "data",
  "transaction valuation": "data",
  "transaction date": "dataLite",
  "order price": (isConnectable = false) => (isConnectable ? "connect" : "dataLite"),
  "order valuation": (isConnectable = false) => (isConnectable ? "connect" : "dataLite"),
  "order volume": (isConnectable = false) => (isConnectable ? "connect" : "dataLite"),
  structure: "trading",
} satisfies Record<
  RestrictedTableDataType,
  DataAccessLevel | ((isConnectable?: boolean) => DataAccessLevel)
>

export const isRecentTrade = (t: Enriched<"trade">) =>
  moment().subtract(1, "month").isBefore(t.raw.observationDate.upperBound)

export const tradesToItems = (
  trades: Enriched<"trade">[],
  account: AccountIdFields & Permissions
): DataRowItem[] =>
  trades.map((obs) => ({
    dates: obs.pricePerShareDisplay.displayDate,
    itemType: "closed_trade",
    recordId: obs.raw.id,
    recordCollection: undefined,
    sourceAttribution: undefined,
    recordAccountId: obs.raw.observedBy.id,
    companyId: obs.raw.company.id,
    structures: unique(obs.raw.structure.entries.map((x) => x.key)).map(
      (x) => new TradeStructure(x)
    ),
    prices: Just(
      Right({
        pricePerShare: obs.pricePerShareDisplay.pricePerShare,
        impliedValuation: obs.valuationDisplay.map((v) => v.valuation),
      })
    ),
    notional: Just(obs.volumeDisplay.volumeUSD),
    splitAdjustments: obs.splitDisplay,
    showIntroductionRequestButton:
      isRecentTrade(obs) &&
      isDataCustomer(account) &&
      obs.raw.observedBy.id !== account.id &&
      !connectExcludedAccountIds.includes(obs.raw.observedBy.id),
    carry: nullableToMaybe(obs.raw.carriedInterest),
    managementFee: nullableToMaybe(obs.raw.managementFee),
    structureLayersCount: Nothing,
    subjectToROFR: Nothing,
    firmness: "N/A",
    transactionDocumentsOnHand: Nothing,
    shareClasses: [obs.raw.shareClass ?? "unknown"],
    rofr: Just(obs.raw.rofr),
    company: obs.raw.company,
  }))

export const ordersToItems = (orders: Order[], user: User): DataRowItem[] =>
  orders.map((order) => ({
    orderId: order.idFields(),
    itemType: order.direction(),
    dates: order.latestUpdateDate(),
    recordId: order.unboxed.order.base.id,
    recordCollection: order.unboxed.order.base.orderCollection,
    recordAccountId: order.unboxed.order.base.source.account.id,
    sourceAttribution: order.sourceAttribution(user).match(identity, () => null),
    companyId: order.company().id,
    prices: order.rawPrice().bimap(
      ({ valuation }) => ({
        valuation,
        impliedPPS: order.impliedPricePerShare(),
      }),
      ({ pricePerShare }) => ({
        pricePerShare,
        impliedValuation: order.impliedValuation(),
      })
    ),
    notional: order.amountUSD().map((x) => intervalMidpoint(x)),
    structures: order.currentStructures(),
    splitAdjustments: order.latestTermUpdate().cumulativeStockSplits(),
    showIntroductionRequestButton:
      order.isConnectable() && order.unboxed.order.base.source.account.id !== user.account.id,
    carry: order.carry(),
    managementFee: order.managementFee(),
    structureLayersCount: order.structureLayersCount(),
    subjectToROFR: nullableToMaybe(order.unboxed.order.base.brokerClientMetadata?.sellerROFR),
    firmness: order.unboxed.order.base.firmness ?? "unknown",
    transactionDocumentsOnHand: nullableToMaybe(
      unconfirmableYesNoToBoolean(
        order.unboxed.order.base.orderDocuments?.areTransactionDocumentOnHand
      )
    ),
    shareClasses: order.shareClasses(),
    rofr: Nothing,
    company: order.company(),
  }))

export const getNumCols = ({
  showIntroductionRequestColumn,
}: Record<"showIntroductionRequestColumn", boolean>): 5 | 6 => {
  if (showIntroductionRequestColumn) {
    return 6
  }
  return 5
}

export const shouldShowRecentTransactionToUser = (u: User) =>
  u.account.clientType.includes("Intermediary") ||
  (u.account.clientType.includes("Investor/Shareholder") && u.account.productAreas.includes("data"))

export type OrderQuarterAggregation = {
  year: number
  quarter: number
  quarterString: string
  bidVolume: number
  offerVolume: number
  bidCount: number
  offerCount: number
  vwapBidPrice: number
  vwapOfferPrice: number
  orders: Order[]
}

export type TradeQuarterAggregation = {
  year: number
  quarter: number
  quarterString: string
  volume: number
  count: number
  vwapPrice: number
  trades: Enriched<"trade">[]
}

export type QuarterAggregation = {
  year: number
  quarter: number
  quarterString: string
  // trade specific
  trades: Enriched<"trade">[]
  volume?: number
  count?: number
  vwapPrice?: number
  // order specific
  orders: Order[]
  bidVolume?: number
  offerVolume?: number
  bidCount?: number
  offerCount?: number
  vwapBidPrice?: number
  vwapOfferPrice?: number
}

export const aggregateOrdersByQuarter = (ordersToAggregate: Order[]): OrderQuarterAggregation[] => {
  const ordersByQuarterDefault: { [key: string]: Order[] } = {}
  const ordersByQuarter = ordersToAggregate
    .sort((a, b) => (moment(a.latestUpdateDate()).isBefore(moment(b.latestUpdateDate())) ? 1 : -1))
    .reduce((acc, o) => {
      const quarter = moment(o.latestUpdateDate()).quarter()
      const year = moment(o.latestUpdateDate()).year()
      const key = `${year}-Q${quarter}`
      acc[key] = acc[key] ? [...acc[key], o] : [o]
      return acc
    }, ordersByQuarterDefault)

  return Object.entries(ordersByQuarter).map(([quarterString, orders]) => {
    const bidOrders = orders.filter((o) => o.direction() === "buy")
    const offerOrders = orders.filter((o) => o.direction() === "sell")
    const bidVolume = bidOrders.reduce(
      (ac, o) => ac + o.amountUSD().map(intervalMidpoint).withDefault(0),
      0
    )
    const offerVolume = offerOrders.reduce(
      (a, o) => a + o.amountUSD().map(intervalMidpoint).withDefault(0),
      0
    )
    const vwapBidPrice = roundToTwoDecimals(calculateOrderVWAP(bidOrders))
    const vwapOfferPrice = roundToTwoDecimals(calculateOrderVWAP(offerOrders))

    const bidCount = bidOrders.length
    const offerCount = offerOrders.length

    const year = moment(orders[0].latestUpdateDate()).year()
    const quarter = moment(orders[0].latestUpdateDate()).quarter()

    return {
      year,
      quarter,
      quarterString,
      bidVolume,
      bidCount,
      offerVolume,
      offerCount,
      vwapBidPrice,
      vwapOfferPrice,
      orders,
    }
  })
}

const calculateOrderVWAP = (orders: Order[]): number => {
  const { totalVolume, totalPrice } = orders.reduce(
    (acc, order) => {
      const price = order.rawPrice().match(
        () => ({ pricePerShare: order.impliedPricePerShare().withDefault(0) }),
        (p) => p,
        () => null
      )

      const volume = order.amountUSD().map(intervalMidpoint).withDefault(0)

      if (price === null || volume === 0) {
        return acc
      }

      return {
        totalVolume: acc.totalVolume + volume,
        totalPrice: acc.totalPrice + volume * price.pricePerShare,
      }
    },
    { totalVolume: 0, totalPrice: 0 }
  )

  return totalPrice / totalVolume
}

export const aggregateTradesByQuarter = (
  tradesToAggregate: Enriched<"trade">[]
): TradeQuarterAggregation[] => {
  const tradesByQuarterDefault: { [key: string]: Enriched<"trade">[] } = {}
  const tradeByQuarter = tradesToAggregate
    .sort((a, b) =>
      moment(a.raw.observationDate.upperBound).isBefore(moment(b.raw.observationDate.upperBound))
        ? 1
        : -1
    )
    .reduce((acc, t) => {
      const quarter = moment(t.raw.observationDate.upperBound).quarter()
      const year = moment(t.raw.observationDate.upperBound).year()
      const key = `${year}-Q${quarter}`
      acc[key] = acc[key] ? [...acc[key], t] : [t]
      return acc
    }, tradesByQuarterDefault)

  return Object.entries(tradeByQuarter).map(([quarterString, trades]) => {
    const volume = trades.reduce((ac, t) => ac + t.volumeDisplay.volumeUSD, 0)
    const count = trades.length
    const vwapPrice = roundToTwoDecimals(calculateTradeVWAP(trades))

    const year = moment(trades[0].raw.observationDate.upperBound).year()
    const quarter = moment(trades[0].raw.observationDate.upperBound).quarter()

    return {
      year,
      quarter,
      quarterString,
      volume,
      count,
      vwapPrice,
      trades,
    }
  })
}

const calculateTradeVWAP = (trades: Enriched<"trade">[]): number => {
  const { totalVolume, totalPrice } = trades.reduce(
    (acc, trade) => {
      const price = trade.pricePerShareDisplay.pricePerShare
      const volume = trade.volumeDisplay.volumeUSD

      if (volume === 0 || price === 0) {
        return acc
      }

      return {
        totalVolume: acc.totalVolume + volume,
        totalPrice: acc.totalPrice + volume * price,
      }
    },
    { totalVolume: 0, totalPrice: 0 }
  )

  return totalPrice / totalVolume
}

const roundToTwoDecimals = (num: number) => Math.round((num + Number.EPSILON) * 100) / 100

export const mergeTradesAndOrders = (
  tradeAggregations: TradeQuarterAggregation[],
  orderAggregations: OrderQuarterAggregation[]
): QuarterAggregation[] => {
  const result: QuarterAggregation[] = []

  // Create maps of trade and order aggregations keyed by year-quarter string
  const tradeMap = new Map(tradeAggregations.map((a) => [`${a.year}-${a.quarter}`, a]))
  const orderMap = new Map(orderAggregations.map((a) => [`${a.year}-${a.quarter}`, a]))

  // Iterate over all unique year-quarter combinations and merge the trade and order aggregations
  const uniqueYearQuarters = Array.from(new Set([...tradeMap.keys(), ...orderMap.keys()]))

  uniqueYearQuarters.forEach((uniqueYearQuarter) => {
    const [year, quarter] = uniqueYearQuarter.split("-").map(Number)
    const key = `${year}-${quarter}`
    const tradeAgg = tradeMap.get(key)
    const orderAgg = orderMap.get(key)

    if (tradeAgg && orderAgg) {
      // Merge trade and order aggregations
      result.push({
        quarterString: tradeAgg.quarterString,
        trades: tradeAgg.trades,
        orders: orderAgg.orders,
        volume: tradeAgg.volume,
        count: tradeAgg.count,
        vwapPrice: tradeAgg.vwapPrice,
        bidVolume: orderAgg.bidVolume,
        offerVolume: orderAgg.offerVolume,
        bidCount: orderAgg.bidCount,
        offerCount: orderAgg.offerCount,
        vwapBidPrice: orderAgg.vwapBidPrice,
        vwapOfferPrice: orderAgg.vwapOfferPrice,
        year,
        quarter,
      })
    } else if (tradeAgg) {
      // Only trade aggregation is present
      result.push({
        quarterString: tradeAgg.quarterString,
        trades: tradeAgg.trades,
        volume: tradeAgg.volume,
        count: tradeAgg.count,
        vwapPrice: tradeAgg.vwapPrice,
        orders: [],
        year,
        quarter,
      })
    } else if (orderAgg) {
      // Only order aggregation is present
      result.push({
        quarterString: orderAgg.quarterString,
        orders: orderAgg.orders,
        bidVolume: orderAgg.bidVolume,
        offerVolume: orderAgg.offerVolume,
        bidCount: orderAgg.bidCount,
        offerCount: orderAgg.offerCount,
        vwapBidPrice: orderAgg.vwapBidPrice,
        vwapOfferPrice: orderAgg.vwapOfferPrice,
        trades: [],
        year,
        quarter,
      })
    }
  })

  return result
}

export const createMergedAggregation = (
  trades: Enriched<"trade">[],
  orders: Order[]
): QuarterAggregation[] => {
  const aggregatedTrades = aggregateTradesByQuarter(trades)
  const aggregatedOrders = aggregateOrdersByQuarter(orders)

  return mergeTradesAndOrders(aggregatedTrades, aggregatedOrders)
}
