import { wrapIcon } from "./IconWrapper"

export const NoteIcon = wrapIcon(
  <svg viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.93934 0.93934C1.22064 0.658035 1.60218 0.5 2 0.5H8C8.39782 0.5 8.77936 0.658035 9.06066 0.93934C9.34196 1.22064 9.5 1.60218 9.5 2V5.5C9.5 5.63261 9.44732 5.75979 9.35355 5.85355L5.85355 9.35355C5.75979 9.44732 5.63261 9.5 5.5 9.5H2C1.60218 9.5 1.22064 9.34196 0.93934 9.06066C0.658035 8.77936 0.5 8.39782 0.5 8V2C0.5 1.60218 0.658035 1.22064 0.93934 0.93934ZM2 1.5C1.86739 1.5 1.74021 1.55268 1.64645 1.64645C1.55268 1.74021 1.5 1.86739 1.5 2V8C1.5 8.13261 1.55268 8.25979 1.64645 8.35355C1.74021 8.44732 1.86739 8.5 2 8.5H5V6C5 5.73478 5.10536 5.48043 5.29289 5.29289C5.48043 5.10536 5.73478 5 6 5H8.5V2C8.5 1.86739 8.44732 1.74021 8.35355 1.64645C8.25979 1.55268 8.13261 1.5 8 1.5H2ZM7.79289 6H6V7.79289L7.79289 6Z"
      fill="currentColor"
    />
  </svg>
)
