import { OrderPriceSection } from "@components/OrderFormComponents/PriceSection"
import { useFormOrderLabels } from "@components/OrderFormComponents/useFormOrderLabels"
import { useOrderForm } from "../../OrderFormContext"
import { defaultIfLoading } from "common/utils/Loading"

const RefreshOrderForm = () => {
  const { setFormOrder: handleChange, formOrder, company } = useOrderForm()
  const { termSectionLabels } = useFormOrderLabels(formOrder)

  return (
    <div>
      {formOrder.direction && company ? (
        <>
          <OrderPriceSection
            title={termSectionLabels.title}
            titles={termSectionLabels.subtitles}
            value={formOrder}
            onChange={handleChange}
            company={defaultIfLoading(company, undefined)}
          />
        </>
      ) : (
        <div className="text-neutral-500 text-center">Select Company and Direction to continue</div>
      )}
    </div>
  )
}

export default RefreshOrderForm
