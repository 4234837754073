import { assertUnreachable } from "../../../utils/fp/Function"
import { Just, Maybe, Nothing, nullableToMaybe } from "../../../containers/Maybe"

type Source =
  | string /** Deprecated case */
  | {
      email?: string | null
      firm?: string | null
      name: string | null
    }

export type SourceAttribution =
  | {
      tag: "direct"
      source: Source
    }
  | {
      tag: "indirect"
      reportedBy: Source
      reportedToSourceBy: SourceAttribution
    }
  | {
      tag: "unknown"
    }

/** The root source in this SourceAttribution */
export const sourceAttributionRootSource = (attr: SourceAttribution): Maybe<Source> => {
  switch (attr.tag) {
    case "direct":
      return Just(attr.source)
    case "indirect":
      return sourceAttributionRootSource(attr.reportedToSourceBy).or(
        Just(attr.reportedBy)
      ) /** If it bottoms out in unknown, rewind to the last known source */
    case "unknown":
      return Nothing
    default:
      return assertUnreachable(attr)
  }
}

/** The top-level source in this SourceAttribution. On order documents this will be the account that provided the order data */
export const sourceAttributionImmediateSource = (attr: SourceAttribution): Maybe<Source> => {
  switch (attr.tag) {
    case "direct":
      return Just(attr.source)
    case "indirect":
      return Just(attr.reportedBy)
    case "unknown":
      return Nothing
    default:
      return assertUnreachable(attr)
  }
}

/** Unwraps one layer of a `SourceAttribution`, exposing the current top-level source's immediate contact as the new top-level source.  */
export const sourceAttributionTail = (attr: SourceAttribution): Maybe<SourceAttribution> => {
  switch (attr.tag) {
    case "direct":
      return Just(attr)
    case "indirect":
      return Just(attr.reportedToSourceBy)
    case "unknown":
      return Nothing
    default:
      return assertUnreachable(attr)
  }
}

export const sourceFirm = (s: Source) => (typeof s === "string" ? Just(s) : nullableToMaybe(s.firm))
export const sourceEmail = (s: Source) =>
  typeof s === "string" ? Nothing : nullableToMaybe(s.email)
export const sourceName = (s: Source) =>
  typeof s === "string"
    ? Nothing
    : s.name?.toLocaleLowerCase() === "unknown"
    ? Nothing
    : nullableToMaybe(s.name)

export const sourceAttributionString = (attr?: SourceAttribution | null): string =>
  nullableToMaybe(attr)
    .bind(sourceAttributionTail)
    .bind(sourceAttributionRootSource)
    .map((s) =>
      sourceName(s).match(
        (name) =>
          `${name} ${sourceFirm(s).match(
            (firm) => `(${firm})`,
            () => ""
          )}`,
        () => sourceFirm(s).withDefault("")
      )
    )
    .withDefault("")
