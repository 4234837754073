import { PriceData } from "common/model/Order/OrderForm/State"
import { isDefined } from "common/utils/TypeUtils"
import { Either, Left, Right } from "common/containers/Either"
import { useMemo } from "react"
import { FormLabel } from "../FormLabel/FormLabel"
import { RadioSelect } from "../RadioSelect/RadioSelect"
import { SizeInputBox } from "../SizeInputBox/SizeInputBox"
import { ValidationError } from "../ValidationError/ValidationError"

export type Version = "amountUSD" | "shares"
export type EitherVersion = Either<"amountUSD", "shares">

export interface SizeInputProps {
  value?: PriceData["size"]
  label?: string
  onChange: (size: PriceData["size"]) => void
  disabled?: boolean
  hasError?: boolean
  amountOnly?: boolean
}

export function SizeInput({
  value,
  label = "Size",
  onChange,
  disabled,
  hasError,
  amountOnly = false,
}: SizeInputProps) {
  const version: EitherVersion = value?.shares !== undefined ? Right("shares") : Left("amountUSD")
  const { upperBound: max, lowerBound: min } =
    version.match(
      () => value?.amountUSD,
      () => value?.shares
    ) ?? {}

  const minValErr = useMemo(() => (isDefined(min) ? min < 1 : false), [min])
  const minMaxCompareErr: boolean = useMemo(
    () => isDefined(min) && isDefined(max) && max > 0 && !(min <= max),
    [min, max]
  )

  const handleMaxChange = (upperBound: number) =>
    version.match(
      () => onChange({ amountUSD: { lowerBound: min ?? 0, upperBound } }),
      () => onChange({ shares: { lowerBound: min ?? 0, upperBound } })
    )

  const handleMinChange = (lowerBound: number) =>
    version.match(
      () => onChange({ amountUSD: { upperBound: max ?? 0, lowerBound } }),
      () => onChange({ shares: { upperBound: max ?? 0, lowerBound } })
    )

  const handleVersionChange = () =>
    version.match(
      () => onChange({ shares: null }),
      () => onChange({ amountUSD: null })
    )

  return (
    <>
      {amountOnly ? (
        <FormLabel hasError={hasError}>{label}</FormLabel>
      ) : (
        <RadioSelect<Version>
          label={label}
          className="-mb-1"
          options={[
            { value: "amountUSD", label: "Dollar Amount" },
            { value: "shares", label: "Share Count" },
          ]}
          value={version.toUnion()}
          onChange={handleVersionChange}
          disabled={disabled}
        />
      )}
      <div className="grid grid-cols-2 gap-4 w-full">
        <div>
          <SizeInputBox
            label="Minimum Size"
            version={version}
            value={min}
            onChange={handleMinChange}
            disabled={disabled}
          />
          {/* <Typography
            size={Size.XXSmall}
            color={Color.Placeholder}
            text={version.match(
              () => `${formatAbbreviatedCurrency(min ?? 0)} Minimum Size`,
              () => `${formatAbbreviatedNumber(min ?? 0, 0)} Minimum Shares`
            )}
          /> */}
        </div>
        <div>
          <SizeInputBox
            label="Maximum Size"
            version={version}
            value={max}
            onChange={handleMaxChange}
            disabled={disabled}
          />
          {/* <Typography
            size={Size.XXSmall}
            color={Color.Placeholder}
            text={version.match(
              () => `${formatAbbreviatedCurrency(max ?? 0)} Maximum Size`,
              () => `${formatAbbreviatedNumber(max ?? 0, 0)} Maximum Shares`
            )}
          /> */}
        </div>
      </div>
      {(minValErr || minMaxCompareErr) && (
        <div className="mt-2">
          {minValErr ? <ValidationError error="Min value must be greater than 0" /> : null}
          {minMaxCompareErr ? (
            <ValidationError error="Min value must be less than or equal to max value" />
          ) : null}
        </div>
      )}
    </>
  )
}
