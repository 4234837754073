import { Order } from "../model/Order/Order"
import { UserAccessFields } from "../model/User"
import { APISpec, getOne, getMany, apiParams, apiBody, apiLocals } from "./Spec"

export const OrderAPI = {
  order: {
    params: apiParams<{ orderId: string }>(),
    body: apiBody<{}>(),
    locals: apiLocals<{ user: UserAccessFields }>(),
    node: getOne<Order>(),
  },
  orders: {
    private: {
      body: apiBody<{}>(),
      node: getMany<Order>(),
      locals: apiLocals<{ user: UserAccessFields }>(),
    },
    platformList: {
      body: apiBody<{ orderIds: string[] }>(),
      node: getMany<Order>(),
      locals: apiLocals<{ user: UserAccessFields }>(),
    },
    privateList: {
      body: apiBody<{ orderIds: string[] }>(),
      node: getMany<Order>(),
      locals: apiLocals<{ user: UserAccessFields }>(),
    },
    darkpoolList: {
      body: apiBody<{ orderIds: string[] }>(),
      node: getMany<Order>(),
      locals: apiLocals<{ user: UserAccessFields }>(),
    },
    tentativeInterestList: {
      body: apiBody<{ orderIds: string[] }>(),
      node: getMany<Order>(),
      locals: apiLocals<{ user: UserAccessFields }>(),
    },
  },
  company: {
    params: apiParams<{ companyId: string }>(),
    node: {
      orders: {
        body: apiBody<{}>(),
        node: getMany<Order>(),
        locals: apiLocals<{ user: UserAccessFields }>(),
      },
      private: {
        body: apiBody<{}>(),
        node: getMany<Order>(),
        locals: apiLocals<{ user: UserAccessFields }>(),
      },
    },
  },
} satisfies APISpec
