import { Button } from "@stories/components/Button/Button"
import { Input } from "@stories/components/Inputs/Input/Input"
import { LabelValueList } from "@stories/components/LabelValueList/LabelValueList"
import { Size } from "@stories/components/Typography/Typography"
import { DealCRMBrokerContact } from "common/model/DealCRM/DealCRMBrokerContact"
import { useMemo } from "react"
import { UpdateContactDeprecatedProps } from "src/firebase/crm"
import { StringField } from "../../Components/InputFields/StringField"
import { useCRMContacts } from "../../Providers/CRMContactsProvider"
import CRMContactCopyEmail from "../ContactDisplay/ContactCopyEmail"
import { CRMContactName } from "./CRMContactName"
import { ContactFirmSelector } from "./ContactFirmSelector"
import PhoneInput from "@stories/components/Inputs/PhoneInput/PhoneInput"
import { getCRMContactEmail } from "common/model/DealCRM/DealCRMContact"

export const BrokerContactDetails = ({
  contact,
  updateContact,
}: {
  contact: DealCRMBrokerContact
  updateContact: (p: UpdateContactDeprecatedProps<DealCRMBrokerContact>) => void
}) => {
  const { contacts: allCRMContacts } = useCRMContacts()
  const associateContacts = useMemo(
    () =>
      allCRMContacts.filter(
        (crmContact) =>
          crmContact.tag !== "firm" &&
          crmContact.firm &&
          crmContact.firm.id === contact.firm?.id &&
          crmContact.id !== contact.id
      ),
    [allCRMContacts, contact.firm?.id, contact.id]
  )

  return (
    <div className="max-w-xl flex flex-row gap-4">
      <LabelValueList
        title="Details"
        items={[
          {
            id: "Brokerage",
            label: "Brokerage",
            value: contact.firm ? (
              <CRMContactName contact={contact.firm} />
            ) : (
              <ContactFirmSelector<DealCRMBrokerContact>
                contact={contact}
                onFirmSelected={(firm) =>
                  updateContact({
                    contact,
                    field: "firm",
                    fieldValue: firm,
                  })
                }
                renderButton={(onClick) => (
                  <div onClick={onClick}>
                    <Input placeholder="Brokerage" value="" readOnly />
                  </div>
                )}
              />
            ),
            actionButton: contact.firm ? (
              <ContactFirmSelector
                contact={contact}
                onFirmSelected={(firm) => {
                  updateContact({
                    contact,
                    field: "firm",
                    fieldValue: firm,
                  })
                }}
                renderButton={(onClick) => (
                  <Button
                    label="Change Brokerage"
                    onClick={onClick}
                    variant="hollow"
                    size="small"
                  />
                )}
              />
            ) : null,
          },
          {
            id: "Email",
            label: "Email",
            value: (
              <div className="flex gap-2 items-center">
                <StringField
                  placeholder="email@company.com"
                  value={contact.email}
                  onChange={(email) =>
                    updateContact({
                      contact,
                      field: "email",
                      fieldValue: email ?? "",
                    })
                  }
                />
                <CRMContactCopyEmail textSize={Size.Small} email={contact.email || ""} iconOnly />
              </div>
            ),
          },
          {
            id: "Phone",
            label: "Phone",
            value: (
              <PhoneInput
                value={contact.phone ?? undefined}
                onChange={(phone) =>
                  updateContact({
                    contact,
                    field: "phone",
                    fieldValue: phone ?? "",
                  })
                }
                heapName="crm-contact-drawer-phone-input"
              />
            ),
          },
        ]}
      />
      <LabelValueList
        title={`Associates ${contact.firm ? `at ${contact.firm.name}` : ""}`}
        items={associateContacts.map((associate) => ({
          label: <CRMContactName contact={associate} />,
          value: (
            <CRMContactCopyEmail
              textSize={Size.Small}
              email={getCRMContactEmail(associate) ?? ""}
            />
          ),
          id: associate.id,
        }))}
      />
    </div>
  )
}
