import { wrapIcon } from "./IconWrapper"

export const PlusIcon = wrapIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.6667 7.33333H8.66669V3.33333C8.66669 3.15652 8.59645 2.98695 8.47143 2.86193C8.3464 2.7369 8.17683 2.66666 8.00002 2.66666C7.82321 2.66666 7.65364 2.7369 7.52862 2.86193C7.40359 2.98695 7.33335 3.15652 7.33335 3.33333V7.33333H3.33335C3.15654 7.33333 2.98697 7.40357 2.86195 7.52859C2.73693 7.65362 2.66669 7.82319 2.66669 8C2.66669 8.17681 2.73693 8.34638 2.86195 8.4714C2.98697 8.59643 3.15654 8.66666 3.33335 8.66666H7.33335V12.6667C7.33335 12.8435 7.40359 13.013 7.52862 13.1381C7.65364 13.2631 7.82321 13.3333 8.00002 13.3333C8.17683 13.3333 8.3464 13.2631 8.47143 13.1381C8.59645 13.013 8.66669 12.8435 8.66669 12.6667V8.66666H12.6667C12.8435 8.66666 13.0131 8.59643 13.1381 8.4714C13.2631 8.34638 13.3334 8.17681 13.3334 8C13.3334 7.82319 13.2631 7.65362 13.1381 7.52859C13.0131 7.40357 12.8435 7.33333 12.6667 7.33333Z"
      fill="currentColor"
    />
  </svg>
)
