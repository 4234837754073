/* eslint-disable max-classes-per-file */
import moment, { Moment } from "moment"
import { collections, restrictedCollections } from "../../../../firestore/Collections"
import { Company } from "../../../../model/Company"
import { firestoreConverter } from "../../../../model/FirestoreConverter"
import { Order } from "../../../../model/Order/Order"
import { User } from "../../../../model/User"
import { PriceObservationType } from "../../../../model/data-product/pricing/PriceObservation"
import { Endo } from "../../../../utils/fp/Function"
import { nullableToMaybe } from "../../../../containers/Maybe"
import { CompanyCollection, CompanyRef } from "../../../pricing/PricingDataSource"
import { FirebaseQuery } from "../Collection"
import { FirebaseDocument } from "../Document"
import { FirebaseClosedTrades } from "./ClosedTrades"
import { FirebaseCompanyLiveOrders, FirebaseCompanyOrders } from "./Orders"
import { FirebaseStockSplits } from "./StockSplits"

export class FirebaseCompany extends FirebaseDocument<Company> implements CompanyRef {
  get = (forUser: User | null, asOf: Moment) =>
    this.unboxed
      .db()
      .collection(collections.companies)
      .withConverter<Company>(firestoreConverter<Company>())
      .doc(this.unboxed.id)
      .get()
      .then((x) => x.data())
      .then((x) => x ?? Promise.reject(new Error(`Company ${this.unboxed.id} does not exist`)))

  latestPriceObservation = async () => {
    const company = await this.get(null, moment())
    return nullableToMaybe(company.lastTrade)
  }

  priceObservations = () =>
    new FirebaseClosedTrades({
      ...this.unboxed,
      ref: this.unboxed.ref
        .collection(restrictedCollections.companySubcollections.priceObservations)
        .withConverter<PriceObservationType>(firestoreConverter<PriceObservationType>()),
    })

  orderObservations = () =>
    new FirebaseCompanyOrders({
      ...this.unboxed,
      ref: this.unboxed
        .db()
        .collection(restrictedCollections.orderObservations)
        .where("company.id", "==", this.unboxed.id)
        .withConverter<Order>(firestoreConverter<Order>()),
    })

  liveOrderObservations = () =>
    new FirebaseCompanyLiveOrders({
      ...this.unboxed,
      ref: this.unboxed
        .db()
        .collection(restrictedCollections.orderObservations)
        .where("company.id", "==", this.unboxed.id)
        .withConverter<Order>(firestoreConverter<Order>())
        .where("_derived.liveUntil", ">", new Date()),
    })

  stockSplits = (): FirebaseStockSplits =>
    new FirebaseStockSplits({
      company: this,
    })
}

export class FirebaseCompanies
  extends FirebaseQuery<Company, FirebaseCompanies>
  implements CompanyCollection
{
  get: (forUser: User | null, asOf: Moment) => Promise<Company[]> = () => this._rawGet()

  update = (f: Endo<typeof this.unboxed.ref>) =>
    new FirebaseCompanies({ ...this.unboxed, ref: f(this.unboxed.ref) })
}
