/* eslint-disable max-classes-per-file */
import { collectionReferences } from "../../../firestore/Collections"
import { FirebaseCommon } from "../../../firestore/Interface"
import { undefinedsToNulls } from "../../../utils/ObjectUtils"
import { Nothing } from "../../../containers/Maybe"
import { viewAccountIdFields } from "../../Account"
import { FormOrder } from "../../Order/OrderForm/State"
import { OrderLookupFields } from "../../Order/Models/Internal"
import { User, viewUserIdFields } from "../../User"
import { ConnectThread } from "../ConnectThread"
import { ConnectMessage, UnsentConnectMessage } from "../Message"
import { ThreadType } from "../ThreadTypes/ThreadType"
import { FirebaseThreadMessageCoordinator } from "./FirebaseThreadMessageCoordinator"

/** This is the only endpoint that should ever be used to send messages */
export const sendMessageToThread =
  (db: FirebaseCommon.DB) =>
  async <T extends ThreadType>(
    thread: Pick<ConnectThread<T>, "id">,
    message: UnsentConnectMessage<T>,
    emailNotifications: boolean = true
  ) => {
    const coordinator = new FirebaseThreadMessageCoordinator<T>(db, thread.id)
    return coordinator.sendMessageToThread(message, emailNotifications, thread)
  }

export type SaveDocumentOverlayProps = {
  thread: ConnectThread
  orderId: OrderLookupFields
  documentOverlay: Partial<FormOrder>
  previousDocumentOverlay: Partial<FormOrder>
  user: User
}
export const saveDocumentOverlay =
  (db: FirebaseCommon.DB) =>
  async ({
    thread,
    orderId,
    documentOverlay,
    previousDocumentOverlay,
    user,
  }: SaveDocumentOverlayProps) => {
    const message: UnsentConnectMessage<"connectRequest" | "orderInquiry"> = {
      author: {
        tag: "user",
        user: viewUserIdFields(user),
        account: viewAccountIdFields(user.account),
      },
      tag: "orderOverlayUpdate",
      payload: {
        orderId,
        before: undefinedsToNulls(previousDocumentOverlay),
        after: undefinedsToNulls(documentOverlay),
      },
    }
    await sendMessageToThread(db)(thread, message)
    return Nothing
  }
export const threadMessages =
  (db: FirebaseCommon.DB) =>
  <T extends ThreadType>(thread: ConnectThread<T>) => {
    // the redundant binding here serves to limit the scope of the type assertion and should not be removed
    // eslint-disable-next-line prefer-destructuring
    const messages: FirebaseCommon.Collection<ConnectMessage<ThreadType>> = collectionReferences
      .connectThreadSubcollections(thread.id)
      .messages(db)
    return messages as FirebaseCommon.Collection<ConnectMessage<T>>
  }
