import { wrapIcon } from "./IconWrapper"

export const XIcon = wrapIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.94002 8L11.8067 5.14C11.9322 5.01446 12.0027 4.8442 12.0027 4.66667C12.0027 4.48913 11.9322 4.31887 11.8067 4.19333C11.6812 4.0678 11.5109 3.99727 11.3334 3.99727C11.1558 3.99727 10.9856 4.0678 10.86 4.19333L8.00002 7.06L5.14002 4.19333C5.01448 4.0678 4.84422 3.99727 4.66669 3.99727C4.48915 3.99727 4.31889 4.0678 4.19335 4.19333C4.06782 4.31887 3.99729 4.48913 3.99729 4.66667C3.99729 4.8442 4.06782 5.01446 4.19335 5.14L7.06002 8L4.19335 10.86C4.13087 10.922 4.08127 10.9957 4.04743 11.0769C4.01358 11.1582 3.99615 11.2453 3.99615 11.3333C3.99615 11.4213 4.01358 11.5085 4.04743 11.5897C4.08127 11.671 4.13087 11.7447 4.19335 11.8067C4.25533 11.8692 4.32906 11.9187 4.4103 11.9526C4.49154 11.9864 4.57868 12.0039 4.66669 12.0039C4.75469 12.0039 4.84183 11.9864 4.92307 11.9526C5.00431 11.9187 5.07805 11.8692 5.14002 11.8067L8.00002 8.94L10.86 11.8067C10.922 11.8692 10.9957 11.9187 11.077 11.9526C11.1582 11.9864 11.2453 12.0039 11.3334 12.0039C11.4214 12.0039 11.5085 11.9864 11.5897 11.9526C11.671 11.9187 11.7447 11.8692 11.8067 11.8067C11.8692 11.7447 11.9188 11.671 11.9526 11.5897C11.9865 11.5085 12.0039 11.4213 12.0039 11.3333C12.0039 11.2453 11.9865 11.1582 11.9526 11.0769C11.9188 10.9957 11.8692 10.922 11.8067 10.86L8.94002 8Z"
      fill="currentColor"
    />
  </svg>
)
