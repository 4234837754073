import React, { useContext } from "react"
import Firebase, { FirebaseReader, FirebaseWriter } from "./Firebase"

const FirebaseContext: React.Context<Firebase> = React.createContext<Firebase>(
  null as unknown as Firebase
)

export default FirebaseContext

export const useFirebaseReader: () => FirebaseReader = () => useContext(FirebaseContext)
export const useFirebaseWriter: () => FirebaseWriter = () => useContext(FirebaseContext)
export const useFirebaseAdmin: () => Firebase = () => useContext(FirebaseContext)
