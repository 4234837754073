import { InboxMessageNotification } from "./InboxMessageNotification"
import { MyOrderReportNotification } from "./MyOrderReportNotification"
import { MyOrderStatusNotification } from "./MyOrderStatusNotification"
import { NewOrderNotification } from "./NewOrderNotification"
import { NewTradeNotification } from "./NewTradeNotification"
import { PriceAlertNotification } from "./PriceAlertNotification"
import { ProductAnnouncementNotification } from "./ProductAnnouncementNotification"
import { RFQNotification } from "./RFQNotification"
import { SharedOrderNotification } from "./SharedOrderNotification"
import { SharedOrderExpressedInterestNotification } from "./SharedOrderExpressedInterestNotification"
import { isHidden } from "../data-product/DataPoint"
import { TaggedNoteNotification } from "./TaggedNoteNotification"
import { RepliedNoteNotification } from "./RepliedNoteNotification"
import { NewDealDistributionNotification } from "./NewDealDistributionNotification"
import { assertUnreachable } from "../../utils/fp/Function"
import { MappedOmit } from "../../utils/types/MappedOmit"
import { NewMatchingOrderNotification } from "./NewMatchingOrderNotification"
import { viewCompanyIdsFromShareableItem } from "../SharedOrder/SharedOrderResponse"
import { Order } from "../Order/Models/Wrapped"
import { Nothing } from "../../containers/Maybe"
import { UserAccessFields } from "../User"
import { Loading } from "../../utils/Loading"
import { Account } from "../Account"
import { TrustedBrokerNetworkInviteNotification } from "./TrustedBrokerNetworkInvite"

export type NotificationUpdate = Required<MyOrderStatusNotification>["update"]

export type GenericNotification =
  | NewOrderNotification
  | NewMatchingOrderNotification
  | NewTradeNotification
  | MyOrderReportNotification
  | MyOrderStatusNotification
  | PriceAlertNotification
  | RFQNotification
  | ProductAnnouncementNotification
  | InboxMessageNotification
  | SharedOrderNotification
  | SharedOrderExpressedInterestNotification
  | TaggedNoteNotification
  | RepliedNoteNotification
  | NewDealDistributionNotification
  | TrustedBrokerNetworkInviteNotification

const isOrderNotificationHidden = (
  { order }: NewOrderNotification | NewMatchingOrderNotification,
  user: UserAccessFields,
  account: Loading<Account>
) => {
  const shouldHideWrappedOrder = Order.wrapRaw({ order, company: Nothing }).match(
    (o) => !o.canShowOnPlatform(user) || !o.canShowToAccount(account),
    () => true
  )

  return isHidden(order) || shouldHideWrappedOrder
}
export const notificationLinksToHiddenDocument = (
  n: GenericNotification,
  user: UserAccessFields,
  account: Loading<Account>
) => {
  switch (n.notificationType) {
    case "new_trade":
      return isHidden(n.trade)
    case "new_order": {
      return isOrderNotificationHidden(n, user, account)
    }
    case "new_matching_order": {
      return isOrderNotificationHidden(n, user, account)
    }
    default:
      return false
  }
}
export const notificationCompanyPostgresId = (
  notificationData: MappedOmit<GenericNotification, "id" | "user">
) => {
  switch (notificationData.notificationType) {
    case "new_order":
      return notificationData.order.company.postgresCompanyId
    case "new_matching_order":
      return notificationData.order.company.postgresCompanyId
    case "my_order_report":
      return notificationData.order.company.postgresCompanyId
    case "my_order_status":
      return notificationData.order.company.postgresCompanyId
    case "new_trade":
      return notificationData.trade.company.postgresCompanyId
    case "price_alert":
      return notificationData.company.postgresCompanyId
    case "shared_order":
    case "shared_order_expressed_interest":
      return viewCompanyIdsFromShareableItem(notificationData.item).postgresCompanyId
    case "rfq":
      return notificationData.rfq.company.postgresCompanyId
    case "product_announcement":
    case "trusted_broker_network_invite":
    case "inbox_message":
    case "crm_tagged_note":
    case "crm_replied_note": // might have companies ?
    case "crm_deal_distribution_received": // technically has companies but we can't check data
      return undefined
    default:
      return assertUnreachable(notificationData)
  }
}
