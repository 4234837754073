import { FirebaseFirestore, Query } from "@firebase/firestore-types"
import { orderBy } from "lodash"
import { collections } from "../../firestore/Collections"
import { head } from "../../utils/data/Array/ArrayUtils"
import { Maybe } from "../../containers/Maybe"
import { AccountIdFields } from "../Account"
import { firestoreConverter } from "../FirestoreConverter"
import { OrderInquiry, OrderInquiryAdminStatus } from "./Db"

export const orderInquiryCountsAgainstRemaining = (inquiry: OrderInquiry): boolean =>
  !inquiry.adminMetadata.adminDoNotCountAgainstRemaining

export const accountInquiriesQuery = (
  db: FirebaseFirestore,
  account: AccountIdFields
): Query<OrderInquiry> =>
  db
    .collection(collections.orderInquiries)
    .withConverter<OrderInquiry>(firestoreConverter<OrderInquiry>())
    .where("source.account.id", "==", account.id)

export const getMostRecentAdminStatus = (inquiry: OrderInquiry): Maybe<OrderInquiryAdminStatus> =>
  head(orderBy(inquiry.adminMetadata.adminStatusHistory, (x) => x.asOf.valueOf(), "desc"))
