import _ from "lodash"
import { DateISOStringWithTime } from "../postgres/PostgresCommon"
import {
  booleanValidator,
  enumValidator,
  isoDateWithTimeValidator,
  numberValidator,
  stringValidator,
  Validator,
} from "../../utils/Validator"
import { isISODateWithTime } from "../../utils/json/validate"
import { isDefined } from "../../utils/TypeUtils"

export type EnrichedFeed<T> = WithFilter<WithTimestamp<T>>
type WithTimestamp<T> = T & { timestamp: number }
type WithFilter<T> = T & { stringifiedFilter: string }

type ListenToNewUpdates = {
  lastId: number
  listeningFilters?: NewsFeedFilterSpec[]
  tag: "listen-to-new-updates"
}
/** @deprecated */
export type ScrollDownNotGrouped = {
  filters: NewsFeedFilterSpec[]
  oldestPublishedAt: DateISOStringWithTime
  scrollDownId: number
  tag: "scroll-down-not-grouped"
}
export type ScrollDownGrouped = {
  filters: NewsFeedFilterSpec[]
  scrollDownGroupId: string | null
  tag: "scroll-down-grouped"
  getTopNewsAsPartOfTheResponse: boolean
}
export type StandaloneRequest = {
  filters: NewsFeedFilterSpec[]
  tag: "standalone-request"
}
export type Controls =
  | ListenToNewUpdates
  | ScrollDownNotGrouped
  | StandaloneRequest
  | ScrollDownGrouped

export const noFilterString = ""

export const getFilterString = (c: Controls): string =>
  isStandaloneRequest(c)
    ? allFiltersIntoOneString(c.filters)
    : isScrollDownGroupedControl(c)
    ? allFiltersIntoOneString(c.filters)
    : isListeningToNewNewsControl(c)
    ? allFiltersIntoOneString(c.listeningFilters || [])
    : noFilterString

export const stringifyNewsFeedFilter = (filter: NewsFeedFilterSpec): string =>
  `${filter.field}___${filter.value}`

export interface NewsFeedFilterSpec {
  field: AllFilters
  value: string
}

export const allFiltersIntoOneString = (nfs: NewsFeedFilterSpec[]): string =>
  nfs.length === 0
    ? noFilterString
    : allFilters
        .map((f) => nfs.find((n) => n.field === f))
        .filter(isDefined)
        .map(stringifyNewsFeedFilter)
        .join("+++")

export const isNewsFeedFilter = (x: object): x is NewsFeedFilterSpec => "field" in x && "value" in x
export const isListeningToNewNewsControl = (x: object): x is ListenToNewUpdates =>
  "lastId" in x && typeof x.lastId === "number" && "tag" in x && x.tag === "listen-to-new-updates"

export const isScrollDownGroupedControl = (x: object): x is ScrollDownGrouped =>
  "scrollDownGroupId" in x &&
  (typeof x.scrollDownGroupId === "string" || x.scrollDownGroupId === null) &&
  "filters" in x &&
  _.isArray(x.filters) &&
  (x.filters.every(isNewsFeedFilter) || x.filters.length === 0) &&
  "tag" in x &&
  x.tag === "scroll-down-grouped"

export const isStandaloneRequest = (x: object): x is StandaloneRequest =>
  "oldestPublishedAt" in x &&
  isISODateWithTime(x.oldestPublishedAt) &&
  !("scrollDownId" in x) &&
  "filters" in x &&
  _.isArray(x.filters) &&
  x.filters.every(isNewsFeedFilter) &&
  "tag" in x &&
  x.tag === "standalone-request"

export interface FundingRoundAnnouncement {
  id: number
  groupingId: string
  publishedAt?: DateISOStringWithTime
  name?: string
  url?: string
  investors?: string
  description?: string
  source?: string
  sector?: string
  location?: string
  amount?: string
  currency?: string
  numeric_amount?: number
  round_name?: string
  pbid?: string
  postgresCompanyId?: string
  full_text?: string
  numberOfOrdersPastYear?: number
  manuallyRemoved?: boolean
}
export const validateFundingRoundAnnouncement: Validator<unknown, FundingRoundAnnouncement> =
  Validator.fromRecord({
    id: numberValidator.validate,
    groupingId: stringValidator.validate,
    url: enumValidator(undefined).or(stringValidator).validate,
    name: enumValidator(undefined).or(stringValidator).validate,
    publishedAt: enumValidator(undefined).or(isoDateWithTimeValidator).validate,
    investors: enumValidator(undefined).or(stringValidator).validate,
    description: enumValidator(undefined).or(stringValidator).validate,
    source: enumValidator(undefined).or(stringValidator).validate,
    sector: enumValidator(undefined).or(stringValidator).validate,
    location: enumValidator(undefined).or(stringValidator).validate,
    amount: enumValidator(undefined).or(stringValidator).validate,
    currency: enumValidator(undefined).or(stringValidator).validate,
    numeric_amount: enumValidator(undefined).or(numberValidator).validate,
    round_name: enumValidator(undefined).or(stringValidator).validate,
    pbid: enumValidator(undefined).or(stringValidator).validate,
    postgresCompanyId: enumValidator(undefined).or(stringValidator).validate,
    numberOfOrdersPastYear: enumValidator(undefined).or(numberValidator).validate,
    full_text: enumValidator(undefined).or(stringValidator).validate,
  })

export interface News {
  id: number
  groupingId: string
  title: string
  link?: string
  publishedAt?: DateISOStringWithTime
  classification?: "positive" | "negative" | null | undefined
  company?: {
    name: string
    postgresCompanyId: string
    pbid: string
    isCaplightDataCompany: boolean
    companyStatus?: "public" | "private"
    numberOfOrdersPastYear?: number
    valuation?: number
    primarySector?: string
  }
  publicationName?: string
  relevance: {
    relevanceScore: number
    isMarketEvent: boolean
    highlighted: boolean // previous market events
    manuallyRemoved?: boolean
  }
}

export const validateNews: Validator<unknown, News> = Validator.fromRecord({
  id: numberValidator.validate,
  groupingId: stringValidator.validate,
  title: stringValidator.validate,
  link: enumValidator(undefined).or(stringValidator).validate,
  publicationName: enumValidator(undefined).or(stringValidator).validate,
  publishedAt: enumValidator(undefined).or(isoDateWithTimeValidator).validate,
  classification: enumValidator(undefined).or(
    enumValidator<"positive" | "negative">("positive", "negative")
  ).validate,
  company: enumValidator(undefined).or(
    Validator.fromRecord({
      name: stringValidator.validate,
      postgresCompanyId: stringValidator.validate,
      pbid: stringValidator.validate,
      isCaplightDataCompany: booleanValidator.validate,
      numberOfOrdersPastYear: enumValidator(undefined).or(numberValidator).validate,
      companyStatus: enumValidator(undefined).or(
        enumValidator<"public" | "private">("public", "private")
      ).validate,
      valuation: enumValidator(undefined).or(numberValidator).validate,
      primarySector: enumValidator(undefined).or(stringValidator).validate,
    })
  ).validate,
  relevance: Validator.fromRecord({
    relevanceScore: numberValidator.validate,
    isMarketEvent: booleanValidator.validate,
    highlighted: booleanValidator.validate,
  }).validate,
})

/** Controller part */
export type Variant = "news" | "funding_rounds"
export type NewsfeedFilterFn = (t: News) => boolean
export type FundingRoundAnnouncementFilterFn = (t: FundingRoundAnnouncement) => boolean
export type FilterFnRecord = {
  news: {
    function: NewsfeedFilterFn
    asStrings: NewsFeedFilterSpec
  }
  funding_rounds: {
    function: FundingRoundAnnouncementFilterFn
    asStrings: NewsFeedFilterSpec
  }
}
export type NewsContainerControllerProps<T extends Variant> = {
  variant: T
  isAdmin: boolean
  setAdminNews?: (news: EnrichedFeed<News>[]) => void
  setFilterFunctions: (fns: FilterFnRecord[T][]) => void
  filterFunctions: FilterFnRecord[T][]
}

export const allFilters = [
  "excludePublic",
  "onlyWatchlist",
  "excludeCompaniesWithoutIoIs",
  "fundingRoundAbove",
  "amountAbove",
  "company",
  "investor",
  "companyPbid", // used on the issuer page only
  "valuationAbove",
  "sectors",
  "onlyHighlighted",
  "afterDate", // published after date (">=")
  "beforeDate", // published before date ("<"); unless "scroll_down_id is supplied", and then the request will be:
  // x.published < beforeDate OR (x.published == beforeDate AND x.id < scroll_down_id)
] as const

export type AllFilters = (typeof allFilters)[number]

export type FiltersThatAreSet<V extends Variant> = Record<AllFilters, FilterFnRecord[V] | undefined>

export const findValueStringFromFunctions = <V extends Variant>(
  k: AllFilters,
  filterFunctions: FilterFnRecord[V][]
) => {
  const filter = filterFunctions.find((v) => v.asStrings.field === k)
  return filter ? filter.asStrings.value : null
}
