import { Lambda } from "common/utils/fp/WrappedFunction"

/** Renders a Map<string, number> as a list of key-value pairs, with the values formatted as percentages.
 */

import _ from "lodash"
import numeral from "numeral"

export const formatShareAsPercent = (val: number | undefined, precision: number = 1) =>
  precision === 0
    ? numeral(val).format("0%")
    : numeral(val).format(`0.${_.repeat("0", precision)}%`)

export const PercentageList = ({
  listClassName,
  entryClassName,
  percentages,
}: {
  listClassName?: string
  entryClassName?: string
  percentages: Map<string, number>
}) => (
  <div className={listClassName}>
    {Array.from(percentages.keys()).map((k) => (
      <div key={k} className={entryClassName}>
        <span>{`${(100.0 * (percentages.get(k) || 0.0)).toFixed(0)}%`}</span>
        <span>{k}</span>
      </div>
    ))}
  </div>
)
export const percentageList = (listClassName?: string, entryClassName?: string) =>
  new Lambda((percentages: Map<string, number>) => (
    <PercentageList
      listClassName={listClassName}
      entryClassName={entryClassName}
      percentages={percentages}
    />
  ))
