import { User } from "common/model/User"
import { DerivedUserOnlineStatus, OnlineStatus } from "common/model/UserOnlineStatus"
import * as React from "react"
import Firebase9 from "src/firebase/Firebase9"
import { useFirebase9 } from "../../firebase/Firebase9Context"
import { useLoggedInUser } from "../../providers/loggedInUser/useLoggedInUser"
import { trackUserEventInFirestore } from "../../utils/Tracking"
import { classNames } from "../../utils/classNames"
import { useUserOnlineStatus } from "src/queries/currentUser/useUserStatus"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { ChatIcon } from "@stories/icons/ChatIcon"
import { Button } from "@stories/components/Button/Button"

export const toggleChat = (
  firebase: Firebase9,
  user: User,
  isShown?: boolean,
  prepopulatedMessage?: string,
  intercomMethod: "showMessages" | "showNewMessage" = "showMessages"
) => {
  if (!isShown) {
    trackUserEventInFirestore(firebase, user, "chat-opened")
  }
  if (window.Intercom)
    window.Intercom(
      isShown ? "hide" : intercomMethod,
      intercomMethod === "showNewMessage" ? prepopulatedMessage : undefined
    )
}

const IntercomChat: React.FunctionComponent<{ hide: boolean }> = ({ hide }) => {
  const [isShown, setIsShown] = React.useState(false)
  const { getAdminUserStatusSnapshots } = useUserOnlineStatus()
  const [adminStatus, setAdminStatus] = React.useState<Exclude<OnlineStatus, "offline">>()

  const user = useLoggedInUser()
  const firebase9 = useFirebase9()

  React.useEffect(() => {
    const handleAdminStatusUpdates = (adminStatuses: DerivedUserOnlineStatus[]) => {
      // Status is online if any admin is online or idle
      // Otherwise status is idle
      if (adminStatuses.some((status) => status.lastStatus !== "offline")) {
        setAdminStatus("active")
      } else {
        setAdminStatus("idle")
      }
    }
    const subscriber = getAdminUserStatusSnapshots(handleAdminStatusUpdates)
    return () => {
      subscriber() // unsubscribe from the firestore snapshot listener
    }
  }, [user, getAdminUserStatusSnapshots])

  React.useEffect(() => {
    if (window.Intercom) {
      window.Intercom("onShow", () => {
        setIsShown(true)
      })
      window.Intercom("onHide", () => {
        setIsShown(false)
      })
    }
  }, [])

  if (hide) {
    return <ChatIcon color={Color.White} />
  }

  const showAvatar = user.user.account.name.charAt(0).toLowerCase() < "m"
  const avatar = (
    <img
      src="https://firebasestorage.googleapis.com/v0/b/caplight-prod.appspot.com/o/images%2Fchris.png?alt=media"
      className="w-8 h-8 rounded-full"
      alt="Account Rep"
    />
  )

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={`px-3 py-2 border rounded text-neutral-500 font-semibold flex flex-col items-center gap-2 ${
        hide ? "" : "w-56"
      }`}
    >
      <div className="flex gap-4 justify-between items-center w-full">
        <div className="flex gap-2 items-center">
          <ChatIcon color={Color.White} />
          <Typography
            color={Color.OffWhite}
            weight={Weight.Bold}
            size={Size.XSmall}
            text="Contact Caplight"
            shouldWrap={false}
          />
        </div>
        <div className="flex gap-2 items-center">
          {showAvatar && avatar}
          {adminStatus && (
            <div
              className={classNames(
                "w-3 h-3 rounded-full",
                adminStatus === "active" ? "bg-success-500" : "bg-warning-400"
              )}
            />
          )}
        </div>
      </div>
      <Button
        label="Chat Now"
        variant="full-link"
        size="small"
        onClick={() => toggleChat(firebase9, user.user, isShown)}
        isFullWidth
      />
    </div>
  )
}

export default IntercomChat
