import { Account } from "common/model/Account"
import { createContext, useContext } from "react"
import {
  getStyleOverrides,
  StyleOverrides,
} from "../../pages/WidgetEmbeds/components/StyleOverrides"

export const StyleOverridesContext = createContext<StyleOverrides | undefined>(undefined)

export const StyleOverridesProvider = ({
  account,
  children,
}: {
  account: Account
  children: React.ReactNode
}) => (
  <StyleOverridesContext.Provider value={getStyleOverrides(account)}>
    {children}
  </StyleOverridesContext.Provider>
)

export const useStyleOverrides = () => useContext(StyleOverridesContext)
