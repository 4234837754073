import { possiblyUndefinedToMaybe } from "../../../containers/Maybe"
import { Rec, UnsafeRec } from "../../RecordUtils"

export interface Traversal<S, T, A, B> {
  views: (s: S) => A[]
  over: (s: S, f: (a: A) => B) => T
}
export type SimpleTraversal<S, A> = Traversal<S, S, A, A>

export const composeTraversal = <S, T, A, B, X, Y>(
  l: Traversal<S, T, A, B>,
  r: Traversal<A, B, X, Y>
): Traversal<S, T, X, Y> => ({
  views: (s) => l.views(s).flatMap((a) => r.views(a)),
  over: (s, f) => l.over(s, (a) => r.over(a, f)),
})

export interface IReifiedTraversal<S, T, A, B> extends Traversal<S, T, A, B> {
  composeTraversal: <X, Y>(l: Traversal<A, B, X, Y>) => ReifiedTraversal<S, T, X, Y>
}
export class ReifiedTraversal<in S, out T, out A, in B>
  implements IReifiedTraversal<S, T, A, B>, Traversal<S, T, A, B>
{
  readonly views: (s: S) => A[]

  readonly over: (s: S, f: (a: A) => B) => T

  constructor(traversal: { views: (s: S) => A[]; over: (s: S, f: (a: A) => B) => T }) {
    this.views = traversal.views
    this.over = traversal.over
  }

  composeTraversal = <X, Y>(l: Traversal<A, B, X, Y>): ReifiedTraversal<S, T, X, Y> =>
    new ReifiedTraversal(composeTraversal(this, l))
}

export type ReifiedSimpleTraversal<S, A> = ReifiedTraversal<S, S, A, A>

export const arrayField =
  <K extends string | number | symbol>(key: K) =>
  <R extends Record<K, unknown[]>>(): ReifiedTraversal<R, R, R[K][number], R[K][number]> =>
    new ReifiedTraversal({ views: (r) => r[key], over: (r, f) => ({ ...r, [key]: r[key].map(f) }) })

export const arrayTraversal = <A,B = A>() => new ReifiedTraversal<A[], B[], A, B>({ views: (s) => s, over: (s, f) => s.map(f)})

export const recordFields = <K extends string | number | symbol, V>(): ReifiedSimpleTraversal<Record<K, V>, V> =>
  new ReifiedTraversal({
    views: UnsafeRec.values,
    over: (s, f) => Rec.map(s, f),
  })

export const indexedRecordFields = <K extends string, V>(): ReifiedTraversal<
  Record<K, V>,
  Record<K, V>,
  [K, V],
  V
> =>
  new ReifiedTraversal({
    views: UnsafeRec.entries,
    over: (s, f) => Rec.indexedMap(s, f),
  })

export const optionalField =
  <K extends string | number | symbol>(key: K) =>
  <R extends Partial<Record<K, unknown>>>(): ReifiedSimpleTraversal<R, Exclude<R[K], undefined>> =>
    new ReifiedTraversal({
      views: (r) =>
        possiblyUndefinedToMaybe(r[key]).match(
          (x) => [x],
          () => []
        ),
      over: (r, f) =>
        possiblyUndefinedToMaybe(r[key]).match(
          (v) => ({ ...r, [key]: f(v) }),
          () => r
        ),
    })
