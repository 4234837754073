import { wrapIcon } from "./IconWrapper"

export const RenewIcon = wrapIcon(
  <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.80899 2.14793C5.82516 1.68911 6.95974 1.56092 8.05266 1.78144C9.14558 2.00197 10.1417 2.56008 10.9004 3.37704C11.6592 4.194 12.1423 5.22856 12.2815 6.33478C12.3268 6.69438 12.072 7.02259 11.7124 7.06787C11.3528 7.11314 11.0246 6.85833 10.9793 6.49873C10.8744 5.66525 10.5104 4.88576 9.93871 4.27022C9.36704 3.65468 8.61652 3.23417 7.79306 3.06801C6.96959 2.90186 6.11475 2.99844 5.34911 3.34415C4.73012 3.62364 4.19471 4.05455 3.79042 4.59384H5.24992C5.61236 4.59384 5.90617 4.88765 5.90617 5.25009C5.90617 5.61253 5.61236 5.90634 5.24992 5.90634H2.33325C1.97082 5.90634 1.677 5.61253 1.677 5.25009V2.33342C1.677 1.97099 1.97082 1.67717 2.33325 1.67717C2.69569 1.67717 2.9895 1.97099 2.9895 2.33342V3.49866C3.48942 2.92605 4.10893 2.46403 4.80899 2.14793ZM2.28744 6.93231C2.64704 6.88704 2.97525 7.14185 3.02053 7.50145C3.12547 8.33493 3.48945 9.11442 4.06112 9.72996C4.6328 10.3455 5.38331 10.766 6.20678 10.9322C7.03024 11.0983 7.88509 11.0017 8.65072 10.656C9.26971 10.3765 9.80513 9.94563 10.2094 9.40634H8.74992C8.38748 9.40634 8.09367 9.11253 8.09367 8.75009C8.09367 8.38765 8.38748 8.09384 8.74992 8.09384H11.6666C12.029 8.09384 12.3228 8.38765 12.3228 8.75009V11.6668C12.3228 12.0292 12.029 12.323 11.6666 12.323C11.3041 12.323 11.0103 12.0292 11.0103 11.6668V10.5015C10.5104 11.0741 9.8909 11.5361 9.19085 11.8522C8.17468 12.3111 7.0401 12.4393 5.94718 12.2187C4.85426 11.9982 3.85816 11.4401 3.09941 10.6231C2.34067 9.80618 1.85758 8.77162 1.71831 7.6654C1.67303 7.3058 1.92784 6.97759 2.28744 6.93231Z"
      fill="currentColor"
    />
  </svg>
)
