import { wrapIcon } from "./IconWrapper"

export const SectorsIcon = wrapIcon(
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 13.3333H2.66671V12.9733L6.00004 9.63999L8.53337 12.1733C8.65828 12.2975 8.82725 12.3672 9.00337 12.3672C9.1795 12.3672 9.34847 12.2975 9.47337 12.1733L14.4734 7.17333C14.5359 7.11135 14.5855 7.03762 14.6193 6.95638C14.6531 6.87514 14.6706 6.788 14.6706 6.69999C14.6706 6.61199 14.6531 6.52485 14.6193 6.44361C14.5855 6.36237 14.5359 6.28864 14.4734 6.22666C14.3485 6.10249 14.1795 6.0328 14.0034 6.0328C13.8273 6.0328 13.6583 6.10249 13.5334 6.22666L9.00004 10.76L6.47337 8.22666C6.34847 8.10249 6.1795 8.0328 6.00337 8.0328C5.82725 8.0328 5.65828 8.10249 5.53337 8.22666L2.66671 11.0867V7.63999L6.00004 4.30666L7.86671 6.17333C7.99162 6.2975 8.16058 6.36719 8.33671 6.36719C8.51283 6.36719 8.6818 6.2975 8.80671 6.17333L12 2.97999L13.46 4.43999C13.5856 4.56465 13.7555 4.63432 13.9324 4.6337C14.1093 4.63307 14.2787 4.5622 14.4034 4.43666C14.528 4.31113 14.5977 4.14121 14.5971 3.9643C14.5965 3.7874 14.5256 3.61798 14.4 3.49333L12.46 1.56666C12.3351 1.44249 12.1662 1.3728 11.99 1.3728C11.8139 1.3728 11.6449 1.44249 11.52 1.56666L8.32004 4.76666L6.46004 2.89999C6.33513 2.77583 6.16616 2.70613 5.99004 2.70613C5.81392 2.70613 5.64495 2.77583 5.52004 2.89999L2.66671 5.75333V1.99999C2.66671 1.82318 2.59647 1.65361 2.47145 1.52859C2.34642 1.40357 2.17685 1.33333 2.00004 1.33333C1.82323 1.33333 1.65366 1.40357 1.52864 1.52859C1.40361 1.65361 1.33337 1.82318 1.33337 1.99999V14C1.33337 14.1768 1.40361 14.3464 1.52864 14.4714C1.65366 14.5964 1.82323 14.6667 2.00004 14.6667H14C14.1769 14.6667 14.3464 14.5964 14.4714 14.4714C14.5965 14.3464 14.6667 14.1768 14.6667 14C14.6667 13.8232 14.5965 13.6536 14.4714 13.5286C14.3464 13.4036 14.1769 13.3333 14 13.3333Z"
      fill="currentColor"
    />
  </svg>
)
