import { deprecatedIsLoaded } from "common/utils/Loading"
import { useCallback, useMemo } from "react"
import { useCurrentUser } from "../../providers/currentUser/useCurrentUser"
import { heapTrackWithEmail } from "../Tracking"
import { useLocalStorage } from "./state/useLocalStorage"
import { AuthCodeAuthenticationResponse } from "common/model/AuthCode"

export const useUtmTracking = () => {
  const [utmCampaign, setUtmCampaign] = useLocalStorage<
    AuthCodeAuthenticationResponse["utmCampaign"]
  >("utmCampaign", undefined)
  const currentUser = useCurrentUser()

  const trackUtmCampaign = useCallback(() => {
    if (!utmCampaign || !deprecatedIsLoaded(currentUser)) return () => {}

    const timeout = setTimeout(() => {
      heapTrackWithEmail({
        event: "email-link-click",
        email: currentUser.user.email,
        metadata: { emailType: utmCampaign },
      })
      setUtmCampaign(undefined)
    }, 3000)
    return () => clearTimeout(timeout)
  }, [utmCampaign, currentUser, setUtmCampaign])

  return useMemo(
    () => ({
      setUtmCampaign,
      trackUtmCampaign,
    }),
    [setUtmCampaign, trackUtmCampaign]
  )
}
