import { Checkbox } from "@components/inputs/checkbox/Checkbox"
import { FilterFnRecord, Variant } from "common/model/newsfeed/NewsFeed"

export const excludeCompaniesWithoutIoIs: FilterFnRecord = {
  news: {
    function: (t) => (t.company?.numberOfOrdersPastYear || 0) > 0,
    asStrings: { field: "excludeCompaniesWithoutIoIs", value: "true" },
  },
  funding_rounds: {
    function: (f) => (f.numberOfOrdersPastYear || 0) > 0,
    asStrings: { field: "excludeCompaniesWithoutIoIs", value: "true" },
  },
}

export const CompaniesWithOrders = <V extends Variant>({
  onEnable,
  variant,
  valueString,
}: {
  onEnable: (fn: FilterFnRecord[V] | undefined) => void
  variant: V
  valueString: string | null
}) => (
  <div className="flex flex-row space-x-2 text-xs items-center">
    <Checkbox
      className="w-3 h-3"
      id={`${variant}-filter-only-iois`}
      onChange={(newVal) => {
        onEnable(newVal.target.checked ? undefined : excludeCompaniesWithoutIoIs[variant])
      }}
      checked={!valueString}
    />
    {!valueString}
    <span>Include companies without bids or asks</span>
  </div>
)
