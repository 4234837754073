import { Button, ButtonProps } from "@stories/components/Button/Button"
import { AccessControlFeatureName } from "common/model/AccessControl/AccessControl"
import { useAccessControl } from "../AccessControlProvider"

export const SeePlansButton = ({
  variant,
  featureName,
  onCloseWrapper,
  allowButtonGroup,
}: Pick<ButtonProps, "variant"> & {
  featureName: AccessControlFeatureName
  onCloseWrapper?: () => void
  allowButtonGroup?: true
}) => {
  const { setIsUpgradePlanModalOpen, setUpgradePlanModalFeatureName } = useAccessControl()

  return (
    <Button
      onClick={() => {
        onCloseWrapper?.()
        setUpgradePlanModalFeatureName(featureName)
        setIsUpgradePlanModalOpen(true)
      }}
      label="See Plans"
      variant={variant}
      isFullWidth
      disableButtonGroup={!allowButtonGroup}
    />
  )
}
