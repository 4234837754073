import { wrapIcon } from "./IconWrapper"

export const CaplightConnectIcon = wrapIcon(
  <svg viewBox="0 0 14 12" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.19999 6.86667L6.79999 4.46667C6.73333 4.4 6.68622 4.32778 6.65866 4.25C6.63066 4.17222 6.61666 4.08889 6.61666 4C6.61666 3.91111 6.63066 3.82778 6.65866 3.75C6.68622 3.67222 6.73333 3.6 6.79999 3.53333L9.19999 1.13333C9.33333 1 9.48888 0.933334 9.66666 0.933334C9.84444 0.933334 10 1 10.1333 1.13333C10.2667 1.26667 10.3333 1.42489 10.3333 1.608C10.3333 1.79156 10.2667 1.95 10.1333 2.08333L8.88333 3.33333H13C13.1889 3.33333 13.3471 3.39711 13.4747 3.52467C13.6027 3.65267 13.6667 3.81111 13.6667 4C13.6667 4.18889 13.6027 4.34711 13.4747 4.47467C13.3471 4.60267 13.1889 4.66667 13 4.66667H8.88333L10.1333 5.91667C10.2667 6.05 10.3333 6.20556 10.3333 6.38333C10.3333 6.56111 10.2667 6.71667 10.1333 6.85C10 6.98333 9.84733 7.05556 9.67533 7.06667C9.50288 7.07778 9.34444 7.01111 9.19999 6.86667ZM3.86666 10.85C3.99999 10.9833 4.15555 11.0527 4.33333 11.058C4.51111 11.0638 4.66666 11 4.79999 10.8667L7.19999 8.46667C7.26666 8.4 7.31399 8.32778 7.34199 8.25C7.36955 8.17222 7.38333 8.08889 7.38333 8C7.38333 7.91111 7.36955 7.82778 7.34199 7.75C7.31399 7.67222 7.26666 7.6 7.19999 7.53333L4.79999 5.13333C4.66666 5 4.51111 4.93333 4.33333 4.93333C4.15555 4.93333 3.99999 5 3.86666 5.13333C3.73333 5.26667 3.66666 5.42489 3.66666 5.608C3.66666 5.79156 3.73333 5.95 3.86666 6.08333L5.11666 7.33333H0.999995C0.811106 7.33333 0.652884 7.39711 0.525328 7.52467C0.397328 7.65267 0.333328 7.81111 0.333328 8C0.333328 8.18889 0.397328 8.34711 0.525328 8.47467C0.652884 8.60267 0.811106 8.66667 0.999995 8.66667H5.11666L3.86666 9.91667C3.73333 10.05 3.66666 10.2056 3.66666 10.3833C3.66666 10.5611 3.73333 10.7167 3.86666 10.85Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth=".35"
    />
  </svg>
)

export default CaplightConnectIcon
