import { dealCRMFirmContactFieldParsers } from "common/model/DealCRM/DealCRMFirmContact"
import { Right } from "common/containers/Either"
import { useCRMContacts } from "../../../Providers/CRMContactsProvider"
import { ContactCSVUploader, firmImportJobSummary } from "./ContactCSVUploader"
import { DealCRMImportJob, startCRMImportJob } from "common/model/DealCRM/DealCRMImportJob"
import { viewUserIdFields } from "common/model/User"
import { SubmittedDocument } from "common/model/files/DocumentSubmission"
import { useMemo } from "react"
import { ImportJobSummaryData } from "../ImportJobSummaryData"
import { useFirebaseWriter } from "src/firebase/Context"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"

export const FirmContactCSVUploader = () => {
  const { latestFirmImport } = useCRMContacts()
  const fb = useFirebaseWriter()
  const user = useLoggedInUser()
  const startImportJob: (
    data: SubmittedDocument,
    importCounts: ImportJobSummaryData
  ) => Promise<DealCRMImportJob> = useMemo(
    () => (data, importCounts) =>
      startCRMImportJob(
        fb.writerDb,
        user.user.account.id,
        viewUserIdFields(user.user),
        data,
        "firm",
        importCounts
      ),
    [fb.writerDb, user.user]
  )
  return (
    <>
      <ContactCSVUploader
        fieldParsers={dealCRMFirmContactFieldParsers}
        uploadButtonText="Upload Firm Contact CSV"
        headerNames={
          {
            name: "Name",
            accountOwner: "Account Owner",
            classification: "Classification",
            isFundLP: "Is Fund LP",
            lastContactedAt: "Last Contacted At",
            sourceId: "Firm Id",
          } as const
        }
        startImportJob={startImportJob}
        postParseValidation={(db, a, records) => Promise.resolve(Right(records))}
        importJobSummary={firmImportJobSummary}
        uploadJobId={latestFirmImport?.id}
        exampleLocation="/assets/FirmContactExample.csv"
      />
    </>
  )
}
