import Typography, { Color, Weight, Size } from "@stories/components/Typography/Typography"

export const EverythingInTierPlus = () => (
  <Typography
    text="Everything else, plus:"
    color={Color.Primary}
    weight={Weight.Semibold}
    size={Size.Small}
  />
)
