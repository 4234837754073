import { EnvelopeIcon } from "@stories/icons/EnvelopeIcon"
import React from "react"
import Pill from "../Badges/Pill/Pill"
import Typography, { Color, Size, Weight } from "../Typography/Typography"
import { articulatedDateTimeFormat } from "common/utils/dateUtils"
import { isToday } from "date-fns"

export interface EmailCardHeaderProps {
  subject: string
  from: string
  to: string[]
  cc: string[]
  sentAt: Date
  threadLength: number
}

export const EmailCardHeader: React.FC<EmailCardHeaderProps> = ({
  subject,
  from,
  to,
  cc,
  sentAt,
  threadLength,
}) => (
  <div className="flex flex-col gap-1 bg-neutral-100 p-3">
    <div>
      <div className="flex justify-between">
        <div className="flex gap-1.5 items-center">
          <Pill label="Email" />
          <EnvelopeIcon color={Color.Neutral800} />
          {threadLength > 0 && (
            <Typography
              text={threadLength}
              color={Color.PrimarySecondary}
              size={Size.XXSmall}
              weight={Weight.Semibold}
            />
          )}
        </div>
        <Typography
          color={Color.Neutral800}
          size={Size.XSmall}
          text={
            isToday(sentAt)
              ? `Today ${articulatedDateTimeFormat(sentAt).time}`
              : sentAt.toDateString()
          }
        />
      </div>
    </div>
    <div className="flex gap-4 items-center">
      <Typography weight={Weight.Semibold} size={Size.Small} text={subject} />
      <Typography color={Color.Neutral800} size={Size.XSmall} text={`from: ${from}`} />
    </div>
    <div className="flex gap-4">
      <Typography color={Color.Neutral800} size={Size.XSmall} text={`to: ${to.join(", ")}`} />
      {cc.length > 0 && (
        <Typography color={Color.Neutral800} size={Size.XSmall} text={`cc: ${cc.join(", ")}`} />
      )}
    </div>
  </div>
)
