import { Button } from "@stories/components/Button/Button"
import { CompanyPill, CompanyPillProps } from "@stories/components/CompanyPill/CompanyPill"
import Divider from "@stories/components/Divider/Divider"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { ViewIcon } from "@stories/icons/ViewIcon"
import { Just, Nothing, nullableToMaybe } from "common/containers/Maybe"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { isBrokerage } from "common/model/DealCRM/DealCRMFirmContact"
import { Interval, showInterval } from "common/utils/data/Interval"
import { relativeDateFormat } from "common/utils/dateUtils"
import { assertUnreachable } from "common/utils/fp/Function"
import { formatValuation } from "common/utils/math/format"
import { SyntheticEvent } from "react"
import { useCRMContacts } from "../../Providers/CRMContactsProvider"
import { CRMContactName } from "../ContactDetailsDrawer/CRMContactName"
import CRMContactCopyEmail from "./ContactCopyEmail"
import ContactIcon from "./ContactIcon"

type TextOrNode =
  | { text: string | undefined; component?: never }
  | { text?: never; component: React.ReactNode }

type CRMContactPopoverContentDetailProps = {
  label: string
} & TextOrNode

const CRMContactPopoverContentDetail = ({
  label,
  text,
  component,
}: CRMContactPopoverContentDetailProps) => (
  <>
    <Typography text={label} size={Size.XSmall} weight={Weight.Semibold} color={Color.Subtitle} />
    {component ?? <Typography text={text ?? "-"} size={Size.XSmall} shouldMaskDataInDataDog />}
  </>
)

const CRMContactPopoverCompanyList = ({
  label,
  companies,
}: {
  label: string
  companies: CompanyPillProps["company"][]
}) =>
  companies.length ? (
    <div className="flex flex-col gap-2">
      <Typography text={label} size={Size.XSmall} weight={Weight.Semibold} color={Color.Subtitle} />
      <div className="flex flex-row gap-1 items-center">
        {companies.slice(0, 3).map((c) => (
          <CompanyPill key={c.id} company={c} shouldMaskDataInDataDog />
        ))}
        {companies.length > 3 ? (
          <Typography
            text={`and ${companies.length - 3} more`}
            size={Size.XSmall}
            shouldMaskDataInDataDog
          />
        ) : null}
      </div>
    </div>
  ) : null

const CRMContactDetails = ({ contact }: { contact: DealCRMContact }) => {
  const { targetCheckSize } = contact
  const targetCheckSizeString: string | undefined = nullableToMaybe(targetCheckSize)
    .bind(({ upperBound, lowerBound }) =>
      upperBound !== null && lowerBound !== null
        ? Just({ upperBound, lowerBound })
        : upperBound !== null
        ? Just(Interval.pure(upperBound))
        : lowerBound !== null
        ? Just(Interval.pure(lowerBound))
        : Nothing
    )
    .map(showInterval(formatValuation, true))
    .toNullable()

  const { findContactById } = useCRMContacts()

  switch (contact.tag) {
    case "firm": {
      const { classification, contacts } = contact
      const primaryContact = contacts?.find((c) => c.isPrimaryContact)
      const fullPrimaryContact = primaryContact?.id ? findContactById(primaryContact.id) : null
      return (
        <>
          <CRMContactPopoverContentDetail label="Type" text={classification} />
          <CRMContactPopoverContentDetail label="Target Check Size" text={targetCheckSizeString} />
          <CRMContactPopoverContentDetail
            label="Primary Contact"
            component={
              primaryContact && (
                <CRMContactName
                  contact={primaryContact}
                  hidePopover
                  size="small"
                  variant="neutral"
                />
              )
            }
          />
          <CRMContactPopoverContentDetail
            label="Primary Email"
            component={
              fullPrimaryContact?.tag === "individual" && (
                <CRMContactCopyEmail email={fullPrimaryContact?.email || ""} />
              )
            }
          />
        </>
      )
    }
    case "individual": {
      const { firm, title, email } = contact
      return (
        <>
          <CRMContactPopoverContentDetail
            label="Email"
            component={<CRMContactCopyEmail email={email || ""} />}
          />
          <CRMContactPopoverContentDetail
            label="Firm"
            component={
              firm && <CRMContactName contact={firm} hidePopover size="small" variant="neutral" />
            }
          />
          <CRMContactPopoverContentDetail label="Title" text={title} />
          <CRMContactPopoverContentDetail label="Target Check Size" text={targetCheckSizeString} />
        </>
      )
    }
    case "broker": {
      const { firm, email } = contact
      return (
        <>
          <CRMContactPopoverContentDetail
            label="Email"
            component={<CRMContactCopyEmail email={email || ""} />}
          />
          <CRMContactPopoverContentDetail
            label="Brokerage"
            component={
              firm && <CRMContactName contact={firm} hidePopover size="small" variant="neutral" />
            }
          />
        </>
      )
    }
    default:
      return assertUnreachable(contact)
  }
}

const CRMContactPopoverContent = ({
  contact,
  onClickView,
}: {
  contact: DealCRMContact
  onClickView?: (e: SyntheticEvent) => void
}) => (
  <div className="flex flex-col gap-4">
    <div className="flex gap-4 items-center justify-between">
      <div className="flex gap-2 items-center">
        <ContactIcon contact={contact} />
        <Typography
          text={
            isBrokerage(contact) || contact.tag === "broker"
              ? `${contact.name} (Broker)`
              : contact.name
          }
          weight={Weight.Semibold}
          shouldMaskDataInDataDog
        />
      </div>
      {onClickView ? (
        <Button
          label="View"
          variant="secondary"
          size="small"
          onClick={onClickView}
          leftIcon={<ViewIcon />}
          isTextWrapped={false}
        />
      ) : null}
    </div>
    <div style={{ grid: "auto-flow / max-content auto" }} className="grid gap-2 items-center">
      <CRMContactDetails contact={contact} />
      <CRMContactPopoverContentDetail
        label="Last Contacted"
        text={nullableToMaybe(contact.lastContactedAt)
          .map((lastContactedAt) => relativeDateFormat(lastContactedAt))
          .toNullable()}
      />
    </div>
    {contact.contactBuyInterest?.length ||
    contact.contactSellInterest?.length ||
    contact.holdings?.length ? (
      <>
        <Divider />
        <CRMContactPopoverCompanyList
          label="Buy Interest"
          companies={contact.contactBuyInterest?.map(({ company }) => company) ?? []}
        />
        <CRMContactPopoverCompanyList
          label="Sell Interest"
          companies={contact.contactSellInterest?.map(({ company }) => company) ?? []}
        />
        {contact.holdings.length > 0 ? (
          <CRMContactPopoverCompanyList
            label="Holdings"
            companies={contact.holdings?.map(({ company }) => company) ?? []}
          />
        ) : null}
      </>
    ) : null}
  </div>
)

export default CRMContactPopoverContent
