import { managementFeeBounds } from "./Models/ManagementFeeBounds"
import { orderValuationBounds } from "./Models/ValuationBounds"
import { volumeBounds } from "./Models/VolumeBounds"
import { carryBounds } from "./Models/CarryBounds"
import { OutlierCheckContext, OutlierCheckPoint } from "./OutlierCheckContext"
import { rollingGaussian2 } from "./Models/RollingGaussian"
import { indexUsingMarket } from "./Models/IndexUsingMarket"

/** each model returns true if an outlier */
export const outlierDetectionModels = {
  volumeBounds,
  orderValuationBounds,
  managementFeeBounds,
  carryBounds,
  rollingGaussian2,
  indexUsingMarket,
} satisfies Record<string, (ctx: OutlierCheckContext, point: OutlierCheckPoint) => boolean>

/** is this model being run on incoming data? */
export const outlierDetectionModelChecked: Record<keyof typeof outlierDetectionModels, boolean> = {
  volumeBounds: true,
  orderValuationBounds: true,
  managementFeeBounds: true,
  carryBounds: true,
  rollingGaussian2: true,
  indexUsingMarket: true,
}
/** is this model being used to filter data points? */
export const outlierDetectionModelEnforced: Record<keyof typeof outlierDetectionModels, boolean> &
  Record<string, boolean> = {
  volumeBounds: true,
  orderValuationBounds: true,
  managementFeeBounds: true,
  carryBounds: true,
  rollingGaussian2: true,
  rollingGaussian: false,
  indexUsingMarket: true,
}
