import { useEffect, useState } from "react"

import { Order } from "common/model/Order/Models/Wrapped"
import { Loading } from "common/utils/Loading/Wrapped"
import { Maybe } from "common/containers/Maybe"
import { OrderInquiry } from "common/model/OrderInquiry/Db"
import Drawer from "../../components/Drawer/Drawer"
import { SubmissionStatus } from "./types"
import OrderInquiryDrawerUnsubumittedView from "./views/OrderInquiryDrawerUnsubmittedView"
import OrderInquiryDrawerSubmittedView from "./views/OrderInquiryDrawerSubmittedView"
import { useFirebaseReader } from "../../firebase/Context"
import { useLoggedInUser } from "../../providers/loggedInUser/useLoggedInUser"
import { useErrorHandler } from "../../providers/errorHandler"

const OrderInquiryDrawerInner = ({
  order,
  closeDrawer,
  open,
  remainingInquiryCount,
  existingInquiry,
}: {
  order: Order
  closeDrawer: () => void
  open: boolean
  remainingInquiryCount: Loading<number>
  existingInquiry: Loading<Maybe<OrderInquiry>>
}) => {
  const [submissionStatus, setSubmissionStatus] = useState<SubmissionStatus>("unsubmitted")
  const firebase = useFirebaseReader()
  const { user } = useLoggedInUser()
  const { handleError } = useErrorHandler()

  const showSubmittedView = remainingInquiryCount
    .alongside(existingInquiry)
    .map(([_count, maybeInquiry]) => maybeInquiry.isJust() || submissionStatus === "success")
    .withDefault(false)

  const { firstViewedOrderInquiryDrawer } = user.productInteractionHistory

  useEffect(() => {
    if (open && !firstViewedOrderInquiryDrawer) {
      firebase
        .recordUserProductInteraction(user, { firstViewedOrderInquiryDrawer: new Date() })
        .catch(handleError)
    }
  }, [open, firstViewedOrderInquiryDrawer, firebase, handleError, user])

  return (
    <Drawer
      className="text-sm h-full"
      open={open}
      title={showSubmittedView ? "Inquiry Sent" : "Order Inquiry"}
      size="sm"
      onClose={closeDrawer}
      destroyOnClose
    >
      {remainingInquiryCount
        .alongside(existingInquiry)
        .match(
          ([count, maybeInquiry]) =>
            showSubmittedView ? (
              <OrderInquiryDrawerSubmittedView onClose={closeDrawer} inquiry={maybeInquiry} />
            ) : (
              <OrderInquiryDrawerUnsubumittedView
                count={count}
                order={order}
                submissionStatus={submissionStatus}
                setSubmissionStatus={setSubmissionStatus}
              />
            ),
          null,
          null
        )}
    </Drawer>
  )
}
const OrderInquiryDrawer = (props: {
  order: Order
  closeDrawer: () => void
  open: boolean
  remainingInquiryCount: Loading<number>
  existingInquiry: Loading<Maybe<OrderInquiry>>
}) => (props.open ? <OrderInquiryDrawerInner {...props} /> : null)
export default OrderInquiryDrawer
