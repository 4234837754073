import { assertDoesNotExtend, assertExtends } from "../Type/Assertions"
import { Nonempty } from "./Nonempty"

interface ExhaustiveWitness<in out T> {
  readonly _exhaustiveWitness: T
}

export type Exhaustive<T> = Nonempty<T> & ExhaustiveWitness<T>
// eslint-disable-next-line rulesdir/no-assert
export const assertExhaustive = <const T>(t: Nonempty<T>) => t as Exhaustive<T>

assertExtends<Exhaustive<"a" | "b">, readonly string[]>
assertDoesNotExtend<readonly string[], Exhaustive<"a" | "b">>
