import { makeAPIGetRequest } from "common/api/Request"
import { OrderAPI } from "common/api/OrderAPI"
import { useEffect, useState } from "react"
import { Loading, isLoaded, mapLoading } from "common/utils/Loading"
import { Order } from "common/model/Order/Order"
import { useLoggedInUserAuthToken } from "src/firebase/AuthUtils"
import { config } from "../../config"
import { useWrapOrders } from "./useWrapOrders"
import { handleConsoleError } from "src/utils/Tracking"

export const usePrivateOrder = (orderId: string | null) => {
  const userToken = useLoggedInUserAuthToken()
  const [privateOrder, setPrivateOrder] = useState<Loading<Order>>("loading")
  useEffect(() => {
    if (orderId !== null) {
      if (isLoaded(userToken)) {
        makeAPIGetRequest<typeof OrderAPI, ["order", string]>(
          `${config.firebase.cloudApiUrl}/data/`,
          OrderAPI,
          ["order", orderId],
          userToken
        )()
          .then(
            (order) => setPrivateOrder(order),
            () => setPrivateOrder(null)
          )
          .catch(handleConsoleError)
      }
    }
  }, [orderId, userToken])
  const wrapped = useWrapOrders(isLoaded(privateOrder) ? [privateOrder] : privateOrder)
  return mapLoading((x: Exclude<typeof wrapped, null | "loading">) => x[0])(wrapped)
}
