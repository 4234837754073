import { wrapIcon } from "./IconWrapper"

export const NewsIcon = wrapIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
    <path
      d="M13.2502 2H2.75016C1.96883 2 1.3335 2.598 1.3335 3.33333V12.6667C1.3335 13.402 1.96883 14 2.75016 14H13.2502C14.0315 14 14.6668 13.402 14.6668 12.6667V3.33333C14.6668 2.598 14.0315 2 13.2502 2ZM13.2502 12.6667H2.75016C2.71216 12.6667 2.68616 12.656 2.67483 12.656C2.67016 12.656 2.6675 12.6573 2.66683 12.6613L2.65883 3.364C2.6635 3.35733 2.6935 3.33333 2.75016 3.33333H13.2502C13.3028 3.334 13.3315 3.352 13.3335 3.33867L13.3415 12.636C13.3368 12.6427 13.3068 12.6667 13.2502 12.6667Z"
      fill="currentColor"
    />
    <path
      d="M4 4.66663H8V8.66663H4V4.66663ZM8.66667 9.99996H4V11.3333H12V9.99996H9.33333H8.66667ZM9.33333 7.33329H12V8.66663H9.33333V7.33329ZM9.33333 4.66663H12V5.99996H9.33333V4.66663Z"
      fill="currentColor"
    />
  </svg>
)
