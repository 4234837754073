import {
  addCompanyToWatchlist,
  removeCompanyFromWatchlist,
} from "@components/Watchlist/WatchlistCard/helpers"
import { useAccountWatchlist } from "@components/Watchlist/useAccountWatchlist"
import { notification } from "@stories/components/Antd"
import { companyInPostgres } from "common/model/Company"
import { defaultIfLoading, deprecatedIsLoaded, isLoading, matchLoading } from "common/utils/Loading"
import { useCallback, useMemo } from "react"
import { useFirebaseWriter } from "src/firebase/Context"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { handleConsoleError } from "src/utils/Tracking"
import { useCurrentAccount } from "src/queries/currentUser/useCurrentAccount"
import { useCompany } from "../Data/Hooks"

export const useWatchlistCompany = (companyId: string) => {
  const firebase = useFirebaseWriter()
  const user = useLoggedInUser()
  const account = useCurrentAccount()
  const watchlistLoading = useAccountWatchlist()
  const accountWatchlist = defaultIfLoading(watchlistLoading, [])
  const company = useCompany(companyId)

  const postgresCompanyId = matchLoading(company, (c) => c.postgresCompanyId, undefined, undefined)

  const isWatched = useMemo(
    () => accountWatchlist.some((item) => item.company.postgresCompanyId === postgresCompanyId),
    [accountWatchlist, postgresCompanyId]
  )

  const currentWatchListItem = useMemo(
    () =>
      accountWatchlist.filter((item) => item.company.postgresCompanyId === postgresCompanyId)[0],
    [accountWatchlist, postgresCompanyId]
  )

  const toggleWatchlist = useCallback(() => {
    if (!user || !account || isLoading(account)) return

    if (!isWatched && deprecatedIsLoaded(company) && companyInPostgres(company)) {
      addCompanyToWatchlist(firebase, company, user, account, accountWatchlist)
      notification.success({
        message: `${company ? company.name : "Company"} added to your watchlist`,
        placement: "top",
      })
      return
    }

    if (!currentWatchListItem) return
    removeCompanyFromWatchlist(firebase, currentWatchListItem).then().catch(handleConsoleError)
  }, [account, accountWatchlist, company, currentWatchListItem, firebase, isWatched, user])

  return { toggleWatchlist, isWatched }
}
