export type OrderVisibility = "darkpool" | "targeted" | "market_visible"

export type BidOrOffer = "bid" | "offer"

export type OrderFirmness = "High" | "Medium" | "Low"
export type OrderStage = (typeof orderStages)[number]
export const orderStages = [
  "Preliminary",
  "Scope",
  "Qualified",
  "Matching",
  "In Closing",
  "Closed",
  "Dead",
] as const
export type LiveOrDead = "Live" | "Dead"
export type DeprecatedOrderStructure = "Direct" | "Swap / Forward" | "Call" | "Put" | "SPV"

export type OrderDirection = "Long" | "Short"
export const OrderDirections: Array<OrderDirection> = ["Long", "Short"]
export const OptionTypes = ["Call", "Put"] as const
export type OptionType = (typeof OptionTypes)[number]
export const METRIC_TO_TRADE_ON = ["Post-money valuation / market cap"] // , "Price per share"]
export const DEFAULT_OPTION_TERM_LENGTH_IN_MONTHS = 24
export type PremiumFormat = "percentage" | "share_price"

/** @deprecated use common/model/order/Order instead */
export interface FullTermsOptionOrder extends DeprecatedOrder {
  notionalRange: string
  strikeValuation: number
  strikePPS: number
  refValuation: number
  refPPS: number
  refSource?: string
  premPercent: number
  netFee: number
  contractExpirationInMonths: number
}

export interface ExoticsInterface {
  lookback?: boolean
  knockin?: boolean
  knockout?: boolean
}

/** @deprecated use common/model/order/Order instead */
export interface DeprecatedOrder {
  id: string
  airtableId: string
  direction: OrderDirection
  structure: Array<DeprecatedOrderStructure>
  notionalValueLow?: number
  notionalValueHigh?: number
  notionalRange?: string
  PPS?: number
  valuation?: number
  strikeValuation?: number
  strikePPS?: number
  refValuation?: number
  refPPS?: number
  refSource?: string
  premPercent?: number
  netFee?: number
  initialOrderDate?: string
  expirationDate?: Date
  cancelledDate?: Date
  issuerAirtableId: string
  liveOrDead: LiveOrDead
  issuerName: string
  companyId: string
  numberOfViewers?: number
  visibility: OrderVisibility
  anchorOrder: Array<string>
  isQualified: boolean
  contractExpirationInMonths?: number
  auctionId?: string
  stage: OrderStage
}
