import { FeatureFlag } from "common/model/FeatureFlag/FeatureFlag"
import { useFeatureFlag } from "./useFeatureFlags"

const FeatureWrapper: React.FC<
  React.PropsWithChildren<{
    flagName: FeatureFlag["name"]
    defaultRender?: React.ReactNode
  }>
> = ({ children, defaultRender = null, flagName }) =>
  useFeatureFlag(flagName) ? <>{children}</> : defaultRender ? <>{defaultRender}</> : null

export default FeatureWrapper
