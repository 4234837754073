import { collections } from "common/firestore/Collections"
import { AccountQuotaHistory } from "common/model/Account/AccountQuotaHistory"
import { Loading, matchLoading } from "common/utils/Loading"
import { UnsafeRec } from "common/utils/RecordUtils"
import moment, { Moment } from "moment-timezone"
import { useFirebaseReader } from "src/firebase/Context"
import { useDocuments } from "../firebase/Firestore"
import { firestoreConverter } from "../model/FirestoreConverter"
import { useMemo } from "react"

export type MinimalAccountQuotaHistoryInstance = {
  date: Date
  wasQuotaSatisfied: boolean
  wasQuotaActive: boolean
}

export type QuarterQuotaHistory = {
  currentQuarterQuotaHistory: Loading<MinimalAccountQuotaHistoryInstance[]>
  percentOfQuarterInCompliance: Loading<number>
  daysInQuarterOutOfCompliance: Loading<number>
}

export const initialQuarterQuotaHistory: QuarterQuotaHistory = {
  currentQuarterQuotaHistory: "loading",
  percentOfQuarterInCompliance: "loading",
  daysInQuarterOutOfCompliance: "loading",
}

export const useAccountQuotaHistory = (accountId: string): QuarterQuotaHistory => {
  const db = useFirebaseReader()
  const query = db.db
    .collection(collections.accountQuotaHistory)
    .withConverter(firestoreConverter<AccountQuotaHistory>())
    .where("account.id", "==", accountId)
    .orderBy("month", "desc")
    .limit(3)
  const docs = useDocuments<AccountQuotaHistory>(query, [accountId])

  const toCurrentQuarterHistory = (quotaDocs: AccountQuotaHistory[]): QuarterQuotaHistory => {
    const today = moment().tz("America/Los_Angeles").startOf("day")
    const currentQuarterDocs = quotaDocs.filter((doc) => moment(doc.month).isSame(today, "quarter"))
    const daysWithQuotaData = currentQuarterDocs.flatMap((doc) =>
      UnsafeRec.entries(doc.quotaHistory).map(([key, value]) => ({
        date: value.date,
        wasQuotaSatisfied: value.isQuotaSatisfied,
        wasQuotaActive: true,
      }))
    )
    const allDaysInQuarter = getAllDaysInCurrentQuarter(today)
    const currentQuarterQuotaHistory = allDaysInQuarter.map((day) => {
      const quotaData = daysWithQuotaData.find((dailyQuotaData) =>
        moment(dailyQuotaData.date).isSame(day, "day")
      )
      return {
        date: day.toDate(),
        wasQuotaSatisfied: quotaData?.wasQuotaSatisfied ?? false,
        wasQuotaActive: quotaData?.wasQuotaActive ?? false,
      }
    })
    const sortedQuotaHistoryMostRecentFirst = currentQuarterQuotaHistory.sort((a, b) =>
      a.date > b.date ? -1 : 1
    )

    return {
      daysInQuarterOutOfCompliance: currentQuarterQuotaHistory.filter(
        (day) => !day.wasQuotaSatisfied && day.wasQuotaActive
      ).length,
      percentOfQuarterInCompliance:
        currentQuarterQuotaHistory.filter((day) => day.wasQuotaSatisfied).length /
        allDaysInQuarter.length,
      currentQuarterQuotaHistory: sortedQuotaHistoryMostRecentFirst,
    }
  }

  return useMemo(
    () =>
      matchLoading(docs, toCurrentQuarterHistory, initialQuarterQuotaHistory, {
        currentQuarterQuotaHistory: [],
        percentOfQuarterInCompliance: 0,
        daysInQuarterOutOfCompliance: 0,
      }),
    [docs]
  )
}

const getAllDaysInCurrentQuarter = (today: moment.Moment): Moment[] => {
  const startOfQuarter = moment(today).startOf("quarter")
  const daysInQuarter = []
  for (let i = 0; i < 100; i++) {
    const day = moment(startOfQuarter).add(i, "days")
    if (day.isAfter(today)) break
    daysInQuarter.push(day)
  }
  return daysInQuarter
}
