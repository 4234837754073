import { Account } from "common/model/Account"
import { firestoreConverter } from "./FirestoreConverter"

export interface Fund {
  id: string
  airtableId: string
  name: string
  account: firebase.default.firestore.DocumentReference<Account>
  accountData: Account
}

export const fundConverter = firestoreConverter<Fund>()
