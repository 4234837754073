/* eslint-disable react/jsx-props-no-spreading */
import {
  RadioSelect,
  RadioSelectProps,
  SelectOptions,
} from "@stories/components/Inputs/RadioSelect/RadioSelect"
import { UnconfirmableYesNo } from "common/model/UnconfirmableYesNo"

type StringBoolean = "yes" | "no"

export const BooleanSelect = ({
  value,
  onChange,
  options = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ],
  ...props
}: {
  value?: boolean | null
  options?: SelectOptions<boolean>[]
  onChange: (value: boolean) => void
} & Omit<RadioSelectProps<StringBoolean>, "value" | "options" | "onChange">) => (
  <RadioSelect<StringBoolean>
    {...props}
    options={options.map((o) => ({ ...o, value: o.value ? "yes" : "no" }))}
    onChange={(v) => onChange(v === "yes")}
    value={typeof value === "boolean" ? (value ? "yes" : "no") : undefined}
  />
)

export const UnconfirmableYesNoSelect = ({
  value,
  onChange,
  options = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
    { label: "Unconfirmed", value: "Unknown" },
  ],
  ...props
}: {
  value?: UnconfirmableYesNo | null
  options?: SelectOptions<UnconfirmableYesNo>[]
  onChange: (value: UnconfirmableYesNo | null) => void
} & Omit<RadioSelectProps<StringBoolean>, "value" | "options" | "onChange">) => (
  <RadioSelect<UnconfirmableYesNo | null>
    {...props}
    options={options.map((o) => ({
      ...o,
      value: o.value,
    }))}
    onChange={onChange}
    value={value}
  />
)
