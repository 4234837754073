import { LinkButton } from "../../../../components/LinkButton"
import Alert from "../../../../stories/components/Alert/Alert"
import Typography, { Size } from "../../../../stories/components/Typography/Typography"
import { useLoggedInUser } from "../../../../providers/loggedInUser/useLoggedInUser"
import { Order } from "common/model/Order/Models/Wrapped"
import { sendPlatformEventMessage } from "../../../../firebase/API"

export const VPPFExplanation = ({ order }: { order: Order }) => {
  const user = useLoggedInUser()
  const orderDirection = order.direction()

  const onLearnMoreClick = () => {
    const message = `User ${user.user.email} clicked on the Learn More link for a VPPF ${
      order.company().name
    } order (${order.id()}).`
    sendPlatformEventMessage(message, user)
  }

  return (
    <div>
      <Alert
        variant="warning"
        headline={`This ${
          orderDirection === "buy" ? "bid" : "offer"
        } is for a Variable Prepaid Forward Contract`}
        message={
          <div>
            <div>
              <Typography
                size={Size.Small}
                text="Variable Prepaid Forwards are a unique structure offered by one of Caplight's partners."
              />
            </div>
            <div className="space-x-2">
              <Typography
                size={Size.Small}
                text="Learn more about the mechanics of this structure:"
              />
              <LinkButton
                id={"vppf-learn-more"}
                text="Learn more"
                href={`${process.env.PUBLIC_URL}/assets/variable-pre-paid-forward.pdf`}
                onClick={onLearnMoreClick}
              />
            </div>
          </div>
        }
      />
    </div>
  )
}
