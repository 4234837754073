import { Skeleton } from "@stories/components/Antd"

type Props = {
  numRows?: number
}
const TableSkeletonLoader = ({ numRows = 3 }: Props) => (
  <div className="w-full space-y-2 mt-6">
    <Skeleton.Input active size="small" />
    {Array.from({ length: numRows }, (_, i) => (
      <Skeleton.Input key={i} block active size="small" />
    ))}
    <Skeleton.Button block active className="mt-2" />
  </div>
)

export default TableSkeletonLoader
