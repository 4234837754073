import {
  AccountNetworkNode,
  getCurrentConnectedNodes,
  TrustedBrokerConnection,
} from "common/model/TrustedBrokerConnection"
import { isLoaded, mapLoading } from "common/utils/Loading"
import { useCRMContacts } from "src/pages/CRM/Providers/CRMContactsProvider"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { TrustedBrokerInviteArea } from "./TrustedBrokerInviteArea"
import Spinner from "@components/icons/Spinner"
import { TrustedBrokerAccountNodeView } from "./TrustedBrokerAccountNodeView"
import { useTrustedBrokerNetwork } from "../hooks/useTrustedBrokerNetwork"
import { Button } from "@stories/components/Button/Button"
import { toggleChat } from "@components/Intercom/IntercomChat"
import { useFirebase9 } from "src/firebase/Firebase9Context"

export const TrustedBrokerConnectionList = ({ onInvite }: { onInvite: () => void }) => {
  const db = useFirebase9()
  const user = useLoggedInUser()
  const { contacts } = useCRMContacts()
  const { currentTrustedBrokerConnections, remainingConnections, maxInvitesReached } =
    useTrustedBrokerNetwork({
      account: user.user.account,
    })
  const connectedAccounts = mapLoading<TrustedBrokerConnection[], AccountNetworkNode[]>(
    (connections) => getCurrentConnectedNodes(user.user.account, connections)
  )(currentTrustedBrokerConnections)

  if (!isLoaded(connectedAccounts) || !isLoaded(contacts)) return <Spinner size="lg" />

  return (
    <>
      {connectedAccounts.map((connectedAccount, i) => (
        <TrustedBrokerAccountNodeView node={connectedAccount} key={i} />
      ))}
      <TrustedBrokerInviteArea
        maxInvitesReached={maxInvitesReached}
        connections={currentTrustedBrokerConnections}
        onInvite={onInvite}
        remainingConnections={remainingConnections}
      />
      {maxInvitesReached ? (
        <Button
          variant="hollow"
          label="Request more invites"
          onClick={() =>
            toggleChat(
              db,
              user.user,
              true,
              "Hi Caplight! I would like to add more brokers to my network.",
              "showNewMessage"
            )
          }
          isFullWidth
          size="small"
        />
      ) : null}
    </>
  )
}
