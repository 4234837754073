import { OrderQuantityTerms, StructureOrderTerms } from "./Types/Terms"
import { SimpleRecTraversal, unsafeRecordFields } from "../../utils/fp/optics/RecTraversal"
import { field } from "../../utils/fp/optics/RecLens"
import { arrayTraversal } from "../../utils/fp/optics/Traversal"
import { KVP } from "../../utils/data/AssociationList"
import { Order } from "./Models/Internal"

// eslint-disable-next-line prettier/prettier

export const orderStructureTerms: SimpleRecTraversal<
  Pick<Order, "terms">,
  StructureOrderTerms[keyof StructureOrderTerms]
> = field("terms")<Pick<Order, "terms">>()
  .composeRecTraversal(unsafeRecordFields())
  .composeTraversal(arrayTraversal<KVP<{}, Partial<Record<number, OrderQuantityTerms | null>>>>())
  .composeRecTraversal(field("key")<KVP<{}, Partial<Record<number, OrderQuantityTerms | null>>>>())
// eslint-disable-next-line prettier/prettier

export const orderQuantityTerms: SimpleRecTraversal<
  Pick<Order, "terms">,
  OrderQuantityTerms | null | undefined
> = field("terms")<Pick<Order, "terms">>()
  .composeRecTraversal(unsafeRecordFields())
  .composeTraversal(arrayTraversal<KVP<{}, Partial<Record<number, OrderQuantityTerms | null>>>>())
  .composeRecTraversal(
    field("value")<KVP<{}, Partial<Record<number, OrderQuantityTerms | null>>>>()
  )
  .composeRecTraversal(unsafeRecordFields())
