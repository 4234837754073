export const POSTGRES_QUERIES = {
  /** Use only from the node.js server */
  FUNDING_ROUNDS: "funding-rounds",
  FUNDING_ROUNDS_PBIDS: "funding-rounds-pbids",
  SECONDARY_SALES_PBIDS: "secondary-sales-pbids",

  COMPANY_INFO: "company-info",
  COMPS: "comps",
  DELETE_COMP: "delete-comp",
  ADD_COMP: "add-comp",
  CONFIRM_COMP: "confirm-comp",

  BASIC_VOLATILITY: "basic-volatility-for-comps",
  BASIC_VOLATILITY_FOR_TICKERS: "basic-volatility-for-tickers",
  COMPS_DATA_TO_CHART: "comps-data-to-chart",
  COMPS_VOL_FOR_A_DEAL: "comps-vol-for-a-deal",
  RECENT_VOLS_FOR_TICKERS: "recent-vols-for-tickers",
  PUBLIC_INDEX_CHANGE: "public-index-change",
  INVESTOR_INVESTMENTS: "investor-investments",
  PERFORMANCE_SINCE_IPO: "performance-since-ipo",
  PERFORMANCE_SINCE_IPO_TO_TODAY: "performance-since-ipo-to-today",
  PUBLIC_COMPS_PERFORMANCE_FOR_SELECTED_DAYS: "public-comps-performance-for-days",
  PUBLIC_COMPS_FINANCIALS_FOR_SELECTED_DAYS: "public-comps-financials-for-days",
  COMPS_SUMMARY_DATA: "comps-summary-tabular-data",
  PUBLIC_COMPS: "public-comps",
  MUTUAL_FUND_MARKS_FOR_TABLE: "mutual-fund-marks-for-table-display",
  INDEX_BY_NASDAQ_100: "index-by-nasdaq-100",

  GET_COMPANY_ADDITIONALLY_KNOWN_AS: "company-additionally-known-as",
  SET_COMPANY_ADDITIONALLY_KNOWN_AS: "set-company-additionally-known-as",
  GET_COMPANY_TICKERS: "company-tickers",
  SET_COMPANY_TICKERS: "set-company-tickers",
  GET_COMPANY_DOMAIN: "company-domain",
  SET_COMPANY_DOMAIN: "set-company-domain",
  PREVIEW_FUNDING_ROUNDS_JSON: "preview-funding-rounds-json",
  UPLOAD_FUNDING_ROUNDS_JSON_TO_DB: "upload-funding-rounds-json",
  CALCULATE_OPTION_PRICES: "option-prices-for-public-comps",
  COMPANY_INFO_BY_EXTERNAL_ID: "company-info-by-external-id",
  CREATE_OR_UPDATE_COMPANY: "create-or-update-company",
  LOGO_PARSER: "logo-parser",
  CAPLIGHT_PRICE_ESTIMATE_FOR_A_COMPANY: "caplight-composite-for-a-company",
  DEFAULT_CAPLIGHT_PRICE_ESTIMATE_FOR_A_COMPANY_W_MODEL_V:
    "default-caplight-composite-for-a-company-with-model-version",
  CAPLIGHT_PRICE_ESTIMATE_META_FOR_A_COMPANY: "caplight-composite-meta-for-a-company",
  CAPLIGHT_PRICE_ESTIMATE_SCORES_FOR_ALL: "caplight-composite-scores-for-all",
  CAPLIGHT_PRICE_ESTIMATE_SCORE_HISTORY: "caplight-composite-score-history-for-a-company",
  MUTUAL_FUND_MARKS_AGGREGATION: "mutual-fund-marks-aggregation",
  STRUCTURE_PRICER_BACKTEST: "structure-pricer-backtest",
  PRICE_ESTIMATE_MONTHLY_FIXING: "price-estimate-monthly-fixing",
  PRICE_ESTIMATE_DAILY_FIXING: "price-estimate-daily-fixing",
  PRICE_ESTIMATE_EOD: "price-estimate-eod",
  PRICE_ESTIMATE_FIXED_EOD_HISTORY: "price-estimate-fixed-eod-history",
  PATCHED_MARKETPRICE: "price-estimate-patched",
  PRICE_ESTIMATE_META_EOD: "price-estimate-meta-eod",
  SPECIAL_CASE_CAPLIGHT_PRICE_ESTIMATE: "special-cases-caplight-composite-for-a-company",
  CAPLIGHT_PRICE_ESTIMATE_FOR_ORDER_DATE_ATTRIBUTION:
    "caplight-price-estimate-for-order-date-attribution",
  CAPLIGHT_PRICE_ESTIMATE_POSTGRES_IDS: "caplight-composite-companies-latest",
  SPACEX_SPV_ADJUSTMENT: "adjust-spacex-spvs",
  MOST_VIEWED_COMPANIES: "most-viewed-companies",
  AVERAGE_SPREAD_IN_THE_MARKET: "average-spread-in-the-market",
  NEWSLETTER_FUNDING_ROUNDS_V2: "get-newsletter-funding-rounds-with-grouping",
  NEWS_FEED_WITH_GROUPING: "get-newsfeed-with-grouping",
  ADMIN_MODIFY_NEWS_FEED: "admin-modify-newsfeed",
  NEWSFEED_SNAPSHOTS: "newsfeed-snapshots",
  ALL_SECTOR_LIST: "all-sector-list",

  LIST_OF_INVESTORS_FOR_DEALS: "list-of-investors-for-deals",
  LIST_OF_SOURCES_FOR_DEALS: "list-of-sources-for-deals",

  GET_INVESTORS_FOR_COMPANY_BY_ROUNDS: "investors-for-company-by-rounds",
  GET_INVESTORS_FOR_COMPANY_WITH_FUNDING_ROUNDS: "investors-for-company-with-rounds",
  GET_INVESTOR_EMPLOYEES: "investor-employees",
  EMAIL_FOR_INVESTOR_EMPLOYEE: "retrieve-email-for-investor-employee-info",
  COMPANY_EXCEL_REPORT: "generate-excel-report-for-one-company",
  REG_D_FILINGS: "reg-d-filings",
  PUBLIC_COMPS_SUMMARY: "public-comps-summary-snapshot",
  LATEST_MARKET_CAPS: "latest-market-capitalizations",
  ALL_PUBLIC_COMPANIES: "all-public-companies",
}

export const ML_PYTHON_QUERIES = {
  CREATE_COMPS: "create-comps",
}

export const PYTHON_CALCULATIONS = {
  CALC_SAVE_CAPLIGHT_PRICE_ESTIMATE_FOR_COMPANIES: "calc-and-save-caplight-composite-for-companies",
  PROBABILITY_OF_PRICE_MOVE: "probability-of-price-move",
  ATTRIBUTE_ORDERS_PRICE_TO_DATE: "attribute-orders-price-to-date",
}
