import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { defaultIfLoading } from "common/utils/Loading"
import { TrustedBrokerSearch } from "./components/TrustedBrokerSearch"
import { useState } from "react"
import { TrustedBrokerNetworkSentInvites } from "./components/TrustedBrokerNetworkSentInvites"
import { Button } from "@stories/components/Button/Button"
import { TrustedBrokerNetworkReceivedInvites } from "./components/TrustedBrokerNetworkReceivedInvites"
import { useTrustedBrokerNetwork } from "./hooks/useTrustedBrokerNetwork"
import { PlusIcon } from "@stories/icons/PlusIcon"
import Alert from "@stories/components/Alert/Alert"
import { handleConsoleError } from "src/utils/Tracking"
import { ExclamationPointCircleIcon } from "@stories/icons/ExclamationPointCircleIcon"
import pluralize from "pluralize"
import { ChevronLeftIcon } from "@stories/icons/ChevronLeftIcon"

export const TrustedBrokerInviteManagementPage = () => {
  const user = useLoggedInUser()
  const [inviteOpen, setInviteOpen] = useState(false)
  const {
    handleAddToNetwork,
    currentTrustedBrokerConnections,
    handleRejectInvite,
    acceptInvite,
    maxInvitesReached,
    remainingConnections,
  } = useTrustedBrokerNetwork({
    account: user.user.account,
  })
  const handleStartInvite = () => setInviteOpen(true)

  if (inviteOpen)
    return (
      <>
        <div className="flex flex-col items-start">
          <Button
            variant="hollow"
            label="Back"
            leftIcon={<ChevronLeftIcon />}
            onClick={() => setInviteOpen(false)}
            size="small"
          />
        </div>
        <TrustedBrokerSearch
          connections={defaultIfLoading(currentTrustedBrokerConnections, [])}
          handleAddToNetwork={(props) => {
            setInviteOpen(false)
            handleAddToNetwork(props).catch(handleConsoleError)
          }}
        />
      </>
    )

  return (
    <>
      {maxInvitesReached ? (
        <Alert
          icon={<ExclamationPointCircleIcon />}
          variant="default"
          headline="Network Limit Reached"
          message="You have reached the limit of the number of Broker teams you can add to your trusted network."
        />
      ) : null}
      <div>
        <Button
          leftIcon={<PlusIcon />}
          label={`Add a Broker (${remainingConnections} ${pluralize(
            "invite",
            remainingConnections
          )} remaining)`}
          isDisabled={maxInvitesReached}
          onClick={handleStartInvite}
        />
      </div>
      <TrustedBrokerNetworkReceivedInvites
        connections={defaultIfLoading(currentTrustedBrokerConnections, [])}
        maxInvitesReached={maxInvitesReached}
        onAcceptInvite={acceptInvite}
        onRejectInvite={handleRejectInvite}
      />
      <TrustedBrokerNetworkSentInvites
        connections={defaultIfLoading(currentTrustedBrokerConnections, [])}
      />
    </>
  )
}
