import { PageTitle } from "@components/typography/PageTitle"
import Typography, { Color, Size } from "@stories/components/Typography/Typography"
import { EmailConnectBanner } from "../CRM/Components/EmailConnectBanner"
import { useLiveGmailAuthTokens } from "../Email/Admin/useLiveGmailAuthTokens"
import { useRevokeGoogleAuthTokens } from "../Email/GmailAuthTokenControls"
import { useConnectGmail } from "../Email/useConnectEmail"
import { GoogleAuthToken } from "common/model/Google/GoogleAuthToken"
import { isLoaded } from "common/utils/Loading"
import { Button } from "@stories/components/Button/Button"
import { notification } from "@stories/components/Antd"
import { handleConsoleError } from "src/utils/Tracking"

export const EmailIntegrationSettingsPage = () => {
  const { isGmailConnected } = useConnectGmail()
  const authTokens = useLiveGmailAuthTokens()

  return (
    <div className="shadow-md bg-neutral-white border-neutral-400 my-4 p-8 rounded-md w-full">
      <PageTitle title="Gmail Integration" />
      <p className="mt-4 border-b pb-4">
        <Typography
          color={Color.Subtitle}
          size={Size.Small}
          text="Configure the Caplight Gmail integration."
        />
      </p>
      {!isGmailConnected && (
        <>
          <div className="mt-4 flex flex-col gap-4">
            <Typography
              size={Size.Small}
              htmlTag="div"
              text="Our Gmail integration provides for seamless import of your outbound and inbound emails into Caplight. Your conversations are fully private."
            />
            <Typography
              size={Size.Small}
              htmlTag="div"
              text="In addition to conversation tracking, the Gmail integration includes powerful AI tooling for automatic order capture based on your email activity."
            />
          </div>
          <div className="mt-4">
            <EmailConnectBanner client="gmail" />
          </div>
        </>
      )}
      {isGmailConnected && isLoaded(authTokens) && (
        <div className="mt-4">
          <RevokeAuthTokensButton authTokens={authTokens} />
        </div>
      )}
    </div>
  )
}

const RevokeAuthTokensButton = ({ authTokens }: { authTokens: GoogleAuthToken[] }) => {
  const revokeGmailAuthTokens = useRevokeGoogleAuthTokens(authTokens)

  return (
    <Button
      label="Remove Gmail Connection"
      variant="secondary"
      onClick={() => {
        // eslint-disable-next-line no-alert
        if (!window.confirm("Are you sure you want to remove your Gmail connection?")) return
        revokeGmailAuthTokens().catch((err) => {
          notification.error({
            message: "Failed to remove Gmail connection",
            placement: "top",
            duration: null,
          })
          handleConsoleError(err)
        })
      }}
    />
  )
}
