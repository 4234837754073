/* eslint-disable react/destructuring-assignment */
import { Interval, showInterval } from "common/utils/data/Interval"
import { shortDateFormat, shortDateNoYear } from "common/utils/dateUtils"

export const showStringInterval = showInterval((x: string) => x)
export const showElementInterval = (t: Interval<JSX.Element>) => (
  <span key="element-interval">
    {t.lowerBound} - {t.upperBound}
  </span>
)

export const IntervalDisplay =
  <T,>(displayPoint: (t: T) => string, collapseDegenerate = false) =>
  ({ upperBound, lowerBound }: Interval<T>) =>
    !collapseDegenerate || upperBound !== lowerBound
      ? `${displayPoint(lowerBound)} - ${displayPoint(upperBound)}`
      : `${displayPoint(lowerBound)}`

export const DateRangeDisplay = (dates: Interval<Date>, collapseDegenerate = false): string =>
  dates.lowerBound.getFullYear() === dates.upperBound.getFullYear()
    ? `${IntervalDisplay(shortDateNoYear, collapseDegenerate)(dates)}, 
      ${dates.lowerBound.toLocaleDateString("en-US", { year: "numeric" })}`
    : `${IntervalDisplay(shortDateFormat, collapseDegenerate)(dates)}`
