import { useCallback, useMemo } from "react"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { useCurrentAccount } from "src/queries/currentUser/useCurrentAccount"
import { isLoaded } from "common/utils/Loading"
import { CSVUploaderCellRenderProps, CSVUploadTable } from "@components/inputs/csv/CSVUploadTable"
import {
  DealCRMIndividualContact,
  minimalFirmContactSchema,
  minimalIndividualContactSchema,
} from "common/model/DealCRM/DealCRMIndividualContact"
import { UnsafeRec } from "common/utils/RecordUtils"
import { mergeCSVSchemas } from "common/csv/CSVSchema"
import { useLazyStatefulCompanyLookupTable } from "src/queries/Company/useLazyCompanyLookupTable"
import { useFirebaseWriter } from "src/firebase/Context"
import { collections } from "common/firestore/Collections"
import {
  Holding,
  MinimalHolding,
  minimalHoldingCRMInterest,
  minimalHoldingSchema,
} from "common/model/holdings/Holding"
import {
  getCRMContactTagName,
  viewDealCRMContactIdFields,
} from "common/model/DealCRM/DealCRMContact"
import { Validation } from "common/containers/Validation"
import { groupBy } from "lodash"
import { uniqueByRep } from "common/utils/data/Array/ArrayUtils"
import { undefinedsToNulls } from "common/utils/ObjectUtils"
import pluralize from "pluralize"
import {
  DealCRMFirmContact,
  viewDealCRMFirmIdFields,
} from "common/model/DealCRM/DealCRMFirmContact"
import { useModal } from "@components/layout/Modal"
import Typography, { Color, Size } from "@stories/components/Typography/Typography"
import CompanyAutocompletePill from "@components/companies/select/CompanyAutocompletePill"
import {
  DealCRMNote,
  minimalContactNote,
  minimalHoldingNote,
} from "common/model/DealCRM/DealCRMNote"
import {
  contactToDealCRMNoteContactSource,
  holdingToDealCRMNoteHoldingSource,
} from "common/model/DealCRM/DealCRMNoteSourceConverters"
import { useCRMContacts } from "src/pages/CRM/Providers/CRMContactsProvider"
import { commaSeparateWithAnd } from "common/utils/StringUtils"
import { buildCRMNote } from "../../Notes/createCRMNote"
import { DangerIcon } from "@stories/icons/DangerIcon"
import { Routes } from "src/Routes/Routes"
import { useNavigate } from "react-router-dom"

const CompanyCell = ({ value, handleChange, error }: CSVUploaderCellRenderProps) => {
  const { open, closeModal, openModal } = useModal()
  return (
    <>
      {open ? (
        <div className=" min-w-36">
          <CompanyAutocompletePill
            showDropDown
            handleSelect={(v) => {
              if (!v) return
              handleChange(v.name)
              closeModal()
            }}
            selected={undefined}
            autoFocus
          />
        </div>
      ) : (
        <div
          onClick={openModal}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => (e.code === "Enter" ? openModal() : null)}
        >
          <Typography text={value} size={Size.Small} />
          {error ? (
            <div className="z-10 absolute right-2 top-2">
              <DangerIcon color={Color.Danger} tooltipTitle={error} />
            </div>
          ) : null}
        </div>
      )}
    </>
  )
}

export const FirmContactHoldingsUploader = () => {
  const account = useCurrentAccount()
  const user = useLoggedInUser()
  const db = useFirebaseWriter()
  const { findContactByTagName } = useCRMContacts()
  const lookup = useLazyStatefulCompanyLookupTable("name")
  const navigate = useNavigate()

  const saveFirmContacts = useCallback(
    async (
      firm: DealCRMFirmContact | null,
      contacts: {
        contact: DealCRMIndividualContact
        contactNote: Omit<DealCRMNote, "source"> | null
        holding: MinimalHolding
        holdingNote: Omit<DealCRMNote, "source"> | null
      }[]
    ) => {
      const batch = db.db.batch()
      if (firm) {
        batch.set(db.db.collection(collections.dealCRM.contacts).doc(firm.id), firm)
      }
      const contactsByName = uniqueByRep<DealCRMIndividualContact, string | null>(
        getCRMContactTagName
      )(contacts.map(({ contact }) => contact))
      const holdingsByContactName = groupBy(
        contacts.map(({ contact, holding, contactNote, holdingNote }) => ({
          contact,
          holding,
          contactNote,
          holdingNote,
        })),
        ({ contact }) => getCRMContactTagName(contact)
      )
      contactsByName.forEach((contact) => {
        const existingContact = findContactByTagName(getCRMContactTagName(contact))
        if (!existingContact) {
          batch.set(db.db.collection(collections.dealCRM.contacts).doc(contact.id), contact)
        }
        const holdings = holdingsByContactName[getCRMContactTagName(contact)]
        if (holdings && holdings.length) {
          holdings.forEach(({ holding, contactNote, holdingNote }) => {
            if (contactNote) {
              const builtContactNote = buildCRMNote({
                content: contactNote.note,
                user: user.user,
                source: contactToDealCRMNoteContactSource(existingContact ?? contact),
                mentions: [],
              })
              batch.set(
                db.db.collection(collections.dealCRM.notes).doc(builtContactNote.id),
                builtContactNote
              )
            }
            if (!holding) return
            const finalizedHolding = undefinedsToNulls({
              ...holding,
              shareholder: {
                tag: "crm_contact",
                contact: viewDealCRMContactIdFields(existingContact ?? contact),
              },
            } satisfies Holding)
            batch.set(db.db.collection(collections.holdings).doc(holding.id), finalizedHolding)
            if (holdingNote) {
              const builtHoldingNote = buildCRMNote({
                content: holdingNote.note,
                user: user.user,
                source: holdingToDealCRMNoteHoldingSource(finalizedHolding),
                mentions: [],
              })
              batch.set(
                db.db.collection(collections.dealCRM.notes).doc(builtHoldingNote.id),
                builtHoldingNote
              )
            }
            if (finalizedHolding.intent === "sell" || finalizedHolding.intent === "buy-more") {
              const crmInterest = minimalHoldingCRMInterest({
                ...finalizedHolding,
                intent: finalizedHolding.intent,
              })
              batch.set(
                db.db.collection(collections.dealCRM.buySellInterest).doc(crmInterest.id),
                crmInterest
              )
            }
          })
        }
      })
      await batch.commit()
    },
    [db.db, findContactByTagName, user.user]
  )

  const buttonSubtext = useCallback(
    (
      rows: (readonly [
        DealCRMFirmContact | null,
        DealCRMIndividualContact,
        Omit<DealCRMNote, "source"> | null,
        MinimalHolding,
        Omit<DealCRMNote, "source"> | null
      ])[]
    ) => {
      if (rows.length === 0) return ""

      const uniqueFirms = uniqueByRep<DealCRMFirmContact | null, string | null>(
        (f) => f?.name ?? null
      )(rows.map(([firm]) => firm))
      const uniqueContacts = uniqueByRep<DealCRMIndividualContact, string>(
        (c) => `${c.firstName} ${c.lastName}`
      )(rows.map(([, contact]) => contact))
      const uniqueHoldings = uniqueByRep<MinimalHolding, string>((h) => `${h?.id ?? "none"}`)(
        rows.map(([, , , holding]) => holding)
      ).flatMap((h) => h ?? [])

      const uniqueData = [
        uniqueFirms.length > 0
          ? [`${uniqueFirms.length} ${pluralize("Firm", uniqueFirms.length)}`]
          : [],
        uniqueContacts.length > 0
          ? [`${uniqueContacts.length} ${pluralize("Contact", uniqueContacts.length)}`]
          : [],
        uniqueHoldings.length > 0
          ? [`${uniqueHoldings.length} ${pluralize("Holding", uniqueHoldings.length)}`]
          : [],
      ]

      return `Upload ${commaSeparateWithAnd(uniqueData.flat())}`
    },
    []
  )

  const submitContactsAndHoldings = useMemo(
    () =>
      async (
        rows: (readonly [
          DealCRMFirmContact | null,
          DealCRMIndividualContact,
          Omit<DealCRMNote, "source"> | null,
          MinimalHolding,
          Omit<DealCRMNote, "source"> | null
        ])[]
      ) => {
        const uniqueFirms = uniqueByRep<DealCRMFirmContact | null, string | null>(
          (f) => f?.name ?? null
        )(rows.map(([firm]) => firm))
        await Promise.all(
          uniqueFirms.map((f) => {
            if (f === null) {
              return saveFirmContacts(
                null,
                rows.flatMap(([firm, contact, contactNote, holding, holdingNote]) =>
                  firm ? [] : [{ contact, contactNote, holding, holdingNote }]
                )
              )
            }
            return saveFirmContacts(
              f,
              rows.flatMap(([firm, contact, contactNote, holding, holdingNote]) =>
                firm?.name === f.name
                  ? [
                      {
                        contact: { ...contact, firm: viewDealCRMFirmIdFields(f) },
                        holding,
                        contactNote,
                        holdingNote,
                      },
                    ]
                  : []
              )
            )
          })
        )
        navigate(Routes.crm.allContacts)
      },
    [navigate, saveFirmContacts]
  )
  const firmSchema = useMemo(() => minimalFirmContactSchema(user.user), [user.user])
  const holdingSchema = useMemo(() => minimalHoldingSchema(user.user, lookup), [lookup, user.user])
  const contactSchema = useMemo(() => minimalIndividualContactSchema(user.user), [user.user])
  const holdingNote = useMemo(() => minimalHoldingNote(user.user), [user.user])
  const contactNote = useMemo(() => minimalContactNote(user.user), [user.user])
  const jointSchema = useMemo(
    () =>
      mergeCSVSchemas(
        mergeCSVSchemas(
          mergeCSVSchemas(firmSchema, contactSchema, (l, r) => Validation.Ok([l, r] as const)),
          mergeCSVSchemas(contactNote, holdingSchema, (l, r) => Validation.Ok([l, r] as const)),
          (l, r) => Validation.Ok([...l, ...r] as const)
        ),
        holdingNote,
        (l, r) => Validation.Ok([...l, r] as const)
      ),
    [contactSchema, firmSchema, holdingSchema, holdingNote, contactNote]
  )
  if (!isLoaded(account)) return null
  return (
    <CSVUploadTable
      schema={jointSchema}
      headers={UnsafeRec.keys(jointSchema.row)}
      cellInputOverrides={{ company: CompanyCell }}
      initialContents={[]}
      submit={submitContactsAndHoldings}
      buttonText={(rows) => `Upload ${rows.length} ${pluralize("Holding", rows.length)}`}
      buttonSubtext={buttonSubtext}
      exampleFile={{
        description: "Upload your CSV with holdings and contacts. Below is the expected format:",
        filePath: "/FirmContactHoldingsExample.csv",
      }}
    />
  )
}
