import { firestoreConverter } from "@model/FirestoreConverter"
import { collections } from "common/firestore/Collections"
import { LinkedBroker } from "common/model/LinkedBroker"
import { getDocs, collection, limit, query } from "firebase/firestore"
import { useState, useEffect } from "react"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import { handleConsoleError } from "src/utils/Tracking"

export const useLinkedBrokersQuery = (queryLimit?: number) => {
  const { db } = useFirebase9()
  const [allBrokers, setAllBrokers] = useState<LinkedBroker[]>([])
  useEffect(() => {
    getDocs(
      query(
        collection(db, collections.linkedBrokers),
        ...(queryLimit ? [limit(queryLimit)] : [])
      ).withConverter<LinkedBroker>(firestoreConverter<LinkedBroker>())
    )
      .then(({ docs }) => docs.map((d) => d.data()))
      .then(setAllBrokers)
      .catch(handleConsoleError)
  }, [db, queryLimit, setAllBrokers])

  return allBrokers
}
