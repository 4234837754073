import { CloseOutlined } from "@ant-design/icons"
import { Dropdown } from "@components/inputs/Dropdown"
import { IconButton } from "@components/buttons/IconButton"
import { FilterFnRecord, Variant } from "common/model/newsfeed/NewsFeed"

export const afterSeries = (series: string): FilterFnRecord => ({
  news: {
    function: () => true,
    asStrings: { field: "fundingRoundAbove", value: series },
  },
  funding_rounds: {
    function: (t) => (t.round_name || "") >= series,
    asStrings: { field: "fundingRoundAbove", value: series },
  },
})

export const AfterSeries = <V extends Variant>({
  onEnable,
  variant,
  valueString,
}: {
  onEnable: (fn: FilterFnRecord[V] | undefined) => void
  variant: V
  valueString: string | null
}) => (
  <div className={`flex flex-col space-y-2 ${variant === "news" ? "opacity-50" : ""}`}>
    <div className="text-xs mr-4 w-40 whitespace-nowrap">
      Funding stage{variant === "news" ? " (coming soon)" : ""}
    </div>
    <div className="flex flex-row space-x-0">
      <Dropdown
        label=""
        id={`${variant}-filter-after-series`}
        disabled={variant === "news"}
        options={["Series A", "Series B", "Series C", "Series D", "Series E", "Series F"].map(
          (a) => ({ id: a, name: a })
        )}
        small
        minWidthPx={150}
        selected={valueString ? { id: valueString, name: valueString } : undefined}
        setSelected={(option) => {
          onEnable(option.id ? afterSeries(option.id)[variant] : undefined)
        }}
      />
      <IconButton
        onClick={() => {
          onEnable(undefined)
        }}
        size="xs"
        className="p-0 w-6 h-6 m-0 -ml-4 text-neutral-400"
        icon={variant === "news" ? () => <></> : () => <CloseOutlined />}
      />
    </div>
  </div>
)
