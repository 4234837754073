import { useEffect, useState } from "react"

export const useArrayStepper = <T,>(initialItems: T[]) => {
  const [allItems, setItems] = useState<T[]>(initialItems)
  const [currentIndex, setCurrentIndex] = useState<number>(-1)
  useEffect(() => {
    if (allItems.length > 0) {
      setCurrentIndex((prevIndex) => {
        if (prevIndex === -1) {
          return 0
        } else if (prevIndex > allItems.length - 1) {
          return allItems.length - 1
        } else {
          return prevIndex
        }
      })
    }
  }, [allItems.length])
  const canIncrementIndex = currentIndex < allItems.length - 1
  const incrementIndex = () => {
    if (canIncrementIndex) {
      setCurrentIndex((prevIndex) => prevIndex + 1)
    }
  }
  const canDecrementIndex = currentIndex > 0
  const decrementIndex = () => {
    if (canDecrementIndex) {
      setCurrentIndex((prevIndex) => prevIndex - 1)
    }
  }

  const updateCurrentItem = (newItem: Partial<T>) =>
    setItems((prevItems) =>
      prevItems.map((i, index) => (index === currentIndex ? { ...i, ...newItem } : i))
    )

  return {
    setItems,
    updateCurrentItem,
    allItems,
    currentItem: allItems[currentIndex] ?? null,
    currentIndex,
    canIncrementIndex,
    incrementIndex,
    canDecrementIndex,
    decrementIndex,
  }
}
