import { Button } from "@stories/components/Button/Button"
import { PlusIcon } from "@stories/icons/PlusIcon"
import { TrustedBrokerConnection } from "common/model/TrustedBrokerConnection"
import { isLoaded, Loading } from "common/utils/Loading"
import pluralize from "pluralize"

export const TrustedBrokerInviteArea = ({
  connections,
  remainingConnections,
  maxInvitesReached,
  onInvite,
}: {
  connections: Loading<TrustedBrokerConnection[]>
  remainingConnections: number
  maxInvitesReached: boolean
  onInvite: () => void
}) => {
  if (!isLoaded(connections)) return null
  return (
    <div className=" bg-neutral-200 border-neutral-400 border-2 border-dashed rounded flex items-center justify-center h-20 w-full">
      <Button
        variant="hollow-link"
        leftIcon={<PlusIcon />}
        label={`Add a Broker (${remainingConnections} ${pluralize(
          "invite",
          remainingConnections
        )} remaining)`}
        isDisabled={maxInvitesReached}
        onClick={onInvite}
      />
    </div>
  )
}
