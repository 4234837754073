import { DocumentData } from "@firebase/firestore-types"
import { collections } from "../../firestore/Collections"
import { firestoreConverter } from "../../model/FirestoreConverter"
import { FirebaseCommon } from "../../firestore/Interface"
import {
  TradeDashboardView,
  TradeDashboardViewType,
  tradeDashboardViews,
} from "./TradeDashboardViews"

/** The data needed to maintain a cached version of some query in firebase */
export type MaterializedView<S, T> = {
  name: string
  source: S
  target: {
    add: (
      doc: MaterializedViewDoc
    ) => Promise<{ collection: (s: string) => { add: (t: DocumentData) => Promise<unknown> } }>
  }
  query: {
    run: (source: S) => Promise<T[]>
  }
}

export const updateMaterializedView = async <S, T extends DocumentData>(
  view: MaterializedView<S, T>
) => {
  const results = await view.query.run(view.source)
  const resultDoc = await view.target.add({ createdAt: new Date(), viewName: view.name })
  await Promise.all(results.map((result) => resultDoc.collection("viewResults").add(result)))
  return resultDoc
}

/** Parent document for a subcollection of cached query results */
export type MaterializedViewDoc = {
  id?: string
  viewName: string
  createdAt: Date
}

export type MaterializedViewName = TradeDashboardView
export type MaterializedViewType<T extends MaterializedViewName> = TradeDashboardViewType<T>

const allowFrontendQueries = true

/** Fetch the subdocuments of the latest MaterializedViewDoc for a given query */
export const latestMaterializedView =
  <K extends MaterializedViewName, T extends MaterializedViewType<K> = MaterializedViewType<K>>(
    name: K
  ) =>
  async (db: FirebaseCommon.DB): Promise<MaterializedViewType<K>[]> => {
    try {
      const cached = await db
        .collection(collections.materializedViews)
        .where("viewName", "==", name)
        .orderBy("createdAt", "desc")
        .limit(1)
        .get()
        .then(
          (docs) =>
            docs.docs?.[0]?.ref
              .collection("viewResults")
              .withConverter<T & { id?: string }>(firestoreConverter<T & { id?: string }>())
              .get()
              .then((results) =>
                results.docs.map((doc) => doc.data()).flatMap((doc) => (doc ? [doc] : []))
              ) ?? (allowFrontendQueries ? tradeDashboardViews[name].run(db) : [])
        )
      return cached
    } catch (e) {
      if (allowFrontendQueries) {
        const fallback = await tradeDashboardViews[name].run(db)
        return (fallback as MaterializedViewType<K>[]) ?? []
      } else {
        throw e
      }
    }
  }
