import { PriceObservationType } from "../../../model/data-product/pricing/PriceObservation"
import { Array_ } from "../../../utils/data/Array/ArrayUtils"
import { PricingDataRoot } from "../../pricing/PricingDataSource"
import { splitAdjust } from "../../pricing/splitAdjustment/SplitAdjustment"
import { enrichWithPPSImpliedValuation } from "../payload/ImpliedValuation"
import { enrichWithPPSSplitAdjustments } from "../payload/SplitAdjustment"

export const enrichTrades = (root: PricingDataRoot) => (trades: PriceObservationType[]) =>
  Promise.all(
    trades
      .map((trade) => splitAdjust(root, new Date(), trade.company, "trade", trade))
      .map((x) =>
        x.then((trade) => ({
          raw: trade,
          pricePerShareDisplay: {
            displayDate: trade.observationDate.lowerBound,
            pricePerShare: trade.price,
          },
          volumeDisplay: {
            displayDate: trade.observationDate.lowerBound,
            volumeUSD: trade.volume,
          },
        }))
      )
  )
    .then(
      Array_.mapCurried((trade) =>
        enrichWithPPSImpliedValuation(root)(
          trade.raw.company,
          trade.raw.observationDate.lowerBound
        )(trade).then((newRaw) => ({
          ...trade,
          ...newRaw,
        }))
      )
    )
    .then((x) => Promise.all(x))
    .then(
      Array_.mapCurried((trade) => enrichWithPPSSplitAdjustments(root)(trade.raw.company)(trade))
    )
    .then((x) => Promise.all(x))
