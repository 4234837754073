import { Company } from "common/model/Company"
import { OrderPageOrder } from "../types"
import InnerOrderMarketPriceComparison from "./InnerOrderMarketPriceComparison"

type Props = { order?: OrderPageOrder; company: Company }

const LoadedOrderMarketPriceComparison = ({ order, company }: Props) => (
  <InnerOrderMarketPriceComparison
    company={company}
    orderPPS={order?.pricePerShare().or(order?.impliedPricePerShare()).toNullable()}
    orderDirection={order?.direction()}
    estimatePPS={order?.rawPrice().match(
      () => true,
      () => false,
      () => false
    )}
  />
)

export default LoadedOrderMarketPriceComparison
