import { BooleanSelect, UnconfirmableYesNoSelect } from "@components/lib/Select/BooleanSelect"
import SectionLabel from "@stories/components/Inputs/SectionLabel/SectionLabel"
import { OrderDirection, OrderDocuments } from "common/model/Order/Order"
import { UnconfirmableYesNo, unconfirmableYesNoToBoolean } from "common/model/UnconfirmableYesNo"
import { isIntermediaryContext, useAuthContext } from "src/Routes/auth/UserAuthContext"

type HasDocumentsSectionData = {
  orderDocuments?: OrderDocuments
  direction?: OrderDirection
  buyerCashOnHand?: UnconfirmableYesNo | null
}
export const DocumentsSection = ({
  value,
  onChange,
  hiddenFields,
  title,
  titles,
}: {
  value: HasDocumentsSectionData
  onChange: (value: HasDocumentsSectionData) => void
  title: string
  titles: {
    diligenceAvailable: string
    requiresDiligence: string
    buyerCashOnHand: string
    buyerCashOnHandYesAnswer: string
    buyerCashOnHandNoAnswer: string
    buyerCashOnHandUnknownAnswer: string
  }
  hiddenFields?: string[]
}) => {
  const authContext = useAuthContext()

  return hiddenFields?.includes("orderDocuments") ? null : (
    <div className="mt-4">
      <SectionLabel>{title}</SectionLabel>
      <div className="flex flex-col gap-4 mb-4">
        {value.direction === "sell" ? (
          <div>
            <UnconfirmableYesNoSelect
              label={titles.diligenceAvailable}
              onChange={(diligenceAvailable) =>
                onChange({
                  ...value,
                  orderDocuments: { ...value.orderDocuments, diligenceAvailable },
                })
              }
              value={value.orderDocuments?.diligenceAvailable}
              disabled={hiddenFields?.includes("orderDocuments")}
            />
          </div>
        ) : (
          <div>
            <UnconfirmableYesNoSelect
              label={titles.requiresDiligence}
              onChange={(requiresDiligence) =>
                onChange({
                  ...value,
                  orderDocuments: { ...value.orderDocuments, requiresDiligence },
                })
              }
              value={value.orderDocuments?.requiresDiligence}
              disabled={hiddenFields?.includes("orderDocuments")}
            />
          </div>
        )}
        {value.direction === "buy" && (
          <div className="flex flex-col gap-4 mb-4">
            <div>
              {isIntermediaryContext(authContext) ? (
                <UnconfirmableYesNoSelect
                  label={titles.buyerCashOnHand}
                  value={value.buyerCashOnHand}
                  onChange={(buyerCashOnHand) => onChange({ buyerCashOnHand })}
                  options={[
                    {
                      label: titles.buyerCashOnHandYesAnswer,
                      value: "Yes",
                    },
                    {
                      label: titles.buyerCashOnHandNoAnswer,
                      value: "No",
                    },
                    {
                      label: titles.buyerCashOnHandUnknownAnswer,
                      value: "Unknown",
                    },
                  ]}
                />
              ) : (
                <BooleanSelect
                  label={titles.buyerCashOnHand}
                  onChange={(v) => onChange({ buyerCashOnHand: v ? "Yes" : "No" })}
                  value={unconfirmableYesNoToBoolean(value.buyerCashOnHand)}
                  options={[
                    {
                      label: titles.buyerCashOnHandYesAnswer,
                      value: true,
                    },
                    {
                      label: titles.buyerCashOnHandNoAnswer,
                      value: false,
                    },
                  ]}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
