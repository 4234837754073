import { groupBy } from "lodash"
import { assertUnreachable } from "../utils/fp/Function"
import { Case, RecordToWrappedSum } from "../utils/types/Sum"
import { CovariantProxy } from "../utils/types/Proxy"
import { EqWitness } from "../utils/data/Type/Eq"
import { annotate } from "../utils/Coerce"

/** A SQL-like syntax tree. Supports filters, unions, selects, joins, and groupBy. */
export class VirtualQuery<Source, T> {
  // eslint-disable-next-line class-methods-use-this
  readonly returnType: CovariantProxy<T> = () => null

  readonly cache: Map<Source, T[]> = new Map<Source, T[]>()

  constructor(readonly raw: InternalVirtualQuery<Source, T>) {}

  static source = <Source2, T2>(
    value: Case<InternalVirtualQuery<Source2, T2>, "source">["value"]
  ) => new VirtualQuery<Source2, T2>({ tag: "source", value })

  product<T2>(other: VirtualQuery<Source, T2>) {
    return new VirtualQuery<Source, [T, T2]>({
      tag: "product",
      value: [this, other, EqWitness.refl()],
    })
  }

  filter(f: (t: T) => boolean) {
    return new VirtualQuery<Source, T>({ tag: "filter", value: [this, f] })
  }

  union(others: VirtualQuery<Source, T>[]) {
    return new VirtualQuery<Source, T>({
      tag: "union",
      value: [this, ...others],
    })
  }

  map<S extends object>(f: (t: T) => S) {
    return new VirtualQuery<Source, S>({ tag: "map", value: (g) => g(this, f) })
  }

  /** Interpret this query as a function */
  run = async (source: Source): Promise<T[]> => {
    if (!this.cache.has(source)) {
      const result = await runInternalVirtualQuery(this.raw, source)
      this.cache.set(source, result)
    }
    return this.cache.get(source) ?? []
  }

  groupBy = <S extends object, K>(key: (t: T) => K, aggregation: (ts: T[]) => S) =>
    new VirtualQuery<Source, S>({ tag: "groupBy", value: (g) => g(this, key, aggregation) })

  join<T2 extends object>(
    other: T extends object ? VirtualQuery<Source, T2> : never,
    by: (l: T, r: T2) => boolean
  ) {
    return this.product(other)
      .filter(([l, r]) => by(l, r))
      .map(([l, r]) => ({ ...l, ...r }))
  }

  /** An outer join, implemented as groupBy . union . join. */
  leftOuterJoin<T2 extends object>(
    other: T extends object ? VirtualQuery<Source, T2> : never,
    by: (l: T, r: T2) => boolean,
    /** key used for grouping */
    leftKey: (l: T) => unknown
  ): VirtualQuery<Source, T & Partial<T2>> {
    return this.product(other)
      .filter(([l, r]) => by(l, r))
      .map(([l, r]) => annotate<T & Partial<T2>>({ ...l, ...r }))
      .union([this.map((t) => t as T & Partial<T2>)])
      .groupBy(leftKey, (ts) => ts[0])
  }

  precompose<S>(before: VirtualQuery<S, Source>): VirtualQuery<S, T> {
    return VirtualQuery.source((s) =>
      before
        .run(s)
        .then((docs) => Promise.all(docs.map((doc) => this.run(doc))).then((x) => x.flat()))
    )
  }
}

/** Do not export */
type InternalVirtualQuery<Source, T> = RecordToWrappedSum<{
  source: (s: Source) => Promise<T[]>
  product: T extends [infer L, infer R]
    ? [
        VirtualQuery<Source, L>,
        VirtualQuery<Source, R>,
        EqWitness<[L, R], T> /** needed to propagate the constraint */
      ]
    : never
  filter: [VirtualQuery<Source, T>, (t: T) => boolean]
  union: VirtualQuery<Source, T>[]
  map: <R>(f: <S>(x: VirtualQuery<Source, S>, f: (s: S) => T) => R) => R
  groupBy: <R>(f: <S, K>(x: VirtualQuery<Source, S>, key: (s: S) => K, f: (s: S[]) => T) => R) => R
}>

/** Do not export */
const runInternalVirtualQuery = async <Source, Cov>(
  q: InternalVirtualQuery<Source, Cov>,
  source: Source
): Promise<Cov[]> => {
  switch (q.tag) {
    case "source":
      return q.value(source)
    case "filter":
      return q.value[0].run(source).then((docs) => docs.filter((t) => q.value[1](t)))
    case "map":
      return q.value((x, f) => x.run(source).then((docs) => docs.map(f)))
    case "product":
      const ls = await q.value[0].run(source)
      const rs = await q.value[1].run(source)
      return ls.flatMap((l) => rs.map((r) => q.value[2].run([l, r])))
    case "union":
      return Promise.all(q.value.map((x) => x.run(source))).then((docs) => docs.flat())
    case "groupBy":
      return q.value((x, k, f) =>
        x.run(source).then((docs) => {
          const groups = Object.values(groupBy(docs, (doc) => k(doc))).filter((g) => g.length > 0)
          return groups.map((g) => f(g))
        })
      )
    default:
      return assertUnreachable(q)
  }
}
