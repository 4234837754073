export enum RoundSource {
  PITCHBOOK_API = "PITCHBOOK_API",
  TRACXN = "TRACXN",
  PLATFORM_UPLOAD = "PLATFORM_UPLOAD", // Pitchbook platform upload
  PITCHBOOK_DATA_FEED = "PITCHBOOK_DATA_FEED",
  CAPLIGHT = "CAPLIGHT",
}

export enum RoundAuthor {
  SOURCE = "SOURCE",
  CAPLIGHT = "CAPLIGHT",
}

export enum RoundStatus {
  RUMOR_SPECULATION = "Rumor/Speculation",
  POSTPONED = "Postponed",
  FAILED_CANCELLED = "Failed/Cancelled",
  COMPLETED = "Completed",
  ANNOUNCED_IN_PROGRESS = "Announced/In Progress",
  UPCOMING = "Upcoming",
}

export enum CaplightSource {
  PARTIES = "parties_familiar_with_the_company",
  RUMOR = "rumor",
}
