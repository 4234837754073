import { Upload, UploadFile } from "@stories/components/Antd"
import { Button } from "@stories/components/Button/Button"
import { Typography, Weight } from "@stories/components/Typography/Typography"
import { DealDistribution } from "common/model/DealDistribution/DealDistribution"
import { SubmittedDocument, DocumentType } from "common/model/files/DocumentSubmission"
import { ViewDocumentLink } from "src/pages/TradeRegistration/Verification/ViewVerificationLink"
import { useFirebaseWriter } from "src/firebase/Context"
import { uniqueByRep } from "common/utils/data/Array/ArrayUtils"
import { FirebaseWriter } from "src/firebase/Firebase"
import { generateSubmittedDocument } from "src/firebase/Firebase/data-product/DocumentSubmission"
import { TrashIcon } from "@stories/icons/TrashIcon"
import React from "react"

export const uploadFileAndGenerateSubmittedDocuments = (
  db: FirebaseWriter,
  uploadedFileList: UploadFile[],
  path: string,
  documentType: DocumentType
) =>
  uploadedFileList.flatMap((upload) => {
    if (upload.originFileObj === undefined) return []
    db.uploadFile(
      upload.originFileObj,
      path,
      () => {
        console.log("success")
      },
      () => {
        console.warn("fail")
      }
    )
    const fileName = upload.originFileObj.name
    return [generateSubmittedDocument(`${path}/${fileName}`, new Date(), documentType)]
  })

export const DealDistributionUploader = ({
  dealDistribution,
  getFileFromDealDistribution,
  onDocumentsChange,
  documentType,
  path,
  children,
}: {
  dealDistribution: DealDistribution
  getFileFromDealDistribution: (d: DealDistribution) => SubmittedDocument[]
  onDocumentsChange: (docs: SubmittedDocument[]) => void
  documentType: DocumentType
  path: string
  children: React.ReactNode
}) => {
  const db = useFirebaseWriter()
  const updateFiles = (uploadedFileList: UploadFile[]) => {
    const docs = uploadFileAndGenerateSubmittedDocuments(db, uploadedFileList, path, documentType)
    onDocumentsChange(
      uniqueByRep<SubmittedDocument, string | undefined>((d) => d.filePath)([
        ...getFileFromDealDistribution(dealDistribution),
        ...docs,
      ])
    )
  }

  return (
    <>
      <Upload
        fileList={[]}
        multiple
        onPreview={() => false}
        listType="picture"
        accept="application/pdf, .doc, .docx, .jpg, .jpeg, .png, .HEIC, .HEIF"
        beforeUpload={() => false}
        onChange={(f) => {
          updateFiles(f.fileList)
        }}
      >
        {children}
      </Upload>
      {getFileFromDealDistribution(dealDistribution).length ? (
        <Typography text="Added Documents" weight={Weight.Semibold} />
      ) : null}
      {getFileFromDealDistribution(dealDistribution).map((d) => (
        <div className="flex justify-between border px-2" key={d.filePath ?? d.documentType}>
          <ViewDocumentLink document={d} />
          <Button
            variant="icon"
            leftIcon={<TrashIcon />}
            onClick={() =>
              onDocumentsChange(
                getFileFromDealDistribution(dealDistribution).filter(
                  (doc) => doc.filePath !== d.filePath
                )
              )
            }
          />
        </div>
      ))}
    </>
  )
}
