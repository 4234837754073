import { makeAPIGetRequest } from "common/api/Request"
import { OrderAPI } from "common/api/OrderAPI"
import { useEffect, useState } from "react"
import { Loading, isLoaded } from "common/utils/Loading"
import { Order } from "common/model/Order/Order"
import { useCurrentUserAuthToken } from "src/firebase/AuthUtils"
import { config } from "../../config"
import { useWrapOrders } from "./useWrapOrders"
import { handleConsoleError } from "src/utils/Tracking"

export const useCompanyPrivateOrders = (query: { companyId: string }) => {
  const userToken = useCurrentUserAuthToken()
  const [privateOrders, setPrivateOrders] = useState<Loading<Order[]>>("loading")
  useEffect(() => {
    if (isLoaded(userToken)) {
      makeAPIGetRequest<typeof OrderAPI, ["orders", "private"] | ["company", string, "private"]>(
        `${config.firebase.cloudApiUrl}/data/`,
        OrderAPI,
        ["company", query.companyId, "private"],
        userToken
      )()
        .then(
          (orders) => setPrivateOrders(orders),
          () => setPrivateOrders(null)
        )
        .catch(handleConsoleError)
    }
  }, [query, userToken])
  return useWrapOrders(privateOrders)
}
