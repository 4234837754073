import { useMemo } from "react"
import { orderBy } from "lodash"
import { Order } from "common/model/Order/Models/Wrapped"
import { justIfNonempty } from "common/utils/data/Array/Nonempty"
import { Subtitle } from "../../../../components/typography/Subtitle"
import { CTACell, LiveMarketTableHeaderCell, PriceCell, UpdateCell } from "./Cells"
import { VolumeCell } from "../../../../stories/components/Orders/TableCells/VolumeCell"
import { StructureCell } from "../../../../stories/components/Orders/TableCells/StructureCell"
import { useOrderDetailsDrawer } from "../../../../components/OrderDetails/OrderDetailsWrapper"
import { AccessBoundary } from "@components/auth/DataAccess"

const headers = ["updated", "price", "volume", "structure", "view"] as const
type OrderLiveMarketTableHeader = (typeof headers)[number]

const displayHeader = (h: OrderLiveMarketTableHeader): string =>
  ({
    updated: "Updated",
    price: "Price",
    volume: "Volume",
    structure: "Structure",
    view: "",
  }[h])

const makeCellKey = (h: OrderLiveMarketTableHeader, id: string) => `${h}-${id}`

const headerToCell =
  (o: Order) =>
  (h: OrderLiveMarketTableHeader): React.ReactNode => {
    const key = makeCellKey(h, o.id())
    return {
      updated: <UpdateCell key={key} order={o} />,
      price: <PriceCell key={key} order={o} />,
      volume: <VolumeCell key={key} order={o} />,
      structure: <StructureCell key={key} order={o} />,
      view: <CTACell key={key} />,
    }[h]
  }

const EmptyLiveMarketTableRow = ({ companyName }: { companyName: string }) => (
  <tr>
    <td colSpan={headers.length}>
      <div className="p-4 flex items-center justify-center">
        <p>No other live orders for {companyName}</p>
      </div>
    </td>
  </tr>
)

type OrderLiveMarketTableProps = { liveOrders: Order[]; order: Order }

const OrderLiveMarketTable = ({ liveOrders, order }: OrderLiveMarketTableProps) => {
  const onClickOrderRow = useOrderDetailsDrawer()
  const items = useMemo(
    () =>
      orderBy(
        liveOrders.filter((o) => o.id() !== order.id()),
        (o) => o.latestUpdateDate().valueOf(),
        "desc"
      ),
    [liveOrders, order]
  )

  return (
    <AccessBoundary accessLevel={order.isDarkpool() ? "hideFromCaplight" : "public"}>
      <div
        className="flex flex-col space-y-4"
        data-dd-privacy={order.isDarkpool() ? "mask" : "allow"}
      >
        <Subtitle subtitle={`Other Live ${order.company().name} Orders`} />
        <div className="flex flex-col rounded overflow-hidden border border-neutral-400">
          <table className="table-auto h-1 text-left text-sm">
            <thead className="bg-neutral-400 text-neutral-1000">
              <tr>
                {headers.map(displayHeader).map((h) => (
                  <LiveMarketTableHeaderCell key={h}>{h}</LiveMarketTableHeaderCell>
                ))}
              </tr>
            </thead>
            <tbody className="bg-neutral-white text-sm text-neutral-1000 divide-y divide-neutral-400">
              {justIfNonempty(items).match(
                (xs) =>
                  xs.map((o) => (
                    <tr
                      key={o.id()}
                      role="button"
                      onClick={() => onClickOrderRow(o.idFields())}
                      className="hover:bg-neutral-100"
                    >
                      {headers.map(headerToCell(o))}
                    </tr>
                  )),
                () => (
                  <EmptyLiveMarketTableRow companyName={order.company().name} />
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </AccessBoundary>
  )
}

export default OrderLiveMarketTable
