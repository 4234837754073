import { useModal } from "@components/layout/Modal"
import { Button } from "@stories/components/Button/Button"
import NoteModal from "./NoteModal"
import { NoteIcon } from "@stories/icons/NoteIcon"
import { useKeyboardShortcut } from "src/utils/hooks/useKeyboardShortCut"
import { useFeatureFlag } from "src/providers/featureFlags/useFeatureFlags"
import { isIntermediaryAccount } from "common/model/Account"
import { isLoaded } from "common/utils/Loading"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"

const NoteModalButton = () => {
  const { open, openModal, closeModal } = useModal()

  const shortcutText = useKeyboardShortcut("l", openModal)

  return (
    <>
      <Button
        leftIcon={<NoteIcon />}
        label={`Log Note (${shortcutText})`}
        variant="secondary"
        onClick={openModal}
        isFullHeight
      />
      <NoteModal open={open} onClose={closeModal} />
    </>
  )
}
const NoteModalButtonWithFeatureFlag = () => {
  const isCRMEnabled = useFeatureFlag("crm")
  const currentUser = useCurrentUser()
  return isCRMEnabled &&
    isLoaded(currentUser) &&
    isIntermediaryAccount(currentUser.user.account) ? (
    <NoteModalButton />
  ) : null
}

export default NoteModalButtonWithFeatureFlag
