import axios from "axios"
import { APIRoute, APISpec, EndpointBody, EndpointReturnType } from "./Spec"
import * as RandomUtils from "../utils/RandomUtils"

// TODO move
const HeaderKeys = {
  userToken: "x-user-token",
  airtableAuthToken: "x-airtable-auth-token",
  datadogAuthToken: "x-datadog-auth-token",
  releaseVersion: "x-release-version",
  requestId: "x-request-id",
  pythonAuthToken: "x-python-auth-token",
}

const requestHeaders = (params: { userToken: string; releaseVersion?: string }) => ({
  "Content-Type": "application/json",
  [HeaderKeys.userToken]: params.userToken,
  [HeaderKeys.releaseVersion]: params.releaseVersion || "not-found",
  [HeaderKeys.requestId]: RandomUtils.randomString(),
})

export const makeAPIGetRequest =
  <T extends APISpec, const R extends APIRoute<T>>(
    root: string,
    api: T,
    route: R,
    userAuthToken: string
  ) =>
  async (): Promise<EndpointReturnType<T, R>> =>
    axios
      .request({
        url: root + route.join("/"),
        method: "GET",
        headers: requestHeaders({ userToken: userAuthToken }),
      })
      .then((res) => res.data as EndpointReturnType<T, R>)

export const makeAPIPostRequest =
  <T extends APISpec, const R extends APIRoute<T>>(
    root: string,
    api: T,
    route: R,
    userAuthToken: string
  ) =>
  async (body: EndpointBody<T, R>): Promise<EndpointReturnType<T, R>> =>
    axios
      .request({
        url: root + route.join("/"),
        method: "POST",
        data: body,
        headers: requestHeaders({ userToken: userAuthToken }),
      })
      .then((res) => res.data as EndpointReturnType<T, R>)

export const makeAPIPatchRequest =
  <T extends APISpec, const R extends APIRoute<T>>(
    root: string,
    api: T,
    route: R,
    userAuthToken: string
  ) =>
  async (body: EndpointBody<T, R>): Promise<EndpointReturnType<T, R>> =>
    axios
      .request({
        url: root + route.join("/"),
        method: "PATCH",
        data: body,
        headers: requestHeaders({ userToken: userAuthToken }),
      })
      .then((res) => res.data as EndpointReturnType<T, R>)
