import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { Order } from "common/model/Order/Models/Wrapped"
import {
  sourceAttributionImmediateSource,
  sourceAttributionTail,
  sourceEmail,
  sourceFirm,
  sourceName,
} from "common/model/data-product/DataPoint/SourceAttribution"
import { isLoaded } from "common/utils/Loading"
import * as W from "common/utils/Loading/Wrapped"
import CRMContactCopyEmail from "src/pages/CRM/Contacts/ContactDisplay/ContactCopyEmail"
import { UserAvatar } from "src/pages/Orders/shared/Cells"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import OrderPageSidebarCard from "../../components/OrderPageSidebarCard"

export const PreviousSourceInfo = ({ name }: { name: string }) => (
  <div className="flex gap-2">
    <UserAvatar character={name[0]} />
    <Typography weight={Weight.Semibold} text={name} />
  </div>
)

export const SourceAttributionInfo = ({ order }: { order: Order }) => {
  const user = useCurrentUser()
  if (!order.isPrivate || !isLoaded(user)) return null
  return order
    .sourceAttribution(user.user)
    .bind(sourceAttributionTail)
    .bind(sourceAttributionImmediateSource)
    .match(
      (s) =>
        sourceName(s).match(
          (name) => (
            <div className="flex flex-col gap-0">
              <div className="flex gap-2">
                <UserAvatar character={name} />
                <Typography weight={Weight.Semibold} text={name} />
              </div>
              <div className="flex flex-col gap-2 ml-8">
                <Typography
                  size={Size.Small}
                  color={Color.Subtitle}
                  text={sourceFirm(s).withDefault("")}
                />
                {sourceEmail(s).match(
                  (email) => (
                    <CRMContactCopyEmail email={email} />
                  ),
                  () => null
                )}
              </div>
            </div>
          ),
          () => <PreviousSourceInfo name={sourceFirm(s).withDefault("")} />
        ),

      () => null
    )
}

export const SourceAttributionCard = ({ order }: { order: W.Loading<Order> }) =>
  order.match(
    (loadedOrder) => {
      if (!loadedOrder.isPrivate) return null
      return (
        <OrderPageSidebarCard>
          <div className="flex flex-col gap-1">
            <Typography weight={Weight.Bold} text="Broker" />
            <SourceAttributionInfo order={loadedOrder} />
          </div>
        </OrderPageSidebarCard>
      )
    },
    null,
    null
  )
