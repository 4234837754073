import { ReactElement, useMemo } from "react"
import { Either, Right } from "common/containers/Either"
import { useParams } from "react-router-dom"
import { RouteParams } from "src/Routes/types"
import { useAuthContext, UserAuthContext } from "../../Routes/auth/UserAuthContext"
import { loginCheck } from "./conditions/LoginRedirect"
import { AuthCondition, ElementWrapper } from "../../Routes/auth/AuthCondition"
import { checkAuthConditions } from "../../Routes/auth"
import { tosCheck } from "./conditions/TermsOfServiceRedirect"
import { useLocation } from "react-router-dom"

interface IAuthBoundaryProps {
  children: ReactElement
  accessConditions?: (params: RouteParams) => AuthCondition<UserAuthContext, UserAuthContext>[]
  requireToS?: (params: RouteParams) => boolean
}

/**
 * An `AuthCondition<X,Y>` takes an `X` and returns either a new wrapper component or a Y.
 * `AuthBoundary` threads a `UserAuthContext` through an array of `AuthCondition<UserAuthContext, UserAuthContext>`s before rendering `children`.
 * If any `AuthCondition` fails, the `AuthBoundary` short-circuits and returns the resulting wrapper around `children` instead.
 */
const AuthBoundary: React.FunctionComponent<React.PropsWithChildren<IAuthBoundaryProps>> = ({
  children,
  accessConditions: additionalConditions = () => [],
  requireToS: requireSignedToS = () => false,
}) => {
  const ctx: UserAuthContext = useAuthContext()
  const params = useParams()
  const location = useLocation()
  const authConditions = useMemo(
    () => [loginCheck(location), ...additionalConditions(params)],
    [additionalConditions, location, params]
  )
  const requireToSResult = requireSignedToS(params)
  const authResult: Either<ElementWrapper, UserAuthContext> = checkAuthConditions(
    authConditions,
    ctx
  )
  const tosCheckResult = requireToSResult ? tosCheck(ctx) : Right({})
  return authResult.alongside(tosCheckResult).match(
    (x: ElementWrapper) => x(children),
    () => children
  )
}

export default AuthBoundary
