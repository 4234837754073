import { restrictedCollections } from "common/firestore/Collections"
import { firestoreConverter } from "common/model/FirestoreConverter"
import { Order } from "common/model/Order/Order"
import { Loading } from "common/utils/Loading"
import { createContext, ReactNode, useMemo } from "react"
import { useFirebaseWriter } from "src/firebase/Context"
import {
  DocumentContextData,
  FirebaseDocumentProvider,
  useFirebaseDocuments,
} from "./FirebaseDocumentProvider"
import { DocumentIdContainer, FilledContainer } from "./internal/DocumentIdContainer"

export const DarkpoolOrderContext = createContext<DocumentContextData<Order>>({
  documents: {},
  requestDocLookups: () => {},
})

export const DarkpoolOrderProvider = ({ children }: { children: ReactNode }) => {
  const firebase = useFirebaseWriter()
  const darkpoolOrderCollection = useMemo(
    () =>
      firebase.writerDb
        .collection(restrictedCollections.darkpoolOrderObservations)
        .withConverter<Order>(firestoreConverter<Order>()),
    [firebase.writerDb]
  )
  return (
    <FirebaseDocumentProvider Context={DarkpoolOrderContext} collection={darkpoolOrderCollection}>
      {children}
    </FirebaseDocumentProvider>
  )
}

export const useDarkpoolOrders = <C extends DocumentIdContainer = DocumentIdContainer>(
  ids: C
): Loading<FilledContainer<C, Order>> => useFirebaseDocuments(ids, DarkpoolOrderContext)
