import { wrapIcon } from "./IconWrapper"

export const CommentIcon = wrapIcon(
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.6667 1.33334H3.33334C2.80291 1.33334 2.2942 1.54405 1.91913 1.91912C1.54406 2.2942 1.33334 2.8029 1.33334 3.33334V10C1.33334 10.5304 1.54406 11.0391 1.91913 11.4142C2.2942 11.7893 2.80291 12 3.33334 12H11.06L13.5267 14.4733C13.589 14.5351 13.6628 14.584 13.7441 14.6172C13.8253 14.6504 13.9123 14.6672 14 14.6667C14.0875 14.6689 14.1742 14.6507 14.2533 14.6133C14.3751 14.5633 14.4793 14.4784 14.5529 14.3692C14.6264 14.2601 14.666 14.1316 14.6667 14V3.33334C14.6667 2.8029 14.456 2.2942 14.0809 1.91912C13.7058 1.54405 13.1971 1.33334 12.6667 1.33334ZM13.3333 12.3933L11.8067 10.86C11.7444 10.7982 11.6705 10.7493 11.5893 10.7162C11.5081 10.683 11.4211 10.6662 11.3333 10.6667H3.33334C3.15653 10.6667 2.98696 10.5964 2.86194 10.4714C2.73691 10.3464 2.66668 10.1768 2.66668 10V3.33334C2.66668 3.15652 2.73691 2.98696 2.86194 2.86193C2.98696 2.73691 3.15653 2.66667 3.33334 2.66667H12.6667C12.8435 2.66667 13.0131 2.73691 13.1381 2.86193C13.2631 2.98696 13.3333 3.15652 13.3333 3.33334V12.3933Z"
      fill="currentColor"
    />
  </svg>
)
