import { v4 as uuid } from "uuid"
import { collections } from "../../firestore/Collections"
import { Parse, Parser } from "../../parser/ParserClasses/Simple"
import { FirebaseCommon } from "../../firestore/Interface"
import { AccountIdFields } from "../Account"
import { firestoreConverter } from "../FirestoreConverter"
import { UserIdFields, viewUserIdFields } from "../User"
import { DealCRMBrokerContact } from "./DealCRMBrokerContact"
import {
  DealCRMCommonContactImportData,
  DealCRMContact,
  DealCRMContactCommonFields,
  DealCRMContactIdFields,
  DealCRMContactIdFieldsCommon,
  DealCRMContactUpdate,
  constructDealCRMContactParser,
  salesforceIdParser,
} from "./DealCRMContact"
import { DealCRMIndividualContact } from "./DealCRMIndividualContact"
import { defaultContactInvestmentPreferences } from "./ContactInvestmentPreferences"

export type DealCRMFirmContactIdFields = Pick<
  DealCRMFirmContact,
  keyof DealCRMContactIdFieldsCommon
> & {
  tag: "firm"
  isBrokerage: boolean
}

export const viewDealCRMFirmIdFields = (
  contact: DealCRMFirmContact
): DealCRMFirmContactIdFields => ({
  id: contact.id,
  name: contact.name,
  tag: contact.tag,
  isBrokerage: contact.isBrokerage || false,
})

export type DealCRMFirmContact = DealCRMContactCommonFields & {
  tag: "firm"
  isBrokerage: boolean // TODO - migration
  brokerDealer?: string | null // TODO - align with individuals, prob move to a collection eventually
  classification: string // e.g. hedge fund, family office, etc.
  contactIds: string[]
  contacts: (DealCRMContactIdFields & { isPrimaryContact: boolean; removedFromFirmAt?: Date })[]
  updates: DealCRMContactUpdate<DealCRMFirmContact>[]
  aum?: number | null
  postgresWebsiteDomain?: string

  platformAccountId?: string
  airtableFirmId?: string
}

export const isNonBrokerageFirm = (
  contact: DealCRMContact
): contact is DealCRMFirmContact & { isBrokerage: false } =>
  contact.tag === "firm" && !contact.isBrokerage

export const isBrokerage = (
  contact: DealCRMContact
): contact is DealCRMFirmContact & { isBrokerage: true } =>
  contact.tag === "firm" && !!contact.isBrokerage

export type DealCRMFirmContactImportData = Pick<
  DealCRMFirmContact,
  "classification" | "name" | "sourceId"
> &
  DealCRMCommonContactImportData & {
    accountOwner: string
    isFundLP: boolean
  }

export const dealCRMFirmContactFieldParsers: {
  [key in keyof DealCRMFirmContactImportData]: Parser<string, {}, DealCRMFirmContactImportData[key]>
} = {
  sourceId: salesforceIdParser,
  name: Parse.String.freeText,
  classification: Parse.String.freeText,
  accountOwner: Parse.String.freeText,
  isFundLP: Parse.Boolean.booleanish,
  lastContactedAt: Parse.Dates.isoDate,
}
export const dealCRMFirmContactImportDataParser = constructDealCRMContactParser(
  dealCRMFirmContactFieldParsers
)

export const finalizeFirmContactImport =
  (user: UserIdFields, accountId: AccountIdFields["id"], imported: DealCRMFirmContactImportData) =>
  (existing: DealCRMFirmContact | null): DealCRMFirmContact => {
    if (existing === null) {
      return {
        accountId,
        contactBuyInterest: [],
        contactSellInterest: [],
        createdAt: new Date(),
        holdings: [],
        id: uuid(),
        isFundLP: imported.isFundLP, // seems odd
        notes: [],
        sourceId: imported.sourceId,
        creationSource: "import",
        tag: "firm",
        updates: [],
        createdBy: user,
        lastContactedAt: imported.lastContactedAt,
        name: imported.name,
        classification: imported.classification,
        contactIds: [],
        contacts: [],
        isBrokerage: imported.classification.toLowerCase().includes("broker"),
        investmentPreferences: defaultContactInvestmentPreferences,
      }
    } else {
      const updateData: Partial<DealCRMFirmContact> = {
        isFundLP: imported.isFundLP,
        sourceId: imported.sourceId,
        lastContactedAt: imported.lastContactedAt,
        name: imported.name,
        classification: imported.classification,
        isBrokerage: imported.classification.toLowerCase().includes("broker"),
      }
      const update: DealCRMContactUpdate<DealCRMFirmContact> = {
        updatedAt: new Date(),
        updatedBy: viewUserIdFields(user),
        data: updateData,
      }
      return {
        ...existing,
        updates: [update, ...existing.updates],
        ...updateData,
      }
    }
  }

export const addIndividualToFirm = (
  individual: DealCRMIndividualContact | DealCRMBrokerContact,
  firm: DealCRMFirmContact
): DealCRMFirmContact => {
  const firmHasPrimaryContact = firm.contacts.some((c) => c.isPrimaryContact)
  const newContacts: DealCRMFirmContact["contacts"] = firm.contacts.concat([
    {
      id: individual.id,
      name: individual.name,
      isPrimaryContact: !firmHasPrimaryContact,
      tag: individual.tag,
    },
  ])
  return { ...firm, contacts: newContacts, contactIds: newContacts.map((c) => c.id) }
}

export const getKnownFirms = async (
  db: FirebaseCommon.LimitedDB,
  accountId: string
): Promise<DealCRMFirmContact[]> =>
  db
    .collection(collections.dealCRM.contacts)
    .withConverter<DealCRMFirmContact>(firestoreConverter<DealCRMFirmContact>())
    .where("tag", "==", "firm" satisfies DealCRMContact["tag"])
    .where("accountId", "==", accountId satisfies DealCRMContact["accountId"])
    .get()
    .then((docs) => docs.docs.map((doc) => doc.data()).flatMap((doc) => (doc ? [doc] : [])))
//
