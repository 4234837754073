import moment from "moment-timezone"
import { justIfNonempty } from "../utils/data/Array/Nonempty"
import { maxDate, nDaysFrom } from "../utils/dateUtils"
import { Maybe, nullableToMaybe } from "../containers/Maybe"
import { AccountActivation } from "./Account/AccountActivation"
import { AccountDataPreferences, createAccountDataPreferences } from "./Account/DataPreferences"
import { AccountInvestmentPreferences } from "./AccountInvestmentPreferences"
import { AccountMetrics } from "./AccountMetrics"
import { AccountOrderQuotaFields } from "./AccountOrderQuotaFields"
import { AccountAccreditationFields } from "./Accreditation"
import { Permissions, ProductAreaState, ProductOnboardingState } from "./Auth/Permissions"
import { CompanyIdFields } from "./Company"
import { Document } from "./Document"
import { UserIdFields } from "./User"
import { AirtableAccount, airtableAccountSubscriptionType } from "./airtable/AirtableAccount"
import {
  DataContributionDocumentType,
  NonContributionPeriod,
  dataContributionDocumentTypes,
} from "./files/DocumentSubmission"
import { AccountClientType } from "./Account/AccountClientType"
import { AccountSubscriptionType } from "./Account/AccountSubscriptionType"
import { AccountRFQProfile } from "./Account/AccountRFQProfiles"
import { AccountCRMSettings, buildAccountCRMSettings } from "./AccountCRMSettings"
import { AccountSelfReportedClientProfile } from "./Account/AccountSelfReportedClientProfile"
import { AccountTradingRegistrationSummary } from "./Account/AccountTradingRegistration"
import { Schema } from "../schema/Schema"
import { AccountSelfReportedBrokerProfile } from "./Account/AccountSelfReportedBrokerProfile"
import { viewDefinedFields } from "../utils/viewDefinedFields"
import { AccountPrimaryUseCase } from "./Account/AccountPrimaryUseCase"
import { assertUnreachable } from "../utils/fp/Function"
import { AccessControlTier } from "./AccessControl/AccessControlTier"
import { AccountGlobalSizeFilterFields } from "./Account/AccountGlobalSizeFilter"

export type AccountId = string

export const firmTypeOptions = [
  "Venture Capital",
  "Hedge Fund",
  "Mutual Fund",
  "Family Office",
  "Bank",
  "Corporate Fund",
  "Endowment",
  "Growth Equity",
  "Insurance Co",
  "Pension",
  "Private Equity",
  "Secondary Fund (VC)",
  "Secondary Fund (PE)",
  "Sovereign Wealth Fund",
  "SPV Manager",
  "Broker",
  "Other",
] as const
export type FirmClassification = (typeof firmTypeOptions)[number]

export const DEFAULT_CAPLIGHT_DATA_TRIAL_DAYS = 14

export const accountIdFieldsSchema = Schema.object({
  id: Schema.string(),
  airtableId: Schema.string(),
  name: Schema.string(),
})
export interface AccountIdFields {
  id: AccountId
  airtableId: string
  name: string
}

export interface AccountPublicFields {
  name: Account["name"]
}

export type NewAccount = Omit<Account, "id">

export const viewAccountIdFields = (acc: AccountIdFields): AccountIdFields => ({
  id: acc.id,
  airtableId: acc.airtableId,
  name: acc.name,
})
export const viewAccountPublicFields = (acc: AccountPublicFields): AccountPublicFields => ({
  name: acc.name,
})

export const viewAccountIdAndAccessFields = (
  acc: Account
): AccountIdFields & AccountAccessFields => ({
  ...viewAccountIdFields(acc),
  productAreas: acc.productAreas,
  onboardingStatus: acc.onboardingStatus,
  clientType: acc.clientType,
  ...viewDefinedFields(acc, [
    "tradingRegistration",
    "selfReportedClientProfile",
    "selfReportedBrokerProfile",
    "creationSource",
    "canViewUnverifiedMarketPrice",
    "isOpportunityInboxEnabled",
    "isDealDistributionsEnabled",
    "isMockingDealDistribution",
    "accessControlTier",
  ]),
  crmPreferences: buildAccountCRMSettings(acc),
})

/**
 * Account metadata fields stored in our admin_all_accounts single-record collection
 */
export type AllAccountsCacheFields = AccountIdFields & AccountAccessFields & AccountTopBrokerFields

export const viewAllAccountsCacheFields = (acc: Account): AllAccountsCacheFields => ({
  ...viewAccountIdAndAccessFields(acc),
  isTopBroker: acc.isTopBroker,
})

export type AccountWatchlistFields = {
  watchlistSuggestions: {
    companies: Array<{ id: string; name: string; airtableId: string }>
    industries: Array<{ id: string; name: string }>
  }
}

export const accountCreationSources = ["caplight-invite", "user-signup"] as const
export type AccountCreationSource = (typeof accountCreationSources)[number]

export type AccountTierFields = {
  accessControlTier?: AccessControlTier
}

export type AccountAccessFields = Permissions &
  AccountTierFields & {
    onboardingStatus: ProductOnboardingState
    fullAccessGrantedAt?: Date
    clientType: AccountClientType[]
    selfReportedClientProfile?: AccountSelfReportedClientProfile
    selfReportedBrokerProfile?: AccountSelfReportedBrokerProfile
    subscriptionType?: AccountSubscriptionType
    creationSource?: AccountCreationSource
    canViewUnverifiedMarketPrice?: boolean
    delinquentContributor?: boolean
    autoLockoutAllowed?: boolean
    allRFQResultsEnabled?: boolean
    maxLiveDarkpoolIndicationCount?: number
    maxMonthlyInquiriesCount?: number
    offPlatformTermsOfServiceAccepted?: boolean
    crmPreferences?: AccountCRMSettings
    isOpportunityInboxEnabled?: boolean
    isDealDistributionsEnabled?: boolean
    isMockingDealDistribution?: boolean // control if we mock the distribution audience (true) or if we pull from airtable (falsey)
    hasMessageThreads?: boolean
    tradingRegistration?: AccountTradingRegistrationSummary
    // TODO: not 100% sure this is actually what we want
    firmClassification?: FirmClassification
    companyDataFileExport?: {
      enabled: boolean
      // for future implementation:
      // maxMonthlyDownloadsCount: number | null
    }
  }

export type AccountNDAId = {
  ndaId?: Document["id"]
}

export type AccountApiFields = {
  apiKeys?: {
    /** @deprecated Use widgetEmbed instead */
    marketPriceEmbed?: string
    v1APIKey?: string
    widgetEmbed?: string
  }
  apiLimits?: {
    requestLimit: number
    requestCount: number
  }
  allowedEmbedHostnames?: URL["hostname"][]
  apiVersion?: "full" | "lite"
  widgetVersion?: "full" | "market_price" | "compound"
}

export type AccountAffiliatedEmailDomains = {
  affiliatedEmailDomains?: string[]
}

export type AccountTopBrokerFields = {
  isTopBroker?: boolean
}
export type AccountLinkedBrokerFields = {
  caplightAsBroker?: boolean
  isBrokerLinkingEnabled?: boolean
}

export type AccountPrimaryUseCaseFields = {
  primaryUseCase?: AccountPrimaryUseCase | null
}

export type AccountPrimaryContactFields = {
  primaryContact?: {
    name: string
    phoneNumber: string
  } | null
}

/* eslint-disable prettier/prettier */
export type Account = AccountIdFields &
  AccountAccessFields &
  AccountWatchlistFields &
  AccountInvestmentPreferences &
  AccountDataFields & {
    rfqProfile?: AccountRFQProfile
    selfReportedClientProfile?: AccountSelfReportedClientProfile
    selfReportedIndividualType?: Exclude<AccountSelfReportedClientProfile, "broker" | "fund">
    hidden_orders: Array<string>
    createdAt: Date
    opportunityInboxForwardingEmail?: string
  } & AccountNDAId &
  AccountApiFields &
  AccountAffiliatedEmailDomains &
  AccountAccreditationFields &
  AccountMetrics &
  AccountOrderQuotaFields &
  AccountTopBrokerFields &
  AccountLinkedBrokerFields &
  AccountActivation &
  AccountPrimaryContactFields &
  AccountPrimaryUseCaseFields &
  AccountGlobalSizeFilterFields

type DefaultableAccountFields =
  | "hidden_orders"
  | "watchlistSuggestions"
  | "lastDataContributions"
  | "dataContributors"
  | "noDataProductSubmissionPeriods"
  | "clientType"
  | "createdAt"

type NestedDefaultableAccountFields = "dataPreferences"

type MinimalAccount = Omit<
  Account,
  "id" | DefaultableAccountFields | NestedDefaultableAccountFields
> & { dataPreferences: Partial<AccountDataPreferences> } // there must be a better way to handle nested defaults

export const accountDefaults: { [key in DefaultableAccountFields]: NonNullable<Account[key]> } = {
  hidden_orders: [],
  watchlistSuggestions: { companies: [], industries: [] },
  lastDataContributions: {},
  dataContributors: [],
  noDataProductSubmissionPeriods: {
    "data-submission": [],
    "order-submission": [],
  },
  clientType: [],
  createdAt: new Date(),
}

export const buildAccount = <R = {}>(account: MinimalAccount & R): Omit<Account, "id"> & R => ({
  ...accountDefaults,
  ...account,
  ...{ dataPreferences: createAccountDataPreferences(account.dataPreferences) },
  ...{ crmPreferences: buildAccountCRMSettings({ ...accountDefaults, ...account }) },
})

export type DataContributionFrequency = "Monthly" | "Weekly" | "None"

export const accountDataContributionFrequency = (acc: Account) =>
  acc.dataContributionFrequency || "Weekly"

export interface AccountDataFields extends AccountIdFields {
  dataPreferences: AccountDataPreferences
  lastDataContributions: { [companyId in string]?: Date }
  dataContributors?: UserIdFields[]
  noHistoricalOrdersToSubmit?: boolean
  noDataProductSubmissionPeriods: {
    [k in DataContributionDocumentType]: NonContributionPeriod[]
  }
  dataContributionFrequency?: DataContributionFrequency
  lastUrgentDataContributionEmailSentAt?: Date
  lastSemiUrgentDataContributionEmailSentAt?: Date
}

export const lastNoDataDateByDocType = (
  account: Pick<Account, "noDataProductSubmissionPeriods">,
  docType: DataContributionDocumentType
): Maybe<Date> =>
  maxDate(account.noDataProductSubmissionPeriods[docType].map((timespan) => timespan.upperBound))

export const lastNoDataToReportDate = (account: Account): Maybe<Date> =>
  maxDate(
    dataContributionDocumentTypes.flatMap((docType) =>
      (account.noDataProductSubmissionPeriods[docType] ?? []).map((timespan) => timespan.upperBound)
    )
  )

export const updatedAccountDataFieldsAfterContribution = (
  a: AccountDataFields,
  companyId: string
): AccountDataFields => ({
  ...a,
  lastDataContributions: { ...a.lastDataContributions, [companyId]: new Date() },
})

export const viewAccountDataFields = (acc: AccountDataFields): AccountDataFields => ({
  id: acc.id,
  airtableId: acc.airtableId,
  name: acc.name,
  dataPreferences: acc.dataPreferences,
  lastDataContributions: acc.lastDataContributions,
  dataContributors: acc.dataContributors,
  noDataProductSubmissionPeriods: acc.noDataProductSubmissionPeriods,
})

export const isFullyOnboardedDataCustomer = (account: AccountAccessFields) => {
  const accountProductAreas = account.productAreas || []
  return (
    accountProductAreas.includes("data") && account.onboardingStatus?.data?.status === "complete"
  )
}

export const isFullyOnboardedMarketsCustomer = (account: AccountAccessFields) => {
  const accountProductAreas = account.productAreas || []
  return (
    accountProductAreas.includes("markets") &&
    account.onboardingStatus?.markets?.status === "complete"
  )
}

export const trialExpirationDate = (account: AccountAccessFields) =>
  nullableToMaybe(
    account.onboardingStatus?.data?.status === "trial-expired" ||
      account.onboardingStatus?.data?.status === "trial"
      ? account.onboardingStatus?.data?.trialExpiration
      : null
  )
export const isTrialExpired = (account: AccountAccessFields) =>
  trialExpirationDate(account).match(
    (date) => date <= new Date(),
    () => false
  )
export const isActiveTrialCustomer = (account: AccountAccessFields) =>
  account.onboardingStatus?.data?.status === "trial"
export const isTrialCustomer = (account: AccountAccessFields): boolean =>
  isActiveTrialCustomer(account) || account.onboardingStatus?.data?.status === "trial-expired"

export const isAccountUserDataContributor = (
  { dataContributors }: AccountDataFields,
  { id: userId }: UserIdFields
) =>
  nullableToMaybe(dataContributors)
    .bind(justIfNonempty)
    .match(
      (xs) => xs.some(({ id }) => id === userId),
      () => true // if dataContributors doesn't exist or is empty, all the account's users are contributors
    )

//TODO: change params to object
export const newAccountFromAirtableAccount = (
  airtableAccount: AirtableAccount,
  companyWatchlistSuggestions?: CompanyIdFields[],
  emailDomain?: string,
  accessTier?: AccessControlTier
): NewAccount => {
  const dataOnboardingStatus: ProductAreaState["status"] | undefined =
    airtableAccount["Data onboarding status"]
  const accountOnboardingStatus: ProductOnboardingState =
    dataOnboardingStatus === undefined
      ? {}
      : {
          data:
            dataOnboardingStatus === "trial"
              ? {
                  status: "trial",
                  trialExpiration: nullableToMaybe(
                    airtableAccount["Data trial expiration date"]
                  ).match(
                    (d) => moment.tz(d, "America/Los_Angeles").startOf("day").toDate(),
                    () => nDaysFrom(DEFAULT_CAPLIGHT_DATA_TRIAL_DAYS)
                  ),
                }
              : dataOnboardingStatus === "trial-expired"
              ? { status: "trial-expired", trialExpiration: new Date() }
              : { status: dataOnboardingStatus },
        }

  return buildAccount({
    dataPreferences: createAccountDataPreferences({}),
    airtableId: airtableAccount.id,
    productAreas: airtableAccount["Product Areas"] || [],
    name: airtableAccount["Fund Name"],
    clientType: airtableAccount["Client Type"] || [],
    watchlistSuggestions: {
      companies: companyWatchlistSuggestions || [],
      industries: [],
    },
    onboardingStatus: accountOnboardingStatus,
    fullPlatformAccessGrantedAt:
      accountOnboardingStatus.data?.status === "complete" ? new Date() : undefined,
    subscriptionType: airtableAccountSubscriptionType(airtableAccount),
    createdAt: new Date(),
    isTopBroker: airtableAccount["Is Top Broker"] || false,
    isBrokerLinkingEnabled: airtableAccount["Is Broker Linking Enabled"] || false,
    isOpportunityInboxEnabled: airtableAccount["Is Opportunity Inbox Enabled"] || false,
    opportunityInboxForwardingEmail: airtableAccount["Opportunity Inbox Forwarding Email"],
    rfqProfile: airtableAccount["RFQ Profile"],
    isDealDistributionsEnabled: airtableAccount["Is Deal Distributions Enabled"] || false,
    affiliatedEmailDomains: emailDomain ? [emailDomain] : [],
    creationSource: airtableAccount["Is Self Signup Account"] ? "user-signup" : "caplight-invite",
    ...(accessTier && { accessControlTier: accessTier }),
  })
}

export const isIntermediaryAccount = (account: Pick<Account, "clientType">): boolean =>
  account.clientType.includes("Intermediary")

export const isInvestorShareholderAccount = (account: Pick<Account, "clientType">): boolean =>
  account.clientType.includes("Investor/Shareholder")

export const isOpportunityInboxAccount = (
  account: Pick<Account, "isOpportunityInboxEnabled">
): account is { isOpportunityInboxEnabled: true } => account.isOpportunityInboxEnabled ?? false

export const isSelfServiceAccount = (account: Pick<Account, "creationSource">): boolean =>
  account.creationSource === "user-signup"

export const isIndividualAccount = ({
  selfReportedBrokerProfile,
  selfReportedClientProfile,
}: Pick<Account, "selfReportedClientProfile" | "selfReportedBrokerProfile">): boolean | null => {
  if (!selfReportedClientProfile) {
    return null
  }
  switch (selfReportedClientProfile) {
    case "broker": {
      if (!selfReportedBrokerProfile) {
        return null
      }
      switch (selfReportedBrokerProfile) {
        case "broker-united-states-individual": {
          return true
        }
        case "broker-international": {
          return false
        }
        case "broker-united-states-org": {
          return false
        }
        default: {
          return assertUnreachable(selfReportedBrokerProfile)
        }
      }
    }
    case "individual-investor": {
      return true
    }
    case "employee": {
      return true
    }
    case "fund": {
      return false
    }
    default: {
      return assertUnreachable(selfReportedClientProfile)
    }
  }
}

export const isGroupAccount = (
  account: Pick<Account, "selfReportedClientProfile" | "selfReportedBrokerProfile">
): boolean | null => (isIndividualAccount(account) === null ? null : !isIndividualAccount(account))
