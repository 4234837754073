import { Account, AccountIdFields, viewAccountIdFields } from "../Account"
import { SubmittedDocument } from "../files/DocumentSubmission"
import { emailPatternString } from "../../utils/StringUtils"
import { assertUnreachable } from "../../utils/fp/Function"
import { UserIdFields } from "../User"
import { AccountSelfReportedClientProfile } from "./AccountSelfReportedClientProfile"

export const TRADING_REGISTRATION_SEARCH_KEY = "k"

export type AccountTradingRegistrationSummary = {
  isNonTransactional?: boolean
  status: ReturnType<typeof getAccountTradingRegistrationStatusString>
  approvedDate: Date | null
  approvedBy: "migration" | "admin"
}

type BaseAccountTradingRegistration = {
  id: string
  accountId: string
  account: Exclude<AccountIdFields, Account>
  createdAt: Date
  updatedAt: Date
  status: {
    isNonTransactional?: boolean
    isNonTransactionalDate?: Date | null
    approved: boolean
    approvedDate?: Date | null
    approvedBy?: UserIdFields
    submittedIdentification?: boolean
    submittedIdentificationDate?: Date
    signedAgreement?: boolean
    signedAgreementDate?: Date
    offlineAgreementRequested?: boolean
    offlineAgreementRequestedDate?: Date
  }
  verificationDocuments?: SubmittedDocument[]
}

export const getAccountTradingRegistrationStatusString = ({
  status,
  verificationDocuments,
}: BaseAccountTradingRegistration) => {
  if (status.approved) {
    return "Approved"
  }
  if (status.signedAgreement) {
    return "Subscriber Agreement Accepted" // deprecated
  }
  if (status.offlineAgreementRequested) {
    return "Offline Agreement Requested" // deprecated
  }
  if (verificationDocuments && verificationDocuments.length > 0) {
    return "Documents Submitted"
  }
  if (status.submittedIdentification) {
    return "Identification Information Submitted"
  }
  return "Not Started"
}

export const isTradingRegistrationOnLastStep = (
  account?: Pick<Account, "tradingRegistration" | "selfReportedClientProfile">
) =>
  account?.tradingRegistration?.status === "Subscriber Agreement Accepted" ||
  account?.tradingRegistration?.status === "Offline Agreement Requested" ||
  account?.tradingRegistration?.status === "Documents Submitted" ||
  (account?.tradingRegistration?.status === "Identification Information Submitted" &&
    account?.selfReportedClientProfile === "broker")

type SharedAccountTradingRegistrationData = {
  phoneNumber: string
  entityName: string
}

export type IndividualAccountTradingRegistration = BaseAccountTradingRegistration &
  SharedAccountTradingRegistrationData & {
    type: "individual-investor"
    address: string
    dateOfBirth: Date
    ssn: string
    country?: string
  }

export const defaultAccountTradingRegistration = (
  id: string,
  account: AccountTradingRegistration["account"],
  type: AccountTradingRegistration["type"]
): MinimumAccountTradingRegistration => ({
  id,
  accountId: account.id,
  account: viewAccountIdFields(account),
  createdAt: new Date(),
  updatedAt: new Date(),
  status: {
    approved: false,
  },
  type,
})

export const numberOfSeatOptions = ["1", "2-5", "6-10", "11-50", "51+"] as const
export type FundAccountTradingRegistration = BaseAccountTradingRegistration &
  SharedAccountTradingRegistrationData & {
    type: "fund"
    fundName: string
    fundType: "hedge" | "mutual" | "private-equity" | "venture-capital" // TODO CONFIRM
    fundAddress: string
    fundCountry: string
    fundState: string
    numberOfSeats: (typeof numberOfSeatOptions)[number]
  }

export type BrokerAccountTradingRegistration = BaseAccountTradingRegistration &
  SharedAccountTradingRegistrationData & {
    type: "broker"
    brokerDealerName: string
    brokerDealerContactName: string
    brokerDealerContactEmail: string
  }

export type AccountTradingRegistration =
  | IndividualAccountTradingRegistration
  | FundAccountTradingRegistration
  | BrokerAccountTradingRegistration

export type MinimumAccountTradingRegistration = BaseAccountTradingRegistration & {
  type: Exclude<AccountSelfReportedClientProfile, "employee">
}

const COUNTRIES_WITH_STATES_OR_PROVINCES = [
  "US",
  "USA",
  "United States",
  "United States of America",
  "Canada",
]
const doesCountryHaveStatesOrProvinces = (country: string) =>
  COUNTRIES_WITH_STATES_OR_PROVINCES.map((c) => c.toLowerCase()).includes(
    country.toLocaleLowerCase()
  )
const validateName = (name: string | undefined) =>
  !!name && name.split(" ").length > 1 && !name.split(" ").some((part) => part.length < 2)
const validateEmail = (email: string | undefined) => !!email && emailPatternString.test(email)
const validateState = (t: Partial<AccountTradingRegistration>) =>
  t.type === "fund" && t.fundCountry && doesCountryHaveStatesOrProvinces(t.fundCountry)
    ? !!t.fundState
    : true
export const validateAccountTradingRegistration = (
  t: Partial<AccountTradingRegistration> & MinimumAccountTradingRegistration
) => ({
  // phoneNumber: validatePhoneNumber(t.phoneNumber) ? undefined : "Required",
  phoneNumber: t.phoneNumber && t.phoneNumber.length > 5 ? undefined : "Required",
  entityName: validateName(t.entityName) ? undefined : "Required",
  ...(t.type === "broker"
    ? {
        brokerDealerName: t.brokerDealerName ? undefined : "Required",
        brokerDealerContactName: validateName(t.brokerDealerContactName) ? undefined : "Required",
        brokerDealerContactEmail: validateEmail(t.brokerDealerContactEmail)
          ? undefined
          : "Invalid Email",
      }
    : t.type === "fund"
    ? {
        fundName: t.fundName ? undefined : "Required",
        fundAddress: t.fundAddress ? undefined : "Required",
        fundCountry: t.fundCountry ? undefined : "Required",
        fundState: validateState(t) ? undefined : "Required",
        numberOfSeats: t.numberOfSeats ? undefined : "Required",
      }
    : t.type === "individual-investor"
    ? {
        address: t.address ? undefined : "Required",
        dateOfBirth: t.dateOfBirth ? undefined : "Required",
        ssn: t.ssn ? undefined : "Required",
      }
    : assertUnreachable(t)),
})

export const getAccountTradingRegistrationLink = (platformUrl: string, account: AccountIdFields) =>
  `${platformUrl}/admin/accounts?tradingRegistration=${account.id}`
