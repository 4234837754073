import InfoTooltip from "@components/InfoTooltip"
import SkeletonLoader from "@components/icons/SkeletonLoader"
import HorizontalScrollContainer from "@stories/components/HorizontalScrollContainer/HorizontalScrollContainer"
import { Color, Typography, Weight } from "@stories/components/Typography/Typography"
import { Account, isIntermediaryAccount, isInvestorShareholderAccount } from "common/model/Account"
import { CompanyIdFields, viewCompanyIdFields } from "common/model/Company"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import {
  DealCRMInterest,
  DealCRMInterestWithOrigin,
  buildDealCRMInterest,
  isInterestCancelled,
} from "common/model/DealCRM/DealCRMInterest"
import { viewOrderSourceAccountFields } from "common/model/Order/OrderSource"
import { viewUserIdFields } from "common/model/User"
import { Loading, isLoaded } from "common/utils/Loading"
import { useState } from "react"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import { useCRMContacts } from "src/pages/CRM/Providers/CRMContactsProvider"
import { userCanCreateOrderFromCRM } from "src/pages/Orders/shared/permissions"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import { handleConsoleError, trackEventInFirestoreAndHeap } from "src/utils/Tracking"
import { AddCompanyRow } from "./AddCompanyRow"
import { InterestRow } from "./InterestRow"

type InterestHeader =
  | "Company"
  | "Source"
  | "Notes"
  | "Stage"
  | "Price Target"
  | "Target Val."
  | "Amount ($)"
  | "Commission %"
  | "Total Fee"
  | "Mgmt Fee Structure"
  | "Mgmt Fee %"
  | "Carry %"
  | "Structure"
  | "Share Class"
  | "Updated"
  | "Notes"
  | "Visibility Status"
  | "Order Origination Date"
  | ""

interface InterestTableProps {
  side: "buy" | "sell"
  contactInterest: Loading<DealCRMInterestWithOrigin[]>
  contact: DealCRMContact
  addInterest: (interest: DealCRMInterest) => Promise<unknown>
  account: Account
}

export const InterestTable = (props: InterestTableProps) => {
  if (isLoaded(props.contactInterest)) {
    return <InterestTableInner {...props} loadedContactInterest={props.contactInterest} />
  } else {
    return <SkeletonLoader numRows={2} />
  }
}

const InterestTableInner = ({
  contact,
  addInterest,
  side,
  account,
  loadedContactInterest,
}: InterestTableProps & {
  loadedContactInterest: DealCRMInterestWithOrigin[]
}) => {
  const directionalContactInterest = loadedContactInterest.filter(
    (interest) => interest.direction === side
  )
  const { findContactById } = useCRMContacts()
  const user = useCurrentUser()
  const headers: InterestHeader[] = (
    [
      "Company",
      "Source",
      "Notes",
      isLoaded(user) && userCanCreateOrderFromCRM(user) ? "Visibility Status" : null,
      "Stage",
      "Price Target",
      "Target Val.",
      "Amount ($)",
      isIntermediaryAccount(account) ? "Commission %" : null,
      isIntermediaryAccount(account) ? "Total Fee" : null,
      side === "buy" && isInvestorShareholderAccount(account) ? "Mgmt Fee Structure" : null,
      side === "buy" && isInvestorShareholderAccount(account) ? "Mgmt Fee %" : null,
      side === "buy" && isInvestorShareholderAccount(account) ? "Carry %" : null,
      "Structure",
      "Share Class",
      "Updated",
      "Order Origination Date",
      "",
    ] satisfies Array<InterestHeader | null>
  ).filter((h): h is InterestHeader => h !== null)

  // Only show Source column if the contact is individual or firm
  if (contact.tag !== "individual" && contact.tag !== "firm") {
    headers.splice(
      headers.findIndex((v) => v === "Source"),
      1
    )
  }

  const sideTitle = side === "buy" ? "Buy" : "Sell"
  const firebase9 = useFirebase9()
  const [addedInterestIds, setAddedInterestIds] = useState<string[]>([]) // ids of newly created interests to sort them to the bottom

  const handleAddInterest = (company: CompanyIdFields) => {
    if (isLoaded(account) && isLoaded(user)) {
      const creationSourceContact = (() => {
        if (contact.tag === "individual" && contact.firm?.id) {
          return findContactById(contact.firm.id) ?? contact
        }
        return contact
      })()

      const newInterest = buildDealCRMInterest({
        company: viewCompanyIdFields(company),
        direction: side,
        contact: creationSourceContact,
        account,
        source: {
          sourceType: "user-form",
          submittingUser: viewUserIdFields(user.user),
          sourceId: null,
          documentUpload: null,
          account: viewOrderSourceAccountFields(account),
        },
      })

      setAddedInterestIds([...addedInterestIds, newInterest.id])
      addInterest(newInterest)
        .then(() =>
          trackEventInFirestoreAndHeap(
            firebase9,
            user.user,
            "crm-contact-indication-of-interest-added",
            {
              direction: side,
              // Omit company for now due to sensitivity
            }
          )
        )
        .catch(handleConsoleError)
    }
  }

  const sortedInterest = directionalContactInterest.sort((a, b) => {
    if (addedInterestIds.includes(a.id) && addedInterestIds.includes(b.id)) {
      return addedInterestIds.indexOf(a.id) - addedInterestIds.indexOf(b.id)
    }
    if (addedInterestIds.includes(a.id)) {
      return 1
    }
    if (addedInterestIds.includes(b.id)) {
      return -1
    }
    if (isInterestCancelled(a) && isInterestCancelled(b)) {
      return a.lastUpdatedAt.getTime() - b.lastUpdatedAt.getTime()
    }
    if (isInterestCancelled(a)) {
      return 1
    }
    if (isInterestCancelled(b)) {
      return -1
    }
    return a.company.name.localeCompare(b.company.name)
  })

  const getSectionTitle = () => {
    const prefix =
      contact.tag === "broker" || (contact.tag === "firm" && contact.isBrokerage) ? "Client" : ""
    return `${prefix} ${sideTitle} Interest`
  }

  return (
    <div className="flex flex-col gap-2 relative">
      <Typography color={Color.Primary} weight={Weight.Semibold} text={getSectionTitle()} />
      <HorizontalScrollContainer className="w-full border rounded">
        <table className="table-auto w-full">
          <thead className="bg-neutral-300">
            <tr className="h-8">
              {headers.map((header) => (
                <th className="whitespace-nowrap px-2 text-left text-xs font-semibold" key={header}>
                  <div className="flex gap-0 items-center">
                    <span>{header}</span>
                    {header === "Total Fee" ? (
                      <InfoTooltip text="Calculated as Amount × Commission %" />
                    ) : null}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedInterest.map((transactionalInterest) => (
              <InterestRow
                key={transactionalInterest.id}
                interest={transactionalInterest}
                contact={contact}
                side={side}
              />
            ))}
          </tbody>
        </table>
        <div className="flex gap-4">
          <AddCompanyRow
            buttonLabel={`Add ${sideTitle} Interest`}
            onAddCompany={handleAddInterest}
          />
        </div>
      </HorizontalScrollContainer>
    </div>
  )
}
