import { collections } from "common/firestore/Collections"
import { viewAccountIdFields } from "common/model/Account"
import { EventCounter, eventCounterPeriods } from "common/model/Analytics/EventCounter"
import { User, viewUserIdFields } from "common/model/User"
import { UserEventType } from "common/model/UserEvent"
import { ISODateFormat } from "common/utils/dateUtils"
import {
  DocumentReference,
  collection,
  doc,
  getDoc,
  increment,
  setDoc,
  updateDoc,
} from "firebase/firestore"
import moment, { Moment } from "moment-timezone"
import Firebase9 from "../../firebase/Firebase9"
import { handleConsoleError } from "../Tracking"

/**
 * This class is used to track user/account event counts in Firestore.
 * We track event counts by day, week, and month, with the start of each time period as the stored date.
 * It uses a cache to avoid unnecessary reads to Firestore.
 *
 * You should NOT use this class directly.
 * See src/utils/EventTracking/EventCounterService.ts for usage.
 */
export class FirestoreEventCounter {
  private firebase: Firebase9

  private subject: "account" | "user"

  private user: User

  private counterCacheStartOfDayDate: Moment

  constructor(firebase: Firebase9, user: User, subject: "account" | "user") {
    this.firebase = firebase
    this.user = user
    this.counterCacheStartOfDayDate = moment().tz("America/Los_Angeles").startOf("day")
    this.subject = subject
  }

  private get counterCacheStartOfWeekDate() {
    return moment(this.counterCacheStartOfDayDate).startOf("week")
  }

  private get counterCacheStartOfMonthDate() {
    return moment(this.counterCacheStartOfDayDate).startOf("month")
  }

  private eventCounterDocCache: Record<EventCounter["period"], DocumentReference> | null = null

  private createEventCountDoc = async (
    period: EventCounter["period"],
    periodDate: Moment
  ): Promise<DocumentReference> => {
    const userFields = this.subject === "account" ? {} : { user: viewUserIdFields(this.user) }
    const eventCountDoc: Omit<EventCounter, "id"> = {
      ...userFields,
      account: viewAccountIdFields(this.user.account),
      period,
      periodDate: periodDate.toDate(),
      eventCounts: {},
    }

    const docRef = doc(this.collectionRef, this.getDocId(period, periodDate))
    await setDoc(docRef, eventCountDoc)
    return docRef
  }

  // e.g. account-123-day-2021-08-01 or user-123-day-2021-08-01
  private getDocId = (period: EventCounter["period"], date: Moment) => {
    const id = this.subject === "account" ? this.user.account.id : this.user.id
    return `${this.subject}-${id}-${period}-${ISODateFormat(date)}`
  }

  private getEventCounterDocs = async () => {
    if (!this.eventCounterDocCache) {
      const [day, week, month] = await Promise.all(
        eventCounterPeriods.map((period) =>
          this.getOrCreateDoc({
            period,
            periodDate: this.periodDate(period),
          })
        )
      )
      const newDocs = {
        day,
        week,
        month,
      }
      this.eventCounterDocCache = newDocs
      return newDocs
    } else {
      return this.eventCounterDocCache
    }
  }

  private get collectionRef() {
    return collection(
      this.firebase.db,
      this.subject === "account" ? collections.accountEventCounts : collections.userEventCounts
    )
  }

  private periodDate = (period: EventCounter["period"]) =>
    period === "day"
      ? this.counterCacheStartOfDayDate
      : period === "week"
      ? this.counterCacheStartOfWeekDate
      : this.counterCacheStartOfMonthDate

  private getOrCreateDoc = (data: { period: EventCounter["period"]; periodDate: Moment }) =>
    getDoc(doc(this.collectionRef, this.getDocId(data.period, data.periodDate)))
      .then((result) => (result.exists() ? result : undefined))
      .then((document) =>
        document ? document.ref : this.createEventCountDoc(data.period, data.periodDate)
      )
      .catch((err) => this.createEventCountDoc(data.period, data.periodDate))

  async incrementEventCount(event: UserEventType) {
    const date = moment().tz("America/Los_Angeles")
    const startOfDay = date.startOf("day")
    if (!this.counterCacheStartOfDayDate || startOfDay.isAfter(this.counterCacheStartOfDayDate)) {
      this.counterCacheStartOfDayDate = startOfDay
      this.eventCounterDocCache = null
    }

    const eventCounterDocRefs = await this.getEventCounterDocs()

    updateDoc(eventCounterDocRefs.day, `eventCounts.${event}`, increment(1)).catch(
      handleConsoleError
    )
    updateDoc(eventCounterDocRefs.week, `eventCounts.${event}`, increment(1)).catch(
      handleConsoleError
    )
    updateDoc(eventCounterDocRefs.month, `eventCounts.${event}`, increment(1)).catch(
      handleConsoleError
    )
  }
}
