import { OrderInquiryUserForm } from "./Db"

const optionalUserFormKeys = [
  "additionalQuestions",
] as const satisfies readonly (keyof OrderInquiryUserForm["userForm"])[]
type OptionalUserFormKey = (typeof optionalUserFormKeys)[number]

const defaultUserFormValues: {
  [Key in OptionalUserFormKey]: OrderInquiryUserForm["userForm"][Key]
} = {
  additionalQuestions: null,
}

export type OrderInquiryUserFormState = Partial<OrderInquiryUserForm["userForm"]>

export const createOrderInquiryUserForm = (
  formState: OrderInquiryUserFormState
): OrderInquiryUserForm => ({
  userForm: { ...defaultUserFormValues, ...formState },
})
