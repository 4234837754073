import { BooleanSelect } from "@components/lib/Select/BooleanSelect"
import SectionLabel from "@stories/components/Inputs/SectionLabel/SectionLabel"
import { PriceObservationType } from "common/model/data-product/pricing/PriceObservation"
import { AnimatePresence, motion } from "framer-motion"

type ClosedTradeROFRSectionInnerData = Partial<Pick<PriceObservationType, "rofr">>
export const ClosedTradeROFRSectionInner = ({
  value,
  onChange,
  title,
  titles,
}: {
  value: ClosedTradeROFRSectionInnerData
  onChange: (value: ClosedTradeROFRSectionInnerData) => void
  title: string
  titles: {
    subjectToROFR: string
    wasROFRd: string
  }
}) => (
  <>
    <SectionLabel>{title}</SectionLabel>
    <BooleanSelect
      label={titles.subjectToROFR}
      onChange={(subjectToROFR) => onChange({ ...value, rofr: { wasROFRd: null, subjectToROFR } })}
      value={value.rofr?.subjectToROFR}
    />
    {value.rofr?.subjectToROFR ? (
      <BooleanSelect
        label={titles.wasROFRd}
        onChange={(wasROFRd) =>
          onChange({ ...value, rofr: { subjectToROFR: null, ...value.rofr, wasROFRd } })
        }
        value={value.rofr?.wasROFRd}
      />
    ) : null}
  </>
)

type ClosedTradeROFRSectionData = Partial<Pick<PriceObservationType, "rofr" | "structure">>

export const ClosedTradeROFRSection = ({
  value,
  onChange,
  title,
  titles,
}: {
  value: ClosedTradeROFRSectionData
  onChange: (value: ClosedTradeROFRSectionInnerData) => void
  title: string
  titles: {
    subjectToROFR: string
    wasROFRd: string
  }
}) => (
  <>
    {value.structure?.entries.find((v) => v.key === "direct") ? (
      <AnimatePresence>
        <motion.div
          initial={{ x: 10, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.4 }}
        >
          <ClosedTradeROFRSectionInner
            value={value}
            onChange={onChange}
            title={title}
            titles={titles}
          />
        </motion.div>
      </AnimatePresence>
    ) : null}
  </>
)
