import { orderVolumeForQuotaCompliance } from "./checkAccountQuotaCompliance"
import { ComplianceContextData } from "./runQuotaComplianceChecks"

export type AccountTransactionSizeThresholdOrderQuotaComplianceViolations = {
  tag: "ACCOUNT_TRANSACTION_SIZE_THRESHOLD"
  data: {
    indicationVolume: number
    minimumOrderSize: number
  }
}

export const accountTransactionSizeThresholdQuotaComplianceCheck = (
  context: ComplianceContextData
): AccountTransactionSizeThresholdOrderQuotaComplianceViolations | undefined => {
  const minimumOrderSize = context.account.accountOrderQuotaRequirements?.minimumOrderSize
  const indicationVolume = orderVolumeForQuotaCompliance(context.orderQuantity)
  if (!indicationVolume || !minimumOrderSize || indicationVolume >= minimumOrderSize) {
    return undefined
  }

  return {
    data: {
      indicationVolume,
      minimumOrderSize,
    },
    tag: "ACCOUNT_TRANSACTION_SIZE_THRESHOLD",
  }
}
