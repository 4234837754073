import { LatestTermsOfServiceVersion } from "common/compliance/TermsOfServiceVersion"
import { UserAuthContext } from "../../Routes/auth/UserAuthContext"

type UserTermsOfServiceStatus =
  | "loading"
  | "never-accepted"
  | "accepted-latest-terms"
  | "accepted-previous-terms"

export const getUserTermsOfServiceStatus = (
  user: UserAuthContext["user"],
  account: UserAuthContext["account"]
): UserTermsOfServiceStatus => {
  if (user === "loading" || !user) return "loading"
  if (account === "loading" || !account) return "loading"
  if (account.offPlatformTermsOfServiceAccepted) return "accepted-latest-terms"
  const latestTermsVersionAccepted =
    user.user.termsOfServiceAcceptance?.latestTermsOfServiceVersionAccepted

  if (!latestTermsVersionAccepted) return "never-accepted"
  else if (latestTermsVersionAccepted !== LatestTermsOfServiceVersion)
    return "accepted-previous-terms"
  else return "accepted-latest-terms"
}

export const hasUserSignedLatestTermsOfService = (
  user: UserAuthContext["user"],
  account: UserAuthContext["account"]
) => getUserTermsOfServiceStatus(user, account) === "accepted-latest-terms"
