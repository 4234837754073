import { Button } from "@stories/components/Button/Button"
import {
  AccountTradingRegistration,
  MinimumAccountTradingRegistration,
} from "common/model/Account/AccountTradingRegistration"
import { Loading, isLoaded } from "common/utils/Loading"
import { Form } from "react-final-form"
import { TradingRegistrationForm } from "src/pages/TradeRegistration/TradingRegistrationForms/TradingRegistrationForm"
import { AdminTradingRegistrationTypeSelection } from "./AdminTradingRegistrationTypeSelection"
import Typography, { Size, Weight } from "@stories/components/Typography/Typography"

export const AdminTradingRegistrationForm = ({
  tradingRegistration,
  updateTradingRegistration,
}: {
  updateTradingRegistration: (
    t: Partial<AccountTradingRegistration> & MinimumAccountTradingRegistration
  ) => Promise<void>
  tradingRegistration: Loading<
    Partial<AccountTradingRegistration> & MinimumAccountTradingRegistration
  >
}) => (
  <>
    <AdminTradingRegistrationTypeSelection
      updateTradingRegistration={updateTradingRegistration}
      tradingRegistration={tradingRegistration}
    />
    {isLoaded(tradingRegistration) ? (
      <>
        <Typography
          text={`Trading Registration Type: ${tradingRegistration.type}`}
          size={Size.XSmall}
          weight={Weight.Semibold}
        />
        <Form<AccountTradingRegistration>
          initialValues={tradingRegistration}
          onSubmit={updateTradingRegistration}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="flex flex-col gap-4 h-full">
              <TradingRegistrationForm tradingRegistration={tradingRegistration} />
              <Button type="submit" label="Update Account Trading Registration Info" />
            </form>
          )}
        />
      </>
    ) : null}
  </>
)
