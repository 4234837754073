import CaplightConnectIcon from "@stories/icons/ConnectIcon"
import Divider from "@stories/components/Divider/Divider"

export const CaplightConnectDivider = () => (
  <div className="my-5">
    <Divider
      label={
        <div className="transform rotate-90">
          <CaplightConnectIcon />
        </div>
      }
    />
  </div>
)
