import { collections } from "common/firestore/Collections"
import { RFQResponse } from "common/model/RFQ/RFQ"
import { Loading } from "common/utils/Loading"
import { collection, onSnapshot, query, where } from "firebase/firestore"
import { useEffect, useState } from "react"
import { oneOrNone } from "../../firebase/Firebase9"
import { useFirebase9 } from "../../firebase/Firebase9Context"
import { firestoreConverter } from "../../model/FirestoreConverter"
import { handleConsoleError } from "../../utils/Tracking"
import { useLoadingState } from "../../utils/useLoadingState"

type FirestoreError = firebase.default.firestore.FirestoreError

// TODO: duplicated  code w/ useQuery
export const useAccountRFQResponse = (
  rfqId?: string,
  accountId?: string
): [Loading<RFQResponse>, FirestoreError | null] => {
  const firebase9 = useFirebase9()

  const [doc, setDoc] = useLoadingState<RFQResponse>()

  const [error, setError] = useState<null | FirestoreError>(null)

  if (error) {
    handleConsoleError("error", error)
  }

  useEffect(() => {
    if (rfqId && accountId) {
      const q = query(
        collection(firebase9.db, collections.rfqs, rfqId, collections.rfqSubcollections.responses),
        where("account.id", "==", accountId)
      ).withConverter(firestoreConverter<RFQResponse>())
      const cleanup = onSnapshot(
        q,
        (next) => {
          setDoc(oneOrNone(next) || null)
        },
        setError
      )
      return cleanup
    }
    return () => {}
  }, [rfqId, accountId, firebase9.db, setDoc])

  return [doc, error]
}
