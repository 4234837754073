import React, { ReactNode } from "react"
import { FooterWithClearbit } from "../layout/LayoutFooter"

interface PageLayoutProps {
  right?: ReactNode
  className?: string
  hideFooter?: boolean
  minHeightScreen?: boolean
}

export const PageLayout: React.FC<React.PropsWithChildren<PageLayoutProps>> = ({
  right,
  children,
  className,
  hideFooter = false,
  minHeightScreen = false,
}) => (
  <main
    className={`${className !== undefined ? className : ""} flex px-4 animate-fade w-full ${
      minHeightScreen ? "min-h-screen" : ""
    }`}
    id="page-layout-div"
  >
    <div className="no-scrollbar w-full lg:w-9/12 mx-auto">
      {children}
      {hideFooter ? null : <FooterWithClearbit />}
    </div>
    {right && <div className="pl-4 sticky top-20 h-full w-0 md:w-1/3 lg:w-3/12 z-20">{right}</div>}
  </main>
)
