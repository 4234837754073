import Typography, { Color, Size } from "../Typography/Typography"

interface EmptyTableRowProps {
  numberOfColumns: number
  emptyStateText: string
}

export const EmptyTableRow: React.FC<EmptyTableRowProps> = ({
  numberOfColumns,
  emptyStateText,
}) => (
  <tr>
    <td className="text-center py-4" colSpan={numberOfColumns}>
      <Typography size={Size.Small} color={Color.Subtitle} text={emptyStateText} />
    </td>
  </tr>
)
