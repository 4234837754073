import { ButtonSize } from "@stories/components/Button/Button"
import { classNames } from "../../utils/classNames"
import { Color } from "../components/Typography/Typography"
import { Tooltip } from "@stories/components/Antd"

export type IconSize = Exclude<ButtonSize, "none">

export type IconWrapperProps = {
  // If no color is provided, icons will match text color
  color?: Color
  size?: IconSize
  tooltipPlacement?: "top" | "bottom" | "left" | "right"
  tooltipTitle?: string
}

const sizeToClass: Record<IconSize, string> = {
  xs: "w-2.5 h-2.5",
  small: "w-3 h-3",
  medium: "w-4 h-4",
  large: "w-6 h-6",
  xlarge: "w-8 h-8",
}

export type WrappedIcon = (props: IconWrapperProps) => React.ReactNode

export const wrapIcon =
  (icon: React.ReactNode): WrappedIcon =>
  ({ color, size = "medium", tooltipTitle, tooltipPlacement }: IconWrapperProps) =>
    (
      <Tooltip
        title={tooltipTitle}
        placement={tooltipPlacement}
        overlayStyle={{
          maxWidth: "350px",
        }}
      >
        <span
          className={classNames(
            sizeToClass[size],
            color,
            "flex items-center justify-center",
            "wrapped-icon" // adds css to svg children via App.css
          )}
        >
          {icon}
        </span>
      </Tooltip>
    )
