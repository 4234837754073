import { Order } from "common/model/Order/Models/Wrapped"
import OrderPageSidebarCard, {
  OrderPageSidebarCardTitle,
} from "../../components/OrderPageSidebarCard"
import { useOrderLatestUpdates } from "../fns/useOrderUpdateLog"
import OrderHistoryOrderStatus from "./OrderHistoryOrderStatus"
import OrderHistoryPriceUpdateItem from "./OrderHistoryPriceUpdateItem"
import { AccessBoundary } from "../../../../../components/auth/DataAccess"
import { orderDataAccessLevel } from "../../../shared/accessLevels"

const OrderLatestUpdates = ({ order }: { order: Order }) => {
  const { latestPriceUpdates, maybeTerminalStatus } = useOrderLatestUpdates(order)

  if (latestPriceUpdates.length < 2 && !maybeTerminalStatus.isJust()) return null

  return (
    <OrderPageSidebarCard className="flex flex-col gap-4">
      <OrderPageSidebarCardTitle title="Latest Updates" />
      <AccessBoundary accessLevel={orderDataAccessLevel.price(order.isConnectable())}>
        {maybeTerminalStatus.match(
          (orderStatus) => (
            <OrderHistoryOrderStatus orderStatus={orderStatus} />
          ),
          () => null
        )}
        {latestPriceUpdates.map((pricePair) => (
          <OrderHistoryPriceUpdateItem
            key={pricePair[0].date().toString()}
            pricePair={pricePair}
            order={order}
          />
        ))}
      </AccessBoundary>
    </OrderPageSidebarCard>
  )
}

export default OrderLatestUpdates
