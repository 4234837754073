import { UnionUnder } from "../../../utils/data/Record/Types/Pointwise"
import { IntroductionRequestPriceMatch } from "../../IntroductionRequest"
import { OrderQuantityTerms, StructureOrderTerms } from "../Types/Terms"
import { Excluding } from "../../../utils/TypeUtils"
import { BrokerClientRelationship } from "../../BrokerClient"
import { CompanyIdFields, Company } from "../../Company"
import { UnconfirmableYesNo } from "../../UnconfirmableYesNo"
import {
  OrderDirection,
  OrderShareClass,
  OrderFirmness,
  OrderDocuments,
  OrderCommission,
  Order,
} from "../Order"
import { BaseOrderSource } from "../OrderSource"
import { UserIdFields } from "../../User"

export type OrderFormSize =
  | (Required<Pick<OrderQuantityTerms, "amountUSD">> & { shares?: never })
  | (Required<Pick<OrderQuantityTerms, "shares">> & { amountUSD?: never })

export type OrderPrice =
  | (Required<Pick<OrderQuantityTerms, "targetValuation">> & { USDPerShare?: never })
  | (Required<Pick<OrderQuantityTerms, "USDPerShare">> & { targetValuation?: never })
export type PriceData = {
  priceMatch: IntroductionRequestPriceMatch
  price: UnionUnder<null, OrderPrice> | null
  size: UnionUnder<null, OrderFormSize>
}

export type OrderFormState = Omit<Partial<FormOrder>, "price" | "size"> & Partial<PriceData>

export type FormOrderFields = {
  direction: OrderDirection
  crmContactId?: string | null
  crmInterestId?: string | null
  clientRelationship: BrokerClientRelationship
  structures: (keyof StructureOrderTerms)[]
  terms?: Partial<StructureOrderTerms>
  shareClasses: OrderShareClass[]
  shareClassSeries?: string | null
  liveUntil: Date | null
  notes?: string
  price: OrderPrice | null
  size: OrderFormSize
  firmness?: OrderFirmness
  orderDocuments?: OrderDocuments
  commission?: OrderCommission | null
  darkpool: boolean
  brokeredBy?: Order["brokeredBy"] | null
  isMultiLayerSpv?: boolean | null
  buyerCashOnHand?: UnconfirmableYesNo | null
  updateExistingCRMInterestTerms?: boolean
}

type ExtraFormOrderFields = {
  company: Excluding<CompanyIdFields, Company>
  account: BaseOrderSource["account"]
  user: UserIdFields
}

export type FormOrder = FormOrderFields & ExtraFormOrderFields

export type FormOrderErrors = Set<RequiredFormOrderKeys>

export const requiredFormOrderKeys = [
  "direction",
  "clientRelationship",
  "structures",
  "shareClasses",
  "liveUntil",
  "price",
  "size",
  "company",
  "account",
  "buyerCashOnHand",
] as const

export type RequiredFormOrderKeys = (typeof requiredFormOrderKeys)[number]
