import {
  DerivedUserOnlineStatus,
  getDerivedOnlineStatus,
  offlineUserStatus,
} from "common/model/UserOnlineStatus"
import { useCallback } from "react"
import { useFirebase9 } from "../../firebase/Firebase9Context"

export const useUserOnlineStatus = () => {
  const firebase = useFirebase9()
  const getUserStatusSnapshot = useCallback(
    (userId: string, onStatusUpdate: (next: DerivedUserOnlineStatus) => void) =>
      firebase.getUserOnlineStatusSnapshot(userId, (next) => {
        const doc = next.data()
        const derivedStatus = doc ? getDerivedOnlineStatus(doc) : offlineUserStatus(userId)
        onStatusUpdate(derivedStatus)
      }),
    [firebase]
  )

  const getAdminUserStatusSnapshots = useCallback(
    (onStatusUpdates: (next: DerivedUserOnlineStatus[]) => void) =>
      firebase.getAdminOnlineStatusSnapshot((next) => {
        const docs = next.docs.map((doc) => doc.data())
        const derivedStatuses = docs.map((doc) => getDerivedOnlineStatus(doc))
        onStatusUpdates(derivedStatuses)
      }),
    [firebase]
  )

  return { getUserStatusSnapshot, getAdminUserStatusSnapshots }
}
