import { Column, Row } from "@tanstack/react-table"
import { FilterBarComponent } from "../types"
import { handleConsoleError } from "src/utils/Tracking"
import SwitchFilter, { SwitchFilterProps } from "./SwitchFilter"
import { TableColumnFilter } from "common/model/Settings/TableSettings"
import SingleSelectFilter from "./SingleSelectFilter"
import DropdownRadioFilter from "./DropdownRadioFilter"

type MakeFilterProps<RowData extends object, CellValue extends unknown> = {
  columnId: string
  filterBy: (args: { rowValue: RowData; cellValue: CellValue; filterState: string }) => boolean
  initialFilterStateValue?: string
  component: FilterBarComponent["component"]
}
type TableFilter<RowData extends object> = {
  filterFn: (row: Row<RowData>, columnId: string, filterValue: string | undefined) => boolean
  filterBarComponent: FilterBarComponent
  initialFilterState: TableColumnFilter | undefined
}

export const makeTableFilter = <RowData extends object, CellValue extends unknown>({
  filterBy,
  initialFilterStateValue,
  columnId,
  component,
}: MakeFilterProps<RowData, CellValue>): TableFilter<RowData> => ({
  filterFn: (row, colId, filterState: string | undefined) =>
    filterState === undefined ||
    filterBy({ rowValue: row.original, cellValue: row.getValue(colId), filterState }),
  filterBarComponent: { columnId, component },
  initialFilterState: initialFilterStateValue
    ? {
        id: columnId,
        value: initialFilterStateValue,
      }
    : undefined,
})

export const makeTableSwitchFilter = <RowData extends object, CellValue extends unknown>({
  initialActive,
  columnId,
  filterLabels,
  filterRow,
  tooltipText,
}: Omit<
  MakeFilterProps<RowData, CellValue>,
  "component" | "filterBy" | "initialFilterStateValue"
> & {
  filterLabels: { on: string; off: string }
  filterRow: (args: { rowValue: RowData; cellValue: CellValue }) => boolean
  initialActive?: boolean
} & Pick<SwitchFilterProps, "tooltipText">): TableFilter<RowData> =>
  makeTableFilter<RowData, CellValue>({
    filterBy: ({ cellValue, rowValue, filterState }) =>
      filterState === filterLabels.on && filterRow({ cellValue, rowValue }),
    initialFilterStateValue: initialActive ? filterLabels.on : undefined,
    columnId,
    component: ({ setFilterState, filterState, isOnFilterBar, displayFilterState }) =>
      isOnFilterBar ? (
        <DropdownRadioFilter
          label={filterState === undefined ? filterLabels.off : filterLabels.on}
          options={[
            {
              name: filterLabels.off,
              toggle: () => setFilterState(undefined),
              active: filterState === undefined,
            },
            {
              name: displayFilterState?.(filterLabels.on) ?? filterLabels.on,
              toggle: () => setFilterState(filterLabels.on),
              active: filterState === filterLabels.on,
            },
          ]}
        />
      ) : (
        <SwitchFilter
          name={filterLabels.on}
          active={filterState === filterLabels.on}
          toggle={() =>
            setFilterState(filterState === filterLabels.on ? undefined : filterLabels.on)
          }
          tooltipText={tooltipText}
        />
      ),
  })

export const makeSingleSelectFilter = <RowData extends object, CellValue extends unknown>({
  initialFilterStateValue,
  columnId,
  filterLabel,
  filterBy,
  filterOptions,
  undefinedLabel,
}: Omit<MakeFilterProps<RowData, CellValue>, "component"> & {
  filterLabel: string
  filterOptions: string[]
  undefinedLabel: string
} & Pick<SwitchFilterProps, "tooltipText">): TableFilter<RowData> =>
  makeTableFilter<RowData, CellValue>({
    filterBy,
    initialFilterStateValue,
    columnId,
    component: ({
      setFilterState,
      filterState,
      isOnFilterBar,
      displayFilterState = (s: string | undefined) => s ?? undefinedLabel,
    }) => {
      const options = [
        {
          name: undefinedLabel,
          toggle: () => setFilterState(undefined),
          active: filterState === undefined,
        },
        ...filterOptions.map((option) => ({
          name: displayFilterState(option) ?? option,
          toggle: () => setFilterState(option),
          active: filterState === option,
        })),
      ]
      return isOnFilterBar ? (
        <DropdownRadioFilter label={filterLabel} options={options} />
      ) : (
        <SingleSelectFilter name={filterLabel} options={options} />
      )
    },
  })

export const FilterBarComponentRenderer = <RowData extends object>({
  component: Component,
  column,
  isOnFilterBar = false,
}: Pick<FilterBarComponent, "component"> & {
  column: Column<RowData> | undefined
  isOnFilterBar?: boolean
}) => {
  if (!column) {
    return null
  }
  const filterValue = column.getFilterValue()
  if (typeof filterValue !== "string" && filterValue !== undefined) {
    handleConsoleError(
      new Error(
        `Filter value for ${column.id} was not of type string or undefined: ${JSON.stringify(
          filterValue
        )}`
      )
    )
    return null
  }
  return (
    <Component
      setFilterState={column.setFilterValue}
      filterState={filterValue}
      displayFilterState={column.columnDef.meta?.displayFilterState}
      isOnFilterBar={isOnFilterBar}
    />
  )
}
