import { UserProductInformationHistory } from "common/model/User"
import { useLocation } from "react-router-dom"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { ProductInformationLoomLinks } from "./InformationPopupConfig"
import { useInformationPopupModal } from "./InformationPopupModal"

export const InformationPopup = ({
  informationKey,
}: {
  informationKey: keyof UserProductInformationHistory
}) => {
  const user = useLoggedInUser()
  const { pathname } = useLocation()
  const productInfo = ProductInformationLoomLinks[informationKey]

  const hasViewed = user.user?.productInteractionHistory?.[informationKey]
  const { ModalComponent } = useInformationPopupModal({
    informationKey,
    defaultModalOpen: !hasViewed,
  })

  const hideFromUser = productInfo.hideFromUser ? productInfo.hideFromUser(user.user) : false
  const dateIsExpired = productInfo.announcementLiveUntil
    ? productInfo.announcementLiveUntil.valueOf() < new Date().valueOf()
    : false
  const onBlacklistedRoute = productInfo.blacklistedRoutes
    ? productInfo.blacklistedRoutes.includes(pathname)
    : false
  const offWhitelistedRoute = productInfo.whitelistedRoutes
    ? !productInfo.whitelistedRoutes.includes(pathname)
    : false

  if (hasViewed || hideFromUser || dateIsExpired || onBlacklistedRoute || offWhitelistedRoute) {
    return null
  }

  return <>{ModalComponent}</>
}
