import { SelectedCompany } from "common/model/Company"
import * as React from "react"
import { useNavigate } from "react-router-dom"
import { Routes } from "../../../Routes/Routes"
import CompanyAutocomplete from "../../companies/select/CompanyAutocomplete"
import { useFeatureFlag } from "../../../providers/featureFlags/useFeatureFlags"
import { QuickActionBar } from "../../../pages/CRM/Components/QuickActionBar/QuickActionBar"
import { useViewport } from "src/utils/useViewport"

interface NavbarCompanySearchProps {
  onSearch?: () => void
}

const NavbarCompanySearch: React.FunctionComponent<
  React.PropsWithChildren<NavbarCompanySearchProps>
> = ({ onSearch }) => {
  const navigate = useNavigate()
  const viewport = useViewport()
  const isCRMEnabled = useFeatureFlag("crm")

  const handleCompanySelect = (company: SelectedCompany | undefined) => {
    if (company?.postgresCompanyId) {
      navigate(Routes.companies.company(company.postgresCompanyId))
      if (onSearch) {
        onSearch()
      }
    }
  }

  const isMobile = viewport.size === "small"

  return (
    <div className="md:h-full min-w-60 flex items-center">
      {isCRMEnabled && !isMobile ? (
        <QuickActionBar />
      ) : (
        <CompanyAutocomplete
          id="navbar-company-search"
          handleSelect={handleCompanySelect}
          selected={undefined}
          className="mt-2.5"
          searchInputClassName="rounded-md"
          includeKeyboardShortcut
        />
      )}
    </div>
  )
}

export default NavbarCompanySearch
