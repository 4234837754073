import { useEffect, useState } from "react"
import { isLoaded } from "common/utils/Loading"
import { OrderPageLoadingOrder, OrderPageOrder } from "./types"
import { useFirebase9 } from "../../../firebase/Firebase9Context"
import { handleConsoleError, trackEvent, trackUserEventInFirestore } from "../../../utils/Tracking"
import { incrementViewCount } from "../../../utils/ViewTracked"
import { useLoggedInUser } from "../../../providers/loggedInUser/useLoggedInUser"
import { identity } from "common/utils/fp/Function"

const LoadedTrackOrderPage = ({ order }: { order: OrderPageOrder }) => {
  const firebase = useFirebase9()
  const currentUser = useLoggedInUser()

  const [viewTrackedOrderIds, setViewTrackedOrderIds] = useState<Set<string>>(new Set())

  useEffect(() => {
    if (!isLoaded(order) || viewTrackedOrderIds.has(order.id())) {
      return
    }

    trackUserEventInFirestore(firebase, currentUser.user, "order-details-page-view")
    trackEvent("view-order-details", {
      orderId: order.id(),
      companyId: order.company().id,
      companyName: order.company().name,
      orderStructure: order
        .currentStructures()
        .map((structure) => structure.displayName())
        .join(","),
    })

    const reasonsToSkipTrackingOnDoc: boolean[] = [
      currentUser.isAdmin,
      order.isPrivate,
      order.isDarkpool(),
      order.fromAccount(currentUser.user.account),
      order.isBrokeredByUser(currentUser.user),
    ]

    if (!reasonsToSkipTrackingOnDoc.some(identity)) {
      firebase
        .getOrderRef(order.toInternal())
        .runEffect((ref) => incrementViewCount(ref, currentUser.user.id).catch(handleConsoleError))
    }

    setViewTrackedOrderIds((prev) => prev.add(order.id()))
  }, [firebase, order, currentUser.user, viewTrackedOrderIds, currentUser.isAdmin])

  return null
}

const TrackOrderPage = ({ order }: OrderPageLoadingOrder) =>
  order.match((o) => <LoadedTrackOrderPage order={o} />, null, null)

export default TrackOrderPage
