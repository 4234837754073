import { firestoreConverter } from "@model/FirestoreConverter"
import { Radio } from "@stories/components/Antd"
import { Button } from "@stories/components/Button/Button"
import Typography, { Size, Weight } from "@stories/components/Typography/Typography"
import { collections } from "common/firestore/Collections"
import { Account } from "common/model/Account"
import { LinkedBroker } from "common/model/LinkedBroker"
import { UserInvitedBroker } from "common/model/UserInvitedBroker"
import { isDefined } from "common/utils/TypeUtils"
import { isNonempty, justIfNonempty } from "common/utils/data/Array/Nonempty"
import { Firestore, collection, doc, getDocs, setDoc, updateDoc } from "firebase/firestore"
import { uniqBy } from "lodash"
import { useEffect, useState } from "react"
import { SingletonObject } from "src/utils/hooks/effects/useEffectSafe"
import { useFirebase9 } from "../../firebase/Firebase9Context"
import { CurrentUser } from "../../model/CurrentUser"
import { handleConsoleError } from "../../utils/Tracking"
import { useResetState } from "../../utils/useResetState"
import { AddLinkedBroker } from "./AddLinkedBroker"
import { InvitedBroker } from "./InviteLinkedBroker"
import SectionHeader from "@stories/components/Typography/SectionHeader"
import SelectRecommendedBroker from "./SelectRecommendedBroker"
import { useLinkedBrokersQuery } from "src/queries/LinkedBroker/useLinkedBrokersQuery"

export const saveLinkedBrokerPreferences = async (
  db: SingletonObject<Firestore>,
  {
    selectedBrokers,
    invitedBrokers,
    caplightAsBroker,
    user,
  }: {
    selectedBrokers: LinkedBroker[]
    invitedBrokers: InvitedBroker[]
    caplightAsBroker: boolean
    user: CurrentUser
  }
) => {
  try {
    const selectedBrokerPromises = justIfNonempty(selectedBrokers)
      .map((selected) =>
        selected.map((broker) => {
          const selectedBrokerCollectionRef = collection(
            db,
            collections.accounts,
            user.user.account.id,
            collections.accountSubcollections.linkedBrokers
          )
          const linkedBrokerRef = doc(selectedBrokerCollectionRef, broker.id)
          return () =>
            setDoc(linkedBrokerRef, {
              ...broker,
              id: linkedBrokerRef.id,
            } satisfies LinkedBroker)
        })
      )
      .withDefault<(() => Promise<void>)[]>([])

    const invitedBrokerPromises = justIfNonempty(invitedBrokers)
      .map((invited) =>
        invited.map((broker) => {
          const invitedBrokerCollectionRef = collection(
            db,
            collections.accounts,
            user.user.account.id,
            collections.accountSubcollections.invitedBrokers
          )
          const invitedBrokerRef = doc(invitedBrokerCollectionRef)
          return () =>
            setDoc(invitedBrokerRef, {
              ...broker,
              id: invitedBrokerRef.id,
            } satisfies UserInvitedBroker)
        })
      )
      .withDefault<(() => Promise<void>)[]>([])

    const caplightAsBrokerPromise = () => {
      const accountCollectionRef = collection(db, collections.accounts)
      const accountRef = doc(accountCollectionRef, user.user.account.id)
      return updateDoc(accountRef, {
        caplightAsBroker,
      } satisfies Partial<Account>)
    }

    await justIfNonempty(
      selectedBrokerPromises.concat(invitedBrokerPromises).concat([caplightAsBrokerPromise])
    ).match(
      (allPromises) => Promise.all(allPromises.map((fn) => fn())),
      () => {
        // NOTE: should not be possible
        throw new Error("no brokers selected or invited")
      }
    )
  } catch (err) {
    handleConsoleError(err)
  }
}

export const SelectLinkedBrokerForm: React.FC<{
  user: CurrentUser
  selectedBrokers: LinkedBroker[]
  setSelectedBrokers: React.Dispatch<React.SetStateAction<LinkedBroker[]>>
  invitedBrokers: InvitedBroker[]
  addInvitedBroker: (b: InvitedBroker) => void
  removeInvitedBroker: (b: InvitedBroker) => void
  userWorksWithBroker: boolean
  caplightAsBroker: boolean
  setCaplightAsBroker: React.Dispatch<React.SetStateAction<boolean>>
}> = ({
  user,
  selectedBrokers,
  setSelectedBrokers,
  invitedBrokers,
  addInvitedBroker,
  removeInvitedBroker,
  userWorksWithBroker,
  caplightAsBroker,
  setCaplightAsBroker,
}) => {
  const allBrokers = useLinkedBrokersQuery()
  return (
    <div className="space-y-4">
      {userWorksWithBroker ? (
        <AddLinkedBroker
          allBrokers={allBrokers}
          selectedBrokers={selectedBrokers}
          setSelectedBrokers={setSelectedBrokers}
          invitedBrokers={invitedBrokers}
          addInvitedBroker={addInvitedBroker}
          removeInvitedBroker={removeInvitedBroker}
        />
      ) : (
        <SelectRecommendedBroker
          user={user}
          allBrokers={allBrokers}
          selectedBrokers={selectedBrokers}
          setSelectedBrokers={setSelectedBrokers}
          caplightAsBroker={caplightAsBroker}
          setCaplightAsBroker={setCaplightAsBroker}
        />
      )}
    </div>
  )
}

const SelectLinkedBroker: React.FC<
  React.PropsWithChildren<{
    user: CurrentUser
    submissionLoading: boolean
    onNext: () => void
  }>
> = ({ user, onNext, submissionLoading }) => {
  const [userWorksWithBroker, setUserWorksWithBroker] = useState<boolean | undefined>(undefined)
  const [caplightAsBroker, setCaplightAsBroker] = useState<boolean>(false)
  const [selectedBrokers, setSelectedBrokers, resetSelectedBrokers] = useResetState<LinkedBroker[]>(
    []
  )
  const [invitedBrokers, setInvitedBrokers, resetInvitedBrokers] = useResetState<InvitedBroker[]>(
    []
  )

  const { db } = useFirebase9()

  const onContinue = () =>
    saveLinkedBrokerPreferences(db, {
      selectedBrokers,
      invitedBrokers,
      caplightAsBroker,
      user,
    }).then(onNext)

  useEffect(() => {
    if (isNonempty(selectedBrokers)) setCaplightAsBroker(false)
  }, [selectedBrokers])

  const validForm = caplightAsBroker || isNonempty(invitedBrokers) || isNonempty(selectedBrokers)

  return (
    <>
      <SectionHeader
        title="Select Broker"
        subtext="Select your preferred broker(s) to complete transactions on your behalf"
      />
      <div className="flex flex-col space-y-4">
        <Typography
          text="Do you regularly work with a broker for private market transactions?"
          size={Size.Small}
          weight={Weight.Bold}
        />
        <div className="flex space-x-1">
          {(["Yes", "No"] as const).map((option) => (
            <Radio
              value={option}
              key={option}
              onClick={() => {
                resetInvitedBrokers()
                resetSelectedBrokers()
                setUserWorksWithBroker(option === "Yes")
              }}
              checked={
                isDefined(userWorksWithBroker) &&
                (userWorksWithBroker ? option === "Yes" : option === "No")
              }
            >
              {option}
            </Radio>
          ))}
        </div>
        {isDefined(userWorksWithBroker) ? (
          <SelectLinkedBrokerForm
            user={user}
            userWorksWithBroker={userWorksWithBroker}
            selectedBrokers={selectedBrokers}
            setSelectedBrokers={setSelectedBrokers}
            invitedBrokers={invitedBrokers}
            addInvitedBroker={(b: InvitedBroker) =>
              setInvitedBrokers((prev) => uniqBy(prev.concat(b), "email"))
            }
            removeInvitedBroker={(b: InvitedBroker) =>
              setInvitedBrokers((prev) => prev.filter((cur) => cur.email !== b.email))
            }
            caplightAsBroker={caplightAsBroker}
            setCaplightAsBroker={setCaplightAsBroker}
          />
        ) : null}
        <div className="pt-4">
          <Button
            label="Continue"
            onClick={onContinue}
            isLoading={submissionLoading}
            isDisabled={!validForm || submissionLoading}
            isFullWidth
          />
        </div>
      </div>
    </>
  )
}

export default SelectLinkedBroker
