import { InformationCircleIcon } from "@heroicons/react/solid"
import { Skeleton, Tooltip } from "@stories/components/Antd"
import Typography, { Color, Size } from "@stories/components/Typography/Typography"
import { useMemo } from "react"
import { AccessBoundary, DataAccessLevel } from "../../../../components/auth/DataAccess"

const Wrapper: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <div className="grid grid-cols-1 md:grid-cols-2 gap-2 bg-neutral-white text-neutral-800 px-2 py-1 items-center">
    {children}
  </div>
)
export const LoadingOrderTermSheetRow = () => (
  <Wrapper>
    <Skeleton
      title={{ width: useMemo(() => 64 + Math.random() * 24, []) }}
      paragraph={false}
      active
    />
    <Skeleton
      title={{ width: useMemo(() => 64 + Math.random() * 24, []) }}
      paragraph={false}
      active
    />
  </Wrapper>
)

export interface OrderTermSheetRowProps {
  label: string
  value: React.ReactNode
  tooltip?: string
  accessLevel: DataAccessLevel
}

const OrderTermSheetRow = ({ label, value, tooltip, accessLevel }: OrderTermSheetRowProps) => (
  <Wrapper>
    <Typography text={label} size={Size.Small} shouldWrap={false} color={Color.Subtitle} />
    <div className="flex items-center space-x-1 text-base text-neutral-1000 md:text-sm">
      <AccessBoundary accessLevel={accessLevel}>
        <div>
          <Typography text={value} size={Size.Small} shouldWrap={false} />
        </div>
        {tooltip ? (
          <Tooltip title={tooltip}>
            <InformationCircleIcon className="w-4 h-4" />
          </Tooltip>
        ) : null}
      </AccessBoundary>
    </div>
  </Wrapper>
)

export default OrderTermSheetRow
