import { SelectOptions } from "@stories/components/Inputs/RadioSelect/RadioSelect"
import { CompanyIdFields } from "common/model/Company"
import {
  IntroductionRequestData,
  IntroductionRequestFormState,
} from "common/model/IntroductionRequest"
import { LIVE_UNTIL_DEFAULT_DAYS } from "common/model/Order/OrderConstants"

export const requestFormLabels = {
  Intermediary: {
    direction: {
      title: "Is your client a buyer or seller?",
      options: [
        { value: "buy", label: "Buyer" },
        { value: "sell", label: "Seller" },
      ] satisfies SelectOptions<IntroductionRequestData["direction"]>[],
    },
    clientDetails: {
      title: "Client Details",
      options: {
        clientRelationship: [
          {
            value: "direct",
            label: "Direct to the client",
          },
          {
            value: "indirect",
            label: "Indirect (Connected via a broker)",
          },
        ] satisfies SelectOptions<IntroductionRequestData["clientRelationship"]>[],
      },
      titles: (company?: CompanyIdFields) => ({
        clientRelationship: "What is your relationship to the client?",
      }),
    },
    price: {
      titles: {
        priceMatch: "Is the client able to match the price of the selected order?",
      },
      options: ({ direction }: IntroductionRequestFormState) => ({
        priceMatch: [
          { value: "match", label: "Yes, the client can meet this price" },
          {
            value: "differ",
            label: `No, the client is slightly ${
              direction === "buy" ? "below" : "above"
            } this price`,
          },
          { value: "unknown", label: "I do not know the client's price" },
        ] satisfies SelectOptions<IntroductionRequestData["priceMatch"]>[],
      }),
    },
    structure: {
      titles: {
        structure: "Which trade structures is the client open to?",
        carryManagement: "Economics",
      },
    },
    liveUntil: {
      title: "How long should the order stay open?",
      titles: { liveUntil: `(Default of ${LIVE_UNTIL_DEFAULT_DAYS} days)` },
    },
    notes: {
      title: "Additional context?",
      optional: true,
      titles: {
        notes: "Add any additional notes or questions for Caplight.",
      },
    },
    doNotIntroNotes: {
      title:
        "Are there any counter-parties you would not like to connect with on this opportunity?",
      titles: {
        doNotIntroNotes: "",
      },
    },
  },
  Client: {
    direction: {
      title: "Are you a buyer or seller?",
      options: [
        { value: "buy", label: "Buyer" },
        { value: "sell", label: "Seller" },
      ] satisfies SelectOptions<IntroductionRequestData["direction"]>[],
    },
    clientDetails: {
      title: undefined,
      options: undefined,
      titles: (_company?: CompanyIdFields) => undefined,
    },
    price: {
      titles: {
        priceMatch: "Are you able to match the price of the selected order?",
      },
      options: ({ direction }: IntroductionRequestFormState) => ({
        priceMatch: [
          { value: "match", label: "Yes, we can meet this price" },
          {
            value: "differ",
            label: `No, we are slightly ${direction === "buy" ? "below" : "above"} this price`,
          },
          { value: "unknown", label: "We don't have a firm price" },
        ] satisfies SelectOptions<IntroductionRequestData["priceMatch"]>[],
      }),
    },
    structure: {
      titles: {
        structure: "Which trade structures are you open to?",
        carryManagement: "Economics",
      },
    },
    liveUntil: {
      title: "How long should the order stay open?",
      titles: { liveUntil: `(Default of ${LIVE_UNTIL_DEFAULT_DAYS} days)` },
    },
    notes: {
      title: "Additional context?",
      optional: true,
      titles: {
        notes: "Add any additional notes or questions for Caplight.",
      },
    },
    doNotIntroNotes: {
      title:
        "Are there any counter-parties you would not like to connect with on this opportunity?",
      titles: {
        doNotIntroNotes: "",
      },
    },
  },
}
