import { JsonKVP } from "@components/displays/JSON"
import { LeafEditor, RecordEditor, StateDisplay } from "@components/displays/StateDisplay"
import Spinner from "@components/icons/Spinner"
import { TextInput } from "@components/inputs/TextInputField"
import { Button, Checkbox, DatePicker } from "@stories/components/Antd"
import { CompanyIdFields, SelectedCompany, viewCompanyIdFields } from "common/model/Company"
import { firestoreConverter } from "common/model/FirestoreConverter"
import { deprecatedIsLoaded, isLoading } from "common/utils/Loading"
import { undefinedsToNulls } from "common/utils/ObjectUtils"
import { allWhitespace } from "common/utils/StringUtils"
import { SimpleLens } from "common/utils/fp/optics/Lens"
import { SimplePrism } from "common/utils/fp/optics/Prism"
import _ from "lodash"
import moment from "moment"
import { useEffect, useReducer, useState } from "react"
import { useLocation } from "react-router-dom"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { captureException } from "src/utils/Logging"
import FirebaseCompanyAutocomplete from "../../components/companies/select/FirebaseCompanyAutocomplete"
import { ANote } from "../../components/typography/Title"
import { useFirebaseAdmin, useFirebaseReader } from "../../firebase/Context"
import { DocumentReference } from "../../firebase/Firebase/utils"
import { useCurrentUser } from "../../providers/currentUser/useCurrentUser"
import { useDbState } from "../../utils/useDb"
import { firestoreConsoleLink } from "../utils"
import { EditableRecord, UpdateDocumentLoggedFnType, deleteDocument } from "./RecordInspectorCRUD"
import { NumberInput } from "../../components/inputs"

const DeletionConfirmation = <T extends object>({
  docRef,
  cancel,
  reason,
}: {
  docRef: DocumentReference<T>
  cancel: () => void
  reason: string
}) => {
  const [inputState, setInputState] = useState<string>("")
  const [deleting, setDeleting] = useState<boolean>(false)
  const [deleted, setDeleted] = useState<boolean>(false)
  const db = useFirebaseAdmin()
  const user = useCurrentUser()
  return !deprecatedIsLoaded(user) ? (
    <Spinner size="md" />
  ) : deleted ? (
    <>Document has been deleted</>
  ) : (
    <div>
      <div>Enter the document id to confirm deletion</div>
      <div className="font-bold">This cannot be undone</div>
      <TextInput
        name="Document Id"
        value={inputState}
        onChange={(e) => setInputState(e.target.value)}
      />
      <Button
        danger
        disabled={deleting || inputState !== docRef.id || allWhitespace(reason)}
        onClick={() => deleteDocument(db, user, reason, docRef).then(() => setDeleted(true))}
      >
        Confirm Deletion
      </Button>
      <Button disabled={deleting} onClick={cancel}>
        Cancel
      </Button>
    </div>
  )
}

const DeleteRecordButton = <T extends object>({
  docRef,
  reason,
}: {
  docRef: DocumentReference<T>
  reason: string
}) => {
  const [deletionStarted, setDeletionStarted] = useState<boolean>(false)

  return (
    <div className="m-2">
      <Button
        className="w-full"
        disabled={deletionStarted}
        danger
        onClick={() => setDeletionStarted(true)}
      >
        Delete Record
      </Button>
      {deletionStarted ? (
        <DeletionConfirmation
          reason={reason}
          docRef={docRef}
          cancel={() => setDeletionStarted(false)}
        />
      ) : null}
    </div>
  )
}

const SaveRecordButton = <R extends EditableRecord>({
  docRef,
  doc,
  pendingEdits,
  reason,
  setMessage,
  updateDocumentWithEditLogs,
}: {
  docRef: DocumentReference<R>
  doc: R
  pendingEdits: [boolean, (b: boolean) => void]
  reason: string
  setMessage: (t: string) => void
  updateDocumentWithEditLogs: UpdateDocumentLoggedFnType<R>
}) => {
  const db = useFirebaseAdmin()
  const u = useLoggedInUser()
  console.log(doc)
  return (
    <div className="m-2">
      {pendingEdits[0]}
      <Button
        type="primary"
        onClick={() => {
          updateDocumentWithEditLogs(db, u, reason, docRef, undefinedsToNulls(doc))
            .then((res) => {
              const resId = res.match(
                (updatedDoc) => updatedDoc.id,
                () => ""
              )
              setMessage(`Id of updated document: ${resId}`)
              pendingEdits[1](false)
            })
            .catch(captureException)
        }}
        disabled={!pendingEdits[0] || allWhitespace(reason)}
      >
        Save changes to database
      </Button>
    </div>
  )
}
const RecordSummary = <R extends EditableRecord>({
  docRef,
  doc,
  canEdit,
  canDelete,
  pendingEdits,
  setMessage,
  updateDocumentWithEditLogs,
}: {
  docRef: DocumentReference<R>
  doc: R
  canEdit: boolean
  canDelete: boolean
  pendingEdits: [boolean, (b: boolean) => void]
  setMessage: (t: string) => void
  updateDocumentWithEditLogs: UpdateDocumentLoggedFnType<R>
}) => {
  const [editReason, setEditReason] = useState("")
  return (
    <div className="flex flex-row justify-between align-middle p-4 shadow-lg bg-neutral-white mt-2 border-neutral-400 border-2">
      <div>
        <div>
          <span>Id: </span>
          <span>{docRef.id}</span>
        </div>
        <div>
          <span>Path: </span>
          <span>{docRef.path}</span>
        </div>
        <div className="text-sm text-accent-400">
          <a href={firestoreConsoleLink(docRef.path)} target="_blank" rel="noreferrer">
            View in Firestore console
          </a>
        </div>
      </div>
      <div>
        <span>Edit reason:</span>
        <TextInput name="" value={editReason} onChange={(e) => setEditReason(e.target.value)} />
      </div>
      <div>
        {canEdit ? (
          <SaveRecordButton
            reason={editReason}
            pendingEdits={pendingEdits}
            doc={doc}
            docRef={docRef}
            updateDocumentWithEditLogs={updateDocumentWithEditLogs}
            setMessage={setMessage}
          />
        ) : null}
        {canDelete ? <DeleteRecordButton reason={editReason} docRef={docRef} /> : null}
      </div>
    </div>
  )
}

const RecordFieldEditor = <R extends Record<string, unknown>>({
  editor,
  doc,
  onChange,
}: {
  editor: RecordEditor<R>
  doc: R
  onChange: (r: R) => void
}) => (
  <div className="shadow-lg bg-neutral-white mt-2 border-neutral-400 border-2">
    <StateDisplay onChange={(r) => onChange(r)} initialState={doc} editor={editor} />
  </div>
)

export const editorFromPrism = <T,>(prism: SimplePrism<string, T>): LeafEditor<T> => ({
  display: (k, state, setState) => (
    <div className="pl-0">
      {JsonKVP(
        k,
        <TextInput
          name=""
          value={prism.review(state)}
          onChange={(e) => {
            const parsed = prism.preview(e.target.value)
            if (parsed.isRight()) {
              setState(parsed.value.value)
            }
          }}
        />
      )}
    </div>
  ),
})

export const numberEditorFromPrism =
  (precision: "decimal" | "integer") =>
  (prism: SimplePrism<string, number>): LeafEditor<number> => ({
    display: (k, state, setState) => (
      <div className="pl-0">
        {JsonKVP(
          k,
          <NumberInput
            name="float-editor"
            precision={precision}
            value={state}
            onChange={(value: number | undefined) => {
              const parsed = prism.preview(value ? value.toString() : "")
              if (parsed.isRight()) {
                setState(parsed.value.value)
              }
            }}
          />
        )}
      </div>
    ),
  })

export const checkboxEditor: LeafEditor<boolean> = {
  display: (k, state, setState) => (
    <div className="pl-8">
      {JsonKVP(k, <Checkbox checked={state} onChange={(e) => setState(e.target.checked)} />)}
    </div>
  ),
}
export const optionalCheckboxEditor: LeafEditor<boolean | undefined | null> = {
  // todo deduplicate
  display: (k, state, setState) => (
    <div className="pl-0">
      {JsonKVP(
        k,
        <Checkbox checked={state ?? false} onChange={(e) => setState(e.target.checked)} />
      )}
    </div>
  ),
}
export const companyEditor: LeafEditor<CompanyIdFields> = {
  display: (k, state, setState) => (
    <div className="pl-8">
      {JsonKVP(
        k,
        state.postgresCompanyId ? (
          <div className="flex flex-row space-x-2">
            <FirebaseCompanyAutocomplete
              selected={{
                ...state,
                postgresCompanyId: state.postgresCompanyId,
                lastRoundDate: null,
                lastRoundPostMoneyValuation: null,
              }}
              handleSelect={(sel: SelectedCompany | undefined) => {
                if (sel) setState(viewCompanyIdFields(sel))
              }}
            />
            <ANote>{state.postgresCompanyId}</ANote>
          </div>
        ) : null
      )}
    </div>
  ),
}
export const DateEditor: LeafEditor<Date> = {
  // todo deduplicate
  display: (k, state, setState) => (
    <div className="pl-8">
      {JsonKVP(
        k,
        <DatePicker value={moment(state)} onChange={(x) => (x ? setState(x?.toDate()) : {})} />
      )}
    </div>
  ),
}
export const DateOrNullEditor: LeafEditor<Date | null> = {
  // todo deduplicate
  display: (k, state, setState) => (
    <div className="pl-8">
      {JsonKVP(
        k,
        <DatePicker value={moment(state)} onChange={(x) => (x ? setState(x?.toDate()) : {})} />
      )}
    </div>
  ),
}

export const RecordInspector = <R extends EditableRecord & { id?: string }>({
  editor,
  updateDocumentWithEditLogs,
  canEdit = false, // todo infer from `editor`; will require decomposing `display` (which should be done anyway)
  canDelete = false,
}: {
  editor: RecordEditor<R> // need to improve the type inference here before making this more JSX-friendly.
  updateDocumentWithEditLogs: UpdateDocumentLoggedFnType<R>
  canEdit?: boolean // todo infer from `editor`; will require decomposing `display` (which should be done anyway)
  canDelete?: boolean
}) => (
  <AdaptedRecordInspector
    editor={editor}
    canEdit={canEdit}
    canDelete={canDelete}
    adapter={{ view: (x) => x, over: (x, f) => f(x) }}
    updateDocumentWithEditLogs={updateDocumentWithEditLogs}
  />
)

const AdaptedRecordInspectorInner = <
  Raw extends EditableRecord,
  R extends Record<string, unknown> = Raw
>({
  editor,
  canEdit,
  canDelete,
  adapter,
  document,
  setMessage,
  updateDocumentWithEditLogs,
}: {
  editor: RecordEditor<R>
  canEdit: boolean
  canDelete: boolean
  adapter: SimpleLens<Raw, R>
  document: Raw
  setMessage: (t: string) => void
  updateDocumentWithEditLogs: UpdateDocumentLoggedFnType<Raw>
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const firebase = useFirebaseReader()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const location = useLocation()
  const path = _.tail(location.pathname.split("records")[1].split("/"))
  const documentRef = firebase.db.doc(path.join("/"))

  const [newDoc, updateNewDoc] = useReducer(
    (old: Raw, modified: R) => adapter.over(old, () => modified),
    document
  )
  useEffect(() => {
    if (document && !isLoading(document)) {
      updateNewDoc(adapter.view(document))
    }
  }, [adapter, document])
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const pendingEdits = useState<boolean>(false)
  return isLoading(document) ? (
    <Spinner size="md" />
  ) : !document ? (
    <>document at {path} not found</>
  ) : (
    <div className="flex flex-col">
      <RecordSummary<Raw>
        doc={newDoc}
        pendingEdits={pendingEdits}
        canEdit={canEdit}
        canDelete={canDelete}
        docRef={documentRef as DocumentReference<Raw>}
        setMessage={setMessage}
        updateDocumentWithEditLogs={updateDocumentWithEditLogs}
      />
      <RecordFieldEditor<R>
        editor={editor}
        doc={adapter.view(document)}
        onChange={(r) => {
          updateNewDoc(r)
          pendingEdits[1](true)
        }}
      />
    </div>
  )
}

/** A RecordInspector<Raw, R> allows a value of type `Raw` to be viewed as an `R`, and transforms R-edits to Raw-edits before applying them to the database */
export const AdaptedRecordInspector = <
  Raw extends EditableRecord,
  R extends Record<string, unknown> = Raw
>({
  editor,
  canEdit = false, // todo infer from `editor`; will require decomposing `display` (which should be done anyway)
  canDelete = false,
  adapter,
  updateDocumentWithEditLogs,
}: {
  editor: RecordEditor<R> // need to improve the type inference here before making this more JSX-friendly.
  canEdit: boolean // todo infer from `editor`; will require decomposing `display` (which should be done anyway)
  canDelete: boolean
  adapter: SimpleLens<Raw, R>
  updateDocumentWithEditLogs: UpdateDocumentLoggedFnType<Raw> // if the generic update implementation of updateDocumentLogged is not suitable
}) => {
  const [message, setMessage] = useState<string>("") // for now this is only used for Orders to show the id of the merged order
  const firebase = useFirebaseReader()
  const location = useLocation()
  const path = _.tail(location.pathname.split("records")[1].split("/"))
  const documentRef = firebase.db.doc(path.join("/"))
  const document = useDbState(
    () =>
      documentRef
        .withConverter<Raw & { id?: string }>(firestoreConverter())
        .get()
        .then((x) => x.data() ?? null),
    [message]
  )
  if (!document)
    return (
      <>
        <p>document at {path} not found</p>
        <p>{message}</p>
      </>
    )
  return deprecatedIsLoaded(document) ? (
    <div className="flex flex-col">
      <AdaptedRecordInspectorInner
        editor={editor}
        canEdit={canEdit}
        canDelete={canDelete}
        adapter={adapter}
        document={document}
        updateDocumentWithEditLogs={updateDocumentWithEditLogs}
        setMessage={setMessage}
      />
      <p>{message}</p>
    </div>
  ) : (
    <Spinner size="md" />
  )
}
