/**
 * Accounts whose orders are omitted from the Live Market, regardless of order source
 */
export const connectExcludedAccountIds = [
  "FmFOWy80weY9IWwWNoDX", // Rainmker
  "ebb4d701-c518-4057-a110-14a972ae3532", // Abstra Agency
]

/**
 * Accounts whose csv or API submitted orders are omitted from the Live Market
 * but whose form-submitted orders are included in the Live Market
 */
export const nonFormOrdersConnectExcludedAccountIds = [
  "1670e010-d573-471f-b3d3-33ca242c02d4", // Unicorns Exchange
]
