import InfoTooltip from "@components/InfoTooltip"
import Alert from "@stories/components/Alert/Alert"
import { Button } from "@stories/components/Button/Button"
import Typography from "@stories/components/Typography/Typography"
import { Left, Right } from "common/containers/Either"
import { DealCRMContact, constructDealCRMContactParser } from "common/model/DealCRM/DealCRMContact"
import { printErrors } from "common/parser/ParseErrors"
import { Parse, Parser } from "common/parser/ParserClasses/Simple"
import { annotate } from "common/utils/Coerce"
import { UnsafeRec } from "common/utils/RecordUtils"
import { useMemo, useState } from "react"
import { handleConsoleError } from "src/utils/Tracking"
import { useContactDetailsDrawer } from "../../Contacts/ContactDetailsDrawer/helpers"
import { useCRMContacts } from "../../Providers/CRMContactsProvider"

type ParsedCSVPreviewProps<T> = {
  headers: string[]
  csvContents: string
  fieldParsers: {
    [key in keyof T]: Parser<string, {}, T[key]>
  }
  renderParsed: (t: T) => string[]
  postValidationErrors: string[] | undefined
}

export const ParsedCSVPreview = <T,>(props: ParsedCSVPreviewProps<T>) => {
  const [itemsMax, setItemsMax] = useState(10)
  const { contacts } = useCRMContacts()
  const parser = useMemo(
    () => constructDealCRMContactParser(props.fieldParsers),
    [props.fieldParsers]
  )
  const fieldParserArray: Parser<string, {}, T[keyof T]>[] = useMemo(
    () =>
      UnsafeRec.values(
        annotate<Record<keyof T, Parser<string, {}, T[keyof T]>>>(props.fieldParsers)
      ),
    [props.fieldParsers]
  )
  const parseResult = useMemo(
    // THIS IS TERRIBLE CODE AND SHOULD NOT BE IMITATED
    () => {
      try {
        return parser.run({}, Array.from(props.csvContents)).value.mapFst((err) =>
          Parse.CSV.unformatted.run({}, Array.from(props.csvContents)).value.match(
            () => Left(printErrors(err)),
            (r) => {
              // if row[0] is a valid, assume the first row isn't a header
              const rows = r[0]
                .map((cell, i) => fieldParserArray[i].accepts({}, Array.from(cell)))
                .every((v) => !!v)
                ? r
                : r.slice(1)
              return Right(
                rows.map((row) =>
                  row.map((cell, i) => fieldParserArray[i].run({}, Array.from(cell)).value)
                )
              )
            }
          )
        )
      } catch (error) {
        handleConsoleError(error)
        return Left(Left("The uploaded file could not be parsed.")) // This should never happen
      }
    },
    [parser, props.csvContents, fieldParserArray]
  )

  const parseTableError = useMemo(
    () =>
      parseResult.match(
        (error) =>
          error.match(
            (p) => p,
            () => null
          ),
        () => null
      ),
    [parseResult]
  )
  const parseRowError = useMemo(
    () =>
      props.postValidationErrors?.length ||
      parseResult.match(
        (error) =>
          error.match(
            () => false,
            (rows) =>
              rows.some((row) =>
                row.some((cell) =>
                  cell.match(
                    () => false,
                    () => true
                  )
                )
              )
          ),
        () => false
      ),
    [parseResult, props.postValidationErrors?.length]
  )
  if (parseTableError) {
    return <div>{parseTableError}</div>
  }
  return (
    <div className="flex flex-col gap-4">
      {parseRowError ? (
        <Alert
          variant="danger"
          headline="Something went wrong"
          message={
            <div className="flex flex-col gap-4">
              {props.postValidationErrors?.map((e) => (
                <div key={`${e}`}>
                  <Typography text={e} />
                </div>
              ))}
            </div>
          }
        />
      ) : null}
      <div className="w-full overflow-x-auto border rounded">
        <table className="table-auto w-full">
          <thead className="bg-neutral-300">
            <tr>
              {props.headers.map((header) => (
                <th className="whitespace-nowrap px-2 text-left" key={header}>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {parseResult.match(
              (err) =>
                err.match(
                  () => null,
                  (rows) =>
                    rows.map((row) => (
                      <tr key={JSON.stringify(row)} className="border-b last:border-b-0">
                        {row.map((s) =>
                          s.match(
                            (cellErr) => (
                              <ErrorCSVCell
                                value={printErrors(cellErr)}
                                key={printErrors(cellErr)}
                              />
                            ),
                            (v) => (
                              <ContactCSVCell value={JSON.stringify(v)} key={JSON.stringify(v)} />
                            )
                          )
                        )}
                      </tr>
                    ))
                ),
              (rows) => (
                <>
                  {rows.slice(0, itemsMax).map((row) => (
                    <tr key={JSON.stringify(row)} className="border-b last:border-b-0">
                      {props.renderParsed(row).map((value, colKey) => {
                        if (colKey === 0) {
                          const existingContact = contacts.find((c) => c.sourceId === value)
                          return existingContact ? (
                            <ExistingContactCell
                              value={value}
                              contact={existingContact}
                              key={value}
                            />
                          ) : (
                            <ContactCSVCell value={value} key={value} />
                          )
                        }
                        return <ContactCSVCell value={value} key={value} />
                      })}
                    </tr>
                  ))}
                  {rows.length > itemsMax ? (
                    <tr className="border-b last:border-b-0">
                      <td colSpan={3}>
                        <Button
                          variant="secondary"
                          label={`Show All (${rows.length})`}
                          isFullWidth
                          onClick={() => setItemsMax(Infinity)}
                        />
                      </td>
                    </tr>
                  ) : null}
                </>
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}
const ContactCSVCell = ({ value }: { value: string }) => (
  <td className="whitespace-nowrap px-2 border-r last:border-0">{value}</td>
)
const ExistingContactCell = ({ value, contact }: { value: string; contact: DealCRMContact }) => {
  const openDetailsDrawer = useContactDetailsDrawer()
  return (
    <td className="whitespace-nowrap px-2 border-r last:border-0 bg-primary-100 flex gap-1 items-center">
      <Button label={value} onClick={() => openDetailsDrawer(contact.id)} variant="hollow" />
      <InfoTooltip text="Contact exist already and will be updated" />
    </td>
  )
}
const ErrorCSVCell = ({ value }: { value: string }) => <td className="bg-danger-400">{value}</td>
