import InfoTooltip from "@components/InfoTooltip"
import { Tooltip } from "@stories/components/Antd/Tooltip/Tooltip"
import { Color } from "@stories/components/Typography/Typography"
import { MultiLayerStructureIcon } from "@stories/icons/MultiLayerStructureIcon"
import { Maybe, Nothing, nullableToMaybe } from "common/containers/Maybe"
import { Order } from "common/model/Order/Models/Wrapped"
import { OrderStructure, TradeStructure } from "common/model/Order/Types/Structure"
import { formatSPVTerms, orderStructureShortDisplayName } from "common/model/Order/Types/Terms"
import { CommaSeparatedComponent } from "src/pages/Orders/shared/Cells"
import { LiveMarketTableCell } from "../../../../pages/Orders/OrderPage/OrderLiveMarket/Cells"
import { orderDataAccessLevel } from "../../../../pages/Orders/shared/accessLevels"
import { PriceObservationType } from "common/model/data-product/pricing/PriceObservation"
import { sizeToTextSize } from "@model/components/lib/shared"

interface StructureCellProps {
  order: Pick<Order, "currentStructures" | "carry" | "managementFee" | "structureLayersCount">
  size?: "sm" | "xs"
}

export const VPPFTooltip = () => <InfoTooltip text="Variable Prepaid Forward" />
export const VPPFPriceTooltip = () => (
  <InfoTooltip text="This is the maximum price per share that an investor would pay within this agreement. A lower price per share may be paid if the share price of the issuer declines." />
)

const DisplaySPVStructure = ({
  managementFee,
  carry,
  layers,
}: {
  managementFee: Maybe<number | null>
  carry: Maybe<number | null>
  layers: Maybe<number | null>
}) => (
  <div className="flex items-center gap-1">
    <Tooltip placement="bottom" title="Management / Carry">
      {formatSPVTerms({ carry, mgmt: managementFee, structureLayersCount: Nothing })}
    </Tooltip>
    {layers.match(
      (numLayers) =>
        numLayers && numLayers > 1 ? <MultiLayerStructureIcon color={Color.Warning} /> : null,
      () => null
    )}
  </div>
)

export const StructureDisplay = (props: {
  structures: (OrderStructure | TradeStructure)[]
  managementFee: Maybe<number | null>
  carry: Maybe<number | null>
  structureLayersCount: Maybe<number | null>
  size?: "sm" | "xs" | "base"
}) => (
  <div className={`flex flex-wrap-none gap-1 ${sizeToTextSize[props.size ?? "sm"]}`}>
    {props.structures
      .map((s) =>
        s.is("variable_prepaid_forward") ? (
          <div key={s.displayName()}>
            {orderStructureShortDisplayName.variable_prepaid_forward}
            <VPPFTooltip />
          </div> // Deduplicate
        ) : (
          s.displayName()
        )
      )
      .map(
        // eslint-disable-next-line react/no-unstable-nested-components
        CommaSeparatedComponent((structure) =>
          structure === "SPV" ? (
            <DisplaySPVStructure
              managementFee={props.managementFee}
              carry={props.carry}
              layers={props.structureLayersCount}
            />
          ) : (
            structure
          )
        )
      )}
  </div>
)

export const TradeStructureDisplay = ({
  trade,
  size,
}: {
  trade: Pick<
    PriceObservationType,
    "structure" | "carriedInterest" | "managementFee" | "layeredStructure"
  >
  size?: "sm" | "xs"
}) => (
  <StructureDisplay
    structures={trade.structure.entries.map((x) => x.key).map((x) => new TradeStructure(x))}
    carry={nullableToMaybe(
      typeof trade.carriedInterest === "number" ? trade.carriedInterest : null
    )}
    managementFee={nullableToMaybe(trade.managementFee)}
    structureLayersCount={nullableToMaybe(trade.layeredStructure ? 2 : 1)}
    size={size}
  />
)

export const StructureCell = ({ order, size }: StructureCellProps) => (
  <LiveMarketTableCell accessLevel={orderDataAccessLevel.structure}>
    <StructureDisplay
      structures={order.currentStructures()}
      managementFee={order.managementFee()}
      carry={order.carry()}
      size={size || "sm"}
      structureLayersCount={order.structureLayersCount()}
    />
  </LiveMarketTableCell>
)
