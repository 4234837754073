import { CurrentUser } from "@model/CurrentUser"
import { isDataCustomer, isMarketsCustomer } from "common/model/Auth/Permissions"
import { AccountClientType } from "common/model/Account/AccountClientType"
import { useLoggedInUser } from "../../../providers/loggedInUser/useLoggedInUser"

const orderCreatorClientTypes: Record<AccountClientType, boolean> = {
  Intermediary: true,
  Lender: true,
  "Investor/Shareholder": true,
  Journalist: false,
  Employee: false,
}
const accountClientTypeCanCreateOrder = (account: CurrentUser["user"]["account"]) =>
  account.clientType.some((clientType) => orderCreatorClientTypes[clientType])

const accountProductAreaCanCreateOrder = (account: CurrentUser["user"]["account"]) =>
  isDataCustomer(account) || isMarketsCustomer(account)

const userCanCreateOrder = ({ user }: CurrentUser): boolean =>
  accountClientTypeCanCreateOrder(user.account) && accountProductAreaCanCreateOrder(user.account)

export const userCanCreateOrderFromCRM = (currentUser: CurrentUser): boolean =>
  currentUser.user.account.clientType.includes("Intermediary") && userCanCreateOrder(currentUser)

export const useAddOrderPermission = () => userCanCreateOrder(useLoggedInUser())
