import { annotate } from "../../utils/Coerce"
import { Thunk, distributeBindThunk } from "../../utils/recursion/Trampoline"
import { tail, unappend } from "../../utils/data/Array/ArrayUtils"
import { Identity } from "../../containers/Identity"
import { Either, Left, Right } from "../../containers/Either"
import { Just, Maybe, Nothing } from "../../containers/Maybe"
import {
  EOF,
  ParseErrors,
  addMessage,
  concatErrors,
  replaceMessages,
  unexpectedTokenMessage,
} from "../ParseErrors"
import { SourcePosition, stepSourcePositionByChar } from "../SourcePosition"
import { UnsafeRec } from "../../utils/RecordUtils"
import { Nonempty } from "../../utils/data/Array/Nonempty"
import { throwWithMessage } from "./throwWithMessage"

// These should be type arguments to Parser, but that requires HKTs
// TODO: look into ts-macros
/** To run a parser over a different effect:
 * - copy the contents of this file
 * - replace Eff and Source with appropriate substitutes.
 * - Replace the functions in source and underlying with implementations for your new Eff and Source types. Do not modify the type definitions.
 * */
namespace Params {
  export type Eff<T> = Identity<T>
  export type Stream<Input> = Input[]
  export const source = {
    unroll: <I>(x: Stream<I>): Eff<Maybe<readonly [I, Stream<I>]>> => underlying.pure(unappend(x)),
  }
  export const underlying = {
    pure: <T>(x: T): Eff<T> => Identity.pure(x),
    map: <S, T>(s: Eff<S>, f: (s: S) => T) => s.map(f),
    bind: <S, T>(s: Eff<S>, f: (s: S) => Eff<T>) => s.bind(f),
  }
}
const dethunk = <X>(x: Params.Eff<Thunk<Params.Eff<X>>>): Thunk<Params.Eff<X>> =>
  new Thunk(() => x.value, "dethunk") // TODO generalize
type ParserState<out Input, out State> = {
  currentInput: Params.Stream<Input>
  state: State
  position: SourcePosition
}
type ParserResult<Input, State, Output> = Either<
  ParseErrors,
  { result: Output; newState: ParserState<Input, State>; err: ParseErrors }
>
type ParserStep<Input, State, Output> = Thunk<
  Params.Eff<{
    result: Params.Eff<ParserResult<Input, State, Output>>
    /** Tracking this explicitly allows us to make backtracking (which can be arbitrarily bad for performance) opt-in.
     * In principle it also allow us to garbage-collect the start of the input stream early (useful for large files, for instance), but the current implementation isn't lazy enough for that.
     */
    consumedInput: boolean
  }>
>

/** This is equivalent to a function from `ParserState` to a sum over the possible results of running a parser:
 *
 *      ParserState =>
 *      | [Output, ParserState, ParserErrors, consumed input: true]
 *      | [ParserErrors, consumed input: true]
 *      | [Output, ParserState, ParserErrors, consumed input: false]
 *      | [ParserErrors, consumed input: false]
 *
 * but unlike the encoding above, can be easily modified to add constraints on `B`.
 */
type RawParser<in out Input, in out State, out Output> = <B>(
  state: ParserState<Input, State>,
  IfOkAndConsumedInput: Thunk<
    (o: Output, s: ParserState<Input, State>, err: ParseErrors) => Thunk<Params.Eff<B>>
  >,
  IfErrAndConsumedInput: Thunk<(err: ParseErrors) => Thunk<Params.Eff<B>>>,
  IfOkAndDidNotConsumeInput: Thunk<
    (o: Output, s: ParserState<Input, State>, err: ParseErrors) => Thunk<Params.Eff<B>>
  >,
  IfErrAndDidNotConsumeInput: Thunk<(err: ParseErrors) => Thunk<Params.Eff<B>>>
) => Thunk<Params.Eff<B>>

// differs from the above in that it forces evaluation of its arguments
type StrictRawParser<in out Input, in out State, out Output> = <B>(
  state: ParserState<Input, State>,
  IfOkAndConsumedInput: (
    o: Output,
    s: ParserState<Input, State>,
    err: ParseErrors
  ) => Thunk<Params.Eff<B>>,
  IfErrAndConsumedInput: (err: ParseErrors) => Thunk<Params.Eff<B>>,
  IfOkAndDidNotConsumeInput: (
    o: Output,
    s: ParserState<Input, State>,
    err: ParseErrors
  ) => Thunk<Params.Eff<B>>,
  IfErrAndDidNotConsumeInput: (err: ParseErrors) => Thunk<Params.Eff<B>>
) => Thunk<Params.Eff<B>>

const stepRawParser = <Input, State, Output>(
  raw: RawParser<Input, State, Output>,
  state: ParserState<Input, State>
): ParserStep<Input, State, Output> => {
  const okCons = Thunk.pure((o: Output, s: ParserState<Input, State>, err: ParseErrors) =>
    Thunk.pure(
      Params.underlying.pure({
        result: Params.underlying.pure(Right({ result: o, newState: s, err })),
        consumedInput: true,
      })
    )
  )
  const errCons = Thunk.pure((err: ParseErrors) =>
    Thunk.pure(
      Params.underlying.pure({
        result: Params.underlying.pure(annotate<ParserResult<Input, State, Output>>(Left(err))),
        consumedInput: true,
      })
    )
  )
  const okNoCons = Thunk.pure((o: Output, s: ParserState<Input, State>, err: ParseErrors) =>
    Thunk.pure(
      Params.underlying.pure({
        result: Params.underlying.pure(Right({ result: o, newState: s, err })),
        consumedInput: false,
      })
    )
  )
  const errNoCons = Thunk.pure((err: ParseErrors) =>
    Thunk.pure(
      Params.underlying.pure({
        result: Params.underlying.pure(Left(err)),
        consumedInput: false,
      })
    )
  )
  return new Thunk(() => raw(state, okCons, errCons, okNoCons, errNoCons), "parser step")
}

/** Emulates syntactic constructs in other languages like
 *      const x = let y = 5 in y + 7 // 12
 *  that allow for local definitions in expressions
 */
const letIn = <R, T>(r: R, f: (vals: R) => T) => f(r)

export class Parser<Input, State, out Output> {
  constructor(private readonly raw: RawParser<Input, State, Output>) {}

  private eagerRaw<B>(
    state: ParserState<Input, State>,
    IfOkAndConsumedInput: (
      o: Output,
      s: ParserState<Input, State>,
      err: ParseErrors
    ) => Thunk<Params.Eff<B>>,
    IfErrAndConsumedInput: (err: ParseErrors) => Thunk<Params.Eff<B>>,
    IfOkAndDidNotConsumeInput: (
      o: Output,
      s: ParserState<Input, State>,
      err: ParseErrors
    ) => Thunk<Params.Eff<B>>,
    IfErrAndDidNotConsumeInput: (err: ParseErrors) => Thunk<Params.Eff<B>>
  ) {
    return this.raw<B>(
      state,
      Thunk.pure(IfOkAndConsumedInput),
      Thunk.pure(IfErrAndConsumedInput),
      Thunk.pure(IfOkAndDidNotConsumeInput),
      Thunk.pure(IfErrAndDidNotConsumeInput)
    )
  }

  static Eager<I, S, O>(raw: StrictRawParser<I, S, O>) {
    return new Parser<I, S, O>((s, ok, err, okNoCons, errNoCons) =>
      raw(
        s,
        distributeBindThunk(ok),
        distributeBindThunk(err),
        distributeBindThunk(okNoCons),
        distributeBindThunk(errNoCons)
      )
    )
  }

  /** Run the parser with a given initial state and input stream */
  run(state: State, input: Params.Stream<Input>): Params.Eff<Either<ParseErrors, Output>> {
    const parserState: ParserState<Input, State> = {
      currentInput: input,
      state,
      position: { char: 1, line: 1 },
    }
    const stepped = stepRawParser(this.raw, parserState)
    return stepped
      .map((s) =>
        Params.underlying.bind(s, ({ result }) =>
          Params.underlying.map(result, (r) => r.map((x) => x.result))
        )
      )
      .force()
  }

  /** Run the parser and discard the results, returning only whether the input was accepted */
  accepts(state: State, input: Params.Stream<Input>) {
    return this.run(state, input).value.isRight()
  }

  /** Returns a parser which succeeds only if this one fails. Does not consume input or return values.
   * Should generally be used with custom error messages, as it provides none of its own.
   */
  negate() {
    return Parser.Eager<Input, State, null>((s, okCons, errCons, okNoCons, errNoCons) => {
      const errorMessages = { position: s.position, messages: [] }
      return this.eagerRaw(
        s,
        () => errNoCons(errorMessages),
        () => okNoCons(null, s, errorMessages),
        () => errNoCons(errorMessages),
        () => okNoCons(null, s, errorMessages)
      )
    })
  }

  map<O2>(f: (o: Output) => O2): Parser<Input, State, O2> {
    return new Parser<Input, State, O2>((s, okCons, errCons, okNoCons, errNoCons) =>
      this.raw(
        s,
        Thunk.pure((o, st, e) => okCons.bind((g) => g(f(o), st, e))),
        errCons,
        Thunk.pure((o, st, e) => okNoCons.bind((g) => g(f(o), st, e))),
        errNoCons
      )
    )
  }

  /** Run this parser, then use the result to pick a parser to run on the remaining input */
  bind<O2>(f: (o: Output) => Parser<Input, State, O2>): Parser<Input, State, O2> {
    return new Parser((s, okCons, errCons, okNoCons, errNoCons) => {
      const okCons2 = (x: Output, s2: ParserState<Input, State>, errs: ParseErrors) => {
        if (errs.messages.length === 0) {
          return f(x).raw(s2, okCons, errCons, okCons, errCons)
        } else {
          return f(x).raw(
            s2,
            okCons,
            errCons,
            // If either `this` or `f(x)` consume input, then so does the composite parser, so in this branch we throw out the noCons cases.
            Thunk.pure((y, s3, errs2) => okCons.bind((g) => g(y, s3, concatErrors(errs, errs2)))),
            Thunk.pure((errs2) => errCons.bind((g) => g(concatErrors(errs, errs2))))
          )
        }
      }
      const okNoCons2 = (x: Output, s2: ParserState<Input, State>, errs: ParseErrors) => {
        if (errs.messages.length === 0) {
          return f(x).raw(s2, okCons, errCons, okNoCons, errNoCons)
        } else {
          return f(x).raw(
            s2,
            okCons,
            errCons,
            Thunk.pure((y, s3, errs2) => okNoCons.bind((g) => g(y, s3, concatErrors(errs, errs2)))),
            Thunk.pure((errs2) => errNoCons.bind((g) => g(concatErrors(errs, errs2))))
          )
        }
      }
      return this.raw(s, Thunk.pure(okCons2), errCons, Thunk.pure(okNoCons2), errNoCons)
    })
  }

  /** Run this parser, then run `x` on the remaining input. */
  alongside<O2>(x: Parser<Input, State, O2>): Parser<Input, State, readonly [Output, O2]> {
    return this.bind((o) => x.map((o2) => [o, o2] as const))
  }

  /** Run this parser, then run `x` on the remaining input while ignoring its output */
  skip<O2>(x: Parser<Input, State, O2>): Parser<Input, State, Output> {
    return this.alongside(x).map((o) => o[0])
  }

  // todo find a better name for this
  /** Run this parser, ignore its output, and run `x` on the remaining input. */
  dropUntil<O2>(x: Parser<Input, State, O2>): Parser<Input, State, O2> {
    return this.alongside(x).map((o) => o[1])
  }

  static pure<I, S, T>(t: T): Parser<I, S, T> {
    return Parser.Eager(
      (s, _, __, okNoCons, ___) =>
        new Thunk(() => okNoCons(t, s, { position: s.position, messages: [] }), "parser pure")
    )
  }

  /** If this parser fails immediately, then run `fallback` instead. */
  or<O2 = Output>(fallback: Parser<Input, State, O2>): Parser<Input, State, Output | O2> {
    return new Parser((s, ok, err, okNoCons, errNoCons) => {
      const newErrNoCons = (errs: ParseErrors) => {
        const fallbackNoCons = (o: O2, s2: ParserState<Input, State>, errs2: ParseErrors) =>
          okNoCons.bind((g) => g(o, s2, concatErrors(errs, errs2)))
        const fallbackErrNoCons = (errs2: ParseErrors) =>
          errNoCons.bind((g) => g(concatErrors(errs, errs2)))
        return fallback.raw(s, ok, err, Thunk.pure(fallbackNoCons), Thunk.pure(fallbackErrNoCons))
      }
      return this.raw(s, ok, err, okNoCons, Thunk.pure(newErrNoCons))
    })
  }

  /** Causes this parser to revert back to its initial position if it fails. */
  backtrack() {
    return new Parser<Input, State, Output>((s, ok, err, okNoCons, errNoCons) =>
      this.raw(s, ok, errNoCons, okNoCons, errNoCons)
    )
  }

  /** Causes this parser to revert back to its initial position if it succeeds */
  lookahead() {
    return Parser.Eager<Input, State, Output>((s, _, err, okNoCons, errNoCons) =>
      this.eagerRaw(
        s,
        (o, __, ___) => okNoCons(o, s, { position: s.position, messages: [] }),
        err,
        okNoCons,
        errNoCons
      )
    )
  }

  repeat() {
    return new Parser<Input, State, Output[]>((s, ok, err, okNoCons, _) => {
      type Helper = (
        acc: Output[]
      ) => Thunk<
        (
          next: Output,
          s2: ParserState<Input, State>,
          __: ParseErrors
        ) => ReturnType<ReturnType<(typeof ok)["force"]>>
      >
      const f: Helper = (acc: Output[]) =>
        new Thunk(
          () => (next, s2, ___) =>
            this.raw(
              s2,
              f(acc.concat([next])),
              err,
              Thunk.pure(
                throwWithMessage(
                  "`repeat`ing a parser that accepts an empty string will result in an infinite loop"
                )
              ),
              Thunk.pure((errs) => distributeBindThunk(ok)(acc.concat([next]), s2, errs))
            ),
          "repeatHelper"
        )

      return this.raw(
        s,
        f([]),
        err,
        Thunk.pure(
          throwWithMessage(
            "`repeat`ing a parser that accepts an empty string will result in an infinite loop"
          )
        ),
        Thunk.pure((errs) => distributeBindThunk(okNoCons)([], s, errs))
      )
    })
  }

  repeatAtLeastOnce(): Parser<Input, State, Nonempty<Output>> {
    return this.alongside(this.repeat()).map(([h, t]) => [h, ...t])
  }

  repeatUntil(sep: Parser<Input, State, Output>) {
    return this.repeat().skip(sep)
  }

  repeatExactly(n: number): Parser<Input, State, Output[]> {
    if (n <= 1) {
      return this.map((x) => [x])
    } else {
      return this.alongside(this.repeatExactly(n - 1)).map(([x, y]) => [x, ...y])
    }
  }

  /** If the parser fails, transform its error messages with `f` */
  mapError(f: (err: ParseErrors) => ParseErrors) {
    return Parser.Eager<Input, State, Output>((s, ok, err, okNoCons, errNoCons) => {
      const newErr = (errs: ParseErrors) => err(f(errs))
      const newErrNoCons = (errs: ParseErrors) => errNoCons(f(errs))
      const newOk = (...[o, state, errs]: Parameters<typeof ok>) => ok(o, state, f(errs))
      return this.eagerRaw(s, newOk, newErr, okNoCons, newErrNoCons)
    })
  }

  optional(): Parser<Input, State, Maybe<Output>> {
    return Parser.Eager<Input, State, Maybe<Output>>((s, ok, err, okNoCons, errNoCons) =>
      this.eagerRaw(
        s,
        (o, st, e) => ok(Just(o), st, e),
        err,
        (o, st, e) => okNoCons(Just(o), st, e),
        (e) => okNoCons(Nothing, s, e)
      )
    )
  }

  overArray(sep: Parser<Input, State, unknown>): Parser<Input, State, Output[]> {
    return this.skip(sep.optional()).repeat()
  }

  overNonemptyArray(sep: Parser<Input, State, unknown>): Parser<Input, State, Nonempty<Output>> {
    return this.skip(sep.optional()).repeatAtLeastOnce()
  }

  static overRecord<I, S, R>(
    fields: { [key in keyof R]: Parser<I, S, R[key]> },
    fieldSep: Parser<I, S, I>
  ): Parser<I, S, R> {
    const entries = UnsafeRec.entries(fields)
    if (entries.length === 0) {
      throw new Error("Tried to construct parser for empty object")
    }
    const result: Parser<
      I,
      S,
      {
        [x: string]: R[keyof R]
      }
    > = entries
      .map(([k, v]) => v.map((r) => ({ [k]: r })))
      .reduce((prev, cur) =>
        prev
          .skip(fieldSep)
          .alongside(cur)
          .map(([l, r]) => ({ ...l, ...r }))
      )
    return result as Parser<I, S, R> // ts doesn't know that we're not dropping keys
  }
}

export namespace Parse {
  export namespace Prim {
    /** A parser that always fails with a given error message */
    export const fail = (message: string) =>
      Parser.Eager(
        (s, _, __, ___, doneerr) =>
          new Thunk(() => doneerr({ position: s.position, messages: [message] }), "fail")
      )
    /** Low-level primitive for parsing a single token */
    export const token = <T, S, O>(
      showToken: (t: T) => string /** For error messages */,
      tokenSize: (t: T, pos: SourcePosition, input: Params.Stream<T>) => SourcePosition,
      tryParseToken: (t: T) => Maybe<O>
    ): Parser<T, S, O> =>
      new Parser((s, ok, err, okNoCons, errNoCons) => {
        const unrolled = Params.source.unroll(s.currentInput)
        const result = Params.underlying.bind(unrolled, (maybeNext) =>
          Params.underlying.pure(
            maybeNext.match(
              ([h, t]) =>
                tryParseToken(h).match(
                  (parsed) => {
                    const nextPos = tokenSize(h, s.position, t)
                    const nextState: ParserState<T, S> = {
                      position: nextPos,
                      currentInput: t,
                      state: s.state,
                    }
                    return ok.bind((g) =>
                      g(parsed, nextState, { position: s.position, messages: [] })
                    )
                  },
                  () =>
                    errNoCons.bind((g) =>
                      g({
                        messages: [unexpectedTokenMessage(showToken(h))],
                        position: s.position,
                      })
                    )
                ),
              () =>
                errNoCons.bind((g) =>
                  g({ messages: ["Unexpected end of input."], position: s.position })
                )
            )
          )
        )
        return dethunk(result)
      })

    /** Low-level primitive for parsing a fixed sequence of tokens */
    export const tokens = <T extends S, S = T>(
      showToken: (t: S) => string /** For error messages */,
      tokenSize: (t: T[], pos: SourcePosition) => SourcePosition,
      tokenList: T[],
      tokenEquality: (l: S, r: T) => boolean = (l, r) => l === r
    ): Parser<S, {}, T[]> =>
      new Parser<S, {}, T[]>((s, ok, err, okNoCons, errNoCons) => {
        // Error.stackTraceLimit = 1e10
        type B = ReturnType<ReturnType<(typeof err)["force"]>> extends Thunk<Params.Eff<infer X>>
          ? X
          : never
        const worker: (toks: T[], input: Params.Stream<S>) => Thunk<Params.Eff<B>> = (
          toks: T[],
          input: Params.Stream<S>
        ): Thunk<Params.Eff<B>> => {
          if (toks.length === 0) {
            const newpos = tokenSize(tokenList, s.position)
            return new Thunk(
              () =>
                ok.bind((g) =>
                  g(
                    tokenList,
                    { currentInput: input, state: s.state, position: newpos },
                    { messages: [], position: newpos }
                  )
                ),
              "tokens base case"
            )
          } else {
            const u = Params.source.unroll(input)
            const bound = Params.underlying.map(u, (maybeNext) =>
              maybeNext.match(
                ([h, t]) => {
                  if (tokenEquality(h, toks[0])) {
                    return worker(tail(toks), t)
                  } else {
                    return errNoCons.bind((g) =>
                      g({
                        messages: [unexpectedTokenMessage(showToken(h), showToken(toks[0]))],
                        position: s.position,
                      })
                    )
                  }
                },
                () => errNoCons.bind((g) => g(EOF(s.position)))
              )
            )

            return dethunk(bound)
          }
        }
        return worker(tokenList, s.currentInput)
      })
  }

  /** Parse a single character. */
  export const char = (c: string) =>
    Prim.token<string, {}, string>(
      (x) => x,
      (ch, pos, _) => stepSourcePositionByChar(ch, pos),
      (t) => (t === c ? Just(t) : Nothing)
    )

  export namespace Char {
    /** Parse any character satisfying a given predicate */
    export const satisfying = (c: (char: string) => boolean) =>
      Prim.token<string, {}, string>(
        (x) => x,
        (ch, pos, _) => stepSourcePositionByChar(ch, pos),
        (t) => (c(t) ? Just(t) : Nothing)
      )

    /** Parse a single lowercase alphabetical character */
    export const lowercase = satisfying((a) => /^[a-z]$/.test(a))

    /** Parse a single uppercase alphabetical character */
    export const uppercase = satisfying((a) => /^[A-Z]$/.test(a))

    /** Parse a single alphabetical character */
    export const alphabetical = lowercase.or(uppercase)

    /** Parse a single numeric character */
    export const numeric = satisfying((a) => /^[0-9]$/.test(a))

    /** Parse a single alphanumeric character */
    export const alphanumeric = alphabetical.or(numeric)

    /** Parse a single whitespace character */
    export const whitespace = satisfying((a) => /^\s$/.test(a))

    export const emailSymbol = satisfying((c) => "!#$%&'*+-/=?^_`{|}~".includes(c))
  }
  export namespace Numbers {
    export const nat = Char.numeric.repeat().map((x) => Number.parseInt(x.join(""), 10))
    export const int = nat.or(
      char("-")
        .alongside(nat)
        .map((x) => -1 * x[1])
    )
  }

  /** Parse a given string */
  export const string = <S extends string>(c: S): Parser<string, {}, S> =>
    Prim.tokens<string, string>(
      (t) => t,
      (s, pos) => s.reduce((acc, next) => stepSourcePositionByChar(next, acc), pos),
      Array.from(c)
    ).map((x) => x.join("") as S) // we're reconstructing the string from a list of its characters, so any literal types are preserved

  export namespace String {
    /** Parse a given string, ignoring case */
    export const caseInsensitive = (c: string): Parser<string, {}, string> =>
      Prim.tokens(
        (t) => t,
        (s, pos) => s.reduce((acc, next) => stepSourcePositionByChar(next, acc), pos),
        Array.from(c),
        (l, r) => l.toLowerCase() === r.toLowerCase()
      ).map((x) => x.join(""))

    export const email = letIn(
      {
        localPart: Char.alphanumeric.or(Char.emailSymbol).or(char(".")).repeat(),
        domainName: Char.alphanumeric.or(Char.emailSymbol).repeat(),
        domainSuffix: Char.alphanumeric.repeat() /** todo */,
      },
      (x) =>
        x.localPart
          .alongside(char("@"))
          .alongside(x.domainName.alongside(char(".")).backtrack().repeat())
          .alongside(x.domainSuffix)
          .map((s) => s.flat(10).join(""))
          .mapError(replaceMessages("Expected a valid email"))
    )

    export const oneOf = <O extends string>(strings: readonly O[]) =>
      strings
        .map((s) => Parse.string(s))
        .reduce((l, r) => l.or(r))
        .mapError(replaceMessages(`Expected one of ${strings.join(", ")}`))

    export const freeText = Parse.Char.alphanumeric
          .or(Parse.Char.emailSymbol)
          .or(Parse.char(" "))
          .repeatAtLeastOnce()
          .map((s) => s.join("").trim())
          .mapError(replaceMessages("Expected only letters, numbers, symbols, and spaces")) // todo remove punctuation and handle including it in headers properly

    export const freeMultilineText = Parse.Char.alphanumeric
      .or(Parse.Char.whitespace)
      .or(Parse.Char.emailSymbol)
      .repeatAtLeastOnce()
      .map((s) => s.join("").trim())
      .mapError(addMessage("Expected only letters, numbers, and whitespace"))
  }

  export namespace Boolean {
    export const trueish = ["yes", "y", "true"]
      .map((x) => String.caseInsensitive(x))
      .reduce((l, r) => l.or(r))
      .map(() => true)
      .backtrack()
      .mapError(addMessage("Expected 'yes', 'y', or 'true'"))

    export const falseish = ["no", "n", "false"]
      .map((x) => String.caseInsensitive(x))
      .reduce((l, r) => l.or(r))
      .map(() => false)
      .backtrack()
      .mapError(addMessage("Expected 'no', 'n', or 'false'"))
    export const booleanish = trueish.or(falseish)
  }

  export namespace Dates {
    // Date conflicts with the native object
    export const fourDigitYear = Char.numeric.repeatExactly(4).map((x) => x.join(""))
    export const twoDigitMonth = char("0")
      .alongside(Char.satisfying((c) => ["1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(c)))
      .or(char("1").alongside(char("0").or(char("1")).or(char("2"))))
      .map((x) => x.join(""))
      .backtrack()
      .mapError(addMessage("Expected a two-digit numeric month between 01 and 12"))
    export const numericDayOfMonth = char("0")
      .or(char("1"))
      .or(char("2"))
      .alongside(Char.numeric)
      .map((x) => x.join(""))
      .or(string("30"))
      .or(string("31"))
      .mapError(addMessage("Expected a two-digit numeric day between 01 and 31")) // todo correct counts
    const dateSep = char("-").or(char("/")).or(char("\\"))
    export const isoDate = fourDigitYear
      .skip(dateSep)
      .alongside(twoDigitMonth)
      .skip(dateSep)
      .alongside(numericDayOfMonth)
      .map(
        ([[yyyy, mm], dd]) =>
          new Date(Number.parseInt(yyyy, 10), Number.parseInt(mm, 10) - 1, Number.parseInt(dd, 10))
      )
      .mapError(addMessage("Expected date in YYYY/MM/DD format"))
  }
  export const endOfInput = Parse.Char.satisfying(() => true).negate()
  const csvLineSep = Parse.char(";").or(Parse.char("\n")).or(Parse.char("\r")).repeatAtLeastOnce()

  export namespace CSV {
    const csvCellSep = Parse.char(",")

    // TODO: support recoverable parsing in a not terrible way
    const fallbackCellParser = Parse.Char.satisfying(
      (c) => !csvLineSep.accepts({}, Array.from(c)) && !csvCellSep.accepts({}, Array.from(c))
    )
      .repeatAtLeastOnce()
      .map((s) => s.join(""))

    export const unformatted = fallbackCellParser
      .overNonemptyArray(Parse.char(","))
      .overArray(csvLineSep)
  }

  export const csv = <R>(fieldParsers: { [key in keyof R]: Parser<string, {}, R[key]> }) =>
    Parser.overRecord(fieldParsers, Parse.char(",")).overArray(csvLineSep).skip(endOfInput)
  export const csvWithOptionalHeader = <R>(
    fieldParsers: { [key in keyof R]: Parser<string, {}, R[key]> },
    headerRowParser: Parser<string, {}, unknown>
  ) => headerRowParser.alongside(csvLineSep).backtrack().optional().dropUntil(csv(fieldParsers))
  export const csvWithRequiredHeader = <R>(
    fieldParsers: { [key in keyof R]: Parser<string, {}, R[key]> },
    headerRowParser: Parser<string, {}, unknown>
  ) => headerRowParser.alongside(csvLineSep).dropUntil(csv(fieldParsers))
}
