const streamHandler: <T>() => ProxyHandler<Stream<T>> = () => ({
  get: (target, ix) => {
    const parsed = Number.parseInt(ix.toString(), 10)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, rulesdir/no-assert
    return Number.isInteger(parsed) ? target.index(parsed) : target[ix as keyof typeof target] // this may fail, but we want to make sure it fails the same way it would without a proxy
  },
})

/** an extremely naive stream implementation */
export class Stream<T> {
  index: (i: number) => T

  private constructor(index: (i: number) => T) {
    this.index = index
  }

  [ix: number]: T

  static tabulate = <X>(index: (i: number) => X) => {
    const raw = new Stream<X>(index)
    return new Proxy(raw, streamHandler())
  }

  static unfold = <X>(seed: X, step: (x: X) => X) => {
    const cache = [seed]
    const raw = new Stream<X>((i) => {
      while (i >= cache.length) {
        cache.push(step(cache[cache.length - 1]))
      }
      return cache[i]
    })
    return new Proxy(raw, streamHandler())
  }

  static repeat = <X>(val: X) => Stream.tabulate(() => val)

  take(count: number) {
    const result: T[] = []
    // eslint-disable-next-line rulesdir/no-let, no-plusplus, better-mutation/no-mutation
    for (let i = 0; i < count; i++) {
      result.push(this.index(i))
    }
    return result
  }

  map<S>(f: (t: T) => S) {
    return Stream.tabulate((x) => f(this.index(x))) // not a very good implementation
  }
}

export const nats = Stream.tabulate((x) => x)

export const iterate = <T>(f: (t: T) => T, init: T, count: number) =>
  Stream.unfold(init, f).index(count)
