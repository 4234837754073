import { OrderCard } from "@components/ItemCards/OrderCard"
import { LiveMarketOrderUpdatedEvent } from "common/model/AccountActivityLog/AccountActivityLog"
import React from "react"

interface LiveMarketOrderUpdatedDetailsProps {
  event: LiveMarketOrderUpdatedEvent
}

export const LiveMarketOrderUpdatedDetails: React.FC<LiveMarketOrderUpdatedDetailsProps> = ({
  event,
}) => {
  const { order } = event

  return <OrderCard order={order} hideDate />
}
