import Typography, { Color, Font, Size, Weight } from "@stories/components/Typography/Typography"

interface SubtitleProps {
  subtitle: React.ReactNode
}

export const Subtitle = ({ subtitle }: SubtitleProps): JSX.Element => (
  <Typography
    color={Color.Black}
    font={Font.Inter}
    size={Size.Small}
    text={subtitle}
    weight={Weight.Semibold}
  />
)
