import Table from "@stories/components/Table/Table"
import { useOwnGmailMessages } from "./Admin/useLiveGmailAuthTokens"
import TableSkeletonLoader from "@components/loaders/TableSkeletonLoader"
import { isLoading } from "common/utils/Loading"
import { createColumnHelper } from "@tanstack/react-table"
import { GmailMessage } from "common/model/Google/GmailMessage"
import { dateTimeFormat } from "common/utils/dateUtils"

const columnHelper = createColumnHelper<GmailMessage>()
const adminInternalMessagesColumns = [
  columnHelper.display({
    header: "id",
    cell: (context) => context.row.original.id,
  }),
  columnHelper.accessor(
    (message) => new Date(Number.parseInt(message.data.internalDate ?? "0", 10)),
    {
      header: "date",
      cell: (context) => dateTimeFormat(context.getValue()),
    }
  ),
  columnHelper.display({
    header: "Snippet",
    cell: (context) => context.row.original.data.snippet?.slice(0, 60) ?? "null",
  }),
]

export const InboxPage = () => {
  const messages = useOwnGmailMessages() ?? []
  return isLoading(messages) ? (
    <TableSkeletonLoader numRows={5} />
  ) : (
    <Table
      columns={adminInternalMessagesColumns}
      data={messages}
      defaultPaginationPageSize={30}
      isLoading={isLoading(messages)}
      nameOfTableRecord="Message"
      nameOfTableRecords="Messages"
      defaultSortBy="date"
      defaultSortDirection="asc"
    />
  )
}
