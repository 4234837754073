import { wrapIcon } from "./IconWrapper"

export const WatchlistIcon = wrapIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.8835 14.6667L4.96683 9.98333L1.3335 6.83333L6.1335 6.41667L8.00016 2L9.86683 6.41667L14.6668 6.83333L11.0335 9.98333L12.1168 14.6667L8.00016 12.1833L3.8835 14.6667Z"
      fill="currentColor"
    />
  </svg>
)
