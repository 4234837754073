import { matchLoading, lift2Loading, Loading } from "common/utils/Loading"
import { checkAccountQuotaCompliance } from "common/model/Order/QuotaCompliance/checkAccountQuotaCompliance"
import { AccountOrderQuotaFields } from "common/model/AccountOrderQuotaFields"
import { Order } from "common/model/Order/Order"
import { identity } from "common/utils/fp/Function"
import { useMemo } from "react"

export type OrderQuotaComplianceData = {
  quotaCompliantOrderCount: Loading<number>
  quotaCompliantOrderVolume: Loading<number>
  isAccountCompliantWithOrderQuotaThresholds: Loading<boolean>
}

export const initialOrderQuotaComplianceData: OrderQuotaComplianceData = {
  quotaCompliantOrderCount: "loading",
  quotaCompliantOrderVolume: "loading",
  isAccountCompliantWithOrderQuotaThresholds: "loading",
}

export const useAccountQuotaCompliantOrders = (
  account: AccountOrderQuotaFields,
  loadingOrders: Loading<Order[]>,
  loadingDarkpoolOrders: Loading<Order[]>
): OrderQuotaComplianceData => {
  const loadingIsAccountQuotaCompliantGivenOrders = useMemo(
    () =>
      lift2Loading<Order[], Order[], OrderQuotaComplianceData>(
        (orders, darkpoolOrders) => checkAccountQuotaCompliance(account, orders, darkpoolOrders),
        loadingOrders,
        loadingDarkpoolOrders
      ),
    [loadingOrders, loadingDarkpoolOrders, account]
  )

  return useMemo(
    () =>
      matchLoading(
        loadingIsAccountQuotaCompliantGivenOrders,
        identity,
        initialOrderQuotaComplianceData,
        {
          quotaCompliantOrderCount: 0,
          quotaCompliantOrderVolume: 0,
          isAccountCompliantWithOrderQuotaThresholds: true,
        }
      ),
    [loadingIsAccountQuotaCompliantGivenOrders]
  )
}
