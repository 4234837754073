import { AccessControl } from "../AccessControl"

export const eliteAccessControl: AccessControl<"elite"> = {
  tier: "elite",
  features: {
    // live market
    liveMarketParticipation: "full",
    orderHistoryData: "full",
    currentMarketPriceData: "full",
    contributeTradeData: "full",
    companyNewsData: "full",
    fundingRoundsPage: "full",
    fundingRoundsData: "full",
    marketInsightsPage: "full",
    rfqData: "full",

    // company page
    companyPageFundingRoundsTable: "full",
    companyPage409aValuationsTable: "full",
    companyPageFilingsTable: "full",
    companyPageInvestorsTable: "full",
    companyPageMutualFundMarksTable: "full",
    companyPageBidOfferVolumeRatioChart: "full",
    companyPageTradingContextSection: "full",
    companyPageResearchReportsSection: "full",
    companyPageComparablesSection: "full",
    companyPageShare: "full",
    companyPageFlagIssue: "full",
    companyPageExport: "full",

    // trade history
    tradeHistoryPage: "full",
    tradeHistoryPricing: "full",
    tradeHistoryDates: "full",
    tradeHistoryStructure: "full",
    tradeHistoryShareClass: "full",

    // sectors page
    sectorsData: "full",
  },
}
