import _ from "lodash"
import { Company, CompanyIdFields } from "common/model/Company"
import { splitAdjustPriceToToday } from "common/model/data-product/valuationCalculation/Valuation"
import { deprecatedIsLoaded } from "common/utils/Loading"
import moment from "moment"
import { useUnsafeMemo } from "src/utils/hooks/effects/useEffectSafe"
import { useCompanyValuation } from "./useCompanyValuation"
import { useLocalTargetIssuer } from "./useLocalTargetIssuer"
import { UnsafeRec } from "common/utils/RecordUtils"
import { head } from "common/utils/data/Array/ArrayUtils"

export const useMostRecentMarketPPS = (company: CompanyIdFields) => {
  const firestoreIssuer = useLocalTargetIssuer(company)
  const { valuationFromPPS } = useCompanyValuation(company)
  return useUnsafeMemo(
    () => () => {
      if (!deprecatedIsLoaded(firestoreIssuer)) return undefined
      const { settings, priceEstimatesSummary } = firestoreIssuer
      const stockSplitsData = firestoreIssuer.stockSplits.map((split) => split.splitData)

      const latestPrice = head(
        _.orderBy(
          UnsafeRec.values(
            _.omit(priceEstimatesSummary, [
              "updatedAt",
              "reasonNotAvailable",
              "metaInformation",
              "lastModelRunAt",
            ])
          ).map((snapshot) => ({ date: snapshot.date, price: snapshot.priceEstimatePPS })),
          (x) => x.date,
          "desc"
        )
      ).toNullable()

      if (
        settings?.useCaplightPriceEstimateAsCurrentMarket &&
        !!latestPrice &&
        priceEstimatesSummary?.lastModelRunAt
      ) {
        const pps = latestPrice.price
        return {
          pps: splitAdjustPriceToToday(pps, latestPrice.date, stockSplitsData),
          date: latestPrice.date,
          dateDaysAgo: moment().diff(latestPrice.date, "days"),
          source: "MarketPrice",
          val: valuationFromPPS({
            price: pps,
            priceDate: latestPrice.date,
            // marketPrice is split adjusted even historically, using the information available on the day of the model run
            splitAdjustedForDate: priceEstimatesSummary?.lastModelRunAt,
            isSplitAdjusted: true,
          }).toNullable(),
        }
      }
      return undefined
    },
    [firestoreIssuer, valuationFromPPS]
  )
}

export const useMostRecentMarketPPSWithoutRedux = (company: Company) => {
  const { valuationFromPPS } = useCompanyValuation(company)
  return useUnsafeMemo(
    () => () => {
      if (!deprecatedIsLoaded(company)) return undefined
      const { settings, priceEstimatesSummary } = company
      const stockSplitsData = company.stockSplits.map((split) => split.splitData)

      const latestPrice = head(
        _.orderBy(
          UnsafeRec.values(
            _.omit(priceEstimatesSummary, [
              "updatedAt",
              "reasonNotAvailable",
              "metaInformation",
              "lastModelRunAt",
            ])
          ).map((snapshot) => ({ date: snapshot.date, price: snapshot.priceEstimatePPS })),
          (x) => x.date,
          "desc"
        )
      ).toNullable()

      if (
        settings?.useCaplightPriceEstimateAsCurrentMarket &&
        !!latestPrice &&
        priceEstimatesSummary?.lastModelRunAt
      ) {
        const pps = latestPrice.price
        return {
          pps: splitAdjustPriceToToday(pps, latestPrice.date, stockSplitsData),
          date: latestPrice.date,
          dateDaysAgo: moment().diff(latestPrice.date, "days"),
          source: "MarketPrice",
          val: valuationFromPPS({
            price: pps,
            priceDate: latestPrice.date,
            // marketPrice is split adjusted even historically, using the information available on the day of the model run
            splitAdjustedForDate: priceEstimatesSummary?.lastModelRunAt,
            isSplitAdjusted: true,
          }).toNullable(),
        }
      }
      return undefined
    },
    [company, valuationFromPPS]
  )
}
