import _ from "lodash"

export const growthRate = (
  newValue: number | undefined,
  baseValue: number | undefined,
  scale: number = 1,
  roundFactor: number = 7
) => {
  /**
   *
   * Returns undefined if at least one is undefined.
   * Round factor is a gimmick from recharts which has some funny tooltip rendering
   * if there is a floating point issuer
   */
  if (newValue === undefined || baseValue === undefined) return undefined
  return _.round((newValue / baseValue) * scale - scale, roundFactor)
}

export const calculatePercentageChange = ({
  initial,
  final,
}: {
  initial: number
  final: number
}): number => (final - initial) / initial
