import CompanyLogoAndName from "@components/CompanyLogoAndName"
import Table from "@stories/components/Table/Table"
import { Row, createColumnHelper } from "@tanstack/react-table"
import { collections } from "common/firestore/Collections"
import { DealDistribution } from "common/model/DealDistribution/DealDistribution"
import { firestoreConverter } from "common/model/FirestoreConverter"
import { defaultIfLoading, isLoading } from "common/utils/Loading"
import { localDateTimeFormat } from "common/utils/dateUtils"
import { identity } from "common/utils/fp/Function"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { Routes } from "src/Routes/Routes"
import { useQuerySnapshot } from "src/utils/hooks/queries/useQuerySnapshot"

export const AdminDealDistributionList = () => {
  const columnHelper = createColumnHelper<DealDistribution>()
  const navigate = useNavigate()

  const dealDistros = useQuerySnapshot(
    (db) =>
      db.db
        .collection(collections.dealDistributions)
        .withConverter<DealDistribution>(firestoreConverter<DealDistribution>()),
    identity,
    [],
    { allowCache: true }
  )

  const columns = useMemo(
    () => [
      columnHelper.accessor("account.name", {
        header: "Account Name",
        cell: (context) => context.getValue(),
        footer: (p) => p.column.id,
      }),
      columnHelper.accessor("company.name", {
        header: "Company Name",
        cell: (context) => (
          <CompanyLogoAndName company={context.row.original.company} size="xxs" disableClick />
        ),
        footer: (p) => p.column.id,
      }),
      columnHelper.accessor("visibility", {
        header: "Visibility",
        cell: (context) => context.getValue(),
        footer: (p) => p.column.id,
      }),
      columnHelper.accessor("createdAt", {
        header: "Created At",
        cell: (context) => `${localDateTimeFormat(context.getValue())}`,
        footer: (p) => p.column.id,
      }),
    ],
    [columnHelper]
  )

  const handleRowClick = (row: Row<DealDistribution>) => {
    navigate(Routes.admin.dealDistributions.distribution(row.original.id))
  }

  return (
    <Table
      globalFilter={{ placeholder: "Search..." }}
      columns={columns}
      data={defaultIfLoading(dealDistros, [])}
      isLoading={isLoading(dealDistros)}
      onRowClick={handleRowClick}
      defaultSortBy="createdAt"
      defaultSortDirection="desc"
    />
  )
}
