import { wrapIcon } from "./IconWrapper"

export const CheckCircleIcon = wrapIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.81338 5.85998L6.95338 8.72665L5.85338 7.62665C5.79361 7.55686 5.72007 7.50018 5.63736 7.46016C5.55465 7.42015 5.46456 7.39766 5.37275 7.39411C5.28093 7.39057 5.18938 7.40604 5.10383 7.43955C5.01828 7.47307 4.94058 7.52391 4.87561 7.58888C4.81064 7.65385 4.7598 7.73155 4.72629 7.8171C4.69277 7.90265 4.6773 7.9942 4.68084 8.08601C4.68439 8.17783 4.70688 8.26792 4.74689 8.35063C4.78691 8.43334 4.84359 8.50688 4.91338 8.56665L6.48004 10.14C6.54234 10.2018 6.61621 10.2506 6.69744 10.2838C6.77866 10.317 6.86564 10.3338 6.95338 10.3333C7.12827 10.3326 7.29587 10.2631 7.42004 10.14L10.7534 6.80665C10.8159 6.74467 10.8655 6.67094 10.8993 6.5897C10.9332 6.50846 10.9506 6.42132 10.9506 6.33331C10.9506 6.2453 10.9332 6.15817 10.8993 6.07693C10.8655 5.99569 10.8159 5.92195 10.7534 5.85998C10.6285 5.73581 10.4595 5.66612 10.2834 5.66612C10.1073 5.66612 9.93829 5.73581 9.81338 5.85998ZM8.00004 1.33331C6.6815 1.33331 5.39257 1.72431 4.29624 2.45685C3.19991 3.18939 2.34543 4.23058 1.84085 5.44876C1.33626 6.66693 1.20424 8.00737 1.46148 9.30058C1.71871 10.5938 2.35365 11.7817 3.286 12.714C4.21835 13.6464 5.40624 14.2813 6.69944 14.5385C7.99265 14.7958 9.33309 14.6638 10.5513 14.1592C11.7694 13.6546 12.8106 12.8001 13.5432 11.7038C14.2757 10.6075 14.6667 9.31852 14.6667 7.99998C14.6667 7.1245 14.4943 6.25759 14.1592 5.44876C13.8242 4.63992 13.3331 3.90499 12.7141 3.28593C12.095 2.66688 11.3601 2.17581 10.5513 1.84078C9.74243 1.50575 8.87552 1.33331 8.00004 1.33331ZM8.00004 13.3333C6.94521 13.3333 5.91406 13.0205 5.037 12.4345C4.15994 11.8484 3.47635 11.0155 3.07269 10.041C2.66902 9.06642 2.5634 7.99406 2.76919 6.9595C2.97498 5.92493 3.48293 4.97462 4.22881 4.22874C4.97469 3.48286 5.925 2.97491 6.95956 2.76912C7.99413 2.56334 9.06648 2.66895 10.041 3.07262C11.0156 3.47629 11.8485 4.15988 12.4345 5.03694C13.0206 5.914 13.3334 6.94515 13.3334 7.99998C13.3334 9.41447 12.7715 10.771 11.7713 11.7712C10.7711 12.7714 9.41453 13.3333 8.00004 13.3333Z"
      fill="currentColor"
    />
  </svg>
)
