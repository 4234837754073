/* eslint-disable max-classes-per-file */
import { annotate } from "../../../utils/Coerce"
import {
  StructureOrderTerms,
  orderStructureDisplayName,
  orderStructureShortDisplayName,
} from "./Terms"

export class OrderStructure<out T extends keyof StructureOrderTerms = keyof StructureOrderTerms> {
  readonly #structure: T

  constructor(structure: T) {
    this.#structure = structure
  }

  // "indirect" is included here
  is<K extends keyof StructureOrderTerms | "indirect">(k: K): this is OrderStructure<T & K> {
    return this.#structure === annotate<keyof StructureOrderTerms | "indirect">(k)
  }

  displayName() {
    return orderStructureDisplayName[this.#structure]
  }

  shortDisplayName() {
    return orderStructureShortDisplayName[this.#structure]
  }

  rawStructure() {
    return this.#structure
  }
}

export class TradeStructure<
  out T extends keyof StructureOrderTerms | "indirect" = keyof StructureOrderTerms | "indirect"
> {
  readonly #structure: T

  constructor(structure: T) {
    this.#structure = structure
  }

  is<K extends keyof StructureOrderTerms | "indirect">(k: K): this is TradeStructure<T & K> {
    return this.#structure === annotate<keyof StructureOrderTerms | "indirect">(k)
  }

  displayName() {
    return this.is("indirect")
      ? "indirect"
      : orderStructureDisplayName[this.#structure as keyof StructureOrderTerms]
  }

  shortDisplayName() {
    return this.is("indirect")
      ? "indirect"
      : orderStructureShortDisplayName[this.#structure as keyof StructureOrderTerms]
  }

  rawStructure() {
    return this.#structure
  }
}
