import { createRef, FC, useEffect } from "react"

/*
  Example embed code for market-price-embed:
  <script type="text/javascript">
    // Create browser compatible event handler.
    var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    var eventer = window[eventMethod];
    var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
    // Listen for a message from the iframe.
    eventer(messageEvent, function(e) {
      if (isNaN(e.data)) return;
      document.getElementById('caplightEmbed').style.height = e.data + 'px';
    }, false);
  </script>
  <iframe frameborder="0" src="http://localhost:3000/market-price-embed?companyName=Stripe&apiKey=asdf" style="width: 100%" id="caplightEmbed"/>
 */

const EmbedWrapper: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const ref = createRef<HTMLDivElement>()
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const obs = new ResizeObserver(([el]) => {
      // Only care about the first element, we expect one element ot be watched
      const { height } = el.contentRect
      window.parent.postMessage(height, "*")
    })

    const el = ref.current

    if (el) {
      obs.observe(el)

      return () => {
        obs.unobserve(el)
      }
    }
  }, [ref])
  return <div ref={ref}>{children}</div>
}

export default EmbedWrapper
