import { assertUnreachable } from "../../../utils/fp/Function"
import { CRMAllDealsViewOption } from "../../UserCRMSettings"
import { CRMNoteSource } from "../DealCRMNote"

export const CONTACT_ID_SEARCH_KEY = "selected-contact-id"
export const VIEW_QUERY_PARAM_KEY = "view"

export const dealCRMRoutes = {
  root: "/crm",
  deal: (dealId: string) => `/crm/deals/${dealId}`,
  allDeals: "/crm/deals",
  allContacts: "/crm/contacts",
  allInterest: "/crm/interest",
  contacts: {
    import: "/crm/contacts/import",
  },
  dealDistribution: {
    new: (dealDistributionId: string) => `/deal-distributions/${dealDistributionId}/new`,
    pendingNew: "/deal-distributions/pending/new",
    monitor: (dealDistributionId: string) => `/deal-distributions/${dealDistributionId}/monitor`,
    participantView: (dealDistributionParticipantId: string) =>
      `/deal-distributions/${dealDistributionParticipantId}/view`,
    creatorPreview: (dealDistributionId: string) =>
      `/deal-distributions/${dealDistributionId}/preview`,
    admin: {
      dealDistribution: (dealDistributionId: string) =>
        `/admin/deal-distributions/${dealDistributionId}`,
    },
  },
}

export const allDealsViewRoute = (view: CRMAllDealsViewOption | undefined = "table") =>
  `${dealCRMRoutes.allDeals}?${VIEW_QUERY_PARAM_KEY}=${view}`

export const routeForCRMNoteSource = (source: CRMNoteSource): string => {
  if (source.sourceType === "contact")
    return `${dealCRMRoutes.allContacts}?selected-contact-id=${source.sourceId}`
  if (source.sourceType === "company") return `/companies/${source.sourceId}` // TODO
  if (source.sourceType === "deal") return `${dealCRMRoutes.allDeals}/${source.sourceId}`
  if (source.sourceType === "dealParticipant")
    return `${dealCRMRoutes.allDeals}/${source.deal.id}?${CONTACT_ID_SEARCH_KEY}=${source.contact.id}`
  if (source.sourceType === "interest" || source.sourceType === "holding")
    return `${dealCRMRoutes.allInterest}${
      source.contact ? `?${CONTACT_ID_SEARCH_KEY}=${source.contact.id}` : ""
    }`
  return assertUnreachable(source)
}
