import React, { ReactElement } from "react"
import Typography, { Color, Size, Weight } from "../Typography/Typography"
import { assertUnreachable } from "common/utils/fp/Function"
import { Button } from "../Button/Button"
import { XIcon } from "src/stories/icons/XIcon"

type AlertVariant = "default" | "accent" | "warning" | "success" | "subtle-success" | "danger"

export interface AlertProps {
  /**
   * What variant is the alert?
   */
  variant: AlertVariant
  /**
   * What is the headline?
   */
  headline?: string
  /**
   * What is the body of the alert?
   */
  message?: string | ReactElement
  /**
   * Include an icon?
   */
  icon?: ReactElement
  /**
   * Is the alert a full width banner?
   */
  isFullWidthBanner?: boolean
  /**
   * Include a close button?
   */
  hasCloseButton?: boolean
  /**
   * What happens when the close button is clicked?
   */
  onCloseButtonClick?: () => void
}

const alertStyle = (variant: AlertVariant) => {
  switch (variant) {
    case "accent":
      return "bg-neutral-100 border-accent-500"
    case "warning":
      return "bg-warning-100 border-warning-500"
    case "success":
      return "bg-success-100 border-success-500"
    case "subtle-success":
      return "bg-neutral-100 border-success-500"
    case "danger":
      return "bg-danger-100 border-danger-500"
    case "default":
      return "bg-neutral-100 border-neutral-400"
    default:
      return assertUnreachable(variant)
  }
}

const Alert: React.FC<AlertProps> = ({
  variant,
  headline,
  message,
  icon,
  isFullWidthBanner = false,
  hasCloseButton = false,
  onCloseButtonClick,
}) => (
  <div
    className={`border ${alertStyle(variant)} ${
      isFullWidthBanner ? "" : "rounded"
    } flex flex-col gap-y-2 p-3 w-full shadow-md relative`}
  >
    {hasCloseButton && (
      <div className="absolute top-2 right-2">
        <Button onClick={onCloseButtonClick} variant="hollow" leftIcon={<XIcon />} />
      </div>
    )}
    {icon || headline ? (
      <div className="flex gap-x-2 items-center">
        {icon}
        {headline && (
          <Typography text={headline} size={Size.Medium} weight={Weight.Bold} color={Color.Black} />
        )}
        {!headline && message && typeof message === "string" && (
          <Typography
            text={message}
            size={Size.Small}
            weight={Weight.Regular}
            color={Color.Black}
          />
        )}
      </div>
    ) : null}
    {headline && message && typeof message === "string" && (
      <Typography text={message} size={Size.Small} weight={Weight.Regular} color={Color.Black} />
    )}
    {message && typeof message !== "string" ? message : null}
  </div>
)

export default Alert
