import { Button } from "@stories/components/Button/Button"
import Typography, { Color, Size } from "@stories/components/Typography/Typography"
import { DealCRMFirmContact } from "common/model/DealCRM/DealCRMFirmContact"
import { UpdateContactProps } from "src/firebase/crm"

export const PrimaryContactButton = ({
  contact,
  contactId,
  updateContact,
}: {
  updateContact: (p: UpdateContactProps<DealCRMFirmContact>) => void
  contact: DealCRMFirmContact
  contactId: string
}) => {
  const handleCreatePrimaryContact = () => {
    updateContact({
      contact,
      update: {
        contacts: [
          ...contact.contacts.map((c) => ({ ...c, isPrimaryContact: c.id === contactId })),
        ],
      },
    })
  }
  const foundContact = contact.contacts.find((c) => c.id === contactId)
  if (!foundContact) {
    return null
  }
  if (foundContact.isPrimaryContact) {
    return <Typography color={Color.Primary} text="Primary" size={Size.XSmall} />
  }
  return (
    <Button
      onClick={handleCreatePrimaryContact}
      label="Set as Primary Contact"
      variant="hollow"
      size="small"
    />
  )
}
