import { PartitionOutlined } from "@ant-design/icons"
import { Tooltip } from "@stories/components/Antd"
import { SplitDisplayPriceData } from "common/queries/pricing/PricingEnrichment"
import { isNonempty } from "common/utils/data/Array/Nonempty"
import { shortDateFormat } from "common/utils/dateUtils"
import { formatSharePrice } from "@components/displays/numeric/Currency"

const SplitAdjustmentTooltipRow = ({
  splitAdjustment,
}: {
  splitAdjustment: SplitDisplayPriceData
}) => (
  <tr>
    <td>{formatSharePrice(splitAdjustment.pricePerShare)}</td>
    <td>{`${splitAdjustment.splitRatio.numerator}:${splitAdjustment.splitRatio.denominator}`}</td>
    <td>{shortDateFormat(splitAdjustment.splitDate)}</td>
  </tr>
)
export const SplitAdjustmentTooltip = ({
  splitAdjustments,
}: {
  splitAdjustments: SplitDisplayPriceData[]
}) =>
  isNonempty(splitAdjustments) ? (
    <Tooltip
      className=""
      overlayInnerStyle={{ width: "24rem", padding: "1rem" }}
      title={
        <div>
          <h5 className="text-white">This data point has been adjusted for stock splits</h5>
          <table cellPadding="2px" className="table w-full">
            <tr className="table-header-group">
              <th>Pre-split price</th>
              <th>Split Ratio</th>
              <th>Date</th>
            </tr>
            {splitAdjustments.map((x, i) => (
              <SplitAdjustmentTooltipRow key={i} splitAdjustment={x} />
            ))}
          </table>
        </div>
      }
    >
      <PartitionOutlined className="p-2" />
    </Tooltip>
  ) : null
