import { Account } from "common/model/Account"
import { topBrokerCriteria } from "./TopBrokerCriteriaConfig"
import { useTopBrokerCriteria } from "./useTopBrokerCriteria"
import { TopBrokerCriteriaCard } from "./TopBrokerCriteriaCard"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { TopBrokerCriteriaDisclaimer } from "./TopBrokerCriteriaDisclaimer"
import { Card } from "@stories/components/Card/Card"
import { displayYearAndQuarter } from "common/utils/dateUtils"
import moment from "moment"

export const LiveTopBrokerCriteria = ({
  account,
  quarterStartDate,
  quarterEndDate,
}: {
  account: Account
  quarterStartDate: Date
  quarterEndDate: Date
}) => {
  const criteria = useTopBrokerCriteria({
    config: topBrokerCriteria,
    account,
    startDate: quarterStartDate,
    endDate: quarterEndDate,
  })
  return (
    <div>
      <Card>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-4 max-w-lg">
            <div>
              <Typography
                text={`${displayYearAndQuarter(
                  moment(quarterEndDate).add(1, "week").toDate()
                )} : Evaluation In Progress`}
                weight={Weight.Semibold}
                size={Size.XLarge}
                color={Color.Primary}
              />
            </div>
          </div>

          <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
            {criteria.map((c) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <TopBrokerCriteriaCard key={c.type} {...c} />
            ))}
          </div>
          <TopBrokerCriteriaDisclaimer />
        </div>
      </Card>
    </div>
  )
}
