import { useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"

export type SearchParamDrawerValue = {
  closeDrawer: () => void
  openDrawer: (id: string) => void
  selectedId: string | null
}

export const defaultSearchParamDrawerValue: SearchParamDrawerValue = {
  closeDrawer: () => {},
  openDrawer: () => {},
  selectedId: null,
}

export const useSearchParamDrawer = (searchKey: string): SearchParamDrawerValue => {
  const location = useLocation()
  const navigate = useNavigate()

  return useMemo(() => {
    const search = new URLSearchParams(location.search)
    return {
      closeDrawer: () => {
        search.delete(searchKey)
        navigate({ search: search.toString() })
      },
      openDrawer: (id: string) => {
        search.set(searchKey, id)
        navigate({ search: search.toString() })
      },
      selectedId: search.get(searchKey),
    }
  }, [location, searchKey, navigate])
}
