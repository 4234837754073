import { AccountIdFields } from "../Account"
import { UserIdFields } from "../User"

export const featureFlagNames = [
  "test_flag", // ! test_flag is used for unit testing and should not be used elsewhere
  "event_feed",
  "spacex_spv_adjustment",
  "marketprice_data_quality",
  "is_trade_form_live",
  "intercom_chat",
  "company_special_case_marketprice_summary",
  "order_inquiry_threads",
  "v2_funding_rounds_table",
  "is_order_quota_system_live",
  "is_orders_requiring_attention_live",
  "linked_broker",
  "order_form_logging",
  "is_unread_news_pill_active",
  "is_additional_market_information_component_live",
  "crm",
  "order_history",
  "account_activity_log",
  "cois_enabled",
  "is_add_from_cap_table_enabled",
  "is_highlighted_opportunities_widget_live",
  "is_live_orders_endpoint_enabled",
  "crm_match_suggestions",
  "deal_participants_kanban_view",
  "self_signup",
  "is_coi_share_information_view_enabled",
  "crm_emails_integration",
  "broker_deals_table_view_access",
  "broker_deal_detail_page_access",
  "comps_v2_enabled",
  "deal_search",
  "carta_share_verification",
  "crm_new_uploader",
] as const

export type FeatureFlagName = (typeof featureFlagNames)[number]

type FeatureFlagIdFields = {
  id: string
  name: FeatureFlagName
}

// ! NOTE - "fully_enabled" and "disabled" do what they say regardless of the other fields
// "conditionally_enabled" falls back on the other logic fields (`enabledAccounts`, etc...)
// see also `isFlagEnabled` and its unit tests
export const featureFlagStatuses = ["fully_enabled", "conditionally_enabled", "disabled"] as const

export type FeatureFlagStatus = (typeof featureFlagStatuses)[number]

export type FeatureFlagLogicFields = {
  status: FeatureFlagStatus
  enabledAccounts?: AccountIdFields[]
  enabledUsers?: UserIdFields[]
}

export type FeatureFlag = FeatureFlagIdFields & FeatureFlagLogicFields

export type FeatureFlagRecord = { [k in FeatureFlag["name"]]?: FeatureFlag }
export type FeatureFlagEnabledRecord = { [k in FeatureFlag["name"]]?: boolean }
