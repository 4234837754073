import { Order } from "common/model/Order/Models/Wrapped"
import { Interval } from "common/utils/data/Interval"
import { ButtonSize, ButtonVariant } from "@stories/components/Button/Button"
import IntroductionRequestButton from "."
import { nullableToMaybe } from "common/containers/Maybe"

type Props = {
  order: Order
  size?: ButtonSize
  variant?: ButtonVariant
  className?: string
  onClick?: () => void
}
const WrappedOrderConnectButton = ({
  className,
  order,
  size,
  variant = "connect",
  onClick,
}: Props) =>
  order.isConnectable() ? (
    <IntroductionRequestButton
      sourceComponent="order-details-page"
      variant={variant}
      buttonClassName={className}
      company={order.company()}
      anchorTradeOrOrder={{
        createdAt: order.originationDate() ?? undefined,
        type: order.isDarkpool()
          ? "darkpool_order"
          : order.isTentativeInterest
          ? "tentative_interest"
          : "order",
        id: order.id(),
        accountId: order.unboxed.order.base.source.account.id,
        direction: order.direction(),
        price: order.rawPrice().match(
          ({ valuation }) => ({ targetValuation: valuation }),
          ({ pricePerShare }) => ({ USDPerShare: Interval.pure(pricePerShare) }),
          () => null
        ),
        volumeUSD: order
          .amountUSD()

          .map((x) => x.upperBound)
          .toNullable(),
        structure: nullableToMaybe(order.currentStructures()?.[0])
          .map((s) => s.displayName())
          .toNullable(),
      }}
      wrappedOrder={order}
      size={size}
      onClick={onClick}
    />
  ) : null

export default WrappedOrderConnectButton
