import z from "zod"
import { Maybe, nullableToMaybe } from "common/containers/Maybe"
import { Company } from "common/model/Company"
import { collections } from "common/firestore/Collections"
import { firestoreConverter } from "common/model/FirestoreConverter"
import { head } from "common/utils/data/Array/ArrayUtils"
import { APIEndpoints, runAPIQuery } from "../../firebase/API"
import { searchCompanies } from "../../services/Algolia"
import { FirebaseReader } from "../../firebase/Firebase"

// TODO: move parser to common/model?
export const embedAccountResult = z.object({
  // FIXME: deduplicate these strings from common/model
  code: z.enum(["valid-hostname", "invalid-hostname", "invalid-key"]),
  account: z.object({ id: z.string() }).passthrough().optional(),
})

export type EmbedAccountResult = z.infer<typeof embedAccountResult>

export const isValidationValid = (validationResult: EmbedAccountResult) =>
  validationResult.code === "valid-hostname"

export type ValidationBody = { hostname: string; apiKey: string }

export const getEmbedAccount = async (
  validationBody: ValidationBody
): Promise<EmbedAccountResult> =>
  runAPIQuery(APIEndpoints.embedAccount, validationBody)
    .then((res) => res.json())
    .then((data: unknown) => embedAccountResult.parse(data))

export const getCompanyId = (companyName: string): Promise<Maybe<string>> =>
  searchCompanies(companyName).then((companies) => head(companies).map((c) => c.id))

// TODO: move parser to common/model?
export const embedCompanyResult = z.object({
  // FIXME: deduplicate these strings from common/model
  code: z.enum(["valid-hostname", "invalid-hostname", "invalid-key", "invalid-company"]),
  company: z.object({ id: z.string() }).passthrough().optional(),
})
export type EmbedCompanyResult = z.infer<typeof embedCompanyResult>
export const getEmbedCompany =
  (pendingMaybeCompanyId: Promise<Maybe<string>>, db: FirebaseReader) =>
  async (): Promise<Company | null> => {
    const maybeCompanyId = await pendingMaybeCompanyId

    return maybeCompanyId
      .map((companyId) =>
        db.db
          .collection(collections.companies)
          .doc(companyId)
          .withConverter(firestoreConverter<Company>())
          .get()
          .then((x) => x.data() ?? null)
      )
      .withDefault(Promise.resolve(null))
  }

const isDatadogTestEmbed = () => window.location.ancestorOrigins[0] === "https://app.datadoghq.com"

export const isEmbed = () => window.location !== window.parent.location && !isDatadogTestEmbed()

export const getOriginURL = (): Maybe<URL> =>
  nullableToMaybe(isEmbed() ? new URL(document.referrer) : new URL(document.location.href))
