import {
  allDisplayFields,
  OrderPreview,
  OrderPreviewWrapper,
  Preview,
} from "@components/OrderFormComponents/OrderPreview"
import { DeepPartialIntroduction } from "common/model/IntroductionRequest"
import { intervalMidpoint } from "common/utils/data/numeric/NumberInterval"
import { formatCurrency, formatPrice, formatValuation } from "common/utils/math/format"
import { orderPriceToEither } from "common/model/Order/OrderForm/Constructors"
import * as Wrapped from "common/model/Order/Models/Wrapped"
import { OrderCardHeader } from "@components/OrderFormComponents/OrderSelector/OrderCardHeader"
import LoadedOrderPendingConnect from "src/pages/Orders/OrderPage/OrderPendingConnect/LoadedOrderPendingConnect"
import { CaplightConnectDivider } from "./CaplightConnectDivider"
import { Order } from "common/model/Order/Order"
import { RequestStates } from "./IntroductionRequestByOrder"
import Typography, { Weight } from "@stories/components/Typography/Typography"
import { Divider } from "@stories/components/Antd/Divider/Divider"
import { isNil } from "lodash"
import pluralize from "pluralize"

export const IntroductionRequestHeader = ({
  introductionRequest,
  wrappedOrder,
  selectedOrders,
  requestState,
}: {
  introductionRequest: DeepPartialIntroduction
  wrappedOrder?: Wrapped.Order
  selectedOrders: Order[]
  requestState: RequestStates
}) => (
  <div>
    <Typography text="Connecting To" weight={Weight.Semibold} />
    <div className="border rounded bg-neutral-200 my-3 p-2 space-y-2">
      <OrderCardHeader
        company={introductionRequest.company}
        direction={introductionRequest.anchorTradeOrOrder?.direction}
        updatedAt={wrappedOrder?.latestUpdateDate()}
        darkpool={wrappedOrder?.isDarkpool()}
        order={wrappedOrder}
      />
      {introductionRequest.anchorTradeOrOrder ? (
        <Preview
          direction={introductionRequest.anchorTradeOrOrder?.direction ?? null}
          sizeString={`${
            introductionRequest.anchorTradeOrOrder?.volumeUSD
              ? `$${formatCurrency(2, introductionRequest.anchorTradeOrOrder?.volumeUSD)}`
              : ""
          }`}
          structureString={introductionRequest.anchorTradeOrOrder?.structure ?? ""}
          priceString={
            !isNil(introductionRequest.anchorTradeOrOrder.price)
              ? orderPriceToEither(introductionRequest.anchorTradeOrOrder.price).match(
                  ({ targetValuation }) => `${formatValuation(targetValuation)} val.`,
                  ({ USDPerShare }) => formatPrice(intervalMidpoint(USDPerShare))
                )
              : "Unpriced"
          }
          hiddenFields={allDisplayFields.filter((f) => !["size", "price", "structure"].includes(f))}
          feeString=""
          createdAtDateString=""
          shareClassesString=""
          clientRelationshipString=""
          areTransactionDocumentOnHandString=""
          clientROFRString=""
          darkpool={false}
        />
      ) : null}
    </div>
    {wrappedOrder ? <LoadedOrderPendingConnect order={wrappedOrder} /> : null}
    <CaplightConnectDivider />
    {requestState !== "select_orders" ? (
      <>
        <Typography
          text={`My ${pluralize("Order", selectedOrders.length)}`}
          weight={Weight.Semibold}
        />
        <div className="flex flex-col space-y-2 my-4">
          {selectedOrders.map((o) => (
            <OrderPreviewWrapper key={o.id}>
              <OrderPreview
                order={o}
                hiddenFields={allDisplayFields.filter(
                  (f) => !["size", "price", "structure"].includes(f)
                )}
              />
            </OrderPreviewWrapper>
          ))}
        </div>
        <Divider />
      </>
    ) : null}
  </div>
)
