import { PageTitle } from "@components/typography/PageTitle"
import { Result } from "@stories/components/Antd"
import { AnimatePresence, motion } from "framer-motion"
import moment from "moment"
import { useEffect } from "react"
import { Button } from "@stories/components/Button/Button"
import { ErrorStatus, SavingStatus } from "../../OrderFormConfirmationPage"
import { useOrderForm } from "../../OrderFormContext"
import { useDrawerControls } from "@components/Drawer/DrawerControlContext"

const SavedStatus = () => {
  const { savedOrder, formOrder } = useOrderForm()
  const { onClose } = useDrawerControls()

  return (
    <Result
      className="mt-24"
      status="success"
      extra={
        <div className="flex flex-col items-center justify-center space-y-4">
          <PageTitle
            title={`Your ${formOrder.direction === "buy" ? "Bid" : "Offer"} is now live.`}
          />
          {savedOrder && formOrder ? (
            <>
              <div className=" max-w-150">You can now use your order for the Connect Request.</div>

              <div>{`This order will expire on ${moment(savedOrder._derived.liveUntil).format(
                "MMM D, YYYY"
              )} (${moment(savedOrder._derived.liveUntil).fromNow()})`}</div>

              <div className="flex space-x-4">
                <Button
                  label="Back to Continue Your Connection"
                  variant="hollow"
                  onClick={onClose}
                />
              </div>
            </>
          ) : null}
        </div>
      }
    />
  )
}

export const ConnectOrderFormConfirmationPage = () => {
  const { saveStatus } = useOrderForm()
  const { onClose } = useDrawerControls()
  useEffect(() => {
    if (saveStatus === "saved") {
      onClose()
    }
  }, [onClose, saveStatus])
  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={saveStatus}
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 10, opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        {saveStatus === "saved" ? <SavedStatus /> : null}
        {saveStatus === "error" ? <ErrorStatus /> : null}
        {saveStatus === "saving" ? <SavingStatus /> : null}
      </motion.div>
    </AnimatePresence>
  )
}
