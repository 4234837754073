import { Account } from "common/model/Account"
import { AccountEventCountsRollup } from "common/model/Analytics/EventCounterRollup"
import * as React from "react"
import AccountsModule from "./AccountsModule"

interface ITrialAccountsModuleProps {
  accounts: Account[]
  weeklyEventCounts: AccountEventCountsRollup[]
  monthlyEventCounts: AccountEventCountsRollup[]
}

const BrokerAccountsModule: React.FunctionComponent<ITrialAccountsModuleProps> = ({
  accounts,
  weeklyEventCounts,
  monthlyEventCounts,
}) => {
  const brokerAccounts = accounts.filter((account) => account.clientType.includes("Intermediary"))

  return (
    <AccountsModule
      accounts={brokerAccounts}
      weeklyEventCounts={weeklyEventCounts}
      monthlyEventCounts={monthlyEventCounts}
      title="Broker Accounts"
    />
  )
}

export default BrokerAccountsModule
