export const charWidthMap: Record<string, number> = {
  " ": 8,
  A: 10,
  B: 10,
  C: 10,
  D: 10,
  E: 10,
  F: 10,
  G: 10,
  H: 10,
  I: 4,
  J: 10,
  K: 10,
  L: 10,
  M: 13,
  N: 10,
  O: 10,
  P: 10,
  Q: 10,
  R: 10,
  S: 10,
  T: 10,
  U: 10,
  V: 10,
  W: 15,
  X: 10,
  Y: 10,
  Z: 10,
  a: 8,
  b: 7,
  c: 7,
  d: 7,
  e: 7,
  f: 7,
  g: 7,
  h: 7,
  i: 4,
  j: 4,
  k: 7,
  l: 4,
  m: 11,
  n: 7,
  o: 7,
  p: 8,
  q: 7,
  r: 7,
  s: 7,
  t: 6,
  u: 7,
  v: 7,
  w: 11,
  x: 7,
  y: 7,
  z: 7,
  "0": 10,
  "1": 7,
  "2": 10,
  "3": 10,
  "4": 10,
  "5": 10,
  "6": 10,
  "7": 10,
  "8": 10,
  "9": 10,
}
