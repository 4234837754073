import {
  RadioSelect,
  RadioSelectProps,
  SelectOptions,
} from "@stories/components/Inputs/RadioSelect/RadioSelect"
import { OrderDirection } from "common/model/Order/Order"

const directionRadioListOptions: SelectOptions<OrderDirection>[] = [
  { label: "Buy", value: "buy" },
  { label: "Sell", value: "sell" },
]
export const DirectionSelect = ({
  value,
  onChange,
  options = directionRadioListOptions,
  label = "Direction",
  disabled,
  hasError,
}: RadioSelectProps<OrderDirection>) => (
  <RadioSelect
    label={label}
    value={value}
    options={options}
    onChange={onChange}
    disabled={disabled}
    hasError={hasError}
  />
)
