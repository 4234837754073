import { Editor, EditorContent } from "@tiptap/react"
import React from "react"
import { BubbleMenuBar } from "./BubbleMenuBar"
import { ITipTapEditor } from "./ITipTapEditor"
import { Button } from "@stories/components/Button/Button"
import Typography, { Color, Size } from "@stories/components/Typography/Typography"
import { classNames } from "src/utils/classNames"
import { useOperatingSystem } from "src/utils/hooks/useOperatingSystem"

interface WYSIWYGEditorProps {
  editor: Editor
  isSubmitDisabled?: boolean
  showSubmitButton?: boolean
  submitButtonText?: string
  handleSubmit: (editor: ITipTapEditor) => Promise<unknown>
  handleCancel?: () => void
  autoFocus?: boolean
  textSize?: Size
  minHeight?: boolean
  className?: string
  hideBorder?: boolean
  hideBubbleBar?: boolean
}

export const WYSIWYGEditor: React.FC<WYSIWYGEditorProps> = ({
  editor,
  isSubmitDisabled,
  showSubmitButton,
  submitButtonText,
  handleSubmit,
  handleCancel,
  autoFocus,
  textSize,
  minHeight = false,
  className,
  hideBorder = false,
  hideBubbleBar = false,
}) => {
  const { isMac } = useOperatingSystem()
  const commandKey = isMac ? "⌘" : "Ctrl"

  return (
    <div className="flex flex-col gap-2">
      <div>
        {editor && !hideBubbleBar && <BubbleMenuBar editor={editor} />}
        <div className="w-full lg:flex md:flex flex-col items-center justify-center relative">
          <EditorContent
            editor={editor}
            autoFocus={autoFocus}
            className={classNames(
              "bg-neutral-white p-2 w-full  focus:border-none",
              showSubmitButton ? "border-neutral-300" : "border-neutral-200",
              !hideBorder && editor.isEditable ? "border shadow-sm rounded-md" : "border-none",
              textSize ?? "text-base",
              minHeight ? "tiptap-with-min-height" : "",
              className
            )}
          />
        </div>
      </div>
      <div className="flex gap-2 items-center">
        {showSubmitButton && (
          <div className="w-24">
            <Button
              isDisabled={isSubmitDisabled}
              isFullWidth
              type="submit"
              label={submitButtonText ?? "Add Note"}
              onClick={() => handleSubmit(editor)}
              size="small"
            />
          </div>
        )}
        {handleCancel && (
          <div className="w-24">
            <Button
              isDisabled={isSubmitDisabled}
              isFullWidth
              variant="secondary"
              label="Cancel"
              size="small"
              onClick={handleCancel}
            />
          </div>
        )}
        {showSubmitButton && (
          <div className="flex flex-row gap-4 items-center">
            <Typography
              size={Size.XXSmall}
              color={Color.Subtitle}
              text={`${commandKey} ↵ Enter to add note`}
            />
            <Typography
              size={Size.XXSmall}
              color={Color.Subtitle}
              text="@ to tag a company or colleague"
            />
          </div>
        )}
      </div>
    </div>
  )
}
