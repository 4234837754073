import _ from "lodash"
import { POSTGRES_QUERIES } from "common/utils/postgres"
import { arrayValidator } from "common/utils/Validator"
import { Either } from "common/containers/Either"
import { APIEndpoints, runAPIQuery } from "../../../firebase/API"
import { CurrentUser } from "../../../model/CurrentUser"
import {
  isListeningToNewNewsControl,
  News,
  validateNews,
  EnrichedFeed,
  Controls,
  getFilterString,
  isStandaloneRequest,
  isScrollDownGroupedControl,
} from "common/model/newsfeed/NewsFeed"

export const requestAdminNewsFeed = async (currentUser: CurrentUser) => {
  const response = await runAPIQuery(
    APIEndpoints.postgresDBQuery,
    {
      query_type: POSTGRES_QUERIES.NEWS_FEED_WITH_GROUPING,
      payload: { admin_view: true },
    },
    currentUser
  )
  const rawRes: unknown = await response.json()
  const isValid: Either<string, News[]> = arrayValidator
    .compose(validateNews.overArray())
    .validate(rawRes)
  const result = isValid.match(
    () => [],
    (correctRes) => correctRes
  )
  const ts = new Date().valueOf()
  return _.orderBy(
    result.map((r) => ({ ...r, timestamp: ts, stringifiedFilter: "" })),
    "publishedAt",
    "desc"
  )
}

export type FeedQueryRequest<T> = (
  currentUser: Pick<CurrentUser, "authUser">,
  controls: Controls,
  callBackNews: (a: { data: EnrichedFeed<T>[] }) => void,
  setLoading?: (a: boolean) => void
) => Promise<number>

const formatQueryBody = (controls: Controls) =>
  isListeningToNewNewsControl(controls)
    ? {
        listening_cutoff_id: controls.lastId,
        listening_filters: getFilterString(controls),
      }
    : isScrollDownGroupedControl(controls)
    ? {
        filter: getFilterString(controls),
        scroll_down_group_id: controls.scrollDownGroupId,
        get_top_news: controls.getTopNewsAsPartOfTheResponse, // usually for the initial request
      }
    : isStandaloneRequest(controls) // used for API
    ? { filter: getFilterString(controls) }
    : {}

/** Returns last id */
export const requestNewsFeed: FeedQueryRequest<News> = async (
  currentUser: Pick<CurrentUser, "authUser">,
  controls: Controls,
  callBackNews: (a: { data: EnrichedFeed<News>[] }) => void,
  setLoading?: (a: boolean) => void
) => {
  if (setLoading) setLoading(true)

  const response = await runAPIQuery(
    APIEndpoints.postgresDBQuery,
    {
      query_type: POSTGRES_QUERIES.NEWS_FEED_WITH_GROUPING,
      payload: formatQueryBody(controls),
    },
    currentUser
  )
  const stringifiedFilter = getFilterString(controls)
  const rawRes: unknown = await response.json()
  const isValid: Either<string, News[]> = arrayValidator
    .compose(validateNews.overArray())
    .validate(rawRes)
  const result = isValid.match(
    () => [],
    (correctRes) => correctRes
  )
  const ts = new Date().valueOf()
  callBackNews({
    data: _.orderBy(
      result.map((r) => ({ ...r, timestamp: ts, stringifiedFilter })),
      "publishedAt",
      "desc"
    ),
  })
  if (setLoading) setLoading(false)

  return result.length ? _.orderBy(result, "id", "desc")[0].id : 0
}
