import { FormOrder } from "common/model/Order/OrderForm/State"
import { useErrorHandler } from "src/providers/errorHandler"
import { useState, useEffect, useCallback, useMemo } from "react"
import { Order } from "common/model/Order/Order"
import { DocumentSnapshot } from "src/firebase/Firebase/utils"
import { Maybe } from "common/containers/Maybe"
import { identity } from "common/utils/fp/Function"
import _ from "lodash"
import { OrderFormState } from "common/model/Order/OrderForm/State"
import { validateFormOrder } from "common/model/Order/OrderForm/Validation"

export type OrderFormSaveFunction = (o: FormOrder) => Promise<Maybe<DocumentSnapshot<Order>>>

export const useFormOrderSaving = (
  formOrder: OrderFormState,
  initialFormOrder: OrderFormState,
  onSave: OrderFormSaveFunction,
  deps: unknown[]
) => {
  const [orderSaving, setOrderSaving] = useState(false)
  const [returnedOrder, setReturnedOrder] = useState<Order | null>(null)
  const { handleError } = useErrorHandler()

  const [saveError, setSaveError] = useState(false)
  const initialErrors = validateFormOrder(initialFormOrder).match(identity, () => new Set())
  const currentErrors = useMemo(
    () => validateFormOrder(formOrder).match(identity, () => new Set<never>()),
    [formOrder]
  )

  const validateAndSave = useCallback(() => {
    if (
      [...currentErrors].some((err) => !initialErrors.has(err)) ||
      (currentErrors.size > 0 && _.isEqual(initialFormOrder, {}))
    ) {
      return Promise.reject(
        new Error(`Attempted to save order with errors: ${currentErrors.toString()}`)
      )
    }
    const o = formOrder

    setOrderSaving(true)
    return onSave(o as FormOrder)
      .then((orderDoc) =>
        orderDoc.match(
          (doc) => setReturnedOrder(doc.data() ?? null),
          () => setSaveError(true)
        )
      )
      .catch((e: Error) => {
        setSaveError(true)
        handleError(e)
      })
      .finally(() => setOrderSaving(false))
  }, [currentErrors, formOrder, handleError, initialErrors, initialFormOrder, onSave])

  const saveStatus: "saving" | "pending" | "error" | "saved" = useMemo(() => {
    if (orderSaving) return "saving"
    if (saveError) return "error"
    if (returnedOrder) return "saved"
    return "pending"
  }, [orderSaving, returnedOrder, saveError])

  // reset order steps on dependencies change
  useEffect(() => {
    setOrderSaving(false)
    setReturnedOrder(null)
    setSaveError(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  return { saveStatus, validateAndSave, savedOrder: returnedOrder }
}
