import { identity } from "../../fp/Function"

/** A value witnessing the assignability of `A` to `B` */
export class SubtypeWitness<in A, out B> {
  /** Use a `SubtypeWitness<A,B>` to "convert" an A into a B */
  private readonly subtypeWitness: <R>(a: A, witness: (w: B) => R) => R

  /** not `private` to allow for inheritance but should not be used directly. */
  protected constructor(value: <R>(a: A, witness: (w: B) => R) => R) {
    this.subtypeWitness = value
  }

  /** Types are assignable to types they extend */
  static assignable = <S extends T, T>() => new SubtypeWitness<S, T>((a, w) => w(a))

  run(a: A): B {
    return this.subtypeWitness(a, identity)
  }
}
