import moment from "moment"
import { Either, Left, Right } from "../../../containers/Either"
import { identity } from "../Function"
import { Just, Maybe, Nothing } from "../../../containers/Maybe"
import { IReifiedTraversal, ReifiedTraversal, Traversal } from "./Traversal"
import { isISODate } from "../../json/validate"

/** A `SimplePrism<S,A>` is a reified constructor: review uses an `A` to construct an `S`, while preview attempts to deconstruct an `S` into the corresponding `A`
 * A `Prism<S, T, A, B>` is a `SimplePrism` with the covariant and contravariant parts split into separate type variables.
 * */
export interface Prism<S, T, A, B> {
  preview: (s: S) => Either<T, A>
  review: (b: B) => T
}
export type SimplePrism<S, A> = Prism<S, S, A, A>

export const composePrism = <S, T, A, B, X, Y>(
  l: Prism<S, T, A, B>,
  r: Prism<A, B, X, Y>
): Prism<S, T, X, Y> => ({
  preview: (s) => l.preview(s).bind((a) => r.preview(a).mapFst(l.review)),
  review: (b) => l.review(r.review(b)),
})
export const overPrism = <S, T, A, B>(p: Prism<S, T, A, B>, s: S, f: (a: A) => B): T =>
  p.preview(s).map(f).map(p.review).match(identity, identity)

// assorted combinators
export const identityPrism: <T>() => SimplePrism<T, T> = () => ({
  preview: Right,
  review: identity,
})
export const failing: <T>() => Prism<T, T, never, never> = () => ({
  preview: Left,
  review: (x) => x,
})
export const prismFromPredicate = <T>(f: (t: T) => boolean): SimplePrism<T, T> => ({
  preview: (t) => (f(t) ? Right(t) : Left(t)),
  review: identity,
})

// useful prisms for primitive types
export const stringOrUndefinedPrism: SimplePrism<string, string | undefined> = {
  preview: (s) => (s === "" ? Right(undefined) : Left(s)),
  review: (s) => s ?? "",
}
export const stringISODatePrism: SimplePrism<string, string> = {
  preview: (s) => (isISODate(s) ? Right(s) : Left(s)),
  review: (s) => s ?? "",
}
export const floatPrism: SimplePrism<string, number> = {
  preview: (s) => {
    const parsed = parseFloat(s)
    return Number.isNaN(parsed) ? Left(s) : Right(parsed)
  },
  review: (x) => x.toString(),
}
export const nullPrism: SimplePrism<string, null> = {
  preview: (s) => (s === "null" ? Right(null) : Left(s)),
  review: () => "null",
}

export const emptyStringNullPrism: SimplePrism<string, null> = {
  preview: (s) => (s === "" ? Right(null) : Left(s)),
  review: () => "",
}
export const unionPrism = <S, T1, T2>(
  discriminant: (t: T1 | T2) => Either<T1, T2>,
  l: SimplePrism<S, T1>,
  r: SimplePrism<S, T2>
): SimplePrism<S, T1 | T2> => ({
  preview: (s) => l.preview(s).match((x) => r.preview(x), Right),
  review: (s) => discriminant(s).match(l.review, r.review),
})

export const safeFloatPrism: SimplePrism<string, number | undefined> = {
  preview: (s) => {
    const parsed = parseFloat(s)
    return Number.isNaN(parsed) ? Left(s) : Right(parsed)
  },
  review: (x) => (x !== undefined ? x.toString() : ""),
}
export const intPrism: SimplePrism<number, number> = {
  preview: (s) => (Number.isInteger(s) ? Right(s) : Left(s)),
  review: (x) => x,
}
export const boolPrism: SimplePrism<string, boolean> = {
  preview: (s) =>
    s?.toLowerCase() === "true"
      ? Right(true)
      : s?.toLowerCase() === "false"
      ? Right(false)
      : Left(s),
  review: (x) => x.toString(),
}
export const enumPrism = <T extends string>(ts: readonly T[]): SimplePrism<string, T> => ({
  preview: (s: string) => (ts.includes(s as T) ? Right(s as T) : Left(s)),
  review: (x: T) => x,
})

const validateDate = <T>(ifInvalid: T, x: Date): Either<T, Date> =>
  Number.isNaN(x.valueOf()) ? Left(ifInvalid) : Right(x)

export const dateFromString: SimplePrism<string, Date> = {
  preview: (s) => validateDate(s, new Date(s)),
  review: (d) => d.toDateString(),
}
export const dateFromISOString: SimplePrism<string, Date> = {
  preview: (s) => validateDate(s, new Date(s)),
  review: (d) => moment(d).format("YYYY-MM-DD"),
}
export const dateFromUTCISOString: SimplePrism<string, Date> = {
  preview: (s) => validateDate(s, new Date(s)),
  review: (d) => moment(d).utc().format("YYYY-MM-DD"),
}
export const dateBefore: (before: Date) => SimplePrism<Date, Date> = (before) => ({
  preview: (s) => (s < before ? Right(s) : Left(s)),
  review: (d) => d,
})
export class ReifiedPrism<S, T, A, B> implements Prism<S, T, A, B>, IReifiedTraversal<S, T, A, B> {
  readonly preview: (s: S) => Either<T, A>

  readonly review: (b: B) => T

  constructor(prism: Prism<S, T, A, B>) {
    this.preview = prism.preview
    this.review = prism.review
  }

  composeTraversal = <X, Y>(l: Traversal<A, B, X, Y>) =>
    new ReifiedTraversal<S, T, A, B>({ views: this.views, over: this.over }).composeTraversal(l)

  views = (s: S): A[] =>
    this.preview(s).match(
      () => [],
      (x) => [x]
    )

  over = (s: S, f: (a: A) => B): T =>
    this.preview(s).map(f).map(this.review).match(identity, identity)
}
export type ReifiedSimplePrism<S, A> = ReifiedPrism<S, S, A, A>

export const ifJust = <T>(): ReifiedPrism<Maybe<T>, Maybe<T>, T, T> =>
  new ReifiedPrism({
    preview: (s) => s.match(Right, () => Left(s)),
    review: Just,
  })
export const ifNothing = <T>(): ReifiedPrism<Maybe<T>, Maybe<T>, null, null> =>
  new ReifiedPrism({
    preview: (s) =>
      s.match(
        () => Left(s),
        () => Right(null)
      ),
    review: () => Nothing,
  })
export const ifLeft = <T, R>(): ReifiedPrism<Either<T, R>, Either<T, R>, T, T> =>
  new ReifiedPrism<Either<T, R>, Either<T, R>, T, T>({
    preview: (s) => s.match(Right, () => Left(s)),
    review: (b) => Left(b),
  })
export const ifRight = <T, R>(): ReifiedPrism<Either<T, R>, Either<T, R>, R, R> =>
  new ReifiedPrism<Either<T, R>, Either<T, R>, R, R>({
    preview: (s) => s.match(() => Left(s), Right),
    review: (b) => Right(b),
  })

const dollarAmountRegex = /^\$?[,0-9]+\.?[0-9]*$/

export const dollarAmountPrism: SimplePrism<string, number> = {
  preview: (s) => {
    const parsed = parseFloat(s.replace(/[$,]/g, ""))
    return Number.isNaN(parsed) || !dollarAmountRegex.test(s) ? Left(s) : Right(parsed)
  },
  review: (x) => `$${x.toString()}`,
}
