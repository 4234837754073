import {
  isLiveOrder,
  isStaleOrder,
  isTerminalOrderStatus,
  isTerminalRequiringClosedTradeOrderStatus,
} from "common/model/Order/Types/Status"
import { assertUnreachable } from "common/utils/fp/Function"
import { GetMyOrderStatus, GetMyOrderVisibility } from "./MyOrdersTypes"

export const getMyOrderStatus: GetMyOrderStatus = (order) => {
  if (isLiveOrder(order)) {
    return "Active"
  }
  if (isStaleOrder(order)) {
    return "Expiring"
  }
  if (
    order._lastReportedStatus &&
    isTerminalRequiringClosedTradeOrderStatus(order._lastReportedStatus) &&
    !!order.linkedPriceObservationId
  ) {
    return "Closed"
  }
  if (
    order._lastReportedStatus &&
    isTerminalRequiringClosedTradeOrderStatus(order._lastReportedStatus)
  ) {
    return "Close Pending"
  }
  if (
    order._lastReportedStatus &&
    isTerminalOrderStatus(order._lastReportedStatus) &&
    order._lastReportedStatus.reason === "matched"
  ) {
    return "Matched"
  }
  if (order._lastReportedStatus && isTerminalOrderStatus(order._lastReportedStatus)) {
    return "Closed Canceled"
  }
  if (order._lastReportedStatus && order._lastReportedStatus.tag === "unknown") {
    return "Unknown"
  }
  return "Expired"
}

export const getMyOrderVisibility: GetMyOrderVisibility = ({ orderCollection }, sharedOrders) => {
  if (
    orderCollection === undefined ||
    orderCollection === "platform" ||
    orderCollection === "tentativeInterest"
  ) {
    return "Market"
  }

  if (orderCollection === "darkpool") {
    return "Darkpool"
  }

  if (sharedOrders.length) {
    return "Shared"
  }

  if (orderCollection === "private") {
    return "Private"
  }

  return assertUnreachable(orderCollection)
}
