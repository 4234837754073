import { Stream } from "common/containers/Stream"
import { Rec, UnsafeRec } from "common/utils/RecordUtils"
import { useState } from "react"
import _ from "lodash"
import { DisplayNode } from "./Display"
import { Json, JsonKVP, rawJsonDisplay } from "./JSON"

export type LeafEditor<Contra, Cov = Contra> = {
  display: null | ((lastKey: string, state: Cov, setState: (r: Contra) => void) => DisplayNode)
}
type BranchEditor<R> = { [key in keyof R]?: RecordEditor<R[key]> } & { display?: never }
type ArrayEditor<R extends unknown[]> = Stream<RecordEditor<R[number]>>

export type RecordEditor<R> =
  | BranchEditor<R>
  | LeafEditor<R>
  | (R extends unknown[] ? ArrayEditor<R> : never)

const isBranch = <R,>(r: RecordEditor<R>): r is BranchEditor<R> =>
  r !== null && !Object.keys(r).includes("display")
const isLeaf = <R,>(r: RecordEditor<R>): r is LeafEditor<R> => !isBranch(r)

export type StateDisplayOptions<T = {}, P = string | number> = {
  maxDepth?: number // absolutely not the right way to do this
  context: T
  defaultEditor?: {
    display: (key: string | number | symbol, prim: P) => DisplayNode
  }
}

export const defaultStateDisplayOptions: StateDisplayOptions = {
  maxDepth: 10,
  context: {},
  defaultEditor: {
    display: (k, s) => <>{s}</>,
  },
}

export const StateDisplay = <R extends Record<string, unknown>>({
  initialState,
  onChange = () => {},
  editor,
  options,
}: {
  initialState: R
  onChange?: (r: R) => void
  editor: RecordEditor<R>
  options?: StateDisplayOptions
}) => {
  const [state, setState] = useState(initialState)
  return editor === null || Object.keys(editor).length === 0 ? (
    rawJsonDisplay.run(state as Json) // TODO fix json stuff throughout
  ) : (
    <StateDisplayBranch
      editor={editor}
      state={state}
      options={{ ...defaultStateDisplayOptions, ...options }}
      setState={(s) => {
        setState(s)
        onChange(s)
      }}
    />
  )
}

const StateDisplayNode = <R extends Record<keyof R, unknown>>({
  lastKey,
  state,
  editor,
  setState,
  options,
}: {
  lastKey: string
  state: R
  editor: RecordEditor<typeof state>
  setState: (r: R) => void
  options: StateDisplayOptions
}) =>
  editor === null || Object.keys(editor).length === 0 ? null : isLeaf(editor) ? (
    <StateDisplayLeaf lastKey={lastKey} editor={editor} state={state} setState={setState} />
  ) : (
    JsonKVP(
      lastKey,
      _.isArray(state) ? (
        <StateDisplayArray<unknown>
          options={{
            ...options,
            ...{ maxDepth: options.maxDepth ? options.maxDepth - 1 : undefined },
          }}
          state={state}
          editor={editor as RecordEditor<unknown[]>} // todo fix; need to split parameters by variance
          setState={setState as unknown as (x: unknown[]) => void}
        />
      ) : (
        <StateDisplayBranch<R>
          options={{
            ...options,
            ...{ maxDepth: options.maxDepth ? options.maxDepth - 1 : undefined },
          }}
          state={state}
          editor={editor}
          setState={setState}
        />
      )
    )
  )

const StateDisplayLeaf = <R,>({
  lastKey,
  state,
  editor,
  setState,
}: {
  lastKey: string
  state: R
  editor: LeafEditor<R>
  setState: (r: R) => void
}) => (editor.display ? editor.display(lastKey, state, setState) : null)

const StateDisplayArray = <R,>({
  state,
  editor,
  setState = () => {},
  options,
}: {
  state: R[]
  editor: RecordEditor<R[]>
  setState?: (r: R[]) => void
  options: StateDisplayOptions
}) =>
  options.maxDepth === 0 ? null : (
    <>
      {state
        // .sort(([k1, __], [k2, ___]) => k1.toString().localeCompare(k2.toString()))
        .map((x, i) => {
          if (isBranch(editor)) {
            const subeditor = editor[i]
            return subeditor ? (
              <StateDisplayNode
                lastKey={i.toString()}
                key={i.toString()}
                state={x}
                editor={subeditor}
                options={options}
                setState={(b) => setState(state.map((y, j) => (j === i ? b : y)))}
              />
            ) : null
          } else {
            return rawJsonDisplay.run({ [i]: state[i] } as unknown as Json)
          }
        })}
    </>
  )

const StateDisplayBranch = <R extends Record<keyof R, unknown>>({
  state,
  editor,
  setState = () => {},
  options,
}: {
  state: R
  editor: RecordEditor<R>
  setState?: (r: R) => void
  options: StateDisplayOptions
}) => {
  const stateFields = Rec.genericFields(state)
  return options.maxDepth === 0 ? null : (
    <>
      {UnsafeRec.entries(stateFields).map(([k, l]) => {
        if (isBranch(editor)) {
          const subeditor = l.view(editor)
          return subeditor ? (
            <StateDisplayNode
              lastKey={k as string}
              key={k as string}
              state={l.view(state)}
              editor={subeditor as RecordEditor<R[keyof R]>}
              options={options}
              setState={(b) => setState(l.over(state, () => b))}
            />
          ) : null
        } else {
          return rawJsonDisplay.run({ [k]: l.view(state) } as unknown as Json)
        }
      })}
    </>
  )
}
