import { collections } from "common/firestore/Collections"
import { Account, AccountIdFields } from "common/model/Account"
import {
  AccountGlobalSizeFilterFields,
  DEFAULT_ACCOUNT_GLOBAL_SIZE_FILTER_MINIMUM,
  GlobalMinimumSizeFilterSetting,
} from "common/model/Account/AccountGlobalSizeFilter"
import { firestoreConverter } from "common/model/FirestoreConverter"
import { formatValuation } from "common/utils/math"
import { Firestore, doc, updateDoc } from "firebase/firestore"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"

const updateAccountGlobalSizeFilterMinimum = ({
  db,
  newGlobalMinimumSizeFilterSetting,
  account,
}: {
  db: Firestore
  newGlobalMinimumSizeFilterSetting: GlobalMinimumSizeFilterSetting
  account: Pick<AccountIdFields, "id">
}) =>
  updateDoc(
    doc(db, collections.accounts, account.id).withConverter<Account>(firestoreConverter<Account>()),
    {
      globalMinimumSizeFilterSetting: newGlobalMinimumSizeFilterSetting,
    } satisfies Pick<AccountGlobalSizeFilterFields, "globalMinimumSizeFilterSetting">
  )

export const useAdminUpdateGlobalSizeFilterMinimum = (account: Account) => {
  const { db } = useFirebase9()
  const loggedInUser = useLoggedInUser()
  if (!loggedInUser.isAdmin) {
    throw new Error("useAdminUpdateGlobalSizeFilterMinimum should not be used by non-admins")
  }
  return (newGlobalMinimumSizeFilterSetting: GlobalMinimumSizeFilterSetting) =>
    // eslint-disable-next-line no-alert
    window.confirm(
      [
        "Are you sure you want to update this accounts's global minimum filter?",
        `Account: ${account.name}`,
        `Old Global Size Filter Minimum: ${formatValuation(
          account.globalMinimumSizeFilterSetting ?? DEFAULT_ACCOUNT_GLOBAL_SIZE_FILTER_MINIMUM
        )}`,
        `New Global Size Filter Minimum: ${formatValuation(newGlobalMinimumSizeFilterSetting)}`,
      ].join("\n")
    )
      ? updateAccountGlobalSizeFilterMinimum({
          db,
          account,
          newGlobalMinimumSizeFilterSetting,
        })
      : Promise.reject()
}
