import { wrapIcon } from "./IconWrapper"

export const UserInboxIcon = wrapIcon(
  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.9467 26.9465C32.2539 25.9181 33.208 24.5077 33.6763 22.9118C34.1446 21.3158 34.1039 19.6135 33.5597 18.0418C33.0155 16.4701 31.995 15.107 30.6401 14.1423C29.2852 13.1776 27.6633 12.6592 26 12.6592C24.3367 12.6592 22.7148 13.1776 21.3599 14.1423C20.005 15.107 18.9845 16.4701 18.4403 18.0418C17.8961 19.6135 17.8553 21.3158 18.3237 22.9118C18.792 24.5077 19.7461 25.9181 21.0533 26.9465C18.8134 27.8439 16.859 29.3323 15.3985 31.2531C13.938 33.1738 13.0261 35.4549 12.76 37.8532C12.7407 38.0283 12.7562 38.2054 12.8054 38.3746C12.8546 38.5437 12.9366 38.7015 13.0468 38.8389C13.2694 39.1165 13.593 39.2943 13.9467 39.3332C14.3003 39.3721 14.6549 39.2689 14.9324 39.0464C15.21 38.8238 15.3878 38.5001 15.4267 38.1465C15.7194 35.5401 16.9622 33.1329 18.9176 31.3849C20.873 29.6369 23.4039 28.6705 26.0267 28.6705C28.6495 28.6705 31.1803 29.6369 33.1357 31.3849C35.0911 33.1329 36.3339 35.5401 36.6267 38.1465C36.6629 38.4741 36.8192 38.7767 37.0655 38.9959C37.3117 39.215 37.6304 39.3352 37.96 39.3332H38.1067C38.4562 39.293 38.7756 39.1162 38.9954 38.8415C39.2152 38.5668 39.3175 38.2163 39.28 37.8665C39.0127 35.4614 38.0958 33.1745 36.6278 31.2508C35.1598 29.3271 33.1959 27.8391 30.9467 26.9465ZM26 25.9998C24.9452 25.9998 23.914 25.687 23.037 25.101C22.1599 24.515 21.4763 23.682 21.0726 22.7075C20.669 21.7329 20.5634 20.6606 20.7691 19.626C20.9749 18.5915 21.4829 17.6412 22.2288 16.8953C22.9746 16.1494 23.9249 15.6414 24.9595 15.4357C25.9941 15.2299 27.0664 15.3355 28.041 15.7391C29.0155 16.1428 29.8485 16.8264 30.4345 17.7035C31.0205 18.5805 31.3333 19.6117 31.3333 20.6665C31.3333 22.081 30.7714 23.4375 29.7712 24.4377C28.771 25.4379 27.4145 25.9998 26 25.9998Z"
      fill="currentColor"
    />
  </svg>
)
