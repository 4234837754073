import { ChevronDoubleLeftIcon } from "@stories/icons/ChevronDoubleLeftIcon"
import { ChevronDoubleRightIcon } from "@stories/icons/ChevronDoubleRightIcon"
import { ChevronLeftIcon } from "@stories/icons/ChevronLeftIcon"
import { ChevronRightIcon } from "@stories/icons/ChevronRightIcon"
import { Table } from "@tanstack/react-table"
import { pascalCase } from "common/utils/StringUtils"
import { Button } from "../Button/Button"
import ButtonGroup from "../ButtonGroup/ButtonGroup"
import Typography, { Size } from "../Typography/Typography"

interface TablePaginationProps<T> {
  table: Table<T>
  nameOfTableRecord?: string
  nameOfTableRecords?: string
  data: T[]
  numberOfRecordsInTable: number
  fontSize?: Size
}

// TODO - fix initial dropdown option displayed to defaultPaginationPageSize
export const TablePagination = <T extends object>({
  table,
  data,
  nameOfTableRecord = "row",
  nameOfTableRecords = "rows",
  numberOfRecordsInTable,
  fontSize = Size.Small,
}: TablePaginationProps<T>) => {
  const paginationPageSizeOptions = [5, 10, 20, 30, 40, 50].filter(
    (pageSize) => pageSize <= numberOfRecordsInTable
  )

  const totalRows = data.length

  return (
    <div className="flex items-center gap-6 p-2">
      <div className="flex flex-wrap items-center gap-4">
        <ButtonGroup>
          <Button
            variant="secondary"
            onClick={() => table.setPageIndex(0)}
            isDisabled={!table.getCanPreviousPage()}
            leftIcon={<ChevronDoubleLeftIcon />}
          />
          <Button
            variant="secondary"
            onClick={() => table.previousPage()}
            isDisabled={!table.getCanPreviousPage()}
            leftIcon={<ChevronLeftIcon />}
          />
          <Button
            variant="secondary"
            onClick={() => table.nextPage()}
            isDisabled={!table.getCanNextPage()}
            leftIcon={<ChevronRightIcon />}
          />
          <Button
            variant="secondary"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            isDisabled={!table.getCanNextPage()}
            leftIcon={<ChevronDoubleRightIcon />}
          />
        </ButtonGroup>

        <span className="flex items-center gap-1">
          <Typography
            size={fontSize}
            shouldWrap={false}
            text={`Page ${table.getState().pagination.pageIndex + 1} of ${table.getPageCount()}`}
          />
        </span>
        {paginationPageSizeOptions.length > 1 ? (
          <select
            className="rounded border border-neutral-400 text-sm text-neutral-1000 py-1 pl-2 pr-8"
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value))
            }}
          >
            {paginationPageSizeOptions.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {`Show ${pageSize}`}
              </option>
            ))}
          </select>
        ) : null}
      </div>
      {totalRows ? (
        <span className="flex items-center gap-1">
          <Typography
            size={fontSize}
            shouldWrap={false}
            text={`${totalRows} ${
              nameOfTableRecord
                ? `${
                    totalRows !== 1 ? pascalCase(nameOfTableRecords) : pascalCase(nameOfTableRecord)
                  } Found`
                : "Rows Found"
            }`}
          />
        </span>
      ) : null}
    </div>
  )
}
