import * as z from "zod"
import _ from "lodash"
import { openIntervalContains, orientedInterval } from "../../utils/data/Interval"
import {
  invertQ,
  multiplyQ,
  Q,
  qToNumber,
  Ratio,
  simplifyQ,
} from "../../utils/data/numeric/Rational"
import { dateOrder, PartialOrdering } from "../../utils/fp/Ord"
import { Timeslice } from "../Timeslice"
import { StockScope } from "./Scope"
import { UnsafeRec } from "../../utils/RecordUtils"
import { viewCompanyIdFields } from "../Company"

/**
 * TODO: create an abstraction for "StockSplits", "PriceLogs" if reusing this structure the third time
 */
export type StockSplitData = { date: Date; ratio: Q<number> } // ratio: New/Old
const stockSplitData: z.Schema<StockSplitData> = z.object({
  date: z.date(),
  ratio: z.object({
    numerator: z.number().positive(),
    denominator: z.number().positive(),
  }),
})

export type StockSplitDataWithCumulativeSplits = {
  splitDate: Date
  cumulativeSplitRatio: Q<number>
  splitRatio: Q<number>
}
export interface StockSplitSource {
  name: "airtable"
  airtableId: string
} // other cases as needed

const stockSplitSource: z.Schema<StockSplitSource> = z.object({
  name: z.enum(["airtable"]),
  airtableId: z.string(),
})

export namespace StockSplitSource {
  export const eq = (l: StockSplitSource, r: StockSplitSource) =>
    l.name === r.name && l.airtableId === r.airtableId
}

export const stockSplitSchema: z.Schema<Omit<StockSplit, "scope">> = z.object({
  id: z.string().optional(),
  source: stockSplitSource,
  splitData: stockSplitData,
  createdAt: z.date(),
})

export interface StockSplit {
  id?: string
  source: StockSplitSource
  scope: StockScope
  splitData: StockSplitData
  createdAt: Date
}

const stockSplitEqualFunctions: {
  [K in keyof StockSplit]: (l: Pick<StockSplit, K>, r: Pick<StockSplit, K>) => boolean
} = {
  id: (l: Pick<StockSplit, "id">, r: Pick<StockSplit, "id">) => l.id === r.id,
  source: (l: Pick<StockSplit, "source">, r: Pick<StockSplit, "source">) =>
    _.isEqual(l.source, r.source),
  scope: (l: Pick<StockSplit, "scope">, r: Pick<StockSplit, "scope">) =>
    _.isEqual(viewCompanyIdFields(l.scope.company), viewCompanyIdFields(r.scope.company)),
  splitData: (l: Pick<StockSplit, "splitData">, r: Pick<StockSplit, "splitData">) =>
    _.isEqual(simplifyQ(l.splitData.ratio), simplifyQ(r.splitData.ratio)) &&
    l.splitData.date.valueOf() === r.splitData.date.valueOf(),
  createdAt: () => true,
}

export const equalStockSplitsWithoutScope = (
  l: Omit<StockSplit, "scope">,
  r: Omit<StockSplit, "scope">
) => _.every(UnsafeRec.values(_.omit(stockSplitEqualFunctions, "scope")).map((fn) => fn(l, r)))

export namespace StockSplit {
  export const eq = (l: StockSplit, r: StockSplit) => StockSplitSource.eq(l.source, r.source)
  // marked as partial because we make no guarantees about the ordering of splits for different companies, even though as currently implemented it's simpler to handle them identically.
  export const compare: (l: StockSplit, r: StockSplit) => PartialOrdering = (l, r) =>
    dateOrder(l.splitData.date, r.splitData.date)
}

const splitsBetween = (history: StockSplitData[], valuationDate: Date, targetDate: Date) => {
  const interval = orientedInterval(dateOrder)(valuationDate, targetDate)
  return history
    .filter((p) => openIntervalContains(dateOrder)(interval, p.date))
    .map((s) => s.ratio)
}

/** @deprecated */
export const splitAdjustedPrice =
  (history: StockSplitData[]) =>
  (v: Timeslice<number>, date: Date): number => {
    const forwardSplits = splitsBetween(history, v.sliceDate, date)
    const splits = date < v.sliceDate ? forwardSplits : forwardSplits.map(invertQ)
    return qToNumber(splits.reduce(multiplyQ, Ratio(v.value, 1)))
  }

/** @deprecated */
export const splitAdjustedPriceNow =
  (history: StockSplitData[]) =>
  (v: Timeslice<number>): number =>
    splitAdjustedPrice(history)(v, new Date())

/** @deprecated */
export const splitAdjustedQuantity =
  (history: StockSplitData[]) =>
  (v: Timeslice<number>, date: Date): number => {
    const forwardSplits = splitsBetween(history, v.sliceDate, date)
    const splits = date > v.sliceDate ? forwardSplits : forwardSplits.map(invertQ)
    return qToNumber(splits.reduce(multiplyQ, Ratio(v.value, 1)))
  }
