import firebase from "firebase/compat/app"
import { ReactNode, useEffect, useRef, useState } from "react"
import { useFirebaseReader } from "../../firebase/Context"
import { AuthUserContext } from "./AuthUserContext"
import { setSessionUserId } from "src/utils/LocalStorage"

const AuthUserProvider = ({ children }: { children: ReactNode }) => {
  const [authUser, setAuthUser] = useState<firebase.User | null | undefined>(undefined)
  const authUserRef = useRef(authUser)
  authUserRef.current = authUser
  const _firebase = useFirebaseReader()

  // https://reactjs.org/docs/hooks-effect.html#example-using-hooks-1 handle cleanup of the listener on unmount
  useEffect(() => {
    const listener = _firebase.auth.onAuthStateChanged((_authUser) => {
      setAuthUser(_authUser)
      if (_authUser) setSessionUserId(_authUser?.uid)
      else setSessionUserId(undefined)
    })

    const unsubscribe = listener
    return () => {
      unsubscribe()
    }
  }, [_firebase.auth])

  return <AuthUserContext.Provider value={authUser}>{children}</AuthUserContext.Provider>
}

export default AuthUserProvider
