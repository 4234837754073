import { tail } from "lodash"
import { isNonempty, nonemptyHead } from "../Array/Nonempty"
import { isObject } from "../../json/validate"

type RawPath<R, T> = {
  [key in keyof R]: key extends string
    ? R[key] extends T
      ? key
      : R[key] extends T[]
      ? `${key}.${number}`
      : R[key] extends Record<string, unknown>
      ? `${key}.${RawPath<R[key], T>}`
      : never
    : never
}[keyof R]
export type Path<R, T> = Exclude<RawPath<R, T>, undefined>

const unsafeIndex = <R extends Record<string, unknown>>(keys: string[], r: R): unknown => {
  const x = isNonempty(keys) ? r?.[nonemptyHead(keys) as keyof R] : r
  return isObject(x) && keys.length > 0 ? unsafeIndex(tail(keys), x) : x
}
export const viewPath = <R extends Record<string, unknown>, T>(p: Path<R, T>, r: R): T => {
  const keys = p.split(".")
  return unsafeIndex(keys, r) as T
}
