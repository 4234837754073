import CompanyLogoAndName from "@components/CompanyLogoAndName"
import Typography, { Color, Weight } from "@stories/components/Typography/Typography"
import { DealWithParticipant } from "common/model/DealCRM/Deal/DealCRMDealParticipant"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { Loading, deprecatedIsLoaded, mapLoading } from "common/utils/Loading"
import { titleCase } from "common/utils/StringUtils"
import { ISODateFormat } from "common/utils/dateUtils"
import { formatAbbreviatedCurrency } from "common/utils/math/format"
import { useEffect, useState } from "react"
import { useFirebaseReader } from "src/firebase/Context"
import { getContactDeals } from "src/firebase/crm"
import CommitmentDisplay from "src/pages/CRM/Components/StageDisplay/CommitmentDisplay"
import DealStageDisplay from "src/pages/CRM/Components/StageDisplay/DealStageDisplay"
import { useCurrentUser } from "../../../../../providers/currentUser/useCurrentUser"
import { useNavigate } from "react-router-dom"
import { Routes } from "src/Routes/Routes"
import { DataDisplayConstants } from "common/UI/DataDisplayConstants"

type TableHeaders = "Date" | "Company" | "Deal Stage" | "Direction" | "Amount ($)" | "Status"

export const ContactDealsTable = ({
  contact,
  hideTitle,
  noDealsMessage,
}: {
  contact: DealCRMContact
  hideTitle?: boolean
  noDealsMessage?: string
}) => {
  const user = useCurrentUser()
  const db = useFirebaseReader()
  const navigate = useNavigate()
  const [dealAndParticipantDocs, setDealAndParticipantDocs] = useState<
    Loading<DealWithParticipant[]> | undefined
  >()

  useEffect(() => {
    if (dealAndParticipantDocs === undefined && deprecatedIsLoaded(user)) {
      getContactDeals({ db, contactId: contact.id, accountId: user.user.account.id }).then(
        setDealAndParticipantDocs
      )
    }
  }, [contact.id, setDealAndParticipantDocs, dealAndParticipantDocs, db, user])

  if (!deprecatedIsLoaded(user)) return null
  const headers: TableHeaders[] = [
    "Company",
    "Date",
    "Deal Stage",
    "Direction",
    "Amount ($)",
    "Status",
  ]

  const sortedDeals = dealAndParticipantDocs
    ? mapLoading<DealWithParticipant[], DealWithParticipant[]>((docs) =>
        docs.sort((a, b) => (a.deal.createdAt > b.deal.createdAt ? -1 : 1))
      )(dealAndParticipantDocs)
    : undefined

  const handleRowClick = (dealId: string) => {
    navigate(Routes.crm.deal(dealId))
  }

  return (
    <div className="flex flex-col gap-2">
      {hideTitle ? null : (
        <Typography color={Color.Primary} weight={Weight.Semibold} text="Deals" />
      )}
      {!sortedDeals || !deprecatedIsLoaded(sortedDeals) ? (
        <div>Loading...</div>
      ) : sortedDeals.length ? (
        <div className="w-full overflow-x-auto border rounded">
          <table className="table-auto w-full">
            <thead className="bg-neutral-300">
              <tr className="h-8">
                {headers.map((header) => (
                  <th className="whitespace-nowrap px-2 text-left" key={header}>
                    <div className="flex gap-2 items-center">{header}</div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sortedDeals.map(({ deal, participant }) => (
                <tr
                  key={deal.id}
                  className="border-b last:border-b-0 h-8 hover:bg-neutral-200 cursor-pointer"
                  onClick={() => handleRowClick(deal.id)}
                >
                  <td className="whitespace-nowrap px-2 border-r">
                    <CompanyLogoAndName company={deal.company} size="xs" disableClick />
                  </td>
                  <td className="whitespace-nowrap px-2 border-r">
                    {ISODateFormat(deal.createdAt)}
                  </td>
                  <td className="whitespace-nowrap px-2 border-r">
                    <DealStageDisplay deal={deal} editable={false} />
                  </td>
                  <td className="whitespace-nowrap px-2 border-r">
                    {titleCase(participant.dealSide)}
                  </td>
                  <td className="whitespace-nowrap px-2 border-r">
                    {participant.commitmentNumShares && participant.commitmentSharePrice
                      ? formatAbbreviatedCurrency(
                          participant.commitmentNumShares * participant.commitmentSharePrice
                        )
                      : participant.targetDollarAmount
                      ? formatAbbreviatedCurrency(participant.targetDollarAmount)
                      : DataDisplayConstants.noValue}
                  </td>
                  <td className="whitespace-nowrap px-2 border-r">
                    {participant.commitmentStatus ? (
                      <CommitmentDisplay value={participant.commitmentStatus} editable={false} />
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div>{noDealsMessage ?? "This contact has not been added to any deals."}</div>
      )}
    </div>
  )
}
