type WrapperProps = {
  main: React.ReactNode
  sidebar: React.ReactNode
}

const Wrapper = ({ main, sidebar }: WrapperProps) => (
  <div className="w-full grid grid-cols-1 xl:grid-cols-3">
    <div className="w-full flex flex-col gap-6 col-span-2">{main}</div>
    <div className="w-full flex flex-col gap-4 bg-neutral-200 border-l border-neutral-400 p-4">
      {sidebar}
    </div>
  </div>
)

export default { Wrapper }
