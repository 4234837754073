import { Disclosure } from "@headlessui/react"
import { ReactElement } from "react"
import { Link } from "react-router-dom"
import { CaplightLogo } from "../../CaplightLogo"
import { MinimalFooter } from "../LayoutFooter"

export const LoggedOutLayout = ({ children }: { children: ReactElement }) => (
  <DefaultLoggedOutLayout>{children}</DefaultLoggedOutLayout>
)

const DefaultLoggedOutLayout: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <div className="min-h-screen">
    <Disclosure as="nav" className="bg-neutral-100 border-b border-neutral-400 sticky top-0 z-50">
      {() => (
        <div className="px-4 sm:px-6 lg:px-8 md:max-w-screen-2xl ml-auto mr-auto py-0.5">
          <div className="flex justify-between h-14">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <Link to="/">
                  <CaplightLogo iconOnly className="h-9 w-9" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </Disclosure>

    <main>
      <div className="ml-auto mr-auto ">
        <div className="min-h-screen flex flex-col justify-between">
          <div className="py-2 pb-8">{children}</div>
          <MinimalFooter />
        </div>
      </div>
    </main>
  </div>
)
