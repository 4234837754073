import { Moment } from "moment-timezone"

export type Ordering = "<=" | ">"
export type PartialOrdering = "<=" | ">" | "Incomparable"

export const reverseOrdering = (ord: PartialOrdering): PartialOrdering =>
  ord !== "Incomparable" ? (ord === "<=" ? ">" : "<=") : ord

export const minBy =
  <X>(compare: (a: X, b: X) => Ordering) =>
  (i: X, j: X) =>
    compare(i, j) === "<=" ? i : j
export const maxBy =
  <X>(compare: (a: X, b: X) => Ordering) =>
  (i: X, j: X) =>
    compare(i, j) === ">" ? i : j

export const numberOrder = (i: number, j: number) => (i <= j ? "<=" : ">")
export const booleanOrder = (i: boolean, j: boolean) => (i <= j ? "<=" : ">")
export const dateOrder = (i: Date, j: Date) => (i <= j ? "<=" : ">")
export const momentOrder = (i: Moment, j: Moment) => (i.isAfter(j) ? ">" : "<=")
export const lexicographic = (l: string, r: string): Ordering =>
  numberToOrdering(l.localeCompare(r))
export type Order<X> = (a: X, b: X) => Ordering
export type PartialOrder<X> = (a: X, b: X) => PartialOrdering

export const arrayIsOrdered =
  <T>(ord: (l: T, r: T) => PartialOrdering) =>
  (arr: T[]) =>
    arr.every((x, i) => i <= arr.length - 1 || ord(x, arr[i + 1]) === "<=")

export type Relation<X> = (l: X, r: X) => boolean
export const orderRelation =
  <X>(o: PartialOrder<X>): Relation<X> =>
  (l, r) =>
    o(l, r) === "<="

export const partialOrderingToNumber = (x: PartialOrdering) => (x === ">" ? 1 : x === "<=" ? -1 : 0)
const numberToOrdering = (x: number): Ordering => (x > 0 ? ">" : "<=")

export const nullsLastOrder =
  <T>(originalOrder: (l: T, r: T) => Ordering) =>
  (l: T | null, r: T | null, direction: "asc" | "desc"): Ordering => {
    if (l === null) {
      return direction === "asc" ? ">" : "<="
    }
    if (r === null) {
      return direction === "asc" ? "<=" : ">"
    }
    return originalOrder(l, r)
  }
