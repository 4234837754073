import { wrapIcon } from "./IconWrapper"

export const DownChevronIcon = wrapIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.62624 5.29289C3.01676 4.90237 3.64993 4.90237 4.04045 5.29289L8.00001 9.25245L11.9596 5.29289C12.3501 4.90237 12.9833 4.90237 13.3738 5.29289C13.7643 5.68342 13.7643 6.31658 13.3738 6.70711L8.70712 11.3738C8.31659 11.7643 7.68343 11.7643 7.2929 11.3738L2.62624 6.70711C2.23571 6.31658 2.23571 5.68342 2.62624 5.29289Z"
      fill="currentColor"
    />
  </svg>
)

export default DownChevronIcon
