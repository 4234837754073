import React from "react"
import { Color, Size, Typography } from "../Typography/Typography"

export interface DividerProps {
  label?: React.ReactNode
  size?: Size
  shouldWrap?: boolean
}

const Divider: React.FC<DividerProps> = ({ label, size = Size.Small, shouldWrap }) => (
  <>
    {label ? (
      <div className="flex flex-row items-center gap-2 max-w-full">
        <div className="border-t h-0 w-full" />
        <div>
          <Typography shouldWrap={shouldWrap} size={size} color={Color.Placeholder} text={label} />
        </div>
        <div className="border-t h-0 w-full" />
      </div>
    ) : (
      <div className="border-t h-0" />
    )}
  </>
)

export default Divider
