import { LinkButton } from "../../../../components/LinkButton"
import Alert from "../../../../stories/components/Alert/Alert"
import Typography, { Size } from "../../../../stories/components/Typography/Typography"
import { useLoggedInUser } from "../../../../providers/loggedInUser/useLoggedInUser"
import { Order } from "common/model/Order/Models/Wrapped"
import { sendPlatformEventMessage } from "../../../../firebase/API"

export const ExchangeFundExplanation = ({ order }: { order: Order }) => {
  const user = useLoggedInUser()
  const orderDirection = order.direction()

  const onLearnMoreClick = () => {
    const message = `User ${user.user.email} clicked on the Learn More link for an exchange fund ${
      order.company().name
    } order (${order.id()}).`
    sendPlatformEventMessage(message, user)
  }

  return (
    <div>
      <Alert
        variant="warning"
        headline={`This ${
          orderDirection === "buy" ? "bid" : "offer"
        } is for an exchange fund contract`}
        message={
          <div>
            <div>
              <Typography
                size={Size.Small}
                text="An exchange fund enables shareholders to contribute their concentrated stock to a diversified fund in exchange for a limited partnership interest in the fund of equal value."
              />
            </div>
            <div className="space-x-2">
              <Typography
                size={Size.Small}
                text="Learn more about the mechanics of this structure:"
              />
              <LinkButton
                id={"exchange-fund-learn-more"}
                text="Learn more"
                href={`${process.env.PUBLIC_URL}/assets/exchange-fund.pdf`}
                onClick={onLearnMoreClick}
              />
            </div>
          </div>
        }
      />
    </div>
  )
}
