import { wrapIcon } from "./IconWrapper"

export const FilterIcon = wrapIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.33333 3.66667C1.33333 3.40145 1.43869 3.1471 1.62622 2.95956C1.81376 2.77202 2.06811 2.66667 2.33333 2.66667H13.6667C13.9319 2.66667 14.1862 2.77202 14.3738 2.95956C14.5613 3.1471 14.6667 3.40145 14.6667 3.66667C14.6667 3.93188 14.5613 4.18624 14.3738 4.37377C14.1862 4.56131 13.9319 4.66667 13.6667 4.66667H2.33333C2.06811 4.66667 1.81376 4.56131 1.62622 4.37377C1.43869 4.18624 1.33333 3.93188 1.33333 3.66667ZM3.99999 7.66667C3.99999 7.40145 4.10535 7.1471 4.29289 6.95956C4.48042 6.77202 4.73478 6.66667 4.99999 6.66667H11C11.2652 6.66667 11.5196 6.77202 11.7071 6.95956C11.8946 7.1471 12 7.40145 12 7.66667C12 7.93188 11.8946 8.18624 11.7071 8.37377C11.5196 8.56131 11.2652 8.66667 11 8.66667H4.99999C4.73478 8.66667 4.48042 8.56131 4.29289 8.37377C4.10535 8.18624 3.99999 7.93188 3.99999 7.66667ZM6.99999 10.6667C6.73478 10.6667 6.48042 10.772 6.29289 10.9596C6.10535 11.1471 5.99999 11.4015 5.99999 11.6667C5.99999 11.9319 6.10535 12.1862 6.29289 12.3738C6.48042 12.5613 6.73478 12.6667 6.99999 12.6667H8.99999C9.26521 12.6667 9.51956 12.5613 9.7071 12.3738C9.89464 12.1862 9.99999 11.9319 9.99999 11.6667C9.99999 11.4015 9.89464 11.1471 9.7071 10.9596C9.51956 10.772 9.26521 10.6667 8.99999 10.6667H6.99999Z"
      fill="currentColor"
    />
  </svg>
)
