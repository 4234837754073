import parsePhoneNumberFromString, { CountryCode } from "libphonenumber-js"
import { takeWhile } from "lodash"
import { last, tabulateArray } from "./data/Array/ArrayUtils"
import { nullableToMaybe } from "../containers/Maybe"

export const titleCase = (s: string) => {
  if (!s) return s
  const allLower = s.toLowerCase()
  return allLower[0].toUpperCase() + allLower.slice(1)
}

export const pascalCase = (input: string): string => {
  // Remove non-alphanumeric characters and split by spaces or underscores
  const words = input.replace(/[^a-zA-Z0-9]/g, " ").split(/[\s_]+/)

  // Capitalize the first letter of each word and join them with a space
  const pascalCaseString = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")

  return pascalCaseString
}

export const snakeCaseToTitleCase = (s: string): string =>
  s
    .replace(/^[-_]*(.)/, (_, c: string) => c.toUpperCase())
    .replace(/[-_]+(.)/g, (_, c: string) => ` ${c.toUpperCase()}`)

export const camelCaseToTitleCase = (s: string): string =>
  s
    .replace(/([A-Z]+)(?![a-z])/g, " $1") // Keep sequences of all uppercase characters unchanged
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Separate words when there is a case change
    // Make each term start with a capital letter
    .replace(/^./, (c: string) => c.toUpperCase())
    .trim()
// s
//   .split(" ")
//   .map((part) =>
//     part
//       .replace(/^[-_]*(.)/, (_, c: string) => c.toUpperCase())
//       .replace(/[-_]+(.)/g, (_, c: string) => ` ${c.toUpperCase()}`)
//       .replace(/([A-Z])/g, (_, c: string) => ` ${c}`)
//       .replace(/^./, (c: string) => c.toUpperCase())
//       .trim()
//   )
//   .join(" ")
//   .trim()

export const prependString = (s: string) => (x: string) => s + x

export const zipStringWith =
  <T>(f: (a: string, b: string) => T) =>
  (a: string, b: string): T[] =>
    tabulateArray((i) => f(a[i], b[i]), Math.min(a.length, b.length))

export const longestSharedPrefix = (a: string, b: string) =>
  takeWhile(zipStringWith((l, r) => l === r)(a, b), (x) => x).length

export const passwordPatternString = /^(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]).{8,}$/
export const PASSWORD_MIN_CHAR_LENGTH = 7
export const emailPatternString =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const twoTermsPatternString = /^.{2,}\s.{2,}/

export const titleCaseWords = (str: string) =>
  str
    .split(/[ ,]+/)
    .map((word) => (word === word.toUpperCase() ? word : titleCase(word)))
    .join(" ")

export const sanitizeEmail = (email: string) => email.toLowerCase().trim()

export const areEmailDomainsEqual = (emailA: string, emailB: string): boolean =>
  last(emailA.split("@"))
    .alongside(last(emailB.split("@")))
    .match(
      ([domainA, domainB]) => domainA === domainB,
      () => false
    )

/** Returns 'a' or 'an' to precede a given noun */
const indefiniteArticleFor = (noun: string) => (/^[aeiou]/.test(noun.toLowerCase()) ? "an" : "a")

/** Prepends the correct indefinite article (a/an) to a noun, with optionality to make it uppercase */
export const withIndefiniteArticle = (noun: string, opts?: { uppercase: boolean }) => {
  const article = `${indefiniteArticleFor(noun)}`
  const casedArticle = opts?.uppercase ? titleCase(article) : article.toLowerCase()
  return `${casedArticle} ${noun}`
}

export const formatDirectionalPercentage = (num: number) => {
  if (num >= 0) return `+${num.toString()}%`

  return `${num.toString()}%`
}

export const toCharArray = (s: string): string[] => Array(s.length).map((_, i) => s[i])

export const takeWords = (text: string, wordCount: number): string => {
  const words = text.split(" ")

  return `${words.splice(0, wordCount).join(" ")}...`
}

const allWhitespaceRegex = /^\s*$/
export const allWhitespace = (s: string) => allWhitespaceRegex.test(s)

export const commaSeparateWithAnd = (terms: string[]): string => commaSeparateWithWord(terms, "and")

export const commaSeparateWithWord = (terms: string[], word: string): string => {
  const commaSeparated = terms.join(", ")
  if (terms.length > 1) {
    const lastCommaIndex = commaSeparated.lastIndexOf(",")
    const includeOxfordComma = terms.length > 2
    return `${commaSeparated.slice(0, lastCommaIndex)}${
      includeOxfordComma ? "," : ""
    } ${word}${commaSeparated.slice(lastCommaIndex + 1)}`
  }
  return commaSeparated
}

/**
 * Removes special characters from a given input string, leaving only alphanumeric characters and spaces.
 *
 * @param {string} input - The input string containing special characters.
 * @returns {string} The input string with special characters removed.
 */
export const removeSpecialCharacters = (input: string): string => {
  // Use a regular expression to match only alphanumeric characters
  const alphanumericWithSpacesRegex = /[^a-zA-Z0-9\s]/g

  // Replace non-alphanumeric or space characters with an empty string
  const result = input.replace(alphanumericWithSpacesRegex, "")

  return result
}

/**
 * Truncates a given input string to a specified maximum length.
 *
 * @param {string} inputString - The input string to be truncated.
 * @param {number} maxLength - The maximum length of the truncated string.
 * @returns {string} The truncated string, followed by an ellipsis ("...") if applicable.
 */
export const truncateString = (inputString: string, maxLength: number): string => {
  if (inputString.length > maxLength) {
    return inputString.slice(0, maxLength - 3) + "..."
  }
  return inputString
}

/**
 * Same validator as `react-phone-number-input`, used in `PhoneInput`
 */
export const validatePhoneNumber = (
  phoneNumber?: string,
  defaultCountryCode?: CountryCode
): boolean =>
  nullableToMaybe(phoneNumber)
    .map((n) => parsePhoneNumberFromString(n, defaultCountryCode))
    .bind(nullableToMaybe)
    .map((parsed) => parsed.isPossible())
    .withDefault(false)

export const getFirstAndLastName = (
  nameString: string
): { firstName: string; lastName: string } => {
  const nameParts = nameString.split(" ")
  return {
    firstName: nameParts[0],
    lastName: nameParts.slice(1).join(" "),
  }
}

/**
 * NOTE: Does not verify whether email is valid, and may produce unexpected results if used on invalid emails. (see StringUtils.test.ts)
 */
export const extractEmailDomain = (email: string): string =>
  // NOTE: [1] will never be `undefined` because of the `.includes` guard
  email.includes("@") ? sanitizeEmail(email).split("@")[1] : email

export const isPersonalEmailDomain = (email: string) => {
  const personalEmailDomains = [
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "outlook.com",
    "icloud.com",
    "aol.com",
    "live.com",
    "comcast.net",
    "me.com",
    "msn.com",
    "sbcglobal.net",
    "verizon.net",
    "att.net",
  ]
  return personalEmailDomains.includes(extractEmailDomain(email))
}
