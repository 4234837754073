import Typography, { Size, Weight } from "@stories/components/Typography/Typography"
import { FirmIcon } from "@stories/icons/FirmIcon"
import { UserIcon } from "@stories/icons/UserIcon"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { ContactInfoPreview } from "./ContactInfoPreview"
import { CompanyFilterSearchProp } from "./shared"
import { isBrokerage } from "common/model/DealCRM/DealCRMFirmContact"
import { FC } from "react"

interface ContactSelectProps {
  contact: DealCRMContact
  onContactSelected: (contact: DealCRMContact) => Promise<void>
  initialCompanyFilter?: CompanyFilterSearchProp
  hideContactDetails?: boolean
  showIndividualFirmName?: boolean
}

export const ContactSelect: FC<ContactSelectProps> = ({
  contact,
  onContactSelected,
  initialCompanyFilter,
  hideContactDetails = false,
  showIndividualFirmName,
}) => (
  <div
    className="flex flex-col rounded-lg hover:border-accent-500 cursor-pointer gap-1 border border-neutral-300 p-2 max-w-150"
    onClick={() => onContactSelected(contact)}
  >
    <div className="flex flex-col gap-2">
      <div className="flex flex-row gap-1 items-center">
        {contact.tag === "firm" ? <FirmIcon /> : <UserIcon />}
        <Typography
          text={
            isBrokerage(contact) || contact.tag === "broker"
              ? `${contact.name} (Broker)`
              : contact.name
          }
          size={Size.Small}
          weight={Weight.Semibold}
        />
        {showIndividualFirmName && contact.tag === "individual" && contact.firm ? (
          <Typography text={`(${contact.firm.name})`} size={Size.Small} />
        ) : null}
      </div>
      {!hideContactDetails ? (
        <ContactInfoPreview initialCompanyFilter={initialCompanyFilter} contact={contact} />
      ) : null}
    </div>
  </div>
)
