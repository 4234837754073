import { getWordWidth } from "@components/companies/select/CompanyAutocompletePill"
import { Just, Nothing, nullableToMaybe } from "common/containers/Maybe"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { ElementRef, useMemo, useRef, useState } from "react"

type DirectionAutocompleteProps = {
  onChange: (c: "bid" | "offer") => void
  placeholder?: string
  allowTabDefault?: boolean
}
const DirectionAutocomplete = ({
  onChange,
  placeholder = "Bid or Offer?",
  allowTabDefault = false,
}: DirectionAutocompleteProps) => {
  const [typedText, setTypedText] = useState<string>("")

  const onSearchInputChanged = (value: string) => {
    const cleanedValue = value.replace(/[^a-zA-Z\s.]/g, "")
    setTypedText(cleanedValue)
  }

  const suggestedDirection = useMemo(() => {
    if (!typedText) return Nothing
    const suggestion = (["bid", "offer"] as const).find((direction) =>
      direction.startsWith(typedText.toLowerCase())
    )
    return nullableToMaybe(suggestion)
  }, [typedText])

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Tab" || event.key === "Enter") {
      if (!allowTabDefault || event.key !== "Tab") event.preventDefault()
      suggestedDirection.runEffect((direction) => {
        onChange(direction)
        setTypedText("")
      })
    }
  }

  const inputRef = useRef<ElementRef<"input">>(null)
  const focusInput = () => inputRef.current?.focus()

  return (
    <div
      id="order-direction-autocomplete"
      className="text-sm items-center flex gap-0 flex-nowrap"
      role="none"
      onClick={focusInput}
    >
      <input
        type="text"
        value={typedText}
        onChange={(e) => onSearchInputChanged(e.target.value)}
        onKeyDown={handleKeyDown}
        style={{ width: typedText.length ? getWordWidth(typedText, "sm") : 2 }}
        className="px-0 py-0.5 text-sm block text-neutral-1000 font-medium placeholder-neutral-600 border-0 focus:outline-none ring-0 outline-none focus:border-0 focus:ring-0"
        ref={inputRef}
      />
      {suggestedDirection.match(
        (direction) => (
          <>
            <span className="text-neutral-700 font-medium whitespace-nowrap">
              {direction.slice(typedText.length)}
            </span>
            <span className="text-xxs text-neutral-700 ml-2 whitespace-nowrap flex-nowrap">
              <span className="px-1 py-0.5 border border-neutral-700 rounded-md">TAB</span> to
              select
            </span>
          </>
        ),
        () => null
      )}
      {!typedText && <span className="text-neutral-700 whitespace-nowrap">{placeholder}</span>}
    </div>
  )
}

export default DirectionAutocomplete
