import { CloudUploadOutlined } from "@ant-design/icons"
import { Upload, UploadFile } from "@stories/components/Antd"
import Typography, { Color } from "@stories/components/Typography/Typography"

const { Dragger } = Upload

export const VerificationDocumentsUpload = ({
  files,
  onChangeFiles,
}: {
  files: UploadFile[]
  onChangeFiles: (files: UploadFile[]) => void
}) => (
  <div className="w-full flex flex-col gap-2">
    <div className="p-3 mx-auto border flex flex-col w-full">
      <Dragger
        fileList={files}
        multiple
        onPreview={() => false}
        listType="picture"
        accept="application/pdf, .doc, .docx, .jpg, .jpeg, .png, .HEIC, .HEIF"
        beforeUpload={() => false}
        onChange={(f) => {
          onChangeFiles(f.fileList)
        }}
      >
        <p className="text-3xl text-neutral-600">
          <CloudUploadOutlined />
        </p>
        <Typography text="Click or drag file to upload" color={Color.Subtitle} />
      </Dragger>
    </div>
  </div>
)
