import NotesDisplay from "@components/inputs/NotesDisplay"
import { TextArea } from "@components/inputs/TextAreaField"
import { notification } from "@stories/components/Antd"
import { restrictedCollectionReferences } from "common/firestore/Collections"
import { createNote } from "common/model/Note"
import { Order } from "common/model/Order/Order"
import { useCallback, useState } from "react"
import { useFirebaseWriter } from "src/firebase/Context"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { Button } from "@stories/components/Button/Button"
import { handleConsoleError } from "src/utils/Tracking"

export const PublicAdminOrderNoteEditor = ({ order }: { order: Order }) => {
  const [newNote, setNewNote] = useState("")
  const firebase = useFirebaseWriter()
  const docRef = order.darkpool
    ? restrictedCollectionReferences.darkpoolOrderObservations(firebase.writerDb).doc(order.id)
    : restrictedCollectionReferences.orderObservations(firebase.writerDb).doc(order.id)
  const currentUser = useLoggedInUser()

  // TODO: make logged
  const updateOrderPublicAdminNotes = useCallback(() => {
    if (window.confirm("CLIENT FACING: These notes will be visible to clients.")) {
      const newNoteObj = createNote(newNote, currentUser.user)
      const updatedOrder: Partial<Order> = {
        publicAdminNotes: [...(order.publicAdminNotes ?? []), newNoteObj],
      }
      docRef
        .update(updatedOrder)
        .then(() => {
          notification.success({
            message: "CLIENT FACING: Public Admin order notes submitted",
            placement: "top",
          })
        })
        .then(() => setNewNote(""))
        .catch(handleConsoleError)
    } else {
      setNewNote("")
    }
  }, [docRef, newNote, currentUser.user, order.publicAdminNotes])

  return (
    <div className="flex flex-col space-y-4 flex-1">
      <div className="flex space-x-2 items-end">
        <TextArea
          name="publicAdminOrderNoteEditor"
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
          label="Public Order Notes"
        />
        <Button label="Submit" variant="primary" onClick={updateOrderPublicAdminNotes} />
      </div>
      <NotesDisplay notes={order.publicAdminNotes ?? undefined} />
    </div>
  ) // todo paragraph editor
}
