import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import {
  TrustedBrokerConnection,
  getAccountNetworkNodeFirmName,
  getPendingInboundInvites,
} from "common/model/TrustedBrokerConnection"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { TrustedBrokerNetworkCard } from "./TrustedBrokerNetworkCard"
import { Button } from "@stories/components/Button/Button"
import ButtonGroup from "@stories/components/ButtonGroup/ButtonGroup"
import { useMemo } from "react"
import UnreadIcon from "@stories/components/Badges/UnreadIcon/UnreadIcon"

export const TrustedBrokerNetworkReceivedInvites = ({
  connections,
  maxInvitesReached,
  onRejectInvite,
  onAcceptInvite,
}: {
  connections: TrustedBrokerConnection[]
  maxInvitesReached: boolean
  onRejectInvite: (props: { sourceAccountId: string; connection: TrustedBrokerConnection }) => void
  onAcceptInvite: (props: { sourceAccountId: string; connection: TrustedBrokerConnection }) => void
}) => {
  const { user } = useLoggedInUser()

  const invites = useMemo(
    () =>
      connections.flatMap((connection) => {
        if (connection.isDeleted || connection.status !== "pending") return []
        return getPendingInboundInvites(user.account, [connection]).flatMap((node) => ({
          connection,
          node,
        }))
      }),
    [connections, user.account]
  )

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row items-center gap-2">
        <Typography text="Received Invitations" weight={Weight.Semibold} />
        {invites.length > 0 && <UnreadIcon count={invites.length} variant="accent" />}
      </div>
      {invites.length === 0 && (
        <TrustedBrokerNetworkCard>
          <div className="flex items-center justify-center">
            <Typography text="No Invites" color={Color.Subtitle} size={Size.XSmall} />
          </div>
        </TrustedBrokerNetworkCard>
      )}
      {invites.map(({ connection, node }, i) => (
        <TrustedBrokerNetworkCard key={`${connection.id}`}>
          <div className="flex items-start justify-between">
            <div className="flex flex-col items-start">
              <Typography text={getAccountNetworkNodeFirmName(node)} />
              <div className="flex items-center gap-2">
                <Typography
                  text={`Sent By: ${connection.createdBy.firstName} ${connection.createdBy.lastName}`}
                  size={Size.XSmall}
                />
              </div>
            </div>
            <ButtonGroup>
              <Button
                label="Reject"
                variant="secondary"
                onClick={() => onRejectInvite({ sourceAccountId: user.account.id, connection })}
              />
              <Button
                label="Accept"
                variant="secondary"
                onClick={() => onAcceptInvite({ sourceAccountId: user.account.id, connection })}
                isDisabled={maxInvitesReached}
              />
            </ButtonGroup>
          </div>
        </TrustedBrokerNetworkCard>
      ))}
    </div>
  )
}
