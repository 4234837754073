import { IntervalDisplay } from "@components/displays/Interval"
import { DeepPartialIntroduction } from "common/model/IntroductionRequest"
import { orderStructureDisplayName } from "common/model/Order/Types/Terms"
import { Enriched } from "common/queries/pricing/PricingEnrichment"
import { Interval } from "common/utils/data/Interval"
import { numberOrder } from "common/utils/fp/Ord"
import { formatCurrency, formatPrice, formatValuation } from "common/utils/math/format"
import { justIfNonempty, Nonempty } from "common/utils/data/Array/Nonempty"
import { capitalize } from "lodash"
import { FC } from "react"

const getOrderStructure = (o: Enriched<"order">): string =>
  orderStructureDisplayName[o.raw.structure]
const getOrderSPVFee = (o: Enriched<"order">) => ({
  management: o.raw.managementFee.match(
    (v) => v,
    () => 0
  ),
  carry: o.raw.carry.match(
    (v) => v,
    () => 0
  ),
})
const getOrderSize = (o: Enriched<"order">) =>
  o.volumeDisplay.match(
    (v) => v.volumeUSD,
    () => 0
  )

const Header: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <div className="font-bold text-primary">{children}</div>
)
const SummaryText: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <div className="text-primary-800">{children}</div>
)
const Subtext: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <div className="text-sm text-neutral-800">{children}</div>
)

const IntroductionPreviewOrderSummary = ({
  enrichedOrders,
}: {
  enrichedOrders: Enriched<"order">[]
}) => {
  const buyers = enrichedOrders.filter((o) => o.raw.direction === "buy")
  const sellers = enrichedOrders.filter((o) => o.raw.direction === "sell")
  const getSummary = (orders: Enriched<"order">[], direction: "buyer" | "seller") =>
    orders.length
      ? `${orders.length} ${[...new Set(orders.map((o) => getOrderStructure(o)))].join(
          "/"
        )} ${direction}${orders.length !== 1 ? "s" : ""}`
      : null
  return (
    <div className="flex flex-col gap-2">
      <Header>Order Summary</Header>
      <SummaryText>
        {getSummary(buyers, "buyer")}
        {getSummary(sellers, "seller")}
      </SummaryText>
    </div>
  )
}
const IntroductionPreviewSize = ({ enrichedOrders }: { enrichedOrders: Enriched<"order">[] }) => {
  const lowerBound = Math.min(...enrichedOrders.map((o) => getOrderSize(o)))
  const upperBound = enrichedOrders.map((o) => getOrderSize(o)).reduce((v, acc) => v + acc, 0)
  const display = IntervalDisplay<number>(
    (v) => `$${formatCurrency(0, v)}`,
    true
  )({
    upperBound,
    lowerBound,
  })
  return (
    <div className="flex flex-col gap-2">
      <Header>Size</Header>
      <SummaryText>{display} total demand</SummaryText>
    </div>
  )
}
const getMinMaxInterval = (intervals: Nonempty<Interval<number>>) =>
  intervals.reduce(Interval.concat(numberOrder))

const getValuationIntervals = (enrichedOrders: Enriched<"order">[]) =>
  enrichedOrders.flatMap((o) =>
    o.priceDisplay.match(
      ({ valuation }) => [Interval.pure(valuation)],
      () => []
    )
  )
const getPPSIntervals = (enrichedOrders: Enriched<"order">[]) =>
  enrichedOrders.flatMap((o) =>
    o.priceDisplay.match(
      () => [],
      ({ pricePerShare }) => [Interval.pure(pricePerShare)]
    )
  )

const IntroductionPreviewPrice = ({ enrichedOrders }: { enrichedOrders: Enriched<"order">[] }) => {
  const valuationRange = justIfNonempty(getValuationIntervals(enrichedOrders)).map(
    getMinMaxInterval
  )
  const ppsRange = justIfNonempty(getPPSIntervals(enrichedOrders)).map(getMinMaxInterval)

  return (
    <div className="flex flex-col gap-2">
      <Header>Price</Header>
      {ppsRange.map(IntervalDisplay<number>(formatPrice, true)).match(
        (range) => (
          <SummaryText>{range} per share</SummaryText>
        ),
        () => null
      )}
      {valuationRange.map(IntervalDisplay<number>(formatValuation, true)).match(
        (range) => (
          <SummaryText>{range} valuation</SummaryText>
        ),
        () => null
      )}
    </div>
  )
}
const IntroductionPreviewSPVFees = ({
  enrichedOrders,
}: {
  enrichedOrders: Enriched<"order">[]
}) => {
  const spvRequesterOrders = enrichedOrders.filter((o) => getOrderStructure(o) === "SPV")
  if (spvRequesterOrders.length === 0) {
    return null
  }
  const getOrderSPVFeePrintOut = (order: Enriched<"order">) => {
    const clientType = `${order.raw.direction === "buy" ? "buyer" : "seller"}`
    if (getOrderStructure(order) === "SPV") {
      if (getOrderSPVFee(order)) {
        return `${getOrderSPVFee(order).management}% management fee, ${
          getOrderSPVFee(order).carry
        }% carry`
      } else {
        return `${capitalize(clientType)} with unspecified fees`
      }
    } else {
      return `${getOrderStructure(order)} ${clientType}`
    }
  }
  return (
    <div className="flex flex-col gap-2">
      <Header>SPV Fees</Header>
      {enrichedOrders.map((o, i) => (
        <SummaryText key={i}>{getOrderSPVFeePrintOut(o)}</SummaryText>
      ))}
    </div>
  )
}
const IntroductionPreviewNotes = ({ intro }: { intro: DeepPartialIntroduction }) => (
  <div className="flex flex-col gap-2">
    <Header>Caplight Notes</Header>
    <Subtext>Notes will be reviewed by Caplight prior to sending to the counter party.</Subtext>
    {intro.data?.notes || intro.data?.doNotIntroNotes ? (
      <>
        <SummaryText>{intro.data?.notes ?? "N/A"}</SummaryText>
        <Subtext>Counterparties you would not like to connect with.</Subtext>
        <SummaryText>{intro.data?.doNotIntroNotes}</SummaryText>
      </>
    ) : (
      <SummaryText>-</SummaryText>
    )}
  </div>
)

export const IntroductionPreview = ({
  intro,
  enrichedOrders,
}: {
  intro: DeepPartialIntroduction
  enrichedOrders: Enriched<"order">[]
}) => (
  <div className="flex flex-col gap-4 bg-neutral-200 rounded p-4">
    <div className="flex flex-col gap-2">
      <div className="text-lg font-bold text-primary-800">Introduction Request Preview</div>
      <Subtext>Please confirm the following information is accurate</Subtext>
    </div>
    <IntroductionPreviewOrderSummary enrichedOrders={enrichedOrders} />
    <IntroductionPreviewSize enrichedOrders={enrichedOrders} />
    <IntroductionPreviewPrice enrichedOrders={enrichedOrders} />
    <IntroductionPreviewSPVFees enrichedOrders={enrichedOrders} />
    <IntroductionPreviewNotes intro={intro} />
  </div>
)
