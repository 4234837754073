import _ from "lodash"
import { isObject } from "./json/validate"

export type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Record<Exclude<Keys, K>, undefined>>
  }[Keys]

export const isDefined = <T>(argument: T | undefined): argument is T => argument !== undefined
export const isNotNull = <T>(argument: T | null): argument is T => argument !== null

export const hasKey = <K extends string>(key: K, x: unknown): x is { [key in K]: unknown } =>
  isObject(x) && key in x

/** Identical to `S`, but prevents direct assignment of a value of type `T`, even if `T extends S`. */
export type Excluding<S, T> = S & { [key in Exclude<keyof T, keyof S>]?: never }
