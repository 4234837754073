// Semi-public auction data, to be shown to auction participants
// No PII of the anchor account / opportunity should be included
// including client name, accountId, accountAirtableId

import {
  AuctionBidsVisibilityType,
  AuctionStatusType,
  AuctionVariantType,
} from "common/model/Auction"
import {
  DEFAULT_OPTION_TERM_LENGTH_IN_MONTHS,
  DeprecatedOrder,
  DeprecatedOrderStructure,
} from "common/model/DeprecatedOrder"
import {
  localTimeRangeString,
  localTimeString,
  longDateFormat,
  timeFormat,
} from "common/utils/dateUtils"
import { titleCase } from "common/utils/StringUtils"
import _ from "lodash"
import moment, { Moment } from "moment-timezone"
import numeral from "numeral"
import { firestoreConverter } from "../FirestoreConverter"
import { WrappedDeprecatedOrder } from "../WrappedDeprecatedOrder"

export interface BidWithAssignedAllocations {
  id: string
  createdAt: Date
  premiumPerShare: number
  premiumPercent: number
  numShares: number
  minNumShares: number
  numSharesAllocation: number
  previousNumSharesAllocation: number
}
export interface AuctionData {
  id: string
  name?: string // internal name for an auction to help disambiguate
  variant: AuctionVariantType
  bidVisibility: AuctionBidsVisibilityType
  order: DeprecatedOrder
  totalAvailableShares: number

  minimumBidShares: number
  minimumBidPrice: number
  extensionMinimumBidPrice?: number // Calculated and set upon triggering of an extension period
  preExtensionHighestBidPrice?: number // Calculated and set upon triggering of an extension period

  bidSharesMinIncrement: number
  bidPriceMinIncrement: number

  auctionAnnouncementDate: Date
  auctionOpenDate: Date
  auctionEndDate: Date // when pre-extension bidding ends
  auctionExtensionEndDate: Date
  status: AuctionStatusType
  spotPriceSource: string
  executionAndSettlementDetails: string

  preExtensionBidsWithAllocations?: BidWithAssignedAllocations[]

  participationAgreementTemplateId: string
}

export const auctionConverter = firestoreConverter<AuctionData>()

export class Auction {
  public data: AuctionData

  private orderFns: WrappedDeprecatedOrder

  constructor(auctionData: AuctionData) {
    this.data = auctionData
    this.orderFns = new WrappedDeprecatedOrder(this.data.order)
  }

  get id(): string {
    return this.data.id
  }

  get issuerName(): string {
    return this.orderFns.issuerName
  }

  get name(): string {
    return `${this.issuerName} ${this.opportunityStructure}${
      this.isOption ? " Option" : ""
    } ${titleCase(this.variantName)}`
  }

  get isAuction(): boolean {
    return this.data.variant === "auction"
  }

  get variantName(): string {
    if (this.data.variant === "auction") return "auction"
    return "offering"
  }

  get biddingWindowStartName(): string {
    return this.isAuction ? "auction open date" : "offering open date"
  }

  get description(): string {
    return this.isAuction ? "time-sensitive private auction" : "time-sensitive opportunity"
  }

  get opportunityStructure(): DeprecatedOrderStructure {
    return this.data.order.structure[0]
  }

  get isOption(): boolean {
    return this.opportunityStructure === "Call" || this.opportunityStructure === "Put"
  }

  get assetType(): string {
    // TODO: handle SPVs
    if (this.opportunityStructure === "Call") return "Call Option"
    if (this.opportunityStructure === "Put") return "Put Option"
    return "block"
  }

  get isComplete(): boolean {
    return this.data.status === "completed"
  }

  get isLive(): boolean {
    return this.data.status === "live"
  }

  get liveDateString(): string {
    return longDateFormat(moment(this.data.auctionOpenDate), { includeDay: true })
  }

  get liveTimeString(): string {
    return moment(this.data.auctionOpenDate).tz(moment.tz.guess()).format("h:mma zz")
  }

  get endDateWindowEnd(): Moment {
    return moment(this.data.auctionEndDate)
  }

  get endTimeString(): string {
    return localTimeString(moment(this.data.auctionEndDate))
  }

  get extensionEndTimeString(): string {
    return localTimeString(moment(this.data.auctionExtensionEndDate))
  }

  get endTimeWindowString(): string {
    return localTimeRangeString(moment(this.data.auctionEndDate), this.endDateWindowEnd)
  }

  get totalSizeString(): string {
    return numeral(this.data.order.notionalValueHigh).format("$0a")
  }

  get totalSharesString(): string {
    return numeral(this.data.totalAvailableShares).format("0,")
  }

  private SPOT_PRICE_PERCENT_RANGE = 0.05 // buffer around spot price to build a range

  get spotPriceRangeString(): string {
    const spotPrice = this.data.order.refPPS || 0
    const low = spotPrice * (1 - this.SPOT_PRICE_PERCENT_RANGE)
    const high = spotPrice * (1 + this.SPOT_PRICE_PERCENT_RANGE)
    return `${numeral(low).format("$0.00")} - ${numeral(high).format("$0.00")}`
  }

  get spotValuationRangeString(): string {
    const spotValuation = this.data.order.refValuation || 0
    const low = spotValuation * (1 - this.SPOT_PRICE_PERCENT_RANGE)
    const high = spotValuation * (1 + this.SPOT_PRICE_PERCENT_RANGE)
    return `${numeral(low).format("$0.[00]")}B - ${numeral(high).format("$0.[00]")}B`
  }

  get optionTermLengthInMonths(): number {
    return this.orderFns.contractExpirationInMonths || DEFAULT_OPTION_TERM_LENGTH_IN_MONTHS
  }

  get strikePriceString(): string {
    return numeral(this.data.order.strikePPS || 0).format("$0.00")
  }

  get strikeValuationString(): string {
    return `${numeral(this.data.order.strikeValuation || 0).format("$0.[0]")}B`
  }

  get strikeOutOfTheMoneyString(): string {
    const strikePrice = this.data.order.strikePPS
    const spotPrice = this.data.order.refPPS
    if (!strikePrice || !spotPrice) return ""
    const inOrOutOfTheMoney = this.inAtOrOutOfTheMoney

    if (inOrOutOfTheMoney === "at-the-money") {
      return ""
    }
    if (inOrOutOfTheMoney === "out-of-the-money") {
      const outOfTheMoneyPercent = strikePrice / spotPrice - 1
      return `${numeral(outOfTheMoneyPercent).format("0%")}`
    }
    // In the money
    const inTheMoneyPercent = (spotPrice - strikePrice) / spotPrice
    return `${numeral(inTheMoneyPercent).format("0%")}`
  }

  get inAtOrOutOfTheMoney(): "in-the-money" | "at-the-money" | "out-of-the-money" | undefined {
    if (this.isOption) {
      const strikePrice = this.data.order.strikePPS
      const spotPrice = this.data.order.refPPS
      if (!strikePrice || !spotPrice) return undefined
      if (this.opportunityStructure === "Call") {
        if (strikePrice === spotPrice) {
          return "at-the-money"
        }
        if (strikePrice > spotPrice) {
          return "out-of-the-money"
        }
        return "in-the-money"
      }
      // Put option
      if (strikePrice === spotPrice) return "at-the-money"
      if (strikePrice > spotPrice) return "in-the-money"
      return "out-of-the-money"
    }
    return undefined
  }

  get minBidPriceString(): string {
    return numeral(this.data.minimumBidPrice).format("$0.00")
  }

  get minBidIncrementString(): string {
    return numeral(this.data.bidPriceMinIncrement).format("$0.00")
  }

  get canParticipantsInviteOthers(): boolean {
    return !this.isComplete && this.data.status !== "extended"
  }

  get formattedMinBidStep(): string {
    return numeral(this.data.bidPriceMinIncrement).format("$0.00")
  }

  get formattedMinBid(): string {
    return numeral(this.data.minimumBidPrice).format("$0.00")
  }

  get formattedExtensionMinBid(): string {
    return numeral(this.data.extensionMinimumBidPrice).format("$0.00")
  }

  get formattedExtensionHighestBid(): string {
    return numeral(this.data.preExtensionHighestBidPrice).format("$0.00")
  }

  get formattedEndTimeLocal(): string {
    return timeFormat(this.data.auctionEndDate)
  }

  get formattedEndDateLocal(): string {
    return moment(this.data.auctionEndDate).format("dddd, MMMM D")
  }

  get formattedExtensionEndTimeLocal(): string {
    return timeFormat(this.data.auctionExtensionEndDate)
  }

  get formattedExtensionEndDateLocal(): string {
    return moment(this.data.auctionExtensionEndDate).format("dddd, MMMM D")
  }

  get minimumBidSharesFormatted(): string {
    return numeral(this.data.minimumBidShares).format("0,0")
  }

  get totalAvailableSharesFormatted(): string {
    return numeral(this.data.totalAvailableShares).format("0,0")
  }

  get bidSharesMinIncrementFormatted(): string {
    return numeral(this.data.bidSharesMinIncrement).format("0,0")
  }

  get minWinningBidPrice(): string | undefined {
    const allocations = this.data.preExtensionBidsWithAllocations || []
    const minBidPrice = _.min(
      allocations.filter((bid) => bid.numSharesAllocation > 0).map((bid) => bid.premiumPerShare)
    )
    return minBidPrice ? numeral(minBidPrice).format("$0.00") : undefined
  }
}
