import { ClosedTradeDateSection } from "@components/ClosedTradeFormComponents/ClosedTradeDateSection"
import { ClosedTradePriceSection } from "@components/ClosedTradeFormComponents/ClosedTradePriceSection"
import { ClosedTradeShareClassSection } from "@components/ClosedTradeFormComponents/ClosedTradeShareClassSection"
import { ClosedTradeStructureSection } from "@components/ClosedTradeFormComponents/ClosedTradeStructureSection"
import { NotesSection } from "@components/OrderFormComponents/NotesSection"
import SectionLabel from "@stories/components/Inputs/SectionLabel/SectionLabel"
import { viewCompanyIdFields } from "common/model/Company"
import { matchLoading } from "common/utils/Loading"
import { identity } from "common/utils/fp/Function"
import { useClosedTradeForm } from "./ClosedTradeFormContext"
import CompanyInput from "../../../stories/components/Inputs/CompanyInput/CompanyInput"
import { ClosedTradeROFRSection } from "@components/ClosedTradeFormComponents/ClosedTradeROFRSection"

const ClosedTradeForm = () => {
  const { selectCompany, handleChange, priceObservation, formErrors } = useClosedTradeForm()
  const company = matchLoading(selectCompany(), identity, null, null)

  return (
    <div className="flex flex-col gap-4">
      <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
        <div>
          <SectionLabel hasError={formErrors.has("company")}>Company</SectionLabel>
          <CompanyInput
            value={company}
            onChange={(c) =>
              handleChange({
                ...priceObservation,
                company: c ? viewCompanyIdFields(c) : undefined,
              })
            }
            logoSize="sm"
          />
        </div>
        <ClosedTradeDateSection
          titles={{ observationDate: "Transaction Closed Date" }}
          value={priceObservation}
          onChange={handleChange}
          formErrors={formErrors}
        />
      </div>
      <ClosedTradePriceSection
        title="Terms"
        titles={{ volume: "Volume", price: "Price" }}
        value={priceObservation}
        onChange={handleChange}
        formErrors={formErrors}
        company={company}
      />
      <ClosedTradeStructureSection
        title="Structure"
        titles={{
          carryManagement: "Economics",
          isMultiLayerSpv: "Is this a Single-layer or Multi-layer SPV?",
          isMultiLayerSpvTooltip:
            "A single-layer SPV is a fund that holds shares directly. A multi-layer SPV is a fund that owns shares in another fund.",
        }}
        value={priceObservation}
        onChange={handleChange}
        formErrors={formErrors}
      />
      <ClosedTradeShareClassSection
        title="Share Class"
        titles={{ shareClassSeries: "Share Class Series" }}
        value={priceObservation}
        onChange={handleChange}
        formErrors={formErrors}
      />
      <ClosedTradeROFRSection
        title="ROFR"
        titles={{
          subjectToROFR: "Did this trade go through a ROFR process?",
          wasROFRd: "Was this Transaction ROFR'd?",
        }}
        value={priceObservation}
        onChange={handleChange}
      />
      <NotesSection
        title="Notes"
        titles={{ notes: "Add any notes you would like Caplight to know about this trade" }}
        value={priceObservation}
        onChange={handleChange}
      />
    </div>
  )
}

export default ClosedTradeForm
