import { ISerializable, isSerializable } from "../../container-interfaces/ISerializable"
import { assertUnreachable } from "../../utils/fp/Function"

type BooleanFormulaLayer<T, X> =
  | { tag: "and"; value: X[] }
  | { tag: "or"; value: X[] }
  | { tag: "not"; value: X }
  | { tag: "atom"; value: T }

export type SerializedBooleanFormula<T> =
  | { tag: "and"; value: SerializedBooleanFormula<T>[] }
  | { tag: "or"; value: SerializedBooleanFormula<T>[] }
  | { tag: "not"; value: SerializedBooleanFormula<T> }
  | { tag: "atom"; value: T }

/** A tree with leaves with labels of type `T` and internal nodes labelled by boolean operators  */
export class BooleanFormula<T> implements ISerializable<SerializedBooleanFormula<T>> {
  private constructor(readonly raw: BooleanFormulaLayer<T, BooleanFormula<T>>) {}

  static atom<S>(atom: S) {
    return new BooleanFormula({ tag: "atom", value: atom })
  }

  static not<S>(clause: BooleanFormula<S>) {
    return new BooleanFormula({ tag: "not", value: clause })
  }

  static and<X>(clauses: BooleanFormula<X>[]) {
    return new BooleanFormula({ tag: "and", value: clauses })
  }

  static or<X>(clauses: BooleanFormula<X>[]) {
    return new BooleanFormula({ tag: "or", value: clauses })
  }

  negate() {
    return BooleanFormula.not(this)
  }

  /** TODO: `and` and `or` helper methods: requires adding normalization first to avoid excessive tree depth */

  /** A general tree deconstructor: use this if writing functions `BooleanFormula<T> => X` */
  fold<X>(ifAtom: (s: T) => X, ifAnd: (s: X[]) => X, ifOr: (s: X[]) => X, ifNot: (s: X) => X) {
    // we don't want to allocate a new function for every layer
    const worker = ({ raw }: BooleanFormula<T>): X => {
      switch (raw.tag) {
        case "atom":
          return ifAtom(raw.value)
        case "and":
          return ifAnd(raw.value.map((f) => worker(f)))
        case "or":
          return ifOr(raw.value.map((f) => worker(f)))
        case "not":
          return ifNot(worker(raw.value))
        default:
          return assertUnreachable(raw)
      }
    }
    return worker(this)
  }

  /** General tree constructor: use this if writing functions `X => BooleanFormula<T>` */
  static unfold<X, Y>(seed: X, grow: (x: X) => BooleanFormulaLayer<Y, X>): BooleanFormula<Y> {
    // we don't want to allocate a new function for every layer
    const worker = (x: X): BooleanFormula<Y> => {
      const next = grow(x)
      switch (next.tag) {
        case "atom":
          return BooleanFormula.atom(next.value)
        case "and":
          return BooleanFormula.and(next.value.map((s) => worker(s)))
        case "or":
          return BooleanFormula.or(next.value.map((s) => worker(s)))
        case "not":
          return BooleanFormula.not(worker(next.value))
        default:
          return assertUnreachable(next)
      }
    }
    return worker(seed)
  }

  /** A specialized version of `fold`: Given an interpretation of the leaves of the tree as booleans, interpret the whole formula */
  interpret(ifAtom: (t: T) => boolean): boolean {
    return this.fold(
      ifAtom,
      (xs) => xs.every((x) => x),
      (xs) => xs.some((x) => x),
      (x) => !x
    )
  }

  [isSerializable]: true = true

  serialize() {
    return this.fold<SerializedBooleanFormula<T>>(tag("atom"), tag("and"), tag("or"), tag("not"))
  }

  static deserialize<S>(raw: SerializedBooleanFormula<S>): BooleanFormula<S> {
    return BooleanFormula.unfold<SerializedBooleanFormula<S>, S>(raw, (x) => x)
  }
}

const tag =
  <Tag extends string, T>(t: Tag) =>
  (value: T) => ({ tag: t, value })
