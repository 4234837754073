import { BILLION, MILLION } from "./constants"

export const roundedToMultiple = (
  n: number,
  multiple: 1 | 5 | 10 | 20 | 25 | 100 | "million" | "10 million" | "billion" | "5 billion"
) => {
  if (multiple === 1) return Math.round(n)

  // eslint-disable-next-line rulesdir/no-let
  let multipleNumeric: number = 0

  if (multiple === "5 billion") multipleNumeric = 5 * BILLION
  else if (multiple === "billion") multipleNumeric = BILLION
  else if (multiple === "million") multipleNumeric = MILLION
  else if (multiple === "10 million") multipleNumeric = 10 * MILLION
  else multipleNumeric = multiple

  return Math.ceil(n / multipleNumeric) * multipleNumeric
}

export const roundToTwoDecimalPlaces = (num: number): number => {
  const roundedString = num.toFixed(2)
  return parseFloat(roundedString)
}
