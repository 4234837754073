import { ExternalAuthAPI } from "common/api/ExternalAuthAPI"
import { GmailAPI } from "common/api/GmailAPI"
import { makeAPIPostRequest } from "common/api/Request"
import { isLoaded } from "common/utils/Loading"
import { config } from "src/config"
import { useLoggedInUserAuthToken } from "src/firebase/AuthUtils"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import { useFeatureFlag } from "src/providers/featureFlags/useFeatureFlags"
import { handleConsoleError } from "src/utils/Tracking"
import { useLiveGmailAuthTokens } from "./Admin/useLiveGmailAuthTokens"
import { useCallback, useMemo, useState } from "react"

export const useConnectGmail = () => {
  const userToken = useLoggedInUserAuthToken()
  const user = useCurrentUser()
  const emailIntegrationFeatureFlagEnabled = useFeatureFlag("crm_emails_integration")
  const liveAuthTokens = useLiveGmailAuthTokens()
  const [loading, setLoading] = useState(false)

  const watchInbox = useCallback(
    (email: string) => {
      if (!isLoaded(userToken) || !isLoaded(liveAuthTokens) || liveAuthTokens.length === 0) {
        handleConsoleError("Cannot watch inbox without user token and auth tokens")
        return Promise.reject()
      }
      if (!email.length) {
        handleConsoleError("Cannot watch inbox without email")
        return Promise.reject()
      }

      return makeAPIPostRequest(
        `${config.firebase.cloudApiUrl}/gmail/`,
        GmailAPI,
        ["watch-emails"],
        userToken
      )({ tokenId: liveAuthTokens[0].id }).catch((e) => {
        handleConsoleError(e)
      })
    },
    [userToken, liveAuthTokens]
  )

  const isGmailConnected = useMemo(() => {
    if (!isLoaded(liveAuthTokens)) return null
    return liveAuthTokens.length > 0
  }, [liveAuthTokens])

  const connectGmail = useCallback(() => {
    if (isLoaded(userToken) && isLoaded(user) && isLoaded(liveAuthTokens)) {
      if (emailIntegrationFeatureFlagEnabled && liveAuthTokens.length === 0) {
        setLoading(true)
        makeAPIPostRequest(
          `${config.firebase.cloudApiUrl}/external-auth/`,
          ExternalAuthAPI,
          ["new-oauth-url"],
          userToken
        )({})
          .then((url) => {
            window.open(url, "_blank")
          })
          .catch((e) => {
            handleConsoleError(e)
          })
          .finally(() => {
            setLoading(false)
          })
      }
    }
  }, [userToken, user, liveAuthTokens, emailIntegrationFeatureFlagEnabled])

  return { connectGmail, isGmailConnected, loading, watchInbox }
}
