import { AccessBoundary } from "@components/auth/DataAccess"
import { TableRowRenderer } from "@components/table/WindowedTable"
import { CompanyIdFields } from "common/model/Company"
import { isDataCustomer } from "common/model/Auth/Permissions"
import { Order, OrderDirection, OrderFirmness } from "common/model/Order/Order"
import { SplitDisplayPriceData } from "common/queries/pricing/PricingEnrichment"
import { Interval } from "common/utils/data/Interval"
import { formatDate } from "common/utils/dateUtils"
import { Either } from "common/containers/Either"
import { Just, Maybe, Nothing } from "common/containers/Maybe"
import { isDefined } from "common/utils/TypeUtils"
import { FC } from "react"
import { Link } from "react-router-dom"
import IntroductionRequestButton from "src/components/IntroductionRequest"
import { formatSharePrice, formatValuation } from "../../../../components/displays/numeric/Currency"
import { useLoggedInUser } from "../../../../providers/loggedInUser/useLoggedInUser"
import { Routes } from "../../../../Routes/Routes"
import { SplitAdjustmentTooltip } from "../../SplitAdjustmentTooltip"
import { tableDataAccessLevel } from "./utils"
import { OrderLookupFields } from "common/model/Order/Models/Internal"
import { orderStructureDisplayName } from "common/model/Order/Types/Terms"
import { OrderStructure, TradeStructure } from "common/model/Order/Types/Structure"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import { deprecatedIsLoaded } from "common/utils/Loading"
import { PriceObservationType } from "common/model/data-product/pricing/PriceObservation"
import { StructureDisplay } from "@stories/components/Orders/TableCells/StructureCell"
import MarketPill from "@stories/components/Badges/MarketPill/MarketPill"

const Cell: FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <td className="text-sm p-2 text-left">{children}</td>
)

export type OrderDirectionOrClosedTrade = OrderDirection | "closed_trade"

export const structureDisplayName = { ...orderStructureDisplayName, indirect: "indirect" }

export const AdminRecordLink: FC<
  React.PropsWithChildren<Pick<DataRowItem, "itemType" | "companyId" | "recordId">>
> = ({ itemType, companyId, recordId, children }) => {
  const user = useCurrentUser()
  return isDefined(recordId) && deprecatedIsLoaded(user) && user.isAdmin ? (
    <Link
      to={
        itemType === "closed_trade"
          ? Routes.admin.records.company(companyId).priceObservations(recordId)
          : Routes.admin.records.order({
              id: recordId,
              darkpool: false,
              orderCollection: "platform",
            })
      }
      target="_blank"
      rel="noopener noreferrer"
      className="hover:text-black"
    >
      {children}
    </Link>
  ) : (
    <>{children}</>
  )
}

export type PriceOrValuation = Maybe<
  Either<
    { valuation: number; impliedPPS: Maybe<number> },
    { pricePerShare: number; impliedValuation: Maybe<number> }
  >
>

export type DataRowItem = {
  itemType: OrderDirectionOrClosedTrade
  dates: Date
  companyId: string
  recordId?: string
  recordCollection?: Order["orderCollection"]
  sourceAttribution: Order["attribution"]
  recordAccountId: string
  prices: PriceOrValuation
  notional: Maybe<number>
  structures: (TradeStructure | OrderStructure)[]
  splitAdjustments: SplitDisplayPriceData[]
  showIntroductionRequestButton: boolean
  firmness: OrderFirmness | "N/A" | "unknown"
  subjectToROFR: Maybe<boolean>
  transactionDocumentsOnHand: Maybe<boolean>
  shareClasses: PriceObservationType["shareClass"][]
  managementFee: Maybe<number>
  carry: Maybe<number | boolean>
  structureLayersCount: Maybe<number | null>
  rofr: Maybe<PriceObservationType["rofr"]>
  orderId?: OrderLookupFields
  company: CompanyIdFields
}

export const renderDataRow: (
  showIntroductionRequestColumn: boolean,
  company: CompanyIdFields,
  onRowClick: (orderId: OrderLookupFields) => void
) => TableRowRenderer<DataRowItem> =
  (showIntroductionRequestColumn, company, onRowClick) =>
  ({ item }) => {
    const {
      itemType,
      dates,
      companyId,
      recordId,
      recordAccountId,
      prices,
      notional,
      structures,
      splitAdjustments,
      showIntroductionRequestButton,
    } = item
    const { user } = useLoggedInUser()
    const hideFromJournalist =
      itemType === "closed_trade" && user.account.clientType.includes("Journalist")
    const allowRowClick =
      !hideFromJournalist && (isDataCustomer(user.account) || showIntroductionRequestButton)

    const pillVariant =
      itemType === "buy"
        ? "bid"
        : itemType === "sell"
        ? "offer"
        : item.rofr.match(
            (rofrData) => (rofrData?.wasROFRd ? "rofrd_trade" : "closed_trade"),
            () => "closed_trade" as const
          )

    return (
      <tr
        className={`border-solid border-b border-neutral-400 ${
          itemType !== "closed_trade"
            ? `${allowRowClick ? "hover:bg-pale-blue cursor-pointer" : ""}`
            : ""
        }`}
        onClick={() =>
          itemType !== "closed_trade" && allowRowClick && recordId
            ? onRowClick({ id: recordId, darkpool: false, orderCollection: "platform" })
            : undefined
        }
        key={recordId}
      >
        <Cell>
          <div className="flex text-xs whitespace-nowrap">
            <AdminRecordLink companyId={companyId} recordId={recordId} itemType={itemType}>
              <MarketPill variant={pillVariant} />
            </AdminRecordLink>
          </div>
        </Cell>
        <Cell>
          <div className="text-xs lg:text-sm">{formatDate(dates)}</div>
        </Cell>
        {/* Data Cells */}
        {hideFromJournalist ? (
          <div className="w-4/6 pl-10 text-sm py-4 flex items-center text-neutral-600">
            Please contact Caplight for closed trade details.
          </div>
        ) : (
          <>
            <Cell>
              <div className="flex items-left flex-col text-xs">
                <AccessBoundary
                  accessLevel={
                    itemType === "closed_trade"
                      ? tableDataAccessLevel["transaction price"]
                      : tableDataAccessLevel["order price"](showIntroductionRequestButton)
                  }
                >
                  <div className="font-bold text-sm">
                    {prices.match(
                      (e) =>
                        e.match(
                          (p) =>
                            p.impliedPPS.map(formatSharePrice).match(
                              (pps) => `${pps} (est.)`,
                              () => "-"
                            ),
                          (p) => formatSharePrice(p.pricePerShare)
                        ),
                      () => "-"
                    )}
                    <SplitAdjustmentTooltip splitAdjustments={splitAdjustments} />
                  </div>
                </AccessBoundary>
                <AccessBoundary
                  accessLevel={
                    itemType === "closed_trade"
                      ? tableDataAccessLevel["transaction valuation"]
                      : tableDataAccessLevel["order valuation"](showIntroductionRequestButton)
                  }
                >
                  <div>
                    {prices.match(
                      (e) =>
                        e.match(
                          (p) => `${formatValuation(p.valuation)} val.`,
                          (p) =>
                            p.impliedValuation.map(formatValuation).match(
                              (v) => `${v} val. (est.)`,
                              () => "-"
                            )
                        ),
                      () => "-"
                    )}
                  </div>
                </AccessBoundary>
              </div>
            </Cell>
            <Cell>
              <div className="text-xs lg:text-sm">
                <AccessBoundary
                  accessLevel={
                    itemType === "closed_trade"
                      ? tableDataAccessLevel["transaction volume"]
                      : tableDataAccessLevel["order volume"](showIntroductionRequestButton)
                    /* change order access level to public once volume bucketing is in */
                  }
                >
                  {notional.match(formatValuation, () => "Undisclosed")}
                </AccessBoundary>
              </div>
            </Cell>
            <Cell>
              <AccessBoundary accessLevel={tableDataAccessLevel.structure}>
                <StructureDisplay
                  structures={structures}
                  managementFee={item.managementFee}
                  carry={item.carry.bind((c) => (typeof c === "number" ? Just(c) : Nothing))}
                  structureLayersCount={item.structureLayersCount}
                />
              </AccessBoundary>
            </Cell>
            {showIntroductionRequestColumn ? (
              <Cell>
                {showIntroductionRequestButton ? (
                  <IntroductionRequestButton
                    company={company}
                    anchorTradeOrOrder={{
                      createdAt: dates,
                      type: itemType !== "closed_trade" ? "order" : "closedTrade",
                      id: recordId ?? "",
                      accountId: recordAccountId,
                      direction: itemType === "closed_trade" ? null : itemType,
                      price: prices.match(
                        (e) =>
                          e.match(
                            ({ valuation }) => ({ targetValuation: valuation }),
                            ({ pricePerShare }) => ({ USDPerShare: Interval.pure(pricePerShare) })
                          ),
                        () => null
                      ),
                      volumeUSD: notional.toNullable(),
                      structure: structures?.[0]?.displayName() ?? "unknown", // probably not quite correct but consistent with previous behavior
                    }}
                    sourceComponent="company-details-trade-history"
                  />
                ) : null}
              </Cell>
            ) : null}
          </>
        )}
      </tr>
    )
  }
