import { RenewIcon } from "@stories/icons/RenewIcon"
import OrderHistoryItem from "./OrderHistoryItem"

const OrderHistoryRenewed = ({ date, isHorizontal }: { date: Date; isHorizontal?: boolean }) => (
  <OrderHistoryItem
    labelString="Renewed"
    iconComponent={RenewIcon}
    date={date}
    isHorizontal={isHorizontal}
  />
)

export default OrderHistoryRenewed
