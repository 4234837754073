import { createContext, FC, useCallback, useContext, useEffect, useMemo } from "react"
import { deprecatedIsLoaded, Loading } from "common/utils/Loading"
import { IntroductionRequest, introductionRequestConverter } from "common/model/IntroductionRequest"
import { collections } from "common/firestore/Collections"
import { useFirebaseReader } from "../firebase/Context"
import { useLoadingState } from "../utils/useLoadingState"
import { useLoggedInUser } from "./loggedInUser/useLoggedInUser"

interface IntroductionRequestProviderData {
  selectOrderIntroductionOfUser: (orderId: string | undefined) => IntroductionRequest | undefined
  selectIntroductionsForAdmin: (props: { active?: boolean }) => Loading<IntroductionRequest[]>
}

const IntroductionRequestContext = createContext<IntroductionRequestProviderData>({
  selectOrderIntroductionOfUser: () => undefined,
  selectIntroductionsForAdmin: () => "loading",
})

/** @deprecated */
const IntroductionRequestProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const firebase = useFirebaseReader()
  const { user, isAdmin } = useLoggedInUser()
  const userId = user.id

  const [introductionRequests, setIntroductionRequests] = useLoadingState<IntroductionRequest[]>()

  useEffect(
    () =>
      firebase.db
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */
        .collection(collections.introductionRequest)
        .where("createdBy.id", "==", userId)
        .withConverter(introductionRequestConverter)
        .onSnapshot((records) => {
          if (records) {
            return setIntroductionRequests(records.docs.map((doc) => doc.data()))
          }

          return setIntroductionRequests([])
        }),
    [firebase, userId, setIntroductionRequests]
  )

  const selectOrderIntroductionOfUser = useCallback(
    (orderId: string | undefined): IntroductionRequest | undefined => {
      if (orderId && deprecatedIsLoaded(introductionRequests)) {
        return introductionRequests.find((v) => v.priceObservationId.id === orderId)
      }
      return undefined
    },
    [introductionRequests]
  )
  const selectIntroductionsForAdmin = useCallback(
    ({ active }: { active?: boolean }) => {
      if (!isAdmin || !deprecatedIsLoaded(introductionRequests)) {
        return "loading"
      }
      return introductionRequests.filter((i) => {
        if (active) {
          return i.status === "requested"
        }
        return true
      })
    },
    [isAdmin, introductionRequests]
  )

  const contextData: IntroductionRequestProviderData = useMemo(
    () => ({
      selectOrderIntroductionOfUser,
      selectIntroductionsForAdmin,
    }),
    [selectOrderIntroductionOfUser, selectIntroductionsForAdmin]
  )

  return (
    <IntroductionRequestContext.Provider value={contextData}>
      {children}
    </IntroductionRequestContext.Provider>
  )
}

/** @deprecated */
export const useIntroductionRequests = () => useContext(IntroductionRequestContext)

export default IntroductionRequestProvider
