import React from "react"
import { classNames } from "src/utils/classNames"
import { Tooltip } from "../../Antd/Tooltip/Tooltip"
import { TooltipProps } from "../../Antd/TooltipProps"

export type StatusCircleVariant = "success" | "warning" | "danger" | "default" | "empty"

export const variantStyleMap: Record<StatusCircleVariant, string> = {
  success: "bg-success-300 border border-success-500",
  warning: "bg-warning-300 border border-warning-500",
  danger: "bg-danger-300 border border-danger-500",
  default: "bg-neutral-white border border-neutral-400",
  empty: "border border-neutral-400",
}

export interface StatusPillProps {
  variant: StatusCircleVariant
  tooltip?: TooltipProps["title"]
}

export const StatusCircle: React.FC<StatusPillProps> = ({ variant, tooltip }) => (
  <Tooltip title={tooltip}>
    <div className={classNames("w-4 h-4 rounded-full", variantStyleMap[variant])} />
  </Tooltip>
)
