/** Creates a memoized version of a function or hook, backed by a Map<,> cache.
 * Unlike react's useMemo, useCache hands back the memoized hook itself, rather than the its result.
 * This allows us to separate the lifecycle of the cache from that of the components using it without having to explicitly manage state on a parent component.
 * @example
 * const cachedGetIssuerById =
 *  useCache((issuerId: string) =>
 *    useCache((db: Firebase) => db.getIssuerById(issuerId)))
 *
 * export const useIssuer = (issuerId: string) => useDbState(cachedGetIssuerById(issuerId))
 */
export const createCache = <X, Y>(f: (x: X) => Y): ((x: X) => Y) => {
  const cache = new Map<X, Y>()
  return (x: X) => {
    if (!cache.get(x)) {
      cache.set(x, f(x))
    }
    return cache.get(x) as Y
  }
}
