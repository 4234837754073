import { AccountActivityLogEvent } from "common/model/AccountActivityLog/AccountActivityLog"
import { Loading, isLoaded, mapLoading, matchLoading } from "common/utils/Loading"
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { useFirebaseReader } from "src/firebase/Context"
import { getAllDocs } from "src/firebase/Firebase/utils"
import { useCurrentUser } from "../currentUser/useCurrentUser"
import { handleConsoleError } from "src/utils/Tracking"
import { useFeatureFlag } from "../featureFlags/useFeatureFlags"

interface AccountActivityLogContextType {
  activityLog: Loading<AccountActivityLogEvent[]>
  unreadCount: Loading<number>
  loadMore: () => void
  loadedAllEvents: boolean
}

export const AccountActivityLogContext = createContext<AccountActivityLogContextType>({
  activityLog: "loading",
  unreadCount: "loading",
  loadMore: () => {},
  loadedAllEvents: false,
})

const ACTIVITY_LOG_EVENT_LOAD_COUNT = 20

export const AccountActivityLogProvider = ({ children }: { children: ReactNode }) => {
  const firebase = useFirebaseReader()
  const user = useCurrentUser()
  const [initDate] = useState(new Date())
  const [newEvents, setNewEvents] = useState<AccountActivityLogEvent[]>([])
  const [activityLogEvents, setActivityLogEvents] =
    useState<Loading<AccountActivityLogEvent[]>>("loading")
  const [earliestEventTime, setEarliestEventTime] = useState<Date>(initDate)
  const [loadedAllEvents, setLoadedAllEvents] = useState<boolean>(false)
  const featureFlag = useFeatureFlag("account_activity_log")

  const userAccountId = matchLoading(user, (u) => u.user.account.id, undefined, undefined)

  const isActivityLogEnabled: Loading<boolean> = useMemo(
    () => (isLoaded(user) && isLoaded(featureFlag) ? featureFlag : "loading"),
    [featureFlag, user]
  )

  const loadMoreEvents = useCallback(() => {
    if (isActivityLogEnabled !== true) return
    if (!userAccountId) return
    const existingEvents: AccountActivityLogEvent[] = matchLoading(
      activityLogEvents,
      (event) => event,
      [],
      []
    )
    firebase
      .getAccountActivityLogEvents({
        accountId: userAccountId,
        limit: ACTIVITY_LOG_EVENT_LOAD_COUNT,
        beforeDate: earliestEventTime,
      })
      .get()
      .then(getAllDocs)
      .then((docs) => {
        const earliestTime = docs.length > 0 ? docs[docs.length - 1].eventTime : earliestEventTime
        setEarliestEventTime(earliestTime)
        setActivityLogEvents(existingEvents.concat(docs))
        if (docs.length < ACTIVITY_LOG_EVENT_LOAD_COUNT) setLoadedAllEvents(true)
      })
      .catch(handleConsoleError)
  }, [userAccountId, activityLogEvents, earliestEventTime, isActivityLogEnabled, firebase])

  // Initial load of events
  useEffect(() => {
    if (userAccountId && isActivityLogEnabled === true && !isLoaded(activityLogEvents)) {
      loadMoreEvents()
    }
  }, [userAccountId, loadMoreEvents, activityLogEvents, isActivityLogEnabled])

  // Listener for new events
  useEffect(() => {
    if (!userAccountId || isActivityLogEnabled !== true) return () => {}

    const unsubscribe = firebase
      .newAccountActivityLogEventsQuery(userAccountId, initDate)
      .onSnapshot((snapshot) => {
        const docs = snapshot.docs.map((doc) => doc.data())
        setNewEvents(docs.concat(newEvents))
      })

    return unsubscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccountId, firebase, isActivityLogEnabled])

  const loadingUnreadCount = useMemo(
    () =>
      mapLoading<AccountActivityLogEvent[], number>(
        (events) =>
          newEvents
            .concat(events)
            .filter(
              (event) =>
                isLoaded(user) &&
                event.user.id !== user.user.id &&
                (!user.user.productInteractionHistory.lastOpenedActivityLog ||
                  event.eventTime.valueOf() >
                    user.user.productInteractionHistory.lastOpenedActivityLog.valueOf())
            ).length
      )(activityLogEvents),
    [activityLogEvents, newEvents, user]
  )

  const allEvents = useMemo(
    () =>
      mapLoading<AccountActivityLogEvent[], AccountActivityLogEvent[]>((events) =>
        newEvents.concat(events)
      )(activityLogEvents),
    [activityLogEvents, newEvents]
  )

  const memomizedContext = useMemo(
    () => ({
      activityLog: allEvents,
      unreadCount: loadingUnreadCount,
      loadMore: loadMoreEvents,
      loadedAllEvents,
    }),
    [loadingUnreadCount, loadedAllEvents, allEvents, loadMoreEvents]
  )

  return (
    <AccountActivityLogContext.Provider value={memomizedContext}>
      {children}
    </AccountActivityLogContext.Provider>
  )
}

export const useAccountActivityLog = () => useContext(AccountActivityLogContext)
