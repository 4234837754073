import {
  getCumulativeSplitAdjustments,
  splitAdjustShareCount,
} from "../../../queries/enrichment/payload/SplitAdjustment"
import { last } from "../../../utils/data/Array/ArrayUtils"
import { Just, Maybe, Nothing } from "../../../containers/Maybe"
import { CompanyFundingRound, getPriorFullInformationRound } from "../../Company/FundingRound"
import { Company } from "../../Company"

export type ValuationToConvertIntoPricePerShare = { valuation: number; valuationDate: Date }

export const splitPricePerShareFromValuationInformationWrapper = (
  valuation: number | undefined,
  valuationDate: Date | undefined,
  firestoreIssuer: Pick<Company, "fundingRounds" | "stockSplits">,
  splitAtDate: Date
): Maybe<number> => {
  if (!valuation || !valuationDate) return Nothing
  return splitPricePerShareFromValuationInformation(
    { valuation, valuationDate },
    firestoreIssuer,
    splitAtDate
  ).map((x) => x.pricePerShare)
}

export const splitPricePerShareFromValuationInformation = (
  valuation: ValuationToConvertIntoPricePerShare,
  firestoreIssuer: Pick<Company, "fundingRounds" | "stockSplits">,
  splitAtDate: Date // will return PPS on the stock split basis for this day
): Maybe<{
  pricePerShare: number
  fundingRoundUsedIfNotLatest?: CompanyFundingRound
  adjustedShareCount: number
}> => {
  const { latestRound, latestRoundWithInfo, notLatestFundingRound } = getPriorFullInformationRound(
    firestoreIssuer.fundingRounds,
    valuation.valuationDate
  )
  const { valuation: valFromLastRound, pps } = latestRoundWithInfo || {}
  if (!valFromLastRound || !pps || !latestRound) return Nothing
  const shareCount = valFromLastRound / pps

  // we need to make sure that we adjust for any stock splits in between
  const adjustedShareCount = splitAdjustShareCount(
    shareCount,
    last(
      getCumulativeSplitAdjustments(
        firestoreIssuer.stockSplits
          .map((split) => split.splitData)
          .filter(
            (spl) =>
              latestRoundWithInfo?.date &&
              spl.date >= latestRoundWithInfo.date &&
              spl.date <= splitAtDate
          ),
        splitAtDate
      )
    ).match(
      (adj) => adj.cumulativeSplitRatio,
      () => ({ numerator: 1, denominator: 1 })
    )
  )
  return Just({
    pricePerShare: valuation.valuation / adjustedShareCount,
    adjustedShareCount,
    fundingRoundUsedIfNotLatest: notLatestFundingRound ? latestRoundWithInfo : undefined,
  })
}
