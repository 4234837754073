import { FC, ReactNode } from "react"
import { Link } from "react-router-dom"

export interface CardProps {
  children: ReactNode
  linkTo?: string
  className?: string
}

export const Card: FC<React.PropsWithChildren<CardProps>> = ({ children, linkTo }) => {
  const cardContent = (
    <div
      className={`p-4 rounded bg-neutral-white border flex flex-col gap-4 shadow-md ${
        linkTo ? "hover:bg-accent-100 hover:bg-opacity-20 cursor-pointer hover:shadow-sm" : ""
      }`}
    >
      {children}
    </div>
  )

  if (linkTo) return <Link to={linkTo}>{cardContent}</Link>

  return cardContent
}
