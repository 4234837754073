import { CovariantProxy, Proxy, covProxy } from "../utils/types/Proxy"

export type GetOneEndpoint<T> = { multiplicity: "one"; type: CovariantProxy<T> }
export type GetManyEndpoint<T> = { multiplicity: "many"; type: CovariantProxy<T> }

export const getOne = <T>() => ({ multiplicity: "one" as const, type: covProxy<T>() })
export const getMany = <T>() => ({ multiplicity: "many" as const, type: covProxy<T>() })
export const apiParams = <T>() => covProxy<T>()
export const apiBody = <T>() => covProxy<T>()
export const apiLocals = <T>() => covProxy<T>()

export type Endpoint = GetOneEndpoint<unknown> | GetManyEndpoint<unknown>

export type APINode = Endpoint | APISpec
type APIParams = CovariantProxy<Record<string, unknown>>
type APIBody = CovariantProxy<Record<string, unknown>>
type APILocals = CovariantProxy<Record<string, unknown>>

export type APISpec = {
  [key in string]:
    | APINode
    | { params?: APIParams; body?: APIBody; locals?: APILocals; node: APINode }
}

export type APIRoute<T> = T extends { node: Endpoint }
  ? readonly []
  : {
      [key in keyof T]: T[key] extends { params?: Proxy<infer V, infer B>; node: APISpec }
        ? readonly [key, B[keyof B], ...APIRoute<T[key]["node"]>]
        : T[key] extends { params?: Proxy<infer V, infer B> }
        ? readonly [key, B[keyof B], ...APIRoute<T[key]>]
        : readonly [key, ...APIRoute<T[key]>]
    }[keyof T]

type TryIndex<T, Key> = T[Key & keyof T]

export type EndpointParams<A extends APISpec, T extends APIRoute<A>> = T extends readonly [
  infer Head,
  ...infer Tail
]
  ? (TryIndex<A, T[0]> extends { params: CovariantProxy<infer B> } ? [B] : []) &
      (TryIndex<A, T[0]> extends APISpec
        ? Tail extends APIRoute<TryIndex<A, T[0]>>
          ? EndpointParams<TryIndex<A, T[0]>, Tail>
          : unknown
        : unknown)
  : never

export type EndpointBody<A extends APISpec, T extends APIRoute<A>> = T extends readonly [
  infer Head,
  ...infer Tail
]
  ? (TryIndex<A, T[0]> extends { body: CovariantProxy<infer B> } ? B : {}) &
      (TryIndex<A, T[0]> extends APISpec
        ? Tail extends APIRoute<TryIndex<A, T[0]>>
          ? EndpointBody<TryIndex<A, T[0]>, Tail>
          : unknown
        : unknown)
  : never

export type EndpointReturnType<A extends APISpec, T extends APIRoute<A>> = T extends readonly [
  infer Head,
  ...infer Tail
]
  ? TryIndex<A, T[0]> extends { node: GetOneEndpoint<infer B> }
    ? B
    : TryIndex<A, T[0]> extends { node: GetManyEndpoint<infer B> }
    ? B[]
    : TryIndex<A, T[0]> extends APISpec
    ? Tail extends APIRoute<TryIndex<A, T[0]>>
      ? EndpointReturnType<TryIndex<A, T[0]>, Tail>
      : never
    : never
  : never
