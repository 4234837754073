import { AccountIdFields } from "../model/Account"
import { GmailInbox } from "../model/Google/GmailInbox"
import { GmailMessage } from "../model/Google/GmailMessage"
import { UserAccessFields, UserIdFields } from "../model/User"
import { getOne, apiBody, apiLocals, APISpec, apiParams } from "./Spec"

export const GmailAPI = {
  "watch-emails": {
    body: apiBody<{}>(),
    locals: apiLocals<{ user: UserIdFields; account: AccountIdFields }>(),
    node: getOne<GmailInbox>(),
  },
  "sync-contact-emails": {
    // TODO: consolidate this and sync-emails
    body: apiBody<{
      contactEmail: string
    }>(),
    locals: apiLocals<{ user: UserAccessFields }>(),
    node: getOne<void>(),
  },
  email: {
    params: apiParams<{ emailId: string }>(),
    body: apiBody<{}>(),
    locals: apiLocals<{ user: UserAccessFields }>(),
    node: getOne<GmailMessage>(),
  },
  "send-reply": {
    body: apiBody<EmailReplyParams>(),
    locals: apiLocals<{ user: UserAccessFields }>(),
    node: getOne<void>(),
  },
} satisfies APISpec

export type EmailReplyParams = {
  replyBodyPlaintext: string
  emailDocId: string
}
