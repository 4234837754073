import { OrderCard } from "@components/ItemCards/OrderCard"
import { DarkpoolOrderPlacedEvent } from "common/model/AccountActivityLog/AccountActivityLog"
import React from "react"

interface DarkpoolOrderPlacedDetailsProps {
  event: DarkpoolOrderPlacedEvent
}

export const DarkpoolOrderPlacedDetails: React.FC<DarkpoolOrderPlacedDetailsProps> = ({
  event,
}) => {
  const { order } = event

  return <OrderCard order={order} hideDate />
}
