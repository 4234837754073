import { lazy } from "react"
import { trySessionStorage } from "./LocalStorage"

type LazyFn = typeof lazy

export const customLazyLoad: LazyFn = (componentImport) => {
  const refreshedString = trySessionStorage()?.getItem("page-has-been-force-refreshed")
  const pageHasAlreadyBeenForceRefreshed: boolean = refreshedString === "true"

  try {
    return lazy(async () => {
      const component = await componentImport()
      trySessionStorage()?.setItem("page-has-been-force-refreshed", "false")
      return component
    })
  } catch (error) {
    if (!pageHasAlreadyBeenForceRefreshed) {
      // clear cache and refresh the page
      return lazy(async () => {
        await caches.keys().then(async (names) => {
          // eslint-disable-next-line no-restricted-syntax
          for (const name of names) {
            await caches.delete(name)
          }
        })
        trySessionStorage()?.setItem("page-has-been-force-refreshed", "true")
        window.location.reload()
        const component = await componentImport()
        return component
      })
    }

    throw error
  }
}
