import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import React, { FC, useMemo, useState } from "react"
import Typography, { Size, Weight } from "../../../../stories/components/Typography/Typography"
import { Input } from "@stories/components/Inputs/Input/Input"
import { CompanySelectFilterSelect } from "./ContactSelectFilterSelect"
import { RecentFirms } from "./RecentFirms"
import { ContactSelect } from "./ContactSelect"
import {
  CompanyFilterSearchProp,
  ContactSelectSearchProps,
  defaultFilteredContacts,
  filterContacts,
} from "./shared"
import { CompanyFilterWarning } from "./CompanyFilterWarning"

// TODO - provider for all contacts
const ContactSelectSearch: FC<ContactSelectSearchProps> = ({
  onContactSelected,
  allContacts,
  hideIndividuals,
  hideFirms,
  initialCompanyFilter,
  additionalFilter,
  contactSearchType,
}) => {
  // React single-select wrapper across all contacts, sorted by name.
  // Displays contact name, type (firm/individual), and (if firm) associated contacts, (if individual) associated firm, and company holdings/buy interest
  // filters to contacts with company interest
  const [usingCompanyInterestFilterByDefault, defaultContacts] = useMemo(
    () =>
      defaultFilteredContacts(
        allContacts,
        { searchString: "", companyFilter: initialCompanyFilter },
        { hideFirms, hideIndividuals, additionalFilter }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const [searchString, setSearchString] = React.useState("")
  const [companyFilter, setCompanyFilter] = useState<CompanyFilterSearchProp | undefined>(
    usingCompanyInterestFilterByDefault ? initialCompanyFilter : undefined
  )
  const [contacts, setContacts] = useState<DealCRMContact[]>(defaultContacts)

  const handleSearchString = (s: string) => {
    setSearchString(s)
    setContacts(
      filterContacts(
        allContacts,
        { searchString: s, companyFilter },
        { hideFirms, hideIndividuals, additionalFilter }
      )
    )
  }
  const handleCompanyFilter = (type: CompanyFilterSearchProp["type"]) => {
    if (initialCompanyFilter) {
      const newFilter = type.length ? { ...initialCompanyFilter, type } : undefined
      setCompanyFilter(newFilter)
      setContacts(
        filterContacts(
          allContacts,
          { searchString, companyFilter: newFilter },
          { hideFirms, hideIndividuals, additionalFilter }
        )
      )
    }
  }

  return (
    <div className="flex flex-col gap-4 w-96">
      <div className="flex flex-col gap-1">
        <Input
          placeholder={
            hideIndividuals
              ? "Search for a Firm"
              : hideFirms
              ? "Search for a Fund or Contact"
              : "Search for a Firm, Fund or Contact"
          }
          onChange={(e) => handleSearchString(e.target.value)}
          value={searchString}
        />
        {initialCompanyFilter ? (
          <CompanySelectFilterSelect
            currentCompanyFilter={companyFilter}
            initialCompanyFilter={initialCompanyFilter}
            onCompanyFilterTypeChange={handleCompanyFilter}
          />
        ) : null}
      </div>

      <div className="flex h-1/2-screen flex-col gap-4">
        {!additionalFilter && hideIndividuals && searchString.length === 0 ? (
          <RecentFirms
            allContacts={allContacts}
            onContactSelected={onContactSelected}
            initialCompanyFilter={initialCompanyFilter}
          />
        ) : null}

        {searchString.length === 0 && (
          <Typography
            text={`All ${hideIndividuals ? "Firms" : hideFirms ? "Individuals" : "Contacts"}`}
            size={Size.Small}
            weight={Weight.Semibold}
          />
        )}

        <div className="flex flex-col gap-2 overflow-y-scroll">
          {contacts.map((contact) => (
            <ContactSelect
              key={contact.id}
              contact={contact}
              onContactSelected={onContactSelected}
              initialCompanyFilter={initialCompanyFilter}
            />
          ))}
          <CompanyFilterWarning
            allContacts={allContacts}
            searchString={searchString}
            companyFilter={companyFilter}
            onSetCompanyFilterTypes={handleCompanyFilter}
            viewOptions={{
              hideFirms,
              hideIndividuals,
            }}
          />
        </div>
        {additionalFilter && contacts.length === 0 ? (
          <div>No {contactSearchType ? `${contactSearchType}s` : "contacts"} found.</div>
        ) : null}
      </div>
    </div>
  )
}

export default ContactSelectSearch
