import { qToNumber, multiplyQ, Ratio, numberToQ } from "../../utils/data/numeric/Rational"
import { Boxed } from "../../utils/fp/Boxed"
import { isNonempty, unsafeAssertNonempty, scanLNonempty } from "../../utils/data/Array/Nonempty"
import * as Raw from "../Company"
import {
  splitPricePerShareFromValuationInformation,
  ValuationToConvertIntoPricePerShare,
} from "../data-product/ppsCalculation/PricePerShare"
import {
  PricePerShareToConvertIntoValuation,
  valuationFromPricePerShareInformation,
} from "../data-product/valuationCalculation/Valuation"

export class Company extends Boxed<Raw.Company> {
  get id() {
    return this.unboxed.id
  }

  get postgresId() {
    return this.unboxed.postgresCompanyId ?? null
  }

  cumulativeSplitRatio(from: Date, to: Date) {
    return qToNumber(
      this.unboxed.stockSplits
        .filter((split) => split.splitData.date >= from && split.splitData.date <= to)
        .map((split) => split.splitData.ratio)
        .reduce(multiplyQ, Ratio(1, 1))
    )
  }

  cumulativeStockSplits() {
    return isNonempty(this.unboxed.stockSplits)
      ? scanLNonempty(
          (l, r) => ({
            ...r,
            cumulativeSplitRatio: multiplyQ(l.cumulativeSplitRatio, r.splitRatio),
          }),
          unsafeAssertNonempty(
            this.unboxed.stockSplits.map((split) => ({
              cumulativeSplitRatio: numberToQ(1),
              splitDate: split.splitData.date,
              splitRatio: split.splitData.ratio,
            }))
          )
        )
      : []
  }

  splitPricePerShareFromValuation(
    valuationToConvert: ValuationToConvertIntoPricePerShare,
    splitDate: Date
  ) {
    return splitPricePerShareFromValuationInformation(valuationToConvert, this.unboxed, splitDate)
  }

  valuationFromPricePerShareInformation(
    pricePerShareToConvert: PricePerShareToConvertIntoValuation
  ) {
    return valuationFromPricePerShareInformation(pricePerShareToConvert, this.unboxed)
  }
}
