import React, { ReactElement } from "react"
import Typography, { Color, Size, Weight } from "../../Typography/Typography"
import { CheckCircleIcon } from "src/stories/icons/CheckCircleIcon"
import { InfoIcon } from "src/stories/icons/InfoIcon"
import { DangerIcon } from "src/stories/icons/DangerIcon"

export type PillVariant = "success" | "warning" | "danger" | "default"

export const variantStyleMap: Record<PillVariant, string> = {
  success: "border-success-500",
  warning: "border-warning-500",
  danger: "border-danger-600",
  default: "border-neutral-300",
}

const variantSubtitleMap: Record<PillVariant, string> = {
  success: "Good standing",
  warning: "Missing Orders",
  danger: "Account Restricted",
  default: "Disabled",
}

const variantIconMap: Record<PillVariant, ReactElement> = {
  success: <CheckCircleIcon color={Color.Success} />,
  warning: <InfoIcon color={Color.Warning} />,
  danger: <DangerIcon color={Color.Danger} />,
  default: <InfoIcon color={Color.Disabled} />,
}

export interface StatusPillProps {
  variant: PillVariant
}

export const StatusPill: React.FC<StatusPillProps> = ({ variant }) => {
  const [isHovered, setIsHovered] = React.useState(false)

  return (
    <div
      className={`py-1 px-2 rounded-md border-2 cursor-pointer text-center whitespace-nowrap inline-block max-w-max
      ${variantStyleMap[variant]}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex gap-4 items-center">
        <div className="flex flex-col gap-1 justify-start items-start">
          <Typography text="Status" weight={Weight.Semibold} size={Size.Small} />
          {isHovered && <Typography text={variantSubtitleMap[variant]} size={Size.XSmall} />}
        </div>
        {variantIconMap[variant]}
      </div>
    </div>
  )
}
