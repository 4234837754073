import { Account } from "common/model/Account"
import { Skeleton } from "@stories/components/Antd"
import { isLoaded } from "common/utils/Loading"
import Typography, { Size } from "@stories/components/Typography/Typography"
import {
  AccountNetworkNode,
  getAccountNetworkNodeFirmName,
  TrustedBrokerConnection,
} from "common/model/TrustedBrokerConnection"
import { TrustedBrokerSearch } from "src/pages/UserSettings/TrustedBrokerManagement/components/TrustedBrokerSearch"
import { useTrustedBrokerNetwork } from "src/pages/UserSettings/TrustedBrokerManagement/hooks/useTrustedBrokerNetwork"

const AccountNodeStatus = ({ node }: { node: AccountNetworkNode }) => (
  <div className="flex flex-col gap-2">
    <Typography text={getAccountNetworkNodeFirmName(node)} />
    <Typography text={node.status} size={Size.XXSmall} />
  </div>
)

export const ConnectionCard = ({ connection }: { connection: TrustedBrokerConnection }) => (
  <div className="flex flex-row justify-between border rounded p-8">
    <AccountNodeStatus node={connection.leftAccount} />
    <AccountNodeStatus node={connection.rightAccount} />
  </div>
)

export const AdminTrustedBrokerConnectionsControl = ({ account }: { account: Account }) => {
  const { currentTrustedBrokerConnections, handleAddToNetwork } = useTrustedBrokerNetwork({
    account,
  })

  if (!isLoaded(currentTrustedBrokerConnections)) return <Skeleton />

  return (
    <div className="flex flex-col gap-4 m-4">
      <Typography text={`Add Connection to ${account.name}`} size={Size.XXSmall} />
      <TrustedBrokerSearch
        connections={currentTrustedBrokerConnections}
        handleAddToNetwork={(props) => handleAddToNetwork({ ...props, adminConnection: true })}
      />
      {currentTrustedBrokerConnections.map((c) => (
        <ConnectionCard connection={c} key={c.id} />
      ))}
    </div>
  )
}
