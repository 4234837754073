import Table from "@stories/components/Table/Table"
import { FilterBarComponent } from "@stories/components/Table/types"
import { DealCRMInterest } from "common/model/DealCRM/DealCRMInterest"
import { TableColumnFilter, TableColumnState } from "common/model/Settings/TableSettings"
import { AddInterestRow } from "./AddInterestRow"
import { useInterestTable } from "./useInterestTable"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import { handleConsoleError } from "src/utils/Tracking"
import { useMemo } from "react"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"

interface AllInterestTableProps {
  data: DealCRMInterest[]
  columns: ReturnType<typeof useInterestTable>["columns"]
  filterBarComponents: FilterBarComponent[]
  advancedFilterComponents: FilterBarComponent[]
  initialFilterState?: TableColumnFilter[]
  additionalPanelComponents?: React.ReactNode
  defaultPaginationPageSize?: number
  isLoading?: boolean
  showColumnConfigButton?: boolean
  afterCreateInterest?: (interest: DealCRMInterest) => void
}

const hiddenBrokerColumnIds = ["managementFeeStructure"]

const hiddenNonBrokerColumnIds = ["commission", "totalFee"]

export const AllInterestTable = ({
  data,
  columns,
  filterBarComponents,
  advancedFilterComponents,
  additionalPanelComponents,
  defaultPaginationPageSize = 50,
  isLoading = false,
  showColumnConfigButton = true,
  initialFilterState,
  afterCreateInterest,
}: AllInterestTableProps) => {
  const user = useLoggedInUser()
  const firebase9 = useFirebase9()

  const isUserABroker = user.user.account.clientType.includes("Intermediary")

  const filteredColumns = columns.filter(
    (col) =>
      !(isUserABroker ? hiddenBrokerColumnIds : hiddenNonBrokerColumnIds).includes(col.id ?? "")
  )

  const onColumnStateChange = useMemo(
    () => (state: TableColumnState) =>
      firebase9
        .updateUserAllInterestTableSettingState(user.user.id, state)
        .catch(handleConsoleError),
    // NOTE: user.user changes when saving settings, using user.user.id is stable
    [user.user.id, firebase9]
  )

  const brokerSpecificColumnOrder = ["managementFeePercent", "carry", "updated"]

  // Adjust columns for broker users to place managementFeePercent and carry at the end of the table
  const defaultColumnOrder: string[] = isUserABroker
    ? [
        ...filteredColumns.flatMap((col) =>
          !brokerSpecificColumnOrder.includes(col.id ?? "") ? col.id ?? [] : []
        ),
        ...brokerSpecificColumnOrder,
      ]
    : filteredColumns.flatMap((col) => col.id ?? [])

  const initialColumnOrder =
    user.user.crmPreferences?.allInterestTable?.columnOrder ?? defaultColumnOrder

  // TODO: this is where you could configure default visibility -- `true | undefined` is visible, `false` is hidden, but allowed to be made visible
  const defaultColumnVisibility = {}

  const initialColumnVisibility =
    user.user.crmPreferences?.allInterestTable?.columnVisibility ?? defaultColumnVisibility

  return (
    <Table
      isLoading={isLoading}
      data={data}
      columns={filteredColumns}
      initialColumnOrder={initialColumnOrder}
      initialColumnVisibility={initialColumnVisibility}
      defaultSortBy="updated"
      defaultSortDirection="desc"
      defaultPaginationPageSize={defaultPaginationPageSize}
      variant="grid"
      getRowId={(row) => row.id}
      filterBarComponents={filterBarComponents}
      advancedFilterComponents={advancedFilterComponents}
      additionalPanelComponents={additionalPanelComponents}
      globalFilter={{ placeholder: "Search by Company or Contact" }}
      beforeRows={
        <AddInterestRow colSpan={columns.length} afterCreateInterest={afterCreateInterest} />
      }
      showColumnConfigButton={showColumnConfigButton}
      onColumnStateChange={onColumnStateChange}
      isHeaderSticky
      initialColumnFilters={initialFilterState}
    />
  )
}

export default AllInterestTable
