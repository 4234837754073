import {
  getCumulativeSplitAdjustments,
  splitAdjustPrice,
  splitAdjustShareCount,
} from "../../../queries/enrichment/payload/SplitAdjustment"
import { last } from "../../../utils/data/Array/ArrayUtils"
import { Just, Maybe, Nothing } from "../../../containers/Maybe"
import {
  CompanyFundingRound,
  getPriorFullInformationRound,
  getLatestRoundForCalculations,
} from "../../Company/FundingRound"
import { StockSplitData } from "../../Stock/StockSplit"
import { Company } from "../../Company"

export type PricePerShareToConvertIntoValuation =
  | { price: number; priceDate: Date; isSplitAdjusted: false } // a bit redundant but explicit
  | { price: number; priceDate: Date; isSplitAdjusted: true; splitAdjustedForDate: Date }

const isNotSplitAdjusted = (
  x: PricePerShareToConvertIntoValuation
): x is { price: number; priceDate: Date; isSplitAdjusted: false } => x.isSplitAdjusted === false
const isSplitAdjusted = (
  x: PricePerShareToConvertIntoValuation
): x is { price: number; priceDate: Date; isSplitAdjusted: true; splitAdjustedForDate: Date } =>
  x.isSplitAdjusted === true && "splitAdjustedForDate" in x

export const getValuationFromTodaysSplitAdjustedData = (
  pricePerShare: number,
  priceDate: Date,
  company: Pick<Company, "fundingRounds" | "stockSplits">
) =>
  valuationFromPricePerShareInformation(
    { price: pricePerShare, isSplitAdjusted: true, priceDate, splitAdjustedForDate: new Date() },
    company
  )

export const getValuationFromTheDaysSplitAdjustedData = (
  pricePerShare: number,
  priceDate: Date,
  company: Pick<Company, "fundingRounds" | "stockSplits">
) =>
  valuationFromPricePerShareInformation(
    { price: pricePerShare, isSplitAdjusted: true, priceDate, splitAdjustedForDate: priceDate },
    company
  )

export const valuationFromPricePerShareInformationWrapper = (
  price: number | undefined,
  date: Date | undefined,
  firestoreIssuer: Pick<Company, "fundingRounds" | "stockSplits">
): Maybe<number> => {
  if (!price || !date) return Nothing
  return valuationFromPricePerShareInformation(
    { price, priceDate: date, isSplitAdjusted: false },
    firestoreIssuer
  ).map((x) => x.valuation)
}

export const splitAdjustPriceToToday = (
  px: number,
  date: Date,
  stockSplitsData: StockSplitData[]
) => {
  const cumulativeSplit = last(
    getCumulativeSplitAdjustments(
      stockSplitsData.filter((spl) => spl.date > date),
      new Date()
    )
  ).match(
    (adj) => adj.cumulativeSplitRatio,
    () => ({ numerator: 1, denominator: 1 })
  )
  const priceAdjusted = splitAdjustPrice(px, cumulativeSplit)
  return priceAdjusted
}

export const valuationFromPricePerShareInformation = (
  pricePerShare: PricePerShareToConvertIntoValuation,
  firestoreIssuer: Pick<Company, "fundingRounds" | "stockSplits">
): Maybe<{
  valuation: number
  fundingRoundUsedIfNotLatest?: CompanyFundingRound
  adjustedShareCount: number
  fundingRoundDate?: Date
}> => {
  const { latestRound, latestRoundWithInfo, notLatestFundingRound } = getPriorFullInformationRound(
    firestoreIssuer.fundingRounds,
    pricePerShare.priceDate
  )
  const { valuation, pps, date } = latestRoundWithInfo || {}
  if (!valuation || !pps || !latestRound) return Nothing

  const stockSplitsData = firestoreIssuer.stockSplits.map((split) => split.splitData)
  const shareCount = valuation / pps

  if (isNotSplitAdjusted(pricePerShare)) {
    // we need to make sure that we adjust for any stock splits in between
    const shareCountAdjustedForLatestToLatestKnownFR = last(
      getCumulativeSplitAdjustments(
        stockSplitsData.filter((spl) => spl.date >= latestRound.date),
        pricePerShare.priceDate
      )
    ).match(
      (adj) => splitAdjustShareCount(shareCount, adj.cumulativeSplitRatio),
      () => shareCount
    )

    return Just({
      valuation: shareCountAdjustedForLatestToLatestKnownFR * pricePerShare.price, // need to unadjust price if there was a stock split between the price date and the funding round date
      adjustedShareCount: shareCountAdjustedForLatestToLatestKnownFR,
      fundingRoundUsedIfNotLatest: notLatestFundingRound ? latestRoundWithInfo : undefined,
      fundingRoundDate: date,
    })
  }

  if (isSplitAdjusted(pricePerShare)) {
    const cumulativeSplitRatio = last(
      getCumulativeSplitAdjustments(
        stockSplitsData.filter(
          (spl) =>
            latestRoundWithInfo?.date &&
            spl.date > latestRoundWithInfo?.date &&
            spl.date <= pricePerShare.splitAdjustedForDate
        ),
        pricePerShare.splitAdjustedForDate
      )
    )

    const adjustedShareCount = splitAdjustShareCount(
      shareCount,
      cumulativeSplitRatio.match(
        (adj) => adj.cumulativeSplitRatio,
        () => ({ numerator: 1, denominator: 1 })
      )
    )

    return Just({
      valuation: adjustedShareCount * pricePerShare.price,
      adjustedShareCount,
      fundingRoundUsedIfNotLatest: notLatestFundingRound ? latestRoundWithInfo : undefined,
      fundingRoundDate: date,
    })
  }
  return Nothing
}

export const estimateValuationChange = (
  price: number,
  company: Pick<Company, "fundingRounds" | "stockSplits">,
  cutoff: Date = new Date()
) => {
  // make sure it's consistent with what you are displaying (i.e. partialFundingRound vs Full information funding round)
  const { latestRoundWithInfo } = getLatestRoundForCalculations(company.fundingRounds, cutoff)

  if (!latestRoundWithInfo?.valuation) return undefined

  const newValuation = valuationFromPricePerShareInformation(
    { price, priceDate: cutoff, splitAdjustedForDate: cutoff, isSplitAdjusted: true },
    company
  ).match(
    (x) => x.valuation,
    () => undefined
  )

  if (!newValuation) return undefined

  return newValuation / latestRoundWithInfo.valuation
}
