import { CheckCircleIcon } from "@stories/icons/CheckCircleIcon"
import { WrappedIcon } from "@stories/icons/IconWrapper"
import Typography, { Color, Size, Weight } from "../Typography/Typography"

export type Feature = {
  name: string
  description?: string
}

export type FeatureListProps = {
  features: Feature[]
  icon?: WrappedIcon
}

export const FeatureList = ({ features, icon: Icon = CheckCircleIcon }: FeatureListProps) => (
  <div className="flex flex-col space-y-2">
    {features.map(({ name, description }) => (
      <div className="flex space-x-2 items-baseline" key={name}>
        <div className="block">
          <Icon color={Color.Link} />
        </div>
        <div className="flex flex-col">
          <Typography
            text={name}
            weight={Weight.Semibold}
            color={Color.Primary}
            size={Size.Small}
          />
          <Typography text={description} size={Size.XSmall} />
        </div>
      </div>
    ))}
  </div>
)
