export function sortByStringValue<T, K extends keyof T>(attribute: K & string) {
  return (a: T, b: T) => (a[attribute] > b[attribute] ? 1 : b[attribute] > a[attribute] ? -1 : 0)
}
export function sortByParsedStringValue<T, K extends keyof T>(attribute: K & string) {
  return (a: T, b: T) =>
    parseFloat(String(a[attribute])) > parseFloat(String(b[attribute]))
      ? 1
      : parseFloat(String(b[attribute])) > parseFloat(String(a[attribute]))
      ? -1
      : 0
}

export const reverseSort =
  <T>(sort: (a: T, b: T) => number) =>
  (a: T, b: T) =>
    -sort(a, b)
