import { Company } from "common/model/Company"
import { matchLoading } from "common/utils/Loading"
import { map2Map, unsafeInsertOrAppend } from "common/utils/MapUtils"
import { Nonempty } from "common/utils/data/Array/Nonempty"
import { createContext, ReactNode, useContext, useMemo } from "react"
import { getAllCompanies } from "src/firebase/Firebase/data-product/Companies"
import { useDbState } from "../../utils/useDb"

export const AllCompaniesContext = createContext<Company[]>([])

export const AdminAllCompaniesProvider = ({ children }: { children: ReactNode }) => {
  const allCompaniesLoading = useDbState((dbb) => getAllCompanies(dbb), [])
  const companies = useMemo(
    () =>
      matchLoading(
        allCompaniesLoading,
        (loadedCompanies) => loadedCompanies.filter((c) => c.pbid && c.postgresCompanyId && c.name),
        [],
        []
      ),
    [allCompaniesLoading]
  )
  return <AllCompaniesContext.Provider value={companies}>{children}</AllCompaniesContext.Provider>
}

export const AllCompaniesProvider = ({ children }: { children: ReactNode }) => {
  const allCompaniesLoading = useDbState((dbb) => getAllCompanies(dbb), [])
  const companies = useMemo(
    () =>
      matchLoading(
        allCompaniesLoading,
        (loadedCompanies) => loadedCompanies.filter((c) => c.pbid && c.postgresCompanyId && c.name),
        [],
        []
      ),
    [allCompaniesLoading]
  )
  return <AllCompaniesContext.Provider value={companies}>{children}</AllCompaniesContext.Provider>
}

export const useAllCompaniesAdminOnly = () => useContext(AllCompaniesContext)

const useCompanyLookup = <T,>(f: (c: Company) => T) => {
  const companies = useAllCompaniesAdminOnly()

  return useMemo(() => {
    const companiesMap = new Map<T, Nonempty<Company>>()
    companies?.forEach((c) => {
      unsafeInsertOrAppend(companiesMap)(f(c), c)
    })
    return companiesMap
  }, [companies, f])
}

export const useUniqueCompanyLookup = <T,>(f: (c: Company) => T) => {
  const companiesMap = useCompanyLookup(f)
  return useMemo(() => map2Map(companiesMap)((x) => x[0]), [companiesMap])
}
