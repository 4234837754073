import { createContext, useContext, useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import { NavItem, NavSectionType, navItems, navSections } from "src/Routes/NavItems"
import { Routes } from "src/Routes/Routes"
import { useViewport } from "src/utils/useViewport"

type NavSectionStateItem = {
  open: boolean
  active: boolean
  toggle: () => void
}

type NavSectionState = Partial<Record<NavSectionType, NavSectionStateItem>>
type NavigationCtx = {
  sectionState: NavSectionState
}

const NavigationContext = createContext<NavigationCtx>({ sectionState: {} })

export const NavigationProvider: React.FC<React.PropsWithChildren<{ mobileNav?: boolean }>> = ({
  children,
  mobileNav = false,
}) => {
  const viewport = useViewport()
  const location = useLocation()
  const activeSection = useMemo(
    () => navItems.find(({ route }) => route(Routes) === location.pathname)?.section,
    [location.pathname]
  )

  const initialSections = useMemo(
    () =>
      activeSection
        ? [activeSection].concat(navSections.filter((s) => s !== activeSection))
        : [...navSections],
    [activeSection]
  )
  const [queue, setQueue] = useState<NavSectionType[]>(initialSections)

  const maxQueueLength = useMemo(() => {
    if (mobileNav) {
      return 1
    }

    if (viewport.height > 900) {
      return navSections.length
    }

    if (viewport.height > 720) {
      return 3
    }

    return 2
  }, [viewport.height, mobileNav])

  useEffect(() => {
    if (maxQueueLength < queue.length) {
      setQueue(queue.slice(0, maxQueueLength))
    }
  }, [initialSections, maxQueueLength, queue])

  const sectionState: NavSectionState = useMemo(() => {
    const entries = navSections.map((section) => {
      const active = activeSection === section
      const open = queue.includes(section)

      return [
        section,
        {
          open,
          active,
          toggle: () =>
            setQueue((prev) => {
              const filtered = prev.filter((s) => s !== section)
              return prev.includes(section)
                ? filtered
                : [section].concat(filtered.slice(0, maxQueueLength - 1))
            }),
        },
      ] as const
    })

    return Object.fromEntries(entries)
  }, [maxQueueLength, queue, activeSection])

  const value = useMemo(() => ({ sectionState }), [sectionState])

  return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>
}

export const useNavigation = () => useContext(NavigationContext)
