import { Tooltip } from "@stories/components/Antd/Tooltip/Tooltip"
import { notification } from "@stories/components/Antd/notification"
import { Button } from "@stories/components/Button/Button"
import {
  SharedOrder,
  SharedWithId,
  sharedOrderRoute,
  isSharedOrderSharedWithUser,
} from "common/model/SharedOrder/SharedOrder"

export const CopySharedOrderAccessLink = ({
  sharedOrder,
  sharedWithId,
}: {
  sharedOrder: SharedOrder
  sharedWithId: SharedWithId
}) => {
  const handleCopyToClipBoard = () => {
    const link = `${window.location.host}${sharedOrderRoute(sharedOrder.id, sharedWithId)}`
    navigator.clipboard
      .writeText(link)
      .then(() => notification.success({ message: "Link Copied to Clipboard" }))
      .catch(() =>
        notification.warn({
          message: `Link couldn't by automatically copied. But here it is: ${link}`,
        })
      )
  }
  if (isSharedOrderSharedWithUser(sharedOrder, sharedWithId)) {
    return (
      <Tooltip title="Click to copy secret link to your clipboard.">
        <div>
          <Button variant="hollow" label="Copy" onClick={handleCopyToClipBoard} />
        </div>
      </Tooltip>
    )
  }
  return null
}
