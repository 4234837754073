import Divider from "@stories/components/Divider/Divider"
import { FC } from "react"
import { classNames } from "src/utils/classNames"

type WrapperProps = {
  main: React.ReactNode
  sidebar: React.ReactNode
  wrapperClasses?: string
  mainClasses?: string
}

const OuterWrapper: FC<React.PropsWithChildren> = ({ children }) => (
  <div className="w-full p-8 pt-0 mb-16">{children}</div>
)

const Wrapper = ({ main, sidebar, wrapperClasses, mainClasses }: WrapperProps) => (
  <OuterWrapper>
    <div className={`w-full grid grid-cols-1 xl:grid-cols-3 gap-4 ${wrapperClasses ?? ""}`}>
      <div className={`w-full flex flex-col space-y-6 col-span-2 ${mainClasses ?? ""}`}>{main}</div>
      <div className="w-full flex flex-col space-y-6">{sidebar}</div>
    </div>
  </OuterWrapper>
)

const Footer = ({ footer }: { footer: React.ReactNode }) => (
  <div className="absolute bottom-0 p-4 w-full bg-neutral-white z-30">{footer}</div>
)
const Header = ({
  header,
  beforeDivider,
}: {
  header: React.ReactNode
  beforeDivider?: React.ReactNode
}) => (
  <>
    <div className={classNames("w-full md:sticky z-50", "top-0 bg-neutral-white p-8 pb-4")}>
      {header}
    </div>
    {beforeDivider}
    <div className="mb-4">
      <Divider />
    </div>
  </>
)

export default { Wrapper, Footer, Header, OuterWrapper }
