import { CompanyMarketHistorySummary } from "common/model/CompanyMarketHistorySummary"
import _ from "lodash"
import { MutualFundMarkParsed } from "common/model/postgres/PostgresFundMarks"
import { CompanyWithPostgres } from "common/model/Company"
import { defaultIfLoading, deprecatedIsLoaded, Loading, Requesting } from "common/utils/Loading"
import {
  CaplightPriceEstimate,
  CaplightPriceEstimateMetaFromPostgres,
  CaplightPriceEstimateQualityScore,
} from "common/model/postgres/PostgresCaplightPriceEstimate"
import { ThematicIndex } from "common/model/postgres/PostgresThematicIndex"
import { isNonempty } from "common/utils/data/Array/Nonempty"
import { ArrayDataRequest } from "common/utils/ArrayDataRequest"
import moment from "moment"
import { MANUALLY_CONFIRMED } from "common/model/postgres/PostgresCompanyComp"
import { annotate } from "common/utils/Coerce"
import {
  ADD_COMP_INFO,
  GET_PBID_INFO,
  GET_STATUS_INFO,
  DELETE_COMP_INFO,
  GET_ISSUER_COMPS,
  CONFIRM_COMP_INFO,
  GET_TARGET_ISSUERS,
  GET_FUNDAMENTAL_INFO,
  ADD_TO_TARGET_ISSUERS,
  RECEIVE_PRICE_INSIGHT,
  ADD_ANOTHER_ISSUER_COMP,
  RECEIVE_PUBLIC_COMPS_INDEX,
  RECEIVE_PRICE_ESTIMATE_SCORES,
  RECEIVE_MOST_VIEWED_COMPANIES,
  ADD_TO_LOADING_TARGET_ISSUERS,
  GET_VOLATILITY_COMPANY_AND_COMPS,
  REMOVE_FROM_LOADING_TARGET_ISSUERS,
  MARKETPRICE_COMPANIES_REQUEST_STATUS,
  SET_CURRENT_COMPS_SUMMARY,
  RECEIVE_DEFAULT_CAPLIGHT_PRICE_ESTIMATE_FOR_A_COMPANY,
  RECEIVE_CAPLIGHT_PRICE_ESTIMATE_FOR_CAPLIGHT_DATA_COMPANIES,
  RECEIVE_CAPLIGHT_PRICE_ESTIMATE_FOR_A_COMPANY,
  RECEIVE_CAPLIGHT_PRICE_ESTIMATE_META_FOR_A_COMPANY,
  RECEIVE_CAPLIGHT_PRICE_ESTIMATE_MODEL_LIST,
  RECEIVE_CAPLIGHT_PRICE_ESTIMATE_FOR_CAPLIGHT_DATA_COMPANIES_ALTERNATIVE_MODEL,
  RECEIVE_MUTUAL_FUND_MARKS_FOR_A_COMPANY,
  RECEIVE_THEMATIC_INDICES,
  RECEIVE_PRICE_ESTIMATE_FIXING,
  Comp,
  RECEIVE_MARKET_INSIGHT,
  CompanyMarketInsightsType,
  CAPLIGHT_DATA_COMPANIES_REQUEST_STATUS,
  RECEIVE_ALL_SECTOR_LIST,
} from "../actions/postgresData"
import {
  CompanyIds,
  CompsListRow,
  CurrentCompsSummary,
  FundamentalDataFromPostgres,
  IVolatilityDataForCompanyAndComps,
  PublicCompsIndexData,
  StatusString,
  CaplightPriceEstimateErrorResponse,
  CaplightPriceEstimateResponse,
} from "../model/postgresData"

// Define a type for the slice state
interface PostgresDataState {
  targetIssuers: Loading<CompanyWithPostgres[]>
  caplightDataCompaniesRequestStatus: Loading<boolean>
  marketPriceCompaniesRequestStatus: Loading<boolean>
  targetIssuersWereRequested: boolean
  loadingTargetIssuers: CompanyIds[]
  compsLists: {
    direct: { [companyId: string]: CompsListRow[] | "loading" }
    reverse: { [companyId: string]: CompsListRow[] }
  }
  externalPbids: { [companyId: string]: string }
  companyStatus: { [companyId: string]: StatusString }
  fundamentalInfo: { [companyId: string]: FundamentalDataFromPostgres[] }
  volatilityData: { [companyId: string]: IVolatilityDataForCompanyAndComps[] }
  publicCompsIndices: { [companyId: string]: PublicCompsIndexData[] | "loading" | undefined }
  currentCompsSummaryData: CurrentCompsSummary[]
  caplightPriceEstimateFromPostgres: {
    [companyId: string]: Loading<CaplightPriceEstimate[]>
  }
  caplightPriceEstimateModelVersion: {
    [companyId: string]: string
  }
  /** @deprecated */
  caplightPriceEstimateFromPostgresAlternativeModel: Loading<{
    [companyId: string]: Loading<CaplightPriceEstimate[]>
  }>
  caplightPriceEstimateMetaFromPostgres: Loading<{
    [companyId: string]: Loading<CaplightPriceEstimateMetaFromPostgres>
  }>
  availablePriceEstimateModels: Loading<{ fullList: string[]; default: string }>
  mutualFundMarks: { [companyId: string]: Loading<MutualFundMarkParsed[]> }
  thematicIndices: Requesting<ThematicIndex[]>
  priceEstimateMonthlyFixing: {
    [companyId: string]: ArrayDataRequest<CaplightPriceEstimate>
  }
  caplightPriceEstimateScores: { [companyId: string]: Loading<CaplightPriceEstimateQualityScore[]> }
  insightsData: {
    companies: Record<
      CompanyMarketInsightsType,
      Record<
        keyof Omit<CompanyMarketHistorySummary, "updatedAt">,
        { byUSD: Loading<string[]>; byCount: Loading<string[]> }
      >
    >
    companyPrices: {
      positivePriceChanges: Record<
        keyof Omit<CompanyMarketHistorySummary, "updatedAt">,
        Loading<string[]>
      >
      negativePriceChanges: Record<
        keyof Omit<CompanyMarketHistorySummary, "updatedAt">,
        Loading<string[]>
      >
    }
    companyMetrics: {
      mostViewed: Record<
        keyof Omit<CompanyMarketHistorySummary, "updatedAt">,
        Loading<{ companyPostgresId: string; viewsPercent: number; sessions: number }[]>
      >
    }
  }
  sectors: Loading<string[]>
}

const defaultRollup: Record<
  keyof Omit<CompanyMarketHistorySummary, "updatedAt">,
  { byUSD: Loading<string[]>; byCount: Loading<string[]> }
> = {
  past7Days: { byUSD: null, byCount: null },
  past30Days: { byUSD: null, byCount: null },
  past90Days: { byUSD: null, byCount: null },
  past180Days: { byUSD: null, byCount: null },
  pastYear: { byUSD: null, byCount: null },
  past2Years: { byUSD: null, byCount: null },
}
const defaultPriceRollup: Record<
  keyof Omit<CompanyMarketHistorySummary, "updatedAt">,
  Loading<string[]>
> = {
  past7Days: null,
  past30Days: null,
  past90Days: null,
  past180Days: null,
  pastYear: null,
  past2Years: null,
}

// Define the initial state using that type
const initialState: PostgresDataState = {
  targetIssuers: null,
  caplightDataCompaniesRequestStatus: false,
  marketPriceCompaniesRequestStatus: false,
  targetIssuersWereRequested: false,
  loadingTargetIssuers: [],
  compsLists: { direct: {}, reverse: {} },
  externalPbids: {},
  companyStatus: {},
  fundamentalInfo: {},
  volatilityData: {},
  publicCompsIndices: {},
  currentCompsSummaryData: [],
  caplightPriceEstimateFromPostgres: {},
  caplightPriceEstimateModelVersion: {},
  caplightPriceEstimateFromPostgresAlternativeModel: null,
  caplightPriceEstimateMetaFromPostgres: null,
  availablePriceEstimateModels: null,
  mutualFundMarks: {},
  thematicIndices: "not requested yet",
  priceEstimateMonthlyFixing: {},
  caplightPriceEstimateScores: {},
  insightsData: {
    companies: {
      most_trades: defaultRollup,
      most_bids: defaultRollup,
      most_asks: defaultRollup,
      most_relative_asks: defaultRollup,
      most_relative_bids: defaultRollup,
    },
    companyPrices: {
      negativePriceChanges: defaultPriceRollup,
      positivePriceChanges: defaultPriceRollup,
    },
    companyMetrics: {
      mostViewed: {
        past7Days: null,
        past30Days: null,
        past90Days: null,
        past180Days: null,
        pastYear: null,
        past2Years: null,
      },
    },
  },
  sectors: null,
}

export type ReducerAction<Tag, Payload> = {
  type: Tag
} & Payload

export type ReceiveCaplightPriceEstimateAction = ReducerAction<
  typeof RECEIVE_CAPLIGHT_PRICE_ESTIMATE_FOR_A_COMPANY,
  {
    companyId: string
    caplightPriceEstimate: Loading<
      CaplightPriceEstimateResponse | CaplightPriceEstimateErrorResponse
    >
    caplightPriceEstimateForACompany: Loading<CaplightPriceEstimate[]>
  }
>

/* eslint-disable prettier/prettier */
export type PostgresDataReducerAction =
  | ReceiveCaplightPriceEstimateAction
  | ReducerAction<typeof RECEIVE_ALL_SECTOR_LIST, { sectors: Loading<string[]> }>
  | ReducerAction<
      typeof RECEIVE_PRICE_ESTIMATE_SCORES,
      { companyId: string; scores: Loading<CaplightPriceEstimateQualityScore[]> }
    >
  | ReducerAction<
      typeof RECEIVE_DEFAULT_CAPLIGHT_PRICE_ESTIMATE_FOR_A_COMPANY,
      {
        companyId: string
        caplightPriceEstimateForACompany: Loading<CaplightPriceEstimate[]>
        modelVersion: string
      }
    >
  | ReducerAction<
      typeof RECEIVE_MOST_VIEWED_COMPANIES,
      {
        timeFrame: keyof Omit<CompanyMarketHistorySummary, "updatedAt">
        companyPostgresIds: Loading<{ companyPostgresId: string; viewsPercent: number }[]>
      }
    >
  | ReducerAction<
      typeof RECEIVE_PRICE_INSIGHT,
      {
        timeFrame: keyof Omit<CompanyMarketHistorySummary, "updatedAt">
        companyPostgresIds: Loading<string[]>
        insight: "positive_price_moves" | "negative_price_moves"
      }
    >
  | ReducerAction<typeof CAPLIGHT_DATA_COMPANIES_REQUEST_STATUS, { status: Loading<boolean> }>
  | ReducerAction<typeof MARKETPRICE_COMPANIES_REQUEST_STATUS, { status: Loading<boolean> }>
  | ReducerAction<
      typeof RECEIVE_MARKET_INSIGHT,
      {
        insight: CompanyMarketInsightsType
        timeFrame: keyof Omit<CompanyMarketHistorySummary, "updatedAt">
        indicator: "byUSD" | "byCount"
        companyPostgresIds: Loading<string[]>
      }
    >
  | ReducerAction<typeof GET_TARGET_ISSUERS, { targetIssuers: "loading" | CompanyWithPostgres[] }>
  | ReducerAction<typeof ADD_TO_TARGET_ISSUERS, { extraIssuer: CompanyWithPostgres }>
  | ReducerAction<typeof ADD_TO_LOADING_TARGET_ISSUERS, { ids: CompanyIds }>
  | ReducerAction<typeof REMOVE_FROM_LOADING_TARGET_ISSUERS, { ids: CompanyIds }>
  | ReducerAction<typeof GET_ISSUER_COMPS, { companyId: string; comps: "loading" | CompsListRow[] }>
  | ReducerAction<
      typeof ADD_ANOTHER_ISSUER_COMP,
      { companyId: string; extraCompInfo: Comp; directOrReverse: "direct" | "reverse" }
    >
  | ReducerAction<typeof DELETE_COMP_INFO, { issuer: string; companyId: string; comp: string }>
  | ReducerAction<typeof CONFIRM_COMP_INFO, { issuer: string; companyId: string; comp: string }>
  | ReducerAction<
      typeof ADD_COMP_INFO,
      { companyId: string; extraCompInfo: Comp; directOrReverse: "direct" | "reverse" }
    >
  | ReducerAction<typeof GET_PBID_INFO, { companyId: string; pbid: string }>
  | ReducerAction<typeof GET_STATUS_INFO, { companyId: string; status: StatusString }>
  | ReducerAction<
      typeof GET_FUNDAMENTAL_INFO,
      { companyId: string; fundamentalInfoArray: FundamentalDataFromPostgres[] }
    >
  | ReducerAction<
      typeof GET_VOLATILITY_COMPANY_AND_COMPS,
      { companyId: string; volatilityData: IVolatilityDataForCompanyAndComps[] }
    >
  | ReducerAction<
      typeof RECEIVE_PUBLIC_COMPS_INDEX,
      {
        companyId: string
        publicCompsIndices: Record<string, PublicCompsIndexData[] | "loading" | undefined>
      }
    >
  | ReducerAction<
      typeof SET_CURRENT_COMPS_SUMMARY,
      { companyId: string; currentCompsSummaryData: CurrentCompsSummary[] }
    >
  | ReducerAction<
      | typeof RECEIVE_CAPLIGHT_PRICE_ESTIMATE_FOR_CAPLIGHT_DATA_COMPANIES
      | typeof RECEIVE_CAPLIGHT_PRICE_ESTIMATE_FOR_CAPLIGHT_DATA_COMPANIES_ALTERNATIVE_MODEL,
      {
        caplightPriceEstimateFromPostgres: Record<string, Loading<CaplightPriceEstimate[]>>
      }
    >
  | ReceiveCaplightPriceEstimateAction
  | ReducerAction<
      typeof RECEIVE_CAPLIGHT_PRICE_ESTIMATE_META_FOR_A_COMPANY,
      {
        companyId: string
        caplightPriceEstimateMetaForACompany: Loading<CaplightPriceEstimateMetaFromPostgres>
      }
    >
  | ReducerAction<
      typeof RECEIVE_CAPLIGHT_PRICE_ESTIMATE_MODEL_LIST,
      {
        listOfModels: Loading<{
          fullList: string[]
          default: string
        }>
      }
    >
  | ReducerAction<
      typeof RECEIVE_MUTUAL_FUND_MARKS_FOR_A_COMPANY,
      {
        companyId: string
        mutualFundMarks: Loading<MutualFundMarkParsed[]>
      }
    >
  | ReducerAction<typeof RECEIVE_THEMATIC_INDICES, { result: Requesting<ThematicIndex[]> }>
  | ReducerAction<
      typeof RECEIVE_PRICE_ESTIMATE_FIXING,
      { companyId: string; result: ArrayDataRequest<CaplightPriceEstimate> }
    >
/* eslint-enable prettier/prettier */

// TODO refactor away from giant switch
export function postgresDataReducer(
  state_: PostgresDataState | undefined,
  action: PostgresDataReducerAction & { directOrReverse: "direct" | "reverse" }
): PostgresDataState {
  // logic here
  const state = state_ ?? initialState
  const { compsLists } = state
  const { directOrReverse } = action
  const existingTI = !deprecatedIsLoaded(state.targetIssuers) ? [] : state.targetIssuers
  switch (action.type) {
    case GET_TARGET_ISSUERS:
      return {
        ...state,
        targetIssuers:
          action.targetIssuers === "loading"
            ? isNonempty(existingTI)
              ? existingTI
              : "loading"
            : _.uniqBy([...action.targetIssuers, ...existingTI], "id"),
        targetIssuersWereRequested: true,
      }
    case RECEIVE_ALL_SECTOR_LIST:
      return { ...state, sectors: action.sectors }
    case ADD_TO_TARGET_ISSUERS:
      return {
        ...state,
        targetIssuers:
          state.targetIssuers === "loading"
            ? [action.extraIssuer]
            : _.uniqBy(
                _.orderBy([...(state.targetIssuers || []), action.extraIssuer], "name"),
                "postgresCompanyId"
              ),
      }
    case ADD_TO_LOADING_TARGET_ISSUERS:
      return {
        ...state,
        loadingTargetIssuers: [...state.loadingTargetIssuers, action.ids],
      }
    case REMOVE_FROM_LOADING_TARGET_ISSUERS:
      return {
        ...state,
        loadingTargetIssuers: state.loadingTargetIssuers.filter(
          (issuer) => !_.isEqual(action.ids, issuer)
        ),
      }
    /// / ~~~~~~~~~~~~~~ comps lists ~~~~~~~~~~~~~~~~~~~~~~
    case GET_ISSUER_COMPS:
      return {
        ...state,
        compsLists: {
          ...compsLists,
          [directOrReverse]: {
            ...compsLists[directOrReverse],
            [action.companyId]:
              action.comps === "loading"
                ? "loading"
                : [...action.comps.map((c) => ({ ...c, timestampAdded: moment().valueOf() }))],
          },
        },
      }
    case ADD_ANOTHER_ISSUER_COMP:
      const existingComps = compsLists[directOrReverse][action.companyId]
      if (
        (existingComps === "loading" ? [] : existingComps)
          .map((c) => c.companyId)
          .includes(action.extraCompInfo.companyId)
      )
        return state
      return {
        ...state,
        compsLists: {
          ...compsLists,
          [directOrReverse]: {
            ...compsLists[directOrReverse],
            [action.companyId]:
              compsLists[directOrReverse][action.companyId] === undefined ||
              compsLists[directOrReverse][action.companyId] === "loading"
                ? [action]
                : [
                    ...compsLists[directOrReverse][action.companyId],
                    { ...action.extraCompInfo, timestampAdded: moment().valueOf() },
                  ],
          },
        },
      }
    case DELETE_COMP_INFO:
      const existing = _.cloneDeep(compsLists[directOrReverse][action.issuer])
      if (existing === "loading") return { ...state }
      const index = existing.map((el: CompsListRow) => el.companyId).indexOf(action.comp)
      existing.splice(index, 1)
      return {
        ...state,
        compsLists: {
          ...compsLists,
          [directOrReverse]: {
            ...compsLists[directOrReverse],
            [action.issuer]: [...existing],
          },
        },
      }
    case CONFIRM_COMP_INFO:
      const comps = _.cloneDeep(compsLists[directOrReverse][action.issuer])
      if (comps === "loading") return { ...state }
      const indexComp = comps.map((el: CompsListRow) => el.companyId).indexOf(action.comp)
      const newObj = {
        ...comps[indexComp],
        status: MANUALLY_CONFIRMED,
      }
      comps.splice(indexComp, 1)
      comps.push(newObj)
      return {
        ...state,
        compsLists: {
          ...compsLists,
          [directOrReverse]: {
            ...compsLists[directOrReverse],
            [action.issuer]: [...comps],
          },
        },
      }
    case ADD_COMP_INFO:
      const existingCompsList = compsLists[directOrReverse][action.companyId]
      // eslint-disable-next-line no-case-declarations
      const newCompsList = [
        annotate<CompsListRow>({
          companyId: action.companyId,
          companyName: action.extraCompInfo.companyName,
          status: MANUALLY_CONFIRMED,
          description: action.extraCompInfo.description,
          ticker: action.extraCompInfo.ticker,
          hasMarketPrice: action.extraCompInfo.hasMarketPrice,
          timestampAdded: moment().valueOf(),
        }),
      ].concat(defaultIfLoading(existingCompsList, []))
      return {
        ...state,
        compsLists: {
          ...compsLists,
          [directOrReverse]: {
            ...compsLists[directOrReverse],
            [action.companyId]: newCompsList,
          },
        },
      }
    /// / ~~~~~~~~~~~~~~ end comps lists ~~~~~~~~~~~~~~~~~~~~~~
    case GET_PBID_INFO:
      return {
        ...state,
        externalPbids: {
          ...state.externalPbids,
          [action.companyId]: action.pbid,
        },
      }
    case GET_STATUS_INFO:
      return {
        ...state,
        companyStatus: {
          ...state.companyStatus,
          [action.companyId]: action.status,
        },
      }
    case GET_FUNDAMENTAL_INFO:
      return {
        ...state,
        fundamentalInfo: {
          ...state.fundamentalInfo,
          [action.companyId]: [...action.fundamentalInfoArray],
        },
      }
    case GET_VOLATILITY_COMPANY_AND_COMPS:
      // TODO This will grow in volume quite a bit so probably should limit
      return {
        ...state,
        volatilityData: {
          ...state.volatilityData,
          [action.companyId]: [...action.volatilityData],
        },
      }
    case RECEIVE_PUBLIC_COMPS_INDEX:
      return {
        ...state,
        publicCompsIndices: { ...state.publicCompsIndices, ...action.publicCompsIndices },
      }
    case SET_CURRENT_COMPS_SUMMARY:
      return addCurrentCompsSummary(action.currentCompsSummaryData, state)
    case RECEIVE_CAPLIGHT_PRICE_ESTIMATE_FOR_CAPLIGHT_DATA_COMPANIES:
      return {
        ...state,
        caplightPriceEstimateFromPostgres: action.caplightPriceEstimateFromPostgres,
      }
    case RECEIVE_CAPLIGHT_PRICE_ESTIMATE_FOR_CAPLIGHT_DATA_COMPANIES_ALTERNATIVE_MODEL:
      return {
        ...state,
        caplightPriceEstimateFromPostgresAlternativeModel: action.caplightPriceEstimateFromPostgres,
      }
    case RECEIVE_CAPLIGHT_PRICE_ESTIMATE_FOR_A_COMPANY:
      return {
        ...state,
        caplightPriceEstimateFromPostgres: {
          ...(deprecatedIsLoaded(state.caplightPriceEstimateFromPostgres)
            ? state.caplightPriceEstimateFromPostgres
            : {}),
          [action.companyId]: action.caplightPriceEstimateForACompany,
        },
      }
    case RECEIVE_DEFAULT_CAPLIGHT_PRICE_ESTIMATE_FOR_A_COMPANY:
      return {
        ...state,
        caplightPriceEstimateFromPostgres: {
          ...(deprecatedIsLoaded(state.caplightPriceEstimateFromPostgres)
            ? state.caplightPriceEstimateFromPostgres
            : {}),
          [action.companyId]: action.caplightPriceEstimateForACompany,
        },
        caplightPriceEstimateModelVersion: {
          ...state.caplightPriceEstimateModelVersion,
          [action.companyId]: action.modelVersion,
        },
      }
    case RECEIVE_CAPLIGHT_PRICE_ESTIMATE_META_FOR_A_COMPANY:
      return {
        ...state,
        caplightPriceEstimateMetaFromPostgres: {
          ...(deprecatedIsLoaded(state.caplightPriceEstimateMetaFromPostgres)
            ? state.caplightPriceEstimateMetaFromPostgres
            : {}),
          [action.companyId]: action.caplightPriceEstimateMetaForACompany,
        },
      }
    case RECEIVE_CAPLIGHT_PRICE_ESTIMATE_MODEL_LIST:
      return {
        ...state,
        availablePriceEstimateModels: _.cloneDeep(action.listOfModels),
      }
    case RECEIVE_MUTUAL_FUND_MARKS_FOR_A_COMPANY:
      return {
        ...state,
        mutualFundMarks: {
          ...state.mutualFundMarks,
          [action.companyId]: action.mutualFundMarks,
        },
      }
    case RECEIVE_THEMATIC_INDICES:
      return {
        ...state,
        thematicIndices: action.result,
      }
    case RECEIVE_PRICE_ESTIMATE_FIXING:
      return {
        ...state,
        priceEstimateMonthlyFixing: {
          ...state.priceEstimateMonthlyFixing,
          [action.companyId]: action.result,
        },
      }
    case RECEIVE_PRICE_ESTIMATE_SCORES:
      return {
        ...state,
        caplightPriceEstimateScores: {
          ...state.caplightPriceEstimateScores,
          [action.companyId]: action.scores,
        },
      }
    case RECEIVE_MARKET_INSIGHT:
      return {
        ...state,
        insightsData: {
          ...state.insightsData,
          companies: {
            ...state.insightsData.companies,
            [action.insight]: {
              ...state.insightsData.companies[action.insight],
              [action.timeFrame]: {
                ...state.insightsData.companies[action.insight][action.timeFrame],
                [action.indicator]: action.companyPostgresIds, // byUSD or byCount
              },
            },
          },
        },
      }
    case RECEIVE_PRICE_INSIGHT:
      // eslint-disable-next-line no-case-declarations
      const key =
        action.insight === "negative_price_moves" ? "negativePriceChanges" : "positivePriceChanges"
      return {
        ...state,
        insightsData: {
          ...state.insightsData,
          companyPrices: {
            ...state.insightsData.companyPrices,
            [key]: {
              ...state.insightsData.companyPrices[key],
              [action.timeFrame]: action.companyPostgresIds,
            },
          },
        },
      }
    case CAPLIGHT_DATA_COMPANIES_REQUEST_STATUS:
      return {
        ...state,
        caplightDataCompaniesRequestStatus: action.status,
      }
    case MARKETPRICE_COMPANIES_REQUEST_STATUS:
      return {
        ...state,
        marketPriceCompaniesRequestStatus: action.status,
      }
    case RECEIVE_MOST_VIEWED_COMPANIES:
      return {
        ...state,
        insightsData: {
          ...state.insightsData,
          companyMetrics: {
            ...state.insightsData.companyMetrics,
            mostViewed: {
              ...state.insightsData.companyMetrics.mostViewed,
              [action.timeFrame]: action.companyPostgresIds,
            },
          },
        },
      }
    default:
      return { ...state }
  }
}

const addCurrentCompsSummary = (newData: CurrentCompsSummary[], state: PostgresDataState) => {
  const newDataIds = newData.map((row) => row.postgresCompanyId)
  const filteredOldState = state.currentCompsSummaryData.filter(
    (data) => !newDataIds.includes(data.postgresCompanyId)
  )
  return {
    ...state,
    currentCompsSummaryData: [...filteredOldState, ...newData],
  }
}

export default postgresDataReducer
