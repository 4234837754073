import InfoTooltip from "@components/InfoTooltip"
import { Button } from "@stories/components/Button/Button"
import { Input } from "@stories/components/Inputs/Input/Input"
import { PercentageField } from "@stories/components/Inputs/NumberInput/PercentageField"
import PhoneInput from "@stories/components/Inputs/PhoneInput/PhoneInput"
import { LabelValueList } from "@stories/components/LabelValueList/LabelValueList"
import Typography, { Size } from "@stories/components/Typography/Typography"
import { isIntermediaryAccount, isInvestorShareholderAccount } from "common/model/Account"
import { DealCRMContact, getCRMContactEmail } from "common/model/DealCRM/DealCRMContact"
import { DealCRMFirmContact, isNonBrokerageFirm } from "common/model/DealCRM/DealCRMFirmContact"
import { DealCRMIndividualContact } from "common/model/DealCRM/DealCRMIndividualContact"
import {
  ManagementFeeStructure,
  managementFeeStructures,
} from "common/model/DealCRM/DealCRMInterest"
import { isLoaded } from "common/utils/Loading"
import { formatAbbreviatedCurrency } from "common/utils/math/format"
import { capitalize, isNil } from "lodash"
import { useMemo } from "react"
import makeAnimated from "react-select/animated"
import { useFirebaseReader, useFirebaseWriter } from "src/firebase/Context"
import { UpdateContactProps, updateContact, updateIndividualContactFirm } from "src/firebase/crm"
import { useCurrentAccount } from "src/queries/currentUser/useCurrentAccount"
import {
  NumberField,
  sizeScaledInputWarning,
} from "../../../../stories/components/Inputs/NumberInput/NumberField"
import { SelectField } from "../../Components/DealParticipantFieldDisplay/DealParticipantFieldDisplay"
import { StringField } from "../../Components/InputFields/StringField"
import { SelectContactButton } from "../../Components/SelectContactButton"
import { useCRMContacts } from "../../Providers/CRMContactsProvider"
import CRMContactCopyEmail from "../ContactDisplay/ContactCopyEmail"
import { CRMContactName } from "./CRMContactName"
import { ContactFirmSelector } from "./ContactFirmSelector"
import { InvestmentPreferences } from "./InvestmentPreferences"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"

const animatedComponents = makeAnimated()

const sectorOptions = [
  { value: "Fintech", label: "Fintech: Payments" },
  { value: "Aerospace", label: "Aerospace/Defense" },
  { value: "SaaS", label: "SaaS" },
  // Add more sectors as needed
]

export const IndividualContactDetails = ({ contact }: { contact: DealCRMIndividualContact }) => {
  const db = useFirebaseWriter()
  const user = useLoggedInUser()

  const handleUpdateContact = <C extends DealCRMContact>({
    contact: updatedContact,
    update,
  }: UpdateContactProps<C>) =>
    updateContact({ db, contact: updatedContact, update, user: user.user })

  const account = useCurrentAccount()
  const { contacts: allCRMContacts } = useCRMContacts()
  const associateContacts = useMemo(
    () =>
      allCRMContacts.filter(
        (c) => c.tag !== "firm" && c.firm && c.firm.id === contact.firm?.id && c.id !== contact.id
      ),
    [allCRMContacts, contact.firm?.id, contact.id]
  )
  const handleAddContactToFirm = async (c: DealCRMContact) => {
    if (c.tag === "individual" && contact.firm) {
      await updateIndividualContactFirm({ db, contact: c, firm: contact.firm })
    }
  }

  const firm: DealCRMFirmContact | undefined = allCRMContacts.find(
    (c): c is DealCRMFirmContact => c.id === contact.firm?.id && isNonBrokerageFirm(c)
  )

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row gap-3">
        <div className="flex-col gap-2">
          {contact.firm ||
          (contact.isTransactionalIndividual &&
            contact.classification !== "shareholder" &&
            contact.classification !== "firm_contact_only") ? (
            <LabelValueList
              title="Firm"
              items={[
                {
                  id: "Firm",
                  label: "Firm",
                  value: contact.firm ? (
                    <CRMContactName contact={contact.firm} />
                  ) : (
                    <ContactFirmSelector<DealCRMIndividualContact>
                      contact={contact}
                      onFirmSelected={(selectedFirm) =>
                        handleUpdateContact({
                          contact,
                          update: {
                            firm: selectedFirm,
                          },
                        })
                      }
                      renderButton={(onClick) => (
                        <div onClick={onClick}>
                          <Input placeholder="Fund/Org" value="" size="xsmall" readOnly />
                        </div>
                      )}
                    />
                  ),
                  actionButton: contact.firm ? (
                    <ContactFirmSelector
                      contact={contact}
                      onFirmSelected={(selectedFirm) => {
                        handleUpdateContact({
                          contact,
                          update: {
                            firm: selectedFirm,
                          },
                        })
                      }}
                      renderButton={(onClick) => (
                        <Button label="Change Firm" onClick={onClick} variant="hollow" />
                      )}
                    />
                  ) : null,
                },
                {
                  id: "Title",
                  label: "Title",
                  value: (
                    <StringField
                      placeholder="CEO, Partner, Investor..."
                      value={contact.title}
                      onChange={(title) =>
                        handleUpdateContact({
                          contact,
                          update: {
                            title: title ?? "",
                          },
                        })
                      }
                    />
                  ),
                },
              ]}
            />
          ) : null}
          <LabelValueList
            title="Details"
            items={[
              {
                id: "Email",
                label: "Email",
                value: (
                  <div className="flex gap-2 items-center">
                    <StringField
                      placeholder="email@company.com"
                      value={contact.email}
                      onChange={(email) =>
                        handleUpdateContact({
                          contact,
                          update: {
                            email: email ?? "",
                          },
                        })
                      }
                    />
                    <CRMContactCopyEmail
                      textSize={Size.Small}
                      email={contact.email || ""}
                      iconOnly
                    />
                  </div>
                ),
              },
              {
                id: "Phone",
                label: "Phone",
                value: (
                  <PhoneInput
                    value={contact.phone ?? undefined}
                    onChange={(phoneNumber) =>
                      handleUpdateContact({
                        contact,
                        update: {
                          phone: phoneNumber ?? "",
                        },
                      })
                    }
                    heapName="crm-contact-drawer-phone-input"
                  />
                ),
              },
              ...(contact.isTransactionalIndividual &&
              contact.classification !== "shareholder" &&
              contact.classification !== "firm_contact_only"
                ? [
                    {
                      id: "Target Check Size",
                      label: "Target Check Size",
                      value: (
                        <div className="flex items-center gap-4">
                          <NumberField
                            size="xsmall"
                            placeholder="Min"
                            value={contact.targetCheckSize?.lowerBound}
                            onChange={(lowerBound) =>
                              handleUpdateContact({
                                contact,
                                update: {
                                  targetCheckSize: {
                                    lowerBound: isNil(lowerBound) ? null : lowerBound,
                                    upperBound: contact.targetCheckSize?.upperBound ?? null,
                                  },
                                },
                              })
                            }
                            formatter={formatAbbreviatedCurrency}
                            inputWarningFunction={sizeScaledInputWarning}
                          />
                          <span>-</span>
                          <NumberField
                            size="xsmall"
                            placeholder="Max"
                            name="maxCheckSize"
                            value={contact.targetCheckSize?.upperBound}
                            onChange={(upperBound) =>
                              handleUpdateContact({
                                contact,
                                update: {
                                  targetCheckSize: {
                                    lowerBound: contact.targetCheckSize?.lowerBound ?? null,
                                    upperBound: isNil(upperBound) ? null : upperBound,
                                  },
                                },
                              })
                            }
                            formatter={formatAbbreviatedCurrency}
                            inputWarningFunction={sizeScaledInputWarning}
                          />
                        </div>
                      ),
                    },
                  ]
                : []),
              ...(contact.name &&
              isLoaded(account) &&
              isInvestorShareholderAccount(account) &&
              contact.isTransactionalIndividual
                ? [
                    {
                      id: "typicalManagementFeeStructure",
                      label: (
                        <span className="flex gap-0.5 items-center">
                          <span>Typical Management Fee Structure</span>
                          <InfoTooltip
                            className="w-44"
                            text="Standard management fee structure you charge this LP. Will be applied by default to new buy-interest you add for this LP."
                          />
                        </span>
                      ),
                      value: (
                        <SelectField<ManagementFeeStructure | null>
                          size="middle"
                          className="min-w-40"
                          bordered
                          value={contact.defaultManagementFeeStructure ?? null}
                          options={[...managementFeeStructures]}
                          renderValue={(v) => (
                            <Typography size={Size.Small} text={capitalize(v ?? undefined)} />
                          )}
                          handleChange={(feeStructure) => {
                            handleUpdateContact({
                              contact,
                              update: {
                                defaultManagementFeeStructure: feeStructure,
                              },
                            })
                            return Promise.resolve()
                          }}
                          editable
                        />
                      ),
                    },
                    {
                      id: "typicalManagementFeePercent",
                      label: (
                        <span className="flex gap-0.5 items-center">
                          <span>Typical Management Fee Percent</span>
                          <InfoTooltip
                            className="w-44"
                            text="Typical management fee you charge this LP. Will be applied by default to new buy-interest you add for this LP."
                          />
                        </span>
                      ),
                      value: (
                        <PercentageField
                          max={0.1}
                          value={contact.defaultManagementFeePercent}
                          onChange={(mgmtFee) =>
                            handleUpdateContact({
                              contact,
                              update: {
                                defaultManagementFeePercent: mgmtFee,
                              },
                            })
                          }
                        />
                      ),
                    },
                    {
                      id: "typicalCarryPercent",
                      label: (
                        <span className="flex gap-0.5 items-center">
                          <span>Typical Carry Percent</span>
                          <InfoTooltip
                            className="w-44"
                            text="Typical carry you charge this LP. Will be applied by default to new buy-interest you add for this LP."
                          />
                        </span>
                      ),
                      value: (
                        <PercentageField
                          value={contact.defaultCarryPercent}
                          max={0.4}
                          onChange={(carry) =>
                            handleUpdateContact({
                              contact,
                              update: {
                                defaultCarryPercent: carry,
                              },
                            })
                          }
                        />
                      ),
                    },
                  ]
                : []),

              // {
              //   id: "Location",
              //   label: "Location",
              //   value: (
              //     <LocationDisplay
              //       editable
              //       location={contact.location}
              //       onUpdate={(location) =>
              //         updateContact({ contact, contactKey: "location", contactValue: location })
              //       }
              //     />
              //   ),
              // },
            ]}
          />
        </div>
        {firm && (
          <InvestmentPreferences
            title={`Investment Preferences (${firm.name})`}
            contact={firm}
            updateContact={(params) =>
              handleUpdateContact<DealCRMContact>({
                contact: firm,
                update: params.update,
              })
            }
          />
        )}
        {!firm &&
          contact.isTransactionalIndividual &&
          contact.classification !== "shareholder" &&
          contact.classification !== "firm_contact_only" && (
            <InvestmentPreferences
              contact={contact}
              updateContact={(params) =>
                handleUpdateContact<DealCRMContact>({
                  contact,
                  update: params.update,
                })
              }
            />
          )}
      </div>
      <LabelValueList
        title={`Associates ${contact.firm ? `at ${contact.firm.name}` : ""}`}
        actions={
          contact.firm && contact.firm.tag === "firm" ? (
            <SelectContactButton
              sourceContactTag="individual"
              onContactSelected={handleAddContactToFirm}
              hideFirms
            />
          ) : null
        }
        items={associateContacts.map((associate) => ({
          label: <CRMContactName contact={associate} />,
          value: (
            <CRMContactCopyEmail
              textSize={Size.Small}
              email={getCRMContactEmail(associate) ?? ""}
            />
          ),
          id: associate.id,
        }))}
      />
    </div>
  )
}
