import { PlusIcon } from "@stories/icons/PlusIcon"
import OrderHistoryItem from "./OrderHistoryItem"

const OrderHistoryCreated = ({ date, isHorizontal }: { date: Date; isHorizontal?: boolean }) => (
  <OrderHistoryItem
    labelString="Order Submitted"
    iconComponent={PlusIcon}
    date={date}
    isHorizontal={isHorizontal}
  />
)

export default OrderHistoryCreated
