import { GoogleAuthToken } from "common/model/Google/GoogleAuthToken"
import { makeAPIPostRequest } from "common/api/Request"
import { ExternalAuthAPI } from "common/api/ExternalAuthAPI"
import { config } from "src/config"
import { handleConsoleError } from "src/utils/Tracking"
import { useLoggedInUserAuthToken } from "src/firebase/AuthUtils"
import { useMemo } from "react"
import { isLoaded } from "common/utils/Loading"

export const useRenewGoogleAuthToken = (token: GoogleAuthToken) => {
  const userToken = useLoggedInUserAuthToken()
  return useMemo(
    () =>
      isLoaded(userToken)
        ? () =>
            token.id
              ? makeAPIPostRequest(
                  `${config.firebase.cloudApiUrl}/external-auth/`,
                  ExternalAuthAPI,
                  ["refresh-google-oauth-token"],
                  userToken
                )({ tokenId: token.id }).catch((e) => {
                  handleConsoleError(e)
                  return null
                })
              : Promise.resolve(null)
        : () => Promise.resolve(null),
    [userToken, token.id]
  )
}

export const useRevokeGoogleAuthTokens = (tokens: GoogleAuthToken[]) => {
  const userToken = useLoggedInUserAuthToken()
  return useMemo(
    () =>
      isLoaded(userToken)
        ? () =>
            Promise.all(
              tokens.map((token) =>
                token.id
                  ? makeAPIPostRequest(
                      `${config.firebase.cloudApiUrl}/external-auth/`,
                      ExternalAuthAPI,
                      ["revoke-google-oauth-token"],
                      userToken
                    )({ tokenId: token.id }).catch((e) => {
                      handleConsoleError(e)
                      return null
                    })
                  : Promise.resolve(null)
              )
            )
        : () => Promise.resolve(null),
    [userToken, tokens]
  )
}
