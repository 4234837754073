import {
  DeprecatedOrder,
  OrderDirection,
  DeprecatedOrderStructure,
} from "common/model/DeprecatedOrder"
import { isEqual } from "lodash"
import { handleConsoleError } from "../utils/Tracking"

export const isOptionFromStructures = (structures?: DeprecatedOrderStructure[]) =>
  (structures || []).some((structure) => ["Call", "Put"].includes(structure))
export const isSwapFromStructures = (structures?: DeprecatedOrderStructure[]) =>
  isEqual(structures, ["Swap / Forward"])

// TODO: refPPS, refValuation,  refSource should not be required
export interface FullTermsOptionOrder extends DeprecatedOrder {
  notionalRange: string
  strikeValuation: number
  strikePPS: number
  refValuation: number
  refPPS: number
  refSource?: string
  premPercent: number
  netFee: number
  contractExpirationInMonths: number
}

export type OrderWithSpotPrice = DeprecatedOrder & { refPPS: number; refValuation: number }

export const structuresString = (
  structures: DeprecatedOrderStructure[],
  direction: OrderDirection
) => {
  const structureToString = (structure: DeprecatedOrderStructure, _direction: OrderDirection) => {
    if (structure === "Call") {
      if (_direction === "Long") return "Call Option"
      return "Call Option"
    }
    if (structure === "Put") {
      if (_direction === "Long") return "Put Option"
      return "Put Option"
    }
    if (structure === "Swap / Forward") {
      if (_direction === "Long") return "Long Swap Position"
      return "Short Swap Position"
    }
    if (structure === "Direct") {
      if (_direction === "Long") return "Direct Share Purchase"
      return "Direct Share Sale"
    }
    if (structure === "SPV") {
      if (_direction === "Long") return "SPV"
      return "SPV"
    }
    handleConsoleError(
      `Invalid structure/direction specified ${JSON.stringify(structure)} / ${_direction}`
    )
    return "Undetermined"
  }

  if (!structures || structures.length === 0) return "Undetermined"
  if (structures.length > 1)
    return structures.map((structure) => structureToString(structure, direction)).join(", ")

  return structureToString(structures[0], direction)
}
