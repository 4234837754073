import { ReusableResponse } from "common/utils/network/ReusableResponse"
import { Either } from "common/containers/Either"
import { Maybe } from "common/containers/Maybe"
import { CurrentUser } from "../model/CurrentUser"
import { ValidationBody } from "../providers/embed/utils"
import { APIEndpoints, runAPIQuery } from "./API"

export type EitherAuthlessValidationOrCurrentUser = Either<Maybe<ValidationBody>, CurrentUser>
export const runPostgresDbQuery = async <T extends object>(
  eitherAuthlessValidationOrCurrentUser: EitherAuthlessValidationOrCurrentUser,
  body?: T
): Promise<ReusableResponse> =>
  eitherAuthlessValidationOrCurrentUser.match(
    (maybeValidationBody) =>
      maybeValidationBody.match(
        (validationBody) =>
          runAPIQuery<(T | {}) & ValidationBody>(APIEndpoints.embedPostgresDBQuery, {
            ...(body ?? {}),
            ...validationBody,
          }),
        () => Promise.reject()
      ),
    (currentUser) => runAPIQuery<T>(APIEndpoints.postgresDBQuery, body, currentUser)
  )
