import { nullableToMaybe } from "common/containers/Maybe"
import { Either, Right } from "common/containers/Either"
import {
  isOpportunityInboxUserCtx,
  isOrdersUserCtx,
} from "@components/auth/conditions/IsOrdersUser"
import { areaCheckWithRedirect } from "../components/auth/conditions/ProductAreaRedirect"
import { AuthCondition, ElementWrapper } from "./auth/AuthCondition"
import { isAdmin } from "../components/auth/conditions/IsAdmin"
import { UserAuthContext } from "./auth/UserAuthContext"
import { AuthRegion, flattenAuthProps, flattenAuthPropsNav } from "./auth/AuthRegion"
import { RouteEndpoint, RouteParams, PartialRouted } from "./types"
import { isPlatformUser } from "../components/auth/conditions/IsPlatformUser"
import { isFullAccessUser } from "../components/auth/conditions/IsFullAccessUser"
import { isDataContributorUser } from "../components/auth/conditions/IsDataContributorUser"
import { isNonEmployee } from "@components/auth/conditions/IsNonEmployee"
import { clientTypeCheck } from "@components/auth/conditions/ClientTypeCheck"

export const RouteAuthConditions: PartialRouted<AuthRegion> = {
  auction: () => ({ authConditions: [], requireToS: true }),
  trading: {
    deals: {
      area: "markets",
      authConditions: [
        areaCheckWithRedirect("markets", (routes) => routes.companies.root),
        isFullAccessUser(),
      ],
      navConditions: [areaCheckWithRedirect("markets", (routes) => routes.companies.root)],
    },
  },
  companies: {
    root: {
      authConditions: [isPlatformUser, isFullAccessUser()],
      navConditions: [isPlatformUser],
      requireToS: true,
    },
    company: (companyRouteId) => ({
      authConditions: [isPlatformUser, isFullAccessUser({ companyRouteId })],
      requireToS: true,
    }),
    companyEdit: (companyRouteId) => ({
      authConditions: [isPlatformUser, isFullAccessUser({ companyRouteId }), isAdmin],
    }),
  },
  rfqs: {
    root: {
      navConditions: [isNonEmployee],
    },
  },
  sectors: {
    root: {
      authConditions: [isPlatformUser, isFullAccessUser()],
      navConditions: [isPlatformUser],
      requireToS: true,
    },
    sector: () => ({
      authConditions: [isPlatformUser, isFullAccessUser()],
      requireToS: true,
    }),
  },
  data: {
    upload: {
      root: { area: "data", authConditions: [isDataContributorUser] },
      noTransactions: { area: "data", authConditions: [isDataContributorUser] },
    },
  },
  orders: {
    root: {
      authConditions: [isOrdersUserCtx, isFullAccessUser()],
      navConditions: [isOrdersUserCtx],
      requireToS: true,
    },
  },
  closedTrades: {
    root: {
      authConditions: [isPlatformUser, isFullAccessUser()],
      navConditions: [isPlatformUser],
      requireToS: true,
    },
  },
  myOrders: { authConditions: [isOrdersUserCtx], requireToS: true },
  eventFeed: {
    authConditions: [isPlatformUser, isFullAccessUser()],
    navConditions: [isPlatformUser],
    requireToS: true,
  },
  news: {
    authConditions: [isPlatformUser],
    requireToS: true,
  },
  fundingRounds: {
    authConditions: [isPlatformUser],
    requireToS: true,
  },
  watchlistPage: {
    authConditions: [isPlatformUser, isNonEmployee, isFullAccessUser()],
    navConditions: [isPlatformUser, isNonEmployee],
    requireToS: true,
  },
  admin: {
    root: { authConditions: [isAdmin] },
  },
  inbox: {
    root: {
      authConditions: [isOrdersUserCtx, isFullAccessUser()],
      navConditions: [isOrdersUserCtx],
      requireToS: true,
    },
  },
  opportunityInbox: {
    authConditions: [
      isOpportunityInboxUserCtx,
      isOrdersUserCtx,
      isPlatformUser,
      isFullAccessUser(),
    ],
    navConditions: [isOpportunityInboxUserCtx],
    requireToS: true,
  },
  insights: {
    authConditions: [isPlatformUser, isFullAccessUser()],
    navConditions: [isPlatformUser],
    requireToS: true,
  },
  shareholders: {
    portfolio: {
      authConditions: [clientTypeCheck("Employee")],
      navConditions: [clientTypeCheck("Employee")],
      requireToS: true,
    },
  },
  crm: {
    root: {
      authConditions: [isPlatformUser],
    },
    allContacts: {
      authConditions: [isPlatformUser],
    },
    allDeals: {
      authConditions: [isPlatformUser],
    },
    contacts: {
      import: {
        authConditions: [isPlatformUser],
      },
    },
    deal: (dealId) => ({
      authConditions: [isPlatformUser],
    }),
  },
  notifications: {
    authConditions: [isPlatformUser, isFullAccessUser()],
    navConditions: [isPlatformUser],
  },
}

export const doesRouteRequireTermsOfService = (route: RouteEndpoint, params: RouteParams) =>
  nullableToMaybe(route(RouteAuthConditions))
    .map((x) => (Array.isArray(x) ? x[0](params[x[1]] || "") : x))
    .map((x) => x.requireToS || false)
    .withDefault(false)

const getRouteAuthConditions =
  <T>(f: (a: AuthRegion) => T[]) =>
  (route: RouteEndpoint, params: RouteParams): T[] =>
    nullableToMaybe(route(RouteAuthConditions))
      .map((x) => (Array.isArray(x) ? x[0](params[x[1]] || "") : x))
      .map(f)
      .withDefault([])

export const getRouteAccessConditions = getRouteAuthConditions(flattenAuthProps)
const getRouteNavConditions = getRouteAuthConditions(flattenAuthPropsNav)

export const checkAuthConditions = <T>(
  conditions: AuthCondition<T, T>[],
  ctx: T
): Either<ElementWrapper, T> =>
  conditions?.reduce((x: Either<ElementWrapper, T>, f) => x.bind(f), Right(ctx)) || Right(ctx)

export const checkCanAccess = (route: RouteEndpoint, params: RouteParams, ctx: UserAuthContext) =>
  checkAuthConditions(getRouteAccessConditions(route, params), ctx)
export const checkCanSeeLinks = (route: RouteEndpoint, params: RouteParams, ctx: UserAuthContext) =>
  checkAuthConditions(getRouteNavConditions(route, params), ctx)
