import { convertObjectDates } from "./FirestoreConverter/convertObjectDates"
import { collectionConverters } from "../firestore/Collections"

type DocumentSnapshot<X> = {
  data: () => X | undefined
  id: string
  ref: { path: string }
}
export interface FirestoreDataConverter<
  AppModelType,
  DbModelType extends DocumentData = DocumentData
> {
  toFirestore(modelObject: AppModelType): DbModelType
  toFirestore(modelObject: Partial<AppModelType>): Partial<DbModelType>
  fromFirestore(snapshot: DocumentSnapshot<DocumentData>): AppModelType
}

// expanded to prevent consumers from having to depend on firestore-types
export type DocumentData = {
  // eslint-disable-next-line rulesdir/no-any, @typescript-eslint/no-explicit-any
  [field: string]: any
}
/**
 *  A firestore data converter for converting from a firestore record to a typed model interface
 *  In addition to type coercsion, this converter also:
 *  1. pulls the 'id' field from the firestore record into the javascript object as an attribute
 *  2. parses Firestore timestamps into javascript dates
 */
export const firestoreConverter = <
  AppModel extends DocumentData & { id?: string },
  DBModel extends DocumentData = DocumentData
>() => {
  const converter: FirestoreDataConverter<AppModel, DBModel> = {
    fromFirestore: (snapshot: DocumentSnapshot<DBModel>) => {
      const obj = snapshot.data()
      if (obj === undefined) {
        throw new Error(
          "A firestore DocumentSnapshot had undefined data(). This is bad and should never be allowed to happen"
        )
      }
      convertObjectDates(obj)
      const withId = { ...obj, id: snapshot.id } as unknown as AppModel
      const pathArray = snapshot.ref.path.split("/")
      const collection = pathArray[pathArray.length - 2]
      const result =
        collection in collectionConverters
          ? (collectionConverters[collection as keyof typeof collectionConverters].fromFirestore(
              withId
            ) as unknown as AppModel)
          : withId
      return result
    },
    toFirestore(modelObject: AppModel): DBModel {
      return modelObject as unknown as DBModel
    },
  }
  return converter
}
