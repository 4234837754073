import { GoogleAuthToken } from "../model/Google/GoogleAuthToken"
import { UserAccessFields } from "../model/User"
import { getOne, apiBody, apiLocals, APISpec, apiParams } from "./Spec"

export const ExternalAuthAPI = {
  "new-oauth-url": {
    body: apiBody<{}>(),
    locals: apiLocals<{ user: UserAccessFields }>(),
    node: getOne<string>(),
  },
  "get-google-oauth-token": {
    node: getOne<GoogleAuthToken | null>(),
    locals: apiLocals<{ user: UserAccessFields }>(),
    body: apiParams<{ userId: string }>(),
  },
  "get-or-create-google-oauth-token": {
    node: getOne<GoogleAuthToken>(),
    params: apiParams<{ state: string; code: string }>(),
  },
  "refresh-google-oauth-token": {
    body: apiBody<{ tokenId: string }>(),
    locals: apiLocals<{ user: UserAccessFields }>(),
    node: getOne<GoogleAuthToken>(),
  },
  "revoke-google-oauth-token": {
    body: apiBody<{ tokenId: string }>(),
    locals: apiLocals<{ user: UserAccessFields }>(),
    node: getOne<boolean>(),
  },
} satisfies APISpec
