import { Popover } from "@stories/components/Antd"
import { Input } from "@stories/components/Inputs/Input/Input"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import React, { ElementRef, FC, useMemo } from "react"
import { nameSimilarityScore } from "../../Contacts/helpers"
import { useCRMContacts } from "../../Providers/CRMContactsProvider"
import InlineContactSelectPopoverContent from "./InlineContactSelectPopoverContent"
import { StopClickPropagation } from "@stories/components/StopClickPropagation/StopClickPropagation"

export type InlineContactSelectComponentSource =
  | "all-interests-table"
  | "company-interest-table"
  | "deal-kanban-page"
  | "new-note"
  | "share-order"

export interface InlineContactSelectSearchProps {
  placeholder?: string
  onContactSelected: (contact: DealCRMContact | null) => Promise<void>
  sourceComponent: InlineContactSelectComponentSource
  hiddenContactTypes?: DealCRMContact["tag"][]
  label?: string
  resetSearchOnContactSelect?: boolean
  noBorder?: boolean
  isSkipVisible?: boolean
}

const InlineContactSelectSearch: FC<InlineContactSelectSearchProps> = ({
  onContactSelected,
  resetSearchOnContactSelect,
  placeholder = "Search",
  label,
  sourceComponent,
  hiddenContactTypes = [],
  noBorder,
  isSkipVisible = true,
}) => {
  const { contacts } = useCRMContacts()
  const [searchString, setSearchString] = React.useState("")
  const popoverRef = React.useRef<HTMLDivElement>(null)

  const matchingContacts = useMemo(
    () =>
      contacts
        .filter((contact) => !hiddenContactTypes.includes(contact.tag))
        .map((contact) => ({ contact, score: nameSimilarityScore(contact, searchString) }))
        .filter(({ score }) => score > 0)
        .sort((a, b) => b.score - a.score)
        .map(({ contact }) => contact),
    [contacts, hiddenContactTypes, searchString]
  )

  const handleContactSelect = (contact: DealCRMContact | null) => {
    if (resetSearchOnContactSelect) {
      setSearchString("")
    }
    return onContactSelected(contact)
  }

  const inputRef = React.useRef<ElementRef<"input">>(null)
  const focusInput = () => inputRef.current?.focus()

  return (
    <div id="inline-contact-search-select" role="none" onClick={focusInput}>
      <Popover
        content={
          <StopClickPropagation>
            <InlineContactSelectPopoverContent
              innerRef={popoverRef}
              sourceComponent={sourceComponent}
              onContactSelected={handleContactSelect}
              searchString={searchString}
              handleClose={() => setSearchString("")}
              matchingContacts={matchingContacts}
              isSkipVisible={isSkipVisible}
            />
          </StopClickPropagation>
        }
        destroyTooltipOnHide
        open={searchString.length > 0}
        showArrow={false}
        placement="bottomLeft"
        align={{ offset: [0, -11] }}
      >
        <Input
          inputRef={inputRef}
          label={label}
          placeholder={placeholder}
          size="small"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Tab") {
              onContactSelected(null)
              e.preventDefault()
            }
          }}
          noBorder={noBorder}
        />
      </Popover>
    </div>
  )
}

export default InlineContactSelectSearch
