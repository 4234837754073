import { AccessControl } from "../AccessControl"

export const legacyDataAccessControl: AccessControl<"legacy-data"> = {
  tier: "legacy-data",
  features: {
    // live market
    liveMarketParticipation: "full",
    orderHistoryData: "full",
    currentMarketPriceData: "full",
    contributeTradeData: "full",
    companyNewsData: "full",
    fundingRoundsPage: "full",
    fundingRoundsData: "full",
    marketInsightsPage: "full",
    rfqData: "full",

    // company page
    companyPageFundingRoundsTable: "full",
    companyPage409aValuationsTable: "full",
    companyPageFilingsTable: "full",
    companyPageInvestorsTable: "full",
    companyPageMutualFundMarksTable: "full",
    companyPageBidOfferVolumeRatioChart: "full",
    companyPageTradingContextSection: "full",
    companyPageResearchReportsSection: "full",
    companyPageComparablesSection: "full",
    companyPageShare: "full",
    companyPageFlagIssue: "full",
    companyPageExport: "full",

    // trade history
    tradeHistoryPage: "full",
    tradeHistoryPricing: "full",
    tradeHistoryDates: "full",
    tradeHistoryStructure: "full",
    tradeHistoryShareClass: "full",

    // sectors page
    sectorsData: "full",
  },
}

export const legacyMarketsAccessControl: AccessControl<"legacy-markets"> = {
  tier: "legacy-markets",
  features: {
    // live market
    liveMarketParticipation: "full",
    orderHistoryData: "full",
    currentMarketPriceData: "locked",
    contributeTradeData: "full",
    companyNewsData: "full",
    fundingRoundsPage: "full",
    fundingRoundsData: "full",
    marketInsightsPage: "full",
    rfqData: "full",

    // company page
    companyPageFundingRoundsTable: "locked",
    companyPage409aValuationsTable: "locked",
    companyPageFilingsTable: "locked",
    companyPageInvestorsTable: "locked",
    companyPageMutualFundMarksTable: "locked",
    companyPageBidOfferVolumeRatioChart: "locked",
    companyPageTradingContextSection: "locked",
    companyPageResearchReportsSection: "locked",
    companyPageComparablesSection: "locked",
    companyPageShare: "full",
    companyPageFlagIssue: "full",
    companyPageExport: "full",

    // trade history
    tradeHistoryPage: "full",
    tradeHistoryPricing: "full",
    tradeHistoryDates: "full",
    tradeHistoryStructure: "full",
    tradeHistoryShareClass: "full",

    // sectors page
    sectorsData: "none",
  },
}
