import { GmailIcon } from "@stories/icons/GmailIcon"
import { XIcon } from "@stories/icons/XIcon"
import { dateDiffToNow } from "common/utils/dateUtils"
import React from "react"
import { useFirebaseWriter } from "src/firebase/Context"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { handleConsoleError } from "src/utils/Tracking"
import { Button } from "../Button/Button"
import Typography, { Size, Weight } from "../Typography/Typography"

const emailClientsIds = ["gmail", "office"] as const

type EmailClientId = (typeof emailClientsIds)[number]

export interface ConnectEmailBannerProps {
  client: EmailClientId
  onClick: () => void
  loading: boolean
}

interface EmailClientDetails {
  name: string
  icon: JSX.Element
}

type EmailClientsDetails = {
  [key in EmailClientId]: EmailClientDetails
}

const emailClientsDetails: EmailClientsDetails = {
  gmail: {
    name: "Gmail",
    icon: <GmailIcon />,
  },
  office: {
    name: "Office 365",
    icon: <></>, // TODO: Add Office 365 icon
  },
}

export const ConnectEmailBanner: React.FC<ConnectEmailBannerProps> = ({
  client,
  onClick,
  loading,
}) => {
  const user = useLoggedInUser()
  const firebase = useFirebaseWriter()
  const clientDetails = emailClientsDetails[client]
  const dismissBanner = () => {
    firebase
      .recordUserProductInteraction(user.user, {
        lastDismissedEmailSyncBanner: new Date(),
      })
      .catch(handleConsoleError)
  }

  const dismissedBannerInPast3Days =
    user.user.productInteractionHistory?.lastDismissedEmailSyncBanner &&
    dateDiffToNow(user.user.productInteractionHistory.lastDismissedEmailSyncBanner, "days") < 3

  if (dismissedBannerInPast3Days) {
    return null
  }

  return (
    <div className="flex gap-4 p-3 bg-neutral-white rounded border mb-4">
      <div className="pt-0.5">{clientDetails.icon}</div>
      <div className="flex flex-col gap-2">
        <Typography
          weight={Weight.Semibold}
          size={Size.Small}
          text={`Connect your ${clientDetails.name}`}
        />
        <Typography
          size={Size.XSmall}
          text="Capture, track and update your contacts by connecting your email to Caplight."
        />
        <div className="pt-2">
          <Button
            variant="primary"
            isFullWidth={false}
            onClick={onClick}
            label={`Connect ${clientDetails.name}`}
            isLoading={loading}
          />
        </div>
      </div>
      <div className="flex-1 flex justify-end">
        <Button variant="icon" leftIcon={<XIcon />} size="medium" onClick={dismissBanner} />
      </div>
    </div>
  )
}
