import { ViewTracked } from "common/model/ViewTracked"
import { DocumentReference, increment, updateDoc, arrayUnion } from "firebase/firestore"
import moment from "moment-timezone"
import { Timestamp } from "common/model/postgres/PostgresCommon"

type DocRefPath = string

// Track views once per 5 minutes max
const viewCountLastTimestamp = new Map<DocRefPath, Timestamp>() //map of doc path to last view timestamp

const _5_MINUTES_IN_MS = 1000 * 60 * 5

export const incrementViewCount = async (ref: DocumentReference<ViewTracked>, userId: string) => {
  const timestamp = moment().valueOf()
  const hashKey = ref.path
  if (viewCountLastTimestamp.has(hashKey)) {
    const lastTimestamp = viewCountLastTimestamp.get(hashKey)
    if (lastTimestamp && lastTimestamp > timestamp - _5_MINUTES_IN_MS) {
      return Promise.resolve()
    }
  }
  viewCountLastTimestamp.set(hashKey, timestamp)

  const encoder = new TextEncoder()
  const viewerId = encoder.encode(`${ref.id}-${userId}`)
  const hashedViewerIdArray = await crypto.subtle.digest("SHA-256", viewerId)
  const dateTimestamp = moment().tz("America/Los_Angeles").startOf("day").valueOf()
  const decoder = new TextDecoder()
  const hashedViewerId = decoder.decode(hashedViewerIdArray)
  const updates = {
    "viewTracking.totalViewCount": increment(1),
    [`viewTracking.viewCountByDay.${dateTimestamp}`]: increment(1),
    "viewTracking.viewerIdsHashed": arrayUnion(hashedViewerId),
  }
  return updateDoc(ref, updates)
}
