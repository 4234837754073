import { Link, useLocation } from "react-router-dom"
import { Routes } from "src/Routes/Routes"
import { classNames } from "src/utils/classNames"

export type NavLink = {
  name: string
  route: string
}

export const BasicNav: React.FC<
  React.PropsWithChildren<{ links: NavLink[]; size?: "xs" | "sm" | "md" | "lg" }>
> = ({ links, size = "sm" }) => {
  const location = useLocation()
  return (
    <nav className="flex flex-wrap lg:flex max-w-full overflow-x-auto" aria-label="Global">
      {links.map(({ name, route }) => (
        <Link
          key={name}
          to={route}
          className={classNames(
            location.pathname === route ||
              (location.pathname === Routes.admin.root && name === "Accounts")
              ? "bg-neutral-white text-neutral-1000"
              : "text-neutral-600 hover:bg-neutral-100 hover:text-neutral-1000",
            "rounded-md py-2 px-3 inline-flex items-center font-medium",
            `text-${size} text-center whitespace-nowrap`
          )}
        >
          {name}
        </Link>
      ))}
    </nav>
  )
}
