import { Input } from "@stories/components/Antd"
import { Button } from "@stories/components/Button/Button"
import DropdownMenu from "@stories/components/DropdownMenu/DropdownMenu"
import { Color } from "@stories/components/Typography/Typography"
import { DotsIconVertical } from "@stories/icons/DotsIcon"
import DragIcon from "@stories/icons/DragIcon"
import { Reorder } from "framer-motion"
import React, { useState } from "react"

interface ReOrderableItemProps {
  item: string
  index: number
  onRename: (index: number, newName: string) => void
  onConfirmDelete: (index: number) => void
  className: string
  containerRef: React.RefObject<HTMLDivElement>
  icon?: React.ReactNode
  disableReorder?: boolean
}

const ReOrderableItem: React.FC<ReOrderableItemProps> = ({
  item,
  index,
  onRename,
  onConfirmDelete,
  className,
  containerRef,
  icon,
  disableReorder,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [editValue, setEditValue] = useState(item)

  const handleStartEditing = () => {
    setIsEditing(true)
  }

  const handleRename = () => {
    onRename(index, editValue)
    setIsEditing(false)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditValue(e.target.value)
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleRename()
    }
  }

  if (disableReorder) {
    return (
      <div className={`flex gap-1 items-center ${className} cursor-pointer mb-1 px-2 py-1`}>
        {icon}
        <span>{item}</span>
      </div>
    )
  }

  return (
    <Reorder.Item
      id={item}
      value={item}
      className={`flex gap-1 items-center ${className} cursor-pointer mb-1 px-2 py-1`}
      dragConstraints={containerRef}
      dragElastic={0.2}
      onDrag={(e) => (disableReorder ? e.stopPropagation() : null)}
    >
      <DragIcon color={Color.Neutral800} />
      {isEditing ? (
        <div className="flex items-center flex-grow">
          <Input
            value={editValue}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            className="flex-grow mr-2"
          />
          <Button onClick={handleRename} label="Submit" />
        </div>
      ) : (
        <div className="flex items-center flex-grow gap-2">
          {icon}
          <span className="flex-grow">{item}</span>
          <DropdownMenu
            className="relative cursor-pointer"
            menuButtonIcon={<DotsIconVertical color={Color.Gray} />}
            menuItems={[
              {
                id: "rename",
                label: "Rename",
                onClick: handleStartEditing,
                variant: "accent",
                heapName: "rename-item-button",
              },
              {
                id: "delete",
                label: "Delete",
                onClick: () => onConfirmDelete(index),
                variant: "danger",
                heapName: "delete-item-button",
              },
            ]}
          />
        </div>
      )}
    </Reorder.Item>
  )
}

export default ReOrderableItem
