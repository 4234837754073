import { AccountActivityLogButton } from "@components/AccountActivityLog/AccountActivityLogButton"
import { AccountQuotaStatusDropdown } from "@components/AccountQuotaStatus"
import { isEmpty } from "lodash"
import { useLocation } from "react-router-dom"
import { ScreenSize, useViewport } from "src/utils/useViewport"
import { useLoggedInUser } from "../../../providers/loggedInUser/useLoggedInUser"
import { MinimalAppRoutes } from "../../../stories/components/Nav/Navigation/Navigation"
import { UserMenu } from "../UserMenu"
import { MobileNavbar } from "./MobileNavbar"
import NavbarCompanySearch from "./NavbarCompanySearch"
import NavbarNewFeatureAnnouncement from "@components/NavbarNewFeatureAnnouncement"
import NoteModalButton from "src/pages/CRM/NoteModal/NoteModalButton"
import { isEmployeeAccount } from "common/model/Auth/Permissions"

const TopNavbar = () => {
  const currentUser = useLoggedInUser()
  const viewport = useViewport()
  const location = useLocation()

  // List of minimal route extensions. These routes only get a minimal navbar

  const isMinimalRoute = MinimalAppRoutes.map((route) =>
    location.pathname.includes(route)
  ).includes(true)

  const minimalNav =
    isMinimalRoute ||
    (isEmpty(currentUser.user.account.productAreas) && !isEmployeeAccount(currentUser.user.account))

  if (viewport.size === ScreenSize.small) return <MobileNavbar isMinimalNav={minimalNav} />
  else if (minimalNav) return null

  return (
    <div className="sticky top-0 z-50 w-full h-16 flex items-center justify-between px-4 bg-neutral-white border border-neutral-400">
      <div className="flex gap-2 items-center">
        <div style={{ width: "380px" }}>
          <NavbarCompanySearch />
        </div>
        {viewport.size === ScreenSize.large && <NavbarNewFeatureAnnouncement />}
      </div>
      <div className="flex gap-4 items-center">
        <AccountQuotaStatusDropdown />
        <NoteModalButton />
        <AccountActivityLogButton />
        <UserMenu user={currentUser} />
      </div>
    </div>
  )
}

export default TopNavbar
