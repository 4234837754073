import Spinner from "@components/icons/Spinner"
import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid"
import { Fragment } from "react"
import { classNames } from "../../utils/classNames"
import { SelectorOption } from "./selectors"

export type DropdownOption<T extends string = string> = SelectorOption<T>

export interface DropdownDisplayProps<T extends string> {
  label: string | JSX.Element
  options: DropdownOption<T>[]
  minWidthPx?: number
  small?: boolean
  listboxButtonClassName?: string
  listboxOptionsClassName?: string
}

interface DropdownProps<T extends string> extends DropdownDisplayProps<T> {
  setSelected: (value: DropdownOption<T>) => void
  renderOption?: (option: DropdownOption<T> | undefined) => JSX.Element | undefined
  selected?: DropdownOption<T>
  disabled?: boolean
  loading?: boolean
  className?: string
  id?: string
  placeholder?: string
}

export function Dropdown<T extends string = string>({
  label,
  options,
  setSelected,
  selected,
  minWidthPx,
  small,
  listboxButtonClassName,
  listboxOptionsClassName,
  renderOption,
  disabled,
  loading,
  className,
  placeholder,
  id,
}: DropdownProps<T>) {
  return (
    <Listbox value={selected} onChange={setSelected} disabled={disabled}>
      {({ open }) => (
        <div
          className="flex items-center"
          id={id}
          style={minWidthPx ? { minWidth: `${minWidthPx}px` } : {}}
        >
          <Listbox.Label
            className={classNames(
              "block text-xs mb-0 mr-2 font-normal text-neutral-1000 whitespace-nowrap"
            )}
          >
            {label}
          </Listbox.Label>
          <div
            className={classNames("", className)}
            style={minWidthPx ? { minWidth: `${minWidthPx}px` } : {}}
          >
            <Listbox.Button
              className={classNames(
                listboxButtonClassName ||
                  "sm:text-sm border border-neutral-400 shadow-sm pl-3 pr-1 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary text-neutral-1000 hover:text-neutral-900",
                small ? "py-1" : "py-2",
                "flex rounded-md text-left cursor-default items-center z-10",
                disabled ? "bg-neutral-500" : "bg-neutral-white"
              )}
              style={minWidthPx ? { minWidth: `${minWidthPx}px` } : {}}
            >
              <span className="block truncate">
                {loading ? (
                  <Spinner size="xs" />
                ) : renderOption ? (
                  renderOption(selected)
                ) : selected?.name ? (
                  selected.name
                ) : (
                  placeholder ?? <span>&nbsp;</span>
                )}
              </span>

              <span className="flex-grow" />
              <span className="float-right flex items-center px-1 pointer-events-none">
                <SelectorIcon className="h-5 w-5 text-neutral-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={classNames(
                  listboxOptionsClassName || "text-base sm:text-sm max-h-60 overflow-auto",
                  "absolute mt-1 bg-neutral-white shadow-lg rounded-md py-1 ring-1 ring-black ring-opacity-5 focus:outline-none z-30"
                )}
                style={minWidthPx ? { minWidth: `${minWidthPx}px` } : {}}
              >
                {options
                  .filter((opt) => opt.name !== "")
                  .map((option) => (
                    <Listbox.Option
                      key={option.id}
                      className={({ active }) =>
                        classNames(
                          active
                            ? "text-accent-500 bg-primary cursor-pointer"
                            : "text-neutral-1000",
                          "cursor-default select-none relative py-2 px-3 flex z-20"
                        )
                      }
                      value={option}
                    >
                      {({ active }) => (
                        <>
                          {renderOption ? (
                            renderOption(option)
                          ) : (
                            <span
                              className={classNames(
                                // NOTE: Bug from headlessui, the selected state is broken
                                selected?.id === option.id ? "font-semibold" : "font-normal",
                                "block truncate"
                              )}
                            >
                              {option.name}
                            </span>
                          )}

                          {selected?.id === option.id && selected.name !== "" ? (
                            <span
                              className={classNames(
                                active ? "text-neutral-900" : "text-indigo-500",
                                "inset-y-0 right-0 flex items-center px-2"
                              )}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  )
}
