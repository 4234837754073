import { wrapIcon } from "./IconWrapper"

export const MyOrdersIcon = wrapIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.1332 8.60004L9.33317 12.4H7.79984V10.8667L11.5998 7.06671L13.1332 8.60004ZM15.3998 8.06671C15.3998 8.26671 15.1998 8.46671 14.9998 8.66671L13.3332 10.3334L12.7332 9.73337L14.4665 8.00004L14.0665 7.60004L13.5998 8.06671L12.0665 6.53337L13.5332 5.13337C13.6665 5.00004 13.9332 5.00004 14.1332 5.13337L15.0665 6.06671C15.1998 6.20004 15.1998 6.46671 15.0665 6.66671C14.9332 6.80004 14.7998 6.93337 14.7998 7.06671C14.7998 7.20004 14.9332 7.33337 15.0665 7.46671C15.2665 7.66671 15.4665 7.86671 15.3998 8.06671ZM1.99984 13.3334V2.66671H6.6665V6.00004H9.99984V7.00004L11.3332 5.66671V5.33337L7.33317 1.33337H1.99984C1.2665 1.33337 0.666504 1.93337 0.666504 2.66671V13.3334C0.666504 14.0667 1.2665 14.6667 1.99984 14.6667H9.99984C10.7332 14.6667 11.3332 14.0667 11.3332 13.3334H1.99984ZM7.33317 11.4C7.19984 11.4 7.0665 11.4667 6.99984 11.4667L6.6665 10H5.6665L4.2665 11.1334L4.6665 9.33337H3.6665L2.99984 12.6667H3.99984L5.93317 10.9334L6.33317 12.4667H6.99984L7.33317 12.4V11.4Z"
      fill="currentColor"
    />
  </svg>
)
