import { Proxy, Unproxy, Variance } from "./Proxy"

/** A type distinct from but structurally identical to T. Can only be constructed in scopes with access to `Name`, but can be deconstructed anywhere. */
export type Nominal<T, Name extends symbol, V extends Variance = "covariant"> = T &
  NominalTag<Name, T, V>
export type ForgetNominal<T> = T extends Nominal<infer Original, infer Name> ? Unproxy<T["raw"]> : T

export type ConstrainNominal<Constrained extends ForgetNominal<Original>, Original> = Constrained &
  Original

/** The only acceptable way to construct a nominal type. (Typescript being what it is, this can't be perfectly enforced) */
export const makeNominal = <T extends object, Name extends symbol>(
  t: T,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  typeName: Name
  // eslint-disable-next-line rulesdir/no-assert
): Nominal<T, Name> => t as Nominal<T, Name>
export const forgetNominal = <T, Name extends symbol>(t: Nominal<T, Name>): T => t

/** Everything below this line is internal and should not be exported */
const nominalSymbol: unique symbol = Symbol("nominal type; this should never appear")

class NominalTag<Name, T, V extends Variance> {
  private readonly [nominalSymbol]: Name

  raw: Proxy<V, T>

  constructor(it_should_never_be_possible_to_call_this: never) {
    throw new Error(
      `Attempted to construct NominalTag with arg ${JSON.stringify(
        it_should_never_be_possible_to_call_this
      )}. This is a compile-time construct and should never be accessible at runtime.`
    )
  }

  /** Only exists to make the linter stop complaining */
  tagName() {
    return this[nominalSymbol]
  }
}
