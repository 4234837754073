import { CheckboxWithLabel } from "@components/inputs/checkbox/Checkbox"
import { CompanyFilterSearchProp } from "./shared"
import Typography, { Color, Size } from "@stories/components/Typography/Typography"

export const CompanySelectFilterSelect = ({
  currentCompanyFilter,
  initialCompanyFilter,
  onCompanyFilterTypeChange,
}: {
  currentCompanyFilter?: CompanyFilterSearchProp
  initialCompanyFilter: CompanyFilterSearchProp
  onCompanyFilterTypeChange: (type: CompanyFilterSearchProp["type"]) => void
}) => {
  const handleCompanyFilterTypeChange = (type: CompanyFilterSearchProp["type"][number]) => {
    if (currentCompanyFilter) {
      onCompanyFilterTypeChange(
        currentCompanyFilter.type.includes(type)
          ? currentCompanyFilter.type.filter((v) => v !== type)
          : [type, ...currentCompanyFilter.type]
      )
    } else {
      onCompanyFilterTypeChange([type])
    }
  }
  return (
    <div className="flex flex-col bg-neutral-200 border-neutral-300 border-1 rounded mt-1 p-2">
      <Typography text="Filters" size={Size.XSmall} color={Color.Subtitle} />
      <div className="grid grid-cols-2 gap-1">
        {initialCompanyFilter.type.includes("buy-interest") ? (
          <CheckboxWithLabel
            id="buy-interest"
            onChange={() => handleCompanyFilterTypeChange("buy-interest")}
            checked={!!currentCompanyFilter?.type.includes("buy-interest")}
            title={`Buy Interest in ${initialCompanyFilter.company.name}`}
            wrapText
            size="small"
          />
        ) : null}
        {initialCompanyFilter.type.includes("sell-interest") ? (
          <CheckboxWithLabel
            id="sell-interest"
            onChange={() => handleCompanyFilterTypeChange("sell-interest")}
            checked={!!currentCompanyFilter?.type.includes("sell-interest")}
            title={`Sell Interest in ${initialCompanyFilter.company.name}`}
            wrapText
            size="small"
          />
        ) : null}
        {initialCompanyFilter.type.includes("holding") ? (
          <CheckboxWithLabel
            id="holding"
            onChange={() => handleCompanyFilterTypeChange("holding")}
            checked={!!currentCompanyFilter?.type.includes("holding")}
            title={`Owns ${initialCompanyFilter.company.name}`}
            wrapText
            size="small"
          />
        ) : null}
      </div>
    </div>
  )
}
