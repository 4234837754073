import { Popover } from "@stories/components/Antd"
import { Company, CompanyIdFields, companyInPostgres } from "common/model/Company"
import { RawOrderPrice } from "common/model/Order/Models/Wrapped/RawPrice"
import { isLoaded } from "common/utils/Loading"
import { format } from "date-fns"
import { formatPercentageWithPlus } from "common/utils/math/format"
import { formatSharePrice, formatValuation } from "../../../components/displays/numeric/Currency"
import { calculatePercentageChange } from "common/utils/math/growthRate"
import { useCompanies } from "src/providers/data/CompanyProvider"
import { useMostRecentMarketPPSWithoutRedux } from "src/data/Hooks/useMostRecentMarketPrice"
import { Button } from "@stories/components/Button/Button"
import { useNavigate } from "react-router-dom"
import { Routes } from "src/Routes/Routes"
import {
  splitAdjustPriceToToday,
  valuationFromPricePerShareInformationWrapper,
} from "common/model/data-product/valuationCalculation/Valuation"
import { filterFundingRoundsLessStrictly } from "common/model/Company/FundingRound"
import { TableCellAccessControlWrapper } from "src/providers/AccessControl/TableCellAccessControlWrapper"
import { SimpleUpgradeTierTooltipContent } from "src/providers/AccessControl/components/SimpleUpgradeTierTooltipContent"
import { useCompanyUnlockedForFeature } from "src/providers/AccessControl/CompanyAccessProvider"
import { AccessControlFeatureName } from "common/model/AccessControl/AccessControl"

const DisplayPercentage = ({
  percentage,
  label,
  featureName,
}: {
  percentage: number
  label?: string
  featureName: AccessControlFeatureName
}) => (
  <div className="flex items-center gap-1 whitespace-nowrap">
    <TableCellAccessControlWrapper
      featureName={featureName}
      upgradeTooltipContent={SimpleUpgradeTierTooltipContent}
      targetAccessLevel="limited-company"
      size="small"
      iconOnly
    >
      <span className={percentage >= 0 ? "text-success-500 text-sm" : "text-danger-500 text-sm"}>
        {formatPercentageWithPlus(percentage)}
      </span>
    </TableCellAccessControlWrapper>
    {label ? <span className="text-xs text-neutral-800">vs {label}</span> : null}
  </div>
)

type PriceVsPopoverContentPrice = { pps: number | undefined; valuation: number | undefined }

const DisplayPriceVsPopoverContentPrice = ({ price }: { price: PriceVsPopoverContentPrice }) => (
  <p className="flex flex-col">
    {price.pps ? <span>{formatSharePrice(price.pps)}</span> : null}
    {price.valuation ? <span>{formatValuation(price.valuation)} val.</span> : null}
  </p>
)

const PriceVsPopoverContent = ({
  marketPrice,
  lastRound,
}: {
  marketPrice?: PriceVsPopoverContentPrice
  lastRound?: PriceVsPopoverContentPrice & { context: string; company: Company }
}) => {
  const navigate = useNavigate()
  const navigateToCompanyFundingRounds = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (lastRound && companyInPostgres(lastRound.company)) {
      navigate(
        Routes.companies.companyPageLocations.fundingRoundsTab(lastRound.company.postgresCompanyId)
      )
    }
  }

  return (
    <table className="table-auto">
      {marketPrice?.pps || marketPrice?.valuation ? (
        <tr className="border-b">
          <td className="font-semibold">MarketPrice</td>
          <td className="pl-2 pb-1">
            <DisplayPriceVsPopoverContentPrice price={marketPrice} />
          </td>
        </tr>
      ) : null}

      {lastRound?.pps || lastRound?.valuation ? (
        <>
          <tr>
            <td className="font-semibold">
              <p>Last Round</p>
              <p className="text-xs text-neutral-800">{lastRound.context}</p>
            </td>
            <td className="pl-2 pt-1">
              <DisplayPriceVsPopoverContentPrice price={lastRound} />
            </td>
          </tr>
          <tr>
            <Button
              variant="hollow-link"
              onClick={navigateToCompanyFundingRounds}
              label="View All Funding Rounds"
              size="xs"
            />
          </tr>
        </>
      ) : null}
    </table>
  )
}

const LoadedPriceVsCell: React.FC<{
  company: Company
  price: RawOrderPrice
  showMarketPrice?: boolean
  showLastRound?: boolean
  labeled?: boolean
}> = ({ price, company, showLastRound = true, showMarketPrice = true, labeled = true }) => {
  const currentMarket = useMostRecentMarketPPSWithoutRedux(company)
  const fundingRounds = (company.fundingRounds ?? []).filter(filterFundingRoundsLessStrictly)
  const lastFundingRound =
    fundingRounds && fundingRounds.length ? fundingRounds[fundingRounds.length - 1] : undefined

  const currentMarketValuation = currentMarket.value()?.val?.valuation
  const currentMarketPPS = currentMarket.value()?.pps
  const lastRoundValuation = lastFundingRound?.valuation
  const lastRoundPPS = lastFundingRound?.pps
    ? splitAdjustPriceToToday(
        lastFundingRound.pps,
        lastFundingRound?.date,
        company.stockSplits.map((split) => split.splitData)
      )
    : null

  const hasMarketPrice = Boolean(currentMarketPPS || currentMarketValuation)
  const hasLastRound = Boolean(lastRoundPPS || lastRoundValuation)

  const popoverHasData = hasMarketPrice || hasLastRound
  const isCompanyUnlockedForMarketPrice = useCompanyUnlockedForFeature("currentMarketPriceData")
  const isCompanyUnlockedForFundingRoundData = useCompanyUnlockedForFeature("fundingRoundsData")
  const isCompanyUnlockedForPopover =
    isCompanyUnlockedForMarketPrice || isCompanyUnlockedForFundingRoundData
  const hidePopoverContent = !popoverHasData || !isCompanyUnlockedForPopover

  return (
    <div className="flex">
      <Popover
        placement="right"
        content={
          <PriceVsPopoverContent
            marketPrice={
              showMarketPrice && isCompanyUnlockedForMarketPrice
                ? { pps: currentMarketPPS, valuation: currentMarketValuation }
                : undefined
            }
            lastRound={
              showLastRound && isCompanyUnlockedForFundingRoundData && lastFundingRound
                ? {
                    pps: lastRoundPPS ?? undefined,
                    valuation: lastRoundValuation ?? undefined,
                    context: format(lastFundingRound.date, "MMMM yyyy"),
                    company,
                  }
                : undefined
            }
          />
        }
        open={hidePopoverContent ? false : undefined}
      >
        {price.match(
          ({ valuation }) => {
            const marketPriceChangePercentage = currentMarketValuation
              ? calculatePercentageChange({ initial: currentMarketValuation, final: valuation })
              : null

            const valuationChangePercentage = lastRoundValuation
              ? calculatePercentageChange({ initial: lastRoundValuation, final: valuation })
              : null

            return (
              <div className="flex flex-col">
                {marketPriceChangePercentage !== null && showMarketPrice ? (
                  <DisplayPercentage
                    percentage={marketPriceChangePercentage}
                    label={labeled ? "MarketPrice" : undefined}
                    featureName="currentMarketPriceData"
                  />
                ) : null}
                {valuationChangePercentage !== null && showLastRound ? (
                  <DisplayPercentage
                    percentage={valuationChangePercentage}
                    label={labeled ? "Last Round" : undefined}
                    featureName="fundingRoundsData"
                  />
                ) : null}
              </div>
            )
          },

          ({ pricePerShare, displayDate }) => {
            const marketPriceChangePercentage = currentMarketPPS
              ? calculatePercentageChange({ initial: currentMarketPPS, final: pricePerShare })
              : null

            const estimatedValuation = valuationFromPricePerShareInformationWrapper(
              pricePerShare,
              displayDate,
              company
            ).toNullable()

            const pricePerShareChangePercentage = lastRoundPPS
              ? calculatePercentageChange({ initial: lastRoundPPS, final: pricePerShare })
              : lastRoundValuation && estimatedValuation
              ? calculatePercentageChange({
                  initial: lastRoundValuation,
                  final: estimatedValuation,
                })
              : null

            return (
              <div className="flex flex-col">
                {marketPriceChangePercentage !== null && showMarketPrice ? (
                  <DisplayPercentage
                    percentage={marketPriceChangePercentage}
                    label={labeled ? "MarketPrice" : undefined}
                    featureName="currentMarketPriceData"
                  />
                ) : null}
                {pricePerShareChangePercentage !== null && showLastRound ? (
                  <DisplayPercentage
                    percentage={pricePerShareChangePercentage}
                    label={labeled ? "Last Round" : undefined}
                    featureName="fundingRoundsData"
                  />
                ) : null}
              </div>
            )
          },
          () => null
        )}
      </Popover>
    </div>
  )
}
export const PriceVsCell: React.FC<{
  companyIds: CompanyIdFields
  price: RawOrderPrice
  showMarketPrice?: boolean
  showLastRound?: boolean
  labeled?: boolean
}> = ({ price, companyIds, showLastRound, showMarketPrice, labeled }) => {
  const company = useCompanies(companyIds.id)

  if (!isLoaded(company)) return null

  return (
    <LoadedPriceVsCell
      company={company}
      price={price}
      showLastRound={showLastRound}
      showMarketPrice={showMarketPrice}
      labeled={labeled}
    />
  )
}
