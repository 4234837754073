export const accountRfqProfiles = [
  "Broker",
  "Cap Table Member",
  "Crossover Hedge Fund",
  "Employee",
  "Family Office",
  "Hedge Fund",
  "Investor/Shareholder",
  "Public Asset Manager",
  "Private Equity",
  "UHNWI",
  "VC Secondaries Fund",
  "Venture Capital / Growth Equity",
  "Other",
  "Not Sure",
] as const

export type AccountRFQProfile = (typeof accountRfqProfiles)[number] | string
