/* eslint-disable max-classes-per-file */

import { identity } from "../fp/Function"
import { fix } from "./Fix"

export class Thunk<out T> {
  constructor(readonly val: () => T | Thunk<T>, readonly message?: string) {}

  static pure<S>(s: S) {
    return s instanceof Thunk ? s : new Thunk<S>(() => s, "thunk pure")
  }

  map<S>(f: (t: T) => S): Thunk<S> {
    return new Thunk(mapThunk(f, this), "thunk map")

    /**
    return new Thunk(() => {
      const v = this.val()
      return v instanceof Thunk ? v.map(f) : f(v)
    }, "map") */
  }

  bind<S>(f: (t: T) => Thunk<S>): Thunk<S> {
    return new Thunk(bindThunk(f, this), "thunk bind")
  }

  force(): T {
    return forceThunk(this)
  }
}

export const distributeThunk =
  <Args extends unknown[], T>(thunk: Thunk<(...args: Args) => T>) =>
  (...args: Args) =>
    thunk.map((f) => f(...args))

export const distributeBindThunk =
  <Args extends unknown[], T>(thunk: Thunk<(...args: Args) => Thunk<T>>) =>
  (...args: Args) =>
    thunk.bind((f) => f(...args))

export class TypeAlignedList<I, O> {
  constructor(private readonly funcs: Function[]) {}

  static identity<T>() {
    return new TypeAlignedList<T, T>([identity])
  }

  compose<O2>(f: (o: O) => O2) {
    return new TypeAlignedList(this.funcs.concat([f]))
  }

  run(i: I) {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, rulesdir/no-assert, @typescript-eslint/no-explicit-any, rulesdir/no-any
    return this.funcs.reduce((acc, next) => (next as unknown as any)(acc) as unknown as O, i)
  }
}

export const trampolineRec = <Args extends unknown[], T>(
  f: (tailcall: (...args: Args) => T | Thunk<T>) => (...args: Args) => T | Thunk<T>
) => {
  const thunked =
    (tailcall: (...args: Args) => Thunk<T>) =>
    (...args: Args) =>
      new Thunk(() => f(tailcall)(...args), "tailcall")
  const fixed = fix(thunked)
  const g = (...args: Args): T => {
    let result: T | Thunk<T> = fixed(...args)
    while (result instanceof Thunk) {
      result = result.val()
    }
    return result
  }
  return g
}

const mapThunk = trampolineRec(
  <S, T>(self: (f: (s: S) => T, s: Thunk<S>) => (() => T) | Thunk<() => T>) =>
    (f: (s: S) => T, s: Thunk<S>) => {
      // thunkDepth += 1
      // console.log(s.message, thunkDepth)
      const v = s.val()
      // console.log(v)
      // thunkDepth -= 1

      return v instanceof Thunk ? self(f, v) : () => f(v)
    }
)

const bindThunk = trampolineRec(
  <S, T>(self: (f: (s: S) => Thunk<T>, s: Thunk<S>) => (() => Thunk<T>) | Thunk<() => Thunk<T>>) =>
    (f: (s: S) => Thunk<T>, s: Thunk<S>) => {
      // thunkDepth += 1
      // console.log(s.message, thunkDepth)
      const v = s.val()
      // console.log(v)
      // thunkDepth -= 1

      return v instanceof Thunk ? self(f, v) : () => f(v)
    }
)

const forceThunk = trampolineRec(<T>(force: (t: Thunk<T>) => T | Thunk<T>) => (x: Thunk<T>) => {
  const v = x.val()
  // console.log(v, x.message, thunkDepth)
  // thunkDepth += 1
  const result = v instanceof Thunk ? force(v) : v
  // thunkDepth -= 1
  return result
})
