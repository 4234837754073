import { Loading } from "common/utils/Loading/Wrapped"
import { useOrderForm } from "./OrderFormContext"
import OrderPageTermSheet from "../OrderPage/OrderTermSheet/OrderPageTermSheet"
import OrderPageOrderPreview from "../OrderPage/OrderPreview/OrderPageOrderPreview"
import Alert from "@stories/components/Alert/Alert"
import { WarningIcon } from "@stories/icons/WarningIcon"

export const OrderFormPreview = () => {
  const { wrappedOrder } = useOrderForm()
  return (
    <>
      {wrappedOrder ? (
        <>
          <OrderPageOrderPreview order={new Loading(wrappedOrder)} />
          <OrderPageTermSheet order={new Loading(wrappedOrder)} />
        </>
      ) : (
        <Alert
          icon={<WarningIcon />}
          variant="danger"
          headline="Form Incomplete"
          message="More information is needed to complete your order. Please review the previous sections and fill in all required fields."
        />
      )}
    </>
  )
}
