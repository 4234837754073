import { notification } from "@stories/components/Antd"
import { Just, Nothing } from "common/containers/Maybe"
import { collections, restrictedCollections } from "common/firestore/Collections"
import { ConnectThread } from "common/model/Messaging/ConnectThread"
import {
  createOverlayOrderFromOrder,
  createPreviousOverlayOrderFromOrder,
} from "common/model/Messaging/DocumentOverlay"
import { saveDocumentOverlay } from "common/model/Messaging/Utils/messagePrimitives"
import { isOrderConnectable } from "common/model/Order/Models/Internal"
import {
  ConstructedOrder,
  MAX_STRINGIFIED_ORDER_LENGTH,
  Order,
  OrderDirection,
  OrderIdFields,
  finalizeConstructedOrder,
  orderLatestQuantityTerms,
  viewOrderIdFields,
} from "common/model/Order/Order"
import { FormOrder } from "common/model/Order/OrderForm/State"
import { runQuotaComplianceChecks } from "common/model/Order/QuotaCompliance/runQuotaComplianceChecks"
import { User, UserAccessFields } from "common/model/User"
import { canShowOnPlatform } from "common/model/data-product/DataPoint/VisibilityFields"
import { FirebaseCommon } from "common/firestore/Interface"
import { lift2Loading, Loading_, mapLoading } from "common/utils/Loading"
import { nestedUndefinedsToEmptyObjects } from "common/utils/ObjectUtils"
import { head } from "common/utils/data/Array/ArrayUtils"
import { assertUnreachable } from "common/utils/fp/Function"
import compatFirebaseApp from "firebase/compat/app"
import { orderBy } from "lodash"
import { useDocuments } from "src/firebase/Firestore"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { useAccountBrokerLinking } from "src/utils/useAccountBrokerLinking"
import { firestoreConverter } from "../model/FirestoreConverter"
import { handleConsoleError, trackEvent } from "../utils/Tracking"
import { FirebaseWriter } from "./Firebase"
import { CreateOrUpdateCRMInterestForOrderOptions, createOrUpdateCRMInterestForOrder } from "./crm"
import { useMemo } from "react"
import { useFirebaseWriter } from "./Context"

const runQuotaComplianceChecksForOrder = async (order: Order, db: FirebaseWriter) => {
  const company = await db.getCompanyById(order.company.id)
  const account = (await db.account(order.source.account.id).get()).data()
  const orderQuantity = head(orderLatestQuantityTerms(order)).toNullable()
  if (!company) {
    throw new Error(`Could not find company with id ${order.company.id}`)
  } else if (!account) {
    throw new Error(`Could not find account with id ${order.source.account.id}`)
  } else if (!orderQuantity) {
    throw new Error("undefined order quantity terms")
  } else {
    return runQuotaComplianceChecks({
      direction: order.direction,
      orderQuantity,
      account,
      company,
    })
  }
}

export const getCollectionForOrder = (
  order: Pick<OrderIdFields, "orderCollection" | "darkpool">
) => {
  if (order.darkpool || order.orderCollection === "darkpool") {
    return restrictedCollections.darkpoolOrderObservations
  }
  if (order.orderCollection === "tentativeInterest") {
    return collections.tentativeInterest
  }
  if (order.orderCollection === "platform" || order.orderCollection === undefined) {
    return restrictedCollections.orderObservations
  }
  if (order.orderCollection === "private") {
    return null
  }
  return assertUnreachable(order.orderCollection)
}

type AddOrderOptions = {
  writeBatch?: compatFirebaseApp.firestore.WriteBatch
  orderReference?: compatFirebaseApp.firestore.DocumentReference<Order>
} & CreateOrUpdateCRMInterestForOrderOptions
export const addOrder = async (
  constructedOrder: ConstructedOrder,
  db: FirebaseWriter,
  { writeBatch, orderReference, updateExistingCRMInterestTerms = false }: AddOrderOptions
) => {
  const collection = getCollectionForOrder(constructedOrder)

  if (!collection) {
    return Promise.reject(new Error("invalid collection"))
  }

  const orderRef =
    orderReference ??
    db.writerDb.collection(collection).withConverter<Order>(firestoreConverter<Order>()).doc()

  const batch = writeBatch ?? db.writerDb.batch()
  const newOrder: Order = finalizeConstructedOrder(constructedOrder, orderRef.id)
  const orderQuotaComplianceViolations = await runQuotaComplianceChecksForOrder(newOrder, db).catch(
    (e) => {
      handleConsoleError(e)
      return []
    }
  )

  const { crmInterestId } = await createOrUpdateCRMInterestForOrder(newOrder, db, batch, {
    updateExistingCRMInterestTerms,
  })

  const newOrderWithQuotaComplianceAndInterestId = {
    ...newOrder,
    orderQuotaComplianceViolations,
    crmInterestId,
  } satisfies Order

  batch.set(orderRef, nestedUndefinedsToEmptyObjects(newOrderWithQuotaComplianceAndInterestId))

  await batch.commit()
  notification.success({
    message: `${constructedOrder.company.name} ${
      constructedOrder.direction === "buy" ? "Bid" : "Offer"
    } order submitted.`,
    placement: "top",
  })
  trackEvent("order-submitted", {
    companyId: constructedOrder.company?.id,
    companyName: constructedOrder.company?.name,
    orderDirection: constructedOrder.direction,
    orderId: newOrder.id,
    visibility: constructedOrder.darkpool ? "darkpool" : "market-visible",
  })

  return orderRef.get().then((doc) => (doc.data() ? Just(doc) : Nothing))
}

export const saveOrderForThread =
  (db: FirebaseCommon.DB, user: User) =>
  (thread: ConnectThread) =>
  (order: Order, formOrder: FormOrder) =>
    saveDocumentOverlay(db)({
      thread,
      orderId: viewOrderIdFields(order),
      documentOverlay: createOverlayOrderFromOrder({
        order,
        formOrder,
        previousOrderOverlay: thread.documentOverlays[order.id]?.orderOverlay,
      }),
      previousDocumentOverlay: createPreviousOverlayOrderFromOrder({
        order,
        formOrder,
        previousOrderOverlay: thread.documentOverlays[order.id]?.orderOverlay,
      }),
      user,
    })

type UpdateOrderOptions = {} & CreateOrUpdateCRMInterestForOrderOptions
export const updateOrder = async ({
  mergedOrder,
  db,
  updateOrderOptions,
}: {
  mergedOrder: Order
  db: FirebaseWriter
  updateOrderOptions: UpdateOrderOptions
}) => {
  const { updateExistingCRMInterestTerms = false } = updateOrderOptions
  const collection = getCollectionForOrder(mergedOrder)

  if (!collection) return Promise.reject(new Error("invalid collection"))
  if (JSON.stringify(mergedOrder).length > MAX_STRINGIFIED_ORDER_LENGTH)
    return Promise.reject(
      new Error(
        `updateOrder: order is too large: ${mergedOrder.orderCollection ?? ""} ${mergedOrder.id} `
      )
    )

  const orderRef = db.writerDb
    .collection(collection)
    .withConverter<Order>(firestoreConverter<Order>())
    .doc(mergedOrder.id)

  // TODO: make a transaction

  const batch = db.writerDb.batch()
  const orderQuotaComplianceViolations = await runQuotaComplianceChecksForOrder(
    mergedOrder,
    db
  ).catch((e) => {
    handleConsoleError(e)
    return []
  })

  const { crmInterestId } = await createOrUpdateCRMInterestForOrder(mergedOrder, db, batch, {
    updateExistingCRMInterestTerms,
  })

  const mergedOrderWithQuotaComplianceAndInterestId = {
    ...mergedOrder,
    orderQuotaComplianceViolations,
    crmInterestId,
  } satisfies Order

  batch.update(
    orderRef,
    nestedUndefinedsToEmptyObjects(mergedOrderWithQuotaComplianceAndInterestId)
  )
  await batch.commit()

  return orderRef.get().then((doc) => (doc.data() ? Just(doc) : Nothing))
}

export const useSuggestedOrdersQuery = ({
  db,
  darkpool,
  companyId,
  user,
  anchorDirection,
}: {
  db: FirebaseWriter
  darkpool: boolean
  companyId: string
  user: UserAccessFields
  anchorDirection?: OrderDirection | null
}) => {
  const collection = darkpool
    ? restrictedCollections.darkpoolOrderObservations
    : restrictedCollections.orderObservations

  const companyQuery = db.writerDb
    .collection(collection)
    .withConverter<Order>(firestoreConverter<Order>())
    .where("company.id", "==", companyId)

  const directionQuery = anchorDirection
    ? companyQuery.where("direction", "==", anchorDirection === "buy" ? "sell" : "buy")
    : companyQuery

  const accountQuery = directionQuery.where("source.account.id", "==", user.account.id)

  const query = accountQuery.orderBy("createdAt", "desc")

  const suggestedOrders = useDocuments<Order>(query, [darkpool])

  return mapLoading((orders: Order[]) =>
    orders.flatMap((o) => (isOrderConnectable(o) && canShowOnPlatform(o, user) ? [o] : []))
  )(suggestedOrders)
}

export const useSuggestedOrders = (props: {
  db: FirebaseWriter
  companyId: string
  user: UserAccessFields
  anchorDirection?: OrderDirection | null
}) => {
  const orders = useSuggestedOrdersQuery({ ...props, darkpool: false })
  const darkpOrders = useSuggestedOrdersQuery({ ...props, darkpool: true })
  return lift2Loading((x, y) => orderBy(x.concat(y), "createdAt"), orders, darkpOrders)
}

type UserCreatedOrderCollection = Exclude<Order["orderCollection"], "private" | undefined>

export const useMyOrdersQuery = ({
  db,
  collection,
  queryLimit,
  userAccountId,
}: {
  db: FirebaseWriter
  collection: UserCreatedOrderCollection
  queryLimit: number
  userAccountId: string
}) => {
  const dbCollection =
    collection === "darkpool"
      ? restrictedCollections.darkpoolOrderObservations
      : collection === "platform"
      ? restrictedCollections.orderObservations
      : collection === "tentativeInterest"
      ? collections.tentativeInterest
      : assertUnreachable(collection)

  const query = db.writerDb
    .collection(dbCollection)
    .withConverter<Order>(firestoreConverter<Order>())
    // we allow account users to see/edit all associated orders
    .where("source.account.id", "==", userAccountId)
    .orderBy("_derived.liveUntil", "desc")
    // additional orderBy createdAt to break ties when a.liveUntil === b.liveUntil
    .orderBy("createdAt", "desc")
    // orderBy id guarantees a consistent ordering for cursor navigation
    // in the unlikely event that a.liveUntil === b.liveUntil && a.createdAt === b.createdAt
    .orderBy("id", "desc")
    // +1 allows us to "peek" at next page
    .limit(queryLimit + 1)
  return useDocuments(query, [dbCollection, queryLimit, userAccountId])
}

export const useMyCompanyOrdersQuery = ({
  collection,
  companyId,
}: {
  collection: UserCreatedOrderCollection
  companyId: string
}) => {
  const loggedInUser = useLoggedInUser()
  const { writerDb } = useFirebaseWriter()

  const query = useMemo(() => {
    const userAccountId = loggedInUser.user.account.id
    const dbCollection =
      collection === "darkpool"
        ? restrictedCollections.darkpoolOrderObservations
        : collection === "platform"
        ? restrictedCollections.orderObservations
        : collection === "tentativeInterest"
        ? collections.tentativeInterest
        : assertUnreachable(collection)

    return (
      writerDb
        .collection(dbCollection)
        .withConverter<Order>(firestoreConverter<Order>())
        // we allow account users to see/edit all associated orders
        .where("source.account.id", "==", userAccountId)
        .where("company.id", "==", companyId)
    )
  }, [writerDb, collection, companyId, loggedInUser.user.account.id])

  return useDocuments(query)
}

export const useAllMyCompanyOrdersQuery = (companyId: string) => {
  // If this is expanded, must also add a query
  const { tentativeInterest, darkpool, platform } = useMemo(
    () => ({
      tentativeInterest: {
        collection: "tentativeInterest" as const,
        companyId,
      },
      darkpool: {
        collection: "darkpool" as const,
        companyId,
      },
      platform: {
        collection: "platform" as const,
        companyId,
      },
    }),
    [companyId]
  )

  const myPlatformOrders = useMyCompanyOrdersQuery(platform)
  const myDarkpoolOrders = useMyCompanyOrdersQuery(darkpool)
  const myTentativeInterestOrders = useMyCompanyOrdersQuery(tentativeInterest)

  return useMemo(
    () => Loading_.Rec.sequence({ myPlatformOrders, myDarkpoolOrders, myTentativeInterestOrders }),
    [myPlatformOrders, myDarkpoolOrders, myTentativeInterestOrders]
  )
}

export const useBrokeredByQuery = ({
  db,
  collection,
  queryLimit,
}: {
  db: FirebaseWriter
  collection: Exclude<Order["orderCollection"], "private" | undefined>
  queryLimit: number
}) => {
  const { isAccountBrokerLinkingEnabled } = useAccountBrokerLinking()
  const user = useLoggedInUser()
  const dbCollection =
    collection === "darkpool"
      ? restrictedCollections.darkpoolOrderObservations
      : collection === "platform"
      ? restrictedCollections.orderObservations
      : collection === "tentativeInterest"
      ? collections.tentativeInterest
      : assertUnreachable(collection)

  const query = db.writerDb
    .collection(dbCollection)
    .withConverter<Order>(firestoreConverter<Order>())
    // we allow account users to see/edit all associated orders
    .where("brokeredBy.user.id", "==", user.user.id)
    .orderBy("_derived.liveUntil", "desc")
    // additional orderBy createdAt to break ties when a.liveUntil === b.liveUntil
    .orderBy("createdAt", "desc")
    // orderBy id guarantees a consistent ordering for cursor navigation
    // in the unlikely event that a.liveUntil === b.liveUntil && a.createdAt === b.createdAt
    .orderBy("id", "desc")
    // +1 allows us to "peek" at next page
    .limit(queryLimit + 1)
  const brokeredByOrders = useDocuments(query, [collection, queryLimit, user.user.id])
  return isAccountBrokerLinkingEnabled ? brokeredByOrders : []
}
