/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from "@heroicons/react/solid"
import { isDataCustomer, isEmployeeAccount, isMarketsCustomer } from "common/model/Auth/Permissions"
import { deprecatedIsLoaded } from "common/utils/Loading"
import { isEmpty } from "lodash"
import React, { useEffect, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Routes } from "src/Routes/Routes"
import { ScreenSize, useViewport } from "src/utils/useViewport"
import { NavItem, navSections, NavSectionType } from "../../../../Routes/NavItems"
import IntercomChat from "../../../../components/Intercom/IntercomChat"
import { useCurrentUser } from "../../../../providers/currentUser/useCurrentUser"
import { IntercomPositioning } from "../../../../providers/intercom/IntercomChatProvider"
import { Logo } from "../../Logo/Logo"
import NavSection from "../NavSection/NavSection"
import { NavigationProvider } from "./NavigationContext"
import { useFeatureFlag } from "src/providers/featureFlags/useFeatureFlags"

interface NavigationProps {
  /** What nav link items should be included in the navbar? */
  items: NavItem[]
  /** What sections should be included? */
  sections: NavSectionType[]
}

// pages where we don't render a full app layout
export const MinimalAppRoutes = ["holdings"]

const Navigation: React.FC<NavigationProps> = ({ items, sections }) => {
  const viewport = useViewport()
  const isCRMEnabled = useFeatureFlag("crm")
  const [isCollapsed, setIsCollapsed] = React.useState(viewport.size !== ScreenSize.large)
  const [isEmptyNavbar, setIsEmptyNavbar] = React.useState<boolean>(true)
  const [isHovered, setIsHovered] = React.useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const currentUser = useCurrentUser()

  const navigateHome = () => {
    if (deprecatedIsLoaded(currentUser) && currentUser.user) {
      navigate(Routes.root)
    } else {
      navigate(Routes.companies.root)
    }
  }

  useEffect(() => {
    if (viewport.size !== ScreenSize.large) {
      setIsCollapsed(true)
      return
    }

    setIsCollapsed(false)
  }, [viewport.size])

  useEffect(() => {
    if (window.Intercom && window.intercomSettings) {
      if (isCollapsed && !isHovered) {
        // eslint-disable-next-line better-mutation/no-mutation
        window.intercomSettings = {
          ...window.intercomSettings,
          horizontal_padding: IntercomPositioning.leftPaddingCollapsed,
        }
        window.Intercom("update", {})
      } else if (
        (!isCollapsed || isHovered) &&
        window.intercomSettings.horizontal_padding !== IntercomPositioning.leftPaddingDefault
      ) {
        // eslint-disable-next-line better-mutation/no-mutation
        window.intercomSettings = {
          ...window.intercomSettings,
          horizontal_padding: IntercomPositioning.leftPaddingDefault,
        }
        window.Intercom("update", {})
      }
    }
  }, [isCollapsed, isHovered])

  const isShrunk = useMemo(() => (isHovered ? false : isCollapsed), [isHovered, isCollapsed])

  const icon = useMemo(
    () => (
      <div className="hover:bg-neutral-800 rounded-sm p-2">
        {isCollapsed ? (
          <ChevronDoubleRightIcon width={16} height={16} color="white" />
        ) : (
          <ChevronDoubleLeftIcon width={16} height={16} color="white" />
        )}
      </div>
    ),
    [isCollapsed]
  )

  const position = useMemo(
    () =>
      isShrunk
        ? "sticky"
        : !isCollapsed && !isShrunk
        ? "sticky"
        : !isCollapsed && isHovered
        ? "sticky"
        : "fixed",
    [isShrunk, isCollapsed, isHovered]
  )

  const hidePlaceholder = useMemo(
    () => (!isShrunk && !isCollapsed) || (isShrunk && isCollapsed),
    [isShrunk, isCollapsed]
  )

  const placeholder = <div className="sticky top-0 z-50 w-20 h-screen" />

  const isMinimalUser = useMemo(() => {
    if (!deprecatedIsLoaded(currentUser)) {
      return false
    }
    return (
      isEmpty(currentUser.user.account.productAreas) && !isEmployeeAccount(currentUser.user.account)
    )
  }, [currentUser])

  useEffect(() => {
    const isMinimalRoute = MinimalAppRoutes.map((route) =>
      location.pathname.includes(route)
    ).includes(true)

    if (!deprecatedIsLoaded(currentUser)) {
      return
    }

    if (isMinimalRoute || isMinimalUser) {
      setIsCollapsed(true)
      setIsEmptyNavbar(true)
    } else {
      setIsEmptyNavbar(false)
    }
  }, [setIsCollapsed, currentUser, setIsEmptyNavbar, location, isMinimalUser])

  const showIntercom = viewport.height > 800

  const sectionsToDisplay: NavSectionType[] = useMemo(() => {
    if (!deprecatedIsLoaded(currentUser)) return []
    if (isMinimalUser) return []
    if (
      isDataCustomer(currentUser.user.account) &&
      !isMarketsCustomer(currentUser.user.account) &&
      !isCRMEnabled
    )
      return navSections.filter((sec) => sec !== "Deal CRM")
    if (isDataCustomer(currentUser.user.account) || isMarketsCustomer(currentUser.user.account))
      return navSections.slice()
    if (isEmployeeAccount(currentUser.user.account)) return ["Controls", "Data", "Insights"]
    return navSections.slice()
  }, [currentUser, isCRMEnabled, isMinimalUser])

  return (
    <NavigationProvider>
      {!hidePlaceholder && placeholder}
      <div
        className={`overflow-y-auto bg-neutral-1000 px-4 h-screen ${position} top-0 z-60 ${
          isShrunk ? "w-20" : "w-64"
        }`}
        onMouseEnter={() => {
          if (!isEmptyNavbar) setIsHovered(true)
        }}
        onMouseLeave={() => setIsHovered(false)}
      >
        <button
          type="button"
          onClick={() => {
            setIsCollapsed(!isCollapsed)
            setIsHovered(false)
          }}
          className={`cursor-pointer flex justify-${
            isShrunk ? "center" : "between"
          } items-center mb-4 w-full h-16`}
        >
          <div onClick={navigateHome}>
            <Logo color="white" isIconOnly={isShrunk} size="regular" />
          </div>
          {!isShrunk && icon}
        </button>
        {!isEmptyNavbar &&
          sectionsToDisplay.map((section) => (
            <div key={section} className="mb-2">
              <NavSection
                title={section}
                items={items.filter((item) => item.section === section)}
                iconsOnly={isShrunk}
                onItemClick={() => undefined}
              />
            </div>
          ))}
        {showIntercom ? (
          <div className={`absolute bottom-4 ${isShrunk ? "left-8" : "left-4"}`}>
            <IntercomChat hide={isShrunk} />
          </div>
        ) : null}
      </div>
    </NavigationProvider>
  )
}

export default Navigation
