import { CarryManagement } from "@components/OrderFormComponents/StructureSection/CarryManagement"
import { BooleanSelect } from "@components/lib/Select/BooleanSelect"
import { RadioSelect } from "@stories/components/Inputs/RadioSelect/RadioSelect"
import SectionLabel from "@stories/components/Inputs/SectionLabel/SectionLabel"
import { doubleLayerSPV } from "common/model/Order/Models/Internal"
import { PriceObservationType } from "common/model/data-product/pricing/PriceObservation"
import { Structure } from "common/model/data-product/pricing/TransactionStructure"
import { head } from "common/utils/data/Array/ArrayUtils"
import { totalMapIndices } from "common/utils/data/TotalMap/Unwrapped"
import { AnimatePresence, motion } from "framer-motion"
import { PriceObservationErrors } from "src/pages/ClosedTrades/ClosedTradeForm/contextHooks/useClosedTradeFormSaving"

type ClosedTradeStructureSectionData = Pick<
  PriceObservationType,
  "structure" | "carriedInterest" | "managementFee" | "rofr" | "layeredStructure"
>
export const ClosedTradeStructureSection = ({
  value,
  onChange,
  title = "Structure",
  titles,
  formErrors,
}: {
  value: Partial<ClosedTradeStructureSectionData>
  onChange: (value: Partial<ClosedTradeStructureSectionData>) => void
  title?: string
  titles: {
    carryManagement: string
    isMultiLayerSpv: string
    isMultiLayerSpvTooltip: string
  }
  formErrors: PriceObservationErrors
}) => (
  <div className="flex flex-col gap-4">
    <SectionLabel hasError={formErrors.has("structure")}>{title}</SectionLabel>
    <RadioSelect<Structure>
      label="Please select the correct structure"
      className="w-full"
      options={[
        { value: "direct", label: "Direct" },
        { value: "spv", label: "SPV" },
        { value: "forward", label: "Forward" },
      ]}
      onChange={(key) =>
        onChange({
          ...value,
          rofr: key === "direct" ? undefined : null,
          carriedInterest: undefined,
          managementFee: undefined,
          structure: {
            defaultValue: 1,
            ...value.structure,
            entries: [{ key, value: 1 }],
          },
        })
      }
      value={value.structure ? head(totalMapIndices(value.structure)).toNullable() : undefined}
    />
    {value.structure?.entries.find((v) => v.key === "spv") ? (
      <AnimatePresence>
        <motion.div
          initial={{ x: 10, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.4 }}
          className="flex flex-col gap-2"
        >
          <div>
            <BooleanSelect
              options={[
                {
                  value: false,
                  label: "Single-layer SPV",
                  subLabel: "(SPV holds shares)",
                },
                {
                  value: true,
                  label: "Multi-layer SPV",
                  subLabel: "(SPV holds units in another fund)",
                },
              ]}
              value={!!value.layeredStructure}
              onChange={(isMultiLayerSpv) =>
                onChange({
                  ...value,
                  layeredStructure: isMultiLayerSpv ? doubleLayerSPV : undefined,
                })
              }
              label={titles.isMultiLayerSpv}
              tooltip={titles.isMultiLayerSpvTooltip}
            />
          </div>
          <div>
            <CarryManagement
              // disabled={hiddenFields?.includes("structures")}
              title={titles.carryManagement}
              onChange={(spv) =>
                onChange({
                  ...value,
                  carriedInterest: spv.carry ?? undefined,
                  managementFee: spv.managementFee,
                })
              }
              value={{
                carry: typeof value.carriedInterest === "number" ? value.carriedInterest : null,
                managementFee: value.managementFee ?? null,
              }}
            />
          </div>
        </motion.div>
      </AnimatePresence>
    ) : null}
  </div>
)
