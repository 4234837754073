import { Account } from "common/model/Account"
import { isEmpty } from "lodash"
import * as React from "react"
import Pill from "../../components/Pill"
import { AccountClientType } from "common/model/Account/AccountClientType"

interface ClientTypePillProps {
  clientType: Account["clientType"]
}

const ClientTypePill: React.FunctionComponent<React.PropsWithChildren<ClientTypePillProps>> = ({
  clientType,
}) => {
  if (!clientType || isEmpty(clientType))
    return <Pill content="No client type" bgClass="bg-neutral-white" textClass="text-neutral-800" />

  return (
    <>
      {clientType.map((type) => (
        <Pill
          key={type}
          content={type}
          bgClass={ClientTypePillBgClasses[type].bgClass}
          textClass={ClientTypePillBgClasses[type].textClass}
        />
      ))}
    </>
  )
}

export default ClientTypePill

const ClientTypePillBgClasses: {
  [key in AccountClientType]: { bgClass: string; textClass: string }
} = {
  Intermediary: { bgClass: "bg-danger-200 bg-opacity-10", textClass: "text-neutral-1000" },
  Journalist: { bgClass: "bg-accent-700 bg-opacity-80", textClass: "text-white" },
  Employee: { bgClass: "bg-accent-700 bg-opacity-80", textClass: "text-white" },
  "Investor/Shareholder": {
    bgClass: "bg-success-500 bg-opacity-10",
    textClass: "text-neutral-1000",
  },
  Lender: { bgClass: "bg-purple-700", textClass: "text-white" },
}
