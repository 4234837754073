import { OrderLookupFields } from "../Order/Models/Internal"
import {
  ORDER_DETAIL_SEARCH_PROP,
  DARKPOOL_ORDER_DETAIL_SEARCH_PROP
} from "../Order/MiscOrderUIStuffThatNeedsToBeCleanedUp"

// admin
export const SELECTED_INTRO_PARAM = "selected_introduction"
// user
export const THREAD_ID_PARAM = "selectedThread"
export const IS_LIVE_RESPONSE = "isLiveResponse"

export const messageThreadRoute = (threadId: string) => `/inbox?${THREAD_ID_PARAM}=${threadId}`
export const messageThreadRouteWithOrderOpen = (threadId: string, orderId: OrderLookupFields) =>
  `${messageThreadRoute(threadId)}&${
    orderId.darkpool ? DARKPOOL_ORDER_DETAIL_SEARCH_PROP : ORDER_DETAIL_SEARCH_PROP
  }=${orderId.id}`
export const messageThreadRouteWithIsLiveResponse = (threadId: string, isLiveResponse: boolean) =>
  `${messageThreadRoute(threadId)}&${IS_LIVE_RESPONSE}=${isLiveResponse.toString()}`
