import { Input } from "@stories/components/Antd"
import { FormLabel } from "@stories/components/Inputs/FormLabel/FormLabel"
import SectionLabel from "@stories/components/Inputs/SectionLabel/SectionLabel"

const { TextArea } = Input

type NotesSectionData = {
  notes: string
}

export const NotesSection = ({
  value,
  onChange,
  hiddenFields,
  title = "Order notes for Caplight",
  titles = {
    notes: "Add any notes you would like Caplight to know about this order",
  },
}: {
  value: Partial<NotesSectionData>
  onChange: (value: Partial<NotesSectionData>) => void
  title?: string
  hiddenFields?: string[]
  titles?: {
    notes?: string
  }
}) => (
  <div>
    {hiddenFields?.includes("notes") ? null : (
      <>
        <SectionLabel>{title}</SectionLabel>
        <div className="flex flex-col gap-4">
          <FormLabel>{titles.notes}</FormLabel>
          <TextArea
            rows={2}
            className="w-full"
            onChange={(e) => onChange({ ...value, notes: e.target.value })}
            value={value.notes}
            disabled={hiddenFields?.includes("notes")}
          />
        </div>
      </>
    )}
  </div>
)

type DoNoIntroSectionData = {
  doNotIntroNotes: string
}

export const DoNoIntroSection = ({
  value,
  onChange,
  hiddenFields,
  title = "Order notes for Caplight",
  titles = {
    doNotIntroNotes: "Parties you would not like to connect with on this opportunity",
  },
}: {
  value: Partial<DoNoIntroSectionData>
  onChange: (value: Partial<DoNoIntroSectionData>) => void
  title?: string
  hiddenFields?: string[]
  titles?: {
    doNotIntroNotes?: string
  }
}) => (
  <>
    {hiddenFields?.includes("doNotIntroNotes") ? null : (
      <>
        <FormLabel>{title}</FormLabel>
        <div>
          <TextArea
            rows={1}
            placeholder={titles.doNotIntroNotes}
            className="w-full"
            onChange={(e) => onChange({ ...value, doNotIntroNotes: e.target.value })}
            value={value.doNotIntroNotes}
            disabled={hiddenFields?.includes("doNotIntroNotes")}
          />
        </div>
      </>
    )}
  </>
)
