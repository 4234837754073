import { useEffect } from "react"
import { useOperatingSystem } from "./useOperatingSystem"

export const useKeyboardShortcutText = (key: string) => {
  const { isMac } = useOperatingSystem()
  return isMac ? `⌘ ${key.toUpperCase()}` : `Ctrl + ${key.toUpperCase()}`
}

export const useKeyboardShortcut = (key: string, onShortcut: () => void) => {
  const { isMac, isWindows } = useOperatingSystem()

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (
        ((isWindows && e.ctrlKey) || (isMac && e.metaKey)) &&
        e.key.toUpperCase() === key.toUpperCase()
      ) {
        e.preventDefault()
        onShortcut()
      }
    }

    window.addEventListener("keydown", handleKeyDown)

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [key, onShortcut, isMac, isWindows])

  return useKeyboardShortcutText(key)
}
