import { isDataCustomer, isEmployeeAccount, isMarketsCustomer } from "common/model/Auth/Permissions"
import { deprecatedIsLoaded } from "common/utils/Loading"
import { Left, Right } from "common/containers/Either"
import { Navigate } from "react-router-dom"
import { Routes } from "src/Routes/Routes"
import { AuthCondition } from "../../../Routes/auth/AuthCondition"
import { UserAuthContext } from "../../../Routes/auth/UserAuthContext"

export const isPlatformUser: AuthCondition<UserAuthContext, UserAuthContext> = (
  ctx: UserAuthContext
) =>
  isPlatformUserContext(ctx)
    ? Right(ctx)
    : Left(() => <Navigate to={Routes.insufficientPermissions} />)

export const isPlatformUserContext = (ctx: UserAuthContext): boolean =>
  deprecatedIsLoaded(ctx.user) &&
  !!ctx.user &&
  (isMarketsCustomer(ctx.user.user.account) ||
    isDataCustomer(ctx.user.user.account) ||
    isEmployeeAccount(ctx.user.user.account))
