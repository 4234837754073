import { Order } from "common/model/Order/Order"
import { Date_ } from "common/utils/dateUtils"
import { DisplayField } from "../DisplayField"
import { useFirebaseAdmin } from "src/firebase/Context"
import { restrictedCollections } from "common/firestore/Collections"
import { FlagOutlierControls } from "../editing/FlagOutlierControls"
import { groupBy } from "common/utils/MapUtils"
import * as _ from "lodash"
import { OutlierCheckResult } from "common/model/data-product/DataPoint/OutlierStatusFields"
import { format } from "date-fns"
import { DataVisibilityFields } from "common/model/data-product/DataPoint/VisibilityFields"
import { firestoreConverter } from "@model/FirestoreConverter"

const outlierItems = (outlier: OutlierCheckResult): { name: string; value: string }[] => [
  {
    name: "Outlier Status",
    value: outlier.tag,
  },
  {
    name: "Scope",
    value:
      outlier.scope?.tag === "term"
        ? `term: ${outlier.scope.termId}`
        : outlier.scope
        ? outlier.scope.tag
        : "missing",
  },
  {
    name: "Flagged For",
    value: outlier?.tag === "auto flagged" ? outlier._flaggedFor?.join(",") ?? "null" : "N/A",
  },
  {
    name: "Date",
    value:
      outlier?.tag === "unflagged"
        ? "N/A"
        : outlier?.date
        ? format(outlier.date, "yyyy-MM-dd h:mm a zz")
        : "null",
  },
  {
    name: "Updated by",
    value:
      outlier?.tag === "unflagged"
        ? "N/A"
        : outlier?.tag === "auto flagged"
        ? "System"
        : outlier?.tag === "cannot check"
        ? "System (not enough data to check)"
        : outlier?.user?.email ?? "null",
  },
]

export const OrderOutlierStatusDisplay = ({ order }: { order: Order }) => {
  const path = useFirebaseAdmin()
    .adminDb.collection(restrictedCollections.orderObservations)
    .withConverter<DataVisibilityFields & { id: string }>(
      firestoreConverter<DataVisibilityFields & { id: string }>()
    )
    .doc(order.id)

  const latestTermOutliers = [
    ...groupBy(
      (u) => (u.scope?.tag === "term" ? u.scope.termId : "global"),
      order.outlierStatus?.outlierHistory ?? []
    ).values(),
  ].map(
    (vs) =>
      _.orderBy(
        vs,
        (u) => (u.tag === "unflagged" ? Date_.minValue.valueOf() : u.date.valueOf()),
        "desc"
      )[0]
  )
  return latestTermOutliers?.map((check, ix) => (
    <ul key={ix} className="divide-y divide-neutral-400">
      <div className="p-4">
        {outlierItems(check).map(({ name, value }) => (
          <li key={name} className="px-6 py-4">
            <DisplayField name={name} value={value} />
          </li>
        ))}
        <FlagOutlierControls outlier={check} path={path} />
      </div>
    </ul>
  ))
}
