import { Loading } from "common/utils/Loading/Wrapped"
import { collections } from "common/firestore/Collections"
import { PostgresTableSummary } from "common/model/newsfeed/FirebaseSnapshots"
import { firestoreConverter } from "@model/FirestoreConverter"
import { identity } from "common/utils/fp/Function"
import { useQuerySnapshot } from "src/utils/hooks/queries/useQuerySnapshot"

export const useNewsfeedLastPostgresId = () =>
  new Loading(
    useQuerySnapshot(
      (db) =>
        db.db
          .collection(collections.postgresHelpers)
          .where("tableName", "==", "newsfeed")
          .withConverter<PostgresTableSummary>(firestoreConverter<PostgresTableSummary>()),
      identity
    )
  ).map((res) => (res.length ? res[0].lastRecord.id : undefined)).unboxed

export const useNewsfeedFundingRoundsLastPostgresId = () =>
  new Loading(
    useQuerySnapshot(
      (db) =>
        db.db
          .collection(collections.postgresHelpers)
          .where("tableName", "==", "newsletter_funding_rounds")
          .withConverter<PostgresTableSummary>(firestoreConverter<PostgresTableSummary>()),
      identity
    )
  ).map((res) => (res.length ? res[0].lastRecord.id : undefined)).unboxed
