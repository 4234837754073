import { ProductInformationKeys } from "common/model/User"
import { InformationPopup } from "./InformationPopup"

export const InformationPopupWrapper = () => (
  <>
    {ProductInformationKeys.map((productKey) => (
      <InformationPopup informationKey={productKey} key={productKey} />
    ))}
  </>
)
