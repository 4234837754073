import CompanyLogoAndName from "@components/CompanyLogoAndName"
import { OrderPreview, Preview } from "@components/OrderFormComponents/OrderPreview"
import { useExpandOrderPreviewButton } from "@components/OrderFormComponents/OrderPreview/ExpandButton"
import { AccessBoundary } from "@components/auth/DataAccess"
import MarketPill from "@stories/components/Badges/MarketPill/MarketPill"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { DealCRMInterest } from "common/model/DealCRM/DealCRMInterest"
import { DealSearch } from "common/model/DealSearch/DealSearch"
import { Order } from "common/model/Order/Order"
import { orderLiveUntil, orderStaleUntil } from "common/model/Order/Types/Status"
import { showInterval } from "common/utils/data/Interval"
import { formatDate, shortDateNoYear } from "common/utils/dateUtils"
import { formatPrice, formatValuation } from "common/utils/math"
import { capitalize } from "lodash"
import { StatusCell as OrderStatusCell } from "src/pages/Orders/MyOrders/MyOrdersRowCells"
import { getMyOrderStatus } from "src/pages/Orders/MyOrders/utils"

const getDisplayDate = (order: Order) => {
  const status = getMyOrderStatus(order)
  if (status === "Expired") {
    return `Expired: ${shortDateNoYear(orderStaleUntil(order))}`
  }
  return `Updated: ${shortDateNoYear(order.lastUpdatedAt)}`
}

export const OrderCardInner = ({ order }: { order: Order }) => {
  const { hiddenFields } = useExpandOrderPreviewButton({
    visibleFields: ["size", "price", "structure"],
  })
  return (
    <>
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <AccessBoundary
            accessLevel={order.orderCollection === "darkpool" ? "hideFromCaplight" : "public"}
          >
            <CompanyLogoAndName
              truncateLength={20}
              company={order.company}
              size="xs"
              shouldMaskDataInDataDog={order.orderCollection === "darkpool"}
            />
          </AccessBoundary>
        </div>
        <MarketPill variant={order.direction === "buy" ? "bid" : "offer"} />
      </div>
      <div className="-ml-2">
        <OrderPreview order={order} hiddenFields={hiddenFields} />
      </div>
    </>
  )
}

export const OrderCard = ({
  order,
  showStatus,
  header,
  footer,
  hideDate,
}: {
  order: Order
  showStatus?: boolean
  header?: React.ReactNode
  footer?: React.ReactNode
  hideDate?: boolean
}) => (
  <div key={order.id} className=" border rounded p-4 space-y-2">
    {header}
    <div className="flex items-center justify-between">
      <div className=" w-28">
        {showStatus ? (
          <OrderStatusCell
            orderStatus={getMyOrderStatus(order)}
            liveUntil={orderLiveUntil(order)}
          />
        ) : null}
      </div>
      {hideDate ? null : (
        <Typography
          text={getDisplayDate(order)}
          weight={Weight.Light}
          color={Color.Subtitle}
          size={Size.XSmall}
        />
      )}
    </div>
    <OrderCardInner order={order} />
    {footer}
  </div>
)

export const CRMInterestCard = ({
  crmInterest,
  header,
  footer,
}: {
  crmInterest: DealCRMInterest | DealSearch
  header?: React.ReactNode
  footer?: React.ReactNode
}) => (
  <div key={crmInterest.id} className=" border rounded p-4 space-y-2">
    {header}

    <div className="flex justify-between items-center">
      <div className="flex items-center space-x-2">
        <CompanyLogoAndName truncateLength={20} company={crmInterest.company} size="xs" />
      </div>
      <MarketPill variant={crmInterest.direction === "buy" ? "bid" : "offer"} />
    </div>
    <div className="-ml-2">
      <Preview
        sizeString={
          crmInterest.amountUSD ? showInterval(formatValuation, true)(crmInterest.amountUSD) : "-"
        }
        hiddenFields={[
          "direction",
          "areTransactionDocumentOnHand",
          "clientROFR",
          "clientRelationship",
          "fee",
          "shareClasses",
        ]}
        direction={crmInterest.direction}
        darkpool={false}
        priceString={crmInterest.targetPrice ? formatPrice(crmInterest.targetPrice) : "-"}
        feeString="-"
        structureString={crmInterest.structure ? capitalize(crmInterest.structure) : "-"}
        createdAtDateString={formatDate(crmInterest.createdAt)}
        shareClassesString="-"
        clientRelationshipString="-"
        areTransactionDocumentOnHandString="-"
        clientROFRString="-"
      />
    </div>
    {footer}
  </div>
)
