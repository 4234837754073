import { DealCRMNoteContactSource, DealCRMNoteDealSource } from "common/model/DealCRM/DealCRMNote"
import { createContext, useContext, useMemo, useState } from "react"
import { useFirebaseWriter } from "src/firebase/Context"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { ITipTapEditor } from "../Components/Notes/ITipTapEditor"
import { getAllNoteMentions } from "../Components/Notes/getAllNoteMentions"
import { saveNewCRMNote } from "../Components/Notes/createCRMNote"
import { handleConsoleError, trackEventInFirestoreAndHeap } from "src/utils/Tracking"
import { useTipTapEditor } from "../Components/Notes/useTipTapEditor"
import { NotificationArgProps, notification } from "@stories/components/Antd/notification"
import Typography, { Weight } from "@stories/components/Typography/Typography"
import { Button } from "@stories/components/Button/Button"
import { useNavigate } from "react-router"
import { routeForCRMNoteSource } from "common/model/DealCRM/utils/dealCRMRouteUtils"

export type SearchableSource = DealCRMNoteContactSource | DealCRMNoteDealSource
type NoteModalCtx = {
  selectedSource: SearchableSource | null
  setSelectedSource: (selected: SearchableSource | null) => void
  onClose: () => void
  onSaveNote: (editor: ITipTapEditor) => Promise<void>
  content: string
  setContent: (content: string) => void
  saveStatus: "idle" | "loading" | "success" | "error"
  allowSave: boolean
  editor: ReturnType<typeof useTipTapEditor> | null
}

const initialContext: NoteModalCtx = {
  selectedSource: null,
  setSelectedSource: () => {},
  onClose: () => {},
  saveStatus: "idle",
  onSaveNote: async () => {},
  content: "",
  setContent: () => {},
  allowSave: false,
  editor: null,
}

const NoteModalContext = createContext(initialContext)

export const NoteModalProvider = ({
  children,
  onClose,
}: React.PropsWithChildren<Pick<NoteModalCtx, "onClose">>) => {
  const [selectedSource, setSelectedSource] = useState<NoteModalCtx["selectedSource"]>(null)
  const [saveStatus, setSaveStatus] = useState<NoteModalCtx["saveStatus"]>("idle")
  const [content, setContent] = useState("")

  const firebase9 = useFirebase9()
  const db = useFirebaseWriter()
  const { user } = useLoggedInUser()

  const allowSave = selectedSource !== null && content.trim().length > 0
  const resetState = useMemo(
    () => () => {
      setContent("")
      setSelectedSource(null)
    },
    []
  )
  const navigate = useNavigate()

  const onSaveNote = useMemo(
    () =>
      async (editor: ITipTapEditor): Promise<void> => {
        if (!allowSave || !editor) {
          throw new Error()
        }

        setSaveStatus("loading")
        const editorContent = editor.getJSON()
        editor.commands.clearContent()
        const mentions = getAllNoteMentions(editorContent)

        try {
          await saveNewCRMNote({
            db,
            user,
            content: editorContent,
            source: selectedSource,
            mentions,
          })

          setSaveStatus("success")
          resetState()

          trackEventInFirestoreAndHeap(firebase9, user, "crm-note-added", {
            companyTags: mentions.filter((tag) => tag.tag === "company").length,
          })
          notification.success(
            noteModalSaveSuccessNotification({
              viewItem: () => navigate(routeForCRMNoteSource(selectedSource)),
              name: selectedSource.name,
            })
          )
          onClose()
        } catch (e) {
          handleConsoleError(e)
          setSaveStatus("error")
        }
      },
    [navigate, onClose, resetState, allowSave, db, firebase9, selectedSource, user]
  )

  const editor = useTipTapEditor({
    placeholder: "Start typing to leave a note...",
    handleSaveNote: allowSave ? onSaveNote : () => {},
    onTextContentChanged: setContent,
    textClassName: "min-h-32",
    initialContent: {
      type: "doc",
      content: [
        {
          type: "paragraph",
          attrs: {
            textAlign: "left",
          },
        },
      ],
    },
  })

  const value: NoteModalCtx = useMemo(
    () => ({
      selectedSource,
      setSelectedSource,
      onSaveNote,
      saveStatus,
      allowSave,
      content,
      setContent,
      editor,
      onClose: () =>
        // eslint-disable-next-line no-alert
        !allowSave || window.confirm("Are you sure you want to cancel?") ? onClose() : null,
    }),
    [
      selectedSource,
      setSelectedSource,
      onSaveNote,
      saveStatus,
      allowSave,
      content,
      setContent,
      editor,
      onClose,
    ]
  )

  return <NoteModalContext.Provider value={value}>{children}</NoteModalContext.Provider>
}

export const useNoteModal = () => useContext(NoteModalContext)

const DURATION_SECONDS = 4
const SUCCESS_NOTIFICATION_KEY = "note-modal-save-success"
const noteModalSaveSuccessNotification = ({
  name,
  viewItem,
}: {
  name: string
  viewItem: () => void
}): NotificationArgProps => ({
  style: {
    width: 400,
  },
  duration: DURATION_SECONDS,
  placement: "top",
  key: SUCCESS_NOTIFICATION_KEY,
  message: <Typography weight={Weight.Bold} text={`Your note on ${name} was saved successfully`} />,
  onClose: () => notification.close(SUCCESS_NOTIFICATION_KEY),
  btn: (
    <div className="flex gap-2">
      <Button
        variant="secondary"
        label={`View ${name}`}
        onClick={() => {
          viewItem()
          notification.close(SUCCESS_NOTIFICATION_KEY)
        }}
        heapName="note-modal-save-success-view-item-button"
      />
    </div>
  ),
})
