import InfoTooltip from "@components/InfoTooltip"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { useSharedOrderEditor } from "./SharedOrderEditorProvider"
import { AsyncDispatchButton } from "@stories/components/Button/AsyncDispatchButton"

export const PreviewEmailButton = () => {
  const { preview } = useSharedOrderEditor()
  const { user } = useLoggedInUser()

  return (
    <AsyncDispatchButton
      label="Preview Email"
      rightIcon={<InfoTooltip text={`Send this email to ${user.email}`} placement="left" />}
      onClick={() => preview.handleSendPreview()}
      variant="secondary"
    />
  )
}
