import { User } from "common/model/User"
import { Loading } from "common/utils/Loading"
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react"
import Firebase9 from "../../firebase/Firebase9"
import { useFirebase9 } from "../../firebase/Firebase9Context"
import { CurrentUser } from "../../model/CurrentUser"
import { handleConsoleError, identifyUser, trackUserSession } from "../../utils/Tracking"
import { useAuthUser } from "../authUser/useAuthUser"
import useErrorHandler from "../errorHandler/useErrorHandler"

const CurrentUserContext = createContext<Loading<CurrentUser>>(null)

const CurrentUserProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState(true)
  const [dbUser, setDbUser] = useState<User | null>(null)
  const [identified, setIdentified] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const authUser = useAuthUser()
  const firebase9 = useFirebase9()
  const { handleError: onError } = useErrorHandler()

  // https://reactjs.org/docs/hooks-effect.html#example-using-hooks-1 handle cleanup of the lister on unmount
  useEffect(() => {
    if (authUser === null) {
      setLoading(false)
      setDbUser(null)
    } else if (authUser === undefined) {
      // Wait for load
    } else {
      setLoading(true)
      // eslint-disable-next-line rulesdir/no-let
      let trackingCleanup: (() => void) | undefined = undefined

      const cleanup = firebase9.getUserSnapshot(
        authUser.uid,
        (next) => {
          const userData = next.data()

          // this implementation avoids race conditions in Authboundary
          if (!next.metadata.hasPendingWrites) {
            setDbUser(userData || null)
            setLoading(false)
          }

          if (userData) {
            const sessionTrackingCleanup = delayedTrackUserSessionStart(firebase9, userData)
            // eslint-disable-next-line better-mutation/no-mutation
            trackingCleanup = () => {
              sessionTrackingCleanup()
            }
          }
        },
        // NOTE: This makes sure we get updates when `next.metadata.hasPendingWrites` changes
        // otherwise, we get a write when .hasPendingWrites is true but never get a write later when it's false
        { includeMetadataChanges: true }
      )
      return () => {
        cleanup()
        if (trackingCleanup) trackingCleanup()
      }
    }
    return undefined
  }, [authUser, firebase9])

  useEffect(() => {
    if (!authUser) {
      setIsAdmin(false)
    } else {
      authUser
        .getIdTokenResult()
        .then((token) => {
          setIsAdmin(!!token.claims.admin || false)
        })
        .catch(onError)
    }
  }, [authUser, onError])

  useEffect(() => {
    if (authUser && dbUser && !identified) {
      identifyUser({ authUser, user: dbUser })
      setIdentified(true)
      setLoading(false)
    }
  }, [dbUser, authUser, identified])

  const currentUser = useMemo(
    () => (loading ? "loading" : authUser && dbUser ? { user: dbUser, authUser, isAdmin } : null),
    [authUser, dbUser, isAdmin, loading]
  )

  return <CurrentUserContext.Provider value={currentUser}>{children}</CurrentUserContext.Provider>
}

const delayedTrackUserSessionStart = (firebase9: Firebase9, user: User): (() => void) => {
  const delayInSeconds = 15 // Require sessions to last at least 15 seconds to be counted
  const timer = setTimeout(() => {
    trackUserSession(firebase9, user).catch(handleConsoleError)
  }, delayInSeconds * 1000)
  const reTrackIntervalInMinutes = 30 // Track a new session every 30 mins
  const interval = setInterval(() => {
    trackUserSession(firebase9, user).catch(handleConsoleError)
  }, reTrackIntervalInMinutes * 1000 * 60)
  return () => {
    clearTimeout(timer)
    clearInterval(interval)
  }
}

const useCurrentUser = () => useContext(CurrentUserContext)

export { CurrentUserProvider, useCurrentUser }
