import { InputProps as CaplightInputProps } from "@stories/components/Inputs/Input/Input"
import { Either, Left, Right } from "common/containers/Either"
import { isRealNumber } from "common/utils/json/validate"
import { FC, useMemo } from "react"
import { RawNumberInput } from "./RawNumberInput"
import React from "react"

export const numberFormats = ["price", "percentage", "default"] as const
export type NumberFormat = (typeof numberFormats)[number]

export const numberPrecisions = ["integer", "decimal"] as const
export type NumberPrecision = (typeof numberPrecisions)[number]

export type NumberInputFormatProps = {
  numberFormat: NumberFormat
  /** @deprecated */
  scale: number
  precision: "integer" | "decimal"
  /** @deprecated */
  decimalPlaces: number
  /** @deprecated */
  totalDigits: number // TODO consolidate with `decimalPlaces`, `precision`, and `numberFormat`
  /** @deprecated */
  overrideWithRounded: boolean // should not exist. modify the rendering logic, not the state
}

type NumberInputValidationProps = {
  min?: number
  max?: number
}

// TODO - prune unused props, there are far too many options
export interface InputProps extends Partial<NumberInputFormatProps>, NumberInputValidationProps {
  id?: string
  label?: string
  dataTestId?: string
  name: string
  value?: number
  onChange?: (value: number | undefined) => void
  addonBefore?: string | React.ReactElement // belongs on the parent
  addonAfter?: React.ReactElement // as with addonBefore
  className?: string
  disabled?: boolean
  subtext?: string // not what that word means
  size?: CaplightInputProps["size"]
}

export const potentiallyValid = (props: Pick<NumberInputFormatProps, "precision">, value: string) =>
  props.precision === "integer" ? /^[0-9]*$/.test(value) : /^[-]?[0-9]*[.]?[0-9]*$/.test(value)

export const formatNumberInput = (
  props: NumberInputFormatProps,
  value: number | undefined
): string =>
  value !== undefined
    ? props.precision === "integer"
      ? Math.floor(value).toString()
      : value.toString()
    : ""

export const parseNumberInput = (
  props: NumberInputFormatProps,
  value: string
): Either<undefined, number | undefined> => {
  const parsed =
    value === ""
      ? undefined
      : props.precision === "integer"
      ? Number.parseInt(value, 10)
      : Number.parseFloat(value)
  return isRealNumber(parsed) ? Right(parsed) : Left(undefined)
}

export const NumberInput: FC<React.PropsWithChildren<InputProps>> = (props) => {
  const {
    onChange = () => {},
    scale,
    numberFormat,
    precision,
    decimalPlaces,
    totalDigits,
    overrideWithRounded,
  } = props

  const formatProps: NumberInputFormatProps = useMemo(
    () => ({
      numberFormat: numberFormat ?? "default",
      scale: scale ?? 1,
      precision: precision ?? "integer",
      decimalPlaces: decimalPlaces ?? 0,
      totalDigits: totalDigits ?? 10,
      overrideWithRounded: overrideWithRounded ?? false,
    }),
    [decimalPlaces, numberFormat, overrideWithRounded, precision, scale, totalDigits]
  )

  const formattedValue = formatNumberInput(formatProps, props.value)

  const onUpdate = useMemo(
    () => (s: string) => {
      parseNumberInput(formatProps, s).match(
        (x) => {
          onChange(x)
        },
        (x) => onChange(x)
      )
    },
    [formatProps, onChange]
  )

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <RawNumberInput onUpdate={onUpdate} {...props} initialValue={formattedValue} />
}
