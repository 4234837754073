import { Color } from "@stories/components/Typography/Typography"
import { Tooltip } from "@stories/components/Antd/Tooltip/Tooltip"
import { Badge } from "@stories/components/Antd/Badge/Badge"
import { ShareIcon } from "@stories/icons/ShareIcon"
import { SharedOrder, isSharedOrderSharedWithUser } from "common/model/SharedOrder/SharedOrder"
import { deprecatedIsLoaded } from "common/utils/Loading"
import { useFirebaseWriter } from "src/firebase/Context"
import { useDocuments } from "src/firebase/Firestore"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { useMemo } from "react"
import { useOrderDetailsDrawer } from "@components/OrderDetails/OrderDetailsWrapper"
import { ShareableItem } from "common/model/SharedOrder/SharedOrderResponse"

export const ShareOrderStatusIcon = ({
  item,
  showIconOnEmpty,
}: {
  item: ShareableItem
  showIconOnEmpty?: boolean
}) => {
  const { user } = useLoggedInUser()
  const firebase = useFirebaseWriter()
  const handleOrderDetailOpen = useOrderDetailsDrawer()
  const handleClick = () =>
    item.tag === "order" ? handleOrderDetailOpen(item.order, "sharing") : null

  const sharedOrders = useDocuments<SharedOrder>(
    firebase.getSharedOrdersFromSharableItem(item, user.account),
    [item, user.account]
  )
  const [totalActiveShareCount, totalActiveUserCount] = useMemo(() => {
    if (!deprecatedIsLoaded(sharedOrders)) return [0, 0]
    const activeSharedOrdersUsers = sharedOrders.map((o) =>
      Object.values(o.sharedWith)
        .filter((u) => isSharedOrderSharedWithUser(o, u.id))
        .map((u) => u)
        .flat()
    )
    return [sharedOrders.length, activeSharedOrdersUsers.length]
  }, [sharedOrders])

  if (!totalActiveShareCount)
    return showIconOnEmpty ? <ShareIcon color={Color.Primary} size="medium" /> : null

  return (
    <Tooltip title={`Shared ${totalActiveShareCount} times. With ${totalActiveUserCount} people.`}>
      <div
        role="button"
        tabIndex={0}
        className="cursor-pointer"
        onClick={handleClick}
        onKeyUp={(e) => e.code === "Enter" && handleClick}
      >
        <Badge count={totalActiveShareCount} size="small">
          <ShareIcon color={Color.Primary} size="medium" />
        </Badge>
      </div>
    </Tooltip>
  )
}
