import { Company } from "common/model/Company"
import { Order } from "common/model/Order/Order"
import { isLoaded } from "common/utils/Loading"
import * as Wrapped from "common/model/Order/Models/Wrapped"
import * as W from "common/utils/Loading/Wrapped"
import { Just, Nothing } from "common/containers/Maybe"
import { useMemo } from "react"
import { firestoreConverter } from "common/model/FirestoreConverter"
import { restrictedCollections } from "common/firestore/Collections"
import { useSimpleQuerySnapshot } from "src/utils/useDb"
import { isHidden } from "common/model/data-product/DataPoint"
import { canShowOnPlatform } from "common/model/data-product/DataPoint/VisibilityFields"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { orderQuantityTerms } from "common/model/Order/Optics"
import { useAccountSnapshot } from "src/providers/AccountSnapshot/useAccountSnapshot"

export const useFullCompanyLiveMarketOrders = (company: Company | undefined) => {
  const { accountSnapshot } = useAccountSnapshot()
  const orders = useSimpleQuerySnapshot(
    (db) =>
      db.writerDb
        .collection(restrictedCollections.orderObservations)
        .withConverter<Order>(firestoreConverter<Order>())
        .where("company.id", "==", company?.id ?? "N/A"),
    [company?.id]
  )
  const user = useLoggedInUser() // TODO: check that this is ok
  return useMemo(
    () =>
      new W.Loading(
        isLoaded(orders)
          ? orders.flatMap((x) =>
              !isHidden(x) &&
              canShowOnPlatform(x, user.user) &&
              orderQuantityTerms.views(x).some((q) => !!q?.shares || !!q?.amountUSD)
                ? Wrapped.Order.wrapRaw({
                    order: x,
                    company: company ? Just(company) : Nothing,
                  })
                    .toArray()
                    .filter((o) => {
                      const latestTerms = o.latestTermUpdate().unboxed.update.quantityTerms
                      return !!latestTerms.amountUSD || !!latestTerms.shares
                    })
                    .filter((o) => o.canShowToAccount(accountSnapshot))
                : []
            )
          : orders
      ),
    [accountSnapshot, company, orders, user.user]
  )
}
