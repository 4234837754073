import { BooleanSelect } from "@components/lib/Select/BooleanSelect"
import { useOrderForm } from "src/pages/Orders/OrderForm/OrderFormContext"
import { useDarkpoolState } from "src/pages/Orders/OrderForm/contextHooks/useDarkpoolState"
import { useFormOrderLabels } from "./useFormOrderLabels"

export const VisibilitySelect = () => {
  const { formOrder } = useOrderForm()
  const { setIsDarkpoolMode, isDarkpoolMode, canChangeDarkpoolMode } = useDarkpoolState()
  const { marketVisibilitySectionLabels } = useFormOrderLabels(formOrder)
  return (
    <BooleanSelect
      label={marketVisibilitySectionLabels.title}
      value={isDarkpoolMode}
      options={[
        {
          label: "Live Market",
          value: false,
          tooltip: marketVisibilitySectionLabels.subtitles.marketVisibleVisibilityTooltip,
        },
        {
          label: "Darkpool",
          value: true,
          tooltip: marketVisibilitySectionLabels.subtitles.darkpoolVisibilityTooltip,
        },
      ]}
      onChange={setIsDarkpoolMode}
      disabled={!canChangeDarkpoolMode}
      hasError={false}
    />
  )
}
