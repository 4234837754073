import _ from "lodash"
import { nestedUndefinedsToEmptyObjects } from "../../utils/ObjectUtils"
import { stringValidator, Validator, numberValidator } from "../../utils/Validator"
import { CompanyIdFields, Company, viewCompanyIdFields } from "../Company"
import { Timestamp } from "../postgres/PostgresCommon"
import { PostgresFundingRound } from "../postgres/PostgresFundingRound"
import { CompanyValueMetrics } from "../CompanyValuation"

interface FundingRound {
  id: string
  company: CompanyIdFields
  companyValue: CompanyValueMetrics
  date: Date
  fundingAmount?: number
  roundName?: string // Series A, Seed
  meta: {
    createdAt: Date
    updatedAt: Date
    companyLastUpdatedInPostgresTimestamp: Timestamp
  }
}

export const isSecondaryRound = (fr: Pick<FundingRound, "roundName">) =>
  (fr.roundName || "").toLowerCase().includes("secondary")

const postgresFundingRoundToFirestoreFundingRoundInfo = (
  fr: PostgresFundingRound
): Omit<FundingRound, "meta" | "id" | "company"> => {
  const date = new Date(fr.date)
  const {
    funding_round_info: {
      post_money_valuation_usd: valuation,
      average_price_per_share_usd: pps,
      funding_amount_usd: fundingAmount,
    },
  } = fr
  const companyValue = nestedUndefinedsToEmptyObjects<FundingRound["companyValue"]>({
    valuation,
    pps,
  })
  return nestedUndefinedsToEmptyObjects<Omit<FundingRound, "meta" | "id" | "company">>({
    companyValue,
    date,
    fundingAmount,
    roundName: fr.round,
  })
}

export const postgresFundingRoundToFirestore = (
  fr: PostgresFundingRound,
  firestoreCompany: Company | undefined
): Omit<FundingRound, "meta" | "id"> | undefined => {
  if (!firestoreCompany) return undefined
  const fireFundingRound = postgresFundingRoundToFirestoreFundingRoundInfo(fr)
  return {
    company: viewCompanyIdFields(firestoreCompany),
    ...fireFundingRound,
  }
}

export interface CompanyInfoUpdates {
  companyId: string
  companyLastUpdatedAtTimestamp: number
}

export const validateCompanyInfoUpdates: Validator<unknown, CompanyInfoUpdates> =
  Validator.fromRecord({
    companyId: stringValidator.validate,
    companyLastUpdatedAtTimestamp: numberValidator.validate,
  })
