import { annotate } from "common/utils/Coerce"
import { EntryOf, Rec, setFieldSimple } from "common/utils/RecordUtils"
import { useMemo, useReducer } from "react"

/** A somewhat more convenient wrapper around `useReducer` */
export const useAction = <T, K extends string | number | symbol>(
  initialState: T,
  actions: Record<K, (t: T) => T> // morally, Match<{}, T => T>
): [T, Record<K, () => void>] => {
  const [x, f] = useReducer((old: T, k: K) => actions[k](old), initialState)
  const memoizedActions = useMemo(
    () =>
      Rec.indexedMap(
        actions,
        ([i, __]) =>
          () =>
            f(i)
      ),
    [actions]
  )
  return [x, memoizedActions]
}

type FiberedMatch<T, X extends Record<keyof T, unknown>> = {
  [key in keyof T]: (t: T[key]) => X[key]
}
type Match<T, X> = FiberedMatch<T, Record<keyof T, X>>
type Action<S, T> = (t: T, s: S) => T // an (uncurried) action of S on T

/** As `useAction`, but with support for actions that take parameters. `useAction`, by contrast, supports only actions of type `() => void`. */
export const useParameterizedAction = <T, S>(
  initialState: T,
  actions: Match<S, (t: T) => T>
): [T, Match<S, void>] => {
  const [x, f] = useReducer(
    (old: T, [actKey, actParam]: EntryOf<S>) => actions[actKey](actParam)(old),
    initialState
  )
  const memoizedActions: Match<S, void> = useMemo(
    () =>
      Rec.indexedMap(
        actions,
        ([i, _]) =>
          (s) =>
            f([i, s])
      ),
    [actions]
  )
  return [x, memoizedActions]
}

export const useFields = <T>(initialState: T) => {
  const memoizedActions = useMemo(
    () =>
      annotate<Match<T, (t: T) => T>>(
        Rec.indexedMap(
          initialState,
          ([k, _v]) =>
            (v: T[keyof T]) =>
            (x: T) =>
              setFieldSimple(k, v, x)
        )
      ),
    [initialState]
  )

  return useParameterizedAction(initialState, memoizedActions)
}
