import { ArrowNarrowRightIcon } from "@heroicons/react/solid"
import { LocationDescriptor } from "history"
import { FC } from "react"
import { Link } from "react-router-dom"
import { classNames } from "../../utils/classNames"

type Size = "xxs" | "xs" | "sm" | "md" | "lg"
export interface NextButtonProps {
  onClick?: () => void
  text: string
  disabled?: boolean
  noIcon?: boolean
  linkTo?: string | LocationDescriptor
  size?: Size
  color?: "green-primary" | "primary" | "gray" | "white" | "red" | "primary-light"
  type?: "submit" | "cancel"
  id?: string
  testId?: string
}

const buttonClasses: Record<Size, string> = {
  xxs: "h-6 text-xs px-2",
  xs: "h-8 text-sm px-4",
  sm: "h-8 text-sm px-4",
  md: "text-base py-2.5 px-3.5",
  lg: "text-lg py-3 px-4",
}
export const NextButton: FC<React.PropsWithChildren<NextButtonProps>> = ({
  onClick,
  linkTo,
  text,
  disabled,
  noIcon,
  size,
  color,
  type,
  id,
  testId,
}) => {
  const body = (
    // eslint-disable-next-line react/button-has-type
    <button
      id={id}
      onClick={onClick}
      type={type === "cancel" ? "reset" : "submit"}
      data-test-id={testId}
      disabled={disabled}
      className={classNames(
        size ? buttonClasses[size] : "h-8 text-sm px-4",
        !color ||
          color === "green-primary" ||
          color === "primary" ||
          color === "primary-light" ||
          color === "red"
          ? "text-white "
          : "text-black",
        !disabled && (!color || color === "green-primary")
          ? "bg-primary-500 hover:bg-primary-600"
          : "",
        !disabled && color === "primary" ? "bg-primary-500" : "",
        !disabled && color === "primary-light" ? "bg-accent-700" : "",
        !disabled && color === "gray" ? "bg-neutral-300 hover:bg-neutral-200" : "",
        !disabled && color === "white" ? "bg-neutral-white hover:bg-neutral-200" : "",
        !disabled && color === "red" ? "bg-danger-600 hover:bg-danger-200" : "",
        size === "sm" ? "font-medium" : "font-semibold",
        size === "xs" ? "rounded-sm" : "rounded-md",
        "w-full flex justify-center items-center border border-transparent shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 whitespace-nowrap",
        disabled ? "cursor-not-allowed bg-neutral-600" : ""
      )}
    >
      {text}
      {!noIcon && (
        <ArrowNarrowRightIcon
          className="ml-3 -mr-1 mt-0.5 h-5 w-5 font-normal"
          aria-hidden="true"
        />
      )}
    </button>
  )

  if (linkTo) {
    return <Link to={linkTo}>{body}</Link>
  }
  return <div>{body}</div>
}

export const OutlineButton: FC<React.PropsWithChildren<NextButtonProps & { linkTo?: string }>> = ({
  onClick,
  text,
  disabled,
  noIcon,
  linkTo,
}) => {
  const body = (
    <button
      onClick={onClick}
      type="submit"
      disabled={disabled}
      className={classNames(
        "w-full flex justify-center py-1 px-4 border border-transparent rounded-md shadow-sm text-base font-semibold text-neutral-700 focus:outline-none ",
        disabled ? "cursor-default bg-neutral-white-100" : "bg-neutral-white "
      )}
    >
      {text}
      {!noIcon && (
        <ArrowNarrowRightIcon className="ml-3 -mr-1 mt-1 h-5 w-5 font-normal" aria-hidden="true" />
      )}
    </button>
  )
  if (linkTo) {
    return <Link to={linkTo}>{body}</Link>
  }
  return <div>{body}</div>
}
