import _ from "lodash"
import * as zod from "zod"
import { getCumulativeSplitAdjustments } from "../../queries/enrichment/payload/SplitAdjustment"
import { SplitDisplayPriceData } from "../../queries/pricing/PricingEnrichment"
import { Date_, fullMonthAndYearFormat } from "../../utils/dateUtils"
import { splitAdjustPriceToToday } from "../data-product/valuationCalculation/Valuation"
import { isAnnouncedIPO, isPrimaryRound, isTenderOffer } from "../postgres/PostgresFundingRound"
import { StockSplitData } from "../Stock/StockSplit"
import { CaplightSource, RoundStatus } from "../../types-for-typeorm/sharedTypes"

export const fundingRoundStatuses = [
  "rumored",
  "announced",
  "completed",
  "failed",
  "postponed",
  "upcoming",
] as const
export type FundingRoundStatus = (typeof fundingRoundStatuses)[number]

// TODO - not allow undefined
export const snapshotStatusToRoundStatus = (x: RoundStatus | undefined): FundingRoundStatus => {
  if (!x) return "announced"
  switch (x) {
    case RoundStatus.ANNOUNCED_IN_PROGRESS:
      return "announced"
    case RoundStatus.COMPLETED:
      return "completed"
    case RoundStatus.FAILED_CANCELLED:
      return "failed"
    case RoundStatus.POSTPONED:
      return "postponed"
    case RoundStatus.RUMOR_SPECULATION:
      return "rumored"
    case RoundStatus.UPCOMING:
      return "upcoming"
    default:
      return "announced"
  }
}

export const roundStatusToSnapshotStatus = (x: FundingRoundStatus): RoundStatus | undefined => {
  switch (x) {
    case "announced":
      return RoundStatus.ANNOUNCED_IN_PROGRESS
    case "completed":
      return RoundStatus.COMPLETED
    case "failed":
      return RoundStatus.FAILED_CANCELLED
    case "postponed":
      return RoundStatus.POSTPONED
    case "rumored":
      return RoundStatus.RUMOR_SPECULATION
    case "upcoming":
      return RoundStatus.UPCOMING
    default:
      return undefined
  }
}

export const companyFundingRound = zod.object({
  date: zod.date(),
  updatedAt: zod.date(),
  pps: zod.number().nullable(),
  valuation: zod.number().nullable(),
  amount: zod.number().nullable(),
  roundName: zod.string(),
  modelVersion: zod.literal(1),
  status: zod.enum(fundingRoundStatuses).nullable(),
  caplightSource: zod.nativeEnum(CaplightSource).nullable(),
  dealPbid: zod.string().nullable(),
})

/** A funding round embedded within a company, unlike `FundingRound`, which is an independent document. (These should eventually be unified.) */
export type CompanyFundingRound = Readonly<zod.infer<typeof companyFundingRound>>

export const companyFundingRoundsComparator = (
  l: CompanyFundingRound,
  r: CompanyFundingRound
): boolean =>
  l.date.valueOf() === r.date.valueOf() &&
  l.roundName === r.roundName &&
  l.amount === r.amount &&
  l.pps === r.pps &&
  l.valuation === r.valuation &&
  l.status === r.status &&
  l.modelVersion === r.modelVersion &&
  l.caplightSource === r.caplightSource &&
  l.dealPbid === r.dealPbid

/**
 *
 * @param latestSnapshotOfRounds: new (revised) list of rounds
 * @param olderSnapshotOfRounds: current list of rounds
 * @returns list of rounds to add to the company object, preserving the updatedAt field
 */
export const updateEmbeddedFundingRounds = (
  latestSnapshotOfRounds: CompanyFundingRound[],
  olderSnapshotOfRounds: CompanyFundingRound[]
): CompanyFundingRound[] => {
  const oldRoundsToKeep = _.intersectionWith(
    olderSnapshotOfRounds,
    latestSnapshotOfRounds,
    companyFundingRoundsComparator
  )
  const newRounds = _.differenceWith(
    latestSnapshotOfRounds,
    olderSnapshotOfRounds,
    companyFundingRoundsComparator
  )
  return _.orderBy([...oldRoundsToKeep, ...newRounds], "date")
}

export const filterFundingRounds = (fr: CompanyFundingRound) =>
  (isPrimaryRound(fr.roundName) || isTenderOffer(fr.roundName)) &&
  (!fr.status ||
    fr.status === "completed" ||
    (fr.status === "announced" && !!fr.valuation && !!fr.pps)) // we only use announced rounds if we have valuation and pps

export const filterFundingRoundsLessStrictly = (fr: CompanyFundingRound) =>
  (isPrimaryRound(fr.roundName) || isTenderOffer(fr.roundName)) &&
  !isAnnouncedIPO(fr) &&
  (fr.status === "completed" || fr.status === "announced") &&
  !(fr.amount === null && fr.valuation === null && fr.pps === null)

export const getPriorFullInformationRound = (fundingRounds: CompanyFundingRound[], date: Date) => {
  const filteredRounds = fundingRounds.filter((fr) => filterFundingRounds(fr) && fr.date <= date)
  const fundingRoundsToUse = filteredRounds
    .slice()
    .sort((l, r) => r.date.valueOf() - l.date.valueOf()) // descending order by date
  const latestRound = fundingRoundsToUse?.[0]
  const latestRoundWithInfo = fundingRoundsToUse.find((fr) => !!fr.valuation && !!fr.pps)
  const notLatestFundingRound = latestRound?.date.valueOf() !== latestRoundWithInfo?.date.valueOf()
  return { latestRound, latestRoundWithInfo, notLatestFundingRound }
}

/**
 * When using funding round information to infer share count (to calculate valuation from pps or the other way round),
 * we restrict the data to the last 5 years.
 * I.e. if the last funding round that we know of is >5 years ago, the share count is likely to be extremely inaccurate,
 * and it's better not to use it.
 */
export const getLatestRoundForCalculations = (fundingRounds: CompanyFundingRound[], date: Date) =>
  getPriorPartialInformationRound(fundingRounds, date)

export const getLatestRoundForDisplay = (fundingRounds: CompanyFundingRound[], date: Date) =>
  getPriorPartialInformationRound(fundingRounds, date)

const getPriorPartialInformationRound = (fundingRounds: CompanyFundingRound[], date: Date) => {
  const filteredRounds = fundingRounds.filter(filterFundingRounds).filter((fr) => fr.date <= date)
  const fundingRoundToUse = _.orderBy(filteredRounds, "date", "desc")

  const latestRound = fundingRoundToUse?.[0]
  const latestRoundWithInfo = fundingRoundToUse.find((fr) => !!fr.valuation || !!fr.pps)
  const notLatestFundingRound = latestRound?.date.valueOf() !== latestRoundWithInfo?.date.valueOf()
  return { latestRound, latestRoundWithInfo, notLatestFundingRound }
}

// TODO - cleanup
type LastFundingRoundForTodaysDisplayReturnType = {
  pps: number | null

  lastRoundValuation: number | null

  roundName: string

  lastRoundDate: Date | null
  formattedActualLastRoundDate: string | null
  dateToDisplay: string
  lastKnownDate: Date
  lastKnownIsOlder: boolean

  splitAdjustmentsToNow: SplitDisplayPriceData[]

  actualLastRoundStatus: FundingRoundStatus | null
  caplightSource: CaplightSource | null
}

export const lastFundingRoundForTodaysDisplay = (
  fundingRounds: CompanyFundingRound[],
  stockSplitsData: StockSplitData[]
): LastFundingRoundForTodaysDisplayReturnType | null => {
  const { latestRound, latestRoundWithInfo } = getLatestRoundForDisplay(fundingRounds, new Date())

  if (!latestRound && !latestRoundWithInfo) return null

  const lastKnownDate = latestRoundWithInfo?.date || Date_.minValue
  const actualLastRoundDate = latestRound?.date || Date_.minValue
  const lastKnownIsOlder = lastKnownDate.valueOf() < actualLastRoundDate.valueOf()

  const formattedActualLastRoundDate = latestRound?.date.valueOf()
    ? fullMonthAndYearFormat(actualLastRoundDate)
    : ""

  const dateToDisplay = latestRoundWithInfo?.date.valueOf()
    ? fullMonthAndYearFormat(lastKnownDate)
    : ""

  const adjustedPPS = latestRoundWithInfo?.pps
    ? splitAdjustPriceToToday(latestRoundWithInfo.pps, latestRoundWithInfo.date, stockSplitsData)
    : undefined

  const lastPPS = latestRoundWithInfo?.pps

  const splitAdjustmentsToNow: SplitDisplayPriceData[] =
    latestRoundWithInfo?.date && lastPPS !== null && lastPPS !== undefined
      ? getCumulativeSplitAdjustments(
          stockSplitsData.filter((spl) => spl.date >= latestRoundWithInfo.date),
          new Date()
        ).map((spl) => ({ ...spl, pricePerShare: lastPPS }))
      : []

  return {
    pps: adjustedPPS ?? null,
    lastRoundValuation: latestRoundWithInfo?.valuation ?? null,
    roundName: latestRoundWithInfo?.roundName || "Undisclosed",
    lastRoundDate: latestRoundWithInfo?.date ?? null,
    formattedActualLastRoundDate,
    dateToDisplay,
    lastKnownDate,
    lastKnownIsOlder,
    splitAdjustmentsToNow,
    actualLastRoundStatus: latestRound?.status ?? null,
    caplightSource: latestRoundWithInfo?.caplightSource ?? null,
  }
}

export const rumoredFundingRound = () =>
  "Specific pricing details of this funding round are not yet verified."

export const familiarPartyFundingRound = (companyName: string) =>
  `Funding round information provided by parties familiar with ${companyName} round. Price per share not disclosed in public filling.`
