import { wrapIcon } from "./IconWrapper"

export const ChatIcon = wrapIcon(
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.3333 3.66667H3.66668C3.48987 3.66667 3.3203 3.7369 3.19527 3.86193C3.07025 3.98695 3.00001 4.15652 3.00001 4.33333C3.00001 4.51014 3.07025 4.67971 3.19527 4.80474C3.3203 4.92976 3.48987 5 3.66668 5H10.3333C10.5102 5 10.6797 4.92976 10.8047 4.80474C10.9298 4.67971 11 4.51014 11 4.33333C11 4.15652 10.9298 3.98695 10.8047 3.86193C10.6797 3.7369 10.5102 3.66667 10.3333 3.66667ZM10.3333 6.33333H3.66668C3.48987 6.33333 3.3203 6.40357 3.19527 6.52859C3.07025 6.65362 3.00001 6.82319 3.00001 7C3.00001 7.17681 3.07025 7.34638 3.19527 7.4714C3.3203 7.59643 3.48987 7.66667 3.66668 7.66667H10.3333C10.5102 7.66667 10.6797 7.59643 10.8047 7.4714C10.9298 7.34638 11 7.17681 11 7C11 6.82319 10.9298 6.65362 10.8047 6.52859C10.6797 6.40357 10.5102 6.33333 10.3333 6.33333ZM11.6667 0.333332H2.33334C1.80291 0.333332 1.2942 0.544046 0.91913 0.919119C0.544057 1.29419 0.333344 1.8029 0.333344 2.33333V9C0.333344 9.53043 0.544057 10.0391 0.91913 10.4142C1.2942 10.7893 1.80291 11 2.33334 11H10.06L12.5267 13.4733C12.589 13.5351 12.6628 13.584 12.7441 13.6172C12.8253 13.6504 12.9123 13.6672 13 13.6667C13.0875 13.6689 13.1742 13.6507 13.2533 13.6133C13.3751 13.5633 13.4793 13.4784 13.5529 13.3692C13.6264 13.2601 13.666 13.1316 13.6667 13V2.33333C13.6667 1.8029 13.456 1.29419 13.0809 0.919119C12.7058 0.544046 12.1971 0.333332 11.6667 0.333332ZM12.3333 11.3933L10.8067 9.86C10.7444 9.79821 10.6705 9.74933 10.5893 9.71615C10.5081 9.68297 10.4211 9.66616 10.3333 9.66667H2.33334C2.15653 9.66667 1.98696 9.59643 1.86194 9.4714C1.73691 9.34638 1.66668 9.17681 1.66668 9V2.33333C1.66668 2.15652 1.73691 1.98695 1.86194 1.86193C1.98696 1.7369 2.15653 1.66667 2.33334 1.66667H11.6667C11.8435 1.66667 12.0131 1.7369 12.1381 1.86193C12.2631 1.98695 12.3333 2.15652 12.3333 2.33333V11.3933Z"
      fill="currentColor"
    />
  </svg>
)
