import { wrapIcon } from "./IconWrapper"

const DotsIconPath = () => (
  <path
    d="M4.66667 8.00001C4.66667 8.35363 4.52619 8.69277 4.27614 8.94281C4.02609 9.19286 3.68696 9.33334 3.33333 9.33334C2.97971 9.33334 2.64057 9.19286 2.39052 8.94281C2.14048 8.69277 2 8.35363 2 8.00001C2 7.64638 2.14048 7.30724 2.39052 7.0572C2.64057 6.80715 2.97971 6.66667 3.33333 6.66667C3.68696 6.66667 4.02609 6.80715 4.27614 7.0572C4.52619 7.30724 4.66667 7.64638 4.66667 8.00001ZM9.33333 8.00001C9.33333 8.35363 9.19286 8.69277 8.94281 8.94281C8.69276 9.19286 8.35362 9.33334 8 9.33334C7.64638 9.33334 7.30724 9.19286 7.05719 8.94281C6.80714 8.69277 6.66667 8.35363 6.66667 8.00001C6.66667 7.64638 6.80714 7.30724 7.05719 7.0572C7.30724 6.80715 7.64638 6.66667 8 6.66667C8.35362 6.66667 8.69276 6.80715 8.94281 7.0572C9.19286 7.30724 9.33333 7.64638 9.33333 8.00001ZM14 8.00001C14 8.35363 13.8595 8.69277 13.6095 8.94281C13.3594 9.19286 13.0203 9.33334 12.6667 9.33334C12.313 9.33334 11.9739 9.19286 11.7239 8.94281C11.4738 8.69277 11.3333 8.35363 11.3333 8.00001C11.3333 7.64638 11.4738 7.30724 11.7239 7.0572C11.9739 6.80715 12.313 6.66667 12.6667 6.66667C13.0203 6.66667 13.3594 6.80715 13.6095 7.0572C13.8595 7.30724 14 7.64638 14 8.00001Z"
    fill="currentColor"
  />
)

export const DotsIcon = wrapIcon(
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <DotsIconPath />
  </svg>
)

export const DotsIconVertical = wrapIcon(
  <svg
    viewBox="0 0 16 16"
    style={{ rotate: "90deg" }}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <DotsIconPath />
  </svg>
)
