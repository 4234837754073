import { CheckboxWithLabel } from "@components/inputs/checkbox/Checkbox"
import { SearchIcon } from "@heroicons/react/solid"
import { filterMaybe, nullableToMaybe } from "common/containers/Maybe"
import { AllAccountsCacheFields } from "common/model/Account"
import { SelectedCompany } from "common/model/Company"
import { DealCRMDeal as DealCRMDealType } from "common/model/DealCRM/Deal/DealCRMDeal"
import { DealCRMBrokerContact } from "common/model/DealCRM/DealCRMBrokerContact"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { DealCRMFirmContact } from "common/model/DealCRM/DealCRMFirmContact"
import { DealCRMIndividualContact } from "common/model/DealCRM/DealCRMIndividualContact"
import { matchLoading } from "common/utils/Loading"
import { assertUnreachable } from "common/utils/fp/Function"
import { FC, useEffect, useMemo, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { handleConsoleError } from "src/utils/Tracking"
import { useOperatingSystem } from "src/utils/hooks/useOperatingSystem"
import { ScreenSize, useViewport } from "src/utils/useViewport"
import { Routes } from "../../../../Routes/Routes"
import AdminVisible from "../../../../admin/AdminVisible"
import CompanyLogo from "../../../../components/CompanyLogo"
import { selectedCompanyFromAlgoliaCompany } from "../../../../components/companies/select/AlgoliaCompanyAutocomplete"
import { Modal } from "../../../../components/layout/Modal"
import { useAdminEngagementDrawer } from "../../../../providers/AdminProviders/AdminEngagementDrawerProvider"
import { searchCompanies } from "../../../../services/Algolia"
import Typography, {
  Color,
  Size,
  Weight,
} from "../../../../stories/components/Typography/Typography"
import { classNames } from "../../../../utils/classNames"
import { useContactDetailsDrawer } from "../../Contacts/ContactDetailsDrawer/helpers"
import { useCRMContacts } from "../../Providers/CRMContactsProvider"
import { useCRMDeals } from "../../Providers/CRMDealsProvider"
import AdminAccountSearchSection from "./AdminAccountSearchSection"
import SectionHeader from "./SectionHeader"

export const QuickActionBar = () => {
  const [isOpen, setIsOpen] = useState(false)
  const viewport = useViewport()
  const user = useCurrentUser()
  const { isMac, isWindows } = useOperatingSystem()
  const isAdmin = matchLoading(user, (usr) => usr.isAdmin, false, false)
  const [adminSearchChecked, setAdminSearchChecked] = useState(false)

  const toggleAdminSearch = useMemo(
    () => () => {
      setAdminSearchChecked(!adminSearchChecked)
    },
    [adminSearchChecked, setAdminSearchChecked]
  )

  useEffect(() => {
    const toggleOpen = () => setIsOpen(!isOpen)
    // If control+K or command+K is pressed, open the quick action bar
    const handleKeyDown = (e: KeyboardEvent) => {
      if (((isWindows && e.ctrlKey) || (isMac && e.metaKey)) && e.key === "k") {
        e.preventDefault()
        if (isAdmin && isOpen) {
          toggleAdminSearch()
        } else {
          toggleOpen()
        }
      }
      if (e.key === "Escape" && isOpen) {
        e.preventDefault()

        setIsOpen(false)
      }
    }

    window.addEventListener("keydown", handleKeyDown)

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [isOpen, setIsOpen, isMac, isWindows, isAdmin, adminSearchChecked, toggleAdminSearch])

  const isMobile = viewport.size === ScreenSize.small

  // if (!isOpen) return null // TODO - can uncomment this once the Contact drawer is moved out of context

  return (
    <>
      <Modal
        open={isOpen}
        handleClose={() => setIsOpen(false)}
        body={
          <ModalBody
            handleClose={() => setIsOpen(false)}
            isOpen
            adminSearchChecked={adminSearchChecked}
            toggleAdminSearch={toggleAdminSearch}
          />
        }
        destroyOnClose
        maskClosable
        centered={false}
        noPadding
        wrapperClassName="quick-action-modal"
        modalClassName="rounded-xl top-1/3 p-0 sm:p-0 px-0 pt-0" // TODO - get this working
      />
      <div className="flex items-center gap-2 border rounded-md w-full py-1.5 px-3 cursor-pointer">
        <SearchIcon
          className={`h-5 w-5 text-neutral-800 ${isMobile ? "bg-neutral-1000" : "bg-white"}`}
        />
        <input
          placeholder={`Search ${isWindows ? "(Ctrl + K)" : "(⌘ K)"}`}
          className={`p-0 border-0 focus:ring-0 w-full caret-white ${
            isMobile ? "bg-neutral-1000" : "bg-white"
          } focus:outline-none text-sm ${
            isMobile ? "text-neutral-200" : "text-neutral-800"
          } cursor-pointer`}
          onClick={() => setIsOpen(true)}
        />
      </div>
    </>
  )
}

const contactSearchMatch = (contact: DealCRMContact, searchTerm: string) => {
  if (contact.tag === "firm") return firmContactSearchMatch(contact, searchTerm)
  else return individualContactSearchMatch(contact, searchTerm)
}

const dealSearchMatch = (deal: DealCRMDealType, searchTerm: string) => {
  if (deal.company.name.toLowerCase().includes(searchTerm.toLowerCase())) return true
  else if (deal.name.toLowerCase().includes(searchTerm.toLowerCase())) return true
  return false
}

const individualContactSearchMatch = (
  contact: DealCRMIndividualContact | DealCRMBrokerContact,
  searchTerm: string
) =>
  contact.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
  contact.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
  contact.firm?.name.toLowerCase().includes(searchTerm.toLowerCase())

const firmContactSearchMatch = (contact: DealCRMFirmContact, searchTerm: string) =>
  contact.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
  contact.contacts.some(
    (firmContact) =>
      !firmContact.removedFromFirmAt &&
      firmContact.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

interface SearchModalBodyProps {
  handleClose: () => void
  isOpen: boolean
  adminSearchChecked: boolean
  toggleAdminSearch: () => void
}

const ModalBody: FC<SearchModalBodyProps> = ({
  handleClose,
  isOpen,
  adminSearchChecked,
  toggleAdminSearch,
}) => {
  // Quick action menu with search bar and then search results under, separated into sections:
  // Section 1: Actions: Create a deal, Add a contact
  // Section 2: Search results : contacts and deals

  const [searchTerm, setSearchTerm] = useState("")
  const [highlightedIndex, setHighlightedIndex] = useState<number | undefined>()
  const [highlightedId, setHighlightedId] = useState<string | undefined>()
  const [matchingCompanies, setMatchingCompanies] = useState<SelectedCompany[]>([])
  const [matchingAdminAccounts, setMatchingAdminAccounts] = useState<AllAccountsCacheFields[]>([])

  const loggedInUser = useLoggedInUser()

  const { contacts } = useCRMContacts()
  const { deals } = useCRMDeals()
  const navigate = useNavigate()

  const recentContacts = useMemo(
    () =>
      // eslint-disable-next-line rulesdir/mutating-array-methods
      contacts
        .slice()
        .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())
        .slice(0, 3), // change to toSorted once polyfill situation is figured out
    [contacts]
  )

  const recentDeals = useMemo(
    () =>
      deals
        .slice()
        .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())
        .slice(0, 3), // change to toSorted once polyfill situation is figured out
    [deals]
  )

  const matchingContacts = useMemo(
    () =>
      searchTerm
        ? contacts.filter((c) => contactSearchMatch(c, searchTerm)).slice(0, 5)
        : recentContacts,
    [contacts, searchTerm, recentContacts]
  )

  const matchingDeals: DealCRMDealType[] = useMemo(
    () =>
      searchTerm ? deals.filter((d) => dealSearchMatch(d, searchTerm)).slice(0, 5) : recentDeals,
    [deals, searchTerm, recentDeals]
  )

  useEffect(() => {
    const fetchMatchingCompanies = async () => {
      const results = await searchCompanies(searchTerm, {
        maxResults: 3,
        includeNonPrivateCompanies: false,
      })
      const companies = filterMaybe(results.map(selectedCompanyFromAlgoliaCompany))
      setMatchingCompanies(companies)
    }
    if (searchTerm) fetchMatchingCompanies().catch(handleConsoleError)
    else setMatchingCompanies([])
  }, [searchTerm, setMatchingCompanies])

  const openContactDetails = useContactDetailsDrawer()

  const handleContactClick = useMemo(
    () => (contact: DealCRMContact) => {
      openContactDetails(contact.id)
      handleClose()
    },
    [handleClose, openContactDetails]
  )

  const handleDealClick = useMemo(
    () => (deal: DealCRMDealType) => {
      navigate(Routes.crm.deal(deal.id))
      handleClose()
    },
    [handleClose, navigate]
  )

  const handleCompanyClick = useMemo(
    () => (company: SelectedCompany) => {
      navigate(Routes.companies.company(company.postgresCompanyId))
      handleClose()
    },
    [handleClose, navigate]
  )

  const { openDrawer: openAdminEngagementDrawer } = useAdminEngagementDrawer()

  const handleAdminAccountClick = useMemo(
    () => (account: AllAccountsCacheFields) => {
      if (loggedInUser.isAdmin) {
        openAdminEngagementDrawer(account.id)
        handleClose()
      }
    },
    [handleClose, loggedInUser.isAdmin, openAdminEngagementDrawer]
  )

  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (isOpen) {
      ref.current?.focus()
    }
  }, [isOpen])

  // NOTE: if the order of items changes, adjust the order of these to make the arrow/enter interactions work correctly
  // TODO: probably best to also use this array for the actual display
  const displayedItems = useMemo(
    () => [
      ...matchingAdminAccounts.flatMap((item) =>
        adminSearchChecked && loggedInUser.isAdmin
          ? ({ tag: "adminAccountCache", item } as const)
          : []
      ),
      ...matchingCompanies.map((item) => ({ tag: "company", item } as const)),
      ...matchingContacts.map((item) => ({ tag: "contact", item } as const)),
      ...matchingDeals.map((item) => ({ tag: "deal", item } as const)),
    ],
    [
      adminSearchChecked,
      loggedInUser.isAdmin,
      matchingAdminAccounts,
      matchingCompanies,
      matchingContacts,
      matchingDeals,
    ]
  )

  const displayedItemHandlers = useMemo(
    () =>
      displayedItems.map(({ tag, item }) => {
        switch (tag) {
          case "adminAccountCache": {
            return () => handleAdminAccountClick(item)
          }
          case "company": {
            return () => handleCompanyClick(item)
          }
          case "contact": {
            return () => handleContactClick(item)
          }
          case "deal": {
            return () => handleDealClick(item)
          }
          default: {
            return assertUnreachable(tag)
          }
        }
      }),
    [
      displayedItems,
      handleContactClick,
      handleDealClick,
      handleCompanyClick,
      handleAdminAccountClick,
    ]
  )

  useEffect(() => {
    const ids = displayedItems.map(({ item }) => item.id)
    const itemCount = displayedItems.length

    const handleKeyPress = (e: KeyboardEvent) => {
      // On keydown or key up, increment/decrement highlighted index
      if (e.key === "ArrowDown" && isOpen) {
        e.preventDefault()
        const newIndex = highlightedIndex === undefined ? 0 : (highlightedIndex + 1) % itemCount
        setHighlightedIndex(newIndex)
        setHighlightedId(ids[newIndex])
      }

      if (e.key === "ArrowUp" && isOpen) {
        e.preventDefault()
        const newIndex =
          highlightedIndex === undefined
            ? itemCount - 1
            : (highlightedIndex + itemCount - 1) % itemCount
        setHighlightedIndex(newIndex)
        setHighlightedId(ids[newIndex])
      }

      if (e.key === "Enter" && isOpen && highlightedIndex !== undefined) {
        e.preventDefault()
        nullableToMaybe(displayedItemHandlers?.[highlightedIndex]).runEffect((handler) => handler())
      }
    }

    window.addEventListener("keydown", handleKeyPress)

    return () => {
      window.removeEventListener("keydown", handleKeyPress)
    }
  }, [displayedItems, displayedItemHandlers, highlightedIndex, searchTerm, isOpen])

  // Render a search bar with results underneath, separated into 2 sections: Contacts & Deals
  return (
    <div className="w-150 flex flex-col">
      <div className="flex gap-2 items-center w-full p-2 rounded-md bg-neutral-white">
        {/* Heroicons magnifying glass icon */}
        <SearchIcon className="h-5 w-5 text-neutral-800 bg-neutral-white" />
        <input
          ref={ref}
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search for a company, contact, or deal"
          className="border-0 focus:ring-0 w-full"
        />
      </div>
      <div className="flex flex-col">
        <InstructionsBar
          adminSearchChecked={adminSearchChecked}
          toggleAdminSearch={() => {
            toggleAdminSearch()
            ref.current?.focus()
          }}
        />
        <div className="flex flex-col">
          <AdminVisible>
            {adminSearchChecked ? (
              <AdminAccountSearchSection
                searchTerm={searchTerm}
                isHighlighted={(itemId: string) => itemId === highlightedId}
                matchingAccounts={matchingAdminAccounts}
                setMatchingAccounts={setMatchingAdminAccounts}
                onClick={handleAdminAccountClick}
              />
            ) : null}
          </AdminVisible>
          {searchTerm && matchingCompanies && (
            <>
              <SectionHeader title="Companies" />
              {matchingCompanies.map((company) => (
                <div
                  key={company.id}
                  className={classNames(
                    "cursor-pointer hover:bg-neutral-200",
                    company.id === highlightedId && "bg-primary-100"
                  )}
                  onClick={() => handleCompanyClick(company)}
                >
                  <CompanyRow company={company} handleClick={() => handleCompanyClick(company)} />
                </div>
              ))}
            </>
          )}
          <SectionHeader title={searchTerm ? "Contacts" : "Recent Contacts"} />
          {matchingContacts.map((contact) => (
            <div
              key={contact.id}
              className={classNames(
                "cursor-pointer hover:bg-neutral-200",
                contact.id === highlightedId && "bg-primary-100"
              )}
              onClick={() => handleContactClick(contact)}
            >
              <ContactRow contact={contact} />
            </div>
          ))}
          {matchingContacts.length === 0 && searchTerm && (
            <div className="text-gray-500 p-2">No matching contacts</div>
          )}
        </div>
        <div className="flex flex-col">
          <SectionHeader title={searchTerm ? "Deals" : "Recent Deals"} />
          {matchingDeals.map((deal) => (
            <div
              key={deal.id}
              className={classNames(
                "cursor-pointer hover:bg-neutral-200",
                deal.id === highlightedId && "bg-primary-100"
              )}
            >
              <DealRow handleClick={() => handleDealClick(deal)} deal={deal} />
            </div>
          ))}
          {matchingDeals.length === 0 && searchTerm && (
            <div className="text-gray-500 p-2">No matching deals</div>
          )}
        </div>
      </div>
    </div>
  )
}

interface InstructionsBarProps {
  adminSearchChecked: boolean
  toggleAdminSearch: () => void
}

const InstructionsBar: FC<InstructionsBarProps> = ({ adminSearchChecked, toggleAdminSearch }) => {
  const user = useLoggedInUser()
  const { isMac } = useOperatingSystem()
  const cmdChar = isMac ? "⌘" : "Ctrl"

  return (
    <div className="px-2 py-1 bg-neutral-100 border-t border-neutral-400 flex justify-between items-center">
      <Typography
        text={"Use ↑ or ↓ to navigate, 'Enter' to select an option."}
        size={Size.XSmall}
        weight={Weight.Regular}
        color={Color.Subtitle}
      />
      {user.isAdmin && (
        <div>
          <CheckboxWithLabel
            size="small"
            checked={adminSearchChecked}
            title={`Admin search (${cmdChar} K)`}
            id={""}
            onChange={toggleAdminSearch}
          />
        </div>
      )}
    </div>
  )
}

const ContactRow = ({ contact }: { contact: DealCRMContact }) => {
  switch (contact.tag) {
    case "individual": {
      return <IndividualContactRow contact={contact} />
    }
    case "firm": {
      return <FirmContactRow contact={contact} handleClick={() => {}} />
    }
    case "broker": {
      return <IndividualContactRow contact={contact} />
    }
    default: {
      return assertUnreachable(contact)
    }
  }
}

const IndividualContactRow = ({
  contact,
}: // handleClick,
{
  contact: DealCRMIndividualContact | DealCRMBrokerContact
  // handleClick: () => void
}) => (
  <div className="flex justify-between p-2 border-b border-neutral-400">
    <div>{contact.name}</div>
    <div className="text-neutral-700">
      {contact.tag === "broker"
        ? contact.firm
          ? `Broker (${contact.firm.name})`
          : "Broker"
        : contact.firm
        ? `${contact.title} (${contact.firm.name})`
        : ""}
    </div>
  </div>
)

const FirmContactRow = ({
  contact,
  handleClick,
}: {
  contact: DealCRMFirmContact
  handleClick: () => void
}) => (
  <div className="flex justify-between p-2 border-b border-neutral-400" onClick={handleClick}>
    <div>{contact.name}</div>
    <div className="text-neutral-700">{contact.classification}</div>
  </div>
)

const DealRow = ({ deal, handleClick }: { deal: DealCRMDealType; handleClick: () => void }) => (
  <div className="flex justify-between p-2 border-b border-neutral-400" onClick={handleClick}>
    <div>{deal.name}</div>
    <div className="text-neutral-700">{deal.stage}</div>
  </div>
)

const CompanyRow = ({
  company,
  handleClick,
}: {
  company: SelectedCompany
  handleClick: () => void
}) => (
  <div className="flex justify-between p-2 border-b border-neutral-400" onClick={handleClick}>
    <div className="flex gap-2 items-center">
      <CompanyLogo size="xs" company={company.airtableId} disableClick />
      <Typography color={Color.Black} text={company.name} size={Size.Small} />
    </div>
  </div>
)
