import { DealCRMPriority } from "common/model/DealCRM/Deal/DealCRMDeal"
import React from "react"
import { getPriorityIcon } from "./helpers"

interface PriorityPillProps {
  priority: DealCRMPriority
}

export const PriorityPill: React.FC<PriorityPillProps> = ({ priority }) => (
  <div className="flex items-center rounded-md gap-0">{getPriorityIcon(priority)}</div>
)
