import InfoTooltip from "@components/InfoTooltip"
import { Checkbox } from "@stories/components/Antd"
import { FormLabel } from "@stories/components/Inputs/FormLabel/FormLabel"
import { SelectOptions } from "@stories/components/Inputs/RadioSelect/RadioSelect"

export interface CheckboxSelectProps<T> {
  value?: T[] | undefined
  label?: string
  tooltip?: string
  onChange: (updated: T[] | undefined, added?: T | undefined) => void
  options?: SelectOptions<T>[]
  disabled?: boolean
  hasError?: boolean
  isOptional?: boolean
}

// it would be good to split this up into SingleCheckboxSelect plus a general purpose Select<T>[] => Select<T[]> combinator
export function MultiCheckboxSelect<T>({
  value,
  label,
  tooltip,
  onChange,
  disabled,
  hasError = false,
  options = [],
  isOptional,
}: CheckboxSelectProps<T>) {
  const handleChange = (option: SelectOptions<T>) => {
    const addedValue = !value?.includes(option.value) ? option.value : undefined
    const newValue = value?.includes(option.value)
      ? value.filter((v) => v !== option.value)
      : [...(value ?? []), option.value]
    onChange(newValue.length ? newValue : undefined, addedValue)
  }
  return (
    <>
      <FormLabel hasError={hasError} isOptional={isOptional}>
        {label}
        {tooltip ? <InfoTooltip text={tooltip} /> : null}
      </FormLabel>
      <div>
        {options.map((option) => (
          <Checkbox
            className="mb-1"
            checked={value?.includes(option.value)}
            onClick={() => handleChange(option)}
            disabled={disabled}
            key={option.label}
          >
            {option.label}
          </Checkbox>
        ))}
      </div>
    </>
  )
}
