import { Func } from "../fp/Function"

export namespace Set_ {
  export const map =
    <S>(s: Set<S>) =>
    <T>(f: Func<S, T>): Set<T> =>
      new Set([...s].map((v) => f(v)))

  export const bind = <S, T>(s: Set<S>, f: Func<S, Set<T>>): Set<T> =>
    new Set([...s].flatMap((v) => [...f(v)]))

  export const insert =
    <V>(v: V) =>
    (m: Set<V>): Set<V> =>
      Set_.shallowCopy(m).add(v)

  export const remove =
    <V>(v: V) =>
    (m: Set<V>): Set<V> => {
      const newSet = Set_.shallowCopy(m)
      newSet.delete(v)
      return newSet
    }

  export const toggle =
    <V>(v: V) =>
    (m: Set<V>): Set<V> => {
      const newSet = Set_.shallowCopy(m)
      return newSet.delete(v) ? newSet : newSet.add(v)
    }

  export const shallowEq = <T>(l: Set<T>, r: Set<T>): boolean =>
    [...l].every((x) => r.has(x)) && [...r].every((x) => l.has(x))

  export const union = <T>(...s: Set<T>[]): Set<T> =>
    s.length > 0 ? new Set([...s].flatMap((x) => [...x])) : new Set()

  export const intersection = <T>(...s: Set<T>[]): Set<T> =>
    s.length > 0
      ? new Set([...s].reduce((prev, cur) => new Set([...prev].filter((k) => cur.has(k)))))
      : new Set()

  export const singleton = <S>(s: S): Set<S> => new Set([s])

  export const shallowCopy = <V>(m: Set<V>): Set<V> => new Set([...m])

  export const duplicate = <S>(s: Set<S>): Set<Set<S>> => new Set([...s].map(singleton))

  export const subset = <S>(sub: Set<S>, of: Set<S>) =>
    sub.size === 0 || [...sub].every((x) => of.has(x))
}
