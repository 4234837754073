import Divider from "@stories/components/Divider/Divider"
import { Input } from "@stories/components/Inputs/Input/Input"
import { Color, Size, Typography } from "@stories/components/Typography/Typography"
import { JSONContent } from "@tiptap/react"
import { MenuBar } from "src/pages/CRM/Components/Notes/BubbleMenuBar"
import { ITipTapEditor } from "src/pages/CRM/Components/Notes/ITipTapEditor"
import { WYSIWYGEditor } from "src/pages/CRM/Components/Notes/WYSIWYGEditor"
import { useTipTapEditor } from "src/pages/CRM/Components/Notes/useTipTapEditor"

export const EmailEditor = ({
  introduction,
  emailSubject,
  onEmailSubjectChange,
  initialEmailBody,
  onEmailBodyChange,
}: {
  introduction: string
  emailSubject: string
  onEmailSubjectChange: (subject: string) => void
  initialEmailBody: JSONContent
  onEmailBodyChange: (emailBody: JSONContent) => void
}) => {
  const handleEditorChange = (editor: ITipTapEditor) =>
    Promise.resolve(onEmailBodyChange(editor.getJSON()))
  const userEmailBodyEditor = useTipTapEditor({
    handleSaveNote: handleEditorChange,
    onContentChange: handleEditorChange,
    initialContent: initialEmailBody,
  })
  return (
    <>
      <Input
        value={emailSubject}
        onChange={(e) => onEmailSubjectChange(e.target.value)}
        placeholder="Email Subject"
      />
      {userEmailBodyEditor && (
        <div className="flex flex-col gap-0 border rounded divide-y">
          <div className="flex flex-col gap-2 p-4 bg-white">
            <Typography text={introduction} color={Color.Subtitle} isItalic size={Size.Small} />
            <Divider label="Start of Customizable Content" shouldWrap={false} size={Size.XXSmall} />
            <WYSIWYGEditor
              hideBubbleBar
              showSubmitButton={false}
              autoFocus
              editor={userEmailBodyEditor}
              handleSubmit={handleEditorChange}
              textSize={Size.Small}
              hideBorder
              minHeight
            />
            <Divider label="End of Customizable Content" shouldWrap={false} size={Size.XXSmall} />
            <Typography
              text="View on Caplight (link)"
              color={Color.Subtitle}
              isItalic
              size={Size.Small}
            />
            <Typography
              text="Powered By Caplight"
              color={Color.Subtitle}
              isItalic
              size={Size.Small}
            />
          </div>
          <div className="p-0">
            <MenuBar editor={userEmailBodyEditor} />
          </div>
        </div>
      )}
    </>
  )
}
