import { NumberInput } from "@components/inputs"
import { debounce } from "lodash"
import { FilterFnRecord } from "common/model/newsfeed/NewsFeed"

export const amountAbove = (amt: number): FilterFnRecord => ({
  news: {
    function: () => true,
    asStrings: { field: "amountAbove", value: amt.toString() },
  },
  funding_rounds: {
    function: (f) => (f.numeric_amount || 0) >= amt,
    asStrings: { field: "amountAbove", value: amt.toString() },
  },
})

const debouncedOnAboveAmount = debounce(
  (
    debouncedValue: number | undefined,
    onEnable: (fn: FilterFnRecord["funding_rounds"] | undefined) => void
  ) => {
    if (debouncedValue && debouncedValue > 0) {
      onEnable(amountAbove(debouncedValue * 1e6).funding_rounds)
    } else onEnable(undefined)
  },
  200,
  { leading: false, trailing: true }
)

export const AmountAboveFundingRound = ({
  onEnable,
  valueString,
}: {
  onEnable: (fn: FilterFnRecord["funding_rounds"] | undefined) => void
  valueString: string | null
}) => (
  <div className="flex flex-row text-xs space-x-1 items-center">
    <div className="text-xs whitespace-nowrap ml-2">Amount &ge;</div>
    <div className="h-8">
      <NumberInput
        id="funding_rounds-filter-amount"
        size="small"
        className="w-20"
        name="filter-amount"
        value={valueString ? parseFloat(valueString) / 1e6 : undefined}
        onChange={(val) => {
          debouncedOnAboveAmount(val, onEnable)
        }}
        decimalPlaces={1}
        precision="decimal"
      />
    </div>
    <div className="text-xs w-40 whitespace-nowrap ml-2">$M</div>
  </div>
)
