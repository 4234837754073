/* eslint-disable max-classes-per-file */
import { Moment } from "moment"
import { isOrderUserOwned } from "../../../../model/Order/Models/Internal"
import { Order } from "../../../../model/Order/Order"
import { isLiveOrder } from "../../../../model/Order/Types/Status"
import { User } from "../../../../model/User"
import { isHidden } from "../../../../model/data-product/DataPoint"
import { Endo, identity } from "../../../../utils/fp/Function"
import { Maybe, filterMaybe } from "../../../../containers/Maybe"
import { Promise_ } from "../../../../utils/fp/Promise"
import { prepOrderForDisplay } from "../../../enrichment/model/Orders"
import { enrichWithSplitAdjustments } from "../../../enrichment/payload/SplitAdjustment"
import { OrderCollection, OrderRef, PricingDataRoot } from "../../../pricing/PricingDataSource"
import { Enriched } from "../../../pricing/PricingEnrichment"
import { FirebaseQuery } from "../Collection"
import { FirebaseDocument } from "../Document"

export const enrichOrder =
  (root: PricingDataRoot) =>
  (q: Promise<Order>): Promise<Maybe<Enriched<"order">>> =>
    q
      .then((order) => prepOrderForDisplay(root, order))
      .then((order) =>
        Promise_.Maybe.sequence(
          order.map((o) => enrichWithSplitAdjustments(root)(o.raw.company)(o))
        )
      )

export const enrichOrders =
  (root: PricingDataRoot) => (q: Promise<Order[]>, forUser: User | null) =>
    q
      .then((orders) =>
        orders.filter((order) => !isHidden(order) || isOrderUserOwned(order, forUser))
      )
      .then((orders) => orders.map((order) => prepOrderForDisplay(root, order)))
      .then((x) => Promise.all(x))
      .then(filterMaybe)
      .then((orders) =>
        Promise.all(orders.map((o) => enrichWithSplitAdjustments(root)(o.raw.company)(o)))
      )

export class FirebaseCompanyOrders
  extends FirebaseQuery<Order, FirebaseCompanyOrders>
  implements OrderCollection
{
  readonly _companyOrdersTag = "companyOrders" // to prevent FirebaseAllOrders and FirebaseCompanyOrders from being substituted for eachother

  update = (f: Endo<typeof this.unboxed.ref>) =>
    new FirebaseCompanyOrders({ ...this.unboxed, ref: f(this.unboxed.ref) })

  get: (forUser: User | null, asOf: Moment) => Promise<Enriched<"order">[]> = (forUser) =>
    enrichOrders(this.unboxed.root)(this._rawGet(), forUser)
}

export class FirebaseCompanyLiveOrders
  extends FirebaseQuery<Order, FirebaseCompanyOrders>
  implements OrderCollection
{
  readonly _companyOrdersTag = "companyLiveOrders" // to prevent FirebaseAllOrders and FirebaseCompanyOrders from being substituted for eachother

  update = (f: Endo<typeof this.unboxed.ref>) =>
    new FirebaseCompanyOrders({ ...this.unboxed, ref: f(this.unboxed.ref) })

  get: (forUser: User | null, asOf: Moment) => Promise<Enriched<"order">[]> = (forUser) =>
    enrichOrders(this.unboxed.root)(
      this._rawGet().then((orders) => orders.filter((o) => isLiveOrder(o))),
      forUser
    )
}

export class FirebaseAllOrders
  extends FirebaseQuery<Order, FirebaseAllOrders>
  implements OrderCollection
{
  readonly _allOrdersTag = "allOrders"

  update = (f: Endo<typeof this.unboxed.ref>) =>
    new FirebaseAllOrders({ ...this.unboxed, ref: f(this.unboxed.ref) })

  get: (forUser: User | null, asOf: Moment) => Promise<Enriched<"order">[]> = (forUser) =>
    enrichOrders(this.unboxed.root)(this._rawGet(), forUser)
}
export class FirebaseLiveOrders
  extends FirebaseQuery<Order, FirebaseLiveOrders>
  implements OrderCollection
{
  readonly _liveOrdersTag = "liveOrders"

  update = (f: Endo<typeof this.unboxed.ref>) =>
    new FirebaseLiveOrders({ ...this.unboxed, ref: f(this.unboxed.ref) })

  get: (forUser: User | null, asOf: Moment) => Promise<Enriched<"order">[]> = (forUser) =>
    enrichOrders(this.unboxed.root)(
      this._rawGet().then((orders) => orders.filter((order) => isLiveOrder(order))),
      forUser
    )
}

export class FirebaseOrder extends FirebaseDocument<Order> implements OrderRef {
  get = () =>
    this._rawGet().then((maybeOrder) =>
      maybeOrder.match(
        (order) =>
          enrichOrder(this.unboxed.root)(Promise.resolve(order)).then((enrichedOrder) =>
            enrichedOrder.match(identity, () =>
              Promise.reject(new Error(`Unable to enrich order ${this.unboxed.id}`))
            )
          ),
        () => Promise.reject(new Error(`Unable to find order ${this.unboxed.id}`))
      )
    )
}
