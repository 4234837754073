/* eslint-disable react/jsx-props-no-spreading */

import SearchIcon from "@heroicons/react/solid/SearchIcon"
import Typography, { Color, Size } from "@stories/components/Typography/Typography"
import { titleCaseWords } from "common/utils/StringUtils"
import React, { ComponentProps, useEffect, useMemo, useRef } from "react"
import { GroupBase, NoticeProps, OptionProps, StylesConfig, components } from "react-select"
import AsyncSelect from "react-select/async"
import AsyncCreatableSelect, { AsyncCreatableProps } from "react-select/async-creatable"
import Select from "react-select/dist/declarations/src/Select"
import { SelectComponents } from "react-select/dist/declarations/src/components"
import { FormLabel } from "../FormLabel/FormLabel"

export type CreateNewOption = {
  label: string
  value: string
  __isNew__: true
}

export const isCreateNewOption = (opt: unknown): opt is CreateNewOption =>
  !!opt && Object.hasOwnProperty.call(opt, "__isNew__")

type BuildOptionProps<T> = {
  renderOption: (option: T) => React.ReactNode
}
const buildOption =
  <Value, IsMulti extends boolean = false, Group extends GroupBase<Value> = GroupBase<Value>>({
    renderOption,
  }: BuildOptionProps<Value>) =>
  (props: OptionProps<Value, IsMulti, Group>) =>
    (
      <components.Option {...props}>
        <div className="flex items-center space-x-2">{renderOption(props.data)}</div>
      </components.Option>
    )
const DropdownIndicator: typeof components.DropdownIndicator = () => <span />

const NoOptionsMessage = <T extends unknown>(props: NoticeProps<T, boolean>) => (
  <components.NoOptionsMessage {...props}>
    <Typography text="Type to search" size={Size.Small} color={Color.Subtitle} />
  </components.NoOptionsMessage>
)

const LoadingMessage = <T extends unknown>(props: NoticeProps<T, boolean>) => (
  <components.LoadingMessage {...props}>
    <Typography text="Loading..." size={Size.Small} color={Color.Subtitle} />
  </components.LoadingMessage>
)

const IndicatorSeparator: typeof components.IndicatorSeparator = () => <span />

type BuildControlProps = {
  icon: ((props: React.ComponentProps<"svg">) => JSX.Element) | null
}

const ControlIcon = ({ icon: Icon }: Pick<BuildControlProps, "icon">) =>
  Icon ? <Icon className="h-4 w-4 mr-1" aria-hidden="true" /> : <></>

const buildControl =
  ({ icon }: BuildControlProps): typeof components.Control =>
  ({ children, ...props }) =>
    (
      <components.Control {...props}>
        <span>
          <ControlIcon icon={icon} />
        </span>
        {children}
      </components.Control>
    )

type BaseSearchSelectProps<
  Value,
  IsMulti extends boolean = false,
  Group extends GroupBase<Value> = GroupBase<Value>
> = {
  id: string
  icon?: ((props: React.ComponentProps<"svg">) => JSX.Element) | null
  label?: string
  placeholder?: string
  shouldMaskDataInDataDog?: boolean
  components?: Partial<SelectComponents<Value, IsMulti, Group>>
  overrideStyles?: (
    defaultStyles: StylesConfig<Value, IsMulti, Group>
  ) => StylesConfig<Value, IsMulti, Group>
} & Pick<BuildOptionProps<Value>, "renderOption">

type PassthroughProps =
  | "onKeyDown"
  | "className"
  | "value"
  | "onChange"
  | "loadOptions"
  | "isClearable"
  | "onBlur"
  | "menuIsOpen"
  | "onKeyDown"
  | "getOptionValue"
  | "getOptionLabel"
  | "autoFocus"
  | "onFocus"
  | "defaultOptions"
  | "isDisabled"
  | "isMulti"
  | "cacheOptions"

type NoCreatableSearchSelectProps<
  Value,
  IsMulti extends boolean = false,
  Group extends GroupBase<Value> = GroupBase<Value>
> = BaseSearchSelectProps<Value, IsMulti, Group> & {
  handleCreate?: never
  isValidNewOption?: never
} & Pick<React.ComponentProps<typeof AsyncSelect<Value, IsMulti, Group>>, PassthroughProps>

type CreatableSearchSelectProps<
  Value,
  IsMulti extends boolean = false,
  Group extends GroupBase<Value> = GroupBase<Value>
> = BaseSearchSelectProps<Value, IsMulti, Group> & {
  handleCreate: (input: string) => void
  isValidNewOption?: AsyncCreatableProps<Value, IsMulti, Group>["isValidNewOption"]
} & Pick<React.ComponentProps<typeof AsyncCreatableSelect<Value, IsMulti, Group>>, PassthroughProps>

export type SearchSelectProps<
  Value,
  IsMulti extends boolean = false,
  Group extends GroupBase<Value> = GroupBase<Value>
> =
  | NoCreatableSearchSelectProps<Value, IsMulti, Group>
  | CreatableSearchSelectProps<Value, IsMulti, Group>

const BORDER_COLOR = "#E3E7EC"
const SearchSelect = <
  Value,
  IsMulti extends boolean = false,
  Group extends GroupBase<Value> = GroupBase<Value>
>({
  id,
  icon = SearchIcon,
  label,
  placeholder,
  renderOption,
  handleCreate,
  isValidNewOption,
  shouldMaskDataInDataDog,
  overrideStyles,
  onChange,
  ...props
}: SearchSelectProps<Value, IsMulti, Group>) => {
  const ref = useRef<Select<Value, IsMulti, Group> | null>(null)
  useEffect(() => {
    ref.current?.inputRef?.setAttribute(
      "data-dd-privacy",
      shouldMaskDataInDataDog ? "mask" : "allow"
    )
  }, [ref, shouldMaskDataInDataDog])

  const defaultStyles: StylesConfig<Value, IsMulti, Group> = useMemo(
    () => ({
      control: (base) => ({
        ...base,
        borderColor: BORDER_COLOR,
        "&:hover": {
          borderColor: BORDER_COLOR,
        },
        borderRadius: 4,
        boxShadow: "none",
        display: "flex",
        alignItems: "center",
        padding: "0px 16px",
        zIndex: 0,
        minHeight: undefined,
      }),
      valueContainer: (base) => ({
        ...base,
        padding: "0px 8px",
        alignItems: "center",
        zIndex: 40,
      }),
      multiValueRemove: (base) => ({
        ...base,
        backgroundColor: "#d4d4d4",
        ":hover": {
          backgroundColor: "#a1a1a1",
          color: "white",
        },
      }),
      input: (base) => ({
        ...base,
        borderColor: BORDER_COLOR,
        outline: "0px !important",
        boxShadow: "none",
        marginLeft: placeholder ? "" : "10px",
        "&:focus-visible": {
          boxShadow: "none",
          outline: "0px !important",
        },
        ":focus": {
          outline: "none",
          boxShadow: "none",
        },

        "> input:focus": {
          outline: "none",
          boxShadow: "none",
        },
        zIndex: 0,
      }),
    }),
    [placeholder]
  )

  const Control = useMemo(() => buildControl({ icon }), [icon])
  const Option = useMemo(() => buildOption<Value, IsMulti, Group>({ renderOption }), [renderOption])
  const Component = useMemo(
    () =>
      handleCreate
        ? AsyncCreatableSelect<Value, IsMulti, Group>
        : AsyncSelect<Value, IsMulti, Group>,
    [handleCreate]
  )

  const creatableProps = useMemo(
    () =>
      handleCreate
        ? ({
            formatCreateLabel: (input) => `Create "${titleCaseWords(input)}"`,
            isValidNewOption,
            onCreateOption: handleCreate,
          } satisfies Partial<ComponentProps<typeof AsyncCreatableSelect<Value, IsMulti, Group>>>)
        : {},
    [handleCreate, isValidNewOption]
  )

  const sharedProps:
    | ComponentProps<typeof AsyncSelect<Value, IsMulti, Group>>
    | ComponentProps<typeof AsyncCreatableSelect<Value, IsMulti, Group>> = useMemo(
    () => ({
      ...props,
      ...creatableProps,
      inputId: id,
      ref,
      styles: {
        ...defaultStyles,
        ...overrideStyles?.(defaultStyles),
      },
      components: {
        Option,
        DropdownIndicator,
        NoOptionsMessage,
        IndicatorSeparator,
        LoadingMessage,
        Control,

        ...props.components,
      },
      onChange: (newValue, actionMeta) => {
        if (isCreateNewOption(newValue)) {
          throw new Error("SearchSelect onChange called with CreateNewOption")
        }

        onChange?.(newValue, actionMeta)
      },
      placeholder: <span className="text-sm text-neutral-700">{placeholder}</span>,
    }),
    [
      onChange,
      defaultStyles,
      overrideStyles,
      props,
      id,
      ref,
      placeholder,
      creatableProps,
      Option,
      Control,
    ]
  )

  return (
    <div className="flex flex-col gap-2">
      {label ? <FormLabel>{label}</FormLabel> : null}
      <Component
        {...sharedProps}
        inputId={id}
        ref={ref}
        styles={{
          ...defaultStyles,
          ...overrideStyles?.(defaultStyles),
        }}
        components={{
          Option,
          DropdownIndicator,
          NoOptionsMessage,
          IndicatorSeparator,
          LoadingMessage,
          Control,
          ...props.components,
        }}
        placeholder={<span className="text-sm text-neutral-700">{placeholder}</span>}
      />
    </div>
  )
}

export default SearchSelect
