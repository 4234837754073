import { LatestTermsOfServiceVersion } from "common/compliance/TermsOfServiceVersion"
import { FC, useMemo } from "react"
import { Routes } from "src/Routes/Routes"
import { useCurrentUser } from "../../providers/currentUser/useCurrentUser"
import { CustomModalProps, Modal } from "../layout/Modal"
import { AcceptTermsOfService } from "./AcceptTermsOfService"
import {
  getUserTermsOfServiceStatus,
  hasUserSignedLatestTermsOfService,
} from "./termsOfServiceCheck"
import { useCurrentAccount } from "../../queries/currentUser/useCurrentAccount"
import Typography, { Weight } from "@stories/components/Typography/Typography"

const TermsOfUse = () => (
  <div className="space-y-2 terms-of-use">
    <Typography weight={Weight.Semibold} text="Terms of Use" />
    <p id="">
      PLEASE READ THESE TERMS OF USE (THIS “AGREEMENT” OR THESE “TERMS OF USE”) CAREFULLY. THESE
      TERMS OF USE ARE A LEGALLY BINDING AGREEMENT BETWEEN YOU AND CAPLIGHT MARKETS LLC
      (“CAPLIGHT”).
    </p>
    <p id="">
      THE TERM “YOU” MEANS YOU AND ANY ENTITY OR PERSON YOU MAY LAWFULLY REPRESENT OR ADVISE IN
      CONNECTION WITH THE USE OF THE SERVICE, AND “YOUR” HAS THE CORRELATED MEANING.
    </p>
    <p id="">
      THIS AGREEMENT SETS FORTH THE LEGALLY BINDING TERMS AND CONDITIONS FOR YOUR ACCESS AND USE OF
      ANY CAPLIGHT SERVICE OR TECHNOLOGY, INCLUDING BUT NOT LIMITED TO ALL DOMAINS AND SUBDOMAINS OF
      CAPLIGHT’S WEBSITE (THE “SITE”), CAPLIGHT’S ALTERNATIVE TRADING SYSTEM (“ATS”), CAPLIGHT’S
      PROPRIETARY SOFTWARE, CAPLIGHT’S COMPUTERS AND NETWORKS THAT ARE ACCESSIBLE EXTERNATILLY,
      OTHER COMPUTER TELEPHONIC OR WIRELESS SECURITIES (“APPLICATION”), TRADING SERVICES OR
      INFORMATION SYSTEM (“TRADING SERVICES”) CAPLIGHT PROVIDES TO YOU, INCLUDING SUCCESSOR TO THE
      SYSTEMS DESCRIBED ABOVE (ATS, APPLICACTION, TRADING SERVICES, COLLECTIVELY “ELECTRONIC
      SERVICES”), AND ALL OF THE ASSOCIATED SERVICES, FEATURES, CONTENT, WIDGETS, MATERIALS, AND
      OTHER TOOLS OFFERED BY CAPLIGHT OR THAT YOU DOWNLOAD FROM THE SITE OR FROM ANY THIRD PARTY
      APPLICATION STORES AUTHORIZED BY CAPLIGHT (BEING REFERRED TO IN THIS AGREEMENT, COLLECTIVELY
      WITH THE SITE AND ELECTRONIC SERVICES THE “SERVICE”).
    </p>
    <p id="">
      SUBJECT TO THESE TERMS OF USE, CAPLIGHT MAY OFFER TO PROVIDE THE SERVICE, AS DESCRIBED MORE
      FULLY ON THE SITE AND/OR THE APPLICATION, AND WHICH IS SELECTED BY YOU, SOLELY FOR YOUR OWN
      USE, AND NOT FOR THE USE OR BENEFIT OF ANY THIRD PARTY.
    </p>
    <p id="">
      BY REGISTERING FOR AND/OR USING THE SERVICE IN ANY MANNER, INCLUDING BUT NOT LIMITED TO
      DOWNLOADING, INSTALLING OR USING THE APPLICATION, AND VISITING OR BROWSING THE SITE, YOU AGREE
      TO BE LEGALLY BOUND BY THIS AGREEMENT. THIS AGREEMENT APPLIES TO ALL USERS OF THE SERVICE,
      INCLUDING USERS WHO ARE ALSO CONTRIBUTORS OF CONTENT, INFORMATION, AND OTHER MATERIALS OR
      SERVICES ON THE SITE OR APPLICATION.
    </p>
    <p id="">ACCEPTANCE OF TERMS; INCORPORATION OF RELATED TERMS</p>
    <p id="">
      The Service is offered subject to acceptance without modification of all of the terms and
      conditions contained in these Terms of Use, which terms also incorporate Caplight’s Privacy
      Policy, located <a href="https://www.caplight.com/legal/privacy-policy">here</a>, and all
      other operating rules, policies and procedures that may be published from time to time on the
      Site or the Application by Caplight, each of which is incorporated by reference and each of
      which may be updated by Caplight from time to time without notice to you. In addition, some
      services offered through the Service may be subject to additional terms and conditions
      promulgated by Caplight from time to time; your use of such services is subject to those
      additional terms and conditions, which are incorporated into these Terms of Use by this
      reference.
    </p>
    <p id="">
      When or if you register on the Site or the Application (as more fully explained below) you
      will also be prompted to agree to a Non-Disclosure Agreement between you and Caplight. To the
      extent that you accept and agree to any Non-Disclosure Agreement with Caplight, that
      Non-Disclosure Agreement is also incorporated herein by reference.
    </p>
    <p id="">
      If you do not acknowledge or agree with these Terms of Use, do not use or browse the Site.
      Continued use of the Services signifies your continued acceptance of these terms and any
      changes to them.
    </p>
    <p id="">RISKS OF ELECTRONIC TRADING</p>
    <p>
      Access to the Service may be limited or unavailable during systems upgrades or maintenance, or
      for other reasons. If the Service is unavailable or delayed at any time, you agree to use
      alternative means to place your orders, such as calling a Caplight representative. Caplight
      will not be liable to you if you are unable to access your account information or request a
      transaction through the Services. (See Limitations of Liability below)
    </p>
    <p>
      When you use the Service to place a trade order, you acknowledge that your order may not be
      reviewed by a registered representative, and you also will not have the opportunity to ask
      questions or otherwise interact with a Caplight representative. By placing a trade order
      through the Service, you voluntarily agree to assume any added risk that may result from the
      lack of human review of your order in exchange for potentially greater convenience of
      electronic trading.
    </p>
    <p>ELIGIBILITY</p>
    <p>
      The Service is available only to individuals who are at least 18 years old. You represent and
      warrant that if you are an individual, you are of legal age to form a binding contract, and
      that all registration information you submit is accurate and truthful. Caplight may, in its
      sole discretion, refuse to offer the Service to any person or entity and change its
      eligibility criteria at any time. This provision is void where prohibited by law and the right
      to access the Service is revoked in such jurisdictions.
    </p>
    <p>
      Portions of the Service are available only to “Accredited Investors” as that term is defined
      by the U.S. Securities and Exchange Commission, which may be found
      <a href="https://www.investor.gov/introduction-investing/general-resources/news-alerts/alerts-bulletins/investor-bulletins/updated-3">
        here.
      </a>
    </p>
    <p>MODIFICATION OF TERMS OF USE</p>
    <p>
      Caplight reserves the right, at its sole discretion, to modify or replace any of the Terms of
      Use, or change, suspend, or discontinue the Site, Electronic Services or any part of the
      Service (including without limitation, the availability of any feature, database, or content)
      at any time by posting a notice on the Site or by sending you an email. Caplight may also
      impose limits on certain features and services or restrict your access to parts or all of the
      Service without notice or liability. It is your responsibility to check the Terms of Use
      periodically for changes. Your continued use of the Site, Electronic Services or Service
      following the posting of any changes to the Terms of Use constitutes acceptance of those
      changes.
    </p>
    <p>REGISTRATION</p>
    <p>
      You may browse the Site and view Content on the Site without registering, but as a condition
      to using certain aspects of the Site you are required to register with Caplight and provide
      Caplight with accurate, truthful, and complete registration information (including, but not
      limited to your name (“User Name”), e-mail address and a password you will use to access the
      Site, Electronic Services and the Service). Information gathered through the registration
      process and information related to your account will be subject to these Terms of Use as well
      as to our Privacy Policy located{" "}
      <a href="https://www.caplight.com/legal/privacy-policy">here.</a>
    </p>
    <p>
      You represent and warrant that all information provided by you when registering is true,
      accurate, up-to-date and complete and that you will maintain, at all times, true, accurate,
      up-to-date, and complete information related to your registration, including any information
      or affirmation provided with respect to your status as an “Accredited Investor.” If your
      status as, or the basis on which you qualify as, an “Accredited Investor” changes after
      registration, you are required to notify Caplight at contact@caplight.com as soon as
      practicable. Failure to comply with any of the obligations in this paragraph shall constitute
      a breach of the Terms of Use, which may result in immediate termination of your Caplight
      account.
    </p>
    <p id="">You shall not:</p>
    <ul id="" className="list-disc">
      <li id="">
        provide any false personal information to Caplight (including a false User Name) or create
        any account for anyone other than yourself without permission;
      </li>
      <li id="">
        use a User Name that is the name of another person with the intent to impersonate that
        person;
      </li>
      <li id="">
        use a User Name that is a name subject to any rights of a person other than you without
        appropriate authorization; or
      </li>
      <li id="">use a User Name that is a name that is otherwise offensive, vulgar or obscene.</li>
    </ul>
    <p>
      Caplight reserves the right to refuse registration of or cancel a User Name in its sole
      discretion.
    </p>
    <p>
      You are solely responsible for all transactions and activities that occur on your account,
      whether undertaken by you or anyone or anything using the Service while using your User Name,
      whether authorized or unauthorized. You shall be responsible for maintaining security and
      confidentiality with respect to your Caplight password and information related to your
      registration. Caplight shall not be responsible for any losses arising from the financial loss
      or theft of your information due to unauthorized or fraudulent transactions using your
      account. You shall also never use another user’s account without such other user’s express
      permission. You will immediately notify Caplight in writing of any actual or suspected
      unauthorized use of your account, or other account related security breach of which you are
      aware.
    </p>
    <p>
      To the extent applicable, when utilizing the Site, Electronic Services or Service, you are
      required to use the security procedures currently or hereafter maintained by Caplight to
      confirm that only authorized users have access to the Service and any account.
    </p>
    <p>CONSENT TO ELECTRONIC COMMUNICATIONS</p>
    <p>
      You specifically agree to receive and/or obtain “Communications” from Caplight and its
      affiliates in “Electronic Form”, rather than in paper form, and to the use of electronic
      signatures in our relationship with you. The term “Communications” includes, but is not
      limited to, documents, agreements, terms, statements, data, records and any other
      communications regarding your relationship to Caplight and/or its affiliates, including Form
      CRS or any other notices, summaries, forms, disclosures or documents required by law, rule or
      regulation. The term “Electronic Form” includes, but is not limited to, electronic delivery of
      Communications posted or made available on the Site and/or through the Application, and/or
      sent via the e-mail address associated with the account through which you access the Caplight
      platform, or through which investment opportunities are accessed, or transactions are
      conducted, on your behalf.
    </p>
    <p>
      You accept Communications provided in Electronic Form as reasonable and proper notice, for the
      purpose of any and all laws, rules, and regulations, and agree that delivery of such
      Communications in Electronic Form fully satisfies any requirement that such Communications be
      provided to you in writing or in a form that you may keep. You represent that you have the
      appropriate personal computer or other electronic device, software, and internet connection to
      enable you to retain, if you so choose, any Communications that Caplight sends in Electronic
      Form for your records.
    </p>
    <p>
      If you have the legal right under any federal and/or state laws or regulations to receive a
      document in paper form, you may withdraw your consent to receiving that document in Electronic
      Form by contacting Caplight at legal@caplight.com. A withdrawal of your consent to receive
      such document in Electronic Form will be effective only after we have had reasonable time to
      process your request.
    </p>
    <p>BUSINESS CONTINUITY STATEMENTS</p>
    <p>
      SEC and FINRA Rules require investment advisers and broker-dealers to create and maintain a
      business continuity plan. In accordance with these rules, Caplight has developed a plan that
      is intended to permit us to continue critical business operations during natural disasters,
      power outages, financial insolvency or other significant events.
    </p>
    <p>
      While there can be no assurance that service will continue without interruption in all
      circumstances, the plans do address the actions that the firms will take in the event that
      there is a significant disruption. We maintain data backup records, in a cloud so that they
      would not be affected by a regional disruption or any unintentional data loss for other
      reasons.
    </p>
    <p>FINANCIAL INFORMATION.</p>
    <p>
      Caplight's Electronic Services make available certain financial market data, quotes, and news,
      or other financial information (collectively, "Information") that has been independently
      obtained by certain financial market information services; financial publishers; various
      securities markets, including stock exchanges and their affiliates; investment bankers and
      other providers (collectively, the "Information Providers") or has been obtained by Caplight.
      Caplight does not guarantee or certify the accuracy, completeness, timeliness or correct
      sequencing of the Information made available through Caplight, the Information Providers or
      any other third party transmitting the Information (the "Information Transmitters").{" "}
    </p>
    <p>
      You agree that neither Caplight, the Information Providers nor the Information Transmitters
      shall be liable in any way for the accuracy, completeness, timeliness or correct sequencing of
      the Information, or for any decision made or action taken by you relying upon the Information.
      You further agree that neither Caplight, the Information Providers nor the Information
      Transmitters will be liable in any way for the interruption of any data, Information or other
      aspect of the Service. You understand that none of the Information available through the
      Service constitutes a recommendation or solicitation that you should purchase or sell any
      particular security.
    </p>
    <p>SECURITIES PRODUCTS</p>
    <p>
      Any securities mentioned on the Site, Electronic Services or as part of the Service are
      suitable only for prospective investors who are familiar with and willing to accept the high
      risks associated with private investments, including the risk of complete loss of the
      investment. Securities sold through private placements are not publicly traded and, therefore,
      are illiquid unless and until registered with the U.S. Securities and Exchange Commission (the
      “SEC”), if at all. Securities will be subject to restrictions on resale and transfer,
      including holding period requirements. Investing in private placements requires high tolerance
      for risk, low need for liquidity, and willingness to make long-term commitments. Investors
      must be able to afford to lose their entire investment. Investment opportunities available on
      the Site or as part of the Service are not FDIC-insured, may lose value, and are not
      guaranteed by any bank or institution.
    </p>
    <p>
      Any securities mentioned have not been registered under the Securities Act, in reliance on
      exemptions thereto. Similar reliance has been placed on apparently available exemptions from
      securities registration or qualification requirements under applicable state securities laws.
      Caplight does not represent that any governmental agency has necessarily reviewed the Site,
      Electronic Services or Service or has passed upon either the adequacy of the disclosure
      contained therein or the fairness of the terms of any transaction available thereon.{" "}
    </p>
    <p>
      The exemptions relied upon for such transactions are significantly dependent upon the accuracy
      of representations made by users of Caplight, including you, as well as the issuers of the
      applicable securities, each as may be reflected in applicable transaction documents. In the
      event that any such representations prove to be untrue, the registration exemptions might not
      be available and substantial liability could result. These risks are non-exhaustive and are
      intended to highlight certain risks associated with investing in securities that are not
      registered with the SEC. WE STRONGLY ADVISE ALL PERSONS AND ENTITIES WHO ELECT TO PARTICIPATE
      IN TRANSACTIONS ON THE SITE, ELECTRONIC SERVICES OR IN CONNECTION WITH THE SERVICE TO CONSULT
      LEGAL, TAX, AND FINANCIAL PROFESSIONALS BEFOREHAND, CAREFULLY REVIEW ALL THE SPECIFIC RISK
      DISCLOSURES PROVIDED AS PART OF ANY TRANSACTION MATERIALS, AND REQUEST ANY ADDITIONAL
      INFORMATION.
    </p>
    <p>RULES AND CONDUCT</p>
    <p>
      As a condition of access and use, you promise not to access or use the Site, Electronic
      Services and the Service for any purpose that is prohibited by the Terms of Use. The Site,
      Electronic Services and the Service (including, without limitation, any associated Content (as
      defined below)) is provided only for your own personal, non-commercial use, unless commercial
      use has been explicitly approved by Caplight. You are responsible for all of your activity in
      connection with the Site, Electronic Services and the Service. For purposes of the Terms of
      Use, the term “Content” includes, without limitation, any videos, audio clips, written forum
      comments, barcodes, hyperlinks, information, data, text, photographs, software, scripts,
      graphics, and interactive features generated, provided, or otherwise made accessible by
      Caplight or its partners on or through the Site, Electronic Services or Service.
    </p>
    <p>
      By way of example, and not as a limitation, you shall not (and shall not permit any third
      party to) either (a) take any action or (b) upload, download, post, submit or otherwise
      distribute or facilitate distribution of any Content on or through the Site, Electronic
      Services or Service that:
    </p>
    <ul id="" className="list-disc">
      <li id="">
        infringes any patent, trademark, trade secret, copyright, right of publicity or other right
        of any other person or entity or violates any law or contractual duty;
      </li>
      <li id="">you know is false, misleading, untruthful or inaccurate;</li>
      <li id="">
        is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent,
        invasive of another’s privacy, tortious, obscene, vulgar, pornographic, offensive, profane,
        or otherwise inappropriate as determined by Caplight in its sole discretion;
      </li>
      <li id="">
        constitutes unauthorized or unsolicited advertising, junk or bulk e-mail (“spamming”);
      </li>
      <li id="">
        involves commercial activities and/or sales without Caplight’s prior written consent such as
        contests, sweepstakes, barter, advertising, or pyramid schemes;
      </li>
      <li id="">
        contains software viruses or any other computer codes, files, or programs that are designed
        or intended to disrupt, damage, limit or interfere with the proper function of any software,
        hardware, network or telecommunications equipment or to damage or obtain unauthorized access
        to any system, data, password or other information of Caplight or any third party;
      </li>
      <li id="">
        impersonates any person or entity, including any employee or representative of Caplight; or
      </li>
      <li id="">
        sends altered, deceptive or false source-identifying information, including “spoofing” or
        “phishing”.
      </li>
    </ul>
    <p>Additionally, you shall not (directly or indirectly):</p>
    <ul id="" className="list-disc">
      <li id="">
        take any action that imposes or may impose (as determined by Caplight in its sole
        discretion) an unreasonable or disproportionately large load on Caplight’s (or its third
        party providers’) infrastructure;
      </li>
      <li id="">
        interfere or attempt to interfere with the proper working of the Site, Electronic Services
        or Service or any activities conducted on the Site;
      </li>
      <li id="">
        bypass any measures Caplight may use to prevent or restrict access to the Site (or other
        accounts, computer systems or networks connected to the Site);
      </li>
      <li id="">run any form of auto-responder or “spam” on the Service;</li>
      <li id="">
        use manual or automated software, devices, or other processes to “crawl” or “spider” any
        component of the Site;
      </li>
      <li id="">
        decipher, decompile, disassemble, reverse engineer or otherwise attempt to derive any source
        code or underlying ideas or algorithms of any part of the Site, Electronic Services or
        Service, except to the limited extent applicable laws specifically prohibit such
        restriction;
      </li>
      <li id="">
        modify, translate, or otherwise create derivative works of any part of the Site, Electronic
        Services or Service, or
      </li>
      <li id="">
        copy, rent, lease, distribute, or otherwise transfer any of the rights that you receive
        hereunder.
      </li>
      <li id="">hack the Site, the Service, or any part thereof; or</li>
      <li id="">
        intercept, capture, emulate, or redirect the communications protocols used by us for any
        purpose, including without limitation causing the Site, or Service to connect to any
        computer server or other device not authorized by us.
      </li>
    </ul>
    <p>
      You shall abide by all applicable local, state, national and international laws and
      regulations in your use of the Service.
    </p>
    <p>
      To the extent that you have agreed to a Non-Disclosure Agreement between you and Caplight or
      to otherwise keep any information, data, documents, agreement, files or other materials
      confidential, you are subject to the terms of any such agreement, and are prohibited from
      uploading, posting, submitting or otherwise distributing or facilitating distribution of any
      such confidential material on or through the Site, Application, any third party social media
      sites, or any websites and applications.
    </p>
    <p>
      Caplight does not guarantee that any Content (as defined below) will be made available on the
      Site, or through the Service. Caplight has no obligation to monitor the Site, Service or
      Content. However, Caplight reserves the right to: (a) remove, edit or modify any Content in
      its sole discretion from the Site, Electronic Services or Service at any time, without notice
      to you and for any reason (including, but not limited to, upon receipt of claims or
      allegations from third parties or authorities relating to such Content or if Caplight is
      concerned that you may have violated the Terms of Use), or for no reason at all; and/or (b)
      suspend or terminate the right to use the Site, Electronic Services and/or the Service
      pursuant to this Agreement
    </p>
    <p>THIRD PARTY SITES AND CONTENT</p>
    <p>
      The Site may permit you to link to other websites, services or resources on the Internet, and
      other websites, services or resources may contain links to the Site. These other websites,
      services and resources (“Third Party Sites”) are not under Caplight’s control, and you
      acknowledge that Caplight is not responsible or liable for any content, information, data,
      advertising, products, goods or services available on or through any such Third Party Sites
      (“Third Party Content”), as well as for any of the functions, accuracy, legality,
      appropriateness or any other aspect of such Third Party Sites. The inclusion of any such link
      does not imply endorsement by Caplight or any association with its operators. You further
      acknowledge and agree that Caplight shall not be responsible or liable, directly or
      indirectly, for any damage or loss caused or alleged to be caused by or in connection with the
      use of or reliance on any such Third Party Sites or Third Party Content.
    </p>
    <p>
      You agree that your access and/or use of Third Party Sites, including but not limited Third
      Party Content and any other materials on or available through Third Party Sites is at your own
      risk and subject to the terms and conditions of use and privacy policies applicable to such
      Third Party Sites.
    </p>
    <p>CONTENT AND LICENSE</p>
    <p>
      You acknowledge and agree that any and all intellectual property rights (the “IP Rights”) on
      the Site, in the Service and in the Application are and shall remain the exclusive property of
      Caplight. Nothing in this Agreement intends to or shall transfer any IP Rights to, or to vest
      any IP Rights in, you. You are only entitled to the limited use of the rights granted to you
      in this Agreement. You will not take any action to jeopardize, limit or interfere with the IP
      Rights. You acknowledge and agree that any unauthorized use of the IP Rights is a violation of
      this Agreement, as well as a violation of applicable intellectual property laws.
    </p>
    <p>
      You acknowledge and understand that all title and rights in and to any Third Party Content
      that is not contained on the Site, in the Service, or in the Application, but may be accessed
      through the Site, Service, or the Application is the property of the respective content owners
      and may be protected by applicable patent, copyright, or other intellectual property laws and
      treaties. You agree not to sell, assign, rent, lease, distribute, export, import, act as an
      intermediary or provider, or otherwise grant rights to third parties with regard to the Site,
      Electronic Services or Service or any part thereof without our prior written consent.
    </p>
    <p>
      You grant to us a nonexclusive, perpetual, non-revocable, royalty free license to use, retain,
      and share any information transmitted through the Site and/or the Application by you,
      including but not limited to account numbers, name, date, account amount, and endorsements for
      the purpose of providing the Service. This license shall survive termination of this Agreement
      for such period as necessary for us to provide the Service, comply with the law, or comply
      with an internal guidelines or procedures.
    </p>
    <p>
      Caplight grants each user of the Site, Electronic Services and/or Service a worldwide,
      non-exclusive, non-sublicensable and non-transferable limited license to use and reproduce the
      Content, solely for personal, non-commercial use, unless commercial use has been explicitly
      approved by Caplight. Use, reproduction, modification, distribution or storage of any Content
      for other than personal, non-commercial use is expressly prohibited without prior written
      permission from Caplight, or from the copyright holder identified in such Content’s copyright
      notice. You shall not sell, license, rent, or otherwise use or exploit any Content for
      commercial use or in any way that violates any third-party right.
    </p>
    <p>YOUR INFORMATION</p>
    <p>
      The collection, use and disclosure, if any, of information collected from you or about you by
      Caplight on the Site and/or the Application is detailed in Caplight’s Privacy Policy that can
      be found <a href="https://www.caplight.com/legal/privacy-policy">here</a>. By acknowledging
      and agreeing to this Agreement, or by using the Site, Electronic Services or Services, you
      consent to Caplight, including its subsidiaries, affiliates, agents, third-party partners and
      service providers, receiving, collecting, storing, processing, transmitting, and using your
      information for Services functionality and for the purposes disclosed in Caplight’s Privacy
      Policy.
    </p>
    <p>CHANGES TO SERVICE</p>
    <p>
      We reserve the right to add or delete features or functions, or to provide programming fixes,
      updates and upgrades, to the Site, Electronic Services or Service. You acknowledge and agree
      that we have no obligation to make available to you any subsequent versions of the Site or the
      Application. You also agree that you may have to enter into a renewed version of this
      Agreement if you want to download, install or use a new version of the Site, Electronic
      Services or Service. We have no obligation whatsoever to furnish any maintenance and support
      services with respect to the Site, Electronic Services or Service, and any such maintenance
      and support services provided will be provided at our discretion.
    </p>
    <p>TERMINATION</p>
    <p>
      Caplight may terminate your access to all or any part of the Site, Electronic Services or
      Service at any time, with or without cause, with or without notice, effective immediately,
      which may result in the forfeiture and destruction of all information associated with your
      membership. If you wish to terminate your account, you may do so by following the instructions
      on the Site or the Application. Any fees paid hereunder are non-refundable. All provisions of
      the Terms of Use which by their nature should survive termination shall survive termination,
      including, without limitation, ownership provisions, warranty disclaimers, indemnity and
      limitations of liability.
    </p>
    <p>WARRANTY DISCLAIMER</p>
    <p>
      By virtue of your accessing the Service, Caplight has no special relationship or fiduciary
      duty with you. Any relationship with Caplight’s affiliates will be governed by your written
      agreement with that affiliate. You acknowledge that Caplight has no control over, and no duty
      to take any action regarding: which users gains access to the Site; what Content you access
      via the Site ; what effects the Content may have on you; how you may interpret or use the
      Content; or what actions you may take as a result of having been exposed to the Content. You
      release Caplight from all liability for you having acquired or not acquired Content through
      the Site. The Site may contain, or direct you to websites containing, information that some
      people may find offensive or inappropriate. Caplight makes no representations concerning any
      Content contained in or accessed through the Site and/or Application, and Caplight will not be
      responsible or liable for the accuracy, copyright compliance, legality or decency of material
      contained in or accessed through the Site, Electronic Services or Service.
    </p>
    <p>
      THE SITE, APPLICATION AND THE SERVICE ARE PROVIDED “AS IS” AND “AS AVAILABLE” AND IS WITHOUT
      WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
      WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE,
      AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE
      EXPRESSLY DISCLAIMED. CAPLIGHT, AND ITS DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS, PARTNERS AND
      CONTENT PROVIDERS DO NOT WARRANT THAT: (A) THE SITE, ELECTRONIC SERVICES OR SERVICE WILL BE
      SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (B) ANY DEFECTS OR ERRORS WILL BE
      CORRECTED; (C) ANY CONTENT OR SOFTWARE AVAILABLE AT OR THROUGH THE SITE, ELECTRONIC SERVICES
      OR SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR (D) THE RESULTS OF USING THE
      SITE, ELECTRONIC SERVICES OR SERVICE WILL MEET YOUR REQUIREMENTS. YOUR USE OF THE SERVICE IS
      SOLELY AT YOUR OWN RISK.
    </p>
    <p>
      SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE
      LIMITATIONS MAY NOT APPLY TO YOU.
    </p>
    <p>
      Electronic Communications Privacy Act Notice (18USC 2701-2711): CAPLIGHT MAKES NO GUARANTY OF
      CONFIDENTIALITY OR PRIVACY OF ANY COMMUNICATION OR INFORMATION TRANSMITTED ON THE SITE,
      APPLICATION OR ANY WEBSITE LINKED TO THE SITE OR APPLICATION. Caplight will not be liable for
      the privacy of email addresses, registration and identification information, disk space,
      communications, confidential or trade-secret information, or any other Content stored on
      Caplight’s equipment, transmitted over networks accessed by the Site and Application or
      otherwise connected with your use of the Service.
    </p>
    <p>INDEMNIFICATION</p>
    <p>
      You shall defend, indemnify, and hold harmless Caplight, its affiliates and each of its, and
      its affiliates employees, contractors, directors, suppliers and representatives from all
      liabilities, claims, and expenses, including reasonable attorneys’ fees, that arise from or
      relate to your use or misuse of, or access to, the Site, Electronic Services, Service,
      Content, violation of the Terms of Use, or infringement by you, or any third party using your
      account, of any intellectual property or other right of any person or entity. Caplight
      reserves the right to assume the exclusive defense and control of any matter otherwise subject
      to indemnification by you, in which event you will assist and cooperate with Caplight in
      asserting any available defenses.
    </p>
    <p>LIMITATION OF LIABILITY</p>
    <p>
      IN NO EVENT SHALL CAPLIGHT, OR ANY OF ITS DIRECTORS, EMPLOYEES, AGENTS, PARTNERS, SUPPLIERS OR
      CONTENT PROVIDERS, BE LIABLE UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR ANY OTHER
      LEGAL OR EQUITABLE THEORY WITH RESPECT TO THE SITE, ELECTRONIC SERVICES OR SERVICE (I) FOR ANY
      LOST PROFITS, DATA LOSS, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR SPECIAL,
      INDIRECT, INCIDENTAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER,
      SUBSTITUTE GOODS OR SERVICES (HOWEVER ARISING), (II) FOR ANY BUGS, VIRUSES, TROJAN HORSES, OR
      THE LIKE (REGARDLESS OF THE SOURCE OF ORIGINATION), (III) FOR ANY DIRECT DAMAGES IN EXCESS OF
      (IN THE AGGREGATE) ONE-HUNDRED U.S. DOLLARS ($100.00). SOME STATES DO NOT ALLOW THE EXCLUSION
      OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS AND EXCLUSIONS
      MAY NOT APPLY TO YOU. IF APPLICABLE LAW DOES NOT ALLOW ALL OR ANY PART OF THE ABOVE LIMITATION
      OF LIABILITY TO APPLY TO YOU, THE LIMITATIONS WILL APPLY TO YOU ONLY TO THE EXTENT PERMITTED
      BY APPLICABLE LAW.
    </p>
    <p>
      CAPLIGHT WILL NOT BE RESPONSIBLE FOR ANY DAMAGES, LOSSES OR LIABILITY TO YOU OR ANYONE ELSE IF
      ANY EVENT LEADING TO SUCH DAMAGES, LOSSES OR LIABILITY WOULD HAVE BEEN PREVENTED BY THE USE OF
      THEN AVAILABLE SECURITY FEATURES AND/OR PROCEDURES MAINTAINED BY CAPLIGHT TO CONFIRM THAT ONLY
      AUTHORIZED USERS HAVE ACCESS TO THE SERVICE AND/OR ANY ACCOUNT. ADDITIONALLY, YOU ARE
      RESPONSIBLE FOR ALL LOGIN CREDENTIALS, INCLUDING USERNAMES AND PASSWORDS. WE WILL NOT BE
      RESPONSIBLE FOR ANY DAMAGES, LOSSES OR LIABILITY TO YOU, OR ANYONE ELSE, IF SUCH INFORMATION
      IS NOT KEPT CONFIDENTIAL BY YOU, OR IF SUCH INFORMATION IS CORRECTLY PROVIDED BY AN
      UNAUTHORIZED THIRD-PARTY LOGGING INTO AND ACCESSING THE SITE, ELECTRONIC SERVICES OR SERVICE.
    </p>
    <p>DISPUTE RESOLUTION</p>
    <p>
      Most concerns can be resolved by contacting Caplight at legal @caplight.com through
      consultation with Caplight, and good faith negotiations shall be a condition to either of us
      initiating any other process or proceeding.
    </p>
    <p>
      A printed version of the Terms of Use and of any notice given in electronic form shall be
      admissible in judicial or administrative proceedings based upon or relating to the Terms of
      Use to the same extent and subject to the same conditions as other business documents and
      records originally generated and maintained in printed form. You and Caplight agree that any
      cause of action arising out of or related to the Service must commence within one (1) year
      after the cause of action arose; otherwise, such cause of action is permanently barred.
    </p>
    <p>
      Choice of Law. The Terms of Use shall be governed by and construed in accordance with the laws
      of the State of California, excluding its conflicts of law rules, and the United States of
      America.
    </p>
    <p>
      Arbitration. The parties acknowledge that this Agreement evidences a transaction involving
      interstate commerce. Notwithstanding the provision with respect to choice of law, any
      arbitration conducted pursuant to the terms of this Agreement shall be governed by the Federal
      Arbitration Act.
    </p>
    <p>
      To the fullest extent permissible by law, except as provided under certain limited
      circumstances below, any dispute, controversy or claim arising from or relating to the subject
      matter of this Agreement, including whether the claims asserted are arbitrable, shall be
      finally determined and settled by arbitration in San Francisco, California. The arbitration
      shall be administered by JAMS, using the English language, in accordance with Streamlined
      Arbitration Rules and Procedures of JAMS then in effect, by one commercial arbitrator with
      substantial experience in resolving intellectual property, internet and commercial contract
      disputes, who shall be selected from the appropriate list of JAMS arbitrators in accordance
      with such Rules. JAMS may be contacted at http://www.jamsadr.com/.
    </p>
    <p>
      The prevailing party in any arbitration or other proceeding arising under these Terms of Use
      shall be entitled to receive reimbursement of its reasonable expenses (including reasonable
      attorneys’ fees, expert witness fees and all other expenses) incurred in connection therewith.
    </p>
    <p>
      Judgment upon the award so rendered may be entered in a court having jurisdiction or
      application may be made to such court for judicial acceptance of any award and an order of
      enforcement, as the case may be.
    </p>
    <p>
      Exceptions. Notwithstanding the foregoing, each party shall have the right to (a) institute an
      action in a court of proper jurisdiction for injunctive or other equitable relief; (b) bring
      an individual action in small claims court; (c) pursue an enforcement action through the
      applicable federal, state, or local agency if that action is available; and (d) file suit in a
      court of law to address an intellectual property infringement claim.
    </p>
    <p>
      Enforceability and Jurisdiction. Without waiving any of the foregoing, if any asserted claims
      are deemed not arbitrable, the parties consent to exclusive jurisdiction and venue in the
      state and federal courts located in the City and County of San Francisco.
    </p>
    <p>INTEGRATION AND SEVERABILITY</p>
    <p>
      The Terms of Use are the entire agreement between you and Caplight with respect to the Site,
      Electronic Services or Service and use of the Site and supersede all prior or contemporaneous
      communications and proposals (whether oral, written or electronic) between you and Caplight
      with respect to the Site . If any provision of the Terms of Use is found to be unenforceable
      or invalid, that provision will be limited or eliminated to the minimum extent necessary so
      that the Terms of Use will otherwise remain in full force and effect and enforceable. The
      failure of either party to exercise in any respect any right provided for herein shall not be
      deemed a waiver of any further rights hereunder.
    </p>
    <p>MISCELLANEOUS</p>
    <p>
      Caplight shall not be liable for any failure to perform its obligations hereunder where such
      failure results from any cause beyond Caplight’s reasonable control, including, without
      limitation, mechanical, electronic or communications failure or degradation (including
      “line-noise” interference). The Terms of Use are personal to you, and are not assignable,
      transferable or sublicensable by you except with Caplight’s prior written consent. Caplight
      may assign, transfer or delegate any of its rights and obligations hereunder without consent.
      No agency, partnership, joint venture, or employment relationship is created as a result of
      the Terms of Use and neither party has any authority of any kind to bind the other in any
      respect. All notices under the Terms of Use will be in writing and will be deemed to have been
      duly given when received, if personally delivered or sent by certified or registered mail,
      return receipt requested; when receipt is electronically confirmed by Caplight, if transmitted
      to Caplight by facsimile or e-mail or upon receipt if transmitted to you; or the day after it
      is sent, if sent for next day delivery by recognized overnight delivery service.
    </p>
    <p>ELECTRONIC FUND TRANSFERS</p>
    <p>Electronic Fund Transfer Disclosure Statement</p>
    <p>Caplight does not hold, receive or send out any client funds.</p>
    <p>CHANGES</p>
    <p>
      Caplight reserves the right and sole discretion to modify these Terms of Use, the Privacy
      Policy, and any notices, rules, policies, and procedures that may be published on the Site,
      from time to time, and any such modifications become effective immediately upon your
      opportunity to view them after a modified version is posted to the Site. It is your sole
      responsibility to check the Site to view any such changes. If you do not agree to all of the
      changes, you must cease use of the Site; any usage by you of the Services after any
      modifications are made indicates acceptance of the modified Terms or other provisions.
      Notwithstanding and without limiting the foregoing, Caplight may provide a notice to you of
      any such revision, and require you to indicate acceptance of any new version in order to
      continue to use the Site, Electronic Services and/or the Service.
    </p>
    <p>CONTACT</p>
    <p>If you have any questions, please email us at legal@caplight.com.</p>

    <p id="">
      Caplight Markets LLC (“Caplight Markets”) is a subsidiary of Caplight Technologies, Inc.
      Caplight Markets is a broker/dealer registered with the Securities Exchange Commission and is
      a{" "}
      <a href="http://www.finra.org/" id="" target="_blank" rel="noreferrer">
        FINRA
      </a>
      /
      <a href="https://www.sipc.org/" id="" target="_blank" rel="noreferrer">
        SIPC
      </a>{" "}
      member firm.
    </p>
    <p id="">
      Equity securities are offered through Caplight Markets LLC. Check the background of this firm
      on{" "}
      <a
        href="https://brokercheck.finra.org/firm/summary/281820"
        id=""
        target="_blank"
        rel="noreferrer"
      >
        FINRA’s BrokerCheck
      </a>
      .
    </p>
    <p id="">
      Caplight.com is a website operated by Caplight Technologies, Inc. ("Caplight"). By accessing
      this site and any pages thereof, you agree to be bound by our Terms of Use.
      <br />
      Caplight and logo are trademarks of Caplight Technologies, Inc. Other trademarks are property
      of their respective owners.
      <br />
      <div className="mt-2">© 2024 Caplight Technologies, Inc. All rights reserved.</div>
    </p>
  </div>
)

interface TermsOfServiceModalProps extends CustomModalProps {
  onSignComplete?: () => Promise<void>
}

const useTermsOfService = () => {
  const user = useCurrentUser()
  const account = useCurrentAccount()

  return useMemo(
    () => ({
      userTermsOfServiceStatus: getUserTermsOfServiceStatus(user, account),
      isLatestTermsVersionAccepted: hasUserSignedLatestTermsOfService(user, account),
    }),
    [user, account]
  )
}

export const TermsOfServiceHeader = () => {
  const { isLatestTermsVersionAccepted, userTermsOfServiceStatus } = useTermsOfService()

  return (
    <div>
      <span className="text-neutral-800 text-xs mb-2 font-normal ml-4">
        Last updated {LatestTermsOfServiceVersion}
      </span>
      <div className="text-xs text-neutral-800 my-2 font-normal">
        You can access a full-page version of the Terms of Use{" "}
        <a
          href={Routes.external.marketingSite.termsOfUse}
          target="_blank"
          className="text-accent-500 font-medium cursor-pointer"
          rel="noreferrer"
        >
          here
        </a>
        .
      </div>
      <div className="text-sm flex flex-col gap-2">
        {userTermsOfServiceStatus === "accepted-previous-terms" && (
          <Typography text="We've updated our Terms of Use" weight={Weight.Semibold} />
        )}
        {!isLatestTermsVersionAccepted && (
          <Typography text="Please review and agree to following" weight={Weight.Semibold} />
        )}
      </div>
    </div>
  )
}

export const TermsOfServiceBody = ({
  onSignComplete,
}: Pick<TermsOfServiceModalProps, "onSignComplete">) => {
  const onSubmit = async () => onSignComplete?.()
  return (
    <>
      <div
        className="relative h-1/2-screen w-full overflow-y-auto text-sm"
        data-test-id="termsOfServiceModalBody"
      >
        <TermsOfUse />
        <div className="sticky -bottom-px left-0 right-0 h-20 bg-gradient-to-t from-neutral-100" />
      </div>
      <div className="flex flex-col w-full justify-center items-center mt-4">
        <AcceptTermsOfService
          className="space-y-4 h-30"
          onAcceptanceSubmission={onSubmit}
          disabled={false}
        />
      </div>
    </>
  )
}

/**
 * Renders a modal with T&Cs.
 * If the user has not signed it yet, it becomes impossible to close the modal
 * (i.e. it becomes blocking).
 * If a user is still signing up and a user object has not been created yet in the firebase,
 * they can close the modal and return to it later.
 */
export const TermsOfServiceModal: FC<React.PropsWithChildren<TermsOfServiceModalProps>> = ({
  onSignComplete = () => Promise.resolve(),
  ...props
}) => {
  const user = useCurrentUser()
  const { isLatestTermsVersionAccepted } = useTermsOfService()
  return (
    <Modal
      subtitle="Terms of Use"
      body={
        <div className="flex flex-col gap-4">
          <TermsOfServiceHeader />
          <TermsOfServiceBody onSignComplete={onSignComplete} />
        </div>
      }
      maskClosable={isLatestTermsVersionAccepted || !user || user === "loading"}
      closable={isLatestTermsVersionAccepted || !user || user === "loading"}
      modalClassName="2xl:w-1/3-screen sm:w-3/4-screen md:w-1/2-screen max-w-1/2-screen"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
}
