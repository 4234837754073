/* eslint-disable max-classes-per-file */
export class Identity<T> {
  private constructor(readonly value: T) {}

  map<S>(f: (t: T) => S): Identity<S> {
    return new Identity(f(this.value))
  }

  bind<S>(f: (t: T) => Identity<S>): Identity<S> {
    return f(this.value)
  }

  static pure<S>(s: S) {
    return new Identity(s)
  }
}
