import { Interval } from "../../utils/data/Interval"
import { AccountIdFields } from "../Account"
import { SubmissionStatus } from "../data-product/SubmissionStatus"
import { UserEvent } from "../UserEvent"

export const dataContributionDocumentTypes = ["data-submission", "order-submission"] as const
export type DataContributionDocumentType = (typeof dataContributionDocumentTypes)[number]
export type NonContributionPeriod = Interval<Date>

export const documentTypes = [
  ...dataContributionDocumentTypes,
  "portfolio-uploads",
  "crm-contact-upload",
  "verification-docs",
  "deal-distribution-email-docs",
  "deal-distribution-private-data-room-docs",
  "deal-distribution-public-data-room-docs",
] as const
export type DocumentType = (typeof documentTypes)[number]
type PlatformSubmission = "platform"
type NonPlatformSubmission = "email" | "other" | "api"
export type SubmissionSource = PlatformSubmission | NonPlatformSubmission

export type SubmissionSourceInformation =
  | {
      source: PlatformSubmission
      rawFilePath: string
      comment: string | null
      tag: "platform"
    }
  | {
      source: NonPlatformSubmission
      rawFilePath: null
      comment: string
      tag: "non-platform"
    }
export type SubmittedDocument = {
  documentDate?: Date
  filePath?: string
  documentType: DocumentType
  submissionSourceInformation?: SubmissionSourceInformation
}

export type DocumentSubmission = Omit<UserEvent, "account"> & {
  document: SubmittedDocument
  status: SubmissionStatus
  eventType: "document-submission"
  account: AccountIdFields
}
