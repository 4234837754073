import { getCompanyValuation } from "src/data/Hooks/useCompanyValuation"
import InnerOrderMarketPriceComparison from "../OrderPage/OrderMarketPriceComparison/InnerOrderMarketPriceComparison"
import { useOrderForm } from "./OrderFormContext"
import { isLoaded } from "common/utils/Loading"

export const OrderFormMarketPriceComparison = () => {
  const { formOrder, company } = useOrderForm()
  if (!isLoaded(company)) {
    return null
  }
  const { valuationFromPPS } = getCompanyValuation(company)

  const numShares = valuationFromPPS({
    price: 1,
    priceDate: new Date(),
    isSplitAdjusted: false,
  }).match(
    (x) => x.adjustedShareCount,
    () => undefined
  )

  const valuationPPS =
    numShares && formOrder.price?.targetValuation
      ? formOrder.price.targetValuation / numShares
      : undefined

  return company && formOrder.direction ? (
    <InnerOrderMarketPriceComparison
      hiddenLinkToPriceChart
      company={company}
      orderDirection={formOrder.direction}
      orderPPS={formOrder.price?.USDPerShare?.lowerBound ?? valuationPPS}
      estimatePPS={!!valuationPPS}
      labels={{
        pricePoint: (_direction) => "Your Price",
        marketPricePoint: () => "Current MarketPrice",
      }}
    />
  ) : null
}
