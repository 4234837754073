export const globalMinimumSizeFilterSettings = [0, 200000, 500000, 1000000] as const

export type GlobalMinimumSizeFilterSetting = (typeof globalMinimumSizeFilterSettings)[number]

export type AccountGlobalSizeFilterFields = {
  globalMinimumSizeFilterSetting?: GlobalMinimumSizeFilterSetting
}

export const DEFAULT_ACCOUNT_GLOBAL_SIZE_FILTER_MINIMUM =
  1000000 satisfies GlobalMinimumSizeFilterSetting
