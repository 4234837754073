import { sizeToTextSize } from "@model/components/lib/shared"
import { Button } from "@stories/components/Button/Button"
import { DealCRMPriority } from "common/model/DealCRM/Deal/DealCRMDeal"
import capitalize from "lodash/capitalize"
import { FC, ReactNode, useEffect, useMemo, useState } from "react"
import { Select, Tooltip } from "../../../../stories/components/Antd"
import { PriorityPill } from "./PriorityPill"
import { PriorityOptions } from "./priorityOptions"

interface PriorityDisplayProps {
  onChange: (newValue: DealCRMPriority) => Promise<void>
  onClick?: (e: React.MouseEvent) => void
  priority: DealCRMPriority
  editable: boolean
  noBorder?: boolean
  size?: "md" | "sm" | "xs"
  includeUrgent?: boolean
}

const PriorityDisplayWrapper = ({ children }: { children: ReactNode }) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  <div
    className="flex justify-end h-5 w-4 overflow-hidden"
    onClick={(e) => {
      e.stopPropagation()
    }}
  >
    {children}
  </div>
)

export const PriorityDisplay: FC<PriorityDisplayProps> = ({
  onChange,
  onClick,
  priority,
  editable,
  noBorder = false,
  size = "sm",
  includeUrgent = false,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const options = useMemo(
    () => PriorityOptions({ includeUrgent, size, iconOnlyMenu: true }),
    [includeUrgent, size]
  )

  useEffect(() => {
    // Close dropdown when clicking outside the component or with Esc
    const closeDropdown = () => {
      setIsDropdownOpen(false)
    }
    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setIsDropdownOpen(false)
      }
    }
    if (!isDropdownOpen) {
      document.removeEventListener("click", closeDropdown)
      return () => {}
    } else {
      document.addEventListener("keydown", handleEsc)
      document.addEventListener("click", closeDropdown)
      return () => {
        document.removeEventListener("keydown", handleEsc)
        document.removeEventListener("click", closeDropdown)
      }
    }
  }, [isDropdownOpen, setIsDropdownOpen])

  const handleIconClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation()
    setIsDropdownOpen(!isDropdownOpen)
  }

  if (!editable || !isDropdownOpen) {
    return (
      <PriorityDisplayWrapper>
        <Tooltip title={`Priority: ${capitalize(priority)}`}>
          <Button
            leftIcon={<PriorityPill priority={priority} />}
            variant="icon"
            onClick={handleIconClick}
            size="none"
          />
        </Tooltip>
      </PriorityDisplayWrapper>
    )
  }

  const handleChange = async (newPriority: DealCRMPriority) => {
    setIsDropdownOpen(false)
    await onChange(newPriority)
  }

  const onSelect = (value: DealCRMPriority) => {
    if (value === priority) {
      setIsDropdownOpen(false)
    }
  }

  return (
    <PriorityDisplayWrapper>
      <Select
        className={`${sizeToTextSize[size]} icon-dropdown-select`}
        dropdownStyle={{ minWidth: "120px" }}
        popupClassName="!w-32"
        value={priority}
        bordered={!noBorder}
        style={{
          height: 20,
          border: noBorder ? "none" : undefined,
          marginTop: "-7px",
          marginRight: "-18px",
        }}
        options={options}
        onChange={handleChange}
        onSelect={onSelect}
        onClick={onClick}
        suffixIcon={null}
        open={isDropdownOpen}
      />
    </PriorityDisplayWrapper>
  )
}
