import CompanyAutocomplete from "@components/companies/select/CompanyAutocomplete"
import { Button } from "@stories/components/Button/Button"
import Typography, { Size, Weight } from "@stories/components/Typography/Typography"
import { CrossIcon } from "@stories/icons/CrossIcon"
import { AccountIdFields, viewAccountIdFields } from "common/model/Account"
import { SerializedBooleanFormula } from "common/containers/Trees/BooleanFormula"
import { CompanyIdFields, SelectedCompany, viewCompanyIdFields } from "common/model/Company"
import { ConstructedOpportunityInboxFilterRule } from "common/model/Order/OrderFilter/OpportunityInboxFilterRule"
import { OrderDirection } from "common/model/Order/Order"
import {
  AtomicOrderFilter,
  OrderNumericField,
  SerializedOrderFilterFormula,
} from "common/model/Order/OrderFilter/OrderFilter"
import moment from "moment"
import { Field, Form } from "react-final-form"
import { handleConsoleError } from "src/utils/Tracking"
import {
  AtomicOrderOpportunityRuleForm,
  validateAtomicOrderFilter,
} from "./AtomicOrderOpportunityRuleForm"
import Select from "react-select"
import { useEffect, useState } from "react"
import { Switch } from "@stories/components/Antd/Switch/Switch"
import { Just, Maybe, Nothing } from "common/containers/Maybe"

export type BuildCompanyOrderRuleProps = {
  account: AccountIdFields
  rule: {
    acceptMissingValues?: boolean
    expiresAt?: Date | null
    company: CompanyIdFields
    direction?: OrderDirection
    atom?: AtomicOrderFilter &
      ({ tag: OrderNumericField } | { tag: "structure" } | { tag: "shareClass" })
  }
}
export const buildCompanyOrderRule = ({
  account,
  rule,
}: BuildCompanyOrderRuleProps): ConstructedOpportunityInboxFilterRule & { tag: "company" } => {
  const { atom } = rule

  const companyClause: Maybe<SerializedBooleanFormula<AtomicOrderFilter>> = Just({
    tag: "atom",
    value: { tag: "company", companyId: rule.company.id },
  })
  const directionClause: Maybe<SerializedBooleanFormula<AtomicOrderFilter>> = rule.direction
    ? Just({
        tag: "atom",
        value: { tag: "direction", direction: rule.direction },
      })
    : Nothing

  const structureClause: Maybe<SerializedBooleanFormula<AtomicOrderFilter>> =
    atom && atom.tag === "structure"
      ? Just({ tag: atom.tag, orderStructure: atom.orderStructure }).map((v) => ({
          tag: "atom",
          value: v,
        }))
      : Nothing

  const shareClassClause: Maybe<SerializedBooleanFormula<AtomicOrderFilter>> =
    atom && atom.tag === "shareClass"
      ? Just({ tag: atom.tag, shareClass: atom.shareClass }).map((v) => ({ tag: "atom", value: v }))
      : Nothing

  const fieldValueClause: Maybe<SerializedBooleanFormula<AtomicOrderFilter>> =
    atom && atom.tag !== "shareClass" && atom.tag !== "structure"
      ? Just({
          tag: atom.tag,
          comparison:
            rule.direction === "sell" ? "geq" : rule.direction === "buy" ? "lt" : atom.comparison,
          limit: atom.limit,
        }).map((v) => ({ tag: "atom", value: v }))
      : Nothing

  const missingValueClause =
    rule.atom && rule.acceptMissingValues && rule.atom.tag === "volume"
      ? Just({
          tag: "not",
          value: {
            tag: "atom",
            value: {
              tag: "field-exists",
              field: "volume",
            },
          },
        } satisfies SerializedBooleanFormula<AtomicOrderFilter>)
      : Nothing

  const formula: SerializedOrderFilterFormula = {
    tag: "and",
    value: [
      ...companyClause.toArray(),
      ...directionClause.toArray(),
      ...structureClause.toArray(),
      ...shareClassClause.toArray(),
      ...fieldValueClause.toArray(),
      ...missingValueClause.toArray(),
    ],
  }
  return {
    tag: "company",
    company: viewCompanyIdFields(rule.company),
    formula,
    account: viewAccountIdFields(account),
    expiresAt: rule.expiresAt ?? null,
    createdAt: new Date(),
    active: true,
  }
}

const validateForm = (values: Partial<BuildCompanyOrderRuleProps["rule"]>) => {
  if (!values.company) return { company: "requires company" }
  // if (!values.direction) return { company: "requires direction" }
  if (!values.atom) return { atom: "requires atom" }
  if (values.atom.tag === "price" && !values.expiresAt)
    return { expiresAt: "price requires expires at" }
  return validateAtomicOrderFilter(values.atom, values.direction)
}

const DateOptions = [
  { value: moment().add(2, "weeks").toDate(), label: "Two Weeks" },
  { value: moment().add(1, "month").toDate(), label: "One Month" },
  { value: moment().add(3, "months").toDate(), label: "Three Months" },
]

const COMPANY_RULE_FORM_BUTTON_ID = "CompanyOrderOpportunityRuleForm-buttonId"
export const CompanyOrderOpportunityRuleForm = ({
  onSubmitForm,
  account,
  initialValues,
  closeForm,
  alwaysRunRule,
}: {
  onSubmitForm: (
    v: ConstructedOpportunityInboxFilterRule & { tag: "company" },
    runRule: boolean
  ) => Promise<void>
  account: AccountIdFields
  initialValues?: Partial<BuildCompanyOrderRuleProps["rule"]>
  closeForm?: () => void
  alwaysRunRule?: boolean
}) => {
  const [runRule, setRunRule] = useState(false)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    document
      .getElementById(COMPANY_RULE_FORM_BUTTON_ID)
      ?.scrollIntoView({ behavior: "smooth", block: "end" })
  }, [])
  return (
    <Form<BuildCompanyOrderRuleProps["rule"]>
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={(rule, form) => {
        setLoading(true)
        onSubmitForm(buildCompanyOrderRule({ rule, account }), alwaysRunRule || runRule).finally(
          () => {
            setLoading(false)
            closeForm?.()
            form.reset()
          }
        )
      }}
      render={({ handleSubmit, valid, values }) => (
        <form onSubmit={handleSubmit} className="flex flex-col gap-4 h-full">
          <div className="flex items-center justify-between">
            <Typography text="I want to hide" weight={Weight.Semibold} />
            {closeForm ? (
              <Button leftIcon={<CrossIcon />} onClick={closeForm} variant="secondary" />
            ) : null}
          </div>
          <Field<SelectedCompany> name="company">
            {({ input: { value, onChange } }) => (
              <CompanyAutocomplete selected={value} handleSelect={onChange} />
            )}
          </Field>
          <AtomicOrderOpportunityRuleForm atom={values.atom} direction={values.direction} />
          {values.atom?.tag === "price" ? (
            <Field<Date> name="expiresAt">
              {({ input: { onChange } }) => (
                <Select
                  placeholder="Select Expiration"
                  options={DateOptions}
                  onChange={(v) => onChange(v?.value)}
                />
              )}
            </Field>
          ) : null}

          {alwaysRunRule ? null : (
            <div className="flex items-center gap-4">
              <Switch size="small" className="w-8" checked={!!runRule} onChange={setRunRule} />
              <Typography size={Size.Small} text="Run rule against matching opportunities" />
            </div>
          )}
          <Button
            id={COMPANY_RULE_FORM_BUTTON_ID}
            isDisabled={!valid}
            variant="primary"
            onClick={(e) => handleSubmit(e)?.catch(handleConsoleError)}
            label="Create Rule"
            size="large"
            isLoading={loading}
          />
        </form>
      )}
    />
  )
}
