import { ShareOrderButton } from "@components/ShareOrder/ShareOrderButton"
import { isOpportunityInboxUser } from "@components/auth/conditions/IsOrdersUser"
import { PrivateOrderIcon } from "@components/icons/PrivateOrderIcon"
import { Popover, Skeleton } from "@stories/components/Antd"
import ButtonGroup from "@stories/components/ButtonGroup/ButtonGroup"
import { Card } from "@stories/components/Card/Card"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { Opportunity } from "common/model/Opportunity/Opportunity"
import { orderToInternal } from "common/model/Order/Models/UpdateLog"
import {
  sourceAttributionImmediateSource,
  sourceAttributionTail,
  sourceEmail,
  sourceFirm,
  sourceName,
} from "common/model/data-product/DataPoint/SourceAttribution"
import { Loading, deprecatedIsLoaded, matchLoading } from "common/utils/Loading"
import { assertUnreachable } from "common/utils/fp/Function"
import { SetStateAction, useCallback, useEffect, useState } from "react"
import OrderInquiryButton from "src/drawers/OrderInquiry/OrderInquiryButton"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import { useAccountBrokerLinking } from "src/utils/useAccountBrokerLinking"
import {
  IntroductionRequestButtonForOrder,
  useIntroductionRequestButton,
} from "../../../../components/IntroductionRequest"
import WrappedOrderConnectButton from "../../../../components/IntroductionRequest/ConnectBtnFromWrappedOrder"
import { useFirebaseWriter } from "../../../../firebase/Context"
import { CurrentUser } from "../../../../model/CurrentUser"
import { useCurrentUser } from "../../../../providers/currentUser/useCurrentUser"
import { useLoggedInUser } from "../../../../providers/loggedInUser/useLoggedInUser"
import { Button } from "../../../../stories/components/Button/Button"
import { handleConsoleError } from "../../../../utils/Tracking"
import { EditButton } from "../../MyOrders/MyOrdersRowCells"
import { OrderFlag } from "../../MyOrders/OrderFlag"
import { RefreshButton } from "../../MyOrders/RefreshOrderButton"
import { NotInterestedButton } from "../../OrderOpportunityInbox/OrderOpportunityRules/NotInterested/NotInterestedButton"
import { OrderPageLoadingOrder, OrderPageOrder } from "../types"

const LoadingOrderCTAs = () => (
  <div className="flex space-x-1">
    <Skeleton.Button block />
  </div>
)

type Props = {
  order: OrderPageOrder
}

const OpportunityInboxCTAs = ({ order, currentUser }: Props & { currentUser: CurrentUser }) => {
  const firebase = useFirebase9()
  const [opportunity, setOpportunity] = useState<Loading<Opportunity>>("loading")
  useEffect(() => {
    firebase
      .getOrderOpportunityByOrder(currentUser.user.account.id, order.unboxed.order.base)
      .then((opp: unknown) => setOpportunity((opp as SetStateAction<Loading<Opportunity>>) ?? null))
      .catch(handleConsoleError)
  }, [currentUser.user.account.id, firebase, order.unboxed.order.base])

  if (!deprecatedIsLoaded(opportunity)) return null

  return (
    <ButtonGroup>
      <NotInterestedButton item={{ opportunity, order }} iconOnly={false} />
      {order.isConnectable() ? <OrderInquiryButton order={order} size="medium" /> : null}
      <IntroductionRequestButtonForOrder
        order={orderToInternal(order.unboxed.order)}
        sourceComponent="order-details-page"
        tooltipText="Connect"
      />
    </ButtonGroup>
  )
}

const PrivateOrderCTAs = ({ order, currentUser }: Props & { currentUser: CurrentUser }) =>
  order.sourceAttribution(currentUser.user).match(
    (s) =>
      s.tag === "direct" ? (
        <Card>
          <div className="flex items-start gap-4">
            <div className="w-2 h-4 flex items-center">
              <PrivateOrderIcon />
            </div>
            <Typography
              weight={Weight.Semibold}
              color={Color.Primary}
              size={Size.Small}
              text={`This order was shared with you by ${sourceAttributionTail(s)
                .bind(sourceAttributionImmediateSource)
                .bind((x) => sourceEmail(x).or(sourceName(x)).or(sourceFirm(x)))
                .withDefault("")}. If you would like to connect on this ${
                order.direction() === "buy" ? "bid" : "offer"
              }, please contact them directly.`}
            />
          </div>
        </Card>
      ) : s.tag === "indirect" ? (
        <Card>
          <div className="flex items-start gap-4">
            <div className="w-2 h-4 flex items-center">
              <PrivateOrderIcon />
            </div>
            <Typography
              weight={Weight.Semibold}
              color={Color.Primary}
              size={Size.Small}
              text={`This order was shared with you by ${sourceAttributionTail(s)
                .bind(sourceAttributionImmediateSource)
                .bind(sourceFirm)
                .withDefault("")}.`}
            />
          </div>
        </Card>
      ) : s.tag === "unknown" ? null : (
        assertUnreachable(s)
      ),
    () => null
  )

const EditorOrderCTAs = ({ order, currentUser }: Props & { currentUser: CurrentUser }) => {
  const { isAccountBrokerLinkingEnabled } = useAccountBrokerLinking()

  const isMyOrder =
    order.fromAccount(currentUser.user.account) ||
    (order.isBrokeredByUser(currentUser.user) && isAccountBrokerLinkingEnabled)

  if (!isMyOrder || order.isPrivate) return null

  const dbOrder = order.toInternal()

  // TODO: make logged
  return (
    <div className="flex flex-col gap-2 items-center md:flex-row md:flex-wrap">
      <ButtonGroup>
        <EditButton order={dbOrder} sourceComponent="order-details-page" />
        <RefreshButton order={dbOrder} sourceComponent="order-details-page" />
        <ShareOrderButton
          item={{ tag: "order", order: dbOrder, id: dbOrder.id }}
          variant="secondary"
          title="Share"
        />
      </ButtonGroup>
      <OrderFlag order={dbOrder} />
    </div>
  )
}

const ViewerOrderCTAs = ({ order }: Props) => {
  const { user } = useLoggedInUser()
  const showConnect =
    useIntroductionRequestButton(user, "order").showIntroductionRequestButtonToUser &&
    order.isConnectable()

  const { lastViewedConnectDrawer, firstDismissedConnectPopover } = user.productInteractionHistory
  const firebase = useFirebaseWriter()

  const hasViewedConnectDrawer = !!lastViewedConnectDrawer
  const hasDismissedConnectPopover = !!firstDismissedConnectPopover

  const [showPopover, setShowPopover] = useState(
    !hasViewedConnectDrawer && !hasDismissedConnectPopover
  )

  const dismissPopover = useCallback(() => {
    if (!firstDismissedConnectPopover) {
      firebase
        .recordUserProductInteraction(user, {
          firstDismissedConnectPopover: new Date(),
        })
        .catch(handleConsoleError)
    }
    setShowPopover(false)
  }, [firebase, firstDismissedConnectPopover, user])

  const investorOrShareholder = order.direction() === "buy" ? "investor" : "shareholder"
  const clientType = order.sourceClientType().includes("Intermediary")
    ? "broker"
    : investorOrShareholder
  if (!showConnect) return null

  return (
    <div className="flex gap-2">
      <OrderInquiryButton order={order} />
      <Popover
        content={
          <div className="max-w-xs flex flex-col space-y-2 items-start">
            <Typography
              text={`Request an introduction to the ${clientType} behind this order.`}
              weight={Weight.Semibold}
              size={Size.Medium}
            />
            <Typography
              text="Submitting a Connect request is the best way to explore a potential transaction with a counterparty. Connect requests are not committal and do not bind you to a transaction in any way."
              size={Size.Small}
            />
            <Button onClick={dismissPopover} label="Dismiss" variant="hollow" size="none" />
          </div>
        }
        placement="bottomLeft"
        open={showPopover}
      >
        <WrappedOrderConnectButton
          order={order}
          onClick={dismissPopover}
          className="w-full"
          size="large"
          variant="primary"
        />
      </Popover>
    </div>
  )
}

export const LoadedOrderCTAs = ({ order }: Props) => {
  const { isAccountBrokerLinkingEnabled } = useAccountBrokerLinking()
  return matchLoading(
    useCurrentUser(),
    (currentUser) => {
      const isPrivateCTAs = order.isPrivate && !isOpportunityInboxUser(currentUser.user)

      if (isPrivateCTAs) {
        return <PrivateOrderCTAs order={order} currentUser={currentUser} />
      }
      const canEditOrder =
        order.fromAccount(currentUser.user.account) ||
        (order.isBrokeredByUser(currentUser.user) && isAccountBrokerLinkingEnabled)
      const isEditorCTAs = !order.isPrivate && canEditOrder
      if (isEditorCTAs) {
        return <EditorOrderCTAs order={order} currentUser={currentUser} />
      }
      if (isOpportunityInboxUser(currentUser.user)) {
        return <OpportunityInboxCTAs order={order} currentUser={currentUser} />
      }
      return <ViewerOrderCTAs order={order} />
    },
    null,
    null
  )
}

const OrderCTAs = ({ order }: OrderPageLoadingOrder) =>
  order.match((o) => <LoadedOrderCTAs order={o} />, <LoadingOrderCTAs />, null)

export default OrderCTAs
