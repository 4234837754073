import { Tab, Tabs } from "@stories/components/Tabs/Tabs"
import {
  MaterializedViewName,
  MaterializedViewType,
  latestMaterializedView,
} from "common/queries/materialized-views/MaterializedViews"
import { defaultIfLoading } from "common/utils/Loading"
import { Rec, UnsafeRec } from "common/utils/RecordUtils"
import { useAdminDb } from "src/utils/useDb"
import { startCase } from "lodash"
import { JsonF, matchJson } from "@components/displays/JSON"
import { shortDateFormat } from "common/utils/dateUtils"
import { tradeDashboardViews } from "common/queries/materialized-views/TradeDashboardViews"
import { useSort } from "src/utils/hooks/components/useSort"
import { useMemo, createElement } from "react"
import { useFilter } from "src/utils/hooks/components/useFilter"
import { substringFilter } from "src/pages/Data/TableFilters/Substring"

const defaultFormat = (x: object): string[] =>
  Object.values(x).map((val: JsonF<unknown>) =>
    matchJson(
      val,
      (obj) => JSON.stringify(obj),
      (arr) => JSON.stringify(arr),
      (str) => str,
      (num) => num.toString(),
      (bool) => bool.toString(),
      (date) => shortDateFormat(date),
      "null"
    )
  )

// string keys only
const defaultHeaders = <R extends Record<string, unknown>>(obj: R): Record<keyof R, string> =>
  Rec.indexedMap(obj, ([key]) => startCase(key as string))

type BuiltinComparable = number | Date | string
const ViewTableInner = <R extends Record<string, unknown>>({
  headers,
  results,
  format,
}: {
  headers: Record<keyof R, string>
  results: R[]
  format: (r: R) => string[]
}) => {
  const filters = useMemo(
    () =>
      Rec.indexedMap(headers, ([header]) => substringFilter((t: R) => JSON.stringify(t[header]))),
    [headers]
  )
  const [filtered, FilterControls] = useFilter<R, Record<keyof R, string>>(results, filters, {})
  const [sorted, SortToggles] = useSort<R, keyof R>(
    filtered,
    Rec.indexedMap(headers, ([header]) => (l, r) => {
      const result =
        (l[
          header
        ] as BuiltinComparable) /** obviously false, but we actually want the js type coercion nonsense here. */ >
        (r[header] as BuiltinComparable)
          ? ">"
          : "<="
      return result
    })
  )
  const formatted = useMemo(() => sorted.map((r) => format(r)), [format, sorted])
  return (
    <div>
      <table>
        <tr>
          {UnsafeRec.entries(headers).map(([headerKey, headerName]) => (
            <th key={headerName}>
              <span>{headerName}</span>
              {FilterControls[headerKey]}
              {createElement(SortToggles[headerKey])}
            </th>
          ))}
        </tr>
        {formatted.map((row) => (
          <tr key={row.join(",")}>
            {row.map((cell) => (
              <td key={cell}>{cell}</td>
            ))}
          </tr>
        ))}
      </table>
    </div>
  )
}

const ViewTable = <K extends MaterializedViewName>({
  view,
  headers = defaultHeaders,
  format = defaultFormat,
}: {
  view: K
  headers?: (t: MaterializedViewType<K>) => Record<keyof typeof t, string>
  format?: (t: MaterializedViewType<K>) => string[]
}) => {
  const viewResults = defaultIfLoading(
    useAdminDb((db) => latestMaterializedView(view)(db.adminDb), [view]),
    []
  )
  return viewResults.length === 0 ? (
    <div>No results</div>
  ) : (
    <ViewTableInner format={format} results={viewResults} headers={headers(viewResults[0])} />
  )
}

const tabs: Tab[] = UnsafeRec.entries(tradeDashboardViews).map(
  ([k, _], ix) =>
    ({
      id: k,
      label: k,
      key: ix,
      children: <ViewTable key={k} view={k} />,
    } satisfies Tab)
)

/** Displays the results of all queries in `tradeDashboardViews` */
export const TradeDataDashboard = () => (
  <div className="shadow-md border rounded bg-neutral-white p-4">
    <Tabs shouldIncludeSideBorders={false} initialTab={0} tabs={tabs} />
  </div>
)
