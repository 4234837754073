import { Note } from "common/model/Note"
import { formatDate } from "common/utils/dateUtils"
import React from "react"
import { MessagingPill } from "src/pages/Messaging/MessagingPill"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"

interface NotesDisplayProps {
  notes?: Note[]
}

const NotesDisplay: React.FC<React.PropsWithChildren<NotesDisplayProps>> = ({ notes }) => {
  const currentUser = useLoggedInUser()
  if (!notes) {
    return null
  }

  const notesSortedByDateDesc = [...notes].sort(
    (a, b) => b.timestamp.getTime() - a.timestamp.getTime()
  )

  return (
    <div className="flex flex-col gap-4 overflow-y-auto">
      {notesSortedByDateDesc.map((note) => (
        <div key={note.timestamp.toISOString()} className="flex space-x-2 items-center">
          <div className="flex flex-col space-y-1">
            <div className="flex items-center space-x-1">
              <p className="text-neutral-600">
                {note.author.firstName} {note.author.lastName}
              </p>
              {currentUser.user.id === note.author.id && <MessagingPill type="user" text="You" />}
              <p className="text-neutral-600 text-xs">{formatDate(note.timestamp)}</p>
            </div>
            <p className="font-semibold whitespace-normal">{note.text}</p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default NotesDisplay
