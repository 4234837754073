import { Row } from "@tanstack/react-table"
import { PropsWithChildren, useState } from "react"
import { TableProps } from "./Table"
import TableCell from "./TableCell"
import { DEFAULT_VARIANT, rowClassName } from "./helpers"
import { HoveredColumnProps } from "./types"

const DefaultRowWrapper = ({ children }: PropsWithChildren) => children

export const TableRow = <T extends object>({
  row,
  index,
  onRowClick,
  variant = DEFAULT_VARIANT,
  isFirstColumnSticky,
  indexesOfStartOfGroupColumn,
  isPaginationEnabled,
  renderSubComponent,
  rowWrapper: RowWrapper = DefaultRowWrapper,
  textSize,
  hoveredColumn,
  setHoveredColumn,
}: Pick<
  TableProps<T>,
  | "isFirstColumnSticky"
  | "onRowClick"
  | "variant"
  | "rowWrapper"
  | "renderSubComponent"
  | "textSize"
> &
  HoveredColumnProps & {
    row: Row<T>
    index: number
    indexesOfStartOfGroupColumn: {
      indexes: number[]
      lastHeader: string | null | undefined
      pointer: number
    }
    isPaginationEnabled: boolean
  }) => {
  const [isHovered, setIsHovered] = useState(false)

  const isEven = index % 2 === 0
  const variantRequiresHover = variant === "stripe"

  return (
    <>
      <tr
        onMouseEnter={() => (variantRequiresHover ? setIsHovered(true) : null)}
        onMouseLeave={() => (variantRequiresHover ? setIsHovered(false) : null)}
        className={rowClassName(isHovered, isPaginationEnabled, { variant, onRowClick })}
        onClick={() => onRowClick?.(row)}
      >
        <RowWrapper rowData={row.original}>
          {row.getVisibleCells().map((cell, idx) => (
            <TableCell<T>
              key={cell.id}
              cell={cell}
              idx={idx}
              isEven={isEven}
              isFirstColumnSticky={isFirstColumnSticky}
              indexesOfStartOfGroupColumn={indexesOfStartOfGroupColumn}
              isRowHovered={isHovered}
              variant={variant}
              textSize={textSize}
              hoveredColumn={hoveredColumn}
              setHoveredColumn={setHoveredColumn}
            />
          ))}
        </RowWrapper>
      </tr>

      {row.getIsExpanded() && renderSubComponent !== undefined && (
        <tr>
          <td colSpan={row.getVisibleCells().length}>{renderSubComponent({ row })}</td>
        </tr>
      )}
    </>
  )
}
