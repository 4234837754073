import { MultiCheckboxSelect } from "@components/OrderFormComponents/CheckboxSelect"
import { FormLabel } from "@stories/components/Inputs/FormLabel/FormLabel"
import { NumberField } from "@stories/components/Inputs/NumberInput/NumberField"
import { RadioSelect } from "@stories/components/Inputs/RadioSelect/RadioSelect"
import { OrderDirection, OrderShareClass } from "common/model/Order/Order"
import {
  AtomicOrderFilter,
  OrderNumericFieldComparisons,
  orderNumericFieldComparisons,
  numericFieldComparisonDisplay,
} from "common/model/Order/OrderFilter/OrderFilter"
import { OrderStructure } from "common/model/Order/Types/Terms"
import { formatAbbreviatedNumber } from "common/utils/math/format"
import { Field } from "react-final-form"

export const validateAtomicOrderFilter = (
  values: Partial<AtomicOrderFilter>,
  direction: OrderDirection | undefined
) => {
  if (!values.tag) return { tag: "requires tag" }
  if (values.tag === "structure") {
    if (!values.orderStructure) return { orderStructure: "structure required" }
  }
  if (values.tag === "origination date" || values.tag === "volume") {
    if (!values.limit) return { limit: "requires limit" }
    if (!values.comparison) return { comparison: "requires comparison" }
  }
  if (values.tag === "price") {
    if (!values.limit) return { limit: "requires limit" }
    if (!values.comparison && !direction) return { comparison: "requires comparison" }
  }
  return undefined
}

type AtomicOrderOpportunityRuleFormFilterTypes = "price" | "volume" | "structure" | "shareClass"
export const AtomicOrderOpportunityRuleForm = ({
  atom,
  direction,
  filterTypes = ["price", "volume", "structure", "shareClass"],
}: {
  atom?: AtomicOrderFilter
  direction?: OrderDirection
  filterTypes?: AtomicOrderOpportunityRuleFormFilterTypes[]
}) => (
  <>
    <Field<OrderDirection | undefined> name="direction">
      {({ input: { value, onChange } }) => (
        <RadioSelect<OrderDirection | undefined>
          value={value || undefined}
          options={[
            { value: undefined, label: "Bids and Offers" },
            { value: "buy", label: "Bids" },
            { value: "sell", label: "Offers" },
          ]}
          onChange={onChange}
        />
      )}
    </Field>
    <Field<AtomicOrderOpportunityRuleFormFilterTypes> name="atom.tag">
      {({ input: { value, onChange } }) => (
        <RadioSelect<AtomicOrderOpportunityRuleFormFilterTypes>
          value={value}
          label="Where"
          options={[
            ...(filterTypes.includes("price") ? [{ value: "price" as const, label: "Price" }] : []),
            ...(filterTypes.includes("volume")
              ? [{ value: "volume" as const, label: "Size" }]
              : []),
            ...(filterTypes.includes("structure")
              ? [{ value: "structure" as const, label: "Structure" }]
              : []),
            ...(filterTypes.includes("shareClass")
              ? [{ value: "shareClass" as const, label: "Share Class" }]
              : []),
          ]}
          onChange={onChange}
        />
      )}
    </Field>
    {atom?.tag === "structure" ? (
      <Field<OrderStructure[]> name="atom.orderStructure">
        {({ input: { value, onChange } }) => (
          <div>
            <MultiCheckboxSelect<OrderStructure>
              value={value}
              label="Any of the following"
              options={[
                { value: "direct", label: "Direct" },
                { value: "spv", label: "SPV" },
                { value: "forward", label: "Forward" },
              ]}
              onChange={onChange}
            />
          </div>
        )}
      </Field>
    ) : atom?.tag === "shareClass" ? (
      <Field<OrderShareClass> name="atom.shareClass">
        {({ input: { value, onChange } }) => (
          <RadioSelect<OrderShareClass>
            value={value}
            label="Select"
            options={[
              { value: "common", label: "Common" },
              { value: "preferred", label: "Preferred" },
              { value: "unknown", label: "Unknown" },
            ]}
            onChange={onChange}
          />
        )}
      </Field>
    ) : atom && atom.tag === "price" && direction === "buy" ? (
      <>
        <FormLabel>Less Than</FormLabel>
        <Field<number> name="atom.limit">
          {({ input: { value, onChange } }) => (
            <NumberField
              onChange={onChange}
              value={value}
              formatter={formatAbbreviatedNumber}
              prefix="$"
              placeholder="Price"
            />
          )}
        </Field>
      </>
    ) : atom && atom.tag === "price" && direction === "sell" ? (
      <>
        <FormLabel>Greater Than</FormLabel>
        <Field<number> name="atom.limit">
          {({ input: { value, onChange } }) => (
            <NumberField
              onChange={onChange}
              value={value}
              formatter={formatAbbreviatedNumber}
              prefix="$"
              placeholder="Price"
            />
          )}
        </Field>
      </>
    ) : (
      <>
        <Field<OrderNumericFieldComparisons> name="atom.comparison">
          {({ input: { value, onChange } }) => (
            <RadioSelect<OrderNumericFieldComparisons>
              value={value}
              label="Is"
              options={orderNumericFieldComparisons.map((v) => ({
                value: v,
                label: numericFieldComparisonDisplay(v),
              }))}
              onChange={onChange}
            />
          )}
        </Field>
        <Field<number> name="atom.limit">
          {({ input: { value, onChange } }) => (
            <NumberField
              onChange={onChange}
              value={value}
              formatter={formatAbbreviatedNumber}
              prefix="$"
            />
          )}
        </Field>
      </>
    )}
  </>
)
