import { Drawer, DrawerProps } from "@stories/components/Antd"
import { FormOrder } from "common/model/Order/OrderForm/State"
import { ConnectOrderFormConfirmationPage } from "./ConnectOrderFormConfirmationPage"
import FullOrderFormContextProvider from "../../OrderFormContext"
import { OrderFormDrawerInner } from "../../OrderFormDrawer"
import { OrderFormSaveFunction } from "../../contextHooks/useFormOrderSaving"
import { DrawerControlProvider } from "@components/Drawer/DrawerControlContext"
import { useOrderFormCloseConfirm } from "../../useOrderFormCloseConfirm"

const ConnectOrderFormDrawer = ({
  onClose,
  open,
  initialFormOrder,
  onSave,
  drawerTitle,
}: {
  onClose: () => void
  open: Required<DrawerProps["open"]>
  onSave: OrderFormSaveFunction
  initialFormOrder: Partial<FormOrder>
  drawerTitle?: string
}) => {
  const { handleSaveWithConfirmation, handleDrawerCloseWithConfirmation, afterChange } =
    useOrderFormCloseConfirm({
      onClose,
      onSave,
    })

  return (
    <div onClick={(e) => e.stopPropagation()} role="none">
      <Drawer
        open={open}
        onClose={handleDrawerCloseWithConfirmation}
        placement="right"
        width={1200}
        contentWrapperStyle={{ maxWidth: "100vw" }}
        bodyStyle={{ padding: 0 }}
        closable={false}
        destroyOnClose
      >
        <DrawerControlProvider onClose={handleDrawerCloseWithConfirmation}>
          <FullOrderFormContextProvider
            initialFormOrder={initialFormOrder}
            onSave={handleSaveWithConfirmation}
            afterChange={afterChange}
            orderFormOpen={!!open}
          >
            <OrderFormDrawerInner
              drawerTitle={drawerTitle ?? ""}
              confirmationElement={ConnectOrderFormConfirmationPage}
            />
          </FullOrderFormContextProvider>
        </DrawerControlProvider>
      </Drawer>
    </div>
  )
}

export default ConnectOrderFormDrawer
