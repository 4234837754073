import { Rec, UnsafeRec } from "../../RecordUtils"
import { Func } from "../Function"
import { RecFunc } from "../Record/Mapping"
import { Traversal } from "./Traversal"

export interface RecTraversal<S, T, A, B> {
  views: (s: S) => A[]
  over: (f: (a: A) => B) => RecFunc<S,T>
}
export type SimpleRecTraversal<S, A> = RecTraversal<S, S, A, A>

export const composeRecTraversal = <S, T, A, B, X, Y>(
  l: RecTraversal<S, T, A, B>,
  r: RecTraversal<A, B, X, Y>
): RecTraversal<S, T, X, Y> => ({
  views: (s) => l.views(s).flatMap((a) => r.views(a)),
  over: (f) => new RecFunc(l.over(r.over(f).run).run),
})

export interface IReifiedRecTraversal<S, T, A, B> extends RecTraversal<S, T, A, B> {
  composeRecTraversal: <X, Y>(l: RecTraversal<A, B, X, Y>) => ReifiedRecTraversal<S, T, X, Y>
}
export class ReifiedRecTraversal<in S, out T, out A, in B>
{
  readonly views: (s: S) => A[]

  readonly over: (f: Func<A, B>) => RecFunc<S, T>

  constructor(traversal: { views: (s: S) => A[]; over: (f: Func<A, B>) => RecFunc<S, T> }) {
    this.views = traversal.views
    this.over = traversal.over
  }

  composeRecTraversal = <X, Y>(l: RecTraversal<A, B, X, Y>): ReifiedRecTraversal<S, T, X, Y> =>
    new ReifiedRecTraversal(composeRecTraversal(this, l))

  composeTraversal = <X, Y>(r: Traversal<A, B, X, Y>): ReifiedRecTraversal<S, T, X, Y> =>
  new ReifiedRecTraversal(({
    views: (s) => this.views(s).flatMap((a) => r.views(a)),
    over: (f) => new RecFunc(this.over((s) => r.over(s, f)).run),
  }))

}

export type ReifiedSimpleRecTraversal<S, A> = ReifiedRecTraversal<S, S, A, A>

export const unsafeRecordFields = <R>(): ReifiedSimpleRecTraversal<R, R[keyof R]> =>
  new ReifiedRecTraversal<R, R, R[keyof R], R[keyof R]>({
    views: UnsafeRec.values<keyof R, R[keyof R]>,
    over: (f) => new RecFunc((s) => Rec.map(s, f) as unknown as typeof s),
  })