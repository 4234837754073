import { User } from "common/model/User"
import { DealCRMNote, viewDealCRMNoteParentNoteFields } from "common/model/DealCRM/DealCRMNote"
import { viewUserIdFields } from "common/model/User"
import { randomString } from "common/utils/RandomUtils"
import { FirebaseWriter } from "src/firebase/Firebase"
import { collections } from "common/firestore/Collections"
import { handleConsoleError } from "src/utils/Tracking"
import { JSONContent } from "@tiptap/react"
import { NoteTag as NoteMention } from "./SuggestionConfig"

export const buildCRMNote = (params: {
  content: JSONContent
  user: User
  source: DealCRMNote["source"]
  mentions: NoteMention[]
}): DealCRMNote => {
  const { content, user, source, mentions } = params // TODO - mentions are broken here

  const newNote: DealCRMNote = {
    id: randomString(),
    accountId: user.account.id,
    note: content as DealCRMNote["note"],
    source,
    createdAt: new Date(),
    taggedContacts: mentions.flatMap((mention) =>
      mention.tag === "contact" ? [mention.data] : []
    ),
    taggedCompanies: mentions.flatMap((mention) =>
      mention.tag === "company" ? [mention.data] : []
    ),
    taggedUsers: mentions.flatMap((mention) => (mention.tag === "user" ? [mention.data] : [])),
    taggedDeals: mentions.flatMap((mention) => (mention.tag === "deal" ? [mention.data] : [])),
    taggedCompanyIds: mentions.flatMap((mention) =>
      mention.tag === "company" ? [mention.data.id] : []
    ),
    taggedContactIds: mentions.flatMap((mention) =>
      mention.tag === "contact" ? [mention.data.id] : []
    ),
    taggedUserIds: mentions.flatMap((mention) => (mention.tag === "user" ? [mention.data.id] : [])),
    taggedDealIds: mentions.flatMap((mention) => (mention.tag === "deal" ? [mention.data.id] : [])),
    createdBy: viewUserIdFields(user),
    parentNote: null,
  }

  return newNote
}

export const saveNewCRMNote = async (params: {
  db: FirebaseWriter
  content: JSONContent
  user: User
  source: DealCRMNote["source"]
  mentions: NoteMention[]
}): Promise<unknown> => {
  const { db } = params
  const newNote = buildCRMNote(params)
  return db.db
    .collection(collections.dealCRM.notes)
    .add(newNote)
    .catch((e) => {
      handleConsoleError("Error adding note", e)
    })
}

export const buildCRMNoteReply = (params: {
  content: JSONContent
  user: User
  source: DealCRMNote["source"]
  mentions: NoteMention[]
  parentNote: Pick<DealCRMNote, "id" | "accountId">
}): DealCRMNote => ({
  ...buildCRMNote(params),
  parentNote: viewDealCRMNoteParentNoteFields(params.parentNote),
})

export const saveNewCRMNoteReply = async (params: {
  db: FirebaseWriter
  content: JSONContent
  user: User
  source: DealCRMNote["source"]
  mentions: NoteMention[]
  parentNote: Pick<DealCRMNote, "id" | "accountId">
}): Promise<unknown> => {
  const { db } = params
  const newNote = buildCRMNoteReply(params)
  return db.db
    .collection(collections.dealCRM.notes)
    .add(newNote)
    .catch((e) => {
      handleConsoleError("Error adding reply", e)
    })
}
