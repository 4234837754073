import { DBQuery } from "common/utils/Collection"
import { firestoreConverter } from "common/model/FirestoreConverter"
import { collections } from "common/firestore/Collections"
import { Sector, sectorPrism } from "common/model/Sector"
import { FirebaseReader } from "../../Firebase"
import { getDocData } from "../utils"

const sectorConverter = firestoreConverter<Sector>()

export const sectors = (db: FirebaseReader) =>
  db.db.collection(collections.sectors).withConverter(sectorConverter)
export const sectorsQuery = (db: FirebaseReader) =>
  new DBQuery(
    db.db,
    db.db.collection(collections.sectors).withConverter(sectorConverter),
    sectorPrism
  )
export const getAllSectors = (db: FirebaseReader): Promise<Sector[]> =>
  getDocData(sectors(db).orderBy("sectorMetaData.name"))
