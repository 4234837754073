import { restrictedCollections } from "common/firestore/Collections"
import { Order } from "common/model/Order/Models/Internal"
import { useFirebaseAdmin } from "src/firebase/Context"
import Firebase from "src/firebase/Firebase"
import { useErrorHandler } from "src/providers/errorHandler"
import { firestoreConverter } from "../../../model/FirestoreConverter"
import { CheckboxWithLabel } from "@components/inputs/checkbox/Checkbox"

const updateOrderAdminIgnoreQuotaComplianceViolations = async (
  db: Firebase,
  o: Order,
  adminIgnoreQuotaComplianceViolations: boolean
) => {
  const ref = db.adminDb
    .collection(
      o.darkpool
        ? restrictedCollections.darkpoolOrderObservations
        : restrictedCollections.orderObservations
    )
    .withConverter<Order>(firestoreConverter<Order>())
    .doc(o.id)
  return ref.update({ adminIgnoreQuotaComplianceViolations })
}

export const AdminIgnoreQuotaComplianceViolationCheckbox = ({ order }: { order: Order }) => {
  const db = useFirebaseAdmin()
  const { handleError } = useErrorHandler()
  return (
    <CheckboxWithLabel
      title="Allow order to count towards quota"
      checked={order.adminIgnoreQuotaComplianceViolations}
      onChange={(e) =>
        updateOrderAdminIgnoreQuotaComplianceViolations(db, order, e.target.checked).catch(
          handleError
        )
      }
      id="adminIgnoreQuotaComplianceViolations"
    />
  )
}
