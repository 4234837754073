import { FC, useMemo, useState } from "react"
import { Select } from "@stories/components/Antd"

import { Account } from "common/model/Account"
import {
  EntityType,
  AccreditationInfo,
  entityTypes,
  AccreditationAttestation,
  AccreditationStandardizedEntityTypes,
  isAccreditedAccount,
  buildAccreditationInfo,
} from "common/model/Accreditation"

import { isEmpty } from "lodash"
import { Checkbox } from "@components/inputs/checkbox/Checkbox"
import { UserFunctions, UserIdFields } from "common/model/User"
import { sendPlatformEventMessage } from "src/firebase/API"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import { deprecatedIsLoaded } from "common/utils/Loading"

import { QuestionLabel } from "../../OnboardingDeprecated/QuestionLabel"
import SectionHeader from "@stories/components/Typography/SectionHeader"
import { Button } from "@stories/components/Button/Button"
import { CurrentUser } from "@model/CurrentUser"
import { useFirebaseReader } from "src/firebase/Context"

const NoneOfTheAbove = "none_of_the_above"

export const NOT_ACCREDITED_TEXT =
  "Based on your selections, you are eligible to view certain parts of the platform but will not be able to view Investment opportunities."

interface AccreditationFormShape {
  selectedAccreditationAttestations: (AccreditationAttestation | typeof NoneOfTheAbove)[]
  entityType: EntityType | undefined
}

export const AccreditationQuestions: Record<
  (typeof AccreditationStandardizedEntityTypes)[number],
  Partial<Record<AccreditationAttestation, string>>
> = {
  individual: {
    netWorkMoreThan1M:
      "I have a net worth greater than $1m, excluding the value of my primary residence.",
    incomeMoreThan200k:
      "I have an individual income above $200,000 or a joint income with my spouse above $300,000, for each of the past 2 years.",
    licensedByFINRA:
      "I am licensed / certified by FINRA as a Licensed General Securities Representative (Series 7), (ii) Licensed Investment Adviser Representative (Series 65), and/or (iii) Licensed Private Securities Offerings Representative (Series 82).",
  },
  trust: {
    aumMoreThan5M:
      "The trust has total assets in excess of $5m and is directed by a sophisticated person (one who has such knowledge and experience in financial and business matters that it is capable of evaluating the merits and risks of the prospective investment).",
    revocableTrust:
      'The trust is a revocable trust that may be amended or revoked at any time by the grantors thereof and all of the grantors are "accredited investors".',
  },
  ria: {
    registeredWithSEC:
      "The RIA is registered with the SEC as an investment adviser pursuant to Section 203 of the Investment Advisers Act.",
    registeredWithState:
      "The RIA is registered with any state of the United States as an investment adviser pursuant to the laws of such state.",
  },
  eia: {
    exemptFromRegistration:
      "The Subscriber is exempt from registration with the SEC as an investment adviser pursuant to Section 203(l) or Section 203(m) of the Investment Advisers Act.",
  },
  other: {
    totalAssetsMoreThan5M: "The firm / fund has total assets under management of at least $5m.",
    allLimitedPartnersAreAccredited:
      "All limited partners, equity owners, or grantors are accredited.",
  },
}

export const convertRawEntityTypeToStandForm = (
  entityType: EntityType | undefined
): (typeof AccreditationStandardizedEntityTypes)[number] | undefined => {
  if (!entityType) return undefined
  switch (entityType) {
    case "Individual":
      return "individual"
    case "Trust":
      return "trust"
    case "Registered Investment Advisor":
      return "ria"
    case "Exempt Investment Advisor":
      return "eia"
    default:
      return "other"
  }
}

export const sendAccreditationSlackMessage = ({
  updatedAccount,
  currentUser,
}: {
  updatedAccount: Account
  currentUser: CurrentUser
}) => {
  const message = [
    "*Account accreditation status updated!*",
    `*Account:* ${updatedAccount.name}`,
    `*Updated By:* ${currentUser.user.email}`,
    `*Accredited:* ${isAccreditedAccount(updatedAccount)
      .match(
        (accredited) => accredited,
        () => "N/A"
      )
      .toString()}`,
  ].join("\n")
  return sendPlatformEventMessage(message, currentUser)
}

const Accreditation: FC<{
  account: Account
  onNext: (formValues?: Partial<{ password: string }>) => void
  userData: Omit<UserIdFields, "id">
}> = ({ account, onNext, userData }) => {
  const firebase = useFirebaseReader()
  const currentUser = useCurrentUser()
  const isIndividual = UserFunctions.isIndividualAccountUser(userData)
  const [showNotAccreditedText, setShowNotAccreditedText] = useState(false)

  const [formValues, setFormValues] = useState<AccreditationFormShape>({
    selectedAccreditationAttestations: [],
    entityType: isIndividual ? "Individual" : undefined,
  })

  const formComplete = useMemo(
    () => !isEmpty(formValues.selectedAccreditationAttestations) && formValues.entityType,
    [formValues]
  )

  const onSelectFirmType = (firmType: EntityType) => {
    setFormValues({ ...formValues, entityType: firmType, selectedAccreditationAttestations: [] })
  }

  const handleOnNext = () => {
    if (!showNotAccreditedText) {
      storePrefs()
      if (formValues.selectedAccreditationAttestations.includes(NoneOfTheAbove)) {
        setShowNotAccreditedText(true)
      } else {
        onNext()
      }
    } else {
      onNext()
    }
  }

  const storePrefs = async () => {
    if (formValues.entityType && deprecatedIsLoaded(currentUser)) {
      const accreditationInfo: AccreditationInfo = buildAccreditationInfo({
        accreditationEntityType: formValues.entityType,
        accreditationStatements: formValues.selectedAccreditationAttestations.filter(
          (e) => e !== NoneOfTheAbove
        ),
        user: userData,
      })
      const updateData: Partial<Account> = {
        accreditationInfos: account.accreditationInfos
          ? [...account.accreditationInfos, accreditationInfo]
          : [accreditationInfo],
      }
      await firebase
        .account(account.id)
        .update(updateData)
        .then(() =>
          sendAccreditationSlackMessage({
            updatedAccount: {
              ...account,
              accreditationInfos: updateData.accreditationInfos,
            },
            currentUser,
          })
        )
    }
  }

  const onSelectStatement = (
    selectedStatement: AccreditationAttestation | typeof NoneOfTheAbove
  ) => {
    if (selectedStatement === NoneOfTheAbove) {
      if (!formValues.selectedAccreditationAttestations.includes(selectedStatement)) {
        setFormValues({
          ...formValues,
          selectedAccreditationAttestations: [selectedStatement],
        })
      } else {
        setFormValues({
          ...formValues,
          selectedAccreditationAttestations: [],
        })
      }
    } else if (!formValues.selectedAccreditationAttestations.includes(selectedStatement)) {
      setFormValues({
        ...formValues,
        selectedAccreditationAttestations: formValues.selectedAccreditationAttestations
          .concat(selectedStatement)
          .filter((s) => s !== NoneOfTheAbove),
      })
    } else {
      setFormValues({
        ...formValues,
        selectedAccreditationAttestations: formValues.selectedAccreditationAttestations.filter(
          (s) => s !== selectedStatement
        ),
      })
    }
  }
  return (
    <div className="flex flex-col justify-center">
      <SectionHeader title="Accreditation" subtext="" />

      <div>
        {!showNotAccreditedText && (
          <StatementSelectionContent
            selectedFirmType={formValues.entityType}
            onSelectFirmType={onSelectFirmType}
            selectedStatements={formValues.selectedAccreditationAttestations}
            onSelectStatement={onSelectStatement}
          />
        )}
      </div>

      {showNotAccreditedText && <div className="max-w-md">{NOT_ACCREDITED_TEXT}</div>}
      <div>
        <div className="mt-10">
          <Button
            label="Continue"
            variant="primary"
            size="large"
            isFullWidth
            onClick={handleOnNext}
            isDisabled={!formComplete}
          />
        </div>
      </div>
    </div>
  )
}

const StatementSelectionContent = ({
  selectedFirmType,
  onSelectFirmType,
  selectedStatements,
  onSelectStatement,
}: {
  selectedFirmType: EntityType | undefined
  onSelectFirmType: (e: EntityType) => void
  selectedStatements: (AccreditationAttestation | typeof NoneOfTheAbove)[]
  onSelectStatement: (e: AccreditationAttestation | typeof NoneOfTheAbove) => void
}) => {
  const entityType = convertRawEntityTypeToStandForm(selectedFirmType)

  const statements = useMemo<Partial<Record<AccreditationAttestation, string>>>(() => {
    if (!entityType) return {}
    return AccreditationQuestions[entityType]
  }, [entityType])

  // TODO - make this its own React FC
  const StatementsCheckBoxSection = () => {
    if (isEmpty(statements) || !entityType) return <div className="h-40" />

    const questionLabelSubject = ["individual", "eia", "ria"].includes(entityType)
      ? "you"
      : entityType === "trust"
      ? "your trust"
      : "your fund/firm"

    return (
      <div className="max-w-md">
        <QuestionLabel
          field="selectedStatements"
          text={`Which of the following statements apply to ${questionLabelSubject}?`}
        />
        <div className="flex flex-col space-y-2 m-4">
          {Object.entries(statements).map(([key, value], index) => (
            <div className="inline-flex" key={key}>
              <Checkbox
                id={`${entityType}-${index}`}
                checked={selectedStatements.includes(key as AccreditationAttestation)}
                onChange={() => onSelectStatement(key as AccreditationAttestation)}
              />
              <span
                className="ml-6 text-neutral-1000 text-sm cursor-pointer self-center"
                onClick={() => onSelectStatement(key as AccreditationAttestation)}
              >
                {value}
              </span>
            </div>
          ))}
          <div className="inline-flex">
            <Checkbox
              id={`${entityType}-extra`}
              checked={selectedStatements.includes(NoneOfTheAbove)}
              onChange={() => onSelectStatement(NoneOfTheAbove)}
            />
            <span
              className="ml-6 text-neutral-1000 text-sm cursor-pointer self-center"
              onClick={() => onSelectStatement(NoneOfTheAbove)}
            >
              None of the above apply.
            </span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-4">
      {entityType !== "individual" && (
        <div className="flex flex-col gap-2">
          <QuestionLabel
            field="firmType"
            text="Which of the following best classifies your firm or fund?"
          />
          <div className="flex flex-col">
            <div className="w-full mb-2">
              <Select
                className="w-full"
                value={selectedFirmType}
                onChange={onSelectFirmType}
                options={entityTypes
                  .filter((e) => e !== "Individual")
                  .map((entity) => ({
                    value: entity,
                    label: entity,
                  }))}
              />
            </div>
          </div>
        </div>
      )}
      <StatementsCheckBoxSection />
    </div>
  )
}
export default Accreditation
