import Disclaimer from "@stories/components/Disclaimer/Disclaimer"
import { Modal, useModal } from "./Modal"

const Footer: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <footer className="mt-4 w-full">
    <div className="flex-1 flex-grow text-xxs text-neutral-600 full-width lg:max-w-screen-xl text-justify ml-auto mr-auto">
      {children}
    </div>
  </footer>
)

export const MinimalFooter = () => (
  <Footer>
    <Disclaimer />
  </Footer>
)

export const FooterWithClearbit = () => (
  <Footer>
    <Disclaimer />
    <p className="mt-2">
      <a className="text-neutral-600 text-xs" href="https://clearbit.com">
        Logos provided by <span className="underline">Clearbit</span>
      </a>
    </p>
  </Footer>
)

export const SidebarFooter = () => {
  const {
    open: disclaimerOpen,
    closeModal: closeDisclaimer,
    openModal: openDisclaimer,
  } = useModal()
  return (
    <>
      <footer className="border-t border-neutral-300 pt-2 w-full">
        <ul className="text-xxs text-neutral-700">
          <li>
            <button className="hover:text-accent-500" type="button" onClick={openDisclaimer}>
              Disclaimer
            </button>
          </li>
          <li>
            <a href="https://clearbit.com">Logos provided by Clearbit</a>
          </li>
        </ul>
      </footer>
      <Modal
        className="max-w-screen-md"
        open={disclaimerOpen}
        handleClose={closeDisclaimer}
        body={
          <div>
            <Disclaimer />
          </div>
        }
        closable
        maskClosable
      />
    </>
  )
}
