import { OfficeBuildingIcon } from "@heroicons/react/solid"
import { useState } from "react"

export const CompanySmallLogo = ({ logo }: { logo?: string }) => {
  const [error, setError] = useState(false)
  return logo && !error ? (
    <img alt="logo" src={logo} className="w-5" onError={() => setError(true)} />
  ) : (
    <OfficeBuildingIcon className="w-5 h-5 text-neutral-600" />
  )
}
