import React, { FC, ReactElement, SVGProps } from "react"
import { QuestionMarkCircleIcon } from "@heroicons/react/solid"
import { classNames } from "../../utils/classNames"

interface IconButtonProps {
  icon: (props: SVGProps<SVGSVGElement>) => ReactElement
  size: "xs" | "sm" | "md" | "lg"
  onClick: () => void
  className?: string
  iconClassName?: string
}

export const IconButton: FC<React.PropsWithChildren<IconButtonProps>> = ({
  icon,
  onClick,
  className = "",
  size,
  iconClassName = "",
}) => (
  <button
    type="button"
    onClick={onClick}
    className={classNames(
      className || "",
      "border-0 focus:ring-0 ring-0 ring-transparent focus:ring-transparent focus:outline-none outline-none",
      className.includes("hover") ? "" : "hover:bg-accent-200"
    )}
  >
    {React.createElement(icon, {
      className: `${
        size === "xs"
          ? "w-3 h-3"
          : size === "sm"
          ? "w-4 h-4"
          : size === "md"
          ? "w-6 h-6"
          : "w-8 h-8 "
      }${iconClassName}`,
    })}
  </button>
)

export const InfoIconButton: FC<React.PropsWithChildren<{ onClick: () => void }>> = ({
  onClick,
}) => (
  <IconButton
    icon={QuestionMarkCircleIcon}
    size="sm"
    onClick={onClick}
    className="text-neutral-600"
  />
)
