import {
  ConnectEmailBanner,
  ConnectEmailBannerProps,
} from "@stories/components/ConnectEmailBanner/ConnectEmailBanner"
import React from "react"
import { useConnectGmail } from "src/pages/Email/useConnectEmail"
import { useFeatureFlag } from "src/providers/featureFlags/useFeatureFlags"

export const EmailConnectBanner: React.FC<{ client: ConnectEmailBannerProps["client"] }> = ({
  client,
}) => {
  const { connectGmail, loading, isGmailConnected } = useConnectGmail()
  const emailIntegrationEnabled = useFeatureFlag("crm_emails_integration")

  const clientMethods: {
    [key in ConnectEmailBannerProps["client"]]: () => void
  } = {
    gmail: connectGmail,
    office: () => {
      // TODO: Implement Office 365 connection
      // eslint-disable-next-line no-console
      console.log("Connect Office")
    },
  }

  if (emailIntegrationEnabled && isGmailConnected === false) {
    return <ConnectEmailBanner client="gmail" onClick={clientMethods[client]} loading={loading} />
  } else {
    return null
  }
}
