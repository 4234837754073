import { Excluding } from "../../utils/TypeUtils"
import { assertUnreachable } from "../../utils/fp/Function"
import { CompanyIdFields, CompanyIdFieldsWithPostgres } from "../Company"
import { RichTextNode } from "../RichText/richText"
import { User, UserIdFields, UserPublicFields, viewUserIdFields } from "../User"
import { UserCreatable } from "../UserCreatable"
import { DealCRMDealIdFields } from "./Deal/DealCRMDeal"
import { DealCRMContactIdFields } from "./DealCRMContact"
import { DealCRMInterest } from "./DealCRMInterest"
import { CSVSchema } from "../../csv/CSVSchema"
import { Validation } from "../../containers/Validation"
import { randomString } from "../../utils/RandomUtils"

export interface DealCRMNote extends UserCreatable {
  id: string
  accountId: string
  source: CRMNoteSource
  note: RichTextNode
  parentNote: DealCRMNoteParentNote | null
  taggedCompanies: CompanyIdFields[]
  taggedContacts: DealCRMContactIdFields[]
  taggedDeals: DealCRMDealIdFields[]
  taggedUsers: UserPublicFields[]
  taggedContactIds: string[] // de-normalized for queries
  taggedCompanyIds: string[]
  taggedDealIds: string[]
  taggedUserIds: string[]
  updatedAt?: Date
  updatedBy?: UserIdFields
}

/** @deprecated only use in migrations on old data */
export interface OldDealCRMNoteSourceFields {
  sourceId: string
  sourceType: "contact" | "company" | "deal" | "dealParticipant"
}

export type DealCRMNoteContactSource = {
  sourceType: "contact"
  sourceId: string
  name: string
  contactType: DealCRMContactIdFields["tag"]
  contact: DealCRMContactIdFields
}

export type DealCRMNoteCompanySource = {
  sourceType: "company"
  sourceId: string
  companyPitchbookId: string
  name: string
  logo: string
}

export type DealCRMNoteDealSource = {
  sourceType: "deal"
  sourceId: string
  name: string
  contact: null
  company: CompanyIdFieldsWithPostgres
  createdAt: Date
}

export type DealCRMNoteDealParticipantSource = {
  sourceType: "dealParticipant"
  sourceId: string
  contact: DealCRMContactIdFields
  deal: DealCRMDealIdFields
  company: CompanyIdFieldsWithPostgres
}

export type DealCRMNoteInterestSource = {
  sourceType: "interest"
  interestDirection: DealCRMInterest["direction"]
  sourceId: string
  contact: DealCRMContactIdFields | null
  company: DealCRMInterest["company"]
}
export type DealCRMNoteHoldingSource = {
  sourceType: "holding"
  sourceId: string
  contact: DealCRMContactIdFields | null
  company: DealCRMInterest["company"]
}

export type CRMNoteSource =
  | DealCRMNoteContactSource
  | DealCRMNoteCompanySource
  | DealCRMNoteDealSource
  | DealCRMNoteDealParticipantSource
  | DealCRMNoteInterestSource
  | DealCRMNoteHoldingSource

export type DealCRMNoteParentNote = Excluding<Pick<DealCRMNote, "id">, DealCRMNote>
export const viewDealCRMNoteParentNoteFields = (
  note: Pick<DealCRMNote, "id">
): DealCRMNoteParentNote => ({
  id: note.id,
})

export const sourceDescriptionString = (source: CRMNoteSource): string => {
  if (source.sourceType === "company") {
    return source.name
  }
  if (source.sourceType === "contact") {
    return source.name
  }
  if (source.sourceType === "deal") {
    return source.name
  }
  if (source.sourceType === "dealParticipant") {
    return `${source.contact.name} on ${source.deal.name}`
  }
  if (source.sourceType === "interest") {
    return `${source.contact ? `${source.contact.name}'s` : ""} ${source.company.name} ${
      source.interestDirection
    } Interest`
  }
  if (source.sourceType === "holding") {
    return `${source.contact ? `${source.contact.name}'s` : ""} ${source.company.name} Holding`
  }
  return assertUnreachable(source)
}

export const minimalHoldingNote = (
  user: User
): CSVSchema<
  {
    holdingNote: string | null
  },
  Omit<DealCRMNote, "source"> | null
> => ({
  row: {
    holdingNote: (x) => Validation.Ok(x),
  },
  reassemble: ({ holdingNote }) =>
    holdingNote
      ? Validation.Ok({
          createdAt: new Date(),
          createdBy: viewUserIdFields(user),
          id: randomString(),
          accountId: user.account.id,
          note: {
            type: "doc",
            content: [
              {
                type: "text",
                text: holdingNote,
                attrs: { label: { name: "default" } },
              },
            ],
            attrs: { label: { name: "default" } },
          } satisfies RichTextNode,
          parentNote: null,
          taggedCompanies: [],
          taggedContacts: [],
          taggedDeals: [],
          taggedUsers: [],
          taggedContactIds: [],
          taggedCompanyIds: [],
          taggedDealIds: [],
          taggedUserIds: [],
        })
      : Validation.Ok(null),
})
export const minimalContactNote = (
  user: User
): CSVSchema<
  {
    contactNote: string | null
  },
  Omit<DealCRMNote, "source"> | null
> => ({
  row: {
    contactNote: (x) => Validation.Ok(x),
  },
  reassemble: ({ contactNote }) =>
    contactNote
      ? Validation.Ok({
          createdAt: new Date(),
          createdBy: viewUserIdFields(user),
          id: randomString(),
          accountId: user.account.id,
          note: {
            type: "doc",
            content: [
              {
                type: "text",
                text: contactNote,
                attrs: { label: { name: "default" } },
              },
            ],
            attrs: { label: { name: "default" } },
          } satisfies RichTextNode,
          parentNote: null,
          taggedCompanies: [],
          taggedContacts: [],
          taggedDeals: [],
          taggedUsers: [],
          taggedContactIds: [],
          taggedCompanyIds: [],
          taggedDealIds: [],
          taggedUserIds: [],
        })
      : Validation.Ok(null),
})
