import { Account, AccountIdFields } from "common/model/Account"
import { FC } from "react"
import { AccountEventCountsRollup } from "common/model/Analytics/EventCounterRollup"
import Drawer from "../../components/Drawer/Drawer"
import { useLoggedInUser } from "../../providers/loggedInUser/useLoggedInUser"
import AccountWatchlistModule from "./AccountWatchlist"
import { AccountEventCounts } from "./AccountEventCounts"
import { AccountEngagementChart } from "./AccountEngagementChart"
import { AccountUsersEngagement } from "./AccountUsersEngagement"
import { AccountMetrics } from "common/model/AccountMetrics"
import { useDocument } from "../../firebase/Firestore"
import { collections } from "common/firestore/Collections"
import Spinner from "../../components/icons/Spinner"
import Typography, { Size, Weight } from "../../stories/components/Typography/Typography"
import { Loading, isLoaded } from "common/utils/Loading"
import { GhostAccountButton } from "../Accounts"
import { Tabs } from "@stories/components/Tabs/Tabs"
import { AdminAccountOpportunityInboxDashboard } from "../opportunity-inbox/AdminOpportunityInboxDashboard"
import AdminAccountCRMSettingsPage from "./AdminAccountCRMSettingsPage"
import { AdminAccountDrawerAccessControlSettings } from "./AdminAccountDrawerAccessControlSettings"
import { AdminTrustedBrokerConnectionsControl } from "./AdminTrustedBrokerConnectionsControl"
import { AdminAccountDrawerSizeFilterSettings } from "./AdminAccountDrawerSizeFilterSettings"

export const AdminAccountDetailsDrawer = ({
  accountId,
  closeDrawer,
  open,
  weeklyEventRollups,
  monthlyEventRollups,
}: {
  accountId: AccountIdFields["id"]
  weeklyEventRollups: Loading<AccountEventCountsRollup[]>
  monthlyEventRollups: Loading<AccountEventCountsRollup[]>
  closeDrawer: () => void
  open: boolean
}) => {
  if (!useLoggedInUser().isAdmin) return null
  if (!open) return null
  return (
    <AccountDetailsDrawerInner
      accountId={accountId}
      closeDrawer={closeDrawer}
      weeklyEventRollups={weeklyEventRollups}
      monthlyEventRollups={monthlyEventRollups}
    />
  )
}

const AccountDetailsDrawerInner = ({
  accountId,
  closeDrawer,
  weeklyEventRollups,
  monthlyEventRollups,
}: {
  accountId: AccountIdFields["id"]
  weeklyEventRollups: Loading<AccountEventCountsRollup[]>
  monthlyEventRollups: Loading<AccountEventCountsRollup[]>
  closeDrawer: () => void
}) => {
  const [fullAccount, err] = useDocument<Account>(collections.accounts, accountId)

  if (
    !fullAccount ||
    fullAccount === "loading" ||
    !isLoaded(weeklyEventRollups) ||
    !isLoaded(monthlyEventRollups)
  )
    return <Spinner size="md" />

  const weeklyAccountEventCounts: AccountEventCounts[] = weeklyEventRollups.map((rollup) => {
    const eventCounts = rollup.accounts[accountId]?.eventCounts || {}
    return {
      period: rollup.period,
      periodDate: rollup.periodDate,
      eventCounts,
    }
  })

  const monthlyAccountEventCounts: AccountEventCounts[] = monthlyEventRollups.map((rollup) => {
    const eventCounts = rollup.accounts[accountId]?.eventCounts || {}
    return {
      period: rollup.period,
      periodDate: rollup.periodDate,
      eventCounts,
    }
  })

  return (
    <Drawer
      className="text-sm h-full"
      open
      title={`Account Details: ${fullAccount.name}`}
      size="lg"
      onClose={closeDrawer}
      destroyOnClose
    >
      <div className="pb-10 space-y-8">
        <div className="flex gap-2 items-center">
          <Typography text="Actions:" size={Size.Small} weight={Weight.Semibold} />
          <GhostAccountButton account={fullAccount} />
        </div>
        <Tabs
          tabs={[
            {
              id: "engagement",
              label: "Engagement",
              children: (
                <>
                  <AccountEngagementChart
                    account={fullAccount}
                    weeklyAccountEvents={weeklyAccountEventCounts}
                    monthlyAccountEvents={monthlyAccountEventCounts}
                  />
                  <AccountEngagementStats account={fullAccount} />
                  <div>
                    <Typography size={Size.Medium} weight={Weight.Bold} text="Account Stats" />
                    <AccountMetricsTable account={fullAccount} />
                  </div>
                  <AccountUsersEngagement account={fullAccount} />
                </>
              ),
            },
            {
              id: "watchlist",
              label: "Watchlist",
              children: <AccountWatchlistModule account={fullAccount} />,
            },
            ...(fullAccount.isOpportunityInboxEnabled
              ? [
                  {
                    id: "opportunityInbox",
                    label: "OppInbox Usage",
                    children: <AdminAccountOpportunityInboxDashboard accountId={fullAccount.id} />,
                  },
                ]
              : []),
            {
              id: "crm",
              label: "CRM Settings",
              children: <AdminAccountCRMSettingsPage account={fullAccount} />,
            },
            {
              id: "accountSettings",
              label: "Account Settings",
              children: (
                <div className="divide-y p-4">
                  <AdminAccountDrawerAccessControlSettings account={fullAccount} />
                  <AdminAccountDrawerSizeFilterSettings account={fullAccount} />
                </div>
              ),
            },
            {
              id: "brokerNetworks",
              label: "Broker Networks",
              children: <AdminTrustedBrokerConnectionsControl account={fullAccount} />,
            },
          ].map((item, key) => ({ ...item, key }))}
          initialTab={0}
        />
      </div>
    </Drawer>
  )
}

const AccountEngagementStats: FC<{ account: Account }> = ({ account }) => {
  return (
    <div>
      <div>
        <Typography size={Size.Medium} weight={Weight.Bold} text="Account Relative Engagement" />
      </div>
      <div>
        <Typography
          size={Size.XSmall}
          text="Engagement score relative to the 'median engagement' of full-access accounts over the specified time period."
        />
      </div>
      <table>
        <thead>
          <tr>
            <th className="px-4 py-2 text-sm font-semibold text-neutral-900 text-left" />
            <th className="px-4 py-2 text-sm font-semibold text-neutral-900">Past 2 Weeks</th>
            <th className="px-4 py-2 text-sm font-semibold text-neutral-900">Past 4 Weeks</th>
            <th className="px-4 py-2 text-sm font-semibold text-neutral-900">Past 3 Months</th>
            <th className="px-4 py-2 text-sm font-semibold text-neutral-900">Past Year</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="px-4 py-2 whitespace-nowrap text-sm text-neutral-800 font-semibold">
              Engagement Score
            </td>
            <td className="px-4 py-2 whitespace-nowrap text-sm text-neutral-700">
              {account.engagementScores?.twoWeekEngagementScore || "none"}
            </td>
            <td className="px-4 py-2 whitespace-nowrap text-sm text-neutral-700">
              {account.engagementScores?.fourWeekEngagementScore || "none"}
            </td>
            <td className="px-4 py-2 whitespace-nowrap text-sm text-neutral-700">
              {account.engagementScores?.threeMonthEngagementScore || "none"}
            </td>
            <td className="px-4 py-2 whitespace-nowrap text-sm text-neutral-700">
              {account.engagementScores?.oneYearEngagementScore || "none"}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

const AccountMetricsTable: FC<{ account: Account }> = ({ account }) => {
  const renderRow = (metric: keyof Required<AccountMetrics>["accountMetrics"]) => {
    const data = account.accountMetrics ? account.accountMetrics[metric] : null

    return (
      <tr key={metric}>
        <td className="px-4 py-2 whitespace-nowrap text-sm text-neutral-800">{metric}</td>
        <td className="px-4 py-2 whitespace-nowrap text-sm text-neutral-700">
          {data?.last30Days ? <span className="font-bold">{data?.last30Days}</span> : 0}
        </td>
        <td className="px-4 py-2 whitespace-nowrap text-sm text-neutral-700">
          {data?.last90Days ? <span className="font-bold">{data?.last90Days}</span> : 0}
        </td>
        <td className="px-4 py-2 whitespace-nowrap text-sm text-neutral-700">
          {data?.total ? <span className="font-bold">{data?.total}</span> : 0}
        </td>
      </tr>
    )
  }

  const metrics: (keyof Required<AccountMetrics>["accountMetrics"])[] = [
    "orders",
    "orderRenewals",
    "connectRequestsSubmitted",
    "connectRequestsReceived",
    "orderInquiriesSubmitted",
    "companyWatchlist",
    "closedTradeReported",
    "companyPageView",
    "liveMarketPageView",
    "rfqResponseSubmitted",
    "viewFundingRoundsPage",
    "viewMarketInsightsPage",
    "viewNewsPage",
    "viewOrderDetails",
  ]

  return (
    <table>
      <thead>
        <tr>
          <th className="px-4 py-2 text-sm font-semibold text-neutral-900 text-left">Metric</th>
          <th className="px-4 py-2 text-sm font-semibold text-neutral-900">30 day count</th>
          <th className="px-4 py-2 text-sm font-semibold text-neutral-900">90 day count</th>
          <th className="px-4 py-2 text-sm font-semibold text-neutral-900">Total</th>
        </tr>
      </thead>
      <tbody>{metrics.map(renderRow)}</tbody>
    </table>
  )
}

export default AdminAccountDetailsDrawer
