import { AccountIdFields } from "common/model/Account"
import { useFirebaseWriter } from "src/firebase/Context"
import {
  AccountNetworkNode,
  DEFAULT_MAX_BROKER_NETWORK_SIZE,
  TrustedBrokerConnection,
  getAccountNetworkNodeFirmName,
  getCurrentConnectedNodes,
  getPendingOutboundInvites,
} from "common/model/TrustedBrokerConnection"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { defaultIfLoading } from "common/utils/Loading"
import { useTrustedBrokerConnectionQuery } from "src/queries/TrustedBrokerNetwork/useTrustedBrokerConnectionsQuery"
import { notification } from "@stories/components/Antd"
import { useMemo } from "react"
import { addConnectionToNetwork } from "src/queries/TrustedBrokerNetwork/addConnectionToNetwork"
import { rejectConnectionInvite } from "src/queries/TrustedBrokerNetwork/rejectConnectionInvite"
import { acceptConnectionInvite } from "src/queries/TrustedBrokerNetwork/acceptConnectionInvite"

export const useTrustedBrokerNetwork = ({ account }: { account: AccountIdFields }) => {
  const { user, isAdmin } = useLoggedInUser()
  const firebase = useFirebaseWriter()

  const currentTrustedBrokerConnections = useTrustedBrokerConnectionQuery({ account })
  const remainingConnections = useMemo(
    () =>
      Math.max(
        0,
        DEFAULT_MAX_BROKER_NETWORK_SIZE -
          (getCurrentConnectedNodes(
            user.account,
            defaultIfLoading(currentTrustedBrokerConnections, [])
          ).length +
            getPendingOutboundInvites(
              user.account,
              defaultIfLoading(currentTrustedBrokerConnections, [])
            ).length)
      ),
    [currentTrustedBrokerConnections, user.account]
  )
  const maxInvitesReached = useMemo(() => remainingConnections === 0, [remainingConnections])

  const handleAddToNetwork = async ({
    connectionAccount,
    status,
    adminConnection = false,
  }: {
    connectionAccount: AccountNetworkNode
    status: AccountNetworkNode["status"]
    adminConnection?: boolean
  }) => {
    if (!isAdmin && maxInvitesReached) return null
    return addConnectionToNetwork({
      db: firebase.writerDb,
      connectionAccount,
      createdForAccount: account,
      status,
      createdBy: user,
      adminConnection,
    })
  }

  const handleRejectInvite = async ({
    sourceAccountId,
    connection,
  }: {
    sourceAccountId: string
    connection: TrustedBrokerConnection
  }) => {
    const otherAccount =
      connection.leftAccount.tag === "on platform" &&
      connection.leftAccount.account.id === sourceAccountId
        ? connection.rightAccount
        : connection.leftAccount
    return rejectConnectionInvite({ db: firebase.writerDb, sourceAccountId, connection }).then(
      () => {
        notification.info({
          message: `You rejected the invite to connect with ${getAccountNetworkNodeFirmName(
            otherAccount
          )}.`,
        })
      }
    )
  }

  const acceptInvite = async ({
    sourceAccountId,
    connection,
  }: {
    sourceAccountId: string
    connection: TrustedBrokerConnection
  }) => {
    if (!isAdmin && maxInvitesReached) return
    const otherAccount =
      connection.leftAccount.tag === "on platform" &&
      connection.leftAccount.account.id === sourceAccountId
        ? connection.rightAccount
        : connection.leftAccount

    await acceptConnectionInvite({
      db: firebase.writerDb,
      sourceAccountId,
      connection,
    }).then(() => {
      notification.success({
        message: `Connected ${getAccountNetworkNodeFirmName(otherAccount)} to your network`,
      })
    })
  }

  return {
    remainingConnections,
    maxInvitesReached,
    currentTrustedBrokerConnections,
    handleAddToNetwork,
    handleRejectInvite,
    acceptInvite,
  }
}
