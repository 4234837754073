import { ReactNode } from "react"
import { useAuthUser } from "../providers/authUser/useAuthUser"
import { useCurrentUser } from "../providers/currentUser/useCurrentUser"

/**
 * Component wrapper that only displays a child component to admin users
 */
const AdminVisible = ({ children }: { children: ReactNode }) => {
  const user = useCurrentUser()
  const authUser = useAuthUser()

  if (user === "loading" || !authUser || !user?.isAdmin) {
    return null
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export default AdminVisible
