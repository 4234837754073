import { capitalize } from "lodash"
import { justIfNonempty } from "common/utils/data/Array/Nonempty"
import OrderTermSheetSection from "../OrderTermSheetSection"
import { UNSPECIFIED_VALUE } from "./constants"
import { OrderTermSheetRowProps } from "../OrderTermSheetRow"
import { orderDataAccessLevel } from "../../../shared/accessLevels"
import { Just, Nothing, filterMaybe } from "common/containers/Maybe"
import { OrderPageSectionProps } from "./types"
import { StructureDisplay } from "@stories/components/Orders/TableCells/StructureCell"
import { Color } from "@stories/components/Typography/Typography"
import { MultiLayerStructureIcon } from "@stories/icons/MultiLayerStructureIcon"

export const OTHER_TERMS_SECTION_TITLE = "Structure & Asset Details"

const OtherTermsSection = ({ order, hideUnspecified }: OrderPageSectionProps) => {
  const structuresRow: OrderTermSheetRowProps = {
    label: `Structure${order.currentStructures().length > 1 ? "s" : ""}`,
    value: (
      <div className="flex items-center gap-1">
        <StructureDisplay
          structures={order.currentStructures()}
          managementFee={order.managementFee()}
          carry={order.carry()}
          structureLayersCount={order.structureLayersCount()}
        />
      </div>
    ),
    accessLevel: orderDataAccessLevel.structure,
  }

  const shareClassesValue = justIfNonempty(order.shareClasses().map(capitalize))
    .map((shareClasses) => shareClasses.join(", "))
    .withDefault(UNSPECIFIED_VALUE)
  const shareClassesRow: OrderTermSheetRowProps = {
    label: "Share Class",
    value: shareClassesValue,
    accessLevel: orderDataAccessLevel.shareClass(order.isConnectable()),
  }

  const managementFeeValue = order
    .managementFee()
    .map((fee) => `${fee}%`)
    .withDefault(UNSPECIFIED_VALUE)
  const managementFeeRow: OrderTermSheetRowProps = {
    label: "SPV management fee",
    value: managementFeeValue,
    accessLevel: orderDataAccessLevel.price(order.isConnectable()),
  }
  const carryFeeValue = order
    .carry()
    .map((fee) => `${fee}%`)
    .withDefault(UNSPECIFIED_VALUE)
  const carryFeeRow: OrderTermSheetRowProps = {
    label: "SPV carry fee",
    value: carryFeeValue,
    accessLevel: orderDataAccessLevel.price(order.isConnectable()),
  }

  const spvLayersRow = order
    .structureLayersCount()
    .map((layers) =>
      layers > 1
        ? Just({
            label: "SPV layers",
            value: (
              <div className="flex items-center gap-2">
                Multi-layer SPV <MultiLayerStructureIcon color={Color.Warning} />
              </div>
            ),
            accessLevel: orderDataAccessLevel.price(order.isConnectable()),
          })
        : Nothing
    )
    .flatten()

  // const shareClassSeriesRow = {
  //   label: "Share Class Series",
  //   value: nullableToMaybe(order.shareClassSeries()).withDefault(UNSPECIFIED_VALUE),
  //   accessLevel: orderDataAccessLevel.shareClass(order.isConnectable()),
  // }

  const vppfMinPriceRow: OrderTermSheetRowProps = {
    label: "Price for minimum shares delivered",
    value: order
      .latestUpdateWithJust((u) => {
        const structureTerms = u.structureTerms()
        return structureTerms.tag === "variable_prepaid_forward" ? Just(structureTerms) : Nothing
      })
      .extract()
      .map((u) => `$${u.USDPerShareForMinimumSharesDelivered.toString()}`)
      .withDefault(UNSPECIFIED_VALUE),

    accessLevel: orderDataAccessLevel.price(order.isConnectable()),
  }
  const vppfMaxPriceRow: OrderTermSheetRowProps = {
    label: "Price for maximum shares delivered",
    value: order
      .latestUpdateWithJust((u) => {
        const structureTerms = u.structureTerms()
        return structureTerms.tag === "variable_prepaid_forward" ? Just(structureTerms) : Nothing
      })
      .extract()
      .map((u) => `$${u.USDPerShareForMaximumSharesDelivered.toString()}`)
      .withDefault(UNSPECIFIED_VALUE),
    accessLevel: orderDataAccessLevel.price(order.isConnectable()),
  }
  const structureSpecificRows = [
    order.currentStructures().some((s) => s.is("spv"))
      ? [...filterMaybe([spvLayersRow]), managementFeeRow, carryFeeRow]
      : [],
    order.currentStructures().some((s) => s.is("variable_prepaid_forward"))
      ? [vppfMinPriceRow, vppfMaxPriceRow]
      : [],
  ].flat()

  return (
    <OrderTermSheetSection
      title={OTHER_TERMS_SECTION_TITLE}
      rows={[structuresRow].concat(structureSpecificRows).concat(shareClassesRow)}
      hideUnspecified={hideUnspecified}
    />
  )
}

export default OtherTermsSection
