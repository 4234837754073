import { InfoCircleOutlined } from "@ant-design/icons"
import Alert from "@stories/components/Alert/Alert"
import FeatureWrapper from "src/providers/featureFlags/FeatureWrapper"
import { useOrderForm } from "./OrderFormContext"

export const OrderBrokerEditingClientOrderWarning = () => {
  const { brokerEditingClientOrder, formOrder } = useOrderForm()

  if (!brokerEditingClientOrder) {
    return null
  }

  return (
    <FeatureWrapper flagName="linked_broker">
      <Alert
        icon={<InfoCircleOutlined />}
        headline={`Order from ${formOrder.account?.name ?? "Client"}`}
        message={`You are editing this order on behalf of your client${
          formOrder.account?.name ? `, ${formOrder.account.name}.` : "."
        }. They will be notified of your edits via email.`}
        variant="warning"
      />
    </FeatureWrapper>
  )
}
