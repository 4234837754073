import { StockSplitData } from "common/model/Stock/StockSplit"
import { splitAdjustPriceToToday } from "common/model/data-product/valuationCalculation/Valuation"
import {
  PostgresCompanyInvestorWithCompanyFundingRounds,
  PostgresInvestorEmployee,
} from "common/model/postgres/PostgresInvestors"
import { removeSpecialCharacters, truncateString } from "common/utils/StringUtils"
import { isBefore } from "date-fns"
import capitalize from "lodash/capitalize"
import { SortDirection } from "src/utils/hooks/components/useSort"
import { SortStructure } from "../Deals/AllDealsTable"
import { CompanyInvestorsTableHeader } from "./CompanyInvestorsTable"
import { InvestorEmployeesTableHeader } from "./InvestorEmployeesTable"
import { orderBy } from "lodash"
import { head } from "common/utils/data/Array/ArrayUtils"
import { splitAdjustHelper } from "common/queries/pricing/splitAdjustment/SplitAdjustment"

const getEarliestRoundDate = (
  rounds: {
    date: string
    valuation: number | null
    price: number | null
    amount: number | null
    round: string
  }[]
): Date => {
  if (!rounds || rounds.length === 0) {
    // default now if no rounds or empty rounds array
    return new Date()
  }

  return rounds.reduce((earliestDate, round) => {
    const roundDate = new Date(round.date)
    return isBefore(roundDate, earliestDate) ? roundDate : earliestDate
  }, new Date())
}

export type LifeCycleGroup =
  | "seed"
  | "series_a"
  | "series_b"
  | "series_c"
  | "series_d"
  | "series_e"
  | "series_f_and_later"
  | "other"
  | "all"

export const stageOptions: {
  value: LifeCycleGroup
  label: string
}[] = [
  { value: "seed", label: "Seed" },
  {
    value: "series_a",
    label: "Series A",
  },
  {
    value: "series_b",
    label: "Series B",
  },
  {
    value: "series_c",
    label: "Series C",
  },
  {
    value: "series_d",
    label: "Series D",
  },
  {
    value: "series_e",
    label: "Series E",
  },
  {
    value: "series_f_and_later",
    label: "Series F and later",
  },
  {
    value: "other",
    label: "Other",
  },
  {
    value: "all",
    label: "Any Round",
  },
]

type InvestmentStages = {
  [key in LifeCycleGroup]: string[]
}

export const investmentStagesMap: Omit<InvestmentStages, "all"> = {
  seed: ["Angel", "Seed", "Seed Round", "Series 1"],
  series_a: ["Series A", "Series A1", "Series A2", "Series A3", "Series AA"],
  series_b: ["Series B", "Series B1", "Series B2", "Series B3", "Series BB", "Series BBB"],
  series_c: ["Series C", "Series C1", "Series C2", "Series C3", "Series CC"],
  series_d: ["Series D", "Series D1", "Series D2", "Series D3", "Series DD"],
  series_e: ["Series E", "Series E1", "Series E2", "Series E3", "Series EE"],
  series_f_and_later: ["Series F", "Series G", "Series H", "Series I", "Series J", "Series K"],
  other: [
    "Later Stage VC",
    "PE Growth/Expansion",
    "Restart - Later VC",
    "Secondary Transaction - Open Market",
    "Secondary Transaction - Private",
    "Secondary Transaction - Stock Distribution",
    "IPO",
    "Tender Offer",
    "Recapitalization",
    "Product Crowdfunding",
    "Equity Crowdfunding",
    "Grant",
    "Grant (prize money)",
  ],
}

export const sortRounds = (
  rounds: {
    date: string
    valuation: number | null
    price: number | null
    amount: number | null
    round: string
  }[],
  direction: SortDirection = "asc"
): {
  date: string
  valuation: number | null
  price: number | null
  amount: number | null
  round: string
}[] => {
  const stagesOrder: Record<string, number> = {
    Grant: 0,
    "Grant (prize money)": 1,
    Angel: 2,
    "Angel (individual)": 3,
    "Equity Crowdfunding": 4,
    "Product Crowdfunding": 5,
    Seed: 6,
    "Seed Round": 7,
    "Series AA": 8,
    "Series A": 9,
    "Series A1": 10,
    "Series A2": 11,
    "Series A3": 12,
    "Series B": 13,
    "Series B1": 14,
    "Series B2": 15,
    "Series B3": 16,
    "Series BB": 17,
    "Series BBB": 18,
    "Series C": 19,
    "Series C1": 20,
    "Series C2": 21,
    "Series C3": 22,
    "Series CC": 23,
    "Series D": 24,
    "Series D1": 25,
    "Series D2": 26,
    "Series D3": 27,
    "Series DD": 28,
    "Series E": 29,
    "Series E1": 30,
    "Series E2": 31,
    "Series E3": 32,
    "Series EE": 33,
    "Series F": 34,
    "Series G": 35,
    "Series H": 36,
    "Series I": 37,
    "Series J": 38,
    "Series K": 39,
    IPO: 40,
    "Later Stage VC": 41,
    "PE Growth/Expansion": 42,
    Recapitalization: 43,
    "Restart - Later VC": 44,
    "Secondary Transaction - Private": 45,
    "Secondary Transaction - Stock Distribution": 46,
    "Secondary Transaction - Open Market": 47,
    "Tender Offer": 48,
    Capitalization: 49,
    Unknown: 50,
  }

  // Create a new array with the same elements as the original one
  const sortedRounds = [...rounds].sort((a, b) => stagesOrder[a.round] - stagesOrder[b.round])

  // Return the sorted array based on the specified direction
  return direction === "asc" ? sortedRounds : sortedRounds.reverse()
}

export const compareEmployees = (
  a: PostgresInvestorEmployee,
  b: PostgresInvestorEmployee,
  sort: SortStructure<InvestorEmployeesTableHeader>
): number => {
  if (sort.col === null) return 0

  if (sort.col === "Name") {
    const nameA = `${a.firstName} ${a.lastName}`
    const nameB = `${b.firstName} ${b.lastName}`
    return sort.dir === "asc" ? nameB.localeCompare(nameA) : nameA.localeCompare(nameB)
  }

  if (sort.col === "Title") {
    return sort.dir === "asc"
      ? a.title.localeCompare(cleanTitle(b.title))
      : b.title.localeCompare(cleanTitle(a.title))
  }

  if (sort.col === "Email") {
    // Assuming you want to sort by email alphabetically
    return sort.dir === "asc"
      ? (a.email ?? "").localeCompare(b.email ?? "")
      : (b.email ?? "").localeCompare(a.email ?? "")
  }

  if (sort.col === "Years at Firm") {
    return sort.dir === "asc"
      ? (a.yearsAtFirm ?? 0) - (b.yearsAtFirm ?? 0)
      : (b.yearsAtFirm ?? 0) - (a.yearsAtFirm ?? 0)
  }

  return 0
}

export const getEarliestRound = (investor: PostgresCompanyInvestorWithCompanyFundingRounds) =>
  head(orderBy(investor.rounds ?? [], (r) => new Date(r.date).valueOf(), "asc"))
export const getEarliestKnownPPSRound = (
  investor: PostgresCompanyInvestorWithCompanyFundingRounds
) =>
  head(
    orderBy(
      investor.rounds?.flatMap((r) => (r.price === null ? [] : [{ ...r, price: r.price }])) ?? [],
      (r) => new Date(r.date).valueOf(),
      "asc"
    )
  )

export const getLatestRound = (investor: PostgresCompanyInvestorWithCompanyFundingRounds) =>
  head(orderBy(investor.rounds ?? [], (r) => new Date(r.date).valueOf(), "desc"))
export const getLatestKnownPPSRound = (investor: PostgresCompanyInvestorWithCompanyFundingRounds) =>
  head(
    orderBy(
      investor.rounds?.flatMap((r) => (r.price === null ? [] : [{ ...r, price: r.price }])) ?? [],
      (r) => new Date(r.date).valueOf(),
      "desc"
    )
  )

export const getSplitAdjustedRoundPrice =
  (stockSplitData: StockSplitData[]) => (round: { date: string; price: number }) =>
    splitAdjustHelper(stockSplitData, new Date(), "simplePrice", {
      price: round.price,
      date: new Date(round.date),
    }).price

export const compareInvestors = (
  a: PostgresCompanyInvestorWithCompanyFundingRounds,
  b: PostgresCompanyInvestorWithCompanyFundingRounds,
  sort: SortStructure<CompanyInvestorsTableHeader>,
  stockSplitData: StockSplitData[]
): number => {
  if (sort.col === null) return 0

  if (sort.col === "Investor") {
    const compareStrings = (strA: string, strB: string): number => {
      const regex = /(\D*)(\d*)/ // Split into non-digit and digit parts

      const [, nonDigitPartA, digitPartA] = regex.exec(strA) || []
      const [, nonDigitPartB, digitPartB] = regex.exec(strB) || []

      const compareNonDigit = nonDigitPartA.localeCompare(nonDigitPartB)

      if (compareNonDigit !== 0) {
        return compareNonDigit
      }

      // If the non-digit parts are the same, compare the numeric parts
      const numA = parseInt(digitPartA, 10) || 0
      const numB = parseInt(digitPartB, 10) || 0

      return numA - numB
    }

    return sort.dir === "asc" ? compareStrings(a.name, b.name) : compareStrings(b.name, a.name)
  }

  if (sort.col === "Funding Rounds") {
    const earliestRoundDateA = getEarliestRoundDate(a.rounds ?? [])
    const earliestRoundDateB = getEarliestRoundDate(b.rounds ?? [])

    if (!earliestRoundDateA) {
      return sort.dir === "asc" ? -1 : 1
    }

    if (!earliestRoundDateB) {
      return sort.dir === "asc" ? 1 : -1
    }

    return sort.dir === "asc"
      ? earliestRoundDateB.getTime() - earliestRoundDateA.getTime()
      : earliestRoundDateA.getTime() - earliestRoundDateB.getTime()
  }

  if (sort.col === "Earliest Investment PPS") {
    // Default based on sort dir always puts nulls last
    const defaultPPS = sort.dir === "asc" ? Infinity : 0
    const ppsA = getEarliestKnownPPSRound(a)
      .map(getSplitAdjustedRoundPrice(stockSplitData))
      .withDefault(defaultPPS)
    const ppsB = getEarliestKnownPPSRound(b)
      .map(getSplitAdjustedRoundPrice(stockSplitData))
      .withDefault(defaultPPS)

    return sort.dir === "asc" ? ppsA - ppsB : ppsB - ppsA
  }

  if (sort.col === "Earliest Investment Date") {
    const dateA = getEarliestRound(a)
      .map((r) => new Date(r.date))
      .withDefault(new Date(0))
    const dateB = getEarliestRound(b)
      .map((r) => new Date(r.date))
      .withDefault(new Date(0))

    return sort.dir === "asc"
      ? dateA.getTime() - dateB.getTime()
      : dateB.getTime() - dateA.getTime()
  }

  if (sort.col === "Latest Investment PPS") {
    // Default based on sort dir always puts nulls last
    const defaultPPS = sort.dir === "asc" ? Infinity : 0
    const ppsA = getLatestKnownPPSRound(a)
      .map(getSplitAdjustedRoundPrice(stockSplitData))
      .withDefault(defaultPPS)
    const ppsB = getLatestKnownPPSRound(b)
      .map(getSplitAdjustedRoundPrice(stockSplitData))

      .withDefault(defaultPPS)

    return sort.dir === "asc" ? ppsA - ppsB : ppsB - ppsA
  }

  if (sort.col === "Latest Investment Date") {
    const dateA = getLatestRound(a)
      .map((r) => new Date(r.date))
      .withDefault(new Date(0))
    const dateB = getLatestRound(b)
      .map((r) => new Date(r.date))
      .withDefault(new Date(0))

    return sort.dir === "asc"
      ? dateA.getTime() - dateB.getTime()
      : dateB.getTime() - dateA.getTime()
  }

  return 0
}

export const cleanTitle = (title: string) =>
  title.includes("**") || title === "" || title === " "
    ? "Unknown"
    : truncateString(removeSpecialCharacters(title).split(" ").map(capitalize).join(" "), 35)

export const filterDisplayedInvestors = (
  investors: PostgresCompanyInvestorWithCompanyFundingRounds[],
  searchTerm: string,
  stageFilter: LifeCycleGroup | null,
  investmentPriceFilter: number | null,
  investorTypeFilter: InvestorType,
  stockSplitsData: StockSplitData[] | null,
  side: "buy" | "sell"
): PostgresCompanyInvestorWithCompanyFundingRounds[] =>
  investors
    .filter((investor: PostgresCompanyInvestorWithCompanyFundingRounds) =>
      investor.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter((investor: PostgresCompanyInvestorWithCompanyFundingRounds) => {
      if (!investorTypeFilter || investorTypeFilter === "all") {
        return true
      }

      return investor.investorType === investorTypeFilter
    })
    .filter((investor: PostgresCompanyInvestorWithCompanyFundingRounds) => {
      if (!stageFilter || stageFilter === "all") {
        return true
      }

      const rounds = (investor.rounds ?? []).map((round) => round.round)

      return rounds.some((round) => investmentStagesMap[stageFilter].includes(round))
    })
    .filter((investor: PostgresCompanyInvestorWithCompanyFundingRounds) => {
      if (!investmentPriceFilter) {
        return true
      }

      const { rounds } = investor

      const earliestInvestment = !rounds
        ? null
        : rounds.sort((a, b) => {
            if (!a.date || !b.date) return 0
            return isBefore(new Date(a.date), new Date(b.date)) ? -1 : 1
          })[0]

      const earliestInvestmentRawPPS = earliestInvestment?.price

      if (!earliestInvestmentRawPPS) {
        return false
      }

      const earliestInvestmentPPS = splitAdjustPriceToToday(
        earliestInvestmentRawPPS,
        new Date(earliestInvestment.date),
        stockSplitsData ?? []
      )

      if (side === "buy") {
        return earliestInvestmentPPS >= investmentPriceFilter
      }

      return earliestInvestmentPPS <= investmentPriceFilter
    })

export type InvestorType = "fund" | "individual" | null | "all"

export const investorTypeOptions: {
  value: InvestorType
  label: string
}[] = [
  { value: "all", label: "All" },
  { value: "fund", label: "Funds" },
  { value: "individual", label: "Individuals" },
]
