import { CurrentUser } from "@model/CurrentUser"
import { CompanyIdFields } from "common/model/Company"
import { Order } from "common/model/Order/Order"
import { DataRef, PricingDataRoot } from "common/queries/pricing/PricingDataSource"
import { enrichOrders } from "common/queries/sources/firestore/model/Orders"
import { Loading, mapLoading } from "common/utils/Loading"
import moment from "moment"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import { usePromisedState } from "src/utils/hooks/state/usePromisedState"
import { User } from "common/model/User"
import { dataSource } from "./DataSource"

export const usePricingData = <T>(
  f: (x: PricingDataRoot) => Promise<Loading<T>>,
  deps?: unknown[]
): Loading<T> => usePromisedState(f, dataSource(), deps)

export const usePricingDataForCurrentUser = <T>(
  query: (x: PricingDataRoot) => DataRef<T>,
  deps: unknown[]
) => {
  const user = useCurrentUser()
  const loadingQuery = async (x: PricingDataRoot): Promise<Loading<T>> =>
    mapLoading((u: CurrentUser) => query(x).get(u.user, moment()))(user) ??
    query(x).get(null, moment())
  return usePricingData(loadingQuery, [...deps, user])
}

export const useCompanyPriceObservations = (company: CompanyIdFields) =>
  usePricingDataForCurrentUser((x) => x.company(company.id).priceObservations(), [company.id])

export const useEnrichOrders = (o: Order[], forUser?: User | null) =>
  usePricingData((x) => enrichOrders(x)(Promise.resolve(o), forUser ?? null), [o])
