import moment from "moment"
import { UserPublicFields } from "../User"

export interface UserTypingStatus {
  id: string
  threadId: string
  user: UserPublicFields
  status: "typing" | "not-typing"
  lastUpdateDate: Date
}

export const isUserTyping = (typingStatusDoc: UserTypingStatus) =>
  typingStatusDoc.status === "typing" &&
  moment(typingStatusDoc.lastUpdateDate).isAfter(moment().subtract(3, "minutes"))
