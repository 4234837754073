import firebase from "firebase/compat/app"
import { useEffect, useMemo, useState } from "react"
import { isLoading, Loading } from "common/utils/Loading"
import { Account, isFullyOnboardedMarketsCustomer } from "common/model/Account"
import { CurrentUser } from "../../model/CurrentUser"
import { useCurrentUser } from "../../providers/currentUser/useCurrentUser"
import { useFirebaseWriter } from "../../firebase/Context"
import { useAuthUser } from "../../providers/authUser/useAuthUser"
import { getInviteCode } from "../../utils/LocalStorage"
import { FirebaseWriter } from "../../firebase/Firebase"
import { useLoadingState } from "../../utils/useLoadingState"
import { useCurrentAccount } from "../../queries/currentUser/useCurrentAccount"

export interface UserAuthContext {
  user?: Loading<CurrentUser>
  account?: Loading<Account>
  authUser?: firebase.User
  marketsOnboardingComplete: boolean
  savedInviteCode?: string
  firebase: FirebaseWriter // ?
}

const useInviteCode = () => {
  const [savedInviteCode, setSavedInviteCode] = useLoadingState<string>()
  useEffect(() => {
    const temp = getInviteCode()
    setSavedInviteCode(temp)
  }, [setSavedInviteCode])
  return savedInviteCode
}

export const useAuthContext = (): UserAuthContext => {
  const user = useCurrentUser()
  const account = useCurrentAccount()
  const db = useFirebaseWriter()
  const authUser = useAuthUser()
  const [marketsOnboardingComplete, setMarketsOnboardingComplete] = useState(true)

  useEffect(() => {
    if (!user || isLoading(user) || !account || isLoading(account)) {
      setMarketsOnboardingComplete(false)
      return
    }
    setMarketsOnboardingComplete(isFullyOnboardedMarketsCustomer(user.user.account))
  }, [user, account])

  const savedInviteCode = useInviteCode()

  return useMemo(
    () => ({
      user: user || undefined,
      account: account || undefined,
      authUser: authUser || undefined,
      marketsOnboardingComplete,
      savedInviteCode: savedInviteCode || undefined,
      firebase: db,
    }),
    [account, authUser, db, marketsOnboardingComplete, savedInviteCode, user]
  )
}

export const isAdminContext = (ctx: UserAuthContext): boolean =>
  !isLoading(ctx.user) && !!ctx.user?.isAdmin && !isGhosting(ctx.user)
export const isIntermediaryContext = (ctx: UserAuthContext): boolean =>
  !isLoading(ctx.user) && !!ctx.user?.user.account.clientType.includes("Intermediary")
export const isInvestorShareholderContext = (ctx: UserAuthContext): boolean =>
  !isLoading(ctx.user) && !!ctx.user?.user.account.clientType.includes("Investor/Shareholder")
export const isPureEmployeeContext = (ctx: UserAuthContext): boolean =>
  !isLoading(ctx.user) &&
  !!ctx.user?.user.account.clientType.includes("Employee") &&
  ctx.user?.user.account.clientType.length === 1

export const isGhosting = (user: CurrentUser): boolean => !!user.user.ghostingUserAccount
