import { useContext, createContext, PropsWithChildren, useMemo } from "react"

export type DrawerControls = {
  onClose: () => void
}

const DrawerControlContext = createContext<DrawerControls>({ onClose: () => {} })
export const DrawerControlProvider: React.FC<PropsWithChildren<DrawerControls>> = ({
  onClose,
  children,
}) => {
  const value = useMemo(
    () => ({
      onClose,
    }),
    [onClose]
  )
  return <DrawerControlContext.Provider value={value}>{children}</DrawerControlContext.Provider>
}
export const useDrawerControls = () => useContext(DrawerControlContext)
