import { Order } from "common/model/Order/Models/Internal"
import { mergeEditedOrder, createOrder } from "common/model/Order/Order"
import { LIVE_UNTIL_DEFAULT_DAYS } from "common/model/Order/OrderConstants"
import { Maybe, Nothing } from "common/containers/Maybe"
import { DocumentSnapshot } from "src/firebase/Firebase/utils"
import moment from "moment"
import React, { useCallback, useContext, useMemo } from "react"
import { trackOrderRenewed } from "../../../../utils/Tracking"
import { TerminalOrderStatusReason } from "common/model/Order/Types/Status"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"

type OrderUpdateControls = {
  updateOrder: (args: { mergedOrder: Order }) => Promise<Maybe<DocumentSnapshot<Order>>>
}

export const OrderUpdateControlsContext: React.Context<OrderUpdateControls> =
  React.createContext<OrderUpdateControls>({
    updateOrder: () => Promise.resolve(Nothing),
  })

export const useOwnOrderControls = () => {
  const { updateOrder } = useContext(OrderUpdateControlsContext)
  const loggedInUser = useLoggedInUser()
  const refreshDate = moment().add(LIVE_UNTIL_DEFAULT_DAYS, "days").toDate()
  const refreshOrder = useCallback(
    (order: Order) =>
      updateOrder({
        mergedOrder: mergeEditedOrder(
          order,
          createOrder({
            ...order,
            _lastReportedStatus: {
              asOf: new Date(),
              liveUntil: refreshDate,
              tag: "live",
            },
          })
        ),
      }).then((o) => {
        trackOrderRenewed(order, loggedInUser)
        return o
      }),
    [refreshDate, updateOrder, loggedInUser]
  )

  const expireOrder = useCallback(
    (order: Order, reason: TerminalOrderStatusReason, linkedPriceObservationId?: string) =>
      updateOrder({
        mergedOrder: mergeEditedOrder(
          order,
          createOrder({
            ...order,
            _lastReportedStatus: {
              asOf: new Date(),
              tag: "cancelled",
              reason,
            },
            linkedPriceObservationId: linkedPriceObservationId ?? null,
          })
        ),
      }),
    [updateOrder]
  )
  const recordClosedTradeOnOrder = useCallback(
    (order: Order, linkedPriceObservationId?: string) =>
      updateOrder({
        mergedOrder: mergeEditedOrder(
          order,
          createOrder({
            ...order,
            linkedPriceObservationId: linkedPriceObservationId ?? null,
          })
        ),
      }),
    [updateOrder]
  )
  return useMemo(
    () => ({
      refreshOrder,
      expireOrder,
      recordClosedTradeOnOrder,
    }),
    [expireOrder, refreshOrder, recordClosedTradeOnOrder]
  )
}
