import OrderDetailsWrapper from "@components/OrderDetails/OrderDetailsWrapper"
import { CRMProviders } from "../pages/CRM/CRMProviders"
import AdminProviders from "./AdminProviders/AdminProviders"
import IntroductionRequestProvider from "./IntroductionRequests"
import AccountComplianceProvider from "./data/AccountComplianceProvider"
import MessageThreadProvider from "./data/MessageThreadProvider"
import { TopBrokerProvider } from "./topBroker/TopBrokerProvider"
import { ShareOrderPopup } from "@components/ShareOrder/ShareOrderPopup/ShareOrderPopup"
import { AccountTrustedBrokerConnectionsProvider } from "./AccountTrustedBrokerConnections"

export const LoggedInAppProviders: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <AdminProviders>
    <IntroductionRequestProvider>
      <MessageThreadProvider>
        <AccountComplianceProvider>
          <CRMProviders>
            <TopBrokerProvider>
              <AccountTrustedBrokerConnectionsProvider>
                <OrderDetailsWrapper>
                    <ShareOrderPopup />
                    {children}
                </OrderDetailsWrapper>
              </AccountTrustedBrokerConnectionsProvider>
            </TopBrokerProvider>
          </CRMProviders>
        </AccountComplianceProvider>
      </MessageThreadProvider>
    </IntroductionRequestProvider>
  </AdminProviders>
)
